import React from "react";
import { Label } from "reactstrap";
import Email from "../../../components/base/Email";
import Phone from "../../../components/base/Phone";

const AdditionalContactList = ({ data, toggle, setEditContactData }) => {
  // handle onclick
  const handleOnClick = () => {
    setEditContactData(data);
    toggle();
  };

  return (
    <>
      {data &&
        data.map(value => {
          return (
            <div className="card">
              <Label
                className="font-weight-bold cursor-pointer"
                onClick={handleOnClick}
              >
                {value.contactFirstname + " " + value.contactLastName}
              </Label>

              <div className="cursor-default">
                <div className="font-weight-bold">
                  {value.title && (
                    <span className="text-secondary">{`${value.title}`}</span>
                  )}
                </div>

                {value.contactEmail && (
                  <div className="font-weight-bold mt-1">
                    <Email
                      isNotClickeable={true}
                      readOnly={true}
                      Email={value.contactEmail}
                      className={["link-secondary", "font-weight-bold"].join(
                        " "
                      )}
                      style={{ color: "#808080" }}
                    />
                  </div>
                )}

                {value.contactPhone && (
                  <div className="font-weight-bold mt-2">
                    <Phone
                      readOnly={true}
                      phone={value.contactPhone}
                      className={["text-secondary", "font-weight-bold"].join(
                        " "
                      )}
                    />
                  </div>
                )}
              </div>
            </div>
          );
        })}
    </>
  );
};

export default AdditionalContactList;
