import React, { useState } from "react";

import { Container, Navbar, Collapse, NavbarToggler } from "reactstrap";
import { getParamsByName } from "../../lib/helper";
import Logo from "../base/Logo";
import detectBrowser from "../helpers/detectBrowser";

// Inner Component
import UserNavDropdown from "./UserNavDropdown";

const PublicNavigation = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [isMobile] = useState(detectBrowser());
  const {
    marketplaceLogoRedirectUrl,
    headerColor,
    headerTextColor,
    loginPage
  } = props;

  const toggle = () => setIsOpen(!isOpen);
  const hideHeaderFooter = getParamsByName("hideHeaderFooter");
  return (
    <div>
      {!hideHeaderFooter && (
        <Navbar
          className={`navbar navbar-expand-lg ${!headerColor &&
            "bg-white"} pt-3 pt-md-1`}
          light
          expand="md"
          style={{
            backgroundColor: headerColor,
            color: headerTextColor,
            height: "4rem"
          }}
        >
          <Container
            className={[
              `${
                loginPage && isMobile
                  ? "justify-content-center"
                  : "justify-content-stretch"
              }`
            ].join(" ")}
          >
            <Logo
              src={props.marketplaceLogo}
              link={marketplaceLogoRedirectUrl}
              altText={props.portalName}
              height={"40px"}
              label={props.portalName}
              loginPage={loginPage}
              headerTextColor={headerTextColor}
            />
            {!isMobile && (
              <>
                <NavbarToggler onClick={toggle} className="ml-auto" />
                <Collapse isOpen={isOpen} navbar>
                  <UserNavDropdown
                    headerTextColor={headerTextColor}
                    enable={props.enable}
                  />
                </Collapse>
              </>
            )}
          </Container>
        </Navbar>
      )}
    </div>
  );
};

export default PublicNavigation;
