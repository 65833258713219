import React, { useState, useEffect, useRef } from "react";

import { Popover, PopoverBody } from "reactstrap";

// Components
import SidebarItem from "../../views/referral/inner-components/SidebarItem";
import Spinner from "../../components/base/Spinner";

// Icons
import { CrossIcon, ExclamationIcon } from "../../assets/img/icons";

import { FormatTimeStampDate } from "../../common/date";

const HubSpotTask = ({
  openHubSpotProspectCompanyModal,
  openHubSpotPartnerCompanyModal,
  referralDetails,
  hubSpotProspectCompanyDetail,
  hubSpotPartnerCompanyDetail,
  hubSpotDetail,
  isHubSpotLoading,
  enableHubSpotSalesRouting,
  enableDealOwnerNewAccount,
  openHubSpotOwnerSelectionModal,
  EnableDealForNewProspectCompany,
  EnableDealOwnerForExistingProspectCompany,
  hubspotUserList
}) => {
  const spinnerStyle = `margin: 0;  position: absolute;  top: 50%;  left: 35%;`;

  const [partnerPopOverOpen, setPartnerPopOverOpen] = useState(false);
  const [prospectPopOverOpen, setProspectPopOverOpen] = useState(false);
  const [dealPopoverOpen, setDealPopoverOpen] = useState(false);
  const [openDealPopoverOpen, setOpenDealPopoverOpen] = useState(false);
  const [DealOwnerPopoverOpen, setOwnerDealPopoverOpen] = useState(false);

  const partnerCompanyToggle = () => setPartnerPopOverOpen(!partnerPopOverOpen);

  const prospectCompanyToggle = () =>
    setProspectPopOverOpen(!prospectPopOverOpen);

  const dealToggle = () => setDealPopoverOpen(!dealPopoverOpen);

  const openDealToggle = () => setOpenDealPopoverOpen(!openDealPopoverOpen);

  const toggleDealOwner = () => setOwnerDealPopoverOpen(!DealOwnerPopoverOpen);

  const partnerHubSpotCompanyId =
    hubSpotPartnerCompanyDetail && hubSpotPartnerCompanyDetail.id;

  const prospectHubSpotCompanyId =
    hubSpotProspectCompanyDetail && hubSpotProspectCompanyDetail.id;

  const ownerHubSpotId = referralDetails && referralDetails.owner_hubspot_id;

  const dealDetail =
    hubSpotDetail &&
    Array.isArray(hubSpotDetail.deals) &&
    hubSpotDetail.deals[0];

  let selectedOwner;
  // Get Selected Owner
  if (hubspotUserList && ownerHubSpotId) {
    selectedOwner =
      hubspotUserList &&
      hubspotUserList.find(x => x.hubspot_id === ownerHubSpotId);
  }

  const popOverRef = useRef();
  const handleClickOutside = event => {
    if (popOverRef.current && !popOverRef.current.contains(event.target)) {
      setPartnerPopOverOpen(false);
      setProspectPopOverOpen(false);
      setDealPopoverOpen(false);
      setOpenDealPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <SidebarItem>
        <div className="salesforce_tasks">
          <p className="font-weight-bold mb-1">HubSpot Tasks</p>
          <p style={{ fontSize: 12, color: "#989898" }}>
            The following tasks must be completed.
          </p>
          {isHubSpotLoading ? (
            <div
              style={{
                position: "relative",
                height: "120px",
                marginRight: 60
              }}
            >
              <Spinner spinnerStyle={spinnerStyle} />
            </div>
          ) : (
            <div>
              <div className="d-flex align-items-start justify-content-start">
                {hubSpotPartnerCompanyDetail ? (
                  <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                ) : referralDetails &&
                  referralDetails.partner_hubspot_company_id ? (
                  <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                ) : (
                  <>
                    <div className="mt-2 pl-1">
                      <ExclamationIcon />
                    </div>
                  </>
                )}
                <div>
                  <b
                    className={
                      partnerHubSpotCompanyId
                        ? `alert-salesforce`
                        : referralDetails &&
                          referralDetails.partner_hubspot_company_id
                        ? `alert-salesforce`
                        : `alert-referral-danger`
                    }
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 16,
                      fontWeight: "bold",
                      margin: "0 10px 2px"
                    }}
                  >
                    Partner Company Inspection
                  </b>

                  <div className="d-flex justify-content-start">
                    {partnerHubSpotCompanyId ? (
                      <p id="ViewPartnerCompany" className="cursor-pointer">
                        <span
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            partnerCompanyToggle();
                          }}
                          style={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            fontSize: 13,
                            paddingLeft: "10px"
                          }}
                        >
                          View Company
                        </span>
                      </p>
                    ) : (
                      <span
                        className="cursor-pointer"
                        style={{
                          color: "#3498DB",
                          textDecoration: "underline",
                          fontSize: 13,
                          paddingLeft: "10px"
                        }}
                        onClick={() => openHubSpotPartnerCompanyModal()}
                      >
                        Check Company
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-start justify-content-start"
                style={{ paddingTop: "20px" }}
              >
                {prospectHubSpotCompanyId ? (
                  <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                ) : referralDetails.prospect_hubspot_company_id ? (
                  <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                ) : (
                  <>
                    <div className="mt-2 pl-1">
                      <ExclamationIcon />
                    </div>
                  </>
                )}
                <div>
                  <b
                    className={
                      prospectHubSpotCompanyId
                        ? `alert-salesforce`
                        : referralDetails.prospect_hubspot_company_id
                        ? `alert-salesforce`
                        : `alert-referral-danger`
                    }
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 16,
                      fontWeight: "bold",
                      margin: "0 10px 2px"
                    }}
                  >
                    Prospect Company Inspection
                  </b>

                  <div className="d-flex justify-content-start">
                    {prospectHubSpotCompanyId ? (
                      <p id="ViewProspectCompany" className="cursor-pointer">
                        <span
                          href=""
                          onClick={e => {
                            e.preventDefault();
                            prospectCompanyToggle();
                          }}
                          style={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            fontSize: 13,
                            paddingLeft: "10px"
                          }}
                        >
                          View Company
                        </span>
                      </p>
                    ) : (
                      <span
                        className="cursor-pointer"
                        style={{
                          color: "#3498DB",
                          textDecoration: "underline",
                          fontSize: 13,
                          paddingLeft: "10px"
                        }}
                        onClick={() => openHubSpotProspectCompanyModal()}
                      >
                        Check Company
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-start justify-content-start"
                style={{ paddingTop: "20px" }}
              >
                {prospectHubSpotCompanyId ? (
                  referralDetails.hubspot_deal_id ? (
                    <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                  ) : hubSpotDetail && hubSpotDetail.dealCount > 0 ? (
                    <>
                      <div className="mt-2 pl-1">
                        <ExclamationIcon />
                      </div>
                    </>
                  ) : (
                    <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                  )
                ) : (
                  <>
                    <div className="mt-2 pl-1">
                      <ExclamationIcon />
                    </div>
                  </>
                )}
                <div>
                  <b
                    className={
                      prospectHubSpotCompanyId
                        ? referralDetails.hubspot_deal_id
                          ? `alert-salesforce`
                          : hubSpotDetail && hubSpotDetail.dealCount > 0
                          ? `alert-referral-danger`
                          : `alert-salesforce`
                        : `alert-referral-danger`
                    }
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 16,
                      fontWeight: "bold",
                      margin: "0 10px 2px"
                    }}
                  >
                    Check Deals
                  </b>

                  <div className="d-flex justify-content-start">
                    {(!prospectHubSpotCompanyId || !partnerHubSpotCompanyId) &&
                    hubSpotDetail &&
                    hubSpotDetail.dealCount == 0 ? (
                      <span
                        style={{
                          fontSize: 13,
                          paddingLeft: "10px"
                        }}
                      >
                        No Deals Found
                      </span>
                    ) : referralDetails.hubspot_deal_id ? (
                      <p id="viewHubSpotDeal" className="cursor-pointer">
                        <a
                          style={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            fontSize: 13,
                            paddingLeft: "10px"
                          }}
                        >
                          View Deals
                        </a>
                      </p>
                    ) : hubSpotDetail && hubSpotDetail.dealCount > 0 ? (
                      <p id="viewHubSpotOpenDeal" className="cursor-pointer">
                        <a
                          style={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            fontSize: 13,
                            paddingLeft: "10px"
                          }}
                        >
                          View Open Deals
                        </a>
                      </p>
                    ) : (
                      <p style={{ fontSize: 14, paddingLeft: "10px" }}>
                        No Deals Found
                      </p>
                    )}
                  </div>
                </div>
              </div>

              {enableHubSpotSalesRouting == "true" && ownerHubSpotId ? (
                <div
                  className="d-flex align-items-start justify-content-start"
                  style={{ paddingTop: "20px" }}
                >
                  <i
                    className={`fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce`}
                  ></i>
                  <div>
                    <b
                      className={`alert-salesforce`}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        fontSize: 16,
                        fontWeight: "bold",
                        margin: "0 10px 2px"
                      }}
                    >
                      Owner Inspection
                    </b>

                    <div className="d-flex justify-content-start">
                      <>
                        <p id="viewDealOwner" className="cursor-pointer">
                          <a
                            style={{
                              color: "#3498DB",
                              textDecoration: "underline",
                              fontSize: 13,
                              paddingLeft: "10px"
                            }}
                          >
                            View Owner
                          </a>
                        </p>
                        <p
                          style={{
                            paddingLeft: 5
                          }}
                        ></p>
                        {EnableDealForNewProspectCompany == "false" && (
                          <>
                            {"/"}
                            <p className="cursor-pointer">
                              <a
                                style={{
                                  color: "#3498DB",
                                  textDecoration: "underline",
                                  fontSize: 13,
                                  paddingLeft: 5
                                }}
                                onClick={() => openHubSpotOwnerSelectionModal()}
                              >
                                Change Owner
                              </a>
                            </p>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              ) : (
                enableHubSpotSalesRouting == "true" &&
                EnableDealForNewProspectCompany == "false" && (
                  <div
                    className="d-flex align-items-start justify-content-start"
                    style={{ paddingTop: "20px" }}
                  >
                    <>
                      <div className="mt-2 pl-1">
                        <ExclamationIcon />
                      </div>
                    </>
                    <div>
                      <b
                        className={`alert-referral-danger`}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          fontSize: 16,
                          fontWeight: "bold",
                          margin: "0 10px 2px"
                        }}
                      >
                        Owner Inspection
                      </b>

                      <div className="d-flex justify-content-start">
                        <span
                          className="cursor-pointer"
                          style={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            fontSize: 13,
                            paddingLeft: "10px"
                          }}
                          onClick={() => openHubSpotOwnerSelectionModal()}
                        >
                          Select Owner
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )}

              {partnerHubSpotCompanyId && (
                <Popover
                  placement="left"
                  isOpen={partnerPopOverOpen}
                  target="ViewPartnerCompany"
                  toggle={partnerCompanyToggle}
                  className="account-in-salesforce"
                >
                  <PopoverBody>
                    <div className="p-2" ref={popOverRef}>
                      <div className="row">
                        <div className="col-md-12 d-flex bd-highlight mb-2">
                          <div
                            className="ml-auto cursor-pointer"
                            onClick={() => partnerCompanyToggle()}
                          >
                            <CrossIcon />
                          </div>
                        </div>

                        {hubSpotPartnerCompanyDetail && (
                          <div className="col-md-12 mb-2">
                            <a
                              target="_blank"
                              href={`https://app.hubspot.com/contacts/${hubSpotPartnerCompanyDetail.portalId}/company/${hubSpotPartnerCompanyDetail.id}`}
                              className="font-weight-bold"
                              rel="noopener noreferrer"
                            >
                              {hubSpotPartnerCompanyDetail.name}
                            </a>
                          </div>
                        )}

                        {hubSpotPartnerCompanyDetail &&
                          hubSpotPartnerCompanyDetail.type && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Type
                              </label>
                              <p className="font-weight-bold">
                                {hubSpotPartnerCompanyDetail.type}
                              </p>
                            </div>
                          )}

                        {hubSpotPartnerCompanyDetail &&
                          hubSpotPartnerCompanyDetail.description && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Description
                              </label>
                              <p className="font-weight-bold">
                                {hubSpotPartnerCompanyDetail.description}
                              </p>
                            </div>
                          )}
                      </div>
                    </div>
                  </PopoverBody>
                </Popover>
              )}
              {prospectHubSpotCompanyId && hubSpotProspectCompanyDetail && (
                <Popover
                  placement="left"
                  isOpen={prospectPopOverOpen}
                  target="ViewProspectCompany"
                  toggle={prospectCompanyToggle}
                  className="account-in-salesforce"
                >
                  <PopoverBody>
                    <div className="p-2" ref={popOverRef}>
                      <div className="row">
                        <div className="col-md-12 d-flex bd-highlight mb-2">
                          <div
                            className="ml-auto cursor-pointer"
                            onClick={() => prospectCompanyToggle()}
                          >
                            <CrossIcon />
                          </div>
                        </div>

                        {hubSpotProspectCompanyDetail && (
                          <div className="col-md-12 mb-2">
                            <a
                              target="_blank"
                              href={`https://app.hubspot.com/contacts/${hubSpotProspectCompanyDetail.portalId}/company/${hubSpotProspectCompanyDetail.id}`}
                              className="font-weight-bold"
                              rel="noopener noreferrer"
                            >
                              {hubSpotProspectCompanyDetail.name}
                            </a>
                          </div>
                        )}

                        {hubSpotProspectCompanyDetail &&
                          hubSpotProspectCompanyDetail.type && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Type
                              </label>
                              <p className="font-weight-bold">
                                {hubSpotProspectCompanyDetail.type}
                              </p>
                            </div>
                          )}

                        {hubSpotProspectCompanyDetail &&
                          hubSpotProspectCompanyDetail.description && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Description
                              </label>
                              <p className="font-weight-bold">
                                {hubSpotProspectCompanyDetail.description}
                              </p>
                            </div>
                          )}
                      </div>
                    </div>
                  </PopoverBody>
                </Popover>
              )}

              {prospectHubSpotCompanyId &&
                partnerHubSpotCompanyId &&
                referralDetails.hubspot_deal_id && (
                  <Popover
                    placement="left"
                    isOpen={dealPopoverOpen}
                    target="viewHubSpotDeal"
                    toggle={dealToggle}
                    className="account-in-salesforce"
                  >
                    <PopoverBody>
                      <div className="p-2" ref={popOverRef}>
                        <div className="row">
                          <div className="col-md-12 d-flex bd-highlight mb-2">
                            <div
                              className="ml-auto cursor-pointer"
                              onClick={() => dealToggle()}
                            >
                              <CrossIcon />
                            </div>
                          </div>

                          {hubSpotProspectCompanyDetail && dealDetail && (
                            <div className="col-md-12 mb-2">
                              <a
                                target="_blank"
                                href={`https://app.hubspot.com/contacts/${dealDetail.portalId}/deal/${dealDetail.dealId}`}
                                className="font-weight-bold"
                                rel="noopener noreferrer"
                              >
                                {hubSpotProspectCompanyDetail.name}
                              </a>
                            </div>
                          )}

                          {hubSpotDetail && hubSpotDetail.dealCount >= 1 && (
                            <div className="">
                              <hr
                                style={{
                                  width: "100%",
                                  borderColor: "#f3f3f4"
                                }}
                              />

                              <div className="d-flex bd-highlight mb-3">
                                <div className="mr-auto p-2 font-weight-bold">
                                  Deals{" "}
                                  {hubSpotDetail.dealCount
                                    ? `(${hubSpotDetail.dealCount})`
                                    : ""}
                                </div>
                                {hubSpotDetail.dealCount > 1 && (
                                  <div className="p-2 font-weight-bold">
                                    <a
                                      target="_blank"
                                      href={`https://app.hubspot.com/contacts/${hubSpotDetail.deals[0].portalId}/objects/0-3/views/all/list`}
                                      className="font-weight-bold"
                                      rel="noopener noreferrer"
                                    >
                                      View All
                                    </a>
                                  </div>
                                )}
                              </div>

                              <div
                                className="overflow-auto"
                                style={{ height: "14rem" }}
                              >
                                {hubSpotDetail.deals &&
                                  hubSpotDetail.deals.length > 0 &&
                                  hubSpotDetail.deals.map(data => {
                                    return (
                                      <div className="opportunity-list p-3 col-12  border-bottom">
                                        <div className="mb-2">
                                          <a
                                            target="_blank"
                                            href={`https://app.hubspot.com/contacts/${data.portalId}/deal/${data.dealId}`}
                                            className="font-weight-bold"
                                            rel="noopener noreferrer"
                                          >
                                            {data.dealname}
                                          </a>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="text-grayed font-weight-bold font-size-13">
                                              Stage
                                            </label>
                                            <p className="font-weight-bold">
                                              {(data && data.dealStage) || ""}
                                            </p>
                                          </div>

                                          <div className="col-md-6">
                                            <label className="text-grayed font-weight-bold font-size-13">
                                              Amount
                                            </label>
                                            <p className="font-weight-bold">
                                              {(data && data.amount) || ""}
                                            </p>
                                          </div>

                                          <div className="col-md-6">
                                            <label className="text-grayed font-weight-bold font-size-13">
                                              Close Date
                                            </label>
                                            <p className="font-weight-bold">
                                              {(data &&
                                                data.closedate &&
                                                FormatTimeStampDate(
                                                  parseInt(data.closedate)
                                                )) ||
                                                ""}
                                            </p>
                                          </div>

                                          <div className="col-md-6">
                                            <label className="text-grayed font-weight-bold font-size-13">
                                              Created Date
                                            </label>
                                            <p className="font-weight-bold">
                                              {(data &&
                                                data.createdate &&
                                                FormatTimeStampDate(
                                                  parseInt(data.createdate)
                                                )) ||
                                                ""}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </PopoverBody>
                  </Popover>
                )}

              {referralDetails &&
                !referralDetails.hubspot_deal_id &&
                hubSpotDetail &&
                hubSpotDetail.dealCount > 0 && (
                  <Popover
                    placement="left"
                    isOpen={openDealPopoverOpen}
                    target="viewHubSpotOpenDeal"
                    toggle={openDealToggle}
                    className="account-in-salesforce"
                  >
                    <PopoverBody>
                      <div className="p-2" ref={popOverRef}>
                        <div className="row">
                          <div className="col-md-12 d-flex bd-highlight mb-2">
                            <div
                              className="ml-auto cursor-pointer"
                              onClick={() => openDealToggle()}
                            >
                              <CrossIcon />
                            </div>
                          </div>

                          {hubSpotProspectCompanyDetail && dealDetail && (
                            <div className="col-md-12 mb-2">
                              <a
                                target="_blank"
                                className="font-weight-bold"
                                href={`https://app.hubspot.com/contacts/${dealDetail.portalId}/deal/${dealDetail.dealId}`}
                                rel="noopener noreferrer"
                              >
                                {hubSpotProspectCompanyDetail.name}
                              </a>
                            </div>
                          )}
                          {hubSpotDetail && hubSpotDetail.dealCount >= 1 && (
                            <div className="">
                              <hr
                                style={{
                                  width: "100%",
                                  borderColor: "#f3f3f4"
                                }}
                              />

                              <div className="d-flex bd-highlight mb-3">
                                <div className="mr-auto p-2 font-weight-bold">
                                  Deals{" "}
                                  {hubSpotDetail.dealCount
                                    ? `(${hubSpotDetail.dealCount})`
                                    : ""}
                                </div>
                                {hubSpotDetail.dealCount > 1 && (
                                  <div className="p-2 font-weight-bold">
                                    <a
                                      target="_blank"
                                      href={`https://app.hubspot.com/contacts/${hubSpotDetail.deals[0].portalId}/objects/0-3/views/all/list`}
                                      className="font-weight-bold"
                                      rel="noopener noreferrer"
                                    >
                                      View All
                                    </a>
                                  </div>
                                )}
                              </div>

                              <div
                                className="overflow-auto"
                                style={{ height: "14rem" }}
                              >
                                {hubSpotDetail.deals &&
                                  hubSpotDetail.deals.length > 0 &&
                                  hubSpotDetail.deals.map(data => {
                                    return (
                                      <div className="opportunity-list p-3 col-12  border-bottom">
                                        <div className="mb-2">
                                          <a
                                            target="_blank"
                                            href={`https://app.hubspot.com/contacts/${data.portalId}/deal/${data.dealId}`}
                                            className="font-weight-bold"
                                            rel="noopener noreferrer"
                                          >
                                            {data.dealname}
                                          </a>
                                        </div>

                                        <div className="row">
                                          <div className="col-md-6">
                                            <label className="text-grayed font-weight-bold font-size-13">
                                              Stage
                                            </label>
                                            <p className="font-weight-bold">
                                              {(data && data.dealStage) || ""}
                                            </p>
                                          </div>

                                          <div className="col-md-6">
                                            <label className="text-grayed font-weight-bold font-size-13">
                                              Amount
                                            </label>
                                            <p className="font-weight-bold">
                                              {(data && data.amount) || ""}
                                            </p>
                                          </div>

                                          <div className="col-md-6">
                                            <label className="text-grayed font-weight-bold font-size-13">
                                              Close Date
                                            </label>
                                            <p className="font-weight-bold">
                                              {(data &&
                                                data.closedate &&
                                                FormatTimeStampDate(
                                                  parseInt(data.closedate)
                                                )) ||
                                                ""}
                                            </p>
                                          </div>

                                          <div className="col-md-6">
                                            <label className="text-grayed font-weight-bold font-size-13">
                                              Created Date
                                            </label>
                                            <p className="font-weight-bold">
                                              {(data &&
                                                data.createdate &&
                                                FormatTimeStampDate(
                                                  parseInt(data.createdate)
                                                )) ||
                                                ""}
                                            </p>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </PopoverBody>
                  </Popover>
                )}

              {referralDetails &&
                enableHubSpotSalesRouting == "true" &&
                ownerHubSpotId && (
                  <Popover
                    placement="left"
                    isOpen={DealOwnerPopoverOpen}
                    target="viewDealOwner"
                    toggle={toggleDealOwner}
                    className="account-in-salesforce"
                  >
                    <PopoverBody>
                      <div className="p-2" ref={popOverRef}>
                        <div className="row">
                          <div className="col-md-12 d-flex bd-highlight mb-2">
                            <div
                              className="ml-auto cursor-pointer"
                              onClick={() => toggleDealOwner()}
                            >
                              <CrossIcon />
                            </div>
                          </div>

                          {selectedOwner &&
                            (selectedOwner.first_name ||
                              selectedOwner.last_name) && (
                              <div className="col-md-12 mb-2">
                                {hubSpotPartnerCompanyDetail ? (
                                  <a
                                    target="_blank"
                                    href={`https://app.hubspot.com/settings/${hubSpotPartnerCompanyDetail.portalId}/users/editUser/${ownerHubSpotId}`}
                                    className="font-weight-bold"
                                    rel="noopener noreferrer"
                                  >
                                    {selectedOwner && selectedOwner.first_name
                                      ? selectedOwner.first_name
                                      : "" + " " + selectedOwner.last_name
                                      ? selectedOwner.last_name
                                      : ""}
                                  </a>
                                ) : selectedOwner &&
                                  selectedOwner.first_name ? (
                                  selectedOwner.first_name
                                ) : "" + " " + selectedOwner.last_name ? (
                                  selectedOwner.last_name
                                ) : (
                                  ""
                                )}
                              </div>
                            )}

                          {selectedOwner &&
                            (selectedOwner.first_name ||
                              selectedOwner.last_name) && (
                              <div className="col-md-12">
                                <label className="text-grayed font-weight-bold font-size-13">
                                  Name
                                </label>
                                <p className="font-weight-bold">
                                  {selectedOwner &&
                                    selectedOwner.first_name +
                                      " " +
                                      selectedOwner.last_name}{" "}
                                </p>
                              </div>
                            )}

                          {selectedOwner && selectedOwner.email && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Email
                              </label>
                              <p className="font-weight-bold">
                                {(selectedOwner && selectedOwner.email) || ""}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </PopoverBody>
                  </Popover>
                )}
            </div>
          )}
        </div>
      </SidebarItem>
      <SidebarItem />
    </>
  );
};

export default HubSpotTask;
