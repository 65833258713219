import React, { Component } from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import Text from "../../../../components/base/Text";
import SaveButton from "../../../../components/base/SaveButton";
import Radio from "../../../../components/base/Radio";
import Hint from "../../../../components/base/Hint";

// Constants
import {
  TRAINING_SYSTEM_TYPE_BUILD_IN_TRAINING,
  TRAINING_SYSTEM_TYPE_THIRD_PARTY_TRAINING
} from "../../../../training/Constants";
import {
  TRAINING_SOURCE_THIRD_PARTY_TRAINING_SYSTEM,
  TRAINING_THIRD_PARTY_CTA_TEXT,
  TRAINING_THIRD_PARTY_LOGO,
  TRAINING_THIRD_PARTY_SYSTEM_NAME,
  TRAINING_THIRD_PARTY_URL
} from "../../../../setting/Constants";
// Helper
import { getSettingMediaUrl } from "../../../../lib/helper";
import { toast } from "react-toastify";
export default class ResourcesTrainingTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      fileTypeError: false,
      thirdPartyLogoUrl:
        props.adminSettings &&
        props.adminSettings.training_third_party_logo &&
        props.settings
          ? getSettingMediaUrl(
              props.settings,
              props.adminSettings.training_third_party_logo
            )
          : "",
      training_source:
        props.adminSettings &&
        props.adminSettings.training_source ===
          TRAINING_SOURCE_THIRD_PARTY_TRAINING_SYSTEM
          ? TRAINING_SYSTEM_TYPE_THIRD_PARTY_TRAINING
          : TRAINING_SYSTEM_TYPE_BUILD_IN_TRAINING
    };
  }
  // Select the Third Party Logo
  onSelectFile = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          this.setState({
            fileTypeError: false
          });
          this.thirdPartyLogoUpload(e);
          break;

        default:
          this.setState({
            fileTypeError: true
          });
          break;
      }
    }
  };
  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Third Party Logo Remove
  handleLogoRemove = () => {
    toast.success("Logo Removed Successfully");
    this.setState({ thirdPartyLogoUrl: "", files: "", thirdPartyLogo: "" });
  };
  // Third Party Logo Upload
  thirdPartyLogoUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          thirdPartyLogoUrl: URL.createObjectURL(files),
          thirdPartyLogo: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, TRAINING_THIRD_PARTY_LOGO)
      );
  };
  handleResourceSource = value => {
    const training_source = value.target.value;

    this.setState(prevState => ({
      ...prevState.checkBox,
      training_source
    }));
  };

  submit = values => {
    const data = new FormData();
    if (values.training_third_party_system_name !== undefined) {
      data.append(
        "training_third_party_system_name",
        values.training_third_party_system_name
          ? values.training_third_party_system_name
          : ""
      );
    }
    if (values.training_third_party_subtext !== undefined) {
      data.append(
        "training_third_party_subtext",
        values.training_third_party_subtext
          ? values.training_third_party_subtext
          : ""
      );
    }
    if (this.state.thirdPartyLogoUrl !== undefined) {
      data.append(
        "training_third_party_logo_url",
        this.state.thirdPartyLogoUrl ? this.state.thirdPartyLogoUrl : ""
      );
    }
    if (this.state.thirdPartyLogo !== undefined) {
      data.append(
        "training_third_party_logo",
        this.state.training_third_party_logo
          ? this.state.training_third_party_logo
          : ""
      );
    }
    if (values.training_third_party_url !== undefined) {
      data.append(
        "training_third_party_url",
        values.training_third_party_url ? values.training_third_party_url : ""
      );
    }
    data.append(
      "training_source",
      this.state.training_source === TRAINING_SOURCE_THIRD_PARTY_TRAINING_SYSTEM
        ? TRAINING_SYSTEM_TYPE_THIRD_PARTY_TRAINING
        : TRAINING_SYSTEM_TYPE_BUILD_IN_TRAINING
    );
    this.props.saveSettings(data);
  };

  render() {
    const { adminSettings } = this.props;
    const {
      training_source,
      training_third_party_system_name,
      training_third_party_subtext,
      training_third_party_logo,
      training_third_party_url
    } = adminSettings;

    const resource_source = adminSettings && adminSettings.training_source;

    const initialValues = {
      training_third_party_system_name: training_third_party_system_name,
      training_third_party_subtext: training_third_party_subtext,
      training_third_party_logo: training_third_party_logo,
      training_third_party_url: training_third_party_url
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          this.submit(values);
        }}
      >
        <>
          <DefaultContent>
            <div className="form-wrapper">
              <label className="font-weight-bold">Resource Source</label>
              <div className="field-wrapper ml-4 mb-0">
                <Radio
                  name="training_source"
                  size="large"
                  options={[
                    TRAINING_SYSTEM_TYPE_BUILD_IN_TRAINING,
                    TRAINING_SYSTEM_TYPE_THIRD_PARTY_TRAINING
                  ]}
                  onChange={e => {
                    this.handleResourceSource(e);
                  }}
                  defaultValue={
                    resource_source
                      ? training_source
                      : TRAINING_SYSTEM_TYPE_BUILD_IN_TRAINING
                  }
                />
              </div>
              {this.state.training_source ===
                TRAINING_SYSTEM_TYPE_THIRD_PARTY_TRAINING && (
                <>
                  <Text
                    name={TRAINING_THIRD_PARTY_SYSTEM_NAME}
                    label="Third Party Training System Name"
                    placeholder="System Name"
                    fontBolded
                    required={true}
                    error=""
                  />
                  <div className="flex-column">
                    <Text
                      name={TRAINING_THIRD_PARTY_CTA_TEXT}
                      label="Call-to-Action Subtext"
                      placeholder="Subtext"
                      fontBolded
                      required={true}
                      error=""
                    />
                  </div>
                  <label className="font-weight-bold">
                    Upload Third Party logo
                  </label>
                  <div className="row">
                    <div className="flex-column w-50 col-7">
                      <div className="upload-field d-inline-block">
                        <input
                          name={TRAINING_THIRD_PARTY_LOGO}
                          className="form-control d-none"
                          type="file"
                          id="bannerImage"
                          onChange={e => {
                            this.onSelectFile(e);
                            e.target.value = null;
                          }}
                          accept="image/png,image/gif,image/jpeg"
                        />
                        <span className="profilePicOverlay d-block ">
                          <label
                            htmlFor="bannerImage"
                            className="profile-img-sm mb-0"
                          >
                            <span className="banner-image-upload-link">
                              Upload Third Party Logo
                            </span>
                          </label>
                        </span>
                      </div>
                      {/* Remove image */}
                      {this.state.thirdPartyLogoUrl && (
                        <span
                          className="banner-image-upload-link text-danger ml-md-1"
                          onClick={this.handleLogoRemove}
                        >
                          (Remove)
                        </span>
                      )}
                      <div className="">
                        <Hint hintText="We recommend using 400 X 300 image" />
                      </div>
                    </div>
                    <div className="ml-auto pull-right col">
                      {this.state.thirdPartyLogoUrl ? (
                        <img
                          src={this.state.thirdPartyLogoUrl}
                          style={{ width: "100px" }}
                          className="ml-auto pull-right col ml-auto"
                          alt="Avatar"
                        />
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                  <div className="mt-4">
                    <Text
                      name={TRAINING_THIRD_PARTY_URL}
                      label="Paste Link URL for the Third Party Training System"
                      placeholder="Webpage URL"
                      fontBolded
                      required={true}
                      error=""
                    />
                  </div>
                </>
              )}
            </div>
          </DefaultContent>
          <div className="btn-wrapper pull-left">
            <SaveButton />
          </div>
        </>
      </Form>
    );
  }
}
