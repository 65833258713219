import React from "react";
import { Link } from "react-router-dom";

// Components
import AlertWarning from "../../../../components/base/Warning";

// Contexts
import KickoffProvider from "../../../../context/kickoffContext/KickoffProvider";
import KickoffContext from "../../../../context/kickoffContext/KickoffContext";

// Helper
import { isExpert, isCustomer } from "../../../../lib/helper";

import { useSelector } from "react-redux";

const ConfirmKickoffTime = props => {
  const expertId =
    props.project.users && props.project.users.length > 0
      ? props.project.users[0].id
      : "";

  const projectId = props.project.id ? props.project.id : "";

  const curentUser = useSelector(state => state.user);

  const selectTimeButton = () => (
    <Link to={`/project/${projectId}`}>
      <button
        type="button"
        className="btn font-weight-bold outline-bold text-white p-0 plain-btn"
      >
        Select a Time
      </button>
    </Link>
  );
  return (
    <>
      {projectId && expertId && (
        <KickoffProvider projectId={projectId} expertId={expertId}>
          <KickoffContext.Consumer>
            {kickoffContext => (
              <>
                {((kickoffContext.kickoffData &&
                  kickoffContext.kickoffData.status !== "Completed" &&
                  !kickoffContext.kickoffData.selectedTimeId &&
                  isExpert(curentUser && curentUser.roleId)) ||
                  (!kickoffContext.kickoffData &&
                    isCustomer(curentUser && curentUser.roleId))) && (
                  <AlertWarning
                    messageTitle="You have not confirmed a time for a kickoff call!"
                    message="Please select a time slot ASAP."
                    onClick={() => alert()}
                    button={selectTimeButton()}
                  />
                )}
              </>
            )}
          </KickoffContext.Consumer>
        </KickoffProvider>
      )}
    </>
  );
};

export default ConfirmKickoffTime;
