import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
  Input
} from "reactstrap";
import { ChevronDown } from "../../assets/img/icons";

class CheckboxDropdownSelectGroup extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedItems: {},
      didMount: false,
      dropdownOpen: false
    };
  }

  toggle = () =>
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));

  handleChange = (event, groupValue) => {
    this.setState(
      {
        checkedItems: {
          ...this.state.checkedItems,
          [groupValue]: {
            ...this.state.checkedItems[groupValue],
            [event.target.name]: event.target.checked
          }
        }
      },
      () => {
        if (this.props.getSelectedFilters) {
          this.props.getSelectedFilters(this.state.checkedItems);
        }
      }
    );
  };

  filterCheckedItems = () =>
    Object.keys(this.state.checkedItems).filter(group =>
      Object.keys(group).filter(
        item => this.state.checkedItems[group][item] === true
      )
    );

  render() {
    const {
      buttonLabel,
      dropdownLabel,
      dropdownLinks,
      menuPosition,
      width,
      color,
      hideCaret,
      hideChevron
    } = this.props;

    const { dropdownOpen, checkedItems } = this.state;

    return (
      <div className="dropdown-wrapper select-dropdown align-items-center justify-content-end">
        {dropdownLabel && (
          <p className="font-weight-bold mb-0 mr-2">{dropdownLabel}</p>
        )}
        <ButtonDropdown isOpen={dropdownOpen} toggle={this.toggle}>
          <DropdownToggle
            color={color}
            className={`dropdown-toggle  ${hideCaret ? "hide-caret" : ""}`}
          >
            <div
              className="d-flex justify-content-between"
              style={{ width: width ? width : "auto" }}
            >
              <div>{buttonLabel}</div>
              <div>{!hideChevron && <ChevronDown />}</div>
            </div>
          </DropdownToggle>
          <DropdownMenu right={menuPosition}>
            {dropdownLinks.length > 0 &&
              dropdownLinks.map(
                (groupItem, i) =>
                  groupItem.options &&
                  groupItem.options.length > 0 && (
                    <div key={i}>
                      {groupItem.groupLabel && (
                        <div className="px-2 pt-1 font-weight-bold">
                          {groupItem.groupLabel}
                        </div>
                      )}
                      {groupItem.options.map(listItem => (
                        <div className="checkbox-wrapper" key={listItem.value}>
                          <label
                            className="pl-1"
                            style={{ width: width ? width : "auto" }}
                          >
                            <Input
                              type="checkbox"
                              name={listItem.value}
                              id={`${i}-${listItem.value}`}
                              onChange={e =>
                                this.handleChange(e, groupItem.groupValue)
                              }
                              checked={
                                checkedItems[groupItem.groupValue] &&
                                checkedItems[groupItem.groupValue][
                                  listItem.value
                                ]
                              }
                              value={
                                checkedItems[groupItem.groupValue] &&
                                checkedItems[groupItem.groupValue][
                                  listItem.value
                                ]
                              }
                            />
                            <span
                              className={`checkbox-placeholder ${checkedItems[
                                groupItem.groupValue
                              ] &&
                                checkedItems[groupItem.groupValue][
                                  listItem.value
                                ] &&
                                "active"}`}
                            />
                            <span className="pt-1">{listItem.label}</span>
                          </label>
                        </div>
                      ))}
                      {dropdownLinks.length > 0 &&
                        i < dropdownLinks.length - 1 && (
                          <div className="py-2">
                            <div className="border-bottom"></div>
                          </div>
                        )}
                    </div>
                  )
              )}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

CheckboxDropdownSelectGroup.defaultProps = {
  buttonLabel: "Button Label",
  color: "",
  hideCaret: false,
  dropdownLinks: [
    { label: "Link 1", value: "link1" },
    { label: "Link 2", value: "link2" },
    { label: "Link 3", value: "link3" }
  ],
  menuPosition: true
};

CheckboxDropdownSelectGroup.propTypes = {
  buttonLabel: PropTypes.string,
  color: PropTypes.string,
  hideCaret: PropTypes.bool,
  dropdownLinks: PropTypes.array,
  menuPosition: PropTypes.bool,
  onClick: PropTypes.func
};

export default CheckboxDropdownSelectGroup;
