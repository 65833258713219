import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";
import classnames from "classnames";

// Constants
import {
  TAB_BASICS,
  TAB_PERMISSIONS,
  TAB_LESSON_MODULES,
  COURSE_DRAFT,
  TAB_PARTNERS
} from "../../course/Constants";

import {
  TAG_TYPE_TRAINING_CATEGORY,
  TAG_TYPE_COURSE_LESSON_QUIZ_TYPE
} from "../../tagType/Constants";

// Components
import GridCard from "../../components/base/GridCard";
import TabsAndContent from "../../components/base/TabsAndContent";

import SelectDropdown from "../../components/base/SelectDropdown";
import PageTitle from "../../components/base/PageTitle";
import toast from "../../components/base/Toast";
import DeleteModal from "../../components/base/DeleteModal";

// Assets
import { ChevronRight, MaximizeAltIcon } from "../../assets/img/icons";

import {
  COURSE_PUBLISHED,
  COURSE_PERMISSION_ALL_PARTNERS,
  COURSE_SINGLE_COURSE,
  COURSE_PROGRESSIVE_COURSE
} from "../../course/Constants";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

import MyContext from "../../context/MyContext";
import EditBasicTab from "./tab/EditBasicTab";
import EditLessonTab from "./tab/EditLessonTab";
import EditLessonModuleModal from "./tab/EditLessonModulesModal";
import { isBadRequest } from "../../common/http";
import PartnerSelector from "../../../src/partner/component/PartnerSelector";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import Table from "./inner-component/table";
import { connect } from "react-redux";
import SaveButton from "../../components/base/SaveButton";

class CourseFormFields extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      title: "",
      courseId: "",
      description: "",
      courseFile: "",
      bannerImageUrl: "",
      lessonTitle: "",
      textElement: "",
      multimediaUrl: "",
      quizTitle: "",
      courseCategory: [],
      courseRequirement: "",
      quizTypeCategory: [],
      isExists: this.props.questions && this.props.questions.length,
      questions: this.props.questions,
      edit: "",
      loading: false,
      activeTab: TAB_BASICS,
      values: [],
      openDeleteModal: false,
      fileTypeError: false,
      checkBox: {
        partnerTier:
          (props.checkboxFieldValue && props.checkboxFieldValue.partnerTier) ||
          [],
        partnerType:
          (props.checkboxFieldValue && props.checkboxFieldValue.partnerType) ||
          [],
        sharingPermission: props.sharingPermission
          ? props.sharingPermission
          : COURSE_PERMISSION_ALL_PARTNERS,
        courseRequirement: props.courseToUnlock
          ? COURSE_PROGRESSIVE_COURSE
          : COURSE_SINGLE_COURSE
      },
      cardTitleShow: false,
      isCollapsed: {}
    };
    this._handleChange = this._handleChange.bind(this);
    this._courseId = this._courseId.bind(this);
    this.toggle = this.toggle.bind(this);
  }
  _courseId = e => {
    const target = e.target;
    const courseId = target.value;
    this.setState({ courseId });
  };
  // Get Course Catergory Tag Values
  getTagValues = async courseTagType => {
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${courseTagType}`
      );
      const results = response.data.data;
      // Check the tag type skills
      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });
        this.setState({
          courseCategory: tagList
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };
  // Get Quiz Tag Values
  getQuizTagValues = async quizTagType => {
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${quizTagType}`
      );
      const results = response.data.data;
      // Check the tag type skills
      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });
        this.setState({
          quizTypeCategory: tagList
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  componentDidMount() {
    this.getTagValues(TAG_TYPE_TRAINING_CATEGORY);
    this.getQuizTagValues(TAG_TYPE_COURSE_LESSON_QUIZ_TYPE);
  }

  // Handle OnChange resource title field
  _handleChange = e => {
    const target = e.target;
    const title = target.value;
    this.setState({ title: title, cardTitleShow: true });
  };

  requirement = e => {
    const { courseRequirement } = this.props;
    this.setState({ courseRequirement: e ? e.courseRequirement : "" }, () => {
      courseRequirement && courseRequirement(this.state.courseRequirement);
    });
  };

  // Toggle Tab
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab }, () => {
        window.localStorage.setItem("courseActiveTab", this.state.activeTab);
      });
    }
  };

  // Select the uploaded image
  onSelectFile = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          this.setState({
            fileTypeError: false
          });
          this.props.bannerImageUpload(e);
          break;

        default:
          this.setState({
            fileTypeError: true
          });
          break;
      }
    }
  };
  handlePartnerTierCheckbox = e => {
    const { getCheckboxFieldValue, updatePermission } = this.props;
    const newSelection = e.target.value;
    let newSelectionArray;
    const { partnerType } = this.state.checkBox;

    if (this.state.checkBox.partnerTier.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.partnerTier.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [...this.state.checkBox.partnerTier, newSelection];
    }

    this.setState(
      prevState => ({
        checkBox: {
          ...prevState.checkBox,
          partnerTier: newSelectionArray
        }
      }),
      () => {
        getCheckboxFieldValue && getCheckboxFieldValue(this.state.checkBox);
        updatePermission &&
          updatePermission(
            partnerType.length === 0 && newSelectionArray.length === 0
              ? true
              : false
          );
      }
    );
  };

  handlePartnerTypeCheckbox = e => {
    const { getCheckboxFieldValue, updatePermission } = this.props;
    const newSelection = e.target.value;
    let newSelectionArray;
    const { partnerTier } = this.state.checkBox;

    if (this.state.checkBox.partnerType.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.partnerType.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [...this.state.checkBox.partnerType, newSelection];
    }

    this.setState(
      prevState => ({
        checkBox: {
          ...prevState.checkBox,
          partnerType: newSelectionArray
        }
      }),
      () => {
        getCheckboxFieldValue && getCheckboxFieldValue(this.state.checkBox);
      }
    );
    updatePermission &&
      updatePermission(
        partnerTier.length === 0 && newSelectionArray.length === 0
          ? true
          : false
      );
  };

  getCheckboxOptions = list => {
    const options = [];
    if (list && list.length) {
      list.forEach(option => {
        options.push(option.name);
      });
    }

    return options;
  };

  handlePartnerPermission = e => {
    const permission = e;
    const { getCheckboxFieldValue } = this.props;

    this.setState(
      prevState => ({
        checkBox: {
          ...prevState.checkBox,
          sharingPermission: permission,
          partnerTier: [],
          partnerType: []
        }
      }),
      () => {
        getCheckboxFieldValue && getCheckboxFieldValue(this.state.checkBox);
      }
    );
  };

  //Delete course
  handleDelete = courseId => {
    return apiClient
      .delete(`${endpoints().courseAPI}/${courseId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        const status = this.props.status;
        const url = `/course?section=${status}`;
        this.props.history.push(url);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  handleCourseSource = e => {
    const courseRequirement = e.target.value;
    const { getCheckboxFieldValue, clearCourseFile } = this.props;

    this.setState(
      prevState => ({
        checkBox: {
          ...prevState.checkBox,
          courseRequirement
        }
      }),
      () => {
        getCheckboxFieldValue && getCheckboxFieldValue(this.state.checkBox);
        clearCourseFile && clearCourseFile();
      }
    );
  };
  _handleDeleteCourseFile = () => {
    if (this.props.course && this.props.course.id)
      return apiClient
        .delete(`${endpoints().courseAPI}/file/${this.props.course.id}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          this.props.getBasicsDetails();
          toast.success(successMessage);
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
  };
  handleChangePartnerSelection = value => {
    const { updatePermission } = this.props;
    updatePermission &&
      updatePermission(value && value !== null ? false : true);
  };

  addField = event => {
    event.preventDefault();
    const id = Math.floor(1000 + Math.random() * 9000);
    const values = [...this.state.values];

    values.push({ description: null, id });
    this.setState({ values });
  };

  // Handle remove
  handleRemove = i => {
    const values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  };

  // Handle change
  handleChange = (i, event) => {
    const values = [...this.state.values];
    values[i].description = event.target.value;
    this.setState({ values });
  };
  courseId = value => {
    this.setState({ courseId: value });
  };
  handleAdminAction = value => {
    switch (value) {
      case "Publish":
        return this.props.publishFun(COURSE_PUBLISHED);
      case "Unpublish":
        return this.props.UnpublishFun(COURSE_DRAFT);
      case "Delete":
        return this.setState({ openDeleteModal: true });
      default:
        return "";
    }
  };
  toggleCollapse = dataListId => {
    this.setState(prevState => {
      const updatedCollapseStates = { ...prevState.isCollapsed };
      updatedCollapseStates[dataListId] = !updatedCollapseStates[dataListId];
      return { isCollapsed: updatedCollapseStates };
    });
  };
  // Render the form fields
  render() {
    const { quizTypeCategory, courseCategory } = this.state;
    const courseData = this.props.data;
    const {
      status,
      courseFile,
      initialValues,
      lessonData,
      getLessonList,
      currentLessonData
    } = this.props;

    // Admin Action Dropdown
    const adminActionOptions =
      status === COURSE_DRAFT
        ? [
            {
              value: "Publish",
              label: "Publish"
            },
            {
              value: "Delete",
              label: "Delete"
            }
          ]
        : [
            {
              value: "Unpublish",
              label: "Unpublish"
            },
            {
              value: "Delete",
              label: "Delete"
            }
          ];
    const data = {
      title:
        !this.state.title && initialValues && initialValues.title
          ? this.state.cardTitleShow
            ? this.state.title
            : initialValues.title
          : this.state.title,
      description:
        !this.state.description && courseData && courseData.description
          ? courseData.description
          : this.state.description,
      bannerImageUrl:
        !this.props.bannerImageUrl && courseData && courseData.bannerImageUrl
          ? courseData.bannerImageUrl
          : this.props.bannerImageUrl,
      courseFile:
        !this.props.courseFile && courseData && courseData.courseFile
          ? courseData.courseFile
          : this.props.courseFile,
      category:
        initialValues && initialValues.category && initialValues.category.value
          ? initialValues.category.value
          : initialValues &&
            initialValues.courseCategory &&
            initialValues.courseCategory.value
          ? initialValues.courseCategory.value
          : "",
      courseRequirement: this.state.courseRequirement
        ? this.state.courseRequirement
        : "",
      lessonTitle:
        !this.state.lessonTitle && courseData && courseData.lessonTitle
          ? courseData.lessonTitle
          : this.state.lessonTitle,
      textElement:
        !this.state.textElement && courseData && courseData.textElement
          ? courseData.textElement
          : this.state.textElement,
      multimediaUrl:
        !this.props.multimediaUrl && courseData && courseData.multimediaUrl
          ? courseData.multimediaUrl
          : this.props.multimediaUrl,
      quizTitle:
        !this.state.quizTitle && courseData && courseData.quizTitle
          ? courseData.quizTitle
          : this.state.quizTitle,
      quizTypeCategory:
        !this.state.quizTypeCategory &&
        courseData &&
        courseData.quizTypeCategory
          ? courseData.quizTypeCategory.value
          : this.state.quizTypeCategory,
      quizTypeCategory:
        courseData && courseData.quizTypeCategory
          ? courseData.quizTypeCategory
          : "",
      partnerTier:
        courseData && courseData.partnerTier ? courseData.partnerTier : "",
      certificateName:
        courseData && courseData.certificate_name
          ? courseData.certificate_name
          : ""
    };

    const playbookCardPlaceHolder =
      courseData && courseData.description ? false : true;

    const { activeTab, openDeleteModal, courseId } = this.state;
    const {
      handleImageRemove,
      bannerImageUrl,
      bannerImageUpload,
      setCurrentLessonData,
      toggleEditLessonModal,
      toggleAddLessonModal,
      count,
      _submit,
      isOpen,
      _lessonSubmit,
      getQuestionValue,
      questions,
      onMediaFileChange,
      getBasicsDetails,
      handleCourseFile,
      course,
      permissionRequired,
      certificateList,
      handleChange,
      certificateImageUrl,
      certificateImageUpload,
      handleCertificateImageRemove,
      reviewerList
    } = this.props;
    return (
      <>
        <DeleteModal
          isOpen={openDeleteModal}
          title="Delete Course "
          toggle={() => {
            this.setState({ openDeleteModal: false });
          }}
          label={course.title}
          deleteFunction={() => this.handleDelete(this.props.match.params.id)}
        />
        <div>
          <MyContext.Consumer>
            {context => (
              <>
                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) && (
                  <div className="d-flex justify-content-between flex-sm-row flex-column">
                    <div className="d-flex align-items-center mb-3">
                      <a
                        className="cursor-pointer text-dark"
                        onClick={() => {
                          this.props.history.push(
                            `/course?section=${COURSE_PUBLISHED}`
                          );
                        }}
                      >
                        Partner Training
                      </a>
                      <span
                        className="text-dark d-inline-flex align-items-center"
                        style={{ width: "15px" }}
                      >
                        <ChevronRight />
                      </span>
                      <span
                        onClick={e => e.preventDefault()}
                        className={[
                          "text-dark",
                          "font-weight-bold",
                          "d-inline-flex",
                          "align-items-center",
                          "project-name",
                          "w-320"
                        ].join(" ")}
                      >
                        <span className="ellipsis">
                          Partner Training Details
                        </span>
                      </span>
                    </div>
                    <div
                      className={`page-heading d-flex ${
                        context.isMobile
                          ? "justify-content-start"
                          : "justify-content-between"
                      }`}
                    >
                      {(isSuperAdmin(this.props.roleId) ||
                        isCompanyAdmin(this.props.roleId)) && (
                        <div className="d-flex align-items-center">
                          {activeTab === TAB_PERMISSIONS && <SaveButton />}
                          <SelectDropdown
                            buttonLabel="Admin Actions"
                            dropdownLinks={adminActionOptions}
                            color={"gray"}
                            hideCaret
                            selectName={"admin_action"}
                            handleChange={this.handleAdminAction}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {!context.isMobile && (
                  <PageTitle label={`Partner Training ${activeTab}`} />
                )}
                <Nav tabs className="admin-tabs">
                  {courseData ? "" : this.toggle(this.props.currentTab + "")}
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_BASICS
                      })}
                      onClick={() => {
                        this.toggle(TAB_BASICS);
                        this.props.backButton(TAB_BASICS);
                      }}
                    >
                      Basics
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_LESSON_MODULES
                      })}
                      onClick={() => {
                        this.toggle(TAB_LESSON_MODULES);
                        this.props.backButton(TAB_LESSON_MODULES);
                      }}
                    >
                      Lesson Modules
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_PERMISSIONS
                      })}
                      onClick={() => {
                        this.toggle(TAB_PERMISSIONS);
                        this.props.backButton(TAB_PERMISSIONS);
                      }}
                    >
                      Permissions
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_PARTNERS
                      })}
                      onClick={() => {
                        this.toggle(TAB_PARTNERS);
                        this.props.backButton(TAB_PARTNERS);
                      }}
                    >
                      {TAB_PARTNERS}
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabsAndContent>
                  {/*added this condition below to support multiple contents inside a tab pane with white background*/}
                  <div
                    id="userActivity"
                    className={`tab-content-wrapper d-flex`}
                  >
                    <TabContent
                      activeTab={activeTab}
                      className={
                        activeTab === TAB_PERMISSIONS ? "selected-experts" : ""
                      }
                    >
                      <TabPane tabId={TAB_BASICS}>
                        <EditBasicTab
                          reviewerList={reviewerList}
                          course={this.props.course}
                          getCheckboxFieldValue={e => this.requirement(e)}
                          handleImageRemove={handleImageRemove}
                          bannerImageUrl={bannerImageUrl}
                          bannerImageUpload={bannerImageUpload}
                          handleCertificateImageRemove={
                            handleCertificateImageRemove
                          }
                          certificateImageUrl={certificateImageUrl}
                          certificateImageUpload={certificateImageUpload}
                          courseId={e => this.courseId(e)}
                          values={initialValues}
                          data={courseData}
                          handleChange={this._handleChange.bind(this)}
                          courseCategory={courseCategory}
                          handleCourseFile={handleCourseFile}
                          courseFile={courseFile}
                          handleDeleteCourseFile={this._handleDeleteCourseFile}
                        />
                      </TabPane>

                      {/* Lesson Modules */}
                      <TabPane tabId={TAB_LESSON_MODULES}>
                        {isOpen === false && (
                          <EditLessonTab
                            getLessonList={getLessonList}
                            toggleAddLessonModal={toggleAddLessonModal}
                            setCurrentLessonData={setCurrentLessonData}
                            toggleEditLessonModal={toggleEditLessonModal}
                            count={count}
                            initialValue={initialValues}
                            lessonData={lessonData}
                            questionData={currentLessonData}
                            data={courseData}
                            courseId={courseId}
                            quizTypeCategory={quizTypeCategory}
                            _submit={e => _submit(e)}
                          />
                        )}
                        {isOpen === true && (
                          <>
                            <EditLessonModuleModal
                              getBasicsDetails={getBasicsDetails}
                              getLessonList={getLessonList}
                              initialValues={initialValues}
                              lessonData={lessonData}
                              currentLessonData={currentLessonData}
                              isOpen={isOpen}
                              toggle={() => {
                                toggleEditLessonModal();
                                setCurrentLessonData("");
                              }}
                              _lessonSubmit={e => _lessonSubmit(e)}
                              quizTypeCategory={this.state.quizTypeCategory}
                              questionValues={getQuestionValue}
                              questions={questions}
                              getDefaultQuestionValue={
                                this.getDefaultQuestionValue
                              }
                              onMediaFileChange={onMediaFileChange}
                              mediaFiles={
                                currentLessonData.lessonMedia &&
                                currentLessonData.lessonMedia.length > 0
                                  ? currentLessonData.lessonMedia
                                  : []
                              }
                            />
                          </>
                        )}
                      </TabPane>

                      {/* Permission */}
                      <TabPane tabId={TAB_PERMISSIONS}>
                        <PartnerSelector
                          showPartnerTier
                          showPartnerType
                          showAllPermission
                          tierTitle="Partner Tier"
                          typeTitle="Partner Type"
                          name="sharedPartner"
                          partnerPermissionDefaultValue={
                            this.state.checkBox.sharingPermission
                          }
                          dropdownName="sharedPartner"
                          dropdownLabel="Share Course With"
                          tierSelectedOptions={this.state.checkBox.partnerTier}
                          handleTierChange={this.handlePartnerTierCheckbox}
                          typeSelectedOptions={this.state.checkBox.partnerType}
                          handleTypeChange={this.handlePartnerTypeCheckbox}
                          handlePartnerChange={e => {
                            this.handleChangePartnerSelection(e);
                          }}
                          handlePermissionOnchange={e => {
                            this.handlePartnerPermission(e);
                          }}
                          permissionRequired={permissionRequired}
                        />
                      </TabPane>
                      <TabPane tabId={TAB_PARTNERS} className="w-100">
                        <div className="mb-5">
                          <ReduxTable
                            apiURL={`${endpoints().courseAPI}`}
                            params={{ id: this.props.match.params.id }}
                            message="You can start by inviting Partners."
                            history={this.props.history}
                            paramsToUrl={true}
                            disableColumnSort
                          >
                            <ReduxColumn
                              minWidth="170px"
                              isClickable
                              className="text-center  "
                              field="companyName"
                              sortBy="company_name"
                              renderField={row => (
                                <div>
                                  {row && row.partner_id && (
                                    <Table
                                      data={row}
                                      toggleCollapse={this.toggleCollapse}
                                      isCollapsed={this.state.isCollapsed}
                                    />
                                  )}
                                </div>
                              )}
                            >
                              Partner Name
                            </ReduxColumn>
                          </ReduxTable>
                        </div>
                      </TabPane>
                    </TabContent>

                    {activeTab === TAB_BASICS && (
                      <div className="preview-wrapper">
                        <label className="font-weight-bold pl-3">Preview</label>
                        <GridCard
                          showOption={
                            isSuperAdmin(this.props.roleId) ||
                            isCompanyAdmin(this.props.roleId) ||
                            isCompanyManager(this.props.roleId)
                          }
                          isPreview={this.props.detailLink ? "false" : "true"}
                          link={this.props.detailLink}
                          openLinkInNewTab={true}
                          boxShadow={"true"}
                          placeHolder={playbookCardPlaceHolder}
                          data={data}
                          files={this.props.files || ""}
                          bannerImageUrl={this.props.bannerImageUrl}
                          tag={"div"}
                          fullWidth={false}
                        />
                        {this.props.detailLink && (
                          <div className="preview-full mt-4 d-flex align-items-center justify-content-center">
                            <MaximizeAltIcon />
                            <a
                              href={`${this.props.detailLink}?redirect=${window.location.pathname}`}
                              target="_blank"
                              className={["text-underline"].join(" ")}
                              rel="noopener noreferrer"
                            >
                              View Full Preview
                            </a>
                          </div>
                        )}
                      </div>
                    )}
                  </div>
                </TabsAndContent>
              </>
            )}
          </MyContext.Consumer>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(CourseFormFields);
