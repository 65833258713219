import React, { useState } from "react";

import Form from "../../components/base/Form";

import TextArea from "../../components/base/TextArea";

import DefaultContent from "../../components/content/DefaultContent";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

const TorchliteJSSnippet = () => {
  const [
    torchliteJSSnippetCopySuccess,

    setTorchliteJSSnippetCopySuccess
  ] = useState("Copy");

  function copyText(e) {
    navigator.clipboard.writeText(snippet);

    e.target.focus();

    setTorchliteJSSnippetCopySuccess("Copied!");
  }

  const snippet = `
  <script> 
    function setCookie(name,value,days) {
      var expires=""; 
      if (days){
        var date = new Date();
        date.setTime(date.getTime()+()days*24*60*60*1000));
        expires="; expires=" + date.toUTCString();
      }
      document.cookie = name + "=" + (value || "") + expires + ";path=/";
    }
   urlParams = new URLSearchParams(window.location.search);
    partnerkey = urlParams.get('partnerkey');
    uniqueclicktrackid = urlParams.get('uniqueclicktrackid');
    redirect = urlParams.get('redirect');
    if(partnerkey) {
      setCookie("partnerkey", partnerkey, 30);
    }
   if(uniqueclicktrackid) {
      setCookie("uniqueclicktrackid", uniqueclicktrackid, 30);
    }
  </script>`;

  return (
    <>
      <Form>
        <div className="form-wrapper">
          <DefaultContent title={"TorchliteJS Snippet"}>
            <p className={["text-inline-grayed"].join("")}>
              TorchliteJS is implemented on your website and used to track
              {Terminology.get(SystemName.REFERRAL)} signups. It makes sure that
              referred signups are attributed to the correct partner.
            </p>

            <div className="row">
              <div className="col-10">
                <TextArea
                  name="torchliteJSSnippet"
                  rows="23"
                  defaultValue={snippet}
                  disabled={true}
                />
              </div>

              <div>
                <button
                  className={[
                    "btn",

                    `${
                      torchliteJSSnippetCopySuccess !== "Copied!"
                        ? "btn-primary"
                        : "btn-success"
                    }`,

                    "mb-2 ml-2"
                  ].join(" ")}
                  type="button"
                  onClick={copyText}
                >
                  <span>{torchliteJSSnippetCopySuccess}</span>
                </button>
              </div>
            </div>
          </DefaultContent>
        </div>
      </Form>
    </>
  );
};

export default TorchliteJSSnippet;
