import React from "react";

// Icons
import { LogoIcon } from "../../assets/img/icons";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";

import AuthButtons, {
  GoogleButton,
  LinkedInButton,
  OfficeButton,
  SalesforceButton
} from "../../components/authentication/AuthButtons";

import toast from "../../components/base/Toast";

// Helper
import { setCookie, clearCookie } from "../../lib/helper";
import {
  COMPANY_SIGNUP_BASE_URL_PATH,
  COMPANY_SIGNUP_GETTING_STARTED_TEXT
} from "../../Company/Constants";
import { isBadRequest } from "../../common/http";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

/**
 * Remove local storage when user signup with social auth
 */
function removeOAuthLocalStorage() {
  clearCookie("googleAuthToken");
  clearCookie("office365AuthToken");
  clearCookie("linkedinAuthToken");
  clearCookie("salesforceAuthToken");
}

class GetStarted extends React.Component {
  // Signup with Google OAuth
  signUpWithGoogle(googleToken, callback, actions) {
    removeOAuthLocalStorage();
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    apiClient
      .get(
        `${endpoints().userAPI}/google/auth/details?googleToken=${googleToken}`
      )
      .then(response => {
        setCookie(
          "googleAuthToken",
          response.data.authToken ? response.data.authToken : ""
        );

        actions && actions.setUserDetails(response.data);
      })
      // .then(() => callback())
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Signup with Microsoft Office365
  signUpWithOffice365(office365Token, callback, actions) {
    removeOAuthLocalStorage();
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    apiClient
      .get(
        `${
          endpoints().userAPI
        }/office365/auth/details?office365Token=${office365Token}`
      )
      .then(response => {
        setCookie(
          "office365AuthToken",
          response.data.authToken ? response.data.authToken : ""
        );

        actions && actions.setUserDetails(response.data);
      })
      // .then(() => callback())
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Signup with Linkedin
  signUpWithLinkedin(linkedInAccessToken, callback, actions) {
    removeOAuthLocalStorage();
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    apiClient
      .get(
        `${
          endpoints().userAPI
        }/linkedin/auth/details?linkedInAccessToken=${linkedInAccessToken}`
      )
      .then(response => {
        setCookie(
          "linkedinAuthToken",
          response.data.authToken ? response.data.authToken : ""
        );

        actions && actions.setUserDetails(response.data);
      })
      // .then(() => callback())
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Signup with Salesforce
  signUpWithSalesforce(salesforceToken, callback, actions) {
    removeOAuthLocalStorage();
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    apiClient
      .get(
        `${
          endpoints().userAPI
        }/salesforce/auth/details?salesforceToken=${salesforceToken}`
      )
      .then(response => {
        setCookie(
          "salesforceAuthToken",
          response.data.authToken ? response.data.authToken : ""
        );

        actions && actions.setUserDetails(response.data);
      })
      // .then(() => callback())
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    const {
      enableSalesforceLogin,
      enableOffice365Login,
      enableGoogleLogin,
      enableLinkedInLogin,
      linkedInClientId,
      linkedInRedirectUri,
      googleClientId,
      salesforceLoginClientId,
      salesforceLoginRedirectUrl
    } = this.props;

    if (this.props.currentStep !== this.props.step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <LogoIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-3">Welcome!</h2>
        </div>

        {/* Content */}
        <div className="content lh-2 h5 font-weight-light">
          <p>
            The Partner Portal is where your Company can share resources, track
            {Terminology.get(SystemName.REFERRALS)}, and manage your Company
            Partners.
          </p>

          <p>
            Let's get your account setup so we can update Partner Portal for
            your Company!
          </p>
        </div>
        <AuthButtons>
          {enableSalesforceLogin === "true" && (
            <SalesforceButton
              redirectUrl={`/${COMPANY_SIGNUP_BASE_URL_PATH}?section=${COMPANY_SIGNUP_GETTING_STARTED_TEXT}`}
              signUpWithSalesforce={this.signUpWithSalesforce}
              eventKey="salesforce-signup"
              actions={this.props}
              salesforceLoginClientId={salesforceLoginClientId}
              salesforceLoginRedirectUrl={salesforceLoginRedirectUrl}
            />
          )}

          {enableGoogleLogin === "true" && (
            <GoogleButton
              redirectUrl={`/${COMPANY_SIGNUP_BASE_URL_PATH}?section=${COMPANY_SIGNUP_GETTING_STARTED_TEXT}`}
              signUpWithGoogle={this.signUpWithGoogle}
              eventKey="google-signup"
              actions={this.props}
              googleClientId={googleClientId}
            />
          )}

          {enableOffice365Login === "true" && (
            <OfficeButton
              redirectUrl={`/${COMPANY_SIGNUP_BASE_URL_PATH}?section=${COMPANY_SIGNUP_GETTING_STARTED_TEXT}`}
              signUpWithOffice365={this.signUpWithOffice365}
              eventKey="microsoft-office265-signup"
              actions={this.props}
            />
          )}

          {enableLinkedInLogin === "true" && (
            <LinkedInButton
              redirectUrl={`/${COMPANY_SIGNUP_BASE_URL_PATH}?section=${COMPANY_SIGNUP_GETTING_STARTED_TEXT}`}
              signUpWithLinkedin={this.signUpWithLinkedin}
              eventKey="linkedin-signup"
              actions={this.props}
              linkedInClientId={linkedInClientId}
              linkedInRedirectUri={linkedInRedirectUri}
            />
          )}
        </AuthButtons>
        <div className="btn-wrapper mt-4-5">
          <button type="reset" className="resigter-with-my-email">
            Register with my Email Address
          </button>
        </div>
        {/* /.btn-wrapper */}
      </div>
    );
  }
}

export default GetStarted;
