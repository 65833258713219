import React, { useState, useEffect } from "react";

// Components
import Form from "./base/Form";
import SingleCheckbox from "../views/adminSettings/inner-component/Checkbox";
import Multiselect from "../components/base/Multiselect";

// Constants
import * as country from "../Country/Constants";

// Constants
import {
  TAG_TYPE_MARKETING_CHANNEL,
  TAG_TYPE_CRM_MARKETING,
  TAG_TYPE_ABM_EXPERTISE,
  TAG_TYPE_INDUSTRIES,
  TAG_TYPE_PRODUCTS,
  TAG_TYPE_REGION
} from "../tagType/Constants";

// API Client
import { apiClient } from "../apiClient";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../configs";
import { isBadRequest } from "../common/http";

const PartnerFilter = props => {
  const [marketplaceChannels, setMarketplaceChannel] = useState([]);
  const [CRMMarketings, setCRMMarketing] = useState([]);
  const [abmExpertiseList, setABMExpertise] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [products, setProducts] = useState([]);
  const [regions, setRegions] = useState([]);

  // Get Tag List By Tag Type
  const getTagType = async tagType => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${tagType}`
      );
      const results = response.data.data;

      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          if (tag.isSearchable !== "No") {
            tagList.push({
              value: tag.id,
              label: tag.name
            });
          }
        });

        if (tagType === TAG_TYPE_MARKETING_CHANNEL) {
          setMarketplaceChannel(tagList);
        } else if (tagType === TAG_TYPE_CRM_MARKETING) {
          setCRMMarketing(tagList);
        } else if (tagType === TAG_TYPE_ABM_EXPERTISE) {
          setABMExpertise(tagList);
        } else if (tagType === TAG_TYPE_INDUSTRIES) {
          setIndustries(tagList);
        } else if (tagType === TAG_TYPE_PRODUCTS) {
          setProducts(tagList);
        } else if (tagType === TAG_TYPE_REGION) {
          setRegions(tagList);
        }
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    getTagType(TAG_TYPE_MARKETING_CHANNEL);
    getTagType(TAG_TYPE_CRM_MARKETING);
    getTagType(TAG_TYPE_ABM_EXPERTISE);
    getTagType(TAG_TYPE_INDUSTRIES);
    getTagType(TAG_TYPE_PRODUCTS);
    getTagType(TAG_TYPE_REGION);
  }, []);

  const defaultCountry = {
    label: country.UNITED_STATES,
    value: country.UNITED_STATES
  };

  return (
    <div className="card p-3 mb-3">
      <Form
        initialValues={{
          countryName: defaultCountry
        }}
        onSubmit={values => {
          const queryString = [];
          const tagcrmMarketing = [];
          const tagProducts = [];
          const tagAbmExpertise = [];
          const tagIndustries = [];
          const tagRegions = [];
          const tagMarketPlaceChannel = [];
          let partnerType = [],
            countryName = "";

          if (values && Object.keys(values).length > 0) {
            Object.keys(values).forEach(value => {
              let fieldKey = `${value}`.split("-");

              if (fieldKey[0] === "tier" && values[value] === true) {
                partnerType.push(fieldKey[1]);
              } else if (value === "regions" && values[value]) {
                let regions = values[value];
                regions.forEach(region => {
                  tagRegions.push(region.value);
                });
              } else if (value === "crmMarketing" && values[value]) {
                let crmMarketings = values[value];
                crmMarketings.forEach(crmMarketing => {
                  tagcrmMarketing.push(crmMarketing.value);
                });
              } else if (value === "industry" && values[value]) {
                let industries = values[value];
                industries.forEach(industry => {
                  tagIndustries.push(industry.value);
                });
              } else {
                let tagId = `${value}`.split("-")[1];
                let tagType = `${value}`.split("-")[0];
                if (values[value] === true) {
                  if (tagType === "marketplaceChannel") {
                    tagMarketPlaceChannel.push(`${tagId}`);
                  }
                  if (tagType === "products") {
                    tagProducts.push(`${tagId}`);
                  }
                  if (tagType === "abmExpertise") {
                    tagAbmExpertise.push(`${tagId}`);
                  }
                  queryString.push(`${tagId}`);
                }
              }
            });
          }

          props.filterPartnerList(
            queryString,
            tagIndustries,
            tagcrmMarketing,
            tagProducts,
            tagAbmExpertise,
            tagMarketPlaceChannel,
            tagRegions,
            partnerType,
            countryName
          );
        }}
      >
        <div className="row " style={{ borderBottom: "1px solid #f3f3f4" }}>
          <div className="col-12 col-md-5">
            <div className="row ">
              <div className="col-12 col-md-6">
                <p className="mb-3">
                  <b>Location</b>
                </p>
                <div className="form-wrapper">
                  <div className="field-wrapper">
                    <Multiselect
                      name="regions"
                      placeholder="Choose an option..."
                      options={regions}
                      error=""
                      required={true}
                    />
                  </div>
                </div>

                <p className="mb-3">
                  <b>Industry</b>
                </p>
                <div className="form-wrapper">
                  <div className="field-wrapper">
                    <Multiselect
                      name="industry"
                      placeholder="Choose an option..."
                      options={industries}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <p className="mb-3">
                  <b>Marketing/CRM Platform</b>
                </p>
                <div className="form-wrapper">
                  <div className="field-wrapper">
                    <Multiselect
                      name="crmMarketing"
                      placeholder="Choose an option..."
                      options={CRMMarketings}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 col-md-7">
            <div className="row ">
              <div className="col-12 col-md-4">
                <p className="mb-3">
                  <b>ABM Expertise</b>
                </p>
                <div className="form-wrapper">
                  {abmExpertiseList &&
                    abmExpertiseList.length > 0 &&
                    abmExpertiseList.map(ABMExpertise => {
                      return (
                        <div
                          className="field-wrapper"
                          key={`partner-abmExpertise-${ABMExpertise.value}`}
                        >
                          <SingleCheckbox
                            name={`abmExpertise-${ABMExpertise.value}`}
                            label={ABMExpertise.label}
                            className="accepted-terms  pb-0 mr-3"
                          />
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="col-12 col-md-4">
                <p className="mb-3">
                  <b>Marketing Services</b>
                </p>
                <div className="form-wrapper">
                  {marketplaceChannels &&
                    marketplaceChannels.length > 0 &&
                    marketplaceChannels.map(marketplaceChannel => {
                      return (
                        <div
                          className="field-wrapper"
                          key={`partner-marketplaceChannel-${marketplaceChannel.value}`}
                        >
                          <SingleCheckbox
                            name={`marketplaceChannel-${marketplaceChannel.value}`}
                            label={marketplaceChannel.label}
                            className="accepted-terms  pb-0 mr-3"
                          />
                        </div>
                      );
                    })}
                </div>
              </div>

              <div className="col-12 col-md-4">
                <p className="mb-3">
                  <b>Products Expertise</b>
                </p>
                <div className="form-wrapper">
                  {products &&
                    products.length > 0 &&
                    products.map(product => {
                      return (
                        <div
                          className="field-wrapper"
                          key={`partner-products-${product.value}`}
                        >
                          <SingleCheckbox
                            name={`products-${product.value}`}
                            label={product.label}
                            className="accepted-terms  pb-0 mr-3"
                          />
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="container-fluid">
            <div className="col-sm-12 text-center">
              <div className="btn-wrapper mt-3">
                <button
                  className="btn btn-outline-dark mr-4"
                  type="reset"
                  onClick={() => {
                    props.filterPartnerList();
                  }}
                >
                  Reset Filters
                </button>
                <button className="btn btn-primary" type="submit">
                  Apply Filters
                </button>
              </div>
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default PartnerFilter;
