import React from "react";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

// Page Components
import ContactFormFields from "./ContactFormFields";

// 404 Page Redirect
import Page404 from "../Page404";
import Spinner from "../../components/base/Spinner";
import { TAB_DETAIL } from "../../customer/Constants";

//Assets
import { ChevronRight } from "../../assets/img/icons";
import { isBadRequest } from "../../common/http";

import { connect } from "react-redux";

class UpdateContactForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      customerDetails: [],
      projects: [],
      teamMembers: [],
      totalCount: "",
      loading: false,
      isInvalidRequest: false,
      activeTab: TAB_DETAIL,
      sortByOptions: [
        {
          value: "createdAt",
          label: "Most Recent Activity"
        },
        {
          value: "updatedAt",
          label: "Date Modified"
        }
      ],
      selectedSortOption: "Most Recent Activity",
      currentSection: ""
    };
  }

  componentDidMount = () => {
    this.getContactFormDetails();
  };

  // Customer details api call by id
  getContactFormDetails = () => {
    return apiClient
      .get(`${endpoints().contactForm}/${this.props.match.params.id}`)
      .then(response => {
        this.setState({
          contactFormDetails: response.data.data,
          formId: response.data.dormId,
          formName: response.data.formName,
          loading: true
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return <Spinner />;
    }

    const { formName } = this.state;

    return (
      <>
        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) && (
          <div className="d-flex align-items-center mb-3">
            <a
              className="cursor-pointer"
              onClick={() => {
                this.props.history.push("/contact-forms");
              }}
            >
              Contact Forms
            </a>
            <span
              className="text-dark d-inline-flex align-items-center"
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <span
              onClick={e => e.preventDefault()}
              className={[
                "text-dark",
                "font-weight-bold",
                "d-inline-flex",
                "align-items-center",
                "project-name",
                "w-320"
              ].join(" ")}
            >
              <span className="ellipsis">{formName}</span>
            </span>
          </div>
        )}
        <div>
          <ContactFormFields
            history={this.props.history}
            match={this.props.match}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(UpdateContactForm);
