import React from "react";

// Components
import Select from "./base/Select";

// API Client
import { apiClient } from "../apiClient";

// Configs
import { endpoints, DEFAULT_API_KEY } from "../configs";
import { getCookie } from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";
import { UNITED_STATES } from "../Country/Constants";

class StateDropdown extends React.Component {
  // Show State options
  stateDropdownOptions = () => {
    const { selectedCountry } = this.props;

    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    let params = "";
    if (selectedCountry) {
      params += `?countryId=${selectedCountry}`;
    }

    let options = [];
    apiClient.get(`${endpoints().stateAPI}/search${params}`).then(response => {
      const states = response.data && response.data.data;
      if (states.length > 0) {
        states.forEach(state => {
          options.push({
            value: state.name,
            label: state.name
          });
        });
      }
    });
    return options;
  };

  componentDidMount() {
    this.stateDropdownOptions();
  }

  render() {
    const {
      name,
      label,
      placeholder,
      error,
      required,
      defaultValue,
      selectedCountryName,
      notRequired,
      id
    } = this.props;

    // let isRequired = selectedCountryName === UNITED_STATES ? true : false;
    // isRequired = notRequired ? false : isRequired;

    return (
      <Select
        id={id || name}
        name={name}
        label={label}
        options={selectedCountryName ? this.stateDropdownOptions() : []}
        placeholder={placeholder}
        error={error}
        defaultValue={defaultValue || ""}
        isSearchable={true}
        required={required}
      />
    );
  }
}

export default StateDropdown;
