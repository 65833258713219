import React, { useState } from "react";

// Inner Components
import ProjectCard from "./projectCard";

// Helper
import { isExpert } from "../../../../lib/helper";

import { useSelector } from "react-redux";

const ProjectsList = props => {
  const {
    status,
    data,
    activeProjectsList,
    activeProjectsCount,
    currentProjectType,
    projectDetailsUrlBasePath
  } = props;

  const currentUser = useSelector(state => state.user);

  const projectDetails = data;
  const { totalCount } = data;
  const totalProjectCount =
    isExpert(currentUser && currentUser.roleId) && activeProjectsList && !status
      ? activeProjectsCount
      : totalCount;

  const currentProjectList =
    totalProjectCount > 1 ? projectDetails : projectDetails;

  return (
    <div className="project-list">
      {currentProjectList &&
        currentProjectList.map(project => (
          <ProjectCard
            data={project}
            currentProjectType={currentProjectType}
            projectDetailsUrlBasePath={projectDetailsUrlBasePath}
          />
        ))}
    </div>
  );
};

export default ProjectsList;
