import React from "react";
import toast from "../../components/base/Toast";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import ReactCrop from "react-image-crop";
import { Redirect } from "react-router-dom";

// Components
import PageTitle from "../../components/base/PageTitle";
import Form from "../../components/base/Form";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import Avatar from "../../components/base/Avatar";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import { toString } from "../../lib/helper";
import { getCookie, setCookie, getParamsByName } from "../../lib/helper";

// Page components
import GeneralProfileFormFields from "./GeneralProfileFormFields";
import SecurityProfileFormFields from "./SecurityProfileFormFields";

//Constants
import { TAB_GENERAL, TAB_SECURITY } from "../../editMyProfile/Constants";
import { CrossIcon } from "../../assets/img/icons";
import { COOKIE_SESSION_TOKEN, COOKIE_USER_ID } from "../../lib/cookie";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUserDetail } from "../../actions/user";
import { isBadRequest } from "../../common/http";

class editMyGeneralProfile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userDetails: [],
      userId: "",
      isLoading: false,
      activeTab: TAB_GENERAL,
      file: "",
      src: null,
      crop: {
        unit: "%",
        width: 80,
        height: 80
      },
      zoom: 1,
      email: "",
      closePreviewImage: false,
      passwordErrors: {
        confirmPasswordError: "",
        currentPasswordError: ""
      }
    };
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  componentDidMount = () => {
    this.setActiveTab();
    this._getUserDetails();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { search } = prevProps.history.location;
    const params = new URLSearchParams(search);
    const section = params.get("section");
    // Set current active tab
    if (this.state.currentSection !== section) {
      this.setState({
        currentSection: section,
        activeTab: section
      });
    }
  }

  _submitGenral = values => {
    if (this._validateFields(values) === false) {
      return;
    }
    this._updateProfile(this._toArray(values));
  };
  _submitSecurity = values => {
    values.newPassword = values.newPassword ? values.newPassword : null;
    values.confirmPassword = values.confirmPassword
      ? values.confirmPassword
      : null;

    if (
      values.currentPassword &&
      values.newPassword &&
      values.confirmPassword &&
      values.newPassword !== values.confirmPassword
    ) {
      this.setState({
        passwordErrors: {
          confirmPasswordError: "Confirm password did not match"
        }
      });
      return false;
    } else if (!values.newPassword || !values.confirmPassword) {
      this.setState({
        passwordErrors: {
          confirmPasswordError: ""
        }
      });
    }

    this.setState({
      passwordErrors: {}
    });

    this._updateProfile(this._toArray(values));
  };

  // Update Expert Details
  _expertProfileUpdate(details) {
    const data = new FormData();
    data.append("file", details.file);
    data.append("userId", this.state.userDetails.id);

    return apiClient
      .put(`${endpoints().userAvatarUpdate}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);

        this.props.actions.fetchUserDetail();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }
  // delete user profile avatar
  _deleteUserProfile() {
    let id = this.state.userId;
    return apiClient
      .delete(`${endpoints().userAPI}/avatar/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);

        this.props.actions.fetchUserDetail();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  handleCroppedImageRemove = () => {
    this.state.userDetails.avatarUrl = "";
    this.state.userDetails.avatar = "";
    this.state.file = "";
    this.setState({
      croppedImageUrl: ""
    });
    this._deleteUserProfile({
      userId: this.state.userId
    });
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define user constants
    const email = values.email;
    const firstName = values.firstName;
    const lastName = values.lastName;

    if (!email || !firstName || !lastName) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    const firstName = toString(values.firstName);
    const lastName = toString(values.lastName);
    const email = toString(values.email);
    const avatarUrl = toString(values.avatarUrl);
    const avatar = toString(values.avatar);

    values.firstName = firstName;
    values.lastName = lastName;
    values.email = email;
    values.currentPassword = toString(values.currentPassword);
    values.newPassword = toString(values.newPassword);
    const data = {
      firstName: firstName,
      lastName: lastName,
      email: email,
      avatar: avatar,
      avatarUrl: avatarUrl,
      currentPassword: values.currentPassword,
      newPassword: values.newPassword,
      roleId: values.roleId
    };
    return data;
  }

  // User update API Call
  _updateProfile(data) {
    return apiClient
      .put(`${endpoints().userAPI}/`, data)
      .then(response => {
        let successMessage;
        let token;
        let userId;
        let role;
        if (response && response.data) {
          successMessage = response.data.message;
          token = response.data.token;
          userId = response.data.userId;
          role = response.data.role;
          toast.success(successMessage);
        }
        if (data.newPassword) {
          setCookie(COOKIE_SESSION_TOKEN, token);
          setCookie(COOKIE_USER_ID, userId);
          // return this.props.history.push("/dashboard");
        }

        this.props.actions.fetchUserDetail();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          this.setState({
            passwordErrors: {
              currentPasswordError: error.response.data.message
            }
          });
          console.error(errorMessage);
        }
      });
  }

  // User details api call by id
  _getUserDetails = () => {
    return apiClient
      .get(`${endpoints().userAPI}/`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({
          userDetails: response.data,
          isLoading: true,
          userId: response.data.id
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Toggle Tab
  toggle = tab => {
    this.setState({
      activeTab: tab
    });
  };

  _handleSectionChange(section) {
    this.props.history.push(`?section=${section}`);
  }

  setActiveTab = () => {
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const status = params.get("section");
    if (status === null) {
      this._handleSectionChange(TAB_GENERAL);
    } else {
      this.toggle(status);
    }
  };

  // Handle image upload
  handleImageUpload() {
    this.setState({ closePreviewImage: false });
    this._expertProfileUpdate({
      file: this.state.file,
      email: this.state.email
    });
  }

  // Select the uploaded image
  onSelectFile = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fSize = targetFile.files.item(0).size;
      const fileSize = Math.round(fSize / 1024);
      this.setState({
        fileSize
      });

      if (fileSize < 10240) {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          this.setState({ src: reader.result, closePreviewImage: true })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  // Load the initial crop screen
  onImageLoaded = image => {
    this.imageRef = image;
  };

  // Get crop value from the preview image
  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  // Move the crop the crop selector
  onCropChange = crop => {
    // You could also use percentCrop:
    this.setState({ crop });
  };

  // Make the crop the image in preview view
  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  // Get Cropped image size
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Convert URL to base64 data
    const base64Image = canvas.toDataURL("image/jpeg");

    if (base64Image) {
      this.setState({ file: base64Image, email: this.props.email });
    }

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");

      canvas.toBlob(file => {
        file.name = fileName;
        resolve(file);
      }, "image/jpeg");
    });
  }

  handlePasswordChange = e => {
    if (e.values && !e.values.confirmPassword) {
      this.setState({
        passwordErrors: {
          confirmPasswordError: ""
        }
      });
    }
  };

  render() {
    if (!this.state.isLoading) {
      return "";
    }

    const {
      firstName,
      lastName,
      email,
      avatarUrl,
      avatar
    } = this.state.userDetails;

    const showRemove = this.props.avatar ? this.props.avatar : avatar;
    const initialValues = {
      firstName,
      lastName,
      email,
      currentPassword: "",
      newPassword: "",
      confirmPassword: ""
    };
    const {
      activeTab,
      crop,
      croppedImageUrl,
      src,
      closePreviewImage,
      fileSize
    } = this.state;
    const searchStatus = getParamsByName("section");
    const isGeneral = searchStatus === TAB_GENERAL;
    const isSecurity = searchStatus === TAB_SECURITY;
    return (
      <div>
        <PageTitle label="Edit Profile" />

        <div className="row links text-center table-filter ml-1 mt-3 mr-3">
          {!activeTab && (
            <Redirect to={`/edit/profile?section=${TAB_GENERAL}`} />
          )}
          <Nav tabs className="setting-tabs">
            <NavItem>
              <NavLink
                className={classnames({
                  active: isGeneral
                })}
                onClick={() => {
                  this.toggle(TAB_GENERAL);
                  this._handleSectionChange(TAB_GENERAL);
                }}
              >
                General
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: isSecurity
                })}
                onClick={() => {
                  this.toggle(TAB_SECURITY);
                  this._handleSectionChange(TAB_SECURITY);
                }}
              >
                Security
              </NavLink>
            </NavItem>
          </Nav>
        </div>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TAB_GENERAL}>
            <Form
              initialValues={initialValues}
              onSubmit={values => {
                this._submitGenral(values);
              }}
            >
              <div className="card bg-white mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6 col-sm-12">
                      <GeneralProfileFormFields />
                    </div>
                    <div className="col-lg-6 col-sm-12 mb-3 center-block text-center">
                      <>
                        <div className="upload-image align-items-center justify-content-start mb-4">
                          <div className="row mb-2">
                            <div className="col">
                              <label
                                className="position-relative profilePicPreview mb-0 mr-4 flex-shrink-0 mx-auto"
                                htmlFor="profilePicture"
                              >
                                {croppedImageUrl ? (
                                  <img
                                    alt="profile"
                                    src={croppedImageUrl}
                                    className="profile-picture-preview rounded-circle"
                                  />
                                ) : (
                                  <Avatar
                                    firstName={firstName}
                                    lastName={lastName}
                                    url={avatarUrl}
                                    customSize={140}
                                    fontSize={32}
                                    className="avatar-placeholder"
                                  />
                                )}
                              </label>
                            </div>
                          </div>

                          <div className="row">
                            <div className="col">
                              <div className="upload-field">
                                <input
                                  name="file"
                                  className="form-control d-none"
                                  type="file"
                                  id="profilePicture"
                                  placeholder="Profile Photo"
                                  onChange={this.onSelectFile}
                                  accept="image/*"
                                />
                                <small
                                  className={`text-danger ${
                                    fileSize > 10240 ? "d-block" : "d-none"
                                  }`}
                                >
                                  Please select a file less than 10mb
                                </small>
                                <span className="profilePicOverlay d-block mt-2 mb-3">
                                  <label
                                    htmlFor="profilePicture"
                                    className="profile-img-sm mb-0"
                                  >
                                    <span className="btn btn-secondary cursor-pointer">
                                      Upload New Photo
                                    </span>
                                  </label>
                                </span>
                                {showRemove ? (
                                  <span
                                    className="banner-image-upload-link text-danger ml-md-1"
                                    onClick={this.handleCroppedImageRemove}
                                  >
                                    (Remove)
                                  </span>
                                ) : (
                                  ""
                                )}
                                <span className="info h7 d-block">
                                  Images should be at least 530x530px and not
                                  exceed 10MB.
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                        {closePreviewImage && src && (
                          <div className="crop-modal">
                            <div className="backdrop" />
                            <div className="crop-modal-body">
                              <div
                                className="crop-modal-close"
                                onClick={() =>
                                  this.setState({ closePreviewImage: false })
                                }
                              >
                                <CrossIcon />
                              </div>
                              <h5 className="mb-3">
                                <b>Crop image and upload</b>
                              </h5>
                              <ReactCrop
                                src={src}
                                crop={crop}
                                ruleOfThirds
                                onImageLoaded={this.onImageLoaded}
                                onComplete={this.onCropComplete}
                                onChange={this.onCropChange}
                              />

                              <div className="mt-4 d-flex justify-content-center">
                                <button
                                  className="btn btn-secondary"
                                  onClick={this.handleImageUpload}
                                >
                                  Upload Profile Photo
                                </button>
                              </div>
                            </div>
                          </div>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <CancelButton
                  onClick={() => this.props.history.push("/dashboard")}
                />
                <SaveButton />
              </div>
            </Form>
          </TabPane>
          <TabPane tabId={TAB_SECURITY}>
            <Form
              initialValues={initialValues}
              onSubmit={values => {
                this._submitSecurity(values);
              }}
            >
              <div className="card bg-white mb-3">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-7 col-sm-12">
                      <SecurityProfileFormFields
                        isSecurityTab={isSecurity}
                        handlePasswordChange={e => {
                          this.handlePasswordChange(e);
                        }}
                        errorMessage={this.state.passwordErrors}
                      />
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4">
                <CancelButton
                  onClick={() => this.props.history.push("/dashboard")}
                />
                <SaveButton />
              </div>
            </Form>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;
  const avatar = user && !user.isFetching ? user.avatar : "";
  const avatarUrl = user && !user.isFetching ? user.avatarUrl : "";
  return { state, avatar, avatarUrl };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchUserDetail }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(editMyGeneralProfile);
