import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link } from "react-router-dom";
import Avatar from "../../../components/base/Avatar";

const ProjectUserMessageModal = ({
  isOpen,
  toggle,
  message,
  expertDetails
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={["edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={toggle}>
        <div
          className={["d-flex", "flex-column", "align-items-center"].join(" ")}
        >
          <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
            Interested If...
          </h4>
        </div>
      </ModalHeader>
      <ModalBody className={["mb-4", "px-0"].join(" ")}>
        <div className="d-flex justify-content-center">
          <div className="field-icon">
            <Link
              target="_blank"
              to={`/expert-profile/${expertDetails.expertId}`}
            >
              <Avatar
                firstName={expertDetails.firstName}
                lastName={expertDetails.lastName}
                size="xs"
                fontSize={12}
                url={expertDetails.avatarUrl}
              />
            </Link>
          </div>
          <div className="expert-info ml-2 d-flex flex-column align-items-start">
            {expertDetails.jobTitle && (
              <p className="h7 text-truncate m-0" style={{ maxWidth: "130px" }}>
                {expertDetails.jobTitle}
              </p>
            )}
            <Link
              target="_blank"
              to={`/expert-profile/${expertDetails.expertId}`}
              className={"font-weight-bold h7 text-truncate text-dark"}
            >
              {expertDetails.name ? expertDetails.name : ""}
            </Link>
          </div>
        </div>
        <hr className="my-4" />
        <p className={["text-center", "font-weight-bold"].join(" ")}>
          This expert is only interested in this project if...
        </p>
        <p className={["text-center", "m-0"].join(" ")}>{`"${message}"`}</p>
      </ModalBody>
    </Modal>
  );
};

export default ProjectUserMessageModal;
