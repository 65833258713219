// Project user  status Start
export const PROJECT_USER_STATUS_SHORTLISTED = "Shortlisted";
export const PROJECT_USER_STATUS_NO_RESPONSE = "No Response";
export const PROJECT_USER_STATUS_INTERESTED = "Interested";
export const PROJECT_USER_STATUS_WAITING_FOR_APPROVAL = "Waiting for Approval";
export const PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE =
  "Waiting for Acceptance";
export const PROJECT_USER_STATUS_ACCEPTED = "Accepted";
export const PROJECT_USER_STATUS_NOT_INTERESTED = "Not Interested";
export const PROJECT_USER_STATUS_PENDING = "Pending";
export const PROJECT_USER_STATUS_APPROVED = "Approved";
export const PROJECT_USER_STATUS_DECLINED = "Declined";
// Project user status End

export const PROJECT_SORTBY_RECENT_ACTIVITY = "Most Recent Activity";
export const PROJECT_SORTBY_DATE_MODIFIED = "Date Modified";

export const PROJECT_SOURCE_EXPERT_MAX_DAYS = 5;

// Project Subscription Status Starts
export const PROJECT_SUBSCRIPTION_STATUS_ACTIVE = "Active";
export const PROJECT_SUBSCRIPTION_STATUS_PAUSED = "Paused";
export const PROJECT_SUBSCRIPTION_STATUS_CANCELLED = "Cancelled";
// Project Subscription Status Ends

// Project Tab Start
export const TAB_BASICS = "Basics";
export const TAB_DETAILS = "Details";
export const TAB_PRICING_AND_EXTRAS = "Tasks & Deliverables";
export const TAB_DELIVERABLES = "Deliverables";
// Project Tab End

// Project Pricing Type Start
export const PLAYBOOK_PRICING_TYPE_FIXED_PRICE = "Fixed Price";
export const PLAYBOOK_PRICING_TYPE_HOURLY_PAYMENT = "Hourly Payment";
export const PLAYBOOK_PRICING_TYPE_SUBSCRIPTION = "Subscription";
// Project Pricing Type End

// Project Extend Request Start
export const PROJECT_EXTEND_STATUS_PENDING = "Pending";
export const PROJECT_EXTEND_STATUS_ACCEPTED = "Accepted";
export const PROJECT_EXTEND_STATUS_DECLINED = "Declined";
// Project Pricing Type End

// Project Type Start
export const PROJECT_TYPE_PROJECT = "Project";
export const PROJECT_TYPE_PROJECTS_URL_PATH = "projects";
export const PROJECT_TYPE_PROJECT_URL_PATH = "project";
export const PROJECT_TYPE_CAMPAIGN = "Campaign";
export const PROJECT_TYPE_CAMPAIGNS_URL_PATH = "campaigns";
export const PROJECT_TYPE_CAMPAIGN_URL_PATH = "campaign";
// Project Type End
