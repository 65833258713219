import React from "react";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  REFERRAL_STATUS_NEW,
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_DECLINED,
  REFERRAL_STATUS_REQUESTED
} from "../../../referral/Constants";
import {
  PARTNER_DASHBOARD_MY_REFERRALS_COUNT,
  PARTNER_DASHBOARD_NULL_REFERRALS_COUNT
} from "../../../dashboard/Constants";
import { PARTNER_TYPE_SETTING_ENABLE_REFERRALS_DEAL_REGISTRATION } from "../../../partnerTypeSetting/Constants";
import { getKeyValueByObject } from "../../../lib/helper";

// Icons
import { DealRegistrationIcon, ChevronRight } from "../../../assets/img/icons";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

import { IsPartnerTypeSettingEnabled } from "../../../lib/PartnerSetting";

import { ALLOW_REFERRAL_SUBMISSION } from "../../adminSettings/tabs/Partner/PartnerTypes/Constants";

class StyledReferralSubmitCard extends React.Component {
  render() {
    const {
      label,
      partnerSettings,
      redirectUrl,
      subTitle,
      subText,
      partnerReferrals,
      partnerReferralsCount,
      enable
    } = this.props;

    const partnerTypeSettingEnableReferrals = partnerSettings
      ? IsPartnerTypeSettingEnabled(
          partnerSettings,
          PARTNER_TYPE_SETTING_ENABLE_REFERRALS_DEAL_REGISTRATION
        )
      : "";

    const partnerTypeSettingAllowReferralSubmission = partnerSettings
      ? IsPartnerTypeSettingEnabled(partnerSettings, ALLOW_REFERRAL_SUBMISSION)
      : "";

    return (
      <>
        {partnerTypeSettingEnableReferrals &&
          partnerTypeSettingAllowReferralSubmission && (
            <div
              className={[
                "expert-onboarding",
                `${!enable ? "disabled" : ""}`
              ].join(" ")}
            >
              <div className="card p-4">
                <div className="card-body">
                  <div className="row">
                    {partnerReferralsCount >
                      PARTNER_DASHBOARD_NULL_REFERRALS_COUNT && (
                      <div className="col text-right">
                        <small className="text-muted pr-1">Showing:</small>
                        <small>
                          <strong>
                            {partnerReferrals.length} of {partnerReferralsCount}
                          </strong>{" "}
                          {Terminology.get(SystemName.REFERRALS)}
                        </small>
                      </div>
                    )}
                  </div>
                  <div className="row">
                    <div className="col">
                      <div className="d-flex">
                        <DealRegistrationIcon />
                        <h6 className="ml-2 text-info font-weight-bold">
                          {label}
                        </h6>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      className={
                        partnerReferrals && partnerReferrals.length > 0
                          ? "col-lg-6 col-md-6 col-12"
                          : "col"
                      }
                    >
                      <Link
                        className="text-decoration-none text-dark"
                        to={redirectUrl || "#"}
                      >
                        {/* Card content */}
                        <div className="mt-2 d-flex">
                          <div>
                            <h5 className="font-weight-bold">{subTitle}</h5>
                            <div className="row">
                              <div className="col-10">
                                <subText>
                                  <b className="text-muted">{subText}</b>
                                </subText>
                              </div>
                            </div>
                          </div>
                          <div
                            className={
                              partnerReferrals && partnerReferrals.length > 0
                                ? "d-none"
                                : "col-1 my-auto"
                            }
                          >
                            <ChevronRight />
                          </div>
                        </div>
                        {/* -------- */}
                      </Link>
                    </div>
                    <div
                      className={
                        partnerReferrals && partnerReferrals.length > 0
                          ? "col-lg-6 col-md-6 col-12"
                          : "d-none"
                      }
                    >
                      <div className="mt-2 d-flex">
                        <h5 className="font-weight-bold">
                          Recent {Terminology.get(SystemName.REFERRALS)}
                        </h5>
                      </div>
                      {partnerReferrals &&
                        partnerReferrals.length > 0 &&
                        partnerReferrals.map(referral => (
                          <div className={["open-deal-item py-4"].join(" ")}>
                            <Link
                              to={`referral/edit/${referral.id}`}
                              className="text-decoration-none text-dark"
                            >
                              <h6 className="font-weight-bold">
                                {referral.account_name}
                              </h6>
                            </Link>
                            <div className="row">
                              <div className="col-7">
                                <div>
                                  <small className="text-muted">
                                    Submitted on:
                                  </small>
                                  <br />
                                  <strong className="pl-0">
                                    {referral.createdAt}
                                  </strong>
                                </div>
                              </div>
                              <div className="col-5">
                                <div>
                                  <small className="text-muted">Status: </small>
                                  <br />
                                  <strong
                                    className={`pl-0 ${
                                      referral.status === REFERRAL_STATUS_NEW
                                        ? "text-warning"
                                        : referral.status ===
                                          REFERRAL_STATUS_APPROVED
                                        ? "text-success"
                                        : referral.status ===
                                            REFERRAL_STATUS_DECLINED ||
                                          referral.status ==
                                            REFERRAL_STATUS_REQUESTED
                                        ? "text-danger"
                                        : "text-secondary"
                                    }`}
                                  >
                                    {referral.status}
                                  </strong>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))}
                    </div>
                    {partnerReferralsCount >
                      PARTNER_DASHBOARD_MY_REFERRALS_COUNT && (
                      <div className="col-lg-6 col-md-6 col-12 offset-md-6 pt-4">
                        <Link className="text-decoration-none" to="/referrals">
                          <button className="btn btn-primary">View More</button>
                        </Link>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
      </>
    );
  }
}

StyledReferralSubmitCard.propTypes = {
  count: propTypes.number,
  label: propTypes.string.isRequired,
  prefix: propTypes.string
};

export default StyledReferralSubmitCard;
