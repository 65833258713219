import React, { Component } from "react";
import { Link } from "react-router-dom";

// 404 page
import Page404 from "../views/Page404";
import { isBadRequest } from "../common/http";

// Components
import Spinner from "../components/base/Spinner";

// API call
import { apiClient } from "../apiClient";

// Configs
import { endpoints, DEFAULT_API_KEY } from "../configs";

// Helper
import {
  getCookie,
  isSuperAdmin,
  isCompanyAdmin,
  getUrlParameter,
  isCompanyManager
} from "../lib/helper";

// Assets
import { ChevronLeft } from "../assets/img/icons";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

import PartnerProfileDetails from "./PartnerProfileDetails";

import { connect } from "react-redux";

class EmbedPartnerProfile extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      partnerDetails: [],
      showAboutMe: false,
      moreRecomendation: false,
      loading: false,
      isInvalidRequest: false,
      isPlaybooksloading: false
    };
  }

  componentDidMount = () => {
    this.getPartnerDetails();
    this.getPartnerPlaybookList();
  };

  //Set Partner Profile Visited
  setPartnerProfileVisited = () => {
    const partnerId =
      this.state && this.state.partnerDetails && this.state.partnerDetails.id;
    if (partnerId) {
      const data = {
        partnerId: partnerId
      };

      // If dont have session send default API key
      if (!getCookie(COOKIE_SESSION_TOKEN)) {
        apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
      }
      apiClient.post(`${endpoints().activity}`, data);
    }
  };

  // Partner details api call by id
  getPartnerDetails = async () => {
    const partnerId = this.props.match.params.id;
    const apiUrl = `${
      endpoints().publicAPI
    }/partner/${partnerId}/?partnerProfileUrl=${partnerId}`;

    // If dont have session send default API key
    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    try {
      const response = await apiClient.get(`${apiUrl}`);
      this.setState({ partnerDetails: response.data, loading: true }, () => {
        if (response && response.data) {
          this.setPartnerProfileVisited();
        }
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }

        console.error(errorMessage);
        this.setState({ isInvalidRequest: true });
      }
    }
  };

  getPartnerPlaybookList = async () => {
    const partnerId = this.props.match.params.id;
    const apiUrl = `${
      endpoints().publicAPI
    }/partner/playbooks/${partnerId}?pagination=true&pageSize=4`;

    // If dont have session send default API key
    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    try {
      const response = await apiClient.get(`${apiUrl}`);
      this.setState({
        playbookList: response.data.data,
        isPlaybooksloading: true
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }

        console.error(errorMessage);
        this.setState({ isInvalidRequest: true });
      }
    }
  };

  toggleShowAboutMe = e => {
    e.preventDefault();
    this.setState({ showAboutMe: !this.state.showAboutMe });
  };

  toggleMoreRecomendation = e => {
    e.preventDefault();
    this.setState({ moreRecomendation: !this.state.moreRecomendation });
  };

  render() {
    if (
      this.state.isInvalidRequest ||
      (!isSuperAdmin(this.props.roleId) &&
        !isCompanyAdmin(this.props.roleId) &&
        !isCompanyManager(this.props.roleId) &&
        this.state.status === "Draft")
    ) {
      return (
        <div className="app-body">
          <Page404 />
        </div>
      );
    }

    const redirectTo = getUrlParameter("redirect");

    const { playbookList } = this.state;

    const currentUrl = window.location.pathname + window.location.search;
    const partnerId = this.props.match.params.id;

    return (
      <div className="expert-profile">
        {!this.state.partnerDetails.length &&
          this.state.partnerDetails.length !== undefined && <Spinner />}
        {this.state.partnerDetails && (
          <div className="content-wrapper">
            <Link
              className="d-flex my-4"
              onClick={() => {
                const { goBack, action } = this.props.history;
                return action === "POP"
                  ? (window.location =
                      redirectTo || "/embed/partners-directory")
                  : goBack();
              }}
              style={{ cursor: "pointer" }}
            >
              <ChevronLeft /> Back
            </Link>

            <PartnerProfileDetails
              partnerDetails={this.state.partnerDetails}
              currentUrl={currentUrl}
              partnerId={partnerId}
              playbookList={playbookList}
              redirectTo={redirectTo}
            />
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(EmbedPartnerProfile);
