import React from "react";
import UpdateTagTypeForm from "./UpdateTagTypeForm";

// Components
import PageTitle from "../../components/base/PageTitle";

const TagTypeDetails = props => {
  return (
    <div>
      <PageTitle label="Tag type details" />

      <UpdateTagTypeForm history={props.history} match={props.match} />
    </div>
  );
};

export default TagTypeDetails;
