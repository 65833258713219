import React from "react";
import { Link } from "react-router-dom";
import { Table } from "reactstrap";
import moment from "moment";

//Constants
import {
  SALESFORCE_OPPORTUNITY_NET_ARR_AMOUNT,
  SALESFORCE_OPPORTUNITY_AMOUNT
} from "../../../../../salesforce/Constants";

const OpportunityListTable = ({
  opportunityList,
  salesforceOpportunityAmountFieldName
}) => {
  return (
    <Table className="table-hover bg-white">
      <thead className="thead-dark table-bordered">
        <tr>
          <th>Opportunity</th>
          <th>Close Date</th>
          <th>Stage</th>
          <th>Amount</th>
        </tr>
      </thead>
      <tbody>
        {opportunityList && opportunityList.length ? (
          opportunityList.map(opportunity => (
            <tr key={`${opportunity.Id}`}>
              <td>
                <Link
                  to={`/support-portal/salesforce/opportunity/${opportunity.Id}`}
                >
                  <span className="banner-image-upload-link">
                    {opportunity.Name}
                  </span>
                </Link>
              </td>
              <td>
                {opportunity.CloseDate &&
                  moment(opportunity.CloseDate).format("MM/DD/YYYY")}
              </td>

              <td>{opportunity.StageName}</td>

              {salesforceOpportunityAmountFieldName ===
                SALESFORCE_OPPORTUNITY_NET_ARR_AMOUNT && (
                <td>
                  {opportunity &&
                    (opportunity.sales2cash__Net_ARR__c
                      ? `$${opportunity.sales2cash__Net_ARR__c}`
                      : "")}
                </td>
              )}

              {salesforceOpportunityAmountFieldName ===
                SALESFORCE_OPPORTUNITY_AMOUNT && (
                <td>
                  {opportunity &&
                    (opportunity.Amount ? `$${opportunity.Amount}` : "")}
                </td>
              )}
            </tr>
          ))
        ) : (
          <tr>
            <td className="text-center p-4" colSpan={5}>
              No Results Found
            </td>
          </tr>
        )}
      </tbody>
    </Table>
  );
};

export default OpportunityListTable;
