import React from "react";
import "react-circular-progressbar/dist/styles.css";
import SelectDropdown from "../../../components/base/SelectDropdown";
import accountMappingIcon from "../../../assets/img/icons/nav-icon-account-mapping.svg";
import { Link } from "react-router-dom";

import SVG from "react-inlinesvg";
import CrossBeamIcon from "../../../assets/img/icons/crossbeam-logo.svg";
import CrossBeamPartnerCard from "../CrossbeamPartnerCard";
import { PARTNER_STATUS_APPROVED } from "../../../partnerStatus/Constants";
import CheckboxDropdownSelect from "../../../components/base/CheckboxDropdownSelect";

import {
  ALL_INSIGHTS,
  REFERRAL_SUGGESTIONS,
  ASSISTANCE_REQUEST_SUGGESTIONS,
  INTRODUCTION_SUGGESTIONS,
  CO_MARKETING_SUGGESTIONS
} from "../../../Constants/Crossbeam";

const AccountMappingInsights = props => {
  const {
    crossBeamData,
    crossBeamPartnerRecords,
    crossBeamDataDashboard,
    crossbeamPartnerStatusUpdate,
    partnerStatus,
    enable,
    history
  } = props;

  const filterOptions = [
    {
      label: ALL_INSIGHTS,
      value: "all_insigts"
    },
    {
      label: REFERRAL_SUGGESTIONS,
      value: "referral_suggestions"
    },
    {
      label: ASSISTANCE_REQUEST_SUGGESTIONS,
      value: "assistance_request_suggestions"
    },
    {
      label: INTRODUCTION_SUGGESTIONS,
      value: "introduction_suggestions"
    },
    {
      label: CO_MARKETING_SUGGESTIONS,
      value: "co-marketing_suggestions"
    }
  ];
  // const handleFilterChange = value => {};

  return (
    <div className={["", `${!enable ? "disabled" : ""}`].join(" ")}>
      <div className="card p-4">
        <div className="d-flex flex-row justify-content-between">
          <span className="crossbeam-icon mt-4">
            <SVG
              style={{ color: "#17a2b8", width: "25px", height: "25px" }}
              src={accountMappingIcon}
            />{" "}
            <span className="ml-2 text-info font-weight-bold">
              Account Mapping
            </span>
          </span>

          <div className="d-flex flex-column ">
            <span className="text-muted" style={{ fontSize: "12px" }}>
              Powered by:
            </span>

            <span className="crossbeam-logo">
              <SVG src={CrossBeamIcon} />{" "}
            </span>
          </div>
        </div>

        <h4 className="font-weight-bold mt-3">Account Mapping Insights</h4>

        <div
          className="mb-0 d-flex flex-row align-items-center justify-content-between"
          style={{ paddingBottom: 35 }}
        >
          <span style={{ fontSize: 14, fontWeight: 600, color: "#989898" }}>
            Actions you can take to co-sell / co-market and close deals begin
            here
          </span>
        </div>

        <div className="mb-0 pb-3 d-flex flex-row align-items-center justify-content-between">
          <span className="font-weight-bold" style={{ fontSize: 15 }}>
            Showing{" "}
            {crossBeamData.partnerData.length > 4
              ? "4"
              : crossBeamData.partnerData.length}{" "}
            of {crossBeamData ? crossBeamData.partnerData.length : "4"} items{" "}
            <Link
              to={{
                pathname: "/account-mapping",
                state: {
                  crossBeamData: crossBeamData
                }
              }}
            >
              <span
                className="text-info font-weight-normal"
                style={{ fontSize: 15 }}
              >
                View All
              </span>
            </Link>
          </span>

          <span className="flex-row-reverse">
            <CheckboxDropdownSelect
              name="filterBy"
              dropdownLabel="Filter by:"
              buttonLabel="All Insights"
              dropdownLinks={filterOptions}
              color="gray"
              // handleChange={values => {
              //   handleFilterChange(values);
              // }}
              checkedItems={{
                all_insigts: true
              }}
              hideCaret
              width={250}
              borderBottom={true}
              changeCheckBoxColor={true}
            />
          </span>
        </div>

        {crossBeamData && (
          <CrossBeamPartnerCard
            crossBeamData={crossBeamDataDashboard}
            crossbeamPartnerStatusUpdate={crossbeamPartnerStatusUpdate}
            type={"Partner"}
            history={history}
          />
        )}
      </div>
    </div>
  );
};

export default AccountMappingInsights;
