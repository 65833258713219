import React from "react";

// Components
import { InlineInfoIcon } from "../../../../assets/img/icons";
import AlertBanner from "../../../../components/base/AlertBanner";

const TerrirtoryAssignmentToggle = props => {
  const { setTeritoryAssignementToggleValues } = props;
  return (
    <>
      <AlertBanner
        icon={<InlineInfoIcon />}
        buttonLabel="Check account in Salesforce"
        buttonOnClick={() => {
          setTeritoryAssignementToggleValues({
            isNoAccountFoundModalOpen: true
          });
        }}
        removeOnClick={() => {
          setTeritoryAssignementToggleValues({
            isTerrirtoryAssignmentNotification: false
          });
        }}
        message="Territory Assignment task was not completed in Salesforce."
      />
    </>
  );
};
export default TerrirtoryAssignmentToggle;
