import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { DEFAULT_API_KEY, endpoints } from "../../configs";
import { apiClient } from "../../apiClient";
import { setCookie } from "../../lib/helper";
import { COOKIE_SESSION_TOKEN, COOKIE_USER_ID } from "../../lib/cookie";
import crypto from "../../lib/crypto";
import { isBadRequest } from "../../common/http";

const LoginByAuth0 = () => {
  // const [isAuth0LoginLoading, setIsAuth0LoginLoading] = useState(true);

  const { user, isAuthenticated, isLoading } = useAuth0();
  if (isLoading) {
    return "";
  }

  const loginByAuth0 = async email => {
    const data = {
      email: await crypto.encrypt(email)
    };
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .post(`${endpoints().userAPI}/auth0/login`, data)
      .then(response => {
        const { token, role, userId } = response.data;

        // Setting Cookies
        setCookie(COOKIE_SESSION_TOKEN, token);
        setCookie(COOKIE_USER_ID, userId);
        window.location.replace("/dashboard");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          window.location.replace("/login");
        }
      });
  };

  useEffect(() => {
    if (isAuthenticated && user && user.email) {
      loginByAuth0(user.email);
    }
  }, [isAuthenticated, user && user.email]);

  return isAuthenticated && <div></div>;
};
export default LoginByAuth0;
