import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import Toast from "../components/base/Toast";
import { isBadRequest } from "../common/http";
import { fetchList } from "../actions/table";
import { getUrlParameter } from "../lib/helper";
class marketplaceFeaturedCollectionService {
  static async create(data, id, callBack) {
    return dispatch => {
      apiClient
        .post(`${endpoints().marketplaceFeaturedCollectionAPI}`, data)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            dispatch(
              fetchList(
                "featuredCollection",
                `${endpoints().marketplaceFeaturedCollectionAPI}`,
                1,
                10,
                {
                  sort: getUrlParameter("sort"),
                  sortDir: getUrlParameter("sortDir"),
                  pagination: true,
                  landingPageBlockId: id
                }
              )
            );
            return callBack(response.data);
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    };
  }

  static async update(data, id, landingPageBlockId, callBack) {
    return dispatch => {
      apiClient
        .put(`${endpoints().marketplaceFeaturedCollectionAPI}/${id}`, data)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            dispatch(
              fetchList(
                "featuredCollection",
                `${endpoints().marketplaceFeaturedCollectionAPI}`,
                1,
                10,
                {
                  sort: getUrlParameter("sort"),
                  sortDir: getUrlParameter("sortDir"),
                  pagination: true,
                  landingPageBlockId: landingPageBlockId
                }
              )
            );
            return callBack(response.data);
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    };
  }
  static async delete(id, callBack) {
    return dispatch => {
      apiClient
        .delete(`${endpoints().marketplaceFeaturedCollectionAPI}/${id}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            dispatch(
              fetchList(
                "featuredCollection",
                `${endpoints().marketplaceFeaturedCollectionAPI}`,
                1,
                10,
                {
                  sort: getUrlParameter("sort"),
                  sortDir: getUrlParameter("sortDir"),
                  pagination: true
                }
              )
            );
            return callBack(response.data);
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    };
  }
}

export default marketplaceFeaturedCollectionService;
