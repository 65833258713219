import React from "react";
import { Link } from "react-router-dom";

// Components
import Email from "../../components/base/Email";
import Password from "../../components/base/Password";
import Checkbox from "../expertSignUp/Checkbox";
import { getKeyValueByObject } from "../../lib/helper";
import { SHOW_BECOME_A_PARTNER_OPTION_IN_LOGIN } from "../../setting/Constants";
class LoginByPasswordForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: ""
    };
    this._hideErrorMessage = this._hideErrorMessage.bind(this);
  }

  // Hide Error Message
  _hideErrorMessage = () => {
    this.setState({ errorMessage: "" });
  };

  componentWillReceiveProps(nextProps) {
    // Set Login Error Message
    if (
      nextProps.errorMessage &&
      nextProps.errorMessage !== this.state.errorMessage
    ) {
      this.setState({ errorMessage: nextProps.errorMessage });
    }
  }
  handleSubmit = e => {
    if (e) {
      window.location.replace("/partner-signup");
    }
  };

  render() {
    const { errorMessage } = this.state;
    const { settings } = this.props;

    const showBecomeAPartnerButton = settings
      ? getKeyValueByObject(settings, SHOW_BECOME_A_PARTNER_OPTION_IN_LOGIN)
      : "";

    return (
      <div className={["basic-login-form"].join(" ")}>
        <div>
          <div className={["field-wrapper"].join("")}>
            <Email
              name="email"
              placeholder="Email Address"
              onKeyDown={this._hideErrorMessage}
              required={showBecomeAPartnerButton !== "true" ? true : false}
            />
          </div>
          <div className={["field-wrapper"].join(" ")}>
            <Password
              name="password"
              placeholder="Password"
              onKeyDown={this._hideErrorMessage}
              required={showBecomeAPartnerButton !== "true" ? true : false}
            />
          </div>
          <div className={["field-wrapper"].join(" ")}>
            <div className="form-group">
              <span
                id="invalid Email-or-Password"
                className={errorMessage ? "error-message" : ""}
              >
                {errorMessage}
              </span>
            </div>
          </div>
          <div className={["field-wrapper"].join(" ")}>
            <div className="form-group d-flex justify-content-between">
              <Checkbox
                name="rememberPassword"
                label="Remember my username"
                className="float-left cursor-pointer"
              />

              <Link to="/forgot-password" className="accepted-terms">
                <span className="pl-0 text-info">Forgot password?</span>
              </Link>
            </div>
          </div>
          <div className={["field-wrapper"].join(" ")}>
            <div className="form-group text-center">
              <button
                id="btn-login"
                type="submit"
                className="btn btn-login w-100"
              >
                Log in
              </button>
            </div>
          </div>
        </div>

        {showBecomeAPartnerButton && showBecomeAPartnerButton == "true" && (
          <div>
            <div className="login-form-inline-title mt-0">
              <p>Not a partner yet ?</p>
            </div>
            <div className={["field-wrapper"].join(" ")}>
              <div className="form-group text-center">
                <button
                  className="btn btn-login w-100 bg-success"
                  onClick={e => this.handleSubmit(e)}
                >
                  Become a Partner
                </button>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

export default LoginByPasswordForm;
