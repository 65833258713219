import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { Input, FormFeedback, Tooltip } from "reactstrap";

// Components
import Label from "../../components/base/Label";

// Assets
import { InlineInfoIcon } from "../../assets/img/icons";

class ExpertRequestQuestion extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tooltipOpen: false
    };
  }

  // Toggle Tooltip
  toggle = targetName => {
    if (!this.state[targetName]) {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: true
        }
      });
    } else {
      this.setState({
        ...this.state,
        [targetName]: {
          tooltipOpen: !this.state[targetName].tooltipOpen
        }
      });
    }
  };

  // Is ToolTip Open
  isToolTipOpen = targetName => {
    return this.state[targetName] ? this.state[targetName].tooltipOpen : false;
  };

  // Validate Field
  validate(value) {
    const { label, placeholder, required, error } = this.props;

    let errorMessage;
    const inputLabel = label || placeholder;
    const errorMessageLabel = error;

    if ((!value || !value.trim()) && required) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    }

    return errorMessage;
  }

  // Render Input
  renderInput({ field, form: { touched, errors, values } }) {
    const {
      name,
      id,
      label,
      required,
      options,
      onRender,
      className,
      subTitleLabel,
      subTitleLabelIcon
    } = this.props;

    const errorMessage = touched[name] && errors[name] ? errors[name] : null;
    const inputId = id || name;

    const fieldInput = (
      <div className={`expert-questions ${className}`}>
        {label && (
          <Label id={inputId} required={required}>
            <span style={{ fontWeight: "lighter" }}>{label}</span>
          </Label>
        )}
        {subTitleLabel && (
          <p className="p2" style={subTitleLabel ? { color: "#009DDA" } : {}}>
            {
              <i
                style={
                  subTitleLabelIcon ? { marginRight: 15, color: "#009DDA" } : {}
                }
                className={subTitleLabelIcon}
              />
            }
            <span>{subTitleLabel}</span>
          </p>
        )}

        {options &&
          options.map((option, key) => (
            <div key={key} className="field-wrapper">
              <Label check className="radio-item">
                <Input
                  type="radio"
                  {...field}
                  name={inputId}
                  defaultChecked={field.value === option.value}
                  value={option.value}
                  className="radio-button"
                />
                <p className="d-flex align-items-center justify-content-start">
                  <span className="radio-placeholder" />
                  {option.label}{" "}
                  {option.tooltip && (
                    <span className="info-icon" id={`radio-button-${key}`}>
                      <InlineInfoIcon />
                    </span>
                  )}
                </p>
                {option.tooltip && (
                  <div>
                    <Tooltip
                      placement="top"
                      isOpen={this.isToolTipOpen(`radio-button-${key}`)}
                      target={`radio-button-${key}`}
                      toggle={() => this.toggle(`radio-button-${key}`)}
                      className={"recruitment-tooltip"}
                    >
                      {option.tooltip}
                    </Tooltip>
                  </div>
                )}
              </Label>
            </div>
          ))}

        {errorMessage && (
          <FormFeedback style={{ marginTop: 1, display: "block" }}>
            {errorMessage}
          </FormFeedback>
        )}
      </div>
    );

    if (!onRender) {
      return fieldInput;
    }

    return onRender(values, (err, render) => (render ? fieldInput : null));
  }

  render() {
    const { name } = this.props;

    return (
      <Field
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

ExpertRequestQuestion.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  subTitleLabel: PropTypes.string,
  subTitleLabelIcon: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  )
};

export default ExpertRequestQuestion;
