/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import toast from "../../components/base/Toast";

// Component
import Form from "../../components/base/Form";

//API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";
import Number from "../../components/base/Number";

// Customer Create API Call
const CommissionModel = ({
  toggle,
  isOpen,
  getReferralDetails,
  referralId
}) => {
  async function handleUpdate(values, toggle) {
    const data = new FormData();

    data.append("commissionPercentage", values && values.commissionPercentage);

    try {
      await apiClient
        .put(
          `${endpoints().referralApi}/referral/updateCommission/${referralId}`,
          data
        )
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            getReferralDetails(true);
            toggle();
          }
          toast.success(successMessage);
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  }

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        className={["edit-task-modal"].join(" ")}
      >
        <Form
          initialValues={{
            commissionPercentage: ""
          }}
          onSubmit={values => {
            handleUpdate(values, toggle);
          }}
        >
          <ModalHeader toggle={toggle}>
            <h4 className={["font-weight-bold"].join(" ")}>
              Update Commission Percentage
            </h4>
          </ModalHeader>
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="form-wrapper">
              <div>
                <Number
                  name="commissionPercentage"
                  label="Commission Percentage"
                  placeholder="Enter Commission Percentage"
                  required
                  error=""
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <Button label="" type="submit" className="btn btn-primary">
                Update
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default CommissionModel;
