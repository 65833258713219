import React from "react";
import AccountCard from "../../../components/base/AccountCard";
import Phone from "../../../components/base/Phone";
import Email from "../../../components/base/Email";
import Date from "../../../components/base/Date";

const AccountContact = props => {
  const { contactList } = props;

  return (
    <div className="card-body pt-3 ml-3 border-top">
      <h4 className="font-weight-bold mt-4">Contacts</h4>
      {contactList &&
        contactList.length > 0 &&
        contactList.map(data => {
          return (
            <>
              <div className="font-weight-bold mt-4">
                <AccountCard
                  firstName={data.first_name}
                  lastName={data.last_name}
                />
              </div>

              <div className="ml-5 pl-2">
                <div className="font-weight-bold">
                  {data.title && (
                    <span className="text-secondary ml-2">{`  ${data.title}`}</span>
                  )}
                </div>

                {data && data.email && (
                  <div className="font-weight-bold mt-2 ml-2">
                    <Email
                      readOnly={true}
                      Email={data.email}
                      className={["text-secondary", "font-weight-bold"].join(
                        " "
                      )}
                    />
                  </div>
                )}

                {data && data.phone && (
                  <div className="mt-2 ml-2">
                    <Phone
                      readOnly={true}
                      phone={data.phone}
                      className={["text-secondary", "font-weight-bold"].join(
                        " "
                      )}
                    />
                  </div>
                )}

                {data.contact_created_at && (
                  <div className="font-weight-bold mt-2 ml-2">
                    <Date
                      readOnly={true}
                      date={data.contact_created_at}
                      className={["text-secondary"].join(" ")}
                    />
                  </div>
                )}
              </div>
            </>
          );
        })}
    </div>
  );
};

export default AccountContact;
