import React from "react";

import ManageTagList from "../../../supportPortal/tabs/Lists/ManageTagList";

export default class Detail extends React.Component {
  render() {
    return (
      <div>
        <ManageTagList showField history={this.props.history} />
      </div>
    );
  }
}
