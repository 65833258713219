import React, { useState } from "react";
import DefaultContent from "../components/content/DefaultContent";
import Label from "../components/base/Label";
import {
  getKeyValueByObject,
  isCompanyAdmin,
  isCompanyManager,
  isSuperAdmin
} from "../lib/helper";
import {
  SETTINGS_ENABLE_DEALS,
  SETTINGS_ENABLE_REFERRALS,
  SETTINGS_PORTAL_NAME
} from "../setting/Constants";
import { PARTNER_TYPE_SETTING_ENABLE_REFERRALS_DEAL_REGISTRATION } from "../partnerTypeSetting/Constants";
import TextArea from "../components/base/TextArea";
import { Referral } from "../assets/img/icons";
import Terminology from "../lib/Terminology";
import SystemName from "../Constants/SystemName";
import { useSelector, useDispatch } from "react-redux";

const LinkSection = props => {
  const {
    referralLink,
    referralLinkBaseUrl,
    encryptedPartnerId,
    partnerId,
    id
  } = props;
  const encryptedReferralLinkId = referralLink.encryptedReferralLinkId;
  const signUpLink = referralLinkBaseUrl
    ? `${referralLinkBaseUrl}/${
        encryptedPartnerId ? encryptedPartnerId : partnerId
      }/${encryptedReferralLinkId}`
    : "";
  const [
    referralToSignUpLinkCopySuccess,
    setReferralToSignUpLinkCopySuccess
  ] = useState("Copy");

  function copyReferralToSignUpLinkRef(e) {
    navigator.clipboard.writeText(signUpLink);
    e.target.focus();
    setReferralToSignUpLinkCopySuccess("Copied!");
  }
  return (
    <div className="mb-3">
      <div className="d-flex align-items-center">
        <TextArea
          id={id}
          name="referralToSignUpLink"
          defaultValue={signUpLink || ""}
          className="w-100 mb-2"
          rows={3}
          disabled
        />
        <div>
          <button
            type="button"
            className={[
              "btn",
              `${
                referralToSignUpLinkCopySuccess !== "Copied!"
                  ? "btn-primary"
                  : "btn-success"
              }`,
              "mb-2 ml-2"
            ].join(" ")}
            onClick={copyReferralToSignUpLinkRef}
          >
            {referralToSignUpLinkCopySuccess}
          </button>
        </div>
      </div>
    </div>
  );
};

const PartnerProfileLinks = ({
  dealRegistrationLink,
  inboundReferralLink,
  referralLinkList,
  referralLinkBaseUrl,
  companyProfileUrl,
  partnerId,
  settings,
  partnerSettings,
  id,
  showPublicReferralLinkTab
}) => {
  const [inboundLinkCopySuccess, setInboundLinkCopySuccess] = useState("Copy");

  const [
    dealRegistrationLinkCopySuccess,
    setDealRegistrationLinkCopySuccess
  ] = useState("Copy");
  const inboundLink = inboundReferralLink;
  const dealLink = dealRegistrationLink;

  function copyDealRegistrationLink(e) {
    navigator.clipboard.writeText(dealLink);
    e.target.focus();
    setDealRegistrationLinkCopySuccess("Copied!");
  }

  function copyInboundReferralLinkRef(e) {
    navigator.clipboard.writeText(inboundLink);
    e.target.focus();
    setInboundLinkCopySuccess("Copied!");
  }

  const partnerTypeSettingEnableReferrals = partnerSettings
    ? getKeyValueByObject(
        partnerSettings,
        PARTNER_TYPE_SETTING_ENABLE_REFERRALS_DEAL_REGISTRATION
      )
    : "";

  const enableDeals = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_DEALS)
    : "";

  const enableReferrals = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_REFERRALS)
    : "";

  const portalName = getKeyValueByObject(settings, SETTINGS_PORTAL_NAME);
  const currentUser = useSelector(state => state.user);

  return (
    <>
      {((enableReferrals &&
        enableReferrals === "true" &&
        partnerTypeSettingEnableReferrals === "true") ||
        isSuperAdmin(currentUser && currentUser.roleId) ||
        isCompanyAdmin(currentUser && currentUser.roleId) ||
        isCompanyManager(currentUser && currentUser.roleId)) && (
        <>
          {referralLinkList && referralLinkList.length > 0 && (
            <DefaultContent
              title={"Share Links to refer people to " + portalName}
            >
              <span className={["text-inline-grayed d-block mb-2"].join("")}>
                Use these link to send to a{" "}
                {Terminology.get(SystemName.REFERRAL)} to signup and
                automatically get credit for the{" "}
                {Terminology.get(SystemName.REFERRAL)}.
              </span>
              {/* Inbound Referral Link */}
              {referralLinkList &&
                referralLinkList.length > 0 &&
                referralLinkList.map(referralLink => (
                  <>
                    <Label className="font-weight-bold">
                      {"Link for " + '"' + referralLink.name + '"'}
                    </Label>
                    <LinkSection
                      id={id}
                      referralLink={referralLink}
                      referralLinkBaseUrl={referralLinkBaseUrl}
                      encryptedPartnerId={companyProfileUrl}
                      partnerId={partnerId}
                      showPublicReferralLinkTab={showPublicReferralLinkTab}
                    />
                  </>
                ))}
            </DefaultContent>
          )}

          {showPublicReferralLinkTab && (
            <DefaultContent
              title={`Submit a ${Terminology.get(
                SystemName.REFERRAL
              )} without logging in`}
            >
              <>
                <span className={["text-inline-grayed d-block mb-2"].join("")}>
                  Here is the link your team can use to submit a{" "}
                  {Terminology.get(SystemName.REFERRAL)} without logging in.
                </span>
                <div className="row">
                  <div className="col-4 align-items-left">
                    <Referral />
                    <h6 className="ml-1 text-inline-grayed">Preview</h6>
                  </div>
                  <div className="col-8">
                    <Label className="font-weight-bold">
                      Link for Registering a Deal
                    </Label>
                    {/* Inbound Referral Link */}
                    <div className="d-flex align-items-center mb-1">
                      <TextArea
                        id={id}
                        name="inboundReferralLink"
                        defaultValue={inboundReferralLink || ""}
                        placeholder={"Enter Inbound Referral Link"}
                        className="w-100 mb-2"
                        rows={3}
                        disabled
                      />
                      <div>
                        <button
                          className={[
                            "btn",
                            `${
                              inboundLinkCopySuccess !== "Copied!"
                                ? "btn-primary"
                                : "btn-success"
                            }`,
                            "ml-2"
                          ].join(" ")}
                          type="button"
                          onClick={copyInboundReferralLinkRef}
                        >
                          <span>{inboundLinkCopySuccess}</span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </>
            </DefaultContent>
          )}
        </>
      )}
      {/* -------------------- */}

      {/* Deal Registration Link */}
      {enableDeals && enableDeals === "true" && (
        <DefaultContent title={"Register a Deal without logging in"}>
          <>
            <p className={["text-inline-grayed"].join("")}>
              Here is the link you can use to submit a reseller deal without
              logging in.
            </p>
            <Label className="font-weight-bold">Deal Registration Link</Label>
            <div className="d-flex align-items-center">
              <TextArea
                id={id}
                name="dealRegistrationLink"
                defaultValue={dealRegistrationLink || ""}
                className="w-100 mb-2"
                rows={3}
                disabled
              />
              <div>
                <button
                  type="button"
                  className={[
                    "btn",
                    `${
                      dealRegistrationLinkCopySuccess !== "Copied!"
                        ? "btn-primary"
                        : "btn-success"
                    }`,
                    "mb-2 ml-2"
                  ].join(" ")}
                  onClick={copyDealRegistrationLink}
                >
                  {dealRegistrationLinkCopySuccess}
                </button>
              </div>
            </div>
          </>
        </DefaultContent>
      )}
      {/* -------------------- */}
    </>
  );
};

export default PartnerProfileLinks;
