import React, { useState } from "react";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import AddButton from "../../components/base/AddButton";

import { DropdownItem } from "reactstrap";
// Components
import PageTitle from "../../components/base/PageTitle";

// Configs
import { endpoints } from "../../configs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MoreDropdown from "../../components/base/MoreDropdown";

import {
  addHubspotSyncRule,
  updateHubspotSyncRule,
  deleteHubspotSyncRule
} from "../../actions/hubspotSyncRule";

import SyncRuleModal from "../components/syncRuleModal";

import DeleteModal from "../../components/base/DeleteModal";

const HubSpotSyncRuleTab = props => {
  const [isSyncRuleAddModalOpen, setSyncRuleAddModalOpen] = useState(false);
  const [isSyncRuleEditModalOpen, setSyncRuleEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [selectedSyncRule, setSelectedSyncRule] = useState("");

  const {
    companyObjectFields,
    contactObjectFields,
    dealObjectFields
  } = props.HubSpotFieldList;

  const handleAddModal = () => {
    setSyncRuleAddModalOpen(!isSyncRuleAddModalOpen);
  };

  const handleEditModal = async selectedSyncRule => {
    setSelectedSyncRule(selectedSyncRule);
    toggleEditModal();
  };

  const toggleEditModal = () => {
    setSyncRuleEditModalOpen(!isSyncRuleEditModalOpen);
  };

  const setOpenDeleteModal = () => {
    setDeleteModalOpen(!isDeleteModalOpen);
  };

  return (
    <>
      <SyncRuleModal
        isOpen={isSyncRuleAddModalOpen}
        toggle={handleAddModal}
        CompanyObjectFieldList={companyObjectFields}
        DealObjectFieldList={dealObjectFields}
        ContactObjectFieldList={contactObjectFields}
        setSelectedSyncRule={setSelectedSyncRule}
        propValue={props}
      />

      <SyncRuleModal
        isOpen={isSyncRuleEditModalOpen}
        toggle={toggleEditModal}
        CompanyObjectFieldList={companyObjectFields}
        DealObjectFieldList={dealObjectFields}
        ContactObjectFieldList={contactObjectFields}
        updateFunction={props}
        syncRuleDetail={selectedSyncRule}
        setSelectedSyncRule={setSelectedSyncRule}
        propValue={props}
      />

      <DeleteModal
        isOpen={isDeleteModalOpen}
        toggle={() => {
          setOpenDeleteModal();
        }}
        title="Delete Hubspot Sync Rule"
        id={selectedSyncRule ? selectedSyncRule.id : ""}
        label={selectedSyncRule ? selectedSyncRule.hubspotFieldName : ""}
        deleteFunction={props.actions.deleteHubspotSyncRule}
      />

      <div className="d-flex justify-content-between">
        <PageTitle label="Sync Rule" />
        <div>
          <AddButton
            label="Add Sync Rule"
            className="pull-right btn btn-secondary"
            onClick={() => {
              handleAddModal();
            }}
          />
        </div>
      </div>

      <div className="mt-1 mb-5">
        <ReduxTable
          id="hubspotSyncRule"
          apiURL={`${endpoints().hubspotSyncRule}`}
          onRowClick={row => {
            handleEditModal(row);
          }}
          noRecordFoundHeight={"7vh"}
        >
          <ReduxColumn
            width={"200px"}
            field="syncDirection"
            isClickable="true"
            disableOnClick
          >
            Sync Direction
          </ReduxColumn>
          <ReduxColumn
            width={"200px"}
            field="hubspotObjectName"
            isClickable="true"
            disableOnClick
          >
            Hubspot Object
          </ReduxColumn>
          <ReduxColumn
            width={"200px"}
            field="hubspotFieldName"
            isClickable="true"
            disableOnClick
          >
            Hubspot Field
          </ReduxColumn>
          <ReduxColumn
            width={"200px"}
            field="torchliteObjectName"
            isClickable="true"
            disableOnClick
          >
            Torchlite Object
          </ReduxColumn>
          <ReduxColumn
            width={"200px"}
            field="torchliteFieldName"
            isClickable="true"
            disableOnClick
          >
            Torchlite Field
          </ReduxColumn>
          <ReduxColumn width={"150px"} disableOnClick field="operator">
            Condition
          </ReduxColumn>
          <ReduxColumn width={"120px"} field="value" disableOnClick>
            Value
          </ReduxColumn>
          <ReduxColumn
            width={"100px"}
            disableOnClick
            className="action-column"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      handleEditModal(row);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setSelectedSyncRule(row);
                      setDeleteModalOpen(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addHubspotSyncRule,
        updateHubspotSyncRule,
        deleteHubspotSyncRule
      },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(HubSpotSyncRuleTab);
