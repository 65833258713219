import React from "react";

// Components
import QuickLinks from "./QuickLinks";
import { getParamsByName } from "../../lib/helper";

class DealRegisteredSuccessfully extends React.Component {
  render() {
    const redirectUrl = getParamsByName("sourceUrl");
    return (
      <>
        <div className="container" style={{ minHeight: "50vh" }}>
          <div className="py-5 my-5 container d-flex flex-column justify-content-center">
            <QuickLinks
              redirectUrl={redirectUrl}
              deal={true}
              message="Thank you for registering a deal. It has been created successfully."
            />
          </div>
        </div>
      </>
    );
  }
}

export default DealRegisteredSuccessfully;
