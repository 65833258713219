// API Client
import { apiClient } from "../apiClient";

import { endpoints } from "../configs";

// Get Redirect URL based on the Header Origin
export const getDestinationUrl = async () => {
  let redirectUrl;
  await apiClient.get(`${endpoints().urlApi}`).then(response => {
    const datas = response.data.data;

    if (datas) {
      datas.forEach(data => {
        if (data.source_url == window.location.origin) {
          redirectUrl = data.destination_url;
        } else redirectUrl = null;
      });
    }
  });
  return redirectUrl;
};
