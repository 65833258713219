import React from "react";
import {
  DEAL_STATUS_APPROVED,
  DEAL_STATUS_CLOSED,
  DEAL_STATUS_DECLINED,
  DEAL_STATUS_DRAFT,
  DEAL_STATUS_PENDING,
  DEAL_STATUS_REQUESTED,
  DEAL_STATUS_SUBMITTED
} from "../../../dealStatus/Constants";

// Components
import SidebarItem from "./SidebarItem";

const DealStatus = ({ status }) => {
  return (
    <>
      <SidebarItem>
        <p className="font-weight-bold mb-1">
          {status === DEAL_STATUS_DRAFT ? "Deal Registration Status" : "Status"}
        </p>
        <div className="d-flex align-items-start justify-content-between">
          <div className="deal-status-line text-warning">
            {status === DEAL_STATUS_DRAFT ? DEAL_STATUS_DRAFT : ""}
          </div>
          <div className="deal-status-line text-warning">
            {status === DEAL_STATUS_REQUESTED
              ? DEAL_STATUS_REQUESTED
              : status === DEAL_STATUS_PENDING
              ? DEAL_STATUS_PENDING
              : status === DEAL_STATUS_SUBMITTED
              ? DEAL_STATUS_SUBMITTED
              : ""}
          </div>
          <div
            className={`deal-status-line ${
              status === DEAL_STATUS_APPROVED
                ? "text-success"
                : status === DEAL_STATUS_DECLINED ||
                  status === DEAL_STATUS_CLOSED
                ? "text-danger"
                : ""
            }`}
          >
            {status === DEAL_STATUS_APPROVED
              ? DEAL_STATUS_APPROVED
              : status === DEAL_STATUS_DECLINED
              ? DEAL_STATUS_DECLINED
              : status === DEAL_STATUS_CLOSED
              ? DEAL_STATUS_CLOSED
              : ""}
          </div>
        </div>

        <div className="mt-2 d-flex align-items-center justify-content-between">
          <div
            className={`deal-status-line  border ${
              status === DEAL_STATUS_DRAFT
                ? "border-warning"
                : status === DEAL_STATUS_REQUESTED ||
                  status === DEAL_STATUS_PENDING ||
                  status === DEAL_STATUS_SUBMITTED ||
                  status === DEAL_STATUS_APPROVED ||
                  status === DEAL_STATUS_DECLINED ||
                  status === DEAL_STATUS_CLOSED
                ? "border-success"
                : "border-secondary"
            }`}
          ></div>
          <div
            className={`deal-status-line  border ${
              status === DEAL_STATUS_REQUESTED || status === DEAL_STATUS_PENDING
                ? "border-warning"
                : status === DEAL_STATUS_APPROVED ||
                  status === DEAL_STATUS_DECLINED ||
                  status === DEAL_STATUS_CLOSED
                ? "border-success"
                : "border-secondary"
            }`}
          ></div>
          <div
            className={`deal-status-line border ${
              status === DEAL_STATUS_APPROVED
                ? "border-success"
                : status === DEAL_STATUS_DECLINED ||
                  status === DEAL_STATUS_CLOSED
                ? "border-danger"
                : "border-secondary"
            }`}
          ></div>
        </div>
      </SidebarItem>
    </>
  );
};

export default DealStatus;
