import React from "react";

const SidebarContent = props => {
  const { children, title, sidebarHeight } = props;
  return (
    <div className="sidebar-content-box sidebar-content" style={sidebarHeight}>
      {title && (
        <p>
          <b>{title}</b>
        </p>
      )}

      {children}
    </div>
  );
};

export default SidebarContent;
