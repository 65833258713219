import React, { useState, useEffect } from "react";
import Avatar from "../../components/base/Avatar";
import { ChevronDown, ChevronUp } from "../../assets/img/icons";
import { Collapse } from "reactstrap";
import lastActivity from "../../assets/img/icons/last-activity.svg";
import SVG from "react-inlinesvg";
import moment from "moment";
// Constants
import {
  PROJECT_USER_STATUS_ACCEPTED,
  PROJECT_USER_STATUS_SHORTLISTED,
  PROJECT_USER_STATUS_NO_RESPONSE
} from "../../project/Constants";
import NoRecordsFound from "../../components/base/NoRecordsFound";

import partnerIcon from "../../assets/img/icons/icon-dashboard-partner.svg";
import { isNotEmpty } from "../../common/utils";
import { formatDate } from "../../common/date";

const CampaignPartnerTasks = props => {
  const { projectPartners, children, context, projectDetails } = props;
  const { setCurrentTaskPartnerId, currentTaskPartnerId } = context;
  setCurrentTaskPartnerId(currentTaskPartnerId);
  const [collapse, setCollapse] = useState("");
  const [discussionDate, setDiscussionDate] = useState([]);

  const projectDiscussions = projectDetails.projectDiscussion;

  const getPartnersDiscussionList = () => {
    const array = [];
    for (var i = 0; i < projectDiscussions.length; i++) {
      array.push(projectDiscussions[i]);
    }
    setDiscussionDate(array);
  };

  useEffect(() => {
    getPartnersDiscussionList();
    // getPartnerStatus();
    getPartnerId();
    formatDate();
  }, []);

  const projectPartner = [];
  projectPartners.forEach(e => {
    if (e.companyName !== "" && e.partnerId !== null) {
      projectPartner.push({
        avatarUrl: e.avatarUrl,
        companyName: e.companyName,
        partnerId: e.partnerId,
        roleId: e.roleId,
        status: e.status,
        userId: e.userId,
        completedTaskCount: e.taskCompltedCount
      });
    } else {
      return [];
    }
  });

  const projectPartnerStatus = [];
  projectPartner &&
    projectPartner.forEach(partner => {
      if (partner.status == PROJECT_USER_STATUS_ACCEPTED) {
        projectPartnerStatus.push(partner.userId);
      }
    });
  const invitedPartners = [];
  projectPartner &&
    projectPartner.forEach(partner => {
      if (partner.status == PROJECT_USER_STATUS_NO_RESPONSE) {
        invitedPartners.push(partner.userId);
      }
    });
  const toggle = partnerId => {
    setCollapse(collapse === Number(partnerId) ? "" : Number(partnerId));
    setCurrentTaskPartnerId(
      currentTaskPartnerId === Number(partnerId) ? "" : Number(partnerId)
    );
  };

  const getPartnerId = (partnerId, DiscussionPartnerId) => {
    if (partnerId == DiscussionPartnerId) {
      return true;
    } else {
      return false;
    }
  };

  let discussionpartnerIds;
  let date;
  return (
    <div>
      <div className="px-4 pt-4 pb-1">
        <h5 className="font-weight-bold">Participating Partners</h5>
      </div>
      <hr />
      {isNotEmpty(projectPartnerStatus) ? (
        <>
          {projectPartner.map(partner => {
            let {
              partnerId,
              companyName,
              avatarUrl,
              status,
              completedTaskCount
            } = partner;
            if (
              status !== PROJECT_USER_STATUS_ACCEPTED ||
              status === PROJECT_USER_STATUS_SHORTLISTED
            )
              return "";
            const isOpen = collapse === partnerId;
            return (
              <div id={`partner-${partnerId}-tasks`} className="">
                <div className="px-4">
                  <div className="d-flex ">
                    <Avatar
                      firstName={companyName}
                      size="customSize"
                      imageSize={"50"}
                      url={avatarUrl}
                    />
                    <div className="ml-1 col-2">
                      <p className="font-weight-bold mb-0">Partner</p>
                      <p className="font-weight-bold text-muted">
                        {companyName}
                      </p>
                    </div>
                    <div className="ml-auto mr-0 d-flex col-8 pl-5">
                      <SVG
                        style={{
                          width: "32px",
                          height: "32px",
                          marginRight: 24
                        }}
                        src={lastActivity}
                      />{" "}
                      <p>
                        <span style={{ marginRight: "" }}>
                          Last Activity ( {completedTaskCount[0]} of{" "}
                          {completedTaskCount[1]} tasks) <br></br>
                          {discussionDate &&
                            discussionDate.forEach(create => {
                              date = moment(create.updatedAt).format("DD");

                              discussionpartnerIds = getPartnerId(
                                create.partner_id,
                                partnerId
                              );
                            })}
                          {discussionpartnerIds || partnerId == true
                            ? formatDate(date)
                            : "Not Logged in yet"}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
                <span
                  className="px-4 font-weight-bold text-link cursor-pointer"
                  onClick={() => {
                    toggle(partnerId);
                  }}
                >
                  Show{" "}
                  {isOpen ? (
                    <>
                      Less Details
                      <ChevronUp />
                    </>
                  ) : (
                    <>
                      More Details
                      <ChevronDown />
                    </>
                  )}{" "}
                </span>
                <Collapse isOpen={isOpen}>{children}</Collapse>

                <hr />
              </div>
            );
          })}
        </>
      ) : !isNotEmpty(invitedPartners) ? (
        <>
          <NoRecordsFound
            hideCard={true}
            icon={partnerIcon}
            message={`No Partners Have Been Invited Yet`}
            description={`After you start getting inviting partners to participate, they will show up here.`}
          />
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default CampaignPartnerTasks;
