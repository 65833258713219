import React from "react";

// Constants
import {
  TAG_TYPE_INDUSTRIES,
  TAG_TYPE_EXPERT_ROLE
} from "../../tagType/Constants";

// Components
import SecondaryButton from "../../components/base/SecondaryButton";
import Multiselect from "../../components/base/Multiselect";
import { SwissKnifeIcon } from "../../assets/img/icons";
import Select from "../../components/base/Select";

// Api call
import { apiClient } from "../../apiClient";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";
import { AVAILABILITY } from "../../availabilityArray/Constants";
import { isBadRequest } from "../../common/http";

class SkillsAndTools extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      industriesList: [],
      expertRoleList: [],
      availability: AVAILABILITY,
      required: false
    };
    this.handleSelectChange = this.handleSelectChange.bind(this);
  }

  componentDidMount = () => {
    this.getTagValues(TAG_TYPE_INDUSTRIES);
    this.getTagValues(TAG_TYPE_EXPERT_ROLE);
  };

  handleSelectChange(value) {
    this.setState({ skills: value });
  }

  // Get Tag Values
  getTagValues = tagType => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .get(`${endpoints().publicTagAPI}?tagType=${tagType}`)
      .then(response => {
        // Check the tag type industries
        if (tagType === TAG_TYPE_INDUSTRIES) {
          this.setState({
            industriesList: response.data.data,
            loading: true
          });
          // Check the tag type expert role
        } else if (tagType === TAG_TYPE_EXPERT_ROLE) {
          this.setState({
            expertRoleList: response.data.data,
            loading: true
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  render() {
    const { currentStep, step, prev, next } = this.props;

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    const {
      availability,
      industriesList,
      expertRoleList,
      required
    } = this.state;

    let industries = [];
    let expertRoles = [];

    // Industries Dropdown List
    if (industriesList) {
      industriesList.forEach(industry => {
        industries.push({ label: industry.name, value: industry.id });
      });
    }

    // Expert Roles Dropdown List
    if (expertRoleList) {
      expertRoleList.forEach(expertRole => {
        expertRoles.push({ label: expertRole.name, value: expertRole.id });
      });
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <SwissKnifeIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-3">
            Skills + Availability
          </h2>
        </div>
        <div className="content">
          <div className="form-wrapper">
            <div className="field-wrapper mb-2">
              <Multiselect
                name="expertType"
                label="I want apply as an…"
                options={expertRoles}
                closeMenuOnSelect={true}
                placeholder="e.g. Developer, Designer, Engineer..."
              />
            </div>

            <div className="field-wrapper mb-2">
              <Multiselect
                name="industriesType"
                label="What industries have you served?"
                options={industries}
                closeMenuOnSelect={true}
                placeholder="e.g. Photoshop, Sketch, Python..."
              />
            </div>

            <div className="field-wrapper flex-column">
              <Select
                name="availability"
                label="What’s your general availability for work?"
                placeholder="Select one"
                options={availability}
                fullWidth={true}
                {...{ required }}
                smallNotificationMessage={
                  "Don’t worry, you can adjust this later if it ever changes."
                }
                isSearchable={true}
              />
            </div>
          </div>

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={() => {
                this.setState({ required: true });
                return next;
              }}
            >
              Submit Profile
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default SkillsAndTools;
