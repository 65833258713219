import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import moment from "moment";
import { formatCurrency } from "../../../../helpers/currency";
// Configs
import { getCookie, getParamsByName, isLoggedIn } from "../../../../lib/helper";
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";
//Assets
import { DownloadIcon, ReportIcon } from "../../../../assets/img/icons";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import PipelineReportStatCard from "../../../../components/reports/PipelineReportStatCard";
import Select from "../../../../components/base/Select";
import Form from "../../../../components/base/Form";

// Action
import { fetchList } from "../../../../actions/table";

// Constants
import {
  REFERRAL_STATUS_NEW,
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_CLOSED_WON
} from "../../../../referral/Constants";
import { isBadRequest } from "../../../../common/http";
import Currency from "../../../../components/Currency";
import { COOKIE_DEFAULT_CURRENCY } from "../../../../lib/cookie";
import PageSize from "../../../../components/PageSize";
class Pipeline extends Component {
  state = {
    startDate: "",
    endDate: "",
    status: "",
    refferalReportCsv: [],
    pageSize: getParamsByName("pageSize") ? getParamsByName("pageSize") : 10
  };
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this._getPiplineReoprtDetails();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }
  // piplinedetails details api call
  _getPiplineReoprtDetails = () => {
    const { startDate, endDate, status, pageSize } = this.state;
    return apiClient
      .get(
        `${
          endpoints().pipelineReportAPI
        }?startDate=${startDate}&endDate=${endDate}&status=${status}&pageSize=${pageSize}`
      )
      .then(response => {
        let successMessage;

        // ReportStatCard Counts
        const totalAmounts = response.data.totalAmount;
        const totalCounts = response.data.totalCount;
        const averageReferrals = totalAmounts / totalCounts;
        const reportData = [];
        response.data.data.forEach(data => {
          reportData.push({
            accountName: data.account_name,
            amount: data.amount,
            status: data.status,
            createdAt: data.createdAt
          });
        });
        this.setState({
          refferalReportCsv: reportData,
          totalAmount: totalAmounts,
          averageReferral: averageReferrals,
          totalCount: totalCounts,
          loading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  handleStartDate = startDate => {
    this.setState({ startDate: startDate ? startDate : "" }, () => {
      this.fetchData();
      this._getPiplineReoprtDetails();
      this.updateUrl();
    });
  };

  handleEndDate = endDate => {
    this.setState({ endDate: endDate ? endDate : "" }, () => {
      this.fetchData();
      this._getPiplineReoprtDetails();
      this.updateUrl();
    });
  };

  handleStatusChange = values => {
    const status = values ? values.value : "";
    this.setState({ status }, () => {
      this.fetchData();
      this._getPiplineReoprtDetails();
      this.updateUrl();
    });
  };

  fetchData = () => {
    const { startDate, endDate, status, pageSize } = this.state;
    this.props.actions.fetchList(
      "pipelineReports",
      `v1/report/pipeline`,
      1,
      pageSize ? pageSize : 10,
      { startDate, endDate, status, pagination: true }
    );
  };

  updateUrl() {
    const { status, startDate, endDate, pageSize } = this.state;

    const params = [];

    if (startDate) {
      params.push(`startDate=${startDate.toLocaleDateString("en-US")}`);
    }

    if (endDate) {
      params.push(`endDate=${endDate.toLocaleDateString("en-US")}`);
    }
    if (status) {
      params.push(`status=${status}`);
    }

    params.push(`pageSize=${pageSize}`);

    if (params.length > 0) {
      this.props.history.push(`/reports/pipeline?${params.join("&")}`);
    } else this.props.history.push(`/reports/pipeline`);
  }

  handlePageSizeChange = e => {
    this.setState({ pageSize: e }, () => {
      this.fetchData();
      this._getPiplineReoprtDetails();
      this.updateUrl();
    });
  };

  render() {
    const statusOptions = [
      {
        value: REFERRAL_STATUS_NEW,
        label: REFERRAL_STATUS_NEW
      },
      {
        value: REFERRAL_STATUS_APPROVED,
        label: REFERRAL_STATUS_APPROVED
      },
      {
        value: REFERRAL_STATUS_CLOSED_WON,
        label: REFERRAL_STATUS_CLOSED_WON
      }
    ];
    const headers = [
      { label: "AccountName", key: "accountName" },
      { label: "Amount", key: "amount" },
      { label: "Partner", key: "partner_id" },
      { label: "Status", key: "status" },
      { label: "createdAt", key: "createdAt" }
    ];
    return (
      <div>
        {/* /.page-heading */}
        {/* <PageTitle label="Pipeline" /> */}
        <div className="mt-4 mb-5">
          <PipelineReportStatCard
            averageReferralSize={this.state.averageReferral}
            totalValue={this.state.totalAmount}
            noOfReferralCount={this.props.pipelineReportsCount}
          />
          <div className={`page-heading cover`}>
            <div className="d-flex justify-content-between">
              <div className="mr-1">
                <PageSize onChange={e => this.handlePageSizeChange(e)} />
              </div>
              <div className="datepicker-wrapper py-1 mr-3">
                <DatePicker
                  name="startDate"
                  className="datepicker"
                  placeholderText="Start Date"
                  onChange={this.handleStartDate}
                  maxDate={new Date()}
                  selected={this.state.startDate}
                  clearIcon={null}
                />
              </div>
              <div className="datepicker-wrapper py-1 mr-3">
                <DatePicker
                  name="endDate"
                  className="datepicker"
                  placeholderText="End Date"
                  onChange={this.handleEndDate}
                  selected={this.state.endDate}
                  clearAriaLabel
                  clearIcon={null}
                  width={"150px"}
                />
              </div>
              <div className="">
                <Form>
                  <Select
                    name="status"
                    placeholder="Select Status"
                    defaultValue=""
                    options={statusOptions}
                    handleChange={e => {
                      this.handleStatusChange(e);
                    }}
                    isSearchable={true}
                    isClearable={true}
                    width={"150px"}
                    marignBottom
                  />
                </Form>
              </div>
              <div className="mt-2">
                <CSVLink
                  data={this.state.refferalReportCsv}
                  headers={headers}
                  filename={`Pipeline_Report_${moment().format(
                    "YYYY-MM-DD"
                  )}.csv`}
                >
                  <span className="download-pdf ml-auto d-flex" href="#">
                    <DownloadIcon />
                    <span className="ml-1">Export</span>
                  </span>
                </CSVLink>
              </div>
            </div>
          </div>
          <ReduxTable
            id="pipelineReports"
            apiURL={`${endpoints().pipelineReportAPI}`}
            searchDisabled
            icon={<ReportIcon />}
            params={{ status: this.state.status ? this.state.status : "" }}
          >
            <ReduxColumn field="account_name" sortBy="account_name">
              Company
            </ReduxColumn>
            <ReduxColumn field="companyName" sortBy="partner_id">
              Partner
            </ReduxColumn>
            <ReduxColumn
              field="amount"
              sortBy="amount"
              className="text-right"
              renderField={row => (
                <span>
                  {row.amount != null ? (
                    <Currency
                      amount={row.amount}
                      currencyName={getCookie(COOKIE_DEFAULT_CURRENCY)}
                    />
                  ) : (
                    ""
                  )}
                </span>
              )}
            >
              Amount
            </ReduxColumn>
            <ReduxColumn field="status" sortBy="status">
              Status
            </ReduxColumn>
            <ReduxColumn field="createdAt" sortBy="createdAt">
              Created At
            </ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

// Get ReportStatCard Counts
const mapStateToProps = state => {
  const { pipelineReports } = state.table;
  const pipelineReportsCount =
    pipelineReports && !pipelineReports.isFetching
      ? pipelineReports.totalCount
      : 0;
  return {
    pipelineReportsCount
  };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(Pipeline);
