import React, { Component } from "react";
import { toast } from "react-toastify";
import { injectStripe } from "react-stripe-elements";
import CancelButton from "../../components/base/CancelButton";

// Components
import Text from "../../components/base/Text";
import Select from "../../components/base/Select";
import Form from "../../components/base/Form";

class AddACH extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  handleSubmit = values => {
    let _this = this;
    this.props.stripe
      .createToken("bank_account", {
        country: "US",
        currency: "usd",
        routing_number: values.routing_number,
        account_number: values.account_number,
        account_holder_name: values.account_holder_name,
        account_holder_type: values.account_holder_type.value
      })
      .then(function(result) {
        if (result.token) {
          _this.props.setPaymentMethod(result.token);
          _this.props.toggle();
        } else {
          toast.warn(result.error.message, {
            toastId: "customId",
            autoClose: 5000
          });
          _this.props.toggle();
        }
        return result;
      })
      .then(result => {
        if (result.token) {
          this.props.updatePaymentMethodUi();
          window.location.reload();
        }
      });
  };

  handleReady = element => {
    this.setState({ cardElement: element });
  };

  render() {
    const { toggle } = this.props;
    const accountHolderType = [
      { value: "individual", label: "Individual" },
      { value: "company", label: "Company" }
    ];
    return (
      <Form
        initialValues={{
          routing_number: "",
          account_number: "",
          account_holder_name: "",
          account_holder_type: ""
        }}
        onSubmit={values => this.handleSubmit(values)}
      >
        <div className="form-wrapper">
          <div className="grouped-fields">
            <div className="field-wrapper">
              <Text
                name="routing_number"
                label="Routing number"
                placeholder="Enter routing number"
                required={true}
                error={""}
              />
            </div>
            <div className="field-wrapper">
              <Text
                name="account_number"
                label="Account number"
                placeholder="Enter account number"
                required={true}
                error={""}
              />
            </div>
          </div>

          <div className="field-wrapper">
            <Text
              name="account_holder_name"
              label="Account holder name"
              placeholder="Enter account holder name"
              required={true}
              error={""}
            />
          </div>

          <div className="field-wrapper">
            <Select
              name="account_holder_type"
              label="Account holder type"
              placeholder="Enter account holder type"
              options={accountHolderType}
              error={""}
              isSearchable={true}
              required
            />
          </div>
        </div>
        <div className="btn-wrapper mt-4 d-flex justify-content-center">
          <CancelButton onClick={toggle} className="mr-2" />
          <button
            type="submit"
            className="btn btn-secondary h6-5-important m-1"
            onContextMenu={e => e.preventDefault()}
          >
            <span>Add Credit Card</span>
          </button>
        </div>
      </Form>
    );
  }
}

export default injectStripe(AddACH);
