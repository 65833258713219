import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import Select from "../../../components/base/Select";
import Form from "../../../components/base/Form";
import CancelButton from "../../../components/base/CancelButton";
import SaveButton from "../../../components/base/SaveButton";

const EditClientModal = ({
  modal,
  setModal,
  clientList,
  setCustomClient,
  updateProject
}) => {
  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      keyboard={true}
      backdrop="static"
      className={["edit-task-modal", "edit-client-modal"].join(" ")}
    >
      <ModalHeader toggle={setModal}>
        <h4 className={["font-weight-bold"].join(" ")}>
          Assign Client to Project
        </h4>
      </ModalHeader>
      <Form
        initialValues={{
          projectCustomer: ""
        }}
        className={["d-block"].join(" ")}
        onSubmit={values => {
          values.customerId = values.projectCustomer
            ? values.projectCustomer.value
            : "";
          values.customerName = values.projectCustomer
            ? values.projectCustomer.label
            : "";

          updateProject(values);
          setCustomClient(
            values.projectCustomer ? values.projectCustomer.label : ""
          );
          setModal();
        }}
      >
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper flex-column">
              <Select
                name="projectCustomer"
                label="Select project Client from the dropdown below"
                placeholder="Customer"
                error="Project Client is required"
                required
                options={clientList}
                isSearchable={true}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={setModal} className="mr-2" />
            <SaveButton />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditClientModal;
