import React from "react";

// View components
import UpdateInvoiceForm from "./UpdateInvoiceForm";

const InvoiceDetails = props => {
  return (
    <div>
      <UpdateInvoiceForm history={props.history} match={props.match} />
    </div>
  );
};

export default InvoiceDetails;
