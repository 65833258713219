import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownItem } from "reactstrap";

// Component
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import MoreDropdown from "../../../../components/base/MoreDropdown";
import DefaultContent from "../../../../components/content/DefaultContent";
import DeleteModal from "../../../../components/base/DeleteModal";
// Configs
import { endpoints } from "../../../../configs";

// Tags
import {
  deleteReferralStatus,
  addReferralStatus,
  updateReferralStatus
} from "../../../../actions/referralStatus";

const StatusSection = props => {
  const { referralStatusToggle } = props;
  const [deleteStatusModal, setdeleteStatusModal] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(false);

  /**
   * Delete tag type
   *
   * @param id
   */
  const handleDelete = id => {
    props.actions.deleteReferralStatus(id);
  };

  // Render the status modal

  return (
    <div>
      <DefaultContent>
        <ReduxTable
          id="referralStatus"
          apiURL={`${endpoints().referralStatusAPI}`}
          onRowClick={row => {
            referralStatusToggle(row);
          }}
          // showHeader
          searchDisabled
        >
          <ReduxColumn
            minWidth={"130px"}
            width={"130px"}
            maxWidth={"130px"}
            field="status"
            disableOnClick
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            minWidth={"120px"}
            width={"120px"}
            maxWidth={"120px"}
            field="group"
            disableOnClick
          >
            Group
          </ReduxColumn>
          <ReduxColumn
            minWidth={"210px"}
            width={"210px"}
            maxWidth={"210px"}
            field="salesforce_status"
            disableOnClick
          >
            Salesforce Status
          </ReduxColumn>

          <ReduxColumn
            minWidth={"100px"}
            width={"100px"}
            maxWidth={"100px"}
            disableOnClick
            className="action-column"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      referralStatusToggle(row);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setCurrentStatus(row);
                      setdeleteStatusModal(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          isOpen={deleteStatusModal}
          toggle={() => {
            setdeleteStatusModal(prevState => !prevState);
          }}
          title="Delete Referral Status"
          id={currentStatus.id}
          label={currentStatus.status}
          deleteFunction={handleDelete}
        />
      </DefaultContent>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        deleteReferralStatus,
        addReferralStatus,
        updateReferralStatus
      },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(StatusSection);
