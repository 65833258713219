import React, { useState } from "react";
import {
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "reactstrap";

// Components
import Form from "../../../components/base/Form";
import DatePicker from "react-date-picker";
import CancelButton from "../../../components/base/CancelButton";
import Button from "../../../components/base/Button";
import Label from "../../../components/base/Label";
import TextArea from "../../../components/base/TextArea";
import Number from "../../../components/base/Number";

const ExtendProjectModal = ({ modal, setModal, extendProject }) => {
  const [extendDate, setExtendDate] = useState("");
  const [additionalHours, setAdditionalHours] = useState("");
  const [extendNotes, setExtendNotes] = useState("");

  const handleAdditionalHours = e => {
    const { value } = e.target;
    setAdditionalHours(value);
  };

  const handleExtendNotes = e => {
    const { value } = e.target;
    setExtendNotes(value);
  };

  const _submit = values => {
    extendProject(additionalHours, extendDate, extendNotes);
    setModal(prevState => !prevState);
  };

  const initialValues = {
    additionalHours: "",
    notes: ""
  };

  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          _submit(values);
        }}
      >
        <ModalHeader toggle={setModal}>
          <h4 className={["font-weight-bold"].join(" ")}>Extend Project</h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div className="field-wrapper">
                  <Number
                    id={`additionalHours`}
                    name={`additionalHours`}
                    label="Additional Hours"
                    placeholder="Additional Hours"
                    onChange={handleAdditionalHours}
                    required
                    error=""
                  />
                </div>
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="field-wrapper">
                  <FormGroup style={{ position: "relative" }}>
                    <Label required>Extention Date</Label>
                    <DatePicker
                      name={`datepicker-extend-project`}
                      className="datepicker"
                      dayPlaceholder="MM/DD/YYYY"
                      onChange={date => setExtendDate(date)}
                      minDate={new Date()}
                      value={extendDate}
                      required
                      clearIcon={null}
                    />
                  </FormGroup>
                </div>
              </div>
            </div>

            <div className="field-wrapper">
              <TextArea
                name="notes"
                label="Your message"
                placeholder={"Your message"}
                onChange={handleExtendNotes}
                required
                error=""
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton
              onClick={() => {
                setModal(prevState => !prevState);
              }}
            />
            <Button label="Extend" type="submit" className="btn btn-primary" />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default ExtendProjectModal;
