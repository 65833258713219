import React from "react";
import { CrossIcon } from "../../../assets/img/icons";

const HowItWorksItem = ({ imgsrc, title, description, align, number }) => (
  <div
    className={`how-it-works-item ${
      align.length > 0 && align === "right" ? "item-right" : ""
    }`}
  >
    <div className="img-wrapper">
      {imgsrc ? (
        <img src={imgsrc} alt="how-it-works" />
      ) : (
        <div className="img-placeholder" />
      )}
    </div>
    <div className="content-wrapper">
      <h5>
        <span className="number">{number}</span>
        <span className="inline-number">Step {number}</span>
        <span className="title">{title}</span>
      </h5>
      <p className="text-inline-grayed mb-0">{description}</p>
    </div>
  </div>
);

const HowItWorks = props => {
  const { title, close, children, handleClose } = props;
  return (
    <div className="how-it-works">
      <div className="d-flex justify-content-between">
        <h4 className="mb-4 ml-4 font-weight-bold d-block">{title}</h4>
        <button
          className={`btn-plain btn mb-4 mr-4 ${close ? "d-block" : "d-none"}`}
          onClick={handleClose}
        >
          <CrossIcon />
        </button>
      </div>
      {children}
    </div>
  );
};

export { HowItWorks, HowItWorksItem };
