import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import Page404 from "../Page404";
import { Link } from "react-router-dom";

// Components
import Form from "../../components/base/Form";
import DeleteButton from "../../components/base/DeleteButton";
import CancelButton from "../../components/base/CancelButton";
import DeleteModal from "../../components/base/DeleteModal";
import SaveButton from "../../components/base/SaveButton";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import toast from "../../components/base/Toast";
import SelectDropdown from "../../components/base/SelectDropdown";

//Constants
import { PARTNER_ROLE, USER_STATUS_INVITED } from "../../roles/Constants";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Constants
import {
  SUPER_ADMIN_ROLE,
  COMPANY_ADMIN_ROLE,
  COMPANY_MANAGER_ROLE
} from "../../roles/Constants";
import {
  COOKIE_DEFAULT_CURRENCY,
  COOKIE_SESSION_TOKEN,
  COOKIE_TERMINOLOGY,
  COOKIE_USER_ID,
  clearAllCookies
} from "../../lib/cookie";
import { GENERAL_TAB } from "../../supportPortal/Constants";

// Helper
import {
  toString,
  validateStrongPassword,
  setCookie,
  clearCookie,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import { isBadRequest } from "../../common/http";

//Assets
import { ChevronRight } from "../../assets/img/icons";

import UserFormFields from "./UserFormFields";

import { connect } from "react-redux";

import SlackSection from "./Slack";
import { TAG_TYPE_CURRENCY } from "../../tagType/Constants";
import Tag from "../../Constants/Tag";
import { Local } from "../../Constants/LocalStorage";
import UserRolePermissionService from "../../services/UserRolePermissionService";
import CertificateTab from "./CertificateTab";
import AvatarCard from "../../components/base/AvatarCard";
import DateTime from "../../lib/DateTime";

class UpdateUserForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      UserDetails: [],
      loading: false,
      isInvalidRequest: false,
      passwordErrors: {
        confirmPasswordError: "",
        strongPasswordError: ""
      },
      activeTab: 1,
      sortByOptions: [
        {
          value: "company_name:ASC",
          label: "Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ],
      deleteModal: false,
      timeZones: []
    };
  }

  componentDidMount = () => {
    this.fetchUserDetails();
    this.getTerminology();
    this.getTagValues(TAG_TYPE_CURRENCY);
    this.getTimeZones();
  };
  getTimeZones = () => {
    try {
      let timeZones = DateTime.getTimeZones();
      let timeZoneList = new Array();
      for (let i = 0; i < timeZones.length; i++) {
        timeZoneList.push({
          label: timeZones[i],
          value: timeZones[i]
        });
      }
      this.setState({ timeZones: timeZoneList });
    } catch (err) {
      console.log(err);
    }
  };

  getTagValues = async tagType => {
    try {
      this.setHeaders();

      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${tagType}`
      );
      const results = response.data.data;

      // Check the tag type skills
      if (results && results.length > 0) {
        results.forEach(tag => {
          if (
            tag.type == TAG_TYPE_CURRENCY &&
            tag.defaultValue == Tag.IsDefault
          ) {
            setCookie(COOKIE_DEFAULT_CURRENCY, tag.name);
          }
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };
  getTerminology = async () => {
    const url = `${endpoints().terminologyAPI}`;
    const terminologyName = [];
    const response = await apiClient.get(url);
    const list = response.data.data;
    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        terminologyName.push({
          custom_name: list[i].custom_name,
          system_name: list[i].system_name
        });
      }
    }
    localStorage.setItem(Local.TERMINOLOGY, JSON.stringify(terminologyName));
  };

  getRolePermission = async role => {
    const permissionList = await UserRolePermissionService.search(role);
    if (permissionList && permissionList.length > 0) {
      let values = permissionList.map(obj => obj.value);

      // Convert the array to a comma-separated string
      let valuesString = values.join(",");

      localStorage.setItem(Local.PERMISSION, valuesString);
    }
  };
  async fetchUserDetails() {
    try {
      let response = await this.props.context._getUserDetailsById(
        this.props.match.params.id
      );
      if (response.error) {
        this.setState({
          isInvalidRequest: true
        });
      } else {
        this.setState({
          UserDetails: response,
          loading: true
        });
      }
    } catch (e) {
      this.setState({
        isInvalidRequest: true
      });
    }
  }

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save name form in the final
    this._updateUser(this._toArray(values));
  };
  //  update API Call
  async _updateCompanyUser(data) {
    let value = {
      slackId: data.slackId
    };
    try {
      const response = await apiClient.put(
        `${endpoints().companyAPI}/user/slackUpdate/${
          this.props.match.params.id
        }`,
        value
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  }

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define user constants
    const email = values.email;
    const passwordSuccess = this.validatePassword(
      values.newPassword,
      values.confirmPassword
    );

    if (!email) {
      success = false;
    }

    if (!passwordSuccess) {
      success = false;
    }

    return success;
  }

  validatePassword = (newPassword, confirmPassword) => {
    let success = true;
    const strongPasswordError = validateStrongPassword(newPassword);

    if (strongPasswordError) {
      this.setState({
        passwordErrors: {
          strongPasswordError
        }
      });
      success = false;
    } else if (newPassword && !confirmPassword) {
      this.setState({
        passwordErrors: {
          confirmPasswordError: "Confirm password is required",
          strongPasswordError: ""
        }
      });
      success = false;
    } else if (
      newPassword &&
      confirmPassword &&
      newPassword !== confirmPassword
    ) {
      this.setState({
        passwordErrors: {
          confirmPasswordError: "Confirm password did not match",
          strongPasswordError: ""
        }
      });
      success = false;
    }

    return success;
  };

  // To Array
  _toArray(values) {
    values.firstName = toString(values.firstName);
    values.lastName = toString(values.lastName);
    values.email = toString(values.email);
    values.internal_user = values.internal_user;
    values.roleId = toString(
      values.roleId ? values.roleId.value : values.roleId
    );
    values.time_zone =
      values && values.time_zone && values.time_zone.value
        ? values.time_zone.value
        : null;

    return values;
  }

  // User update API Call
  _updateUser(data) {
    return apiClient
      .put(`${endpoints().userAPI}/${this.props.match.params.id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        toast.success(successMessage);
        this._getUserDetails();
        this.props.history.goBack();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  //handle resend Invite
  resendInvite = member => {
    return apiClient
      .post(`${endpoints().userAPI}/resendInvite`, member)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message, {});
          console.error(errorMessage);
        }
      });
  };
  // User delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().userAPI}/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;

          toast.success(successMessage);

          // Redirect user to there respective tab
          this.props.history.push("/users");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Login By Admin
  loginByAdmin = email => {
    return apiClient
      .post(`${endpoints().userAPI}/loginByAdmin/${email}`)
      .then(async response => {
        if (response && response.data) {
          const { token, role, userId } = response.data;

          clearAllCookies();

          clearCookie("googleAuthToken");
          clearCookie("office365AuthToken");
          clearCookie("linkedinAuthToken");
          clearCookie("salesforceAuthToken");

          setCookie(COOKIE_SESSION_TOKEN, token);
          setCookie(COOKIE_USER_ID, userId);
          await this.getTerminology();
          await this.getTagValues();
          await this.getRolePermission(role);

          this.props.history.push("/dashboard");

          window.location.reload();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // User details api call by id
  _getUserDetails = () => {
    return apiClient
      .get(`${endpoints().userAPI}/${this.props.match.params.id}`)
      .then(response => {
        this.setState({ UserDetails: response.data, loading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Active tab
  toggle = tab => {
    this.setState({
      activeTab: tab
    });
  };

  handleAdminAction = value => {
    const { email } = this.state.UserDetails;
    switch (value) {
      case "Login As":
        return this.loginByAdmin(email);
      case "Resend Invite":
        return this.resendInvite(this.state.UserDetails);
      case "Delete":
        return this.handleDelete();
      default:
        return "";
    }
  };

  // Partners by Admin and manager
  renderPartnerTable() {
    // get mamger id
    const managerId = this.props.match.params.id;
    // params
    let params = {
      managerUserId: managerId
    };

    return (
      <div className="mt-4 mb-5">
        <ReduxTable
          id="partners"
          apiURL={`${endpoints().partnerAPI}/search`}
          params={params}
          message="No Partners found"
          searchPlaceholder="Search"
          sortByOptions={this.state.sortByOptions}
          newTableHeading
          showHeader
          paramsToUrl={true}
          history={this.props.history}
        >
          <ReduxColumn
            type="link"
            isClickable="false"
            field="companyName"
            sortBy="company_name"
            renderField={row => (
              <Link to={`/edit/partner/public-profile?id=${row.id}`}>
                <span>{row.companyName}</span>
              </Link>
            )}
          >
            Name
          </ReduxColumn>
          <ReduxColumn field="email" width="220px" disableOnClick>
            Email
          </ReduxColumn>
          <ReduxColumn field="partnerTypeName" disableOnClick>
            Partner Type
          </ReduxColumn>
          <ReduxColumn field="partnerTierName" disableOnClick>
            Partner Tier
          </ReduxColumn>
          <ReduxColumn field="managerUserName" disableOnClick>
            Manager
          </ReduxColumn>
        </ReduxTable>
      </div>
    );
  }

  render() {
    const { activeTab } = this.state;

    const adminActions =
      this.state.UserDetails.status === USER_STATUS_INVITED
        ? [
            {
              value: "Login As",
              label: "Login As"
            },
            {
              value: "Resend Invite",
              label: "Resend Invite"
            },
            {
              value: "Delete",
              label: "Delete"
            }
          ]
        : [
            {
              value: "Login As",
              label: "Login As"
            },
            {
              value: "Delete",
              label: "Delete"
            }
          ];

    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return "";
    }
    let { roleId, roleName } = this.state.UserDetails;

    roleId = {
      value: roleId ? roleId : "",
      label: roleName ? roleName : ""
    };
    const {
      firstName,
      lastName,
      email,
      slackId,
      internal_user,
      isPartnerUser,
      time_zone
    } = this.state.UserDetails;

    const initialValues = {
      firstName,
      lastName,
      email,
      roleId: roleId,
      slackId,
      internal_user: internal_user == 1 ? true : false,
      time_zone: this.state.timeZones.find(data => data.value === time_zone)
    };
    const { deleteModal } = this.state;
    return (
      <>
        <div>
          <div className="row">
            <div className="ml-3">
              <h5 className="m-0 font-weight-bold">User</h5>

              {/* Breadcrumb Start */}
              <div className="d-flex align-items-center pt-4 pb-3">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    this.props.history.push(`/users`);
                  }}
                >
                  <span>{this.state.UserDetails.firstName}</span>
                </span>
                <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
                  <ChevronRight />
                </span>
                <span className="cursor-pointer">
                  <span>
                    {activeTab === GENERAL_TAB ? "General" : "Partners"}
                  </span>
                </span>
              </div>
            </div>
            {/* Breadcrumb End */}

            <div className="ml-auto mr-3 mt-1">
              {(isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)) && (
                <SelectDropdown
                  buttonLabel={"Admin actions"}
                  dropdownLinks={adminActions}
                  color={"gray"}
                  hideCaret
                  handleChange={this.handleAdminAction}
                />
              )}
            </div>
            <div className="float-right mr-3 mt-1">
              <DeleteModal
                isOpen={deleteModal}
                toggle={() => {
                  this.setState({ deleteModal: false });
                }}
                title="Delete User"
                label={firstName}
                deleteFunction={this.handleDelete}
              />
              <DeleteButton
                label="Delete User"
                onClick={() =>
                  this.setState({
                    deleteModal: true
                  })
                }
              />
            </div>
          </div>

          <Nav tabs className="admin-tabs mb-0">
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === 1
                })}
                onClick={() => {
                  this.toggle(1);
                }}
              >
                General
              </NavLink>
            </NavItem>
            {(roleId.value === SUPER_ADMIN_ROLE ||
              roleId.value === COMPANY_ADMIN_ROLE ||
              roleId.value === COMPANY_MANAGER_ROLE) && (
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === 2
                  })}
                  onClick={() => {
                    this.toggle(2);
                  }}
                >
                  Partners
                </NavLink>
              </NavItem>
            )}
            {(roleId.value === SUPER_ADMIN_ROLE ||
              roleId.value === COMPANY_ADMIN_ROLE ||
              roleId.value === COMPANY_MANAGER_ROLE ||
              roleId.value === PARTNER_ROLE) && (
              <>
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 3
                    })}
                    onClick={() => {
                      this.toggle(3);
                    }}
                  >
                    Slack
                  </NavLink>
                </NavItem>
              </>
            )}
            {(roleId.value === SUPER_ADMIN_ROLE ||
              roleId.value === COMPANY_ADMIN_ROLE ||
              roleId.value === COMPANY_MANAGER_ROLE ||
              roleId.value === PARTNER_ROLE) &&
              isPartnerUser && (
                <>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === 4
                      })}
                      onClick={() => {
                        this.toggle(4);
                      }}
                    >
                      Certificate
                    </NavLink>
                  </NavItem>
                </>
              )}
          </Nav>
          <TabContent activeTab={activeTab}>
            {/* Edit User Form */}
            <TabPane tabId={1}>
              <Form
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={values => {
                  this._submit(values);
                }}
              >
                <UserFormFields
                  defaultValue={roleId}
                  UserDetails={this.state.UserDetails}
                  userId={this.props.match.params.id}
                  errorMessage={this.state.passwordErrors}
                  loggedInUser={this.props.loggedInUser}
                />

                <div className="mb-4">
                  <div className="float-left">
                    <CancelButton onClick={() => this.props.history.goBack()} />
                    <SaveButton />
                  </div>
                </div>
              </Form>
            </TabPane>
            {/* Partner table */}
            <TabPane tabId={2}>{this.renderPartnerTable()}</TabPane>
            <TabPane tabId={3}>
              <Form
                enableReinitialize={true}
                initialValues={initialValues}
                onSubmit={values => {
                  this._updateCompanyUser(values);
                }}
              >
                <SlackSection
                  UserDetails={this.state.UserDetails}
                  userId={this.props.match.params.id}
                />
                <div className="mb-4">
                  <div className="float-left">
                    <SaveButton />
                  </div>
                </div>
              </Form>
            </TabPane>
            <TabPane tabId={4}>
              <div>
                <ReduxTable
                  id="certificate"
                  apiURL={`${endpoints().userAPI}/certificate/${
                    this.props.match.params.id
                  }`}
                  searchPlaceholder="Search"
                  message="Get started by selecting New Account."
                  history={this.props.history}
                >
                  <ReduxColumn
                    Width={"160px"}
                    isClickable="false"
                    disableOnClick
                    className="text-center"
                    field="name"
                    renderField={row => (
                      <AvatarCard companyName={row.name} url={row.image} />
                    )}
                  >
                    Name
                  </ReduxColumn>
                </ReduxTable>
              </div>
            </TabPane>
          </TabContent>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(UpdateUserForm);
