import React from "react";
import { toast } from "react-toastify";
import { InlineInfoIcon } from "../../assets/img/icons";

const Toast = {
  success: message => {
    const CustomSuccessToast = (
      <div className={"d-flex text-capitalize"}>
        <InlineInfoIcon />
        <span className={"ml-1"}>{message}</span>
      </div>
    );

    return toast.success(CustomSuccessToast);
  },

  error: message => {
    const CustomErrorToast = (
      <div className={"d-flex text-capitalize"}>
        <InlineInfoIcon />
        <span className={"ml-1"}>{message}</span>
      </div>
    );

    return toast.error(CustomErrorToast);
  },

  warn: message => {
    const CustomWarnToast = (
      <div className={"d-flex text-capitalize"}>
        <InlineInfoIcon />
        <span className={"ml-1"}>{message}</span>
      </div>
    );

    return toast.warn(CustomWarnToast);
  }
};

export default Toast;
