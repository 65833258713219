import SystemName from "../Constants/SystemName";
import Terminology from "../lib/Terminology";

// Admin Dashboard
export const PARTNER_MANAGER_DASHBOARD = `${Terminology.get(
  SystemName.PARTNER_MANAGER
)} Dashboard`;
export const SERVICE_DELIVERY_DASHBOARD = "Service Delivery Dashboard";

// Partner Dashboard
export const PARTNER_DASHBOARD_OPEN_DEALS_COUNT = 3;
export const PARTNER_DASHBOARD_MY_REFERRALS_COUNT = 3;
export const PARTNER_DASHBOARD_NULL_REFERRALS_COUNT = 0;
export const PARTNER_DASHBOARD_NULL_DEALS_COUNT = 0;
