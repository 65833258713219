import React from "react";

// Components
import SourcePartners from "./SourcePartners";
import EstimatedEarnings from "./EstimatedEarnings";
import DealStatus from "./DealStatus";

// Helper
import {
  isPartner,
  getUserId,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../lib/helper";

// Constant
import DealPartnerTier from "./DealPartnerTier";

import { useSelector } from "react-redux";

const SideBar = props => {
  let dealPartner = "";
  if (props.currentPartner) {
    dealPartner = props.currentPartner;
  }
  const dealStatus =
    (props.dealDetails && props.dealDetails.status) || props.dealStatus || "";
  const dealPartnerTier =
    (props.dealDetails &&
      props.dealDetails.partnerDetails &&
      props.dealDetails.partnerDetails.partnerTier &&
      props.dealDetails.partnerDetails.partnerTier.name) ||
    (props.currentPartner &&
      props.currentPartner.partnerTier &&
      props.currentPartner.partnerTier.name) ||
    "";
  const partnerTierCommissionPercentages =
    props.partnerTierCommissionPercentages;

  const currentUser = useSelector(state => state.user);

  return (
    <div className="details-body-sidebar">
      {/* Source Partner */}
      {(isSuperAdmin(currentUser && currentUser.roleId) ||
        isCompanyAdmin(currentUser && currentUser.roleId) ||
        isPartner(currentUser && currentUser.roleId) ||
        isCompanyManager(currentUser && currentUser.roleId)) && (
        <SourcePartners
          showStatus
          dealId={props.dealId}
          currentUserId={getUserId()}
          isDealUpdate={props.isDealUpdate}
          dealDetails={props.dealDetails}
          dealStatus={dealStatus}
          setDealPartners={props.setDealPartners}
          allowPartnerSelect={props.allowPartnerSelect}
        />
      )}

      {/* Deal status  */}
      {!props.publicDealForm && props.dealDetails && (
        <DealStatus status={dealStatus} />
      )}
      {/* Earned Estimation */}
      {isSuperAdmin(currentUser && currentUser.roleId) ||
      isCompanyAdmin(currentUser && currentUser.roleId) ||
      isPartner(currentUser && currentUser.roleId) ||
      isCompanyManager(currentUser && currentUser.roleId) ? (
        <EstimatedEarnings
          handleChange={props.handleEstimatedEariningChange}
          estimatedEarning={props.estimatedEarning}
        />
      ) : (
        <SourcePartners
          dealId={props.dealId}
          currentUserId={getUserId()}
          isDealUpdate={props.isDealUpdate}
          dealDetails={props.dealDetails}
          dealStatus={dealStatus}
          setDealPartners={props.setDealPartners}
          dealPartner={dealPartner}
          showStatus
        />
      )}
    </div>
  );
};

export default SideBar;
