import React from "react";
import LazyLoad, { forceVisible } from "react-lazyload";
import { Link } from "react-router-dom";

// Variable
import variables from "../scss/_variables.scss";

// Assets
import headerTriangles from "../assets/header-triangles.svg";
import { MessageCircleIcon } from "../assets/img/icons";

import { METACX_EVENT_MARKETPLACE_LANDING_PAGE_PLAYBOOK_CLICK } from "../metaCXEvents/Constants";

// Library
import { metaCXTrack } from "../lib/metaCX";

import { toast } from "react-toastify";

class MarketplaceAppCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deletePlaybookModal: false,
      deletePlaybook: false,
      cardLink:
        this.props.isPreview === "true"
          ? "#"
          : this.props.link
          ? this.props.link
          : "#"
    };
    this.renderUnreadMessages = this.renderUnreadMessages.bind(this);
    this.renderAlertNotification = this.renderAlertNotification.bind(this);
  }

  handledelete = () => {
    this.props.delete();
  };

  componentDidMount() {
    forceVisible();
  }

  renderUnreadMessages() {
    const { notifications } = this.props;
    if (notifications !== undefined && notifications.newMessages !== null) {
      return (
        <div className="new-message">
          <MessageCircleIcon />
          {notifications.newMessages}
        </div>
      );
    }
  }

  renderAlertNotification() {
    const { notifications } = this.props;
    if (
      notifications !== undefined &&
      notifications.notificationAlertLabel !== null
    ) {
      return (
        <div className="new-alert-message">
          {notifications.notificationAlertLabel}
        </div>
      );
    }
  }

  // Open the link
  openLink() {
    const { link, openLinkInNewTab } = this.props;
    if (link) {
      openLinkInNewTab ? window.open(link) : this.props.history.push(link);
    }
  }

  // Set Card Link
  setCardLink() {
    const { openLinkInNewTab } = this.props;
    if (openLinkInNewTab) {
      this.setState({
        cardLink: "#"
      });
    } else {
      this.setState({
        cardLink:
          this.props.isPreview === "true"
            ? "Javascript:void(0);"
            : this.props.link
            ? this.props.link
            : "#"
      });
    }
  }

  // Reset Card Link
  resetCardLink() {
    this.setState({
      cardLink: "#"
    });
  }

  render() {
    const multiLineElipsisStyle = {
      fontSize: "small",
      display: "-webkit-box",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      overflow: "hidden"
    };

    // props
    const {
      landingPageName,
      data,
      size,
      openLinkInNewTab,
      selectApps,
      removeApp,
      partnerDetails,
      showCardBody,
      isActive,
      handleAppList,
      isPreview,
      bannerImageUrl,
      boxShadow,
      placeHolder,
      showOption,
      isMarketPlaceLandingPage,
      settings
    } = this.props;

    const cardBoxStyle = {
      height: showCardBody ? "350px" : "",
      width: this.props.fullWidth ? "" : "255px"
    };

    // Playbook details
    const { id, price, app_logo, pricingType, app_name, summary } = data;

    const name = app_name;
    const summarys = summary;
    const bannerColor = data.app_color;

    const cursorPointer = isPreview === "true" ? "" : "cursor-pointer";

    const ImageURL = app_logo || bannerImageUrl;

    const isActivePlaybooks = isActive
      ? "active-playbooks far fa-check-circle"
      : "inactive-playbooks far fa-circle";

    const activePlaybooks = `float-right ${cursorPointer} ${isActivePlaybooks}`;
    const removeSelectPlaybook = `float-right cursor-pointer remove-expert far fa-times-circle`;

    const TagName = this.props.tag || Link;

    return (
      <TagName
        id="card-link"
        {...(TagName === "a"
          ? { href: this.state.cardLink }
          : { to: this.state.cardLink })}
        style={{
          color: "#212529",
          cursor: isPreview ? "default" : "pointer",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}
        className={`
          ${boxShadow !== false ? "playbook-card-link" : ""}
          ${isPreview === "true" ? "" : size ? size : "col-4"}
          mb-4
          text-decoration-none
        `}
        key={id}
        onClick={e => {
          // Track MetaCX event for click on Playbook card
          isMarketPlaceLandingPage &&
            metaCXTrack(
              METACX_EVENT_MARKETPLACE_LANDING_PAGE_PLAYBOOK_CLICK,
              {
                landingPageName,
                playbookId: `${id}`,
                playbookName: name,
                currentPrice: `${price.currentPrice}`,
                pricingType
              },
              settings,
              () => {
                e.preventDefault();
              }
            );
          if (openLinkInNewTab && TagName !== "a") {
            e.preventDefault();
            this.openLink();
          }
        }}
      >
        <div className={`${cursorPointer} card `} style={cardBoxStyle}>
          <LazyLoad>
            <div
              className="card-header"
              id="card-image"
              style={{
                backgroundImage: `url(${
                  bannerImageUrl
                    ? bannerImageUrl
                    : ImageURL
                    ? ImageURL
                    : headerTriangles
                })`,
                backgroundPosition: "center center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat"
              }}
              onMouseOver={e => {
                if (showOption) {
                  e.preventDefault();
                  if (e.target.id === "card-image") {
                    this.setCardLink();
                  } else {
                    this.resetCardLink();
                  }
                }
              }}
              onClick={e => {
                if (showOption) {
                  e.preventDefault();
                  if (e.target.id === "card-image") {
                    this.openLink();
                  }
                }
              }}
            >
              {selectApps && (
                <span
                  onClick={() => handleAppList(id)}
                  className={activePlaybooks}
                  style={{
                    right: "20px",
                    top: "0.75rem",
                    position: "absolute",
                    color: "white",
                    fontSize: "50px"
                  }}
                />
              )}
              <div id="remove-playbook">
                {removeApp && (
                  <span
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleAppList(id);
                      toast.success("App Removed Successfully");
                    }}
                    className={removeSelectPlaybook}
                    onMouseOver={e => {
                      if (e.target.id === "remove-playbook") {
                        this.setCardLink();
                      } else {
                        this.resetCardLink();
                      }
                    }}
                  />
                )}
              </div>

              {this.renderUnreadMessages()}
              {this.renderAlertNotification()}
            </div>
          </LazyLoad>
          <div className={"body-wrapper"}>
            {showCardBody !== false ? (
              <div
                className="card-body pt-1"
                onClick={() => showOption && this.openLink()}
              >
                <p
                  className={`${cursorPointer} card-title font-weight-bold mb-0 mt-2 d-inline-block text-truncate`}
                  style={multiLineElipsisStyle}
                >
                  {!name && placeHolder ? "" : name}
                </p>
                <p
                  className={`${cursorPointer} card-title mb-0 mt-2`}
                  style={multiLineElipsisStyle}
                >
                  {!summarys ? "" : summarys}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
          {partnerDetails && (
            <div
              style={{ height: "40px" }}
              className="d-flex justify-content-start m-1"
            >
              {data && data.partnerLogo && (
                <div>
                  <img
                    src={data.partnerLogo}
                    alt="Selected File"
                    className=""
                    style={{ maxWidth: "40px", maxHeight: "40px" }}
                  />
                </div>
              )}
              <div
                className="pt-3 pl-3 color-dark-grey"
                style={multiLineElipsisStyle}
              >
                {data.companyName}
              </div>
            </div>
          )}
        </div>
      </TagName>
    );
  }
}

export default MarketplaceAppCard;
