import React, { useState } from "react";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import { endpoints } from "../../configs";
import Toast from "../../components/base/Toast";
import { apiClient } from "../../apiClient";
import { useDispatch } from "react-redux";
import { fetchList } from "../../actions/table";

const CrossBeam = props => {
  let { history, sortByOptions } = props;
  const [selectedIds, setSelectedIds] = useState("");
  let dispatch = useDispatch();

  const handleBulkUpdate = ids => {
    setSelectedIds(ids);
  };

  const deleteUserFunction = async () => {
    try {
      await apiClient
        .delete(`${endpoints().cross_beamAPI}/delete/?ids=${selectedIds}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
          }
        })
        .then(res => {
          dispatch(
            fetchList(
              "cross_beam",
              `${endpoints().cross_beamAPI}/search`,
              1,
              10,
              { pagination: true }
            )
          );
        });
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <>
      <div className="row mb-2 ">
        <div className="col-6">
          <h5 className="mt-2">Cross Beam</h5>
        </div>
        <div className="col-6">
          <button
            type="button"
            className="m-1 pull-right btn btn-danger"
            onClick={() => {
              deleteUserFunction();
            }}
            disabled={selectedIds && selectedIds.length > 0 ? false : true}
          >
            Delete
          </button>
        </div>
      </div>
      <ReduxTable
        id="cross_beam"
        apiURL={`${endpoints().cross_beamAPI}/search`}
        history={history}
        showHeader
        params={{
          pagination: true
        }}
        bulkSelect
        onBulkSelect={e => {
          handleBulkUpdate(e);
        }}
        sortByOptions={sortByOptions}
        newTableHeading
        paramsToUrl={true}
      >
        <ReduxColumn field="company_name" sortBy="company_name">
          Company Name
        </ReduxColumn>
        <ReduxColumn field="name" sortBy="name">
          Name
        </ReduxColumn>
        <ReduxColumn field="phone" sortBy="phone">
          Phone
        </ReduxColumn>
        <ReduxColumn field="email" sortBy="email">
          Email
        </ReduxColumn>
        <ReduxColumn field="website" sortBy="website">
          Website
        </ReduxColumn>
        <ReduxColumn field="type" sortBy="type">
          Type
        </ReduxColumn>
        <ReduxColumn field="title" sortBy="title">
          Title
        </ReduxColumn>
        <ReduxColumn field="company_name" sortBy="company_name">
          Company Name
        </ReduxColumn>
        <ReduxColumn field="status" sortBy="status">
          Status
        </ReduxColumn>
        <ReduxColumn field="total_overlap_count" sortBy="total_overlap_count">
          Total Over Lap Count
        </ReduxColumn>
        <ReduxColumn field="shared_overlap_count" sortBy="shared_overlap_count">
          Shared Over Lap Count
        </ReduxColumn>
        <ReduxColumn field="snooze_date" sortBy="snooze_date">
          Snooze Date
        </ReduxColumn>
        <ReduxColumn field="domain" sortBy="domain">
          Domain
        </ReduxColumn>
        <ReduxColumn
          field="crossbeam_partner_org_id"
          sortBy="crossbeam_partner_org_id"
        >
          Crossbeam Partner Org Id
        </ReduxColumn>
        <ReduxColumn field="last_overlap_time" sortBy="last_overlap_time">
          Last Overlap Time
        </ReduxColumn>
        <ReduxColumn field="account_created_at" sortBy="account_created_at">
          Account CreatedAt
        </ReduxColumn>
        <ReduxColumn
          field="partnership_created_at"
          sortBy="partnership_created_at"
        >
          PartnerShip CreatedAt
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default CrossBeam;
