import React, { useState } from "react";
import Rating from "react-rating";
import { Input, Label } from "reactstrap";

// Assets
import { StarIcon, StarIconFilled } from "../assets/img/icons";

// Components
import ExpertCard from "./base/ExpertCard";

const ExpertReviewFeedBackForm = props => {
  const [expertCommunication, setExpertCommunication] = useState(0);
  const [expertWorkWithAgain, setExpertWorkWithAgain] = useState(0);
  const [expertOverallExperience, setExpertOverallExperience] = useState(0);
  const [expertReviewNotes, setExpertReviewNotes] = useState("");
  const [checkboxCommentPublic, setCheckboxCommentPublic] = useState(false);

  const handleExpertCommunication = value => setExpertCommunication(value);
  const handleExpertWorkWithAgain = value => setExpertWorkWithAgain(value);
  const handleExpertOverallExperience = value =>
    setExpertOverallExperience(value);

  const handleExpertReview = e => setExpertReviewNotes(e.target.value);

  const handleCheckboxCommentPublic = () =>
    setCheckboxCommentPublic(prevState => !prevState);

  const {
    projectExpert: { id, projectExperts },
    saveExpertFeedback
  } = props;

  return (
    <div className="feedback-component d-flex justify-content-start">
      <ExpertCard
        classnames="expert-card"
        data={projectExperts}
        size="m-0"
        key={projectExperts.id}
        certified={projectExperts.certified}
        openLinkInNewTab
      />
      <div className="rating-component">
        <div className="form-wrapper">
          <div className="rating-wrapper d-flex align-items-center">
            <Rating
              initialRating={expertCommunication}
              emptySymbol={<StarIcon />}
              fullSymbol={<StarIconFilled />}
              onChange={handleExpertCommunication}
            />{" "}
            <h5 className="font-weight-bold ml-3">Communication</h5>
          </div>
          <div className="rating-wrapper d-flex align-items-center">
            <Rating
              initialRating={expertWorkWithAgain}
              emptySymbol={<StarIcon />}
              fullSymbol={<StarIconFilled />}
              onChange={handleExpertWorkWithAgain}
            />{" "}
            <h5 className="font-weight-bold ml-3">
              Work with Again or Recommend
            </h5>
          </div>
          <div className="rating-wrapper d-flex align-items-center">
            <Rating
              initialRating={expertOverallExperience}
              emptySymbol={<StarIcon />}
              fullSymbol={<StarIconFilled />}
              onChange={handleExpertOverallExperience}
            />{" "}
            <h5 className="font-weight-bold ml-3">Overall Experience</h5>
          </div>
          <div className="field-wrapper">
            <label>Tell us about your experience with your Expert</label>
            <Input
              type="textarea"
              placeholder="Leave feedback and let others know how things went"
              style={{
                background: "#F3F3F4",
                border: "none",
                borderRadius: "5px",
                fontSize: "14px",
                minHeight: 132
              }}
              onChange={handleExpertReview}
            />
          </div>
          <div className="btn-wrapper d-flex align-items-center justify-content-stretch">
            <div className="checkbox-wrapper">
              <Label htmlFor={`expertAgreeCheck-${id}`}>
                <Input
                  id={`expertAgreeCheck-${id}`}
                  name="expertAgreeCheck"
                  type="checkbox"
                  defaultChecked={checkboxCommentPublic}
                  value={"value"}
                  onChange={handleCheckboxCommentPublic}
                />{" "}
                <span
                  className={`checkbox-placeholder ${checkboxCommentPublic &&
                    "active"}`}
                />
                <span>I don’t want my comments made public.</span>
              </Label>
            </div>
            <button
              className="btn btn-primary mr-0 ml-auto"
              onClick={() =>
                saveExpertFeedback({
                  id,
                  expertCommunication,
                  expertWorkWithAgain,
                  expertOverallExperience,
                  expertReviewNotes,
                  checkboxCommentPublic
                })
              }
            >
              Submit My Feedback
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertReviewFeedBackForm;
