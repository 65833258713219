import React from "react";
import AccountCard from "../../../components/base/AccountCard";

import Phone from "../../../components/base/Phone";
import Email from "../../../components/base/Email";

const AccountOwner = props => {
  const { accountOwner } = props;
  const phoneNumber =
    accountOwner && accountOwner.phone ? accountOwner.phone : "";
  const title = accountOwner && accountOwner.title ? accountOwner.title : "";

  return (
    <div>
      <div className="card-body pt-3 ml-3">
        <h4 className="font-weight-bold mt-4">Account Owner</h4>
        <div className="font-weight-bold mt-4">
          <AccountCard
            firstName={accountOwner.first_name}
            lastName={accountOwner.last_name}
          />
        </div>

        <div className="ml-5 pl-2">
          <div className="font-weight-bold">
            {title && (
              <span className="text-secondary ml-2">{`  ${title}`}</span>
            )}
          </div>

          {accountOwner && accountOwner.email && (
            <div className="font-weight-bold mt-1 ml-2">
              <Email
                readOnly={true}
                Email={accountOwner.email}
                className={["text-secondary", "font-weight-bold"].join(" ")}
              />
            </div>
          )}

          {phoneNumber && (
            <div className="font-weight-bold mt-2 ml-2">
              <Phone
                readOnly={true}
                phone={phoneNumber}
                className={["text-secondary", "font-weight-bold"].join(" ")}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountOwner;
