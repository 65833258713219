import React from "react";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../lib/helper";
import {
  LEAD_PARTNER_STATUS_ACCEPTED,
  LEAD_PARTNER_STATUS_CHOSEN
} from "../../../lead/Constants";

import { PlusIcon } from "../../../assets/img/icons";

import { useSelector } from "react-redux";

const PartnerSection = ({
  leadPartnerList,
  currentUserId,
  onSourcePartner,
  activeTab
}) => {
  const currentUser = useSelector(state => state.user);

  return (
    <>
      {leadPartnerList && leadPartnerList.length
        ? leadPartnerList.map(partner => (
            <>
              {partner.status !== LEAD_PARTNER_STATUS_CHOSEN ? (
                leadPartnerList.length === 0 && activeTab !== "newTab" ? (
                  <span className="text-grayed">No partner assigned yet</span>
                ) : (
                  ""
                )
              ) : (
                <p className="text-center">
                  {partner.userId === currentUserId
                    ? "You"
                    : partner.companyName}
                </p>
              )}
            </>
          ))
        : ""}
      {(isSuperAdmin(currentUser && currentUser.userId) ||
        isCompanyAdmin(currentUser && currentUser.userId) ||
        isCompanyManager(currentUser && currentUser.userId)) && (
        <>
          {!leadPartnerList.length ? (
            <div
              className="d-flex flex-column align-items-center"
              onClick={onSourcePartner}
            >
              <div className="task-list">
                <div className="task-left">
                  <div className="checkbox add-task">
                    <PlusIcon />
                  </div>
                  <a className={["add-task"].join(" ")}>Source Partners</a>
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default PartnerSection;
