import React from "react";
import classnames from "classnames";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from "reactstrap";
import PropTypes from "prop-types";
import _ from "lodash";
import { Link } from "react-router-dom";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Helper
import {
  getParamsByName,
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  isExpert,
  isCompanyManager,
  getUrlPath,
  isPartner
} from "../../lib/helper";

// Components
import AddButton from "../../components/base/AddButton";
import CountBadge from "../../components/base/CountBadge";
import NoRecordsFound from "../../components/base/NoRecordsFound";
import PageSearch from "../../components/base/PageSearch";
import PageTitle from "../../components/base/PageTitle";
import ProjectsList from "./inner-components/project/ProjectsList";
import SelectDropdown from "../../components/base/SelectDropdown";
import Spinner from "../../components/base/Spinner";
import Pagination from "../../components/base/Pagination";

// Constant
import {
  PROJECT_STATUS_ACTIVE,
  TAB_PROJECT_ACTIVE,
  PROJECT_STATUS_COMPLETED,
  PROJECT_STATUS_DRAFT,
  PROJECT_STATUS_NEW,
  TAB_PROJECT_COMPLETED,
  TAB_PROJECT_DRAFT,
  TAB_PROJECT_NEW
} from "../../projectStatus/Constants";
import {
  PROJECT_USER_STATUS_ACCEPTED,
  PROJECT_USER_STATUS_NO_RESPONSE,
  PROJECT_SORTBY_DATE_MODIFIED,
  PROJECT_TYPE_PROJECT,
  PROJECT_TYPE_PROJECTS_URL_PATH,
  PROJECT_TYPE_CAMPAIGN,
  PROJECT_TYPE_CAMPAIGNS_URL_PATH,
  PROJECT_TYPE_PROJECT_URL_PATH,
  PROJECT_TYPE_CAMPAIGN_URL_PATH
} from "../../project/Constants";

//Assets
import projectsIcon from "../../assets/img/icons/nav-icon-projects.svg";
import campaignsNorecordsIcon from "../../assets/img/icons/icon-campaigns.svg";

// Actions
import { fetchProjectList, changeProjectPage } from "../../actions/project";
import PageSize from "../../components/PageSize";
import { DEFAULT_PAGE_SIZE } from "../../helpers/Page";

class Projects extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      isGrid: true,
      searchParam: "",
      status: "",
      name: "",
      activeTab: TAB_PROJECT_ACTIVE,
      projectDetails: [],
      count: 0,
      totalCount: 0,
      searchCount: 0,
      projectType: getParamsByName("status")
        ? getParamsByName("status")
        : PROJECT_STATUS_ACTIVE,
      draftProjectsCount: 0,
      activeProjectsList: [],
      isCampaign: "",
      currentPage: 1,
      pageSize: getParamsByName("pageSize")
        ? getParamsByName("pageSize")
        : DEFAULT_PAGE_SIZE,
      sortByOptions: [
        {
          value: "createdAt",
          label: "Most Recent Activity"
        },
        {
          value: "updatedAt",
          label: "Date Modified"
        }
      ],
      selectedSortOption: "Most Recent Activity"
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();

    this.isCampaignProject();
    const { actions } = this.props;
    const searchParam = getParamsByName("search") || "";
    const status = getParamsByName("status");
    const currentPath = getUrlPath(1);
    let tab = "";
    const params = {};
    if (status === null) {
      this.props.history.push(
        `/${currentPath}?status=${PROJECT_STATUS_ACTIVE}`
      );
      tab = TAB_PROJECT_ACTIVE;
      params.status = PROJECT_STATUS_ACTIVE;
    }
    if (status === PROJECT_STATUS_ACTIVE) {
      this.props.history.push(
        `/${currentPath}?status=${PROJECT_STATUS_ACTIVE}`
      );
      tab = TAB_PROJECT_ACTIVE;
      params.status = PROJECT_STATUS_ACTIVE;
    } else if (status === PROJECT_STATUS_COMPLETED) {
      this.props.history.push(
        `/${currentPath}?status=${PROJECT_STATUS_COMPLETED}`
      );
      tab = TAB_PROJECT_COMPLETED;
      params.status = PROJECT_STATUS_COMPLETED;
    } else if (status === PROJECT_STATUS_DRAFT) {
      this.props.history.push(`/${currentPath}?status=${PROJECT_STATUS_DRAFT}`);
      tab = TAB_PROJECT_DRAFT;
      params.status = PROJECT_STATUS_DRAFT;
    } else if (status === PROJECT_STATUS_NEW) {
      this.props.history.push(`/${currentPath}?status=${PROJECT_STATUS_NEW}`);
      tab = TAB_PROJECT_NEW;
      params.status = PROJECT_STATUS_NEW;
    }

    params.type =
      currentPath === PROJECT_TYPE_PROJECTS_URL_PATH
        ? PROJECT_TYPE_PROJECT
        : currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
        ? PROJECT_TYPE_CAMPAIGN
        : PROJECT_TYPE_PROJECT;

    params.page = this.state.currentPage;

    // Call Project List API
    this.setState({ currentPage: 1, searchParam: searchParam }, () => {
      actions.fetchProjectList(
        this.updateUrl({
          status: status,
          pageSize: this.state.pageSize,
          search: encodeURIComponent(searchParam),
          page: 1,
          sort: "updatedAt",
          sortDir: "DESC",
          type:
            currentPath === PROJECT_TYPE_PROJECTS_URL_PATH
              ? PROJECT_TYPE_PROJECT
              : currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
              ? PROJECT_TYPE_CAMPAIGN
              : PROJECT_TYPE_PROJECT
        })
      );
    });

    this.setState({
      activeTab: tab
    });
  }

  // Check campaign project
  isCampaignProject = () => {
    const currentBasePath = getUrlPath(1);
    const isCampaign = currentBasePath === PROJECT_TYPE_CAMPAIGNS_URL_PATH;
    this.setState({ isCampaign: isCampaign });
  };

  // Form Project URL
  updateUrl(data) {
    const {
      search,
      page,
      pageSize,
      sort,
      sortDir,
      status,
      type,
      projectUserStatus
    } = data;

    const params = [];

    params.push(`pagination=true`);

    if (search) {
      params.push(`search=${search}`);
    }

    if (status) {
      params.push(`status=${status}`);
    }

    if (page) {
      params.push(`page=${page}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    if (type) {
      params.push(`type=${type}`);
    }

    if (projectUserStatus) {
      params.push(`projectUserStatus=${projectUserStatus}`);
    }

    if (params.length > 0) {
      return params.join("&");
    }
  }

  componentDidUpdate(prevProps) {
    // Check is logged in user
    isLoggedIn();
    const { search } = prevProps.history.location;
    const params = new URLSearchParams(search);
    const statusType = params.get("status");
    // Set current active tab
    if (this.state.status !== statusType) {
      const currentTab =
        statusType === PROJECT_STATUS_DRAFT
          ? TAB_PROJECT_DRAFT
          : statusType === PROJECT_STATUS_NEW
          ? TAB_PROJECT_NEW
          : statusType === PROJECT_STATUS_ACTIVE
          ? TAB_PROJECT_ACTIVE
          : statusType === PROJECT_STATUS_COMPLETED
          ? TAB_PROJECT_COMPLETED
          : TAB_PROJECT_NEW;
      this.setState(
        {
          activeTab: currentTab || PROJECT_STATUS_NEW
        },
        () => {
          this.renderProjectType(statusType);
        }
      );
    }
  }
  // Search Query string
  pushQueryToUrl = () => {
    const { searchParam, sort, sortDir, status, pageSize } = this.state;
    const { activeTab } = this.props;
    const params = [];

    if (searchParam) {
      params.push(`search=${searchParam}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }
    // if (status) {
    //   params.push(`status=${status}`);
    // }

    // this.props.history.push(`/campaigns?status=&${params.join("&")}`);
    const currentPath = getUrlPath(1);
    this.props.history.push(
      `/${currentPath}?status=${status}&${params.join("&")}`
    );
  };
  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }
  handleSortByChange = value => {
    const currentPath = getUrlPath(1);
    const status = getParamsByName("status");
    const valueArray = this.getSortValueFromLabel(value).split(":");
    const sortBy = valueArray[0];
    const sortDir = valueArray[1];
    this.setState(
      {
        sort: sortBy,
        sortDir: sortDir,
        currentPage: 1
      },
      () => {
        const {
          searchParam,
          sort,
          sortDir,
          currentPage,
          pageSize
        } = this.state;
        this.pushQueryToUrl();

        this.props.actions.fetchProjectList(
          this.updateUrl({
            status: status,
            sort: sort,
            sortDir: sortDir,
            page: currentPage,
            pageSize: pageSize,
            search: searchParam,
            type:
              currentPath === PROJECT_TYPE_PROJECTS_URL_PATH
                ? PROJECT_TYPE_PROJECT
                : currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
                ? PROJECT_TYPE_CAMPAIGN
                : PROJECT_TYPE_PROJECT
          })
        );
      }
    );
  };

  // Search project
  _handleInputChange = e => {
    const currentPath = getUrlPath(1);
    this.setState(
      {
        searchParam: e.target.value
      },
      () => {
        const { searchParam, status } = this.state;
        const sort = getParamsByName("sort") || "";
        const sortDir = getParamsByName("sortDir") || "";
        const params = {
          status: status,
          search: encodeURIComponent(searchParam),
          pageSize: this.state.pageSize,
          sort: sort,
          sortDir: sortDir,
          type:
            currentPath === PROJECT_TYPE_PROJECTS_URL_PATH
              ? PROJECT_TYPE_PROJECT
              : currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
              ? PROJECT_TYPE_CAMPAIGN
              : PROJECT_TYPE_PROJECT
        };
        this.pushQueryToUrl();
        this.props.actions.fetchProjectList(this.updateUrl(params));
      }
    );
  };

  // Toggle Tab
  toggle = tab => {
    const currentPath = getUrlPath(1);
    let params = [];
    if (this.state.pageSize) {
      params.push(`pageSize=${this.state.pageSize}`);
    }
    if (tab === TAB_PROJECT_NEW) {
      this.props.history.push(
        `/${currentPath}?status=${PROJECT_STATUS_NEW}&${params.join("&")}`
      );
    } else if (tab === TAB_PROJECT_DRAFT) {
      this.props.history.push(
        `/${currentPath}?status=${PROJECT_STATUS_DRAFT}&${params.join("&")}`
      );
    } else if (tab === TAB_PROJECT_ACTIVE) {
      this.props.history.push(
        `/${currentPath}?status=${PROJECT_STATUS_ACTIVE}&${params.join("&")}`
      );
    } else if (tab === TAB_PROJECT_COMPLETED) {
      this.props.history.push(
        `/${currentPath}?status=${PROJECT_STATUS_COMPLETED}&${params.join("&")}`
      );
    }

    this.setState({
      activeTab: tab,
      status: ""
    });
  };

  // Render Active Tab
  renderProjectType = projectType => {
    const currentPath = getUrlPath(1);
    const searchParam = getParamsByName("search") || "";
    this.setState(
      {
        projectType,
        status: projectType,
        searchParam: ""
      },
      () => {
        const params = {
          status: projectType,
          search: encodeURIComponent(searchParam),
          type:
            currentPath === PROJECT_TYPE_PROJECTS_URL_PATH
              ? PROJECT_TYPE_PROJECT
              : currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
              ? PROJECT_TYPE_CAMPAIGN
              : PROJECT_TYPE_PROJECT
        };

        if (isPartner(this.props.roleId))
          params.projectUserStatus = `${PROJECT_USER_STATUS_NO_RESPONSE}, ${PROJECT_USER_STATUS_ACCEPTED}`;

        this.props.actions.fetchProjectList(this.updateUrl(params));
      }
    );
  };

  // Load Project By Page Onchange
  onPageChange(page) {
    const currentPath = getUrlPath(1);
    this.setState(
      {
        currentPage: page
      },
      () => {
        const { actions, projects } = this.props;

        if (!(page in projects.pages)) {
          actions.fetchProjectList(
            this.updateUrl({
              status: TAB_PROJECT_COMPLETED,
              page,
              type:
                currentPath === PROJECT_TYPE_PROJECTS_URL_PATH
                  ? PROJECT_TYPE_PROJECT
                  : currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
                  ? PROJECT_TYPE_CAMPAIGN
                  : PROJECT_TYPE_PROJECT
            })
          );
        } else {
          actions.changeProjectPage(page);
        }
      }
    );
  }

  handlePageSizeChange = e => {
    const currentPath = getUrlPath(1);
    this.setState(
      {
        pageSize: e
      },
      () => {
        const { actions, projects } = this.props;

        actions.fetchProjectList(
          this.updateUrl({
            status: TAB_PROJECT_COMPLETED,
            pageSize: e,
            type:
              currentPath === PROJECT_TYPE_PROJECTS_URL_PATH
                ? PROJECT_TYPE_PROJECT
                : currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
                ? PROJECT_TYPE_CAMPAIGN
                : PROJECT_TYPE_PROJECT
          })
        );
        this.pushQueryToUrl();
      }
    );
  };

  render() {
    const { projects, projectDetails } = this.props;

    let projectList;
    let pagination;
    let totalCount;
    let activeProjectsCount;
    let completedProjectsCount, draftProjectsCount, newProjectsCount;
    if (projects.pagination) {
      pagination = projects.pagination;
      projectList = projects.pages[pagination.currentPage];
      totalCount = pagination.totalCount;
      activeProjectsCount = pagination.activeProjectsCount;
      completedProjectsCount = pagination.completedProjectsCount;
      draftProjectsCount = pagination.draftProjectsCount || 0;
      newProjectsCount = pagination.newProjectsCount;
    }

    const dataArray = [];

    const adminProjectDetails = projectDetails || {};
    Object.keys(projectList || {}).map(key => {
      const data = adminProjectDetails[projectList[key]];
      if (data) {
        dataArray.push(data);
      }
    });

    const {
      projectType,
      sortByOptions,
      selectedSortOption,
      activeTab,
      activeProjectsList,
      currentPage,
      pageSize,
      isCampaign
    } = this.state;

    const searchParam = window.location.search;
    const totalProjectCount =
      isExpert(this.props.roleId) && activeProjectsList
        ? activeProjectsCount
        : totalCount;

    // Get Start And End Page
    let startPage = "";
    let endPage = "";

    startPage = (currentPage - 1) * pageSize;
    startPage = startPage > totalProjectCount ? totalProjectCount : startPage;

    endPage = currentPage * pageSize;
    endPage = endPage > totalProjectCount ? totalProjectCount : endPage;

    const searchStatus = getParamsByName("status");
    const currentPath = getUrlPath(1);

    const isCompleted = searchStatus === PROJECT_STATUS_COMPLETED;
    const currentProjectType =
      currentPath === PROJECT_TYPE_PROJECTS_URL_PATH
        ? PROJECT_TYPE_PROJECT
        : currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
        ? PROJECT_TYPE_CAMPAIGN
        : PROJECT_TYPE_PROJECT;

    const projectDetailsUrlBasePath =
      currentPath === PROJECT_TYPE_PROJECTS_URL_PATH
        ? PROJECT_TYPE_PROJECT_URL_PATH
        : currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
        ? PROJECT_TYPE_CAMPAIGN_URL_PATH
        : PROJECT_TYPE_PROJECT_URL_PATH;

    return (
      <>
        <div className="row">
          <div className="col-6">
            <PageTitle label={`${currentProjectType}s`} />
          </div>
          {/* Add Project Button */}
          <div className="col-6">
            <div className="d-flex justify-content-end">
              <div className="mr-3 mt-1">
                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId) ||
                  isPartner(this.props.roleId)) &&
                  isCampaign && (
                    <Link
                      to="/campaign/calendar"
                      className="text-decoration-none text-dark"
                    >
                      <i
                        className="fa fa-calendar fa-lg"
                        aria-hidden="true"
                      ></i>
                    </Link>
                  )}
              </div>
              {(isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)) && (
                <AddButton
                  label={`New ${
                    currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
                      ? "Campaign"
                      : "Project"
                  }`}
                  targetUrl={`/${
                    currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
                      ? "campaign"
                      : "project"
                  }/new`}
                  className="pull-right btn btn-secondary"
                />
              )}
            </div>
          </div>
        </div>

        {/* Nav Tabs */}
        <Nav tabs className="admin-tabs">
          <NavItem className={draftProjectsCount !== 0 ? "" : "d-none"}>
            <NavLink
              className={classnames({
                active: activeTab === TAB_PROJECT_DRAFT
              })}
              onClick={() => {
                this.toggle(TAB_PROJECT_DRAFT);
                this.renderProjectType(PROJECT_STATUS_DRAFT);
              }}
            >
              Draft
              <CountBadge
                count={
                  searchParam && projectType === PROJECT_STATUS_DRAFT
                    ? totalCount
                    : draftProjectsCount
                }
                isActive={activeTab === TAB_PROJECT_DRAFT}
              />
            </NavLink>
          </NavItem>

          {(isSuperAdmin(this.props.roleId) ||
            isCompanyAdmin(this.props.roleId) ||
            isCompanyManager(this.props.roleId)) && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === TAB_PROJECT_NEW
                })}
                onClick={() => {
                  this.toggle(TAB_PROJECT_NEW);
                  // this.renderProjectType(PROJECT_STATUS_NEW);
                }}
              >
                New
                <CountBadge
                  count={
                    searchParam && projectType === PROJECT_STATUS_NEW
                      ? totalCount
                      : newProjectsCount
                  }
                  isActive={activeTab === TAB_PROJECT_NEW}
                />
              </NavLink>
            </NavItem>
          )}

          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === TAB_PROJECT_ACTIVE
              })}
              onClick={() => {
                this.toggle(TAB_PROJECT_ACTIVE);
                // this.renderProjectType(PROJECT_STATUS_ACTIVE);
              }}
            >
              Active
              <CountBadge
                count={
                  searchParam && projectType === PROJECT_STATUS_ACTIVE
                    ? totalCount
                    : activeProjectsCount
                }
                isActive={activeTab === TAB_PROJECT_ACTIVE}
              />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === TAB_PROJECT_COMPLETED
              })}
              onClick={() => {
                this.toggle(TAB_PROJECT_COMPLETED);
                // this.renderProjectType(PROJECT_STATUS_COMPLETED);
              }}
            >
              Completed
              <CountBadge
                count={
                  searchParam && projectType === PROJECT_STATUS_COMPLETED
                    ? totalCount
                    : completedProjectsCount
                }
                isActive={activeTab === TAB_PROJECT_COMPLETED}
              />
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TAB_PROJECT_DRAFT}>
            {/* Search Projects */}
            <ProjectSearchList
              classnames="page-search"
              placeholder={`Search`}
              onChange={this._handleInputChange.bind(this)}
              value={this.state.searchParam}
              buttonLabel={selectedSortOption}
              dropdownLinks={sortByOptions}
              color={"gray"}
              hideCaret
              selectName={"sortby_projects"}
              handleChange={this.handleSortByChange}
              isCampaign={isCampaign}
              handlePageSizeChange={this.handlePageSizeChange}
            />
          </TabPane>
          <TabPane tabId={TAB_PROJECT_NEW}>
            {/* Search Projects */}
            <ProjectSearchList
              classnames="page-search"
              placeholder={`Search`}
              onChange={this._handleInputChange.bind(this)}
              value={this.state.searchParam}
              buttonLabel={selectedSortOption}
              dropdownLinks={sortByOptions}
              color={"gray"}
              hideCaret
              selectName={"sortby_projects"}
              handleChange={this.handleSortByChange}
              handlePageSizeChange={this.handlePageSizeChange}
              isCampaign={isCampaign}
            />
          </TabPane>
          <TabPane tabId={TAB_PROJECT_ACTIVE}>
            {/* Search Projects */}
            <ProjectSearchList
              classnames="page-search"
              placeholder={`Search`}
              onChange={this._handleInputChange.bind(this)}
              value={this.state.searchParam}
              buttonLabel={selectedSortOption}
              dropdownLinks={sortByOptions}
              color={"gray"}
              hideCaret
              selectName={"sortby_projects"}
              handleChange={this.handleSortByChange}
              handlePageSizeChange={this.handlePageSizeChange}
              isCampaign={isCampaign}
            />
          </TabPane>
          <TabPane tabId={TAB_PROJECT_COMPLETED}>
            {/* Search Projects */}
            <ProjectSearchList
              classnames="page-search"
              placeholder={`Search`}
              onChange={this._handleInputChange.bind(this)}
              value={this.state.searchParam}
              buttonLabel={selectedSortOption}
              dropdownLinks={sortByOptions}
              color={"gray"}
              hideCaret
              selectName={"sortby_projects"}
              handleChange={this.handleSortByChange}
              handlePageSizeChange={this.handlePageSizeChange}
              isCampaign={isCampaign}
            />
          </TabPane>
        </TabContent>

        {projects.isFetching ? (
          <Spinner />
        ) : totalCount ? (
          // Project List
          <>
            <ProjectsList
              status={isCompleted}
              data={dataArray}
              history={this.props.history}
              pagination={false}
              type={projectType}
              activeProjectsList={activeProjectsList}
              activeProjectsCount={activeProjectsCount}
              currentProjectType={currentProjectType}
              projectDetailsUrlBasePath={projectDetailsUrlBasePath}
            />

            {totalProjectCount > 0 && (
              <Row>
                <Col>
                  Showing {startPage + 1} to {endPage} of {totalProjectCount}{" "}
                  entries
                </Col>
                <Col>
                  <Pagination
                    currentPage={currentPage}
                    totalCount={totalProjectCount}
                    pageSize={pageSize}
                    onPageChange={this.onPageChange.bind(this)}
                  />
                </Col>
              </Row>
            )}
          </>
        ) : searchParam ? (
          !totalCount ? (
            // No Records Found
            <NoRecordsFound icon={projectsIcon} message="No Results Found" />
          ) : (
            ""
          )
        ) : (
          // No Records Found
          <NoRecordsFound
            icon={
              currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
                ? campaignsNorecordsIcon
                : projectsIcon
            }
            message={`No ${currentProjectType}s Yet`}
            description={`After you start a ${
              currentPath === PROJECT_TYPE_CAMPAIGNS_URL_PATH
                ? "campaign"
                : "project"
            }, they will show up here`}
          />
        )}
      </>
    );
  }
}

Projects.propTypes = {
  actions: PropTypes.object,
  projectDetails: PropTypes.object,
  projects: PropTypes.object
};

function mapStateToProps(state) {
  return {
    projects: state.projects,
    projectDetails: state.projectDetails,
    roleId: state.user ? state.user.roleId : ""
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { fetchProjectList, changeProjectPage },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Projects);

const ProjectSearchList = props => {
  const {
    classnames,
    placeholder,
    onChange,
    value,
    dropdownLabel,
    buttonLabel,
    dropdownLinks,
    color,
    hideCaret,
    selectName,
    handleChange,
    handlePageSizeChange
  } = props;

  return (
    <>
      <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column">
        <div className="mr-1">
          <PageSize
            onChange={e => handlePageSizeChange && handlePageSizeChange(e)}
          />
        </div>
        {/* Search Projects */}
        <PageSearch
          classnames={classnames}
          placeholder={placeholder}
          onChange={onChange}
        />

        {/* Sort Option */}
        <SelectDropdown
          dropdownLabel={dropdownLabel}
          buttonLabel={buttonLabel}
          dropdownLinks={dropdownLinks}
          color={color}
          hideCaret={hideCaret}
          selectName={selectName}
          handleChange={handleChange}
        />
      </div>
    </>
  );
};
