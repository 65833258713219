import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import AddButton from "../../components/base/AddButton";
import SaveButton from "../../components/base/SaveButton";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownItem
} from "reactstrap";

// Configs
import { endpoints } from "../../configs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MoreDropdown from "../../components/base/MoreDropdown";

// API call
import { apiClient } from "../../apiClient";

import {
  addHubspotFieldMapping,
  updateHubspotFieldMapping,
  deleteHubspotFieldMapping
} from "../../actions/hubspotFieldMapping";
import DeleteModal from "../../components/base/DeleteModal";
import { isBadRequest } from "../../common/http";

import * as HubspotConstants from "../Constants";

import { getParamsByName } from "../../lib/helper";

import AddNewMappingModal from "../components/AddFieldMappingModal";
import EditMappingModal from "../components/EditFieldMappingModal";

const HubspotFieldMapping = props => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedHubspotObjectName, setHubSpotObjectName] = useState("");
  const [selectedRowObject, setSelectedRowObject] = useState("");
  const [selectData, setSelectData] = useState("");
  const [customFieldList, setCustomField] = useState(" ");
  const [pickList, setPicklist] = useState(" ");
  const [objectName, setSelectedObjectName] = useState("");

  const AccountObjectList = () => [
    {
      value: "Account (Partner)",
      label: "Account (Partner)"
    },
    {
      value: "Account (Prospect)",
      label: "Account (Prospect)"
    },
    {
      value: "Referral",
      label: "Referral"
    }
  ];

  const contactObject = () => [
    {
      value: "Contact (Partner)",
      label: "Contact (Partner)"
    },
    {
      value: "Contact (Prospect)",
      label: "Contact (Prospect)"
    }
  ];

  const ReferralObjectList = () => [
    {
      value: "Referral",
      label: "Referral"
    }
  ];

  const hubspotObjectNameList = () => [
    {
      value: "Deal",
      label: "Deal"
    },
    {
      value: "Company (Partner)",
      label: "Company (Partner)"
    },
    {
      value: "Company (Prospect)",
      label: "Company (Prospect)"
    },
    {
      value: "Contact (Partner)",
      label: "Contact (Partner)"
    },
    {
      value: "Contact (Prospect)",
      label: "Contact (Prospect)"
    }
  ];

  const ReferralFieldList = () => [
    {
      value: "account_name",
      label: "account_name"
    },
    {
      value: "amount",
      label: "amount"
    },
    {
      value: "owner_hubspot_id",
      label: "owner_hubspot_id"
    },
    {
      value: "contact_title",
      label: "contact_title"
    },
    {
      value: "contact_email",
      label: "contact_email"
    },
    {
      value: "contact_phone",
      label: "contact_phone"
    },
    {
      value: "close_date",
      label: "close_date"
    },
    {
      value: "createdAt",
      label: "createdAt"
    },
    {
      value: "first_name",
      label: "first_name"
    },
    {
      value: "last_name",
      label: "last_name"
    },
    {
      value: "notes",
      label: "notes"
    },
    {
      value: "status",
      label: "status"
    },
    {
      value: "hubspot_company_id",
      label: "hubspot_company_id"
    },
    {
      value: "hubspot_contact_id",
      label: "hubspot_contact_id"
    },
    {
      value: "hubspot_deal_id",
      label: "hubspot_deal_id"
    },
    {
      value: "stage",
      label: "stage"
    },
    {
      value: "submitted_by",
      label: "submitted_by"
    },
    {
      value: "partner_hubspot_company_id",
      label: "partner_hubspot_company_id"
    },
    {
      value: "partner_hubspot_contact_id",
      label: "partner_hubspot_contact_id"
    },
    {
      value: "partner_manager_user_id",
      label: "partner_manager_user_id"
    },
    {
      value: "referral_name",
      label: "referral_name"
    }
  ];

  const accountFieldList = () => [
    {
      value: "address1",
      label: "address1"
    },
    {
      value: "address2",
      label: "address2"
    },
    {
      value: "country_name",
      label: "country_name"
    },
    {
      value: "city",
      label: "city"
    },
    {
      value: "company_name",
      label: "company_name"
    },
    {
      value: "phone",
      label: "phone"
    },
    {
      value: "state_name",
      label: "state_name"
    },
    {
      value: "partner_manager",
      label: "partner_manager"
    },
    {
      value: "website",
      label: "website"
    }
  ];

  const contactFieldList = () => [
    {
      value: "first_name",
      label: "first_name"
    },
    {
      value: "last_name",
      label: "last_name"
    },
    {
      value: "email",
      label: "email"
    },
    {
      value: "phone",
      label: "phone"
    }
  ];

  const syncDirection = () => [
    {
      value: HubspotConstants.Hubspot.SYNC_DIRECTION_OUTBOUND,
      label: "Outbound"
    },
    {
      value: HubspotConstants.Hubspot.SYNC_DIRECTION_INBOUND,
      label: "Inbound"
    },
    {
      value: HubspotConstants.Hubspot.SYNC_DIRECTION_BOTH,
      label: "Both"
    }
  ];

  const getCustomFieldList = () => {
    apiClient
      .get(`${endpoints().referralQuestionAPI}`)
      .then(response => {
        if (response.data && response.data.data) {
          let customField = [];
          response.data.data.forEach(data => {
            customField.push({
              value: data.name,
              label: data.name
            });
          });
          let customFielddata = ReferralFieldList().concat(customField);
          if (customFielddata) {
            setCustomField(customFielddata);
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  const addNewMapping = () => {
    getCustomFieldList();
    setOpenAddModal(true);
  };

  const headerButton = () => (
    <div className="btn-wrapper mb-n0 mt-0">
      <AddButton
        label="Add New"
        className="pull-right btn btn-secondary"
        onClick={() => {
          addNewMapping();
        }}
      />
    </div>
  );
  function closeAddModal() {
    setOpenAddModal(false);
    setHubSpotObjectName("");
    setSelectedObjectName("");
  }

  const getPicklist = row => {
    let CompanyObjectFieldList =
      props.HubSpotFieldList && props.HubSpotFieldList.companyObjectFields
        ? props.HubSpotFieldList.companyObjectFields
        : [];

    let DealObjectFieldList =
      props.HubSpotFieldList && props.HubSpotFieldList.dealObjectFields
        ? props.HubSpotFieldList.dealObjectFields
        : [];
    if (row.hubspotObject === "deal") {
      if (DealObjectFieldList.length > 0) {
        DealObjectFieldList.map(data => {
          if (data.label === row.hubspotFieldName) {
            setPicklist(data);
          }
        });
      }
    } else {
      if (CompanyObjectFieldList.length > 0) {
        CompanyObjectFieldList.map(data => {
          if (data.label === row.hubspotFieldName) {
            setPicklist(data);
          }
        });
      }
    }
  };

  const FieldEditToggle = row => {
    getCustomFieldList();
    getPicklist(row);
    setSelectedRowObject(row);
    setHubSpotObjectName(row.hubspotObject);
    setSelectedObjectName(row.objectName);
    setOpenEditModal(true);
  };
  const closeEditModal = () => {
    setOpenEditModal(false);
    setHubSpotObjectName("");
    setSelectedObjectName("");
  };

  // Handle pricing type
  const handleChange = data => {
    if (
      data &&
      data.values &&
      data.values.hubspotObjectName &&
      data.values.hubspotObjectName.value
    ) {
      let selectedObject = data.values.hubspotObjectName.value;
      setHubSpotObjectName(selectedObject);
    }
  };

  const handleObjectNameChange = data => {
    if (
      data &&
      data.values &&
      data.values.objectName &&
      data.values.objectName.value
    ) {
      let selectedObject = data.values.objectName.value;
      setSelectedObjectName(selectedObject);
    }
  };

  const deleteHubspotFieldMapping = id => {
    const params = { search: getParamsByName("search") };
    props.actions.deleteHubspotFieldMapping(id, params);
  };
  const deleteModal = row => {
    setOpenDeleteModal(true);
    setSelectData(row);
  };

  return (
    <div>
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(prevState => !prevState);
        }}
        title="Delete Salesforce Field Mapping"
        id={selectData ? selectData.id : ""}
        label={selectData ? selectData.hubspotFieldName : ""}
        deleteFunction={deleteHubspotFieldMapping}
      />
      <AddNewMappingModal
        openAddModal={openAddModal}
        closeAddModal={closeAddModal}
        hubspotObjectNameList={hubspotObjectNameList}
        addfunction={props}
        handleChange={handleChange}
        handleObjectNameChange={handleObjectNameChange}
        selectedHubspotObjectName={selectedHubspotObjectName}
        selectedObjectName={objectName}
        HubSpotFieldList={props.HubSpotFieldList}
        accountFieldList={accountFieldList}
        customFieldList={customFieldList}
        ReferralFieldList={ReferralFieldList}
        AccountObjectList={AccountObjectList}
        contactObjectList={contactObject}
        ReferralObjectList={ReferralObjectList}
        syncDirection={syncDirection}
        contactFieldList={contactFieldList}
      />
      <EditMappingModal
        selectedRowObject={selectedRowObject}
        closeEditModal={closeEditModal}
        hubspotObjectNameList={hubspotObjectNameList}
        handleChange={handleChange}
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        update={props}
        selectedHubspotObjectName={selectedHubspotObjectName}
        selectedObjectName={objectName}
        handleObjectNameChange={handleObjectNameChange}
        accountFieldList={accountFieldList}
        customFieldList={customFieldList}
        ReferralFieldList={ReferralFieldList}
        ReferralObjectList={ReferralObjectList}
        AccountObjectList={AccountObjectList}
        contactFieldList={contactFieldList}
        picklist={pickList}
        syncDirection={syncDirection}
        HubSpotFieldList={props.HubSpotFieldList}
        contactObjectList={contactObject}
      />
      <ReduxTable
        onScroll
        id="hubspotFieldMapping"
        apiURL={`${endpoints().hubSpotFieldMapRoute}`}
        headerButton={headerButton()}
        onRowClick={row => {
          FieldEditToggle(row);
        }}
        paramsToUrl={true}
        searchPlaceholder="Search"
        showHeader
        history={props.history}
      >
        <ReduxColumn
          minWidth={"102px"}
          type="link"
          isClickable="true"
          field="hubspotObject"
          sortBy="hubspot_object"
        >
          Hubspot Object
        </ReduxColumn>
        <ReduxColumn
          minWidth={"102px"}
          field="hubspotFieldName"
          sortBy="hubspot_field_name"
        >
          Hubspot Field
        </ReduxColumn>
        <ReduxColumn minWidth={"74px"} field="objectName" sortBy="object_name">
          Object
        </ReduxColumn>
        <ReduxColumn minWidth={"60px"} field="fieldName" sortBy="field_name">
          Field
        </ReduxColumn>
        <ReduxColumn
          minWidth={"80px"}
          field="defaultValue"
          sortBy="default_value"
        >
          Default Value
        </ReduxColumn>
        <ReduxColumn minWidth={"81px"} field="partnerTypes">
          Partner Type
        </ReduxColumn>
        <ReduxColumn
          minWidth={"62px"}
          field="syncDirectionText"
          sortBy="sync_direction"
        >
          Sync Direction
        </ReduxColumn>
        <ReduxColumn
          minWidth={"75px"}
          disableOnClick
          className="action-column"
          renderField={row => (
            <div className="text-center landing-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    FieldEditToggle(row);
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  className={"text-danger"}
                  onClick={event => {
                    deleteModal(row);
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addHubspotFieldMapping,
        updateHubspotFieldMapping,
        deleteHubspotFieldMapping
      },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(HubspotFieldMapping);
