import SystemName from "../Constants/SystemName";
import Terminology from "../lib/Terminology";

export const ACTIVITY_TYPE_PARTNER_PROFILE_VIEW =
  "Partner Profile in Marketplace";
export const ACTIVITY_TYPE_PARTNER_USER_INVITATION_ACCEPTED =
  "Invitation Accepted";
export const ACTIVITY_TYPE_PARTNER_SIGNUP = "Signed Up";
export const ACTIVITY_TYPE_LOGIN = "Logged In";
export const ACTIVITY_TYPE_LOGOUT = "Logged Out";
export const ACTIVITY_TYPE_REFERAL_SUBMISSION = ` ${Terminology.get(
  SystemName.REFERRAL
)}Submitted`;
export const ACTIVITY_TYPE_DEAL_REGISTRATION = "Deal Registered";
export const ACTIVITY_TYPE_PARTNER_CONTACT_SUBMISSION =
  "Partner Contact Submitted";
export const ACTIVITY_TYPE_RESOURCE_VIEWED = " Viewed Resource";
export const ACTIVITY_TYPE_COURSE_VIEWED = " Viewed Course";
