// API Client
import { apiClient } from "../apiClient";
import { endpoints } from "../configs";

import { isPartner } from "./helper";

/**
 * Create Activity
 *
 * @param {*} activityData
 * @param {*} callback
 * @returns
 */
export const createActivity = (activityData, roleId, callback) => {
  const { partnerId, notes, activityType } = activityData;

  const data = {};

  if (partnerId || isPartner(roleId)) {
    if (partnerId) {
      data.partnerId = partnerId;
    }

    if (activityType) {
      data.activityType = activityType;
    }

    if (notes) {
      data.notes = notes;
    }

    // Create Activity
    apiClient.post(`${endpoints().activity}`, data);

    return callback();
  } else {
    return callback();
  }
};

/**
 * Create Activity
 *
 * @param {*} activityData
 * @param {*} callback
 * @returns
 */
export const createAppActivity = (activityData, callback) => {
  const { activityType, app_id } = activityData;

  const data = new Object();

  if (app_id) {
    if (activityType) {
      data.activityType = activityType;
    }

    data.app_id = app_id;

    // Create Activity
    apiClient.post(`${endpoints().appAPI}/activity`, data);

    return callback();
  } else {
    return callback();
  }
};
