import React from "react";

// Components
import Form from "../../../../../components/base/Form";
import DefaultContent from "../../../../../components/content/DefaultContent";
import Text from "../../../../../components/base/Text";
import SaveButton from "../../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_METACX_ADMIN_VIRTUAL_CUSTOMER_ID,
  SETTINGS_METACX_API_URL,
  SETTINGS_METACX_CLIENT_ID,
  SETTINGS_METACX_CLIENT_SECRET,
  SETTINGS_METACX_CUSTOMER_ID,
  SETTINGS_METACX_EXPERT_VIRTUAL_CUSTOMER_ID,
  SETTINGS_METACX_VISITOR_VIRTUAL_CUSTOMER_ID
} from "../../../../../setting/Constants";

const MetaCxSection = props => {
  const { adminSettings, saveSettings } = props;

  const {
    metacx_client_id,
    metacx_client_secret,
    metacx_api_url,
    metacx_customer_id,
    metacx_admin_virtual_customer_id,
    metacx_expert_virtual_customer_id,
    metacx_visitor_virtual_customer_id
  } = adminSettings;

  // MetaCX Section Initial Values
  const metaCxInitialValues = {
    metacx_client_id,
    metacx_client_secret,
    metacx_api_url,
    metacx_customer_id,
    metacx_admin_virtual_customer_id,
    metacx_expert_virtual_customer_id,
    metacx_visitor_virtual_customer_id
  };

  // MetaCX Section Submit Form
  const submit = values => {
    const data = new FormData();

    // MetaCX Client Id
    if (values.metacx_client_id !== undefined) {
      data.append(
        "metacx_client_id",
        values.metacx_client_id ? values.metacx_client_id : ""
      );
    }

    // MetaCX Client Secret
    if (values.metacx_client_secret !== undefined) {
      data.append(
        "metacx_client_secret",
        values.metacx_client_secret ? values.metacx_client_secret : ""
      );
    }

    // MetaCX API Url
    if (values.metacx_api_url !== undefined) {
      data.append(
        "metacx_api_url",
        values.metacx_api_url ? values.metacx_api_url : ""
      );
    }

    // MetaCX Customer Id
    if (values.metacx_customer_id !== undefined) {
      data.append(
        "metacx_customer_id",
        values.metacx_customer_id ? values.metacx_customer_id : ""
      );
    }

    // MetaCX Admin Virtual Customer Id
    if (values.metacx_admin_virtual_customer_id !== undefined) {
      data.append(
        "metacx_admin_virtual_customer_id",
        values.metacx_admin_virtual_customer_id
          ? values.metacx_admin_virtual_customer_id
          : ""
      );
    }

    // MetaCX Admin Virtual Customer Id
    if (values.metacx_expert_virtual_customer_id !== undefined) {
      data.append(
        "metacx_expert_virtual_customer_id",
        values.metacx_expert_virtual_customer_id
          ? values.metacx_expert_virtual_customer_id
          : ""
      );
    }

    // MetaCX Visitor Virtual Customer Id
    if (values.metacx_visitor_virtual_customer_id !== undefined) {
      data.append(
        "metacx_visitor_virtual_customer_id",
        values.metacx_visitor_virtual_customer_id
          ? values.metacx_visitor_virtual_customer_id
          : ""
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={metaCxInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent title="MetaCx">
        <h5 className="font-weight-bold">Client ID</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_METACX_CLIENT_ID}
            placeholder="Enter METACX Client ID"
          />
        </div>

        <h5 className="font-weight-bold">Client Secret</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_METACX_CLIENT_SECRET}
            placeholder="Enter METACX Client Secret"
          />
        </div>

        <h5 className="font-weight-bold">API URL</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_METACX_API_URL}
            placeholder="Enter METACX API URL"
          />
        </div>

        <h5 className="font-weight-bold">Customer ID</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_METACX_CUSTOMER_ID}
            placeholder="Enter METACX Customer ID"
          />
        </div>

        <h5 className="font-weight-bold">Admin Virtual Customer ID</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_METACX_ADMIN_VIRTUAL_CUSTOMER_ID}
            placeholder="Enter METACX Visitor Virtual Customer ID"
          />
        </div>

        <h5 className="font-weight-bold">Expert Virtual Customer ID</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_METACX_EXPERT_VIRTUAL_CUSTOMER_ID}
            placeholder="Enter METACX Expert Virtual Customer ID"
          />
        </div>

        <h5 className="font-weight-bold">Visitor Virtual Customer ID</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_METACX_VISITOR_VIRTUAL_CUSTOMER_ID}
            placeholder="Enter METACX Visitor Virtual Customer ID"
          />
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default MetaCxSection;
