import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

import { getKeyValueByObject, getCookie } from "../../lib/helper";
import { SETTINGS_ENABLE_PARTNERS } from "../../setting/Constants";

// Components
import ListViewCard from "../../components/base/ListViewCard";

//Asset
import Img from "../../assets/img/icons/icon-user.svg";
import NoUserFound from "../../components/base/NoUserFound";

// Actions
import { fetchAdminDashboardCount } from "../../actions/dashboard";

// Cookies
import { COOKIE_SHOW_ASSIGNED_PARTNERS } from "../../lib/cookie";
import PartnerActivityCard from "../../components/PartnerActivityCard";

const List = styled.div`
  padding: 15px;
  border-bottom: 1px solid #e9e8ee;
`;

const RecentPartnerUserActivities = props => {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(fetchAdminDashboardCount());
  }, []);

  const adminDashboard = dashboard.adminDashboard;
  const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);

  let recentPartnerUserActivities, recentPartnerUserActivitiesCount;

  if (checked_status) {
    recentPartnerUserActivities =
      adminDashboard && adminDashboard.recentPartnerUserActivities;
    recentPartnerUserActivitiesCount =
      adminDashboard && adminDashboard.recentPartnerUserActivitiesCount;
  } else {
    recentPartnerUserActivities =
      adminDashboard && adminDashboard.recentPartnerUserActivities;
    recentPartnerUserActivitiesCount =
      adminDashboard && adminDashboard.recentPartnerUserActivitiesCount;
  }
  const enablePartners = props.settings
    ? getKeyValueByObject(props.settings, SETTINGS_ENABLE_PARTNERS)
    : "";
  return (
    <>
      {adminDashboard && (
        <>
          {recentPartnerUserActivities &&
          recentPartnerUserActivities.length > 0 ? (
            <>
              {/* Top Partners Deal Closed */}
              {enablePartners === "true" && (
                <ListViewCard
                  label="Recent Partner Activity"
                  link={
                    recentPartnerUserActivitiesCount > 5
                      ? "/reports/user-activities"
                      : ""
                  }
                >
                  {recentPartnerUserActivities &&
                    recentPartnerUserActivities.length > 0 && (
                      <>
                        {recentPartnerUserActivities.map(
                          userActivity =>
                            userActivity.partner && (
                              <List key={userActivity.partner.id}>
                                <PartnerActivityCard
                                  partnerAvatar={userActivity.partner.avatar}
                                  partnerCompanyName={
                                    userActivity.partner.company_name
                                  }
                                  userName={
                                    userActivity.user
                                      ? `${
                                          userActivity.user.first_name
                                            ? userActivity.user.first_name
                                            : ""
                                        } ${
                                          userActivity.user.last_name
                                            ? userActivity.user.last_name
                                            : ""
                                        }`
                                      : ""
                                  }
                                  activityType={userActivity.activity_type}
                                  activityTime={userActivity.createdAt}
                                  notes={userActivity.notes}
                                  firstName={userActivity.user.first_name}
                                  lastName={userActivity.user.last_name}
                                />
                              </List>
                            )
                        )}
                      </>
                    )}
                </ListViewCard>
              )}
            </>
          ) : (
            <ListViewCard label="Recent Partner Activity">
              <NoUserFound
                title="There Has Been No Recent Partner Activity"
                titleStyle={{ margin: "3px" }}
                icon={Img}
                hideCard
                message="After partners start performing activities, they will show up here."
              />
            </ListViewCard>
          )}
        </>
      )}
    </>
  );
};

export default RecentPartnerUserActivities;
