import React, { Component } from "react";

import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

// Components
import Form from "../../../components/base/Form";
import Country from "../../../components/base/Country";
import Text from "../../../components/base/Text";
import State from "../../../components/State";
import Zipcode from "../../../components/base/Zipcode";
import Phone from "../../../components/base/Phone";
import CancelButton from "../../../components/base/CancelButton";

// Helper
import { removeMaskedPhoneNumber } from "../../../lib/helper";

class BillingDetailModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      selectedCountryName: ""
    };
  }

  handleCountryChange = ({ values }) => {
    const selectedOption = values.country && values.country.id;
    const selectedCountryName = values.country && values.country.label;
    this.setState({
      selectedOption,
      selectedCountryName: selectedCountryName
    });
  };
  render() {
    const { isBillingModal, ToggleBillingModal, billingDetails } = this.props;
    let initialValues = {
      name: "",
      address: "",
      city: "",
      state: "",
      country: "",
      postal_code: "",
      phone: ""
    };

    if (billingDetails) {
      initialValues = {
        name: billingDetails.name || "",
        address: billingDetails.address || "",
        city: billingDetails.city || "",
        state: billingDetails.state
          ? { label: billingDetails.state, value: billingDetails.state }
          : "",
        country: billingDetails.country
          ? { label: billingDetails.country, value: billingDetails.country }
          : "",
        postal_code: billingDetails.postal_code || "",
        phone: billingDetails.phone || ""
      };
    }

    return (
      <Modal
        isOpen={isBillingModal}
        toggle={ToggleBillingModal}
        backdrop="static"
        className={["edit-task-modal", "edit-billing-modal"].join(" ")}
      >
        <Form
          initialValues={{
            ...initialValues
          }}
          onSubmit={values => {
            let newValues = Object.assign({}, values);

            newValues.country = newValues.country && newValues.country.label;
            newValues.state = newValues.state && newValues.state.label;
            newValues.phone =
              newValues.phone && removeMaskedPhoneNumber(newValues.phone);

            this.props.billingDetailsSubmit(newValues);
            this.props.ToggleBillingModal();
          }}
        >
          <ModalHeader toggle={ToggleBillingModal} tag={"div"}>
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
              {billingDetails ? "Edit" : "Add"} Billing Address
            </h4>
          </ModalHeader>
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="form-wrapper">
              <div className="field-wrapper">
                <Text
                  name="name"
                  label="Company Name"
                  placeholder="Enter company name"
                  required={true}
                  error={""}
                />
              </div>
              <div className="field-wrapper">
                <Text
                  name="address"
                  label="Address"
                  placeholder="Enter Address"
                  error=""
                  required={true}
                />
                <Text
                  name="city"
                  label="City"
                  placeholder="Enter city"
                  required={true}
                  error=""
                />
              </div>
              <div className="field-wrapper">
                <Country
                  name="country"
                  label="Country"
                  placeholder="Enter Country"
                  error=""
                  required={true}
                  onChange={this.handleCountryChange}
                />
                {this.state.selectedCountryName && (
                  <State
                    name="state"
                    label="State/Province/Region"
                    placeholder="Enter State"
                    error=""
                    required={true}
                    selectedCountry={
                      this.state.selectedOption
                        ? this.state.selectedOption
                        : this.state.selectedCountry
                    }
                    selectedCountryName={
                      this.state.selectedCountryName
                        ? this.state.selectedCountryName
                        : ""
                    }
                  />
                )}
              </div>
              <div className="field-wrapper">
                <Zipcode
                  name="postal_code"
                  label="Zip/Postal Code"
                  placeholder="Enter Zip"
                  error=""
                  required={true}
                />
                <Phone
                  name="phone"
                  label="Phone Number"
                  placeholder="Phone number..."
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <CancelButton onClick={this.props.ToggleBillingModal} />
              <button className="btn btn-primary" type={"submit"}>
                {billingDetails ? "Save" : "Add"}
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}

export default BillingDetailModal;
