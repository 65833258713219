import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Form from "../../../components/base/Form";
import CancelButton from "../../../components/base/CancelButton";
import Currency from "../../../components/base/Currency";

const UpdateBudgetModal = props => {
  const {
    modal,
    setModal,
    currentBudget,
    updateProject,
    newEstimatedBudget
  } = props;

  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      keyboard={true}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={setModal}>
        <div
          className={["d-flex", "flex-column", "align-items-center"].join(" ")}
        >
          <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
            Request Client to update Budget
          </h4>
        </div>
      </ModalHeader>
      <Form
        initialValues={{
          newEstimatedBudget: newEstimatedBudget || ""
        }}
        className={["d-block"].join(" ")}
        onSubmit={values => {
          updateProject(values);
          setModal();
        }}
      >
        <ModalBody className={["mb-4", "px-0"].join(" ")}>
          <div className="content-wrapper">
            <p>
              Request client to update the current budget of{" "}
              <strong>${currentBudget}</strong> to:
            </p>
          </div>

          <div className="form-wrapper justify-content-center d-flex">
            <div className="field-wrapper w-100">
              <Currency
                name="newEstimatedBudget"
                label="Specify new budget:"
                error="New Estimated Budget is required"
                required
                minValue={0}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={setModal} className="mr-2" />
            <Button type="submit" label="">
              Send request to client
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default UpdateBudgetModal;
