import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import PartnerAlert from "../assets/pages/landing-page/PartnerAlert.png";

class PartnerInvitePopUp extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      isOpen: false
    };
  }
  _toggle = () => {
    this.props.resetPartnerUserInvitePopUp &&
      this.props.resetPartnerUserInvitePopUp();
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.props.isOpen}
          className=" referral-modal-popup w-100 text-center"
        >
          <ModalHeader
            toggle={this.props.resetPartnerUserInvitePopUp}
            cssModule={{ "modal-title": "w-100 text-center mt-3" }}
            style={{ backgroundColor: "#DCDCDC" }}
          >
            <div className="img-wrapper d-flex justify-content-center mb-2 mt-3">
              <img src={PartnerAlert} width="12%" alt="" />
            </div>
            <div className="font-weight-bold mt-2">
              <span>This partner company already </span>
              <br />
              <span>exists in the platform.</span>
            </div>
          </ModalHeader>
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div>
              <span>Would you like to add them to</span>
              <br />
              <span>the existing partner team?</span>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-md-12 mb-4">
              <Button
                className="btn"
                style={{ backgroundColor: "#03a9f4", color: "#ffffff " }}
                onClick={() => {
                  this.props.partnerMenberInvite();
                }}
              >
                Yes, add them to the Partner Team
              </Button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default PartnerInvitePopUp;
