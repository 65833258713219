import React, { Component } from "react";
import { DropdownItem } from "reactstrap";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import MoreDropdown from "../../components/base/MoreDropdown";
import DeleteModal from "../../components/base/DeleteModal";

// configs
import { endpoints } from "../../configs";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

// Helper
import { getParamsByName, isPartner } from "../../lib/helper";
import {
  DEAL_STATUS_CLOSED,
  DEAL_STATUS_DECLINED,
  DEAL_STATUS_APPROVED,
  DEAL_STATUS_REQUESTED
} from "../../dealStatus/Constants";

// Inner Components
import DeclineDealModal from "./inner-components/DeclineDealModal";

// Action
import { updateDeal, deleteDeal } from "../../../src/actions/deal";
import { DealIcon } from "../../assets/img/icons";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class AllDeals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "amount:ASC",
          label: "Amount"
        }
      ],
      statusOptions: [
        {
          value: "All:ASC",
          label: "All"
        },
        {
          value: "Requested",
          label: "Requested"
        },
        {
          value: "Approved",
          label: "Approved"
        },
        {
          value: "Closed",
          label: "Closed"
        },
        {
          value: "Declined",
          label: "Declined"
        }
      ],
      declineDealModal: false,
      deal_id: "",
      deleteDealModal: false,
      deleteDeal: false
    };
  }

  // Update The Deal By Status
  updateDealStatus(status, dealId, params) {
    const searchItem = getParamsByName("search");
    this.props.actions.updateDeal(
      dealId,
      { status: status },
      params ? params : {},
      { search: searchItem },
      this.props.roleId
    );
  }

  // Landing page delete api call
  handleDelete = id => {
    this.props.actions.deleteDeal(id, this.props.roleId);
  };

  // Toggle deal modal
  toggleModal = dealId => {
    let deal_id = "";
    if (dealId) {
      deal_id = dealId;
    }
    const { declineDealModal } = this.state;
    this.setState({ deal_id: deal_id }, () => {
      this.setState({ declineDealModal: !declineDealModal });
    });
  };

  render() {
    const currentUrl = window.location.pathname + window.location.search;
    const partnerId = this.props.partnerId;
    const search = window.location.search;
    const pathParams = new URLSearchParams(search);
    const searchItem = pathParams.get("search");
    let params = {
      section: getParamsByName("section") || "",
      status: "",
      searchItem: searchItem
    };
    if (isPartner(this.props.roleId) && partnerId) {
      params = { partnerId: partnerId };
    }
    const {
      declineDealModal,
      deal_id,
      deleteDealModal,
      deleteDeal
    } = this.state;
    return (
      <>
        <ReduxTable
          id="allDeals"
          apiURL={`${endpoints().dealsAPI}/search`}
          params={params}
          searchPlaceholder="Search"
          statusOptions={this.state.statusOptions}
          showStatusOptions
          message="No My Deal found"
          onRowClick={row =>
            this.props.history.push(
              `/deal/details/${row.deal_id}?redirect=${currentUrl}`
            )
          }
          sortByOptions={this.state.sortByOptions}
          newTableHeading
          showHeader
          icon={<DealIcon />}
          subtextMessage="To get started tap the Register a Deal button"
          paramsToUrl={true}
          history={this.props.history}
          showPartnerFilter
        >
          <ReduxColumn
            type="link"
            isClickable="true"
            sortBy="account_name"
            field="accountName"
            renderField={row => (
              <Link to={`deal/details/${row.id}`}>{row.accountName}</Link>
            )}
          >
            Account Name
          </ReduxColumn>
          <ReduxColumn
            field="status"
            disableOnClick
            renderField={row => (
              <div
                className={`text-center text-uppercase ${
                  row.status !== DEAL_STATUS_APPROVED
                    ? row.status === DEAL_STATUS_CLOSED ||
                      row.status === DEAL_STATUS_DECLINED
                      ? "bg-danger"
                      : "landing-page-status-draft"
                    : "landing-page-status-active"
                }`}
                style={statusStyle}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn field="stage_tag_name" sortBy="stage_tag_name">
            Stage
          </ReduxColumn>
          <ReduxColumn
            field="amount"
            disableOnClick
            renderField={row => {
              return (
                <div className="text-left">
                  <p>{row.amount ? `$ ${row.amount}` : ""}</p>
                </div>
              );
            }}
          >
            Amount
          </ReduxColumn>
          <ReduxColumn field="partnerName" sortBy="partner_name">
            Partner
          </ReduxColumn>
          <ReduxColumn field="createdAt" sortBy="createdAt">
            Created At
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  {row.status == "Closed" && (
                    <DropdownItem
                      onClick={() => {
                        this.props.history.push(
                          `/deal/details/${row.deal_id}?redirect=${currentUrl}`
                        );
                      }}
                    >
                      View Details
                    </DropdownItem>
                  )}
                  <Link
                    className="text-dark text-decoration-none"
                    to={`/deal/details/${row.deal_id}?redirect=${currentUrl}`}
                    style={{ cursor: "pointer" }}
                  >
                    {row.status !== "Closed" && (
                      <DropdownItem>Edit</DropdownItem>
                    )}
                  </Link>
                  {(isSuperAdmin(this.props.roleId) ||
                    isCompanyAdmin(this.props.roleId) ||
                    isCompanyManager(this.props.roleId)) && (
                    <>
                      {row.status !== "Approved" && row.status !== "Closed" && (
                        <DropdownItem
                          className="text-success"
                          onClick={() =>
                            this.updateDealStatus(
                              "Approved",
                              row.deal_id,
                              params
                            )
                          }
                        >
                          Approve Deal
                        </DropdownItem>
                      )}

                      {row.status !== "Declined" && row.status !== "Closed" && (
                        <DropdownItem
                          className="text-danger"
                          onClick={() => this.toggleModal(row.deal_id)}
                        >
                          Decline Deal
                        </DropdownItem>
                      )}

                      {row.status !== "Closed" && row.status !== "Closed" && (
                        <DropdownItem
                          className="text-danger"
                          onClick={() =>
                            this.updateDealStatus("Closed", row.deal_id, params)
                          }
                        >
                          Close Deal
                        </DropdownItem>
                      )}
                    </>
                  )}
                  {row.status !== "Closed" && (
                    <DropdownItem
                      className="font-weight-bold text-danger"
                      onClick={() =>
                        this.setState({
                          deleteDealModal: true,
                          deleteDeal: row
                        })
                      }
                    >
                      Delete
                    </DropdownItem>
                  )}
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          isOpen={deleteDealModal}
          toggle={() => {
            this.setState({ deleteDealModal: false });
          }}
          title="Delete Deal"
          label={deleteDeal.accountName}
          deleteFunction={() => this.handleDelete(deleteDeal.deal_id)}
        />

        {/* Declined Deal Modal */}
        {deal_id && declineDealModal && (
          <DeclineDealModal
            modal={declineDealModal}
            setModal={this.toggleModal}
            dealId={deal_id}
            listName={"newDeals"}
            params={{ status: getParamsByName("status") }}
          />
        )}
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updateDeal, deleteDeal }, dispatch)
  };
}
const mapStateToProps = state => {
  let roleId = state ? state.user.roleId : "";

  return { roleId };
};
export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(AllDeals);
