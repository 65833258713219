import React from "react";
import "react-datepicker/dist/react-datepicker.css";

// Components
import Text from "../../components/base/Text";
import Select from "../../components/base/Select";
import Form from "../../components/base/Form";
import { endpoints } from "../../configs";
import { apiClient } from "../../apiClient";
import { isBadRequest } from "../../common/http";
import toast from "../../components/base/Toast";
import SaveButton from "../../components/base/SaveButton";
import Spinner from "../../components/base/Spinner";
// Helper
import { getParamsByName } from "../../lib/helper";

class PricingTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pricingDetail: "",
      appId: getParamsByName("id"),
      isLoading: false,
      isPutMethod: false,
      model_id: ""
    };
  }

  componentDidMount() {
    this._getPricingDetails(this.state.appId);
  }

  // Pricing details
  _getPricingDetails = () => {
    const id = this.state.appId;
    this.setState({ isLoading: true }, () => {
      return apiClient
        .get(`${endpoints().appPricingAPI}/get?app_id=${id}`)
        .then(response => {
          this.setState({
            pricingDetail: response.data,
            isLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  };

  _submit(values) {
    const data = new FormData();
    data.append(
      "currency",
      values.currency && values.currency.id ? values.currency.id : ""
    );
    data.append(
      "billingPeriod",
      values.billingPeriod && values.billingPeriod.id
        ? values.billingPeriod.id
        : ""
    );
    data.append("modelId", values.modelId ? values.modelId : "");
    data.append("type", values.type ? values.type : "");
    data.append("license", values.license ? values.license : "");
    data.append("trial", values.trial ? values.trial : "");
    data.append("feePayer", values.feePayer ? values.feePayer : "");
    data.append("commission", values.commission ? values.commission : "");
    data.append("price", values.price ? values.price : "");
    data.append(
      "billingPeriodUnit",
      values.billingPeriodUnit ? values.billingPeriodUnit : ""
    );
    data.append("modelType", values.modelType ? values.modelType : "");
    data.append("appId", this.state.appId ? this.state.appId : "");

    this._createPriceForm(data);
  }

  // Creating Pricing
  _createPriceForm(data) {
    apiClient
      .post(endpoints().appPricingAPI, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          this._getPricingDetails();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  _getData(values) {
    const data = new FormData();

    data.append(
      "currency",
      values.currency && values.currency.id ? values.currency.id : []
    );

    data.append(
      "billingPeriod",
      values.billingPeriod && values.billingPeriod.id
        ? values.billingPeriod.id
        : []
    );

    data.append("modelId", values.modelId ? values.modelId : "");
    data.append("type", values.type ? values.type : "");
    data.append("license", values.license ? values.license : "");
    data.append("trial", values.trial ? values.trial : "");
    data.append("feePayer", values.feePayer ? values.feePayer : "");
    data.append("commission", values.commission ? values.commission : "");
    data.append("price", values.price ? values.price : "");

    data.append(
      "billingPeriodUnit",
      values.billingPeriodUnit ? values.billingPeriodUnit : ""
    );
    data.append("modelType", values.modelType ? values.modelType : "");
    data.append("appId", values.appId ? values.appId : "");
    return data;
  }

  // update the pricing
  _submitUpdate(values) {
    const data = this._getData(values);
    this._updatePriceForm(data);
  }
  _updatePriceForm(data) {
    const id = this.state.pricingDetail.id;
    apiClient
      .put(`${endpoints().appPricingAPI}/update/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          this._getPricingDetails(this.state.appId);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    const { pricingDetail } = this.state;
    const currencyList = [
      {
        label: "USD",
        value: "USD",
        id: 1
      },
      {
        label: "CAD",
        value: "CAD",
        id: 2
      },
      {
        label: "EUR",
        value: "EUR",
        id: 3
      },
      {
        label: "GPB",
        value: "GPB",
        id: 4
      }
    ];

    const billingList = [
      {
        label: "Monthly",
        value: "Monthly",
        id: 1
      },
      {
        label: "Yearly",
        value: "Yearly",
        id: 2
      }
    ];

    const id = this.state.pricingDetail.id;
    const { isLoading } = this.state;
    if (isLoading) {
      return <Spinner />;
    }

    // InitialValues
    const initialValues = {
      modelId: pricingDetail.modelId || "",
      type: pricingDetail.type || "",
      currency: pricingDetail.currency
        ? currencyList.find(
            currencyList => currencyList.id == pricingDetail.currency
          )
        : "",
      billingPeriod: pricingDetail.billingPeriod
        ? billingList.find(
            billingList => billingList.id == pricingDetail.billingPeriod
          )
        : "",
      license: pricingDetail.license || "",
      trial: pricingDetail.trial || "",
      modelType: pricingDetail.modelType || "",
      price: pricingDetail.price || "",
      feePayer: pricingDetail.feePayer || "",
      commission: pricingDetail.commission || "",
      billingPeriodUnit: pricingDetail.billingPeriodUnit || ""
    };

    return (
      <>
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            if (!id) {
              this._submit(values);
            } else {
              this._submitUpdate(values);
            }
          }}
        >
          <div className="col-lg-6">
            {/* Model ID*/}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text
                  id="modelId"
                  name="modelId"
                  label="Model ID"
                  fontBolded
                  placeholder="Enter Modal Id"
                  error=""
                />
              </div>
            </div>

            {/* currency */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Select
                  id="currency"
                  name="currency"
                  label="Currency"
                  options={currencyList}
                  fontBolded
                  placeholder=""
                  defaultValue={
                    pricingDetail.currency
                      ? currencyList.find(
                          currencyList =>
                            currencyList.id == pricingDetail.currency
                        )
                      : ""
                  }
                />
              </div>
            </div>

            {/* Type */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text id="type" name="type" label="Type" fontBolded />
              </div>
            </div>

            {/* Price */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text
                  id="price"
                  name="price"
                  label="Price"
                  fontBolded
                  required={true}
                />
              </div>
            </div>

            {/* License */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text id="license" name="license" label="License" fontBolded />
              </div>
            </div>

            {/* Trial */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text id={"trial"} name={"trial"} label="Trial" fontBolded />
              </div>
            </div>

            {/* Fee Payer */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text
                  id="feePayer"
                  name="feePayer"
                  label="Fee Payer"
                  fontBolded
                  error=""
                />
              </div>
            </div>
            {/* Commission */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text
                  id="commission"
                  name="commission"
                  label="Commission"
                  fontBolded
                  error=""
                />
              </div>
            </div>
            {/* Billing Period*/}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Select
                  id="billingPeriod"
                  name="billingPeriod"
                  label="Billing Period"
                  fontBolded
                  options={billingList}
                  error=""
                  defaultValue={
                    billingList[parseInt(pricingDetail.billingPeriod)]
                  }
                />
              </div>
            </div>
            {/* Billing Period Unit*/}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text
                  id="billingPeriodUnit"
                  name="billingPeriodUnit"
                  label="Billing Period Unit"
                  fontBolded
                  error=""
                />
              </div>
            </div>
            {/* Model type*/}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text
                  id="modelType"
                  name="modelType"
                  label="Model Type"
                  fontBolded
                  error=""
                />
              </div>
            </div>
          </div>

          <SaveButton
            // id={id}
            type="submit"
            className={["btn", "btn-primary", "pull right"].join(" ")}
          >
            Save
          </SaveButton>
        </Form>
      </>
    );
  }
}

export default PricingTab;
