import React from "react";
import toast from "../../components/base/Toast";

// Components
import Form from "../../components/base/Form";
import CancelButton from "../../components/base/CancelButton";
import PageTitle from "../../components/base/PageTitle";
import AdminActionNavigation from "../../components/base/AdminActionNavigation";
import Spinner from "../../components/base/Spinner";
import Text from "../../components/base/Text";
import Phone from "../../components/base/Phone";
import TextArea from "../../components/base/TextArea";
import Email from "../../components/base/Email";
import SideBar from "../leads/inner-components/SideBar";
import PartnerAcceptLeadAlert from "../../components/PartnerAcceptLeadAlert";
import SaveButton from "../../components/base/SaveButton";

// 404 Page
import Page404 from "../Page404";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient, apiClientGet } from "../../apiClient";

//Assets
import { ChevronRight } from "../../assets/img/icons";

// Helper
import { isPartner } from "../../lib/helper";
import {
  LEAD_PARTNER_STATUS_CHOSEN,
  LEAD_PARTNER_STATUS_SHORTLISTED,
  LEAD_PARTNER_STATUS_PENDING
} from "../../lead/Constants";
import { isBadRequest } from "../../common/http";

import { connect } from "react-redux";

class PlaybookInquiryDetails extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      isLoading: true,
      partnerContactDetails: "",
      leadId: this.props.match.params.id,
      isLeadUpdate: false,
      leadPartnerUser: "",
      isPageNotFound: false
    };
  }

  getPartnerContactDetails = () => {
    this.setState({ isLoading: true });
    apiClientGet(
      `${endpoints().lead}/${this.props.match.params.id}`,
      (errMessage, response) => {
        if (errMessage) {
          this.setState({
            isPageNotFound: true,
            isLoading: false
          });
        } else {
          const partnerContactDetails = response && response.data;
          this.setState({
            partnerContactDetails,
            leadPartnerUser:
              partnerContactDetails && partnerContactDetails.leadPartnerUser,
            isLoading: false
          });
        }
      }
    );
  };

  componentDidMount() {
    this.getPartnerContactDetails();
  }

  _submit = values => {
    values.playbookId = this.state.partnerContactDetails.playbookId;
    values.status = this.state.partnerContactDetails.status;

    apiClient
      .put(`${endpoints().lead}/${this.props.match.params.id}`, values)
      .then(response => {
        toast.success(response.data.message);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  handleCancelButton = () => {
    this.props.history.push("/leads?section=New");
  };

  updatePartnerLeadStatus = (status, leadId) => {
    return apiClient
      .put(
        `${
          endpoints().leadPartnerAPI
        }/updateStatus?leadId=${leadId}&status=${status}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);

          this.getPartnerContactDetails(this.props.match.params.id);
          this.setState({ isLeadUpdate: true });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };

  render() {
    const {
      partnerContactDetails,
      isLoading,
      leadPartnerUser,
      isPageNotFound
    } = this.state;

    if (isLoading) return <Spinner />;

    if (isPageNotFound) return <Page404 />;

    const initialValues = {
      name: (partnerContactDetails && partnerContactDetails.name) || "",
      email: (partnerContactDetails && partnerContactDetails.email) || "",
      phone: (partnerContactDetails && partnerContactDetails.phone) || "",
      companyName:
        (partnerContactDetails && partnerContactDetails.companyName) || "",
      notes: (partnerContactDetails && partnerContactDetails.notes) || ""
    };

    return (
      <>
        <React.Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={values => {
              this._submit(values);
            }}
          >
            <AdminActionNavigation>
              <div className="d-flex align-items-center mb-3">
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    this.props.history.push("/leads?section=New");
                  }}
                >
                  Leads
                </a>
                <span
                  className="text-dark d-inline-flex align-items-center"
                  style={{ width: "15px" }}
                >
                  <ChevronRight />
                </span>
                <span
                  onClick={e => e.preventDefault()}
                  className={[
                    "text-dark",
                    "font-weight-bold",
                    "d-inline-flex",
                    "align-items-center",
                    "project-name",
                    "w-320"
                  ].join(" ")}
                >
                  <span className="ellipsis">
                    Edit Partner Contact Submission
                  </span>
                </span>
              </div>
              <CancelButton
                className="ml-auto mr-1"
                onClick={this.handleCancelButton}
              />
              <SaveButton />
            </AdminActionNavigation>

            <PageTitle label="Edit Partner Contact Submission" />
            {isPartner(this.props.roleId) &&
              leadPartnerUser &&
              (leadPartnerUser.status === LEAD_PARTNER_STATUS_SHORTLISTED ||
                leadPartnerUser.status === LEAD_PARTNER_STATUS_CHOSEN ||
                leadPartnerUser.status === LEAD_PARTNER_STATUS_PENDING) && (
                <PartnerAcceptLeadAlert
                  updatePartnerLeadStatus={this.updatePartnerLeadStatus}
                  leadDetails={partnerContactDetails}
                />
              )}
            <div className="card border-0">
              <div className="card-body">
                <div className="project-details-body">
                  <div className="details-body-main">
                    <div className="col-lg-12">
                      <div className="field-wrapper">
                        <Text
                          name="name"
                          label="Name"
                          placeholder="Enter name"
                          required={true}
                          error=""
                        />
                      </div>
                      <div className="field-wrapper">
                        <Email
                          name="email"
                          label="Email Address"
                          placeholder="Enter email address"
                          required={true}
                          error=""
                        />
                      </div>
                      <div className="field-wrapper">
                        <Phone
                          name="phone"
                          label="Phone Number"
                          placeholder="Enter phone number"
                          error=""
                          required
                        />
                      </div>
                      <div className="field-wrapper">
                        <Text
                          name="companyName"
                          label="Company Name"
                          placeholder="Enter company name"
                          required={true}
                          error=""
                        />
                      </div>
                      <div className="field-wrapper">
                        <TextArea
                          name="notes"
                          label="Description of need or project"
                          placeholder="Enter project description"
                          required={true}
                          error="Please enter project description"
                        />
                      </div>
                    </div>
                  </div>
                  {/* Sidebar */}
                  <SideBar
                    status={leadPartnerUser}
                    leadDetails={partnerContactDetails}
                    leadId={this.state.leadId}
                    isLeadUpdate={this.state.isLeadUpdate}
                  />
                </div>
              </div>
            </div>
          </Form>
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(PlaybookInquiryDetails);
