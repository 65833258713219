import React, { useEffect, useState } from "react";

// API Client
import { apiClient } from "../../../../apiClient";
import { endpoints } from "../../../../configs";

// Constants
import {
  PROJECT_STATUS_ACTIVE,
  PROJECT_STATUS_INPROCESS
} from "../../../../projectStatus/Constants";
import { PROJECT_USER_STATUS_ACCEPTED } from "../../../../project/Constants";

// Components
import ConfirmKickoffTime from "./ConfirmKickoffTime";

// Helper
import { isExpert, isCustomer } from "../../../../lib/helper";

import { useSelector } from "react-redux";

const KickOffMeetingList = () => {
  const [activeProjectList, setActiveProjectList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const currentUser = useSelector(state => state.user);

  const projectStatus = isCustomer(currentUser && currentUser.roleId)
    ? PROJECT_STATUS_INPROCESS
    : PROJECT_STATUS_ACTIVE;

  // Get active project list
  const getProjectList = async status => {
    setIsLoading(true);
    const projectUserStatus = isExpert(currentUser && currentUser.roleId)
      ? `&projectUserStatus=${PROJECT_USER_STATUS_ACCEPTED}`
      : "";
    const response = await apiClient.get(
      `${endpoints().projectAPI}/search?status=${status}${projectUserStatus}`
    );

    const data = response.data;
    const activeProjects = data.activeProjectsList
      ? data.activeProjectsList
      : [];
    setActiveProjectList(activeProjects);
    setIsLoading(false);
  };

  useEffect(() => {
    getProjectList(projectStatus);
  }, []);

  if (isLoading) {
    return "";
  }

  return (
    <>
      {activeProjectList && activeProjectList.length > 0 && !isLoading
        ? activeProjectList.map(project => (
            <>
              <ConfirmKickoffTime project={project} />
            </>
          ))
        : ""}
    </>
  );
};

export default KickOffMeetingList;
