import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

const OnboardingLink = ({
  icon,
  title,
  description,
  progress,
  partnerStatus = {}
}) => {
  return (
    <div className="onboarding-link">
      <div className="onboarding-icon">
        <div className="icon-wrapper">{icon}</div>
      </div>
      <div className="onboarding-main">
        <h5 className="font-weight-bold">{title}</h5>
        <p className="font-weight-bold text-inline-grayed">{description}</p>
      </div>
      <div className="completion-gauge">
        <CircularProgressbarWithChildren
          value={progress}
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0,

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "round",

            // Text size
            textSize: "16px",

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: partnerStatus.pathColor || "rgba(10, 194, 151, 1)",
            trailColor: "#F0F0F1",
            backgroundColor: "#fff"
          })}
        >
          <span className="d-block text-center font-weight-light h3 mb-0">
            {progress <= 100 && <>{progress}%</>}
          </span>
          {progress <= 100 && (
            <span className="d-block text-center font-weight-bold h6 mb-0">
              Complete
            </span>
          )}
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
};

export default OnboardingLink;
