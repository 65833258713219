// Constants
import {
  REQUEST_PLAYBOOK_LIST,
  RECEIVE_PLAYBOOK_LIST,
  FETCH_PLAYBOOK_LIST_FAIL,
  CHANGE_PLAYBOOK_PAGE,
  RESET_PLAYBOOK_LIST
} from "../actionType/Constants";

export function playbookReducer(
  state = {
    isFetching: false
  },
  action
) {
  switch (action.type) {
    // Request Playbook list
    case REQUEST_PLAYBOOK_LIST: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }

    // Receive Playbook list
    case RECEIVE_PLAYBOOK_LIST: {
      return Object.assign({}, state, {
        pages: Object.assign({}, state.pages, {
          [action.payload.currentPage]: action.payload.data.map(n => n.id)
        }),
        statuses: Object.assign({}, state.statuses, {
          [action.payload.currentPage]: action.payload.status
        }),
        pagination: {
          currentPage: action.payload.currentPage,
          totalCount: action.payload.totalCount,
          activePlaybookCount: action.payload.activePlaybookCount,
          draftedPlaybookCount: action.payload.draftedPlaybookCount
        },
        isFetching: false
      });
    }

    case RESET_PLAYBOOK_LIST: {
      state = undefined;
    }

    // Fetch Playbook list fail
    case FETCH_PLAYBOOK_LIST_FAIL:
      return { ...state, isFetching: false };

    // Change Playbook Page
    case CHANGE_PLAYBOOK_PAGE: {
      return Object.assign({}, state, {
        pagination: Object.assign({}, state.pagination, {
          currentPage: action.payload
        })
      });
    }

    default:
      return state;
  }
}
