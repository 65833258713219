import React from "react";
import LazyLoad, { forceVisible } from "react-lazyload";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

// Constant
import { ACTIVITY_TYPE_RESOURCE_VIEWED } from "../../../Activity/Constants";

// Activity Lib
import { createActivity } from "../../../lib/Activity";

// Variable
import variables from "../../../scss/_variables.scss";

// Assets
import headerTriangles from "../../../assets/header-triangles.svg";

// Components
import PlaybookIcon from "../../../components/base/PlaybookIcon";
import MoreDropdown from "../../../components/base/MoreDropdown";

// Library
import {
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../lib/helper";
import {
  PARTNER_PERMISSION_INDIVIDUAL_PARTNER,
  PARTNER_PERMISSION_ALL_PARTNER
} from "../../../resource/Constants";
import Badge from "../../../components/base/Badge";
import { COURSE_PUBLISHED } from "../../../course/Constants";
import { connect } from "react-redux";

class GridCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      cardLink:
        this.props.isPreview === "true"
          ? "#"
          : this.props.link
          ? this.props.link
          : "#"
    };
  }

  componentDidMount() {
    forceVisible();
  }

  // Open the link
  openLink() {
    const { link, openLinkInNewTab } = this.props;
    if (link) {
      if (openLinkInNewTab) {
        window.open(link, "_blank");
      } else {
        window.open(link, "_self");
      }
    }
  }
  // Set Card Link
  setCardLink() {
    const { openLinkInNewTab } = this.props;
    if (openLinkInNewTab) {
      this.setState({
        cardLink: "#"
      });
    } else {
      this.setState({
        cardLink:
          this.props.isPreview === "true"
            ? "Javascript:void(0);"
            : this.props.link
            ? this.props.link
            : "#"
      });
    }
  }

  // Reset Card Link
  resetCardLink() {
    this.setState({
      cardLink: "#"
    });
  }

  render() {
    const cardBoxStyle = {
      maxWidth: "321px",
      height: showCardBody ? "100%" : "350px",
      width: this.props.fullWidth ? "" : "100%"
    };

    const multiLineElipsisStyle = {
      display: "-webkit-box",
      WebkitLineClamp: "2",
      WebkitBoxOrient: "vertical",
      overflow: "hidden"
    };

    // props
    const {
      data,
      size,
      link,
      viewLink,
      openLinkInNewTab,
      showEditOptions,
      onClickDelete,
      showCardBody,
      isPreview,
      bannerImageUrl,
      boxShadow,
      placeHolder,
      showOption,
      removePartnerPlaybook,
      right,
      left,
      marginLeft,
      showCategory,
      category,
      marginRight,
      description
    } = this.props;

    // Resource details
    const { id, bannerImagePathUrl, partnerId, appLogoUrl } = data;

    const name = data.name || data.title;
    const bannerIcon = data.bannerIcon || data.banner_icon;
    const bannerColor = data.bannerColor || data.banner_color;

    const cursorPointer = isPreview === "true" ? "" : "cursor-pointer";

    const resourceFile =
      data.resource_file || data.course_file ? cursorPointer : "";

    const ImageURL = bannerImagePathUrl || bannerImageUrl;
    const logoUrl = appLogoUrl;

    const TagName = this.props.tag || Link;

    return (
      <TagName
        id="card-link"
        {...(TagName === "a"
          ? { href: this.state.cardLink }
          : !isPartner(this.props.roleId) && { href: this.state.cardLink })}
        style={{
          color: "#212529",
          cursor: isPreview ? "default" : "pointer",
          maxWidth: isPreview === "true" ? "" : "321px",
          marginRight: "30px",
          marginTop: "0px"
        }}
        className={`
          ${boxShadow !== false ? "playbook-card-link" : ""}
          ${isPreview === "true" ? "" : size ? size : "col-4"}
          mb-4 pb-1
          text-decoration-none
          pl-0 pr-0 ml-3 mt-0 
        `}
        key={id}
        onClick={e => {
          if (isPartner(this.props.roleId)) {
            const activityData = {
              activityType: ACTIVITY_TYPE_RESOURCE_VIEWED,
              notes: `${name}`
            };
            e.preventDefault();
            this.openLink();

            // Create ResourceActivity
            createActivity(activityData, this.props.roleId, () => {});
          }
          if (openLinkInNewTab && TagName !== "a") {
            e.preventDefault();
            this.openLink();
          }
        }}
      >
        <div className={`${cursorPointer} card `} style={cardBoxStyle}>
          <LazyLoad>
            <div
              id="card-image"
              className="card-header"
              style={{
                backgroundImage: `url(${
                  bannerImageUrl
                    ? bannerImageUrl
                    : ImageURL
                    ? ImageURL
                    : logoUrl
                    ? logoUrl
                    : headerTriangles
                })`,
                backgroundColor: `${bannerColor}`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat"
              }}
              onMouseOver={e => {
                if (showOption) {
                  e.preventDefault();
                  if (e.target.id === "card-image") {
                    this.setCardLink();
                  } else {
                    this.resetCardLink();
                  }
                }
              }}
              onClick={e => {
                if (showOption) {
                  e.preventDefault();
                  if (e.target.id === "card-image") {
                    this.openLink();
                    // window.open("#");
                  }
                }
                if (this.props.video) {
                  this.props.video();
                }
              }}
            >
              <Badge
                title={
                  data.sharingPermission == PARTNER_PERMISSION_ALL_PARTNER
                    ? "All Partners"
                    : ""
                }
                backgroundColor="#4ab7e2"
                position="relative"
                marginLeft={marginLeft}
                right={right}
                marginBottom="150px"
                marginRight={marginRight}
              />
              <div
                data-bs-toggle="tooltip"
                data-bs-placement="right"
                title="Edit Option"
              >
                {showOption && showEditOptions && (
                  <MoreDropdown className="edit-option">
                    {isSuperAdmin(this.props.roleId) ||
                    isCompanyAdmin(this.props.roleId) ||
                    isCompanyManager(this.props.roleId) ||
                    (isPartner(this.props.roleId) && partnerId) ? (
                      <>
                        {viewLink && (
                          <a
                            href={viewLink}
                            target="_blank"
                            style={{
                              color: "inherit",
                              textDecoration: "none"
                            }}
                            rel="noreferrer"
                          >
                            <DropdownItem
                              onClick={e => {
                                e.preventDefault();
                                return viewLink && window.open(viewLink);
                              }}
                            >
                              View
                            </DropdownItem>
                          </a>
                        )}
                        <Link
                          to={`${this.props.editLink || link}`}
                          style={{
                            color: "inherit",
                            textDecoration: "none"
                          }}
                        >
                          <DropdownItem>Edit</DropdownItem>
                        </Link>
                        <DropdownItem divider />
                        <DropdownItem
                          className={"text-danger"}
                          onClick={onClickDelete}
                        >
                          Delete
                        </DropdownItem>
                      </>
                    ) : (
                      <>
                        <DropdownItem
                          className={"text-danger"}
                          onClick={removePartnerPlaybook}
                        >
                          Remove
                        </DropdownItem>
                      </>
                    )}
                  </MoreDropdown>
                )}
              </div>
              {/* /.dropdown-wrapper */}
              <div
                className="card-header-icon"
                onClick={() => showOption && this.openLink()}
              >
                {!ImageURL ? <PlaybookIcon iconName={bannerIcon} /> : ""}
              </div>
            </div>
          </LazyLoad>
          <div
            onClick={e => {
              if (showOption) {
                e.preventDefault();
                if (e.target.id === "card-image") {
                  this.openLink();
                }
              }
            }}
          >
            <div className={"body-wrapper"}>
              {showCardBody !== false ? (
                <div
                  className="card-body pt-0"
                  onClick={() => showOption && this.openLink()}
                >
                  <Badge
                    left={left}
                    right={right}
                    title={category ? category : ""}
                    backgroundColor={category ? "#554BEF" : "#292933"}
                    className={category ? "" : "invisible"}
                  />
                  <div className={`${category ? "" : "mt-4 pt-1"}`}>
                    <p
                      className={`${resourceFile} card-title font-weight-bold mb-0 mt-2`}
                      style={multiLineElipsisStyle}
                    >
                      {!name && placeHolder ? "" : name}
                    </p>
                  </div>
                  <span className="text-grayed font-weight-bold mb-0 mt-2">
                    {data.courseLesson && (
                      <small className="font-weight-bold">
                        {data.courseLesson.length} Lesson Module
                        {data.courseLesson.length &&
                        data.courseLesson.length > 1
                          ? "s"
                          : ""}
                      </small>
                    )}
                  </span>
                  <div className="text-center mt-2">
                    <small className="font-weight-bold">
                      {showCategory && data.category && (
                        <span className="text-center bg-dark text-white rounded-pill px-2 py-1">
                          {data.category}
                        </span>
                      )}
                    </small>
                  </div>
                  {description && (
                    <div>
                      <small
                        style={{
                          fontSize: "small",
                          display: "-webkit-box",
                          WebkitLineClamp: 2,
                          WebkitBoxOrient: "vertical",
                          overflow: "hidden",
                          whiteSpace: "break-spaces"
                        }}
                      >
                        {description}
                      </small>
                    </div>
                  )}
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
      </TagName>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(GridCard);
