import {
  RECEIVE_PARTNER_SETTINGS_LIST,
  REQUEST_PARTNER_SETTINGS_LIST,
  FETCH_PARTNER_SETTINGS_LIST_FAIL
} from "../actionType/Constants";

import { apiClient } from "../apiClient";

import { endpoints } from "../configs";

// Components
import toast from "../components/base/Toast";
import { getCookie, getUserId } from "../lib/helper";

import { COOKIE_USER_ID } from "../lib/cookie";

/**
 * Request for Settings list
 */
function requestPartnerSettings() {
  return {
    type: REQUEST_PARTNER_SETTINGS_LIST
  };
}

/**
 * Receive for Settings list
 */
function receivePartnerSettings({ data: payload }) {
  return {
    type: RECEIVE_PARTNER_SETTINGS_LIST,
    payload
  };
}

/**
 * Settings list fails
 */
function fetchPartnerSettingsFail() {
  return {
    type: FETCH_PARTNER_SETTINGS_LIST_FAIL,
    payload: []
  };
}

/**
 * Fetch Settings
 */
export function fetchPartnerSettings() {
  return dispatch => {
    dispatch(requestPartnerSettings());
    let userId = getUserId();

    return apiClient
      .get(`${endpoints().partnerTypeSettingAPI}/setting?userId=${userId}`)
      .then(response => response)
      .then(response => {
        dispatch(receivePartnerSettings(response));
      })
      .catch(error => {
        dispatch(fetchPartnerSettingsFail());

        if (error.status >= 400) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}

/**
 * Fetch Settings
 */
export function fetchPartnerSettingsByUserId(userId, callback) {
  if (userId) {
    apiClient
      .get(`${endpoints().partnerTypeSettingAPI}/setting?userId=${userId}`)
      .then(response => response)
      .then(response => {
        return callback(null, response);
      })
      .catch(error => {
        if (error.status >= 400) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
        }
        return callback(error, null);
      });
  }
}

/**
 * Fetch Settings
 */
export function fetchPartnerSettingsByPartnerId(partnerId, callback) {
  if (partnerId) {
    apiClient
      .get(`${endpoints().partnerTypeSettingAPI}/setting/${partnerId}}`)
      .then(response => response)
      .then(response => {
        return callback(null, response);
      })
      .catch(error => {
        if (error.status >= 400) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
        }
        return callback(error, null);
      });
  }
}
