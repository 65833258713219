export const DEFAULT_PAGE = 1;
export const DEFAULT_PAGE_SIZE = 10;
export const DEFAULT_CARD_PAGE_SIZE = 12;
export const PAGE_SIZE = 25;

export const cardPageSizeOption = [
  {
    value: "12",
    label: "12"
  },
  {
    value: "24",
    label: "24"
  },
  {
    value: "60",
    label: "60"
  },
  {
    value: "120",
    label: "120"
  }
];
