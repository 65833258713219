import React from "react";

// Components
import Form from "../../../../../components/base/Form";
import DefaultContent from "../../../../../components/content/DefaultContent";
import Text from "../../../../../components/base/Text";
import SaveButton from "../../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_BLUE_JEANS_API_BASE_URL,
  SETTINGS_BLUE_JEANS_BASE_URL,
  SETTINGS_BLUE_JEANS_PASSWORD,
  SETTINGS_BLUE_JEANS_USERNAME
} from "../../../../../setting/Constants";

const BlueJeansSection = props => {
  const { adminSettings, saveSettings } = props;

  const {
    blue_jeans_api_base_url,
    blue_jeans_base_url,
    blue_jeans_username,
    blue_jeans_password
  } = adminSettings;

  // BlueJeans Section Initial Values
  const blueJeansSettingsInitialValues = {
    blue_jeans_api_base_url,
    blue_jeans_base_url,
    blue_jeans_username,
    blue_jeans_password
  };

  // BlueJeans Section Submit Form
  const submit = values => {
    const data = new FormData();

    if (values.blue_jeans_api_base_url !== undefined) {
      data.append(
        "blue_jeans_api_base_url",
        values.blue_jeans_api_base_url ? values.blue_jeans_api_base_url : ""
      );
    }

    if (values.blue_jeans_base_url !== undefined) {
      data.append(
        "blue_jeans_base_url",
        values.blue_jeans_base_url ? values.blue_jeans_base_url : ""
      );
    }

    if (values.blue_jeans_username !== undefined) {
      data.append(
        "blue_jeans_username",
        values.blue_jeans_username ? values.blue_jeans_username : ""
      );
    }

    if (values.blue_jeans_password !== undefined) {
      data.append(
        "blue_jeans_password",
        values.blue_jeans_password ? values.blue_jeans_password : ""
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={blueJeansSettingsInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <h5 className="font-weight-bold">Portal API Url</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_BLUE_JEANS_API_BASE_URL}
            placeholder="Enter Blue Jeans Portal API Url"
          />
        </div>

        <h5 className="font-weight-bold">Portal Url</h5>
        <Text
          name={SETTINGS_BLUE_JEANS_BASE_URL}
          placeholder="Enter Blue Jeans Portal Url"
        />

        <h5 className="font-weight-bold">Username</h5>
        <Text
          name={SETTINGS_BLUE_JEANS_USERNAME}
          placeholder="Enter Blue Jeans Username"
        />

        <h5 className="font-weight-bold">Password</h5>
        <Text
          name={SETTINGS_BLUE_JEANS_PASSWORD}
          placeholder="Enter Blue Jeans Password"
        />
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default BlueJeansSection;
