import React, { useState } from "react";
import { Link } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { formatCurrency } from "../../../helpers/currency";

// Components
import SidebarItem from "./SidebarItem";
import toast from "../../../components/base/Toast";

import Form from "../../../components/base/Form";

//API call
import { apiClient } from "../../../apiClient";

// Configs
import { endpoints } from "../../../configs";

// Assets
import blueMessageIcon from "../../../assets/img/icons/icon-message.svg";
import TextArea from "../../../components/base/TextArea";
import { isBadRequest } from "../../../common/http";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";
import Currency from "../../../components/Currency";

// Send Partner Message API Call
function sendMessageData(referralDetails, values, toggle) {
  return apiClient
    .post(`${endpoints().referralApi}/sendMessage`, {
      message: values,
      referralDetails,
      EmailTo: "PartnerManagerMessage"
    })
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      // props.actions.fetchList("accounts", "/v1/account/search");

      setTimeout(() => {
        toggle();
      }, 300);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    });
}

const ReferralClosedBanner = ({
  referralDetails,
  show,
  togglePayInvoice,
  enableReferralPayments,
  isInvoicePaid
}) => {
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [enteredMessage, setEnteredMessage] = useState("");
  const handleEnteredInput = e => setEnteredMessage(e.target.value);
  return (
    <div>
      {enableReferralPayments && (
        <div
          className="card border-0 text-white"
          style={{ backgroundColor: "#2F2F3E" }}
        >
          <div className="project-details-body">
            <div className="details-body-main">
              <div className="card-body pt-0">
                <div className="certification mb-2">
                  <span
                    className="badge badge-pill badge-primary"
                    style={{ backgroundColor: "#0AC297", marginLeft: "122px" }}
                  >
                    Payout
                  </span>
                </div>
                <h4 className="font-weight-bold">Congratulations!</h4>
                <p>
                  This deal is closed and your{" "}
                  {Terminology.get(SystemName.REFERRAL)} is ready to be paid.
                </p>

                <div className="btn-wrapper">
                  <button
                    type="button"
                    className="btn btn-outline-secondary h6-5 font-weight-bold px-3 mr-2 text-white"
                    onClick={toggle}
                  >
                    Message {Terminology.get(SystemName.PARTNER_MANAGER)}
                  </button>

                  <Modal
                    isOpen={modal}
                    toggle={toggle}
                    className={["edit-task-modal"].join(" ")}
                    backdrop="static"
                  >
                    <ModalHeader toggle={toggle}>
                      <div className="content-wrapper">
                        <div className="icon-wrapper">
                          <div className="img-wrapper d-flex justify-content-center mb-2 mt-3">
                            <img src={blueMessageIcon} size="30" alt="" />
                          </div>
                          <p className="text-center font-weight-bold mb-3">
                            Send {Terminology.get(SystemName.PARTNER_MANAGER)} a
                            Message
                          </p>
                          <p className="text-center h6-5 mb-2"></p>
                        </div>
                      </div>
                    </ModalHeader>

                    <Form
                      initialValues={{
                        message: ""
                      }}
                    >
                      <ModalBody className="custom-modal-body">
                        <div className="mt-2 mb-3">
                          <div>
                            <TextArea
                              name="message"
                              label="Message"
                              placeholder="Message"
                              rows={5}
                              error=""
                              required={true}
                              onChange={handleEnteredInput}
                            />
                          </div>
                        </div>
                      </ModalBody>
                      <ModalFooter>
                        <div className="container-fluid">
                          <div className="col-sm-12 text-center">
                            <Button
                              onClick={sendMessageData.bind(
                                this,
                                referralDetails,
                                enteredMessage,
                                toggle
                              )}
                              label=""
                              className="h6-5-important"
                            >
                              Send Message
                            </Button>
                          </div>
                        </div>
                      </ModalFooter>
                    </Form>
                  </Modal>
                </div>
              </div>
            </div>

            <div
              className="details-body-sidebar"
              // style={{ borderLeft: "1px solid #E2E2E8" }}
            >
              <SidebarItem>
                <p className="font-weight-bold mb-0">Deal Earnings:</p>
                <h5 className="font-weight-bold mt-0 mb-0">
                  $
                  {referralDetails.commission_amount ? (
                    <Currency
                      amount={referralDetails.commission_amount}
                      currencyName={referralDetails.currency}
                    />
                  ) : (
                    0
                  )}
                </h5>
                <Link
                  to={`/edit/partner/public-profile?id=${referralDetails.partner.partner_id}&section=payout-preferences`}
                >
                  <p className="m-0">
                    <small className="font-weight-bold">
                      Edit Payout Prefernces
                    </small>
                  </p>
                </Link>
                <p className="font-weight-bold mt-2 mb-0">
                  Estimated Payment Date:
                </p>
                <h5 className="font-weight-bold mt-0">
                  {referralDetails.closeDate ? referralDetails.closeDate : ""}
                </h5>
              </SidebarItem>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReferralClosedBanner;
