import React, { useState, useEffect } from "react";

// Components
import Form from "./base/Form";

// API Client
import { apiClient } from "../apiClient";

import detectBrowser from "./helpers/detectBrowser";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../configs";
import PageSearch from "./base/PageSearch";
import LandingPageFilter from "./LandingPageFilters";
import { getCookie, isEmpty } from "../lib/helper";
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";
import { isBadRequest } from "../common/http";
import {
  LANDING_PAGE_FILTER_PARTNER_TIRE_VALUE,
  LANDING_PAGE_FILTER_PARTNER_TYPE_VALUE
} from "../landingPage/Constants";

const PartnerSidebarFilter = props => {
  const { partnerSelectedFilters, marketplaceId, typeIds, data } = props;
  const [search, setSearch] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(
    partnerSelectedFilters
  );
  const [isMobile] = useState(detectBrowser());
  const [tagTypeList, setTagTypeList] = useState([]);
  const [partnerTypeList, setPartnerTypeList] = useState([]);
  const [partnerTierList, setPartnerTierList] = useState([]);

  // Get tag types
  const getTagTypes = async () => {
    try {
      const response = await apiClient.get(
        `${
          endpoints().tagTypeAPI
        }?category=marketplace&marketplace=${marketplaceId}`
      );
      const results = response.data.data;

      if (results && results.length > 0) {
        setTagTypeList(results);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  // Get partner types
  const getPartnerTypes = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().publicAPI}/partnerType`
      );
      const results = response.data.data;
      if (results && results.length > 0) {
        let selectedId = [];
        if (typeIds && typeIds.length > 0) {
          typeIds.forEach(typeId => {
            results.forEach(partnerTypeId => {
              if (parseInt(typeId) === partnerTypeId.id) {
                selectedId.push(partnerTypeId);
              }
            });
          });
          setPartnerTypeList(selectedId);
        } else setPartnerTypeList(results);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  // Get partner types
  const getPartnerTiers = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().publicAPI}/partnerTier`
      );
      const results = response.data.data;
      if (results && results.length > 0) {
        setPartnerTierList(results);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    getTagTypes();
    getPartnerTypes();
    getPartnerTiers();
  }, []);

  const handleSearchTermChange = e => {
    setSearch(e.target.value);
  };

  const handleOnchangeSubmit = (values, selectName, selectValue) => {
    //Filter Partners
    props.filterPartnerList(values, search, selectName, selectValue);
    props.searchTerm(search);
  };

  useEffect(() => {
    handleOnchangeSubmit(selectedFilters);
  }, [search]);

  useEffect(() => {
    setSelectedFilters(partnerSelectedFilters);
  }, [partnerSelectedFilters]);

  const SectionBreakLine = ({ className }) => {
    return (
      <div
        className={className}
        style={{ borderBottom: "1px solid #f3f3f4" }}
      ></div>
    );
  };

  const getLandingPageBlockTagType = tagTypeId => {
    const tagType = [];
    if (tagTypeList) {
      tagTypeList.forEach(tagTypes => {
        if (tagTypes.id === tagTypeId) {
          tagType.push(tagTypes);
        }
      });
    }
    return tagType;
  };

  return (
    <div className={!isMobile ? `card p-3 mb-3` : `p-3`}>
      <Form
        initialValues={selectedFilters}
        enableReinitialize={true}
        onSubmit={() => {}}
      >
        <div className="row ">
          <div className="col-12 page-heading ">
            <PageSearch
              id={props.id}
              name="partner-search"
              classnames="page-search"
              placeholder="Search"
              onChange={handleSearchTermChange.bind(this)}
            />
            <SectionBreakLine className="pb-3" />
          </div>
          {props.landingBlockTagTypes &&
            props.landingBlockTagTypes.length > 0 &&
            props.landingBlockTagTypes.map(filterType => {
              return (
                <>
                  {filterType.tag_type_id ===
                    LANDING_PAGE_FILTER_PARTNER_TYPE_VALUE &&
                    data &&
                    data.show_partner_type == "true" && (
                      <LandingPageFilter
                        tagTypeList={
                          isEmpty(partnerTypeList)
                            ? [{ name: "Partner Type", tags: partnerTypeList }]
                            : []
                        }
                        displayName={"Partner Type"}
                        fieldStartName={"partnerType"}
                        multiselect={filterType.multi_select}
                        handleOnchangeSubmit={handleOnchangeSubmit}
                      />
                    )}
                  {filterType.tag_type_id ===
                    LANDING_PAGE_FILTER_PARTNER_TIRE_VALUE &&
                    data &&
                    data.show_partner_tier == "true" && (
                      <LandingPageFilter
                        tagTypeList={
                          isEmpty(partnerTierList)
                            ? [
                                {
                                  name: "Partner Tier",
                                  tags: partnerTierList
                                }
                              ]
                            : []
                        }
                        displayName={"Partner Tier"}
                        fieldStartName={"partnerTier"}
                        multiselect={filterType.multi_select}
                        handleOnchangeSubmit={handleOnchangeSubmit}
                      />
                    )}
                </>
              );
            })}
          {props.landingBlockTagTypes &&
            props.landingBlockTagTypes.length > 0 &&
            props.landingBlockTagTypes.map(landingBlockTagType => {
              return (
                <LandingPageFilter
                  tagTypeList={getLandingPageBlockTagType(
                    landingBlockTagType.tag_type_id
                  )}
                  displayName={landingBlockTagType.display_name}
                  multiselect={landingBlockTagType.multi_select}
                  handleOnchangeSubmit={handleOnchangeSubmit}
                />
              );
            })}
        </div>
      </Form>
    </div>
  );
};

export default PartnerSidebarFilter;
