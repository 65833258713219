// Api Call
import { apiClient } from "../apiClient";
import { endpoints } from "../configs";

// Config
import { DEFAULT_API_KEY } from "../configs";

// Helper
import { getCookie } from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

// Constants
import {
  REQUEST_RESOURCE_LIST,
  RECEIVE_RESOURCE_LIST,
  RESET_RESOURCE_LIST,
  FETCH_RESOURCE_LIST_FAIL,
  CHANGE_RESOURCE_PAGE
} from "../actionType/Constants";

/**
 * Request Resource
 */
export function requestResourceList() {
  return {
    type: REQUEST_RESOURCE_LIST
  };
}

/**
 * Receive Resource
 */
export function receiveResourceList(data) {
  return {
    type: RECEIVE_RESOURCE_LIST,
    payload: data
  };
}

/**
 * Reset Resource
 */
export function resetResourceList(data) {
  return {
    type: RESET_RESOURCE_LIST,
    payload: data
  };
}

/**
 * Fetch Resource fail
 *
 * @param {*} error
 */
export function fetchResourceListFail(error) {
  return {
    type: FETCH_RESOURCE_LIST_FAIL,
    error
  };
}

/**
 * Fetch Resource
 */
export function fetchResourceList(params) {
  params = params ? `&${params}` : "";
  return async dispatch => {
    dispatch(requestResourceList());
    try {
      if (getCookie(COOKIE_SESSION_TOKEN)) {
        apiClient.defaults.headers.common.Authorization = getCookie(
          COOKIE_SESSION_TOKEN
        );
      } else {
        apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
      }
      const response = await apiClient.get(
        `${endpoints().resourceAPI}/search?pagination=true${params}`
      );
      const data = response.data;
      return dispatch(receiveResourceList(data));
    } catch (error) {
      dispatch(fetchResourceListFail(error));
      if (error.status >= 400) {
        console.log("Error");
      }
    }
  };
}

/**
 * Reset Resource
 */
export function resetPlaybook(params) {
  return dispatch => {
    return dispatch(resetResourceList());
  };
}

/**
 *  Change Resource Page
 *
 * @param {*} payload
 */
export function changePlaybookPage(payload) {
  return { type: CHANGE_RESOURCE_PAGE, payload };
}
