import React from "react";
import { Card, CardBody } from "reactstrap";
import toast from "../../components/base/Toast";

// Components
import Form from "../../components/base/Form";
import Password from "../../components/base/Password";
import Button from "../../components/base/Button";

// Helper
import { setCookie } from "../../lib/helper";

import { COOKIE_SESSION_TOKEN, COOKIE_USER_ID } from "../../lib/cookie";

// API call
import { apiClientPostPublic } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Helper
import {
  getUrlParameter,
  getKeyValueByObject,
  validateStrongPassword
} from "../../lib/helper";

// Helper
import { SETTINGS_PORTAL_LOGO, MEDIA_BASE_URL } from "../../setting/Constants";

class setPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      errorMessage: ""
    };
    this._userSetPassword = this._userSetPassword.bind(this);
    this._updateDimensions = this._updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this._updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._updateDimensions);
  }

  // Update the screen height
  _updateDimensions() {
    this.setState({
      height: window.innerHeight
    });
  }

  // Set password API call
  _userSetPassword(data) {
    const token = getUrlParameter("token");

    apiClientPostPublic(
      `${endpoints().userAPI}/setPassword?token=${token}`,
      data,
      (errMessage, token, role, userId) => {
        if (errMessage) {
          toast.error(errMessage);
        } else {
          setCookie(COOKIE_SESSION_TOKEN, token);
          setCookie(COOKIE_USER_ID, userId);
          window.location = "/dashboard";
        }
      }
    );
  }

  // Reset the error message
  _hideErrorMessage = () => {
    this.setState({ errorMessage: "" });
  };

  render() {
    const cardCenter = (this.state.height / 100) * 30;
    const { errorMessage, height } = this.state;

    const { settings } = this.props;

    const marketplaceLogo = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO)
      : "";

    const mediaBaseUrl = settings
      ? getKeyValueByObject(settings, MEDIA_BASE_URL)
      : "";

    const renderSetPasswordForm = (
      <div className={`${errorMessage ? "mb-2" : "mb-4"}`}>
        <div className="mb-4">
          <Password
            name="password"
            placeholder="Password"
            onKeyDown={this._hideErrorMessage}
            required
          />
        </div>

        <div className={`${errorMessage ? "mb-n1" : "mb-2"}`}>
          <Password
            name="confirmPassword"
            placeholder="Confirm Password"
            onKeyDown={this._hideErrorMessage}
            required
          />
          <div className={`${errorMessage ? "mt-n3" : "mb-2"}`}>
            <span
              style={{ fontSize: "0.75rem" }}
              className={errorMessage ? "error-message" : ""}
            >
              {errorMessage}
            </span>
          </div>
        </div>
      </div>
    );

    const initialValues = {
      password: "",
      confirmPassword: ""
    };

    return (
      <div>
        <div className="container-fluid" style={{ height: height }}>
          <div className="container">
            <div className="row justify-content-center">
              <div
                className="col-lg-5 col-md-7 col-sm-12"
                style={{ paddingTop: cardCenter }}
              >
                <Card className="w-75 mx-auto rounded">
                  <div className="mx-auto mt-3">
                    {marketplaceLogo && (
                      <img
                        src={`${mediaBaseUrl}/media/setting/${marketplaceLogo}`}
                        className="jss1557 mt-3"
                        height="29"
                        alt="marketplace logo"
                      />
                    )}
                  </div>

                  <Form
                    initialValues={initialValues}
                    onSubmit={values => {
                      values.password = values.password
                        ? values.password
                        : null;
                      values.confirmPassword = values.confirmPassword
                        ? values.confirmPassword
                        : null;

                      const passwordError = values.password
                        ? validateStrongPassword(values.password)
                        : "";

                      if (passwordError) {
                        this.setState({
                          errorMessage: passwordError
                        });
                        return false;
                      }
                      if (
                        values.password &&
                        values.confirmPassword &&
                        values.password !== values.confirmPassword
                      ) {
                        this.setState({
                          errorMessage: "Confirm password did not match"
                        });
                        return false;
                      } else if (!values.password || !values.confirmPassword) {
                        this.setState({ errorMessage: "" });
                      }

                      this._userSetPassword(values);
                      return false;
                    }}
                  >
                    <CardBody className="pb-0">
                      <div className="text-center pb-2">
                        <small className="font-weight-bold">
                          Create a new password below.
                        </small>
                      </div>
                      {renderSetPasswordForm}
                    </CardBody>
                    <div className="pr-2">
                      <Button
                        type="submit"
                        color="#E0674C"
                        hoverColor="#ff876c"
                        label="Submit"
                        className="rounded-0 text-white w-100 font-weight-bold"
                      />
                    </div>
                  </Form>
                </Card>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default setPassword;
