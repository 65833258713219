import React, { useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Configs
import { endpoints } from "../../../configs";
import { apiClient } from "../../../apiClient";

// Custom Toggle
import useToggle from "../../../components/customhooks/useToggle";

// Components
import Form from "../../../components/base/Form";
import TextArea from "../../../components/base/TextArea";
import toast from "../../../components/base/Toast";
import Email from "../../../components/base/Email";
import Text from "../../../components/base/Text";
import Phone from "../../../components/base/Phone";
import CancelButton from "../../../components/base/CancelButton";
import SaveButton from "../../../components/base/SaveButton";

// Helper
import {
  toString,
  removeMaskedPhoneNumber,
  getCookie
} from "../../../lib/helper";
import { LEAD_SOURCE_PLAYBOOK_INQUIRY } from "../../../lead/Constants";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../../../lib/cookie";

// API Configs
import { DEFAULT_API_KEY } from "../../../configs";
import { isBadRequest } from "../../../common/http";

const PlaybookInquiryModals = ({ isOpen, toggle, playbookId }) => {
  const [isClicked, setIsClicked] = useState(false);
  const handleSubmit = values => {
    setIsClicked(true);
    // If dont have session send default API key
    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    apiClient
      .post(`${endpoints().lead}`, values)
      .then(response => {
        toggle();
        toast.success(response.data.message);
        setIsClicked(false);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  // To Array
  const _toArray = data => {
    let values = { ...data };

    values.playbookId = playbookId;
    values.name = toString(values.name);
    values.email = toString(values.email);
    values.phone = toString(
      values.phone && removeMaskedPhoneNumber(values.phone)
    );
    values.companyName = toString(values.companyName);
    values.notes = toString(values.notes);
    values.source = toString(LEAD_SOURCE_PLAYBOOK_INQUIRY);

    return values;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <Form
        initialValues={{
          name: "",
          email: "",
          phone: "",
          companyName: "",
          notes: ""
        }}
        onSubmit={values => {
          handleSubmit(_toArray(values));
        }}
      >
        <ModalHeader className="justify-content-center" toggle={toggle}>
          <div className={["d-flex", "flex-column"].join(" ")}>
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
              Playbook Inquiry
            </h4>
          </div>
        </ModalHeader>
        <ModalBody className={["mb-4", "px-0"].join(" ")}>
          <div className={["text-center", "modal-body-subtitle"].join(" ")}>
            <p>
              Enter your contact information below and a representative from
              this company will be in touch with you shortly.
            </p>
          </div>
          <div className="form-wrapper justify-content-center d-flex flex-column">
            <div className="field-wrapper">
              <Text
                name="name"
                label="Your Name"
                placeholder="Enter your name"
                required={true}
                error=""
              />
            </div>
            <div className="field-wrapper">
              <Email
                name="email"
                label="Your Email Address"
                placeholder="Enter your email address"
                required={true}
                error=""
              />
            </div>
            <div className="field-wrapper">
              <Phone
                name="phone"
                label="Your Phone Number"
                placeholder="Enter phone number"
                required={true}
                error=""
              />
            </div>
            <div className="field-wrapper">
              <Text
                name="companyName"
                label="Company Name"
                placeholder="Enter company name"
                required={true}
                error=""
              />
            </div>
            <div className="field-wrapper">
              <TextArea
                name="notes"
                label="Description of your need or project"
                placeholder="Enter project description"
                required={true}
                error="Please enter project description"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={toggle} />
            <SaveButton isClicked={isClicked} />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const PlaybookInquiryModal = ({ playbookId }) => {
  const [modal, setModal] = useToggle();

  return (
    <>
      <Button
        color={"primary"}
        onClick={() => setModal(true)}
        onContextMenu={e => e.preventDefault()}
        className="mt-2"
      >
        Playbook Inquiry
      </Button>

      <PlaybookInquiryModals
        isOpen={modal}
        toggle={setModal}
        playbookId={playbookId}
      />
    </>
  );
};

export default PlaybookInquiryModal;
