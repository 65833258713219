import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import ManagerButton from "../../../components/base/Button";
import UserDropdown from "../../projects/inner-components/UserDropdown";
import SimpleUserCard from "../../../components/SimpleUserCard";
import Spinner from "../../../components/base/Spinner";
import CancelButton from "../../../components/base/CancelButton";

// Helper
import { getUserId, isSuperAdmin, isCompanyAdmin } from "../../../lib/helper";
import NoRecordsFound from "../../../components/base/NoRecordsFound";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";
import { useSelector, useDispatch } from "react-redux";

const PartnerManager = ({
  userList,
  managerUser,
  selectedManagerId,
  changeManager,
  isPartnerManagerLoading
}) => {
  // useState
  const [isOpen, setIsOpen] = useState(false);
  const [currentManagerUserId, setCurrentManagerUserId] = useState(
    selectedManagerId || ""
  );
  const [disableAssignButton, setDisableAssignButton] = useState(true);

  const currentUser = useSelector(state => state.user);

  let selectedPartnerManager;

  // Toggle Assign Partner Manager
  const toggle = () => {
    setCurrentManagerUserId(selectedManagerId || "");
    setIsOpen(!isOpen);
    setDisableAssignButton(true);
  };

  // Get Selected Partner Manager
  selectedPartnerManager = userList.find(x => x.id === currentManagerUserId);

  if (!selectedPartnerManager) {
    selectedPartnerManager = {};
  }

  // handele selected manager
  const handleUpdateManager = userId => {
    setCurrentManagerUserId(userId);
    setDisableAssignButton(false);
  };

  // handele selected manager
  const handleChangeManager = () => {
    changeManager(currentManagerUserId);
    toggle();
  };

  // handele delete manager
  const handleDeleteManager = () => {
    changeManager(null);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={["edit-task-modal"].join(" ")}
      >
        <ModalHeader toggle={toggle}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Assign {Terminology.get(SystemName.PARTNER_MANAGER)}
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4", "ml-5"].join(" ")}>
          <div className="form-wrapper justify-content-center d-flex">
            <div className="col-10 field-wrapper">
              <label> {Terminology.get(SystemName.PARTNER_MANAGER)}</label>
              <UserDropdown
                assignee={selectedPartnerManager}
                placeholderText={`Select ${Terminology.get(
                  SystemName.PARTNER_MANAGER
                )}`}
                userlist={userList}
                updateDefaultManager={handleUpdateManager}
                currentUserId={getUserId()}
                partnerManagerId={"partner-manager"}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={toggle} />
            <button
              className="btn btn-secondary"
              onClick={handleChangeManager}
              disabled={disableAssignButton}
            >
              Assign {Terminology.get(SystemName.PARTNER_MANAGER)}
            </button>
          </div>
        </ModalFooter>
      </Modal>
      {/* <div className="mt-4">
        <ToastContainerComponent
          containerId="partner-manager-toast"
          newestOnTop={true}
        />
      </div> */}

      <>
        <div className="form-wrapper">
          <div>
            {!selectedManagerId &&
            (isSuperAdmin(currentUser && currentUser.roleId) ||
              isCompanyAdmin(currentUser && currentUser.roleId)) ? (
              <div>
                <h5 className="font-weight-bold">
                  {Terminology.get(SystemName.PARTNER_MANAGER)}
                </h5>
                {/* Assign a Partner Manager Button */}
                {isPartnerManagerLoading ? (
                  <div className="field-wrapper mt-3">
                    <Spinner />
                  </div>
                ) : (
                  <ManagerButton
                    label={`Assign ${Terminology.get(
                      SystemName.PARTNER_MANAGER
                    )} `}
                    className="btn btn-secondary mt-3"
                    onClick={toggle}
                    fontSize={{ fontSize: "14px" }}
                  />
                )}
              </div>
            ) : (
              <div>
                {selectedManagerId && managerUser ? (
                  <div>
                    <h5 className="font-weight-bold">
                      {Terminology.get(SystemName.PARTNER_MANAGER)}
                    </h5>
                    {/*  */}
                    {isPartnerManagerLoading ? (
                      <div className="field-wrapper mt-4">
                        <Spinner />
                      </div>
                    ) : (
                      <SimpleUserCard
                        userDetails={managerUser}
                        key={managerUser.id}
                        currentUserId={getUserId()}
                        showEditOptions={
                          isSuperAdmin(currentUser && currentUser.roleId) ||
                          isCompanyAdmin(currentUser && currentUser.roleId)
                        }
                        handleEdit={toggle}
                        handleDelete={handleDeleteManager}
                      />
                    )}
                  </div>
                ) : (
                  <NoRecordsFound
                    showMessage={true}
                    message="No Manager Assigned Yet"
                    position="top"
                    hideCard={true}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </>
    </>
  );
};

export default PartnerManager;
