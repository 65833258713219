import React from "react";
import { TabContent, TabPane } from "reactstrap";

// Constants
import {
  TAB_PIPELINE,
  TAB_ACTIVITIES,
  getTabNameFromUrl,
  TAB_PARTNER_TIER_OR_QUOTA_REPORT,
  TAB_REFERRAL_LINK_TRACKING_ACTIVITY_REPORT,
  TAB_REFERRAL_LINK_TRACKING_REPORT,
  TAB_PARTNER_ACTIVITIES,
  TAB_CLOSED_WON_REFERRAL_REPORT,
  TAB_OVERALL_SUMMARY,
  TAB_REFERRAL_REPORT,
  TAB_PARTNER_REPORT,
  TAB_PARTNER_USER_REPORT
} from "../../../reports/Constants";

// Tab Sections
import PipelineTab from "../tabs/PipelineTab/";
import ActivitiesTab from "../tabs/ActivitiesTab/";
import PartnerTierTab from "../tabs/PartnerTierTab/";
import { getStringToUrl } from "../../../lib/helper";
import PartnerLinkTracking from "../tabs/PartnerLinkTracking";
import PartnerLinkTrackingStatistics from "../tabs/PartnerLinkTrackingStatistics";
import PartnerActivityTab from "../tabs/PartnerActivityTab";
import ReferralReportTab from "../tabs/ReferralReportTab";
import OverallSummary from "../tabs/OverAllSummaryTab/index";
import ReferralReport from "../ReferralReport/index";
import PartnerReport from "../partnerReport/index";
import PartnerUserReport from "../partnerUserReport";

const TabContentSection = props => {
  const {
    activeTab,
    activeSection,
    toggleTabs,
    history,
    settings,
    match
  } = props;
  return (
    <TabContent activeTab={activeTab}>
      <h5 className=" mt-3 mb-4 text-left font-weight-bold">
        {activeSection
          ? getTabNameFromUrl(activeSection)
          : getTabNameFromUrl(activeTab)}
      </h5>

      <TabPane tabId={getStringToUrl(TAB_OVERALL_SUMMARY)}>
        <div className="tab-content-wrapper">
          <OverallSummary
            toggleTabs={toggleTabs}
            history={history}
            settings={settings}
            match={match}
            loggedInUser={props.loggedInUser}
          />
        </div>
      </TabPane>
      <TabPane tabId={getStringToUrl(TAB_REFERRAL_REPORT)}>
        <div className="tab-content-wrapper">
          <ReferralReport
            toggleTabs={toggleTabs}
            history={history}
            settings={settings}
            match={match}
            loggedInUser={props.loggedInUser}
          />
        </div>
      </TabPane>
      <TabPane tabId={getStringToUrl(TAB_PARTNER_REPORT)}>
        <div className="tab-content-wrapper">
          <PartnerReport
            toggleTabs={toggleTabs}
            history={history}
            settings={settings}
            match={match}
            loggedInUser={props.loggedInUser}
          />
        </div>
      </TabPane>
      <TabPane tabId={getStringToUrl(TAB_PARTNER_USER_REPORT)}>
        <div className="tab-content-wrapper">
          <PartnerUserReport
            toggleTabs={toggleTabs}
            history={history}
            settings={settings}
            match={match}
            loggedInUser={props.loggedInUser}
          />
        </div>
      </TabPane>
      {/* Pipeline Tab Start */}
      <TabPane tabId={getStringToUrl(TAB_PIPELINE)}>
        <div className="tab-content-wrapper">
          <PipelineTab
            toggleTabs={toggleTabs}
            history={history}
            settings={settings}
            match={match}
          />
        </div>
      </TabPane>
      {/* Pipeline Tab End */}

      {/* Activities Tab Start */}
      <TabPane tabId={getStringToUrl(TAB_ACTIVITIES)}>
        <div className="tab-content-wrapper">
          <ActivitiesTab
            toggleTabs={toggleTabs}
            history={history}
            settings={settings}
            match={match}
          />
        </div>
      </TabPane>
      {/* Activities Settings End */}

      {activeTab == getStringToUrl(TAB_PARTNER_ACTIVITIES) && (
        <>
          {/* Activities Tab Start */}
          <TabPane tabId={getStringToUrl(TAB_PARTNER_ACTIVITIES)}>
            <div className="tab-content-wrapper">
              <PartnerActivityTab
                toggleTabs={toggleTabs}
                history={history}
                settings={settings}
                match={match}
              />
            </div>
          </TabPane>
        </>
      )}
      {/* Activities Settings End */}

      {/* Partner Tier Tab Start */}
      <TabPane tabId={getStringToUrl(TAB_PARTNER_TIER_OR_QUOTA_REPORT)}>
        <div className="tab-content-wrapper">
          <PartnerTierTab
            activeTab={activeTab}
            toggleTabs={toggleTabs}
            history={history}
            settings={settings}
            match={match}
          />
        </div>
      </TabPane>
      {/* Partner Tier Settings End */}

      {/* Partner Link Tracking Start */}
      <TabPane
        tabId={getStringToUrl(TAB_REFERRAL_LINK_TRACKING_ACTIVITY_REPORT)}
      >
        <div className="tab-content-wrapper">
          <PartnerLinkTracking
            activeTab={activeTab}
            toggleTabs={toggleTabs}
            history={history}
            settings={settings}
            match={match}
          />
        </div>
      </TabPane>

      {/* Partner Link Tracking Start */}
      <TabPane tabId={getStringToUrl(TAB_REFERRAL_LINK_TRACKING_REPORT)}>
        <div className="tab-content-wrapper">
          <PartnerLinkTrackingStatistics
            activeTab={activeTab}
            toggleTabs={toggleTabs}
            history={history}
            settings={settings}
            match={match}
          />
        </div>
      </TabPane>
      {/* Partner Link Tracking End */}

      {/* Closed Won Referral Report */}
      <TabPane tabId={getStringToUrl(TAB_CLOSED_WON_REFERRAL_REPORT)}>
        <div className="tab-content-wrapper">
          <ReferralReportTab
            activeTab={activeTab}
            toggleTabs={toggleTabs}
            history={history}
            settings={settings}
            match={match}
          />
        </div>
      </TabPane>
      {/* Partner Link Tracking End */}
    </TabContent>
  );
};

export default TabContentSection;
