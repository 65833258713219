import React from "react";

// Components
import Text from "../../../components/base/Text";
import Currency from "../../../components/base/Currency";

// Assets
import { PlusIcon, TrashIcon } from "../../../assets/img/icons";

class PlaybookExtras extends React.Component {
  constructor(props) {
    super(props);
    const { extras } = this.props;
    this.state = {
      values:
        extras && extras.length
          ? [...extras]
          : [{ id: 0, option: null, price: null }],
      extras: {}
    };
  }

  componentDidMount() {
    if (this.props.extras) {
      this.props.extrasValue(this.state.values);
    }
  }

  // Add extras field
  addField = event => {
    event.preventDefault();
    // Generate added extras field key
    const id = Math.floor(1000 + Math.random() * 9000);
    const values = [...this.state.values];

    values.push({ id, option: null, price: null });
    this.setState({ values });
  };

  // Handle remove extras
  handleRemove = i => {
    const values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });

    this.props.extrasValue(values);
  };

  // Handle change extras
  handleChange = (i, event) => {
    const values = [...this.state.values];
    // Map price and extras values
    event.target.name.includes("price")
      ? (values[i].price = event.target.value)
      : (values[i].option = event.target.value);
    this.setState({ values });

    this.props.extrasValue(values);
  };

  render() {
    const { values } = this.state;

    return (
      <>
        {values.map((el, i) => (
          <div className="grouped-fields" key={el.id}>
            <div className="field-wrapper">
              <Text
                name={`extra-${el.id}`}
                label={`Extra ${i + 1}`}
                defaultValue={el.option || ""}
                placeholder={`Enter Extra ${i + 1}`}
                onChange={e => this.handleChange(i, e)}
                error=""
                required={values[i].price && !values[i].option && true}
              />
            </div>
            <div className="field-wrapper">
              <Currency
                className="price"
                name={`extra_price-${el.id}`}
                label={`Price`}
                defaultValue={el.price || ""}
                placeholder={`Enter Price`}
                onChange={e => this.handleChange(i, e)}
                error=""
                required={values[i].option && !values[i].price && true}
                minValue={0}
              />
              <div
                className="mr-n5"
                style={{ marginTop: "40px", cursor: "pointer" }}
                onClick={() => this.handleRemove(i)}
              >
                {values.length > 1 || values[0].option || values[0].price ? (
                  <TrashIcon />
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        ))}
        <div className="btn-wrapper">
          <button
            onClick={this.addField}
            className="btn btn-link d-inline-flex px-0 text-underline"
          >
            <PlusIcon /> Add Another Extra
          </button>
        </div>
      </>
    );
  }
}

export default PlaybookExtras;
