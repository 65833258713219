import React from "react";
import {
  LinkedinIcon,
  OfficeIcon,
  SalesforceIcon,
  Auth0Icon
} from "../../assets/img/icons";
import jsforce from "jsforce";
import GoogleLogin from "react-google-login";
import LinkedInLogin from "react-linkedin-login-oauth2";
import MicrosoftLogin from "react-microsoft-login";
import { useAuth0 } from "@auth0/auth0-react";
import useToggle from "../customhooks/useToggle";
import RegisterWithEmail from "../RegisterWithEmail";
import BasicLoginForm from "../../views/login/BasicLoginForm";

export const SalesforceLogin = (
  redirect,
  salesforceLoginClientId,
  salesforceLoginRedirectUrl,
  callback
) => {
  jsforce.browser.init({
    clientId: salesforceLoginClientId,
    redirectUri: salesforceLoginRedirectUrl
  });

  jsforce.browser.login();
  jsforce.browser.on("connect", function(conn) {
    return callback(conn.accessToken);
  });
};

export const SalesforceButton = ({
  redirect,
  redirectUrl,
  signUpWithSalesforce,
  actions,
  salesforceLoginClientId,
  salesforceLoginRedirectUrl,
  id
}) => {
  return (
    <div className="signup-button">
      <a
        id={id}
        onClick={() => {
          SalesforceLogin(
            redirect,
            salesforceLoginClientId,
            salesforceLoginRedirectUrl,
            salesforceAuthToken => {
              signUpWithSalesforce(
                salesforceAuthToken,
                () => {
                  if (redirectUrl) {
                    window.location.replace(redirectUrl);
                  } else if (redirect) {
                    redirect();
                  }
                },
                actions
              );
            }
          );
        }}
        className="button-salesforce cursor-pointer"
      >
        <span className="icon-wrapper">
          <SalesforceIcon />
        </span>
        Continue with Salesforce
      </a>
    </div>
  );
};

export const GoogleButton = ({
  redirect,
  redirectUrl,
  signUpWithGoogle,
  actions,
  googleClientId,
  id
}) => {
  return (
    <GoogleLogin
      id={id}
      clientId={googleClientId}
      buttonText={<span>Continue with Google</span>}
      onSuccess={response => {
        signUpWithGoogle(
          response.getAuthResponse().id_token,
          () => {
            if (redirectUrl) {
              window.location.replace(redirectUrl);
            } else if (redirect) {
              redirect();
            }
          },
          actions
        );
      }}
      className="google-login mb-3"
      onFailure={err => console.log(err)}
    />
  );
};

export const OfficeButton = ({
  redirect,
  redirectUrl,
  signUpWithOffice365,
  actions,
  office365ClientId,
  office365RedirectUri,
  id
}) => {
  return (
    <div className="signup-button">
      <div>
        <MicrosoftLogin
          id={id}
          clientId={office365ClientId}
          className="office-login"
          children={
            <div className="signup-button">
              <a href="#" className="button-office">
                <span className="icon-wrapper">
                  <OfficeIcon />
                </span>
                Continue with Office
              </a>
            </div>
          }
          redirectUri={office365RedirectUri}
          authCallback={(err, data) => {
            if (err) {
              return console.log(err);
            }

            signUpWithOffice365(
              data.authResponseWithAccessToken.accessToken,
              () => {
                if (redirectUrl) {
                  window.location.replace(redirectUrl);
                } else if (redirect) {
                  redirect();
                }
              },
              actions
            );
          }}
        />
      </div>
    </div>
  );
};

export const LinkedInButton = ({
  redirect,
  redirectUrl,
  signUpWithLinkedin,
  actions,
  linkedInClientId,
  linkedInRedirectUri,
  id
}) => {
  return (
    <LinkedInLogin
      clientId={linkedInClientId}
      className="linkedin-login mb-3"
      id={id}
      children={
        <div id={id} className="signup-button">
          <a href="#" className="button-linkedin">
            <span className="icon-wrapper">
              <LinkedinIcon />
            </span>
            Continue with Linkedin
          </a>
        </div>
      }
      scope="r_liteprofile,r_emailaddress"
      onFailure={err => console.log(JSON.stringify(err))}
      onSuccess={data => {
        signUpWithLinkedin(
          data.code,
          () => {
            if (redirectUrl) {
              window.location.replace(redirectUrl);
            } else if (redirect) {
              redirect();
            }
          },
          actions
        );
      }}
      redirectUri={linkedInRedirectUri}
      redirectPath={linkedInRedirectUri}
    />
  );
};

export const Auth0Button = ({ id }) => {
  const { loginWithRedirect } = useAuth0();
  return (
    <div className="signup-button">
      <a
        id={id}
        onClick={() => {
          loginWithRedirect();
        }}
        className="button-salesforce cursor-pointer"
      >
        <span className="icon-wrapper">
          <Auth0Icon />
        </span>
        Continue with Auth0
      </a>
    </div>
  );
};

export const LoginForm = ({
  redirect,
  eventKey,
  settings,
  secondaryPortalDetails,
  id
}) => {
  return (
    <BasicLoginForm
      id={id}
      redirect={redirect}
      eventKey={eventKey}
      settings={settings}
      secondaryPortalDetails={secondaryPortalDetails}
    />
  );
};

export const RegisterButton = props => {
  const [modal, setModal] = useToggle();
  const { next } = props;

  return (
    <>
      <div className="signup-button">
        <a href="#" className="btn-link" onClick={setModal}>
          Register with my Email Address
        </a>
      </div>
      <RegisterWithEmail next={next} isOpen={modal} toggle={setModal} />
    </>
  );
};

const AuthButtons = props => {
  const { children } = props;

  return (
    <>
      <div className="signup-buttons">{children}</div>
    </>
  );
};

export default AuthButtons;
