import React from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import Select from "../../../../components/base/Select";
import Text from "../../../../components/base/Text";
import SaveButton from "../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_EXPERT_DEFAULT_TITLE,
  FIND_AN_EXPERT_FORM,
  SETTINGS_EXPERT_SIGNUP_WELCOME_MESSAGE,
  SETTINGS_PORTAL_TALENT_NAME
} from "../../../../setting/Constants";
import {
  SIMPLY_FORM,
  WIZARD_FORM
} from "../../../../findAnExpertLayout/Constants";

// Helper
import { toString } from "../../../../lib/helper";

const ExpertTab = props => {
  const { adminSettings, saveSettings } = props;

  const {
    expert_default_title,
    find_an_expert_Form,
    expert_signup_welcome_message,
    marketplace_talent_name
  } = adminSettings;

  // General Settings Expert Section Initial Values
  const generalSettingsExpertTabInitialValues = {
    expert_default_title,
    find_an_expert_Form,
    expert_signup_welcome_message,
    marketplace_talent_name
  };

  // Find an Expert Form Types
  const FindAnExpertItems = () => [
    {
      label: SIMPLY_FORM,
      value: SIMPLY_FORM
    },
    {
      label: WIZARD_FORM,
      value: WIZARD_FORM
    }
  ];

  // General Settings Expert Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Default Expert Title
    if (values.expert_default_title !== undefined) {
      data.append(
        "expert_default_title",
        values.expert_default_title ? values.expert_default_title : ""
      );
    }
    if (values.marketplace_talent_name !== undefined) {
      data.append(
        "marketplace_talent_name",
        values.marketplace_talent_name ? values.marketplace_talent_name : ""
      );
    }
    if (values.expert_signup_welcome_message !== undefined) {
      data.append(
        "expert_signup_welcome_message",
        values.expert_signup_welcome_message
          ? values.expert_signup_welcome_message
          : ""
      );
    }

    // Find an Expert Form type
    if (values.find_an_expert_Form !== undefined) {
      data.append(
        "find_an_expert_Form",
        toString(
          values.find_an_expert_Form &&
            typeof values.find_an_expert_Form === "object"
            ? values.find_an_expert_Form.value
            : values.find_an_expert_Form
        )
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={generalSettingsExpertTabInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          {/* Default expert title  */}
          <h5 className="font-weight-bold">Default Expert Title</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_EXPERT_DEFAULT_TITLE}
              placeholder="Enter Default Expert Title"
            />
          </div>
          {/* Find an Expert */}
          <h5 className="font-weight-bold">Find an Expert Form</h5>
          <div className="field-wrapper">
            <Select
              name={FIND_AN_EXPERT_FORM}
              placeholder="Select a Find and Expert"
              options={FindAnExpertItems()}
              defaultValue={FindAnExpertItems().find(
                type => type.value === find_an_expert_Form
              )}
              isSearchable={true}
            />
          </div>
        </div>
      </DefaultContent>
      <DefaultContent>
        <h5 className="font-weight-bold">Welcome Message for Experts</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_EXPERT_SIGNUP_WELCOME_MESSAGE}
            label="This will be the welcome headline within Expert Signup wizard."
            placeholder="Type something..."
          />
        </div>
      </DefaultContent>
      <DefaultContent>
        {/* Talent Name */}
        <h5 className="font-weight-bold">Talent Name</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_PORTAL_TALENT_NAME}
            placeholder="Enter Talent Name"
          />
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default ExpertTab;
