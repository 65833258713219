import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  TabContent
} from "reactstrap";

// Component

// import MoreDropdown from "../../../../components/base/MoreDropdown";
// import AddButton from "../../../../components/base/AddButton";
import Form from "../../../../components/base/Form";
import Text from "../../../../components/base/Text";
import SaveButton from "../../../../components/base/SaveButton";

// Tabs
import Profile from "./profile";
import PartnerTypeTab from "./PartnerTypes/index";
import PartnerTier from "../PartnerTiersTab/index";

// Helper
import { toString } from "../../../../lib/helper";

// Tags
import {
  deleteTagType,
  addTagType,
  updateTagType
} from "../../../../actions/tagType";
import {
  TYPE_USER_DEFINED,
  TAG_TYPE_CATEGORY_PARTNER,
  TAG_TYPE_CATEGORY_MARKETPLACE,
  TYPE_SYSTEM
} from "../../../../tagType/Constants";
import {
  PARTNER_PROFILE_SUB_TAB,
  REFERRAL_PARTNER_TIERS_SUB_TAB,
  PARTNERS_SUB_TAB,
  TAB_PARTNER,
  PARTNER_SETTINGS_CONFIG_TAB
} from "../../../../setting/Constants";
// import {
//   TAG_PERMISSION_INTERNAL_ONLY_VALUE,
//   TAG_PERMISSION_SELECT_AND_ADD_VALUE,
//   TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE,
//   TAG_PERMISSION_SELECT_AND_ADD_TABLE_TEXT,
//   TAG_PERMISSION_SELECT_AND_NOT_ADD_TABLE_TEXT,
//   TAG_PERMISSION_INTERNAL_ONLY_TABLE_TEXT
// } from "../../../../tag/Constants";

import DeleteModal from "../../../../components/base/DeleteModal";

import classnames from "classnames";
import Setting from "./PartnerTypes/settings";

const Partner = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [deleteTagTypeModal, setDeleteTagTypeModal] = useState(false);
  const [currentTagtype, setCurrentTagtype] = useState(false);
  const {
    activeTab,
    activeSubTab,
    activeSection,
    adminSettings,
    saveSettings,
    toggleTabs,
    history
  } = props;
  /**
   * Create tag
   *
   * @param data
   */
  const tagTypeCreate = data => {
    data.category = category.value || TAG_TYPE_CATEGORY_MARKETPLACE;
    props.actions.addTagType(data, { category: TAG_TYPE_CATEGORY_MARKETPLACE });
  };

  /**
   * Update tag type
   *
   * @param data
   * @param id
   */
  const tagTypeUpdate = data => {
    props.actions.updateTagType(id, data, {
      category: TAG_TYPE_CATEGORY_MARKETPLACE
    });
  };

  /**
   * Delete tag type
   *
   * @param id
   */
  const handleDelete = id => {
    props.actions.deleteTagType(id, {
      category: TAG_TYPE_CATEGORY_MARKETPLACE
    });
  };

  const _toggle = tagTypeId => {
    setId(tagTypeId || 0);
    setIsOpen(!isOpen);
  };

  // const headerButton = () => (
  //   <div className="btn-wrapper">
  //     <AddButton
  //       label="Add New"
  //       className="pull-right btn btn-secondary"
  //       onClick={() => {
  //         setName("");
  //         setCategory("");
  //         setType("");
  //         _toggle();
  //       }}
  //     />
  //     {tagTypeModal()}
  //   </div>
  // );

  // const handleChange = Category => {
  //   const selectedCategory = Category.values.category;
  //   setCategory(selectedCategory);
  // };

  // Render the tag type modal
  const tagTypeModal = () => (
    <Modal
      isOpen={isOpen}
      toggle={_toggle}
      className="landing-create-popup w-100"
      backdrop="static"
    >
      <ModalHeader
        toggle={_toggle}
        cssModule={{ "modal-title": "w-100 text-center" }}
      >
        <p>{id ? "Edit Tag Type" : "Create Tag Type"}</p>
      </ModalHeader>
      <Form
        initialValues={{
          name: name || ""
        }}
        onSubmit={values => {
          values.name = toString(values.name);
          values.type = type ? toString(type) : toString(TYPE_USER_DEFINED);
          values.category = TAG_TYPE_CATEGORY_MARKETPLACE;
          id ? tagTypeUpdate(values) : tagTypeCreate(values);
          setTimeout(() => {
            setIsOpen(!isOpen);
          }, 100);
        }}
      >
        <ModalBody className="ml-3 mr-3 mt-2 mb-3">
          <div>
            <Text
              name="name"
              label="Tag Type"
              placeholder="Enter tag type"
              required={true}
              error=""
              disabled={type === TYPE_SYSTEM ? true : false}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="container-fluid">
            <div className="col-sm-12 text-center">
              <SaveButton type="submit" label="">
                {id ? "Save" : "Create Tag Type"}
              </SaveButton>
            </div>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
  return (
    <>
      <DeleteModal
        isOpen={deleteTagTypeModal}
        toggle={() => {
          setDeleteTagTypeModal(prevState => !prevState);
        }}
        title="Delete Partner Profile"
        label={currentTagtype.name}
        deleteFunction={() => {
          handleDelete(currentTagtype.id);
        }}
      />
      {activeTab === TAB_PARTNER && !activeSection && (
        <Nav tabs className="admin-tabs">
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === PARTNER_PROFILE_SUB_TAB
              })}
              onClick={() => {
                toggleTabs(TAB_PARTNER, PARTNER_PROFILE_SUB_TAB);
              }}
            >
              {PARTNER_PROFILE_SUB_TAB}
            </NavLink>
          </NavItem>
          {/* partner types */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === PARTNERS_SUB_TAB
              })}
              onClick={() => {
                toggleTabs(TAB_PARTNER, PARTNERS_SUB_TAB);
              }}
            >
              {PARTNERS_SUB_TAB}
            </NavLink>
          </NavItem>
          {/* partner tiers */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === REFERRAL_PARTNER_TIERS_SUB_TAB
              })}
              onClick={() => {
                toggleTabs(TAB_PARTNER, REFERRAL_PARTNER_TIERS_SUB_TAB);
              }}
            >
              {REFERRAL_PARTNER_TIERS_SUB_TAB}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === PARTNER_SETTINGS_CONFIG_TAB
              })}
              onClick={() => {
                toggleTabs(TAB_PARTNER, PARTNER_SETTINGS_CONFIG_TAB);
              }}
            >
              {PARTNER_SETTINGS_CONFIG_TAB}
            </NavLink>
          </NavItem>
        </Nav>
      )}
      <TabContent activeTab={activeSubTab || activeSection}>
        {/* Profile */}
        <TabPane tabId={PARTNER_PROFILE_SUB_TAB} className="w-100">
          <Profile
            adminSettings={adminSettings}
            activeTab={activeTab}
            activeSubTab={activeSubTab}
            activeSection={activeSection}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
        {/* partner types */}
        <TabPane tabId={PARTNERS_SUB_TAB} className="w-100">
          <PartnerTypeTab
            activeTab={activeTab}
            activeSubTab={activeSubTab}
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
        {/* Partner tiers */}
        <TabPane tabId={REFERRAL_PARTNER_TIERS_SUB_TAB} className="w-100">
          <PartnerTier
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
        <TabPane tabId={PARTNER_SETTINGS_CONFIG_TAB} className="w-100">
          <Setting
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { deleteTagType, addTagType, updateTagType },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(Partner);
