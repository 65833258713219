import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";

// Helper
import {
  getKeyValueByObject,
  getFormattedPrice,
  getCookie
} from "../../lib/helper";

// Constants
import { SETTINGS_ENABLE_PARTNERS } from "../../setting/Constants";

// Components
import ListViewCard from "../../components/base/ListViewCard";
import AvatarCard from "../../components/base/AvatarCard";

// Actions
import { fetchAdminDashboardCount } from "../../actions/dashboard";

// Cookies
import { COOKIE_SHOW_ASSIGNED_PARTNERS } from "../../lib/cookie";

const List = styled.div`
  padding: 15px;
  border-bottom: 1px solid #e9e8ee;
`;

const TopPartnersDealClosed = props => {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(fetchAdminDashboardCount());
  }, []);

  const adminDashboard = dashboard.adminDashboard;
  const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);

  let topPartnerClosedDeals;

  if (checked_status) {
    topPartnerClosedDeals =
      adminDashboard && adminDashboard.topAssignedPartnerClosedDeals;
  } else {
    topPartnerClosedDeals =
      adminDashboard && adminDashboard.topPartnerClosedDeals;
  }
  const enablePartners = props.settings
    ? getKeyValueByObject(props.settings, SETTINGS_ENABLE_PARTNERS)
    : "";

  return (
    <>
      {topPartnerClosedDeals && topPartnerClosedDeals.length > 0 && (
        <>
          {/* Top Partners Deal Closed */}
          {enablePartners === "true" && (
            <ListViewCard label="Top Partners by $ Closed Deals">
              {topPartnerClosedDeals.map(closedPartnerDeal => (
                <List key={closedPartnerDeal.partner.id}>
                  <div className="d-flex align-items-center justify-content-between">
                    <AvatarCard
                      firstName={closedPartnerDeal.partner.company_name}
                      url={closedPartnerDeal.partner.avatar}
                      size="xs"
                    />
                    <span className="mr-3" style={{ fontSize: "14px" }}>
                      {getFormattedPrice(closedPartnerDeal.total_amount)}
                    </span>
                  </div>
                </List>
              ))}
            </ListViewCard>
          )}
        </>
      )}
    </>
  );
};

export default TopPartnersDealClosed;
