import React from "react";
import { Badge } from "reactstrap";
import isEqual from "react-fast-compare";

// Assets
import { EditIconAlt, TrashIcon } from "../../../assets/img/icons";

// Constants

const DeleteTaskButton = React.memo(function DeleteTaskButton(props) {
  let { task, setCurrentTask, setDeleteTaskModal } = props;

  return (
    <>
      <button
        type="button"
        className={["delete-btn", "btn-link", "btn"].join(" ")}
        onClick={() => {
          setCurrentTask(task);
          setDeleteTaskModal(true);
        }}
      >
        <TrashIcon />
      </button>
    </>
  );
}, isEqual);

const Task = props => {
  let { task, setEditTaskModal, setCurrentTask, setDeleteTaskModal } = props;
  let { name, category } = task;

  return (
    <div className=" task pr-4 pt-2 pb-1" style={{ minHeight: "70px" }}>
      <div className="task-left">
        <p className="pr-3 pt-3" style={{ cursor: "move", width: "55px" }}>
          <i className="fa fa-arrows"></i>
        </p>
        {/*Show Checkbox Based On Status*/}
        <div className="name-comments mr-2 ">
          {/*Show Task Name*/}
          <span className="text-decoration-none">{name}</span>
          {category === "System" && (
            <Badge color="secondary" pill>
              Default
            </Badge>
          )}
        </div>
      </div>

      <div className="task-right">
        {/*Show Task Actions For All Tasks Based on the Condition*/}
        <div className="task-actions">
          <>
            <span
              className={"mt-1 d-flex justify-content-between"}
              style={{
                visibility: category === "System" ? "hidden" : "show"
              }}
            >
              <button
                type="button"
                className={["edit-btn", "btn-link", "btn"].join(" ")}
                onClick={() => {
                  setCurrentTask(task);
                  setEditTaskModal(true);
                }}
              >
                <EditIconAlt />
              </button>

              {/* Trigger Edit Task modal */}
              <DeleteTaskButton
                task={task}
                setCurrentTask={setCurrentTask}
                setDeleteTaskModal={setDeleteTaskModal}
              />
            </span>
          </>
        </div>
      </div>
    </div>
  );
};

export default Task;
