import { apiClient } from "../apiClient";
import toast from "../components/base/Toast";
import { endpoints } from "../configs";
import { fetchList } from "./table";

import {
  REQUEST_UPDATE_COMPANY,
  RECEIVE_UPDATE_COMPANY,
  COMPANY_UPDATE_ERROR
} from "../actionType/Constants";
import { isBadRequest } from "../common/http";

/**
 * Request for updating Company
 */
export function requestUpdateCompany() {
  return {
    type: REQUEST_UPDATE_COMPANY
  };
}

/**
 * Receive for updating Company
 */
export function receiveUpdateCompany() {
  return {
    type: RECEIVE_UPDATE_COMPANY
  };
}

/**
 * Receive for error updating Company
 */
export function companyUpdateError(error) {
  return {
    type: COMPANY_UPDATE_ERROR,
    error
  };
}

/**
 * Update Companys
 *
 * @param id
 * @returns {function(...[*]=)}
 */
export function deleteCompanyById(id, params) {
  return dispatch => {
    dispatch(requestUpdateCompany());
    apiClient
      .delete(`${endpoints().companyAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;

          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "companies",
            `${endpoints().companyAPI}`,
            1,
            10,
            params ? params : {}
          )
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Update Companys
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateCompanyStatus(id, data, params) {
  return dispatch => {
    dispatch(requestUpdateCompany());
    apiClient
      .put(`${endpoints().companyAPI}/status/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        dispatch(
          fetchList(
            "companies",
            `${endpoints().companyAPI}`,
            1,
            10,
            params ? params : {}
          )
        );
        dispatch(receiveUpdateCompany());
      })
      .catch(error => {
        dispatch(companyUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}
