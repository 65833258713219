import React, { Component } from "react";
import { Collapse, Container } from "reactstrap";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";
import Image from "../../components/base/Image";

// Components
import MarketplaceSection from "../../components/MarketplaceSection";
import ImageCarousel from "../../components/base/imageCarasoul/ImageCarousel.js";
import VideoCarousel from "../../components/base/videoCarousel/VideoCarousel.js";
import SaveButton from "../../components/base/SaveButton";
import CancelButton from "../../components/base/CancelButton";
import Avatar from "../../components/base/Avatar";
import Page404 from "../../views/Page404";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

import { isBadRequest } from "../../common/http";

//Icons
import iconGlobe from "../../assets/img/icons/icon-www.svg";
import iconPin from "../../assets/img/icons/icon-pin.svg";
import iconMessage from "../../assets/img/icons/icon-email1.svg";
import iconForm from "../../assets/img/icons/icon-form.svg";

import SVG from "react-inlinesvg";

//Service
import { getPartnerDetailsById } from "../../partners/Service";
import { isNotEmpty } from "../../common/utils";

//Constant
import {
  MEDIA_APP_PHOTO,
  MEDIA_APP_VIDEO
} from "../marketplaceSourcing/Constants";
import { createAppActivity } from "../../lib/Activity";
import appContants from "../../Constants/App";

import { COOKIE_MARKETPLACE_APP_ID } from "./Constant";

// Helper
import { setCookie, getCookie, getParamsByName } from "../../lib/helper";
import Spinner from "../../components/base/Spinner";
import ContactPartnerForm from "../../partners/ContactPartnerForm.js";

class AppPublicProfile extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      appDetails: [],
      isLoading: false,
      photos: [],
      faq: [],
      partnerDetails: [],
      videos: [],
      appview: true,
      appId: "",
      isPageNotFound: false
    };
  }

  //Faq toggle
  _toggle = id => {
    this.setState({ [id]: !this.state[id] });
  };

  componentDidMount = () => {
    this.setState({ isLoading: true });
    this.getAppDetails();
  };

  //Get App Details
  getAppDetails = async () => {
    const profileUrl = this.props.match.params.id;
    const eid = getParamsByName("eid");

    const apiUrl = `${endpoints().publicAPI}/app/${profileUrl}?eid=${eid}`;
    try {
      await apiClient.get(`${apiUrl}`).then(async response => {
        this.setState({
          appDetails: response.data,
          appId: response.data.id,
          partnerDetails:
            response.data && response.data.partner && response.data.partner[0]
              ? response.data.partner[0]
              : ""
        });
        if (this.state.appDetails.id) {
          await this.getMedia(this.state.appDetails.id, MEDIA_APP_PHOTO);
          await this.getMedia(this.state.appDetails.id, MEDIA_APP_VIDEO);
          await this.getFaq(this.state.appDetails.id);
          this.createViewActivity(this.state.appDetails.id);
        }
      });
      this.setState({ isLoading: false });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        this.setState({
          isLoading: false,
          isPageNotFound: true,
          isInvalidRequest: true
        });
        console.error(errorMessage);
      }
    }
  };

  //Get App Media
  getMedia = async (id, value) => {
    const apiUrl = `${endpoints().mediaAPI}/app?appId=${id}&object=${value}`;
    try {
      const response = await apiClient.get(`${apiUrl}`);
      if (response && response.data && response.data.data) {
        this.setState({ isMediaLoading: true });
        if (value == MEDIA_APP_PHOTO) {
          this.setState({
            photos: response.data.data,
            isMediaLoading: false
          });
        } else
          this.setState({
            videos: response.data.data,
            isMediaLoading: false
          });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        this.setState({
          isInvalidRequest: true,
          isMediaLoading: false,
          isLoading: false,
          isPageNotFound: true
        });
        console.error(errorMessage);
      }
    }
  };

  //Get Faq Details
  getFaq = async id => {
    const objectId = id;
    const apiUrl = `${endpoints().faqAPI}`;

    try {
      const response = await apiClient.get(`${apiUrl}?objectId=${objectId}`);
      if (response && response.data && response.data.data) {
        this.setState({ faq: response.data.data, isLoading: false });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.stringify(errorRequest.response).message;
        }
        this.setState({
          isLoading: false,
          isPageNotFound: true,
          isInvalidRequest: true
        });
        console.error(errorMessage);
      }
    }
  };

  showAsList = data => {
    let dataLists =
      data &&
      data
        .split(",")
        .map(item => <li style={{ listStyleType: "none" }}>{item.trim()}</li>);
    return dataLists;
  };

  getValidUrl = (url = "") => {
    let newUrl = window.decodeURIComponent(url);
    newUrl = newUrl.trim().replace(/\s/g, "");

    if (/^(:\/\/)/.test(newUrl)) {
      return `http${newUrl}`;
    }
    if (!/^(f|ht)tps?:\/\//i.test(newUrl)) {
      return `http://${newUrl}`;
    }
    return newUrl;
  };

  createViewActivity = id => {
    try {
      if (this.state.appview) {
        //get app Ids from cookie
        let appIds = getCookie(COOKIE_MARKETPLACE_APP_ID);
        //split commans separated valuue to array
        let appId = appIds ? appIds.split(",") : [];
        //validate previouus app Id and current app Id same
        if (appId.indexOf(id) == -1) {
          //push the new Id in to array
          appId.push(id);
          //set cookie
          setCookie(COOKIE_MARKETPLACE_APP_ID, appId);
          this.setState({ appview: false });
        }
      }
    } catch (err) {}
  };

  render() {
    const {
      appDetails,
      photos,
      faq,
      partnerDetails,
      videos,
      isLoading,
      isPageNotFound
    } = this.state;
    const {
      app_name,
      app_logo,
      categories,
      version,
      versionNotes,
      summary,
      keyBenefits,
      integrationDescrition,
      supportUrl,
      supportEmail,
      contactEmail,
      integrationType,
      installationUrl,
      app_color,
      appType,
      setup,
      installation_instructions,
      available_for,
      how_it_works,
      installation_file,
      partnerLogo
    } = appDetails;

    let InstallationFileName;
    let InstallationFileUrl;
    if (installation_file && installation_file.length > 0) {
      installation_file.forEach(data => {
        InstallationFileName = data.name;
        InstallationFileUrl = data.fileUrl;
      });
    }

    const {
      id,
      company_name,
      email,
      website_url,
      first_name,
      last_name,
      profile_url
    } = partnerDetails;

    // Error 404 page
    if (isPageNotFound) {
      return <Page404 />;
    }

    // Spinner
    if (isLoading) {
      return <Spinner />;
    }

    return (
      <div className="expert-profile">
        <>
          <MarketplaceSection>
            <Container className="">
              <>
                <Row>
                  {app_logo ? (
                    <Col className="my-auto" sm="5" md="4" lg="2">
                      <Image
                        ImgSrc={app_logo}
                        size="medium"
                        ImgBackgroundColor={app_color}
                      />
                    </Col>
                  ) : (
                    <Col className="my-auto" sm="5" md="4" lg="2">
                      <Avatar
                        fullName={app_name}
                        lineHeight="130px"
                        color="white"
                        bgColor="black"
                        width="140px"
                        height="130px"
                        square
                      />
                    </Col>
                  )}
                  <Col>
                    <div>
                      <h3>{app_name}</h3>
                      <hr style={{ width: "100px", borderColor: "#F34423" }} />
                    </div>
                    <div
                      style={{
                        fontSize: 18,
                        paddingLeft: 6,
                        paddingRight: 6,
                        paddingTop: 0,
                        paddingBottom: 0
                      }}
                    >
                      <div>
                        {company_name && (
                          <div className="d-flex justify-content-start align-items-center">
                            <>
                              <SVG src={iconPin} />
                              <span className="text-dark text-decoration-none pr-2">
                                {" "}
                                by{" "}
                              </span>
                              <a
                                href={`/partner-profile/${profile_url}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <div className="d-flex justify-content-between align-items-center">
                                  <div>
                                    {partnerLogo && (
                                      <img
                                        src={partnerLogo}
                                        className=""
                                        style={{
                                          maxWidth: "50px",
                                          maxHeight: "50px"
                                        }}
                                      />
                                    )}
                                  </div>
                                  <div className="mx-1">
                                    <a>{company_name}</a>
                                  </div>
                                </div>
                              </a>
                            </>
                          </div>
                        )}
                      </div>
                      <div className="pt-1">
                        {website_url && (
                          <>
                            <SVG src={iconGlobe} />
                            <a
                              className="pl-2"
                              href={website_url}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>{website_url}</span>
                            </a>
                          </>
                        )}
                      </div>
                      <div className="pt-1">
                        {contactEmail && (
                          <div>
                            <SVG src={iconMessage} />{" "}
                            <Link
                              to="#"
                              onClick={() =>
                                (window.location = `mailTo: ${contactEmail}`)
                              }
                            >
                              {contactEmail}
                            </Link>
                          </div>
                        )}
                      </div>
                      <div className="pt-1">
                        {profile_url && (
                          <>
                            <SVG src={iconForm} />
                            <a
                              className="pl-2"
                              href={`/partner-profile/${profile_url}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <span>
                                Request More Information about this partner
                              </span>
                            </a>
                          </>
                        )}
                      </div>
                    </div>
                  </Col>
                  <div className="float-lg-right mt-5">
                    {isNotEmpty(supportUrl) ? (
                      <CancelButton
                        width={"95%"}
                        name="Get Support"
                        onClick={() => {
                          window.open(this.getValidUrl(supportUrl));
                        }}
                      />
                    ) : (
                      ""
                    )}

                    {this.state.partnerDetails && this.state.partnerDetails.id && (
                      <div className="mx-1">
                        <ContactPartnerForm
                          partnerId={
                            this.state.partnerDetails &&
                            this.state.partnerDetails.id
                          }
                        />
                      </div>
                    )}
                    {isNotEmpty(installationUrl) ? (
                      <SaveButton
                        label={"View and Install"}
                        className="w-100"
                        onClick={() => {
                          window.open(this.getValidUrl(installationUrl));
                        }}
                      />
                    ) : (
                      ""
                    )}
                    {InstallationFileName && InstallationFileUrl ? (
                      <div className="d-inline-block">
                        <a
                          href={InstallationFileUrl}
                          download={InstallationFileName}
                        >
                          <SaveButton
                            label={"Download"}
                            onClick={() => {
                              this.createAppActivity(
                                appDetails.id,
                                appContants.ACTIVITY_TYPE_MARKETPLACE_APP_DOWNLOAD
                              );
                            }}
                          />
                        </a>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </Row>
                <Row className="mt-5 mb-4">
                  <Col className="pb-4 pt-5">
                    <hr />
                  </Col>
                </Row>
                <Row>
                  <Col lg="8">
                    {/* Overview */}
                    {summary && (
                      <div className="section-maincontent mb-4">
                        <h3 className="font-weight-bold mb-4">Overview</h3>
                        <p className="pre-wrap">{summary} </p>
                      </div>
                    )}
                    {/* Key Benefits */}
                    {keyBenefits && (
                      <div className="section-maincontent mb-4">
                        <h3 className="font-weight-bold mb-4">Key Benefits</h3>
                        <p className="pre-wrap">{keyBenefits} </p>
                      </div>
                    )}
                    {/* Gallery Section */}
                    {photos && photos.length > 0 && (
                      <>
                        <Row>
                          <Col>
                            <div className="section-maincontent mb-4">
                              <h3 className="font-weight-bold mb-4">Gallery</h3>
                            </div>
                          </Col>
                        </Row>
                        <ImageCarousel
                          photos={photos}
                          showRemove={false}
                          style={{
                            width: "100%",
                            height: "400px",
                            objectFit: "scale-down"
                          }}
                          slides={1}
                        />
                      </>
                    )}
                    {/* Video Gallery Section */}
                    {videos && videos.length > 0 && (
                      <>
                        <Row>
                          <Col>
                            <div className="section-maincontent mb-4">
                              <h3 className="font-weight-bold mb-4">Videos</h3>
                            </div>
                          </Col>
                        </Row>
                        <VideoCarousel
                          videos={videos}
                          showRemove={false}
                          style={{
                            width: "100%",
                            height: "400px",
                            objectFit: "scale-down"
                          }}
                          slides={1}
                        />
                      </>
                    )}
                    {/* FAQ Section */}
                    {faq && faq.length > 0 && (
                      <div className="section-maincontent mt-4">
                        <h3 className="font-weight-bold mb-4">FAQ</h3>
                        <hr />
                        {faq.map(data => {
                          return (
                            <>
                              <div className="col-12">
                                <p
                                  className="cursor-pointer mb-3"
                                  onClick={() => {
                                    this._toggle(data.id);
                                  }}
                                >
                                  <>
                                    <b>{data.question}</b>
                                    {this.state[data.id] ? (
                                      <i
                                        className="fa fa-minus pull-right pt-1"
                                        aria-hidden="true"
                                      ></i>
                                    ) : (
                                      <i
                                        className="fa fa-plus pull-right pt-1"
                                        aria-hidden="true"
                                      ></i>
                                    )}
                                  </>
                                </p>
                              </div>
                              <Collapse
                                className="pl-3"
                                isOpen={this.state[data.id]}
                              >
                                <span>{data.answer}</span>
                              </Collapse>
                              <hr />
                            </>
                          );
                        })}
                      </div>
                    )}
                    {/* How It Work Section */}
                    {how_it_works && (
                      <div className="section-maincontent mb-4">
                        <h3 className="font-weight-bold mb-4">How it Works</h3>
                        <p className="pre-wrap">{how_it_works} </p>
                      </div>
                    )}
                    {/* Installation Instructions Section */}
                    {installation_instructions && (
                      <div className="section-maincontent mb-4">
                        <h3 className="font-weight-bold mb-4">
                          Installation Instructions
                        </h3>
                        <p className="pre-wrap">{installation_instructions} </p>
                      </div>
                    )}
                  </Col>

                  {/* Side Bar Section */}
                  <Col lg={{ size: 3, offset: 1 }}>
                    {categories && (
                      <div className="section-maincontent mb-4">
                        <h6 className="font-weight-bold mb-4">Categories</h6>
                        {this.showAsList(categories)}
                      </div>
                    )}
                    <div className="section-maincontent mb-4">
                      {/* Information */}
                      {(summary ||
                        (photos && photos.length > 0) ||
                        (faq && faq.length > 0) ||
                        how_it_works ||
                        installation_instructions) && (
                        <h6 className="font-weight-bold mb-4">Information</h6>
                      )}
                      {summary && <p className="pre-wrap"> Overview </p>}
                      {keyBenefits && (
                        <p className="pre-wrap"> Key Benefits </p>
                      )}

                      {photos && photos.length > 0 && (
                        <p className="pre-wrap"> Gallery </p>
                      )}

                      {faq && faq.length > 0 && (
                        <p className="pre-wrap"> FAQ </p>
                      )}

                      {how_it_works && (
                        <p className="pre-wrap"> How it Works </p>
                      )}

                      {installation_instructions && (
                        <p className="pre-wrap"> Installation Instruction </p>
                      )}
                    </div>

                    {/* App Details */}
                    {(integrationType || setup || version) && (
                      <div className="section-maincontent mb-4">
                        <h6 className="font-weight-bold mb-4">App Details</h6>
                        {integrationType && (
                          <p className="pre-wrap"> Type: {integrationType}</p>
                        )}
                        {setup && <p className="pre-wrap"> Setup: {setup} </p>}
                        {version && (
                          <p className="pre-wrap"> Version: {version} </p>
                        )}
                      </div>
                    )}

                    {/* Developer Detail */}
                    {(installationUrl || supportUrl || supportEmail) && (
                      <div className="section-maincontent mb-4">
                        <h6 className="font-weight-bold mb-4">
                          Developer Details
                        </h6>
                        {installationUrl && (
                          <a
                            className="pl-2"
                            href={installationUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>Installation Website</span>
                            <br />
                          </a>
                        )}
                        {supportUrl && (
                          <a
                            className="pl-2"
                            href={supportUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span>Support Website</span>
                            <br />
                          </a>
                        )}
                        {supportEmail && (
                          <a
                            className="pl-2"
                            href={supportEmail}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <span
                              onClick={() =>
                                (window.location = `mailTo: ${supportEmail}`)
                              }
                            >
                              Support Email
                            </span>
                            <br />
                          </a>
                        )}
                      </div>
                    )}
                  </Col>
                </Row>
              </>
            </Container>
          </MarketplaceSection>
        </>
      </div>
    );
  }
}

export default AppPublicProfile;
