import React, { useEffect, useState } from "react";

// API call
import { apiClient } from "../../../../apiClient";

// Assets
import { PlusIcon, ChevronRight } from "../../../../assets/img/icons";

// Components
import Spinner from "../../../../components/base/Spinner";
import toast from "../../../../components/base/Toast";
import Form from "../../../../components/base/Form";
import Page404 from "../../../Page404";

// Constants
import {
  GENERAL_GENERAL_SUB_TAB,
  PARTNER_APPLICATIONS_FORMS_SUB_TAB,
  TAB_GENERAL,
  TAB_PARTNER_ONBOARDING
} from "../../../../setting/Constants";
import {
  PARTNER_PERMISSION_ALL_PARTNER,
  PARTNER_PERMISSION_ALL_PARTNER_VALUE,
  PARTNER_PERMISSION_FILTERED_PARTNERS
} from "../../../../resource/Constants";

//Helper
import { endpoints } from "../../../../configs";
import { isBadRequest } from "../../../../common/http";

// Inner Components
import AddFieldModal from "./inner-components/AddFieldModal";
import DeleteTaskModal from "./inner-components/DeleteTaskModal";
import EditFieldModal from "./inner-components/EditFieldModal";
import Field from "./inner-components/Field";
import PartnerSelector from "../../../../../src/partner/component/PartnerSelector";

const PartnerApplicationsField = props => {
  const { history, selectedFormName } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [formDetails, setFormDetails] = useState("");
  const [fieldList, setFieldList] = useState([]);
  const [addFieldModal, setAddFieldModal] = useState(false);
  const [editFieldModal, setEditFieldModal] = useState(false);
  const [currentField, setCurrentField] = useState(false);
  const [deleteFieldModal, setDeleteFieldModal] = useState(false);
  const [sourceFieldOrder, setSourceFieldOrderState] = useState("");
  const [targetFieldOrder, setTargetFieldOrderState] = useState("");
  const [isPageNotFound, setIsPageNotFound] = useState(false);

  const getFieldList = async () => {
    await apiClient
      .get(
        `${endpoints().partnerApplicationFieldAPI}?formId=${
          props.match.params.section
        }`
      )
      .then(response => {
        if (response.data && response.data.data) {
          setFieldList(response.data.data);

          setIsLoading(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  const getFormDetails = async () => {
    let id = props.selectedFormId
      ? props.selectedFormId
      : props.match.params.section;
    await apiClient
      .get(`${endpoints().partnerApplicationFormAPI}/${id}`)
      .then(response => {
        if (response && response.data) {
          setIsLoading(true);
          setFormDetails(response.data);
        }
        setIsLoading(false);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          setIsPageNotFound(true);
          console.error(errorMessage);
        }
      });
  };

  const addNewField = values => {
    apiClient
      .post(`${endpoints().partnerApplicationFieldAPI}`, values)
      .then(response => {
        if (response.data) {
          setIsLoading(true);
          toast.success(response.data.message);
          setAddFieldModal(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          toast.error(error.response.data.message);

          setIsLoading(false);
        }
      });
  };

  const updateField = (id, values) => {
    apiClient
      .put(`${endpoints().partnerApplicationFieldAPI}/${id}`, values)
      .then(response => {
        if (response.data) {
          setCurrentField("");
          setIsLoading(true);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  const deleteField = id => {
    apiClient
      .delete(`${endpoints().partnerApplicationFieldAPI}/${id}`)
      .then(response => {
        if (response.data) {
          setCurrentField("");
          setIsLoading(true);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };
  const partnerPermissionLevel = async permission => {
    const permissionId = permission === PARTNER_PERMISSION_ALL_PARTNER ? 1 : 2;
    if (permissionId) {
      const data = new FormData();
      data.append("partnerPermissionId", permissionId);

      await apiClient
        .put(
          `${endpoints().partnerApplicationFormAPI}/partnerPermission/${
            props.match.params.section
          }`,
          data
        )
        .then(response => {
          if (response.data) {
            toast.success(response.data.message);
            getFormDetails();
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    }
  };
  const handleTagTirePermission = async tierId => {
    if (tierId) {
      const data = new FormData();
      data.append("partnerTierId", tierId);
      await apiClient
        .put(
          `${endpoints().partnerApplicationFormAPI}/partnerTier/${
            props.match.params.section
          }`,
          data
        )
        .then(response => {
          if (response.data) {
            toast.success(response.data.message);
            getFormDetails();
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    }
  };
  const handleTagTypePermission = async typeId => {
    if (typeId) {
      const data = new FormData();
      data.append("partnerTypeId", typeId);
      await apiClient
        .put(
          `${endpoints().partnerApplicationFormAPI}/partnerType/${
            props.match.params.section
          }`,
          data
        )
        .then(response => {
          if (response.data) {
            toast.success(response.data.message);
            getFormDetails();
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    }
  };

  useEffect(() => {
    if (isLoading) {
      getFieldList();
    }
  }, [isLoading]);

  useEffect(() => {
    getFormDetails();
  }, []);

  // Set Current Order Position of Selected Field
  const setSourceFieldOrder = tagType => {
    if (sourceFieldOrder !== tagType.order) {
      setSourceFieldOrderState(tagType.order);
    }
  };

  // Set Target Order Position of Selected Field
  const setTargetFieldOrder = tagType => {
    if (targetFieldOrder !== tagType.order) {
      setTargetFieldOrderState(tagType.order);
    }
  };

  //Handle Permission Change
  const handlePartnerPermission = e => {
    const partnerPermission = e;
    partnerPermissionLevel(partnerPermission);
  };

  //Update Field
  const updateFieldOrder = updatedFieldList => {
    apiClient
      .put(
        `${endpoints().partnerApplicationFieldAPI}/update/sortOrder`,
        updatedFieldList
      )
      .then(response => {
        if (response.data) {
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  // Change Order Position of Selected Field
  const changeFieldOrder = () => {
    // Current Selected Field List
    if (fieldList && fieldList.length > 0) {
      fieldList.splice(
        targetFieldOrder,
        0,
        fieldList.splice(sourceFieldOrder, 1)[0]
      );

      // Updated Selected Tag Type List
      const updatedFieldList = [];

      fieldList.forEach(tagType => {
        updatedFieldList.push({
          ...tagType,
          // Update Selected Tag Type Order
          order: updatedFieldList.length
        });
      });

      updateFieldOrder(updatedFieldList);
      setFieldList(updatedFieldList);
      setSourceFieldOrderState("");
      setTargetFieldOrderState("");
    } else {
      // Reset Order Positions of Selected Tag Type
      setSourceFieldOrderState("");
      setTargetFieldOrderState("");
    }
  };
  if (isLoading) {
    return <Spinner />;
  }

  if (isPageNotFound) {
    return <Page404 />;
  }

  return (
    <>
      <AddFieldModal
        isOpen={addFieldModal}
        toggle={() => {
          setAddFieldModal(prevState => !prevState);
        }}
        addNewField={addNewField}
        index={fieldList.length}
        formId={props.match.params.section}
      />

      <EditFieldModal
        isOpen={editFieldModal}
        toggle={() => {
          setEditFieldModal(prevState => !prevState);
        }}
        field={currentField}
        updateField={updateField}
      />

      <DeleteTaskModal
        isOpen={deleteFieldModal}
        toggle={() => {
          setDeleteFieldModal(prevState => !prevState);
        }}
        task={currentField}
        deleteTask={deleteField}
      />
      <>
        {/* Breadcrumb Start */}
        <div className="d-flex align-items-center pt-4 pb-3">
          <span
            className="cursor-pointer"
            onClick={() => {
              history.push(
                `/admin-settings/${TAB_GENERAL}/${GENERAL_GENERAL_SUB_TAB}`
              );
            }}
          >
            Admin Settings
          </span>
          <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
            <ChevronRight />
          </span>
          <span
            className="cursor-pointer"
            onClick={() => {
              history.push(`/admin-settings/${TAB_PARTNER_ONBOARDING}`);
            }}
          >
            <span>{TAB_PARTNER_ONBOARDING}</span>
          </span>
          <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
            <ChevronRight />
          </span>
          <span
            className="cursor-pointer"
            onClick={() => {
              history.push(
                `/admin-settings/${TAB_PARTNER_ONBOARDING}/${PARTNER_APPLICATIONS_FORMS_SUB_TAB}`
              );
            }}
          >
            <span>{PARTNER_APPLICATIONS_FORMS_SUB_TAB}</span>
          </span>

          <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
            <ChevronRight />
          </span>

          <span className="text-dark font-weight-bold">
            <span>{(formDetails && formDetails.name) || selectedFormName}</span>
          </span>
        </div>
        {/* Breadcrumb End */}

        <h5 className=" mt-3 mb-4 text-left font-weight-bold">
          {(formDetails && formDetails.name) || selectedFormName}{" "}
        </h5>
      </>

      <div className="card">
        <div className="pt-3 px-4">
          <Form>
            {!isLoading ? (
              <div>
                <PartnerSelector
                  showPartnerType
                  showPartnerTier
                  partnerPermissionDefaultValue={
                    formDetails &&
                    formDetails.partnerPermissionId ===
                      PARTNER_PERMISSION_ALL_PARTNER_VALUE
                      ? PARTNER_PERMISSION_ALL_PARTNER
                      : PARTNER_PERMISSION_FILTERED_PARTNERS
                  }
                  SelectedTierOptions={
                    (formDetails && formDetails.partnerTierId) || []
                  }
                  SelectedTypeOptions={
                    (formDetails && formDetails.partnerTypeId) || []
                  }
                  handleTierOption={e => {
                    handleTagTirePermission(e);
                  }}
                  handleTypeOption={e => {
                    handleTagTypePermission(e);
                  }}
                  handlePermissionOnchange={e => {
                    handlePartnerPermission(e);
                  }}
                />
              </div>
            ) : (
              <Spinner />
            )}
          </Form>
        </div>
        <h6 className="font-weight-bold pt-4 px-4 pb-3">
          Fields for Applicants
        </h6>

        <div className="task-list">
          {fieldList &&
            fieldList.length > 0 &&
            fieldList.map(fieldItem => (
              <>
                <div
                  id={fieldItem.order}
                  key={fieldItem.id}
                  draggable="true"
                  onDrag={() => {
                    setSourceFieldOrder(fieldItem);
                  }}
                  onDragOver={e => {
                    e.preventDefault();
                    setTargetFieldOrder(fieldItem);
                  }}
                  onDrop={() => {
                    changeFieldOrder();
                  }}
                >
                  <Field
                    field={fieldItem}
                    setEditFieldModal={setEditFieldModal}
                    setCurrentField={setCurrentField}
                    setDeleteFieldModal={setDeleteFieldModal}
                    updateField={updateField}
                  />
                </div>
              </>
            ))}

          <div className=" task p-4" style={{ minHeight: "70px" }}>
            <div
              onClick={() => {
                setAddFieldModal(prevState => !prevState);
              }}
              className="task-left cursor-pointer"
            >
              <div className="checkbox add-task">
                <PlusIcon />
              </div>
              <span className={["add-task text-link"].join(" ")}>
                Add a new field...
              </span>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PartnerApplicationsField;
