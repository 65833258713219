import React from "react";
import { toast } from "react-toastify";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import {
  toString,
  fromArray,
  getParamsByName,
  removeMaskedPhoneNumber,
  getCookie,
  setCookie,
  clearCookie,
  validateStrongPassword,
  getKeyValueByObject,
  getSettingMediaUrl,
  getBaseUrlParam
} from "../../lib/helper";

// Components
import Form from "../../components/base/Form";

import LeftSideSection from "./LeftSideSection";
import GetStarted from "./GetStarted";
import RequestReceived from "./RequestReceived";
import BasicInformation from "./BasicInformation";

// Constants
import {
  PARTNER_SIGNUP_BASIC_INFORMATION,
  PARTNER_SIGNUP_BASIC_INFORMATION_TEXT,
  PARTNER_SIGNUP_GETTING_STARTED,
  PARTNER_SIGNUP_GETTING_STARTED_TEXT,
  PARTNER_SIGNUP_REQUEST_RECEIVED,
  PARTNER_SIGNUP_REQUEST_RECEIVED_TEXT
} from "../../partnerSignup/Constants";

import {
  SETTINGS_PARTNER_SIGNUP_WELCOME_MESSAGE,
  SETTINGS_PARTNER_SIGNUP_HERO_IMAGE,
  BACKGROUND_IMAGE_URL,
  SETTINGS_PORTAL_NAME,
  SETTINGS_ENABLE_SALESFORCE_LOGIN,
  SETTINGS_ENABLE_OFFICE_365_LOGIN,
  SETTINGS_ENABLE_GOOGLE_LOGIN,
  SETTINGS_ENABLE_LINKEDIN_LOGIN,
  SETTINGS_LINKEDIN_CLIENT_ID,
  SETTINGS_LINKEDIN_REDIRECT_URI,
  SETTINGS_GOOGLE_CLIENT_ID,
  SETTINGS_SALESFORCE_LOGIN_CLIENT_ID,
  SETTINGS_SALESFORCE_LOGIN_REDIRECT_URL,
  PARTNER_SIGNUP_PAGE_IMAGE,
  PARTNER_LOGIN_PAGE_IMAGE
} from "../../setting/Constants";
import { COOKIE_SESSION_TOKEN, COOKIE_USER_ID } from "../../lib/cookie";
import Avatar from "../../components/base/Avatar";
import { isBadRequest } from "../../common/http";

class PartnerSignUp extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      height: window.innerHeight,
      firstName: "",
      lastName: "",
      errorMessage: "",
      yearsExperience: "",
      companyNameErrorMessage: "",
      emailErrorMessage: "",
      yearsOfExperience: "",
      timezone: "",
      partnerSignup: false,
      partnerUserSignup: false,
      partnerRoles: [],
      passwordToken: "",
      lastName: "",
      firstName: "",
      defaultPartnerType: "",
      partnerId: "",
      jobTitle: "",
      passwordMismatchError: "",
      confirmPasswordMismatchError: "",
      currentUserId: null,
      partnerTypePermission: "",
      partnerPermission: "",
      partnerDetails: "",
      isLoading: true,
      partnerId: getParamsByName("partnerId"),
      selectedPartnerDetails: "",
      partnerCompanyName: "",
      userDetails: "",
      encryptedUserId: getParamsByName("userId"),
      selectPartnerDisabled: false,
      isThirdPartyAccountSignUp: false,
      partnerTypeImage: "",
      partnerType: "",
      partnerTypeWelcomeMessage: ""
    };

    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this._submit = this._submit.bind(this);
    this._close = this._close.bind(this);

    // Bind the update dimensions
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    const section = getParamsByName("section");
    const partnerId = getParamsByName("partnerId");
    const partner_type = getParamsByName("partnertype");

    this.setState({
      partnerId: partnerId,
      partnerType: partner_type
    });
    const encryptedUserId = getParamsByName("userId");

    if (partnerId) this.getPartnerDetails(partnerId);

    if (encryptedUserId)
      this.setState({ encryptedUserId: encryptedUserId }, () => {
        this.getUserDetails();
      });

    if (partnerId || encryptedUserId) {
      this.setState({ isLoading: true });
    } else this.setState({ isLoading: false });

    if (getCookie(COOKIE_SESSION_TOKEN)) {
      this.getUserDetails();
    }

    if (getCookie("userId") !== "") {
      window.location.href = "/dashboard";
    }

    if (section === PARTNER_SIGNUP_GETTING_STARTED_TEXT) {
      this.setState({
        currentStep: PARTNER_SIGNUP_GETTING_STARTED
      });
    }
    if (section === PARTNER_SIGNUP_BASIC_INFORMATION_TEXT) {
      this.setState({
        currentStep: PARTNER_SIGNUP_BASIC_INFORMATION
      });
    }

    if (section === PARTNER_SIGNUP_REQUEST_RECEIVED_TEXT) {
      this.setState({
        currentStep: PARTNER_SIGNUP_REQUEST_RECEIVED
      });
    }

    if (section === null) {
      this.updateSectionUrl(PARTNER_SIGNUP_GETTING_STARTED_TEXT);
    }
    if (partner_type) {
      this.getPartnerType(partner_type);
    }
  }

  componentDidUpdate() {
    const section = getParamsByName("section");
    const { currentStep } = this.state;
    if (
      section === PARTNER_SIGNUP_GETTING_STARTED_TEXT &&
      currentStep != PARTNER_SIGNUP_GETTING_STARTED
    ) {
      this.setState({
        currentStep: PARTNER_SIGNUP_GETTING_STARTED
      });
    }
    if (
      section === PARTNER_SIGNUP_BASIC_INFORMATION_TEXT &&
      currentStep != PARTNER_SIGNUP_BASIC_INFORMATION
    ) {
      this.setState({
        currentStep: PARTNER_SIGNUP_BASIC_INFORMATION
      });
    }

    if (
      section === PARTNER_SIGNUP_REQUEST_RECEIVED_TEXT &&
      currentStep != PARTNER_SIGNUP_REQUEST_RECEIVED
    ) {
      this.setState({
        currentStep: PARTNER_SIGNUP_REQUEST_RECEIVED
      });
    }

    if (section === null) {
      this.updateSectionUrl(PARTNER_SIGNUP_GETTING_STARTED_TEXT);
    }
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  // Update the screen height
  updateDimensions() {
    this.setState({
      height: window.innerHeight
    });
  }

  // Update param based on step selected
  updateParam(currentStep) {
    const sectionParam =
      currentStep === PARTNER_SIGNUP_GETTING_STARTED
        ? PARTNER_SIGNUP_GETTING_STARTED_TEXT
        : currentStep === PARTNER_SIGNUP_BASIC_INFORMATION
        ? PARTNER_SIGNUP_BASIC_INFORMATION_TEXT
        : currentStep === PARTNER_SIGNUP_REQUEST_RECEIVED
        ? PARTNER_SIGNUP_REQUEST_RECEIVED_TEXT
        : "";

    const partnerId = getParamsByName("partnerId");

    let query = "";
    if (currentStep === PARTNER_SIGNUP_BASIC_INFORMATION && partnerId) {
      query = `&partnerId=${partnerId}`;
    }

    this.props.history.push(`/partner-signup?section=${sectionParam}${query}`);
  }

  // _next functions will be called on button click
  _next = props => {
    let currentStep = this.state.currentStep;
    // If the current step is 5 or 6, then add one on "next" button click
    currentStep = currentStep >= 3 ? 3 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });
    this.updateParam(currentStep);
  };

  // _previous functions will be called on button click
  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
    this.updateParam(currentStep);
  }

  getPartnerDetails = partnerId => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

    return apiClient
      .get(`${endpoints().partnerSignUp}?id=${partnerId}`)
      .then(response => {
        this.setState(
          {
            partnerDetails: response.data,
            partnerCompanyName: response.data.companyName,
            selectPartnerDisabled: true,
            isLoading: true
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          toast.error(errorMessage);
        }
      });
  };

  setHeaders = () => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
  };

  getUserDetails = () => {
    this.setHeaders();
    let userApiUrl = endpoints().userAPI;
    // if (getCookie(COOKIE_SESSION_TOKEN)) {
    //   userApiUrl = endpoints().userAPI;
    // } else
    if (this.state.encryptedUserId) {
      userApiUrl = `${endpoints().publicAPI}/user/${
        this.state.encryptedUserId
      }`;
    }
    return apiClient
      .get(
        `${userApiUrl}${this.state.partnerId &&
          "?encryptedPartnerId=" + this.state.partnerId}`
      )
      .then(response => {
        if (response && response.data && response.data.isUserAccepted) {
          window.location.replace(`/login?email=${response.data.email}`);
        }

        this.setState(
          {
            userDetails: response.data,
            isLoading: true
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          toast.error(errorMessage);
        }
      });
  };

  getPartnerType = partner_type => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

    apiClient
      .get(`${endpoints().partnerTypeAPI}?name=${partner_type}`)
      .then(response => {
        this.setState(
          {
            partnerTypeImage: response.data.partner_signup_page_image,
            partnerTypeWelcomeMessage:
              response.data.partnerTypeSettingWelcomeMessage,
            isLoading: true
          },
          () => {
            this.setState({ isLoading: false });
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          toast.error(errorMessage);
        }
      });
  };
  // Handle job run
  _updatePartnerProfileCompletion = async userId => {
    if (!userId) return null;

    try {
      await apiClient.put(
        `${endpoints().partnerAPI}/job/profileCompletionPercentageUpdate`,
        { userId }
      );
    } catch (error) {
      return null;
    }
  };

  // _previous functions will be called on button click
  _submit(values) {
    if (this._validateFields(values) === false) {
      return;
    }

    let currentStep = this.state.currentStep;
    // currentStep = currentStep >= 3 ? 3 : currentStep + 1;
    // this.setState({
    //   currentStep: currentStep
    // });
    // this.updateParam(currentStep);
    if (currentStep === 1) {
      this._partnerUserSignUp(this._toArray(values));
    }

    if (currentStep === 2) {
      if (!this.state.partnerSignup) {
        this._partnerSignUp(this._toArray(values));
      } else {
        this._updatePartner(this._toArray(values));
      }
    }
    // if (currentStep === 3 && this.state.partnerSignup) {
    //   if (this.state.partnerId) values.partnerId = this.state.partnerId;
    //   this._updatePartner(this._toArray(values));
    // }
  }

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const { partnerCompanyName, partnerId } = this.state;

    // define partner sign up constants
    const fullName = values.fullName ? values.fullName : "";
    const firstName = values.firstName
      ? values.firstName
      : values.fullName || "";
    const lastName = values.lastName;
    const email = values.email;
    const password = values.password;
    const confirmPassword = values.confirmPassword;

    const passwordError = validateStrongPassword(password);

    switch (this.state.currentStep) {
      case 1:
        if (fullName == null || email == null) {
          success = false;
        } else if (passwordError) {
          this.setState({
            passwordMismatchError: passwordError
          });
          success = false;
        } else if (
          password &&
          confirmPassword &&
          password !== confirmPassword
        ) {
          this.setState({
            passwordMismatchError: "",
            confirmPasswordMismatchError: "Confirm password did not match"
          });
          success = false;
        } else {
          this.setState({
            passwordMismatchError: "",
            confirmPasswordMismatchError: ""
          });
        }
        break;
      case 2:
        if (!partnerCompanyName) {
          success = false;
        }

        break;
      default:
        break;
    }

    return success;
  }

  // Handle password error messages
  _handlePasswordErrors = e => {
    const password = e.values.password;
    const confirmPassword = e.values.confirmPassword;
    const passwordError = validateStrongPassword(password);
    if (passwordError) {
      this.setState({
        passwordMismatchError: passwordError
      });
    } else if (password && confirmPassword && password !== confirmPassword) {
      this.setState({
        passwordMismatchError: "",
        confirmPasswordMismatchError: "Confirm password did not match"
      });
    } else {
      this.setState({
        passwordMismatchError: "",
        confirmPasswordMismatchError: ""
      });
    }
  };

  // Close the wizard
  _close() {
    window.location = "/dashboard";
  }

  // Render square box
  _renderSquare(currentValue) {
    const currentStep = this.state.currentStep;
    const showActive = currentStep === currentValue ? "active" : "in-active";
    return currentStep < 3 ? <div className={`box ${showActive}`} /> : "";
  }

  // Render wizard indicator
  _renderWizardIndicator() {
    return (
      <div className="expert-request-active-btn-section d-flex">
        {[1, 2, 3].map(n => {
          return this._renderSquare(n);
        })}
      </div>
    );
  }

  // To Array
  _toArray(data) {
    let values = { ...data };
    const {
      partnerCompanyName,
      partnerId,
      selectedPartnerDetails,
      userDetails,
      encryptedUserId
    } = this.state;
    const firstName = values.firstName;
    const lastName = values.lastName;
    const email = values.email;
    const password = values.password;
    const partnerRoles = values.partnerRoles;

    if (encryptedUserId) values.encryptedUserId = encryptedUserId;
    if (partnerId) values.partnerId = partnerId;

    this.setState({
      firstName,
      lastName,
      email,
      partnerRoles
    });

    values.companyName = toString(partnerCompanyName);
    values.firstName = firstName
      ? toString(firstName)
      : (userDetails && userDetails.firstName) || "";
    values.lastName = lastName
      ? toString(lastName)
      : (userDetails && userDetails.lastName) || "";
    values.email = email
      ? toString(email)
      : (userDetails && userDetails.lastName) || "";
    values.password = toString(password);
    values.phone = toString(
      values.phone && removeMaskedPhoneNumber(values.phone)
    );
    values.acceptTerms = values.acceptTerms ? "Yes" : "No";
    values.partnerRoles = partnerRoles ? fromArray(partnerRoles) : "";
    values.companyUrl =
      selectedPartnerDetails && selectedPartnerDetails.website_url
        ? toString(selectedPartnerDetails.website_url)
        : toString(values.companyUrl);

    return values;
  }

  // Partner User SignUp API Call
  _partnerUserSignUp(data) {
    this.setHeaders();
    let currentStep = this.state.currentStep;
    if (this.state.partnerDetails) {
      data.partnerType =
        this.state.partnerDetails && this.state.partnerDetails.partnerType;
    }
    return apiClient
      .post(
        `${endpoints().publicAPI}/partner/user/signup?${getBaseUrlParam()}`,
        data
      )
      .then(response => {
        // If the current step is 5 or 6, then add one on "next" button click
        // currentStep = currentStep >= 3 ? 3 : currentStep + 1;
        this.setState(
          {
            // currentStep: currentStep,
            partnerUserSignup: true,
            currentUserId: response.data.userId,
            partnerTypePermission: response.data.partnerTypePermission,
            partnerPermission: response.data.partnerPermission,
            firstName: response && response.data && response.data.firstName,
            lastName: response && response.data && response.data.lastName,
            email: response && response.data && response.data.email,
            defaultPartnerType:
              response && response.data && response.data.defaultPartnerType,
            partnerId: response && response.data && response.data.partnerId
          },
          () => {
            const { token, role, userId, partnerDetails } = response.data;

            setCookie(COOKIE_SESSION_TOKEN, token);
            setCookie(COOKIE_USER_ID, userId);

            clearCookie("googleAuthToken");
            clearCookie("office365AuthToken");
            clearCookie("linkedinAuthToken");
            clearCookie("salesforceAuthToken");

            if (partnerDetails) {
              this.setState(
                {
                  partnerDetails: partnerDetails,
                  partnerId: partnerDetails.id,
                  partnerCompanyName: partnerDetails.companyName,
                  selectPartnerDisabled: true
                },
                () => {
                  this.updateSectionUrl(PARTNER_SIGNUP_BASIC_INFORMATION_TEXT);
                }
              );
            } else {
              let partnerName = {
                name: this.state.firstName + " " + this.state.lastName,
                firstName: this.state.firstName,
                lastName: this.state.lastName,
                email: this.state.email,
                defaultPartnerType: getParamsByName("partnertype"),
                partnerId: this.state.partnerId
              };
              {
                this.state.partnerTypePermission == "true" ||
                this.state.partnerPermission == "true"
                  ? this._partnerSignUp(partnerName)
                  : this.updateSectionUrl(
                      PARTNER_SIGNUP_BASIC_INFORMATION_TEXT
                    );
              }
            }
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          toast.error(errorMessage);
          const isCompanyNameExitsError =
            errorMessage === "Company Name already exist" ? errorMessage : "";
          const isEmailExitsError =
            errorMessage === "Email already exists" ? errorMessage : "";
          this.setState({
            errorMessage,
            companyNameErrorMessage: isCompanyNameExitsError,
            emailErrorMessage: isEmailExitsError
          });
        }
      });
  }

  // Partner SignUp API Call
  _partnerSignUp(data) {
    this.setHeaders();
    let currentStep = this.state.currentStep;

    return apiClient
      .post(`${endpoints().partnerSignUp}?${getBaseUrlParam()}`, data)
      .then(response => {
        // If the current step is 5 or 6, then add one on "next" button click
        // currentStep = currentStep >= 3 ? 3 : currentStep + 1;
        this.setState(
          {
            currentStep: currentStep,
            partnerSignup: true,
            currentUserId: response.data.userId
          },
          () => {
            if (this.state.encryptedUserId) {
              window.location.replace("/login");
            } else if (this.state.isThirdPartyAccountSignUp) {
              this._close();
            } else {
              this.updateSectionUrl(PARTNER_SIGNUP_REQUEST_RECEIVED_TEXT);
            }
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          toast.error(errorMessage);
          const isCompanyNameExitsError =
            errorMessage === "Company Name already exist" ? errorMessage : "";
          const isEmailExitsError =
            errorMessage === "Email already exists" ? errorMessage : "";
          this.setState({
            errorMessage,
            companyNameErrorMessage: isCompanyNameExitsError,
            emailErrorMessage: isEmailExitsError
          });
        }
      });
  }

  updateSectionUrl = section => {
    const partnerId = getParamsByName("partnerId");
    const encryptedUserId = getParamsByName("userId");
    const defaultPartnerType = getParamsByName("partnertype");

    this.props.history.push(
      `/partner-signup?section=${section}${
        partnerId ? "&partnerId=" + partnerId : ""
      }${encryptedUserId ? "&userId=" + encryptedUserId : ""}${
        defaultPartnerType ? "&partnertype=" + defaultPartnerType : ""
      }`
    );
  };

  // Handle Resend Verification Mail
  handleResendVerificationMail() {
    apiClient
      .get(`${endpoints().userAPI}/resend/verify`)
      .then(response => {
        let successMessage;
        if (response && response.data && response.data.message) {
          successMessage = response.data.message;

          toast.success(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  partnerDropdownChange = data => {
    const partnerDetails =
      data &&
      data.values &&
      data.values.companyName &&
      data.values.companyName.details;
    if (partnerDetails) {
      this.setState({
        selectedPartnerDetails: partnerDetails,
        partnerId: partnerDetails.id,
        partnerCompanyName: partnerDetails.companyName
      });
    } else {
      if (
        data &&
        data.values &&
        data.values.companyName &&
        data.values.companyName.value
      ) {
        this.setState({ partnerCompanyName: data.values.companyName.value });
      } else {
        this.setState({ partnerCompanyName: "" });
      }
      this.setState({ selectedPartnerDetails: "" });
    }
  };

  // Reset the message
  _hideErrorMessage = () => {
    this.setState({ companyNameErrorMessage: "", emailErrorMessage: "" });
  };

  // Update Partner Details
  _updatePartner(data) {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    let currentStep = this.state.currentStep;
    data.partnerSignup = true;

    return apiClient
      .put(`${endpoints().partnerSignUpUpdate}?${getBaseUrlParam()}`, data)
      .then(response => {
        this.setState(
          {
            currentStep: currentStep,
            currentUserId: response.data.userId
          },
          () => {
            if (this.state.encryptedUserId) {
              window.location.replace("/login");
            } else if (this.state.isThirdPartyAccountSignUp) {
              this._close();
            } else {
              this.updateSectionUrl(PARTNER_SIGNUP_REQUEST_RECEIVED_TEXT);
            }
          }
        );

        this._updatePartnerProfileCompletion(this.state.currentUserId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({
            errorMessage
          });
        }
      });
  }

  // Update Partner Details
  _partnerProfileUpdate(details) {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    const data = new FormData();
    data.append("file", details.file);
    data.append("email", details.email);

    return apiClient
      .put(endpoints().partnerSignUpAvatarUpdate, data)
      .then(response => {})
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  setUserDetails = data => {
    const partnerDetails = {
      ...this.state.partnerDetails,
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      email: data.email || "",
      authToken: data.authToken || ""
    };

    const userDetails = {
      ...this.state.userDetails,
      fullName: `${data.firstName || ""}${
        data.lastName ? " " + data.lastName : ""
      }`,
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      email: data.email || "",
      authToken: data.authToken || ""
    };

    this.setState(
      {
        partnerDetails,
        userDetails,
        currentStep: 1,
        isLoading: true,
        email: data.email
      },
      () => this.setState({ isLoading: false })
    );
  };

  setAccountSignUpMethod = value => {
    this.setState({ isThirdPartyAccountSignUp: value });
  };

  render() {
    const {
      currentStep,
      height,
      email,
      errorMessage,
      companyNameErrorMessage,
      emailErrorMessage,
      passwordMismatchError,
      confirmPasswordMismatchError,
      yearsExperience,
      partnerDetails,
      isLoading,
      selectedPartnerDetails,
      userDetails,
      selectPartnerDisabled,
      encryptedUserId,
      isThirdPartyAccountSignUp,
      partnerTypeImage,
      partnerType
    } = this.state;
    let type = getParamsByName("partnertype")
      ? getParamsByName("partnertype")
      : "";
    if (isLoading) return "";
    const { settings } = this.props;

    const partnerSignupWelcomeMessage = settings
      ? getKeyValueByObject(settings, SETTINGS_PARTNER_SIGNUP_WELCOME_MESSAGE)
      : "";

    const partnerSignupHeroImage =
      settings &&
      getKeyValueByObject(settings, SETTINGS_PARTNER_SIGNUP_HERO_IMAGE)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, SETTINGS_PARTNER_SIGNUP_HERO_IMAGE)
          )
        : "";
    const backgroundImage =
      settings && getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
          )
        : "";
    const marketplaceName = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_NAME)
      : "";

    const enableSalesforceLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_SALESFORCE_LOGIN)
      : "";

    const enableGoogleLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_GOOGLE_LOGIN)
      : "";

    const enableOffice365Login = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_OFFICE_365_LOGIN)
      : "";

    const enableLinkedInLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_LINKEDIN_LOGIN)
      : "";

    const linkedInClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_CLIENT_ID)
      : "";

    const linkedInRedirectUri = settings
      ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_REDIRECT_URI)
      : "";

    const googleClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_GOOGLE_CLIENT_ID)
      : "";

    const salesforceLoginClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_SALESFORCE_LOGIN_CLIENT_ID)
      : "";

    const salesforceLoginRedirectUrl = settings
      ? getKeyValueByObject(settings, SETTINGS_SALESFORCE_LOGIN_REDIRECT_URL)
      : "";
    let initialValues = {
      companyName:
        partnerDetails && partnerDetails.companyName
          ? {
              details: partnerDetails,
              value: partnerDetails.companyName,
              label: (
                <>
                  <span className="d-flex justify-content-start align-items-center">
                    <Avatar
                      firstName={partnerDetails.companyName}
                      // lastName={assignee.lastName}
                      url={partnerDetails.avatarUrl}
                      size="customSize"
                      imageSize={"20"}
                      fontSize={8}
                    ></Avatar>
                    <span style={{ marginLeft: "5px" }}>
                      {partnerDetails.companyName}
                    </span>
                  </span>
                </>
              )
            }
          : "",
      fullName: `${userDetails.firstName || ""}${
        userDetails.lastName ? " " + userDetails.lastName : ""
      }`,
      firstName: userDetails.firstName || "",
      lastName: userDetails.lastName || "",
      email: userDetails.email || "",
      password: "",
      confirmPassword: "",
      companyUrl: partnerDetails.companyUrl || "",
      reference: "",
      acceptTerms: "",
      phone: partnerDetails.phone || "",
      invitation: true,
      companyUrl: "",
      discoverable: false,
      domainSignup: false,
      approvedDomains: ""
    };

    return (
      <div
        className="container-fluid"
        style={{ height: height, minHeight: "100vh" }}
      >
        <div className="row overflow-hidden" style={{ height: "100%" }}>
          <LeftSideSection
            height={height}
            partnerSignupHeroImage={
              partnerType && partnerTypeImage
                ? `${partnerTypeImage}`
                : !partnerTypeImage
                ? `${partnerSignupHeroImage}`
                : partnerSignupHeroImage
            }
            backgroundImage={backgroundImage}
          />
          <div
            className="col"
            style={{ background: "#fff", overflowY: "scroll", height: "100%" }}
          >
            <div className="row find-an-expert-budget-right-side-section form-wrapper flex-column">
              {this._renderWizardIndicator()}
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  if (getCookie("googleAuthToken")) {
                    values.googleAuthToken = getCookie("googleAuthToken");
                  }
                  if (getCookie("office365AuthToken")) {
                    values.office365AuthToken = getCookie("office365AuthToken");
                  }
                  if (getCookie("linkedinAuthToken")) {
                    values.linkedinAuthToken = getCookie("linkedinAuthToken");
                  }
                  if (getCookie("salesforceAuthToken")) {
                    values.salesforceAuthToken = getCookie(
                      "salesforceAuthToken"
                    );
                  }
                  values.defaultPartnerType = type.replace("-", " ");
                  values.partnerId = getParamsByName("partnerId")
                    ? getParamsByName("partnerId")
                    : this.state.partnerId;
                  this._submit(values);
                }}
                onReset={() => {
                  this.setState({ confirmPasswordMismatchError: "" });
                  return this._next();
                }}
              >
                <GetStarted
                  step={1}
                  currentStep={currentStep}
                  partnerSignupWelcomeMessage={partnerSignupWelcomeMessage}
                  setUserDetails={data => this.setUserDetails(data)}
                  next={this._submit}
                  enableSalesforceLogin={enableSalesforceLogin}
                  enableOffice365Login={enableOffice365Login}
                  enableGoogleLogin={enableGoogleLogin}
                  enableLinkedInLogin={enableLinkedInLogin}
                  linkedInClientId={linkedInClientId}
                  linkedInRedirectUri={linkedInRedirectUri}
                  marketplaceName={marketplaceName}
                  emailErrorMessage={emailErrorMessage}
                  errorMessage={errorMessage}
                  passwordMismatchError={passwordMismatchError}
                  confirmPasswordMismatchError={confirmPasswordMismatchError}
                  handlePasswordErrors={this._handlePasswordErrors}
                  encryptedUserId={encryptedUserId}
                  googleClientId={googleClientId}
                  salesforceLoginClientId={salesforceLoginClientId}
                  salesforceLoginRedirectUrl={salesforceLoginRedirectUrl}
                  setAccountSignUpMethod={this.setAccountSignUpMethod}
                  partnerTypeWelcomeMessage={
                    this.state.partnerTypeWelcomeMessage
                  }
                  isThirdPartyAccountSignUp={
                    this.state.isThirdPartyAccountSignUp
                  }
                />

                <BasicInformation
                  step={2}
                  companyNameErrorMessage={companyNameErrorMessage}
                  emailErrorMessage={emailErrorMessage}
                  currentStep={currentStep}
                  errorMessage={errorMessage}
                  passwordMismatchError={passwordMismatchError}
                  confirmPasswordMismatchError={confirmPasswordMismatchError}
                  yearsExperience={yearsExperience}
                  partnerSignup={this.state.partnerSignup}
                  handlePasswordErrors={this._handlePasswordErrors}
                  next={this._submit}
                  prev={this._prev}
                  partnerDropdownChange={this.partnerDropdownChange}
                  selectedPartnerDetails={
                    selectedPartnerDetails || partnerDetails
                  }
                  selectPartnerDisabled={selectPartnerDisabled}
                />

                {!isThirdPartyAccountSignUp && (
                  <RequestReceived
                    step={3}
                    currentStep={currentStep}
                    close={this._close}
                    prev={this._prev}
                    marketplaceName={marketplaceName}
                    resendVerificationMail={this.handleResendVerificationMail}
                    email={email || (userDetails && userDetails.email) || ""}
                  />
                )}
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerSignUp;
