import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import blueMessageIcon from "../../assets/img/icons/icon-message.svg";
import TextArea from "../../components/base/TextArea";
import Text from "../../components/base/Text";

import Form from "../../components/base/Form";
import PartnerSelector from "../../partner/component/PartnerSelector";
import { PARTNER_PERMISSION_INDIVIDUAL_PARTNER } from "../../resource/Constants";

// Components
import Select from "../../components/base/Select";

import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

import { useSelector } from "react-redux";

const sendMessageToPartnerManager = props => {
  const {
    modal,
    toggle,
    sendMessageData,
    heading,
    buttonLabel,
    partnerDropdown,
    handleClose,
    id,
    permissionRequired,
    handlePartnerTierCheckbox,
    handlePartnerTypeCheckbox,
    handleChangePartnerSelection,
    handlePartnerPermission,
    tierSelectedOptions,
    typeSelectedOptions,
    showSubject,
    showEmailTemplate,
    EmailTemplateOptions,
    handleEmailTemplateChange,
    selectedEmailTemplate,
    message
  } = props;
  const currentUser = useSelector(state => state.user);

  return (
    <Modal
      id={id}
      isOpen={modal}
      toggle={toggle}
      className={["edit-task-modal"].join(" ")}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>
        <div className="content-wrapper">
          <div className="icon-wrapper">
            <div className="img-wrapper d-flex justify-content-center mb-2 mt-3">
              <img src={blueMessageIcon} size="30" alt="" />
            </div>
            <p className="text-center font-weight-bold mb-3">{heading}</p>
            <p className="text-center h6-5 mb-2"></p>
          </div>
        </div>
      </ModalHeader>
      <Form
        initialValues={{
          partner: "",
          message: message ? message : "",
          sharedPartner: "",
          contentType: "",
          subject: ""
        }}
        onSubmit={values => {
          values.contentType =
            values && values.contentType && values.contentType.value
              ? values.contentType.value
              : null;
          handleClose && handleClose();
          sendMessageData(values);
        }}
      >
        <ModalBody className="custom-modal-body">
          <div className="mt-2 mb-3">
            {partnerDropdown && (
              <div>
                {/* <Partner
                  id={id}
                  name="partner"
                  label="Partner"
                  error=""
                  required={true}
                  placeholder="Select Partner"
                  isSearchable={true}
                /> */}
                <PartnerSelector
                  showPartnerTier
                  showPartnerType
                  showAllPermission
                  tierTitle="Partner Tier"
                  typeTitle="Partner Type"
                  name="sharedPartner"
                  dropdownName="sharedPartner"
                  dropdownLabel="Send Message to"
                  handleTierChange={e => {
                    handlePartnerTierCheckbox(e);
                  }}
                  handleTypeChange={e => {
                    handlePartnerTypeCheckbox(e);
                  }}
                  handlePartnerChange={e => {
                    handleChangePartnerSelection(e);
                  }}
                  handlePermissionOnchange={e => {
                    handlePartnerPermission(e);
                  }}
                  tierSelectedOptions={tierSelectedOptions}
                  typeSelectedOptions={typeSelectedOptions}
                  permissionRequired={permissionRequired}
                  partnerPermissionDefaultValue={
                    PARTNER_PERMISSION_INDIVIDUAL_PARTNER
                  }
                />
              </div>
            )}
            {showEmailTemplate &&
              (isSuperAdmin(currentUser && currentUser.roleId) ||
                isCompanyAdmin(currentUser && currentUser.roleId) ||
                isCompanyManager(currentUser && currentUser.roleId)) && (
                <Select
                  name="EmailTemplate"
                  label="Template"
                  options={EmailTemplateOptions}
                  onInputChange={handleEmailTemplateChange}
                  placeholder="Select Template"
                  isClearable={true}
                />
              )}

            {showSubject && (
              <Text
                id={id}
                name="subject"
                label="Subject"
                value={
                  selectedEmailTemplate ? selectedEmailTemplate.subject : null
                }
                placeholder="Enter a Subject"
                error=""
                required={
                  selectedEmailTemplate && selectedEmailTemplate.subject
                    ? false
                    : true
                }
                disabled={
                  selectedEmailTemplate && selectedEmailTemplate.content
                    ? true
                    : false
                }
              />
            )}

            <div>
              <TextArea
                id={id}
                name="message"
                label="Message"
                placeholder="Message"
                value={
                  selectedEmailTemplate ? selectedEmailTemplate.content : null
                }
                rows={5}
                error=""
                required={selectedEmailTemplate ? false : true}
                disabled={
                  selectedEmailTemplate && selectedEmailTemplate.content
                    ? true
                    : false
                }
              />
            </div>
            {/* show attachment label */}
            {selectedEmailTemplate &&
              selectedEmailTemplate.attachmentFileName && (
                <span className="text-decoration-none">Attachment</span>
              )}
            {/* Show file Name */}
            <div>
              {selectedEmailTemplate &&
                selectedEmailTemplate.attachmentFileName && (
                  <a href={selectedEmailTemplate.files}>
                    {selectedEmailTemplate.attachmentFileName}
                  </a>
                )}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="container-fluid">
            <div className="col-sm-12 text-center">
              <Button
                id={id}
                type="submit"
                label=""
                className="h6-5-important"
                disabled={!modal}
              >
                {buttonLabel}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default sendMessageToPartnerManager;
