import React, { Component } from "react";
import ExpertProfileAboutMe from "./ExpertProfileAboutMe";
import ExpertProfileRecommendations from "./ExpertProfileRecommendations";
import ExpertProfileCertifications from "./ExpertProfileCertifications";
import ExpertProfileEmployments from "./ExpertProfileEmployments";
import ExpertProfileEducation from "./ExpertProfileEducation";
import ExpertProfileNotes from "./ExpertProfileNotes";
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import ExpertProfileSpecifications from "./ExpertProfileSpecifications";

import { connect } from "react-redux";

class ExpertProfileMain extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      id,
      aboutMe,
      certifications,
      employments,
      education,
      recommendations,
      specifications,
      notes
    } = this.props.expertDetails;

    return (
      <div className={["expert-profile-main", "ml-4", "w-100"].join(" ")}>
        <ExpertProfileAboutMe aboutMe={aboutMe} />

        <ExpertProfileRecommendations recommendations={recommendations} />

        <ExpertProfileCertifications certifications={certifications} />

        <ExpertProfileEmployments employments={employments} />

        <ExpertProfileEducation educations={education} />

        <ExpertProfileSpecifications specifications={specifications} />

        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) && (
          <ExpertProfileNotes notes={notes} />
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(ExpertProfileMain);
