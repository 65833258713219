import React from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink, Row, TabContent, TabPane } from "reactstrap";

import Page404 from "../../../Page404";
import toast from "../../../../components/base/Toast";
import AccountFields from "./inner-components/AccountFields";
import OpportunityListTable from "./inner-components/OpportunityListTable";

// Components
import Form from "../../../../components/base/Form";
import DeleteButton from "../../../../components/base/DeleteButton";
import CancelButton from "../../../../components/base/CancelButton";
import AdminActionNavigation from "../../../../components/base/AdminActionNavigation";
import { ChevronRight } from "../../../../assets/img/icons";
import { THEME_LAYOUT_FLUID_WIDTH } from "../../../../setting/Constants";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

// Helper
import {
  removeMaskedPhoneNumber,
  isSuperAdmin,
  isCompanyAdmin,
  getKeyValueByObject
} from "../../../../lib/helper";

// Constants
import {
  SETTINGS_SALESFORCE_OPPORTUNITY_AMOUNT_FIELD_NAME,
  SETTINGS_SALESFORCE_TERRITORY_FIELD_NAME
} from "../../../../setting/Constants";
import { isBadRequest } from "../../../../common/http";
import SideNavBar from "../../inner-component/SideNavBar";
import { TAB_SALESFORCE } from "../../../../supportPortal/Constants";
import { connect } from "react-redux";

class AccountDetails extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      activeTab: 1,
      isSearching: false,
      accountDetails: [],
      opportunityList: [],
      loading: false,
      isInvalidRequest: false
    };
  }

  componentDidMount = () => {
    this._getAccountDetails();
    this.getOpportunitiesList();
  };

  getOpportunitiesList = () => {
    this.setState({ isSearching: true }, () => {
      return apiClient
        .get(
          `${
            endpoints().salesforceLookupAPI
          }/opportunity/search?salesforceAccountId=${
            this.props.match.params.id
          }`
        )
        .then(response => {
          const { records } = response.data;

          this.setState({
            opportunityList: records || [],
            isSearching: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    });
  };

  // Account delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().salesforceLookupAPI}/account/${this.props.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;

          toast.success(successMessage);

          this.props.history.push("/salesforce/account");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Account details api call by id
  _getAccountDetails = () => {
    return apiClient
      .get(
        `${endpoints().salesforceLookupAPI}/account/${
          this.props.match.params.id
        }`
      )
      .then(response => {
        this.setState({ accountDetails: response.data, loading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  toggle = tab => {
    this.setState({
      activeTab: tab
    });
  };

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId)
    ) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return "";
    }

    const { activeTab, accountDetails, opportunityList } = this.state;

    const salesforceOpportunityAmountFieldName = this.props.settings
      ? getKeyValueByObject(
          this.props.settings,
          SETTINGS_SALESFORCE_OPPORTUNITY_AMOUNT_FIELD_NAME
        )
      : "";

    const salesforceTerritoryFieldName = this.props.settings
      ? getKeyValueByObject(
          this.props.settings,
          SETTINGS_SALESFORCE_TERRITORY_FIELD_NAME
        )
      : "";

    const {
      Id,
      Name,
      Phone,
      BillingCity,
      BillingState,
      BillingCountry,
      OwnerId,
      CreatedById,
      IsDeleted,
      Description,
      CreatedDate,
      LastModifiedDate
    } = accountDetails;

    const initialValues = {
      salesforceId: Id,
      name: Name,
      phone: Phone,
      billingCity: BillingCity,
      billingState: BillingState,
      billingCountry: BillingCountry,
      createdDate: CreatedDate,
      ownerId: OwnerId,
      createdById: CreatedById,
      isDeleted: IsDeleted,
      description: Description,
      lastModifiedDate: LastModifiedDate,
      territoryId: accountDetails[salesforceTerritoryFieldName]
    };

    return (
      <>
        <Row className="mt-n3 mt-md-n4 admin-setting">
          <div
            className={`${
              THEME_LAYOUT_FLUID_WIDTH
                ? " col-lg-3 col-sm-5 sidemenu p-0"
                : " secondary-menu col-md-3"
            }`}
          >
            <SideNavBar activeTab={TAB_SALESFORCE} handleClick={() => {}} />
          </div>
          <div className={`${THEME_LAYOUT_FLUID_WIDTH ? "col" : "col-md-9"}`}>
            <Form
              initialValues={initialValues}
              onSubmit={values => {
                values.phone =
                  values.phone && removeMaskedPhoneNumber(values.phone);
              }}
            >
              <AdminActionNavigation>
                <div className="d-flex align-items-center mt-3">
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      this.props.history.goBack(
                        "/support-portal/salesforce/account"
                      );
                    }}
                  >
                    Salesforce Account
                  </a>
                  <span
                    className="text-dark d-inline-flex align-items-center"
                    style={{ width: "15px" }}
                  >
                    <ChevronRight />
                  </span>
                  <span
                    onClick={e => e.preventDefault()}
                    className={[
                      "text-dark",
                      "font-weight-bold",
                      "d-inline-flex",
                      "align-items-center",
                      "project-name",
                      "w-320"
                    ].join(" ")}
                  >
                    <span className="ellipsis">{Id || "Account Details"}</span>
                  </span>
                </div>

                <CancelButton
                  className="ml-auto mr-1 mt-4"
                  onClick={() => this.props.history.goBack()}
                />

                <DeleteButton
                  className="mt-4"
                  label={"Delete Account"}
                  onClick={this.handleDelete}
                />
              </AdminActionNavigation>

              <Nav tabs className="admin-tabs">
                {/* Links */}
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 1
                    })}
                    onClick={() => {
                      this.toggle(1);
                    }}
                  >
                    General
                  </NavLink>
                </NavItem>
                {/* Partner Tier Tab */}
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === 2
                    })}
                    onClick={() => {
                      this.toggle(2);
                    }}
                  >
                    Opportunities
                  </NavLink>
                </NavItem>
              </Nav>
              <div className="tabs-and-preview full-width d-flex admin-settings">
                <TabContent activeTab={activeTab}>
                  {/* Links Tab */}
                  <TabPane tabId={1} className="w-100">
                    <AccountFields
                      accountDetails={this.state.accountDetails}
                      salesforceTerritoryFieldName={
                        salesforceTerritoryFieldName
                      }
                    />
                  </TabPane>
                  {/* Partner Tier */}
                  <TabPane tabId={2} className="w-100">
                    <OpportunityListTable
                      opportunityList={opportunityList}
                      salesforceOpportunityAmountFieldName={
                        salesforceOpportunityAmountFieldName
                      }
                    />
                  </TabPane>
                </TabContent>
              </div>
            </Form>
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(AccountDetails);
