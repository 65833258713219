import React, { useState } from "react";
import { COLOR_WHITE } from "../../common/color";
import detectBrowser from "../helpers/detectBrowser";

function Logo(props) {
  const {
    src,
    className,
    link,
    altText,
    label,
    headerTextColor,
    loginPage
  } = props;

  const [isMobile] = useState(detectBrowser());

  let styles = {};

  if (loginPage) {
    styles = { maxHeight: "32px", maxWidth: "85px", objectFit: "cover" };
  }
  if (isMobile && !loginPage) {
    styles = { maxHeight: "14px", maxWidth: "85px", objectFit: "cover" };
  } else {
    styles = { maxHeight: "45px", maxWidth: "185px", objectFit: "cover" };
  }

  return (
    <div>
      {src === "" || src === undefined ? (
        <div className={`${!isMobile ? "mx-5" : ""} text-center`}>
          <a
            href={link}
            style={{
              backgroundColor: "transparent",
              textDecoration: "none",
              color: headerTextColor ? headerTextColor : COLOR_WHITE
            }}
          >
            <h3>{label}</h3>
          </a>
        </div>
      ) : (
        <div
          className={`${!isMobile ? "mx-5" : ""} text-center`}
          style={{
            maxHeight: props.height,
            maxWidth: loginPage ? "85px" : "185px"
          }}
        >
          <a href={link}>
            <img src={src} className={className} alt={altText} style={styles} />
          </a>
        </div>
      )}
    </div>
  );
}
export default Logo;
