import React from "react";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";

// GA
import { gaEvent } from "../../lib/GoogleAnalytics";

// API call
import { apiClient } from "../../apiClient";

// Helper
import {
  toString,
  removeMaskedPhoneNumber,
  clearCookie,
  setCookie,
  getUrlParameter,
  validateStrongPassword,
  getKeyValueByObject,
  getSettingMediaUrl
} from "../../lib/helper";

// Components
import Form from "../../components/base/Form";

import LeftSideSection from "./LeftSideSection";
import BasicInformation from "./BasicInformation";
import { metaCXTrack } from "../../lib/metaCX";
import { METACX_EVENT_CUSTOMER_SIGNUP_SUCCESS } from "../../metaCXEvents/Constants";
import {
  COOKIE_SESSION_TOKEN,
  COOKIE_USER_ID,
  COOKIE_CUSTOMER_NAME,
  COOKIE_CUSTOMER_ID
} from "../../lib/cookie";

import {
  SETTINGS_CUSTOMER_SIGNUP_HERO_IMAGE,
  BACKGROUND_IMAGE_URL
} from "../../setting/Constants";
import { isBadRequest } from "../../common/http";
import { UNITED_STATES } from "../../Country/Constants";

class CustomerSignup extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      height: window.innerHeight,
      firstName: "",
      lastName: "",
      email: "",
      companyNameErrorMessage: "",
      emailErrorMessage: "",
      yearsExperience: "",
      yearsOfExperience: "",
      timezone: "",
      passwordToken: "",
      passwordMismatchError: "",
      confirmPasswordMismatchError: ""
    };

    // Bind new functions for next and previous
    this._submit = this._submit.bind(this);

    // Bind the update dimensions
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    this.getCountryDetails(UNITED_STATES);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  // Update the screen height
  updateDimensions() {
    this.setState({
      height: window.innerHeight
    });
  }

  // _submit functions will be called on button click
  _submit(values) {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save "Basic Information" form of expert
    this._userSignUp(this._toArray(values));
  }

  // Get Country Details
  getCountryDetails = countryName => {
    return apiClient
      .get(`${endpoints().countryAPI}/${countryName}`)
      .then(response => {
        const data = response.data;
        this.setState({
          selectedCountry: data.id
        });
      });
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    const firstName = values.firstName;
    const lastName = values.lastName;
    const email = values.email;
    const password = values.password;
    const confirmPassword = values.confirmPassword;

    const passwordError = validateStrongPassword(password);
    const authToken = getUrlParameter("authToken");

    if (!authToken) {
      if (firstName == null || lastName == null || email == null) {
        success = false;
      } else if (passwordError) {
        this.setState({
          passwordMismatchError: passwordError
        });
        success = false;
      } else if (password && confirmPassword && password !== confirmPassword) {
        this.setState({
          passwordMismatchError: "",
          confirmPasswordMismatchError: "Confirm password did not match"
        });
        success = false;
      } else {
        this.setState({
          passwordMismatchError: "",
          confirmPasswordMismatchError: ""
        });
      }
    }

    return success;
  }

  // Handle password error messages
  _handlePasswordErrors = e => {
    const password = e.values.password;
    const confirmPassword = e.values.confirmPassword;
    const passwordError = validateStrongPassword(password);
    if (passwordError) {
      this.setState({
        passwordMismatchError: passwordError
      });
    } else if (password && confirmPassword && password !== confirmPassword) {
      this.setState({
        passwordMismatchError: "",
        confirmPasswordMismatchError: "Confirm password did not match"
      });
    } else {
      this.setState({
        passwordMismatchError: "",
        confirmPasswordMismatchError: ""
      });
    }
  };

  // To Array
  _toArray(data) {
    let values = { ...data };

    const authToken = getUrlParameter("authToken");

    if (authToken) {
      values.authToken = authToken;
    }
    values.firstName = toString(values.firstName);
    values.lastName = toString(values.lastName);
    values.companyName = toString(values.companyName);
    values.email = toString(values.email);
    values.password = toString(values.password);
    values.phone = toString(
      values.phone && removeMaskedPhoneNumber(values.phone)
    );
    values.address1 = toString(values.address1);
    values.postalCode = toString(values.postalCode);
    values.countryId = toString(values.countryId);
    values.countryName = toString(
      values.country ? values.country.label : values.country
    );
    values.city = toString(values.city);
    values.stateId = toString(values.stateId);
    values.stateName = toString(
      values.state ? values.state.value : values.state
    );
    values.timeZone = toString(
      values.timezone ? values.timezone.value : values.timezone
    );
    values.acceptTerms = values.acceptTerms ? "Yes" : "No";

    return values;
  }

  // User SignUp API Call
  _userSignUp(data) {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

    return apiClient
      .post(endpoints().publicUserAPI, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        this.setState({
          companyNameErrorMessage: "",
          emailErrorMessage: ""
        });

        // Remove Local Storage
        clearCookie("googleAuthToken");
        clearCookie("office365AuthToken");
        clearCookie("linkedinAuthToken");
        clearCookie("salesforceAuthToken");

        const {
          token,
          role,
          userId,
          firstName,
          lastName,
          customerId,
          customerName
        } = response.data;

        // Set Logged in user Local Storage
        setCookie(COOKIE_SESSION_TOKEN, token);
        setCookie(COOKIE_USER_ID, userId);
        setCookie(COOKIE_CUSTOMER_ID, `${customerId}`);
        setCookie(COOKIE_CUSTOMER_NAME, customerName);

        const { settings } = this.props;

        metaCXTrack(
          METACX_EVENT_CUSTOMER_SIGNUP_SUCCESS,
          {
            firstName,
            lastName
          },
          settings,
          () => {}
        );

        // GA Event
        gaEvent({
          category: "Customer Signup",
          action: "Clicked on customer signup",
          label: "Customer Signup",
          value: customerId
        });

        const redirect = getUrlParameter("redirect");
        const selectedCartType = getUrlParameter("selectedCartType");
        const redirectUrl =
          redirect && `${redirect}?selectedCartType=${selectedCartType}`;

        // Redirect URL
        if (redirectUrl) {
          window.location.replace(redirectUrl);
        } else {
          window.location.replace("/dashboard");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          const isCompanyNameExitsError =
            errorMessage === "Company name already Exists" ? errorMessage : "";
          const isEmailExitsError =
            errorMessage === "Email already Exists" ? errorMessage : "";

          this.setState({
            companyNameErrorMessage: isCompanyNameExitsError,
            emailErrorMessage: isEmailExitsError
          });
        }
      });
  }

  // Reset the message
  _hideErrorMessage = () => {
    this.setState({ companyNameErrorMessage: "", emailErrorMessage: "" });
  };

  render() {
    const {
      height,
      companyNameErrorMessage,
      emailErrorMessage,
      passwordMismatchError,
      confirmPasswordMismatchError,
      selectedCountry
    } = this.state;

    const { settings } = this.props;

    const marketplaceLogo =
      settings &&
      getKeyValueByObject(settings, SETTINGS_CUSTOMER_SIGNUP_HERO_IMAGE)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, SETTINGS_CUSTOMER_SIGNUP_HERO_IMAGE)
          )
        : "";
    const backgroundImage =
      settings && getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
          )
        : "";

    const authToken = getUrlParameter("authToken");

    let initialValues = {
      firstName: "" || getUrlParameter("firstName"),
      lastName: "" || getUrlParameter("lastName"),
      companyName: "",
      email: "" || getUrlParameter("email"),
      password: "",
      confirmPassword: "",
      yearsExperience: "",
      timezone: "",
      acceptTerms: "",
      address1: "",
      postalCode: "",
      country: {
        value: UNITED_STATES,
        label: UNITED_STATES
      },
      state: "",
      city: "",
      selectedCountry: "",
      phone: ""
    };

    return (
      <div
        className="container-fluid"
        style={{ height: height, minHeight: "100vh", overflowY: "scroll" }}
      >
        <div className="row overflow-hidden" style={{ height: "100%" }}>
          <LeftSideSection
            height={height}
            marketplaceLogo={marketplaceLogo}
            backgroundImage={backgroundImage}
          />
          <div
            className="col"
            style={{ background: "#fff", overflowY: "scroll", height: "100%" }}
          >
            <div className="row find-an-expert-budget-right-side-section form-wrapper flex-column">
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  this._submit(values);
                }}
              >
                <BasicInformation
                  companyNameErrorMessage={companyNameErrorMessage}
                  authToken={authToken}
                  emailErrorMessage={emailErrorMessage}
                  hideErrorMessage={this._hideErrorMessage}
                  next={this._submit}
                  prev={() => this.props.history.goBack()}
                  passwordMismatchError={passwordMismatchError}
                  confirmPasswordMismatchError={confirmPasswordMismatchError}
                  handlePasswordErrors={this._handlePasswordErrors}
                  selectedCountry={selectedCountry}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerSignup;
