import React from "react";
import toast from "../../components/base/Toast";

// Components
import Form from "../../components/base/Form";
import CancelButton from "../../components/base/CancelButton";
import PageTitle from "../../components/base/PageTitle";
import AdminActionNavigation from "../../components/base/AdminActionNavigation";
import Spinner from "../../components/base/Spinner";
import SideBar from "../leads/inner-components/SideBar";
import PartnerAcceptLeadAlert from "../../components/PartnerAcceptLeadAlert";
import SaveButton from "../../components/base/SaveButton";

// 404 Page Redirect
import Page404 from "../Page404";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient, apiClientGet } from "../../apiClient";

// Helper

// Page Components
import PlaybookInquiryForm from "./PlaybookInquiryForm";

//Assets
import { ChevronRight } from "../../assets/img/icons";

// Helper
import { isPartner } from "../../lib/helper";
import { LEAD_PARTNER_STATUS_SHORTLISTED } from "../../lead/Constants";
import { isBadRequest } from "../../common/http";
import { connect } from "react-redux";

class PlaybookInquiryDetails extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      isLoading: true,
      playbookInquiryDetails: "",
      leadId: this.props.match.params.id,
      isLeadUpdate: false,
      leadPartnerUser: "",
      isPageNotFound: false
    };
  }

  getPlaybookInquiryDetails = () => {
    this.setState({ isLoading: true });
    apiClientGet(
      `${endpoints().lead}/${this.props.match.params.id}`,
      (errMessage, response) => {
        if (errMessage) {
          this.setState({
            isPageNotFound: true,
            isLoading: false
          });
        } else {
          const playbookInquiryDetails = response && response.data;
          this.setState({
            playbookInquiryDetails,
            leadPartnerUser:
              playbookInquiryDetails && playbookInquiryDetails.leadPartnerUser,
            isLoading: false
          });
        }
      }
    );
  };

  componentDidMount() {
    this.getPlaybookInquiryDetails();
  }

  _submit = values => {
    values.playbookId = this.state.playbookInquiryDetails.playbookId;
    values.status = this.state.playbookInquiryDetails.status;

    apiClient
      .put(`${endpoints().lead}/${this.props.match.params.id}`, values)
      .then(response => {
        toast.success(response.data.message);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(errorMessage);
        }
      });
  };

  handleCancelButton = () => {
    this.props.history.goBack();
  };

  updatePartnerLeadStatus = (status, leadId) => {
    return apiClient
      .put(
        `${
          endpoints().leadPartnerAPI
        }/updateStatus?leadId=${leadId}&status=${status}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);

          this.getPlaybookInquiryDetails(this.props.match.params.id);
          this.setState({ isLeadUpdate: true });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };

  render() {
    const {
      playbookInquiryDetails,
      isLoading,
      leadPartnerUser,
      isPageNotFound
    } = this.state;

    if (isLoading) return <Spinner />;

    if (isPageNotFound) return <Page404 />;

    const initialValues = {
      name: (playbookInquiryDetails && playbookInquiryDetails.name) || "",
      email: (playbookInquiryDetails && playbookInquiryDetails.email) || "",
      phone: (playbookInquiryDetails && playbookInquiryDetails.phone) || "",
      companyName:
        (playbookInquiryDetails && playbookInquiryDetails.companyName) || "",
      notes: (playbookInquiryDetails && playbookInquiryDetails.notes) || ""
    };

    return (
      <>
        <React.Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={values => {
              this._submit(values);
            }}
          >
            <AdminActionNavigation>
              <div className="d-flex align-items-center mb-3">
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    this.props.history.push("/leads?section=New");
                  }}
                >
                  Leads
                </a>
                <span
                  className="text-dark d-inline-flex align-items-center"
                  style={{ width: "15px" }}
                >
                  <ChevronRight />
                </span>
                <span
                  onClick={e => e.preventDefault()}
                  className={[
                    "text-dark",
                    "font-weight-bold",
                    "d-inline-flex",
                    "align-items-center",
                    "project-name",
                    "w-320"
                  ].join(" ")}
                >
                  <span className="ellipsis">Edit Playbook Inquiry</span>
                </span>
              </div>
              <CancelButton
                className="ml-auto mr-1"
                onClick={this.handleCancelButton}
              />
              <SaveButton />
            </AdminActionNavigation>

            <PageTitle label="Edit Playbook Inquiry" />
            {isPartner(this.props.roleId) &&
              leadPartnerUser &&
              leadPartnerUser.status === LEAD_PARTNER_STATUS_SHORTLISTED && (
                <PartnerAcceptLeadAlert
                  updatePartnerLeadStatus={this.updatePartnerLeadStatus}
                  leadDetails={playbookInquiryDetails}
                />
              )}
            <div className="card border-0">
              <div className="card-body">
                <div className="project-details-body">
                  <div className="details-body-main">
                    <PlaybookInquiryForm />
                  </div>
                  {/* Sidebar */}
                  <SideBar
                    leadDetails={playbookInquiryDetails}
                    leadId={this.state.leadId}
                    isLeadUpdate={this.state.isLeadUpdate}
                    status={leadPartnerUser}
                  />
                </div>
              </div>
            </div>
          </Form>
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(PlaybookInquiryDetails);
