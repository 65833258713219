import React, { Component } from "react";
import KickoffContext from "./KickoffContext";
import { apiClient } from "../../apiClient";

class KickoffProvider extends Component {
  constructor(props) {
    super(props);

    this.getKickoffTimes();
  }

  state = {
    kickoffData: null
  };

  getKickoffTimes = () => {
    if (this.props.expertId) {
      return apiClient
        .get(
          `/v1/project/${this.props.projectId}/kickoff/${this.props.expertId}`
        )
        .then(res => this.setState({ kickoffData: res.data.data }))
        .catch(err => console.error(err));
    }
    if (this.props.isCampaign) {
      return apiClient
        .get(`/v1/project/${this.props.projectId}/kickoff/partner`)
        .then(res => this.setState({ kickoffData: res.data.data }))
        .catch(err => console.error(err));
    }
  };

  render() {
    return (
      <KickoffContext.Provider
        value={{
          ...this.state,
          getKickoffTimes: this.getKickoffTimes
        }}
      >
        {this.props.children}
      </KickoffContext.Provider>
    );
  }
}

export default KickoffProvider;
