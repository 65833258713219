import React from "react";

// Constants
import { TIMEZONE_LIST } from "../timezoneList/Constants";

// Components
import Select from "./base/Select";

class KickoffTimeZone extends React.Component {
  render() {
    const timeZoneOptions = TIMEZONE_LIST;

    const {
      name,
      label,
      placeholder,
      error,
      required,
      defaultValue,
      onChange,
      isDisabled,
      id
    } = this.props;

    return (
      <Select
        id={id || name}
        name={name}
        label={label}
        options={timeZoneOptions}
        placeholder={placeholder}
        error={error}
        required={required}
        defaultValue={TIMEZONE_LIST.find(type => type.value === defaultValue)}
        onInputChange={onChange}
        isDisabled={isDisabled}
        isSearchable={true}
      />
    );
  }
}

export default KickoffTimeZone;
