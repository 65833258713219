import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Assets
import { HelpGraphicIcon, InlineInfoIcon } from "../../assets/img/icons";

// Components
import Form from "../../components/base/Form";
import Email from "../../components/base/Email";
import Select from "../../components/base/Select";

const methodofCapture = [
  {
    value: "email",
    label: "Email"
  },
  {
    value: "phone",
    label: "Phone"
  }
];

const HelpModal = props => {
  const { label, className } = props;

  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  return (
    <div className="help-modal">
      <a
        onClick={toggle}
        href="#"
        className="text-link d-flex align-items-center justify-content-start"
      >
        <InlineInfoIcon />
        {label}
      </a>
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader toggle={toggle}>
          <div className="modal-header-wrapper d-flex flex-column align-items-center">
            <HelpGraphicIcon />
            <h5 className="mb-0 mt-3">
              <b>Help</b>
            </h5>
            <p className="h6-5 text-center mb-0 mt-3">
              Enter your email address or phone number and we’ll have someone
              walk through this with you.
            </p>
          </div>
        </ModalHeader>
        <ModalBody className="px-0">
          <div className="form-wrapper">
            <Form>
              <div className="field-wrapper">
                <Select
                  name="capture_method"
                  label="Preferred Method of Contact"
                  placeholder="Select Method"
                  options={methodofCapture}
                  isSearchable={true}
                  required
                />
              </div>
              <div className="field-wrapper">
                <Email
                  name="email"
                  label="Your Email Address"
                  placeholder="Enter email"
                  required={true}
                />
              </div>
            </Form>
          </div>
          {/* /.form-wrapper */}
        </ModalBody>
        <ModalFooter className="justify-content-center">
          <Button color="primary" onClick={toggle}>
            Get Help
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default HelpModal;
