import React from "react";
import { Col, Row } from "reactstrap";
// import _ from "lodash";

// Page components
import NoRecordsFound from "../../components/base/NoRecordsFound";
import CourseCardGrid from "./inner-component/CourseCardGrid";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isPartner,
  isCompanyManager
} from "../../lib/helper";

// Components
import PageSearch from "../../components/base/PageSearch";
import Spinner from "../../components/base/Spinner";
import Pagination from "../../components/base/Pagination";
import SelectDropdown from "../../components/base/SelectDropdown";

// Asset
import trainingIcon from "../../assets/img/icons/nav-icon-course.svg";

// 404 Page Redirect
import Page404 from "../Page404";

import { connect } from "react-redux";
import PageSize from "../../components/PageSize";

class CourseCardView extends React.Component {
  state = {
    isGrid: true,
    searchParam: "",
    status: "",
    isLoading: false,
    currentPage: 1,
    pageSize: 12,
    sort: "",
    sortDir: "",
    selectedSortOption: "Recently Updated",
    sortByOptions: [
      {
        value: "updatedAt:DESC",
        label: "Recently Updated"
      },
      {
        value: "title:ASC",
        label: "Title"
      }
    ]
  };

  componentDidMount() {
    this.setState({ currentPage: 1, isLoading: true }, () => {
      this.props.actions
        .fetchCourseList(
          this.updateUrl({
            status: this.props.activeTab,
            pageSize: this.state.pageSize,
            page: 1,
            sort: this.state.sort || "updatedAt",
            sortDir: this.state.sort || "DESC"
          })
        )
        .then(() => {
          this.setState({
            isLoading: false
          });
        });
    });
    this.pushQueryToUrl();
  }

  componentDidUpdate(prevProps) {
    if (prevProps && prevProps.activeTab !== this.props.activeTab) {
      this.setState({ currentPage: 1, isLoading: true });
      this.props.actions
        .fetchCourseList(
          this.updateUrl({
            status: this.props.activeTab,
            pageSize: this.state.pageSize,
            page: 1,
            sort: this.state.sort,
            sortDir: this.state.sortDir
          })
        )
        .then(() => {
          this.setState({
            isLoading: false
          });
        });
    }
  }
  // Search Query string
  pushQueryToUrl = () => {
    const { searchParam, sort, sortDir, pageSize } = this.state;
    const { activeTab } = this.props;
    const params = [];

    if (searchParam) {
      params.push(`search=${searchParam}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }
    this.props.history.push(`/course?section=${activeTab}&${params.join("&")}`);
  };

  // Search resource
  _handleInputChange = e => {
    this.setState(
      {
        searchParam: e.target.value,
        isLoading: true
      },
      () => {
        const { searchParam } = this.state;
        // const sort = getParamsByName("sort") || "";
        // const sortDir = getParamsByName("sortDir") || "";
        const params = {
          status: this.props.activeTab,
          search: encodeURIComponent(searchParam),
          pageSize: this.state.pageSize,
          sort: this.state.sort,
          sortDir: this.state.sortDir
        };
        this.pushQueryToUrl();

        this.props.actions.fetchCourseList(this.updateUrl(params)).then(() => {
          this.setState({
            isLoading: false
          });
        });
      }
    );
  };

  // Search playbook by status
  _handleStatusChange(searchStatus) {
    this.setState({ status: searchStatus, activeTab: searchStatus }, () => {
      this.props.actions.fetchCourseList(
        this.updateUrl({
          status: searchStatus,
          sort: this.state.sort,
          sortDir: this.state.sortDir,
          page: this.state.currentPage,
          pageSize: this.state.pageSize
        })
      );
    });
  }

  // Form URL
  updateUrl(data) {
    const { search, page, pageSize, sort, sortDir, status } = data;

    const params = [];

    if (search) {
      params.push(`search=${search}`);
    }

    if (status) {
      params.push(`status=${status}`);
    }

    if (page) {
      params.push(`page=${page}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    if (params.length > 0) {
      return params.join("&");
    }
  }

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  handleSortByChange = value => {
    const valueArray = this.getSortValueFromLabel(value).split(":");
    const sortBy = valueArray[0];
    const sortDir = valueArray[1];
    this.setState(
      {
        sort: sortBy,
        sortDir: sortDir,
        currentPage: 1,
        isLoading: true
      },
      () => {
        const {
          searchParam,
          sort,
          sortDir,
          currentPage,
          pageSize
        } = this.state;
        this.pushQueryToUrl();

        this.props.actions
          .fetchCourseList(
            this.updateUrl({
              status: this.props.activeTab,
              sort: sort,
              sortDir: sortDir,
              page: currentPage,
              pageSize: pageSize,
              search: searchParam
            })
          )
          .then(() => {
            this.setState({
              isLoading: false
            });
          });
      }
    );
  };

  viewChange = () => {
    this.setState({ isGrid: true });
  };
  // Handle Page Change
  handlePageChange = page => {
    this.setState(
      {
        currentPage: page
      },
      () => {
        const { actions, course } = this.props;

        if (
          !(page in course.pages) ||
          course.statuses[page] !== this.props.activeTab
        ) {
          actions.fetchCourseList(
            this.updateUrl({
              sort: this.state.sort,
              sortDir: this.state.sortDir,
              status: this.props.activeTab,
              page: page,
              pageSize: this.state.pageSize
            })
          );
        } else {
          actions.changePlaybookPage(page);
        }
      }
    );
  };

  handlePageSizeChange = e => {
    this.setState(
      {
        pageSize: e,
        currentPage: this.state.currentPage
      },
      () => {
        const { actions, course } = this.props;
        this.pushQueryToUrl();
        actions.fetchCourseList(
          this.updateUrl({
            sort: this.state.sort,
            sortDir: this.state.sortDir,
            status: this.props.activeTab,
            page: this.state.currentPage,
            pageSize: this.state.pageSize
          })
        );
      }
    );
  };

  render() {
    // Props
    const {
      history,
      course,
      courseDetails,
      activeTab,
      DefaultLayout
    } = this.props;

    // State
    const {
      isGrid,
      searchParam,
      currentPage,
      pageSize,
      selectedSortOption,
      sortByOptions,
      isLoading
    } = this.state;

    let courseList;
    let pagination;
    let totalCount;
    if (course && course.pagination) {
      pagination = course.pagination;
      courseList = course.pages[pagination.currentPage];
      totalCount = pagination.totalCount;
    }

    const params = {
      page: currentPage,
      pageSize: pageSize,
      status: activeTab,
      sort: selectedSortOption
    };

    const dataArray = [];

    const adminPlaybookDetails = courseDetails || {};
    Object.keys(courseList || {}).forEach(key => {
      const data = adminPlaybookDetails[courseList[key]];
      if (data) {
        dataArray.push(data);
      }
    });

    let startPage = 1;
    let endPage = "";

    startPage = (currentPage - 1) * pageSize;
    startPage = startPage > totalCount ? totalCount : startPage;

    endPage = currentPage * pageSize;
    endPage = endPage > totalCount ? totalCount : endPage;

    const courses = totalCount > 0 ? dataArray : dataArray;

    // Return Page 404
    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId) &&
      !isCompanyManager(this.props.roleId) &&
      !isPartner(this.props.roleId)
    ) {
      return <Page404 />;
    }

    return (
      <div>
        <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column">
          <div className="mr-1">
            <PageSize onChange={e => this.handlePageSizeChange(e)} />
          </div>
          <PageSearch
            classnames="page-search"
            placeholder="Search"
            onChange={this._handleInputChange.bind(this)}
            value={this.state.searchParam}
          />

          <SelectDropdown
            buttonLabel={selectedSortOption}
            dropdownLinks={sortByOptions}
            color={"gray"}
            hideCaret
            selectName={"sortby_partners"}
            handleChange={this.handleSortByChange}
          />
        </div>

        {/* Card Grid View */}
        <div className={DefaultLayout !== "Fluid Width" ? "container" : ""}>
          {isLoading ? (
            <Spinner />
          ) : totalCount ? (
            isGrid ? (
              <>
                <CourseCardGrid
                  history={history}
                  courses={courses}
                  params={params}
                />

                {/* Show the Pagination */}
                {totalCount > 0 && (
                  <Row>
                    <Col>
                      Showing {startPage + 1} to {endPage} of {totalCount}{" "}
                      entries
                    </Col>
                    <Col>
                      <Pagination
                        currentPage={currentPage}
                        totalCount={totalCount}
                        pageSize={pageSize}
                        onPageChange={this.handlePageChange}
                      />
                    </Col>
                  </Row>
                )}
              </>
            ) : (
              ""
            )
          ) : searchParam ? (
            !totalCount ? (
              <NoRecordsFound
                hideCard={true}
                icon={trainingIcon}
                boldMessage={
                  isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)
                    ? `Course is where you can select content and materials to share with Partners.`
                    : `No Courses have been shared with you yet.`
                }
                description={
                  isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)
                    ? `Get started by selecting “Add Courses”.`
                    : `This is where content and materials shared with you will be available.`
                }
              />
            ) : (
              ""
            )
          ) : (
            <NoRecordsFound
              hideCard={true}
              icon={trainingIcon}
              boldMessage={
                isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)
                  ? `Course is where you can select content and materials to share with Partners.`
                  : `No Courses have been shared with you yet.`
              }
              description={
                isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)
                  ? `Get started by selecting “Add Courses”.`
                  : `This is where content and materials shared with you will be available.`
              }
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(CourseCardView);
