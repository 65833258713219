import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";
import { isLoggedIn } from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import AddButton from "../../components/base/AddButton";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

export default class TagType extends Component {
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    return (
      <div>
        <PageTitle label="Tag types" />

        <AddButton
          label="New Tag Type"
          targetUrl="/settings/tagtype/add"
          className="pull-right btn btn-secondary"
        />

        <div>
          <ReduxTable
            id="tagTypes"
            apiURL={`${endpoints().tagTypeAPI}`}
            onRowClick={row =>
              this.props.history.push(`/settings/tagtype/${row.id}`)
            }
          >
            <ReduxColumn type="link" isClickable="true" field="name">
              Name
            </ReduxColumn>
            <ReduxColumn field="createdAt">Created At</ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}
