import React, { Component } from "react";

// Components
import Text from "../../../components/base/Text";
import Email from "../../../components/base/Email";
import Phone from "../../../components/base/Phone";
import URL from "../../../components/base/URL";
import Select from "../../../components/Select";
class AccountFormFields extends Component {
  // Render the form fields
  render() {
    return (
      <div>
        <div>
          <Text
            name="companyName"
            label="Account Name"
            placeholder="Enter Account Name"
            error=""
            required={true}
          />
        </div>
        <div>
          <URL name="website" label="Website" placeholder="Enter Website" />
        </div>
        <div>
          <Text name="title" label="Title" placeholder="Enter Title" />
        </div>
        <div>
          <Text
            name="firstName"
            label="First Name"
            placeholder="Enter First Name"
          />
        </div>
        <div>
          <Text
            name="lastName"
            label="Last Name"
            placeholder="Enter Last Name"
          />
        </div>
        <div>
          <Email
            name="email"
            label="Email"
            placeholder="Enter Email"
            error=""
            required={true}
          />
        </div>
        <div>
          <Phone
            name="phone"
            label="Phone Number"
            placeholder="Enter phone number"
          />
        </div>
        <div>
          <Select
            name="type"
            label="Type"
            placeholder="Enter Type"
            options={this.props.typeOptions}
          />
        </div>
      </div>
    );
  }
}

export default AccountFormFields;
