import React, { useEffect } from "react";
import { EditIconAlt, TrashIconAlt } from "../assets/img/icons";
import Avatar from "./base/Avatar";
import LazyLoad, { forceVisible } from "react-lazyload";

const SimpleUserCard = props => {
  const {
    data,
    userDetails,
    currentUserId,
    handleDelete,
    handleEdit,
    showEditOptions
  } = props;
  let id, avatarUrl, firstName, lastName, email;

  if (data) {
    if (data.id) id = data.id;
    if (data.first_name) firstName = data.firstName;
    if (data.last_name) lastName = data.lastName;
    if (data.avatarUrl) avatarUrl = data.avatarUrl;
    if (data.email) email = data.email;
  }
  if (userDetails) {
    if (userDetails.id) id = userDetails.id;
    if (userDetails.first_name) firstName = userDetails.first_name;
    if (userDetails.last_name) lastName = userDetails.last_name;
    if (userDetails.avatarUrl) avatarUrl = userDetails.avatarUrl;
    if (userDetails.email) email = userDetails.email;
  }
  useEffect(() => {
    forceVisible();
  }, []);

  return (
    <div className={["simple-expert-card"].join(" ")}>
      <div
        className={[
          "card-header",
          "bg-transparent",
          "d-flex justify-content-between"
        ].join(" ")}
      >
        <span className="d-flex mb-0 p-0">
          <div
            className={["user-and-title", "d-flex", "mb-0", "mt-1"].join(" ")}
          >
            <div className={["mr-2"].join(" ")}>
              <LazyLoad>
                <Avatar
                  url={avatarUrl}
                  firstName={firstName}
                  lastName={lastName}
                  size="xs"
                />
              </LazyLoad>
            </div>
            <div className={["simple-user-card"].join(" ")}>
              <div className={["h6", "m-0", "font-weight-bold"].join(" ")}>
                {currentUserId && currentUserId === id ? (
                  "You"
                ) : (
                  <>
                    {firstName} {lastName}
                  </>
                )}
              </div>
              <span
                className={["text-inline-grayed", "h6", "mb-0", "p-0"].join(
                  " "
                )}
              >
                <p> {email}</p>
              </span>
            </div>
          </div>
        </span>
      </div>
      {showEditOptions && (
        <div className={["d-flex justify-content-end", "mt-3"].join(" ")}>
          <span
            onClick={handleEdit}
            className={["d-block", "mr-2", "cursor-pointer"].join(" ")}
          >
            <EditIconAlt />
          </span>
          <span
            onClick={handleDelete}
            className={["d-block", "ml-2", "cursor-pointer"].join(" ")}
          >
            <TrashIconAlt />
          </span>
        </div>
      )}
    </div>
  );
};

export default SimpleUserCard;
