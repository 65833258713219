import React from "react";

// Components
import SourcePartners from "./SourcePartners";

// Helper
import { getUserId } from "../../../lib/helper";

const SideBar = props => {
  return (
    <div className="details-body-sidebar">
      {/* Source Partner */}
      <SourcePartners
        status={props.status}
        leadId={props.leadId}
        currentUserId={getUserId()}
        isLeadUpdate={props.isLeadUpdate}
        leadDetails={props.leadDetails}
      />
    </div>
  );
};

export default SideBar;
