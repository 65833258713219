import React from "react";
import AddInvoiceForm from "./AddInvoiceForm";

// Components
import PageTitle from "../../components/base/PageTitle";

const AddInvoice = props => {
  return (
    <div>
      <PageTitle label="Add Invoice" />
      <AddInvoiceForm history={props.history} />
    </div>
  );
};

export default AddInvoice;
