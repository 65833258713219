import React from "react";

// Components
import { InlineInfoIcon } from "../../../../assets/img/icons";
import AlertBanner from "../../../../components/base/AlertBanner";

const PartnerAccountToggle = props => {
  const { setPartnerToggleValues } = props;
  return (
    <>
      <AlertBanner
        icon={<InlineInfoIcon />}
        buttonLabel="Check Partner account in Salesforce"
        buttonOnClick={() => {
          setPartnerToggleValues({
            isNoPartnerAccountModalOpen: true,
            isNoPartnerAccountFoundInSalesforce: true,
            accountType: "Account (Partner)"
          });
        }}
        removeOnClick={() => {
          setPartnerToggleValues({ isNoPartnerAccountModalOpen: false });
        }}
        message="Partner Account was not found. Do a check for partial matches."
      />
    </>
  );
};

export default PartnerAccountToggle;
