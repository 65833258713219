import React from "react";

// Icons
import { InfoIcon } from "../../assets/img/icons";

// Components
import Text from "../../components/base/Text";

// Helper
import PartnerSelect from "../../components/PartnerSelect";
import URL from "../../components/base/URL";
import SingleCheckbox from "../../components/base/SingleCheckbox";
import Avatar from "../../components/base/Avatar";

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptTerms: false,
      required: false
    };
  }

  render() {
    if (this.props.currentStep !== this.props.step) {
      // Prop: The current step
      return null;
    }

    const { required } = this.state;

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <InfoIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-3">
            Company Information
          </h2>
        </div>
        <div className="content">
          {!this.props.selectPartnerDisabled && (
            <div className="field-wrapper">
              <PartnerSelect
                name="companyName"
                label="Company Name"
                placeholder="Enter company name"
                error={this.props.companyNameErrorMessage}
                onKeyDown={this.props.hideErrorMessage}
                {...{ required }}
                onchange={this.props.partnerDropdownChange}
                disabled={this.props.selectPartnerDisabled}
              />
            </div>
          )}

          {!this.props.selectedPartnerDetails ? (
            <>
              <div className="field-wrapper">
                <URL
                  name="companyUrl"
                  label="Company URL"
                  placeholder="Enter Company URL*"
                  error=""
                  {...{ required }}
                />
              </div>

              <h5 className="font-weight-bold">Discoverable</h5>
              <div className="field-wrapper position-relative">
                <SingleCheckbox
                  name="discoverable"
                  label="Allow other companies to see that you’re on Torchlite and send partnership requests."
                  className={"validation accepted-terms mr-3"}
                />
              </div>

              <h5 className="font-weight-bold mt-2">
                Choose How People Can Join Your Team
              </h5>
              <div className="field-wrapper position-relative">
                <SingleCheckbox
                  name="invitation"
                  label="By invitation *"
                  required
                  disabled
                  className={"validation accepted-terms mr-3"}
                />
              </div>
              <div className="field-wrapper position-relative">
                <SingleCheckbox
                  name="domainSignup"
                  label="By signing up with an email address from an approved domain"
                  className={"validation accepted-terms mr-3"}
                />
              </div>
              <div className="field-wrapper ml-4">
                <Text name="approvedDomains" placeholder="Enter Domains" />
              </div>
              <p className="text-grayed h7 mt-1 ml-4">
                If you want to add more than one domain, separate each one with
                a comma.
              </p>

              <div className="btn-wrapper mt-4-5">
                <button
                  id="btn-setup-company"
                  className="btn btn-login w-100"
                  type="submit"
                  onClick={() => {
                    this.setState({ required: true });
                  }}
                >
                  Setup Company
                </button>
              </div>
            </>
          ) : (
            <>
              {!this.props.selectPartnerDisabled && (
                <>
                  <div
                    className="mt-1 mb-4"
                    style={{ borderBottom: "1px solid #e9e8ee" }}
                  ></div>
                  <h5 className="font-weight-bold mt-2">
                    There is already an account in Torchlite. Confirm this is
                    the company you want to use.
                  </h5>
                  <p className="text-grayed h7 mt-0">
                    If this is not correct, please start over with typing in the
                    text field above.
                  </p>
                </>
              )}
              <>
                <div className="d-flex justify-content-start">
                  <Avatar
                    firstName={this.props.selectedPartnerDetails.companyName}
                    url={this.props.selectedPartnerDetails.avatarUrl}
                    size="customSize"
                    imageSize={"30"}
                    fontSize={13}
                  ></Avatar>
                  <div className="ml-3">
                    <h5 className="font-weight-bold mb-1">
                      {this.props.selectedPartnerDetails.companyName}
                    </h5>
                    <p className="text-grayed h7 my-0">
                      Joined:{" "}
                      <span className="font-weight-bold">
                        {this.props.selectedPartnerDetails.formattedCreatedAt}
                      </span>
                    </p>
                    {this.props.selectedPartnerDetails.website_url && (
                      <p className="text-grayed h7 mt-1 mb-0">
                        URL:{" "}
                        <span className="font-weight-bold text-link">
                          {this.props.selectedPartnerDetails.website_url}
                        </span>
                      </p>
                    )}
                  </div>
                </div>
              </>

              <div
                className="mt-4 mb-4"
                style={{ borderBottom: "1px solid #e9e8ee" }}
              ></div>

              <div className="btn-wrapper mt-4">
                <button
                  id="btn-joincompany"
                  className="btn btn-login w-100"
                  type="submit"
                  onClick={() => {
                    this.setState({ required: true });
                  }}
                >
                  Yes, Join this Company
                </button>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

export default BasicInformation;
