class ArrayList {
  /**
   * Get value by object
   *
   * @param {*} data
   * @param {*} keyValue
   */
  static getByKey = (data, key) => {
    if (!data.length) {
      return [];
    }
    const list = data.filter(data => data.name === key);
    return list;
  };
  static arrayToArrayFilter = (array1, array2) => {
    if (!array1 && !array2) {
      return null;
    }
    let filteredArray = array1.filter(list => array2.includes(list));
    return filteredArray;
  };
}

export default ArrayList;
