import React from "react";
import { injectStripe } from "react-stripe-elements";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// API call
import { apiClient } from "../../apiClient";

// Helper
import {
  getCookie,
  toString,
  removeMaskedPhoneNumber,
  setAuthorizationHeader
} from "../../lib/helper";
import { getBillingDetails } from "../accountingSettings/BillingDetails";
import {
  getPaymentMethods,
  setPaymentMethods
} from "../accountingSettings/PaymentOptions";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";

// Components
import Form from "../../components/base/Form";
import Text from "../../components/base/Text";
import Zipcode from "../../components/base/Zipcode";
import Phone from "../../components/base/Phone";
import Email from "../../components/base/Email";
import State from "../../components/State";
import Country from "../../components/base/Country";
import Loader from "../../components/base/Loader";
import CardSection from "./CardSection";

// Icons
import { DoubleClapGraphicIcon } from "../../assets/img/icons";

// Constants
import { METACX_EVENT_PLAYBOOK_CHECKOUT_PAY_NOW_BUTTON_CLICK } from "../../metaCXEvents/Constants";
import { UNITED_STATES } from "../../Country/Constants";

// Library
import { metaCXTrack } from "../../lib/metaCX";
import { COOKIE_SESSION_TOKEN } from "../../lib/cookie";
import BillingDetails from "../accountingSettings/BillingDetails";
import PaymentOptions from "../accountingSettings/PaymentOptions";
import AddPaymentOptions from "../accountingSettings/AddPaymentOptions";

import { gaEvent } from "../../lib/GoogleAnalytics";
import { isBadRequest } from "../../common/http";

class CheckoutForm extends React.Component {
  constructor(props) {
    super(props);
    const { customerDetails } = props;

    const country = customerDetails ? customerDetails.country : "";

    this.state = {
      isOpen: false,
      passwordToken: "",
      email: "",
      isRequired: false,
      isLoading: false,
      canMakePayment: false,
      cardElement: null,
      cardNumberRequired: true,
      CardErrorMessage: "",
      cardComplete: false,
      errorMessage: "",
      isPaymentFail: false,
      billingDetails: {},
      paymentMethods: [],
      selectedPaymentMethodId: null,
      newPaymentOptionModal: false,
      selectedCountry: "",
      selectedCountryName: UNITED_STATES,
      selectedOption: ""
    };

    this._handleSubmit = this._handleSubmit.bind(this);
    this.toggle = this.toggle.bind(this);
    this.togglePaymentFail = this.togglePaymentFail.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.toggleLoadingModal = this.toggleLoadingModal.bind(this);
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  // Handle Modal Click
  toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  // Toggle Payment Failed Model
  togglePaymentFail() {
    this.setState({ isPaymentFail: !this.state.isPaymentFail });
  }

  // Close Modal When Payment Failed
  closeModal() {
    this.setState({
      isPaymentFail: !this.state.isPaymentFail,
      errorMessage: ""
    });
  }

  toggleLoadingModal() {
    this.setState({ isLoading: !this.state.isLoading });
  }

  _isEmpty() {
    if (this.state.cardNumberRequired) {
      if (!this.state.CardErrorMessage) {
        this.setState({
          CardErrorMessage: "Credit Card Number is required"
        });
      }
    } else if (!this.state.cardNumberRequired) {
      this.setState({
        CardErrorMessage: ""
      });
    }
  }

  // Submit playbook purchase address and payment details
  _handleSubmit = async (values, bypassThisCheck = false) => {
    const {
      playbook,
      cartOption,
      totalAmount,
      actualAmount,
      requestedStartDate,
      playbookExtrasIds,
      projectNotes,
      settings
    } = this.props;

    if (totalAmount) {
      if (
        this.state.CardErrorMessage ||
        (!this.state.cardComplete && !bypassThisCheck)
      ) {
        if (!this.state.cardComplete) {
          this.setState(
            {
              cardNumberRequired: true,
              CardErrorMessage:
                this.state.CardErrorMessage || "Your card number is incomplete."
            },
            () => this._isEmpty()
          );
        }
        return null;
      }
    }

    const paymentMethodResponse =
      this.state.selectedPaymentMethodId === null &&
      this.state.cardElement !== null
        ? await this.props.stripe.createPaymentMethod({
            type: "card",
            card: this.state.cardElement
          })
        : {};

    values.playbook = playbook;
    values.cartOption = cartOption;
    values.estimatedBudget = totalAmount;
    values.actualAmount = actualAmount;
    values.totalAmount = totalAmount;
    values.requestedStartDate = requestedStartDate;
    values.projectNotes = projectNotes;
    values.depositAmount = 100;
    values.playbookExtrasIds = playbookExtrasIds;
    values.countryName = toString(
      values.country ? values.country.label : values.country
    );
    values.phone = removeMaskedPhoneNumber(values.phone);
    values.city = toString(values.city);
    values.stateName = toString(
      values.state ? values.state.value : values.state
    );

    if (!paymentMethodResponse.error && paymentMethodResponse.paymentMethod) {
      values.paymentMethod = paymentMethodResponse.paymentMethod;
    } else {
      values.paymentMethod = null;
    }

    if (this.state.paymentMethods && this.state.paymentMethods.length > 0) {
      values.paymentMethod = this.state.selectedPaymentMethodId;
    }

    /* It will pass card element details - for now we are not passing this card details.
     * We will pass card details once stripe integration fully */

    const packageDeliverables = playbook.packageDeliverables;
    values.deliverables = [];
    if (
      packageDeliverables[cartOption.package_type.toLowerCase()] &&
      packageDeliverables[cartOption.package_type.toLowerCase()].length > 0
    ) {
      values.deliverables =
        packageDeliverables[cartOption.package_type.toLowerCase()];
    }
    values.billingInfo = this.state.billingDetails;

    const coupon = window.localStorage.getItem("coupon");

    values.coupon = coupon;

    this.setState({
      email: values.email,
      isLoading: true
    });

    // Track MetaCX event for click on Playbook Checkout Button Click
    metaCXTrack(
      METACX_EVENT_PLAYBOOK_CHECKOUT_PAY_NOW_BUTTON_CLICK,
      {
        playbookId: `${playbook.id}`,
        playbookName: playbook.name,
        totalAmount: `${totalAmount}`,
        requestedStartDate
      },
      settings,
      () => {}
    );

    let apiUrl;
    const sessionToken = getCookie(COOKIE_SESSION_TOKEN);
    if (!sessionToken) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
      apiUrl = `${endpoints().publicAPI}/playbook/checkout`;
    } else {
      apiClient.defaults.headers.common.Authorization = sessionToken;
      apiUrl = `${endpoints().playbookAPI}/checkout`;
    }

    return apiClient
      .post(apiUrl, { data: values })
      .then(response => {
        const data = response && response.data ? response.data : response;
        const passwordToken = data ? data.passwordToken : "";
        this.setState({ passwordToken, isLoading: false });
        this.toggle();
        window.localStorage.removeItem("percentage");
        window.localStorage.removeItem("coupon");
        window.localStorage.removeItem("projectNotes");

        // Billing Info Funnel Step
        window.ga("ec:setAction", "checkout", {
          step: 3
        });

        window.ga("ec:addProduct", {
          // Provide playbook details in an productFieldObject.
          id: `${playbook.id}`, // playbook ID (string).
          name: `${playbook.name}`, // playbook name (string).
          category: "Playbooks", // playbook category (string).
          brand: "Playbooks", // playbook brand (string).
          variant: "Playbooks", // playbook variant (string).
          price: `${totalAmount}`, // playbook price (number).
          coupon: coupon, // playbook coupon (string).
          quantity: 1 // playbook quantity (number).
        });

        window.ga("ec:setAction", "purchase", {
          // Transaction details are provided in an actionFieldObject.
          id: Math.floor(1000 + Math.random() * 9000), // (Required) Transaction id (string).
          affiliation: "Torchlite online store", // Affiliation (string).
          revenue: `${totalAmount}`, // Revenue (number).
          tax: 0, // Tax (number).
          shipping: 0, // Shipping (number).
          coupon: "coupon" // Transaction coupon (string).
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({
            errorMessage: errorMessage
          });
          this.setState({ isLoading: false });
          if (errorMessage) {
            this.togglePaymentFail();
          } else {
            this.toggle();
          }
        }
      });
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define checkout constants
    const name = values.name;

    if (!name) {
      success = false;
    }

    return success;
  }

  handleOnclick = () => {
    this._isEmpty();
  };

  handleReady = element => {
    this.setState({ cardElement: element });
  };

  handleChange = element => {
    if (element.error) {
      this.setState({
        cardNumberRequired: true,
        CardErrorMessage: element.error.message,
        cardComplete: element.complete
      });
    } else {
      this.setState(
        {
          cardNumberRequired: false,
          cardComplete: element.complete
        },
        () => this._isEmpty()
      );
    }
    if (!element.error && element.empty && !element.complete) {
      this.setState(
        {
          cardNumberRequired: true,
          cardComplete: element.complete
        },
        () => this._isEmpty()
      );
    }
  };

  componentDidMount() {
    if (this.props.customerDetails.country) {
      this.getCountryDetails(this.props.customerDetails.country);
    } else {
      this.getCountryDetails(UNITED_STATES);
    }

    setAuthorizationHeader(() => {
      getBillingDetails().then(res =>
        this.setState({ billingDetails: res.data.data })
      );

      getPaymentMethods().then(res =>
        this.setState({ paymentMethods: res.data.data }, () => {
          const paymentMethods = this.state.paymentMethods;
          if (paymentMethods.length > 0) {
            paymentMethods.forEach(paymentOption => {
              // Setting Default Payment Option
              if (paymentOption.is_default) {
                this.setState({
                  selectedPaymentMethodId: paymentOption.id
                });
              }
            });
          }
        })
      );
    });
  }

  getSelectedPaymentOption = id => {
    this.setState({
      selectedPaymentMethodId: id
    });
  };

  // Set New Payment Option
  setNewPaymentOptionModal = () => {
    this.setState({
      newPaymentOptionModal: !this.state.newPaymentOptionModal
    });
  };

  //  Update Payment Method UI
  updatePaymentMethodUi = () => {
    getPaymentMethods().then(res =>
      this.setState({ paymentMethods: res.data.data })
    );
  };

  // Handle Country
  handleCountryChange = ({ values }) => {
    const selectedOption = values.country && values.country.id;
    const selectedCountryName = values.country && values.country.label;
    this.setState({
      selectedOption,
      selectedCountryName: selectedCountryName
    });
  };

  // Get Country Details
  getCountryDetails = countryName => {
    return apiClient
      .get(`${endpoints().countryAPI}/${countryName}`)
      .then(response => {
        const data = response.data;
        this.setState({
          selectedCountry: data.id
        });
      });
  };

  render() {
    const { customerDetails, totalAmount, playbook } = this.props;
    const {
      passwordToken,
      email,
      isLoading,
      CardErrorMessage,
      selectedCountryName,
      selectedCountrys
    } = this.state;

    let country = "",
      state = "";

    if (customerDetails) {
      country = {
        value: customerDetails ? customerDetails.country : "",
        label: customerDetails ? customerDetails.country : ""
      };

      state = {
        value: customerDetails ? customerDetails.state : "",
        label: customerDetails ? customerDetails.state : ""
      };
    }

    // Initial Values
    const initialValues = {
      companyName: customerDetails ? customerDetails.legalCompanyName : "",
      firstName: customerDetails ? customerDetails.firstName : "",
      lastName: customerDetails ? customerDetails.lastName : "",
      email: customerDetails ? customerDetails.email : "",
      phone: customerDetails ? customerDetails.phone : "",
      address: customerDetails ? customerDetails.address1 : "",
      zip: customerDetails ? customerDetails.postalCode : "",
      country: {
        value: UNITED_STATES,
        label: UNITED_STATES
      },
      selectedCountrys: "",
      city: customerDetails ? customerDetails.city : "",
      state: state
    };

    const initialBillingValues = {
      ...initialValues,
      billingInfo: { ...this.state.billingDetails }
    };

    return (
      <>
        {this.state.billingDetails &&
        Object.values(this.state.billingDetails).length > 0 &&
        this.state.paymentMethods &&
        this.state.paymentMethods.length > 0 ? (
          <>
            {/*Billing Details*/}
            <BillingDetails
              fields={this.state.billingDetails}
              selectedCountry={selectedCountrys}
              updateBillingDetails={data =>
                this.setState({ billingDetails: data })
              }
            />

            {/* Add Payment Method */}
            {totalAmount ? (
              <>
                {/*Add Payments Options*/}
                <AddPaymentOptions
                  setPaymentMethod={setPaymentMethods}
                  updatePaymentMethodUi={this.updatePaymentMethodUi}
                  isModal={true}
                  isOpen={this.state.newPaymentOptionModal}
                  toggle={this.setNewPaymentOptionModal}
                  className={"add-payment-options-modal"}
                />

                {/*Payment Method*/}
                <div className="payment-methods-wrapper">
                  <span className="h5 mr-3 d-block">
                    <b>Payment Method</b>
                  </span>
                  <PaymentOptions
                    paymentOptions={this.state.paymentMethods}
                    listView={true}
                    getSelectedPaymentOption={this.getSelectedPaymentOption}
                    optionSelectable={true}
                    showPaymentOptionButtons={false}
                    showEditPaymentOptions={true}
                    setNewPaymentOptionModal={this.setNewPaymentOptionModal}
                    isModal={true}
                  />
                </div>
              </>
            ) : (
              ""
            )}

            <Form
              initialValues={initialBillingValues}
              onSubmit={values => {
                this._handleSubmit(values, true);
              }}
            >
              <button
                type="submit"
                className="btn btn-secondary float-left"
                style={{ width: "240px" }}
                disabled={isLoading}
                onContextMenu={e => e.preventDefault()}
              >
                Pay Now
              </button>
            </Form>
          </>
        ) : (
          <Form
            initialValues={initialValues}
            onSubmit={values => {
              this._handleSubmit(values);
            }}
          >
            <div className="form-wrapper">
              <div className="field-wrapper">
                <Text
                  name="companyName"
                  label="Company Name"
                  placeholder="Enter Company Name"
                  error=""
                  required={true}
                />
              </div>
              <div className="field-wrapper">
                <Text
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                  error=""
                  required={true}
                />
                <Text
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  error=""
                  required={true}
                />
              </div>
              <div className="field-wrapper">
                <Email
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                  error=""
                  required={true}
                />
                <Phone
                  name="phone"
                  label="Phone"
                  placeholder="Enter Phone"
                  error=""
                  required={true}
                />
              </div>
              <div className="field-wrapper">
                <Text
                  name="address"
                  label="Address"
                  placeholder="Enter Address"
                  error=""
                  required={true}
                />
                <Zipcode
                  name="zip"
                  label="Zip/Postal Code"
                  placeholder="Enter Zip"
                  error=""
                  required={true}
                  locale={
                    selectedCountryName ? selectedCountryName : UNITED_STATES
                  }
                />
              </div>
              <div className="field-wrapper">
                <Country
                  name="country"
                  label="Country"
                  placeholder="Select Country"
                  error=""
                  required={true}
                  onChange={this.handleCountryChange}
                />
                <Text
                  name="city"
                  label="City"
                  placeholder="Enter City"
                  error=""
                  required={true}
                />
                <State
                  name="state"
                  label="State/Province/Region"
                  placeholder="Enter State"
                  error=""
                  selectedCountry={
                    this.state.selectedOption
                      ? this.state.selectedOption
                      : this.state.selectedCountry
                  }
                  selectedCountryName={
                    this.state.selectedCountryName
                      ? this.state.selectedCountryName
                      : ""
                  }
                />
              </div>

              {/* Credit Card */}
              {totalAmount ? (
                <>
                  <CardSection
                    handleChange={element => this.handleChange(element)}
                    handleReady={this.handleReady}
                  />
                  <span className="mb-5 mr-3 d-block credit-card-error">
                    {CardErrorMessage}
                  </span>
                </>
              ) : (
                ""
              )}

              <button
                type="submit"
                className="btn btn-secondary float-left"
                style={{ width: "240px" }}
                disabled={isLoading}
                onContextMenu={e => e.preventDefault()}
                onClick={() => {
                  // GA Event
                  gaEvent({
                    category: "Playbook Checkout Payment",
                    action: "Clicked on playbook checkout pay now",
                    label: "Playbook Checkout Payment",
                    value: `${playbook.id}`
                  });

                  // Billing Info Funnel Step
                  window.ga("ec:setAction", "checkout", {
                    step: 3
                  });

                  this.handleOnclick();
                }}
              >
                Pay Now
              </button>
            </div>
          </Form>
        )}

        {/*Payment Process*/}
        <Modal
          isOpen={isLoading}
          className={["log-hours-modal", "edit-task-modal"].join(" ")}
        >
          <ModalHeader>
            <Loader />
            <h4 className={["font-weight-bold", "my-4"].join(" ")}>
              Processing
            </h4>
          </ModalHeader>
          <ModalBody className={["text-center", "mb-4"].join(" ")}>
            <div className="form-wrapper">
              <div className="field-wrapper">
                <p>
                  Thank you for your deposit. <br />
                  Please wait while we set your dashboard up.
                </p>
              </div>
            </div>
          </ModalBody>
        </Modal>

        {/*Payment Success Popup*/}
        <Modal
          isOpen={this.state.isOpen}
          toggle={this.toggle}
          className={["log-hours-modal", "edit-task-modal"].join(" ")}
        >
          <ModalHeader toggle={this.toggle}>
            <DoubleClapGraphicIcon />
            <h4 className={["font-weight-bold", "my-4"].join(" ")}>
              All Done! {this.state.errorMessage}
            </h4>
          </ModalHeader>

          <ModalBody className={["text-center", "mb-4"].join(" ")}>
            <div className="form-wrapper">
              <div className="field-wrapper">
                <p>We’re ready for you to visit your dashboard.</p>
              </div>
            </div>
          </ModalBody>

          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <a
                href={`${
                  passwordToken
                    ? `/set-password?token=${passwordToken}&email=${email}`
                    : "/dashboard"
                }`}
                className="btn btn-primary"
              >
                Go To Dashboard
              </a>
            </div>
          </ModalFooter>
        </Modal>

        {/*Payment failed model*/}
        <Modal
          isOpen={this.state.isPaymentFail}
          toggle={this.togglePaymentFail}
          className={["log-hours-modal", "edit-task-modal"].join(" ")}
        >
          <ModalBody className={["text-center", "mb-4"].join(" ")}>
            <div className="form-wrapper">
              <div className="field-wrapper">
                <p>Payment Failed</p>
              </div>
            </div>
          </ModalBody>

          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <a
                onClick={this.closeModal}
                className="btn btn-primary cursor-pointer"
              >
                Ok
              </a>
            </div>
          </ModalFooter>
        </Modal>
      </>
    );
  }
}

export default injectStripe(CheckoutForm);
