import React, { useState } from "react";
import Button from "../../../components/base/Button";
import { Modal, ModalBody } from "reactstrap";
import PartnerSidebarFilter from "../../../components/PartnerSidebarFilter";

// Icons
import { CrossIcon } from "../../../assets/img/icons";

const PartnerFilter = props => {
  const {
    title,
    partnerSelectedFilters,
    services,
    industries,
    filterPartnerList,
    marketplaceId,
    searchTerm,
    data
  } = props;

  const [popoverOpen, setPopoverOpen] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  return (
    <>
      <div className="section-title-mobile">
        {title && <span className="h1 d-block font-weight-light">{title}</span>}
      </div>
      <div>
        <div
          id="accountInSalesforce"
          className="container d-flex justify-content-end mb-2"
        >
          <button
            type="button"
            className="btn"
            style={{ backgroundColor: "#f1f1f1" }}
            onClick={() => toggle()}
          >
            Filters <i className="fas fa-angle-down"></i>
          </button>
        </div>
        <Modal
          isOpen={popoverOpen}
          toggle={toggle}
          className="custom-modal-popup partner-directory-filter p-0"
          target="accountInSalesforce"
          backdrop="static"
        >
          <ModalBody style={{ padding: 0 }}>
            <div className="col-md-12 d-flex bd-highlight my-2">
              <div className="ml-auto cursor-pointer" onClick={() => toggle()}>
                <CrossIcon />
              </div>
            </div>
            <PartnerSidebarFilter
              partnerSelectedFilters={partnerSelectedFilters}
              services={services}
              industries={industries}
              filterPartnerList={filterPartnerList}
              searchTerm={searchTerm}
              marketplaceId={marketplaceId}
              data={data}
            />
            <div className="d-flex align-items-center px-3">
              <Button
                label="Show Results"
                onClick={() => toggle()}
                className="btn btn-primary btn-block mb-2"
              />
            </div>
          </ModalBody>
        </Modal>
      </div>
    </>
  );
};

export default PartnerFilter;
