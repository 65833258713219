import React, { useState, useEffect } from "react";

import { Col, Row } from "reactstrap";
import ContactPartnerForm from "./ContactPartnerForm";

// Helper
import { getKeyValueByObject } from "../lib/helper";

import {
  SETTINGS_ENABLE_CLUTCH_REVIEW,
  ENABLE_TIER_VISIBILITY
} from "../setting/Constants";
import { isTrue } from "../common/utils";
// Components
import PlaybookCard from "../components/PlaybookCard";

// Assets
import { LocationIcon, ElitePartner } from "../assets/img/icons";
import Image from "../components/base/Image";

import {
  TASK_TYPE_DROP_DOWN_FIELD,
  TASK_TYPE_TEXT_FIELD
} from "../modules/task/Constant";
import ImageCarousel from "../components/base/imageCarasoul/ImageCarousel";
import ClutchReview from "../../src/partner/component/ClutchReview";

import SocialIcons from "../components/base/SocialIcons";

import { fetchPartnerSettings } from "../actions/settings";

const PartnerProfileDetails = props => {
  const [partnerTypeSetting, setPartnerTypeSettings] = useState(false);
  const {
    partnerDetails,
    playbookList,
    partnerId,
    contactUsFormId,
    contactUsFormFields,
    marketplaceTagTypeList,
    photos,
    handlePhotoDelete,
    settings
  } = props;
  const imageRemoveHandler = photo => {
    handlePhotoDelete(photo);
  };
  const showAsList = data => {
    let dataLists = data
      .split(",")
      .map(item => <li style={{ listStyleType: "none" }}>{item.trim()}</li>);
    return dataLists;
  };
  const {
    companyName,
    partnerTier,
    avatarUrl,
    city,
    stateName,
    headline,
    aboutMe,
    services,
    industries,
    regions,
    abmExpertises,
    crmExpertises,
    facebookUrl,
    linkedinUrl,
    instagramUrl,
    twitterUrl,
    websiteUrl,
    youtubeUrl,
    customFields
  } = partnerDetails;
  useEffect(() => {
    fetchPartnerTypeSettings();
  }, []);

  const fetchPartnerTypeSettings = async () => {
    if (partnerDetails.id && partnerDetails.id) {
      fetchPartnerSettings(
        partnerDetails.id,
        SETTINGS_ENABLE_CLUTCH_REVIEW,
        settings => {
          if (settings && settings.length > 0) {
            let length = settings.length;
            for (let i = 0; i < length; i++) {
              if (settings[i].value == "true") {
                setPartnerTypeSettings(true);
                break;
              }
            }
          }
        }
      );
    }
  };

  var aboutHtml = aboutMe;
  var div = document.createElement("div");
  div.innerHTML = aboutHtml;
  var aboutMeText = div.textContent || div.innerText || "";

  let serviceList = [],
    industryList = [],
    regionList = [],
    abmExpertiseList = [],
    crmExpertiseList = [];

  services && services.map(service => serviceList.push(service.name));
  industries && industries.map(industry => industryList.push(industry.name));
  regions && regions.map(region => regionList.push(region.name));
  abmExpertises &&
    abmExpertises.map(abmExpertise => abmExpertiseList.push(abmExpertise.name));
  crmExpertises &&
    crmExpertises.map(crmExpertise => crmExpertiseList.push(crmExpertise.name));
  const enableClutchReview = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_CLUTCH_REVIEW)
    : "";

  return (
    <>
      <Row>
        {avatarUrl ? (
          <Col className="my-auto" sm="5" md="4" lg="3">
            <Image
              ImgSrc={avatarUrl}
              ImgAlt="avatar of partner"
              ImgMaxHeight="170px"
              ImgMaxWidth="170px"
              ImgWidth="100%"
            />
          </Col>
        ) : (
          ""
        )}
        <Col lg={avatarUrl ? "6" : "9"}>
          {partnerTier && partnerTier.visibility === ENABLE_TIER_VISIBILITY && (
            <div className="elite-partner">
              <p className="text-uppercase text-info">
                <ElitePartner /> {partnerTier.name}
              </p>
            </div>
          )}
          <div className="mt-4">
            <h3>{companyName}</h3>
            {headline && (
              <div className="section-maincontent">
                <p className="pre-wrap">{headline} </p>
              </div>
            )}
            <hr style={{ width: "100px", borderColor: "#97CA7E" }} />
            <div className="partner-profile-location">
              {(city || stateName) && (
                <p>
                  <LocationIcon /> {city}
                  {city ? ", " : ""} {stateName}
                </p>
              )}

              <SocialIcons
                website={websiteUrl}
                facebook={facebookUrl}
                twitter={twitterUrl}
                linkedIn={linkedinUrl}
                instagram={instagramUrl}
                youtube={youtubeUrl}
              />
            </div>
          </div>
        </Col>
        <Col lg="3" className="my-auto">
          <ContactPartnerForm
            partnerId={partnerId}
            contactUsFormId={contactUsFormId}
            formFields={contactUsFormFields}
          />
        </Col>
      </Row>
      <Row className="mt-5 mb-4 ">
        <Col>
          <hr />
        </Col>
      </Row>
      <Row>
        <Col lg="8">
          {aboutMeText && (
            <div className="section-maincontent mb-4">
              <h6 className="font-weight-bold">About {companyName}</h6>
              <p>
                <div
                  dangerouslySetInnerHTML={{ __html: aboutMe }}
                  style={{ wordBreak: "break-word" }}
                />
              </p>
            </div>
          )}
        </Col>
        <Col lg={{ size: 3, offset: 1 }}>
          {marketplaceTagTypeList &&
            marketplaceTagTypeList.length > 0 &&
            marketplaceTagTypeList
              .sort((a, b) =>
                (a["sort"] || "")
                  .toString()
                  .localeCompare((b["sort"] || "").toString())
              )
              .map(marketplaceTagType => (
                <>
                  {marketplaceTagType.name && marketplaceTagType.selectedTags && (
                    <div className="mb-4">
                      <h6 className="font-weight-bold">
                        {marketplaceTagType.name}
                      </h6>
                      {showAsList(marketplaceTagType.selectedTags)}
                    </div>
                  )}
                </>
              ))}
          {customFields &&
            customFields.partnerApplicationTasks &&
            customFields.partnerApplicationTasks.length > 0 &&
            customFields.partnerApplicationTasks.map(
              task =>
                task.partnerApplicationFields &&
                task.partnerApplicationFields.length > 0 &&
                task.partnerApplicationFields.map(customField => (
                  <>
                    {customField.name &&
                      customField.fieldValue &&
                      customField.type === TASK_TYPE_TEXT_FIELD && (
                        <div className="mb-4">
                          <h6 className="font-weight-bold">
                            {customField.name}
                          </h6>
                          {customField.fieldValue}
                        </div>
                      )}
                    {customField.name &&
                      customField.selectedTagString &&
                      customField.type === TASK_TYPE_DROP_DOWN_FIELD && (
                        <div className="mb-4">
                          <h6 className="font-weight-bold">
                            {customField.name}
                          </h6>
                          {customField.selectedTagString}
                        </div>
                      )}
                  </>
                ))
            )}
        </Col>
      </Row>

      {photos && photos.length > 0 && (
        <>
          <Row>
            <Col>
              <h6 className="font-weight-bold">Photos</h6>
              <hr style={{ width: "100px", borderColor: "#97CA7E" }} />
            </Col>
          </Row>
          <ImageCarousel
            photos={photos}
            showRemove={false}
            handlePhotoDelete={imageRemoveHandler}
            style={{
              width: "100%",
              height: "140px",
              objectFit: "scale-down"
            }}
            slides={5}
          />
        </>
      )}

      {playbookList && playbookList.length > 0 && (
        <Row>
          <Col>
            <h6 className="font-weight-bold">{companyName} Playbooks</h6>
            <hr style={{ width: "100px", borderColor: "#97CA7E" }} />
          </Col>
        </Row>
      )}

      <Row className="mb-5 pb-5 ">
        {playbookList &&
          playbookList.length > 0 &&
          playbookList.map(playbook => (
            <Col md="3">
              <PlaybookCard
                data={playbook}
                size={"col-lg-3 col-md-3"}
                link={`/playbook/${playbook.id}${
                  partnerId ? "?partnerId=" + partnerId : ""
                }&redirect=${window.location.pathname}`}
              />
            </Col>
          ))}
      </Row>
      {isTrue(enableClutchReview) && websiteUrl !== "" && partnerTypeSetting && (
        <Row>
          <Col>
            <ClutchReview domainName={websiteUrl} />
          </Col>
        </Row>
      )}
    </>
  );
};

export default PartnerProfileDetails;
