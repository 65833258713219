import React from "react";
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";

//Assets
import {
  ChevronRight,
  SettingsRambleChatIcon,
  SlackIcon
} from "../../../../assets/img/icons";

// Constants
import {
  INTEGRATION_CHAT_SUB_TAB,
  TAB_INTEGRATION,
  TAB_RAMBLE_CHAT_SETTINGS,
  TAB_SLACK
} from "../../../../setting/Constants";
import RambleChatSection from "./sections/RambleChatSection";
import SlackSection from "./sections/SlackSection";

const ChatSection = props => {
  const {
    activeSection,
    adminSettings,
    settings,
    saveSettings,
    history
  } = props;

  return (
    <>
      {!activeSection && (
        <>
          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_CHAT_SUB_TAB}/${TAB_RAMBLE_CHAT_SETTINGS}`}
            className="onboarding-link mb-2"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="step-count d-block completed">
                  <SettingsRambleChatIcon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">Ramble Chat</h5>
              <p className="font-weight-bold text-inline-grayed">
                {/* Sync leads from Salesforce */}
              </p>
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>

          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_CHAT_SUB_TAB}/${TAB_SLACK}`}
            className="onboarding-link"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="step-count d-block completed">
                  <SlackIcon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">Slack</h5>
              <p className="font-weight-bold text-inline-grayed">
                {/* Sync leads from Salesforce */}
              </p>
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>
        </>
      )}

      <TabContent activeTab={activeSection}>
        <TabPane tabId={TAB_RAMBLE_CHAT_SETTINGS} className="w-100">
          <RambleChatSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        <TabPane tabId={TAB_SLACK} className="w-100">
          <SlackSection
            settings={settings}
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default ChatSection;
