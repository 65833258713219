//API Call
import { apiClient } from "../apiClient";
import { endpoints } from "../configs";

//Helper
import { fetchList } from "../../src/actions/table";
import { isBadRequest } from "../common/http";

//Components
import toast from "../components/base/Toast";

//Constants
import {
  REQUEST_UPDATE_PARTNER,
  RECEIVE_UPDATE_PARTNER,
  PARTNER_UPDATE_ERROR
} from "./Constants";
import { getParamsByName } from "../lib/helper";

/**
 * Request for updating Partner
 */
export function requestUpdatePartner() {
  return {
    type: REQUEST_UPDATE_PARTNER
  };
}

/**
 * Receive for updating Partner
 */
export function receiveUpdatePartner() {
  return {
    type: RECEIVE_UPDATE_PARTNER
  };
}

/**
 * Receive for error updating Partner
 */
export function partnerUpdateError(error) {
  return {
    type: PARTNER_UPDATE_ERROR,
    error
  };
}

/**
 * Update Partners
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updatePartners(id, data, page, params) {
  return dispatch => {
    dispatch(requestUpdatePartner());
    apiClient
      .put(`${endpoints().partnerAPI}/status/approve/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        dispatch(
          fetchList(
            "all-partners",
            `${endpoints().partnerAPI}/search`,
            page || 1,
            10,
            {
              ...params
            }
          )
        );
        dispatch(receiveUpdatePartner());
      })
      .catch(error => {
        dispatch(partnerUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Update Pending Partners
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updatePendingPartners(id, data, page, params) {
  return dispatch => {
    dispatch(requestUpdatePartner());
    apiClient
      .put(`${endpoints().partnerAPI}/status/approve/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        dispatch(
          fetchList(
            "all-partners",
            `${endpoints().partnerAPI}/search`,
            page || 1,
            10,
            {
              section: "all-partners",
              sort: "company_name",
              sortDir: "ASC",
              pagination: true
            }
          )
        );
      })
      .catch(error => {
        dispatch(partnerUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Delte Partners
 *
 * @param id
 * @returns {function(...[*]=)}
 */
export function deletePartners(id, params, page) {
  const searchItem = getParamsByName("search");
  return dispatch => {
    dispatch(requestUpdatePartner());
    apiClient
      .delete(`${endpoints().partnerAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        dispatch(
          fetchList(
            "all-partners",
            `${endpoints().partnerAPI}/search`,
            params.page || 1,
            10,
            {
              ...params,
              section: "all-partners",
              sortDir: "ASC",
              pagination: true,
              search: searchItem ? searchItem : ""
            }
          )
        );
        dispatch(receiveUpdatePartner());
      })
      .catch(error => {
        dispatch(partnerUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}
