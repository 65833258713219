import React, { useEffect, useRef, useState } from "react";
import moment from "moment-timezone";
import KickOffTimePickerAccordion from "./KickOffTimePickerAccordion";
import {
  ChevronLeft,
  ChevronRight,
  MoonIcon,
  SunIcon,
  SunriseIcon
} from "../../../../assets/img/icons";
import {
  isCustomer,
  isExpert,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../../lib/helper";
import {
  KickOffTimePickerCustomerHours,
  KickOffTimePickerExpertHours,
  KickOffTimePickerAdminHours
} from "./KickOffTimePickerHours";

import { useSelector } from "react-redux";

const KickOffTimePicker = React.memo(function KickOffTimePicker({
  updateSelectedDates,
  defaultTimezone,
  visualTimezone,
  expertAvailableDates,
  updateSelectedDateId,
  customerWaitingForExpert,
  expertAvailableTimes
}) {
  const [customerAvailableDates, setCustomerAvailableDates] = useState(
    enumerateDaysBetweenDates()
  );
  const currentUser = useSelector(state => state.user);

  function enumerateDaysBetweenDates() {
    const workingDayEndTime = 19;
    const currentTime = moment()
      .utc()
      .tz(visualTimezone)
      .hour();

    const defaultDate = new Date();
    const currentDate = moment(defaultDate)
      .utc()
      .tz(visualTimezone);
    const nextDate = moment(defaultDate)
      .utc()
      .add(1, "days")
      .tz(visualTimezone);

    const startDate =
      currentTime < workingDayEndTime
        ? currentDate.format()
        : nextDate.format();
    const endDate =
      currentTime < workingDayEndTime
        ? currentDate.clone().add(9, "days")
        : nextDate.clone().add(9, "days");

    let dates = [];
    let currDate = moment(startDate)
      .utc()
      .tz(visualTimezone)
      .startOf("day");
    let lastDate = moment(endDate)
      .utc()
      .tz(visualTimezone)
      .startOf("day");

    do {
      if (currDate.weekday() > 0 && currDate.weekday() < 6) {
        dates.push(currDate.clone());
      }
    } while (currDate.add(1, "days").diff(lastDate) < 0);

    return dates;
  }

  const [selectedDates, setSelectedDates] = useState({});

  const [selectedAdminDates, setSelectedAdminDates] = useState(
    expertAvailableTimes
  );

  const convertSelectedTimesToArray = object =>
    Object.values(object).filter(v => v !== undefined);

  // code
  const handleChangeAdminHours = event => {
    const { value, checked } = event.target;

    if (!checked) {
      setSelectedAdminDates(
        dates => dates && dates.filter(date => date !== value)
      );
    } else {
      setSelectedAdminDates(dates => [...dates, value]);
    }
  };

  // code
  const handleChange = e => {
    const { value, checked, name } = e.target;
    const { id } = e.target.dataset;

    setSelectedDates({
      ...selectedDates,
      [name]: checked ? value : undefined
    });

    isExpert(currentUser && currentUser.roleId) && updateSelectedDateId(id);
  };

  const getSelectedValue = (date, hour, timezone, dateId) => {
    let d = moment(date).format("YYYY-MM-DD");
    let z = moment(date)
      .utc()
      .tz(timezone)
      .format("Z");
    let [h, m] = hour.split(":");
    return `${d}T${h < 10 ? `0${h}` : h}:${m}:00${z}`;
  };

  const getAvailableDates = userType => {
    switch (userType) {
      case "CUSTOMER":
        return customerAvailableDates;
      case "SUPER_ADMIN":
        return customerAvailableDates;
      case "EXPERT":
        return getSetOfDates(
          expertAvailableDates.map(date => {
            return moment
              .utc(date.time)
              .tz(visualTimezone)
              .format("MM-DD-YYYY");
          })
        );
      default:
        return customerAvailableDates;
    }
  };

  const getSetOfDates = dates => {
    return [...new Set(dates)].sort();
  };

  // animate scrolling functionality

  const timepickerRef = useRef(null);
  const [translate, setTranslate] = useState(0);

  const scrollContainer = direction => {
    let dir = direction.toLowerCase();
    let width =
      timepickerRef.current.querySelector(".kickoff-scheduler__days")
        .scrollWidth + 3;
    let maximumTranslate = -Math.abs(
      timepickerRef.current.scrollWidth - width * 3
    );
    if (dir === "left" && translate < 0) {
      setTranslate(prevState => prevState + width);
    } else if (dir === "right" && translate > maximumTranslate) {
      setTranslate(prevState => prevState - width);
    }
  };

  useEffect(() => {
    timepickerRef.current.style.transform = `translateX(${translate}px)`;
  }, [translate]);

  useEffect(() => {
    updateSelectedDates(convertSelectedTimesToArray(selectedDates));
  }, [selectedDates]);

  useEffect(() => {
    updateSelectedDates(selectedAdminDates);
  }, [selectedAdminDates]);

  useEffect(() => {
    setCustomerAvailableDates(enumerateDaysBetweenDates);
  }, [visualTimezone]);

  return (
    <div className={"kickoff-scheduler__wrapper"}>
      <button
        type="button"
        className="kickoff-scheduler__scroll-btn kickoff-scheduler__scroll-btn--left"
        onClick={() => scrollContainer("left")}
      >
        <ChevronLeft />
      </button>
      <button
        type="button"
        className="kickoff-scheduler__scroll-btn kickoff-scheduler__scroll-btn--right"
        onClick={() => scrollContainer("right")}
      >
        <ChevronRight />
      </button>
      <div className={`kickoff-scheduler__timepicker`} ref={timepickerRef}>
        {getAvailableDates(
          (isCustomer(currentUser && currentUser.roleId) &&
            !customerWaitingForExpert &&
            "CUSTOMER") ||
            ((isExpert(currentUser && currentUser.roleId) ||
              customerWaitingForExpert) &&
              "EXPERT") ||
            ((isSuperAdmin(currentUser && currentUser.roleId) ||
              isCompanyAdmin(currentUser && currentUser.roleId) ||
              isCompanyManager(currentUser && currentUser.roleId)) &&
              "SUPER_ADMIN")
        )
          .filter((_, index) => index < 8)
          .map((date, index) => (
            <div className={"kickoff-scheduler__days"} key={index}>
              <div className={"kickoff-scheduler__header"}>
                {moment(date).format("dddd, MMM DD")}
              </div>
              <div className={"kickoff-scheduler__body"}>
                {(isSuperAdmin(currentUser && currentUser.roleId) ||
                  isCompanyAdmin(currentUser && currentUser.roleId) ||
                  isCompanyManager(currentUser && currentUser.roleId)) && (
                  <>
                    <ul
                      className={`kickoff-scheduler__list list-unstyled ${
                        isExpert() ? "kickoff-scheduler__list--expert" : ""
                      }`}
                    >
                      <KickOffTimePickerAdminHours
                        date={date}
                        handleChange={handleChangeAdminHours}
                        getSelectedValue={getSelectedValue}
                        expertAvailableDates={expertAvailableDates}
                        endTime={20}
                        minTime={8}
                        maximumHours={19}
                        defaultTimezone={defaultTimezone}
                        visualTimezone={visualTimezone}
                        customerWaitingForExpert={customerWaitingForExpert}
                      />
                    </ul>
                  </>
                )}

                {(isCustomer(currentUser && currentUser.roleId) ||
                  isExpert(currentUser && currentUser.roleId)) && (
                  <>
                    <KickOffTimePickerAccordion
                      icon={<SunriseIcon />}
                      title={"Morning"}
                    >
                      <ul
                        className={`kickoff-scheduler__list list-unstyled ${
                          isExpert(currentUser && currentUser.roleId)
                            ? "kickoff-scheduler__list--expert"
                            : ""
                        }`}
                      >
                        {isCustomer(currentUser && currentUser.roleId) &&
                          !customerWaitingForExpert && (
                            <KickOffTimePickerCustomerHours
                              date={date}
                              handleChange={handleChange}
                              getSelectedValue={getSelectedValue}
                              endTime={12}
                              minTime={8}
                              maximumHours={11}
                              step={30}
                              defaultTimezone={defaultTimezone}
                              visualTimezone={visualTimezone}
                              customerWaitingForExpert={
                                customerWaitingForExpert
                              }
                            />
                          )}
                        {(isExpert(currentUser && currentUser.roleId) ||
                          customerWaitingForExpert) && (
                          <KickOffTimePickerExpertHours
                            date={date}
                            handleChange={handleChange}
                            getSelectedValue={getSelectedValue}
                            expertAvailableDates={expertAvailableDates}
                            endTime={12}
                            minTime={8}
                            defaultTimezone={defaultTimezone}
                            visualTimezone={visualTimezone}
                            customerWaitingForExpert={customerWaitingForExpert}
                          />
                        )}
                      </ul>
                    </KickOffTimePickerAccordion>

                    <KickOffTimePickerAccordion
                      icon={<SunIcon />}
                      title={"Afternoon"}
                    >
                      <ul
                        className={`kickoff-scheduler__list list-unstyled ${
                          isExpert(currentUser && currentUser.roleId)
                            ? "kickoff-scheduler__list--expert"
                            : ""
                        }`}
                      >
                        {isCustomer(currentUser && currentUser.roleId) &&
                          !customerWaitingForExpert && (
                            <KickOffTimePickerCustomerHours
                              date={date}
                              handleChange={handleChange}
                              getSelectedValue={getSelectedValue}
                              endTime={17}
                              minTime={12}
                              maximumHours={16}
                              step={30}
                              defaultTimezone={defaultTimezone}
                              visualTimezone={visualTimezone}
                              customerWaitingForExpert={
                                customerWaitingForExpert
                              }
                            />
                          )}
                        {(isExpert(currentUser && currentUser.roleId) ||
                          customerWaitingForExpert) && (
                          <KickOffTimePickerExpertHours
                            date={date}
                            handleChange={handleChange}
                            getSelectedValue={getSelectedValue}
                            expertAvailableDates={expertAvailableDates}
                            endTime={17}
                            minTime={12}
                            defaultTimezone={defaultTimezone}
                            visualTimezone={visualTimezone}
                            customerWaitingForExpert={customerWaitingForExpert}
                          />
                        )}
                      </ul>
                    </KickOffTimePickerAccordion>

                    <KickOffTimePickerAccordion
                      icon={<MoonIcon />}
                      title={"Evening"}
                    >
                      <ul
                        className={`kickoff-scheduler__list list-unstyled ${
                          isExpert() ? "kickoff-scheduler__list--expert" : ""
                        }`}
                      >
                        {isCustomer(currentUser && currentUser.roleId) &&
                          !customerWaitingForExpert && (
                            <KickOffTimePickerCustomerHours
                              date={date}
                              handleChange={handleChange}
                              getSelectedValue={getSelectedValue}
                              endTime={20}
                              minTime={17}
                              maximumHours={19}
                              step={30}
                              defaultTimezone={defaultTimezone}
                              visualTimezone={visualTimezone}
                              customerWaitingForExpert={
                                customerWaitingForExpert
                              }
                            />
                          )}
                        {(isExpert(currentUser && currentUser.roleId) ||
                          customerWaitingForExpert) && (
                          <KickOffTimePickerExpertHours
                            date={date}
                            handleChange={handleChange}
                            getSelectedValue={getSelectedValue}
                            expertAvailableDates={expertAvailableDates}
                            endTime={20}
                            minTime={17}
                            defaultTimezone={defaultTimezone}
                            visualTimezone={visualTimezone}
                            customerWaitingForExpert={customerWaitingForExpert}
                          />
                        )}
                      </ul>
                    </KickOffTimePickerAccordion>
                  </>
                )}
              </div>
            </div>
          ))}
      </div>
    </div>
  );
});

export default KickOffTimePicker;
