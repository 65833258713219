/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import Form from "../../../../components/base/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import Text from "../../../../components/base/Text";

// Constants
import {
  LANDING_PAGE_BLOCK_TYPE_BANNER,
  LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT,
  LANDING_PAGE_BLOCK_TYPE_EXPERT,
  LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT,
  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK,
  LANDING_PAGE_BLOCK_TYPE_SIGN_UP,
  LANDING_PAGE_BLOCK_TYPE_CONTENT,
  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID,
  LANDING_PAGE_BLOCK_TYPE_PARTNER,
  LANDING_PAGE_BLOCK_TYPE_APPS,
  LANDING_PAGE_FEATURED_COLLECTION
} from "../../../../landingPage/Constants";
import {
  SETTINGS_ENABLE_PARTNERS,
  SETTINGS_ENABLE_PLAYBOOK
} from "../../../../setting/Constants";

// Actions
import { addLandingPageBlock } from "../../../../actions/landingPageBlock";

// Helper
import { getKeyValueByObject } from "../../../../lib/helper";

class AddLandingPageBlockModal extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
    // Set the initial input values
    this.state = {
      showAddSection: false,
      modal: false,
      type: ""
    };
    this.toggleAddSection = this.toggleAddSection.bind(this);
    this.toggle = this.toggle.bind(this);
    this.landingPageBlockCreate = this.landingPageBlockCreate.bind(this);
  }

  // Toggle modal
  toggle = type => {
    this.setState({
      modal: !this.state.modal,
      type: type
    });
  };

  // Get Modal Title By Type
  getModalNameByType(type) {
    let title;

    if (type === LANDING_PAGE_BLOCK_TYPE_BANNER) {
      title = LANDING_PAGE_BLOCK_TYPE_BANNER;
    } else if (type === LANDING_PAGE_BLOCK_TYPE_PLAYBOOK) {
      title = LANDING_PAGE_BLOCK_TYPE_PLAYBOOK;
    } else if (type === LANDING_PAGE_BLOCK_TYPE_EXPERT) {
      title = LANDING_PAGE_BLOCK_TYPE_EXPERT;
    } else if (type === LANDING_PAGE_BLOCK_TYPE_PARTNER) {
      title = LANDING_PAGE_BLOCK_TYPE_PARTNER;
    } else if (type === LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT) {
      title = "Find an Expert Call-to-Action";
    } else if (type === LANDING_PAGE_BLOCK_TYPE_SIGN_UP) {
      title = "Sign up Call-to-Action";
    } else if (type === LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT) {
      title = LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT;
    } else if (type === LANDING_PAGE_BLOCK_TYPE_CONTENT) {
      title = "Content";
    } else if (type === LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID) {
      title = LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID;
    } else if (type === LANDING_PAGE_BLOCK_TYPE_APPS) {
      title = LANDING_PAGE_BLOCK_TYPE_APPS;
    } else if (type === LANDING_PAGE_FEATURED_COLLECTION) {
      title = LANDING_PAGE_FEATURED_COLLECTION;
    }
    return title;
  }

  // Landing Page Blocks Create API Call
  landingPageBlockCreate(data) {
    const { landingPageId } = this.props;
    this.props.actions.addLandingPageBlock(data, landingPageId, () => {
      this.props.getLandingBlocksDetails();
    });
  }
  // Toggle Add Section Button
  toggleAddSection() {
    this.setState({ showAddSection: !this.state.showAddSection });
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  componentWillUnmount() {
    document.removeEventListener("mousedown", this.handleClickOutside);
  }
  handleClickOutside = event => {
    if (this.ref.current && !this.ref.current.contains(event.target)) {
      this.setState({ showAddSection: false });
    }
  };
  render() {
    const { landingPageId } = this.props;
    const { modal, type } = this.state;
    const show = this.state.showAddSection ? "show" : "";

    const settings = this.props.settings ? this.props.settings : "";
    const enablePartners = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS)
      : "";

    const enablePlaybook = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PLAYBOOK)
      : "";

    return (
      <div className="" ref={this.ref}>
        <div className="row justify-content-end mb-3">
          <div className="btn-group text-right pr-3">
            <button
              type="button"
              className="btn btn-secondary dropdown-toggle pull-right"
              onClick={this.toggleAddSection}
            >
              <FontAwesomeIcon
                icon={faPlus}
                style={{ fontSize: "12px", marginBottom: "2px" }}
              />

              <span style={{ marginLeft: "8px" }}>Add Section</span>
            </button>
            <div
              className={`dropdown-menu dropdown-menu-right pt-2 pb-2  ${show}`}
              style={{ width: "auto" }}
            >
              <a
                className="dropdown-item form-control"
                onClick={() => {
                  this.toggle(LANDING_PAGE_BLOCK_TYPE_BANNER);
                  this.toggleAddSection();
                }}
              >
                {LANDING_PAGE_BLOCK_TYPE_BANNER}
              </a>
              <a
                className="dropdown-item form-control"
                onClick={() => {
                  this.toggle(LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT);
                  this.toggleAddSection();
                }}
              >
                {LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT}
              </a>
              {enablePlaybook && enablePlaybook === "true" && (
                <>
                  <a
                    className="dropdown-item form-control"
                    onClick={() => {
                      this.toggle(LANDING_PAGE_BLOCK_TYPE_PLAYBOOK);
                      this.toggleAddSection();
                    }}
                  >
                    {LANDING_PAGE_BLOCK_TYPE_PLAYBOOK}
                  </a>
                  <a
                    className="dropdown-item form-control"
                    onClick={() => {
                      this.toggle(LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID);
                      this.toggleAddSection();
                    }}
                  >
                    {LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID}
                  </a>
                </>
              )}
              {enablePartners && enablePartners === "true" && (
                <a
                  className="dropdown-item form-control"
                  onClick={() => {
                    this.toggle(LANDING_PAGE_BLOCK_TYPE_PARTNER);
                    this.toggleAddSection();
                  }}
                >
                  {LANDING_PAGE_BLOCK_TYPE_PARTNER}
                </a>
              )}
              <a
                className="dropdown-item form-control"
                onClick={() => {
                  this.toggle(LANDING_PAGE_BLOCK_TYPE_EXPERT);
                  this.toggleAddSection();
                }}
              >
                {LANDING_PAGE_BLOCK_TYPE_EXPERT}
              </a>
              <a
                className="dropdown-item form-control"
                onClick={() => {
                  this.toggle(LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT);
                  this.toggleAddSection();
                }}
              >
                Find an Expert Call-to-Action
              </a>
              <a
                className="dropdown-item form-control"
                onClick={() => {
                  this.toggle(LANDING_PAGE_BLOCK_TYPE_SIGN_UP);
                  this.toggleAddSection();
                }}
              >
                Sign up Call-to-Action
              </a>
              <a
                className="dropdown-item form-control"
                onClick={() => {
                  this.toggle(LANDING_PAGE_BLOCK_TYPE_CONTENT);
                  this.toggleAddSection();
                }}
              >
                Content
              </a>
              <a
                className="dropdown-item"
                onClick={() => {
                  this.toggle(LANDING_PAGE_BLOCK_TYPE_APPS);
                  this.toggleAddSection();
                }}
              >
                App Collection (Grid)
              </a>
              <a
                className="dropdown-item form-control"
                onClick={() => {
                  this.toggle(LANDING_PAGE_FEATURED_COLLECTION);
                  this.toggleAddSection();
                }}
              >
                {LANDING_PAGE_FEATURED_COLLECTION}
              </a>
            </div>
          </div>
        </div>

        <Modal
          isOpen={modal}
          toggle={this.toggle}
          className="landing-create-popup w-100"
          backdrop="static"
        >
          <div className="mb-4"></div>
          <ModalHeader
            toggle={this.toggle}
            cssModule={{ "modal-title": "w-100 text-center" }}
          >
            <span className="text-center">{this.getModalNameByType(type)}</span>
          </ModalHeader>

          <Form
            initialValues={{
              title: ""
            }}
            onSubmit={values => {
              values.title = values.title;
              values.type = type;
              values.sort = this.props.sortOrder;
              values.landingPageId = landingPageId;
              this.landingPageBlockCreate(values);
              this.toggle();
            }}
          >
            <ModalBody>
              <div className="ml-3 mr-3 mt-2 mb-3">
                <div>
                  <Text
                    name="title"
                    label="Title"
                    placeholder="Enter title"
                    error=""
                    required
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="container-fluid">
                <div className="col-sm-12 text-center">
                  <Button type="submit" label="">
                    Add New
                  </Button>
                </div>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ addLandingPageBlock }, dispatch)
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(AddLandingPageBlockModal);
