import React from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Config
import { endpoints } from "../../configs";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import Page404 from "../Page404";
import AddAccountModal from "./inner-component/AddAccountModal";
import { AccountsIcon } from "../../assets/img/icons";

// Helper
import {
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  getParamsByName
} from "../../lib/helper";
import AvatarCard from "../../components/base/AvatarCard";

import {
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";
import classNames from "classnames";

//Action
import { fetchList } from "../../actions/table";
import CountBadge from "../../components/base/CountBadge";
import MoreDropdown from "../../components/base/MoreDropdown";
import DeleteModal from "../../components/base/DeleteModal";
import { apiClient } from "../../apiClient";
import { isBadRequest } from "../../common/http";
import Toast from "../../components/base/Toast";

//Constants
const ACCOUNTS_ALL_TAB = "All";
const ACCOUNTS_PARTNER_TAB = "Partner";
const ACCOUNTS_PROSPECT_TAB = "Prospect";

class Accounts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByOptions: [
        {
          value: "company_name:ASC",
          label: "Account Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ],
      activeTab: ACCOUNTS_ALL_TAB,
      deleteAccountModal: false,
      account: "",
      accountId: "",
      companyName: "",
      currentPage: 1,
      sort: "",
      sortDir: ""
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  //toggle
  toggle = tab => {
    this.setState({
      activeTab: tab
    });

    // this.props.history.push(`/users?type=${tab}`);
  };

  //render table by ID
  renderAccountTableById(id) {
    const { deleteAccountModal, account, accountId } = this.state;
    //Api url for diffrent tabs
    let type = "";
    if (id === ACCOUNTS_PARTNER_TAB) {
      type = ACCOUNTS_PARTNER_TAB;
    } else if (id === ACCOUNTS_PROSPECT_TAB) {
      type = ACCOUNTS_PROSPECT_TAB;
    }
    const searchItem = getParamsByName("search");

    let params = {
      searchItem: searchItem,
      type: type
    };
    return (
      <>
        <div>
          <DeleteModal
            id="account"
            isOpen={deleteAccountModal}
            toggle={() => {
              this.setState({ deleteAccountModal: false });
            }}
            title="Delete App"
            label={account}
            deleteFunction={() => this.handleDelete(accountId, params, id)}
          />
        </div>
        <div className="mt-4 mb-5">
          <ReduxTable
            id={id}
            params={params}
            apiURL={`${endpoints().accountAPI}/search`}
            onRowClick={row =>
              this.props.history.push(`/account/edit/${row.id}`)
            }
            searchPlaceholder="Search"
            sortByOptions={this.state.sortByOptions}
            newTableHeading
            showHeader
            icon={<AccountsIcon />}
            message="Get started by selecting New Account."
            paramsToUrl={true}
            history={this.props.history}
          >
            <ReduxColumn
              Width={"160px"}
              type="link"
              isClickable="false"
              field="companyName"
              sortBy="company_name"
              renderField={row => (
                <Link to={`/account/edit/${row.id}`}>
                  <AvatarCard
                    companyName={row.companyName}
                    url={row.avatarUrl}
                  />
                </Link>
              )}
            >
              Account Name
            </ReduxColumn>
            <ReduxColumn Width={"230px"} field="type" sortBy="type">
              Type
            </ReduxColumn>
            <ReduxColumn Width={"110px"} field="createdAt" sortBy="createdAt">
              CreatedAt
            </ReduxColumn>
            <ReduxColumn
              width="70px"
              field="Action"
              disableOnClick
              renderField={row => (
                <div className="text-center landing-group-dropdown">
                  <MoreDropdown>
                    <DropdownItem
                      className="font-weight-bold text-danger"
                      onClick={() =>
                        this.setState({
                          deleteAccountModal: true,
                          account: row.companyName,
                          accountId: row.id
                        })
                      }
                    >
                      Delete
                    </DropdownItem>
                  </MoreDropdown>
                </div>
              )}
            >
              Action
            </ReduxColumn>
          </ReduxTable>
        </div>
      </>
    );
  }
  // App delete api call
  handleDelete = async accountId => {
    return apiClient
      .delete(`${endpoints().accountAPI}/${accountId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          Toast.success(successMessage);
        }
        this.props.actions.fetchList(
          ACCOUNTS_PROSPECT_TAB,
          "/v1/account/search",
          1,
          10,
          {
            pagination: true,
            type: ACCOUNTS_PROSPECT_TAB
          }
        );
        this.props.actions.fetchList(
          ACCOUNTS_PARTNER_TAB,
          "/v1/account/search",
          1,
          10,
          {
            pagination: true,
            type: ACCOUNTS_PARTNER_TAB
          }
        );
        this.props.actions.fetchList(
          ACCOUNTS_ALL_TAB,
          "/v1/account/search",
          1,
          10,
          { pagination: true }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  render() {
    const { activeTab } = this.state;
    const { allTabCount, partnerTabCount, prospectTabCount } = this.props;
    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId)
    ) {
      return <Page404 />;
    }
    const search = window.location.search;
    const pathParams = new URLSearchParams(search);
    const searchItem = pathParams.get("search");
    let params = {
      searchItem: searchItem,
      pagination: true,
      search: getParamsByName("search")
    };

    return (
      <>
        <AddAccountModal params={params} />

        {/* Render table */}
        <Nav tabs className="admin-tabs mb-0">
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === ACCOUNTS_ALL_TAB
              })}
              onClick={() => {
                this.toggle(ACCOUNTS_ALL_TAB);
              }}
            >
              All
              <CountBadge
                count={allTabCount}
                isActive={activeTab === ACCOUNTS_ALL_TAB}
              />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === ACCOUNTS_PARTNER_TAB
              })}
              onClick={() => {
                this.toggle(ACCOUNTS_PARTNER_TAB);
              }}
            >
              Partner
              <CountBadge
                count={partnerTabCount}
                isActive={activeTab === ACCOUNTS_PARTNER_TAB}
              />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === ACCOUNTS_PROSPECT_TAB
              })}
              onClick={() => {
                this.toggle(ACCOUNTS_PROSPECT_TAB);
              }}
            >
              Prospect
              <CountBadge
                count={prospectTabCount}
                isActive={activeTab === ACCOUNTS_PROSPECT_TAB}
              />
            </NavLink>
          </NavItem>
        </Nav>

        {/* Tab contents */}
        <TabContent activeTab={activeTab}>
          {/* All tab */}
          <TabPane tabId={ACCOUNTS_ALL_TAB}>
            {this.renderAccountTableById(ACCOUNTS_ALL_TAB)}
          </TabPane>

          {/* Partner tab */}
          <TabPane tabId={ACCOUNTS_PARTNER_TAB}>
            {this.renderAccountTableById(ACCOUNTS_PARTNER_TAB)}
          </TabPane>

          {/* Prospect tab */}
          <TabPane tabId={ACCOUNTS_PROSPECT_TAB}>
            {this.renderAccountTableById(ACCOUNTS_PROSPECT_TAB)}
          </TabPane>
        </TabContent>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { account } = state.table;

  const { roleId } = state.user;

  //Total count
  const allTabCount =
    state.table[ACCOUNTS_ALL_TAB] && !state.table[ACCOUNTS_ALL_TAB].isFetching
      ? state.table[ACCOUNTS_ALL_TAB].totalCount
      : 0;

  const partnerTabCount =
    state.table[ACCOUNTS_PARTNER_TAB] &&
    !state.table[ACCOUNTS_PARTNER_TAB].isFetching
      ? state.table[ACCOUNTS_PARTNER_TAB].totalCount
      : 0;

  const prospectTabCount =
    state.table[ACCOUNTS_PROSPECT_TAB] &&
    !state.table[ACCOUNTS_PROSPECT_TAB].isFetching
      ? state.table[ACCOUNTS_PROSPECT_TAB].totalCount
      : 0;
  const currentPage = account && !account.isFetching ? account.currentPage : 1;

  return {
    allTabCount,
    partnerTabCount,
    prospectTabCount,
    currentPage,
    roleId
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(Accounts);
