import React from "react";
import { CrossIcon } from "../../assets/img/icons";

const AlertBanner = props => {
  const { id, hideCrossIcon, isloading, terms_accepted_at } = props;
  let banner = isloading ? false : terms_accepted_at ? false : true;

  return (
    <>
      {banner && (
        <div className="mb-3">
          <div className="row alert-banner">
            <div className={"col-1 col-sm-1 col-md-1 col-lg-1 alert-icon"}>
              {props.icon}
            </div>
            <div
              className={
                !props.removeOnClick && props.buttonLabel
                  ? "col-9 col-sm-9 col-md-9 col-lg-9 alert-option"
                  : "col-7 col-sm-7 col-md-7 col-lg-7 alert-option"
              }
            >
              <p className="mt-1 pt-1 font-weight-bold mr-auto text-white">
                {props.message}
                {props.content}
              </p>
            </div>
            <div
              className={
                !props.removeOnClick && props.buttonLabel
                  ? "col-2 col-sm-2 col-md-2 col-lg 2 alert-option d-flex justify-content-end"
                  : "col-3 col-sm-3 col-md-3 col-lg 3 alert-option d-flex justify-content-end"
              }
            >
              <div className="btn-wrapper ml-auto">
                <a id={id}>
                  <button
                    type="button"
                    className="p-2 btn font-weight-bold outline-bold text-white p-0 plain-btn"
                    onClick={props.buttonOnClick}
                    style={{ border: "2px solid white" }}
                  >
                    {props.buttonLabel}
                  </button>
                </a>
              </div>
            </div>
            {props.removeOnClick && (
              <div className="col-1 col-sm-1 col-md-1 col-lg-1 d-flex justify-content-end alert-icon">
                {!hideCrossIcon && (
                  <div className="close-alert mr-2 ml-2">
                    <button
                      className="btn-plain btn text-white p-0"
                      type="button"
                      onClick={props.removeOnClick}
                    >
                      <CrossIcon />
                    </button>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default AlertBanner;
