import React, { useEffect } from "react";
import { CrossIcon, MaximizeAltIcon } from "../assets/img/icons";
import Avatar from "./base/Avatar";
import { Input, Label } from "reactstrap";
import SampleProjectTooltip from "../views/projects/inner-components/SampleProjectTooltip";
import LazyLoad, { forceVisible } from "react-lazyload";

const SimplePartnerCard = props => {
  const {
    addPartner,
    showDeleteIcon,
    handlePartners,
    selected,
    currentUserId,
    partnerUrl
  } = props;
  const { id, avatarUrl, companyName } = props.data;
  const TagName = props.tag || "div";
  const tagNameForProps =
    addPartner && TagName === "label" ? { htmlFor: `partner${id}` } : {};

  useEffect(() => {
    forceVisible();
  }, []);

  let type;
  return (
    <div className={["simple-expert-card"].join(" ")}>
      <div
        className={[
          "card-header",
          "bg-transparent",
          "d-flex justify-content-between",
          "align-items-center"
        ].join(" ")}
      >
        <span className="d-flex">
          {showDeleteIcon && (
            <span
              onClick={() => handlePartners(id, (type = "remove"))}
              className={["d-block", "mr-2"].join(" ")}
            >
              <CrossIcon />
            </span>
          )}

          {addPartner && (
            <div className="checkbox-wrapper m-0">
              <Label htmlFor={`partner${id}`}>
                <Input
                  id={`partner${id}`}
                  name={`partner${id}`}
                  onChange={() => {
                    handlePartners(id);
                  }}
                  value={`partner${id}`}
                  type="checkbox"
                  checked={selected}
                />{" "}
                <span
                  className={`checkbox-placeholder mx-0 mr-2 p-0 ${
                    selected ? "active" : ""
                  }`}
                />
              </Label>
            </div>
          )}

          <TagName
            className={[
              "user-and-title",
              "d-flex",
              "align-items-center",
              "mb-0"
            ].join(" ")}
            {...tagNameForProps}
          >
            <div className={["mr-2"].join(" ")}>
              <LazyLoad>
                <Avatar
                  url={avatarUrl}
                  firstName={companyName}
                  lastName={""}
                  size="xs"
                />
              </LazyLoad>
            </div>
            <div className={["user-title"].join(" ")}>
              <p className={["h6-5", "m-0", "font-weight-bold"].join(" ")}>
                {currentUserId ? (
                  currentUserId === id ? (
                    "You"
                  ) : (
                    <>{companyName}</>
                  )
                ) : (
                  <>{companyName}</>
                )}
              </p>
            </div>
          </TagName>
        </span>
        {partnerUrl && (
          <a
            href={partnerUrl}
            target="_blank"
            id={`view-profile-${id}`}
            className="d-block pull-right mt-1"
            rel="noreferrer noopener"
          >
            <MaximizeAltIcon />
            <SampleProjectTooltip
              tooltipId={`view-profile-${id}`}
              placement={"top"}
            >
              <p className="font-weight-bold">View Full Profile</p>
            </SampleProjectTooltip>
          </a>
        )}
      </div>
    </div>
  );
};

export default SimplePartnerCard;
