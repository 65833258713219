import getSymbolFromCurrency from "currency-symbol-map";
import { COOKIE_DEFAULT_CURRENCY } from "./cookie";
import { getCookie } from "./helper";

export const GetCurrencySymbol = () => {
  let currency = getCookie(COOKIE_DEFAULT_CURRENCY);
  if (currency) {
    return getSymbolFromCurrency(currency);
  } else {
    return "";
  }
};
