import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { SuccessCheckIcon } from "../../../assets/img/icons";

const ProfileCompletedModal = props => {
  let { modal, setModal } = props;
  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      className={["log-hours-modal", "edit-task-modal"].join(" ")}
      backdrop="static"
    >
      <ModalHeader toggle={setModal}>
        <SuccessCheckIcon />
        <h4 className={["font-weight-bold", "my-4 mx-5 px-3"].join(" ")}>
          Success! All application tasks are complete.
        </h4>
      </ModalHeader>
      <ModalBody className={["text-center", "mb-4"].join(" ")}>
        <div className="form-wrapper">
          <div className="field-wrapper">
            <p className={"px-3"}>
              You'll get a notification when your application has been approved
              or if we need to get more information.
            </p>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default ProfileCompletedModal;
