import React from "react";
import SVG from "react-inlinesvg";
import searchIcon from "./nav-icon-search.svg";
import favoritesIcon from "./nav-icon-favorites.svg";
import accountsIcon from "./nav-icon-accounts.svg";
import contactsIcon from "./nav-icon-contacts.svg";
import reportIcon from "./nav-icon-reports.svg";

import helpIcon from "./nav-icon-help.svg";
import notificationIcon from "./nav-icon-notifications.svg";
import projectsIcon from "./nav-icon-projects.svg";
import playbookIcon from "./nav-icon-playbooks.svg";
import customerIcon from "./nav-icon-users.svg";
import mdfIcon from "./icon-mdf.svg";
import campaignIcon from "./nav-icon-campaigns.svg";

import envelopeIcon from "./icon-email.svg";
import envelopeOpenIcon from "./icon-email-open.svg";
import moreIconVertical from "./icon-more-vertical.svg";
import chevronDownIcon from "./icon-chevron-down.svg";
import chevronUpIcon from "./icon-chevron-up.svg";
import chevronLeftIcon from "./icon-chevron-left.svg";
import chevronRightIcon from "./icon-chevron-right.svg";
import arrowLeftIcon from "./icon-arrow-left.svg";
import arrowRightIcon from "./icon-arrow-right.svg";
import arrowUpIcon from "./icon-arrow-up.svg";
import questionsIcon from "./icon-questions-icon.svg";
import brochureIcon from "./icon-file-icon.svg";
import pulseIcon from "./icon-pulse.svg";
import checkmarkIcon from "./icon-check.svg";
import checkmarkCircleIcon from "./icon-check-circle.svg";
import clockIcon from "./icon-clock.svg";
import refreshcwIcon from "./icon-refresh-cw.svg";
import checkBoxOff from "./icon-checkbox-off.svg";
import checkBoxOn from "./icon-checkbox-on.svg";
import copyWriterIcon from "./icon-edit.svg";
import uXIcon from "./icon-thumbs-up.svg";
import webDesignIcon from "./icon-trello.svg";
import illustrationIcon from "./icon-pen-tool.svg";
import graphicDIcon from "./icon-layout.svg";
import infoTextIcon from "./icon-info.svg";
import swissKnife from "./icon-swiss-knife.svg";
import projectType from "./icon-project-type.svg";
import projectTimeline from "./icon-timeline.svg";
import calendarIcon from "./icon-calendar.svg";
import womanIcon from "./icon-woman.svg";
import worldIcon from "./icon-world.svg";
import handsIcon from "./icon-hands.svg";
import filePlusIcon from "./icon-file-plus.svg";
import htmlFileIcon from "./icon-html-file.svg";
import fileIcon from "./icon-file.svg";
import plusIcon from "./icon-plus.svg";
import userIcon from "./icon-user.svg";
import userPlusIcon from "./icon-user-plus.svg";
import activityIcon from "./icon-activity.svg";
import dollarIcon from "./icon-dollar-sign.svg";
import sendIcon from "./icon-send.svg";
import messageCircleIcon from "./icon-message-circle.svg";
import checkboxOnIconAlt from "./icon-checkbox-on-alt.svg";
import checkboxOffIcon from "./icon-checkbox-off.svg";
import locationIcon from "./icon-location.svg";
import crossIcon from "./icon-cross.svg";
import exclamationIcon from "./icon-exclamation.svg";
import octagonAlertIcon from "./icon-alert-octagon.svg";
import sportVestIcon from "./icon-vest-sport.svg";
import editIconAlt from "./icon-edit-alt.svg";
import editIconSimple from "./icon-edit-simple.svg";
import trashIcon from "./icon-trash.svg";
import trashIconAlt from "./icon-trash-alt.svg";
import attachIcon from "./icon-paperclip.svg";
import smileIcon from "./icon-smile.svg";
import gifIcon from "./icon-gif.svg";
import imageIcon from "./icon-image.svg";
import watchIcon from "./icon-watch.svg";
import largeWatchIcon from "./icon-large-watch.svg";
import downloadIcon from "./icon-download.svg";
import logoIcon from "./icon-logo-dark.svg";
import infoIcon from "./icon-basic-info.svg";
import homeIcon from "./icon-home.svg";
import maximizeAltIcon from "./icon-maximize-2.svg";
import cloudUploadIcon from "./icon-upload-cloud.svg";
import blueMessageIcon from "./icon-message.svg";
import starIcon from "./icon-star.svg";
import starIconFilled from "./icon-star-filled.svg";
import awardIcon from "./icon-award.svg";
import warningRedIcon from "./icon-check-circle.png";
import intersectIcon from "./intersect.png";
import successCheckIcon from "./icon-check-circle-success.png";
import dashboardCalendarIcon from "./icon-dashboard-calendar.svg";
import dashboardProfileCompletionIcon from "./icon-dashboard-profile-completion.svg";
import dashboardProjectIcon from "./icon-dashboard-project.svg";
import dashboardLinksIcon from "./icon-dashboard-link.svg";
import dashboardDealRegisteredIcon from "./icon-dashboard-deal-registered.svg";
import dashboardDealPendingIcon from "./icon-dashboard-deal-pending.svg";
import dashboardCommissionEarnedIcon from "./icon-dashboard-commission-earned.svg";
import dashboardCommissionProjectedIcon from "./icon-dashboard-commission-projected.svg";
import dashboardProfileIcon from "./icon-dashboard-profile.svg";
import dashboardLeadDistributedIcon from "./icon-dashboard-lead-distributed.svg";
import dashboardPartnerIcon from "./icon-dashboard-partner.svg";
import dashboardInboundReferralIcon from "./icon-dashboard-inbound-referral.svg";
import dashboardResourceIcon from "./icon-dashboard-resource.svg";
import dashboardFeedbackIcon from "./icon-dashboard-feedback.svg";
import dashboardCampaignIcon from "./icon-dashboard-campaign.svg";
import slackIcon from "./nav-icon-slack.svg";
import referralImg from "./referral-img.svg";
import blueJeansIcon from "./icon-blue-jeans.svg";
import mondayDotComIcon from "./icon-monday-dot-com.svg";
import rocketGraphicIcon from "./icon-graphic-rocket.svg";
import expertProIcon from "./icon-pro-expert.svg";
import expertEliteIcon from "./icon-elite-expert.svg";
import expertCertifiedIcon from "./icon-certified-expert.svg";
import expertIcon from "./icon-expert.svg";
import matchedGraphicIcon from "./icon-graphic-matched.svg";
import congratsGraphicIcon from "./icon-graphic-congrats.svg";
import calendarGraphicIcon from "./icon-graphic-calendar.svg";
import coffeeGraphicIcon from "./icon-graphic-coffee.svg";
import doubleClapGraphicIcon from "./icon-double-clap.svg";
import campaignsNorecordsIcon from "./icon-campaigns.svg";
import funnelFill from "./funnel-fill-icon.svg";

import torchliteLogoDark from "../../logo.svg";

import salesforceIcon from "./icon-salesforce.svg";
import googleIcon from "./google.svg";
import amazonIcon from "./amazon.svg";
import microsoftIcon from "./microsoft.svg";
import officeIcon from "./icon-office.svg";
import linkedInIcon from "./icon-linkedin.svg";
import instagramIcon from "./icon-instagram.svg";
import youtubeIcon from "./icon-youtube.svg";
import facebookIcon from "./icon-facebook.svg";
import twitterIcon from "./icon-twitter.svg";
import globeIcon from "./icon-globe.svg";
import stackIcon from "./icon-stack.svg";
import privateIcon from "./icon-private.svg";
import auth0Icon from "./icon-auth0.svg";
import oktaIcon from "./icon-okta.svg";

import helpGraphicIcon from "./icon-help.svg";
import eyeIcon from "./icon-eye.svg";

import creditCard from "./icon-credit-card.svg";
import paypalIcon from "./icon-ppcom.svg";

import visaIcon from "./icon-visa.png";
import mastercardIcon from "./icon-mastercard.svg";
import amexIcon from "./icon-amex.png";

import sunRiseIcon from "./icon-sunrise.svg";
import sunIcon from "./icon-sun.svg";
import moonIcon from "./icon-moon.svg";

import menuIcon from "./icon-menu.svg";
import penTool from "./icon-pen-tool.svg";
import shoppingBag from "./icon-shopping-bag.svg";
import elitePartner from "./icon-elite-partner.svg";

import introIcon from "./icon-intro.svg";
import dealRegistrationIcon from "./icon-deal-registration.svg";
import locationWhiteIcon from "./icon-deal-registration.svg";
import settingsSalesforceIcon from "./icon-settings-salesforce.svg";
import settingsHubspotIcon from "./icon-settings-hubspot.svg";
import settingsRambleChatIcon from "./icon-settings-ramblechat.svg";
import settingsBillDotComIcon from "./icon-settings-biil-dot-com.svg";
import settingsCrossbeamIcon from "./icon-settings-crossbeam.svg";
import settingsRevealIcon from "./icon-settings-reveal-icon.svg";
import settingsStripeIcon from "./icon-settings-strip-icon.svg";
import settingsGoogleAnalyticsIcon from "./icon-settings-google-analytics.svg";
import settingsMetaCxIcon from "./icon-settings-metacx.svg";

import lockIcon from "./lock.png";

import arrowUp from "./arrow-up.svg";
import marketplaceIcon from "./icon-marketplace.svg";
import dealsIcon from "./icon-deals.svg";
import registeredDealsIcon from "./icon-dashboard-registered-deal.svg";
import pendingDealsIcon from "./icon-dashboard-pending-deal.svg";
import commissionsEarnedIcon from "./icon-dashboard-commissions-earned.svg";
import projectedCommissionIcon from "./icon-dashboard-projected-commission.svg";
import referralIcon from "./icon-referral.svg";
import quicklinkReferralIcon from "./icon-quicklink-referral.svg";
import profileCompletingIcon from "./icon-profile-completing.svg";
import partnerSearchIcon from "./icon-partner-search.svg";
import accountMappingIcon from "./icon-account-mapping.svg";

import gridIcon from "./icon-grid.svg";
import listIcon from "./icon-list.svg";

import gmailIcon from "./gmail-icon.svg";
import arrowRightUp from "./arrow-right-up.svg";

const SunriseIcon = () => <SVG src={sunRiseIcon} />;
const SunIcon = () => <SVG src={sunIcon} />;
const MoonIcon = () => <SVG src={moonIcon} />;

const CreditCardIcon = () => <SVG src={creditCard} />;
const PaypalIcon = () => <SVG src={paypalIcon} />;
const SearchIcon = () => <SVG src={searchIcon} alt="search" />;
const FavoritesIcon = () => <SVG src={favoritesIcon} alt="favorites" />;
const AccountsIcon = () => <SVG src={accountsIcon} alt="accounts" />;
const ContactsIcon = () => <SVG src={contactsIcon} alt="accounts" />;
const ReportIcon = () => <SVG src={reportIcon} alt="accounts" />;
const Referral = () => <SVG src={referralImg} alt="referral" />;

const HelpIcon = ({ headerTextColor }) => (
  <SVG src={helpIcon} fill={headerTextColor} alt="help" />
);
const NotificationIcon = () => (
  <SVG src={notificationIcon} alt="notifications" />
);
const EnvelopeIcon = () => <SVG src={envelopeIcon} alt="envelope" />;
const EnvelopeIconOpen = () => (
  <SVG src={envelopeOpenIcon} alt="envelope open" />
);
const MoreIconVertical = () => <SVG src={moreIconVertical} alt="more" />;
const ChevronDown = () => <SVG src={chevronDownIcon} alt="chevron down" />;
const ChevronUp = () => <SVG src={chevronUpIcon} alt="chevron up" />;
const ChevronLeft = () => <SVG src={chevronLeftIcon} alt="chevron left" />;
const ChevronRight = () => <SVG src={chevronRightIcon} alt="chevron right" />;

const ArrowLeft = () => <SVG src={arrowLeftIcon} alt="arrow left" />;
const ArrowRight = () => <SVG src={arrowRightIcon} alt="arrow right" />;
const ArrowUpIcon = () => <SVG src={arrowUpIcon} />;

const CheckmarkIcon = () => <SVG src={checkmarkIcon} alt="checkmark" />;
const CheckmarkCircleIcon = () => (
  <SVG src={checkmarkCircleIcon} alt="checkmark" />
);
const ClockIcon = () => <SVG src={clockIcon} alt="clock" />;
const RefreshCW = () => <SVG src={refreshcwIcon} alt="refresh" />;

const InlineInfoIcon = () => <SVG src={infoTextIcon} />;

const CheckBoxOff = () => <SVG src={checkBoxOff} alt="unchecked" />;
const CheckBoxOn = () => <SVG src={checkBoxOn} alt="checked" />;

const CopyWriterIcon = () => <SVG src={copyWriterIcon} />;
const UXIcon = () => <SVG src={uXIcon} />;
const WebDesignIcon = () => <SVG src={webDesignIcon} />;
const IllustrationIcon = () => <SVG src={illustrationIcon} />;
const GraphicDIcon = () => <SVG src={graphicDIcon} />;

// used in admin panels
const FilePlusIcon = () => <SVG src={filePlusIcon} />;
const HtmlFileIcon = () => <SVG src={htmlFileIcon} />;
const PlusIcon = () => <SVG src={plusIcon} />;
const FunnelFill = () => <SVG src={funnelFill} />;

// expert wizard
const LogoIcon = () => <SVG src={logoIcon} />;
const InfoIcon = () => <SVG src={infoIcon} />;
const SwissKnifeIcon = () => <SVG src={swissKnife} />;
const ProjectTypeIcon = () => <SVG src={projectType} />;
const TimelineIcon = () => <SVG src={projectTimeline} />;
const CalendarIcon = () => <SVG src={calendarIcon} />;
const WomanIcon = () => <SVG src={womanIcon} />;
const WorldIcon = () => <SVG src={worldIcon} />;
const HandsIcon = () => <SVG src={handsIcon} />;

const UserIcon = () => <SVG src={userIcon} />;
const UserPlusIcon = () => <SVG src={userPlusIcon} />;
const ActivityIcon = () => <SVG src={activityIcon} />;
const DollarIcon = () => <SVG src={dollarIcon} />;
const SendIcon = () => <SVG src={sendIcon} />;
const MessageCircleIcon = () => <SVG src={messageCircleIcon} />;

const CrossIcon = () => <SVG src={crossIcon} />;
const ExclamationIcon = () => <SVG src={exclamationIcon} />;
const CheckboxOnIconAlt = () => <SVG src={checkboxOnIconAlt} />;
const CheckboxOffIcon = () => <SVG src={checkboxOffIcon} />;
const LocationIcon = () => <SVG src={locationIcon} />;
const LocationWhiteIcon = () => <SVG src={locationWhiteIcon} />;
const OctagonAlertIcon = () => <SVG src={octagonAlertIcon} />;
const SportVestIcon = () => <SVG src={sportVestIcon} />;
const EditIconAlt = () => <SVG src={editIconAlt} />;
const EditIconSimple = () => <SVG src={editIconSimple} />;
const TrashIcon = () => <SVG src={trashIcon} />;
const TrashIconAlt = () => <SVG src={trashIconAlt} />;
const AttachIcon = () => <SVG src={attachIcon} />;
const SmileIcon = () => <SVG src={smileIcon} />;
const GifIcon = () => <SVG src={gifIcon} />;
const WatchIcon = () => <SVG src={watchIcon} />;
const LargeWatchIcon = () => <SVG src={largeWatchIcon} />;
const DownloadIcon = () => <SVG src={downloadIcon} />;
const ImageIcon = () => <SVG src={imageIcon} />;
const FileIcon = () => <SVG src={fileIcon} />;
const HomeIcon = () => <SVG src={homeIcon} />;
const ProjectsIcon = () => <SVG src={projectsIcon} />;
const PlaybookIcon = () => <SVG src={playbookIcon} />;
const CustomerIcon = () => <SVG src={customerIcon} />;
const MdfIcon = () => <SVG src={mdfIcon} />;
const CampaignIcon = () => <SVG src={campaignIcon} />;
const MaximizeAltIcon = () => <SVG src={maximizeAltIcon} />;
const CloudUploadIcon = () => <SVG src={cloudUploadIcon} />;
const BlueMessageIcon = () => <SVG src={blueMessageIcon} />;
const StarIcon = () => <SVG src={starIcon} />;
const StarIconFilled = () => <SVG src={starIconFilled} />;
const BlueJeansIcon = () => <SVG src={blueJeansIcon} />;
const MondayDotComIcon = () => <SVG src={mondayDotComIcon} />;
const OktaIcon = () => <SVG src={oktaIcon} />;

const AwardIcon = () => <SVG src={awardIcon} />;
const WarningRedIcon = () => (
  <img src={warningRedIcon} width={50} height={50} alt="warning red icon" />
);
const IntersectIcon = () => (
  <img src={intersectIcon} width={25} height={25} alt="intersect icon" />
);
const SuccessCheckIcon = () => (
  <img src={successCheckIcon} width={50} height={50} alt="success icon" />
);

const RocketGraphicIcon = () => <SVG src={rocketGraphicIcon} />;
const ExpertProIcon = () => <SVG src={expertProIcon} />;
const ExpertEliteIcon = () => <SVG src={expertEliteIcon} />;
const ExpertCertifiedIcon = () => <SVG src={expertCertifiedIcon} />;
const ExpertIcon = () => <SVG src={expertIcon} />;
const MatchedGraphicIcon = () => <SVG src={matchedGraphicIcon} />;
const CongratsGraphicIcon = () => <SVG src={congratsGraphicIcon} />;
const CalendarGraphicIcon = () => <SVG src={calendarGraphicIcon} />;
const CoffeeGraphicIcon = () => <SVG src={coffeeGraphicIcon} />;
const DoubleClapGraphicIcon = () => <SVG src={doubleClapGraphicIcon} />;
const CampaignsNorecordsIcon = () => <SVG src={campaignsNorecordsIcon} />;

const TorchliteLogoDark = () => <SVG src={torchliteLogoDark} />;

const SalesforceIcon = () => <SVG src={salesforceIcon} />;
const GoogleIcon = () => <SVG src={googleIcon} />;
const AmazonIcon = () => <SVG src={amazonIcon} />;
const MicrosoftIcon = () => <SVG src={microsoftIcon} />;
const OfficeIcon = () => <SVG src={officeIcon} />;
const LinkedinIcon = () => <SVG src={linkedInIcon} />;
const YoutubeIcon = () => <SVG src={youtubeIcon} />;
const Auth0Icon = () => <SVG src={auth0Icon} />;

const HelpGraphicIcon = () => <SVG src={helpGraphicIcon} />;

const InstagramIcon = () => <SVG src={instagramIcon} />;
const FacebookIcon = () => <SVG src={facebookIcon} />;
const TwitterIcon = () => <SVG src={twitterIcon} />;
const GlobeIcon = () => <SVG src={globeIcon} />;
const StackIcon = () => <SVG src={stackIcon} />;
const PrivateIcon = () => <SVG src={privateIcon} />;

const EyeIcon = () => <SVG src={eyeIcon} />;
const VisaIcon = () => <img src={visaIcon} alt={"visa"} />;
const MastercardIcon = () => <SVG src={mastercardIcon} />;
const AmexIcon = () => <img src={amexIcon} alt={"amex"} />;

const HamburgerMenu = () => <SVG src={menuIcon} />;

const PenTool = () => <SVG src={penTool} />;
const ShoppingBag = () => <SVG src={shoppingBag} />;
const SlackIcon = () => <SVG src={slackIcon} />;
const IntroIcon = () => <SVG src={introIcon} />;
const DealRegistrationIcon = () => <SVG src={dealRegistrationIcon} />;
const ElitePartner = () => <SVG src={elitePartner} />;
const DashboardCalendarIcon = () => <SVG src={dashboardCalendarIcon} />;
const DashboardProfileCompletionIcon = () => (
  <SVG src={dashboardProfileCompletionIcon} />
);
const DashboardProjectIcon = () => <SVG src={dashboardProjectIcon} />;
const DashboardLinksIcon = () => <SVG src={dashboardLinksIcon} />;
const DashboardDealRegisteredIcon = () => (
  <SVG src={dashboardDealRegisteredIcon} />
);
const DashboardDealPendingIcon = () => <SVG src={dashboardDealPendingIcon} />;
const DashboardCommissionEarnedIcon = () => (
  <SVG src={dashboardCommissionEarnedIcon} />
);
const DashboardCommissionProjectedIcon = () => (
  <SVG src={dashboardCommissionProjectedIcon} />
);
const DashboardProfileIcon = () => <SVG src={dashboardProfileIcon} />;
const DashboardLeadDistributedIcon = () => (
  <SVG src={dashboardLeadDistributedIcon} />
);
const DashboardPartnerIcon = () => <SVG src={dashboardPartnerIcon} />;
const DashboardInboundReferralIcon = () => (
  <SVG src={dashboardInboundReferralIcon} />
);
const DashboardQuickLinkReferralIcon = () => (
  <SVG src={quicklinkReferralIcon} />
);
const DashboardProfileCompletingIcon = () => (
  <SVG src={profileCompletingIcon} />
);
const DashboardResourceIcon = () => <SVG src={dashboardResourceIcon} />;
const DashboardCampaignIcon = () => <SVG src={dashboardCampaignIcon} />;
const SettingsSalesforceIcon = () => <SVG src={settingsSalesforceIcon} />;
const SettingsHubspotIcon = () => <SVG src={settingsHubspotIcon} />;
const SettingsRambleChatIcon = () => <SVG src={settingsRambleChatIcon} />;
const SettingsBillDotComIcon = () => <SVG src={settingsBillDotComIcon} />;
const SettingsCrossbeamIcon = () => <SVG src={settingsCrossbeamIcon} />;
const SettingsRevealIcon = () => <SVG src={settingsRevealIcon} />;
const SettingsStripeIcon = () => <SVG src={settingsStripeIcon} />;
const SettingsGoogleAnalyticsIcon = () => (
  <SVG src={settingsGoogleAnalyticsIcon} />
);
const SettingsMetaCxIcon = () => <SVG src={settingsMetaCxIcon} />;

const DashboardFeedbackIcon = () => <SVG src={dashboardFeedbackIcon} />;
const MarketplaceIcon = () => <SVG src={marketplaceIcon} />;
const LockIcon = () => (
  <img src={lockIcon} width={25} height={30} alt="lock icon" />
);

const ArrowUp = () => <SVG src={arrowUp} />;
const DealIcon = () => <SVG src={dealsIcon} />;
const RegisteredDealIcon = () => <SVG src={registeredDealsIcon} />;
const PendingDealIcon = () => <SVG src={pendingDealsIcon} />;
const CommissionsEarnedIcon = () => <SVG src={commissionsEarnedIcon} />;
const ProjectedCommissionIcon = () => <SVG src={projectedCommissionIcon} />;
const ReferralIcon = () => <SVG src={referralIcon} />;
const PartnerSearchIcon = () => <SVG src={partnerSearchIcon} />;
const AccountMappingIcon = () => <SVG src={accountMappingIcon} />;
const QuestionsIcon = () => <SVG src={questionsIcon} />;
const BrochureIcon = () => <SVG src={brochureIcon} />;
const PulseIcon = () => <SVG src={pulseIcon} width={22} height={20} />;
const PartnerCommunicationIcon = () => <i className="far fa-envelope-open"></i>;
const CubeIcon = () => <i className="fa fa-cube"></i>;
const CircleIcon = () => <i className="fa fa-circle-thin"></i>;

const GridIcon = () => <SVG src={gridIcon} />;
const ListIcon = () => <SVG src={listIcon} />;

const GmailIcon = () => <SVG src={gmailIcon} />;
const ArrowRigthUp = () => <SVG src={arrowRightUp} />;
export {
  PartnerCommunicationIcon,
  SearchIcon,
  FavoritesIcon,
  AccountsIcon,
  ContactsIcon,
  ReportIcon,
  HelpIcon,
  NotificationIcon,
  EnvelopeIcon,
  EnvelopeIconOpen,
  MoreIconVertical,
  ChevronDown,
  ChevronLeft,
  ArrowLeft,
  ArrowRight,
  ArrowUpIcon,
  CheckmarkIcon,
  CampaignsNorecordsIcon,
  ClockIcon,
  RefreshCW,
  CheckBoxOff,
  CheckBoxOn,
  CopyWriterIcon,
  UXIcon,
  WebDesignIcon,
  IllustrationIcon,
  GraphicDIcon,
  LogoIcon,
  InfoIcon,
  SwissKnifeIcon,
  InlineInfoIcon,
  ProjectTypeIcon,
  TimelineIcon,
  CalendarIcon,
  WomanIcon,
  WorldIcon,
  HandsIcon,
  FilePlusIcon,
  PlusIcon,
  FunnelFill,
  ChevronUp,
  UserIcon,
  UserPlusIcon,
  ActivityIcon,
  DollarIcon,
  SendIcon,
  MessageCircleIcon,
  CheckboxOnIconAlt,
  CheckboxOffIcon,
  LocationIcon,
  CrossIcon,
  ExclamationIcon,
  OctagonAlertIcon,
  SportVestIcon,
  ChevronRight,
  EditIconAlt,
  TrashIcon,
  TrashIconAlt,
  AttachIcon,
  SmileIcon,
  GifIcon,
  WatchIcon,
  LargeWatchIcon,
  DownloadIcon,
  HtmlFileIcon,
  ImageIcon,
  FileIcon,
  HomeIcon,
  ProjectsIcon,
  PlaybookIcon,
  CustomerIcon,
  MdfIcon,
  CampaignIcon,
  MaximizeAltIcon,
  CloudUploadIcon,
  BlueMessageIcon,
  StarIcon,
  StarIconFilled,
  AwardIcon,
  RocketGraphicIcon,
  ExpertProIcon,
  ExpertEliteIcon,
  ExpertCertifiedIcon,
  ExpertIcon,
  MatchedGraphicIcon,
  CongratsGraphicIcon,
  CalendarGraphicIcon,
  CoffeeGraphicIcon,
  WarningRedIcon,
  IntersectIcon,
  CheckmarkCircleIcon,
  SuccessCheckIcon,
  DoubleClapGraphicIcon,
  TorchliteLogoDark,
  SalesforceIcon,
  GoogleIcon,
  OfficeIcon,
  LinkedinIcon,
  YoutubeIcon,
  Auth0Icon,
  HelpGraphicIcon,
  InstagramIcon,
  FacebookIcon,
  TwitterIcon,
  GlobeIcon,
  PrivateIcon,
  StackIcon,
  EditIconSimple,
  EyeIcon,
  CreditCardIcon,
  PaypalIcon,
  VisaIcon,
  MastercardIcon,
  SunriseIcon,
  SunIcon,
  MoonIcon,
  HamburgerMenu,
  AmexIcon,
  PenTool,
  ShoppingBag,
  ElitePartner,
  IntroIcon,
  DealRegistrationIcon,
  LocationWhiteIcon,
  DashboardCalendarIcon,
  DashboardProfileCompletionIcon,
  DashboardProjectIcon,
  DashboardLinksIcon,
  DashboardDealRegisteredIcon,
  DashboardDealPendingIcon,
  DashboardCommissionEarnedIcon,
  DashboardCommissionProjectedIcon,
  DashboardProfileIcon,
  DashboardLeadDistributedIcon,
  DashboardPartnerIcon,
  DashboardInboundReferralIcon,
  DashboardQuickLinkReferralIcon,
  DashboardProfileCompletingIcon,
  DashboardResourceIcon,
  DashboardCampaignIcon,
  SettingsGoogleAnalyticsIcon,
  SettingsMetaCxIcon,
  SettingsSalesforceIcon,
  SettingsHubspotIcon,
  SettingsCrossbeamIcon,
  SettingsRevealIcon,
  SettingsStripeIcon,
  SettingsRambleChatIcon,
  DashboardFeedbackIcon,
  LockIcon,
  ArrowUp,
  MarketplaceIcon,
  DealIcon,
  ReferralIcon,
  SettingsBillDotComIcon,
  PartnerSearchIcon,
  AccountMappingIcon,
  SlackIcon,
  BlueJeansIcon,
  MondayDotComIcon,
  OktaIcon,
  RegisteredDealIcon,
  PendingDealIcon,
  CommissionsEarnedIcon,
  ProjectedCommissionIcon,
  GridIcon,
  ListIcon,
  CubeIcon,
  QuestionsIcon,
  CircleIcon,
  BrochureIcon,
  PulseIcon,
  GmailIcon,
  Referral,
  ArrowRigthUp,
  AmazonIcon,
  MicrosoftIcon
};
