import React from "react";
import AddProjectForm from "./AddProjectForm";

// Components

const AddProject = props => {
  return (
    <div>
      <AddProjectForm history={props.history} />
    </div>
  );
};

export default AddProject;
