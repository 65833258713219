import React from "react";

// Components
import PageTitle from "../../components/base/PageTitle";
import ToastContainerComponent from "../../components/base/ToastContainer";
import BillingDetails from "./BillingDetails";
import PaymentOptions from "./PaymentOptions";

const AccountingSettings = props => {
  return (
    <div className={["accounting-settings"].join(" ")}>
      <PageTitle label="Accounting Settings" />
      <ToastContainerComponent />
      <BillingDetails title={"Billing Details"} />
      <PaymentOptions title={"Payment Options"} />
    </div>
  );
};

export default AccountingSettings;
