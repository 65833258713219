import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import AddButton from "../../components/base/AddButton";

import { DropdownItem } from "reactstrap";
// Components
import PageTitle from "../../components/base/PageTitle";

// Configs
import { endpoints } from "../../configs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MoreDropdown from "../../components/base/MoreDropdown";

import {
  addSalesforceSyncRule,
  updateSalesforceSyncRule,
  deleteSalesforceSyncRule
} from "../../actions/salesforceSyncRule";

import AddSyncRuleModal from "../Components/addSyncRuleModal";

import EditSynRuleModal from "../Components/editSyncRuleModal";

import DeleteModal from "../../components/base/DeleteModal";

const SalesforceSyncRule = props => {
  const [isSyncRuleAddModalOpen, setSyncRuleAddModalOpen] = useState(false);
  const [isSyncRuleEditModalOpen, setSyncRuleEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);

  const [selectedSyncRule, setSelectedSyncRule] = useState("");

  const { AccountObjectFieldList, OpportunityObjectFieldList } = props;

  const salesforceObjectNameList = () => [
    {
      label: "Opportunities",
      value: "Opportunities"
    },
    {
      label: "Account",
      value: "Account"
    }
  ];

  const relationalOperators = () => [
    {
      label: "Less than",
      value: "less_than"
    },
    {
      label: "Greater than",
      value: "greater_than"
    },
    {
      label: "Greater than or Equal to",
      value: "greater_than_or_equal_to"
    },
    {
      label: "Equal to",
      value: "equal_to"
    },
    {
      label: "Not Equal to",
      value: "not_equal_to"
    }
  ];

  const handleAddModal = () => {
    setSyncRuleAddModalOpen(!isSyncRuleAddModalOpen);
  };

  const handleEditModal = selectedSyncRule => {
    setSelectedSyncRule(selectedSyncRule);
  };
  const toggleEditModal = () => {
    setSyncRuleEditModalOpen(!isSyncRuleEditModalOpen);
  };

  const setOpenDeleteModal = () => {
    setDeleteModalOpen(!isDeleteModalOpen);
  };

  return (
    <>
      <AddSyncRuleModal
        isOpen={isSyncRuleAddModalOpen}
        toggle={handleAddModal}
        AccountObjectFieldList={AccountObjectFieldList}
        OpportunityObjectFieldList={OpportunityObjectFieldList}
        salesforceObjectNameList={salesforceObjectNameList}
        relationalOperators={relationalOperators}
        addFunction={props}
      />

      <EditSynRuleModal
        isOpen={isSyncRuleEditModalOpen}
        toggle={toggleEditModal}
        AccountObjectFieldList={AccountObjectFieldList}
        OpportunityObjectFieldList={OpportunityObjectFieldList}
        salesforceObjectNameList={salesforceObjectNameList}
        relationalOperators={relationalOperators}
        selectedSyncRule={selectedSyncRule}
        updateFunction={props}
      />

      <DeleteModal
        isOpen={isDeleteModalOpen}
        toggle={() => {
          setOpenDeleteModal();
        }}
        title="Delete Salesforce Sync Rule"
        id={selectedSyncRule ? selectedSyncRule.id : ""}
        label={selectedSyncRule ? selectedSyncRule.salesforceFieldName : ""}
        deleteFunction={props.actions.deleteSalesforceSyncRule}
      />

      <div className="d-flex justify-content-between">
        <PageTitle label="Sync Rule" />
        <div>
          <AddButton
            label="Add Sync Rule"
            className="pull-right btn btn-secondary"
            onClick={() => {
              handleAddModal();
            }}
          />
        </div>
      </div>

      <div className="mt-1 mb-5">
        <ReduxTable
          id="salesforceSyncRule"
          apiURL={`${endpoints().salesforceSyncRuleRoute}`}
          // onRowClick={row => {
          //   handleRowClick(row);
          // }}
          noRecordFoundHeight={"7vh"}
        >
          <ReduxColumn
            width={"200px"}
            field="salesforceObjectName"
            isClickable="true"
            disableOnClick
          >
            Salesforce Object
          </ReduxColumn>
          <ReduxColumn
            width={"200px"}
            field="salesforceFieldName"
            isClickable="true"
            disableOnClick
          >
            Salesforce Field
          </ReduxColumn>
          <ReduxColumn width={"150px"} disableOnClick field="operator">
            Condition
          </ReduxColumn>
          <ReduxColumn width={"120px"} field="value" disableOnClick>
            Value
          </ReduxColumn>
          <ReduxColumn
            width={"100px"}
            disableOnClick
            className="action-column"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      handleEditModal(row);
                      toggleEditModal();
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      handleEditModal(row);
                      setDeleteModalOpen(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addSalesforceSyncRule,
        updateSalesforceSyncRule,
        deleteSalesforceSyncRule
      },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(SalesforceSyncRule);
