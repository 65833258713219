import React from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  setExpertBadge,
  isCompanyManager,
  isPartner
} from "../../../lib/helper";
import { DEAL_PARTNER_STATUS_ACCEPTED } from "../../../deal/Constants";

// Components
import { Badge } from "reactstrap";
import SidebarItem from "./SidebarItem";
import Avatar from "../../../components/base/Avatar";
import AvatarCard from "../../../components/base/AvatarCard";

import { useSelector } from "react-redux";

const PartnerSection = ({
  dealPartnerList,
  currentUserId,
  onSourcePartner,
  isPartnerLoading,
  dealPartner,
  showStatus,
  allowSelectPartner
}) => {
  const currentUser = useSelector(state => state.user);

  return (
    <>
      <SidebarItem>
        <p className="font-weight-bold">Partner</p>
        {dealPartnerList && dealPartnerList.length ? (
          dealPartnerList.map(partner => (
            <>
              {partner.userId === currentUserId &&
              partner.status &&
              partner.status !== DEAL_PARTNER_STATUS_ACCEPTED ? (
                dealPartnerList.length === 1 ? (
                  <span className="radio-placeholder checkbox-placeholder">
                    <Link
                      target="_blank"
                      to={`/partner-profile/${partner.profile_url}`}
                    >
                      <AvatarCard
                        firstName={partner.companyName}
                        url={partner.avatar}
                        size="xs"
                        fontSize={12}
                      />
                    </Link>
                  </span>
                ) : (
                  ""
                )
              ) : (
                (isSuperAdmin(currentUser && currentUser.roleId) ||
                  isCompanyAdmin(currentUser && currentUser.roleId) ||
                  isPartner(currentUser && currentUser.roleId)) && (
                  <div className="field-wrapper">
                    <div className="user-wrapper">
                      <div className="field-icon">
                        <Link
                          target="_blank"
                          to={`/partner-profile/${partner.profile_url}`}
                        >
                          <LazyLoad>
                            <Avatar
                              firstName={partner.companyName}
                              size="xs"
                              fontSize={12}
                              url={partner.avatarUrl}
                            />
                          </LazyLoad>
                        </Link>
                      </div>

                      <div className="expert-info ml-2 d-flex flex-column align-items-start">
                        <p className="font-weight-bold">
                          <Link
                            target="_blank"
                            to={`/partner-profile/${partner.profile_url}`}
                          >
                            {partner.userId === currentUserId
                              ? "You"
                              : partner.companyName}
                          </Link>
                        </p>

                        {partner.status &&
                          partner.status !== DEAL_PARTNER_STATUS_ACCEPTED &&
                          !showStatus && (
                            <div
                              className={[
                                "status-tooltips d-flex",
                                `${partner.status.toLowerCase() ===
                                  "no response" && "align-items-start"}`
                              ].join(" ")}
                            >
                              <Badge
                                id={`status-tooltip-${partner.partnerId}`}
                                color={setExpertBadge(partner.status)}
                                className="text-white h7 font-weight-bold text-uppercase"
                              >
                                {partner.status}
                              </Badge>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>
                )
              )}
            </>
          ))
        ) : isPartnerLoading ? (
          "Loading..."
        ) : dealPartner ? (
          <div className="field-wrapper">
            <div className="user-wrapper">
              <div className="field-icon">
                <Link
                  target="_blank"
                  to={`/partner-profile/${dealPartner.profile_url}`}
                >
                  <LazyLoad>
                    <Avatar
                      firstName={dealPartner.companyName}
                      size="xs"
                      fontSize={12}
                      url={dealPartner.avatarUrl}
                    />
                  </LazyLoad>
                </Link>
              </div>

              <div className="expert-info ml-2 d-flex flex-column align-items-start">
                <p className="font-weight-bold">
                  <Link
                    target="_blank"
                    to={`/partner-profile/${dealPartner.profile_url}`}
                  >
                    {dealPartner.companyName}
                  </Link>
                </p>

                {dealPartner.status &&
                  dealPartner.status !== DEAL_PARTNER_STATUS_ACCEPTED &&
                  !showStatus && (
                    <div
                      className={[
                        "status-tooltips d-flex",
                        `${dealPartner.status.toLowerCase() === "no response" &&
                          "align-items-start"}`
                      ].join(" ")}
                    >
                      <Badge
                        id={`status-tooltip-${dealPartner.partnerId}`}
                        color={setExpertBadge(dealPartner.status)}
                        className="text-white h7 font-weight-bold text-uppercase"
                      >
                        {dealPartner.status}
                      </Badge>
                    </div>
                  )}
              </div>
            </div>
          </div>
        ) : (
          <span className="text-grayed">No partner assigned yet</span>
        )}
        {(isSuperAdmin(currentUser && currentUser.roleId) ||
          isCompanyAdmin(currentUser && currentUser.roleId) ||
          isCompanyManager(currentUser && currentUser.roleId)) && (
          <div>
            {!dealPartnerList.length && !allowSelectPartner && (
              <button
                className="btn btn-primary w-100 mt-2 "
                type="button"
                onClick={onSourcePartner}
              >
                Assign Partner
              </button>
            )}
            {allowSelectPartner == "true" && (
              <button
                className="btn btn-primary w-100 mt-2"
                type="button"
                onClick={onSourcePartner}
              >
                Reassign Partner
              </button>
            )}
          </div>
        )}
      </SidebarItem>
    </>
  );
};

export default PartnerSection;
