import React from "react";

// Inner Components
import SidebarItem from "./sidebar-items/SidebarItem";

const CampaignTimeline = ({ formattedStartDate, formattedEndDate }) => {
  return (
    <>
      {(formattedStartDate || formattedEndDate) && (
        <SidebarItem>
          {/* Start Date */}
          {formattedStartDate && (
            <div className="supporting-materials">
              <div className="supporting-material">
                <div className="field-wrapper">
                  <p className="font-weight-bold">Start Date</p>
                  <span className="d-block text-grayed font-weight-bold">
                    {formattedStartDate ? formattedStartDate : ""}
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* End Date */}
          {formattedEndDate && (
            <div className="supporting-materials">
              <div className="supporting-material">
                <div className="field-wrapper">
                  <p className="font-weight-bold">Targeted End Date</p>
                  <span className="d-block text-grayed font-weight-bold">
                    {formattedEndDate ? formattedEndDate : ""}
                  </span>
                </div>
              </div>
            </div>
          )}
        </SidebarItem>
      )}
    </>
  );
};

export default CampaignTimeline;
