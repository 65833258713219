import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import {
  TRAINING_CATEGORY_TAB,
  TRAINING_GENERAL_TAB,
  TAB_TRAINING
} from "../../../../setting/Constants";
import ResourcesTrainingTab from "./TrainingTab";
import TrainingCategory from "./CategoryTab";

// Sub Tab  Sections

const ThemeTab = props => {
  const {
    activeSubTab,
    adminSettings,
    saveSettings,
    toggleTabs,
    history,
    settings
  } = props;

  return (
    <>
      <Nav tabs className="admin-tabs">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === TRAINING_GENERAL_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_TRAINING, TRAINING_GENERAL_TAB);
            }}
          >
            {TRAINING_GENERAL_TAB}
          </NavLink>
        </NavItem>

        {/* Email Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === TRAINING_CATEGORY_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_TRAINING, TRAINING_CATEGORY_TAB);
            }}
          >
            {TRAINING_CATEGORY_TAB}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeSubTab}>
        {/* General Tab */}
        <TabPane tabId={TRAINING_GENERAL_TAB} className="w-100">
          <ResourcesTrainingTab
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
            settings={settings}
          />
        </TabPane>

        {/* Category Tab */}
        <TabPane tabId={TRAINING_CATEGORY_TAB} className="w-100">
          <TrainingCategory
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default ThemeTab;
