import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DatePicker from "react-date-picker";
import Form from "../../../components/base/Form";
import CancelButton from "../../../components/base/CancelButton";
import moment from "moment";

const EditTimelineModal = ({
  modal,
  setModal,
  startDate,
  endDate,
  setDate,
  updateProject
}) => {
  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      keyboard={true}
      backdrop="static"
      className={["edit-task-modal", "edit-timeline-modal"].join(" ")}
    >
      <ModalHeader toggle={setModal}>
        <h4 className={["font-weight-bold"].join(" ")}>
          Update Project Timeline
        </h4>
      </ModalHeader>
      <Form
        className={["d-block"].join(" ")}
        onSubmit={() => {
          const values = {};
          values.startDate = moment(startDate).format("YYYY-MM-DD");
          values.endDate = moment(endDate).format("YYYY-MM-DD");

          updateProject(values);
          setModal();
        }}
      >
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="grouped-fields">
              <div className="field-wrapper">
                <label>Start Date</label>
                <DatePicker
                  name="datepicker-timeline-start"
                  className="datepicker"
                  dayPlaceholder="MM/DD/YYYY"
                  onChange={date => setDate(date, "projectTimelineStart")}
                  minDate={new Date()}
                  value={startDate}
                  required
                  clearIcon={null}
                />
              </div>
              <div className="field-wrapper">
                <label>End Date</label>
                <DatePicker
                  name={`datepicker-timeline-end`}
                  className="datepicker"
                  dayPlaceholder="MM/DD/YYYY"
                  onChange={date => setDate(date, "projectTimelineEnd")}
                  minDate={startDate}
                  value={endDate}
                  required
                  clearIcon={null}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={setModal} className="mr-2" />
            <Button type="submit" label="">
              Update
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditTimelineModal;
