import React from "react";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

// Config
import { endpoints } from "../../configs";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import Page404 from "../Page404";
import AddContactModal from "./inner-component/AddContactModal";
import { ContactsIcon } from "../../assets/img/icons";

// Helper
import {
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  getFullName,
  getParamsByName
} from "../../lib/helper";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classNames from "classnames";

//Action
import { fetchList } from "../../actions/table";
import CountBadge from "../../components/base/CountBadge";

//Constants
const CONTACTS_ALL_TAB = "All";
const CONTACTS_PARTNER_TAB = "Partner";
const CONTACTS_PROSPECT_TAB = "Prospect";

class Contacts extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByOptions: [
        {
          value: "first_name:ASC",
          label: "Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ],
      activeTab: CONTACTS_ALL_TAB,
      currentPage: 1,
      sort: ""
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  //toggle
  toggle = tab => {
    this.setState({
      activeTab: tab
    });
  };

  //render table by ID
  renderContactTableById(id) {
    //Api url for diffrent tabs
    let type = "";
    if (id === CONTACTS_PARTNER_TAB) {
      type = CONTACTS_PARTNER_TAB;
    } else if (id === CONTACTS_PROSPECT_TAB) {
      type = CONTACTS_PROSPECT_TAB;
    }
    const searchItem = getParamsByName("search");

    let params = {
      searchItem: searchItem,
      type: type ? type : ""
    };
    return (
      <>
        {/* List */}
        <div className="mt-4 mb-5">
          <ReduxTable
            id={id}
            params={params}
            apiURL={`${endpoints().contactAPI}/search`}
            onRowClick={row => this.history.push(`/contact/edit/${row.id}`)}
            searchPlaceholder="Search"
            sortByOptions={this.state.sortByOptions}
            newTableHeading
            showHeader
            icon={<ContactsIcon />}
            message="Get started by selecting New Contact."
            paramsToUrl={true}
            history={this.props.history}
          >
            <ReduxColumn
              maxWidth={"120px"}
              width={"120px"}
              type="link"
              isClickable="false"
              field="first_name"
              sortBy="first_name"
              renderField={row => (
                <Link to={`/contact/edit/${row.id}`}>
                  {getFullName(row.first_name, row.last_name)}
                </Link>
              )}
            >
              Name
            </ReduxColumn>
            <ReduxColumn
              maxWidth={"215px"}
              width={"215px"}
              field="email"
              sortBy="email"
            >
              Email
            </ReduxColumn>
            <ReduxColumn
              maxWidth={"140px"}
              width={"140px"}
              field="phone"
              sortBy="phone"
            >
              Phone
            </ReduxColumn>
          </ReduxTable>
        </div>
      </>
    );
  }

  render() {
    const { activeTab } = this.state;
    const { allTabCount, partnerTabCount, prospectTabCount } = this.props;
    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId)
    ) {
      return <Page404 />;
    }
    const search = window.location.search;
    const pathParams = new URLSearchParams(search);
    const searchItem = pathParams.get("search");
    let type = "";
    if (CONTACTS_PARTNER_TAB) {
      type = CONTACTS_PARTNER_TAB;
    } else if (CONTACTS_PROSPECT_TAB) {
      type = CONTACTS_PROSPECT_TAB;
    }
    let params = {
      searchItem: searchItem,
      pagination: true,
      search: getParamsByName("search"),
      type: type
    };

    return (
      <>
        <AddContactModal params={params} />

        {/* Render table */}
        <Nav tabs className="admin-tabs mb-0">
          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === CONTACTS_ALL_TAB
              })}
              onClick={() => {
                this.toggle(CONTACTS_ALL_TAB);
              }}
            >
              All
              <CountBadge
                count={allTabCount}
                isActive={activeTab === CONTACTS_ALL_TAB}
              />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === CONTACTS_PARTNER_TAB
              })}
              onClick={() => {
                this.toggle(CONTACTS_PARTNER_TAB);
              }}
            >
              Partner
              <CountBadge
                count={partnerTabCount}
                isActive={activeTab === CONTACTS_PARTNER_TAB}
              />
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classNames({
                active: activeTab === CONTACTS_PROSPECT_TAB
              })}
              onClick={() => {
                this.toggle(CONTACTS_PROSPECT_TAB);
              }}
            >
              Prospect
              <CountBadge
                count={prospectTabCount}
                isActive={activeTab === CONTACTS_PROSPECT_TAB}
              />
            </NavLink>
          </NavItem>
        </Nav>

        {/* Tab contents */}
        <TabContent activeTab={activeTab}>
          {/* All tab */}
          <TabPane tabId={CONTACTS_ALL_TAB}>
            {this.renderContactTableById(CONTACTS_ALL_TAB)}
          </TabPane>

          {/* Partner tab */}
          <TabPane tabId={CONTACTS_PARTNER_TAB}>
            {this.renderContactTableById(CONTACTS_PARTNER_TAB)}
          </TabPane>

          {/* Prospect tab */}
          <TabPane tabId={CONTACTS_PROSPECT_TAB}>
            {this.renderContactTableById(CONTACTS_PROSPECT_TAB)}
          </TabPane>
        </TabContent>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { contact } = state.table;
  const roleId = state.user ? state.user.roleId : "";
  //Total count
  const allTabCount =
    state.table[CONTACTS_ALL_TAB] && !state.table[CONTACTS_ALL_TAB].isFetching
      ? state.table[CONTACTS_ALL_TAB].totalCount
      : 0;

  const partnerTabCount =
    state.table[CONTACTS_PARTNER_TAB] &&
    !state.table[CONTACTS_PARTNER_TAB].isFetching
      ? state.table[CONTACTS_PARTNER_TAB].totalCount
      : 0;

  const prospectTabCount =
    state.table[CONTACTS_PROSPECT_TAB] &&
    !state.table[CONTACTS_PROSPECT_TAB].isFetching
      ? state.table[CONTACTS_PROSPECT_TAB].totalCount
      : 0;
  const currentPage = contact && !contact.isFetching ? contact.currentPage : 1;

  return {
    allTabCount,
    partnerTabCount,
    prospectTabCount,
    currentPage,
    roleId
  };
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(Contacts);
