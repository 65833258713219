/**
 * Get Field Type Option
 */
export const fieldTypeOptions = [
  {
    label: "Email",
    value: "Email"
  },
  {
    label: "Phone",
    value: "Phone"
  },
  {
    label: "Text",
    value: "Text"
  }
];
