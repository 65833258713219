import React, { Component } from "react";

// Components
import PartnerProfileAboutMe from "./PartnerProfileAboutMe";
import PartnerProfileTagType from "./PartnerProfileTagType";

import PartnerMediaImages from "./PartnerMediaImages";
import { RichtextEditor } from "../base/RichTextEditor";
import DefaultContent from "../content/DefaultContent";
import detectBrowser from "../helpers/detectBrowser";

class PartnerProfileMain extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isMobile: detectBrowser()
    };
  }
  render() {
    const {
      tagTypeList,
      photos,
      photoUpload,
      handlePhotoDelete,
      handleAboutMe
    } = this.props;
    const {
      headline,
      aboutMe,
      certificateName,
      certificateUrl
    } = this.props.partnerDetails;

    const { isMobile } = this.state;

    return (
      <div
        className={[
          "expert-profile-main",
          `${!isMobile ? "ml-4" : ""}`,
          "w-100"
        ].join(" ")}
      >
        <PartnerProfileAboutMe
          heading={headline}
          certificateName={certificateName}
          certificateUrl={certificateUrl}
        />
        <DefaultContent title={"About"}>
          <p className={["text-inline-grayed"].join("")}>
            Tell us more about you and your skills. This will be shown to
            customers prominently on your profile. This is your personal
            statement, so be thorough!
          </p>
          <RichtextEditor
            aboutMe={aboutMe}
            handleAboutMe={handleAboutMe}
            placeholder={"About"}
          />
        </DefaultContent>

        <PartnerMediaImages
          photos={photos}
          photoUpload={photoUpload}
          handlePhotoDelete={handlePhotoDelete}
        />

        {/* Tag type select */}
        {tagTypeList &&
          tagTypeList.length > 0 &&
          tagTypeList.map(tagType => (
            <PartnerProfileTagType
              id={tagType.id}
              name={tagType.name}
              permission={tagType.permission}
              tags={tagType.tags}
              sort={tagType.sort}
            />
          ))}

        {/* <PartnerProfileRegion regionList={regionList} /> */}

        {/* <PartnerProfilePlatforms productList={productList} /> */}

        {/* <PartnerProfileServices servicesList={servicesList} /> */}

        {/* <PartnerProfileExpertise abmExpertiseList={abmExpertiseList} /> */}

        {/* <PartnerProfileMarketingChannel
          marketingChannelList={marketingChannelList}
        /> */}

        {/* <PartnerProfileIndustry industryList={industryList} /> */}

        {/* <PartnerProfileCrmMarketing crmMarketingList={crmMarketingList} /> */}

        {/* {(isSuperAdmin() || isCompanyAdmin() || isCompanyManager()) &&
          settingsEnableContactPartnerForm === "true" && (
            <PartnerProfileContactForm
              contactFormOptions={contactFormOptions}
            />
          )} */}

        {/* <PartnerProfileRecommendations recommendations={recommendations} /> */}

        {/* <PartnerProfileCertifications certifications={certifications} /> */}

        {/* <PartnerProfileSpecifications specifications={specifications} /> */}

        {/* {(isSuperAdmin() || isCompanyAdmin()) && <PartnerProfileNotes notes={notes} />} */}
      </div>
    );
  }
}

export default PartnerProfileMain;
