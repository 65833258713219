import React from "react";
import TextArea from "../base/TextArea";
import DefaultContent from "../content/DefaultContent";

const ExpertProfileAboutMe = ({ id }) => {
  return (
    <>
      <DefaultContent title={"Notes (Admin only)"}>
        <TextArea
          id={id}
          name="notes"
          maxLength={250}
          showCharCount
          placeholder={"Notes"}
        />
      </DefaultContent>
    </>
  );
};

export default ExpertProfileAboutMe;
