import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import toast from "../components/base/Toast";
import { fetchList } from "../actions/table";
import { isBadRequest } from "../common/http";

class ReferralRequestService {
  static REQUEST_DELETE_REFERRAL = "REQUEST_DELETE_REFERRAL";
  static REFERRAL_DELETE_ERROR = "REFERRAL_DELETE_ERROR";

  /**
   * Request for deleting landing page
   */
  static requestDeleteReferral() {
    return {
      type: this.REQUEST_DELETE_REFERRAL
    };
  }

  /**
   * Receive for error deleting landing page
   */
  static referralDeleteError(error) {
    return {
      type: this.REFERRAL_DELETE_ERROR,
      error
    };
  }

  static async create(data, callback) {
    try {
      apiClient
        .post(`${endpoints().referralRequestApi}/create`, data)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
            return callback(response);
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
              toast.error(errorMessage);
            }
            console.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  static async get(id, callback) {
    try {
      apiClient
        .get(`${endpoints().referralRequestApi}/${id}`)
        .then(response => {
          return callback(response);
        });
    } catch (err) {
      console.log(err);
    }
  }

  static async update(data, id, callback) {
    try {
      apiClient
        .put(`${endpoints().referralRequestApi}/${id}`, data)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
            callback && callback();
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  static async delete(id, params) {
    return dispatch => {
      dispatch(this.requestDeleteReferral());
      apiClient
        .delete(`${endpoints().referralRequestApi}/${id}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          toast.success(successMessage);
        })
        .then(() => {
          // Table delete data
          dispatch(
            fetchList(
              "requestReferral",
              `${endpoints().referralRequestApi}/search`,
              params.currentPage || 1,
              10,
              {
                pagination: true
              }
            )
          );
        })
        .catch(error => {
          dispatch(this.referralDeleteError(error));
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        });
    };
  }
}

export default ReferralRequestService;
