import React from "react";

// Assets
import {
  DashboardDealRegisteredIcon,
  DashboardDealPendingIcon,
  DashboardCommissionEarnedIcon
} from "../../assets/img/icons";

// Components
import StatisticsCountCard from "../base/StatisticsCountCard";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
import { GetCurrencySymbol } from "../../lib/CurrencySymbol";

const PipelineReportStatCard = props => {
  const { noOfReferralCount, averageReferralSize, totalValue } = props;

  return (
    <div className="report-card-statistics">
      <>
        {/* Referrals Count */}
        <StatisticsCountCard
          icon={<DashboardCommissionEarnedIcon />}
          count={noOfReferralCount}
          label={`Number of ${Terminology.get(SystemName.REFERRALS)}`}
          redirectUrl=""
        />
        {/* Average Referral Size */}
        <StatisticsCountCard
          icon={<DashboardDealPendingIcon />}
          count={averageReferralSize}
          label={`Average ${Terminology.get(SystemName.REFERRAL)} Size`}
          redirectUrl=""
          prefix={GetCurrencySymbol()}
        />
      </>

      {/* Total Value */}
      <StatisticsCountCard
        icon={<DashboardCommissionEarnedIcon />}
        count={totalValue}
        label="Total Value"
        prefix={GetCurrencySymbol()}
        redirectUrl=""
      />
    </div>
  );
};
export default PipelineReportStatCard;
