import React, { useState } from "react";
import DatePicker from "react-date-picker";

const ExpertProfileEditDatepicker = ({
  name,
  placeholder,
  value,
  onChange,
  clearIcon,
  minDate,
  maxDate,
  disabled,
  id
}) => {
  let newDate = value ? new Date(value) : "";
  const [date, setDate] = useState(newDate);
  return (
    <DatePicker
      id={id || name}
      name={name}
      className="datepicker"
      dayPlaceholder={placeholder}
      value={date}
      onChange={val => {
        setDate(val);
        onChange(val);
      }}
      clearIcon={clearIcon}
      maxDate={maxDate ? maxDate : ""}
      minDate={minDate ? minDate : ""}
      disabled={disabled ? disabled : false}
    />
  );
};

export default ExpertProfileEditDatepicker;
