import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CancelButton from "../components/base/CancelButton";
import lodash from "lodash";

// Api Client
import { apiClient } from "../apiClient";

// Assets
import { CrossIcon } from "../assets/img/icons";

// Configs
import { endpoints } from "../configs";

// Constants
import {
  TAG_TYPE_PARTNER_TYPE,
  TAG_TYPE_PARTNER_TIER,
  TAG_TYPE_CATEGORY_MARKETPLACE
} from "../tagType/Constants";

// Components
import SimplePartnerCard from "./SimplePartnerCard";
import NLFormWrapper from "./nl-form/NLFormWrapper";
import Spinner from "./base/Spinner";
import { isBadRequest } from "../common/http";

class PartnerModal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedFilters: {},
      partnerSelectObj: { partnerTypeObj: [], partnerTierObj: [] },
      partnerParagraphs: [
        "I'm looking for {partnerTypeObj}",
        "with {partnerTierObj}"
      ],
      partnerExtraParagraphs: [],
      partnerTypes: [
        {
          label: "Partner Type",
          value: "Partner Type",
          selected: false,
          placeholder: true
        }
      ],
      partnerTiers: [
        {
          label: "Partner Tier",
          value: "Partner Tier",
          selected: false,
          placeholder: true
        }
      ],
      tagTypeList: [],
      tagsTypeLoading: false,
      sourcePartnerClicked: false,
      sourcePartner: false
    };
  }
  // const [selectedFilters, setSelectedFilters] = useState({});
  // const [partnerTypes, setPartnerTypes] = useState([]);
  // const [services, setServices] = useState([]);
  // const [platforms, setPlatforms] = useState([]);
  // const [sourcePartner, setSourcePartner] = useState(false);
  // const [sourcePartnerClicked, setSourcePartnersClicked] = useState(false);

  toggleSourcePartnersClicked = () => {
    this.setState({ sourcePartnerClicked: !this.state.sourcePartnerClicked });
  };

  componentDidMount() {
    this.state.partnerSelectObj.partnerTypeObj.push(this.state.partnerTypes[0]);
    this.state.partnerSelectObj.partnerTierObj.push(this.state.partnerTiers[0]);
    this.getMarketplaceTagValues();
    this._getTagsByTagType(TAG_TYPE_PARTNER_TYPE);
    this._getTagsByTagType(TAG_TYPE_PARTNER_TIER);
    this.getPartnerType();
  }

  // Get Marketplace Tag Values
  getMarketplaceTagValues = async () => {
    this.setState({ tagsTypeLoading: true }, async () => {
      try {
        const response = await apiClient.get(
          `${endpoints().tagTypeAPI}?category=${TAG_TYPE_CATEGORY_MARKETPLACE}`
        );
        const results = response.data.data;
        // Check the tag type skills
        if (results && results.length > 0) {
          const tagTypeList = [];
          await results.forEach(async (tagType, i) => {
            const tagList = [];
            this.state.partnerSelectObj[tagType.name] = [
              {
                multiselect: 1,
                label: tagType.name,
                value: "",
                placeholder: true
              }
            ];

            if (i > 0) {
              this.state.partnerExtraParagraphs.push(`with {${tagType.name}}`);
            } else {
              this.state.partnerParagraphs.push(`with {${tagType.name}}`);
            }

            if (tagType.tags && tagType.tags.length > 0) {
              await tagType.tags.forEach(tag => {
                tagList.push({
                  id: tag.id,
                  name: tag.name,
                  value: tag.name,
                  label: tag.name,
                  tagTypeId: tagType.id
                });
                this.state.partnerSelectObj[tagType.name].push({
                  label: tag.name,
                  value: tag.name,
                  selected: false
                });
              });
            }
            tagTypeList.push({
              id: tagType.id,
              name: tagType.name,
              permission: tagType.permission,
              tags: tagList
            });
          });

          this.setState(
            {
              tagTypeList: tagTypeList
            },
            () => {
              this.setState({
                tagsTypeLoading: false
              });
            }
          );
        } else {
          this.setState({
            tagsTypeLoading: false
          });
        }
      } catch (error) {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      }
    });
  };
  // Get Tags by tag type
  _getTagsByTagType = tagType => {
    return apiClient
      .get(`${endpoints().tagAPI}?tagType=${tagType}&isSearchable=true`)
      .then(response => {
        const tags = response && response.data && response.data.data;

        if (tags && tags.length > 0) {
          if (tagType === TAG_TYPE_PARTNER_TYPE) {
            tags.forEach(tag => {
              this.state.partnerTypes.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
              this.state.partnerSelectObj.partnerTypeObj.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          } else if (tagType === TAG_TYPE_PARTNER_TIER) {
            tags.forEach(tag => {
              this.state.partnerTiers.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
              this.state.partnerSelectObj.partnerTierObj.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get partner tiers
  getPartnerTier = async type => {
    try {
      const response = await apiClient.get(
        `${endpoints().partnerTierAPI}/search`
      );
      const results = response.data.data;
      if (results && results.length > 0) {
        // Update state tier value with new selected type
        if (type) {
          this.state.partnerSelectObj.partnerTierObj.splice(
            0,
            this.state.partnerSelectObj.partnerTierObj.length
          );

          // Set tier object name
          this.state.partnerSelectObj.partnerTierObj.push(
            this.state.partnerTiers[0]
          );
          results.forEach(tier => {
            tier &&
              tier.partnerType.forEach(element => {
                // If selected type and assigned type equal
                if (type === element.name) {
                  this.state.partnerTiers.push({
                    label: tier.name,
                    value: tier.name,
                    selected: false
                  });
                  // Update tier state current value
                  this.state.partnerSelectObj.partnerTierObj.push({
                    id: tier.id,
                    label: tier.name,
                    value: tier.name,
                    selected: false
                  });
                }
              });
          });
        } else {
          // Set tier object name
          this.state.partnerSelectObj.partnerTierObj.push(
            this.state.partnerTiers[0]
          );
          results.forEach(tier => {
            this.state.partnerTiers.push({
              label: tier.name,
              value: tier.name,
              selected: false
            });
            this.state.partnerSelectObj.partnerTierObj.push({
              id: tier.id,
              label: tier.name,
              value: tier.name,
              selected: false
            });
          });
        }
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }

      this.getMarketplaceTagValues();
    }
  };

  // Get partner tiers
  getPartnerType = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().partnerTypeAPI}/search`
      );
      const results = response.data.data;
      if (results && results.length > 0) {
        this.state.partnerSelectObj.partnerTypeObj.push(
          this.state.partnerTypes[0]
        );
        results
          .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
          .forEach(type => {
            this.state.partnerTypes.push({
              label: type.name,
              value: type.name,
              selected: false
            });
            this.state.partnerSelectObj.partnerTypeObj.push({
              id: type.id,
              label: type.name,
              value: type.name,
              selected: false
            });
          });
      }
      this.getPartnerTier();
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
      this.getPartnerTier();
    }
  };

  getSelectedFilters = (selectedFilters, selectName) => {
    // Get tier based on selected type
    if (selectName === "partnerTypeObj") {
      this.getPartnerTier(selectedFilters.selectedValue[0]);
    }
    this.setState(
      {
        selectedFilters: {
          ...this.state.selectedFilters,
          [selectName]: selectedFilters
        }
      },
      () => {
        this.renderPartnerList();
      }
    );
  };

  // Filter partner list
  renderPartnerList = () => {
    const selectedFilters = this.state.selectedFilters;
    const selectedFiltersConst = selectedFilters;
    const tags = [];
    let type = "";
    let tier = "";
    let tagTypeList = this.state.tagTypeList;

    const partnerTags = selectedFilters.partnerTypeObj
      ? selectedFilters.partnerTypeObj.selectedValue
      : "";

    if (partnerTags.length > 0) {
      type = `${partnerTags[0]}`;
    }

    const partnerTierTags = selectedFilters.partnerTierObj
      ? selectedFilters.partnerTierObj.selectedValue
      : "";

    if (partnerTierTags.length > 0) {
      tier = `${partnerTierTags[0]}`;
    }
    if (tagTypeList.length > 0) {
      tagTypeList.forEach(tagType => {
        if (
          selectedFilters[tagType.name] &&
          selectedFilters[tagType.name].selectedValue &&
          selectedFilters[tagType.name].selectedValue.length > 0
        )
          selectedFilters[tagType.name].selectedValue.forEach(customTag => {
            tags.push(customTag);
          });
      });
    }

    const location =
      selectedFiltersConst &&
      selectedFiltersConst.location &&
      selectedFiltersConst.location.selectedValue
        ? selectedFiltersConst.location.selectedValue
        : "";
    const locationSplit =
      location && location[0] ? location[0].split(", ") : "";
    const city = locationSplit[0] || "";

    const filtersSelected = {
      tags,
      city,
      type,
      tier
    };
    this.props.updatePartnerList(filtersSelected);
  };

  // Delete selected filters
  deleteFilters = e => {
    const { dataset } = e.target;
    const selectedFilters = this.state.selectedFilters;
    let currentValueIndex = selectedFilters[
      dataset["parentobj"]
    ].selectedValue.indexOf(dataset["filter"]);
    let currentArray = selectedFilters[dataset["parentobj"]].selectedValue;
    currentArray.splice(currentValueIndex, 1);
    this.renderPartnerList();
    if (dataset.parentobj === "partnerTypeObj") {
      this.state.partnerSelectObj.partnerTierObj.splice(
        0,
        this.state.partnerSelectObj.partnerTierObj.length
      );
      this.getPartnerTier().then(() => {
        this.setState({
          selectedFilters: {
            ...selectedFilters,
            [dataset["parentobj"]]: {
              selectedValue: [...currentArray]
            }
          }
        });
      });
    } else {
      this.setState({
        selectedFilters: {
          ...selectedFilters,
          [dataset["parentobj"]]: {
            selectedValue: [...currentArray]
          }
        }
      });
    }
  };

  render() {
    const {
      isPartnerModal,
      toggle,
      partnerList,
      selectedPartnerList,
      handlePartners,
      partnerIds,
      createLeadPartner,
      createDealPartner,
      createReferralPartner,
      isLoading,
      id,
      createMdfPartner,
      createAppPartner,
      togglePartnerModal,
      populateSelectedPartner
    } = this.props;
    const {
      partnerSelectObj,
      partnerParagraphs,
      partnerExtraParagraphs,
      selectedFilters
    } = this.state;
    let options = [];
    let orderList = partnerList;
    orderList
      .sort((a, b) =>
        (a["companyName"] || "")
          .toString()
          .localeCompare((b["companyName"] || "").toString())
      )
      .forEach(partner => {
        options.push({
          companyName: partner.companyName
        });
      });

    // Get selected filter keys like (partnerTypeObj, multiselect, etc,.)
    const getFilterKeys = () => Object.keys(selectedFilters);

    // Remove selected partner from partnerList
    let ExcludedPartnerList = lodash.differenceWith(
      partnerList,
      selectedPartnerList,
      function(arr1, arr2) {
        return arr1["id"] === arr2["partnerId"] || arr1["id"] === arr2["id"];
        // return arr1["id"] === arr2["id"];
      }
    );

    return (
      <Modal
        id={id}
        isOpen={isPartnerModal}
        toggle={toggle}
        className="edit-task-modal expert-modal"
        backdrop="static"
        size="xl"
        style={{ verticalAlign: "middle" }}
      >
        <ModalHeader
          id={id}
          toggle={() => {
            handlePartners(null);
            this.setState({ selectedFilters: {} });
            toggle();
          }}
          cssModule={{ "modal-title": "w-100 text-center" }}
          tag={"div"}
        >
          <span className="text-center h4 font-weight-bold">
            Source More Partners
          </span>
        </ModalHeader>

        <NLFormWrapper
          paragraphs={partnerParagraphs}
          selectObj={partnerSelectObj}
          extraParagraphs={partnerExtraParagraphs}
          getSelectedFilters={this.getSelectedFilters}
          liveFilters={selectedFilters}
          renderExpertList={e => this.renderPartnerList(e)}
          hideDiscoverButton={true}
        />

        {getFilterKeys().length > 0 ? (
          <div className="filters-wrapper">
            {getFilterKeys().map(filterObj =>
              selectedFilters[filterObj]["selectedValue"].map(
                value =>
                  value && (
                    <button
                      id={id}
                      className={`badge badge-primary ${filterObj}`}
                      data-parentobj={filterObj}
                      data-filter={value}
                      onClick={e => this.deleteFilters(e)}
                    >
                      {value} <CrossIcon />
                    </button>
                  )
              )
            )}
          </div>
        ) : (
          ""
        )}
        <ModalBody>
          <div className="simple-expert-card-list mt-5 mb-3">
            {!ExcludedPartnerList.length ? (
              <p className="text-center w-100 mt-4">
                No partners are added yet
              </p>
            ) : (
              ""
            )}
            {isLoading ? (
              <Spinner />
            ) : (
              ExcludedPartnerList.length > 0 &&
              ExcludedPartnerList.map(partner => (
                // TODO: BUG: https://trello.com/c/gwiKX2tt is happening with both implementations
                <SimplePartnerCard
                  data={{
                    id: partner.id,
                    avatarUrl: partner.avatarUrl,
                    companyName: partner.companyName
                  }}
                  key={partner.id}
                  addPartner={true}
                  selected={partnerIds.indexOf(parseInt(partner.id, 10)) > -1}
                  handlePartners={handlePartners}
                  tag={"label"}
                  partnerUrl={`/partner-profile/${partner.companyProfileUrl}`}
                />
              ))
            )}
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="container-fluid">
            <div className="col-sm-12 text-center">
              <div className="btn-wrapper">
                <CancelButton
                  onClick={() => {
                    handlePartners(null);
                    this.setState({ selectedFilters: {} });
                    toggle();
                  }}
                />
                <button
                  id="sourcePatnersButton"
                  className={`btn btn-primary ${
                    !partnerIds.length ? "disabled" : ""
                  }`}
                  onClick={event => {
                    event.currentTarget.disabled = true;
                    this.setState({
                      selectedFilters: {},
                      sourcePartner: !this.state.sourcePartner
                    });
                    partnerIds.length &&
                      createLeadPartner &&
                      createLeadPartner();
                    partnerIds.length &&
                      createDealPartner &&
                      createDealPartner();
                    partnerIds.length &&
                      createReferralPartner &&
                      createReferralPartner();
                    partnerIds.length && createMdfPartner && createMdfPartner();
                    partnerIds.length && createAppPartner && createAppPartner();
                    partnerIds.length &&
                      togglePartnerModal &&
                      togglePartnerModal();
                    partnerIds.length &&
                      populateSelectedPartner &&
                      populateSelectedPartner(partnerIds);
                    this.toggleSourcePartnersClicked(true);
                  }}
                  disabled={!partnerIds.length}
                >
                  Source Partners
                </button>
              </div>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
}

export default PartnerModal;
