import React, { Component } from "react";
import moment from "moment";
import { Col, Container, Row } from "reactstrap";

// 404 page
import Page404 from "../Page404";

// Components
import ExpertCard from "../../components/base/ExpertCard";
import SocialIcons from "../../components/base/SocialIcons";
import SidebarContent from "../../components/base/SidebarContent";
import Badges from "../../components/badges/Badges";
import Badge from "../../components/badges/Badge";
import DefaultContent from "../../components/content/DefaultContent";
import Spinner from "../../components/base/Spinner";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints, DEFAULT_API_KEY } from "../../configs";

// Helper
import {
  excerptText,
  getCookie,
  getUrlParameter,
  isSuperAdmin,
  isCompanyAdmin,
  isExpert,
  getUserId,
  isCompanyManager
} from "../../lib/helper";

// Assets
import { ArrowLeft, GraphicDIcon } from "../../assets/img/icons";
import salesforceCertifiedUrl from "../../assets/img/mock_imgs/certified_salesforce.png";
import { COOKIE_SESSION_TOKEN } from "../../lib/cookie";
import { isBadRequest } from "../../common/http";
import { connect } from "react-redux";

class ExpertProfile extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      expertDetails: [],
      showAboutMe: false,
      moreRecomendation: false,
      loading: false,
      isInvalidRequest: false
    };
  }

  componentDidMount = () => {
    this.getExpertDetails();
  };

  // Expert details api call by id
  getExpertDetails = () => {
    const expertId = this.props.match.params.id;
    const apiUrl = `${endpoints().publicAPI}/expert/${expertId}`;

    // If dont have session send default API key
    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    return apiClient
      .get(`${apiUrl}`)
      .then(response => {
        this.setState({ expertDetails: response.data, loading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  toggleShowAboutMe = e => {
    e.preventDefault();
    this.setState({ showAboutMe: !this.state.showAboutMe });
  };
  toggleMoreRecomendation = e => {
    e.preventDefault();
    this.setState({ moreRecomendation: !this.state.moreRecomendation });
  };

  render() {
    let salesforceCertified = salesforceCertifiedUrl || 0;

    if (
      this.state.isInvalidRequest ||
      (!isSuperAdmin(this.props.roleId) &&
        !isCompanyAdmin(this.props.roleId) &&
        !isCompanyManager(this.props.roleId) &&
        this.state.status === "Draft")
    ) {
      return (
        <div className="app-body">
          <Page404 />
        </div>
      );
    }

    const showSkills = skills => {
      const max_skills = skills.length;
      let skills_arr = [];
      for (let i = 0; i < max_skills; i++) {
        skills_arr.push(
          <Badge
            grouped
            outlined
            theme={"dark"}
            icon={<GraphicDIcon />}
            name={skills[i].name}
          />
        );
        if (i === 4) {
          break;
        }
      }

      return skills_arr;
    };

    const {
      id,
      userId,
      firstName,
      lastName,
      email,
      avatar,
      avatarUrl,
      phone,
      city,
      stateName,
      aboutMe,
      marketplaceStatus,
      timezone,
      hourlyRate,
      availability,
      facebookUrl,
      twitterUrl,
      linkedinUrl,
      instagramUrl,
      dribbbleUrl,
      websiteUrl,
      youtubeUrl,
      trailheadProfileUrl,
      certified,
      skills,
      tools,
      industries,
      recommendations,
      certifications,
      education,
      employments,
      allowedToEdit,
      showHourlyRate,
      specifications,
      notes
    } = this.state.expertDetails;

    const { showAboutMe, moreRecomendation } = this.state;
    const aboutMeLength = 420;
    const aboutMeLengthExceed = aboutMe && aboutMe.length > aboutMeLength;
    const currentUserId = getUserId();

    const redirectTo = getUrlParameter("redirect");

    return (
      <div className="expert-profile">
        {!this.state.expertDetails.length &&
          this.state.expertDetails.length !== undefined && <Spinner />}
        {this.state.expertDetails && (
          <>
            <div className="sub-navigation">
              <Container>
                <Col>
                  <p
                    onClick={() => {
                      const { goBack, action } = this.props.history;
                      return action === "POP"
                        ? (window.location = redirectTo || "/")
                        : goBack();
                    }}
                    className="text-link d-flex align-items-center"
                  >
                    <ArrowLeft /> Back
                  </p>
                </Col>
              </Container>
            </div>
            <Container className="my-4 py-4">
              <Row className="my-5 py-4">
                <Col sm="5" md="4" lg="3">
                  {id && (
                    <div className="site-sidebar mt-4">
                      <ExpertCard
                        classnames={"expert-card"}
                        certified={certified}
                        data={{
                          id,
                          bannerImagePath: avatar,
                          firstName,
                          lastName,
                          avatarUrl,
                          timezone,
                          hourlyRate,
                          availability,
                          marketplaceStatus,
                          showHourlyRate,
                          phone,
                          email,
                          city,
                          stateName
                        }}
                        readyForHire={allowedToEdit ? true : ""}
                        size={"w100"}
                        fixedWidth={false}
                      />

                      <SocialIcons
                        className="social-icons sidebar-content-box sidebar-content"
                        facebook={facebookUrl}
                        linkedIn={linkedinUrl}
                        twitter={twitterUrl}
                        dribbble={dribbbleUrl}
                        www={websiteUrl}
                        instagram={instagramUrl}
                        trailheadProfileUrl={trailheadProfileUrl}
                      />

                      {skills && skills.length > 0 && (
                        <SidebarContent title={"Top Five Skills"}>
                          <Badges grouped>{showSkills(skills)}</Badges>
                        </SidebarContent>
                      )}

                      {tools && tools.length > 0 && (
                        <SidebarContent title={"Tools"}>
                          <Badges>
                            {tools.map(tool => (
                              <Badge outlined theme={"dark"} name={tool.name} />
                            ))}
                          </Badges>
                        </SidebarContent>
                      )}

                      {industries && industries.length > 0 && (
                        <SidebarContent title={"Industries"}>
                          <Badges>
                            {industries.map(industry => (
                              <Badge
                                outlined
                                theme={"dark"}
                                name={industry.name}
                              />
                            ))}
                          </Badges>
                        </SidebarContent>
                      )}
                    </div>
                  )}
                </Col>
                <Col sm="7" md="8" lg="9">
                  <div className="section-maincontent mt-4">
                    {aboutMe && (
                      <DefaultContent title={"About me"}>
                        <p>
                          {showAboutMe
                            ? aboutMe
                            : excerptText(aboutMe, aboutMeLength)}
                        </p>
                        {aboutMeLengthExceed && (
                          <a
                            href="#"
                            className="h7"
                            onClick={this.toggleShowAboutMe}
                          >
                            Read {showAboutMe ? "less" : "more"}...
                          </a>
                        )}
                      </DefaultContent>
                    )}

                    {recommendations && recommendations.length > 0 && (
                      <DefaultContent title={"Recommendations"}>
                        <div className="recommendations">
                          {recommendations.map((recommendation, index) => (
                            <>
                              {(moreRecomendation ||
                                (!moreRecomendation && index === 0)) && (
                                <div className="recommendation">
                                  <div className="content-title">
                                    <p>
                                      <b>{recommendation.name}</b>
                                      {
                                        <span className="subtitle d-block text-grayed">
                                          {recommendation.role} at{" "}
                                          {recommendation.companyName}
                                        </span>
                                      }
                                    </p>
                                  </div>
                                  <p>{recommendation.testimonial}</p>
                                </div>
                              )}
                            </>
                          ))}
                          {recommendations.length > 1 && (
                            <a
                              href="#"
                              className="h7"
                              onClick={this.toggleMoreRecomendation}
                            >
                              Read {moreRecomendation ? "less" : "more"}...
                            </a>
                          )}
                        </div>
                      </DefaultContent>
                    )}

                    {certifications && certifications.length > 0 && (
                      <DefaultContent title={"Certifications"}>
                        <div className="certifications">
                          {certifications.map(certification => (
                            <div className="certification">
                              <div className="certification-image">
                                <img
                                  src={
                                    certification.logoUrl || salesforceCertified
                                  }
                                  alt="certification icon"
                                />
                              </div>
                              <div className="certification-content">
                                <p>{certification.name}</p>
                                <span>
                                  {certification.description
                                    ? certification.description
                                    : "Lorem ipsum sit dolor amet, consecutur " +
                                      "dolor sit about this awesome " +
                                      "ceertification which i recieved from salesforce!"}
                                </span>
                              </div>
                            </div>
                          ))}
                        </div>
                      </DefaultContent>
                    )}

                    {employments && employments.length > 0 && (
                      <DefaultContent title={"Employment"}>
                        <div className="employments">
                          {employments.map(employment => (
                            <div className="employment">
                              <div className="content-title">
                                <p>
                                  <b>
                                    {employment.title} at{" "}
                                    {employment.companyName}
                                  </b>
                                  <span className="h7 d-block text-grayed">
                                    {employment.location}
                                  </span>
                                  <span className="h8 d-block text-grayed">
                                    {employment.startDate
                                      ? moment(employment.startDate).format(
                                          "MMM YYYY"
                                        )
                                      : ""}{" "}
                                    {employment.endDate
                                      ? `-${moment(employment.endDate).format(
                                          "MMM YYYY"
                                        )}`
                                      : `Present`}
                                  </span>
                                  <span className="h8 d-block text-grayed">
                                    {employment.employmentType}
                                  </span>
                                </p>
                              </div>
                              <p>{employment.description}</p>
                            </div>
                          ))}
                        </div>
                      </DefaultContent>
                    )}

                    {education && education.length > 0 && (
                      <DefaultContent title={"Education"}>
                        <div className="educations">
                          {education.map(data => (
                            <div className="education">
                              <div className="content-title">
                                <p>
                                  <b>{data.collegeName}</b>
                                  <span className="h7 d-block text-grayed">
                                    {data.degree}
                                    {","} {data.course}
                                    {","} {data.courseType}
                                  </span>
                                  <span className="h8 d-block text-grayed">
                                    {data.startDate !== null ? (
                                      <>
                                        {moment(data.startDate).format(
                                          "MMM YYYY"
                                        )}{" "}
                                        -{" "}
                                        {(data.currentlyGoingHere &&
                                          data.currentlyGoingHere !== true) ||
                                        data.endDate
                                          ? moment(data.endDate).format(
                                              "MMM YYYY"
                                            )
                                          : "Present"}
                                      </>
                                    ) : (
                                      ""
                                    )}
                                  </span>
                                </p>
                              </div>
                            </div>
                          ))}
                        </div>
                      </DefaultContent>
                    )}
                    {(isSuperAdmin(this.props.roleId) ||
                      isCompanyAdmin(this.props.roleId) ||
                      isCompanyManager(this.props.roleId) ||
                      (isExpert(this.props.roleId) &&
                        currentUserId === userId)) &&
                      specifications && (
                        <DefaultContent
                          title={"Exclusions or Specifications"}
                          titleHint={"(Torchlite Admins Only)"}
                        >
                          <p>{specifications}</p>
                        </DefaultContent>
                      )}

                    {(isSuperAdmin(this.props.roleId) ||
                      isCompanyAdmin(this.props.roleId) ||
                      isCompanyManager(this.props.roleId)) &&
                      notes && (
                        <DefaultContent title={"Notes (Admin only)"}>
                          <p>{notes}</p>
                        </DefaultContent>
                      )}
                  </div>
                </Col>
              </Row>
            </Container>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(ExpertProfile);
