// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  getKeyValueByObject
} from "../lib/helper";

import Terminology from "../lib/Terminology";
import SystemName from "../Constants/SystemName";
import Permission from "../helpers/Permission";

export const TAB_GENERAL = "General";
export const TAB_THEME = "Theme";
export const TAB_EMAIL = "Email";
export const TAB_SOCIAL = "Social";
export const TAB_INTEGRATION = "Integrations";
export const TAB_NOTIFICATION = "Notifications";
export const TAB_SCHEDULER = "Scheduler";
export const TAB_SALESFORCE_SETTINGS = "Salesforce";
export const TAB_HUBSPOT_SETTING = "Hubspot";
export const TAB_CROSSBEAM = "Crossbeam";
export const TAB_REVEAL = "Reveal";
export const TAB_REFERRALS = "Referrals & Deal Reg";
export const TAB_RAMBLE_CHAT_SETTINGS = "RambleChat";
export const TAB_BILL_DOT_COM_SETTINGS = "Bill.com";
export const TAB_SLACK = "Slack";
export const TAB_STRIPE_SETTINGS = "Stripe";
export const TAB_GOOGLE_ANALYTICS_SETTINGS = "GoogleAnalytics";
export const TAB_METACX_SETTINGS = "MetaCx";
export const TAB_BLUE_JEANS_SETTINGS = "BlueJeans";
export const TAB_MONDAY_DOT_COM_SETTINGS = "Monday.com";
export const TAB_OKTA_SETTINGS = "Okta";
export const TAB_PAYMENTS = "Payments";
export const TAB_MARKETPLACE = "Marketplace";
export const TAB_PARTNER_ONBOARDING = "Partner Onboarding";
export const TAB_APPS = "App";
export const TAB_PARTNER_RESOURCE = "Resource";
export const TAB_TRAINING = "Training";
export const TAB_AUTH0 = "Auth0";
export const TAB_EXPERT = "Expert";
export const TAB_GMAIL = "Gmail";
export const TAB_PARTNER = "Partner";
export const TAB_EMAIL_TEMPLATE = "Email Templates";
export const TAB_TERMINOLOGY = "Terminology";
export const TAB_ROLE = "Roles";
export const GENERAL_TAB_ROLE = "General";
export const PERMISSION_TAB_ROLE = "Permission";

// General Tab Sub Tabs
export const GENERAL_GENERAL_SUB_TAB = "General";
export const GENERAL_ADDRESS_SUB_TAB = "Address";
export const GENERAL_EXPERT_SUB_TAB = "Expert";
export const GENERAL_SOCIAL_SUB_TAB = "Social";
export const GENERAL_SETTINGS_SUB_TAB = "Settings";

// Theme Tab Sub Tabs
export const THEME_GENERAL_SUB_TAB = "General";
export const THEME_EMAIL_SUB_TAB = "Email";

// List Tab Sub Tabs
export const LIST_TAG_TYPES_SUB_TAB = "Tag Types";
export const LIST_TAGS_SUB_TAB = "Tags";
export const LIST_PROJECT_CATEGORIES_SUB_TAB = "Project Categories";

// Referrals Tab Sub Tabs
export const REFERRAL_LINKS_SUB_TAB = "Links";
export const REFERRAL_PARTNER_TIERS_SUB_TAB = "Tiers";
export const PARTNER_SETTINGS_CONFIG_TAB = "Settings";

export const REFERRAL_STATUS_SUB_TAB = "Status";
export const REFERRAL_QUALIFICATION_QUESTIONS_SUB_TAB =
  "Qualification Questions";
export const SALESFORCE_SALES_ROUTING_SUB_TAB = "Salesforce Sales Routing";
export const HUBSPOT_SALES_ROUTING_SUB_TAB = "Hubspot Sales Routing";
export const REFERRAL_SETTING_CONFIG = "Setting";

// Notification Tab Sub Tabs
export const NOTIFICATION_EMAIL_SUB_TAB = "Email";
export const NOTIFICATION_SLACK_SUB_TAB = "Slack";
export const NOTIFICATION_PARTNER_SUB_TAB = "Partner";

// Integration Tab Sub Tabs
export const INTEGRATION_ANALYCTICS_SUB_TAB = "Analytics";
export const INTEGRATION_CRMS_SUB_TAB = "CRMs";
export const INTEGRATION_CHAT_SUB_TAB = "Chat";
export const INTEGRATION_ACCOUNT_MAPPING_SUB_TAB = "Account Mapping";
export const INTEGRATION_ACCOUNT_MAPPING_URL_TAB = "account-mapping";
export const INTEGRATION_PAYMENTS_SUB_TAB = "Payments";
export const INTEGRATION_SINGLE_SIGN_ON_SUB_TAB = "Single Sign on";
export const INTEGRATION_COLLABORATION_SUB_TAB = "Collaboration";

// System Tab Sub Tabs
export const SYSTEM_GENERAL_SUB_TAB = "General";
export const SYSTEM_URL_SUB_TAB = "URL";
export const SYSTEM_FEATURES_SUB_TAB = "Features";

// Marketplace Tab Sub Tabs
export const MARKETPLACES_SUB_TAB = "Landing Pages ";
//export const FILTERS_SUB_TAB = "Profile";

// Partner Application Tab Sub Tabs
export const PARTNER_ONBOARDING_SUB_TAB = "Signup";
export const PARTNER_APPLICATIONS_TASKS_SUB_TAB = "Tasks";
export const PARTNER_APPLICATIONS_FIELDS_SUB_TAB = "Fields";
export const PARTNER_APPLICATIONS_FORMS_SUB_TAB = "Forms";

// App Tab Sub Tabs
export const APP_CATEGORY = "App Category";

// Partner Resources Tab Sub Tabs
export const PARTNER_RESOURCE_CATEGORY_SUB_TAB = "Resource Category";

//Partner Tier Quota Section
export const PARTNER_TIER_QUOTA_NO_QUOTA = "No Quota";
export const PARTNER_TIER_QUOTA_YEARLY_REVENUE = "Yearly Revenue";
export const PARTNER_TIER_QUOTA_QUATERLY_REVENUE = "Quarterly Revenue";
export const PARTNER_TIER_QUOTA_YEARLY_DEAL_COUNT = "Yearly Deal Count";
export const PARTNER_TIER_QUOTA_QUATERLY_DEAL_COUNT = "Quarterly Deal Count";
export const ENABLE_TIER_VISIBILITY = 1;
export const DISABLE_TIER_VISIBILITY = 2;
// Partners Tab sub Tab
export const PARTNERS_SUB_TAB = "Types";

// Partner tab Profile tab
export const PARTNER_PROFILE_SUB_TAB = "Profile";

// Payments Tab Sub Tab
export const OUTGOING_PAYMENTS_SUB_TAB = "Outgoing Payments";
export const REFERRAL_PAYMENTS_SUB_TAB = "Referral Payments";

//Jobs Tab
export const JOBS_TAB = "Jobs";
export const JOBS_DEFAULT_SUB_TAB = "Default";
export const JOBS_OTHERS_SUB_TAB = "Others";

//Users Tab
export const USERS_TAB = "Users";
export const SUPER_ADMIN_SUB_TAB = "Super Admin";
export const USERS_ROLE_SUB_TAB = "Role";
export const USERS_PERMISSION_SUB_TAB = "Permission";
export const REFERRAL_TAB = "Referral";
export const BOMBORA_TAB = "Bombora";

//Customer Readiness
export const CUSTOMER_IS_READEY_TO_SPEAK_AN_ACCOUNT_EXECUTIVE =
  "Customer is ready to speak an Account Executive";
export const CUSTOMER_IS_NOT_READY_YET_SUBMITTING_FOR_VISIBILITY =
  "Customer is not ready yet, submitting for visibility";
export const CUSTOMER_HAS_PURCHASED_AND_IS_READY_FOR_ONBOARDING =
  "Customer has purchased and is ready for onboarding";
export const CUSTOMER_IS_READEY_TO_SPEAK_AN_ACCOUNT_EXECUTIVE_VALUE = 1;
export const CUSTOMER_IS_NOT_READY_YET_SUBMITTING_FOR_VISIBILITY_VALUE = 2;
export const CUSTOMER_HAS_PURCHASED_AND_IS_READY_FOR_ONBOARDING_VALUE = 3;

export const TERMS_OF_SERVICE = "termsOfService";
export const SETTINGS_FIND_AN_EXPERT_VERSION = "find_an_expert_version";
export const SETTINGS_TERMS_OF_SERVICE_URL = "terms_of_service_url";
export const SETTINGS_EXPERT_SIGNUP_WELCOME_MESSAGE =
  "expert_signup_welcome_message";
export const SETTINGS_EXPERT_REQUEST_NOTIFICATION_EMAIL =
  "expert_request_notification_email";
export const SETTINGS_DEFAULT_ACCOUNT_MANAGER = "default_account_manager";
export const SETTINGS_MARKETPLACE = "marketplace";
export const SETTINGS_PORTAL_NAME = "marketplace_name";
export const SETTINGS_PORTAL_TALENT_NAME = "marketplace_talent_name";
export const SETTINGS_EXPERT_DEFAULT_TITLE = "expert_default_title";
export const SETTINGS_STRIPE_SECRET_KEY = "stripe_secret_key";
export const SETTINGS_STRIPE_PROVIDER_API_KEY = "stripe_provider_api_key";
export const SETTINGS_LEGAL_DISCLOSURE = "legal_disclosure";
export const SETTINGS_PORTAL_LOGO_REDIRECT_URL =
  "marketplace_logo_redirect_url";
export const BACKGROUND_IMAGE = "background_image";
export const SETTINGS_PORTAL_FAV_ICON = "marketplace_fav_icon";
export const SETTINGS_EXPERT_SIGNUP_HERO_IMAGE = "expert_signup_hero_image";
export const SETTINGS_CUSTOMER_SIGNUP_HERO_IMAGE = "customer_signup_hero_image";
export const SETTINGS_PORTAL_LOGO = "marketplace_logo";
export const BACKGROUND_IMAGE_URL = "background_image";
export const SETTINGS_SECONDARY_PORTAL_API_BASE_URL =
  "secondary_portal_api_base_url";
export const SETTINGS_SECONDARY_PORTAL_API_DEFAULT_KEY =
  "secondary_portal_api_default_key";
export const SETTINGS_SECONDARY_PORTAL_REDIRECT_MESSAGE =
  "secondary_portal_redirect_message";

export const SETTINGS_MARKETPLACE_SALESFORCE_ESSENTIALS =
  "Salesforce Essentials";
export const SETTINGS_PORTAL_TORCHLITE = "Torchlite";
export const SETTINGS_PORTAL_TERMINUS = "Terminus";

// Metacx
export const SETTINGS_METACX_CLIENT_ID = "metacx_client_id";
export const SETTINGS_METACX_CLIENT_SECRET = "metacx_client_secret";
export const SETTINGS_METACX_API_URL = "metacx_api_url";
export const SETTINGS_METACX_CUSTOMER_ID = "metacx_customer_id";
export const SETTINGS_METACX_VISITOR_VIRTUAL_CUSTOMER_ID =
  "metacx_visitor_virtual_customer_id";
export const SETTINGS_METACX_EXPERT_VIRTUAL_CUSTOMER_ID =
  "metacx_expert_virtual_customer_id";
export const SETTINGS_METACX_ADMIN_VIRTUAL_CUSTOMER_ID =
  "metacx_admin_virtual_customer_id";
export const SETTINGS_PORTAL_DEFAULT_LANDING_PAGE_URL =
  "marketplace_default_landing_page_url";
export const SETTINGS_PORTAL_DEFAULT_START_PAGE_URL =
  "marketplace_default_start_page_url";

export const SETTINGS_DEFAULT_CLOSE_DAYS = "referral_default_close_days";
// Theme
export const SETTINGS_PORTAL_HEADER_COLOR = "marketplace_header_color";
export const SETTINGS_PORTAL_HEADER_TEXT_COLOR =
  "marketplace_header_text_color";
export const SETTINGS_PORTAL_FOOTER_COLOR = "marketplace_footer_color";
export const SETTINGS_PORTAL_FOOTER_HEADING_COLOR =
  "marketplace_footer_heading_color";
export const SETTINGS_PORTAL_FOOTER_TEXT_COLOR =
  "marketplace_footer_text_color";
export const SETTINGS_PORTAL_FOOTER_COPY_RIGHTS_TEXT =
  "marketplace_footer_copy_rights_text";
export const SETTINGS_PORTAL_DESKTOP_FOOTER_CONTENT =
  "marketplace_desktop_footer_content";
export const SETTINGS_PORTAL_MOBILE_FOOTER_CONTENT =
  "marketplace_mobile_footer_content";
export const FIND_AN_EXPERT_FORM = "find_an_expert_Form";
export const SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_IMAGE =
  "marketplace_left_navigation_background_image";
export const SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_COLOR =
  "marketplace_left_navigation_text_color";
export const SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_HOVER_OVER_COLOR =
  "marketplace_left_navigation_text_hover_over_color";
export const SETTINGS_PORTAL_PUBLIC_PAGE_FOOTER_CONTENT =
  "public_page_footer_content";

// Partner
export const SETTINGS_FIND_AN_PARTNER_VERSION = "find_an_partner_version";
export const SETTINGS_PARTNER_SIGNUP_WELCOME_MESSAGE =
  "partner_signup_welcome_message";
export const SETTINGS_PARTNER_REQUEST_NOTIFICATION_EMAIL =
  "partner_request_notification_email";
export const SETTINGS_REFERRAL_SUBMISSION_NOTIFICATION_EMAIL =
  "referral_submission_notification_email";
export const SETTINGS_DEAL_SUBMISSION_NOTIFICATION_EMAIL =
  "deal_submission_notification_email";
export const SETTINGS_PARTNER_INVITE_EMAIL_SUBJECT =
  "partner_invite_email_subject";
export const SETTINGS_PARTNER_INVITE_EMAIL_MESSAGE =
  "partner_invite_email_message";
export const SETTINGS_REFERRAL_DECLINE_EMAIL_SUBJECT =
  "referral_decline_email_subject";
export const SETTINGS_REFERRAL_DECLINE_EMAIL_MESSAGE =
  "referral_decline_email_message";
export const SETTINGS_EMAIL_PRIMARY_BACKGROUND_COLOR =
  "email_primary_background_color";
export const SETTINGS_EMAIL_PRIMARY_TEXT_COLOR = "email_primary_text_color";
export const SETTINGS_PARTNER_DEFAULT_TITLE = "expert_default_title";
export const SETTINGS_PARTNER_SIGNUP_HERO_IMAGE = "partner_signup_hero_image";
export const SETTINGS_METACX_PARTNER_VIRTUAL_CUSTOMER_ID =
  "metacx_expert_virtual_customer_id";
export const SETTINGS_ALLOW_PARTNER_TO_CREATE_PLAYBOOK =
  "allow_partner_to_create_playbook";
export const SETTINGS_ENABLE_PARTNERS = "enable_partners";
export const SETTINGS_ENABLE_FILES = "enable_files";
export const SETTINGS_ENABLE_EXPERTS = "enable_experts";
export const SETTINGS_GA_TRACKING_ID = "ga_tracking_id";
export const SETTINGS_ENABLE_DEALS = "enable_deals";
export const SETTINGS_ENABLE_PROJECTS = "enable_projects";
export const SETTINGS_ENABLE_RESOURCES = "enable_resources";
export const SETTINGS_ENABLE_ACCOUNTS = "enable_accounts";
export const SETTINGS_ENABLE_CONTACTS = "enable_contacts";
export const SETTINGS_ENABLE_CUSTOMERS = "enable_customers";
export const SETTINGS_ENABLE_COMMUNICATIONS = "enable_communications";
export const SETTINGS_ENABLE_PAYMENTS = "enable_payments";
export const SETTINGS_ENABLE_ACCOUNT_MAPPING = "enable_account_mapping";
export const SETTINGS_ENABLE_ACCOUNTING = "enable_accounting";
export const SETTINGS_ENABLE_USERS = "enable_users";
export const SETTINGS_ENABLE_FEEDBACK = "enable_feedback";
export const SETTINGS_ENABLE_APP = "enable_app";
export const SETTINGS_ENABLE_REFERRAL_ADDITIONAL_CONTACTS =
  "enable_referral_additional_contacts";

export const SETTINGS_ENABLE_REPORTS = "enable_reports";

export const SETTINGS_ENABLE_LEADS = "enable_leads";
export const SETTINGS_ENABLE_CAMPAIGNS = "enable_campaigns";
export const SETTINGS_ENABLE_PARTNER_MANAGER = "enable_partner_manager";
export const SETTINGS_ENABLE_PARTNER_TRAINING = "enable_partner_training";
export const SETTINGS_ENABLE_MARKETPLACE = "enable_marketplace";
export const SETTINGS_ENABLE_CONTACT_PARTNER_FORM =
  "enable_custom_contact_form";
export const SETTINGS_ENABLE_PARTNER_PAYMENTS = "enable_partner_payments";

export const SETTINGS_ENABLE_MDF_REQUEST = "enable_mdf_request";

// Playbook settings
export const SETTINGS_ENABLE_PLAYBOOK = "enable_playbook";
export const SETTINGS_ENABLE_PLAYBOOK_INQUIRY = "enable_playbook_inquiry";
export const SETTINGS_ALLOW_PLAYBOOK_PURCHASE = "allow_playbook_purchase";

// Media Settings
export const MEDIA_BASE_URL = "media_base_url";
export const MEDIA_PATH_SETTING = "media/setting";
export const SETTINGS_EMAIL_LOGO = "email_logo";
export const SETTINGS_EMAIL_BACKGROUND_BANNER = "email_background_banner";

// SalesForce Settings
export const SETTINGS_SALESFORCE_URL = "salesforce_url";
export const SETTINGS_SALESFORCE_CLIENT_ID = "salesforce_client_id";
export const SETTINGS_SALESFORCE_CLIENT_SECRET = "salesforce_client_secret";
export const SETTINGS_SALESFORCE_OBJECT = "salesforce_object";
export const SETTINGS_SALESFORCE_ENVIRONMENT = "salesforce_environment";

export const SETTINGS_SALESFORCE_OPPORTUNITY_CLOSED_STATUS_NAME =
  "salesforce_opportunity_closed_status_name";
export const SETTINGS_SALESFORCE_OPPORTUNITY_AMOUNT_FIELD_NAME =
  "salesforce_opportunity_amount_field_name";
export const SETTINGS_SALESFORCE_TERRITORY_FIELD_NAME =
  "salesforce_territory_field_name";
export const SETTINGS_SALESFORCE_OPPORTUNITY_ACCOUNT_CREATED_BY_FIELD_NAME =
  "salesforce_opportunity_account_created_by_field_name";
export const SETTINGS_SALESFORCE_OPPORTUNITY_PARTNER_ACCOUNT_NAME_FIELD =
  "salesforce_opportunity_partner_account_name_field";
export const SETTINGS_SALESFORCE_OPPORTUNITY_PARTNER_CONTACT_NAME_FIELD =
  "salesforce_opportunity_partner_contact_name_field";
export const SETTINGS_SALESFORCE_ACCOUNT_OWNER_FIELD_NAME =
  "salesforce_account_owner_field_name";
export const SETTINGS_SALESFORCE_ACCOUNT_STATUS_FIELD_NAME =
  "salesforce_account_status_field_name";
export const SETTINGS_SALESFORCE_OPPORTUNITY_SYNC_FROM_DATE =
  "salesforce_opportunity_sync_from_date";

// Deal Registration Approval Platform Settings
export const SETTINGS_DEAL_REGISTRATION_APPROVAL_PLATFORM =
  "deal_registration_approval_platform";

// Partner Account Type Name
export const SETTINGS_PARTNER_ACCOUNT_TYPE_NAME = "partner_account_type_name";

export const SALESFORCE_PROSPECT_ACCOUNT_RECORD_TYPE =
  "salesforce_prospect_account_record_type";

export const SALESFORCE_PARTNER_ACCOUNT_RECORD_TYPE =
  "salesforce_partner_account_record_type";

export const SETTINGS_DEAL_REGISTRATION_DEFAULT_OWNER =
  "deal_registration_default_owner";

// Deal Registration Approval Platform Settings
export const SETTINGS_DEAL_REGISTRATION_APPROVAL_IN_SALESFORCE =
  "approval_in_salesforce";
export const SETTINGS_DEAL_REGISTRATION_APPROVAL_IN_TORCHLITE_PLATFORM =
  "approval_in_torchlite_platform";

// Slack Settings
export const SETTINGS_SLACK_ACCESS_TOKEN = "slack_access_token";
export const SETTINGS_SLACK_REFERRAL_SUBMIT_NOTIFICATION_CHANNEL =
  "referral_submission_slack_notification_channel";
export const SETTING_SLACK_APP_URL = "slack_app_url";
export const SETTING_SLACK_ACCESS_TOKEN = "slack_access_token";

//Partner Notification Setting
export const SETTING_PARTNER_INVITE_EMAIL_NOTIFICATION =
  "enable_partner_invite_email_notification";
export const SETTINGS_PARTNER_REFERRAL_APPROVAL_EMAIL_NOTIFICATION =
  "enable_partner_referral_approval_email_notification";
export const SETTINGS_SALESFORCE_UPDATE_EMAIL_NOTIFICATION =
  "enable_salesforce_update_email_notification";

// System Settings
export const SETTINGS_GOOGLE_CLIENT_ID = "google_client_id";
export const SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY = "google_recaptcha_site_key";
export const SETTINGS_GOOGLE_PLACES_API_KEY = "google_places_api_key";
export const SETTINGS_BLUE_JEANS_API_BASE_URL = "blue_jeans_api_base_url";
export const SETTINGS_BLUE_JEANS_BASE_URL = "blue_jeans_base_url";
export const SETTINGS_BLUE_JEANS_PASSWORD = "blue_jeans_password";
export const SETTINGS_BLUE_JEANS_USERNAME = "blue_jeans_username";
export const SETTINGS_LINKEDIN_CLIENT_ID = "linkedin_client_id";
export const SETTINGS_LINKEDIN_CLIENT_SECRET = "linkedin_client_secret";
export const SETTINGS_LINKEDIN_REDIRECT_URL = "linkedin_redirect_url";
export const SETTINGS_LINKEDIN_REDIRECT_URI = "linkedin_redirect_uri";
export const SETTINGS_SENDGRID_API_KEY = "sendgrid_api_key";
export const SETTINGS_OKTA_ISSUER_URL = "okta_issuer_url";
export const SETTINGS_OKTA_CLIENT_ID = "okta_client_id";
export const SETTINGS_AUTH0_DOMAIN_URL = "auth0_domain_url";
export const SETTINGS_AUTH0_CLIENT_ID = "auth0_client_id";
export const SETTINGS_SALESFORCE_LOGIN_CLIENT_ID = "salesforce_login_client_id";
export const SETTINGS_SALESFORCE_LOGIN_REDIRECT_URL =
  "salesforce_login_redirect_url";
export const SETTINGS_OFFICE365_CLIENT_ID = "office365_client_id";
export const SETTINGS_OFFICE365_REDIRECT_URI = "office365_redirect_uri";
export const SETTINGS_WEBSITE_BASE_URL = "website_base_url";
export const SETTINGS_STRIPE_ACCOUNT_ID = "stripe_account_id";
export const SETTINGS_DEFAULT_TIMEZONE = "default_timezone";
export const SETTING_PORTAL_TERMS_AND_CONDITIONS_LINK = "terms_of_service_url";

// AWS S3 Settings
export const SETTINGS_AWS_KEY_ID = "aws_key_id";
export const SETTINGS_AWS_SECRET_KEY_ACCESS = "aws_secret_key_access";
export const SETTINGS_AWS_BUCKET = "aws_bucket";

//Social Settings
export const SETTINGS_FACEBOOK_URL = "facebook_url";
export const SETTINGS_INSTAGRAM_URL = "instagram_url";
export const SETTINGS_LINKEDIN_URL = "linkedin_url";
export const SETTINGS_TWITTER_URL = "twitter_url";
export const SETTINGS_YOUTUBE_URL = "youtube_url";

//Referral Settings
export const SETTINGS_ENABLE_REFERRALS = "enable_referrals";
export const SETTINGS_ENABLE_REFERRAL_REQUEST = "enable_referral_request";
export const SETTINGS_ENABLE_PARTNER_REFERRAL_TRACKING =
  "enable_partner_referral_tracking";
export const SETTINGS_REFERRAL_LINK_BASE_URL = "referral_link_base_url";
//Terms and conditions

export const SETTING_ENABLE_PARTNER_TERMS_AND_CONDITIONS_UPDATE_NOTIFICATION =
  "enable_partner_terms_and_conditions_update_notification";
//Sales Routing Settings
export const SETTINGS_ENABLE_SALES_ROUTING = "enable_sales_routing";
export const SETTINGS_OPPORTUNITY_OWNER_FOR_EXISTING_PROSPECT_ACCOUNT =
  "enable_opportunity_owner_for_existing_prospect_account";
export const SETTINGS_OPPORTUNITY_OWNER_FOR_NEW_PROSPECT_ACCOUNT =
  "enable_opportunity_for_new_prospect_account";

export const SETTINGS_ENABLE_HUBSPOT_SALES_ROUTING =
  "enable_hubspot_sales_routing";
export const SETTINGS_DEAL_OWNER_FOR_EXISTING_PROSPECT_COMPANY =
  "enable_deal_owner_for_existing_prospect_company";
export const SETTINGS_DEAL_OWNER_FOR_NEW_PROSPECT_COMPANY =
  "enable_deal_for_new_prospect_company";

//Help Settings
export const SETTINGS_ENABLE_HELP = "enable_help";

//Payment Settings
export const SETTINGS_ENABLE_REFERRAL_PAYMENTS = "enable_referral_payments";
export const SETTINGS_ENABLE_SCHEDULE_PAYMENT = "enable_schedule_payment";
export const SETTINGS_ENABLE_REFERRAL_PAYMENT_TRACKING =
  "enable_referral_payment_tracking";
export const SETTINGS_ENABLE_INVOICE_SETTINGS = "enable_invoice_settings";
export const SETTINGS_PARTNERS_SUBMIT_INVOICE_MANUALLY =
  "enable_partner_submit_invoice_manually";
export const SETTINGS_PAYMENT_WORKFLOW = "enable_payment_workflow";
export const SETTINGS_PARTNER_SUBMIT_MAIL = "partner_submit_mail";
export const SETTINGS_ENABLE_HUBSPOT_SYNC_REFERRAL =
  "enable_hubspot_sync_referral";

// Ramble Chat Settings
export const SETTINGS_RAMBLE_CHAT_BOT_SCRIPT = "ramble_chat_bot_script";
export const SETTINGS_RAMBLE_CHAT_ENABLE_ADMIN = "enable_ramble_chat_admin";
export const SETTINGS_RAMBLE_CHAT_ENABLE_CUSTOMER =
  "enable_ramble_chat_customer";
export const SETTINGS_RAMBLE_CHAT_ENABLE_PARTNER = "enable_ramble_chat_partner";
export const SETTINGS_RAMBLE_CHAT_ENABLE_EXPERT = "enable_ramble_chat_expert";
export const SETTINGS_RAMBLE_CHAT_ENABLE_GUEST = "enable_ramble_chat_guest";

// Bill.com Settings
export const SETTINGS_BILL_DOT_COM_ENVIRONMENT = "bill_dot_com_environment";
export const SETTINGS_BILL_DOT_COM_ORGANIZATION_ID =
  "bill_dot_com_organization_id";
export const SETTINGS_BILL_DOT_COM_DEVELOPMENT_KEY =
  "bill_dot_com_development_key";
export const SETTINGS_BILL_DOT_COM_USER_ID = "bill_dot_com_user_id";
export const SETTINGS_BILL_DOT_COM_SESSION_ID = "bill_dot_com_session_id";

// Crossbeam Settings
export const SETTINGS_CROSSBEAM_CLIENT_ID = "crossbeam_client_id";
export const SETTINGS_CROSSBEAM_CLIENT_SECRET = "crossbeam_client_secret";
export const SETTINGS_CROSSBEAM_REFRESH_TOKEN = "crossbeam_refresh_token";

//Google Settingss
export const SETTINGS_GOOGLE_API_CLIENT_ID = "google_api_client_id";
export const SETTINGS_GOOGLE_API_CLIENT_SECRET = "google_api_client_secret";
export const SETTINGS_GOOGLE_API_REFERSH_TOKEN = "google_api_refresh_token";

// Social Login Settings
export const SETTINGS_ENABLE_SALESFORCE_LOGIN = "enable_salesforce_login";
export const SETTINGS_ENABLE_OFFICE_365_LOGIN = "enable_office_365_login";
export const SETTINGS_ENABLE_GOOGLE_LOGIN = "enable_google_login";
export const SETTINGS_ENABLE_LINKEDIN_LOGIN = "enable_linkedin_login";
export const SETTINGS_ENABLE_OKTA_LOGIN = "enable_okta_login";
export const SETTINGS_ENABLE_AUTH0_LOGIN = "enable_auth0_login";

// Marketplace Company Address
export const SETTINGS_PORTAL_COMPANY_NAME = "marketplace_company_name";
export const SETTINGS_PORTAL_COMPANY_ADDRESS = "marketplace_company_address";
export const SETTINGS_PORTAL_COMPANY_ZIP_CODE = "marketplace_company_zip_code";
export const SETTINGS_PORTAL_COMPANY_CITY = "marketplace_company_city";
export const SETTINGS_PORTAL_COMPANY_STATE = "marketplace_company_state";
export const SETTINGS_PORTAL_COMPANY_COUNTRY = "marketplace_company_country";
export const SETTINGS_PORTAL_COMPANY_PHONE = "marketplace_company_phone";
export const SETTINGS_PORTAL_COMPANY_WEBSITE = "marketplace_company_website";
export const SETTINGS_PORTAL_URL = "portal_url";
export const SETTINGS_PORTAL_API_URL = "portal_api_url";
export const SETTINGS_PORTAL_FROM_EMAIL = "marketplace_from_email";
export const SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME =
  "marketplace_from_email_display_name";
export const SETTINGS_ENABLE_PARTNER_APPLICATIONS =
  "enable_partner_applications";
export const SETTINGS_THEME_LAYOUT = "theme_layout";
//Dashboard account mapping
export const SETTINGS_DASHBOARD_SHOW_ACCOUNT_MAPPING =
  "dashboard_show_account_mapping";

//Enable Update Notes Config
export const SETTINGS_ENABLE_SALESFORCE_ACCOUNT_NOTE_UPDATE =
  "enable_salesforce_account_note_update";

//training source constant
export const TRAINING_SOURCE_BUILD_IN_TRAINING = "Built-in Training";
export const TRAINING_SOURCE_THIRD_PARTY_TRAINING_SYSTEM =
  "Third Party Training System";
export const SETTING_TRAINING_SOURCE = "training_source";
export const TRAINING_THIRD_PARTY_SYSTEM_NAME =
  "training_third_party_system_name";
export const TRAINING_THIRD_PARTY_CTA_TEXT = "training_third_party_subtext";
export const TRAINING_THIRD_PARTY_LOGO = "training_third_party_logo";
export const TRAINING_THIRD_PARTY_URL = "training_third_party_url";

//Layout Dropdown
export const THEME_LAYOUT_FIXED_WIDTH = "Fixed Width";
export const THEME_LAYOUT_FLUID_WIDTH = "Fluid Width";

//Referral Questions
export const SETTING_REFERRAL_SHOW_CUSTOMER_READINESS =
  "referral_show_customer_readiness";
export const SETTING_REFERRAL_CUSTOMER_READINESS_OPTION =
  "referral_customer_readiness_option";

// clutch review
export const SETTINGS_ENABLE_CLUTCH_REVIEW = "enable_clutch_review";

// Partner programs
export const SETTINGS_ENABLE_PARTNER_PROGRAMS = "enable_partner_programs";

//Hubspot Setting
export const SETTINGS_HUBSPOT_CLIENT_ID = "hubspot_client_id";
export const SETTINGS_HUBSPOT_CLIENT_SECRET = "hubspot_client_secret";
export const SETTINGS_HUBSPOT_REFRESH_TOKEN = "hubspot_refresh_token";

export const SALESFORCE_REFRESH_TOKEN = "salesforce_refresh_token";

export const TRAINING_CATEGORY_TAB = "Category";
export const TRAINING_GENERAL_TAB = "General";
export const CROSS_BEAM_TAB = "Cross Beam";

export const SETTINGS_SHOW_REFERRAL_NUMBER = "show_referral_number";
export const SETTINGS_SHOW_PARTNER_MANAGER = "show_partner_manager";
export const SETTINGS_SHOW_REFERRAL_AMOUNT = "show_referral_amount";
export const SETTINGS_SHOW_REGION = "show_region";
export const SETTINGS_REFERRAL_PHONE_NUMBER_REQUIRED =
  "referral_phone_number_required";
export const SETTINGS_ALLOW_PARTNER_MANAGER_TO_MANAGE_OTHER_REFERRALS =
  "allow_partner_managers_to_manage_others_referral";

export const SETTINGS_PASSWORD_EXPIRY_DAYS = "password_expiry_days";
export const SHOW_BECOME_A_PARTNER_OPTION_IN_LOGIN =
  "show_become_a_partner_option_in_login";

export const ALLOW_UPGRADE = "allow_upgrade";

export const UPGRADE_HUBSPOT_FORM_URL = "upgrade_hubspot_form_url";

export const TAB_CERTIFICATES = "Certificates";
export const TAB_INTENT_DATA = "Intent Data";
export const TAB_INTENT_DATA_COMPANY = "Intent Data Company";
export const TAB_INTENT_DATA_WEEK = "Intent Data Week";
export const SETTINGS_ENABLE_COSELL = "enable_co_sell";
export const ENABLE_2FA_ON_LOGIN = "enable_2fa_on_login";
export const TAB_COSELL = "Co-Sell";
export const COSELL_GENERAL_TAB = "General";
export const COSELL_TAG_LIST = "List";
export const COSELL_TAG_GENERAL = "General";
export const TIME_ZONE = "time_zone";
export const TAB_DEAL = "Deal";
export const TAB_DEAL_SETTINGS = "Settings";
export const ALLOW_PARTNER_CHANGE = "allow_partner_change";
export const SHOW_IN_SINGLE_LINE = "show_in_single_line";
export const TAB_APP_SETTINGS = "Settings";
export const SHOW_FILTER = "show_filter";
export const SHOW_PARTNER_TIER = "show_partner_tier";
export const SHOW_PARTNER_TYPE = "show_partner_type";
export const SETTINGS_PORTAL_TAG_LINE = "portal_tag_line";

/**
 * Get Admin Setting Side Nav List
 *
 * @returns {Array}
 */
export function getAdminSettingsNavList(settings, roleId) {
  let adminSettingsNavList = [];
  // Return Admin Setting Nav Object
  const getadminSettingNavObject = (tab, subtab, tabLabel = "") => {
    return {
      name: tab,
      defaultSubTab: subtab,
      label: tabLabel
    };
  };
  const enableExperts = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS)
    : "";
  const enablePayments = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_PAYMENTS)
    : "";
  let enableThemeView = Permission.get(Permission.ENABLE_THEME_VIEW);
  // Check Admin User or Manager User
  if (
    isSuperAdmin(roleId) ||
    isCompanyAdmin(roleId) ||
    isCompanyManager(roleId)
  )
    adminSettingsNavList = [
      ...adminSettingsNavList,
      getadminSettingNavObject(
        TAB_GENERAL,
        GENERAL_GENERAL_SUB_TAB,
        TAB_GENERAL
      ),
      getadminSettingNavObject(
        TAB_PARTNER,
        PARTNER_PROFILE_SUB_TAB,
        TAB_PARTNER
      ),
      getadminSettingNavObject(
        TAB_REFERRALS,
        REFERRAL_LINKS_SUB_TAB,
        Terminology.get(SystemName.REFERRAL_DEAl_REG)
      ),
      getadminSettingNavObject(TAB_DEAL, TAB_DEAL_SETTINGS, TAB_DEAL),
      getadminSettingNavObject(
        TAB_PARTNER_ONBOARDING,
        PARTNER_ONBOARDING_SUB_TAB,
        TAB_PARTNER_ONBOARDING
      ),
      getadminSettingNavObject(TAB_APPS, APP_CATEGORY, TAB_APPS),
      getadminSettingNavObject(
        TAB_PARTNER_RESOURCE,
        TAB_PARTNER_RESOURCE,
        TAB_PARTNER_RESOURCE
      ),
      getadminSettingNavObject(
        TAB_TRAINING,
        TRAINING_GENERAL_TAB,
        TAB_TRAINING
      ),
      getadminSettingNavObject(
        TAB_NOTIFICATION,
        NOTIFICATION_EMAIL_SUB_TAB,
        TAB_NOTIFICATION
      ),
      getadminSettingNavObject(
        TAB_MARKETPLACE,
        TAB_MARKETPLACE,
        TAB_MARKETPLACE
      ),
      getadminSettingNavObject(
        TAB_INTEGRATION,
        INTEGRATION_CRMS_SUB_TAB,
        TAB_INTEGRATION
      ),
      getadminSettingNavObject(
        TAB_EMAIL_TEMPLATE,
        TAB_EMAIL_TEMPLATE,
        TAB_EMAIL_TEMPLATE
      ),
      getadminSettingNavObject(
        TAB_TERMINOLOGY,
        TAB_TERMINOLOGY,
        TAB_TERMINOLOGY
      ),
      getadminSettingNavObject(TAB_ROLE, TAB_ROLE, TAB_ROLE),
      getadminSettingNavObject(
        TAB_CERTIFICATES,
        TAB_CERTIFICATES,
        TAB_CERTIFICATES
      ),
      getadminSettingNavObject(TAB_COSELL, COSELL_GENERAL_TAB, TAB_COSELL),
      getadminSettingNavObject(
        TAB_INTENT_DATA,
        TAB_INTENT_DATA_COMPANY,
        TAB_INTENT_DATA
      )
    ];
  if (enableThemeView == true) {
    adminSettingsNavList.push(
      getadminSettingNavObject(TAB_THEME, THEME_EMAIL_SUB_TAB, TAB_THEME)
    );
  }
  if (enableExperts === "true") {
    adminSettingsNavList.push(
      getadminSettingNavObject(TAB_EXPERT, TAB_EXPERT, TAB_EXPERT)
    );
  }
  if (enablePayments === "true") {
    adminSettingsNavList.push(
      getadminSettingNavObject(
        TAB_PAYMENTS,
        OUTGOING_PAYMENTS_SUB_TAB,
        TAB_PAYMENTS
      )
    );
  }
  // Return Admin Settings Nav List
  return adminSettingsNavList;
}
