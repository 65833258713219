import React, { useContext, useEffect, useState } from "react";
import moment from "moment-timezone";

// Context
import KickoffContext from "../../../../context/kickoffContext/KickoffContext";
import ProjectContext from "../../../../context/projectContext/ProjectContext";

// Helper
import {
  isCustomer,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../../lib/helper";

// Icons
import { CheckmarkIcon } from "../../../../assets/img/icons";

// Inner Components
import SidebarItem from "../sidebar-items/SidebarItem";

import { useSelector } from "react-redux";

const KickOffSidebarInfo = React.memo(function KickOffSidebarInfo(props) {
  const context = useContext(ProjectContext);
  const kickoffContext = useContext(KickoffContext);

  const { projectId, expertId, editKickoff, toggle } = props;

  const currentUser = useSelector(state => state.user);

  const setKickoffHappened = () => {
    context.updateKickoffTimes("Completed", projectId, expertId, () =>
      context.setKickoffHappened()
    );
  };

  useEffect(() => {
    if (
      kickoffContext &&
      kickoffContext.kickoffData &&
      kickoffContext.kickoffData.status === "Completed"
    ) {
      context.setKickoffHappened();
    }
  }, []);

  return (
    <>
      {console.log(
        kickoffContext &&
          kickoffContext.kickoffData &&
          kickoffContext.kickoffData.selectedTime &&
          kickoffContext.kickoffData.status !== "Completed"
      )}
      {kickoffContext &&
        kickoffContext.kickoffData &&
        kickoffContext.kickoffData.selectedTime &&
        kickoffContext.kickoffData.status !== "Completed" && (
          <SidebarItem>
            <div className="field-wrapper">
              <p className="font-weight-bold mb-1">Kickoff Call</p>
              <div className="d-flex align-items-center">
                <div
                  className="sidebar-task"
                  onClick={() => {
                    !context.kickoffHappened &&
                      (isCustomer(currentUser && currentUser.roleId) ||
                        isSuperAdmin(currentUser && currentUser.roleId) ||
                        isCompanyAdmin(currentUser && currentUser.roleId) ||
                        isCompanyManager(currentUser && currentUser.roleId)) &&
                      setKickoffHappened();
                  }}
                >
                  <div
                    className={`checkbox ${
                      context.kickoffHappened ? "checkbox-completed" : ""
                    }`}
                  >
                    {context.kickoffHappened ? <CheckmarkIcon /> : ""}
                  </div>
                </div>

                <p className="font-weight-bold">
                  <span className="text-grayed">
                    {moment(
                      `${kickoffContext.kickoffData.selectedTime.date} ${kickoffContext.kickoffData.selectedTime.time}`
                    ).calendar()}
                  </span>

                  {!context.kickoffHappened && editKickoff && (
                    <a
                      href="#"
                      onClick={toggle}
                      className="ml-2 font-weight-bold"
                      style={{ fontSize: "12px" }}
                    >
                      Edit
                    </a>
                  )}
                </p>
              </div>
            </div>
          </SidebarItem>
        )}

      {(isSuperAdmin(currentUser && currentUser.roleId) ||
        isCompanyAdmin(currentUser && currentUser.roleId) ||
        isCompanyManager(currentUser && currentUser.roleId)) &&
        kickoffContext &&
        kickoffContext.kickoffData &&
        kickoffContext.kickoffData.selectedTime &&
        kickoffContext.kickoffData.status === "Completed" && (
          <SidebarItem>
            <div className="field-wrapper">
              <p className="font-weight-bold mb-1">Kickoff Call</p>
              <div className="d-flex align-items-center">
                <div className="sidebar-task">
                  <div className="checkbox checkbox-completed">
                    <CheckmarkIcon />
                  </div>
                </div>

                <p className="font-weight-bold">
                  <span className="text-grayed">
                    {moment(
                      `${kickoffContext.kickoffData.selectedTime.date} ${kickoffContext.kickoffData.selectedTime.time}`
                    ).calendar()}
                  </span>
                </p>
              </div>
            </div>
          </SidebarItem>
        )}
    </>
  );
});

export default KickOffSidebarInfo;
