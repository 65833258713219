import React from "react";

// Components
import Form from "../../../components/base/Form";
import ReferralFormFields from "./ReferralFormFields";
import CancelButton from "../../../components/base/CancelButton";
import Button from "../../../components/base/Button";

//Sidebar
import SideBar from "./components/SideBar";

import ReferralRequestService from "../../../services/ReferralRequestService";

const ReferralRequest = props => {
  let initialValues = {
    accountName: "",
    accountUrl: ""
  };

  const submitReferral = values => {
    if (props.location && props.location.state) {
      values.accountList = props.location.state.accountList;
    }
    ReferralRequestService.create(values, () => {
      props.history.push("/referrals?activeTab=Referral Request");
    });
  };

  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          submitReferral(values);
        }}
      >
        <div className="card border-0">
          <div className="project-details-body">
            <ReferralFormFields />
            <SideBar
              accountList={
                props.location &&
                props.location.state &&
                props.location.state.accountList
              }
              referralStatus="Draft"
            />
          </div>
        </div>
        <div className="pt-3 pb-4 pull-right">
          <CancelButton
            className="ml-auto mr-1"
            onClick={() => (window.location.href = "/referrals")}
          />
          <Button
            id="submitButton"
            label={`Save`}
            type="submit"
            className="btn btn-primary"
          />
        </div>
      </Form>
    </>
  );
};

export default ReferralRequest;
