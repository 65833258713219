import React, { useEffect, useState } from "react";

// Components
import Select from "./base/Select";

// Constants
import {
  CUSTOMER_ROLE,
  CUSTOMER_ROLE_TEXT,
  EXPERT_ROLE,
  EXPERT_ROLE_TEXT,
  COMPANY_ADMIN_ROLE,
  ROLE_COMPANY_ADMIN_TEXT,
  PARTNER_ROLE,
  PARTNER_ROLE_TEXT,
  COMPANY_MANAGER_ROLE,
  COMPANY_MANAGER_ROLE_TEXT,
  ROLE_SUPER_ADMIN_TEXT,
  SUPER_ADMIN_ROLE
} from "../roles/Constants";
import { getCookie } from "../lib/helper";

const UserRole = props => {
  const [userRoles, setUserRoleOptions] = useState([]);
  const {
    name,
    id,
    label,
    placeholder,
    error,
    required,
    onChange,
    defaultValue,
    roleNames
  } = props;
  // Get country option list
  const getUserRole = () => {
    //User Role Options
    const userRoles =
      roleNames == ROLE_COMPANY_ADMIN_TEXT ||
      roleNames == COMPANY_MANAGER_ROLE_TEXT
        ? [
            {
              value: COMPANY_ADMIN_ROLE,
              label: ROLE_COMPANY_ADMIN_TEXT
            },
            {
              value: COMPANY_MANAGER_ROLE,
              label: COMPANY_MANAGER_ROLE_TEXT
            }
          ]
        : [
            {
              value: SUPER_ADMIN_ROLE,
              label: ROLE_SUPER_ADMIN_TEXT
            },
            {
              value: COMPANY_ADMIN_ROLE,
              label: ROLE_COMPANY_ADMIN_TEXT
            },

            {
              value: EXPERT_ROLE,
              label: EXPERT_ROLE_TEXT
            },
            {
              value: COMPANY_MANAGER_ROLE,
              label: COMPANY_MANAGER_ROLE_TEXT
            },
            {
              value: CUSTOMER_ROLE,
              label: CUSTOMER_ROLE_TEXT
            },

            {
              value: PARTNER_ROLE,
              label: PARTNER_ROLE_TEXT
            }
          ];

    setUserRoleOptions(userRoles);
    return userRoles;
  };
  useEffect(() => {
    getUserRole();
  }, []);
  return (
    <Select
      id={id || name}
      name={name}
      label={label}
      options={userRoles}
      placeholder={placeholder}
      error={error}
      required={required}
      onInputChange={onChange}
      defaultValue={defaultValue}
      isDisabled={getCookie("role") == SUPER_ADMIN_ROLE ? false : true}
    />
  );
};

export default UserRole;
