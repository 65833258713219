import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import Form from "../../../components/base/Form";
import TextArea from "../../../components/base/TextArea";

const AdditionalInterestedInfoModal = ({ modal, setModal, sendMessage }) => {
  const handleSubmit = values => {
    setModal();
    sendMessage(values);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      backdrop="static"
      className={["edit-task-modal", "interested-in-project-modal"].join(" ")}
    >
      <Form
        initialValues={{ interested_message: "" }}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        <ModalHeader toggle={setModal}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Great, You’re Interested!
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <p className={["text-center"].join(" ")}>
            Let us know if you have any specifications by using the text area
            below or click “No Specifications”:
          </p>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <TextArea
                label={"Interested if..."}
                name={"interested_message"}
                placeholder={"Your Message..."}
                error="Your message is required"
                required
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <button
              className="btn btn-outline-secondary mr-2"
              type={"button"}
              onClick={setModal}
            >
              No Specifications
            </button>
            <button className="btn btn-primary" type={"submit"}>
              Send message
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AdditionalInterestedInfoModal;
