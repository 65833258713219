import React from "react";
import toast from "../../components/base/Toast";

// Components
import Form from "../../components/base/Form";
import Text from "../../components/base/Text";
import Button from "../../components/base/Button";
import SaveButton from "../../components/base/SaveButton";
import CancelButton from "../../components/base/CancelButton";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import { toString } from "../../lib/helper";
import { isBadRequest } from "../../common/http";

export default class UpdateTagTypeForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      loading: false
    };
  }

  componentDidMount = () => {
    this.getInputValue();
  };

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save tag type form in the final
    this._tagTypeRequest(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define tag type request constants
    const name = values.name;

    if (!name) {
      success = false;
    }

    if (name && name.length > 51) {
      toast.error("Max allowed character 50");
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.name = toString(values.name);
    return values;
  }

  // Tag type type Request API Call
  _tagTypeRequest(data) {
    return apiClient
      .put(`${endpoints().tagTypeAPI}/${this.props.match.params.id}`, data)
      .then(() => {
        window.location.replace("/settings/tagtypes");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Tag type delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().tagTypeAPI}/${this.props.match.params.id}`)
      .then(() => {
        window.location.replace("/settings/tagtypes");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Tag type request api call by id
  getInputValue = () => {
    return apiClient
      .get(`${endpoints().tagTypeAPI}/${this.props.match.params.id}`)
      .then(response => {
        this.setState({ name: response.data.name, loading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  render() {
    if (!this.state.loading) {
      return "";
    }

    const initialValues = {
      name: this.state.name
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          this._submit(values);
        }}
      >
        <div>
          <Text
            name="name"
            label="Name"
            placeholder="Enter name"
            required={true}
            error=""
          />
        </div>

        <div>
          <SaveButton />
          <CancelButton onClick={() => this.props.history.goBack()} />
          <Button
            label="Delete"
            align="right"
            onClick={this.handleDelete}
            color="red"
          />
        </div>
      </Form>
    );
  }
}
