import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { WarningRedIcon } from "../../assets/img/icons";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

// const [customerReadinessModalOpen, setCustomerReadinessModal] = useState(false);
const ReferralModal = ({
  customerReadinessModalOpen,
  setCustomerReadinessModal,
  setCustomerReadiness,
  id
}) => {
  return (
    <>
      <Modal
        isOpen={customerReadinessModalOpen}
        className={["log-hours-modal", "edit-task-modal"].join(" ")}
      >
        <ModalHeader
          toggle={() => {
            setCustomerReadinessModal(false);
          }}
        >
          <WarningRedIcon />
          <h4 className={["font-weight-bold", "mt-3"].join(" ")}>
            Customer Readiness was not indicated
          </h4>
        </ModalHeader>
        <ModalBody className={["text-center", "mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <h6 className="mx-4">
                You'll need to indicate the customer's readiness before
                submitting a {Terminology.get(SystemName.REFERRAL)}
              </h6>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          className={["justify-content-center", "border-top-1"].join(" ")}
        >
          <div className="btn-wrapper">
            <button
              id={id}
              className="btn btn-primary px-3"
              onClick={() => {
                setCustomerReadinessModal(false);
                setCustomerReadiness();
              }}
            >
              Back to Select Customer Readiness
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ReferralModal;
