import React from "react";

// Components
import Form from "../../../../../components/base/Form";
import DefaultContent from "../../../../../components/content/DefaultContent";
import Text from "../../../../../components/base/Text";
import SaveButton from "../../../../../components/base/SaveButton";

// Constants
import { SETTINGS_GA_TRACKING_ID } from "../../../../../setting/Constants";

const GoogleAnalyticsSection = props => {
  const { adminSettings, saveSettings } = props;

  const { ga_tracking_id } = adminSettings;

  // Google Analytics Section Initial Values
  const googlAnalyticsInitialValues = {
    ga_tracking_id
  };

  // Google Analytics Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Google Analytics tracking Id
    if (values.ga_tracking_id !== undefined) {
      data.append(
        "ga_tracking_id",
        values.ga_tracking_id ? values.ga_tracking_id : ""
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={googlAnalyticsInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent title="Google Analytics">
        <div className="form-wrapper">
          <h5 className="font-weight-bold">Google Analytics</h5>
          <div className="field-wrapper">
            <Text name={SETTINGS_GA_TRACKING_ID} placeholder="GA Tracking ID" />
          </div>
          <div className="btn-wrapper">
            <SaveButton />
          </div>
        </div>
      </DefaultContent>
    </Form>
  );
};

export default GoogleAnalyticsSection;
