import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { DropdownItem } from "reactstrap";

// Action
import { updateCompanyStatus } from "../../actions/company";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import PageTitle from "../../components/base/PageTitle";
import Page404 from "../Page404";
import MoreDropdown from "../../components/base/MoreDropdown";

// Helper
import { isLoggedIn, isSuperAdmin } from "../../lib/helper";
import { endpoints } from "../../configs";

// Constants
import {
  COMPANY_STATUS_APPROVED,
  COMPANY_STATUS_PENDING,
  COMPANY_STATUS_DECLINED
} from "../../Company/Constants";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class Companies extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByOptions: [
        {
          value: "name:ASC",
          label: "Company Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ]
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  // Update company status handler
  updateCompanyByStatus(status, companyId) {
    this.props.actions.updateCompanyStatus(
      companyId,
      { status: status },
      {
        sort: this.props.companiesSort,
        sortDir: this.props.companiesSortDir
      }
    );
  }

  render() {
    if (!isSuperAdmin(this.props.roleId)) {
      return <Page404 />;
    }

    return (
      <>
        {/* /.page-heading */}
        <PageTitle label="Companies" />
        <div className="mt-4 mb-5">
          <ReduxTable
            id="companies"
            showHeader
            searchPlaceholder="Search"
            apiURL={endpoints().companyAPI}
            onRowClick={row => this.props.history.push(`/company/${row.id}`)}
            newTableHeading
            sortByOptions={this.state.sortByOptions}
          >
            <ReduxColumn
              type="link"
              isClickable="true"
              field="companyName"
              sortBy="name"
            >
              Company
            </ReduxColumn>
            <ReduxColumn field="email" sortBy="email">
              Email
            </ReduxColumn>
            <ReduxColumn
              field="status"
              disableOnClick
              renderField={row => (
                <div
                  className={`text-center text-uppercase ${
                    row.status !== COMPANY_STATUS_PENDING
                      ? row.status === COMPANY_STATUS_DECLINED
                        ? "bg-danger"
                        : "landing-page-status-active"
                      : "landing-page-status-draft"
                  }`}
                  style={statusStyle}
                >
                  <p>{row.status}</p>
                </div>
              )}
            >
              Status
            </ReduxColumn>
            <ReduxColumn field="createdAt" sortBy="createdAt">
              Created
            </ReduxColumn>
            <ReduxColumn
              field="Action"
              disableOnClick
              renderField={row => (
                <div className="text-center landing-group-dropdown">
                  <MoreDropdown>
                    <DropdownItem
                      onClick={() =>
                        this.updateCompanyByStatus(
                          COMPANY_STATUS_APPROVED,
                          row.id
                        )
                      }
                    >
                      Approve Company
                    </DropdownItem>

                    <DropdownItem
                      onClick={() =>
                        this.updateCompanyByStatus(
                          COMPANY_STATUS_DECLINED,
                          row.id
                        )
                      }
                    >
                      Decline Company
                    </DropdownItem>
                  </MoreDropdown>
                </div>
              )}
            >
              Action
            </ReduxColumn>
          </ReduxTable>
        </div>
      </>
    );
  }
}

// export default Companies;

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updateCompanyStatus }, dispatch)
  };
}

const mapStateToProps = state => {
  const { companies } = state.table;
  const roleId = state.user ? state.user.roleId : "";

  const companiesSort =
    companies && !companies.isFetching && companies.sort !== undefined
      ? companies.sort
      : "createdAt";

  const companiesSortDir =
    companies && !companies.isFetching && companies.sortDir !== undefined
      ? companies.sortDir
      : "DESC";
  return { companiesSort, companiesSortDir, roleId };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(Companies);
