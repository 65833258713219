import React, { Component } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import toast from "../../components/base/Toast";

// Constants
import { TERMS_OF_SERVICE } from "../../setting/Constants";

// Components
import Form from "../../components/base/Form";
import PageTitle from "../../components/base/PageTitle";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import Select from "../../components/base/Select";
import URL from "../../components/base/URL";
import Email from "../../components/base/Email";

// Helper
import { toString } from "../../lib/helper";

// API call
import { apiClient } from "../../apiClient";

// Config
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";

class GeneralSettings extends Component {
  constructor() {
    super();
    this.state = {
      settingsDetails: "",
      isLoading: false,
      activeTab: "1"
    };
  }
  componentDidMount = () => {
    this._getSettingsDetails();
  };

  _submit = values => {
    const { name } = this.state.settingsDetails;
    if (TERMS_OF_SERVICE === name) {
      this._updateSettings(this._toArray(values));
      return;
    }
    // Save settings
    this._createSettings(this._toArray(values));
  };

  // To Array
  _toArray(values) {
    values.name = TERMS_OF_SERVICE;
    values.value = toString(values.termsOfService);
    return values;
  }

  // Settings API post
  _createSettings(data) {
    return apiClient
      .post(endpoints().settingAPI, data)
      .then(() => {
        window.location.replace("/settings");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Settings update API Call
  _updateSettings(data) {
    return apiClient
      .put(`${endpoints().settingAPI}`, data)
      .then(() => {
        window.location.replace("/settings");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Settings details api call by name
  _getSettingsDetails = () => {
    return apiClient
      .get(`${endpoints().settingAPI}/${TERMS_OF_SERVICE}`)
      .then(response => {
        this.setState({ settingsDetails: response.data, isLoading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Toggle tab
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab });
    }
  };

  expertVersionForm = () => {
    return [
      {
        label: "Simple Form",
        value: "simple_form"
      },
      {
        label: "Multi-Step Wizard",
        value: "multistep_wizard"
      }
    ];
  };

  render() {
    const { isLoading, settingsDetails, activeTab } = this.state;

    if (!isLoading) {
      return "";
    }

    let { value } = settingsDetails;
    const initialValues = {
      termsOfService: value
    };

    return (
      <>
        <PageTitle label="Obsolete Page, will be removed as soon as /Settings is up to date." />
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            this._submit(values);
          }}
        >
          <Nav tabs className="admin-tabs">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "1" })}
                onClick={() => {
                  this.toggle("1");
                }}
              >
                General
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === "2" })}
                onClick={() => {
                  this.toggle("2");
                }}
              >
                List
              </NavLink>
            </NavItem>
          </Nav>
          <div className="tabs-and-preview admin-settings full-width d-flex">
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <div className="tab-content-wrapper">
                  <div className="form-wrapper">
                    <h5 className="font-weight-bold">Find an Expert Version</h5>
                    <div className="field-wrapper">
                      <Select
                        name="expert_version_form"
                        label="Which “Find an Expert” version will work best on your marketplace?"
                        placeholder="Select a version"
                        options={this.expertVersionForm()}
                        isSearchable={true}
                      />
                    </div>

                    <h5 className="font-weight-bold">
                      URL for Terms of Service
                    </h5>
                    <div className="field-wrapper">
                      <URL
                        name="termsOfService"
                        label="Set the path to your terms of service."
                        placeholder="Enter URL"
                        required
                      />
                    </div>

                    <h5 className="font-weight-bold">Email Address</h5>
                    <div className="field-wrapper">
                      <Email
                        name="email"
                        label="This is where you will receive pending Expert request alerts."
                        placeholder="Enter your email..."
                        required
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId="2">
                <div className="tab-content-wrapper">
                  <div className="form-wrapper">
                    <div className="field-wrapper"></div>
                  </div>
                </div>
              </TabPane>
              <div className="btn-wrapper">
                <CancelButton onClick={() => this.props.history.goBack()} />
                <SaveButton />
              </div>
            </TabContent>
            <div className="d-flex justify-content-end">
              <CancelButton onClick={() => this.props.history.goBack()} />
              <SaveButton />
            </div>
          </div>
        </Form>
      </>
    );
  }
}

export default GeneralSettings;
