import React from "react";
import moment from "moment";
import toast from "../../components/base/Toast";
import InvoiceFormFields from "./InvoiceFormFields";

// Components
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import Form from "../../components/base/Form";

// Configs
import { endpoints } from "../../configs";

//API call
import { apiClient } from "../../apiClient";
import { isBadRequest } from "../../common/http";

export default class AddInvoiceForm extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      date: null
    };

    this.handleChange = this.handleChange.bind(this);
  }

  // Handle date changes
  handleChange(date) {
    this.setState({
      date: date
    });
  }

  // Create invoice
  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save invoice form in the final
    this._createInvoice(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;
    return success;
  }

  // To Array
  _toArray(values) {
    values.date = this.state.date
      ? moment(this.state.date).format("YYYY-MM-DD")
      : null;
    return values;
  }

  // Invoice API Call
  _createInvoice(data) {
    return apiClient
      .post(endpoints().invoiceAPI, data)
      .then(() => {
        window.location.replace("/invoice");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    const initialValues = {
      date: ""
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          this._submit(values);
        }}
      >
        <InvoiceFormFields
          handleChange={this.handleChange}
          date={this.state.date}
        />
        <div className="d-flex justify-content-end">
          <CancelButton onClick={() => this.props.history.goBack()} />
          <SaveButton />
        </div>
      </Form>
    );
  }
}
