import React from "react";

// Icons
import { InfoIcon } from "../../assets/img/icons";

// Components
import Email from "../../components/base/Email";
import Phone from "../../components/base/Phone";
import Text from "../../components/base/Text";
import Password from "../../components/base/Password";
import SecondaryButton from "../../components/base/SecondaryButton";
import Checkbox from "./Checkbox";
import Country from "../../components/base/Country";
import State from "../../components/State";
import TimeZone from "../../components/base/TimeZone";
import Zipcode from "../../components/base/Zipcode";

// Constants
import { CANADA, UNITED_STATES } from "../../Country/Constants";

// Configs
import { TORCHLITE_WEBSITE_URL } from "../../configs";

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptTerms: false,
      selectedOption: "",
      selectedCountryName: ""
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  // Handle Country
  handleCountryChange = ({ values }) => {
    const selectedOption = values.country && values.country.id;
    const selectedCountryName = values.country && values.country.id;
    this.setState({
      selectedOption,
      selectedCountryName: selectedCountryName
    });
  };

  render() {
    const { selectedOption, selectedCountryName } = this.state;

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <InfoIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-3">Create an Account</h2>
        </div>
        <div className="content">
          <div className="field-wrapper">
            <Text
              name="companyName"
              label="Company Name"
              placeholder="Enter company name"
              required={true}
              onKeyDown={this.props.hideErrorMessage}
              error={this.props.companyNameErrorMessage}
            />
          </div>

          <div className="field-wrapper">
            <Text
              name="firstName"
              label="First Name"
              placeholder="Enter first name"
              required={true}
              error=""
            />
            <Text
              name="lastName"
              label="Last Name"
              placeholder="Enter last name"
              required={true}
              error=""
            />
          </div>

          <div className="field-wrapper">
            <Email
              name="email"
              label="Email"
              placeholder="Enter email"
              required={true}
              error={this.props.emailErrorMessage}
              onKeyDown={this.props.hideErrorMessage}
            />
            <Phone
              name="phone"
              label="Phone"
              placeholder="Enter phone"
              error=""
              required
            />
          </div>

          {!this.props.authToken && (
            <div className="field-wrapper">
              <Password
                name="password"
                label="Password"
                placeholder="Enter password"
                error={
                  this.props.passwordMismatchError
                    ? this.props.passwordMismatchError
                    : ""
                }
                onInputChange={this.props.handlePasswordErrors}
                required
              />
              <Password
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Enter confirm password"
                error={
                  this.props.confirmPasswordMismatchError
                    ? this.props.confirmPasswordMismatchError
                    : ""
                }
                onInputChange={this.props.handlePasswordErrors}
                required
              />
            </div>
          )}

          <div className="field-wrapper">
            <Text
              name="address1"
              label="Address"
              placeholder="Enter Address"
              error=""
              required={true}
            />
            <Zipcode
              name="postalCode"
              label="Zip/Postal Code"
              placeholder="Enter Zip"
              error=""
              required={true}
              locale={selectedCountryName ? selectedCountryName : UNITED_STATES}
            />
          </div>

          <div className="field-wrapper">
            <Country
              name="country"
              label="Country"
              placeholder="Select Country"
              error=""
              required={true}
              onChange={this.handleCountryChange}
            />
          </div>
          <div className="field-wrapper">
            <Text
              name="city"
              label="City"
              placeholder="Enter city"
              required={true}
              error=""
            />

            <State
              name="state"
              label="State/Province/Region"
              placeholder="Enter State"
              error=""
              selectedCountry={
                this.state.selectedOption
                  ? this.state.selectedOption
                  : this.props.selectedCountry
              }
              selectedCountryName={
                this.props.selectedCountry ? this.props.selectedCountry : ""
              }
            />
          </div>

          <div className="field-wrapper">
            <TimeZone
              name="timezone"
              label="Timezone"
              placeholder="Select Timezone"
            />
            <div className="form-group" />
          </div>

          <div className="field-wrapper">
            <Checkbox
              name="acceptTerms"
              label="I have read and accept the"
              required
              error="You must accept the terms and condition"
              className="accepted-terms mr-3"
            />{" "}
            <a
              className="term-and-condition cursor-pointer"
              href={`${TORCHLITE_WEBSITE_URL}/terms-of-service`}
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions
            </a>
          </div>

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton
              className="mr-4"
              label="Go Back"
              onClick={this.props.prev}
            />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={this.props.next}
            >
              Create Account
            </button>
          </div>
          <p className="text-grayed h7 mt-3">
            Note: We'll keep your personal information private.
          </p>
        </div>
      </div>
    );
  }
}

export default BasicInformation;
