import {
  EXPERT_TYPE_10_20_HOURS_WEEK,
  EXPERT_TYPE_30_PLUS_HOURS_WEEK,
  EXPERT_TYPE_20_30_HOURS_WEEK,
  EXPERT_TYPE_10_40_HOURS_TOTAL,
  EXPERT_TYPE_NOT_CURRENTLY_AVAILABLE
} from "../expertHours/Constants";

export const AVAILABILITY = [
  {
    value: EXPERT_TYPE_10_40_HOURS_TOTAL,
    label: EXPERT_TYPE_10_40_HOURS_TOTAL
  },
  {
    value: EXPERT_TYPE_10_20_HOURS_WEEK,
    label: EXPERT_TYPE_10_20_HOURS_WEEK
  },
  {
    value: EXPERT_TYPE_20_30_HOURS_WEEK,
    label: EXPERT_TYPE_20_30_HOURS_WEEK
  },
  {
    value: EXPERT_TYPE_30_PLUS_HOURS_WEEK,
    label: EXPERT_TYPE_30_PLUS_HOURS_WEEK
  },
  {
    value: EXPERT_TYPE_NOT_CURRENTLY_AVAILABLE,
    label: EXPERT_TYPE_NOT_CURRENTLY_AVAILABLE
  }
];
