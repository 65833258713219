import React, { Component } from "react";
import { Row } from "reactstrap";

// Inner Components
import SideNavBar from "./inner-component/SideNavBar";
import TabContentSection from "./inner-component/TabContentSection";
import { ChevronDown, ChevronUp } from "../../assets/img/icons";

// Helper
import { getKeyValueByObject } from "../../lib/helper";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Library
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

// 404 Page
import Page404 from "../Page404";

// Constants
import { TAB_COMPANIES } from "../../supportPortal/Constants";
import { isBadRequest } from "../../common/http";
import {
  SETTINGS_THEME_LAYOUT,
  THEME_LAYOUT_FLUID_WIDTH
} from "../../setting/Constants";
import detectBrowser from "../../components/helpers/detectBrowser";
import { connect } from "react-redux";

class SupportPortal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      settingsData: "",
      isSettingsLoading: true,
      isAccountManagerListLoading: true,
      isActive: false
    };
  }

  componentDidMount() {
    const activeTab = this.props.match.params.tab;
    if (!activeTab) {
      this.props.history.push(`/support-portal/${TAB_COMPANIES}`);
    }
    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    ) {
      this.getSettingsData(true);
    }
  }

  toggle = (tab, subtab = "") => {
    const activeTab = this.props.match.params.tab;
    const activeSubTab = this.props.match.params.subtab;
    if (activeTab !== tab || activeSubTab !== subtab) {
      this.props.history.push(
        `/support-portal/${tab}${subtab ? "/" + subtab : ""}`
      );
    }
  };

  // Get Settings Data
  getSettingsData = () => {
    let SettingsData = {};
    this.setState({ isSettingsLoading: true }, () => {
      return apiClient
        .get(`${endpoints().systemSettingAPI}`)
        .then(response => {
          const settings = response.data.data;
          if (settings) {
            settings.forEach(data => {
              const name = data.name;
              const value = data.value;
              SettingsData[name] = value;
            });
            this.setState({ settingsData: SettingsData });
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
              this.setState({ isInvalidRequest: true });
            }
            console.error(errorMessage);
          }
        });
    });
  };

  sideBarActive = () => {
    if (this.state.isActive == false) {
      this.setState({ isActive: true });
    } else {
      this.setState({ isActive: false });
    }
  };

  render() {
    const settings =
      this.props.settings && this.props.settings.settings
        ? this.props.settings.settings
        : "";
    const { isLoading, isActive, isMobile } = this.state;

    // Page Not Found
    if (!isSuperAdmin(this.props.roleId)) {
      return <Page404 />;
    }

    if (isLoading) {
      return "";
    }

    const activeTab = this.props.match.params.tab;
    const activeSubTab = this.props.match.params.subtab;
    const activeSection = this.props.match.params.section;
    const activeSubSection = this.props.match.params.subsection;
    const DefaultLayout = settings
      ? getKeyValueByObject(settings, SETTINGS_THEME_LAYOUT)
      : "";
    return (
      <>
        <div className="mobile-menu">
          <div className="card mb-3">
            <div className="m-2 " onClick={() => this.sideBarActive()}>
              <span className="font-weight-bold">Support Portal Menu</span>
              <span className="float-right">
                {isActive == false ? <ChevronDown /> : <ChevronUp />}
              </span>
            </div>
          </div>
          <div className={isActive == false ? "d-none" : "mobile-wrapper"}>
            <SideNavBar activeTab={activeTab} handleClick={this.toggle} />
          </div>
        </div>
        <Row className="mt-n3 mt-md-n4 admin-setting d-flex flex-nowrap">
          <div
            className={`${
              DefaultLayout && DefaultLayout === THEME_LAYOUT_FLUID_WIDTH
                ? "col-lg-3 col-sm-5 sidemenu p-0"
                : " sidemenu"
            }`}
          >
            <div className="side-menu ">
              <SideNavBar activeTab={activeTab} handleClick={this.toggle} />
            </div>
          </div>
          <div
            className={`${
              DefaultLayout && DefaultLayout === THEME_LAYOUT_FLUID_WIDTH
                ? "col "
                : "col col-md-9 col-lg-9"
            }`}
          >
            <TabContentSection
              activeTab={activeTab}
              activeSubTab={activeSubTab}
              activeSection={activeSection}
              activeSubSection={activeSubSection}
              toggleTabs={this.toggle}
              adminSettings={this.state.settingsData}
              history={this.props.history}
              match={this.props.match}
              settings={this.props.settings}
            />
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(SupportPortal);
