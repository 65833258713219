import React from "react";

const DefaultContent = props => {
  const {
    children,
    title,
    titleHint,
    className,
    enableButton,
    buttonLabel,
    onButtonClick,
    certificateImage,
    certificateName,
    id,
    style,
    disabled
  } = props;

  return (
    <div className={`marketplace-section-content ${className}`} style={style}>
      <div className="content">
        {title && (
          <h4>
            <div className="d-flex justify-content-between">
              <b>{title}</b>
              {enableButton && (
                <button
                  id={id}
                  type="button"
                  disabled={disabled}
                  className="btn btn-primary"
                  onClick={onButtonClick ? onButtonClick : null}
                >
                  {buttonLabel}
                </button>
              )}
            </div>
            {titleHint && (
              <span className="text-inline-grayed" style={{ fontSize: "16px" }}>
                {" "}
                {titleHint}
              </span>
            )}
          </h4>
        )}
        {children}
      </div>
      {certificateImage && certificateName && (
        <div style={{ display: "flex" }}>
          <img
            src={certificateImage}
            alt="Certificate Image"
            style={{
              width: "30px",
              height: "30px",
              borderRadius: "50%",
              marginTop: "5px",
              marginRight: "5px"
            }}
          />
          <p style={{ fontSize: 13, marginTop: 10 }}>{certificateName}</p>
        </div>
      )}
    </div>
  );
};

export default DefaultContent;
