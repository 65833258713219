import React from "react";
import toast from "../../components/base/Toast";

// Page components
import PlaybookFormFields from "./PlaybookFormFields";

// Components
import PageTitle from "../../components/base/PageTitle";
import Form from "../../components/FormWithErrorHandling";
import CancelButton from "../../components/base/CancelButton";

// Configs
import { endpoints } from "../../configs";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { resetPlaybookList } from "../../actions/playbook";

// API call
import { apiClient } from "../../apiClient";

// Constants
import {
  PLAYBOOK_STATUS_ACTIVE,
  PLAYBOOK_STATUS_DRAFT
} from "../../playbookStatus/Constants";
import { EXPERT_STATUS_APPROVED } from "../../expertStatus/Constants";
import {
  PLAYBOOK_PRICING_TYPE_FIXED_PRICE,
  TAB_BASICS,
  TAB_DELIVERABLES,
  TAB_DETAILS,
  TAB_EXPERTS,
  TAB_PACKAGES,
  TAB_PRICING_AND_EXTRAS
} from "../../playbook/Constants";
import {
  TAG_TYPE_EXPERT_ROLE,
  TAG_TYPE_SKILLS,
  TAG_TYPE_TOOLS
} from "../../tagType/Constants";
import MyContext from "../../context/MyContext";
import { isBadRequest } from "../../common/http";

class AddPlaybookForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerColor: "#75B0CE",
      bannerIcon: "EnvelopeIcon",
      files: [],
      fileCount: "",
      bannerImageUrl: "",
      backButton: "Cancel",
      publishPlaybook: "Next",
      publishStatus: PLAYBOOK_STATUS_DRAFT,
      buttonType: "button",
      currentTab: TAB_BASICS,
      nextTab: "",
      playbookIconValidate: true,
      playbookColorValidate: true,
      expertRoles: [],
      skills: [],
      tools: [],
      isExpertLoading: false,
      isOpen: false,
      expertList: [],
      expertIds: [],
      supportingMaterial: "",
      supportingMaterials: [],
      deliverablesValue: "",
      defaultDeliverablesValue: "",
      extrasValue: "",
      isSubmit: false,
      isErrors: false,
      isSubmitting: false
    };
    this.onColourPickerClick = this.onColourPickerClick.bind(this);
    this.onIconClick = this.onIconClick.bind(this);
    this._handleBannerImagePath = this._handleBannerImagePath.bind(this);
    this.bannerImageUpload = this.bannerImageUpload.bind(this);
    this.handleExperts = this.handleExperts.bind(this);
    this.backButton = this.backButton.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.cancelButton = this.cancelButton.bind(this);
  }

  componentDidMount() {
    this._getTagsList();
    this._getExpertsList();
  }

  setSubmitState = () => {
    this.setState({ isSubmit: true });
  };

  reSetSubmitState = () => {
    this.setState({ isSubmit: false }, () => {
      this.setState({ isErrors: false });
    });
  };

  setIsErrors = () => {
    this.setState({ isErrors: true });
  };

  // Create playbook
  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }
    // Save playbook form in the final
    this._createPlaybook(this._toArray(values));
  };

  // Get deliverable values
  getDeliverablesValue = values => {
    this.setState({ deliverablesValue: values });
  };

  // Get default deliverable values
  getDefaultDeliverablesValue = values => {
    this.setState({ defaultDeliverablesValue: values });
  };

  // Get extras values
  getExtrasValue = values => {
    this.setState({ extrasValue: values });
  };

  // Color picker
  onColourPickerClick(e) {
    this.setState({ bannerColor: e.target.id, playbookColorValidate: true });
  }

  // Icon click
  onIconClick(e) {
    this.setState({
      bannerIcon: e.target.className,
      playbookIconValidate: true
    });
  }

  // Back Button
  backButton(e) {
    let tab = e;
    this.setState({ currentTab: tab });
    if (tab === TAB_BASICS) {
      this.setState({ backButton: "Cancel" });
    } else {
      this.setState({ backButton: "Back" });
    }
    if (tab === TAB_EXPERTS) {
      this.setState({ publishPlaybook: "Publish Playbook" });
    } else {
      this.setState({ publishPlaybook: "Next" });
    }
  }

  // Cancel Button
  cancelButton() {
    if (this.state.backButton === "Cancel") {
      this.props.history.goBack();
    } else {
      const currentTab = this.state.currentTab;
      this.state.isErrors && this.setSubmitState();

      switch (currentTab) {
        case TAB_DETAILS:
          this.backButton(TAB_BASICS);
          break;
        case TAB_PRICING_AND_EXTRAS:
          this.backButton(TAB_DETAILS);
          break;
        case TAB_DELIVERABLES:
          this.backButton(TAB_PRICING_AND_EXTRAS);
          break;
        case TAB_PACKAGES:
          this.backButton(TAB_DELIVERABLES);
          break;
        case TAB_EXPERTS:
          this.backButton(TAB_PACKAGES);
          break;
      }
    }
  }

  // Set next playbook tab
  setNextTab = tab => {
    if (this.state.currentTab !== TAB_EXPERTS) {
      return this.setState({
        currentTab: tab,
        buttonType: "button"
      });
    }

    return this.setState({
      currentTab: tab,
      publishStatus: PLAYBOOK_STATUS_ACTIVE,
      buttonType: "submit",
      publishPlaybook: "Publish Playbook"
    });
  };

  // Next Button
  nextButton() {
    const currentTab = this.state.currentTab;
    if (this.state.isErrors) {
      return this.setSubmitState();
    }

    switch (currentTab) {
      case TAB_BASICS:
        this.setNextTab(TAB_DETAILS);
        this.backButton(TAB_DETAILS);
        break;
      case TAB_DETAILS:
        this.setNextTab(TAB_PRICING_AND_EXTRAS);
        this.backButton(TAB_PRICING_AND_EXTRAS);
        break;
      case TAB_PRICING_AND_EXTRAS:
        this.setNextTab(TAB_DELIVERABLES);
        this.backButton(TAB_DELIVERABLES);
        break;
      case TAB_DELIVERABLES:
        this.setNextTab(TAB_PACKAGES);
        this.backButton(TAB_PACKAGES);
        break;
      case TAB_PACKAGES:
        this.setNextTab(TAB_EXPERTS);
        this.backButton(TAB_EXPERTS);
        break;
      case TAB_EXPERTS:
        this.setNextTab(TAB_EXPERTS);
        break;
    }
  }

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Banner Image Upload
  bannerImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    this.setState({ bannerImageUrl: URL.createObjectURL(files) }, () =>
      this.setBase64Image(files, "files")
    );
  };

  // Banner Image Remove
  handleImageRemove = () => {
    this.setState({ bannerImageUrl: "", files: "" });
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const name = values.name;

    if (!name) {
      success = false;
    }

    return success;
  }

  // Get Expert List
  _getExpertsList() {
    this.setState({ isExpertLoading: true }, () => {
      apiClient
        .get(
          `${
            endpoints().expertAPI
          }/search?marketplaceStatus=${EXPERT_STATUS_APPROVED}`
        )
        .then(response => {
          const expertList = response.data.data;

          this.setState({
            isExpertLoading: false,
            expertList: expertList ? expertList : this.state.expertList
          });
        });
    });
  }

  // To Array
  _toArray(values) {
    const {
      deliverablesValue,
      defaultDeliverablesValue,
      extrasValue
    } = this.state;
    const data = new FormData();
    data.append("name", values.name ? values.name : "");
    data.append("publishStatus", this.state.publishStatus);
    data.append("category", values.category ? values.category : "");
    data.append("description", values.description ? values.description : "");
    data.append(
      "bannerImagePath",
      this.state.bannerImageUrl ? this.state.bannerImageUrl : ""
    );
    data.append(
      "bannerColor",
      this.state.bannerColor ? this.state.bannerColor : ""
    );
    data.append(
      "bannerIcon",
      this.state.bannerIcon ? this.state.bannerIcon : "EnvelopeIcon"
    );
    data.append("price", values.price ? values.price : "");
    data.append("requirements", values.requirements ? values.requirements : "");
    data.append("outOfScope", values.outOfScope ? values.outOfScope : "");
    data.append(
      "changeManagement",
      values.changeManagement ? values.changeManagement : ""
    );
    data.append("deliverables", values.deliverables ? values.deliverables : "");
    data.append(
      "expertRoles",
      values.expertRoles ? JSON.stringify([values.expertRoles]) : []
    );
    data.append(
      "required_skills",
      values.required_skills ? JSON.stringify(values.required_skills) : []
    );
    data.append(
      "required_tools",
      values.required_tools ? JSON.stringify(values.required_tools) : []
    );
    data.append("methodology", values.methodology ? values.methodology : "");
    data.append(
      "estimatedLength",
      values.estimatedLength ? values.estimatedLength : ""
    );
    data.append("supportingMaterial", this.state.supportingMaterial);
    data.append("supportingMaterialName", this.state.supportingMaterialName);
    data.append("file", this.state.files);
    data.append(
      "pricingType",
      values.pricingType ? values.pricingType.value : ""
    );
    data.append(
      "pricingSchedule",
      values.pricingSchedule ? values.pricingSchedule.value : ""
    );

    // Add playbook deliverables
    const playbookDeliverables = [];
    deliverablesValue &&
      deliverablesValue.forEach(data => {
        if (data.description) {
          playbookDeliverables.push({
            description: data.description
          });
        }
      });
    data.append("defaultDeliverables", JSON.stringify(playbookDeliverables));

    // Add playbook default deliverables
    const playbookDefaultDeliverables = [];
    defaultDeliverablesValue &&
      defaultDeliverablesValue.forEach(data => {
        if (data.description) {
          playbookDefaultDeliverables.push({
            description: data.description
          });
        }
      });
    data.append(
      "defaultTaskDeliverables",
      JSON.stringify(playbookDefaultDeliverables)
    );

    const packages = [];
    if (values["Standard-package-1"]) {
      packages.push({
        price: values["Standard-package-1"]
          ? values["Standard-package-1"]
          : null,
        package_type: "Standard",
        turnaround_time: values["Standard_playbook_turnaround_time"]
          ? values["Standard_playbook_turnaround_time"]
          : null,
        included_deliverables: values["extra-Standard-1"]
      });
    }
    if (values["Basic-package-2"]) {
      packages.push({
        price: values["Basic-package-2"] ? values["Basic-package-2"] : null,
        package_type: "Basic",
        turnaround_time: values["Basic_playbook_turnaround_time"]
          ? values["Basic_playbook_turnaround_time"]
          : null,
        included_deliverables: values["extra-Basic-1"]
      });
    }
    if (values["Premium-package-3"]) {
      packages.push({
        price: values["Premium-package-3"] ? values["Premium-package-3"] : null,
        package_type: "Premium",
        turnaround_time: values["Premium_playbook_turnaround_time"]
          ? values["Premium_playbook_turnaround_time"]
          : null,
        included_deliverables: values["extra-Premium-1"]
      });
    }
    data.append("packages", JSON.stringify(packages));
    // Playbook extras
    let playbookExtras = [];
    extrasValue &&
      extrasValue.forEach(data => {
        if (data.option && data.price) {
          playbookExtras.push({
            extra_type: data.option,
            price: data.price
          });
        }
      });
    data.append("extras", JSON.stringify(playbookExtras));
    const playbookPackageDeliverables = [];
    for (let i = 1; i < 100; i++) {
      if (values[`extra-Standard-${i}`]) {
        playbookPackageDeliverables.push({
          package_type: "Standard",
          description: values[`extra-Standard-${i}`]
        });
      }
      if (values[`extra-Basic-${i}`]) {
        playbookPackageDeliverables.push({
          package_type: "Basic",
          description: values[`extra-Basic-${i}`]
        });
      }
      if (values[`extra-Premium-${i}`]) {
        playbookPackageDeliverables.push({
          package_type: "Premium",
          description: values[`extra-Premium-${i}`]
        });
      }
    }
    data.append("deliverables", JSON.stringify(playbookPackageDeliverables));
    data.append("expertIds", this.state.expertIds);
    return data;
  }

  // Playbook API Call
  _createPlaybook(data) {
    this.setState({ isSubmitting: true });
    const { actions } = this.props;
    return apiClient
      .post(endpoints().playbookAPI, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        setTimeout(() => {
          toast.success(successMessage);

          const url =
            this.state.publishStatus === PLAYBOOK_STATUS_DRAFT
              ? "/marketplace-sourcing?section=Draft"
              : "/marketplace-sourcing";
          this.props.history.push(url);
        }, 2000);
        actions.resetPlaybookList();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isSubmitting: false });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  //get tags list
  _getTagsList() {
    this.setState({ isLoading: true }, () => {
      apiClient.get(`${endpoints().playbookTagAPI}`).then(response => {
        const tags = response.data.data;
        const expertRoles = [];
        const skills = [];
        const tools = [];
        if (tags && tags.length > 0) {
          tags.forEach(tag => {
            if (tag.type === TAG_TYPE_EXPERT_ROLE) {
              expertRoles.push({
                value: tag.id,
                label: tag.name
              });
            } else if (tag.type === TAG_TYPE_SKILLS) {
              skills.push({
                value: tag.id,
                label: tag.name
              });
            } else if (tag.type === TAG_TYPE_TOOLS) {
              tools.push({
                value: tag.id,
                label: tag.name
              });
            }
          });
        }

        this.setState({
          isLoading: false,
          expertRoles,
          skills,
          tools
        });
      });
    });
  }

  // Handle OnChange playbook banner image path
  _handleBannerImagePath = e => {
    const target = e.target;
    const bannerImagePath = target.value;
    this.setState({ bannerImagePath });
  };

  // Handle experts
  handleExperts(value) {
    const expertIds = this.state.expertIds;
    if (expertIds.indexOf(value) > -1) {
      this.setState({
        expertIds: expertIds.filter(el => el != value)
      });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ expertIds: expertIds.concat(value) });
    }
  }

  // Handle supporting material
  _handleSupportingMaterial = files => {
    this.setState({ supportingMaterialName: files[0].name });
    this.setBase64Image(files[0], "supportingMaterial");
  };

  render() {
    const { expertRoles, skills, tools } = this.state;

    const headerButton = (
      <div className="btn-wrapper mt-2 mb-4">
        <button
          className="btn btn-outline-dark pr-2 mr-4"
          onClick={() => {
            this.state.isErrors && this.setSubmitState();
          }}
        >
          Save as Draft
        </button>
        <button
          type={this.state.buttonType}
          className={`btn btn-secondary pr-2 ${
            this.state.isSubmitting ? "disabled" : ""
          }`}
          onClick={this.nextButton}
        >
          {this.state.publishPlaybook}
        </button>
      </div>
    );

    const {
      playbookIconValidate,
      playbookColorValidate,
      expertList,
      isOpen,
      expertIds,
      currentTab,
      supportingMaterials,
      isSubmit,
      isErrors
    } = this.state;

    const pricingType = {
      value: PLAYBOOK_PRICING_TYPE_FIXED_PRICE,
      label: PLAYBOOK_PRICING_TYPE_FIXED_PRICE
    };

    const initialValues = {
      name: "",
      bannerImageUrl: "",
      fileCount: "",
      pricingType
    };
    return (
      <>
        <MyContext.Consumer>
          {context => (
            <>
              {context.isMobile && <PageTitle label="New Playbook" />}
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  this._submit(values);
                }}
                showErrorToast={true}
                isSubmit={isSubmit}
                reSetSubmitState={this.reSetSubmitState}
                setIsErrorState={this.setIsErrors}
                isErrorsState={isErrors}
              >
                <div
                  className={`page-heading d-flex ${
                    context.isMobile
                      ? "justify-content-end"
                      : "justify-content-between"
                  }`}
                >
                  {!context.isMobile && <PageTitle label="New Playbook" />}
                  {headerButton && headerButton}
                </div>
                <PlaybookFormFields
                  colorValidate={playbookColorValidate}
                  iconValidate={playbookIconValidate}
                  onColourPickerClick={this.onColourPickerClick}
                  onIconClick={this.onIconClick}
                  bannerColor={this.state.bannerColor}
                  bannerIcon={this.state.bannerIcon}
                  handleBannerImagePath={this._handleBannerImagePath}
                  projectCategory={this.state.projectCategory}
                  bannerImageUpload={e => this.bannerImageUpload(e)}
                  bannerImageUrl={this.state.bannerImageUrl}
                  handleImageRemove={this.handleImageRemove}
                  backButton={e => this.backButton(e)}
                  currentTab={currentTab}
                  expertRoles={expertRoles}
                  handleExperts={this.handleExperts}
                  handleSupportingMaterial={this._handleSupportingMaterial}
                  supportingMaterials={supportingMaterials}
                  isOpen={isOpen}
                  expertList={expertList}
                  expertIds={expertIds}
                  selectedIds={expertIds}
                  skills={skills}
                  tools={tools}
                  getDeliverablesValue={this.getDeliverablesValue}
                  getDefaultDeliverablesValue={this.getDefaultDeliverablesValue}
                  getExtrasValue={this.getExtrasValue}
                />
                {currentTab !== TAB_BASICS ? (
                  <CancelButton
                    name={this.state.backButton}
                    onClick={this.cancelButton}
                  />
                ) : (
                  ""
                )}

                <div className="page-heading d-flex justify-content-between float-right">
                  {headerButton && headerButton}
                </div>
              </Form>
            </>
          )}
        </MyContext.Consumer>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    playbooks: state.playbooks
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        resetPlaybookList
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPlaybookForm);
