import React, { Component, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { UserIcon } from "../../../../../assets/img/icons";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";

// Components
import AvatarCard from "../../../../../components/base/AvatarCard";
import toast from "../../../../../components/base/Toast";
import ReduxTable, {
  ReduxColumn
} from "../../../../../components/base/ReduxTable";
import Form from "../../../../../components/base/Form";
import UserRole from "../../../../../components/UserRole";
import Avatar from "../../../../../components/base/Avatar";
import CancelButton from "../../../../../components/base/CancelButton";
import Spinner from "../../../../../components/base/Spinner";
import SelectDropdown from "../../../../../components/base/SelectDropdown";
import MoreDropdown from "../../../../../components/base/MoreDropdown";
import Page404 from "../../../../Page404";

// API call
import { apiClient } from "../../../../../apiClient";

// Configs
import { endpoints } from "../../../../../configs";

// Actions
import { fetchList } from "../../../../../actions/table";

// Helper
import { getFullName } from "../../../../../lib/helper";
import {
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  getParamsByName
} from "../../../../../lib/helper";

// Constants
import {
  CUSTOMER_ROLE,
  CUSTOMER_ROLE_TEXT,
  EXPERT_ROLE,
  EXPERT_ROLE_TEXT,
  SUPER_ADMIN_ROLE,
  ROLE_SUPER_ADMIN_TEXT,
  COMPANY_ADMIN_ROLE,
  ROLE_COMPANY_ADMIN_TEXT,
  PARTNER_ROLE,
  PARTNER_ROLE_TEXT,
  COMPANY_MANAGER_ROLE,
  USER_INVITED_TEXT,
  USER_ACCEPTED_TEXT,
  USER_STATUS_ACCEPTED,
  USER_STATUS_INVITED,
  COMPANY_MANAGER_ROLE_TEXT,
  USERS_ROLE_TEXT,
  CUSTOMER_ADMIN_ROLE,
  CUSTOMER_ADMIN_TEXT,
  CUSTOMER_MEMBER_TEXT,
  CUSTOMER_MEMBER_ROLE,
  PARTNER_ADMIN_ROLE,
  PARTNER_ADMIN_TEXT,
  PARTNER_MEMBER_TEXT,
  PARTNER_MEMBER_ROLE
} from "../../../../../roles/Constants";

//Add User Popup
import AddUserModal from "../../../../users/AddUserModal";

// Common
import { isBadRequest } from "../../../../../common/http";

const statusStyle = {
  borderRadius: "10px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "75px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px",
  backgroundColor: "#0AD4BB"
};

class users extends Component {
  state = {
    userRemoveModal: false,
    UserChangeRoleModal: false
  };
  constructor(props) {
    super(props);
    this.state = {
      activeTab: ROLE_SUPER_ADMIN_TEXT,
      roleId: 1,
      sortByOptions: [
        {
          value: "first_name:ASC",
          label: "Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ],
      selectedSortOption: "Most Recent",
      selectedSortRoles: USERS_ROLE_TEXT,
      searchParam: "",
      currentSuperAdminSort: "createdAt",
      currentSuperAdminSortDir: "DESC",
      roleNames: "",
      isLoading: false,
      role: ""
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();

    const params = new URLSearchParams(this.props.history.location.search);
    const role = params.get("type");

    this.setState({
      searchParam: role,
      activeTab: role || ROLE_SUPER_ADMIN_TEXT
    });
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // Check is logged in user
    isLoggedIn();
    if (
      this.props.superAdminSort &&
      this.props.superAdminSort !== this.state.currentSuperAdminSort
    ) {
      this.setState({
        currentSuperAdminSort: this.props.superAdminSort
      });
    }
    if (
      this.props.superAdminSortDir &&
      this.props.superAdminSortDir !== this.state.currentSuperAdminSortDir
    ) {
      this.setState({
        currentSuperAdminSortDir: this.props.superAdminSortDir
      });
    }

    const { search } = prevProps.history.location;
    const params = new URLSearchParams(search);
    const role = params.get("type");
    // Set current active tab
    if (this.state.searchParam !== role) {
      this.setState({
        searchParam: role,
        activeTab: role || ROLE_SUPER_ADMIN_TEXT
      });
    }
  }
  close = () => {
    this.setState({ UserRemoveModal: false });
    this.setState({ UserChangeRoleModal: false });
  };

  toggleUserRemoveModal = userData => {
    this.setState({
      UserRemoveModal: !this.state.UserRemoveModal,
      id: userData.id,
      firstName: userData.firstName,
      lastName: userData.lastName,
      avatarUrl: userData.avatarUrl,
      email: userData.email
    });
  };

  toggleUserChangeRoleModal = userData => {
    this.setState({
      UserChangeRoleModal: !this.state.UserChangeRoleModal,
      id: userData.id,
      firstName: userData.firstName,
      lastName: userData.lastName,
      avatarUrl: userData.avatarUrl,
      email: userData.email,
      role: userData.roleName
    });
  };
  toggle = tab => {
    this.setState({
      activeTab: tab,
      selectedSortRoles: ""
    });

    this.props.history.push(`/users?type=${tab}`);
  };

  sortByRoles = [
    {
      label: ROLE_SUPER_ADMIN_TEXT,
      value: SUPER_ADMIN_ROLE
    },
    {
      label: ROLE_COMPANY_ADMIN_TEXT,
      value: COMPANY_ADMIN_ROLE
    },
    {
      label: COMPANY_MANAGER_ROLE_TEXT,
      value: COMPANY_MANAGER_ROLE
    },
    {
      label: CUSTOMER_ROLE_TEXT,
      value: CUSTOMER_ROLE
    },
    {
      label: CUSTOMER_ADMIN_TEXT,
      value: CUSTOMER_ADMIN_ROLE
    },
    {
      label: CUSTOMER_MEMBER_TEXT,
      value: CUSTOMER_MEMBER_ROLE
    },
    {
      label: PARTNER_ROLE_TEXT,
      value: PARTNER_ROLE
    },
    {
      label: PARTNER_ADMIN_TEXT,
      value: PARTNER_ADMIN_ROLE
    },
    {
      label: PARTNER_MEMBER_TEXT,
      value: PARTNER_MEMBER_ROLE
    },
    {
      label: EXPERT_ROLE_TEXT,
      value: EXPERT_ROLE
    }
  ];

  handleSortByRoles = selectedRole => {
    const selectedRoleObj = this.sortByRoles.find(
      role => role.value === parseInt(selectedRole)
    );

    if (selectedRoleObj) {
      const roleId = selectedRoleObj.value;

      this.setState({ selectedSortRoles: roleId }, () => {
        this.setState({
          isLoading: true
        });

        const params = new URLSearchParams(this.props.history.location.search);
        params.set("role", roleId);
        const newUrl = `${window.location.pathname}?${params.toString()}`;
        this.props.history.push(newUrl);

        this.setState({ role: roleId });

        this.props.actions.fetchList(
          "userList",
          `${endpoints().userAPI}/search`,
          1,
          10,
          {
            sort: this.state.currentSuperAdminSort || selectedRole,
            sortDir: this.state.currentSuperAdminSortDir,
            roleIds: roleId,
            pagination: true,
            search: getParamsByName("search").trim(),
            type: selectedRole
          }
        );

        this.setState({
          selectedSortRoles: "",
          isLoading: false
        });
      });
    }
  };

  // handle resend invite
  resendInvite = async member => {
    try {
      const response = await apiClient.post(
        `${endpoints().userAPI}/resendInvite`,
        member
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message, {
          containerId: "Team-Member-toast"
        });
        console.error(errorMessage);
      }
    }
  };

  // handle resend invite
  resetPassword = async member => {
    try {
      const response = await apiClient.post(
        `${endpoints().userAPI}/resetPassword`,
        member
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;

        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message, {
          containerId: "Team-Member-toast"
        });
        console.error(errorMessage);
      }
    }
  };

  // Remove user
  handleRemove = (id, page) => {
    apiClient
      .delete(`${endpoints().userAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          const sort = this.state.currentSuperAdminSort;
          // Get current tab name
          const currentTab = this.props.id;
          const searchItem = getParamsByName("search");
          const searchTerm = searchItem ? searchItem.trim() : "";

          const sortDir = getParamsByName("sortDir");
          this.props.actions.fetchList(
            ROLE_SUPER_ADMIN_TEXT,
            `${endpoints().userAPI}/search`,
            1,
            10,
            {
              sort: sort || this.state.currentSuperAdminSort,
              search: encodeURIComponent(searchTerm),
              sortDir: sortDir || this.state.currentSuperAdminSortDir,
              roleIds:
                currentTab === ROLE_SUPER_ADMIN_TEXT
                  ? `${SUPER_ADMIN_ROLE}`
                  : currentTab === USERS_ROLE_TEXT
                  ? `${COMPANY_ADMIN_ROLE},${COMPANY_MANAGER_ROLE},${PARTNER_ROLE},${CUSTOMER_ROLE}`
                  : `${COMPANY_ADMIN_ROLE},${COMPANY_MANAGER_ROLE}`,
              pagination: true
            }
          );
          this.props.actions.fetchList(
            PARTNER_ROLE_TEXT,
            `${endpoints().userAPI}/search`,
            1,
            10,
            {
              sort: sort,
              sortDir: sortDir,
              roleIds: PARTNER_ROLE,
              pagination: true
            }
          );
          this.props.actions.fetchList(
            CUSTOMER_ROLE_TEXT,
            `${endpoints().userAPI}/search`,
            1,
            10,
            {
              sort: sort,
              sortDir: sortDir,
              roleIds: CUSTOMER_ROLE,
              pagination: true
            }
          );
          this.props.actions.fetchList(
            EXPERT_ROLE_TEXT,
            `${endpoints().userAPI}/search`,
            1,
            10,
            {
              sort: sort,
              sortDir: sortDir,
              roleIds: EXPERT_ROLE,
              pagination: true
            }
          );
        }
      })

      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  // Handle User Role
  handleUserRole = async (data, id, emailId) => {
    const sort = getParamsByName("sort");
    const sortDir = getParamsByName("sortDir");
    const searchTerm = getParamsByName("search").trim();
    const type = getParamsByName("type");

    const role =
      data === ROLE_SUPER_ADMIN_TEXT
        ? SUPER_ADMIN_ROLE
        : data === ROLE_COMPANY_ADMIN_TEXT
        ? COMPANY_ADMIN_ROLE
        : data === PARTNER_ROLE_TEXT
        ? PARTNER_ROLE
        : data === EXPERT_ROLE_TEXT
        ? EXPERT_ROLE
        : data === CUSTOMER_ROLE_TEXT
        ? CUSTOMER_ROLE
        : data === COMPANY_MANAGER_ROLE_TEXT
        ? COMPANY_MANAGER_ROLE
        : null;

    try {
      const response = await apiClient.put(`${endpoints().userAPI}/${id}`, {
        roleId: role,
        email: emailId
      });
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
        this.props.actions.fetchList(
          ROLE_SUPER_ADMIN_TEXT,
          `${endpoints().userAPI}/search`,
          1,
          10,
          {
            sort: sort || this.state.currentSuperAdminSort,
            sortDir: sortDir || this.state.currentSuperAdminSortDir,
            roleIds: `${COMPANY_ADMIN_ROLE},${COMPANY_MANAGER_ROLE}`,
            pagination: true,
            search: type === ROLE_SUPER_ADMIN_TEXT ? searchTerm : "",
            type: ROLE_SUPER_ADMIN_TEXT
          }
        );
        this.props.actions.fetchList(
          PARTNER_ROLE_TEXT,
          `${endpoints().userAPI}/search`,
          1,
          10,
          {
            sort: sort,
            sortDir: sortDir,
            roleIds: PARTNER_ROLE,
            pagination: true
          }
        );
        this.props.actions.fetchList(
          CUSTOMER_ROLE_TEXT,
          `${endpoints().userAPI}/search`,
          1,
          10,
          {
            sort: sort,
            sortDir: sortDir,
            roleIds: CUSTOMER_ROLE,
            pagination: true
          }
        );
        this.props.actions.fetchList(
          EXPERT_ROLE_TEXT,
          `${endpoints().userAPI}/search`,
          1,
          10,
          {
            sort: sort,
            sortDir: sortDir,
            roleIds: EXPERT_ROLE,
            pagination: true
          }
        );
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
      }
    }
  };

  renderUserTableByRoleId(roleIds, roleName, invitedUsers) {
    const currentUrl = window.location.pathname + window.location.search;
    const search = window.location.search;
    let role = getParamsByName("type") || ROLE_SUPER_ADMIN_TEXT;
    const apiUrl =
      invitedUsers && invitedUsers == "true"
        ? "/v1/user/associated/users"
        : "/v1/user/search";
    const pathParams = new URLSearchParams(search);

    const searchItem = pathParams.get("search");
    return (
      <div className="mb-5 text-break">
        <ReduxTable
          id="userList"
          showHeader
          searchPlaceholder="Search"
          newTableHeading
          icon={<UserIcon />}
          message="Get started by selecting New User."
          apiURL={apiUrl}
          params={{
            roleIds: this.state.role ? this.state.role : roleIds,
            type: role,
            search: searchItem
          }}
          sortByOptions={this.state.sortByOptions}
          paramsToUrl={true}
          history={this.props.history}
        >
          <ReduxColumn
            width={"105px"}
            field="firstName"
            type="link"
            isClickable="true"
            sortBy="first_name"
            renderField={row => (
              <Link to={`/user/edit/${row.id}?redirect=/users${currentUrl}`}>
                <AvatarCard
                  firstName={row.firstName}
                  lastName={row.lastName}
                  url={row.avatarUrl}
                />
              </Link>
            )}
          >
            Name
          </ReduxColumn>
          <ReduxColumn width={"105px"} field="email" sortBy="email">
            Email
          </ReduxColumn>
          <ReduxColumn width={"105px"} field="roleName">
            Role
          </ReduxColumn>
          <ReduxColumn
            width={"105px"}
            field="status"
            renderField={row => (
              <div className="actions">
                <p
                  className={`text-center ${
                    row.status === USER_STATUS_INVITED
                      ? "bg-secondary"
                      : row.status === USER_STATUS_ACCEPTED
                      ? row.status === null
                      : "bg-white"
                  }`}
                  style={statusStyle}
                  disableOnClick
                >
                  {row.status === USER_STATUS_INVITED
                    ? USER_INVITED_TEXT
                    : row.status === USER_STATUS_ACCEPTED
                    ? USER_ACCEPTED_TEXT
                    : row.status === null
                    ? null
                    : USER_INVITED_TEXT}
                </p>
              </div>
            )}
          >
            Invite Status
          </ReduxColumn>
          <ReduxColumn
            Width="105px"
            field="Action"
            disableOnClick
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  {row.status === 0 && (
                    <DropdownItem
                      class=""
                      onClick={() => {
                        {
                          this.resendInvite({
                            id: row.id,
                            email: row.email,
                            status: row.status
                          });
                        }
                      }}
                    >
                      Resend Invite
                    </DropdownItem>
                  )}
                  {this.state.activeTab === ROLE_SUPER_ADMIN_TEXT &&
                    !isCompanyAdmin(this.props.roleId) &&
                    !isCompanyManager(this.props.roleId) && (
                      <DropdownItem
                        onClick={() => {
                          this.setState({
                            id: row.id,
                            email: row.email,
                            status: row.status,
                            roleNames: row.roleName
                          });
                          return this.toggleUserChangeRoleModal(row);
                        }}
                      >
                        Change Role
                      </DropdownItem>
                    )}

                  <DropdownItem
                    class=""
                    onClick={() => {
                      {
                        this.resetPassword({
                          id: row.id,
                          email: row.email,
                          status: row.status
                        });
                      }
                    }}
                  >
                    Reset Password
                  </DropdownItem>
                  <DropdownItem
                    onClick={() => {
                      this.setState({
                        id: row.id,
                        email: row.email,
                        status: row.status
                      });
                      return this.toggleUserRemoveModal(row);
                    }}
                  >
                    Remove
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    );
  }

  render() {
    const {
      activeTab,
      currentSuperAdminSort,
      currentSuperAdminSortDir,
      selectedSortRoles,
      isLoading
    } = this.state;
    const currentParams = {
      currentSuperAdminSort,
      currentSuperAdminSortDir
    };

    const headerButton = (
      <AddUserModal
        supportPortal={this.props.supportPortal}
        currentParams={currentParams}
        activeTab={this.state.activeTab}
        id={this.props.id}
      />
    );

    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId) &&
      !isCompanyManager(this.props.roleId)
    ) {
      return <Page404 />;
    }
    let tab = this.props && this.props.match && this.props.match.params.tab;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <>
        {/* /.page-heading */}
        <div className="d-flex justify-content-end">
          {headerButton}
          <div className="pl-2 mt-2 pt-1">
            <SelectDropdown
              buttonLabel={selectedSortRoles}
              dropdownLinks={this.sortByRoles}
              color={"gray"}
              hideCaret
              getValue
              selectName={"sortby_role"}
              handleChange={this.handleSortByRoles}
            />
          </div>
        </div>
        <UserChangeRoleModal
          isOpen={this.state.UserChangeRoleModal}
          toggle={this.toggleUserChangeRoleModal}
          id={this.state.id}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          avatarUrl={this.state.avatarUrl}
          email={this.state.email}
          role={this.state.role}
          close={this.close}
          handleUserRole={this.handleUserRole}
          roleNames={this.state.roleNames}
        />
        <UserRemoveModal
          isOpen={this.state.UserRemoveModal}
          toggle={this.toggleUserRemoveModal}
          id={this.state.id}
          firstName={this.state.firstName}
          lastName={this.state.lastName}
          avatarUrl={this.state.avatarUrl}
          email={this.state.email}
          handleRemove={this.handleRemove}
          close={this.close}
          page={this.props.partnerCurrentPage}
        />

        {/* User List */}
        <div>
          {this.renderUserTableByRoleId(
            `${SUPER_ADMIN_ROLE},${CUSTOMER_ROLE},${PARTNER_ROLE},${COMPANY_MANAGER_ROLE},${COMPANY_ADMIN_ROLE},${EXPERT_ROLE}`,
            ROLE_SUPER_ADMIN_TEXT
          )}
        </div>
      </>
    );
  }
}

const UserRemoveModal = ({
  isOpen,
  toggle,
  id,
  firstName,
  lastName,
  avatarUrl,
  email,
  handleRemove,
  close,
  page
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={["edit-task-modal"].join(" ")}
      backdrop="static"
    >
      <ModalHeader toggle={toggle}>
        <h4 className={["font-weight-bold"].join(" ")}>Remove User</h4>
      </ModalHeader>
      <ModalBody className={["mb-4"].join(" ")}>
        <div className=" d-flex">
          <Avatar
            firstName={firstName}
            lastName={lastName}
            size="customSize"
            imageSize={"48"}
            url={avatarUrl}
          />
          <p className="h6-5 font-weight-bold ml-3">
            <Link
              className="text-dark text-decoration-none"
              to={`/user/edit/${id}?redirect=/users?type=Partner`}
              style={{ cursor: "pointer" }}
            >
              {getFullName(firstName, lastName)}
            </Link>
            <div>
              <span className="font-weight-normal text-inline-grayed">
                ({email})
              </span>
            </div>
          </p>
        </div>
      </ModalBody>
      <ModalFooter className={["justify-content-center"].join(" ")}>
        <p className="font-weight-bold">Are you sure you want to remove ?</p>

        <div className="btn-wrapper mt-4 d-flex justify-content-center">
          <CancelButton
            onClick={() => {
              close();
            }}
            className="mr-2"
          />
          <button
            className="btn btn-secondary h6-5-important m-1"
            onClick={() => {
              handleRemove(id, page);
              setTimeout(() => {
                close();
              }, 100);
            }}
          >
            <span>Yes, Remove</span>
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

const UserChangeRoleModal = ({
  isOpen,
  toggle,
  id,
  firstName,
  lastName,
  avatarUrl,
  email,
  role,
  close,
  handleUserRole,
  roleNames
}) => {
  const [roleName, setRole] = useState();
  const handleChange = data => {
    const selectedRole = data.values.userRole.label;
    setRole(selectedRole);
  };
  return (
    <Modal isOpen={isOpen} className={["edit-task-modal"].join(" ")}>
      <ModalHeader toggle={toggle}>
        <h4 className={["font-weight-bold"].join(" ")}>Change User Role</h4>
      </ModalHeader>
      <Form
        onSubmit={() => {
          if (roleName) {
            handleUserRole(roleName, id, email);
            setTimeout(() => {
              close();
            }, 100);
          }
        }}
      >
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="row">
            <Avatar
              firstName={firstName}
              lastName={lastName}
              size="customSize"
              imageSize={"48"}
              url={avatarUrl}
            />
            <p className="h6-5 font-weight-bold ml-3">
              <Link
                className="text-dark text-decoration-none"
                to={`/user/edit/${id}?redirect=/users?type=Partner`}
                style={{ cursor: "pointer" }}
              >
                {getFullName(firstName, lastName)}
              </Link>

              <div>
                <span className="font-weight-normal text-inline-grayed">
                  ({email})
                </span>
              </div>
            </p>
          </div>
          <div className="row">
            <p className="font-weight-bold ml-5 pl-3">User Role</p>
          </div>
          <div className="row">
            <div className="col-12">
              <div className="w-50 ml-5">
                <UserRole
                  name="userRole"
                  onChange={handleChange}
                  placeholder={role}
                  roleNames={roleNames}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper text-center">
            <Button type="submit" label="">
              Change
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => {
  const { Customer, Expert, Partner } = state.table;

  const { user } = state;

  const superAdminCount =
    state.table["Super Admin"] && !state.table["Super Admin"].isFetching
      ? state.table["Super Admin"].totalCount
      : 0;
  const superAdminSort =
    state.table["Super Admin"] && !state.table["Super Admin"].isFetching
      ? state.table["Super Admin"].sort
      : "";
  const superAdminSortDir =
    state.table["Super Admin"] && !state.table["Super Admin"].isFetching
      ? state.table["Super Admin"].sortDir
      : "";

  const roleId = user && !user.isFetching && user.roleId;

  const partnerCurrentPage =
    Partner && !Partner.isFetching ? Partner.currentPage : 1;

  return {
    superAdminCount,
    superAdminSort,
    superAdminSortDir,
    partnerCurrentPage,
    roleId
  };
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}
// const mapStateToProps = state => {};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(users);
