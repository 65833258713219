import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import SaveButton from "../../components/base/SaveButton";
import CancelButton from "../../components/base/CancelButton";
import DeleteButton from "../../components/base/DeleteButton";

/**
 * Render Add modal
 *
 * @param {*} param0
 */
const AddModal = ({
  toggle,
  toggleModalClose,
  isOpen,
  HeaderClassName,
  FooterClassName,
  modalTitle,
  modalBody,
  modalFooter,
  primaryBtnClassName,
  primaryColor,
  cancelColor,
  hideFooter,
  showAddButton,
  showDeleteButton,
  hideDefaultButtons,
  id
}) => (
  <div className="custom-popup">
    <Modal
      id={id}
      isOpen={isOpen}
      toggle={toggle}
      keyboard={true}
      backdrop="static"
      size="xl"
      className={HeaderClassName}
      style={{
        width: "auto",
        height: "auto",
        margin: "10px auto"
      }}
    >
      <ModalHeader
        toggle={toggleModalClose}
        cssModule={{ "modal-title": "w-100 text-center" }}
      >
        <span className="text-center">{modalTitle}</span>
      </ModalHeader>
      <ModalBody
        style={{
          maxHeight: "70vh",
          paddingRight: "20px",
          overflowY: "auto"
        }}
      >
        <div className="mt-2 mb-3">{modalBody}</div>
      </ModalBody>
      {hideFooter ? (
        ""
      ) : (
        <ModalFooter className={FooterClassName}>
          <div className="container-fluid">
            <div className="col-sm-12 text-center">{modalFooter}</div>
          </div>
          {!hideDefaultButtons && (
            <>
              {showAddButton ? (
                <Button
                  id={id}
                  color={primaryColor}
                  className={primaryBtnClassName}
                  onClick={toggle}
                >
                  ADD
                </Button>
              ) : (
                <SaveButton
                  id={id}
                  type="submit"
                  color={primaryColor}
                  className={primaryBtnClassName}
                  onClick={toggle}
                >
                  Save
                </SaveButton>
              )}
              <CancelButton id={id} color={cancelColor} onClick={toggle} />

              <DeleteButton id={id} label={"Delete"} onClick={toggle} />
            </>
          )}
        </ModalFooter>
      )}
    </Modal>
  </div>
);
export default AddModal;
