// Status
export const INVOICE_STATUS_PENDING = "pending";
export const INVOICE_STATUS_APPROVED = "approved";
export const INVOICE_STATUS_PAID = "paid";
export const INVOICE_STATUS_PAID_OUT = "paid_out";
export const INVOICE_STATUS_PAYMENT_ERROR = "payment_error";
export const INVOICE_STATUS_PARTIALLY_PAID = "partially paid";

// Invoice Bill Creation and Schedule Payment
export const CREATE_BILL_NOT_SCHEDULE_PAYMENT =
  "Create Bill in Torchlite, Payment made in AP Provider";
export const CREATE_BILL_AND_SCHEDULE_PAYMENT =
  "Create Bill and Schedule Payment in Torchlite";
export const AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_DEAL_CLOSED =
  "Automatically generate an invoice when Deal is Closed";
export const ENABLE_INVOICE_SETTINGS = "enable_invoice_settings";
export const AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_CUSTOMER_PAYMENT_HAS_BEEN_MADE =
  "Automatically generate an invoice when Customer Payment has been made";
export const DISABLE_INVOICE_GENERATION = "Disable invoice generation";
export const PAYMENT_MADE_IN_AP_PROVIDER = "Payment made in AP Provider";
export const PAYMENT_MADE_IN_TORCHLITE = "Payment in Torchlite";
export const AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_DEAL_CLOSED_VALUE = 1;
export const AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_CUSTOMER_PAYMENT_HAS_BEEN_MADE_VALUE = 2;
export const DISABLE_INVOICE_GENERATION_VALUE = 3;
