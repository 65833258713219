import {
  REQUEST_DASHBOARD_COUNT,
  RECEIVE_DASHBOARD_COUNT,
  FETCH_DASHBOARD_COUNT_FAIL,
  REQUEST_CUSTOMER_DASHBOARD_COUNT,
  RECEIVE_CUSTOMER_DASHBOARD_COUNT,
  FETCH_CUSTOMER_DASHBOARD_COUNT_FAIL
} from "../actionType/Constants";

export function dashboardReducer(
  state = {
    isFetching: false,
    isCustomerFetching: false
  },
  action
) {
  switch (action.type) {
    case REQUEST_DASHBOARD_COUNT:
      return Object.assign({}, state, {
        isFetching: true
      });
    case RECEIVE_DASHBOARD_COUNT: {
      return Object.assign({}, state, {
        isFetching: false,
        adminDashboard: action.payload
      });
    }
    case FETCH_DASHBOARD_COUNT_FAIL: {
      return { ...state, isFetching: false };
    }
    case REQUEST_CUSTOMER_DASHBOARD_COUNT:
      return Object.assign({}, state, {
        isCustomerFetching: true
      });
    case RECEIVE_CUSTOMER_DASHBOARD_COUNT:
      return Object.assign({}, state, {
        isCustomerFetching: false,
        customerDashboard: action.payload
      });
    case FETCH_CUSTOMER_DASHBOARD_COUNT_FAIL:
      return { ...state, isCustomerFetching: false };
    default:
      return state;
  }
}
