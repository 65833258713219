import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CheckmarkIcon,
  ChevronRight,
  CoffeeGraphicIcon
} from "../../../assets/img/icons";
import "react-circular-progressbar/dist/styles.css";
import OnboardingLink from "./OnboardingLink";
import { getCookie } from "../../../lib/helper";
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";
import { EXPERT_STATUS_APPROVED } from "../../../expertStatus/Constants";
import { COOKIE_USER_ID } from "../../../lib/cookie";
import { isBadRequest } from "../../../common/http";

const pendingStatus = "Pending";

const ExpertOnboarding = props => {
  const {
    enable,
    percentage,
    profileUpdateStatus,
    expertPaymentAccessStatus,
    expertAvailableStatus,
    expertAvailableText
  } = props;

  const [expertId, setExpertId] = useState(null);

  const userLoggedIn =
    getCookie(COOKIE_USER_ID) !== undefined &&
    getCookie(COOKIE_USER_ID) !== null;

  const _getUserDetails = () =>
    userLoggedIn &&
    apiClient
      .get(`${endpoints().userAPI}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        setExpertId(response.data.expertId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });

  useEffect(() => {
    _getUserDetails();
  }, []);

  return (
    <div
      className={["expert-onboarding", `${!enable ? "disabled" : ""}`].join(
        " "
      )}
    >
      {props.expertStatus !== EXPERT_STATUS_APPROVED && (
        <OnboardingLink
          icon={
            <div className="step-wrapper">
              <span className="step-count red-faded completed">
                <CoffeeGraphicIcon />
              </span>
            </div>
          }
          title={"What a great day! Thank you for applying!"}
          description={
            "We’re evaluating your profile. In the meantime, you can follow the\n" +
            "            steps below & check out the sample project card."
          }
          availability={{
            status:
              expertAvailableStatus === "Completed"
                ? "available"
                : "not-available",
            text: expertAvailableText
          }}
          progress={percentage}
          link="/availability"
        />
      )}

      {/* TODO: @NORBERT ADAPT THIS TO USE THE COMPONENT */}
      <Link
        to={{
          pathname: "/edit/public-profile",
          state: {
            expertId: expertId
          }
        }}
        className="onboarding-link"
      >
        <div className="onboarding-header">
          <div className="step-wrapper">
            {profileUpdateStatus === pendingStatus ? (
              <span className="step-count blue-faded">1</span>
            ) : (
              <span className="step-count blue-faded completed">
                <CheckmarkIcon />
              </span>
            )}
          </div>
        </div>
        <div className="onboarding-main">
          <h5 className="font-weight-bold">Complete your profile</h5>
          <p className="font-weight-bold text-inline-grayed">
            Boost your chances of getting matched to projects
          </p>
        </div>
        <div className="onboarding-url">
          <ChevronRight />
        </div>
      </Link>

      <div className="d-none">
        {/* Remove the above d-none div when showing add payment */}
        <a
          href={
            expertPaymentAccessStatus === pendingStatus
              ? "#"
              : "Javascript:void(0);"
          }
          className="onboarding-link"
        >
          <div className="onboarding-header">
            <div className="step-wrapper">
              {expertPaymentAccessStatus === pendingStatus ? (
                <span className="step-count red-faded">2</span>
              ) : (
                <span className="step-count red-faded completed">
                  <CheckmarkIcon />
                </span>
              )}
            </div>
          </div>
          <div className="onboarding-main">
            <h5 className="font-weight-bold">Accept Payments</h5>
            <p className="font-weight-bold text-inline-grayed">
              Add payment info so that you can get paid for projects
            </p>
          </div>
          <div className="onboarding-url">
            <ChevronRight />
          </div>
        </a>
      </div>

      {percentage < 100 && expertAvailableStatus === pendingStatus && (
        <Link to="/availability" className="onboarding-link">
          <div className="onboarding-header">
            <div className="step-wrapper">
              {expertAvailableStatus === pendingStatus ? (
                <span className="step-count green-faded">2</span> // change to 3 when showing payment section
              ) : (
                <span className="step-count green-faded completed">
                  <CheckmarkIcon />
                </span>
              )}
            </div>
          </div>
          <div className="onboarding-main">
            <h5 className="font-weight-bold">Update Availability</h5>
            <p className="font-weight-bold text-inline-grayed">
              Keeping your availability updated helps us find the right projects
              for you
            </p>
          </div>
          <div className="onboarding-url">
            <ChevronRight />
          </div>
        </Link>
      )}
    </div>
  );
};

export default ExpertOnboarding;
