import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import Form from "../../components/base/Form";
import Button from "../../components/base/Button";
import Text from "../../components/base/Text";
import toast from "../../components/base/Toast";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

//Constants
import { COURSE_DRAFT } from "../../course/Constants";
import { isBadRequest } from "../../common/http";

export default class AddCourseModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      status: COURSE_DRAFT,
      addData: false,
      values: []
    };
  }

  _onBasicSubmit = values => {
    this.setState({ addData: true });
    if (this._validateFields(values) === false) {
      return;
    }
    this._createCourseTitle(this._toArray(values));
  };
  componentDidMount() {
    if (this.props.isOpen) {
      this.setState({ addData: false });
    }
  }
  _createCourseTitle(data) {
    this.setState({ addData: true });
    return apiClient
      .post(endpoints().courseAPI, data)
      .then(response => {
        this.setState({ courseId: response.data.courseId });
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({ addData: false });
        setTimeout(() => {
          toast.success(successMessage);
          const url =
            this.state.status === COURSE_DRAFT
              ? "/course?section=Draft"
              : "/course";
          this.props.history.push(url);
          this.props.actions.fetchCourseList(`status=${COURSE_DRAFT}`);
          this.props.toggle();
        }, 2000);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          this.setState({ isSubmitting: false });
          toast.error(error.response.data.message);
          this.setState({ addData: true });
        }
      });
  }
  _toArray(values) {
    const data = new FormData();
    data.append("title", values.title.trim());
    data.append("status", this.state.status);
    return data;
  }

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const title = values.title;

    if (!title) {
      success = false;
    }
    this.setState({ addData: false });
    return success;
  }

  render() {
    const { isOpen, toggle } = this.props;
    const { addData } = this.state;
    const closeEditModal = () => {
      toggle();
    };

    return (
      <Modal
        isOpen={isOpen}
        toggle={closeEditModal}
        backdrop="static"
        className={["edit-task-modal"].join(" ")}
      >
        <Form
          initialValues={{
            title: ""
          }}
          onSubmit={values => {
            this._onBasicSubmit(values);
          }}
        >
          <ModalHeader toggle={closeEditModal}>
            <h4 className={["font-weight-bold"].join(" ")}>Add Course</h4>
          </ModalHeader>
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="form-wrapper">
              <div>
                <Text
                  id={`title`}
                  name="title"
                  label="Course Title"
                  placeholder="Enter Course Title"
                  required
                  error=""
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <Button
                label="Add"
                type="submit"
                className="btn btn-primary"
                disabled={isOpen ? false : addData}
              />
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}
