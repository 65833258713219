import React from "react";
import moment from "moment";
import toast from "../../../../components/base/Toast";
import { Link } from "react-router-dom";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

// Helper
import { getKeyValueByObject } from "../../../../lib/helper";

// Components
import PageTitle from "../../../../components/base/PageTitle";
import PageSearch from "../../../../components/base/PageSearch";
import { Table } from "reactstrap";
import SelectDropdown from "../../../../components/base/SelectDropdown";
import Page404 from "../../../Page404";
import AddFilter from "./inner-components/AdvanceFilter";
import { FunnelFill } from "../../../../assets/img/icons";
// Constants
import {
  LAST_MONTH,
  LAST_MONTH_TEXT,
  LAST_WEEK,
  LAST_WEEK_TEXT,
  THIS_MONTH,
  THIS_MONTH_TEXT,
  THIS_WEEK,
  THIS_WEEK_TEXT,
  TODAY,
  TODAY_TEXT,
  YESTERDAY,
  YESTERDAY_TEXT,
  SALESFORCE_OBJECT_ACCOUNT,
  SALESFORCE_OBJECT_OPPORTUNITY
} from "../../../../salesforceRelativeDates/Constants";

// Constants
import { SETTINGS_SALESFORCE_OPPORTUNITY_AMOUNT_FIELD_NAME } from "../../../../setting/Constants";

import {
  SALESFORCE_OPPORTUNITY_NET_ARR_AMOUNT,
  SALESFORCE_OPPORTUNITY_AMOUNT
} from "../../../../salesforce/Constants";

// Helper
import { isSuperAdmin, isCompanyAdmin } from "../../../../lib/helper";
import { isBadRequest } from "../../../../common/http";
import OpportunityDetails from "./opportunityDetails";

import { isSalesforceID } from "../../../../common/utils";

import { connect } from "react-redux";

class SalesforceOpportunity extends React.Component {
  state = {
    isSearching: false,
    opportunityList: [],
    searchResultMessage: "Search Opportunities",
    searchTerm: "",
    salesforceId: "",
    createdDate: "",
    isSyncRuleAddModalOpen: false,
    AccountObjectFieldList: [],
    OpportunityObjectFieldList: [],
    sortByOptions: [
      {
        value: TODAY,
        label: TODAY_TEXT
      },
      {
        value: YESTERDAY,
        label: YESTERDAY_TEXT
      },
      {
        value: THIS_WEEK,
        label: THIS_WEEK_TEXT
      },
      {
        value: LAST_WEEK,
        label: LAST_WEEK_TEXT
      },
      {
        value: THIS_MONTH,
        label: THIS_MONTH_TEXT
      },
      {
        value: LAST_MONTH,
        label: LAST_MONTH_TEXT
      }
    ]
  };

  salesforceObjectNameList = [
    {
      label: "Opportunities",
      value: "Opportunities"
    },
    {
      label: "Account",
      value: "Account"
    }
  ];

  relationalOperators = [
    {
      label: "Starts with",
      value: "Starts with"
    },
    {
      label: "Contains",
      value: "Contains"
    },
    {
      label: "Less than",
      value: "less_than"
    },
    {
      label: "Greater than",
      value: "greater_than"
    },
    {
      label: "Greater than or Equal to",
      value: "greater_than_or_equal_to"
    },
    {
      label: "Equal to",
      value: "equal_to"
    },
    {
      label: "Not Equal to",
      value: "not_equal_to"
    }
  ];

  handleAddModal = () => {
    this.setState({
      isSyncRuleAddModalOpen: !this.state.isSyncRuleAddModalOpen
    });
  };

  salesforceSearch = data => {
    this.setState({ isSearching: true }, () => {
      let { searchTerm, salesforceId, createdDate } = this.state;

      searchTerm = searchTerm ? searchTerm : "";
      salesforceId = salesforceId ? salesforceId : "";
      createdDate = createdDate ? createdDate : "";
      let SalesforceObjectName;

      let operator;
      let salesforceFieldName;
      let type;
      let value;
      if (data) {
        SalesforceObjectName = data.SalesforceObjectName || "";
        operator = data.operator || "";
        salesforceFieldName = data.salesforceFieldName || "";
        type = data.type || "";
        value = data.value || "";
      }

      let url1 = `${
        endpoints().salesforceLookupAPI
      }/opportunity/search?searchTerm=${searchTerm}&salesforceId=${salesforceId}&createdDate=${createdDate}`;

      let url2 = `${
        endpoints().salesforceLookupAPI
      }/opportunity/search?SalesforceObjectName=${SalesforceObjectName}&operator=${operator}&salesforceFieldName=${salesforceFieldName}&type=${type}&value=${value}`;

      let url = data ? url2 : url1;

      return apiClient
        .get(url)
        .then(response => {
          const { records } = response.data;

          this.setState({
            opportunityList: records || [],
            isSearching: false,
            searchResultMessage:
              searchTerm || salesforceId || createdDate
                ? "No opportunities found"
                : "Search Opportunities"
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    });
  };

  componentDidMount() {
    this.getSalesforceObjectFieldList(SALESFORCE_OBJECT_ACCOUNT);
    this.getSalesforceObjectFieldList(SALESFORCE_OBJECT_OPPORTUNITY);
  }

  getSalesforceObjectFieldList = ObjectName => {
    apiClient
      .get(`${endpoints().salesforceAPI}/fieldList/${ObjectName}`)
      .then(response => {
        if (response && response.data) {
          if (ObjectName === "Account") {
            this.setState({ AccountObjectFieldList: response.data });
          } else if (ObjectName === "Opportunity") {
            this.setState({ OpportunityObjectFieldList: response.data });
          }
        }
      });
  };

  handleSearchTermChange = searchTerm => {
    //validate search term is salesforce ID or not
    let validateSalesforceID = isSalesforceID(searchTerm);
    this.setState(
      {
        searchTerm,
        salesforceId: validateSalesforceID ? searchTerm : null
      },
      () => {
        this.salesforceSearch();
      }
    );
  };

  handleIdChange = salesforceId => {
    this.setState(
      {
        salesforceId
      },
      () => {
        this.salesforceSearch();
      }
    );
  };

  handleCreatedDateChange = createdDate => {
    this.setState(
      {
        createdDate: this.getSortValueFromLabel(createdDate)
      },
      () => {
        this.salesforceSearch();
      }
    );
  };

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  render() {
    const { settings } = this.props;
    const {
      opportunityList,
      searchResultMessage,
      createdDate,
      sortByOptions,
      isSyncRuleAddModalOpen,
      AccountObjectFieldList,
      OpportunityObjectFieldList
    } = this.state;

    const salesforceOpportunityAmountFieldName = settings
      ? getKeyValueByObject(
          settings,
          SETTINGS_SALESFORCE_OPPORTUNITY_AMOUNT_FIELD_NAME
        )
      : "";

    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId)
    ) {
      return <Page404 />;
    }
    const { activeSubTab, activeSection, activeSubSection } = this.props;
    return (
      <>
        <AddFilter
          isOpen={isSyncRuleAddModalOpen}
          toggle={this.handleAddModal}
          salesforceObjectNameList={this.salesforceObjectNameList}
          relationalOperators={this.relationalOperators}
          AccountObjectFieldList={AccountObjectFieldList}
          OpportunityObjectFieldList={OpportunityObjectFieldList}
          salesforceSearch={this.salesforceSearch}
        />
        {!activeSection ? (
          <>
            <PageTitle label="Salesforce Opportunity" />

            {/* Main Layout */}
            <div>
              {/* Search Bar */}
              <div>
                <div
                  className={`page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column`}
                >
                  <>
                    <PageSearch
                      classnames="page-search"
                      placeholder="Search"
                      onChange={e =>
                        this.handleSearchTermChange(e.target.value)
                      }
                    />
                  </>

                  <SelectDropdown
                    buttonLabel={createdDate || "Select Created Date"}
                    dropdownLinks={sortByOptions}
                    color={"gray"}
                    hideCaret
                    selectName={"sortby_createdDate"}
                    handleChange={this.handleCreatedDateChange}
                  />
                  <div
                    className="pull-right btn btn-secondary ml-2"
                    onClick={() => {
                      this.handleAddModal();
                    }}
                  >
                    <FunnelFill />
                  </div>
                </div>

                {/* Opportunity List */}
                <div className="overflow-auto">
                  <Table className="table-hover bg-white">
                    <thead className="thead-dark table-bordered">
                      <tr>
                        <th>Salesforce Id</th>
                        <th>Opportunity</th>
                        <th>Close Date</th>
                        <th>Amount</th>
                        <th>Stage</th>
                        <th>CreatedAt</th>
                      </tr>
                    </thead>
                    <tbody>
                      {opportunityList && opportunityList.length ? (
                        opportunityList.map(opportunity => (
                          <tr>
                            <td>
                              <Link
                                to={`/support-portal/salesforce/opportunity/${opportunity.Id}`}
                              >
                                <span className="banner-image-upload-link">
                                  {opportunity.Id}
                                </span>
                              </Link>
                            </td>
                            <td>{opportunity.Name}</td>
                            <td>
                              {opportunity.CloseDate &&
                                moment(opportunity.CloseDate).format(
                                  "MM/DD/YYYY"
                                )}
                            </td>

                            {salesforceOpportunityAmountFieldName ===
                              SALESFORCE_OPPORTUNITY_NET_ARR_AMOUNT && (
                              <td>
                                {opportunity.sales2cash__Net_ARR__c
                                  ? `$${opportunity.sales2cash__Net_ARR__c}`
                                  : ""}
                              </td>
                            )}
                            <td>{opportunity.Amount}</td>
                            {salesforceOpportunityAmountFieldName ===
                              SALESFORCE_OPPORTUNITY_AMOUNT && (
                              <td>
                                {opportunity &&
                                  (opportunity.Amount
                                    ? `$${opportunity.Amount}`
                                    : "")}
                              </td>
                            )}

                            <td>{opportunity.StageName}</td>

                            <td>
                              {moment(opportunity.CreatedDate).format(
                                "MM/DD/YYYY"
                              )}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td className="text-center p-4" colSpan={6}>
                            {searchResultMessage}
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            {activeSubTab == "opportunity" && (
              <OpportunityDetails id={activeSubSection} />
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(SalesforceOpportunity);
