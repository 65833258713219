import React from "react";
import moment from "moment-timezone";

// Helper
import {
  isCustomer,
  isExpert,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../../lib/helper";

// KickOff Time Picker Functions
import {
  KickOffTimePickerCheckbox,
  KickOffTimePickerRadio,
  KickOffTimePickerAdminCheckbox
} from "./KickOffTimePickerInputs";

import { useSelector } from "react-redux";

const KickOffTimePickerListItem = ({
  index,
  date,
  hour,
  handleChange,
  getSelectedValue,
  visualTimezone,
  defaultTimezone,
  dateId,
  customerWaitingForExpert,
  selectedHours
}) => {
  const currentUser = useSelector(state => state.user);

  return (
    <li className={"kickoff-scheduler__list-item"}>
      <div className="checkbox-wrapper">
        <label
          className={"kickoff-scheduler__label"}
          htmlFor={`hour-${moment(date).date()}-${index}`}
        >
          {/* KickOff Time Picker For Customer */}
          {(isSuperAdmin(currentUser && currentUser.roleId) ||
            isCompanyAdmin(currentUser && currentUser.roleId) ||
            isCompanyManager(currentUser && currentUser.roleId)) && (
            <KickOffTimePickerAdminCheckbox
              index={index}
              date={date}
              hour={hour}
              handleChange={handleChange}
              getSelectedValue={getSelectedValue}
              visualTimezone={visualTimezone}
              customerWaitingForExpert={customerWaitingForExpert}
              dateId={dateId}
              selectedHours={selectedHours}
            />
          )}
          {isCustomer(currentUser && currentUser.roleId) && (
            <KickOffTimePickerCheckbox
              index={index}
              date={date}
              hour={hour}
              handleChange={handleChange}
              getSelectedValue={getSelectedValue}
              visualTimezone={visualTimezone}
              customerWaitingForExpert={customerWaitingForExpert}
            />
          )}

          {/* KickOff Time Picker For Expert */}
          {isExpert(currentUser && currentUser.roleId) && (
            <KickOffTimePickerRadio
              index={index}
              date={date}
              hour={hour}
              handleChange={handleChange}
              getSelectedValue={getSelectedValue}
              defaultTimezone={defaultTimezone}
              visualTimezone={visualTimezone}
              dateId={dateId}
            />
          )}
          <span>{hour.formatted}</span>
        </label>
      </div>
    </li>
  );
};

export default KickOffTimePickerListItem;
