import React from "react";
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";

//Assets
import {
  BlueJeansIcon,
  ChevronRight,
  MondayDotComIcon
} from "../../../../assets/img/icons";

// Constants
import {
  INTEGRATION_COLLABORATION_SUB_TAB,
  TAB_BLUE_JEANS_SETTINGS,
  TAB_MONDAY_DOT_COM_SETTINGS,
  TAB_INTEGRATION
} from "../../../../setting/Constants";
import BlueJeansSection from "./sections/BlueJeansSection";
import MondayDotComSection from "./sections/MondayDotComSection";

const CollaborationSection = props => {
  const { activeSection, adminSettings, saveSettings, history } = props;

  return (
    <>
      {!activeSection && (
        <>
          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_COLLABORATION_SUB_TAB}/${TAB_BLUE_JEANS_SETTINGS}`}
            className="onboarding-link mb-2"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="step-count d-block completed">
                  <BlueJeansIcon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">Blue Jeans</h5>
              <p className="font-weight-bold text-inline-grayed"></p>
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>
          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_COLLABORATION_SUB_TAB}/${TAB_MONDAY_DOT_COM_SETTINGS}`}
            className="onboarding-link mb-2"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="completed">
                  <MondayDotComIcon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">Monday.com</h5>
              <p className="font-weight-bold text-inline-grayed"></p>
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>
        </>
      )}

      <TabContent activeTab={activeSection}>
        <TabPane tabId={TAB_BLUE_JEANS_SETTINGS} className="w-100">
          <BlueJeansSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeSection}>
        <TabPane tabId={TAB_MONDAY_DOT_COM_SETTINGS} className="w-100">
          <MondayDotComSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default CollaborationSection;
