import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { SuccessCheckIcon } from "../../../assets/img/icons";
import CancelButton from "../../../components/base/CancelButton";

const TaskCompletedModal = ({ projectDeliverables, modal, setModal }) => {
  const { projectId, description, projectName } = projectDeliverables;
  const redirectToProjectDetailPage = projectId => {
    window.location = `/project/${projectId}`;
  };

  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      className={["log-hours-modal", "edit-task-modal"].join(" ")}
      backdrop="static"
    >
      <ModalHeader toggle={setModal}>
        <SuccessCheckIcon />
        <h4 className={["font-weight-bold", "my-4"].join(" ")}>
          Task Completed
        </h4>
      </ModalHeader>
      <ModalBody className={["text-center", "mb-4"].join(" ")}>
        <div className="form-wrapper">
          <div className="field-wrapper">
            <p>
              Your expert has completed{" "}
              <strong className="font-weight-bold">“{description}”</strong> task
              {projectName && (
                <>
                  {" "}
                  in project{" "}
                  <strong className="font-weight-bold">"{projectName}</strong>
                </>
              )}
              . Please review and approve or give notes.
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={["justify-content-center"].join(" ")}>
        <div className="btn-wrapper">
          <CancelButton
            className="btn btn-outline-secondary"
            onClick={setModal}
          />
          <button
            className="btn btn-primary"
            onClick={() => redirectToProjectDetailPage(projectId)}
          >
            View Project
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default TaskCompletedModal;
