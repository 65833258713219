import React from "react";

// Components
import { InlineInfoIcon } from "../../../../assets/img/icons";
import AlertBanner from "../../../../components/base/AlertBanner";

const ProspectAccountToggle = props => {
  const { setProspectToggleValues } = props;
  return (
    <>
      <AlertBanner
        icon={<InlineInfoIcon />}
        buttonLabel=" Check Prospect company in HubSpot"
        buttonOnClick={() => {
          setProspectToggleValues({
            isNoHubSpotProspectAccountFoundModalOpen: true,
            isHubSpotProspectAccountNotFoundNotification: true,
            accountType: "Company (Prospect)"
          });
        }}
        removeOnClick={() => {
          setProspectToggleValues({
            isHubSpotProspectAccountNotFoundNotification: false
          });
        }}
        message="Prospect Company was not found. Do a check for partial matches."
      />
    </>
  );
};

export default ProspectAccountToggle;
