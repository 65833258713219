import React from "react";

// Icons
import { InfoIcon } from "../../assets/img/icons";

// Components
import Email from "../../components/base/Email";
import Phone from "../../components/base/Phone";
import Text from "../../components/base/Text";
import Password from "../../components/base/Password";
import SecondaryButton from "../../components/base/SecondaryButton";
import Checkbox from "./Checkbox";

// Helper
import { TORCHLITE_WEBSITE_URL } from "../../configs";

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptTerms: false,
      required: false
    };
  }

  render() {
    if (this.props.currentStep !== this.props.step) {
      // Prop: The current step
      return null;
    }

    const { required } = this.state;

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <InfoIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-3">Basic Information</h2>
        </div>
        <div className="content">
          <div className="field-wrapper">
            <span>
              <b>Company Name: </b> {this.props.partnerDetails.companyName}
            </span>
          </div>

          <hr />

          <div className="field-wrapper">
            <Text
              name="firstName"
              label="First Name"
              placeholder="Enter first name"
              error=""
              {...{ required }}
            />
            <Text
              name="lastName"
              label="Last Name"
              placeholder="Enter last name"
              error=""
              {...{ required }}
            />
          </div>

          <div className="field-wrapper">
            <Email
              name="email"
              label="Email"
              placeholder="Enter email"
              error={!this.props.partnerSignup ? this.props.errorMessage : ""}
              {...{ required }}
            />
            <Phone
              name="phone"
              label="Phone"
              placeholder="Enter phone"
              error=""
              {...{ required }}
            />
          </div>

          <div className="field-wrapper">
            <Password
              name="password"
              label="Password"
              placeholder="Enter password"
              required={true}
              error={
                this.props.passwordMismatchError
                  ? this.props.passwordMismatchError
                  : ""
              }
              strongPassword={
                this.props.passwordMismatchError ===
                "Please choose stronger password"
              }
              onInputChange={this.props.handlePasswordErrors}
              {...{ required }}
            />
            <Password
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Enter confirm password"
              required={true}
              error={
                this.props.confirmPasswordMismatchError
                  ? this.props.confirmPasswordMismatchError
                  : ""
              }
              onInputChange={this.props.handlePasswordErrors}
              {...{ required }}
            />
          </div>

          <div className="field-wrapper position-relative">
            <Checkbox
              name="acceptTerms"
              label="I have read and accept the"
              required
              error="You must accept the terms and conditions"
              className={"check accepted-terms mr-3"}
            />{" "}
            <a
              className="term-and-condition cursor-pointer"
              href={`${TORCHLITE_WEBSITE_URL}/terms-of-service`}
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions
            </a>
          </div>

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton
              className="mr-4"
              label="Go Back"
              onClick={this.props.prev}
            />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={() => {
                this.setState({ required: true });
                return this.props.next;
              }}
            >
              Create Account
            </button>
          </div>
          <p className="text-grayed h7 mt-3">
            Note: We'll keep your personal information private.
          </p>
        </div>
      </div>
    );
  }
}

export default BasicInformation;
