import React from "react";
import toast from "../../../components/base/Toast";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchResourceList } from "../../../actions/resource";

// Configs
import { endpoints } from "../../../configs";

// API call
import { apiClient } from "../../../apiClient";

// Components
import ResourceGridCard from "./ResourceGridCard";
import DeleteModal from "../../../components/base/DeleteModal";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  isPartner
} from "../../../lib/helper";
import { isBadRequest } from "../../../common/http";

class ResourceCardGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteFormModal: false,
      deleteForm: false,
      currentResource: ""
    };
  }
  // Form URL
  updateUrl(data) {
    const { search, page, pageSize, sort, sortDir, status } = data;

    const params = [];

    if (search) {
      params.push(`search=${search}`);
    }

    if (status) {
      params.push(`status=${status}`);
    }

    if (page) {
      params.push(`page=${page}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    if (params.length > 0) {
      return params.join("&");
    }
  }

  // Resourcedelete api call
  handleDelete = resourceId => {
    return apiClient
      .delete(`${endpoints().resourceAPI}/${resourceId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);

        this.props.actions.fetchResourceList(
          this.updateUrl({
            pageSize: this.props.params.pageSize,
            status: this.props.params.status,
            page: this.props.params.page,
            search: this.props.params.searchParam
          })
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  render() {
    const { resources, history, roleId } = this.props;
    const { deleteFormModal, currentResource } = this.state;

    return (
      <>
        <DeleteModal
          isOpen={deleteFormModal}
          toggle={() => {
            this.setState({ deleteFormModal: false });
          }}
          title="Delete Resource"
          label={currentResource.title}
          deleteFunction={() => this.handleDelete(currentResource.id)}
        />
        <div className="row">
          {resources.map(resource => (
            <>
              <ResourceGridCard
                tag="a"
                category={resource.primaryCategory}
                description={resource.description}
                showOption={
                  isSuperAdmin(roleId) ||
                  isCompanyAdmin(roleId) ||
                  isCompanyManager(roleId)
                }
                data={{ ...resource, partnerId: resource.partner_id }}
                link={
                  (isSuperAdmin(roleId) && resource.resourceFile) ||
                  (isCompanyAdmin(roleId) && resource.resourceFile) ||
                  (isPartner(roleId) && resource.resourceFile)
                    ? resource.resourceFile
                    : resource.web_page_url
                }
                editLink={`/resource/edit/${resource.id}`}
                onClick={`/resource/edit/${resource.id}`}
                openLinkInNewTab={resource.web_page_url ? true : false}
                onClickDelete={() =>
                  this.setState({
                    deleteFormModal: true,
                    currentResource: resource
                  })
                }
                history={history}
                isPreview
                showEditOptions
                size={"col-xl-6 col-lg-6 col-md-6"}
                right={resource.banner_image ? "18px" : ""}
                left="123px"
              />
            </>
          ))}
        </div>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    resource: state.resource,
    resourceDetails: state.resource ? state.resource.data : {},
    roleId: state.user ? state.user.roleId : ""
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchResourceList }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ResourceCardGrid);
