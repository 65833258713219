/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchList } from "../actions/table";
import { Label } from "reactstrap";
import { PARTNER_TYPE_SETTING_ENABLE_PARTNERSHIP_WITH_INDIVIDUAL_PERSON } from "../partnerTypeSetting/Constants";

// Assets
import createLandingIcon from "../assets/pages/landing-page/create-landing-page.png";

// Component
import Text from "../components/base/Text";
import Form from "../components/base/Form";
import Email from "../components/base/Email";
import toast from "../components/base/Toast";
import Phone from "../components/base/Phone";

// Helper

//API call
import { apiClient } from "../apiClient";

// Configs
import { endpoints } from "../configs";
import { isBadRequest } from "../common/http";
import PartnerTier from "../components/PartnerTier";
import {
  isCompanyAdmin,
  isCompanyManager,
  isSuperAdmin,
  getParamsByName
} from "../lib/helper";
import PartnerType from "../components/PartnerType";
import { getDefaultPartnerType } from "./Service";
import { IsPartnerTypeSettingEnabled } from "../lib/PartnerSetting";
import { useSelector } from "react-redux";

// Partner Create API Call
async function partnerCreate(data, id, props, toggle, page) {
  // get params from url
  const searchItem = getParamsByName("search") || "";
  const section = getParamsByName("section") || "";
  const status = getParamsByName("status") || "";
  const sort = getParamsByName("sort") || "";
  const sortDir = getParamsByName("sortDir") || "";

  if (data.partnerEmail && props.setCurrentEmail) {
    props.setCurrentEmail(data.partnerEmail);
  }
  try {
    const response = await apiClient.post(endpoints().partnerInviteAPI, data);
    let successMessage;
    if (response && response.data) {
      successMessage = response.data.message;
    }
    toast.success(successMessage);
    successMessage &&
      props.actions.fetchList(
        "all-partners",
        `${endpoints().partnerAPI}/search`,
        page || 1,
        10,
        {
          search: searchItem,
          section: section,
          status: status,
          sort: sort,
          sortDir: sortDir
        }
      );
    toggle();
    props.triggerPartnerType();
  } catch (error) {
    if (isBadRequest(error)) {
      if (error.response.data.message === "Partner Already Exists") {
        props.setExistingPartnerId &&
          props.setExistingPartnerId(error.response.data.existingPartnerId);
        props.setPartnerUserInvitePopUp && props.setPartnerUserInvitePopUp();
        return "";
      }
      let errorMessage;
      const errorRequest = error.response.request;
      if (errorRequest && errorRequest.response) {
        errorMessage = JSON.parse(errorRequest.response).message;
      }
      toast.error(error.response.data.message);
      console.error(errorMessage);
    }
  }
}
const InvitePartnerModal = props => {
  const [associatedCompanyId] = useState();
  const modal = props.isInvitePartnerModalOpen;
  const page = props.currentPage;
  const [inboundLinkCopySuccess, setInboundLinkCopySuccess] = useState("Copy");
  const [shareLink, setShareLink] = useState("");
  const [defaultPartnerType, setDefaultPartnerType] = useState({});
  const [selectedTypeId, setSelectedTypeId] = useState([]);
  const [showDefaultTier, setShowDefaultTier] = useState(true);
  const [
    enablePartnerInvidualPersonConfig,
    setEnablePartnerInvidualPersonConfig
  ] = useState(false);

  const currentUser = useSelector(state => state.user);

  // Handle OnChange
  const handleChange = e => {
    const linkValue = e.target.value;
    setShareLink(linkValue);
  };

  function copyUrl(e) {
    navigator.clipboard.writeText(shareLink);
    e.target.focus();
    setInboundLinkCopySuccess("Copied!");
  }

  // get defulat type
  const getTagValues = async () => {
    let defaultData = {};
    defaultData["partnerType"] = await getDefaultPartnerType();
    setDefaultPartnerType(defaultData);

    if (
      defaultData &&
      defaultData.partnerType &&
      defaultData.partnerType.length > 0
    ) {
      defaultData = defaultData.partnerType[0];

      if (defaultData && defaultData.partnerTypeSetting) {
        const partnerAsInvidualPerson = defaultData.partnerTypeSetting
          ? IsPartnerTypeSettingEnabled(
              defaultData.partnerTypeSetting,
              PARTNER_TYPE_SETTING_ENABLE_PARTNERSHIP_WITH_INDIVIDUAL_PERSON
            )
          : "";
        setEnablePartnerInvidualPersonConfig(partnerAsInvidualPerson);
      }
    }
  };

  // get selected partner type id
  const handlePartnerType = event => {
    if (event.value && event.value.length > 0) {
      let selectedType = event && event !== null ? event.value : [];
      let typeId = [];
      let partnerAsInvidualPerson;
      let partnerTypeConfig;
      selectedType &&
        selectedType.forEach(element => {
          if (element) {
            partnerAsInvidualPerson = element.partnerTypeSetting
              ? IsPartnerTypeSettingEnabled(
                  element.partnerTypeSetting,
                  PARTNER_TYPE_SETTING_ENABLE_PARTNERSHIP_WITH_INDIVIDUAL_PERSON
                )
              : "";

            typeId.push({ id: element.value });

            if (partnerAsInvidualPerson) {
              partnerTypeConfig = partnerAsInvidualPerson;
            }
          }
        });
      partnerTypeConfig = partnerTypeConfig ? partnerTypeConfig : false;
      setSelectedTypeId(typeId);
      setEnablePartnerInvidualPersonConfig(partnerTypeConfig);
    } else {
      setShowDefaultTier(false);
      setSelectedTypeId([]);
    }
  };

  useEffect(() => {
    getTagValues();
  }, []);

  // modal toggle
  const toggle = () => {
    setShowDefaultTier(true);
    props.invitePartnerModalToggle();
  };

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="custom-modal-popup"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <div className="img-wrapper d-flex justify-content-center mb-2 mt-3">
                <img src={createLandingIcon} size="30" alt="" />
              </div>
              <p className="text-center font-weight-bold mb-3 ">
                Invite a new Partner
              </p>
              <p className="text-center h6-5 mb-2">
                Inviting a partner will send them an email that gets them
                started with the registration process
              </p>
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.content-wrapper */}
        </ModalHeader>

        <Form
          initialValues={{
            ...defaultPartnerType,
            company_name: "",
            firstName: "",
            lastName: "",
            partnerEmail: "",
            phone: ""
          }}
          onSubmit={values => {
            values &&
              values.partnerTier &&
              values.partnerTier.length > 0 &&
              values.partnerTier.forEach(tier => {
                values.partnerTier = tier;
              });
            let partnerType = [];
            partnerType &&
              partnerType.forEach(data => {
                if (data) {
                  partnerType.push({
                    partnerType: data.value
                  });
                }
              });
            if (enablePartnerInvidualPersonConfig) {
              values.companyName = values.company_name
                ? values.company_name
                : values.firstName + " " + values.lastName;
            } else {
              values.companyName = values.company_name;
            }
            values.phone = values && values.phone ? values.phone : "";

            partnerCreate(
              values,
              associatedCompanyId,
              props,
              toggle,
              page,
              partnerType
            );
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div>
                <Text
                  name="company_name"
                  label="Company Name"
                  error=""
                  required={enablePartnerInvidualPersonConfig ? false : true}
                  placeholder="Enter Company Name"
                />
              </div>
              <div>
                <Text
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                  error=""
                  required
                />
              </div>
              <div>
                <Text
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  error=""
                  required
                />
              </div>
              <div>
                <Email
                  name="partnerEmail"
                  label="Email"
                  placeholder="Enter Email Address..."
                  error=""
                  required
                />

                <Phone
                  name="phone"
                  label="Phone"
                  placeholder="Enter phone "
                  error=""
                />
                <PartnerType
                  isMulti
                  defaultPartnerType
                  name="partnerType"
                  label="Type"
                  required
                  placeholder="Select type"
                  isSearchable={true}
                  isDisabled={
                    !isSuperAdmin(currentUser && currentUser.roleId) &&
                    !isCompanyAdmin(currentUser && currentUser.roleId) &&
                    !isCompanyManager(currentUser && currentUser.roleId)
                      ? true
                      : false
                  }
                  onChange={e => {
                    handlePartnerType(e);
                  }}
                />
                <PartnerTier
                  showDefaultTier={showDefaultTier}
                  name="partnerTier"
                  label="Tier"
                  required
                  placeholder="Select Tier"
                  isSearchable={true}
                  isDisabled={
                    !isSuperAdmin(currentUser && currentUser.roleId) &&
                    !isCompanyAdmin(currentUser && currentUser.roleId) &&
                    !isCompanyManager(currentUser && currentUser.roleId)
                      ? true
                      : false
                  }
                  typeId={selectedTypeId}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="" className="h6-5-important">
                  Invite Partner
                </Button>
              </div>
            </div>
          </ModalFooter>
          <div className="d-none">
            <div
              style={{
                width: "100%",
                height: "20px",
                borderBottom: "1px solid gray",
                borderBottomColor: "gray",
                textAlign: "center"
              }}
            >
              <div
                style={{
                  padding: "8px 188px"
                }}
              >
                <div style={{ backgroundColor: "#fff" }}>OR</div>
              </div>
            </div>

            <div style={{ marginTop: "20px" }}>
              <Label className="font-weight-bold">Share an Invite Link</Label>
              <div className="row">
                <div className="col-9">
                  <Text
                    name="inboundReferralLink"
                    onChange={e => handleChange(e)}
                    placeholder=""
                  />
                </div>
                <div className="col-2">
                  <button
                    className={[
                      "btn",
                      `${
                        inboundLinkCopySuccess !== "Copied!"
                          ? "btn-primary"
                          : "btn-success"
                      }`,
                      "mb-2 ml-2"
                    ].join(" ")}
                    type="button"
                    onClick={copyUrl}
                  >
                    <span>{inboundLinkCopySuccess}</span>
                  </button>
                </div>
              </div>

              <span className={["text-inline-grayed d-block mb-2"].join("")}>
                You'll receive a notification when your partner signs up with
                this link.
              </span>
            </div>
          </div>
        </Form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(InvitePartnerModal);
