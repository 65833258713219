import React from "react";
import { apiClient } from "../../apiClient";
import { Redirect } from "react-router-dom";
import classnames from "classnames";
import { Link } from "react-router-dom";
import {
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  DropdownItem
} from "reactstrap";

// Configs
import { endpoints } from "../../configs";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchList } from "../../actions/table";
import { COOKIE_SHOW_ASSIGNED_PARTNERS } from "../../lib/cookie";
import {
  SETTINGS_ENABLE_PARTNER_PROGRAMS,
  SETTINGS_SHOW_REGION
} from "../../setting/Constants";
import { updatePartners, deletePartners } from "../../partners/action";
import InvitePartnerModal from "../../partners/InvitePartnerModal";
import PartnerInvitePopUp from "../../partners/PartnerInvitePopUp";
// Helper
import {
  getParamsByName,
  isLoggedIn,
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  getCookie,
  setCookie,
  clearCookie,
  getUserId,
  getKeyValueByObject,
  isEmpty
} from "../../lib/helper";
// import { COOKIE_SHOW_ASSIGNED_PARTNERS } from "../lib/cookie";
// Components
import PageTitle from "../../components/base/PageTitle";
import AddButton from "../../components/base/AddButton";
import { toast } from "react-toastify";
import { UserIcon } from "../../assets/img/icons";
import MoreDropdown from "../../components/base/MoreDropdown";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import AvatarCard from "../../components/base/AvatarCard";
import DeleteModal from "../../components/base/DeleteModal";

// self Components
import PartnerPrograms from "../../partners/PartnerPrograms";

// Inner Components
import Spinner from "../../components/base/Spinner";
import { isBadRequest } from "../../common/http";
import {
  PARTNER_STATUS_APPROVED,
  PARTNER_STATUS_PENDING,
  PARTNER_STATUS_DECLINED
} from "../../partners/Constants";
import CountBadge from "../../components/base/CountBadge";
import { getSetting } from "../../actions/settings";
// constants
const TAB_ALL_PARTNERS = "all-partners";
const TAB_PENDING_PARTNERS = "pending-partners";
const TAB_APPROVED_PARTNERS = "approved-partners";
const TAB_PARTNER_PROGRAMS = "partner-programs";

// Inner Components

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class PartnerList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isChecked: "",
      isInvitePartnerModalOpen: false,
      status: "",
      searchCount: 0,
      isLoading: false,
      partnerId: "",
      searchParam: "",
      activeTab: "",
      partnerTypeTrigger: false,
      deleteModal: false,
      partnerCompanyName: "",
      partnerTypeCount: 0,
      selectedStatus: "",
      selectedType: "",
      selectedTier: "",
      selectedPartnerProgramTypeId: "",
      partnerTiers: [],
      partnerTypes: [],
      selectedTypeId: [],
      statusSelected: "",
      regionOption: [],
      selectedRegion: "",
      showRegionField: "",
      statusOptions: [
        {
          value: "All:ASC",
          label: "All"
        },
        {
          value: "Approved",
          label: "Approved"
        },
        {
          value: "Pending",
          label: "Pending"
        },
        {
          value: "Declined",
          label: "Declined"
        }
      ],
      sortByOptions: [
        {
          value: "company_name:ASC",
          label: "Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ]
    };
  }

  // activetab state value
  setActiveTabToState = value => {
    let tab;
    if (value === "true") {
      tab = TAB_PARTNER_PROGRAMS;
      this.setState({
        activeTab: TAB_PARTNER_PROGRAMS
      });
    } else {
      tab = TAB_ALL_PARTNERS;
      this.setState({
        activeTab: TAB_ALL_PARTNERS
      });
    }
    this.setActiveTab(tab);
  };

  getSettings = () => {
    getSetting(response => {
      if (response && response.length > 0) {
        let showRegion =
          getKeyValueByObject(response, SETTINGS_SHOW_REGION) == "true"
            ? true
            : false;

        this.setState({
          showRegionField: showRegion
        });
      }
    });
  };

  getDefaultTab = () => {
    return isSuperAdmin(this.props.loggedInUser.roleId) ||
      isCompanyAdmin(this.props.loggedInUser.roleId) ||
      isCompanyManager(this.props.loggedInUser.roleId)
      ? this.state.activeTab
      : TAB_PARTNER_PROGRAMS;
  };
  // Assigned partners checkbox toggle
  toggleChange = e => {
    {
      this.setState({ isChecked: !this.state.isChecked });
    }

    if (this.state.isChecked) {
      clearCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    }

    if (!this.state.isChecked) {
      clearCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
      setCookie(COOKIE_SHOW_ASSIGNED_PARTNERS, "true");
    }
  };
  // Get search query string value
  getParamsStatus = () => {
    const status = getParamsByName("section");
    this.setState({ status: status || this.getDefaultTab() });
  };
  setActiveTab = status => {
    this.props.history.push(`/partners?section=${status}`);
  };
  componentDidMount() {
    const { settings } = this.props;
    // Check is logged in user
    isLoggedIn();
    // get setting value
    const enablePartnersProgram = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNER_PROGRAMS)
      : "";
    this.setActiveTabToState(enablePartnersProgram);
    // Assigned partner checkbox status
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    if (checked_status) {
      this.setState({ isChecked: !this.state.isChecked });
    }
    const regionId = getParamsByName("regionId") || "";

    if (regionId) {
      this.setState({ regionId: regionId });
    }
    if (!checked_status) {
      this.setState({ isChecked: this.state.isChecked });
    }
    this.getParamsStatus();
    if (isPartner(this.props.roleId)) {
      this._getPartnerDetails();
    }
    this.getPartnerType();
    this.getPartnerTier();
    this.getSettings();
  }
  // Handle status change
  _handleStatusChange = (tabStatus, status, typeId) => {
    // this.props.history.push(`/partners?section=${tabStatus}`);
    this.setState({
      status: tabStatus,
      selectedStatus: status,
      selectedPartnerProgramTypeId: typeId
    });
  };

  handleTypeChange = values => {
    let typeObj = values;
    let type = "",
      typeArray = [];
    const selectedTypeArrays = Object.entries(typeObj);

    if (selectedTypeArrays.length > 0) {
      selectedTypeArrays.forEach(async selectedTypeArray => {
        if (selectedTypeArray[1] === true) {
          typeArray.push(selectedTypeArray[0]);
        }
      });
    }
    if (typeArray.length > 0) {
      type = typeArray.join();
    }
    this.setState({ selectedType: type ? type : "", typeObj }, () => {
      this.fetchList();
    });
    this.getPartnerTier(type);
  };

  handleTierChange = values => {
    let tierObj = values;
    let tier = "",
      tierArray = [];
    const selectedTierArrays = Object.entries(tierObj);

    if (selectedTierArrays.length > 0) {
      selectedTierArrays.forEach(async selectedTierArray => {
        if (selectedTierArray[1] === true) {
          tierArray.push(selectedTierArray[0]);
        }
      });
    }

    if (tierArray.length > 0) {
      tier = tierArray.join();
    }
    this.setState({ selectedTier: tier ? tier : "", tierObj }, () => {
      this.fetchList();
    });
  };

  handleRegionChange = type => {
    if (type.value !== null) {
      this.setState(
        { selectedRegion: type && type ? type.value.value : "" },
        () => {
          this.fetchList();
        }
      );
    } else {
      this.setState({ selectedRegion: "" }, () => {
        this.fetchList();
      });
    }
  };

  getStatus = status => {
    this.setState({ statusSelected: status });
    if (status == "All") {
      this.setState({ statusSelected: "" });
    }
  };
  // Get partner details
  _getPartnerDetails = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        return apiClient
          .get(`${endpoints().partnerAPI}`)
          .then(response => {
            this.setState({
              isLoading: true,
              partnerDetails: response.data,
              partnerId: response.data.id
            });
          })
          .catch(error => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              console.error(errorMessage);
            }
          });
      }
    );
  };

  // Update partner status handler
  updatePartnerByStatus(status, partnerId) {
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    const managerUserId = getUserId();
    const searchItem = getParamsByName("search");
    const section = getParamsByName("section");
    const sort = getParamsByName("sort");
    const sortDir = getParamsByName("sortDir");
    const filterStatus = getParamsByName("status");
    let params = {
      pagination: true,
      search: searchItem ? searchItem : "",
      sort: sort ? sort : "company_name",
      sortDir: sortDir ? sortDir : "ASC",
      section: section,
      status: filterStatus ? filterStatus : ""
    };

    if (checked_status) {
      params.managerUserId = managerUserId;
    }
    this.props.actions.updatePartners(
      partnerId,
      { marketplaceStatus: status },
      this.props.currentPage,
      params
    );
    this.triggerPartnerType();
  }
  updatePartnersList = () => {
    const searchItem = getParamsByName("search");
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    const managerUserId = getUserId();
    const params = {
      pagination: true,
      search: searchItem
    };
    if (checked_status) {
      params.managerUserId = managerUserId;
    }
    this.props.actions.fetchList(
      TAB_APPROVED_PARTNERS,
      `${endpoints().partnerAPI}/search`,
      this.props.currentPage,
      10,
      {
        ...params,
        pagination: true,
        status: PARTNER_STATUS_APPROVED,
        marketplaceStatus: PARTNER_STATUS_APPROVED,
        section: TAB_APPROVED_PARTNERS
      }
    );
    this.props.actions.fetchList(
      TAB_ALL_PARTNERS,
      `${endpoints().partnerAPI}/search`,
      this.props.currentPage,
      10,
      {
        ...params,
        pagination: true
      }
    );
    this.props.actions.fetchList(
      TAB_PENDING_PARTNERS,
      `${endpoints().partnerAPI}/search`,
      this.props.currentPage,
      10,
      {
        ...params,
        pagination: true
      }
    );
    this.props.actions.fetchList(
      TAB_APPROVED_PARTNERS,
      `${endpoints().partnerAPI}/search`,
      this.props.currentPage,
      10,
      {
        ...params,
        pagination: true
      }
    );
  };
  setPartnerUserInvitePopUp = () => {
    this.setState({
      isOpen: true
    });
  };

  resetPartnerUserInvitePopUp = () => {
    this.setState({ isOpen: !this.state.isOpen });
  };
  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }
  setExistingPartnerId = partnerId => {
    this.setState({
      existingPartnerId: partnerId
    });
  };
  setCurrentEmail = email => {
    this.setState({
      currentEmail: email
    });
  };
  // Handle Parter User Invite
  partnerMenberInvite = async (id, userEmail) => {
    const partnerId = id || this.state.existingPartnerId;
    const email = userEmail || this.state.currentEmail;
    try {
      const response = await apiClient.post(
        `${endpoints().partnerUserAPI}/invite`,
        {
          partnerId,
          email
        }
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        this.setState({
          existingPartnerId: "",
          currentEmail: " ",
          isOpen: false,
          isInvitePartnerModalOpen: false
        });
      }
      toast.success(successMessage);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
      }
    }
  };

  // Toggle
  toggle = tab => {
    this.setState({
      activeTab: tab
    });

    this.props.history.push(`/partners?section=${tab}`);
  };
  invitePartnerModalToggle = () => {
    this.setState({
      isInvitePartnerModalOpen: !this.state.isInvitePartnerModalOpen
    });
  };

  // triggger partner type API
  triggerPartnerType = () => {
    this.setState({
      partnerTypeTrigger: !this.state.partnerTypeTrigger
    });
  };

  // getting partner type count
  setPartnerTypeCount = length => {
    this.setState({
      partnerTypeCount: length
    });
  };

  fetchList = () => {
    const {
      selectedTier,
      selectedType,
      statusSelected,
      selectedRegion
    } = this.state;
    this.props.actions.fetchList(
      "all-partners",
      `${endpoints().partnerAPI}/search`,
      1,
      10,
      {
        pagination: true,
        selectedType,
        selectedTier,
        statusSelected,
        selectedRegion
      }
    );
  };

  // Get partner tiers
  getPartnerType = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().partnerTypeAPI}/search`
      );
      const results = response.data.data;
      this.setState({ partnerTypes: results });
      this.getPartnerTier([], true);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
      this.getPartnerTier([], true);
    }
  };

  // Getting the partenr type options
  getPartnerTypeOptions = () => {
    // To list the categories in multi select dropdown
    let partnerTypeListOptions = [];
    let { partnerTypes } = this.state;
    if (!partnerTypes) return partnerTypeListOptions;

    this.state.partnerTypes
      .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
      .forEach(type => {
        partnerTypeListOptions.push({ value: type.id, label: type.name });
      });
    return partnerTypeListOptions;
  };

  // Get partner tiers
  getPartnerTier = async type => {
    try {
      const response = await apiClient.get(
        `${endpoints().partnerTierAPI}/search`
      );

      const results = response.data.data;

      const typeId = type ? type.split(",") : "";

      if (typeId && typeId.length > 0) {
        let options = [];

        let partnerTierOptions = [];

        // If type
        if (results && results.length > 0) {
          typeId.forEach(id => {
            results.forEach(tier => {
              // If partner type in tier

              let tierTypeIds = tier.partnerType.map(data => {
                return data.id;
              });

              let isTypeExist = tierTypeIds.find(data => data == id);

              if (isTypeExist) {
                options.push({
                  id: tier.id,
                  value: tier.id,
                  label: tier.name,
                  selected: false
                });
              }
            });
          });
        }

        // get tier ids to compair
        const selectedTierIds = options.map(tier => tier.id);

        // filtering duplicate tier
        partnerTierOptions = options.filter(
          ({ value }, index) => !selectedTierIds.includes(value, index + 1)
        );
        this.setState({ partnerTiers: partnerTierOptions });
      } else {
        if (results && results.length > 0) {
          const partnerTier = [];
          // Update state tier value with new selected type
          results.forEach(tier => {
            partnerTier.push({
              value: tier.id,
              label: tier.name
            });
          });
          this.setState({ partnerTiers: partnerTier });
        }
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };
  handlePartnerDelete = partnerId => {
    const section = getParamsByName("section");
    const status = getParamsByName("status");
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    const managerUserId = getUserId();
    const sort = getParamsByName("sort");
    let params = {
      sort: sort ? sort : ""
    };
    if (checked_status) {
      params.managerUserId = managerUserId;
    }
    this.props.actions.deletePartners(partnerId, {
      page: this.props.currentPage,
      ...params,
      sortDir: 10,
      pagination: true,
      status: status
        ? status
        : this.state.selectedStatus
        ? this.state.selectedStatus
        : "",
      partnerType: this.state.selectedPartnerProgramTypeId
        ? this.state.selectedPartnerProgramTypeId
        : "",
      section:
        section === TAB_ALL_PARTNERS ? TAB_ALL_PARTNERS : this.getDefaultTab()
    });
    this.triggerPartnerType();
  };
  renderPartnerTableByStatus(tableId, params) {
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    const managerUserId = getUserId();
    if (checked_status) {
      params.managerUserId = managerUserId;
    }
    const currentUrl = window.location.pathname + window.location.search;
    return (
      <div className="mt-4 mb-5">
        <ReduxTable
          id={tableId}
          apiURL={`${endpoints().partnerAPI}/search`}
          params={params && { ...params }}
          onRowClick={row =>
            window.open(
              `/partner-profile/${row.id}?redirect=${currentUrl}`,
              "_blank"
            )
          }
          settings={this.props.settings}
          searchBarWidth={
            isCompanyManager(this.props.loggedInUser.roleId) ||
            isCompanyAdmin(this.props.loggedInUser.roleId)
              ? "60%"
              : ""
          }
          searchPlaceholder="Search Partners..."
          sortByOptions={this.state.sortByOptions}
          selectedPartnerProgramTypeId={this.state.selectedPartnerProgramTypeId}
          statusOptions={this.state.statusOptions}
          selectedStatus={this.state.selectedStatus}
          newTableHeading
          showHeader
          assignedPartnersCheckbox="assignedPartnersCheckbox"
          assignedPartnerValue={this.state.isChecked}
          assignedPartnerChange={this.toggleChange}
          paramsToUrl={true}
          icon={<UserIcon />}
          message="You can start by inviting Partners."
          history={this.props.history}
          showDropdown={true}
          showStatusOptions
          handleTypeChange={this.handleTypeChange}
          handleRegionChange={this.handleRegionChange}
          handleTierChange={this.handleTierChange}
          partnerTierList={this.state.partnerTiers}
          partnerTypeList={this.getPartnerTypeOptions()}
          getStatus={this.getStatus}
          showRegionFilter={this.state.showRegionField}
        >
          <ReduxColumn
            minWidth="170px"
            type="link"
            isClickable="false"
            field="companyName"
            sortBy="company_name"
            renderField={row => (
              <Link to={`edit/partner/public-profile?id=${row.id}`}>
                <AvatarCard companyName={row.companyName} url={row.avatarUrl} />
              </Link>
            )}
          >
            Name
          </ReduxColumn>
          <ReduxColumn width={"100px"} field="partnerTypeName" disableOnClick>
            Type
          </ReduxColumn>
          <ReduxColumn width="100px" field="partnerTierName" disableOnClick>
            Tier
          </ReduxColumn>
          <ReduxColumn width="100px" field="managerUserName" disableOnClick>
            Manager
          </ReduxColumn>
          {this.state.showRegionField == true && (
            <ReduxColumn width="100px" field="region" disableOnClick>
              Region
            </ReduxColumn>
          )}
          <ReduxColumn
            width="115px"
            field="marketplaceStatus"
            disableOnClick
            renderField={row => (
              <div
                className={`text-center text-uppercase ${
                  row.marketplaceStatus !== PARTNER_STATUS_PENDING
                    ? row.marketplaceStatus === PARTNER_STATUS_DECLINED
                      ? "bg-danger"
                      : "landing-page-status-active"
                    : "landing-page-status-draft"
                }`}
                style={statusStyle}
              >
                <p>{row.marketplaceStatus}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            width="100px"
            field="Action"
            disableOnClick
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() =>
                      window.open(
                        `/partner-profile/${
                          row.companyProfileUrl ? row.companyProfileUrl : row.id
                        }`,
                        "_blank"
                      )
                    }
                  >
                    View Public Profile
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      this.props.history.push(
                        `edit/partner/public-profile?id=${row.id}`,
                        "_self"
                      )
                    }
                  >
                    Edit Details
                  </DropdownItem>
                  {row.marketplaceStatus === PARTNER_STATUS_PENDING && (
                    <DropdownItem
                      onClick={() => {
                        {
                          this.partnerMenberInvite(row.id, row.email);
                        }
                      }}
                    >
                      Resend Invite
                    </DropdownItem>
                  )}
                  {row.marketplaceStatus !== PARTNER_STATUS_APPROVED && (
                    <DropdownItem
                      onClick={() =>
                        this.updatePartnerByStatus(
                          PARTNER_STATUS_APPROVED,
                          row.id
                        )
                      }
                    >
                      Approve Partner
                    </DropdownItem>
                  )}
                  {row.marketplaceStatus !== PARTNER_STATUS_DECLINED && (
                    <DropdownItem
                      onClick={() =>
                        this.updatePartnerByStatus(
                          PARTNER_STATUS_DECLINED,
                          row.id
                        )
                      }
                    >
                      Decline Partner
                    </DropdownItem>
                  )}
                  <DropdownItem
                    className="font-weight-bold text-danger"
                    onClick={() =>
                      this.setState({
                        deleteModal: true,
                        partnerCompanyName: row.companyName,
                        partnerId: row.id
                      })
                    }
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    );
  }

  render() {
    const {
      status,
      isLoading,
      activeTab,
      partnerTypeCount,
      partnerTypeTrigger,
      selectedTier,
      selectedType,
      selectedRegion
    } = this.state;

    const {
      pendingPartnersRequestCount,
      approvedPartnersRequestCount,
      allPartnersRequestCount,
      currentPage
    } = this.props;

    const { settings } = this.props;

    // get setting value
    const enablePartnersPrograms = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNER_PROGRAMS)
      : "";
    const search = window.location.search;
    const { partnersSort, partnersSortDir } = this.props;
    const params = new URLSearchParams(search);
    const searchStatus = params.get("section");

    const isPendingPartners =
      searchStatus === TAB_PENDING_PARTNERS ||
      activeTab === TAB_PENDING_PARTNERS;

    const isAllPartners = activeTab === TAB_ALL_PARTNERS;

    const isPartnerPrograms = activeTab === TAB_PARTNER_PROGRAMS;

    const isApprovedPartners =
      searchStatus === TAB_APPROVED_PARTNERS ||
      activeTab === TAB_APPROVED_PARTNERS;
    const pathParams = new URLSearchParams(search);
    const searchItem = pathParams.get("search");

    const { deleteModal, partnerCompanyName, partnerId } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <>
        <DeleteModal
          isOpen={deleteModal}
          toggle={() => {
            this.setState({ deleteModal: false });
          }}
          title="Delete Partner"
          label={partnerCompanyName}
          deleteFunction={() => this.handlePartnerDelete(partnerId)}
        />
        <div className="pt-2">
          <div className="d-flex justify-content-between">
            {/* /.page-heading */}
            <PageTitle label="Partners" />
            <div className="pt-2">
              <AddButton
                label="Invite a Partner"
                className="btn btn-secondary  font-weight-bold"
                onClick={this.invitePartnerModalToggle}
              />
            </div>
            {isLoading && <Spinner />}

            {activeTab === this.getDefaultTab() ? (
              <Redirect to={`/partners?section=${this.getDefaultTab()}`} />
            ) : (
              ""
            )}
          </div>
          <InvitePartnerModal
            invitePartnerModalToggle={this.invitePartnerModalToggle}
            isInvitePartnerModalOpen={this.state.isInvitePartnerModalOpen}
            partnersSort={partnersSort}
            partnersSortDir={partnersSortDir}
            setPartnerUserInvitePopUp={this.setPartnerUserInvitePopUp}
            setExistingPartnerId={this.setExistingPartnerId}
            setCurrentEmail={this.setCurrentEmail}
            currentPage={currentPage}
            triggerPartnerType={this.triggerPartnerType}
          />
          <PartnerInvitePopUp
            resetPartnerUserInvitePopUp={this.resetPartnerUserInvitePopUp}
            isOpen={this.state.isOpen}
            partnerMenberInvite={this.partnerMenberInvite}
          />

          <Nav tabs className="admin-tabs mb-0">
            {(isSuperAdmin(this.props.loggedInUser.roleId) ||
              isCompanyAdmin(this.props.loggedInUser.roleId) ||
              isCompanyManager(this.props.loggedInUser.roleId)) && (
              <>
                {enablePartnersPrograms === "true" && (
                  <NavItem>
                    <NavLink
                      className={classnames({ active: isPartnerPrograms })}
                      onClick={() => {
                        this.toggle(TAB_PARTNER_PROGRAMS);
                        this._handleStatusChange(TAB_PARTNER_PROGRAMS);
                      }}
                    >
                      Partners Programs
                      <CountBadge
                        count={partnerTypeCount}
                        isActive={classnames({
                          active: isPartnerPrograms
                        })}
                      />
                    </NavLink>
                  </NavItem>
                )}

                <NavItem>
                  <NavLink
                    className={classnames({ active: isAllPartners })}
                    onClick={() => {
                      this.toggle(TAB_ALL_PARTNERS);
                      this._handleStatusChange(TAB_ALL_PARTNERS);
                    }}
                  >
                    All Partners
                    <CountBadge
                      count={allPartnersRequestCount}
                      isActive={classnames({
                        active: isAllPartners
                      })}
                    />
                  </NavLink>
                </NavItem>
              </>
            )}
          </Nav>

          {/* Tab Content */}
          <TabContent activeTab={activeTab}>
            {/*Partners programs Tab*/}
            <TabPane tabId={TAB_PARTNER_PROGRAMS}>
              {enablePartnersPrograms === "true" && (
                <PartnerPrograms
                  history={this.props.history}
                  actions={this.props.actions}
                  toggle={this.toggle}
                  handleStatusChange={this._handleStatusChange}
                  setPartnerTypeCount={this.setPartnerTypeCount}
                  partnerTypeTrigger={partnerTypeTrigger}
                />
              )}
            </TabPane>

            {/* All Partners Tab */}
            <TabPane tabId={TAB_ALL_PARTNERS}>
              {this.renderPartnerTableByStatus(TAB_ALL_PARTNERS, {
                section:
                  isCompanyManager(this.props.loggedInUser.roleId) ||
                  isCompanyAdmin(this.props.loggedInUser.roleId)
                    ? ""
                    : enablePartnersPrograms === "true" &&
                      activeTab === TAB_PARTNER_PROGRAMS
                    ? TAB_PARTNER_PROGRAMS
                    : TAB_ALL_PARTNERS,
                selectedTier,
                selectedType,
                selectedRegion
              })}
            </TabPane>
          </TabContent>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const reduxTable = state.table;

  const roleId = state.user ? state.user.roleId : "";

  // Get pending partners count
  const pendingPartnersRequestCount =
    reduxTable[TAB_PENDING_PARTNERS] &&
    !reduxTable[TAB_PENDING_PARTNERS].isFetching
      ? reduxTable[TAB_PENDING_PARTNERS].totalCount
      : 0;

  // Get approved partners count
  const approvedPartnersRequestCount =
    reduxTable[TAB_APPROVED_PARTNERS] &&
    !reduxTable[TAB_APPROVED_PARTNERS].isFetching
      ? reduxTable[TAB_APPROVED_PARTNERS].totalCount
      : 0;

  // Get all partners count
  const allPartnersRequestCount =
    reduxTable[TAB_ALL_PARTNERS] && !reduxTable[TAB_ALL_PARTNERS].isFetching
      ? reduxTable[TAB_ALL_PARTNERS].totalCount
      : 0;

  //get current page
  const currentPage =
    reduxTable[TAB_ALL_PARTNERS] && !reduxTable[TAB_ALL_PARTNERS].isFetching
      ? reduxTable[TAB_ALL_PARTNERS].currentPage
      : 1;

  return {
    pendingPartnersRequestCount,
    approvedPartnersRequestCount,
    allPartnersRequestCount,
    currentPage,
    roleId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators(
      { fetchList, updatePartners, deletePartners },
      dispatch
    )
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerList);
