import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  DropdownToggle,
  DropdownMenu,
  ButtonDropdown,
  Input
} from "reactstrap";
import { ChevronDown } from "../../assets/img/icons";

class CheckboxDropdownSelect extends Component {
  constructor(props) {
    super(props);
    this.state = {
      checkedItems: this.props.checkedItems || {},
      didMount: false,
      dropdownOpen: false,
      checkedItemText: ""
    };
  }

  componentDidMount() {
    let checkedItems = this.props.checkedItems;
    if (checkedItems) {
      this.setState({ checkedItems });
    }
  }
  componentDidUpdate(prevProps) {
    let checkedItems = this.props.checkedItems;
    if (
      prevProps &&
      prevProps.checkedItems &&
      prevProps.checkedItems !== checkedItems
    ) {
      this.setState({ checkedItems });
      this.getCheckedItemValue();
    }
  }

  getCheckedItemValue = () => {
    let checkedItems = this.state.checkedItems
      ? this.state.checkedItems
      : this.props.checkedItems;

    let objectKeys = new Array();

    if (checkedItems) {
      for (const property in checkedItems) {
        console.log(`${property}: ${checkedItems[property]}`);
        if (checkedItems[property]) {
          objectKeys.push(property);
        }
      }
      if (objectKeys && objectKeys.length > 0) {
        this.setState({ checkedItemText: objectKeys.toString() });
      } else {
        this.setState({ checkedItemText: "" });
      }
    }
  };

  toggle = () =>
    this.setState(prevState => ({ dropdownOpen: !prevState.dropdownOpen }));

  handleChange = event => {
    this.setState(
      {
        checkedItems: {
          ...this.state.checkedItems,
          [event.target.name]: event.target.checked
        }
      },
      () => {
        if (this.props.handleChange) {
          this.props.handleChange(this.state.checkedItems);
        }
        this.getCheckedItemValue(this.state.checkedItems);
      }
    );
  };

  filterCheckedItems = () =>
    Object.keys(this.state.checkedItems).filter(
      item => this.state.checkedItems[item] === true
    );

  render() {
    const {
      buttonLabel,
      dropdownLabel,
      dropdownLinks,
      menuPosition,
      width,
      dropdownWidth,
      buttonWidth,
      color,
      hideCaret,
      hideChevron,
      borderBottom,
      changeCheckBoxColor,
      prefillSelectedValue
    } = this.props;

    const { dropdownOpen, checkedItems, checkedItemText } = this.state;

    return (
      <div className="dropdown-wrapper w-100 align-items-center justify-content-end">
        {dropdownLabel && (
          <p className="font-weight-bold mb-0 mr-2">{dropdownLabel}</p>
        )}
        <ButtonDropdown
          style={{ minWidth: buttonWidth ? buttonWidth : "100%" }}
          isOpen={dropdownOpen}
          toggle={this.toggle}
        >
          <DropdownToggle
            color={color}
            className={`dropdown-toggle  ${hideCaret ? "hide-caret" : ""}`}
          >
            <div
              className="d-flex justify-content-between"
              style={{ width: dropdownWidth ? dropdownWidth : "auto" }}
            >
              <div className="text-truncate">
                {prefillSelectedValue && checkedItemText
                  ? checkedItemText
                  : buttonLabel}
              </div>
              <div>{!hideChevron && <ChevronDown />}</div>
            </div>
          </DropdownToggle>
          <DropdownMenu right={menuPosition}>
            {dropdownLinks.length > 0 &&
              dropdownLinks.map((listItem, index) => (
                <div
                  className={
                    borderBottom && index == 0
                      ? "checkbox-wrapper border-bottom"
                      : "checkbox-wrapper "
                  }
                  key={listItem.value}
                >
                  <label
                    className="pl-1"
                    style={{ width: width ? width : "auto" }}
                  >
                    <Input
                      type="checkbox"
                      name={listItem.value}
                      id={`${listItem.value}`}
                      onChange={e => this.handleChange(e)}
                      checked={checkedItems[listItem.value]}
                      value={checkedItems[listItem.value]}
                    />
                    <span
                      id={`${listItem.value}Checkbox`}
                      className={`${
                        changeCheckBoxColor
                          ? "checkbox-placeholder-black"
                          : "checkbox-placeholder"
                      } ${checkedItems[listItem.value] && "active"}`}
                    />
                    <div className="pt-1">{listItem.label}</div>
                  </label>
                </div>
              ))}
          </DropdownMenu>
        </ButtonDropdown>
      </div>
    );
  }
}

CheckboxDropdownSelect.defaultProps = {
  buttonLabel: "Button Label",
  color: "",
  hideCaret: false,
  dropdownLinks: [
    { label: "Link 1", value: "link1" },
    { label: "Link 2", value: "link2" },
    { label: "Link 3", value: "link3" }
  ],
  menuPosition: true
};

CheckboxDropdownSelect.propTypes = {
  buttonLabel: PropTypes.string,
  color: PropTypes.string,
  hideCaret: PropTypes.bool,
  dropdownLinks: PropTypes.array,
  menuPosition: PropTypes.bool,
  onClick: PropTypes.func
};

export default CheckboxDropdownSelect;
