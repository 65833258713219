import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownItem, Label } from "reactstrap";

// 404 Page
import Page404 from "../../../../Page404";
// Components
import Form from "../../../../../components/base/Form";
import DeleteButton from "../../../../../components/base/DeleteButton";
import CancelButton from "../../../../../components/base/CancelButton";
import SaveButton from "../../../../../components/base/SaveButton";
import LandingPageBlockFormFields from "./LandingPageBlockFormFields";
import PlaybookCard from "../../../../../components/PlaybookCard";
import Spinner from "../../../../../components/base/Spinner";

// Configs
import { endpoints } from "../../../../../configs";

// API call
import { apiClient } from "../../../../../apiClient";

// Constants
import {
  LANDING_PAGE_BLOCK_PARTNER_SPECIFY_ALL,
  LANDING_PAGE_BLOCK_PARTNER_SPECIFY_ALL_VALUE,
  LANDING_PAGE_BLOCK_PARTNER_SPECIFY_SELECTED,
  LANDING_PAGE_BLOCK_PARTNER_SPECIFY_SELECTED_VALUE,
  LANDING_PAGE_BLOCK_PARTNER_SPECIFY_FILTERED_VALUE,
  LANDING_PAGE_BLOCK_PARTNER_SPECIFY_FILTERED,
  LANDING_PAGE_BLOCK_TYPE_EXPERT,
  LANDING_PAGE_BLOCK_TYPE_PARTNER,
  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK,
  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID,
  LANDING_PAGE_BLOCK_EXPERT_SIGNUP_CTA_VALUE,
  LANDING_PAGE_BLOCK_CUSTOM_CTA_VALUE,
  LANDING_PAGE_BLOCK_PARTNER_SIGNUP_CTA_VALUE,
  LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT,
  LANDING_PAGE_FILTER_PARTNER_TYPE_VALUE,
  LANDING_PAGE_FILTER_PARTNER_TIRE_VALUE,
  LANDING_PAGE_FILTER_PARTNER_TIRE,
  LANDING_PAGE_FILTER_PARTNER_TYPE,
  LANDING_PAGE_FEATURED_COLLECTION
} from "../../../../../landingPage/Constants";
import { PLAYBOOK_STATUS_ACTIVE } from "../../../../../playbookStatus/Constants";
import { EXPERT_STATUS_APPROVED } from "../../../../../expertStatus/Constants";
import { APP_STATUS_APPROVED } from "../../../../marketplaceSourcing/Constants";

// Actions
import {
  updateLandingPageBlock,
  deleteLandingPageBlock,
  deleteLandingPageFeatureCollection
} from "../../../../../actions/landingPageBlock";
import ExpertGrid from "../../../../../components/ExpertGrid";
import { PARTNER_STATUS_APPROVED } from "../../../../../partnerStatus/Constants";
import PartnerGrid from "../../../../../components/PartnerGrid";
import {
  GENERAL_GENERAL_SUB_TAB,
  MARKETPLACES_SUB_TAB,
  TAB_GENERAL,
  TAB_MARKETPLACE
} from "../../../../../setting/Constants";
import { ChevronRight } from "../../../../../assets/img/icons";
import { TAG_TYPE_CATEGORY_MARKETPLACE } from "../../../../../tagType/Constants";
import { isBadRequest } from "../../../../../common/http";

import MarketplaceAppCard from "../../../../../components/MarketplaceAppCard";
import AddButton from "../../../../../components/base/AddButton";
import AddFeatureCollectionModal from "../../../../landingPages/AddFeatureCollectionModal";
import ReduxTable, {
  ReduxColumn
} from "../../../../../components/base/ReduxTable";
import MoreDropdown from "../../../../../components/base/MoreDropdown";
import DeleteModal from "../../../../../components/base/DeleteModal";
import { getUrlParameter } from "../../../../../lib/helper";
import { fetchList } from "../../../../../actions/table";
import Toast from "../../../../../components/base/Toast";

class LandingPageBlocksForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      landingPageBlockDetails: "",
      loading: false,
      backgroundColor: "",
      textColor: "",
      isOpen: "",
      logoLink: "",
      logoImageDesktopUrlPreview: "",
      logoImageDesktopUrl: "",
      logoImageMobileUrlPreview: "",
      logoImageMobileUrl: "",
      backgroundImageDesktopUrl: "",
      backgroundImageMobileUrl: "",
      backgroundImageDesktopUrlPreview: "",
      backgroundImageMobileUrlPreview: "",
      backgroundImageDesktopUrl1: "",
      backgroundImageMobileUrl1: "",
      backgroundImageDesktopUrl1Preview: "",
      backgroundImageMobileUrl1Preview: "",
      isParnerLoading: false,
      partnerList: [],
      selectedPartners: [],
      partnerIds: [],
      initialPartnerIdsLength: 0,
      isExpertLoading: false,
      expertList: [],
      expertIds: [],
      isPlaybookLoading: false,
      playbookList: [],
      playbookIds: [],
      isInvalidRequest: false,
      backgroundColor1: "",
      textColor1: "",
      selectedPlaybooks: [],
      selectedExperts: [],
      initialExpertIdsLength: 0,
      partnerSpecification: LANDING_PAGE_BLOCK_PARTNER_SPECIFY_SELECTED_VALUE,
      tagTypeList: [],
      partnerTypeList: [],
      partnerTypeTags: [],
      partnerTypeIds: [],
      selectedSignUpCTA: "",
      isAppListLoading: false,
      appIds: [],
      selectedApps: [],
      isModelOpen: false,
      row: "",
      deleteModel: false
    };

    this.handleBackgroundColor = this.handleBackgroundColor.bind(this);
    this.handleTextColor = this.handleTextColor.bind(this);
    this.handleBackgroundColor1 = this.handleBackgroundColor1.bind(this);
    this.handleTextColor1 = this.handleTextColor1.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handlePartners = this.handlePartners.bind(this);
    this.handleExperts = this.handleExperts.bind(this);
    this.handlePlaybooks = this.handlePlaybooks.bind(this);
    this.handleContrastColorSelect = this.handleContrastColorSelect.bind(this);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);
    this._getPartnerTypeTagValues();
    this.getMarketplaceTagValues();
    this._getLandingPageDetails();

    this._getPartnerList();
    this._getExpertsList();
    this._getPlaybooksList();
    this._getAppsList();
  };

  // Update Landing page blocks
  _submit = values => {
    // Save landing page block form
    this.landingPageBlockUpdate(this._toArray(values));
  };

  // Handle partners
  handlePartners(value) {
    const partnerIds = this.state.partnerIds.slice();
    if (partnerIds.indexOf(value) > -1) {
      this.setState({ partnerIds: partnerIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ partnerIds: partnerIds.concat(value) });
    }
  }

  handleRemovePartners = value => {
    const partnerIds = this.state.partnerIds.slice();
    if (partnerIds.indexOf(value) > -1) {
      this.setState(
        { partnerIds: partnerIds.filter(el => el !== value) },
        () => {
          this.setState({ selectedPartners: this.state.partnerIds });
        }
      );
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ partnerIds: partnerIds.concat(value) });
    }
  };

  handleSelectedPartners = () => {
    this.toggle();
    const partnerIds = this.state.partnerIds;
    const selectedPartners = partnerIds.length > 0 ? partnerIds : [];
    this.setState({ selectedPartners });
  };

  // Handle experts
  handleExperts(value) {
    const expertIds = this.state.expertIds.slice();
    if (expertIds.indexOf(value) > -1) {
      this.setState({ expertIds: expertIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ expertIds: expertIds.concat(value) });
    }
  }
  // Handle experts
  handleRemoveExperts = value => {
    const expertIds = this.state.expertIds.slice();
    if (expertIds.indexOf(value) > -1) {
      this.setState({ expertIds: expertIds.filter(el => el !== value) }, () => {
        this.setState({ selectedExperts: this.state.expertIds });
      });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ expertIds: expertIds.concat(value) });
    }
  };

  handleSelectedExperts = () => {
    this.toggle();
    const expertIds = this.state.expertIds;
    const selectedExperts = expertIds.length > 0 ? expertIds : [];
    this.setState({ selectedExperts });
  };

  // Handle playbooks
  handlePlaybooks(value) {
    const playbookIds = this.state.playbookIds.slice();
    if (playbookIds.indexOf(value) > -1) {
      this.setState({ playbookIds: playbookIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ playbookIds: playbookIds.concat(value) });
    }
  }

  handleAppList = value => {
    const appIds = this.state.appIds ? this.state.appIds.slice() : [];
    if (appIds.indexOf(value) > -1) {
      this.setState({ appIds: appIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ appIds: appIds.concat(value) });
    }
  };

  // Handle remove playbooks
  handleRemovePlaybooks = value => {
    const playbookIds = this.state.playbookIds.slice();
    if (playbookIds.indexOf(value) > -1) {
      this.setState(
        { playbookIds: playbookIds.filter(el => el !== value) },
        () => {
          this.setState({ selectedPlaybooks: this.state.playbookIds });
        }
      );
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ playbookIds: playbookIds.concat(value) });
    }
  };

  handleRemoveApps = value => {
    const appIds = this.state.appIds.slice();
    if (appIds.indexOf(value) > -1) {
      this.setState(
        { appIds: appIds && appIds.filter(el => el !== value) },
        () => {
          this.setState({ selectedApps: this.state.appIds });
        }
      );
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ appIds: appIds.concat(value) });
    }
  };

  handleSelectedPlaybooks = () => {
    this.toggle();
    const playbookIds = this.state.playbookIds;
    const selectedPlaybooks = playbookIds.length > 0 ? playbookIds : [];
    this.setState({ selectedPlaybooks });
  };

  handleSelectedApps = () => {
    this.toggle();
    const appIds = this.state.appIds;
    const selectedApps = appIds.length > 0 ? appIds : [];
    this.setState({ selectedApps });
  };

  // To Array
  _toArray(values) {
    const { selectedTagTypes, partnerSpecification } = this.state;

    // landing Filter Tag Types
    let landingFilterTagTypes = [];
    selectedTagTypes &&
      selectedTagTypes.forEach(selectedTagType => {
        if (
          selectedTagType.tag_type_id &&
          selectedTagType.display_name &&
          selectedTagType.multi_select !== null &&
          selectedTagType.order >= 0
        ) {
          landingFilterTagTypes.push({
            tag_type_id: selectedTagType.tag_type_id,
            display_name: selectedTagType.display_name,
            multi_select: selectedTagType.multi_select,
            order: selectedTagType.order
          });
        }
      });
    landingFilterTagTypes = JSON.stringify(landingFilterTagTypes);

    const data = new FormData();
    data.append(
      "appIds",
      this.state.appIds ? this.state.appIds : values.appIds
    );
    data.append(
      "backgroundColor",
      this.state.backgroundColor ? this.state.backgroundColor : ""
    );
    data.append("title", values.title ? values.title : "");
    data.append("link", values.link ? values.link : "");
    data.append("subtitle", values.subtitle ? values.subtitle : "");
    data.append("textColor", this.state.textColor ? this.state.textColor : "");
    data.append("buttonLabel", values.buttonLabel ? values.buttonLabel : "");
    data.append(
      "selectedSignUpCTA",
      this.state.selectedSignUpCTA ? this.state.selectedSignUpCTA : ""
    );
    data.append(
      "buttonLink",
      values && values.buttonLink ? values.buttonLink : ""
    );
    data.append(
      "partnerIds",
      partnerSpecification === LANDING_PAGE_BLOCK_PARTNER_SPECIFY_ALL_VALUE ||
        partnerSpecification ===
          LANDING_PAGE_BLOCK_PARTNER_SPECIFY_FILTERED_VALUE
        ? []
        : this.state.partnerIds
        ? this.state.partnerIds
        : values.partnerIds
    );
    data.append(
      "expertIds",
      this.state.expertIds ? this.state.expertIds : values.expertIds
    );
    data.append(
      "playbookIds",
      this.state.playbookIds ? this.state.playbookIds : values.playbookIds
    );
    data.append(
      "backgroundImageDesktop",
      this.state.backgroundImageDesktopUrl
        ? this.state.backgroundImageDesktopUrl
        : ""
    );
    data.append(
      "backgroundImageMobile",
      this.state.backgroundImageMobileUrl
        ? this.state.backgroundImageMobileUrl
        : ""
    );
    data.append(
      "backgroundImageMobileFile",
      this.state.backgroundImageMobileUrlPreview
        ? this.state.backgroundImageMobileUrlPreview
        : ""
    );
    data.append(
      "backgroundImageDesktop1",
      this.state.backgroundImageDesktopUrl1
        ? this.state.backgroundImageDesktopUrl1
        : ""
    );
    data.append(
      "backgroundImageMobile1",
      this.state.backgroundImageMobileUrl1
        ? this.state.backgroundImageMobileUrl1
        : ""
    );
    data.append(
      "backgroundImageMobileFile1",
      this.state.backgroundImageMobileUrl1Preview
        ? this.state.backgroundImageMobileUrl1Preview
        : ""
    );
    data.append("logoLink", values.logoLink ? values.logoLink : "");
    data.append(
      "logoImageDesktop",
      this.state.logoImageDesktopUrl ? this.state.logoImageDesktopUrl : ""
    );
    data.append(
      "logoImageMobile",
      this.state.logoImageMobileUrl ? this.state.logoImageMobileUrl : ""
    );
    data.append(
      "logoImageMobileFile",
      this.state.logoImageMobileUrlPreview
        ? this.state.logoImageMobileUrlPreview
        : ""
    );
    data.append("title1", values.title1 ? values.title1 : "");
    data.append("link1", values.link1 ? values.link1 : "");
    data.append("subtitle1", values.subtitle1 ? values.subtitle1 : "");
    data.append(
      "backgroundColor1",
      this.state.backgroundColor1 ? this.state.backgroundColor1 : ""
    );
    data.append(
      "textColor1",
      this.state.textColor1 ? this.state.textColor1 : ""
    );
    data.append("content", values.content ? values.content : "");
    data.append("filterTagTypes", landingFilterTagTypes);
    data.append(
      "partnerSpecification",
      partnerSpecification ? partnerSpecification : ""
    );
    data.append(
      "partnerType",
      partnerSpecification === LANDING_PAGE_BLOCK_PARTNER_SPECIFY_FILTERED_VALUE
        ? this.state.partnerTypeIds
        : []
    );
    data.append("show_filter", values.show_filter);
    data.append("show_in_single_line", values.show_in_single_line);
    data.append("show_partner_tier", values.show_partner_tier);
    data.append("show_partner_type", values.show_partner_type);

    return data;
  }

  // Landing page API Call
  landingPageBlockUpdate(data) {
    const { landingPageId } = this.state.landingPageBlockDetails;
    this.props.actions.updateLandingPageBlock(
      this.props.sectionId,
      data,
      () => {}
    );
  }
  // Get Marketplace Tag Values
  getMarketplaceTagValues = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().tagTypeAPI}?category=${TAG_TYPE_CATEGORY_MARKETPLACE}`
      );
      const results = response.data.data;
      // Check the tag type skills
      const tagTypeList = [];
      if (results && results.length > 0) {
        await results.forEach(async tagType => {
          const tagList = [];
          if (tagType.tags && tagType.tags.length > 0) {
            await tagType.tags.forEach(tag => {
              tagList.push({
                id: tag.id,
                name: tag.name,
                value: tag.name,
                label: tag.name,
                tagTypeId: tagType.id
              });
            });
          }
          tagTypeList.push({
            id: tagType.id,
            name: tagType.name,
            value: tagType.id,
            label: tagType.name,
            permission: tagType.permission,
            tags: tagList
          });
        });
      }
      const PartnerTypeData = [
        {
          id: LANDING_PAGE_FILTER_PARTNER_TYPE_VALUE,
          label: LANDING_PAGE_FILTER_PARTNER_TYPE
        },
        {
          id: LANDING_PAGE_FILTER_PARTNER_TIRE_VALUE,
          label: LANDING_PAGE_FILTER_PARTNER_TIRE
        }
      ];
      await PartnerTypeData.forEach(type => {
        tagTypeList.push({
          id: type.id,
          name: type.label,
          value: type.id,
          label: type.label
        });
      });

      this.setState({
        tagTypeList: tagTypeList
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  // Get PartnerType Tag Values
  _getPartnerTypeTagValues = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().partnerTypeAPI}/search`
      );
      const results = response.data.data;

      if (results && results.length > 0) {
        const types = [];
        results
          .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
          .forEach(tag => {
            types.push(tag.name);
          });
        this.setState({ partnerTypeList: types, partnerTypeTags: results });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  // Get TagName By partner Type id
  getTagNameByPartnerTypeId = value => {
    const { partnerTypeTags } = this.state;
    const partnerType = partnerTypeTags.filter(
      partnerTypeTag => parseInt(partnerTypeTag.id) === parseInt(value)
    );
    return partnerType ? partnerType[0].name : "";
  };

  // Get partner Type checkbox options
  getCheckBoxOptions = values => {
    const partnerTypeName = [],
      partnerTypeIds = (values && values.split(",")) || values;

    partnerTypeIds &&
      partnerTypeIds.forEach(value => {
        partnerTypeName.push(this.getTagNameByPartnerTypeId(value));
      });
    return partnerTypeName;
  };

  // handle Partner Type
  handlePartnerType = values => {
    const { partnerTypeTags } = this.state;
    let selectedValues = [];
    const selectedTags = [];
    if (values && values.length > 0) {
      values.forEach(value => {
        if (partnerTypeTags && partnerTypeTags.length > 0)
          partnerTypeTags.forEach(tag => {
            if (value === tag.name) {
              selectedTags.push(tag.id);
            }
          });
        selectedValues = selectedTags;
      });
    }
    this.setState({ partnerTypeIds: selectedValues.toString() });
    this._getPartnerList(selectedValues);
  };

  // Landing page api call by id
  _getLandingPageDetails = async () => {
    return await apiClient
      .get(`${endpoints().landingPageBlocksAPI}/${this.props.sectionId}`)
      .then(response => {
        const data = response.data;
        const partnerIds = data.partnerIds ? data.partnerIds.split(",") : [];
        const expertIds = data.expertIds ? data.expertIds.split(",") : [];
        const playbookIds = data.playbookIds ? data.playbookIds.split(",") : [];
        const MarketplaceAppIds = data.appIds ? data.appIds.split(",") : [];
        const partners = [];
        partnerIds.forEach(partnerId => {
          partners.push(parseInt(partnerId, 10));
        });

        const experts = [];
        expertIds.forEach(expertId => {
          experts.push(parseInt(expertId, 10));
        });

        const playbooks = [];
        playbookIds.forEach(playbook => {
          playbooks.push(parseInt(playbook, 10));
        });

        const appIds = [];
        if (MarketplaceAppIds) {
          MarketplaceAppIds.forEach(app => {
            appIds.push(parseInt(app, 10));
          });
        }

        this.setState(
          {
            landingPageId: data.landingPageId,
            landingPageBlockDetails: data,
            selectedSignUpCTA: data.selectedSignUpCTA,
            backgroundColor: data.backgroundColor,
            textColor: data.textColor,
            backgroundColor1: data.backgroundColor1,
            textColor1: data.textColor1,
            backgroundImageDesktopUrl: data.backgroundImageDesktopUrl,
            backgroundImageDesktopUrl1: data.backgroundImageDesktopUrl1,
            backgroundImageMobileUrl: data.backgroundImageMobileUrl,
            backgroundImageMobileUrl1: data.backgroundImageMobileUrl1,
            logoLink: data.logoLink,
            logoImageDesktopUrl: data.logoImageDesktopUrl,
            logoImageMobileUrl: data.logoImageMobileUrl,
            loading: true,
            partnerIds: partners,
            selectedPartners: partners,
            expertIds: experts,
            selectedExperts: experts,
            playbookIds: playbooks,
            selectedPlaybooks: playbooks,
            tagTypes: data.landingBlockTagType,
            partnerSpecification:
              data.partnerSpecification || this.state.partnerSpecification,
            partnerTypeIds: data.partnerTypeIds || "",
            appIds: appIds,
            selectedApps: appIds
          },
          () => {
            if (
              this.state.partnerSpecification ===
              LANDING_PAGE_BLOCK_PARTNER_SPECIFY_FILTERED_VALUE
            ) {
              this._getPartnerList(this.state.partnerTypeIds);
            }

            this.setState({
              initialExpertIdsLength: this.state.expertIds.length,
              initialPartnerIdsLength: this.state.partnerIds.length
            });
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Get Partner List
  _getPartnerList(partnerType) {
    let param = `?marketplaceStatus=${PARTNER_STATUS_APPROVED}`;
    if (
      partnerType &&
      partnerType.length > 0 &&
      this.state.partnerSpecification ===
        LANDING_PAGE_BLOCK_PARTNER_SPECIFY_FILTERED_VALUE
    ) {
      param = param + `&partnerType=${partnerType}`;
    }
    this.setState({ isParnerLoading: true }, () => {
      apiClient
        .get(`${endpoints().partnerAPI}/search${param}`)
        .then(response => {
          const partnerList = response.data.data;

          let allPartnerIds = [];
          if (partnerList && partnerList.length > 0)
            partnerList.forEach(partner => {
              allPartnerIds.push(partner.id);
            });

          this.setState({
            isParnerLoading: false,
            partnerList: partnerList ? partnerList : this.state.partnerList,
            allPartnerIds
          });
        });
    });
  }

  // Get Expert List
  _getExpertsList() {
    this.setState({ isExpertLoading: true }, () => {
      apiClient
        .get(
          `${
            endpoints().expertAPI
          }/search?marketplaceStatus=${EXPERT_STATUS_APPROVED}`
        )
        .then(response => {
          const expertList = response.data.data;

          this.setState({
            isExpertLoading: false,
            expertList: expertList ? expertList : this.state.expertList
          });
        });
    });
  }

  // Get Playbook List
  _getPlaybooksList() {
    this.setState({ isPlaybookLoading: true }, () => {
      apiClient
        .get(`${endpoints().playbookAPI}?status=${PLAYBOOK_STATUS_ACTIVE}`)
        .then(response => {
          const playbookList = response.data.data;

          this.setState({
            isPlaybookLoading: false,
            playbookList: playbookList ? playbookList : this.state.playbookList
          });
        });
    });
  }

  // Get Playbook List
  _getAppsList() {
    this.setState({ isAppListLoading: true }, () => {
      apiClient
        .get(`${endpoints().appAPI}/search?status=${APP_STATUS_APPROVED}`)
        .then(response => {
          const appsList = response.data.data;

          this.setState({
            isAppListLoading: false,
            appsList: appsList ? appsList : this.state.appsList
          });
        });
    });
  }

  // Landing page block delete api call
  handleDelete = () => {
    const { landingPageId } = this.state.landingPageBlockDetails;
    this.props.actions.deleteLandingPageBlock(
      this.props.match.params.subsection,
      landingPageId,
      () => {
        this.props.history.push(
          `/admin-settings/${TAB_MARKETPLACE}/${MARKETPLACES_SUB_TAB}/${this.state.landingPageId}`
        );
        this.props.getLandingBlocksDetails();
      }
    );
  };
  handleCollectionDelete = id => {
    try {
      let landingPageBlockId =
        this.state.landingPageBlockDetails &&
        this.state.landingPageBlockDetails.id;

      this.props.actions.deleteLandingPageFeatureCollection(
        id,
        {
          pagination: true,
          landingPageBlockId: landingPageBlockId,
          sort: getUrlParameter("sort"),
          sortDir: getUrlParameter("sortDir")
        },
        () => {}
      );
    } catch (err) {
      console.log(err);
    }
  };

  // Set image preview in state
  setPreviewImage = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [`${state}Preview`]: reader.result });
    };
  };

  // Update image uploads
  handleImageChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files ? e.target.files[0] : "";
      this.setPreviewImage(file, e.target.name);
      this.setState({ [`${e.target.name}`]: file });
    }
  }

  // Remove image
  handleImageRemove = e => {
    this.setState({
      [`${e.target.id}`]: "",
      [`${e.target.id}Preview`]: ""
    });
  };

  // Handle Background Color
  handleBackgroundColor(e) {
    this.setState({ backgroundColor: e.target.id });
  }

  // Handle Text Color
  handleTextColor(e) {
    this.setState({ textColor: e.target.id });
  }

  // Handle Background Color1
  handleBackgroundColor1(e) {
    this.setState({ backgroundColor1: e.target.id });
  }

  // Handle Text Color1
  handleTextColor1(e) {
    this.setState({ textColor1: e.target.id });
  }

  // Handle Text Color High Contrast Version

  handleContrastColorSelect(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // Handle Modal Click
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
      initialExpertIdsLength: this.state.expertIds.length,
      initialPartnerIdsLength: this.state.partnerIds.length
    });
  }

  _toggle = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen, row: "" });
  };
  // Handle Modal Click
  toggleModalClose() {
    this.setState({
      expertIds: this.state.selectedExperts,
      playbookIds: this.state.selectedPlaybooks,
      appIds: this.state.selectedApps,
      isOpen: !this.state.isOpen
    });
  }

  // Handle Specify Partners
  handleSpecifyPartners = specification => {
    if (specification !== undefined) {
      this.setState(
        {
          partnerSpecification:
            specification === LANDING_PAGE_BLOCK_PARTNER_SPECIFY_ALL
              ? LANDING_PAGE_BLOCK_PARTNER_SPECIFY_ALL_VALUE
              : specification === LANDING_PAGE_BLOCK_PARTNER_SPECIFY_SELECTED
              ? LANDING_PAGE_BLOCK_PARTNER_SPECIFY_SELECTED_VALUE
              : specification === LANDING_PAGE_BLOCK_PARTNER_SPECIFY_FILTERED
              ? LANDING_PAGE_BLOCK_PARTNER_SPECIFY_FILTERED_VALUE
              : ""
        },
        () => {
          const { partnerSpecification, partnerTypeIds } = this.state;
          if (
            partnerSpecification ===
            LANDING_PAGE_BLOCK_PARTNER_SPECIFY_ALL_VALUE
          )
            this._getPartnerList();

          if (
            partnerSpecification ===
            LANDING_PAGE_BLOCK_PARTNER_SPECIFY_FILTERED_VALUE
          )
            this._getPartnerList(partnerTypeIds);
        }
      );
    }
  };

  // Handle Signup CTA
  handleSignupCTA = selectedSignUpCTAObject => {
    if (selectedSignUpCTAObject && selectedSignUpCTAObject.value) {
      this.setState({
        selectedSignUpCTA: selectedSignUpCTAObject.value
      });
    }
  };

  playbookstyle = {
    marginLeft: "2rem",
    marginRight: "25px",
    marginBottom: "40px"
  };

  // Get extras values
  handleSelectedTagTypeValues = values => {
    this.setState({ selectedTagTypes: values });
  };

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return <Spinner />;
    }

    const currentUrl = window.location.pathname + window.location.search;
    const {
      landingPageBlockDetails,
      expertIds,
      isOpen,
      partnerList,
      selectedPartners,
      partnerIds,
      initialPartnerIdsLength,
      expertList,
      selectedExperts,
      playbookList,
      playbookIds,
      backgroundImageDesktopUrlPreview,
      backgroundImageMobileUrlPreview,
      selectedPlaybooks,
      initialExpertIdsLength,
      logoLink,
      logoImageDesktopUrlPreview,
      logoImageDesktopUrl,
      logoImageMobileUrlPreview,
      logoImageMobileUrl,
      backgroundImageDesktopUrl,
      backgroundImageMobileUrl,
      backgroundImageDesktopUrl1,
      backgroundImageMobileUrl1,
      backgroundImageDesktopUrl1Preview,
      backgroundImageMobileUrl1Preview,
      tagTypes,
      tagTypeList,
      allPartnerIds,
      partnerTypeList
    } = this.state;

    // Initialize form values
    const initialValues = {
      title: landingPageBlockDetails.title,
      subtitle: landingPageBlockDetails.subtitle,
      type: landingPageBlockDetails.type,
      sort: landingPageBlockDetails.sort,
      logoUrl: landingPageBlockDetails.logoUrl,
      backgroundImageDesktopUrl:
        landingPageBlockDetails.backgroundImageDesktopUrl,
      backgroundImageMobileUrl:
        landingPageBlockDetails.backgroundImageMobileUrl,
      buttonLabel: landingPageBlockDetails.buttonLabel,
      buttonLink: landingPageBlockDetails.buttonLink,
      playbookIds: landingPageBlockDetails.playbookIds,
      appIds: landingPageBlockDetails.appIds,
      partnerIds: landingPageBlockDetails.partnerIds,
      expertIds: landingPageBlockDetails.expertIds,
      link: landingPageBlockDetails.link,
      title1: landingPageBlockDetails.title1,
      subtitle1: landingPageBlockDetails.subtitle1,
      link1: landingPageBlockDetails.link1,
      backgroundImageDesktopUrl1:
        landingPageBlockDetails.backgroundImageDesktopUrl1,
      backgroundImageMobileUrl1:
        landingPageBlockDetails.backgroundImageMobileUrl1,
      logoLink: landingPageBlockDetails.logoLink,
      logoImageDesktopUrl: landingPageBlockDetails.logoImageDesktopUrl,
      logoImageMobileUrl: landingPageBlockDetails.logoImageMobileUrl,
      content: landingPageBlockDetails.content,
      signUpCTA: "",
      show_filter:
        landingPageBlockDetails && landingPageBlockDetails.show_filter == "true"
          ? true
          : false,
      show_in_single_line:
        landingPageBlockDetails &&
        landingPageBlockDetails.show_in_single_line == "true"
          ? true
          : false,
      show_partner_type:
        landingPageBlockDetails &&
        landingPageBlockDetails.show_partner_type == "true"
          ? true
          : false,
      show_partner_tier:
        landingPageBlockDetails &&
        landingPageBlockDetails.show_partner_tier == "true"
          ? true
          : false
    };

    const partnerSpecification = this.state.partnerSpecification;
    const checkboxFieldValue =
      this.state.partnerTypeTags &&
      this.state.partnerTypeTags.length > 0 &&
      this.getCheckBoxOptions(this.state.partnerTypeIds);
    const history = this.props.history;

    return (
      <div>
        <>
          <DeleteModal
            isOpen={this.state.deleteModel}
            toggle={() => {
              this.setState({ deleteModel: false });
            }}
            title="Delete"
            label={this.state.row.title}
            deleteFunction={() =>
              this.handleCollectionDelete(this.state.row.id)
            }
          />
          {/* Breadcrumb Start */}
          <div className="d-flex align-items-center pt-4 pb-3">
            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(
                  `/admin-settings/${TAB_GENERAL}/${GENERAL_GENERAL_SUB_TAB}`
                );
              }}
            >
              Admin Settings
            </span>
            <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
              <ChevronRight />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(
                  `/admin-settings/${TAB_MARKETPLACE}/${MARKETPLACES_SUB_TAB}`
                );
              }}
            >
              <span>{MARKETPLACES_SUB_TAB}</span>
            </span>

            <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
              <ChevronRight />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(
                  `/admin-settings/${TAB_MARKETPLACE}/${MARKETPLACES_SUB_TAB}/${this.props.marketplaceId}`
                );
              }}
            >
              <span>{this.props.marketplaceName}</span>
            </span>

            <span
              className=" text-dark font-weight-bold d-inline-flex pt-1"
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <span className="text-dark font-weight-bold">
              <span>{landingPageBlockDetails.title}</span>
            </span>
          </div>
          {/* Breadcrumb End */}

          <h5 className=" mt-3 mb-4 text-left font-weight-bold">
            {this.props.marketplaceName}
          </h5>
        </>
        <div className="section-title ">
          <span className="h1 d-block mb-0">
            {landingPageBlockDetails.title}
            <a
              href={`/marketplace/${
                this.props.slugUrl
              }?eid=${encodeURIComponent(landingPageBlockDetails.eid)}`}
              target="blank"
              className="pull-right mt-4"
              style={{ fontSize: "16px" }}
            >
              View Full Preview
            </a>
          </span>
        </div>
        {landingPageBlockDetails.type !== LANDING_PAGE_FEATURED_COLLECTION && (
          <Form
            initialValues={initialValues}
            onSubmit={values => {
              this._submit(values);
            }}
          >
            <div className="card bg-white mt-3 mb-3">
              <div className="card-body">
                <LandingPageBlockFormFields
                  type={landingPageBlockDetails.type}
                  sort={landingPageBlockDetails.sort}
                  handleImageChange={this.handleImageChange}
                  handleImageRemove={this.handleImageRemove}
                  handleBackgroundColor={this.handleBackgroundColor}
                  handleTextColor={this.handleTextColor}
                  handleBackgroundColor1={this.handleBackgroundColor1}
                  handleTextColor1={this.handleTextColor1}
                  toggle={this.toggle}
                  toggleModalClose={() => this.toggleModalClose()}
                  isOpen={isOpen}
                  partnerList={partnerList}
                  selectedPartners={selectedPartners}
                  partnerIds={partnerIds}
                  initialPartnerIdsLength={initialPartnerIdsLength}
                  handlePartners={this.handlePartners}
                  handleSelectedPartners={this.handleSelectedPartners}
                  expertList={expertList}
                  initialExpertIdsLength={initialExpertIdsLength}
                  expertIds={expertIds}
                  selectedExperts={selectedExperts}
                  handleExperts={this.handleExperts}
                  handleSelectedExperts={this.handleSelectedExperts}
                  playbookList={playbookList}
                  handlePlaybooks={this.handlePlaybooks}
                  handleSelectedPlaybooks={this.handleSelectedPlaybooks}
                  selectedPlaybooks={selectedPlaybooks}
                  playbookIds={playbookIds}
                  logoLink={logoLink}
                  logoImageDesktopUrlPreview={logoImageDesktopUrlPreview}
                  logoImageMobileUrlPreview={logoImageMobileUrlPreview}
                  logoImageDesktopUrl={logoImageDesktopUrl}
                  logoImageMobileUrl={logoImageMobileUrl}
                  backgroundImageDesktopUrlPreview={
                    backgroundImageDesktopUrlPreview
                  }
                  backgroundImageMobileUrlPreview={
                    backgroundImageMobileUrlPreview
                  }
                  backgroundImageDesktopUrl={backgroundImageDesktopUrl}
                  backgroundImageMobileUrl={backgroundImageMobileUrl}
                  backgroundImageDesktopUrl1Preview={
                    backgroundImageDesktopUrl1Preview
                  }
                  backgroundImageMobileUrl1Preview={
                    backgroundImageMobileUrl1Preview
                  }
                  backgroundImageDesktopUrl1={backgroundImageDesktopUrl1}
                  backgroundImageMobileUrl1={backgroundImageMobileUrl1}
                  textColor={
                    this.state.textColor
                      ? this.state.textColor
                      : landingPageBlockDetails.textColor
                  }
                  backgroundColor={
                    this.state.backgroundColor
                      ? this.state.backgroundColor
                      : landingPageBlockDetails.backgroundColor
                  }
                  textColor1={
                    this.state.textColor1
                      ? this.state.textColor1
                      : landingPageBlockDetails.textColor1
                  }
                  backgroundColor1={
                    this.state.backgroundColor1
                      ? this.state.backgroundColor1
                      : landingPageBlockDetails.backgroundColor1
                  }
                  handleContrastColorSelect={this.handleContrastColorSelect}
                  partnerSpecification={partnerSpecification}
                  handleSpecifyPartners={this.handleSpecifyPartners}
                  handleSignupCTA={this.handleSignupCTA}
                  selectedSignUpCTA={this.state.selectedSignUpCTA}
                  tagTypes={tagTypes}
                  handleSelectedTagTypeValues={this.handleSelectedTagTypeValues}
                  tagTypeList={tagTypeList}
                  partnerTypeList={partnerTypeList}
                  partnerTierList={[]}
                  handlePartnerType={this.handlePartnerType}
                  checkboxFieldValue={checkboxFieldValue && checkboxFieldValue}
                  appsList={this.state.appsList}
                  isAppListLoading={this.state.isAppListLoading}
                  handleSelectedApps={this.handleSelectedApps}
                  appIds={this.state.appIds}
                  selectedApps={this.state.selectedApps}
                  handleAppList={this.handleAppList}
                  detail={landingPageBlockDetails}
                  history={this.props.history}
                />

                {landingPageBlockDetails.type ===
                  LANDING_PAGE_BLOCK_TYPE_PARTNER && (
                  <>
                    <Label className="mb-4">Partner Collection Preview</Label>
                    <PartnerGrid
                      partnerList={partnerList}
                      showDeleteIcon={
                        partnerSpecification ===
                        LANDING_PAGE_BLOCK_PARTNER_SPECIFY_SELECTED_VALUE
                          ? true
                          : false
                      }
                      selectedPartners={
                        partnerSpecification ===
                        LANDING_PAGE_BLOCK_PARTNER_SPECIFY_SELECTED_VALUE
                          ? partnerIds
                          : allPartnerIds
                      }
                      handlePartners={this.handleRemovePartners}
                      size="col-12 col-md-12 col-lg-6 col-xl-6"
                    />
                  </>
                )}
                {landingPageBlockDetails.type ===
                  LANDING_PAGE_BLOCK_TYPE_EXPERT && (
                  <ExpertGrid
                    expertList={expertList}
                    showDeleteIcon={true}
                    selectedExperts={expertIds}
                    handleExperts={this.handleRemoveExperts}
                    size="m-3"
                    style={{ justifyContent: "space-between" }}
                  />
                )}
                {(landingPageBlockDetails.type ===
                  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK ||
                  landingPageBlockDetails.type ===
                    LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID) && (
                  <div className="row playbook-list-section">
                    {playbookList.length > 0 &&
                      playbookList.map(
                        playbook =>
                          playbookIds &&
                          playbookIds.indexOf(playbook.id) > -1 && (
                            <div style={this.playbookstyle}>
                              <PlaybookCard
                                data={playbook}
                                key={playbook.id}
                                link={`/playbook/${playbook.id}?redirect=${currentUrl}`}
                                openLinkInNewTab={true}
                                removePlaybook={true}
                                handlePlaybooks={this.handleRemovePlaybooks}
                                history={this.props.history}
                              />
                            </div>
                          )
                      )}
                  </div>
                )}

                <div className="row playbook-list-section">
                  {!this.state.isAppListLoading &&
                    this.state.appsList &&
                    this.state.appsList.length > 0 &&
                    this.state.appsList.map(
                      app =>
                        this.state.appIds &&
                        this.state.appIds.indexOf(app.id) > -1 && (
                          <div style={this.playbookstyle}>
                            <MarketplaceAppCard
                              data={app}
                              key={app.id}
                              link={`/app/${app.profile_url}`}
                              openLinkInNewTab={true}
                              removeApp={true}
                              handleAppList={this.handleRemoveApps}
                              history={this.props.history}
                            />
                          </div>
                        )
                    )}
                </div>
              </div>
            </div>

            <div className="mb-4">
              <DeleteButton
                label="Delete Section"
                onClick={this.handleDelete}
              />
              <div className="float-right">
                <CancelButton onClick={() => this.props.history.goBack()} />
                <SaveButton />
              </div>
            </div>
          </Form>
        )}

        {landingPageBlockDetails.type === LANDING_PAGE_FEATURED_COLLECTION && (
          <>
            <div className="d-flex justify-content-end mb-1">
              <AddFeatureCollectionModal
                isOpen={this.state.isModelOpen}
                toggle={this._toggle}
                detail={landingPageBlockDetails}
                collectionData={this.state.row}
              />
            </div>
            <div>
              <ReduxTable
                id="featuredCollection"
                apiURL={`${endpoints().marketplaceFeaturedCollectionAPI}`}
                onRowClick={row => {
                  this._toggle(row);
                  this.setState({ row: row });
                }}
                params={{
                  landingPageBlockId:
                    landingPageBlockDetails && landingPageBlockDetails.id
                }}
                paramsToUrl={true}
                noRecordFoundHeight={"7vh"}
                history={this.props.history}
              >
                <ReduxColumn
                  width={"200px"}
                  field="title"
                  sortBy="name"
                  isClickable="true"
                  type="link"
                >
                  Title
                </ReduxColumn>
                <ReduxColumn
                  width={"20px"}
                  field="status"
                  className="text-center"
                  disableOnClick
                  renderField={row => (
                    <div className="text-center landing-group-dropdown">
                      <MoreDropdown>
                        <DropdownItem
                          onClick={() => {
                            this._toggle();
                            this.setState({ row: row });
                          }}
                        >
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          onClick={() =>
                            this.setState({
                              deleteModel: true,
                              row: row
                            })
                          }
                        >
                          Delete
                        </DropdownItem>
                      </MoreDropdown>
                    </div>
                  )}
                >
                  Action
                </ReduxColumn>
              </ReduxTable>
            </div>
          </>
        )}
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        updateLandingPageBlock,
        deleteLandingPageBlock,
        deleteLandingPageFeatureCollection
      },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(LandingPageBlocksForm);
