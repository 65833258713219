import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";
import {
  isLoggedIn,
  isSuperAdmin,
  isPartner,
  isCompanyManager,
  isCompanyAdmin
} from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import MessageList from "../../views/message/MessageList";
import MessageDialog from "../../views/message/MessageDialog";
import AddButton from "../../components/base/AddButton";

// 404 Page
import Page404 from "../Page404";
import { isBadRequest } from "../../common/http";
import {
  PARTNER_PERMISSION_FILTERED_PARTNERS,
  PARTNER_PERMISSION_INDIVIDUAL_PARTNER
} from "../../resource/Constants";

// Toast
import toast from "../../components/base/Toast";

import { MessageContentType } from "../../views/message/constants";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
import { connect } from "react-redux";

class Message extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ],
      isPageNotFound: false,
      messageList: [],
      isLoading: false,
      sendMessageModal: false,
      checkBox: {
        partnerTier: [],
        partnerType: []
      },
      sharingPermission: PARTNER_PERMISSION_INDIVIDUAL_PARTNER,
      partnerId: null,
      permissionRequired: true,
      closeModal: false,
      contentType: "",
      EmailSubject: "",
      EmailTemplateOptions: [],
      EmailTemplates: [],
      selectedEmailTemplate: ""
    };
  }
  componentDidMount() {
    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isPartner(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    ) {
      // Check is logged in user
      isLoggedIn();
      this.getMessageList();
      this.getEmailTemplate();
    } else {
      this.setState({
        isPageNotFound: true
      });
    }
  }

  handleMessage = (toUserId, messageId, messageDetails) => {
    this.props.history.push({
      pathname: `communication/${messageId}`,
      state: {
        messageDetails: messageDetails,
        toUserId: toUserId,
        messageId: messageId
      }
    });
  };

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  //Get Messsage List
  getMessageList = async () => {
    this.setState({ isLoading: true });
    try {
      const response = await apiClient.get(`${endpoints().messageAPI}/search`);
      if (response && response.data) {
        this.setState({ messageList: response.data });
      }
      this.setState({ isLoading: false });
    } catch (error) {
      this.setState({ isLoading: false });
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  getEmailTemplate = async () => {
    return await apiClient
      .get(`${endpoints().emailTemplateAPI}/search`)
      .then(response => {
        if (
          response &&
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          let EmailTemplate = response.data.data;
          let EmailTemplateOptions = new Array();
          for (let i = 0; i < EmailTemplate.length; i++) {
            EmailTemplateOptions.push({
              label: EmailTemplate[i].name,
              value: EmailTemplate[i].id
            });
          }
          this.setState({
            EmailTemplateOptions: EmailTemplateOptions,
            EmailTemplates: EmailTemplate
          });
        }
      })
      .catch(error => {
        this.setState({ isLoading: false });
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  //Send Message function
  sendMessageData = values => {
    //form new Object
    const data = new FormData();

    //destrcture the state data
    const {
      checkBox,
      sharingPermission,
      partnerId,
      selectedEmailTemplate
    } = this.state;

    if (values.message && !selectedEmailTemplate) {
      //append the message in data object
      data.append("message", values.message);
    }

    if (selectedEmailTemplate) {
      let attachment = JSON.stringify(selectedEmailTemplate);
      data.append("attachment", attachment);
    }

    if (selectedEmailTemplate) {
      //append the message in data object
      data.append("message", selectedEmailTemplate.content);

      if (selectedEmailTemplate.subject) {
        data.append("subject", selectedEmailTemplate.subject);
      }
    }

    if (values.subject && !selectedEmailTemplate) {
      data.append("subject", values.subject);
    }

    data.append("isNewConveration", true);

    //validare user is admin or manager
    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    ) {
      //validate permission type is selected partners
      if (sharingPermission == PARTNER_PERMISSION_FILTERED_PARTNERS) {
        //append the partner type
        data.append(
          "partnerType",
          checkBox && checkBox.partnerType ? checkBox.partnerType : []
        );
        //appende the partner tier
        data.append(
          "partnerTier",
          checkBox && checkBox.partnerTier ? checkBox.partnerTier : []
        );
      }

      //append the selected permission
      data.append(
        "sharingPermission",
        sharingPermission ? sharingPermission : ""
      );
      //validate selected permission is invidual partner
      if (sharingPermission == PARTNER_PERMISSION_INDIVIDUAL_PARTNER) {
        //append the partner Id
        data.append("partnerId", partnerId ? partnerId : null);
      }
    }
    // Check if the permission field is selected in case of selected partner option
    if (
      sharingPermission === PARTNER_PERMISSION_FILTERED_PARTNERS &&
      (checkBox.partnerTier.length || checkBox.partnerType.length) === 0
    ) {
      this.setState({
        permissionRequired: true
      });
      //validate sharing permission is individual partner
    } else if (
      sharingPermission === PARTNER_PERMISSION_INDIVIDUAL_PARTNER &&
      (partnerId === undefined || partnerId === null)
    ) {
      this.setState({
        permissionRequired: true
      });
    } else {
      this.setState({
        permissionRequired: false
      });
    }

    //validate logged in user is partner
    if (isPartner(this.props.roleId)) {
      this.setState({
        permissionRequired: false
      });
    }

    //validate partner permission false
    if (this.state.permissionRequired == false) {
      //send message
      apiClient
        .post(endpoints().messageAPI, data)
        .then(response => {
          this.handleClose();
          this.toggle();
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
          }
          this.setState({
            partnerId: null,
            permissionRequired: false
          });
          this.getMessageList();
        })
        .catch(error => {
          if (isBadRequest(error)) {
            this.setState({
              partnerId: null,
              permissionRequired: true
            });
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    }
  };

  //Handle Type Change
  handleTypeChange = e => {
    const newSelection = e.target.value;
    let newSelectionArray;
    if (this.state.checkBox.partnerType.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.partnerType.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [...this.state.checkBox.partnerType, newSelection];
    }
    if (newSelectionArray.length == 0) {
      this.setState({ permissionRequired: true });
    } else {
      this.setState({ permissionRequired: false });
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        partnerType: newSelectionArray
      }
    }));
  };

  //Handle Tier Change
  handleTierChange = e => {
    const newSelection = e.target.value;
    let newSelectionArray;

    if (this.state.checkBox.partnerTier.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.partnerTier.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [...this.state.checkBox.partnerTier, newSelection];
    }
    if (newSelectionArray.length == 0) {
      this.setState({ permissionRequired: true });
    } else {
      this.setState({ permissionRequired: false });
    }
    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        partnerTier: newSelectionArray
      }
    }));
  };

  //Partner Select
  handlePartnerSelect = partner => {
    if (partner) {
      this.setState({ partnerId: partner.id, permissionRequired: false });
    }
  };

  //Partner Permission select
  handlePartnerPermission = permissionType => {
    if (permissionType) {
      this.setState({ sharingPermission: permissionType });
    }
  };

  handleClose = () => {
    if (this.state.permissionRequired == false) {
      this.setState({ sendMessageModal: false });
    }
  };
  toggle = () => {
    this.setState({
      sendMessageModal: false,
      selectedEmailTemplate: "",
      checkBox: {
        partnerTier: [],
        partnerType: []
      },
      sharingPermission: PARTNER_PERMISSION_INDIVIDUAL_PARTNER
    });
  };

  handleEmailTemplateChange = onChangeValue => {
    if (onChangeValue && onChangeValue.value && onChangeValue.value.value) {
      let EmailTemplateId = onChangeValue.value.value;
      let emailTemplateList = this.state.EmailTemplates;
      const selectedEmailTemplate = emailTemplateList.find(
        template => template.id == EmailTemplateId
      );
      this.setState({ selectedEmailTemplate: selectedEmailTemplate });
    } else {
      this.setState({ selectedEmailTemplate: "" });
    }
  };

  render() {
    // Page Not Found
    if (this.state.isPageNotFound) {
      return <Page404 />;
    }
    return (
      <>
        <MessageDialog
          modal={this.state.sendMessageModal}
          toggle={this.toggle}
          sendMessageData={this.sendMessageData}
          heading={
            isPartner(this.props.roleId)
              ? `Send ${Terminology.get(SystemName.PARTNER_MANAGER)} a Message`
              : "Send Partner a Message"
          }
          buttonLabel="Send Message"
          partnerDropdown={isPartner(this.props.roleId) ? false : true}
          handlePartnerTierCheckbox={this.handleTierChange}
          tierSelectedOptions={this.state.checkBox.partnerTier}
          typeSelectedOptions={this.state.checkBox.partnerType}
          handlePartnerTypeCheckbox={this.handleTypeChange}
          handleChangePartnerSelection={this.handlePartnerSelect}
          handlePartnerPermission={this.handlePartnerPermission}
          permissionRequired={this.state.permissionRequired}
          handleContentTypeChange={this.handleContentTypeChange}
          showSubject={true}
          showEmailTemplate={true}
          EmailTemplateOptions={this.state.EmailTemplateOptions}
          handleEmailTemplateChange={this.handleEmailTemplateChange}
          selectedEmailTemplate={this.state.selectedEmailTemplate}
        />
        <div className="d-flex justify-content-between">
          <PageTitle label="Partner Communications" />
          <div>
            <AddButton
              label="New Message"
              className="pull-right btn btn-secondary"
              onClick={() => {
                this.setState({
                  sendMessageModal: true
                });
              }}
            />
          </div>
        </div>
        <MessageList
          isLoading={this.state.isLoading}
          messageList={this.state.messageList}
          handleMessage={this.handleMessage}
        />
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(Message);
