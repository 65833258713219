import React from "react";
import LandingPageTypeForm from "./LandingPageTypeForm";
import MyContext from "../../../../context/MyContext";
import PageTitle from "../../../../components/base/PageTitle";

const LandingPageDetails = props => {
  return (
    <MyContext.Consumer>
      {context => (
        <>
          {context.isMobile && <PageTitle label={"Edit Landing page"} />}
          <LandingPageTypeForm
            history={props.history}
            match={props.match}
            marketplaceId={props.marketplaceId}
            sectionId={props.sectionId}
            settings={props.settings}
          />
        </>
      )}
    </MyContext.Consumer>
  );
};

export default LandingPageDetails;
