import React, { Component } from "react";

import { isBadRequest } from "../../../../common/http";
import {
  SETTINGS_PORTAL_FROM_EMAIL,
  SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME
} from "../../../../setting/Constants";
import Toast from "../../../../components/base/Toast";
import { apiClient } from "../../../../apiClient";
import { endpoints } from "../../../../configs";
import Theme from "./theme";

class ThemeTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsData: "",
      isLoading: true,
      isSettingsLoading: true,
      marketplace_fav_icon_url: "",
      background_image_url: "",
      expert_signup_hero_image_url: "",
      partner_signup_hero_image_url: "",
      marketplace_left_navigation_background_image_url: "",
      customer_signup_hero_image_url: "",
      email_logo_url: "",
      email_background_banner_url: "",
      marketplace_fav_icon: "",
      background_image: "",
      customer_signup_hero_image: "",
      partner_signup_hero_image: "",
      expert_signup_hero_image: "",
      marketplace_left_navigation_background_image: "",
      email_logo: "",
      email_background_banner: "",
      settings: []
    };
  }

  componentDidMount() {
    this.getSettingsData(true);
  }

  // Settings API post
  _createSettings = async (data, companyId) => {
    return apiClient
      .post(`${endpoints().settingAPI}/?company_id=${companyId}`, data)
      .then(() => {
        Toast.success("Settings saved successfully");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Marketplace FavIcon Upload
  marketplaceFavIconUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          marketplace_fav_icon_url: URL.createObjectURL(files),
          marketplace_fav_icon: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "marketplaceFavIconFile")
      );
  };

  // Marketplace FavIcon Remove
  handleMarketplaceFavIconRemove = () => {
    this.setState({
      marketplace_fav_icon_url: "",
      marketplace_fav_icon: "",
      marketplaceFavIconFile: ""
    });
  };

  // Marketplace Logo Upload
  backgroundImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          background_image_url: URL.createObjectURL(files),
          background_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "backgroundImageFile")
      );
  };

  // Background Image Remove
  handleBackgroundImageRemove = () => {
    this.setState({
      background_image_url: "",
      background_image: "",
      backgroundImageFile: ""
    });
  };

  // Expert signup hero image upload
  expertSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          expert_signup_hero_image_url: URL.createObjectURL(files),
          expert_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "expertSignupHeroImageFile")
      );
  };

  // Expert signup hero image remove
  handleExpertSignupHeroImageRemove = () => {
    this.setState({
      expert_signup_hero_image_url: "",
      expert_signup_hero_image: "",
      expertSignupHeroImageFile: ""
    });
  };

  // Customer signup hero image upload
  customerSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          customer_signup_hero_image_url: URL.createObjectURL(files),
          customer_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "customerSignupHeroImageFile")
      );
  };

  // Customer signup hero image remove
  handleCustomerSignupHeroImageRemove = () => {
    this.setState({
      customer_signup_hero_image_url: "",
      customer_signup_hero_image: "",
      customerSignupHeroImageFile: ""
    });
  };

  // Partner signup hero image upload
  partnerSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          partner_signup_hero_image_url: URL.createObjectURL(files),
          partner_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "partnerSignupHeroImageFile")
      );
  };

  // Partner signup hero image remove
  handlePartnerSignupHeroImageRemove = () => {
    this.setState({
      partner_signup_hero_image_url: "",
      partner_signup_hero_image: "",
      partnerSignupHeroImageFile: ""
    });
  };

  // Partner Dashboard Default Banner image upload
  leftNavigationBackgroundImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          marketplace_left_navigation_background_image_url: URL.createObjectURL(
            files
          ),
          marketplace_left_navigation_background_image: URL.createObjectURL(
            files
          )
        },
        () => this.setBase64Image(files, "leftNavigationBackgroundImageFile")
      );
  };

  // Partner Dashboard Default Banner image remove
  handleLeftNavigationBackgroundImageRemove = () => {
    this.setState({
      marketplace_left_navigation_background_image_url: "",
      marketplace_left_navigation_background_image: "",
      leftNavigationBackgroundImageFile: ""
    });
  };

  // Email Background Banner Remove
  handleEmailBackgroundBannerRemove = () => {
    this.setState({
      email_background_banner_url: "",
      email_background_banner: "",
      emailBackgroundBannerFile: ""
    });
  };

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Marketplace FavIcon Upload
  marketplaceFavIconUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          marketplace_fav_icon_url: URL.createObjectURL(files),
          marketplace_fav_icon: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "marketplaceFavIconFile")
      );
  };

  // Marketplace FavIcon Remove
  handleMarketplaceFavIconRemove = () => {
    this.setState({
      marketplace_fav_icon_url: "",
      marketplace_fav_icon: "",
      marketplaceFavIconFile: ""
    });
  };

  // Marketplace Logo Upload
  backgroundImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          background_image_url: URL.createObjectURL(files),
          background_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "backgroundImageFile")
      );
  };

  // Background Image Remove
  handleBackgroundImageRemove = () => {
    this.setState({
      background_image_url: "",
      background_image: "",
      backgroundImageFile: ""
    });
  };

  // Expert signup hero image upload
  expertSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          expert_signup_hero_image_url: URL.createObjectURL(files),
          expert_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "expertSignupHeroImageFile")
      );
  };

  // Expert signup hero image remove
  handleExpertSignupHeroImageRemove = () => {
    this.setState({
      expert_signup_hero_image_url: "",
      expert_signup_hero_image: "",
      expertSignupHeroImageFile: ""
    });
  };

  // Customer signup hero image upload
  customerSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          customer_signup_hero_image_url: URL.createObjectURL(files),
          customer_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "customerSignupHeroImageFile")
      );
  };

  // Customer signup hero image remove
  handleCustomerSignupHeroImageRemove = () => {
    this.setState({
      customer_signup_hero_image_url: "",
      customer_signup_hero_image: "",
      customerSignupHeroImageFile: ""
    });
  };

  // Partner signup hero image upload
  partnerSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          partner_signup_hero_image_url: URL.createObjectURL(files),
          partner_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "partnerSignupHeroImageFile")
      );
  };

  // Partner signup hero image remove
  handlePartnerSignupHeroImageRemove = () => {
    this.setState({
      partner_signup_hero_image_url: "",
      partner_signup_hero_image: "",
      partnerSignupHeroImageFile: ""
    });
  };

  // Partner Dashboard Default Banner image upload
  leftNavigationBackgroundImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          marketplace_left_navigation_background_image_url: URL.createObjectURL(
            files
          ),
          marketplace_left_navigation_background_image: URL.createObjectURL(
            files
          )
        },
        () => this.setBase64Image(files, "leftNavigationBackgroundImageFile")
      );
  };

  // Partner Dashboard Default Banner image remove
  handleLeftNavigationBackgroundImageRemove = () => {
    this.setState({
      marketplace_left_navigation_background_image_url: "",
      marketplace_left_navigation_background_image: "",
      leftNavigationBackgroundImageFile: ""
    });
  };

  // Handle theme tab submit
  _handleThemeSubmit = values => {
    const data = new FormData();

    if (values.legal_disclosure !== undefined) {
      data.append(
        "legal_disclosure",
        values.legal_disclosure ? values.legal_disclosure : ""
      );
    }

    // Marketplace logo rediect URL
    if (values.marketplace_logo_redirect_url !== undefined) {
      data.append(
        "marketplace_logo_redirect_url",
        values.marketplace_logo_redirect_url
          ? values.marketplace_logo_redirect_url
          : ""
      );
    }

    // Expert signup logo URL
    if (this.state.expert_signup_hero_image_url !== undefined) {
      data.append(
        "expert_signup_hero_image_url",
        this.state.expert_signup_hero_image_url
          ? this.state.expert_signup_hero_image_url
          : ""
      );
    }

    if (this.state.expertSignupHeroImageFile !== undefined) {
      data.append(
        "expert_signup_hero_image",
        this.state.expertSignupHeroImageFile
      );
    }

    // Customer signup logo URL
    if (this.state.customer_signup_hero_image_url !== undefined) {
      data.append(
        "customer_signup_hero_image_url",
        this.state.customer_signup_hero_image_url
          ? this.state.customer_signup_hero_image_url
          : ""
      );
    }

    if (this.state.customerSignupHeroImageFile !== undefined) {
      data.append(
        "customer_signup_hero_image",
        this.state.customerSignupHeroImageFile
      );
    }

    // Partner signup logo URL
    if (this.state.partner_signup_hero_image_url !== undefined) {
      data.append(
        "partner_signup_hero_image_url",
        this.state.partner_signup_hero_image_url
          ? this.state.partner_signup_hero_image_url
          : ""
      );
    }

    if (this.state.partnerSignupHeroImageFile !== undefined) {
      data.append(
        "partner_signup_hero_image",
        this.state.partnerSignupHeroImageFile
      );
    }

    // Left Navigation Background Image URL
    if (
      this.state.marketplace_left_navigation_background_image_url !== undefined
    ) {
      data.append(
        "marketplace_left_navigation_background_image_url",
        this.state.marketplace_left_navigation_background_image_url
          ? this.state.marketplace_left_navigation_background_image_url
          : ""
      );
    }

    if (this.state.leftNavigationBackgroundImageFile !== undefined) {
      data.append(
        "marketplace_left_navigation_background_image",
        this.state.leftNavigationBackgroundImageFile
      );
    }

    // Marketplace Header Color
    if (values.marketplace_header_color !== undefined) {
      data.append(
        "marketplace_header_color",
        values.marketplace_header_color ? values.marketplace_header_color : ""
      );
    }

    // Marketplace Header Text Color
    if (values.marketplace_header_text_color !== undefined) {
      data.append(
        "marketplace_header_text_color",
        values.marketplace_header_text_color
          ? values.marketplace_header_text_color
          : ""
      );
    }

    // Marketplace Footer Color
    if (values.marketplace_footer_color !== undefined) {
      data.append(
        "marketplace_footer_color",
        values.marketplace_footer_color ? values.marketplace_footer_color : ""
      );
    }

    // Marketplace Footer Heading Color
    if (values.marketplace_footer_heading_color !== undefined) {
      data.append(
        "marketplace_footer_heading_color",
        values.marketplace_footer_heading_color
          ? values.marketplace_footer_heading_color
          : ""
      );
    }

    // Marketplace Footer Text Color
    if (values.marketplace_footer_text_color !== undefined) {
      data.append(
        "marketplace_footer_text_color",
        values.marketplace_footer_text_color
          ? values.marketplace_footer_text_color
          : ""
      );
    }

    // Marketplace Footer Copyright text
    if (values.marketplace_footer_copy_rights_text !== undefined) {
      data.append(
        "marketplace_footer_copy_rights_text",
        values.marketplace_footer_copy_rights_text
          ? values.marketplace_footer_copy_rights_text
          : ""
      );
    }

    // Terms of service url
    if (values.terms_of_service_url !== undefined) {
      data.append(
        "terms_of_service_url",
        values.terms_of_service_url ? values.terms_of_service_url : ""
      );
    }

    // Marketplace Desktop Footer Content
    if (values.marketplace_desktop_footer_content !== undefined) {
      data.append(
        "marketplace_desktop_footer_content",
        values.marketplace_desktop_footer_content
          ? values.marketplace_desktop_footer_content
          : ""
      );
    }

    // Marketplace Mobile Footer Content
    if (values.marketplace_mobile_footer_content !== undefined) {
      data.append(
        "marketplace_mobile_footer_content",
        values.marketplace_mobile_footer_content
          ? values.marketplace_mobile_footer_content
          : ""
      );
    }

    // Public Page Footer Content
    if (values.public_page_footer_content !== undefined) {
      data.append(
        "public_page_footer_content",
        values.public_page_footer_content
          ? values.public_page_footer_content
          : ""
      );
    }

    // Marketplace Left Navigation Text color
    if (values.marketplace_left_navigation_text_color !== undefined) {
      data.append(
        "marketplace_left_navigation_text_color",
        values.marketplace_left_navigation_text_color
          ? values.marketplace_left_navigation_text_color
          : ""
      );
    }
    // Marketplace Default Layout

    if (values.theme_layout !== undefined) {
      data.append(
        "theme_layout",

        values.theme_layout ? values.theme_layout.value : ""
      );
    }
    // Marketplace Left Navigation Text Hover Over color
    if (
      values.marketplace_left_navigation_text_hover_over_color !== undefined
    ) {
      data.append(
        "marketplace_left_navigation_text_hover_over_color",
        values.marketplace_left_navigation_text_hover_over_color
          ? values.marketplace_left_navigation_text_hover_over_color
          : ""
      );
    }

    // Marketplace favicon URL
    if (this.state.marketplace_fav_icon_url !== undefined) {
      data.append(
        "marketplace_fav_icon_url",
        this.state.marketplace_fav_icon_url
          ? this.state.marketplace_fav_icon_url
          : ""
      );
    }

    // Marketplace favicon file
    if (this.state.marketplaceFavIconFile !== undefined) {
      data.append("marketplace_fav_icon", this.state.marketplaceFavIconFile);
    }

    // Background image URL
    if (this.state.background_image_url !== undefined) {
      data.append(
        "background_image_url",
        this.state.background_image_url ? this.state.background_image_url : ""
      );
    }

    if (this.state.backgroundImageFile !== undefined) {
      data.append("background_image", this.state.backgroundImageFile);
    }

    // Save settings
    this._createSettings(data, this.props.companyId);
  };

  // Get Settings Data
  getSettingsData = () => {
    this.setState({ isSettingsLoading: true }, () => {
      return apiClient
        .get(`${endpoints().companyAPI}/settings/${this.props.companyId}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);
          const settings = response.data;
          this.setState({ settings: settings });

          if (settings) {
            settings.forEach(data => {
              const name = data.name;
              const value = data.value;
              this.setState({ [name]: value });
            });
          }
          this.setState({
            isSettingsLoading: false,
            marketplace_fav_icon_url: this.state.marketplace_fav_icon,
            background_image_url: this.state.background_image,
            expert_signup_hero_image_url: this.state.expert_signup_hero_image,
            partner_signup_hero_image_url: this.state.partner_signup_hero_image,
            marketplace_left_navigation_background_image_url: this.state
              .marketplace_left_navigation_background_image,
            customer_signup_hero_image_url: this.state
              .customer_signup_hero_image,
            email_logo_url: this.state.email_logo,
            email_background_banner_url: this.state.email_background_banner,
            marketplace_fav_icon: "",
            background_image: "",
            customer_signup_hero_image: "",
            partner_signup_hero_image: "",
            expert_signup_hero_image: "",
            marketplace_left_navigation_background_image: "",
            email_logo: "",
            email_background_banner: ""
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
              this.setState({ isInvalidRequest: true });
            }
            console.error(errorMessage);
          }
        });
    });
  };

  render() {
    const themeTabSettings = {
      marketplaceFavIconUpload: this.marketplaceFavIconUpload,
      handleMarketplaceFavIconRemove: this.handleMarketplaceFavIconRemove,
      backgroundImageUpload: this.backgroundImageUpload,
      handleBackgroundImageRemove: this.handleBackgroundImageRemove,
      expertSignupHeroImageUpload: this.expertSignupHeroImageUpload,
      handleExpertSignupHeroImageRemove: this.handleExpertSignupHeroImageRemove,
      customerSignupHeroImageUpload: this.customerSignupHeroImageUpload,
      handleCustomerSignupHeroImageRemove: this
        .handleCustomerSignupHeroImageRemove,
      partnerSignupHeroImageUpload: this.partnerSignupHeroImageUpload,
      handlePartnerSignupHeroImageRemove: this
        .handlePartnerSignupHeroImageRemove,
      leftNavigationBackgroundImageUpload: this
        .leftNavigationBackgroundImageUpload,
      handleLeftNavigationBackgroundImageRemove: this
        .handleLeftNavigationBackgroundImageRemove,
      settings: this.state.settings,
      handleEmailBackgroundBannerRemove: this.handleEmailBackgroundBannerRemove,
      submitThemeTab: this._handleThemeSubmit
    };

    return (
      <div>
        <Theme
          adminSettings={this.state}
          saveSettings={themeTabSettings}
          companyId={this.props.companyId}
          settings={this.state.settings}
        />
      </div>
    );
  }
}

export default ThemeTab;
