import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Assets
import { EditIconAlt } from "../../assets/img/icons";

// Components
import useToggle from "../../components/customhooks/useToggle";
import Country from "../../components/base/Country";
import Text from "../../components/base/Text";
import State from "../../components/State";
import Zipcode from "../../components/base/Zipcode";
import Form from "../../components/base/Form";
import WithDefaultWrapper from "../../components/WithDefaultWrapper";
import toast from "../../components/base/Toast";
import CancelButton from "../../components/base/CancelButton";

// Helper
import { toString } from "../../lib/helper";

// API Client
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Constants
import { CANADA } from "../../Country/Constants";

export const getBillingDetails = () => {
  return apiClient.get(`${endpoints().customerAPI}/billing-info`);
};

const BillingDetailsModal = ({
  isOpen,
  toggle,
  setBillingDetails,
  customerBillingDetails
}) => {
  const [initialValues, setInitialValues] = useState({});
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState("");

  useEffect(() => {
    setInitialValues(customerBillingDetails);
    const country = customerBillingDetails && customerBillingDetails.country;

    if (country) {
      getCountryDetails(country);
      setSelectedCountryName(country);
    }
  }, [customerBillingDetails]);

  // Handle Country
  const handleCountryChange = ({ values }) => {
    if (values) {
      const selectedOption = values.country && values.country.value;
      setSelectedCountryName(values.country && values.country.label);
      setSelectedOption(selectedOption);
    }
  };

  // Get Country Details
  const getCountryDetails = countryName => {
    return apiClient
      .get(`${endpoints().countryAPI}/${countryName}`)
      .then(response => {
        const data = response.data;
        setSelectedCountry(data.id);
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      className={["edit-task-modal", "edit-billing-modal"].join(" ")}
    >
      <Form
        initialValues={{
          ...initialValues,
          state: { value: initialValues.state, label: initialValues.state },
          country: {
            value: initialValues.country,
            label: initialValues.country
          }
        }}
        onSubmit={values => {
          let newValues = Object.assign({}, values);

          newValues.country = toString(
            newValues.country ? newValues.country.label : newValues.country
          );
          newValues.state = toString(
            newValues.state ? newValues.state.value : newValues.state
          );

          setBillingDetails(newValues);
          toggle();
        }}
      >
        <ModalHeader toggle={toggle} tag={"div"}>
          <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
            Edit Billing Address
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <Text
                name="name"
                label="Company Name"
                placeholder="Enter company name"
                required={true}
                error={""}
              />
            </div>
            <div className="field-wrapper">
              <Text
                name="address"
                label="Address"
                placeholder="Enter Address"
                error=""
                required={true}
              />
            </div>
            <div className="field-wrapper">
              <Text
                name="city"
                label="City"
                placeholder="Enter city"
                required={true}
                error=""
              />
              <State
                name="state"
                label="State/Province/Region"
                placeholder="Enter State"
                error=""
                selectedCountry={selectedOption || selectedCountry}
                // notify={true}
                // required
              />
              <Zipcode
                name="postalCode"
                label="Zip/Postal Code"
                placeholder="Enter Zip"
                error=""
                required={true}
                locale={selectedCountryName === CANADA ? "CA" : "US"}
              />
            </div>
            <div className="field-wrapper">
              <Country
                name="country"
                label="Country"
                placeholder="Enter Country"
                error=""
                required={true}
                onChange={handleCountryChange}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={toggle} />
            <button className="btn btn-primary" type={"submit"}>
              Update
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export const BillingDetailsBody = props => {
  const { fields, toggleModal, classNames } = props;
  const {
    name,
    address,
    state,
    city,
    country,
    postalCode,
    phone
  } = props.fields;

  return (
    <div
      className={`${["billing-details", "d-flex"].join(" ")} ${classNames &&
        classNames}`}
    >
      {Object.values(fields).length < 1 ? (
        <h6>Update your billing info!</h6>
      ) : (
        <dl className={["definition-list"].join(" ")}>
          {name && <dd>{name}</dd>}
          {address && <dd>{address}</dd>}
          {(city || state || postalCode) && (
            <dd>{`${city}, ${state}, ${postalCode}`}</dd>
          )}
          {country && <dd>{country}</dd>}
          {phone && <dd>{phone}</dd>}
        </dl>
      )}
      <button
        className="edit-btn btn btn-link d-flex"
        onClick={toggleModal}
        type={"button"}
      >
        <EditIconAlt />
        Edit
      </button>
    </div>
  );
};

const BillingDetails = props => {
  const [modal, setModal] = useToggle(false);
  const [customerBillingDetails, setCustomerBillingDetails] = useState({});
  const { title, updateBillingDetails } = props;

  const setBillingDetails = data => {
    return apiClient
      .put(`${endpoints().customerAPI}/billing-info`, data)
      .then(() => updateBillingUi());
  };

  const updateBillingUi = () => {
    getBillingDetails()
      .then(res => {
        toast.success(res.data.message);
        setCustomerBillingDetails(res.data.data);
        if (updateBillingDetails) updateBillingDetails(res.data.data);
      })
      .catch(err => console.error(err));
  };

  const toggleModal = () => setModal(true);

  useEffect(() => {
    getBillingDetails()
      .then(res => {
        setCustomerBillingDetails(res.data.data || {});
      })
      .catch(err => console.error(err));
  }, []);

  return (
    <WithDefaultWrapper title={title}>
      <BillingDetailsBody
        fields={customerBillingDetails}
        toggleModal={toggleModal}
        classNames={"mt-4"}
      />
      <BillingDetailsModal
        isOpen={modal}
        toggle={setModal}
        setBillingDetails={setBillingDetails}
        customerBillingDetails={customerBillingDetails}
      />
    </WithDefaultWrapper>
  );
};

export default BillingDetails;
