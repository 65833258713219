import React from "react";

// Assets
import { ArrowRight, CheckmarkIcon } from "../../../assets/img/icons";
import { PROJECT_STATUS_DRAFT } from "../../../projectStatus/Constants";

const ProjectStepWrapper = ({ steps, projectStatusHistory, projectStatus }) => {
  let matchedStepIndex = null;

  return (
    <div className="steps-wrapper">
      {steps.map(({ label, icon }, index) => {
        const draftStatus = projectStatus === PROJECT_STATUS_DRAFT;

        if (!matchedStepIndex && projectStatus === label)
          matchedStepIndex = index + 1;

        return (
          <div
            className={`step
                    ${projectStatus === label ? "current-step" : ""}
                    ${
                      !draftStatus &&
                      projectStatus !== label &&
                      (!matchedStepIndex || index + 1 < matchedStepIndex)
                        ? "completed-step"
                        : ""
                    }
                    `}
            key={index}
          >
            <div className="step-icon">
              {draftStatus ||
              projectStatus === label ||
              (matchedStepIndex && index + 1 > matchedStepIndex) ? (
                icon
              ) : (
                <CheckmarkIcon />
              )}
            </div>
            <span
              className={`step-status-text font-weight-bold ${
                !draftStatus && projectStatus !== label ? "d-none" : ""
              }`}
            >
              {projectStatus === label ? label : ""}
            </span>

            {steps.length > index + 1 && <ArrowRight />}
          </div>
        );
      })}
    </div>
  );
};

export default ProjectStepWrapper;
