// Task Status
export const TASK_STATUS_PENDING = "Pending";
export const TASK_STATUS_COMPLETED = "Completed";

// Task Type
export const TASK_TYPE_CREATE_ACCOUNT = "Create Account";
export const TASK_TYPE_AVATAR = "Avatar";
export const TASK_TYPE_SERVICES = "Services";
export const TASK_TYPE_FILE_UPLOAD = "File Upload";
export const TASK_TYPE_TEXT_FIELD = "Text Field";
export const TASK_TYPE_DROP_DOWN_FIELD = "Drop Down Field";
export const TASK_TYPE_DATE_FIELD = "Date Field";
export const TASK_TYPE_WEBSITE_FIELD = "Website";
export const TASK_TYPE_CURRENCY_FIELD = "Currency";
export const TASK_TYPE_NUMBER_FIELD = "Number";

// Conditional Display
export const ALWAYS_ASK = "Always Ask";
export const CONDITIONAL =
  "Only ask if specific answers to previous question are given";

//Task
export const TASK_TYPE_FILL_OUT_FORM = "Fill out form";
export const TASK_TYPE_VISIT_URL = "Visit URL";
export const TASK_TYPE_COMPLETED_OFFLINE = "Completed offline";

// Task Category
export const TASK_CATEGORY_SYSTEM = "System";
export const TASK_CATEGORY_USER_DEFINED = "User Defined";

// Task Visibility
export const TASK_VISIBILITY_ADMIN = "Admin";
export const TASK_VISIBILITY_PARTNER = "Partner";
export const TASK_VISIBILITY_PUBLIC = "Public";

// Drop Down Type
export const TASK_TYPE_DROP_DOWN_MULTI_SELECT = "Multi Select";
export const TASK_TYPE_DROP_DOWN_SINGLE_SELECT = "Single Select";

export const percentOptions = [
  { label: "0%", value: 0 },
  { label: "5%", value: 5 },
  { label: "10%", value: 10 },
  { label: "15%", value: 15 },
  { label: "20%", value: 20 },
  { label: "25%", value: 25 },
  { label: "50%", value: 50 },
  { label: "75%", value: 75 },
  { label: "100%", value: 100 }
];

export const taskTypeOptions = [
  { label: TASK_TYPE_FILE_UPLOAD, value: TASK_TYPE_FILE_UPLOAD },
  { label: TASK_TYPE_TEXT_FIELD, value: TASK_TYPE_TEXT_FIELD },
  { label: TASK_TYPE_DROP_DOWN_FIELD, value: TASK_TYPE_DROP_DOWN_FIELD }
];

export const applicationTaskTypeOptions = [
  { label: TASK_TYPE_FILL_OUT_FORM, value: TASK_TYPE_FILL_OUT_FORM },
  { label: TASK_TYPE_VISIT_URL, value: TASK_TYPE_VISIT_URL },
  { label: TASK_TYPE_COMPLETED_OFFLINE, value: TASK_TYPE_COMPLETED_OFFLINE }
];

export const visibilityOptions = [
  { label: TASK_VISIBILITY_ADMIN, value: TASK_VISIBILITY_ADMIN },
  { label: TASK_VISIBILITY_PARTNER, value: TASK_VISIBILITY_PARTNER },
  { label: TASK_VISIBILITY_PUBLIC, value: TASK_VISIBILITY_PUBLIC }
];
