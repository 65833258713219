import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import toast from "../components/base/Toast";
import { fetchList } from "../actions/table";
import { isBadRequest } from "../common/http";

class CertificateService {
  static async updateStatus(id, status, callBack) {
    try {
      let data = {};

      data.status = status;
      data.id = id;

      apiClient
        .put(`${endpoints().certificateApi}/status`, data)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
          }
          return callBack(response);
        });
    } catch (err) {
      console.log(err);
    }
  }
  static async delete(id, callBack) {
    try {
      apiClient.delete(`${endpoints().certificateApi}/${id}`).then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          return callBack(response);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }
  static async update(data, callBack) {
    try {
      return dispatch => {
        apiClient
          .put(`${endpoints().certificateApi}`, data)
          .then(response => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
              toast.success(successMessage);
              return callBack(response);
            }
          })
          .then(() => {
            dispatch(
              fetchList(
                "certificate",
                `${endpoints().certificateApi}/search`,
                1,
                10
              )
            );
          })
          .catch(error => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;

              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              toast.error(errorMessage);
              console.error(errorMessage);
            }
          });
      };
    } catch (err) {
      console.log(err);
    }
  }
  static async create(data, callBack) {
    try {
      return dispatch => {
        apiClient
          .post(`${endpoints().certificateApi}`, data)
          .then(response => {
            if (response.data) {
              toast.success(response.data.message);
              return callBack(response);
            }
          })
          .then(() => {
            dispatch(
              fetchList(
                "certificate",
                `${endpoints().certificateApi}/search`,
                1,
                10
              )
            );
          })
          .catch(error => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;

              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              toast.error(errorMessage);
              console.error(errorMessage);
            }
          });
      };
    } catch (err) {
      console.log(err);
    }
  }

  static async list() {
    try {
      let dataArray = [];
      let response = await apiClient.get(
        `${endpoints().certificateApi}/list`,
        data
      );
      let data = response && response.data && response.data.data;

      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          dataArray.push({
            label: data[i].name,
            value: data[i].id,
            image: data[i].imageUrl
          });
        }
      }
      return dataArray;
    } catch (err) {
      console.log(err);
    }
  }
}

export default CertificateService;
