import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Field } from "formik";

// API call
import { apiClient } from "../../apiClient";

// Assets
import { PlusIcon } from "../../assets/img/icons";

// Components
import Spinner from "../../components/base/Spinner";
import toast from "../../components/base/Toast";
import Form from "../../components/base/Form";
import Select from "../../components/base/Select";
import Button from "../../components/base/Button";
import Text from "../../components/base/Text";
import ToggleSwitch from "../../components/base/ToggleSwitch";
import DeleteModal from "../../components/base/DeleteModal";
import SaveButton from "../../components/base/SaveButton";
import MultiselectCreatable from "../../components/base/MultiselectCreatable";

// Constants
import { endpoints } from "../../configs";

// Inner Components
import Question from "../../../src/views/adminSettings/tabs/ReferralsTab/inner-components/Question";
import { isBadRequest } from "../../common/http";
import {
  ALWAYS_ASK,
  CONDITIONAL,
  TASK_TYPE_DROP_DOWN_FIELD,
  TASK_TYPE_TEXT_FIELD,
  TASK_TYPE_DATE_FIELD,
  TASK_TYPE_WEBSITE_FIELD,
  TASK_TYPE_DROP_DOWN_MULTI_SELECT,
  TASK_TYPE_DROP_DOWN_SINGLE_SELECT,
  TASK_TYPE_CURRENCY_FIELD,
  TASK_TYPE_NUMBER_FIELD
} from "../../modules/task/Constant";
import Radio from "../../components/base/Radio";
import {
  TAG_PERMISSION_SELECT_AND_ADD,
  TAG_PERMISSION_SELECT_AND_ADD_VALUE,
  TAG_PERMISSION_SELECT_AND_NOT_ADD,
  TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
} from "../../tag/Constants";
import Checkbox from "../../components/base/Checkbox";
import {
  getCheckboxOptions,
  isTrue,
  compareArrayInArray
} from "../../common/utils";

import {
  SETTING_REFERRAL_CUSTOMER_READINESS_OPTION,
  SETTING_REFERRAL_SHOW_CUSTOMER_READINESS
} from "../../setting/Constants";
import { readinessOptions } from "../../referral/Constants";

const requiredForOptions = [
  {
    value: "Partner Submit",
    label: "Partner Submit"
  },
  {
    value: "Approval",
    label: "Approval"
  },
  {
    value: "Not Required",
    label: "Not Required"
  }
];

const typepOptions = [
  {
    label: TASK_TYPE_DROP_DOWN_FIELD,
    value: TASK_TYPE_DROP_DOWN_FIELD
  },
  {
    label: TASK_TYPE_TEXT_FIELD,
    value: TASK_TYPE_TEXT_FIELD
  },
  {
    label: TASK_TYPE_DATE_FIELD,
    value: TASK_TYPE_DATE_FIELD
  },
  {
    label: TASK_TYPE_WEBSITE_FIELD,
    value: TASK_TYPE_WEBSITE_FIELD
  },
  {
    label: TASK_TYPE_CURRENCY_FIELD,
    value: TASK_TYPE_CURRENCY_FIELD
  },
  {
    label: TASK_TYPE_NUMBER_FIELD,
    value: TASK_TYPE_NUMBER_FIELD
  }
];

const AddQuestionModal = props => {
  const { isOpen, toggle, addNewQuestion, index, questionDetails } = props;
  const [selectedType, setSelectedType] = useState("");
  const [isOPen, setIsOpen] = useState(false);
  const [answerList, setAnswerList] = useState([]);
  const [selectedDropDownType, setSelectedDropDownType] = useState(
    TASK_TYPE_DROP_DOWN_SINGLE_SELECT
  );
  const [tagTypePermission, setTagTypePermission] = useState("");
  const [list, setList] = useState([]);
  const [selectConditionalDisplay, setSelectConditionalDisplay] = useState(
    ALWAYS_ASK
  );
  const [selectedQuestion, setSelectedQuestion] = useState("");
  const closeAddQuestionModal = () => {
    setSelectedType("");
    toggle();
  };
  useEffect(() => {
    getQuestionList();
  }, [isOpen]);

  //Type Dropdown Change
  const handleTypeChange = selectedTypeObject => {
    if (selectedTypeObject && selectedTypeObject.value) {
      setSelectedType(selectedTypeObject.value);
    }
    getQuestionList();
  };

  const _submit = values => {
    const lable =
      values && values.label ? (values.label + "_c").toLowerCase() : "";
    const data = new FormData();
    data.append("label", values.label || "");
    data.append("name", lable.split(" ").join("_"));
    data.append("showToPartner", values.showToPartner || false);
    data.append("order", index || 0);
    data.append(
      "type",
      (values.type && values.type.value && values.type.value) || ""
    );
    data.append(
      "conditionalDisplay",
      selectConditionalDisplay == ALWAYS_ASK
        ? ALWAYS_ASK
        : selectConditionalDisplay == CONDITIONAL
        ? "Conditional"
        : ""
    );
    data.append(
      "conditionalFieldId",
      values &&
        values.conditionalFieldId &&
        values.conditionalFieldId.id &&
        selectConditionalDisplay == CONDITIONAL
        ? values.conditionalFieldId.id
        : ""
    );
    data.append(
      "fieldValue",
      values &&
        values.fieldValue &&
        values.fieldValue.value &&
        selectConditionalDisplay == CONDITIONAL
        ? values.fieldValue.value
        : ""
    );
    data.append("selectType", selectedDropDownType ? selectedDropDownType : "");
    data.append(
      "customTags",
      values.customTags && values.customTags.length > 0
        ? JSON.stringify(values.customTags)
        : ""
    );

    data.append(
      "tagTypePermission",
      tagTypePermission === TAG_PERMISSION_SELECT_AND_ADD
        ? TAG_PERMISSION_SELECT_AND_ADD_VALUE
        : tagTypePermission === TAG_PERMISSION_SELECT_AND_NOT_ADD
        ? TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
        : ""
    );
    data.append(
      "requiredFor",
      (values.requiredFor &&
        values.requiredFor.value &&
        values.requiredFor.value) ||
        ""
    );

    addNewQuestion(data);
  };

  const initialValues = {
    label: "",
    name: "",
    type: "",
    conditionalFieldId: "",
    fieldValue: "",
    requiredFor: "",
    showToPartner: "",
    tagType_select: ""
  };
  const getQuestionList = () => {
    apiClient
      .get(`${endpoints().referralQuestionAPI}`)
      .then(response => {
        if (response.data && response.data.data) {
          const questionList = response.data.data;
          if (questionList && questionList.length > 0) {
            const List = [];
            questionList.forEach(data => {
              List.push({
                id: data.id,
                value: data.value,
                label: data.label,
                tagList: data.tagList,
                conditionalDisplay: data.conditionalDisplay
              });
            });
            setList(List);
            const answerList = [];
            list.forEach(data => {
              if (questionDetails.tagList !== data.tagList) {
                data.tagList.forEach(data => {
                  answerList.push({
                    value: data.value,
                    label: data.label
                  });
                });
              }
            });
            setAnswerList(answerList);
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };
  const defaultValue = [
    {
      label: "Is",
      name: "is"
    }
  ];
  const handleFunction = e => {
    setSelectedQuestion(e.conditionalFieldId.tagList);
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={closeAddQuestionModal}
      backdrop="static"
      className={["add-task-modal"].join(" ")}
    >
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          _submit(values);
        }}
      >
        <ModalHeader toggle={closeAddQuestionModal}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Add Qualification Question
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div>
              <Text
                id={`label`}
                name={`label`}
                label="Label"
                placeholder="Enter Label"
                required
                error=""
              />
            </div>
            <div>
              <Text
                className="d-none"
                id={`name`}
                name={`label`}
                label="Field Name"
                placeholder="Enter Field Name"
                required
                error=""
              />
            </div>
            <div>
              <Select
                label="Required for"
                name="requiredFor"
                placeholder="Select"
                options={requiredForOptions}
                isSearchable={true}
                required
                error=""
              />
            </div>
            <div>
              <Select
                label="Type"
                name="type"
                placeholder="Select"
                options={typepOptions}
                isSearchable={true}
                handleChange={e => handleTypeChange(e)}
              />
            </div>
            {selectedType === TASK_TYPE_DROP_DOWN_FIELD && (
              <>
                <div>
                  <Radio
                    name={`dropdownType`}
                    options={[
                      TASK_TYPE_DROP_DOWN_SINGLE_SELECT,
                      TASK_TYPE_DROP_DOWN_MULTI_SELECT
                    ]}
                    size="large"
                    label="Drop Down Type"
                    onChange={e => {
                      if (e && e.target && e.target.value) {
                        setSelectedDropDownType(e.target.value);
                      }
                    }}
                    showInline={true}
                    className="row ml-0 mr-0 mb-0 pl-1 pr-1"
                    radioClassName="col-6 mb-0"
                  />
                </div>

                <div className="field-wrapper">
                  <MultiselectCreatable
                    name={"customTags"}
                    label={"Field Values"}
                    placeholder={"Enter the field values"}
                    closeMenuOnSelect={false}
                  />
                </div>

                {selectedDropDownType === TASK_TYPE_DROP_DOWN_MULTI_SELECT && (
                  <div className="form-wrapper">
                    <h6 className="mb-2">Partner Permissions</h6>
                    <div className="px-3 w-100">
                      <Radio
                        name="tagTypePermissions"
                        options={[
                          TAG_PERMISSION_SELECT_AND_ADD,
                          TAG_PERMISSION_SELECT_AND_NOT_ADD
                        ]}
                        size="large"
                        onChange={e => {
                          if (e && e.target && e.target.value) {
                            setTagTypePermission(e.target.value);
                          }
                        }}
                        defaultValue={
                          tagTypePermission &&
                          tagTypePermission ===
                            TAG_PERMISSION_SELECT_AND_ADD_VALUE
                            ? TAG_PERMISSION_SELECT_AND_ADD
                            : tagTypePermission ===
                              TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
                            ? TAG_PERMISSION_SELECT_AND_NOT_ADD
                            : ""
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}
            <label>Conditional Display</label>
            <div className="pl-3">
              <Radio
                name="conditionalDisplay"
                options={[ALWAYS_ASK, CONDITIONAL]}
                size="large"
                onChange={e => {
                  if (e && e.target && e.target.value) {
                    setSelectConditionalDisplay(e.target.value);
                  }
                }}
              />
            </div>
            {selectConditionalDisplay === CONDITIONAL && (
              <div className="row">
                <span className="pl-3 mt-2">if</span>
                <div className="col-5 p-0 ml-2">
                  <Select
                    name="conditionalFieldId"
                    placeholder="Select"
                    options={list}
                    isSearchable={true}
                    onInputChange={e => {
                      handleFunction(e.values);
                    }}
                  />
                </div>
                <div className="col-2 px-1">
                  <Select
                    name="name"
                    placeholder="Select"
                    options={defaultValue}
                    defaultValue={defaultValue}
                  />
                </div>
                <div className="col-4 px-2">
                  <Select
                    name="fieldValue"
                    placeholder="Select"
                    options={selectedQuestion}
                    isSearchable={true}
                  />
                </div>
              </div>
            )}
            <div
              className={["set-expert-visibility pl-0"].join(" ")}
              style={{ boxShadow: "0 0 0 0", zIndex: 0 }}
            >
              <label> Show to Partner </label>
              <div className="pull-right">
                <Field
                  name="showToPartner"
                  render={({ field, form }) => {
                    return (
                      <ToggleSwitch
                        name="showToPartner"
                        value={field.value}
                        handleChange={() => {
                          form.setFieldValue("showToPartner", !field.value);
                        }}
                        outlined
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <Button
              label="Add New Question"
              type="submit"
              className="btn btn-primary"
            />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const EditQuestionModal = props => {
  let { questionDetails, isOpen, toggle, updateQuestion } = props;

  let {
    id,
    requiredFor,
    conditionalDisplay,
    conditionalFieldId,
    fieldValue,
    showToPartner,
    type,
    label,
    selectType,
    tagList,
    tagTypePermission
  } = questionDetails;

  const [list, setList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [answerList, setAnswerList] = useState([]);
  const [selectedType, setSelectedType] = useState("");
  const [selectedDropDownType, setSelectedDropDownType] = useState(selectType);
  const [selectedTagTypePermission, setSelectedTagTypePermission] = useState(
    tagTypePermission
  );
  const [selectConditionalDisplay, setSelectConditionalDisplay] = useState(
    ALWAYS_ASK
  );
  const [selectedQuestion, setSelectedQuestion] = useState("");
  useEffect(() => {
    if (conditionalDisplay !== selectConditionalDisplay) {
      setSelectConditionalDisplay(conditionalDisplay);
    }
    handleFunction(conditionalDisplay);
  }, [conditionalDisplay]);
  const closeEditQuestionModal = () => {
    toggle();
    setSelectedType("");
  };

  useEffect(() => {
    setSelectedDropDownType(selectType);
    getQuestionList();
  }, [selectType]);
  const handleFunction = value => {
    const data = value == ALWAYS_ASK ? ALWAYS_ASK : "Conditional";
    setSelectConditionalDisplay(data);
  };
  // split array Answer List
  const otherAnswerList = [];
  list.forEach(data => {
    if (data.label === selectedQuestion) {
      data.tagList.forEach(data => {
        otherAnswerList.push({
          value: data.value,
          label: data.label
        });
      });
    }
  });
  const updateQuestionDetails = values => {
    const data = new FormData();
    const lable =
      values && values.label ? (values.label + "_c").toLowerCase() : "";
    data.append("label", values.label || "");
    data.append("name", lable.split(" ").join("_"));
    data.append("showToPartner", values.showToPartner || false);
    data.append(
      "type",
      (values.type && values.type.value && values.type.value) || ""
    );
    data.append(
      "requiredFor",
      (values.requiredFor &&
        values.requiredFor.value &&
        values.requiredFor.value) ||
        ""
    );
    data.append(
      "conditionalDisplay",
      selectConditionalDisplay === ALWAYS_ASK ? ALWAYS_ASK : "Conditional"
    );
    data.append(
      "conditionalFieldId",
      values &&
        values.conditionalFieldId &&
        values.conditionalFieldId.id &&
        selectConditionalDisplay === "Conditional"
        ? values.conditionalFieldId.id
        : ""
    );
    data.append(
      "fieldValue",
      values &&
        values.fieldValue &&
        values.fieldValue.value &&
        selectConditionalDisplay === "Conditional"
        ? values.fieldValue.value
        : ""
    );
    data.append("selectType", selectedDropDownType ? selectedDropDownType : "");

    data.append(
      "customTags",
      values.customTags && values.customTags.length > 0
        ? JSON.stringify(values.customTags)
        : ""
    );
    data.append(
      "tagTypePermission",
      selectedTagTypePermission === TAG_PERMISSION_SELECT_AND_ADD
        ? TAG_PERMISSION_SELECT_AND_ADD_VALUE
        : selectedTagTypePermission === TAG_PERMISSION_SELECT_AND_NOT_ADD
        ? TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
        : ""
    );

    updateQuestion(id, data);
    closeEditQuestionModal();
  };

  //Type Dropdown Change
  const handleTypeChange = selectedTypeObject => {
    if (selectedTypeObject && selectedTypeObject.value) {
      setSelectedType(selectedTypeObject.value);
    }
  };
  let initialValues = {
    label: label || "",
    name: label || "",
    showToPartner: showToPartner || "",
    requiredFor: requiredFor
      ? requiredForOptions.find(
          requiredForOption => requiredForOption.value === requiredFor
        )
      : "",
    conditionalFieldId: conditionalFieldId
      ? list.find(option =>
          option.id === conditionalFieldId ? option.label : ""
        )
      : "",
    type: type
      ? typepOptions.find(
          questionTypeOptionDetails => questionTypeOptionDetails.value === type
        )
      : "",
    customTags: tagList || []
  };
  if (selectConditionalDisplay != ALWAYS_ASK) {
    initialValues.fieldValue =
      fieldValue && fieldValue
        ? answerList.find(option =>
            option.value === fieldValue
              ? { label: option.label, value: option.value }
              : ""
          )
        : "";
  }

  const getQuestionList = () => {
    setIsLoading(true);
    apiClient
      .get(`${endpoints().referralQuestionAPI}`)
      .then(response => {
        if (response.data && response.data.data) {
          const questionList = response.data.data;
          if (questionList && questionList.length > 0) {
            const List = [];
            questionList.forEach(data => {
              if (questionDetails.label !== data.label) {
                List.push({
                  id: data.id,
                  value: data.value,
                  label: data.label,
                  tagList: data.tagList
                });
              }
            });
            setList(List);
            const answerList = [];
            list.forEach(data => {
              if (questionDetails.tagList !== data.tagList) {
                data.tagList.forEach(data => {
                  answerList.push({
                    value: data.value,
                    label: data.label
                  });
                });
              }
            });
            setAnswerList(answerList);
          }
        }
        setIsLoading(false);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };
  const defaultValue = [
    {
      label: "Is",
      name: "is"
    }
  ];
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <Modal
      isOpen={isOpen}
      toggle={closeEditQuestionModal}
      backdrop="static"
      className={["add-task-modal"].join(" ")}
    >
      <Form
        initialValues={initialValues}
        enableReinitialize={true}
        onSubmit={values => {
          updateQuestionDetails(values);
        }}
      >
        <ModalHeader toggle={closeEditQuestionModal}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Edit Qualification Question
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div>
              <Text
                id={`label`}
                name={`label`}
                label="Label"
                placeholder="label"
                required
                error=""
              />
            </div>
            <div>
              <Text
                id={`name`}
                name={`label`}
                label="Field Name"
                placeholder="name"
                required
                error=""
              />
            </div>
            <Select
              label="Required for"
              name="requiredFor"
              placeholder="Select"
              options={requiredForOptions}
              isSearchable={true}
              required
              error=""
            />
          </div>
          <div>
            <Select
              label="Type"
              name="type"
              placeholder="Select"
              options={typepOptions}
              isSearchable={true}
              handleChange={e => handleTypeChange(e)}
            />
          </div>
          {(selectedType === TASK_TYPE_DROP_DOWN_FIELD ||
            (selectedType === "" && type === TASK_TYPE_DROP_DOWN_FIELD)) && (
            <>
              <div>
                <Radio
                  name={`dropdownType`}
                  options={[
                    TASK_TYPE_DROP_DOWN_SINGLE_SELECT,
                    TASK_TYPE_DROP_DOWN_MULTI_SELECT
                  ]}
                  size="large"
                  label="Drop Down Type"
                  onChange={e => {
                    if (e && e.target && e.target.value) {
                      setSelectedDropDownType(e.target.value);
                    }
                  }}
                  defaultValue={selectedDropDownType}
                  showInline={true}
                  className="row ml-0 mr-0 mb-0 pl-1 pr-1"
                  radioClassName="col-6 mb-0"
                />
              </div>

              <div className="field-wrapper">
                <MultiselectCreatable
                  name={"customTags"}
                  label={"Field Values"}
                  placeholder={"Enter the field values"}
                  closeMenuOnSelect={false}
                  options={tagList}
                />
              </div>

              {selectedDropDownType === TASK_TYPE_DROP_DOWN_MULTI_SELECT && (
                <div className="form-wrapper">
                  <h6 className="mb-2">Partner Permissions</h6>
                  <div className="px-3 w-100">
                    <Radio
                      name="tagTypePermissions"
                      options={[
                        TAG_PERMISSION_SELECT_AND_ADD,
                        TAG_PERMISSION_SELECT_AND_NOT_ADD
                      ]}
                      size="large"
                      onChange={e => {
                        if (e && e.target && e.target.value) {
                          setSelectedTagTypePermission(e.target.value);
                        }
                      }}
                      defaultValue={
                        tagTypePermission &&
                        tagTypePermission ===
                          TAG_PERMISSION_SELECT_AND_ADD_VALUE
                          ? TAG_PERMISSION_SELECT_AND_ADD
                          : tagTypePermission ===
                            TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
                          ? TAG_PERMISSION_SELECT_AND_NOT_ADD
                          : ""
                      }
                    />
                  </div>
                </div>
              )}
            </>
          )}
          <label>Conditional Display</label>
          <div className="pl-3">
            <Radio
              name="conditionalDisplay"
              options={[ALWAYS_ASK, CONDITIONAL]}
              size="large"
              onChange={e => {
                if (e && e.target && e.target.value) {
                  handleFunction(e.target.value);
                }
              }}
              defaultValue={
                selectConditionalDisplay == ALWAYS_ASK
                  ? ALWAYS_ASK
                  : CONDITIONAL
              }
            />
          </div>
          {selectConditionalDisplay != ALWAYS_ASK && (
            <div className="row">
              <span className="pl-3 mt-2">if</span>
              <div className="col-5 p-0 ml-2">
                <Select
                  name="conditionalFieldId"
                  placeholder="Select"
                  options={list}
                  isSearchable={true}
                  onInputChange={e => {
                    setSelectedQuestion(e.values.conditionalFieldId.label);
                  }}
                />
              </div>
              <div className="col-2 px-1">
                <Select
                  name="name"
                  placeholder="Select"
                  options={defaultValue}
                  defaultValue={defaultValue}
                />
              </div>
              <div className="col-4 px-2">
                <Select
                  name="fieldValue"
                  placeholder="Select"
                  options={otherAnswerList}
                  isSearchable={true}
                />
              </div>
            </div>
          )}
          <div>
            <div
              className={["set-expert-visibility pl-0"].join(" ")}
              style={{ boxShadow: "0 0 0 0", zIndex: 0 }}
            >
              <label> Show to Partner </label>
              <div className="pull-right">
                <Field
                  name="showToPartner"
                  render={({ field, form }) => {
                    return (
                      <ToggleSwitch
                        name="showToPartner"
                        value={field.value}
                        handleChange={() => {
                          form.setFieldValue("showToPartner", !field.value);
                        }}
                        outlined
                      />
                    );
                  }}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <SaveButton />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const QualificationQuestionSection = props => {
  const [isLoading, setIsLoading] = useState(true);
  const [questionList, setQuestionList] = useState([]);
  const [addQuestionModal, setAddQuestionModal] = useState(false);
  const [editQuestionModal, setEditQuestionModal] = useState(false);
  const [currentQuestion, setCurrentQuestion] = useState(false);
  const [deleteQuestionModal, setDeleteQuestionModal] = useState(false);
  const [sourceQuestionOrder, setSourceQuestionOrderState] = useState("");
  const [targetQuestionOrder, setTargetQuestionOrderState] = useState("");
  const [toggleValue, setToggleValue] = useState();
  const [readinessOption, setReadinessOption] = useState();
  const { saveSettings, adminSettings } = props;

  const { referral_show_customer_readiness } = adminSettings;
  const getQuestionList = () => {
    apiClient
      .get(`${endpoints().referralQuestionAPI}`)
      .then(response => {
        if (response.data && response.data.data) {
          setQuestionList(response.data.data);

          setIsLoading(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  const addNewQuestion = values => {
    apiClient
      .post(`${endpoints().referralQuestionAPI}`, values)
      .then(response => {
        if (response.data) {
          setIsLoading(true);
          toast.success(response.data.message);
          setAddQuestionModal(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            toast.error(errorMessage);
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  const updateQuestion = (id, values) => {
    apiClient
      .put(`${endpoints().referralQuestionAPI}/${id}`, values)
      .then(response => {
        if (response.data) {
          setCurrentQuestion("");
          setIsLoading(true);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  const deleteQuestion = id => {
    apiClient
      .delete(`${endpoints().referralQuestionAPI}/${id}`)
      .then(response => {
        if (response.data) {
          setCurrentQuestion("");
          setIsLoading(true);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (isLoading) {
      getQuestionList();
    }
  }, [isLoading]);

  //Get Settings
  const getSettingsData = async settingName => {
    return await apiClient
      .get(`${endpoints().settingAPI}/${settingName}`)
      .then(response => {
        const settings = response.data.data;
        if (settingName === SETTING_REFERRAL_SHOW_CUSTOMER_READINESS) {
          setToggleValue(settings.value);
        } else setReadinessOption(settings.value);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  useEffect(() => {
    if (isLoading) {
      getSettingsData(SETTING_REFERRAL_SHOW_CUSTOMER_READINESS);
      getSettingsData(SETTING_REFERRAL_CUSTOMER_READINESS_OPTION);
    }
  }, [isLoading]);

  // Set Current Order Position of Selected Question
  const setSourceQuestionOrder = tagType => {
    if (sourceQuestionOrder !== tagType.order) {
      setSourceQuestionOrderState(tagType.order);
    }
  };
  // Set Target Order Position of Selected Question
  const setTargetQuestionOrder = tagType => {
    if (targetQuestionOrder !== tagType.order) {
      setTargetQuestionOrderState(tagType.order);
    }
  };

  const updateQuestionOrder = updatedQuestionList => {
    apiClient
      .put(
        `${endpoints().referralQuestionAPI}/update/sortOrder`,
        updatedQuestionList
      )
      .then(response => {
        if (response.data) {
          setIsLoading(true);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  // Change Order Position of Selected Question
  const changeQuestionOrder = () => {
    // Current Selected Question List
    if (questionList && questionList.length > 0) {
      questionList.splice(
        targetQuestionOrder,
        0,
        questionList.splice(sourceQuestionOrder, 1)[0]
      );

      // Updated Selected Tag Type List
      const updatedQuestionList = [];

      questionList.forEach(tagType => {
        updatedQuestionList.push({
          ...tagType,
          // Update Selected Tag Type Order
          order: updatedQuestionList.length
        });
      });

      updateQuestionOrder(updatedQuestionList);
      setQuestionList(updatedQuestionList);
      setSourceQuestionOrderState("");
      setTargetQuestionOrderState("");
    } else {
      // Reset Order Positions of Selected Tag Type
      setSourceQuestionOrderState("");
      setTargetQuestionOrderState("");
    }
  };

  //Handle Readiness Checkbox
  const handleReadinessCheckbox = async e => {
    const data = new FormData();
    const selection = e.target.value;
    let defaultValue;
    let newSelection = [];

    readinessOptions.forEach(option => {
      if (selection === option.name) {
        newSelection.push(option.id);
      }
    });
    defaultValue = readinessOption && JSON.parse(readinessOption);
    if (Array.isArray(defaultValue)) {
      //Compare Array with Array if existing it will Remove otherwise Add
      compareArrayInArray(defaultValue, newSelection);
      defaultValue = JSON.stringify(defaultValue);
    } else {
      defaultValue = JSON.stringify(newSelection);
    }
    if (defaultValue) {
      data.append("referral_customer_readiness_option", defaultValue);
    }
    if (!defaultValue) {
      defaultValue = JSON.stringify(newSelection);
      data.append("referral_customer_readiness_option", defaultValue);
    }
    // Save settings
    await saveSettings(data);
    //Get Setting Data
    getSettingsData(SETTING_REFERRAL_CUSTOMER_READINESS_OPTION);
  };

  // Handle enable referral Question
  const handleEnableReferralQuestion = async value => {
    const data = new FormData();

    if (value !== undefined) {
      data.append("referral_show_customer_readiness", value);
    }

    // Save settings
    await saveSettings(data);
    //Get Setting Data
    getSettingsData(SETTING_REFERRAL_SHOW_CUSTOMER_READINESS);
  };

  const getValueByName = value => {
    const partnerType = readinessOptions.filter(
      partnerTypeTag => parseInt(partnerTypeTag.id) === parseInt(value)
    );
    return partnerType ? partnerType[0].name : "";
  };

  // Get checkbox options
  const getCheckBoxOption = data => {
    const selectedCheckbox = [];
    let values = JSON.parse(data);
    if (values && values && !Array.isArray(values)) {
      return "";
    }
    values &&
      values.forEach(value => {
        selectedCheckbox.push(getValueByName(value));
      });
    return selectedCheckbox;
  };

  //Initial Value
  const referralQuestionPermissionInitialValues = {
    referral_show_customer_readiness:
      referral_show_customer_readiness === "true"
  };

  return (
    <>
      <AddQuestionModal
        isOpen={addQuestionModal}
        toggle={() => {
          setAddQuestionModal(prevState => !prevState);
        }}
        addNewQuestion={addNewQuestion}
        index={questionList.length}
      />

      <EditQuestionModal
        isOpen={editQuestionModal}
        toggle={() => {
          setEditQuestionModal(prevState => !prevState);
        }}
        questionDetails={currentQuestion}
        updateQuestion={updateQuestion}
      />

      <DeleteModal
        isOpen={deleteQuestionModal}
        toggle={() => {
          setDeleteQuestionModal(prevState => !prevState);
        }}
        title="Delete Qualification Question"
        id={currentQuestion.id}
        label={currentQuestion.label}
        deleteFunction={deleteQuestion}
      />

      <div className="card p-3">
        <Form initialValues={referralQuestionPermissionInitialValues}>
          <Field
            name={SETTING_REFERRAL_SHOW_CUSTOMER_READINESS}
            render={({ field, form }) => {
              return (
                <>
                  <ToggleSwitch
                    name={SETTING_REFERRAL_SHOW_CUSTOMER_READINESS}
                    label={"Ask partners to confirm Customer Readiness"}
                    togglePosition={"right"}
                    value={field.value}
                    handleChange={() => {
                      handleEnableReferralQuestion(!field.value);
                      form.setFieldValue(
                        SETTING_REFERRAL_SHOW_CUSTOMER_READINESS,
                        !field.value
                      );
                    }}
                    outlined
                  />
                  {isTrue(toggleValue) && (
                    <div className="p-4">
                      <Checkbox
                        title={"Customer Readiness"}
                        titleFontBold
                        className={"pl-2"}
                        options={getCheckboxOptions(readinessOptions)}
                        selectedOptions={
                          readinessOption
                            ? getCheckBoxOption(readinessOption)
                            : ""
                        }
                        handleChange={e => {
                          handleReadinessCheckbox(e);
                        }}
                      />
                    </div>
                  )}
                </>
              );
            }}
          />
        </Form>
        <h5 className="font-weight-bold px-3 pb-3">Qualification Questions</h5>
        <div className="pb-0 pt-0 mb-0"></div>
        <div className="card">
          <div className="d-flex justify-content-between no-wrap relative bg-dark text-white">
            <h6 className="font-weight-bold pt-4 px-4 pb-3">Questions</h6>
            <h6 className="font-weight-bold pt-4 pl-4 pb-3 ml-5">
              Conditional Display
            </h6>
            <h6 className="font-weight-bold pt-4 px-4 pb-3">Action</h6>
          </div>

          <div className="task-list">
            {isLoading && <Spinner />}
            {questionList &&
              questionList.length > 0 &&
              questionList.map((taskItem, i) => (
                <>
                  <div
                    id={taskItem.order}
                    key={taskItem.id}
                    draggable="true"
                    onDrag={e => {
                      setSourceQuestionOrder(taskItem);
                    }}
                    onDragOver={e => {
                      e.preventDefault();
                      setTargetQuestionOrder(taskItem);
                    }}
                    onDrop={e => {
                      changeQuestionOrder();
                    }}
                  >
                    <Question
                      task={taskItem}
                      setEditQuestionModal={setEditQuestionModal}
                      setCurrentQuestion={setCurrentQuestion}
                      setDeleteQuestionModal={setDeleteQuestionModal}
                      updateQuestion={updateQuestion}
                    />
                  </div>
                </>
              ))}

            <div className=" task p-4" style={{ minHeight: "70px" }}>
              <div
                onClick={e => {
                  setAddQuestionModal(prevState => !prevState);
                }}
                className="task-left cursor-pointer"
              >
                <div className="checkbox add-task">
                  <PlusIcon />
                </div>
                <a className={["add-task text-link"].join(" ")}>
                  {/* Add a new task... */}
                  {questionList.length > 0
                    ? "Add Another Qualification Question"
                    : "Add Qualification Question"}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default QualificationQuestionSection;
