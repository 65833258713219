import React from "react";
import DefaultContent from "../content/DefaultContent";
import NoRecordsFound from "../base/NoRecordsFound";
// Assets
import ImageCarousel from "../base/imageCarasoul/ImageCarousel";
// import SimpleSlider from "../base/ImageCarousel1";

const PartnerMediaImages = props => {
  const { photoUpload, photos, handlePhotoDelete } = props;

  const imageRemoveHandler = photo => {
    handlePhotoDelete(photo);
  };

  return (
    <>
      <DefaultContent
        title={"Photos"}
        enableButton
        buttonLabel={
          <div className="">
            <input
              name="file"
              className="form-control d-none"
              type="file"
              id="photo"
              onChange={e => {
                photoUpload && photoUpload(e);
              }}
              accept="image/png,image/gif,image/jpeg"
              // ref={marketplaceLogoRef}
            />
            <span>
              <label className="mb-0" htmlFor="photo">
                Upload
              </label>
            </span>
          </div>
        }
      >
        {photos && photos.length > 0 ? (
          <div className="mt-3 d-block edit-profile-media mr-2 ml-2">
            <div className="d-block ">
              <ImageCarousel
                photos={photos}
                showRemove={true}
                handlePhotoDelete={imageRemoveHandler}
                editProfile
                slides={3}
              />
            </div>
          </div>
        ) : (
          <NoRecordsFound
            showMessage={true}
            hideCard={true}
            message="No Photos Found"
            position="top"
          />
        )}
        {/* <SimpleSlider /> */}
      </DefaultContent>
    </>
  );
};

export default PartnerMediaImages;
