import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { setHours, setMinutes } from "date-fns";
import DatePicker from "react-date-picker";
import { toast } from "react-toastify";
import { format } from "date-fns";
import moment from "moment";
// Configs
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";

// Component
import ReduxTable, { ReduxColumn } from "../base/ReduxTable";
import Text from "../../components/base/Text";
import DeleteButton from "../../components/base/DeleteButton";
import DefaultContent from "../content/DefaultContent";
import {
  getParamsByName,
  isCompanyAdmin,
  isExpert,
  isPartner,
  isCompanyManager,
  isSuperAdmin,
  getKeyValueByObject
} from "../../lib/helper";
import ActivityTimeline from "../base/ActivityTimeline";
import PlannedActivityTimeline from "../base/PlannedActivityTimeline";
import Form from "../base/Form";
import PageTitle from "../base/PageTitle";

// API Client
import { apiClient } from "../../apiClient";

import AddActivityModal from "./AddActivityModal";
import { SETTINGS_ENABLE_REFERRALS } from "../../setting/Constants";
import { useSelector } from "react-redux";

const PartnerProfileActivities = props => {
  const { getActivityList, settings } = props;

  const enableReferrals = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_REFERRALS)
    : "";
  const [activityNotes, setactivityNotes] = useState("");
  const [activityDate, setactivityDate] = useState("");
  const [activityId, setactivityId] = useState("");
  const [startDate, setStartDate] = useState("");
  const [plannedVisible, setPlannedVisible] = useState(4);
  const [pastVisible, setPastVisible] = useState(4);

  const toggle = () => setModal(props.closeEditModal);
  const [modal, setModal] = useState(false);
  var today = new Date();

  const showPlannedActivity = length => {
    length && setPlannedVisible(length);
  };
  const showPastActivity = length => {
    length && setPastVisible(length);
  };
  const LessPlannedActivity = length => {
    length && setPlannedVisible(4);
  };
  const LessPastActivity = length => {
    length && setPastVisible(4);
  };

  const currentUser = useSelector(state => state.user);

  async function activityMarketplaceUpdate(data, toggle) {
    try {
      const response = await apiClient.put(
        `${endpoints().activity}/update`,
        data
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      setModal(props.closeEditModal);
      await props.getActivityList(data.activityId);
      setTimeout(() => {
        toggle();
      }, 300);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  }

  // delete Site Name page api call by id
  function deleteSiteDetails(activityId) {
    apiClient
      .delete(`${endpoints().activity}/delete?id=${activityId})`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        setTimeout(() => {
          props.getActivityList(activityId);
        }, 300);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  return (
    <>
      {isPartner(currentUser && currentUser.roleId) && (
        <PageTitle label="Activity History" />
      )}

      {(isSuperAdmin(currentUser && currentUser.roleId) ||
        isCompanyAdmin(currentUser && currentUser.roleId) ||
        isCompanyManager(currentUser && currentUser.roleId)) && (
        <AddActivityModal
          partnerId={props.partnerId}
          getActivityList={getActivityList}
        />
      )}
      {(isSuperAdmin(currentUser && currentUser.roleId) ||
        isCompanyAdmin(currentUser && currentUser.roleId) ||
        isCompanyManager(currentUser && currentUser.roleId)) && (
        <Modal
          isOpen={modal}
          toggle={toggle}
          backdrop="static"
          className={["edit-task-modal"].join(" ")}
        >
          <Form
            initialValues={{
              activityNotes: activityNotes,
              startDate: activityDate,
              activityId: ""
            }}
            onSubmit={values => {
              values.startDate = startDate;
              values.activityType = "event";
              values.activityId = activityId;
              values.partnerId = props.partnerId;
              activityMarketplaceUpdate(values, toggle);
            }}
          >
            <ModalHeader toggle={toggle}>
              <h4 className={["font-weight-bold"].join(" ")}>Edit Activity</h4>
            </ModalHeader>
            <ModalBody className={["mb-4"].join(" ")}>
              <div className="form-wrapper">
                <div>
                  <Text
                    name="activityNotes"
                    label="Activity"
                    placeholder="Enter Activity"
                    required
                    error=""
                  />
                </div>
              </div>
              <div className="grouped-fields mt-4">
                <div className="">
                  <label>Activity Date</label>
                  <br></br>
                  <DatePicker
                    selected={startDate}
                    name="startDate"
                    className="datepicker"
                    placeholderText="DD-MM-YYYY"
                    required
                    minDate={new Date()}
                    onChange={date => setStartDate(date)}
                    dateFormat="DD-MM-YYYY"
                    clearIcon={true}
                    width={"150px"}
                    isClearable={true}
                    value={startDate}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter className={["justify-content-center"].join(" ")}>
              <div className="btn-wrapper">
                <DeleteButton
                  label={" Delete"}
                  onClick={() => {
                    toggle();
                    deleteSiteDetails(activityId);
                  }}
                />
              </div>
              <div className="btn-wrapper">
                <Button
                  label=""
                  type="submit"
                  className="btn btn-primary float-right"
                >
                  Save
                </Button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      )}
      <div
        style={{
          maxHeight: enableReferrals == "true" ? "600px" : "876px",
          height: enableReferrals == "false" && "870px",
          overflowY: "scroll",
          position: "relative"
        }}
      >
        {" "}
        <div className="card h-90" style={{ padding: "25px" }}>
          {props.plannedActivity && props.plannedActivity.length > 0 && (
            <>
              <DefaultContent
                className="stats-and-activity"
                style={{
                  boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
                  padding: "0px",
                  marginBottom: "0px"
                }}
              >
                <h5 className="font-weight-bold">Planned Activities</h5>
                <br></br>
                <div
                  className="card"
                  style={{
                    boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
                    padding: "0px"
                  }}
                >
                  {props.plannedActivity &&
                    props.plannedActivity.length > 0 &&
                    props.plannedActivity
                      .slice(0, plannedVisible)
                      .map(activity => (
                        <div
                          className="single-activity accordion accordion-flush"
                          style={{
                            cursor: `${
                              !isPartner(currentUser && currentUser.roleId)
                                ? "pointer"
                                : ""
                            }`
                          }}
                          onClick={() => {
                            setactivityNotes(activity.notes);
                            // setactivityDate(activity.activity_at);
                            setStartDate(new Date(activity.activity_at));
                            setactivityId(activity.id);
                            setModal(true);
                          }}
                        >
                          <PlannedActivityTimeline data={activity} />
                        </div>
                      ))}
                </div>
                <div
                  className="card"
                  style={{
                    boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
                    padding: "0px"
                  }}
                >
                  {props.plannedActivity &&
                    props.plannedActivity.length > plannedVisible && (
                      <div className="col-12">
                        <p
                          className="cursor-pointer"
                          onClick={() => {
                            showPlannedActivity(props.plannedActivity.length);
                          }}
                          style={{ color: "primary" }}
                        >
                          <p style={{ color: "#009DDA", fontSize: 12 }}>
                            Show all...
                          </p>
                          <i
                            className="fa pull-right pt-1"
                            aria-hidden="true"
                          ></i>
                        </p>
                      </div>
                    )}
                  {props.plannedActivity && plannedVisible >= 5 ? (
                    <div className="col-12">
                      <p
                        className="cursor-pointer mb-3"
                        onClick={() => {
                          LessPlannedActivity(props.plannedActivity.length);
                        }}
                        style={{ color: "primary" }}
                      >
                        <p style={{ color: "#009DDA", fontSize: 12 }}>
                          Show less...
                        </p>
                        <i
                          className="fa pull-right pt-1"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </DefaultContent>
              <hr />
            </>
          )}

          <DefaultContent
            className="stats-and-activity"
            style={{
              boxShadow: " 0 0 0 0 rgba(0, 0, 0, 0)",

              padding: "0px",

              marginBottom: "0px"
            }}
          >
            <h5 className="font-weight-bold">Past Activities</h5>
            <br></br>
            <div
              className="card"
              style={{ boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)", padding: "0px" }}
            >
              {props.pastActivity &&
                props.pastActivity.length > 0 &&
                props.pastActivity.slice(0, pastVisible).map(activity => (
                  <div className="single-activity accordion accordion-flush">
                    <div className="accordion-item">
                      <ActivityTimeline data={activity} />
                    </div>
                  </div>
                ))}
            </div>

            <div
              className="card"
              style={{ boxShadow: " 0 0 0 0 rgba(0, 0, 0, 0)", padding: "0px" }}
            >
              {props.pastActivity && props.pastActivity.length > pastVisible && (
                <div className="col-12">
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      showPastActivity(props.pastActivity.length);
                    }}
                    style={{ color: "primary" }}
                  >
                    <p style={{ color: "#009DDA", fontSize: 12 }}>
                      Show all...
                    </p>
                    <i className="fa pull-right pt-1" aria-hidden="true"></i>
                  </p>
                </div>
              )}
              {props.pastActivity && pastVisible >= 5 ? (
                <div className="col-12">
                  <p
                    className="cursor-pointer"
                    onClick={() => {
                      LessPastActivity(props.pastActivity.length);
                    }}
                    style={{ color: "primary" }}
                  >
                    <p style={{ color: "#009DDA", fontSize: 12 }}>
                      Show less...
                    </p>
                    <i className="fa pull-right pt-1" aria-hidden="true"></i>
                  </p>
                </div>
              ) : (
                ""
              )}
            </div>
          </DefaultContent>
          <hr />
        </div>
      </div>
    </>
  );
};

export default PartnerProfileActivities;
