import React from "react";

// Asstes
import {
  ActivityIcon,
  SendIcon,
  UserIcon,
  UserPlusIcon
} from "../../../assets/img/icons";

const getSteps = () => [
  {
    label: "Requested",
    icon: <SendIcon />
  },
  {
    label: "Matching",
    icon: <UserPlusIcon />
  },
  {
    label: "Interviewing",
    icon: <UserIcon />
  },
  {
    label: "In Process",
    icon: <ActivityIcon />
  }
];

export default getSteps;
