import React from "react";
import { getUrlParameter } from "../../../lib/helper";
import Number from "../../../components/base/Number";
import Text from "../../../components/base/Text";
import Select from "../../../components/base/Select";
import Country from "../../../components/base/Country";
import StateDropdown from "../../../components/State";
import Zipcode from "../../../components/base/Zipcode";
import CancelButton from "../../../components/base/CancelButton";
import Button from "../../../components/base/Button";
import CoSell from "../../../Constants/Cosell";
import { UNITED_STATES } from "../../../Country/Constants";
import Form from "../../../components/base/Form";
import URL from "../../../components/base/URL";
import Email from "../../../components/base/Email";
import Phone from "../../../components/base/Phone";

const CustomerDetailTab = props => {
  const { detail, enableReinitialize, showButton } = props;
  return (
    <div>
      <Form
        enableReinitialize={enableReinitialize}
        initialValues={{
          accountName:
            detail && detail.accountName
              ? detail.accountName
              : getUrlParameter("company_name"),
          website: detail && detail.website,
          firstName: detail && detail.firstName,
          lastName: detail && detail.lastName,
          title: detail && detail.title,
          email: detail && detail.email,
          phone: detail && detail.phone,

          address: detail && detail.address,
          city: detail && detail.city,
          postalCode: detail && detail.postalCode,
          customer_duns: detail && detail.customer_duns,
          industry_vertical: props.industryVerticalOption.find(
            value => value.value == detail.industry_vertical
          ),
          state: {
            value: detail && detail.state ? detail && detail.state : null,
            label: detail && detail.state ? detail && detail.state : null
          },
          country: {
            value: props.selectedCountryName
              ? props.selectedCountryName
              : detail && detail.country
              ? detail && detail.country
              : null,
            label: props.selectedCountryName
              ? props.selectedCountryName
              : detail && detail.country
              ? detail && detail.country
              : null
          }
        }}
        onSubmit={values => {
          props.handleCreate(values);
        }}
      >
        <div className="card border-0">
          <div
            style={{
              backgroundColor: "#fafafa",
              borderBottom: "1px solid",
              borderColor: "#eaeded"
            }}
          >
            <div className="mt-4 mx-4">
              <label className="font-weight-bold  form-group">
                Customer Detail
              </label>
            </div>
          </div>
          <div className="card-body">
            <div className="col-lg-12">
              <div className="field-wrapper mt-3">
                <Number
                  name="customer_duns"
                  label="Customer DUNS"
                  placeholder="Enter Customer DUNS"
                  hintText="Customer data universal number system, 9 characters"
                  error=""
                  maxLength={9}
                />
              </div>
              <div className="field-wrapper mt-3">
                <Text
                  name="accountName"
                  label=" Company Name"
                  placeholder="Enter  Company Name"
                  required
                  error=""
                />
              </div>
              <div className="field-wrapper mt-2">
                <Text
                  name="firstName"
                  label=" First Name"
                  placeholder="Enter  First Name"
                />
              </div>

              <div className="field-wrapper">
                <Text
                  name="lastName"
                  label=" Last Name"
                  placeholder="Enter  Last Name"
                />
              </div>
              <div className="field-wrapper">
                <Text name="title" label=" Title" placeholder="Enter  Title" />
              </div>
              <div className="field-wrapper">
                <Email name="email" label=" Email" placeholder="Enter  Email" />
              </div>

              <div className="field-wrapper">
                <Phone
                  name="phone"
                  label="Customer Phone"
                  placeholder="Enter Customer Phone"
                />
              </div>
              <div className="field-wrapper mt-3">
                <Select
                  name="industry_vertical"
                  label="Industry Vertical"
                  placeholder="Enter Industry Vertical"
                  error=""
                  options={props && props.industryVerticalOption}
                  required
                />
              </div>

              <div className="field-wrapper">
                <URL
                  name={"website"}
                  label={"Customer Website"}
                  placeholder={"Enter Customer Website"}
                  required
                />
              </div>

              <div className="field-wrapper">
                <Text
                  name="address"
                  label="Address"
                  placeholder="Enter Address"
                />
              </div>

              <div className="field-wrapper">
                <Text name="city" label="City" placeholder="Enter City" />
              </div>
              <div className="field-wrapper">
                <Country
                  name="country"
                  label="Country"
                  placeholder="Select Country"
                  error=""
                  onChange={props.handleCountryChange}
                />
              </div>
              <div className="field-wrapper">
                <StateDropdown
                  name="state"
                  label="State/Province/Region"
                  placeholder="Enter State"
                  error=""
                  selectedCountry={props.selectedCountry}
                  selectedCountryName={
                    props.selectedCountryName ? props.selectedCountryName : ""
                  }
                  notify={true}
                />
              </div>

              <div className="field-wrapper">
                <Zipcode
                  name="postalCode"
                  label="Zip/Postal Code"
                  placeholder="Enter Zip Code"
                  required
                  locale={
                    props.selectedCountryName
                      ? props.selectedCountryName
                      : UNITED_STATES
                  }
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          <CancelButton
            label="Cancel"
            type="button"
            onClick={() => props.history.goBack()}
            className=" mt-3 mb-3"
          />
          {!showButton && (
            <Button label="Next" type="submit" className=" mt-3 mb-3" />
          )}
        </div>
      </Form>
    </div>
  );
};

export default CustomerDetailTab;
