import React from "react";

//API Call
import { endpoints } from "../../configs";
import { apiClient } from "../../apiClient";
import { isBadRequest } from "../../common/http";

//Constants
import {
  PARTNER_PERMISSION_ALL_PARTNER,
  PARTNER_PERMISSION_INDIVIDUAL_PARTNER,
  PARTNER_PERMISSION_FILTERED_PARTNERS
} from "../../resource/Constants";

//Components
import Checkbox from "../../components/base/Checkbox";
import Radio from "../../components/base/Radio";
import PartnerDropDown from "../../components/PartnerDropDown";

//Common
import { getCheckboxOptions } from "../../common/utils";
import { getKeyValueByObject } from "../../lib/helper";
import { SETTINGS_THEME_LAYOUT } from "../../setting/Constants";
class PartnerSelector extends React.Component {
  state = {
    partnerTierList: [],
    partnerTypeList: [],
    partnerTierLabelList: "",
    seletedPartnerTierName: "",
    formDetails: "",
    getTierNameById: "",
    sharingPermission: this.props.partnerPermissionDefaultValue
      ? this.props.partnerPermissionDefaultValue
      : PARTNER_PERMISSION_ALL_PARTNER,
    handleTagTirePermission: "",
    checkBox: {
      partnerTier:
        (this.props.checkboxFieldValue &&
          this.props.checkboxFieldValue.partnerTier) ||
        [],
      partnerType:
        (this.props.checkboxFieldValue &&
          this.props.checkboxFieldValue.partnerType) ||
        [],

      sharingPermission: this.props.sharingPermission
        ? this.props.sharingPermission
        : PARTNER_PERMISSION_ALL_PARTNER
    }
  };

  // partner type page api call
  getPartnerType = async () => {
    try {
      this.setState({ isLoading: true });
      apiClient.get(`${endpoints().partnerTypeAPI}/search`).then(response => {
        const partnterTypeList = response.data.data;

        if (partnterTypeList && partnterTypeList.length > 0) {
          const typeList = [];
          partnterTypeList
            .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
            .forEach(type => {
              typeList.push({
                id: type.id,
                name: type.name,
                value: type.name,
                label: type.name,
                tagTypeId: type.typeId
              });
            });
          this.setState({ partnerTypeList: typeList });
        }
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  // Get partner tiers
  getPartnerTier = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().partnerTierAPI}/search`
      );
      const results = response.data.data;
      if (results && results.length > 0) {
        const tierList = [];

        results
          .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
          .forEach(tier => {
            tierList.push({
              id: tier.id,
              name: tier.name,
              value: tier.name,
              label: tier.name
            });
          });
        this.setState({ partnerTierList: tierList });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  componentDidMount() {
    this.getPartnerTier();
    this.getPartnerType();
  }

  //Get Id By Name
  getIdByName = (name, values) => {
    let id = "";
    if (values && values.length > 0)
      values.forEach(value => {
        if (value.label == name) {
          id = value.id;
        }
      });
    return id;
  };

  //Get Name By Id
  getTypeNameById = (ids, values) => {
    let typeIds = [],
      tierIds = [],
      selectedType = [];

    ids &&
      ids.length &&
      ids.forEach(id => {
        if (id.type_id) {
          typeIds.push(id.type_id);
        }
        if (id.tier_id) {
          tierIds.push(id.tier_id);
        }
      });
    if (tierIds && tierIds.length >= 0) {
      tierIds.forEach(tierId => {
        if (values && values.length > 0)
          values.forEach(value => {
            if (tierId === value.id) selectedType.push(value.name);
          });
      });
    }
    if (typeIds && typeIds.length >= 0) {
      typeIds.forEach(typeId => {
        if (values && values.length > 0)
          values.forEach(value => {
            if (typeId === value.id) selectedType.push(value.name);
          });
      });
    }
    return selectedType;
  };

  //Get Name By Id
  getTierNameById = (ids, values) => {
    let tierIds = [];
    let selectedTier = [];
    ids &&
      ids.length &&
      ids.forEach(id => {
        tierIds.push(id.tier_id);
      });
    if (tierIds && tierIds.length > 0) {
      tierIds.forEach(typeId => {
        if (values && values.length > 0)
          values.forEach(value => {
            if (typeId === value.id) selectedTier.push(value.name);
          });
      });
    }
    return selectedTier;
  };

  //Handle Permission Change
  handlePermissionChange = e => {
    const permission = e.target.value;
    this.setState({ sharingPermission: permission });
    this.props.handlePermissionOnchange(permission);
  };

  //Handle Type Change
  handlePartnerTypeChange = e => {
    const typeName = e.target.value;
    this.props && this.props.handleTypeChange && this.props.handleTypeChange(e);
    const typeId = this.getIdByName(typeName, this.state.partnerTypeList);
    this.props &&
      this.props.handleTypeOption &&
      this.props.handleTypeOption(typeId);
  };

  //Handle Tier Change
  handlePartnerTireChange = e => {
    const tierName = e.target.value;
    this.props && this.props.handleTierChange && this.props.handleTierChange(e);
    const tierId = this.getIdByName(tierName, this.state.partnerTierList);
    this.props &&
      this.props.handleTierOption &&
      this.props.handleTierOption(tierId);
  };

  render() {
    const { partnerTypeList, partnerTierList } = this.state;
    const {
      label,
      permissionName,
      typeTitle,
      tierSelectedOptions,
      tierTitle,
      handlePartnerChange,
      size,
      errorLabel,
      typeSelectedOptions,
      tierName,
      typeName,
      permissionRequired,
      dropdownName,
      showPartnerTier,
      showPartnerType,
      partnerPermissionDefaultValue,
      dropdownLabel,
      showAllPermission,
      SelectedTierOptions,
      SelectedTypeOptions,
      settings
    } = this.props;

    const DefaultLayout = settings
      ? getKeyValueByObject(settings, SETTINGS_THEME_LAYOUT)
      : "";

    //Permissions Options
    let permissions = showAllPermission
      ? [
          PARTNER_PERMISSION_ALL_PARTNER,
          PARTNER_PERMISSION_FILTERED_PARTNERS,
          PARTNER_PERMISSION_INDIVIDUAL_PARTNER
        ]
      : [PARTNER_PERMISSION_ALL_PARTNER, PARTNER_PERMISSION_FILTERED_PARTNERS];

    const defaultTierValue = SelectedTierOptions
      ? this.getTierNameById(SelectedTierOptions, partnerTierList)
      : [];
    const defaultTypeValue = SelectedTypeOptions
      ? this.getTypeNameById(SelectedTypeOptions, partnerTypeList)
      : [];
    return (
      <>
        <div className="form-wrapper">
          <label className="font-weight-bold">
            {label ? label : "Partner Permissions"}{" "}
          </label>

          <div className="field-wrapper">
            <div className="form-group">
              <div className="ml-4">
                <Radio
                  name={permissionName || "sharingPermission"}
                  size={size || "large"}
                  options={permissions}
                  onChange={e => {
                    this.handlePermissionChange(e);
                  }}
                  defaultValue={partnerPermissionDefaultValue}
                />

                <div
                  className={DefaultLayout == "Fluid Width" ? "pl-4 ml-3" : ""}
                >
                  {this.state.sharingPermission ===
                    PARTNER_PERMISSION_FILTERED_PARTNERS && (
                    <>
                      {showPartnerTier ||
                      (!showPartnerType && !showPartnerTier) ? (
                        <div>
                          <Checkbox
                            title={tierTitle || "Partner Tier"}
                            titleFontBold
                            name={tierName}
                            options={getCheckboxOptions(partnerTierList)}
                            selectedOptions={
                              (SelectedTierOptions && defaultTierValue) ||
                              tierSelectedOptions
                            }
                            handleChange={e => {
                              this.handlePartnerTireChange(e);
                            }}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      {showPartnerType ||
                      (!showPartnerType && !showPartnerTier) ? (
                        <div>
                          <Checkbox
                            title={typeTitle || "Partner Type"}
                            titleFontBold
                            name={typeName}
                            options={getCheckboxOptions(partnerTypeList)}
                            selectedOptions={
                              (SelectedTypeOptions && defaultTypeValue) ||
                              typeSelectedOptions
                            }
                            handleChange={e => this.handlePartnerTypeChange(e)}
                          />
                        </div>
                      ) : (
                        ""
                      )}
                      <small
                        className={`text-danger ${
                          permissionRequired ? "d-block" : "d-none"
                        }`}
                      >
                        {errorLabel
                          ? errorLabel
                          : "Partner Tiers or Partner Types are required"}
                      </small>
                    </>
                  )}
                </div>
              </div>
              {this.state.sharingPermission ===
                PARTNER_PERMISSION_INDIVIDUAL_PARTNER && (
                <>
                  {showAllPermission && (
                    <>
                      <div className="field-wrapper w-100">
                        <PartnerDropDown
                          name={dropdownName}
                          label={dropdownLabel}
                          fontBolded
                          handleChange={handlePartnerChange}
                          required={true}
                        />
                      </div>
                    </>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default PartnerSelector;
