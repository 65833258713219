import {
  REQUEST_PROJECT_LIST,
  RECEIVE_PROJECT_LIST,
  FETCH_PROJECT_LIST_FAIL,
  CHANGE_PROJECT_PAGE
} from "../actionType/Constants";

export function projectReducer(
  state = {
    isFetching: false
  },
  action
) {
  switch (action.type) {
    // Request project list
    case REQUEST_PROJECT_LIST: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }
    // Receive project list
    case RECEIVE_PROJECT_LIST: {
      return Object.assign({}, state, {
        pages: Object.assign({}, state.pages, {
          [action.payload.currentPage]: action.payload.data.map(n => n.id)
        }),
        pagination: {
          currentPage: action.payload.currentPage,
          totalCount: action.payload.totalCount,
          completedProjectsCount: action.payload.completedProjectsCount,
          activeProjectsCount: action.payload.activeProjectsCount,
          draftProjectsCount: action.payload.draftProjectsCount,
          newProjectsCount: action.payload.newProjectsCount
        },
        isFetching: false
      });
    }
    // Fetch project list fail
    case FETCH_PROJECT_LIST_FAIL:
      return { ...state, isFetching: false };

    // Change Project Page
    case CHANGE_PROJECT_PAGE: {
      return Object.assign({}, state, {
        pagination: Object.assign({}, state.pagination, {
          currentPage: action.payload
        })
      });
    }
    default:
      return state;
  }
}
