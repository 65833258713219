import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import toast from "../components/base/Toast";

class CrossBeamService {
  static async getPartners(callback) {
    try {
      apiClient.get(`${endpoints().crossbeamAPI}/partners`).then(response => {
        return callback(null, response);
      });
    } catch (err) {
      console.log(err);
      return callback(err, null);
    }
  }

  static async getMyPopulation(callback) {
    try {
      apiClient
        .get(`${endpoints().crossbeamAPI}/populations`)
        .then(response => {
          return callback(null, response);
        });
    } catch (err) {
      console.log(err);
      return callback(err, null);
    }
  }

  static async getPartnerPopulations(partnerId, callback) {
    try {
      apiClient
        .get(
          `${
            endpoints().crossbeamAPI
          }/partner/populations?partnerId=${partnerId}`
        )
        .then(response => {
          return callback(null, response);
        });
    } catch (err) {
      console.log(err);
      return callback(err, null);
    }
  }
}
export default CrossBeamService;
