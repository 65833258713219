import React from "react";

// Page Components
import LandingPageBlocksForm from "./LandingPageBlocksForm";

const LandingPageBlocksDetails = props => {
  return (
    <div>
      <LandingPageBlocksForm
        history={props.history}
        match={props.match}
        marketplaceId={props.marketplaceId}
        marketplaceName={props.marketplaceName}
        slugUrl={props.slugUrl}
        sectionId={props.sectionId}
        settings={props.settings}
        getLandingBlocksDetails={props.getLandingBlocksDetails}
      />
    </div>
  );
};

export default LandingPageBlocksDetails;
