/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { connect, useDispatch } from "react-redux";
import { bindActionCreators } from "redux";
import { Label } from "reactstrap";

// Components
import Text from "../../components/base/Text";
import TextArea from "../../components/base/TextArea";
import PreviewImage from "../adminSettings/tabs/MarketplaceTab/landingPageBlocks/PreviewImage";
import ColorPicker from "../../components/base/ColorPicker";
import HighContrastColorPicker from "../../components/base/HighContrastColorPicker";
import URL from "../../components/base/URL";
import Drawer from "../../components/Drawer";
import marketplaceFeaturedCollectionService from "../../services/marketplaceFeaturedCollectionService";
import { addLandingPage } from "../../actions/landingPage";

const AddFeatureCollectionModal = ({
  collectionData,
  isOpen,
  toggle,
  detail
}) => {
  const [formData, setFormData] = useState({
    title: "",
    isOpen: false,
    backgroundImagePreview: "",
    backgroundImage: "",
    backgroundColor: "",
    textColor: "",
    buttonLabel: "",
    buttonLink: ""
  });

  const [fileName, setFileName] = useState("");

  const dispatch = useDispatch();

  useEffect(() => {
    setFormData({
      title: collectionData.title || "",
      isOpen: false,
      backgroundImagePreview: collectionData.backgroundImage || "",
      backgroundImage: collectionData.backgroundImage || "",
      backgroundColor: collectionData.background_color || "",
      textColor: collectionData.text_color || "",
      buttonLabel: collectionData.button_label || "",
      buttonLink: collectionData.button_link || ""
    });
  }, [collectionData]);
  const handleImageChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files ? e.target.files[0] : "";
      setPreviewImage(file, e.target.name);
      setFormData({ ...formData, [`${e.target.name}`]: file });
      setFileName(file && file.name);
    }
  };

  const setPreviewImage = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      setFormData({ ...formData, [`${state}Preview`]: reader.result });
    };
  };

  const handleImageRemove = e => {
    setFormData({
      ...formData,
      [`${e.target.id}`]: "",
      [`${e.target.id}Preview`]: ""
    });
  };

  const handleBackgroundColor = e => {
    setFormData({ ...formData, backgroundColor: e.target.id });
  };

  const handleContrastColorSelect = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async values => {
    const data = new FormData();
    data.append("title", values.title ? values.title : "");
    data.append("description", values.description ? values.description : "");
    data.append(
      "backgroundImage",
      formData.backgroundImagePreview ? formData.backgroundImagePreview : ""
    );
    data.append(
      "backgroundColor",
      formData.backgroundColor ? formData.backgroundColor : ""
    );
    data.append("fileName", fileName ? fileName : "");
    data.append("textColor", formData.textColor ? formData.textColor : "");
    data.append("buttonLabel", values.buttonLabel ? values.buttonLabel : "");
    data.append("buttonLink", values.buttonLink ? values.buttonLink : "");
    data.append("landing_page_id", detail && detail.landingPageId);

    data.append("landing_page_block_id", detail && detail.id);
    if (!collectionData.id) {
      dispatch(
        await marketplaceFeaturedCollectionService.create(
          data,
          detail && detail.id,
          res => {
            if (res) {
              toggle();
              setFormData({});
            }
          }
        )
      );
    } else {
      dispatch(
        await marketplaceFeaturedCollectionService.update(
          data,
          collectionData.id,
          detail && detail.id,
          res => {
            if (res) {
              toggle();
              setFormData({});
            }
          }
        )
      );
    }
  };

  const header = (
    <div className="row">
      <div className="mb-2 col-12">
        <Text
          name="title"
          label="Title"
          placeholder="Title..."
          error=""
          required
        />
      </div>

      <div className="mb-2 col-12">
        <TextArea
          name="description"
          label="Description"
          required
          placeholder="Enter Description..."
        />
      </div>
      <div className="col-12">
        <input
          name="backgroundImage"
          className="form-control d-none"
          type="file"
          id="backgroundImage"
          onChange={handleImageChange}
        />
        <span className="profilePicOverlay d-block mb-3 p-0 col-12">
          <label htmlFor="backgroundImage" className="profile-img-sm mb-0">
            <span className="banner-image-upload-link">
              Upload Collection Image
            </span>
          </label>
        </span>
        {formData &&
        (formData.backgroundImagePreview || formData.backgroundImage) ? (
          <div>
            <PreviewImage
              imageUrl={formData.backgroundImage}
              previewImage={formData.backgroundImagePreview}
              height="150px"
            />
            <span
              id="backgroundImage"
              className="banner-image-upload-link mb-2 text-danger"
              onClick={handleImageRemove}
            >
              Remove
            </span>
          </div>
        ) : (
          ""
        )}
        <div className="col-12 p-0">
          <Label>Background Color</Label>
          <div className="row">
            <div className="col-md-6">
              <ColorPicker
                id={collectionData.background_color}
                onColourPickerClick={handleBackgroundColor}
                bannerColor={formData.backgroundColor}
              />
            </div>
          </div>
        </div>

        <div className="col-12 mt-2 p-0">
          <Label>Text Color</Label>
          <div className="row">
            <div className="col-12">
              <HighContrastColorPicker
                handleSelect={handleContrastColorSelect}
                selectedColor={formData.textColor}
                inputName={"textColor"}
              />
            </div>
          </div>

          <div className="mt-2 col-12 p-0">
            <Text
              name="buttonLabel"
              label="Button Label"
              placeholder="Enter button label"
              required
              error=""
            />
          </div>
          <div className="col-12 p-0">
            <URL name="buttonLink" placeholder="Enter button link" required />
          </div>
        </div>
      </div>
    </div>
  );

  const initialValues = {
    title: collectionData.title || "",
    buttonLabel: collectionData.button_label || "",
    buttonLink: collectionData.button_link || "",
    description: collectionData.description || "",
    description: collectionData.description || "",
    background_color: collectionData.background_color || ""
  };

  const footer = (
    <div className="text-center">
      <Button type="submit" label="">
        {collectionData && collectionData.id ? " Update" : "Create"}
      </Button>
    </div>
  );

  return (
    <div>
      <Drawer
        modelTitle={
          collectionData && collectionData.id
            ? "Edit Collection"
            : "Add Collection"
        }
        DrawerBody={header}
        DrawerFooter={footer}
        onSubmit={handleSubmit}
        initialValues={initialValues}
        handleOpenModal={toggle}
        handleCloseModal={toggle}
        handleDrawerClose={toggle}
        isModalOpen={isOpen}
        enableReinitialize
        showAddButton
        buttonLabel="Add"
      />
    </div>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ addLandingPage }, dispatch)
  };
};

export default connect(
  () => ({}),
  mapDispatchToProps
)(AddFeatureCollectionModal);
