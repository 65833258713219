import React from "react";

// Components
import Form from "../../../../components/base/Form";
import Text from "../../../../components/base/Text";
import PreferredMethodOfCommunication from "../../../../components/PreferredMethodOfCommunication";
import KickoffTimeZone from "../../../../components/KickoffTimeZone";

import {
  COMMUNICATION_METHOD_WEB_CONFERERENCE,
  COMMUNICATION_METHOD_PHONE
} from "../../../../kickOffCall/Constants";

const ConfirmedKickoffMeetingForm = props => {
  const { kickoffData } = props;
  let {
    communicationMethod,
    meeting_link,
    meeting_id,
    meeting_password,
    timezone,
    phone
  } = kickoffData;

  const preferredCommunicationMethod = communicationMethod;

  communicationMethod = {
    label: communicationMethod,
    value: communicationMethod
  };

  return (
    <>
      <Form
        initialValues={{
          meetingLink: meeting_link,
          meetingId: meeting_id,
          meetingPassword: meeting_password,
          phone: phone
        }}
        className={["d-block"].join(" ")}
        onSubmit={() => {
          return;
        }}
      >
        <div className="form-wrapper">
          <div className="row">
            <div className="col-md-5 text-right">
              <label>Preferred Method of Communication:</label>
            </div>
            <div className="col-md-6">
              <PreferredMethodOfCommunication
                name="communication_method"
                placeholder="Select Preferred Method Of Communication"
                defaultValue={communicationMethod}
                isDisabled={true}
              />
            </div>
          </div>
          {preferredCommunicationMethod ===
            COMMUNICATION_METHOD_WEB_CONFERERENCE && (
            <>
              <div className="row">
                <div className="col-md-5 text-right">
                  <label>Meeting Link:</label>
                </div>
                <div className="col-md-6">
                  <Text
                    name="meetingLink"
                    placeholder="Meeting Link"
                    disabled
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 text-right">
                  <label>Meeting ID:</label>
                </div>
                <div className="col-md-6">
                  <Text name="meetingId" placeholder="Meeting Id" disabled />
                </div>
              </div>
              <div className="row">
                <div className="col-md-5 text-right">
                  <label>Meeting Password:</label>
                </div>
                <div className="col-md-6">
                  <Text
                    name="meetingPassword"
                    placeholder="Meeting Password"
                    disabled
                  />
                </div>
              </div>
            </>
          )}

          {preferredCommunicationMethod === COMMUNICATION_METHOD_PHONE && (
            <>
              <div className="row">
                <div className="col-md-5 text-md-right text-sm-left">
                  <label className="font-weight-bold">Phone:</label>
                </div>
                <div className="col-md-6">
                  <Text name="phone" placeholder="Phone number" disabled />
                </div>
              </div>
            </>
          )}

          <div className="row">
            <div className="col-md-5 text-right">
              <label>Time Zone:</label>
            </div>
            <div className="col-md-6">
              <KickoffTimeZone
                name="timezone"
                placeholder="Select Time Zone"
                defaultValue={timezone}
                isDisabled={true}
              />
            </div>
          </div>
        </div>
      </Form>
    </>
  );
};

export default ConfirmedKickoffMeetingForm;
