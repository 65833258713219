import React from "react";
import ExpertRequestQuestion from "./ExpertRequestQuestion";

// Components
import SecondaryButton from "../../components/base/SecondaryButton";

// Assets
import { TimelineIcon } from "../../assets/img/icons";

class Timeline extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timelineOptions: [
        {
          value: "<1 month",
          label: "<1 month",
          tooltip: ""
        },
        {
          value: "1-2 months",
          label: "1-2 months",
          tooltip: ""
        },
        {
          value: "3-5 months",
          label: "3-5 months",
          tooltip: ""
        },
        {
          value: "6+ months",
          label: "6+ months",
          tooltip: ""
        },
        {
          value: "I want help figuring this out",
          label: "I want help figuring this out",
          tooltip: ""
        },
        {
          value: "I'll decide later",
          label: "I'll decide later",
          tooltip: ""
        }
      ]
    };
  }

  render() {
    const { currentStep, step, prev, next } = this.props;
    const { timelineOptions } = this.state;

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <TimelineIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-4">Timeline</h2>
        </div>
        <div className="subtitle-wrapper mt-4">
          <h5 className="font-weight-light">
            How long do you need the expert?
          </h5>
        </div>
        <div className="content">
          <ExpertRequestQuestion
            name="timeline"
            options={timelineOptions}
            error="Timeline is required"
            required
          />
          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={next}
            >
              Next: Time Commitment
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Timeline;
