class Url {
  static get(url, params) {
    try {
      let apiUrl;
      let queryString = new Array();

      if (params) {
        Object.keys(params).forEach(param => {
          if (params[param]) {
            queryString.push(`${param}=${params[param]}`);
          }
        });
      }

      if (queryString && queryString.length > 0) {
        apiUrl = `${url}?${queryString.join("&")}`;
      } else {
        apiUrl = `${url}`;
      }
      return apiUrl;
    } catch (err) {
      console.log(err);
    }
  }
  static updateQueryStringParameter(uri, key, value) {
    const re = new RegExp("([?&])" + key + "=.*?(&|$)", "i");
    const separator = uri.indexOf("?") !== -1 ? "&" : "?";
    if (uri.match(re)) {
      return uri.replace(re, "$1" + key + "=" + value + "$2");
    } else {
      return uri + separator + key + "=" + value;
    }
  }
}
export default Url;
