import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormGroup, Input, FormFeedback } from "reactstrap";
import Label from "../base/Label";
import Hint from "./Hint";
import Select from "./Select";
import { getStringToUrl } from "../../lib/helper";

class TextArea extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      inputValue: "",
      referralLink: ""
    };

    this.textareaRef = React.createRef();
  }

  componentDidMount() {
    this.setState({
      inputValue: this.textareaRef.current.props.value
    });
  }

  validate(value) {
    const { label, placeholder, required, error } = this.props;

    let errorMessage;
    const inputLabel = label || placeholder;
    const errorMessageLabel = error;

    if ((!value || !value.trim()) && required) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    }

    return errorMessage;
  }

  // get referral link pattern
  getRefeeralLink = value => {
    let pattern = getStringToUrl(value)
      .toUpperCase()
      .replace(/-/g, "_");
    return pattern;
  };

  // set referral link
  handleLinkChange = link => {
    let selectedLink = this.getRefeeralLink(link.value);
    let linkValue;
    if (link) {
      linkValue = `%REFERRAL_${selectedLink}%`;
    }
    this.setState(prevState => ({
      referralLink: prevState.inputValue.concat(linkValue)
    }));
  };

  renderInput({ field, form: { touched, errors } }) {
    const {
      name,
      id,
      label,
      placeholder,
      required,
      rows,
      onChange,
      defaultValue,
      maxLength,
      showCharCount,
      disabled,
      fontBolded,
      className,
      hintText,
      value,
      showReferralLinks,
      referrLinkOptions
    } = this.props;

    const fieldMinHeight = rows ? rows * 23 : 92;
    const errorMessage = touched[name] && errors[name] ? errors[name] : null;
    const inputId = id || name;

    const countInputChars = () => {
      if (this.state.inputValue) {
        return this.state.inputValue.length;
      }
      return 0;
    };

    const setInputValue = e => {
      const { value } = e.target;
      this.setState({
        inputValue: value,
        referralLink: ""
      });
    };

    let inputProps = new Object();

    if (value) {
      inputProps.value = value;
    }

    // update the referral link to textarea
    if (this.state.referralLink) {
      inputProps.value = this.state.referralLink;
    }

    return (
      <FormGroup style={{ position: "relative" }} className={`${className} `}>
        <div className="row">
          <div className="col-4 col-lg-4 mt-1">
            {label && (
              <Label
                className={`${fontBolded ? "font-weight-bold" : ""}`}
                id={inputId}
                required={required}
              >
                {label}
              </Label>
            )}
          </div>
          <div className="col-8">
            {showReferralLinks && (
              <Select
                id={`referralLink`}
                name="referralLink"
                placeholder="Select Referral Link"
                options={referrLinkOptions}
                isSearchable={true}
                handleChange={e => {
                  this.handleLinkChange(e);
                }}
              />
            )}
          </div>
        </div>

        <Input
          id={inputId}
          {...field}
          type="textarea"
          placeholder={placeholder || label}
          invalid={!!errorMessage}
          {...inputProps}
          rows={rows}
          onKeyUp={e => {
            setInputValue(e);
            onChange && onChange(e);
          }}
          defaultValue={defaultValue}
          style={{
            background: "#F3F3F4",
            borderRadius: "5px",
            fontSize: "14px",
            minHeight: fieldMinHeight
          }}
          maxLength={maxLength && maxLength}
          ref={this.textareaRef}
          disabled={disabled}
        />
        {showCharCount && maxLength && (
          <span className="char-count d-block text-inline-grayed h7">
            {`${countInputChars()}/${maxLength && maxLength} Characters`}
          </span>
        )}

        {hintText && (
          <div style={{ position: "absolute", marginTop: "6px" }}>
            <Hint hintText={hintText} />
          </div>
        )}

        {errorMessage && (
          <FormFeedback style={{ position: "absolute", marginTop: 1 }}>
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }

  render() {
    const { name, id } = this.props;

    return (
      <Field
        id={id || name}
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

TextArea.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  rows: PropTypes.string
};

export default TextArea;
