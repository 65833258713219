import React from "react";

// Page components
import StandardPricingPackage from "./StandardPricingPackage";
import PremiumPricingPackage from "./PremiumPricingPackage";
import BasicPricingPackage from "./BasicPricingPackage";

const PlaybookPackage = props => {
  return (
    <>
      <div className="form-wrapper">
        <StandardPricingPackage
          id="1"
          packageType="Standard"
          onChange={props.onChange}
          standardDeliverableOnChange={props.standardDeliverableOnChange}
          defaultDeliverables={props.defaultDeliverables}
          standardPackageDeliverables={props.standardPackageDeliverables}
        />

        <BasicPricingPackage
          id="2"
          packageType="Basic"
          onChange={props.onChange}
          basicDeliverableOnChange={props.basicDeliverableOnChange}
          defaultDeliverables={props.defaultDeliverables}
          basicPackageDeliverables={props.basicPackageDeliverables}
        />

        <PremiumPricingPackage
          id="3"
          packageType="Premium"
          onChange={props.onChange}
          premiumDeliverableOnChange={props.premiumDeliverableOnChange}
          defaultDeliverables={props.defaultDeliverables}
          premiumPackageDeliverables={props.premiumPackageDeliverables}
        />
      </div>
    </>
  );
};

export default PlaybookPackage;
