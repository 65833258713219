import React from "react";
import { CrossIcon, InlineInfoIcon } from "../../../assets/img/icons";
import { Alert } from "reactstrap";

function PaymentOverdueAlert({ onclick }) {
  return (
    <Alert
      color="danger"
      className={"d-flex align-items-center justify-content-stretch text-white"}
    >
      <InlineInfoIcon />
      <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
        <span className="font-weight-bold">
          Your payment is overdue and your account is at risk
        </span>
      </p>
      <div className="btn-wrapper ml-auto mr-3">
        <button className="btn btn-outline-light">View Invoice</button>
      </div>
      <div className="close-alert mr-0 ml-0">
        <button className="btn-plain btn text-white p-0" onClick={onclick}>
          <CrossIcon />
        </button>
      </div>
    </Alert>
  );
}

const CustomerAlerts = ({ handlePaymentOverdueNotification }) => {
  return (
    <div className={["alerts-wrapper"].join(" ")}>
      <PaymentOverdueAlert onclick={handlePaymentOverdueNotification} />
    </div>
  );
};

export default CustomerAlerts;
