import React, { useState } from "react";
import AvatarCard from "../../components/base/AvatarCard";
import Select from "../../components/base/Select";

const getDropDownTemplate = (first_name, last_name, title) => {
  return (
    <div className="">
      <AvatarCard
        firstName={first_name}
        lastName={last_name}
        title={title}
        size="xs"
        fontSize={8}
      />
    </div>
  );
};

const HubSpotUserSelector = ({ userList, updateHandle, placeholderText }) => {
  let selectOptions = [];

  for (let index = 0; index < userList.length; index++) {
    selectOptions.push({
      id: userList[index].id,
      hubspot_id: userList[index].hubspot_id,
      hubspot_user_id: userList[index].hubspot_user_id,
      value: userList[index].hubspot_id,
      label: getDropDownTemplate(
        userList[index].first_name,
        userList[index].last_name,
        userList[index].title
      )
    });
  }

  return (
    <div className="dropdown-wrapper">
      <Select
        id="referralOwner"
        name="referralOwner"
        label="Owner"
        fontBolded
        options={selectOptions}
        placeholder={placeholderText}
        fullWidth={true}
        isSearchable={true}
        onInputChange={data => {
          updateHandle(data && data.value && data.value.hubspot_id);
        }}
      />
    </div>
  );
};

export default HubSpotUserSelector;
