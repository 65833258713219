import React, { Component } from "react";

//Components
import Form from "../../components/base/Form";
import SingleRadio from "../../components/base/SingleRadio";
import toast from "../../components/base/Toast";
import ShowMoreContent from "../../components/base/showMoreContent";
import SaveButton from "../../components/base/SaveButton";
import CourseViewCard from "./CourseViewCard";
import SidebarItem from "./SidebarItem";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";
import { isBadRequest } from "../../common/http";
import TrainingSupportMaterial from "./inner-component/TrainingSupportMaterial";
import ReactPlayer from "react-player";
import Video from "../../lib/Video";
import VideoPlayer from "../../components/VideoPlayer";

export default class LessonModuleTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      correctAnswers: [],
      alphabet: "A"
    };
  }

  handleCorrectAnswer = (answerId, questionId) => {
    this.setState({
      correctAnswers: {
        ...this.state.correctAnswers,
        [`correctAnswer-${questionId}`]: `${answerId}`
      }
    });
  };

  //Submit API Call
  _answerSubmit = () => {
    const value = this.state.correctAnswers;
    this._createCorrectAnswer(this._toArray(value), false);
  };

  // To Array
  _toArray(values) {
    const data = new FormData();

    let correctAnswers = [];
    if (values && Object.keys(values).length > 0) {
      for (let fieldName of Object.keys(values)) {
        let fieldKey = `${fieldName}`.split("-");

        if (fieldKey[0] === "correctAnswer" && values[fieldName]) {
          const questionData = {
            questionId: fieldKey[1],
            correctAnswer: values[fieldName]
          };
          correctAnswers.push(questionData);
        }
      }
      data.append("correctAnswers", JSON.stringify(correctAnswers));
    }
    return data;
  }

  //Get Answer List by Question ID
  getCorrectAnswersByQuestionId = (questionId, values) => {
    let correctAnswers = "";
    if (values && Object.keys(values).length > 0) {
      Object.keys(values).forEach(fieldName => {
        let fieldKey = `${fieldName}`.split("-");

        if (
          fieldKey[0] === "correctAnswer" &&
          fieldKey[1] === questionId &&
          values[fieldName]
        ) {
          correctAnswers = values[fieldName];
        }
      });
    }
    return correctAnswers;
  };

  // Course Lesson Question Answer API Call
  async _createCorrectAnswer(data) {
    return await apiClient
      .post(`${endpoints().courseAPI}/partner/${this.props.questionId}`, data)
      .then(response => {
        this.setState({ courseId: response.data.courseId });
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.props.courseDetails();

        //Go to Next Lesson
        const courseLessons =
          (this.props.course && this.props.course.courseLesson) || [];
        const currentLesson = this.props.count;
        if (courseLessons.length > 0 && courseLessons[currentLesson + 1]) {
          this.props.toggle(courseLessons[currentLesson + 1].id);
          window.scrollTo(0, 0);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isSubmitting: false });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  isSelectedAnswer = (questionId, answerId) => {
    const answerList = (this.props.answer && this.props.answer.answer) || [];
    if (answerList.length > 0) {
      return answerList.find(
        answer =>
          answer.question_id === questionId && answer.answer_id === answerId
      );
    } else return false;
  };

  render() {
    const linkStyle = {
      color: "rgba(0, 0, 0, 0.25)"
    };
    const {
      lessonTitle,
      course,
      description,
      question,
      totalQuestionCount,
      answer,
      user,
      count,
      media,
      courseMedia,
      answerCount,
      multimedia_url
    } = this.props;

    const { alphabet } = this.state;
    const data = [];

    return (
      <>
        <Form
          onSubmit={values => {
            this._answerSubmit(values);
          }}
        >
          <div className="project-details mt-3 mx-1">
            <CourseViewCard data={course} />
            <div className="project-details-body border-top row mx-1">
              <div className="col-md-8 border-right pt-3">
                <p className="font-weight-bold my-2">{lessonTitle}</p>
                {media && media.length > 0 && (
                  <div className="pt-4">
                    <div className="field-wrapper">
                      <div className="user-wrapper">
                        <p className="font-weight-bold">
                          Training Lesson Materials
                        </p>
                        <span className="text-grayed pb-2">
                          Any material that will help you complete the lesson.
                        </span>
                        {media.map(mediaList => {
                          data.push(mediaList);
                        })}
                        <div className="pt-1">
                          <TrainingSupportMaterial data={data} />
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                <ShowMoreContent maxContentLength={400} content={description} />
                {multimedia_url && (
                  <div className=" text-break">
                    <div>URL of Existing Multimedia</div>
                    <a href={multimedia_url} target="_blank" rel="noreferrer">
                      {multimedia_url}
                    </a>
                  </div>
                )}
                <VideoPlayer url={multimedia_url} />

                <p className="font-weight-bold my-2">Lesson Module Quiz</p>
                <hr></hr>
                {
                  (question &&
                    question.length > 0 &&
                    question.sort(
                      (a, b) => parseFloat(a.id) - parseFloat(b.id)
                    ),
                  question.map((questionList, i) => {
                    let { id, question, questionAnswer } = questionList;
                    const questionId = id;
                    return (
                      <>
                        <div className="field-wrapper mx-3 mt-3">
                          <div className="user-wrapper">
                            <div className="row">
                              <p className="font-weight-bold ml-2 my-2">
                                {`Question ${i + 1}`}
                              </p>
                            </div>
                            <div className="row">
                              <div>
                                <p className="font-weight-bold ml-2 my-2">
                                  {question}
                                </p>
                              </div>
                            </div>
                            <div className="justify-content-center">
                              {
                                (questionAnswer &&
                                  questionAnswer.length > 0 &&
                                  questionAnswer.sort(
                                    (a, b) =>
                                      parseFloat(a.id) - parseFloat(b.id)
                                  ),
                                questionAnswer.map((answersList, i) => {
                                  let { id, answer } = answersList;
                                  return (
                                    <div className="justify-content-center">
                                      <div className="row mx-1">
                                        <div className="col-md-1">
                                          <SingleRadio
                                            name={`correctAnswer-${questionId}`}
                                            options={String.fromCharCode(
                                              alphabet.charCodeAt() + i
                                            )}
                                            size="large"
                                            fontBold
                                            onChange={() => {
                                              this.handleCorrectAnswer(
                                                id,
                                                questionId
                                              );
                                            }}
                                            defaultValue={
                                              this.isSelectedAnswer(
                                                questionId,
                                                id
                                              )
                                                ? true
                                                : false
                                            }
                                          />
                                        </div>
                                        <div className="col-md-11 pt-1">
                                          <p>{answer}</p>
                                        </div>
                                      </div>
                                    </div>
                                  );
                                }))
                              }
                            </div>
                          </div>
                        </div>
                        <hr></hr>
                      </>
                    );
                  }))
                }
              </div>
              <div className="col-md-4 pl-0 mb-4">
                <SidebarItem
                  course={course}
                  question={question}
                  totalQuestionCount={totalQuestionCount}
                  answer={answer}
                  user={user}
                  media={courseMedia}
                  answerCount={answerCount}
                />
              </div>
            </div>
            <div className="btn-wrapper justify-content-center pull-right pt-3 mb-3">
              <SaveButton label="Submit and Go to Next Module" />
            </div>
          </div>
        </Form>
      </>
    );
  }
}
