import React from "react";
import toast from "../../components/base/Toast";

// Components
import CollectionFormFields from "./CollectionFormFields";
import DeleteButton from "../../components/base/DeleteButton";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import Select from "../../components/base/Select";
import PlaybookCard from "../../components/PlaybookCard";
import PageTitle from "../../components/base/PageTitle";
import Form from "../../components/base/Form";
import Button from "../../components/base/Button";

// Configs
import { endpoints } from "../../configs";

//API call
import { apiClient } from "../../apiClient";

// Helper
import { toString } from "../../lib/helper";

// Constant
import { PLAYBOOK_STATUS_ACTIVE } from "../../playbookStatus/Constants";
import { isBadRequest } from "../../common/http";

export default class UpdateCollectionForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      collectionDetails: [],
      loading: false,
      isLoading: false,
      isplayBookCollectionLoading: false,
      playbooks: [],
      playbooksCollection: ""
    };
  }

  componentDidMount = () => {
    this._getCollectionDetails();
    this.getPlaybooksOptions();
    this.getPlaybooksByCollectionId();
  };

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save name form in the final
    this._updateCollection(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define playbook constants
    const name = values.name;

    if (!name) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.name = toString(values.name);
    return values;
  }

  // Collection update API Call
  _updateCollection(data) {
    return apiClient
      .put(`${endpoints().collectionAPI}/${this.props.match.params.id}`, data)
      .then(() => {
        window.location.replace("/collection");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Collection delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().collectionAPI}/${this.props.match.params.id}`)
      .then(() => {
        window.location.replace("/collection");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Collecion details api call by id
  _getCollectionDetails = () => {
    return apiClient
      .get(`${endpoints().collectionAPI}/${this.props.match.params.id}`)
      .then(response => {
        this.setState({ collectionDetails: response.data, loading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  //get project of project categories
  getPlaybooksOptions() {
    this.setState({ isLoading: true }, () => {
      apiClient
        .get(`${endpoints().playbookAPI}?status=${PLAYBOOK_STATUS_ACTIVE}`)
        .then(response => {
          const playbooks = response.data.data;

          const playbookOptions = [];
          if (playbooks) {
            playbooks.forEach(playbook => {
              playbookOptions.push({
                value: playbook.id,
                label: playbook.name
              });
            });
          }

          this.setState({
            isLoading: false,
            playbooks: playbookOptions
          });
        });
    });
  }

  //get project of project categories
  getPlaybooksByCollectionId() {
    this.setState({ isplayBookCollectionLoading: true }, () => {
      apiClient
        .get(
          `${endpoints().collectionPlaybookAPI}/${this.props.match.params.id}`
        )
        .then(response => {
          const playbooksCollection = response.data
            ? response.data[0].playbook
            : [];

          this.setState({
            isplayBookCollectionLoading: false,
            playbooksCollection: playbooksCollection
          });
        });
    });
  }

  // Create Collection playbook
  createCollectionPlaybook(data) {
    return apiClient
      .post(endpoints().collectionPlaybookAPI, data)
      .then(() => {
        window.location.reload();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    if (!this.state.loading) {
      return "";
    }

    const collectionId = this.props.match.params.id;

    const { playbooks, collectionDetails, playbooksCollection } = this.state;

    const { name } = collectionDetails;

    const initialValues = {
      name
    };

    const playbookInitialValues = {
      playbooks: ""
    };

    return (
      <div>
        <PageTitle label={initialValues.name} />
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            this._submit(values);
          }}
        >
          <CollectionFormFields />

          <div className="mb-4">
            <DeleteButton
              label={"Delete Collection"}
              onClick={this.handleDelete}
            />
            <div className="float-right">
              <CancelButton onClick={() => this.props.history.goBack()} />
              <SaveButton />
            </div>
          </div>
        </Form>

        {/*Create collection playbook*/}

        <div className="card mt-3 mb-3 collection-playbook">
          <div className="card-body">
            <div className=" mb-3">
              <Form
                initialValues={playbookInitialValues}
                onSubmit={values => {
                  values.collectionId = parseInt(collectionId, 10);
                  values.playbookId = values.playbooks
                    ? values.playbooks.value
                    : values.playbooks;

                  //  Create collection playbook
                  this.createCollectionPlaybook(values);
                }}
              >
                <div className="row playbook-field">
                  <div className="col-lg-6 col-sm-12">
                    <Select
                      name="playbooks"
                      label="Playbooks"
                      placeholder="Enter playbooks"
                      options={playbooks}
                      error=""
                      isSearchable={true}
                      required
                    />
                  </div>
                  <div className="col-lg-6 col-sm-12 margin-top-30">
                    <Button type="submit" label="Add" className="mb-3" />
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>

        <div className="row">
          {playbooksCollection &&
            playbooksCollection.map(playbook => {
              return (
                <PlaybookCard
                  data={playbook}
                  url={`/playbook-detail/${playbook.id}`}
                  showEditOptions
                />
              );
            })}
        </div>
      </div>
    );
  }
}
