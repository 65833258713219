/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import toast from "../../components/base/Toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchList } from "../../actions/table";

// Assets
import createLandingIcon from "../../assets/pages/landing-page/create-landing-page.png";

// Component
import AddButton from "../../components/base/AddButton";
import Text from "../../components/base/Text";
import Form from "../../components/base/Form";
import Email from "../../components/base/Email";

// Helper
import { toString } from "../../lib/helper";

//API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";
import PageTitle from "../../components/base/PageTitle";
import { isBadRequest } from "../../common/http";

// Customer Create API Call

const AddCustomerModal = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);
  function customerCreate(data, props) {
    return apiClient
      .post(endpoints().customerAPI, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        props.actions.fetchList("customers", "/v1/customer", 1, 10, {
          sort: props.sort || "createdAt",
          sortDir: props.sortDir || "DESC",
          pagination: true
        });
        setTimeout(() => {
          toggle();
        }, 500);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  return (
    <>
      <PageTitle
        label="Customers"
        buttonHandler={toggle}
        buttonLabel="New Customer"
      />
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <div className="img-wrapper d-flex justify-content-center mb-2 mt-3">
                <img src={createLandingIcon} size="30" alt="" />
              </div>
              <p className="text-center font-weight-bold mb-3">
                Create a new Customer
              </p>
              <p className="text-center h6-5 mb-2"></p>
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.content-wrapper */}
        </ModalHeader>

        <Form
          initialValues={{
            displayCompanyName: "",
            legalContactEmail: ""
          }}
          onSubmit={values => {
            values.displayCompanyName = toString(
              values.displayCompanyName
            ).trim();
            values.legalCompanyName = toString(values.displayCompanyName);
            values.legalContactEmail = toString(values.legalContactEmail);
            customerCreate(values, props);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div>
                <Text
                  name="displayCompanyName"
                  label="Company Name"
                  placeholder="Company Display Name..."
                  error=""
                  required
                />
              </div>
              <div>
                <Email
                  name="legalContactEmail"
                  label="Contact Email"
                  placeholder="Legal Contact Email Address..."
                  error=""
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="" className="h6-5-important">
                  Create Customer
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(() => {
  return {};
}, mapDispatchToProps)(AddCustomerModal);
