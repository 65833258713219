import React from "react";
import { endpoints } from "../../../../configs";
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";

const WeeklyTab = props => {
  const sortOptions = [
    {
      value: "date:DESC",
      label: "Most Recent"
    },
    {
      value: "domain:ASC",
      label: "Name"
    }
  ];

  return (
    <>
      <div className="p-0 col-12 col-md-12">
        <ReduxTable
          id="intentDatas"
          newTableHeading
          showHeader
          noRecordFoundHeight={"7vh"}
          apiURL={`${endpoints().intentDatasAPI}`}
          searchPlaceholder="Search"
          paramsToUrl={true}
          history={props.history}
          sortByOptions={sortOptions}
        >
          <ReduxColumn
            width="150px"
            field="date"
            sortBy="date"
            isClickable="true"
          >
            Date
          </ReduxColumn>
          <ReduxColumn width="150px" field="domain" sortBy="domain">
            Domain
          </ReduxColumn>
          <ReduxColumn
            width="150px"
            field="keyword"
            sortBy="keyword"
            isClickable="true"
          >
            Key Word
          </ReduxColumn>
          <ReduxColumn
            width="150px"
            field="intent_strength"
            sortBy="intent_strength"
            isClickable="true"
          >
            Intent Strength
          </ReduxColumn>
          <ReduxColumn
            width="150px"
            minWidth="150px"
            maxWidth="150px"
            field="is_trending"
            sortBy="is_trending"
            isClickable="true"
          >
            Is Trending
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};

export default WeeklyTab;
