import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

// Constants
import {
  TAB_BASICS,
  TAB_DELIVERABLES,
  TAB_DETAILS,
  TAB_EXPERTS,
  TAB_PACKAGES,
  TAB_PRICING_AND_EXTRAS
} from "../../playbook/Constants";

import {
  TAG_TYPE_EXPERT_ROLE,
  TAG_TYPE_INDUSTRIES,
  TAG_TYPE_SKILLS,
  TAG_TYPE_TOOLS,
  TAG_TYPE_CERTIFICATION
} from "../../tagType/Constants";

import {
  AVAILABLE_EXPERT,
  APPROVED_EXPERT
} from "../../expertAvailability/Constants";

import {
  ONE_PROJECT_EXPERIENCE,
  MORE_THAN_ONE_PROJECT_EXPERIENCE,
  NO_EXPERIENCE,
  ONE_PROJECT_EXPERIENCE_IDENTIFIER,
  MORE_THAN_ONE_PROJECT_EXPERIENCE_IDENTIFIER,
  NO_EXPERIENCE_IDENTIFIER
} from "../../expertLevelOfExperience/Constants";

import { getExpertHourlyOption } from "../../expertHours/Constants";
import { getExpertAvailabilityOption } from "../../expertAvailability/Constants";
import { getExpertLevelOfExperienceOption } from "../../expertLevelOfExperience/Constants";

// Components
import Text from "../../components/base/Text";
import TextArea from "../../components/base/TextArea";
import ColorPicker from "../../components/base/ColorPicker";
import IconPicker from "../../components/base/IconPicker";
import PlaybookCard from "../../components/PlaybookCard";
import Multiselect from "../../components/base/Multiselect";
import Select from "../../components/base/Select";
import DragAndDropField from "../../components/base/DragAndDropField";
import Hint from "../../components/base/Hint";
import TabsAndContent from "../../components/base/TabsAndContent";
import SimpleExpertCard from "../../components/SimpleExpertCard";
import NLFormWrapper from "../../components/nl-form/NLFormWrapper";

import PlaybookPricingFields from "./inner_components/PlaybookPricingFields";
import PlaybookPackage from "./inner_components/PlaybookPackage";
import PlaybookDeliverables from "./inner_components/PlaybookDeliverables";
import { paragraphs } from "../../components/helpers/NLFormStaticData";

// Assets
import {
  MaximizeAltIcon,
  CrossIcon,
  OctagonAlertIcon
} from "../../assets/img/icons";

// APi call
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";
import ExpertGrid from "../../components/ExpertGrid";
import PlaybookExtras from "./inner_components/PlaybookExtras";
import { isBadRequest } from "../../common/http";

class PlaybookFormFields extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      description: "",
      bannerImageUrl: "",
      playbookDetails: [],
      loading: false,
      activeTab: TAB_BASICS,
      values: [],
      isExists: this.props.extras && this.props.extras.length,
      extras: this.props.extras,
      updatedExtras: [],
      extra: {},
      isOpen: false,
      "Basic-package-2": "",
      "Standard-package-1": "",
      "Premium-package-3": "",
      selectedFilters: {},
      expertSearchedList: [],
      skills: [
        {
          multiselect: 1,
          label: "skill",
          value: "",
          placeholder: true
        }
      ],
      tools: [
        {
          multiselect: 1,
          label: "tool",
          value: "",
          placeholder: true
        }
      ],
      industries: [
        {
          multiselect: 1,
          label: "industry",
          value: "",
          placeholder: true
        }
      ],
      expertTypes: [
        {
          label: "Expert type",
          value: "Expert type",
          selected: false,
          placeholder: true
        }
      ],
      certificates: [
        {
          multiselect: 1,
          label: "certification",
          value: "",
          placeholder: true
        }
      ],
      skills: [
        {
          multiselect: 1,
          label: "skill",
          value: "",
          placeholder: true
        }
      ],
      fileTypeError: false
    };
    this.bannerImageUrlRef = React.createRef();
    this._handleChange = this._handleChange.bind(this);
    this._handleDescriptionChange = this._handleDescriptionChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.checkAndSetActiveTab = this.checkAndSetActiveTab.bind(this);
    this.getSelectedFilters = this.getSelectedFilters.bind(this);
  }

  viewpreviewstyle = { paddingLeft: "30px" };

  previewstyle = { marginLeft: "60px" };
  // Handle OnChange playbook name field
  _handleChange = e => {
    const target = e.target;
    const name = target.value;
    this.setState({ name });
  };

  //Handle OnChange playbook description field
  _handleDescriptionChange = e => {
    const target = e.target;
    const description = target.value;
    this.setState({ description });
  };

  // Handle package price
  _handlePackagePriceChange = e => {
    const { value } = e.target;
    this.setState({ [e.target.name]: value });
  };

  // Toggle Tab
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab }, () => {
        window.localStorage.setItem("playbookActiveTab", this.state.activeTab);
      });
    }
  };

  // Check and Set active tab
  checkAndSetActiveTab = () => {
    const existingActiveTab = window.localStorage.getItem("playbookActiveTab");
    if (existingActiveTab !== null) {
      this.setState({ activeTab: existingActiveTab });
    }
  };

  componentDidMount() {
    this.checkAndSetActiveTab();
    this.renderExpertList();
    this._getTagsByTagType(TAG_TYPE_EXPERT_ROLE);
    this._getTagsByTagType(TAG_TYPE_SKILLS);
    this._getTagsByTagType(TAG_TYPE_TOOLS);
    this._getTagsByTagType(TAG_TYPE_INDUSTRIES);
    this._getTagsByTagType(TAG_TYPE_CERTIFICATION);

    // Set package price value
    const { data } = this.props;
    this.setState({
      "Standard-package-1": (data && data["Standard-package-1"]) || "",
      "Basic-package-2": (data && data["Basic-package-2"]) || "",
      "Premium-package-3": (data && data["Premium-package-3"]) || ""
    });
  }

  // Selected Filters
  getSelectedFilters = (selectedFilters, selectName) => {
    this.setState(prevState => ({
      selectedFilters: {
        ...prevState.selectedFilters,
        [selectName]: selectedFilters
      }
    }));
  };

  getFilterKeys = () => Object.keys(this.state.selectedFilters);

  deleteFilters = e => {
    const { dataset } = e.target;
    this.setState(
      prevState => {
        let currentValueIndex = prevState.selectedFilters[
          dataset["parentobj"]
        ].selectedValue.indexOf(dataset["filter"]);
        let currentArray =
          prevState.selectedFilters[dataset["parentobj"]].selectedValue;
        currentArray.splice(currentValueIndex, 1);

        return {
          selectedFilters: {
            ...prevState.selectedFilters,
            [dataset["parentobj"]]: {
              selectedValue: [...currentArray]
            }
          }
        };
      },
      () => {
        this.renderExpertList();
      }
    );
  };

  // Get Tags by tag type
  _getTagsByTagType = tagType => {
    return apiClient
      .get(`${endpoints().playbookAPI}/tags/list?tagType=${tagType}`)
      .then(response => {
        const tags = response.data.data;

        if (tags.length > 0) {
          // Check the tag type experts
          if (tagType === TAG_TYPE_EXPERT_ROLE) {
            tags.forEach(tag => {
              this.state.expertTypes.push({
                label: tag.name,
                value: tag.id,
                selected: false
              });
            });
          } else if (tagType === TAG_TYPE_SKILLS) {
            tags.forEach(tag => {
              this.state.skills.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          } else if (tagType === TAG_TYPE_TOOLS) {
            tags.forEach(tag => {
              this.state.tools.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          } else if (tagType === TAG_TYPE_INDUSTRIES) {
            tags.forEach(tag => {
              this.state.industries.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          } else if (tagType === TAG_TYPE_CERTIFICATION) {
            tags.forEach(tag => {
              this.state.certificates.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Render The Expert List
  renderExpertList() {
    const selectedFilters = this.state.selectedFilters;
    const tags = [];
    const certificates = [];

    const expertTypesTags = selectedFilters.expertTypeObj
      ? selectedFilters.expertTypeObj.selectedValue
      : "";

    if (expertTypesTags.length > 0) {
      expertTypesTags.forEach(expertType => {
        tags.push(expertType);
      });
    }

    const skillsTags = selectedFilters.multiSelect
      ? selectedFilters.multiSelect.selectedValue
      : "";

    if (skillsTags.length > 0) {
      skillsTags.forEach(skill => {
        tags.push(skill);
      });
    }

    const certificateTags = selectedFilters.certification
      ? selectedFilters.certification.selectedValue
      : "";

    if (certificateTags.length > 0) {
      certificateTags.forEach(certificate => {
        certificates.push(certificate);
      });
    }

    const toolsTags = selectedFilters.tool
      ? selectedFilters.tool.selectedValue
      : "";

    if (toolsTags.length > 0) {
      toolsTags.forEach(tool => {
        tags.push(tool);
      });
    }

    const industriesTags = selectedFilters.industry
      ? selectedFilters.industry.selectedValue
      : "";

    if (industriesTags.length > 0) {
      industriesTags.forEach(industry => {
        tags.push(industry);
      });
    }

    const isAvailability =
      selectedFilters &&
      selectedFilters.availabilityObj &&
      selectedFilters.availabilityObj.selectedValue.length > 0 &&
      selectedFilters.availabilityObj.selectedValue &&
      selectedFilters.availabilityObj.selectedValue[0] !== APPROVED_EXPERT
        ? selectedFilters.availabilityObj.selectedValue[0] === AVAILABLE_EXPERT
          ? true
          : false
        : "";

    const availability =
      selectedFilters &&
      selectedFilters.hoursObj &&
      selectedFilters.hoursObj.selectedValue &&
      selectedFilters.hoursObj.selectedValue.length > 0
        ? selectedFilters.hoursObj.selectedValue[0]
        : "";

    const levelOfExperienceValue =
      selectedFilters &&
      selectedFilters.levelOfExp &&
      selectedFilters.levelOfExp.selectedValue &&
      selectedFilters.levelOfExp.selectedValue.length > 0
        ? selectedFilters.levelOfExp.selectedValue[0]
        : "";

    let levelOfExperience = "";
    if (levelOfExperienceValue === NO_EXPERIENCE) {
      levelOfExperience = NO_EXPERIENCE_IDENTIFIER;
    } else if (levelOfExperienceValue === ONE_PROJECT_EXPERIENCE) {
      levelOfExperience = ONE_PROJECT_EXPERIENCE_IDENTIFIER;
    } else if (levelOfExperienceValue === MORE_THAN_ONE_PROJECT_EXPERIENCE) {
      levelOfExperience = MORE_THAN_ONE_PROJECT_EXPERIENCE_IDENTIFIER;
    }

    const location =
      selectedFilters &&
      selectedFilters.location &&
      selectedFilters.location.selectedValue
        ? selectedFilters.location.selectedValue
        : "";
    const locationSplit =
      location && location[0] ? location[0].split(", ") : "";
    const city = locationSplit[0] || "";

    apiClient
      .get(
        `${
          endpoints().expertAPI
        }/search?marketplaceStatus=Approved&tags=${tags}&city=${city}&isAvailable=${isAvailability}&availability=${availability}&levelOfExperience=${levelOfExperience}&certificate=${certificates}`
      )
      .then(response => {
        const expertList = response.data.data || [];
        const data = response.data;
        this.setState({
          expertSearchedList: expertList,
          totalCount: data.totalCount
        });
      });
  }

  // Select the uploaded image
  onSelectFile = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          this.setState({
            fileTypeError: false
          });
          this.props.bannerImageUpload(e);
          break;

        default:
          this.setState({
            fileTypeError: true
          });
          break;
      }
    }
  };

  // Handle banner image remove
  handleBannerImageRemove = () => {
    this.bannerImageUrlRef.current.value = "";
    this.props.handleImageRemove();
  };

  // Render the form fields
  render() {
    const existPlaybookData = this.props.data;
    const {
      expertRoles,
      skills,
      tools,
      defaultDeliverables,
      defaultTaskDeliverables,
      extras,
      expertList,
      handleExperts,
      selectedIds,
      standardDeliverableOnChange,
      basicDeliverableOnChange,
      premiumDeliverableOnChange,
      standardPackageDeliverables,
      basicPackageDeliverables,
      premiumPackageDeliverables,
      supportingMaterials,
      getDeliverablesValue,
      getDefaultDeliverablesValue,
      getExtrasValue
    } = this.props;

    const data = {
      name:
        !this.state.name && existPlaybookData && existPlaybookData.name
          ? existPlaybookData.name
          : this.state.name,
      description:
        !this.state.description &&
        existPlaybookData &&
        existPlaybookData.description
          ? existPlaybookData.description
          : this.state.description,
      bannerIcon:
        !this.props.bannerIcon &&
        existPlaybookData &&
        existPlaybookData.bannerIcon
          ? existPlaybookData.bannerIcon
          : this.props.bannerIcon,
      bannerColor:
        !this.props.bannerColor &&
        existPlaybookData &&
        existPlaybookData.bannerColor
          ? existPlaybookData.bannerColor
          : this.props.bannerColor,
      bannerImageUrl:
        !this.props.bannerImageUrl &&
        existPlaybookData &&
        existPlaybookData.bannerImageUrl
          ? existPlaybookData.bannerImageUrl
          : this.props.bannerImageUrl,
      price: {
        priceRange: {
          basic:
            !this.state["Basic-package-2"] &&
            existPlaybookData &&
            existPlaybookData["Basic-package-2"]
              ? existPlaybookData["Basic-package-2"]
              : this.state["Basic-package-2"],
          standard:
            !this.state["Basic-package-2"] &&
            existPlaybookData &&
            existPlaybookData["Standard-package-1"]
              ? existPlaybookData["Standard-package-1"]
              : this.state["Standard-package-1"],
          premium:
            !this.state["Basic-package-2"] &&
            existPlaybookData &&
            existPlaybookData["Premium-package-3"]
              ? existPlaybookData["Premium-package-3"]
              : this.state["Premium-package-3"]
        },
        salesPrice: false,
        currentPrice: ""
      }
    };

    const playbookCardPlaceHolder =
      existPlaybookData && existPlaybookData.description ? false : true;

    const { activeTab, selectedFilters, expertSearchedList } = this.state;

    // Natural Language form Setup

    const extraParagraphs = [
      "That has experience in {industry}",
      "that utilizes {tool}",
      "that has {levelOfExp}",
      "that is located in {location}"
    ];

    const selectObj = {
      expertTypeObj: this.state.expertTypes,
      hoursObj: getExpertHourlyOption(),
      availabilityObj: getExpertAvailabilityOption(),
      industry: this.state.industries,
      tool: this.state.tools,
      levelOfExp: getExpertLevelOfExperienceOption(),
      multiSelect: this.state.skills,
      certification: this.state.certificates,
      location: [
        {
          location: true
        }
      ]
    };
    const fileTypeError = this.state.fileTypeError;
    const availableExpertCount = expertSearchedList.length - selectedIds.length;

    return (
      <div>
        <Nav tabs className="admin-tabs">
          {existPlaybookData ? "" : this.toggle(this.props.currentTab + "")}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TAB_BASICS })}
              onClick={() => {
                this.toggle(TAB_BASICS);
                this.props.backButton(TAB_BASICS);
              }}
            >
              Basics
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TAB_DETAILS })}
              onClick={() => {
                this.toggle(TAB_DETAILS);
                this.props.backButton(TAB_DETAILS);
              }}
            >
              Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === TAB_PRICING_AND_EXTRAS
              })}
              onClick={() => {
                this.toggle(TAB_PRICING_AND_EXTRAS);
                this.props.backButton(TAB_PRICING_AND_EXTRAS);
              }}
            >
              Pricing & Extras
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TAB_DELIVERABLES })}
              onClick={() => {
                this.toggle(TAB_DELIVERABLES);
                this.props.backButton(TAB_DELIVERABLES);
              }}
            >
              Deliverables
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TAB_PACKAGES })}
              onClick={() => {
                this.toggle(TAB_PACKAGES);
                this.props.backButton(TAB_PACKAGES);
              }}
            >
              Packages
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TAB_EXPERTS })}
              onClick={() => {
                this.toggle(TAB_EXPERTS);
                this.props.backButton(TAB_EXPERTS);
              }}
            >
              Experts
            </NavLink>
          </NavItem>
        </Nav>
        <TabsAndContent>
          {/*added this condition below to support multiple contents inside a tab pane with white background*/}
          <div
            className={`${
              activeTab === TAB_EXPERTS ? "" : "tab-content-wrapper d-flex"
            }`}
          >
            <TabContent
              activeTab={activeTab}
              className={activeTab === TAB_EXPERTS ? "selected-experts" : ""}
            >
              <TabPane tabId={TAB_BASICS}>
                <div className="form-wrapper">
                  <div className="field-wrapper">
                    <Text
                      name="name"
                      label="Playbook Title"
                      placeholder="Enter title"
                      error=""
                      required={true}
                      onChange={this._handleChange.bind(this)}
                      autoFocus={true}
                    />
                  </div>

                  <div className="form-wrapper">
                    <div className="field-wrapper flex-column">
                      <TextArea
                        name="description"
                        label="Description"
                        onChange={this._handleDescriptionChange.bind(this)}
                        placeholder="Enter Description"
                        error=""
                        maxLength={1000}
                      />
                      <Hint
                        id="requirements"
                        hintText="Description will appear on Playbook Detail Page"
                      />
                    </div>
                  </div>

                  <div className="field-wrapper mt-4">
                    <div className="form-group">
                      <label>Color</label>
                      <ColorPicker
                        onColourPickerClick={this.props.onColourPickerClick}
                        bannerColor={this.props.bannerColor}
                      />
                    </div>
                  </div>
                  <div className="field-wrapper">
                    <div className="form-group">
                      <label>Icon</label>
                      <IconPicker
                        onIconClick={this.props.onIconClick}
                        bannerIcon={this.props.bannerIcon}
                      />
                    </div>
                  </div>
                  <div className="upload-field d-inline-block">
                    <input
                      name="file"
                      className="form-control d-none"
                      type="file"
                      id="bannerImage"
                      placeholder="Banner Image"
                      onChange={e => {
                        this.onSelectFile(e);
                      }}
                      accept="image/png,image/gif,image/jpeg"
                      ref={this.bannerImageUrlRef}
                    />
                    <span className="profilePicOverlay d-block ">
                      <label
                        htmlFor="bannerImage"
                        className="profile-img-sm mb-0"
                      >
                        <span className="banner-image-upload-link">
                          Upload Custom Cover Graphic
                        </span>
                      </label>
                    </span>
                  </div>
                  {/* Remove image */}
                  {data.bannerImageUrl && (
                    <span
                      className="banner-image-upload-link text-danger ml-md-1"
                      onClick={this.handleBannerImageRemove}
                    >
                      (Remove)
                    </span>
                  )}

                  <small
                    className={`text-danger mt-3 ${
                      fileTypeError ? "d-block" : "d-none"
                    }`}
                  >
                    Please select a image file
                  </small>
                  <Hint
                    id="bannerRequirements"
                    hintText="We recommended using 400 x 300 image"
                  />
                </div>
              </TabPane>
              <TabPane tabId={TAB_DETAILS}>
                <div className="form-wrapper">
                  <div className="field-wrapper flex-column">
                    <Select
                      name="expertRoles"
                      label="Expert Role"
                      options={expertRoles}
                    />
                    <Hint
                      id="expert-roles"
                      hintText="The type of experts you will need to complete this project"
                    />
                  </div>

                  <div className="field-wrapper flex-column">
                    <Multiselect
                      name="required_skills"
                      label="Required Skills"
                      options={skills}
                      placeholder="Copywriting, Design, Marketing..."
                    />

                    <Hint
                      id="required-skills"
                      hintText="If the experts need to have certain skills, list them here"
                    />
                  </div>

                  <div className="field-wrapper flex-column">
                    <Multiselect
                      name="required_tools"
                      label="Required Tools"
                      options={tools}
                      placeholder="Salesforce,Sketch,Photoshop,Adobe XD..."
                    />

                    <Hint
                      id={"required-tools"}
                      hintText="If the experts need to have certain tools, list them here"
                    />
                  </div>

                  <div className="flex-column">
                    <DragAndDropField
                      label={"Supporting Materials"}
                      name={"supporting_materials"}
                      hiddenDropzone
                      btnLabel={"Add Supporting Materials"}
                      onFileChange={this.props.handleSupportingMaterial}
                      onFileDelete={this.props.handleDeleteSupportingMaterial}
                      data={supportingMaterials}
                    />
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={TAB_PRICING_AND_EXTRAS}>
                <div className="form-wrapper">
                  <div className="field-wrapper-group">
                    <PlaybookPricingFields
                      initialPricingType={
                        existPlaybookData ? existPlaybookData.pricingType : ""
                      }
                    />
                  </div>

                  <div className="field-wrapper-group">
                    <label>Add Extras</label>
                    <Hint
                      id="fixed-rate-tooltip"
                      hintText='Add optional fixed-rate extras. Ex: "Urgent Delivery"'
                    />
                    <div className="field-wrapper-group mt-4">
                      <PlaybookExtras
                        extras={extras}
                        extrasValue={getExtrasValue}
                      />
                    </div>
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={TAB_DELIVERABLES}>
                <div className="form-wrapper">
                  <label>Deliverables & Tasks</label>
                  <Hint
                    id="deliverables"
                    hintText="These are the the tasks & deliverables the expert completes for this Playbook."
                  />
                  <div className="field-wrapper-group mt-4">
                    <PlaybookDeliverables
                      deliverables={defaultDeliverables}
                      deliverablesValue={getDeliverablesValue}
                    />
                  </div>

                  <label>Default Deliverables & Tasks</label>
                  <Hint
                    id="deliverables"
                    hintText="These are the tasks & deliverables that appear on all packages."
                  />
                  <div className="field-wrapper-group mt-4">
                    <PlaybookDeliverables
                      fieldName="default"
                      deliverables={defaultTaskDeliverables}
                      deliverablesValue={getDefaultDeliverablesValue}
                    />
                  </div>
                </div>
              </TabPane>
              <TabPane tabId={TAB_PACKAGES}>
                <PlaybookPackage
                  onChange={e => this._handlePackagePriceChange(e)}
                  defaultDeliverables={defaultDeliverables}
                  standardDeliverableOnChange={standardDeliverableOnChange}
                  basicDeliverableOnChange={basicDeliverableOnChange}
                  premiumDeliverableOnChange={premiumDeliverableOnChange}
                  standardPackageDeliverables={standardPackageDeliverables}
                  basicPackageDeliverables={basicPackageDeliverables}
                  premiumPackageDeliverables={premiumPackageDeliverables}
                />
              </TabPane>
              <TabPane tabId={TAB_EXPERTS} className="selected-experts">
                <div className="tab-content-wrapper">
                  <div className="form-wrapper">
                    <div className="pane-heading d-flex justify-content-between">
                      <label className={["m-0"].join(" ")}>
                        Your Selected Experts
                      </label>
                    </div>
                    <Hint
                      id="select-expert"
                      hintText="These are the recommended experts you have selected for you Playbook"
                    />
                    <div
                      className={["simple-expert-card-list", "mt-4"].join(" ")}
                    >
                      {expertList.length > 0 &&
                        expertList.map(
                          expert =>
                            selectedIds &&
                            selectedIds.indexOf(expert.id) > -1 && (
                              <SimpleExpertCard
                                data={expert}
                                key={expert.id}
                                showDeleteIcon={true}
                                handleExperts={handleExperts}
                              />
                            )
                        )}
                    </div>
                  </div>
                </div>

                <NLFormWrapper
                  paragraphs={paragraphs}
                  selectObj={selectObj}
                  extraParagraphs={extraParagraphs}
                  getSelectedFilters={this.getSelectedFilters}
                  liveFilters={selectedFilters}
                  renderExpertList={e => this.renderExpertList(e)}
                />

                {this.getFilterKeys().length > 0 ? (
                  <div className="filters-wrapper">
                    {this.getFilterKeys().map(filterObj =>
                      this.state.selectedFilters[filterObj][
                        "selectedValue"
                      ].map(
                        value =>
                          value && (
                            <button
                              className={`badge badge-primary ${filterObj}`}
                              data-parentobj={filterObj}
                              data-filter={value}
                              onClick={this.deleteFilters}
                            >
                              {value} <CrossIcon />
                            </button>
                          )
                      )
                    )}
                  </div>
                ) : (
                  ""
                )}

                {!expertSearchedList.length && (
                  <div className="inline-alert alert alert-light">
                    <OctagonAlertIcon />{" "}
                    <span>
                      There are no Experts available that match your query. Try
                      different filters.
                    </span>
                  </div>
                )}

                {/* /.selectedFilters */}
                <div className="card-list-wrapper p-2 mt-4 bg-transparent">
                  {availableExpertCount > 0 && (
                    <span className="d-block font-weight-bold h6-5">
                      Showing {availableExpertCount}{" "}
                      {availableExpertCount > 1 ? "experts" : "expert"}…
                    </span>
                  )}
                  <ExpertGrid
                    expertList={expertSearchedList}
                    selectExperts={true}
                    selectedExperts={selectedIds}
                    handleExperts={handleExperts}
                  />
                </div>
              </TabPane>
            </TabContent>

            {activeTab !== TAB_EXPERTS && (
              <div className="preview-wrapper" style={this.previewstyle}>
                <label>Preview</label>
                <PlaybookCard
                  isPreview={this.props.detailLink ? "false" : "true"}
                  link={this.props.detailLink}
                  openLinkInNewTab={true}
                  boxShadow={"true"}
                  placeHolder={playbookCardPlaceHolder}
                  data={data}
                  files={this.props.files || ""}
                  bannerImageUrl={this.props.bannerImageUrl}
                  tag={"div"}
                  fullWidth={false}
                />
                {this.props.detailLink && (
                  <div
                    className="preview-full mt-4 d-flex"
                    style={this.viewpreviewstyle}
                  >
                    <MaximizeAltIcon />
                    <a
                      href={`${this.props.detailLink}?redirect=${window.location.pathname}`}
                      target="_blank"
                      className={["text-underline"].join(" ")}
                      rel="noreferrer"
                    >
                      View Full Preview
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        </TabsAndContent>
      </div>
    );
  }
}

export default PlaybookFormFields;
