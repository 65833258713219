import moment from "moment-timezone";
import React from "react";

// Inner Components
import KickOffTimePickerListItem from "./KickOffTimePickerListItem";

export const KickOffTimePickerCustomerHours = React.memo(
  function KickOffTimePickerCustomerHours({
    endTime,
    minTime,
    maximumHours,
    step,
    date,
    handleChange,
    getSelectedValue,
    visualTimezone,
    defaultTimezone,
    customerWaitingForExpert
  }) {
    // Get Hours By A Day
    function enumerateHoursOfADay(h = 8, maximumHours = 20, step = 30) {
      let hours = [];
      for (let hour = h; hour <= maximumHours; hour++) {
        for (let minute = 0; minute < 60; minute += step) {
          hours.push({
            formatted: moment({ hour, minute }).format("h:mm a"),
            raw: moment({ hour, minute }).format("H:mm:a")
          });
        }
      }

      return hours;
    }

    // Get Times
    const getCategorizedTimes = (
      date,
      endTime,
      minTime,
      defaultTimezone,
      visualTimezone
    ) => {
      let currentTime = moment()
        .utc()
        .tz(visualTimezone)
        .hour();

      const startMeetingTime = moment()
        .utc()
        .add(1, "hour")
        .tz(visualTimezone)
        .hour();

      const currentDate = moment.utc(date).format("YYYY-MM-DD");

      const todayDate = moment()
        .utc()
        .tz(visualTimezone)
        .format("YYYY-MM-DD");

      return todayDate === currentDate
        ? currentTime < endTime
          ? startMeetingTime > minTime
            ? startMeetingTime
            : minTime
          : currentTime < endTime
          ? startMeetingTime
          : endTime
        : minTime;
    };

    return (
      <>
        {enumerateHoursOfADay(
          getCategorizedTimes(
            date,
            endTime,
            minTime,
            defaultTimezone,
            visualTimezone
          ),
          maximumHours,
          step
        ).map((hour, index) => (
          <KickOffTimePickerListItem
            index={hour.raw.split(":").join("")}
            date={date}
            hour={hour}
            handleChange={handleChange}
            getSelectedValue={getSelectedValue}
            visualTimezone={visualTimezone}
            key={index}
            customerWaitingForExpert={customerWaitingForExpert}
          />
        ))}
      </>
    );
  }
);

export const KickOffTimePickerExpertHours = ({
  date,
  handleChange,
  getSelectedValue,
  expertAvailableDates,
  endTime = 24,
  minTime = 8,
  defaultTimezone,
  visualTimezone,
  customerWaitingForExpert
}) => {
  let propDate = date;
  console.log(propDate);
  return (
    <>
      {expertAvailableDates
        .filter(
          date =>
            moment
              .utc(date.time)
              .tz(visualTimezone)
              .date() === moment.utc(propDate, "MM-DD-YYYY").date()
        )
        .filter(
          date =>
            moment(date.time)
              .utc()
              .tz(visualTimezone)
              .hour() < endTime &&
            moment(date.time)
              .utc()
              .tz(visualTimezone)
              .hour() >= minTime
        )
        .sort((a, b) => a - b)
        .map((date, index) => {
          let hour = {
            formatted: moment(date.time)
              .utc()
              .tz(visualTimezone)
              .format("hh:mm a"),
            raw: moment(date.time)
              .utc()
              .tz(visualTimezone)
              .format("H:mm:a")
          };

          return (
            <KickOffTimePickerListItem
              index={hour.raw.split(":").join("")}
              date={date.time}
              hour={hour}
              selectedHours={hour}
              handleChange={handleChange}
              getSelectedValue={getSelectedValue}
              defaultTimezone={defaultTimezone}
              visualTimezone={visualTimezone}
              dateId={date.id}
              key={index}
              customerWaitingForExpert={customerWaitingForExpert}
            />
          );
        })}
    </>
  );
};

export const KickOffTimePickerAdminHours = React.memo(
  function KickOffTimePickerCustomerHours({
    endTime,
    minTime,
    maximumHours,
    step,
    date,
    handleChange,
    getSelectedValue,
    visualTimezone,
    defaultTimezone,
    expertAvailableDates,
    customerWaitingForExpert
  }) {
    // Get Hours By A Day
    function enumerateHoursOfADay(h = 8, maximumHours = 20, step = 30) {
      let hours = [];
      for (let hour = h; hour <= maximumHours; hour++) {
        for (let minute = 0; minute < 60; minute += step) {
          hours.push({
            formatted: moment({ hour, minute }).format("h:mm a"),
            raw: moment({ hour, minute }).format("H:mm:a")
          });
        }
      }

      return hours;
    }

    function getSelectedHours(date, hours) {
      let hour = "";

      Object.keys(expertAvailableDates).forEach(key => {
        if (
          moment
            .utc(expertAvailableDates[key].time)
            .tz(visualTimezone)
            .date() === moment.utc(date).date()
        ) {
          const formatted = moment
            .utc(expertAvailableDates[key].time)
            .tz(visualTimezone)
            .format("hh:mm a");
          const raw = moment
            .utc(expertAvailableDates[key].time)
            .tz(visualTimezone)
            .format("H:mm:a");

          if (`${raw}` === `${hours.raw}`) {
            hour = {
              formatted,
              raw
            };
          }
        }
      });

      return hour;
    }

    // Get Times
    const getCategorizedTimes = (
      date,
      endTime,
      minTime,
      defaultTimezone,
      visualTimezone
    ) => {
      let currentTime = moment()
        .utc()
        .tz(visualTimezone)
        .hour();

      const startMeetingTime = moment()
        .utc()
        .add(1, "hour")
        .tz(visualTimezone)
        .hour();

      const currentDate = moment.utc(date).format("YYYY-MM-DD");

      const todayDate = moment()
        .utc()
        .tz(visualTimezone)
        .format("YYYY-MM-DD");

      return todayDate === currentDate
        ? currentTime < endTime
          ? startMeetingTime > minTime
            ? startMeetingTime
            : minTime
          : currentTime < endTime
          ? startMeetingTime
          : endTime
        : minTime;
    };

    return (
      <>
        {enumerateHoursOfADay(
          getCategorizedTimes(
            date,
            endTime,
            minTime,
            defaultTimezone,
            visualTimezone
          ),
          maximumHours,
          step
        ).map((hour, index) => (
          <KickOffTimePickerListItem
            index={hour.raw.split(":").join("")}
            date={date}
            hour={hour}
            handleChange={handleChange}
            getSelectedValue={getSelectedValue}
            visualTimezone={visualTimezone}
            key={index}
            selectedHours={getSelectedHours(date, hour)}
            customerWaitingForExpert={customerWaitingForExpert}
          />
        ))}
      </>
    );
  }
);
