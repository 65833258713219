import React, { useState } from "react";

import SaveButton from "../../components/base/SaveButton";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// Components
import Form from "../../components/base/Form";
import Text from "../../components/Text";

import Select from "../../components/base/Select";

const addSyncRuleModal = ({
  isOpen,
  toggle,
  AccountObjectFieldList,
  OpportunityObjectFieldList,
  salesforceObjectNameList,
  relationalOperators,
  addFunction
}) => {
  const [
    selectedSalesforceObjectName,
    setSelectedSalesforceObjectName
  ] = useState();

  const handleSalesforceObjectChange = e => {
    setSelectedSalesforceObjectName(
      e &&
        e.values &&
        e.values.salesforceObjectName &&
        e.values.salesforceObjectName.value
    );
  };
  return (
    <div>
      <Modal isOpen={isOpen} className="edit-task-modal" backdrop="static">
        <ModalHeader
          toggle={() => {
            toggle();
          }}
        >
          <p className="text-center font-weight-bold mb-3">
            Add Salesforce Sync Rule
          </p>
        </ModalHeader>

        <Form
          initialValues={{
            salesforceObjectName: "",
            salesforceFieldName: "",
            operator: "",
            value: ""
          }}
          onSubmit={values => {
            let submitData = {};

            submitData.SalesforceObjectName =
              values &&
              values.salesforceObjectName &&
              values.salesforceObjectName.value;

            submitData.salesforceFieldName =
              values &&
              values.salesforceFieldName &&
              values.salesforceFieldName.value;

            submitData.operator =
              values && values.operator && values.operator.value;
            submitData.value = values && values.value;

            submitData.type =
              values &&
              values.salesforceFieldName &&
              values.salesforceFieldName.type;
            addFunction.actions.addSalesforceSyncRule(submitData, " ", toggle);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div className="field-wrapper mb-3">
                <Select
                  name="salesforceObjectName"
                  label="Salesforce Object "
                  placeholder="Select Salesforce Object "
                  options={salesforceObjectNameList()}
                  onInputChange={e => handleSalesforceObjectChange(e)}
                  required
                  isClearable={true}
                />
              </div>
              <div className="field-wrapper mb-3">
                <Select
                  name="salesforceFieldName"
                  label="Salesforce Field "
                  placeholder="Select Salesforce Field "
                  options={
                    selectedSalesforceObjectName === "Account"
                      ? AccountObjectFieldList
                      : selectedSalesforceObjectName === "Opportunities"
                      ? OpportunityObjectFieldList
                      : []
                  }
                  required
                  isClearable={true}
                  isSearchable={true}
                />
              </div>

              <div className="field-wrapper mb-3">
                <Select
                  name="operator"
                  placeholder="Select condition"
                  label="Condition"
                  options={relationalOperators()}
                  isClearable={true}
                  required
                />
              </div>

              <Text
                name="value"
                label="Value"
                placeholder="Enter Value"
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <SaveButton />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};
export default addSyncRuleModal;
