import React from "react";
import { Link } from "react-router-dom";
import { Alert } from "reactstrap";

// Assets
import { InlineInfoIcon } from "../../../assets/img/icons";

// Constants
import {
  PROJECT_STATUS_INTERVIEWING,
  PROJECT_STATUS_MATCHING
} from "../../../projectStatus/Constants";

const ExpertInterestCheck = ({ projectDetails }) => (
  <div className={["alerts-wrapper"].join(" ")}>
    <Alert
      color="danger"
      className={"d-flex align-items-center justify-content-stretch text-white"}
    >
      <InlineInfoIcon />
      {/*Check the expert availability interest for project, show the alert*/}
      {projectDetails.status === PROJECT_STATUS_MATCHING && (
        <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
          <span className="font-weight-bold">Action Required!</span> Please
          confirm your interest in{" "}
          <span className="font-weight-bold"> {projectDetails.name} </span>
        </p>
      )}
      {/*Waiting for expert acceptance for project, show the alert*/}
      {projectDetails.status === PROJECT_STATUS_INTERVIEWING && (
        <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
          <span className="font-weight-bold">Action Required!</span> Please
          confirm your acceptance in{" "}
          <span className="font-weight-bold"> {projectDetails.name} </span>
        </p>
      )}

      <div className="btn-wrapper ml-auto mr-3">
        <Link
          className="btn btn-outline-light"
          to={`/project/${projectDetails.id}`}
        >
          Review Project
        </Link>
      </div>
    </Alert>
  </div>
);

export default ExpertInterestCheck;
