import React from "react";

// Components
import Text from "../../../components/base/Text";

// Assets
import { PlusIcon, TrashIcon } from "../../../assets/img/icons";

class PlaybookDeliverables extends React.Component {
  constructor(props) {
    super(props);
    const { deliverables } = this.props;
    this.state = {
      values:
        deliverables && deliverables.length
          ? [...this.props.deliverables]
          : [{ description: null, id: 0 }],
      deliverable: {}
    };
  }

  componentDidMount() {
    if (this.props.deliverables) {
      this.props.deliverablesValue(this.state.values);
    }
  }

  // Add deliverable field
  addField = event => {
    event.preventDefault();
    // Generate added deliverable field key
    const id = Math.floor(1000 + Math.random() * 9000);
    const values = [...this.state.values];

    values.push({ description: null, id });
    this.setState({ values });
  };

  // Handle remove deliverable
  handleRemove = i => {
    const values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
    this.props.deliverablesValue(values);
  };

  // Handle change deliverable
  handleChange = (i, event) => {
    const values = [...this.state.values];
    values[i].description = event.target.value;
    this.setState({ values });

    this.props.deliverablesValue(values);
  };

  // Handle update deliverable
  handleDeliverable = (i, event) => {
    let data = [];
    data.push({ id: i, description: event.target.value, key: "update-task" });

    this.props.deliverablesValue(data);
  };

  render() {
    const { fieldName, task } = this.props;
    const { values } = this.state;

    return (
      <>
        {values.map((el, i) => (
          <div className="field-wrapper" key={el.id}>
            <Text
              name={`deliverable-${
                fieldName !== undefined && fieldName.length > 0 ? fieldName : ""
              }${el.id}`}
              label={`Deliverable / Task ${i + 1}`}
              defaultValue={el.description || ""}
              placeholder={`Enter Deliverable / Task ${i + 1}`}
              onChange={e => this.handleChange(i, e)}
            />
            <div
              style={{ marginTop: "40px", cursor: "pointer" }}
              onClick={() => this.handleRemove(i)}
            >
              {values.length > 1 || values[0].description ? <TrashIcon /> : ""}
            </div>
          </div>
        ))}
        {this.props.id &&
          task &&
          task.map((data, i) => (
            <div className="field-wrapper" key={data.id}>
              <Text
                id={data.id}
                name={`deliverable-${
                  task && task !== undefined && task.length > 0
                    ? task.description
                    : ""
                }${data.id}`}
                label={`Deliverable / Task`}
                defaultValue={data.description || ""}
                placeholder={`Enter Deliverable / Task ${i + 1}`}
                onChange={e => this.handleDeliverable(data.id, e)}
              />
              <div
                style={{ marginTop: "40px", cursor: "pointer" }}
                onClick={() => this.props.deleteTask(data.id)}
              >
                {data.length > 1 || data.description ? <TrashIcon /> : ""}
              </div>
            </div>
          ))}
        <div className="btn-wrapper">
          <button
            onClick={this.addField}
            className="btn btn-link d-inline-flex px-0 text-underline"
          >
            <PlusIcon /> Add Another Deliverable / Task
          </button>
        </div>
      </>
    );
  }
}

export default PlaybookDeliverables;
