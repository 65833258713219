import React, { useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownItem
} from "reactstrap";

//Components
import MoreDropdown from "../../components/base/MoreDropdown";
import PageTitle from "../../components/base/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import DeleteModal from "../../components/base/DeleteModal";
import TextArea from "../../components/base/TextArea";
import Form from "../../components/base/Form";
import Text from "../../components/base/Text";
import toast from "../../components/base/Toast";

//Config
import { endpoints } from "../../configs";
// Helper
import { getParamsByName } from "../../lib/helper";

//Actions
import { fetchList } from "../../actions/table";

//Common
import { isBadRequest } from "../../common/http";
import { apiClient } from "../../apiClient";

const Faq = props => {
  const dispatch = useDispatch();

  // initilize states
  const [visibility, setVisibility] = useState(false);
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [id, setId] = useState("");
  const [question, setQuestion] = useState("");
  const [answer, setAnswer] = useState("");
  const [disable, setDisable] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  // const appId = getParamsByName("id");
  //Add Data
  const addData = data => {
    dispatch(createFaq(id, data, {}));
  };
  //Update Data
  const updateData = data => {
    dispatch(updateFaq(id, data, {}));
  };

  //Delete Data
  const deleteData = id => {
    dispatch(deleteFaq(id, {}));
  };

  //Handle Save
  const handleSave = () => {
    const data = new FormData();
    //const appId = props && props.match ? this.props.match.params.id : 1;
    data.append("object_id", props.appId);
    data.append("title", question);
    data.append("description", answer);

    if (question && answer) addData(data);
  };

  //Handle Update
  const handleUpdate = () => {
    const data = new FormData();
    const appId = props.appId;
    data.append("object_id", appId);
    data.append("title", question);
    data.append("description", answer);
    if (question && answer) updateData(data);
  };

  const toggle = () => {
    setId(0);
    setIsOpen(!isOpen);
    setVisibility(false);
  };

  //Add Modal
  const handleAddModal = () => {
    setId("");
    setQuestion("");
    setAnswer("");
    setIsOpen(true);
  };

  //Create API route
  const createFaq = (id, data) => {
    let params = { objectId: props.appId };
    return dispatch => {
      return apiClient
        .post(`${endpoints().faqAPI}/${id}`, data)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
            toggle();
          }
        })
        .then(() => {
          dispatch(fetchList("faq", `${endpoints().faqAPI}`, 1, 10, params));
          setId("");
          setQuestion("");
          setAnswer("");
          toggle();
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    };
  };

  //Update API Route
  const updateFaq = (id, data) => {
    let params = { objectId: props.appId };
    return dispatch => {
      return apiClient
        .put(`${endpoints().faqAPI}/${id}`, data)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
          }
        })
        .then(() => {
          dispatch(fetchList("faq", `${endpoints().faqAPI}`, 1, 10, params));
          setId("");
          setQuestion("");
          setAnswer("");
          toggle();
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    };
  };

  //Delete Route
  const deleteFaq = id => {
    let params = { objectId: props.appId };
    return dispatch => {
      return apiClient
        .delete(`${endpoints().faqAPI}/${id}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
          }
        })
        .then(() => {
          dispatch(fetchList("faq", `${endpoints().faqAPI}`, 1, 10, params));
          setId("");
          setQuestion("");
          setAnswer("");
          setDeleteModal(false);
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    };
  };

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="landing-create-popup w-100"
        backdrop="static"
      >
        <ModalHeader
          toggle={toggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <span>{id ? "Edit" : "Add"} FAQ Item </span>
        </ModalHeader>
        <Form
          initialValues={{
            status: status || "",
            type: type || "",
            visibility: "",
            appId: getParamsByName("id"),
            faqTitle: question ? question : "",
            faqDescription: answer ? answer : ""
          }}
          onSubmit={values => {
            values.type = values.type.label || "";
            values.status = values.status.label || "";
          }}
          enableReinitialize={true}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div className="field-wrapper">
              <Text
                name="faqTitle"
                label="Title"
                placeholder="Enter title"
                isSearchable={true}
                onChange={e => {
                  setQuestion(e.target.value);
                }}
                error=""
                required={true}
              />
              <TextArea
                name="faqDescription"
                label="Description"
                placeholder="Enter Description"
                onChange={e => {
                  setAnswer(e.target.value);
                }}
                error=""
                required={true}
              />
            </div>
          </ModalBody>

          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button
                  type="submit"
                  label=""
                  onClick={e => {
                    setDisable(false);
                    id ? handleUpdate() : handleSave();
                  }}
                  disabled={disable}
                >
                  {id ? "Save" : "Add"}
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <div>
        <PageTitle
          label="FAQ Item"
          buttonLabel="Add New"
          buttonHandler={() => handleAddModal()}
        />
      </div>

      <div className="mt-1 mb-5">
        <ReduxTable
          id="faq"
          apiURL={`${endpoints().faqAPI}`}
          params={{ objectId: props.appId }}
          onRowClick={row => {
            toggle(row.id);
            setId(row.id);
            setQuestion(row.question);
            setAnswer(row.answer);
          }}
        >
          <ReduxColumn
            width={"295px"}
            type="link"
            isClickable="true"
            field="question"
          >
            Title
          </ReduxColumn>
          <ReduxColumn width={"215px"} field="answer" disableOnClick>
            Description
          </ReduxColumn>
          <ReduxColumn
            width={"100px"}
            disableOnClick
            className="action-column"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      setId(row.id);
                      setQuestion(row.question);
                      setAnswer(row.answer);
                      setIsOpen(true);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setId(row.id);
                      setQuestion(row.question);
                      setDeleteModal(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          isOpen={deleteModal}
          toggle={() => {
            setDeleteModal(false);
          }}
          title="Delete FAQ"
          question="Delete Faq"
          id={id}
          label={question}
          deleteFunction={deleteData}
        />
      </div>
    </div>
  );
};

export default Faq;
