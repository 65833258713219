export const TIMEZONE_LIST = [
  {
    label: "(GMT-05:00) Eastern Time",
    value: "America/New_York"
  },
  {
    label: "(GMT-06:00) Central Time",
    value: "America/Chicago"
  },
  {
    label: "(GMT-07:00) Mountain Time",
    value: "America/Denver"
  },
  {
    label: "(GMT-08:00) Pacific Time",
    value: "America/Los_Angeles"
  },
  {
    label: "(GMT-09:00) Alaska Time",
    value: "America/Anchorage"
  },
  {
    label: "(GMT-10:00) Hawaii Time",
    value: "America/Adak"
  }
];
