import React from "react";
import toast from "../../components/base/Toast";

// Components
import Form from "../../components/base/Form";
import PageTitle from "../../components/base/PageTitle";
import Text from "../../components/base/Text";
import Button from "../../components/base/Button";
import SaveButton from "../../components/base/SaveButton";
import CancelButton from "../../components/base/CancelButton";

// Configs
import { endpoints } from "../../configs";

//API call
import { apiClient } from "../../apiClient";

// Helper
import { toString } from "../../lib/helper";
import { isBadRequest } from "../../common/http";

export default class UpdateTagForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      type: "",
      projectCategory: "",
      loading: false
    };
  }

  componentDidMount = () => {
    this.getInputValue();
  };

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save tag form in the final
    this._tagRequest(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define tag request constants
    const name = values.name;
    const type = values.type;

    if (!name || !type) {
      success = false;
    }

    if (name && name.length > 51) {
      toast.error("Max allowed character 50");
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.name = toString(values.name);
    values.type = toString(values.type);
    return values;
  }

  // Tag Request API Call
  _tagRequest(data) {
    return apiClient
      .put(`${endpoints().tagAPI}/${this.props.match.params.id}`, data)
      .then(() => {
        window.location.replace("/settings/tags");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Tag delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().tagAPI}/${this.props.match.params.id}`)
      .then(() => {
        window.location.replace("/settings/tags");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Tag request api call by id
  getInputValue = () => {
    return apiClient
      .get(`${endpoints().tagAPI}/${this.props.match.params.id}`)
      .then(response => {
        const { name, type, projectCategory } = response.data;
        this.setState({ name, type, projectCategory, loading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  render() {
    if (!this.state.loading) {
      return "";
    }

    const { name, type } = this.state;

    const initialValues = {
      name,
      type,
      projectCategory: this.state.projectCategory
    };

    return (
      <div className="card">
        <div className="card-body">
          <PageTitle label={name} />
          <Form
            initialValues={initialValues}
            onSubmit={values => {
              this._submit(values);
            }}
          >
            <div>
              <Text
                name="name"
                label="Tag"
                placeholder="Enter tag"
                required={true}
                error=""
              />
            </div>

            <div>
              <Text
                name="type"
                label="Type"
                placeholder="Enter Type"
                required={true}
                error=""
              />
            </div>

            <div>
              <Text
                name="projectCategory"
                label="Project category"
                placeholder="Enter project category"
              />
            </div>

            <div>
              <SaveButton />
              <CancelButton onClick={() => this.props.history.goBack()} />
              <Button
                label="Delete"
                align="right"
                onClick={this.handleDelete}
                color="red"
              />
            </div>
          </Form>
        </div>
      </div>
    );
  }
}
