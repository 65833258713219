import React from "react";

// Components
import Form from "../../../../../components/base/Form";
import DefaultContent from "../../../../../components/content/DefaultContent";
import Text from "../../../../../components/base/Text";
import SaveButton from "../../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_STRIPE_PROVIDER_API_KEY,
  SETTINGS_STRIPE_SECRET_KEY
} from "../../../../../setting/Constants";

const StripeSection = props => {
  const { adminSettings, saveSettings } = props;

  const { stripe_secret_key, stripe_provider_api_key } = adminSettings;

  // Stripe Section Initial Values
  const stripeInitialValues = {
    stripe_secret_key,
    stripe_provider_api_key
  };

  // Stripe Section Submit Form
  const submit = values => {
    const data = new FormData();

    if (values.stripe_secret_key !== undefined) {
      data.append(
        "stripe_secret_key",
        values.stripe_secret_key ? values.stripe_secret_key : ""
      );
    }

    if (values.stripe_provider_api_key !== undefined) {
      data.append(
        "stripe_provider_api_key",
        values.stripe_provider_api_key ? values.stripe_provider_api_key : ""
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={stripeInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent title="Stripe">
        <h5 className="font-weight-bold">Secret Key</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_STRIPE_SECRET_KEY}
            placeholder="Stripe secret key"
          />
        </div>

        <h5 className="font-weight-bold">Provider API Key</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_STRIPE_PROVIDER_API_KEY}
            placeholder="Strip provider api key"
          />
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default StripeSection;
