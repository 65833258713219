import React from "react";
import Page404 from "../Page404";
import toast from "../../components/base/Toast";
import ContactFormFields from "./inner-component/ContactFormFields";

// Components
import Form from "../../components/base/Form";
import DeleteButton from "../../components/base/DeleteButton";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import PageTitle from "../../components/base/PageTitle";
import DeleteModal from "../../components/base/DeleteModal";

// Configs
import { endpoints } from "../../configs";

// API call

import { apiClient } from "../../apiClient";

// Helper
import {
  removeMaskedPhoneNumber,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import { isBadRequest } from "../../common/http";
import SelectDropdown from "../../components/base/SelectDropdown";
import { connect } from "react-redux";

class UpdateUserForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      contactDetails: [],
      loading: false,
      isInvalidRequest: false,
      isPageNotFound: false,
      openDeleteModal: false
    };
  }

  componentDidMount = () => {
    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    ) {
      this._getContactDetails();
    } else {
      this.setState({
        isPageNotFound: true
      });
    }
  };

  // Contact update API Call
  _updateContact(data) {
    return apiClient
      .put(`${endpoints().contactAPI}/${this.props.match.params.id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Contact delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().contactAPI}/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;

          toast.success(successMessage);

          this.props.history.push("/contacts");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Contact details api call by id
  _getContactDetails = () => {
    return apiClient
      .get(`${endpoints().contactAPI}/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({ contactDetails: response.data, loading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  handleSyncToSalesforce = () => {
    this.setState({ isContactSyncing: true }, () => {
      return apiClient
        .put(
          `${endpoints().syncAPI}/salesforce/contact/${
            this.props.match.params.id
          }`
        )
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }

          this.setState({ isContactSyncing: false });

          toast.success(successMessage);
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    });
  };

  handleAdminAction = value => {
    if (value === "Sync To Salesforce") {
      return this.handleSyncToSalesforce();
    } else if (value === "Sync To Hubspot") {
    }
  };

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (this.state.isPageNotFound) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return "";
    }

    const { first_name, last_name, email, phone } = this.state.contactDetails;
    const { openDeleteModal } = this.state;

    const initialValues = {
      firstName: first_name,
      lastName: last_name,
      email,
      phone
    };

    const adminActionOptions = [
      { value: "Sync To Salesforce", label: "Sync To Salesforce" },
      { value: "Sync To Hubspot", label: "Sync To Hubspot" }
    ];

    return (
      <>
        <DeleteModal
          isOpen={openDeleteModal}
          title="Delete Contact "
          toggle={() => {
            this.setState({ openDeleteModal: false });
          }}
          label={first_name}
          deleteFunction={() => this.handleDelete(this.props.match.params.id)}
        />
        <div className="d-flex justify-content-between align-items-center">
          <PageTitle label="Edit Contact" />
          {(isSuperAdmin(this.props.roleId) ||
            isCompanyAdmin(this.props.roleId)) && (
            <SelectDropdown
              buttonLabel="Admin Actions"
              dropdownLinks={adminActionOptions}
              color={"gray"}
              hideCaret
              selectName={"admin_action"}
              handleChange={this.handleAdminAction}
            />
          )}
        </div>
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            values.phone =
              values.phone && removeMaskedPhoneNumber(values.phone);

            this._updateContact(values);
          }}
        >
          <ContactFormFields />

          <div className="mb-4">
            <DeleteButton
              label={"Delete Contact"}
              onClick={() => {
                this.setState({ openDeleteModal: true });
              }}
            />
            <div className="float-right">
              <CancelButton
                onClick={() => this.props.history.push("/contacts")}
              />
              <SaveButton />
            </div>
          </div>
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(UpdateUserForm);
