import React from "react";

// Components
import PageTitle from "../../components/base/PageTitle";

import AddPaymentForm from "./AddPaymentForm";

const AddPayment = props => {
  return (
    <div>
      <PageTitle label="Add Payment" />
      <AddPaymentForm history={props.history} />
    </div>
  );
};

export default AddPayment;
