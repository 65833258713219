import React, { useState } from "react";
import { Navbar } from "reactstrap";
import UtilityList from "./UtilityList";
import UserNavDropdown from "./UserNavDropdown";
// Assets

import CompanyDropdown from "./CompanyDropdown";
import Logo from "../base/Logo";
import { THEME_LAYOUT_FLUID_WIDTH } from "../../setting/Constants";
import detectBrowser from "../helpers/detectBrowser";
import MobileHamburgerMenu from "../base/MobileHamburgerMenu";
import { useSelector } from "react-redux";
import { COLOR_WHITE } from "../../common/color";

const DashboardNavigation = ({
  showNav,
  pathName,
  changeNavBarColor,
  allowAccess,
  partnerStatus,
  portalLogo,
  headerColor,
  headerTextColor,
  enableHelp,
  enablePartnerPayments,
  companyList,
  handleCompanyChange,
  id,
  layout,
  portalName,
  roleId,
  portalTagLine
}) => {
  const [isMobile] = useState(detectBrowser());

  const currentUser = useSelector(state => state.user);

  return (
    <>
      {pathName.includes("/expert-availability") > 0 && (
        <Navbar
          className={`navbar navbar-expand-lg ${showNav} ${changeNavBarColor}`}
        ></Navbar>
      )}

      {!pathName.includes("/expert-availability") && (
        <Navbar
          className={`navbar navbar-expand ${showNav} ${changeNavBarColor}`}
          style={{
            backgroundColor: headerColor,
            color: headerTextColor,
            height: "4rem"
          }}
        >
          <div
            className={`${
              layout === THEME_LAYOUT_FLUID_WIDTH
                ? "container-fluid"
                : isMobile
                ? "container row"
                : "container "
            } pl-0`}
          >
            <div
              className={`${isMobile ? "col-4" : ""} d-flex
  align-items-center justify-content-center`}
            >
              <MobileHamburgerMenu headerTextColor={headerTextColor} />
              <Logo
                src={portalLogo}
                link="/dashboard"
                altText={portalName}
                height={"40px"}
                label={portalName}
                headerTextColor={headerTextColor}
              />
              {portalTagLine && (
                <>
                  <div
                    style={{
                      color: headerTextColor ? headerTextColor : COLOR_WHITE,
                      width: "1px",
                      height: "40px",
                      backgroundColor: headerTextColor
                        ? headerTextColor
                        : COLOR_WHITE,
                      margin: "0 10px"
                    }}
                  ></div>
                  <p
                    style={{
                      backgroundColor: "transparent",
                      textDecoration: "none",
                      color: headerTextColor ? headerTextColor : COLOR_WHITE,
                      textAlign: "center",
                      margin: "0px"
                    }}
                  >
                    {portalTagLine}
                  </p>
                </>
              )}
            </div>
            <div
              className={`${
                isMobile ? "col-8" : ""
              } nav-wrapper d-flex align-items-center`}
            >
              {companyList && companyList.length > 1 && (
                <CompanyDropdown
                  id={id}
                  headerColor={headerColor}
                  headerTextColor={headerTextColor}
                  companyList={companyList}
                  handleCompanyChange={handleCompanyChange}
                />
              )}
              <div className="ml-1">
                {enableHelp && enableHelp === "true" && (
                  <UtilityList
                    headerTextColor={headerTextColor}
                    enable={allowAccess}
                  />
                )}
              </div>

              {/* /.utilities */}
              <UserNavDropdown
                id={id}
                headerTextColor={headerTextColor}
                enable={allowAccess}
                partnerStatus={partnerStatus}
                enablePartnerPayments={enablePartnerPayments}
                roleId={roleId}
              />
            </div>
            {/* /.nav-wrapper */}
          </div>
        </Navbar>
      )}
    </>
  );
};

export default DashboardNavigation;
