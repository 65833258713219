import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";
import { isLoggedIn } from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

export default class ExpertRequest extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "first_name:ASC",
          label: "Name"
        }
      ]
    };
  }
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    return (
      <div>
        <PageTitle label="Expert Requests" />

        <div>
          <ReduxTable
            id="expertRequest"
            apiURL={`${endpoints().lead}/search`}
            newTableHeading
            showHeader
            searchPlaceholder="Search"
            sortByOptions={this.state.sortByOptions}
            onRowClick={row =>
              this.props.history.push(`/expert-requests/details/${row.id}`)
            }
          >
            <ReduxColumn field="createdAt">Date</ReduxColumn>
            <ReduxColumn type="link" isClickable="true" field="firstName">
              First Name
            </ReduxColumn>
            <ReduxColumn field="lastName">Last Name</ReduxColumn>
            <ReduxColumn field="email">Email</ReduxColumn>
            <ReduxColumn field="description">Description</ReduxColumn>
            {/* <ReduxColumn field="reference">Reference</ReduxColumn>
            <ReduxColumn field="expertType">Expert Type</ReduxColumn>
            <ReduxColumn field="projectType">Project Type</ReduxColumn>
            <ReduxColumn field="startDate">Start Date</ReduxColumn>
            <ReduxColumn field="timeline">Timeline</ReduxColumn>
            <ReduxColumn field="timeCommitment">Time commitments</ReduxColumn>
            <ReduxColumn field="budget">Budget</ReduxColumn>
            <ReduxColumn field="additionalDetails">
              Additional Details
            </ReduxColumn> */}
          </ReduxTable>
        </div>
      </div>
    );
  }
}
