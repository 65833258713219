import React, { useEffect, useState } from "react";

// Configs
import { endpoints } from "../../../../configs";

// Action
import { fetchList } from "../../../../actions/table";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

import ReferralTab from "../../inner-components/referralTab";

import { REFERRAL_STATUS_CLOSED_WON } from "../../../../referral/Constants";

// API call
import { apiClient } from "../../../../apiClient";

const ReferralReport = props => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState();
  const [partnerId, setPartnerId] = useState("");
  const [partnerTypeId, setpartnerTypeId] = useState("");
  const [referralCount, setTotalReferralCount] = useState(0);
  const [referralSize, setReferralSize] = useState(0);
  const [referralTotalAmount, setReferralTotalAmount] = useState(0);
  const [reportData, setReportData] = useState([]);

  useEffect(() => {
    getExportContent();
  }, [props.totalCount]);

  const handleStartDate = date => {
    if (date) {
      setStartDate(date);
    } else {
      setStartDate("");
    }

    props.actions.fetchList(
      "closedWonReferralReport",
      `${endpoints().referralApi}/search`,
      1,
      10,
      {
        pagination: true,
        closedWonAtStartDate: date ? date : "",
        closedWonAtEndDate: endDate ? endDate : "",
        partnerId: partnerId ? partnerId : "",
        status: REFERRAL_STATUS_CLOSED_WON,
        typeId: partnerTypeId
      }
    );
  };

  const handleEndDate = date => {
    if (date) {
      setEndDate(date);
    } else {
      setPartnerId("");
    }

    props.actions.fetchList(
      "closedWonReferralReport",
      `${endpoints().referralApi}/search`,
      1,
      10,
      {
        pagination: true,
        closedWonAtStartDate: startDate ? startDate : "",
        closedWonAtEndDate: date ? date : "",
        partnerId: partnerId ? partnerId : "",
        status: REFERRAL_STATUS_CLOSED_WON,
        typeId: partnerTypeId
      }
    );
  };

  const handlePartnerChange = selectedValue => {
    let partnerId = selectedValue ? selectedValue.value : "";
    if (partnerId) {
      setPartnerId(partnerId);
    } else {
      setPartnerId("");
    }

    props.actions.fetchList(
      "closedWonReferralReport",
      `${endpoints().referralApi}/search`,
      1,
      10,
      {
        pagination: true,
        closedWonAtStartDate: startDate ? startDate : "",
        closedWonAtEndDate: endDate ? endDate : "",
        partnerId: partnerId,
        status: REFERRAL_STATUS_CLOSED_WON,
        typeId: partnerTypeId
      }
    );
  };

  const handlePartnerTypeChange = partnerType => {
    let partnerTypeId = partnerType ? partnerType.value : null;
    if (partnerTypeId) {
      setpartnerTypeId(partnerTypeId);
    } else {
      setpartnerTypeId("");
    }

    props.actions.fetchList(
      "closedWonReferralReport",
      `${endpoints().referralApi}/search`,
      1,
      10,
      {
        pagination: true,
        closedWonAtStartDate: startDate ? startDate : "",
        closedWonAtEndDate: endDate ? endDate : "",
        partnerId: partnerId ? partnerId : "",
        status: REFERRAL_STATUS_CLOSED_WON,
        typeId: partnerTypeId ? partnerTypeId : ""
      }
    );
  };

  // piplinedetails details api call
  const getExportContent = async () => {
    const params = [];

    let apiUrl;

    if (startDate) {
      params.push(`closedWonAtStartDate=${startDate}`);
    }
    if (endDate) {
      params.push(`closedWonAtEndDate=${endDate}`);
    }

    if (partnerId) {
      params.push(`partnerId=${partnerId}`);
    }

    if (partnerTypeId) {
      params.push(`typeId=${partnerTypeId}`);
    }

    params.push(`status=${REFERRAL_STATUS_CLOSED_WON}`);

    if (params && params.length > 0) {
      apiUrl = `${endpoints().referralApi}/search?${params.join("&")}`;
    } else {
      apiUrl = `${endpoints().referralApi}/search`;
    }

    const response = await apiClient.get(apiUrl);

    if (response && response.data) {
      const reportData = [];
      const referralCount = response.data.totalCount || 0;
      const referralSize = response.data.averageAmount || 0;
      const referralTotalAmount = response.data.totalAmount || 0;

      setTotalReferralCount(referralCount);
      setReferralSize(referralSize);
      setReferralTotalAmount(referralTotalAmount);

      response.data.data.forEach(data => {
        reportData.push({
          accountName: data.account_name,
          amount: data.amount,
          status: data.status,
          createdAt: data.createdAt
        });
      });

      setReportData(reportData);
    }
  };

  const exportHeaders = [
    { label: "AccountName", key: "accountName" },
    { label: "Amount", key: "amount" },
    { label: "Partner", key: "partner_id" },
    { label: "Status", key: "status" },
    { label: "ClosedWonAt", key: "ClosedWonAt" }
  ];

  return (
    <div>
      <ReferralTab
        params={{ status: "Closed Won" }}
        tableId={"closedWonReferralReport"}
        apiUrl={`${endpoints().referralApi}/search`}
        handlePartnerChange={handlePartnerChange}
        handleStartDate={handleStartDate}
        handleEndDate={handleEndDate}
        handlePartnerTypeChange={handlePartnerTypeChange}
        startDate={startDate}
        endDate={endDate}
        headers={exportHeaders}
        referralCount={referralCount}
        referralSize={referralSize}
        referralTotalAmount={referralTotalAmount}
        reportData={reportData}
      />
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

// Get ReportStatCard Counts
const mapStateToProps = state => {
  const reduxTable = state.table;

  // Get closed Deals count
  const currentPage =
    reduxTable["closedWonReferralReport"] &&
    !reduxTable["closedWonReferralReport"].isFetching
      ? reduxTable["closedWonReferralReport"].currentPage
      : 1;

  const data =
    reduxTable["closedWonReferralReport"] &&
    !reduxTable["closedWonReferralReport"].isFetching
      ? reduxTable["closedWonReferralReport"][1]
      : [];

  const totalCount =
    reduxTable["closedWonReferralReport"] &&
    !reduxTable["closedWonReferralReport"].isFetching
      ? reduxTable["closedWonReferralReport"].totalCount
      : [];

  return {
    currentPage: currentPage,
    data: data,
    totalCount: totalCount
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralReport);
