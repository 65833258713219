import React, { useEffect, useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import Form from "../../../../../components/base/Form";
import Select from "../../../../../components/base/Select";
import CancelButton from "../../../../../components/base/CancelButton";
import Text from "../../../../../components/base/Text";
import Radio from "../../../../../components/base/Radio";
import MultiselectCreatable from "../../../../../components/base/MultiselectCreatable";
import DragAndDropField from "../../../../../components/base/DragAndDropField";
import Hint from "../../../../../components/base/Hint";
import SaveButton from "../../../../../components/base/SaveButton";

import {
  TASK_TYPE_DROP_DOWN_FIELD,
  TASK_TYPE_DROP_DOWN_MULTI_SELECT,
  TASK_TYPE_DROP_DOWN_SINGLE_SELECT,
  TASK_TYPE_FILE_UPLOAD,
  TASK_VISIBILITY_PUBLIC,
  taskTypeOptions,
  visibilityOptions
} from "../../../../../modules/task/Constant";
import {
  TAG_PERMISSION_SELECT_AND_ADD,
  TAG_PERMISSION_SELECT_AND_ADD_VALUE,
  TAG_PERMISSION_SELECT_AND_NOT_ADD,
  TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
} from "../../../../../tag/Constants";

const EditFieldModal = props => {
  let { field, isOpen, toggle, updateField } = props;
  let {
    id,
    name,
    description,
    type,
    resourceFile,
    visibility,
    selectType,
    tagList,
    tagTypePermission
  } = field;
  const [newResourceFile, setResorceFile] = useState("");
  const [resourceFileName, setResorceFileName] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDropDownType, setSelectedDropDownType] = useState(selectType);
  const [selectedTagTypePermission, setSelectedTagTypePermission] = useState(
    tagTypePermission
  );

  useEffect(() => {
    setSelectedDropDownType(selectType);
  }, [selectType]);
  const closeEditFieldModal = () => {
    toggle();
    setResorceFileName("");
    setResorceFile("");
    setSelectedType("");
  };
  const updateFieldDetails = values => {
    const data = new FormData();
    data.append("name", values.name || "");
    data.append("description", values.description || "");
    data.append(
      "type",
      (values.type && values.type.value && values.type.value) || ""
    );
    data.append(
      "visibility",
      (values.visibility &&
        values.visibility.value &&
        values.visibility.value) ||
        TASK_VISIBILITY_PUBLIC
    );

    if (newResourceFile) {
      data.append("resourceFile", newResourceFile ? newResourceFile : "");
      data.append("resourceFileName", resourceFileName ? resourceFileName : "");
    }

    data.append("selectType", selectedDropDownType ? selectedDropDownType : "");

    data.append(
      "customTags",
      values.customTags && values.customTags.length > 0
        ? JSON.stringify(values.customTags)
        : ""
    );
    data.append(
      "tagTypePermission",
      selectedTagTypePermission === TAG_PERMISSION_SELECT_AND_ADD
        ? TAG_PERMISSION_SELECT_AND_ADD_VALUE
        : selectedTagTypePermission === TAG_PERMISSION_SELECT_AND_NOT_ADD
        ? TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
        : ""
    );

    updateField(id, data);
    closeEditFieldModal();
  };

  // Handle resource file upload
  const handleResourceFile = files => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.name) {
        setResorceFileName(file.name);

        setResorceFile(file);
      }
    }
  };
  const handleTypeChange = selectedTypeObject => {
    if (selectedTypeObject && selectedTypeObject.value) {
      setSelectedType(selectedTypeObject.value);
    }
  };

  const initialValues = {
    name: name || "",
    description: description || "",
    type: selectedType
      ? taskTypeOptions.find(
          fieldTypeOptionDetails =>
            fieldTypeOptionDetails.value === selectedType
        )
      : type
      ? taskTypeOptions.find(
          fieldTypeOptionDetails => fieldTypeOptionDetails.value === type
        )
      : "",
    visibility: visibility
      ? visibilityOptions.find(
          visibilityOptionDetails =>
            visibilityOptionDetails.value === visibility
        )
      : "",
    customTags: tagList || []
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeEditFieldModal}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          updateFieldDetails(values);
        }}
      >
        <ModalHeader toggle={closeEditFieldModal}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Edit Application Field
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div>
              <Text
                id={`name`}
                name={`name`}
                label="Name"
                placeholder="Name"
                required
                error=""
              />
            </div>
            <div>
              <Text
                id={`description`}
                name={`description`}
                label="Description"
                placeholder="Description"
                error=""
              />
            </div>
            <div>
              <Select
                label="Visibility"
                name="visibility"
                placeholder="Select"
                options={visibilityOptions}
                isSearchable={true}
              />
            </div>
            <div>
              <Select
                label="Type"
                name="type"
                placeholder="Select"
                options={taskTypeOptions}
                isSearchable={true}
                required
                handleChange={e => handleTypeChange(e)}
              />
            </div>
            {(selectedType === TASK_TYPE_FILE_UPLOAD ||
              (selectedType === "" && type === TASK_TYPE_FILE_UPLOAD)) && (
              <>
                <div className="w-100 border-bottom mt-4 mb-3"></div>
                <div className="form-wrapper mt-4">
                  <div className="flex-column">
                    <DragAndDropField
                      label={
                        "Add Supporting Materials for a Partner to Download"
                      }
                      name={"file"}
                      btnLabel={"Upload File"}
                      onFileChange={handleResourceFile}
                      data={
                        newResourceFile
                          ? []
                          : resourceFile
                          ? [resourceFile]
                          : []
                      }
                      minHeight={"140px"}
                    />

                    <Hint
                      id="supportingMaterial"
                      hintText="If the partners need to download a template, add them here."
                    />
                  </div>
                </div>
              </>
            )}
            {(selectedType === TASK_TYPE_DROP_DOWN_FIELD ||
              (selectedType === "" && type === TASK_TYPE_DROP_DOWN_FIELD)) && (
              <>
                <div>
                  <Radio
                    name={`dropdownType`}
                    options={[
                      TASK_TYPE_DROP_DOWN_SINGLE_SELECT,
                      TASK_TYPE_DROP_DOWN_MULTI_SELECT
                    ]}
                    size="large"
                    label="Drop Down Type"
                    onChange={e => {
                      if (e && e.target && e.target.value) {
                        setSelectedDropDownType(e.target.value);
                      }
                    }}
                    defaultValue={selectedDropDownType}
                    showInline={true}
                    className="row ml-0 mr-0 mb-0 pl-1 pr-1"
                    radioClassName="col-6 mb-0"
                  />
                </div>

                <div className="field-wrapper">
                  <MultiselectCreatable
                    name={"customTags"}
                    label={"Field Values"}
                    placeholder={"Enter the field values"}
                    options={tagList}
                    closeMenuOnSelect={false}
                  />
                </div>

                {selectedDropDownType === TASK_TYPE_DROP_DOWN_MULTI_SELECT && (
                  <div className="form-wrapper">
                    <h6 className="mb-2">Partner Permissions</h6>
                    <div className="px-3 w-100">
                      <Radio
                        name="tagTypePermissions"
                        options={[
                          TAG_PERMISSION_SELECT_AND_ADD,
                          TAG_PERMISSION_SELECT_AND_NOT_ADD
                        ]}
                        size="large"
                        onChange={e => {
                          if (e && e.target && e.target.value) {
                            setSelectedTagTypePermission(e.target.value);
                          }
                        }}
                        defaultValue={
                          tagTypePermission &&
                          tagTypePermission ===
                            TAG_PERMISSION_SELECT_AND_ADD_VALUE
                            ? TAG_PERMISSION_SELECT_AND_ADD
                            : tagTypePermission ===
                              TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
                            ? TAG_PERMISSION_SELECT_AND_NOT_ADD
                            : ""
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={closeEditFieldModal} />
            <SaveButton />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditFieldModal;
