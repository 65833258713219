// API Client
import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import { isBadRequest } from "../common/http";

// get user list
export const search = async (roleId, callback) => {
  let userList = [];
  await apiClient
    .get(`${endpoints().userAPI}/search/?roleIds=${roleId}`)
    .then(response => {
      const results = response.data && response.data.data;
      if (results) {
        results.forEach(data => {
          userList.push({
            id: data.id,
            name: data.name,
            picture: data.avatarUrl,
            firstName: data.firstName,
            lastName: data.lastName
          });
        });
      }
      return callback(userList);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    });
};

export default {
  search
};
