import React, { Component } from "react";
// Configs
import { endpoints } from "../../configs";
// API call
import { apiClient } from "../../apiClient";
import { isBadRequest } from "../../common/http";
// Helper
import { getParamsByName } from "../../lib/helper";

// Components
import Text from "../../components/base/Text";
import Select from "../../components/base/Select";
import MultiSelect from "../../components/base/Multiselect";
import Hint from "../../components/base/Hint";
import SingleCheckbox from "../../components/base/SingleCheckbox";
import TextArea from "../../components/base/TextArea";
import ColorPicker from "../../components/base/ColorPicker";
import ToggleSwitch from "../../components/base/ToggleSwitch";
import Email from "../../components/base/Email";
import InputURL from "../../components/base/URL";
import SaveButton from "../../components/base/SaveButton";
import Form from "../../components/base/Form";
import toast from "../../components/base/Toast";
import Spinner from "../../components/base/Spinner";
import DragAndDropField from "../../components/base/DragAndDropField";

//Constant
import { TAG_TYPE_APP_CATEGORY } from "../../tagType/Constants";

export default class Basics extends Component {
  constructor(props) {
    super(props);
    const { isFeatured } = this.props;
    // Set the initial input values
    this.state = {
      appId: "",
      cardTitleShow: false,
      fileTypeError: false,
      isLoading: false,
      appLogoFileUrl: "",
      appLogoFile: "",
      files: [],
      categories: [],
      installationfileName: "",
      installationfile: "",
      installationFiles: this.props.appDetails.installation_file,
      isFeatured: isFeatured ? isFeatured : false
    };
    this.handleAppLogoFile = this.handleAppLogoFile.bind(this);

    //this.toggle = this.toggle.bind(this);
  }

  componentDidMount() {
    this.setState({
      appId: getParamsByName("id")
    });
    this.getTagValues(TAG_TYPE_APP_CATEGORY);
  }

  // Select the applogo image
  onSelectFile = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          this.setState({
            fileTypeError: false
          });
          this.handleAppLogoFile(e);
          break;

        default:
          this.setState({
            fileTypeError: true
          });
          break;
      }
    }
  };

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Banner app logo Upload
  handleAppLogoFile = e => {
    const files = e.target.files ? e.target.files[0] : "";
    this.props.appLogoFileChange(files);
    this.setState({ appLogoFileUrl: URL.createObjectURL(files) }, () =>
      this.setBase64Image(files, "files")
    );
  };

  // Create app
  _submit = values => {
    this._updateApp(this._toArray(values), false);
  };

  // To Array
  _toArray(values) {
    const data = new FormData();
    data.append("appName", values.appName ? values.appName : "");
    data.append("appType", values.appType.name ? values.appType.name : "");

    let categoryData = [];
    values &&
      values.categories &&
      values.categories.length >= 0 &&
      values.categories.forEach(data => {
        if (data) {
          categoryData.push({ id: data.id, name: data.name });
        }
      });

    categoryData = JSON.stringify(categoryData);
    data.append("categories", categoryData ? categoryData : "");
    data.append("contactEmail", values.contactEmail ? values.contactEmail : "");
    data.append("version", values.version ? values.version : "");
    data.append("versionNotes", values.versionNotes ? values.versionNotes : "");
    data.append(
      "installationUrl",
      values.installationUrl ? values.installationUrl : ""
    );
    data.append(
      "integrationDescrition",
      values.integrationDescrition ? values.integrationDescrition : ""
    );
    data.append("keyBenefits", values.keyBenefits ? values.keyBenefits : "");
    data.append("summary", values.summary ? values.summary : "");
    data.append("supportEmail", values.supportEmail ? values.supportEmail : "");
    data.append("supportUrl", values.supportUrl ? values.supportUrl : "");
    data.append(
      "isFeatured",
      this.state.isFeatured ? this.state.isFeatured : ""
    );
    data.append(
      "integrationType",
      values.integrationType && values.integrationType.label
        ? values.integrationType.label
        : ""
    );
    data.append(
      "setup",
      values.setup && values.setup.label ? values.setup.label : ""
    );
    data.append(
      "app_logo",
      this.state.appLogoFileUrl
        ? this.state.appLogoFileUrl
        : this.props.appDetails.app_logo
        ? this.props.appDetails.app_logo
        : ""
    );
    data.append(
      "bannerColor",
      this.props.bannerColor ? this.props.bannerColor : values.bannerColor
    );
    data.append("file", this.state.files);
    data.append("installation_fileName", this.state.installationfileName);
    data.append("installation_file", this.state.installationfile);
    data.append(
      "appProfileUrl",
      values.appProfileUrl ? values.appProfileUrl : ""
    );
    data.append(
      "publicationNotification",
      values.publicationNotification == true ? 1 : 0
    );

    return data;
  }

  // Get Tag Values

  getTagValues = async tagType => {
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${tagType}`
      );
      const results = response.data.data;

      // Check the tag type skills
      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });

        this.setState({
          categories: tagList
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  // update app
  _updateApp(data) {
    const id = this.state.appId;
    return apiClient
      .put(`${endpoints().appAPI}/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.props.getAppDetails(id);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isSubmitting: false });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Splitting the Selected Tags
  getValue = (array, array1) => {
    const selectedTags = [];
    const tagInitialValues = {};

    if (array && array.length > 0) {
      array.forEach(data => {
        if (array1 && array1.length > 0)
          array1.forEach(tag => {
            if (tag.value.trim() === data.trim()) selectedTags.push(tag);
          });
      });
      tagInitialValues["categories"] = selectedTags;
    }
    return tagInitialValues;
  };

  // Handle installation file upload
  handleInstallationFile = files => {
    this.setState({
      installationfileName: files[0].name,
      installationfile: files[0]
    });
  };

  // Delete Installation file
  handleDeleteInstallationFile = () => {
    const id = this.state.appId;
    return apiClient
      .delete(`${endpoints().appAPI}/file/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.setState({ installationFiles: "" });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // handle featured apps
  handleFeaturedApp = () => {
    const { isFeatured } = this.state;
    this.setState({
      isFeatured: !isFeatured
    });
  };

  render() {
    const { appDetails } = this.props;
    const { categories, isLoading, isFeatured } = this.state;
    const categoryData = appDetails && appDetails.categories;
    var array = categoryData && categoryData.split(",");
    const tagTypeInitialValues = this.getValue(array, categories) || {};
    const data = {
      appLogoUrl:
        !this.props.appLogoFileUrl &&
        this.props.appDetails &&
        this.props.appDetails.app_logo
          ? this.props.appDetails.app_logo
          : this.props.appLogoFileUrl,
      bannerColor:
        !this.props.bannerColor &&
        this.props.appDetails &&
        this.props.appDetails.app_color
          ? this.props.appDetails.app_color
          : this.props.bannerColor
    };

    const id = appDetails.id || "";

    const appTypes = [
      {
        label: "Web Integration",
        name: "Web Integration"
      }
    ];

    const integrationType = [
      {
        label: "Direct",
        name: "direct"
      }
    ];

    const setup = [
      {
        label: "Turnkey",
        name: "turnkey"
      }
    ];

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <div style={{ maxWidth: "440px" }}>
        <Form
          enableReinitialize={true}
          initialValues={{
            ...tagTypeInitialValues,
            id: id || "",
            appName: this.props.appDetails.app_name || "",
            appProfileUrl: this.props.appDetails.profile_url || "",
            appType: this.props.appDetails.appType || "",
            app_logo: this.props.appDetails.app_logo || "",
            contactEmail: this.props.appDetails.contactEmail || "",
            installationUrl: this.props.appDetails.installationUrl || "",
            integrationDescrition:
              this.props.appDetails.integrationDescrition || "",
            integrationType: this.props.appDetails.integrationType || "",
            keyBenefits: this.props.appDetails.keyBenefits || "",
            setup: this.props.appDetails.setup || "",
            summary: this.props.appDetails.summary || "",
            supportEmail: this.props.appDetails.supportEmail || "",
            supportUrl: this.props.appDetails.supportUrl || "",
            version: this.props.appDetails.version || "",
            versionNotes: this.props.appDetails.versionNotes || "",
            publicationNotification:
              appDetails.publication_notification == 1 ? true : false
          }}
          onSubmit={values => {
            this._submit(values);
          }}
        >
          <div className="field-wrapper mt-3">
            <Select
              id={id}
              name={"appType"}
              label="Choose your App Type"
              fontBolded
              options={appTypes}
              defaultValue={appTypes}
            />
          </div>

          <Text
            id={id}
            name={"appName"}
            label={"App Name"}
            fontBolded
            placeholder="Enter App Name"
            error=""
            required={true}
            onChange={this.props._handleChange}
            autoFocus={true}
            // onKeyPress={e => {
            //   e.key === "Enter" && e.preventDefault();
            // }}
            //nChange={handleNameChange}
          />
          {/* app profile url */}
          <div className="field-wrapper">
            <Text
              id={id}
              name={"appProfileUrl"}
              placeholder={"Enter Profile URL"}
              label={"Profile URL"}
              error=""
              required={true}
            />
          </div>

          <div className="field-wrapper pt-2 pb-2">
            <MultiSelect
              name="categories"
              label="Categories"
              fontBolded
              options={categories}
              hintText="The type of category you want to add app"
            />
          </div>

          <div className="pt-4 pb-2">
            <Text
              name="version"
              label="Integration Version"
              fontBolded
              placeholder="Enter Integration Version"
              //  onChange={this.props._handleChange}
              onKeyPress={e => {
                e.key === "Enter" && e.preventDefault();
              }}
              hintText="We recommend version 1.0 once integration is in general release"
            />
          </div>

          <div className="pt-4 pb-2">
            <TextArea
              name="versionNotes"
              label="Version Notes"
              fontBolded
              placeholder="Enter version notes"
              hintText="Provide info on changes in the new releases of the integration"
            />
          </div>

          <div className="upload-field d-inline-block pt-4 pb-2">
            <input
              name="app_logo"
              className="form-control d-none"
              type="file"
              id="app_logo"
              placeholder="app_logo"
              onChange={e => {
                this.onSelectFile(e);
              }}
              accept="image/png,image/gif,image/jpeg"
            />
            <span className="profilePicOverlay d-block ">
              <label htmlFor="app_logo" className="profile-img-sm mb-0">
                <span className="banner-image-upload-link">
                  Upload App Logo
                </span>
              </label>
            </span>
          </div>
          {/* Remove image */}
          {data.appLogoUrl && (
            <span
              className="banner-image-upload-link text-danger ml-md-1"
              onClick={this.props.handleAppLogoRemove}
            >
              (Remove)
            </span>
          )}

          <Hint
            id="expert-roles"
            hintText="We recomment using 200 x 200 image"
          />

          <div className="pt-4">
            <div className="form-group">
              <label className="font-weight-bold">Color</label>
              <ColorPicker
                onColourPickerClick={this.props.onColourPickerClick}
                bannerColor={this.props.bannerColor}
              />
            </div>
          </div>

          <div className="pt-4 pb-2">
            <TextArea
              label="Summary"
              fontBolded
              name="summary"
              placeholder="Enter summary"
              hintText="Summary will appear on Martetplace card. 200 character max."
              showCharCount
              maxLength={200}
            />
          </div>

          <div className="pt-4 pb-2">
            <TextArea
              label="Integration Description"
              fontBolded
              name="integrationDescrition"
              placeholder="Enter Integration Description"
              hintText="Description will appear on App Detail Page. 500 character max."
              showCharCount
              maxLength={500}
            />
          </div>

          <div className="pt-4 pb-3">
            <TextArea
              label="Key Benefits"
              fontBolded
              name="keyBenefits"
              placeholder="Enter key benefits"
              hintText="2-5 bullet points will appear on App Detail Page"
            />
          </div>

          <div className="pt-4 pb-3">
            <ToggleSwitch
              name="featuredApp"
              label1="Show in Featured Apps"
              label2="Show in Featured Apps"
              value={isFeatured}
              className={["flex-column", "align-items-start"].join(" ")}
              handleChange={this.handleFeaturedApp}
              outlined
            />
          </div>

          <div className="pt-4 pb-3">
            <label className="font-weight-bold">
              App Publication Notification
            </label>
            <SingleCheckbox
              name="publicationNotification"
              label="Send Notification to partners that have permissions"
              className="accepted-terms pb-0 mr-3"
            />
          </div>

          <div className=" pt-4">
            <Select
              name="integrationType"
              label="Integration Type"
              fontBolded
              options={integrationType}
              defaultValue={integrationType}
              hintText="Choose Direct unless powered through an iPaaS provider"
            />
          </div>

          <div className=" pt-4 pb-2">
            <Select
              name="setup"
              label="Setup"
              fontBolded
              options={setup}
              defaultValue={setup}
              hintText="Choose how the integration is deployed for the user"
            />
          </div>

          <div className=" pt-4 pb-2">
            <Email
              fontBolded
              name="supportEmail"
              label="Support Email"
              placeholder="Enter support email"
              hintText="Where emails will route for integration support"
            />
          </div>
          <div className=" pt-4 pb-2">
            <InputURL
              fontBolded
              label="Support URL"
              name="supportUrl"
              placeholder="Enter support url"
              hintText="Where users will go for integration support"
            />
          </div>

          <div className=" pt-4 pb-2">
            <Email
              fontBolded
              name="contactEmail"
              label="Contact Email"
              placeholder="Enter contact email"
              hintText="Where email will route for developer questions"
            />
          </div>

          <div className=" pt-4 pb-4">
            <InputURL
              fontBolded
              label="Installation URL"
              name="installationUrl"
              placeholder="Enter Installation url"
              hintText="Where users will go for developer details"
            />
          </div>

          <div className="pt-4 pb-4">
            <DragAndDropField
              label={"Upload Installation File"}
              fontBolded
              name={"installation_file"}
              hiddenDropzone
              btnLabel={"Upload Installation File"}
              onFileChange={this.handleInstallationFile}
              onFileDelete={this.handleDeleteInstallationFile}
              data={this.state.installationFiles}
            />
          </div>

          <SaveButton
            id={id}
            type="submit"
            className={["btn", "btn-primary"].join(" ")}
          >
            Save
          </SaveButton>
        </Form>
      </div>
    );
  }
}
