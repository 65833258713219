import React, { Component } from "react";
import { Media } from "reactstrap";
import Editor from "draft-js-plugins-editor";
import createEmojiPlugin from "draft-js-emoji-plugin";
import createMentionPlugin, {
  defaultSuggestionsFilter
} from "draft-js-mention-plugin";
import {
  convertToRaw,
  EditorState,
  convertFromRaw,
  ContentState
} from "draft-js";
import "draft-js/dist/Draft.css";
import "draft-js-emoji-plugin/lib/plugin.css";
import moment from "moment";

// Library
import { getFullName, isExpert } from "../../lib/helper";

// Assets
import { AttachIcon, GifIcon } from "../../assets/img/icons";

// Components
import Avatar from "../../components/base/Avatar";
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";
import FilePreview from "../../components/base/FilePreview";
import { DISCUSSION_PLUGIN_TYPE_MENTION } from "../../discussion/Constants";
import { PROJECT_USER_STATUS_ACCEPTED } from "../../project/Constants";
import { isBadRequest } from "../../common/http";

import { connect } from "react-redux";

const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

// Discussion person entry
const DiscussionPersonEntry = props => {
  const { mention, ...parentProps } = props;
  return (
    <div {...parentProps}>
      <div className="mention-suggestions-entry-container">
        <div className="mention-suggestions-entry-container-left">
          <Avatar
            firstName={mention.firstName}
            lastName={mention.lastName}
            size="xs"
            fontSize={12}
            url={mention.picture}
          />
        </div>

        <div className="mention-suggestions-entry-container-right">
          <div className="mention-suggestions-entry-text">
            {mention.name}
            <span className="mention-suggestions-entry-email">
              {mention.email}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

const UserComment = props => {
  const { projectDiscussion, handleProjectDiscussionDelete } = props;
  const linkStyle = {
    color: "rgba(0, 0, 0, 0.25)"
  };

  const {
    id,
    name,
    firstName,
    lastName,
    comment,
    avatarUrl,
    isEditable,
    formattedCreatedAt,
    discussionMaterial
  } = projectDiscussion;

  // Get discussion date
  const getDiscussionDate = () => {
    const todayDate = moment(new Date()).format("DD MMM, YYYY");
    // const discussionTime = moment(projectDiscussion.createdAt).format("h:mm A");
    const discussionTime =
      projectDiscussion && projectDiscussion.createdAt
        ? moment(projectDiscussion.createdAt).format("h:mm A")
        : "";

    const prevDate =
      projectDiscussion && projectDiscussion.createdAt
        ? moment(projectDiscussion.createdAt).format("DD MMM, YYYY")
        : "";
    let discussionDate = moment(formattedCreatedAt).format("DD MMM, YYYY");

    discussionDate =
      todayDate === discussionDate && todayDate === prevDate
        ? `Today at ${discussionTime}`
        : `${prevDate} ${discussionTime}`;

    return discussionDate;
  };

  return (
    <Media className="user-rating">
      <div className="field-icon mr-3">
        <Avatar
          firstName={firstName}
          lastName={lastName}
          size="xs"
          fontSize={12}
          url={avatarUrl}
        />
      </div>
      <Media body>
        <div className="rating-and-usage">
          <div className="rating-wrapper d-flex align-items-center">
            <span className="h7">
              <b>{name ? name : ""}</b>
            </span>
            {isEditable ? (
              <div className="discussion-actions d-inline-flex">
                <a
                  className={[
                    "link-hover",
                    "text-inline-grayed",
                    "mr-2",
                    "cursor-pointer"
                  ].join(" ")}
                  onClick={() => handleProjectDiscussionDelete(id)}
                >
                  Delete
                </a>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
        <Editor
          editorState={EditorState.createWithContent(
            convertFromRaw(JSON.parse(comment))
          )}
          readOnly={true}
          onChange={() => "1"}
        />
        {discussionMaterial && (
          <FilePreview
            key={discussionMaterial.id}
            img={""}
            preview={discussionMaterial.fileUrl}
            name={discussionMaterial.name}
            file={discussionMaterial}
            isPreview={false}
            linkStyle={linkStyle}
          />
        )}
        {formattedCreatedAt && (
          <p className={["text-inline-grayed", "h8"].join(" ")}>
            {getDiscussionDate()}
          </p>
        )}
      </Media>
    </Media>
  );
};

class Discussion extends Component {
  constructor() {
    super();

    this.state = {
      editorState: EditorState.createEmpty(),
      isProjectDiscussionLoading: false,
      projectDiscussions: [],
      material: "",
      materialName: "",
      suggestions: []
    };

    this.handleProjectDiscussionDelete = this.handleProjectDiscussionDelete.bind(
      this
    );

    this.discussionMentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      mentionPrefix: "@",
      supportWhitespace: true
    });
  }

  componentDidMount() {
    this.getProjectDiscussionList(true);
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps &&
      prevProps.partnerId &&
      this.props.partnerId &&
      prevProps.partnerId !== this.props.partnerId
    )
      this.getProjectDiscussionList(true);
  }

  onChange = editorState => {
    this.setState({
      editorState
    });
  };

  focus = () => {
    this.editor.focus();
  };

  handleReturn(evt, editorState) {
    if (evt.shiftKey) {
      return "not-handled";
    }

    if (
      !editorState
        .getCurrentContent()
        .getPlainText()
        .trim()
    ) {
      return "handled";
    }
    const rawComment = convertToRaw(editorState.getCurrentContent());

    const emptyEditorState = EditorState.moveFocusToEnd(
      EditorState.push(this.state.editorState, ContentState.createFromText(""))
    );

    this.setState({ editorState: emptyEditorState });

    const mentionIds = [];
    const message = JSON.parse(JSON.stringify(rawComment));

    if (Object.keys(message.entityMap).length) {
      for (let key in message.entityMap) {
        const isMention =
          message.entityMap[key].type === DISCUSSION_PLUGIN_TYPE_MENTION;
        if (isMention) {
          mentionIds.push({ id: message.entityMap[key].data.mention.id });
        }
      }
    }

    const partnerId = this.props.partnerId;

    const data = new FormData();
    data.append("message", JSON.stringify(rawComment));
    data.append("projectId", this.props.projectId);
    data.append("mentionIds", JSON.stringify(mentionIds));
    data.append("file", this.state.material);

    if (partnerId) {
      data.append("partnerId", partnerId);
    }

    // Clear material file from state
    this.setState({ material: "", materialPreview: "" });

    apiClient
      .post(endpoints().projectDiscussionAPI, data)
      .then(() => {
        this.setState({
          editorState: EditorState.createEmpty()
        });

        this.getProjectDiscussionList(true);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });

    return "handled";
  }

  // Project discussion delete api call
  handleProjectDiscussionDelete = projectDiscussionId => {
    return apiClient
      .delete(`${endpoints().projectDiscussionAPI}/${projectDiscussionId}`)
      .then(() => {
        this.getProjectDiscussionList(true);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Project discussion api list
  getProjectDiscussionList = isProjectDiscussion => {
    const projectId = this.props.projectId;
    const partnerId = this.props.partnerId;
    this.setState({ isProjectDiscussionLoading: isProjectDiscussion }, () => {
      apiClient
        .get(
          `${endpoints().projectDiscussionAPI}/?projectId=${projectId}${
            partnerId ? "&partnerId=" + partnerId : ""
          }`
        )
        .then(response => {
          this.setState({
            projectDiscussions: response.data.data,
            isProjectDiscussionLoading: !isProjectDiscussion
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  };

  // Handle material
  handleMaterialChange = e => {
    const file = e.target.files[0];

    if (file && file.name) {
      this.setState({
        material: file,
        materialName: file.name,
        materialPreview: URL.createObjectURL(file)
      });
    }
  };

  onSearchChange = ({ value }) => {
    const suggestions = [];
    const projectTeam = this.props.projectTeam;
    if (projectTeam && projectTeam.length > 0) {
      projectTeam.forEach(team => {
        suggestions.push({
          id: team.id,
          firstName: team.firstName,
          lastName: team.lastName,
          email: team.email,
          name: getFullName(team.firstName, team.lastName),
          picture: team.avatarUrl
        });
      });
    }

    this.setState({
      suggestions: defaultSuggestionsFilter(value, suggestions)
    });
  };

  render() {
    const {
      editorState,
      projectDiscussions,
      material,
      materialPreview
    } = this.state;

    const { projectUserStatus } = this.props;

    const { MentionSuggestions } = this.discussionMentionPlugin;
    const plugins = [this.discussionMentionPlugin, emojiPlugin];

    return (
      <div className="discussions">
        <div className="title-wrapper">
          <h6>Discussion</h6>
        </div>
        <div
          className={[
            "discussions-body",
            `${
              isExpert(this.props.roleId) &&
              projectUserStatus &&
              projectUserStatus !== PROJECT_USER_STATUS_ACCEPTED
                ? "disabled"
                : ""
            }`
          ].join(" ")}
        >
          <div className="form-wrapper discussion-input-wrapper">
            <div className="field-wrapper position-relative">
              <div className="discussion-editor-edit">
                <div
                  className={`editor message-card ${materialPreview && "mb-0"}`}
                  onClick={this.focus}
                >
                  <Editor
                    editorState={editorState}
                    onChange={this.onChange}
                    plugins={plugins}
                    placeholder="Your message..."
                    ref={element => {
                      this.editor = element;
                    }}
                    handleReturn={this.handleReturn.bind(this)}
                  />
                  <MentionSuggestions
                    onSearchChange={this.onSearchChange}
                    suggestions={this.state.suggestions}
                    entryComponent={DiscussionPersonEntry}
                  />
                  <EmojiSuggestions />
                </div>
                {material && (
                  <FilePreview
                    key={material.path}
                    img={""}
                    preview={materialPreview}
                    name={material.name}
                    file={material}
                    showUploadButton={false}
                    isPreview
                  />
                )}
              </div>
              <div className="discussion-actions">
                <input
                  name="attachment"
                  className="form-control d-none"
                  type="file"
                  id="attachment"
                  onChange={this.handleMaterialChange}
                  accept=".docx, .doc, application/*, image/*, text/*"
                />
                <label
                  htmlFor="attachment"
                  className={[
                    "discussion-action cursor-pointer pl-5 ml-2 my-1"
                  ].join(" ")}
                >
                  <AttachIcon />
                </label>

                <div className={["discussion-action"].join(" ")}>
                  <EmojiSelect />
                </div>
                <div className={["discussion-action"].join(" ")}>
                  <GifIcon />
                </div>
              </div>
            </div>
          </div>

          <div className="discussion-list">
            {projectDiscussions &&
              projectDiscussions.map((projectDiscussion, index) => (
                <UserComment
                  projectDiscussion={projectDiscussion}
                  handleProjectDiscussionDelete={
                    this.handleProjectDiscussionDelete
                  }
                  key={index}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(Discussion);
