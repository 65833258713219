import React from "react";
import UpdateTagForm from "./UpdateTagForm";

const TagDetails = props => {
  return (
    <div>
      <UpdateTagForm history={props.history} match={props.match} />
    </div>
  );
};

export default TagDetails;
