// Country Start
export const UNITED_STATES = "United States";
export const CANADA = "Canada";
export const MEXICO = "Mexico";
export const GUATEMALA = "Guatemala";
export const CUBA = "Cuba";
export const HAITI = "Haiti";
export const DOMINICAN_REPUBLIC = "Dominican Republic";
export const HONDURAS = "Honduras";
export const FRANCE = "France";
export const SPAIN = "Spain";
export const ITALY = "Italy";
export const UNITED_KINGDOM = "United Kingdom";
export const GERMANY = "Germany";
export const AUSTRIA = "Austria";
export const GREECE = "Greece";
export const RUSSIA = "Russia";
export const PORTUGAL = "Portugal";
export const TAHITI = "Tahiti";
export const SYDNEY = "Sydney";
export const CHINA = "China";
export const INDIA = "India";
export const SOUTH_KOREA = "South Korea";
export const AUSTRALIA = "Australia";
export const INDONESIA = "Indonesia";
export const TAIWAN = "Taiwan";
export const HONG_KONG = "Hong Kong";
export const MALAYSIA = "Malaysia";
export const SINGAPORE = "Singapore";
export const PHILIPPINES = "Philippines";
export const BANGLADESH = "Bangladesh";
export const PAKISTAN = "Pakistan";
export const VIETNAM = "Vietnam";
export const NEW_ZEALAND = "New Zealand";
export const SRI_LANKA = "Sri Lanka";
export const BRUNEI = "Brunei";
export const QATAR = "Qatar";
export const ARMENIA = "Armenia";
export const MONGOLIA = "Mongolia";
export const GEORGIA = "Georgia";
export const OMAN = "Oman";
export const LAOS = "Laos";
export const Israel = "Israel";
export const TAJIKISTAN = "Tajikistan";
export const UNITED_ARAB_EMIRATES = "United Arab Emirates";
export const JORDAN = "Jordan";
export const CAMBODIA = "Cambodia";
export const SYRIA = "Syria";
export const NORTH_KOREA = "North Korea";
export const YEMEN = "Yemen";
export const KUWAIT = "Kuwait";
export const BRAZIL = "Brazil";
export const COLOMBIA = "Colombia";
export const ARGENTINA = "Argentina";
export const PERU = "Peru";
export const VENEZUELA = "Venezuela";
export const ECUADOR = "Ecuador";
export const BOLIVIA = "Bolivia";
export const ALGERIA = "Algeria";
export const BAHRAIN = "Bahrain";
export const DJIBOUTI = "Djibouti";
export const EGYPT = "Egypt";
export const IRAN = "Iran";
export const IRAQ = "Iraq";
export const LEBANON = "Lebanon";
export const LIBYA = "Libya";
export const MALTA = "Malta";
export const MOROCCO = "Morocco";
export const SAUDI_ARABIA = "Saudi Arabia";
export const TUNISIA = "Tunisia";
export const PALESTINE = "Palestine";
// Country Ends
export const COUNTRY_OPTIONS = [
  {
    value: "United States",
    label: "United States"
  }
];
