import React, { useState } from "react";
import DatePicker from "react-datepicker";

// Styles
import "react-datepicker/dist/react-datepicker.css";

const OrderExtras = props => {
  const [startDate] = useState(new Date());
  let { onChange, requestedStartDate } = props;
  //set current date on initial load
  requestedStartDate = requestedStartDate ? requestedStartDate : startDate;

  const currentday = new Date();
  return (
    <div className="cart-item">
      <h5 className="mb-3">
        <b>Start Date Requested:</b>
      </h5>
      <span className="subtitle text-grayed h6-5 d-block mb-2">
        When do you want to get started?
      </span>
      <DatePicker
        id="date"
        name="date"
        className="datepicker"
        selected={requestedStartDate}
        placeholderText="MM/DD/YYYY"
        onChange={onChange}
        minDate={currentday}
      />
    </div>
  );
};

export default OrderExtras;
