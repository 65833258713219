import React from "react";
import toast from "../../components/base/Toast";

// Page components
import ProjectCategoryFormFields from "./ProjectCategoryFormFields";

// Components
import Form from "../../components/base/Form";
import SaveButton from "../../components/base/SaveButton";
import CancelButton from "../../components/base/CancelButton";

// Configs
import { endpoints } from "../../configs";

// Helper
import { toString } from "../../lib/helper";

// API
import { apiClient } from "../../apiClient";
import { isBadRequest } from "../../common/http";

export default class AddProjectCategoryForm extends React.Component {
  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save skill form in the final
    this._createprojectCategory(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const name = values.name;

    if (!name) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.name = toString(values.name);
    return values;
  }

  // Project API Call
  _createprojectCategory(data) {
    return apiClient
      .post(endpoints().projectCategoryAPI, data)
      .then(() => {
        window.location.replace("/admin/settings/project/categories");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    const initialValues = {
      name: ""
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          this._submit(values);
        }}
      >
        <ProjectCategoryFormFields />

        <SaveButton />
        <CancelButton onClick={() => this.props.history.goBack()} />
      </Form>
    );
  }
}
