import React, { useState, useRef, useEffect } from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import Hint from "../../../../components/base/Hint";
import Text from "../../../../components/base/Text";
import TextArea from "../../../../components/base/TextArea";
import URL from "../../../../components/base/URL";
import SaveButton from "../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_TERMS_OF_SERVICE_URL,
  SETTINGS_LEGAL_DISCLOSURE,
  SETTINGS_PORTAL_HEADER_COLOR,
  SETTINGS_PORTAL_HEADER_TEXT_COLOR,
  SETTINGS_PORTAL_FOOTER_COLOR,
  SETTINGS_PORTAL_FOOTER_HEADING_COLOR,
  SETTINGS_PORTAL_FOOTER_TEXT_COLOR,
  SETTINGS_PORTAL_FOOTER_COPY_RIGHTS_TEXT,
  SETTINGS_PORTAL_DESKTOP_FOOTER_CONTENT,
  SETTINGS_PORTAL_MOBILE_FOOTER_CONTENT,
  SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_COLOR,
  SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_HOVER_OVER_COLOR,
  SETTINGS_PORTAL_LOGO,
  SETTINGS_PORTAL_FAV_ICON,
  BACKGROUND_IMAGE,
  SETTINGS_PARTNER_SIGNUP_HERO_IMAGE,
  SETTINGS_EXPERT_SIGNUP_HERO_IMAGE,
  SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_IMAGE,
  SETTINGS_CUSTOMER_SIGNUP_HERO_IMAGE,
  SETTINGS_ENABLE_EXPERTS,
  SETTINGS_ENABLE_PARTNERS,
  SETTINGS_ENABLE_CUSTOMERS,
  SETTINGS_THEME_LAYOUT,
  THEME_LAYOUT_FLUID_WIDTH,
  THEME_LAYOUT_FIXED_WIDTH,
  SETTINGS_PORTAL_PUBLIC_PAGE_FOOTER_CONTENT
} from "../../../../setting/Constants";

// Helper
import {
  getSettingMediaUrl,
  getKeyValueByObject
} from "../../../../lib/helper";
import { isTrue } from "../../../../common/utils";
import Select from "../../../../components/base/Select";

const Theme = props => {
  const { adminSettings, saveSettings, settings } = props;

  const {
    marketplace_logo_redirect_url,
    marketplace_header_color,
    marketplace_header_text_color,
    marketplace_left_navigation_text_color,
    marketplace_left_navigation_text_hover_over_color,
    legal_disclosure,
    marketplace_footer_color,
    marketplace_footer_heading_color,
    marketplace_footer_text_color,
    marketplace_footer_copy_rights_text,
    terms_of_service_url,
    marketplace_desktop_footer_content,
    marketplace_mobile_footer_content,
    marketplace_logo,
    marketplace_logo_url,
    marketplace_fav_icon,
    marketplace_fav_icon_url,
    background_image,
    background_image_url,
    expert_signup_hero_image,
    expert_signup_hero_image_url,
    customer_signup_hero_image,
    customer_signup_hero_image_url,
    partner_signup_hero_image,
    partner_signup_hero_image_url,
    marketplace_left_navigation_background_image,
    marketplace_left_navigation_background_image_url,
    theme_layout,
    public_page_footer_content
  } = adminSettings;

  const {
    marketplaceFavIconUpload,
    handleMarketplaceFavIconRemove,
    backgroundImageUpload,
    handleBackgroundImageRemove,
    expertSignupHeroImageUpload,
    handleExpertSignupHeroImageRemove,
    customerSignupHeroImageUpload,
    handleCustomerSignupHeroImageRemove,
    partnerSignupHeroImageUpload,
    handlePartnerSignupHeroImageRemove,
    leftNavigationBackgroundImageUpload,
    handleLeftNavigationBackgroundImageRemove,
    submitThemeTab
  } = saveSettings;

  const enableExperts = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS)
    : "";
  const enableCustomers = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_CUSTOMERS)
    : "";
  const enablePartners = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS)
    : "";
  // initilize states
  const [favIconFileTypeError, setFavIconFileTypeError] = useState(false);
  const [partnerSignupFileTypeError, setPartnerSignupFileTypeError] = useState(
    false
  );
  const [
    customerSignupFileTypeError,
    setcustomerSignupFileTypeError
  ] = useState(false);
  const [
    navBackgroundFileTypeError,
    setNavigationBackgroundFileTypeError
  ] = useState(false);
  const [
    BackgroundImageFileTypeError,
    setBackgroundImageFileTypeError
  ] = useState(false);
  const [expertSignupFileTypeError, setExpertSignupFileTypeError] = useState(
    false
  );
  const themeLayoutOptions = [
    {
      value: THEME_LAYOUT_FLUID_WIDTH,

      label: THEME_LAYOUT_FLUID_WIDTH
    },

    {
      value: THEME_LAYOUT_FIXED_WIDTH,

      label: THEME_LAYOUT_FIXED_WIDTH
    }
  ];

  let defaultLayout;

  if (theme_layout) {
    defaultLayout = { value: theme_layout, label: theme_layout };
  }
  const marketplaceLogoRef = useRef();
  const marketplaceFavIconRef = useRef();
  const leftNavigationBackgroundImageRef = useRef();
  const backgroundImageRef = useRef();
  const expertSignupHeroImageRef = useRef();
  const customerSignupHeroImageRef = useRef();
  const partnerSignupHeroImageRef = useRef();

  // Theme Settings General Section Initial Values
  const themeSettingsGeneralSectionInitialValues = {
    marketplace_logo_redirect_url,
    marketplace_header_color,
    marketplace_header_text_color,
    marketplace_left_navigation_text_color,
    marketplace_left_navigation_text_hover_over_color,
    legal_disclosure,
    marketplace_footer_color,
    marketplace_footer_heading_color,
    marketplace_footer_text_color,
    marketplace_footer_copy_rights_text,
    terms_of_service_url,
    marketplace_desktop_footer_content,
    marketplace_mobile_footer_content,
    public_page_footer_content
  };

  // Validate Filetype
  const validateFile = (e, File) => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          (File === SETTINGS_PORTAL_FAV_ICON &&
            setFavIconFileTypeError(false)) ||
            (File === BACKGROUND_IMAGE &&
              setBackgroundImageFileTypeError(false)) ||
            (File === SETTINGS_PARTNER_SIGNUP_HERO_IMAGE &&
              setPartnerSignupFileTypeError(false)) ||
            (File === SETTINGS_EXPERT_SIGNUP_HERO_IMAGE &&
              setExpertSignupFileTypeError(false)) ||
            (File === SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_IMAGE &&
              setNavigationBackgroundFileTypeError(false)) ||
            (File === SETTINGS_CUSTOMER_SIGNUP_HERO_IMAGE &&
              setcustomerSignupFileTypeError(false));
          break;
        default:
          (File === SETTINGS_PORTAL_FAV_ICON &&
            setFavIconFileTypeError(true)) ||
            (File === BACKGROUND_IMAGE &&
              setBackgroundImageFileTypeError(true)) ||
            (File === SETTINGS_PARTNER_SIGNUP_HERO_IMAGE &&
              setPartnerSignupFileTypeError(true)) ||
            (File === SETTINGS_EXPERT_SIGNUP_HERO_IMAGE &&
              setExpertSignupFileTypeError(true)) ||
            (File === SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_IMAGE &&
              setNavigationBackgroundFileTypeError(true)) ||
            (File === SETTINGS_CUSTOMER_SIGNUP_HERO_IMAGE &&
              setcustomerSignupFileTypeError(true));
          break;
      }
    }
  };

  // Background image upload
  const onBackgroundImageChange = e => {
    validateFile(e, BACKGROUND_IMAGE);
    if (!BackgroundImageFileTypeError) {
      backgroundImageUpload(e);
    }
  };
  // Background image remove
  const onBackgroundImageRemove = () => {
    setBackgroundImageFileTypeError(false);
    backgroundImageRef.current.value = "";
    handleBackgroundImageRemove();
  };

  // Portal favicon image upload
  const onMarketplaceFavIconChange = e => {
    validateFile(e, SETTINGS_PORTAL_FAV_ICON);
    if (!favIconFileTypeError) {
      marketplaceFavIconUpload(e);
    }
  };
  // Portal favicon image remove
  const onMarketplaceFavIconRemove = () => {
    setFavIconFileTypeError(false);
    marketplaceFavIconRef.current.value = "";
    handleMarketplaceFavIconRemove();
  };

  // Expert signup hero image upload
  const onExpertSignupHeroImageChange = e => {
    validateFile(e, SETTINGS_EXPERT_SIGNUP_HERO_IMAGE);
    if (!expertSignupFileTypeError) {
      expertSignupHeroImageUpload(e);
    }
  };
  // Expert signup hero image remove
  const onExpertSignupHeroImageRemove = () => {
    setExpertSignupFileTypeError(false);
    expertSignupHeroImageRef.current.value = "";
    handleExpertSignupHeroImageRemove();
  };

  // Customer signup hero image upload
  const onCustomerSignupHeroImageChange = e => {
    validateFile(e, SETTINGS_CUSTOMER_SIGNUP_HERO_IMAGE);
    if (!customerSignupFileTypeError) {
      customerSignupHeroImageUpload(e);
    }
  };
  // Customer signup hero image remove
  const onCustomerSignupHeroImageRemove = () => {
    setcustomerSignupFileTypeError(false);
    customerSignupHeroImageRef.current.value = "";
    handleCustomerSignupHeroImageRemove();
  };

  // Partner signup hero image upload
  const onPartnerSignupHeroImageChange = e => {
    validateFile(e, SETTINGS_PARTNER_SIGNUP_HERO_IMAGE);
    if (!partnerSignupFileTypeError) {
      partnerSignupHeroImageUpload(e);
    }
  };
  // Partner signup hero image remove
  const onPartnerSignupHeroImageRemove = () => {
    setPartnerSignupFileTypeError(false);
    partnerSignupHeroImageRef.current.value = "";
    handlePartnerSignupHeroImageRemove();
  };

  // Left Navigtion Background image upload
  const onLeftNavigationBackgroundImageChange = e => {
    validateFile(e, SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_IMAGE);
    if (!navBackgroundFileTypeError) {
      leftNavigationBackgroundImageUpload(e);
    }
  };
  // Left Navigtion Background image remove
  const onLeftNavigationBackgroundImageRemove = () => {
    setNavigationBackgroundFileTypeError(false);
    leftNavigationBackgroundImageRef.current.value = "";
    handleLeftNavigationBackgroundImageRemove();
  };

  // Portal logo s3 url
  const marketplaceLogoWithBaseUrl = marketplace_logo
    ? marketplace_logo
    : marketplace_logo_url && settings
    ? getSettingMediaUrl(settings, marketplace_logo_url)
    : "";

  // Fav Icon s3 url
  const faviconWithBaseUrl = marketplace_fav_icon
    ? marketplace_fav_icon
    : marketplace_fav_icon_url && settings
    ? getSettingMediaUrl(settings, marketplace_fav_icon_url)
    : "";

  // Background Image s3 url
  const backgroundImageWithBaseUrl = background_image
    ? background_image
    : background_image_url && settings
    ? getSettingMediaUrl(settings, background_image_url)
    : "";

  // Expert signup hero image s3 url
  const ExpertSignupHeroImageWithBaseUrl = expert_signup_hero_image
    ? expert_signup_hero_image
    : expert_signup_hero_image_url && settings
    ? getSettingMediaUrl(settings, expert_signup_hero_image_url)
    : "";

  // Customer signup hero image s3 url
  const CustomerSignupHeroImageWithBaseUrl = customer_signup_hero_image
    ? customer_signup_hero_image
    : customer_signup_hero_image_url && settings
    ? getSettingMediaUrl(settings, customer_signup_hero_image_url)
    : "";

  // Partner signup hero image s3 url
  const PartnerSignupHeroImageWithBaseUrl = partner_signup_hero_image
    ? partner_signup_hero_image
    : partner_signup_hero_image_url && settings
    ? getSettingMediaUrl(settings, partner_signup_hero_image_url)
    : "";

  // Left Navigation Background Image s3 url
  const LeftNavigationBackgroundImageWithBaseUrl = marketplace_left_navigation_background_image
    ? marketplace_left_navigation_background_image
    : marketplace_left_navigation_background_image_url && settings
    ? getSettingMediaUrl(
        settings,
        marketplace_left_navigation_background_image_url
      )
    : "";

  return (
    <Form
      initialValues={themeSettingsGeneralSectionInitialValues}
      enableReinitialize
      onSubmit={values => {
        submitThemeTab(values);
      }}
    >
      <div className="form-wrapper">
        <DefaultContent>
          {/* Portal Favicon upload */}
          <h5 className="font-weight-bold">Portal Favicon</h5>
          <div className="field-wrapper d-flex">
            <div>
              <div className="upload-field d-inline-block">
                <input
                  name="file"
                  className="form-control d-none"
                  type="file"
                  id="marketplaceFavIcon"
                  placeholder="Favicon"
                  onChange={e => {
                    onMarketplaceFavIconChange(e);
                  }}
                  accept="image/png,image/gif,image/jpeg"
                  ref={marketplaceFavIconRef}
                />
                <span className="profilePicOverlay d-block ">
                  <label
                    htmlFor="marketplaceFavIcon"
                    className="profile-img-sm mb-0"
                  >
                    <span className="banner-image-upload-link">
                      Upload Portal Favicon
                    </span>
                  </label>
                </span>
              </div>
              {/* Remove image */}
              {marketplace_fav_icon_url && (
                <span
                  className="banner-image-upload-link text-danger ml-md-1"
                  onClick={onMarketplaceFavIconRemove}
                >
                  (Remove)
                </span>
              )}
              <div className="field-wrapper">
                <small
                  className={`text-danger mt-3 ${
                    favIconFileTypeError ? "d-block" : "d-none"
                  }`}
                >
                  Please select a image file
                </small>
                <Hint
                  id="bannerRequirements"
                  hintText="We recommended using 32 x 32 image"
                />
              </div>
            </div>
            <div className="ml-auto">
              <img src={faviconWithBaseUrl} style={{ width: "32px" }} />
            </div>
          </div>
          {/* Portal Favicon upload Ends */}
          {/* Background image upload */}
          <h5 className="font-weight-bold">Login Page Hero Image</h5>
          <div className="field-wrapper d-flex">
            <div>
              <div className="upload-field d-inline-block">
                <input
                  name="file"
                  className="form-control d-none"
                  type="file"
                  id="backgroundImage"
                  placeholder="Background Image"
                  onChange={e => {
                    onBackgroundImageChange(e);
                  }}
                  accept="image/png,image/gif,image/jpeg"
                  ref={backgroundImageRef}
                />
                <span className="profilePicOverlay d-block ">
                  <label
                    htmlFor="backgroundImage"
                    className="profile-img-sm mb-0"
                  >
                    <span className="banner-image-upload-link">
                      Upload Background Image
                    </span>
                  </label>
                </span>
              </div>
              {/* Remove image */}
              {background_image_url && (
                <span
                  className="banner-image-upload-link text-danger ml-md-1"
                  onClick={onBackgroundImageRemove}
                >
                  (Remove)
                </span>
              )}
              <div className="field-wrapper">
                <small
                  className={`text-danger mt-3 ${
                    BackgroundImageFileTypeError ? "d-block" : "d-none"
                  }`}
                >
                  Please select a image file
                </small>
                <Hint
                  id="backgroundImage"
                  hintText="We recommended using 400 x 600 image"
                />
              </div>
            </div>
            <div className="ml-auto">
              <img
                src={backgroundImageWithBaseUrl}
                style={{ width: "100px" }}
              />
            </div>
          </div>
          {/* Background image upload ends */}
          {/* Expert signup page logo upload */}
          {isTrue(enableExperts) && (
            <>
              <h5 className="font-weight-bold">
                Expert Signup Page Hero Image
              </h5>
              <div className="field-wrapper d-flex">
                <div>
                  <div className="upload-field d-inline-block">
                    <input
                      name="file"
                      className="form-control d-none"
                      type="file"
                      id="expertSignupHeroImage"
                      placeholder="Expert Signup Page Image"
                      onChange={e => {
                        onExpertSignupHeroImageChange(e);
                      }}
                      accept="image/png,image/gif,image/jpeg"
                      ref={expertSignupHeroImageRef}
                    />
                    <span className="profilePicOverlay d-block ">
                      <label
                        htmlFor="expertSignupHeroImage"
                        className="profile-img-sm mb-0"
                      >
                        <span className="banner-image-upload-link">
                          Upload Expert Signup Page Hero Image
                        </span>
                      </label>
                    </span>
                  </div>
                  {/* Remove image */}
                  {expert_signup_hero_image_url && (
                    <span
                      className="banner-image-upload-link text-danger ml-md-1"
                      onClick={onExpertSignupHeroImageRemove}
                    >
                      (Remove)
                    </span>
                  )}
                  <div className="field-wrapper">
                    <small
                      className={`text-danger mt-3 ${
                        expertSignupFileTypeError ? "d-block" : "d-none"
                      }`}
                    >
                      Please select a image file
                    </small>
                    <Hint
                      id="bannerRequirements"
                      hintText="We recommended using 400 x 600 image"
                    />
                  </div>
                </div>
                <div className="ml-auto">
                  <img
                    src={ExpertSignupHeroImageWithBaseUrl}
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
            </>
          )}

          {/* Expert signup page logo upload Ends */}
          {/* Customer signup page logo upload */}
          {isTrue(enableCustomers) && (
            <>
              <h5 className="font-weight-bold">
                Customer Signup Page Hero Image
              </h5>
              <div className="field-wrapper d-flex">
                <div>
                  <div className="upload-field d-inline-block">
                    <input
                      name="file"
                      className="form-control d-none"
                      type="file"
                      id="customerSignupHeroImage"
                      placeholder="Customer Signup Page Image"
                      onChange={e => {
                        onCustomerSignupHeroImageChange(e);
                      }}
                      accept="image/png,image/gif,image/jpeg"
                      ref={customerSignupHeroImageRef}
                    />
                    <span className="profilePicOverlay d-block ">
                      <label
                        htmlFor="customerSignupHeroImage"
                        className="profile-img-sm mb-0"
                      >
                        <span className="banner-image-upload-link">
                          Upload Customer Signup Page Hero Image
                        </span>
                      </label>
                    </span>
                  </div>
                  {/* Remove image */}
                  {customer_signup_hero_image_url && (
                    <span
                      className="banner-image-upload-link text-danger ml-md-1"
                      onClick={onCustomerSignupHeroImageRemove}
                    >
                      (Remove)
                    </span>
                  )}
                  <div className="field-wrapper">
                    <small
                      className={`text-danger mt-3 ${
                        customerSignupFileTypeError ? "d-block" : "d-none"
                      }`}
                    >
                      Please select a image file
                    </small>
                    <Hint
                      id="bannerRequirements"
                      hintText="We recommended using 400 x 600 image"
                    />
                  </div>
                </div>
                <div className="ml-auto">
                  <img
                    src={CustomerSignupHeroImageWithBaseUrl}
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
            </>
          )}
          {/* Customer signup page logo upload Ends */}
          {/* Partner signup page logo upload */}
          {isTrue(enablePartners) && (
            <>
              <h5 className="font-weight-bold">
                Partner Signup Page Hero Image
              </h5>
              <div className="field-wrapper d-flex">
                <div>
                  <div className="upload-field d-inline-block">
                    <input
                      name="file"
                      className="form-control d-none"
                      type="file"
                      id="partnerSignupHeroImage"
                      placeholder="Partner Signup Page Image"
                      onChange={e => {
                        onPartnerSignupHeroImageChange(e);
                      }}
                      accept="image/png,image/gif,image/jpeg"
                      ref={partnerSignupHeroImageRef}
                    />
                    <span className="profilePicOverlay d-block ">
                      <label
                        htmlFor="partnerSignupHeroImage"
                        className="profile-img-sm mb-0"
                      >
                        <span className="banner-image-upload-link">
                          Upload Partner Signup Page Hero Image
                        </span>
                      </label>
                    </span>
                  </div>
                  {/* Remove image */}
                  {partner_signup_hero_image_url && (
                    <span
                      className="banner-image-upload-link text-danger ml-md-1"
                      onClick={onPartnerSignupHeroImageRemove}
                    >
                      (Remove)
                    </span>
                  )}
                  <div className="field-wrapper">
                    <small
                      className={`text-danger mt-3 ${
                        partnerSignupFileTypeError ? "d-block" : "d-none"
                      }`}
                    >
                      Please select a image file
                    </small>
                    <Hint
                      id="bannerRequirements"
                      hintText="We recommended using 400 x 600 image"
                    />
                  </div>
                </div>
                <div className="ml-auto">
                  <img
                    src={PartnerSignupHeroImageWithBaseUrl}
                    style={{ width: "100px" }}
                  />
                </div>
              </div>
            </>
          )}
          {/* Partner signup page logo upload Ends */}
        </DefaultContent>
        <DefaultContent>
          <h4 className="font-weight-bold">Layout</h4>

          {/* Layout */}

          {/* <h5 className="font-weight-bold">Color</h5> */}

          <div className="field-wrapper">
            <Select
              name={SETTINGS_THEME_LAYOUT}
              placeholder="Select Layout"
              options={themeLayoutOptions}
              defaultValue={defaultLayout}
              isSearchable={true}
            />
          </div>
        </DefaultContent>
        <DefaultContent>
          <h4 className="font-weight-bold">Header</h4>
          {/* Header color */}
          <h5 className="font-weight-bold">Color</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_HEADER_COLOR}
              placeholder="Enter Header Color code"
            />
          </div>

          {/* Header Text color*/}
          <h5 className="font-weight-bold">Text Color</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_HEADER_TEXT_COLOR}
              placeholder="Enter Header Text Color code"
            />
          </div>
        </DefaultContent>

        <DefaultContent>
          <h4 className="font-weight-bold">Left Navigation</h4>
          {/* Left Navigation Text color*/}
          <h5 className="font-weight-bold">Text Color</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_COLOR}
              placeholder="Enter Left Navigation Text Color code"
            />
          </div>

          {/* Left Navigation Text color*/}
          <h5 className="font-weight-bold">Text Hover Over Color</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_HOVER_OVER_COLOR}
              placeholder="Enter Left NavigationText Hover Over Color code"
            />
          </div>

          {/* Left Navigation Background Image upload */}
          <h5 className="font-weight-bold">Background Image</h5>
          <div className="field-wrapper d-flex">
            <div>
              <div className="upload-field d-inline-block">
                <input
                  name="file"
                  className="form-control d-none"
                  type="file"
                  id="leftNavigationBackgroundImage"
                  placeholder="Background Image"
                  onChange={e => {
                    onLeftNavigationBackgroundImageChange(e);
                  }}
                  accept="image/png,image/gif,image/jpeg"
                  ref={leftNavigationBackgroundImageRef}
                />
                <span className="profilePicOverlay d-block ">
                  <label
                    htmlFor="leftNavigationBackgroundImage"
                    className="profile-img-sm mb-0"
                  >
                    <span className="banner-image-upload-link">
                      Upload Background Image
                    </span>
                  </label>
                </span>
              </div>

              {/* Remove image */}
              {marketplace_left_navigation_background_image_url && (
                <span
                  className="banner-image-upload-link text-danger ml-md-1"
                  onClick={onLeftNavigationBackgroundImageRemove}
                >
                  (Remove)
                </span>
              )}
              <div className="field-wrapper">
                <small
                  className={`text-danger mt-3 ${
                    navBackgroundFileTypeError ? "d-block" : "d-none"
                  }`}
                >
                  Please select a image file
                </small>
                <Hint
                  id="bannerRequirements"
                  hintText="We recommended using 410 x 1000 image"
                />
              </div>
            </div>
            <div className="ml-auto">
              <img
                src={LeftNavigationBackgroundImageWithBaseUrl}
                style={{ width: "100px" }}
              />
            </div>
          </div>
          {/* Left Navigation Background image upload Ends */}
        </DefaultContent>
        <DefaultContent>
          <h5 className="font-weight-bold">Legal Disclosure</h5>
          <div className="field-wrapper">
            <TextArea
              name={SETTINGS_LEGAL_DISCLOSURE}
              // label="Legal Disclosure"
              placeholder="Enter the legal disclosure..."
            />
          </div>
        </DefaultContent>
        <DefaultContent>
          <h4 className="font-weight-bold">Footer</h4>
          {/* Footer color */}
          <h5 className="font-weight-bold">Color</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_FOOTER_COLOR}
              placeholder="Enter Footer Color code"
            />
          </div>

          {/* Footer Heading color*/}
          <h5 className="font-weight-bold">Heading Color</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_FOOTER_HEADING_COLOR}
              placeholder="Enter Footer Heading Color code"
            />
          </div>

          {/* Footer Text color*/}
          <h5 className="font-weight-bold">Text Color</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_FOOTER_TEXT_COLOR}
              placeholder="Enter Footer Text Color code"
            />
          </div>

          {/* Footer Copyright text */}
          <h5 className="font-weight-bold">Copyright Text</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_FOOTER_COPY_RIGHTS_TEXT}
              placeholder="Enter Footer Copyright Text"
            />
          </div>

          <h5 className="font-weight-bold">URL for Terms of Service</h5>
          <div className="field-wrapper">
            <URL
              name={SETTINGS_TERMS_OF_SERVICE_URL}
              label="Set the path to your terms of service."
              placeholder="Enter the url..."
            />
          </div>

          <h5 className="font-weight-bold">Desktop Content</h5>
          <div className="field-wrapper">
            <TextArea
              name={SETTINGS_PORTAL_DESKTOP_FOOTER_CONTENT}
              placeholder="Enter the desktop footer content"
            />
          </div>

          <h5 className="font-weight-bold">Mobile Content</h5>
          <div className="field-wrapper">
            <TextArea
              name={SETTINGS_PORTAL_MOBILE_FOOTER_CONTENT}
              placeholder="Enter the mobile footer content"
            />
          </div>

          <h5 className="font-weight-bold">Public Page Footer Content</h5>
          <div className="field-wrapper">
            <TextArea
              name={SETTINGS_PORTAL_PUBLIC_PAGE_FOOTER_CONTENT}
              placeholder="Enter the public page footer content"
            />
          </div>
        </DefaultContent>
      </div>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default Theme;
