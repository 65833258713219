import React from "react";

// Components
import UpdateContactForm from "./UpdateContactForm";

const ContactFormDetails = props => {
  return (
    <div>
      <UpdateContactForm history={props.history} match={props.match} />
    </div>
  );
};

export default ContactFormDetails;
