import React from "react";
import Avatar from "../../../components/base/Avatar";
import {
  CheckmarkIcon,
  DollarIcon,
  MessageCircleIcon,
  UserIcon,
  WatchIcon
} from "../../../assets/img/icons";
import ProjectStepWrapper from "../../projects/inner-components/ProjectStepWrapper";
import mockUser from "../../../assets/img/mock_imgs/user_mock.jpg";
import Steps from "../../projects/inner-components/ProjectSteps";
import mackBackground from "../../../assets/img/mock_imgs/project_placeholder.jpg";
import SampleProjectTooltip from "../../projects/inner-components/SampleProjectTooltip";
import Hint from "../../../components/base/Hint";
import ProjectsList from "../../../views/projects/inner-components/ProjectsList";

const IncomingProjects = props => {
  const steps = Steps();
  let projectStatusHistory = ["Requested", "Matching", "Interviewing"];

  return (
    <div>
      <div
        className={[
          `${
            !props.expertIncomingProjects.totalCount ? "incoming-project" : ""
          }`,
          `${!props.enable ? "disabled" : ""}`
        ].join(" ")}
      >
        <h5 className={["mt-4", "mb-2", "font-weight-bold"].join(" ")}>
          Incoming Projects
        </h5>
        {props.expertIncomingProjects &&
        props.expertIncomingProjects.totalCount ? (
          <>
            {/* Incoming project list */}
            <ProjectsList data={props.expertIncomingProjects} />
          </>
        ) : (
          <>
            <Hint
              id="incoming-projects"
              hintText="No projects are available yet, but check out the sample project card below as an example of what they look like"
            />
            <div className="project-list">
              <div className="project d-flex justify-content-start text-decoration-none">
                <div
                  id="card-link"
                  className="playbook-card-link project-playbook-card text-decoration-none"
                >
                  <div className="card mx-auto">
                    <div
                      id="card-image"
                      className="card-header"
                      style={{
                        backgroundImage: `url(${mackBackground})`,
                        backgroundColor: "rgb(0, 157, 218)",
                        backgroundSize: "cover"
                      }}
                    >
                      <div className="new-message" id="new-message">
                        <MessageCircleIcon />3
                        <SampleProjectTooltip
                          tooltipId={"new-message"}
                          placement={"right"}
                        >
                          <p className="font-weight-bold">New Comments</p>
                          <p>
                            This indicates how many new comments there are on
                            the project.
                          </p>
                        </SampleProjectTooltip>
                      </div>
                      <div className="new-alert-message">
                        A Deliverable is Due Within 24 Hours
                      </div>
                    </div>
                    <div className="card-body" id={"body-tooltip"}>
                      <p className="cursor-pointer card-title font-weight-bold">
                        Sample Project
                      </p>
                      <SampleProjectTooltip
                        tooltipId={"body-tooltip"}
                        placement={"right"}
                      >
                        <p className="font-weight-bold">
                          A title for the tooltip
                        </p>
                      </SampleProjectTooltip>
                    </div>
                  </div>
                </div>
                <div className="project-data d-flex flex-column w-100">
                  <div className="project-maininfo d-flex justify-content-between">
                    <div className="project-finances">
                      <div className="field-wrapper" id="client-tooltip">
                        <p className="font-weight-bold">Client</p>
                        <span className="d-block text-grayed font-weight-bold">
                          Acme Corporation
                        </span>
                        <SampleProjectTooltip
                          tooltipId={"client-tooltip"}
                          placement={"top"}
                        >
                          <p className="font-weight-bold">
                            A title for the tooltip
                          </p>
                        </SampleProjectTooltip>
                      </div>
                      <div className="field-wrapper" id="budget-tooltip">
                        <p className="font-weight-bold">Budget</p>
                        <span className="d-block text-grayed font-weight-bold">
                          Invoiced $1,350 of $3,500
                        </span>
                        <SampleProjectTooltip
                          tooltipId={"budget-tooltip"}
                          placement={"top"}
                        >
                          <p className="font-weight-bold">
                            A title for the tooltip
                          </p>
                        </SampleProjectTooltip>
                      </div>

                      <div className="field-wrapper" id="timeline-tooltip">
                        <p className="font-weight-bold">Timeline</p>
                        <span className="d-block text-grayed font-weight-bold">
                          January 1, 2020 - June 30, 2020
                        </span>
                        <SampleProjectTooltip
                          tooltipId={"timeline-tooltip"}
                          placement={"top"}
                        >
                          <p className="font-weight-bold">
                            A title for the tooltip
                          </p>
                        </SampleProjectTooltip>
                      </div>
                    </div>
                    <div className="project-progress">
                      <div className="field-wrapper">
                        <div className="user-wrapper">
                          <div className="field-icon">
                            <Avatar
                              firstName={"N"}
                              lastName={"B"}
                              size="customSize"
                              imageSize={"32"}
                              url={mockUser}
                            />
                          </div>
                          <p className="font-weight-bold">You</p>
                        </div>
                      </div>

                      <div className="field-wrapper">
                        <div className="d-flex align-items-center">
                          <div className="field-icon">
                            <WatchIcon />
                          </div>
                          <p className="font-weight-bold">
                            5
                            <span className="text-grayed font-weight-bold">
                              Total Hours
                            </span>
                          </p>
                        </div>
                      </div>

                      <div className="field-wrapper">
                        <div className="d-flex align-items-center">
                          <div className="field-icon">
                            <DollarIcon />
                          </div>
                          <p className="font-weight-bold">
                            $100
                            <span className="text-grayed font-weight-bold">
                              per hour
                            </span>
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="project-timeline" id="project-timeline">
                    <ProjectStepWrapper
                      steps={steps}
                      projectStatusHistory={projectStatusHistory}
                    />
                    <SampleProjectTooltip
                      tooltipId={"project-timeline"}
                      placement={"top"}
                    >
                      <p className="font-weight-bold">Project Process</p>
                      <p>
                        The project process will show what state the project
                        status is in - these states are:
                      </p>
                      <div className="steps-placeholder">
                        <div className="steps-wrapper">
                          <div className="step current-step">
                            <div className="step-icon">
                              <UserIcon />
                            </div>
                            <div className="flex-wrapper">
                              <span
                                className={`step-status-text font-weight-bold d-block text-white`}
                              >
                                Interviewing
                              </span>
                              <span
                                className={`step-status-text d-block text-white`}
                              >
                                You are being matched to a project
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="steps-wrapper">
                          <div className="step current-step">
                            <div className="step-icon">
                              <UserIcon />
                            </div>
                            <div className="flex-wrapper">
                              <span
                                className={`step-status-text font-weight-bold d-block text-white`}
                              >
                                In Process
                              </span>
                              <span
                                className={`step-status-text d-block text-white`}
                              >
                                The project is active and in process
                              </span>
                            </div>
                          </div>
                        </div>

                        <div className="steps-wrapper">
                          <div className="step completed-step current-step">
                            <div className="step-icon">
                              <CheckmarkIcon />
                            </div>
                            <div className="flex-wrapper">
                              <span
                                className={`step-status-text font-weight-bold d-block text-white`}
                              >
                                Completed
                              </span>
                              <span
                                className={`step-status-text d-block text-white`}
                              >
                                The project is complete
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </SampleProjectTooltip>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default IncomingProjects;
