import React, { Component } from "react";

import { isBadRequest } from "../../../../common/http";

import Toast from "../../../../components/base/Toast";
import { apiClient } from "../../../../apiClient";
import { endpoints } from "../../../../configs";
import EmailSection from "./email";
import {
  SETTINGS_PORTAL_FROM_EMAIL,
  SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME
} from "../../../../setting/Constants";

class EmailTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsData: "",
      isLoading: true,
      isSettingsLoading: true,
      email_logo_url: "",
      email_background_banner_url: "",
      email_logo: "",
      email_background_banner: "",
      settings: []
    };
  }

  componentDidMount() {
    this.getSettingsData(true);
  }

  // Settings API post
  _createSettings = async (data, companyId) => {
    return apiClient
      .post(`${endpoints().settingAPI}/?company_id=${companyId}`, data)
      .then(() => {
        Toast.success("Settings saved successfully");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Handle theme tab submit
  _handleThemeSubmit = values => {
    const data = new FormData();

    // Marketplace logo URL
    if (this.state.email_logo_url !== undefined) {
      data.append(
        "email_logo_url",
        this.state.email_logo_url ? this.state.email_logo_url : ""
      );
    }
    if (this.state.emailLogoFile !== undefined) {
      data.append("email_logo", this.state.emailLogoFile);
    }

    // Email background banner
    if (this.state.email_background_banner_url !== undefined) {
      data.append(
        "email_background_banner_url",
        this.state.email_background_banner_url
          ? this.state.email_background_banner_url
          : ""
      );
    }
    if (this.state.emailBackgroundBannerFile !== undefined) {
      data.append(
        "email_background_banner",
        this.state.emailBackgroundBannerFile
      );
    }

    if (values.email_primary_background_color !== undefined) {
      data.append(
        "email_primary_background_color",
        values.email_primary_background_color
          ? values.email_primary_background_color
          : ""
      );
    }

    if (values.email_primary_text_color !== undefined) {
      data.append(
        "email_primary_text_color",
        values.email_primary_text_color ? values.email_primary_text_color : ""
      );
    }

    if (values[SETTINGS_PORTAL_FROM_EMAIL] !== undefined) {
      data.append(
        SETTINGS_PORTAL_FROM_EMAIL,
        values[SETTINGS_PORTAL_FROM_EMAIL]
          ? values[SETTINGS_PORTAL_FROM_EMAIL]
          : ""
      );
    }

    if (values[SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME] !== undefined) {
      data.append(
        SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME,
        values[SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME]
          ? values[SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME]
          : ""
      );
    }
    // Partner invite notification email subject
    if (values.partner_invite_email_subject !== undefined) {
      data.append(
        "partner_invite_email_subject",
        values.partner_invite_email_subject
          ? values.partner_invite_email_subject
          : ""
      );
    }

    // Partner invite notification email message
    if (values.partner_invite_email_message !== undefined) {
      data.append(
        "partner_invite_email_message",
        values.partner_invite_email_message
          ? values.partner_invite_email_message
          : ""
      );
    }
    // Referral Decline notification email subject
    if (values.referral_decline_email_subject !== undefined) {
      data.append(
        "referral_decline_email_subject",
        values.referral_decline_email_subject
          ? values.referral_decline_email_subject
          : ""
      );
    }
    // Referral Decline  notification email message
    if (values.referral_decline_email_message !== undefined) {
      data.append(
        "referral_decline_email_message",
        values.referral_decline_email_message
          ? values.referral_decline_email_message
          : ""
      );
    }

    // Save settings
    this._createSettings(data, this.props.companyId);
  };

  // Email Logo Upload
  emailLogoUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          email_logo_url: URL.createObjectURL(files),
          email_logo: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "emailLogoFile")
      );
  };

  // Email Background Banner Upload
  emailBackgroundBannerUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          email_background_banner_url: URL.createObjectURL(files),
          email_background_banner: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "emailBackgroundBannerFile")
      );
  };
  // Email Logo Remove
  handleEmailLogoRemove = () => {
    this.setState({ email_logo_url: "", email_logo: "", emailLogoFile: "" });
  };

  // Email Background Banner Remove
  handleEmailBackgroundBannerRemove = () => {
    this.setState({
      email_background_banner_url: "",
      email_background_banner: "",
      emailBackgroundBannerFile: ""
    });
  };

  // Get Settings Data
  getSettingsData = () => {
    this.setState({ isSettingsLoading: true }, () => {
      return apiClient
        .get(`${endpoints().companyAPI}/settings/${this.props.companyId}`)
        .then(response => {
          let successMessage;

          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);
          const settings = response.data;
          this.setState({ settings: settings });

          if (settings) {
            settings.forEach(data => {
              const name = data.name;
              const value = data.value;
              this.setState({ [name]: value });
            });
          }
          this.setState({
            isSettingsLoading: false,
            email_logo_url: this.state.email_logo,
            email_background_banner_url: this.state.email_background_banner,
            email_logo: "",
            email_background_banner: ""
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
              this.setState({ isInvalidRequest: true });
            }
            console.error(errorMessage);
          }
        });
    });
  };

  render() {
    const themeTabSettings = {
      submitThemeTab: this._handleThemeSubmit,
      emailLogoUpload: this.emailLogoUpload,
      handleEmailLogoRemove: this.handleEmailLogoRemove,
      emailBackgroundBannerUpload: this.emailBackgroundBannerUpload,
      handleEmailBackgroundBannerRemove: this.handleEmailBackgroundBannerRemove
    };

    return (
      <div>
        <EmailSection
          adminSettings={this.state}
          saveSettings={themeTabSettings}
          companyId={this.props.companyId}
          settings={this.state.settings}
        />
      </div>
    );
  }
}

export default EmailTab;
