import _ from "lodash";
import React from "react";
import { connect } from "react-redux";
import { Col, Row, Table } from "reactstrap";
import { bindActionCreators } from "redux";

//Components
import { Formik } from "formik";
import SingleCheckbox from "../../../../src/partners/singleCheckbox";
import NoRecordsFound from "../NoRecordsFound";
import PageSearch from "../PageSearch";
import SelectDropdown from "../SelectDropdown";
import Spinner from "../Spinner";

//Action
import { fetchList, setTablePage } from "../../../actions/table";

//Styles
import Pagination from "../Pagination";
import "./styles.scss";

//Assets
import { ChevronDown, ChevronUp } from "../../../assets/img/icons";

//Helper
import {
  getKeyValueByObject,
  getParamsByName,
  isCompanyAdmin,
  isCompanyManager
} from "../../../lib/helper";
import PageSize from "../../PageSize";
import Region from "../../Region";
import CheckboxDropdownSelect from "../CheckboxDropdownSelect";
import Form from "../Form";
import Referral from "../../../helpers/Referral";
import { SETTINGS_THEME_LAYOUT } from "../../../setting/Constants";
import Partner from "../../Partner";

// Status constants
const PARTNERS_STATUS_ALL = "All";

export const ReduxColumn = () => {};

class ReduxTable extends React.Component {
  state = {
    page: 1,
    pageSize: 10,
    id: "",
    apiURL: "",
    selectedAll: false,
    selectedIds: [],
    searchTerm:
      this.props.params && this.props.params.searchItem
        ? this.props.params.searchItem
        : "" || "",
    isSearch: false,
    isActive: "",
    pagination: true,
    selectedStatusOption: "",
    selectedSortOption: "Alphabetical A-Z",
    sortByOptions: [
      {
        value: "a-z",
        label: "Alphabetical A-Z"
      }
    ],
    value: getParamsByName("search"),
    pageSizeValue: getParamsByName("pageSize"),
    partner: ""
  };

  componentDidMount() {
    const {
      apiURL,
      id,
      table,
      sortByOptions,
      statusOptions,
      params
    } = this.props;

    this.setState(
      {
        id,
        apiURL,
        page: table[id] && table[id].currentPage ? table[id].currentPage : 1,
        pageSize:
          params && params.pageSize
            ? params.pageSize
            : this.state.pageSizeValue
            ? this.state.pageSizeValue
            : table[id] && table[id].pageSize
            ? table[id].pageSize
            : 10,
        selectedStatusOption:
          statusOptions && statusOptions.length
            ? statusOptions[0].value
            : this.state.selectedStatusOption,

        selectedSortOption:
          sortByOptions && sortByOptions.length
            ? sortByOptions[0].value
            : this.state.selectedSortOption
      },
      () => {
        const selectedSortOption = this.getSelectedSortLabel(
          this.state.selectedSortOption
        );
        this.handleSortByChange(selectedSortOption);
      },
      () => {
        const selectedStatusOption = this.getSelectedStatusLabel(
          this.state.selectedStatusOption
        );
        this.handleStatusByChange(selectedStatusOption);
      }
    );
  }

  componentDidUpdate(prevProps) {
    if (this.props.apiURL !== prevProps.apiURL) {
      const { sortByOptions, statusOptions } = this.props;

      this.setState(
        {
          apiURL: this.props.apiURL,
          selectedStatusOption:
            statusOptions && statusOptions.length
              ? statusOptions[0].value
              : this.state.selectedStatusOption,
          selectedSortOption:
            sortByOptions && sortByOptions.length
              ? sortByOptions[0].value
              : this.state.selectedSortOption
        },
        () => {
          const selectedSortOption = this.getSelectedSortLabel(
            this.state.selectedSortOption
          );
          this.handleSortByChange(selectedSortOption);
        },
        () => {
          const selectedStatusOption = this.getSelectedStatusLabel(
            this.state.selectedStatusOption
          );
          this.handleStatusByChange(selectedStatusOption);
        }
      );
    }
  }

  /**
   * Fetch data using API call
   *
   * @param {*} force
   */

  fetchData(force, sort, sortDir, status, data) {
    const {
      id,
      apiURL,
      page,
      pageSize,
      searchTerm,
      pagination,
      selectedSortOption,
      selectedStatusOption
    } = this.state;
    const {
      table,
      actions: { fetchList, setTablePage },
      sortByOptions,
      statusOptions,
      selectedPartnerProgramTypeId,
      selectedStatus
    } = this.props;
    const sortAndSortDir = selectedSortOption.split(":");
    const statusValue = selectedStatusOption
      ? selectedStatusOption.split(":")
      : "";
    const sortBy = sort ? sort : sortAndSortDir[0];
    const sortDirBy = sortDir ? sortDir : sortAndSortDir[1];
    const selectedSatus = status
      ? status
      : selectedStatus
      ? selectedStatus
      : statusValue[0];

    const listDetails = table[id] || {};

    const params = this.props.params || {};
    params.search =
      searchTerm || (data && data.search ? data.search : "") || "";
    if (this.props.startDate) {
      params.startDate = this.props.startDate;
    }
    if (this.props.endDate) {
      params.endDate = this.props.endDate;
    }
    if (getParamsByName("partnerType")) {
      params.partnerType = getParamsByName("partnerType");
    }
    if (getParamsByName("start_date")) {
      params.start_date = getParamsByName("start_date");
    }
    if (getParamsByName("end_date")) {
      params.end_date = getParamsByName("end_date");
    }
    if (getParamsByName("partnerTier")) {
      params.partnerTier = getParamsByName("partnerTier");
    }
    if (getParamsByName("account")) {
      params.account = getParamsByName("account");
    }

    if (getParamsByName("status")) {
      params.status = getParamsByName("status");
    }
    if (getParamsByName("region")) {
      params.region = getParamsByName("region");
    }

    if (this.props.id == "partnerReport") {
      if (getParamsByName("status")) {
        params.status = getParamsByName("status");
      } else {
        params.status = "";
      }
    }
    if (getParamsByName("partner")) {
      params.partner = getParamsByName("partner");
    }
    if (this.props.id == "referralReport") {
      if (getParamsByName("referralType")) {
        params.referralType = getParamsByName("referralType");
      } else {
        params.referralType = "";
      }
    }
    if (selectedSatus) {
      params.status =
        selectedSatus === PARTNERS_STATUS_ALL
          ? ""
          : selectedSatus
          ? selectedSatus
          : "";
    }

    if (selectedPartnerProgramTypeId) {
      params.partnerType = selectedPartnerProgramTypeId;
    }
    params.sort = sort || (sortByOptions && sortBy) || "";
    params.sortDir = sortDir || (sortByOptions && sortDirBy) || "";
    params.partner =
      data && (data.partner == "" || data.partner !== "")
        ? data.partner
        : this.state.partner
        ? this.state.partner
        : "";
    params.pagination = pagination;
    if (params.pageSize) {
      params.pageSize = params.pageSize;
    } else {
      params.pageSize = this.state.pageSizeValue || pageSize;
    }
    if (this.props.paramsToUrl) {
      const currentPage = window.location.pathname;
      let queryString = "";
      const queryStringArray = Object.entries(params);

      if (queryStringArray.length > 0) {
        queryString = "?";
        queryStringArray.forEach(async queryParam => {
          if (queryParam[0] !== "searchItem" && queryParam[1] !== "")
            queryString = `${queryString}&${queryParam[0]}=${queryParam[1]}`;
        });
      }
      if (this.props.history) {
        this.props.history.push(`${currentPage}${queryString}`, {
          data: params
        });
      }
    }

    if (!listDetails.isFetching) {
      if (
        !listDetails[page] ||
        (listDetails.sortList[page] !== params.sort &&
          listDetails.sortDirList[page] !== params.sortDir) ||
        force
      ) {
        fetchList(id, apiURL, page, pageSize, params);
      } else {
        setTablePage(id, page);
      }
    }
  }

  /**
   * Change page
   *
   * @param {*} page
   */
  onPageChange(page) {
    const sort = getParamsByName("sort") || "";
    const sortDir = getParamsByName("sortDir") || "";
    const status = getParamsByName("status") || "";
    const searchTerm = getParamsByName("search") || "";
    this.setState({ page }, () =>
      this.fetchData(true, sort, sortDir, status, { search: searchTerm })
    );
  }

  /**
   * Change page size
   *
   * @param {*} e
   */
  onPageSizeChange(e) {
    this.setState({ page: 1, pageSize: e.target.value, isSearch: false }, () =>
      this.fetchData(true)
    );
  }

  /**
   * Select all checkbox
   *
   * @param {*} data
   * @param {*} e
   */
  toggleSelectAll(data, e) {
    const selectedIds = this.state.selectedIds;

    data.forEach(row => {
      if (e.target.checked) {
        if (selectedIds.indexOf(row.id) < 0) {
          selectedIds.push(row.id);
        }
      } else {
        if (selectedIds.indexOf(row.id) >= 0) {
          selectedIds.splice(selectedIds.indexOf(row.id), 1);
        }
      }
    });

    this.setState(
      {
        selectedAll: e.target.checked,
        selectedIds: selectedIds
      },
      () => {
        this.props.onBulkSelect(selectedIds);
      }
    );
  }

  /**
   * Single checkbox select
   *
   * @param {*} data
   * @param {*} e
   */
  toggleSelect(data, e) {
    const rowIds = [];
    data.forEach(row => {
      rowIds.push(row.id);
    });

    const rowId = this.props.isOnCheckIntegerValue
      ? parseInt(e.target.value)
      : e.target.value;

    const selectedIds = this.state.selectedIds;
    if (e.target.checked) {
      if (selectedIds.indexOf(rowId) < 0) {
        selectedIds.push(rowId);
      }
    } else {
      if (selectedIds.indexOf(rowId) >= 0) {
        selectedIds.splice(selectedIds.indexOf(rowId), 1);
      }
    }

    let selectedLength = 0;
    rowIds.forEach(rowId => {
      if (selectedIds.indexOf(rowId) >= 0) {
        selectedLength++;
      }
    });

    this.setState(
      {
        selectedAll: rowIds.length === selectedLength,
        selectedIds: selectedIds
      },
      () => {
        this.props.onBulkSelect(selectedIds);
      }
    );
  }

  componentWillReceiveProps(props) {
    const { table, id } = props;
    const listDetails = table[id];
    let data = [];

    if (listDetails) {
      data = listDetails[listDetails.currentPage] || [];
    }

    const selectedIds = this.state.selectedIds;
    let selectedLength = 0;
    data.forEach(row => {
      if (selectedIds.indexOf(row.id) >= 0) {
        selectedLength++;
      }
    });

    this.setState({
      selectedAll: selectedLength > 0 && selectedLength === data.length
    });
  }

  /**
   * Change search term
   *
   * @param {*} event
   */
  onChange(event) {
    this.setState({ value: event.target.value });
    event.persist();
    this.setState({ searchTerm: event.target.value });
    this.doSearch(event);
    if (this.props.islandingTable)
      this.props.saveSearchTerm(event.target.value);
  }

  doSearch = _.debounce(event => {
    this.setState(
      {
        isSearch: true,
        searchTerm: encodeURIComponent(event.target.value),
        page: 1
      },
      () => {
        const status = getParamsByName("status") || "";
        this.fetchData(true, "", "", status);
        this.setState({
          selectedAll: false,
          selectedIds: []
        });
      }
    );
  }, 500);

  columnSortBy(sortBy) {
    this.setState({ isActive: !this.state.isActive, sortBy: sortBy });
    let searchTerm = getParamsByName("search");
    let sortDir = "";
    if (this.state.isActive) {
      sortDir = "DESC";
    } else {
      sortDir = "ASC";
    }
    this.fetchData(true, sortBy, sortDir, "", { search: searchTerm });
  }

  handleSortByChange = value => {
    this.setState({ selectedSortOption: this.getSortValueFromLabel(value) });
    this.getSortByOptions(value);
  };

  // Sort by option
  getSortByOptions(value) {
    const valueArray = this.getSortValueFromLabel(value).split(":");
    const sortBy = valueArray[0];
    const sortDir = valueArray[1];
    const status = getParamsByName("status");
    this.fetchData(true, sortBy, sortDir, status);
  }

  getSelectedSortLabel() {
    const sortByOptions = this.props.sortByOptions
      ? this.props.sortByOptions
      : this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.value === this.state.selectedSortOption
    );

    if (selectedSortOption) {
      return selectedSortOption.label;
    }

    return "";
  }

  // handle status
  handleStatusByChange = value => {
    this.setState({ selectedStatusOption: this.getSortValueFromLabel(value) });
    this.getStatusByOptions(value);
  };
  // get seleted status
  getSelectedStatusLabel() {
    const statusOptions = this.props.statusOptions
      ? this.props.statusOptions
      : this.state.sortByOptions;

    let selectedStatusOption;
    if (this.props.selectedStatus) {
      selectedStatusOption = statusOptions.find(
        option => option.value === this.props.selectedStatus
      );
    } else {
      selectedStatusOption = statusOptions.find(
        option => option.value === this.state.selectedStatusOption
      );
    }
    if (selectedStatusOption) {
      return selectedStatusOption.label;
    }

    return "";
  }
  // get status value from label
  getStatusValueFromLabel(label) {
    const statusOptions = this.props.statusOptions
      ? this.props.statusOptions
      : this.state.statusOptions;

    const selectedStatusOption = statusOptions.find(
      option => option.label === label
    );

    if (selectedStatusOption) {
      return selectedStatusOption.value;
    }

    return "";
  }

  handlePartnerChange = value => {
    let partnerId = value && value.value ? value.value : "";
    this.setState({ partner: partnerId });
    this.fetchData(
      true,
      getParamsByName("sort"),
      getParamsByName("sortDir"),
      getParamsByName("status"),
      { partner: partnerId ? partnerId : "" }
    );
  };
  // status by option
  getStatusByOptions(value) {
    const valueArray = this.getStatusValueFromLabel(value).split(":");
    const status = valueArray[0];
    if (this.props && this.props.getStatus) {
      this.props.getStatus(status);
    }
    this.fetchData(true, "", "", status);
  }

  getSortValueFromLabel(label) {
    const sortByOptions = this.props.sortByOptions
      ? this.props.sortByOptions
      : this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  // Page size by option
  getPageSizeByOptions(value) {
    this.setState(
      {
        page: 1,
        pageSize: value,
        pageSizeValue: value
      },
      () => {
        this.fetchData(true, "", "");
      }
    );
  }

  render() {
    const {
      table,
      id,
      children: columns,
      showHeader,
      onRowClick,
      bulkSelect,
      headerButton,
      searchPlaceholder,
      assignedPartnersCheckbox,
      searchDisabled,
      newTableHeading,
      transformData,
      disableColumnSort,
      sortByOptions,
      icon,
      message,
      searchBarWidth,
      assignedPartnerChange,
      assignedPartnerValue,
      subtextMessage,
      noPagination,
      hideMarginBottom,
      showNoRecord = true,
      noRecordFoundHeight,
      noRecordFoundComponent,
      onScroll,
      statusOptions,
      showStatusOptions,
      dropdownStyle,
      showPageSize,
      showDropdown,
      handleTypeChange,
      handleTierChange,
      showSortOptions,
      partnerTypeList,
      partnerTierList,
      handleRegionChange,
      showRegionFilter,
      settings,
      showPartnerFilter
    } = this.props;

    const DefaultLayout = settings
      ? getKeyValueByObject(settings, SETTINGS_THEME_LAYOUT)
      : "";

    const listDetails = table[id];
    const isLoading = !listDetails || listDetails.isFetching;
    const { selectedAll, selectedIds } = this.state;

    let data = [];
    let totalCount = 0;
    let currentPage = "";
    let pageSize = "";
    let startPage = "";
    let endPage = "";

    if (listDetails) {
      currentPage = listDetails.currentPage;
      totalCount = listDetails.totalCount;
      pageSize = listDetails.pageSize;
      data = listDetails[currentPage] || [];

      startPage = (currentPage - 1) * pageSize + 1;
      startPage = startPage > totalCount ? totalCount : startPage;

      endPage = currentPage * pageSize;
      endPage = endPage > totalCount ? totalCount : endPage;
    }

    const columnLength = columns.length + (bulkSelect ? 1 : 0);

    if (transformData) {
      data = transformData(data);
    }

    return (
      <div
        className={`redux-table ${this.props.className} ${
          onScroll ? "redux-table-responsive" : ""
        }`}
      >
        <div>
          {showHeader && (
            <>
              {newTableHeading ? (
                <div className="page-heading  cover ">
                  <div
                    className={` d-flex align-items-start ${
                      searchDisabled
                        ? "justify-content-end"
                        : "justify-content-between"
                    }`}
                  >
                    {!showPageSize && (
                      <div className="mr-1">
                        <PageSize
                          onChange={e => this.getPageSizeByOptions(e)}
                        />
                      </div>
                    )}
                    {!searchDisabled && (
                      <PageSearch
                        width={searchBarWidth}
                        value={this.state.value}
                        classnames="page-search"
                        placeholder={searchPlaceholder}
                        onChange={this.onChange.bind(this)}
                      />
                    )}
                    {/* show assigned partners checkbox */}
                    {(isCompanyManager(this.props.roleId) ||
                      isCompanyAdmin(this.props.roleId)) &&
                      assignedPartnersCheckbox && (
                        <div>
                          <Formik>
                            <SingleCheckbox
                              name={assignedPartnersCheckbox}
                              label="Show My Assigned Partners Only"
                              className="assign-partner-checkbox mt-2"
                              assignedPartnerValue={assignedPartnerValue}
                              assignedPartnerChange={assignedPartnerChange}
                            />
                          </Formik>
                        </div>
                      )}

                    {showStatusOptions && (
                      <SelectDropdown
                        buttonLabel={this.getSelectedStatusLabel()}
                        dropdownLinks={statusOptions}
                        color={"gray"}
                        hideCaret
                        selectName={"sortby_experts"}
                        handleChange={this.handleStatusByChange}
                      />
                    )}
                    {!showSortOptions && (
                      <div className="ml-3">
                        <SelectDropdown
                          buttonLabel={this.getSelectedSortLabel()}
                          dropdownLinks={sortByOptions}
                          color={"gray"}
                          hideCaret
                          selectName={"sortby_experts"}
                          handleChange={this.handleSortByChange}
                          dropdownStyle={dropdownStyle}
                        />
                      </div>
                    )}
                  </div>
                  <div className="row mt-2">
                    {showPartnerFilter && (
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <Form
                          initialValues={{
                            partner: ""
                          }}
                        >
                          <Partner
                            name="partner"
                            // partner_id={props.selectedPartnerId}
                            placeholder="Select Partner"
                            defaultValue="partner"
                            isSearchable={true}
                            isClearable={true}
                            handleChange={currentVal => {
                              this.handlePartnerChange(currentVal);
                            }}
                          />
                        </Form>
                      </div>
                    )}
                  </div>
                  <div>
                    {showDropdown && (
                      <div>
                        <Form
                          initialValues={{
                            partnerType: "",
                            partnerTier: "",
                            region: ""
                          }}
                        >
                          <div className="row mt-2">
                            <div
                              className={
                                showRegionFilter == true
                                  ? "col-md-4 mt-2"
                                  : "col-md-6 mt-2"
                              }
                            >
                              <CheckboxDropdownSelect
                                id="partnerType"
                                color={"gray"}
                                hideCaret
                                name="partnerType"
                                buttonLabel="Partner Type"
                                dropdownLinks={partnerTypeList}
                                handleChange={e => {
                                  handleTypeChange && handleTypeChange(e);
                                }}
                              />
                            </div>
                            <div
                              className={
                                showRegionFilter == true
                                  ? "col-md-4 mt-2"
                                  : "col-md-6 mt-2"
                              }
                            >
                              <CheckboxDropdownSelect
                                id="partnerTier"
                                name="partnerTier"
                                buttonLabel="Partner Tier"
                                color={"gray"}
                                hideCaret
                                dropdownLinks={partnerTierList}
                                handleChange={e => {
                                  handleTierChange && handleTierChange(e);
                                }}
                              />
                            </div>
                            <div className="col-md-4 mt-2">
                              {showRegionFilter == true && (
                                <Region
                                  id="region"
                                  name="region"
                                  background="#4d4d4d"
                                  placeholder="Select Region"
                                  onChange={e => {
                                    handleRegionChange && handleRegionChange(e);
                                  }}
                                  isClearable
                                />
                              )}
                            </div>
                          </div>
                        </Form>
                      </div>
                    )}
                  </div>
                </div>
              ) : (
                <Row className="mb-3">
                  <Col xs="12">
                    <div
                      className={`page-heading d-flex ${
                        searchDisabled
                          ? "justify-content-end"
                          : "justify-content-between"
                      }`}
                    >
                      {!searchDisabled && (
                        <PageSearch
                          classnames="page-search"
                          placeholder={searchPlaceholder}
                          onChange={this.onChange.bind(this)}
                        />
                      )}
                      {headerButton && headerButton}
                    </div>
                  </Col>
                </Row>
              )}
            </>
          )}

          <Table
            hover
            responsive
            className={`table-outline text-break ${
              hideMarginBottom ? "mb-0" : ""
            }`}
          >
            <thead className="thead-light">
              <tr>
                {bulkSelect && (
                  <th style={{ width: 12, paddingRight: 0 }}>
                    <input
                      type="checkbox"
                      checked={selectedAll}
                      onChange={this.toggleSelectAll.bind(this, data)}
                    />
                  </th>
                )}
                {React.Children.map(columns, x => {
                  // TODO: Santhosh, let's have the "selected" class applied when you click on the TH for the filterings.
                  return (
                    x && (
                      <th
                        style={{
                          minWidth: x.props.minWidth ? x.props.minWidth : "",
                          maxWidth: x.props.maxWidth ? x.props.maxWidth : ""
                        }}
                        className={`${
                          x.props.sortBy !== "" ? "cursor-pointer" : ""
                        } ${x.props.className}`}
                        onClick={() => {
                          return !disableColumnSort && !x.props.disableOnClick
                            ? this.columnSortBy(x.props.sortBy)
                            : false;
                        }}
                        colSpan={x.props.colspan}
                      >
                        {x.props.children}
                        {!disableColumnSort && !x.props.disableOnClick ? (
                          x.props.sortBy === this.state.sortBy ? (
                            this.state.isActive ? (
                              <ChevronUp />
                            ) : (
                              <ChevronDown />
                            )
                          ) : (
                            <ChevronDown />
                          )
                        ) : (
                          ""
                        )}
                      </th>
                    )
                  );
                })}
              </tr>
            </thead>
            <tbody>
              {data.map((list, key) => {
                return (
                  <tr key={key}>
                    {bulkSelect && (
                      <td style={{ paddingRight: 0 }}>
                        <input
                          type="checkbox"
                          value={list.id}
                          onChange={this.toggleSelect.bind(this, data)}
                          checked={selectedIds.indexOf(list.id) >= 0}
                        />
                      </td>
                    )}
                    {React.Children.map(columns, x => {
                      return (
                        x && (
                          <td
                            className={`${x.props.className} ${
                              onRowClick && !x.props.disableOnClick
                                ? "cursor-pointer"
                                : ""
                            } ${
                              x.props.type && x.props.type === "link"
                                ? "text-link text-underline"
                                : ""
                            }`}
                            onClick={() =>
                              x.props.isClickable === "true" &&
                              onRowClick &&
                              !x.props.disableOnClick
                                ? x.props.onLinkClick
                                  ? x.props.onLinkClick(list)
                                  : onRowClick(list)
                                : null
                            }
                            style={{
                              maxWidth: x.props.width ? x.props.width : "300px",
                              ...(x.props.field &&
                                x.props.field.toLowerCase() === "action" && {
                                  width: "90px"
                                })
                            }}
                          >
                            {x.props.field !== "action"
                              ? x.props.renderField
                                ? x.props.renderField(list)
                                : list[x.props.field]
                              : x.props.element}
                          </td>
                        )
                      );
                    })}
                  </tr>
                );
              })}
              {console.log(noRecordFoundComponent)}
              {isLoading ? (
                <Spinner />
              ) : !noRecordFoundComponent ? (
                data.length === 0 && !icon && showNoRecord ? (
                  <tr>
                    <td className="text-center" colSpan={columnLength}>
                      <NoRecordsFound
                        middleHeight={noRecordFoundHeight}
                        showMessage={true}
                        hideCard={true}
                        message="No Records Found"
                      />
                    </td>
                  </tr>
                ) : data.length === 0 && icon ? (
                  <tr>
                    <td
                      className="align-middle"
                      colSpan={columnLength}
                      height="400px"
                    >
                      <div className="d-flex flex-column align-items-center">
                        {icon}
                        <strong>No records found</strong>
                        <span> {message ? message : ""} </span>
                        <span> {subtextMessage ? subtextMessage : ""} </span>
                      </div>
                    </td>
                  </tr>
                ) : null
              ) : (
                data.length === 0 && (
                  <tr>
                    <td className="text-center" colSpan={columnLength}>
                      {noRecordFoundComponent}
                    </td>
                  </tr>
                )
              )}
            </tbody>
          </Table>

          {totalCount > 0 && !noPagination && (
            <Row>
              <Col>
                Showing {startPage} to {endPage} of {totalCount} entries
              </Col>
              <Col>
                <Pagination
                  currentPage={currentPage}
                  totalCount={totalCount}
                  pageSize={pageSize}
                  onPageChange={this.onPageChange.bind(this)}
                />
              </Col>
            </Row>
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    table: state.table,
    roleId: state.user ? state.user.roleId : ""
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList, setTablePage }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ReduxTable);
