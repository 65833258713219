import React from "react";
import Text from "../../../components/base/Text";
import Select from "../../../components/base/Select";
import Button from "../../../components/base/Button";
import CoSell from "../../../Constants/Cosell";
import Form from "../../../components/base/Form";
import TextArea from "../../../components/base/TextArea";
import Radio from "../../../components/base/Radio";
import DateInput from "../../../components/base/Date";
import Multiselect from "../../../components/base/Multiselect";
import Number from "../../../components/base/Number";

const ProjectDetailTab = props => {
  let { detail, showButton, readOnly } = props;

  let awsProductValue = [];

  let awsDealList =
    detail && detail.aws_products && detail.aws_products.split(",");
  if (awsDealList && awsDealList.length > 0) {
    for (let i = 0; i < awsDealList.length; i++) {
      let awsDeal = props.awsProductOption.find(
        value => value.value == awsDealList[i]
      );
      awsProductValue.push(awsDeal ? awsDeal : "");
    }
  }

  let deliveryModelValue = [];

  let deliveryList =
    detail && detail.delivery_model && detail.delivery_model.split(",");
  if (deliveryList && deliveryList.length > 0) {
    for (let i = 0; i < deliveryList.length; i++) {
      let awsDeal = props.deliveryModelOption.find(
        value => value.value === deliveryList[i]
      );
      deliveryModelValue.push(awsDeal ? awsDeal : "");
    }
  }

  let apnProgramValue = [];

  let apnList = detail && detail.apn_programs && detail.apn_programs.split(",");
  if (apnList && apnList.length > 0) {
    for (let i = 0; i < apnList.length; i++) {
      let awsDeal = props.apnProgramOption.find(
        value => value.value == apnList[i]
      );
      apnProgramValue.push(awsDeal ? awsDeal : "");
    }
  }

  let saleActivity = [];

  let saleActivityList =
    detail && detail.sales_activities && detail.sales_activities.split(",");
  if (saleActivityList && saleActivityList.length > 0) {
    for (let i = 0; i < saleActivityList.length; i++) {
      let awsDeal = props.salesActivityOption.find(
        value => value.value === saleActivityList[i]
      );
      saleActivity.push(awsDeal ? awsDeal : "");
    }
  }

  let cosellWithAwsObj = CoSell.cosellWithAwsOption.find(
    item => item.value == detail.share_with_aws
  );

  let oppTypeObj = CoSell.opportunityTypeOption.find(
    item => item.value == detail.opportunity_type
  );
  let oppSource = CoSell.opportunitySourceOption.find(
    item => item.value == detail.opportunity_source
  );
  return (
    <div>
      <Form
        enableReinitialize={true}
        initialValues={{
          share_with_aws: "",
          opportunity_type: "",
          partner_project_title: (detail && detail.partner_project_title) || "",
          sales_activities: saleActivity || "",
          customer_business_problem:
            (detail && detail.customer_business_problem) || "",
          solutions_offered:
            props.solutionOfferedOption.find(
              value => value.value == detail.solutions_offered
            ) || "",
          other_solution_offered:
            (detail && detail.other_solution_offered) || "",
          aws_products: awsProductValue.length > 0 ? awsProductValue : "",
          next_step: (detail && detail.next_step) || "",
          use_case: props.useCaseOption.find(
            value => value.value == detail.use_case
          ),
          delivery_model: deliveryModelValue || "",
          estimated_aws_monthly_recurring_revenue:
            (detail && detail.estimated_aws_monthly_recurring_revenue) || "",
          target_close_date: (detail && detail.target_close_date) || "",
          apn_programs: apnProgramValue.length > 0 ? apnProgramValue : "",
          opportunity_source: ""
        }}
        onSubmit={values => {
          props.handleUpdate(values);
        }}
      >
        <div className="card border-0 mt-4">
          <div
            style={{
              backgroundColor: "#fafafa",
              borderBottom: "1px solid",
              borderColor: "#eaeded"
            }}
          >
            <div className="mt-4 mx-4">
              <label className="font-weight-bold form-group">
                Project Details
              </label>
            </div>
          </div>
          <div className="card-body">
            <label>Partner primary need from AWS</label>
            <div className="field-wrapper ml-4 mb-3">
              <div className="form-group">
                <Radio
                  name="share_with_aws"
                  size="medium"
                  showValue={true}
                  options={CoSell.cosellWithAwsOption}
                  onChange={e => {
                    props.handleAwsShareChange(e.target.value);
                  }}
                  defaultValue={cosellWithAwsObj && cosellWithAwsObj.label}
                  readOnly={readOnly}
                />
              </div>
            </div>
            <label>Opportunity Type</label>
            <div className="field-wrapper ml-4 mb-3">
              <div className="form-group">
                <Radio
                  name="opportunity_type"
                  size="medium"
                  showValue={true}
                  options={CoSell.opportunityTypeOption}
                  onChange={e => {
                    props.handleOppTypeChange(e.target.value);
                  }}
                  defaultValue={oppTypeObj && oppTypeObj.label}
                  readOnly={readOnly}
                />
              </div>
            </div>
            <div className="field-wrapper">
              <Text
                name="partner_project_title"
                required
                label="Partner Project Title"
                placeholder="Enter Partner Project Title"
                disabled={readOnly}
              />
            </div>
            <div className="field-wrapper mt-3">
              <Multiselect
                name="sales_activities"
                label="Sales Activities"
                placeholder="Enter Sales Activities"
                required
                error=""
                options={props.salesActivityOption}
                isDisabled={readOnly}
              />
            </div>
            <div className="field-wrapper w-100">
              <TextArea
                name="customer_business_problem"
                label="Customer Business Problem"
                placeholder="Customer Business Problem..."
                rows="5"
                required
                disabled={readOnly}
              />
            </div>
            <div className="field-wrapper mt-3">
              <Select
                name="solutions_offered"
                label="Solutions Offered"
                required
                placeholder="Enter Solutions Offered"
                error=""
                options={props.solutionOfferedOption}
                isDisabled={readOnly}
              />
            </div>
            <div className="field-wrapper">
              <Text
                name="other_solution_offered"
                label="Other Solution Offered"
                placeholder="Enter Other Solution Offered"
                required
                disabled={readOnly}
              />
            </div>
            <div className="field-wrapper mt-3">
              <Multiselect
                name="aws_products"
                label="AWS Products"
                placeholder="Enter AWS Products"
                error=""
                options={props.awsProductOption}
                isDisabled={readOnly}
              />
            </div>
            <div className="field-wrapper w-100">
              <TextArea
                name="next_step"
                label="Next Step"
                placeholder="Next Step..."
                rows="5"
                disabled={readOnly}
              />
            </div>
            <div className="field-wrapper mt-3">
              <Select
                name="use_case"
                label="Use Case"
                placeholder="Enter Use Case"
                required
                error=""
                isDisabled={readOnly}
                options={props.useCaseOption}
              />
            </div>
            <div className="field-wrapper mt-3">
              <Multiselect
                name="delivery_model"
                label="Delivery Model"
                placeholder="Enter Delivery Model"
                error=""
                options={props.deliveryModelOption}
                required
                isDisabled={readOnly}
              />
            </div>
            <div className="field-wrapper">
              <Number
                name="estimated_aws_monthly_recurring_revenue"
                label="Estimated AWS Monthly Recurring Revenue"
                placeholder="Enter Estimated AWS Monthly Recurring Revenue"
                required
                readOnly={readOnly}
              />
            </div>
            <div>
              <DateInput
                name="target_close_date"
                className="datepicker"
                placeholder="Target Close Date"
                label="Target Close Date"
                // onChange={props.handleStartDate}
                format="dd-MMM-yyyy"
                isClearable
                disabled={readOnly}
              />
            </div>
            <div className="field-wrapper mt-3">
              <Multiselect
                name="apn_programs"
                label="APN Programs"
                placeholder="Enter APN Programs"
                error=""
                options={props.apnProgramOption}
                isDisabled={readOnly}
              />
            </div>
          </div>
        </div>

        <div className="card border-0 mt-4">
          <div
            style={{
              backgroundColor: "#fafafa",
              borderBottom: "1px solid",
              borderColor: "#eaeded"
            }}
          >
            <div className="mt-4 mx-4">
              <label className=" font-weight-bold form-group">
                Opportunity marketing details
              </label>
            </div>
          </div>
          <div className="card-body">
            <label>
              Opportunity source
              <div style={{ fontSize: "10px" }}>
                Indicate whether this opportunity was sourced from a marketing
                activity.
              </div>
            </label>
            <div className="field-wrapper ml-4 mb-3">
              <div className="form-group">
                <Radio
                  name="opportunity_source"
                  size="medium"
                  showValue={true}
                  options={CoSell.opportunitySourceOption}
                  onChange={e => {
                    props.handleOppSourceChange(e.target.value);
                  }}
                  defaultValue={oppSource && oppSource.label}
                  readOnly={readOnly}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {props.handleChange && (
            <Button
              label="Back"
              type="button"
              onClick={() => {
                props.setActiveTab(1);
                props.handleChange(1);
              }}
              className=" mt-3 mb-3"
            />
          )}
          {!showButton && (
            <Button
              label={props.enableSaveButton ? "Save" : "Next"}
              type="submit"
              className=" mt-3 mb-3"
            />
          )}
        </div>
      </Form>
    </div>
  );
};

export default ProjectDetailTab;
