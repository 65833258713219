import React from "react";
import { Link } from "react-router-dom";
import _ from "lodash";

// Config
import { endpoints } from "../../../configs";

// Components
import ReduxTable, { ReduxColumn } from "../../../components/base/ReduxTable";
import { fetchList } from "../../../actions/table";

// Helper
import {
  isLoggedIn,
  getParamsByName,
  getCookie,
  setCookie,
  clearCookie
} from "../../../lib/helper";

import { COOKIE_SHOW_ASSIGNED_PARTNERS } from "../../../lib/cookie";
import { apiClient } from "../../../apiClient";
import detectBrowser from "../../../components/helpers/detectBrowser";

import { SETTINGS_SHOW_REGION } from "../../../setting/Constants";

import { getSettingsData } from "../../../actions/settings";

import PageTitle from "../../../components/base/PageTitle";

import PartnerService from "../../../services/partnerService";
import Filter from "../../../components/filter";

import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import Referral from "../../../helpers/Referral";
import ReportTable from "./table";
import DateTime from "../../../lib/DateTime";

class Report extends React.Component {
  state = {
    isChecked: "",
    isMobile: detectBrowser(),
    partner: "",
    start_date: "",
    end_date: "",
    status: getParamsByName("status")
      ? getParamsByName("status")
      : Referral.TYPE_ALL,
    search: "",
    selectedSortValue: "",
    sort: "",
    sortDir: "",
    typeObj: {},
    selectedSortOption: "createdAt:DESC",
    page: 1,
    currentPage: 1,
    submittedFrom: "",
    partnerType: "",
    partnerTier: "",
    region: "",
    showRegion: false,
    partnerTypeOptions: [],
    partnerTierOptions: [],
    regionList: [],
    partnerList: [],
    statusOptions: [
      {
        value: "All",
        label: "All"
      },
      {
        value: "Approved",
        label: "Approved"
      },
      {
        value: "Pending",
        label: "Pending"
      },
      {
        value: "Declined",
        label: "Declined"
      }
    ],
    sortByOptions: [
      {
        value: "company_name:ASC",
        label: "Name"
      },
      {
        value: "createdAt:DESC",
        label: "Most Recent"
      }
    ],
    params: {
      search: getParamsByName("search"),
      partner: getParamsByName("partner"),
      status: getParamsByName("status")
        ? getParamsByName("status")
        : Referral.TYPE_ALL,
      partnerType: getParamsByName("partnerType"),
      partnerTier: getParamsByName("partnerTier"),
      region: getParamsByName("region"),
      pageSize: getParamsByName("pageSize"),
      sort: getParamsByName("sort"),
      sortDir: getParamsByName("sortDir"),
      page: getParamsByName("page"),
      start_date: getParamsByName("start_date"),
      end_date: getParamsByName("end_date")
    },
    isCollapsed: {}
  };

  // Assigned partners checkbox toggle
  toggleChange = e => {
    this.setState({ isChecked: !this.state.isChecked });

    if (this.state.isChecked) {
      clearCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    }

    if (!this.state.isChecked) {
      clearCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
      setCookie(COOKIE_SHOW_ASSIGNED_PARTNERS, "true");
    }
  };

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this.getPartnerData();
    this.getPartnerType();
    this.getPartnerTier();
    this.getPartnerList();
    this.getSetting();
    this.getRegionList();

    // Assigned partner checkbox status
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    if (checked_status) {
      this.setState({ isChecked: !this.state.isChecked });
    }

    if (!checked_status) {
      this.setState({ isChecked: this.state.isChecked });
    }
    this.handleSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check is logged in user
    isLoggedIn();
    const status = this.props.status;
    if (prevProps && prevProps.status !== status) {
      this.setState({ status });
    }
  }

  getSetting = () => {
    getSettingsData(SETTINGS_SHOW_REGION, response => {
      if (response) {
        this.setState({
          showRegion: response.value == "true" ? true : false
        });
      }
    });
  };

  //handle search function
  handleSearch() {
    const search = window.location.search;
    const pathParams = new URLSearchParams(search);
    const searchItem = pathParams.get("search");

    if (searchItem != this.state.search) {
      this.setState({ search: searchItem });
    }
  }

  // Search
  pageSearchOnChange(event) {
    event.persist();
    const value = event.target.value;
    this.setState({ search: decodeURI(value) });
    this.doSearch(event);
  }

  doSearch = _.debounce(event => {
    this.setState(
      {
        isSearch: true,
        page: 1
      },
      () => {
        this.fetchData({ search: this.state.search });
      }
    );
  }, 500);

  handlePartnerChange = partner => {
    this.setState({ partner: partner ? partner.value : "" }, () => {
      this.fetchData({ partner: partner ? partner.value : "" });
    });
  };

  handleStatusChange = values => {
    let params = new Object();
    if (values) {
      params.status = values.value;
    } else {
      params.status = Referral.TYPE_ALL;
    }
    this.setState(params, () => {
      this.fetchData({ status: values ? values.value : "" });
    });
  };

  handletypeChange = type => {
    this.setState({ partnerType: type ? type.value : "" }, () => {
      this.fetchData({ partnerType: type ? type.value : "" });
    });
  };

  handletierChange = type => {
    this.setState({ partnerTier: type ? type.value : "" }, () => {
      this.fetchData({ partnerTier: type ? type.value : "" });
    });
  };

  handleRegionChange = value => {
    this.setState(
      { region: value && value.value ? value.value.value : "" },
      () => {
        this.fetchData({
          region: value && value.value ? value.value.value : ""
        });
      }
    );
  };
  handleStartDate = start_date => {
    this.setState(
      {
        start_date: start_date ? DateTime.toISOStringDate(start_date) : ""
      },
      () => {
        this.fetchData({
          start_date: start_date ? DateTime.formatDate(start_date) : ""
        });
      }
    );
  };

  handleEndDate = end_date => {
    this.setState(
      {
        end_date: end_date ? DateTime.toISOStringDate(end_date) : ""
      },
      () => {
        this.fetchData({
          end_date: end_date ? DateTime.formatDate(end_date) : ""
        });
      }
    );
  };
  handleSortByChange = value => {
    const valueArray = this.getSortValueFromLabel(value).split(":");
    const sort = valueArray[0];
    const sortDir = valueArray[1];
    this.setState({ sort, sortDir }, () => {
      this.fetchData({ sort: sort, sortDir: sortDir });
    });
  };

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  getSelectedSortLabel() {
    const sortByOptions = this.props.sortByOptions
      ? this.props.sortByOptions
      : this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.value === this.state.selectedSortOption
    );

    if (selectedSortOption) {
      return selectedSortOption.label;
    }

    return "";
  }
  getPartnerType = async () => {
    const response = await PartnerService.TypeList();
    this.setState({ partnerTypeOptions: response });
  };
  getRegionList = async () => {
    const response = await PartnerService.getRegion();

    this.setState({ regionList: response });
  };

  getPartnerTier = async () => {
    const response = await PartnerService.TierList();
    this.setState({ partnerTierOptions: response });
  };
  getPartnerList = async () => {
    const response = await PartnerService.List();
    this.setState({ partnerList: response });
  };

  getApiURL() {
    return `${endpoints().partnerUserReportApi}/search`;
  }

  fetchData = updateParams => {
    const params = {
      search: getParamsByName("search"),
      partner: getParamsByName("partner"),
      status: getParamsByName("status"),
      partnerType: getParamsByName("partnerType"),
      partnerTier: getParamsByName("partnerTier"),
      region: getParamsByName("region"),
      pageSize: getParamsByName("pageSize"),
      sort: getParamsByName("sort"),
      sortDir: getParamsByName("sortDir"),
      page: getParamsByName("page"),
      start_date: getParamsByName("start_date"),
      pagination: true,
      end_date: getParamsByName("end_date"),
      ...updateParams
    };

    this.setState({ params: params });

    let queryString = "";
    const queryStringArray = Object.entries(params);

    if (queryStringArray.length > 0) {
      queryString = "?";
      queryStringArray.forEach(async queryParam => {
        if (queryParam[1] !== "")
          queryString = `${queryString}&${queryParam[0]}=${queryParam[1]}`;
      });
    }

    if (this.props.history) {
      const currentPage = window.location.pathname;
      this.props.history.push(`${currentPage}${queryString}`);
    } else {
      this.props.history.push(`/reports/partner-report`);
    }

    // Get Referral list from redux table
    this.props.actions.fetchList(
      "partnerUserReport",
      this.getApiURL(),
      this.props.currentPage,
      params.pageSize ? params.pageSize : 10,
      params
    );
  };

  //get referrals data
  getPartnerData = async () => {
    const response = await apiClient.get(
      `${endpoints().partnerUserReportApi}/search`
    );
    const currentPage = response.data.currentPage;
    this.setState({ page: currentPage });
  };

  handleDeleteFilter = removedFilter => {
    this.fetchData(removedFilter);
  };

  toggleCollapse = dataListId => {
    this.setState(prevState => {
      const updatedCollapseStates = { ...prevState.isCollapsed };
      updatedCollapseStates[dataListId] = !updatedCollapseStates[dataListId];
      return { isCollapsed: updatedCollapseStates };
    });
  };

  handlePageSizeChange = e => {
    this.setState({
      params: {
        ...this.state.params,
        pageSize: e
      }
    });
    this.fetchData({ pageSize: e });
  };
  render() {
    const {
      search,
      partnerList,
      partnerTierOptions,
      partnerTypeOptions,
      params,
      statusOptions,
      regionList,
      status
    } = this.state;

    const type_id = getParamsByName("partnerType");
    const tier_id = getParamsByName("partnerTier");

    const defaultPartnerTypeId = type_id
      ? this.state.partnerTypeOptions.find(
          partnerDetail => partnerDetail.value === parseInt(type_id, 10)
        )
      : "";
    const defaultStatus = status
      ? statusOptions.find(
          partnerDetail => partnerDetail.value === parseInt(status, 10)
        )
      : "";

    const defaultPartnerTierId = tier_id
      ? this.state.partnerTierOptions.find(
          partnerDetail => partnerDetail.value === parseInt(tier_id, 10)
        )
      : "";

    const initialValues = {
      partnerType: partnerTypeOptions.find(partnerDetail =>
        partnerDetail.value == getParamsByName("partnerType")
          ? getParamsByName("partnerType")
          : ""
      ),

      partnerTier: partnerTierOptions.find(partnerDetail =>
        partnerDetail.value == getParamsByName("partnerTier")
          ? getParamsByName("partnerTier")
          : ""
      ),

      status: statusOptions.find(partnerDetail =>
        partnerDetail.value == getParamsByName("status")
          ? getParamsByName("status")
          : ""
      ),
      partner: partnerList.find(partnerDetail =>
        partnerDetail.value == getParamsByName("partner")
          ? getParamsByName("partner")
          : ""
      ),
      region: regionList.find(data =>
        data.id == getParamsByName("region") ? getParamsByName("region") : ""
      ),
      start_date: getParamsByName("start_date")
        ? getParamsByName("start_date")
        : "",
      end_date: getParamsByName("end_date") ? getParamsByName("end_date") : ""
    };

    return (
      <>
        <div className="d-flex justify-content-between  align-items-center mb-4 mt-3">
          <PageTitle label="Partner User Report" />
        </div>

        <Filter
          showHeader
          newTableHeading
          showSearch
          displayFilter
          params={params}
          sortByDropdown
          initialValues={initialValues}
          sortByOptions={this.state.sortByOptions}
          pageSearchOnChange={this.pageSearchOnChange.bind(this)}
          searchTerm={search}
          getSelectedSortLabel={this.getSelectedSortLabel()}
          handleSortByChange={this.handleSortByChange}
          showPartnerFilter
          showPartnerTierFilter
          showPartnerTypeFilter
          showPartnerStatusFilter
          handlePartnerChange={this.handlePartnerChange}
          selectedPartnerId={getParamsByName("partner")}
          handleStatusChange={this.handleStatusChange}
          partnerStatusOptions={statusOptions}
          handletypeChange={this.handletypeChange}
          defaultPartnerTypeId={defaultPartnerTypeId}
          partnerTypeOptions={this.state.partnerTypeOptions}
          defaultStatus={defaultStatus}
          handletierChange={this.handletierChange}
          defaultPartnerTierId={defaultPartnerTierId}
          partnerTierOptions={this.state.partnerTierOptions}
          partnerList={this.state.partnerList}
          handleDeleteFilter={this.handleDeleteFilter}
          showRegionFilter={this.state.showRegion}
          handleRegionChange={this.handleRegionChange}
          showDateFilter
          handleStartDate={this.handleStartDate}
          handleEndDate={this.handleEndDate}
          selectedStartDate={this.state.start_date}
          selectedEndDate={this.state.end_date}
          handlePageSizeChange={this.handlePageSizeChange}
        />

        <div className="mb-5">
          <ReduxTable
            startDate={getParamsByName("start_date")}
            endDate={getParamsByName("end_date")}
            id={"partnerUserReport"}
            apiURL={`${endpoints().partnerUserReportApi}/search`}
            params={params && { ...params }}
            searchPlaceholder="Search Partners..."
            sortByOptions={this.state.sortByOptions}
            paramsToUrl={true}
            message="You can start by inviting Partners."
            history={this.props.history}
          >
            <ReduxColumn
              minWidth="170px"
              isClickable={false}
              className="text-center  "
              field="companyName"
              sortBy="company_name"
              renderField={row => (
                <div>
                  <ReportTable
                    data={row}
                    toggleCollapse={this.toggleCollapse}
                    isCollapsed={this.state.isCollapsed}
                  />
                </div>
              )}
            >
              Name
            </ReduxColumn>
            <ReduxColumn
              minWidth="170px"
              isClickable={false}
              className="text-center"
              field="partnerCount"
              sortBy="partnerCount"
            >
              Referral Count
            </ReduxColumn>
          </ReduxTable>
        </div>
      </>
    );
  }
}
const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};
export default connect(null, mapDispatchToProps)(Report);
