import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import Form from "../../../../components/base/Form";
import Text from "../../../../components/base/Text";
import UserDropdown from "../../../components/UserDropdown";
import DatePicker from "react-date-picker";
import CancelButton from "../../../../components/base/CancelButton";
import Button from "../../../../components/base/Button";
import { getCookie } from "../../../../lib/helper";

const TaskAdd = ({
  modal,
  setModal,
  index,
  addTask,
  task_users,
  currentUserId,
  changeStatus,
  setCurrentTaskPartnerId,
  setAssignTaskPartnerId
}) => {
  const selectedDate = new Date();
  const [dueDate, setDueDate] = useState(selectedDate);
  const [input, setInput] = useState("");
  const [tempAssignee, setTempAssignee] = useState({});

  const handleInputChange = e => {
    const { value } = e.target;
    setInput(value);
  };

  const addNewTaskAssignee = (taskIndex = 0, updatedField = {}) => {
    setTempAssignee(updatedField);
  };

  const _submit = values => {
    tempAssignee &&
      tempAssignee.partnerId &&
      setCurrentTaskPartnerId(tempAssignee.partnerId);
    tempAssignee && tempAssignee.id && setAssignTaskPartnerId(tempAssignee.id);
    addTask(input, dueDate, tempAssignee);
    setModal(prevState => !prevState);
    changeStatus();
  };

  const initialValues = {
    [`task-name-${index}`]: null
  };

  useEffect(() => {
    addNewTaskAssignee();
  }, []);

  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          _submit(values);
        }}
      >
        <ModalHeader toggle={setModal}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Add New Task / Deliverable
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <Text
                id={`task-name-${index}`}
                name={`task-name-${index}`}
                label="Deliverable / Task Name"
                placeholder="Task Name"
                onChange={handleInputChange}
                required
                error=""
              />
            </div>
            <div className="grouped-fields">
              <div className="field-wrapper">
                <label>Assign to</label>
                <UserDropdown
                  currentUserId={currentUserId || getCookie("userId")}
                  taskIndex={index}
                  assignee={tempAssignee}
                  userlist={task_users}
                  updateAssignee={addNewTaskAssignee}
                />
              </div>
              <div className="field-wrapper">
                <label>Due Date</label>
                <div className="datepicker-wrapper w-100">
                  <DatePicker
                    name={`datepicker-task-${index}`}
                    className="datepicker"
                    dayPlaceholder="MM/DD/YYYY"
                    onChange={date => setDueDate(date)}
                    minDate={new Date()}
                    value={dueDate}
                    required
                    clearIcon={null}
                  />
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton
              onClick={() => {
                setModal(prevState => !prevState);
              }}
            />
            <Button
              label="Add New Task"
              type="submit"
              className="btn btn-primary"
            />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default TaskAdd;
