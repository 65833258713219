import React, { useState } from "react";

//Base Components
import Form from "../../../components/base/Form";
import PageTitle from "../../../components/base/PageTitle";
import Radio from "../../../components/base/Radio";

//Components
import DefaultContent from "../../../components/content/DefaultContent";

//SalesRouting Constants
import {
  OPPORTUNITY_OWNER_IN_APPROVAL_WORKFLOW,
  OPPORTUNITY_OWNER_TO_ACCOUNT_OWNER_FOR_EXISTING_PROSPECT,
  OPPORTUNITY_OWNER_TO_INTEGRATION_USER_FOR_EXISTING_PROSPECT,
  OPPORTUNITY_OWNER_TO_INTEGRATION_USER_FOR_NEW_PROSPECT
} from "../Constants";

const SalesRouting = props => {
  const { adminSettings, saveSettings } = props;

  // Handle Opportunity for an Existing Prospect Account
  const handleExistingSalesRouting = enable_opportunity_owner_for_existing_prospect_account => {
    const data = new FormData();

    if (enable_opportunity_owner_for_existing_prospect_account !== undefined) {
      data.append(
        "enable_opportunity_owner_for_existing_prospect_account",
        enable_opportunity_owner_for_existing_prospect_account ===
          OPPORTUNITY_OWNER_TO_ACCOUNT_OWNER_FOR_EXISTING_PROSPECT
          ? "true"
          : "false"
      );
    }

    // Save settings
    saveSettings(data);
  };

  // Saved Opportunity for an Existing Prospect Account
  const enableOpportunityForExistingProspect =
    adminSettings &&
    adminSettings.enable_opportunity_owner_for_existing_prospect_account;

  // Handle Opportunity for an New Prospect Account
  const handleNewSalesRouting = enable_opportunity_for_new_prospect_account => {
    const data = new FormData();

    if (enable_opportunity_for_new_prospect_account !== undefined) {
      data.append(
        "enable_opportunity_for_new_prospect_account",
        enable_opportunity_for_new_prospect_account ===
          OPPORTUNITY_OWNER_TO_INTEGRATION_USER_FOR_NEW_PROSPECT
          ? "true"
          : "false"
      );
    }

    // Save settings
    saveSettings(data);
  };

  // Saved Opportunity for an New Prospect Account
  const enableOpportunityForNewProspect =
    adminSettings && adminSettings.enable_opportunity_for_new_prospect_account;

  return (
    <>
      <Form
        onSubmit={values => {
          this.submit(values);
        }}
      >
        <PageTitle label="Sales Routing" />
        <DefaultContent>
          <div className="form-wrapper">
            <label className="font-weight-bold">
              When Creating an Opportunity for an Existing Prospect Account
            </label>
            <div className="field-wrapper ml-4 mb-0">
              <Radio
                name="opportunity_for_new_prospect"
                size="large"
                options={[
                  OPPORTUNITY_OWNER_TO_ACCOUNT_OWNER_FOR_EXISTING_PROSPECT,
                  OPPORTUNITY_OWNER_TO_INTEGRATION_USER_FOR_EXISTING_PROSPECT
                ]}
                onChange={e => {
                  handleExistingSalesRouting(e.target.value);
                }}
                defaultValue={
                  enableOpportunityForExistingProspect &&
                  enableOpportunityForExistingProspect === "false"
                    ? OPPORTUNITY_OWNER_TO_INTEGRATION_USER_FOR_EXISTING_PROSPECT
                    : OPPORTUNITY_OWNER_TO_ACCOUNT_OWNER_FOR_EXISTING_PROSPECT
                }
              />
            </div>
          </div>
          <div className="form-wrapper">
            <label className="font-weight-bold">
              When Creating an Opportunity for an New Prospect Account
            </label>
            <div className="field-wrapper ml-4 mb-0">
              <Radio
                name="opportunity_for_existing_prospect"
                size="large"
                options={[
                  OPPORTUNITY_OWNER_TO_INTEGRATION_USER_FOR_NEW_PROSPECT,
                  OPPORTUNITY_OWNER_IN_APPROVAL_WORKFLOW
                ]}
                onChange={e => {
                  handleNewSalesRouting(e.target.value);
                }}
                defaultValue={
                  enableOpportunityForNewProspect &&
                  enableOpportunityForNewProspect === "false"
                    ? OPPORTUNITY_OWNER_IN_APPROVAL_WORKFLOW
                    : OPPORTUNITY_OWNER_TO_INTEGRATION_USER_FOR_NEW_PROSPECT
                }
              />
            </div>
          </div>
        </DefaultContent>
      </Form>
    </>
  );
};

export default SalesRouting;
