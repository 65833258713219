import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

// Action
// import * as API from "../../../actions/userSetting";
import Form from "../../../../components/base/Form";

// Components
import DefaultContent from "../../../../components/content/DefaultContent";
import SingleCheckbox from "../../../../components/base/SingleCheckbox";
import Spinner from "../../../../components/base/Spinner";

// contstants
import Permission from "../../../../helpers/Permission";
import UserRolePermissionService from "../../../../services/UserRolePermissionService";

const UserRolePermission = props => {
  const { roleId } = props;
  const [isOpen, setIsOpen] = useState(false);
  const [currentData, setCurrentData] = useState({});
  const [permissionList, setPermissionList] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();

  const toggle = () => {
    setIsOpen(!isOpen);
    setCurrentData("");
  };

  /**
   * Create Creation
   *
   * @param values
   */
  const userRolePermissionCreate = async data => {
    dispatch(
      await UserRolePermissionService.create(data, roleId, {}, () => {})
    );

    toggle();
  };

  /**
   * Delte Creation
   *
   * @param data
   */

  useEffect(() => {
    getRolePermissions(props.roleId);
  }, []);

  // Get Role Permission
  const getRolePermissions = async roleId => {
    setIsLoading(true);
    const lists = await UserRolePermissionService.search(roleId);
    setPermissionList(lists);
    setIsLoading(false);
  };

  // handle check box value
  const handleCheckBoxValue = (value, name) => {
    // convert object to array
    let array = Object.entries(value);
    let checkBoxData = {};
    // forEach the selected permission
    array.forEach(result => {
      // if selected name is equal to field name
      if (result[0] === name) {
        // push permission name
        checkBoxData.label = result[0];
        // push permission value
        checkBoxData.value = result[1];
      }
    });
    // permission post Api
    userRolePermissionCreate(checkBoxData);
  };

  // Invitial values
  const initialValues = {
    referral_manage_others:
      permissionList &&
      permissionList.find(
        option => option.value === Permission.REFERRAL_MANAGE_OTHERS
      )
        ? true
        : false,
    enable_theme_view:
      permissionList &&
      permissionList.find(
        option => option.value === Permission.ENABLE_THEME_VIEW
      )
        ? true
        : false
  };

  // spinner
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <Form initialValues={initialValues}>
        <DefaultContent>
          {Permission.permissionList.map((role, index) => {
            return (
              <div className="card" key={index}>
                <div className="accordion-card-header d-flex justify-content-between ">
                  <h5>{role.title}</h5>
                </div>

                <div className="form-wrapper p-3">
                  {role.permission.map(permission => (
                    <div className="field-wrapper" key={permission.name}>
                      <SingleCheckbox
                        name={permission.name}
                        label={permission.label}
                        className="accepted-terms mb-1 pb-0 mr-3"
                        handleOnChangeSubmit={(value, name) =>
                          handleCheckBoxValue(value, name)
                        }
                      />
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </DefaultContent>
      </Form>
    </>
  );
};
export default UserRolePermission;
