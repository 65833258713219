import React from "react";
import { endpoints } from "../../../../configs";
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";

const CompanyTab = props => {
  const sortOptions = [
    {
      value: "domain:ASC",
      label: "Name"
    },
    {
      value: "createdAt:DESC",
      label: "Most Recent"
    }
  ];

  return (
    <>
      <div className="p-0 col-12 col-md-12">
        <ReduxTable
          id="intentData"
          newTableHeading
          showHeader
          noRecordFoundHeight={"7vh"}
          apiURL={`${endpoints().intentDataAPI}`}
          searchPlaceholder="Search"
          paramsToUrl={true}
          history={props.history}
          sortByOptions={sortOptions}
        >
          <ReduxColumn width="150px" field="domain" sortBy="domain">
            Domain
          </ReduxColumn>

          <ReduxColumn
            width="150px"
            field="product"
            sortBy="product"
            isClickable="true"
          >
            Product
          </ReduxColumn>
          <ReduxColumn
            width="150px"
            field="vendor"
            sortBy="vendor"
            isClickable="true"
          >
            Vendor
          </ReduxColumn>
          <ReduxColumn
            width="150px"
            field="category"
            sortBy="category"
            isClickable="true"
          >
            Category
          </ReduxColumn>
          <ReduxColumn
            width="150px"
            minWidth="200px"
            maxWidth="300px"
            field="subCategory"
            sortBy="subCategory"
            isClickable="true"
          >
            Sub Category
          </ReduxColumn>
          <ReduxColumn
            width="150px"
            minWidth="500px"
            maxWidth="600px"
            field="description"
            sortBy="description"
            isClickable="true"
          >
            Description
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};

export default CompanyTab;
