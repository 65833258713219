import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  setExpertBadge,
  isCompanyManager,
  isPartner
} from "../../../lib/helper";
// Constants
import { MDF_STATUS_DECLINED, MDF_PARTNER_STATUS_ACCEPTED } from "../Constants";

// Components
import { Badge } from "reactstrap";
import SidebarItem from "./SidebarItem";
import Avatar from "../../../components/base/Avatar";
import Spinner from "../../../components/base/Spinner";

// Configs
import { endpoints } from "../../../configs";

// API Client
import { apiClient } from "../../../apiClient";
import { isBadRequest } from "../../../common/http";
import { useSelector } from "react-redux";

const PartnerSection = ({
  mdfPartnerList,
  currentUserId,
  onSourcePartner,
  isPartnerLoading,
  status,
  partnerId
}) => {
  const [partnerDetails, setPartnerDetails] = useState([""]);
  const [partnerList, setPartnerList] = useState([]);
  const currentUser = useSelector(state => state.user);

  const spinnerStyle = `margin: 0;  position: absolute;  top: 50%;  left: 35%;`;

  // Get Partner Details
  const _getPartnerDetails = () => {
    return apiClient
      .get(`${endpoints().partnerAPI}`)
      .then(response => {
        setPartnerDetails(response.data && response.data);

        this.setState({
          partnerDetails: {
            ...response.data
          }
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
        }
      });
  };

  // getPartnerDetails
  const getPartnerDetailsById = partnerId => {
    apiClient
      .get(`${endpoints().partnerAPI}/?id=${partnerId}`)
      .then(response => {
        if (response && response.data) {
          setPartnerList(response.data);
        }
      });
  };

  useEffect(() => {
    _getPartnerDetails();
    getPartnerDetailsById(partnerId);
  }, []);
  let details = [];
  if (partnerList && partnerList.companyName) {
    details.push({
      partnerId: partnerList.id || "",
      companyName: partnerList.companyName || "",
      status: partnerList.status || "",
      avatarUrl: partnerList.avatarUrl || "",
      profileUrl: partnerList.companyProfileUrl || ""
    });
  }

  const partner = partnerDetails ? partnerDetails : "";
  const list = details && details.length > 0 ? details : mdfPartnerList;

  return (
    <>
      <SidebarItem>
        <p className="font-weight-bold">Partner</p>
        {!list && isPartnerLoading ? (
          <div
            style={{
              position: "relative",
              height: "100px"
            }}
          >
            <Spinner spinnerStyle={spinnerStyle} />
          </div>
        ) : (
          <>
            {!isPartner(currentUser && currentUser.roleId) &&
            list &&
            list.length > 0 ? (
              list.map(partner => (
                <>
                  {partner.userId === currentUserId &&
                  partner.status &&
                  partner.status !== MDF_PARTNER_STATUS_ACCEPTED ? (
                    list.length === 1 ? (
                      <span className="text-grayed">
                        No partner assigned yet
                      </span>
                    ) : (
                      ""
                    )
                  ) : (
                    <div className="field-wrapper">
                      <div className="user-wrapper">
                        <div className="field-icon">
                          <Link
                            target="_blank"
                            to={`/partner-profile/${partner.profileUrl}`}
                          >
                            <LazyLoad>
                              <Avatar
                                firstName={partner.companyName}
                                size="xs"
                                fontSize={12}
                                url={partner.avatarUrl}
                              />
                            </LazyLoad>
                          </Link>
                        </div>

                        <div className="expert-info ml-2 d-flex flex-column align-items-start">
                          <p className="font-weight-bold">
                            <Link
                              target="_blank"
                              to={`/partner-profile/${partner.profileUrl}`}
                            >
                              {partner.companyName}
                            </Link>
                          </p>

                          {partner.status &&
                            partner.status !== MDF_PARTNER_STATUS_ACCEPTED && (
                              <div
                                className={[
                                  "status-tooltips d-flex mt-2",
                                  `${partner.status.toLowerCase() ===
                                    "no response" && "align-items-start"}`
                                ].join(" ")}
                              >
                                <Badge
                                  id={`status-tooltip-${partner.partnerId}`}
                                  color={setExpertBadge(partner.status)}
                                  className="text-white h7 font-weight-bold text-uppercase"
                                >
                                  {partner.status}
                                </Badge>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))
            ) : isPartnerLoading ? (
              "Loading..."
            ) : (
              <>
                {isPartner(currentUser && currentUser.roleId) ? (
                  <>
                    {partner.userId === currentUserId &&
                    partner.status &&
                    partner.status !== MDF_PARTNER_STATUS_ACCEPTED ? (
                      list.length === 1 ? (
                        <span className="text-grayed">
                          No partner assigned yet
                        </span>
                      ) : (
                        ""
                      )
                    ) : (
                      <div className="field-wrapper">
                        <div className="user-wrapper">
                          <div className="field-icon">
                            <Link
                              target="_blank"
                              to={`/partner-profile/${partner.profileUrl}`}
                            >
                              <LazyLoad>
                                <Avatar
                                  firstName={partner.companyName}
                                  size="xs"
                                  fontSize={12}
                                  url={partner.avatarUrl}
                                />
                              </LazyLoad>
                            </Link>
                          </div>

                          <div className="expert-info ml-2 d-flex flex-column align-items-start">
                            <p className="font-weight-bold">
                              <Link
                                target="_blank"
                                to={`/partner-profile/${partner.profileUrl}`}
                              >
                                {partner.companyName}
                              </Link>
                            </p>

                            {partner.status &&
                              partner.status !==
                                MDF_PARTNER_STATUS_ACCEPTED && (
                                <div
                                  className={[
                                    "status-tooltips d-flex mt-2",
                                    `${partner.status.toLowerCase() ===
                                      "no response" && "align-items-start"}`
                                  ].join(" ")}
                                >
                                  <Badge
                                    id={`status-tooltip-${partner.partnerId}`}
                                    color={setExpertBadge(partner.status)}
                                    className="text-white h7 font-weight-bold text-uppercase"
                                  >
                                    {partner.status}
                                  </Badge>
                                </div>
                              )}
                          </div>
                        </div>
                      </div>
                    )}
                  </>
                ) : (
                  <span className="text-grayed">No partner assigned yet</span>
                )}
              </>
            )}
            {(isSuperAdmin(currentUser && currentUser.roleId) ||
              isCompanyAdmin(currentUser && currentUser.roleId) ||
              isCompanyManager(currentUser && currentUser.roleId)) &&
              list && (
                <button
                  id={
                    !list.length
                      ? "assignPartnerButton"
                      : "reassignPartnerButton"
                  }
                  className="btn btn-primary w-100 mt-2"
                  type="button"
                  onClick={onSourcePartner}
                  disabled={status === MDF_STATUS_DECLINED ? true : false}
                >
                  {!list.length ? "Select the" : "Re-Select the"}
                  {""} Partner
                </button>
              )}
          </>
        )}
      </SidebarItem>
    </>
  );
};

export default PartnerSection;
