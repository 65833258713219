import React from "react";
import AccountCard from "../../../components/base/AccountCard";
import Phone from "../../../components/base/Phone";
import Email from "../../../components/base/Email";

const AccountDetail = props => {
  const { accountDetails } = props;

  return (
    <div>
      <div className="card-body pt-3 ml-3">
        <h4 className="font-weight-bold mt-4">Account Details</h4>
        <div className="font-weight-bold mt-4">
          <AccountCard
            firstName={accountDetails && accountDetails.firstName}
            lastName={accountDetails && accountDetails.lastName}
          />
        </div>

        <div className="ml-5 pl-2">
          {accountDetails && accountDetails.title && (
            <div className="font-weight-bold">
              <span className="text-secondary ml-2">{`  ${
                accountDetails && accountDetails.title
                  ? accountDetails.title
                  : ""
              }`}</span>
            </div>
          )}

          {accountDetails && accountDetails.email && (
            <div className="font-weight-bold mt-2 ml-2">
              <Email
                readOnly={true}
                Email={accountDetails.email}
                className={["text-secondary", "font-weight-bold"].join(" ")}
              />
            </div>
          )}

          {accountDetails && accountDetails.phone && (
            <div className="font-weight-bold mt-2 ml-2">
              <Phone
                readOnly={true}
                phone={accountDetails.phone}
                className={["text-secondary", "font-weight-bold"].join(" ")}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default AccountDetail;
