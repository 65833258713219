import classnames from "classnames";
import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Constants
import { TAB_THEME, THEME_EMAIL_SUB_TAB } from "../../../../setting/Constants";

// Sub Tab  Sections
import EmailSection from "./EmailSection";

const ThemeTab = props => {
  const {
    activeSubTab,
    adminSettings,
    saveSettings,
    toggleTabs,
    history
  } = props;

  return (
    <>
      <Nav tabs className="admin-tabs">
        {/* General Tab */}

        {/* Email Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === THEME_EMAIL_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_THEME, THEME_EMAIL_SUB_TAB);
            }}
          >
            {THEME_EMAIL_SUB_TAB}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeSubTab}>
        {/* Email Tab */}
        <TabPane tabId={THEME_EMAIL_SUB_TAB} className="w-100">
          <EmailSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default ThemeTab;
