import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//Components
import VideoCarousel from "../../components/base/videoCarousel/VideoCarousel.js";
import NoRecordsFound from "../../components/base/NoRecordsFound";
import PageTitle from "../../components/base/PageTitle";
import Text from "../../components/base/Text";
import Form from "../../components/base/Form";

//Constant
import { MEDIA_APP_VIDEO } from "./Constant";

const VideoItem = props => {
  // initilize states
  const [isOpen, setIsOpen] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [url, setUrl] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFileExist, setIsFileExist] = useState(false);

  const {
    videoUpload,
    videos,
    handleVideoDelete,
    closeVideoModal,
    openVideoModal
  } = props;

  //Image Remove
  const videoRemoveHandler = video => {
    handleVideoDelete(video);
  };

  // Toggle Create modal
  const _createModaltoggle = () => {
    setTitle("");
    setDescription("");
    setUrl("");
    setFile("");
    setIsFileExist(false);
    setFileName("");
    setIsOpen(!isOpen);
  };

  //Set Media
  const media = e => {
    const data = e.target.files ? e.target.files[0] : "";
    setFile(data);
    setFileName(data && data.name);
    setIsFileExist(false);
  };

  //Upload File
  const upload = () => {
    const data = {};
    if (!file && !fileName) {
      setIsFileExist(true);
    } else {
      data.title = title;
      data.description = description;
      data.url = url;
      data.file = file;
      data.fileName = fileName;
      data.object = MEDIA_APP_VIDEO;
      videoUpload && videoUpload(data);
      setFile("");
    }
  };
  const openAddModal = () => {
    setTitle("");
    setDescription("");
    setUrl("");
    setFile("");
    setFileName("");
    openVideoModal();
    _createModaltoggle();
  };

  const handleClick = e => {
    upload(e);
  };

  if (closeVideoModal === true && isOpen === true) {
    _createModaltoggle();
  }

  return (
    <>
      <div>
        <Modal
          isOpen={isOpen}
          toggle={_createModaltoggle}
          className="landing-create-popup w-100"
          backdrop="static"
        >
          <ModalHeader
            toggle={_createModaltoggle}
            cssModule={{ "modal-title": "w-100 text-center" }}
          >
            <p>Add Video Items</p>
          </ModalHeader>
          <Form>
            <ModalBody className="ml-3 mr-3 mt-2 mb-3">
              <div>
                <Text
                  name="videoUrl"
                  label="URL of Existing Multimedia"
                  placeholder="Enter video player URL"
                  error=""
                  onChange={e => {
                    setUrl(e.target.value);
                  }}
                />
              </div>
              <div>
                <Text
                  name="videoTitle"
                  label="Title"
                  placeholder="Enter title"
                  error=""
                  onChange={e => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div>
                <Text
                  name="videoDescription"
                  label="Description"
                  placeholder="Enter Description"
                  error=""
                  onChange={e => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className="">
                <input
                  name="file"
                  className="form-control d-none"
                  type="file"
                  id="video"
                  onChange={e => {
                    media && media(e);
                  }}
                  accept="video/mp4, video/ogg, video/WebM video/mp4 video/mov video/flv video/mkv video/WebM video/WMV"
                />
                <span className="profilePicOverlay d-block ">
                  <label htmlFor="video" className="profile-img-sm mb-0">
                    <span className="banner-image-upload-link">
                      Add Multimedia
                    </span>
                    <span className="text-danger ml-1">*</span>
                  </label>
                  {/* Show image Name */}
                  {file && fileName && <span className="pl-3">{fileName}</span>}
                </span>
                {isFileExist && (
                  <small className={`text-danger position-absolute `}>
                    Multimedia is required
                  </small>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="container-fluid">
                <div className="col-sm-12 text-center">
                  <Button
                    label=""
                    onClick={e => {
                      handleClick(e);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
      <div>
        <PageTitle
          label="Video Item"
          buttonHandler={() => {
            openAddModal();
          }}
          buttonLabel="Add Video"
        />
        {videos && videos.length > 0 ? (
          <div className="d-block ">
            <VideoCarousel
              videos={videos}
              showRemove={true}
              appStyle={true}
              handleVideoDelete={videoRemoveHandler}
              //editProfile
              style={{
                width: "100%",
                height: "140px",
                objectFit: "scale-down"
              }}
              slides={2}
            />
          </div>
        ) : (
          <NoRecordsFound
            showMessage={true}
            hideCard={true}
            message="No Videos Found"
            position="top"
          />
        )}
        {/* <SimpleSlider /> */}
      </div>
    </>
  );
};
export default VideoItem;
