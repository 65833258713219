import React, { Component } from "react";
import toast from "../../../../components/base/Toast";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

import classnames from "classnames";

// // Sub Tab  Sections
import GeneralSection from "./GeneralSection";
import UrlSection from "./UrlSection";

// Constants
import { GENERAL_GENERAL_SUB_TAB } from "../../../..//setting/Constants";

// Constants
import {
  SYSTEM_TAB,
  SYSTEM_URL_SUB_TAB
} from "../../../../supportPortal/Constants";

// API call
import { apiClient } from "../../../../apiClient";

// Configs
import { endpoints } from "../../../../configs";

// 404 Page
import { isBadRequest } from "../../../../common/http";

class SystemTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsData: "",
      isLoading: false,
      isSettingsLoading: true,
      isAccountManagerListLoading: true
    };
  }

  // Settings API post
  _createSettings = data => {
    return apiClient
      .post(`${endpoints().systemSettingAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success("Settings saved successfully");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.stringify(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  render() {
    const {
      activeSubTab,
      adminSettings,
      saveSettings,
      toggleTabs,
      history
    } = this.props;
    return (
      <>
        <Nav tabs className="admin-tabs">
          {/* General Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === GENERAL_GENERAL_SUB_TAB
              })}
              onClick={() => {
                toggleTabs(SYSTEM_TAB, GENERAL_GENERAL_SUB_TAB);
              }}
            >
              {GENERAL_GENERAL_SUB_TAB}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === SYSTEM_URL_SUB_TAB
              })}
              onClick={() => {
                toggleTabs(SYSTEM_TAB, SYSTEM_URL_SUB_TAB);
              }}
            >
              {SYSTEM_URL_SUB_TAB}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeSubTab}>
          {/* General Tab */}
          <TabPane tabId={GENERAL_GENERAL_SUB_TAB} className="w-100">
            <GeneralSection
              adminSettings={adminSettings}
              saveSettings={this._createSettings}
              history={history}
            />
          </TabPane>
          {/* Url Tab */}
          <TabPane tabId={SYSTEM_URL_SUB_TAB} className="w-100">
            <UrlSection
              adminSettings={adminSettings}
              saveSettings={saveSettings}
              history={history}
            />
          </TabPane>
        </TabContent>
      </>
    );
  }
}

export default SystemTab;
