// Project Status Starts
export const PROJECT_STATUS_NEW = "New";
export const PROJECT_STATUS_DRAFT = "Draft";
export const PROJECT_STATUS_REQUESTED = "Requested";
export const PROJECT_STATUS_MATCHING = "Matching";
export const PROJECT_STATUS_INTERVIEWING = "Interviewing";
export const PROJECT_STATUS_INPROCESS = "In Process";
export const PROJECT_STATUS_APPROVED = "Approved";
export const PROJECT_STATUS_PENDING = "Pending";
export const PROJECT_STATUS_REJECTED = "Rejected";
export const PROJECT_STATUS_ACTIVE = "Active";
export const PROJECT_STATUS_COMPLETED = "Completed";
export const PROJECT_STATUS_SOURCING = "Sourcing";
// Project Status Ends

// Project Tab Starts
export const TAB_PROJECT_DRAFT = "1";
export const TAB_PROJECT_NEW = "2";
export const TAB_PROJECT_ACTIVE = "3";
export const TAB_PROJECT_COMPLETED = "4";
// Project Tab Ends
