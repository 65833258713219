import React from "react";
import { Table } from "reactstrap";
import toast from "../../../../components/base/Toast";

// Configs
import { apiClient } from "../../../../apiClient";
import { endpoints } from "../../../../configs";
import { isBadRequest } from "../../../../common/http";
import { Link } from "react-router-dom";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

// Handle job run
const handleJobRun = async endPoint => {
  try {
    const response = await apiClient.put(endPoint);
    if (response && response.data) {
      toast.success(response.data.message);
    }
  } catch (error) {
    if (isBadRequest(error)) {
      toast.error(error.response.data.message);
    }
  }
};

const DefaultSection = () => {
  return (
    <div className="marketplace-section-content">
      <Table className="table-hover">
        <thead className="thead-dark table-bordered">
          <tr>
            <th>Name</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {/* Crossbeam jobs start*/}
          <tr>
            <td>Crossbeam: Sync Populations</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().crossbeamAPI}/job/populations`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          <tr>
            <td>Crossbeam: Sync Partners</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().crossbeamAPI}/job/partners`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          <tr>
            <td>Crossbeam: Partner Populations</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${endpoints().crossbeamAPI}/job/partner/populations`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          <tr>
            <td>Crossbeam: Sync Partner Overlap</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${endpoints().crossbeamAPI}/job/partner/overlap`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          <tr>
            <td>Crossbeam: Update Snooze</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${
                      endpoints().crossbeamPartnerAPI
                    }/job/partner/statusUpdate`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          {/* Crossbeam jobs end*/}

          {/* Company  jobs start */}
          <tr>
            <td>Company: Create default tag types</td>
            <td>
              <Link
                onClick={() =>
                  handleJobRun(`${endpoints().tagTypeAPI}/job/create`)
                }
              >
                Run
              </Link>
            </td>
          </tr>

          {/* Create Company Record for Partners*/}
          <tr>
            <td>Company: Create company record for partners </td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().companyAPI}/job/createCompany`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/* Deal Reindex */}
          <tr>
            <td>Deal: Reindex</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().dealsAPI}/job/reindex/all`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          {/* Company  jobs end */}
          <tr>
            <td>Expert Profile: Update Completion Percentage</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${
                      endpoints().expertAPI
                    }/job/profileCompletionPercentageUpdate`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          <tr>
            <td>Expert: Reindex</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().expertAPI}/job/reindex/all`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          <tr>
            <td>Google: Mail Activities </td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().googleApi}/mail/job`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          {/* Closed Referral Invoice Create*/}
          <tr>
            <td>
              Invoice: Create for Closed {Terminology.get(SystemName.REFERRALS)}
            </td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${endpoints().referralApi}/job/createInvoice/all`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/* Partner Jobs Start */}
          <tr>
            <td>Partner: Add default partner type</td>
            <td>
              <Link
                onClick={() =>
                  handleJobRun(`${endpoints().partnerTypeAPI}/job/create`)
                }
              >
                Run
              </Link>
            </td>
          </tr>
          <tr>
            <td>Partner: Add default partner tier</td>
            <td>
              <Link
                onClick={() =>
                  handleJobRun(`${endpoints().partnerTierAPI}/job/create`)
                }
              >
                Run
              </Link>
            </td>
          </tr>

          <tr>
            <td>Partner Profile: Update Completion Percentage</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${
                      endpoints().partnerAPI
                    }/job/profileCompletionPercentageUpdate`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          {/* Set Partner Profile Visibility true */}
          <tr>
            <td>Partner Profile: Set Public Visibility</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${
                      endpoints().partnerAPI
                    }/job/partner/setProfileVisibility/`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          {/* Partner Reindex */}
          <tr>
            <td>Partner: Reindex</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().partnerAPI}/job/reindex/all`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/* Partner Profile URL Generate Job */}
          <tr>
            <td>Partner: Set Profile URL</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${endpoints().partnerAPI}/job/generateProfileUrl/all`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          {/* Partner Jobs End */}

          <tr>
            <td>Partner Profile: Sync from Salesforce</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${endpoints().partnerAPI}/job/sync/partnerAccount`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/* Create Partner Record for Company*/}
          <tr>
            <td>Partner: Create partner record for companies </td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().partnerAPI}/job/createPartner`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/*Update Partner Account Record from Partner Profile*/}
          <tr>
            <td>
              Account: Update prospect account record from{" "}
              {Terminology.get(SystemName.REFERRAL)}
            </td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${endpoints().partnerAPI}/job/updateProspectAccount`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/*Update Partner Account Record from Partner Profile*/}
          <tr>
            <td>Account: Update partner account record from partner profile</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().partnerAPI}/job/updateAccount`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/* Update company partner relationship*/}
          <tr>
            <td>Partner: Update partner company</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${endpoints().partnerAPI}/job/createCompanyPartner`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/* Update company id in partner_profile_custom_field_value table*/}
          <tr>
            <td>
              Partner: Update company id in partner_profile_custom_field_value
              table
            </td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${endpoints().partnerAPI}/job/updateCustomFieldValue`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/* Project Reindex */}
          <tr>
            <td>Project: Reindex</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().projectAPI}/job/reindex/all`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/* Referral Reindex*/}
          <tr>
            <td>Referral: Reindex</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().referralApi}/job/reindex/all`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/* set customer readiness referral*/}
          <tr>
            <td>Referral: Set Customer Readiness</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${endpoints().referralApi}/job/referral/customerReadiness`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          {/* Salesforce jobs start*/}
          <tr>
            <td>Salesforce: Sync Opportunities</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${endpoints().referralApi}/job/sync/opportunities`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          <tr>
            <td>Salesforce: Sync Users</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().salesforceAPI}/job/sync/users`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          <tr>
            <td>Salesforce: Sync Accounts</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().salesforceAPI}/job/sync/account`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>

          <tr>
            <td>Salesforce: Sync Opportunity Owner</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(`${endpoints().salesforceAPI}/job/sync/owner`)
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
          {/* Salesforce jobs end*/}

          <tr>
            <td>Salesforce: Snapshot Opportunities</td>
            <td>
              <span
                onClick={() =>
                  handleJobRun(
                    `${endpoints().salesforceAPI}/job/opportunitySnapshot`
                  )
                }
                className="banner-image-upload-link"
              >
                Run
              </span>
            </td>
          </tr>
        </tbody>
      </Table>
    </div>
  );
};

export default DefaultSection;
