import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownItem } from "reactstrap";

// Components
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import MoreDropdown from "../../../../components/base/MoreDropdown";
import AddButton from "../../../../components/base/AddButton";
import Form from "../../../../components/base/Form";
import PageTitle from "../../../../components/base/PageTitle";
import Text from "../../../../components/Text";
import DeleteModal from "../../../../components/base/DeleteModal";
// Helper
import { toString } from "../../../../lib/helper";

// API call
import { apiClient } from "../../../../apiClient";

// Configs
import { endpoints } from "../../../../configs";

// Tags
import { deleteTag, addTag, updateTag } from "../../../../actions/tags";
import { fetchList } from "../../../../actions/table";

// Constant
import { TAG_TYPE_TRAINING_CATEGORY } from "../../../../tagType/Constants";
import { isBadRequest } from "../../../../common/http";

class TrainingCategory extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values.
    this.state = {
      isLoading: false,
      isOpen: false,
      id: 0,
      name: "",
      deleteId: "",
      type: "",
      typeId: null,
      projectCategory: "",
      deleteCategoryModal: false,
      projectCategoryId: null,
      isSearchable: "true",
      tagTypeDetails: [],
      projectCategoryDetails: [],
      tagTypeFilter: "",
      tagTypeFilterId: null
    };

    this.handleDelete = this.handleDelete.bind(this);
  }
  deleteModal = row => {
    this.setState({
      deleteId: row.id,
      name: row.name
    });
    this.setState({
      deleteCategoryModal: true
    });
  };

  /**
   *
   * @param id
   */
  handleDelete(id) {
    this.props.actions.deleteTag(
      id,
      {
        tagType: TAG_TYPE_TRAINING_CATEGORY || ""
      },
      "tags"
    );
  }

  /**
   * Create tag
   *
   * @param data
   */
  tagCreate = data => {
    this.props.actions.addTag(
      data,
      {
        tagType: TAG_TYPE_TRAINING_CATEGORY || ""
      },
      "tags",
      this._toggle
    );
    this.getTagTypeData();
  };

  /**
   * Update tag
   *
   * @param data
   * @param id
   */
  tagUpdate = data => {
    this.props.actions.updateTag(
      this.state.id,
      data,
      {
        tagType: TAG_TYPE_TRAINING_CATEGORY || ""
      },
      "tags",
      this._toggle
    );
  };

  _toggle = tagId => {
    this.setState({ isOpen: !this.state.isOpen, id: tagId || 0 });
  };

  componentDidMount = () => {
    const tagTypeFilter = new URLSearchParams(
      this.props.history.location.search
    ).get("tag_type_filter");

    this.setState({ tagTypeFilter });

    this.getTagTypeData();
    this.getProjectCategory();
  };

  // Get Tag Type Data
  getTagTypeData = () => {
    this.setState({ isLoading: true }, () => {
      return apiClient
        .get(`${endpoints().tagTypeAPI}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);

          const tagTypeDetails = response.data.data || [];

          const tagTypeFilterId = tagTypeDetails.find(
            type => type.name === TAG_TYPE_TRAINING_CATEGORY
          );

          this.setState({
            tagTypeDetails,
            tagTypeFilterId: tagTypeFilterId ? tagTypeFilterId.id : null
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  };

  // Get Project Category Data
  getProjectCategory = () => {
    this.setState({ isLoading: true }, () => {
      return apiClient
        .get(`${endpoints().projectCategoryAPI}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);

          this.setState({ projectCategoryDetails: response.data.data });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  };

  // Tag type options
  tagTypeOptions = () => {
    const { tagTypeDetails } = this.state;
    return (
      tagTypeDetails &&
      tagTypeDetails.map(tagType => ({
        label: tagType.name,
        value: tagType.id
      }))
    );
  };

  // Project category options
  projectCategoryOptions = () => {
    const { projectCategoryDetails } = this.state;
    return (
      projectCategoryDetails &&
      projectCategoryDetails.map(projectCategory => ({
        label: projectCategory.name,
        value: projectCategory.id
      }))
    );
  };

  // Render the tag modal
  tagModal = () => (
    <div>
      <Modal
        isOpen={this.state.isOpen}
        toggle={this._toggle}
        className="landing-create-popup w-100"
        backdrop="static"
      >
        <ModalHeader
          toggle={this._toggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <p>
            {this.state.id ? "Edit Training Category" : "Add Training Category"}
          </p>
        </ModalHeader>
        <Form
          initialValues={{
            name: this.state.name || "",
            projectCategory: this.state.projectCategoryId
              ? {
                  value: this.state.projectCategoryId,
                  label: this.state.projectCategory
                }
              : "",
            type:
              this.state.typeId || this.state.tagTypeFilter
                ? {
                    value: this.state.typeId || this.state.tagTypeFilterId,
                    label: this.state.type || this.state.tagTypeFilter
                  }
                : "",
            isSearchable: false
          }}
          onSubmit={values => {
            values.name = toString(values.name).trim();
            values.type = this.state.tagTypeFilterId
              ? this.state.tagTypeFilterId
              : TAG_TYPE_TRAINING_CATEGORY;
            values.projectCategory = toString(
              values.projectCategory ? values.projectCategory.value : ""
            );
            this.state.id ? this.tagUpdate(values) : this.tagCreate(values);
          }}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div>
              <Text
                name="name"
                label="Name"
                placeholder="Enter name"
                error=""
                required={true}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col text-center">
                <Button type="submit" label="">
                  {this.state.id ? "Save" : "Add"}
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );

  render() {
    const { deleteCategoryModal } = this.state;

    return (
      <div>
        <DeleteModal
          isOpen={deleteCategoryModal}
          toggle={() => {
            this.setState({ deleteCategoryModal: false });
          }}
          title="Delete Category"
          label={this.state.name}
          deleteFunction={() => this.handleDelete(this.state.deleteId)}
        />
        <div className="d-flex align-items-center mt-3 mb-n3">
          <div className="col-12">
            <AddButton
              label="Add New"
              className="pull-right btn btn-secondary"
              onClick={() => {
                this.setState({
                  name: "",
                  projectCategory: "",
                  projectCategoryId: null,
                  type: "",
                  typeId: null
                });
                this._toggle();
              }}
            />
            {this.tagModal()}
          </div>
        </div>
        <ReduxTable
          id="tags"
          apiURL={`${endpoints().tagAPI}`}
          params={{ tagType: TAG_TYPE_TRAINING_CATEGORY }}
          onRowClick={row => {
            this.setState({
              name: row.name,
              projectCategory: row.projectCategory,
              projectCategoryId: row.projectCategoryId,
              isSearchable: row.isSearchable === "Yes" ? true : false,
              type: row.type,
              typeId: row.typeId
            });
            return this._toggle(row.id);
          }}
          showHeader
          searchDisabled
        >
          <ReduxColumn
            width={"510px"}
            type="link"
            isClickable="true"
            field="name"
          >
            Name
          </ReduxColumn>
          <ReduxColumn
            width={"100px"}
            field="status"
            disableOnClick
            className="action-column"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      this.setState({
                        name: row.name,
                        projectCategory: row.projectCategory,
                        projectCategoryId: row.projectCategoryId,
                        type: row.type,
                        typeId: row.typeId
                      });
                      return this._toggle(row.id);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => this.deleteModal(row)}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { deleteTag, addTag, updateTag, fetchList },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(TrainingCategory);
