import React from "react";
import toast from "../../../../components/base/Toast";
import ContactFields from "./inner-components/ContactFields";

// Components
import Form from "../../../../components/base/Form";
import DeleteButton from "../../../../components/base/DeleteButton";
import CancelButton from "../../../../components/base/CancelButton";
import AdminActionNavigation from "../../../../components/base/AdminActionNavigation";
import { ChevronRight } from "../../../../assets/img/icons";
import Page404 from "../../../Page404";

// constants
import { THEME_LAYOUT_FLUID_WIDTH } from "../../../../setting/Constants";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

// Helper
import {
  removeMaskedPhoneNumber,
  isSuperAdmin,
  isCompanyAdmin
} from "../../../../lib/helper";
import { isBadRequest } from "../../../../common/http";
import SideNavBar from "../../inner-component/SideNavBar";
import { Row } from "reactstrap";
import { TAB_SALESFORCE } from "../../../../supportPortal/Constants";
import { connect } from "react-redux";

class ContactDetails extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      contactDetails: [],
      loading: false,
      isInvalidRequest: false
    };
  }

  componentDidMount = () => {
    this._getContactDetails();
  };

  // Contact delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().salesforceLookupAPI}/contact/${this.props.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;

          toast.success(successMessage);

          this.props.history.push("/support-portal/Salesforce/contact");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Contact details api call by id
  _getContactDetails = () => {
    return apiClient
      .get(
        `${endpoints().salesforceLookupAPI}/contact/${
          this.props.match.params.id
        }`
      )
      .then(response => {
        this.setState({ contactDetails: response.data, loading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId)
    ) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return "";
    }

    const {
      Id,
      Name,
      FirstName,
      LastName,
      Phone,
      Email,
      OwnerId,
      CreatedById,
      IsDeleted,
      Description,
      CreatedDate,
      LastModifiedDate,
      AccountId
    } = this.state.contactDetails;

    const initialValues = {
      salesforceId: Id,
      name: Name,
      firstName: FirstName,
      lastName: LastName,
      email: Email,
      phone: Phone,
      ownerId: OwnerId,
      accountId: AccountId,
      createdDate: CreatedDate,
      createdById: CreatedById,
      isDeleted: IsDeleted,
      description: Description,
      lastModifiedDate: LastModifiedDate
    };

    return (
      <>
        <Row className="mt-n3 mt-md-n4 admin-setting">
          <div
            className={`${
              THEME_LAYOUT_FLUID_WIDTH
                ? " col-lg-3 col-sm-5 sidemenu p-0"
                : " secondary-menu col-md-3"
            }`}
          >
            <SideNavBar activeTab={TAB_SALESFORCE} handleClick={() => {}} />
          </div>
          <div className={`${THEME_LAYOUT_FLUID_WIDTH ? "col" : "col-md-9"}`}>
            <Form
              initialValues={initialValues}
              onSubmit={values => {
                values.phone =
                  values.phone && removeMaskedPhoneNumber(values.phone);
              }}
            >
              <AdminActionNavigation>
                <div className="d-flex align-items-center mt-3">
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      this.props.history.goBack(
                        "/support-portal/salesforce/contact"
                      );
                    }}
                  >
                    Salesforce Contact
                  </a>
                  <span
                    className="text-dark d-inline-flex align-items-center"
                    style={{ width: "15px" }}
                  >
                    <ChevronRight />
                  </span>
                  <span
                    onClick={e => e.preventDefault()}
                    className={[
                      "text-dark",
                      "font-weight-bold",
                      "d-inline-flex",
                      "align-items-center",
                      "project-name",
                      "w-320"
                    ].join(" ")}
                  >
                    <span className="ellipsis">{Id || "Contact Details"}</span>
                  </span>
                </div>

                <CancelButton
                  className="ml-auto mr-1 mt-4"
                  onClick={() => this.props.history.goBack()}
                />

                <DeleteButton
                  className="mt-4"
                  label={"Delete Contact"}
                  onClick={this.handleDelete}
                />
              </AdminActionNavigation>
              <ContactFields contactDetails={this.state.contactDetails} />
            </Form>
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(ContactDetails);
