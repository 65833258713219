export const PARTNER_TYPE_SETTING_ENABLE_ACCOUNT_MAPPING = "accountMapping";
export const PARTNER_TYPE_SETTING_ENABLE_CO_MARKETING_CAMPAIGNS =
  "coMarketingCampaigns";
export const PARTNER_TYPE_SETTING_ENABLE_LEAD_DISTRIBUTION = "leadDistribution";
export const PARTNER_TYPE_SETTING_ENABLE_MDF = "mdf";
export const PARTNER_TYPE_SETTING_ENABLE_PARTNER_RESOURCES = "partnerResources";
export const PARTNER_TYPE_SETTING_ENABLE_PARTNERSHIP_WITH_INDIVIDUAL_PERSON =
  "partnerShipWithIndividualPerson";
export const PARTNER_TYPE_SETTING_ENABLE_REFERRALS_DEAL_REGISTRATION =
  "referralsDealRegistration";
export const PARTNER_TYPE_SETTING_ENABLE_TEAM_MANAGEMENT = "teamManagement";
