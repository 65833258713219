import { apiClient } from "../apiClient";
import { endpoints } from "../configs";

import {
  REQUEST_USER_DETAIL,
  RECEIVE_USER_DETAIL,
  FETCH_USER_DETAIL_FAIL
} from "../actionType/Constants";

import { getCookie } from "../lib/helper";

import { DEFAULT_API_KEY } from "../configs";

import toast from "../components/base/Toast";

function requestUserDetail() {
  return {
    type: REQUEST_USER_DETAIL
  };
}

/**
 * Receive for Settings list
 */
function receiveUserDetail({ data: payload }) {
  return {
    type: RECEIVE_USER_DETAIL,
    payload
  };
}

function userDetailFetchFail(error) {
  return {
    type: FETCH_USER_DETAIL_FAIL,
    error
  };
}

export function fetchUserDetail(callback) {
  return dispatch => {
    dispatch(requestUserDetail());

    // Default API Key For Public API
    if (getCookie("session_token")) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        "session_token"
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    return apiClient
      .get(`${endpoints().userAPI}`)
      .then(response => response)
      .then(response => {
        if (response && response.data) {
          dispatch(receiveUserDetail(response));
          return callback(response);
        }
      })
      .catch(error => {
        dispatch(userDetailFetchFail());

        if (error.status >= 400) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
        return callback(null);
      });
  };
}
