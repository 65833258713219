import React from "react";

const IntentTable = props => {
  let { detail } = props;
  const NoRecordsFound = () => (
    <tr>
      <td colSpan="9" style={{ textAlign: "center", padding: "20px" }}>
        No Records Found
      </td>
    </tr>
  );
  let intnetData =
    detail && detail.intentData && detail.intentData[0].intentDataArray;
  return (
    <div>
      <div
        className="container-fluid site-maincontent p-0"
        style={{ overflowX: "auto" }}
      >
        <table
          style={{
            borderCollapse: "collapse",
            width: "100%",
            marginTop: "10px"
          }}
        >
          <thead>
            <tr style={{ backgroundColor: "#D3D3D3" }}>
              <th
                colSpan="3"
                style={{
                  border: "1px solid black",
                  borderBottom: "transparent"
                }}
              >
                <p className="text-center m-0"> TOPIC </p>
              </th>

              <th
                colSpan="6"
                style={{
                  border: "1px solid black",
                  borderBottom: "transparent"
                }}
              >
                <p className="text-center m-0"> INTENT SIGNAL BY WEEK </p>
              </th>
            </tr>
            <tr
              style={{
                textAlign: "center",
                backgroundColor: "#D3D3D3",
                cursor: "pointer"
              }}
            >
              <th colSpan="3" style={{ borderLeft: "1px solid black" }}>
                {/* Company header content here */}
              </th>
              <th
                style={{
                  borderLeft: "1px solid black",
                  minWidth: "50px"
                }}
              >
                <div className="d-flex justify-content-center">1</div>
              </th>
              <th
                style={{
                  minWidth: "50px"
                }}
              >
                <div className="d-flex justify-content-center">2</div>
              </th>
              <th
                style={{
                  minWidth: "50px"
                }}
              >
                <div className="d-flex justify-content-center">3</div>
              </th>
              <th
                style={{
                  minWidth: "50px"
                }}
              >
                <div className="d-flex justify-content-center">4</div>
              </th>
              <th
                style={{
                  minWidth: "50px"
                }}
              >
                <div className="d-flex justify-content-center">5</div>
              </th>

              <th
                style={{
                  borderRight: "1px solid black",
                  minWidth: "50px"
                }}
              >
                <div className="d-flex justify-content-center">6</div>
              </th>
            </tr>
          </thead>
          {intnetData && intnetData.length > 0 ? (
            intnetData.map((value, index) => (
              <tbody style={{ height: "50px", overflowX: "auto" }}>
                <tr key={index} style={{ textAlign: "center", height: "50px" }}>
                  <td
                    colSpan="3"
                    style={{
                      border: "1px solid black",
                      minWidth: "300px",
                      textAlign: "left",
                      padding: "10px"
                    }}
                  >
                    {value && value.keyword ? value.keyword : "-"}
                  </td>

                  {/* Days columns */}
                  {value &&
                    value.days &&
                    value.days.length > 0 &&
                    value.days.map(
                      (day, dayIndex) =>
                        // Only render the first 6 days
                        dayIndex < 6 && (
                          <td
                            key={dayIndex}
                            style={{
                              border: "1px solid black",
                              minWidth: "50px"
                            }}
                          >
                            {/* Intent Strength column */}
                            {day && day.label && (
                              <span
                                key={index}
                                style={{
                                  color:
                                    day.value === "H"
                                      ? "green"
                                      : day.value === "M"
                                      ? "blue"
                                      : day.value === "L"
                                      ? "red"
                                      : "inherit",
                                  fontWeight: 400
                                }}
                              >
                                {day.value}
                              </span>
                            )}
                          </td>
                        )
                    )}
                </tr>
              </tbody>
            ))
          ) : (
            <tbody
              style={{ height: "50px", overflowX: "auto", border: "1px solid" }}
            >
              <NoRecordsFound />
            </tbody>
          )}
        </table>
      </div>
    </div>
  );
};
export default IntentTable;
