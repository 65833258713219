import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";
import { isLoggedIn } from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import AddButton from "../../components/base/AddButton";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

export default class Tags extends Component {
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    return (
      <div>
        <PageTitle label="Tags" />

        <AddButton
          label="New Tag"
          targetUrl="/settings/tags/add"
          className="pull-right btn btn-secondary"
        />

        <div>
          <ReduxTable
            id="tags"
            apiURL={`${endpoints().tagAPI}`}
            onRowClick={row =>
              this.props.history.push(`/settings/tag/${row.id}`)
            }
          >
            <ReduxColumn type="link" isClickable="true" field="name">
              Name
            </ReduxColumn>
            <ReduxColumn field="type">Type</ReduxColumn>
            <ReduxColumn field="projectCategory">Project Category</ReduxColumn>
            <ReduxColumn field="createdAt">Created At</ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}
