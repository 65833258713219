const { default: Terminology } = require("../lib/Terminology");
const { default: SystemName } = require("./SystemName");

module.exports = {
  ALL_INSIGHTS: "All Insights",

  REFERRAL_SUGGESTIONS: `${Terminology.get(SystemName.REFERRAL)} Suggestions`,

  ASSISTANCE_REQUEST_SUGGESTIONS: "Assistance Request Suggestions",

  INTRODUCTION_SUGGESTIONS: "Introduction Suggestions",

  CO_MARKETING_SUGGESTIONS: "Co-Marketing Suggestions"
};
