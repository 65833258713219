export const TAG_SALESFORCE_OBJECT_LEAD = "Lead";
export const TAG_SALESFORCE_OBJECT_OPPORTUNITY = "Opportunity";

export const TAG_OBJECT_DEAL = "Deal";
export const TAG_OBJECT_REFERRAL = "Referral";

export const TAG_PERMISSION_SELECT_AND_ADD =
  "Partners can select from and add to this list";
export const TAG_PERMISSION_SELECT_AND_NOT_ADD =
  "Partners can select from this list but not add";
export const TAG_PERMISSION_INTERNAL_ONLY = "Internal only";
export const TAG_PERMISSION_SELECT_AND_ADD_TABLE_TEXT =
  "Partners can select/add";
export const TAG_PERMISSION_SELECT_AND_NOT_ADD_TABLE_TEXT =
  "Partners can only select";
export const TAG_PERMISSION_INTERNAL_ONLY_TABLE_TEXT = "Internal only";

export const TAG_VISIBILITY_PUBLIC = 1;
export const TAG_VISIBILITY_ADMIN = 2;

export const TAG_PERMISSION_SELECT_AND_ADD_VALUE = 1;
export const TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE = 2;
export const TAG_PERMISSION_INTERNAL_ONLY_VALUE = 3;
