import React, { useState } from "react";
import Label from "../base/Label";
import { FormFeedback, FormGroup } from "reactstrap";

const CustomFile = props => {
  const {
    name,
    id,
    label,
    required,
    onChange,
    className,
    errorMessage,
    setFieldValue
  } = props;
  let [updatedLabelText, setUpdatedLabelText] = useState("");

  const updateLabel = e => {
    const path = e.target.value;
    const labelArray = path.split("\\");
    setUpdatedLabelText(path ? labelArray[labelArray.length - 1] : "");
  };

  const inputId = id || name;
  return (
    <FormGroup className={className}>
      {label && (
        <Label id={inputId} required={required}>
          <span>{updatedLabelText || label}</span>
        </Label>
      )}
      <input
        id={inputId}
        type="file"
        onChange={e => {
          updateLabel(e);
          onChange && onChange(e);
        }}
      />

      {errorMessage && (
        <FormFeedback style={{ position: "absolute", marginTop: 1 }}>
          {errorMessage}
        </FormFeedback>
      )}
    </FormGroup>
  );
};

export default CustomFile;
