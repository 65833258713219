import React, { useState } from "react";
import Drawers from "@material-ui/core/Drawer";
import Button from "@material-ui/core/Button";
import Form from "./base/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import DeleteButton from "./base/DeleteButton";
import AddButton from "./base/AddButton";

const Drawer = props => {
  let {
    DrawerBody,
    DrawerFooter,
    initialValues,
    enableReinitialize,
    onSubmit,
    handleOpenModal,
    handleCloseModal,
    handleDrawerClose,
    isModalOpen,
    showButton,
    hideAddButton,
    modelTitle,
    className,
    showFooter
  } = props;

  const handleCancel = () => {
    handleCloseModal();
  };

  return (
    <>
      {showButton && !hideAddButton && (
        <div className="">
          <div
            onClick={handleOpenModal}
            className="d-flex justify-content-center align-items-center mr-1"
            style={{ width: "35px", height: "35px" }}
          >
            <button
              style={{
                border: "none",
                width: "35px",
                height: "35px",
                borderRadius: "5px"
              }}
              className="bg-primary"
            >
              <FontAwesomeIcon
                icon={faPlus}
                color="var(--drawer-FontAwesomeIcon-color)"
              />
            </button>
          </div>
        </div>
      )}
      <Drawers
        className="MuiDrawer-paper"
        anchor="right"
        open={isModalOpen}
        style={{
          width: "100%"
        }}
        PaperProps={{
          className: className ? className : ""
        }}
      >
        <div
          style={{ display: "flex", flexDirection: "column", height: "100%" }}
        >
          {/* Header */}
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "6px 10px",
              borderBottom:
                "1px solid var(--drawer-header-border-bottom-color)",
              position: "sticky",
              top: 0,
              backgroundColor: "var(--drawer-bg-color)",
              zIndex: 2
            }}
          >
            <h2>{modelTitle}</h2>
            <div className="d-flex">
              <Button
                onClick={handleDrawerClose}
                className="custom-button" // Add a custom class for styling
                style={{ fontSize: "24px" }}
              >
                ×
              </Button>
            </div>
          </div>
          {/* Content */}
          <div
            style={{
              flex: 1,
              padding: "16px 16px 0px 16px",
              overflowY: "auto"
            }}
          >
            <Form
              initialValues={initialValues}
              enableReinitialize={enableReinitialize}
              onSubmit={onSubmit}
              className="w-100"
              width="100%"
            >
              <>
                <div style={{ minHeight: "80vh" }}> {DrawerBody} </div>
                {showFooter && (
                  <div
                    style={{
                      display: "flex",
                      marginTop: "60px",
                      justifyContent: "center",
                      padding: "0px 16px 16px 16px",
                      borderTop:
                        "1px solid var(--drawer-footer-border-tap-color)",
                      textAlign: "center",
                      position: "sticky",
                      bottom: 0,
                      backgroundColor: "var(--drawer-footer-bg-color)",
                      zIndex: 2
                      // marginTop:"4px"
                    }}
                  >
                    <div className="mt-4">{DrawerFooter}</div>

                    <div className="mt-4 bg-danger">
                      <Button
                        onClick={handleCancel}
                        style={{
                          border:
                            "1px solid var(--drawer-cancel-button-border-color)",
                          marginLeft: "15px",
                          borderRadius: "7px",
                          color: "white"
                        }}
                      >
                        Close
                      </Button>
                    </div>
                  </div>
                )}
              </>
            </Form>
          </div>
        </div>
      </Drawers>
    </>
  );
};

export default Drawer;
