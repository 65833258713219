import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Elements } from "react-stripe-elements";

// Components
import InjectStripeForm from "../AddACH";

const AddAchModal = ({
  isOpen,
  toggle,
  setPaymentMethod,
  updatePaymentMethodUi
}) => (
  <Modal
    isOpen={isOpen}
    toggle={toggle}
    className={["edit-task-modal", "edit-billing-modal"].join(" ")}
  >
    <ModalHeader toggle={toggle} tag={"div"}>
      <h4 className={["font-weight-bold", "mt-2"].join(" ")}>Add ACH</h4>
    </ModalHeader>
    <ModalBody>
      <Elements>
        <InjectStripeForm
          toggle={toggle}
          setPaymentMethod={setPaymentMethod}
          updatePaymentMethodUi={updatePaymentMethodUi}
        />
      </Elements>
    </ModalBody>
  </Modal>
);

export default AddAchModal;
