import React from "react";
import moment from "moment";
import toast from "../../../../components/base/Toast";
import { Link } from "react-router-dom";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

// Components
import PageTitle from "../../../../components/base/PageTitle";
import PageSearch from "../../../../components/base/PageSearch";
import { Table } from "reactstrap";
import Page404 from "../../../Page404";
import SelectDropdown from "../../../../components/base/SelectDropdown";

// Constants
import {
  LAST_MONTH,
  LAST_MONTH_TEXT,
  LAST_WEEK,
  LAST_WEEK_TEXT,
  THIS_MONTH,
  THIS_MONTH_TEXT,
  THIS_WEEK,
  THIS_WEEK_TEXT,
  TODAY,
  TODAY_TEXT,
  YESTERDAY,
  YESTERDAY_TEXT
} from "../../../../salesforceRelativeDates/Constants";

// Helper
import { isSuperAdmin, isCompanyAdmin } from "../../../../lib/helper";
import { isBadRequest } from "../../../../common/http";

import { isSalesforceID } from "../../../../common/utils";

import { connect } from "react-redux";

class SalesforceContact extends React.Component {
  state = {
    isSearching: false,
    contactList: [],
    searchResultMessage: "Search contacts",
    searchTerm: "",
    salesforceId: "",
    createdDate: "",
    sortByOptions: [
      {
        value: TODAY,
        label: TODAY_TEXT
      },
      {
        value: YESTERDAY,
        label: YESTERDAY_TEXT
      },
      {
        value: THIS_WEEK,
        label: THIS_WEEK_TEXT
      },
      {
        value: LAST_WEEK,
        label: LAST_WEEK_TEXT
      },
      {
        value: THIS_MONTH,
        label: THIS_MONTH_TEXT
      },
      {
        value: LAST_MONTH,
        label: LAST_MONTH_TEXT
      }
    ]
  };

  componentDidMount() {
    const isSearch = localStorage.getItem("searchFields");
    if (isSearch) {
      const searchState = JSON.parse(isSearch);
      this.setState(searchState, () => {
        this.salesforceSearch();
      });
      localStorage.removeItem("searchFields");
    }
  }

  rowSelection = () => {
    localStorage.setItem("searchFields", JSON.stringify(this.state));
  };

  salesforceSearch = () => {
    this.setState({ isSearching: true }, () => {
      let { searchTerm, salesforceId, createdDate } = this.state;
      searchTerm = searchTerm ? searchTerm : "";
      salesforceId = salesforceId ? salesforceId : "";
      createdDate = createdDate ? createdDate : "";
      return apiClient
        .get(
          `${
            endpoints().salesforceLookupAPI
          }/contact/search?searchTerm=${searchTerm}&salesforceId=${salesforceId}&createdDate=${createdDate}`
        )
        .then(response => {
          const { records } = response.data;

          this.setState({
            contactList: records || [],
            isSearching: false,
            searchResultMessage: "No contacts found"
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    });
  };

  handleSearchTermChange = searchTerm => {
    let validateSalesforceID = isSalesforceID(searchTerm);
    this.setState(
      {
        searchTerm,
        salesforceId: validateSalesforceID ? searchTerm : null
      },
      () => {
        this.salesforceSearch();
      }
    );
  };

  handleIdChange = salesforceId => {
    this.setState(
      {
        salesforceId
      },
      () => {
        this.salesforceSearch();
      }
    );
  };

  handleCreatedDateChange = createdDate => {
    this.setState(
      {
        createdDate: this.getSortValueFromLabel(createdDate)
      },
      () => {
        this.salesforceSearch();
      }
    );
  };

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  getSortLabelFromValue(Value) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.value === Value
    );

    if (selectedSortOption) {
      return selectedSortOption.label;
    }

    return "";
  }

  render() {
    const {
      contactList,
      searchResultMessage,
      createdDate,
      sortByOptions,
      searchTerm,
      salesforceId
    } = this.state;

    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId)
    ) {
      return <Page404 />;
    }
    const { activeTab, activeSubTab, activeSection } = this.props;
    let selectedDate = this.getSortLabelFromValue(createdDate);
    return (
      <>
        {!activeSection ? (
          <>
            <PageTitle label="Salesforce Contact" />

            {/* Main Layout */}
            <div>
              {/* Search Bar */}
              <div>
                <div
                  className={`page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column`}
                >
                  <>
                    <PageSearch
                      classnames="page-search "
                      placeholder="Search"
                      onChange={e =>
                        this.handleSearchTermChange(e.target.value)
                      }
                    />
                  </>
                  <SelectDropdown
                    buttonLabel={selectedDate || "Select Created Date"}
                    dropdownLinks={sortByOptions}
                    color={"gray"}
                    hideCaret
                    selectName={"sortby_createdDate"}
                    value={createdDate}
                    handleChange={this.handleCreatedDateChange}
                  />
                </div>
                {/* Contact List */}
                <Table className="table-hover bg-white">
                  <thead className="thead-dark table-bordered">
                    <tr>
                      <th>Salesforce Id</th>
                      <th>Name</th>
                      <th>CreatedAt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {contactList && contactList.length ? (
                      contactList.map(contact => (
                        <tr>
                          <td>
                            <Link
                              onClick={this.rowSelection}
                              to={`/support-portal/salesforce/contact/${contact.Id}`}
                            >
                              <span className="banner-image-upload-link">
                                {contact.Id}
                              </span>
                            </Link>
                          </td>
                          <td>{contact.Name}</td>
                          <td>
                            {moment(contact.CreatedDate).format("MM/DD/YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center p-4" colSpan={3}>
                          {searchResultMessage}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        ) : (
          <>{activeSubTab == "contact" && <SalesforceContact />}</>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(SalesforceContact);
