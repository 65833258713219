import React from "react";

// Assets
import { SportVestIcon } from "../../assets/img/icons";

// Component
import NoRecordsFound from "../../components/base/NoRecordsFound";
import Form from "../../components/base/Form";
import Email from "../../components/base/Email";
import toast from "../../components/base/Toast";

import TeamMember from "./TeamMember";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

import {
  CUSTOMER_ADMIN_ROLE,
  CUSTOMER_MEMBER_ROLE,
  CUSTOMER_ADMIN_TEXT,
  CUSTOMER_MEMBER_TEXT
} from "../../roles/Constants";
import { isBadRequest } from "../../common/http";

class AddTeamMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: ""
    };
  }

  // Handle Customer User Invite
  handleSubmit(data) {
    const { customerId } = this.props;
    return apiClient
      .post(`${endpoints().customerUserAPI}/invite`, {
        customerId,
        email: data.email
      })
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        data.email = "";
        this.props.handleTeamMemberChange();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
          data.email = "";
          this.props.handleTeamMemberChange();
        }
      });
  }

  // Remove team member
  handleRemove = member => {
    let id = member.id;
    return apiClient
      .delete(`${endpoints().customerUserAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.props.handleTeamMemberChange();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Handle User Role
  handleUserRole = (value, member) => {
    const role =
      value === CUSTOMER_ADMIN_TEXT
        ? CUSTOMER_ADMIN_ROLE
        : CUSTOMER_MEMBER_ROLE;

    return apiClient
      .put(`${endpoints().customerUserAPI}/update/role/${member.id}`, { role })
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this.props.handleTeamMemberChange();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
        }
      });
  };

  render() {
    const { members } = this.props;

    const initialValues = {
      email: ""
    };

    // Customer User Role Options
    const userRoles = [
      {
        value: CUSTOMER_ADMIN_ROLE,
        label: CUSTOMER_ADMIN_TEXT
      },
      {
        value: CUSTOMER_MEMBER_ROLE,
        label: CUSTOMER_MEMBER_TEXT
      }
    ];

    return (
      <>
        <div className="tab-content-wrapper">
          <div className="add-team-member d-flex flex-column align-items-center text-center">
            <div className="img-wrapper mb-4">
              <SportVestIcon />
            </div>
            <h4 className="mb-2">Add Team Members</h4>
            <p className="mb-4">
              Team members have access to all collections in your marketplace
              and will be able to make engagement requests with experts.
            </p>
            <Form
              initialValues={initialValues}
              onSubmit={values => {
                values.email = values.email ? values.email : null;

                this.handleSubmit(values);
                return false;
              }}
            >
              <div
                className="form-wrapper w-300"
                style={{
                  width: "400px"
                }}
              >
                <div className="field-wrapper text-center">
                  <Email name="email" placeholder="Email Address" required />
                </div>

                <div className="btn-wrapper mt-4">
                  <button
                    type="submit"
                    className={["btn", "btn-secondary"].join(" ")}
                  >
                    Send Invitation
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </div>

        {/* Team Members Section*/}
        <div className="tab-content-wrapper">
          <div className="team-members">
            {members && members.length > 0 ? (
              members.map((member, index) => (
                <TeamMember
                  member={member}
                  index={index}
                  userRoles={userRoles}
                  handleUserRole={this.handleUserRole}
                  removeTeamMember={this.handleRemove}
                />
              ))
            ) : (
              <NoRecordsFound
                description="No team members"
                position="top"
                hideCard={true}
              />
            )}
          </div>
        </div>
      </>
    );
  }
}

export default AddTeamMember;
