import React from "react";

//Assets
import image from "../../assets/img/map_animation.gif";
import { ArrowRight, HandsIcon } from "../../assets/img/icons";

class RequestReceived extends React.Component {
  render() {
    const {
      currentStep,
      step,
      close,
      minimal,
      email,
      marketplaceDefaultLandingPageUrl
    } = this.props;

    const redirectToDashboard = () => {
      window.location = marketplaceDefaultLandingPageUrl;
    };

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <HandsIcon />{" "}
          {minimal ? (
            <h2 className="h1-5 font-weight-bold mb-0 ml-3">
              Thank You for Your Expert Request!
            </h2>
          ) : (
            <h2 className="h1-5 font-weight-bold mb-0 ml-3">
              Matching Experts
            </h2>
          )}
        </div>
        <div className="content">
          {minimal ? (
            <>
              <h5 className="lh-2 mb-3">
                We’ll be in contact shortly with next steps regarding the
                process of creating a custom project and sourcing the perfect
                Expert to work with you.
              </h5>
              <h5 className="lh-2">
                In the meantime, feel free to continue browsing the Playbooks
                within the marketplace.
              </h5>
            </>
          ) : (
            <>
              <h5 className="lh-2">
                <b>
                  We’ve received your request and are working on matching you to
                  experts.
                </b>{" "}
                We will send an email to {email} with more information about the
                experts who can help with your upcoming project.
              </h5>
              <div className="img-wrapper">
                <img src={image} className="img-fluid" alt="" />
              </div>
            </>
          )}
          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <button
              type="button"
              className="btn btn-primary d-flex align-items-center"
              onClick={redirectToDashboard}
            >
              Go to Marketplace <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default RequestReceived;
