import React from "react";

import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import { WarningRedIcon } from "../../../../assets/img/icons";

import {
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_DECLINED
} from "../../../Constants";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

const ReferralConflictModal = props => {
  const {
    referralConflictModalOpen,
    toggleReferralConflictConfirmation,
    updateReferralByStatus,
    conflictReferralList
  } = props;

  let referralData =
    conflictReferralList &&
    conflictReferralList.length > 0 &&
    conflictReferralList[0];

  return (
    <>
      <Modal
        isOpen={referralConflictModalOpen}
        className={["log-hours-modal", "edit-task-modal"].join(" ")}
      >
        <ModalHeader
          toggle={() => {
            toggleReferralConflictConfirmation();
          }}
        >
          <WarningRedIcon />
          <h4 className={["font-weight-bold", "mt-3"].join(" ")}>
            There is an another {Terminology.get(SystemName.REFERRAL)} conflicts
            this.
          </h4>
        </ModalHeader>
        <ModalBody className={["text-center", "mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <h6 className="mx-4">
                <p>
                  Are you sure you want to Approve this <br />
                  <b>"{referralData ? referralData.account_name : ""}"</b>?
                </p>
              </h6>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          className={["justify-content-center", "border-top-0"].join(" ")}
        >
          <div className="btn-wrapper">
            <button
              className="btn btn-success px-3"
              onClick={() => {
                updateReferralByStatus(REFERRAL_STATUS_APPROVED, true);
                toggleReferralConflictConfirmation();
              }}
            >
              Approve
            </button>
            <button
              className="btn px-3 btn-outline-danger"
              onClick={() => {
                updateReferralByStatus(REFERRAL_STATUS_DECLINED);
                toggleReferralConflictConfirmation();
              }}
            >
              Decline
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ReferralConflictModal;
