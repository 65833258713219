import React, { Component } from "react";
import DefaultContent from "../content/DefaultContent";
import Text from "../base/Text";
import Checkbox from "../../views/expertSignUp/Checkbox";
import { FormGroup } from "reactstrap";
import { FieldArray } from "formik";
import moment from "moment";
import ExpertProfileEditDatepicker from "./ExpertProfileEditDatepicker";
import ErrorMessage from "../base/ErrorMessage";
import CustomFile from "../base/CustomFile";
import TextArea from "../base/TextArea";
import {
  isExpert,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

import { connect } from "react-redux";

const doBase64encode = eventTarget => {
  return new Promise((resolve, reject) => {
    const { files } = eventTarget.target;
    const reader = new FileReader();

    reader.addEventListener("load", () => {
      return resolve(reader.result);
    });

    if (files && files.length > 0) {
      reader.readAsDataURL(files[0]);
    }
  });
};

class ExpertProfileCertifications extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  setStartDate(field, value) {
    this.setState({
      [field]: value
    });
  }
  render() {
    const { certifications, id } = this.props;
    return (
      <DefaultContent title={"Certifications"}>
        <p className={["text-inline-grayed"].join("")}>
          If you have certifications, list them! They’ll help clients know
          you’re great.
        </p>
        <FieldArray
          name="certifications"
          render={arrayHelpers => (
            <div
              className={["certifications", "certifications-editing"].join(" ")}
            >
              {arrayHelpers.form.values.certifications &&
                arrayHelpers.form.values.certifications.map(
                  (certification, index) => (
                    <div
                      className={["certification-editing"].join(" ")}
                      key={index}
                    >
                      {(isSuperAdmin(this.props.roleId) ||
                        isCompanyAdmin(this.props.roleId) ||
                        isCompanyManager(this.props.roleId) ||
                        isExpert(this.props.roleId)) && (
                        <div className="field-wrapper">
                          <CustomFile
                            id={id}
                            label={"Certification Logo"}
                            name={`certifications[${index}].logo`}
                            className={["file-upload"].join(" ")}
                            accept=".docx, .doc, .pdf, image/*, text/*"
                            setFieldValue={arrayHelpers.form.setFieldValue}
                            onChange={async e => {
                              arrayHelpers.form.values.certifications[
                                index
                              ].logo = await doBase64encode(e);
                            }}
                          />
                        </div>
                      )}
                      <div className="field-wrapper">
                        <ErrorMessage name={`certifications[${index}].name`} />
                        <Text
                          id={id}
                          placeholder={"Certificate name"}
                          name={`certifications[${index}].name`}
                          required
                          maxLength={150}
                        />
                      </div>
                      <div className="field-wrapper">
                        <ErrorMessage
                          name={`certifications[${index}].description`}
                        />
                        <TextArea
                          id={id}
                          placeholder={"Certificate Description"}
                          name={`certifications[${index}].description`}
                          required
                        />
                      </div>
                      <div className="field-wrapper">
                        <div className="inline-field-wrapper">
                          <ErrorMessage
                            name={`certifications[${index}].institute`}
                          />
                          <Text
                            id={id}
                            name={`certifications[${index}].institute`}
                            placeholder={"Certificate institution"}
                            required
                          />
                        </div>
                        <CustomFile
                          id={id}
                          label={"Attach documentation..."}
                          name={`certifications[${index}].file`}
                          className={["file-upload"].join(" ")}
                          accept=".docx, .doc, .pdf, image/*, text/*"
                          setFieldValue={arrayHelpers.form.setFieldValue}
                          onChange={async e => {
                            arrayHelpers.form.values.certifications[
                              index
                            ].file = await doBase64encode(e);
                          }}
                        />
                      </div>
                      <div className="field-wrapper" key={index}>
                        <FormGroup>
                          <ExpertProfileEditDatepicker
                            id={id}
                            name={`certifications[${index}].startDate`}
                            placeholder={"Start date"}
                            value={
                              arrayHelpers.form.values.certifications[index]
                                .startDate
                            }
                            onChange={val => {
                              arrayHelpers.form.values.certifications[
                                index
                              ].startDate = moment(val).format("YYYY-MM-DD");
                              this.setStartDate(
                                `certifications[${index}].startDate`,
                                moment(val).format("YYYY-MM-DD")
                              );
                            }}
                            clearIcon={null}
                            maxDate={new Date()}
                          />
                        </FormGroup>
                        {!arrayHelpers.form.values.certifications[index]
                          .hasNoEndDate && (
                          <FormGroup>
                            <ExpertProfileEditDatepicker
                              id={id}
                              name={`certifications[${index}].endDate`}
                              placeholder={"End date"}
                              value={
                                arrayHelpers.form.values.certifications[index]
                                  .endDate
                              }
                              onChange={val => {
                                arrayHelpers.form.values.certifications[
                                  index
                                ].endDate = moment(val).format("YYYY-MM-DD");
                              }}
                              clearIcon={null}
                              minDate={
                                this.state[`certifications[${index}].startDate`]
                                  ? new Date(
                                      this.state[
                                        `certifications[${index}].startDate`
                                      ]
                                    )
                                  : arrayHelpers.form.values.certifications[
                                      index
                                    ].startDate
                                  ? new Date(
                                      arrayHelpers.form.values.certifications[
                                        index
                                      ].startDate
                                    )
                                  : ""
                              }
                              disabled={
                                this.state[
                                  `certifications[${index}].startDate`
                                ] ||
                                arrayHelpers.form.values.certifications[index]
                                  .startDate
                                  ? false
                                  : true
                              }
                            />
                          </FormGroup>
                        )}
                      </div>
                      <div className="field-wrapper">
                        <Checkbox
                          id={`certifications_no_end_date_${index}`}
                          className="custom-checkbox accepted-terms pb-0 mb-0"
                          name={`certifications[${index}].hasNoEndDate`}
                          label={"Certificate has no end date "}
                        />
                      </div>
                      <div className={["btn-wrapper", "text-right"].join(" ")}>
                        <a
                          className="remove-link"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Remove
                        </a>
                      </div>
                    </div>
                  )
                )}
              <a
                href="#"
                className="h7"
                onClick={e => {
                  e.preventDefault();
                  arrayHelpers.push({
                    file: "",
                    logo: "",
                    description: "",
                    institute: "",
                    name: "",
                    startDate: new Date(),
                    endDate: new Date(),
                    hasNoEndDate: false
                  });
                }}
              >
                {`Add ${
                  certifications && certifications.length > 0 ? "another" : ""
                } certificate...`}
              </a>
            </div>
          )}
        />
      </DefaultContent>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(ExpertProfileCertifications);
