import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";

// Action
import { fetchList } from "../../../actions/table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import Form from "../../../components/base/Form";

import ReduxTable, { ReduxColumn } from "../../../components/base/ReduxTable";

import { formatCurrency } from "../../../helpers/currency";

import { DownloadIcon, ReportIcon } from "../../../assets/img/icons";

import PipelineReportStatCard from "../../../components/reports/PipelineReportStatCard";

import Select from "../../../components/base/Select";

import PageTitle from "../../../components/base/PageTitle";

import Partner from "../../../components/Partner";

// Api Client
import { apiClient } from "../../../apiClient";

// Configs
import { endpoints } from "../../../configs";

import { isBadRequest } from "../../../common/http";

import { CSVLink } from "react-csv";

import moment from "moment";

// Constants
import { TAB_CLOSED_WON_REFERRAL_REPORT } from "../../../reports/Constants";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

const ReferralTab = props => {
  const [partnerId, setPartnerId] = useState("");
  const [partnerTypes, setPartnerTypes] = useState([]);

  useEffect(() => {
    getPartnerType();
  }, []);

  // partner type page api call
  const getPartnerType = () => {
    apiClient
      .get(`${endpoints().partnerTypeAPI}/search`)
      .then(response => {
        const partnerTypeList = response.data.data;
        let partnerTypeSortedList = [];
        if (response && response.data) {
          partnerTypeList
            .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
            .forEach(type => {
              partnerTypeSortedList.push({
                label: type.name,
                value: type.id
              });
            });
          setPartnerTypes(partnerTypeSortedList);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <PageTitle
          label={`Closed Won ${Terminology.get(SystemName.REFERRAL)} Report`}
        />

        <CSVLink
          data={props.reportData ? props.reportData : []}
          headers={props.headers}
          filename={`Pipeline_Report_${moment().format("YYYY-MM-DD")}.csv`}
        >
          <span className="download-pdf ml-auto d-flex" href="#">
            <DownloadIcon />
            <span className="ml-1">Export</span>
          </span>
        </CSVLink>
      </div>

      <div className="mt-4 mb-5">
        <PipelineReportStatCard
          averageReferralSize={props.referralSize}
          totalValue={props.referralTotalAmount}
          noOfReferralCount={props.referralCount}
        />
        <div className={`page-heading cover`}>
          <div className="row">
            <div className="col-3">
              <Form>
                <Partner
                  partnerid="refferalPartner"
                  name="partner"
                  partnerId={partnerId}
                  placeholder="Select Partner"
                  defaultValue="partner"
                  isSearchable={true}
                  isClearable={true}
                  handleChange={currentVal => {
                    props.handlePartnerChange(currentVal);
                  }}
                />
              </Form>
            </div>

            <div className="col-3">
              <Form>
                <Select
                  name="partnerType"
                  placeholder="Partner Type"
                  defaultValue=""
                  options={partnerTypes}
                  handleChange={e => {
                    props.handlePartnerTypeChange(e);
                  }}
                  isSearchable={true}
                  isClearable={true}
                  width={"100%"}
                  marignBottom
                />
              </Form>
            </div>

            <div className="col-3">
              <div className="datepicker-wrapper">
                <DatePicker
                  name="startDate"
                  className="datepicker"
                  placeholderText="Start Date"
                  onChange={props.handleStartDate}
                  maxDate={new Date()}
                  selected={props.startDate}
                  clearIcon={null}
                />
              </div>
            </div>

            <div className="col-3">
              <div className="datepicker-wrapper">
                <DatePicker
                  name="endDate"
                  className="datepicker"
                  placeholderText="End Date"
                  onChange={props.handleEndDate}
                  selected={props.endDate}
                  clearAriaLabel
                  clearIcon={null}
                />
              </div>
            </div>
          </div>
        </div>

        {/*  referral list redux table*/}
        <ReduxTable
          id={props.tableId}
          apiURL={props.apiUrl}
          searchDisabled
          icon={<ReportIcon />}
          params={props.params}
        >
          <ReduxColumn field="account_name" sortBy="account_name">
            Company
          </ReduxColumn>
          <ReduxColumn field="companyName" sortBy="partner_id">
            Partner
          </ReduxColumn>
          <ReduxColumn
            field="amount"
            sortBy="amount"
            className="text-right"
            renderField={row => (
              <span>
                {row.amount != null ? `$${formatCurrency(row.amount)}` : ""}
              </span>
            )}
          >
            Amount
          </ReduxColumn>
          <ReduxColumn field="status" sortBy="status">
            Status
          </ReduxColumn>
          <ReduxColumn
            field="lastStageChangedAt"
            sortBy="last_stage_changed_at"
          >
            Last Stage Changed At
          </ReduxColumn>
        </ReduxTable>
      </div>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

// Get ReportStatCard Counts
const mapStateToProps = state => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(ReferralTab);
