import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  CheckmarkIcon,
  ChevronRight,
  DashboardProfileCompletionIcon,
  DashboardFeedbackIcon,
  DashboardLinksIcon,
  DashboardQuickLinkReferralIcon,
  DashboardProfileCompletingIcon,
  CoffeeGraphicIcon,
  ArrowRigthUp
} from "../../../assets/img/icons";
import "react-circular-progressbar/dist/styles.css";
import { getCookie, getSettingMediaUrl, isPartner } from "../../../lib/helper";
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";
import { PARTNER_STATUS_APPROVED } from "../../../partnerStatus/Constants";
import { COOKIE_USER_ID } from "../../../lib/cookie";
import FeedbackModal from "./FeedbackModal";

import {
  SETTINGS_ENABLE_DEALS,
  SETTINGS_ENABLE_REFERRALS,
  SETTINGS_ENABLE_PARTNER_TRAINING,
  SETTINGS_ENABLE_PARTNER_APPLICATIONS,
  TRAINING_SOURCE_THIRD_PARTY_TRAINING_SYSTEM,
  TRAINING_THIRD_PARTY_SYSTEM_NAME,
  TRAINING_THIRD_PARTY_LOGO,
  TRAINING_THIRD_PARTY_CTA_TEXT,
  TRAINING_THIRD_PARTY_URL,
  SETTING_TRAINING_SOURCE,
  SETTINGS_PORTAL_URL,
  UPGRADE_HUBSPOT_FORM_URL,
  ALLOW_UPGRADE
} from "../../../setting/Constants";
import { PARTNER_TYPE_SETTING_ENABLE_REFERRALS_DEAL_REGISTRATION } from "../../../partnerTypeSetting/Constants";

import { getKeyValueByObject } from "../../../lib/helper";
import OnboardingLink from "./OnboardingLink";
import ProfileCompletionItem from "./ProfileCompletionItem";
import { TASK_STATUS_COMPLETED } from "../../../modules/task/Constant";
import MessageDialog from "../../../views/message/MessageDialog";
import toast from "../../../components/base/Toast";
import { Button } from "reactstrap";
import { isBadRequest } from "../../../common/http";
import OutlineButton from "../../../components/base/OutlineButton";
import Avatar from "../../../components/base/Avatar";

// Constants
import { PARTNER_PERMISSION_INDIVIDUAL_PARTNER } from "../../../resource/Constants";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

import { ALLOW_REFERRAL_SUBMISSION } from "../../adminSettings/tabs/Partner/PartnerTypes/Constants";

import { IsPartnerTypeSettingEnabled } from "../../../lib/PartnerSetting";

import ReferralRequestPopUp from "../../../views/dashboard/ReferralRequestPopUp";
import HubspotFormModel from "../../../hubspot/hubspotFormModel";
import QuickLinkCard from "../../../components/QuickLinkCard";

const pendingStatus = "Pending";

const PartnerOnboarding = props => {
  const {
    enable,
    percentage,
    profileUpdateStatus,
    partnerPaymentAccessStatus,
    partnerStatus,
    settings,
    partnerSettings,
    encryptedPartnerId,
    history,
    taskList,
    setCurrentTask,
    setTaskFileUploadModal,
    profileTaskCompleted,
    isProfileTasksCompleted,
    getTaskList,
    openMessageModel,
    referralRequestedCount
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [partnerId, setPartnerId] = useState(null);
  const [toggleFeedbackModal, setToggleFeedbackModal] = useState(
    openMessageModel
  );
  const [sendMessageModal, setSendMessageModal] = useState(openMessageModel);
  const [copyLinkButtonLabel, setCopyLinkButtonLabel] = useState("Copy Link");
  const [link, setLink] = useState(true);
  const [status, setStatus] = useState("");
  const [isModalOpen, setIsModalOpen] = useState(false);

  const [detail, setDetail] = useState(null);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  const handleCopyToClipboard = () => {
    const tempInput = document.createElement("input");

    tempInput.value = `${SETTINGS_PORTAL_URL}/referral/submit?partner=${encryptedPartnerId}`;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    setCopyLinkButtonLabel("Copied!");
  };

  const userLoggedIn =
    getCookie(COOKIE_USER_ID) !== undefined &&
    getCookie(COOKIE_USER_ID) !== null;

  const partnerTypeSettingEnableReferrals = partnerSettings
    ? IsPartnerTypeSettingEnabled(
        partnerSettings,
        PARTNER_TYPE_SETTING_ENABLE_REFERRALS_DEAL_REGISTRATION
      )
    : "";

  const partnerTypeSettingAllowReferralSubmit = partnerSettings
    ? IsPartnerTypeSettingEnabled(partnerSettings, ALLOW_REFERRAL_SUBMISSION)
    : "";

  const enableDeals = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_DEALS)
    : "";
  const enableReferrals = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_REFERRALS)
    : "";
  const enableTraining = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNER_TRAINING)
    : "";
  const enablePartnerApplicants = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNER_APPLICATIONS)
    : "";

  const thirdPartySystemName = settings
    ? getKeyValueByObject(settings, TRAINING_THIRD_PARTY_SYSTEM_NAME)
    : "";
  const trainingThirdPartySystemText = settings
    ? getKeyValueByObject(settings, TRAINING_THIRD_PARTY_CTA_TEXT)
    : "";
  const trainingSourceThirdPartyTrainingSystem = settings
    ? getKeyValueByObject(settings, SETTING_TRAINING_SOURCE)
    : "";

  const trainingThirdPartyUrl = settings
    ? getKeyValueByObject(settings, TRAINING_THIRD_PARTY_URL)
    : "";
  const trainingThirdPartyLogo =
    settings && getKeyValueByObject(settings, TRAINING_THIRD_PARTY_LOGO)
      ? getSettingMediaUrl(
          settings,
          getKeyValueByObject(settings, TRAINING_THIRD_PARTY_LOGO)
        )
      : "";
  const enableAllowUpgrade = settings
    ? getKeyValueByObject(settings, ALLOW_UPGRADE)
    : "";

  const hubspotFormUrl = settings
    ? getKeyValueByObject(settings, UPGRADE_HUBSPOT_FORM_URL)
    : "";

  const _getUserDetails = () =>
    userLoggedIn &&
    apiClient
      .get(`${endpoints().userAPI}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        setPartnerId(response.data.partnerId);
        setDetail(response.data);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  const openMessage = () => {
    setSendMessageModal(openMessageModel);
  };
  useEffect(() => {
    _getUserDetails();
  }, []);

  useEffect(() => {
    openMessage();
  }, [openMessageModel]);

  // Send Partner Message API Call
  const sendMessageData = values => {
    return apiClient
      .post(`${endpoints().messageAPI}`, {
        message: values.message,
        EmailTo: "PartnerManagerMessage",
        sharingPermission: PARTNER_PERMISSION_INDIVIDUAL_PARTNER
      })
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
  const toggle = () => setToggleFeedbackModal(!toggleFeedbackModal);
  const toggleSendMessage = () => {
    setSendMessageModal(!sendMessageModal);
    props.closeModal(!sendMessageModal);
  };
  return (
    <div className={["expert-onboarding"].join(" ")}>
      <div
        className={`${!enable ? "disabled" : ""} ${
          !percentage ? "d-none" : ""
        }`}
      >
        <MessageDialog
          modal={sendMessageModal}
          toggle={toggleSendMessage}
          handleClose={toggleSendMessage}
          sendMessageData={sendMessageData}
          heading={`Send ${Terminology.get(
            SystemName.PARTNER_MANAGER
          )} a Message`}
          buttonLabel="Send Message"
        />

        <ReferralRequestPopUp requestReferralCount={referralRequestedCount} />

        {percentage >= 100 && isProfileTasksCompleted ? (
          ""
        ) : (
          <>
            <>
              <div className="mb-0 text-info font-weight-bold quick-link d-flex align-items-center justify-content-between">
                <span className="onboarding-link text-info">
                  <DashboardProfileCompletingIcon />
                  Getting Started
                </span>
                <OutlineButton
                  color="#2196F3"
                  backgroundColor="#FFFF"
                  borderColor="#2196F3"
                  type="button"
                  label="Message Our Partner Team"
                  onClick={toggleSendMessage}
                ></OutlineButton>
              </div>

              {detail && detail.isPartnerAdmin && (
                <div>
                  <OnboardingLink
                    icon={<CoffeeGraphicIcon />}
                    title={"Thank you for your interest in partnering with us!"}
                    description={
                      "There are just a few more tasks to complete before we can accept you into our partner program."
                    }
                    progress={percentage}
                    history={history}
                  />
                  <ProfileCompletionItem
                    completed={TASK_STATUS_COMPLETED}
                    status={
                      percentage ? [{ status: TASK_STATUS_COMPLETED }] : ""
                    }
                    title="Create Account"
                    history={history}
                    redirectUrl={
                      !percentage
                        ? `/edit/partner/public-profile?id=${partnerId}`
                        : ""
                    }
                  />

                  <ProfileCompletionItem
                    completed={profileTaskCompleted}
                    status={
                      profileTaskCompleted
                        ? [{ status: TASK_STATUS_COMPLETED }]
                        : ""
                    }
                    title="Complete Your Profile"
                    history={history}
                    redirectUrl={
                      !profileTaskCompleted
                        ? `/edit/partner/public-profile?id=${partnerId}`
                        : ""
                    }
                  />
                  {taskList &&
                    taskList.length > 0 &&
                    taskList.map(taskItem => {
                      let {
                        name,
                        task_user,
                        description,
                        completionStatus,
                        type,
                        target_url,
                        visibility,
                        percent
                      } = taskItem;

                      return (
                        <ProfileCompletionItem
                          getTaskList={getTaskList}
                          completed={completionStatus === TASK_STATUS_COMPLETED}
                          title={name}
                          subTitle={description}
                          status={task_user}
                          type={type}
                          visitUrl={target_url}
                          visibility={visibility}
                          percent={percent}
                          history={history}
                          task={taskItem}
                          setCurrentTask={setCurrentTask}
                          redirectUrl={`/edit/partner/public-profile?id=${partnerId}&section=application`}
                          setTaskFileUploadModal={setTaskFileUploadModal}
                        />
                      );
                    })}
                </div>
              )}
            </>
          </>
        )}

        <FeedbackModal modal={toggleFeedbackModal} toggle={toggle} />
        {/* TODO: @NORBERT ADAPT THIS TO USE THE COMPONENT */}
        <div>
          <h6 className="mb-0 text-info font-weight-bold quick-link mt-4">
            <span className="mr-2">
              <DashboardLinksIcon />{" "}
            </span>
            Quick Links
          </h6>
          {partnerStatus !== PARTNER_STATUS_APPROVED && percentage < 100 ? (
            <span>
              <QuickLinkCard
                title="Complete your profile"
                description="Your company profile appears in our partner directory and lets
                customers easily find you"
                icon={<DashboardProfileCompletionIcon />}
                link={`/edit/partner/public-profile?id=${partnerId}`}
              />
            </span>
          ) : (
            ""
          )}

          <QuickLinkCard
            title="Get Personalized Link"
            description="Start here to copy your personalized link and share it with your
              team."
            icon={<DashboardProfileCompletionIcon />}
            link={`/edit/partner/public-profile?id=${partnerId}&section=referral-links`}
          />

          <div className={`${partnerStatus !== "Approved" ? "disabled" : ""}`}>
            {enableReferrals === "true" &&
              partnerTypeSettingEnableReferrals &&
              partnerTypeSettingAllowReferralSubmit && (
                <span>
                  <QuickLinkCard
                    title={`Submit a ${Terminology.get(SystemName.REFERRAL)}`}
                    description={`Start here to submit a 
                    ${Terminology.get(SystemName.REFERRAL)} or register a
                    deal`}
                    icon={<DashboardQuickLinkReferralIcon />}
                    link={`/referral/add`}
                  />
                </span>
              )}
            {enableTraining === "true" &&
              trainingSourceThirdPartyTrainingSystem ===
                TRAINING_SOURCE_THIRD_PARTY_TRAINING_SYSTEM && (
                <div>
                  <QuickLinkCard
                    title={thirdPartySystemName}
                    description={trainingThirdPartySystemText}
                    icon={<DashboardQuickLinkReferralIcon />}
                    logo={trainingThirdPartyLogo ? trainingThirdPartyLogo : ""}
                    url={trainingThirdPartyUrl}
                    buttonLabel="Go to Training"
                  />
                </div>
              )}

            <QuickLinkCard
              title="Send Feedback"
              description="Tell us about your experience and let us know what
              improvements you would like to see in our partner portal"
              icon={<DashboardFeedbackIcon />}
              onClick={() => setToggleFeedbackModal(true)}
              link
            />

            {enableAllowUpgrade === "true" && (
              <div>
                <QuickLinkCard
                  title="Upgrade"
                  description="Upgrade your Partner Program with Torchlite Partner
            Management Software - Get a full demo"
                  icon={<ArrowRigthUp />}
                  link
                  onClick={openModal}
                />

                <HubspotFormModel
                  isOpen={isModalOpen}
                  closeModal={closeModal}
                  title="Upgrade Form"
                  data={detail}
                  hubspotFormUrl={hubspotFormUrl}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      {/* {enableDeals === "true" && (
        <Link
          to={{
            pathname: "/deals"
          }}
          className="onboarding-link"
        >
          <div className="onboarding-header">
            <div className="step-wrapper">
              <span className="step-count green-faded completed">
                <DashboardProjectIcon />
              </span>
            </div>
          </div>
          <div className="onboarding-main">
            <h5 className="font-weight-bold">Review Deals</h5>
            <p className="font-weight-bold text-inline-grayed">
              Lorem ipsum dolor sit amet consectetur adipiscing elit
            </p>
          </div>
          <div className="onboarding-url">
            <ChevronRight />
          </div>
        </Link>
      )}  */}

      <div className="d-none">
        {/* Remove the above d-none div when showing add payment */}
        <a
          href={
            partnerPaymentAccessStatus === pendingStatus
              ? "#"
              : "Javascript:void(0);"
          }
          className="onboarding-link"
        >
          <div className="onboarding-header">
            <div className="step-wrapper">
              {partnerPaymentAccessStatus === pendingStatus ? (
                <span className="step-count red-faded">2</span>
              ) : (
                <span className="step-count red-faded completed">
                  <CheckmarkIcon />
                </span>
              )}
            </div>
          </div>
          <div className="onboarding-main">
            <h5 className="font-weight-bold">Accept Payments</h5>
            <p className="font-weight-bold text-inline-grayed">
              Add payment info so that you can get paid for projects
            </p>
          </div>
          <div className="onboarding-url">
            <ChevronRight />
          </div>
        </a>
      </div>
    </div>
  );
};

export default PartnerOnboarding;
