// API Client
import { apiClient } from "../apiClient";

import { DEFAULT_API_KEY, endpoints } from "../configs";

// Get Tag Type Value
export const getTagTypeByCategory = async value => {
  if (!value) {
    return null;
  }
  apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

  const response = await apiClient.get(
    `${endpoints().tagAPI}?tagType=${value}`
  );

  const data = response.data.data;
  if (data && data.length > 0) return data;
};
