import { DropdownItem } from "reactstrap";

import React, { useState } from "react";

// Components
import DeleteModal from "../../../../../components/base/DeleteModal";

import MoreDropdown from "../../../../../components/base/MoreDropdown";
import EditFeaturedCollection from "../EditFeaturedCollection";
import { LANDING_PAGE_FEATURED_COLLECTION } from "../../../../../landingPage/Constants";

const LandingPageBlocksItem = ({
  landingPageBLock,
  marketplaceId,
  history,
  handleDelete,
  isOpen,
  toggle,
  getLandingBlocksDetails
}) => {
  const { title, type, id } = landingPageBLock;
  const [deleteSectionModal, setdeleteSectionModal] = useState(false);
  const [selectedId, setSelectedId] = useState("");

  let detail = selectedId == landingPageBLock.id ? landingPageBLock : "";

  return (
    <div className="task row pr-4 pt-2 pb-1" style={{ minHeight: "70px" }}>
      {detail && detail.id && (
        <EditFeaturedCollection
          isOpen={isOpen}
          getLandingBlocksDetails={getLandingBlocksDetails}
          toggle={toggle}
          detail={detail !== undefined && detail}
        />
      )}
      <div className="task-left-1 col-10">
        <p className="pr-3 pt-3" style={{ cursor: "move", width: "40px" }}>
          <i className="fa fa-arrows"></i>
        </p>
        <div className="name-comments mr-2 col-4 ">
          {/*Landing Blocks Title*/}
          <span className="text-decoration-none">
            <a
              href="#"
              onClick={e => {
                e.preventDefault();

                history.push(
                  `/admin-settings/Marketplace/Landing Pages/${marketplaceId}/${id}`
                );
              }}
            >
              {title}
            </a>
          </span>
        </div>
        <div className="name-comments col-8 mr-2 ">
          <span className="pl-2">{type}</span>
        </div>
      </div>

      <div
        className="task-right col-2"
        width={"100px"}
        field="status"
        disableOnClick
      >
        {/*Show Task Actions For All Tasks Based on the Condition*/}
        <div className="task-actions col-12">
          <>
            <span className={"mt-1 d-flex justify-content-between"}>
              <div
                className="text-center landing-group-dropdown"
                cssModule={{ "modal-title": "w-100 text-center" }}
              >
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      if (type == LANDING_PAGE_FEATURED_COLLECTION) {
                        toggle();
                        setSelectedId(id);
                      } else {
                        history.push(
                          `/admin-settings/Marketplace/Landing Pages/${marketplaceId}/${id}`
                        );
                      }
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setdeleteSectionModal(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            </span>
            <DeleteModal
              isOpen={deleteSectionModal}
              toggle={() => {
                setdeleteSectionModal(prevState => !prevState);
              }}
              title="Delete Section"
              label={title}
              deleteFunction={() => handleDelete(id)}
            />
          </>
        </div>
      </div>
    </div>
  );
};
export default LandingPageBlocksItem;
