import moment from "moment";

// Constants
import * as constants from "../roles/Constants";

// Cookie
import { COOKIE_SESSION_TOKEN, COOKIE_USER_ID } from "./cookie";

// API Client
import { apiClient } from "../apiClient";
import {
  BACKGROUND_IMAGE,
  SETTINGS_PORTAL_LOGO,
  SETTINGS_PORTAL_FAV_ICON,
  SETTINGS_EXPERT_SIGNUP_HERO_IMAGE,
  SETTINGS_CUSTOMER_SIGNUP_HERO_IMAGE,
  SETTINGS_PARTNER_SIGNUP_HERO_IMAGE,
  MEDIA_BASE_URL,
  MEDIA_PATH_SETTING,
  SETTINGS_EMAIL_LOGO
} from "../setting/Constants";

/**
 * To String
 *
 * @param {*} value
 */
export function toString(value) {
  return value ? value : null;
}

/**
 * Is LoggedIn user
 *
 * @param name
 */
export function isLoggedIn() {
  const currentPath = window.location.pathname + window.location.search;
  let redirectUrl = "";
  if (currentPath) {
    redirectUrl = `?redirect=${currentPath}`;
  }

  if (!getCookie(COOKIE_SESSION_TOKEN) && !currentPath.includes("/login")) {
    // if session_token is null redirect login
    window.location.replace(`/login${redirectUrl}`);
  }
}

/**
 * Check logged in status
 *
 * @returns boolean
 */
export function getLoggedInStatus() {
  return getCookie(COOKIE_SESSION_TOKEN);
}

/**
 * Get Url Parameter

 * @param name
 */
export function getUrlParameter(name) {
  name = name.replace(/[\[]/, "\\[").replace(/[\]]/, "\\]");
  var regex = new RegExp("[\\?&]" + name + "=([^&#]*)");
  var results = regex.exec(window.location.search);
  return results === null
    ? ""
    : name === "email"
    ? decodeURIComponent(results[1])
    : decodeURIComponent(results[1].replace(/\+/g, " "));
}

/**
 * Get nth Url Path
 *
 * @returns {String}
 */
export function getUrlPath(n) {
  let currentPath = window.location.pathname;
  if (n) currentPath = currentPath.split("/")[n];
  return currentPath;
}

/**
 * Get Redirect Url

 * @param name
 */
export function getRedirectUrl() {
  const urlParams = window.location.search;
  const redirectUrlArray = urlParams.split("redirect=");
  return urlParams === null
    ? ""
    : redirectUrlArray && redirectUrlArray[1]
    ? decodeURIComponent(redirectUrlArray[1])
    : "";
}

/**
 * Get Url Parameter

 * @param password
 */
export function getpassword(password) {
  var strongRegex = new RegExp(
    "^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})"
  );
  let data;
  if (password.match(strongRegex)) {
    data = true;
  } else {
    data = false;
  }
  return data;
}

/**
 * Strong Password validation
 *
 * @param password
 * @returns error
 */
export function validateStrongPassword(password) {
  let error;

  if (password && password.length < 8) {
    error = "Must be at least 8 characters";
  } else if (
    password &&
    password.length >= 8 &&
    getpassword(password) !== true
  ) {
    error =
      "Please choose stronger password (Password should be at least 8 characters long with combination of upper case letters, lower case letters, numbers and symbols)";
  }

  return error;
}

/**
 * From Array
 *
 * @param values
 * @returns {string}
 */
export function fromArray(values) {
  const arrayData = [];
  if (values && values.length > 0) {
    values.forEach(value => {
      arrayData.push({ tagId: value.value });
    });
  }

  return arrayData ? arrayData : "";
}

export function getUserId() {
  return parseInt(getCookie(COOKIE_USER_ID));
}

/**
 * Is Super Admin
 */
export function isSuperAdmin(roleId) {
  return roleId && roleId === constants.SUPER_ADMIN_ROLE;
}

/**
 * Is Company Admin
 */
export function isCompanyAdmin(roleId) {
  return roleId && roleId === constants.COMPANY_ADMIN_ROLE;
}

/**
 * Is Company Manager
 */
export function isCompanyManager(roleId) {
  return roleId && roleId === constants.COMPANY_MANAGER_ROLE;
}

/**
 * Is Customer
 */
export function isCustomer(roleId) {
  return roleId && roleId === constants.CUSTOMER_ROLE;
}

/**
 * Is Expert
 */
export function isExpert(roleId) {
  return roleId && roleId === constants.EXPERT_ROLE;
}

/**
 * Is Customer Admin
 */
export function isCustomerAdmin(roleId) {
  return roleId && roleId === constants.CUSTOMER_ADMIN_ROLE;
}

/**
 * Is Customer Member
 */
export function isCustomerMember(roleId) {
  return roleId && roleId === constants.CUSTOMER_MEMBER_ROLE;
}

/**
 * Is Partner
 */
export function isPartner(roleId) {
  return roleId && roleId === constants.PARTNER_ROLE;
}

/**
 *
 * Get Full name by first name and last name
 * @param {*} first_name
 * @param {*} last_name
 */
export function getFullName(first_name, last_name) {
  let name = [];
  if (first_name) {
    name.push(first_name);
  }
  if (last_name) {
    name.push(last_name);
  }

  return name.join(" ");
}

/**
 * Get Current Year
 */
export function getCurrentYear() {
  return new Date().getFullYear();
}

/**
 * Get Formatted Today dd/mm/yyyy
 */
export function getFormattedTodayDate() {
  return moment().format("ll");
}

/**
 * Get params by name
 */
export function getParamsByName(e) {
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const value = params.get(e);
  return value ? value : "";
}

/**
 * Remove params by name
 */
export function removeParamsByName(name) {
  const search = window.location.search;
  const params = new URLSearchParams(search.slice(1));
  return params.delete(name);
}

/**
 * Remove phone mask
 * @param {*} phoneNumber
 */
export function removeMaskedPhoneNumber(phoneNumber) {
  return phoneNumber.replace(/[^0-9]/g, "");
}

/**
 * validate Routing Number
 * @param {*} routingNumber
 */
export function validateRoutingNumber(routingNumber) {
  if (routingNumber.length !== 9) {
    return false;
  }

  const checksumTotal =
    7 *
      (parseInt(routingNumber.charAt(0), 10) +
        parseInt(routingNumber.charAt(3), 10) +
        parseInt(routingNumber.charAt(6), 10)) +
    3 *
      (parseInt(routingNumber.charAt(1), 10) +
        parseInt(routingNumber.charAt(4), 10) +
        parseInt(routingNumber.charAt(7), 10)) +
    9 *
      (parseInt(routingNumber.charAt(2), 10) +
        parseInt(routingNumber.charAt(5), 10) +
        parseInt(routingNumber.charAt(8), 10));

  const checksumMod = checksumTotal % 10;

  if (checksumMod !== 0) {
    return false;
  } else {
    return true;
  }
}

/**
 *  Excerpt text
 *  @param {*} string
 */
export function excerptText(str = "", strLength = 0) {
  return `${str.slice(0, strLength)} ${
    str.length - 3 > strLength ? "..." : ""
  }`;
}

/**
 * Get Cookie
 *
 * @param cname
 * @returns {string}
 */
export function getCookie(cname) {
  var nameEQ = cname + "=";
  var ca = document.cookie.split(";");
  for (var i = 0; i < ca.length; i++) {
    var c = ca[i];
    while (c.charAt(0) == " ") {
      c = c.substring(1, c.length);
    }
    if (c.indexOf(nameEQ) == 0) {
      return c.substring(nameEQ.length, c.length);
    }
  }
  return "";
}

/**
 * Set Cookie
 *
 * @param cookieName
 * @param cookieValue
 * @param days
 */
export function setCookie(cookieName, cookieValue, days = 1) {
  var date, expires;
  if (days) {
    date = new Date();
    date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
    expires = "; expires=" + date.toGMTString();
  } else {
    expires = "";
  }
  document.cookie = cookieName + "=" + cookieValue + expires + "; path=/";
}

/**
 * Clear Cookie
 *
 * @param name
 */
export function clearCookie(name) {
  setCookie(name, "", -24);
}

/**
 * Mask Phone Number
 *
 * @param phone number
 */
export function maskPhoneNumber(phoneNumber) {
  const num = `(${phoneNumber.substring(0, 3)}) ${phoneNumber.substring(
    3,
    6
  )}-${phoneNumber.substring(6, phoneNumber.length)}`;
  return num;
}

/**
 * Switch expert badge colors
 * @param cases
 * @returns {string}
 */

export const setExpertBadge = cases => {
  let standardized = cases.toLowerCase();
  switch (standardized) {
    case "waiting":
      return "warning";
    case "interested":
      return "success";
    case "not interested":
      return "danger";
    case "declined":
      return "danger";
    case "no response":
      return "secondary";
    default:
      return "secondary";
  }
};

/**
 * Get Formatted Price
 *
 * @param price
 * @returns {string}
 */
export const getFormattedPrice = price => {
  if (!price) {
    return "";
  }

  price = parseInt(price, 10).toFixed(2);

  return price ? `$${price}` : "";
};

/**
 * Set API Authorization Header
 */
export const setAuthorizationHeader = callback => {
  const sessionToken = getCookie(COOKIE_SESSION_TOKEN);
  if (sessionToken) {
    apiClient.defaults.headers.common.Authorization = sessionToken;
  }
  if (callback) {
    return callback();
  }
};

/**
 * Get value by object key
 *
 * @param {*} data
 * @param {*} keyValue
 */
export const getKeyValueByObject = (data, keyValue) => {
  if (!data.length) {
    return "";
  }

  let defaultValue = "";
  Object.keys(data).forEach(function(key) {
    var value = data[key];
    if (
      // keyValue === SETTINGS_PORTAL_LOGO ||
      // keyValue === BACKGROUND_IMAGE ||
      // keyValue === SETTINGS_PORTAL_FAV_ICON ||
      // keyValue === SETTINGS_EXPERT_SIGNUP_HERO_IMAGE ||
      // keyValue === SETTINGS_PARTNER_SIGNUP_HERO_IMAGE ||
      // keyValue === SETTINGS_CUSTOMER_SIGNUP_HERO_IMAGE ||
      keyValue === SETTINGS_EMAIL_LOGO &&
      keyValue === value.name
    ) {
      defaultValue = getSettingMediaUrl(data, value.value);
    } else if (keyValue === value.name) {
      defaultValue = value.value;
    }
  });

  return defaultValue;
};

/**
 * Get value by object
 *
 * @param {*} data
 * @param {*} keyValue
 */
export const getValueByObject = (data, keyValue) => {
  if (!data.length) {
    return "";
  }
  const obj = data.find(url => url.label === keyValue);
  const SocialMedialink = obj.value;
  return SocialMedialink;
};

/**
 * Short Timezone
 *
 * @param {*} value
 */
export function shortTimeZone(value) {
  return value.slice(1, 10);
}

// Sort Array by order
export function sortNameByOrder(arrays) {
  return (
    arrays &&
    arrays.length > 0 &&
    arrays.sort(function(a, b) {
      return a.label.localeCompare(b.label);
    })
  );
}

/**
 * Get setting media url
 *
 * @param {*} settings
 * @param {*} filePath
 */
export const getSettingMediaUrl = (settings, filePath) => {
  if (!settings.length) {
    return "";
  }

  let mediaUrl = "";
  Object.keys(settings).forEach(function(key) {
    var value = settings[key];
    if (value.name === MEDIA_BASE_URL) {
      mediaUrl = `${value.value}/${MEDIA_PATH_SETTING}/${filePath}`;
    }
  });

  return mediaUrl;
};

/**
 * Get Formatted Price
 *
 * @param price
 * @returns {string}
 */
export const getBaseUrlParam = () => {
  const baseUrl = window.location.origin;
  return baseUrl ? `baseUrl=${baseUrl}` : "";
};

//String to URL
export const getStringToUrl = str => {
  if (!str) {
    return null;
  }
  try {
    str = str
      .toLowerCase()
      .replace("/", "")
      .replace(/\s+/g, " ")
      .replace(/ /g, "-")
      .replace(/_/g, "-")
      .replace(/[^\w-]+/g, "");
    return str;
  } catch (e) {
    return "";
  }
};

/**
 * Get Formatted Message Date
 *
 * @param date
 * @returns {string}
 */
export const getFormattedMessageDateTime = value => {
  const formattedDateTime = moment(value).format("DD MMM, Y h:mm A");

  const todayDate = moment(new Date()).format("DD MMM, YYYY");

  const yesterdayDate = moment()
    .subtract(1, "days")
    .format("DD MMM, YYYY");

  const messageTime = moment(value).format("h:mm A");
  let messageDate = moment(value).format("DD MMM, YYYY");

  messageDate =
    todayDate === messageDate
      ? `Today at ${messageTime}`
      : yesterdayDate === messageDate
      ? `Yesterday at ${messageTime}`
      : formattedDateTime;

  return messageDate;
};

/**
 * Is data Empty
 * @param data
 */
export const isEmpty = data => {
  if (data && data.length > 0) {
    return true;
  } else return null;
};

/**
 * Is data Empty
 * @param data
 */
export const getSlicedString = (string, count) => {
  var updatedString = "";
  if (string && string.length > 0) {
    updatedString =
      string.slice(0, count) + (string.length > count ? "..." : "");
  }
  return updatedString;
};
