import React from "react";
import Page404 from "../Page404";
import toast from "../../components/base/Toast";
import ProjectFormFields from "./ProjectFormFields";
import DiscussionList from "./DiscussionList";
import AddDiscussion from "./AddDiscussion";
import moment from "moment";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import { toString } from "../../lib/helper";

// Components
import DeleteButton from "../../components/base/DeleteButton";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import Form from "../../components/base/Form";
import { isBadRequest } from "../../common/http";

export default class UpdateProjectForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      projectDetails: [],
      loading: false,
      isInvalidRequest: false,
      desiredStartDate: ""
    };
  }

  componentDidMount = () => {
    this.getProjectDetails();
  };

  setDate = (date, stateProperty) => {
    this.setState({ [stateProperty]: date });
  };

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save name form in the final
    this._updateProject(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define project constants
    const name = values.name;

    if (!name) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.name = toString(values.name);
    values.billingType = values.billingType ? values.billingType.value : null;
    values.status = toString(
      values.status ? values.status.value : values.status
    );
    values.customerName = values.customer
      ? toString(values.customer.label)
      : null;
    values.customerId = values.customer ? values.customer.value : null;
    values.desiredStartDate = this.state.desiredStartDate
      ? moment(this.state.desiredStartDate).format("YYYY-MM-DD")
      : "";
    return values;
  }

  // Project update API Call
  _updateProject(data) {
    return apiClient
      .put(`${endpoints().projectAPI}/${this.props.match.params.id}`, data)
      .then(() => {
        window.location.replace("/projects");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Project delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().projectAPI}/${this.props.match.params.id}`)
      .then(() => {
        window.location.replace("/projects");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Project details api call by id
  getProjectDetails = () => {
    return apiClient
      .get(`${endpoints().projectAPI}/${this.props.match.params.id}`)
      .then(response => {
        this.setState({
          projectDetails: response.data,
          loading: true,
          desiredStartDate: response.data.desiredStartDate
            ? new Date(response.data.desiredStartDate)
            : ""
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return "";
    }

    const projectId = this.props.match.params.id;

    let { status } = this.state.projectDetails;

    status = {
      label: status ? status : "",
      value: status ? status : ""
    };

    const {
      name,
      summary,
      projectCategory,
      description,
      requirements,
      methodology,
      outOfScope,
      changeManagement,
      toolsExpectedExpertHas,
      industryOfRelevance,
      deliverables,
      estimatedTimeline,
      estimatedBudget,
      desiredStartDate,
      desiredCommitments,
      estimatedLength,
      locationPreference,
      customerName,
      customerId,
      billingType,
      paymentType
    } = this.state.projectDetails;

    const projectdeliverables = [];
    if (Array.isArray(deliverables) && deliverables.length > 0) {
      deliverables.forEach(deliverable => {
        if (deliverable.description) {
          projectdeliverables.push(deliverable.description);
        }
      });
    }

    const initialValues = {
      name,
      summary,
      projectCategory,
      description,
      requirements,
      methodology,
      outOfScope,
      changeManagement,
      toolsExpectedExpertHas,
      industryOfRelevance,
      deliverables:
        projectdeliverables.length > 0
          ? projectdeliverables.join(", ").toString()
          : "",
      estimatedTimeline,
      estimatedBudget,
      desiredStartDate,
      desiredCommitments,
      estimatedLength,
      locationPreference,
      status,
      billingType: billingType
        ? {
            value: billingType,
            label: billingType
          }
        : null,
      paymentTerms: paymentType,
      customer: customerName ? { value: customerId, label: customerName } : null
    };

    return (
      <div>
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            this._submit(values);
          }}
        >
          <ProjectFormFields
            setDate={this.setDate}
            desiredStartDate={this.state.desiredStartDate}
          />

          <div className="mb-4">
            <DeleteButton
              label={"Delete Project"}
              onClick={this.handleDelete}
            />
            <div className="float-right">
              <CancelButton onClick={() => this.props.history.goBack()} />
              <SaveButton />
            </div>
          </div>
        </Form>

        <AddDiscussion projectId={projectId} />
        <DiscussionList projectId={projectId} />
      </div>
    );
  }
}
