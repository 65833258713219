import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

import { DropdownItem } from "reactstrap";

// Action
import {
  updateCompanyStatus,
  deleteCompanyById
} from "../../../../actions/company";

// Components
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import MoreDropdown from "../../../../components/base/MoreDropdown";
import DeleteModal from "../../../../components/base/DeleteModal";

// Helper
import { isLoggedIn } from "../../../../lib/helper";
import { endpoints } from "../../../../configs";
// API call

// Constants
import {
  COMPANY_STATUS_APPROVED,
  COMPANY_STATUS_PENDING,
  COMPANY_STATUS_DECLINED
} from "../../../../Company/Constants";
import {
  COMPANY_GENERAL_SECTION,
  TAB_COMPANIES
} from "../../../../supportPortal/Constants";
import CompanyDetails from "./CompanyDetails";
import AddCompanyModal from "./AddCompanyModal";
import companyService from "../../../../services/companyService";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class CompaniesTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteCompany: "",
      companyList: [],
      deleteCampanyModal: false,
      sortByOptions: [
        {
          value: "name:ASC",
          label: "Company Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ]
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this._getCompanyList();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  _getCompanyList = async () => {
    try {
      let response = await companyService.list();

      this.setState({ companyList: response });
    } catch (err) {
      console.log(err);
    }
  };

  // Update company status handler
  updateCompanyByStatus = (status, companyId) => {
    this.props.actions.updateCompanyStatus(
      companyId,
      { status: status },
      {
        sort: this.props.companiesSort,
        sortDir: this.props.companiesSortDir,
        pagination: true
      }
    );
  };

  handleDelete = row => {
    this.setState({ deleteCompany: row, deleteCompanyModal: true });
  };

  // Update company status handler
  deleteCompany = companyId => {
    this.props.actions.deleteCompanyById(companyId, {
      sort: this.props.companiesSort,
      sortDir: this.props.companiesSortDir,
      pagination: true
    });
  };

  render() {
    const {
      activeTab,
      activeSubTab,
      activeSection,
      history,
      match
    } = this.props;

    const { deleteCompanyModal, deleteCompany, companyList } = this.state;

    const headerButton = (
      <div className="btn-wrapper pull-right no-gutters mt-n5">
        <AddCompanyModal companyList={companyList} />
      </div>
    );

    if (activeSubTab)
      return (
        <CompanyDetails
          companyId={activeSubTab}
          activeTab={activeTab}
          activeSubTab={activeSubTab}
          activeSection={activeSection}
          history={history}
          match={match}
          updateStatus={this.updateCompanyByStatus}
        />
      );
    else
      return (
        <>
          {/* /.page-heading */}
          {headerButton}
          <div className="mt-4 mb-5">
            <ReduxTable
              id="companies"
              showHeader
              searchPlaceholder="Search"
              apiURL={endpoints().companyAPI}
              onRowClick={row =>
                this.props.history.push(
                  `/support-portal/${TAB_COMPANIES}/${row.id}/${COMPANY_GENERAL_SECTION}`
                )
              }
              newTableHeading
              sortByOptions={this.state.sortByOptions}
              paramsToUrl={true}
              history={this.props.history}
            >
              <ReduxColumn
                width={"126px"}
                type="link"
                isClickable="false"
                field="companyName"
                sortBy="name"
                renderField={row => (
                  <Link
                    to={`/support-portal/${TAB_COMPANIES}/${row.id}/${COMPANY_GENERAL_SECTION}`}
                  >
                    {row.companyName}
                  </Link>
                )}
              >
                Company
              </ReduxColumn>
              <ReduxColumn width={"126px"} field="email" sortBy="email">
                Email
              </ReduxColumn>
              <ReduxColumn
                width={"121px"}
                maxWidth={"121px"}
                field="status"
                disableOnClick
                renderField={row => (
                  <div
                    className={`text-center text-uppercase ${
                      row.status !== COMPANY_STATUS_PENDING
                        ? row.status === COMPANY_STATUS_DECLINED
                          ? "bg-danger"
                          : "landing-page-status-active"
                        : "landing-page-status-draft"
                    }`}
                    style={statusStyle}
                  >
                    <p>{row.status}</p>
                  </div>
                )}
              >
                Status
              </ReduxColumn>
              <ReduxColumn width={"135px"} field="createdAt" sortBy="createdAt">
                Created At
              </ReduxColumn>
              <ReduxColumn
                width={"100px"}
                field="Action"
                disableOnClick
                renderField={row => (
                  <div className="text-center landing-group-dropdown">
                    <MoreDropdown>
                      {row.status !== COMPANY_STATUS_APPROVED && (
                        <DropdownItem
                          onClick={() =>
                            this.updateCompanyByStatus(
                              COMPANY_STATUS_APPROVED,
                              row.id
                            )
                          }
                        >
                          Approve
                        </DropdownItem>
                      )}
                      {row.status !== COMPANY_STATUS_DECLINED && (
                        <DropdownItem
                          onClick={() =>
                            this.updateCompanyByStatus(
                              COMPANY_STATUS_DECLINED,
                              row.id
                            )
                          }
                        >
                          Decline
                        </DropdownItem>
                      )}

                      <DropdownItem onClick={() => this.handleDelete(row)}>
                        Delete
                      </DropdownItem>
                    </MoreDropdown>
                  </div>
                )}
              >
                Action
              </ReduxColumn>
            </ReduxTable>
            <DeleteModal
              id="deleteCompany"
              isOpen={deleteCompanyModal}
              toggle={() => {
                this.setState({ deleteCompanyModal: false });
              }}
              title="Delete Company"
              label={deleteCompany.companyName}
              deleteFunction={() => this.deleteCompany(deleteCompany.id)}
            />
          </div>
        </>
      );
  }
}

// export default Companies;

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { updateCompanyStatus, deleteCompanyById },
      dispatch
    )
  };
}

const mapStateToProps = state => {
  const { companies } = state.table;
  const companiesSort =
    companies && !companies.isFetching && companies.sort !== undefined
      ? companies.sort
      : "name";

  const companiesSortDir =
    companies && !companies.isFetching && companies.sortDir !== undefined
      ? companies.sortDir
      : "ASC";

  return { companiesSort, companiesSortDir };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(CompaniesTab);
