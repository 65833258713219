//Feedback TAB Start
export const FEEDBACK_STATUS_AWAITING = "awaiting";
export const FEEDBACK_STATUS_PREVIOUS = "previous";
export const FEEDBACK_STATUS_RECEIVED = "received";
export const FEEDBACK_STATUS_PENDING = "pending";
export const FEEDBACK_STATUS_APPROVED = "approved";
//Feedback TAB status Ends

//Feedback TAB Label Start
export const FEEDBACK_STATUS_AWAITING_LABEL = "Awaiting Feedback";
export const FEEDBACK_STATUS_PREVIOUS_LABEL = "Previous Feedback";
export const FEEDBACK_STATUS_RECEIVED_LABEL = "Feedback Received";
export const FEEDBACK_STATUS_PENDING_LABEL = "Pending Feedback";
export const FEEDBACK_STATUS_APPROVED_LABEL = "Approved Feedback";
//Feedback TAB Label Ends

// Review Category starts
export const FEEDBACK_EXPERT_CATEGORY = "Expert";
export const FEEDBACK_PLAYBOOK_CATEGORY = "Playbook";
// Review Category  block type ends

// Review Status start
export const FEEDBACK_REVIEW_STATUS_AWAITING = "Awaiting";
export const FEEDBACK_REVIEW_STATUS_PENDING = "Pending";
export const FEEDBACK_REVIEW_STATUS_APPROVED = "Approved";
export const FEEDBACK_REVIEW_STATUS_PREVIOUS = [
  FEEDBACK_REVIEW_STATUS_PENDING,
  FEEDBACK_REVIEW_STATUS_APPROVED
].join(",");
// Review Status ends

// Feedback Table start
export const FEEDBACK_TABLE_PENDING = "pendingFeedback";
export const FEEDBACK_TABLE_APPROVED = "approvedFeedback";
export const FEEDBACK_TAB = "Feedback";
// Feedback Table ends

// Feedback Action Label Start
export const FEEDBACK_APPROVE_LABEL = "Approve Feedback";
export const FEEDBACK_UNAPPROVE_LABEL = "Unapprove Feedback";
// Feedback Action Label End
