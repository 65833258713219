import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

import Form from "../../../../components/base/Form";

import AsyncSelect from "../../../../components/base/AsyncSelect";

const ProspectInspectModal = props => {
  const {
    isInspectAccountModalOpen,
    referralDetails,
    defaultAccountSearchTerm,
    currentAccountSearchTerm,
    getSearchedOptionList,
    setProspectInspectModalValues
  } = props;
  return (
    <>
      <Modal
        isOpen={isInspectAccountModalOpen}
        className={["log-hours-modal", "edit-task-modal"].join(" ")}
      >
        <ModalHeader
          toggle={() => {
            setProspectInspectModalValues({
              isHubSpotProspectAccountSelectModalOpen: false,
              currentAccountSearchTerm: ""
            });
          }}
        >
          <h4 className={["font-weight-bold", "mt-3"].join(" ")}>
            Check Partial Matches
          </h4>
        </ModalHeader>
        <ModalBody className={["text-center", "mb-3"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <h4 className="mx-4 text-danger font-weight-bold">
                “{referralDetails.account_name}”
              </h4>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["pr-0 pl-0"].join(" ")}>
          <div className="text-left">
            <h5 className={["font-weight-bold"].join(" ")}>
              Matching Companies in HubSpot
            </h5>
            <div className="text-muted">
              <span style={{ fontSize: "12px" }}>
                If the account name already exists in HubSpot, please select the
                correct one from the names in the list below and continue to the
                next screen.
              </span>
            </div>
          </div>
          <div className="d-block w-100">
            <Form
              initialValues={{
                accounts: {
                  label: "",
                  value: ""
                }
              }}
              onSubmit={values => {
                // Set selected salesforce account id in state
                setProspectInspectModalValues({
                  selectedHubSpotCompany: values.company,
                  currentAccountSearchTerm: "",
                  isHubSpotProspectAccountSelectModalOpen: false,
                  isHubSpotPartialMatchConfirmationModalOpen: true
                });
              }}
            >
              <AsyncSelect
                name="company"
                placeholder="Search Company Name..."
                defaultSearchTerm={defaultAccountSearchTerm}
                currentSearchTerm={currentAccountSearchTerm}
                getSearchedOptionList={getSearchedOptionList}
              />
              <div className="btn-wrapper text-center">
                <button className="btn btn-primary">Next</button>
              </div>
            </Form>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ProspectInspectModal;
