import React, { Component } from "react";

import { Modal, ModalBody, ModalHeader } from "reactstrap";
import CancelButton from "../../../../components/base/CancelButton";

// Components
import Number from "../../../../components/base/Number";
import Form from "../../../../components/base/Form";
import Text from "../../../../components/base/Text";
import Country from "../../../../components/base/Country";
import State from "../../../../components/State";
import Zipcode from "../../../../components/base/Zipcode";
import Select from "../../../../components/base/Select";
// API Client
import { apiClient } from "../../../../../src/apiClient";

// Configs
import { endpoints } from "../../../../../src/configs";
// constans
import {
  PAYMENT_OPTION_ACH,
  PAYMENT_OPTION_WIRE_TRANSFER,
  PAYMENT_OPTION_INTERNATIONAL
} from "../../../../Constants/PaymentOptions";

import { paymentOptions } from "../../../../Constants/PaymentOptions";

class AddPaymentOption extends Component {
  constructor(props) {
    super(props);
    this.state = {
      paymentType: "",
      selectedOption: "",
      selectedCountryName: ""
    };
  }

  componentDidMount() {
    this.getCountryDetails();
  }

  handleChange = e => {
    let type = e.value;
    this.setState({ paymentType: type });
  };

  toggleModal = () => {
    this.props.Toggle();
    this.setState({ paymentType: "" });
  };

  // Get Country Details
  getCountryDetails = countryName => {
    return apiClient
      .get(`${endpoints().countryAPI}/${countryName}`)
      .then(response => {
        const data = response.data;
        this.setState({ selectedCountry: data.id });
      });
  };

  // Handle Country
  handleCountryChange = ({ values }) => {
    const selectedOption = values.country && values.country.id;
    const selectedCountryName = values.country && values.country.label;
    this.setState({
      selectedOption,
      selectedCountryName: selectedCountryName
    });
  };

  render() {
    const { selectedCountryName, selectedOption, paymentType } = this.state;
    const { isOpen, paymentTypes } = this.props;

    const paymentOption = paymentType ? paymentType : paymentTypes;

    return (
      <Modal
        isOpen={isOpen}
        toggle={this.toggleModal}
        backdrop="static"
        className={["edit-task-modal", "edit-billing-modal"].join(" ")}
      >
        <ModalHeader toggle={this.toggleModal} tag={"div"}>
          {paymentOption === PAYMENT_OPTION_ACH && (
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>Add ACH</h4>
          )}
          {paymentOption === PAYMENT_OPTION_WIRE_TRANSFER && (
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
              Add Wire Transfer
            </h4>
          )}
          {paymentOption === PAYMENT_OPTION_INTERNATIONAL && (
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
              Add International
            </h4>
          )}
        </ModalHeader>
        <ModalBody>
          <Form
            initialValues={{
              routingNumber: "",
              accountNumber: "",
              name: "",
              address: "",
              city: "",
              country: "",
              state: "",
              postal_code: "",
              ibanNumber: "",
              swiftCode: "",
              bankName: "",
              paymentType: paymentOption
                ? paymentOptions.find(type => type.value === paymentOption)
                : ""
            }}
            enableReinitialize={true}
            onSubmit={values => {
              values.paymentType = paymentOption;
              let newValues = Object.assign({}, values);
              this.props.paymentMethodSubmit(newValues);
              this.toggleModal();
            }}
          >
            <div className="form-wrapper">
              <div className="field-wrapper">
                <Select
                  id="paymentType"
                  name="paymentType"
                  label="Payment Type"
                  options={paymentOptions}
                  handleChange={e => this.handleChange(e)}
                  error=""
                  required={true}
                />
              </div>
              {paymentOption === PAYMENT_OPTION_INTERNATIONAL && (
                <>
                  <div className="field-wrapper">
                    <Text
                      name="ibanNumber"
                      label="IBAN Number"
                      placeholder="Enter IBAN Number"
                      error=""
                      required
                    />
                  </div>
                  <div className="field-wrapper">
                    <Text
                      name="swiftCode"
                      label="SWIFT Code"
                      placeholder="Enter SWIFT Code"
                      error={""}
                      required={true}
                    />
                  </div>
                  <div className="field-wrapper">
                    <Text
                      name="bankName"
                      label="Bank Name"
                      placeholder="Enter Bank Name"
                      required={true}
                      error=""
                    />
                  </div>
                </>
              )}
              {(paymentOption === PAYMENT_OPTION_WIRE_TRANSFER ||
                paymentOption === PAYMENT_OPTION_INTERNATIONAL) && (
                <>
                  <div className="field-wrapper">
                    <Text
                      name="name"
                      label="Name"
                      placeholder="Enter name"
                      error={""}
                      required={true}
                    />
                  </div>
                  <div className="field-wrapper">
                    <Text
                      name="address"
                      label="Address"
                      placeholder="Enter Address"
                      error=""
                      required={true}
                    />
                    <Text
                      name="city"
                      label="City"
                      placeholder="Enter city"
                      required={true}
                      error=""
                    />
                  </div>
                  <div className="field-wrapper">
                    <Country
                      name="country"
                      label="Country"
                      placeholder="Enter Country"
                      error=""
                      required={true}
                      onChange={this.handleCountryChange}
                    />
                  </div>
                  <div className="field-wrapper">
                    <State
                      name="state"
                      label="State/Province/Region"
                      placeholder="Enter State"
                      error=""
                      required={true}
                      selectedCountry={
                        selectedOption
                          ? selectedOption
                          : this.state.selectedCountry
                      }
                      selectedCountryName={
                        selectedCountryName ? selectedCountryName : ""
                      }
                    />
                    <Zipcode
                      name="postal_code"
                      label="Zip/Postal Code"
                      placeholder="Enter Zip"
                      error=""
                      required={true}
                    />
                  </div>
                </>
              )}
            </div>
            <div className="form-wrapper">
              {(paymentOption === PAYMENT_OPTION_ACH ||
                paymentOption === PAYMENT_OPTION_WIRE_TRANSFER) && (
                <div className="field-wrapper">
                  <Number
                    name="routingNumber"
                    label="Routing Number"
                    placeholder="Enter Routing Number"
                    error=""
                    required
                  />
                  <Number
                    name="accountNumber"
                    label="Account Number"
                    placeholder="Enter Account Number"
                    error=""
                    required
                  />
                </div>
              )}

              <div className="btn-wrapper mt-4 d-flex justify-content-center">
                <CancelButton onClick={this.toggleModal} className="mr-2" />
                <button
                  type="submit"
                  className="btn btn-secondary h6-5-important m-1"
                  onContextMenu={e => {
                    e.preventDefault();
                  }}
                >
                  <span>Add</span>
                </button>
              </div>
            </div>
          </Form>
        </ModalBody>
      </Modal>
    );
  }
}

export default AddPaymentOption;
