import React, { useEffect, useState } from "react";
import { Table } from "reactstrap";
import { apiClient } from "../../../../apiClient";
import { isBadRequest } from "../../../../common/http";
import PageSearch from "../../../../components/base/PageSearch";
import PageTitle from "../../../../components/base/PageTitle";
import { endpoints } from "../../../../configs";
import toast from "../../../../components/base/Toast";
import moment from "moment";
import { isTextExistInString } from "../../../../common/utils";

import OpportunityAuditTableData from "./OpportunityAuditTableData";

function OpportunityAudit() {
  const [opportunityAuditData, setOpportunityAuditData] = useState([]);

  // Get Opportunity Audit Data
  const getData = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().salesforceLookupAPI}/opportunityAudit/search`
      );
      const setData = response && response.data && response.data.data;
      setOpportunityAuditData(setData);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  useEffect(() => {
    getData();
  }, []);

  return (
    <div>
      <PageTitle label="OpportunityAudit" />
      <div
        className={`page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column`}
      >
        <>
          <PageSearch
            classnames="page-search"
            placeholder="Search"
            // onChange={e => this.handleSearchTermChange(e.target.value)}
          />
        </>
      </div>
      {/* Opportunity Audit List */}
      <div className="overflow-auto">
        <Table className="table-hover bg-white">
          <thead className="thead-dark table-bordered">
            <tr>
              <th style={{ minWidth: "135px" }}>Source</th>
              <th>oppportunity_id</th>
              <th>opportunity_name</th>
              <th>opportunity_stage</th>
              <th>opportunity_amount</th>
              <th>opportunity_notes</th>

              <th>opportunity_owner_id</th>
              <th>opportunity_owner_first_name</th>
              <th>opportunity_owner_last_name</th>
              <th>opportunity_owner_email</th>

              <th>opportunity_created_by_id</th>
              <th>opportunity_created_by_first_name</th>
              <th>opportunity_created_by_last_name</th>
              <th>opportunity_created_by_email</th>
              <th>opportunity_created_at</th>

              <th>partner_account_id</th>
              <th>partner_account_name</th>

              <th>partner_contact_id</th>
              <th>partner_contact_first_name</th>
              <th>partner_contact_last_name</th>
              <th>partner_contact_email</th>
              <th>partner_contact_phone</th>

              <th>prospect_account_id</th>
              <th>prospect_account_name</th>

              <th>prospect_contact_id</th>
              <th>prospect_contact_first_name</th>
              <th>prospect_contact_last_name</th>
              <th>prospect_contact_email</th>
              <th>prospect_contact_phone</th>
            </tr>
          </thead>
          <tbody>
            {opportunityAuditData && opportunityAuditData.length > 0 ? (
              opportunityAuditData.map(data => {
                let {
                  referralData,
                  opportunityAccountData,
                  ownerData,
                  partnerAccountData,
                  salesforceData,
                  partnerContactData,
                  prospectAccountData,
                  propspectContactData
                } = data;

                return (
                  <tr>
                    <td>
                      <span>
                        Torchlite PRM : <br />
                        Salesforce :
                      </span>
                    </td>
                    {/* oppportunity_id */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          referralData && referralData.salesforce_opportunity_id
                        }
                        value2={salesforceData && salesforceData.opportunity_id}
                      />
                    </td>

                    {/* opportunity_name */}
                    <td>
                      <span
                        className={`${referralData &&
                          !referralData.account_name &&
                          "text-danger"}`}
                      >
                        {referralData && referralData.account_name
                          ? referralData.account_name
                          : "NOT AVAILABLE"}
                      </span>
                      <br />
                      <span
                        className={`${(!salesforceData ||
                          (salesforceData &&
                            !salesforceData.opportunity_name)) &&
                          "text-danger"} ${isTextExistInString(
                          referralData && referralData.account_name,
                          salesforceData && salesforceData.opportunity_name
                        ) == false && "text-danger"}`}
                      >
                        {salesforceData && salesforceData.opportunity_name
                          ? salesforceData.opportunity_name
                          : "NOT AVAILABLE"}
                      </span>
                    </td>
                    {/* opportunity_stage */}
                    <td>
                      <OpportunityAuditTableData
                        value1={referralData && referralData.stage}
                        value2={
                          salesforceData && salesforceData.opportunity_stage
                        }
                      />
                    </td>
                    {/* opportunity_amount */}
                    <td>
                      <OpportunityAuditTableData
                        value1={referralData && referralData.amount}
                        value2={
                          salesforceData && salesforceData.opportunity_amount
                        }
                      />
                    </td>
                    {/* opportunity_notes */}
                    <td>
                      <OpportunityAuditTableData
                        value1={referralData && referralData.notes}
                        value2={
                          salesforceData && salesforceData.opportunity_notes
                        }
                      />
                    </td>
                    {/* opportunity_owner_id */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          referralData && referralData.owner_salesforce_id
                        }
                        value2={
                          salesforceData && salesforceData.opportunity_owner_id
                        }
                      />
                    </td>
                    {/* opportunity_owner_first_name */}
                    <td>
                      <OpportunityAuditTableData
                        value1={ownerData && ownerData.first_name}
                        value2={
                          salesforceData &&
                          salesforceData.opportunity_owner_first_name
                        }
                      />
                    </td>
                    {/* opportunity_owner_last_name */}
                    <td>
                      <OpportunityAuditTableData
                        value1={ownerData && ownerData.last_name}
                        value2={
                          salesforceData &&
                          salesforceData.opportunity_owner_last_name
                        }
                      />
                    </td>
                    {/* opportunity_owner_email */}
                    <td>
                      <OpportunityAuditTableData
                        value1={ownerData && ownerData.email}
                        value2={
                          salesforceData &&
                          salesforceData.opportunity_owner_email
                        }
                      />
                    </td>
                    {/* opportunity_created_by_id */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          opportunityAccountData &&
                          opportunityAccountData.salesforce_account_id
                        }
                        value2={
                          salesforceData &&
                          salesforceData.opportunity_created_by_id
                        }
                      />
                    </td>
                    {/* opportunity_created_by_first_name */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          opportunityAccountData &&
                          opportunityAccountData.first_name
                        }
                        value2={
                          salesforceData &&
                          salesforceData.opportunity_created_by_first_name
                        }
                      />
                    </td>
                    {/* opportunity_created_by_last_name */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          opportunityAccountData &&
                          opportunityAccountData.last_name
                        }
                        value2={
                          salesforceData &&
                          salesforceData.opportunity_created_by_last_name
                        }
                      />
                    </td>
                    {/* opportunity_created_by_email */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          opportunityAccountData && opportunityAccountData.email
                        }
                        value2={
                          salesforceData &&
                          salesforceData.opportunity_created_by_email
                        }
                      />
                    </td>
                    {/* opportunity_created_at */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          referralData &&
                          referralData.createdAt &&
                          moment(referralData && referralData.createdAt).format(
                            "MM/DD/YYYY"
                          )
                        }
                        value2={
                          salesforceData &&
                          salesforceData.opportunity_created_at &&
                          moment(
                            salesforceData &&
                              salesforceData.opportunity_created_at
                          ).format("MM/DD/YYYY")
                        }
                      />
                    </td>
                    {/* partner_account_id */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          partnerAccountData &&
                          partnerAccountData.salesforce_account_id
                        }
                        value2={
                          salesforceData && salesforceData.partner_account_id
                        }
                      />
                    </td>
                    {/* partner_account_name */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          partnerAccountData && partnerAccountData.company_name
                        }
                        value2={
                          salesforceData && salesforceData.partner_account_name
                        }
                      />
                    </td>
                    {/* partner_contact_id */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          partnerContactData &&
                          partnerContactData.salesforce_contact_id
                        }
                        value2={
                          salesforceData && salesforceData.partner_contact_id
                        }
                      />
                    </td>
                    {/* partner_contact_first_name */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          partnerContactData && partnerContactData.first_name
                        }
                        value2={
                          salesforceData &&
                          salesforceData.partner_contact_first_name
                        }
                      />
                    </td>
                    {/* partner_contact_last_name */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          partnerContactData && partnerContactData.last_name
                        }
                        value2={
                          salesforceData &&
                          salesforceData.partner_contact_last_name
                        }
                      />
                    </td>
                    {/* partner_contact_email */}
                    <td>
                      <OpportunityAuditTableData
                        value1={partnerContactData && partnerContactData.email}
                        value2={
                          salesforceData && salesforceData.partner_contact_email
                        }
                      />
                    </td>
                    {/* partner_contact_phone */}
                    <td>
                      <OpportunityAuditTableData
                        value1={partnerContactData && partnerContactData.phone}
                        value2={
                          salesforceData && salesforceData.partner_contact_phone
                        }
                      />
                    </td>
                    {/* prospect_account_id */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          prospectAccountData &&
                          prospectAccountData.salesforce_account_id
                        }
                        value2={
                          salesforceData && salesforceData.prospect_account_id
                        }
                      />
                    </td>
                    {/* prospect_account_name */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          prospectAccountData &&
                          prospectAccountData.company_name
                        }
                        value2={
                          salesforceData && salesforceData.prospect_account_name
                        }
                      />
                    </td>
                    {/* prospect_contact_id */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          propspectContactData &&
                          propspectContactData.salesforce_contact_id
                        }
                        value2={
                          salesforceData && salesforceData.prospect_contact_id
                        }
                      />
                    </td>
                    {/* prospect_contact_first_name */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          propspectContactData &&
                          propspectContactData.first_name
                        }
                        value2={
                          salesforceData &&
                          salesforceData.prospect_contact_first_name
                        }
                      />
                    </td>
                    {/* prospect_contact_last_name */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          propspectContactData && propspectContactData.last_name
                        }
                        value2={
                          salesforceData &&
                          salesforceData.prospect_contact_last_name
                        }
                      />
                    </td>
                    {/* prospect_contact_email */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          propspectContactData && propspectContactData.email
                        }
                        value2={
                          salesforceData &&
                          salesforceData.prospect_contact_email
                        }
                      />
                    </td>
                    {/* prospect_contact_phone */}
                    <td>
                      <OpportunityAuditTableData
                        value1={
                          propspectContactData && propspectContactData.phone
                        }
                        value2={
                          salesforceData &&
                          salesforceData.prospect_contact_phone
                        }
                      />
                    </td>
                  </tr>
                );
              })
            ) : (
              <tr>
                <td className="text-center p-4" colSpan={5}>
                  {"No Results Found"}
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>
    </div>
  );
}
export default OpportunityAudit;
