import React, { useState, useEffect } from "react";
import moment from "moment-timezone";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import Form from "../../../components/base/Form";
import Text from "../../../components/Text";
import Radio from "../../../components/base/Radio";
import PreferredMethodOfCommunication from "../../../components/PreferredMethodOfCommunication";
import KickoffTimeZone from "../../../components/KickoffTimeZone";
import CancelButton from "../../../components/base/CancelButton";

// Inner Components
import KickOffTimePicker from "./kickoff/KickOffTimePicker";

// API Client
import { apiClient } from "../../../apiClient";

// Constants
import {
  COMMUNICATION_METHOD_WEB_CONFERERENCE,
  COMMUNICATION_METHOD_PHONE,
  WEB_CONFERERENCE_TYPE_PROVIDE_CONFERERENCE_URL,
  WEB_CONFERERENCE_TYPE_TORCHLITE
} from "../../../kickOffCall/Constants";

const EditKickoffScheduleModal = React.memo(function RescheduleModal({
  isOpen,
  toggle,
  kickoffData,
  kickoffLoaded,
  projectId,
  expertId,
  getKickoffTimes
}) {
  // Scheduled
  const [defaultTimezone] = useState("America/New_York");
  const [visualTimezone, setVisualTimezone] = useState("America/New_York");
  const [availableTimes, setAvailableTimes] = useState([]);
  const [expertAvailableTimes, setExpertAvailableTimes] = useState([]);
  const [selectedDates, setSelectedDates] = useState([]);
  const [setSelectedDateId] = useState(null);
  const [webConferenceSource, setWebConferenceSource] = useState(
    (kickoffData && kickoffData.web_conference_source) ||
      WEB_CONFERERENCE_TYPE_TORCHLITE
  );

  const webConferenceSourceList = [
    WEB_CONFERERENCE_TYPE_TORCHLITE,
    WEB_CONFERERENCE_TYPE_PROVIDE_CONFERERENCE_URL
  ];

  const [
    preferredCommunicationMethod,
    setPreferredCommunicationMethod
  ] = useState(kickoffData.communicationMethod);

  const updateTimezone = values => {
    const timezone =
      values.values && values.values.timezone && values.values.timezone.value
        ? values.values.timezone.value
        : defaultTimezone;
    setVisualTimezone(timezone);
  };

  const updateSelectedDates = dates => {
    setSelectedDates(dates);
  };

  const updateSelectedDateId = id => {
    setSelectedDateId(id);
  };

  const handleWebConferenceSource = e => {
    setWebConferenceSource(e.target.value);
  };

  const handleCommunicationMethod = method => {
    setPreferredCommunicationMethod(
      method.values.communication_method
        ? method.values.communication_method.label
        : communicationMethod
    );
  };

  let {
    communicationMethod,
    meeting_link,
    meeting_id,
    meeting_password,
    timezone,
    phone
  } = kickoffData;

  communicationMethod = {
    label: communicationMethod,
    value: communicationMethod
  };

  // Update Kickoff Time
  const updateKickoffTime = async values => {
    const res = await apiClient.put(
      `/v1/project/${projectId}/kickoff/${expertId}`,
      values
    );
    if (res) {
      getKickoffTimes(true);
      toggle();
    }
  };

  useEffect(() => {
    const expertTimes = [];
    const expertList = [];
    const timezone = kickoffData && kickoffData.timezone;
    kickoffLoaded &&
      kickoffData.times.map(time => {
        expertList.push(
          `${time.date}T${time.time}${moment()
            .tz(timezone)
            .format("Z")}`
        );
        expertTimes.push({
          time: `${time.date}T${time.time}${moment()
            .tz(timezone)
            .format("Z")}`,
          id: time.id
        });
      });
    setVisualTimezone(timezone);
    setAvailableTimes(expertTimes);
    setExpertAvailableTimes(expertList);
  }, []);

  // API Consumptions Below
  const getSelectedDays = (dates, tz) => {
    let daysArray = dates.map(date =>
      moment(date)
        .tz(tz)
        .format("YYYY-MM-DD")
    );

    return [...new Set(daysArray)];
  };

  const getSelectedTimesByDay = (days, dates, tz) => {
    return days.map(day => {
      let dayZ = moment(day)
        .clone()
        .format("YYYY-MM-DD");

      let times = dates.filter(date => {
        let dateZ = moment(date)
          .clone()
          .tz(tz)
          .format("YYYY-MM-DD");
        return dayZ === dateZ;
      });

      return {
        date: dayZ,
        times: times.map(time =>
          moment(time)
            .tz(tz)
            .format("HH:mm")
        )
      };
    });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={["edit-task-modal", "kickoff-modal"].join(" ")}
      backdrop="static"
      size="xl"
      style={{ maxWidth: "auto", width: "auto", margin: "10px auto" }}
    >
      <ModalHeader
        toggle={() => {
          toggle();
        }}
        cssModule={{ "modal-title": "w-100 text-center" }}
        tag={"div"}
      >
        <span className="text-center h4 font-weight-bold">
          Edit Kickoff Call
        </span>
      </ModalHeader>
      <Form
        initialValues={{
          meetingLink: meeting_link,
          meetingId: meeting_id,
          meetingPassword: meeting_password,
          phone: phone
        }}
        className={["d-block"].join(" ")}
        onSubmit={values => {
          values.communicationMethod = values.communication_method
            ? values.communication_method.label
            : communicationMethod.label;
          values.timezone = values.timezone ? values.timezone.value : timezone;
          values.meetingLink = values.meetingLink ? values.meetingLink : "";
          values.meetingId = values.meetingId ? values.meetingId : "";
          values.meetingPassword = values.meetingPassword
            ? values.meetingPassword
            : "";
          values.phone = values.phone ? values.phone : "";
          values.webConferenceSource = webConferenceSource
            ? webConferenceSource
            : "";

          let selectedDays = getSelectedDays(selectedDates, visualTimezone);
          let selectedTimes = getSelectedTimesByDay(
            selectedDays,
            selectedDates,
            visualTimezone
          );

          values.dates = selectedTimes;

          // Update Kickoff Time
          updateKickoffTime(values);
        }}
      >
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="row">
              <div className="col-md-4 text-md-right text-sm-left">
                <label className="font-weight-bold">
                  Preferred Method of Communication:
                </label>
              </div>
              <div className="col-md-6">
                <PreferredMethodOfCommunication
                  name="communication_method"
                  placeholder="Select Preferred Method Of Communication"
                  defaultValue={communicationMethod}
                  onChange={handleCommunicationMethod}
                />
              </div>
            </div>

            {preferredCommunicationMethod ===
              COMMUNICATION_METHOD_WEB_CONFERERENCE && (
              <>
                <div className="row">
                  <div className="col-md-4 text-md-right text-sm-left">
                    <label className="font-weight-bold">Meeting Link:</label>
                  </div>
                  <div className="col-md-6 ml-4">
                    <Radio
                      name="webConferenceSource"
                      options={webConferenceSourceList}
                      onChange={handleWebConferenceSource}
                      defaultValue={webConferenceSource}
                    />
                  </div>
                </div>
              </>
            )}

            {preferredCommunicationMethod ===
              COMMUNICATION_METHOD_WEB_CONFERERENCE &&
              webConferenceSource ===
                WEB_CONFERERENCE_TYPE_PROVIDE_CONFERERENCE_URL && (
                <>
                  <div className="row">
                    <div className="col-md-4 text-md-right text-sm-left">
                      <label className="font-weight-bold">Meeting Link:</label>
                    </div>
                    <div className="col-md-6">
                      <Text name="meetingLink" placeholder="Meeting Link" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 text-md-right text-sm-left">
                      <label className="font-weight-bold">Meeting ID:</label>
                    </div>
                    <div className="col-md-6">
                      <Text name="meetingId" placeholder="Meeting Id" />
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-4 text-md-right text-sm-left">
                      <label className="font-weight-bold">
                        Meeting Password:
                      </label>
                    </div>
                    <div className="col-md-6">
                      <Text
                        name="meetingPassword"
                        placeholder="Meeting Password"
                      />
                    </div>
                  </div>
                </>
              )}

            {preferredCommunicationMethod === COMMUNICATION_METHOD_PHONE && (
              <div className="row">
                <div className="col-md-4 text-md-right text-sm-left">
                  <label className="font-weight-bold">Phone:</label>
                </div>
                <div className="col-md-6">
                  <Text name="phone" placeholder="Phone number" required />
                </div>
              </div>
            )}

            <div className="row">
              <div className="col-md-4 text-md-right text-sm-left">
                <label className="font-weight-bold">Time Zone:</label>
              </div>
              <div className="col-md-6">
                <KickoffTimeZone
                  name="timezone"
                  placeholder="Select Time Zone"
                  defaultValue={timezone}
                  onChange={updateTimezone}
                />
              </div>
            </div>
          </div>

          {/* Kickoff Time Picker */}
          <KickOffTimePicker
            updateSelectedDates={updateSelectedDates}
            defaultTimezone={defaultTimezone}
            visualTimezone={visualTimezone}
            expertAvailableDates={availableTimes}
            updateSelectedDateId={updateSelectedDateId}
            expertAvailableTimes={expertAvailableTimes}
          />
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={toggle} />
            <button
              type="submit"
              className="btn btn-primary"
              disabled={
                selectedDates && selectedDates.length > 0 ? false : true
              }
            >
              Update Kickoff
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
});

export default EditKickoffScheduleModal;
