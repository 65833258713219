import React, { useEffect, useState } from "react";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import moment from "moment";
import { Formik } from "formik";

// Assets
import { ClockIcon } from "../../assets/img/icons";

// Constants
import { METACX_EVENT_PLAYBOOK_CONTINUE_ORDER_BUTTON_CLICK } from "../../metaCXEvents/Constants";

// Library
import { metaCXTrack } from "../../lib/metaCX";

// Components
import toast from "../../components/base/Toast";
import Text from "../../components/base/Text";

// Api clients
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Helper
import { getParamsByName } from "../../lib/helper";

// GA
import { gaEvent } from "../../lib/GoogleAnalytics";
import { isBadRequest } from "../../common/http";

const CartSummary = props => {
  // Initialize State Values
  const [couponName, setCouponName] = useState("");
  const [disable, setDisable] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [percentage, setPercentage] = useState("");

  const partnerId = getParamsByName("partnerId");

  // Props
  const {
    classnames,
    btnHidden,
    playbook,
    cartOption,
    extras,
    selectedExtras,
    requestedStartDate,
    customerDetails,
    stripeProviderApiKey,
    projectNotes,
    settings
  } = props;

  const getAppliedCoupon = () => {
    return !!(
      percentage &&
      couponName &&
      localStorage.getItem("coupon") &&
      localStorage.getItem("percentage")
    );
  };

  // Handle Button
  const handleButton = e => {
    if (
      !((e.target && e.target.value) || e.keyCode === 13) &&
      !getAppliedCoupon()
    ) {
      resetValues();
    }
    e.target.value ? setDisable(true) : setDisable(false);
  };

  // Handle Close Button
  const handleCloseCoupon = () => {
    resetValues();
  };

  // Get Path Name
  const search = props.history.location.pathname;

  // Reset State And Localstorage Values
  const resetValues = () => {
    setCouponName("");
    setPercentage("");
    setErrorMessage("");
    window.localStorage.removeItem("coupon");
    window.localStorage.removeItem("percentage");
    window.localStorage.removeItem("playbookId");
    window.localStorage.removeItem("projectNotes");
  };

  // Calculate Total Amount
  let totalAmount =
    cartOption && cartOption.price ? parseInt(cartOption.price, 10) : 0;
  const playbookExtras = [];
  const playbookExtrasIds = [];
  if (extras && extras.length > 0) {
    extras.forEach(extra => {
      if (selectedExtras.indexOf(extra.option) > -1) {
        playbookExtrasIds.push(extra.id);
        totalAmount = totalAmount + extra.price;
        playbookExtras.push({
          option: extra.option,
          price: extra.price
        });
      }
    });
  }

  // Validate Coupon Code API Cqll
  function validateCouponCode(data, actions) {
    return apiClient
      .post(`${endpoints().couponAPI}/validate/coupon`, data)
      .then(response => {
        if (response && response.data && response.data.coupon) {
          const data = response.data;

          const coupon = data.coupon;
          const value = data.value;
          const playbookId = data.playbookId;

          setCouponName(coupon);
          setPercentage(value);
          setDisable(false);

          window.localStorage.setItem("coupon", coupon);
          window.localStorage.setItem("percentage", value);
          window.localStorage.setItem("playbookId", playbookId);

          // Reset The Form Values
          actions.setFieldValue("coupon", "");
        }

        setErrorMessage("");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let couponErrorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            couponErrorMessage = JSON.parse(errorRequest.response).message;
          }

          // Set Error Message
          setErrorMessage(couponErrorMessage);
        }
      });
  }

  // Calculate Discounted Amount
  let discountedAmount = (
    totalAmount *
    (percentage ? percentage : window.localStorage.getItem("percentage"))
  ).toFixed(2);

  discountedAmount = discountedAmount ? discountedAmount : 0;

  const appliedCouponName = couponName
    ? couponName
    : localStorage.getItem("coupon");

  useEffect(() => {
    setCouponName(appliedCouponName);
  }, [couponName]);

  return (
    <>
      {cartOption && cartOption.id && (
        <div className={`sidebar-content-box ${classnames}`}>
          <div className="sidebar-content">
            <div className="cart-item">
              <span className="title h4 d-block">Order Summary</span>
            </div>
            <div className="cart-item">
              {/* Show Playbook Name */}
              <span className="title h5 d-block mb-3">
                <b>{playbook.name}</b>
              </span>

              {/* Show Package */}
              <ul className="list-unstyled h-6-5">
                <li className="d-flex h6-5 mb-2 justify-content-between align-items-end">
                  <span>{cartOption.package_type} Package</span>{" "}
                  <span>${parseInt(cartOption.price, 10).toFixed(2)}</span>
                </li>
                {playbookExtras &&
                  playbookExtras.length > 0 &&
                  playbookExtras.map(extra => (
                    <li className="d-flex h6-5 mb-2 justify-content-between align-items-end">
                      <span>{extra.option}</span>{" "}
                      <span>${parseInt(extra.price, 10).toFixed(2)}</span>
                    </li>
                  ))}

                {/* Show Discount Amount */}
                {(search.indexOf("playbook/order/summary") > -1 ||
                  search.indexOf("playbook/order/checkout/") > -1) &&
                  window.localStorage.getItem("coupon") && (
                    <li className="d-flex h6-5 mb-2 justify-content-between align-items-end">
                      <span>Discount</span>
                      <span>
                        {discountedAmount && parseInt(discountedAmount, 10)
                          ? `-$${discountedAmount}`
                          : `$${discountedAmount}`}
                      </span>
                    </li>
                  )}
              </ul>
            </div>

            {/* Show Total Amount */}
            <div className="cart-item">
              <p className="mb-0">
                <b>Total</b>
              </p>
              <span className="d-flex align-items-baseline justify-content-between">
                <span className="h8 font-weight-light">
                  Includes 15% Torchlite fee
                </span>
                <span className="text-black">
                  <b>${totalAmount - discountedAmount}</b>
                </span>
              </span>
            </div>

            {/* Show Delivery Days */}
            {cartOption.turnaround_time && (
              <div className="cart-item">
                <p className="d-flex align-items-center">
                  <ClockIcon />{" "}
                  <b>{cartOption.turnaround_time} Days Delivery</b>
                </p>
              </div>
            )}

            {/* Show Start Date */}
            <div className="cart-item">
              <p>
                <b>Start Date:</b>
                <span className="d-block h6-5 font-weight-light">
                  {/* If requested start date is not available current date will be set */}
                  {requestedStartDate
                    ? moment(requestedStartDate).format("MMMM, DD YYYY")
                    : moment().format("MMMM, DD YYYY")}
                </span>
              </p>
            </div>

            {/* Show Coupon Code */}
            {search.indexOf("/order/summary/") > -1 ? (
              <div className="cart-item">
                <p>
                  <b>Coupon Code:</b>

                  <Formik
                    initialValues={{
                      coupon: ""
                    }}
                    onSubmit={async (values, actions) => {
                      let copiedValues = Object.assign({}, values);

                      copiedValues.coupon = values.coupon
                        ? values.coupon.trim()
                        : null;
                      copiedValues.playbookId = playbook.id;

                      await validateCouponCode(copiedValues, actions);
                      actions.setSubmitting(false);
                    }}
                  >
                    {props => {
                      const { values, handleSubmit } = props;
                      return (
                        <form onSubmit={handleSubmit}>
                          <div className="form-wrapper w-300">
                            <div className="field-wrapper text-center">
                              <Text
                                name="coupon"
                                placeholder="Coupon code"
                                onChange={handleButton}
                                defaultValue={values.coupon}
                              />

                              <div className="btn-wrapper">
                                <button
                                  type="submit"
                                  disabled={!disable}
                                  className={["btn", "btn-secondary"].join(" ")}
                                >
                                  Apply
                                </button>
                              </div>
                            </div>
                          </div>

                          {/* Show Invalid Coupon Code Error Message */}
                          <div className="mt-n2">
                            <span
                              style={{ fontSize: "0.80rem" }}
                              className={
                                errorMessage ? "error-message" : "text-success"
                              }
                            >
                              {errorMessage}
                            </span>
                          </div>

                          {/* Show Coupon Code Success Card */}
                          {couponName ? (
                            <div className="text-left applied-coupon-code">
                              <i
                                className="fa fa-tag ml-2 mr-2"
                                aria-hidden="true"
                              />
                              <span className="mr-2">{couponName}</span>
                              <i
                                className="fa fa-times mr-2 cursor-pointer"
                                aria-hidden="true"
                                onClick={() => handleCloseCoupon()}
                              />
                            </div>
                          ) : (
                            ""
                          )}
                        </form>
                      );
                    }}
                  </Formik>
                </p>
              </div>
            ) : (
              ""
            )}

            {!btnHidden && (
              <Link
                to={{
                  pathname: `/playbook/order/checkout${
                    playbook ? `/${playbook.id}` : ""
                  }${partnerId ? "?partnerId=" + partnerId : ""}`,
                  state: {
                    playbook,
                    extras,
                    selectedExtras,
                    cartOption,
                    actualAmount: totalAmount,
                    totalAmount:
                      totalAmount -
                      totalAmount *
                        (percentage
                          ? percentage
                          : window.localStorage.getItem("percentage")),
                    customerDetails,
                    stripeProviderApiKey,
                    playbookExtrasIds: playbookExtrasIds,
                    requestedStartDate: requestedStartDate
                      ? moment(requestedStartDate).format("MMMM, DD YYYY")
                      : moment().format("MMMM, DD YYYY"),
                    projectNotes,
                    settings
                  }
                }}
                style={{
                  color: "inherit",
                  textDecoration: "none"
                }}
              >
                <Button
                  color={"primary"}
                  onContextMenu={e => e.preventDefault()}
                  onClick={e => {
                    {
                      // Validate stripe provider api key
                      if (!stripeProviderApiKey) {
                        e.preventDefault();
                        return toast.error(
                          "Stripe provider api key is missing"
                        );
                      }

                      // GA Event
                      gaEvent({
                        category: "Playbook Order Summary Details",
                        action: "Clicked on continue order playbook checkout",
                        label: "Playbook order summary",
                        value: `${playbook.id}`
                      });

                      // Customize Playbook Info Funnel Step
                      window.ga("ec:setAction", "checkout", {
                        step: 2
                      });

                      // Customize Playbook Info Funnel Step
                      window.ga("ec:setAction", "add", {
                        id: `${playbook.id}`
                      });

                      // Track MetaCX events when click on "Continue Order" playbook in playbook order summary page
                      return metaCXTrack(
                        METACX_EVENT_PLAYBOOK_CONTINUE_ORDER_BUTTON_CLICK,
                        {
                          playbookId: `${playbook.id}`,
                          playbookName: playbook.name
                        },
                        settings,
                        () => {}
                      );
                    }
                  }}
                  block
                >
                  Continue Order
                </Button>
              </Link>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default CartSummary;
