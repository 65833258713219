import React, { useEffect, useState } from "react";

// API call
import { apiClient } from "../../apiClient";

// Assets
import { PlusIcon } from "../../assets/img/icons";
import { isBadRequest } from "../../common/http";

// Components
import Spinner from "../../components/base/Spinner";
import toast from "../../components/base/Toast";

// Constants
import { endpoints } from "../../configs";

//Component
import Task from "./components/Task";
import TaskAdd from "./components/TaskAdd";
import TaskDelete from "./components/TaskDelete";
import TaskEdit from "./components/TaskEdit";

const TaskListPage = props => {
  const { getFormList, formList } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [taskList, setTaskList] = useState([]);
  const [addTaskModal, setAddTaskModal] = useState(false);
  const [editTaskModal, setEditTaskModal] = useState(false);
  const [currentTask, setCurrentTask] = useState(false);
  const [deleteTaskModal, setDeleteTaskModal] = useState(false);
  const [sourceTaskOrder, setSourceTaskOrderState] = useState("");
  const [targetTaskOrder, setTargetTaskOrderState] = useState("");

  const getTaskList = () => {
    apiClient
      .get(`${endpoints().partnerApplicationTaskAPI}`)
      .then(response => {
        if (response.data && response.data.data) {
          setTaskList(response.data.data);

          setIsLoading(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  const addNewTask = values => {
    apiClient
      .post(`${endpoints().partnerApplicationTaskAPI}`, values)
      .then(response => {
        if (response.data) {
          setIsLoading(true);
          toast.success(response.data.message);
          setAddTaskModal(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          toast.error(errorMessage);
          setIsLoading(false);
        }
      });
  };

  const updateTask = (id, values, callback) => {
    apiClient
      .put(`${endpoints().partnerApplicationTaskAPI}/${id}`, values)
      .then(response => {
        if (response.data) {
          setCurrentTask("");
          setIsLoading(true);
          toast.success(response.data.message);
        }
        return callback(true);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          toast.error(errorMessage);
          setIsLoading(false);
          return callback(null);
        }
      });
  };

  const deleteTask = id => {
    apiClient
      .delete(`${endpoints().partnerApplicationTaskAPI}/${id}`)
      .then(response => {
        if (response.data) {
          setCurrentTask("");
          setIsLoading(true);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (isLoading) {
      getTaskList();
    }
  }, [isLoading]);

  // Set Current Order Position of Selected Task
  const setSourceTaskOrder = tagType => {
    if (sourceTaskOrder !== tagType.order) {
      setSourceTaskOrderState(tagType.order);
    }
  };
  // Set Target Order Position of Selected Task
  const setTargetTaskOrder = tagType => {
    if (targetTaskOrder !== tagType.order) {
      setTargetTaskOrderState(tagType.order);
    }
  };

  const updateTaskOrder = updatedTaskList => {
    apiClient
      .put(
        `${endpoints().partnerApplicationTaskAPI}/update/sortOrder`,
        updatedTaskList
      )
      .then(response => {
        if (response.data) {
          setIsLoading(true);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  // Change Order Position of Selected Task
  const changeTaskOrder = () => {
    // Current Selected Task List
    if (taskList && taskList.length > 0) {
      taskList.splice(
        targetTaskOrder,
        0,
        taskList.splice(sourceTaskOrder, 1)[0]
      );

      // Updated Selected Tag Type List
      const updatedTaskList = [];

      taskList.forEach(tagType => {
        updatedTaskList.push({
          ...tagType,
          // Update Selected Tag Type Order
          order: updatedTaskList.length
        });
      });

      updateTaskOrder(updatedTaskList);
      setTaskList(updatedTaskList);
      setSourceTaskOrderState("");
      setTargetTaskOrderState("");
    } else {
      // Reset Order Positions of Selected Tag Type
      setSourceTaskOrderState("");
      setTargetTaskOrderState("");
    }
  };

  return (
    <>
      <TaskAdd
        isOpen={addTaskModal}
        toggle={() => {
          setAddTaskModal(prevState => !prevState);
        }}
        addNewTask={addNewTask}
        getFormList={getFormList}
        index={taskList.length}
        formList={formList}
      />

      <TaskEdit
        isOpen={editTaskModal}
        toggle={() => {
          setEditTaskModal(prevState => !prevState);
        }}
        task={currentTask}
        updateTask={updateTask}
        formList={formList}
      />

      <TaskDelete
        isOpen={deleteTaskModal}
        toggle={() => {
          setDeleteTaskModal(prevState => !prevState);
        }}
        task={currentTask}
        deleteTask={deleteTask}
      />

      <div className="card">
        <h6 className="font-weight-bold pt-4 px-4 pb-3">Tasks</h6>

        <div className="task-list">
          {isLoading && <Spinner />}
          {taskList &&
            taskList.length > 0 &&
            taskList.map((taskItem, i) => (
              <>
                <div
                  id={taskItem.order}
                  key={taskItem.id}
                  draggable="true"
                  onDrag={e => {
                    setSourceTaskOrder(taskItem);
                  }}
                  onDragOver={e => {
                    e.preventDefault();
                    setTargetTaskOrder(taskItem);
                  }}
                  onDrop={e => {
                    changeTaskOrder();
                  }}
                >
                  <Task
                    task={taskItem}
                    setEditTaskModal={setEditTaskModal}
                    setCurrentTask={setCurrentTask}
                    setDeleteTaskModal={setDeleteTaskModal}
                    updateTask={updateTask}
                  />
                </div>
              </>
            ))}

          <div className=" task p-4" style={{ minHeight: "70px" }}>
            <div
              onClick={e => {
                setAddTaskModal(prevState => !prevState);
              }}
              className="task-left cursor-pointer"
            >
              <div className="checkbox add-task">
                <PlusIcon />
              </div>
              <a className={["add-task text-link"].join(" ")}>
                Add a new task...
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TaskListPage;
