import React from "react";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { BlueMessageIcon } from "../../../assets/img/icons";
import { getFullName } from "../../../lib/helper";
import CancelButton from "../../../components/base/CancelButton";

const MessageAccountManagerModal = React.memo(
  function MessageAccountManagerModal({
    projectId,
    isOpen,
    toggle,
    onChangeMessage,
    selectedProjectTeamMemberFirstName,
    selectedProjectTeamMemberLastName,
    selectedProjectTeamMemberId,
    sendMessageToAccountManager
  }) {
    return (
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className={["edit-task-modal"].join(" ")}
      >
        <ModalHeader toggle={toggle}>
          <div
            className={["d-flex", "flex-column", "align-items-center"].join(
              " "
            )}
          >
            <BlueMessageIcon />
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
              Message Account Manager
            </h4>
          </div>
        </ModalHeader>
        <ModalBody className={["mb-4", "px-0"].join(" ")}>
          <div className={["text-center", "modal-body-subtitle"].join(" ")}>
            <p>
              Send a message to the account manager,{" "}
              <b>
                {getFullName(
                  selectedProjectTeamMemberFirstName,
                  selectedProjectTeamMemberLastName
                )}
              </b>
              , by using the text area below:
            </p>
          </div>
          <div className="form-wrapper justify-content-center d-flex">
            <div className="field-wrapper w-100">
              <label>Your message</label>
              <Input
                id={"inputId"}
                type="textarea"
                placeholder={"Your message..."}
                style={{
                  background: "#F3F3F4",
                  border: "none",
                  borderRadius: "5px",
                  fontSize: "14px",
                  minHeight: "112px"
                }}
                onChange={onChangeMessage}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={toggle} />
            <button
              className="btn btn-primary"
              onClick={() => {
                sendMessageToAccountManager(
                  projectId,
                  selectedProjectTeamMemberId
                );
              }}
            >
              Send Message
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  }
);

export default MessageAccountManagerModal;
