import React from "react";
import moment from "moment";
import Editor from "draft-js-plugins-editor";
import { EditorState, convertFromRaw } from "draft-js";

// Helper
import { getCookie, getSlicedString } from "../../lib/helper";

//component
import Avatar from "../../components/base/Avatar";

import { COOKIE_USER_ID } from "../../lib/cookie";
import CountBadge from "../../components/base/CountBadge";

//Constants
import {
  PARTNER_PERMISSION_INDIVIDUAL_PARTNER,
  PARTNER_PERMISSION_FILTERED_PARTNERS,
  PARTNER_PERMISSION_ALL_PARTNER
} from "../../resource/Constants";

import { isPartner } from "../../lib/helper";

const MessageCard = props => {
  const { messageDetails, handleMessage } = props;

  const todayDate = moment(new Date()).format("DD MMM, YYYY");
  const discussionTime =
    messageDetails && messageDetails.createdAt
      ? moment(messageDetails.createdAt).format("h:mm A")
      : "";
  const prevDate =
    messageDetails && messageDetails.createdAt
      ? moment(messageDetails.createdAt).format("DD MMM, YYYY")
      : "";
  let discussionDate =
    messageDetails && messageDetails.createdAt
      ? moment(messageDetails.createdAt).format("DD MMM, YYYY")
      : "";

  discussionDate =
    todayDate === discussionDate && todayDate === prevDate
      ? `Today at ${discussionTime}`
      : `${prevDate} ${discussionTime}`;

  let fromUserId =
    messageDetails && messageDetails.from_user_id
      ? messageDetails.from_user_id
      : null;

  return (
    <>
      {messageDetails && (
        <div
          className="row mt-3 cursor-pointer"
          onClick={() => {
            handleMessage(
              messageDetails.to_user_id,
              messageDetails.id,
              messageDetails
            );
          }}
        >
          <div className="text-cente">
            <div className="col-2 mr-n2">
              <Avatar
                companyName={
                  messageDetails.type == PARTNER_PERMISSION_INDIVIDUAL_PARTNER
                    ? messageDetails.companyName
                    : messageDetails.type
                }
                size="customSize"
                imageSize={"36"}
                url={messageDetails.avatarUrl}
              />
            </div>
          </div>
          <div className="col-10">
            <div className="row ml-0 mb-0 text-muted font-weight-normal h6">
              {messageDetails.companyName
                ? messageDetails.companyName
                : messageDetails.displayName}
              {messageDetails.createdAt && (
                <p
                  className={["text-inline-grayed", "h7", "ml-2 mt-1"].join(
                    " "
                  )}
                >
                  {discussionDate}
                </p>
              )}
            </div>
            {messageDetails && messageDetails.subject && (
              <div
                className={`${
                  getCookie(COOKIE_USER_ID) === messageDetails.UserDetails &&
                  messageDetails.UserDetails.id
                    ? ""
                    : messageDetails.readAt
                    ? "fs-5"
                    : "font-weight-normal fs-5"
                }`}
              >
                {/* communication subject */}
                <span
                  className={`multiline-ellipsis ${
                    getCookie(COOKIE_USER_ID) == fromUserId
                      ? ""
                      : messageDetails.readAt
                      ? ""
                      : "font-weight-bold"
                  }`}
                >
                  {messageDetails.subject}
                </span>
              </div>
            )}
            <div
              className={`${
                getCookie(COOKIE_USER_ID) === messageDetails.UserDetails &&
                messageDetails.UserDetails.id
                  ? ""
                  : messageDetails.readAt
                  ? "small"
                  : "small"
              }`}
            >
              <div className="d-flex justify-content-between">
                <div>
                  <div className="d-flex justify-content-start">
                    {getCookie(COOKIE_USER_ID) == fromUserId && (
                      <div className="mr-1 text-inline-grayed">You:</div>
                    )}
                    <div>
                      <p
                        className={`multiline-ellipsis ${
                          getCookie(COOKIE_USER_ID) == fromUserId
                            ? ""
                            : messageDetails.readAt
                            ? "small"
                            : "font-weight-bold small"
                        }`}
                      >
                        {messageDetails &&
                          messageDetails.message &&
                          getSlicedString(messageDetails.message)}
                      </p>
                    </div>
                  </div>
                </div>
                <div>
                  <CountBadge
                    count={messageDetails.unreadMessageCount}
                    isActive={true}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};
export default MessageCard;
