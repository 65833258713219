import React from "react";
import { CrossIcon, MaximizeAltIcon } from "../assets/img/icons";
import Avatar from "./base/Avatar";
import { Input, Label } from "reactstrap";
import SampleProjectTooltip from "../views/projects/inner-components/SampleProjectTooltip";
import LazyLoad from "react-lazyload";

const SimpleExpertCard = props => {
  const {
    addExpert,
    showDeleteIcon,
    handleExperts,
    selected,
    currentUserId,
    expertUrl
  } = props;
  const { id, avatarUrl, firstName, lastName, jobTitle } = props.data;
  const TagName = props.tag || "div";
  const tagNameForProps =
    addExpert && TagName === "label" ? { htmlFor: `expert${id}` } : {};

  let type;
  return (
    <div className={["simple-expert-card"].join(" ")}>
      <div
        className={[
          "card-header",
          "bg-transparent",
          "d-flex justify-content-between",
          "align-items-center"
        ].join(" ")}
      >
        <span className="d-flex">
          {showDeleteIcon && (
            <span
              onClick={() => handleExperts(id, (type = "remove"))}
              className={["d-block", "mr-2"].join(" ")}
            >
              <CrossIcon />
            </span>
          )}

          {addExpert && (
            <div className="checkbox-wrapper m-0">
              <Label htmlFor={`expert${id}`}>
                <Input
                  id={`expert${id}`}
                  name={`expert${id}`}
                  onChange={() => {
                    handleExperts(id);
                  }}
                  value={`expert${id}`}
                  type="checkbox"
                  checked={selected}
                />{" "}
                <span
                  className={`checkbox-placeholder mx-0 mr-2 p-0 ${
                    selected ? "active" : ""
                  }`}
                />
              </Label>
            </div>
          )}

          <TagName
            className={[
              "user-and-title",
              "d-flex",
              "align-items-center",
              "mb-0"
            ].join(" ")}
            {...tagNameForProps}
          >
            <div className={["mr-2"].join(" ")}>
              <LazyLoad>
                <Avatar
                  url={avatarUrl}
                  firstName={firstName}
                  lastName={lastName}
                  size="xs"
                />
              </LazyLoad>
            </div>
            <div className={["user-title"].join(" ")}>
              <p className={["h6-5", "m-0", "font-weight-bold"].join(" ")}>
                {currentUserId ? (
                  currentUserId === id ? (
                    "You"
                  ) : (
                    <>
                      {firstName} {lastName}
                    </>
                  )
                ) : (
                  <>
                    {firstName} {lastName}
                  </>
                )}
              </p>
              <span
                className={["text-inline-grayed", "d-block", "h6-5"].join(" ")}
              >
                {jobTitle}
              </span>
            </div>
          </TagName>
        </span>
        {expertUrl && (
          <a
            href={expertUrl}
            target="_blank"
            id={`view-profile-${id}`}
            className="d-block pull-right mt-1"
            rel="noopener noreferrer"
          >
            <MaximizeAltIcon />
            <SampleProjectTooltip
              tooltipId={`view-profile-${id}`}
              placement={"top"}
            >
              <p className="font-weight-bold">View Full Profile</p>
            </SampleProjectTooltip>
          </a>
        )}
      </div>
    </div>
  );
};

export default SimpleExpertCard;
