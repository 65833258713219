import React from "react";

// Components
import Select from "../../../../../components/base/Select";

// Assets
import { PlusIcon, TrashIcon } from "../../../../../assets/img/icons";
import Radio from "../../../../../components/base/Radio";
import {
  LANDING_PAGE_BLOCK_TAG_TYPE_MULTI_SELECT,
  LANDING_PAGE_BLOCK_TAG_TYPE_MULTI_SELECT_VALUE,
  LANDING_PAGE_BLOCK_TAG_TYPE_SINGLE_SELECT,
  LANDING_PAGE_BLOCK_TAG_TYPE_SINGLE_SELECT_VALUE
} from "../../../../../landingPage/Constants";
import { Input } from "reactstrap";

class LandingBlockTagType extends React.Component {
  constructor(props) {
    super(props);
    const { tagTypes, tagTypeList } = this.props;
    this.state = {
      values: tagTypes && tagTypes.length ? [...tagTypes] : [],
      tagTypeList: tagTypeList && tagTypeList.length ? tagTypeList : [],
      tagTypes: {},
      sourceTagTypeOrder: "",
      targetTagTypeOrder: "",
      name: ""
    };
  }

  componentDidMount() {
    if (this.props.tagTypes) {
      this.props.handleSelectedTagTypeValues(this.state.values);
    }
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.tagTypeList &&
      prevProps.tagTypeList.length !== this.props.tagTypeList.length &&
      this.props.tagTypeList.length > 0
    ) {
      this.setState({
        tagTypeList: this.props.tagTypeList
      });
    }
  }

  // Add tagTypes field
  addField = event => {
    event.preventDefault();
    // Generate added tagTypes field key
    const id = Math.floor(1000 + Math.random() * 9000);
    const values = [...this.state.values];

    values.push({
      id,
      tag_type_id: null,
      display_name: null,
      multi_select: null,
      order: values.length
    });
    this.setState({ values });
  };

  // Handle remove tagTypes
  handleRemove = i => {
    const values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });

    this.props.handleSelectedTagTypeValues(values);
  };

  // Handle change tagTypes
  handleChange = (i, event) => {
    const values = [...this.state.values];
    // Map tagTypes values
    event && event.target && event.target.name.includes("select")
      ? (values[i].multi_select =
          event.target.value === LANDING_PAGE_BLOCK_TAG_TYPE_MULTI_SELECT
            ? LANDING_PAGE_BLOCK_TAG_TYPE_MULTI_SELECT_VALUE
            : event.target.value === LANDING_PAGE_BLOCK_TAG_TYPE_SINGLE_SELECT
            ? LANDING_PAGE_BLOCK_TAG_TYPE_SINGLE_SELECT_VALUE
            : "")
      : event && event.target && event.target.name.includes("name")
      ? (values[i].display_name = event.target.value)
      : (values[i].tag_type_id = event.id) &&
        (values[i].display_name = event.name);
    this.setState({ values });

    const data = event.name;
    this.setState({ name: data });
    this.props.handleSelectedTagTypeValues(values);
  };

  // Set Current Order Position of Selected Tag Type
  setSourceTagTypeOrder = tagType => {
    if (this.state.sourceTagTypeOrder !== tagType.order) {
      this.setState({
        sourceTagTypeOrder: tagType.order
      });
    }
  };

  // Set Target Order Position of Selected Tag Type
  setTargetTagTypeOrder = tagType => {
    if (this.state.targetTagTypeOrder !== tagType.order) {
      this.setState({
        targetTagTypeOrder: tagType.order
      });
    }
  };

  // Change Order Position of Selected Tag Type
  changeTagTypeOrder = () => {
    const { sourceTagTypeOrder, targetTagTypeOrder } = this.state;

    // Current Selected Tag Type List
    const tagTypeList = this.state.values;

    if (tagTypeList && tagTypeList.length > 0) {
      tagTypeList.splice(
        targetTagTypeOrder,
        0,
        tagTypeList.splice(sourceTagTypeOrder, 1)[0]
      );

      // Updated Selected Tag Type List
      const updatedTagTypeList = [];

      tagTypeList.forEach(tagType => {
        updatedTagTypeList.push({
          ...tagType,
          // Update Selected Tag Type Order
          order: updatedTagTypeList.length
        });
      });

      this.setState({
        values: updatedTagTypeList,
        sourceTagTypeOrder: "",
        targetTagTypeOrder: ""
      });

      this.props.handleSelectedTagTypeValues(updatedTagTypeList);
    } else {
      // Reset Order Positions of Selected Tag Type
      this.setState({
        sourceTagTypeOrder: "",
        targetTagTypeOrder: ""
      });
    }
  };

  render() {
    const { values, tagTypeList } = this.state;

    return (
      <>
        <div className="row">
          <div className="col-12 col-md-12 col-lg-9 col-xl-9 pr-md-0 pr-lg-0">
            <div className="row">
              <div className="col-12 col-md-12 col-lg-2">
                <p className="pt-1 pb-1 h7 font-weight-bold"></p>
              </div>
              <div className="col-12 col-md-12 col-lg-5 pl-lg-0">
                <p className="pt-1 pb-1 h7 font-weight-bold">Field</p>
              </div>
              <div className="col-12 col-md-12 col-lg-5 pl-lg-0">
                <p className="pt-1 pb-1 h7 font-weight-bold">Display Name</p>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-12 col-lg-3 col-xl-3  pl-lg-0 pr-lg-0">
            <div className="row">
              <div className="col-4 pr-0">
                <p className="pt-1 pb-1 h7 font-weight-bold"> Multi- Select</p>
              </div>
              <div className="col-4 pl-0">
                <p className="pt-1 pb-1 h7 font-weight-bold"> Single Select</p>
              </div>
              <div className="col-4">
                <p className="pt-1 pb-1 h7 font-weight-bold"></p>
              </div>
            </div>
          </div>
        </div>
        {values.map((el, i) => (
          <>
            <div
              id={el.order}
              className="row pt-2"
              key={el.id}
              draggable="true"
              onDrag={e => {
                this.setSourceTagTypeOrder(el);
              }}
              onDragOver={e => {
                e.preventDefault();
                this.setTargetTagTypeOrder(el);
              }}
              onDrop={e => {
                this.changeTagTypeOrder();
              }}
            >
              <div className="col-12 col-md-12 col-lg-9 col-xl-9">
                <div className="row">
                  <div className="col-12 col-md-12 col-lg-2">
                    <p className="pt-1 pb-1" style={{ cursor: "move" }}>
                      <i className="fa fa-arrows"></i>
                    </p>
                  </div>
                  <div className="col-12 col-md-12 col-lg-5 pl-lg-0">
                    <Select
                      name={`tagType-${el.id}`}
                      defaultValue={tagTypeList.find(
                        tagType => tagType.value === el.tag_type_id
                      )}
                      options={tagTypeList}
                      handleChange={e => this.handleChange(i, e)}
                      error="Type is required"
                      isSearchable={true}
                      required={
                        (values[i].display_name ||
                          values[i].multi_select !== undefined ||
                          values[i].multi_select !== null) &&
                        !values[i].tag_type_id &&
                        true
                      }
                    />
                  </div>
                  <div className="col-12 col-md-12 col-lg-5 pl-lg-0">
                    <Input
                      name={`tagType_name-${el.id}`}
                      className="form-control"
                      placeholder={`Display Name`}
                      style={{
                        background: "#F3F3F4",
                        border: "none",
                        borderRadius: "5px",
                        fontSize: "14px",
                        height: "40px"
                      }}
                      type="text"
                      value={el.display_name}
                      onChange={e => this.handleChange(i, e)}
                      required={
                        (values[i].tag_type_id ||
                          values[i].multi_select !== undefined ||
                          values[i].multi_select !== null) &&
                        !values[i].display_name &&
                        true
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-12 col-lg-3 col-xl-3 pl-lg-0">
                <div className="row">
                  <div className="col-8">
                    <div className="ml-3">
                      <Radio
                        name={`tagType_select-${el.id}`}
                        options={[
                          LANDING_PAGE_BLOCK_TAG_TYPE_MULTI_SELECT,
                          LANDING_PAGE_BLOCK_TAG_TYPE_SINGLE_SELECT
                        ]}
                        size="large"
                        onChange={e => this.handleChange(i, e)}
                        defaultValue={
                          el.multi_select ===
                          LANDING_PAGE_BLOCK_TAG_TYPE_MULTI_SELECT_VALUE
                            ? LANDING_PAGE_BLOCK_TAG_TYPE_MULTI_SELECT
                            : el.multi_select ===
                              LANDING_PAGE_BLOCK_TAG_TYPE_SINGLE_SELECT_VALUE
                            ? LANDING_PAGE_BLOCK_TAG_TYPE_SINGLE_SELECT
                            : ""
                        }
                        hideOptionName={true}
                        showInline={true}
                        className="row"
                        radioClassName="col-6 text-center"
                        required={
                          (values[i].tag_type_id || values[i].display_name) &&
                          (values[i].multi_select === undefined ||
                            values[i].multi_select === null) &&
                          true
                        }
                      />
                    </div>
                  </div>
                  <div className="col-4">
                    <div
                      className="pt-1 pb-1"
                      style={{ cursor: "pointer" }}
                      onClick={() => this.handleRemove(i)}
                    >
                      <TrashIcon />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-100 border-bottom mt-2 mb-3"></div>
          </>
        ))}
        <div className="btn-wrapper">
          <button
            onClick={this.addField}
            className="btn btn-link d-inline-flex px-0 text-underline"
          >
            <PlusIcon /> Add {values.length > 0 && "Another"} Filter
          </button>
        </div>
      </>
    );
  }
}

export default LandingBlockTagType;
