import React, { useState, useEffect } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Api Client
import { apiClient } from "../../../apiClient";

// Assets
import { CrossIcon } from "../../../assets/img/icons";

// Configs
import { endpoints } from "../../../configs";

// Constants
import {
  AVAILABLE_EXPERT,
  APPROVED_EXPERT,
  getExpertAvailabilityOption
} from "../../../expertAvailability/Constants";
import {
  TAG_TYPE_EXPERT_ROLE,
  TAG_TYPE_INDUSTRIES,
  TAG_TYPE_SKILLS,
  TAG_TYPE_TOOLS,
  TAG_TYPE_CERTIFICATION
} from "../../../tagType/Constants";
import {
  ONE_PROJECT_EXPERIENCE,
  MORE_THAN_ONE_PROJECT_EXPERIENCE,
  NO_EXPERIENCE,
  ONE_PROJECT_EXPERIENCE_IDENTIFIER,
  MORE_THAN_ONE_PROJECT_EXPERIENCE_IDENTIFIER,
  NO_EXPERIENCE_IDENTIFIER,
  getExpertLevelOfExperienceOption
} from "../../../expertLevelOfExperience/Constants";

import { getExpertHourlyOption } from "../../../expertHours/Constants";

// Components
import SimpleExpertCard from "../../../components/SimpleExpertCard";
import NLFormWrapper from "../../../components/nl-form/NLFormWrapper";
import Spinner from "../../../components/base/Spinner";
import CancelButton from "../../../components/base/CancelButton";

import {
  paragraphs,
  extraParagraphs
} from "../../../components/helpers/NLFormStaticData";
import { isBadRequest } from "../../../common/http";

const ExpertModal = props => {
  const [selectedFilters, setSelectedFilters] = useState({});
  const [expertTypes, setExpertTypes] = useState([]);
  const [skills, setSkills] = useState([]);
  const [tools, setTools] = useState([]);
  const [industries, setIndustries] = useState([]);
  const [certification, setCertification] = useState([]);

  const {
    isExpertModal,
    toggle,
    expertList,
    handleExperts,
    expertIds,
    createProjectExpert,
    updateExpertList,
    isLoading,
    redirectUrl,
    expertCount
  } = props;

  const getSelectedFilters = (selectedFilters, selectName) => {
    setSelectedFilters(prevState => ({
      ...prevState,
      [selectName]: selectedFilters
    }));
  };

  // Get Tags by tag type
  const getTagsByTagType = tagType => {
    return apiClient
      .get(
        `${
          endpoints().playbookAPI
        }/tags/list?tagType=${tagType}&isSearchable=true`
      )
      .then(response => {
        const tags = response.data.data;

        if (tags.length > 0) {
          // Check the tag type experts
          if (tagType === TAG_TYPE_EXPERT_ROLE) {
            const experts = [];
            tags.forEach(tag => {
              experts.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });

            setExpertTypes(experts);
          }
          // Check the tag type skills
          else if (tagType === TAG_TYPE_SKILLS) {
            const tagSkills = [];
            tags.forEach(tag => {
              tagSkills.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });

            setSkills(tagSkills);
          }
          // Check the tag type tools
          else if (tagType === TAG_TYPE_TOOLS) {
            const tagTools = [];
            tags.forEach(tag => {
              tagTools.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });

            setTools(tagTools);
          }
          // Check the tag type industries
          else if (tagType === TAG_TYPE_INDUSTRIES) {
            const tagIndustries = [];
            tags.forEach(tag => {
              tagIndustries.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });

            setIndustries(tagIndustries);
          }
          // Check the tag type certificates
          else if (tagType === TAG_TYPE_CERTIFICATION) {
            const tagCertifictes = [];
            tags.forEach(tag => {
              tagCertifictes.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });

            setCertification(tagCertifictes);
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  useEffect(() => {
    getTagsByTagType(TAG_TYPE_EXPERT_ROLE);
    getTagsByTagType(TAG_TYPE_SKILLS);
    getTagsByTagType(TAG_TYPE_TOOLS);
    getTagsByTagType(TAG_TYPE_INDUSTRIES);
    getTagsByTagType(TAG_TYPE_CERTIFICATION);
  }, [selectedFilters]);

  // Filter expert list
  const renderExpertList = () => {
    const selectedFiltersConst = selectedFilters;
    const tags = [];
    const certificates = [];

    const expertsTags = selectedFiltersConst.expertTypeObj
      ? selectedFiltersConst.expertTypeObj.selectedValue
      : "";

    if (expertsTags.length > 0) {
      expertsTags.forEach(expert => {
        tags.push(expert);
      });
    }

    const skillsTags = selectedFiltersConst.multiSelect
      ? selectedFiltersConst.multiSelect.selectedValue
      : "";

    if (skillsTags.length > 0) {
      skillsTags.forEach(skill => {
        tags.push(skill);
      });
    }

    const certificateTags = selectedFilters.certification
      ? selectedFilters.certification.selectedValue
      : "";

    if (certificateTags.length > 0) {
      certificateTags.forEach(certificate => {
        certificates.push(certificate);
      });
    }

    const toolsTags = selectedFiltersConst.tool
      ? selectedFiltersConst.tool.selectedValue
      : "";

    if (toolsTags.length > 0) {
      toolsTags.forEach(tool => {
        tags.push(tool);
      });
    }

    const industriesTags = selectedFiltersConst.industry
      ? selectedFiltersConst.industry.selectedValue
      : "";

    if (industriesTags.length > 0) {
      industriesTags.forEach(industry => {
        tags.push(industry);
      });
    }

    const isAvailability =
      selectedFilters &&
      selectedFilters.availabilityObj &&
      selectedFilters.availabilityObj.selectedValue.length > 0 &&
      selectedFilters.availabilityObj.selectedValue &&
      selectedFilters.availabilityObj.selectedValue[0] !== APPROVED_EXPERT
        ? selectedFilters.availabilityObj.selectedValue[0] === AVAILABLE_EXPERT
          ? true
          : false
        : "";

    const availability =
      selectedFilters &&
      selectedFilters.hoursObj &&
      selectedFilters.hoursObj.selectedValue &&
      selectedFilters.hoursObj.selectedValue.length > 0
        ? selectedFilters.hoursObj.selectedValue[0]
        : "";

    const levelOfExperienceValue =
      selectedFilters &&
      selectedFilters.levelOfExp &&
      selectedFilters.levelOfExp.selectedValue &&
      selectedFilters.levelOfExp.selectedValue.length > 0
        ? selectedFilters.levelOfExp.selectedValue[0]
        : "";

    let levelOfExperience = "";
    if (levelOfExperienceValue === NO_EXPERIENCE) {
      levelOfExperience = NO_EXPERIENCE_IDENTIFIER;
    } else if (levelOfExperienceValue === ONE_PROJECT_EXPERIENCE) {
      levelOfExperience = ONE_PROJECT_EXPERIENCE_IDENTIFIER;
    } else if (levelOfExperienceValue === MORE_THAN_ONE_PROJECT_EXPERIENCE) {
      levelOfExperience = MORE_THAN_ONE_PROJECT_EXPERIENCE_IDENTIFIER;
    }

    const location =
      selectedFiltersConst &&
      selectedFiltersConst.location &&
      selectedFiltersConst.location.selectedValue
        ? selectedFiltersConst.location.selectedValue
        : "";
    const locationSplit =
      location && location[0] ? location[0].split(", ") : "";
    const city = locationSplit[0] || "";

    const filtersSelected = {
      tags,
      city,
      isAvailability,
      availability,
      levelOfExperience,
      certificates
    };
    updateExpertList(filtersSelected);
  };

  // Filter options
  const selectObj = {
    expertTypeObj: [
      {
        label: "Expert type",
        value: "Expert type",
        selected: false,
        placeholder: true
      },
      ...expertTypes
    ],
    hoursObj: getExpertHourlyOption(),
    availabilityObj: getExpertAvailabilityOption(),
    industry: [
      {
        multiselect: 1,
        label: "industry",
        value: "",
        placeholder: true
      },
      ...industries
    ],
    tool: [
      {
        multiselect: 1,
        label: "tool",
        value: "",
        placeholder: true
      },
      ...tools
    ],
    levelOfExp: getExpertLevelOfExperienceOption(),
    certification: [
      {
        multiselect: 1,
        label: "certification",
        value: "",
        placeholder: true
      },
      ...certification
    ],
    multiSelect: [
      {
        multiselect: 1,
        label: "skill",
        value: "",
        placeholder: true
      },
      ...skills
    ],
    location: [
      {
        location: true
      }
    ]
  };

  // Get selected filter keys like (expertTypeObj, multiselect, etc,.)
  const getFilterKeys = () => Object.keys(selectedFilters);

  // Delete selected filters
  const deleteFilters = e => {
    const { dataset } = e.target;
    setSelectedFilters(prevState => {
      let currentValueIndex = selectedFilters[
        dataset["parentobj"]
      ].selectedValue.indexOf(dataset["filter"]);
      let currentArray = selectedFilters[dataset["parentobj"]].selectedValue;
      currentArray.splice(currentValueIndex, 1);
      renderExpertList();
      return {
        ...selectedFilters,
        [dataset["parentobj"]]: {
          selectedValue: [...currentArray]
        }
      };
    });
  };

  return (
    <Modal
      isOpen={isExpertModal}
      toggle={toggle}
      className="edit-task-modal expert-modal"
      backdrop="static"
      size="xl"
      style={{ maxWidth: "auto", width: "auto", margin: "10px auto" }}
    >
      <ModalHeader
        toggle={() => {
          handleExperts(null);
          setSelectedFilters({});
          toggle();
        }}
        cssModule={{ "modal-title": "w-100 text-center" }}
        tag={"div"}
      >
        <span className="text-center h4 font-weight-bold">
          Source More Experts
        </span>
      </ModalHeader>

      <NLFormWrapper
        paragraphs={paragraphs}
        selectObj={selectObj}
        extraParagraphs={extraParagraphs}
        getSelectedFilters={getSelectedFilters}
        liveFilters={selectedFilters}
        renderExpertList={e => renderExpertList(e)}
        hideDiscoverButton={false}
      />

      {getFilterKeys().length > 0 ? (
        <div className="filters-wrapper">
          {getFilterKeys().map(filterObj =>
            selectedFilters[filterObj]["selectedValue"].map(
              value =>
                value && (
                  <button
                    className={`badge badge-primary ${filterObj}`}
                    data-parentobj={filterObj}
                    data-filter={value}
                    onClick={e => deleteFilters(e)}
                  >
                    {value} <CrossIcon />
                  </button>
                )
            )
          )}
        </div>
      ) : (
        ""
      )}
      <ModalBody>
        <>
          {expertCount > 0 && !expertList.length ? (
            <p className="text-center w-100 mt-4">
              No Matched Experts Are Available
            </p>
          ) : !expertList.length ? (
            <p className="text-center w-100 mt-4">No experts are added yet</p>
          ) : (
            ""
          )}
          <div className="simple-expert-card-list mt-5 mb-3">
            {isLoading ? (
              <Spinner />
            ) : (
              expertList.length > 0 &&
              expertList.map(expert => (
                <SimpleExpertCard
                  data={{
                    id: expert.userId,
                    avatarUrl: expert.avatarUrl,
                    firstName: expert.firstName,
                    lastName: expert.lastName,
                    jobTitle: expert.jobTitle
                  }}
                  key={expert.userId}
                  addExpert={true}
                  selected={expertIds.indexOf(parseInt(expert.userId, 10)) > -1}
                  handleExperts={handleExperts}
                  tag={"label"}
                  expertUrl={`/expert-profile/${expert.id}${redirectUrl}`}
                />
              ))
            )}
          </div>
        </>
      </ModalBody>
      <ModalFooter>
        <div className="container-fluid">
          <div className="col-sm-12 text-center">
            <div className="btn-wrapper">
              <CancelButton
                onClick={() => {
                  setSelectedFilters({});
                  toggle();
                }}
              />
              <button
                className={`btn btn-primary ${
                  !expertIds.length ? "disabled" : ""
                }`}
                onClick={() => {
                  setSelectedFilters({});
                  expertIds.length && createProjectExpert();
                }}
                disabled={!expertIds.length}
              >
                Source Experts
              </button>
            </div>
          </div>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ExpertModal;
