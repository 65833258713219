import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
// Page Components
import OrderItem from "./OrderItem";
import OrderExtras from "./OrderExtras";
import OrderStartDate from "./OrderStartDate";
import CartSummary from "./CartSummary";
import OrderProjectNotes from "./OrderProjectNotes";

// Components
import MarketplaceSection from "../../components/MarketplaceSection";

// Assets
import { ArrowLeft } from "../../assets/img/icons";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";

// Helper
import {
  getFullName,
  getCookie,
  getUrlParameter,
  getKeyValueByObject
} from "../../lib/helper";
import { SETTINGS_STRIPE_PROVIDER_API_KEY } from "../../setting/Constants";
import { COOKIE_SESSION_TOKEN } from "../../lib/cookie";
import { isBadRequest } from "../../common/http";

export default class PlaybookOrderDetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playbookDetails: [],
      cartOptions: [],
      cartOption: {},
      urgentDelivery: "",
      additionalRevisions: "",
      twoDesignOption: "",
      // Some default styles, this would be than updated by the API
      bgColor: "#fff",
      checkBox: {
        extraOptionSelections: []
      },
      customerDetails: "",
      requestedStartDate: "",
      projectNotes: "",
      selectedPlaybookExtras: [],
      selectedCartType: "",
      stripeProviderApiKey: ""
    };
  }

  componentDidMount = () => {
    const playbookId = this.props.match.params.id;
    const appliedCouponPlaybookId = window.localStorage.getItem("playbookId");
    const projectNotes = window.localStorage.getItem("projectNotes");

    this.setState({ projectNotes: projectNotes ? projectNotes : "" });
    // Reset the Coupon when new playbook opened
    if (appliedCouponPlaybookId && appliedCouponPlaybookId !== playbookId) {
      window.localStorage.removeItem("playbookId");
      window.localStorage.removeItem("coupon");
      window.localStorage.removeItem("percentage");
      window.localStorage.removeItem("projectNotes");
    }

    if (this.props.location.state) {
      this.getSummarDetailsByprops();
    } else {
      this._getPlaybookDetails();
    }
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      this._getCustomerBillingDetails();
    }

    this.getSettingsData(SETTINGS_STRIPE_PROVIDER_API_KEY);
  };

  getSummarDetailsByprops() {
    const {
      playbook,
      cartOptions,
      selectedCartType
    } = this.props.location.state;
    if (cartOptions.length > 0) {
      cartOptions.forEach(cartOption => {
        if (selectedCartType === cartOption.package_type) {
          this.setState({ cartOption });
        }
      });
    }
    this.setState({ playbookDetails: playbook, loading: true });

    if (this.props.location.state.successfulLogin) {
      // toast.success("Logged in successfully")
    }
  }

  getSummarDetailsBystate(cartOptions) {
    const selectedCartType = getUrlParameter("selectedCartType");
    this.setState({ selectedCartType });
    if (cartOptions.length > 0) {
      cartOptions.forEach(cartOption => {
        if (this.state.selectedCartType === cartOption.package_type) {
          this.setState({ cartOption });
        }
      });
    }
    if (this.props.location.state.successfulLogin) {
      // toast.success("Logged in successfully")
    }
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      this._getCustomerBillingDetails();
    }
  }

  // Playbook details api call by id
  _getPlaybookDetails = () => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .get(`${endpoints().publicAPI}/playbook/${this.props.match.params.id}`)
      .then(response => {
        this.setState({ playbookDetails: response.data, loading: true });
        this.setState({
          cartOptions: response.data.cartOptions,
          loading: true
        });
        this.getSummarDetailsBystate(this.state.cartOptions);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          console.error(errorMessage);
        }
      });
  };

  // Get Customer Billing Details
  _getCustomerBillingDetails() {
    apiClient.defaults.headers.common.Authorization = getCookie(
      COOKIE_SESSION_TOKEN
    );
    return apiClient
      .get(`${endpoints().customerUserAPI}/getDetails/`)
      .then(response => {
        this.setState({
          customerDetails: response.data,
          isLoading: true
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  // Get Settings Data
  getSettingsData = settingName => {
    return apiClient
      .get(`${endpoints().settingAPI}/${settingName}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        console.error(successMessage);
        const settings = response.data.data;
        this.setState({
          stripeProviderApiKey: settings.value
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Handle change playbook extras
  _handleChangeExtras = e => {
    const selectedExtras = e.target.value;
    let newSelectionArray;

    if (
      this.state.checkBox.extraOptionSelections.indexOf(selectedExtras) > -1
    ) {
      newSelectionArray = this.state.checkBox.extraOptionSelections.filter(
        s => s !== selectedExtras
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.extraOptionSelections,
        selectedExtras
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        extraOptionSelections: newSelectionArray
      }
    }));

    this.setState({ selectedPlaybookExtras: newSelectionArray });
  };

  // Handle Date
  _handleChangeDate = date => {
    this.setState({ requestedStartDate: date });
  };

  // Handle Project Detail Note
  _handleChangeProjectNote = e => {
    const projectNotes = e.target.value;
    this.setState({ projectNotes });
    window.localStorage.setItem("projectNotes", projectNotes);
  };

  render() {
    const styles = {
      // styles that are applied to the body from the admin
      backgroundColor: this.state.bgColor
    };

    const customerDetails = this.state.customerDetails
      ? this.state.customerDetails
      : "";

    const {
      cartOption,
      playbookDetails,
      requestedStartDate,
      projectNotes
    } = this.state;

    const { settings } = this.props;

    const {
      name,
      createdByUserDetail,
      bannerImagePath,
      bannerColor,
      price,
      ratingCount,
      ratings,
      extras
    } = playbookDetails;

    const isExistPlaybook =
      this.state.playbookDetails.length === undefined ? true : false;

    const getAllRatings = () => {
      let rating_arr = [];
      ratings.forEach(rating => {
        rating_arr.push(rating["rating"]);
      });
      return rating_arr;
    };

    const countRatings = (input, arr) => {
      return arr.filter(x => x === input).length;
    };

    const getWeightedAverage = () => {
      const rating_arr = getAllRatings();
      return (
        (5 * countRatings(5, rating_arr) +
          4 * countRatings(4, rating_arr) +
          3 * countRatings(3, rating_arr) +
          2 * countRatings(2, rating_arr) +
          1 * countRatings(1, rating_arr)) /
        (countRatings(5, rating_arr) +
          countRatings(4, rating_arr) +
          countRatings(3, rating_arr) +
          countRatings(2, rating_arr) +
          countRatings(1, rating_arr))
      );
    };

    const round = (value, precision) => {
      var multiplier = Math.pow(10, precision || 0);
      return Math.round(value * multiplier) / multiplier;
    };

    const stripeProviderApiKey = settings
      ? getKeyValueByObject(settings, SETTINGS_STRIPE_PROVIDER_API_KEY)
      : "";

    return (
      <div className="marketplace-landing" style={styles}>
        <div className="sub-navigation" style={{ cursor: "default" }}>
          <Container>
            <Col>
              <p
                style={{ width: "70px", cursor: "pointer" }}
                onClick={() => {
                  const { goBack, push } = this.props.history;

                  // Get redirect url from query params
                  const redirectUrl = getUrlParameter("redirect");
                  if (redirectUrl) {
                    return push(redirectUrl);
                  }

                  return goBack();
                }}
                className="text-link d-flex align-items-center"
              >
                <ArrowLeft /> Back
              </p>
            </Col>
          </Container>
        </div>
        {isExistPlaybook && (
          <Container>
            <Row>
              <Col md="9">
                <div className="site-maincontent mt-3">
                  <MarketplaceSection title="Customize Your Playbook Order">
                    <OrderItem
                      itemData={{
                        title: name,
                        author: getFullName(
                          createdByUserDetail
                            ? createdByUserDetail.first_name
                              ? createdByUserDetail.first_name
                              : ""
                            : "",
                          createdByUserDetail
                            ? createdByUserDetail.last_name
                              ? createdByUserDetail.last_name
                              : ""
                            : ""
                        ),
                        product_img: bannerImagePath,
                        banner_color: bannerColor,
                        price: `$${price}`,
                        ratings: {
                          stars: round(getWeightedAverage()),
                          count: ratingCount,
                          used: 46
                        }
                      }}
                      playbookDetails={playbookDetails}
                    />

                    {/* Show Extras */}
                    {extras && extras.length > 0 && (
                      <OrderExtras
                        extrasOptions={extras}
                        extraOptionSelections={
                          this.state.checkBox.extraOptionSelections
                        }
                        onChange={this._handleChangeExtras.bind(this)}
                      />
                    )}

                    {/* Order Status Date */}
                    <OrderStartDate
                      onChange={this._handleChangeDate.bind(this)}
                      requestedStartDate={requestedStartDate}
                    />

                    {/* Order Project Details Note */}
                    <OrderProjectNotes
                      onChange={this._handleChangeProjectNote.bind(this)}
                      projectNotes={projectNotes}
                    />
                  </MarketplaceSection>
                </div>
              </Col>

              {/* Cart Summary */}
              <Col md="3">
                <div className="site-sidebar mt-3 pt-4">
                  <CartSummary
                    playbook={this.state.playbookDetails}
                    cartOption={cartOption}
                    extras={extras}
                    customerDetails={customerDetails}
                    selectedExtras={this.state.selectedPlaybookExtras}
                    urgentDelivery={this.state.urgentDelivery}
                    additionalRevisions={this.state.additionalRevisions}
                    twoDesignOption={this.state.twoDesignOption}
                    requestedStartDate={requestedStartDate}
                    stripeProviderApiKey={stripeProviderApiKey}
                    history={this.props.history}
                    projectNotes={projectNotes}
                    settings={settings}
                  />
                </div>
              </Col>
            </Row>
          </Container>
        )}
      </div>
    );
  }
}
