import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import {
  COSELL_TAG_LIST,
  COSELL_GENERAL_TAB,
  TAB_COSELL
} from "../../../../setting/Constants";
// import CoSellCategory from "./Category";
import General from "./General";
import CoSell from "../../../../Constants/Cosell";

import PageTitle from "../../../../components/base/PageTitle";

import TagDetail from "./TagDetail";
import ManageTagTypeList from "../../../supportPortal/tabs/Lists/ManageTagTypeList";

// Sub Tab  Sections

const CoSellTab = props => {
  const {
    activeSubTab,
    adminSettings,
    saveSettings,
    toggleTabs,
    history,
    settings,
    activeSection
  } = props;

  return (
    <>
      {!activeSection ? (
        <div>
          <PageTitle label="Co Sell" />
          <Nav tabs className="admin-tabs">
            {/* General Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeSubTab === COSELL_GENERAL_TAB
                })}
                onClick={() => {
                  toggleTabs(TAB_COSELL, COSELL_GENERAL_TAB);
                }}
              >
                {COSELL_GENERAL_TAB}
              </NavLink>
            </NavItem>

            {/* Email Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeSubTab === COSELL_TAG_LIST
                })}
                onClick={() => {
                  toggleTabs(TAB_COSELL, COSELL_TAG_LIST);
                }}
              >
                {COSELL_TAG_LIST}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeSubTab}>
            {/* General Tab */}
            <TabPane tabId={COSELL_GENERAL_TAB} className="w-100">
              <General
                adminSettings={adminSettings}
                saveSettings={saveSettings}
                history={history}
                settings={settings}
              />
            </TabPane>

            {/* Category Tab */}
            <TabPane tabId={COSELL_TAG_LIST} className="w-100">
              <ManageTagTypeList
                history={history}
                tagType={CoSell.tagTypeOption}
                link="/admin-settings/Co-Sell/List"
                showButton
              />
            </TabPane>
          </TabContent>
        </div>
      ) : (
        <TagDetail
          activeSection={activeSection}
          history={history}
          match={props.match}
          roleId={props.roleId}
        />
      )}
    </>
  );
};

export default CoSellTab;
