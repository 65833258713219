import { combineReducers } from "redux";
import { routerReducer } from "react-router-redux";
import { reducer as form } from "redux-form";

// Reducer
import { expertAvailabilityReducer } from "./expertAvailabilityReducer";
import table from "./table";

// Dashboard
import { dashboardReducer } from "./dashboardReducer";

// User
import { userReducer } from "./userReducer";

// Projects
import { projectReducer } from "./projectReducer";
import { projectDetailsReducer } from "./projectDetailsReducer";

// Playbooks
import { playbookReducer } from "./playbookReducer";
import { resourceReducer } from "./resourceReducer";
import { courseReducer } from "./courseReducer";
import { playbookDetailsReducer } from "./playbookDetailsReducer";

// Settings
import { settingsReducer } from "./settingsReducer";
import { partnerSettingsReducer } from "./partnerSettingReducer";

const appReducer = combineReducers({
  routing: routerReducer,
  form,
  table,
  expertAvailability: expertAvailabilityReducer,
  dashboard: dashboardReducer,
  user: userReducer,
  projects: projectReducer,
  projectDetails: projectDetailsReducer,
  playbooks: playbookReducer,
  playbookDetails: playbookDetailsReducer,
  settings: settingsReducer,
  partnerSettings: partnerSettingsReducer,
  resource: resourceReducer,
  course: courseReducer
});

const rootReducer = (state, action) => {
  if (action.type === "UNAUTH_USER") {
    state = undefined;
  }

  return appReducer(state, action);
};

export default rootReducer;
