import React from "react";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { apiClient } from "../../../apiClient";

// Assets
import { CheckmarkIcon, ChevronRight } from "../../../assets/img/icons";
import { endpoints } from "../../../configs";
import {
  TASK_TYPE_COMPLETED_OFFLINE,
  TASK_TYPE_FILE_UPLOAD,
  TASK_VISIBILITY_ADMIN
} from "../../../modules/task/Constant";

const ProfileCompletionItem = props => {
  let {
    status,
    completed,
    title,
    subTitle,
    redirectUrl,
    type,
    visitUrl,
    task,
    setCurrentTask,
    setTaskFileUploadModal,
    getTaskList
  } = props;
  let url = {};
  if (!visitUrl && redirectUrl && type != TASK_TYPE_COMPLETED_OFFLINE) {
    url.to = redirectUrl;
  }
  const openLink = () => {
    if (visitUrl) {
      window.open(visitUrl);
    }
  };

  //Submit Complete Task
  const updateStatus = data => {
    if (data && data.id)
      return apiClient
        .post(
          `${endpoints().partnerApplicationTaskAPI}/user/task/${data.id}`,
          data
        )
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          toast.success(successMessage);
          getTaskList();
        })
        .catch(error => {
          if (error.response && error.response.status >= 400) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
          }
        });
  };
  return (
    <>
      <Link
        {...url}
        onClick={e => {
          if (visitUrl) {
            openLink();
          }
          if (type === TASK_TYPE_FILE_UPLOAD && !completed) {
            e.preventDefault();
            setCurrentTask(task);
            setTaskFileUploadModal();
          }
        }}
      >
        <div className="onboarding-link">
          <div className="onboarding-header">
            <div className="step-wrapper">
              <span className="step-count completed">
                {status && status.length > 0 ? (
                  status.map(details => {
                    let { status } = details;
                    return (
                      <>
                        {status === "Completed" ? (
                          <span
                            className={`step-count ${
                              status === "Completed"
                                ? `profile-percentage-completed`
                                : `profile-percentage-pending`
                            }`}
                            onClick={() => updateStatus(task)}
                          >
                            <CheckmarkIcon />
                          </span>
                        ) : (
                          <>
                            <span
                              className="step-count profile-percentage-pending"
                              onClick={() => updateStatus(task)}
                            />
                          </>
                        )}
                      </>
                    );
                  })
                ) : (
                  <span
                    className="step-count profile-percentage-pending"
                    onClick={() => updateStatus(task)}
                  />
                )}
              </span>
            </div>
          </div>

          <div className="onboarding-main">
            <h5 className="font-weight-bold">{title}</h5>
            <p className="font-weight-bold text-inline-grayed">{subTitle}</p>
          </div>
          <div className="onboarding-url">
            <ChevronRight />
          </div>
        </div>
      </Link>
    </>
  );
};

export default ProfileCompletionItem;
