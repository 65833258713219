import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";
import { isLoggedIn } from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import AddButton from "../../components/base/AddButton";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

export default class Invoice extends Component {
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    return (
      <div>
        <PageTitle label="Invoices" />

        <AddButton
          label="New Invoice"
          onClick="/invoice/add"
          className="pull-right"
        />

        <div>
          <ReduxTable
            id="invoices"
            apiURL={`${endpoints().invoiceAPI}`}
            onRowClick={row => this.props.history.push(`/invoice/${row.id}`)}
          >
            <ReduxColumn type="link" isClickable="true" field="name">
              Name
            </ReduxColumn>
            <ReduxColumn field="description">Description</ReduxColumn>
            <ReduxColumn field="amount">Amount</ReduxColumn>
            <ReduxColumn field="formattedDate">Date</ReduxColumn>
            <ReduxColumn field="createdAt">Created At</ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}
