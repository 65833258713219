import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";

// Helper
import { isLoggedIn } from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import AddButton from "../../components/base/AddButton";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

export default class ProjectCategory extends Component {
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    return (
      <div>
        <PageTitle label="Project categories" />

        <AddButton
          label="New Project Category"
          onClick="/admin/settings/project/category/add"
          className="pull-right"
        />

        <div>
          <ReduxTable
            id="projectCategory"
            apiURL={`${endpoints().projectCategoryAPI}`}
            onRowClick={row =>
              this.props.history.push(
                `/admin/settings/project/category/${row.id}`
              )
            }
          >
            <ReduxColumn type="link" isClickable="true" field="name">
              Name
            </ReduxColumn>
            <ReduxColumn field="createdAt">Created At</ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}
