import React, { useState, useEffect } from "react";
import { bindActionCreators } from "redux";
import { connect, useDispatch } from "react-redux";
import PageTitle from "../../../../components/base/PageTitle";
import toast from "../../../../components/base/Toast";

import {
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Pagination
} from "reactstrap";

// Components
import Form from "../../../../components/base/Form";
import Text from "../../../../components/Text";
import Select from "../../../../components/base/Select";
import TextArea from "../../../../components/base/TextArea";

// Settings
import AddButton from "../../../../components/base/AddButton";
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import { endpoints } from "../../../../configs";

//action
import { fetchList } from "../../../../actions/table";

// Tags
import {
  schedulerCreate,
  schedulerUpdate,
  schedulerDelete
} from "../../../../actions/scheduler";
import DeleteModal from "../../../../components/base/DeleteModal";
import { SchedulerApiUrl } from "./Constant";
import Button from "../../../../components/base/Button";
import DeleteButton from "../../../../components/base/DeleteButton";
import { isBadRequest } from "../../../../common/http";
import { apiClient } from "../../../../apiClient";
import Spinner from "../../../../components/base/Spinner";
import { getParamsByName } from "../../../../lib/helper";

const Scheduler = props => {
  // initilize states
  const [openSceduler, setOpenScheduler] = useState(false);
  const [openEditModal, _setEditModal] = useState(false);

  const [setId, schedulerId] = useState("");
  const [name, setSchedulerName] = useState("");
  const [interval, setInterval] = useState("");
  const [apiUrl, setSchedulerApiUrl] = useState("");
  const [status, setStatus] = useState();
  const [notes, setNotes] = useState("");
  const [deleteShedulerModal, setdeleteShedulerModal] = useState(false);
  const [currentSheduler, setCurrentSheduler] = useState(false);
  const [jobId, setJobId] = useState("Run");
  const [isLoading, setIsLoading] = useState(false);
  const [schedulerOptions, setSchedulerOptions] = useState([]);

  const [sortByOptions] = useState([
    { value: "Enabled:ASC", label: "Enabled" },
    { value: "Disabled:ASC", label: "Disabled" }
  ]);
  const [jobStatus, setJobStatus] = useState("");

  const showStatus = [
    { value: "Enabled", label: "Enabled" },
    { value: "Disabled", label: "Disabled" }
  ];

  const getSchedulerTime = () => {
    let Interval = [];
    const IntervalInHour = [];
    const IntervalInMin = [
      { value: 1, label: "1 Min" },
      { value: 5, label: "5 Min" },
      { value: 15, label: "15 Min" },
      { value: 30, label: "30 Min" }
    ];
    for (let i = 1; i <= 24; i++) {
      IntervalInHour.push({
        value: 60 * i,
        label: `${i} Hour`
      });
    }
    Interval = IntervalInMin.concat(IntervalInHour);

    return Interval;
  };

  useEffect(() => {
    getSchedulerFilterList();
  }, [props]);

  const getSchedulerFilterList = () => {
    try {
      apiClient.get(`${endpoints().schedulerJobAPI}/search`).then(response => {
        //validate response data
        if (response && response.data && response.data.data) {
          //get schedule liat
          let addedList = response.data.data;
          let jobList = new Array();
          //validate data
          if (addedList && addedList.length > 0 && SchedulerApiUrl.length > 0) {
            //create new array
            //loop the scheduler list
            SchedulerApiUrl.forEach(scheduler => {
              let data = addedList.find(job => job.apiUrl == scheduler.value);
              if (!data) {
                jobList.push(scheduler);
              }
            });
            //validate jibslit
            if (jobList && jobList.length > 0) {
              const schedulerList = jobList.sort(function(a, b) {
                if (a.value < b.value) {
                  return -1;
                }
                if (a.value > b.value) {
                  return 1;
                }
                return 0;
              });
              setSchedulerOptions(schedulerList);
            }
          } else {
            //create new array
            //loop the scheduler list
            SchedulerApiUrl.forEach(scheduler => {
              let data = addedList.find(job => job.apiUrl == scheduler.value);

              if (!data) {
                jobList.push(scheduler);
              }
            });
            //validate jibslit
            if (jobList && jobList.length > 0) {
              const schedulerList = jobList.sort(function(a, b) {
                if (a.value < b.value) {
                  return -1;
                }
                if (a.value > b.value) {
                  return 1;
                }
                return 0;
              });

              setSchedulerOptions(schedulerList);
            }
          }
        }
      });
    } catch (err) {
      console.log(err);
    }
  };

  const initialValues = {
    interval: interval
      ? getSchedulerTime().find(type => type.value === interval)
      : "",
    status: status ? showStatus.find(type => type.value === status) : "",
    schedulerJob: apiUrl
      ? SchedulerApiUrl.find(url => url.value == apiUrl)
      : "",
    notes: notes || ""
  };

  /**
   * Create scheduler
   *
   * @param data
   */
  const createScheduler = data => {
    props.actions.schedulerCreate(data).then(detail => {
      getSchedulerFilterList();
      if (detail) {
        _schedulerNewModalToggle();
      } else setOpenScheduler(true);
    });
  };

  /**
   * Update scheduler
   *
   * @param data
   * @param id
   */
  const updateSchedulerData = data => {
    // get parames by name
    const sort = getParamsByName("sort");
    const sortDir = getParamsByName("sortDir");

    let params = {
      sort: sort,
      sortDir: sortDir,
      pagination: true
    };
    props.actions.schedulerUpdate(setId, data, params).then(detail => {
      if (detail) {
        _schedulerToggle();
      } else _setEditModal(true);
    });
  };

  /**
   * Delete scheduler
   *
   * @param id
   */
  const deleteSchedulerData = id => {
    props.actions.schedulerDelete(id);
    _setEditModal(false);
    getSchedulerFilterList();
  };

  // Scheduler toggle modal
  const _schedulerToggle = data => {
    schedulerId((data && data.id) || "");
    setSchedulerName((data && data.name) || "");
    setInterval((data && data.interval) || "");
    setSchedulerApiUrl((data && data.apiUrl) || "");
    setStatus((data && data.status) || "");
    setNotes((data && data.notes) || "");
    _setEditModal(!openEditModal);
  };
  const _schedulerNewModalToggle = () => {
    setOpenScheduler(!openSceduler);
  };

  const getInterval = interval => {
    let TimeInterval;
    getSchedulerTime().forEach(Time => {
      if (interval === Time.value) {
        TimeInterval = Time.label;
      }
    });
    return TimeInterval;
  };

  //get schedule data
  const getSchedulerData = async id => {
    id = id ? id : currentSheduler.id;
    try {
      const response = await apiClient.get(
        `${endpoints().schedulerJobAPI}/${id}`
      );
      const data = response && response.data;
      setJobStatus(data && data.execution_status ? data.execution_status : "");
      setJobId("");
      if (data && data.execution_status == "Running") {
        setJobId(id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // spinner
  if (isLoading) {
    return <Spinner />;
  }

  // Render the scheduler Add modal
  const schedulerModal = () => (
    <div>
      <Modal
        isOpen={openSceduler}
        toggle={_schedulerNewModalToggle}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader
          toggle={_schedulerNewModalToggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <p>Add Scheduler</p>
        </ModalHeader>
        <Form
          initialValues={{
            schedulerJob: ""
          }}
          onSubmit={values => {
            values.name =
              values && values.schedulerJob && values.schedulerJob.label;
            values.apiUrl =
              values && values.schedulerJob && values.schedulerJob.value;
            createScheduler(values);
          }}
          enableReinitialize={true}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <Select
              name="schedulerJob"
              label="Scheduler Job"
              placeholder="Select Scheduler Job"
              error={""}
              required={true}
              options={schedulerOptions}
              isSearchable={true}
            />
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="Add" />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );

  // Render the scheduler Edit modal
  const schedulerEditModal = () => (
    <div>
      <Modal
        isOpen={openEditModal}
        toggle={_schedulerToggle}
        className="landing-create-popup w-100"
        backdrop="static"
      >
        <ModalHeader
          toggle={_schedulerToggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <p>Edit Scheduler</p>
        </ModalHeader>
        <Form
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={values => {
            const intervalTime = isNaN(values.interval)
              ? values.interval.value
              : values.interval;
            values.interval = intervalTime;
            const statusData =
              typeof values.status === "object"
                ? values.status.value
                : values.status;
            values.status = statusData;
            values.name =
              values && values.schedulerJob && values.schedulerJob.label;
            values.apiUrl =
              values && values.schedulerJob && values.schedulerJob.value;
            updateSchedulerData(values);
          }}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div className="field-wrapper">
              <Select
                name="schedulerJob"
                label="Scheduler Job"
                placeholder="Select Scheduler Job"
                error={""}
                required
                options={schedulerOptions}
                defaultValue={schedulerOptions.find(
                  url => url.value === apiUrl
                )}
                isSearchable={true}
              />
            </div>
            <div className="field-wrapper">
              <Select
                name="interval"
                label="Interval"
                placeholder="Select Interval"
                error={""}
                required
                options={getSchedulerTime()}
                defaultValue={getSchedulerTime().find(
                  type => type.value === interval
                )}
                isSearchable={true}
              />
            </div>
            <div className="field-wrapper">
              <Select
                name="status"
                label="Status"
                placeholder="Select status"
                required
                options={showStatus}
                isSearchable={true}
              />
            </div>
            <div className="field-wrapper">
              <TextArea
                name="notes"
                label="Notes"
                placeholder="Enter notes"
                defaultValue={notes}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <DeleteButton
                  label="Delete"
                  onClick={() => {
                    setdeleteShedulerModal(true);
                  }}
                />
                <Button type="submit" label="Update" />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
  // Handle job run
  const handleJobRun = async (endPoint, id) => {
    try {
      setJobId(id);
      const response = await apiClient.put(endPoint);
      if (response && response.data) {
        toast.success(response.data.message);
      }

      await getSchedulerData(id);
    } catch (error) {
      if (isBadRequest(error)) {
        toast.error(error.response.data.message);
        setJobId("");
      }
    }
  };

  return (
    <>
      <div>
        {schedulerModal()}
        {schedulerEditModal()}
        {/* page heading */}

        <PageTitle
          label="Scheduler Jobs"
          buttonLabel="Add New"
          buttonHandler={() => {
            _schedulerNewModalToggle();
          }}
        />

        <ReduxTable
          onScroll
          id="scheduler"
          apiURL={`${endpoints().schedulerJobAPI}/search`}
          searchPlaceholder="Search"
          onRowClick={row => {
            setCurrentSheduler(row);
            schedulerId(row.id);
            _schedulerToggle(row);
          }}
          newTableHeading
          showHeader
          sortByOptions={sortByOptions}
          paramsToUrl={true}
          history={props.history}
        >
          <ReduxColumn
            type="link"
            maxWidth={"100px"}
            minWidth={"100px"}
            field="name"
            isClickable="true"
          >
            Name
          </ReduxColumn>
          {/* <ReduxColumn
            width={"128px"}
            className="text-center"
            field="interval"
            renderField={row => getInterval(row.interval)}
          >
            Interval
          </ReduxColumn> */}
          <ReduxColumn maxWidth={"140px"} minWidth={"134px"} field="startedAt">
            Started At
          </ReduxColumn>
          <ReduxColumn maxWidth={"140px"} minWidth={"124px"} field="endedAt">
            Ended At
          </ReduxColumn>
          <ReduxColumn
            maxWidth={"140px"}
            minWidth={"124px"}
            renderField={row => (
              <div>
                {row.interval
                  ? getSchedulerTime().find(type => type.value == row.interval)
                      .label
                  : ""}
              </div>
            )}
          >
            Interval
          </ReduxColumn>
          <ReduxColumn
            maxWidth={"120px"}
            minWidth={"114px"}
            className="action-column"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <Button
                  id={row.id}
                  label={jobId == row.id ? "Running" : "Run"}
                  onClick={() => {
                    setCurrentSheduler(row);
                    handleJobRun(`${row.apiUrl}`, row.id);
                    setJobStatus("Running");
                  }}
                  disabled={
                    jobStatus == "Running" && jobId == row.id ? true : false
                  }
                />
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          isOpen={deleteShedulerModal}
          toggle={() => {
            setdeleteShedulerModal(prevState => !prevState);
          }}
          title="Delete scheduler"
          id={currentSheduler.id}
          label={currentSheduler.name}
          deleteFunction={deleteSchedulerData}
        />
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        schedulerCreate,
        schedulerUpdate,
        schedulerDelete
      },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(Scheduler);
