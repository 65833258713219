import React from "react";
import { Alert } from "reactstrap";
import moment from "moment";
import toast from "../../components/base/Toast";

// Components
import AdminActionNavigation from "../../components/base/AdminActionNavigation";
import PlaybookCard from "../../components/PlaybookCard";
import Page404 from "../Page404";
import ProjectStepWrapper from "./inner-components/ProjectStepWrapper";
import Steps from "./inner-components/ProjectSteps";
import TaskList from "./inner-components/TaskList";
import Discussion from "./DiscussionComponent";
import ProjectDetailsSidebar from "./inner-components/ProjectDetailsSidebar";
import AlertSuccess from "../../components/base/Success";
import ExpertReviewAndFeedBackAlert from "./inner-components/ExpertReviewAndFeedBackAlert";
import KickOffModalPreview from "./inner-components/KickOffModalPreview";
import KickOffScheduler from "./inner-components/KickOffScheduler";
import { connect } from "react-redux";

// Assets
import {
  CheckmarkCircleIcon,
  ChevronDown,
  ChevronRight,
  ChevronUp,
  CrossIcon,
  InlineInfoIcon,
  WatchIcon
} from "../../assets/img/icons";

// API
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";

// Helper
import {
  isCustomer,
  isExpert,
  getUrlParameter,
  removeParamsByName,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  isPartner,
  getUrlPath,
  getKeyValueByObject
} from "../../lib/helper";

// Constants
import {
  PROJECT_USER_STATUS_ACCEPTED,
  PROJECT_USER_STATUS_DECLINED,
  PROJECT_USER_STATUS_NO_RESPONSE,
  PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE,
  PROJECT_USER_STATUS_INTERESTED,
  PROJECT_USER_STATUS_NOT_INTERESTED,
  PROJECT_EXTEND_STATUS_PENDING,
  PROJECT_EXTEND_STATUS_ACCEPTED,
  PROJECT_EXTEND_STATUS_DECLINED,
  PROJECT_TYPE_CAMPAIGN_URL_PATH
} from "../../project/Constants";
import {
  PROJECT_STATUS_COMPLETED,
  PROJECT_STATUS_INPROCESS,
  PROJECT_STATUS_INTERVIEWING
} from "../../projectStatus/Constants";
import {
  DELIVERABLE_STATUS_COMPLETED,
  DELIVERABLE_STATUS_PENDING
} from "../../projectDeliverableStatus/Constants";

import {
  FEEDBACK_EXPERT_CATEGORY,
  FEEDBACK_PLAYBOOK_CATEGORY,
  FEEDBACK_REVIEW_STATUS_PENDING,
  FEEDBACK_REVIEW_STATUS_AWAITING
} from "../../feedback/Constants";

// Partner type setting constants
import { PARTNER_TYPE_SETTING_ENABLE_CO_MARKETING_CAMPAIGNS } from "../../../src/partnerTypeSetting/Constants";

import ProjectBudgetAlerts from "./ProjectBudgetAlerts";
import ProjectReviewFeedBackForm from "../../components/ProjectReviewFeedBackForm";
import ExpertReviewFeedbackForm from "../../components/ExpertReviewFeedbackForm";
import PlaybookReviewFeedBackForm from "../../components/PlaybookReviewFeedBackForm";
import Spinner from "../../components/base/Spinner";

// Modal
import LogGeneralHoursModal from "./inner-components/LogGeneralHoursModal";
import CompletedProjectModal from "./inner-components/CompletedProjectModal";
import AdditionalInterestedInfoModal from "./inner-components/AdditionalInterestedInfoModal";
import WrapperWithHamburgerMenu from "../../components/WrapperWithHamburgerMenu";
import MyContext from "../../context/MyContext";
import SomeComponents from "./SomeComponents";
import ProjectProvider from "../../context/projectContext/ProjectProvider";
import ProjectContext from "../../context/projectContext/ProjectContext";
import KickoffProvider from "../../context/kickoffContext/KickoffProvider";
import KickoffContext from "../../context/kickoffContext/KickoffContext";
import ProjectNotes from "./inner-components/ProjectNotes";
import ArchiveProjectModal from "./inner-components/ArchiveProjectModal";
import ProjectStatusChangeModal from "./inner-components/ProjectStatusChangeModal";
import DeleteExpertModal from "./inner-components/DeleteExpertModal";
import Button from "../../components/base/Button";
import CampaignPartnerTasks from "./CampaignPartnerTasks";
import { isBadRequest } from "../../common/http";

class ProjectDetails extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      projectDetails: [],
      isInvalidRequest: false,
      isLoading: false,
      generalHoursModal: false,
      checkboxCommentPublic: false,
      checkboxExpertCommentPublic: false,
      projectReviewSuccess: false,
      expertReviewSuccess: false,
      showFeedbackSection: true,
      showProjectReview: true,
      showExpertReview: true,
      pendingProjectUsers: [],
      hideProjectFeedbackNotification: false,
      hideProjectNewBudgetNotification: false,
      isUpdatedProjectInterestStatus: false,
      alertModal: true,
      startDate: new Date(),
      startTime: new Date(),
      projectReviewDetails: [],
      expertReviewDetails: [],
      additionInterestedInfoModal: false,
      isProjectReviewLoading: false,
      isExpertReviewLoading: false,
      isExpertApprovedProjectsLoading: false,
      isPendingProjectExpertsLoading: false,
      projectArchiveModal: false,
      projectStatusChangeModal: false,
      deleteExpertModal: false,
      taskCompltedCount: []
    };

    this.setCompletedProjectStatus = this.setCompletedProjectStatus.bind(this);

    this.props.context.setProjectId(this.props.match.params.id); // VERY VERY IMPORTANT UNTIL WE FIND A BETTER SOLUTION
  }

  // Handle Project Budget Notification
  handleProjectBudgetNotification = () => {
    this.setState({
      hideProjectNewBudgetNotification: !this.state
        .hideProjectNewBudgetNotification
    });
  };

  // Share availability
  shareExpertAvailability = projectId => {
    const data = {};
    data.startDate = this.state.startDate
      ? moment(this.state.startDate).format("DD-MM-YYYY")
      : "";
    data.startTime = this.state.startTime
      ? moment(this.state.startTime).format("h:mm A")
      : "";
    data.projectId = projectId;

    return apiClient
      .post(`${endpoints().projectUserAPI}/share/availability`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ errorMessage, successMessage: "" });
          console.error(errorMessage);
        }
      });
  };

  componentDidMount() {
    const projectId = this.props.match.params.id;
    this.getProjectTaskCompletedCount(projectId);

    this.props.context.getProjectDetails(projectId);
    this.props.context.getProjectTasks(projectId, true);
    this.props.context.getProjectApprovedExperts(projectId, true);
    this.props.context._getProjectUserDetails(true, projectId);
    this.props.context.getProjectExtendDetails(projectId);
    this.props.context.setCurrentTaskPartnerId("");

    if (!isExpert(this.props.roleId)) {
      this.props.context.getProjectTaskCompletedCount(projectId);
    }
    if (isCustomer(this.props.roleId)) {
      this._getProjectReviewByProjectId(true, projectId);
      this._getExpertReviewByProjectId(true, projectId);
      this.props.context._getProjectPendingExperts(true, projectId);
      this.props.context._getExpertsWaitingForAcceptanceList(true, projectId);
    }

    if (isExpert(this.props.roleId) && getUrlParameter("status")) {
      this.props.context.setExpertInterestedStatus(() =>
        this.props.context.updateProjectStatus(
          getUrlParameter("status"),
          projectId
        )
      );
    }
    if (isExpert(this.props.roleId)) {
      // TODO: @SANTHOSH TO UPDATE WITH API CALL FOR PLAYBOOK-REVIEW
      this._getProjectReviewByProjectId(true, projectId);
    }

    if (isPartner(this.props.roleId)) {
      const partnerTypeSettingEnableCampaign = this.props.partnerSettings
        ? getKeyValueByObject(
            this.props.partnerSettings,
            PARTNER_TYPE_SETTING_ENABLE_CO_MARKETING_CAMPAIGNS
          )
        : "";
      if (partnerTypeSettingEnableCampaign === "false") {
        this.setState({ isInvalidRequest: true });
      } else {
        this.setState({ isInvalidRequest: false });
      }
    }

    this.expertApproval = React.createRef();
  }

  componentDidUpdate(prevProps, prevState) {
    const { tasks: currentTasks } = this.props.context;
    const { tasks: prevTasks } = prevProps.context;

    if (prevTasks && !prevTasks.length && currentTasks && currentTasks.length) {
      this.props.context.setTasks({ tasks: currentTasks });
    }
  }

  // Handle Review Match Expert Notification
  _handleReviewMatchExpert = () => {
    this.expertApproval.current.scrollIntoView({
      behavior: "smooth",
      block: "start"
    });
  };

  // Hide Project Feedback Notification
  _hideProjectFeedbackNotification = () => {
    this.setState({ hideProjectFeedbackNotification: true });
  };

  // Project Review By Project Id
  _getProjectReviewByProjectId = (isProjectReviewLoading, projectId) => {
    return apiClient
      .get(
        `${
          endpoints().reviewAPI
        }/search?projectId=${projectId}&category=${FEEDBACK_PLAYBOOK_CATEGORY}&status=${FEEDBACK_REVIEW_STATUS_AWAITING}`
      )
      .then(response => {
        if (response.data) {
          this.setState({
            projectReviewDetails: response.data.data
              ? response.data.data[0]
              : [],
            isProjectReviewLoading: !isProjectReviewLoading,
            showProjectReview: true
          });
        } else {
          this.setState({
            showProjectReview: false
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Expert Review By Project Id
  _getExpertReviewByProjectId = (isExpertReviewLoading, projectId) => {
    return apiClient
      .get(
        `${
          endpoints().reviewAPI
        }/search?projectId=${projectId}&category=${FEEDBACK_EXPERT_CATEGORY}&status=${FEEDBACK_REVIEW_STATUS_AWAITING}`
      )
      .then(response => {
        const data = response.data;
        if (response.data) {
          this.setState({
            expertReviewDetails: data.data ? data.data[0] : [],
            isExpertReviewLoading: !isExpertReviewLoading,
            showExpertReview: true
          });
        } else {
          this.setState({
            showExpertReview: false
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Check campaign project
  isCampaignProject = () => {
    const currentBasePath = getUrlPath(1);
    const isCampaign = currentBasePath === PROJECT_TYPE_CAMPAIGN_URL_PATH;
    return isCampaign;
  };

  // Update project tasks / deliverable by deliverable id
  updateProjectTasks = (id, name, due_date, assignee = {}) => {
    const isCampaign = this.isCampaignProject();
    return apiClient
      .put(`${endpoints().projectDeliverableAPI}/${id}`, {
        name,
        due_date: moment(due_date).format("YYYY-MM-DD"),
        user_id: assignee.id ? assignee.id : "",
        isCampaign
      })
      .then(() => {
        this.props.context.getProjectDetails(this.props.match.params.id);
        this.props.context.getProjectTasks(this.props.match.params.id);
        this.setState({ isLoading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Get project task Completed Count
  getProjectTaskCompletedCount = projectId => {
    let pid = projectId ? projectId : this.state.projectId;
    return apiClient
      .get(`${endpoints().projectAPI}/task/count/completed/${pid}`)
      .then(response => {
        if (response.data) {
          const data = response.data;
          this.setState({ taskCompltedCount: data });
          this.props.context.getProjectDetails(projectId);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Delete project tasks / deliverable by deliverable id
  deleteProjectTasks = async id => {
    const projectId = this.props.match.params.id;
    let currentTaskPartnerId = this.props.context.currentTaskPartnerId;
    const isCampaign = this.isCampaignProject();
    return await apiClient
      .delete(
        `${
          endpoints().projectDeliverableAPI
        }/${id}/?isCampaign=${isCampaign}&currentTaskPartnerId=${currentTaskPartnerId}`
      )
      .then(response => {
        let successMessage;
        if (response.data) {
          successMessage = response.data.message;
          this.props.context.getProjectApprovedExperts(projectId, true);
          this.props.context.getProjectTasks(projectId);
          this.getProjectTaskCompletedCount(projectId);
          this.props.context.getProjectDetails(projectId);
          this.setState({ isLoading: true });
        }
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Request project tasks / deliverable approval
  requestApproveDeliverable = async id => {
    const { customerId } = this.props.context.projectDetails;
    const projectId = this.props.match.params.id;
    return apiClient
      .put(`${endpoints().projectDeliverableAPI}/approve/request/${id}`, {
        customerId,
        status: DELIVERABLE_STATUS_PENDING
      })
      .then(async () => {
        this.getProjectTaskCompletedCount(projectId);
        this.props.context.getProjectTasks(this.props.match.params.id);
        await this.getProjectTaskCompletedCount(this.props.match.params.id);
        this.props.context.getProjectDetails(projectId);
        this.setState({ isLoading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Request project tasks / deliverable approval
  updateTaskStatusDeliverable = async (id, status) => {
    const isCampaign = this.isCampaignProject();
    const projectId = this.props.match.params.id;
    if (status === DELIVERABLE_STATUS_COMPLETED) {
      status = "";
    } else {
      status = DELIVERABLE_STATUS_COMPLETED;
    }
    const { customerId } = this.props.context.projectDetails;
    return await apiClient
      .put(`${endpoints().projectDeliverableAPI}/approve/request/${id}`, {
        customerId,
        status,
        isCampaign,
        currentTaskPartnerId: this.props.context.currentTaskPartnerId
      })
      .then(response => {
        this.getProjectTaskCompletedCount(projectId);
        this.props.context.getProjectDetails(projectId);
        this.setState({ isLoading: true });
        this.setState({ deliverableStatus: response.data.status });
        this.props.context.getProjectTasks(projectId);
        this.props.context.changeCampaignStatus();

        if (!isExpert()) {
          this.props.context.getProjectTaskCompletedCount(projectId);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  /**
   * Save project review feedback
   */
  saveProjectFeedback = data => {
    const {
      id,
      projectValueForMoney,
      projectQualityOfFinalDeliverable,
      projectOverAllExperience,
      projectReviewNotes,
      checkboxCommentPublic
    } = data;

    const reviewRatingData = {
      value_for_money: projectValueForMoney,
      quality_of_final_deliverable: projectQualityOfFinalDeliverable,
      overall_experience: projectOverAllExperience
    };
    return apiClient
      .put(`${endpoints().reviewAPI}/${id}`, {
        reviewRatingData,
        comment: projectReviewNotes,
        isPublic: checkboxCommentPublic,
        status: FEEDBACK_REVIEW_STATUS_PENDING
      })
      .then(() => {
        const projectId = this.props.match.params.id;
        this._getProjectReviewByProjectId(true, projectId);
        this._getExpertReviewByProjectId(true, projectId);
        this.setState({ projectReviewSuccess: true, showProjectReview: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Save Customer Project Review Feedback
  saveExpertProjectForCustomerFeedback = data => {
    const {
      id,
      communicationFromClient,
      playbookCompensation,
      playbookOverAllExperience,
      playbookReviewNotes,
      checkboxCommentPublic
    } = data;

    const reviewRatingData = {
      communication_from_client: communicationFromClient,
      compensation: playbookCompensation,
      overall_experience: playbookOverAllExperience
    };

    return apiClient
      .put(`${endpoints().reviewAPI}/${id}`, {
        reviewRatingData,
        comment: playbookReviewNotes,
        isPublic: checkboxCommentPublic,
        status: FEEDBACK_REVIEW_STATUS_PENDING
      })
      .then(() => {
        const projectId = this.props.match.params.id;
        this._getProjectReviewByProjectId(true, projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  /**
   * Save project review feedback
   */
  saveExpertFeedback = data => {
    const {
      id,
      expertCommunication,
      expertWorkWithAgain,
      expertOverallExperience,
      expertReviewNotes,
      checkboxCommentPublic
    } = data;

    const reviewRatingData = {
      communication: expertCommunication,
      work_with_again_or_recommend: expertWorkWithAgain,
      overall_experience: expertOverallExperience
    };

    return apiClient
      .put(`${endpoints().reviewAPI}/${id}`, {
        reviewRatingData,
        isPublic: checkboxCommentPublic,
        comment: expertReviewNotes,
        status: FEEDBACK_REVIEW_STATUS_PENDING
      })
      .then(() => {
        this.setState({ expertReviewSuccess: true, showExpertReview: false });
        const projectId = this.props.match.params.id;
        this._getProjectReviewByProjectId(true, projectId);
        this._getExpertReviewByProjectId(true, projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Set completed after project task and deliverables completed
  setCompletedProjectStatus() {
    const projectId = this.props.match.params.id;
    const { customerId } = this.props.context.projectDetails;
    return apiClient
      .put(`${endpoints().projectAPI}/${projectId}`, {
        status: PROJECT_STATUS_COMPLETED,
        customerId
      })
      .then(() => {
        this.setState({ isLoading: true });
        this.props.context.getProjectDetails(projectId);
        this._getProjectReviewByProjectId(true, projectId);
        this._getExpertReviewByProjectId(true, projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  }

  // Get Pending Tasks
  getPendingTasks = () => {
    if (
      this.props.context.tasks === undefined ||
      this.props.context.tasks === null
    ) {
      return false;
    }

    return this.props.context.tasks.filter(
      task => task.status === DELIVERABLE_STATUS_PENDING
    ).length;
  };

  // Update Archive Project
  updateProjectArchive = () => {
    const isCampaign = this.isCampaignProject();
    const projectId = this.props.match.params.id;
    return apiClient
      .put(`${endpoints().projectAPI}/archive/${projectId}`)
      .then(response => {
        toast.success(response.data.message);
        this.props.history.push(isCampaign ? `/campaigns` : `/projects`);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Update Project Status
  handleUpdateProjectStatus = status => {
    const projectId = this.props.match.params.id;

    return apiClient
      .put(`${endpoints().projectAPI}/status/${projectId}`, {
        projectStatus: status
      })
      .then(response => {
        toast.success(response.data.message);
        this.props.context.getProjectDetails(projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          this.setState({ isInvalidRequest: true });
        }
      });
  };

  removeApprovedExpert = data => {
    const projectId = this.props.match.params.id;
    return apiClient
      .patch(`${endpoints().projectUserAPI}/deleteApprovedExpert/`, {
        data,
        projectId: projectId
      })
      .then(() => {
        this.props.context.getProjectDetails(projectId);
        this.props.context.getProjectApprovedExperts(projectId, true);
        this.props.context._getProjectUserDetails(true, projectId);
        this.props.context.getProjectTasks(projectId, true);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Set project archive modal
  toggleArchiveProjectModal = () =>
    this.setState({ projectArchiveModal: !this.state.projectArchiveModal });

  // Set project status change modal
  toggleProjectStatusChangeModal = () =>
    this.setState({
      projectStatusChangeModal: !this.state.projectStatusChangeModal
    });

  // Set project archive modal
  toggleDeleteExpertModal = () =>
    this.setState({ deleteExpertModal: !this.state.deleteExpertModal });

  // redirect to edit page
  handleEdit = () => {
    this.props.history.push(`/campaign/edit/${this.props.match.params.id}`);
  };

  render() {
    const {
      isInvalidRequest,
      projectReviewSuccess,
      expertReviewSuccess,
      showProjectReview,
      showExpertReview,
      hideProjectFeedbackNotification,
      hideProjectNewBudgetNotification,
      projectReviewDetails,
      expertReviewDetails,
      projectArchiveModal,
      projectStatusChangeModal,
      deleteExpertModal,
      taskCompltedCount
    } = this.state;

    const {
      hideFeedbackAlert,
      expertApprovalSuccess,
      expertApprovalSuccessRef,
      kickOffModalPreview,
      additionInterestedInfoModal,
      projectCompletedModal,
      approvedProjectExpertList,
      generalHoursModal,
      showMore,
      hideReviewMatchExpert,
      projectDetails,
      isLoading,
      tasks,
      currentUserId,
      isUpdatedProjectInterestStatus,
      projectUserStatus,
      projectExtendStatus,
      projectExtendDetails,
      pendingProjectUsers,
      projectWaitingForAcceptanceExpertList,
      kickoffHappened,
      approvedExpertProjectUserStatus,
      isProjectInvalidRequest,
      changeCampaignStatus,
      setCurrentTaskPartnerId,
      setAssignTaskPartnerId
    } = this.props.context;

    const projectId = this.props.match.params.id;
    const settings = this.props.settings ? this.props.settings : "";

    const {
      projectTeam,
      projectPartners,
      projectAssignee,
      statusHistory,
      newEstimatedBudget,
      status,
      notes
    } = projectDetails;

    if (isProjectInvalidRequest || isInvalidRequest) {
      return <Page404 />;
    }

    if (!isLoading) {
      return <Spinner />;
    }
    const isCampaign = this.isCampaignProject();
    const descriptionLength = 200;
    const projectDescriptionLengthExceed =
      projectDetails.description &&
      projectDetails.description.length > descriptionLength;

    const expertInterestStatus = getUrlParameter("status");

    let kickoffExpertId;

    if (
      approvedProjectExpertList.length > 0 &&
      approvedProjectExpertList[0].id
    ) {
      kickoffExpertId = approvedProjectExpertList[0].id;
    }

    return (
      <>
        <SomeComponents />
        <AdditionalInterestedInfoModal
          modal={additionInterestedInfoModal}
          setModal={this.props.context.setAdditionInterestedInfoModal}
          sendMessage={this.props.context.sendAdditionalInterestedInfoMessage}
        />
        <MyContext.Consumer>
          {context => (
            <WrapperWithHamburgerMenu isMobile={context.isMobile}>
              <AdminActionNavigation>
                <div className="d-inline-flex align-items-center">
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      this.props.history.push(
                        isCampaign ? `/campaigns` : `/projects`
                      );
                    }}
                  >
                    {projectDetails.type}s
                  </a>
                  <span
                    className="text-dark d-inline-flex align-items-cente"
                    style={{ width: "15px" }}
                  >
                    <ChevronRight />
                  </span>
                  <span
                    onClick={e => e.preventDefault()}
                    className={[
                      "text-dark",
                      "font-weight-bold",
                      "d-inline-flex",
                      "align-items-center",
                      "project-name",
                      "w-320"
                    ].join(" ")}
                  >
                    <span className="ellipsis">{projectDetails.name}</span>
                  </span>
                </div>

                <div ref={expertApprovalSuccessRef}>
                  <ProjectStepWrapper
                    steps={Steps()}
                    projectStatusHistory={statusHistory}
                    projectStatus={projectDetails.status}
                  />
                </div>
              </AdminActionNavigation>
            </WrapperWithHamburgerMenu>
          )}
        </MyContext.Consumer>
        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) &&
          status !== PROJECT_STATUS_COMPLETED && (
            <CompletedProjectModal
              id={projectDetails.id}
              modal={projectCompletedModal}
              setModal={this.props.context.setProjectCompletedModal}
              projectName={projectDetails.name}
              setCompletedProjectStatus={this.setCompletedProjectStatus}
              projectType={projectDetails.type}
            />
          )}
        {isPartner(this.props.roleId) &&
          !isUpdatedProjectInterestStatus &&
          projectUserStatus === PROJECT_USER_STATUS_NO_RESPONSE && (
            <div className="alerts-wrapper">
              <Alert
                color="default"
                className={
                  "d-flex align-items-center justify-content-stretch text-white alert-dark-info"
                }
              >
                <p className="mb-0 font-weight-bold mr-auto text-white">
                  Would you like to accept this invite?
                </p>
                <div className="btn-wrapper ml-auto mr-0 font-weight-bold">
                  <Button
                    id={"partner-accept-campaign"}
                    className="btn btn-primary"
                    label={"Yes, Commit to this Invite"}
                    onClick={() =>
                      this.props.context.updateProjectStatus(
                        PROJECT_USER_STATUS_ACCEPTED,
                        projectId
                      )
                    }
                  />
                  <button
                    id={"partner-decline-campaign"}
                    className="btn btn-danger ml-3"
                    onClick={() =>
                      this.props.context.updateProjectStatus(
                        PROJECT_USER_STATUS_NOT_INTERESTED,
                        projectId
                      )
                    }
                  >
                    I Can Not Accept Invite
                  </button>
                </div>
              </Alert>
            </div>
          )}
        {isExpert(this.props.roleId) &&
          !isUpdatedProjectInterestStatus &&
          projectUserStatus === PROJECT_USER_STATUS_NO_RESPONSE && (
            <div className="alerts-wrapper">
              <Alert
                color="dark-info"
                className={
                  "d-flex align-items-center justify-content-stretch text-dark alert-light-info"
                }
              >
                <p className="mb-0 font-weight-bold mr-auto text-dark">
                  Are you available and interested in this project?
                </p>
                <div className="btn-wrapper ml-auto mr-0">
                  <button
                    className="btn btn-outline-dark mr-4"
                    onClick={() =>
                      this.props.context.updateProjectStatus(
                        PROJECT_USER_STATUS_NOT_INTERESTED,
                        projectId
                      )
                    }
                  >
                    No, Thank You!
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      this.props.context.updateProjectStatus(
                        PROJECT_USER_STATUS_INTERESTED,
                        projectId
                      )
                    }
                  >
                    I'm interested!
                  </button>
                </div>
              </Alert>
            </div>
          )}
        {isExpert(this.props.roleId) && isUpdatedProjectInterestStatus && (
          <Alert
            color="dark-success"
            className={
              "d-flex align-items-center justify-content-stretch text-white"
            }
          >
            <CheckmarkCircleIcon />
            <p className="ml-3 mb-0 font-weight-bold mr-auto text-white">
              You are {expertInterestStatus.toString().toLowerCase()} to work on
              this project!
            </p>
            <div className="close-alert mr-0 ml-0">
              <button
                className="btn-plain btn text-white p-0"
                onClick={() => {
                  this.setState(
                    { isUpdatedProjectInterestStatus: false },
                    () => {
                      removeParamsByName("status");
                      window.history.replaceState(
                        {},
                        "",
                        `${window.location.pathname}`
                      );
                    }
                  );
                }}
              >
                <CrossIcon />
              </button>
            </div>
          </Alert>
        )}
        {isExpert(this.props.roleId) &&
          projectUserStatus === PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE && (
            <div className="alerts-wrapper">
              <Alert
                color="dark-info"
                className={
                  "d-flex align-items-center justify-content-stretch text-dark alert-light-info"
                }
              >
                <p className="mb-0 font-weight-bold mr-auto text-dark">
                  Would you like to accept this project?
                </p>
                <div className="btn-wrapper ml-auto mr-0">
                  <button
                    className="btn btn-outline-dark mr-4"
                    onClick={() =>
                      this.props.context.updateProjectStatus(
                        PROJECT_USER_STATUS_DECLINED,
                        projectId
                      )
                    }
                  >
                    No, I Reject!
                  </button>
                  <button
                    className="btn btn-success"
                    onClick={() =>
                      this.props.context.updateProjectStatus(
                        PROJECT_USER_STATUS_ACCEPTED,
                        projectId
                      )
                    }
                  >
                    Yes, I Accept!
                  </button>
                </div>
              </Alert>
            </div>
          )}

        {isExpert(this.props.roleId) &&
          projectUserStatus === PROJECT_USER_STATUS_ACCEPTED &&
          projectDetails.status === PROJECT_STATUS_INPROCESS && (
            <KickOffModalPreview
              isOpen={kickOffModalPreview}
              toggle={this.props.context.setPreviewKickOffModal}
            />
          )}

        {isExpert(this.props.roleId) &&
          projectExtendStatus === PROJECT_EXTEND_STATUS_PENDING &&
          projectUserStatus === PROJECT_USER_STATUS_ACCEPTED && (
            <div className="alerts-wrapper">
              <Alert
                color="dark-info"
                className={
                  "d-flex align-items-center justify-content-stretch text-dark alert-dark-info"
                }
              >
                <p className="mb-0 font-weight-bold mr-auto text-white">
                  Would you like to accept extension?
                </p>
                <div className="btn-wrapper ml-auto mr-0">
                  <button
                    className="btn btn-info mr-4 font-weight-bold"
                    onClick={() =>
                      this.props.context.updateProjectExtendStatus(
                        PROJECT_EXTEND_STATUS_ACCEPTED,
                        projectId
                      )
                    }
                  >
                    Yes, Accept Extension
                  </button>
                  <button
                    className="btn btn-danger mr-0 font-weight-bold"
                    onClick={() =>
                      this.props.context.updateProjectExtendStatus(
                        PROJECT_EXTEND_STATUS_DECLINED,
                        projectId
                      )
                    }
                  >
                    No, Decline Extension
                  </button>
                </div>
              </Alert>
              <div className="tabs-and-preview">
                <div className="tab-content-wrapper pl-5 pr-5 pt-4 pb-3">
                  <div className="row">
                    <div className="col-3">
                      <p>Additional Hours</p>
                      <p className="font-weight-bold">
                        {projectExtendDetails.additional_hours}
                      </p>
                    </div>
                    <div className="col-3">
                      <p>Extension Date</p>
                      <p className="font-weight-bold">
                        {projectExtendDetails.extendDate}
                      </p>
                    </div>
                    <div className="col-6">
                      <p>Customer Message</p>
                      <p className="font-weight-bold">
                        “{projectExtendDetails.notes}”
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}

        {isCustomer(this.props.roleId) && (
          <>
            <div className="alerts-wrapper">
              {!hideProjectFeedbackNotification &&
                (showExpertReview || showProjectReview) &&
                projectDetails.status === PROJECT_STATUS_COMPLETED && (
                  <Alert
                    color="danger"
                    className={
                      "d-flex align-items-center justify-content-stretch text-white"
                    }
                  >
                    <InlineInfoIcon />
                    <p className="ml-3 mb-0 font-weight-bold mr-auto text-white">
                      Your project is completed and awaiting your review!
                    </p>
                    <div className="btn-wrapper ml-auto mr-3">
                      {!this.props.context.showProjectReviewFeedback ? (
                        <button
                          className="btn btn-outline-light"
                          onClick={this.props.context.showFeedback}
                        >
                          Add Feedback
                        </button>
                      ) : (
                        <button
                          className="btn btn-outline-light"
                          onClick={this.props.context.hideFeedback}
                        >
                          Hide Feedback
                        </button>
                      )}
                    </div>
                    <div className="close-alert mr-0 ml-0">
                      <button
                        className="btn-plain btn text-white p-0"
                        onClick={() =>
                          this.setState({
                            hideProjectFeedbackNotification: true
                          })
                        }
                      >
                        <CrossIcon />
                      </button>
                    </div>
                  </Alert>
                )}

              {projectReviewSuccess && (
                <Alert
                  color="success"
                  className={
                    "d-flex align-items-center justify-content-stretch text-white"
                  }
                >
                  <CheckmarkCircleIcon />
                  <p className="ml-3 mb-0 font-weight-bold mr-auto text-white">
                    Thank you for your project feedback!
                  </p>
                  <div className="close-alert mr-0 ml-0">
                    <button
                      className="btn-plain btn text-white p-0"
                      onClick={() =>
                        this.setState({ projectReviewSuccess: false })
                      }
                    >
                      <CrossIcon />
                    </button>
                  </div>
                </Alert>
              )}

              {expertReviewSuccess && (
                <Alert
                  color="success"
                  className={
                    "d-flex align-items-center justify-content-stretch text-white"
                  }
                >
                  <CheckmarkCircleIcon />
                  <p className="ml-3 mb-0 font-weight-bold mr-auto text-white">
                    Thank you for your project expert feedback!
                  </p>
                  <div className="close-alert mr-0 ml-0">
                    <button
                      className="btn-plain btn text-white p-0"
                      onClick={() =>
                        this.setState({ expertReviewSuccess: false })
                      }
                    >
                      <CrossIcon />
                    </button>
                  </div>
                </Alert>
              )}

              {!hideReviewMatchExpert &&
                pendingProjectUsers &&
                pendingProjectUsers.length > 0 && (
                  <Alert
                    color="danger"
                    className={
                      "d-flex align-items-center justify-content-stretch text-white"
                    }
                  >
                    <InlineInfoIcon />
                    <p className="ml-3 mb-0 font-weight-bold mr-auto text-white">
                      Torchlite is awaiting your decision on the matched Expert.
                    </p>
                    <div className="btn-wrapper ml-auto mr-3">
                      <button
                        className="btn btn-outline-light"
                        onClick={() => {
                          this._handleReviewMatchExpert();
                        }}
                      >
                        Review the Matched Expert
                      </button>
                    </div>
                    <div className="close-alert mr-0 ml-0">
                      <button
                        className="btn-plain btn text-white p-0"
                        onClick={() => {
                          this.props.context._hideReviewMatchExpert();
                        }}
                      >
                        <CrossIcon />
                      </button>
                    </div>
                  </Alert>
                )}

              {isCustomer(this.props.roleId) &&
                !hideReviewMatchExpert &&
                projectDetails.status === PROJECT_STATUS_INTERVIEWING &&
                pendingProjectUsers &&
                pendingProjectUsers.length === 0 &&
                projectWaitingForAcceptanceExpertList &&
                projectWaitingForAcceptanceExpertList.length === 0 && (
                  <Alert
                    color="danger"
                    className={
                      "d-flex align-items-center justify-content-stretch text-white"
                    }
                  >
                    <InlineInfoIcon />
                    <p className="ml-3 mb-0 font-weight-bold mr-auto text-white">
                      We are finding a new expert for you. A new pick will be
                      available for you within 24 hours.
                    </p>

                    <div className="close-alert mr-0 ml-0">
                      <button
                        className="btn-plain btn text-white p-0"
                        onClick={() => {
                          this.props.context._hideReviewMatchExpert();
                        }}
                      >
                        <CrossIcon />
                      </button>
                    </div>
                  </Alert>
                )}

              {expertApprovalSuccess && (
                <AlertSuccess
                  message="Your expert has been approved. Now, we just
                need to wait a moment for them to confirm."
                  onClick={this.props.context.hideExpertApprovalSuccess}
                />
              )}

              {isCustomer(this.props.roleId) &&
                !hideProjectNewBudgetNotification &&
                newEstimatedBudget && (
                  <ProjectBudgetAlerts
                    handleProjectBudgetNotification={
                      this.handleProjectBudgetNotification
                    }
                    handleNewEstimatedBudget={
                      this.props.context.handleNewEstimatedBudget
                    }
                  />
                )}
              {Boolean(this.getPendingTasks()) &&
                !this.props.context.hideFeedbackAwaitingAlert && (
                  <Alert
                    color="danger"
                    className={
                      "d-flex align-items-center justify-content-stretch text-white"
                    }
                  >
                    <InlineInfoIcon />
                    <p className="ml-3 mb-0 mr-auto text-white">
                      The Expert is awaiting your feedback on a deliverable -
                      Check off if complete or provide feedback
                    </p>
                    <div className="close-alert mr-0 ml-0">
                      <button
                        className="btn-plain btn text-white p-0"
                        onClick={
                          this.props.context.setHideFeedbackAwaitingAlert
                        }
                      >
                        <CrossIcon />
                      </button>
                    </div>
                  </Alert>
                )}
            </div>

            {this.props.context.showProjectReviewFeedback && (
              <>
                {showProjectReview &&
                  !projectReviewSuccess &&
                  projectReviewDetails &&
                  projectReviewDetails.projectDetails && (
                    <ProjectReviewFeedBackForm
                      projectDetails={projectReviewDetails}
                      saveProjectFeedback={e => this.saveProjectFeedback(e)}
                    />
                  )}

                {showExpertReview &&
                  expertReviewDetails &&
                  expertReviewDetails.projectExperts && (
                    <ExpertReviewFeedbackForm
                      projectExpert={expertReviewDetails}
                      saveExpertFeedback={e => this.saveExpertFeedback(e)}
                    />
                  )}
              </>
            )}
          </>
        )}

        {isExpert(this.props.roleId) && showProjectReview && (
          <>
            <div className="alerts-wrapper">
              {!hideFeedbackAlert && (
                <ExpertReviewAndFeedBackAlert
                  showProjectReviewFeedback={
                    this.props.context.showProjectReviewFeedback
                  }
                  showFeedback={this.props.context.showFeedback}
                  hideFeedback={this.props.context.hideFeedback}
                  hideFeedbackAlert={this.props.context.setHideFeedbackAlert}
                />
              )}
            </div>
            {/* TODO: @SANTHOSH TO UPDATE WITH PLAYBOOK-REVIEW API*/}
            {this.props.context.showProjectReviewFeedback &&
              showProjectReview &&
              !projectReviewSuccess &&
              projectReviewDetails &&
              projectReviewDetails.projectDetails && (
                <PlaybookReviewFeedBackForm
                  projectDetails={projectReviewDetails}
                  saveProjectFeedback={e =>
                    this.saveExpertProjectForCustomerFeedback(e)
                  }
                />
              )}
          </>
        )}

        {/*TODO: Rasie Question on Standup, how do we handle multiple experts?*/}
        {(isExpert(this.props.roleId) || isCustomer(this.props.roleId)) &&
          approvedProjectExpertList.length > 0 &&
          approvedExpertProjectUserStatus === PROJECT_USER_STATUS_ACCEPTED &&
          !kickoffHappened &&
          projectDetails.status !== PROJECT_STATUS_COMPLETED && (
            <KickoffProvider
              projectId={projectId}
              expertId={approvedProjectExpertList[0].id}
            >
              <KickoffContext.Consumer>
                {context =>
                  ((context.kickoffData &&
                    context.kickoffData.status !== "Completed") ||
                    context.kickoffData === null) && (
                    <KickOffScheduler
                      expertId={approvedProjectExpertList[0].id}
                      projectId={this.props.context.projectDetails.id}
                    />
                  )
                }
              </KickoffContext.Consumer>
            </KickoffProvider>
          )}

        <div className="project-details-wrapper">
          <div className="project-details">
            <div className="project-details-header">
              <div className="img-wrapper mb-n4">
                {/* TODO: @SANTHOSH Swap this out with the expert card. */}
                <PlaybookCard
                  data={projectDetails}
                  isPreview={"true"}
                  size={"project-playbook-card"}
                  boxShadow={false}
                  placeHolder={true}
                  bannerImageUrl={projectDetails.bannerImageUrl}
                  tag={"div"}
                  showCardBody={false}
                  fullWidth
                  projectEditOptions={true}
                  showOption={true}
                  onClickProjectArchive={() => this.toggleArchiveProjectModal()}
                  onProjectStatusChange={() =>
                    this.toggleProjectStatusChangeModal()
                  }
                  onClickDeleteExpertModal={() =>
                    this.toggleDeleteExpertModal()
                  }
                  approvedProjectExpertList={approvedProjectExpertList}
                  optionName={`Archive ${projectDetails.type}`}
                  editOption={isCampaign && `Edit ${projectDetails.type}`}
                  onClickEdit={this.handleEdit}
                />
                <ArchiveProjectModal
                  modal={projectArchiveModal}
                  setModal={() => this.toggleArchiveProjectModal()}
                  updateProjectArchive={this.updateProjectArchive}
                  label={`Archive ${projectDetails.type}`}
                />
                <ProjectStatusChangeModal
                  modal={projectStatusChangeModal}
                  setModal={() => this.toggleProjectStatusChangeModal()}
                  onUpdateProjectStatus={this.handleUpdateProjectStatus}
                  label={`${projectDetails.type} Status`}
                />
                <DeleteExpertModal
                  modal={deleteExpertModal}
                  setModal={() => this.toggleDeleteExpertModal()}
                  removeApprovedExpert={this.removeApprovedExpert}
                  approvedProjectExpertList={approvedProjectExpertList}
                />
              </div>
              <div className="description">
                <p className="font-weight-bold">{projectDetails.name}</p>

                <p>
                  {projectDescriptionLengthExceed && !showMore
                    ? `${projectDetails.description.slice(
                        0,
                        descriptionLength
                      )}...`
                    : projectDetails.description}
                </p>
                {projectDescriptionLengthExceed ? (
                  <a
                    href="#"
                    className={["d-flex", "show-more"].join(" ")}
                    onClick={e => {
                      e.preventDefault();
                      this.props.context.showMoreHandler();
                    }}
                  >
                    Show{" "}
                    {showMore ? (
                      <>
                        Less
                        <ChevronUp />
                      </>
                    ) : (
                      <>
                        More
                        <ChevronDown />
                      </>
                    )}
                  </a>
                ) : (
                  ""
                )}
              </div>
            </div>

            {/*Show Task And Deliverable In The Project*/}

            <div className="project-details-body">
              <div className="details-body-main">
                {approvedProjectExpertList &&
                  approvedProjectExpertList.length > 0 && (
                    <KickoffProvider
                      projectId={projectId}
                      expertId={approvedProjectExpertList[0].id}
                    >
                      <KickoffContext.Consumer>
                        {kickoffContext => (
                          <div
                            className={`tasks-and-deliverables
                                ${kickoffContext.kickoffData &&
                                  kickoffContext.kickoffData.status !==
                                    "Completed" &&
                                  isExpert(this.props.roleId) &&
                                  "disabled"}`}
                          >
                            <div className="title-wrapper d-flex justify-content-between align-items-center">
                              <h6>Tasks / Deliverables</h6>
                              {isExpert(this.props.roleId) &&
                                projectUserStatus ===
                                  PROJECT_USER_STATUS_ACCEPTED &&
                                projectDetails.status ===
                                  PROJECT_STATUS_INPROCESS && (
                                  <>
                                    <a
                                      href="#"
                                      className={["d-inline-flex"].join(" ")}
                                      onClick={e => {
                                        e.preventDefault();
                                        this.props.context.toggleGeneralHoursModal();
                                      }}
                                    >
                                      Log general Time{" "}
                                      <span className="text-dark ml-2">
                                        <WatchIcon />
                                      </span>
                                    </a>

                                    {/*Log general hours modal*/}
                                    <LogGeneralHoursModal
                                      modal={generalHoursModal}
                                      setModal={
                                        this.props.context
                                          .toggleGeneralHoursModal
                                      }
                                      projectDetails={projectDetails}
                                      createProjectTimeEntry={
                                        this.props.context
                                          .createProjectTimeEntry
                                      }
                                    />
                                  </>
                                )}
                            </div>
                          </div>
                        )}
                      </KickoffContext.Consumer>
                    </KickoffProvider>
                  )}
                {/*Render Task and deliverables list*/}
                {(((isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) &&
                  !isCampaign) ||
                  isCustomer(this.props.roleId) ||
                  (isPartner(this.props.roleId) &&
                    projectUserStatus === PROJECT_USER_STATUS_ACCEPTED) ||
                  (isExpert(this.props.roleId) &&
                    approvedProjectExpertList &&
                    approvedProjectExpertList.find(
                      expert => expert.userId === projectDetails.currentUserId
                    ))) && (
                  <>
                    <TaskList
                      tasks={tasks || []} //[] is used to avoid undefined error
                      currentUserId={currentUserId}
                      context={this.props.context}
                      projectId={projectId}
                      expertId={
                        projectDetails &&
                        projectDetails.expertId &&
                        projectDetails.expertId
                      }
                      projectTeam={projectTeam}
                      currentTaskPartnerId={
                        this.props.context.currentTaskPartnerId
                      }
                      projectAssignee={[...projectAssignee, ...projectTeam]}
                      updateProjectTasks={this.updateProjectTasks}
                      deleteProjectTasks={this.deleteProjectTasks}
                      requestApproveDeliverable={this.requestApproveDeliverable}
                      getProjectApprovedExperts={e =>
                        this.props.context.getProjectApprovedExperts(e)
                      }
                      updateTaskStatusDeliverable={
                        this.updateTaskStatusDeliverable
                      }
                      newClient={!projectTeam.length}
                      addTask={() => alert()}
                      disabledTaskList={!this.props.context.kickoffHappened}
                      contextProvider={this.props.context}
                      status={status}
                      changeStatus={changeCampaignStatus}
                      setCurrentTaskPartnerId={setCurrentTaskPartnerId}
                      setAssignTaskPartnerId={setAssignTaskPartnerId}
                    />
                  </>
                )}
                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) &&
                  isCampaign && (
                    <CampaignPartnerTasks
                      projectPartners={projectPartners}
                      projectDetails={projectDetails}
                      context={this.props.context}
                      taskCompltedCount={taskCompltedCount}
                    >
                      <TaskList
                        tasks={tasks}
                        status={status}
                        currentUserId={currentUserId}
                        projectId={projectId}
                        expertId={
                          projectDetails &&
                          projectDetails.expertId &&
                          projectDetails.expertId
                        }
                        projectTeam={projectTeam}
                        projectAssignee={[...projectAssignee, ...projectTeam]}
                        updateProjectTasks={this.updateProjectTasks}
                        deleteProjectTasks={this.deleteProjectTasks}
                        requestApproveDeliverable={
                          this.requestApproveDeliverable
                        }
                        getProjectApprovedExperts={e =>
                          this.props.context.getProjectApprovedExperts(e)
                        }
                        getProjectTaskCompletedCount={e =>
                          this.getProjectTaskCompletedCount(e)
                        }
                        updateTaskStatusDeliverable={
                          this.updateTaskStatusDeliverable
                        }
                        currentTaskPartnerId={
                          this.props.context.currentTaskPartnerId
                        }
                        newClient={!projectTeam.length}
                        addTask={() => alert()}
                        disabledTaskList={!this.props.context.kickoffHappened}
                        contextProvider={this.props.context}
                        changeStatus={changeCampaignStatus}
                        setCurrentTaskPartnerId={setCurrentTaskPartnerId}
                        setAssignTaskPartnerId={setAssignTaskPartnerId}
                      />
                    </CampaignPartnerTasks>
                  )}
                {/*Show Project Detail Notes*/}
                {notes && (
                  <ProjectNotes
                    projectNotes={notes}
                    projectTeam={projectTeam}
                  />
                )}
                {/*Show User Conversation or Discussion*/}
                {((isCampaign && this.props.context.currentTaskPartnerId) ||
                  isPartner(this.props.roleId) ||
                  !isCampaign) && (
                  <Discussion
                    partnerId={this.props.context.currentTaskPartnerId || ""}
                    projectId={projectId}
                    projectTeam={[...projectAssignee, ...projectTeam]}
                  />
                )}
              </div>

              <ProjectDetailsSidebar
                handleReviewMatchExpert={this.expertApproval}
                projectId={projectId}
                expertId={kickoffExpertId}
                projectDetails={projectDetails}
                pendingProjectUsers={pendingProjectUsers}
                approveMatchedExpert={this.props.context.approveMatchedExpert}
                declineMatchedExpert={this.props.context.declineMatchedExpert}
                approvedProjectExpertList={approvedProjectExpertList}
                handleProjectDetails={e =>
                  this.props.context.getProjectDetails(e)
                }
                kickoffHappened={kickoffHappened}
                setKickoffHappened={this.props.context.setKickoffHappened}
                settings={settings}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const withProjectContext = Component =>
  React.forwardRef((props, ref) => (
    <ProjectProvider>
      <ProjectContext.Consumer>
        {context => <Component {...props} context={context} ref={ref} />}
      </ProjectContext.Consumer>
    </ProjectProvider>
  ));

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(withProjectContext(ProjectDetails));
