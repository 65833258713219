import React, { Component } from "react";
import { Link } from "react-router-dom";

import { CubeIcon, QuestionsIcon } from "../../assets/img/icons";
import AvatarCard from "../../components/base/AvatarCard";
import TrainingSupportMaterial from "./inner-component/TrainingSupportMaterial";
import SidebarItems from "./SidebarItems";

export default class SidebarItem extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      course,
      question,
      totalQuestionCount,
      media,
      user,
      answer,
      answerCount
    } = this.props;

    const data = [];
    return (
      <div>
        <SidebarItems>
          <div className="px-4 pt-4">
            <div className="field-wrapper">
              <div className="user-wrapper">
                <p className="font-weight-bold">Partner Training Course</p>
                <span className="text-grayed font-weight-bold">
                  {course && course.title}
                </span>
              </div>
            </div>
            <div className="field-wrapper">
              <div className="user-wrapper">
                <p className="font-weight-bold">Lesson Modules</p>
                <span className="text-grayed font-weight-bold">
                  {course && course.courseLesson && course.courseLesson.length}
                </span>
              </div>
            </div>
            <div className="field-wrapper">
              <div className="user-wrapper">
                <p className="font-weight-bold">Total Questions</p>
                <span className="text-grayed font-weight-bold">
                  {totalQuestionCount ? totalQuestionCount : "0"}
                </span>
              </div>
            </div>
          </div>
        </SidebarItems>
        <hr></hr>
        <SidebarItems>
          <div className="px-4 pt-4">
            <div className="field-wrapper">
              <div className="user-wrapper">
                <p className="font-weight-bold">Recent Activity</p>
              </div>
            </div>
            <div className="field-wrapper">
              <div className="user-wrapper">
                <div className="field-icon">
                  <div className="d-flex">
                    <AvatarCard firstName={user && user.company_name} />
                  </div>
                </div>

                <p className="font-weight-bold">
                  <Link></Link>
                </p>
              </div>
            </div>
            <div className="field-wrapper">
              <div className="d-flex align-items-center">
                <div className="field-icon pr-2 cubesize">
                  <CubeIcon />
                </div>
                <p className="font-weight-bold">
                  1 of{" "}
                  {course && course.courseLesson && course.courseLesson.length}
                  <span className="text-grayed font-weight-bold">Modules</span>
                </p>
              </div>
            </div>
            <div className="field-wrapper">
              <div className="d-flex align-items-center">
                <div className="field-icon pr-2">
                  <QuestionsIcon />
                </div>
                <p className="font-weight-bold">
                  {answerCount}
                  {" of "} {totalQuestionCount || "0"}
                  <span className="text-grayed font-weight-bold">
                    Questions
                  </span>
                </p>
              </div>
            </div>
          </div>
        </SidebarItems>
        <hr></hr>
        <SidebarItems>
          <div className="px-4 pt-4 pb-5">
            <div className="field-wrapper">
              <div className="user-wrapper">
                <p className="font-weight-bold pb-2">
                  Training Course Materials
                </p>
                <span className="text-grayed pb-2">
                  Any material that will help
                  <br />
                  you complete the course.
                </span>
                {media &&
                  media.length > 0 &&
                  media.map(mediaList => {
                    data.push(mediaList);
                  })}
                <div className="pt-1">
                  <TrainingSupportMaterial data={data} />
                </div>
              </div>
            </div>
          </div>
        </SidebarItems>
      </div>
    );
  }
}
