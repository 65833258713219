export const AVAILABLE_EXPERT = "Available";
export const NOT_AVAILABLE_EXPERT = "Not Available";
export const APPROVED_EXPERT = "Approved Expert (Any Availability)";
export const AVAILABILITY_EXPERT = "availability";

/**
 * Get Expert Availability Option
 */
export function getExpertAvailabilityOption() {
  return [
    {
      label: AVAILABILITY_EXPERT,
      value: AVAILABILITY_EXPERT,
      selected: false,
      placeholder: true
    },
    {
      label: AVAILABLE_EXPERT,
      value: AVAILABLE_EXPERT,
      selected: false
    },
    {
      label: APPROVED_EXPERT,
      value: APPROVED_EXPERT,
      selected: false
    },
    {
      label: NOT_AVAILABLE_EXPERT,
      value: NOT_AVAILABLE_EXPERT,
      selected: false
    }
  ];
}
