import React from "react";
import PropTypes from "prop-types";

import { Button } from "reactstrap";

class SaveButton extends React.Component {
  render() {
    const {
      onClick,
      loading,
      className,
      align,
      isClicked,
      id,
      type,
      label
    } = this.props;

    return (
      <Button
        id={id}
        disabled={loading}
        onClick={onClick}
        type={type ? type : "submit"}
        className={`m-1 float-${align} ${className}`}
        style={{
          borderRadius: "7px",
          minWidth: "90px",
          fontSize: ".875rem",
          lineHeight: "1.25rem",
          padding: ".5rem 1.25rem"
        }}
        disabled={isClicked}
      >
        {label ? (
          <span style={{ marginBottom: "5px" }}>{label}</span>
        ) : (
          <span style={{ marginBottom: "5px" }}>
            {loading ? "Saving..." : "Save"}
          </span>
        )}
      </Button>
    );
  }
}

SaveButton.propTypes = {
  type: PropTypes.string,
  onClick: PropTypes.func,
  loading: PropTypes.bool,
  className: PropTypes.string,
  style: PropTypes.object
};

export default SaveButton;
