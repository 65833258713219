import React from "react";
import "react-datepicker/dist/react-datepicker.css";

// Components
import Text from "../../../components/base/Text";

import URL from "../../../components/base/URL";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

class ReferralFormFields extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="details-body-main">
        <div className="card-body">
          <div className="col-lg-12">
            {/* Account Name */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text
                  id="accountName"
                  name="accountName"
                  label={Terminology.get(SystemName.REFERRAL_ACCOUNT_NAME)}
                  placeholder={`Select a ${Terminology.get(
                    SystemName.REFERRAL_ACCOUNT_NAME
                  )}`}
                  required={true}
                  error=""
                />
              </div>
            </div>

            {/* Contact Title */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <URL
                  id={"accountUrl"}
                  name={"accountUrl"}
                  label="Account URL"
                  placeholder={"Enter Account Url"}
                  required={true}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ReferralFormFields;
