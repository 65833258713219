import React, { Component } from "react";
import { Formik } from "formik";

// API Client
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Helper
import { isLoggedIn } from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import ToggleSwitch from "../../components/base/ToggleSwitch";
import Select from "../../components/base/Select";
import Spinner from "../../components/base/Spinner";
// import ProjectAvailbilityCard from "./ProjectAvailbilityCard";

// Constants
import { PROJECT_STATUS_INPROCESS } from "../../projectStatus/Constants";
import { AVAILABILITY } from "../../availabilityArray/Constants";
import { isBadRequest } from "../../common/http";

export default class Payment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      is_available: false,
      availability: [],
      isProjectLoading: false,
      projectDetails: [],
      selectedAvailabilityProjectId: 0
    };
    this.handleAvailability = this.handleAvailability.bind(this);
    this.setSelectedAvailabilityProjectId = this.setSelectedAvailabilityProjectId.bind(
      this
    );
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this.getExpertDetails();
    // this.getProjectActiveList(true);
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  // Handle highly requested areas
  handleAvailability(e) {
    this.setState(
      {
        is_available: !this.state.is_available
      },
      () => {
        // Update expert availability
        const { availability } = this.state;
        const availabilityValue = availability[0].label
          ? availability[0].value
          : "";
        if (availabilityValue) {
          this.updateExpertAvailabilityState({
            is_available: this.state.is_available,
            availability: availabilityValue
          });
        }
      }
    );
  }

  // Get active project list
  getProjectActiveList = isProjectLoading => {
    this.setState({ isProjectLoading: isProjectLoading }, () => {
      apiClient
        .get(`${endpoints().projectAPI}?status=${PROJECT_STATUS_INPROCESS}`)
        .then(response => {
          const data = response.data;
          this.setState({
            isProjectLoading: !isProjectLoading,
            projectDetails: !data ? [] : data.data
          });
        });
    });
  };

  // Expert details api call by email
  getExpertDetails = () => {
    return apiClient
      .get(`${endpoints().expertAPI}/`)
      .then(response => {
        this.setState({
          is_available: response.data.is_available,
          availability: [
            {
              label: response.data.availability,
              value: response.data.availability
            }
          ],
          loading: true
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          console.error(errorMessage);
        }
      });
  };

  // Set selected project id
  setSelectedAvailabilityProjectId(projectId) {
    this.setState({ selectedAvailabilityProjectId: projectId });
  }

  onSubmit(values) {
    const availability = [{ label: values.label, value: values.label }];
    const is_available =
      values.label === AVAILABILITY[4].value ? false : this.state.is_available;

    this.setState({ availability, is_available }, () =>
      this.updateExpertAvailabilityState(this._toArray(values))
    );
  }

  // To Array
  _toArray(values) {
    values.availability = values.label ? values.value : values.label;
    values.is_available = this.state.is_available;
    return values;
  }

  // Update Expert Details
  updateExpertAvailabilityState(details) {
    const data = new FormData();

    const is_available =
      details.availability === AVAILABILITY[4].value
        ? false
        : details.is_available;

    data.append("availability", details.availability);
    data.append("is_available", is_available);

    return apiClient
      .put(`${endpoints().expertAvailabilityAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          console.log(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  render() {
    const { availability, loading } = this.state;
    // Availability hours

    // To show spinner while fetching expert availability
    if (!loading) {
      return <Spinner />;
    }

    return (
      <>
        <PageTitle label="Availability" />
        {/* {projectDetails.length > 0 &&
          projectDetails.map(projectDetail => (
            <ProjectAvailbilityCard
              projectDetail={projectDetail}
              selectedAvailabilityProjectId={selectedAvailabilityProjectId}
              setSelectedAvailabilityProjectId={
                this.setSelectedAvailabilityProjectId
              }
            />
          ))} */}

        <div className="tabs-and-preview">
          <div className="tab-content-wrapper">
            <Formik
              initialValues={""}
              onSubmit={values => this.onSubmit(values)}
            >
              <>
                <div className="field-wrapper mb-4-5">
                  <div className="form-group">
                    <ToggleSwitch
                      title="Are you Available for Work?"
                      name="availability"
                      label1="Not available"
                      label2="Yes, I'm Available"
                      value={this.state.is_available}
                      handleChange={this.handleAvailability}
                      className={["flex-column", "align-items-start"].join(" ")}
                    />
                  </div>
                </div>
                <div className="field-wrapper w-360">
                  <Select
                    name="availability"
                    label="Available Hours"
                    placeholder="Select one"
                    options={AVAILABILITY}
                    {...(availability &&
                      availability[0] &&
                      availability[0].label && {
                        defaultValue: this.state.availability
                      })}
                    isClearable={false}
                    isDisabled={!this.state.is_available}
                    handleChange={this.onSubmit.bind(this)}
                    fontBolded={true}
                    isSearchable={true}
                    required
                  />
                </div>
              </>
            </Formik>
          </div>
        </div>
      </>
    );
  }
}
