import React from "react";
import { InputGroup, InputGroupAddon, InputGroupText, Input } from "reactstrap";
import { SearchIcon } from "../../assets/img/icons";

const PageSearch = props => {
  const { classnames, placeholder, onChange, value, width, id } = props;

  return (
    <>
      <InputGroup
        className={`${classnames} align-items-stretch mr-4 `}
        style={{ width: width ? width : "100%" }}
      >
        <p className="font-weight-bold my-auto ml-0 mr-2 d-block d-md-none ml-0">
          Search:{" "}
        </p>
        <InputGroupAddon id={id} addonType="prepend">
          <InputGroupText>
            <SearchIcon id={id} />
          </InputGroupText>
        </InputGroupAddon>
        <Input
          id={id}
          placeholder={placeholder}
          onChange={onChange}
          // value prop: can be used if want a full control over input field value
          {...((value || value === "") && { value })}
        />
      </InputGroup>
    </>
  );
};

export default PageSearch;
