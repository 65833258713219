export const YESTERDAY = "YESTERDAY";
export const YESTERDAY_TEXT = "Yesterday";
export const TODAY = "TODAY";
export const TODAY_TEXT = "Today";
export const TOMORROW = "TOMORROW";
export const TOMORROW_TEXT = "Tomorrow";

export const LAST_WEEK = "LAST_WEEK";
export const LAST_WEEK_TEXT = "Last Week";
export const THIS_WEEK = "THIS_WEEK";
export const THIS_WEEK_TEXT = "This Week";
export const NEXT_WEEK = "NEXT_WEEK";
export const NEXT_WEEK_TEXT = "Next Week";

export const LAST_MONTH = "LAST_MONTH";
export const LAST_MONTH_TEXT = "Last Month";
export const THIS_MONTH = "THIS_MONTH";
export const THIS_MONTH_TEXT = "This Month";
export const NEXT_MONTH = "NEXT_MONTH";
export const NEXT_MONTH_TEXT = "Next Month";

export const LAST_YEAR = "LAST_YEAR";
export const LAST_YEAR_TEXT = "Last Year";
export const THIS_YEAR = "THIS_YEAR";
export const THIS_YEAR_TEXT = "This Year";
export const NEXT_YEAR = "NEXT_YEAR";
export const NEXT_YEAR_TEXT = "Next Year";

export const SALESFORCE_OBJECT_ACCOUNT = "Account";
export const SALESFORCE_OBJECT_OPPORTUNITY = "Opportunity";
