import React from "react";
import { Link } from "react-router-dom";
import { DropdownItem } from "reactstrap";

import SidebarItem from "./SidebarItem";
import Avatar from "../../../../components/base/Avatar";
import {
  InlineInfoIcon,
  GlobeIcon,
  DollarIcon,
  WatchIcon
} from "../../../../assets/img/icons";
import SourceMoreExperts from "../SourceMoreExperts";
import LazyLoad from "react-lazyload";

import { isCustomer, shortTimeZone } from "../../../../lib/helper";
import { string } from "prop-types";
import SampleProjectTooltip from "../SampleProjectTooltip";

const SidebarApprovedExpertList = React.memo(
  function SidebarApprovedExpertList({
    approvedProjectExpertList,
    projectWaitingForAcceptanceExpertList
  }) {
    return (
      <SidebarItem>
        <p className="font-weight-bold">Expert</p>{" "}
        {/*had to move this here because of design and global css restrictions*/}
        {approvedProjectExpertList.length > 0
          ? approvedProjectExpertList.map((projectUser, index) => {
              const {
                id,
                firstName,
                lastName,
                avatarUrl,
                name,
                userId,
                currentUserId
              } = projectUser;

              return (
                <React.Fragment key={index}>
                  <div className="field-wrapper">
                    <div className="user-wrapper">
                      <div className="field-icon">
                        <Link target="_blank" to={`/expert-profile/${id}`}>
                          <LazyLoad>
                            <Avatar
                              firstName={firstName}
                              lastName={lastName}
                              size="xs"
                              fontSize={12}
                              url={avatarUrl}
                            />
                          </LazyLoad>
                        </Link>
                      </div>

                      <p className="font-weight-bold">
                        <Link target="_blank" to={`/expert-profile/${id}`}>
                          {userId === currentUserId ? "You" : name}
                        </Link>
                      </p>
                    </div>
                  </div>

                  {projectUser && projectUser.currentMonthlyHoursSpent ? (
                    <div className="field-wrapper">
                      <div className="d-flex align-items-center">
                        <div className="field-icon">
                          <WatchIcon />
                        </div>
                        <p className="font-weight-bold">
                          {projectUser.currentMonthlyHoursSpent
                            ? projectUser.currentMonthlyHoursSpent
                            : ""}
                          <span className="text-grayed font-weight-bold">
                            Total Hours
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="field-wrapper">
                      <div className="d-flex align-items-center">
                        <div className="field-icon">
                          <WatchIcon />
                        </div>
                        <p className="font-weight-bold">
                          {projectUser.currentMonthlyHoursSpent}
                          <span className="text-grayed font-weight-bold">
                            Total Hours
                          </span>
                        </p>
                      </div>
                    </div>
                  )}

                  {projectUser && projectUser.hourlyRate ? (
                    <div className="field-wrapper">
                      <div className="d-flex align-items-center">
                        <div className="field-icon">
                          <DollarIcon />
                        </div>
                        <p className="font-weight-bold">
                          ${projectUser.hourlyRate}
                          <span className="text-grayed font-weight-bold">
                            per hour
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="field-wrapper">
                      <div className="d-flex align-items-center">
                        <div className="field-icon">
                          <DollarIcon />
                        </div>
                        <p className="font-weight-bold">
                          $0
                          <span className="text-grayed font-weight-bold">
                            per hour
                          </span>
                        </p>
                      </div>
                    </div>
                  )}

                  {projectUser && projectUser.timezone ? (
                    <div className="field-wrapper">
                      <div className="d-flex align-items-center">
                        <div className="field-icon">
                          <GlobeIcon />
                        </div>
                        <p className="font-weight-bold d-flex justify-content-between">
                          <span className="font-weight-bold my-auto">
                            {shortTimeZone(projectUser.timezone)}
                          </span>
                          <span>
                            <div
                              className="expert-timezone"
                              id="expert-timezone"
                            >
                              <InlineInfoIcon />
                              <SampleProjectTooltip
                                tooltipId={"expert-timezone"}
                                placement={"top"}
                              >
                                <span>{projectUser.timezone}</span>
                              </SampleProjectTooltip>
                            </div>
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    ""
                  )}
                </React.Fragment>
              );
            })
          : "No Expert Added"}
      </SidebarItem>
    );
  }
);

export default SidebarApprovedExpertList;
