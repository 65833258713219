import _ from "lodash";

// Constants
import {
  REQUEST_RESOURCE_LIST,
  RECEIVE_RESOURCE_LIST,
  RESET_RESOURCE_LIST,
  FETCH_RESOURCE_LIST_FAIL,
  CHANGE_RESOURCE_PAGE
} from "../actionType/Constants";

export function resourceReducer(
  state = {
    isFetching: false
  },
  action
) {
  switch (action.type) {
    // Request Resourcelist
    case REQUEST_RESOURCE_LIST: {
      return Object.assign({}, state, {
        isFetching: true
      });
    }

    // Receive Resourcelist
    case RECEIVE_RESOURCE_LIST: {
      return Object.assign({}, state, {
        pages: Object.assign({}, state.pages, {
          [action.payload.currentPage]: action.payload.data.map(n => n.id)
        }),
        statuses: Object.assign({}, state.statuses, {
          [action.payload.currentPage]: action.payload.status
        }),
        pagination: {
          currentPage: action.payload.currentPage,
          totalCount: action.payload.totalCount,
          publishedResourceCount: action.payload.publishedResourceCount,
          draftedResourceCount: action.payload.draftedResourceCount
        },
        isFetching: false,
        data: { ..._.keyBy(action.payload.data, n => n.id) }
      });
    }

    case RESET_RESOURCE_LIST: {
      state = undefined;
    }

    // Fetch Resourcelist fail
    case FETCH_RESOURCE_LIST_FAIL:
      return { ...state, isFetching: false };

    // Change ResourcePage
    case CHANGE_RESOURCE_PAGE: {
      return Object.assign({}, state, {
        pagination: Object.assign({}, state.pagination, {
          currentPage: action.payload
        })
      });
    }

    default:
      return state;
  }
}
