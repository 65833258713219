import React from "react";

// Components
import Form from "../../../../../components/base/Form";
import DefaultContent from "../../../../../components/content/DefaultContent";
import Text from "../../../../../components/base/Text";
import SaveButton from "../../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_OKTA_CLIENT_ID,
  SETTINGS_OKTA_ISSUER_URL
} from "../../../../../setting/Constants";

const OktaSection = props => {
  const { adminSettings, saveSettings } = props;

  const { okta_issuer_url, okta_client_id } = adminSettings;

  // Slack Section Initial Values
  const oktaInitialValues = {
    okta_issuer_url,
    okta_client_id
  };

  // Okta Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Okta Issuer Url
    if (values.okta_issuer_url !== undefined) {
      data.append(
        "okta_issuer_url",
        values.okta_issuer_url ? values.okta_issuer_url : ""
      );
    }

    // Okta Client Id
    if (values.okta_client_id !== undefined) {
      data.append(
        "okta_client_id",
        values.okta_client_id ? values.okta_client_id : ""
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={oktaInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          <h5 className="font-weight-bold">Issuer</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_OKTA_ISSUER_URL}
              placeholder="Enter Issuer Url"
            />
          </div>
          <h5 className="font-weight-bold">Client Id</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_OKTA_CLIENT_ID}
              placeholder="Enter Client ID"
            />
          </div>
          <div className="btn-wrapper">
            <SaveButton />
          </div>
        </div>
      </DefaultContent>
    </Form>
  );
};

export default OktaSection;
