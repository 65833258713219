import React from "react";

import { apiClient } from "../../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../../configs";

// Helper
import { getCookie } from "../../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../../lib/cookie";

// Components
import Select from "./Select";
import { isBadRequest } from "../../common/http";

class CrossbeamPartner extends React.Component {
  state = {
    crossbeamPartnerList: []
  };

  // Get partner type
  getCrossbeamPartner = async () => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    try {
      const response = await apiClient.get(
        `${endpoints().crossbeamPartnerAPI}/search`
      );
      const results = response && response.data && response.data.data;

      if (results && results.length > 0) {
        const crossbeamPartnerList = [];
        results.forEach(crossbeamPartner => {
          crossbeamPartnerList.push({
            id: crossbeamPartner.id,
            name: crossbeamPartner.name
          });
        });
        this.setState({ crossbeamPartnerList });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  componentDidMount() {
    this.getCrossbeamPartner();
  }

  //  Get options
  getCrossbeamPartnerOptions = () => {
    const { crossbeamPartnerList } = this.state;
    let crossbeamPartnerOptions = [];

    if (!crossbeamPartnerList) return crossbeamPartnerList;

    let orderList = crossbeamPartnerList;
    orderList.sort((a, b) =>
      (a["name"] || "").toString().localeCompare((b["name"] || "").toString())
    );
    crossbeamPartnerList.forEach(crossbeamPartner => {
      crossbeamPartnerOptions.push({
        value: crossbeamPartner.id,
        label: crossbeamPartner.name
      });
    });

    return crossbeamPartnerOptions;
  };

  render() {
    const {
      name,
      label,
      placeholder,
      error,
      required,
      isSearchable,
      fullWidth,
      isDisabled,
      id
    } = this.props;

    return (
      <Select
        id={id || name}
        name={name}
        label={label}
        placeholder={placeholder}
        options={this.getCrossbeamPartnerOptions()}
        fullWidth={fullWidth}
        isSearchable={isSearchable}
        error={error}
        {...{ required }}
        isDisabled={isDisabled}
      />
    );
  }
}

export default CrossbeamPartner;
