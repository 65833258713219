import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Assets
import Slider from "react-slick";
import "./imageCarasoul.scss";

const ImageCarousel = props => {
  const {
    photos,
    handlePhotoDelete,
    showRemove,
    style,
    size = "sm",
    slides,
    customImageSize,
    appStyle
  } = props;

  const imageRemoveHandler = photo => {
    handlePhotoDelete(photo);
  };

  const predefinedSize = {
    sm: "100px",
    md: "200px",
    lg: "300px"
  };
  const defaultStyle = {
    width: "100%",
    height: "140px",
    objectFit: "scale-down"
  };

  // const publicProfileStyle = {
  //   width: customImageSize ? customImageSize : predefinedSize[size]
  // };
  const appRemoveStyle = {
    bottom: "145px",
    left: "341px"
  };
  const removeStyle = {
    bottom: "145px",
    left: "128px"
  };
  return (
    <>
      <Slider
        className="photo-gallery-corousel cursor-pointer "
        slidesToShow={slides}
        slidesToScroll={1}
        swiping={true}
        transition={0.5}
        arrows={true}
        infinite={false}
        responsive={[
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: false
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: false
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: false
            }
          }
        ]}
      >
        {photos.map(photo => (
          <div
            className="imagecarousel-photos"
            style={{ position: "relative", width: "fit-content" }}
          >
            <div
              style={{
                margin: "5px"
              }}
            >
              <img
                src={`${photo.imageUrl}`}
                style={style ? style : defaultStyle}
                className="my-2 img-fluid img-thumbnail"
                alt="thumbnail"
              ></img>
              {showRemove ? (
                <span
                  onClick={imageRemoveHandler.bind(this, photo)}
                  className={`far fa-lg fa-times-circle remove-icon position-relative`}
                  style={appStyle ? appRemoveStyle : removeStyle}
                />
              ) : (
                ""
              )}
              {photo.title && photo.description && (
                <div className="card" style={{ height: "6rem" }}>
                  <span className="mx-2">{photo.title}</span>
                  <br />
                  <span
                    className="mx-2 text-truncate"
                    style={{
                      display: "-webkit-box",
                      WebkitLineClamp: 2,
                      WebkitBoxOrient: "vertical",
                      overflow: "hidden",
                      whiteSpace: "break-spaces"
                    }}
                  >
                    {photo.description}
                  </span>
                </div>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default ImageCarousel;
