import React from "react";
import { Link } from "react-router-dom";

class ListViewCard extends React.Component {
  render() {
    const { label, link, children, id } = this.props;

    return (
      <div className="card">
        {/* Card label */}
        <div className="p-3 pt-4">
          <div className="d-flex align-items-center justify-content-between">
            <h6 className="font-weight-bold">{label}</h6>
            {link && (
              <Link id={id} to={link}>
                <h6 className={"font-weight-bold"} style={{ fontSize: "13px" }}>
                  View All
                </h6>
              </Link>
            )}
          </div>
        </div>
        {children}
      </div>
    );
  }
}

export default ListViewCard;
