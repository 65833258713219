import React from "react";
import Page404 from "../Page404";
import toast from "../../components/base/Toast";
import AccountFormFields from "./inner-component/AccountFormFields";
import { Link } from "react-router-dom";

// Components
import Form from "../../components/base/Form";
import DeleteButton from "../../components/base/DeleteButton";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import PageTitle from "../../components/base/PageTitle";
import DeleteModal from "../../components/base/DeleteModal";
import SelectDropdown from "../../components/base/SelectDropdown";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient, apiClientGet } from "../../apiClient";

// Helper
import {
  removeMaskedPhoneNumber,
  isSuperAdmin,
  isCompanyAdmin
} from "../../lib/helper";
import { isBadRequest } from "../../common/http";
import AccountBanner from "./inner-component/AccountBanner";
import Button from "../../components/base/Button";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import AccountOwner from "./account/AccountOwner";
import { ChevronRight } from "../../assets/img/icons";
import AccountPartner from "./inner-component/Partner";
import AccountContact from "./account/AccountContact";

import Spinner from "../../components/base/Spinner";

import AccountDetail from "./account/AccountDetail";

import { connect } from "react-redux";

class UpdateUserForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      isOpen: false,
      accountDetails: [],
      partnerDetails: null,
      loading: false,
      isInvalidRequest: false,
      openDeleteModal: false,
      accountOwner: "",
      contactList: "",
      crossbeamPartner: "",
      crossBeamAccountLoad: false
    };
  }

  typeOptions = [
    {
      value: "Partner",
      label: "Partner"
    },
    {
      value: "Prospect",
      label: "Prospect"
    }
  ];

  componentDidMount = () => {
    this._getAccountDetails();
    this._getPartnerDetails();
  };

  // Account update API Call
  _updateAccount(data) {
    return apiClient
      .put(`${endpoints().accountAPI}/${this.props.match.params.id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._getAccountDetails();
        this._toggle();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Account delete api call
  handleDelete = id => {
    return apiClient
      .delete(`${endpoints().accountAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;

          toast.success(successMessage);

          this.props.history.push("/accounts");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Account details api call by id
  _getAccountDetails = () => {
    apiClientGet(
      `${endpoints().accountAPI}/${this.props.match.params.id}`,
      (errMessage, response) => {
        if (errMessage) {
          this.setState({ isInvalidRequest: true });
        } else {
          const accountDetails = response && response.data;
          this.setState({
            accountDetails,
            loading: true
          });
        }
      }
    );
  };

  _getPartnerDetails = () => {
    this.setState({ crossBeamAccountLoad: true });
    apiClientGet(
      `${endpoints().accountAPI}/partner/${this.props.match.params.id}`,
      (errMessage, response) => {
        if (response !== undefined) {
          const partnerDetails = response && response.data;
          this.setState({
            accountOwner:
              response.data && response.data.account_owner
                ? response.data.account_owner
                : null,
            contactList:
              response.data && response.data.contact_list
                ? response.data.contact_list
                : null,
            crossbeamPartner:
              response.data && response.data.crossbeam_partner
                ? response.data.crossbeam_partner
                : null,
            loading: true,
            crossBeamAccountLoad: false
          });
        } else {
          this.setState({ crossBeamAccountLoad: false });
        }
      }
    );
  };

  handleAdminAction = value => {
    switch (value) {
      case "Create In Salesforce":
        return this.handleSyncToSalesforce();
      case "Edit":
        return this._toggle(this.state.accountDetails.id);
      case "Delete":
        return this.setState({ openDeleteModal: true });
    }
  };

  handleSyncToSalesforce = () => {
    this.setState({ isAccountSyncing: true }, () => {
      return apiClient
        .put(
          `${endpoints().syncAPI}/salesforce/account/${
            this.props.match.params.id
          }`
        )
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }

          this.setState({ isAccountSyncing: false });

          toast.success(successMessage);
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    });
  };

  _toggle = tagId => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return "";
    }

    const {
      companyName,
      firstName,
      lastName,
      email,
      phone,
      title,
      type,
      website
    } = this.state.accountDetails;

    const { openDeleteModal } = this.state;
    const initialValues = {
      companyName,
      firstName,
      lastName,
      email,
      phone,
      title,
      website,
      type: type ? this.typeOptions.find(data => data.value == type) : ""
    };
    const adminActionOptions =
      !this.state.accountDetails.salesforceAccountId &&
      (isSuperAdmin(this.props.roleId) || isCompanyAdmin(this.props.roleId))
        ? [
            { value: "Create In Salesforce", label: "Create In Salesforce" },
            {
              value: "Edit",
              label: "Edit"
            },
            {
              value: "Delete",
              label: "Delete"
            }
          ]
        : [
            {
              value: "Edit",
              label: "Edit"
            },
            {
              value: "Delete",
              label: "Delete"
            }
          ];

    return (
      <>
        <Modal
          isOpen={this.state.isOpen}
          toggle={this._toggle}
          className="landing-create-popup w-100"
          backdrop="static"
        >
          <ModalHeader
            toggle={this._toggle}
            cssModule={{ "modal-title": "w-100 text-center" }}
          >
            <p>Edit Account Details</p>
          </ModalHeader>
          <Form
            initialValues={initialValues}
            onSubmit={values => {
              values.phone =
                values.phone && removeMaskedPhoneNumber(values.phone);
              if (values.companyName) {
                values.companyName = values.companyName.trim();
              }
              if (values.firstName) {
                values.firstName = values.firstName.trim();
              }
              if (values.lastName) {
                values.lastName = values.lastName.trim();
              }
              if (values.type) {
                values.type = values && values.type && values.type.value;
              }
              this._updateAccount(values);
            }}
          >
            <ModalBody className="ml-3 mr-3 mt-2 mb-3">
              <div>
                <div className="field-wrapper w-100">
                  <AccountFormFields typeOptions={this.typeOptions} />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="container-fluid">
                <div className="col text-center">
                  <SaveButton />
                </div>
              </div>
            </ModalFooter>
          </Form>
        </Modal>

        <DeleteModal
          isOpen={openDeleteModal}
          title="Delete Account "
          toggle={() => {
            this.setState({ openDeleteModal: false });
          }}
          label={companyName}
          deleteFunction={() => this.handleDelete(this.props.match.params.id)}
        />
        <div className="d-flex align-items-center pt-4 pb-4">
          <span
            className="cursor-pointer"
            onClick={() => {
              window.location.replace(`/accounts`);
            }}
          >
            Accounts
          </span>
          <span className="d-inline-flex" style={{ width: "15px" }}>
            <ChevronRight />
          </span>
          <span className="text-dark font-weight-bold">
            <span>{this.state.accountDetails.companyName}</span>
          </span>
        </div>

        <div className="d-flex justify-content-between align-items-center mb-4">
          <PageTitle label="Account Details" />
          {(isSuperAdmin(this.props.roleId) ||
            isCompanyAdmin(this.props.roleId)) && (
            <SelectDropdown
              buttonLabel="Admin Actions"
              dropdownLinks={adminActionOptions}
              color={"gray"}
              hideCaret
              selectName={"admin_action"}
              handleChange={this.handleAdminAction}
            />
          )}
        </div>
        <AccountBanner accountDetails={this.state.accountDetails} />

        {this.state.crossBeamAccountLoad && <Spinner />}

        <div className="card border-0">
          <div className="project-details-body d-block">
            <div className="row">
              <div className="col-sm-7 border-right">
                {this.state.accountOwner && (
                  <AccountOwner accountOwner={this.state.accountOwner} />
                )}
                {this.state.contactList &&
                  this.state.contactList.length > 0 && (
                    <AccountContact contactList={this.state.contactList} />
                  )}
              </div>
              {this.state.crossbeamPartner &&
                this.state.crossbeamPartner.length > 0 && (
                  <div className="col-sm-5">
                    <AccountPartner partnerList={this.state.crossbeamPartner} />
                  </div>
                )}
            </div>
            {!this.state.accountOwner &&
              this.state.crossbeamPartner &&
              this.state.crossbeamPartner.length == 0 &&
              this.state.contactList &&
              this.state.contactList.length == 0 &&
              this.state.accountDetails && (
                <AccountDetail accountDetails={this.state.accountDetails} />
              )}
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { roleId } = state.user;

  return {
    roleId
  };
};

export default connect(() => {
  return mapStateToProps;
})(UpdateUserForm);
