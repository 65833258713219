/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import Form from "../../../../components/base/Form";
import Hint from "../../../../components/base/Hint";

// Helper
import { updateLandingPageBlock } from "../../../../actions/landingPageBlock";
// Assets
import createLandingIcon from "../../../../assets/pages/landing-page/create-landing-page.png";

// Action
import SaveButton from "../../../../components/base/SaveButton";
import SingleCheckbox from "../../inner-component/Checkbox";
import {
  SHOW_FILTER,
  SHOW_IN_SINGLE_LINE
} from "../../../../setting/Constants";
import Text from "../../../../components/base/Text";
import { toString } from "../../../../lib/helper";

class EditFeaturedCollection extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: ""
    };
  }

  _toArray(values) {
    const data = new FormData();
    data.append("title", values.title ? values.title : "");
    data.append("show_filter", values.show_filter);
    data.append("show_in_single_line", values.show_in_single_line);
    data.append("filterTagTypes", []);
    return data;
  }

  landingPageBlockUpdate(data) {
    try {
      this.props.actions.updateLandingPageBlock(
        this.props.detail.id,
        data,
        res => {
          if (res) {
            this.props.toggle();
            this.props.getLandingBlocksDetails();
          }
        }
      );
    } catch (err) {
      console.log(err);
    }
  }
  _submit = values => {
    // Save landing page block form
    this.landingPageBlockUpdate(this._toArray(values));
  };

  render() {
    const { isOpen, toggle, detail } = this.props;

    let initialValues = {
      title: detail.title,
      show_filter: detail && detail.show_filter == "true" ? true : false,
      show_in_single_line:
        detail && detail.show_in_single_line == "true" ? true : false
    };

    return (
      <div className="">
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          className="custom-modal-popup w-100"
          backdropClassName="custom-backdrop"
          backdrop="static"
        >
          <ModalHeader toggle={toggle}>
            <div className="content-wrapper">
              <div className="icon-wrapper">
                <div className="row justify-content-center mb-2 mt-3">
                  <img src={createLandingIcon} size="30" alt="" />
                </div>
                <p className="text-center mb-3">Edit Collection</p>
                <p
                  className="text-center"
                  style={{ fontSize: "14px", lineHeight: "20px" }}
                ></p>
              </div>
            </div>
          </ModalHeader>

          <Form
            initialValues={initialValues}
            enableReinitialize
            onSubmit={values => {
              values.title = toString(values.title);

              this._submit(values, toggle);
            }}
          >
            <ModalBody>
              <div className="mt-2 mb-3">
                <div className="mb-2">
                  <Text
                    name="title"
                    label="Title"
                    placeholder="Title..."
                    error=""
                    required
                  />
                  <Hint
                    id="title"
                    hintText="This will be the heading for your landing page"
                  />
                </div>
              </div>
              <div className="form-wrapper justify-content-start d-flex">
                <div className="field-wrapper">
                  <SingleCheckbox
                    name={SHOW_IN_SINGLE_LINE}
                    label="Show In Single Line"
                    className="accepted-terms  pb-0 mr-3"
                  />
                </div>
                <div className="field-wrapper">
                  <SingleCheckbox
                    name={SHOW_FILTER}
                    label="Show Filter"
                    className="accepted-terms  pb-0 mr-3"
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="container-fluid">
                <div className="col-sm-12 text-center">
                  <SaveButton type="submit" label="Save" />
                </div>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updateLandingPageBlock }, dispatch)
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(EditFeaturedCollection);
