import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import UserSelector from "../../HubSpotUserSelector";
import CancelButton from "../../../../components/base/CancelButton";
import Form from "../../../../components/base/Form";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

// Helper

const OwnerSelectionModal = ({
  userList,
  referralDetails,
  toggle,
  isOpen,
  updateOwner
}) => {
  let ownerHubSpotId = referralDetails && referralDetails.owner_hubspot_id;
  // useState
  const [currentOwnerUserId, setCurrentOwnerUserId] = useState("");
  const [isselectedOwner, setIsselectedOwner] = useState(true);

  const ownerDetail = userId => {
    let selectedOwner;
    // Get Selected Partner Manager

    selectedOwner = userList && userList.find(x => x.hubspot_id === userId);
    setIsselectedOwner(selectedOwner ? false : true);
  };

  // handele selected manager
  const handleUpdateOwner = userId => {
    setCurrentOwnerUserId(userId);
    ownerDetail(userId);
  };

  // handele selected manager
  const updateOwnerData = () => {
    updateOwner(currentOwnerUserId, setIsselectedOwner, false);
    toggle();
  };

  const handleToggle = () => {
    setIsselectedOwner(true);
    toggle();
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={handleToggle}
        className={["edit-task-modal"].join(" ")}
      >
        <Form onSubmit={values => {}}>
          <ModalHeader toggle={handleToggle}>
            <h4 className={["font-weight-bold"].join(" ")}>
              {ownerHubSpotId ? "Change" : "Select"} Owner
            </h4>
          </ModalHeader>

          <ModalBody className={["mb-4", "ml-5"].join(" ")}>
            <div className="form-wrapper justify-content-center d-flex">
              <div className="col-10 field-wrapper">
                <UserSelector
                  placeholderText={`Search ${Terminology.get(
                    SystemName.REFERRAL
                  )} Owner`}
                  userList={userList}
                  updateHandle={handleUpdateOwner}
                />
              </div>
            </div>
          </ModalBody>

          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <CancelButton onClick={handleToggle} />
              <button
                disabled={isselectedOwner}
                className="btn btn-secondary"
                onClick={updateOwnerData}
              >
                Save
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default OwnerSelectionModal;
