import React, { useEffect, useState } from "react";
import _ from "lodash";
import { TrashIcon } from "../../assets/img/icons";
import {
  TASK_TYPE_DROP_DOWN_FIELD,
  TASK_TYPE_DROP_DOWN_MULTI_SELECT,
  TASK_TYPE_FILE_UPLOAD,
  TASK_TYPE_TEXT_FIELD,
  TASK_VISIBILITY_ADMIN,
  TASK_VISIBILITY_PARTNER,
  TASK_VISIBILITY_PUBLIC
} from "../../modules/task/Constant";
import {
  isCompanyAdmin,
  isCompanyManager,
  isSuperAdmin
} from "../../lib/helper";
import DefaultContent from "../content/DefaultContent";
import Form from "../../components/FormWithErrorHandling";
import Text from "../base/Text";
import DragAndDropField from "../base/DragAndDropField";
import MultiSelect from "../base/Multiselect";
import Select from "../base/Select";
import MultiselectCreatable from "../base/MultiselectCreatable";
import { TAG_PERMISSION_SELECT_AND_ADD_VALUE } from "../../tag/Constants";

import { useSelector } from "react-redux";

const PartnerCustomField = ({
  customfField,
  uploadTaskFile,
  deleteUploadedTaskFile,
  updateCustomField,
  updateCustomTags
}) => {
  let {
    name,
    fieldName,
    resourceFile,
    uploadedFile,
    type,
    visibility,
    fieldValue,
    tagList,
    selectType,
    selectedTagId,
    tagTypeId,
    selectedTagList,
    tagTypePermission,
    id
  } = customfField;
  const [isUploading, setIsUploading] = useState("");
  const [uploadFile, setUploadFile] = useState("");
  const [uploadFileName, setUploadFileName] = useState("");

  const currentUser = useSelector(state => state.user);

  const handleUploadFile = files => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.name) {
        setUploadFileName(file.name);

        setUploadFile(file);
        setIsUploading(true);
      }
    }
  };

  const setCustomFieldValue = customFieldValue => {
    const data = new FormData();
    data.append("fieldLabel", name);
    data.append("fieldName", fieldName);
    data.append("fieldValue", customFieldValue ? customFieldValue : "");

    updateCustomField(data);
  };

  const handleTextFieldChange = event => {
    event.persist();
    doSearch(event);
  };

  const doSearch = _.debounce(event => {
    setCustomFieldValue(event.target.value);
  }, 500);

  const multiSelectHandleChange = values => {
    const data = new FormData();

    let customFieldValues = values && values[fieldName];

    data.append("fieldLabel", name);
    data.append("tagTypeId", tagTypeId);
    data.append(
      "customFieldValues",
      customFieldValues && customFieldValues.length > 0
        ? JSON.stringify(customFieldValues)
        : JSON.stringify([])
    );

    updateCustomTags(data);
  };

  const singleSelectHandleChange = selectedValueObject => {
    const data = new FormData();

    let customFieldValues = [];
    if (selectedValueObject && selectedValueObject.value)
      customFieldValues.push(selectedValueObject);

    data.append("fieldLabel", name);
    data.append("tagTypeId", tagTypeId);
    data.append(
      "customFieldValues",
      customFieldValues && customFieldValues.length > 0
        ? JSON.stringify(customFieldValues)
        : JSON.stringify([])
    );

    updateCustomTags(data);
  };

  useEffect(() => {
    if (isUploading) {
      const data = new FormData();
      data.append("fieldLabel", name);
      data.append("fieldName", fieldName);
      data.append("uploadFile", uploadFile ? uploadFile : "");
      data.append("uploadFileName", uploadFileName ? uploadFileName : "");

      uploadTaskFile(data);

      setIsUploading(false);
    }
  }, [isUploading]);

  function preventDefaultSubmit(e) {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }

  return type === TASK_TYPE_FILE_UPLOAD ? (
    <>
      <DefaultContent title={name}>
        <div>
          <a href={resourceFile.fileUrl} download={resourceFile.name}>
            {resourceFile.name}
          </a>
        </div>
        {uploadedFile && uploadedFile.fileUrl ? (
          <div className={"mt-3"}>
            Uploaded:
            <div className={"mt-1 d-flex justify-content-between"}>
              <div>
                <a href={uploadedFile.fileUrl} download={uploadedFile.name}>
                  {" "}
                  {uploadedFile.name}
                </a>
              </div>

              <div>
                <button
                  id={id}
                  type="button"
                  className={["delete-btn", "btn-link", "btn"].join(" ")}
                  onClick={() => {
                    if (
                      deleteUploadedTaskFile &&
                      uploadedFile &&
                      uploadedFile.id
                    ) {
                      deleteUploadedTaskFile(uploadedFile.id);
                    }
                  }}
                >
                  <TrashIcon />
                </button>
              </div>
            </div>
          </div>
        ) : (
          <div className={"mt-3"}>
            <DragAndDropField
              id={id}
              name={"file"}
              btnLabel={"Upload File"}
              onFileChange={handleUploadFile}
              data={[]}
              minHeight={"140px"}
            />
          </div>
        )}
      </DefaultContent>
    </>
  ) : type === TASK_TYPE_TEXT_FIELD ? (
    ((visibility === TASK_VISIBILITY_ADMIN &&
      (isSuperAdmin(currentUser && currentUser.roleId) ||
        isCompanyAdmin(currentUser && currentUser.roleId) ||
        isCompanyManager(currentUser && currentUser.roleId))) ||
      visibility === TASK_VISIBILITY_PARTNER ||
      visibility === TASK_VISIBILITY_PUBLIC) && (
      <>
        <DefaultContent title={name}>
          <div className="field-wrapper">
            <Text
              id={id}
              onKeyPress={preventDefaultSubmit}
              name={fieldName}
              placeholder={`Enter ${name}`}
              onChange={handleTextFieldChange}
              defaultValue={fieldValue}
            />
          </div>
        </DefaultContent>
      </>
    )
  ) : (
    type === TASK_TYPE_DROP_DOWN_FIELD &&
    ((visibility === TASK_VISIBILITY_ADMIN &&
      (isSuperAdmin(currentUser && currentUser.roleId) ||
        isCompanyAdmin(currentUser && currentUser.roleId) ||
        isCompanyManager(currentUser && currentUser.roleId))) ||
      visibility === TASK_VISIBILITY_PARTNER ||
      visibility === TASK_VISIBILITY_PUBLIC) && (
      <>
        <DefaultContent title={name}>
          {selectType === TASK_TYPE_DROP_DOWN_MULTI_SELECT ? (
            <Form
              initialValues={{
                [fieldName]: selectedTagList || []
              }}
              onKeyPress={preventDefaultSubmit}
            >
              <div className="form-wrapper">
                <div className="field-wrapper">
                  {tagTypePermission === TAG_PERMISSION_SELECT_AND_ADD_VALUE ? (
                    <MultiselectCreatable
                      id={id}
                      onKeyDown={preventDefaultSubmit}
                      name={fieldName}
                      placeholder={`Select an ${name}...`}
                      options={tagList}
                      closeMenuOnSelect={false}
                      handleOnChangeSubmit={multiSelectHandleChange}
                    />
                  ) : (
                    <MultiSelect
                      id={id}
                      onKeyDown={preventDefaultSubmit}
                      name={fieldName}
                      placeholder={`Select an ${name}...`}
                      options={tagList}
                      handleOnChangeSubmit={multiSelectHandleChange}
                    />
                  )}
                </div>
              </div>
            </Form>
          ) : (
            <div className="field-wrapper">
              <Select
                id={id}
                onKeyDown={preventDefaultSubmit}
                name={fieldName}
                placeholder="Select"
                options={tagList}
                defaultValue={tagList.find(
                  tagDetails => tagDetails.value === selectedTagId
                )}
                isSearchable={true}
                handleChange={singleSelectHandleChange}
              />
            </div>
          )}
        </DefaultContent>
      </>
    )
  );
};

export default PartnerCustomField;
