import React from "react";

// Components
import UpdateCollectionForm from "./UpdateCollectionForm";

const CollectionDetails = props => {
  return (
    <div>
      <UpdateCollectionForm history={props.history} match={props.match} />
    </div>
  );
};

export default CollectionDetails;
