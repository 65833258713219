import React from "react";
import { CrossIcon, InlineInfoIcon } from "../../../assets/img/icons";
import { Alert } from "reactstrap";

const ExpertReviewAndFeedBackAlert = ({
  showProjectReviewFeedback,
  showFeedback,
  hideFeedback,
  hideFeedbackAlert
}) => {
  return (
    <Alert
      color="danger"
      className={"d-flex align-items-center justify-content-stretch text-white"}
    >
      <InlineInfoIcon />
      <p className="ml-3 mb-0 font-weight-bold mr-auto text-white">
        Your project is completed and awaiting your review!
      </p>
      <div className="btn-wrapper ml-auto mr-3">
        {!showProjectReviewFeedback ? (
          <button
            className="btn btn-outline-light"
            onClick={() => showFeedback()}
          >
            Add Feedback
          </button>
        ) : (
          <button
            className="btn btn-outline-light"
            onClick={() => hideFeedback()}
          >
            Hide Feedback
          </button>
        )}
      </div>
      <div className="close-alert mr-0 ml-0">
        <button
          className="btn-plain btn text-white p-0"
          onClick={() => {
            hideFeedback();
            hideFeedbackAlert();
          }}
        >
          <CrossIcon />
        </button>
      </div>
    </Alert>
  );
};

export default ExpertReviewAndFeedBackAlert;
