import React, { Component } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

//Components
import Text from "../../components/base/Text";

class InvoiceFormFields extends Component {
  // Render the form fields
  render() {
    return (
      <div>
        <div className="card bg-white mt-3 mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                  required={true}
                  error=""
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="description"
                  label="Description"
                  placeholder="Enter Description"
                  required={true}
                  error=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="amount"
                  label="Amount"
                  placeholder="Enter Amount"
                  required={true}
                  error=""
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <div className="row">
                  <div className="col-lg-6 col-sm-12">
                    <label>Date</label>
                  </div>
                </div>
                <DatePicker
                  id="date"
                  name="date"
                  className="datepicker"
                  selected={this.props.date}
                  placeholderText="MM/DD/YYYY"
                  onChange={this.props.handleChange}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default InvoiceFormFields;
