/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import toast from "../../components/base/Toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchList } from "../../actions/table";

// Assets
import createLandingIcon from "../../assets/pages/landing-page/create-landing-page.png";

// Component
import AddButton from "../../components/base/AddButton";
import Text from "../../components/base/Text";
import Form from "../../components/base/Form";

// Helper
import { toString } from "../../lib/helper";

//API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";

// Contact form Create API Call
function contactFormCreate(data, props) {
  return apiClient
    .post(endpoints().contactForm, data)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      props.actions.fetchList("contactForms", "/v1/contactForm");
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    });
}

const AddContactFormModal = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <AddButton
        label="New Contact Form"
        className="pull-right btn btn-secondary h6-5-important font-weight-bold"
        onClick={toggle}
      />
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="custom-modal-popup"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <div className="img-wrapper d-flex justify-content-center mb-2 mt-3">
                <img src={createLandingIcon} size="30" alt="" />
              </div>
              <p className="text-center font-weight-bold mb-3">
                Create a new Contact Form
              </p>
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.content-wrapper */}
        </ModalHeader>

        <Form
          initialValues={{
            formName: ""
          }}
          onSubmit={values => {
            values.fromName = toString(values.formName);
            contactFormCreate(values, props);
            setTimeout(() => {
              toggle();
            }, 500);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div>
                <Text
                  name="formName"
                  label="Form Name"
                  placeholder="Form Name..."
                  error=""
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="" className="h6-5-important">
                  Create Contact Form
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(() => {
  return {};
}, mapDispatchToProps)(AddContactFormModal);
