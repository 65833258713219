// API Client
import { apiClient } from "../apiClient";

import { endpoints } from "../configs";

// Get Tag Type Value
export const getPartnerDetailsById = async id => {
  if (!id) {
    return null;
  }
  const response = await apiClient.get(`${endpoints().partnerAPI}/?id=${id}`);
  const data = response.data;
  if (data) return data;
};

// Get Tag Type Value
export const getDefaultPartnerType = async () => {
  const response = await apiClient.get(`${endpoints().partnerTypeAPI}/search`);
  const partnterTypeList = response.data.data;
  let defaultPartnerType = [];
  if (partnterTypeList && partnterTypeList.length > 0) {
    const tagList = [];
    partnterTypeList.forEach(tag => {
      tagList.push({
        id: tag.id,
        name: tag.name,
        value: tag.id,
        label: tag.name,
        isDefault: tag.isDefault,
        partnerTypeSetting: tag.partnerTypeSetting
      });
    });
    if (tagList && tagList.length > 0) {
      tagList.forEach(list => {
        if (list.isDefault === 1) {
          defaultPartnerType.push(list);
        }
      });
    }
  }
  if (defaultPartnerType) return defaultPartnerType;
};
