import React from "react";

// Config
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";

// Components
import SidebarItem from "./SidebarItem";
import toast from "../../../components/base/Toast";
import PartnerSelectionModal from "../../../components/PartnerSelectionModal";
import PartnerSection from "../../../components/PartnerSection";

// Helper
import {
  getUrlParameter,
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../lib/helper";

import { isBadRequest } from "../../../common/http";
import {
  MDF_PARTNER_STATUS_PENDING,
  MDF_STATUS_REQUESTED,
  MDF_STATUS_APPROVED,
  MDF_PARTNER_STATUS_ACCEPTED,
  MDF_PARTNER_STATUS_NOT_INTERESTED
} from "../../../views/mdf/Constants";

import { connect } from "react-redux";

// Referral source more partners pending list statuses
const sourceMorePartnersPendingListStatuses = [
  MDF_PARTNER_STATUS_PENDING,
  MDF_PARTNER_STATUS_ACCEPTED,
  MDF_PARTNER_STATUS_NOT_INTERESTED
];

class SelectPartner extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPartnerLoading: false,
      partnerList: [],
      currentPartner: {},
      partnerIds: [],
      isPartnerModal: false,
      isAppPartnerLoading: false,
      appPartnerLists: [],
      appPartnerList: [],
      projectPartnerWaitingList: [],
      pendingReferralPartnerList: [],
      partnerRequestId: "",
      partnerStatus: "",
      partnerWaitingRequestId: [],
      checkBox: {
        shortListedPartners: []
      },
      editInterestStatusModal: false,
      selectedEditInterestPartnerId: "",
      filteredPartnerList: []
    };
    this.toggle = this.toggle.bind(this);
    this.handlePartners = this.handlePartners.bind(this);
    this._handleShortListedPartners = this._handleShortListedPartners.bind(
      this
    );
  }

  componentDidMount() {
    // Check is logged in user
    // isLoggedIn();
    this._getPartnersList(this.props.referralId);
    if (
      isPartner(this.props.roleId) &&
      this.props.Status &&
      this.props.Status === MDF_STATUS_REQUESTED &&
      this.props.currentUserId
    ) {
      this._getCurrentPartner();
    }
  }

  componentDidUpdate(prevProps) {
    // Check is logged in user
    // isLoggedIn();
    if (prevProps.isReferralUpdate !== this.props.isReferralUpdate)
      this._getPartnersList(this.props.appId);
  }

  // Get Current Partner
  _getCurrentPartner = () => {
    const id = getUrlParameter("id");
    this.setState({ isPartnerLoading: true }, () => {
      apiClient.get(`${endpoints().partnerAPI}?id=${id}`).then(response => {
        const currentPartner = response.data;

        this.setState({
          isPartnerLoading: false,
          currentPartner: currentPartner
            ? currentPartner
            : this.state.currentPartner
        });
      });
    });
  };

  // Filter Partner List
  _filterPartnersList(selectedFilters) {
    const { partnerList } = this.state;
    let filteredPartnerList = [],
      tags = [],
      city = "",
      type = "",
      tier = "";

    // Check filters selected
    if (selectedFilters) {
      city = selectedFilters.city || "";
      type = selectedFilters.type || "";
      tags = selectedFilters.tags || [];
      tier = selectedFilters.tier || "";
    }

    // Filter partners
    if (partnerList && partnerList.length > 0) {
      this.setState({ isPartnerLoading: true }, () => {
        partnerList.forEach(partner => {
          const partnerType = partner.partnerTypeName || "";
          const partnerServices = partner.services || [];
          const partnerPlatforms = partner.platforms || [];
          const partnerCity = partner.city || "";
          const partnerTier = partner.partnerTierName || "";
          let isTypeExist = false;
          let isCityExist = false;
          let isTagExist = false;
          let isTierExist = false;

          if (partnerType === type || type === "") isTypeExist = true;
          if (partnerCity === city || city === "") isCityExist = true;
          if (partnerTier === tier || tier === "") isTierExist = true;
          if (tags && tags.length > 0) {
            tags.forEach(tagName => {
              let isServiceExist = false;
              let isPlatformExist = false;
              isServiceExist = this.isTagExist(partnerServices, tagName);
              isPlatformExist = this.isTagExist(partnerPlatforms, tagName);
              if (isServiceExist || isPlatformExist) {
                isTagExist = true;
              }
            });
          } else {
            isTagExist = true;
          }
          if (isTypeExist && isCityExist && isTagExist && isTierExist) {
            filteredPartnerList.push(partner);
          }
        });
        this.setState({
          filteredPartnerList: filteredPartnerList,
          isPartnerLoading: false
        });
      });
    }
  }

  // Check is tag exist in partner tag data
  isTagExist = (tagList, filterTagName) => {
    if (!tagList.length) {
      return false;
    }

    let isExist = false;
    tagList.forEach(tag => {
      if (filterTagName === tag.tag_name) {
        isExist = true;
      }
    });

    return isExist;
  };

  _getPartnersList(appId) {
    this.setState({ isPartnerLoading: true }, () => {
      apiClient
        .get(
          `${
            endpoints().partnerAPI
          }/search?marketplaceStatus=${MDF_STATUS_APPROVED}`
        )
        .then(response => {
          const partnerList = response.data.data;
          this.setState(
            {
              isPartnerLoading: false,
              partnerList: partnerList ? partnerList : this.state.partnerList
            },
            () => {
              this._filterPartnersList({});
            }
          );
          this.props.Status &&
            this.props.Status !== MDF_STATUS_REQUESTED &&
            this._getSourceMorePartnersPendingList(
              true,
              sourceMorePartnersPendingListStatuses.join(", ")
            );
        });
    });
  }

  // Handle Modal Click
  toggle = () => {
    this.setState({ isPartnerModal: !this.state.isPartnerModal });
  };

  // Handle partners
  handlePartners(value) {
    if (!value) {
      return this.setState({ partnerIds: [] });
    }

    const partnerIds = this.state.partnerIds.slice();
    if (partnerIds.indexOf(value) > -1) {
      this.setState({ partnerIds: partnerIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ partnerIds: [value] });
    }
  }

  // Create Project User
  async createAppPartner() {
    const { partnerIds } = this.state;
    const appId = this.props.appId;
    if (appId) {
      const data = {
        partnerIds: partnerIds,
        appId: appId,
        status: MDF_PARTNER_STATUS_PENDING,
        appName: this.props.appDetails.app_name
      };

      return await apiClient
        .put(`${endpoints().appAPI}/${appId}`, data)
        .then(() => {
          this.toggle();
          this.props.getAppDetails(this.props.appId);
          this.setState({ partnerIds: [] });
          this._getSourceMorePartnersPendingList(
            true,
            sourceMorePartnersPendingListStatuses.join(", ")
          );
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    } else if (this.props.setAppPartners) {
      this.setSelectedAppPartners(partnerIds);
      this.props.setAppPartners(partnerIds, MDF_PARTNER_STATUS_PENDING);
      this.setState({ partnerIds: [] }, () => {
        this.toggle();
      });
    } else if (this.props.handlePartnerChange) {
      this.setSelectedAppPartners(partnerIds);
      this.props.handlePartnerChange(partnerIds);
      this.setState({ partnerIds: [] }, () => {
        this.toggle();
      });
    }
  }

  setSelectedAppPartners = partnerIds => {
    const partnerList = this.state.partnerList;
    if (partnerIds && partnerIds.length > 0 && partnerList.length > 0) {
      partnerList.map(partner => {
        partnerIds.forEach(partnerId => {
          if (partner.id === partnerId) {
            if (
              this.state.appPartnerList.filter(
                setAppPartners => setAppPartners.id === partnerId
              ).length === 0
            ) {
              this.setState({ appPartnerList: [partner] });
            }
          }
        });
      });
    }
  };

  // Update Partner List
  updatePartnerList = selectedFilters => {
    this._filterPartnersList(selectedFilters);
  };

  // Get Project User Pending List
  _getSourceMorePartnersPendingList(isAppPartner, status) {
    const appId = this.props.appId;
    this.setState(
      { isAppPartnerLoading: isAppPartner, isPartnerLoading: true },
      () => {
        apiClient.get(`${endpoints().appAPI}/${appId}`).then(response => {
          const appPartnerList = response && response.data;
          const selectedIds = [];
          if (appPartnerList) {
            appPartnerList.length >= 0 &&
              appPartnerList.map(partner =>
                selectedIds.push(partner.partnerId)
              );
          }
          this.setState({
            appPartnerList: appPartnerList ? appPartnerList : ""
          });
        });
      }
    );
  }

  // App details
  getAppDetails = appId => {
    this.setState({ isLoading: true }, () => {
      return apiClient
        .get(`${endpoints().appAPI}/${appId}`)
        .then(response => {
          this.setState({
            appDetails: response.data,
            isLoading: false,
            appLogoFileUrl: response.data.app_logo
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  };

  _handleShortListedPartners(e) {
    const newSelection = parseInt(e.target.value, 10);
    let newSelectionArray;

    if (this.state.checkBox.shortListedPartners.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.shortListedPartners.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.shortListedPartners,
        parseInt(newSelection, 10)
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        shortListedPartners: newSelectionArray
      }
    }));
  }

  render() {
    const {
      partnerIds,
      isPartnerModal,
      isPartnerLoading,
      appPartnerList,
      filteredPartnerList,
      currentPartner
    } = this.state;

    const { appId, appDetails, partnerId } = this.props;

    let assignedPartnerList = [];

    if (appDetails && appDetails.partnerDetails) {
      assignedPartnerList.push(appDetails.partnerDetails);
    }
    if (partnerId) {
      assignedPartnerList.push({ partnerId: partnerId });
    }

    if (
      isPartner(this.props.roleId) &&
      this.props.status &&
      this.props.status === MDF_STATUS_REQUESTED &&
      this.props.currentUserId
    ) {
      assignedPartnerList.push(currentPartner);
    }
    if (
      !isPartner(this.props.roleId) &&
      this.props.status &&
      this.props.status === MDF_STATUS_REQUESTED &&
      this.props.setAppPartners
    ) {
      assignedPartnerList.push(this.props.setAppPartners);
    }

    return (
      <>
        <PartnerSection
          appDetails={appDetails}
          currentUserId={this.props.currentUserId}
          appPartnerList={appPartnerList}
          onSourcePartner={() => {
            this._getPartnersList(appId);
            this.toggle();
          }}
          isPartnerLoading={isPartnerLoading}
          status={this.props.status}
          partnerId={partnerId}
        />
        {/*Partner modal*/}
        <PartnerSelectionModal
          isPartnerModal={isPartnerModal}
          isLoading={isPartnerLoading}
          toggle={this.toggle}
          partnerList={filteredPartnerList}
          selectedPartnerList={assignedPartnerList}
          handlePartners={this.handlePartners}
          partnerIds={partnerIds}
          createAppPartner={e => this.createAppPartner(e)}
          updatePartnerList={this.updatePartnerList}
        />
        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) &&
          this.props.enablePartners && (
            <>
              <SidebarItem>
                <p className="font-weight-bold mb-1">
                  Shortlist of Sourced Partners{" "}
                </p>{" "}
                {/*had to move this here because of design and global css restrictions*/}
                <span className="sidebar-item-description d-block h7 text-grayed mb-3">
                  Once your shortlist is approved by Admin, you’ll select one
                  primary Partner to share with the Client.
                </span>
                <span
                  onClick={() => {
                    this._getPartnersList(appId);
                    this.toggle();
                  }}
                  className="source-more h6-5 font-weight-bold mb-4 d-block cursor-pointer"
                >
                  {`Source ${
                    partnerIds && partnerIds.length > 0 ? "More " : ""
                  }Partners`}
                </span>
              </SidebarItem>
            </>
          )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const roleId = state.user ? state.user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(SelectPartner);
