import React, { useState } from "react";
import { Link } from "react-router-dom";

import { formatCurrency } from "../../../helpers/currency";

// Components
import SidebarItem from "./SidebarItem";
import { InlineInfoIcon, CrossIcon } from "../../../assets/img/icons";

import { Alert } from "reactstrap";
import detectBrowser from "../../../components/helpers/detectBrowser";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";
import Currency from "../../../components/Currency";

const PaymentSection = ({
  referralDetails,
  show,
  togglePayInvoice,
  togglePaymentSection,
  enableReferralPayments,
  isInvoicePaid,
  partnerReminder,
  toggleMarkPaymentMadeModal,
  paymentId
}) => {
  const [showReminder, setShowReminder] = useState(true);
  const showReminderToggle = () => {
    setShowReminder(!showReminder);
  };
  const [isMobile] = useState(detectBrowser());
  return (
    <div>
      {enableReferralPayments && (
        <div
          className="card border-0 text-white mb-4"
          style={{ backgroundColor: "#2F2F3E" }}
        >
          <div className="project-details-body">
            <div className="details-body-main">
              <div className="card-body pt-0">
                <div className="certification mb-2">
                  <span
                    className="badge badge-pill badge-primary"
                    style={{ backgroundColor: "#0AC297", marginLeft: "122px" }}
                  >
                    Payout
                  </span>
                </div>
                <h4 className="font-weight-bold">Attention!</h4>
                <p className="font-weight-bold">
                  The deal has been closed and the{" "}
                  {Terminology.get(SystemName.REFERRAL)} is ready to be paid.
                </p>

                <div className="btn-wrapper">
                  <button
                    type="button"
                    className="btn btn-outline-secondary px-3 mr-2 mt-2 text-white"
                    onClick={togglePaymentSection}
                  >
                    Send Partner a Message
                  </button>

                  <div className="btn-group text-right mt-2">
                    <button
                      type="button"
                      className="btn btn-primary dropdown-toggle"
                      onClick={togglePayInvoice}
                    >
                      <span className="px-5">Pay Invoice</span>
                    </button>
                    <div
                      className={`dropdown-menu dropdown-menu-right ${show}`}
                      style={{ width: "auto", fontSize: "12px" }}
                    >
                      {referralDetails.invoice_id && (
                        <Link
                          to={`/accounting/billings/${referralDetails.invoice_id}`}
                          className="dropdown-item"
                          onClick={() => {
                            togglePayInvoice();
                          }}
                        >
                          View Invoice to Make Payment
                        </Link>
                      )}
                      <a
                        className="dropdown-item"
                        onClick={() => {
                          toggleMarkPaymentMadeModal();
                          togglePayInvoice();
                        }}
                      >
                        Mark Payment as Made
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="details-body-sidebar">
              <SidebarItem>
                <p className="font-weight-bold mb-0">Deal Earnings:</p>
                <h5 className="font-weight-bold mt-0">
                  {referralDetails.commission_amount ? (
                    <Currency
                      amount={referralDetails.commission_amount}
                      currencyName={referralDetails.currency}
                    />
                  ) : (
                    0
                  )}
                </h5>
                <p className="font-weight-bold mt-3 mb-0">
                  Estimated Payment Date:
                </p>
                <h5 className="font-weight-bold mt-0">
                  {referralDetails.closeDate ? referralDetails.closeDate : ""}
                </h5>
              </SidebarItem>
            </div>
          </div>
        </div>
      )}

      {referralDetails.partner &&
        !referralDetails.partner.account_payment_method_id &&
        enableReferralPayments &&
        !isInvoicePaid &&
        !paymentId &&
        showReminder && (
          <>
            <div className="alerts-wrapper pt-3">
              <Alert
                color="danger"
                className={`${
                  isMobile ? "d-block" : "d-flex"
                } align-items-center justify-content-stretch text-white`}
              >
                {isMobile ? (
                  <div className="row">
                    <div className="col-1">
                      <InlineInfoIcon />
                    </div>
                    <div className="col-9">
                      <p className="ml-1 mb-0 font-weight-bold mr-auto text-white">
                        Partner hasn't setup their payout preferences.
                      </p>
                    </div>
                    <div className="col-2">
                      <div className="close-alert mr-0 ml-0 float-right">
                        <button
                          className="btn-plain btn text-white p-0"
                          type="button"
                          onClick={() => {
                            showReminderToggle();
                          }}
                        >
                          <CrossIcon />
                        </button>
                      </div>
                    </div>
                  </div>
                ) : (
                  <>
                    <InlineInfoIcon />
                    <p className="ml-1 mb-0 font-weight-bold mr-auto text-white">
                      Partner hasn't setup their payout preferences.
                    </p>
                    <div className="btn-wrapper ml-auto mr-3">
                      <button
                        type="button"
                        className="p-2 btn font-weight-bold outline-bold text-white p-0 plain-btn"
                        onClick={() => {
                          partnerReminder();
                        }}
                        style={{ border: "2px solid white" }}
                      >
                        Remind Partner to Setup Payout Preferences
                      </button>
                    </div>
                    <div className="close-alert mr-0 ml-0">
                      <button
                        className="btn-plain btn text-white p-0"
                        type="button"
                        onClick={() => {
                          showReminderToggle();
                        }}
                      >
                        <CrossIcon />
                      </button>
                    </div>
                  </>
                )}
                {isMobile && (
                  <div className="btn-wrapper ml-auto mr-3 mt-2">
                    <button
                      type="button"
                      className="p-2 btn font-weight-bold outline-bold text-white p-0 plain-btn"
                      onClick={() => {
                        partnerReminder();
                      }}
                      style={{ border: "2px solid white" }}
                    >
                      Remind Partner to Setup Payout Preferences
                    </button>
                  </div>
                )}
              </Alert>
            </div>
          </>
        )}
    </div>
  );
};

export default PaymentSection;
