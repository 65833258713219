import React, { useState, useEffect, useRef } from "react";
import moment from "moment";

import { Popover, PopoverBody } from "reactstrap";

import { Link } from "react-router-dom";

// Components
import SidebarItem from "../../views/referral/inner-components/SidebarItem";
import Spinner from "../../components/base/Spinner";

// Icons
import { CrossIcon, ExclamationIcon } from "../../assets/img/icons";

import Button from "../../components/base/Button";
import { formatDate } from "../../common/utils";

const SalesforceTasks = ({
  salesforceAccountDetails,
  openProspectAccountModal,
  openParterAccountModal,
  accountId,
  salesforeTerritoryFieldName,
  isSalesforceTasksLoading,
  referralDetails,
  openOwnerSelectionModal,
  salesforceUsersList,
  enableSalesRouting,
  enableOpportunityOwnerExistAccount,
  enableOpportunityOwnerNewAccount,
  linkOpportunity
}) => {
  const spinnerStyle = `margin: 0;  position: absolute;  top: 50%;  left: 35%;`;

  const [isTerritaryAssignment, setTerritaryAssignment] = useState(false);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [opportunityPopoverOpen, setOpportunityPopoverOpen] = useState(false);
  const [partnerPopoverOpen, setPartnerPopoverOpen] = useState(false);
  const [
    opportunityOwnerPopoverOpen,
    setOwnerOpportunityPopoverOpen
  ] = useState(false);

  const toggle = () => setPopoverOpen(!popoverOpen);

  const toggleOpportunity = () =>
    setOpportunityPopoverOpen(!opportunityPopoverOpen);

  const togglePartnerAccountPopup = () => {
    setPartnerPopoverOpen(!partnerPopoverOpen);
  };

  const accountDetails =
    salesforceAccountDetails && salesforceAccountDetails.accountDetails;

  const partnerSalesforceAccountDetails =
    salesforceAccountDetails && salesforceAccountDetails.partnerAccountDetails;

  const partnerSalesforceAccountId =
    partnerSalesforceAccountDetails && partnerSalesforceAccountDetails.id;

  const toggleOpportunityOwner = () => {
    setOwnerOpportunityPopoverOpen(!opportunityOwnerPopoverOpen);
  };

  let ownerSalesforceId =
    referralDetails && referralDetails.owner_salesforce_id;

  let selectedOwner;
  // Get Selected Owner
  if (salesforceUsersList && ownerSalesforceId) {
    selectedOwner =
      salesforceUsersList &&
      salesforceUsersList.find(x => x.salesforce_id === ownerSalesforceId);
  }

  //get owner first name and last name
  const getOwnerDetails = ownerId => {
    let ownerDetails;
    let ownerName;
    if (
      salesforceUsersList &&
      Array.isArray(salesforceUsersList) &&
      salesforceUsersList.length > 0 &&
      ownerId
    ) {
      ownerDetails = salesforceUsersList.find(x => x.salesforce_id === ownerId);
    }
    ownerName = ownerDetails
      ? ownerDetails.first_name
        ? ownerDetails.first_name
        : "" + " " + ownerDetails.last_name
        ? ownerDetails.last_name
        : ""
      : "";
    return ownerName;
  };

  if (
    accountDetails &&
    accountDetails.id &&
    salesforeTerritoryFieldName &&
    accountDetails[salesforeTerritoryFieldName]
  ) {
    setTerritaryAssignment(true);
  }
  const popOverRef = useRef();
  const handleClickOutside = event => {
    if (popOverRef.current && !popOverRef.current.contains(event.target)) {
      setPopoverOpen(false);
      setOpportunityPopoverOpen(false);
      setOwnerOpportunityPopoverOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
  }, []);

  return (
    <>
      <SidebarItem>
        <div className="salesforce_tasks">
          <p className="font-weight-bold mb-1">Salesforce Tasks</p>
          <p style={{ fontSize: 12, color: "#989898" }}>
            The following tasks must be completed.
          </p>
          {isSalesforceTasksLoading ? (
            <div
              style={{
                position: "relative",
                height: isTerritaryAssignment ? "170px" : "120px",
                marginRight: 60
              }}
            >
              <Spinner spinnerStyle={spinnerStyle} />
            </div>
          ) : (
            <div>
              <div className="d-flex align-items-start justify-content-start">
                {partnerSalesforceAccountId ? (
                  <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                ) : referralDetails &&
                  referralDetails.partner_salesforce_account_id ? (
                  <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                ) : (
                  <>
                    <div className="mt-2 pl-1">
                      <ExclamationIcon />
                    </div>
                  </>
                )}
                <div>
                  <b
                    className={
                      partnerSalesforceAccountId
                        ? `alert-salesforce`
                        : referralDetails &&
                          referralDetails.partner_salesforce_account_id
                        ? `alert-salesforce`
                        : `alert-referral-danger`
                    }
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 16,
                      fontWeight: "bold",
                      margin: "0 10px 2px"
                    }}
                  >
                    Partner Account Inspection
                  </b>

                  <div className="d-flex justify-content-start">
                    {partnerSalesforceAccountId ? (
                      <p
                        id="viewPartnerAccountInSalesforce"
                        className="cursor-pointer"
                      >
                        <span
                          href=""
                          onClick={_e => {
                            // e.preventDefault();
                            // toggle();
                          }}
                          style={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            fontSize: 13,
                            paddingLeft: "10px"
                          }}
                        >
                          View Account
                        </span>
                      </p>
                    ) : (
                      <span
                        className="cursor-pointer"
                        style={{
                          color: "#3498DB",
                          textDecoration: "underline",
                          fontSize: 13,
                          paddingLeft: "10px"
                        }}
                        onClick={() => openParterAccountModal()}
                      >
                        Check Account
                      </span>
                    )}
                  </div>
                </div>
              </div>

              <div
                className="d-flex align-items-start justify-content-start"
                style={{ paddingTop: "20px" }}
              >
                {accountId ? (
                  <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                ) : referralDetails.salesforce_account_id ? (
                  <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                ) : (
                  <>
                    <div className="mt-2 pl-1">
                      <ExclamationIcon />
                    </div>
                  </>
                )}
                <div>
                  <b
                    className={
                      accountId
                        ? `alert-salesforce`
                        : referralDetails.salesforce_account_id
                        ? `alert-salesforce`
                        : `alert-referral-danger`
                    }
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 16,
                      fontWeight: "bold",
                      margin: "0 10px 2px"
                    }}
                  >
                    Prospect Account Inspection
                  </b>

                  <div className="d-flex justify-content-start">
                    {accountId ? (
                      <p
                        id="viewAccountInSalesforce"
                        className="cursor-pointer"
                      >
                        <span
                          href=""
                          onClick={_e => {
                            // e.preventDefault();
                            // toggle();
                          }}
                          style={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            fontSize: 13,
                            paddingLeft: "10px"
                          }}
                        >
                          View Account
                        </span>
                      </p>
                    ) : (
                      <span
                        className="cursor-pointer"
                        style={{
                          color: "#3498DB",
                          textDecoration: "underline",
                          fontSize: 13,
                          paddingLeft: "10px"
                        }}
                        onClick={() => openProspectAccountModal()}
                      >
                        Check Account
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div
                className="d-flex align-items-start justify-content-start"
                style={{ paddingTop: "20px" }}
              >
                {accountId ? (
                  referralDetails.salesforce_opportunity_id ? (
                    <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                  ) : salesforceAccountDetails &&
                    salesforceAccountDetails.opportunityCount > 0 ? (
                    <>
                      <div className="mt-2 pl-1">
                        <ExclamationIcon />
                      </div>
                    </>
                  ) : (
                    <i className="fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce"></i>
                  )
                ) : (
                  <>
                    <div className="mt-2 pl-1">
                      <ExclamationIcon />
                    </div>
                  </>
                )}
                <div>
                  <b
                    className={
                      accountId
                        ? referralDetails.salesforce_opportunity_id
                          ? `alert-salesforce`
                          : salesforceAccountDetails &&
                            salesforceAccountDetails.opportunityCount > 0
                          ? `alert-referral-danger`
                          : `alert-salesforce`
                        : `alert-referral-danger`
                    }
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      fontSize: 16,
                      fontWeight: "bold",
                      margin: "0 10px 2px"
                    }}
                  >
                    Check Opportunities
                  </b>

                  <div className="d-flex justify-content-start">
                    {!accountId || !partnerSalesforceAccountId ? (
                      <span
                        style={{
                          fontSize: 13,
                          paddingLeft: "10px"
                        }}
                      >
                        No Opportunities Found
                      </span>
                    ) : referralDetails.salesforce_opportunity_id ? (
                      <p
                        id="viewOpportunityInSalesforce"
                        className="cursor-pointer"
                      >
                        <a
                          style={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            fontSize: 13,
                            paddingLeft: "10px"
                          }}
                        >
                          View Opportunities
                        </a>
                      </p>
                    ) : salesforceAccountDetails &&
                      salesforceAccountDetails.opportunityCount > 0 ? (
                      <p
                        id="viewOpportunityInSalesforce"
                        className="cursor-pointer"
                      >
                        <a
                          style={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            fontSize: 13,
                            paddingLeft: "10px"
                          }}
                        >
                          View Open Opportunities
                        </a>
                      </p>
                    ) : (
                      <p style={{ fontSize: 14, paddingLeft: "10px" }}>
                        No Opportunities Found
                      </p>
                    )}
                  </div>
                </div>
              </div>
              {isTerritaryAssignment && (
                <div
                  className={
                    accountId && isTerritaryAssignment
                      ? `d-flex align-items-center alert-salesforce`
                      : `d-flex align-items-center`
                  }
                  style={{ paddingTop: "20px" }}
                >
                  <i
                    className={
                      accountId && isTerritaryAssignment
                        ? `fas fa-check-circle fa-2x mt-1 pr-1`
                        : accountId
                        ? `far fa-circle fa-2x`
                        : `fa fa-exclamation-circle fa-2x  mt-1 pr-2 alert-referral-danger`
                    }
                    aria-hidden="true"
                  ></i>
                  {accountId && isTerritaryAssignment ? (
                    `fas fa-check-circle fa-2x mt-1 pr-1`
                  ) : accountId ? (
                    `far fa-circle fa-2x`
                  ) : (
                    <>
                      <div className="mt-2 pl-1">
                        <ExclamationIcon />
                      </div>
                    </>
                  )}
                  <div>
                    <b
                      className={
                        accountId
                          ? referralDetails.salesforce_opportunity_id
                            ? isTerritaryAssignment
                              ? `alert-salesforce`
                              : ""
                            : salesforceAccountDetails &&
                              salesforceAccountDetails.opportunityCount > 0
                            ? `alert-referral-danger`
                            : `alert-salesforce`
                          : `alert-referral-danger`
                      }
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        fontSize: 16,
                        fontWeight: "bold",
                        margin: "0 10px 2px"
                      }}
                    >
                      Territory Assignment
                    </b>
                    <div className="d-flex justify-content-start">
                      {!accountId ? (
                        <Link
                          style={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            fontSize: 13,
                            paddingLeft: "10px"
                          }}
                          onClick={() => openProspectAccountModal()}
                        >
                          Check Account
                        </Link>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              )}
              {enableSalesRouting == "true" && ownerSalesforceId ? (
                <div
                  className="d-flex align-items-start justify-content-start"
                  style={{ paddingTop: "20px" }}
                >
                  <i
                    className={`fas fa-check-circle fa-2x mt-1 pr-1 alert-salesforce`}
                  ></i>
                  <div>
                    <b
                      className={`alert-salesforce`}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        fontSize: 16,
                        fontWeight: "bold",
                        margin: "0 10px 2px"
                      }}
                    >
                      Owner Inspection
                    </b>

                    <div className="d-flex justify-content-start">
                      <>
                        <p id="viewOpportunityOwner" className="cursor-pointer">
                          <a
                            style={{
                              color: "#3498DB",
                              textDecoration: "underline",
                              fontSize: 13,
                              paddingLeft: "10px"
                            }}
                          >
                            View Owner
                          </a>
                        </p>
                        <p
                          style={{
                            paddingLeft: 5
                          }}
                        ></p>
                        {enableOpportunityOwnerNewAccount == "false" && (
                          <>
                            {"/"}
                            <p className="cursor-pointer">
                              <a
                                style={{
                                  color: "#3498DB",
                                  textDecoration: "underline",
                                  fontSize: 13,
                                  paddingLeft: 5
                                }}
                                onClick={() => openOwnerSelectionModal()}
                              >
                                Change Owner
                              </a>
                            </p>
                          </>
                        )}
                      </>
                    </div>
                  </div>
                </div>
              ) : (
                enableSalesRouting == "true" &&
                enableOpportunityOwnerNewAccount == "false" && (
                  <div
                    className="d-flex align-items-start justify-content-start"
                    style={{ paddingTop: "20px" }}
                  >
                    <>
                      <div className="mt-2 pl-1">
                        <ExclamationIcon />
                      </div>
                    </>
                    <div>
                      <b
                        className={`alert-referral-danger`}
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          fontSize: 16,
                          fontWeight: "bold",
                          margin: "0 10px 2px"
                        }}
                      >
                        Owner Inspection
                      </b>

                      <div className="d-flex justify-content-start">
                        <span
                          className="cursor-pointer"
                          style={{
                            color: "#3498DB",
                            textDecoration: "underline",
                            fontSize: 13,
                            paddingLeft: "10px"
                          }}
                          onClick={() => openOwnerSelectionModal()}
                        >
                          Select Owner
                        </span>
                      </div>
                    </div>
                  </div>
                )
              )}

              {accountId && accountDetails && (
                <Popover
                  placement="left"
                  isOpen={popoverOpen}
                  target="viewAccountInSalesforce"
                  toggle={toggle}
                  className="account-in-salesforce"
                >
                  <PopoverBody>
                    <div className="p-2" ref={popOverRef}>
                      <div className="row">
                        <div className="col-md-12 d-flex bd-highlight mb-2">
                          <div
                            className="ml-auto cursor-pointer"
                            onClick={() => toggle()}
                          >
                            <CrossIcon />
                          </div>
                        </div>

                        {accountDetails && accountDetails.name && (
                          <div className="col-md-12 mb-2">
                            <a
                              target="_blank"
                              href={`${salesforceAccountDetails.instanceUrl}/lightning/r/${accountDetails.id}/view`}
                              className="font-weight-bold"
                              rel="noopener noreferrer"
                            >
                              {accountDetails && accountDetails.name}
                            </a>
                          </div>
                        )}

                        {accountDetails && accountDetails.type && (
                          <div className="col-md-12">
                            <label className="text-grayed font-weight-bold font-size-13">
                              Type
                            </label>
                            <p className="font-weight-bold">
                              {accountDetails && accountDetails.type}
                            </p>
                          </div>
                        )}

                        {accountDetails && accountDetails.status && (
                          <div className="col-md-12">
                            <label className="text-grayed font-weight-bold font-size-13">
                              Status
                            </label>
                            <p className="font-weight-bold">
                              {(accountDetails && accountDetails.status) || ""}
                            </p>
                          </div>
                        )}

                        {accountDetails && accountDetails.accountOwner && (
                          <div className="col-md-12">
                            <label className="text-grayed font-weight-bold font-size-13">
                              Account Owner
                            </label>
                            <p className="font-weight-bold">
                              {(accountDetails &&
                                accountDetails.accountOwner) ||
                                ""}
                            </p>
                          </div>
                        )}

                        {accountDetails &&
                          accountDetails.billingAddressCountry && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Billing Address
                              </label>
                              <p className="font-weight-bold">
                                {(accountDetails &&
                                  accountDetails.billingAddressCountry) ||
                                  ""}
                              </p>
                            </div>
                          )}

                        {salesforceAccountDetails &&
                          salesforceAccountDetails.opportunityCount >= 1 && (
                            <div className="">
                              <hr
                                style={{
                                  width: "100%",
                                  borderColor: "#f3f3f4"
                                }}
                              />

                              <div className="d-flex bd-highlight mb-3">
                                <div className="mr-auto p-2 font-weight-bold">
                                  Opportunities{" "}
                                  {salesforceAccountDetails.opportunityCount
                                    ? `(${salesforceAccountDetails.opportunityCount})`
                                    : ""}
                                </div>
                                {salesforceAccountDetails.opportunityCount >
                                  1 && (
                                  <div className="p-2 font-weight-bold">
                                    <a
                                      target="_blank"
                                      href={`${
                                        salesforceAccountDetails.instanceUrl
                                      }/_ui/search/ui/UnifiedSearchResults?searchType=2&sen=005&sen=006&str=${accountDetails.name
                                        .split(" ")
                                        .join("+")}`}
                                      className="font-weight-bold"
                                      rel="noopener noreferrer"
                                    >
                                      View All
                                    </a>
                                  </div>
                                )}
                              </div>

                              <div
                                className="overflow-auto"
                                style={{ height: "14rem" }}
                              >
                                {salesforceAccountDetails.opportunityList &&
                                  salesforceAccountDetails.opportunityList
                                    .length > 0 &&
                                  salesforceAccountDetails.opportunityList.map(
                                    data => {
                                      return (
                                        <div className="opportunity-list p-3 col-md-12 border-bottom">
                                          <div className="mb-2">
                                            <a
                                              target="_blank"
                                              href={`${salesforceAccountDetails.instanceUrl}/lightning/r/${data.Id}/view`}
                                              className="font-weight-bold"
                                              rel="noopener noreferrer"
                                            >
                                              {data.Name}
                                            </a>
                                          </div>

                                          <div className="row">
                                            <div className="col-md-6">
                                              <label className="text-grayed font-weight-bold font-size-13">
                                                Stage
                                              </label>
                                              <p className="font-weight-bold">
                                                {(data && data.StageName) || ""}
                                              </p>
                                            </div>

                                            <div className="col-md-6">
                                              <label className="text-grayed font-weight-bold font-size-13">
                                                Owner
                                              </label>
                                              <p className="font-weight-bold">
                                                {data && data.OwnerId
                                                  ? getOwnerDetails(
                                                      data.OwnerId
                                                    )
                                                  : ""}
                                              </p>
                                            </div>

                                            <div className="col-md-6">
                                              <label className="text-grayed font-weight-bold font-size-13">
                                                Close Date
                                              </label>
                                              <p className="font-weight-bold">
                                                {(data &&
                                                  moment(data.CloseDate).format(
                                                    "MMM DD, YYYY"
                                                  )) ||
                                                  ""}
                                              </p>
                                            </div>
                                            <div className="col-md-6">
                                              <label className="text-grayed font-weight-bold font-size-13">
                                                Created Date
                                              </label>
                                              <p className="font-weight-bold">
                                                {data &&
                                                  data.CreatedDate &&
                                                  formatDate(data.CreatedDate)}
                                              </p>
                                            </div>

                                            <div className="opportunity-list text-center pb-2">
                                              {data.linkOpportunity &&
                                                salesforceAccountDetails.opportunityList &&
                                                salesforceAccountDetails
                                                  .opportunityList.length > 0 &&
                                                referralDetails &&
                                                !referralDetails.salesforce_opportunity_id && (
                                                  <Link
                                                    to="#"
                                                    className="text-underline"
                                                    style={{ marginLeft: 70 }}
                                                    onClick={e => {
                                                      e.preventDefault();
                                                      toggle();
                                                      linkOpportunity(data.Id);
                                                    }}
                                                  >
                                                    Link this Opportunity{" "}
                                                  </Link>
                                                )}
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    }
                                  )}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </PopoverBody>
                </Popover>
              )}

              {partnerSalesforceAccountId && partnerSalesforceAccountDetails && (
                <Popover
                  placement="left"
                  isOpen={partnerPopoverOpen}
                  target="viewPartnerAccountInSalesforce"
                  toggle={togglePartnerAccountPopup}
                  className="account-in-salesforce"
                >
                  <PopoverBody>
                    <div className="p-2" ref={popOverRef}>
                      <div className="row">
                        <div className="col-md-12 d-flex bd-highlight mb-2">
                          <div
                            className="ml-auto cursor-pointer"
                            onClick={() => togglePartnerAccountPopup()}
                          >
                            <CrossIcon />
                          </div>
                        </div>

                        {partnerSalesforceAccountDetails &&
                          partnerSalesforceAccountDetails.name && (
                            <div className="col-md-12 mb-2">
                              <a
                                target="_blank"
                                href={`${salesforceAccountDetails.instanceUrl}/lightning/r/${partnerSalesforceAccountDetails.id}/view`}
                                className="font-weight-bold"
                                rel="noopener noreferrer"
                              >
                                {partnerSalesforceAccountDetails &&
                                  partnerSalesforceAccountDetails.name}
                              </a>
                            </div>
                          )}

                        {partnerSalesforceAccountDetails &&
                          partnerSalesforceAccountDetails.type && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Type
                              </label>
                              <p className="font-weight-bold">
                                {partnerSalesforceAccountDetails &&
                                  partnerSalesforceAccountDetails.type}
                              </p>
                            </div>
                          )}

                        {partnerSalesforceAccountDetails &&
                          partnerSalesforceAccountDetails.website && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Website
                              </label>
                              <p className="font-weight-bold">
                                <a
                                  href={`${partnerSalesforceAccountDetails &&
                                    partnerSalesforceAccountDetails.website}`}
                                >
                                  {(partnerSalesforceAccountDetails &&
                                    partnerSalesforceAccountDetails.website) ||
                                    ""}
                                </a>
                              </p>
                            </div>
                          )}

                        {partnerSalesforceAccountDetails &&
                          partnerSalesforceAccountDetails.billingAddressCountry && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Billing Address
                              </label>
                              <p className="font-weight-bold">
                                {(partnerSalesforceAccountDetails &&
                                  partnerSalesforceAccountDetails.billingAddressCountry) ||
                                  ""}
                              </p>
                            </div>
                          )}

                        {partnerSalesforceAccountDetails &&
                          partnerSalesforceAccountDetails.phone && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Phone
                              </label>
                              <p className="font-weight-bold">
                                {(partnerSalesforceAccountDetails &&
                                  partnerSalesforceAccountDetails.phone) ||
                                  ""}
                              </p>
                            </div>
                          )}
                      </div>
                    </div>
                  </PopoverBody>
                </Popover>
              )}

              {accountId &&
                accountDetails &&
                ((salesforceAccountDetails &&
                  salesforceAccountDetails.opportunityCount > 0 &&
                  salesforceAccountDetails.partnerAccountCount > 0) ||
                  (referralDetails.salesforce_opportunity_id &&
                    salesforceAccountDetails.partnerAccountCount > 0)) && (
                  <Popover
                    placement="left"
                    isOpen={opportunityPopoverOpen}
                    target="viewOpportunityInSalesforce"
                    toggle={toggleOpportunity}
                    className="account-in-salesforce"
                  >
                    <PopoverBody>
                      <div className="p-2" ref={popOverRef}>
                        <div className="row">
                          <div className="col-md-12 d-flex bd-highlight mb-2">
                            <div
                              className="ml-auto cursor-pointer"
                              onClick={() => toggleOpportunity()}
                            >
                              <CrossIcon />
                            </div>
                          </div>

                          {accountDetails && accountDetails.name && (
                            <div className="col-md-12 mb-2">
                              <a
                                target="_blank"
                                href={`${salesforceAccountDetails.instanceUrl}/lightning/r/${accountDetails.id}/view`}
                                className="font-weight-bold"
                                rel="noopener noreferrer"
                              >
                                {accountDetails && accountDetails.name}
                              </a>
                            </div>
                          )}

                          {accountDetails && accountDetails.type && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Type
                              </label>
                              <p className="font-weight-bold">
                                {accountDetails && accountDetails.type}
                              </p>
                            </div>
                          )}

                          {accountDetails && accountDetails.status && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Status
                              </label>
                              <p className="font-weight-bold">
                                {(accountDetails && accountDetails.status) ||
                                  ""}
                              </p>
                            </div>
                          )}

                          {accountDetails && accountDetails.accountOwner && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Account Owner
                              </label>
                              <p className="font-weight-bold">
                                {(accountDetails &&
                                  accountDetails.accountOwner) ||
                                  ""}
                              </p>
                            </div>
                          )}

                          {accountDetails &&
                            accountDetails.billingAddressCountry && (
                              <div className="col-md-12">
                                <label className="text-grayed font-weight-bold font-size-13">
                                  Billing Address
                                </label>
                                <p className="font-weight-bold">
                                  {(accountDetails &&
                                    accountDetails.billingAddressCountry) ||
                                    ""}
                                </p>
                              </div>
                            )}

                          {salesforceAccountDetails &&
                            salesforceAccountDetails.opportunityCount >= 1 && (
                              <div className="">
                                <hr
                                  style={{
                                    width: "100%",
                                    borderColor: "#f3f3f4"
                                  }}
                                />

                                <div className="d-flex bd-highlight mb-3">
                                  <div className="mr-auto p-2 font-weight-bold">
                                    Opportunities{" "}
                                    {salesforceAccountDetails.opportunityCount
                                      ? `(${salesforceAccountDetails.opportunityCount})`
                                      : ""}
                                  </div>
                                  {salesforceAccountDetails.opportunityCount >
                                    1 && (
                                    <div className="p-2 font-weight-bold">
                                      <a
                                        target="_blank"
                                        href={`${
                                          salesforceAccountDetails.instanceUrl
                                        }/_ui/search/ui/UnifiedSearchResults?searchType=2&sen=005&sen=006&str=${accountDetails.name
                                          .split(" ")
                                          .join("+")}`}
                                        className="font-weight-bold"
                                        rel="noopener noreferrer"
                                      >
                                        View All
                                      </a>
                                    </div>
                                  )}
                                </div>

                                <div
                                  className="overflow-auto"
                                  style={{ height: "14rem" }}
                                >
                                  {salesforceAccountDetails.opportunityList &&
                                    salesforceAccountDetails.opportunityList
                                      .length > 0 &&
                                    salesforceAccountDetails.opportunityList.map(
                                      data => {
                                        return (
                                          <div className="opportunity-list p-3 col-md-12  border-bottom">
                                            <div className="mb-2">
                                              <a
                                                target="_blank"
                                                href={`${salesforceAccountDetails.instanceUrl}/lightning/r/${data.Id}/view`}
                                                className="font-weight-bold"
                                                rel="noopener noreferrer"
                                              >
                                                {data.Name}
                                              </a>
                                            </div>

                                            <div className="row">
                                              <div className="col-md-6">
                                                <label className="text-grayed font-weight-bold font-size-13">
                                                  Stage
                                                </label>
                                                <p className="font-weight-bold">
                                                  {(data && data.StageName) ||
                                                    ""}
                                                </p>
                                              </div>

                                              <div className="col-md-6">
                                                <label className="text-grayed font-weight-bold font-size-13">
                                                  Owner
                                                </label>
                                                <p className="font-weight-bold">
                                                  {data && data.OwnerId
                                                    ? getOwnerDetails(
                                                        data.OwnerId
                                                      )
                                                    : ""}
                                                </p>
                                              </div>

                                              <div className="col-md-6">
                                                <label className="text-grayed font-weight-bold font-size-13">
                                                  Close Date
                                                </label>
                                                <p className="font-weight-bold">
                                                  {data &&
                                                    formatDate(data.CloseDate)}
                                                </p>
                                              </div>

                                              <div className="col-md-6">
                                                <label className="text-grayed font-weight-bold font-size-13">
                                                  Created Date
                                                </label>
                                                <p className="font-weight-bold">
                                                  {data &&
                                                    data.CreatedDate &&
                                                    formatDate(
                                                      data.CreatedDate
                                                    )}
                                                </p>
                                              </div>

                                              <div className="opportunity-list pb-2">
                                                {data.linkOpportunity &&
                                                  salesforceAccountDetails.opportunityList &&
                                                  salesforceAccountDetails
                                                    .opportunityList.length >
                                                    0 &&
                                                  referralDetails &&
                                                  !referralDetails.salesforce_opportunity_id && (
                                                    <Link
                                                      to="#"
                                                      className="text-underline"
                                                      style={{
                                                        marginLeft: 70
                                                      }}
                                                      onClick={e => {
                                                        e.preventDefault();
                                                        toggleOpportunity();
                                                        linkOpportunity(
                                                          data.Id
                                                        );
                                                      }}
                                                    >
                                                      Link this Opportunity{" "}
                                                    </Link>
                                                  )}
                                              </div>
                                            </div>
                                          </div>
                                        );
                                      }
                                    )}
                                </div>
                              </div>
                            )}
                        </div>
                      </div>
                    </PopoverBody>
                  </Popover>
                )}

              {referralDetails &&
                ownerSalesforceId &&
                enableSalesRouting == "true" && (
                  <Popover
                    placement="left"
                    isOpen={opportunityOwnerPopoverOpen}
                    target="viewOpportunityOwner"
                    toggle={toggleOpportunityOwner}
                    className="account-in-salesforce"
                  >
                    <PopoverBody>
                      <div className="p-2" ref={popOverRef}>
                        <div className="row">
                          <div className="col-md-12 d-flex bd-highlight mb-2">
                            <div
                              className="ml-auto cursor-pointer"
                              onClick={() => toggleOpportunityOwner()}
                            >
                              <CrossIcon />
                            </div>
                          </div>

                          {selectedOwner &&
                            (selectedOwner.first_name ||
                              selectedOwner.last_name) && (
                              <div className="col-md-12 mb-2">
                                <a
                                  target="_blank"
                                  href={`${salesforceAccountDetails.instanceUrl}/lightning/r/${selectedOwner.salesforce_id}/view`}
                                  className="font-weight-bold"
                                  rel="noopener noreferrer"
                                >
                                  {selectedOwner && selectedOwner.first_name
                                    ? selectedOwner.first_name
                                    : "" + " " + selectedOwner.last_name
                                    ? selectedOwner.last_name
                                    : ""}
                                </a>
                              </div>
                            )}

                          {selectedOwner &&
                            (selectedOwner.first_name ||
                              selectedOwner.last_name) && (
                              <div className="col-md-12">
                                <label className="text-grayed font-weight-bold font-size-13">
                                  Name
                                </label>
                                <p className="font-weight-bold">
                                  {selectedOwner &&
                                    selectedOwner.first_name +
                                      " " +
                                      selectedOwner.last_name}{" "}
                                </p>
                              </div>
                            )}

                          {selectedOwner && selectedOwner.email && (
                            <div className="col-md-12">
                              <label className="text-grayed font-weight-bold font-size-13">
                                Email
                              </label>
                              <p className="font-weight-bold">
                                {(selectedOwner && selectedOwner.email) || ""}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </PopoverBody>
                  </Popover>
                )}
            </div>
          )}
        </div>
      </SidebarItem>
    </>
  );
};

export default SalesforceTasks;
