import React from "react";

// Components
import Form from "../../../../../components/base/Form";
import DefaultContent from "../../../../../components/content/DefaultContent";
import TextArea from "../../../../../components/base/TextArea";
import SubTitle from "../../../../../components/base/SubTitle";
import SingleCheckbox from "../../../inner-component/Checkbox";
import SaveButton from "../../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_RAMBLE_CHAT_BOT_SCRIPT,
  SETTINGS_RAMBLE_CHAT_ENABLE_ADMIN,
  SETTINGS_RAMBLE_CHAT_ENABLE_CUSTOMER,
  SETTINGS_RAMBLE_CHAT_ENABLE_EXPERT,
  SETTINGS_RAMBLE_CHAT_ENABLE_GUEST,
  SETTINGS_RAMBLE_CHAT_ENABLE_PARTNER
} from "../../../../../setting/Constants";

const RambleChatSection = props => {
  const { adminSettings, saveSettings } = props;

  const {
    ramble_chat_bot_script,
    enable_ramble_chat_admin,
    enable_ramble_chat_customer,
    enable_ramble_chat_partner,
    enable_ramble_chat_expert,
    enable_ramble_chat_guest
  } = adminSettings;

  // Google Analytics Section Initial Values
  const rambleChatInitialValues = {
    ramble_chat_bot_script,
    enable_ramble_chat_admin: enable_ramble_chat_admin === "true",
    enable_ramble_chat_customer: enable_ramble_chat_customer === "true",
    enable_ramble_chat_partner: enable_ramble_chat_partner === "true",
    enable_ramble_chat_expert: enable_ramble_chat_expert === "true",
    enable_ramble_chat_guest: enable_ramble_chat_guest === "true"
  };

  // Ramble Chat Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Marketplace Footer Ramble Chat Bot Script
    if (values.ramble_chat_bot_script !== undefined) {
      data.append(
        "ramble_chat_bot_script",
        values.ramble_chat_bot_script ? values.ramble_chat_bot_script : ""
      );
    }

    if (values.enable_ramble_chat_admin !== undefined) {
      data.append("enable_ramble_chat_admin", values.enable_ramble_chat_admin);
    }

    if (values.enable_ramble_chat_customer !== undefined) {
      data.append(
        "enable_ramble_chat_customer",
        values.enable_ramble_chat_customer
      );
    }

    if (values.enable_ramble_chat_partner !== undefined) {
      data.append(
        "enable_ramble_chat_partner",
        values.enable_ramble_chat_partner
      );
    }

    if (values.enable_ramble_chat_expert !== undefined) {
      data.append(
        "enable_ramble_chat_expert",
        values.enable_ramble_chat_expert
      );
    }

    if (values.enable_ramble_chat_guest !== undefined) {
      data.append("enable_ramble_chat_guest", values.enable_ramble_chat_guest);
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={rambleChatInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent title="Chat Bot Settings">
        {/* Footer Chat Bot Script */}
        <h5 className="font-weight-bold">Ramble Chat Bot Script</h5>
        <div className="field-wrapper">
          <TextArea
            name={SETTINGS_RAMBLE_CHAT_BOT_SCRIPT}
            placeholder="Enter Footer Chat Bot Script"
            rows="8"
          />
        </div>

        <div className="form-wrapper mt-4">
          <SubTitle label="Enable Ramble Chat Bot for" />

          {/* Enable Chat Bot for Admin*/}
          <div className="field-wrapper mb-3">
            <SingleCheckbox
              name={SETTINGS_RAMBLE_CHAT_ENABLE_ADMIN}
              label="Admin Users"
              className="accepted-terms mb-0 pb-0 mr-3"
            />
          </div>
          {/* Enable Chat Bot for Customer*/}
          <div className="field-wrapper mb-3">
            <SingleCheckbox
              name={SETTINGS_RAMBLE_CHAT_ENABLE_CUSTOMER}
              label="Customer Users"
              className="accepted-terms mb-0 pb-0 mr-3"
            />
          </div>
          {/* Enable Chat Bot for Expert*/}
          <div className="field-wrapper">
            <SingleCheckbox
              name={SETTINGS_RAMBLE_CHAT_ENABLE_EXPERT}
              label="Expert Users"
              className="accepted-terms mb-0 pb-0 mr-3"
            />
          </div>
          {/* Enable Chat Bot for Guest*/}
          <div className="field-wrapper">
            <SingleCheckbox
              name={SETTINGS_RAMBLE_CHAT_ENABLE_GUEST}
              label="Guest Users"
              className="accepted-terms mb-0 pb-0 mr-3"
            />
          </div>
          {/* Enable Chat Bot for Partner*/}
          <div className="field-wrapper mb-3">
            <SingleCheckbox
              name={SETTINGS_RAMBLE_CHAT_ENABLE_PARTNER}
              label="Partner Users"
              className="accepted-terms mb-0 pb-0 mr-3"
            />
          </div>
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default RambleChatSection;
