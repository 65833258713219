//Lead page
export const RESOURCE_PUBLISHED = "Published";
export const RESOURCE_DRAFT = "Draft";

// Partner Permission
export const PARTNER_PERMISSION_ALL_PARTNER = "All Partners";
export const PARTNER_PERMISSION_FILTERED_PARTNERS = "Selected Partners";
export const PARTNER_PERMISSION_INDIVIDUAL_PARTNER = "Individual Partner";

export const PARTNER_PERMISSION_ALL_PARTNER_VALUE = 1;
export const PARTNER_PERMISSION_FILTERED_PARTNERS_VALUE = 2;
export const PARTNER_PERMISSION_INDIVIDUAL_PARTNER_VALUE = 3;

// Tabs
export const TAB_BASICS = "Basics";
export const TAB_PERMISSIONS = "Permissions";

// Resource Source
export const RESOURCE_SOURCE_NEW_RESOURCE = "New Resource";
export const RESOURCE_SOURCE_EXISTING_RESOURCE =
  "Reference to Existing Resource";
export const RESOURCE_WEB_PAGE = "Web Page";
export const RESOURCE_VIDEO = "Video";
