import React from "react";

// Configs
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";
import { isLoggedIn } from "../../lib/helper";

// Components
import NoRecordsFound from "../../components/base/NoRecordsFound";
import PageSearch from "../../components/base/PageSearch";
import PageTitle from "../../components/base/PageTitle";
import ProjectsList from "../projects/inner-components/ProjectsList";
import SelectDropdown from "../../components/base/SelectDropdown";
import Spinner from "../../components/base/Spinner";

import {
  PROJECT_SORTBY_DATE_MODIFIED,
  PROJECT_USER_STATUS_NO_RESPONSE,
  PROJECT_USER_STATUS_INTERESTED,
  PROJECT_USER_STATUS_WAITING_FOR_APPROVAL,
  PROJECT_USER_STATUS_ACCEPTED
} from "../../project/Constants";

//Assets
import projectsIcon from "../../assets/img/icons/nav-icon-projects.svg";
import Hint from "../../components/base/Hint";

class PotentialProjects extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      isLoading: false,
      searchParam: "",
      searchCount: 0,
      sortByOptions: [
        {
          value: "createdAt",
          label: "Most Recent Activity"
        },
        {
          value: "updatedAt",
          label: "Date Modified"
        }
      ],
      selectedSortOption: "Most Recent Activity",
      expertPotentialProjectCount: 0,
      expertPotentialProjects: [],
      projectUserStatus: `${PROJECT_USER_STATUS_NO_RESPONSE}, ${PROJECT_USER_STATUS_INTERESTED}, ${PROJECT_USER_STATUS_WAITING_FOR_APPROVAL}, ${PROJECT_USER_STATUS_ACCEPTED}`
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();

    this._getProjectList();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  // Search project
  _handleInputChange = e => {
    this.setState(
      {
        searchParam: e.target.value
      },
      () => {
        this.setState({ isLoading: true }, () => {
          apiClient
            .get(
              `${endpoints().projectAPI}?search=${encodeURIComponent(
                this.state.searchParam
              )}&projectUserStatus=${this.state.projectUserStatus}`
            )
            .then(response => {
              const data = response.data;
              this.setState({
                isLoading: false,
                expertPotentialProjects: !data ? [] : data,
                expertPotentialProjectCount: data.totalCount,
                searchCount: data.totalCount
              });
            });
        });
      }
    );
  };

  //Get potential project
  _getProjectList() {
    this.setState({ isLoading: true }, () => {
      apiClient
        .get(
          `${endpoints().projectAPI}?projectUserStatus=${
            this.state.projectUserStatus
          }`
        )
        .then(response => {
          const data = response.data;
          const totalCount = data.totalCount;
          this.setState({
            isLoading: false,
            expertPotentialProjectCount: totalCount,
            expertPotentialProjects: !data ? [] : data
          });
        });
    });
  }

  // Sort by option
  getSortByOptions(value) {
    let sortBy = "createdAt";
    if (value === PROJECT_SORTBY_DATE_MODIFIED) {
      sortBy = "updatedAt";
    }

    this.setState({ isLoading: true }, () => {
      apiClient
        .get(
          `${endpoints().projectAPI}?sort=${sortBy}&projectUserStatus=${
            this.state.projectUserStatus
          }&sortDir=DESC`
        )
        .then(response => {
          const data = response.data;
          const {
            activeProjectsCount,
            completedProjectsCount,
            totalCount
          } = data;
          const projectDetails = data;
          this.setState({
            isLoading: false,
            projectDetails: projectDetails,
            totalCount: totalCount,
            activeProjectsCount: activeProjectsCount,
            completedProjectsCount: completedProjectsCount
          });
        });
    });
  }

  handleSortByChange = value => {
    this.getSortByOptions(value);
  };

  render() {
    const {
      searchCount,
      searchParam,
      sortByOptions,
      selectedSortOption,
      isLoading,
      expertPotentialProjects,
      expertPotentialProjectCount
    } = this.state;

    return (
      <>
        <PageTitle label="Potential Projects" />
        <Hint
          id="requirements"
          hintText="Potential project mean that you have been selected as a possible candidate among others and can let us know if you are interested or not."
        />
        <div className="page-heading mt-3 d-flex justify-content-between align-items-start cover flex-md-row flex-column">
          <PageSearch
            classnames="page-search"
            placeholder="Search"
            onChange={this._handleInputChange.bind(this)}
          />

          <SelectDropdown
            buttonLabel={selectedSortOption}
            dropdownLinks={sortByOptions}
            color={"gray"}
            hideCaret
            selectName={"sortby_projects"}
            handleChange={this.handleSortByChange}
          />
        </div>
        {isLoading ? (
          <Spinner />
        ) : expertPotentialProjectCount ? (
          <ProjectsList
            data={expertPotentialProjects}
            history={this.props.history}
            pagination={true}
          />
        ) : searchParam ? (
          !searchCount ? (
            <NoRecordsFound
              icon={projectsIcon}
              message="No Projects Yet"
              description="Once a customer requests a project that you may be a good fit for, it will show up here"
            />
          ) : (
            ""
          )
        ) : (
          <NoRecordsFound
            icon={projectsIcon}
            message="No Projects Yet"
            description="Once a customer requests a project that you may be a good fit for, it will show up here"
          />
        )}
      </>
    );
  }
}

export default PotentialProjects;
