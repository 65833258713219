import React from "react";
import UpdateProjectForm from "./updateProjectForm";

// Components
import PageTitle from "../../components/base/PageTitle";

const ProjectEdit = props => {
  return (
    <div>
      <PageTitle label="Edit Project" />
      <UpdateProjectForm history={props.history} match={props.match} />
    </div>
  );
};

export default ProjectEdit;
