import React from "react";
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";

//Assets
import {
  ChevronRight,
  SettingsSalesforceIcon,
  SettingsHubspotIcon
} from "../../../../assets/img/icons";

// Constants
import {
  INTEGRATION_CRMS_SUB_TAB,
  TAB_SALESFORCE_SETTINGS,
  TAB_INTEGRATION,
  TAB_HUBSPOT_SETTING
} from "../../../../setting/Constants";
import SalesforceSection from "./sections/SalesforceSection";
import HubspotSection from "./sections/HubspotSection";

const CRMsSection = props => {
  const {
    activeSection,
    adminSettings,
    saveSettings,
    salesforceSettings,
    history,
    AccountObjectFieldList,
    OpportunityObjectFieldList,
    connectHubspot,
    HubSpotFieldList
  } = props;

  return (
    <>
      {!activeSection && (
        <>
          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_CRMS_SUB_TAB}/${TAB_SALESFORCE_SETTINGS}`}
            className="onboarding-link"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="step-count d-block completed">
                  <SettingsSalesforceIcon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">Salesforce</h5>
              <p className="font-weight-bold text-inline-grayed">
                Two way sync of pipeline and partner data
              </p>
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>
          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_CRMS_SUB_TAB}/${TAB_HUBSPOT_SETTING}`}
            className="onboarding-link mt-2"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="completed">
                  <SettingsHubspotIcon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">Hubspot</h5>
              <p className="font-weight-bold text-inline-grayed">
                Two way sync of pipeline and partner data
              </p>
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>
        </>
      )}

      <TabContent activeTab={activeSection}>
        <TabPane tabId={TAB_SALESFORCE_SETTINGS} className="w-100">
          <SalesforceSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            salesforceSettings={salesforceSettings}
            history={history}
            AccountObjectFieldList={AccountObjectFieldList}
            OpportunityObjectFieldList={OpportunityObjectFieldList}
          />
        </TabPane>
      </TabContent>
      <TabContent activeTab={activeSection}>
        <TabPane tabId={TAB_HUBSPOT_SETTING} className="w-100">
          <HubspotSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
            connectHubspot={connectHubspot}
            HubSpotFieldList={HubSpotFieldList}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default CRMsSection;
