import React from "react";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";

// Icons
import { BlueMessageIcon, CrossIcon } from "../../assets/img/icons";

// Components
import Text from "../../components/base/Text";
import TextArea from "../../components/base/TextArea";
import SecondaryButton from "../../components/base/SecondaryButton";
import Avatar from "../../components/base/Avatar";
import { apiClient } from "../../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../../configs";
import { TAG_TYPE_EXPERT_ROLE } from "../../tagType/Constants";
import { TORCHLITE } from "../../marketplaceId/Constants";
import { isBadRequest } from "../../common/http";

class TellUsAboutYourself extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: "",
      src: null,
      crop: {
        unit: "%",
        width: 80,
        height: 80
      },
      zoom: 1,
      email: "",
      closePreviewImage: false,
      expertTypeList: "",
      required: false
    };

    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  componentDidMount() {
    this.getTagValues(TAG_TYPE_EXPERT_ROLE);
  }

  // Handle image upload
  handleImageUpload() {
    this.setState({ closePreviewImage: false });
    this.props.fileUpload({
      file: this.state.file,
      email: this.state.email
    });
  }

  // Select the uploaded image
  onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        this.setState({ src: reader.result, closePreviewImage: true })
      );
      reader.readAsDataURL(e.target.files[0]);
    }
  };

  // Load the initial crop screen
  onImageLoaded = image => {
    this.imageRef = image;
  };

  // Get crop value from the preview image
  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  // Move the crop the crop selector
  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  // Make the crop the image in preview view
  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  // Get Cropped image size
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Convert URL to base64 data
    const base64Image = canvas.toDataURL("image/jpeg");

    if (base64Image) {
      this.setState({ file: base64Image, email: this.props.email });
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");

      canvas.toBlob(file => {
        var ImageURL = this.state.src;
        var block = ImageURL.split(";");

        file.name = fileName;
        resolve(file);
      }, "image/jpeg");
    });
  }

  // Get Tag Values
  getTagValues = tagType => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .get(`${endpoints().publicTagAPI}?tagType=${tagType}`)
      .then(response => {
        this.setState({
          expertTypeList: response.data.data,
          loading: true
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get expert tag options
  getExpertTypeOptions = () => {
    const { expertTypeList } = this.state;
    let experts = [];

    if (expertTypeList) {
      expertTypeList.forEach(expert => {
        experts.push({ label: expert.name, value: expert.id });
      });
    }

    return experts;
  };

  render() {
    const {
      currentStep,
      step,
      firstName,
      lastName,
      prev,
      next,
      marketplaceName
    } = this.props;

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    const {
      crop,
      croppedImageUrl,
      src,
      closePreviewImage,
      required
    } = this.state;

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <BlueMessageIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-3">
            Tell us about yourself!
          </h2>
        </div>
        <div className="content mt-4">
          <div className="upload-image d-flex align-items-center justify-content-start mb-4 pb-2">
            <label
              className="position-relative profilePicPreview mb-0 mr-4 flex-shrink-0"
              htmlFor="profilePicture"
            >
              {croppedImageUrl ? (
                <img
                  alt="profile"
                  src={croppedImageUrl}
                  className="profile-picture-preview rounded-circle"
                />
              ) : (
                <Avatar
                  firstName={firstName}
                  lastName={lastName}
                  customSize={104}
                  fontSize={32}
                  className="avatar-placeholder"
                  customStyle="pt-0"
                />
              )}
            </label>
            <div className="upload-field">
              <input
                name="file"
                className="form-control d-none"
                type="file"
                id="profilePicture"
                placeholder="Profile Photo"
                onChange={this.onSelectFile}
                accept="image/*"
              />

              <label
                htmlFor="profilePicture"
                className="profile-img-sm d-block mb-3"
              >
                <span className="btn btn-outline-dark cursor-pointer">
                  Upload a Photo
                </span>
              </label>
              <span className="info h7 d-block">
                Images should be at least 200x200px and not exceed 10MB.
              </span>
            </div>
          </div>
          {closePreviewImage && src && (
            <div className="crop-modal">
              <div className="backdrop" />
              <div className="crop-modal-body">
                <div
                  className="crop-modal-close"
                  onClick={() => this.setState({ closePreviewImage: false })}
                >
                  <CrossIcon />
                </div>
                <h5 className="mb-3">
                  <b>Crop image and upload</b>
                </h5>
                <ReactCrop
                  src={src}
                  crop={crop}
                  ruleOfThirds
                  onImageLoaded={this.onImageLoaded}
                  onComplete={this.onCropComplete}
                  onChange={this.onCropChange}
                />

                <div className="mt-4 d-flex justify-content-center">
                  <button
                    className="btn btn-primary"
                    onClick={this.handleImageUpload}
                  >
                    Upload Profile Photo
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="form-wrapper">
            <div className="field-wrapper">
              <Text
                name="jobTitle"
                label={`Briefly describe what kind of expert you are. This title displays when you show up in ${marketplaceName} Directory results.`}
                placeholder="Expert Title (e.g. Graphic Designer, Copywriter, etc)..."
                error="Job title is required"
                {...{ required }}
                maxLength={50}
                showCharCount={true}
              />
            </div>
            <div className="field-wrapper">
              <TextArea
                name="description"
                label="Tell us more about you, your philosophy, and why you're the best. This is your personal statement, so be thorough!"
                placeholder="Tell us more..."
                rows="5"
                error="Description is required"
                {...{ required }}
              />
            </div>
          </div>

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={() => {
                this.setState({ required: true });
                return next;
              }}
            >
              Next: Top Skills
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default TellUsAboutYourself;
