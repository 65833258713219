import React, { Component } from "react";

// Components
import Text from "../../../components/base/Text";
import Email from "../../../components/base/Email";
import Phone from "../../../components/base/Phone";

class ContactFormFields extends Component {
  // Render the form fields
  render() {
    return (
      <div>
        <div className="card bg-white mt-3 mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div>
                  <Text
                    name="firstName"
                    label="First Name"
                    placeholder="Enter First Name"
                    error=""
                    required={true}
                  />
                </div>
                <div>
                  <Text
                    name="lastName"
                    label="Last Name"
                    placeholder="Enter Last Name"
                    error=""
                    required={true}
                  />
                </div>
                <div>
                  <Email
                    name="email"
                    label="Email"
                    placeholder="Enter Email"
                    error=""
                    required={true}
                  />
                </div>
                <div>
                  <Phone
                    name="phone"
                    label="Phone Number"
                    placeholder="Enter phone number"
                    error=""
                    required
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactFormFields;
