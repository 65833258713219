import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import toast from "../components/base/Toast";
import { fetchList } from "./table";
import { isBadRequest } from "../common/http";
import HubspotSyncRuleService from "../services/HubspotSyncRuleService";
/**
 * Delete hubspot sync rule
 *
 * @param id
 * @returns {function(*): *}
 */
export function deleteHubspotSyncRule(id, params) {
  return dispatch => {
    HubspotSyncRuleService.delete(id)
      .then(() => {
        dispatch(
          fetchList(
            "hubspotSyncRule",
            `${endpoints().hubspotSyncRule}`,
            1,
            10,
            { ...params, pagination: true }
          )
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}
/**
 * Create hubspot sync rule
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function addHubspotSyncRule(data, params, toggle) {
  return dispatch => {
    HubspotSyncRuleService.create(data)
      .then(() => {
        toggle();
        dispatch(
          fetchList(
            "hubspotSyncRule",
            `${endpoints().hubspotSyncRule}`,
            1,
            10,
            { ...params, pagination: true }
          )
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Update hubspot sync rule
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateHubspotSyncRule(id, data, params, toggle) {
  return dispatch => {
    HubspotSyncRuleService.update(id, data)
      .then(() => {
        toggle();
        dispatch(
          fetchList(
            "hubspotSyncRule",
            `${endpoints().hubspotSyncRule}`,
            1,
            10,
            params
          )
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}
