import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from "classnames";

// Constants
import { TAB_BASICS, TAB_PERMISSIONS } from "../../resource/Constants";

// Components
import Text from "../../components/base/Text";
import ColorPicker from "../../components/base/ColorPicker";
import IconPicker from "../../components/base/IconPicker";
import GridCard from "../../components/base/GridCard";
import DragAndDropField from "../../components/base/DragAndDropField";
import Hint from "../../components/base/Hint";
import TabsAndContent from "../../components/base/TabsAndContent";
import SingleCheckbox from "../../components/base/SingleCheckbox";
import ToggleSwitch from "../../components/base/ToggleSwitch";
import PreviewImage from "../../components/base/PreviewImage";
import Radio from "../../components/base/Radio";
import TextArea from "../../components/base/TextArea";
import ReactCrop from "react-image-crop";
import MultiselectCreatable from "../../components/base/MultiselectCreatable";

// Assets
import { CrossIcon, MaximizeAltIcon } from "../../assets/img/icons";
import {
  PARTNER_PERMISSION_ALL_PARTNER,
  RESOURCE_SOURCE_NEW_RESOURCE,
  RESOURCE_SOURCE_EXISTING_RESOURCE,
  RESOURCE_WEB_PAGE,
  RESOURCE_VIDEO
} from "../../resource/Constants";
import MultiSelect from "../../components/base/Multiselect";
import PartnerSelector from "../../../src/partner/component/PartnerSelector";
import ReactPlayer from "react-player";
import Video from "../../lib/Video";
import VideoPlayer from "../../components/VideoPlayer";

class ResourceFormFields extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      title: "",
      description: "",
      fileSize: "",
      file: "",
      bannerImageUrl: "",
      loading: false,
      activeTab: TAB_BASICS,
      values: [],
      fileTypeError: false,
      spotlightfileTypeError: false,
      cardTitleShow: false,
      crop: {
        unit: "%",
        width: 100,
        height: 100
      },
      src: null,
      checkBox: {
        partnerTier:
          (this.props.checkboxFieldValue &&
            this.props.checkboxFieldValue.partnerTier) ||
          [],
        partnerType:
          (this.props.checkboxFieldValue &&
            this.props.checkboxFieldValue.partnerType) ||
          [],
        showPartnerSpotlight: props.showPartnerSpotlight || false,
        sharingPermission: props.sharingPermission
          ? props.sharingPermission
          : PARTNER_PERMISSION_ALL_PARTNER,
        resourceSource:
          props.webPageUrl || props.videoEmbedCode
            ? RESOURCE_SOURCE_EXISTING_RESOURCE
            : RESOURCE_SOURCE_NEW_RESOURCE,
        existingResourceSource: props.webPageUrl
          ? RESOURCE_WEB_PAGE
          : props.videoEmbedCode
          ? RESOURCE_VIDEO
          : "",
        closePreviewImage: false,
        croppedImageUrl: "",
        bannerImageUrl: ""
      }
    };
    this._handleChange = this._handleChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handleImageUpload = this.handleImageUpload.bind(this);
  }

  // Handle OnChange resource title field
  _handleChange = e => {
    const target = e.target;
    const title = target.value;
    const description = target.value;
    this.setState({
      title: title,
      cardTitleShow: true,
      description: description
    });
  };

  handleCroppedImageRemove = () => {
    this.state.userDetails.avatarUrl = "";
    this.state.userDetails.avatar = "";
    this.state.files = "";
    this.setState({
      croppedImageUrl: ""
    });
    this._deleteUserProfile({
      userId: this.state.userId
    });
  };

  // Toggle Tab
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab }, () => {
        window.localStorage.setItem(
          "partnerResourceActiveTab",
          this.state.activeTab
        );
      });
    }
  };
  // Handle image upload
  handleImageUpload() {
    this.setState({ closePreviewImage: false });
    this.props.bannerImageUpload(this.state.file, this.state.targetFile);
  }

  // Select the uploaded image
  onSelectFile = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fSize = targetFile.files.item(0).size;

      const fileSize = Math.round(fSize / 1024);
      this.setState({
        fileSize,
        bannerImageUrl: URL.createObjectURL(targetFile.files[0])
      });

      if (fileSize < 10240) {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          this.setState({ src: reader.result, closePreviewImage: true })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  // Load the initial crop screen
  onImageLoaded = image => {
    this.imageRef = image;
  };

  // Get crop value from the preview image
  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  // Move the crop the crop selector
  onCropChange = crop => {
    // You could also use percentCrop:
    this.setState({ crop });
  };

  // Make the crop the image in preview view
  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ bannerImageUrl: croppedImageUrl });
    }
  }

  // Get Cropped image size
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext("2d");
    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );
    // Convert URL to base64 data
    const base64Image = canvas.toDataURL("image/jpeg");

    if (base64Image) {
      this.setState({ file: base64Image });
    }

    return new Promise(resolve => {
      canvas.toBlob(blob => {
        if (!blob) {
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");

      canvas.toBlob(file => {
        file.name = fileName;
        resolve(file);
      }, "image/jpeg");
    });
  }
  // Select the uploaded image
  onSpotlightSelectFile = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
          this.setState({
            spotlightFileTypeError: false
          });
          this.props.handleSpotlightImageChange(e);
          break;
        default:
          this.setState({
            spotlightFileTypeError: true
          });
          break;
      }
    }
  };

  handlePartnerTierCheckbox = e => {
    const { getCheckboxFieldValue, updatePermission } = this.props;
    const newSelection = e.target.value;
    let newSelectionArray;
    const { partnerType } = this.state.checkBox;

    if (this.state.checkBox.partnerTier.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.partnerTier.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [...this.state.checkBox.partnerTier, newSelection];
    }

    this.setState(
      prevState => ({
        checkBox: {
          ...prevState.checkBox,
          partnerTier: newSelectionArray
        }
      }),
      () => {
        getCheckboxFieldValue && getCheckboxFieldValue(this.state.checkBox);
        updatePermission &&
          updatePermission(
            partnerType.length === 0 && newSelectionArray.length === 0
              ? true
              : false
          );
      }
    );
  };

  handlePartnerTypeCheckbox = e => {
    const { getCheckboxFieldValue, updatePermission } = this.props;
    const newSelection = e.target.value;
    let newSelectionArray;
    const { partnerTier } = this.state.checkBox;

    if (this.state.checkBox.partnerType.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.partnerType.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [...this.state.checkBox.partnerType, newSelection];
    }

    this.setState(
      prevState => ({
        checkBox: {
          ...prevState.checkBox,
          partnerType: newSelectionArray
        }
      }),
      () => {
        getCheckboxFieldValue && getCheckboxFieldValue(this.state.checkBox);
      }
    );
    updatePermission &&
      updatePermission(
        partnerTier.length === 0 && newSelectionArray.length === 0
          ? true
          : false
      );
  };

  handlePartnerSpotlight = () => {
    const { showPartnerSpotlight } = this.state.checkBox;
    const { getCheckboxFieldValue } = this.props;

    this.setState(
      prevState => ({
        checkBox: {
          ...prevState.checkBox,
          showPartnerSpotlight: !showPartnerSpotlight
        }
      }),
      () => {
        getCheckboxFieldValue && getCheckboxFieldValue(this.state.checkBox);
      }
    );
  };

  handlePartnerPermission = e => {
    const permission = e;
    const { getCheckboxFieldValue } = this.props;

    this.setState(
      prevState => ({
        checkBox: {
          ...prevState.checkBox,
          sharingPermission: permission,
          partnerTier: [],
          partnerType: []
        }
      }),
      () => {
        getCheckboxFieldValue && getCheckboxFieldValue(this.state.checkBox);
      }
    );
  };

  handleResourceSource = e => {
    const resourceSource = e.target.value;
    const { getCheckboxFieldValue, clearResourceFile } = this.props;

    let existingResourceSource =
      this.state.checkBox.existingResourceSource || RESOURCE_WEB_PAGE;

    if (resourceSource === RESOURCE_SOURCE_NEW_RESOURCE) {
      existingResourceSource = "";
    }

    this.setState(
      prevState => ({
        checkBox: {
          ...prevState.checkBox,
          resourceSource,
          existingResourceSource
        }
      }),
      () => {
        getCheckboxFieldValue && getCheckboxFieldValue(this.state.checkBox);
        clearResourceFile && clearResourceFile();
      }
    );
  };

  handleExistingResource = e => {
    const existingResourceSource = e.target.value;
    const { getCheckboxFieldValue } = this.props;

    this.setState(
      prevState => ({
        checkBox: {
          ...prevState.checkBox,
          existingResourceSource
        }
      }),
      () => {
        getCheckboxFieldValue && getCheckboxFieldValue(this.state.checkBox);
      }
    );
  };

  handleChangePartnerSelection = value => {
    const { updatePermission } = this.props;
    updatePermission &&
      updatePermission(value && value !== null ? false : true);
  };

  //Get Category Tag Value
  getTagInitialValues = (resourceCategory, category) => {
    const selectedTags = [];
    const tagInitialValues = {};
    if (resourceCategory && resourceCategory.length > 0) {
      resourceCategory.forEach(tagType => {
        if (category && category.length > 0)
          category.forEach(tag => {
            if (tagType.tag_id === tag.id) selectedTags.push(tag);
          });
      });
      tagInitialValues["category"] = selectedTags;
    }
    if (this.props.handleCategoryValue) {
      this.props.handleCategoryValue(tagInitialValues);
    }
    return tagInitialValues;
  };

  // Render the form fields
  render() {
    const resourceData = this.props.data;
    const { settings } = this.props;

    const {
      category,
      resourceCategory,
      resourceFile,
      spotlightImagePathUrlPreview,
      spotlightImagePathUrl,
      handleSpotlightImageRemove
    } = this.props;
    const { crop, croppedImageUrl, src, fileSize } = this.state;
    const data = {
      title:
        !this.state.title && resourceData && resourceData.title
          ? this.state.cardTitleShow
            ? this.state.title
            : resourceData.title
          : this.state.title,
      description:
        !this.state.description && resourceData && resourceData.description
          ? this.state.cardTitleShow
            ? this.state.description
            : resourceData.description
          : this.state.description,
      category: this.getTagInitialValues(resourceCategory, category) || {},
      bannerIcon:
        !this.props.bannerIcon && resourceData && resourceData.banner_icon
          ? resourceData.banner_icon
          : this.props.bannerIcon,
      bannerColor:
        !this.props.bannerColor && resourceData && resourceData.banner_color
          ? resourceData.banner_color
          : this.props.bannerColor,
      bannerImageUrl:
        !this.props.bannerImageUrl &&
        resourceData &&
        resourceData.bannerImageUrl
          ? resourceData.bannerImageUrl
          : this.props.bannerImageUrl,
      partnerTier:
        resourceData && resourceData.partnerTier ? resourceData.partnerTier : ""
    };

    const playbookCardPlaceHolder =
      resourceData && resourceData.description ? false : true;

    const { activeTab, spotlightFileTypeError, closePreviewImage } = this.state;
    const { permissionRequired } = this.props;

    const fileTypeError = this.state.fileTypeError;

    return (
      <div>
        <Nav tabs className="admin-tabs">
          {resourceData ? "" : this.toggle(this.props.currentTab + "")}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TAB_BASICS })}
              onClick={() => {
                this.toggle(TAB_BASICS);
                this.props.backButton(TAB_BASICS);
              }}
            >
              Basics
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TAB_PERMISSIONS })}
              onClick={() => {
                this.toggle(TAB_PERMISSIONS);
                this.props.backButton(TAB_PERMISSIONS);
              }}
            >
              Permissions
            </NavLink>
          </NavItem>
        </Nav>
        <TabsAndContent>
          {/*added this condition below to support multiple contents inside a tab pane with white background*/}
          <div className={`tab-content-wrapper d-flex`}>
            <TabContent
              activeTab={activeTab}
              className={
                activeTab === TAB_PERMISSIONS ? "selected-experts" : ""
              }
            >
              <TabPane tabId={TAB_BASICS}>
                <div className="form-wrapper">
                  {/* Resource Source Section */}
                  <label className="font-weight-bold">Resource Source</label>
                  <div>
                    <div className="field-wrapper ml-4 mb-1">
                      <div className="form-group">
                        <div>
                          <Radio
                            name="resourceSource"
                            className="w-100 mb-0"
                            options={[
                              RESOURCE_SOURCE_NEW_RESOURCE,
                              RESOURCE_SOURCE_EXISTING_RESOURCE
                            ]}
                            onChange={this.handleResourceSource}
                            defaultValue={this.state.checkBox.resourceSource}
                          />
                        </div>
                      </div>
                    </div>

                    {this.state.checkBox.resourceSource ===
                      RESOURCE_SOURCE_EXISTING_RESOURCE && (
                      <div className="field-wrapper ml-5">
                        <div className="form-group">
                          <div>
                            <Radio
                              name="existingResourceSource"
                              className="mb-0"
                              options={[RESOURCE_WEB_PAGE, RESOURCE_VIDEO]}
                              onChange={this.handleExistingResource}
                              defaultValue={
                                this.state.checkBox.existingResourceSource
                              }
                            />
                          </div>
                        </div>
                      </div>
                    )}

                    {this.state.checkBox.existingResourceSource ===
                      RESOURCE_WEB_PAGE && (
                      <Text
                        name="webPageUrl"
                        label="URL of Existing Resource"
                        fontBolded
                        placeholder="Enter URL"
                        required
                      />
                    )}

                    {this.state.checkBox.existingResourceSource ===
                      RESOURCE_VIDEO && (
                      <div>
                        <TextArea
                          label="Embed Code"
                          fontBolded
                          name="videoEmbedCode"
                          placeholder="Enter embed code"
                          rows={16}
                          required
                        />
                        <VideoPlayer url={this.props.videoEmbedCode} />
                      </div>
                    )}
                  </div>
                  {/* Resource Source Section End */}

                  <div className="field-wrapper mt-3">
                    <Text
                      name="title"
                      label="Resource Title"
                      fontBolded
                      placeholder="Enter title"
                      error=""
                      required={true}
                      onChange={this._handleChange.bind(this)}
                      autoFocus={true}
                    />
                  </div>

                  <div className="field-wrapper mt-3">
                    <TextArea
                      name="description"
                      label="Description"
                      fontBolded
                      placeholder="Enter description"
                    />
                  </div>

                  <div className="field-wrapper flex-column">
                    <MultiselectCreatable
                      name="category"
                      label="Category"
                      placeholder={"Select or Create Category"}
                      fontBolded
                      options={category}
                    />
                    <Hint
                      id="expert-roles"
                      hintText="The type of category you want to add resource"
                    />
                  </div>

                  <div className="mt-4 mb-3">
                    <ToggleSwitch
                      name="availability"
                      label1="Show in Partner Spotlight"
                      label2="Show in Partner Spotlight"
                      value={this.state.checkBox.showPartnerSpotlight}
                      handleChange={this.handlePartnerSpotlight}
                      outlined
                    />
                  </div>

                  {/* Send Notification to Partner */}
                  <div className="field-wrapper flex-column">
                    <label className="font-weight-bold">
                      Resource Publication Notification
                    </label>
                    <SingleCheckbox
                      name="sendNotification"
                      label="Send Notification to partners that have permission"
                      className="accepted-terms  mb-3 pb-0 mr-3"
                    />
                  </div>

                  {/* Spotlight image */}
                  <div className="form-group">
                    <input
                      name="spotlightImagePathUrl"
                      className="form-control d-none"
                      type="file"
                      id="spotlightImagePathUrl"
                      onChange={e => {
                        this.onSpotlightSelectFile(e);
                      }}
                      accept="image/png,image/gif,image/jpeg"
                    />
                    {/* Upload image link */}
                    <span className="profilePicOverlay d-block mb-3">
                      <label
                        htmlFor="spotlightImagePathUrl"
                        className="profile-img-sm mb-0"
                      >
                        <span className="banner-image-upload-link">
                          Upload Partner Spotlight Image
                        </span>
                      </label>
                    </span>
                    <small
                      className={`text-danger mt-3 ${
                        spotlightFileTypeError ? "d-block" : "d-none"
                      }`}
                    >
                      Please select a .jpg, .jpeg, .png files
                    </small>
                    {spotlightImagePathUrlPreview || spotlightImagePathUrl ? (
                      <div>
                        <PreviewImage
                          imageUrl={spotlightImagePathUrl}
                          previewImage={spotlightImagePathUrlPreview}
                          height="100px"
                        />
                        {/* Remove image */}
                        <span
                          id="spotlightImagePathUrl"
                          className="banner-image-upload-link text-danger"
                          onClick={handleSpotlightImageRemove}
                        >
                          Remove
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>

                  {/* ----------- Separator -----------  */}
                  <hr />
                  {closePreviewImage && src && (
                    <div className="crop-modal">
                      <div className="backdrop" />
                      <div className="crop-modal-body">
                        <div
                          className="crop-modal-close"
                          onClick={() =>
                            this.setState({ closePreviewImage: false })
                          }
                        >
                          <CrossIcon />
                        </div>
                        <h5 className="mb-3">
                          <b>Crop image and upload</b>
                        </h5>
                        <ReactCrop
                          src={src}
                          crop={crop}
                          ruleOfThirds
                          onImageLoaded={this.onImageLoaded}
                          onComplete={this.onCropComplete}
                          onChange={this.onCropChange}
                        />

                        <div className="mt-4 d-flex justify-content-center">
                          <button
                            className="btn btn-secondary"
                            onClick={this.handleImageUpload}
                          >
                            Upload Profile Photo
                          </button>
                        </div>
                      </div>
                    </div>
                  )}

                  <div className="upload-field d-inline-block">
                    <input
                      name="file"
                      className="form-control d-none"
                      type="file"
                      id="bannerImage"
                      placeholder="Banner Image"
                      onChange={e => {
                        this.onSelectFile(e);
                      }}
                      accept="image/*"
                    />
                    <small
                      className={`text-danger ${
                        fileSize > 10240 ? "d-block" : "d-none"
                      }`}
                    >
                      Please select a file less than 10mb
                    </small>
                    <span className="profilePicOverlay d-block ">
                      <label
                        htmlFor="bannerImage"
                        className="profile-img-sm mb-0"
                      >
                        <span className="banner-image-upload-link">
                          Upload Card Image
                        </span>
                      </label>
                    </span>
                  </div>
                  {/* Remove image */}
                  {data.bannerImageUrl && (
                    <span
                      className="banner-image-upload-link text-danger ml-md-1"
                      onClick={this.props.handleImageRemove}
                    >
                      (Remove)
                    </span>
                  )}

                  <small
                    className={`text-danger mt-3 ${
                      fileTypeError ? "d-block" : "d-none"
                    }`}
                  >
                    Please select a image file
                  </small>
                  <Hint
                    id="bannerRequirements"
                    hintText="Images should be at least 530x530px and not exceed 10MB."
                  />

                  <div className="field-wrapper mt-4">
                    <div className="form-group">
                      <label className="font-weight-bold">Color</label>
                      <ColorPicker
                        onColourPickerClick={this.props.onColourPickerClick}
                        bannerColor={this.props.bannerColor}
                      />
                    </div>
                  </div>
                  <div className="field-wrapper">
                    <div className="form-group">
                      <label className="font-weight-bold">Icon</label>
                      <IconPicker
                        onIconClick={this.props.onIconClick}
                        bannerIcon={this.props.bannerIcon}
                      />
                    </div>
                  </div>
                </div>
                {/* Upload Resource file */}
                {this.state.checkBox.resourceSource ===
                  RESOURCE_SOURCE_NEW_RESOURCE && (
                  <div className="form-wrapper mt-4">
                    <div className="flex-column">
                      <DragAndDropField
                        label={"Upload Resource File"}
                        fontBolded
                        name={"resource_file"}
                        hiddenDropzone
                        btnLabel={"Upload Resource File"}
                        onFileChange={this.props.handleResourceFile}
                        onFileDelete={this.props.handleDeleteSupportingMaterial}
                        data={resourceFile}
                      />
                    </div>
                  </div>
                )}
              </TabPane>
              {/* Permission */}
              <TabPane tabId={TAB_PERMISSIONS}>
                <PartnerSelector
                  showPartnerTier
                  showPartnerType
                  showAllPermission
                  tierTitle="Partner Tier"
                  typeTitle="Partner Type"
                  name="sharedPartner"
                  partnerPermissionDefaultValue={
                    this.state.checkBox.sharingPermission
                  }
                  settings={this.props.settings}
                  dropdownName="sharedPartner"
                  dropdownLabel="Share Resource With"
                  tierSelectedOptions={this.state.checkBox.partnerTier}
                  handleTierChange={this.handlePartnerTierCheckbox}
                  typeSelectedOptions={this.state.checkBox.partnerType}
                  handleTypeChange={this.handlePartnerTypeCheckbox}
                  handlePartnerChange={e => {
                    this.handleChangePartnerSelection(e);
                  }}
                  handlePermissionOnchange={e => {
                    this.handlePartnerPermission(e);
                  }}
                  permissionRequired={permissionRequired}
                />
              </TabPane>
            </TabContent>

            {activeTab === TAB_BASICS && (
              <div className="preview-wrapper">
                <label className="font-weight-bold pl-3">Preview</label>
                <GridCard
                  isPreview={this.props.detailLink ? "false" : "true"}
                  link={this.props.detailLink}
                  openLinkInNewTab={true}
                  boxShadow={"true"}
                  placeHolder={playbookCardPlaceHolder}
                  data={data}
                  files={this.props.files || ""}
                  bannerImageUrl={
                    this.state.bannerImageUrl || this.props.bannerImageUrl
                  }
                  tag={"div"}
                />
                {this.props.detailLink && (
                  <div className="preview-full mt-4 d-flex align-items-center justify-content-center">
                    <MaximizeAltIcon />
                    <a
                      href={`${this.props.detailLink}?redirect=${window.location.pathname}`}
                      target="_blank"
                      className={["text-underline"].join(" ")}
                      rel="noopener noreferrer"
                    >
                      View Full Preview
                    </a>
                  </div>
                )}
              </div>
            )}
          </div>
        </TabsAndContent>
      </div>
    );
  }
}

export default ResourceFormFields;
