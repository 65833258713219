import React from "react";

// Components
import StatisticsCountCard from "../../components/base/StatisticsCountCard";
import { GetCurrencySymbol } from "../../lib/CurrencySymbol";

const ExpertStatisticCards = props => {
  const { enable, customerCount, CompletedProject } = props;

  return (
    <div
      className={["card-statistics", `${!enable ? "disabled" : ""}`].join(" ")}
    >
      {/* Show Customer Count */}
      <StatisticsCountCard
        count={customerCount}
        label="Customers"
        className={"text-white"}
      />

      {/* Show Project Completed Count */}
      <StatisticsCountCard
        count={CompletedProject}
        label="Projects Completed"
        redirectUrl="/projects?status=Completed"
        className={"text-white"}
      />

      {/* Show Pending Payments Count */}
      <StatisticsCountCard
        count={0}
        label="Pending Payments"
        prefix={GetCurrencySymbol()}
        redirectUrl="/payment"
        className={"text-white"}
      />

      {/* Show Total Earnings Count */}
      <StatisticsCountCard
        count={0}
        label="Total Earnings"
        prefix={GetCurrencySymbol()}
        className={"text-white"}
      />
    </div>
  );
};
export default ExpertStatisticCards;
