import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CancelButton from "./CancelButton";

const ConfirmationModal = ({
  modal,
  setModal,
  title,
  description,
  closeModal,
  submitValue
}) => {
  const handleSubmit = () => {
    submitValue();
    setModal(false);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={closeModal}>
        <h4 className={["font-weight-bold"].join(" ")}>{title}</h4>
      </ModalHeader>
      <ModalBody className={["text-center", "mb-4"].join(" ")}>
        <p>{description}</p>
      </ModalBody>
      <ModalFooter className={["justify-content-center"].join(" ")}>
        <div className="btn-wrapper">
          <button
            className="btn btn-danger"
            type="button" // Set type to "button" to prevent form submission
            onClick={handleSubmit}
          >
            Yes
          </button>
          <CancelButton onClick={closeModal} />
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ConfirmationModal;
