import React, { useState, useRef } from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import Hint from "../../../../components/base/Hint";
import Text from "../../../../components/base/Text";
import SaveButton from "../../../../components/base/SaveButton";
import Email from "../../../../components/base/Email";

// Constants
import {
  SETTINGS_EMAIL_LOGO,
  SETTINGS_EMAIL_BACKGROUND_BANNER,
  SETTINGS_EMAIL_PRIMARY_BACKGROUND_COLOR,
  SETTINGS_EMAIL_PRIMARY_TEXT_COLOR,
  SETTINGS_PARTNER_INVITE_EMAIL_MESSAGE,
  SETTINGS_PARTNER_INVITE_EMAIL_SUBJECT,
  SETTINGS_PORTAL_FROM_EMAIL,
  SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME,
  SETTINGS_REFERRAL_DECLINE_EMAIL_MESSAGE,
  SETTINGS_REFERRAL_DECLINE_EMAIL_SUBJECT
} from "../../../../setting/Constants";

// Helper
import { getSettingMediaUrl } from "../../../../lib/helper";
import TextArea from "../../../../components/base/TextArea";

const EmailSection = props => {
  const { adminSettings, saveSettings, settings } = props;

  const {
    email_logo,
    email_logo_url,
    email_primary_background_color,
    email_primary_text_color,
    partner_invite_email_subject,
    partner_invite_email_message,
    referral_decline_email_message,
    referral_decline_email_subject,
    email_background_banner,
    email_background_banner_url
  } = adminSettings;

  const {
    emailLogoUpload,
    handleEmailLogoRemove,
    emailBackgroundBannerUpload,
    handleEmailBackgroundBannerRemove,
    submitThemeTab
  } = saveSettings;

  // initilize states
  const [emailLogoFileError, setEmailLogoFileError] = useState(false);
  const [emailBannerFileError, setEmailBannerFileError] = useState(false);

  const emailLogoRef = useRef();
  const emailBackgroundBannerRef = useRef();

  // Theme Settings Email Section Initial Values
  const themeSettingsEmailSectionInitialValues = {
    email_primary_background_color,
    email_primary_text_color,
    partner_invite_email_subject,
    partner_invite_email_message,
    referral_decline_email_subject,
    referral_decline_email_message,
    [SETTINGS_PARTNER_INVITE_EMAIL_SUBJECT]:
      adminSettings[SETTINGS_PARTNER_INVITE_EMAIL_SUBJECT],
    [SETTINGS_PARTNER_INVITE_EMAIL_MESSAGE]:
      adminSettings[SETTINGS_PARTNER_INVITE_EMAIL_MESSAGE],
    [SETTINGS_PORTAL_FROM_EMAIL]: adminSettings[SETTINGS_PORTAL_FROM_EMAIL],
    [SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME]:
      adminSettings[SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME]
  };

  // Validate Filetype
  const validateFile = (e, File) => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          (File === SETTINGS_EMAIL_LOGO && setEmailLogoFileError(false)) ||
            (File === SETTINGS_EMAIL_BACKGROUND_BANNER &&
              setEmailBannerFileError(false));
          break;
        default:
          (File === SETTINGS_EMAIL_LOGO && setEmailLogoFileError(true)) ||
            (File === SETTINGS_EMAIL_BACKGROUND_BANNER &&
              setEmailBannerFileError(true));
          break;
      }
    }
  };

  // Email Logo upload
  const onEmailLogoChange = e => {
    validateFile(e, SETTINGS_EMAIL_LOGO);
    if (!emailLogoFileError) {
      emailLogoUpload(e);
    }
  };

  // Email Logo remove
  const onEmailLogoRemove = () => {
    setEmailLogoFileError(false);
    emailLogoRef.current.value = "";
    handleEmailLogoRemove();
  };

  // Email Logo s3 url
  const emailLogoWithBaseUrl = email_logo
    ? email_logo
    : email_logo_url && settings
    ? getSettingMediaUrl(settings, email_logo_url)
    : "";

  // Emailbackground banner
  const onEmailBannerChange = e => {
    validateFile(e, SETTINGS_EMAIL_BACKGROUND_BANNER);
    if (!emailLogoFileError) {
      emailBackgroundBannerUpload(e);
    }
  };

  // Email background remove
  const onEmailBackgroundBannerRemove = () => {
    setEmailBannerFileError(false);
    emailBackgroundBannerRef.current.value = "";
    handleEmailBackgroundBannerRemove();
  };

  // Email banner s3 url
  const emailBackgroundBannerWithBaseUrl = email_background_banner
    ? email_background_banner
    : email_background_banner_url && settings
    ? getSettingMediaUrl(settings, email_background_banner_url)
    : "";

  return (
    <Form
      initialValues={themeSettingsEmailSectionInitialValues}
      enableReinitialize
      onSubmit={values => {
        submitThemeTab(values);
      }}
    >
      <div className="form-wrapper">
        <DefaultContent>
          <h4 className="font-weight-bold">Email</h4>

          <h5 className="font-weight-bold">Primary Background Color</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_EMAIL_PRIMARY_BACKGROUND_COLOR}
              placeholder="Enter Email Primary Background Color"
            />
          </div>

          <h5 className="font-weight-bold">Primary Text Color</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_EMAIL_PRIMARY_TEXT_COLOR}
              placeholder="Enter Email Primary Text Color"
            />
          </div>

          <h5 className="font-weight-bold">From Email</h5>
          <div className="field-wrapper">
            <Email
              name={SETTINGS_PORTAL_FROM_EMAIL}
              placeholder="Enter From Email"
              validationMessage="email address"
            />
          </div>

          <h5 className="font-weight-bold">From Email Display Name</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME}
              placeholder="Enter From Email Display Name"
            />
          </div>

          {/* Email Logo upload */}
          <h5 className="font-weight-bold">Email Logo</h5>
          <div className="field-wrapper d-flex">
            <div>
              <div className="upload-field d-inline-block">
                <input
                  name="file"
                  className="form-control d-none"
                  type="file"
                  id="emailLogo"
                  placeholder="Banner Image"
                  onChange={e => {
                    onEmailLogoChange(e);
                  }}
                  accept="image/png,image/gif,image/jpeg"
                  ref={emailLogoRef}
                />
                <span className="profilePicOverlay d-block ">
                  <label htmlFor="emailLogo" className="profile-img-sm mb-0">
                    <span className="banner-image-upload-link">
                      Upload Email Logo
                    </span>
                  </label>
                </span>
              </div>
              {/* Remove image */}
              {email_logo_url && (
                <span
                  className="banner-image-upload-link text-danger ml-md-1"
                  onClick={onEmailLogoRemove}
                >
                  (Remove)
                </span>
              )}
              <div className="field-wrapper">
                <small
                  className={`text-danger mt-3 ${
                    emailLogoFileError ? "d-block" : "d-none"
                  }`}
                >
                  Please select a image file
                </small>
                <Hint
                  id="bannerRequirements"
                  hintText="We recommended using 400 x 300 image"
                />
              </div>
            </div>
            <div className="ml-auto">
              <img src={emailLogoWithBaseUrl} style={{ width: "75px" }} />
            </div>
          </div>

          {/* Email background banner */}
          <h5 className="font-weight-bold">Email Background Banner</h5>
          <div className="field-wrapper d-flex">
            <div>
              <div className="upload-field d-inline-block">
                <input
                  name="file"
                  className="form-control d-none"
                  type="file"
                  id="emilBackgroundImage"
                  placeholder="Background Image"
                  onChange={e => {
                    onEmailBannerChange(e);
                  }}
                  accept="image/png,image/gif,image/jpeg"
                  ref={emailBackgroundBannerRef}
                />
                <span className="profilePicOverlay d-block ">
                  <label
                    htmlFor="emilBackgroundImage"
                    className="profile-img-sm mb-0"
                  >
                    <span className="banner-image-upload-link">
                      Upload Email Background Banner
                    </span>
                  </label>
                </span>
              </div>
              {/* Remove image */}
              {email_background_banner_url && (
                <span
                  className="banner-image-upload-link text-danger ml-md-1"
                  onClick={onEmailBackgroundBannerRemove}
                >
                  (Remove)
                </span>
              )}
              <div className="field-wrapper">
                <small
                  className={`text-danger mt-3 ${
                    emailBannerFileError ? "d-block" : "d-none"
                  }`}
                >
                  Please select a image file
                </small>
                <Hint
                  id="emilBackgroundImage"
                  hintText="We recommended using 400 x 800 image"
                />
              </div>
            </div>
            <div className="ml-auto">
              <img
                src={emailBackgroundBannerWithBaseUrl}
                style={{ width: "100px" }}
              />
            </div>
          </div>
          {/* Email background banner end*/}

          {/* Partner invite email */}
          <h4 className="font-weight-bold">Partner Invite Email</h4>
          <h5 className="font-weight-bold">Subject</h5>
          <div className="field-wrapper d-flex">
            <Text
              name={SETTINGS_PARTNER_INVITE_EMAIL_SUBJECT}
              placeholder="Enter Partner Invite Email Subject"
            />
          </div>
          <h5 className="font-weight-bold">Message</h5>
          <div className="field-wrapper d-flex">
            <TextArea
              name={SETTINGS_PARTNER_INVITE_EMAIL_MESSAGE}
              placeholder="Enter Partner Invite Email Message"
              rows="8"
            />
          </div>
          <h4 className="font-weight-bold">Referral Decline Email</h4>
          <h5 className="font-weight-bold">Subject</h5>
          <div className="field-wrapper d-flex">
            <Text
              name={SETTINGS_REFERRAL_DECLINE_EMAIL_SUBJECT}
              placeholder="Enter Referral Decline Email Subject"
            />
          </div>
          <h5 className="font-weight-bold">Message</h5>
          <div className="field-wrapper d-flex">
            <TextArea
              name={SETTINGS_REFERRAL_DECLINE_EMAIL_MESSAGE}
              placeholder="Enter Referral Decline Email Message"
              rows="8"
            />
          </div>
        </DefaultContent>
      </div>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default EmailSection;
