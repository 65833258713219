import React from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";

// Helper
import SingleCheckbox from "../../inner-component/Checkbox";
import {
  ALLOW_PARTNER_CHANGE,
  TAB_DEAL_SETTINGS
} from "../../../../setting/Constants";
import { Nav, NavItem, NavLink } from "reactstrap";
import classNames from "classnames";

const DealTab = props => {
  const { adminSettings, saveSettings } = props;

  const { allow_partner_change } = adminSettings;

  // General Settings  Section Initial Values
  const initialValues = {
    allow_partner_change: allow_partner_change == "true" ? true : false
  };

  // General Settings  Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Default  Title
    if (values.allow_partner_change !== undefined) {
      data.append(ALLOW_PARTNER_CHANGE, values.allow_partner_change);
    }
    // Save settings
    saveSettings(data);
  };

  return (
    <div>
      <Nav tabs className="admin-tabs">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classNames({
              active: TAB_DEAL_SETTINGS
            })}
          >
            {TAB_DEAL_SETTINGS}
          </NavLink>
        </NavItem>
      </Nav>

      <Form initialValues={initialValues} enableReinitialize>
        <DefaultContent>
          <div className="form-wrapper">
            {/* Find an Expert */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={ALLOW_PARTNER_CHANGE}
                label="Allow To Change the Partner"
                className="accepted-terms  pb-0 mr-3"
                handleOnChangeSubmit={values => {
                  submit(values);
                }}
              />
            </div>
          </div>
        </DefaultContent>
      </Form>
    </div>
  );
};

export default DealTab;
