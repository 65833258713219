import React, { useRef, useState } from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import Select from "../../../../components/base/Select";
import Text from "../../../../components/base/Text";
import SaveButton from "../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_PORTAL_NAME,
  SETTINGS_DEFAULT_ACCOUNT_MANAGER,
  SETTINGS_PORTAL_DEFAULT_LANDING_PAGE_URL,
  SETTINGS_PORTAL_DEFAULT_START_PAGE_URL,
  SETTINGS_PORTAL_LOGO,
  SETTINGS_PORTAL_TAG_LINE
} from "../../../../setting/Constants";

// Helper
import { getSettingMediaUrl, toString } from "../../../../lib/helper";
import Hint from "../../../../components/base/Hint";

const GeneralSection = props => {
  const { adminSettings, saveSettings, settings } = props;

  const [logoFileTypeError, setLogoFileTypeError] = useState(false);

  const marketplaceLogoRef = useRef();

  const {
    marketplace_name,
    default_account_manager,
    marketplace_default_landing_page_url,
    marketplace_default_start_page_url,
    marketplace_logo,
    marketplace_logo_url,
    portal_tag_line
  } = adminSettings;

  const defaultAccountManagerList =
    adminSettings.defaultAccountManagerList || [];
  let selectedOption = {};
  if (default_account_manager) {
    defaultAccountManagerList.forEach(list => {
      if (list.value == default_account_manager) {
        selectedOption = list;
      }
    });
  }

  // General Settings General Section Initial Values
  const generalSettingsGeneralSectionInitialValues = {
    marketplace_name,
    portal_tag_line,
    default_account_manager: default_account_manager
      ? defaultAccountManagerList.find(
          type => type.value === parseInt(default_account_manager, 10)
        )
      : "",
    marketplace_default_landing_page_url,
    marketplace_default_start_page_url
  };

  const marketplaceLogoWithBaseUrl = marketplace_logo
    ? marketplace_logo
    : marketplace_logo_url && settings
    ? getSettingMediaUrl(settings, marketplace_logo_url)
    : "";
  // General Settings General Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Marketplace name
    if (values.marketplace_name !== undefined) {
      data.append(
        "marketplace_name",
        values.marketplace_name ? values.marketplace_name : ""
      );
    }
    // Marketplace logo URL
    if (values.marketplace_logo_url !== undefined) {
      data.append(
        "marketplace_logo_url",
        values.marketplace_logo_url ? values.marketplace_logo_url : ""
      );
    }

    // Marketplace favicon file
    if (values.marketplaceLogoFile !== undefined) {
      data.append("marketplace_logo", values.marketplaceLogoFile);
    }
    // Default Account Manager
    if (values.default_account_manager !== undefined) {
      data.append(
        "default_account_manager",
        toString(
          values.default_account_manager &&
            typeof values.default_account_manager === "object"
            ? values.default_account_manager.value
            : values.default_account_manager
        )
      );
    }

    // Marketplace default landing page URL
    if (values.marketplace_default_landing_page_url !== undefined) {
      data.append(
        "marketplace_default_landing_page_url",
        values.marketplace_default_landing_page_url
          ? values.marketplace_default_landing_page_url
          : ""
      );
    }

    // Marketplace default start page URL
    if (values.marketplace_default_start_page_url !== undefined) {
      data.append(
        "marketplace_default_start_page_url",
        values.marketplace_default_start_page_url
          ? values.marketplace_default_start_page_url
          : ""
      );
    }
    // Marketplace default start page URL
    if (values.portal_api_url !== undefined) {
      data.append(
        "portal_api_url",
        values.portal_api_url ? values.portal_api_url : ""
      );
    }
    if (values.portal_tag_line !== undefined) {
      data.append(
        "portal_tag_line",
        values.portal_tag_line ? values.portal_tag_line : ""
      );
    }
    // Save settings
    saveSettings(data);
  };

  // Validate Filetype
  const validateFile = (e, File) => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          File === SETTINGS_PORTAL_LOGO && setLogoFileTypeError(false);
          break;
        default:
          File === SETTINGS_PORTAL_LOGO && setLogoFileTypeError(true);
          break;
      }
    }
  };
  const onMarketplaceLogoChange = e => {
    validateFile(e, SETTINGS_PORTAL_LOGO);
    if (!logoFileTypeError) {
      props.marketplaceLogoUpload(e);
    }
  };
  // Portal logo image remove
  const onMarketplaceLogoRemove = () => {
    setLogoFileTypeError(false);
    marketplaceLogoRef.current.value = "";
    props.handleMarketplaceLogoRemove();
  };
  return (
    <Form
      initialValues={generalSettingsGeneralSectionInitialValues}
      enableReinitialize
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          <h5 className="font-weight-bold">Portal Name</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_NAME}
              id="portalName"
              placeholder="Enter Portal Name"
            />
          </div>
          <h5 className="font-weight-bold">Portal Tag Line</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_TAG_LINE}
              id="portal_tag_line"
              placeholder="Enter Portal Tag Line"
            />
          </div>
          <h5 className="font-weight-bold">Portal Logo</h5>
          <div className="field-wrapper d-flex">
            <div>
              <div className="upload-field d-inline-block">
                <input
                  name="file"
                  className="form-control d-none"
                  type="file"
                  id="marketplaceLogo"
                  placeholder="Banner Image"
                  onChange={e => {
                    onMarketplaceLogoChange(e);
                  }}
                  accept="image/png,image/gif,image/jpeg"
                  ref={marketplaceLogoRef}
                />
                <span className="profilePicOverlay d-block ">
                  <label
                    htmlFor="marketplaceLogo"
                    className="profile-img-sm mb-0"
                  >
                    <span className="banner-image-upload-link">
                      Upload Portal Logo
                    </span>
                  </label>
                </span>
              </div>
              {/* Remove image */}
              {marketplace_logo_url && (
                <span
                  className="banner-image-upload-link text-danger ml-md-1"
                  onClick={onMarketplaceLogoRemove}
                >
                  (Remove)
                </span>
              )}
              <div className="field-wrapper">
                <small
                  className={`text-danger mt-3 ${
                    logoFileTypeError ? "d-block" : "d-none"
                  }`}
                >
                  Please select a image file
                </small>
                <Hint
                  id="bannerRequirements"
                  hintText="We recommended using 400 x 600 image"
                />
              </div>
            </div>
            <div className="ml-auto">
              <img
                src={marketplaceLogoWithBaseUrl}
                style={{ width: "100px" }}
              />
            </div>
          </div>
          <h5 className="font-weight-bold">Default Account Manager</h5>
          <div className="field-wrapper">
            <Select
              name={SETTINGS_DEFAULT_ACCOUNT_MANAGER}
              label="Who do you want to assign as the default Account Manager as your marketplace?"
              placeholder="Select a default account manager"
              options={defaultAccountManagerList}
              isSearchable={true}
              defaultValue={selectedOption}
            />
          </div>

          {/* Default Start Page URL */}
          <h5 className="font-weight-bold">Default Start Page URL</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_DEFAULT_START_PAGE_URL}
              placeholder="Enter Default Start Page URL"
            />
          </div>

          {/* Landing page URL */}
          <h5 className="font-weight-bold">Default Landing Page URL</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_DEFAULT_LANDING_PAGE_URL}
              placeholder="Enter Default landing page URL"
            />
          </div>
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default GeneralSection;
