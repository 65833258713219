import React, { Component, createRef } from "react";
import CrossBeamPartnerCard from "./CrossbeamPartnerCard";
import { Col, Row } from "reactstrap";
// API call
import { apiClient } from "../../apiClient";
// Configs
import { endpoints } from "../../configs";
import toast from "../../components/base/Toast";
import accountMappingIcon from "../../assets/img/icons/nav-icon-account-mapping.svg";
import NoRecordsFound from "../../components/base/NoRecordsFound";
import Pagination from "../../components/base/Pagination";
import Spinner from "../../components/base/Spinner";
import PageSearch from "../../components/base/PageSearch";
import SelectDropdown from "../../components/base/SelectDropdown";
import { getParamsByName, getUrlPath } from "../../lib/helper";
import { fetchList } from "../../actions/table";
import AddButton from "../../components/base/AddButton";
import PageTitle from "../../components/base/PageTitle";
import Button from "../../components/base/Button";
import Select from "../../components/base/Select";
import Form from "../../components/base/Form";
import FileUploader from "../../components/FileUploader";
import * as XLSX from "xlsx";

import InvitePartner from "../accountMapping/invitePartnerModal";

import Url from "../../lib/Url";
import PageSize from "../../components/PageSize";
import { cardPageSizeOption } from "../../helpers/Page";

export default class AccountMappingPartners extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm:
        this.params && this.params.searchItem
          ? this.params.searchItem
          : "" || "",
      crossBeamData: "",
      status: "",
      pageSize: 12,
      currentPage: 1,
      pageNumber: "",
      totalCount: "",
      selectedSortOption: "Name",
      selectedStatusOption: "Active",
      status: getParamsByName("status") ? getParamsByName("status") : "Active",
      selectedTypeOption: "Prospect",
      sortByOptions: [
        {
          value: "company_name",
          label: "Name"
        },
        {
          value: "last_overlap_time",
          label: "Activity"
        }
      ],
      SortByStatus: [
        {
          value: "All",
          label: "All"
        },
        {
          value: "Active",
          label: "Active"
        },

        {
          value: "Snoozed",
          label: "Snoozed"
        },
        {
          value: "Archived",
          label: "Archived"
        }
      ],
      typeOption: [
        {
          value: "Partner",
          label: "Partner"
        },
        {
          value: "Prospect",
          label: "Prospect"
        }
      ],
      value: getParamsByName("search"),
      hiddenFileInput: createRef(),
      sort: getParamsByName("sort")
        ? getParamsByName("sort")
        : "total_overlap_count",
      sortDir: getParamsByName("sortDir") ? getParamsByName("sortDir") : "DESC",
      type: getParamsByName("type") ? getParamsByName("type") : "Partner",
      searchTerm: getParamsByName("search"),
      pageSizeValue: getParamsByName("pageSize")
    };
  }
  componentDidMount() {
    this.getCrossBeamPartnerRecords(true);
    this.prefillFilter();
  }

  prefillFilter = () => {
    let status = getParamsByName("status");
    let sort = getParamsByName("sort");

    this.getSortValueFromLabel(sort);
    this.getStatusValueFromLabel(status);

    if (sort) {
      const selectedSortOption = this.state.sortByOptions.find(
        option => option.value === sort
      );
      if (selectedSortOption) {
        this.setState({ selectedSortOption: selectedSortOption.label });
      }
    }

    if (status) {
      const selectedStatus = this.state.SortByStatus.find(
        option => option.value === status
      );

      if (selectedStatus) {
        this.setState({ selectedStatusOption: selectedStatus.label });
      }
    }
  };
  // Search Query string
  getCrossBeamPartnerRecords = paramsData => {
    let sort;

    let searchTerm;

    let sortDir;

    let status;

    let page;

    let pageSize;

    let type;

    if (this.state.sort) {
      sort = this.state.sort;
    }

    if (this.state.searchTerm) {
      searchTerm = this.state.searchTerm;
    }

    if (this.state.sortDir) {
      sortDir = this.state.sortDir;
    }

    if (this.state.status) {
      status = this.state.status;
    }

    if (this.state.page) {
      page = this.state.page;
    }

    if (this.state.pageSize) {
      pageSize = this.state.pageSizeValue
        ? this.state.pageSizeValue
        : this.state.pageSize;
    }

    if (this.state.type) {
      type = this.state.type;
    }

    if (paramsData) {
      const { sortData, sortDirData, typeData, statusData } = paramsData;

      if (sortData) {
        sort = sortData;
      }

      if (sortDirData) {
        sortDir = sortDirData;
      }

      if (typeData) {
        type = typeData;
      }

      if (statusData) {
        status = statusData;
      }
    }

    const params = [];

    if (page) {
      params.push(`page=${page}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }
    if (searchTerm) {
      params.push(`search=${searchTerm}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (status) {
      params.push(`status=${status}`);
    }

    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    if (type) {
      params.push(`type=${type}`);
    }

    const currentPath = getUrlPath(1);

    this.props.history.push(`/${currentPath}?${params.join("&")}`);

    let apiUrl = `${endpoints().crossbeamPartnerAPI}/crossbeamPartnerRecords`;

    if (params && params.length > 0) {
      apiUrl = `${apiUrl}?${params.join("&")}`;
    }

    apiClient.get(apiUrl).then(data => {
      if (data.data) {
        this.setState({
          crossBeamData: data.data.partnerData,
          totalCount: data.data.totalCount,
          page: data.data.page,
          pageSize: data.data.pageSize
        });
      }
    });
  };

  updateUrl(data) {
    const { search, page, pageSize, sort, sortDir, status, type } = data;

    const params = [];

    params.push(`pagination=true`);

    if (search) {
      params.push(`search=${search}`);
    }

    if (status) {
      params.push(`status=${status}`);
    }

    if (page) {
      params.push(`page=${page}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    if (type) {
      params.push(`type=${type}`);
    }

    if (params.length > 0) {
      return params.join("&");
    }
  }
  _handleInputChange = e => {
    const currentPath = getUrlPath(1);
    this.setState(
      {
        page: 1,
        currentPage: 1,
        searchTerm: e.target.value
      },
      () => {
        const { searchTerm, status } = this.state;
        const params = {
          status: this.state.status,
          search: encodeURIComponent(searchTerm),
          pageSize: this.state.pageSize,
          sort: this.state.sort,
          sortDir: this.state.sortDir,
          type: this.state.type
        };
        this.getCrossBeamPartnerRecords();
        fetchList(this.updateUrl(params));
      }
    );
  };
  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }
  getStatusValueFromLabel(label) {
    const SortByStatus = this.state.SortByStatus;

    const selectedStatusOption = SortByStatus.find(
      option => option.label === label
    );

    if (selectedStatusOption) {
      return selectedStatusOption.value;
    }

    return "";
  }
  handleStatusByChange = value => {
    const status = this.getStatusValueFromLabel(value)
      ? this.getStatusValueFromLabel(value)
      : this.state.status;
    this.setState(
      {
        status: status,
        selectedStatusOption: status,
        page: 1,
        currentPage: 1
      },
      () => {
        const {
          sort,
          sortDir: sortDir,
          currentPage,
          pageSize,
          searchTerm
        } = this.state;
        this.getCrossBeamPartnerRecords();

        fetchList(
          this.updateUrl({
            status: status,
            sort: sort,
            sortDir: sortDir,
            page: currentPage,
            pageSize: pageSize,
            search: searchTerm,
            type: this.state.type
          })
        );
      }
    );
  };

  handleSortByChange = value => {
    const sortBy = this.getSortValueFromLabel(value)
      ? this.getSortValueFromLabel(value)
      : this.state.sort;
    this.setState(
      {
        status: this.state.status,
        sort: sortBy,
        sortDir: this.state.sortDir,
        type: this.state.type
      },
      () => {
        const {
          status,
          sortDir: sortDir,
          currentPage,
          pageSize,
          searchTerm,
          type
        } = this.state;
        this.getCrossBeamPartnerRecords();

        fetchList(
          this.updateUrl({
            status: status,
            sort: sortBy,
            sortDir: sortDir,
            page: currentPage,
            pageSize: pageSize,
            search: searchTerm,
            type: type
          })
        );
      }
    );
  };

  getUrlPath = n => {
    let currentPath = window.location.pathname;
    if (n) currentPath = currentPath.split("/")[n];
    return currentPath;
  };

  crossbeamPartnerStatusUpdate = (crossbeamAccountId, snoozeDate) => {
    apiClient
      .post(`${endpoints().crossbeamPartnerAPI}/status/update`, {
        crossbeamAccountId: crossbeamAccountId,
        snoozeDate: snoozeDate
      })
      .then(data => {
        if (data.status === 200) {
          toast.success(data.data.message);
          this.getCrossBeamPartnerRecords(
            false,
            getParamsByName("sort"),
            getParamsByName("sortDir")
          );
        }
      });
  };

  onPageChange = page => {
    this.setState(
      {
        currentPage: page,
        page: page
      },
      () => {
        this.getCrossBeamPartnerRecords({
          sortData: getParamsByName("sort"),
          sortDirData: getParamsByName("sortDir")
        });
      }
    );
  };

  populationOnClick = () => {
    this.props.history.push("/crossbeam-report");
  };

  handleTypeChnage = data => {
    this.setState({ type: data && data.value ? data.value : "" }, () => {
      this.getCrossBeamPartnerRecords();
    });
  };

  handleActionMenuChange = menu => {
    if (menu == "From Bombora") {
      this.state.hiddenFileInput.current.value = null;
      this.state.hiddenFileInput.current.click();
    }
  };

  importBomboraData = data => {
    let fileContent = new Array();
    let content;

    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        content = this.convertObjectKeysToLowercase(data[i]);
        fileContent.push({
          companyName: content["company name"],
          domain: content.domain,
          companySize: content["company size"],
          companyRevenue: content["company revenue"],
          industry: content.industry,
          address1: content["address 1"],
          address2: content["address 2"],
          hqCity: content["hq city"],
          hqState: content["hq state"],
          hqZip: content["hq zip"],
          hqCountry: content["hq country"],
          averageScore: content["average score"],
          topicCount: content["topic count"],
          topicCountDelta: content["topic count delta"]
        });
      }

      if (fileContent) {
        apiClient
          .post(`${endpoints().bomboraAPI}/import`, { importData: fileContent })
          .then(response => {
            if (response && response.data) {
              let successMessage = response.data.message;
              toast.success(successMessage);
            }
          });
      }
    }
  };

  convertToJson(data) {
    var result = [];

    if (data && data.length > 0) {
      var headers = data[0];

      if (headers && headers.length > 0) {
        for (let i = 1; i < data.length; i++) {
          var obj = {};
          if (headers.length == data[i].length) {
            for (var j = 0; j < headers.length; j++) {
              obj[headers[j]] = data[i][j];
            }
            result.push(obj);
          }
        }
      }
    }

    //return result; //JavaScript object
    return result; //JSON
  }

  convertObjectKeysToLowercase = obj => {
    const convertedObject = {};

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const lowercaseKey = key.toLowerCase();
        convertedObject[lowercaseKey] = obj[key];
      }
    }

    return convertedObject;
  };

  handleFileChange = e => {
    var file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = evt => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      let jsonData = this.convertToJson(data);

      this.importBomboraData(jsonData);
    };
    reader.readAsBinaryString(file);
  };

  handlePageSizeChange = e => {
    this.setState(
      {
        currentPage: this.state.currentPage,
        page: this.state.currentPage,
        pageSizeValue: e,
        pageSize: e
      },
      () => {
        this.getCrossBeamPartnerRecords({
          sortData: getParamsByName("sort"),
          sortDirData: getParamsByName("sortDir")
        });
      }
    );
  };

  render() {
    let startPage = 1;
    let endPage = "";

    startPage = (this.state.currentPage - 1) * this.state.pageSize;
    startPage =
      startPage > this.state.totalCount ? this.state.totalCount : startPage;

    endPage = this.state.currentPage * this.state.pageSize;
    endPage = endPage > this.state.totalCount ? this.state.totalCount : endPage;

    if (!this.state.crossBeamData) {
      return <Spinner />;
    }
    return (
      <div>
        <div className="d-flex justify-content-between  align-items-center mb-4">
          <PageTitle label="Account Mapping" />
          <div className="d-flex justify-content-between">
            <InvitePartner />

            <FileUploader
              hiddenFileInput={this.state.hiddenFileInput}
              handleChange={this.handleFileChange}
            />

            <SelectDropdown
              buttonLabel="Import"
              dropdownLinks={[{ label: "From Bombora", value: "From Bombora" }]}
              color={"gray"}
              hideCaret
              selectName={"Import"}
              handleChange={this.handleActionMenuChange}
            />
          </div>
        </div>

        <div className={`page-heading cover`}>
          <div className="d-flex justify-content-between">
            <div className="mr-1">
              <PageSize
                onChange={e => this.handlePageSizeChange(e)}
                customPageSizeOption={cardPageSizeOption}
              />
            </div>
            <div className="pr-2 w-100">
              <PageSearch
                classnames="page-search"
                placeholder="Search"
                value={this.state.searchTerm}
                onChange={this._handleInputChange.bind(this)}
              />
            </div>

            <div className="mr-1">
              <SelectDropdown
                buttonLabel={this.state.selectedStatusOption}
                dropdownLinks={this.state.SortByStatus}
                color={"gray"}
                hideCaret
                selectName={"statusby_account"}
                handleChange={this.handleStatusByChange}
              />
            </div>
            <SelectDropdown
              buttonLabel={this.state.selectedSortOption}
              dropdownLinks={this.state.sortByOptions}
              color={"gray"}
              hideCaret
              selectName={"sortby_account"}
              handleChange={this.handleSortByChange}
            />
          </div>
          <div className="d-flex justify-content-between mt-3">
            <div className="w-50">
              <Form
                initialValues={{
                  type: getParamsByName("type")
                    ? this.state.typeOption.find(
                        data => data.value == getParamsByName("type")
                      )
                    : ""
                }}
              >
                <Select
                  name="type"
                  placeholder="Select Type"
                  options={this.state.typeOption}
                  handleChange={e => {
                    this.handleTypeChnage(e);
                  }}
                />
              </Form>
            </div>
          </div>
        </div>

        {this.state.crossBeamData && this.state.crossBeamData.length > 0 ? (
          <>
            <CrossBeamPartnerCard
              getCrossBeamPartnerRecords={this.getCrossBeamPartnerRecords}
              crossbeamPartnerStatusUpdate={this.crossbeamPartnerStatusUpdate}
              crossBeamData={this.state.crossBeamData}
              pageSize={this.state.pageSize}
              sort={this.state.sort}
              type={this.state.type}
              status={this.state.status}
              history={this.props.history}
            />
          </>
        ) : (
          <NoRecordsFound
            icon={accountMappingIcon}
            message="No Account Mapping Yet"
            description="After you start a account mapping, they will show up here"
          />
        )}
        {this.state.crossBeamData.length > 0 && (
          <Row className="mt-3">
            <Col>
              Showing {startPage + 1} to {endPage} of {this.state.totalCount}{" "}
              entries
            </Col>
            <Col>
              <Pagination
                currentPage={this.state.currentPage}
                totalCount={this.state.totalCount}
                pageSize={this.state.pageSize}
                onPageChange={this.onPageChange.bind(this)}
              />
            </Col>
          </Row>
        )}
      </div>
    );
  }
}
