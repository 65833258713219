import React from "react";

import { getUrlParameter } from "../../lib/helper";

import RegisterDealForm from "../deals/RegisterDealForm";

const DealRegister = props => {
  const encryptedPartnerId = getUrlParameter("partner");

  return (
    <div className="container my-4">
      <RegisterDealForm
        publicDealForm={true}
        encryptedPartnerId={encryptedPartnerId}
        history={props.history}
        settings={props.settings}
      />
    </div>
  );
};

export default DealRegister;
