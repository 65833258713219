import React, { Component } from "react";
import toast from "../../components/base/Toast";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient, apiClientGet } from "../../apiClient";

// Components
import AdminActionNavigation from "../../components/base/AdminActionNavigation";
import Spinner from "../../components/base/Spinner";
import Form from "../../components/base/Form";
import Text from "../../components/base/Text";
import TextArea from "../../components/base/TextArea";
import SideBar from "../leads/inner-components/SideBar";
import PartnerAcceptLeadAlert from "../../components/PartnerAcceptLeadAlert";

// Assets
import { ChevronRight } from "../../assets/img/icons";

// Helper
import { isPartner } from "../../lib/helper";
import { LEAD_PARTNER_STATUS_SHORTLISTED } from "../../lead/Constants";
import Page404 from "../Page404";
import { isBadRequest } from "../../common/http";
import { connect } from "react-redux";

class ExpertRequestDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      expertRequestDetails: {},
      isLoading: false,
      leadId: this.props.match.params.id,
      isLeadUpdate: false,
      leadPartnerUser: "",
      isPageNotFound: false
    };
  }

  componentDidMount() {
    this._getExpertRequestDetails(this.props.match.params.id);
  }

  // Get Expert Request Details
  _getExpertRequestDetails(id) {
    this.setState({ isLoading: true });
    apiClientGet(
      `${endpoints().lead}/${this.props.match.params.id}`,
      (errMessage, response) => {
        if (errMessage) {
          this.setState({
            isPageNotFound: true,
            isLoading: false
          });
        } else {
          const expertRequestDetails = response && response.data;
          this.setState({
            expertRequestDetails,
            leadPartnerUser:
              expertRequestDetails && expertRequestDetails.leadPartnerUser,
            isLoading: false
          });
        }
      }
    );
  }

  updatePartnerLeadStatus = (status, leadId) => {
    return apiClient
      .put(
        `${
          endpoints().leadPartnerAPI
        }/updateStatus?leadId=${leadId}&status=${status}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);

          this._getExpertRequestDetails(this.props.match.params.id);
          this.setState({ isLeadUpdate: true });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
        }
      });
  };

  render() {
    const {
      firstName,
      lastName,
      email,
      description,
      expertType,
      projectType,
      timeline,
      timeCommitment,
      startDate,
      experts,
      budget,
      location,
      reference,
      additionalDetails
    } = this.state.expertRequestDetails;
    const initialValues = {
      firstName,
      lastName,
      email,
      description,
      expertType,
      projectType,
      timeline,
      timeCommitment,
      startDate,
      experts,
      budget,
      location,
      reference,
      additionalDetails
    };
    const minimal = projectType ? false : true;

    if (this.state.isLoading) return <Spinner />;

    if (this.state.isPageNotFound) return <Page404 />;

    return (
      <>
        <AdminActionNavigation>
          <div className="d-flex w-100 align-items-center">
            <a
              className="cursor-pointer"
              onClick={() => {
                this.props.history.push("/leads?section=New");
              }}
            >
              Leads
            </a>
            <span
              className="text-dark d-inline-flex align-items-center"
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <a
              className="cursor-pointer"
              onClick={() => this.props.history.goBack()}
            >
              Expert Request
            </a>
            <span
              className="text-dark d-flex align-items-cente"
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <span
              onClick={e => e.preventDefault()}
              className={[
                "text-dark",
                "font-weight-bold",
                "d-flex",
                "align-items-center",
                "project-name",
                "w-320"
              ].join(" ")}
            >
              <span className="ellipsis">
                Request #{this.props.match.params.id}
              </span>
            </span>
          </div>
        </AdminActionNavigation>
        {isPartner(this.props.roleId) &&
          this.state.leadPartnerUser &&
          this.state.leadPartnerUser.status ===
            LEAD_PARTNER_STATUS_SHORTLISTED && (
            <PartnerAcceptLeadAlert
              updatePartnerLeadStatus={this.updatePartnerLeadStatus}
              leadDetails={this.state.expertRequestDetails}
            />
          )}
        <div className="card bg-white mb-3">
          <div className="card-body">
            <div className="project-details-body">
              <div className="details-body-main">
                <div className="col-lg-12">
                  <Form initialValues={initialValues}>
                    <div className="field-wrapper">
                      <Text
                        name="firstName"
                        label="First Name"
                        error=""
                        disabled={true}
                      />
                    </div>
                    <div className="field-wrapper">
                      <Text
                        name="lastName"
                        label="Last Name"
                        error=""
                        disabled={true}
                      />
                    </div>
                    <div className="field-wrapper">
                      <Text
                        name="email"
                        label="Email"
                        error=""
                        disabled={true}
                      />
                    </div>
                    {minimal ? (
                      <div className="field-wrapper">
                        <TextArea
                          name="description"
                          label="Description"
                          error=""
                          disabled={true}
                        />
                      </div>
                    ) : (
                      <>
                        <div className="field-wrapper">
                          <Text
                            name="expertType"
                            label="Expert Type"
                            error=""
                            disabled={true}
                          />
                        </div>
                        <div className="field-wrapper">
                          <Text
                            name="projectType"
                            label="Project Type"
                            error=""
                            disabled={true}
                          />
                        </div>
                        <div className="field-wrapper">
                          <Text
                            name="timeline"
                            label="Timeline"
                            error=""
                            disabled={true}
                          />
                        </div>
                        <div className="field-wrapper">
                          <Text
                            name="timeCommitment"
                            label="Time Commitment"
                            error=""
                            disabled={true}
                          />
                        </div>
                        <div className="field-wrapper">
                          <Text
                            name="startDate"
                            label="Start Date"
                            error=""
                            disabled={true}
                          />
                        </div>
                        <div className="field-wrapper">
                          <TextArea
                            name="experts"
                            label="Experts"
                            error=""
                            disabled={true}
                          />
                        </div>
                        <div className="field-wrapper">
                          <Text
                            name="budget"
                            label="Budget"
                            error=""
                            disabled={true}
                          />
                        </div>
                        <div className="field-wrapper">
                          <TextArea
                            name="location"
                            label="Location"
                            error=""
                            disabled={true}
                          />
                        </div>
                        <div className="field-wrapper">
                          <TextArea
                            name="reference"
                            label="Reference"
                            error=""
                            disabled={true}
                          />
                        </div>
                        <div className="field-wrapper">
                          <TextArea
                            name="additionalDetails"
                            label="Additional Details"
                            error=""
                            disabled={true}
                          />
                        </div>
                      </>
                    )}
                  </Form>
                </div>
              </div>
              {/* Sidebar */}
              <SideBar
                leadDetails={this.state.expertRequestDetails}
                leadId={this.state.leadId}
                isLeadUpdate={this.state.isLeadUpdate}
              />
            </div>
          </div>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(ExpertRequestDetails);
