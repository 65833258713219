import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormGroup, Input, FormFeedback } from "reactstrap";
import Label from "./Label";

class SingleRadio extends React.Component {
  validate(value) {
    const { label, placeholder, required, error } = this.props;

    let errorMessage;
    const inputLabel = label || placeholder;
    const errorMessageLabel = error;

    if ((!value || !value.trim()) && required) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    }

    return errorMessage;
  }

  renderInput({ field, form: { touched, errors, values } }) {
    const {
      name,
      id,
      label,
      required,
      options,
      onRender,
      className,
      onChange,
      defaultValue,
      size,
      hideOptionName,
      showInline,
      radioClassName,
      selected,
      fontBold
    } = this.props;

    const errorMessage = touched[name] && errors[name] ? errors[name] : null;
    const inputId = id || name;
    const sizeStyle =
      size && size === "large"
        ? {
            height: "25px",
            width: "25px",
            verticalAlign: "middle"
          }
        : {};

    const fieldInput = (
      <FormGroup
        style={{ position: "relative" }}
        className={`${(!!errorMessage && "is-invalid") || ""}`}
      >
        {label && (
          <Label id={inputId} required={required}>
            <span style={{ fontWeight: "lighter" }}>{label}</span>
          </Label>
        )}

        <div className={`${className}`}>
          <div style={{ display: "block" }} className={radioClassName}>
            <Label
              check
              className={`${name} radio-item ${fontBold && "font-weight-bold"}`}
            >
              <Input
                type="radio"
                {...field}
                name={inputId}
                defaultChecked={defaultValue === true}
                className={`radio-button1 ${showInline && "mt-0"}`}
                style={sizeStyle}
                value={selected}
                onChange={onChange}
              />
              {!hideOptionName && (
                <p
                  className={size && size === "large" ? "ml-3 pt-1 mb-0" : ""}
                  style={{
                    fontSize: "16px",
                    fontWeight: fontBold ? "700" : "500"
                  }}
                >
                  {options}
                </p>
              )}
            </Label>
          </div>
        </div>

        {errorMessage && (
          <FormFeedback style={{ marginTop: 1, display: "block" }}>
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );

    if (!onRender) {
      return fieldInput;
    }

    return onRender(values, (err, render) => (render ? fieldInput : null));
  }

  render() {
    const { name, id } = this.props;

    return (
      <Field
        id={id || name}
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

SingleRadio.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  )
};

export default SingleRadio;
