import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { WarningRedIcon } from "../../../assets/img/icons";
import CancelButton from "../../../components/base/CancelButton";

const PaymentOverdueModal = ({ modal, setModal }) => (
  <Modal
    isOpen={modal}
    toggle={setModal}
    className={["log-hours-modal", "edit-task-modal"].join(" ")}
  >
    <ModalHeader toggle={setModal}>
      <WarningRedIcon />
      <h4 className={["font-weight-bold", "my-4"].join(" ")}>
        Your Payment is Overdue
      </h4>
    </ModalHeader>
    <ModalBody className={["text-center", "mb-4"].join(" ")}>
      <div className="form-wrapper">
        <div className="field-wrapper">
          <p>
            Be sure to make your payment for{" "}
            <strong className="font-weight-bold">
              “High-Conversion Landing Page”
            </strong>
            to ensure that your account remains in good standing.
          </p>
        </div>
      </div>
    </ModalBody>
    <ModalFooter className={["justify-content-center"].join(" ")}>
      <div className="btn-wrapper">
        <CancelButton onClick={setModal} />
        <button className="btn btn-primary">View Project</button>
      </div>
    </ModalFooter>
  </Modal>
);

export default PaymentOverdueModal;
