import React, { useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";

// Components
import FilePreview from "../../../components/base/FilePreview";

// Icons
import { FilePlusIcon } from "../../../assets/img/icons";

// API clients
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";
import { isBadRequest } from "../../../common/http";

const AssetsUploader = props => {
  const {
    projectDeliverableId,
    showUploader,
    handleShowUploader,
    currentTaskPartnerId
  } = props;
  // Set the state
  const [files, setFiles] = useState([]);
  const [uploaded, setUploaded] = useState(false);
  const [preview, setPreview] = useState(false);
  const [
    projectDeliverableMaterials,
    setProjectDeliverableMaterials
  ] = useState([]);

  // Dropzone
  const { acceptedFiles, getRootProps, getInputProps } = useDropzone({
    accept: ".docx, .doc, application/*, image/*, text/*",
    onDrop: acceptedFiles => {
      setPreview(true);
      setFiles(
        acceptedFiles.map(file =>
          Object.assign(file, {
            preview: URL.createObjectURL(file)
          })
        )
      );
    }
  });

  // Get project deliverable materials list
  const getProjectDeliverableMaterials = projectDeliverableId => {
    return apiClient
      .get(
        `${
          endpoints().projectDeliverableMaterialAPI
        }?projectDeliverableId=${projectDeliverableId}`,
        { partner_id: currentTaskPartnerId }
      )
      .then(response => {
        const projectDeliverableMaterialList = response.data.data;
        setProjectDeliverableMaterials(projectDeliverableMaterialList);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Create Project deliverable material
  const createProjectDeliverableMaterial = file => {
    const data = new FormData();
    data.append("projectDeliverableId", projectDeliverableId);
    data.append("file", file);
    data.append("partner_id", currentTaskPartnerId);
    return apiClient
      .post(endpoints().projectDeliverableMaterialAPI, data)
      .then(response => {
        if (response && response.data) {
          setFiles([]);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Delete project deliverable materials list
  const deleteProjectDeliverableMaterials = projectDeliverableMaterialId => {
    return apiClient
      .delete(
        `${
          endpoints().projectDeliverableMaterialAPI
        }/${projectDeliverableMaterialId}`
      )
      .then(response => {
        getProjectDeliverableMaterials(projectDeliverableId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Call API
  useEffect(() => {
    getProjectDeliverableMaterials(projectDeliverableId);
    files.forEach(file => URL.revokeObjectURL(file.preview));
    files.length > 0 && handleShowUploader(false);
  }, [files]);

  useEffect(() => {
    setFiles([]);
    handleShowUploader(false);
  }, [projectDeliverableId]);

  // Set file upload status
  const fileUploaded = uploaded => {
    uploaded && getProjectDeliverableMaterials(projectDeliverableId);
    setUploaded(uploaded);
  };

  // File previews
  const filePreviews = acceptedFiles =>
    acceptedFiles.map(file => (
      <FilePreview
        key={file.path}
        img={""}
        preview={file.preview}
        name={file.name}
        file={file}
        createProjectDeliverableMaterial={createProjectDeliverableMaterial}
        deleteProjectDeliverableMaterials={deleteProjectDeliverableMaterials}
        fileUploaded={fileUploaded}
        handleShowUploader={handleShowUploader}
        isPreview
        hidepreview={false}
      />
    ));

  // Project Deliverable material
  const projectDeliveriableMaterials = acceptedFiles =>
    acceptedFiles.map(
      file =>
        file.partner_id === currentTaskPartnerId && (
          <FilePreview
            key={file.path}
            img={""}
            preview={file.fileUrl}
            name={file.name}
            id={file.id}
            file={file}
            createProjectDeliverableMaterial={createProjectDeliverableMaterial}
            deleteProjectDeliverableMaterials={
              deleteProjectDeliverableMaterials
            }
            handleShowUploader={handleShowUploader}
            isPreview={false}
          />
        )
    );

  const getAssetsUploaderClass =
    (projectDeliverableMaterials && projectDeliverableMaterials.length) ||
    files.length > 0
      ? "assets-uploader"
      : "";

  return (
    <div className={getAssetsUploaderClass}>
      {/*Added project deliverable material list*/}
      {projectDeliverableMaterials &&
        projectDeliveriableMaterials(projectDeliverableMaterials).length >
          0 && (
          <div className={["file-list"].join(" ")}>
            {projectDeliveriableMaterials(projectDeliverableMaterials)}
          </div>
        )}

      {/*Show project deliverable material upload data*/}
      {!uploaded && preview && filePreviews(files).length > 0 && (
        <div className={["file-list"].join(" ")}>
          {filePreviews(acceptedFiles)}
        </div>
      )}
      {/* Dropzone */}
      {showUploader && (
        <div
          className={`${!preview ? "dropzone-wrapper flex-column" : "d-none"}`}
          onClick={() => {
            setPreview(prevState => !prevState);
          }}
        >
          <div
            {...getRootProps({
              className:
                "dropzone text-center d-flex align-items-center justify-content-center p-3 cursor-pointer"
            })}
          >
            <input {...getInputProps()} />
            <div className="icon-wrapper mr-3">
              <FilePlusIcon />
            </div>
            <p className="mb-0">
              Drag & Drop to Upload Deliverable or Click to Select
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default AssetsUploader;
