import React from "react";

import { apiClient } from "../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../configs";

// Helper
import { getCookie } from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

// Components
import Select from "./base/Select";
import { isBadRequest } from "../common/http";

class PartnerTier extends React.Component {
  state = {
    partnerTierList: "",
    defaultPartnerTier: [],
    defaultTier: []
  };

  // Get partner tier
  getPartnerTier = async () => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    try {
      const response = await apiClient.get(
        `${endpoints().partnerTierAPI}/search`
      );
      const results = response.data.data;

      if (results && results.length > 0) {
        const tierList = [];
        results.forEach(tier => {
          tierList.push({
            id: tier.id,
            name: tier.name,
            sort: tier.sort,
            value: tier.id,
            label: tier.name,
            typeName: tier.partner_type,
            partnerType: tier.partnerType,
            isDefault: tier.isDefault
          });
        });
        this.setState({ partnerTierList: tierList });
        let defaultPartnerTier = [];
        if (tierList && tierList.length > 0) {
          tierList.forEach(list => {
            if (list.isDefault === 1) {
              defaultPartnerTier.push(list);
            }
          });
          this.setState({ defaultPartnerTier: defaultPartnerTier });
        }
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  componentDidMount() {
    this.getPartnerTier();
  }

  //  Get options
  getPartnerTierOptions = typeId => {
    const { partnerTierList } = this.state;
    let options = [];
    let partnerTierOptions = [];

    if (!partnerTierList) return partnerTierOptions;

    // based on partner typeid getting tier options
    typeId.forEach(data => {
      partnerTierList
        .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
        .forEach(tier => {
          tier &&
            tier.partnerType.forEach(element => {
              if (data.id === element.id) {
                options.push({ value: tier.id, label: tier.name });
              }
            });
        });
    });
    // get tier ids to compair
    const tierIds = options.map(tier => tier.value);
    // filtering duplicate tier
    partnerTierOptions = options.filter(
      ({ value }, index) => !tierIds.includes(value, index + 1)
    );

    return partnerTierOptions;
  };

  render() {
    const {
      name,
      label,
      placeholder,
      error,
      required,
      isSearchable,
      fullWidth,
      id,
      isDisabled,
      readOnly,
      showDefaultTier,
      typeId
    } = this.props;

    return (
      <Select
        id={id || name}
        name={name}
        label={label}
        readOnly={readOnly}
        placeholder={placeholder}
        options={this.getPartnerTierOptions(typeId)}
        fullWidth={fullWidth}
        isSearchable={isSearchable}
        error={error}
        {...{ required }}
        isDisabled={isDisabled}
        defaultValue={showDefaultTier ? this.state.defaultPartnerTier : ""}
      />
    );
  }
}

export default PartnerTier;
