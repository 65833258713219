import React, { useState } from "react";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";
import SaveButton from "../../../components/base/SaveButton";
import PageTitle from "../../../components/base/PageTitle";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownItem
} from "reactstrap";

// Components
import Form from "../../../components/base/Form";
import Text from "../../../components/Text";
import Select from "../../../components/base/Select";
import TextArea from "../../../components/base/TextArea";

// Settings
import AddButton from "../../../components/base/AddButton";
import ReduxTable, { ReduxColumn } from "../../../components/base/ReduxTable";
import { endpoints } from "../../../configs";
import MoreDropdown from "../../../components/base/MoreDropdown";

// Tags
import {
  schedulerCreate,
  schedulerUpdate,
  schedulerDelete
} from "../../../actions/scheduler";
import DeleteModal from "../../../components/base/DeleteModal";

const Scheduler = props => {
  // initilize states
  const [isOpen, setIsOpen] = useState(false);
  const [openSceduler, setOpenScheduler] = useState(false);
  const [openEditModal, _setEditModal] = useState(false);

  const [setId, schedulerId] = useState("");
  const [name, setSchedulerName] = useState("");
  const [interval, setInterval] = useState("");
  const [ApiUrl, setSchedulerApiUrl] = useState("");
  const [status, setStatus] = useState("");
  const [notes, setNotes] = useState("");
  const [deleteShedulerModal, setdeleteShedulerModal] = useState(false);
  const [currentSheduler, setCurrentSheduler] = useState(false);

  const statusOptions = () => [
    {
      value: "Enable",
      label: "Enable"
    },
    {
      value: "Disable",
      label: "Disable"
    }
  ];

  const getSchedulerTime = () => [
    {
      value: 1,
      label: "1 min"
    },
    {
      value: 5,
      label: "5 min"
    },
    {
      value: 15,
      label: "15 min"
    },
    {
      value: 30,
      label: "30 min"
    },
    {
      value: 60,
      label: "1 Hour"
    },
    {
      value: 120,
      label: "2 Hour"
    }
  ];

  const initialValues = {
    name: name || "",
    interval: interval
      ? getSchedulerTime().find(type => type.value === interval)
      : "",
    status: status ? statusOptions().find(type => type.value === status) : "",
    ApiUrl: ApiUrl || "",
    notes: notes || ""
  };

  /**
   * Create scheduler
   *
   * @param data
   */
  const createScheduler = data => {
    props.actions.schedulerCreate(data).then(detail => {
      if (detail) {
        _schedulerNewModalToggle();
      } else setOpenScheduler(true);
    });
  };

  /**
   * Update scheduler
   *
   * @param data
   * @param id
   */
  const updateSchedulerData = data => {
    props.actions.schedulerUpdate(setId, data).then(detail => {
      if (detail) {
        _schedulerToggle();
      } else _setEditModal(true);
    });
  };

  /**
   * Delete scheduler
   *
   * @param id
   */
  const deleteSchedulerData = id => {
    props.actions.schedulerDelete(id);
  };

  // Scheduler toggle modal
  const _schedulerToggle = data => {
    schedulerId((data && data.id) || "");
    setSchedulerName((data && data.name) || "");
    setInterval((data && data.interval) || "");
    setSchedulerApiUrl((data && data.apiUrl) || "");
    setStatus((data && data.status) || "");
    setNotes((data && data.notes) || "");
    _setEditModal(!openEditModal);
  };
  const _schedulerNewModalToggle = () => {
    setOpenScheduler(!openSceduler);
  };

  const getInterval = interval => {
    let TimeInterval;
    getSchedulerTime().forEach(Time => {
      if (interval === Time.value) {
        TimeInterval = Time.label;
      }
    });
    return TimeInterval;
  };

  // Render the scheduler Add modal
  const schedulerModal = () => (
    <div>
      <Modal
        isOpen={openSceduler}
        toggle={_schedulerNewModalToggle}
        className="landing-create-popup w-100"
        backdrop="static"
      >
        <ModalHeader
          toggle={_schedulerNewModalToggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <p>Add Scheduler</p>
        </ModalHeader>
        <Form
          initialValues={{
            name: ""
          }}
          onSubmit={values => {
            values.name = values.name.trim();
            createScheduler(values);
          }}
          enableReinitialize={true}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div>
              <Text
                name="name"
                label="Scheduler Name"
                placeholder="Enter scheduler name"
                error=""
                required={true}
                defaultValue={name}
                style={{}}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="">
                  Add
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );

  // Render the scheduler Edit modal
  const schedulerEditModal = () => (
    <div>
      <Modal
        isOpen={openEditModal}
        toggle={_schedulerToggle}
        className="landing-create-popup w-100"
        backdrop="static"
      >
        <ModalHeader
          toggle={_schedulerToggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <p>Edit Scheduler</p>
        </ModalHeader>
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            values.name = values.name.trim();
            const intervalTime = isNaN(values.interval)
              ? values.interval.value
              : values.interval;
            values.interval = intervalTime;
            const statusData =
              typeof values.status === "object"
                ? values.status.value
                : values.status;
            values.status = statusData;
            updateSchedulerData(values);
          }}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div className="field-wrapper">
              <Text
                name="name"
                label="Scheduler Name"
                placeholder="Enter scheduler name"
                error=""
                required={true}
                defaultValue={name}
              />
            </div>
            <div className="field-wrapper">
              <Select
                name="interval"
                label="Interval"
                placeholder="Select Interval"
                error={""}
                required
                options={getSchedulerTime()}
                defaultValue={getSchedulerTime().find(
                  type => type.value === interval
                )}
                isSearchable={true}
              />
            </div>
            <div className="field-wrapper">
              <Select
                name="status"
                label="Status"
                placeholder="Select status"
                required
                options={statusOptions()}
                defaultValue={statusOptions().find(
                  type => type.value === status
                )}
                isSearchable={true}
              />
            </div>{" "}
            <div className="field-wrapper">
              <Text
                name="ApiUrl"
                label="API URL"
                placeholder="Enter API URL"
                defaultValue={ApiUrl}
                error=""
                required={true}
              />
            </div>{" "}
            <div className="field-wrapper">
              <TextArea
                name="notes"
                label="Notes"
                placeholder="Enter notes"
                defaultValue={notes}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <SaveButton />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );

  return (
    <>
      <div>
        {schedulerModal()}
        {schedulerEditModal()}
        {/* page heading */}
        <div>
          <AddButton
            label="Add New"
            className="pull-right btn btn-secondary mb-4"
            onClick={() => {
              _schedulerNewModalToggle();
            }}
          />
        </div>

        <PageTitle adminSettings={true} label="Scheduler" />

        <ReduxTable
          id="scheduler"
          apiURL={`${endpoints().schedulerJobAPI}/search`}
          onRowClick={row => {
            _schedulerNewModalToggle(row);
          }}
        >
          <ReduxColumn width={"120px"} field="name">
            Name
          </ReduxColumn>
          <ReduxColumn
            width={"128px"}
            className="text-center"
            field="interval"
            renderField={row => getInterval(row.interval)}
          >
            Interval
          </ReduxColumn>
          <ReduxColumn width={"120px"} field="status">
            Status
          </ReduxColumn>
          <ReduxColumn
            width={"100px"}
            disableOnClick
            className="action-column"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      _schedulerToggle(row);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setCurrentSheduler(row);
                      setdeleteShedulerModal(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          isOpen={deleteShedulerModal}
          toggle={() => {
            setdeleteShedulerModal(prevState => !prevState);
          }}
          title="Delete scheduler"
          id={currentSheduler.id}
          label={currentSheduler.name}
          deleteFunction={deleteSchedulerData}
        />
      </div>
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        schedulerCreate,
        schedulerUpdate,
        schedulerDelete
      },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(Scheduler);
