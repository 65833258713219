import React from "react";

// Icons
import { ArrowRight, HandsIcon } from "../../assets/img/icons";

// Components
import SecondaryButton from "../../components/base/SecondaryButton";

class RequestReceived extends React.Component {
  render() {
    const { currentStep, step, prev, close } = this.props;
    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className={`content-wrapper custom-width`}>
        <div className="title-wrapper d-flex align-items-center">
          <HandsIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-3">You're ready!</h2>
        </div>
        <div className="content">
          <p className="h5 lh-2">
            Your account is now setup and you are ready to start using our
            Partner Portal.
          </p>
          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />
            <button
              type="submit"
              className="btn btn-primary d-flex align-items-center"
              onClick={close}
            >
              Go to Partner Portal <ArrowRight />
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default RequestReceived;
