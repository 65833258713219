import reactStringReplace from "react-string-replace";
import SelectDropdown from "../base/SelectDropdown";
import React, { Component } from "react";
import CheckboxDropdown2 from "../base/CheckboxDropdownV2";
import LocationSearchInput from "../base/LocationSearchInput";

export default class Sentence extends Component {
  render() {
    const {
      actualParagraph,
      select,
      getSelectedFilters,
      liveFilters,
      id
    } = this.props;
    this.newSentence = actualParagraph;

    getSelectObjectsFrom(actualParagraph).forEach((objectName, index) => {
      this.newSentence = reactStringReplace(
        this.newSentence,
        `{${objectName}}`,
        () => {
          return select &&
            select[objectName] &&
            select[objectName][0] &&
            select[objectName][0].multiselect !== undefined ? (
            <CheckboxDropdown2
              id={id}
              buttonLabel={
                select[objectName][1]
                  ? select[objectName][1].label
                  : select[objectName][0].label
              }
              dropdownLinks={select[objectName]}
              hideCaret
              hideChevron
              key={objectName + index}
              getSelectedFilters={getSelectedFilters}
              selectName={objectName}
              liveFilters={liveFilters}
            />
          ) : select &&
            select[objectName] &&
            select[objectName][0] &&
            select[objectName][0].location ? (
            <LocationSearchInput
              selectName={objectName}
              getSelectedFilters={getSelectedFilters}
              liveFilters={liveFilters}
            />
          ) : (
            select &&
            select[objectName] &&
            select[objectName][0] && (
              <SelectDropdown
                id={id}
                buttonLabel={select[objectName][0].label}
                dropdownLinks={select[objectName]}
                hideCaret
                hideChevron
                key={objectName + index}
                getSelectedFilters={getSelectedFilters}
                selectName={objectName}
                liveFilters={liveFilters}
                nlFormFilter={true}
              />
            )
          );
        }
      );
    });

    return <h4>{this.newSentence}</h4>;
  }
}

function getSelectObjectsFrom(paragraph) {
  let results = [];
  let re = /{([^}]+)}/g;
  let text;
  while ((text = re.exec(paragraph))) {
    results.push(text[1]);
  }

  return results;
}
