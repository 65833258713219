import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CancelButton from "../../../../components/base/CancelButton";

const RescheduleModal = React.memo(function RescheduleModal({
  isOpen,
  toggle,
  resetKickoffTimes,
  rescheduleKickOffExpert
}) {
  const [message, setMessage] = useState(null);

  const onChangeMessage = e => {
    setMessage(e.target.value);
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={["edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={toggle}>
        <div
          className={["d-flex", "flex-column", "align-items-center"].join(" ")}
        >
          <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
            Reschedule Kickoff
          </h4>
        </div>
      </ModalHeader>
      <ModalBody className={["mb-4", "px-0"].join(" ")}>
        <div className={["text-center", "modal-body-subtitle"].join(" ")}>
          <p>
            Only request to reschedule if absolutely necessary and keep the
            customer’s time zone in mind. You can send a message as to why you
            need to reschedule and what times work best for you if you’d like.
          </p>
        </div>
        <div className="form-wrapper justify-content-center d-flex">
          <div className="field-wrapper w-100">
            <label>Your message (optional)</label>
            <Input
              id="inputId"
              type="textarea"
              placeholder="Your message..."
              style={{
                background: "#F3F3F4",
                border: "none",
                borderRadius: "5px",
                fontSize: "14px",
                minHeight: "112px"
              }}
              onChange={onChangeMessage}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={["justify-content-center"].join(" ")}>
        <div className="btn-wrapper">
          <CancelButton onClick={toggle} />
          <button
            className="btn btn-primary"
            onClick={() => {
              rescheduleKickOffExpert(message);
              toggle();
            }}
          >
            Reschedule Kickoff
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
});

export default RescheduleModal;
