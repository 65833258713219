import React from "react";
import { TabContent, TabPane } from "reactstrap";

// Constants
import { JOBS_DEFAULT_SUB_TAB } from "../../../..//setting/Constants";

// Sub Tab  Sections
import DefaultSection from "./DefaultSection";
// import OthersSection from "./OthersSection";

const JobsTab = props => {
  const { activeSubTab, adminSettings, saveSettings, history } = props;

  return (
    <>
      <TabContent activeTab={activeSubTab}>
        {/* Default Tab */}
        <TabPane tabId={JOBS_DEFAULT_SUB_TAB} className="w-100">
          <DefaultSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        {/* Others Tab
        <TabPane tabId={JOBS_OTHERS_SUB_TAB} className="w-100">
          <OthersSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane> */}
      </TabContent>
    </>
  );
};

export default JobsTab;
