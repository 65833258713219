import React, { Component } from "react";

import { Field } from "formik";
// Components
import DefaultContent from "../../../../components/content/DefaultContent";
import Form from "../../../../components/base/Form";
import Radio from "../../../../components/base/Radio";
import Text from "../../../../components/base/Text";
import ToggleSwitch from "../../../../components/base/ToggleSwitch";
import SingleCheckbox from "../../../../components/base/SingleCheckbox";
import Spinner from "../../../../components/base/Spinner";

// Constants
import {
  SETTINGS_ENABLE_REFERRAL_PAYMENT_TRACKING,
  SETTINGS_PARTNERS_SUBMIT_INVOICE_MANUALLY,
  SETTINGS_PARTNER_SUBMIT_MAIL
} from "../../../../setting/Constants";
import {
  AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_DEAL_CLOSED,
  AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_CUSTOMER_PAYMENT_HAS_BEEN_MADE,
  DISABLE_INVOICE_GENERATION,
  PAYMENT_MADE_IN_AP_PROVIDER,
  PAYMENT_MADE_IN_TORCHLITE,
  AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_DEAL_CLOSED_VALUE,
  AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_CUSTOMER_PAYMENT_HAS_BEEN_MADE_VALUE,
  DISABLE_INVOICE_GENERATION_VALUE,
  ENABLE_INVOICE_SETTINGS
} from "../../../../invoice/Constants";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";
export default class ReferralPaymentsTab extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      showTextArea: null,
      enable_payment_workflow:
        props.adminSettings &&
        props.adminSettings.enable_payment_workflow === true
          ? PAYMENT_MADE_IN_AP_PROVIDER
          : PAYMENT_MADE_IN_TORCHLITE,
      enable_referral_payment_tracking: this.props.adminSettings
        .enable_referral_payment_tracking
    };
  }
  // Handle Enable Invoice Settings
  handleEnableInvoiceSettings = value => {
    const data = new FormData();
    if (value !== undefined) {
      data.append(
        "enable_invoice_settings",
        value === AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_DEAL_CLOSED
          ? AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_DEAL_CLOSED_VALUE
          : value ===
            AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_CUSTOMER_PAYMENT_HAS_BEEN_MADE
          ? AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_CUSTOMER_PAYMENT_HAS_BEEN_MADE_VALUE
          : value === DISABLE_INVOICE_GENERATION
          ? DISABLE_INVOICE_GENERATION_VALUE
          : ""
      );
    }
    // Save settings
    this.props.saveSettings(data);
  };

  // Payment Workflow
  handlePaymentWorkflow = value => {
    const data = new FormData();
    this.setState({ showTextArea: value });
    if (value !== undefined) {
      data.append(
        "enable_payment_workflow",
        value === PAYMENT_MADE_IN_AP_PROVIDER
          ? "true"
          : value === PAYMENT_MADE_IN_TORCHLITE
          ? "false"
          : ""
      );
    }
    // Save settings
    this.props.saveSettings(data);
  };

  // partner submit mail
  handleChange = value => {
    const data = new FormData();

    if (value !== undefined) {
      data.append("partner_submit_mail", value);
    }

    // Save settings
    this.props.saveSettings(data);
  };

  // Handle enable Referral Payment Tracking
  handleEnableReferralPaymentTracking = async value => {
    const data = new FormData();

    if (value !== undefined) {
      data.append(
        "enable_referral_payment_tracking",
        value === true ? "true" : "false"
      );
    }

    // Save settings
    await this.props.saveSettings(data);
    this.setState({ enable_referral_payment_tracking: value.toString() });
  };

  render() {
    const { adminSettings } = this.props;
    const { isLoading, enable_referral_payment_tracking } = this.state;
    const {
      enable_invoice_settings,
      enable_payment_workflow,
      enable_partner_submit_invoice_manually,
      partner_submit_mail
    } = adminSettings;

    if (isLoading) {
      return <Spinner />;
    }

    const showText = this.state.showTextArea
      ? this.state.showTextArea
      : enable_payment_workflow == "true"
      ? PAYMENT_MADE_IN_AP_PROVIDER
      : PAYMENT_MADE_IN_TORCHLITE;

    const initialValues = {
      partner_submit_mail: partner_submit_mail,
      enable_referral_payment_tracking:
        enable_referral_payment_tracking === "true",
      enable_partner_submit_invoice_manually:
        enable_partner_submit_invoice_manually === "true"
    };
    return (
      <DefaultContent>
        <Form initialValues={initialValues} onSubmit={() => {}}>
          <div className="form-wrapper">
            <div className="d-flex justify-content-between align-items-center mb-5">
              <span>
                Enable {Terminology.get(SystemName.REFERRAL)} Payment Tracking
              </span>
              <div>
                <Field
                  name={SETTINGS_ENABLE_REFERRAL_PAYMENT_TRACKING}
                  render={({ field, form }) => {
                    return (
                      <ToggleSwitch
                        name={SETTINGS_ENABLE_REFERRAL_PAYMENT_TRACKING}
                        title={field.value}
                        value={field.value}
                        handleChange={() => {
                          this.handleEnableReferralPaymentTracking(
                            !field.value
                          );
                          form.setFieldValue(
                            SETTINGS_ENABLE_REFERRAL_PAYMENT_TRACKING,
                            !field.value
                          );
                        }}
                        outlined
                      />
                    );
                  }}
                />
              </div>
            </div>
            {enable_referral_payment_tracking == "true" && (
              <div>
                <h6 className="font-weight-bold mb-3">Invoice Settings</h6>
                <div className="px-4 w-100">
                  <Radio
                    name={ENABLE_INVOICE_SETTINGS}
                    options={[
                      AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_DEAL_CLOSED,
                      AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_CUSTOMER_PAYMENT_HAS_BEEN_MADE,
                      DISABLE_INVOICE_GENERATION
                    ]}
                    size="large"
                    onChange={e => {
                      this.handleEnableInvoiceSettings(e.target.value);
                    }}
                    defaultValue={
                      enable_invoice_settings &&
                      enable_invoice_settings ==
                        AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_DEAL_CLOSED_VALUE
                        ? AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_DEAL_CLOSED
                        : enable_invoice_settings ==
                          AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_CUSTOMER_PAYMENT_HAS_BEEN_MADE_VALUE
                        ? AUTOMATICALLY_GENERATE_AN_INVOICE_WHEN_CUSTOMER_PAYMENT_HAS_BEEN_MADE
                        : enable_invoice_settings ==
                          DISABLE_INVOICE_GENERATION_VALUE
                        ? DISABLE_INVOICE_GENERATION
                        : ""
                    }
                  />
                </div>
                <h6 className="font-weight-bold mb-3 mt-4">Payment Workflow</h6>
                <div className="px-4 w-100">
                  <Radio
                    name={"enable_payment_workflow"}
                    options={[PAYMENT_MADE_IN_AP_PROVIDER]}
                    size="large"
                    onChange={e => {
                      this.handlePaymentWorkflow(e.target.value);
                    }}
                    defaultValue={
                      enable_payment_workflow &&
                      enable_payment_workflow === "true"
                        ? PAYMENT_MADE_IN_AP_PROVIDER
                        : ""
                    }
                  />
                  {showText == PAYMENT_MADE_IN_AP_PROVIDER && (
                    <div className="field-wrapper pl-3 font-weight-bold w-75">
                      <Text
                        name={SETTINGS_PARTNER_SUBMIT_MAIL}
                        label="Send invoice to email address"
                        required={true}
                        onBlur={e => {
                          this.handleChange(e.target.value);
                        }}
                      />
                    </div>
                  )}
                  <Radio
                    name={"enable_payment_workflow"}
                    options={[PAYMENT_MADE_IN_TORCHLITE]}
                    size="large"
                    onChange={e => {
                      this.handlePaymentWorkflow(e.target.value);
                    }}
                    defaultValue={
                      enable_payment_workflow &&
                      enable_payment_workflow === "false"
                        ? PAYMENT_MADE_IN_TORCHLITE
                        : ""
                    }
                  />
                </div>
              </div>
            )}
          </div>
        </Form>
      </DefaultContent>
    );
  }
}
