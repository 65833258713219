import React from "react";

// Components
import Select from "./base/Select";

class PreferredMethodOfCommunication extends React.Component {
  render() {
    const communicationMethodList = [
      {
        label: "Web Conference",
        value: "Web Conference"
      },
      {
        label: "Phone",
        value: "Phone"
      }
    ];

    const {
      name,
      label,
      placeholder,
      error,
      required,
      defaultValue,
      onChange,
      isDisabled,
      id
    } = this.props;

    return (
      <Select
        id={id || label}
        name={name}
        label={label}
        options={communicationMethodList}
        // placeholder={placeholder}
        error={error}
        required={required}
        defaultValue={defaultValue || ""}
        onInputChange={onChange}
        isDisabled={isDisabled}
      />
    );
  }
}

export default PreferredMethodOfCommunication;
