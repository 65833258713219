import React from "react";
import Page404 from "../Page404";
import toast from "../../components/base/Toast";
import CompanyFormFields from "./inner-component/CompanyFormFields";

// Components
import Form from "../../components/base/Form";
import DeleteButton from "../../components/base/DeleteButton";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import PageTitle from "../../components/base/PageTitle";

// Configs
import { endpoints } from "../../configs";

// API call

import { apiClient } from "../../apiClient";

// Helper
import { removeMaskedPhoneNumber, isSuperAdmin } from "../../lib/helper";
import { isBadRequest } from "../../common/http";

import { connect } from "react-redux";

class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      companyDetails: [],
      loading: true,
      isInvalidRequest: false,
      isPageNotFound: false
    };
  }

  componentDidMount = () => {
    if (isSuperAdmin(this.props.roleId)) {
      this._getCompanyDetails();
    } else {
      this.setState({
        isPageNotFound: true
      });
    }
  };

  // Company update API Call
  _updateCompany(data) {
    return apiClient
      .put(`${endpoints().companyAPI}/${this.props.match.params.id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Company delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().companyAPI}/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;

          toast.success(successMessage);

          this.props.history.push("/companies");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Company details api call by id
  _getCompanyDetails = () => {
    return apiClient
      .get(`${endpoints().companyAPI}/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({ companyDetails: response.data, loading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  render() {
    if (
      !isSuperAdmin(this.props.roleId) ||
      this.state.isInvalidRequest ||
      this.state.isPageNotFound
    ) {
      return <Page404 />;
    }

    if (this.state.loading) {
      return "";
    }

    const {
      companyName,
      email,
      phone,
      address1,
      address2,
      city,
      state,
      country,
      zipCode
    } = this.state.companyDetails;

    const initialValues = {
      companyName,
      email,
      phone,
      address1,
      address2,
      city,
      state:
        state && state != "null"
          ? {
              label: state,
              value: state
            }
          : "",
      country:
        country && country != "null"
          ? {
              label: country,
              value: country
            }
          : "",
      zipCode
    };

    return (
      <>
        <div className="d-flex justify-content-between align-items-center">
          <PageTitle label="Edit Company" />
        </div>
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            values.phone =
              values.phone && removeMaskedPhoneNumber(values.phone);

            values.stateName = values.state ? values.state.value : values.state;

            values.countryName = values.country
              ? values.country.label
              : values.country;

            this._updateCompany(values);
          }}
        >
          <CompanyFormFields
            selectedCountryName={country}
            compnyDetails={this.state.companyDetails}
          />

          <div className="mb-4">
            <DeleteButton
              label={"Delete Company"}
              onClick={this.handleDelete}
            />
            <div className="float-right">
              <CancelButton
                onClick={() => this.props.history.push("/companies")}
              />
              <SaveButton />
            </div>
          </div>
        </Form>
      </>
    );
  }
}

const mapStateToProps = state => {
  const roleId = state.user ? state.user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(CompanyDetails);
