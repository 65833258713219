import React, { useState, useEffect } from "react";
import { Media } from "reactstrap";
import Avatar from "../../../components/base/Avatar";
import { CUSTOMER_ROLE } from "../../../roles/Constants";

const ProjectNotes = props => {
  const [customerUser, setCustomerUser] = useState({});

  const { projectNotes, projectTeam } = props;

  // Set Customer User from Team
  const setCustomerUserFromTeam = team => {
    team.forEach(user => {
      if (user.role == CUSTOMER_ROLE) {
        setCustomerUser(user);
      }
    });
  };

  useEffect(() => {
    if (projectTeam && projectTeam.length > 0) {
      setCustomerUserFromTeam(projectTeam);
    }
  }, [projectNotes, projectTeam]);

  return (
    <div className="discussions">
      <div className="title-wrapper">
        <h6>Project Details:</h6>
      </div>

      <div className={"discussions-body"}>
        <div className="discussion-list">
          <Media className="user-rating">
            <div className="field-icon mr-3">
              <Avatar
                firstName={customerUser.firstName}
                lastName={customerUser.lastName}
                size="xs"
                // fontSize={15}
                url={customerUser.avatarUrl}
              />
            </div>

            <Media body>
              <div className="rating-and-usage">
                <div className="rating-wrapper d-flex align-items-center">
                  <span className="h7">
                    <b>
                      {customerUser.firstName + " " + customerUser.lastName}
                    </b>
                  </span>
                </div>
              </div>
              <div
                className={"h7 text-justify"}
                dangerouslySetInnerHTML={{
                  __html: projectNotes ? projectNotes : ""
                }}
              ></div>
            </Media>
          </Media>
        </div>
      </div>
    </div>
  );
};

export default ProjectNotes;
