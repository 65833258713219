import React from "react";

// History
import defaultAvatar from "../assets/default-avatar.png";
import { CheckmarkIcon, ElitePartner } from "../assets/img/icons";
import { ELITE_PARTNER } from "../partnerType/Constants";

const PartnerCard = props => {
  const {
    id,
    avatarUrl,
    firstName,
    lastName,
    city,
    stateName,
    skills,
    companyName,
    type,
    aboutMe,
    headline
  } = props.data;
  const {
    classnames,
    fixedWidth,
    size,
    certified,
    openLinkInNewTab,
    handlePartners,
    selected,
    selectPartners,
    showDeleteIcon
  } = props;

  let name = [];
  if (firstName) {
    name.push(firstName);
  }
  if (lastName) {
    name.push(lastName);
  }

  name = name.join(" ");

  const location = [];
  if (city) {
    location.push(city);
  }
  if (stateName) {
    location.push(stateName);
  }

  const skillCount = skills && skills.length > 0 ? skills.length : 0;

  const showSkills = skills => {
    const max_skills = skills.length;
    let skills_arr = [];
    for (let i = 0; i < skills.length; i++) {
      skills_arr.push(
        <span className="badge badge-pill badge-dark">{skills[i].name}</span>
      );
      if (i === 1) {
        break;
      }
    }
    if (max_skills > 2) {
      skills_arr.push(
        <span className="badge badge-pill badge-orange">{`+${max_skills -
          2}`}</span>
      );
    }
    return skills_arr;
  };
  const isActivePartners = selected ? "selected" : "";
  const activePartners = `expert-select ${isActivePartners}`;
  const removeSelectPartner = `float-right cursor-pointer remove-expert far fa-times-circle`;

  let cardLink = props.link;

  const setCardLink = () => {
    cardLink = props.link ? props.link : "Javascript:void(0);";
  };
  const resetCardLink = () => {
    cardLink = false;
  };
  return (
    <div className={`${size ? size : "m-2"} mb-4 ${classnames}`} key={id}>
      <div
        className="card mx-auto cursor-pointer"
        style={{
          minHeight: "200px",
          width: fixedWidth !== undefined && !fixedWidth ? "" : "255px"
        }}
        onClick={() => {
          {
            if (cardLink) {
              openLinkInNewTab
                ? window.open(cardLink)
                : window.location.replace(cardLink);
            }
          }
        }}
      >
        <div
          className={`card-header bg-white
          `}
          id="card-image"
          style={{
            backgroundImage: `url(${avatarUrl ? avatarUrl : defaultAvatar})`,
            backgroundPosition: "center center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat"
          }}
        >
          {selectPartners && (
            <span onClick={() => handlePartners(id)} className={activePartners}>
              {selected && <CheckmarkIcon />}
            </span>
          )}
          <div id="remove-partner">
            {showDeleteIcon && (
              <span
                onClick={() => handlePartners(id)}
                className={removeSelectPartner}
                onMouseOver={resetCardLink}
                onMouseOut={setCardLink}
              />
            )}
          </div>
        </div>

        <div className="card-body" style={{ minHeight: "135px" }}>
          <div className="card-title cursor-pointer">
            <p className="mb-0">
              <b>{companyName}</b>
              <p
                // className="text-truncate"
                style={{
                  fontSize: "small",
                  display: "-webkit-box",
                  WebkitLineClamp: 2,
                  WebkitBoxOrient: "vertical",
                  overflow: "hidden"
                }}
              >
                {headline}
              </p>
            </p>

            <div className="elite-partner mt-4">
              {type === ELITE_PARTNER && (
                <p className="text-uppercase text-info">
                  <ElitePartner /> Elite partner
                </p>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PartnerCard;
