import React from "react";

import { apiClient } from "../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../configs";
import { TAG_TYPE_PARTNER_TYPE } from "../tagType/Constants";

// Helper
import { getCookie } from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

// Components
import Select from "./base/Select";
import { isBadRequest } from "../common/http";
import MultiSelect from "./base/Multiselect";
import Form from "./base/Form";

class PartnerType extends React.Component {
  constructor(props) {
    super(props);
    const { name } = this.props;
    this.state = {
      partnerTypeList: [],
      tagInitialValues: {}
    };
  }

  // partner type api call
  getTagType = async tagType => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    if (!tagType) return null;
    try {
      this.setState({ isLoading: true });
      apiClient.get(`${endpoints().partnerTypeAPI}/search`).then(response => {
        const partnterTypeList = response.data.data;

        if (partnterTypeList && partnterTypeList.length > 0) {
          const tagList = [];
          partnterTypeList.forEach(tag => {
            tagList.push({
              id: tag.id,
              name: tag.name,
              sort: tag.sort,
              value: tag.id,
              label: tag.name,
              tagTypeId: tag.typeId,
              isDefault: tag.isDefault,
              partnerTypeSetting: tag.partnerTypeSetting
            });
          });
          let defaultPartnerType = [];
          let tagInitialValues = {};
          if (tagType === TAG_TYPE_PARTNER_TYPE) {
            this.setState({ partnerTypeList: tagList });
            if (tagList && tagList.length > 0) {
              tagList.forEach(list => {
                if (list.isDefault == 1) {
                  defaultPartnerType.push(list);
                  tagInitialValues[`${this.props.name}`] = defaultPartnerType;
                }
              });
              this.setState({ tagInitialValues });
            }
          }
        }
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  componentDidMount() {
    this.getTagType(TAG_TYPE_PARTNER_TYPE);
  }

  //  Get options
  getPartnerTypeOptions = () => {
    const { partnerTypeList } = this.state;
    let partnerTyeOptions = [];

    if (!partnerTypeList) return partnerTyeOptions;

    partnerTypeList
      .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
      .forEach(type => {
        partnerTyeOptions.push({
          id: type.id,
          value: type.id,
          label: type.name,
          partnerTypeSetting: type.partnerTypeSetting
        });
      });
    return partnerTyeOptions;
  };

  render() {
    const {
      name,
      label,
      placeholder,
      error,
      required,
      isSearchable,
      fullWidth,
      isDisabled,
      id,
      isMulti,
      readOnly,
      isClearable,
      onChange
    } = this.props;
    const { tagInitialValues } = this.state;
    const initialValues = {
      ...tagInitialValues
    };
    return (
      <>
        {isMulti ? (
          <MultiSelect
            name={name || "partnerType"}
            readOnly={readOnly}
            label={label}
            placeholder={placeholder}
            isSearchable={isSearchable}
            isDisabled={isDisabled}
            options={this.getPartnerTypeOptions()}
            onInputChange={e => {
              onChange && onChange(e);
            }}
            error={error}
            {...{ required }}
          />
        ) : (
          <Select
            id={id || name}
            name={name}
            label={label}
            readOnly={readOnly}
            placeholder={placeholder}
            options={this.getPartnerTypeOptions()}
            fullWidth={fullWidth}
            isSearchable={isSearchable}
            error={error}
            {...{ required }}
            isDisabled={isDisabled}
            isClearable={isClearable}
          />
        )}
        {/* </Form> */}
      </>
    );
  }
}

export default PartnerType;
