import {
  RECEIVE_PROJECT_LIST,
  FETCH_PROJECT_LIST_FAIL
} from "../actionType/Constants";

import _ from "lodash";

export function projectDetailsReducer(
  state = {
    isFetching: false
  },
  action
) {
  switch (action.type) {
    case RECEIVE_PROJECT_LIST: {
      return Object.assign({}, state, {
        ..._.keyBy(action.payload.data, n => n.id),
        isFetching: false
      });
    }
    // Fetch project list fail
    case FETCH_PROJECT_LIST_FAIL:
      return { ...state, isFetching: false };
    default:
      return state;
  }
}
