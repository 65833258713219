import React, { useState, useEffect } from "react";
import toast from "../../components/base/Toast";
// Helper
import {
  removeMaskedPhoneNumber,
  getUrlParameter,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  isPartner,
  getCookie,
  getKeyValueByObject
} from "../../lib/helper";
import { WarningRedIcon } from "../../assets/img/icons";

// Configs
import { endpoints, DEFAULT_API_KEY } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Components
import Form from "../../components/base/Form";
import ReferralFormFields from "../referral/ReferralFormFields";
import PageTitle from "../../components/base/PageTitle";
import SideBar from "../../referral/Components/SideBar";
import ReferralModal from "../../components/referral/ReferralModal";

// Constant
import {
  REFERRAL_STATUS_DRAFT,
  CUSTOMER_READINESS_READY_TEXT,
  CUSTOMER_READINESS_NOT_READY_TEXT,
  CUSTOMER_READINESS_NOT_READY,
  CUSTOMER_READINESS_PURCHASED_READY_TEXT,
  CUSTOMER_READINESS_READY,
  CUSTOMER_READINESS_PURCHASED_READY,
  Referral
} from "../../referral/Constants";

import { COOKIE_SESSION_TOKEN } from "../../lib/cookie";

// History
import history from "../../history";
import { isBadRequest } from "../../common/http";
import {
  SETTING_REFERRAL_SHOW_CUSTOMER_READINESS,
  SETTING_REFERRAL_CUSTOMER_READINESS_OPTION,
  SETTINGS_SHOW_REFERRAL_AMOUNT
} from "../../setting/Constants";
import { isTrue, isNotEmpty } from "../../common/utils";
import Spinner from "../../components/base/Spinner";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
import { useSelector } from "react-redux";
import {
  TAG_TYPE_CURRENCY,
  TAG_TYPE_PHONE_COUNTRY_CODE
} from "../../tagType/Constants";
import { getSetting } from "../../actions/settings";
import Tag from "../../Constants/Tag";

const SubmitReferral = props => {
  const [isUserVerified, setUserVerified] = useState(false);
  const [referralPartner, setReferralPartner] = useState("");
  const [closeDateValue, setCloseDateValue] = useState();
  const [customerReadinessModalOpen, setCustomerReadinessModal] = useState(
    false
  );
  const [customerReadinessTarget, setCustomerReadinessTarget] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(false);
  const [currencyList, setCurrencyList] = useState([]);
  const [showAmoutField, setShowAmount] = useState("");
  const [currrency, setCurrency] = useState();
  const [countryCodeList, setCountryCodeList] = useState([]);

  const settings = props.settings && props.settings ? props.settings : "";

  let currency = currrency
    ? currrency
    : currencyList.find(data => data.isDefault == Tag.IsDefault);

  let phoneCountryCode = countryCodeList.find(
    data => data.isDefault == Tag.IsDefault
  );

  const enableCustomerReadiness = settings
    ? getKeyValueByObject(settings, SETTING_REFERRAL_SHOW_CUSTOMER_READINESS)
    : "";

  const customerReadinessOptions = settings
    ? getKeyValueByObject(settings, SETTING_REFERRAL_CUSTOMER_READINESS_OPTION)
    : "";

  const _getQuestionList = () => {
    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    apiClient
      .get(`${endpoints().referralQuestionAPI}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;

          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach(questionDetails => {
              if (
                isSuperAdmin(currentUser && currentUser.roleId) ||
                isCompanyAdmin(currentUser && currentUser.roleId) ||
                isCompanyManager(currentUser && currentUser.roleId) ||
                (isPartner(currentUser && currentUser.roleId) &&
                  questionDetails.showToPartner) ||
                questionDetails.showToPartner
              ) {
                questionList.push(questionDetails);
              }
            });
          }
          setLoading(false);
          setIsQuestionLoading(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          setLoading(false);
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
        }
      });
  };

  const encryptedPartnerId = getUrlParameter("partner");
  const encryptedUserId = getUrlParameter("user");

  const setCustomerReadiness = () => {
    setCustomerReadinessTarget(!customerReadinessTarget);
  };
  // Handle form submit
  const handleSubmit = values => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    values.closeDate = closeDateValue;

    return apiClient
      .post(endpoints().referralApi, values)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          const currentUrl = window.location.search;
          history.push(`/referral-submission-success${currentUrl}`);
          window.location.reload();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
        }
      });
  };

  // Get Referral Partner
  const _getReferralPartner = () => {
    const id = getUrlParameter("id");

    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    apiClient
      .get(
        `${
          endpoints().publicAPI
        }/partner/id?partnerProfileUrl=${encryptedPartnerId}&basicInfo=true`
      )
      .then(response => {
        const partnerUser = response.data;
        setReferralPartner(partnerUser);
      });
  };

  //  Get Question List
  let questionInitials = {};
  if (questionList && questionList.length > 0) {
    questionList.forEach(list => {
      questionInitials[`${list.id}`] = "";
    });
  }

  useEffect(() => {
    _getQuestionList();
    getTagValues();
    getSettings();
    if (encryptedPartnerId) {
      _getReferralPartner();
    }
  }, []);

  const setCloseDate = date => {
    setCloseDateValue(date);
  };

  const initialValues = {
    accountName: "",
    firstName: "",
    lastName: "",
    contactTitle: "",
    contactEmail: "",
    contactPhone: "",
    countryCode: phoneCountryCode,
    currency: currency && currency,
    notes: "",
    ...questionInitials
  };

  const permisionList =
    customerReadinessOptions && JSON.parse(customerReadinessOptions);

  const currentUser = useSelector(state => state.user);

  const getSettings = () => {
    getSetting(response => {
      if (response && response.length > 0) {
        let showReferralAmount = getKeyValueByObject(
          response,
          SETTINGS_SHOW_REFERRAL_AMOUNT
        );

        setShowAmount(showReferralAmount);
      }
    });
  };

  const getTagValues = async tagType => {
    try {
      setIsCurrencyLoading(true);

      const response = await apiClient.get(`${endpoints().tagAPI}`);
      const results = response.data.data;

      // Check the tag type skills
      if (results && results.length > 0) {
        const currencyList = [];
        const countryCodeList = [];

        results.forEach(tag => {
          if (tag.type == TAG_TYPE_CURRENCY) {
            currencyList.push({
              value: tag.id,
              label: tag.name,
              isDefault: tag.defaultValue
            });
          }
          if (tag.type == TAG_TYPE_PHONE_COUNTRY_CODE) {
            countryCodeList.push({
              value: tag.id,
              label: tag.name,
              isDefault: tag.defaultValue
            });
          }
        });
        setCountryCodeList(countryCodeList);
        setCurrencyList(currencyList);
      }
      setIsCurrencyLoading(false);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  // Get Current Partner
  const onCurrencyChange = e => {
    if (e.values) {
      setCurrency(e.values.currency);
    }
  };
  let partnerSubmit =
    showAmoutField == Referral.AMOUNT_REQUIRED_ON_PARTNER_SUBMIT;

  let partnerOptionalSubmit =
    showAmoutField == Referral.AMOUNT_OPTIONAL_ON_PARTNER_SUBMIT;

  if (isLoading && isCurrencyLoading) {
    return <Spinner />;
  }
  return (
    <div className="container my-4">
      <ReferralModal
        setCustomerReadinessModal={setCustomerReadinessModal}
        customerReadinessModalOpen={customerReadinessModalOpen}
        setCustomerReadiness={setCustomerReadiness}
      ></ReferralModal>
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          if (
            isTrue(enableCustomerReadiness) &&
            isNotEmpty(permisionList) &&
            !values.customerReadiness
          ) {
            return setCustomerReadinessModal(true);
          }
          if (isUserVerified) {
            values.contactPhone = values.contactPhone
              ? removeMaskedPhoneNumber(values.contactPhone)
              : "";
            values.encryptedPartnerId = encryptedPartnerId || null;
            values.encryptedUserId = encryptedUserId || null;

            // Customer readinesss value
            values.customer_readiness =
              values.customerReadiness &&
              values.customerReadiness === CUSTOMER_READINESS_READY_TEXT
                ? CUSTOMER_READINESS_READY
                : values.customerReadiness === CUSTOMER_READINESS_NOT_READY_TEXT
                ? CUSTOMER_READINESS_NOT_READY
                : values.customerReadiness ===
                  CUSTOMER_READINESS_PURCHASED_READY_TEXT
                ? CUSTOMER_READINESS_PURCHASED_READY
                : null;

            const customFields = [];
            if (values && Object.keys(values).length > 0) {
              Object.keys(values).forEach(value => {
                if (!isNaN(value)) {
                  customFields.push({
                    questionId: value,
                    value: values[value]
                  });
                }
              });
              values.customFields = customFields;
            }
            if (values.currency) {
              values.currency = values.currency ? values.currency.value : null;
            }
            if (values.amount >= 0) {
              values.amount = values.amount ? values.amount : null;
            }
            handleSubmit(values);
          }
        }}
      >
        <PageTitle label={`Submit a ${Terminology.get(SystemName.REFERRAL)}`} />

        <div className="card border-0">
          <div className="project-details-body">
            <ReferralFormFields
              publicReferralForm
              settings={props.settings}
              onUserVerified={verified => setUserVerified(verified)}
              disableCloseDate
              questionList={questionList}
              isSubmitPage={true}
              currencyList={currencyList}
              partnerSubmit={partnerSubmit}
              partnerOptionalSubmit={partnerOptionalSubmit}
              onCurrencyChange={onCurrencyChange}
              countryCodeList={countryCodeList}
            />
            <SideBar
              referralStatus={REFERRAL_STATUS_DRAFT}
              referralPartner={referralPartner}
              publicReferralForm
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default SubmitReferral;
