import React from "react";
import AddtagForm from "./AddtagForm";

// Components
import PageTitle from "../../components/base/PageTitle";

const AddTag = props => {
  return (
    <div>
      <PageTitle label="Add Tag" />
      <AddtagForm history={props.history} />
    </div>
  );
};

export default AddTag;
