import React, { Component } from "react";

import { Container } from "reactstrap";

// // 404 page
import Page404 from "../views/Page404";

// Components
import Spinner from "../components/base/Spinner";
import MarketplaceSection from "../components/MarketplaceSection";

// API call
import { apiClient } from "../apiClient";

// Configs
import { endpoints, DEFAULT_API_KEY } from "../configs";

// Helper
import {
  getCookie,
  getUrlParameter,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";
import { connect } from "react-redux";

import PartnerProfileDetails from "./PartnerProfileDetails";
import { TAG_TYPE_CATEGORY_MARKETPLACE } from "../tagType/Constants";
import { isBadRequest } from "../common/http";

class PartnerProfile extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      partnerId: "",
      partnerDetails: [],
      showAboutMe: false,
      moreRecomendation: false,
      loading: false,
      isInvalidRequest: false,
      isPlaybooksloading: false,
      photos: []
    };
  }

  componentDidMount = () => {
    this.getPartnerDetails();
    this.getPartnerPlaybookList();
    this.getContactFormFields();
    this.getMarketplaceTagValues();
    this.getPartnerMediaFiles();
  };

  getContactFormFields = async () => {
    const partnerId = this.props.match.params.id;
    const apiUrl = `${
      endpoints().publicAPI
    }/partner/getContactFormFieldsByPartnerId/${partnerId}`;

    // If dont have session send default API key
    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    try {
      const response = await apiClient.get(`${apiUrl}`);
      this.setState({
        contactUsFormFields: response.data.data,
        contactUsFormId: response.data.formId,
        iscontactUsFormFieldsLoading: true
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }

        console.error(errorMessage);
        // this.setState({ isInvalidRequest: true });
      }
    }
  };

  //Set Partner Profile Visited
  setPartnerProfileVisited = () => {
    const partnerId =
      this.state && this.state.partnerDetails && this.state.partnerDetails.id;
    if (partnerId) {
      const data = {
        partnerId: partnerId
      };

      // If dont have session send default API key
      if (!getCookie(COOKIE_SESSION_TOKEN)) {
        apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
      }
      apiClient.post(`${endpoints().activity}`, data);
    }
  };

  // Partner details api call by id
  getPartnerDetails = async () => {
    const partnerId = this.props.match.params.id;
    const apiUrl = `${
      endpoints().publicAPI
    }/partner/${partnerId}/?partnerProfileUrl=${partnerId}`;

    // If dont have session send default API key
    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    try {
      const response = await apiClient.get(`${apiUrl}`);
      this.setState(
        { partnerDetails: response.data, loading: true },
        async () => {
          if (response && response.data) {
            await this.setState({ partnerId: response.data.id });
            await this.setPartnerProfileVisited();
          }
        }
      );
      return response.data;
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }

        console.error(errorMessage);
        this.setState({ isInvalidRequest: true });
      }
    }
  };

  // Partner details api call by id
  getPartnerMediaFiles = async () => {
    const partnerId =
      this.state && this.state.partnerId
        ? this.state.partnerId
        : this.props.match.params.id;
    const apiUrl = `${endpoints().publicAPI}/partnerMedia/${partnerId}`;

    // If dont have session send default API key
    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    try {
      const response = await apiClient.get(`${apiUrl}`);
      if (response && response.data && response.data.data) {
        this.setState({ photos: response.data.data });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }

        console.error(errorMessage);
        this.setState({ isInvalidRequest: true });
      }
    }
  };

  getPartnerPlaybookList = async () => {
    const partnerId = this.props.match.params.id;
    const apiUrl = `${
      endpoints().publicAPI
    }/partner/playbooks/${partnerId}?pagination=true&pageSize=4`;

    // If dont have session send default API key
    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    try {
      const response = await apiClient.get(`${apiUrl}`);
      this.setState({
        playbookList: response.data.data,
        isPlaybooksloading: true
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }

        console.error(errorMessage);
        this.setState({ isInvalidRequest: true });
      }
    }
  };

  // Get Marketplace Tag Values
  getMarketplaceTagValues = async () => {
    this.setState({ tagsTypeLoading: true }, async () => {
      try {
        if (!getCookie(COOKIE_SESSION_TOKEN)) {
          apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
        }
        const response = await apiClient.get(
          `${
            endpoints().tagTypeAPI
          }?category=${TAG_TYPE_CATEGORY_MARKETPLACE}&partnerName=${
            this.props.match.params.id
          }`
        );
        const results = response.data.data;
        // Check the tag type skills
        if (results && results.length > 0) {
          const tagTypeList = [];
          await results.forEach(async tagType => {
            const tagList = [];
            if (tagType.tags && tagType.tags.length > 0) {
              await tagType.tags.forEach(tag => {
                tagList.push({
                  id: tag.id,
                  name: tag.name,
                  value: tag.name,
                  label: tag.name,
                  tagTypeId: tagType.id,
                  sort: tag.sort
                });
              });
            }
            tagTypeList.push({
              id: tagType.id,
              name: tagType.name,
              permission: tagType.permission,
              visibility: tagType.visibility,
              tags: tagList
            });
          });

          this.setState(
            {
              tagTypeList: tagTypeList
            },
            () => {
              this.setState({
                tagsTypeLoading: false
              });
            }
          );
        } else {
          this.setState({
            tagsTypeLoading: false
          });
        }
      } catch (error) {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({
            tagsTypeLoading: false
          });
        }
      }
    });
  };

  getMarketplaceTags = (partnertags, tagTypeList) => {
    const tagTypeValues = [];
    if (tagTypeList && tagTypeList.length > 0) {
      tagTypeList.forEach(tagType => {
        const selectedTags = [];
        let sort = [];
        if (tagType.tags && tagType.tags.length > 0)
          tagType.tags &&
            tagType.tags.length &&
            tagType.tags.forEach(tag => {
              sort.push(tag.sort);
            });
        let sortValue = sort[0];
        let sortParam = sortValue && sortValue >= 0 ? "sort" : "name";
        if (partnertags && partnertags.length > 0) {
          partnertags &&
            partnertags
              .sort((a, b) =>
                (a[`${sortParam}`] || "")
                  .toString()
                  .localeCompare((b[`${sortParam}`] || "").toString())
              )
              .forEach(tag => {
                if (tagType.id === tag.tagTypeId) selectedTags.push(tag.name);
              });
          tagTypeValues.push({
            name: tagType.name,
            visibility: tagType.visibility,
            selectedTags: selectedTags.join(",")
          });
        }
      });
    }

    return tagTypeValues;
  };

  toggleShowAboutMe = e => {
    e.preventDefault();
    this.setState({ showAboutMe: !this.state.showAboutMe });
  };
  toggleMoreRecomendation = e => {
    e.preventDefault();
    this.setState({ moreRecomendation: !this.state.moreRecomendation });
  };

  render() {
    if (
      this.state.isInvalidRequest ||
      (!isSuperAdmin(this.props.roleId) &&
        !isCompanyAdmin(this.props.roleId) &&
        !isCompanyManager(this.props.roleId) &&
        this.state.status === "Draft")
    ) {
      return (
        <div className="app-body">
          <Page404 />
        </div>
      );
    }

    const {
      playbookList,
      contactUsFormFields,
      contactUsFormId,
      tagTypeList,
      photos
    } = this.state;
    let { partnerTags } = this.state.partnerDetails;
    const { settings } = this.props;

    const redirectTo = `${getUrlParameter("redirect")}`;

    const currentUrl = window.location.pathname + window.location.search;
    const partnerId = this.props.match.params.id;
    const marketplaceTagTypeList =
      this.getMarketplaceTags(partnerTags, tagTypeList) || [];
    if (
      !this.state.partnerDetails.length &&
      this.state.partnerDetails.length !== undefined
    ) {
      return <Spinner />;
    }

    return (
      <div className="expert-profile py-5 my-5">
        {this.state.partnerDetails && (
          <>
            <MarketplaceSection className="py-5 my-5">
              <Container>
                <PartnerProfileDetails
                  partnerDetails={this.state.partnerDetails}
                  currentUrl={currentUrl}
                  partnerId={partnerId}
                  playbookList={playbookList}
                  redirectTo={redirectTo}
                  contactUsFormFields={contactUsFormFields}
                  contactUsFormId={contactUsFormId}
                  marketplaceTagTypeList={marketplaceTagTypeList}
                  photos={photos}
                  settings={settings}
                />
              </Container>
            </MarketplaceSection>
          </>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(PartnerProfile);
