import React, { Component } from "react";

// Components
import Text from "../../components/base/Text";
import Phone from "../../components/base/Phone";
import TextArea from "../../components/base/TextArea";
import Email from "../../components/base/Email";

class PlaybookInquiryForm extends Component {
  // Render the form fields
  render() {
    return (
      <div className="col-lg-12">
        <div className="field-wrapper">
          <Text
            name="name"
            label="Name"
            placeholder="Enter name"
            required={true}
            error=""
          />
        </div>
        <div className="field-wrapper">
          <Email
            name="email"
            label="Email Address"
            placeholder="Enter email address"
            required={true}
            error=""
          />
        </div>
        <div className="field-wrapper">
          <Phone
            name="phone"
            label="Phone Number"
            placeholder="Enter phone number"
            required={true}
            error=""
          />
        </div>
        <div className="field-wrapper">
          <Text
            name="companyName"
            label="Company Name"
            placeholder="Enter company name"
            required={true}
            error=""
          />
        </div>
        <div className="field-wrapper">
          <TextArea
            name="notes"
            label="Description of need or project"
            placeholder="Enter project description"
            required={true}
            error="Please enter project description"
          />
        </div>
      </div>
    );
  }
}

export default PlaybookInquiryForm;
