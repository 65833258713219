import React, { useState } from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import Form from "../../../../../components/base/Form";
import DefaultContent from "../../../../../components/content/DefaultContent";
import Text from "../../../../../components/base/Text";
import Password from "../../../../../components/base/Password";
import Select from "../../../../../components/base/Select";
import SaveButton from "../../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_BILL_DOT_COM_DEVELOPMENT_KEY,
  SETTINGS_BILL_DOT_COM_ENVIRONMENT,
  SETTINGS_BILL_DOT_COM_ORGANIZATION_ID
} from "../../../../../setting/Constants";

// Helper
import { toString } from "../../../../../lib/helper";

const BillDotComSection = props => {
  const { adminSettings, saveSettings, billDotComSettings } = props;

  const [isModalOpen, setIsModalOpen] = useState(false);

  const {
    bill_dot_com_environment,
    bill_dot_com_organization_id,
    bill_dot_com_development_key,
    bill_dot_com_session_id
  } = adminSettings;

  const { handleBillDotComConnect } = billDotComSettings;

  const isBillDotComConnected = bill_dot_com_session_id ? true : false;

  // Bill.com Section Initial Values
  const biilDotComSettingsInitialValues = {
    bill_dot_com_organization_id,
    bill_dot_com_development_key
  };

  const billDotComEnvironmentOption = () => [
    {
      label: "Production",
      value: "Production"
    },
    {
      label: "Sandbox",
      value: "Sandbox"
    }
  ];

  // Bill.com Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Bill.com Integration Data
    if (values.bill_dot_com_environment !== undefined) {
      data.append(
        "bill_dot_com_environment",
        toString(
          values.bill_dot_com_environment &&
            typeof values.bill_dot_com_environment === "object"
            ? values.bill_dot_com_environment.value
            : values.bill_dot_com_environment
        )
      );
    }
    if (values.bill_dot_com_organization_id !== undefined) {
      data.append(
        "bill_dot_com_organization_id",
        values.bill_dot_com_organization_id
      );
    }

    if (values.bill_dot_com_development_key !== undefined) {
      data.append(
        "bill_dot_com_development_key",
        values.bill_dot_com_development_key
      );
    }

    // Save settings
    saveSettings(data);
  };

  const toggleIsModalOpen = () => {
    setIsModalOpen(!isModalOpen);
  };

  const handleSubmit = values => {
    const data = {
      userName: values.userName ? values.userName : "",
      password: values.password ? values.password : ""
    };
    handleBillDotComConnect(data);
    toggleIsModalOpen();
  };

  return (
    <>
      <Form
        initialValues={biilDotComSettingsInitialValues}
        onSubmit={values => {
          submit(values);
        }}
      >
        <DefaultContent
          title="Bill.com"
          enableButton
          buttonLabel={!isBillDotComConnected ? "Connect" : "Reconnect"}
          onButtonClick={toggleIsModalOpen}
        >
          <h5 className="font-weight-bold">Environment</h5>
          <div className="field-wrapper">
            <Select
              name={SETTINGS_BILL_DOT_COM_ENVIRONMENT}
              placeholder="Select Bill.com Environment"
              options={billDotComEnvironmentOption()}
              defaultValue={billDotComEnvironmentOption().find(
                type => type.value === bill_dot_com_environment
              )}
              isSearchable={true}
            />
          </div>

          <h5 className="font-weight-bold">Organization Id</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_BILL_DOT_COM_ORGANIZATION_ID}
              placeholder="Bill.com Organization Id"
            />
          </div>

          <h5 className="font-weight-bold">Development Key</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_BILL_DOT_COM_DEVELOPMENT_KEY}
              placeholder="Bill.com Development Key"
            />
          </div>
        </DefaultContent>

        <div className="btn-wrapper">
          <SaveButton />
        </div>
      </Form>
      <Modal
        isOpen={isModalOpen}
        toggle={toggleIsModalOpen}
        className={["edit-task-modal"].join(" ")}
        backdrop="static"
      >
        <Form
          initialValues={{ userName: "", password: "" }}
          onSubmit={values => {
            handleSubmit(values);
          }}
        >
          <ModalHeader toggle={toggleIsModalOpen}>
            <div
              className={["d-flex", "flex-column", "align-items-center"].join(
                " "
              )}
            >
              <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
                Bill.com Login
              </h4>
            </div>
          </ModalHeader>
          <ModalBody className={["mb-4", "px-0"].join(" ")}>
            <div className="form-wrapper">
              <div className="field-wrapper w-100">
                <Text
                  name="userName"
                  label="Username / Email"
                  placeholder="Username / Email..."
                  error="Username / Email is required"
                  required={true}
                />
              </div>
              <div className="field-wrapper w-100">
                <Password
                  name="password"
                  label="Password"
                  placeholder="Password..."
                  error=""
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <Button type="submit">Connect</Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default BillDotComSection;
