import React, { useState, useEffect } from "react";
import { DropdownItem } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Field } from "formik";

//Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import MoreDropdown from "../../components/base/MoreDropdown";
import Form from "../../components/base/Form";
import Text from "../../components/Text";
import AddButton from "../../components/base/AddButton";
import Spinner from "../../components/base/Spinner";
import ToggleSwitch from "../../components/base/ToggleSwitch";
import Select from "../../components/base/Select";
import AvatarCard from "../../components/base/AvatarCard";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import { getParamsByName } from "../../lib/helper";
import DeleteModal from "../../components/base/DeleteModal";
import { toast } from "react-toastify";

// 404 Page
import { isBadRequest } from "../../common/http";

//constants
import {
  APP_VISIBILITY_LIVE,
  APP_VISIBILITY_DISABLED,
  APP_STATUS_APPROVED,
  APP_STATUS_DECLINED,
  APP_TYPE_PUBLIC
} from "../../views/app/Constant";

//Assets
import { GlobeIcon, PrivateIcon, StackIcon } from "../../assets/img/icons";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px",
  marginTop: "8px"
};

const Versions = () => {
  //states
  const [isOpen, setIsOpen] = useState(false);
  const [visibility, setVisibility] = useState(false);
  const [id, setId] = useState(0);
  const [version, setVersion] = useState("");
  const [status, setStatus] = useState("");
  const [type, setType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [deleteVertionModel, setDeleteVertionModel] = useState(false);
  const [deleteAppVersion, setDeleteAppVersion] = useState(false);
  const [isChecked, setIsChecked] = useState("");
  const [appId, setAppId] = useState("");
  const [currentValue, setCurrentValue] = useState("");
  const [statusOption, setStatusOption] = useState([
    {
      value: "Pending",
      label: "Pending"
    },
    {
      value: "Approved",
      label: "Approved"
    },
    {
      value: "Declined",
      label: "Declined"
    }
  ]);
  const [typeOption, setTypeOption] = useState([
    {
      value: "Private",
      label: "Private"
    },
    {
      value: "Public",
      label: "Public"
    }
  ]);
  useEffect(() => {
    const appId = getParamsByName("id");
    setAppId(appId);
    setIsLoading(true);
    setIsLoading(false);
  }, []);

  //Add button
  const headerButton = (
    <div className="col-12">
      <AddButton
        label="Add New"
        className="pull-right btn btn-secondary mb-2"
        onClick={() => {
          setVersion("");
          setCurrentValue("");
          setStatus("");
          setType("");
          toggle();
          setIsChecked(APP_VISIBILITY_DISABLED);
        }}
      />
    </div>
  );

  //create version details
  const createData = async data => {
    if (data) {
      try {
        const response = await apiClient.post(
          `${endpoints().appVersionApi}`,
          data
        );
        setIsLoading(true);
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          toggle();
        }
        setIsLoading(false);
      } catch (error) {
        if (isBadRequest(error)) {
          setIsLoading(false);
          toast.error(error.response.data.message);
        }
      }
    }
  };
  //edit version details
  const editData = async (id, data) => {
    try {
      const response = await apiClient.put(
        `${endpoints().appVersionApi}/${id}`,
        data
      );
      setIsLoading(true);
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      toggle();
      setIsLoading(false);
    } catch (error) {
      if (isBadRequest(error)) {
        setIsLoading(false);
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(errorMessage);
        console.error(errorMessage);
      }
    }
  };

  //delete app version details
  const deleteData = async id => {
    try {
      const response = await apiClient.delete(
        `${endpoints().appVersionApi}/${id}`
      );
      setIsLoading(true);
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
      }
      setIsLoading(false);
    } catch (error) {
      if (isBadRequest(error)) {
        setIsLoading(false);
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(errorMessage);
        console.error(errorMessage);
      }
    }
  };

  const toggle = id => {
    setId(id || "");
    setIsOpen(!isOpen);
    setVisibility(false);
  };

  if (isLoading) {
    return <Spinner />;
  }
  const handleStatusChange = selectStatus => {
    if (selectStatus && selectStatus.value) {
      setStatus(selectStatus.value);
    }
  };
  const handleTypeChange = selectType => {
    if (selectType && selectType.value) {
      setType(selectType.value);
    }
  };
  return (
    <>
      <div className="mb-5">
        <div className="d-flex align-items-center mb-2">{headerButton}</div>

        <div>
          <Modal
            isOpen={isOpen}
            toggle={toggle}
            className="edit-task-modal"
            backdrop="static"
          >
            <ModalHeader toggle={toggle}>
              {id ? "Edit Version" : "Add Version"}
            </ModalHeader>
            <Form
              initialValues={{
                version: version || "",
                status: status
                  ? statusOption.find(
                      statusOption => statusOption.value === status
                    )
                  : "",
                type: type
                  ? typeOption.find(typeOption => typeOption.value === type)
                  : "",
                profile_visibility:
                  isChecked === APP_VISIBILITY_LIVE ? true : false,
                visibility: "",
                appId: getParamsByName("id")
              }}
              onSubmit={values => {
                values.type = type;
                values.status = status;
                values.visibility =
                  isChecked === APP_VISIBILITY_LIVE
                    ? APP_VISIBILITY_LIVE
                    : APP_VISIBILITY_DISABLED;
                id ? editData(id, values) : createData(values);
              }}
            >
              <ModalBody className="custom-modal-body">
                <div>
                  <Text
                    name="version"
                    label="Version"
                    placeholder="Enter App version"
                    error=""
                    required={true}
                  />
                  <div className=" field-wrapper mb-3">
                    <Select
                      name="status"
                      label="Status"
                      placeholder="Select App Status"
                      options={statusOption}
                      isClearable={true}
                      isSearchable={true}
                      error=""
                      handleChange={e => handleStatusChange(e, id)}
                      required
                    />
                  </div>
                  <div className=" field-wrapper mb-5">
                    <Select
                      name="type"
                      label="Type"
                      placeholder="Select App Type"
                      options={typeOption}
                      isClearable={true}
                      isSearchable={true}
                      error=""
                      handleChange={e => handleTypeChange(e)}
                      required
                    />
                  </div>
                  <div className="btn-wrapper mb-3">
                    <div className="d-flex justify-content-between align-items-center ">
                      <div>
                        <p className="mt-1">Visibility</p>
                      </div>
                      <div>
                        <Field
                          name="profile_visibility"
                          label="Visibility"
                          render={({ field, form }) => {
                            return (
                              <ToggleSwitch
                                id={id}
                                name="visibility"
                                label1={
                                  isChecked === APP_VISIBILITY_LIVE
                                    ? "Live"
                                    : "Disabled"
                                }
                                label2={
                                  isChecked === APP_VISIBILITY_LIVE
                                    ? "Live"
                                    : "Disabled"
                                }
                                value={
                                  isChecked === APP_VISIBILITY_LIVE
                                    ? true
                                    : false
                                }
                                handleChange={() => {
                                  setIsChecked(
                                    isChecked === APP_VISIBILITY_LIVE
                                      ? APP_VISIBILITY_DISABLED
                                      : isChecked === APP_VISIBILITY_DISABLED
                                      ? APP_VISIBILITY_LIVE
                                      : APP_VISIBILITY_DISABLED
                                  );
                                  form.setFieldValue(
                                    "visibility",
                                    !field.value
                                  );
                                }}
                                outlined
                              />
                            );
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="container-fluid">
                  <div className="col text-center">
                    <Button type="submit" label="">
                      Save
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </Form>
          </Modal>
        </div>

        <ReduxTable
          id="url"
          apiURL={`${endpoints().appVersionApi}`}
          // showHeader
          // searchPlaceholder="Search"
          // sortByOptions={sortByOptions}
          // newTableHeading
          params={{ appId }}
        >
          <ReduxColumn
            minWidth={"170px"}
            width={"460px"}
            maxWidth={"460px"}
            className="w-75"
            field="name"
            disableOnClick
            renderField={row => (
              <>
                <div
                  onClick={() => {
                    toggle(row.id);
                    setId(row.id);
                    setVersion(row.version);
                    setStatus(row.status);
                    setType(row.type);
                    setCurrentValue(row);
                    setIsChecked(row.visibility);
                  }}
                  className="cursor-pointer"
                >
                  <AvatarCard companyName={row.app_name} url={row.app_logo} />
                </div>
                <br></br>
                <small className="font-weight-bold text-secondary text-decoration-none">
                  <StackIcon />
                  <span className="px-2">{row.version}</span>
                  {row && row.type === APP_TYPE_PUBLIC ? (
                    <small className="font-weight-bold text-secondary text-decoration-none">
                      <span className="pr-1">
                        <GlobeIcon />
                      </span>
                      Public
                    </small>
                  ) : (
                    <small className="font-weight-bold text-secondary text-decoration-none">
                      <span className="pr-1">
                        <PrivateIcon />
                      </span>
                      Private
                    </small>
                  )}
                </small>
              </>
            )}
          >
            Name
          </ReduxColumn>

          <ReduxColumn
            minWidth={"110px"}
            width={"460px"}
            maxWidth={"460px"}
            className="w-75 "
            field="status"
            sortBy="status"
            renderField={row => (
              <div
                className={`text-center text-uppercase ${
                  row.status !== APP_STATUS_APPROVED
                    ? row.status === APP_STATUS_DECLINED
                      ? "bg-danger"
                      : "bg-warning"
                    : "bg-success"
                }`}
                style={statusStyle}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>

          <ReduxColumn
            minWidth={"126px"}
            width={"460px"}
            maxWidth={"460px"}
            className="w-75"
            field="submitted_at"
            renderField={row => <p>{row.createdAt}</p>}
            sortBy="createdAt"
          >
            Submitted
          </ReduxColumn>

          <ReduxColumn
            minWidth={"150px"}
            width={"460px"}
            maxWidth={"460px"}
            className="w-75"
            field="last_updated"
            renderField={row => <p>{row.updatedAt}</p>}
            sortBy="updatedAt"
          >
            Last Updated
          </ReduxColumn>

          <ReduxColumn
            minWidth={"122px"}
            width={"460px"}
            maxWidth={"460px"}
            className="w-75"
            field="visibility"
            sortBy="visibility"
            renderField={row => {
              return <>{row.visibility}</>;
            }}
          >
            Visibility
          </ReduxColumn>
          <ReduxColumn
            minWidth={"100px"}
            width={"150px"}
            maxWidth={"150px"}
            className="w-25 text-center"
            field="status"
            disableOnClick
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    className={"text-dark"}
                    onClick={() => {
                      toggle(row.id);
                      setId(row.id);
                      setVersion(row.version);
                      setStatus(row.status);
                      setType(row.type);
                      setCurrentValue(row);
                      setIsChecked(row.visibility);
                      toggle(row.id);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setDeleteVertionModel(true);
                      setDeleteAppVersion(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          id="deleteVersionDetail"
          isOpen={deleteVertionModel}
          toggle={() => {
            setDeleteVertionModel(false);
          }}
          title="Delete App Version"
          label={`${deleteAppVersion.app_name} ${""} ${
            deleteAppVersion.version
          }`}
          deleteFunction={() => deleteData(deleteAppVersion.id)}
        />
      </div>
    </>
  );
};

export default Versions;
