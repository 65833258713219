import React from "react";
import lastActivity from "../../assets/img/icons/last-activity.svg";

import chevronRight from "../../assets/img/icons/icon-chevron-right.svg";
import SVG from "react-inlinesvg";
import ProfileCard from "../../components/base/ProfileCard";
import moment from "moment";
// API call
import { apiClient } from "../../apiClient";
// Configs
import { endpoints } from "../../configs";
import toast from "../../components/base/Toast";

import { Link } from "react-router-dom";

import MessageDialog from "../../views/message/MessageDialog";

import {
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

import { isBadRequest } from "../../common/http";
import SnoozeModal from "../accountMapping/SnoozeModal";

// Constants
import { PARTNER_PERMISSION_INDIVIDUAL_PARTNER } from "../../resource/Constants";
// Constants
import { PROJECT_STATUS_INPROCESS } from "../../projectStatus/Constants";

import { PROJECT_TYPE_CAMPAIGN } from "../../project/Constants";
// Constants
import { PROJECT_USER_STATUS_ACCEPTED } from "../../project/Constants";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

import { DropdownItem } from "reactstrap";
import MoreDropdown from "../../components/base/MoreDropdown";
import { calculateDaysBeforeToday } from "../../common/date";

class crossBeamPartnerRecords extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      modalOpen: false,
      data: "",
      partnerMessageModalOpen: false,
      selectedPartner: "",
      isActive: "",
      sortBy: ""
    };
  }
  crossbeamPartnerStatusUpdate(crossbeamAccountId, snoozeDate) {
    apiClient
      .post(`${endpoints().crossbeamPartnerAPI}/status/update`, {
        crossbeamAccountId: crossbeamAccountId,
        snoozeDate: snoozeDate
      })
      .then(data => {
        if (data.status === 200) {
          toast.success(data.data.message);
        }
      });
  }

  askIntroductionToPartner = (messageData, crossbeamPartnerData) => {
    if (
      messageData &&
      crossbeamPartnerData &&
      crossbeamPartnerData.account_owner_email
    ) {
      const data = new FormData();
      data.append("message", messageData.message);
      data.append("email", crossbeamPartnerData.account_owner_email);

      apiClient
        .post(endpoints().messageAPI, data)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          toast.success(successMessage);
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    }
  };

  statusUpdate(crossbeamAccountId) {
    this.props.crossbeamPartnerStatusUpdate(crossbeamAccountId);
  }
  modelOpen(data) {
    this.setState({ modalOpen: true, data: data });
  }
  toggleModal = () => {
    this.setState({ modalOpen: !this.state.modalOpen });
  };

  togglePartnerMessageModalOpen = () => {
    this.setState({
      partnerMessageModalOpen: !this.state.partnerMessageModalOpen
    });
  };

  askForIntroduction = data => {
    this.setState({ selectedPartner: data });
    this.togglePartnerMessageModalOpen();
  };

  sendMessageData = data => {
    this.askIntroductionToPartner(data, this.state.selectedPartner);
  };

  // Create default campaign API Call
  createDefaultCampaign = async partnerData => {
    let partnerId = partnerData && partnerData.id;
    let partnerCompany =
      partnerData && partnerData.company_name + " " + PROJECT_TYPE_CAMPAIGN;
    const data = new FormData();
    data.append("name", partnerCompany);
    data.append("status", PROJECT_STATUS_INPROCESS);
    data.append("type", PROJECT_TYPE_CAMPAIGN);
    // Add Project deliverables
    const projectDeliverables = [];
    data.append("deliverables", JSON.stringify(projectDeliverables));
    try {
      // create campaign
      const response = await apiClient.post(endpoints().projectAPI, data);
      let successMessage;
      if (response && response.data) {
        let camapignId = response && response.data.campaignId;
        const data = {
          partnerIds: partnerId,
          projectId: camapignId,
          status: PROJECT_USER_STATUS_ACCEPTED
        };
        // if campaignId create project user
        const createUser = await apiClient.post(
          `${endpoints().projectUserAPI}/partner`,
          data
        );
        if (createUser) {
          successMessage = response.data.message;
        }
        if (camapignId) {
          this.props.history.push(`campaign/${camapignId}`);
        }
      }
      toast.success(successMessage);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  columnSortBy = sortBy => {
    if (this.props.sort) {
      this.setState({ isActive: !this.state.isActive, sortBy: sortBy });
      let sortDir = "";
      if (this.state.isActive) {
        sortDir = "DESC";
      } else {
        sortDir = "ASC";
      }
      this.props.getCrossBeamPartnerRecords &&
        this.props.getCrossBeamPartnerRecords({
          sortData: sortBy,
          sortDirData: sortDir,
          typeData: this.props.type,
          statusData: this.props.status
        });
    }
  };

  IntentQuantityColumn = () => {
    return (
      <th
        className="text-center"
        onClick={() => {
          this.columnSortBy("intent_quantity");
        }}
      >
        W / Intent
      </th>
    );
  };

  IntentScoreColumn = () => {
    return (
      <th
        className="text-center"
        onClick={() => {
          this.columnSortBy("intent_score");
        }}
      >
        Intent Score
      </th>
    );
  };

  IntentQuantityData = data => {
    return (
      <td className="text-center">
        {data.IntentQuantity ? data.IntentQuantity : ""}
      </td>
    );
  };

  IntentScoreData = data => {
    return (
      <td className="text-center">
        {data.IntentScore ? data.IntentScore : ""}
      </td>
    );
  };

  render() {
    let partnerAccountStatus;

    return (
      <div className="table-wrapper">
        <table className="table account-mapping-custom">
          <thead>
            <tr
              className={`text-muted text-decoration-none ${this.props.sort &&
                "cursor-pointer"}`}
            >
              <th
                onClick={() => {
                  this.columnSortBy("company_name");
                }}
              >
                Name
              </th>
              <th
                className="text-center"
                onClick={() => {
                  this.columnSortBy("total_overlap_count");
                }}
              >
                Overlap Quantity
              </th>
              {this.props.type == "Prospect" && (
                <th
                  onClick={() => {
                    this.columnSortBy("last_activity");
                  }}
                >
                  Last Activity
                </th>
              )}
              {this.props.type == "Partner" ? (
                this.IntentQuantityColumn()
              ) : this.props.type == "Prospect" ? (
                this.IntentScoreColumn()
              ) : (
                <>
                  {this.IntentQuantityColumn()}
                  {this.IntentScoreColumn()}
                </>
              )}
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {this.props.crossBeamData.map(data => {
              partnerAccountStatus = data.last_overlap_time
                ? calculateDaysBeforeToday(data.last_overlap_time)
                : null;

              return (
                <tr>
                  <td>
                    <span className="d-flex flex-row justify-content-start">
                      <ProfileCard
                        avatarUrl={data.logo_url}
                        firstName={data.company_name}
                      />
                      <div className="d-flex flex-column justify-content-start mt-1">
                        {data.type == "Partner" ? (
                          <Link
                            to={`/crossbeam-report?crossbeamPartnerId=${data.crossbeamPartnerId}&selectAllPopulation=true`}
                            className={"view-full-profile"}
                          >
                            <span className="ml-2 font-weight-bold">
                              {data.company_name}
                            </span>{" "}
                          </Link>
                        ) : (
                          <span className="ml-2 font-weight-bold">
                            {data.company_name}
                          </span>
                        )}

                        {partnerAccountStatus >= 0 &&
                          partnerAccountStatus != null && (
                            <div className="ml-2">
                              {partnerAccountStatus >= 0 &&
                                partnerAccountStatus <= 3 && (
                                  <span className="account-mapping-partner-status-new">
                                    NEW
                                  </span>
                                )}

                              {partnerAccountStatus > 4 &&
                                partnerAccountStatus <= 15 && (
                                  <span className="account-mapping-partner-status-recent">
                                    RECENT
                                  </span>
                                )}
                            </div>
                          )}
                      </div>
                    </span>
                  </td>
                  <td className="text-center">{data.total_overlap_count}</td>
                  {this.props.type == "Prospect" && (
                    <td>
                      {" "}
                      <span className="text-muted">
                        {data.lastActivity
                          ? `${data.lastActivity} Days ago`
                          : ""}
                      </span>
                    </td>
                  )}

                  {this.props.type == "Partner" ? (
                    this.IntentQuantityData(data)
                  ) : this.props.type == "Prospect" ? (
                    this.IntentScoreData(data)
                  ) : (
                    <>
                      {this.IntentQuantityData(data)}
                      {this.IntentScoreData(data)}
                    </>
                  )}

                  <td>
                    <div className="text-center landing-group-dropdown">
                      <MoreDropdown direction="down">
                        <DropdownItem onClick={() => this.modelOpen(data)}>
                          {" "}
                          Snooze
                        </DropdownItem>
                        {data.type == "Partner" && (
                          <>
                            <DropdownItem
                              onClick={() =>
                                this.props.history.push(
                                  `/crossbeam-report?crossbeamPartnerId=${data.crossbeamPartnerId}&selectAllPopulation=true`
                                )
                              }
                            >
                              {" "}
                              Explore Connections
                            </DropdownItem>

                            <DropdownItem
                              onClick={() => {
                                this.createDefaultCampaign(
                                  data && data.partnerDetails
                                    ? data.partnerDetails
                                    : ""
                                );
                              }}
                            >
                              {" "}
                              Launch{" "}
                              {Terminology.get(SystemName.COMARKETING_CAMPAIGN)}
                            </DropdownItem>
                          </>
                        )}
                      </MoreDropdown>
                    </div>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>

        {this.state.modalOpen && (
          <SnoozeModal
            crossbeamAccountId={this.state.data.crossbeam_account_id}
            statusUpdate={this.props.crossbeamPartnerStatusUpdate}
            modalOpen={this.state.modalOpen}
            toggle={this.toggleModal}
          />
        )}

        {this.state.partnerMessageModalOpen && (
          <MessageDialog
            modal={this.state.partnerMessageModalOpen}
            toggle={this.togglePartnerMessageModalOpen}
            handleClose={this.togglePartnerMessageModalOpen}
            sendMessageData={this.sendMessageData}
            heading="Ask for an introduction"
            buttonLabel={"Send Request"}
            message={
              this.state.selectedPartner &&
              this.state.selectedPartner.company_name
                ? `Hello,\nPlease introduce me to ${this.state.selectedPartner.company_name}.`
                : ""
            }
          />
        )}
      </div>
    );
  }
}

export default crossBeamPartnerRecords;
