import React from "react";
import { Alert } from "reactstrap";

// Components
import { CrossIcon, InlineInfoIcon } from "../../../../assets/img/icons";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

const TaskCompletedToggle = props => {
  const { setTaskCompletionToggleValues } = props;
  return (
    <>
      <Alert
        color="success"
        className={
          "d-flex align-items-center justify-content-stretch text-white"
        }
      >
        <InlineInfoIcon />
        <p className="ml-3 mb-0 font-weight-bold mr-auto text-white">
          This {Terminology.get(SystemName.REFERRAL)} is now in Salesforce and
          all tasks are completed.
        </p>

        <div className="close-alert mr-0 ml-0">
          <button
            className="btn-plain btn text-white p-0"
            type="button"
            onClick={() => {
              setTaskCompletionToggleValues({
                isTerrirtoryAssignmentNotification: false
              });
            }}
          >
            <CrossIcon />
          </button>
        </div>
      </Alert>
    </>
  );
};

export default TaskCompletedToggle;
