import React from "react";

class PreviewImage extends React.Component {
  render() {
    const { imageUrl, previewImage, width, height, id } = this.props;
    return (
      <div>
        <img
          id={id}
          alt="preview avatar"
          src={previewImage || imageUrl}
          style={{
            objectFit: "cover",
            width: width || "auto",
            height: height || "auto"
          }}
        />
      </div>
    );
  }
}

export default PreviewImage;
