import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import toast from "../../../components/base/Toast";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Actions
import { fetchList } from "../../../actions/table";

// Configs
import { endpoints } from "../../../configs";

// API call
import { apiClient } from "../../../apiClient";

// Components
import Form from "../../../components/base/Form";
import TextArea from "../../../components/base/TextArea";
import CancelButton from "../../../components/base/CancelButton";

// Constants
import {
  DEAL_STATUS_CLOSED,
  DEAL_STATUS_DECLINED,
  DEAL_STATUS_APPROVED,
  DEAL_STATUS_REQUESTED
} from "../../../dealStatus/Constants";
import { isBadRequest } from "../../../common/http";
import { getParamsByName } from "../../../lib/helper";

class DeclineDealModal extends React.Component {
  declineDeal = (dealId, declinedMessage) => {
    const { setModal, listName, params, getDealDetails } = this.props;
    if (dealId && declinedMessage) {
      const data = {
        status: DEAL_STATUS_DECLINED,
        message: declinedMessage
      };
      return apiClient
        .put(`${endpoints().dealsAPI}/status/${dealId}`, data)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            if (getDealDetails) {
              getDealDetails();
            }
            toast.success(successMessage);
            setModal();

            if (listName && params) {
              this.props.actions.fetchList(
                "newDeals",
                `${endpoints().dealsAPI}/search`,
                1,
                10,
                {
                  status: `${DEAL_STATUS_REQUESTED}`,
                  pagination: true
                }
              );
              this.props.actions.fetchList(
                "openDeals",
                `${endpoints().dealsAPI}/search`,
                1,
                10,
                {
                  status: `${DEAL_STATUS_APPROVED}`,
                  pagination: true
                }
              );
              this.props.actions.fetchList(
                "closedDeals",
                `${endpoints().dealsAPI}/search`,
                1,
                10,
                {
                  status: `${DEAL_STATUS_CLOSED}`,
                  pagination: true
                }
              );
              this.props.actions.fetchList(
                "allDeals",
                `${endpoints().dealsAPI}/search`,
                1,
                10,
                {
                  pagination: true
                }
              );
            }
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            toast.error(error.response.data.message);

            setModal();
          }
        });
    }
  };
  render() {
    const { modal, setModal, dealId } = this.props;
    return (
      <>
        <Modal
          isOpen={modal}
          toggle={setModal}
          className={["edit-task-modal"].join(" ")}
        >
          <ModalHeader toggle={setModal}>
            <div
              className={["d-flex", "flex-column", "align-items-center"].join(
                " "
              )}
            >
              <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
                Decline Deal
              </h4>
            </div>
          </ModalHeader>
          <Form
            initialValues={{
              declinedMessage: ""
            }}
            onSubmit={values => {
              this.declineDeal(dealId, values.declinedMessage);
            }}
          >
            <ModalBody className={["mb-4"].join(" ")}>
              <div className="form-wrapper">
                <p className="text-center">
                  Can you tell us why you declined this deal?
                </p>

                <hr />

                <div className="field-wrapper">
                  <TextArea
                    label="Your Message"
                    name="declinedMessage"
                    placeholder="Your Message..."
                    error="Your message is required"
                    required="true"
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="btn-wrapper">
                <CancelButton onClick={setModal} />
                <button className="btn btn-primary" type={"submit"}>
                  Decline Deal
                </button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

const mapStateToProps = state => {};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(DeclineDealModal);
