import { Redirect } from "react-router-dom";
import classnames from "classnames";
import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Configs
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";
import {
  getKeyValueByObject,
  getParamsByName,
  isCustomer,
  isExpert,
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  isPartner
} from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import CountBadge from "../../components/base/CountBadge";

import PageSearch from "../../components/base/PageSearch";
import SelectDropdown from "../../components/base/SelectDropdown";
import NoRecordsFound from "../../components/base/NoRecordsFound";
import { connect } from "react-redux";

import ProjectReviewCard from "./ProjectReviewCard";
import ProjectReviewFeedBackForm from "../../components/ProjectReviewFeedBackForm";
import ExpertReviewFeedbackForm from "../../components/ExpertReviewFeedbackForm";
import ExpertProjectReviewFeedBackForm from "../../components/ExpertProjectReviewFeedBackForm";
import Spinner from "../../components/base/Spinner";

//Assets
import feedbackIcon from "../../assets/img/icons/nav-icon-feedback.svg";

// constants
import {
  FEEDBACK_STATUS_AWAITING,
  FEEDBACK_STATUS_PREVIOUS,
  FEEDBACK_STATUS_PENDING,
  FEEDBACK_STATUS_APPROVED,
  FEEDBACK_STATUS_AWAITING_LABEL,
  FEEDBACK_STATUS_PREVIOUS_LABEL,
  FEEDBACK_STATUS_PENDING_LABEL,
  FEEDBACK_STATUS_APPROVED_LABEL,
  FEEDBACK_REVIEW_STATUS_AWAITING,
  FEEDBACK_REVIEW_STATUS_PREVIOUS,
  FEEDBACK_REVIEW_STATUS_PENDING,
  FEEDBACK_REVIEW_STATUS_APPROVED,
  FEEDBACK_EXPERT_CATEGORY,
  FEEDBACK_STATUS_RECEIVED,
  FEEDBACK_STATUS_RECEIVED_LABEL,
  FEEDBACK_TABLE_PENDING,
  FEEDBACK_TABLE_APPROVED,
  FEEDBACK_TAB
} from "../../feedback/Constants";
import toast from "../../components/base/Toast";
import ExpertReviewCard from "./ExpertReviewCard";
import ExpertProjectReviewCard from "./ExpertProjectReviewCard";
import AdminFeedbackTable from "./AdminFeedbackTable";
import { isBadRequest } from "../../common/http";
import PartnerFeedbackTable from "./PartnerFeedbackTable";

class Feedbacks extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowId: "",
      loading: false,
      status: "",
      searchCount: 0,
      isLoading: false,
      searchParam: "",
      activeTab: "1",
      isPreviousFeedbackReviewLoading: false,
      previousFeedbackReviewCount: 0,
      previousFeedbackReviews: [],
      isAwaitingFeedbackReviewloading: false,
      awaitingFeedbackReviewCount: 0,
      projectAndExpertReviews: [],
      receivedFeedbackReviewCount: 0,
      receivedFeedbackReviews: [],
      isReceivedFeedbackReviewLoading: false,
      pendingFeedbackReviewCount: 0,
      isPendingFeedbackReviewLoading: true,
      isLoading: true,
      feedbackCount: 0,
      approvedFeedbackReviewCount: 0,
      isApprovedFeedbackReviewLoading: false,
      sortByOptions: [
        {
          value: "createdAt",
          label: "Most Recent"
        }
      ],
      selectedSortOption: "Most Recent"
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this.getParamsStatus();
    this.setActiveTab();
    this._getPreviousFeedbackReviews();
    this._getAwaitingFeedbackReviews();
    this._getReceivedFeedbackReviews();
    this._getPendingFeedbackReviews();
    this._getApprovedFeedbackReviews();
    this._getFeedbackReviews();
  }

  // Get Approved Feedback List For Admin
  _getFeedbackReviews() {
    this.setState({ isLoading: true }, async () => {
      try {
        const response = await apiClient.get(
          `${endpoints().partnerFeedbackAPI}/search`
        );
        const data = response.data;

        this.setState({
          feedbackCount: data.totalCount,
          isLoading: false
        });
      } catch (error) {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      }
    });
  }

  componentDidUpdate(prevProps) {
    const { search } = prevProps.history.location;
    const params = new URLSearchParams(search);
    const status = params.get("status");
    if (
      this.props.approvedFeedbackReviewCounts > 0 &&
      this.state.approvedFeedbackReviewCount !==
        this.props.approvedFeedbackReviewCounts
    ) {
      this.setState({
        approvedFeedbackReviewCount: this.props.approvedFeedbackReviewCounts
      });
    }
    if (
      this.props.pendingFeedbackReviewCounts > 0 &&
      this.state.pendingFeedbackReviewCount !==
        this.props.pendingFeedbackReviewCounts
    ) {
      this.setState({
        pendingFeedbackReviewCount: this.props.pendingFeedbackReviewCounts
      });
    }

    // Set current active tab
    if (status && status !== this.state.activeTab) {
      this.setActiveTab(status);
    }
  }

  // Get Project reviews
  _getPreviousFeedbackReviews() {
    this.setState({ isPreviousFeedbackReviewLoading: true }, () => {
      return apiClient
        .get(
          `${
            endpoints().reviewAPI
          }/search?status=${FEEDBACK_REVIEW_STATUS_PENDING},${FEEDBACK_REVIEW_STATUS_APPROVED}`
        )
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          const data = response.data;
          this.setState({
            previousFeedbackReviewCount: data ? data.totalCount : 0,
            previousFeedbackReviews: data ? data.data : [],
            isPreviousFeedbackReviewLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  }

  // Get Project And Expert Review List
  _getAwaitingFeedbackReviews() {
    this.setState({ isAwaitingFeedbackReviewLoading: true }, () => {
      return apiClient
        .get(
          `${
            endpoints().reviewAPI
          }/search?status=${FEEDBACK_REVIEW_STATUS_AWAITING}`
        )
        .then(response => {
          const data = response.data;
          this.setState({
            awaitingFeedbackReviewCount: data ? data.totalCount : 0,
            awaitingFeedbackReviews: data ? data.data : [],
            isAwaitingFeedbackReviewLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  }

  // Get Received List For Expert
  _getReceivedFeedbackReviews() {
    this.setState({ isReceivedFeedbackReviewLoading: true }, () => {
      return apiClient
        .get(
          `${
            endpoints().reviewAPI
          }/search?&receivedFeedback=true&&category=${FEEDBACK_EXPERT_CATEGORY}&status=${FEEDBACK_REVIEW_STATUS_PENDING},${FEEDBACK_REVIEW_STATUS_APPROVED}`
        )
        .then(response => {
          const data = response.data;

          this.setState({
            receivedFeedbackReviewCount: data ? data.totalCount : 0,
            receivedFeedbackReviews: data ? data.data : [],
            isReceivedFeedbackReviewLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  }

  // Get Pending Feedback List For Admin
  _getPendingFeedbackReviews() {
    this.setState({ isPendingFeedbackReviewLoading: true }, () => {
      return apiClient
        .get(
          `${
            endpoints().reviewAPI
          }/search?status=${FEEDBACK_REVIEW_STATUS_PENDING}`
        )
        .then(response => {
          const data = response.data;

          this.setState({
            pendingFeedbackReviewCount: data ? data.totalCount : 0,
            isPendingFeedbackReviewLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
            this.setState({
              isPendingFeedbackReviewLoading: false
            });
          }
        });
    });
  }
  // Get Approved Feedback List For Admin
  _getApprovedFeedbackReviews() {
    this.setState({ isApprovedFeedbackReviewLoading: true }, () => {
      return apiClient
        .get(
          `${
            endpoints().reviewAPI
          }/search?status=${FEEDBACK_REVIEW_STATUS_APPROVED}`
        )
        .then(response => {
          const data = response.data;

          this.setState({
            approvedFeedbackReviewCount: data ? data.totalCount : 0,
            isApprovedFeedbackReviewLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  }

  // Get search query string value
  getParamsStatus = () => {
    const status = getParamsByName("status");
    this.setState({
      status:
        status ||
        isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)
          ? FEEDBACK_STATUS_PENDING
          : FEEDBACK_STATUS_PENDING
    });
  };

  setActiveTab = currentStatus => {
    const status = currentStatus || getParamsByName("status");
    // this.setState({
    //   activeTab:
    //     status === FEEDBACK_STATUS_PREVIOUS
    //       ? FEEDBACK_STATUS_PREVIOUS
    //       : status === FEEDBACK_STATUS_RECEIVED
    //       ? FEEDBACK_STATUS_RECEIVED
    //       : status === FEEDBACK_STATUS_AWAITING
    //       ? FEEDBACK_STATUS_AWAITING
    //       : status === FEEDBACK_STATUS_PENDING
    //       ? FEEDBACK_STATUS_PENDING
    //       : status === FEEDBACK_STATUS_APPROVED
    //       ? FEEDBACK_STATUS_APPROVED
    //       : isSuperAdmin() ||
    //         isCompanyAdmin() ||
    //         isCompanyManager() ||
    //         isPartner()
    //       ? FEEDBACK_STATUS_PENDING
    //       : FEEDBACK_STATUS_AWAITING,
    // });
    this.setState({
      activeTab:
        isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId) ||
        isPartner(this.props.roleId)
          ? FEEDBACK_TAB
          : FEEDBACK_TAB
    });
  };

  // Update Review
  _updateReviews = (data, status, category) => {
    // Update awaiting feedback reviews
    if (status === FEEDBACK_REVIEW_STATUS_AWAITING) {
      this.setState({
        awaitingFeedbackReviewCount: data ? data.totalCount : 0,
        awaitingFeedbackReviews: data ? data.data : [],
        isAwaitingFeedbackReviewLoading: false
      });
    }
    // Update received feedback reviews
    else if (status === FEEDBACK_REVIEW_STATUS_PREVIOUS && category) {
      this.setState({
        receivedFeedbackReviewCount: data ? data.totalCount : 0,
        receivedFeedbackReviews: data ? data.data : [],
        isReceivedFeedbackReviewLoading: false
      });
    }
    // Update previous feedback reviews
    else if (status === FEEDBACK_REVIEW_STATUS_PREVIOUS && !category) {
      this.setState({
        previousFeedbackReviewCount: data ? data.totalCount : 0,
        previousFeedbackReviews: data ? data.data : [],
        isPreviousFeedbackReviewLoading: false
      });
    }
  };

  // Search user feedback
  _handleFeedbackSearch = (e, status, category) => {
    const searchTerm = e.target.value;

    // Search param based on review status
    const searchParam = `&search=${searchTerm}&receivedFeedback=${
      category ? "true" : ""
    }&&category=${category || ""}&status=${status}`;

    // Review search API call
    return apiClient
      .get(`${endpoints().reviewAPI}/search?${searchParam}`)
      .then(response => {
        const data = response.data;

        // Update reviews in state based on review status
        this._updateReviews(data, status, category);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Handle status change
  _handleStatusChange = tabStatus => {
    this.props.history.push(`/feedback?status=${tabStatus}`);
  };

  toggle = tab => {
    this.setState({
      activeTab: tab
    });
  };

  selectValue = e => {
    this.setState({
      selectedSortOption: e.target.dataset["label"]
    });
    // sort logic here.
  };

  /**
   * Save project review feedback
   */
  saveProjectFeedback = data => {
    const {
      id,
      projectValueForMoney,
      projectQualityOfFinalDeliverable,
      projectOverAllExperience,
      projectReviewNotes,
      checkboxCommentPublic
    } = data;

    const reviewRatingData = {
      value_for_money: projectValueForMoney,
      quality_of_final_deliverable: projectQualityOfFinalDeliverable,
      overall_experience: projectOverAllExperience
    };

    if (
      projectValueForMoney === 0 ||
      projectQualityOfFinalDeliverable === 0 ||
      projectOverAllExperience === 0
    ) {
      toast.error("Ratings are required");
      return false;
    }

    return apiClient
      .put(`${endpoints().reviewAPI}/${id}`, {
        reviewRatingData,
        comment: projectReviewNotes,
        isPublic: checkboxCommentPublic,
        status: FEEDBACK_REVIEW_STATUS_PENDING
      })
      .then(response => {
        this._getPreviousFeedbackReviews();
        this._getAwaitingFeedbackReviews();
        this._getReceivedFeedbackReviews();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  /**
   * Save Expert review feedback
   */
  saveExpertFeedback = data => {
    const {
      id,
      expertCommunication,
      expertWorkWithAgain,
      expertOverallExperience,
      expertReviewNotes,
      checkboxCommentPublic
    } = data;

    const reviewRatingData = {
      communication: expertCommunication,
      work_with_again_or_recommend: expertWorkWithAgain,
      overall_experience: expertOverallExperience
    };

    if (
      expertCommunication === 0 ||
      expertWorkWithAgain === 0 ||
      expertOverallExperience === 0
    ) {
      toast.error("Ratings are required");
      return false;
    }

    return apiClient
      .put(`${endpoints().reviewAPI}/${id}`, {
        reviewRatingData,
        isPublic: checkboxCommentPublic,
        comment: expertReviewNotes,
        status: FEEDBACK_REVIEW_STATUS_PENDING
      })
      .then(response => {
        this._getPreviousFeedbackReviews();
        this._getAwaitingFeedbackReviews();
        this._getReceivedFeedbackReviews();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Save Customer Project Review Feedback
  saveExpertProjectForCustomerFeedback = data => {
    const {
      id,
      communicationFromClient,
      compensation,
      overAllExperience,
      reviewNotes,
      checkboxCommentPublic
    } = data;

    const reviewRatingData = {
      communication_from_client: communicationFromClient,
      compensation: compensation,
      overall_experience: overAllExperience
    };

    if (
      communicationFromClient === 0 ||
      compensation === 0 ||
      overAllExperience === 0
    ) {
      toast.error("Ratings are required");
      return false;
    }

    return apiClient
      .put(`${endpoints().reviewAPI}/${id}`, {
        reviewRatingData,
        comment: reviewNotes,
        isPublic: checkboxCommentPublic,
        status: FEEDBACK_REVIEW_STATUS_PENDING
      })
      .then(response => {
        toast.success(response.data.message);
        this._getPreviousFeedbackReviews();
        this._getAwaitingFeedbackReviews();
        this._getReceivedFeedbackReviews();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  render() {
    const {
      status,
      sortByOptions,
      selectedSortOption,
      activeTab,
      previousFeedbackReviewCount,
      previousFeedbackReviews,
      awaitingFeedbackReviewCount,
      awaitingFeedbackReviews,
      receivedFeedbackReviews,
      receivedFeedbackReviewCount,
      pendingFeedbackReviewCount,
      approvedFeedbackReviewCount,
      isPendingFeedbackReviewLoading,
      isApprovedFeedbackReviewLoading,
      isAwaitingFeedbackReviewLoading,
      isPreviousFeedbackReviewLoading,
      isReceivedFeedbackReviewLoading,
      isLoading,
      feedbackCount
    } = this.state;

    const { settings } = this.props;

    if (isPendingFeedbackReviewLoading || !settings) {
      return <Spinner />;
    }

    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchStatus = params.get("status");

    const isPendingFeedback =
      searchStatus === FEEDBACK_STATUS_PENDING ||
      activeTab === FEEDBACK_STATUS_PENDING;
    const isApprovedFeedback =
      searchStatus === FEEDBACK_STATUS_APPROVED ||
      activeTab === FEEDBACK_STATUS_APPROVED;
    const isAwaitingFeedback =
      searchStatus === FEEDBACK_STATUS_AWAITING ||
      activeTab === FEEDBACK_STATUS_AWAITING;
    const isPreviousFeedBack =
      searchStatus === FEEDBACK_STATUS_PREVIOUS ||
      activeTab === FEEDBACK_STATUS_PREVIOUS;
    const isReceivedFeedBack =
      searchStatus === FEEDBACK_STATUS_RECEIVED ||
      activeTab === FEEDBACK_STATUS_RECEIVED;

    const feedback = FEEDBACK_TAB;

    return (
      <div>
        {!isPendingFeedbackReviewLoading && (
          <>
            <PageTitle label="Feedback" />

            {/* {status === FEEDBACK_STATUS_AWAITING ? (
              <Redirect to={`/feedback?status=${FEEDBACK_STATUS_AWAITING}`} />
            ) : (isSuperAdmin() ||
                isCompanyAdmin() ||
                isCompanyManager() ||
                isPartner()) &&
              !status ? (
              <Redirect to={`/feedback?status=${FEEDBACK_STATUS_PENDING}`} />
            ) : (
              ""
            )} */}

            <Nav tabs className="admin-tabs mb-0">
              {(isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId) ||
                isPartner(this.props.roleId)) && (
                <>
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: isPendingFeedback })}
                      onClick={() => {
                        this.toggle(FEEDBACK_STATUS_PENDING);
                        this._handleStatusChange(FEEDBACK_STATUS_PENDING);
                      }}
                    >
                      {FEEDBACK_STATUS_PENDING_LABEL}
                      <CountBadge
                        count={
                          this.props.pendingFeedbackReviewCounts
                            ? this.props.pendingFeedbackReviewCounts
                            : 0
                        }
                        isActive={isPendingFeedback}
                      />
                    </NavLink>
                  </NavItem> */}
                  {/* <NavItem>
                    <NavLink
                      className={classnames({ active: isApprovedFeedback })}
                      onClick={() => {
                        this.toggle(FEEDBACK_STATUS_APPROVED);
                        this._handleStatusChange(FEEDBACK_STATUS_APPROVED);
                      }}
                    >
                      {FEEDBACK_STATUS_APPROVED_LABEL}
                      <CountBadge
                        count={
                          this.props.approvedFeedbackReviewCounts
                            ? this.props.approvedFeedbackReviewCounts
                            : 0
                        }
                        isActive={isApprovedFeedback}
                      />
                    </NavLink>
                  </NavItem> */}
                </>
              )}
              {/* {(isCustomer() || isExpert()) && (
                <>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: isAwaitingFeedback })}
                      onClick={() => {
                        this.toggle(FEEDBACK_STATUS_AWAITING);
                        this._handleStatusChange(FEEDBACK_STATUS_AWAITING);
                        this._getAwaitingFeedbackReviews();
                      }}
                    >
                      {FEEDBACK_STATUS_AWAITING_LABEL}
                      <CountBadge
                        count={
                          awaitingFeedbackReviewCount
                            ? awaitingFeedbackReviewCount
                            : 0
                        }
                        isActive={isAwaitingFeedback}
                      />
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({ active: isPreviousFeedBack })}
                      onClick={() => {
                        this.toggle(FEEDBACK_STATUS_PREVIOUS);
                        this._handleStatusChange(FEEDBACK_STATUS_PREVIOUS);
                        this._getPreviousFeedbackReviews();
                      }}
                    >
                      {FEEDBACK_STATUS_PREVIOUS_LABEL}
                      <CountBadge
                        count={previousFeedbackReviewCount}
                        isActive={isPreviousFeedBack}
                      />
                    </NavLink>
                  </NavItem>
                </>
              )} */}
              {/* {isExpert() && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: isReceivedFeedBack })}
                    onClick={() => {
                      this.toggle(FEEDBACK_STATUS_RECEIVED);
                      this._handleStatusChange(FEEDBACK_STATUS_RECEIVED);
                      this._getReceivedFeedbackReviews();
                    }}
                  >
                    {FEEDBACK_STATUS_RECEIVED_LABEL}
                    <CountBadge
                      count={receivedFeedbackReviewCount}
                      isActive={isReceivedFeedBack}
                    />
                  </NavLink>
                </NavItem>
              )} */}
            </Nav>
            <TabContent activeTab={activeTab}>
              {(isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId) ||
                isPartner(this.props.roleId)) && (
                <>
                  <TabPane tabId={FEEDBACK_TAB}>
                    {isLoading ? (
                      <Spinner />
                    ) : this.state.feedbackCount &&
                      this.state.feedbackCount > 0 ? (
                      <PartnerFeedbackTable
                        tableName={FEEDBACK_TAB}
                        sortByOptions={this.state.sortByOptions}
                        history={this.props.history}
                      />
                    ) : (
                      <NoRecordsFound
                        icon={feedbackIcon}
                        iconClass="card-feedback-icon"
                        showMessage="false"
                        message="No Feedback Yet"
                        description="After partner send a feedback, your feedback will show up here"
                      />
                    )}
                  </TabPane>

                  {/* <TabPane tabId={FEEDBACK_STATUS_PENDING}>
                    {isPendingFeedbackReviewLoading ? (
                      <Spinner />
                    ) : pendingFeedbackReviewCount &&
                      pendingFeedbackReviewCount > 0 ? (
                      <AdminFeedbackTable
                        tableName={FEEDBACK_TABLE_PENDING}
                        status={FEEDBACK_REVIEW_STATUS_PENDING}
                        sortByOptions={this.state.sortByOptions}
                        history={this.props.history}
                      />
                    ) : (
                      <NoRecordsFound
                        icon={feedbackIcon}
                        iconClass="card-feedback-icon"
                        showMessage="false"
                        message="No Feedback Yet"
                        description="After projects are completed, your feedback will show up here"
                      />
                    )}
                  </TabPane> */}
                  {/* <TabPane tabId={FEEDBACK_STATUS_APPROVED}>
                    {isApprovedFeedbackReviewLoading ? (
                      <Spinner />
                    ) : approvedFeedbackReviewCount &&
                      approvedFeedbackReviewCount > 0 ? (
                      <AdminFeedbackTable
                        tableName={FEEDBACK_TABLE_APPROVED}
                        status={FEEDBACK_REVIEW_STATUS_APPROVED}
                        sortByOptions={this.state.sortByOptions}
                        history={this.props.history}
                      />
                    ) : (
                      <NoRecordsFound
                        icon={feedbackIcon}
                        iconClass="card-feedback-icon"
                        showMessage="false"
                        message="No Feedback Yet"
                        description="After projects are completed, your feedback will show up here"
                      />
                    )}
                  </TabPane> */}
                </>
              )}
              {(isCustomer(this.props.roleId) ||
                isExpert(this.props.roleId) ||
                isPartner(this.props.roleId)) && (
                <>
                  <TabPane tabId={FEEDBACK_STATUS_AWAITING}>
                    <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column">
                      <PageSearch
                        classnames="page-search"
                        placeholder={"Search"}
                        onChange={e =>
                          this._handleFeedbackSearch(
                            e,
                            FEEDBACK_REVIEW_STATUS_AWAITING
                          )
                        }
                      />

                      <SelectDropdown
                        dropdownLabel="Sort By:"
                        buttonLabel={selectedSortOption}
                        dropdownLinks={sortByOptions}
                        color={"gray"}
                        hideCaret
                        selectName="sortby_experts"
                      />
                    </div>
                    {isAwaitingFeedbackReviewLoading ? (
                      <Spinner />
                    ) : awaitingFeedbackReviews &&
                      awaitingFeedbackReviews.length > 0 ? (
                      awaitingFeedbackReviews.map(awaitingFeedbackReview =>
                        awaitingFeedbackReview &&
                        awaitingFeedbackReview.category ===
                          FEEDBACK_EXPERT_CATEGORY &&
                        awaitingFeedbackReview.projectExperts ? (
                          <ExpertReviewFeedbackForm
                            projectExpert={awaitingFeedbackReview}
                            saveExpertFeedback={e => this.saveExpertFeedback(e)}
                          />
                        ) : (
                          <>
                            {isCustomer(this.props.roleId) && (
                              <ProjectReviewFeedBackForm
                                projectDetails={awaitingFeedbackReview}
                                saveProjectFeedback={e =>
                                  this.saveProjectFeedback(e)
                                }
                              />
                            )}

                            {isExpert(this.props.roleId) && (
                              <ExpertProjectReviewFeedBackForm
                                projectDetails={awaitingFeedbackReview}
                                saveProjectFeedback={e =>
                                  this.saveExpertProjectForCustomerFeedback(e)
                                }
                              />
                            )}
                          </>
                        )
                      )
                    ) : (
                      <NoRecordsFound
                        icon={feedbackIcon}
                        iconClass="card-feedback-icon"
                        showMessage="false"
                        message="No Feedback Yet"
                        description="After projects are completed, your feedback will show up here"
                      />
                    )}
                  </TabPane>

                  <TabPane tabId={FEEDBACK_STATUS_PREVIOUS}>
                    <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column">
                      <PageSearch
                        classnames="page-search"
                        placeholder={"Search"}
                        onChange={e =>
                          this._handleFeedbackSearch(
                            e,
                            FEEDBACK_REVIEW_STATUS_PREVIOUS
                          )
                        }
                      />

                      <SelectDropdown
                        dropdownLabel="Sort By:"
                        buttonLabel={selectedSortOption}
                        dropdownLinks={sortByOptions}
                        color="gray"
                        hideCaret
                        selectName={"sortby_experts"}
                      />
                    </div>
                    {isPreviousFeedbackReviewLoading ? (
                      <Spinner />
                    ) : previousFeedbackReviews.length > 0 ? (
                      previousFeedbackReviews.map(previousFeedbackReview =>
                        previousFeedbackReview &&
                        previousFeedbackReview.projectExperts &&
                        previousFeedbackReview.category ===
                          FEEDBACK_EXPERT_CATEGORY ? (
                          <ExpertReviewCard
                            expertReviewDetail={previousFeedbackReview}
                          />
                        ) : (
                          <>
                            {isCustomer(this.props.roleId) && (
                              <ProjectReviewCard
                                projectReviewDetail={previousFeedbackReview}
                              />
                            )}

                            {isExpert(this.props.roleId) && (
                              <ExpertProjectReviewCard
                                projectReviewDetail={previousFeedbackReview}
                              />
                            )}
                          </>
                        )
                      )
                    ) : (
                      <NoRecordsFound
                        icon={feedbackIcon}
                        iconClass="card-feedback-icon"
                        showMessage="false"
                        message="No Feedback Yet"
                        description="After projects are completed, your feedback will show up here"
                      />
                    )}
                  </TabPane>

                  <TabPane tabId={FEEDBACK_STATUS_RECEIVED}>
                    <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column">
                      <PageSearch
                        classnames="page-search"
                        placeholder={"Search"}
                        onChange={e =>
                          this._handleFeedbackSearch(
                            e,
                            FEEDBACK_REVIEW_STATUS_PREVIOUS,
                            FEEDBACK_EXPERT_CATEGORY
                          )
                        }
                      />

                      <SelectDropdown
                        dropdownLabel="Sort By:"
                        buttonLabel={selectedSortOption}
                        dropdownLinks={sortByOptions}
                        color={"gray"}
                        hideCaret
                        selectName="sortby_experts"
                      />
                    </div>
                    {isReceivedFeedbackReviewLoading ? (
                      <Spinner />
                    ) : receivedFeedbackReviews &&
                      receivedFeedbackReviews.length > 0 ? (
                      receivedFeedbackReviews.map(
                        receivedFeedbackReview =>
                          receivedFeedbackReview &&
                          receivedFeedbackReview.category ===
                            FEEDBACK_EXPERT_CATEGORY &&
                          receivedFeedbackReview.projectExperts && (
                            <ProjectReviewCard
                              projectReviewDetail={receivedFeedbackReview}
                            />
                          )
                      )
                    ) : (
                      <NoRecordsFound
                        icon={feedbackIcon}
                        iconClass="card-feedback-icon"
                        showMessage="false"
                        message="No Feedback Yet"
                        description="After projects are completed, your feedback will show up here"
                      />
                    )}
                  </TabPane>
                </>
              )}
            </TabContent>
          </>
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { pendingFeedback, approvedFeedback } = state.table;
  const roleId = state.user ? state.user.roleId : "";
  // Get pending pending feedback count
  const pendingFeedbackReviewCounts =
    pendingFeedback && !pendingFeedback.isFetching
      ? pendingFeedback.totalCount
      : 0;
  // Get pending pending feedback count
  const approvedFeedbackReviewCounts =
    approvedFeedback && !approvedFeedback.isFetching
      ? approvedFeedback.totalCount
      : 0;
  return { pendingFeedbackReviewCounts, approvedFeedbackReviewCounts, roleId };
};

export default connect(mapStateToProps)(Feedbacks);
