import React, { useState } from "react";

import EmailValidation from "../../../../lib/Email";
import PhoneValidation from "../../../../lib/Phone";

import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Button from "../../../../components/base/Button";
import CancelButton from "../../../../components/base/CancelButton";
import Email from "../../../../components/base/Email";
import Form from "../../../../components/base/Form";
import Phone from "../../../../components/base/Phone";
import Text from "../../../../components/base/Text";

function AdditionalContactModal(props) {
  const {
    referralId,
    isOpen,
    toggle,
    saveContactData,
    contactArrayIndex
  } = props;

  const [contactFirstname, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [title, setTitle] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [errorMessage, setErrorMessage] = useState(false);

  // handle chnage for fields
  const handleContactFirstName = event => {
    setContactFirstName(event.target.value);
    setErrorMessage(false);
  };

  const handleContactLastName = event => {
    setContactLastName(event.target.value);
  };

  const handleContactTitle = event => {
    setTitle(event.target.value);
  };

  const handleContactEmail = event => {
    setContactEmail(event.target.value);
  };

  const handleContactPhone = event => {
    setContactPhone(event.target.value);
  };

  // validate the inputes
  const validateContact = data => {
    let success = true;
    data &&
      data.forEach(values => {
        if (values && !values.contactFirstname) {
          success = false;
          setErrorMessage(true);
        } else if (values && EmailValidation.isValid(values.contactEmail)) {
          success = false;
        } else if (values && PhoneValidation.isValid(values.contactPhone)) {
          success = false;
        }
      });
    return success;
  };

  const handleContactData = () => {
    const data = [];
    data.push({
      contactArrayIndex: `${contactArrayIndex + 1}`,
      referralId: referralId ? referralId : "",
      contactFirstname: contactFirstname ? contactFirstname : "",
      contactLastName: contactLastName ? contactLastName : "",
      title: title ? title : "",
      contactEmail: contactEmail ? contactEmail : "",
      contactPhone: contactPhone ? contactPhone : ""
    });
    if (validateContact(data) === false) {
      return;
    }
    saveContactData(data, toggle);
    setContactFirstName("");
    setContactLastName("");
    setTitle("");
    setContactEmail("");
    setContactPhone("");
  };

  // handle toggle func
  const handleToggle = () => {
    toggle();
    setErrorMessage(false);
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={handleToggle}
        className={["edit-task-modal"].join(" ")}
      >
        <ModalHeader toggle={handleToggle}>
          <Label>Add Additional Contact</Label>
        </ModalHeader>
        <Form
          initialValues={{
            contactFirstName: "",
            contactLastName: "",
            email: "",
            phone: ""
          }}
          enableReinitialize={true}
        >
          <ModalBody>
            <Text
              name="contactFirstName"
              label="First Name"
              placeholder="Enter first name"
              onChange={e => {
                handleContactFirstName(e);
              }}
              required={true}
            />
            <small className={`text-danger ${"d-block"}`}>
              {errorMessage && "First Name is required"}
            </small>
            <Text
              name="contactLastName"
              label="Last Name"
              placeholder="Enter last name"
              onChange={e => {
                handleContactLastName(e);
              }}
            />
            <Text
              name="title"
              label="Title"
              placeholder="Enter Contact Title"
              onChange={e => {
                handleContactTitle(e);
              }}
            />
            <Email
              name="email"
              label="Email"
              placeholder="Enter Email Address..."
              onChange={e => {
                handleContactEmail(e);
              }}
            />
            <Phone
              name="phone"
              label="Phone Number"
              placeholder="Enter phone number"
              onChange={e => {
                handleContactPhone(e);
              }}
            />
          </ModalBody>
          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <CancelButton onClick={handleToggle} />
              <Button
                label="Add"
                type="button"
                className="btn btn-primary"
                onClick={handleContactData}
              />
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
}

export default AdditionalContactModal;
