import React from "react";

const KickoffTipsWrapper = props => (
  <div className="kickoff-tips">{props.children}</div>
);

const KickoffTip = ({ number, description }) => (
  <div className="kickoff-tips__tip">
    <span className="kickoff-tips__number">{number}</span>
    <p className="kickoff-tips__description">{description}</p>
  </div>
);

const KickoffTips = ({ description, children }) => (
  <div className={"kickoff-scheduler"}>
    <p className={"kickoff-tips__description--main"}>
      <strong>{description}</strong>
    </p>
    {children}
  </div>
);

export { KickoffTips, KickoffTipsWrapper, KickoffTip };
