import React, { useState } from "react";
import Rating from "react-rating";
import { Input, Label } from "reactstrap";

// Assets
import { StarIcon, StarIconFilled } from "../assets/img/icons";

// Components
import PlaybookCard from "./PlaybookCard";

const ProjectReviewFeedBackForm = props => {
  const [projectValueForMoney, setProjectValueForMoney] = useState(0);
  const [
    projectQualityOfFinalDeliverable,
    setProjectQualityOfFinalDeliverable
  ] = useState(0);
  const [projectOverAllExperience, setProjectOverAllExperience] = useState(0);
  const [projectReviewNotes, setProjectReviewNotes] = useState("");
  const [checkboxCommentPublic, setCheckboxCommentPublic] = useState(false);

  const handleProjectValueForMoney = value => setProjectValueForMoney(value);
  const handleProjectQualityOfFinalDeliverable = value =>
    setProjectQualityOfFinalDeliverable(value);
  const handleProjectOverallExperience = value =>
    setProjectOverAllExperience(value);

  const handleProjectReview = e => setProjectReviewNotes(e.target.value);

  const handleCheckboxCommentPublic = () =>
    setCheckboxCommentPublic(!checkboxCommentPublic);

  const {
    projectDetails: { id, projectDetails },
    saveProjectFeedback
  } = props;

  return (
    <div className="feedback-component d-flex justify-content-start">
      <PlaybookCard
        id={id}
        data={projectDetails}
        isPreview={"true"}
        boxShadow={false}
        placeHolder={true}
        bannerImageUrl={projectDetails.bannerImageUrl}
        tag={"div"}
      />
      <div className="rating-component">
        <div className="form-wrapper">
          <div className="rating-wrapper d-flex align-items-center">
            <Rating
              id={id}
              initialRating={projectValueForMoney}
              emptySymbol={<StarIcon />}
              fullSymbol={<StarIconFilled />}
              onChange={handleProjectValueForMoney}
            />{" "}
            <h5 className="font-weight-bold ml-3">Value for Money</h5>
          </div>
          <div className="rating-wrapper d-flex align-items-center">
            <Rating
              id={id}
              initialRating={projectQualityOfFinalDeliverable}
              emptySymbol={<StarIcon />}
              fullSymbol={<StarIconFilled />}
              onChange={handleProjectQualityOfFinalDeliverable}
            />{" "}
            <h5 className="font-weight-bold ml-3">
              Quality of Final Deliverable(s)
            </h5>
          </div>
          <div className="rating-wrapper d-flex align-items-center">
            <Rating
              id={id}
              initialRating={projectOverAllExperience}
              emptySymbol={<StarIcon />}
              fullSymbol={<StarIconFilled />}
              onChange={handleProjectOverallExperience}
            />{" "}
            <h5 className="font-weight-bold ml-3">Overall Experience</h5>
          </div>
          <div className="field-wrapper">
            <label>Tell us about your experience with this Project</label>
            <Input
              id={id}
              type="textarea"
              placeholder={"Leave feedback and let others know how things went"}
              style={{
                background: "#F3F3F4",
                border: "none",
                borderRadius: "5px",
                fontSize: "14px",
                minHeight: 132
              }}
              onChange={handleProjectReview}
            />
          </div>
          <div className="btn-wrapper d-flex align-items-center justify-content-stretch">
            <div className="checkbox-wrapper">
              <Label htmlFor={`agreeCheck-${id}`}>
                <Input
                  id={`agreeCheck-${id}`}
                  name={"agreeCheck"}
                  type="checkbox"
                  defaultChecked={checkboxCommentPublic}
                  value={"value"}
                  onChange={handleCheckboxCommentPublic}
                />{" "}
                <span
                  className={`checkbox-placeholder ${checkboxCommentPublic &&
                    "active"}`}
                />
                <span>I don’t want my comments made public.</span>
              </Label>
            </div>
            <button
              className="btn btn-primary mr-0 ml-auto"
              onClick={() =>
                saveProjectFeedback({
                  id,
                  projectValueForMoney,
                  projectQualityOfFinalDeliverable,
                  projectOverAllExperience,
                  projectReviewNotes,
                  checkboxCommentPublic
                })
              }
            >
              Submit My Feedback
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectReviewFeedBackForm;
