import React, { Component } from "react";
import PreviewImage from "./PreviewImage";
import { Label } from "reactstrap";

// Constants
import {
  LANDING_PAGE_BLOCK_TYPE_BANNER,
  LANDING_PAGE_BLOCK_TYPE_SIGN_UP,
  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK,
  LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT,
  LANDING_PAGE_BLOCK_TYPE_EXPERT,
  LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT,
  LANDING_PAGE_BLOCK_TYPE_CONTENT,
  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID,
  LANDING_PAGE_BLOCK_TYPE_PARTNER
} from "../../landingPage/Constants";

// Components
import ColorPicker from "../../components/base/ColorPicker";
import HighContrastColorPicker from "../../components/base/HighContrastColorPicker";
import AddButton from "../../components/base/AddButton";
import PlaybookCard from "../../components/PlaybookCard";
import Text from "../../components/base/Text";
import AddModal from "../../components/base/Modal";
import TextArea from "../../components/base/TextArea";
import ExpertGrid from "../../components/ExpertGrid";
import URL from "../../components/base/URL";
import PartnerGrid from "../../components/PartnerGrid";

class LandingPageBlockFormFields extends Component {
  // Render the form fields
  render() {
    const {
      type,
      partnerList,
      partnerIds,
      selectedPartners,
      initialPartnerIdsLength,
      handlePartners,
      handleSelectedPartners,
      expertList,
      handleExperts,
      expertIds,
      handleImageChange,
      handleBackgroundColor,
      handleBackgroundColor1,
      toggle,
      toggleModalClose,
      isOpen,
      backgroundColor,
      textColor,
      backgroundColor1,
      textColor1,
      playbookList,
      selectedPlaybooks,
      selectedExperts,
      initialExpertIdsLength,
      playbookIds,
      handlePlaybooks,
      backgroundImageDesktopUrlPreview,
      backgroundImageMobileUrlPreview,
      backgroundImageDesktopUrl,
      backgroundImageMobileUrl,
      backgroundImageDesktopUrl1,
      backgroundImageMobileUrl1,
      backgroundImageDesktopUrl1Preview,
      backgroundImageMobileUrl1Preview,
      handleImageRemove,
      handleSelectedPlaybooks,
      logoImageDesktopUrlPreview,
      logoImageDesktopUrl,
      logoImageMobileUrlPreview,
      logoImageMobileUrl,
      handleSelectedExperts,
      handleContrastColorSelect
    } = this.props;

    const partnerModalHeader = "SELECT PARTNERS";
    const partnerModalBody = (
      <>
        {partnerList.length === selectedPartners.length ? (
          <p className="text-center w-100 mt-4">No Partners</p>
        ) : (
          ""
        )}
        <PartnerGrid
          partnerIds={partnerIds}
          partnerList={partnerList}
          selectPartners={true}
          selectedPartners={selectedPartners}
          handlePartners={handlePartners}
        />
      </>
    );
    const partnerModalFooter = (
      <AddButton
        label="Add Selected Partners"
        onClick={
          partnerIds.length > initialPartnerIdsLength
            ? () => handleSelectedPartners()
            : ""
        }
        isDisabled={partnerIds.length > initialPartnerIdsLength ? false : true}
      />
    );

    const expertModalHeader = "SELECT EXPERTS";
    const expertModalBody = (
      <>
        {expertList.length === selectedExperts.length ? (
          <p className="text-center w-100 mt-4">No Experts</p>
        ) : (
          ""
        )}
        <ExpertGrid
          expertIds={expertIds}
          expertList={expertList}
          selectExperts={true}
          selectedExperts={selectedExperts}
          handleExperts={handleExperts}
        />
      </>
    );
    const expertModalFooter = (
      <AddButton
        label="Add Selected Experts"
        onClick={
          expertIds.length > initialExpertIdsLength
            ? () => handleSelectedExperts()
            : ""
        }
        isDisabled={expertIds.length > initialExpertIdsLength ? false : true}
      />
    );
    const playbookModalHeader = "SELECT PLAYBOOKS";
    const playbookModalBody = (
      <div className="row mt-n4">
        {playbookList.length === selectedPlaybooks.length ? (
          <p className="text-center w-100 mt-4">No playbooks are added yet</p>
        ) : (
          ""
        )}
        {playbookList.length > 0 &&
          playbookList.map(
            playbook =>
              selectedPlaybooks &&
              selectedPlaybooks.indexOf(playbook.id) === -1 && (
                <div className="col-12 col-md-12 col-lg-6 col-xl-4">
                  <PlaybookCard
                    data={playbook}
                    isActive={
                      playbookIds.indexOf(parseInt(playbook.id, 10)) > -1
                    }
                    selectPlaybooks={true}
                    handlePlaybooks={handlePlaybooks}
                    key={playbook.id}
                    history={this.props.history}
                  />
                </div>
              )
          )}
      </div>
    );
    const playbookModalFooter = (
      <AddButton
        label="Add Selected Playbooks"
        onClick={() => handleSelectedPlaybooks()}
        isDisabled={
          playbookIds.length > selectedPlaybooks.length ? false : true
        }
      />
    );

    return (
      <div>
        {type === LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT && <h3>Section 1</h3>}
        {(type === LANDING_PAGE_BLOCK_TYPE_BANNER ||
          type === LANDING_PAGE_BLOCK_TYPE_SIGN_UP ||
          type === LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT ||
          type === LANDING_PAGE_BLOCK_TYPE_PLAYBOOK ||
          type === LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID ||
          type === LANDING_PAGE_BLOCK_TYPE_PARTNER ||
          type === LANDING_PAGE_BLOCK_TYPE_EXPERT ||
          type === LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT ||
          type === LANDING_PAGE_BLOCK_TYPE_CONTENT) && (
          <>
            <div>
              <Text
                name="title"
                label="Title"
                placeholder="Enter title"
                required={true}
                error=""
              />
            </div>
          </>
        )}

        {(type === LANDING_PAGE_BLOCK_TYPE_BANNER ||
          type === LANDING_PAGE_BLOCK_TYPE_SIGN_UP ||
          type === LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT ||
          type === LANDING_PAGE_BLOCK_TYPE_PLAYBOOK ||
          type === LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID ||
          type === LANDING_PAGE_BLOCK_TYPE_PARTNER ||
          type === LANDING_PAGE_BLOCK_TYPE_EXPERT ||
          type === LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT) && (
          <div>
            <TextArea
              name="subtitle"
              label="Subtitle"
              placeholder="Enter subtitle"
              rows="5"
            />
          </div>
        )}

        {(type === LANDING_PAGE_BLOCK_TYPE_BANNER ||
          type === LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT) && (
          <>
            <div>
              <Text name="link" label="Link" placeholder="Enter Link" />
            </div>
          </>
        )}

        {type === LANDING_PAGE_BLOCK_TYPE_BANNER && (
          <>
            <div>
              <URL
                name="logoLink"
                label="Logo Link"
                placeholder="Enter Logo Link"
              />
            </div>
          </>
        )}

        {type === LANDING_PAGE_BLOCK_TYPE_BANNER && (
          <div>
            {/* Preview image */}
            <div className="form-group">
              <input
                name="logoImageDesktopUrl"
                className="form-control d-none"
                type="file"
                id="logoImageDesktopUrl"
                onChange={handleImageChange}
              />
              {/* Upload image link */}
              <span className="profilePicOverlay d-block mb-3">
                <label
                  htmlFor="logoImageDesktopUrl"
                  className="profile-img-sm mb-0"
                >
                  <span className="banner-image-upload-link">
                    Upload Desktop Logo Image
                  </span>
                </label>
              </span>
              {logoImageDesktopUrlPreview || logoImageDesktopUrl ? (
                <div>
                  <PreviewImage
                    imageUrl={logoImageDesktopUrl}
                    previewImage={logoImageDesktopUrlPreview}
                    height="100px"
                  />
                  {/* Remove image */}
                  <span
                    id="logoImageDesktopUrl"
                    className="banner-image-upload-link text-danger"
                    onClick={handleImageRemove}
                  >
                    Remove
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>

            <div className="form-group">
              <input
                name="logoImageMobileUrl"
                className="form-control d-none"
                type="file"
                id="logoImageMobileUrl"
                onChange={handleImageChange}
              />
              {/* Upload image link */}
              <span className="profilePicOverlay d-block mb-3">
                <label
                  htmlFor="logoImageMobileUrl"
                  className="profile-img-sm mb-0"
                >
                  <span className="banner-image-upload-link">
                    Upload Mobile Logo Image
                  </span>
                </label>
              </span>
              {logoImageMobileUrlPreview || logoImageMobileUrl ? (
                <div>
                  <PreviewImage
                    imageUrl={logoImageMobileUrl}
                    previewImage={logoImageMobileUrlPreview}
                    height="100px"
                  />
                  {/* Remove image */}
                  <span
                    id="logoImageMobileUrl"
                    className="banner-image-upload-link text-danger"
                    onClick={handleImageRemove}
                  >
                    Remove
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}

        {(type === LANDING_PAGE_BLOCK_TYPE_BANNER ||
          type === LANDING_PAGE_BLOCK_TYPE_SIGN_UP ||
          type === LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT ||
          type === LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT) && (
          <div>
            {/* Preview image */}
            <div className="form-group">
              <input
                name="backgroundImageDesktopUrl"
                className="form-control d-none"
                type="file"
                id="backgroundImageDesktopUrl"
                onChange={handleImageChange}
              />
              {/* Upload image link */}
              <span className="profilePicOverlay d-block mb-3">
                <label
                  htmlFor="backgroundImageDesktopUrl"
                  className="profile-img-sm mb-0"
                >
                  <span className="banner-image-upload-link">
                    Upload Desktop Background Image
                  </span>
                </label>
              </span>
              {backgroundImageDesktopUrlPreview || backgroundImageDesktopUrl ? (
                <div>
                  <PreviewImage
                    imageUrl={backgroundImageDesktopUrl}
                    previewImage={backgroundImageDesktopUrlPreview}
                    height="300px"
                  />
                  {/* Remove image */}
                  <span
                    id="backgroundImageDesktopUrl"
                    className="banner-image-upload-link text-danger"
                    onClick={handleImageRemove}
                  >
                    Remove
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {(type === LANDING_PAGE_BLOCK_TYPE_BANNER ||
          type === LANDING_PAGE_BLOCK_TYPE_SIGN_UP ||
          type === LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT ||
          type === LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT) && (
          <div>
            {/* Preview image */}
            <div className="form-group">
              <input
                name="backgroundImageMobileUrl"
                className="form-control d-none"
                type="file"
                id="backgroundImageMobileUrl"
                onChange={handleImageChange}
              />
              {/* Upload image link */}
              <span className="profilePicOverlay d-block mb-3">
                <label
                  htmlFor="backgroundImageMobileUrl"
                  className="profile-img-sm mb-0"
                >
                  <span className="banner-image-upload-link">
                    Upload Mobile Background Image
                  </span>
                </label>
              </span>
              {backgroundImageMobileUrlPreview || backgroundImageMobileUrl ? (
                <div>
                  <PreviewImage
                    imageUrl={backgroundImageMobileUrl}
                    previewImage={backgroundImageMobileUrlPreview}
                    height="300px"
                  />
                  {/* Remove image */}
                  <span
                    id="backgroundImageMobileUrl"
                    className="banner-image-upload-link text-danger"
                    onClick={handleImageRemove}
                  >
                    Remove
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        )}
        {(type === LANDING_PAGE_BLOCK_TYPE_BANNER ||
          type === LANDING_PAGE_BLOCK_TYPE_SIGN_UP ||
          type === LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT ||
          type === LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT) && (
          <div className="form-group">
            <Label>Background Color</Label>
            <div className="row">
              <div className="col-md-6">
                <ColorPicker
                  onColourPickerClick={handleBackgroundColor}
                  bannerColor={backgroundColor}
                />
              </div>
            </div>
          </div>
        )}
        {(type === LANDING_PAGE_BLOCK_TYPE_BANNER ||
          type === LANDING_PAGE_BLOCK_TYPE_SIGN_UP ||
          type === LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT ||
          type === LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT) && (
          <div className="form-group">
            <Label>Text Color</Label>
            <div className="row">
              <div className="col-md-6">
                <HighContrastColorPicker
                  handleSelect={handleContrastColorSelect}
                  selectedColor={textColor}
                  inputName={"textColor"}
                />
              </div>
            </div>
          </div>
        )}
        {(type === LANDING_PAGE_BLOCK_TYPE_BANNER ||
          type === LANDING_PAGE_BLOCK_TYPE_SIGN_UP ||
          type === LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT) && (
          <div>
            <Text
              name="buttonLabel"
              label="Button Label"
              placeholder="Enter button label"
              error=""
            />
          </div>
        )}
        {(type === LANDING_PAGE_BLOCK_TYPE_BANNER ||
          type === LANDING_PAGE_BLOCK_TYPE_SIGN_UP ||
          type === LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT) && (
          <div>
            <URL
              name="buttonLink"
              label="Button Link"
              placeholder="Enter button link"
            />
          </div>
        )}
        {type === LANDING_PAGE_BLOCK_TYPE_PARTNER && (
          <div>
            {/*Add Partners Button*/}
            <AddButton
              label="Add Partners"
              className="btn btn-secondary mb-3"
              onClick={toggle}
            />

            {/*Add Partners Modal*/}
            <AddModal
              isOpen={isOpen}
              toggle={toggle}
              toggleModalClose={toggleModalClose}
              HeaderClassName="expert-modal"
              modalTitle={partnerModalHeader}
              modalBody={partnerModalBody}
              modalFooter={partnerModalFooter}
              hideDefaultButtons
            />
          </div>
        )}
        {type === LANDING_PAGE_BLOCK_TYPE_EXPERT && (
          <div>
            {/*Add Experts Button*/}
            <AddButton
              label="Add Experts"
              className="btn btn-secondary mb-3"
              onClick={toggle}
            />

            {/*Add Experts Modal*/}
            <AddModal
              isOpen={isOpen}
              toggle={toggle}
              toggleModalClose={toggleModalClose}
              HeaderClassName="expert-modal"
              modalTitle={expertModalHeader}
              modalBody={expertModalBody}
              modalFooter={expertModalFooter}
              hideDefaultButtons
            />
          </div>
        )}
        {(type === LANDING_PAGE_BLOCK_TYPE_PLAYBOOK ||
          type === LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID) && (
          <div>
            {/*Select Playbooks Button*/}
            <AddButton
              label="Add Playbooks"
              className="btn btn-secondary mb-3"
              onClick={toggle}
            />

            {/*Select Playbooks Modal*/}
            <AddModal
              isOpen={isOpen}
              toggle={toggle}
              toggleModalClose={toggleModalClose}
              HeaderClassName="expert-modal"
              modalTitle={playbookModalHeader}
              modalBody={playbookModalBody}
              modalFooter={playbookModalFooter}
              hideDefaultButtons
            />
          </div>
        )}
        {type === LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT && (
          <>
            <h3>Section 2</h3>

            <div>
              <Text
                name="title1"
                label="Title"
                placeholder="Enter title"
                required={true}
                error=""
              />
            </div>

            <Text
              name="subtitle1"
              label="Subtitle"
              placeholder="Enter subtitle"
              maxLength={1000}
            />

            <div>
              <URL name="link1" label="Link" placeholder="Enter Link" />
            </div>

            {/* Preview image */}
            <div className="form-group">
              <input
                name="backgroundImageDesktopUrl1"
                className="form-control d-none"
                type="file"
                id="backgroundImageDesktopUrl1"
                onChange={handleImageChange}
              />
              {/* Upload image link */}
              <span className="profilePicOverlay d-block mb-3">
                <label
                  htmlFor="backgroundImageDesktopUrl1"
                  className="profile-img-sm mb-0"
                >
                  <span className="banner-image-upload-link">
                    Upload Desktop Background Image
                  </span>
                </label>
              </span>
              {backgroundImageDesktopUrl1Preview ||
              backgroundImageDesktopUrl1 ? (
                <div>
                  <PreviewImage
                    imageUrl={backgroundImageDesktopUrl1}
                    previewImage={backgroundImageDesktopUrl1Preview}
                    height="300px"
                  />
                  {/* Remove image */}
                  <span
                    id="backgroundImageDesktopUrl1"
                    className="banner-image-upload-link text-danger"
                    onClick={handleImageRemove}
                  >
                    Remove
                  </span>
                </div>
              ) : (
                ""
              )}
            </div>

            <div>
              {/* Mobile Preview image 1 */}
              <div className="form-group">
                <input
                  name="backgroundImageMobileUrl1"
                  className="form-control d-none"
                  type="file"
                  id="backgroundImageMobileUrl1"
                  onChange={handleImageChange}
                />
                {/* Upload image link */}
                <span className="profilePicOverlay d-block mb-3">
                  <label
                    htmlFor="backgroundImageMobileUrl1"
                    className="profile-img-sm mb-0"
                  >
                    <span className="banner-image-upload-link">
                      Upload Mobile Background Image
                    </span>
                  </label>
                </span>
                {backgroundImageMobileUrl1Preview ||
                backgroundImageMobileUrl1 ? (
                  <div>
                    <PreviewImage
                      imageUrl={backgroundImageMobileUrl1}
                      previewImage={backgroundImageMobileUrl1Preview}
                      height="300px"
                    />
                    {/* Remove image */}
                    <span
                      id="backgroundImageMobileUrl1"
                      className="banner-image-upload-link text-danger"
                      onClick={handleImageRemove}
                    >
                      Remove
                    </span>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>

            <div className="form-group">
              <Label>Background Color</Label>
              <div className="row">
                <div className="col-md-6">
                  <ColorPicker
                    onColourPickerClick={handleBackgroundColor1}
                    bannerColor={backgroundColor1}
                  />
                </div>
              </div>
            </div>

            <div className="form-group">
              <Label>Text Color</Label>
              <div className="row">
                <div className="col-md-6">
                  <HighContrastColorPicker
                    handleSelect={handleContrastColorSelect}
                    selectedColor={textColor1}
                    inputName={"textColor1"}
                  />
                </div>
              </div>
            </div>
          </>
        )}

        {type === LANDING_PAGE_BLOCK_TYPE_CONTENT && (
          <>
            <div className="form-wrapper justify-content-center d-flex">
              <div className="field-wrapper w-100">
                <TextArea
                  name="content"
                  label="Content"
                  placeholder=" Content..."
                  rows="15"
                />
              </div>
            </div>
          </>
        )}
      </div>
    );
  }
}

export default LandingPageBlockFormFields;
