import React from "react";

// Components
import Form from "../../components/base/Form";
import DefaultContent from "../../components/content/DefaultContent";
import Text from "../../components/base/Text";
import Select from "../../components/base/Select";
import SaveButton from "../../components/base/SaveButton";

// Constants
import {
  SETTINGS_DEFAULT_CLOSE_DAYS,
  SETTINGS_REFERRAL_PHONE_NUMBER_REQUIRED,
  SETTINGS_ALLOW_PARTNER_MANAGER_TO_MANAGE_OTHER_REFERRALS
} from "../../setting/Constants";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
import SingleCheckbox from "../../views/adminSettings/inner-component/Checkbox";
import { SHOW_REFERRAL_AMOUNT, ShowReferralAmountOptions } from "../Constants";

const Setting = props => {
  const { adminSettings, saveSettings } = props;

  const {
    referral_default_close_days,
    show_referral_number,
    show_partner_manager,
    show_referral_amount,
    referral_phone_number_required,
    allow_partner_managers_to_manage_others_referral
  } = adminSettings;

  // General Settings General Section Initial Values
  const generalSettingsGeneralSectionInitialValues = {
    referral_default_close_days,
    show_referral_number,
    show_partner_manager,
    show_referral_amount: ShowReferralAmountOptions.find(
      data => data.value == show_referral_amount
    ),
    referral_phone_number_required: referral_phone_number_required,
    allow_partner_managers_to_manage_others_referral: allow_partner_managers_to_manage_others_referral
  };

  // General Settings General Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Marketplace name
    if (values.referral_default_close_days !== undefined) {
      data.append(
        "referral_default_close_days",
        values.referral_default_close_days
          ? values.referral_default_close_days
          : ""
      );
    }
    data.append(
      "show_referral_amount",
      values.show_referral_amount ? values.show_referral_amount.value : ""
    );
    data.append(
      "show_referral_number",
      values.show_referral_number ? values.show_referral_number : ""
    );
    data.append(
      "referral_phone_number_required",
      values.referral_phone_number_required
        ? values.referral_phone_number_required
        : ""
    );

    data.append(
      "show_partner_manager",
      values.show_partner_manager ? values.show_partner_manager : ""
    );

    data.append(
      "allow_partner_managers_to_manage_others_referral",
      values.allow_partner_managers_to_manage_others_referral
        ? values.allow_partner_managers_to_manage_others_referral
        : ""
    );
    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={generalSettingsGeneralSectionInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          {/* Landing page URL */}
          <div className="field-wrapper">
            <Text
              label={`${Terminology.get(
                SystemName.REFERRAL
              )} Default Close Days`}
              name={SETTINGS_DEFAULT_CLOSE_DAYS}
              placeholder="Enter Default Close Days"
            />
          </div>
          <div className="field-wrapper mb-3">
            <Select
              name={SHOW_REFERRAL_AMOUNT}
              label={`Show ${Terminology.get(SystemName.REFERRAL)} Amount`}
              options={ShowReferralAmountOptions}
              className="accepted-terms mb-0 pb-0 mr-3"
              isClearable
            />
          </div>
          <div className="field-wrapper mb-3">
            <SingleCheckbox
              name={"show_referral_number"}
              label={`Show ${Terminology.get(SystemName.REFERRAL)} Number`}
              className="accepted-terms mb-0 pb-0 mr-3"
            />
          </div>
          <div className="field-wrapper mb-3">
            <SingleCheckbox
              name={"show_partner_manager"}
              label={`Show ${Terminology.get(
                SystemName.PARTNER_MANAGER
              )} Selection`}
              className="accepted-terms mb-0 pb-0 mr-3"
            />
          </div>

          <div className="field-wrapper mb-3">
            <SingleCheckbox
              name={SETTINGS_REFERRAL_PHONE_NUMBER_REQUIRED}
              label={`${Terminology.get(
                SystemName.REFERRAL
              )} Phone Number Required`}
              className="accepted-terms mb-0 pb-0 mr-3"
            />
          </div>

          <div className="field-wrapper mb-3">
            <SingleCheckbox
              name={SETTINGS_ALLOW_PARTNER_MANAGER_TO_MANAGE_OTHER_REFERRALS}
              label={`Allow Partner Managers to Manage Others ${Terminology.get(
                SystemName.REFERRAL
              )}`}
              className="accepted-terms mb-0 pb-0 mr-3"
            />
          </div>
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default Setting;
