import React from "react";

// Components
import SecondaryButton from "../../components/base/SecondaryButton";

// Page Components
import ExpertRequestQuestion from "./ExpertRequestQuestion";

// Assets
import { TimelineIcon } from "../../assets/img/icons";

class TimeCommitment extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      timeCommitmentOptions: [
        {
          value: "10-40 hrs total",
          label: "10-40 hrs total",
          tooltip: ""
        },
        {
          value: "10-20 hrs/week",
          label: "10-20 hrs/week",
          tooltip: ""
        },
        {
          value: "20-30 hrs/week",
          label: "20-30 hrs/week",
          tooltip: ""
        },
        {
          value: "30+ hrs/week",
          label: "30+ hrs/week",
          tooltip: ""
        },
        {
          value: "I want help figuring this out",
          label: "I want help figuring this out",
          tooltip: ""
        },
        {
          value: "I'll decide later",
          label: "I'll decide later",
          tooltip: ""
        }
      ]
    };
  }

  render() {
    const { currentStep, step, prev, next } = this.props;
    const { timeCommitmentOptions } = this.state;

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <TimelineIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-4">Time Commitment</h2>
        </div>
        <div className="subtitle-wrapper mt-4">
          <h5 className="font-weight-light">
            What level of time commitment are you looking for from the
            Salesforce Expert?
          </h5>
        </div>
        <div className="content">
          <ExpertRequestQuestion
            name="timeCommitment"
            options={timeCommitmentOptions}
            error="Time commitment is required"
            required
          />

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={next}
            >
              Next: Start Date
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default TimeCommitment;
