import moment from "moment";
/**
 * Is data True
 * @param value
 */
export const isTrue = value => {
  if (value === "true") {
    return true;
  } else return false;
};

/**
 * Is data False
 * @param value
 */
export const isFalse = value => {
  if (value !== "true") {
    return true;
  } else return false;
};

/**
 * Is data False
 * @param value
 */
export function isNotEmpty(value) {
  if (value && value.length > 0) {
    return true;
  } else {
    return false;
  }
}

/**
 * Is data Empty
 * @param data
 */
export const isEmpty = data => {
  if (!data || (data && !data.length)) {
    return true;
  } else {
    return false;
  }
};

/**
 * Is Any String in Array Exist In Array
 * @param array1
 * @param array2
 */
export function isAnyStringInArrayExistInArray(array1, array2) {
  if (isEmpty(array1) || isEmpty(array2)) {
    return false;
  }

  array1.forEach(string1 => {
    if (isStringExistInArray(array2, string1)) {
      return true;
    }
  });

  return false;
}

/**
 * Is Any String Exist In Array
 * @param array
 * @param string
 */
export function isStringExistInArray(array, string) {
  if (isEmpty(array)) {
    return false;
  }
  let result = false;
  array.forEach(string1 => {
    if (string1.trim() === string.trim()) {
      result = true;
    }
  });
  return result;
}
/**
 * Get Checkbox Option by List
 * @param list
 */
export function getCheckboxOptions(list) {
  const options = [];
  if (list && list.length) {
    list.forEach(option => {
      options.push(option.name);
    });
  }

  return options;
}

/**
 * Compare Array with Array if existing it will Remove otherwise Add
 * @param array
 * @param array1
 */
export function compareArrayInArray(array, array1) {
  array1.forEach(value => {
    var value1 = array.indexOf(value);
    if (value1 === -1) {
      array.push(value);
    } else {
      array.splice(value1, 1);
    }
  });
  return array;
}

/**
 * Get Concat Name
 *
 * @param firstName
 * @param lastName
 * @returns {*}
 */
export function concatName(firstName, lastName) {
  let name = [];

  if (firstName) {
    name.push(firstName);
  }

  if (lastName) {
    let urlPartnerType = lastName.replace(" ", "-");
    const value = urlPartnerType.toLowerCase();
    const param = `partnertype=${value}`;
    name.push(param);
  }

  if (name) {
    return name.join("?");
  }

  return "";
}

/**
 * Is Integer
 * @param int
 */
export function isInteger(int) {
  if (isNaN(int)) {
    return false;
  } else if (parseInt(int, 10)) {
    return true;
  } else return false;
}
/**
 * Format Date
 *
 * @param date
 * @returns {string|null}
 */
export function formatDate(date) {
  if (!date) {
    return null;
  }

  return moment(date).format("MMM DD, YYYY");
}

/**
 * Is Text Exist in String
 * @param text
 * @param string
 */
export const isTextExistInString = (text, string) => {
  if (!text || !string) {
    return null;
  }
  let isExist = string.includes(text);
  return isExist;
};

/**
 * Is String is Salesforce ID
 * @param text
 * @param string
 */
export const isSalesforceID = searchTerm => {
  if (!searchTerm) {
    return false;
  }
  let isExist = /^[0-9a-zA-Z]{15}([0-9a-zA-Z]{3})?$/.test(searchTerm);
  return isExist;
};

export const randomString = (length = 32) => {
  const possible =
    "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let text = "";
  for (let i = 0; i < length; i++) {
    text += possible.charAt(Math.floor(Math.random() * possible.length));
  }

  return text;
};
