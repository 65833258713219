import React from "react";
import Video from "../lib/Video";
import ReactPlayer from "react-player";

const VideoPlayer = props => {
  let { url, width, height } = props;

  return (
    <div>
      {Video.isValid(url) && (
        <div className="player-wrapper">
          <ReactPlayer
            className="react-player"
            url={url}
            controls={true}
            width={width ? width : "100%"}
            height={height ? height : "100%"}
            style={{ backgroundImage: url }}
          />
        </div>
      )}
    </div>
  );
};
export default VideoPlayer;
