import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// Components
import CountBadge from "../../components/base/CountBadge";

// Inner Components
import NewLead from "./NewLead";
import InProgressLead from "./InProgressLead";
import ConvertedLead from "./ConvertedLead";
import AllLead from "./AllLead";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchList } from "../../actions/table";

import {
  LEADS_PAGE_NEW_TAB,
  LEADS_PAGE_INPROGRESS_TAB,
  LEADS_PAGE_CONVERTED_TAB,
  LEADS_PAGE_ALL_TAB
} from "../../leads/Constants";

// Helper
import {
  getParamsByName,
  isCompanyAdmin,
  isSuperAdmin
} from "../../lib/helper";
import AddLeadModal from "./inner-components/AddLeadModal";

class Leads extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchCount: 0,
      searchParam: "",
      activeTab: LEADS_PAGE_NEW_TAB
    };
  }

  componentDidMount() {
    // Check is logged in user
    this.setActiveTab();
  }

  // Set Active Tab
  setActiveTab = () => {
    const status = getParamsByName("section") || this.state.activeTab;

    this.setState({
      activeTab:
        status === LEADS_PAGE_NEW_TAB
          ? LEADS_PAGE_NEW_TAB
          : status === LEADS_PAGE_INPROGRESS_TAB
          ? LEADS_PAGE_INPROGRESS_TAB
          : status === LEADS_PAGE_CONVERTED_TAB
          ? LEADS_PAGE_CONVERTED_TAB
          : status === LEADS_PAGE_ALL_TAB
          ? LEADS_PAGE_ALL_TAB
          : LEADS_PAGE_NEW_TAB
    });
  };

  // Toggle Tab
  toggle = tab => {
    this.setState({
      activeTab: tab
    });
    this.props.history.push(`/leads?section=${tab}`);
  };

  render() {
    const { activeTab } = this.state;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchStatus = params.get("section");

    const isNew =
      searchStatus === LEADS_PAGE_NEW_TAB || activeTab === LEADS_PAGE_NEW_TAB;
    const isInprogress =
      searchStatus === LEADS_PAGE_INPROGRESS_TAB ||
      activeTab === LEADS_PAGE_INPROGRESS_TAB;
    const isConverted =
      searchStatus === LEADS_PAGE_CONVERTED_TAB ||
      activeTab === LEADS_PAGE_CONVERTED_TAB;
    const isAll =
      searchStatus === LEADS_PAGE_ALL_TAB || activeTab === LEADS_PAGE_ALL_TAB;
    const {
      newLeadsCount,
      inPrpgressCount,
      convertedCount,
      allLeadsCount
    } = this.props;

    return (
      <div className="text-break">
        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId)) && (
          <>
            <AddLeadModal />
          </>
        )}

        <Nav tabs className="admin-tabs mb-0">
          <NavItem>
            <NavLink
              className={classnames({ active: isNew })}
              onClick={() => {
                this.toggle(LEADS_PAGE_NEW_TAB);
              }}
            >
              New
              <CountBadge
                isActive={classnames({
                  active: activeTab === LEADS_PAGE_NEW_TAB
                })}
                count={newLeadsCount}
              />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: isInprogress })}
              onClick={() => {
                this.toggle(LEADS_PAGE_INPROGRESS_TAB);
              }}
            >
              In Progress
              <CountBadge
                isActive={classnames({
                  active: activeTab === LEADS_PAGE_INPROGRESS_TAB
                })}
                count={inPrpgressCount}
              />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: isConverted })}
              onClick={() => {
                this.toggle(LEADS_PAGE_CONVERTED_TAB);
              }}
            >
              Converted
              <CountBadge
                isActive={classnames({
                  active: activeTab === LEADS_PAGE_CONVERTED_TAB
                })}
                count={convertedCount}
              />
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({ active: isAll })}
              onClick={() => {
                this.toggle(LEADS_PAGE_ALL_TAB);
              }}
            >
              All
              <CountBadge
                isActive={classnames({
                  active: activeTab === LEADS_PAGE_ALL_TAB
                })}
                count={allLeadsCount}
              />
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeTab}>
          {/*New Tab*/}
          <TabPane tabId={LEADS_PAGE_NEW_TAB}>
            <NewLead history={this.props.history} />
          </TabPane>

          {/* In Progress Tab */}
          <TabPane tabId={LEADS_PAGE_INPROGRESS_TAB}>
            <InProgressLead history={this.props.history} />
          </TabPane>

          {/* Converted Tab */}
          <TabPane tabId={LEADS_PAGE_CONVERTED_TAB}>
            <ConvertedLead history={this.props.history} />
          </TabPane>

          {/* All Tab */}
          <TabPane tabId={LEADS_PAGE_ALL_TAB}>
            <AllLead history={this.props.history} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { pendingExperts } = state.table;
  const reduxTable = state.table;
  const roleId = state.user ? state.user.roleId : "";

  // Get pending expert count
  // const pendingExpertsCount =
  //   pendingExperts && !pendingExperts.isFetching
  //     ? pendingExperts.totalCount
  //     : 0;

  // Get New Deals count
  const newLeadsCount =
    reduxTable["playbookInquiry"] && !reduxTable["playbookInquiry"].isFetching
      ? reduxTable["playbookInquiry"].totalCount
      : 0;
  // Get Open Deals count
  const inPrpgressCount =
    reduxTable["leadInProgressList"] &&
    !reduxTable["leadInProgressList"].isFetching
      ? reduxTable["leadInProgressList"].totalCount
      : 0;
  // Get closed Deals count
  const convertedCount =
    reduxTable["leadConvertedList"] &&
    !reduxTable["leadConvertedList"].isFetching
      ? reduxTable["leadConvertedList"].totalCount
      : 0;
  // Get closed Deals count
  const allLeadsCount =
    reduxTable["leadAllList"] && !reduxTable["leadAllList"].isFetching
      ? reduxTable["leadAllList"].totalCount
      : 0;
  return {
    newLeadsCount,
    inPrpgressCount,
    convertedCount,
    allLeadsCount,
    roleId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Leads);
