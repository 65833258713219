import React from "react";

const Badge = props => {
  let {
    backgroundColor,
    title,
    className,
    marginLeft,
    marginBottom,
    zindex,
    right,
    position,
    left,
    marginRight
  } = props;
  return (
    <div className={className ? className : ""}>
      <div className="certification">
        <span
          className="badge badge-pill badge-primary"
          style={{
            backgroundColor: backgroundColor ? backgroundColor : "#554BEF",
            marginBottom: marginBottom ? marginBottom : "",
            marginLeft: marginLeft ? marginLeft : left || "",
            marginRight: marginRight ? marginRight : "",
            right: right ? right : "",
            position: position ? position : ""
          }}
        >
          {title}
        </span>
      </div>
    </div>
  );
};

export default Badge;
