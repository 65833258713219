import React, { useState } from "react";
import detectBrowser from "../helpers/detectBrowser";

const Footer = props => {
  const [isMobile] = useState(detectBrowser());
  const { footerContent, mobileFooterContent } = props;

  return (
    <>
      <div
        dangerouslySetInnerHTML={{
          __html: isMobile ? mobileFooterContent : footerContent
        }}
      />
      <></>
    </>
  );
};

Footer.propTypes = {};

export default Footer;
