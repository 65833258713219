import React from "react";
import toast from "../../components/base/Toast";
import moment from "moment";

// Components
import Form from "../../components/base/Form";
import CancelButton from "../../components/base/CancelButton";
import PageTitle from "../../components/base/PageTitle";
import Button from "../../components/base/Button";
import AdminActionNavigation from "../../components/base/AdminActionNavigation";
import PartnerAcceptDealAlert from "../../components/PartnerAcceptDealAlert";
import ProjectSection from "../coSell/tab/ProjectDetailSection";
import AdditionalSection from "../coSell/tab/AdditionalDetailSection";
import ContactSection from "../coSell/tab/ContactDetailsSection";
import CoSell from "../../Constants/Cosell";
// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient, apiClientGet } from "../../apiClient";

// Helper
import {
  toString,
  removeMaskedPhoneNumber,
  isSuperAdmin,
  isCompanyAdmin,
  isPartner,
  getKeyValueByObject,
  isCompanyManager
} from "../../lib/helper";
import {
  DEAL_STATUS_APPROVED,
  DEAL_STATUS_DECLINED,
  DEAL_STATUS_DRAFT,
  DEAL_STATUS_SUBMITTED
} from "../../dealStatus/Constants";

import {
  DEAL_PARTNER_STATUS_PENDING,
  DEAL_PARTNER_STATUS_REQUESTED
} from "../../deal/Constants";

import {
  ALLOW_PARTNER_CHANGE,
  SETTINGS_ENABLE_PARTNERS
} from "../../setting/Constants";

// Page Components
import DealFormFields from "./DealFormFields";
import Page404 from "../Page404";

//Assets
import { ChevronRight } from "../../assets/img/icons";

// Components
import Spinner from "../../components/base/Spinner";
import SideBar from "./inner-components/SideBar";

// Constant
import {
  AWS_APN_PROGRAM,
  AWS_PRODUCT,
  AWS_COMPETITIVE_TRACKING,
  AWS_DELIVERY_MODEL,
  AWS_INDUSTRY_VERTICAL,
  AWS_SALES_ACTIVITY,
  AWS_SOLUTION_OFFERED,
  AWS_USECASE,
  TAG_TYPE_PRODUCTS
} from "../../tagType/Constants";
import DeclineDealModal from "./inner-components/DeclineDealModal";
import { isBadRequest } from "../../common/http";

import { connect } from "react-redux";
import Toast from "../../components/base/Toast";

class UpdateDealForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      loading: false,
      checkBox: {
        productsInvolvedOptionSelections: []
      },
      selectedProductsInvolved: "",
      closeDate: "",
      dealDetails: {},
      awsDealDetails: {},
      dealRegistered: {},
      avatar: {},
      isLoading: true,
      isParnerLoading: false,
      selectedPartner: "",
      partnerId: "",
      dealId: this.props.match.params.id,
      dealPartnerUser: "",
      isDealUpdate: false,
      tagPlatforms: [],
      declineDealModal: false,
      estimatedEarning: "",
      partnerTierCommissionPercentages: [],
      isPageNotFound: false,
      shareWithAws: "",
      oppType: "",
      oppSource: "",
      apnProgramOption: [],
      useCaseOption: [],
      industryVerticalOption: [],
      salesActivityOption: [],
      solutionOfferedOption: [],
      deliveryModelOption: [],
      awsProductOption: [],
      competitiveTrackingOption: [],
      isOpen: {
        ProjectDetail: false,
        AdditionalDetailTab: false
      },
      selectedCountry: "",
      selectedCountryName: ""
    };
    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  componentDidMount() {
    this._getDealDetails(this.props.match.params.id);
    this.getTagsByTagType(TAG_TYPE_PRODUCTS);
    this._getPartnerTierCommissionPercentages();

    this.getTags();
  }

  // Handle Country
  handleCountryChange = ({ values }) => {
    const selectedOption = values.country && values.country.id;
    const selectedCountryName = values.country && values.country.label;
    this.setState({
      selectedCountry: selectedOption,
      selectedCountryName
    });
  };

  toggleAccordion = section => {
    this.setState(prevState => ({
      isOpen: {
        ...prevState.isOpen,
        [section]: !prevState.isOpen[section]
      }
    }));
  };
  getTags = async () => {
    try {
      const response = await apiClient.get(`${endpoints().coSellAPI}`);
      const results = response.data.data;

      let apnProgramOption = results.find(
        value => value.name == AWS_APN_PROGRAM
      );

      let useCaseOption = results.find(value => value.name == AWS_USECASE);

      let industryVerticalOption = results.find(
        value => value.name == AWS_INDUSTRY_VERTICAL
      );

      let salesActivityOption = results.find(
        value => value.name == AWS_SALES_ACTIVITY
      );

      let solutionOfferedOption = results.find(
        value => value.name == AWS_SOLUTION_OFFERED
      );

      let deliveryModelOption = results.find(
        value => value.name == AWS_DELIVERY_MODEL
      );

      let awsProductOption = results.find(value => value.name == AWS_PRODUCT);

      let competitiveTrackingOption = results.find(
        value => value.name == AWS_COMPETITIVE_TRACKING
      );
      this.setState({
        apnProgramOption: apnProgramOption.tagList,
        useCaseOption: useCaseOption.tagList,
        industryVerticalOption: industryVerticalOption.tagList,
        salesActivityOption: salesActivityOption.tagList,
        solutionOfferedOption: solutionOfferedOption.tagList,
        deliveryModelOption: deliveryModelOption.tagList,
        awsProductOption: awsProductOption.tagList,
        competitiveTrackingOption: competitiveTrackingOption.tagList
      });
    } catch (err) {
      console.log(err);
    }
  };
  // Get Partner Request Details
  _getDealDetails = id => {
    apiClientGet(`${endpoints().dealsAPI}/${id}`, (errMessage, response) => {
      if (errMessage) {
        this.setState({
          isPageNotFound: true,
          isLoading: false
        });
      } else {
        this.setState({
          isLoading: true
        });
        const data = response && response.data && response.data.data;
        const awdDeal = response && response.data && response.data.awdDeal;

        let productsInvolved = [];
        productsInvolved =
          data && data.products_involved && data.products_involved.split(",");
        this.setState({
          dealDetails: data,
          awsDealDetails: awdDeal,
          dealPartnerUser: data.dealPartnerUser || "",
          dealRegistered: data.user,
          avatarUrl: data.avatarUrl,
          partnerId: data.partnerId,
          selectedPartner: data.partnerId,
          closeDate: data.closeDate ? new Date(data.closeDate) : "",
          checkBox: {
            productsInvolvedOptionSelections: productsInvolved
          },
          isLoading: false,
          selectedProductsInvolved: productsInvolved
        });
        data.country && this.getCountryDetails(data.country);
      }
    });
  };

  //Get Partner Tier Commission Percentages
  _getPartnerTierCommissionPercentages = () => {
    return apiClient
      .get(`${endpoints().partnerTierAPI}/search`)
      .then(response => {
        if (response && response.data && response.data.data) {
          this.setState({
            partnerTierCommissionPercentages: response.data.data
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };

  // Handle Estimated Earning
  handleEstimatedEariningChange = e => {
    this.setState({ estimatedEarning: e.target.value ? e.target.value : "" });
    this.updateEstimatedEarnings({
      estimatedEarning: e.target.value ? e.target.value : ""
    });
  };

  // Update Estimated Earnings
  async updateEstimatedEarnings(data) {
    try {
      const response = await apiClient.put(
        `${endpoints().dealsAPI}/updateDeal/${this.props.match.params.id}`,
        data
      );
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        // toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  }

  // Update Deals
  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }
    // Save Deal data form
    this.updateDeal(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define deal constants
    const accountName = values.accountName;

    if (!accountName) {
      success = false;
    }

    return success;
  }

  // Set Date
  setDate = (date, stateProperty) => {
    this.setState({ [stateProperty]: date });
  };

  // Toggle Modal
  toggleModal = () => {
    const { declineDealModal } = this.state;
    this.setState({ declineDealModal: !declineDealModal });
  };

  // Update Deal By Status
  updateDealByStatus = status => {
    let data = {};
    if (status === DEAL_STATUS_APPROVED) {
      data.status = DEAL_STATUS_APPROVED;
    } else if (status === DEAL_STATUS_DECLINED) {
      data.status = DEAL_STATUS_DECLINED;
    }

    return apiClient
      .put(`${endpoints().dealsAPI}/status/${this.props.match.params.id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this.setState({ isDealUpdate: true, isLoading: true });
          toast.success(successMessage);
          this._getDealDetails(this.props.match.params.id);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };

  // Handle change playbook extras
  handleChangeProductsInvolved = e => {
    const selectedExtras = e.target.value;
    let newSelectionArray;

    if (
      this.state.checkBox.productsInvolvedOptionSelections.indexOf(
        selectedExtras
      ) > -1
    ) {
      newSelectionArray = this.state.checkBox.productsInvolvedOptionSelections.filter(
        s => s !== selectedExtras
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.productsInvolvedOptionSelections,
        selectedExtras
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        productsInvolvedOptionSelections: newSelectionArray
      }
    }));

    this.setState({ selectedProductsInvolved: newSelectionArray });
  };

  // To Array
  _toArray(values) {
    const {
      selectedProductsInvolved,
      closeDate,
      shareWithAws,
      oppType
    } = this.state;
    values.partnerId = this.state.dealPartnerUser.partner
      ? this.state.dealPartnerUser.partner.id
      : values.partnerId;
    values.accountName = toString(values.accountName);
    values.firstName = toString(values.firstName);
    values.lastName = toString(values.lastName);
    values.title = toString(values.title);
    values.email = toString(values.email);
    values.phone = values.phone ? removeMaskedPhoneNumber(values.phone) : "";
    values.city = toString(values.city);
    values.state = values.state
      ? typeof values.state === "object"
        ? values.state.value
        : values.state.toString()
      : null;
    values.country = values.country
      ? typeof values.country === "object"
        ? values.country.label
        : values.country.toString()
      : null;
    values.postalCode = toString(values.postalCode);
    values.selectedStage = toString(
      values.stage ? values.stage.value : values.stage
    );
    values.amount = toString(values.amount);
    values.salesRep = toString(values.salesRep);
    values.notes = toString(values.notes);
    values.customer_duns = toString(values.customer_duns);
    values.industry_vertical = toString(
      values && values.industry_vertical && values.industry_vertical.value
        ? values.industry_vertical.value
        : values.industry_vertical
    );

    values.productsInvolved =
      selectedProductsInvolved && selectedProductsInvolved.length > 0
        ? selectedProductsInvolved.join(",")
        : selectedProductsInvolved;
    values.closeDate = closeDate ? moment(closeDate).format("YYYY-MM-DD") : "";
    if (shareWithAws !== "") {
      values.shareWithAws = toString(this.state.shareWithAws);
    }
    if (oppType !== "") {
      values.opportunityType = toString(this.state.oppType);
    }

    return values;
  }

  // Customer update API Call
  async updateDeal(data) {
    try {
      const response = await apiClient.put(
        `${endpoints().dealsAPI}/${this.props.match.params.id}`,
        data
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      this._getDealDetails(this.props.match.params.id);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  }

  // Update Partner Deal Status
  updatePartnerDealStatus = (status, dealId) => {
    return apiClient
      .put(
        `${
          endpoints().dealPartnerAPI
        }/updateStatus?dealId=${dealId}&status=${status}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);

          this.setState({ isDealUpdate: true, isLoading: true });
          this._getDealDetails(this.props.match.params.id);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };

  // Handle Cancel Button
  handleCancelButton = () => {
    this.props.history.goBack();
  };

  // Get Tags by tag type
  getTagsByTagType = tagType => {
    return apiClient
      .get(`${endpoints().playbookAPI}/tags/list?tagType=${tagType}`)
      .then(response => {
        const tags = response.data.data;

        if (tags && tags.length > 0) {
          // Check the tag type products
          if (tagType === TAG_TYPE_PRODUCTS) {
            const tagPlatforms = [];
            tags.forEach(tag => {
              tagPlatforms.push({
                option: tag.name
              });
            });
            this.setState({
              productsInvolvedOptions: tagPlatforms
            });
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get Country Details
  getCountryDetails = countryName => {
    return apiClient
      .get(`${endpoints().countryAPI}/${countryName}`)
      .then(response => {
        const data = response.data;
        this.setState({
          selectedCountry: data.id
        });
      });
  };

  handleAwsShareChange = value => {
    this.setState({
      shareWithAws: value
    });
  };

  handleOppTypeChange = value => {
    this.setState({
      oppType: value
    });
  };

  handleOppSourceChange = value => {
    this.setState({
      oppSource: value
    });
  };

  handleSubmit = async () => {
    try {
      const data = new FormData();
      data.append("status", DEAL_STATUS_SUBMITTED);
      const response = await apiClient.put(
        `${endpoints().dealsAPI}/status/${this.props.match.params.id}`,
        data
      );

      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        Toast.success(successMessage);
        this._getDealDetails(this.props.match.params.id);
      }
    } catch (err) {
      console.log(err);
    }
  };

  updateUpdateAwsDeal = data => {
    apiClient
      .put(
        `${endpoints().awsDealAPI}/${this.state.awsDealDetails &&
          this.state.awsDealDetails.id}`,
        data
      )
      .then(response => {
        let successMessage;

        if (response && response.data) {
          successMessage = response.data.message;
        }
        Toast.success(successMessage);

        this._getDealDetails(this.props.match.params.id);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
  handleContactDetailUpdate = values => {
    try {
      const data = new FormData();
      data.append(
        "primary_contact_first_name",
        values.primary_contact_first_name
      );
      data.append("primary_contact_title", values.primary_contact_title);
      data.append(
        "primary_contact_last_name",
        values.primary_contact_last_name
      );
      data.append("primary_contact_email", values.primary_contact_email);
      data.append("primary_contact_phone", values.primary_contact_phone);
      data.append("dealId", this.props.match.params.id);
      this.updateUpdateAwsDeal(data);
    } catch (err) {
      console.log(err);
    }
  };

  handleAdditionalDetailUpdate = values => {
    try {
      const data = new FormData();
      data.append("additional_comments", values.additional_comments);
      data.append("aws_account_id", values.aws_account_id);
      data.append(
        "partner_crm_unique_identifier",
        values.partner_crm_unique_identifier
      );
      data.append(
        "competitive_tracking",
        values &&
          values.competitive_tracking &&
          values.competitive_tracking.value
          ? values.competitive_tracking.value
          : ""
      );
      data.append("dealId", this.props.match.params.id);

      this.updateUpdateAwsDeal(data);
    } catch (err) {
      console.log(err);
    }
  };

  handleProjectUpdate = async values => {
    try {
      const data = new FormData();

      let apn_programs =
        values.apn_programs && values.apn_programs.length > 0
          ? values.apn_programs.map(value => value.value)
          : "";

      data.append("apn_programs", apn_programs);

      let aws_products =
        values.aws_products && values.aws_products.length > 0
          ? values.aws_products.map(value => value.value)
          : "";

      data.append("aws_products", aws_products);

      let delivery_model =
        values.delivery_model && values.delivery_model.length > 0
          ? values.delivery_model.map(value => value.value)
          : "";

      data.append("delivery_model", delivery_model);

      let sales_activities =
        values.sales_activities && values.sales_activities.length > 0
          ? values.sales_activities.map(value => value.value)
          : "";

      data.append("sales_activities", sales_activities);

      data.append(
        "solutions_offered",
        values && values.solutions_offered && values.solutions_offered.value
          ? values.solutions_offered.value
          : ""
      );

      data.append(
        "use_case",
        values && values.use_case && values.use_case.value
          ? values.use_case.value
          : ""
      );

      data.append(
        "customer_business_problem",
        values.customer_business_problem
      );

      data.append(
        "estimated_aws_monthly_recurring_revenue",
        values.estimated_aws_monthly_recurring_revenue
      );

      data.append("next_step", values.next_step);

      data.append(
        "share_with_aws",
        this.state.shareWithAws !== "" &&
          this.state.shareWithAws !== "undefined"
          ? this.state.shareWithAws
          : this.state.awsDealDetails &&
              this.state.awsDealDetails.share_with_aws
      );

      data.append(
        "opportunity_type",
        this.state.oppType !== "" && this.state.oppType !== undefined
          ? this.state.oppType
          : this.state.awsDealDetails &&
              this.state.awsDealDetails.opportunity_type
      );

      data.append(
        "opportunity_source",
        this.state.oppSource !== "" && this.state.oppSource !== "undefined"
          ? this.state.oppSource
          : this.state.awsDealDetails &&
              this.state.awsDealDetails.opportunity_source
      );
      data.append("other_solution_offered", values.other_solution_offered);

      data.append("partner_project_title", values.partner_project_title);

      data.append("target_close_date", values.target_close_date);
      data.append("dealId", this.props.match.params.id);

      this.updateUpdateAwsDeal(data);
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      dealDetails,
      isLoading,
      closeDate,
      dealRegistered,
      avatarUrl,
      partnerId,
      dealPartnerUser,
      productsInvolvedOptions,
      declineDealModal,
      partnerTierCommissionPercentages,
      selectedCountry,
      selectedCountryName,
      isPageNotFound,
      isOpen
    } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    if (isPageNotFound) return <Page404 />;
    const {
      accountName,
      firstName,
      lastName,
      amount,
      city,
      email,
      id,
      notes,
      phone,
      postalCode,
      salesRep,
      title,
      estimatedEarning,
      address,
      opportunity_type,
      website,
      industry_vertical,
      customer_duns
    } = dealDetails;

    let { status, stage_tag, country, state } = dealDetails;
    const stage = {
      value: stage_tag ? stage_tag.id : "",
      label: stage_tag ? stage_tag.name : ""
    };

    state = {
      value: state ? state : null,
      label: state ? state : null
    };

    country = {
      value: selectedCountryName
        ? selectedCountryName
        : country
        ? country
        : null,
      label: selectedCountryName
        ? selectedCountryName
        : country
        ? country
        : null
    };

    let cosellWithAwsObj = CoSell.cosellWithAwsOption.find(
      item => item.value == this.state.shareWithAws
    );

    let oppTypeObj = CoSell.opportunityTypeOption.find(
      item => item.value == this.state.oppType
    );

    const initialValues = {
      accountName,
      firstName,
      lastName,
      city,
      state,
      postalCode,
      phone,
      country,
      stage,
      amount,
      notes,
      email,
      title,
      salesRep,
      estimatedEarning,
      address,
      website,
      customer_duns,
      industry_vertical: this.state.industryVerticalOption.find(
        value => value.value == industry_vertical
      )
    };
    const settings = this.props.settings ? this.props.settings : "";

    const enablePartners = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS) &&
        getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS) === "true" &&
        true
      : false;

    const allowPartnerSelect = settings
      ? getKeyValueByObject(settings, ALLOW_PARTNER_CHANGE) &&
        getKeyValueByObject(settings, ALLOW_PARTNER_CHANGE)
      : "";

    return (
      <>
        <React.Fragment>
          <Form
            initialValues={initialValues}
            enableReinitialize
            enableReferrals={true}
            onSubmit={values => {
              this._submit(values);
            }}
          >
            <AdminActionNavigation>
              <div className="d-flex align-items-center mb-3">
                <a
                  href="javascript:void(0)"
                  className="cursor-pointer"
                  onClick={() => {
                    this.props.history.push("/deals");
                  }}
                >
                  Deals
                </a>
                <span
                  className="text-dark d-inline-flex align-items-center"
                  style={{ width: "15px" }}
                >
                  <ChevronRight />
                </span>
                <span
                  onClick={e => e.preventDefault()}
                  className={[
                    "text-dark",
                    "font-weight-bold",
                    "d-inline-flex",
                    "align-items-center",
                    "project-name",
                    "w-320"
                  ].join(" ")}
                >
                  <span className="ellipsis">{accountName}</span>
                </span>
              </div>
              <CancelButton
                className="ml-auto mr-1"
                onClick={this.handleCancelButton}
              />
              {(isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)) &&
                status.value === DEAL_PARTNER_STATUS_REQUESTED && (
                  <button
                    type="button"
                    className="m-1 float- btn btn-danger"
                    onClick={() => this.toggleModal()}
                  >
                    Decline Deal
                  </button>
                )}
              {(isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)) &&
                status.value === DEAL_PARTNER_STATUS_REQUESTED && (
                  <button
                    type="button"
                    className="m-1 float- btn btn-success"
                    onClick={() =>
                      this.updateDealByStatus(DEAL_STATUS_APPROVED)
                    }
                  >
                    Approve Deal
                  </button>
                )}
              <Button
                label="Submit"
                type="button"
                className="btn btn-primary"
                onClick={this.handleSubmit}
              />
            </AdminActionNavigation>

            <PageTitle label="Edit Deal" />
            {isPartner(this.props.roleId) &&
              dealPartnerUser &&
              dealPartnerUser.status === DEAL_PARTNER_STATUS_PENDING && (
                <PartnerAcceptDealAlert
                  updatePartnerDealStatus={this.updatePartnerDealStatus}
                  dealDetails={dealDetails}
                />
              )}
            <div className="card border-0">
              <div className="project-details-body">
                <div className="details-body-main">
                  <div className="card-body">
                    <DealFormFields
                      productsInvolvedOptions={productsInvolvedOptions}
                      productsInvolvedOptionSelections={
                        this.state.checkBox.productsInvolvedOptionSelections
                      }
                      onChange={this.handleChangeProductsInvolved.bind(this)}
                      handleCountryChange={this.handleCountryChange}
                      showStatusDropdown={!isPartner(this.props.roleId)}
                      setDate={this.setDate.bind(this)}
                      closeDate={closeDate}
                      partnerId={partnerId}
                      id={id}
                      dealRegistered={dealRegistered}
                      avatarUrl={avatarUrl}
                      defaultCountry={initialValues.country.label}
                      selectedCountry={selectedCountry}
                      handleAwsShareChange={this.handleAwsShareChange}
                      shareAws={cosellWithAwsObj}
                      handleOppTypeChange={this.handleOppTypeChange}
                      oppType={oppTypeObj}
                      dealDetails={this.state.awsDealDetails}
                      awsProductOption={this.state.awsProductOption}
                      deliveryModelOption={this.state.deliveryModelOption}
                      apnProgramOption={this.state.apnProgramOption}
                      salesActivityOption={this.state.salesActivityOption}
                      useCaseOption={this.state.useCaseOption}
                      solutionOfferedOption={this.state.solutionOfferedOption}
                      industryVerticalOption={this.state.industryVerticalOption}
                      competitiveTrackingOption={
                        this.state.competitiveTrackingOption
                      }
                      details={dealDetails}
                      handleContactDetailUpdate={this.handleContactDetailUpdate}
                      selectedCountryName={selectedCountryName}
                    />
                  </div>
                  <div className="d-flex justify-content-end">
                    <Button
                      label="Save"
                      type="submit"
                      className="btn btn-primary"
                    />
                  </div>
                </div>
                <SideBar
                  dealDetails={dealDetails}
                  enablePartners={enablePartners}
                  dealId={this.state.dealId}
                  isDealUpdate={this.state.isDealUpdate}
                  handleEstimatedEariningChange={
                    this.handleEstimatedEariningChange
                  }
                  estimatedEarning={this.state.estimatedEarning}
                  partnerTierCommissionPercentages={
                    partnerTierCommissionPercentages
                  }
                  allowPartnerSelect={allowPartnerSelect}
                />
              </div>
            </div>
          </Form>

          {this.state.awsDealDetails && this.state.awsDealDetails.isAwsPartner && (
            <div className="accordion-section p-2 bg-white">
              <div
                className="accordion-header border cursor-pointer rounded"
                onClick={() => this.toggleAccordion("ProjectDetail")}
              >
                <div className="d-flex justify-content-between bg-light-grey w-100  p-2">
                  Project Detail
                  <span
                    className={
                      isOpen.ProjectDetail
                        ? "fa fa-chevron-up "
                        : "fa fa-chevron-down "
                    }
                  ></span>
                </div>
              </div>
              {isOpen.ProjectDetail && (
                <div className="accordion-content">
                  <ProjectSection
                    detail={this.state.awsDealDetails}
                    enableSaveButton
                    showButton={
                      dealDetails && dealDetails.status == DEAL_STATUS_DRAFT
                        ? false
                        : true
                    }
                    readOnly={
                      dealDetails && dealDetails.status == DEAL_STATUS_DRAFT
                        ? false
                        : true
                    }
                    awsProductOption={this.state.awsProductOption}
                    deliveryModelOption={this.state.deliveryModelOption}
                    apnProgramOption={this.state.apnProgramOption}
                    salesActivityOption={this.state.salesActivityOption}
                    useCaseOption={this.state.useCaseOption}
                    solutionOfferedOption={this.state.solutionOfferedOption}
                    handleUpdate={this.handleProjectUpdate}
                    handleOppTypeChange={this.handleOppTypeChange}
                    handleAwsShareChange={this.handleAwsShareChange}
                    handleOppSourceChange={this.handleOppSourceChange}
                  />
                </div>
              )}

              <div
                className="accordion-header border cursor-pointer rounded mt-2"
                onClick={() => this.toggleAccordion("AdditionalDetailTab")}
              >
                <div className="d-flex justify-content-between w-100 bg-light-grey p-2">
                  Additional Detail
                  <span
                    className={
                      isOpen.ProjectDetail
                        ? "fa fa-chevron-up "
                        : "fa fa-chevron-down "
                    }
                  ></span>
                </div>
              </div>

              {isOpen.AdditionalDetailTab && (
                <div className="accordion-content">
                  <AdditionalSection
                    detail={this.state.awsDealDetails}
                    handleUpdate={this.handleAdditionalDetailUpdate}
                    enableSaveButton
                    showButton={
                      dealDetails && dealDetails.status == DEAL_STATUS_DRAFT
                        ? false
                        : true
                    }
                    readOnly={
                      dealDetails && dealDetails.status == DEAL_STATUS_DRAFT
                        ? false
                        : true
                    }
                    competitiveTrackingOption={
                      this.state.competitiveTrackingOption
                    }
                  />
                </div>
              )}
              <div
                className="accordion-header border cursor-pointer rounded mt-2"
                onClick={() => this.toggleAccordion("ContactDetailTab")}
              >
                <div className="d-flex justify-content-between bg-light-grey w-100  p-2">
                  Contact Detail
                  <span
                    className={
                      isOpen.ContactDetailTab
                        ? "fa fa-chevron-up "
                        : "fa fa-chevron-down "
                    }
                  ></span>
                </div>
              </div>
              {isOpen.ContactDetailTab && (
                <div className="accordion-content">
                  <ContactSection
                    detail={this.state.awsDealDetails}
                    handleUpdate={this.handleContactDetailUpdate}
                    enableSaveButton
                    showButton={
                      dealDetails && dealDetails.status == DEAL_STATUS_DRAFT
                        ? false
                        : true
                    }
                    readOnly={
                      dealDetails && dealDetails.status == DEAL_STATUS_DRAFT
                        ? false
                        : true
                    }
                  />
                </div>
              )}
            </div>
          )}

          {/* Declined Deal Modal */}
          {declineDealModal && (
            <DeclineDealModal
              modal={declineDealModal}
              setModal={this.toggleModal}
              dealId={this.props.match.params.id}
              getDealDetails={() => {
                this.setState({ isDealUpdate: true, isLoading: true });
                this._getDealDetails(this.props.match.params.id);
              }}
            />
          )}
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(UpdateDealForm);
