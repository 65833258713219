import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import Form from "../../../components/base/Form";
import Select from "../../../components/base/Select";
import CancelButton from "../../../components/base/CancelButton";
import Button from "../../../components/base/Button";
import Text from "../../../components/base/Text";
import PartnerType from "../../../components/PartnerType";

import {
  isCompanyAdmin,
  isCompanyManager,
  isSuperAdmin
} from "../../../lib/helper";

import {
  applicationTaskTypeOptions,
  TASK_TYPE_FILL_OUT_FORM,
  TASK_TYPE_VISIT_URL
} from "../Constant";

import { connect } from "react-redux";

class TaskAdd extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectType: ""
    };
  }

  closeAddTaskModal = () => {
    this.props.toggle();
    this.setState({ selectType: "" });
  };

  handleTypeChange = selectedTypeObject => {
    if (selectedTypeObject && selectedTypeObject.value) {
      this.setState({ selectType: selectedTypeObject.value });
    }
  };

  _submit = values => {
    let partnerType = [];
    const data = new FormData();
    data.append("name", values.name || "");
    values &&
      values.partnerType &&
      values.partnerType.forEach(data => {
        if (data) {
          partnerType.push({
            partnerType: data.value
          });
        }
      });

    data.append("partnerType", JSON.stringify(partnerType) || "");
    data.append("order", this.props.index || 0);
    data.append(
      "selectedFormId",
      (values.selectedForm &&
        values.selectedForm.value &&
        values.selectedForm.value) ||
        ""
    );
    data.append("type", (values.type && values.type.label) || "");
    data.append("visitUrl", (values && values.visitUrl) || "");

    this.props.addNewTask(data);
    this.setState({ selectType: "" });
  };

  render() {
    const initialValues = {
      name: "",
      description: "",
      type: "",
      visitUrl: "",
      selectedForm: ""
    };

    const { isOpen, formList } = this.props;
    const { selectType } = this.state;
    return (
      <Modal
        isOpen={isOpen}
        toggle={this.closeAddTaskModal}
        backdrop="static"
        className={["edit-task-modal"].join(" ")}
      >
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            this._submit(values);
          }}
        >
          <ModalHeader toggle={this.closeAddTaskModal}>
            <h4 className={["font-weight-bold"].join(" ")}>
              Add Application Task
            </h4>
          </ModalHeader>
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="form-wrapper">
              <div>
                <Text
                  id={`name`}
                  name={`name`}
                  label="Task Name"
                  placeholder="Name"
                  required
                  error=""
                />
              </div>
              <div className="field-wrapper">
                <PartnerType
                  isMulti
                  name="partnerType"
                  label="Partner Type"
                  placeholder="Select type"
                  isSearchable={true}
                  isDisabled={
                    !isSuperAdmin(this.props.roleId) &&
                    !isCompanyAdmin(this.props.roleId) &&
                    !isCompanyManager(this.props.roleId)
                      ? true
                      : false
                  }
                />
              </div>
              <div>
                <Select
                  id={`type`}
                  label="Type of Task"
                  name="type"
                  placeholder="Select"
                  options={applicationTaskTypeOptions}
                  isSearchable={true}
                  required
                  handleChange={e => this.handleTypeChange(e)}
                />
              </div>
              {selectType === TASK_TYPE_FILL_OUT_FORM && (
                <>
                  <div className="w-100 border-bottom mt-4 mb-3"></div>
                  <div className="form-wrapper mt-4">
                    <div className="flex-column">
                      <Select
                        label="Form"
                        name="selectedForm"
                        placeholder="Select"
                        options={formList}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                </>
              )}
              {selectType === TASK_TYPE_VISIT_URL && (
                <>
                  <div className="w-100 border-bottom mt-4 mb-3"></div>
                  <div className="form-wrapper mt-4">
                    <div className="flex-column">
                      <Text
                        id={`visitUrl`}
                        name="visitUrl"
                        label="Visit URL"
                        placeholder="Visit URL"
                      />
                    </div>
                  </div>
                </>
              )}
            </div>
          </ModalBody>
          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <CancelButton onClick={this.closeAddTaskModal} />
              <Button
                label="Add Task"
                type="submit"
                className="btn btn-primary"
              />
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(TaskAdd);
