import React, { useState, useEffect } from "react";

import SaveButton from "../../components/base/SaveButton";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// Components
import Form from "../../components/base/Form";
import Text from "../../components/Text";
import Select from "../../components/base/Select";

import PartnerType from "../../components/PartnerType";

import { getParamsByName } from "../../lib/helper";

import MultiselectCreatable from "../../components/base/MultiselectCreatable";

const AddNewMappingModal = props => {
  const [selectedHubspotField, setSelectedHubspotField] = useState("");

  const {
    openAddModal,
    closeAddModal,
    hubspotObjectNameList,
    addfunction,
    handleChange,
    handleObjectNameChange,
    selectedHubspotObjectName,
    AccountObjectList,
    ReferralObjectList,
    customFieldList,
    accountFieldList,
    selectedObjectName,
    syncDirection,
    HubSpotFieldList,
    contactFieldList,
    contactObjectList
  } = props;
  const hubspotFieldChanges = e => {
    setSelectedHubspotField(e && e.values && e.values.hubspotFieldName);
  };

  let CompanyObjectFieldList =
    HubSpotFieldList && HubSpotFieldList.companyObjectFields
      ? HubSpotFieldList.companyObjectFields
      : [];

  let DealObjectFieldList =
    HubSpotFieldList && HubSpotFieldList.dealObjectFields
      ? HubSpotFieldList.dealObjectFields
      : [];

  let ContactObjectFieldList =
    HubSpotFieldList && HubSpotFieldList.contactObjectFields
      ? HubSpotFieldList.contactObjectFields
      : [];

  const searchItem = getParamsByName("search") || "";

  return (
    <div>
      <Modal
        isOpen={openAddModal}
        toggle={() => {
          closeAddModal(prevState => !prevState);
          setSelectedHubspotField("");
        }}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader
          toggle={() => {
            closeAddModal(prevState => !prevState);
            setSelectedHubspotField("");
          }}
        >
          <p className="text-center font-weight-bold mb-3">
            Add HubSpot Field Mapping
          </p>
        </ModalHeader>

        <Form
          initialValues={{
            objectName: "",
            hubspotObjectName: "",
            fieldName: "",
            hubspotFieldName: "",
            defaultValue: "",
            partnerType: "",
            syncDirection: ""
          }}
          onSubmit={values => {
            values.objectName =
              values && values.objectName ? values.objectName.value : "";
            values.fieldName =
              values && values.fieldName ? values.fieldName.value : "";
            values.hubspotObjectName =
              values && values.hubspotObjectName
                ? values.hubspotObjectName.value
                : "";
            values.defaultValue =
              selectedHubspotField &&
              selectedHubspotField.type === "enumeration"
                ? values &&
                  values.ObjectDefaultValue &&
                  values.ObjectDefaultValue.value
                : values && values.TextDefaultValue;
            values.syncDirection =
              values && values.syncDirection ? values.syncDirection.value : 0;
            addfunction.actions.addHubspotFieldMapping(values, {
              search: searchItem
            });
            closeAddModal(false);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div className="field-wrapper mb-3">
                <Select
                  name="hubspotObjectName"
                  label="Hubspot Object Name"
                  placeholder="Select Hubspot Object Name"
                  options={hubspotObjectNameList()}
                  onInputChange={e => handleChange(e)}
                  required
                  isClearable={true}
                />
              </div>
              <div className="field-wrapper mb-3">
                <Select
                  name="hubspotFieldName"
                  label="Hubspot Field Name"
                  placeholder="Select Hubspot Field Name"
                  onInputChange={e => hubspotFieldChanges(e)}
                  options={
                    selectedHubspotObjectName === "Company (Partner)" ||
                    selectedHubspotObjectName === "Company (Prospect)"
                      ? CompanyObjectFieldList
                      : selectedHubspotObjectName === "Deal"
                      ? DealObjectFieldList
                      : selectedHubspotObjectName === "Contact (Partner)" ||
                        selectedHubspotObjectName === "Contact (Prospect)"
                      ? ContactObjectFieldList
                      : []
                  }
                  required
                  isClearable={true}
                  isSearchable={true}
                />
              </div>

              <h6>Object</h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="objectName"
                  placeholder="Select Object Name"
                  onInputChange={e => handleObjectNameChange(e)}
                  options={
                    selectedHubspotObjectName === "Company (Partner)" ||
                    selectedHubspotObjectName === "Company (Prospect)"
                      ? AccountObjectList()
                      : selectedHubspotObjectName === "Deal"
                      ? ReferralObjectList()
                      : selectedHubspotObjectName === "Contact (Partner)" ||
                        selectedHubspotObjectName === "Contact (Prospect)"
                      ? contactObjectList()
                      : []
                  }
                  isClearable={true}
                  isSearchable={true}
                />
              </div>

              <h6>Field</h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="fieldName"
                  placeholder="Select Field Name"
                  options={
                    selectedObjectName === "Account (Partner)" ||
                    selectedObjectName === "Account (Prospect)"
                      ? accountFieldList()
                      : selectedObjectName === "Referral"
                      ? customFieldList
                      : selectedHubspotObjectName === "Contact (Partner)" ||
                        selectedHubspotObjectName === "Contact (Prospect)"
                      ? contactFieldList()
                      : []
                  }
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
              {selectedHubspotField &&
              selectedHubspotField.type === "enumeration" ? (
                <>
                  {" "}
                  <h6>Default Value</h6>
                  <div className="field-wrapper mb-3">
                    <MultiselectCreatable
                      isSingleSelect={true}
                      name="ObjectDefaultValue"
                      placeholder="Select Default Value"
                      options={selectedHubspotField.picklistValues}
                      isClearable={true}
                      isSearchable={true}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <Text
                    name="TextDefaultValue"
                    label="Default Value"
                    placeholder="Enter Default Value"
                  />
                </div>
              )}
              <div className="field-wrapper">
                <PartnerType
                  name="partnerType"
                  label="Partner Type"
                  placeholder="Select Partner type"
                  isSearchable={true}
                  isClearable={true}
                />
              </div>

              <div className="field-wrapper mb-5">
                <Select
                  name="syncDirection"
                  label="Sync Direction"
                  placeholder="Select Sync Direction"
                  options={syncDirection()}
                  isClearable={true}
                  isSearchable={true}
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <SaveButton />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default AddNewMappingModal;
