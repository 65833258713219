import React from "react";
import { Link } from "react-router-dom";

//Components
import PageTitle from "./PageTitle";
import { Table } from "reactstrap";

class List extends React.Component {
  render() {
    const { heading, data, header, id, hidePadding, className } = this.props;

    return (
      <>
        {heading && <PageTitle label={heading} />}
        <div className="redux-table">
          <Table
            hover
            responsive
            className={`table-outline text-break ${hidePadding} ? m-0 p-0: ""`}
          >
            <thead className="thead-dark table-bordered">
              <tr>
                {header &&
                  header.length > 0 &&
                  header.map(headerList => {
                    let { name } = headerList;
                    return <th>{name}</th>;
                  })}
              </tr>
            </thead>
            <tbody>
              {data &&
                data.length > 0 &&
                data.map(dataList => {
                  let { title, link, description, category } = dataList;
                  return (
                    <tr>
                      <td className={`${hidePadding} ? py-0 mt-.5 : ""`}>
                        <Link
                          id={id}
                          to={link}
                          className={
                            className ? className : "text-link text-underline"
                          }
                        >
                          {title}
                        </Link>
                        <p className={className ? className : ""}>
                          {description}
                        </p>
                      </td>
                      <td>{category ? <span>{category}</span> : ""}</td>
                    </tr>
                  );
                })}
            </tbody>
          </Table>
        </div>
      </>
    );
  }
}

export default List;
