import React, { useEffect, useState } from "react";
// components
import PageSearch from "../components/base/PageSearch";
import ProgramCard from "./PartnerPogramCard";

// Api Client
import { apiClient } from "../apiClient";

// Configs
import { endpoints } from "../configs";
import { isNotEmpty } from "../common/utils";
import { isBadRequest } from "../common/http";
import Spinner from "../components/base/Spinner";
import NoRecordsFound from "../components/base/NoRecordsFound";

const PartnerPrograms = props => {
  const {
    history,
    actions,
    toggle,
    handleStatusChange,
    setPartnerTypeCount,
    partnerTypeTrigger
  } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [partnerList, setPartnerList] = useState([]);
  const [searchParam, setSearchParam] = useState("");

  useEffect(() => {
    getPartnerType();
    getPartnerList();
  }, [partnerTypeTrigger]);

  // partner type page api call
  const getPartnerType = () => {
    setIsLoading(true);
    apiClient
      .get(`${endpoints().partnerTypeAPI}/search`)
      .then(response => {
        const partnerTypeList = response.data.data;
        setPartnerTypeCount(partnerTypeList.length);
        setIsLoading(false);
        let successMessage;
        if (response && response.data) {
          setIsLoading(true);
          getPartnerList();
          successMessage = response.data.message;
        }
        setIsLoading(false);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          setIsLoading(false);
        }
      });
  };

  const getPartnerList = async searchTerm => {
    let terms = searchTerm ? searchTerm : "";
    const response = await apiClient.get(
      `${endpoints().partnerAPI}/partnerType/type/?search=${terms}`
    );
    if (response) {
      let res = response && response.data;
      setPartnerList(res);
      setIsLoading(false);
    }
  };

  // Search Query string
  function pushQueryToUrl(searchParam) {
    const params = [];

    if (searchParam) {
      params.push(`search=${searchParam}`);
    }

    history.push(`partners?&section=partner-programs&${params.join("&")}`);
  }

  const onChange = e => {
    setSearchParam(e.target.value);
    getPartnerList(e.target.value);
    pushQueryToUrl(e.target.value);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <>
      <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column">
        {/* Search programs */}
        <PageSearch
          classnames="page-search"
          placeholder={`Search`}
          onChange={e => onChange(e)}
          value={searchParam}
        />
      </div>
      <div className="project-list">
        {isNotEmpty(partnerList) ? (
          partnerList
            .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
            .map(data => (
              <ProgramCard
                type={data.type}
                partnerTypeId={data.typeId}
                approvedPartners={data.approvedCount}
                pendingPartners={data.pendingCount}
                declinedPartners={data.declinedCount}
                totalPartners={data.totalCount}
                history={history}
                actions={actions}
                toggle={toggle}
                handleStatusChange={handleStatusChange}
                activity={data.partnerActivity}
                referralStatistics={data.referralStatisticsDetail}
              />
            ))
        ) : (
          <NoRecordsFound hideCard />
        )}
      </div>
    </>
  );
};
export default PartnerPrograms;
