import React from "react";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import Avatar from "../base/Avatar";
import Label from "../base/Label";
import DateLabel from "./DateLabel";
import moment from "moment";
import { COLOR_GREY } from "../../common/color";
import { SIZE_SMALLER } from "../../common/size";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
class ActivityTimeline extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <>
        {data && (
          <div
            className="dropdown-wrapper ml-auto"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Avatar
              id={
                data.id
                  ? data.id
                  : data.id
                  ? data.user.first_name
                  : data.userName
              }
              firstName={data.user ? data.user.first_name : data.userName}
              lastName={data.user ? data.user.last_name : ""}
              url={data.user && data.user.avatarUrl ? data.user.avatarUrl : ""}
              size="xs"
            ></Avatar>

            <div className="activity-line " style={{ left: "2%" }}></div>

            <div>
              <h5
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: 14,
                  fontWeight: "bold",
                  margin: "0 10px 2px"
                }}
              >
                {data.detailsUrl ? (
                  <Link to={data.detailsUrl}>
                    {data.activity_type} ({data.notes})
                  </Link>
                ) : data.activity_type ===
                  ` ${Terminology.get(SystemName.REFERRAL)} Deleted` ? (
                  data.activity_type + " (" + data.notes + ")"
                ) : data.activity_type === "SENT" ? (
                  data.notes ? (
                    data.activity_type +
                    " ( " +
                    data.toEmail +
                    " - " +
                    data.notes +
                    " ) "
                  ) : (
                    data.activity_type + " ( " + data.toEmail + ")"
                  )
                ) : data.activity_type === "RECEIVED" ? (
                  data.notes ? (
                    data.activity_type +
                    " ( " +
                    data.fromEmail +
                    " - " +
                    data.notes +
                    " ) "
                  ) : (
                    data.activity_type + " ( " + data.fromEmail + ")"
                  )
                ) : data.activity_type === "event" ? (
                  data.label
                ) : (
                  data.activity_type
                )}
              </h5>
              <Label
                textDisplay="flex"
                textColor={COLOR_GREY}
                textDirection="row"
                textSize={SIZE_SMALLER}
                textMarzin="0px 10px -3px"
              >
                {data.user ? data.user.first_name : data.userName}&nbsp;
                {data.user ? data.user.last_name : ""}
              </Label>
              <DateLabel>{data.createdAt}</DateLabel>
            </div>
          </div>
        )}
      </>
    );
  }
}

ActivityTimeline.propTypes = {
  label: PropTypes.string
};

export default ActivityTimeline;
