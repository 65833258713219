import React from "react";
import PropTypes from "prop-types";
import { Formik, Form as FormikForm } from "formik";
import { toast } from "react-toastify";
import { InlineInfoIcon } from "../assets/img/icons";

const ErrorWrapper = ({ children }) => (
  <div className={"error-messages"}>{children}</div>
);

// Number of Toast Error Message at a Time
const MAX_TOAST = 1;

class Form extends React.Component {
  state = {
    values: {},
    toastList: new Set()
  };
  filteredErrors = errors =>
    errors && Object.keys(errors).filter(error => error !== undefined);

  setErrors = () => {
    if (this.props.setIsErrorState && this.props.isErrorsState === false) {
      this.props.setIsErrorState();
    }
  };

  submit = formSubmit => {
    if (
      this.props.isSubmit &&
      this.props.reSetSubmitState &&
      this.props.handleOnButtonSubmit
    ) {
      this.props.reSetSubmitState();
      formSubmit();
    }
  };

  showError() {
    if (this.state.toastList.size < MAX_TOAST) {
      const CustomErrorToast = (
        <div className={"d-flex"}>
          <InlineInfoIcon />
          <span className={"ml-1"}>
            One or more required fields are missing input, please review.
          </span>
        </div>
      );
      const id = toast.error(CustomErrorToast, {
        // Remove the id on onClose
        onClose: () => {
          this.props.reSetSubmitState && this.props.reSetSubmitState();
          return this.state.toastList.delete(id);
        }
      });
      this.state.toastList.add(id);
    }
  }
  render() {
    const {
      initialValues,
      enableReinitialize,
      onSubmit,
      showErrorToast,
      showErrorBanner,
      children,
      isErrorsState,
      isSubmit,
      setIsErrorState,
      reSetSubmitState,
      handleOnButtonSubmit,
      id
    } = this.props;

    return (
      <Formik
        id={id}
        initialValues={initialValues}
        enableReinitialize={enableReinitialize}
        onSubmit={values => onSubmit(values)}
      >
        {({ errors, touched, handleSubmit }) => {
          // Get the error messages from Formik errors object
          const errorList = Object.values(errors);

          // Set First Error as Error message
          const errorMessage = errorList.length > 0 && errorList[0];

          return (
            <>
              {Object.keys(errors).length === 0 &&
                showErrorToast &&
                (isErrorsState || isSubmit) &&
                reSetSubmitState &&
                reSetSubmitState()}

              {/* Reset submit state when there is no error */}
              {Object.keys(errors).length === 0 &&
                showErrorBanner &&
                isSubmit &&
                reSetSubmitState &&
                reSetSubmitState()}

              {Object.keys(errors).length > 0 && (
                <>
                  {showErrorToast &&
                    isErrorsState &&
                    isSubmit &&
                    this.showError()}
                  {Object.keys(touched).length > 0 &&
                    (showErrorToast ? (
                      (setIsErrorState && this.setErrors()) || ""
                    ) : showErrorBanner ? (
                      isSubmit && (
                        <ErrorWrapper>
                          One or more required fields are missing input, please
                          review.
                        </ErrorWrapper>
                      )
                    ) : (
                      <ErrorWrapper>
                        {errorMessage
                          ? errorMessage
                          : "One or more required fields are missing input, please review."}
                      </ErrorWrapper>
                    ))}
                </>
              )}
              <FormikForm>{children}</FormikForm>
              {Object.keys(errors).length === 0 &&
                isSubmit &&
                reSetSubmitState &&
                handleOnButtonSubmit &&
                this.submit(handleSubmit)}
            </>
          );
        }}
      </Formik>
    );
  }
}

Form.propTypes = {
  initialValues: PropTypes.object,
  onSubmit: PropTypes.func
};

export default Form;
