import React, { useEffect, useState } from "react";
import PageTitle from "../../../components/base/PageTitle";
import { getParamsByName, getUrlParameter } from "../../../lib/helper";
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";
import Toast from "../../../components/base/Toast";
import { isBadRequest } from "../../../common/http";
import Url from "../../../lib/Url";
import Spinner from "../../../components/base/Spinner";
import SummaryTab from "../tab/SummarySection";
import Page404 from "../../Page404";
import {
  DEAL_STATUS_DRAFT,
  DEAL_STATUS_SUBMITTED
} from "../../../dealStatus/Constants";
import CustomerDetailTab from "../tab/CustomerDetailSection";
import ProjectDetailTab from "../tab/ProjectDetailSection";
import AdditionalDetailSection from "../tab/AdditionalDetailSection";
import ContactDetailTab from "../tab/ContactDetailsSection";
import {
  AWS_APN_PROGRAM,
  AWS_PRODUCT,
  AWS_COMPETITIVE_TRACKING,
  AWS_DELIVERY_MODEL,
  AWS_INDUSTRY_VERTICAL,
  AWS_SALES_ACTIVITY,
  AWS_SOLUTION_OFFERED,
  AWS_USECASE
} from "../../../tagType/Constants";

const AddPage = props => {
  const [selectedCountry, setSelectedCountry] = useState();
  const [selectedCountryName, setSelectedCountryName] = useState("");
  const [selectedOppType, setSelectedOppType] = useState("");
  const [shareWithAws, setShareWithAwsType] = useState("");
  const [oppSource, setOppSource] = useState("");
  const [detail, setDetail] = useState("");
  const [awsDetail, setAwsDealDetail] = useState("");
  const [enableReinitialize, setEnableReinitialize] = useState(true);

  const [apnProgramOption, setapnProgramOption] = useState([]);

  const [useCaseOption, setuseCaseOption] = useState([]);

  const [industryVerticalOption, setindustryVerticalOption] = useState([]);

  const [salesActivityOption, setsalesActivityOption] = useState([]);

  const [solutionOfferedOption, setsolutionOfferedOption] = useState([]);

  const [deliveryModelOption, setdeliveryModelOption] = useState([]);

  const [awsProductOption, setawsProductOption] = useState([]);

  const [competitiveTrackingOption, setcompetitiveTrackingOption] = useState(
    []
  );

  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(
    parseInt(getUrlParameter("tab")) || 1
  );

  const tabs = {
    TAB1: 1,
    TAB2: 2,
    TAB3: 3,
    TAB4: 4,
    TAB5: 5
  };

  useEffect(() => {
    setActiveTab(
      parseInt(getUrlParameter("tab")) ? parseInt(getUrlParameter("tab")) : 1
    );
    getDetail(getUrlParameter("id"));
    getAwsDealDetail(getUrlParameter("awsDealId"));
    getTags();
  }, []);

  const getTags = async () => {
    try {
      const response = await apiClient.get(`${endpoints().coSellAPI}`);
      const results = response.data.data;

      let apnProgramOption = results.find(
        value => value.name == AWS_APN_PROGRAM
      );
      setapnProgramOption(apnProgramOption.tagList);
      let useCaseOption = results.find(value => value.name == AWS_USECASE);

      setuseCaseOption(useCaseOption.tagList);

      let industryVerticalOption = results.find(
        value => value.name == AWS_INDUSTRY_VERTICAL
      );
      setindustryVerticalOption(industryVerticalOption.tagList);

      let salesActivityOption = results.find(
        value => value.name == AWS_SALES_ACTIVITY
      );

      setsalesActivityOption(salesActivityOption.tagList);

      let solutionOfferedOption = results.find(
        value => value.name == AWS_SOLUTION_OFFERED
      );

      setsolutionOfferedOption(solutionOfferedOption.tagList);

      let deliveryModelOption = results.find(
        value => value.name == AWS_DELIVERY_MODEL
      );
      setdeliveryModelOption(deliveryModelOption.tagList);

      let awsProductOption = results.find(value => value.name == AWS_PRODUCT);

      setawsProductOption(awsProductOption.tagList);

      let competitiveTrackingOption = results.find(
        value => value.name == AWS_COMPETITIVE_TRACKING
      );
      setcompetitiveTrackingOption(competitiveTrackingOption.tagList);
    } catch (err) {
      console.log(err);
    }
  };
  // Handle Country Change
  const handleCountryChange = ({ values }) => {
    setEnableReinitialize(false);
    const selectedOption = values.country && values.country.id;
    const selectedCountryName = values.country && values.country.label;
    setEnableReinitialize(false);
    setSelectedCountry(selectedOption);
    setSelectedCountryName(selectedCountryName);
  };

  // Get Deal Data
  const getDetail = async id => {
    try {
      setIsLoading(true);

      if (id) {
        const response = await apiClient.get(`${endpoints().dealsAPI}/${id}`);
        setDetail(response && response.data && response.data.data);
      } else {
        const response = await apiClient.get(
          `${endpoints().accountAPI}/search?company_name=${getParamsByName(
            "company_name"
          )}`
        );

        let data = response && response.data && response.data.data[0];
        setDetail(data);
      }
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Get Aws Deal Data
  const getAwsDealDetail = async id => {
    try {
      setIsLoading(true);

      const response = await apiClient.get(`${endpoints().awsDealAPI}/${id}`);
      setAwsDealDetail(response && response.data);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
    }
  };

  // Deal Create
  const handleCreate = async values => {
    const data = new FormData();
    data.append("address", values.address ? values.address : "");
    data.append("city", values.city ? values.city : "");
    data.append("firstName", values.firstName ? values.firstName : "");
    data.append("lastName", values.lastName ? values.lastName : "");
    data.append("title", values.title ? values.title : "");
    data.append("email", values.email ? values.email : "");
    data.append("phone", values.phone ? values.phone : "");
    data.append("notes", values && values.notes ? values.notes : "");

    data.append("accountName", values.accountName ? values.accountName : "");
    data.append("website", values.website ? values.website : "");
    data.append("postalCode", values.postalCode ? values.postalCode : "");
    data.append(
      "customer_duns",
      values.customer_duns ? values.customer_duns : ""
    );
    data.append(
      "industry_vertical",
      values.industry_vertical ? values.industry_vertical.value : ""
    );

    data.append(
      "state",
      values &&
        values.state &&
        values.state.value !== undefined &&
        values.state.value !== "null"
        ? values.state.value
        : ""
    );
    data.append(
      "country",
      values &&
        values.country &&
        values.country.label !== undefined &&
        values.country.label !== "null "
        ? values.country.label
        : ""
    );
    try {
      if (!getParamsByName("id")) {
        data.append("status", DEAL_STATUS_DRAFT);
        const response = await apiClient.post(`${endpoints().dealsAPI}`, data);
        let successMessage;
        if (response && response.data) {
          let id =
            response.data &&
            response.data.createDeal &&
            response.data.createDeal.id;
          const updatedUrl = Url.updateQueryStringParameter(
            window.location.href,
            "id",
            response.data &&
              response.data.createDeal &&
              response.data.createDeal.id
          );
          window.history.replaceState(null, "", updatedUrl);
          successMessage = response.data.message;
          Toast.success(successMessage);
          getDetail(id);

          if (activeTab !== tabs.TAB5) {
            setActiveTab(activeTab + 1);
            handleChange(activeTab + 1);
          }
        }
      } else {
        updateDeal(data);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        Toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  // Deal Update
  const updateDeal = data => {
    let id = getParamsByName("id");
    apiClient
      .put(`${endpoints().dealsAPI}/${id}`, data)
      .then(response => {
        let successMessage;

        if (response && response.data) {
          successMessage = response.data.message;
        }
        Toast.success(successMessage);
        getDetail(id);
        if (activeTab !== tabs.TAB5) {
          setActiveTab(activeTab + 1);
          handleChange(activeTab + 1);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Aws Deal Update
  const updateUpdateAwsDeal = (data, allowNavigation) => {
    let id = getParamsByName("awsDealId");
    let dealId = getParamsByName("id");
    if (!getParamsByName("awsDealId")) {
      Toast.error("Please create a aws deal");
      setActiveTab(2);
      handleChange(2);
    } else {
      apiClient
        .put(`${endpoints().awsDealAPI}/${id}`, data)
        .then(response => {
          let successMessage;

          if (response && response.data) {
            successMessage = response.data.message;
          }
          if (response && response.data && allowNavigation) {
            props.history.push(`/deal/details/${dealId}`);
          }
          getAwsDealDetail(id);
          if (activeTab !== tabs.TAB5) {
            setActiveTab(activeTab + 1);
            handleChange(activeTab + 1);
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    }
  };

  // Update Project Section
  const handleProjectUpdate = async values => {
    const data = new FormData();

    let apn_programs =
      values.apn_programs && values.apn_programs.length > 0
        ? values.apn_programs.map(value => value.value)
        : "";

    data.append("apn_programs", apn_programs);

    let aws_products =
      values.aws_products && values.aws_products.length > 0
        ? values.aws_products.map(value => value.value)
        : "";

    data.append("aws_products", aws_products);

    let delivery_model =
      values.delivery_model && values.delivery_model.length > 0
        ? values.delivery_model.map(value => value.value)
        : "";

    data.append("delivery_model", delivery_model);

    let sales_activities =
      values.sales_activities && values.sales_activities.length > 0
        ? values.sales_activities.map(value => value.value)
        : "";

    data.append("sales_activities", sales_activities);

    data.append(
      "solutions_offered",
      values && values.solutions_offered && values.solutions_offered.value
        ? values.solutions_offered.value
        : ""
    );

    data.append(
      "use_case",
      values && values.use_case && values.use_case.value
        ? values.use_case.value
        : ""
    );

    data.append("customer_business_problem", values.customer_business_problem);

    data.append(
      "estimated_aws_monthly_recurring_revenue",
      values.estimated_aws_monthly_recurring_revenue
    );

    data.append("next_step", values.next_step);

    data.append(
      "share_with_aws",
      shareWithAws !== "" ? shareWithAws : awsDetail && awsDetail.share_with_aws
    );

    data.append(
      "opportunity_type",
      selectedOppType !== ""
        ? selectedOppType
        : awsDetail && awsDetail.opportunity_type
    );

    data.append(
      "opportunity_source",
      oppSource !== "" ? oppSource : awsDetail && awsDetail.opportunity_source
    );
    data.append("other_solution_offered", values.other_solution_offered);

    data.append("partner_project_title", values.partner_project_title);

    data.append("target_close_date", values.target_close_date);
    data.append("dealId", getParamsByName("id"));
    if (!getParamsByName("awsDealId")) {
      await apiClient
        .post(`${endpoints().awsDealAPI}`, data)
        .then(response => {
          let successMessage;

          successMessage = response.data.message;

          const updatedUrl = Url.updateQueryStringParameter(
            window.location.href,
            "awsDealId",
            response.data && response.data.awsDealId
          );

          window.history.replaceState(null, "", updatedUrl);
          if (response && response.data && activeTab == tabs.TAB5) {
            Toast.success(successMessage);
          }
          if (activeTab !== tabs.TAB5) {
            setActiveTab(activeTab + 1);
            handleChange(activeTab + 1);
          }
          getAwsDealDetail(response.data.awsDealId);
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    } else {
      updateUpdateAwsDeal(data);
    }
  };

  // Update Additional Detail Section
  const handleAdditionalDetailUpdate = values => {
    const data = new FormData();
    data.append("additional_comments", values.additional_comments);
    data.append("aws_account_id", values.aws_account_id);
    data.append(
      "partner_crm_unique_identifier",
      values.partner_crm_unique_identifier
    );
    data.append(
      "competitive_tracking",
      values && values.competitive_tracking && values.competitive_tracking.value
        ? values.competitive_tracking.value
        : ""
    );
    data.append("dealId", getParamsByName("id"));
    updateUpdateAwsDeal(data);
  };

  // Contact Detail Section Update
  const handleContactDetailUpdate = values => {
    const data = new FormData();
    data.append(
      "primary_contact_first_name",
      values.primary_contact_first_name
    );
    data.append("primary_contact_title", values.primary_contact_title);
    data.append("primary_contact_last_name", values.primary_contact_last_name);
    data.append("primary_contact_email", values.primary_contact_email);
    data.append("primary_contact_phone", values.primary_contact_phone);
    data.append("dealId", getParamsByName("id"));
    updateUpdateAwsDeal(data);
  };

  const handleSummaryUpdate = values => {
    const data = new FormData();
    const dealData = new FormData();

    data.append(
      "primary_contact_first_name",
      values.primary_contact_first_name
    );
    data.append("primary_contact_title", values.primary_contact_title);
    data.append("primary_contact_last_name", values.primary_contact_last_name);
    data.append("primary_contact_email", values.primary_contact_email);
    data.append("primary_contact_phone", values.primary_contact_phone);
    data.append("additional_comments", values.additional_comments);
    data.append("aws_account_id", values.aws_account_id);
    data.append(
      "partner_crm_unique_identifier",
      values.partner_crm_unique_identifier
    );
    data.append(
      "competitive_tracking",
      values && values.competitive_tracking && values.competitive_tracking.value
        ? values.competitive_tracking.value
        : ""
    );
    data.append("dealId", getParamsByName("id"));
    let apn_programs =
      values.apn_programs && values.apn_programs.length > 0
        ? values.apn_programs.map(value => value.value)
        : "";

    data.append("apn_programs", apn_programs);

    let aws_products =
      values.aws_products && values.aws_products.length > 0
        ? values.aws_products.map(value => value.value)
        : "";

    data.append("aws_products", aws_products);

    let delivery_model =
      values.delivery_model && values.delivery_model.length > 0
        ? values.delivery_model.map(value => value.value)
        : "";

    data.append("delivery_model", delivery_model);

    let sales_activities =
      values.sales_activities && values.sales_activities.length > 0
        ? values.sales_activities.map(value => value.value)
        : "";

    data.append("sales_activities", sales_activities);

    data.append(
      "solutions_offered",
      values && values.solutions_offered && values.solutions_offered.value
        ? values.solutions_offered.value
        : ""
    );

    data.append(
      "use_case",
      values && values.use_case && values.use_case.value
        ? values.use_case.value
        : ""
    );

    data.append("customer_business_problem", values.customer_business_problem);

    data.append(
      "estimated_aws_monthly_recurring_revenue",
      values.estimated_aws_monthly_recurring_revenue
    );

    data.append("next_step", values.next_step);

    data.append(
      "share_with_aws",
      shareWithAws !== "" ? shareWithAws : awsDetail && awsDetail.share_with_aws
    );

    data.append(
      "opportunity_type",
      selectedOppType !== ""
        ? selectedOppType
        : awsDetail && awsDetail.opportunity_type
    );

    data.append(
      "opportunity_source",
      oppSource !== "" ? oppSource : awsDetail && awsDetail.opportunity_source
    );
    data.append("other_solution_offered", values.other_solution_offered);

    data.append("partner_project_title", values.partner_project_title);

    data.append("target_close_date", values.target_close_date);
    dealData.append("address", values.address ? values.address : "");
    dealData.append("city", values.city ? values.city : "");
    dealData.append("firstName", values.firstName ? values.firstName : "");
    dealData.append("lastName", values.lastName ? values.lastName : "");
    dealData.append("title", values.title ? values.title : "");
    dealData.append("email", values.email ? values.email : "");
    dealData.append("phone", values.phone ? values.phone : "");
    dealData.append("selectedStatus", DEAL_STATUS_SUBMITTED);
    data.append("notes", values && values.notes ? values.notes : "");
    dealData.append(
      "accountName",
      values.accountName ? values.accountName : ""
    );
    dealData.append("website", values.website ? values.website : "");
    dealData.append("postalCode", values.postalCode ? values.postalCode : "");
    dealData.append(
      "customer_duns",
      values.customer_duns ? values.customer_duns : ""
    );
    dealData.append(
      "industry_vertical",
      values.industry_vertical ? values.industry_vertical.value : ""
    );

    dealData.append(
      "state",
      values && values.state && values.state.value !== undefined
        ? values.state.value
        : ""
    );
    dealData.append(
      "country",
      values && values.country && values.country.label !== undefined
        ? values.country.label
        : ""
    );

    apiClient
      .put(`${endpoints().dealsAPI}/${getParamsByName("id")}`, dealData)
      .then(response => {
        let successMessage;

        if (response && response.data) {
          successMessage = response.data.message;
        }
        Toast.success(successMessage);
        updateUpdateAwsDeal(data, true);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          Toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // OppType onChange
  const handleOppTypeChange = values => {
    setSelectedOppType(values);
  };

  // AwsShare onChange
  const handleAwsShareChange = values => {
    setShareWithAwsType(values);
  };

  // OppSource onChange
  const handleOppSourceChange = values => {
    setOppSource(values);
  };

  // Update Url
  const handleChange = value => {
    const updatedUrl = Url.updateQueryStringParameter(
      window.location.href,
      "tab",
      value
    );
    window.history.replaceState(null, "", updatedUrl);
  };

  // Show Loading
  if (isLoading && getParamsByName("id")) {
    return <Spinner />;
  }

  return (
    <>
      {activeTab <= tabs.TAB5 && <PageTitle label="New Co-Sell Opportunity" />}

      {activeTab > tabs.TAB5 && <Page404 />}
      {/* Customer Detail Section*/}
      {activeTab === tabs.TAB1 && (
        <CustomerDetailTab
          history={props.history}
          handleCreate={handleCreate}
          handleCountryChange={handleCountryChange}
          selectedCountry={selectedCountry}
          selectedCountryName={selectedCountryName}
          setActiveTab={setActiveTab}
          handleChange={handleChange}
          detail={detail}
          enableReinitialize={enableReinitialize}
          industryVerticalOption={industryVerticalOption}
        />
      )}

      {/* Project Detail Section*/}
      {activeTab === tabs.TAB2 && (
        <ProjectDetailTab
          history={props.history}
          handleUpdate={handleProjectUpdate}
          handleOppTypeChange={handleOppTypeChange}
          handleAwsShareChange={handleAwsShareChange}
          setActiveTab={setActiveTab}
          handleChange={handleChange}
          handleOppSourceChange={handleOppSourceChange}
          detail={awsDetail}
          awsProductOption={awsProductOption}
          deliveryModelOption={deliveryModelOption}
          apnProgramOption={apnProgramOption}
          salesActivityOption={salesActivityOption}
          useCaseOption={useCaseOption}
          solutionOfferedOption={solutionOfferedOption}
        />
      )}

      {/* Additional Detail Section*/}
      {activeTab === tabs.TAB3 && (
        <AdditionalDetailSection
          history={props.history}
          handleUpdate={handleAdditionalDetailUpdate}
          setActiveTab={setActiveTab}
          handleChange={handleChange}
          detail={awsDetail}
          competitiveTrackingOption={competitiveTrackingOption}
        />
      )}

      {/* Contact Detail Section*/}
      {activeTab === tabs.TAB4 && (
        <ContactDetailTab
          history={props.history}
          handleUpdate={handleContactDetailUpdate}
          setActiveTab={setActiveTab}
          handleChange={handleChange}
          detail={awsDetail}
        />
      )}
      {activeTab === tabs.TAB5 && (
        <SummaryTab
          history={props.history}
          setActiveTab={setActiveTab}
          handleChange={handleChange}
          detail={detail}
          handleCountryChange={handleCountryChange}
          selectedCountry={selectedCountry}
          selectedCountryName={selectedCountryName}
          enableReinitialize={enableReinitialize}
          awsDetail={awsDetail}
          handleOppTypeChange={handleOppTypeChange}
          handleSummaryUpdate={handleSummaryUpdate}
          handleAwsShareChange={handleAwsShareChange}
          handleOppSourceChange={handleOppSourceChange}
          handleUpdate={handleCreate}
          handleProjectUpdate={handleProjectUpdate}
          handleAdditionalDetailUpdate={handleAdditionalDetailUpdate}
          handleContactDetailUpdate={handleContactDetailUpdate}
          awsProductOption={awsProductOption}
          deliveryModelOption={deliveryModelOption}
          apnProgramOption={apnProgramOption}
          salesActivityOption={salesActivityOption}
          useCaseOption={useCaseOption}
          solutionOfferedOption={solutionOfferedOption}
          industryVerticalOption={industryVerticalOption}
          competitiveTrackingOption={competitiveTrackingOption}
        />
      )}
    </>
  );
};
export default AddPage;
