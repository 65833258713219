import { apiClient } from "../apiClient";
import toast from "../components/base/Toast";
import { endpoints } from "../configs";
import { fetchList } from "./table";

import {
  REQUEST_UPDATE_PROJECT,
  RECEIVE_UPDATE_PROJECT,
  PROJECT_UPDATE_ERROR,
  REQUEST_PROJECT_LIST,
  RECEIVE_PROJECT_LIST,
  FETCH_PROJECT_LIST_FAIL,
  CHANGE_PROJECT_PAGE
} from "../actionType/Constants";
import { isBadRequest } from "../common/http";

/**
 * Request for updating Project
 */
export function requestUpdateProject() {
  return {
    type: REQUEST_UPDATE_PROJECT
  };
}

/**
 * Receive for updating Project
 */
export function receiveUpdateProject() {
  return {
    type: RECEIVE_UPDATE_PROJECT
  };
}

/**
 * Receive for error updating Project
 */
export function projectUpdateError(error) {
  return {
    type: PROJECT_UPDATE_ERROR,
    error
  };
}

/**
 * Update Project
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateProject(id, data, params) {
  return dispatch => {
    dispatch(requestUpdateProject());
    apiClient
      .put(`${endpoints().projectAPI}/status/${id}`, data)
      .then(() => {
        dispatch(
          fetchList(
            "projectList",
            `${endpoints().projectAPI}`,
            1,
            10,
            params ? params : {}
          )
        );
        dispatch(receiveUpdateProject());
      })
      .catch(error => {
        dispatch(projectUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Request Project list
 */
export function requestProjectList() {
  return {
    type: REQUEST_PROJECT_LIST
  };
}

/**
 * Receive Project list
 */
export function receiveProjectList(data) {
  return {
    type: RECEIVE_PROJECT_LIST,
    payload: data
  };
}

/**
 * Fetch Project list fail
 *
 * @param {*} error
 */
export function fetchProjectListFail(error) {
  return {
    type: FETCH_PROJECT_LIST_FAIL,
    error
  };
}

/**
 * Fetch Project list
 */
export function fetchProjectList(params) {
  params = params ? params : "";
  return async dispatch => {
    dispatch(requestProjectList());
    try {
      const response = await apiClient.get(
        `${endpoints().projectAPI}/search?${params}`
      );
      const data = response.data;

      return dispatch(receiveProjectList(data));
    } catch (error) {
      dispatch(fetchProjectListFail(error));
      if (error.status >= 400) {
        console.log("Error");
      }
    }
  };
}

/**
 *  Change Project Page
 *
 * @param {*} payload
 */
export function changeProjectPage(payload) {
  return { type: CHANGE_PROJECT_PAGE, payload };
}
