import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import Form from "../../../../../components/base/Form";
import CancelButton from "../../../../../components/base/CancelButton";
import Text from "../../../../../components/base/Text";
import SaveButton from "../../../../../components/base/SaveButton";

const EditFormModal = props => {
  let { task, isOpen, toggle, updateForm } = props;
  let { id, name, description } = task;

  const closeEditFormModal = () => {
    toggle();
  };

  const updateFormDetails = values => {
    const data = new FormData();
    data.append("name", values.name || "");
    data.append("description", values.description || "");

    updateForm(id, data);
    closeEditFormModal();
  };

  const initialValues = {
    name: name || "",
    description: description || ""
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeEditFormModal}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          updateFormDetails(values);
        }}
      >
        <ModalHeader toggle={closeEditFormModal}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Edit Application Form
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div>
              <Text
                id={`name`}
                name={`name`}
                label="Name"
                placeholder="Name"
                required
                error=""
              />
            </div>
            <div>
              <Text
                id={`description`}
                name={`description`}
                label="Description"
                placeholder="Description"
                error=""
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={closeEditFormModal} />
            <SaveButton />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditFormModal;
