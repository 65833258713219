// Helper
import { isSuperAdmin, isCompanyAdmin, isCompanyManager } from "../lib/helper";

export const TAB_GENERAL = "General";
export const TAB_COMPANIES = "Companies";
export const TAB_SYSTEM_LOGS = "System Logs";
export const TAB_LIST = "List";
export const LIST_TAGS_SUB_TAB = "Tags";
export const LIST_TAG_TYPES_SUB_TAB = "Tag Types";
export const SYSTEM_GENERAL_SUB_TAB = "General";
export const SYSTEM_URL_SUB_TAB = "URL";
export const SYSTEM_TAB = "System";
export const USER_TAB = "Users";
export const TAB_SALESFORCE = "Salesforce";
export const TAB_ACTIVITIES = "Activities";
export const TAB_SCHEDULER = "Scheduler Jobs";
export const TAB_JOBS = "Jobs";
export const TAB_IMPORT = "Import";
// Companies Details Sub Tab Sections
export const COMPANY_GENERAL_SECTION = "General";
export const COMPANY_TEAM_SECTION = "Team";
export const COMPANY_LICENSES_AND_FEATURES_SECTION = "Licenses-and-Features";
export const COMPANY_SYSTEMS_SECTION = "Systems";
export const COMPANY_EMAIL_TEMPLATES_SECTION = "Email Templates";
export const GENERAL_TAB = 1;
export const REFERRAL = "Referral";
export const BOMBORA = "Bombora";
export const CROSS_BEAM = "Cross Beam";
export const TAB_SETTINGS = "Settings";
export const THEME = "Theme";
export const EMAIL = "Email";

/**
 * Get Support Portal Side Nav List
 *
 * @returns {Array}
 */
export function getSupportPortalsNavList(roleId) {
  let supportPortalsNavList = [];

  // Return Support Portal Nav Object
  const getsupportPortalNavObject = (tab, subtab = "") => {
    return {
      name: tab,
      defaultSubTab: subtab
    };
  };

  // Check Super Admin User
  if (isSuperAdmin(roleId) || isCompanyAdmin(roleId))
    supportPortalsNavList = [
      ...supportPortalsNavList,
      // getsupportPortalNavObject(TAB_GENERAL),
      getsupportPortalNavObject(TAB_COMPANIES),
      getsupportPortalNavObject(USER_TAB),
      getsupportPortalNavObject(TAB_LIST),
      getsupportPortalNavObject(TAB_SALESFORCE),
      getsupportPortalNavObject(TAB_SCHEDULER),
      getsupportPortalNavObject(SYSTEM_TAB, SYSTEM_GENERAL_SUB_TAB),
      getsupportPortalNavObject(TAB_ACTIVITIES),
      getsupportPortalNavObject(TAB_SYSTEM_LOGS),
      getsupportPortalNavObject(TAB_IMPORT),
      getsupportPortalNavObject(REFERRAL),
      getsupportPortalNavObject(BOMBORA),
      getsupportPortalNavObject(CROSS_BEAM)
    ];

  // Return Support Portals Nav List
  return supportPortalsNavList;
}
