import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

// Constants
import {
  TAB_BASICS,
  TAB_DETAILS,
  TAB_SITES,
  TAB_PRICING,
  TAB_VERSIONS,
  TAB_GALLERY,
  TAB_FAQ,
  APP_STATUS_DRAFT,
  APP_STATUS_REJECTED,
  APP_STATUS_APPROVED,
  APP_STATUS_IN_REVIEW,
  APP_STATUS_SUSPENDED,
  TAB_ACTIVIY
} from "../marketplaceSourcing/Constants";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  getParamsByName
} from "../../lib/helper";

import Terminology from "../../lib/Terminology";
// Components
import GridCard from "../../components/base/GridCard";
import TabsAndContent from "../../components/base/TabsAndContent";
import PageTitle from "../../components/base/PageTitle";

// Assets
import { ChevronRight, MaximizeAltIcon } from "../../assets/img/icons";

//Context
import MyContext from "../../context/MyContext";
import Basics from "../../views/app/Basics";
import Detail from "./Detail";
import SelectDropdown from "../../components/base/SelectDropdown";
import { apiClient, apiClientGet } from "../../apiClient";
import { endpoints } from "../../configs";
import { toast } from "react-toastify";
import { isBadRequest } from "../../common/http";
import SideBar from "./components/SideBar";
import Spinner from "../../components/base/Spinner";
import Gallery from "../../views/app/Gallery";
import Site from "../../views/app/Site";
import Faq from "./Faq";
import Versions from "../../views/app/Versions";
import Pricing from "./Pricing";
import Activity from "./Activity";
import AppActivityStats from "./AppActivityStats";

import SystemName from "../../Constants/SystemName";

import { connect } from "react-redux";

class EditAppFields extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      activeTab: this.props.activeTab ? this.props.activeTab : TAB_BASICS,
      appName: "",
      appDetails: this.props.appDetails,
      status: "",
      appId: "",
      appsColorValidate: true,
      bannerColor: "#FFFFFF",
      appLogoFileUrl: "",
      appLogoFile: "",
      files: [],
      partnerId: "",
      isLoading: false,
      appActivity: "",
      appActivityStats: ""
    };
    this.onColourPickerClick = this.onColourPickerClick.bind(this);
    this._handleChange = this._handleChange.bind(this);
  }

  adminActionOptions = [
    {
      value: "Draft",
      label: "Draft"
    },
    {
      value: "In Review",
      label: "In Review"
    },
    {
      value: "Approve",
      label: "Approve"
    },
    {
      value: "Reject",
      label: "Reject"
    },
    {
      value: "Suspend",
      label: "Suspend"
    }
  ];

  componentDidMount() {
    const appId = getParamsByName("id");
    this.setState({ appId: appId });
    this.setActiveTab();
    this._getAppDetails();
    this.getActivityList();
    this.getAppStatics();
  }
  setActiveTab = () => {
    const status = getParamsByName("section");

    this.setState({
      activeTab:
        status === TAB_BASICS
          ? TAB_BASICS
          : status === TAB_DETAILS
          ? TAB_DETAILS
          : status === TAB_SITES
          ? TAB_SITES
          : status === TAB_PRICING
          ? TAB_PRICING
          : status === TAB_VERSIONS
          ? TAB_VERSIONS
          : status === TAB_GALLERY
          ? TAB_GALLERY
          : status === TAB_FAQ
          ? TAB_FAQ
          : status === TAB_ACTIVIY
          ? TAB_ACTIVIY
          : TAB_BASICS
    });
  };
  // Handle status change
  _handleStatusChange = tabStatus => {
    this.props.history.push(
      `?id=${this.props.appDetails.id}&section=${tabStatus}`
    );
    this.setState({ status: tabStatus });
  };

  //Handle Admin Actions Dropdown
  handleAdminAction = value => {
    const id = this.state.appDetails.id;
    switch (value) {
      case "Draft":
        return this.updateAppByStatus(APP_STATUS_DRAFT, id);
      case "In Review":
        return this.updateAppByStatus(APP_STATUS_IN_REVIEW, id);
      case "Approve":
        return this.updateAppByStatus(APP_STATUS_APPROVED, id);
      case "Reject":
        return this.updateAppByStatus(APP_STATUS_REJECTED, id);
      case "Suspend":
        return this.updateAppByStatus(APP_STATUS_SUSPENDED, id);
      default:
        return "";
    }
  };

  // Update App By Status
  updateAppByStatus = status => {
    let data = { status };
    let id = this.state.appId;
    this.setState({ status: data.status, isLoading: true });
    return apiClient
      .put(`${endpoints().appAPI}/status/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this._getAppDetails();
          toast.success(successMessage);
        }
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
        }
      });
  };

  // Set image preview in state

  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  appLogoFileChange = files => {
    this.setState({ appLogoFileUrl: URL.createObjectURL(files) }, () =>
      this.setBase64Image(files, "files")
    );
  };

  // App Logo Remove
  handleAppLogoRemove = () => {
    this.setState(
      { appLogoFileUrl: "", files: "", appLogoFile: "" },
      this._handleDeleteAppLogo
    );
  };

  // Delete App Logo

  _handleDeleteAppLogo = () => {
    const id = this.state.appId;
    return apiClient
      .delete(`${endpoints().appAPI}/applogo/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.props.getAppDetails(id);
      })

      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Handle OnChange App Name field
  _handleAppNameChange = e => {
    const name = e.target.value;
    this.setState({ appName: name, cardTitleShow: true });
  };
  _handleChange = e => {
    const appName = e.target.value;
    this.setState({ appName: appName, cardTitleShow: true });
  };

  toggle = tab => {
    this.setState({
      activeTab: tab
    });
  };
  onColourPickerClick(e) {
    this.setState({ bannerColor: e.target.id, appsColorValidate: true });
  }

  // App details api call by id
  _getAppDetails = () => {
    this.setState({ loading: true }, () => {
      apiClientGet(
        `${endpoints().appAPI}/${this.props.appDetails &&
          this.props.appDetails.id}`,
        (errMessage, response) => {
          if (errMessage) {
            this.setState({ isInvalidRequest: true });
          } else {
            const appDetails = response && response.data;
            const partnerId = appDetails.partner_id;
            this.setState({
              appDetails,
              partnerId,
              eventDate: appDetails.eventDate
                ? new Date(appDetails.eventDate)
                : "",
              loading: false,
              bannerColor: appDetails.app_color,
              appsColorValidate: true
            });
          }
        }
      );
    });
  };

  getAppStatics = () => {
    try {
      const appId = getParamsByName("id");
      apiClient
        .get(`${endpoints().appAPI}/appActivity/stats/?id=${appId}`)
        .then(response => {
          if (response && response.data) {
            this.setState({
              appActivityStats: response.data
            });
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    } catch (err) {}
  };

  // Get Activity list
  getActivityList = id => {
    try {
      const appId = getParamsByName("id") ? getParamsByName("id") : id;
      apiClient
        .get(`${endpoints().activity}?appId=${appId}`)
        .then(response => {
          if (response && response.data) {
            this.setState({
              appActivity: response.data.data
            });
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    } catch (err) {}
  };
  // Render the form fields
  render() {
    const profileUrl = this.props.appDetails.profile_url;
    const detailLink = `/app/${profileUrl}?eid=${encodeURIComponent(
      this.props.appDetails.eid
    )}`;
    const { appsColorValidate, partnerId, isLoading, appId } = this.state;
    const data = {
      // title: this.state.appName || this.props.appDetails.app_name,
      title:
        !this.state.appName &&
        this.props.appDetails &&
        this.props.appDetails.app_name
          ? this.state.cardTitleShow
            ? this.state.title
            : this.props.appDetails.app_name
          : this.state.appName,
      id: this.props.appDetails.id,
      appLogoUrl:
        !this.state.appLogoFileUrl &&
        this.state.appDetails &&
        this.state.appDetails.app_logo
          ? this.state.appDetails.app_logo
          : this.state.appLogoFileUrl,
      bannerColor:
        !this.state.bannerColor &&
        this.props.appDetails &&
        this.props.appDetails.app_color
          ? this.props.appDetails.app_color
          : this.state.bannerColor
    };
    const { activeTab } = this.state;
    let bannerColor = this.state.appDetails.app_color;
    const initialValues = { bannerColor };

    //Get the Selected App
    const selectedAppStatus =
      this.state.appDetails && this.state.appDetails.status;

    const headerButton = (
      <div className="d-flex align-items-center">
        <SelectDropdown
          buttonLabel={selectedAppStatus}
          dropdownLinks={this.adminActionOptions}
          color={"gray"}
          hideCaret
          selectName={"admin_action"}
          handleChange={this.handleAdminAction}
        />
      </div>
    );

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <>
        <div>
          <MyContext.Consumer>
            {context => (
              <>
                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) && (
                  <div className="d-flex justify-content-between">
                    <div className="d-flex align-items-center mb-3">
                      <a
                        className="cursor-pointer text-dark"
                        onClick={() => {
                          this.props.history.push(`/marketplace-sourcing`);
                        }}
                      >
                        {Terminology.get(SystemName.MARKETPLACE)}
                      </a>
                      <span
                        className="text-dark d-inline-flex align-items-center"
                        style={{ width: "15px" }}
                      >
                        <ChevronRight />
                      </span>

                      <a
                        className="cursor-pointer text-dark"
                        onClick={() => {
                          this.props.history.push(`/marketplace-sourcing`);
                        }}
                      >
                        Apps
                      </a>

                      <span
                        className="text-dark d-inline-flex align-items-center"
                        style={{ width: "15px" }}
                      >
                        <ChevronRight />
                      </span>
                      <span
                        onClick={e => e.preventDefault()}
                        className={[
                          "text-dark",
                          "font-weight-bold",
                          "d-inline-flex",
                          "align-items-center",
                          "project-name",
                          "w-320"
                        ].join(" ")}
                      >
                        <span className="ellipsis">Edit App</span>
                      </span>
                    </div>
                  </div>
                )}
                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) && (
                  <div className="pull-right">{headerButton}</div>
                )}
                {!context.isMobile && <PageTitle label={"Edit App"} />}
                <Nav tabs className="admin-tabs">
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_BASICS
                      })}
                      onClick={() => {
                        this.toggle(TAB_BASICS);
                        this._handleStatusChange(TAB_BASICS);
                      }}
                    >
                      Basics
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_DETAILS
                      })}
                      onClick={() => {
                        this.toggle(TAB_DETAILS);
                        this._handleStatusChange(TAB_DETAILS);
                      }}
                    >
                      Details
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_VERSIONS
                      })}
                      onClick={() => {
                        this.toggle(TAB_VERSIONS);
                        this._handleStatusChange(TAB_VERSIONS);
                      }}
                    >
                      Versions
                    </NavLink>
                  </NavItem>

                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_PRICING
                      })}
                      onClick={() => {
                        this.toggle(TAB_PRICING);
                        this._handleStatusChange(TAB_PRICING);
                      }}
                    >
                      Pricing
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_SITES
                      })}
                      onClick={() => {
                        this.toggle(TAB_SITES);
                        this._handleStatusChange(TAB_SITES);
                      }}
                    >
                      Sites
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_GALLERY
                      })}
                      onClick={() => {
                        this.toggle(TAB_GALLERY);
                        this._handleStatusChange(TAB_GALLERY);
                      }}
                    >
                      Gallery
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_FAQ
                      })}
                      onClick={() => {
                        this.toggle(TAB_FAQ);
                        this._handleStatusChange(TAB_FAQ);
                      }}
                    >
                      FAQ
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={classnames({
                        active: activeTab === TAB_ACTIVIY
                      })}
                      onClick={() => {
                        this.toggle(TAB_ACTIVIY);
                        this._handleStatusChange(TAB_ACTIVIY);
                      }}
                    >
                      Stats & Activity
                    </NavLink>
                  </NavItem>
                </Nav>
                <TabsAndContent fullWidth>
                  <div
                    className={`${
                      activeTab == TAB_ACTIVIY
                        ? "d-flex"
                        : "tab-content-wrapper d-flex"
                    }`}
                  >
                    <TabContent activeTab={activeTab}>
                      <TabPane tabId={TAB_BASICS}>
                        <Basics
                          colorValidate={appsColorValidate}
                          data={initialValues}
                          onColourPickerClick={this.onColourPickerClick}
                          bannerColor={
                            this.state.bannerColor || initialValues.bannerColor
                          }
                          appLogoFileChange={e => {
                            this.appLogoFileChange(e);
                          }}
                          handleAppLogoRemove={this.handleAppLogoRemove}
                          appLogoFileUrl={this.state.appLogoFileUrl}
                          appDetails={this.props.appDetails}
                          _handleChange={this._handleChange}
                          handleNameChange={this._handleAppNameChange.bind(
                            this
                          )}
                          isFeatured={
                            this.props.appDetails &&
                            this.props.appDetails.is_featured
                          }
                          getAppDetails={this.props.getAppDetails}
                        />
                      </TabPane>
                      <TabPane tabId={TAB_DETAILS}>
                        <Detail appDetails={this.props.appDetails} />
                      </TabPane>
                      <TabPane tabId={TAB_VERSIONS} className="smooth-scroll">
                        <Versions />
                      </TabPane>
                      <TabPane tabId={TAB_PRICING}>
                        <Pricing />
                      </TabPane>
                      <TabPane tabId={TAB_SITES}>
                        <Site />
                      </TabPane>
                      <TabPane tabId={TAB_GALLERY}>
                        <Gallery />
                      </TabPane>
                      <TabPane tabId={TAB_FAQ}>
                        <Faq appId={this.state.appId} />
                      </TabPane>
                      <TabPane tabId={TAB_ACTIVIY}>
                        {this.state.appActivityStats && (
                          <AppActivityStats
                            appActivityStats={this.state.appActivityStats}
                            appId={appId}
                          />
                        )}
                        {this.state.appActivity && (
                          <Activity activities={this.state.appActivity} />
                        )}
                      </TabPane>
                    </TabContent>

                    {activeTab === TAB_BASICS && (
                      <div className="preview-wrapper">
                        <SideBar
                          status={
                            this.props.appDetails &&
                            this.props.appDetails.status
                          }
                          appDetails={this.props.appDetails}
                          appId={
                            this.props.appDetails && this.props.appDetails.id
                          }
                          partnerId={partnerId}
                          getAppDetails={this._getAppDetails}
                        />

                        <label className="font-weight-bold pl-3">Preview</label>
                        <div className="ml-3">
                          <GridCard
                            isPreview={detailLink ? "false" : "true"}
                            link={detailLink}
                            openLinkInNewTab={true}
                            boxShadow={"true"}
                            placeHolder={"App Type"}
                            data={data}
                            files={this.props.files || ""}
                            appLogoUrl={this.state.appLogoFileUrl}
                            fullWidth={false}
                          />
                          {detailLink && (
                            <div className="preview-full mt-4 d-flex align-items-center justify-content-center">
                              <MaximizeAltIcon />
                              <a
                                href={detailLink}
                                target="_blank"
                                className={["text-underline"].join(" ")}
                                rel="noreferrer"
                              >
                                View Full Preview
                              </a>
                            </div>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </TabsAndContent>
              </>
            )}
          </MyContext.Consumer>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const roleId = state.user ? state.user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(EditAppFields);
