import React, { useState, useEffect } from "react";
import DefaultContent from "../../components/content/DefaultContent";
import { getKeyValueByObject } from "../../lib/helper";

import ActivityTimeline from "../../components/base/ActivityTimeline";

import { SETTINGS_ENABLE_REFERRALS } from "../../setting/Constants";

import PageTitle from "../../components/base/PageTitle";

const PartnerProfileActivities = props => {
  const { activities, settings } = props;

  const [VisibleActivity, setVisible] = useState(4);

  const showActivity = length => {
    length && setVisible(length);
  };
  const LessActivity = length => {
    length && setVisible(4);
  };

  return (
    <>
      <PageTitle label="Activity History" />
      <div
        style={{
          maxHeight: "876px",
          height: "500px",
          overflowY: "scroll",
          position: "relative"
        }}
      >
        <div className="card h-90" style={{ padding: "25px" }}>
          {activities && activities.length > 0 && (
            <>
              <DefaultContent
                className="stats-and-activity"
                style={{
                  boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
                  padding: "0px",
                  marginBottom: "0px"
                }}
              >
                <div
                  className="card"
                  style={{
                    boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
                    padding: "0px"
                  }}
                >
                  {activities &&
                    activities.length > 0 &&
                    activities.slice(0, VisibleActivity).map(activity => (
                      <div className="single-activity cursor-pointer  accordion accordion-flush">
                        <div className="accordion-item">
                          <ActivityTimeline data={activity} />
                        </div>
                      </div>
                    ))}
                </div>
                <div
                  className="card"
                  style={{
                    boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
                    padding: "0px"
                  }}
                >
                  {activities && activities.length > VisibleActivity && (
                    <div className="col-12">
                      <p
                        className="cursor-pointer"
                        onClick={() => {
                          showActivity(activities.length);
                        }}
                        style={{ color: "primary" }}
                      >
                        <p style={{ color: "#009DDA", fontSize: 12 }}>
                          Show all...
                        </p>
                        <i
                          className="fa pull-right pt-1"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  )}
                  {activities && VisibleActivity >= 5 ? (
                    <div className="col-12">
                      <p
                        className="cursor-pointer mb-3"
                        onClick={() => {
                          LessActivity(activities.length);
                        }}
                        style={{ color: "primary" }}
                      >
                        <p style={{ color: "#009DDA", fontSize: 12 }}>
                          Show less...
                        </p>
                        <i
                          className="fa pull-right pt-1"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </DefaultContent>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default PartnerProfileActivities;
