import React, { useRef } from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
// Assets
import Slider from "react-slick";
import "./videoCarousel.scss";

const VideoCarousel = props => {
  const {
    videos,
    handleVideoDelete,
    showRemove,
    appStyle,
    style,
    size = "sm",
    slides,
    customVideoSize
  } = props;

  const videoRemoveHandler = video => {
    handleVideoDelete(video);
  };

  const predefinedSize = {
    sm: "100px",
    md: "200px",
    lg: "300px"
  };
  const defaultStyle = {
    width: "100%",
    height: "140px",
    objectFit: "scale-down"
  };

  const publicProfileStyle = {
    width: customVideoSize ? customVideoSize : predefinedSize[size]
  };
  const appRemoveStyle = {
    bottom: "142px",
    left: "341px"
  };
  const removeStyle = {
    bottom: "145px",
    left: "128px"
  };
  const videoRefObjects = [];
  videos.forEach(video => {
    videoRefObjects.push(useRef());
  });
  const handlePauseVideo = () => {
    videoRefObjects.forEach(vidRef => {
      vidRef.current.pause();
    });
  };

  return (
    <>
      <Slider
        afterChange={e => {
          handlePauseVideo();
        }}
        className="video-gallery-corousel cursor-pointer "
        slidesToShow={slides}
        slidesToScroll={1}
        swiping={true}
        transition={0.5}
        arrows={true}
        infinite={false}
        responsive={[
          {
            breakpoint: 320,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 1,
              infinite: false
            }
          },
          {
            breakpoint: 768,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              infinite: false
            }
          },
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 3,
              slidesToScroll: 3,
              infinite: false
            }
          }
        ]}
      >
        {videos.map((video, index) => (
          <div
            className="videocarousel-photos"
            style={{ position: "relative", width: "fit-content" }}
          >
            <div
              style={{
                margin: "5px"
              }}
            >
              <video
                ref={videoRefObjects[index]}
                style={style ? style : defaultStyle}
                controls
                muted={true}
              >
                <source src={`${video.imageUrl}`} type="video/mp4" />
              </video>
              {showRemove ? (
                <span
                  onClick={videoRemoveHandler.bind(this, video)}
                  className={`far fa-lg fa-times-circle remove-icon position-relative`}
                  style={appStyle ? appRemoveStyle : removeStyle}
                />
              ) : (
                ""
              )}
              {video.title && video.description && (
                <div className="card">
                  <span className="mx-2">{video.title}</span>
                  <br />
                  <span className="mx-2">{video.description}</span>
                </div>
              )}
            </div>
          </div>
        ))}
      </Slider>
    </>
  );
};

export default VideoCarousel;
