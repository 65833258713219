import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import toast from "../components/base/Toast";

class HubspotSyncRuleService {
  static async create(data) {
    try {
      apiClient.post(`${endpoints().hubspotSyncRule}`, data).then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      });
    } catch (err) {
      console.log(err);
    }
  }

  static async update(id, data) {
    try {
      apiClient
        .put(`${endpoints().hubspotSyncRule}/${id}`, data)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }

  static async delete(id) {
    try {
      apiClient
        .delete(`${endpoints().hubspotSyncRule}/${id}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            toast.success(successMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }
}

export default HubspotSyncRuleService;
