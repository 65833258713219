export const SALESFORCE_OPPORTUNITY_NET_ARR_AMOUNT = "sales2cash__Net_ARR__c";
export const SALESFORCE_OPPORTUNITY_AMOUNT = "Amount";
export const SALESFORCE_OBJECT_ACCOUNT = "Account";
export const SALESFORCE_OBJECT_OPPORTUNITY = "Opportunity";

export const SALESFORCE_SYNC_DIRECTION_OUTBOUND = 1;
export const SALESCORCE_SYNC_DIRECTION_INBOUND = 2;
export const SALESCORCE_SYNC_DIRECTION_BOTH = 3;

export const OPPORTUNITY_DETAILS = "Details";
export const REFERRAL_LIST = "Referral";
