import React, { useState } from "react";
import AvatarCard from "../../components/base/AvatarCard";
import Select from "../../components/base/Select";

const getDropDownTemplate = (first_name, last_name, name, title) => {
  return (
    <div className="">
      <AvatarCard
        firstName={first_name}
        lastName={last_name}
        title={title}
        size="xs"
        fontSize={8}
      />
    </div>
  );
};

const UserSelector = ({ userList, updateHandle, placeholderText }) => {
  let selectOptions = [];

  for (let index = 0; index < userList.length; index++) {
    selectOptions.push({
      id: index,
      value: userList[index].name,
      label: getDropDownTemplate(
        userList[index].first_name,
        userList[index].last_name,
        userList[index].name,
        userList[index].title
      )
    });
  }

  return (
    <div className="dropdown-wrapper">
      <Select
        id="referralOwner"
        name="referralOwner"
        label="Owner"
        fontBolded
        options={selectOptions}
        placeholder={placeholderText}
        fullWidth={true}
        isSearchable={true}
        onInputChange={data => {
          updateHandle(userList[data.values.referralOwner.id].salesforce_id);
        }}
      />
    </div>
  );
};

export default UserSelector;
