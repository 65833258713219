import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { StripeProvider } from "react-stripe-elements";

// Page Components
import MyStoreCheckout from "./MyStoreCheckout";
import CartSummary from "./CartSummary";
import DepositSummary from "./DepositSummary";

// Assets
import MarketplaceSection from "../../components/MarketplaceSection";

// Assets
import { ArrowLeft } from "../../assets/img/icons";

// Styles
import "./style.scss";
import AddPaymentOptions from "../accountingSettings/AddPaymentOptions";

export default class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stripe: null,
      // Some default styles, this would be than updated by the API
      bgColor: "#f3f3f5",
      stripeProviderApiKey: ""
    };
  }

  componentDidMount() {
    // Create Stripe instance in componentDidMount
    // (componentDidMount only fires in browser/DOM environment)
    this.setState({
      stripe: window.Stripe(this.props.location.state.stripeProviderApiKey)
    });
  }

  render() {
    const {
      playbook,
      cartOption,
      extras,
      selectedExtras,
      totalAmount,
      requestedStartDate,
      customerDetails,
      playbookExtrasIds,
      stripeProviderApiKey,
      actualAmount,
      projectNotes,
      settings
    } = this.props.location.state;

    const styles = {
      // styles that are applied to the body from the admin
      backgroundColor: this.state.bgColor
    };

    return (
      <div className="marketplace-landing" style={styles}>
        <div className="sub-navigation" style={{ cursor: "default" }}>
          <Container>
            <Col>
              <p
                style={{ width: "70px", cursor: "pointer" }}
                onClick={() => {
                  const { goBack } = this.props.history;
                  return goBack();
                }}
                className="text-link d-flex align-items-center"
              >
                <ArrowLeft /> Back
              </p>
            </Col>
          </Container>
        </div>
        <Container>
          <Row>
            <Col md="9">
              <div className="site-maincontent mt-3">
                <MarketplaceSection title={"Place a Refundable Deposit"}>
                  <div className="marketplace-section-content">
                    <div className="cart-item">
                      <p>
                        Our Expert matching process is highly customized, so
                        we’ll need to know you’re serious before we begin. Your
                        credit card will be charged a $100 deposit that will
                        either be credited to your first invoice (if you hire)
                        or refunded in full (if you don’t hire). Once the Expert
                        has been sourced and approved by you, you will get an
                        invoice in order to fully activate and schedule the
                        project.
                      </p>
                    </div>
                    <div className={["playbook-checkout"].join("")}>
                      <span className="h5 mr-3 d-block">
                        <b>Billing Address</b>
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          <StripeProvider
                            stripe={this.state.stripe}
                            apiKey={stripeProviderApiKey}
                          >
                            <MyStoreCheckout
                              playbook={playbook}
                              cartOption={cartOption}
                              totalAmount={totalAmount}
                              requestedStartDate={requestedStartDate}
                              customerDetails={customerDetails}
                              playbookExtrasIds={playbookExtrasIds}
                              actualAmount={actualAmount}
                              projectNotes={projectNotes}
                              settings={settings}
                            />
                          </StripeProvider>
                        </div>
                      </div>
                    </div>
                  </div>
                </MarketplaceSection>
              </div>
            </Col>
            <Col md="3">
              <div className="site-sidebar checkout-sidebar mt-3 pt-4">
                <DepositSummary playbook={playbook} cartOption={cartOption} />
                <CartSummary
                  classnames="transparent-box"
                  btnHidden={true}
                  playbook={playbook}
                  cartOption={cartOption}
                  extras={extras}
                  selectedExtras={selectedExtras}
                  totalAmount={totalAmount}
                  requestedStartDate={requestedStartDate}
                  projectNotes={projectNotes}
                  history={this.props.history}
                  settings={settings}
                />
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}
