import React, { Component } from "react";

// Components
import Form from "../../../components/FormWithErrorHandling";
import Text from "../../../components/base/Text";
import Select from "../../../components/base/Select";
import DragAndDropField from "../../../components/base/DragAndDropField";
import Hint from "../../../components/base/Hint";
import TextArea from "../../../components/base/TextArea";
import toast from "../../../components/base/Toast";
import SaveButton from "../../../components/base/SaveButton";
import CancelButton from "../../../components/base/CancelButton";
import { faExternalLinkSquareAlt } from "@fortawesome/free-solid-svg-icons";

// Constants
import {
  TAB_BASICS,
  COURSE_DRAFT,
  COURSE_SINGLE_COURSE,
  COURSE_PROGRESSIVE_COURSE,
  COURSE_LESSON
} from "../../../course/Constants";

// API call
import { apiClient } from "../../../apiClient";

// Configs
import { endpoints } from "../../../configs";

// Assets
import { PlusIcon } from "../../../assets/img/icons";
import { isBadRequest } from "../../../common/http";
import CourseQuestionAnswer from "./CourseQuestionAnswer";
import MultiDragDrop from "../../components/MultiDragDrop";
import ReactPlayer from "react-player";
import Video from "../../../lib/Video";
import { Link } from "react-router-dom";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VideoPlayer from "../../../components/VideoPlayer";

export default class EditLessonModuleModal extends Component {
  constructor(props) {
    super(props);
    const { questions } = this.props;
    this.state = {
      lessonTitle: "",
      textElement: "",
      multimediaUrl: "",
      quizTitle: "",
      quizType: "",
      question: "",
      answer: "",
      alphabet: "A",
      answerIds: "",
      questionIds: "",
      isSubmit: false,
      status: COURSE_DRAFT,
      isErrors: false,
      isSubmitting: false,
      loading: false,
      activeTab: TAB_BASICS,
      courseId: "",
      fileTypeError: false,
      currentQuestionData: "",
      openDeleteModal: false,
      checkBox: {
        partnerTier:
          (props.checkboxFieldValue && props.checkboxFieldValue.partnerTier) ||
          [],
        partnerType:
          (props.checkboxFieldValue && props.checkboxFieldValue.partnerType) ||
          [],
        courseRequirement: props.courseToUnlock
          ? COURSE_PROGRESSIVE_COURSE
          : COURSE_SINGLE_COURSE
      },
      correctAnswers: {},
      values:
        questions && questions.length
          ? [...questions]
          : [{ id: "1", question: null, answer: null }],
      questions: {},
      answerField: [{ answer1: null, id: 1, questionId: 1, orderId: 0 }],
      partnerTierList: [],
      partnerTypeList: [],
      questionCount: 1,
      answerCount: 1
    };
  }

  componentDidMount() {
    const correctAnswers = {};

    if (this.state.values && this.state.values.length > 0) {
      for (let i = 0; i < this.state.values.length; i++) {
        if (
          this.state.values[i].questionAnswer &&
          this.state.values[i].questionAnswer.length > 0
        ) {
          for (
            let index = 0;
            index < this.state.values[i].questionAnswer.length;
            index++
          ) {
            let data = this.state.values[i].questionAnswer[index];

            if (data.correct_answer === 1) {
              correctAnswers[
                `correctAnswer-${data.question_id}`
              ] = `${data.id}`;
            }
          }
        }
      }
    }

    this.setState({ correctAnswers });
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.lessonData &&
      prevProps.lessonData.length >= 0 &&
      JSON.stringify(prevProps.lessonData) !==
        JSON.stringify(this.props.lessonData)
    ) {
      this.setState({ values: this.props.lessonData });
      this.props.questionValues(this.state.values);
    }
  }

  getSelectedQuestionAnswer = (answerField, i) => {
    var filteredList = answerField.filter(item => {
      return item.questionId === i + 1;
    });
    return filteredList.length > 0
      ? filteredList[filteredList.length - 1].orderId
      : -1;
  };

  // Add lesson field
  addField = event => {
    event.preventDefault();
    const id = `${this.state.questionCount + 1}`;
    const values = [...this.state.values];
    values.push({
      id,
      questionId: parseInt(id),
      question: null,
      answer1: null
    });

    const answerId = this.state.answerCount;
    const answerField = [...this.state.answerField];
    const orderId = this.getSelectedQuestionAnswer(
      answerField,
      parseInt(id) - 1
    );

    answerField.push({
      answer: null,
      id: answerId,
      questionId: parseInt(id),
      orderId: orderId + 1
    });
    this.setState({ answerField });
    this.setState({ values, questionCount: this.state.questionCount + 1 });
  };

  // Handle remove lesson
  handleRemove = i => {
    const values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  };

  // Handle change lesson
  handleChange = (i, event) => {
    const values = [...this.state.values];
    values[i].question = event.target.value;
    this.setState({ values });
    this.props.questionValues(values);
  };

  handleCorrectAnswer = (questionId, correctAnswerId) => {
    this.setState({
      correctAnswers: {
        ...this.state.correctAnswers,
        [`correctAnswer-${questionId}`]: `${correctAnswerId}`
      }
    });
  };

  // Question delete api call
  handleQuestionDelete = questionId => {
    var removeIndex = this.props.currentLessonData.lessonQuestion.findIndex(
      item => item.id === questionId
    );
    this.props.currentLessonData.lessonQuestion.splice(removeIndex, 1);
    return apiClient
      .delete(`${endpoints().courseAPI}/question/${questionId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.props.getBasicsDetails();
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Answer delete api call
  handleAnswerDelete = (answerId, questionId) => {
    const lessonId =
      this.props.currentLessonData && this.props.currentLessonData.id;
    return apiClient
      .delete(
        `${
          endpoints().courseAPI
        }/answer/${answerId}?questionId=${questionId}&lessonId=${lessonId}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  _handleDeleteMediaFile = id => {
    var removeIndex = this.props.mediaFiles.findIndex(item => item.id === id);
    this.props.mediaFiles.splice(removeIndex, 1);
    return apiClient
      .delete(`${endpoints().courseAPI}/lessonmedia/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.props.getBasicsDetails();
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  setSubmitState = () => {
    this.setState({ isSubmit: true });
  };

  reSetSubmitState = () => {
    this.setState({ isSubmit: false });
  };

  // Handle remove lesson
  handleRemove = i => {
    const values = [...this.state.values];
    values.splice(i, 1);
    this.setState({ values });
  };

  render() {
    const {
      count,
      isOpen,
      toggle,
      initialValues,
      quizTypeCategory,
      _lessonSubmit,
      onMediaFileChange,
      mediaFiles,
      questions
    } = this.props;
    const data = {
      lessonId: count || "",
      lessonTitle: (initialValues && initialValues.lessonTitle) || "",
      textElement: (initialValues && initialValues.textElement) || "",
      multimediaUrl: (initialValues && initialValues.multimediaUrl) || "",
      quizTitle: (initialValues && initialValues.quizTitle) || "",
      quizTypeCategory: (initialValues && initialValues.quizTypeCategory) || "",
      ...initialValues
    };

    const { values, isSubmit } = this.state;
    return (
      <>
        {isOpen === true && (
          <Form
            initialValues={data}
            enableReinitialize={true}
            onSubmit={values => {
              values = { ...values, ...this.state.correctAnswers };
              _lessonSubmit(values);
            }}
            isSubmit={isSubmit}
            reSetSubmitState={this.reSetSubmitState}
            handleOnButtonSubmit={true}
          >
            <div className="form-wrapper">
              <input
                value={count}
                defaultValue={count}
                name="lessonId"
                hidden
              />
              <label className="font-weight-bold">Lesson Module {count}</label>
              <div className="field-wrapper mt-3">
                <Text
                  name="lessonTitle"
                  label="Lesson Title"
                  placeholder="Enter lesson title"
                  autoFocus={true}
                />
              </div>

              {/* Seperator */}
              <hr />
              <div className="pt-3 ">
                <label className="font-weight-bold">Text Element</label>
              </div>
              <div className="field-wrapper">
                <TextArea
                  name="textElement"
                  label="Add Text"
                  placeholder="Enter Text"
                />
              </div>

              {/* Seperator */}
              <hr />

              <div className="pt-3">
                <label className="font-weight-bold">Multimedia</label>
              </div>
              <div className="field-wrapper pt-3">
                <Text
                  name="multimediaUrl"
                  label="URL of Existing Multimedia"
                  placeholder="Enter multimedia url"
                />
                <div className="flex-shrink-1 bd-highlight mt-4 pt-1">
                  {data && data.multimediaUrl && (
                    <a
                      target="_blank"
                      href={data.multimediaUrl}
                      rel="noreferrer"
                    >
                      <FontAwesomeIcon
                        className="text-secondary"
                        icon={faExternalLinkSquareAlt}
                        style={{
                          padding: "4px",
                          borderRadius: "4px",
                          fontSize: "40px"
                        }}
                      />
                    </a>
                  )}
                </div>
              </div>

              <div>
                <Hint
                  id="expert-roles"
                  hintText="Specify a URL where the item is available."
                />
              </div>
              <VideoPlayer url={data.multimediaUrl} />

              <div className="form-wrapper mt-4">
                <label>Add Multimedia</label>
                <div className="flex-column">
                  <MultiDragDrop
                    name={"course_lesson"}
                    btnLabel={"Upload File"}
                    minHeight={"220px"}
                    uploadLabel={"Drag & Drop Files to Upload"}
                    objectId={data && data.lessonId}
                    object={COURSE_LESSON}
                    getMediaUrl={`${endpoints().courseAPI}/courseMedia/${data &&
                      data.lessonId}?object=${COURSE_LESSON}&objectId=${data &&
                      data.lessonId}`}
                    mediaUploadUrl={`${endpoints().courseAPI}/uploadCourse/`}
                  />
                </div>
              </div>

              {/* Seperator */}
              <hr />

              <div className="pt-3">
                <label className="font-weight-bold">Quiz</label>
              </div>
              <div className="field-wrapper mt-3">
                <Text
                  name="quizTitle"
                  label="Quiz Title"
                  placeholder="Enter quiz title"
                />
              </div>

              <div>
                <label>Select a Quiz Type</label>
                <Select
                  name="quizTypeCategory"
                  placeholder="Select quiz type"
                  fontBolded
                  options={quizTypeCategory}
                />
              </div>
              {values &&
                values.length > 0 &&
                values.map((value, i) => (
                  <CourseQuestionAnswer
                    e={value}
                    i={i}
                    questions={questions}
                    answers={value.questionAnswer}
                    handleCorrectAnswer={this.handleCorrectAnswer}
                    handleRemove={this.handleRemove}
                    handleQuestionDelete={this.handleQuestionDelete}
                    handleAnswerDelete={this.handleAnswerDelete}
                  />
                ))}
              <div className="btn-wrapper pt-3">
                <button
                  onClick={this.addField}
                  className="btn btn-link d-inline-flex px-0 text-underline"
                >
                  <PlusIcon /> Add Another Quiz Question
                </button>
              </div>
              {/* Seperator */}
              <hr />
              <div className="upload-field d-inline-block pt-3 pb-3">
                <input
                  name="file"
                  className="form-control d-none"
                  type="file"
                  id="bannerImage"
                  placeholder="Banner Image"
                  onChange={() => {}}
                  accept="image/png,image/gif,image/jpeg"
                />
                <span className="profilePicOverlay d-block ">
                  <label htmlFor="bannerImage" className="profile-img-sm mb-0">
                    <span className="banner-image-upload-link">
                      <PlusIcon />
                      Add Another Lesson Element
                    </span>
                  </label>
                </span>
              </div>
            </div>
            <div className="btn-wrapper justify-content-center pull-left">
              <CancelButton
                onClick={() => {
                  toggle();
                  window.scrollTo(0, 0);
                }}
              />
              <SaveButton
                onClick={() => {
                  this.setSubmitState();
                  window.scrollTo(0, 0);
                }}
              />
            </div>
          </Form>
        )}
      </>
    );
  }
}
