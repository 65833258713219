import React from "react";
import { TabContent, TabPane } from "reactstrap";

// Constants
import {
  GENERAL_GENERAL_SUB_TAB,
  INTEGRATION_CRMS_SUB_TAB,
  NOTIFICATION_EMAIL_SUB_TAB,
  REFERRAL_LINKS_SUB_TAB,
  TAB_GENERAL,
  TAB_INTEGRATION,
  TAB_MARKETPLACE,
  MARKETPLACES_SUB_TAB,
  TAB_NOTIFICATION,
  TAB_PAYMENTS,
  TAB_REFERRALS,
  TAB_SCHEDULER,
  TAB_EXPERT,
  TAB_THEME,
  THEME_GENERAL_SUB_TAB,
  TAB_PARTNER_ONBOARDING,
  PARTNER_ONBOARDING_SUB_TAB,
  PARTNER_APPLICATIONS_TASKS_SUB_TAB,
  INTEGRATION_ACCOUNT_MAPPING_SUB_TAB,
  INTEGRATION_ACCOUNT_MAPPING_URL_TAB,
  PARTNERS_SUB_TAB,
  TAB_PARTNER_RESOURCE,
  TAB_TRAINING,
  REFERRAL_PARTNER_TIERS_SUB_TAB,
  TAB_PARTNER,
  PARTNER_PROFILE_SUB_TAB,
  TAB_APPS,
  OUTGOING_PAYMENTS_SUB_TAB,
  SETTINGS_BILL_DOT_COM_USER_ID,
  REFERRAL_PAYMENTS_SUB_TAB,
  TAB_EMAIL_TEMPLATE,
  TAB_TERMINOLOGY,
  TRAINING_GENERAL_TAB,
  TAB_ROLE,
  TAB_CERTIFICATES,
  TAB_INTENT_DATA,
  TAB_INTENT_DATAS,
  TAB_INTENT_DATA_COMPANY,
  THEME_EMAIL_SUB_TAB,
  TAB_COSELL,
  COSELL_GENERAL_TAB,
  TAB_DEAL,
  APP_CATEGORY
} from "../../../setting/Constants";

// Icon
import { ChevronRight } from "../../../assets/img/icons";

// Tab Sections
import GeneralTab from "../tabs/GeneralTab/";
import ThemeTab from "../tabs/ThemeTab";
import Partner from "../tabs/Partner";
import ReferralsTab from "../tabs/ReferralsTab/";
import PaymentsTab from "../tabs/PaymentsTab";
import NotificationTab from "../tabs/NotificationTab/";
import Scheduler from "../schedulerJob/Scheduler";
import IntegrationTab from "../tabs/IntegrationTab";
import MarketplaceTab from "../tabs/MarketplaceTab/";
import PartnerApplicationsTab from "../tabs/PartnerApplicationsTab/";
import PartnerResourceTab from "../tabs/PartnerResourceTab";
import PartnetsTab from "../tabs/PartnersTab";
import ResourcesTrainingTab from "../tabs/ResourcesTrainingTab";
import ExpertTab from "../tabs/ExpertTab";
import { getKeyValueByObject } from "../../../lib/helper";
import { SETTINGS_ENABLE_EXPERTS } from "../../../setting/Constants";
import PartnerTiersSection from "../tabs/PartnerTiersTab";
import EmailTemplate from "../tabs/EmailTemplate";
import Terminology from "../tabs/Terminology";
import Role from "../tabs/Roles";
import CertificateTab from "../tabs/CertificateTab/certificateTab";
import IntentDataTab from "../tabs/IntentData";
import CoSellTab from "../tabs/CoSellTab/index";
import Deal from "../tabs/Deal";
import AppsCategoryTab from "../tabs/AppsCategoryTab";

const TabContentSection = props => {
  const {
    activeTab,
    activeSubTab,
    activeSection,
    activeSubSection,
    adminSettings,
    saveSettings,
    themeTabSettings,
    salesforceSettings,
    billDotComSettings,
    toggleTabs,
    settings,
    history,
    AccountObjectFieldList,
    OpportunityObjectFieldList,
    connectHubspot,
    HubSpotFieldList
  } = props;
  // const currentTab = tabName => {
  //   if (tabName == "Partner Tiers") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  // const statusTab = tabName => {
  //   if (tabName === "Status") {
  //     return true;
  //   } else {
  //     return false;
  //   }
  // };
  const enableExperts = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS)
    : "";
  const isBillDotcomConnected = settings
    ? getKeyValueByObject(settings, SETTINGS_BILL_DOT_COM_USER_ID)
      ? true
      : false
    : true;
  return (
    <TabContent activeTab={activeTab}>
      {(activeTab === TAB_MARKETPLACE ||
        activeTab === TAB_PARTNER_ONBOARDING ||
        activeTab === TAB_PARTNER) &&
      activeSubTab &&
      activeSection ? (
        ""
      ) : (
        <>
          {/* Breadcrumb Start */}
          <div className="d-flex align-items-center pt-4 pb-3">
            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(
                  `/admin-settings/${TAB_GENERAL}/${GENERAL_GENERAL_SUB_TAB}`
                );
              }}
            >
              Admin Settings
            </span>
            <span
              className={`${
                activeSubTab ? "" : "text-dark font-weight-bold"
              } d-inline-flex pt-1`}
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <span
              className={
                activeSubTab ? "cursor-pointer" : "text-dark font-weight-bold"
              }
              onClick={() => {
                if (activeSubTab) history.push(`/admin-settings/${activeTab}`);
              }}
            >
              <span>{activeTab}</span>
            </span>
            {activeSubTab && (
              <>
                <span
                  className={`${
                    activeSection ? "" : "text-dark font-weight-bold"
                  } d-inline-flex pt-1`}
                  style={{ width: "15px" }}
                >
                  <ChevronRight />
                </span>
                <span
                  className={
                    activeSection
                      ? "cursor-pointer"
                      : "text-dark font-weight-bold"
                  }
                  onClick={() => {
                    if (activeSection)
                      history.push(
                        `/admin-settings/${activeTab}/${activeSubTab}`
                      );
                  }}
                >
                  <span>
                    {activeSubTab === INTEGRATION_ACCOUNT_MAPPING_URL_TAB
                      ? INTEGRATION_ACCOUNT_MAPPING_SUB_TAB
                      : activeSubTab}
                  </span>
                </span>
              </>
            )}
            {activeSection && (
              <>
                <span
                  className="text-dark font-weight-bold d-inline-flex pt-1"
                  style={{ width: "15px" }}
                >
                  <ChevronRight />
                </span>
                <span
                  onClick={e => e.preventDefault()}
                  className={"text-dark font-weight-bold"}
                >
                  <span>{activeSection}</span>
                </span>
              </>
            )}
          </div>
          {/* Breadcrumb End */}

          <h5 className=" mt-3 mb-4 text-left font-weight-bold">
            <span className="pull-right" style={{ float: "top right" }}></span>
          </h5>
        </>
      )}

      {/* General Settings Start */}
      <TabPane tabId={TAB_GENERAL}>
        <div className="tab-content-wrapper">
          <GeneralTab
            activeSubTab={activeSubTab || GENERAL_GENERAL_SUB_TAB}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            toggleTabs={toggleTabs}
            history={history}
            settings={props.settings}
            marketplaceLogoUpload={props.marketplaceLogoUpload}
            handleMarketplaceLogoRemove={props.handleMarketplaceLogoRemove}
          />
        </div>
      </TabPane>
      {/* General Settings End */}

      {/* Theme Settings Start */}
      <TabPane tabId={TAB_THEME}>
        <div className="tab-content-wrapper">
          <ThemeTab
            activeSubTab={activeSubTab || THEME_EMAIL_SUB_TAB}
            adminSettings={adminSettings}
            saveSettings={themeTabSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>
      {/* Theme Settings End */}

      {/* Partner Settings Start */}
      <TabPane tabId={TAB_PARTNER}>
        <div className="tab-content-wrapper">
          <Partner
            activeTab={activeTab}
            activeSubTab={activeSubTab || PARTNER_PROFILE_SUB_TAB}
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>
      {/* Partner Settings End */}

      {/* Referrals Settings Start */}
      <TabPane tabId={TAB_REFERRALS}>
        <div className="tab-content-wrapper">
          <ReferralsTab
            activeSubTab={activeSubTab || REFERRAL_LINKS_SUB_TAB}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>
      {/* Referrals Settings End */}
      {/* Partner Tiers Start */}
      <TabPane tabId={REFERRAL_PARTNER_TIERS_SUB_TAB}>
        <div className="tab-content-wrapper">
          <PartnerTiersSection
            activeSubTab={activeSubTab}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>
      {/* Partner Tiers End */}

      {/* Partner Applications Settings Start */}
      <TabPane tabId={TAB_PARTNER_ONBOARDING}>
        <div className="tab-content-wrapper">
          <PartnerApplicationsTab
            activeTab={activeTab}
            activeSubTab={activeSubTab || PARTNER_ONBOARDING_SUB_TAB}
            activeSection={activeSection}
            toggleTabs={toggleTabs}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
            match={props.match}
          />
        </div>
      </TabPane>
      {/* Partner Applications Settings End */}

      {/* Partners Sub tab */}
      <TabPane tabId={PARTNERS_SUB_TAB}>
        <div className="tab-content-wrapper">
          <PartnetsTab
            activeSubTab={activeSubTab || PARTNERS_SUB_TAB}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>

      {/* Apps Tab Start */}
      <TabPane tabId={TAB_APPS}>
        <div className="tab-content-wrapper">
          <AppsCategoryTab
            activeTab={activeTab}
            activeSubTab={activeSubTab || APP_CATEGORY}
            activeSection={activeSection}
            adminSettings={adminSettings}
            toggleTabs={toggleTabs}
            saveSettings={saveSettings}
            history={history}
          />
        </div>
      </TabPane>
      {/* App Tab End */}

      {/* Resource Tab Start */}
      <TabPane tabId={TAB_PARTNER_RESOURCE}>
        <div className="tab-content-wrapper">
          <PartnerResourceTab
            activeTab={activeTab}
            activeSubTab={activeSubTab}
            activeSection={activeSection}
            adminSettings={adminSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>
      {/* ResourceTab End */}

      {/* Payment Settings Start */}
      <TabPane tabId={TAB_PAYMENTS}>
        <div className="tab-content-wrapper">
          <PaymentsTab
            activeSubTab={
              isBillDotcomConnected
                ? activeSubTab || OUTGOING_PAYMENTS_SUB_TAB
                : REFERRAL_PAYMENTS_SUB_TAB
            }
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            toggleTabs={toggleTabs}
            history={history}
            isBillDotcomConnected={isBillDotcomConnected}
          />
        </div>
      </TabPane>
      {/* Payment Settings End */}

      {/* Notifications Settings Start */}
      <TabPane tabId={TAB_NOTIFICATION}>
        <div className="tab-content-wrapper">
          <NotificationTab
            activeSubTab={activeSubTab || NOTIFICATION_EMAIL_SUB_TAB}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>
      {/* Notifications Settings End */}

      {/* Marketplace Settings Start */}
      <TabPane tabId={TAB_MARKETPLACE}>
        <div className="tab-content-wrapper">
          <MarketplaceTab
            activeTab={activeTab}
            activeSubTab={activeSubTab || MARKETPLACES_SUB_TAB}
            activeSection={activeSection}
            activeSubSection={activeSubSection}
            toggleTabs={toggleTabs}
            history={history}
            match={props.match}
            settings={props.settings}
          />
        </div>
      </TabPane>

      {/* Marketplace Settings End */}

      {/* Email Template Settings Start */}
      <TabPane tabId={TAB_EMAIL_TEMPLATE}>
        <div className="tab-content-wrapper">
          <EmailTemplate
            activeTab={activeTab}
            activeSubTab={activeSubTab}
            activeSection={activeSection}
            activeSubSection={activeSubSection}
            toggleTabs={toggleTabs}
            history={history}
            match={props.match}
            settings={props.settings}
          />
        </div>
      </TabPane>
      {/* Email Template Settings End */}

      {/* Integration Settings Start */}
      <TabPane tabId={TAB_INTEGRATION}>
        <div className="tab-content-wrapper">
          <IntegrationTab
            activeSubTab={activeSubTab || INTEGRATION_CRMS_SUB_TAB}
            activeSection={activeSection}
            adminSettings={adminSettings}
            settings={settings}
            saveSettings={saveSettings}
            salesforceSettings={salesforceSettings}
            billDotComSettings={billDotComSettings}
            toggleTabs={toggleTabs}
            history={history}
            AccountObjectFieldList={AccountObjectFieldList}
            OpportunityObjectFieldList={OpportunityObjectFieldList}
            connectHubspot={connectHubspot}
            HubSpotFieldList={HubSpotFieldList}
          />
        </div>
      </TabPane>
      {/* Integration Settings End */}

      {/* Expert Settings Start */}
      {enableExperts === "true" && (
        <TabPane tabId={TAB_EXPERT}>
          <div className="tab-content-wrapper">
            <ExpertTab
              activeSubTab={activeSubTab}
              adminSettings={adminSettings}
              saveSettings={saveSettings}
              toggleTabs={toggleTabs}
              history={history}
            />
          </div>
        </TabPane>
      )}

      {/* Expert Settings End */}

      {/* Resources & Training Start */}
      <TabPane tabId={TAB_TRAINING}>
        <div className="tab-content-wrapper">
          <ResourcesTrainingTab
            activeSubTab={activeSubTab || TRAINING_GENERAL_TAB}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            toggleTabs={toggleTabs}
            history={history}
            settings={settings}
          />
        </div>
      </TabPane>

      <TabPane tabId={TAB_TERMINOLOGY}>
        <div className="tab-content-wrapper">
          <Terminology activeTab={activeTab} history={history} />
        </div>
      </TabPane>
      <TabPane tabId={TAB_ROLE}>
        <div className="tab-content-wrapper">
          <Role
            activeTab={activeTab}
            history={history}
            activeSubTab={activeSubTab}
            activeSection={activeSection}
            toggleTabs={toggleTabs}
          />
        </div>
      </TabPane>
      <TabPane tabId={TAB_CERTIFICATES}>
        <div className="tab-content-wrapper">
          <CertificateTab
            history={history}
            match={props.match}
            settings={props.settings}
          />
        </div>
      </TabPane>
      <TabPane tabId={TAB_COSELL}>
        <div className="tab-content-wrapper">
          <CoSellTab
            activeSubTab={activeSubTab || COSELL_GENERAL_TAB}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            activeSection={activeSection}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>

      <TabPane tabId={TAB_INTENT_DATA}>
        <div className="tab-content-wrapper">
          <IntentDataTab
            history={history}
            match={props.match}
            settings={props.settings}
            activeSubTab={activeSubTab || TAB_INTENT_DATA_COMPANY}
            toggleTabs={toggleTabs}
          />
        </div>
      </TabPane>
      <TabPane tabId={TAB_DEAL}>
        <div className="tab-content-wrapper">
          <Deal
            history={history}
            match={props.match}
            settings={props.settings}
            activeSubTab={activeSubTab || TAB_DEAL}
            toggleTabs={toggleTabs}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
          />
        </div>
      </TabPane>
      {/* Resources & Training End */}
    </TabContent>
  );
};

export default TabContentSection;
