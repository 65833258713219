import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { StripeProvider } from "react-stripe-elements";

// Assets
import { CreditCardIcon, PaypalIcon } from "../../assets/img/icons";

// API Client
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Constants
import { SETTINGS_STRIPE_PROVIDER_API_KEY } from "../../setting/Constants";

// Modals
import AddCreditCardModal from "./modals/AddCreditCardModal";
import AddACHModal from "./modals/AddACHModal";

// Components
import useToggle from "../../components/customhooks/useToggle";
import WithDefaultWrapper from "../../components/WithDefaultWrapper";
import { isBadRequest } from "../../common/http";

const WithModalAddPaymentOptions = ({
  isModal,
  isOpen,
  toggle,
  children,
  className
}) =>
  isModal ? (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={["edit-task-modal", "edit-billing-modal", `${className}`].join(
        " "
      )}
    >
      <ModalHeader toggle={toggle} tag={"div"}>
        <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
          Add Payment Options
        </h4>
      </ModalHeader>
      <ModalBody>{children}</ModalBody>
    </Modal>
  ) : (
    children
  );

const AddPaymentOptions = props => {
  const [modal, setModal] = useToggle();
  const [achModal, setAchModal] = useToggle();

  const [stripeProviderApiKey, setStripeProviderApiKey] = useState(null);
  const [providerKeyLoading, setProviderKeyLoading] = useState(true);
  const [stripeKey, setStripeKey] = useState(null);

  const {
    setPaymentMethod,
    title,
    isModal = false,
    isOpen,
    toggle,
    className,
    updatePaymentMethodUi
  } = props;

  const getSettingsData = settingName => {
    return apiClient
      .get(`${endpoints().settingAPI}/${settingName}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        console.error(successMessage);
        const settings = response.data.data;
        setStripeProviderApiKey(settings.value);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  useEffect(() => {
    getSettingsData(SETTINGS_STRIPE_PROVIDER_API_KEY);
  }, []);

  useEffect(() => {
    setProviderKeyLoading(false);
  }, [stripeProviderApiKey]);

  useEffect(() => {
    if (!providerKeyLoading) {
      setStripeKey(window.Stripe(stripeProviderApiKey));
    }
  }, [stripeProviderApiKey]);

  return (
    <>
      <WithModalAddPaymentOptions
        isModal={isModal}
        isOpen={isOpen}
        toggle={toggle}
        className={className}
      >
        <WithDefaultWrapper title={title}>
          <div className="payment-options mt-4">
            <div className="payment-option">
              <button
                className={["btn", "btn-plain", "btn-link"].join(" ")}
                onClick={() => {
                  setModal(true);
                  isModal && toggle();
                }}
              >
                <CreditCardIcon />
                Add Credit Card
              </button>
            </div>
            <div className="payment-option">
              <button
                className={["btn", "btn-plain", "btn-link"].join(" ")}
                onClick={() => {
                  setAchModal(true);
                  isModal && toggle();
                }}
              >
                <CreditCardIcon />
                Add ACH
              </button>
            </div>
            <div className="payment-option disabled">
              <button className={["btn", "btn-plain", "btn-link"].join(" ")}>
                <CreditCardIcon />
                Add Wire
              </button>
            </div>
            <div className="payment-option disabled">
              <button className={["btn", "btn-plain", "btn-link"].join(" ")}>
                <PaypalIcon />
                Add Paypal
              </button>
            </div>
          </div>
        </WithDefaultWrapper>
      </WithModalAddPaymentOptions>
      <StripeProvider stripe={stripeKey}>
        <AddCreditCardModal
          isOpen={modal}
          toggle={setModal}
          setPaymentMethod={setPaymentMethod}
          updatePaymentMethodUi={updatePaymentMethodUi}
        />
      </StripeProvider>
      <StripeProvider stripe={stripeKey}>
        <AddACHModal
          isOpen={achModal}
          toggle={setAchModal}
          setPaymentMethod={setPaymentMethod}
          updatePaymentMethodUi={updatePaymentMethodUi}
        />
      </StripeProvider>
    </>
  );
};

export default AddPaymentOptions;
