import React from "react";
import moment from "moment";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import { WarningRedIcon } from "../../../../assets/img/icons";

const PartnerAccountModal = props => {
  const {
    isNoPartnerAccountModalOpen,
    setPartnerModalValues,
    createAccount
  } = props;
  return (
    <>
      <Modal
        isOpen={isNoPartnerAccountModalOpen}
        className={["log-hours-modal", "edit-task-modal"].join(" ")}
      >
        <ModalHeader
          toggle={() => {
            setPartnerModalValues({
              isNoSalesforcePartnerAccountFoundModalOpen: false
            });
          }}
        >
          <WarningRedIcon />
          <h4 className={["font-weight-bold", "mt-3"].join(" ")}>
            Partner Account was not found. Check for partial matches before
            approving.
          </h4>
        </ModalHeader>
        <ModalBody className={["text-center", "mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <h6 className="mx-4"></h6>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          className={["justify-content-center", "border-top-0"].join(" ")}
        >
          <div className="btn-wrapper">
            <button
              className="btn btn-primary px-3"
              onClick={() => {
                setPartnerModalValues({
                  isNoSalesforcePartnerAccountFoundModalOpen: false,
                  partnerInspectAccountModalOpen: true,
                  accountType: "Account (Partner)"
                });
              }}
            >
              Inspect Accounts
            </button>
            <button
              className="btn btn-success px-3"
              onClick={() => {
                setPartnerModalValues({
                  isNoSalesforcePartnerAccountFoundModalOpen: false,
                  accountType: "Account (Partner)"
                });
                createAccount("Account (Partner)");
              }}
            >
              Approve (New Account)
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default PartnerAccountModal;
