import React, { useEffect, useState } from "react";

import PageTitle from "../../components/base/PageTitle";

import MultiSelect from "../../components/base/Multiselect";

import Form from "../../components/base/Form";

import Label from "../../components/base/Label";

import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

import { endpoints } from "../../configs";

import CrossBeamService from "../../services/CrossBeamService";

import Button from "../../components/base/Button";

import { fetchList } from "../../actions/table";

import { useDispatch } from "react-redux";

import { connect } from "react-redux";

import ReferralService from "../../services/ReferralService";

import MessageService from "../../services/MessageService";

import SystemName from "../../Constants/SystemName";
import Spinner from "../../components/base/Spinner";
import Terminology from "../../lib/Terminology";
import {
  getKeyValueByObject,
  getParamsByName,
  getUrlPath,
  isCompanyAdmin,
  isCompanyManager,
  isPartner,
  isSuperAdmin
} from "../../lib/helper";
import MessageDialog from "../../views/message/MessageDialog";

import ReferralRequestService from "../../services/ReferralRequestService";

import ReferralRequestConfirmationModal from "../referral/referralRequest/components/ReferralRequestConfirmationModal";

import {
  SETTINGS_ENABLE_REFERRAL_REQUEST,
  SETTINGS_PORTAL_NAME
} from "../../setting/Constants";

import MoreDropdown from "../../components/base/MoreDropdown";

import { DropdownItem } from "reactstrap";

const Population = props => {
  let crossbeamPartnerId = getParamsByName("crossbeamPartnerId");

  let selectedAllPopulation =
    getParamsByName("selectAllPopulation") == "true" ? true : false;

  let sortParam = getParamsByName("sort");

  let sortDirc = getParamsByName("sortDir");

  const [myPopulations, setMyPopulations] = useState([]);
  const [partnerPopulation, setPartnerPopulations] = useState([]);
  const [selectedPartnerName, setSelectedPartnerName] = useState("");

  const [selectedPopulationData, setSelectedPopulationData] = useState([]);

  const [selectedMyPopulationId, setSelectedMyPopulationId] = useState(
    getParamsByName("myPopulationIds")
  );

  const [
    selectedPartnerPopulationId,
    setSelectedPartnerPopulationId
  ] = useState(getParamsByName("partnerPopulationId"));

  const [myPopulationData, setMyPopulationData] = useState([]);

  const [partnerPopulationData, setPartnerPopulationData] = useState([]);

  const [messageModalOpen, setMessageModalOpen] = useState(false);

  const [isLoading, setIsLoading] = useState(true);

  const [myPopulationLoad, setMyPopulationLoad] = useState(true);

  const [partnerDetail, setSelectedPartnerDetail] = useState(null);

  const [confirmationModal, setShowConfirmationModal] = useState(false);

  const [sort, setSort] = useState(sortParam ? sortParam : "intent_quantity");

  const [sortDir, setSortDirc] = useState(sortDirc ? sortDirc : "DESC");

  const dispatch = useDispatch();

  const marketPlaceName =
    props.settings && getKeyValueByObject(props.settings, SETTINGS_PORTAL_NAME);

  const referralRequestEnabled =
    props.settings &&
    getKeyValueByObject(props.settings, SETTINGS_ENABLE_REFERRAL_REQUEST) ==
      "true";

  useEffect(() => {
    getPartners();
    getMyPopulations();
  }, []);

  useEffect(() => {
    getPartnerPopulation();
  }, []);

  useEffect(() => {
    getPopulationReport();
  }, [selectedMyPopulationId, selectedPartnerPopulationId]);

  const getPartners = () => {
    setIsLoading(true);
    let crossbeamPartners = new Array();
    CrossBeamService.getPartners((err, response) => {
      if (response && response.data && response.data.data) {
        let partnerData = response.data.data;

        if (partnerData && partnerData.length > 0) {
          for (let i = 0; i < partnerData.length; i++) {
            crossbeamPartners.push({
              label: partnerData[i].company_name,
              value: partnerData[i].crossbeam_partner_id,
              partnerDetail: partnerData[i].partnerDetail
            });

            if (crossbeamPartnerId == partnerData[i].crossbeam_partner_id) {
              setSelectedPartnerDetail(partnerData[i].partnerDetail);
              setSelectedPartnerName(partnerData[i].company_name);
            }
          }
        }
      }
      setIsLoading(false);
    });
  };

  const getMyPopulations = () => {
    let selectedMyPopulationData = new Array();
    let selectedMyPopulationIds = new Array();

    setMyPopulationLoad(true);

    CrossBeamService.getMyPopulation((err, response) => {
      if (response && response.data && response.data.data) {
        let myPopulation = response.data.data;

        let populationIds = getParamsByName("myPopulationIds")
          ? getParamsByName("myPopulationIds").split(",")
          : [];

        if (myPopulation && myPopulation.length > 0) {
          myPopulation.forEach(data => {
            if (data.value) {
              if (
                (populationIds &&
                  populationIds.length > 0 &&
                  populationIds.indexOf(data.value.toString()) > -1) ||
                (populationIds &&
                  populationIds.length == 0 &&
                  selectedAllPopulation)
              ) {
                selectedMyPopulationData.push(data);
                if (selectedAllPopulation) {
                  selectedMyPopulationIds.push(data.value);
                }
              }
            }
          });
        }

        setMyPopulations(myPopulation);

        setMyPopulationData(selectedMyPopulationData);

        if (selectedAllPopulation) {
          setSelectedMyPopulationId(selectedMyPopulationIds);
        }
        setMyPopulationLoad(false);
      } else {
        setMyPopulationLoad(false);
      }
    });
  };

  const getPartnerPopulation = () => {
    let selectedPartnerPopulationData = new Array();
    let selectedPartnerPopulationIds = new Array();
    CrossBeamService.getPartnerPopulations(
      crossbeamPartnerId,
      (err, response) => {
        if (response && response.data && response.data.data) {
          let partnerPopulation = response.data.data;

          let partnerPopulationId = getParamsByName("partnerPopulationId")
            ? getParamsByName("partnerPopulationId").split(",")
            : [];

          if (partnerPopulation && partnerPopulation.length > 0) {
            partnerPopulation.forEach(data => {
              if (data.value) {
                if (
                  (partnerPopulationId &&
                    partnerPopulationId.length > 0 &&
                    partnerPopulationId.indexOf(data.value.toString()) > -1) ||
                  (partnerPopulationId &&
                    partnerPopulationId.length == 0 &&
                    selectedAllPopulation)
                ) {
                  selectedPartnerPopulationData.push(data);

                  if (selectedAllPopulation) {
                    selectedPartnerPopulationIds.push(data.value);
                  }
                }
              }
            });
          }

          setPartnerPopulations(partnerPopulation);

          setPartnerPopulationData(selectedPartnerPopulationData);

          if (selectedAllPopulation) {
            setSelectedPartnerPopulationId(selectedPartnerPopulationIds);
          }
        }
      }
    );
  };

  const handleBulkSelect = selectedPartner => {
    //create a new array
    let selectedPopulation = new Array();

    //validate selected id length
    if (
      selectedPartner &&
      selectedPartner.length > 0 &&
      props.crossbeamTableData &&
      props.crossbeamTableData.length > 0
    ) {
      //loop the selected ids
      selectedPartner.forEach(accountId => {
        let populationDetail = props.crossbeamTableData.find(
          data => data.id == accountId
        );

        if (populationDetail) {
          //push the data
          selectedPopulation.push({
            name: populationDetail.accountName,
            partnerName: selectedPartnerName,
            ownerEmail: populationDetail.ownerEmail,
            ownerName: populationDetail.ownerName,
            accountWebsite: populationDetail.accountWebsite
          });
        }
      });
      //set the selected population
      setSelectedPopulationData(selectedPopulation);
    } else {
      //set selected population data
      setSelectedPopulationData([]);
    }
  };

  const onClickSubmitHandler = () => {
    ReferralService.bulkCreate({ referralData: selectedPopulationData });
  };

  const myPopulationOnChange = data => {
    let myPopulationIds = new Array();
    if (data && data.value) {
      let selectedPopulationId = data.value;
      if (selectedPopulationId && selectedPopulationId.length > 0) {
        selectedPopulationId.forEach(data => {
          myPopulationIds.push(data.value);
        });
        setSelectedMyPopulationId(myPopulationIds.toString());
      } else {
        setSelectedMyPopulationId(myPopulationIds);
      }
    }
  };

  const partnerPopulationOnChange = data => {
    let partnerPopulationIds = new Array();
    if (data && data.value) {
      let partnerPopulationId = data.value;
      if (partnerPopulationId && partnerPopulationId.length > 0) {
        partnerPopulationId.forEach(data => {
          partnerPopulationIds.push(data.value);
        });
        setSelectedPartnerPopulationId(partnerPopulationIds.toString());
      } else {
        setSelectedPartnerPopulationId(partnerPopulationIds);
      }
    }
  };

  const getPopulationReport = () => {
    try {
      if (
        selectedMyPopulationId &&
        selectedPartnerPopulationId &&
        crossbeamPartnerId
      ) {
        const params = [];

        if (selectedMyPopulationId) {
          params.push(`myPopulationIds=${selectedMyPopulationId}`);
        }

        if (selectedPartnerPopulationId) {
          params.push(`partnerPopulationId=${selectedPartnerPopulationId}`);
        }

        if (crossbeamPartnerId) {
          params.push(`crossbeamPartnerId=${crossbeamPartnerId}`);
        }

        if (selectedAllPopulation) {
          params.push(`selectAllPopulation=${selectedAllPopulation}`);
        }

        if (sort) {
          params.push(`sort=${sort}`);
        }

        if (sortDir) {
          params.push(`sortDir=${sortDir}`);
        }

        const currentPath = getUrlPath(1);

        props.history.push(`/${currentPath}?${params.join("&")}`);

        let paramsData = {
          myPopulationIds: selectedMyPopulationId,
          partnerPopulationId: selectedPartnerPopulationId,
          crossbeamPartnerId: crossbeamPartnerId,
          sort: sort,
          sortDir: sortDir
        };
        dispatch(
          fetchList(
            "crossBeamPopulaion",
            `${endpoints().crossbeamAPI}/getPopulationReport`,
            1,
            10,
            paramsData
          )
        );
      }
    } catch (err) {
      console.log(err);
    }
  };

  const onHandleAskForInroduction = () => {
    setMessageModalOpen(!messageModalOpen);
  };

  const sendMessageData = messageData => {
    try {
      let message = messageData && messageData.message;

      let accountExecutiveList = new Array();

      let bodyData = new Object();

      if (selectedPopulationData && selectedPopulationData.length > 0) {
        for (let i = 0; i < selectedPopulationData.length; i++) {
          if (
            selectedPopulationData[i] &&
            selectedPopulationData[i].ownerEmail
          ) {
            accountExecutiveList.push(selectedPopulationData[i]);
          }
        }
      }

      if (accountExecutiveList && accountExecutiveList.length > 0) {
        bodyData.accountExecutiveList = accountExecutiveList;
      }

      bodyData.message = message;

      MessageService.bulkMessage(bodyData);
    } catch (err) {
      console.log(err);
    }
  };

  const onReferralSubmit = () => {
    setShowConfirmationModal(true);
  };

  const toggle = () => {
    setShowConfirmationModal(false);
  };

  const onSubmit = () => {
    if (selectedPopulationData) {
      let body = {
        accountList: selectedPopulationData,
        partnerId: partnerDetail && partnerDetail.id
      };
      ReferralRequestService.create(body, () => {
        toggle();
      });
    }
  };

  if (isLoading || myPopulationLoad) {
    return <Spinner />;
  }

  return (
    <div>
      <div className="d-flex justify-content-between  align-items-center mb-4">
        <PageTitle label={"Populations"} />

        <div className="d-flex justify-content-between">
          {(isSuperAdmin(props.loggedInUser && props.loggedInUser.roleId) ||
            isCompanyAdmin(props.loggedInUser && props.loggedInUser.roleId) ||
            isCompanyManager(
              props.loggedInUser && props.loggedInUser.roleId
            )) &&
            referralRequestEnabled && (
              <Button
                label="Referral Request"
                className="btn btn-secondary  font-weight-bold"
                onClick={() => {
                  onReferralSubmit();
                }}
                disabled={
                  selectedPopulationData && selectedPopulationData.length == 0
                    ? true
                    : false
                }
              />
            )}
        </div>
      </div>

      <MessageDialog
        modal={messageModalOpen}
        toggle={onHandleAskForInroduction}
        handleClose={onHandleAskForInroduction}
        sendMessageData={sendMessageData}
        heading="Ask for an introduction"
        buttonLabel={"Send Request"}
      />

      <ReferralRequestConfirmationModal
        accountList={selectedPopulationData}
        openModal={confirmationModal}
        partnerDetail={partnerDetail}
        toggle={toggle}
        onSubmit={onSubmit}
      />

      <Form
        initialValues={{
          mypopulation: myPopulationData,
          partnerPopulation: partnerPopulationData
        }}
      >
        <div className={`page-heading cover`}>
          <div className="d-flex justify-content-between">
            <div className="pr-2 w-100">
              <Label>
                {marketPlaceName ? marketPlaceName : ""} Populations
              </Label>
              <MultiSelect
                name="mypopulation"
                placeholder="Select My Population"
                options={myPopulations}
                isSearchable={true}
                onInputChange={myPopulationOnChange}
              />
            </div>

            <div className="pr-2 w-100">
              <>
                <Label>
                  {selectedPartnerName ? selectedPartnerName : ""} Populations
                </Label>
                <MultiSelect
                  name="partnerPopulation"
                  placeholder="Select Partner Population"
                  options={partnerPopulation}
                  isSearchable={true}
                  onInputChange={partnerPopulationOnChange}
                />
              </>
            </div>
          </div>
        </div>
      </Form>

      <ReduxTable
        id="crossBeamPopulaion"
        apiURL={`${endpoints().crossbeamAPI}/getPopulationReport`}
        params={{
          myPopulationIds: selectedMyPopulationId,
          partnerPopulationId: selectedPartnerPopulationId,
          crossbeamPartnerId: crossbeamPartnerId,
          selectAllPopulation: selectedAllPopulation,
          pageSize: 10
        }}
        onRowClick={row => props.history.push(`/referrals/edit/${row.id}`)}
        searchDisabled
        message={`You can track the ${Terminology.get(
          SystemName.REFERRALS
        )} you have submitted here`}
        subtextMessage={`To get started tap the Submit a ${Terminology.get(
          SystemName.REFERRAL
        )} button`}
        paramsToUrl={true}
        history={props.history}
        isOnCheckIntegerValue={true}
        bulkSelect={isPartner(props.loggedInUser.roleId) ? false : true}
        onBulkSelect={e => {
          handleBulkSelect(e);
        }}
      >
        <ReduxColumn
          minWidth={"110px"}
          width={"110px"}
          isClickable="false"
          field="accountName"
          sortBy="account_name"
        >
          Account Name
        </ReduxColumn>
        <ReduxColumn
          minWidth={"150px"}
          width={"150px"}
          sortBy="partner_owner_name"
          field="partnerOwnerName"
        >
          Account Owner
        </ReduxColumn>
        <ReduxColumn
          minWidth={"150px"}
          width={"150px"}
          sortBy="intent_score"
          field="intentScore"
          className="text-center"
        >
          Intent Score
        </ReduxColumn>
        <ReduxColumn
          minWidth={"150px"}
          width={"150px"}
          sortBy="intent_quantity"
          field="intentQuantity"
          className="text-center"
        >
          Intent Quantity
        </ReduxColumn>
        <ReduxColumn
          minWidth={"100px"}
          width={"100px"}
          maxWidth={"100px"}
          field="status"
          disableOnClick
          className="action-column"
          renderField={row => (
            <div className="text-center landing-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    props.history.push({
                      pathname: `/referral/add`,
                      state: {
                        accountName: row.accountName,
                        partnerId: row.partnerId
                      }
                    });
                  }}
                >{`Submit As ${Terminology.get(
                  SystemName.REFERRAL
                )}`}</DropdownItem>
                {(isSuperAdmin(
                  props.loggedInUser && props.loggedInUser.roleId
                ) ||
                  isCompanyAdmin(
                    props.loggedInUser && props.loggedInUser.roleId
                  ) ||
                  isCompanyManager(
                    props.loggedInUser && props.loggedInUser.roleId
                  )) &&
                  referralRequestEnabled && (
                    <DropdownItem
                      onClick={() => {
                        setSelectedPopulationData([
                          {
                            name: row.accountName,
                            partnerName: selectedPartnerName,
                            ownerEmail: row.ownerEmail,
                            ownerName: row.ownerName,
                            accountWebsite: row.accountWebsite
                          }
                        ]);
                        onReferralSubmit();
                      }}
                    >
                      Referral Request
                    </DropdownItem>
                  )}
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {};
}

const mapStateToProps = state => {
  const { crossBeamPopulaion } = state.table;

  const crossbeamPopulationArray = new Array();

  if (crossBeamPopulaion && crossBeamPopulaion.isFetching == false) {
    let currentPage = crossBeamPopulaion.currentPage;
    if (currentPage > 0) {
      for (let i = 1; i <= currentPage; i++) {
        if (crossBeamPopulaion[i] && crossBeamPopulaion[i].length > 0) {
          for (let j = 0; j < crossBeamPopulaion[i].length; j++) {
            crossbeamPopulationArray.push(crossBeamPopulaion[i][j]);
          }
        }
      }
    }
  }

  return { crossbeamTableData: crossbeamPopulationArray };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(Population);
