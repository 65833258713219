import React, { Component } from "react";
import toast from "../../components/base/Toast";
import { Row } from "reactstrap";

import { TAG_TYPE_EXPERT_ROLE } from "../../tagType/Constants";
import {
  SALESFORCE_OBJECT_ACCOUNT,
  SALESFORCE_OBJECT_OPPORTUNITY
} from "../../salesforce/Constants";
import {
  SETTINGS_THEME_LAYOUT,
  THEME_LAYOUT_FLUID_WIDTH
} from "../../setting/Constants";

// Inner Components
import SideNavBar from "./inner-component/SideNavBar";
import TabContentSection from "./inner-component/TabContentSection";

// Helper
import { getKeyValueByObject } from "../../lib/helper";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Library
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

// 404 Page
import Page404 from "../Page404";
import { isBadRequest } from "../../common/http";
import {
  SETTINGS_PORTAL_FROM_EMAIL,
  SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME
} from "../../setting/Constants";
import { connect } from "react-redux";

class Settings extends Component {
  constructor(props) {
    super(props);
    this.state = {
      settingsData: "",
      isLoading: true,
      isSettingsLoading: true,
      isAccountManagerListLoading: true,
      AccountObjectFieldList: [],
      OpportunityObjectFieldList: [],
      HubSpotFieldList: ""
    };
  }

  componentDidMount() {
    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    ) {
      this.getSettingsData(true);
      this.getDefaultAccountManagerList();
      this.getSlackChannelList();
      this.getTagValues(TAG_TYPE_EXPERT_ROLE);
      this.getSalesforceObjectFieldList(SALESFORCE_OBJECT_ACCOUNT);
      this.getSalesforceObjectFieldList(SALESFORCE_OBJECT_OPPORTUNITY);
      this.getHubSpotObjectFieldList();
    } else {
      this.setState({
        isPageNotFound: true
      });
    }
  }

  getSalesforceObjectFieldList = ObjectName => {
    apiClient
      .get(`${endpoints().salesforceAPI}/fieldList/${ObjectName}`)
      .then(response => {
        if (response && response.data) {
          if (ObjectName === "Account") {
            this.setState({ AccountObjectFieldList: response.data });
          } else if (ObjectName === "Opportunity") {
            this.setState({ OpportunityObjectFieldList: response.data });
          }
        }
      });
  };

  getHubSpotObjectFieldList = () => {
    apiClient.get(`${endpoints().hubSpotRoute}/fieldList`).then(response => {
      if (response && response.data) {
        this.setState({ HubSpotFieldList: response.data });
      }
    });
  };

  toggle = (tab, subtab = "") => {
    const activeTab = this.props.match.params.tab;
    const activeSubTab = this.props.match.params.subtab;
    const activeSection = this.props.match.params.section;
    const activeSubSection = this.props.match.params.subsection;
    if (
      activeTab !== tab ||
      activeSubTab !== subtab ||
      activeSection ||
      activeSubSection
    ) {
      this.props.history.push(
        `/admin-settings/${tab}${subtab ? "/" + subtab : ""}`
      );
    }
  };

  // Get Settings Data
  getSettingsData = () => {
    this.setState({ isSettingsLoading: true }, () => {
      return apiClient
        .get(`${endpoints().settingAPI}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);
          const settings = response.data.data;
          if (settings) {
            settings.forEach(data => {
              const name = data.name;
              const value = data.value;
              this.setState({ [name]: value });
            });
          }
          this.setState({
            isSettingsLoading: false,
            marketplace_logo_url: this.state.marketplace_logo,
            marketplace_fav_icon_url: this.state.marketplace_fav_icon,
            background_image_url: this.state.background_image,
            expert_signup_hero_image_url: this.state.expert_signup_hero_image,
            partner_signup_hero_image_url: this.state.partner_signup_hero_image,
            marketplace_left_navigation_background_image_url: this.state
              .marketplace_left_navigation_background_image,
            customer_signup_hero_image_url: this.state
              .customer_signup_hero_image,
            email_logo_url: this.state.email_logo,
            email_background_banner_url: this.state.email_background_banner,
            marketplace_logo: "",
            marketplace_fav_icon: "",
            background_image: "",
            customer_signup_hero_image: "",
            partner_signup_hero_image: "",
            expert_signup_hero_image: "",
            marketplace_left_navigation_background_image: "",
            email_logo: "",
            email_background_banner: ""
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
              this.setState({ isInvalidRequest: true });
            }
            console.error(errorMessage);
          }
        });
    });
  };

  // Get default account manager user list
  getDefaultAccountManagerList = () => {
    this.setState({ isAccountManagerListLoading: true }, () => {
      return apiClient
        .get(`${endpoints().settingAPI}/account/manager/list`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);
          const results = response.data.data;
          let defaultAccountManagerList = [];
          if (results) {
            results.forEach(data => {
              defaultAccountManagerList.push({
                label: data.name,
                value: data.id
              });
            });
          }
          this.setState({
            defaultAccountManagerList,
            isAccountManagerListLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  };

  // Get slack channel list
  getSlackChannelList = () => {
    this.setState({ isLoading: true }, () => {
      return apiClient
        .get(`${endpoints().settingAPI}/slack/channel/list`)
        .then(response => {
          const results =
            (response && response.data && response.data.channels) || [];
          let slackChannelList = [];
          if (results) {
            results.forEach(data => {
              slackChannelList.push({
                label: data.name,
                value: data.id
              });
            });
          }
          this.setState({
            slackChannelList,
            isLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            this.setState({
              isLoading: false
            });
            console.error(errorMessage);
          }
        });
    });
  };

  // Get Tag Values
  getTagValues = tagType => {
    return apiClient
      .get(`${endpoints().tagAPI}?tagType=${tagType}`)
      .then(response => {
        let expertRoles = [];
        if (tagType === TAG_TYPE_EXPERT_ROLE) {
          const expertRoleList = response.data.data;
          expertRoleList.forEach(expertRole => {
            expertRoles.push({ label: expertRole.name, value: expertRole.id });
          });
        }
        this.setState({
          expertRoleList: expertRoles,
          loading: true
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Settings API post
  _createSettings = data => {
    // Marketplace logo URL
    if (this.state.marketplace_logo_url !== undefined) {
      data.append(
        "marketplace_logo_url",
        this.state.marketplace_logo_url ? this.state.marketplace_logo_url : ""
      );
    }

    // Marketplace favicon file
    if (this.state.marketplaceLogoFile !== undefined) {
      data.append("marketplace_logo", this.state.marketplaceLogoFile);
    }
    return apiClient
      .post(endpoints().settingAPI, data)
      .then(() => {
        toast.success("Settings saved successfully");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Connect salesforce
  _connectSalesforce() {
    return apiClient
      .get(`${endpoints().settingAPI}/salesforce/auth`)
      .then(response => {
        let successMessage;

        if (response && response.data) {
          successMessage = response.data.message;
          window.location = response.data.redirectTo;
        }
      })

      .catch(error => {
        if (
          isBadRequest(error) &&
          error.response &&
          error.response &&
          error.response.data
        ) {
          toast.error(error.response.data);
          console.error(error.response.data);
        }
      });
  }

  // Connect Bill.com
  _connectBillDotCom = data => {
    return apiClient
      .post(`${endpoints().billDotComAPI}/connect`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        successMessage && toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Connect salesforce
  _connectHubspot() {
    return apiClient
      .get(`${endpoints().settingAPI}/hubspot/auth`)
      .then(response => {
        let successMessage;

        if (response && response.data) {
          successMessage = response.data.message;
          window.location = response.data.redirectTo;
        }
      })

      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Marketplace Logo Upload
  marketplaceLogoUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          marketplace_logo_url: URL.createObjectURL(files),
          marketplace_logo: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "marketplaceLogoFile")
      );
  };

  // Marketplace Logo Remove
  handlemarketplaceLogoRemove = () => {
    this.setState({
      marketplace_logo_url: "",
      marketplace_logo: "",
      marketplaceLogoFile: ""
    });
  };

  // Marketplace FavIcon Upload
  marketplaceFavIconUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          marketplace_fav_icon_url: URL.createObjectURL(files),
          marketplace_fav_icon: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "marketplaceFavIconFile")
      );
  };

  // Marketplace FavIcon Remove
  handleMarketplaceFavIconRemove = () => {
    this.setState({
      marketplace_fav_icon_url: "",
      marketplace_fav_icon: "",
      marketplaceFavIconFile: ""
    });
  };

  // Marketplace Logo Upload
  backgroundImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          background_image_url: URL.createObjectURL(files),
          background_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "backgroundImageFile")
      );
  };

  // Background Image Remove
  handleBackgroundImageRemove = () => {
    this.setState({
      background_image_url: "",
      background_image: "",
      backgroundImageFile: ""
    });
  };

  // Expert signup hero image upload
  expertSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          expert_signup_hero_image_url: URL.createObjectURL(files),
          expert_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "expertSignupHeroImageFile")
      );
  };

  // Expert signup hero image remove
  handleExpertSignupHeroImageRemove = () => {
    this.setState({
      expert_signup_hero_image_url: "",
      expert_signup_hero_image: "",
      expertSignupHeroImageFile: ""
    });
  };

  // Customer signup hero image upload
  customerSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          customer_signup_hero_image_url: URL.createObjectURL(files),
          customer_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "customerSignupHeroImageFile")
      );
  };

  // Customer signup hero image remove
  handleCustomerSignupHeroImageRemove = () => {
    this.setState({
      customer_signup_hero_image_url: "",
      customer_signup_hero_image: "",
      customerSignupHeroImageFile: ""
    });
  };

  // Partner signup hero image upload
  partnerSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          partner_signup_hero_image_url: URL.createObjectURL(files),
          partner_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "partnerSignupHeroImageFile")
      );
  };

  // Partner signup hero image remove
  handlePartnerSignupHeroImageRemove = () => {
    this.setState({
      partner_signup_hero_image_url: "",
      partner_signup_hero_image: "",
      partnerSignupHeroImageFile: ""
    });
  };

  // Partner Dashboard Default Banner image upload
  leftNavigationBackgroundImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          marketplace_left_navigation_background_image_url: URL.createObjectURL(
            files
          ),
          marketplace_left_navigation_background_image: URL.createObjectURL(
            files
          )
        },
        () => this.setBase64Image(files, "leftNavigationBackgroundImageFile")
      );
  };

  // Partner Dashboard Default Banner image remove
  handleLeftNavigationBackgroundImageRemove = () => {
    this.setState({
      marketplace_left_navigation_background_image_url: "",
      marketplace_left_navigation_background_image: "",
      leftNavigationBackgroundImageFile: ""
    });
  };

  // Email Logo Upload
  emailLogoUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          email_logo_url: URL.createObjectURL(files),
          email_logo: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "emailLogoFile")
      );
  };

  // Email Logo Remove
  handleEmailLogoRemove = () => {
    this.setState({ email_logo_url: "", email_logo: "", emailLogoFile: "" });
  };

  // Email Background Banner Upload
  emailBackgroundBannerUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          email_background_banner_url: URL.createObjectURL(files),
          email_background_banner: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "emailBackgroundBannerFile")
      );
  };

  // Email Background Banner Remove
  handleEmailBackgroundBannerRemove = () => {
    this.setState({
      email_background_banner_url: "",
      email_background_banner: "",
      emailBackgroundBannerFile: ""
    });
  };

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Marketplace Logo Upload
  marketplaceLogoUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          marketplace_logo_url: URL.createObjectURL(files),
          marketplace_logo: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "marketplaceLogoFile")
      );
  };

  // Marketplace Logo Remove
  handlemarketplaceLogoRemove = () => {
    this.setState({
      marketplace_logo_url: "",
      marketplace_logo: "",
      marketplaceLogoFile: ""
    });
  };

  // Marketplace FavIcon Upload
  marketplaceFavIconUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          marketplace_fav_icon_url: URL.createObjectURL(files),
          marketplace_fav_icon: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "marketplaceFavIconFile")
      );
  };

  // Marketplace FavIcon Remove
  handleMarketplaceFavIconRemove = () => {
    this.setState({
      marketplace_fav_icon_url: "",
      marketplace_fav_icon: "",
      marketplaceFavIconFile: ""
    });
  };

  // Marketplace Logo Upload
  backgroundImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          background_image_url: URL.createObjectURL(files),
          background_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "backgroundImageFile")
      );
  };

  // Background Image Remove
  handleBackgroundImageRemove = () => {
    this.setState({
      background_image_url: "",
      background_image: "",
      backgroundImageFile: ""
    });
  };

  // Expert signup hero image upload
  expertSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          expert_signup_hero_image_url: URL.createObjectURL(files),
          expert_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "expertSignupHeroImageFile")
      );
  };

  // Expert signup hero image remove
  handleExpertSignupHeroImageRemove = () => {
    this.setState({
      expert_signup_hero_image_url: "",
      expert_signup_hero_image: "",
      expertSignupHeroImageFile: ""
    });
  };

  // Customer signup hero image upload
  customerSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          customer_signup_hero_image_url: URL.createObjectURL(files),
          customer_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "customerSignupHeroImageFile")
      );
  };

  // Customer signup hero image remove
  handleCustomerSignupHeroImageRemove = () => {
    this.setState({
      customer_signup_hero_image_url: "",
      customer_signup_hero_image: "",
      customerSignupHeroImageFile: ""
    });
  };

  // Partner signup hero image upload
  partnerSignupHeroImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          partner_signup_hero_image_url: URL.createObjectURL(files),
          partner_signup_hero_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "partnerSignupHeroImageFile")
      );
  };

  // Partner signup hero image remove
  handlePartnerSignupHeroImageRemove = () => {
    this.setState({
      partner_signup_hero_image_url: "",
      partner_signup_hero_image: "",
      partnerSignupHeroImageFile: ""
    });
  };

  // Partner Dashboard Default Banner image upload
  leftNavigationBackgroundImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          marketplace_left_navigation_background_image_url: URL.createObjectURL(
            files
          ),
          marketplace_left_navigation_background_image: URL.createObjectURL(
            files
          )
        },
        () => this.setBase64Image(files, "leftNavigationBackgroundImageFile")
      );
  };

  // Partner Dashboard Default Banner image remove
  handleLeftNavigationBackgroundImageRemove = () => {
    this.setState({
      marketplace_left_navigation_background_image_url: "",
      marketplace_left_navigation_background_image: "",
      leftNavigationBackgroundImageFile: ""
    });
  };

  // Email Logo Upload
  emailLogoUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    files &&
      this.setState(
        {
          email_logo_url: URL.createObjectURL(files),
          email_logo: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, "emailLogoFile")
      );
  };

  // Email Logo Remove
  handleEmailLogoRemove = () => {
    this.setState({ email_logo_url: "", email_logo: "", emailLogoFile: "" });
  };

  // Handle theme tab submit
  _handleThemeSubmit = values => {
    const data = new FormData();

    // if (values.marketplace_talent_name !== undefined) {
    //   data.append(
    //     "marketplace_talent_name",
    //     values.marketplace_talent_name ? values.marketplace_talent_name : ""
    //   );
    // }

    if (values.legal_disclosure !== undefined) {
      data.append(
        "legal_disclosure",
        values.legal_disclosure ? values.legal_disclosure : ""
      );
    }

    // Marketplace logo rediect URL
    if (values.marketplace_logo_redirect_url !== undefined) {
      data.append(
        "marketplace_logo_redirect_url",
        values.marketplace_logo_redirect_url
          ? values.marketplace_logo_redirect_url
          : ""
      );
    }

    // Expert signup logo URL
    if (this.state.expert_signup_hero_image_url !== undefined) {
      data.append(
        "expert_signup_hero_image_url",
        this.state.expert_signup_hero_image_url
          ? this.state.expert_signup_hero_image_url
          : ""
      );
    }

    if (this.state.expertSignupHeroImageFile !== undefined) {
      data.append(
        "expert_signup_hero_image",
        this.state.expertSignupHeroImageFile
      );
    }

    // Customer signup logo URL
    if (this.state.customer_signup_hero_image_url !== undefined) {
      data.append(
        "customer_signup_hero_image_url",
        this.state.customer_signup_hero_image_url
          ? this.state.customer_signup_hero_image_url
          : ""
      );
    }

    if (this.state.customerSignupHeroImageFile !== undefined) {
      data.append(
        "customer_signup_hero_image",
        this.state.customerSignupHeroImageFile
      );
    }

    // Partner signup logo URL
    if (this.state.partner_signup_hero_image_url !== undefined) {
      data.append(
        "partner_signup_hero_image_url",
        this.state.partner_signup_hero_image_url
          ? this.state.partner_signup_hero_image_url
          : ""
      );
    }

    if (this.state.partnerSignupHeroImageFile !== undefined) {
      data.append(
        "partner_signup_hero_image",
        this.state.partnerSignupHeroImageFile
      );
    }

    // Left Navigation Background Image URL
    if (
      this.state.marketplace_left_navigation_background_image_url !== undefined
    ) {
      data.append(
        "marketplace_left_navigation_background_image_url",
        this.state.marketplace_left_navigation_background_image_url
          ? this.state.marketplace_left_navigation_background_image_url
          : ""
      );
    }

    if (this.state.leftNavigationBackgroundImageFile !== undefined) {
      data.append(
        "marketplace_left_navigation_background_image",
        this.state.leftNavigationBackgroundImageFile
      );
    }

    // Marketplace Header Color
    if (values.marketplace_header_color !== undefined) {
      data.append(
        "marketplace_header_color",
        values.marketplace_header_color ? values.marketplace_header_color : ""
      );
    }

    // Marketplace Header Text Color
    if (values.marketplace_header_text_color !== undefined) {
      data.append(
        "marketplace_header_text_color",
        values.marketplace_header_text_color
          ? values.marketplace_header_text_color
          : ""
      );
    }

    // Marketplace Footer Color
    if (values.marketplace_footer_color !== undefined) {
      data.append(
        "marketplace_footer_color",
        values.marketplace_footer_color ? values.marketplace_footer_color : ""
      );
    }

    // Marketplace Footer Heading Color
    if (values.marketplace_footer_heading_color !== undefined) {
      data.append(
        "marketplace_footer_heading_color",
        values.marketplace_footer_heading_color
          ? values.marketplace_footer_heading_color
          : ""
      );
    }

    // Marketplace Footer Text Color
    if (values.marketplace_footer_text_color !== undefined) {
      data.append(
        "marketplace_footer_text_color",
        values.marketplace_footer_text_color
          ? values.marketplace_footer_text_color
          : ""
      );
    }

    // Marketplace Footer Copyright text
    if (values.marketplace_footer_copy_rights_text !== undefined) {
      data.append(
        "marketplace_footer_copy_rights_text",
        values.marketplace_footer_copy_rights_text
          ? values.marketplace_footer_copy_rights_text
          : ""
      );
    }

    // Terms of service url
    if (values.terms_of_service_url !== undefined) {
      data.append(
        "terms_of_service_url",
        values.terms_of_service_url ? values.terms_of_service_url : ""
      );
    }

    // Marketplace Desktop Footer Content
    if (values.marketplace_desktop_footer_content !== undefined) {
      data.append(
        "marketplace_desktop_footer_content",
        values.marketplace_desktop_footer_content
          ? values.marketplace_desktop_footer_content
          : ""
      );
    }

    // Marketplace Mobile Footer Content
    if (values.marketplace_mobile_footer_content !== undefined) {
      data.append(
        "marketplace_mobile_footer_content",
        values.marketplace_mobile_footer_content
          ? values.marketplace_mobile_footer_content
          : ""
      );
    }

    // Public Page Footer Content
    if (values.public_page_footer_content !== undefined) {
      data.append(
        "public_page_footer_content",
        values.public_page_footer_content
          ? values.public_page_footer_content
          : ""
      );
    }

    // Marketplace Left Navigation Text color
    if (values.marketplace_left_navigation_text_color !== undefined) {
      data.append(
        "marketplace_left_navigation_text_color",
        values.marketplace_left_navigation_text_color
          ? values.marketplace_left_navigation_text_color
          : ""
      );
    }
    // Marketplace Default Layout

    if (values.theme_layout !== undefined) {
      data.append(
        "theme_layout",

        values.theme_layout ? values.theme_layout.value : ""
      );
    }
    // Marketplace Left Navigation Text Hover Over color
    if (
      values.marketplace_left_navigation_text_hover_over_color !== undefined
    ) {
      data.append(
        "marketplace_left_navigation_text_hover_over_color",
        values.marketplace_left_navigation_text_hover_over_color
          ? values.marketplace_left_navigation_text_hover_over_color
          : ""
      );
    }

    // Marketplace logo URL
    if (this.state.marketplace_logo_url !== undefined) {
      data.append(
        "marketplace_logo_url",
        this.state.marketplace_logo_url ? this.state.marketplace_logo_url : ""
      );
    }

    // Marketplace favicon file
    if (this.state.marketplaceLogoFile !== undefined) {
      data.append("marketplace_logo", this.state.marketplaceLogoFile);
    }

    // Marketplace favicon URL
    if (this.state.marketplace_fav_icon_url !== undefined) {
      data.append(
        "marketplace_fav_icon_url",
        this.state.marketplace_fav_icon_url
          ? this.state.marketplace_fav_icon_url
          : ""
      );
    }

    // Marketplace favicon file
    if (this.state.marketplaceFavIconFile !== undefined) {
      data.append("marketplace_fav_icon", this.state.marketplaceFavIconFile);
    }

    // Background image URL
    if (this.state.background_image_url !== undefined) {
      data.append(
        "background_image_url",
        this.state.background_image_url ? this.state.background_image_url : ""
      );
    }

    if (this.state.backgroundImageFile !== undefined) {
      data.append("background_image", this.state.backgroundImageFile);
    }

    // Marketplace logo URL
    if (this.state.email_logo_url !== undefined) {
      data.append(
        "email_logo_url",
        this.state.email_logo_url ? this.state.email_logo_url : ""
      );
    }
    if (this.state.emailLogoFile !== undefined) {
      data.append("email_logo", this.state.emailLogoFile);
    }

    // Email background banner
    if (this.state.email_background_banner_url !== undefined) {
      data.append(
        "email_background_banner_url",
        this.state.email_background_banner_url
          ? this.state.email_background_banner_url
          : ""
      );
    }
    if (this.state.emailBackgroundBannerFile !== undefined) {
      data.append(
        "email_background_banner",
        this.state.emailBackgroundBannerFile
      );
    }

    if (values.email_primary_background_color !== undefined) {
      data.append(
        "email_primary_background_color",
        values.email_primary_background_color
          ? values.email_primary_background_color
          : ""
      );
    }

    if (values.email_primary_text_color !== undefined) {
      data.append(
        "email_primary_text_color",
        values.email_primary_text_color ? values.email_primary_text_color : ""
      );
    }

    if (values[SETTINGS_PORTAL_FROM_EMAIL] !== undefined) {
      data.append(
        SETTINGS_PORTAL_FROM_EMAIL,
        values[SETTINGS_PORTAL_FROM_EMAIL]
          ? values[SETTINGS_PORTAL_FROM_EMAIL]
          : ""
      );
    }

    if (values[SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME] !== undefined) {
      data.append(
        SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME,
        values[SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME]
          ? values[SETTINGS_PORTAL_FROM_EMAIL_DISPLAY_NAME]
          : ""
      );
    }
    // Partner invite notification email subject
    if (values.partner_invite_email_subject !== undefined) {
      data.append(
        "partner_invite_email_subject",
        values.partner_invite_email_subject
          ? values.partner_invite_email_subject
          : ""
      );
    }

    // Partner invite notification email message
    if (values.partner_invite_email_message !== undefined) {
      data.append(
        "partner_invite_email_message",
        values.partner_invite_email_message
          ? values.partner_invite_email_message
          : ""
      );
    }
    // Referral Decline notification email subject
    if (values.referral_decline_email_subject !== undefined) {
      data.append(
        "referral_decline_email_subject",
        values.referral_decline_email_subject
          ? values.referral_decline_email_subject
          : ""
      );
    }
    // Referral Decline  notification email message
    if (values.referral_decline_email_message !== undefined) {
      data.append(
        "referral_decline_email_message",
        values.referral_decline_email_message
          ? values.referral_decline_email_message
          : ""
      );
    }
    // Save settings
    this._createSettings(data);
  };

  render() {
    const settings =
      this.props.settings && this.props.settings.settings
        ? this.props.settings.settings
        : "";
    const { isLoading, isSettingsLoading, HubSpotFieldList } = this.state;

    // Page Not Found
    if (this.state.isInvalidRequest || this.state.isPageNotFound) {
      return <Page404 />;
    }

    if (isLoading || isSettingsLoading) {
      return "";
    }
    const themeTabSettings = {
      marketplaceLogoUpload: this.marketplaceLogoUpload,
      handleMarketplaceLogoRemove: this.handlemarketplaceLogoRemove,
      marketplaceFavIconUpload: this.marketplaceFavIconUpload,
      handleMarketplaceFavIconRemove: this.handleMarketplaceFavIconRemove,
      backgroundImageUpload: this.backgroundImageUpload,
      handleBackgroundImageRemove: this.handleBackgroundImageRemove,
      expertSignupHeroImageUpload: this.expertSignupHeroImageUpload,
      handleExpertSignupHeroImageRemove: this.handleExpertSignupHeroImageRemove,
      customerSignupHeroImageUpload: this.customerSignupHeroImageUpload,
      handleCustomerSignupHeroImageRemove: this
        .handleCustomerSignupHeroImageRemove,
      partnerSignupHeroImageUpload: this.partnerSignupHeroImageUpload,
      handlePartnerSignupHeroImageRemove: this
        .handlePartnerSignupHeroImageRemove,
      leftNavigationBackgroundImageUpload: this
        .leftNavigationBackgroundImageUpload,
      handleLeftNavigationBackgroundImageRemove: this
        .handleLeftNavigationBackgroundImageRemove,
      emailLogoUpload: this.emailLogoUpload,
      handleEmailLogoRemove: this.handleEmailLogoRemove,
      emailBackgroundBannerUpload: this.emailBackgroundBannerUpload,
      handleEmailBackgroundBannerRemove: this.handleEmailBackgroundBannerRemove,
      settings: this.props.settings,
      submitThemeTab: this._handleThemeSubmit
    };

    const salesforceSettings = {
      handleSalesForceConnect: () => this._connectSalesforce(),
      resetInstanceUrl: () => this.setState({ salesforce_instance_url: false })
    };

    const billDotComSettings = {
      handleBillDotComConnect: this._connectBillDotCom
    };

    const activeTab = this.props.match.params.tab;
    const activeSubTab = this.props.match.params.subtab;
    const activeSection = this.props.match.params.section;
    const activeSubSection = this.props.match.params.subsection;
    const DefaultLayout = settings
      ? getKeyValueByObject(settings, SETTINGS_THEME_LAYOUT)
      : "";
    return (
      <>
        <Row className="mt-n3 mt-md-n4 admin-setting d-flex flex-nowrap">
          <div
            className={`${
              DefaultLayout && DefaultLayout === THEME_LAYOUT_FLUID_WIDTH
                ? "col-lg-3 col-sm-5 sidemenu p-0"
                : " sidemenu"
            }`}
          >
            <SideNavBar
              activeTab={activeTab}
              handleClick={this.toggle}
              settings={this.props.settings}
            />
          </div>

          <div
            className={`${
              DefaultLayout && DefaultLayout === THEME_LAYOUT_FLUID_WIDTH
                ? "col "
                : "col col-lg-9"
            }`}
          >
            <TabContentSection
              activeTab={activeTab}
              activeSubTab={activeSubTab}
              activeSection={activeSection}
              activeSubSection={activeSubSection}
              adminSettings={this.state}
              saveSettings={this._createSettings}
              themeTabSettings={themeTabSettings}
              salesforceSettings={salesforceSettings}
              billDotComSettings={billDotComSettings}
              toggleTabs={this.toggle}
              history={this.props.history}
              match={this.props.match}
              settings={this.props.settings}
              AccountObjectFieldList={this.state.AccountObjectFieldList}
              OpportunityObjectFieldList={this.state.OpportunityObjectFieldList}
              connectHubspot={this._connectHubspot}
              HubSpotFieldList={HubSpotFieldList}
              loggedInUser={this.props.user}
              marketplaceLogoUpload={this.marketplaceLogoUpload}
              handleMarketplaceLogoRemove={this.handlemarketplaceLogoRemove}
            />
          </div>
        </Row>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(Settings);
