import React, { useEffect, useState } from "react";

// API Client
import { apiClient } from "../../apiClient";

// Configs
import { endpoints, DEFAULT_API_KEY } from "../../configs";
import { getCookie } from "../../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../../lib/cookie";

// Components
import Select from "./Select";

const Country = props => {
  const [countryOptions, setCountryOptions] = useState([]);
  const { name, label, placeholder, error, required, onChange, id } = props;
  // Get country option list
  const getCountryOptions = () => {
    const countryOptions = [];
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    apiClient.get(`${endpoints().countryAPI}/search`).then(response => {
      const countries = response.data.data;
      countries.forEach(country => {
        countryOptions.push({
          value: country.name,
          label: country.name,
          id: country.id
        });
        setCountryOptions(countryOptions);
      });
    });

    return countryOptions;
  };
  useEffect(() => {
    getCountryOptions();
  }, []);
  return (
    <Select
      id={id || name}
      name={name}
      label={label}
      options={countryOptions}
      placeholder={placeholder}
      error={error}
      required={required}
      // defaultValue={
      //   defaultValue || {
      //     label: country.UNITED_STATES,
      //     value: country.UNITED_STATES
      //   }
      // }
      onInputChange={onChange}
      isSearchable={true}
    />
  );
};

export default Country;
