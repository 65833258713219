import React from "react";
import { CSVLink } from "react-csv";

// Assets
import { DownloadIcon } from "../assets/img/icons";
import { getCurrentDate } from "../common/date";

const CSVDownload = ({ data, headers, fileName, className }) => {
  return (
    <div className={className}>
      <CSVLink
        data={data}
        headers={headers}
        filename={`${fileName}${"_"}${getCurrentDate()}.csv`}
      >
        <span className="download-pdf ml-auto d-flex" href="#">
          <DownloadIcon />
          <span className="ml-1">Export</span>
        </span>
      </CSVLink>
    </div>
  );
};

export default CSVDownload;
