import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components

import AddLandingPageBlockModal from "./AddLandingPageBlockModal";
import LandingPageBlocksDetails from "./landingPageBlocks/LandingPageBlocksDetails";

// Configs
import { endpoints } from "../../../../configs";

// Actions
import { deleteLandingPageBlock } from "../../../../actions/landingPageBlock";
import {
  MARKETPLACES_SUB_TAB,
  TAB_MARKETPLACE
} from "../../../../setting/Constants";
import toast from "../../../../components/base/Toast";
import LandingPageBlocksItem from "./landingPageBlocks/LandingPageBlocksItem";
import { apiClient } from "../../../../apiClient";
import { isNotEmpty } from "../../../../common/utils";
import { isBadRequest } from "../../../../common/http";
import Spinner from "../../../../components/base/Spinner";

class LandingPageBlocks extends React.Component {
  state = {
    landingPageBLocks: "",
    sourceLandingBlocksOrder: "",
    targetLandingBlocksOrder: "",
    isModelOpen: false,
    isLoading: false
  };
  // Landing page Blocks delete api call
  handleDelete = id => {
    apiClient
      .delete(`${endpoints().landingPageBlocksAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this.getLandingBlocksDetails();
        }
        toast.success(successMessage);
      });
  };
  _toggle = () => {
    this.setState({ isModelOpen: !this.state.isModelOpen, row: "" });
  };

  // Set Current Order Position of Selected LandingPage BLocks
  setsourceLandingBlocksOrder = landingBlock => {
    if (this.state.sourceLandingBlocksOrder !== landingBlock.sort) {
      this.setState({
        sourceLandingBlocksOrder: landingBlock.sort
      });
    }
  };

  // Set Target Order Position of Selected LandingPage BLocks
  setTargetLandingBlocksOrder = landingBlock => {
    if (this.state.targetLandingBlocksOrder !== landingBlock.sort) {
      this.setState({
        targetLandingBlocksOrder: landingBlock.sort
      });
    }
  };

  updateLandingBlocksOrder = updateLandingBlocksList => {
    this.setState({ isLoading: true });
    apiClient
      .put(
        `${endpoints().landingPageBlocksAPI}/update/sortOrder`,
        updateLandingBlocksList
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Change Order Position of Selected LandingPage BLocks
  changeLandingBlockOrder = () => {
    const { sourceLandingBlocksOrder, targetLandingBlocksOrder } = this.state;

    // Current Selected LandingPage BLocks List
    const landingPageBLocksList = this.state.landingPageBLocks;

    if (landingPageBLocksList && landingPageBLocksList.length > 0) {
      landingPageBLocksList.splice(
        targetLandingBlocksOrder,
        0,
        landingPageBLocksList.splice(sourceLandingBlocksOrder, 1)[0]
      );

      // Updated Selected LandingPage BLocks List
      const updatedLandingBlocksList = [];

      landingPageBLocksList.forEach(landingPageBLocks => {
        updatedLandingBlocksList.push({
          ...landingPageBLocks,
          // Update Selected landingPage BLocks Order
          sort: updatedLandingBlocksList.length
        });
      });

      this.setState({
        landingPageBLocks: updatedLandingBlocksList,
        sourceLandingBlocksOrder: "",
        targetLandingBlocksOrder: ""
      });

      this.updateLandingBlocksOrder(updatedLandingBlocksList);
    } else {
      // Reset Order Positions of Selected Tag Type
      this.setState({
        sourceLandingBlocksOrder: "",
        targetLandingBlocksOrder: ""
      });
    }
  };

  componentDidMount() {
    this.getLandingBlocksDetails(this.props.marketplaceId);
  }

  getLandingBlocksDetails = () => {
    this.setState({ isLoading: true });

    apiClient
      .get(
        `${endpoints().landingPageBlocksAPI}?landingPageId=${
          this.props.marketplaceId
        }`
      )
      .then(response => {
        if (response && response.data) {
          this.setState({ landingPageBLocks: response.data.data });
          this.setState({ isLoading: false });
        }
      });
  };

  render() {
    const marketplaceId = this.props.marketplaceId;
    const sectionId = this.props.sectionId;
    const params = { landingPageId: marketplaceId };
    if (!marketplaceId) return "";

    if (this.state.isLoading) {
      return <Spinner />;
    }

    return (
      <>
        {!sectionId ? (
          <>
            <div className="d-block">
              <div className="btn-wrapper">
                <AddLandingPageBlockModal
                  landingPageId={marketplaceId}
                  settings={this.props.settings}
                  sortOrder={this.state.landingPageBLocks.length}
                  getLandingBlocksDetails={this.getLandingBlocksDetails}
                />
              </div>
            </div>
            <div>
              <div className="card">
                <div className="task-list container">
                  <div
                    className="task row thead-light pr-4 pt-2 pb-1"
                    style={{ minHeight: "40px" }}
                  >
                    <div className="task-left-1 col-10">
                      <p className="pr-3 pt-3" style={{ width: "40px" }}></p>
                      <div className="name-comments mr-2 col-4">
                        {/*Landing Blocks Title*/}
                        <span className="font-weight-bold">Title</span>
                      </div>
                      <div className="name-comments align-items-left mr-2 col-8">
                        <span className="pl-2 font-weight-bold">Type</span>
                      </div>
                    </div>

                    <div
                      className="task-right pr-2 col-2"
                      style={{ paddingLeft: "45px" }}
                    >
                      {/*Show Task Actions For All Tasks Based on the Condition*/}
                      <div className="task-actions">
                        <>
                          <span
                            className={
                              "mt-1 d-flex justify-content-between font-weight-bold"
                            }
                          >
                            Action
                          </span>
                        </>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="task-list" style={{ minHeight: "70px" }}>
                  {isNotEmpty(this.state.landingPageBLocks) ? (
                    this.state.landingPageBLocks.map(landingPageBLock => (
                      <div
                        id={landingPageBLock.sort}
                        key={landingPageBLock.id}
                        draggable="true"
                        onDrag={e => {
                          this.setsourceLandingBlocksOrder(landingPageBLock);
                        }}
                        onDragOver={e => {
                          e.preventDefault();
                          this.setTargetLandingBlocksOrder(landingPageBLock);
                        }}
                        onDrop={e => {
                          this.changeLandingBlockOrder();
                        }}
                      >
                        <LandingPageBlocksItem
                          landingPageBLock={landingPageBLock}
                          history={this.props.history}
                          marketplaceId={marketplaceId}
                          handleDelete={this.handleDelete}
                          isOpen={this.state.isModelOpen}
                          toggle={this._toggle}
                          getLandingBlocksDetails={this.getLandingBlocksDetails}
                        />
                      </div>
                    ))
                  ) : (
                    <p className="text-center py-4">No records found</p>
                  )}
                </div>
              </div>
            </div>
          </>
        ) : (
          <LandingPageBlocksDetails
            marketplaceId={this.props.marketplaceId}
            marketplaceName={this.props.marketplaceName}
            slugUrl={this.props.slugUrl}
            sectionId={this.props.sectionId}
            history={this.props.history}
            settings={this.props.settings}
            match={this.props.match}
            getLandingBlocksDetails={this.getLandingBlocksDetails}
          />
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ deleteLandingPageBlock }, dispatch)
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(LandingPageBlocks);
