import React from "react";
import { Alert } from "reactstrap";

// Components
import { CrossIcon, InlineInfoIcon } from "../../../../assets/img/icons";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

const ReferralSourceBanner = props => {
  const { closeReferralSourceBanner, submittedFrom } = props;
  return (
    <>
      <div className="alerts-wrapper">
        <Alert
          color="success"
          className={
            "d-flex align-items-center justify-content-stretch text-white"
          }
        >
          <InlineInfoIcon />
          <p className="ml-3 mb-0 font-weight-bold mr-auto text-white">
            This {Terminology.get(SystemName.REFERRAL)} is synced from{" "}
            {submittedFrom}
          </p>
          <div className="close-alert mr-0 ml-0">
            <button
              className="btn-plain btn text-white p-0"
              type="button"
              onClick={() => {
                closeReferralSourceBanner();
              }}
            >
              <CrossIcon />
            </button>
          </div>
        </Alert>
      </div>
    </>
  );
};

export default ReferralSourceBanner;
