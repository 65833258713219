import React from "react";
import { Nav, NavItem } from "reactstrap";

// Component
import SideNavBarItem from "./SideNavBarItem";

// Constant
import { getAdminSettingsNavList } from "../../../setting/Constants";

import { useSelector } from "react-redux";

const SideNavBar = props => {
  const { activeTab, handleClick, settings } = props;

  const currentUser = useSelector(state => state.user);

  const adminSettingsNavList = getAdminSettingsNavList(
    settings,
    currentUser && currentUser.roleId
  );

  // Return Admin Settings Nav List
  return (
    <Nav tabs vertical pills>
      <NavItem className="p-3 pt-4">
        <h5 className="text-left font-weight-bold">Admin Settings</h5>
      </NavItem>

      {/* Nav List Start */}
      {adminSettingsNavList &&
        adminSettingsNavList.length > 0 &&
        adminSettingsNavList.map((adminSettingsNavItem, key) => (
          <SideNavBarItem
            key={key}
            activeTab={activeTab}
            tabName={adminSettingsNavItem.name}
            defaultSubTab={adminSettingsNavItem.defaultSubTab}
            handleClick={handleClick}
            label={adminSettingsNavItem.label}
          />
        ))}
      {/* Nav List End */}
    </Nav>
  );
};

export default SideNavBar;
