import React, { useState, useEffect } from "react";

// Components
import Form from "./base/Form";

//Service
import { getTagTypeByCategory } from "../tag/Service";

import detectBrowser from "./helpers/detectBrowser";

import PageSearch from "./base/PageSearch";
import LandingPageFilter from "./LandingPageFilters";
import { isEmpty } from "../lib/helper";
import { TAG_TYPE_APP_CATEGORY } from "../tagType/Constants";

const AppSideBarFilter = props => {
  const {
    appSelectedFilters,
    searchTerm,
    tagTypeList,
    id,
    landingPageBlockId
  } = props;
  const [isMobile] = useState(detectBrowser());
  const [search, setSearch] = useState("");
  const [selectedFilters, setSelectedFilters] = useState(appSelectedFilters);

  useEffect(() => {
    setSelectedFilters(props.appSelectedFilters);
  }, [props.appSelectedFilters]);

  const getLandingPageBlockTagType = () => {
    const appDetail = [];
    if (appDetail) {
      appDetail.forEach(appDetails => {
        if (appDetail.id === appDetail) {
          appDetail.push(appDetails);
        }
      });
    }
    return appDetail;
  };
  const handlechangeSubmit = (values, selectName, selectValue) => {
    //Filter Partners
    props.filterAppList(values, search, selectName, selectValue, id);
    searchTerm(search);
  };

  useEffect(() => {
    handlechangeSubmit(selectedFilters);
  }, [search]);

  const handleSearchTermChange = e => {
    setSearch(e.target.value);
  };

  const SectionBreakLine = ({ className }) => {
    return (
      <div
        className={className}
        style={{ borderBottom: "1px solid #f3f3f4" }}
      ></div>
    );
  };

  return (
    <div className={!isMobile ? `card p-3 mb-3` : `p-3`}>
      <Form
        initialValues={selectedFilters}
        enableReinitialize={true}
        onSubmit={() => {}}
      >
        <div className="row ">
          <div className="col-12 page-heading ">
            <PageSearch
              id={props.id}
              name="partner-search"
              classnames="page-search"
              placeholder="Search"
              onChange={handleSearchTermChange.bind(this)}
            />
            <SectionBreakLine className="pb-3" />
          </div>
          {props.details && (
            <>
              <LandingPageFilter
                tagTypeList={
                  isEmpty(tagTypeList)
                    ? [{ name: "Category", tags: tagTypeList }]
                    : []
                }
                displayName={"Category"}
                fieldStartName={"category"}
                multiselect={true}
                handleOnchangeSubmit={handlechangeSubmit}
              />
            </>
          )}
        </div>
      </Form>
    </div>
  );
};

export default AppSideBarFilter;
