import React from "react";

// Assets
import {
  DashboardDealRegisteredIcon,
  DashboardDealPendingIcon,
  DashboardCommissionEarnedIcon,
  DashboardCommissionProjectedIcon
} from "../../assets/img/icons";
import { getKeyValueByObject } from "../../lib/helper";
import {
  SETTINGS_ENABLE_PARTNER_PAYMENTS,
  SETTINGS_ENABLE_REFERRALS
} from "../../setting/Constants";
import { PARTNER_STATUS_APPROVED } from "../../partnerStatus/Constants";

// Components
import StatisticsCountCard from "../../components/base/StatisticsCountCard";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
import { GetCurrencySymbol } from "../../lib/CurrencySymbol";

const PartnerStatisticCards = props => {
  const {
    submittedReferralCount,
    acceptedReferralCount,
    earnedReferralCommissionAmount,
    closeReferralCount,
    settings,
    enable,
    partnerStatus
  } = props;

  const enableReferrals = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_REFERRALS)
    : "";

  const enablePartnerPayments = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNER_PAYMENTS)
    : "";

  return (
    <>
      {enableReferrals === "true" ? (
        <div
          className={[
            "card-statistics",
            `${
              !enable
                ? "disabled"
                : partnerStatus !== PARTNER_STATUS_APPROVED
                ? "disabled"
                : ""
            }`
          ].join(" ")}
        >
          {enableReferrals === "true" && (
            <>
              {/* Show Submitted Referrals Count */}
              <StatisticsCountCard
                icon={<DashboardCommissionEarnedIcon />}
                count={submittedReferralCount}
                label={`${Terminology.get(SystemName.REFERRALS)} Submitted`}
                redirectUrl="/referrals"
                className={"text-white"}
              />
              {/* Show Approved Referrals Count */}
              <StatisticsCountCard
                icon={<DashboardDealPendingIcon />}
                count={acceptedReferralCount}
                label="Opportunities Created"
                redirectUrl="/referrals"
                className={"text-white"}
              />
            </>
          )}
          {/* Show Marketplace Referrals Count */}
          {enablePartnerPayments === "true" && (
            <StatisticsCountCard
              icon={<DashboardCommissionEarnedIcon />}
              count={earnedReferralCommissionAmount}
              label=" Commission Generated"
              prefix={GetCurrencySymbol()}
              redirectUrl="/referrals/converted"
              className={"text-white"}
            />
          )}

          {/* Show Closed Deals Count */}
          {enableReferrals === "true" && (
            <StatisticsCountCard
              icon={<DashboardCommissionProjectedIcon />}
              count={closeReferralCount}
              label="Closed Deals"
              redirectUrl="/referrals/?status=Closed%20Won"
              className={"text-white"}
              // prefix={"$"}
            />
          )}
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default PartnerStatisticCards;
