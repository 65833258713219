import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import CancelButton from "../../../../../components/base/CancelButton";

const DeleteTaskModal = ({ isOpen, toggle, deleteTask, task }) => {
  let { id, name } = task;
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={toggle}>
        <h4 className={["font-weight-bold"].join(" ")}>Delete Task</h4>
      </ModalHeader>
      <ModalBody className={["text-center", "mb-4"].join(" ")}>
        <p>
          Are you sure you want to delete <br />
          <b>"{name}"</b>?
        </p>
      </ModalBody>
      <ModalFooter className={["justify-content-center"].join(" ")}>
        <div className="btn-wrapper">
          <CancelButton onClick={() => toggle()} />
          <button
            className="btn btn-danger"
            onClick={() => {
              toggle();
              deleteTask(id);
            }}
          >
            Yes, Delete
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteTaskModal;
