import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { CongratsGraphicIcon } from "../../assets/img/icons";
import { Link } from "react-router-dom";
// Helper
import { getCookie, setCookie } from "../../lib/helper";
import { COOKIE_SHOW_REFFERRAL_REQUEST_NOTIFICATION_POPUP } from "../../lib/cookie";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

class ReferralsRequestPopUp extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      isOpen: false,
      requestReferralCount: 0
    };
  }
  componentDidUpdate(prevProps) {
    const pop_status = getCookie(
      COOKIE_SHOW_REFFERRAL_REQUEST_NOTIFICATION_POPUP
    );
    const popup_show = pop_status === "hide" ? false : true;
    if (
      this.state.requestReferralCount !== this.props.requestReferralCount &&
      this.props.requestReferralCount > 0 &&
      popup_show
    ) {
      this.setState({
        isOpen: true,
        requestReferralCount: this.props.requestReferralCount
      });
    }
  }
  _toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
    setCookie(COOKIE_SHOW_REFFERRAL_REQUEST_NOTIFICATION_POPUP, "hide");
  };

  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.isOpen}
          className="referral-modal-popup w-100 text-center mx-auto"
        >
          <ModalHeader
            toggle={this._toggle}
            cssModule={{ "modal-title": "w-100 text-center mt-3" }}
            style={{ backgroundColor: "#DCDCDC" }}
          >
            <div className="">
              <CongratsGraphicIcon />
            </div>
            <div className="font-weight-bold mt-2">
              <span>Welcome back!</span>
              <br />
              <span>
                You have {this.props.requestReferralCount}{" "}
                {Terminology.get(SystemName.REFERRALS)} Request
              </span>
            </div>
          </ModalHeader>
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div>
              <span>
                You have been requested to submit a{" "}
                {Terminology.get(SystemName.REFERRALS)}{" "}
              </span>
              <br />
              <span>for a company that looks like a good match.</span>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="col-md-12 mb-4">
              <Link
                className="text-decoration-none"
                onClick={this._toggle}
                to="/referrals"
              >
                <button id="goReferralsButton" className="btn btn-primary">
                  Go to {Terminology.get(SystemName.REFERRALS)}
                </button>
              </Link>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default ReferralsRequestPopUp;
