// API Client
import { apiClient } from "../apiClient";

// History
import history from "../history";

// API Configs
import { endpoints, DEFAULT_API_KEY } from "../configs";

import {
  REQUEST_CREATE_EXPERT_AVAILABILITY_UPDATE,
  RECEIVE_CREATE_EXPERT_AVAILABILITY_UPDATE,
  EXPERT_AVAILABILITY_UPDATE_CREATE_ERROR
} from "../actionType/Constants";
import toast from "../components/base/Toast";
import { isBadRequest } from "../common/http";

/** Add expert availability update **/

/**
 * Request for expert availability update create
 */
export function requestCreateExpertAvailabilityUpdate() {
  return {
    type: REQUEST_CREATE_EXPERT_AVAILABILITY_UPDATE
  };
}

/**
 * receive for expert availability update create
 */
export function receiveCreateExpertAvailabilityUpdate() {
  return {
    type: RECEIVE_CREATE_EXPERT_AVAILABILITY_UPDATE
  };
}

/**
 * Expert availability update create fails
 */
export function expertAvailabilityUpdateCreateError(error) {
  return {
    type: EXPERT_AVAILABILITY_UPDATE_CREATE_ERROR,
    error
  };
}

/**
 * Expert availability update create
 */
export function expertAvailabilityUpdate(details) {
  return dispatch => {
    dispatch(requestCreateExpertAvailabilityUpdate());

    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .post(endpoints().expertAvailabilityUpdate, details)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);

        dispatch();
        history.push("/expert-availability");
      })
      .then(() => {
        dispatch(receiveCreateExpertAvailabilityUpdate());
      })
      .catch(error => {
        dispatch(expertAvailabilityUpdateCreateError(error));
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}
