import React, { Component } from "react";

// Components
import PageTitle from "../../components/base/PageTitle";

class mySettings extends Component {
  render() {
    return <PageTitle label="My Settings" />;
  }
}

export default mySettings;
