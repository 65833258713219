import React, { useEffect, useState } from "react";
import { ToastContainer } from "react-toastify";
import { Route } from "react-router-dom";

// Components
import PublicNavigation from "../components/header/PublicNavigation";
import Footer from "../components/base/Footer";
import EndFooter from "../components/base/EndFooter";

// Helper
import {
  isExpert,
  getCookie,
  getKeyValueByObject,
  getSettingMediaUrl,
  getParamsByName
} from "../lib/helper";
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

import {
  SETTINGS_PORTAL_NAME,
  SETTINGS_PORTAL_LOGO_REDIRECT_URL,
  SETTINGS_PORTAL_LOGO,
  SETTINGS_PORTAL_FOOTER_COPY_RIGHTS_TEXT,
  SETTINGS_PORTAL_HEADER_COLOR,
  SETTINGS_PORTAL_HEADER_TEXT_COLOR,
  SETTINGS_PORTAL_FAV_ICON,
  SETTINGS_GA_TRACKING_ID,
  SETTINGS_PORTAL_DESKTOP_FOOTER_CONTENT,
  SETTINGS_PORTAL_MOBILE_FOOTER_CONTENT,
  SETTINGS_FACEBOOK_URL,
  SETTINGS_INSTAGRAM_URL,
  SETTINGS_LINKEDIN_URL,
  SETTINGS_TWITTER_URL,
  SETTINGS_PORTAL_FOOTER_TEXT_COLOR,
  SETTINGS_PORTAL_FOOTER_COLOR,
  SETTINGS_PORTAL_PUBLIC_PAGE_FOOTER_CONTENT
} from "../setting/Constants";

// Constants
import { USER_EMAIL_VERIFICATION_REQUIRED } from "../users/Constants";

import UserProvider from "../context/userContext/UserProvider";

// Configs
import { endpoints } from "../configs";

// API call
import { apiClient } from "../apiClient";

import { GAPageView } from "../lib/GoogleAnalytics";
import { isBadRequest } from "../common/http";
import { getDestinationUrl } from "../url/Service";

import { useSelector } from "react-redux";

const PublicPageLayout = ({ children, match, settings }) => {
  const [allowAccess, setAllowAccess] = useState(true);

  const settingsData = settings ? settings : "";

  const name = document.getElementById("portalName");

  const currentUser = useSelector(state => state.user);

  const portalName = getKeyValueByObject(settings, SETTINGS_PORTAL_NAME);
  if (portalName) {
    name.innerHTML = portalName;
  }
  const marketplaceLogo =
    settings && getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO)
      ? getSettingMediaUrl(
          settings,
          getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO)
        )
      : "";

  const favIcon = document.getElementById("marketPlaceFavIcon");
  const marketplaceFavIconUrl =
    settings && getKeyValueByObject(settings, SETTINGS_PORTAL_FAV_ICON)
      ? getSettingMediaUrl(
          settings,
          getKeyValueByObject(settings, SETTINGS_PORTAL_FAV_ICON)
        )
      : "";
  if (marketplaceFavIconUrl && favIcon.href !== marketplaceFavIconUrl) {
    favIcon.href = marketplaceFavIconUrl;
  }

  const marketplaceLogoRedirectUrl = getKeyValueByObject(
    settingsData,
    SETTINGS_PORTAL_LOGO_REDIRECT_URL
  );

  const footerContent = getKeyValueByObject(
    settingsData,
    SETTINGS_PORTAL_DESKTOP_FOOTER_CONTENT
  );

  const mobileFooterContent = getKeyValueByObject(
    settingsData,
    SETTINGS_PORTAL_MOBILE_FOOTER_CONTENT
  );

  const publicFooterContent = getKeyValueByObject(
    settingsData,
    SETTINGS_PORTAL_PUBLIC_PAGE_FOOTER_CONTENT
  );

  const headerColor = getKeyValueByObject(
    settingsData,
    SETTINGS_PORTAL_HEADER_COLOR
  );

  const headerTextColor = getKeyValueByObject(
    settingsData,
    SETTINGS_PORTAL_HEADER_TEXT_COLOR
  );

  const copyRightsText = getKeyValueByObject(
    settingsData,
    SETTINGS_PORTAL_FOOTER_COPY_RIGHTS_TEXT
  );

  const backgroundColor = getKeyValueByObject(
    settingsData,
    SETTINGS_PORTAL_FOOTER_COLOR
  );

  const textColor = getKeyValueByObject(
    settingsData,
    SETTINGS_PORTAL_FOOTER_TEXT_COLOR
  );

  const facebookUrl = settings
    ? getKeyValueByObject(settings, SETTINGS_FACEBOOK_URL)
    : "";
  const instagramUrl = settings
    ? getKeyValueByObject(settings, SETTINGS_INSTAGRAM_URL)
    : "";
  const linkedInUrl = settings
    ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_URL)
    : "";
  const twitterUrl = settings
    ? getKeyValueByObject(settings, SETTINGS_TWITTER_URL)
    : "";

  // Check Expert Completed Email Verification
  if (
    isExpert(currentUser && currentUser.roleId) &&
    getCookie(COOKIE_SESSION_TOKEN)
  ) {
    apiClient
      .get(`${endpoints().userAPI}`)
      .then(response => {
        if (response && response.data) {
          const { emailVerification } = response.data;

          setAllowAccess(
            emailVerification === USER_EMAIL_VERIFICATION_REQUIRED
              ? false
              : true
          );
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  const hideHeaderFooter =
    match.url.includes("/expert-signup") ||
    match.url.includes("/partner-signup") ||
    match.url.includes("/partner-user-signup") ||
    match.url.includes("/find-an-expert") ||
    match.url.includes("/customer-signup") ||
    match.url.includes("/playbook-view") ||
    match.url.includes("/login") ||
    match.url.includes("/embed/") ||
    match.url.includes("/company-signup") ||
    getParamsByName("hideHeaderFooter");
  const rambleChat = document.getElementById("ramble-ew");
  if (rambleChat && !getCookie(COOKIE_SESSION_TOKEN)) {
    rambleChat.parentNode.removeChild(rambleChat);
  }
  const getRedirectUrl = async () => {
    let redirectUrl = await getDestinationUrl();
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    }
  };
  useEffect(() => {
    getRedirectUrl();
  }, []);

  return (
    <UserProvider>
      {!hideHeaderFooter && (
        <PublicNavigation
          match={match}
          enable={allowAccess}
          marketplaceLogo={marketplaceLogo}
          marketplaceLogoRedirectUrl={marketplaceLogoRedirectUrl}
          portalName={portalName}
          headerColor={headerColor}
          headerTextColor={headerTextColor}
        />
      )}
      <ToastContainer
        autoClose={4000}
        pauseOnHover={false}
        toastClassName="toastRequestSuccess"
        bodyClassName="toastBody"
      />
      <div className="pb-5">{children}</div>
      {!hideHeaderFooter && (footerContent || mobileFooterContent) && (
        <Footer
          footerContent={footerContent}
          mobileFooterContent={mobileFooterContent}
        />
      )}
      {!hideHeaderFooter && publicFooterContent && (
        <Footer footerContent={publicFooterContent} />
      )}
      {!publicFooterContent && !hideHeaderFooter && !footerContent && (
        <EndFooter
          facebookUrl={facebookUrl}
          instagramUrl={instagramUrl}
          linkedInUrl={linkedInUrl}
          twitterUrl={twitterUrl}
          copyRightsText={copyRightsText}
          backgroundColor={backgroundColor}
          textColor={textColor}
        />
      )}
    </UserProvider>
  );
};

const PublicPageLayoutRoute = ({ component: Component, settings, ...rest }) => {
  if (settings.isFetching) {
    return "";
  }

  useEffect(() => {
    const settingData = settings ? settings.settings : "";
    if (settingData) {
      const gaTrackingId = getKeyValueByObject(
        settingData,
        SETTINGS_GA_TRACKING_ID
      );

      window.ga("create", `${gaTrackingId}`, "auto");

      GAPageView();
    }
  }, []);

  return (
    <Route
      {...rest}
      render={matchProps => (
        <PublicPageLayout {...matchProps} {...settings}>
          <Component {...settings} {...matchProps} />
        </PublicPageLayout>
      )}
    />
  );
};

export default PublicPageLayoutRoute;
