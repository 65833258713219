import React, { useState } from "react";
import Rating from "react-rating";
import { Input, Label } from "reactstrap";

// Assets
import { StarIcon, StarIconFilled } from "../assets/img/icons";

// Components
import PlaybookCard from "./PlaybookCard";

const PlaybookReviewFeedBackForm = props => {
  const [communicationFromClient, setCommunicationFromClient] = useState(0);
  const [playbookCompensation, setPlaybookCompensation] = useState(0);
  const [playbookOverAllExperience, setPlaybookOverAllExperience] = useState(0);
  const [playbookReviewNotes, setPlaybookReviewNotes] = useState("");
  const [checkboxCommentPublic, setCheckboxCommentPublic] = useState(false);

  const handleProjectValueForMoney = value => setCommunicationFromClient(value);
  const handlePlaybookCompensation = value => setPlaybookCompensation(value);
  const handleProjectOverallExperience = value =>
    setPlaybookOverAllExperience(value);

  const handleProjectReview = e => setPlaybookReviewNotes(e.target.value);

  const handleCheckboxCommentPublic = () =>
    setCheckboxCommentPublic(!checkboxCommentPublic);

  const {
    projectDetails: { id, projectDetails },
    saveProjectFeedback
  } = props;

  return (
    <div className="feedback-component d-flex justify-content-start">
      <PlaybookCard
        data={projectDetails}
        isPreview={"true"}
        boxShadow={false}
        placeHolder={true}
        bannerImageUrl={projectDetails.bannerImageUrl}
        tag={"div"}
      />
      <div className="rating-component">
        <div className="form-wrapper">
          <div className="rating-wrapper d-flex align-items-center">
            <Rating
              initialRating={communicationFromClient}
              emptySymbol={<StarIcon />}
              fullSymbol={<StarIconFilled />}
              onChange={handleProjectValueForMoney}
            />{" "}
            <h5 className="font-weight-bold ml-3">Communication from Client</h5>
          </div>
          <div className="rating-wrapper d-flex align-items-center">
            <Rating
              id={id}
              initialRating={playbookCompensation}
              emptySymbol={<StarIcon />}
              fullSymbol={<StarIconFilled />}
              onChange={handlePlaybookCompensation}
            />{" "}
            <h5 className="font-weight-bold ml-3">Compensation</h5>
          </div>
          <div className="rating-wrapper d-flex align-items-center">
            <Rating
              initialRating={playbookOverAllExperience}
              emptySymbol={<StarIcon />}
              fullSymbol={<StarIconFilled />}
              onChange={handleProjectOverallExperience}
            />{" "}
            <h5 className="font-weight-bold ml-3">Overall Experience</h5>
          </div>
          <div className="field-wrapper">
            <label>Tell us about your experience with this Playbook</label>
            <Input
              id={id}
              type="textarea"
              placeholder={"Leave feedback and let others know how things went"}
              style={{
                background: "#F3F3F4",
                border: "none",
                borderRadius: "5px",
                fontSize: "14px",
                minHeight: 132
              }}
              onChange={handleProjectReview}
            />
          </div>
          <div className="btn-wrapper d-flex align-items-center justify-content-stretch">
            <div className="checkbox-wrapper">
              <Label htmlFor="agreeCheck">
                <Input
                  id={"agreeCheck"}
                  name={"agreeCheck"}
                  type="checkbox"
                  defaultChecked={checkboxCommentPublic}
                  value={"value"}
                  onChange={handleCheckboxCommentPublic}
                />{" "}
                <span
                  className={`checkbox-placeholder ${checkboxCommentPublic &&
                    "active"}`}
                />
                <span>I don’t want my comments made public.</span>
              </Label>
            </div>
            <button
              id={id}
              className="btn btn-primary mr-0 ml-auto"
              onClick={() =>
                saveProjectFeedback({
                  id,
                  communicationFromClient,
                  playbookCompensation,
                  playbookOverAllExperience,
                  playbookReviewNotes,
                  checkboxCommentPublic
                })
              }
            >
              Submit My Feedback
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlaybookReviewFeedBackForm;
