import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { getKeyValueByObject, getCookie } from "../../lib/helper";
import {
  SETTINGS_ENABLE_EXPERTS,
  SETTINGS_ENABLE_PARTNERS,
  SETTINGS_ENABLE_PLAYBOOK,
  SETTINGS_ENABLE_REFERRALS
} from "../../setting/Constants";

// Assets
import {
  ProjectsIcon,
  PlaybookIcon,
  CustomerIcon,
  UserIcon,
  DashboardCommissionEarnedIcon,
  DashboardCommissionProjectedIcon,
  DashboardDealRegisteredIcon
} from "../../assets/img/icons";

// Components
import StatisticsCountCard from "../../components/base/StatisticsCountCard";

// Actions
import { fetchAdminDashboardCount } from "../../actions/dashboard";
import {
  PARTNER_MANAGER_DASHBOARD,
  SERVICE_DELIVERY_DASHBOARD
} from "../../dashboard/Constants";
import {
  COOKIE_DEFAULT_CURRENCY,
  COOKIE_SHOW_ASSIGNED_PARTNERS
} from "../../lib/cookie";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
import { GetCurrencySymbol } from "../../lib/CurrencySymbol";

const AdminStatisticCards = props => {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(fetchAdminDashboardCount());
  }, []);

  const adminDashboard = dashboard.adminDashboard;
  const enableExperts = props.settings
    ? getKeyValueByObject(props.settings, SETTINGS_ENABLE_EXPERTS)
    : "";
  const enablePartners = props.settings
    ? getKeyValueByObject(props.settings, SETTINGS_ENABLE_PARTNERS)
    : "";
  const enableReferrals = props.settings
    ? getKeyValueByObject(props.settings, SETTINGS_ENABLE_REFERRALS)
    : "";
  const enablePlaybook = props.settings
    ? getKeyValueByObject(props.settings, SETTINGS_ENABLE_PLAYBOOK)
    : "";
  const selectedManagerDashboard = props.selectedManagerDashboard;
  const isChecked = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
  console.log(adminDashboard);

  return (
    <div className={["card-statistics"].join(" ")}>
      {adminDashboard && (
        <>
          {selectedManagerDashboard === PARTNER_MANAGER_DASHBOARD && (
            <>
              {/* Show Partners Count */}
              {enablePartners === "true" && (
                <StatisticsCountCard
                  icon={<UserIcon />}
                  count={
                    isChecked
                      ? adminDashboard.assignedPartenrsCount
                      : adminDashboard.partnersCount
                  }
                  label="Partners"
                  className={"text-white"}
                  redirectUrl="/marketplace-sourcing?section=partners"
                />
              )}

              {/* Show Submitted Referrals Count */}
              {enableReferrals === "true" && (
                <StatisticsCountCard
                  icon={<DashboardCommissionEarnedIcon />}
                  count={
                    isChecked
                      ? adminDashboard.assignedReferralCount
                      : adminDashboard.approvedReferralCount +
                        adminDashboard.closeReferralCount
                  }
                  label={`Approved ${Terminology.get(SystemName.REFERRALS)} `}
                  className={"text-white"}
                  redirectUrl="/referrals?status=Approved,Closed%20Won"
                />
              )}

              {/* Show Partner sourced pipeline */}
              {props.enablePartnerPayments === "true" && (
                <>
                  <StatisticsCountCard
                    icon={<DashboardCommissionEarnedIcon />}
                    count={
                      isChecked
                        ? adminDashboard.assignedPartnerSourcedPipeline
                            .total_amount
                        : adminDashboard.partnerSourcedPipeline.total_amount
                    }
                    prefix={GetCurrencySymbol()}
                    label="Partner Sourced Pipeline"
                    className={"text-white"}
                    redirectUrl="/referrals?status=Approved"
                  />

                  {/* Show Partner sourced Revenue */}
                  <StatisticsCountCard
                    icon={<DashboardCommissionProjectedIcon />}
                    count={
                      isChecked
                        ? adminDashboard.assignedpartnerSourcedRevenue
                            .total_amount
                        : adminDashboard.partnerSourcedRevenue.total_amount
                    }
                    label="Partner Sourced Revenue"
                    className={"text-white"}
                    prefix={GetCurrencySymbol()}
                    redirectUrl="/referrals?status=Closed%20Won"
                  />
                </>
              )}
            </>
          )}

          {selectedManagerDashboard === SERVICE_DELIVERY_DASHBOARD && (
            <>
              {/* Show Active Projects count */}
              <StatisticsCountCard
                icon={<ProjectsIcon />}
                count={adminDashboard.activeProjectsCount}
                label="Active Projects"
                redirectUrl="/projects"
                className={"text-white"}
              />

              {/* Show Playbooks Count */}
              {enablePlaybook && enablePlaybook === "true" && (
                <StatisticsCountCard
                  icon={<PlaybookIcon />}
                  count={adminDashboard.playbooksCount}
                  label="Playbooks"
                  redirectUrl="/marketplace-sourcing?section=playbooks"
                  className={"text-white"}
                />
              )}

              {/*Show Customer Count*/}
              <StatisticsCountCard
                icon={<CustomerIcon />}
                count={adminDashboard.customerCount}
                label="Customers"
                redirectUrl="/customers"
                className={"text-white"}
              />

              {/* Show Experts Count */}
              {enableExperts === "true" && (
                <StatisticsCountCard
                  icon={<UserIcon />}
                  count={adminDashboard.expertsCount}
                  label="Experts"
                  redirectUrl="/marketplace-sourcing?section=experts"
                  className={"text-white"}
                />
              )}
            </>
          )}
        </>
      )}
    </div>
  );
};

export default AdminStatisticCards;
