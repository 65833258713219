import React from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import Select from "../../../../components/base/Select";
import SaveButton from "../../../../components/base/SaveButton";

// Constants
import { SETTINGS_SLACK_REFERRAL_SUBMIT_NOTIFICATION_CHANNEL } from "../../../../setting/Constants";

// Helper
import { toString } from "../../../../lib/helper";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

const SlackSection = props => {
  const { adminSettings, saveSettings } = props;

  const slackChannelList = adminSettings.slackChannelList || [];

  const { referral_submission_slack_notification_channel } = adminSettings;

  // Notification Settings Slack Section Initial Values
  const notificationSettingsSlackSectionInitialValues = {
    referral_submission_slack_notification_channel
  };

  // Notification Settings Slack Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Marketplace company state
    if (values.referral_submission_slack_notification_channel !== undefined) {
      data.append(
        "referral_submission_slack_notification_channel",
        toString(
          values.referral_submission_slack_notification_channel &&
            typeof values.referral_submission_slack_notification_channel ===
              "object"
            ? values.referral_submission_slack_notification_channel.value
            : values.referral_submission_slack_notification_channel
        )
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={notificationSettingsSlackSectionInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          {/* Marketplace Company Name */}
          <h5 className="font-weight-bold">
            {Terminology.get(SystemName.REFERRAL)} Submission Slack Notification
            Channel
          </h5>
          <div className="field-wrapper">
            <Select
              name={SETTINGS_SLACK_REFERRAL_SUBMIT_NOTIFICATION_CHANNEL}
              placeholder="Select Slack Channel"
              options={slackChannelList}
              defaultValue={slackChannelList.find(
                type =>
                  type.value === referral_submission_slack_notification_channel
              )}
              isSearchable={true}
            />
          </div>
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default SlackSection;
