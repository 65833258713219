import React from "react";
import classnames from "classnames";
import { apiClient } from "../../apiClient";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from "reactstrap";

// Configs
import { endpoints } from "../../configs";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchList } from "../../actions/table";
import CountBadge from "../../components/base/CountBadge";

import {
  MDF_STATUS_REQUESTED,
  MDF_STATUS_APPROVED,
  TAB_NEW,
  TAB_APPROVED,
  TAB_ALL
} from "./Constants";

// Helper
import {
  getParamsByName,
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import { MdfIcon } from "../../assets/img/icons";
// Components
import PageTitle from "../../components/base/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import MoreDropdown from "../../components/base/MoreDropdown";
import Spinner from "../../components/base/Spinner";
import { isBadRequest } from "../../common/http";
import toast from "../../components/base/Toast";
import DeleteModal from "../../components/base/DeleteModal";

// Inner Components

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class MDF extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "request_amount:ASC",
          label: "Amount"
        }
      ],
      rowId: "",
      status: "",
      searchCount: 0,
      isLoading: false,
      partnerId: "",
      activeTab: TAB_NEW,
      deleteMdfModal: false,
      deleteMdf: false,
      searchparam: ""
    };
  }

  componentDidMount() {
    if (isPartner(this.props.roleId)) {
      this._getPartnerDetails();
    }
    this.setActiveTab();
  }
  setActiveTab = () => {
    const status = getParamsByName("section") || this.state.activeTab;

    this.setState({
      activeTab:
        status === TAB_NEW
          ? TAB_NEW
          : status === TAB_APPROVED
          ? TAB_APPROVED
          : status === TAB_ALL
          ? TAB_ALL
          : TAB_ALL
    });
  };

  // Get partner details
  _getPartnerDetails = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        return apiClient
          .get(`${endpoints().partnerAPI}`)
          .then(response => {
            this.setState({
              isLoading: false,
              partnerDetails: response.data,
              partnerId: response.data.id
            });
          })
          .catch(error => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              console.error(errorMessage);
            }
          });
      }
    );
  };

  // Update mdf By Status
  updateMdfByStatus = (status, id) => {
    let data = { status };
    return apiClient
      .put(`${endpoints().mdfRequestAPI}/status/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          // this.setState({ isMdfUpdate: true, isLoading: true });
          toast.success(successMessage);
        }
        this.updateMdfList();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };

  updateMdfList = () => {
    var tab = getParamsByName("section") || getParamsByName("tab");
    const searchItem = getParamsByName("search") || "";
    if (tab === "Approved") {
      this.props.actions.fetchList(
        TAB_APPROVED,
        `${endpoints().mdfRequestAPI}/search`,
        1,
        10,
        {
          pagination: true,
          status: MDF_STATUS_APPROVED,
          search: searchItem
        }
      );
    } else {
      this.props.actions.fetchList(
        TAB_APPROVED,
        `${endpoints().mdfRequestAPI}/search`,
        1,
        10,
        {
          pagination: true,
          status: MDF_STATUS_APPROVED,
          search: ""
        }
      );
    }
    if (tab === "All") {
      this.props.actions.fetchList(
        TAB_ALL,
        `${endpoints().mdfRequestAPI}/search`,
        1,
        10,
        {
          pagination: true,
          search: searchItem
        }
      );
    } else {
      this.props.actions.fetchList(
        TAB_ALL,
        `${endpoints().mdfRequestAPI}/search`,
        1,
        10,
        {
          pagination: true,
          search: ""
        }
      );
    }
    if (tab === "New") {
      this.props.actions.fetchList(
        TAB_NEW,
        `${endpoints().mdfRequestAPI}/search`,
        1,
        10,
        {
          pagination: true,
          status: MDF_STATUS_REQUESTED,
          search: searchItem
        }
      );
    } else {
      this.props.actions.fetchList(
        TAB_NEW,
        `${endpoints().mdfRequestAPI}/search`,
        1,
        10,
        {
          pagination: true,
          status: MDF_STATUS_REQUESTED,
          search: ""
        }
      );
    }
  };
  // Account delete api call
  handleDelete = id => {
    return apiClient
      .delete(`${endpoints().mdfRequestAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          this.setState({
            isLoading: false
          });
        }
        this.updateMdfList();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };

  toggle = tab => {
    this.setState({
      activeTab: tab
    });
    this.props.history.push(`/mdf-requests?section=${tab}`);
  };

  rendermdfTableByStatus(tableId, params) {
    const currentUrl = window.location.pathname + window.location.search;
    const { deleteMdfModal, deleteMdf } = this.state;
    return (
      <div>
        <ReduxTable
          id={tableId}
          apiURL={`${endpoints().mdfRequestAPI}/search`}
          params={params && { ...params }}
          searchPlaceholder={`Search`}
          onRowClick={row =>
            this.props.history.push(`/mdf-request/details/${row.id}`)
          }
          showHeader
          icon={<MdfIcon />}
          message="You can track the mdf requests you have submited here"
          subtextMessage="To get started tap the Submit a MDF button"
          paramsToUrl={true}
          history={this.props.history}
          sortByOptions={this.state.sortByOptions}
          newTableHeading
        >
          <ReduxColumn
            width="200px"
            type="link"
            isClickable="true"
            sortBy="title"
            field="title"
            renderField={row => (
              <Link to={`mdf-request/details/${row.id}`}>{row.title}</Link>
            )}
          >
            MDF Request
          </ReduxColumn>
          <ReduxColumn width="120px" field="partnerCompanyName" disableOnClick>
            Partner
          </ReduxColumn>
          <ReduxColumn
            width="100px"
            field="requestAmount"
            disableOnClick
            renderField={row => {
              return (
                <div className="text-left">
                  <p>{row.requestAmount ? `$ ${row.requestAmount}` : ""}</p>
                </div>
              );
            }}
          >
            Requested
          </ReduxColumn>
          <ReduxColumn
            width="100px"
            field="status"
            disableOnClick
            renderField={row => (
              <div
                className={`text-center text-uppercase ${
                  row.status !== "Approved"
                    ? row.status === "Declined"
                      ? "bg-danger"
                      : "landing-page-status-draft"
                    : "landing-page-status-active"
                }`}
                style={statusStyle}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn width="110px" field="createdAt" disableOnClick>
            Created At
          </ReduxColumn>
          <ReduxColumn
            width="100px"
            field="Action"
            disableOnClick
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  {row.status === "Closed" && (
                    <DropdownItem
                      onClick={() => {
                        this.props.history.push(
                          `/mdf-request/details/${row.id}?redirect=${currentUrl}`
                        );
                      }}
                    >
                      View Details
                    </DropdownItem>
                  )}
                  {(isSuperAdmin(this.props.roleId) ||
                    isCompanyAdmin(this.props.roleId) ||
                    isCompanyManager(this.props.roleId)) && (
                    <>
                      {row.status !== "Approved" && (
                        <DropdownItem
                          className="text-success"
                          onClick={() =>
                            this.updateMdfByStatus("Approved", row.id)
                          }
                        >
                          Approve
                        </DropdownItem>
                      )}

                      {row.status !== "Declined" && (
                        <DropdownItem
                          className="text-danger"
                          onClick={() =>
                            this.updateMdfByStatus("Declined", row.id)
                          }
                        >
                          Decline
                        </DropdownItem>
                      )}
                    </>
                  )}
                  <Link
                    className="text-dark text-decoration-none"
                    to={`/mdf-request/details/${row.id}?redirect=${currentUrl}`}
                    style={{ cursor: "pointer" }}
                  >
                    <DropdownItem>Edit</DropdownItem>
                  </Link>
                  {row.status !== "Closed" && (
                    <DropdownItem
                      className="font-weight-bold text-danger"
                      onClick={() =>
                        this.setState({
                          deleteMdfModal: true,
                          deleteMdf: row
                        })
                      }
                    >
                      Delete
                    </DropdownItem>
                  )}
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          isOpen={deleteMdfModal}
          toggle={() => {
            this.setState({ deleteMdfModal: false });
          }}
          title="Delete MDF"
          label={deleteMdf.title}
          deleteFunction={() => this.handleDelete(deleteMdf.id)}
        />
      </div>
    );
  }

  render() {
    const { isLoading, activeTab } = this.state;

    const {
      newMdfRequestCount,
      approvedMdfRequestCount,
      allMdfRequestCount
    } = this.props;

    const search = window.location.search;
    const searchItem = getParamsByName("search");
    const params = new URLSearchParams(search, searchItem);
    const searchStatus = params.get("section");

    const isNew = searchStatus === TAB_NEW || activeTab === TAB_NEW;
    const isApproved =
      searchStatus === TAB_APPROVED || activeTab === TAB_APPROVED;
    const isAll = searchStatus === TAB_ALL || activeTab === TAB_ALL;

    return (
      <div>
        <PageTitle
          label="MDF"
          targetUrl={"/mdf-request/new"}
          buttonLabel="Submit a MDF Request"
        />
        {/* Nav Tabs */}
        <Nav tabs className="admin-tabs">
          <NavItem>
            <NavLink
              className={classnames({
                active: isNew
              })}
              onClick={() => {
                this.toggle(TAB_NEW);
              }}
            >
              {TAB_NEW}{" "}
              {
                <CountBadge
                  isActive={classnames({
                    active: isNew
                  })}
                  count={newMdfRequestCount}
                />
              }
            </NavLink>
          </NavItem>

          <NavItem>
            <NavLink
              className={classnames({
                active: isApproved
              })}
              onClick={() => {
                this.toggle(TAB_APPROVED);
              }}
            >
              {TAB_APPROVED}{" "}
              {
                <CountBadge
                  isActive={classnames({
                    active: isApproved
                  })}
                  count={approvedMdfRequestCount}
                />
              }
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: isAll
              })}
              onClick={() => {
                this.toggle(TAB_ALL);
              }}
            >
              {TAB_ALL}{" "}
              {
                <CountBadge
                  isActive={classnames({
                    active: isAll
                  })}
                  count={allMdfRequestCount}
                />
              }
            </NavLink>
          </NavItem>
        </Nav>

        {isLoading && <Spinner />}

        <TabContent activeTab={activeTab}>
          {/*User table*/}
          <TabPane tabId={TAB_NEW}>
            {this.rendermdfTableByStatus(TAB_NEW, {
              ...params,
              status: MDF_STATUS_REQUESTED,
              tab: TAB_NEW,
              search: searchItem
            })}
          </TabPane>
          <TabPane tabId={TAB_APPROVED}>
            {this.rendermdfTableByStatus(TAB_APPROVED, {
              ...params,
              status: MDF_STATUS_APPROVED,
              tab: TAB_APPROVED,
              search: searchItem
            })}
          </TabPane>
          <TabPane tabId={TAB_ALL}>
            {this.rendermdfTableByStatus(TAB_ALL, {
              ...params,
              tab: TAB_ALL,
              search: searchItem
            })}
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const reduxTable = state.table;
  const roleId = state.user ? state.user.roleId : "";

  // Get new mdf count
  const newMdfRequestCount =
    reduxTable[TAB_NEW] && !reduxTable[TAB_NEW].isFetching
      ? reduxTable[TAB_NEW].totalCount
      : 0;

  // Get approved mdf count
  const approvedMdfRequestCount =
    reduxTable[TAB_APPROVED] && !reduxTable[TAB_APPROVED].isFetching
      ? reduxTable[TAB_APPROVED].totalCount
      : 0;

  // Get approved mdf count
  const allMdfRequestCount =
    reduxTable[TAB_ALL] && !reduxTable[TAB_ALL].isFetching
      ? reduxTable[TAB_ALL].totalCount
      : 0;

  return {
    newMdfRequestCount,
    approvedMdfRequestCount,
    allMdfRequestCount,
    roleId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MDF);
