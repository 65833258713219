import React, { Component } from "react";
import Rating from "react-rating";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import PageTitle from "../../components/base/PageTitle";
import Spinner from "../../components/base/Spinner";
import Form from "../../components/base/Form";
import Text from "../../components/base/Text";
import TextArea from "../../components/base/TextArea";

// Assets
import { StarIcon, StarIconFilled } from "../../assets/img/icons";
import { isBadRequest } from "../../common/http";

class FeedbackDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      feedbackDetails: {},
      isLoading: false
    };
  }

  componentDidMount() {
    this._getFeedbackDetails(this.props.match.params.id);
  }

  // Get Expert Request Details
  _getFeedbackDetails(id) {
    this.setState({ isLoading: true });
    return apiClient
      .get(`${endpoints().reviewAPI}/${id}`)
      .then(response => {
        this.setState({
          feedbackDetails: response.data,
          isLoading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isLoading: false });
        }
      });
  }
  render() {
    const {
      category,
      projectName,
      comment,
      status,
      userName,
      createdAt
    } = this.state.feedbackDetails;
    const initialValues = {
      category,
      projectName,
      comment,
      status,
      userName,
      createdAt
    };
    if (this.state.isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <PageTitle label="Feedback Details" />
        <div className="card bg-white mb-3">
          <div className="card-body">
            <div className="row mt-4 mb-4">
              <div className="col-lg-6 col-sm-12">
                <Form initialValues={initialValues}>
                  <div className="field-wrapper">
                    <Text
                      name="projectName"
                      label="Project Name"
                      error=""
                      disabled={true}
                    />
                  </div>
                  <div className="field-wrapper">
                    <Text
                      name="category"
                      label="Category"
                      error=""
                      disabled={true}
                    />
                  </div>
                  <div className="field-wrapper">
                    <Text
                      name="userName"
                      label="Reviewed By"
                      error=""
                      disabled={true}
                    />
                  </div>
                  <div className="field-wrapper">
                    <TextArea
                      name="comment"
                      label="Comment"
                      error=""
                      disabled={true}
                    />
                  </div>
                  <div className="field-wrapper">
                    <Text
                      name="status"
                      label="Status"
                      error=""
                      disabled={true}
                    />
                  </div>
                  <div className="field-wrapper">
                    <Text
                      name="createdAt"
                      label="Reviewed Date"
                      error=""
                      disabled={true}
                    />
                  </div>
                </Form>
              </div>

              <div className="col-lg-6 col-sm-12">
                <ReduxTable
                  id="ratings"
                  newTableHeading
                  apiURL={`${endpoints().reviewAPI}/ratings/${
                    this.props.match.params.id
                  }`}
                  disableColumnSort
                >
                  <ReduxColumn field="reviewName">Review</ReduxColumn>
                  <ReduxColumn
                    field="rating"
                    renderField={row => (
                      <p style={{ width: "120px" }}>
                        <Rating
                          initialRating={row.rating}
                          emptySymbol={<StarIcon />}
                          fullSymbol={<StarIconFilled />}
                          readonly
                        />
                      </p>
                    )}
                  >
                    Rating
                  </ReduxColumn>
                </ReduxTable>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default FeedbackDetails;
