import React from "react";
import { getParamsByName } from "../../lib/helper";

// Component
import QuickLinks from "../DealRegister/QuickLinks";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

class ReferralRegisteredSuccessfully extends React.Component {
  render() {
    const encryptedpartnerId = getParamsByName("partner");
    const encrypteduserId = getParamsByName("user");
    const redirectUrl = `/referral/submit?partner=${encryptedpartnerId}&user=${encrypteduserId}`;

    return (
      <>
        <div
          className="container"
          style={{ minHeight: "90vh", position: "relative" }}
        >
          <div className="py-9 my-9 container d-flex flex-column justify-content-center">
            <QuickLinks
              redirectUrl={redirectUrl}
              message={`Thank you for submitting this ${Terminology.get(
                SystemName.REFERRAL
              )}. It has been created successfully.`}
            />
          </div>
        </div>
      </>
    );
  }
}

export default ReferralRegisteredSuccessfully;
