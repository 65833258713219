import React, { useState } from "react";
import ToggleSwitch from "../base/ToggleSwitch";
import { Field } from "formik";
import SaveButton from "../../components/base/SaveButton";
import { PARTNER_TYPE_SETTING_ENABLE_PARTNERSHIP_WITH_INDIVIDUAL_PERSON } from "../../partnerTypeSetting/Constants";
// Helper
import {
  getKeyValueByObject,
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import detectBrowser from "../helpers/detectBrowser";
import { useSelector } from "react-redux";

const PartnerProfileVisibilitySettings = props => {
  const {
    partnerId,
    companyProfileUrl,
    showProfileVisibility,
    id,
    partnerSettings,
    handlePartnerProfileVisibility
  } = props;

  const [
    enablePartnerShipWithIndividualPerson,
    setEnablePartnerShipWithIndividualPerson
  ] = useState("");

  const currentUser = useSelector(state => state.user);

  return (
    <div className={["set-expert-visibility row"].join(" ")}>
      {(isPartner(currentUser && currentUser.roleId) &&
        (!enablePartnerShipWithIndividualPerson ||
          enablePartnerShipWithIndividualPerson === "false")) ||
      isCompanyAdmin(currentUser && currentUser.roleId) ||
      isCompanyManager(currentUser && currentUser.roleId) ||
      isSuperAdmin(currentUser && currentUser.roleId) ? (
        <>
          {showProfileVisibility ? (
            <Field
              name="profile_visibility"
              render={({ field, form }) => {
                return (
                  <ToggleSwitch
                    className="p-2"
                    id={id}
                    name={"profile_visibility"}
                    label1={"Your profile's public visibility"}
                    label2={"Your profile's public visibility"}
                    value={field.value}
                    handleChange={() => {
                      handlePartnerProfileVisibility(!field.value);
                      form.setFieldValue("profile_visibility", !field.value);
                    }}
                    outlined
                  />
                );
              }}
            />
          ) : (
            <div className="flex-row" />
          )}
        </>
      ) : (
        <div className="flex-row" />
      )}

      <div className="btn-wrapper pt-1">
        {(isPartner(currentUser && currentUser.roleId) &&
          enablePartnerShipWithIndividualPerson === "false") ||
        isCompanyAdmin(currentUser && currentUser.roleId) ||
        isCompanyManager(currentUser && currentUser.roleId) ||
        isSuperAdmin(currentUser && currentUser.roleId) ? (
          <a
            href={`/partner-profile/${
              companyProfileUrl ? companyProfileUrl : partnerId
            }`}
            target="_blank"
            className={["btn", "btn-outline-secondary", "mr-2"].join(" ")}
            rel="noopener noreferrer"
          >
            View Public Profile
          </a>
        ) : (
          ""
        )}
        <SaveButton id={id} type="submit" />
      </div>
    </div>
  );
};
export default PartnerProfileVisibilitySettings;
