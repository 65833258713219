import React from "react";
import Text from "../../../components/base/Text";
import Button from "../../../components/base/Button";
import Form from "../../../components/base/Form";
import Email from "../../../components/base/Email";
import Phone from "../../../components/base/Phone";

const ContactDetailTab = props => {
  let { detail, showButton, readOnly } = props;
  return (
    <div>
      <Form
        enableReinitialize={true}
        initialValues={{
          primary_contact_first_name:
            (detail && detail.primary_contact_first_name) || "",
          primary_contact_last_name:
            (detail && detail.primary_contact_last_name) || "",
          primary_contact_title: (detail && detail.primary_contact_title) || "",
          primary_contact_email: (detail && detail.primary_contact_email) || "",
          primary_contact_phone: (detail && detail.primary_contact_phone) || ""
        }}
        onSubmit={values => {
          props.handleUpdate(values);
        }}
      >
        {!props.enableSaveButton && (
          <label className=" font-weight-bold h5 form-group">
            Add contact details
          </label>
        )}
        <div className="card border-0 mt-4">
          <div
            style={{
              backgroundColor: "#fafafa",
              borderBottom: "1px solid",
              borderColor: "#eaeded"
            }}
          >
            <div className="mt-4 mx-3">
              <label className=" font-weight-bold h5 form-group">
                Partner Contact
              </label>
            </div>
          </div>
          <div className="card-body">
            <label className="h6-5">
              Provide contact information for the primary sales contact
              responsible for this opportunity within your organization. The AWS
              sales team may reach out to this individual to discuss the
              opportunity further.
            </label>
            <div className="field-wrapper mt-2">
              <Text
                name="primary_contact_first_name"
                label="Primary Contact First Name"
                placeholder="Enter Primary Contact First Name"
                disabled={readOnly}
              />
            </div>

            <div className="field-wrapper">
              <Text
                name="primary_contact_last_name"
                label="Primary Contact Last Name"
                placeholder="Enter Primary Contact Last Name"
                disabled={readOnly}
              />
            </div>
            <div className="field-wrapper">
              <Text
                name="primary_contact_title"
                label="Primary Contact Title "
                placeholder="Enter Primary Contact Title "
                disabled={readOnly}
              />
            </div>
            <div className="field-wrapper">
              <Email
                name="primary_contact_email"
                label="Primary Contact Email"
                placeholder="Enter Primary Contact Email"
                disabled={readOnly}
              />
            </div>

            <div className="field-wrapper">
              <Phone
                name="primary_contact_phone"
                label="Primary Contact Phone"
                placeholder="Enter Primary Contact Phone"
                disabled={readOnly}
              />
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {props.handleChange && (
            <Button
              label="Back"
              type="button"
              onClick={() => {
                props.setActiveTab(1);
                props.handleChange(1);
              }}
              className=" mt-3 mb-3"
            />
          )}
          {!showButton && (
            <Button
              label={props.enableSaveButton ? "Save" : "Next"}
              type="submit"
              className=" mt-3 mb-3"
            />
          )}
        </div>
      </Form>
    </div>
  );
};

export default ContactDetailTab;
