class Phone {
  /**
   * Is data Empty
   * @param data
   */
  static isValid = data => {
    if (data && data.replace(/[^0-9]/g, "").length < 10) {
      return true;
    } else return null;
  };
}

export default Phone;
