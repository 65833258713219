import { apiClient } from "../apiClient";
import { endpoints } from "../configs";

class PartnerReffrelReportService {
  static async search(params) {
    const queryString = [];

    if (params) {
      Object.keys(params).forEach(param => {
        queryString.push(`${param}=${params[param]}`);
      });
    }
    const response = await apiClient.get(
      `${endpoints().overallSummaryAPI}/search?${queryString.join("&")}`
    );
    return response;
  }
}
export default PartnerReffrelReportService;
