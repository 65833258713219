import React from "react";
import { endpoints } from "../../../../configs";
import toast from "../../../../components/base/Toast";
import { apiClient } from "../../../../apiClient";

import { DropdownItem, TabContent, TabPane } from "reactstrap";

// Components
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import MoreDropdown from "../../../../components/base/MoreDropdown";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DeleteModal from "../../../../components/base/DeleteModal";
import Spinner from "../../../../components/base/Spinner";

// Components
import Text from "../../../../components/base/Text";
import Form from "../../../../components/base/Form";

// Assets
import createLandingIcon from "../../../../assets/pages/landing-page/create-landing-page.png";

// Helper
import { isLoggedIn } from "../../../../lib/helper";

import PartnerType from "./partnerTypes";

import { PARTNERS_SUB_TAB } from "../../../../setting/Constants";
import { isBadRequest } from "../../../../common/http";
import SingleCheckbox from "../../../../components/base/SingleCheckbox";

class PartnerTypeTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      name: "",
      id: "",
      isOpen: false,
      isLoading: false,
      isDefault: true,
      partnterTypeList: [],
      deletePartnerTypeModal: false,
      deletePartnerType: false,
      editPartnerTypeModal: false,
      editPartnerType: false,
      sortByOptions: [
        {
          value: "name:ASC",
          label: "name"
        }
      ]
    };
  }

  componentDidMount() {
    this._getPartnerTypeDetails();
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }
  saveSearchTerm = value => {
    this.setState({ searchTerm: value });
  };

  _toggle = id => {
    this.setState({ isOpen: !this.state.isOpen, id: id || "" });
  };
  handleEdit = row => {
    this.setState({
      name: row.name,
      id: row.id,
      isDefault: row.isDefault === 1 ? true : false
    });
    return this._toggle(row.id);
  };
  createPartnerType = data => {
    apiClient
      .post(`${endpoints().partnerTypeAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._getPartnerTypeDetails();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  // partner type page api call
  _getPartnerTypeDetails() {
    this.setState({ isLoading: true });
    apiClient
      .get(`${endpoints().partnerTypeAPI}/search`)
      .then(response => {
        const partnterTypeList = response.data.data;

        this.setState({
          partnterTypeList: partnterTypeList
            ? partnterTypeList
            : this.state.partnterTypeList,
          isLoading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({
            isLoading: false
          });
        }
      });
  }

  // delete partner type page api call by id
  _deletePartnerTypeDetails(id) {
    apiClient
      .delete(`${endpoints().partnerTypeAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this._getPartnerTypeDetails();
        }
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  // update partner type page api call by id
  _updatePartnerTypeDetails(data) {
    apiClient
      .put(`${endpoints().partnerTypeAPI}/update`, data)
      .then(response => {
        let successMessage;
        this._getPartnerTypeDetails();
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._toggle();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  }

  render() {
    const {
      partnterTypeList,
      deletePartnerTypeModal,
      deletePartnerType,
      isOpen,
      isLoading
    } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    const headerButton = (
      <div className="btn-wrapper">
        <PartnerType
          createPartnerType={this.createPartnerType}
          partnterTypeList={partnterTypeList}
        />
      </div>
    );

    const activeSubTab = this.props.activeSubTab;

    return (
      <>
        <div className="d-flex justify-content-between flex-wrap relative">
          <Modal
            isOpen={isOpen}
            toggle={this._toggle}
            className="custom-modal-popup w-100"
            backdrop="static"
          >
            <Form
              initialValues={{
                name: this.state.name || "",
                id: this.state.id || "",
                isDefault: this.state.isDefault
              }}
              onSubmit={values => {
                values.id = this.state.id;
                values.name = values.name;
                values.isDefault = values && values.isDefault ? 1 : 0;
                values.sort = values.sort;
                this._updatePartnerTypeDetails(values);
              }}
            >
              <ModalHeader toggle={this._toggle}>
                <div className="content-wrapper">
                  <div className="icon-wrapper">
                    <div className="row justify-content-center mb-2 mt-3">
                      <img src={createLandingIcon} size="30" alt="" />
                    </div>
                    <p className="text-center font-weight-bold mb-3">
                      Edit a Partner Type
                    </p>
                    <p
                      className="text-center"
                      style={{ fontSize: "14px", lineHeight: "20px" }}
                    ></p>
                  </div>
                  {/* /.icon-wrapper */}
                </div>
                {/* /.content-wrapper */}
              </ModalHeader>

              <ModalBody>
                <div className="mt-2 mb-5">
                  <div className="mb-2">
                    <Text
                      name="name"
                      label="Name"
                      placeholder="Enter Partner Types Name..."
                      error=""
                      required
                    />
                  </div>
                  <div className="field-wrapper">
                    <SingleCheckbox
                      name="isDefault"
                      label="Make as default"
                      className="accepted-terms"
                    />
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="container-fluid">
                  <div className="col-sm-12 text-center">
                    <Button type="submit" label="">
                      Update Partner Type
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </Form>
          </Modal>
        </div>

        <TabContent activeTab={activeSubTab}>
          <TabPane tabId={PARTNERS_SUB_TAB}>
            {activeSubTab === PARTNERS_SUB_TAB && (
              <>
                <div className="mt-4 mb-5">
                  <ReduxTable
                    id="partnerTypes"
                    apiURL={`${endpoints().partnerTypeAPI}/search`}
                    headerButton={headerButton}
                    showHeader
                    searchPlaceholder="Search"
                    onRowClick={row => {
                      this.setState({
                        name: row.name,
                        id: row.id,
                        isDefault: row.isDefault === 1 ? true : false
                      });
                      return this._toggle(row.id);
                    }}
                    saveSearchTerm={this.saveSearchTerm}
                    sortByOptions={this.state.sortByOptions}
                  >
                    <ReduxColumn
                      width={"460px"}
                      className="w-75"
                      type="link"
                      isClickable="true"
                      field="name"
                      sortBy="name"
                      renderField={row => {
                        return (
                          <>
                            {row.name}
                            <br></br>
                            {row.name && row.isDefault ? (
                              <small className="font-weight-bold text-secondary text-decoration-none">
                                (Default)
                              </small>
                            ) : (
                              ""
                            )}
                          </>
                        );
                      }}
                    >
                      Name
                    </ReduxColumn>
                    <ReduxColumn
                      width={"150px"}
                      className="w-25 text-center"
                      field="status"
                      disableOnClick
                      renderField={row => (
                        <div className="text-center landing-group-dropdown">
                          <MoreDropdown>
                            <DropdownItem
                              className={"text-dark"}
                              onClick={() => {
                                this.handleEdit(row);
                              }}
                            >
                              Edit
                            </DropdownItem>
                            <DropdownItem
                              className={"text-danger"}
                              onClick={() =>
                                this.setState({
                                  deletePartnerTypeModal: true,
                                  deletePartnerType: row
                                })
                              }
                            >
                              Delete
                            </DropdownItem>
                          </MoreDropdown>
                        </div>
                      )}
                    >
                      Action
                    </ReduxColumn>
                  </ReduxTable>
                  <DeleteModal
                    id="deletePartnerType"
                    isOpen={deletePartnerTypeModal}
                    toggle={() => {
                      this.setState({ deletePartnerTypeModal: false });
                    }}
                    title="Delete PartnerType"
                    label={deletePartnerType.name}
                    deleteFunction={() =>
                      this._deletePartnerTypeDetails(deletePartnerType.id)
                    }
                  />
                </div>
              </>
            )}
          </TabPane>
        </TabContent>
      </>
    );
  }
}

export default PartnerTypeTab;
