import { clearCookie } from "./helper";

// Cookies
export const COOKIE_SESSION_TOKEN = "session_token";
export const COOKIE_USER_ID = "userId";
export const COOKIE_ROLE = "role";
export const COOKIE_CUSTOMER_ID = "customerId";
export const COOKIE_CUSTOMER_NAME = "customerName";
export const COOKIE_MARKETPLACE_TALENT_NAME = "marketplace_talent_name";
export const COOKIE_SHOW_REFFERRAL_APPROVAL_NOTIFICATION_POPUP =
  "showReferralApprovalNotificationPopup";
export const COOKIE_SHOW_REFFERRAL_CLOSED_NOTIFICATION_POPUP =
  "showReferralClosedNotificationPopup";
export const COOKIE_SHOW_ASSIGNED_PARTNERS = "showAssignedPartners";
export const COOKIE_PARTNER_PROFILE_COMPLETED = "partnerProfileCompleted";
export const COOKIE_PARTNER_PROFILE_ACCEPTED = "partnerProfileAccepted";
export const COOKIE_SHOW_CAMPAIGNS_NOTIFICATION_POPUP =
  "showCampaignsNotificationPopup";
export const COOKIE_SHOW_APPS_APPROVAL_NOTIFICATION_POPUP =
  "showAppsApprovalNotificationPopup";
export const COOKIE_TERMINOLOGY = "terminology";
export const COOKIE_SHOW_REFFERRAL_REQUEST_NOTIFICATION_POPUP =
  "showReferralRequestNotificationPopup";
export const COOKIE_DEFAULT_CURRENCY = "default_currency";

/**
 * Clear all cookies
 *
 */
export function clearAllCookies() {
  clearCookie(COOKIE_SESSION_TOKEN);
  clearCookie(COOKIE_USER_ID);
  clearCookie(COOKIE_CUSTOMER_ID);
  clearCookie(COOKIE_CUSTOMER_NAME);
  clearCookie(COOKIE_SHOW_REFFERRAL_APPROVAL_NOTIFICATION_POPUP);
  clearCookie(COOKIE_SHOW_APPS_APPROVAL_NOTIFICATION_POPUP);
  clearCookie(COOKIE_SHOW_REFFERRAL_CLOSED_NOTIFICATION_POPUP);
  clearCookie(COOKIE_SHOW_REFFERRAL_REQUEST_NOTIFICATION_POPUP);
  clearCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
  clearCookie(COOKIE_SHOW_CAMPAIGNS_NOTIFICATION_POPUP);
  clearCookie(COOKIE_TERMINOLOGY);
  window.localStorage.clear();
  return "";
}
