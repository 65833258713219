import React, { useState } from "react";
import { ArrowUpIcon, TrashIcon } from "../assets/img/icons";
import { Badge } from "reactstrap";
import WithDefaultWrapper from "./WithDefaultWrapper";
import DeleteModal from "./base/DeleteModal";
// constans
import {
  PAYMENT_OPTION_ACH,
  PAYMENT_OPTION_WIRE_TRANSFER,
  PAYMENT_OPTION_INTERNATIONAL
} from "../Constants/PaymentOptions";

const PaymentOption = ({
  paymentOptions,
  setDefaultPaymentOption,
  deletePaymentOption
}) => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [currentId, setCurrentId] = useState("");
  return (
    <WithDefaultWrapper title={"Payment Options"}>
      <div className="payment-options options-list mt-4">
        {paymentOptions && paymentOptions.length > 0 ? (
          <>
            {paymentOptions.map(paymentOption => (
              <div className="payment-option">
                <div className="option-info">
                  <h6 className="font-weight-bold">
                    {paymentOption.paymentOption === PAYMENT_OPTION_ACH
                      ? PAYMENT_OPTION_ACH
                      : paymentOption.paymentOption ===
                        PAYMENT_OPTION_WIRE_TRANSFER
                      ? PAYMENT_OPTION_WIRE_TRANSFER
                      : PAYMENT_OPTION_INTERNATIONAL}
                  </h6>
                  <ul className="option-list list-unstyled">
                    {(paymentOption.paymentOption ===
                      PAYMENT_OPTION_WIRE_TRANSFER ||
                      paymentOption.paymentOption ===
                        PAYMENT_OPTION_INTERNATIONAL) && (
                      <>
                        <li>
                          <span>Name:</span>{" "}
                          {paymentOption.name && paymentOption.name}
                        </li>
                        <li>
                          <span>Address:</span>{" "}
                          {paymentOption.address && paymentOption.address}
                        </li>
                        <li>
                          <span>City:</span>{" "}
                          {paymentOption.city && paymentOption.city}
                        </li>
                        <li>
                          <span>State:</span>{" "}
                          {paymentOption.state && paymentOption.state}
                        </li>
                        <li>
                          <span>Country:</span>{" "}
                          {paymentOption.country && paymentOption.country}
                        </li>
                        <li>
                          <span>Zipcode:</span>{" "}
                          {paymentOption.postal_code &&
                            paymentOption.postal_code.slice(-4)}
                        </li>
                      </>
                    )}
                    {paymentOption.paymentOption ===
                      PAYMENT_OPTION_INTERNATIONAL && (
                      <>
                        <li>
                          <span>IBAN Number:</span>{" "}
                          {paymentOption.ibanNumber && paymentOption.ibanNumber}
                        </li>
                        <li>
                          <span>SWIFT Code:</span>{" "}
                          {paymentOption.swiftCode && paymentOption.swiftCode}
                        </li>
                        <li>
                          <span>Bank Name:</span>{" "}
                          {paymentOption.bankName && paymentOption.bankName}
                        </li>
                      </>
                    )}
                    {(paymentOption.paymentOption ===
                      PAYMENT_OPTION_WIRE_TRANSFER ||
                      paymentOption.paymentOption === PAYMENT_OPTION_ACH) && (
                      <>
                        <li>
                          <span>Routing Number:</span> ****{" "}
                          {paymentOption.routingNumber &&
                            paymentOption.routingNumber.slice(-4)}
                        </li>
                        <li>
                          <span>Account Number:</span> ****{" "}
                          {paymentOption.accountNumber &&
                            paymentOption.accountNumber.slice(-4)}
                        </li>
                      </>
                    )}
                  </ul>
                </div>
                <div className="btn-wrapper d-flex align-items-center">
                  {paymentOption && paymentOption.is_default ? (
                    <Badge color="primary" pill>
                      Primary
                    </Badge>
                  ) : (
                    <button
                      className={["btn", "btn-link"].join(" ")}
                      onClick={() => setDefaultPaymentOption(paymentOption.id)}
                    >
                      Make Primary <ArrowUpIcon />
                    </button>
                  )}
                  <button
                    className={["btn", "btn-plain", "text-danger", "ml-3"].join(
                      " "
                    )}
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setCurrentId(paymentOption.id);
                    }}
                  >
                    Delete <TrashIcon />
                  </button>
                  <DeleteModal
                    isOpen={openDeleteModal}
                    toggle={() => {
                      setOpenDeleteModal(prevState => !prevState);
                    }}
                    title="Delete Payment Option"
                    label={"Payment Option"}
                    deleteFunction={() => deletePaymentOption(currentId)}
                  />
                </div>
              </div>
            ))}
          </>
        ) : paymentOptions.length === 0 ? (
          <h6 className={["mt-3"].join(" ")}>
            You currently don't have any payment options added, please consider
            adding one!
          </h6>
        ) : (
          ""
        )}
      </div>
    </WithDefaultWrapper>
  );
};

export default PaymentOption;
