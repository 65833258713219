import React from "react";
import { Alert } from "reactstrap";

// Components
import { InlineInfoIcon } from "../../../../assets/img/icons";
import AlertBanner from "../../../../components/base/AlertBanner";

const OpportunityConflictToggle = props => {
  const { setOpportunityConflictToggleValues, id } = props;
  return (
    <>
      <>
        <AlertBanner
          icon={<InlineInfoIcon />}
          buttonLabel=" View Opportunities in Salesforce"
          id="viewAccountInSalesforce"
          removeOnClick={() => {
            setOpportunityConflictToggleValues({
              isOppOppotunityConflictNotification: false
            });
          }}
          message=" Account matched, but there may be an opportunity conflict."
        />
      </>
    </>
  );
};

export default OpportunityConflictToggle;
