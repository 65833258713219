import React from "react";

// Components
import DefaultContent from "../../components/content/DefaultContent";
import StatisticsCard from "../../components/base/StatisticsCard";

const AppActivityStats = ({ appId, appActivityStats }) => {
  return (
    appActivityStats && (
      <DefaultContent className={appId ? "pt-1 pb-1" : ""}>
        {appId && <h5 className="font-weight-bold mt-3">App stats</h5>}
        <div className={["app-card-count-statistics"].join(" ")}>
          {appActivityStats.map(data => {
            return <StatisticsCard count={data.value} label={data.label} />;
          })}
        </div>
      </DefaultContent>
    )
  );
};

export default AppActivityStats;
