import React from "react";
import ExpertRequestQuestion from "./ExpertRequestQuestion";
import HelpModal from "./HelpModal";

// Components
import SecondaryButton from "../../components/base/SecondaryButton";

//Assets
import { SwissKnifeIcon } from "../../assets/img/icons";

class ExpertType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expertTypeOptions: [
        {
          value: "App Builders, Developers and Administrators",
          label: "App Builders, Developers and Administrators",
          tooltip:
            "Platform Developers, App Builders, Commerce Cloud Digital Developers and QA"
        },
        {
          value: "Salesforce Essentials Advisor",
          label: "Salesforce Essentials Advisor",
          tooltip: "Sales, Service, Data, Migration, and Best Practices"
        },
        {
          value: "Salesforce Consultants",
          label: "Salesforce Consultants",
          tooltip:
            "Sales Cloud, Marketing Cloud, Community Cloud, Pardot, Services Cloud, Field Service Lightning Consultant"
        },
        {
          value: "Salesforce Architects",
          label: "Salesforce Architects",
          tooltip:
            "Data Architect and Management, Development Lifecycle and Deployment, Identity and Access Management, Integration Architecture, Mobile Solutions, System and Technical Architects"
        },
        {
          value: "Salesforce Marketing Cloud Specialist",
          label: "Salesforce Marketing Cloud Specialist",
          tooltip:
            "Email Studio Specialist, Social Studio, Journey Builder, Automation Studio, Pardot"
        },
        {
          value: "Contents Creators",
          label: "Contents Creators",
          tooltip:
            "Graphic Designers, UX Design and Development, Writers, Marketing Strategists"
        },
        {
          value: "Martech Solution Experts",
          label: "Martech Solution Experts",
          tooltip:
            "Hubspot, Eloqua, Marketo, Adobe, Oracle, Campaign Monitor, MailChimp"
        }
      ]
    };
  }

  render() {
    const { currentStep, step, prev, next } = this.props;
    const { expertTypeOptions } = this.state;

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <SwissKnifeIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-3">Expert Type</h2>
        </div>
        <div className="subtitle-wrapper mt-4">
          <h5 className="font-weight-light">
            What type of expert are you looking for?
          </h5>
        </div>
        {/* /.subtitle-wrapper */}
        <HelpModal label={"I want help figuring this out"} />
        <div className="content">
          <ExpertRequestQuestion
            name="expertType"
            options={expertTypeOptions}
            error="Expert type is required"
            // subTitleLabelIcon="fas fa-life-ring"
            // subTitleLabel="I want help figuring this out"
            required
          />
          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={next}
            >
              Next: Project Type
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpertType;
