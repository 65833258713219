// Pricing Type Start
export const PLAYBOOK_PRICING_TYPE_FIXED_PRICE = "Fixed Price";
export const PLAYBOOK_PRICING_TYPE_HOURLY_PAYMENT = "Hourly Payment";
export const PLAYBOOK_PRICING_TYPE_SUBSCRIPTION = "Subscription";
// Pricing Type End

// Playbook Tab Start
export const TAB_BASICS = "Basics";
export const TAB_DETAILS = "Details";
export const TAB_PRICING_AND_EXTRAS = "Pricing & Extras";
export const TAB_DELIVERABLES = "Deliverables";
export const TAB_PACKAGES = "Packages";
export const TAB_EXPERTS = "Experts";
export const PLAYBOOK_TAB = "Playbooks";
// Playbook Tab End

// Playbook Package Type Start
export const PLAYBOOK_PACKAGE_STANDARD = "Standard";
export const PLAYBOOK_PACKAGE_BASIC = "Basic";
export const PLAYBOOK_PACKAGE_PREMIUM = "Premium";
// Playbook Package Type End

// Pricing Schecule Type Start
export const PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_MONTHLY = "Monthly";
export const PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_QUARTERLY =
  "Quarterly";
export const PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_YEARLY = "Yearly";
// Pricing Schecule Type End
