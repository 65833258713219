import React from "react";
import { Container } from "reactstrap";
import SocialIcons from "../base/SocialIcons";
import moment from "moment";
import { getParamsByName } from "../../lib/helper";
import { COLOR_WHITE } from "../../../src/common/color";

const hide = getParamsByName("hideHeaderFooter");
const EndFooter = ({
  facebookUrl,
  linkedInUrl,
  instagramUrl,
  twitterUrl,
  copyRightsText,
  backgroundColor,
  textColor
}) => {
  return (
    <div
      className="end-footer"
      style={{
        background: backgroundColor ? backgroundColor : COLOR_WHITE,
        position: "fixed",
        left: "0",
        bottom: "0",
        right: "0",
        width: "100%",
        zIndex: "3"
      }}
    >
      {!hide && (
        <div>
          <Container>
            <div className="endfooter-wrapper d-flex justify-content-stretch">
              <p
                style={{
                  color: textColor ? textColor : "text-dark"
                }}
              >
                © {moment().year()} {copyRightsText}
              </p>

              <SocialIcons
                className="social-icons sidebar-content-box sidebar-content"
                facebook={facebookUrl}
                linkedIn={linkedInUrl}
                instagram={instagramUrl}
                twitter={twitterUrl}
              />
            </div>
          </Container>
        </div>
      )}
    </div>
  );
};

export default EndFooter;
