import React from "react";
import Rating from "react-rating";

// Assets
import { StarIcon, StarIconFilled } from "../../assets/img/icons";

// Components
import PlaybookCard from "../../components/PlaybookCard";

const ProjectReviewCard = props => {
  const {
    projectReviewDetail: { projectDetails, comment, userName, reviewRatings }
  } = props;

  // Get the overall_experience Rating Count
  let overall_experience = 0;
  reviewRatings &&
    reviewRatings.forEach(reviewRating => {
      if (reviewRating.name === "overall_experience") {
        overall_experience = parseInt(reviewRating.rating, 10);
      }
    });

  return (
    <div className="feedback-component d-flex justify-content-start w-100">
      <PlaybookCard
        data={projectDetails}
        isPreview={"true"}
        boxShadow={false}
        placeHolder={true}
        bannerImageUrl={projectDetails.bannerImageUrl}
        tag={"div"}
      />

      <div className="rating-component w-100">
        <div className="form-wrapper">
          <div className="field-wrapper mb-4-5 mt-0">
            <p>
              <Rating
                initialRating={overall_experience}
                emptySymbol={<StarIcon />}
                fullSymbol={<StarIconFilled />}
                readonly
              />
            </p>
            {comment && (
              <p>
                <span>"{comment}"</span>
              </p>
            )}

            <p className="font-weight-bold">
              - {userName ? `${userName},` : ""} {projectDetails.customerName}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProjectReviewCard;
