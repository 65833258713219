import React from "react";
import toast from "../../components/base/Toast";

// Page components
import CourseEditFields from "./CourseEditFields";

// Actions
import { fetchCourseList } from "../../actions/course";

// Components
import PageTitle from "../../components/base/PageTitle";
import Form from "../../components/FormWithErrorHandling";
import Spinner from "../../components/base/Spinner";
import Page404 from "../Page404";

// Configs
import { endpoints } from "../../configs";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// API call
import { apiClient, apiClientGet } from "../../apiClient";

// Constants
import {
  TAB_BASICS,
  TAB_PERMISSIONS,
  TAB_LESSON_MODULES,
  COURSE_DRAFT,
  COURSE_PUBLISHED,
  COURSE_PERMISSION_SELECTED_PARTNERS,
  COURSE_PERMISSION_INDIVIDUAL_PARTNER,
  COURSE_PERMISSION_ALL_PARTNERS,
  COURSE_PROGRESSIVE_COURSE,
  COURSE_TYPE_PROGRESSIVE,
  COURSE_TYPE_SINGLE
} from "../../course/Constants";

import MyContext from "../../context/MyContext";

import {
  TAG_TYPE_TRAINING_CATEGORY,
  TAG_TYPE_COURSE_LESSON_QUIZ_TYPE
} from "../../tagType/Constants";

import AddLessonModal from "./AddLessonModal";
import { isBadRequest } from "../../common/http";
import CertificateService from "../../services/certificateService";

class EditCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerColor: "",
      files: [],
      certificateImageFile: [],
      fileCount: "",
      bannerImageUrl: "",
      bannerImagePath: "",
      certificateImageUrl: "",
      certificateFileName: "",
      backButton: "Cancel",
      publishCourse: "Next",
      status: "",
      buttonType: "submit",
      currentTab: TAB_BASICS,
      nextTab: "",
      playbookIconValidate: true,
      playbookColorValidate: true,
      skills: [],
      tools: [],
      count: "",
      courseRequirement: "",
      isExpertLoading: false,
      isOpen: false,
      AddLessonModal: false,
      expertList: [],
      currentLessonData: "",
      courseId: "",
      expertIds: [],
      courseFile: "",
      courseFiles: [],
      courseCategory: [],
      quizTypeCategory: [],
      questionValue: "",
      defaultQuestionValue: "",
      // checkboxFieldValue: "",
      extrasValue: "",
      isSubmit: false,
      isErrors: false,
      isSubmitting: false,
      category: [],
      syllabusFileName: "",
      syllabusFile: "",
      course: {},
      mediaFiles: [],
      mediaFile: "",
      permissionRequired: false,
      courseDetails: "",
      checkboxFieldValue: {
        sharingPermission: ""
      },
      lessonData: "",
      isPublished: false,
      isUnPublished: false,
      certificateList: [],
      certificate: "",
      bannerImageFileName: "",
      reviewerList: []
    };
    this._handleBannerImagePath = this._handleBannerImagePath.bind(this);
    this.bannerImageUpload = this.bannerImageUpload.bind(this);
    this._handleCertificateImagePath = this._handleCertificateImagePath.bind(
      this
    );
    this.certificateImageUpload = this.certificateImageUpload.bind(this);

    this.nextButton = this.nextButton.bind(this);
  }

  componentDidUpdate() {
    fetchCourseList();
  }
  // Get Course Catergory Tag Values
  getTagValues = async courseTagType => {
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${courseTagType}`
      );
      const results = response.data.data;
      // Check the tag type skills
      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });
        this.setState({
          courseCategory: tagList
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };
  // Get Quiz Tag Values
  getQuizTagValues = async quizTagType => {
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${quizTagType}`
      );
      const results = response.data.data;
      // Check the tag type skills
      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });
        this.setState({
          quizTypeCategory: tagList
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  getBasicsDetails = async () => {
    this.setState({ isLoading: true }, () => {
      return apiClientGet(
        `${endpoints().courseAPI}/${this.props.match.params.id}`,
        (errMessage, response) => {
          if (errMessage) {
            this.setState({
              isPageNotFound: true,
              isLoading: false
            });
          } else {
            const courseDetails = response && response.data;
            this.setState({ courseDetails: courseDetails });
            this.setState({
              status:
                courseDetails && courseDetails.courseData.status
                  ? courseDetails.courseData.status
                  : COURSE_DRAFT
            });
            this.setState(prevState => ({
              checkboxFieldValue: {
                ...prevState.checkboxFieldValue,
                sharingPermission:
                  courseDetails && courseDetails.sharingPermission
                    ? courseDetails.sharingPermission
                    : COURSE_PERMISSION_ALL_PARTNERS,
                partnerTier:
                  courseDetails && courseDetails.sharedTags
                    ? courseDetails.sharedTags.partnerTier
                    : "",
                partnerType:
                  courseDetails && courseDetails.sharedTags
                    ? courseDetails.sharedTags.partnerType
                    : ""
              }
            }));

            this.setState({
              isLoading: false,
              courseDetails: courseDetails,
              reviewerList: courseDetails && courseDetails.reviewerList,
              course: courseDetails.courseData,
              lessonData: courseDetails.lessonData,
              bannerImagePath:
                courseDetails &&
                courseDetails.courseData &&
                courseDetails.courseData.banner_image,
              certificateImageUrl: courseDetails.certificateImage
            });
          }
        }
      );
    });
  };
  componentDidMount() {
    this.getBasicsDetails();
    this.getCertificateList();
    this.getTagValues(TAG_TYPE_TRAINING_CATEGORY);
    this.getQuizTagValues(TAG_TYPE_COURSE_LESSON_QUIZ_TYPE);
  }

  getCertificateList = async () => {
    try {
      let data = await CertificateService.list();
      this.setState({ certificateList: data });
    } catch (err) {
      console.log(err);
    }
  };
  setSubmitState = () => {
    this.setState({ isSubmit: true });
  };
  getQuestionValue = values => {
    this.setState({ questionValue: values });
  };

  reSetSubmitState = () => {
    this.setState({ isSubmit: false }, () => {
      this.setState({ isErrors: false });
    });
  };

  setIsErrors = () => {
    this.setState({ isErrors: true });
  };

  _lessonSubmit = values => {
    this._updateLesson(this._toLessonArray(values));
  };

  // Create playbook
  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    const {
      partnerTier,
      partnerType,
      sharingPermission
    } = this.state.checkboxFieldValue;

    // Check if the permission field is selected in case of selected partner option
    if (
      this.state.checkboxFieldValue &&
      sharingPermission === COURSE_PERMISSION_SELECTED_PARTNERS &&
      (partnerTier.length || partnerType.length) === 0
    ) {
      this.setState({
        permissionRequired: true
      });
      return;
    } else {
      this.setState({
        permissionRequired: false
      });
    }

    // Check if the permission field is selected in case of Individual partner option
    if (
      sharingPermission === COURSE_PERMISSION_INDIVIDUAL_PARTNER &&
      (values.sharedPartner === undefined || values.sharedPartner === null)
    ) {
      this.setState({
        permissionRequired: true
      });
      return;
    } else {
      this.setState({
        permissionRequired: false
      });
    }

    this._updateCourse(this._toArray(values), false);
  };

  // Back Button
  backButton = e => {
    let tab = e;
    this.setState({ currentTab: tab });
    if (tab === TAB_BASICS) {
      this.setState({ backButton: "Cancel" });
    } else {
      this.setState({ backButton: "Back" });
    }
    if (tab === TAB_LESSON_MODULES) {
      this.setState({ backButton: "Cancel" });
    } else {
      this.setState({ backButton: "Back" });
    }
    if (tab === TAB_PERMISSIONS) {
      this.setState({ publishCourse: "Publish Course" });
    } else {
      this.setState({ publishCourse: "Next" });
    }
  };

  // Set next course tab
  setNextTab = tab => {
    if (this.state.currentTab !== TAB_PERMISSIONS) {
      return this.setState({
        currentTab: tab,
        buttonType: "button"
      });
    }

    return this.setState({
      currentTab: tab,
      status: COURSE_PUBLISHED,
      buttonType: "submit",
      publishCourse: "Publish Course"
    });
  };

  // Next Button
  nextButton() {
    const currentTab = this.state.currentTab;
    if (this.state.isErrors) {
      return this.setSubmitState();
    }

    switch (currentTab) {
      case TAB_BASICS:
        this.setNextTab(TAB_LESSON_MODULES);
        break;
      case TAB_LESSON_MODULES:
        this.setNextTab(TAB_PERMISSIONS);
        break;
      default:
        return "";
    }
  }

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };
  // Set image preview in state
  base64Image = (certificateImageFile, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(certificateImageFile);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Banner Image Upload
  bannerImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";

    this.setState(
      {
        bannerImageUrl: URL.createObjectURL(files),
        bannerImagePath: files
      },
      () => this.setBase64Image(files, "files")
    );
  };

  // Banner Image Upload
  certificateImageUpload = e => {
    const certificateImageFile = e.target.files ? e.target.files[0] : "";

    if (certificateImageFile !== undefined) {
      this.setState({
        certificateFileName: certificateImageFile && certificateImageFile.name
      });
    }

    this.setState(
      { certificateImageUrl: URL.createObjectURL(certificateImageFile) },
      () => this.base64Image(certificateImageFile, "certificateImageFile")
    );
  };
  // Banner Image Remove
  handleCertificateImageRemove = () => {
    this.setState({ certificateImageUrl: "", certificateImageFile: "" });
  };

  // Banner Image Remove
  handleImageRemove = () => {
    this.setState({ bannerImageUrl: "", bannerImagePath: "" });
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const title = values.title;

    if (!title) {
      success = false;
    }

    return success;
  }
  // To Array
  _toLessonArray(values) {
    const data = new FormData();
    //Lesson Modules
    data.append(
      "lessonId",
      this.state.currentLessonData && this.state.currentLessonData.id
        ? this.state.currentLessonData.id
        : ""
    );
    data.append(
      "lessonTitle",
      values.lessonTitle ? values.lessonTitle.trim() : ""
    );
    data.append("textElement", values.textElement ? values.textElement : "");
    data.append("courseFile", values.courseFile ? values.courseFile : "");

    if (this.state.mediaFile) data.append("mediaFile", this.state.mediaFile);

    data.append("quizTitle", values.quizTitle ? values.quizTitle : "");
    data.append(
      "quizTypeCategory",
      values.quizTypeCategory ? values.quizTypeCategory.value : ""
    );
    let lessonQuestions = [];
    if (values && Object.keys(values).length > 0) {
      for (let fieldName of Object.keys(values)) {
        let fieldKey = `${fieldName}`.split("-");
        if (fieldKey[0] === "question" && values[fieldName]) {
          const answers = this.getAnswersByQuestionId(
            `${fieldKey[1]}${fieldKey[2] ? "-" + fieldKey[2] : ""}`,
            values
          );
          const correctAnswer = this.getCorrectAnswersByQuestionId(
            fieldKey[1],
            values.correctAnswers
          );
          const questionData = {
            questionId: `${fieldKey[1]}${fieldKey[2] ? fieldKey[2] : ""}`,
            value: values[fieldName],
            answers: answers,
            correctAnswer: correctAnswer
          };
          lessonQuestions.push(questionData);
        }
      }
      data.append("questions", JSON.stringify(lessonQuestions));
    }

    data.append(
      "multimediaUrl",
      values.multimediaUrl ? values.multimediaUrl : ""
    );
    return data;
  }

  getAnswersByQuestionId = (questionId, values) => {
    let answers = [];
    if (values && Object.keys(values).length > 0) {
      Object.keys(values).forEach(fieldName => {
        let fieldKey = `${fieldName}`.split("-");

        if (
          fieldKey[0] === "answer" &&
          fieldKey[1] === questionId &&
          values[fieldName]
        ) {
          const answerData = {
            questionId: questionId,
            answerId: fieldKey[2],
            value: values[fieldName],
            isAnswer:
              values[`correctAnswer-${questionId}`] === fieldKey[2] ? 1 : 0
          };
          answers.push(answerData);
        }
      });
    }
    return answers;
  };
  getCorrectAnswersByQuestionId = (questionId, values) => {
    let correctAnswers = "";
    if (values && Object.keys(values).length > 0) {
      Object.keys(values).forEach(fieldName => {
        let fieldKey = `${fieldName}`.split("-");

        if (
          fieldKey[0] === "correctAnswer" &&
          fieldKey[1] === questionId &&
          values[fieldName]
        ) {
          correctAnswers = values[fieldName];
        }
      });
    }
    return correctAnswers;
  };

  handleChange = values => {
    this.setState({
      certificate:
        values && values.value && values.value.value ? values.value.value : ""
    });
  };
  // To Array
  _toArray(values) {
    let reviwerIds =
      values && values.reviewer && values.reviewer.length > 0
        ? values.reviewer.map(value => value.value)
        : "";
    const { status } = this.state;
    const data = new FormData();
    data.append("title", values.title ? values.title : "");
    data.append("status", status);
    data.append("description", values.description ? values.description : "");
    data.append(
      "courseCategory",
      values.courseCategory
        ? values.courseCategory.value
        : values.category
        ? values.category.value
        : ""
    );
    data.append(
      "courseRequirement",
      this.state.courseRequirement
        ? this.state.courseRequirement === COURSE_PROGRESSIVE_COURSE
          ? COURSE_TYPE_PROGRESSIVE
          : COURSE_TYPE_SINGLE
        : this.state.course.type
        ? this.state.course.type
        : COURSE_TYPE_SINGLE
    );
    data.append(
      "progressiveCourse",
      values.progressiveCourse
        ? values.progressiveCourse.id
        : (this.state.course && this.state.course.link_course_id) || ""
    );
    data.append("reviewer", reviwerIds);
    data.append(
      "certificateImagePath",
      this.state.certificateImageUrl ? this.state.certificateImageUrl : ""
    );
    data.append(
      "certificateFileName",
      this.state.certificateFileName ? this.state.certificateFileName : ""
    );
    data.append(
      "bannerImagePath",
      this.state.bannerImagePath ? this.state.bannerImagePath : ""
    );
    data.append("courseFile", this.state.courseFile);
    data.append("courseFileName", this.state.courseFileName);
    data.append("syllabusFileName", this.state.syllabusFileName);
    data.append("syllabusFile", this.state.syllabusFile);
    // data.append("file", this.state.files);
    data.append("certificateFile", this.state.certificateImageFile);

    data.append(
      "sharedTags",
      this.state.checkboxFieldValue
        ? this.state.checkboxFieldValue && [
            ...this.state.checkboxFieldValue.partnerType
          ]
        : ""
    );
    data.append(
      "partnerTiers",
      this.state.checkboxFieldValue
        ? this.state.checkboxFieldValue && [
            ...this.state.checkboxFieldValue.partnerTier
          ]
        : ""
    );
    data.append(
      "sharingPermission",
      this.state.checkboxFieldValue
        ? this.state.checkboxFieldValue.sharingPermission
        : ""
    );
    data.append(
      "sharedPartnerId",
      values.sharedPartner ? values.sharedPartner.id : ""
    );

    if (values.sendNotification !== undefined) {
      data.append("sendNotification", values.sendNotification);
    }

    if (values && values.certificateName && values.certificateName) {
      data.append("certificateName", values.certificateName);
    }

    return data;
  }

  // Course update API Call
  _updateLesson(data) {
    this.setState({ isOpen: false, currentLessonData: "" });
    return apiClient
      .put(
        `${endpoints().courseAPI}/lesson/${this.props.match.params.id}`,
        data
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        setTimeout(() => {
          this.getBasicsDetails();
          // this._getLessonList();
        }, 500);

        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isSubmitting: false });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Course update API Call
  _updateCourse(data) {
    return apiClient
      .put(`${endpoints().courseAPI}/${this.props.match.params.id}`, data)
      .then(response => {
        this.setState({ courseId: response.data.courseId });
        this.setState({ bannerImageUrl: "" });
        let successMessage;
        // if (response && response.data) {
        //   successMessage = response.data.message;
        // }
        if (this.state.isPublished) {
          successMessage = "Course published successfully";
        } else if (this.state.isUnPublished) {
          successMessage = "Course unpublished successfully";
        } else {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.setState({ isLoading: true });
        this.getTagValues(TAG_TYPE_TRAINING_CATEGORY);
        this.getBasicsDetails();
        this.setState({ isLoading: false });
        this.setState({
          courseFileName: "",
          courseFile: ""
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isSubmitting: false });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Get Lesson API Call
  _getLessonList = () => {
    return apiClient
      .get(
        `${endpoints().courseAPI}/search/lesson/${this.props.match.params.id}`
      )
      .then(response => {
        this.setState({
          isLoading: false,
          lessonData: response.data.lessonData,
          currentLessonData: response.data.lessonData
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isSubmitting: false });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
  // Handle OnChange playbook banner image path
  _handleBannerImagePath = e => {
    const target = e.target;
    const bannerImagePath = target.value;
    this.setState({ bannerImagePath });
  };

  // Handle OnChange playbook banner image path
  _handleCertificateImagePath = e => {
    const target = e.target;
    const certificateImagePath = target.value;
    this.setState({ certificateImagePath });
  };

  // Handle course file upload
  handleCourseFile = files => {
    let courseFileName = files[0].name.split(" ").join("");
    this.setState({ courseFileName: courseFileName, courseFile: files[0] });
    // this.setBase64Image(files[0], "syllabusFile");
  };

  // Delete course
  _handleDeleteCourseFile = () => {
    return apiClient
      .delete(`${endpoints().courseAPI}/file/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };

  getQuestionInitialValues = question => {
    const questionInitialValues = {};
    if (question && question.length > 0) {
      question.forEach(questionList => {
        questionList.questionAnswer.forEach((answerList, i) => {
          questionInitialValues[`answer-${questionList.id}-${answerList.id}`] =
            answerList.answer;
        });
        questionInitialValues[`question-${questionList.id}`] =
          questionList.question;
      });
    }
    return questionInitialValues;
  };

  // Set image preview in state
  setPreviewImage = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [`${state}Preview`]: reader.result });
    };
  };

  // Validate to publish
  _publish = () => {
    this.setState({ status: COURSE_PUBLISHED, isPublished: true });
    this.setSubmitState();
    setTimeout(() => {
      if (
        this.state.permissionRequired === false &&
        this.state.isErrors === false
      ) {
        this._getpublish();
      }
    }, 500);
  };

  // Publish the course
  _getpublish = () => {
    const data = { status: COURSE_PUBLISHED };
    apiClient
      .put(
        `${endpoints().courseAPI}/status/${this.props.match.params.id}`,
        data
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        const url = `/course?section=Published`;
        this.props.history.push(url);
      });
  };

  // Validate to unpublish
  _Unpublish = () => {
    this.setState({ status: COURSE_DRAFT, isUnPublished: true });
    this.setSubmitState();
    setTimeout(() => {
      if (
        this.state.permissionRequired === false &&
        this.state.isErrors === false
      ) {
        this._getUnpublish();
      }
    }, 500);
  };

  // Unpublish the corse
  _getUnpublish = () => {
    const data = { status: COURSE_DRAFT };
    apiClient
      .put(
        `${endpoints().courseAPI}/status/${this.props.match.params.id}`,
        data
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        const url = `/course?section=Draft`;
        this.props.history.push(url);
      });
  };

  onMediaFileChange = files => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file) this.setState({ mediaFile: file });
    }
  };

  render() {
    const {
      category,
      course,
      lessonData,
      isLoading,
      isPageNotFound,
      currentTab,
      isSubmit,
      isErrors,
      currentLessonData,
      permissionRequired,
      mediaFiles,
      courseDetails,
      checkboxFieldValue,
      courseCategory,
      certificateList,
      certificate,
      bannerImagePath,
      bannerImageUrl
    } = this.state;
    if (isLoading) {
      return <Spinner />;
    }
    if (isPageNotFound) return <Page404 />;
    let questionList =
      currentLessonData &&
      currentLessonData.lessonQuestion &&
      currentLessonData.lessonQuestion.length > 0
        ? currentLessonData.lessonQuestion
        : [];
    const questionInitialValues =
      this.getQuestionInitialValues(questionList) || [];

    if (questionList) {
      questionList &&
        questionList.length > 0 &&
        questionList.forEach(list => {});
    }

    let reviewerValue = [];

    let reviewerData = course && course.reviewer && course.reviewer.split(",");
    if (reviewerData && reviewerData.length > 0) {
      for (let i = 0; i < reviewerData.length; i++) {
        let data = this.state.reviewerList.find(
          value => value.value == reviewerData[i]
        );
        reviewerValue.push(data);
      }
    }

    const initialValues = {
      title: course.title,
      description: course.description,
      bannerImageUrl: "",
      certificateImageUrl: "",
      fileCount: "",
      category: course.category
        ? courseCategory.find(
            courseCategoryDetails =>
              courseCategoryDetails.value === course.category
          )
        : "",
      lessonId: currentLessonData.id || "",
      lessonTitle: currentLessonData.lesson_title || "",
      textElement: currentLessonData.text_element || "",
      multimediaUrl: currentLessonData.multimedia_url || "",
      courseFile: currentLessonData.courseFile || "",
      quizTitle: currentLessonData.quiz_title || "",
      reviewer: reviewerValue,
      quizTypeCategory: {
        label: currentLessonData.quiz_type || "",
        value: currentLessonData.quiz_type || ""
      },
      sharedPartner: courseDetails.sharedPartner
        ? {
            label:
              (courseDetails.sharedPartner &&
                courseDetails.sharedPartner.company_name) ||
              "",
            value:
              (courseDetails.sharedPartner && courseDetails.sharedPartner.id) ||
              "",
            id:
              (courseDetails.sharedPartner && courseDetails.sharedPartner.id) ||
              ""
          }
        : null,
      sendNotification: course && course.send_notification === 1 ? true : false,
      certificateName: course && course.certificate_name,
      ...questionInitialValues
    };

    return (
      <>
        <MyContext.Consumer>
          {context => (
            <>
              {context.isMobile && (
                <div className="d-flex">
                  <PageTitle label="Edit course" />
                </div>
              )}
              <AddLessonModal
                getLessonList={this._getLessonList}
                match={this.props.match}
                AddLessonModal={this.state.AddLessonModal}
                toggle={() => {
                  this.setState({ AddLessonModal: false });
                }}
              />
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  this._submit(values);
                }}
                showErrorToast={true}
                enableReinitialize={true}
                isSubmit={isSubmit}
                reSetSubmitState={this.reSetSubmitState}
                handleOnButtonSubmit={true}
                setIsErrorState={this.setIsErrors}
                isErrorsState={isErrors}
              >
                <CourseEditFields
                  reviewerList={this.state.reviewerList}
                  courseRequirement={values =>
                    this.setState({ courseRequirement: values })
                  }
                  isOpen={this.state.isOpen}
                  course={course}
                  initialValues={initialValues}
                  history={this.props.history}
                  match={this.props.match}
                  publishFun={this._publish}
                  UnpublishFun={this._Unpublish}
                  status={course.status}
                  toggleAddLessonModal={() =>
                    this.setState({
                      AddLessonModal: !this.state.AddLessonModal
                    })
                  }
                  _lessonSubmit={values => {
                    this._lessonSubmit(values);
                  }}
                  toggleEditLessonModal={value =>
                    this.setState({
                      isOpen: !this.state.isOpen,
                      currentLessonData: ""
                    })
                  }
                  setCurrentLessonData={e =>
                    this.setState({ currentLessonData: e })
                  }
                  getBasicsDetails={this.getBasicsDetails}
                  getLessonList={this._getLessonList}
                  count={e => this.setState({ count: e })}
                  handleBannerImagePath={this._handleBannerImagePath}
                  handleCertificateImagePath={this._handleCertificateImagePath}
                  bannerImageUpload={e => this.bannerImageUpload(e)}
                  certificateImageUpload={e => this.certificateImageUpload(e)}
                  bannerImageUrl={bannerImageUrl || bannerImagePath}
                  certificateImageUrl={this.state.certificateImageUrl}
                  handleImageRemove={this.handleImageRemove}
                  handleCertificateImageRemove={
                    this.handleCertificateImageRemove
                  }
                  backButton={this.backButton}
                  nextButton={this.nextButton}
                  currentTab={currentTab}
                  category={category}
                  handleCourseFile={this.handleCourseFile}
                  courseFile={courseDetails.courseFile}
                  getCheckboxFieldValue={values =>
                    this.setState({ checkboxFieldValue: values })
                  }
                  updatePermission={value =>
                    this.setState({ permissionRequired: value })
                  }
                  clearCourseFile={() =>
                    this.setState({ courseFile: "", courseFileName: "" })
                  }
                  permissionRequired={permissionRequired}
                  lessonData={lessonData}
                  currentLessonData={currentLessonData}
                  _submit={e => this._submit(e)}
                  getQuestionValue={e => this.getQuestionValue(e)}
                  onMediaFileChange={this.onMediaFileChange}
                  mediaFiles={mediaFiles}
                  sharingPermission={courseDetails.sharingPermission}
                  checkboxFieldValue={checkboxFieldValue}
                  saveBasicData={this.saveBasicData}
                  questions={questionList}
                  certificateList={certificateList}
                  handleChange={this.handleChange}
                />
              </Form>
            </>
          )}
        </MyContext.Consumer>
      </>
    );
  }
}
function mapStateToProps(state) {
  return {
    course: state.course,
    courseDetails: state.course ? state.course.data : {}
  };
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchCourseList }, dispatch)
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(EditCourse);
