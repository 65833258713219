import {
  REQUEST_DELETE_REFERRAL_LINK,
  RECEIVE_DELETE_REFERRAL_LINK,
  REFERRAL_LINK_DELETE_ERROR,
  REQUEST_CREATE_REFERRAL_LINK,
  RECEIVE_CREATE_REFERRAL_LINK,
  REFERRAL_LINK_CREATE_ERROR,
  REQUEST_UPDATE_REFERRAL_LINK,
  RECEIVE_UPDATE_REFERRAL_LINK,
  REFERRAL_LINK_UPDATE_ERROR
} from "../actionType/Constants";
import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import toast from "../components/base/Toast";
import { fetchList } from "./table";
import { isBadRequest } from "../common/http";

/**
 * Request for deleting referralLink
 */
export function requestDeleteReferralLink() {
  return {
    type: REQUEST_DELETE_REFERRAL_LINK
  };
}

/**
 * Receive for deleting referralLink
 */
export function receiveDeleteReferralLink() {
  return {
    type: RECEIVE_DELETE_REFERRAL_LINK
  };
}

/**
 * Receive for error deleting referralLink
 */
export function referralLinkDeleteError(error) {
  return {
    type: REFERRAL_LINK_DELETE_ERROR,
    error
  };
}

/**
 * Delete referralLink
 *
 * @param id
 * @returns {function(*): *}
 */
export function deleteReferralLink(id, params) {
  return dispatch => {
    dispatch(requestDeleteReferralLink());

    apiClient
      .delete(`${endpoints().referralLinkAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "referralLinks",
            `${endpoints().referralLinkAPI}/search`,
            1,
            10,
            params
          )
        );
      })
      .catch(error => {
        dispatch(referralLinkDeleteError(error));
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}

/**
 * Request for creating referralLink
 */
export function requestCreateReferralLink() {
  return {
    type: REQUEST_CREATE_REFERRAL_LINK
  };
}

/**
 * Receive for receive referralLink
 */
export function receiveCreateReferralLink() {
  return {
    type: RECEIVE_CREATE_REFERRAL_LINK
  };
}

/**
 * Receive for error creating referralLink
 */
export function ReferralLinkCreateError(error) {
  return {
    type: REFERRAL_LINK_CREATE_ERROR,
    error
  };
}

/**
 * Create referralLink
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function addReferralLink(data, params) {
  return dispatch => {
    dispatch(requestCreateReferralLink());

    return apiClient
      .post(`${endpoints().referralLinkAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "referralLinks",
            `${endpoints().referralLinkAPI}/search`,
            1,
            10,
            params
          )
        );
        dispatch(receiveCreateReferralLink());
      })
      .catch(error => {
        dispatch(ReferralLinkCreateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Request for updating referralLink
 */
export function requestUpdateReferralLink() {
  return {
    type: REQUEST_UPDATE_REFERRAL_LINK
  };
}

/**
 * Receive for updating referralLink
 */
export function receiveUpdateReferralLink() {
  return {
    type: RECEIVE_UPDATE_REFERRAL_LINK
  };
}

/**
 * Receive for error updating referralLink
 */
export function ReferralLinkUpdateError(error) {
  return {
    type: REFERRAL_LINK_UPDATE_ERROR,
    error
  };
}

/**
 * Update referralLink details
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateReferralLink(id, data, params) {
  return dispatch => {
    dispatch(requestUpdateReferralLink());
    apiClient
      .put(`${endpoints().referralLinkAPI}/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "referralLinks",
            `${endpoints().referralLinkAPI}/search`,
            1,
            10,
            params
          )
        );
      })
      .catch(error => {
        dispatch(ReferralLinkUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Create referralLink
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function addReferralLinkParameters(id, data, params) {
  return dispatch => {
    dispatch(requestCreateReferralLink());

    return apiClient
      .post(`${endpoints().referralLinkAPI}/parameter`, data)
      .then(response => {
        dispatch(
          fetchList(
            "link",
            `${endpoints().referralLinkAPI}/parameter/${id}`,
            1,
            10,
            params
          )
        );
        dispatch(receiveCreateReferralLink());
      })
      .catch(error => {
        dispatch(ReferralLinkCreateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Update referralLink Parameters
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function updateReferralLinkParameters(id, parameterId, data, params) {
  return dispatch => {
    dispatch(requestCreateReferralLink());

    return apiClient
      .put(`${endpoints().referralLinkAPI}/parameter/${parameterId}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "link",
            `${endpoints().referralLinkAPI}/parameter/${id}`,
            1,
            10,
            params
          )
        );
        dispatch(receiveCreateReferralLink());
      })
      .catch(error => {
        dispatch(ReferralLinkCreateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Create referralLink
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function deleteReferralLinkParameters(id, linkId) {
  return dispatch => {
    dispatch(requestDeleteReferralLink());

    return apiClient
      .delete(`${endpoints().referralLinkAPI}/parameter/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "link",
            `${endpoints().referralLinkAPI}/parameter/${linkId}`
          )
        );
        dispatch(receiveDeleteReferralLink());
      })
      .catch(error => {
        dispatch(ReferralLinkCreateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}
