import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownItem } from "reactstrap";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import MoreDropdown from "../../components/base/MoreDropdown";
import { UserIcon } from "../../assets/img/icons";

// Helper
import { getFullName } from "../../lib/helper";

// Config
import { endpoints } from "../../configs";

// Action
import { updateExpert } from "../../actions/expert";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class PendingExperts extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "first_name:ASC",
          label: "Name"
        }
      ]
    };
  }

  // Update The Expert By Status
  updateExpertByStatus(status, expertId) {
    this.props.actions.updateExpert(
      expertId,
      { marketplaceStatus: status },
      {
        marketplaceStatus: "Pending",
        sort: this.props.pendingExpertsSort,
        sortDir: this.props.pendingExpertsSortDir
      }
    );
  }

  render() {
    const currentUrl = window.location.pathname + window.location.search;

    return (
      <div>
        <ReduxTable
          id="pendingExperts"
          apiURL={`${endpoints().expertAPI}/search`}
          params={{ marketplaceStatus: "Pending" }}
          onRowClick={row =>
            window.open(
              `/expert-profile/${row.id}?redirect=${currentUrl}`,
              "_blank"
            )
          }
          searchPlaceholder="Search"
          sortByOptions={this.state.sortByOptions}
          newTableHeading
          showHeader
          icon={<UserIcon />}
          message="You can start by inviting Experts."
        >
          <ReduxColumn
            type="link"
            isClickable="true"
            renderField={row => getFullName(row.firstName, row.lastName)}
            sortBy="first_name"
          >
            Name
          </ReduxColumn>
          <ReduxColumn field="email" disableOnClick>
            Email
          </ReduxColumn>

          <ReduxColumn field="jobTitle" sortBy="job_title">
            Category
          </ReduxColumn>
          <ReduxColumn field="createdAt" sortBy="createdAt">
            Date
          </ReduxColumn>
          <ReduxColumn
            field="marketplaceStatus"
            disableOnClick
            renderField={row => (
              <div
                className={`text-center text-uppercase ${
                  row.marketplaceStatus !== "Pending"
                    ? "landing-page-status-active"
                    : "landing-page-status-draft"
                }`}
                style={statusStyle}
              >
                <p>{row.marketplaceStatus}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() =>
                      window.open(
                        `/expert-profile/${row.id}?redirect=${currentUrl}`,
                        "_blank"
                      )
                    }
                  >
                    View Details
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      this.updateExpertByStatus("Approved", row.id)
                    }
                  >
                    Approve Expert
                  </DropdownItem>

                  <DropdownItem
                    onClick={() =>
                      this.updateExpertByStatus("Declined", row.id)
                    }
                  >
                    Decline Expert
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updateExpert }, dispatch)
  };
}

const mapStateToProps = state => {
  const { pendingExperts } = state.table;
  const pendingExpertsSort =
    pendingExperts && !pendingExperts.isFetching
      ? pendingExperts.sort
      : "createdAt";

  const pendingExpertsSortDir =
    pendingExperts && !pendingExperts.isFetching
      ? pendingExperts.sortDir
      : "DESC";
  return { pendingExpertsSort, pendingExpertsSortDir };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(PendingExperts);
