import React, { Component } from "react";

// Components
import Text from "../../../../../components/base/Text";
import Email from "../../../../../components/base/Email";
import Select from "../../../../../components/base/Select";
import SingleCheckbox from "../../../../../components/base/SingleCheckbox";

class CompanyFormFields extends Component {
  // Render the form fields
  render() {
    // Master Service Agreement Status options
    const masterServiceAgreementStatusOption = [
      {
        value: "Sent",
        label: "Sent"
      },
      {
        value: "Signed",
        label: "Signed"
      },
      {
        value: "Expired",
        label: "Expired"
      }
    ];
    // css for sent MSA
    const linkStyle = {
      padding: "0",
      paddingTop: "37px"
    };

    return (
      <div>
        <div className="card bg-white mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-9 col-sm-12">
                <div className="form-wrapper">
                  <div className="field-wrapper mb-0">
                    <Text
                      name="companyName"
                      label="Company Name"
                      placeholder="Enter Company Name"
                      error=""
                      required={true}
                    />
                  </div>
                  <div className="field-wrapper mb-0">
                    <Email
                      name="email"
                      label="Legal Contact Email"
                      placeholder="Enter Email"
                      error=""
                      required={true}
                    />
                  </div>

                  <div className="row">
                    <div className="col-9">
                      <Select
                        name="masterServiceAgreementStatus"
                        label="Master Service Agreement Status"
                        placeholder="Select MSA Status"
                        options={masterServiceAgreementStatusOption}
                        isSearchable={true}
                      />
                    </div>
                    <div className="col-3" style={linkStyle}>
                      <a
                        href="javascript:void(0)"
                        className={["text-underline"].join(" ")}
                      >
                        Send MSA
                      </a>
                    </div>
                  </div>

                  <div className="field-wrapper">
                    <SingleCheckbox
                      name="isDefaultCompany"
                      label="Default Company"
                      className="accepted-terms mb-0 pb-0 mr-3"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyFormFields;
