import React from "react";
import toast from "../../components/base/Toast";

// Page Components
import ProjectCategoryFormFields from "./ProjectCategoryFormFields";

// Components
import Form from "../../components/base/Form";
import Button from "../../components/base/Button";
import SaveButton from "../../components/base/SaveButton";
import CancelButton from "../../components/base/CancelButton";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import { toString } from "../../lib/helper";
import { isBadRequest } from "../../common/http";

export default class UpdateProjectCategoryForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      projectCategoryDetails: [],
      loading: false
    };
  }

  componentDidMount = () => {
    this._getProjectCategoryDetails();
  };

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save name form in the final
    this._updateProjectCategory(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define project constants
    const name = values.name;

    if (!name) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.name = toString(values.name);
    return values;
  }

  // Project update API Call
  _updateProjectCategory(data) {
    return apiClient
      .put(
        `${endpoints().projectCategoryAPI}/${this.props.match.params.id}`,
        data
      )
      .then(() => {
        window.location.replace("/admin/settings/project/categories");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Project delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().projectCategoryAPI}/${this.props.match.params.id}`)
      .then(() => {
        window.location.replace("/admin/settings/project/categories");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Project details api call by id
  _getProjectCategoryDetails = () => {
    return apiClient
      .get(`${endpoints().projectCategoryAPI}/${this.props.match.params.id}`)
      .then(response => {
        this.setState({ projectCategoryDetails: response.data, loading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  render() {
    if (!this.state.loading) {
      return "";
    }

    const { name } = this.state.projectCategoryDetails;

    const initialValues = {
      name
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          this._submit(values);
        }}
      >
        <ProjectCategoryFormFields />

        <div>
          <SaveButton />
          <CancelButton onClick={() => this.props.history.goBack()} />
          <Button
            label="Delete"
            align="right"
            onClick={this.handleDelete}
            color="red"
          />
        </div>
      </Form>
    );
  }
}
