import React from "react";
import { Badge } from "reactstrap";

// Assets
import { EditIconAlt, TrashIcon } from "../../../../../assets/img/icons";

const Tags = props => {
  let { task, openEditModal, openDeleteModal } = props;
  let {
    name,
    category,
    percentage,
    quota_type,
    isDefault,
    partner_type
  } = task;
  return (
    <div className=" task pr-4 pt-2 pb-1" style={{ minHeight: "70px" }}>
      <div className="task-left">
        <p className="pr-3 pt-3" style={{ cursor: "move", width: "50px" }}>
          <i className="fa fa-arrows"></i>
        </p>
        {/*Show Checkbox Based On Status*/}
        <div
          className="mr-2  name-comments"
          style={{ minWidth: "5.3rem", maxWidth: "5.3rem" }}
        >
          {/*Show Tags Name*/}
          <span className="text-decoration-none">{name}</span>
          <span className="text-decoration-none text-secondary">
            {isDefault ? "(Default)" : ""}
          </span>
          {category === "System" && (
            <Badge color="secondary" pill>
              Default
            </Badge>
          )}
        </div>
        <div
          className="ml-5"
          style={{ minWidth: "5.3rem", maxWidth: "5.3rem" }}
        >
          {/*Show Tags Name*/}
          <span className="text-decoration-none">
            {percentage > 0 ? percentage : ""}
          </span>
          {category === "System" && (
            <Badge color="secondary" pill>
              Default
            </Badge>
          )}
        </div>
      </div>

      <div className="task-right">
        {/*Show Task Actions For All Tasks Based on the Condition*/}
        <div className="task-actions">
          <>
            <div
              className="mr-5"
              style={{
                minWidth: "5.3rem",
                maxWidth: "5.3rem",
                position: "relative"
              }}
            >
              {/*Show Tags Name*/}
              <span className="text-decoration-none">{quota_type}</span>
              {category === "System" && (
                <Badge color="secondary" pill>
                  Default
                </Badge>
              )}
            </div>
            <span
              className={"mt-1 d-flex justify-content-between"}
              style={{
                visibility: category === "System" ? "hidden" : "show"
              }}
            >
              <button
                type="button"
                className={["edit-btn", "btn-link", "btn"].join(" ")}
                onClick={() => {
                  openEditModal(true);
                }}
              >
                <EditIconAlt />
              </button>
            </span>
            <button
              type="button"
              className={["delete-btn", "btn-link", "btn"].join(" ")}
              onClick={() => {
                openDeleteModal(true);
              }}
            >
              <TrashIcon />
            </button>
          </>
        </div>
      </div>
    </div>
  );
};

export default Tags;
