import React from "react";
import ExpertRequestQuestion from "./ExpertRequestQuestion";

// Components
import SecondaryButton from "../../components/base/SecondaryButton";
// Assets
import { WorldIcon } from "../../assets/img/icons";

class Budget extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      budgetOptions: [
        {
          value: "$1,500 -  $5,000",
          label: "$1,500 -  $5,000",
          tooltip: ""
        },
        {
          value: "$5,000 - $15,000",
          label: "$5,000 - $15,000",
          tooltip: ""
        },
        {
          value: "$15k+",
          label: "$15k+",
          tooltip: ""
        },
        {
          value: "I want help figuring this out",
          label: "I want help figuring this out",
          tooltip: ""
        }
      ]
    };
  }

  render() {
    const { currentStep, step, prev, next } = this.props;
    const { budgetOptions } = this.state;
    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <WorldIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-4">Budget</h2>
        </div>
        <div className="subtitle-wrapper mt-4">
          <h5 className="font-weight-light">
            What budget did you have in mind?
          </h5>
        </div>
        <div className="content">
          <ExpertRequestQuestion
            name="budget"
            options={budgetOptions}
            error="Budget is required"
            required
          />

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={next}
            >
              Next: Form Submission
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Budget;
