import React from "react";
import toast from "../../components/base/Toast";

// Components
import Form from "../../components/base/Form";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";

// Configs
import { endpoints } from "../../configs";

// API Call
import { apiClient } from "../../apiClient";

//Helper
import { toString } from "../../lib/helper";

import CollectionFormFields from "./CollectionFormFields";
import { isBadRequest } from "../../common/http";

export default class AddCollectionForm extends React.Component {
  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save collection form in the final
    this._createCollection(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const name = values.name;

    if (!name) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.name = toString(values.name);
    return values;
  }

  // Collection API Call
  _createCollection(data) {
    return apiClient
      .post(endpoints().collectionAPI, data)
      .then(() => {
        window.location.replace("/collection");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    const initialValues = {
      name: ""
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          this._submit(values);
        }}
      >
        <CollectionFormFields />
        <div className="d-flex justify-content-end">
          <CancelButton onClick={() => this.props.history.goBack()} />
          <SaveButton />
        </div>
      </Form>
    );
  }
}
