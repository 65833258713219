import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Constants
import { TIMEZONE_LIST } from "../../../../timezoneList/Constants";

// Helper
import { isExpert } from "../../../../lib/helper";

// Components
import useToggle from "../../../../components/customhooks/useToggle";
import Select from "../../../../components/base/Select";
import Form from "../../../../components/base/Form";
import Button from "../../../../components/base/Button";
import CancelButton from "../../../../components/base/CancelButton";
import { useSelector } from "react-redux";

const TimezoneModal = React.memo(function TimezoneModal({
  isOpen,
  toggle,
  timezones,
  updateTimezone,
  selectedTimezone
}) {
  const activeTimezone = selectedTimezone => {
    let timezone = "";
    TIMEZONE_LIST.map(zone => {
      if (zone.value === selectedTimezone) {
        timezone = { label: zone.label, value: zone.value };
        return timezone;
      }
    });
    return timezone;
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={["edit-task-modal"].join(" ")}
    >
      <Form
        initialValues={{
          time: activeTimezone(selectedTimezone)
        }}
        onSubmit={values => {
          updateTimezone(values.time);
          toggle();
        }}
      >
        <ModalHeader toggle={toggle}>
          <div
            className={["d-flex", "flex-column", "align-items-center"].join(
              " "
            )}
          >
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
              Select a timezone
            </h4>
          </div>
        </ModalHeader>
        <ModalBody className={["mb-4", "px-0"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <Select
                name={"time"}
                options={timezones}
                error="Timezone is required"
                isSearchable={true}
                required
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="btn-wrapper text-center">
            <CancelButton onClick={toggle} />
            <Button label="Set Timezone" type="submit" />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
});

const KickOffTimezone = React.memo(function KickOffTimezone({
  updateTimezone,
  selectedTimezone
}) {
  const [timezoneModal, setTimezoneModal] = useToggle();
  const getSelectedTimezone = selectedTimezone => {
    return TIMEZONE_LIST.map(zone =>
      zone.value === selectedTimezone ? zone.label : ""
    );
  };

  const currentUser = useSelector(state => state.user);

  return (
    <div
      className={
        "kickoff-scheduler__timezone d-flex align-items-start justify-content-stretch"
      }
    >
      <p>
        <strong>Time Zone:</strong> {getSelectedTimezone(selectedTimezone)}
      </p>
      {!isExpert(currentUser && currentUser.roleId) && (
        <button
          className="btn btn-link p-0"
          onClick={() => setTimezoneModal(prevState => !prevState)}
        >
          Edit
        </button>
      )}
      <TimezoneModal
        isOpen={timezoneModal}
        toggle={() => setTimezoneModal(prevState => !prevState)}
        updateTimezone={updateTimezone}
        timezones={TIMEZONE_LIST}
        selectedTimezone={selectedTimezone}
      />
    </div>
  );
});

export default KickOffTimezone;
