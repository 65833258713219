import React from "react";
import { Link } from "react-router-dom";
import CountUp from "react-countup";
import { DashboardProfileIcon } from "../../assets/img/icons";

const AdminNeedsAttentionCard = props => {
  const {
    pageURL,
    title,
    subTitle,
    count,
    bellIcon,
    avatorColor,
    countColor,
    textColor,
    id
  } = props;
  return (
    <div>
      <Link id={id} to={pageURL} className="onboarding-link">
        <div className="row w-100">
          <div className="col-10">
            <div>
              <i
                className={`pull-left ${bellIcon}`}
                style={{
                  backgroundColor: "#FF6347",
                  color: "#ffffff"
                }}
                aria-hidden="true"
              ></i>
            </div>
            <div className="need-attention">
              <h5 className="font-weight-bold">{title}</h5>
              <p className="font-weight-bold text-inline-grayed">{subTitle}</p>
            </div>
          </div>
          <div className="col-2">
            <div className="pull-right">
              <div
                className="onboarding-header"
                style={{ paddingLeft: "8rem" }}
              >
                <div className="step-wrapper">
                  <span
                    className={`step-count  completed  mr-4 ${avatorColor}`}
                  >
                    <DashboardProfileIcon />
                  </span>
                  <div>
                    <h3 className="mb-0 font-weight-bold text-link">
                      <CountUp
                        id={id}
                        style={{ color: `${countColor}` }}
                        separator=""
                        start={0}
                        end={count || 0}
                        duration={3}
                      />
                    </h3>
                    <Link
                      id={id}
                      style={{
                        color: `${textColor}`,
                        textDecoration: "underline"
                      }}
                      to={pageURL}
                    >
                      Review
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  );
};
export default AdminNeedsAttentionCard;
