import React from "react";
import propTypes from "prop-types";
import CountUp from "react-countup";
import { Link } from "react-router-dom";

class StatisticsCard extends React.Component {
  render() {
    const { count, label, prefix, redirectUrl, cardClassName } = this.props;

    //handle onClick
    const handleClick = e => {
      if (redirectUrl) {
        return;
      } else {
        e.preventDefault();
      }
    };

    return (
      <Link
        className={`card-statistic text-center text-decoration-none ${
          cardClassName ? cardClassName : ""
        }`}
        to={redirectUrl ? redirectUrl : false}
        style={{ cursor: "pointer" }}
        onClick={e => handleClick(e)}
      >
        <h2>
          <CountUp
            separator=","
            start={0}
            end={count || 0}
            duration={3}
            prefix={prefix}
          />
        </h2>
        <h6 className="">{label}</h6>
      </Link>
    );
  }
}

StatisticsCard.propTypes = {
  count: propTypes.number,
  label: propTypes.string.isRequired,
  prefix: propTypes.string
};

export default StatisticsCard;
