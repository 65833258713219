import React from "react";
import { getUserId } from "../../../lib/helper";
import MdfStatus from "./MdfStatus";
import SelectPartner from "./SelectPartner";

const SideBar = props => {
  let {
    status,
    mdfRequestId,
    mdfDetails,
    partnerIds,
    partnerId,
    createMdfPartner,
    getMdfDetails
  } = props;

  return (
    <div className="details-body-sidebar">
      <SelectPartner
        createMdfPartner={createMdfPartner}
        mdfRequestId={mdfRequestId}
        currentUserId={getUserId()}
        mdfDetails={mdfDetails}
        partnerId={partnerId}
        status={status}
        partnerIds={partnerIds}
        setMdfPartners={props.setMdfPartners}
        getMdfDetails={getMdfDetails}
      />
      <MdfStatus status={status} />
    </div>
  );
};

export default SideBar;
