// Referral Status
export const REFERRAL_STATUS_NEW = "New";
export const REFERRAL_STATUS_APPROVED = "Approved";
export const REFERRAL_STATUS_DECLINED = "Declined";
export const REFERRAL_STATUS_DRAFT = "Draft";
export const REFERRAL_STATUS_CLOSED = "Closed";
export const REFERRAL_STATUS_CLOSED_WON = "Closed Won";
export const REFERRAL_STATUS_EXPIRED = "Expired";
export const REFERRAL_STATUS_REQUESTED = "Requested";
export const REFERRAL_STATUS_ACCEPTED = "Accepted";
export const REFERRAL_STATUS_REJECTED = "Rejected";

export const REFERRAL_PARTNER_STATUS_PENDING = "Pending";
export const REFERRAL_PARTNER_STATUS_ACCEPTED = "Accepted";
export const REFERRAL_PARTNER_STATUS_NOT_INTERESTED = "Not Interested";

export const REFERRAL_SECTION_NEW = "new";
export const REFERRAL_SECTION_APPROVED = "approved";
export const REFERRAL_SECTION_CONVERTED = "converted";
export const REFERRAL_SECTION_DECLINED = "declined";

// Customer Readiness
export const CUSTOMER_READINESS_READY_TEXT =
  "Customer is ready to speak an Account Executive";
export const CUSTOMER_READINESS_NOT_READY_TEXT =
  "Customer is not ready yet, submitting for visibility";
export const CUSTOMER_READINESS_PURCHASED_READY_TEXT =
  "Customer has purchased and is ready for onboarding";
export const CUSTOMER_READINESS_READY = 1;
export const CUSTOMER_READINESS_NOT_READY = 2;
export const CUSTOMER_READINESS_PURCHASED_READY = 3;

//Customer Readiness Checkbox Constant
export const readinessOptions = [
  {
    id: CUSTOMER_READINESS_READY,
    name: CUSTOMER_READINESS_READY_TEXT
  },
  {
    id: CUSTOMER_READINESS_NOT_READY,
    name: CUSTOMER_READINESS_NOT_READY_TEXT
  },
  {
    id: CUSTOMER_READINESS_PURCHASED_READY,
    name: CUSTOMER_READINESS_PURCHASED_READY_TEXT
  }
];

export const Referral = {
  AMOUNT_REQUIRED_ON_PARTNER_SUBMIT: 1,
  AMOUNT_REQUIRED_ON_APPROVAL: 2,
  AMOUNT_OPTIONAL_ON_PARTNER_SUBMIT: 3,
  AMOUNT_OPTIONAL_ON_APPROVAL: 4,
  AMOUNT_REQUIRED_ON_PARTNER_SUBMIT_TEXT: "Required On Partner Submit",
  AMOUNT_REQUIRED_ON_APPROVAL_TEXT: "Required On Approval",
  AMOUNT_OPTIONAL_ON_PARTNER_SUBMIT_TEXT: "Optional On Partner Submit",
  AMOUNT_OPTIONAL_ON_APPROVAL_TEXT: "Optional On Approval"
};

export const SHOW_REFERRAL_AMOUNT = "show_referral_amount";

//Customer Readiness Checkbox Constant
export const ShowReferralAmountOptions = [
  {
    label: Referral.AMOUNT_REQUIRED_ON_PARTNER_SUBMIT_TEXT,
    value: Referral.AMOUNT_REQUIRED_ON_PARTNER_SUBMIT
  },
  {
    label: Referral.AMOUNT_REQUIRED_ON_APPROVAL_TEXT,
    value: Referral.AMOUNT_REQUIRED_ON_APPROVAL
  },
  {
    label: Referral.AMOUNT_OPTIONAL_ON_PARTNER_SUBMIT_TEXT,
    value: Referral.AMOUNT_OPTIONAL_ON_PARTNER_SUBMIT
  },
  {
    label: Referral.AMOUNT_OPTIONAL_ON_APPROVAL_TEXT,
    value: Referral.AMOUNT_OPTIONAL_ON_APPROVAL
  }
];

//Submitted From
export const REFERRAL_SUBMITTED_FROM_LINK_TRACKING = "Link Tracking";
export const REFERRAL_SUBMITTED_FROM_SALESFORCE = "Salesforce";
