import React from "react";
import toast from "../../../components/base/Toast";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchCourseList } from "../../../actions/course";

// Configs
import { endpoints } from "../../../configs";

// API call
import { apiClient } from "../../../apiClient";

// Components
import GridCard from "../../../components/base/GridCard";

import DeleteModal from "../../../components/base/DeleteModal";

import { COURSE_PERMISSION_ALL_PARTNERS } from "../../../../src/course/Constants";
// Helper
import {
  getParamsByName,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  isPartner
} from "../../../lib/helper";
import { isBadRequest } from "../../../common/http";

class CourseCardGrid extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deleteFormModal: false,
      deleteForm: false,
      currentCourse: ""
    };
  }
  // Form URL
  updateUrl(data) {
    const { search, page, pageSize, sort, sortDir, status } = data;

    const params = [];

    if (search) {
      params.push(`search=${search}`);
    }

    if (status) {
      params.push(`status=${status}`);
    }

    if (page) {
      params.push(`page=${page}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    if (params.length > 0) {
      return params.join("&");
    }
  }

  // Partner course delete api call

  handleDelete = courseId => {
    return apiClient
      .delete(`${endpoints().courseAPI}/${courseId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        const status = getParamsByName("section") || this.prop.activeTab;
        const url = `/course?section=${status}`;
        this.props.history.push(url);
        this.props.actions.fetchCourseList(
          this.updateUrl({
            pageSize: this.props.params.pageSize,
            status: this.props.params.status || this.props.activeTab,
            page: this.props.params.page
          })
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  render() {
    const { courses, history } = this.props;
    const { deleteFormModal, currentCourse } = this.state;

    return (
      <div className="row">
        <DeleteModal
          title="Delete Course"
          isOpen={deleteFormModal}
          toggle={() => {
            this.setState({ deleteFormModal: false });
          }}
          label={currentCourse.title}
          deleteFunction={() => {
            this.handleDelete(currentCourse.id);
          }}
        />
        {courses.map(course => (
          <>
            <GridCard
              permission={
                course.sharingPermission === COURSE_PERMISSION_ALL_PARTNERS
                  ? course.sharingPermission
                  : ""
              }
              showCategory
              showOption={
                isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)
              }
              data={{ ...course, partnerId: course.partner_id }}
              link={
                isPartner(this.props.roleId)
                  ? `/course/details/${course.id}`
                  : "" ||
                    isSuperAdmin(this.props.roleId) ||
                    isCompanyAdmin(this.props.roleId) ||
                    isCompanyManager(this.props.roleId)
                  ? `/course/edit/${course.id}`
                  : ""
              }
              editLink={`/course/edit/${course.id}`}
              tag="a"
              onClickDelete={() =>
                this.setState({ deleteFormModal: true, currentCourse: course })
              }
              history={history}
              isPreview
              right="18px"
              showEditOptions
              size={"col-xl-6 col-lg-6 col-md-6"}
              left="121px"
            />
          </>
        ))}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    course: state.course,
    courseDetails: state.course ? state.course.data : {},
    roleId: state.user ? state.user.roleId : ""
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchCourseList }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(CourseCardGrid);
