import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownItem } from "reactstrap";
import { toast } from "react-toastify";

// Components
import MoreDropdown from "../../components/base/MoreDropdown";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";
import { isLoggedIn } from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

// Action
import { updatePlaybookInquiry } from "../../actions/playbookInquiry";
import { fetchList } from "../../actions/table";
import { isBadRequest } from "../../common/http";

class PlaybookInquiry extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "name:ASC",
          label: "Name"
        }
      ]
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  // Update The Expert By Status
  updatePlaybookInquiryStatus(status, playbookInquiryId) {
    this.props.actions.updatePlaybookInquiry(
      playbookInquiryId,
      { status: status },
      {}
    );
  }

  handleDelete = id => {
    apiClient
      .delete(`${endpoints().lead}/${id}`)
      .then(response => {
        toast.success(response.data.message);

        this.props.actions.fetchList("playbookInquiry", "/v1/lead/search");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  render() {
    return (
      <div>
        <PageTitle label="Playbook Inquiry" />

        <div>
          <ReduxTable
            id="playbookInquiry"
            apiURL={`/v1/lead/search`}
            newTableHeading
            showHeader
            searchPlaceholder="Search"
            onRowClick={row =>
              this.props.history.push(`/playbook-inquiry/${row.id}`)
            }
            sortByOptions={this.state.sortByOptions}
          >
            <ReduxColumn field="companyName" type="link" isClickable="true">
              Company Name
            </ReduxColumn>
            <ReduxColumn field="name">Name</ReduxColumn>
            <ReduxColumn field="email">Email</ReduxColumn>
            <ReduxColumn field="playbookName">Playbook</ReduxColumn>
            <ReduxColumn field="createdAt">Created</ReduxColumn>
            <ReduxColumn field="status">Status</ReduxColumn>
            <ReduxColumn
              field="Action"
              disableOnClick
              renderField={row => (
                <div className="text-center landing-group-dropdown">
                  <MoreDropdown>
                    <DropdownItem
                      onClick={() =>
                        this.props.history.push(`/playbook-inquiry/${row.id}`)
                      }
                    >
                      Edit Details
                    </DropdownItem>
                    {row.status !== "Pending" && (
                      <DropdownItem
                        onClick={() =>
                          this.updatePlaybookInquiryStatus("Pending", row.id)
                        }
                      >
                        Pending
                      </DropdownItem>
                    )}
                    {row.status !== "Close" && (
                      <DropdownItem
                        onClick={() =>
                          this.updatePlaybookInquiryStatus("Close", row.id)
                        }
                      >
                        Close
                      </DropdownItem>
                    )}
                    <DropdownItem
                      className="text-danger"
                      onClick={() => this.handleDelete(row.id)}
                    >
                      Delete
                    </DropdownItem>
                  </MoreDropdown>
                </div>
              )}
            >
              Action
            </ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updatePlaybookInquiry, fetchList }, dispatch)
  };
}

const mapStateToProps = state => {
  const { playbookInquiry } = state.table;
  const playbookInquirySort =
    playbookInquiry && !playbookInquiry.isFetching
      ? playbookInquiry.sort
      : "createdAt";

  const playbookInquirySortDir =
    playbookInquiry && !playbookInquiry.isFetching
      ? playbookInquiry.sortDir
      : "DESC";
  return { playbookInquirySort, playbookInquirySortDir };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(PlaybookInquiry);
