/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import toast from "../../../components/base/Toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchList } from "../../../actions/table";

// Assets
import createLandingIcon from "../../../assets/pages/landing-page/create-landing-page.png";

// Component
import AddButton from "../../../components/base/AddButton";
import Text from "../../../components/base/Text";
import Form from "../../../components/base/Form";
import Email from "../../../components/base/Email";
import Phone from "../../../components/base/Phone";

// Helper
import { getParamsByName, removeMaskedPhoneNumber } from "../../../lib/helper";

//API call
import { apiClient } from "../../../apiClient";

// Configs
import { endpoints } from "../../../configs";
import PageTitle from "../../../components/base/PageTitle";
import { isBadRequest } from "../../../common/http";

// Customer Create API Call
function contactCreate(data, props, toggle) {
  const { search } = props.params;
  let params = {
    search: search,
    pagination: true,
    sort: getParamsByName("sort"),
    sortDir: getParamsByName("sortDir")
  };

  return apiClient
    .post(endpoints().contactAPI, data)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      props.actions.fetchList("All", "/v1/contact/search", 1, 10, params);
      setTimeout(() => {
        toggle();
      }, 300);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    });
}

const AddContactModal = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <PageTitle
        label="Contacts"
        buttonHandler={toggle}
        buttonLabel="New Contact"
      />

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <div className="img-wrapper d-flex justify-content-center mb-2 mt-3">
                <img src={createLandingIcon} size="30" alt="" />
              </div>
              <p className="text-center font-weight-bold mb-3">
                Create a new Contact
              </p>
              <p className="text-center h6-5 mb-2"></p>
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.content-wrapper */}
        </ModalHeader>

        <Form
          initialValues={{
            firstName: "",
            lastName: "",
            email: "",
            phone: ""
          }}
          onSubmit={values => {
            values.phone =
              values.phone && removeMaskedPhoneNumber(values.phone);

            contactCreate(values, props, toggle);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div>
                <Text
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                  error=""
                  required={true}
                />
              </div>
              <div>
                <Text
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  error=""
                  required={true}
                />
              </div>
              <div>
                <Email
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                  error=""
                  required={true}
                />
              </div>
              <div>
                <Phone
                  name="phone"
                  label="Phone Number"
                  placeholder="Enter phone number"
                  error=""
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="" className="h6-5-important">
                  Create Contact
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(() => {
  return {};
}, mapDispatchToProps)(AddContactModal);
