/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import toast from "../../../components/base/Toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchList } from "../../../actions/table";

// Assets
import createLandingIcon from "../../../assets/pages/landing-page/create-landing-page.png";

// Component
import AddButton from "../../../components/base/AddButton";
import Text from "../../../components/base/Text";
import Form from "../../../components/base/Form";
import Email from "../../../components/base/Email";
import Phone from "../../../components/base/Phone";
import Select from "../../../components/base/Select";

// Helper
import { removeMaskedPhoneNumber } from "../../../lib/helper";

//API call
import { apiClient } from "../../../apiClient";

// Configs
import { endpoints } from "../../../configs";
import PageTitle from "../../../components/base/PageTitle";
import { isBadRequest } from "../../../common/http";

//Constants
const ACCOUNTS_PARTNER_TAB = "Partner";
const ACCOUNTS_PROSPECT_TAB = "Prospect";

// Customer Create API Call
function accountCreate(values, props, params, toggle) {
  let type = values && values.type ? values.type.value : null;

  let data = new FormData();
  data.append("companyName", values.companyName || "");
  data.append("email", values.email || "");
  data.append("firstName", values.firstName || "");
  data.append("lastName", values.lastName || "");
  data.append("phone", values.phone || "");
  data.append("type", type);

  return apiClient
    .post(endpoints().accountAPI, data)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
      }
      // props.actions.fetchList("accounts", "/v1/account/search");
      toggle();
    })
    .then(() => {
      if (type === ACCOUNTS_PARTNER_TAB) {
        props.actions.fetchList(
          "Partner",
          `${endpoints().accountAPI}/search`,
          1,
          10,
          {
            search: params.searchItem,
            pagination: true,
            type: ACCOUNTS_PARTNER_TAB
          }
        );
      } else if (type === ACCOUNTS_PROSPECT_TAB) {
        props.actions.fetchList(
          "Prospect",
          `${endpoints().accountAPI}/search`,
          1,
          10,
          {
            search: params.searchItem,
            pagination: true,
            type: ACCOUNTS_PROSPECT_TAB
          }
        );
      }

      props.actions.fetchList(
        "All",
        `${endpoints().accountAPI}/search`,
        1,
        10,
        {
          search: params.searchItem,
          pagination: true
        }
      );
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    });
}

const AddAccountModal = props => {
  const { params } = props;
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const typeOptions = [
    {
      label: "Partner",
      value: "Partner"
    },
    {
      label: "Prospect",
      value: "Prospect"
    }
  ];

  return (
    <>
      <PageTitle
        label="Accounts"
        buttonHandler={toggle}
        buttonLabel="New Account"
      />
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(false);
        }}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <div className="img-wrapper d-flex justify-content-center mb-2 mt-3">
                <img src={createLandingIcon} size="30" alt="" />
              </div>
              <p className="text-center font-weight-bold mb-3">
                Create a new Account
              </p>
              <p className="text-center h6-5 mb-2"></p>
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.content-wrapper */}
        </ModalHeader>

        <Form
          initialValues={{
            companyName: "",
            firstName: "",
            lastName: "",
            email: "",
            phone: "",
            type: ""
          }}
          onSubmit={values => {
            values.phone =
              values.phone && removeMaskedPhoneNumber(values.phone);
            accountCreate(values, props, params, toggle);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div>
                <Text
                  name="companyName"
                  label="Account Name"
                  placeholder="Enter Account Name"
                  error=""
                  required={true}
                />
              </div>
              <div>
                <Text
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                  error=""
                  required={true}
                />
              </div>
              <div>
                <Text
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  error=""
                  required={true}
                />
              </div>
              <div>
                <Email
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                  error=""
                  required={true}
                />
              </div>
              <div>
                <Phone
                  name="phone"
                  label="Phone Number"
                  placeholder="Enter phone number"
                  error=""
                />
              </div>
              <div>
                <Select
                  id="type"
                  name="type"
                  label="Type"
                  placeholder="Select Type"
                  options={typeOptions}
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button
                  type="submit"
                  label=""
                  className="h6-5-important"
                  disabled={!modal}
                >
                  Create Account
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(() => {
  return {};
}, mapDispatchToProps)(AddAccountModal);
