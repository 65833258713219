import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import {
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  ButtonDropdown
} from "reactstrap";
import { ChevronDown } from "../../assets/img/icons";
import { isCompanyManager, isCompanyAdmin } from "../../lib/helper";
import { useSelector } from "react-redux";

const SelectDropdown = props => {
  const [selectedValue, setSelectedValue] = useState({
    selectedValue: props.defaultValue
  });
  const [dropdownOpen, setOpen] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const currentUser = useSelector(state => state.user);

  const {
    dropdownLabel,
    dropdownLinks,
    menuPosition,
    color,
    textColor,
    width,
    hideCaret,
    hideChevron,
    selectName,
    getSelectedFilters,
    liveFilters,
    nlFormFilter,
    isPartnerFilter,
    handleChange,
    dropdownStyle,
    id,
    getValue
  } = props;
  useEffect(() => setDidMount(true), []);
  useEffect(() => {
    if (didMount && nlFormFilter) {
      getSelectedFilters(selectedValue, selectName);
    }
  }, [selectedValue]);
  useEffect(() => {
    if (didMount && nlFormFilter) {
      if (liveFilters[selectName] !== undefined) {
        setSelectedValue({
          selectedValue: [...liveFilters[selectName].selectedValue]
        });
      }
    }
  }, []);

  useEffect(() => {
    if (props.defaultValue) {
      handleChange(props.defaultValue);
    }
  }, []);

  const updateLabel = e => {
    setSelectedValue({ selectedValue: [e.target.dataset["label"]] });
  };

  let style = {};
  if (textColor) {
    style.color = `${textColor}`;
  }
  if (width) {
    style.width = `${width}`;
  }
  return (
    <div
      className={`${
        isPartnerFilter &&
        (isCompanyManager(currentUser && currentUser.roleId) ||
          isCompanyAdmin(currentUser && currentUser.roleId))
          ? "dropdown-wrapper d-inline-flex"
          : "dropdown-wrapper select-dropdown"
      }"select-dropdown align-items-center justify-content-end"`}
    >
      {" "}
      {dropdownLabel && (
        <p className="font-weight-bold mb-0 mr-2">{dropdownLabel}</p>
      )}
      <ButtonDropdown id={id} isOpen={dropdownOpen} toggle={toggle}>
        <DropdownToggle
          id={id}
          color={color}
          className={`dropdown-toggle ${hideCaret ? "hide-caret" : ""}`}
        >
          <span
            className={"d-flex justify-content-between align-items-center"}
            style={style}
          >
            {Object.values(selectedValue)[0] !== undefined &&
            Object.values(selectedValue)[0].length > 0
              ? Object.values(selectedValue)[0]
              : props.buttonLabel}

            {!hideChevron && <ChevronDown />}
          </span>
        </DropdownToggle>
        <DropdownMenu style={dropdownStyle} right={menuPosition}>
          {dropdownLinks
            .filter(listItem => listItem.placeholder === undefined)
            .map(listItem => (
              <React.Fragment key={listItem.value}>
                {listItem.type === "separator" ? (
                  <hr style={{ borderTop: "1px solid #CCCCCC" }} />
                ) : (
                  <DropdownItem
                    onClick={e => {
                      updateLabel(e);
                      handleChange &&
                        handleChange(
                          getValue
                            ? e.target.dataset["value"]
                            : e.target.dataset["label"]
                        );
                    }}
                    data-value={listItem.value}
                    data-label={listItem.label}
                    data-name={selectName}
                    id={listItem.value}
                  >
                    {listItem.label}
                  </DropdownItem>
                )}
              </React.Fragment>
            ))}
        </DropdownMenu>
      </ButtonDropdown>
    </div>
  );
};

SelectDropdown.defaultProps = {
  buttonLabel: "Button Label",
  color: "",
  hideCaret: false,
  dropdownLinks: [
    { label: "Link 1", value: "link1" },
    { label: "Link 2", value: "link2" },
    { label: "Link 3", value: "link3" }
  ],
  menuPosition: true
};

SelectDropdown.propTypes = {
  buttonLabel: PropTypes.string,
  color: PropTypes.string,
  hideCaret: PropTypes.bool,
  dropdownLinks: PropTypes.array,
  menuPosition: PropTypes.bool,
  onClick: PropTypes.func,
  selectName: PropTypes.string.isRequired,
  getSelectedFilters: PropTypes.func
};

export default SelectDropdown;
