import React, { useEffect, useRef } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

const HubspotFormModel = props => {
  const { isOpen, closeModal, data, title, hubspotFormUrl } = props;

  const iframeRef = useRef(null);
  useEffect(() => {
    setTimeout(loadIframeContent, 0);
  }, [isOpen]);

  const loadIframeContent = () => {
    if (iframeRef.current) {
      // Define the query parameters to prefill the fields
      const queryParams = {
        company: data.companyName,
        email: data.email,
        firstname: data.firstName,
        lastname: data.lastName
      };

      // Construct the final URL with query parameters
      const urlWithParams = new URL(hubspotFormUrl);

      Object.keys(queryParams).forEach(param => {
        urlWithParams.searchParams.append(param, queryParams[param]);
      });

      iframeRef.current.src = urlWithParams.toString();
    }
  };

  return (
    <span className="d-flex justify-content-center align-items-center">
      <Modal
        isOpen={isOpen}
        toggle={closeModal}
        contentLabel={title}
        className="success-modal-popup"
        backdrop="static"
      >
        <ModalHeader toggle={closeModal}>Upgrade Form</ModalHeader>
        <ModalBody>
          <iframe
            id="hubspotFormIframe"
            ref={iframeRef}
            frameBorder="0"
            style={{
              width: "100%",
              maxHeight: "800px",
              minHeight: "500px",
              height: "800px"
            }}
          ></iframe>
        </ModalBody>
      </Modal>
    </span>
  );
};

export default HubspotFormModel;
