import React, { useEffect, useState } from "react";

import "react-datepicker/dist/react-datepicker.css";

// Components
import PageTitle from "../../../../components/base/PageTitle";

import * as API from "../../../../referral/actions";

import Spinner from "../../../../components/base/Spinner";

import { Nav, NavItem, NavLink } from "reactstrap";

import classnames from "classnames";

import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";

// Configs
import { endpoints } from "../../../../configs";

const ReferralDetails = props => {
  const [referralDetails, setReferralDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("ReferralDetails");

  useEffect(() => {
    getReferralDetails();
  }, []);

  const getReferralDetails = () => {
    setIsLoading(true);
    API.getReferralDetails(props.match.params.id, response => {
      if (response && response.data && response.data.data) {
        setReferralDetails(response.data.data);
      }
      setIsLoading(false);
    });
  };

  const toggleTabs = activeTab => {
    setActiveTab(activeTab);
  };

  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      <Nav tabs className="admin-tabs">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab == "ReferralDetails" ? true : false
            })}
            onClick={() => {
              toggleTabs("ReferralDetails");
            }}
          >
            Details
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab == "ReferralHistory" ? true : false
            })}
            onClick={() => {
              toggleTabs("ReferralHistory");
            }}
          >
            History
          </NavLink>
        </NavItem>
      </Nav>

      {activeTab == "ReferralDetails" ? (
        <div className="card bg-white mt-3 mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <PageTitle label={"Referral Details"} />
                {referralDetails &&
                  Object.keys(referralDetails).map(key => {
                    return (
                      <>
                        {typeof referralDetails[key] != "object" && (
                          <div className="mt-3">
                            <label htmlFor="text">{key}</label>
                            <input
                              type="text"
                              className="form-control"
                              value={referralDetails[key]}
                            />
                          </div>
                        )}
                      </>
                    );
                  })}
              </div>
            </div>
          </div>
        </div>
      ) : (
        <>
          <div className="mt-4">
            <ReduxTable
              id="url"
              apiURL={`${endpoints().referralApi}/referralHistory/search/${
                props.match.params.id
              }`}
            >
              <ReduxColumn field="field">Field</ReduxColumn>
              <ReduxColumn field="notes">Notes</ReduxColumn>
              <ReduxColumn field="original_value">Old value</ReduxColumn>
              <ReduxColumn field="new_value">New value</ReduxColumn>
            </ReduxTable>
          </div>
        </>
      )}
    </div>
  );
};

export default ReferralDetails;
