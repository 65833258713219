import React from "react";
import { Link } from "react-router-dom";
import { IntersectIcon, UserIcon } from "../../../assets/img/icons";
import AccountCard from "../../../components/base/AccountCard";

const AccountPartner = props => {
  const { partnerList } = props;
  return (
    <div>
      <div className="card-body pt-3">
        <h4 className="font-weight-bold mt-4">Partner</h4>
        {partnerList &&
          partnerList.length > 0 &&
          partnerList.map(data => {
            return (
              <>
                <div className="font-weight-bold mt-4">
                  <AccountCard companyName={data && data.company_name} />
                </div>
                <div className="ml-2 mt-3">
                  <div className="font-weight-bold mt-4">
                    <div className="row">
                      <div className="col-2">
                        <IntersectIcon />
                      </div>
                      <div className="col-10">
                        {data && data.overlapContactPercentage} %
                        <span className="text-secondary">{`  ${"Contact Overlap"}`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold mt-4">
                    <div className="row">
                      <div className="col-2">
                        <UserIcon />
                      </div>
                      <div className="col-10">
                        {data && data.overlapContactsCount}
                        <span className="text-secondary">{`  ${"Total Contacts"}`}</span>
                      </div>
                    </div>
                  </div>
                  <div className="font-weight-bold mt-4">
                    <Link
                      target="_blank"
                      to={`/account/edit/${data.account_id}`}
                    >
                      View Details
                    </Link>
                  </div>
                </div>
              </>
            );
          })}
      </div>
    </div>
  );
};

export default AccountPartner;
