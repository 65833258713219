import React from "react";
import UserContext from "../../context/userContext/UserContext";

// Components
import Form from "../../components/base/Form";
import SecondaryPortalRedirectModel from "./ineer-components/SecondaryPortalRedirectModel";

// Constants
import {
  COOKIE_TERMINOLOGY,
  COOKIE_SESSION_TOKEN,
  COOKIE_USER_ID,
  COOKIE_DEFAULT_CURRENCY
} from "../../lib/cookie";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints, DEFAULT_API_KEY } from "../../configs";

// Helper
import {
  setCookie,
  getUrlParameter,
  getRedirectUrl,
  getParamsByName,
  getCookie
} from "../../lib/helper";

// Library
import { metaCXTrack } from "../../lib/metaCX";

import LoginByPasswordForm from "./LoginByPasswordForm";

import { gaEvent } from "../../lib/GoogleAnalytics";
import { isBadRequest } from "../../common/http";
import toast from "../../components/base/Toast";
import { getKeyValueByObject } from "../../lib/helper";
import {
  SETTINGS_PASSWORD_EXPIRY_DAYS,
  SETTINGS_PORTAL_URL,
  ENABLE_2FA_ON_LOGIN
} from "../../setting/Constants";
import DateTime from "../../lib/DateTime";
import { randomString } from "../../common/utils";
import { TAG_TYPE_CURRENCY } from "../../tagType/Constants";
import Tag from "../../Constants/Tag";
import { Local } from "../../Constants/LocalStorage";
import UserRolePermissionService from "../../services/UserRolePermissionService";
import Toast from "../../components/base/Toast";

class BasicLoginForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorMessage: "",
      showSecondaryPortalRedirectModel: false,
      data: {},
      userDetails: "",
      passwordResetDays: "",
      portalUrl: "",
      enable2faLogin: false
    };
  }

  componentDidMount() {
    this.setState({ redirect: typeof this.props.redirect === "function" });
    this.getUserDetails();
    this.getTerminology();
    this.getTagValues(TAG_TYPE_CURRENCY);
    this.getSettings();
    this.getRolePermission();
  }

  _handleLogin = async (
    values,
    redirect,
    context,
    secondaryApiLoginUrl,
    forceSecondaryApiLogin
  ) => {
    const { settings, eventKey } = this.props;
    if (this.state.enable2faLogin == "true") {
      let data = {
        email: values && values.email,
        password: values && values.password
      };
      if (values && values.email !== "" && values.password !== "")
        await apiClient
          .post(`${endpoints().userAPI}/verifyAccount`, data)
          .then(response => {
            if (response && response.data) {
              if (response.status == 200) {
                Toast.success(response.data.message);
                const redirectUrl = `./two-factor-authentication?email=${encodeURIComponent(
                  values.email
                )}`;
                window.location.replace(redirectUrl);
              }
            }
          })
          .catch(error => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
                toast.error(error.response.data.message);
              }
              return { errorMessage } || {};
            }
          });
    } else {
      this._userLogin(
        values,
        redirect,
        context,
        secondaryApiLoginUrl,
        forceSecondaryApiLogin
      ).then(res => {
        return (
          res &&
          Object.keys(res).forEach(key => {
            // Setting Login Error Message
            if (key === "errorMessage") {
              this.setState({ errorMessage: "Invalid email or Password" });
            }

            if (key === "successMessage" && this.state.redirect) {
              // MetaCX Track after user logged-in with username and password
              metaCXTrack(
                eventKey || "user-login",
                {
                  email: res.email,
                  firstName: res.firstName,
                  lastName: res.lastName
                },
                settings,
                () => {}
              );
              context.loginUser();
              this.props.redirect();
            }
          })
        );
      });
    }
  };

  getTerminology = async () => {
    this.setHeaders();
    const url = `${endpoints().terminologyAPI}`;
    const customName = [];
    const response = await apiClient.get(url);
    const list = response.data.data;
    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        customName.push({
          custom_name: list[i].custom_name,
          system_name: list[i].system_name
        });
      }
    }
    localStorage.setItem(Local.TERMINOLOGY, JSON.stringify(customName));
  };

  getRolePermission = async role => {
    this.setHeaders();
    const permissionList = await UserRolePermissionService.search(role);
    if (permissionList && permissionList.length > 0) {
      let values = permissionList.map(obj => obj.value);

      // Convert the array to a comma-separated string
      let valuesString = values.join(",");

      localStorage.setItem(Local.PERMISSION, valuesString);
    }
  };
  getTagValues = async tagType => {
    try {
      this.setHeaders();

      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${tagType}`
      );
      const results = response.data.data;

      // Check the tag type skills
      if (results && results.length > 0) {
        results.forEach(tag => {
          if (
            tag.type == TAG_TYPE_CURRENCY &&
            tag.defaultValue == Tag.IsDefault
          ) {
            setCookie(COOKIE_DEFAULT_CURRENCY, tag.name);
          }
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };
  getSettings = async => {
    const { settings } = this.props;

    this.setHeaders();
    const days = settings
      ? getKeyValueByObject(settings, SETTINGS_PASSWORD_EXPIRY_DAYS)
      : "";
    const url = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_URL)
      : "";
    const enable2fAuth = settings
      ? getKeyValueByObject(settings, ENABLE_2FA_ON_LOGIN)
      : "";
    this.setState({
      passwordResetDays: days,
      portalUrl: url,
      enable2faLogin: enable2fAuth
    });
  };
  // Login with username and password
  _userLogin(
    data,
    redirect = false,
    context,
    secondaryApiLoginUrl,
    forceSecondaryApiLogin = true
  ) {
    const secondaryPortalAPIDefaultKey =
      this.props.secondaryPortalDetails &&
      this.props.secondaryPortalDetails.secondaryPortalAPIDefaultKey;

    apiClient.defaults.headers.common.Authorization = !secondaryApiLoginUrl
      ? DEFAULT_API_KEY
      : secondaryPortalAPIDefaultKey;
    return apiClient[!secondaryApiLoginUrl ? "post" : "get"](
      secondaryApiLoginUrl || endpoints().userLogin,
      data
    )
      .then(async response => {
        const secondaryPortalBaseUrl =
          this.props.secondaryPortalDetails &&
          this.props.secondaryPortalDetails.secondaryPortalBaseUrl;

        if (secondaryApiLoginUrl && secondaryPortalBaseUrl) {
          const { showSecondaryPortalRedirectModel } = this.state;
          return this.setState({
            showSecondaryPortalRedirectModel: !showSecondaryPortalRedirectModel,
            data
          });
        }

        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        const {
          token,
          role,
          userId,
          email,
          firstName,
          lastName,
          lastPasswordSetAt,
          password_token
        } = response.data;

        // Setting Cookies
        setCookie(COOKIE_SESSION_TOKEN, token);
        setCookie(COOKIE_USER_ID, userId);

        gaEvent({
          category: "Login",
          action: "Clicked on login by password",
          label: "Login",
          value: userId
        });

        // Resetting Error Message
        this.setState({ errorMessage: "" });
        let numberofdays = DateTime.GetNumberOfDays(lastPasswordSetAt);

        let passwordToken;

        if (password_token) {
          // if there user detail and password token
          passwordToken = password_token;
        } else if (!password_token) {
          // if there user detail but no password token
          passwordToken = randomString();
        }

        if (
          numberofdays > this.state.passwordResetDays &&
          this.state.passwordResetDays > 0
        ) {
          let value = {
            password_token: passwordToken
          };
          await apiClient
            .put(`${endpoints().userAPI}/${userId}`, value)
            .then(response => {
              if (response && response.data) {
                let value = response.data;
              }
              let redirectUrl = `${this.state.portalUrl}/set-password?token=${value.password_token}&email=${email}`;
              window.location.replace(redirectUrl);
            });
        } else {
          // Setting Cookies
          setCookie(COOKIE_SESSION_TOKEN, token);
          setCookie(COOKIE_USER_ID, userId);

          gaEvent({
            category: "Login",
            action: "Clicked on login by password",
            label: "Login",
            value: userId
          });
          if (!redirect) {
            const redirectUrl = getRedirectUrl();

            await this.getTerminology();
            await this.getTagValues();
            await this.getRolePermission(role);

            if (redirectUrl) {
              window.location.replace(redirectUrl);
            } else {
              window.location.replace("/dashboard");
            }
          }
        }

        return { successMessage, email, firstName, lastName } || {};
      })
      .catch(error => {
        const secondaryPortalApiBaseUrl =
          this.props.secondaryPortalDetails &&
          this.props.secondaryPortalDetails.secondaryPortalApiBaseUrl;

        if (forceSecondaryApiLogin && secondaryPortalApiBaseUrl) {
          return this._handleLogin(
            data,
            redirect,
            context,
            `${secondaryPortalApiBaseUrl}/v1/user/email/${data.email}`,
            false
          );
        }

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            toast.error(error.response.data.message);
          }
          return { errorMessage } || {};
        }
      });
  }

  setHeaders = () => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
  };

  getUserDetails = async () => {
    this.setHeaders();
    const encryptedUserId = getParamsByName("userId");
    try {
      const response = await apiClient.get(
        `${endpoints().publicAPI}/user/${encryptedUserId}`
      );
      const data = response.data;

      this.setState({ userDetails: data });
    } catch {}
  };

  render() {
    const email = getUrlParameter("email");
    const defaultEmail = this.state.userDetails.email;

    const initialValues = {
      email: defaultEmail || "",
      password: ""
    };
    const { errorMessage, showSecondaryPortalRedirectModel, data } = this.state;

    const { secondaryPortalDetails } = this.props;
    const secondaryPortalApiBaseUrl =
      secondaryPortalDetails &&
      secondaryPortalDetails.secondaryPortalApiBaseUrl;
    const secondaryPortalBaseUrl =
      secondaryPortalDetails && secondaryPortalDetails.secondaryPortalBaseUrl;
    const secondaryPortalRedirectMessage =
      secondaryPortalDetails &&
      secondaryPortalDetails.secondaryPortalRedirectMessage;
    return (
      <UserContext.Consumer>
        {context => (
          <Form
            initialValues={initialValues}
            enableReinitialize
            onSubmit={values => {
              values.email = values.email ? values.email : null;
              values.password = values.password ? values.password : null;

              this._handleLogin(values, this.state.redirect, context);
              return false;
            }}
          >
            {secondaryPortalApiBaseUrl && secondaryPortalBaseUrl && (
              <SecondaryPortalRedirectModel
                redirectPortalUrl={`${secondaryPortalBaseUrl}/login?email=${data.email}`}
                secondaryPortalRedirectMessage={secondaryPortalRedirectMessage}
                isOpen={showSecondaryPortalRedirectModel}
                toggle={() =>
                  this.setState({
                    showSecondaryPortalRedirectModel: !showSecondaryPortalRedirectModel
                  })
                }
              />
            )}
            <LoginByPasswordForm
              settings={this.props.settings}
              errorMessage={errorMessage}
            />
          </Form>
        )}
      </UserContext.Consumer>
    );
  }
}

export default BasicLoginForm;
