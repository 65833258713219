import React, { useEffect, useState } from "react";
import { Alert } from "reactstrap";
import moment from "moment-timezone";

// Icons
import {
  CheckmarkCircleIcon,
  CrossIcon,
  InlineInfoIcon
} from "../../../assets/img/icons";

// Helper
import { isCustomer, isExpert } from "../../../lib/helper";

// Components
import Form from "../../../components/base/Form";
import Text from "../../../components/base/Text";
import PreferredMethodOfCommunication from "../../../components/PreferredMethodOfCommunication";
import Radio from "../../../components/base/Radio";

// Inner Components
import KickOffMethodOfCommunication from "./kickoff/KickOffMethodOfCommunication";
import KickOffTimezone from "./kickoff/KickOffTimezone";
import KickOffTimePicker from "./kickoff/KickOffTimePicker";
import RescheduleModal from "./kickoff/RescheduleModal";
import {
  KickoffTips,
  KickoffTip,
  KickoffTipsWrapper
} from "./kickoff/KickoffTips";
import KickOffAddToCalendar from "./kickoff/KickOffAddToCalendar";

import ConfirmedKickoffMeetingForm from "./kickoff/ConfirmedKickoffMeetingForm";

// Api Client
import { apiClient } from "../../../apiClient";

import {
  COMMUNICATION_METHOD_WEB_CONFERERENCE,
  COMMUNICATION_METHOD_PHONE,
  WEB_CONFERERENCE_TYPE_PROVIDE_CONFERERENCE_URL,
  WEB_CONFERERENCE_TYPE_TORCHLITE
} from "../../../kickOffCall/Constants";

import { useSelector } from "react-redux";

const KickOffScheduler = React.memo(function KickOffScheduler({
  expertId,
  projectId
}) {
  const [alert, setAlert] = useState(true);
  const [communicationMethod, setCommunicationMethod] = useState(
    COMMUNICATION_METHOD_WEB_CONFERERENCE
  );
  const [selectedDates, setSelectedDates] = useState([]);
  const [kickoffScheduled, setKickoffScheduled] = useState(false);
  const [reschedule, setReschedule] = useState(false);
  const [kickoffData, setKickoffData] = useState({});
  const [availableTimes, setAvailableTimes] = useState([]);

  const [waitingForExpertSelection, setWaitingForExpertSelection] = useState(
    false
  );
  const [rescheduleModal, setRescheduleModal] = useState(false);
  const [expertAskedToReschedule, setExpertAskedToReschedule] = useState(false);
  const [kickoffScheduledCustomer, setKickoffScheduledCustomer] = useState(
    false
  );

  const currentUser = useSelector(state => state.user);

  const [calendarEvent, setCalendarEvent] = useState({
    title: "Sample Event",
    description: "This is the sample event provided as an example only",
    location: "Portland, OR",
    startTime: "2016-09-16T20:15:00-04:00",
    endTime: "2016-09-16T21:45:00-04:00"
  });

  const [kickoffLoaded, setKickoffLoaded] = useState(false);
  const [selectedDateId, setSelectedDateId] = useState(null);
  const [kickoffRescheduled, setKickoffRescheduled] = useState(null);
  const [expertMessage, setExpertMessage] = useState(null);
  const [meetingLink, setMeetingLink] = useState(null);
  const [meetingId, setMeetingId] = useState(null);
  const [meetingPassword, setMeetingPassword] = useState(null);
  const [phone, setPhone] = useState(null);
  const [webConferenceSource, setWebConferenceSource] = useState(
    WEB_CONFERERENCE_TYPE_TORCHLITE
  );

  const [defaultTimezone] = useState("America/New_York");
  const [visualTimezone, setVisualTimezone] = useState("America/New_York");

  const updateSelectedDates = dates => {
    setSelectedDates(dates);
  };
  const updateTimezone = timezone => {
    let value = Object.keys(timezone).length ? timezone.value : timezone;
    setVisualTimezone(value);
  };

  const updateSelectedDateId = id => {
    setSelectedDateId(id);
  };

  const handleCommunicationMethod = method => {
    setCommunicationMethod(
      method.values.communication_method
        ? method.values.communication_method.label
        : communicationMethod
    );
  };

  // API Consumptions Below
  const getSelectedDays = (dates, tz) => {
    let daysArray = dates.map(date =>
      moment(date)
        .tz(tz)
        .format("YYYY-MM-DD")
    );

    return [...new Set(daysArray)];
  };

  const getSelectedTimesByDay = (days, dates, tz) => {
    return days.map(day => {
      let dayZ = moment(day)
        .clone()
        .format("YYYY-MM-DD");

      let times = dates.filter(date => {
        let dateZ = moment(date)
          .clone()
          .tz(tz)
          .format("YYYY-MM-DD");
        return dayZ === dateZ;
      });

      return {
        date: dayZ,
        times: times.map(time =>
          moment(time)
            .tz(tz)
            .format("HH:mm")
        )
      };
    });
  };

  const getKickoffTimes = () => {
    return apiClient
      .get(`/v1/project/${projectId}/kickoff/${expertId}`)
      .then(res => res)
      .catch(err => console.error(err));
  };

  const setKickoffTimes = (
    communicationMethod = "Zoom",
    dates = [],
    timezone,
    status = "Scheduled"
  ) => {
    let selectedDays = getSelectedDays(dates, timezone);
    let selectedTimes = getSelectedTimesByDay(selectedDays, dates, timezone);
    let data = {
      communicationMethod,
      dates: selectedTimes,
      timezone,
      status,
      webConferenceSource
    };

    if (isCustomer(currentUser && currentUser.roleId)) {
      data.meetingId = meetingId;
      data.meetingLink = meetingLink;
      data.meetingPassword = meetingPassword;
      data.phone = phone;
      data.webConferenceSource = webConferenceSource;
    }

    return apiClient
      .post(`/v1/project/${projectId}/kickoff/${expertId}`, data)
      .then(res => {
        setKickoffData(res.data.data);
        setReschedule(false);
        setKickoffRescheduled(false);
        setExpertAskedToReschedule(false);
      });
  };

  const resetKickoffTimes = () => {
    return apiClient
      .delete(`/v1/project/${projectId}/kickoff/${expertId}`)
      .then(() => {
        setKickoffData(null);
        setAvailableTimes([]);
        setReschedule(true);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const confirmKickoffTime = () => {
    const { communicationMethod, web_conference_source } = kickoffData;

    return apiClient
      .patch(`/v1/project/${projectId}/kickoff/${expertId}/select`, {
        selectedTimeId: selectedDateId
      })
      .then(async res => {
        if (
          communicationMethod === COMMUNICATION_METHOD_WEB_CONFERERENCE &&
          web_conference_source === WEB_CONFERERENCE_TYPE_TORCHLITE
        ) {
          await apiClient.post(`/v1/bluejeans/schedule/kickoff`, {
            selectedTimeId: selectedDateId,
            projectId,
            expertId
          });

          loadKickoff();
        } else {
          setKickoffData(res.data.data);
          updateCalendarEvent(res.data.data);
          setKickoffScheduled(true);
        }
      });
  };

  const rescheduleKickOff = () => {
    setKickoffScheduled(prevState => !prevState);
    setReschedule(true);
    resetKickoffTimes().then(_ => setKickoffRescheduled(true));
  };

  const rescheduleKickOffExpert = (message = "") => {
    let userMessage = message;

    return apiClient
      .patch(`/v1/project/${projectId}/kickoff/${expertId}/select`, {
        status: "RESCHEDULED_EXPERT",
        message: userMessage
      })
      .then(res => {
        setReschedule(true);
        setAvailableTimes([]);
        setKickoffData(res.data.data);
        setKickoffRescheduled(true);
      });
  };

  const handleMeetingLink = e => {
    setMeetingLink(e.target.value);
  };

  const handleMeetingId = e => {
    setMeetingId(e.target.value);
  };

  const handleMeetingPassword = e => {
    setMeetingPassword(e.target.value);
  };

  const handlePhone = e => {
    setPhone(e.target.value);
  };

  const handleWebConferenceSource = e => {
    setWebConferenceSource(e.target.value);
  };

  const webConferenceSourceList = [
    WEB_CONFERERENCE_TYPE_TORCHLITE,
    WEB_CONFERERENCE_TYPE_PROVIDE_CONFERERENCE_URL
  ];

  const loadKickoff = () => {
    getKickoffTimes()
      .then(res => {
        if (res !== undefined) {
          const data = res.data ? res.data.data : "";
          if (data) {
            let {
              meeting_link,
              meeting_id,
              meeting_password,
              timezone,
              communicationMethod,
              web_conference_source,
              phone
            } = data;

            setMeetingPassword(meeting_password);
            setMeetingId(meeting_id);
            setMeetingLink(meeting_link);
            setPhone(phone);
            setCommunicationMethod(
              communicationMethod || COMMUNICATION_METHOD_WEB_CONFERERENCE
            );
            setWebConferenceSource(web_conference_source);
            setVisualTimezone(timezone);
          }

          setKickoffData(res.data ? res.data.data : "");
          data && data.selectedTime && updateCalendarEvent(data);
          data && data.selectedTime && setKickoffScheduled(true);
        }
      })
      .then(_ => setKickoffLoaded(true));
  };

  useEffect(() => {
    loadKickoff();
  }, []);

  useEffect(() => {
    if (
      kickoffData &&
      Object.keys(kickoffData).length > 0 &&
      kickoffData.status === "RESCHEDULED_EXPERT"
    ) {
      setReschedule(true);
      setKickoffRescheduled(true);
      setExpertAskedToReschedule(true);
      setExpertMessage(kickoffData.notes);
    }
  }, [kickoffLoaded, visualTimezone]);

  useEffect(() => {
    if (kickoffData && Object.keys(kickoffData).length > 0) {
      let expertTimes = kickoffData.times.map(time => ({
        time: `${time.date}T${time.time}${moment()
          .tz(kickoffData.timezone)
          .format("Z")}`,
        id: time.id
      }));
      setAvailableTimes(expertTimes);
    }
  }, [kickoffLoaded]);

  useEffect(() => {
    if (
      kickoffData &&
      Object.keys(kickoffData).length > 0 &&
      kickoffData.times.length > 0
    ) {
      setAlert(true);
      setWaitingForExpertSelection(true);
    }
  }, [kickoffLoaded]);

  const updateCalendarEvent = kickoffData => {
    let endTime = `${moment(
      `${kickoffData.selectedTime.date} ${kickoffData.selectedTime.time}`,
      "YYYY-MM-DD HH:mm"
    )
      .add(30, "m")
      .format("YYYY-MM-DD T HH:mm:ss")} ${moment()
      .tz(kickoffData.timezone)
      .format("Z")}`;

    setCalendarEvent({
      title: "Kick Off Call",
      description: "You've successfully scheduled a kickoff call",
      location: `${kickoffData.communicationMethod}`,
      startTime: `${kickoffData.selectedTime.date}T${
        kickoffData.selectedTime.time
      }${moment()
        .tz(kickoffData.timezone)
        .format("Z")}`,
      endTime: endTime.split(" ").join("")
    });
  };

  useEffect(() => {
    if (
      kickoffData &&
      Object.keys(kickoffData).length > 0 &&
      kickoffData.selectedTime &&
      Object.keys(kickoffData.selectedTime)
    ) {
      setKickoffScheduled(true);
      updateCalendarEvent(kickoffData);
    }
  }, [kickoffLoaded]);

  useEffect(() => {
    if (kickoffData && Object.keys(kickoffData).length > 0) {
      let expertTimes = kickoffData.times.map(time => ({
        time: `${time.date}T${time.time}${moment()
          .tz(kickoffData.timezone)
          .format("Z")}`,
        id: time.id
      }));
      setAvailableTimes(expertTimes);
      setWaitingForExpertSelection(true);
    }
  }, [kickoffData, kickoffScheduledCustomer]);

  let communicationMethodDropdown = {
    label: communicationMethod || COMMUNICATION_METHOD_WEB_CONFERERENCE,
    value: communicationMethod || COMMUNICATION_METHOD_WEB_CONFERERENCE
  };

  return (
    <>
      {alert && (
        <div className="alerts-wrapper">
          {isCustomer(currentUser && currentUser.roleId) &&
            !kickoffScheduled &&
            kickoffData &&
            kickoffData.times &&
            kickoffData.times.length < 1 && (
              <Alert
                color="danger"
                className={
                  "d-flex align-items-center justify-content-stretch text-white"
                }
              >
                <InlineInfoIcon />
                <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
                  <span className="font-weight-bold">
                    {isCustomer(currentUser && currentUser.roleId) &&
                      `Your expert has accepted this project! Please select a few
                available times for a kickoff call.`}
                    {isExpert(currentUser && currentUser.roleId) &&
                      `The client has selected some times that they are available
                  for a kickoff call. Select a preferred time.`}
                  </span>
                </p>
                <div className="close-alert mr-0 ml-0">
                  <button
                    className="btn-plain btn text-white p-0"
                    onClick={() => setAlert(false)}
                  >
                    <CrossIcon />
                  </button>
                </div>
              </Alert>
            )}

          {isExpert(currentUser && currentUser.roleId) &&
            !kickoffRescheduled &&
            !kickoffScheduled &&
            kickoffData &&
            kickoffData.times &&
            !kickoffData.times.length < 1 && (
              <Alert
                color="danger"
                className={
                  "d-flex align-items-center justify-content-stretch text-white"
                }
              >
                <InlineInfoIcon />
                <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
                  <span className="font-weight-bold">
                    The client has selected some times that they are available
                    for a kickoff call. Select a preferred time.
                  </span>
                </p>
                <div className="close-alert mr-0 ml-0">
                  <button
                    className="btn-plain btn text-white p-0"
                    onClick={() => setAlert(false)}
                  >
                    <CrossIcon />
                  </button>
                </div>
              </Alert>
            )}

          {isExpert(currentUser && currentUser.roleId) &&
            !kickoffData &&
            !kickoffScheduled &&
            !kickoffRescheduled && (
              <Alert
                color="info"
                className={
                  "d-flex align-items-center justify-content-stretch text-white"
                }
              >
                <InlineInfoIcon />
                <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
                  <span className="font-weight-bold">
                    While we're waiting for the customer to pick some times for
                    a kickoff call, check out our tips below
                  </span>
                </p>
                <div className="close-alert mr-0 ml-0">
                  <button
                    className="btn-plain btn text-white p-0"
                    onClick={() => setAlert(false)}
                  >
                    <CrossIcon />
                  </button>
                </div>
              </Alert>
            )}

          {isExpert(currentUser && currentUser.roleId) &&
            kickoffData &&
            !kickoffScheduled &&
            kickoffRescheduled && (
              <Alert
                color="success"
                className={
                  "d-flex align-items-center justify-content-stretch text-white"
                }
              >
                <CheckmarkCircleIcon />
                <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
                  <span className="font-weight-bold">
                    Your kickoff call is being rescheduled and a new set of time
                    slots will be posted soon.
                  </span>
                </p>
                <div className="close-alert mr-0 ml-0">
                  <button
                    className="btn-plain btn text-white p-0"
                    onClick={() => setAlert(false)}
                  >
                    <CrossIcon />
                  </button>
                </div>
              </Alert>
            )}

          {isCustomer(currentUser && currentUser.roleId) &&
            expertAskedToReschedule && (
              <Alert
                color="danger"
                className={
                  "d-flex align-items-center justify-content-stretch text-white"
                }
              >
                <InlineInfoIcon />
                <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
                  <span className="font-weight-bold">
                    Your expert has requested to reschedule the kickoff. Please
                    select a few new times for a kickoff call.
                  </span>
                </p>
                <div className="close-alert mr-0 ml-0">
                  <button
                    className="btn-plain btn text-white p-0"
                    onClick={() => setAlert(false)}
                  >
                    <CrossIcon />
                  </button>
                </div>
              </Alert>
            )}

          {isCustomer(currentUser && currentUser.roleId) && kickoffScheduled && (
            <Alert
              color="success"
              className={
                "d-flex align-items-center justify-content-stretch text-white"
              }
            >
              <CheckmarkCircleIcon />
              <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
                <span className="font-weight-bold">
                  Your expert has selected a time slot from the options that you
                  have selected.
                </span>
              </p>
              <div className="close-alert mr-0 ml-0">
                <button
                  className="btn-plain btn text-white p-0"
                  onClick={() => setAlert(false)}
                >
                  <CrossIcon />
                </button>
              </div>
            </Alert>
          )}

          {isCustomer(currentUser && currentUser.roleId) &&
            waitingForExpertSelection &&
            !kickoffScheduled &&
            !reschedule && (
              <Alert
                color="success"
                className={
                  "d-flex align-items-center justify-content-stretch text-white"
                }
              >
                <CheckmarkCircleIcon />
                <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
                  <span className="font-weight-bold">
                    Thank you! Your time slots have been selected and sent to
                    the expert.
                  </span>
                </p>
                <div className="close-alert mr-0 ml-0">
                  <button
                    className="btn-plain btn text-white p-0"
                    onClick={() => setAlert(false)}
                  >
                    <CrossIcon />
                  </button>
                </div>
              </Alert>
            )}
        </div>
      )}

      {isExpert(currentUser && currentUser.roleId) &&
        !kickoffScheduled &&
        (!kickoffData || (kickoffData && kickoffRescheduled)) && (
          <KickoffTips description={"Tips for Kickoff Call"}>
            <KickoffTipsWrapper>
              <KickoffTip
                number={1}
                description={`Focus on the client's background, the vision,
        and the approach you'll take in the project.`}
              />
              <KickoffTip
                number={2}
                description={`Always be respectful and dress appropriately.`}
              />
              <KickoffTip
                number={3}
                description={`Make sure you have a stable internet
        connection and your camera and mic are working.`}
              />
              <KickoffTip
                number={4}
                description={`Try to have the call in a quiet and naturally lit room.`}
              />
            </KickoffTipsWrapper>
          </KickoffTips>
        )}

      {// prettier-ignore
      ((isExpert() && kickoffData && !kickoffRescheduled) || isCustomer(currentUser && currentUser.roleId)) && (
        <div className="kickoff-scheduler">
          {expertAskedToReschedule && isCustomer(currentUser && currentUser.roleId) && (
            <div className="customer-reschedule">
              <p>
                <strong>Message from your expert:</strong>
              </p>
              <div className="expert-message">
                <p className="expert-message__description">
                  {/* TODO: DYNAMIC MESSAGE, If we have than msg, if we don't than no message from the expert */}
                  {expertMessage ? expertMessage : 'No reason specified'}
                </p>
              </div>
            </div>
          )}
          <p>
            {isExpert(currentUser && currentUser.roleId) &&
            !kickoffScheduled &&
              !kickoffRescheduled &&
            `Select a preferred time that you can be availble for a kickoff call
              with the client. If you aren’t available for any of the times below,
              you can reschedule.`}
            {isCustomer(currentUser && currentUser.roleId) &&
            !kickoffScheduled &&
            `Select at least 3 times that you are available for a kickoff call.
              Your expert will be notified and will confirm the time. If the expert
              can’t make any of your selected time slots, you will be able to
              reschedule.`}
            {(isCustomer(currentUser && currentUser.roleId) || isExpert(currentUser && currentUser.roleId)) && kickoffScheduled && (
              <>
                The Kickoff Call for this project is set for{" "}
                <strong>{kickoffData.selectedTime.date}</strong> at{" "}
                <strong>{moment(kickoffData.selectedTime.time, ["HH"]).format("h:mm A")}</strong> via{" "}
                <strong>{kickoffData.communicationMethod}</strong>
                {(isCustomer(currentUser && currentUser.roleId) || isExpert(currentUser && currentUser.roleId)) && (
                  <button
                    className="btn btn-link p-0 ml-3 border-0 vertical-top"
                    onClick={() => {
                      if (isCustomer(currentUser && currentUser.roleId)) rescheduleKickOff();
                      else if (isExpert(currentUser && currentUser.roleId)) setRescheduleModal(true);
                    }}
                  >
                    Reschedule
                  </button>
                )}
              </>
            )}
          </p>

          {kickoffData && kickoffData.selectedTimeId && (isExpert(currentUser && currentUser.roleId) || isCustomer(currentUser && currentUser.roleId)) && (
            <ConfirmedKickoffMeetingForm kickoffData={kickoffData} />
          )}

          {!kickoffScheduled && !kickoffRescheduled && (
            <KickOffMethodOfCommunication
              waitingForExpertSelection={waitingForExpertSelection}
              selectedCommunicationMethod={communicationMethod}
            />
          )}

          {!kickoffScheduled && (
            <>
              {reschedule ||
              (kickoffData && kickoffData.times && kickoffData.times.length) <
              1 ? (
                <Form
                initialValues={{
                  meetingLink: meetingLink,
                  meetingId: meetingId,
                  meetingPassword: meetingPassword,
                  phone: phone
                }}
                >
                <div className="form-wrapper">
                    <div className="row">
                      <div className="col-md-5 text-md-right text-sm-left">
                        <label className="font-weight-bold">Preferred Method of Communication:</label>
                      </div>
                      <div className="col-md-6">
                        <PreferredMethodOfCommunication
                          name="communication_method"
                          placeholder="Select Preferred Method Of Communication"
                          defaultValue={communicationMethodDropdown}
                          onChange={handleCommunicationMethod}
                        />
                      </div>
                    </div>
                    {communicationMethod === COMMUNICATION_METHOD_WEB_CONFERERENCE && (
                      <>
                      <div className="row">
                        <div className="col-md-5 text-md-right text-sm-left">
                            <label className="font-weight-bold">Web Conference Source:</label>
                          </div>
                          <div className="col-md-6 ml-4">
                            <Radio
                                name="webConferenceSource"
                                options={webConferenceSourceList}
                                onChange={handleWebConferenceSource}
                                defaultValue={webConferenceSource}
                              />
                          </div>
                        </div>
                      </>
                    )}

                    {communicationMethod === COMMUNICATION_METHOD_WEB_CONFERERENCE
                     && webConferenceSource === WEB_CONFERERENCE_TYPE_PROVIDE_CONFERERENCE_URL && (
                      <>
                        <div className="row">
                          <div className="col-md-5 text-md-right text-sm-left">
                            <label className="font-weight-bold">Meeting Link:</label>
                          </div>
                          <div className="col-md-6">
                            <Text name="meetingLink" placeholder="Meeting Link" onChange={handleMeetingLink} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 text-md-right text-sm-left">
                            <label className="font-weight-bold">Meeting ID:</label>
                          </div>
                          <div className="col-md-6">
                            <Text name="meetingId" placeholder="Meeting Id" onChange={handleMeetingId} />
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-5 text-md-right text-sm-left">
                            <label className="font-weight-bold">Meeting Password:</label>
                          </div>
                          <div className="col-md-6">
                            <Text name="meetingPassword" placeholder="Meeting Password" onChange={handleMeetingPassword} />
                          </div>
                        </div>
                      </>
                    )}

                    {communicationMethod === COMMUNICATION_METHOD_PHONE && (
                      <div className="row">
                        <div className="col-md-5 text-md-right text-sm-left">
                          <label className="font-weight-bold">Phone:</label>
                        </div>
                        <div className="col-md-6">
                          <Text name="phone" placeholder="Phone number" required onChange={handlePhone}/>
                        </div>
                      </div>
                    )}
                  </div>
                </Form>
              ) : ""}
              </>
          )}

            <KickOffTimezone
              defaultTimezone={defaultTimezone}
              updateTimezone={updateTimezone}
              selectedTimezone={visualTimezone}
            />

          {!kickoffScheduled && (
            <>
              {!isCustomer(currentUser && currentUser.roleId) ||
              reschedule ||
              (kickoffData && kickoffData.times && kickoffData.times.length) <
              1 ? (
                <>
                <KickOffTimePicker
                        updateSelectedDates={updateSelectedDates}
                        defaultTimezone={defaultTimezone}
                        visualTimezone={visualTimezone}
                        expertAvailableDates={availableTimes}
                        updateSelectedDateId={updateSelectedDateId}
                      />
                </>
                )  : (
                <div className={["mt-3"].join(" ")}>
                  <p className={["font-weight-bold"].join(" ")}>
                    Your Selected Time Slots:{" "}
                  </p>
                  <KickOffTimePicker
                    updateSelectedDates={updateSelectedDates}
                    defaultTimezone={defaultTimezone}
                    visualTimezone={visualTimezone}
                    expertAvailableDates={availableTimes}
                    updateSelectedDateId={updateSelectedDateId}
                    customerWaitingForExpert={true}
                  />
                </div>
              )}
              {(!kickoffData ||
                kickoffData &&
                kickoffData.times &&
                kickoffData.times.length < 1 ||
                isExpert(currentUser && currentUser.roleId) && kickoffData.selectedTime === null ||
                reschedule) && (
                <div
                  className={`kickoff-scheduler__btn-wrapper d-flex w-100 justify-content-center mt-4 ${
                    selectedDates && selectedDates.length ? "" : "disabled"
                  }`}
                >
                  {isExpert(currentUser && currentUser.roleId) &&
                  kickoffData &&
                  kickoffData.times &&
                  kickoffData.times.length > 0 && (
                    <button
                      className="btn btn-outline-secondary mr-3"
                      type={"button"}
                      onClick={() => {
                        selectedDates && selectedDates.length && setRescheduleModal(true);
                      }}
                    >
                      Reschedule
                    </button>
                  )}

                  <button
                    className="btn btn-primary"
                    onClick={() => {
                      isCustomer(currentUser && currentUser.roleId) &&
                      setKickoffTimes(
                        communicationMethod,
                        selectedDates,
                        visualTimezone,
                        "Scheduled"
                      );
                      isCustomer(currentUser && currentUser.roleId) && setKickoffScheduledCustomer(true);
                      isExpert(currentUser && currentUser.roleId) && confirmKickoffTime();
                    }}
                  >
                    Schedule Kickoff
                  </button>
                </div>
              )}
            </>
          )}

          {/* Kickoff Calender */}
          {kickoffScheduled && <KickOffAddToCalendar event={calendarEvent} />}

          {kickoffData && kickoffData.times && kickoffData.times.length > 0 && (
            <RescheduleModal
              isOpen={rescheduleModal}
              toggle={() => setRescheduleModal(prevState => !prevState)}
              resetKickoffTimes={resetKickoffTimes}
              rescheduleKickOffExpert={rescheduleKickOffExpert}
            />
          )}
        </div>
      )}
    </>
  );
});

export default KickOffScheduler;
