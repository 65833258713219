import React from "react";
import moment from "moment";
import toast from "../../../../components/base/Toast";
import { Link } from "react-router-dom";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

// Components
import PageTitle from "../../../../components/base/PageTitle";
import PageSearch from "../../../../components/base/PageSearch";
import { Table } from "reactstrap";
import SelectDropdown from "../../../../components/base/SelectDropdown";
import Page404 from "../../../Page404";

// Constants
import {
  LAST_MONTH,
  LAST_MONTH_TEXT,
  LAST_WEEK,
  LAST_WEEK_TEXT,
  THIS_MONTH,
  THIS_MONTH_TEXT,
  THIS_WEEK,
  THIS_WEEK_TEXT,
  TODAY,
  TODAY_TEXT,
  YESTERDAY,
  YESTERDAY_TEXT
} from "../../../../salesforceRelativeDates/Constants";

// Helper
import { isSuperAdmin, isCompanyAdmin } from "../../../../lib/helper";
import { isBadRequest } from "../../../../common/http";

import { isSalesforceID } from "../../../../common/utils";

import { connect } from "react-redux";

class SalesforceAccount extends React.Component {
  state = {
    isSearching: false,
    accountList: [],
    searchResultMessage: "Search Accounts",
    searchTerm: "",
    salesforceId: "",
    createdDate: "",
    sortByOptions: [
      {
        value: TODAY,
        label: TODAY_TEXT
      },
      {
        value: YESTERDAY,
        label: YESTERDAY_TEXT
      },
      {
        value: THIS_WEEK,
        label: THIS_WEEK_TEXT
      },
      {
        value: LAST_WEEK,
        label: LAST_WEEK_TEXT
      },
      {
        value: THIS_MONTH,
        label: THIS_MONTH_TEXT
      },
      {
        value: LAST_MONTH,
        label: LAST_MONTH_TEXT
      }
    ],
    selectedSortOption: ""
  };

  componentDidMount() {
    const isSearch = localStorage.getItem("searchFields");
    if (isSearch) {
      const searchState = JSON.parse(isSearch);
      this.setState(searchState, () => {
        this.salesforceSearch();
      });
      localStorage.removeItem("searchFields");
    }
  }

  rowSelection = () => {
    localStorage.setItem("searchFields", JSON.stringify(this.state));
  };

  salesforceSearch = () => {
    this.setState({ isSearching: true }, () => {
      let { searchTerm, salesforceId, createdDate, sort } = this.state;

      searchTerm = searchTerm ? searchTerm : "";
      salesforceId = salesforceId ? salesforceId : "";
      createdDate = createdDate ? createdDate : "";

      return apiClient
        .get(
          `${
            endpoints().salesforceLookupAPI
          }/account/search?searchTerm=${searchTerm}&salesforceId=${salesforceId}&createdDate=${createdDate}&sort=${sort}`
        )
        .then(response => {
          const { records } = response.data;

          this.setState({
            accountList: records || [],
            isSearching: false,
            searchResultMessage: "No accounts found"
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    });
  };

  handleSearchTermChange = searchTerm => {
    let validateSalesforceID = isSalesforceID(searchTerm);
    this.setState(
      {
        searchTerm,
        salesforceId: validateSalesforceID ? searchTerm : null
      },
      () => {
        this.updateUrl();
        this.salesforceSearch();
      }
    );
  };

  handleIdChange = salesforceId => {
    this.setState(
      {
        salesforceId
      },
      () => {
        this.updateUrl();
        this.salesforceSearch();
      }
    );
  };

  handleCreatedDateChange = createdDate => {
    this.setState(
      {
        createdDate: this.getSortValueFromLabel(createdDate),
        sort: createdDate
      },
      () => {
        this.updateUrl();
        this.salesforceSearch();
      }
    );
  };

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }
  updateUrl() {
    const { searchTerm, sort } = this.state;

    const params = [];

    if (searchTerm) {
      params.push(`search=${searchTerm}`);
    }
    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (params.length > 0) {
      this.props.history.push(
        `/support-portal/Salesforce/account?${params.join("")}`
      );
    }
  }

  render() {
    const {
      accountList,
      searchResultMessage,
      createdDate,
      sortByOptions
    } = this.state;

    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId)
    ) {
      return <Page404 />;
    }
    const { activeSubTab, activeSection } = this.props;
    return (
      <>
        {!activeSection ? (
          <>
            <PageTitle label="Salesforce Account" />

            {/* Main Layout */}
            <div>
              {/* Search Bar */}
              <div>
                <div
                  className={`page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column`}
                >
                  <>
                    <PageSearch
                      classnames="page-search"
                      placeholder="Search"
                      onChange={e =>
                        this.handleSearchTermChange(e.target.value)
                      }
                    />
                  </>

                  <SelectDropdown
                    buttonLabel={createdDate || "Select Created Date"}
                    dropdownLinks={sortByOptions}
                    color={"gray"}
                    hideCaret
                    selectName={"sortby_createdDate"}
                    handleChange={this.handleCreatedDateChange}
                  />
                </div>
                {/* Account List */}
                <Table className="table-hover bg-white">
                  <thead className="thead-dark table-bordered">
                    <tr>
                      <th>Salesforce Id</th>
                      <th>Name</th>
                      <th>CreatedAt</th>
                    </tr>
                  </thead>
                  <tbody>
                    {accountList && accountList.length ? (
                      accountList.map(account => (
                        <tr>
                          <td>
                            <Link
                              onClick={this.rowSelection}
                              to={`/support-portal/salesforce/account/${account.Id}`}
                            >
                              <span className="banner-image-upload-link">
                                {account.Id}
                              </span>
                            </Link>
                          </td>
                          <td>{account.Name}</td>
                          <td>
                            {moment(account.CreatedDate).format("MM/DD/YYYY")}
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center p-4" colSpan={3}>
                          {searchResultMessage}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        ) : (
          <>{activeSubTab === "account" && ""}</>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(SalesforceAccount);
