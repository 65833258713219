import React, { useEffect, useState } from "react";
import { ChevronDown } from "../../assets/img/icons";
import Sentence from "./NLSentence";

const NLForm = props => {
  const {
    paragraphs,
    extraParagraphs,
    select,
    getSelectedFilters,
    liveFilters,
    renderExpertList,
    hideDiscoverButton,
    buttonLabel,
    id
  } = props;
  const [showFilters, setShowFilters] = useState(false);

  const toggleFilters = () => {
    setShowFilters(prevState => !prevState);
  };

  return (
    <>
      {paragraphs &&
        paragraphs.length > 0 &&
        paragraphs.map((paragraph, index) => {
          return (
            <Sentence
              actualParagraph={paragraph}
              select={select}
              key={index}
              getSelectedFilters={getSelectedFilters}
              liveFilters={liveFilters}
              renderExpertList={renderExpertList}
            />
          );
        })}
      {!showFilters && (
        <>
          {!hideDiscoverButton && (
            <div className="btn-wrapper">
              <a
                id={id}
                className="btn btn-primary text-white"
                onClick={e => renderExpertList(e)}
              >
                {buttonLabel ? buttonLabel : "Discover Expert"}
              </a>
            </div>
          )}
        </>
      )}
      {extraParagraphs && extraParagraphs.length > 0 && (
        <div
          className={`show-more-filters ${!!showFilters && "filters-active"}`}
        >
          <button
            id={id}
            type="button"
            className="btn btn-link"
            onClick={toggleFilters}
          >
            <span className="text-primary">
              show {showFilters ? "less" : "more"} filters
            </span>
            <span className="circle-arrow-down">
              <ChevronDown />
            </span>
          </button>
        </div>
      )}

      {showFilters && (
        <>
          {extraParagraphs &&
            extraParagraphs.length > 0 &&
            extraParagraphs.map((paragraph, index) => {
              return (
                <Sentence
                  actualParagraph={paragraph}
                  select={select}
                  key={index}
                  getSelectedFilters={getSelectedFilters}
                  liveFilters={liveFilters}
                  renderExpertList={renderExpertList}
                />
              );
            })}
          {!hideDiscoverButton && (
            <div className="btn-wrapper">
              <a
                id={id}
                className="btn btn-primary text-white"
                onClick={e => renderExpertList(e)}
              >
                {buttonLabel ? buttonLabel : "Discover Expert"}
              </a>
            </div>
          )}
        </>
      )}
    </>
  );
};

export default NLForm;
