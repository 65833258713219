import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import matchedThumb from "../../../assets/img/mock_imgs/thumb-matched.png";

const KickOffModalPreview = React.memo(function KickOffModalPreview({
  isOpen,
  toggle
}) {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={["edit-task-modal", "kickoff-preview-modal"].join(" ")}
    >
      <ModalHeader toggle={toggle} tag={"div"}>
        <h4 className={["font-weight-bold"].join(" ")}>
          Congrats on Starting a Project!
        </h4>
      </ModalHeader>
      <ModalBody>
        <div className="content">
          <div className="split-content-wrapper">
            <div className="img-wrapper split-content">
              <img src={matchedThumb} alt="matchedThumb" />
            </div>
            <div className="split-content content-wrapper">
              <h5 className={"font-weight-bold"}>Setting up Kickoff Call</h5>
              <p className={"text-inline-grayed"}>
                Next, we’ll reach out to the client to see if they have some
                times in mind for the kickoff call. You’ll be able to pick the
                best time from a few selected timeslots.
              </p>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
});

export default KickOffModalPreview;
