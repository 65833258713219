import React, { Component } from "react";
import DefaultContent from "../content/DefaultContent";
import Text from "../base/Text";
import { FormGroup } from "reactstrap";
import Checkbox from "../../views/expertSignUp/Checkbox";
import Select from "../base/Select";
import TextArea from "../base/TextArea";
import { FieldArray } from "formik";
import ErrorMessage from "../base/ErrorMessage";
import moment from "moment";
import ExpertProfileEditDatepicker from "./ExpertProfileEditDatepicker";

class ExpertProfileEmployments extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  setStartDate(field, value) {
    this.setState({
      [field]: value
    });
  }
  render() {
    const { employments, id } = this.props;
    const EMPLOYMENT_TYPE = [
      {
        value: "Full-time",
        label: "Full Time"
      },
      {
        value: "Part-time",
        label: "Part Time"
      },
      {
        value: "Hourly",
        label: "Hourly"
      }
    ];
    return (
      <DefaultContent id={id} title={"Employment"}>
        <p className={["text-inline-grayed"].join("")}>
          We want to know more about what your employment. This will help
          prospective clients get a better idea of how impressive you are!
        </p>
        <FieldArray
          id={id}
          name="employments"
          render={arrayHelpers => (
            <div className={["employments", "employment-editing"].join(" ")}>
              {arrayHelpers.form.values.employments &&
                arrayHelpers.form.values.employments.map(
                  (employment, index) => (
                    <div className="employment" key={index}>
                      <div className="field-wrapper">
                        <ErrorMessage name={`employments[${index}].title`} />
                        <Text
                          id={id}
                          placeholder={"Title"}
                          name={`employments[${index}].title`}
                          required
                        />
                      </div>
                      <div className="field-wrapper">
                        <Select
                          id={id}
                          name={`employments[${index}].employmentType`}
                          placeholder="Employment Type"
                          defaultValue={EMPLOYMENT_TYPE.find(
                            type => type.value === employment.employmentType
                          )}
                          options={EMPLOYMENT_TYPE}
                          isSearchable={true}
                        />
                      </div>
                      <div className="field-wrapper">
                        <ErrorMessage
                          name={`employments[${index}].companyName`}
                        />
                        <Text
                          id={id}
                          name={`employments[${index}].companyName`}
                          placeholder="Company Name"
                          defaultValue={employment.companyName}
                          required
                        />
                      </div>
                      <div className="field-wrapper">
                        <Text
                          id={id}
                          name={`employments[${index}].location`}
                          placeholder={"Location"}
                        />
                      </div>
                      <div className="field-wrapper">
                        <TextArea
                          id={id}
                          name={`employments[${index}].description`}
                          placeholder={"Description"}
                        />
                      </div>
                      <div className="field-wrapper">
                        <FormGroup>
                          <ExpertProfileEditDatepicker
                            id={id}
                            name={`employments[${index}].startDate`}
                            placeholder={"Start date"}
                            value={
                              arrayHelpers.form.values.employments[index]
                                .startDate
                            }
                            onChange={val => {
                              arrayHelpers.form.values.employments[
                                index
                              ].startDate = moment(val).format("YYYY-MM-DD");
                              this.setStartDate(
                                `employments[${index}].startDate`,
                                moment(val).format("YYYY-MM-DD")
                              );
                            }}
                            clearIcon={null}
                            maxDate={new Date()}
                          />
                        </FormGroup>
                        {!arrayHelpers.form.values.employments[index]
                          .isCurrentlyWorking && (
                          <FormGroup>
                            <ExpertProfileEditDatepicker
                              id={id}
                              name={`employments[${index}].endDate`}
                              placeholder={"End date"}
                              value={
                                arrayHelpers.form.values.employments[index]
                                  .endDate
                              }
                              onChange={val => {
                                arrayHelpers.form.values.employments[
                                  index
                                ].endDate = moment(val).format("YYYY-MM-DD");
                              }}
                              clearIcon={null}
                              minDate={
                                this.state[`employments[${index}].startDate`]
                                  ? new Date(
                                      this.state[
                                        `employments[${index}].startDate`
                                      ]
                                    )
                                  : arrayHelpers.form.values.employments[index]
                                      .startDate
                                  ? new Date(
                                      arrayHelpers.form.values.employments[
                                        index
                                      ].startDate
                                    )
                                  : ""
                              }
                              disabled={
                                this.state[`employments[${index}].startDate`] ||
                                arrayHelpers.form.values.employments[index]
                                  .startDate
                                  ? false
                                  : true
                              }
                            />
                          </FormGroup>
                        )}
                      </div>
                      <div className="field-wrapper">
                        <Checkbox
                          id={id}
                          className="custom-checkbox accepted-terms pb-0 mb-0"
                          name={`employments[${index}].isCurrentlyWorking`}
                          label={"Currently working here"}
                        />
                      </div>
                      <div className={["btn-wrapper", "text-right"].join(" ")}>
                        <a
                          className="remove-link"
                          onClick={() => arrayHelpers.remove(index)}
                        >
                          Remove
                        </a>
                      </div>
                    </div>
                  )
                )}
              <a
                href="#"
                className="h7"
                onClick={e => {
                  let startDate, endDate;
                  e.preventDefault();
                  arrayHelpers.push({
                    id: null,
                    title: "",
                    employmentType: "",
                    companyName: "",
                    location: "",
                    startDate,
                    endDate,
                    isCurrentlyWorking: false
                  });
                }}
              >
                {`Add ${
                  employments && employments.length > 0 ? "another" : ""
                } employment...`}
              </a>
            </div>
          )}
        />
      </DefaultContent>
    );
  }
}

export default ExpertProfileEmployments;
