import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import toast from "../../components/base/Toast";
import MessageDialog from "../../views/message/MessageDialog";
import AddButton from "../../components/base/AddButton";
import PartnerCommunicationIcon from "../../assets/img/icons/nav-icon-partnercommunication.svg";

// API Client
import { apiClient } from "../../apiClient";

// Components
import PartnerProfileMain from "../../components/editPartnerProfile/PartnerProfileMain";
import PartnerProfileSidebar from "../../components/editPartnerProfile/PartnerProfileSidebar";
import PartnerProfileVisibilitySettings from "../../components/editPartnerProfile/PartnerProfileVisibilitySettings";
import Form from "../../components/FormWithErrorHandling";
import Loader from "../../components/base/Loader";
import PageTitle from "../../components/base/PageTitle";
import Spinner from "../../components/base/Spinner";
import SelectDropdown from "../../components/base/SelectDropdown";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import DeleteModal from "../../components/base/DeleteModal";
import NoRecordsFound from "../../components/base/NoRecordsFound";

// 404 Page
import Page404 from "../../views/Page404";

// Inner Components
import TeamMember from "./inner-components/TeamMember";
import ReferralProgramStats from "./inner-components/ReferralProgramStats";
import BillingDetail from "./inner-components/BillingDetail";
import AddPaymentOptions from "../../components/PaymentOptionAdd";

// Configs
import { endpoints } from "../../configs";

// Constants
import {
  TAG_TYPE_CATEGORY_MARKETPLACE,
  TAG_TYPE_CURRENCY
} from "../../tagType/Constants";
import {
  PARTNER_STATUS_APPROVED,
  PARTNER_STATUS_DECLINED
} from "../../partnerStatus/Constants";
import {
  SETTINGS_ENABLE_PARTNER_MANAGER,
  SETTINGS_ENABLE_PARTNER_PAYMENTS,
  SETTINGS_PORTAL_URL,
  SETTINGS_REFERRAL_LINK_BASE_URL,
  SETTINGS_ENABLE_DEALS,
  SETTINGS_ENABLE_REFERRALS,
  SETTINGS_ENABLE_COMMUNICATIONS,
  SETTINGS_ENABLE_ACCOUNT_MAPPING
} from "../../setting/Constants";

import {
  PARTNER_TYPE_SETTING_ENABLE_PARTNERSHIP_WITH_INDIVIDUAL_PERSON,
  PARTNER_TYPE_SETTING_ENABLE_TEAM_MANAGEMENT
} from "../../partnerTypeSetting/Constants";
import { AVAILABILITY as availabilityOption } from "../../availabilityArray/Constants";

// Context
import MyContext from "../../context/MyContext";

// Helper
import {
  getUrlParameter,
  removeMaskedPhoneNumber,
  toString,
  getKeyValueByObject,
  getUserId,
  getParamsByName,
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  setCookie,
  clearCookie,
  isEmpty
} from "../../lib/helper";
import {
  COOKIE_DEFAULT_CURRENCY,
  COOKIE_ROLE,
  COOKIE_SESSION_TOKEN,
  COOKIE_TERMINOLOGY,
  COOKIE_USER_ID,
  clearAllCookies
} from "../../lib/cookie";
import Users from "../../helpers/User";

// Components
import PartnerProfileActivities from "../../components/editPartnerProfile/PartnerProfileActivities";
import {
  COMPANY_MANAGER_ROLE,
  COMPANY_ADMIN_ROLE
} from "../../roles/Constants";
import PaymentOption from "../../components/PaymentOption";
import Applications from "./inner-components/Applications";
import { isBadRequest } from "../../common/http";
import PartnerProfileLinks from "../../referralSetting/PartnerProfileLinksSection";
import { isTrue } from "../../common/utils";
import detectBrowser from "../../components/helpers/detectBrowser";
import MessageList from "../../views/message/MessageList";
// Constants
import { PARTNER_PERMISSION_INDIVIDUAL_PARTNER } from "../../resource/Constants";

import { getPartnerSettingById } from "../../lib/PartnerSetting";

import { ALLOW_REFERRAL_SUBMISSION } from "../../views/adminSettings/tabs/Partner/PartnerTypes/Constants";

import { fetchPartnerSettingsByPartnerId } from "../../actions/partnerSettings";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

// Constants
import { TAG_TYPE_REGION } from "../../tagType/Constants";
import { getSetting } from "../../actions/settings";
import { SETTINGS_SHOW_REGION } from "../../setting/Constants";
import Tag from "../../Constants/Tag";
import { Local } from "../../Constants/LocalStorage";
import UserRolePermissionService from "../../services/UserRolePermissionService";

import { connect } from "react-redux";
import File from "../files";

// constants
const TAB_PROFILE = "profile";
const TAB_APPLICATION = "application";
const TAB_REFERRAL_LINKS = "referral-links";
const TAB_STATS_AND_ACTIVITY = "stats-and-activity";
const TAB_TEAM = "team";
const TAB_PAYOUT_PREFERENCES = "payout-preferences";
const TAB_ACCOUNT_MAPPING = "account-mapping";
const TAB_COMMUNICATIONS = "communications";
const TAB_FILES = "files";

// Loading Modal
const LoadingModal = ({ isLoading }) => (
  <Modal
    isOpen={isLoading}
    className={["log-hours-modal", "edit-task-modal"].join(" ")}
  >
    <ModalBody className={["text-center", "mb-4"].join(" ")}>
      <div className="form-wrapper">
        <Loader />
        <div className="field-wrapper">
          <h4 className={["font-weight-bold", "my-4"].join(" ")}>
            Saving Profile
          </h4>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

class EditPartnerProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      isInvalidRequest: false,
      isMobile: detectBrowser(),
      isMediaLoading: true,
      isPartnerManagerLoading: false,
      partnerDetails: {},
      partnerTypeList: {},
      productList: [],
      industryList: [],
      regionList: [],
      abmExpertiseList: [],
      crmMarketingList: [],
      marketingChannelList: [],
      contactFormOptions: [],
      isSavingBasicInfo: false,
      isSavingSocialInfo: false,
      loadingModal: false,
      activeTab: TAB_PROFILE,
      partnerCompanyName: "",
      partnerStatics: "",
      teamMembers: [],
      partnerActivity: [],
      plannedActivity: [],
      pastActivity: [],
      userList: [],
      billingDetails: [],
      paymentMethod: [],
      routingNumber: "",
      accountNumber: "",
      isBillingDetailsLoading: true,
      isPaymentDetailsLoading: true,
      isPartnerAdmin: false,
      tagTypeList: [],
      tagsTypeLoading: false,
      partnerApplicationTaskList: [],
      referralLinkList: [],
      openDeleteModal: false,
      photos: [],
      aboutMe: "",
      messageList: "",
      partnerMessageModalOpen: false,
      selectedPartner: "",
      isMessageLoading: false,
      showPublicReferralLinkTab: false,
      partnerSettings: [],
      showRegionField: "",
      partnerId: getParamsByName("id")
        ? getParamsByName("id")
        : getParamsByName("partnerId")
    };
  }

  componentDidMount() {
    this.getMarketplaceTagValues();
    this._getPartnerDetails().then(() => {
      this.getBillingDetails();
      this.getPaymentMethod();
      this._getTaskList();
      this._getReferralLinks();
      this.getPartnerMediaFiles();
      this.getMessageList();
      this.getPartnerSettingList();
      this.setState({ isLoading: false, isMediaLoading: false });
    });
    this._getPartnerUsers();
    this.getActivityList();
    this.getContactFormList();
    this.getPartnerStatics();

    this.getRegion();

    this.getParamsStatus();
    this.setActiveTab();
    this.getTerminology();
    this.getTagValues(TAG_TYPE_CURRENCY);
    this.getSettings();
    this.getRolePermission();

    if (isSuperAdmin(this.props.roleId) || isCompanyAdmin(this.props.roleId)) {
      this.getUsersList();
    }
  }
  getSettings = () => {
    getSetting(response => {
      if (response && response.length > 0) {
        let showRegion =
          getKeyValueByObject(response, SETTINGS_SHOW_REGION) == "true"
            ? true
            : false;

        this.setState({
          showRegionField: showRegion
        });
      }
    });
  };
  getRegion = async () => {
    const response = await apiClient.get(
      `${endpoints().tagAPI}?tagType=${TAG_TYPE_REGION}`
    );
    const results = response.data.data;

    if (results && results.length > 0) {
      const tagList = [];
      const defaultRegion = {};
      results.forEach(tag => {
        tagList.push({
          id: tag.id,
          name: tag.name,
          value: tag.name,
          label: tag.name,
          tagTypeId: tag.typeId
        });
      });

      this.setState({ regionList: tagList });
    }
  };

  getPartnerSettingList = async () => {
    if (this.state.partnerDetails && this.state.partnerDetails.partnerUserId) {
      fetchPartnerSettingsByPartnerId(
        this.state.partnerDetails.id,
        (err, response) => {
          if (response && response.data) {
            this.setState({ partnerSettings: response.data }, () => {
              this.getPartnerSettingConfig();
            });
          }
        }
      );
    }
  };

  // Get search query string value
  getParamsStatus = () => {
    const status = getParamsByName("section");
    this.setState({ status: status || TAB_PROFILE });
  };

  // Set Active Tab
  setActiveTab = () => {
    const status = getParamsByName("section");

    this.setState({
      activeTab:
        status === TAB_STATS_AND_ACTIVITY
          ? TAB_STATS_AND_ACTIVITY
          : status === TAB_REFERRAL_LINKS
          ? TAB_REFERRAL_LINKS
          : status === TAB_TEAM
          ? TAB_TEAM
          : status === TAB_PAYOUT_PREFERENCES
          ? TAB_PAYOUT_PREFERENCES
          : status === TAB_ACCOUNT_MAPPING
          ? TAB_ACCOUNT_MAPPING
          : status === TAB_APPLICATION
          ? TAB_APPLICATION
          : status === TAB_COMMUNICATIONS
          ? TAB_COMMUNICATIONS
          : status === TAB_FILES
          ? TAB_FILES
          : TAB_PROFILE
    });
  };
  togglePartnerMessageModalOpen = () => {
    this.setState({
      partnerMessageModalOpen: !this.state.partnerMessageModalOpen
    });
  };

  getPartnerSettingConfig = async () => {
    try {
      if (this.state.partnerDetails) {
        let showPublicReferralLinkTab = getPartnerSettingById(
          this.state.partnerDetails.partnerType,
          this.state.partnerSettings,
          ALLOW_REFERRAL_SUBMISSION
        );

        this.setState({ showPublicReferralLinkTab: showPublicReferralLinkTab });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Send Partner Message API Call
  sendMessageData = async values => {
    const { partnerDetails } = this.state;
    let partnerId = partnerDetails.id;
    return await apiClient
      .post(`${endpoints().messageAPI}`, {
        message: values.message,
        sharingPermission: PARTNER_PERMISSION_INDIVIDUAL_PARTNER,
        partnerId
      })
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.getMessageList(partnerId);
        setTimeout(() => {}, 300);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.stringify(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  handleMessageModal = e => {
    this.setState({
      partnerMessageModalOpen: e
    });
  };

  // Handle status change
  _handleStatusChange = tabStatus => {
    this.props.history.push(
      `/edit/partner/public-profile?id=${this.state.partnerDetails.id}&section=${tabStatus}`
    );
    this.setState({ status: tabStatus });
  };

  // Toggle Tab
  toggle = tab => {
    this.setState({
      activeTab: tab
    });
  };

  componentDidUpdate(prevState) {
    // Updating page after saving
    if (
      prevState.loadingModal &&
      prevState.loadingModal !== this.state.loadingModal &&
      this.state.loadingModal === false
    ) {
      this._getPartnerDetails();
      this._getPartnerUsers();
    }
  }

  // Get Contact form list
  getContactFormList = async () => {
    try {
      const response = await apiClient.get(`${endpoints().contactForm}`);
      const contactFormList = response.data.data;
      const contactFormOptions = [];
      contactFormList.forEach(contactform => {
        contactFormOptions.push({
          id: contactform.id,
          name: contactform.form_name,
          value: contactform.id,
          label: contactform.form_name
        });
        this.setState({
          contactFormOptions
        });
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  // Get Partner Statics
  getPartnerStatics = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().partnerAPI}/statics?id=${this.state.partnerId}`
      );
      this.setState({
        partnerStatics: response.data
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(errorMessage);
      }
    }
  };

  // Get Country Details
  getCountryDetails = countryName => {
    return apiClient
      .get(`${endpoints().countryAPI}/${countryName}`)
      .then(response => {
        const data = response.data;
        this.setState({
          selectedCountry: data.id
        });
      });
  };

  getSelectedTypes = types => {
    const selectedTypes = [];
    const typeInitialValues = {};
    if (types && types.length > 0) {
      types.forEach(partner => {
        if (partner && partner.type_id != null && partner.name != null) {
          selectedTypes.push({
            value: partner.type_id,
            label: partner.name
          });
        }
      });
    }
    typeInitialValues["partnerType"] = selectedTypes ? selectedTypes : [];
    return typeInitialValues;
  };

  // Get Partner Details
  _getPartnerDetails = () => {
    return apiClient
      .get(`${endpoints().partnerAPI}?id=${this.state.partnerId}`)
      .then(response => {
        let partnerTypeList;
        if (response && response.data && response.data.partnerType) {
          partnerTypeList = this.getSelectedTypes(response.data.partnerType);
        }

        this.setState({
          isPartnerManagerLoading: false,
          isMediaLoading: false
        });
        this.setState({
          partnerDetails: {
            ...this.state.partnerDetails,
            ...response.data
          },
          partnerTypeList: partnerTypeList
        });

        if (response.data && response.data.companyName) {
          this.setState({
            partnerCompanyName: response.data.companyName
          });
        }
        if (response.data && response.data.countryName) {
          this.getCountryDetails(response.data.countryName);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          this.setState({
            isInvalidRequest: true,
            isLoading: false,
            tagsTypeLoading: false
          });
        }
      });
  };

  // Get Activity list
  getActivityList = id => {
    apiClient
      .get(`${endpoints().activity}?partnerId=${this.state.partnerId}`)
      .then(response => {
        if (response && response.data) {
          this.setState({
            partnerActivity: response.data.data,
            plannedActivity: response.data.plannedData,
            pastActivity: response.data.pastData
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get pa users by partner id
  _getPartnerUsers = () => {
    if (!isPartner(this.props.roleId)) {
      return apiClient
        .get(`${endpoints().partnerUserAPI}/${this.state.partnerId}`)
        .then(response => {
          const data = response.data;
          const users = data.users;
          this.setState({
            teamMembers: users
          });
        });
    } else {
      return apiClient
        .get(`${endpoints().partnerAPI}/team/member`)
        .then(response => {
          const partnerUsers = response.data;
          const users = partnerUsers.data;
          this.setState({
            teamMembers: users
          });
        });
    }
  };

  // Get Marketplace Tag Values
  getMarketplaceTagValues = async () => {
    this.setState({ tagsTypeLoading: true }, async () => {
      try {
        const response = await apiClient.get(
          `${
            endpoints().tagTypeAPI
          }?category=${TAG_TYPE_CATEGORY_MARKETPLACE}&partnerId=${
            this.state.partnerId
          }`
        );
        const results = response.data.data;
        // Check the tag type skills
        if (results && results.length > 0) {
          const tagTypeList = [];
          await results
            .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
            .forEach(async tagType => {
              const tagList = [];
              if (tagType.tags) {
                await tagType.tags
                  .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
                  .forEach(tag => {
                    tagList.push({
                      id: tag.id,
                      name: tag.name,
                      value: tag.name,
                      label: tag.name,
                      sort: tag.sort,
                      tagTypeId: tagType.id
                    });
                  });
              }
              tagTypeList.push({
                id: tagType.id,
                name: tagType.name,
                permission: tagType.permission,
                sort: tagType.sort,
                tags: tagList
              });
            });

          this.setState(
            {
              tagTypeList: tagTypeList
            },
            () => {
              this.setState({
                tagsTypeLoading: false
              });
            }
          );
        } else {
          this.setState({
            tagsTypeLoading: false
          });
        }
      } catch (error) {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({
            tagsTypeLoading: false
          });
        }
      }
    });
  };

  getTagInitialValues = (partnertags, tagTypeList) => {
    const tagInitialValues = {};
    if (tagTypeList && tagTypeList.length > 0) {
      tagTypeList
        .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
        .forEach(tagType => {
          const selectedTags = [];

          if (partnertags && partnertags.length > 0)
            partnertags.forEach(tag => {
              if (tagType.id === tag.tagTypeId) selectedTags.push(tag);
            });

          tagInitialValues[tagType.name] = selectedTags;
        });
    }

    return tagInitialValues;
  };

  // Update Visibility
  updateVisibility = () => {
    this.setState({
      partnerDetails: {
        ...this.state.partnerDetails,
        profile_visibility: !this.state.profile_visibility
      }
    });
  };

  //Profile visibilty On Change save
  handlePartnerProfileVisibility = value => {
    const data = new FormData();

    if (value !== undefined) {
      data.append("profileVisibility", value);
    }
    this.setState({ loadingModal: true });
    apiClient
      .put(`v1/partner?partnerId=${this.state.partnerDetails.id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
        this.setState({ loadingModal: false });
      })
      .catch(error => {
        this.setState({ loadingModal: false });
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Update Partner Details
  handleSubmit = async values => {
    this.setState({ loadingModal: true });
    apiClient
      .put(`v1/partner?partnerId=${this.state.partnerDetails.id}`, values)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage, {
            containerId: "Edit-partner-public-toast"
          });
        }
        this.setState({ loadingModal: false });
      })
      .catch(e => {
        this.setState({ loadingModal: false });
        if (299 < e.response.status && 400 > e.response.status) {
          toast.warn(e.message, { containerId: "Edit-partner-public-toast" });
        } else if (400 <= e.response.status) {
          toast.error(e.response.data.message, {
            containerId: "Edit-partner-public-toast"
          });
        } else {
          toast.warn(e.message, { containerId: "Edit-partner-public-toast" });
        }
      });
  };

  // Delete Partner Api call
  handleDelete = () => {
    return apiClient
      .delete(
        `${endpoints().partnerAPI}/delete/all/${this.state.partnerDetails.id}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          setTimeout(() => {
            toast.success(successMessage, {
              containerId: "Edit-partner-public-toast"
            });
            window.location.replace("/partners");
          }, 2000);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message, {
            containerId: "Edit-partner-public-toast"
          });
          console.error(errorMessage);
        }
      });
  };

  // Get Users
  getUsersList = async () => {
    let roleId = `${COMPANY_MANAGER_ROLE},${COMPANY_ADMIN_ROLE}`;
    await Users.search(roleId, list => {
      this.setState({ userList: list });
    });
  };

  // Change Account Manager
  changeManager = userId => {
    this.setState({ isPartnerManagerLoading: true });
    return apiClient
      .post(
        `${endpoints().partnerAPI}/updateManager/${
          this.state.partnerDetails.id
        }`,
        {
          userId
        }
      )
      .then(() => {
        this._getPartnerDetails();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  getTerminology = async () => {
    const url = `${endpoints().terminologyAPI}`;
    const terminologyName = [];
    const response = await apiClient.get(url);
    const list = response.data.data;
    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        terminologyName.push({
          custom_name: list[i].custom_name,
          system_name: list[i].system_name
        });
      }
    }
    localStorage.setItem(Local.TERMINOLOGY, JSON.stringify(terminologyName));
  };

  getRolePermission = async role => {
    const permissionList = await UserRolePermissionService.search(role);

    if (permissionList && permissionList.length > 0) {
      let values = permissionList.map(obj => obj.value);

      // Convert the array to a comma-separated string
      let valuesString = values.join(",");

      localStorage.setItem(Local.PERMISSION, valuesString);
    }
  };

  getTagValues = async tagType => {
    try {
      this.setHeaders();

      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${tagType}`
      );
      const results = response.data.data;

      // Check the tag type skills
      if (results && results.length > 0) {
        results.forEach(tag => {
          if (
            tag.type == TAG_TYPE_CURRENCY &&
            tag.defaultValue == Tag.IsDefault
          ) {
            setCookie(COOKIE_DEFAULT_CURRENCY, tag.name);
          }
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };
  // Login By Admin
  loginByAdmin = partnerAdminUserId => {
    return apiClient
      .post(`${endpoints().userAPI}/loginByAdmin/${partnerAdminUserId}`)
      .then(async response => {
        if (response && response.data) {
          const { token, role, userId } = response.data;

          clearAllCookies();

          clearCookie("googleAuthToken");
          clearCookie("office365AuthToken");
          clearCookie("linkedinAuthToken");
          clearCookie("salesforceAuthToken");

          setCookie(COOKIE_SESSION_TOKEN, token);
          setCookie(COOKIE_USER_ID, userId);
          await this.getTerminology();
          await this.getTagValues();
          await this.getRolePermission(role);

          this.props.history.push("/dashboard");

          window.location.reload();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Update partner status handler
  updatePartnerByStatus(status, partnerId) {
    const data = { marketplaceStatus: status };
    apiClient
      .put(`${endpoints().partnerAPI}/status/approve/${partnerId}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          setTimeout(() => {
            toast.success(successMessage, {
              containerId: "Edit-partner-public-toast"
            });
          }, 2000);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  getPartnerAdminUserId() {
    const { admin_user_id } = this.state.partnerDetails;

    if (admin_user_id) {
      return admin_user_id;
    } else {
      toast.error("No Partner User Found", {
        containerId: "Team-Member-toast"
      });
    }
  }

  // Get Billing Details
  getBillingDetails() {
    return apiClient
      .get(
        `${endpoints().billingInfoAPI}/search?id=${
          this.state.partnerDetails.id
        }`
      )
      .then(response => {
        this.setState({
          billingDetails: response.data.data,
          isBillingDetailsLoading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            this.setState({
              isBillingDetailsLoading: false
            });
          }
          console.error(errorMessage);
        }
      });
  }

  // Get Payment Method
  getPaymentMethod() {
    return apiClient
      .get(
        `${endpoints().accountPaymentMethodAPI}/search?id=${
          this.state.partnerDetails.id
        }`
      )
      .then(response => {
        this.setState({
          paymentMethod: response.data.data,
          isPaymentDetailsLoading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
            this.setState({
              isPaymentDetailsLoading: false
            });
          }
        }
      });
  }

  // Billing Details Create and Update
  billingDetailsSubmit = values => {
    return apiClient
      .put(
        `${endpoints().billingInfoAPI}/${this.state.partnerDetails.id}`,
        values
      )
      .then(response => {
        this.getBillingDetails();
        if (response.data && response.data.message) {
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  // Payment Method Create and Update
  paymentMethodSubmit = values => {
    return apiClient
      .post(
        `${endpoints().accountPaymentMethodAPI}/${
          this.state.partnerDetails.id
        }`,
        values
      )
      .then(response => {
        if (response.data && response.data.message) {
          this.getPaymentMethod();
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  // Delete Payment Options
  deletePaymentOption = id => {
    return apiClient
      .delete(`${endpoints().accountPaymentMethodAPI}/${id}`)
      .then(response => {
        if (response && response.data) {
          this.getPaymentMethod();
          let successMessage;
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  // Set as Default Payment Option
  setDefaultPaymentOption = id => {
    return apiClient
      .put(
        `${
          endpoints().accountPaymentMethodAPI
        }/makeDefault?id=${id}&partnerId=${this.state.partnerDetails.id}`
      )
      .then(response => {
        if (response && response.data) {
          this.getPaymentMethod();
          let successMessage;
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  handleAdminAction = value => {
    const { id } = this.state.partnerDetails;
    switch (value) {
      case "Login As":
        const partnerAdminUserId = this.getPartnerAdminUserId();
        return partnerAdminUserId && this.loginByAdmin(partnerAdminUserId);
      case "Delete":
        return this.setState({ openDeleteModal: true });
      case "Approve Partner":
        return this.updatePartnerByStatus(PARTNER_STATUS_APPROVED, id);
      case "Decline Partner":
        return this.updatePartnerByStatus(PARTNER_STATUS_DECLINED, id);
      default:
        return "";
    }
  };

  // Get Partner Application
  _getTaskList = () => {
    apiClient
      .get(
        `${endpoints().partnerApplicationFieldAPI}/partner/dashboard?${this
          .state.partnerDetails.id &&
          "partnerId=" + this.state.partnerDetails.id}`
      )
      .then(response => {
        if (response.data) {
          let partnerApplicationFieldList = [];
          if (
            response.data.partnerApplicationFields &&
            response.data.partnerApplicationFields.length > 0
          ) {
            partnerApplicationFieldList =
              response.data.partnerApplicationFields;
          }
          this.setState({
            partnerApplicationTaskList: partnerApplicationFieldList
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get Partner Application
  _getReferralLinks = () => {
    apiClient
      .get(`${endpoints().referralLinkAPI}/search`)
      .then(response => {
        if (response.data) {
          let referralLinkList = [];
          if (response.data.data && response.data.data.length > 0) {
            referralLinkList = response.data.data;
          }
          this.setState({
            referralLinkList: referralLinkList
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  uploadTaskFile = values => {
    apiClient
      .post(
        `${endpoints().partnerApplicationFieldAPI}/partner/file?${this.state
          .partnerDetails.id && "partnerId=" + this.state.partnerDetails.id}`,
        values
      )
      .then(response => {
        if (response.data) {
          toast.success(response.data.message);

          this._getTaskList();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  updateCustomField = values => {
    apiClient
      .post(
        `${endpoints().partnerApplicationFieldAPI}/partner/field?${this.state
          .partnerDetails.id && "partnerId=" + this.state.partnerDetails.id}`,
        values
      )
      .then(response => {
        if (response.data) {
          toast.success(response.data.message);

          this._getTaskList();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  updateCustomTags = values => {
    apiClient
      .put(
        `${endpoints().partnerAPI}/custom/tag?${this.state.partnerDetails.id &&
          "partnerId=" + this.state.partnerDetails.id}`,
        values
      )
      .then(response => {
        if (response.data) {
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  deleteUploadedTaskFile = id => {
    apiClient
      .delete(`${endpoints().partnerAPI}/custom/media/${id}`)
      .then(response => {
        if (response.data) {
          toast.success(response.data.message);

          this._getTaskList();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  getPartnerMediaFiles = async () => {
    const partnerId = this.state.partnerDetails && this.state.partnerDetails.id;
    const apiUrl = `${endpoints().mediaAPI}/${partnerId}`;

    try {
      const response = await apiClient.get(`${apiUrl}`);
      if (response && response.data && response.data.data) {
        this.setState({ photos: response.data.data, isMediaLoading: false });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }

        console.error(errorMessage);
        this.setState({ isInvalidRequest: true, isMediaLoading: false });
      }
    }
  };
  handlePhotoDelete = photo => {
    var photosList = this.state.photos;

    //call delete api
    // in the success response write this code.
    let id = photo.id;
    apiClient
      .delete(`${endpoints().mediaAPI}/${id}`)
      .then(response => {
        if (response.data) {
          toast.success(response.data.message);
          var filtered = photosList.filter(function(value, index, arr) {
            return value.id !== photo.id;
          });
          this.setState({ photos: filtered });
          this.getPartnerMediaFiles();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  photoUpload = e => {
    const file = e.target.files ? e.target.files[0] : "";
    if (file && file.name) {
      const data = new FormData();
      data.append("uploadFile", file ? file : "");
      data.append("uploadFileName", file.name ? file.name : "");
      data.append(
        "partnerId",
        this.state.partnerDetails && this.state.partnerDetails.id
          ? this.state.partnerDetails.id
          : ""
      );
      this.setState({ isMediaLoading: false });

      apiClient
        .post(`${endpoints().mediaAPI}`, data)
        .then(response => {
          if (response.data) {
            toast.success(response.data.message);
            this.getPartnerMediaFiles();
            this._getPartnerDetails();
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    }
  };
  handleChange = e => {
    this.setState({ aboutMe: e });
  };

  // Get Message list
  getMessageList = async Id => {
    this.setState({ isMessageLoading: true });
    const { partnerDetails } = this.state;
    let partnerId = partnerDetails.id;

    try {
      const id = partnerId
        ? partnerId
        : getParamsByName("id")
        ? getParamsByName("id")
        : Id;
      const apiUrl = `${endpoints().messageAPI}/search?partner_id=${id}`;

      const response = await apiClient.get(`${apiUrl}`);
      this.setState({ messageList: response.data, isMessageLoading: false });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  handleMessage = (toUserId, messageId, messageDetails) => {
    this.props.history.replace({
      pathname: `/communication/${messageId}`,
      state: {
        messageDetails: messageDetails,
        toUserId: toUserId,
        messageId: messageId
      }
    });
  };
  handleClose = () => {
    this.setState({ partnerMessageModalOpen: false });
  };
  render() {
    const {
      isLoading,
      isPartnerManagerLoading,
      productList,
      industryList,
      abmExpertiseList,
      crmMarketingList,
      regionList,
      marketingChannelList,
      loadingModal,
      contactFormOptions,
      activeTab,
      partnerCompanyName,
      partnerStatics,
      selectedCountry,
      tagTypeList,
      tagsTypeLoading,
      partnerApplicationTaskList,
      referralLinkList,
      photos,
      isMobile,
      isMediaLoading,
      messageList,
      isMessageLoading,
      showRegionField
    } = this.state;
    if (isLoading || tagsTypeLoading) return <Spinner />;

    let {
      type,
      countryName,
      stateName,
      availability,
      timezone,
      contactFormId,
      contactFormName,
      encryptedPartnerId
    } = this.state.partnerDetails;

    const { partnerSettings, settings } = this.props;

    const partnerTypeSettingEnableTeam = partnerSettings
      ? getKeyValueByObject(
          partnerSettings,
          PARTNER_TYPE_SETTING_ENABLE_TEAM_MANAGEMENT
        )
      : "";

    const enablePartnerPayments = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNER_PAYMENTS)
      : "";

    const referralLinkBaseUrl = settings
      ? getKeyValueByObject(settings, SETTINGS_REFERRAL_LINK_BASE_URL)
      : "";

    const portal_url = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_URL)
      : "";

    const enableAccountMapping = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_ACCOUNT_MAPPING)
      : "";
    type = {
      label: type,
      value: type
    };

    if (countryName) {
      countryName = {
        label: countryName,
        value: countryName
      };
    }

    if (stateName) {
      stateName = {
        label: stateName,
        value: stateName
      };
    }

    availability = {
      label: availability,
      value: availability
    };

    timezone = {
      label: timezone,
      value: timezone
    };

    let contactForm = {
      label: contactFormName,
      value: contactFormId
    };

    const { openDeleteModal, partnerTypeList, isInvalidRequest } = this.state;
    const {
      id,
      profile_visibility,
      firstName,
      lastName,
      jobTitle,
      city,
      address1,
      address2,
      zipcode,
      phone,
      email,
      hourlyRate,
      websiteUrl,
      twitterUrl,
      facebookUrl,
      instagramUrl,
      linkedinUrl,
      youtubeUrl,
      headline,
      aboutMe,
      avatar,
      showHourlyRate,
      companyName,
      companyProfileUrl,
      companyShortName,
      marketplaceStatus,
      partnerTier,
      crossbeamPartner,
      inboundReferralLink,
      partnerTags,
      managerUserId,
      managerUser,
      admin_user_id,
      partnerUserId,
      isPartnerAdmin,
      region
    } = this.state.partnerDetails;

    const adminActionOptions =
      marketplaceStatus === PARTNER_STATUS_APPROVED
        ? [
            {
              value: "Login As",
              label: "Login As"
            },
            {
              value: "Decline Partner",
              label: "Decline Partner"
            },
            {
              value: "Delete",
              label: "Delete"
            }
          ]
        : marketplaceStatus === PARTNER_STATUS_DECLINED
        ? [
            {
              value: "Login As",
              label: "Login As"
            },
            {
              value: "Approve Partner",
              label: "Approve Partner"
            },
            {
              value: "Delete",
              label: "Delete"
            }
          ]
        : [
            {
              value: "Login As",
              label: "Login As"
            },
            {
              value: "Approve Partner",
              label: "Approve Partner"
            },
            {
              value: "Decline Partner",
              label: "Decline Partner"
            },
            {
              value: "Delete",
              label: "Delete"
            }
          ];

    const searchStatus = getParamsByName("section");

    const isProfile = searchStatus === TAB_PROFILE || activeTab === TAB_PROFILE;
    const isApplication =
      searchStatus === TAB_APPLICATION || activeTab === TAB_APPLICATION;
    const isReferral =
      searchStatus === TAB_REFERRAL_LINKS || activeTab === TAB_REFERRAL_LINKS;
    const isStatsAndActivity =
      searchStatus === TAB_STATS_AND_ACTIVITY ||
      activeTab === TAB_STATS_AND_ACTIVITY;
    const isTeam = searchStatus === TAB_TEAM || activeTab === TAB_TEAM;
    const isPayoutPreferences =
      searchStatus === TAB_PAYOUT_PREFERENCES ||
      activeTab === TAB_PAYOUT_PREFERENCES;
    const isAccountMapping =
      searchStatus === TAB_ACCOUNT_MAPPING || activeTab === TAB_ACCOUNT_MAPPING;
    const isCommunication =
      searchStatus === TAB_COMMUNICATIONS || activeTab === TAB_COMMUNICATIONS;
    const isFiles = searchStatus === TAB_FILES || activeTab === TAB_FILES;

    const enablePartnerManager = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNER_MANAGER)
      : "";

    const tagTypeInitialValues =
      this.getTagInitialValues(partnerTags, tagTypeList) || {};

    const enableDeals = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_DEALS)
      : "";

    const enableReferrals = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_REFERRALS)
      : "";
    const enable_communications = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_COMMUNICATIONS)
      : "";

    // Page Not Found
    if (isInvalidRequest) {
      return <Page404 />;
    }

    return (
      <>
        <DeleteModal
          isOpen={openDeleteModal}
          title="Delete Partner"
          toggle={() => {
            this.setState({ openDeleteModal: false });
          }}
          label={partnerCompanyName}
          deleteFunction={() => this.handleDelete()}
        />
        <div className={["edit-user-profile"].join(" ")}>
          <MyContext.Consumer>
            {context => (
              <>
                {context.isMobile && <PageTitle label={"Edit Your Profile"} />}
              </>
            )}
          </MyContext.Consumer>

          <div className="d-flex justify-content-between">
            <div className="">
              <small className="text-muted font-weight-bold">Partner</small>
              <h5 className="font-weight-bold">{partnerCompanyName}</h5>
            </div>
            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId)) && (
              <div
                className="d-flex align-items-center"
                style={{ zIndex: "12" }}
              >
                <SelectDropdown
                  buttonLabel="Admin Actions"
                  dropdownLinks={adminActionOptions}
                  color={"gray"}
                  hideCaret
                  selectName={"admin_action"}
                  handleChange={this.handleAdminAction}
                />
              </div>
            )}
          </div>

          <Nav tabs className="setting-tabs mb-0">
            <NavItem>
              <NavLink
                className={classnames({ active: isProfile })}
                onClick={() => {
                  this.toggle(TAB_PROFILE);
                  this._handleStatusChange(TAB_PROFILE);
                }}
              >
                Profile
              </NavLink>
            </NavItem>
            {((enableReferrals && enableReferrals === "true") ||
              (enableDeals && enableDeals === "true")) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: isReferral })}
                  onClick={() => {
                    this.toggle(TAB_REFERRAL_LINKS);
                    this._handleStatusChange(TAB_REFERRAL_LINKS);
                  }}
                >
                  {Terminology.get(SystemName.REFERRAL)} Links
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                className={classnames({ active: isStatsAndActivity })}
                onClick={() => {
                  this.toggle(TAB_STATS_AND_ACTIVITY);
                  this._handleStatusChange(TAB_STATS_AND_ACTIVITY);
                }}
              >
                Stats & Activity
              </NavLink>
            </NavItem>
            {/* Team Tab */}
            {(isPartner(this.props.roleId) &&
              partnerTypeSettingEnableTeam === "true") ||
            isSuperAdmin(this.props.roleId) ||
            isCompanyAdmin(this.props.roleId) ||
            isCompanyManager(this.props.roleId) ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: isTeam })}
                  onClick={() => {
                    this.toggle(TAB_TEAM);
                    this._handleStatusChange(TAB_TEAM);
                  }}
                >
                  Team
                </NavLink>
              </NavItem>
            ) : (
              ""
            )}
            {/* Application Tab */}
            <NavItem>
              <NavLink
                className={classnames({ active: isApplication })}
                onClick={() => {
                  this.toggle(TAB_APPLICATION);
                  this._handleStatusChange(TAB_APPLICATION);
                }}
              >
                Application
              </NavLink>
            </NavItem>
            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId) ||
              isPartnerAdmin === true) &&
              enablePartnerPayments === "true" && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: isPayoutPreferences })}
                    onClick={() => {
                      this.toggle(TAB_PAYOUT_PREFERENCES);
                      this._handleStatusChange(TAB_PAYOUT_PREFERENCES);
                    }}
                  >
                    Payout Preferences
                  </NavLink>
                </NavItem>
              )}
            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) &&
              enableAccountMapping == "true" && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: isAccountMapping })}
                    onClick={() => {
                      this.toggle(TAB_ACCOUNT_MAPPING);
                      this._handleStatusChange(TAB_ACCOUNT_MAPPING);
                    }}
                  >
                    Account Mapping
                  </NavLink>
                </NavItem>
              )}
            {enable_communications == "true" && (
              <NavItem>
                <NavLink
                  className={classnames({ active: isCommunication })}
                  onClick={() => {
                    this.toggle(TAB_COMMUNICATIONS);
                    this._handleStatusChange(TAB_COMMUNICATIONS);
                  }}
                >
                  Communications
                </NavLink>
              </NavItem>
            )}
            <NavItem>
              <NavLink
                className={classnames({ active: isFiles })}
                onClick={() => {
                  this.toggle(TAB_FILES);
                  this._handleStatusChange(TAB_FILES);
                }}
              >
                Files
              </NavLink>
            </NavItem>
          </Nav>
          {/* Tab Content */}
          <TabContent activeTab={activeTab}>
            {/*Profile Tab*/}
            <TabPane tabId={TAB_PROFILE}>
              <div className="form-wrapper">
                <Form
                  enableReinitialize={true}
                  initialValues={{
                    ...partnerTypeList,
                    firstName: firstName || "",
                    lastName: lastName || "",
                    jobTitle: jobTitle || "",
                    countryName: countryName || "",
                    city: city || "",
                    address1: address1 || "",
                    address2: address2 || "",
                    zipcode: zipcode || "",
                    stateName: stateName || "",
                    phone: phone || "",
                    email: email || "",
                    hourlyRate: hourlyRate || "",
                    timezone: timezone || "",
                    availability: availability || "",
                    websiteUrl: websiteUrl || "",
                    twitterUrl: twitterUrl || "",
                    facebookUrl: facebookUrl || "",
                    instagramUrl: instagramUrl || "",
                    linkedinUrl: linkedinUrl || "",
                    youtubeUrl: youtubeUrl || "",
                    profile_visibility,
                    inboundReferralLink: inboundReferralLink || "",
                    headline: headline || "",
                    aboutMe: aboutMe || "",
                    avatar: avatar || "",
                    companyName: companyName || "",
                    companyProfileUrl: companyProfileUrl || "",
                    companyShortName: companyShortName || "",
                    partnerTier: partnerTier
                      ? { value: partnerTier.id, label: partnerTier.name }
                      : "",
                    contactForm: contactForm || "",
                    crossbeamPartner: crossbeamPartner
                      ? {
                          value: crossbeamPartner.id,
                          label: crossbeamPartner.company_name
                        }
                      : "",
                    region:
                      regionList && regionList.find(data => data.id == region),
                    ...tagTypeInitialValues
                  }}
                  onSubmit={values => {
                    let partnerType = [];
                    let newValues = Object.assign({}, values);
                    this.setState({
                      partnerCompanyName: values.companyName
                    });
                    if (newValues.phone) {
                      newValues.phone = removeMaskedPhoneNumber(
                        newValues.phone
                      );
                    }
                    if (this.state.aboutMe) {
                      newValues.aboutMe = this.state.aboutMe;
                    }
                    if (tagTypeList.length > 0) {
                      tagTypeList.forEach(tagType => {
                        newValues[tagType.name] =
                          newValues[tagType.name] &&
                          newValues[tagType.name].length > 0
                            ? newValues[tagType.name]
                            : [];
                      });
                    }

                    newValues.availability = toString(
                      newValues.availability
                        ? newValues.availability.value
                        : newValues.availability
                    );
                    newValues.isAvailable = toString(
                      newValues.availability === availabilityOption[4].value
                        ? "false"
                        : "true"
                    );
                    newValues.timezone = toString(
                      newValues.timezone
                        ? newValues.timezone.value
                        : newValues.timezone
                    );
                    newValues.stateName = toString(
                      newValues.stateName
                        ? newValues.stateName.value
                        : newValues.stateName
                    );
                    newValues.countryName = toString(
                      newValues.countryName
                        ? newValues.countryName.value
                        : newValues.countryName
                    );

                    newValues.partnerTier = toString(
                      newValues.partnerTier
                        ? newValues.partnerTier.value
                        : newValues.partnerTier
                    );

                    newValues.contactForm = newValues.contactForm
                      ? newValues.contactForm.value
                      : newValues.contactForm.id;

                    newValues &&
                      newValues.partnerType &&
                      newValues.partnerType.forEach(data => {
                        if (data) {
                          partnerType.push({
                            partnerType: data.value
                          });
                        }
                      });
                    newValues.partnerType = partnerType;

                    newValues.crossbeamPartner = toString(
                      newValues.crossbeamPartner
                        ? newValues.crossbeamPartner.value
                        : newValues.crossbeamPartner
                    );

                    newValues.region = newValues.region
                      ? newValues.region.id
                      : newValues.region;

                    this.handleSubmit(newValues);
                  }}
                >
                  <PartnerProfileVisibilitySettings
                    partnerSettings={partnerSettings}
                    partnerId={id}
                    companyProfileUrl={companyProfileUrl}
                    updateVisibility={this.updateVisibility}
                    profileVisibility={profile_visibility}
                    showProfileVisibility={true}
                    handlePartnerProfileVisibility={value =>
                      this.handlePartnerProfileVisibility(value)
                    }
                  />
                  <div
                    className={`user-profile-main ${
                      isMobile ? "d-block" : "d-flex"
                    } align-items-start`}
                  >
                    <PartnerProfileSidebar
                      isMobile={isMobile}
                      expertId={id}
                      partnerSettings={partnerSettings}
                      partnerDetails={this.state.partnerDetails}
                      showHourlyRate={showHourlyRate}
                      selectedCountry={selectedCountry}
                      apiUrl={`${endpoints().partnerAvatarUpdate}?id=${id}`}
                      isPartnerManagerLoading={isPartnerManagerLoading}
                      managerUserId={managerUserId}
                      managerUser={managerUser}
                      userList={this.state.userList}
                      changeManager={this.changeManager}
                      enablePartnerManager={enablePartnerManager}
                      regionList={regionList}
                      showRegionField={showRegionField}
                      loggedInUser={this.props.loggedInUser}
                    />
                    {!isMediaLoading && (
                      <PartnerProfileMain
                        handleAboutMe={e => {
                          this.handleChange(e);
                        }}
                        expertId={id}
                        partnerDetails={this.state.partnerDetails}
                        productList={productList}
                        regionList={regionList}
                        abmExpertiseList={abmExpertiseList}
                        marketingChannelList={marketingChannelList}
                        industryList={industryList}
                        crmMarketingList={crmMarketingList}
                        contactFormOptions={contactFormOptions}
                        inboundReferralLink={inboundReferralLink || ""}
                        settings={settings}
                        tagTypeList={tagTypeList}
                        photoUpload={this.photoUpload}
                        photos={photos}
                        handlePhotoDelete={this.handlePhotoDelete}
                      />
                    )}
                  </div>
                </Form>
              </div>
              <LoadingModal isLoading={loadingModal} />
            </TabPane>

            {/* Referral Links Tab */}
            <TabPane tabId={TAB_REFERRAL_LINKS}>
              <div className="form-wrapper">
                <Form>
                  <PartnerProfileLinks
                    partnerSettings={this.state.partnerSettings}
                    dealRegistrationLink={
                      companyProfileUrl
                        ? `${portal_url}/deal/register?partner=${companyProfileUrl}`
                        : ""
                    }
                    inboundReferralLink={
                      companyProfileUrl
                        ? `${portal_url}/referral/submit?partner=${companyProfileUrl}&user=${partnerUserId}`
                        : ""
                    }
                    referralLinkList={referralLinkList}
                    referralLinkBaseUrl={referralLinkBaseUrl}
                    encryptedPartnerId={encryptedPartnerId}
                    companyProfileUrl={companyProfileUrl}
                    partnerId={id}
                    referralToSignUpLink={
                      referralLinkBaseUrl
                        ? `${referralLinkBaseUrl}/${
                            encryptedPartnerId ? encryptedPartnerId : id
                          }`
                        : ""
                    }
                    settings={settings}
                    showPublicReferralLinkTab={
                      this.state.showPublicReferralLinkTab
                    }
                  />
                </Form>
              </div>
            </TabPane>

            {/* Stats & Activity Tab */}
            <TabPane tabId={TAB_STATS_AND_ACTIVITY}>
              {isTrue(enableReferrals) && (
                <ReferralProgramStats
                  isApproved={marketplaceStatus === PARTNER_STATUS_APPROVED}
                  partnerStatics={partnerStatics}
                  partnerId={this.state.partnerDetails.id}
                  enablePartnerPayments={enablePartnerPayments}
                />
              )}
              <PartnerProfileActivities
                settings={settings}
                partnerActivity={this.state.partnerActivity}
                plannedActivity={this.state.plannedActivity}
                pastActivity={this.state.pastActivity}
                partnerId={this.state.partnerDetails.id}
                getActivityList={() => {
                  this.getActivityList();
                }}
              />
            </TabPane>

            {/* Team Tab */}
            <TabPane tabId={TAB_TEAM}>
              <TeamMember
                partnerId={this.state.partnerDetails.id}
                members={this.state.teamMembers}
                getPartnerUsers={this._getPartnerUsers}
                getActivityList={this.getActivityList}
              />
            </TabPane>

            {/* Application Tab */}
            <TabPane tabId={TAB_APPLICATION}>
              {isEmpty(partnerApplicationTaskList) ? (
                <Applications
                  partnerApplicationTaskList={partnerApplicationTaskList}
                  uploadTaskFile={this.uploadTaskFile}
                  deleteUploadedTaskFile={this.deleteUploadedTaskFile}
                  updateCustomField={this.updateCustomField}
                  updateCustomTags={this.updateCustomTags}
                />
              ) : (
                <NoRecordsFound
                  showMessage={true}
                  hideCard={true}
                  message="No Records Found"
                  position="center"
                />
              )}
            </TabPane>

            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId) ||
              isPartnerAdmin === true) &&
              enablePartnerPayments === "true" && (
                <TabPane tabId={TAB_PAYOUT_PREFERENCES}>
                  <BillingDetail
                    title="Billing Details"
                    classNames={"mt-4"}
                    billingDetails={this.state.billingDetails}
                    billingDetailsSubmit={this.billingDetailsSubmit}
                  />
                  <AddPaymentOptions
                    paymentMethodSubmit={this.paymentMethodSubmit}
                  />
                  <PaymentOption
                    paymentOptions={this.state.paymentMethod}
                    setDefaultPaymentOption={this.setDefaultPaymentOption}
                    deletePaymentOption={this.deletePaymentOption}
                  />
                </TabPane>
              )}

            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) &&
              enableAccountMapping == "true" && (
                <TabPane tabId={TAB_ACCOUNT_MAPPING}>
                  {this.state.partnerDetails && (
                    <div className="mt-3">
                      <PageTitle label="Overlap Activities" />
                      <ReduxTable
                        id="partnerAccountMappingactivities"
                        apiURL={`${endpoints().crossbeamAPI}/timeline`}
                        params={{
                          overlapOnly: true,
                          partnerId: this.state.partnerDetails.id
                        }}
                      >
                        <ReduxColumn field="companyName">Company</ReduxColumn>
                        <ReduxColumn field="ourPopulationName">
                          Our Population
                        </ReduxColumn>
                        <ReduxColumn field="partnerName">Partner</ReduxColumn>
                        <ReduxColumn field="partnerPopulationName">
                          Partner Population
                        </ReduxColumn>
                        <ReduxColumn field="overlapAt">Updated</ReduxColumn>
                      </ReduxTable>
                    </div>
                  )}
                </TabPane>
              )}
            {isTrue(enable_communications) && (
              <>
                <TabPane tabId={TAB_COMMUNICATIONS}>
                  <div className="mt-3">
                    <PageTitle
                      buttonHandler={
                        messageList && messageList.length > 0
                          ? this.handleMessageModal
                          : false
                      }
                      label="Communications"
                      buttonLabel="New Message"
                    />
                    <MessageList
                      messageList={messageList}
                      handleMessage={this.handleMessage}
                      messageButton={true}
                      isLoading={isMessageLoading}
                      buttonLabel="New Message"
                      handleMessageModal={e => {
                        this.handleMessageModal(e);
                      }}
                    />
                  </div>
                </TabPane>
              </>
            )}

            <TabPane tabId={TAB_FILES}>
              <div className="mt-3">
                <File
                  history={this.props.history}
                  partnerId={this.state.partnerDetails.id}
                  roleId={this.props.roleId}
                  users={this.state.teamMembers}
                />
              </div>
            </TabPane>
          </TabContent>

          {this.state.partnerMessageModalOpen && (
            <MessageDialog
              modal={this.state.partnerMessageModalOpen}
              toggle={this.togglePartnerMessageModalOpen}
              sendMessageData={this.sendMessageData}
              heading="Send Partner a Message"
              buttonLabel={"Send Message"}
              handleClose={this.handleClose}
            />
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(EditPartnerProfile);
