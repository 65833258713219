import React, { useEffect, useState } from "react";
import toast from "../../../components/base/Toast";
import moment from "moment";
import { Badge, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Helpers
import {
  isCustomer,
  isSuperAdmin,
  isCompanyAdmin,
  isExpert,
  getFullName,
  isCompanyManager,
  getUrlPath,
  isPartner,
  getCookie
} from "../../../lib/helper";

// Constants
import {
  DELIVERABLE_STATUS_PENDING,
  DELIVERABLE_STATUS_COMPLETED
} from "../../../projectDeliverableStatus/Constants";

// Components
import useToggle from "../../../components/customhooks/useToggle";
import Avatar from "../../../components/base/Avatar";
import AssetsUploader from "./AssetsUploader";
import CancelButton from "../../../components/base/CancelButton";

// Modal
import AddTaskModal from "./task/AddTaskModal";
import EditTaskModal from "./task/EditTaskModal";
import DeleteTaskModal from "./task/DeleteTaskModal";
import LogHoursModal from "./task/LogHoursModal";

// API
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";

import {
  CheckmarkIcon,
  EditIconAlt,
  PlusIcon,
  TrashIcon,
  WatchIcon,
  CloudUploadIcon
} from "../../../assets/img/icons";

import isEqual from "react-fast-compare";
import ExtendProjectModal from "./ExtendProjectModal";
import {
  PROJECT_EXTEND_STATUS_PENDING,
  PROJECT_TYPE_PROJECT_URL_PATH,
  PROJECT_TYPE_CAMPAIGN_URL_PATH
} from "../../../project/Constants";
import { isBadRequest } from "../../../common/http";

import { useSelector } from "react-redux";

const DeleteTaskButton = React.memo(function DeleteTaskButton(props) {
  const [modal, setModal] = useToggle();
  const {
    id,
    taskIndex,
    deleteProjectTasks,
    deleteTask,
    taskName,
    currentTaskPartnerId,
    handleShowUploader
  } = props;
  return (
    <>
      <button
        className={["delete-btn", "btn-link", "btn"].join(" ")}
        onClick={() => {
          setModal(prevState => !prevState);
        }}
      >
        <TrashIcon />
      </button>

      <DeleteTaskModal
        modal={modal}
        setModal={setModal}
        taskIndex={taskIndex}
        id={id}
        deleteProjectTasks={deleteProjectTasks}
        deleteTask={deleteTask}
        taskName={taskName}
        handleShowUploader={handleShowUploader}
      />
    </>
  );
}, isEqual);

const Task = React.memo(function Task(props) {
  // State Initial State
  const [modal, setModal] = useToggle();
  const [logModal, setLogModal] = useToggle();
  const [modalDone, setModalDone] = useToggle();
  const [showUploader, setShowUploader] = useState(false);

  const {
    id,
    status,
    name,
    created_at,
    assignee,
    activity,
    task_users
  } = props.task;
  const {
    index,
    updateAssignee,
    markAsPendingTask,
    markAsCompletedTask,
    deleteTask,
    updateTaskName,
    updateTaskDate,
    updateProjectTasks,
    deleteProjectTasks,
    requestApproveDeliverable,
    updateTaskStatusDeliverable,
    logHours,
    projectId,
    currentUserId,
    contextProvider,
    isProject,
    setCurrentTaskPartnerId,
    setAssignTaskPartnerId
  } = props;

  const currentTaskPartnerId = contextProvider.currentTaskPartnerId;

  const currentUser = useSelector(state => state.user);

  // Handle showUploader icon
  const handleShowUploader = e => {
    setShowUploader(!e);
  };

  let statusClass = "";
  let hasPermissionToApprove = true;
  if (status && status === DELIVERABLE_STATUS_COMPLETED) {
    statusClass = "checkbox-completed";
    hasPermissionToApprove = false;
  } else if (status && status === DELIVERABLE_STATUS_PENDING) {
    statusClass = "checkbox-pending";
    hasPermissionToApprove = false;
  }

  // Show Task To The Assigned User
  const isAssignedUser =
    assignee && assignee.id && currentUserId === assignee.id;

  const confirmDoneModal = (
    id,
    modal,
    setModal,
    taskName,
    index,
    markAsPendingTask,
    requestApproveDeliverable
  ) => {
    return (
      <Modal
        isOpen={modal}
        toggle={setModal}
        backdrop="static"
        className={["edit-task-modal"].join(" ")}
      >
        <ModalHeader toggle={setModal}>
          <h4 className={["font-weight-bold"].join(" ")}>Done?</h4>
        </ModalHeader>
        <ModalBody className={["text-center", "mb-4"].join(" ")}>
          <div className="content">
            Are you sure you are done with <b>{`“${taskName}”`}</b> and want to
            send for approval?
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={() => setModal(prevState => !prevState)} />
            <button
              className="btn btn-success"
              onClick={() => {
                setModal(prevState => !prevState);
                requestApproveDeliverable(id);
                markAsPendingTask(index);
              }}
            >
              Yes, Send for Approval
            </button>
          </div>
        </ModalFooter>
      </Modal>
    );
  };

  return (
    <>
      <div className="task">
        <div className="task-left">
          {/*Show Checkbox Based On Status*/}
          <div
            className={`checkbox ${statusClass}`}
            onClick={() =>
              isExpert(currentUser && currentUser.roleId) &&
              hasPermissionToApprove &&
              isAssignedUser
                ? hasPermissionToApprove && setModalDone()
                : !isExpert(currentUser && currentUser.roleId)
                ? (markAsCompletedTask(index, status),
                  updateTaskStatusDeliverable(id, status))
                : "Javascript:void(0)"
            }
          >
            {status ? <CheckmarkIcon /> : ""}
            {!isCustomer(currentUser && currentUser.roleId) &&
              !isSuperAdmin(currentUser && currentUser.roleId) &&
              !isCompanyAdmin(currentUser && currentUser.roleId) &&
              !isCompanyManager(currentUser && currentUser.roleId) &&
              confirmDoneModal(
                id,
                modalDone,
                setModalDone,
                name,
                index,
                markAsPendingTask,
                requestApproveDeliverable
              )}
          </div>

          <div className="name-comments mr-2 ">
            {/*Show Task Name*/}
            {name && <a className="text-decoration-none">{name}</a>}
            {isProject && (
              <Badge color="secondary" pill>
                {activity.hours_spent ? activity.hours_spent : 0} Hours
              </Badge>
            )}
          </div>
        </div>

        <div className="task-right">
          <div className="date-role ">
            <p className={"h6-5"}>{created_at}</p>

            {Object.keys(assignee).length > 0 ? (
              // Show Assignee Name
              <>
                <span className="user-badge">
                  <Avatar
                    firstName={assignee.firstName}
                    lastName={assignee.lastName}
                    url={assignee.picture}
                    size="customSize"
                    imageSize={"20"}
                    fontSize={8}
                  />
                  <span
                    className="text-truncate"
                    style={{ marginLeft: "5px", maxWidth: "80px" }}
                  >
                    {currentUserId == assignee.id ? "You" : assignee.name}
                  </span>
                </span>
              </>
            ) : (
              // Show UnAssignee
              <>
                <span className="user-badge">
                  <Avatar size="customSize" imageSize={"20"} fontSize={8} />
                  <span
                    className="text-truncate"
                    style={{ marginLeft: "5px", maxWidth: "80px" }}
                  >
                    Unassigned
                  </span>
                </span>
              </>
            )}
          </div>

          {/*Show Task Actions For All Tasks Based on the Condition*/}
          <div className="task-actions">
            <>
              {/*Show Deliverables Upload Icon*/}
              <button
                className={["edit-btn", "btn-link", "btn"].join(" ")}
                onClick={e => setShowUploader(prevState => !prevState)}
              >
                <CloudUploadIcon />
                {/* 1. Show the dropzone on click */}
              </button>

              {/*Show Log Time For Assigned User*/}
              {Object.keys(assignee).length > 0 && isAssignedUser
                ? isProject && (
                    <>
                      <button
                        className={["edit-btn", "btn-link", "btn"].join(" ")}
                        onClick={() => setLogModal()}
                      >
                        <WatchIcon />
                      </button>

                      <LogHoursModal
                        modal={logModal}
                        setModal={setLogModal}
                        name={name}
                        index={index}
                        logHours={logHours}
                        id={id}
                        projectId={projectId}
                      />
                    </>
                  )
                : ""}

              {/*Show Edit Task For All Users*/}
              <button
                className={["edit-btn", "btn-link", "btn"].join(" ")}
                onClick={() => setModal()}
              >
                <EditIconAlt />
              </button>

              {/* Trigger Edit Task modal */}
              <EditTaskModal
                modal={modal}
                setModal={setModal}
                id={id}
                name={name}
                index={index}
                created_at={created_at}
                assignee={assignee}
                task_users={task_users}
                currentUserId={currentUserId || getCookie("userId")}
                updateAssignee={updateAssignee}
                updateTaskName={updateTaskName}
                updateTaskDate={updateTaskDate}
                updateProjectTasks={updateProjectTasks}
                setCurrentTaskPartnerId={setCurrentTaskPartnerId}
                setAssignTaskPartnerId={setAssignTaskPartnerId}
              />

              {/*Show Delete Task For All Users*/}
              <DeleteTaskButton
                taskIndex={index}
                id={id}
                deleteProjectTasks={deleteProjectTasks}
                deleteTask={deleteTask}
                taskName={name}
                handleShowUploader={handleShowUploader}
              />
            </>
          </div>
        </div>
      </div>

      {/*Show Assets Task Uploader*/}
      {showUploader && (
        <div>
          <AssetsUploader
            currentTaskPartnerId={currentTaskPartnerId}
            projectDeliverableId={id}
            showUploader={showUploader}
            handleShowUploader={handleShowUploader}
          />
        </div>
      )}

      {/*Show Pending Task, Notification Alert To Customer*/}
      {status === DELIVERABLE_STATUS_PENDING &&
        isCustomer(currentUser && currentUser.roleId) && (
          <div className="alerts-wrapper mb-0">
            <div className="alert alert-danger text-white p-3-5 border-0">
              Expert has marked this complete. If done, please check off by
              clicking the yellow circle or provide feedback in the Discussion
              area below.
            </div>
          </div>
        )}
    </>
  );
});

const AddTask = React.memo(function AddTask(props) {
  const {
    modal,
    setModal,
    index,
    addTask,
    task_users,
    extendProject,
    setExtendProjectModal,
    extendProjectModal,
    isProject,
    changeStatus,
    currentUserId,
    setCurrentTaskPartnerId,
    setAssignTaskPartnerId
  } = props;

  const currentUser = useSelector(state => state.user);

  return (
    <>
      <AddTaskModal
        modal={modal}
        setModal={setModal}
        index={index}
        addTask={addTask}
        task_users={task_users}
        changeStatus={changeStatus}
        setCurrentTaskPartnerId={setCurrentTaskPartnerId}
        setAssignTaskPartnerId={setAssignTaskPartnerId}
        currentUserId={currentUserId || getCookie("userId")}
      />
      <ExtendProjectModal
        extendProject={extendProject}
        modal={extendProjectModal}
        setModal={setExtendProjectModal}
      />
      {/*{addTaskModal(modal, setModal, index, addTask, task_users)}*/}
      <div className="task">
        <div className="task-left">
          <div
            className="checkbox add-task cursor-pointer"
            onClick={e => {
              e.preventDefault();
              setModal(prevState => !prevState);
            }}
          >
            <PlusIcon />
          </div>
          <a
            href="#"
            className={["add-task"].join(" ")}
            onClick={e => {
              e.preventDefault();
              setModal(prevState => !prevState);
            }}
          >
            Add a new task / deliverable...
          </a>
        </div>
        {(isSuperAdmin(currentUser && currentUser.roleId) ||
          isCompanyAdmin(currentUser && currentUser.roleId) ||
          isCustomer(currentUser && currentUser.roleId) ||
          isCompanyManager(currentUser && currentUser.roleId)) &&
          isProject && (
            <a
              href="#"
              className={["add-task"].join(" ")}
              onClick={e => {
                e.preventDefault();
                setExtendProjectModal(prevState => !prevState);
              }}
            >
              Extend Project
            </a>
          )}
      </div>
    </>
  );
});

const mockTask = {
  id: 0,
  status: "",
  name: "Task Name",
  comments: 0,
  created_at: "Jun 10",
  assignee: [],
  activity: "0"
};

const TaskList = React.memo(function TaskList(props) {
  const {
    projectId,
    expertId,
    projectTeam,
    projectAssignee,
    tasks,
    status,
    newClient,
    currentUserId,
    getProjectApprovedExperts,
    getProjectTaskCompletedCount,
    changeStatus,
    setCurrentTaskPartnerId,
    setAssignTaskPartnerId,
    contextProvider
  } = props;
  const projectTasks = [];

  const currentBasePath = getUrlPath(1);
  const isProject = currentBasePath === PROJECT_TYPE_PROJECT_URL_PATH;
  const isCampaign = currentBasePath === PROJECT_TYPE_CAMPAIGN_URL_PATH;

  let users = [];
  if (projectAssignee && projectAssignee.length > 0) {
    projectAssignee.forEach(assignee => {
      users.push({
        id: assignee.id,
        firstName: assignee.firstName,
        lastName: assignee.lastName,
        name: getFullName(assignee.firstName, assignee.lastName),
        picture: assignee.avatarUrl,
        partnerId: assignee.partner_id
      });
    });
  }

  const [taskList, setTaskList] = useState(projectTasks);

  const handleUpdateTask = (taskIndex = undefined, updatedField = {}) => {
    return taskList;
  };

  const handleUpdateAssignee = (taskIndex = 0, newAssignee = {}) => {
    setCurrentTaskPartnerId(newAssignee.partnerId);
    let newTaskList = handleUpdateTask(taskIndex, { assignee: newAssignee });
    setTaskList(newTaskList);
  };

  const handleMarkTaskPending = (taskIndex = 0) => {
    let newTaskList = handleUpdateTask(taskIndex, {
      status: DELIVERABLE_STATUS_PENDING
    });

    setTaskList(newTaskList);
  };

  const handleMarkCompletedTask = (taskIndex = 0, currentStatus) => {
    let status;
    if (!tasks[taskIndex]) {
      status = DELIVERABLE_STATUS_COMPLETED;
    } else if (currentStatus === DELIVERABLE_STATUS_PENDING) {
      status = DELIVERABLE_STATUS_COMPLETED;
    } else if (tasks[taskIndex].status === "") {
      status = DELIVERABLE_STATUS_COMPLETED;
    } else if (!tasks[taskIndex].status && !currentStatus) {
      status = DELIVERABLE_STATUS_COMPLETED;
    } else {
      status =
        tasks[taskIndex].status === "" ? DELIVERABLE_STATUS_COMPLETED : null;
    }

    let newTaskList = handleUpdateTask(taskIndex, { status });
    setTaskList(newTaskList);
  };

  const handleAddTask = async (name, dueDate, newAssignee) => {
    const data = {
      project_id: projectId,
      name: name,
      due_date: dueDate,
      isCampaign: isCampaign,
      status: status
    };

    if (newAssignee && newAssignee.id) {
      data.user_id = newAssignee.id;
    }

    try {
      const response = await apiClient.post(
        `${endpoints().projectDeliverableAPI}`,
        data
      );
      setTaskList([
        ...taskList,
        {
          id: response.data.id,
          status: "",
          name: name,
          due_date: dueDate ? moment(dueDate).format("MMM. DD, YYYY") : "",
          comments: [],
          created_at: dueDate ? moment(dueDate).format("MMM. DD, YYYY") : "",
          assignee: newAssignee,
          activity: {
            hours_logged: 0,
            last_action: ""
          },
          task_users: users
        }
      ]);
      getProjectTaskCompletedCount(projectId);
      props.contextProvider.getProjectDetails(projectId);
      props.contextProvider.getProjectTasks(projectId);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
        toast.error(error.response.data.message);
      }
    }
  };

  const handleExtendProject = (additionalHours, extendDate, extendNotes) => {
    const data = {
      project_id: projectId,
      expert_id: expertId,
      additional_hours: additionalHours,
      extend_date: extendDate,
      notes: extendNotes,
      status: PROJECT_EXTEND_STATUS_PENDING
    };

    return apiClient
      .post(`${endpoints().projectExtendAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          toast.error(error.response.data.message);
        }
      });
  };

  const handleDeleteTask = index => {
    let updatedTasks = taskList;
    updatedTasks.splice(index, 1);
    setTaskList(updatedTasks);
  };

  const handleUpdateTaskName = (taskIndex = 0, taskName = "Task Name") => {
    let newTaskList = handleUpdateTask(taskIndex, {
      name: taskName
    });
    setTaskList(newTaskList);
  };

  const handleUpdateTaskDate = (
    taskIndex = 0,
    name,
    taskDueDate = new Date()
  ) => {
    let newTaskList = handleUpdateTask(taskIndex, {
      name,
      created_at: moment(taskDueDate).format("MMM. DD, YYYY")
    });
    setTaskList(newTaskList);
  };

  const handleLoggingHours = (
    setModal,
    taskIndex,
    projectId,
    id,
    hours,
    date
  ) => {
    let alreadyLoggedHours = taskList[taskIndex].activity.hours_spent
      ? parseInt(taskList[taskIndex].activity.hours_spent)
      : 0;
    let currentHours = hours ? parseInt(hours) : 0;
    let totalHours = currentHours + alreadyLoggedHours;

    let newTaskList = handleUpdateTask(taskIndex, {
      activity: {
        hours_spent: totalHours,
        last_action: date
      }
    });

    return apiClient
      .post(`${endpoints().projectDeliverableAPI}/timeEntry`, {
        projectId,
        deliverableId: id,
        hoursSpent: hours,
        date: moment(date).format("YYYY-MM-DD")
      })
      .then(() => {
        setTaskList(newTaskList);
        getProjectApprovedExperts(projectId, true);
        setModal();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          toast.error(error.response.data.message);
        }
      });
  };

  useEffect(() => {
    setTaskList(taskList && taskList.length > 0 ? taskList : projectTasks);
  }, [taskList]);

  useEffect(() => {
    let tasksList = [];
    if (tasks && tasks.length > 0) {
      tasks.forEach(task => {
        tasksList.push({
          id: task.id,
          status: task.status,
          name: task.description,
          comments: [],
          created_at: task.due_date
            ? moment(task.due_date).format("MMM. DD, YYYY")
            : "",
          assignee: task.assignee,
          activity: task.activity,
          task_users: users
        });
      });
      setTaskList(tasksList);
    }
  }, [tasks]);

  const [addTaskModalState, setAddTaskModalState] = useToggle();
  const [extendProjectModalState, setExtendProjectModalState] = useToggle();
  const currentUser = useSelector(state => state.user);

  return (
    <div className="task-list">
      {(taskList && taskList.length > 0 ? taskList : projectTasks).map(
        (task, index) => (
          <Task
            task={task}
            contextProvider={contextProvider}
            currentUserId={currentUserId}
            projectId={projectId}
            projectTeam={projectTeam}
            index={index}
            key={index}
            updateAssignee={handleUpdateAssignee}
            markAsPendingTask={handleMarkTaskPending}
            markAsCompletedTask={handleMarkCompletedTask}
            deleteTask={handleDeleteTask}
            updateTaskName={handleUpdateTaskName}
            updateTaskDate={handleUpdateTaskDate}
            logHours={handleLoggingHours}
            updateProjectTasks={props.updateProjectTasks}
            deleteProjectTasks={props.deleteProjectTasks}
            requestApproveDeliverable={props.requestApproveDeliverable}
            updateTaskStatusDeliverable={props.updateTaskStatusDeliverable}
            isProject={isProject}
            setCurrentTaskPartnerId={setCurrentTaskPartnerId}
            setAssignTaskPartnerId={setAssignTaskPartnerId}
          />
        )
      )}

      {/*TODO: Santhosh to decide on how to check for a new client!*/}
      {newClient && isCustomer(currentUser && currentUser.roleId) && (
        <div className={["mock-tasks", "pointer-none", "disabled"].join(" ")}>
          <Task task={mockTask} />
          <Task task={mockTask} />
          <Task task={mockTask} />
        </div>
      )}
      {/*Show Add Task For all users*/}
      <AddTask
        addTask={handleAddTask}
        modal={addTaskModalState}
        setModal={setAddTaskModalState}
        extendProject={handleExtendProject}
        extendProjectModal={extendProjectModalState}
        setExtendProjectModal={setExtendProjectModalState}
        key={taskList.length}
        index={taskList.length}
        task_users={users}
        isProject={isProject}
        changeStatus={changeStatus}
        currentUserId={currentUserId}
        setCurrentTaskPartnerId={setCurrentTaskPartnerId}
        setAssignTaskPartnerId={setAssignTaskPartnerId}
      />
    </div>
  );
}, isEqual);

export default TaskList;
