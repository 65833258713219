//Lead page
export const COURSE_PUBLISHED = "Published";
export const COURSE_DRAFT = "Draft";

// Partner Permission
export const COURSE_PERMISSION_ALL_PARTNERS = "All Partners";
export const COURSE_PERMISSION_SELECTED_PARTNERS = "Selected Partners";
export const COURSE_PERMISSION_INDIVIDUAL_PARTNER = "Individual Partner";

// Tabs
export const TAB_BASICS = "Basics";
export const TAB_LESSON_MODULES = "Lesson Modules";
export const TAB_PERMISSIONS = "Permissions";
export const TAB_DETAILS = "Details";
export const COURSE_GRID_VIEW = "Grid";
export const COURSE_LIST_VIEW = "List";
export const TAB_USERS = "Users";
export const TAB_CERTIFICATES = "Certificates";
export const TAB_PARTNERS = "Partners";

// Course to Unlock
export const CHOOSE_COURSE_TO_UNLOCK = "Choose Course to Unlock";

//Course Requirements Radio Button
export const COURSE_SINGLE_COURSE = "Single Course";
export const COURSE_PROGRESSIVE_COURSE =
  "Progressive Course that will Unlock Another Course after Completion";

//Lesson Status
export const COURSE_LESSON_STATUS_COMPLETED = "Completed";
export const COURSE_LESSON_STATUS_PENDING = "Pending";

//Course Type Constant
export const COURSE_TYPE_SINGLE = 1;
export const COURSE_TYPE_PROGRESSIVE = 2;
export const COURSE_FILE = "COURSE_FILE";
export const COURSE_LESSON = "COURSE_LESSON";
export const FILE = "FILE";
