import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";

// Constants
import { PARTNER_RESOURCE_CATEGORY_SUB_TAB } from "../../../../setting/Constants";
import ResourceCategory from "./ResourceCategory";

const PartnerResourceTab = props => {
  const {
    activeTab,
    activeSubTab,
    adminSettings,
    toggleTabs,
    history,
    activeSection
  } = props;

  return (
    <>
      <Nav tabs className="admin-tabs">
        {/*CategoryTab */}
        <NavItem>
          <NavLink className="active">Category</NavLink>
        </NavItem>
      </Nav>
      <ResourceCategory
        activeTab={activeTab}
        activeSubTab={activeSubTab || PARTNER_RESOURCE_CATEGORY_SUB_TAB}
        activeSection={activeSection}
        adminSettings={adminSettings}
        toggleTabs={toggleTabs}
        history={history}
      />
    </>
  );
};

export default PartnerResourceTab;
