import React from "react";
import Rating from "react-rating";

// Assets
import { StarIcon, StarIconFilled } from "../../assets/img/icons";

// Components
import ExpertCard from "../../components/base/ExpertCard";

const ExpertReviewCard = props => {
  const {
    expertReviewDetail: {
      projectExperts,
      comment,
      userName,
      reviewRatings,
      projectDetails
    }
  } = props;

  // Get the overall_experience Rating Count
  let overall_experience = 0;
  reviewRatings &&
    reviewRatings.forEach(reviewRating => {
      if (reviewRating.name === "overall_experience") {
        overall_experience = parseInt(reviewRating.rating, 10);
      }
    });

  return (
    <div className="feedback-component d-flex justify-content-start w-100">
      <ExpertCard
        classnames="expert-card"
        data={projectExperts}
        size="m-0"
        key={projectExperts.id}
        certified={projectExperts.certified}
        openLinkInNewTab
      />

      <div className="rating-component w-100">
        <div className="form-wrapper">
          <div className="field-wrapper mb-4-5 mt-0">
            <p>
              <Rating
                initialRating={overall_experience}
                emptySymbol={<StarIcon />}
                fullSymbol={<StarIconFilled />}
                readonly
              />
            </p>
            {comment && (
              <p>
                <span>"{comment}"</span>
              </p>
            )}

            <p className="font-weight-bold">
              {" "}
              - {userName ? `${userName},` : ""} {projectDetails.customerName}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ExpertReviewCard;
