import React, { useState } from "react";
import { DropdownItem } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import Avatar from "../../../../../components/base/Avatar";
import Form from "../../../../../components/base/Form";
import Select from "../../../../../components/base/Select";
import CancelButton from "../../../../../components/base/CancelButton";
import MoreDropdown from "../../../../../components/base/MoreDropdown";
import Link from "../../../../../components/base/Link";

// Helper
import { getFullName } from "../../../../../lib/helper";

import {
  USER_STATUS_INVITED,
  USER_STATUS_ACCEPTED,
  USER_INVITED_TEXT,
  USER_ACCEPTED_TEXT
} from "../../../../../roles/Constants";

const statusStyle = {
  borderRadius: "10px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "75px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px",
  backgroundColor: "#0AD4BB"
};

const fontStyle = {
  fontSize: "14px"
};

const initialValues = {
  role: ""
};

const TeamMember = ({
  member,
  index,
  userRoles,
  removeTeamMember,
  handleUserRole,
  resendInvite
}) => {
  const [removeMember] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [removeModal, setRemoveModal] = useState(false);
  const memberToggle = () => setRemoveModal(!removeModal);

  const [role, setRole] = useState();

  const handleChange = values => {
    const selectedRole = values.label;
    setRole(selectedRole);
  };
  return (
    <>
      <div className="row mb-3 p-1">
        <div className="col-4 p-0">
          <div className="row">
            <div className="col-2 pl-2">
              <Avatar
                firstName={member.firstName}
                lastName={member.lastName}
                size="customSize"
                imageSize={"36"}
                url={member.avatarUrl}
              />
            </div>
            <div className="col-10">
              <p className="h6-5 font-weight-bold">
                <Link
                  decoration={"text-dark text-decoration-none"}
                  url={`/user/edit/${member.id}?redirect=/users?type=Partner`}
                  text={getFullName(member.firstName, member.lastName)}
                />

                <div className="row pl-3">
                  <Link
                    className="text-truncate"
                    url={`/user/edit/${member.id}?redirect=/users?type=Partner`}
                    text={member.email}
                  />
                </div>
              </p>
            </div>
          </div>
        </div>

        {member.last_loggedin_at ? (
          <div className="col-2 pr-0">
            <div style={fontStyle}>
              <p className="font-weight-bold">Last Login</p>
              <p>{member.last_loggedin_at}</p>
            </div>
          </div>
        ) : (
          <div className="col-2 pr-0">
            <div style={fontStyle}>
              <p className="font-weight-bold">Invited sent</p>
              <p>{member.createdAt}</p>
            </div>
          </div>
        )}
        <div className="col-3 p-0">
          {!removeMember && (
            <div style={fontStyle}>
              <p className="font-weight-bold">Role</p>
              <p>{member.role}</p>
            </div>
          )}
        </div>
        <div className="actions col-2">
          <p
            className={`text-center ${
              member.status !== USER_STATUS_INVITED
                ? member.status === USER_STATUS_ACCEPTED
                : "bg-secondary"
            }`}
            style={statusStyle}
            disableOnClick
          >
            {member.status === USER_STATUS_INVITED
              ? USER_INVITED_TEXT
              : USER_STATUS_ACCEPTED
              ? USER_ACCEPTED_TEXT
              : ""}
          </p>
        </div>
        <div className="actions col-1 pl-0 text-center landing-group-dropdown">
          <MoreDropdown>
            {member.status === USER_STATUS_INVITED && (
              <DropdownItem
                onClick={() => {
                  resendInvite({ email: member.email, reInvite: true });
                }}
              >
                Resend Invite
              </DropdownItem>
            )}

            <DropdownItem onClick={toggle}>Change Role</DropdownItem>

            <DropdownItem onClick={memberToggle}>Remove</DropdownItem>
          </MoreDropdown>
        </div>
      </div>

      {/*  Chnage Role Model */}
      <Modal isOpen={isOpen} className={["edit-task-modal"].join(" ")}>
        <ModalHeader toggle={toggle}>
          <h4 className={["font-weight-bold"].join(" ")}>Change User Role</h4>
        </ModalHeader>
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            if (role) {
              handleUserRole(role, member);
              setTimeout(() => {
                toggle();
              }, 100);
            }
          }}
        >
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="row">
              <Avatar
                firstName={member.firstName}
                lastName={member.lastName}
                size="customSize"
                imageSize={"48"}
                url={member.avatarUrl}
              />
              <p className="h6-5 font-weight-bold ml-3">
                <Link
                  decoration={"text-dark text-decoration-none"}
                  url={`/user/edit/${member.id}?redirect=/users?type=Partner`}
                  text={getFullName(member.firstName, member.lastName)}
                />

                <div>
                  <span className="font-weight-normal text-inline-grayed">
                    ({member.email})
                  </span>
                </div>
              </p>
            </div>
            <div className="row">
              <p className="font-weight-bold ml-5 pl-3">Company User Role</p>
            </div>
            <div>
              <div className="w-50 ml-5">
                {!removeMember && (
                  <Select
                    name="userRoles"
                    options={userRoles}
                    placeholder={member.role || "Admin"}
                    isSearchable
                    handleChange={handleChange}
                    required={true}
                  />
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper text-center">
              <Button type="submit" label="">
                Change
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      {/*  Remove Team User  */}
      <Modal
        isOpen={removeModal}
        toggle={memberToggle}
        className={["edit-task-modal"].join(" ")}
      >
        <ModalHeader toggle={memberToggle}>
          <h4 className={["font-weight-bold"].join(" ")}>Remove User Member</h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className=" d-flex">
            <Avatar
              firstName={member.firstName}
              lastName={member.lastName}
              size="customSize"
              imageSize={"48"}
              url={member.avatarUrl}
            />
            <p className="h6-5 font-weight-bold ml-3">
              <Link
                decoration={"text-dark text-decoration-none"}
                url={`/user/edit/${member.id}?redirect=/users?type=Partner`}
                text={getFullName(member.firstName, member.lastName)}
              />

              <div>
                <span className="font-weight-normal text-inline-grayed">
                  ({member.email})
                </span>
              </div>
            </p>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <p className="font-weight-bold">Are you sure you want to remove ?</p>

          <div className="btn-wrapper mt-4 d-flex justify-content-center">
            <CancelButton onClick={memberToggle} className="mr-2" />
            <button
              className="btn btn-secondary h6-5-important m-1"
              onClick={() => {
                removeTeamMember(member);
                setTimeout(() => {
                  memberToggle();
                }, 100);
              }}
            >
              <span>Yes, Remove</span>
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TeamMember;
