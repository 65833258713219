import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Component
import AddButton from "../../../../components/base/AddButton";
import Form from "../../../../components/base/Form";
import Text from "../../../../components/base/Text";
import SaveButton from "../../../../components/base/SaveButton";
import List from "../../../../components/base/List";
import Spinner from "../../../../components/base/Spinner";
import Tags from "./inner-components/Tags";
import { apiClient } from "../../../../apiClient";
import { isBadRequest } from "../../../../common/http";
import { toast } from "react-toastify";
// Configs
import { endpoints } from "../../../../configs";

// Helper
import { toString } from "../../../../lib/helper";

// Tags
import {
  deleteTagType,
  addTagType,
  updateTagType
} from "../../../../actions/tagType";
import {
  TYPE_USER_DEFINED,
  TAG_TYPE_CATEGORY_MARKETPLACE,
  TYPE_SYSTEM
} from "../../../../tagType/Constants";

import ManageTagList from "../MarketplaceTab/lists/ManageTagList";

import DeleteModal from "../../../../components/base/DeleteModal";

const Profile = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [deleteTagTypeModal, setDeleteTagTypeModal] = useState(false);
  const [currentTagtype, setCurrentTagtype] = useState(false);
  const { activeTab, activeSubTab } = props;
  const [tagTypeList, setTagTypeList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [tierId, setTagTypeId] = useState("");
  const [tierName, setTagTypeName] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [targetTagTypeOrder, setTargetTagTypeOrderState] = useState("");
  const [sourceTagTypeOrder, setSourceTagTypeOrderState] = useState("");

  // Getting Partner tier List
  const getTagTypeList = () => {
    apiClient
      .get(
        `${endpoints().tagTypeAPI}?category=${TAG_TYPE_CATEGORY_MARKETPLACE}`
      )
      .then(response => {
        if (response.data && response.data.data) {
          setTagTypeList(response.data.data);
          setTagTypeId(response.data.data.id);
          setTagTypeName(response.data.data.name);
          setIsDefault(response.data.data.isDefault);
          setIsLoading(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };
  // Changing the Tag Type List Order
  const changeTagTypeOrder = () => {
    // Current Selected Tag Type List
    if (tagTypeList && tagTypeList.length > 0) {
      tagTypeList.splice(
        targetTagTypeOrder,
        0,
        tagTypeList.splice(sourceTagTypeOrder, 1)[0]
      );
      // Updated Selected Tag Type List
      const updatedTagTypeList = [];

      tagTypeList.forEach(tagType => {
        updatedTagTypeList.push({
          ...tagType,
          // Update Selected Tag Type Order
          sort: updatedTagTypeList.length
        });
      });

      updateTagTypeList(updatedTagTypeList);
      setTagTypeList(updatedTagTypeList);
      setSourceTagTypeOrderState("");
      setTargetTagTypeOrderState("");
    } else {
      // Reset Order Positions of Selected Tag Type
      setSourceTagTypeOrderState("");
      setTargetTagTypeOrderState("");
    }
  };

  //Updating the Tag Type Sort Order List
  const updateTagTypeList = updatedTagTypeList => {
    apiClient
      .put(`${endpoints().tagTypeAPI}/update/sortOrder`, updatedTagTypeList)
      .then(response => {
        if (response.data) {
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  const setSourceTagTypeOrder = tagType => {
    if (sourceTagTypeOrder !== tagType.sort) {
      setSourceTagTypeOrderState(tagType.sort);
    }
  };

  const setTargetTagTypeOrder = tagType => {
    if (targetTagTypeOrder !== tagType.sort) {
      setTargetTagTypeOrderState(tagType.sort);
    }
  };
  /**
   * Create tag
   *
   * @param data
   */
  const tagTypeCreate = data => {
    data.category = category.value || TAG_TYPE_CATEGORY_MARKETPLACE;
    props.actions.addTagType(
      data,
      { category: TAG_TYPE_CATEGORY_MARKETPLACE },
      setIsOpen,
      getTagTypeList
    );
  };

  /**
   * Update tag type
   *
   * @param data
   * @param id
   */
  const tagTypeUpdate = data => {
    props.actions.updateTagType(
      id,
      data,
      {
        category: TAG_TYPE_CATEGORY_MARKETPLACE
      },
      setIsOpen,
      getTagTypeList
    );
  };

  /**
   * Delete tag type
   *
   * @param id
   */
  const handleDelete = id => {
    props.actions.deleteTagType(
      id,
      {
        category: TAG_TYPE_CATEGORY_MARKETPLACE
      },
      getTagTypeList
    );
  };

  const _toggle = tagTypeId => {
    setId(tagTypeId || 0);
    setIsOpen(!isOpen);
  };

  // Add Tag Button
  const headerButton = () => (
    <div className="btn-wrapper">
      <AddButton
        label="Add New"
        className="pull-right btn btn-secondary"
        onClick={() => {
          setName("");
          setCategory("");
          setType("");
          _toggle();
        }}
      />
      {tagTypeModal()}
    </div>
  );

  // Render the tag type modal
  const tagTypeModal = () => (
    <Modal
      isOpen={isOpen}
      toggle={_toggle}
      className="landing-create-popup w-100"
      backdrop="static"
    >
      <ModalHeader
        toggle={_toggle}
        cssModule={{ "modal-title": "w-100 text-center" }}
      >
        <p>{id ? "Edit Tag Type" : "Create Tag Type"}</p>
      </ModalHeader>
      <Form
        initialValues={{
          name: name || ""
        }}
        onSubmit={values => {
          values.name = toString(values.name).trim();
          values.type = type ? toString(type) : toString(TYPE_USER_DEFINED);
          values.category = TAG_TYPE_CATEGORY_MARKETPLACE;
          id ? tagTypeUpdate(values) : tagTypeCreate(values);
        }}
      >
        <ModalBody className="ml-3 mr-3 mt-2 mb-3">
          <div>
            <Text
              name="name"
              label="Tag Type"
              placeholder="Enter tag type"
              required={true}
              error=""
              disabled={type === TYPE_SYSTEM ? true : false}
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="container-fluid">
            <div className="col-sm-12 text-center">
              <SaveButton type="submit" label="">
                {id ? "Save" : "Create Tag Type"}
              </SaveButton>
            </div>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
  const selectedTagType = props.activeSection;

  useEffect(() => {
    getTagTypeList();
  }, []);

  return (
    <>
      <DeleteModal
        isOpen={deleteTagTypeModal}
        toggle={() => {
          setDeleteTagTypeModal(prevState => !prevState);
        }}
        title="Delete Partner Profile"
        label={currentTagtype.name}
        deleteFunction={() => {
          handleDelete(currentTagtype.id);
        }}
      />
      {!selectedTagType ? (
        <div className="mt-4 mb-5">
          <p className="mb-4">
            These are the attributes that are setup to distinguish which
            partners are a good fit for certain leads or projects and are
            available as filters when building out your partner directory
            landing pages. You can add or remove from this list to make it match
            the criteria that make sense for your business.
          </p>
          <div className="pb-4 pt-3 mb-4">{headerButton()}</div>
          <div className="card">
            <div className="d-flex justify-content-between no-wrap relative bg-dark text-white">
              <h6 className="font-weight-bold pt-4 px-4 pb-3">Profile</h6>
              <h6 className="font-weight-bold pt-4 px-4 pb-3">Action</h6>
            </div>
            <div className="task-list">
              {isLoading && <Spinner />}
              {tagTypeList &&
                tagTypeList.length > 0 &&
                tagTypeList
                  .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
                  .map((tagTypeItem, i) => (
                    <div>
                      <div
                        id={tagTypeItem.sort}
                        key={tagTypeItem.id}
                        onRowClick={row => {
                          setSourceTagTypeOrder(row.name, row.id);
                          return _toggle(row.id);
                        }}
                        draggable="true"
                        onDrag={e => {
                          setSourceTagTypeOrder(tagTypeItem);
                        }}
                        onDragOver={e => {
                          e.preventDefault();

                          setTargetTagTypeOrder(tagTypeItem);
                        }}
                        onDrop={e => {
                          changeTagTypeOrder();
                        }}
                      >
                        <Tags
                          task={tagTypeItem}
                          openEditModal={() => {
                            setId(tagTypeItem.id);
                            setName(tagTypeItem.name);
                            return _toggle(tagTypeItem.id);
                          }}
                          openDeleteModal={() => {
                            setCurrentTagtype(tagTypeItem);
                            setDeleteTagTypeModal(tagTypeItem);
                          }}
                        />
                      </div>
                    </div>
                  ))}
            </div>
          </div>
          <List
            className="mx-5 pl-4"
            data={[
              {
                title: "Partner Type",
                link: "/admin-settings/Partner/Types",
                description: "(System)"
              },
              {
                title: "Partner Tier",
                link: "/admin-settings/Partner/Tiers",
                description: "(System)"
              }
            ]}
            hidePadding
          />
        </div>
      ) : (
        <ManageTagList
          selectedId={id ? id : ""}
          selectedTagType={props.activeSection}
          activeTab={activeTab}
          activeSubTab={activeSubTab}
          activeSubSection={props.activeSubSection}
          history={props.history}
          match={props.match}
          settings={props.settings}
        />
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { deleteTagType, addTagType, updateTagType },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(Profile);
