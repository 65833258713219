import React from "react";
import AccountCard from "../../../components/base/AccountCard";

const AccountBanner = props => {
  const { accountDetails } = props;

  return (
    <div>
      <div
        className="card border-0 text-white mb-4"
        style={{ backgroundColor: "#2F2F3E" }}
      >
        <div className="account-banner ">
          <div className="payment-option">
            <div className="font-weight-bold mt-3">
              <AccountCard
                bgColor="#fff"
                headerTextColor="#000000"
                companyName={accountDetails.companyName}
                website={accountDetails.website}
              />
            </div>
          </div>
          <div className=" payment-option">
            <div className=" mt-3">
              <div className="font-weight-bold">Account Type</div>
              <div className="text-warning font-weight-bold">
                <h4> {accountDetails.type}</h4>
              </div>
            </div>
          </div>
          <div className="payment-option ">
            <div className=" mt-3">
              <div className="font-weight-bold">Created At</div>
              <div>
                <h4>{accountDetails.createdAt}</h4>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountBanner;
