import React from "react";

class LeftSideSection extends React.Component {
  render() {
    let { partnerSignupHeroImage } = this.props;

    partnerSignupHeroImage = partnerSignupHeroImage
      ? partnerSignupHeroImage
      : "";

    return (
      <div className="col desktop-only">
        <div
          className="find-an-expert-left-side-section-background-image d-flex flex-column align-items-center justify-content-center"
          style={{
            height: "100%",
            backgroundImage: "url(" + partnerSignupHeroImage + ")",
            backgroundPosition: "center center",
            backgroundRepeat: "no-repeat"
          }}
        ></div>
      </div>
    );
  }
}

export default LeftSideSection;
