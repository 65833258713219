import {
  REQUEST_DELETE_HUBSPOT_FIELD_MAPPING,
  RECEIVE_DELETE_HUBSPOT_FIELD_MAPPING,
  HUBSPOT_FIELD_MAPPING_DELETE_ERROR,
  REQUEST_CREATE_HUBSPOT_FIELD_MAPPING,
  RECEIVE_CREATE_HUBSPOT_FIELD_MAPPING,
  HUBSPOT_FIELD_MAPPING_CREATE_ERROR,
  REQUEST_UPDATE_HUBSPOT_FIELD_MAPPING,
  RECEIVE_UPDATE_HUBSPOT_FIELD_MAPPING,
  HUBSPOT_FIELD_MAPPING_UPDATE_ERROR
} from "../actionType/Constants";

import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import toast from "../components/base/Toast";
import { fetchList } from "./table";
import { isBadRequest } from "../common/http";

/**
 * Request for deleting hubpot lead field mapping
 */
export function requestDeleteHubspotFieldMapping() {
  return {
    type: REQUEST_DELETE_HUBSPOT_FIELD_MAPPING
  };
}

/**
 * Receive for deleting hubspot lead field mapping
 */
export function receiveDeleteHubSpotFieldMapping() {
  return {
    type: RECEIVE_DELETE_HUBSPOT_FIELD_MAPPING
  };
}

/**
 * Receive for error deleting hubspot lead field mapping
 */
export function hubspotFieldMappingDeleteError(error) {
  return {
    type: HUBSPOT_FIELD_MAPPING_DELETE_ERROR,
    error
  };
}

/**
 * Delete hubspot lead field mapping
 *
 * @param id
 * @returns {function(*): *}
 */
export function deleteHubspotFieldMapping(id, params) {
  return dispatch => {
    dispatch(requestDeleteHubspotFieldMapping());

    apiClient
      .delete(`${endpoints().hubSpotFieldMapRoute}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "hubspotFieldMapping",
            `${endpoints().hubSpotFieldMapRoute}`,
            1,
            10,
            { ...params, pagination: true }
          )
        );
      })
      .catch(error => {
        dispatch(hubspotFieldMappingDeleteError(error));
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}

/**
 * Request for creating hubspot lead field mapping
 */
export function requestCreatesHubSpotFieldMapping() {
  return {
    type: REQUEST_CREATE_HUBSPOT_FIELD_MAPPING
  };
}

/**
 * Receive for receive salesforce lead field mapping
 */
export function receiveCreateHubspotFieldMapping() {
  return {
    type: RECEIVE_CREATE_HUBSPOT_FIELD_MAPPING
  };
}

/**
 * Receive for error creating salesforce lead field mapping
 */
export function hubspotFieldMappingCreateError(error) {
  return {
    type: HUBSPOT_FIELD_MAPPING_CREATE_ERROR,
    error
  };
}

/**
 * Create salesforce lead field mapping
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function addHubspotFieldMapping(data, params) {
  return dispatch => {
    dispatch(requestCreatesHubSpotFieldMapping());

    return apiClient
      .post(`${endpoints().hubSpotFieldMapRoute}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "hubspotFieldMapping",
            `${endpoints().hubSpotFieldMapRoute}`,
            1,
            10,
            { ...params, pagination: true }
          )
        );
        dispatch(receiveCreateHubspotFieldMapping());
      })
      .catch(error => {
        dispatch(hubspotFieldMappingCreateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Request for updating hubspot lead field mapping
 */
export function requestUpdateHubspotFieldMapping() {
  return {
    type: REQUEST_UPDATE_HUBSPOT_FIELD_MAPPING
  };
}

/**
 * Receive for updating hubspot lead field mapping
 */
export function receiveUpdateHubspotFieldMapping() {
  return {
    type: RECEIVE_UPDATE_HUBSPOT_FIELD_MAPPING
  };
}

/**
 * Receive for error updating hubspot lead field mapping
 */
export function HubspotFieldMappingUpdateError(error) {
  return {
    type: HUBSPOT_FIELD_MAPPING_UPDATE_ERROR,
    error
  };
}

/**
 * Update hubspot lead field mapping details
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateHubspotFieldMapping(id, data, params) {
  return dispatch => {
    dispatch(requestUpdateHubspotFieldMapping());
    apiClient
      .put(`${endpoints().hubSpotFieldMapRoute}/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "hubspotFieldMapping",
            `${endpoints().hubSpotFieldMapRoute}`,
            1,
            10,
            params
          )
        );
      })
      .catch(error => {
        dispatch(HubspotFieldMappingUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}
