// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  isCustomer,
  isExpert,
  isCustomerAdmin,
  isCustomerMember,
  isPartner,
  getKeyValueByObject
} from "./lib/helper";

// Constants
import {
  PARTNER_TYPE_SETTING_ENABLE_ACCOUNT_MAPPING,
  PARTNER_TYPE_SETTING_ENABLE_CO_MARKETING_CAMPAIGNS,
  PARTNER_TYPE_SETTING_ENABLE_LEAD_DISTRIBUTION,
  PARTNER_TYPE_SETTING_ENABLE_MDF,
  PARTNER_TYPE_SETTING_ENABLE_PARTNER_RESOURCES,
  PARTNER_TYPE_SETTING_ENABLE_REFERRALS_DEAL_REGISTRATION
} from "./partnerTypeSetting/Constants";
import {
  SETTINGS_ENABLE_PARTNERS,
  SETTINGS_ENABLE_FILES,
  SETTINGS_ENABLE_EXPERTS,
  SETTINGS_ENABLE_DEALS,
  SETTINGS_ENABLE_PROJECTS,
  SETTINGS_ENABLE_RESOURCES,
  SETTINGS_ENABLE_PAYMENTS,
  SETTINGS_ENABLE_ACCOUNTING,
  SETTINGS_ENABLE_USERS,
  SETTINGS_ENABLE_FEEDBACK,
  SETTINGS_ENABLE_REPORTS,
  SETTINGS_ENABLE_ACCOUNT_MAPPING,
  SETTINGS_ENABLE_ACCOUNTS,
  SETTINGS_ENABLE_REFERRALS,
  SETTINGS_ENABLE_CONTACTS,
  SETTINGS_ENABLE_CUSTOMERS,
  SETTINGS_ENABLE_LEADS,
  SETTINGS_ENABLE_PLAYBOOK,
  SETTINGS_ENABLE_APP,
  SETTINGS_ENABLE_PARTNER_TRAINING,
  SETTINGS_ENABLE_COMMUNICATIONS,
  SETTINGS_ENABLE_CAMPAIGNS,
  SETTINGS_ENABLE_MDF_REQUEST,
  SETTINGS_ENABLE_COSELL,
  SETTINGS_ENABLE_MARKETPLACE
} from "./setting/Constants";

import reportIcon from "./assets/img/icons/nav-icon-reports.svg";
import coSellIcon from "./assets/img/icons/nav-icon-reports.svg";

import projectsIcon from "./assets/img/icons/nav-icon-projects.svg";
// import playbookIcon from "./assets/img/icons/nav-icon-playbooks.svg";
import clockIcon from "./assets/img/icons/nav-icon-clock.svg";
import teamIcon from "./assets/img/icons/nav-icon-team.svg";
import customerIcon from "./assets/img/icons/nav-icon-users.svg";
import billingIcon from "./assets/img/icons/nav-icon-billing.svg";
import feedbackIcon from "./assets/img/icons/nav-icon-feedback.svg";
import sourcingIcon from "./assets/img/icons/nav-icon-sourcing.svg";
import TrainingIcon from "./assets/img/icons/nav-icon-course.svg";
import homeIcon from "./assets/img/icons/icon-home.svg";
import leadsIcon from "./assets/img/icons/icon-send.svg";
import dealsIcon from "./assets/img/icons/icon-deals.svg";
import marketplaceIcon from "./assets/img/icons/icon-marketplace.svg";
import partnerIcon from "./assets/img/icons/icon-partner.svg";
import ReferralIcon from "./assets/img/icons/icon-referral.svg";
import accountMappingIcon from "./assets/img/icons/nav-icon-account-mapping.svg";
import contactsIcon from "./assets/img/icons/nav-icon-contacts.svg";
import AccountsIcon from "./assets/img/icons/nav-icon-accounts.svg";
import partnerCommunicationIcon from "./assets/img/icons/nav-icon-partnercommunication.svg";
import mdfIcon from "./assets/img/icons/icon-mdf.svg";
import campaignIcon from "./assets/img/icons/nav-icon-campaigns.svg";
import Terminology from "./lib/Terminology";
import SystemName from "./Constants/SystemName";

import { IsPartnerTypeSettingEnabled } from "./lib/PartnerSetting";

/**
 * Get navigation list
 */
export function getNavList(settings, partnerSettings, user) {
  const SideMenu = [
    "Dashboard",
    "division",
    "My Company",
    "division",
    "Lead Distribution",
    Terminology.get(SystemName.REFERRALS),
    "Deals",
    "division",
    "Partners",
    "MDF",
    Terminology.get(SystemName.COMARKETING_CAMPAIGNS),
    "Projects",
    "Potential Projects",
    "division",
    "Partner Resources",
    "Files",
    "Resources",
    "Partner Training",
    "Training",
    "Partner Communications",
    "Communications",
    "division",
    "Account Mapping",
    "Co-Sell",
    "Accounting",
    "Payments",
    "Billings",
    "division",
    "Reports",
    "Feedback",
    "Availability",
    "Users",
    "division",
    Terminology.get(SystemName.MARKETPLACE),
    "Accounts",
    "Contacts",
    "Customers",
    "Experts"
  ];

  let arrayList = [];
  const currentUrl = window.location.pathname;
  const section = window.location.search;

  const enablePartners = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS)
    : "";

  const enableFiles = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_FILES)
    : "";

  const enableExperts = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS)
    : "";

  const enableDeals = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_DEALS)
    : "";

  const enableProjects = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_PROJECTS)
    : "";

  const enableResources = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_RESOURCES)
    : "";

  const enableAccounts = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_ACCOUNTS)
    : "";
  const enableAccountMapping = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_ACCOUNT_MAPPING)
    : "";

  const enableContacts = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_CONTACTS)
    : "";
  const enableCustomers = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_CUSTOMERS)
    : "";

  const enableCampaigns = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_CAMPAIGNS)
    : "";

  const enableReferrals = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_REFERRALS)
    : "";

  const enableLeads = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_LEADS)
    : "";

  const enablePlaybook = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_PLAYBOOK)
    : "";

  const enableApp = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_APP)
    : "";

  const enablePartnerTraining = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNER_TRAINING)
    : "";

  const enableMarketPlace = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_MARKETPLACE)
    : "";

  const enableCommunications = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_COMMUNICATIONS)
    : "";
  const enablePayments = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_PAYMENTS)
    : "";
  const enableAccounting = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_ACCOUNTING)
    : "";
  const enableReports = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_REPORTS)
    : "";

  const enableCoSell = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_COSELL)
    : "";
  const enableUsers = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_USERS)
    : "";
  const enableFeedback = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_FEEDBACK)
    : "";

  const enableMdfRequest = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_MDF_REQUEST)
    : "";

  const partnerTypeSettingEnableAccountMapping = partnerSettings
    ? getKeyValueByObject(
        partnerSettings,
        PARTNER_TYPE_SETTING_ENABLE_ACCOUNT_MAPPING
      )
    : "";

  const partnerTypeSettingEnableCampaigns = partnerSettings
    ? getKeyValueByObject(
        partnerSettings,
        PARTNER_TYPE_SETTING_ENABLE_CO_MARKETING_CAMPAIGNS
      )
    : "";

  const partnerTypeSettingEnableLeadDistribution = partnerSettings
    ? getKeyValueByObject(
        partnerSettings,
        PARTNER_TYPE_SETTING_ENABLE_LEAD_DISTRIBUTION
      )
    : "";

  const partnerTypeSettingEnableMdf = partnerSettings
    ? getKeyValueByObject(partnerSettings, PARTNER_TYPE_SETTING_ENABLE_MDF)
    : "";

  const partnerTypeSettingEnableReferrals = partnerSettings
    ? IsPartnerTypeSettingEnabled(
        partnerSettings,
        PARTNER_TYPE_SETTING_ENABLE_REFERRALS_DEAL_REGISTRATION
      )
    : "";

  if (
    isSuperAdmin(user && user.roleId) ||
    isCompanyAdmin(user && user.roleId) ||
    isCompanyManager(user && user.roleId) ||
    isCustomer(user && user.roleId) ||
    isExpert(user && user.roleId) ||
    isPartner(user && user.roleId) ||
    isCustomerAdmin(user && user.roleId) ||
    isCustomerMember(user && user.roleId)
  ) {
    arrayList = arrayList.concat({
      icon: homeIcon,
      name: "Dashboard",
      url: "/dashboard"
    });
  }
  if (isPartner(user && user.roleId) && user.isPartnerAdmin) {
    arrayList = arrayList.concat({
      icon: partnerIcon,
      name: "My Company",
      url: `/edit/partner/public-profile?id=${user && user.partnerId}`
    });
  }

  if (
    ((isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
      enableLeads === "true") ||
    (isPartner(user && user.roleId) &&
      enableLeads === "true" &&
      partnerTypeSettingEnableLeadDistribution === "true")
  ) {
    arrayList = arrayList.concat({
      icon: leadsIcon,
      name: "Lead Distribution",
      url: "/leads",
      editPageurl: "/leads"
    });
  }

  if (
    (isPartner(user && user.roleId) &&
      partnerTypeSettingEnableReferrals &&
      enableReferrals === "true") ||
    (enableReferrals === "true" &&
      (isSuperAdmin(user && user.roleId) ||
        isCompanyAdmin(user && user.roleId) ||
        isCompanyManager(user && user.roleId)))
  ) {
    arrayList = arrayList.concat({
      icon: ReferralIcon,
      name: Terminology.get(SystemName.REFERRALS),
      url: "/referrals",
      editPageurl: "/referral",
      icon: ReferralIcon
    });
  }

  if (
    enableDeals === "true" &&
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId) ||
      isPartner(user && user.roleId))
  ) {
    arrayList = arrayList.concat({
      icon: dealsIcon,
      name: "Deals",
      url: "/deals",
      addPageurl: "/deal/add",
      editPageurl: "/deal/details"
    });
  }

  if (
    (enableMdfRequest === "true" &&
      (isSuperAdmin(user && user.roleId) ||
        isCompanyAdmin(user && user.roleId) ||
        isCompanyManager(user && user.roleId))) ||
    (isPartner(user && user.roleId) &&
      enableMdfRequest === "true" &&
      partnerTypeSettingEnableMdf === "true")
  ) {
    arrayList = arrayList.concat({
      icon: mdfIcon,
      name: "MDF",
      url: "/mdf-requests",

      addPageurl: "/mdf-request/new",
      editPageurl: "/mdf-request/details"
    });
  }

  if (
    ((isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
      enableCampaigns === "true") ||
    (isPartner(user && user.roleId) &&
      enableCampaigns === "true" &&
      partnerTypeSettingEnableCampaigns === "true")
  ) {
    arrayList = arrayList.concat({
      icon: campaignIcon,
      name: Terminology.get(SystemName.COMARKETING_CAMPAIGNS),
      url: currentUrl === "/campaigns" ? `/campaigns${section}` : "/campaigns",
      addPageurl: "/campaign/new",
      editPageurl: "/campaign"
    });
  }
  if (
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
    enableCoSell === "true"
  ) {
    arrayList = arrayList.concat({
      icon: reportIcon,
      name: "Co-Sell",
      url: "/co-sell?page=1&sort=week1&sortDir=desc",
      addPageurl: "/co-sell/new",
      editPageurl: "/co-sell/detail"
    });
  }
  if (
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId) ||
      isCustomer(user && user.roleId) ||
      isExpert(user && user.roleId) ||
      isPartner(user && user.roleId) ||
      isCustomerAdmin(user && user.roleId) ||
      isCustomerMember(user && user.roleId)) &&
    enableProjects === "true"
  ) {
    arrayList = arrayList.concat({
      icon: projectsIcon,
      name: "Projects",
      url: currentUrl === "/projects" ? `/projects${section}` : "/projects",
      addPageurl: "/project/new",
      editPageurl: "/project"
    });
  }

  // if (isSuperAdmin()) {
  //   arrayList = arrayList.concat({
  //     name: "Sourcing",
  //     url: currentUrl === "/sourcing" ? `/sourcing${section}` : "/sourcing",
  //     editPageurl: "/sourcing",
  //     icon: sourcingIcon
  //   });
  // }

  if (
    ((isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
      enableMarketPlace === "true") ||
    ((isPartner(user && user.roleId) || isCustomer(user && user.roleId)) &&
      enableMarketPlace === "true")
  ) {
    arrayList = arrayList.concat({
      icon: marketplaceIcon,
      name: Terminology.get(SystemName.MARKETPLACE),
      url:
        currentUrl === "/marketplace-sourcing"
          ? `/marketplace-sourcing${section}`
          : "/marketplace-sourcing",
      editPageurl: "/marketplace-sourcing",
      addPageurl: "/playbook/new"
    });
  }

  if (
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
    enablePartners === "true"
  ) {
    arrayList = arrayList.concat({
      icon: partnerIcon,
      name: "Partners",
      url: "/partners",
      editPageurl: "/edit/partner/public-profile"
    });
  }

  if (isSuperAdmin(user && user.roleId) && enableAccounts === "true") {
    arrayList = arrayList.concat({
      icon: AccountsIcon,
      name: "Accounts",
      url: "/accounts",
      editPageurl: "/account/edit"
    });
  }

  if (isSuperAdmin(user && user.roleId) && enableContacts === "true") {
    arrayList = arrayList.concat({
      icon: contactsIcon,
      name: "Contacts",
      url: "/contacts",
      editPageurl: "/contact/edit"
    });
  }

  if (
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
    enableCustomers === "true"
  ) {
    arrayList = arrayList.concat({
      icon: customerIcon,
      name: "Customers",
      url: "/customers",
      editPageurl: "/customer"
    });
  }

  if (
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
    enableResources === "true"
  ) {
    arrayList = arrayList.concat({
      icon: sourcingIcon,
      name: "Partner Resources",
      url: "/resources",
      addPageurl: "/resource/add",
      editPageurl: "/resource/edit",
      icon: sourcingIcon
    });
  }
  if (enableFiles == "true") {
    if (isPartner(user && user.roleId)) {
      arrayList = arrayList.concat({
        icon: sourcingIcon,
        name: "Files",
        url: "/files",
        addPageurl: "/files/add",
        editPageurl: "/files/edit",
        icon: sourcingIcon
      });
    }
  }
  if (isPartner(user && user.roleId) && enableResources === "true") {
    arrayList = arrayList.concat({
      icon: sourcingIcon,
      name: "Resources",
      url: "/resources",
      addPageurl: "/resource/add",
      editPageurl: "/resource/edit",
      icon: sourcingIcon
    });
  }

  if (
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
    enablePartnerTraining === "true"
  ) {
    arrayList = arrayList.concat({
      icon: TrainingIcon,
      name: "Partner Training",
      url: "/course",
      addPageurl: "/course/add",
      editPageurl: "/course/edit",
      icon: TrainingIcon
    });
  }

  if (isPartner(user && user.roleId) && enablePartnerTraining === "true") {
    arrayList = arrayList.concat({
      icon: TrainingIcon,
      name: "Training",
      url: "/course",
      detailsPageurl: "/course",
      icon: TrainingIcon
    });
  }
  if (
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
    enableCommunications === "true"
  ) {
    arrayList = arrayList.concat({
      icon: partnerCommunicationIcon,
      name: "Partner Communications",
      url: "/partner-communications",
      detailsPageurl: "/communication/"
    });
  }
  if (isPartner(user && user.roleId) && enableCommunications === "true") {
    arrayList = arrayList.concat({
      icon: partnerCommunicationIcon,
      name: "Communications",
      url: "/partner-communications",
      detailsPageurl: "/communication/"
    });
  }
  if (
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
    enableAccounting === "true"
  ) {
    arrayList = arrayList.concat({
      icon: billingIcon,
      name: "Accounting",
      url: "/accounting",
      editPageurl: "/accounting/billings/"
    });
  }

  if (
    enableFeedback === "true" &&
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId) ||
      isCustomer(user && user.roleId) ||
      isExpert(user && user.roleId) ||
      isPartner(user && user.roleId))
  ) {
    arrayList = arrayList.concat({
      icon: feedbackIcon,
      name: "Feedback",
      url: "/feedback",
      editPageurl: "/feedback"
    });
  }

  if (
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
    enableUsers === "true"
  ) {
    arrayList = arrayList.concat({
      icon: teamIcon,
      name: "Users",
      url: "/users",
      addPageurl: "/user/new",
      editPageurl: "/user"
    });
  }

  if (
    (isExpert(user && user.roleId) || isPartner(user && user.roleId)) &&
    enableProjects === "true"
  ) {
    arrayList = arrayList.concat({
      icon: projectsIcon,
      name: "Potential Projects",
      url: "/potential-projects"
    });
  }

  // if (isSuperAdmin() || isCustomer() || isPartner()) {
  //   arrayList = arrayList.concat({
  //     name: "Playbooks",
  //     url: "/playbooks",
  //     addPageurl: "/playbook/new",
  //     editPageurl: "/playbook/edit",
  //     icon: playbookIcon
  //   });
  // }

  // if (isSuperAdmin()) {
  //   arrayList = arrayList.concat({
  //     name: "Playbook Inquiry",
  //     url: "/playbook-inquiry",
  //     editPageurl: "/playbook-inquiry",
  //     icon: playbookIcon
  //   });
  // }

  // if (isSuperAdmin() && enableExperts === "true") {
  //   arrayList = arrayList.concat({
  //     name: "Expert Requests",
  //     url: "/expert-requests",
  //     editPageurl: "/expert-requests",
  //     icon: sourcingIcon
  //   });
  // }

  if (isCustomer(user && user.roleId)) {
    arrayList = arrayList.concat({
      icon: billingIcon,
      name: "Billings",
      url: "/billings",
      editPageurl: "/billings"
    });
  }

  if (
    (isExpert(user && user.roleId) || isPartner(user && user.roleId)) &&
    enablePayments === "true"
  ) {
    arrayList = arrayList.concat({
      icon: billingIcon,
      name: "Payments",
      url: "/payment"
    });
  }

  if (isExpert(user && user.roleId)) {
    arrayList = arrayList.concat({
      icon: clockIcon,
      name: "Availability",
      url: "/availability"
    });
  }

  if (
    ((isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
      enableAccountMapping === "true") ||
    (isPartner(user && user.roleId) &&
      enableAccountMapping === "true" &&
      partnerTypeSettingEnableAccountMapping === "true")
  ) {
    arrayList = arrayList.concat({
      icon: accountMappingIcon,
      name: "Account Mapping",
      url: "/account-mapping"
    });
  }

  if (
    (isSuperAdmin(user && user.roleId) ||
      isCompanyAdmin(user && user.roleId) ||
      isCompanyManager(user && user.roleId)) &&
    enableReports === "true"
  ) {
    arrayList = arrayList.concat({
      icon: reportIcon,
      name: "Reports",
      url: "/reports",
      editPageurl: "/reports"
    });
  }

  // if ((isSuperAdmin() && enablePartners === "true") || isPartner()) {
  //   arrayList = arrayList.concat({
  //     name: "Contact Submissions",
  //     url: "/partner-requests",
  //     editPageurl: "/partner-requests",
  //     icon: sourcingIcon
  //   });
  // }

  // if (isSuperAdmin()) {
  //   arrayList = arrayList.concat({
  //     name: "Contact Forms",
  //     url: "/contact-forms",
  //     editPageurl: "/contact-form",
  //     icon: customerIcon
  //   });
  // }
  const sortedList = arrayList.sort((a, b) => {
    return SideMenu.indexOf(a) - SideMenu.indexOf(b);
  });
  arrayList.sort((a, b) => {
    const indexA = SideMenu.indexOf(a.name);
    const indexB = SideMenu.indexOf(b.name);

    if (indexA === -1 && indexB === -1) {
      return 0; // If both names are not in SideMenu, maintain the order
    } else if (indexA === -1) {
      return 1; // If only b is in SideMenu, put b after a
    } else if (indexB === -1) {
      return -1; // If only a is in SideMenu, put a before b
    } else {
      return indexA - indexB; // Compare the indexes in SideMenu
    }
  });

  const sortedArrayList = [];
  let groupEmpty = true;

  SideMenu.forEach(menuItem => {
    if (menuItem === "division") {
      // If the menu item is empty, add a placeholder object for the grey line
      if (!groupEmpty) {
        sortedArrayList.push({ division: true });
      }
      groupEmpty = true;
    } else {
      // Find the corresponding item in the arrayList
      const item = arrayList.find(obj => obj.name === menuItem);
      if (item) {
        sortedArrayList.push(item);
        groupEmpty = false;
      }
    }
  });
  const uniqueSideMenu = [...new Set(sortedArrayList)];

  return uniqueSideMenu;
}
