import React from "react";
import { ToastContainer } from "react-toastify";

class ToastContainerComponent extends React.Component {
  render() {
    const {
      position,
      autoClose,
      hideProgressBar,
      newestOnTop,
      closeOnClick,
      limit,
      containerId
    } = this.props;

    // Close button
    const CloseButton = ({ closeToast }) => (
      <button
        type="button"
        className="close"
        aria-label="Close"
        onClick={closeToast}
        style={{ color: "#ffffff" }}
      >
        <span aria-hidden="true">&times;</span>
      </button>
    );

    return (
      <ToastContainer
        containerId={containerId || "toast-container"}
        position={position || "relative"}
        autoClose={autoClose || 1000}
        hideProgressBar={hideProgressBar || true}
        newestOnTop={newestOnTop || false}
        closeButton={CloseButton}
        closeOnClick={closeOnClick || true}
        limit={limit || 1}
        enableMultiContainer={containerId ? true : false}
      />
    );
  }
}

export default ToastContainerComponent;
