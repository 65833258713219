import React from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  setExpertBadge,
  isCompanyManager
} from "../../../lib/helper";
import { DEAL_PARTNER_STATUS_ACCEPTED } from "../../../deal/Constants";

// Components
import { Badge } from "reactstrap";
import SidebarItem from "./SidebarItem";
import Avatar from "../../../components/base/Avatar";
import AvatarCard from "../../../components/base/AvatarCard";
import { useSelector } from "react-redux";

const PartnerSection = ({
  leadPartnerList,
  currentUserId,
  onSourcePartner,
  isPartnerLoading,
  leadPartnerUser
}) => {
  const currentUser = useSelector(state => state.user);

  return (
    <>
      <SidebarItem>
        <p className="font-weight-bold">Partner</p>
        {leadPartnerList && leadPartnerList.length ? (
          leadPartnerList.map(partner => (
            <>
              {partner.userId === currentUserId &&
              partner.status &&
              partner.status !== DEAL_PARTNER_STATUS_ACCEPTED ? (
                leadPartnerList.length === 1 ? (
                  <span className="radio-placeholder checkbox-placeholder">
                    <Link
                      target="_blank"
                      to={`/partner-profile/${
                        leadPartnerUser && leadPartnerUser.partner.profile_url
                          ? leadPartnerUser.partner.profile_url
                          : partner.profile_url
                      }`}
                    >
                      <AvatarCard
                        firstName={partner.companyName}
                        url={partner.avatar}
                        size="xs"
                      />
                    </Link>
                  </span>
                ) : (
                  ""
                )
              ) : (
                <div className="field-wrapper">
                  <div className="user-wrapper">
                    <div className="field-icon">
                      <Link
                        target="_blank"
                        to={`/partner-profile/${
                          leadPartnerUser && leadPartnerUser.partner.profile_url
                            ? leadPartnerUser.partner.profile_url
                            : partner.profile_url
                        }`}
                      >
                        <LazyLoad>
                          <Avatar
                            firstName={partner.companyName}
                            size="xs"
                            fontSize={12}
                            url={partner.avatarUrl}
                          />
                        </LazyLoad>
                      </Link>
                    </div>

                    <div className="expert-info ml-2 d-flex flex-column align-items-start">
                      <p className="font-weight-bold">
                        <Link
                          target="_blank"
                          to={`/partner-profile/${
                            leadPartnerUser &&
                            leadPartnerUser.partner.profile_url
                              ? leadPartnerUser.partner.profile_url
                              : partner.profile_url
                          }`}
                        >
                          {partner.userId === currentUserId
                            ? "You"
                            : partner.companyName}
                        </Link>
                      </p>

                      {partner.status &&
                        partner.status !== DEAL_PARTNER_STATUS_ACCEPTED && (
                          <div
                            className={[
                              "status-tooltips d-flex",
                              `${partner.status.toLowerCase() ===
                                "no response" && "align-items-start"}`
                            ].join(" ")}
                          >
                            <Badge
                              id={`status-tooltip-${partner.partnerId}`}
                              color={setExpertBadge(partner.status)}
                              className="text-white h7 font-weight-bold text-uppercase"
                            >
                              {leadPartnerUser.status}
                            </Badge>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              )}
            </>
          ))
        ) : isPartnerLoading ? (
          "Loading..."
        ) : (
          <span className="text-grayed">No partner assigned yet</span>
        )}
        {(isSuperAdmin(currentUser && currentUser.roleId) ||
          isCompanyAdmin(currentUser && currentUser.roleId) ||
          isCompanyManager(currentUser && currentUser.roleId)) && (
          <button
            className="btn btn-primary w-100 mt-2"
            type="button"
            onClick={onSourcePartner}
          >
            {!leadPartnerList.length ? "Assign" : "Reassign"} Partner
          </button>
        )}
      </SidebarItem>
    </>
  );
};

export default PartnerSection;
