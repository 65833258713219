import React from "react";
import toast from "../../components/base/Toast";

// Page components
import ResourceFormFields from "./ResourceFormFields";

// Components
import PageTitle from "../../components/base/PageTitle";
import Form from "../../components/FormWithErrorHandling";
import CancelButton from "../../components/base/CancelButton";

// Configs
import { endpoints } from "../../configs";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { resetPlaybookList } from "../../actions/playbook";

// API call
import { apiClient } from "../../apiClient";

// Constants
import {
  RESOURCE_DRAFT,
  RESOURCE_PUBLISHED,
  RESOURCE_WEB_PAGE,
  RESOURCE_VIDEO,
  PARTNER_PERMISSION_FILTERED_PARTNERS,
  PARTNER_PERMISSION_INDIVIDUAL_PARTNER,
  PARTNER_PERMISSION_ALL_PARTNER
} from "../../resource/Constants";
import { TAB_BASICS, TAB_PERMISSIONS } from "../../resource/Constants";
import MyContext from "../../context/MyContext";
import { TAG_TYPE_RESOURCE_CATEGORY } from "../../tagType/Constants";
import { isBadRequest } from "../../common/http";

class AddResource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerColor: "",
      bannerIcon: "EnvelopeIcon",
      files: [],
      fileCount: "",
      bannerImageUrl: "",
      backButton: "Cancel",
      publishResource: "Next",
      status: RESOURCE_DRAFT,
      buttonType: "submit",
      currentTab: TAB_BASICS,
      nextTab: "",
      playbookIconValidate: true,
      playbookColorValidate: true,
      skills: [],
      tools: [],
      isExpertLoading: false,
      isOpen: false,
      expertList: [],
      expertIds: [],
      resourceFile: "",
      resourceFiles: [],
      deliverablesValue: "",
      defaultDeliverablesValue: "",
      spotlightImagePathUrl: "",
      checkboxFieldValue: "",
      extrasValue: "",
      isSubmit: false,
      isErrors: false,
      isSubmitting: false,
      category: [],
      permissionRequired: false
    };
    this.onColourPickerClick = this.onColourPickerClick.bind(this);
    this.onIconClick = this.onIconClick.bind(this);
    this._handleBannerImagePath = this._handleBannerImagePath.bind(this);
    this.bannerImageUpload = this.bannerImageUpload.bind(this);
    this.backButton = this.backButton.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.cancelButton = this.cancelButton.bind(this);
  }

  // Get Tag Values
  getTagValues = async tagType => {
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${tagType}`
      );
      const results = response.data.data;
      // Check the tag type skills
      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });
        this.setState({
          category: tagList
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  componentDidMount() {
    this.getTagValues(TAG_TYPE_RESOURCE_CATEGORY);
  }
  setSubmitState = () => {
    this.setState({ isSubmit: true });
  };

  reSetSubmitState = () => {
    this.setState({ isSubmit: false }, () => {
      this.setState({ isErrors: false });
    });
  };

  setIsErrors = () => {
    this.setState({ isErrors: true });
  };

  // Create playbook
  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    const {
      partnerTier,
      partnerType,
      sharingPermission
    } = this.state.checkboxFieldValue;

    // Check if the permission field is selected in case of selected partner option
    if (
      this.state.checkboxFieldValue &&
      sharingPermission === PARTNER_PERMISSION_FILTERED_PARTNERS &&
      (partnerTier.length || partnerType.length) === 0
    ) {
      this.setState({
        permissionRequired: true
      });
      return;
    } else {
      this.setState({
        permissionRequired: false
      });
    }

    // Check if the permission field is selected in case of Individual partner option
    if (
      sharingPermission === PARTNER_PERMISSION_INDIVIDUAL_PARTNER &&
      (values.sharedPartner === undefined || values.sharedPartner === null)
    ) {
      this.setState({
        permissionRequired: true
      });
      return;
    } else {
      this.setState({
        permissionRequired: false
      });
    }

    // Save playbook form in the final
    this._createResource(this._toArray(values));
  };

  // Get deliverable values
  getDeliverablesValue = values => {
    this.setState({ deliverablesValue: values });
  };

  // Get default deliverable values
  getDefaultDeliverablesValue = values => {
    this.setState({ defaultDeliverablesValue: values });
  };

  // Get extras values
  getExtrasValue = values => {
    this.setState({ extrasValue: values });
  };

  // Color picker
  onColourPickerClick(e) {
    this.setState({ bannerColor: e.target.id, playbookColorValidate: true });
  }

  // Icon click
  onIconClick(e) {
    this.setState({
      bannerIcon: e.target.className,
      playbookIconValidate: true
    });
  }

  // Back Button
  backButton(e) {
    let tab = e;
    this.setState({ currentTab: tab });
    if (tab === TAB_BASICS) {
      this.setState({ backButton: "Cancel" });
    } else {
      this.setState({ backButton: "Back" });
    }
    if (tab === TAB_PERMISSIONS) {
      this.setState({ publishResource: "Publish Resource" });
    } else {
      this.setState({ publishResource: "Next" });
    }
  }

  // Cancel Button
  cancelButton() {
    if (this.state.backButton === "Cancel") {
      this.props.history.goBack();
    } else {
      const currentTab = this.state.currentTab;
      this.state.isErrors && this.setSubmitState();

      switch (currentTab) {
        case TAB_PERMISSIONS:
          this.backButton(TAB_BASICS);
          break;
      }
    }
  }

  // Set next playbook tab
  setNextTab = tab => {
    if (this.state.currentTab !== TAB_PERMISSIONS) {
      return this.setState({
        currentTab: tab,
        buttonType: "button"
      });
    }

    return this.setState({
      currentTab: tab,
      status: RESOURCE_PUBLISHED,
      buttonType: "submit",
      publishResource: "Publish Resource"
    });
  };

  // Next Button
  nextButton() {
    const currentTab = this.state.currentTab;
    if (this.state.isErrors) {
      return this.setSubmitState();
    }

    switch (currentTab) {
      case TAB_BASICS:
        this.setNextTab(TAB_PERMISSIONS);
        this.backButton(TAB_PERMISSIONS);
        break;
      case TAB_PERMISSIONS:
        this.setNextTab(TAB_PERMISSIONS);
        break;
    }
  }

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Banner Image Upload
  bannerImageUpload = file => {
    this.setState({ files: file });
  };
  // Banner Image Remove
  handleImageRemove = () => {
    this.setState({ bannerImageUrl: "", files: "" });
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const title = values.title;

    if (!title) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    const data = new FormData();
    const { existingResourceSource } = this.state.checkboxFieldValue;
    data.append(
      "webPageUrl",
      existingResourceSource === RESOURCE_WEB_PAGE && values.webPageUrl
        ? values.webPageUrl
        : ""
    );
    data.append(
      "videoEmbedCode",
      existingResourceSource === RESOURCE_VIDEO && values.videoEmbedCode
        ? values.videoEmbedCode
        : ""
    );
    data.append("title", values.title ? values.title : "");
    data.append("description", values.description ? values.description : "");
    data.append("status", this.state.status);
    let categoryData = [];
    values &&
      values.category &&
      values.category.forEach(data => {
        if (data) {
          categoryData.push(data.value);
        }
      });
    categoryData = JSON.stringify(categoryData);
    data.append("category", categoryData ? categoryData : "");
    data.append(
      "bannerImagePath",
      this.state.bannerImageUrl ? this.state.bannerImageUrl : ""
    );
    data.append(
      "bannerColor",
      this.state.bannerColor ? this.state.bannerColor : ""
    );
    data.append(
      "bannerIcon",
      this.state.bannerIcon ? this.state.bannerIcon : "EnvelopeIcon"
    );
    data.append("resourceFile", this.state.resourceFile);
    data.append("resourceFileName", this.state.resourceFileName);
    data.append("file", this.state.files);
    data.append(
      "spotlightImage",
      this.state.spotlightImagePathUrlPreview
        ? this.state.spotlightImagePathUrlPreview
        : ""
    );
    data.append(
      "showPartnerSpotlight",
      this.state.checkboxFieldValue &&
        this.state.checkboxFieldValue.showPartnerSpotlight
    );
    data.append(
      "sharedTags",
      this.state.checkboxFieldValue && [
        ...this.state.checkboxFieldValue.partnerType
      ]
    );
    data.append(
      "partnerTiers",
      this.state.checkboxFieldValue && [
        ...this.state.checkboxFieldValue.partnerTier
      ]
    );
    data.append(
      "sharingPermission",
      this.state.checkboxFieldValue
        ? this.state.checkboxFieldValue.sharingPermission
        : PARTNER_PERMISSION_ALL_PARTNER
    );
    data.append(
      "sharedPartnerId",
      values.sharedPartner ? values.sharedPartner.id : ""
    );
    if (values.sendNotification !== undefined) {
      data.append("sendNotification", values.sendNotification);
    }

    return data;
  }

  // Playbook API Call
  _createResource(data) {
    this.setState({ isSubmitting: true });
    const { actions } = this.props;
    return apiClient
      .post(endpoints().resourceAPI, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        setTimeout(() => {
          toast.success(successMessage);

          const url =
            this.state.status === RESOURCE_DRAFT
              ? "/resources?section=Draft"
              : "/resources";
          this.props.history.push(url);
        }, 2000);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isSubmitting: false, status: RESOURCE_DRAFT });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Handle OnChange playbook banner image path
  _handleBannerImagePath = e => {
    const target = e.target;
    const bannerImagePath = target.value;
    this.setState({ bannerImagePath });
  };

  // Handle resource file upload
  handleResourceFile = files => {
    this.setState({ resourceFileName: files[0].name, resourceFile: files[0] });
    // this.setBase64Image(files[0], "resourceFile");
  };

  // Delete spotlight image
  _handleDeleteResourceFile = () => {
    return apiClient
      .delete(
        `${endpoints().resourceAPI}/spotlight/${this.props.match.params.id}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Set image preview in state
  setPreviewImage = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [`${state}Preview`]: reader.result });
    };
  };

  // Update image uploads
  handleSpotlightImageChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files ? e.target.files[0] : "";
      this.setPreviewImage(file, e.target.name);
      this.setState({ [`${e.target.name}`]: file });
    }
  };

  // Remove image
  handleSpotlightImageRemove = e => {
    this.setState(
      {
        [`${e.target.id}`]: "",
        [`${e.target.id}Preview`]: ""
      },
      this._handleDeleteResourceFile
    );
  };

  render() {
    const { category } = this.state;

    const headerButton = (
      <div className="btn-wrapper mt-2 mb-4">
        <button
          className="btn btn-outline-dark pr-2 mr-4"
          onClick={() => {
            this.state.isErrors && this.setSubmitState();
          }}
        >
          Save as Draft
        </button>
        <button
          type={this.state.buttonType}
          className={`btn btn-secondary pr-2 ${
            this.state.isSubmitting ? "disabled" : ""
          }`}
          onClick={this.nextButton}
        >
          {this.state.publishResource}
        </button>
      </div>
    );

    const {
      playbookIconValidate,
      playbookColorValidate,
      currentTab,
      resourceFiles,
      isSubmit,
      isErrors,
      spotlightImagePathUrlPreview,
      spotlightImagePathUrl,
      permissionRequired
    } = this.state;

    const initialValues = {
      title: "",
      description: "",
      bannerImageUrl: "",
      fileCount: "",
      videoEmbedCode: "",
      webPageUrl: ""
    };
    return (
      <>
        <MyContext.Consumer>
          {context => (
            <>
              {context.isMobile && <PageTitle label="Add Resource" />}
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  this._submit(values);
                }}
                showErrorToast={true}
                isSubmit={isSubmit}
                reSetSubmitState={this.reSetSubmitState}
                setIsErrorState={this.setIsErrors}
                isErrorsState={isErrors}
              >
                <div
                  className={`page-heading d-flex ${
                    context.isMobile
                      ? "justify-content-end"
                      : "justify-content-between"
                  }`}
                >
                  {!context.isMobile && <PageTitle label="Add Resource" />}
                  {headerButton && headerButton}
                </div>
                <ResourceFormFields
                  colorValidate={playbookColorValidate}
                  iconValidate={playbookIconValidate}
                  onColourPickerClick={this.onColourPickerClick}
                  onIconClick={this.onIconClick}
                  bannerColor={this.state.bannerColor}
                  bannerIcon={this.state.bannerIcon}
                  handleBannerImagePath={this._handleBannerImagePath}
                  bannerImageUpload={e => this.bannerImageUpload(e)}
                  bannerImageUrl={this.state.bannerImageUrl}
                  handleImageRemove={this.handleImageRemove}
                  backButton={e => this.backButton(e)}
                  currentTab={currentTab}
                  category={category}
                  handleResourceFile={this.handleResourceFile}
                  resourceFile={resourceFiles}
                  handleCategoryValue={this.handleCategoryValue}
                  getCheckboxFieldValue={values =>
                    this.setState({ checkboxFieldValue: values })
                  }
                  updatePermission={value =>
                    this.setState({ permissionRequired: value })
                  }
                  handleSpotlightImageChange={this.handleSpotlightImageChange}
                  handleSpotlightImageRemove={this.handleSpotlightImageRemove}
                  spotlightImagePathUrlPreview={spotlightImagePathUrlPreview}
                  spotlightImagePathUrl={spotlightImagePathUrl}
                  clearResourceFile={() =>
                    this.setState({ resourceFile: "", resourceFileName: "" })
                  }
                  permissionRequired={permissionRequired}
                  files={this.state.files}
                />
                {currentTab !== TAB_BASICS ? (
                  <CancelButton
                    name={this.state.backButton}
                    className="mt-2 p-2"
                    onClick={this.cancelButton}
                  />
                ) : (
                  ""
                )}
              </Form>
            </>
          )}
        </MyContext.Consumer>
      </>
    );
  }
}

function mapStateToProps(state) {
  return {
    playbooks: state.playbooks
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        resetPlaybookList
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(AddResource);
