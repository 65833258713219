import React from "react";
import PropTypes from "prop-types";
import { img } from "reactstrap";

class Image extends React.Component {
  render() {
    const {
      id,
      children,
      required,
      className,
      ImgSrc,
      ImgAlt,
      size,
      ImgMaxHeight,
      ImgMaxWidth,
      ImgWidth,
      ImgBackgroundColor
    } = this.props;

    const sizeStyle =
      size && size === "medium"
        ? {
            maxHeight: "170px",
            maxWidth: "170px",
            width: "100%"
          }
        : {};

    return (
      <img
        id={id || children}
        htmlFor={id}
        className={className}
        src={ImgSrc}
        alt={ImgAlt}
        style={{
          maxHeight: ImgMaxHeight,
          maxWidth: ImgMaxWidth,
          width: ImgWidth,
          backgroundColor: ImgBackgroundColor,
          sizeStyle
        }}
      >
        {children}
      </img>
    );
  }
}

Image.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default Image;
