import React, { Component } from "react";
import { DropdownItem } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Link } from "react-router-dom";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import MoreDropdown from "../../components/base/MoreDropdown";

// configs
import { endpoints } from "../../configs";

// Helper
import {
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

// Action
import { updateDeal, deleteDeal } from "../../actions/deal";
import {
  DEAL_STATUS_CLOSED,
  DEAL_STATUS_DECLINED,
  DEAL_STATUS_APPROVED,
  DEAL_STATUS_REQUESTED
} from "../../dealStatus/Constants";

// Inner Components
import DeclineDealModal from "./inner-components/DeclineDealModal";

import { DealIcon } from "../../assets/img/icons";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class MyDeals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "amount:ASC",
          label: "Amount"
        }
      ],
      declineDealModal: false,
      deal_id: ""
    };
  }

  // Update The Deal By Status
  updateDealStatus(status, dealId) {
    this.props.actions.updateDeal(
      dealId,
      { status: status },
      { status: "Requested" },
      "newDeals",
      this.props.roleId
    );
  }

  // Landing page delete api call
  handleDelete = id => {
    this.props.actions.deleteDeal(id, this.props.roleId);
  };

  // Toggle deal modal
  toggleModal = dealId => {
    let deal_id = "";
    if (dealId) {
      deal_id = dealId;
    }
    const { declineDealModal } = this.state;
    this.setState({ deal_id: deal_id }, () => {
      this.setState({ declineDealModal: !declineDealModal });
    });
  };

  render() {
    const currentUrl = window.location.pathname + window.location.search;
    const { declineDealModal, deal_id } = this.state;
    let params = { section: "my-deals" };
    if (isPartner(this.props.roleId)) {
      params = {
        partnerRegistered: true,
        status: `${DEAL_STATUS_REQUESTED},${DEAL_STATUS_APPROVED}`
      };
    } else {
      params = { status: DEAL_STATUS_APPROVED };
    }
    return (
      <>
        <ReduxTable
          id="myDeals"
          apiURL={`${endpoints().dealsAPI}/search`}
          params={params}
          searchPlaceholder="Search"
          message="No My Deal found"
          onRowClick={row =>
            this.props.history.push(
              `/deal/details/${row.deal_id}?redirect=${currentUrl}`
            )
          }
          sortByOptions={this.state.sortByOptions}
          newTableHeading
          showHeader
          icon={<DealIcon />}
          message="You can track the deals you have registered here"
          subtextMessage="To get started tap the Register a Deal button"
          paramsToUrl={true}
          history={this.props.history}
        >
          <ReduxColumn
            type="link"
            isClickable="true"
            sortBy="accountName"
            field="accountName"
          >
            Account Name
          </ReduxColumn>
          <ReduxColumn
            field="status"
            disableOnClick
            renderField={row => (
              <div
                className={`text-center text-uppercase ${
                  row.status !== DEAL_STATUS_APPROVED
                    ? row.status === DEAL_STATUS_CLOSED ||
                      row.status === DEAL_STATUS_DECLINED
                      ? "bg-danger"
                      : "landing-page-status-draft"
                    : "landing-page-status-active"
                }`}
                style={statusStyle}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn field="stage_tag_name" sortBy="stage_tag_name">
            Stage
          </ReduxColumn>
          <ReduxColumn
            field="amount"
            disableOnClick
            renderField={row => {
              return (
                <div className="text-left">
                  <p>{row.amount ? `$ ${row.amount}` : ""}</p>
                </div>
              );
            }}
          >
            Amount
          </ReduxColumn>
          <ReduxColumn field="partnerName" sortBy="partnerName">
            Partner
          </ReduxColumn>
          <ReduxColumn field="createdAt" sortBy="createdAt">
            Created At
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <Link
                    className="text-dark text-decoration-none"
                    to={`/deal/details/${row.deal_id}?redirect=${currentUrl}`}
                    style={{ cursor: "pointer" }}
                  >
                    <DropdownItem>Edit</DropdownItem>
                  </Link>
                  {(isSuperAdmin(this.props.roleId) ||
                    isCompanyAdmin(this.props.roleId) ||
                    isCompanyManager(this.props.roleId)) && (
                    <>
                      {row.status !== "Approved" && (
                        <DropdownItem
                          className="text-success"
                          onClick={() =>
                            this.updateDealStatus("Approved", row.deal_id)
                          }
                        >
                          Approve Deal
                        </DropdownItem>
                      )}

                      {row.status !== "Declined" && (
                        <DropdownItem
                          className="text-danger"
                          onClick={() => this.toggleModal(row.deal_id)}
                        >
                          Decline Deal
                        </DropdownItem>
                      )}

                      {row.status !== "Closed" && (
                        <DropdownItem
                          className="text-danger"
                          onClick={() =>
                            this.updateDealStatus("Closed", row.deal_id)
                          }
                        >
                          Close Deal
                        </DropdownItem>
                      )}
                    </>
                  )}
                  <DropdownItem
                    className="font-weight-bold text-danger"
                    onClick={() => this.handleDelete(row.deal_id)}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>

        {/* Declined Deal Modal */}
        {deal_id && declineDealModal && (
          <DeclineDealModal
            modal={declineDealModal}
            setModal={this.toggleModal}
            dealId={deal_id}
            listName={"myDeals"}
            params={params}
          />
        )}
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updateDeal, deleteDeal }, dispatch)
  };
}

const mapStateToProps = state => {
  let roleId = state.user ? state.user.roleId : "";

  return { roleId };
};
export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(MyDeals);
