import React from "react";
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import Spinner from "../../../../components/base/Spinner";

import MoreDropdown from "../../../../components/base/MoreDropdown";
import Form from "../../../../components/base/Form";
import Text from "../../../../components/Text";
import AddButton from "../../../../components/base/AddButton";
import DeleteModal from "../../../../components/base/DeleteModal";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownItem
} from "reactstrap";

// Helper
import { isLoggedIn, toString } from "../../../../lib/helper";

// API call
import { apiClient } from "../../../../apiClient";

// Configs
import { endpoints } from "../../../../configs";

// Constant
import { isBadRequest } from "../../../../common/http";
import { toast } from "react-toastify";
import { GENERAL_TAB_ROLE, TAB_ROLE } from "../../../../setting/Constants";
import { User } from "../../../../Constants/Status";
import Detail from "./detail";

class Role extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values.
    this.state = {
      id: "",
      name: "",
      status: "",
      deleteModal: false,
      deleteData: false,
      isLoading: false,
      isOpen: false,
      sortByOptions: [
        {
          value: "name:ASC",
          label: "Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ]
    };
  }

  _toggle = id => {
    this.setState({ isOpen: !this.state.isOpen, id: id || "" });
  };

  /**
   * Create template
   *
   * @param data
   */
  create = async data => {
    apiClient
      .post(`${endpoints().userRoleAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.setState({ isLoading: true });

        this._toggle();
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });

          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          this.setState({ isOpen: true });
          console.error(errorMessage);
        }
      });
  };

  updateStatus = async (id, data) => {
    apiClient
      .put(`${endpoints().userRoleAPI}/status/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({ isLoading: true });
        toast.success(successMessage);
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });

          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  // delete Name page api call by id
  _delete(id) {
    apiClient
      .delete(`${endpoints().userRoleAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({ isLoading: true });

        toast.success(successMessage);
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  render() {
    const { deleteModal, deleteData, isLoading, id } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    let roleId = this.props.activeSection;

    return (
      <>
        {!roleId ? (
          <>
            <div className="d-flex justify-content-between">
              <h5 className="font-weight-bold">Role</h5>
              {/* <div className="btn-wrapper">
                <AddButton
                  label="Add New"
                  className="pull-right btn btn-secondary"
                  onClick={() => {
                    this.setState({
                      id: "",
                      name: "",
                      status: "",
                      isOpen: true
                    });
                  }}
                />
              </div> */}
            </div>

            <br />
            <div>
              <Modal
                isOpen={this.state.isOpen}
                toggle={this._toggle}
                className="landing-create-popup w-100"
                backdrop="static"
              >
                <ModalHeader
                  toggle={this._toggle}
                  cssModule={{ "modal-title": "w-100 text-center" }}
                >
                  <p>{this.state.id ? "Edit Role" : "Add Role"}</p>
                </ModalHeader>
                <Form
                  initialValues={{
                    id: this.state.id || "",
                    name: this.state.name || ""
                  }}
                  onSubmit={values => {
                    values.name = toString(values.name).trim();

                    this.create(values);
                  }}
                >
                  <ModalBody className="ml-3 mr-3 mt-2 mb-3">
                    <div>
                      <Text
                        name="name"
                        label="Role Name"
                        placeholder="Enter Name"
                        error=""
                        required
                      />
                    </div>
                  </ModalBody>
                  <ModalFooter>
                    <div className="container-fluid">
                      <div className="col text-center">
                        <Button type="submit" label="">
                          {this.state.id ? "Save" : "Add"}
                        </Button>
                      </div>
                    </div>
                  </ModalFooter>
                </Form>
              </Modal>
            </div>
            {/* REDUX TABLE */}
            <ReduxTable
              id="userRole"
              className="mt-3 mb-3"
              apiURL={`${endpoints().userRoleAPI}/search`}
              showHeader
              newTableHeading
              paramsToUrl={true}
              pagination={true}
              history={this.props.history}
              searchPlaceholder="Search"
              sortByOptions={this.state.sortByOptions}
              onRowClick={row => {
                this.props.history.push(
                  `/admin-settings/${TAB_ROLE}/${GENERAL_TAB_ROLE}/${row.id}`
                );
                this.setState({ id: row.id });
              }}
            >
              <ReduxColumn
                type="link"
                isClickable="true"
                field="name"
                sortBy="name"
              >
                Name
              </ReduxColumn>
              <ReduxColumn field="status" sortBy="status" disableOnClick>
                Status
              </ReduxColumn>

              <ReduxColumn
                field="status"
                disableOnClick
                className="action-column"
                renderField={row => (
                  <div className="text-center landing-group-dropdown">
                    <MoreDropdown>
                      <DropdownItem
                        onClick={row => {
                          this.props.history.push(
                            `/admin-settings/${TAB_ROLE}/${GENERAL_TAB_ROLE}/${row.id}`
                          );
                        }}
                      >
                        Edit
                      </DropdownItem>
                      {row.status !== User.STATUS_ACTIVE_TEXT ? (
                        <DropdownItem
                          onClick={() => {
                            this.updateStatus(row.id, {
                              company_name: row.company_name,
                              status: User.STATUS_ACTIVE_VALUE
                            });
                          }}
                        >
                          Make as Active
                        </DropdownItem>
                      ) : (
                        <DropdownItem
                          onClick={() => {
                            this.updateStatus(row.id, {
                              company_name: row.company_name,
                              status: User.STATUS_INACTIVE_VALUE
                            });
                          }}
                        >
                          Make as InActive
                        </DropdownItem>
                      )}
                      <DropdownItem
                        className={"text-danger"}
                        onClick={() =>
                          this.setState({
                            deleteModal: true,
                            deleteData: row
                          })
                        }
                      >
                        Delete
                      </DropdownItem>
                    </MoreDropdown>
                  </div>
                )}
              >
                Action
              </ReduxColumn>
            </ReduxTable>
            <DeleteModal
              id="deleteRole"
              isOpen={deleteModal}
              toggle={() => {
                this.setState({ deleteModal: false });
              }}
              title="Delete Role"
              label={deleteData.name}
              deleteFunction={() => this._delete(deleteData.id)}
            />
          </>
        ) : (
          <Detail
            activeSection={this.props.activeSection}
            history={this.props.history}
            roleId={roleId}
            match={this.props.match}
          />
        )}
      </>
    );
  }
}

export default Role;
