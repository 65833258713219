import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import _ from "lodash";

// Action
import { fetchList } from "../../../actions/table";
import {
  updateReferral,
  deleteReferral,
  referralDecline
} from "../../../actions/referral";

// Config
import { endpoints } from "../../../configs";

// Components
import ReduxTable, { ReduxColumn } from "../../../components/base/ReduxTable";

// Helper
import {
  isLoggedIn,
  getFullName,
  getParamsByName,
  getCookie,
  setCookie,
  clearCookie
} from "../../../lib/helper";
import {
  REFERRAL_STATUS_DECLINED,
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_NEW,
  REFERRAL_STATUS_CLOSED_WON,
  REFERRAL_STATUS_ACCEPTED,
  REFERRAL_STATUS_REJECTED
} from "../../../referral/Constants";

import { DownloadIcon, ReferralIcon } from "../../../assets/img/icons";
import { COOKIE_SHOW_ASSIGNED_PARTNERS } from "../../../lib/cookie";
import { apiClient } from "../../../apiClient";
import detectBrowser from "../../../components/helpers/detectBrowser";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";
import {
  SETTINGS_SHOW_REFERRAL_NUMBER,
  SETTINGS_ENABLE_REFERRAL_REQUEST
} from "../../../setting/Constants";

import { getSettingsData } from "../../../actions/settings";
import Currency from "../../../components/Currency";
import Referral from "../../../helpers/Referral";
import PageTitle from "../../../components/base/PageTitle";
import { CSVLink } from "react-csv";
import moment from "moment";
import PartnerService from "../../../services/partnerService";
import Filter from "../../../components/filter";
import DateTime from "../../../lib/DateTime";

class AllReferral extends React.Component {
  state = {
    isChecked: "",
    isMobile: detectBrowser(),
    sortByOptions: [
      {
        value: "createdAt:DESC",
        label: "Most Recent"
      },
      {
        value: "account_name:ASC",
        label: "Account Name"
      }
    ],
    deleteReferralModal: false,
    deleteReferral: false,
    partner: "",
    start_date: "",
    end_date: "",
    status: "",
    search: "",
    referralList: "",
    selectedSortValue: "",
    sort: "",
    sortDir: "",
    statusObj: {},
    typeObj: {},
    selectedSortOption: "createdAt:DESC",
    page: 1,
    currentPage: 1,
    referral_id: "",
    referralDeclineModal: false,
    submittedFrom: "",
    partnerType: "",
    partnerTier: "",
    showReferralNumber: false,
    referralType: getParamsByName("referralType")
      ? getParamsByName("referralType")
      : Referral.TYPE_ALL,
    referralRequestEnabled: false,
    partnerTypeOptions: [],
    partnerTierOptions: [],
    partnerList: [],
    params: {
      search: getParamsByName("search"),
      start_date: getParamsByName("start_date"),
      end_date: getParamsByName("end_date"),
      partner: getParamsByName("partner"),
      status: getParamsByName("status"),
      partnerType: getParamsByName("partnerType"),
      partnerTier: getParamsByName("partnerTier"),
      referralType: getParamsByName("referralType")
        ? getParamsByName("referralType")
        : Referral.TYPE_ALL,
      pageSize: getParamsByName("pageSize"),
      sort: getParamsByName("sort"),
      sortDir: getParamsByName("sortDir"),
      page: getParamsByName("page")
    },
    pageSize: 10
  };

  // Assigned partners checkbox toggle
  toggleChange = e => {
    this.setState({ isChecked: !this.state.isChecked });

    if (this.state.isChecked) {
      clearCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    }

    if (!this.state.isChecked) {
      clearCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
      setCookie(COOKIE_SHOW_ASSIGNED_PARTNERS, "true");
    }
  };

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this.getReferralData();
    this.getPartnerType();
    this.getPartnerTier();
    this.getPartnerList();
    this.getSetting();

    let status = this.props.status;
    if (status) {
      this.setState({ status });
    } else {
      const statusNames = getParamsByName("status") || "";
      if (statusNames) {
        this.setState({ status: statusNames });
        let statusObj = {},
          statusArray = [];
        statusArray = statusNames.split(",");
        if (statusArray.length > 0) {
          statusArray.forEach(element => {
            statusObj[`${element}`] = true;
          });
        }
        this.setState({ statusObj: statusObj });
      }
    }
    // Assigned partner checkbox status
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    if (checked_status) {
      this.setState({ isChecked: !this.state.isChecked });
    }

    if (!checked_status) {
      this.setState({ isChecked: this.state.isChecked });
    }
    this.handleSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check is logged in user
    isLoggedIn();
    const status = this.props.status;
    if (prevProps && prevProps.status !== status) {
      this.setState({ status });
    }
  }

  getSetting = () => {
    getSettingsData(SETTINGS_SHOW_REFERRAL_NUMBER, response => {
      if (response) {
        this.setState({
          showReferralNumber: response.value == "true" ? true : false
        });
      }
    });

    getSettingsData(SETTINGS_ENABLE_REFERRAL_REQUEST, response => {
      if (response) {
        this.setState({
          referralRequestEnabled: response.value == "true" ? true : false
        });
      }
    });
  };

  //handle search function
  handleSearch() {
    const search = window.location.search;
    const pathParams = new URLSearchParams(search);
    const searchItem = pathParams.get("search");

    if (searchItem != this.state.search) {
      this.setState({ search: searchItem });
    }
  }

  // Search
  pageSearchOnChange(event) {
    event.persist();
    const value = event.target.value;
    this.setState({ search: decodeURI(value) });
    this.doSearch(event);
  }

  doSearch = _.debounce(event => {
    this.setState(
      {
        isSearch: true,
        page: 1
      },
      () => {
        this.fetchData({ search: this.state.search });
      }
    );
  }, 500);

  handleStartDate = start_date => {
    this.setState(
      {
        start_date: start_date ? DateTime.toISOStringDate(start_date) : ""
      },
      () => {
        this.fetchData({
          start_date: start_date ? DateTime.formatDate(start_date) : ""
        });
      }
    );
  };

  handleEndDate = end_date => {
    this.setState(
      {
        end_date: end_date ? DateTime.toISOStringDate(end_date) : ""
      },
      () => {
        this.fetchData({
          end_date: end_date ? DateTime.formatDate(end_date) : ""
        });
      }
    );
  };

  handlePartnerChange = partner => {
    this.setState({ partner: partner ? partner.value : "" }, () => {
      this.fetchData({ partner: partner ? partner.value : "" });
    });
  };

  handleStatusChange = values => {
    let statusObj = values;
    let status = "",
      statusArray = [];
    const selectedStatusArrays = Object.entries(statusObj);

    if (selectedStatusArrays.length > 0) {
      selectedStatusArrays.forEach(async selectedStatusArray => {
        if (selectedStatusArray[1] === true) {
          statusArray.push(selectedStatusArray[0]);
        }
      });
    }

    if (statusArray.length > 0) {
      status = statusArray.join();
    }

    this.setState({ status: status ? status : "", statusObj }, () => {
      this.fetchData({ status: status ? status : "" });
    });
  };

  handletypeChange = type => {
    this.setState({ partnerType: type ? type.value : "" }, () => {
      this.fetchData({ partnerType: type ? type.value : "" });
    });
  };
  handletierChange = type => {
    this.setState({ partnerTier: type ? type.value : "" }, () => {
      this.fetchData({ partnerTier: type ? type.value : "" });
    });
  };
  handleSortByChange = value => {
    const valueArray = this.getSortValueFromLabel(value).split(":");
    const sort = valueArray[0];
    const sortDir = valueArray[1];
    this.setState({ sort, sortDir }, () => {
      this.fetchData({ sort: sort, sortDir: sortDir });
    });
  };

  handleReferralTypeChange = type => {
    let params = new Object();
    if (type) {
      params.referralType = type.value;
    } else {
      params.referralType = Referral.TYPE_ALL;
    }
    this.setState(params, () => {
      this.fetchData({ referralType: type ? type.value : "" });
    });
  };

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  getSelectedSortLabel() {
    const sortByOptions = this.props.sortByOptions
      ? this.props.sortByOptions
      : this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.value === this.state.selectedSortOption
    );

    if (selectedSortOption) {
      return selectedSortOption.label;
    }

    return "";
  }
  getPartnerType = async () => {
    const response = await PartnerService.TypeList();
    this.setState({ partnerTypeOptions: response });
  };

  getPartnerTier = async () => {
    const response = await PartnerService.TierList();
    this.setState({ partnerTierOptions: response });
  };
  getPartnerList = async () => {
    const response = await PartnerService.List();
    this.setState({ partnerList: response });
  };

  getApiURL() {
    return `${endpoints().referralReportApi}/search`;
  }

  fetchData = updateParams => {
    const params = {
      search: getParamsByName("search"),
      start_date: getParamsByName("start_date"),
      end_date: getParamsByName("end_date"),
      partner: getParamsByName("partner"),
      status: getParamsByName("status"),
      partnerType: getParamsByName("partnerType"),
      partnerTier: getParamsByName("partnerTier"),
      referralType: getParamsByName("referralType"),
      pageSize: getParamsByName("pageSize"),
      sort: getParamsByName("sort"),
      sortDir: getParamsByName("sortDir"),
      page: getParamsByName("page"),
      ...updateParams
    };

    this.props.getReferralList(params);
    this.setState({ params: params });

    let queryString = "";
    const queryStringArray = Object.entries(params);

    if (queryStringArray.length > 0) {
      queryString = "?";
      queryStringArray.forEach(async queryParam => {
        if (queryParam[1] !== "")
          queryString = `${queryString}&${queryParam[0]}=${queryParam[1]}`;
      });
    }

    if (this.props.history) {
      const currentPage = window.location.pathname;
      this.props.history.push(`${currentPage}${queryString}`);
    } else {
      this.props.history.push(`/reports/referral-report`);
    }

    // Get Referral list from redux table
    this.props.actions.fetchList(
      "referralReport",
      this.getApiURL(),
      this.props.allReferralsCurrentPage,
      params.pageSize ? params.pageSize : 10,
      params
    );
  };

  //get referrals data
  getReferralData = async () => {
    const response = await apiClient.get(
      `${endpoints().referralReportApi}/search`
    );
    const currentPage = response.data.currentPage;
    this.setState({ page: currentPage });
  };

  // Toggle Referral Decline Modal
  toggleModal = referralId => {
    let referral_id = "";
    if (referralId) {
      referral_id = referralId;
    }
    const { referralDeclineModal } = this.state;
    this.setState({ referral_id: referral_id }, () => {
      this.setState({ referralDeclineModal: !referralDeclineModal });
    });
  };
  handleDeleteFilter = removedFilter => {
    this.fetchData(removedFilter);
  };

  handlePageSizeChange = e => {
    this.setState(
      {
        pageSize: e
      },
      () => {
        this.fetchData({ pageSize: e });
      }
    );
  };

  render() {
    const {
      search,
      showReferralNumber,
      referralType,
      referralRequestEnabled,
      partnerList,
      partnerTierOptions,
      partnerTypeOptions,
      params
    } = this.state;

    const statusOptions =
      referralType == Referral.TYPE_REFERRAL
        ? [
            {
              value: REFERRAL_STATUS_NEW,
              label: REFERRAL_STATUS_NEW
            },
            {
              value: REFERRAL_STATUS_APPROVED,
              label: REFERRAL_STATUS_APPROVED
            },
            {
              value: REFERRAL_STATUS_DECLINED,
              label: REFERRAL_STATUS_DECLINED
            },
            {
              value: REFERRAL_STATUS_CLOSED_WON,
              label: REFERRAL_STATUS_CLOSED_WON
            }
          ]
        : referralType == Referral.TYPE_REQUEST
        ? [
            {
              value: REFERRAL_STATUS_ACCEPTED,
              label: REFERRAL_STATUS_ACCEPTED
            },
            {
              value: REFERRAL_STATUS_REJECTED,
              label: REFERRAL_STATUS_REJECTED
            }
          ]
        : [
            {
              value: REFERRAL_STATUS_NEW,
              label: REFERRAL_STATUS_NEW
            },
            {
              value: REFERRAL_STATUS_APPROVED,
              label: REFERRAL_STATUS_APPROVED
            },
            {
              value: REFERRAL_STATUS_DECLINED,
              label: REFERRAL_STATUS_DECLINED
            },
            {
              value: REFERRAL_STATUS_CLOSED_WON,
              label: REFERRAL_STATUS_CLOSED_WON
            },
            {
              value: REFERRAL_STATUS_ACCEPTED,
              label: REFERRAL_STATUS_ACCEPTED
            },
            {
              value: REFERRAL_STATUS_REJECTED,
              label: REFERRAL_STATUS_REJECTED
            }
          ];
    const referralTypeOption = [
      {
        value: Referral.TYPE_ALL,
        label: Referral.TYPE_ALL
      },
      {
        value: Referral.TYPE_REFERRAL,
        label: Referral.TYPE_REFERRAL
      },
      {
        value: Referral.TYPE_REQUEST,
        label: Referral.TYPE_REQUEST
      }
    ];

    const type_id = getParamsByName("partnerType");
    const tier_id = getParamsByName("partnerTier");

    const defaultPartnerTypeId = type_id
      ? this.state.partnerTypeOptions.find(
          partnerDetail => partnerDetail.value === parseInt(type_id, 10)
        )
      : "";
    const defaultPartnerTierId = tier_id
      ? this.state.partnerTierOptions.find(
          partnerDetail => partnerDetail.value === parseInt(tier_id, 10)
        )
      : "";
    const defaultReferralType = referralType
      ? referralTypeOption.find(
          partnerDetail => partnerDetail.value === parseInt(referralType, 10)
        )
      : "";
    const initialValues = {
      partnerType: partnerTypeOptions.find(partnerDetail =>
        partnerDetail.value == getParamsByName("partnerType")
          ? getParamsByName("partnerType")
          : ""
      ),

      partnerTier: partnerTierOptions.find(partnerDetail =>
        partnerDetail.value == getParamsByName("partnerTier")
          ? getParamsByName("partnerTier")
          : ""
      ),
      referralType: referralTypeOption.find(partnerDetail =>
        partnerDetail.value == getParamsByName("referralType")
          ? getParamsByName("referralType")
          : ""
      ),
      status: statusOptions.find(partnerDetail =>
        partnerDetail.value == getParamsByName("status")
          ? getParamsByName("status")
          : ""
      ),
      partner: partnerList.find(partnerDetail =>
        partnerDetail.value == getParamsByName("partner")
          ? getParamsByName("partner")
          : ""
      ),
      start_date: getParamsByName("start_date")
        ? getParamsByName("start_date")
        : "",
      end_date: getParamsByName("end_date") ? getParamsByName("end_date") : ""
    };

    return (
      <>
        <div className="d-flex justify-content-between  align-items-center mb-4 mt-3">
          <PageTitle label={`${Terminology.get(SystemName.REFERRAL)} Report`} />

          <div className="d-flex justify-content-between">
            <div className="mr-3">
              <CSVLink
                data={this.props.exportData}
                headers={this.props.headers}
                filename={`Referral_Report_${moment().format(
                  "YYYY-MM-DD"
                )}.csv`}
              >
                <span className="download-pdf ml-auto d-flex" href="#">
                  <DownloadIcon />
                  <span className="ml-1">Export</span>
                </span>
              </CSVLink>
            </div>
          </div>
        </div>

        <Filter
          showHeader
          newTableHeading
          showSearch
          displayFilter
          params={params}
          sortByDropdown
          initialValues={initialValues}
          sortByOptions={this.state.sortByOptions}
          pageSearchOnChange={this.pageSearchOnChange.bind(this)}
          search={search}
          getSelectedSortLabel={this.getSelectedSortLabel()}
          handleSortByChange={this.handleSortByChange}
          showDateFilter
          showPartnerFilter
          showReferralTypeFilter
          showPartnerTierFilter
          showPartnerTypeFilter
          showTypeFilter
          showReferralStatusFilter
          handleStartDate={this.handleStartDate}
          handleEndDate={this.handleEndDate}
          selectedStartDate={this.state.start_date}
          selectedEndDate={this.state.end_date}
          handlePartnerChange={this.handlePartnerChange}
          selectedPartnerId={getParamsByName("partner")}
          handleStatusChange={this.handleStatusChange}
          statusOptions={statusOptions}
          selectedStatus={this.state.statusObj}
          handletypeChange={this.handletypeChange}
          defaultPartnerTypeId={defaultPartnerTypeId}
          partnerTypeOptions={this.state.partnerTypeOptions}
          handletierChange={this.handletierChange}
          defaultPartnerTierId={defaultPartnerTierId}
          partnerTierOptions={this.state.partnerTierOptions}
          handleReferralTypeChange={this.handleReferralTypeChange}
          defaultReferralType={defaultReferralType}
          referralTypeOption={referralTypeOption}
          partnerList={this.state.partnerList}
          handleDeleteFilter={this.handleDeleteFilter}
          handlePageSizeChange={this.handlePageSizeChange}
        />

        <div className="mb-5">
          <ReduxTable
            startDate={getParamsByName("start_date")}
            endDate={getParamsByName("end_date")}
            id="referralReport"
            apiURL={this.getApiURL()}
            params={params}
            onRowClick={row => this.history.push(`/referrals/edit/${row.id}`)}
            sortByOptions={this.state.sortByOptions}
            searchDisabled
            icon={<ReferralIcon />}
            message={`You can track the ${Terminology.get(
              SystemName.REFERRALS
            )} you have submitted here`}
            subtextMessage={`To get started tap the Submit a ${Terminology.get(
              SystemName.REFERRAL
            )} button`}
            assignedPartnersCheckbox="assignedPartnersCheckbox"
            assignedPartnerValue={this.state.isChecked}
            assignedPartnerChange={this.toggleChange}
            paramsToUrl={true}
            history={this.props.history}
          >
            {showReferralNumber && (
              <ReduxColumn
                minWidth={"110px"}
                width={"110px"}
                type="link"
                disableOnClick
                className="text-center"
                field="referral_number"
                renderField={row => (
                  <Link
                    to={
                      row.type == Referral.TYPE_REQUEST
                        ? `/referral/request/${row.id}`
                        : `/referral/edit/${row.id}`
                    }
                  >
                    {row.referral_number}
                  </Link>
                )}
              >
                {`${Terminology.get(SystemName.REFERRAL)}#`}
              </ReduxColumn>
            )}
            <ReduxColumn
              minWidth={"110px"}
              width={"110px"}
              type="link"
              isClickable="false"
              field="account_name"
              sortBy="account_name"
              renderField={row => (
                <Link
                  to={
                    row.type == Referral.TYPE_REQUEST
                      ? `/referral/request/${row.id}`
                      : `/referral/edit/${row.id}`
                  }
                >
                  {row.account_name}
                </Link>
              )}
            >
              Account
            </ReduxColumn>
            <ReduxColumn
              minWidth={"110px"}
              width={"110px"}
              field="name"
              sortBy="first_name"
              renderField={row => (
                <span>{getFullName(row.first_name, row.last_name)}</span>
              )}
            >
              Contact
            </ReduxColumn>
            <ReduxColumn
              minWidth={"150px"}
              width={"150px"}
              sortBy="account_name"
              field="companyName"
            >
              Partner
            </ReduxColumn>
            <ReduxColumn
              minWidth={"100px"}
              maxWidth={"100px"}
              sortBy="statusV"
              field="status"
            >
              Status
            </ReduxColumn>
            <ReduxColumn
              minWidth={"110px"}
              width={"110px"}
              sortBy="amount"
              field="amount"
              className="text-right"
              renderField={row => (
                <span>
                  {row.amount != null ? (
                    <Currency amount={row.amount} currencyName={row.currency} />
                  ) : (
                    ""
                  )}
                </span>
              )}
            >
              Amount
            </ReduxColumn>
            {referralRequestEnabled && (
              <ReduxColumn
                minWidth={"100px"}
                maxWidth={"100px"}
                sortBy="type"
                field="type"
              >
                Type
              </ReduxColumn>
            )}
            <ReduxColumn
              minWidth={"130px"}
              width={"130px"}
              field="createdAt"
              sortBy="createdAt"
            >
              Created At
            </ReduxColumn>
          </ReduxTable>
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { updateReferral, deleteReferral, referralDecline, fetchList },
      dispatch
    )
  };
}

export default connect(null, mapDispatchToProps)(AllReferral);
