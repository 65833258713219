import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// Constants
import {
  GENERAL_ADDRESS_SUB_TAB,
  GENERAL_GENERAL_SUB_TAB,
  GENERAL_SETTINGS_SUB_TAB,
  GENERAL_SOCIAL_SUB_TAB,
  TAB_GENERAL
} from "../../../../setting/Constants";

// Sub Tab  Sections
import GeneralSection from "./GeneralSection";
import AddressSection from "./AddressSection";
import SocialSection from "./SocialSection";
import SettingsSection from "./SettingsSection";

const GeneralTab = props => {
  const {
    activeSubTab,
    adminSettings,
    saveSettings,
    toggleTabs,
    history,
    settings
  } = props;

  return (
    <>
      <Nav tabs className="admin-tabs">
        {/* General Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === GENERAL_GENERAL_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_GENERAL, GENERAL_GENERAL_SUB_TAB);
            }}
          >
            {GENERAL_GENERAL_SUB_TAB}
          </NavLink>
        </NavItem>

        {/* Address Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === GENERAL_ADDRESS_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_GENERAL, GENERAL_ADDRESS_SUB_TAB);
            }}
          >
            {GENERAL_ADDRESS_SUB_TAB}
          </NavLink>
        </NavItem>

        {/* Social Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === GENERAL_SOCIAL_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_GENERAL, GENERAL_SOCIAL_SUB_TAB);
            }}
          >
            {GENERAL_SOCIAL_SUB_TAB}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === GENERAL_SETTINGS_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_GENERAL, GENERAL_SETTINGS_SUB_TAB);
            }}
          >
            {GENERAL_SETTINGS_SUB_TAB}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeSubTab}>
        {/* General Tab */}
        <TabPane tabId={GENERAL_GENERAL_SUB_TAB} className="w-100">
          <GeneralSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
            settings={settings}
            marketplaceLogoUpload={props.marketplaceLogoUpload}
            handleMarketplaceLogoRemove={props.handleMarketplaceLogoRemove}
          />
        </TabPane>

        {/* Address Tab */}
        <TabPane tabId={GENERAL_ADDRESS_SUB_TAB} className="w-100">
          <AddressSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        {/* Social Tab */}
        <TabPane tabId={GENERAL_SOCIAL_SUB_TAB} className="w-100">
          <SocialSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
        <TabPane tabId={GENERAL_SETTINGS_SUB_TAB} className="w-100">
          <SettingsSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default GeneralTab;
