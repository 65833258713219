import React from "react";
import { RocketGraphicIcon } from "../../../assets/img/icons";
import OnboardingLink from "./OnboardingLink";

const ExpertEliteStatus = () => {
  const percentage = 100;
  return (
    <div className="expert-onboarding">
      <OnboardingLink
        icon={<RocketGraphicIcon />}
        title={"Hey Karl. Amazing job so far!"}
        description={"You are currently a Certified Elite Expert."}
        availability={{
          status: "available",
          text: "You’re Available 30+ Hours Per Week"
        }}
        progress={percentage}
        expertStatus={{
          accepted: true,
          experttype: "elite"
        }}
      />
    </div>
  );
};

export default ExpertEliteStatus;
