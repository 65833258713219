/* Expert Availability Create*/
export const REQUEST_CREATE_EXPERT_AVAILABILITY_UPDATE =
  "REQUEST_CREATE_EXPERT_AVAILABILITY_UPDATE";
export const RECEIVE_CREATE_EXPERT_AVAILABILITY_UPDATE =
  "RECEIVE_CREATE_EXPERT_AVAILABILITY_UPDATE";
export const EXPERT_AVAILABILITY_UPDATE_CREATE_ERROR =
  "EXPERT_AVAILABILITY_UPDATE_CREATE_ERROR";

/**
 * Table
 */
export const REQUEST_TABLE_LIST = "REQUEST_TABLE_LIST";
export const RECEIVE_TABLE_LIST = "RECEIVE_TABLE_LIST";
export const FETCH_TABLE_LIST_FAIL = "FETCH_TABLE_LIST_FAIL";
export const SET_TABLE_PAGE = "SET_TABLE_PAGE";
export const CLEAR_TABLE_LIST = "CLEAR_TABLE_LIST";

/* Admin Dashboard */
export const REQUEST_DASHBOARD_COUNT = "REQUEST_DASHBOARD_COUNT";
export const RECEIVE_DASHBOARD_COUNT = "RECEIVE_DASHBOARD_COUNT";
export const FETCH_DASHBOARD_COUNT_FAIL = "FETCH_DASHBOARD_COUNT_FAIL";

/* Customer Dashboard */
export const REQUEST_CUSTOMER_DASHBOARD_COUNT =
  "REQUEST_CUSTOMER_DASHBOARD_COUNT";
export const RECEIVE_CUSTOMER_DASHBOARD_COUNT =
  "RECEIVE_CUSTOMER_DASHBOARD_COUNT";
export const FETCH_CUSTOMER_DASHBOARD_COUNT_FAIL =
  "FETCH_CUSTOMER_DASHBOARD_COUNT_FAIL";

/* Landing Page */
export const REQUEST_LANDING_PAGE_DETAIL = "REQUEST_LANDING_PAGE_DETAIL";
export const RECEIVE_LANDING_PAGE_DETAIL = "RECEIVE_LANDING_PAGE_DETAIL";
export const FETCH_LANDING_PAGE_DETAIL_FAIL = "FETCH_LANDING_PAGE_DETAIL_FAIL";

/* Delete Tags */
export const REQUEST_DELETE_TAG = "REQUEST_DELETE_TAG";
export const RECEIVE_DELETE_TAG = "RECEIVE_DELETE_TAG";
export const TAG_DELETE_ERROR = "TAG_DELETE_ERROR";

/* Add TAG */
export const REQUEST_CREATE_TAG = "REQUEST_CREATE_TAG";
export const RECEIVE_CREATE_TAG = "RECEIVE_CREATE_TAG";
export const TAG_CREATE_ERROR = "TAG_CREATE_ERROR";

/* Update TAG */
export const REQUEST_UPDATE_TAG = "REQUEST_UPDATE_TAG";
export const RECEIVE_UPDATE_TAG = "RECEIVE_UPDATE_TAG";
export const TAG_UPDATE_ERROR = "TAG_UPDATE_ERROR";

/* Add TAG Type */
export const REQUEST_CREATE_TAG_TYPE = "REQUEST_CREATE_TAG_TYPE";
export const RECEIVE_CREATE_TAG_TYPE = "RECEIVE_CREATE_TAG_TYPE";
export const TAG_TYPE_CREATE_ERROR = "TAG_TYPE_CREATE_ERROR";

/* Update TAG Type */
export const REQUEST_UPDATE_TAG_TYPE = "REQUEST_UPDATE_TAG_TYPE";
export const RECEIVE_UPDATE_TAG_TYPE = "RECEIVE_UPDATE_TAG_TYPE";
export const TAG_TYPE_UPDATE_ERROR = "TAG_TYPE_UPDATE_ERROR";

/* Delete Tag Types */
export const REQUEST_DELETE_TAG_TYPE = "REQUEST_DELETE_TAG_TYPE";
export const RECEIVE_DELETE_TAG_TYPE = "RECEIVE_DELETE_TAG_TYPE";
export const TAG_TYPE_DELETE_ERROR = "TAG_TYPE_DELETE_ERROR";

// Add Landing Page
export const REQUEST_CREATE_LANDING_PAGE = "REQUEST_CREATE_LANDING_PAGE";
export const RECEIVE_CREATE_LANDING_PAGE = "RECEIVE_CREATE_LANDING_PAGE";
export const LANDING_PAGE_CREATE_ERROR = "LANDING_PAGE_CREATE_ERROR";

/* Update Landing Page */
export const REQUEST_UPDATE_LANDING_PAGE = "REQUEST_UPDATE_LANDING_PAGE";
export const RECEIVE_UPDATE_LANDING_PAGE = "RECEIVE_UPDATE_LANDING_PAGE";
export const LANDING_PAGE_UPDATE_ERROR = "LANDING_PAGE_UPDATE_ERROR";

/* Delete Landing Page */
export const REQUEST_DELETE_LANDING_PAGE = "REQUEST_DELETE_LANDING_PAGE";
export const RECEIVE_DELETE_LANDING_PAGE = "RECEIVE_DELETE_LANDING_PAGE";
export const LANDING_PAGE_DELETE_ERROR = "LANDING_PAGE_DELETE_ERROR";

// Add Landing Page Block
export const REQUEST_CREATE_LANDING_PAGE_BLOCK =
  "REQUEST_CREATE_LANDING_PAGE_BLOCK";
export const RECEIVE_CREATE_LANDING_PAGE_BLOCK =
  "RECEIVE_CREATE_LANDING_PAGE_BLOCK";
export const LANDING_PAGE_BLOCK_CREATE_ERROR =
  "LANDING_PAGE_BLOCK_CREATE_ERROR";

/* Update Landing Page Block*/
export const REQUEST_UPDATE_LANDING_PAGE_BLOCK =
  "REQUEST_UPDATE_LANDING_PAGE_BLOCK";
export const RECEIVE_UPDATE_LANDING_PAGE_BLOCK =
  "RECEIVE_UPDATE_LANDING_PAGE_BLOCK";
export const LANDING_PAGE_BLOCK_UPDATE_ERROR =
  "LANDING_PAGE_BLOCK_UPDATE_ERROR";

/* Delete Landing Page Block*/
export const REQUEST_DELETE_LANDING_PAGE_BLOCK =
  "REQUEST_DELETE_LANDING_PAGE_BLOCK";
export const RECEIVE_DELETE_LANDING_PAGE_BLOCK =
  "RECEIVE_DELETE_LANDING_PAGE_BLOCK";
export const LANDING_PAGE_BLOCK_DELETE_ERROR =
  "LANDING_PAGE_BLOCK_DELETE_ERROR";

/* Update Expert Profile Tag */
export const REQUEST_UPDATE_EXPERT_PROFILE_TAG =
  "REQUEST_UPDATE_EXPERT_PROFILE_TAG";
export const RECEIVE_UPDATE_EXPERT_PROFILE_TAG =
  "RECEIVE_UPDATE_EXPERT_PROFILE_TAG";
export const EXPERT_PROFILE_TAG_UPDATE_ERROR =
  "EXPERT_PROFILE_TAG_UPDATE_ERROR";

/* Update Expert Status Update */
export const REQUEST_UPDATE_EXPERT = "REQUEST_UPDATE_EXPERT";
export const RECEIVE_UPDATE_EXPERT = "RECEIVE_UPDATE_EXPERT";
export const EXPERT_UPDATE_ERROR = "EXPERT_UPDATE_ERROR";

/* Update Project Status */
export const REQUEST_UPDATE_PROJECT = "REQUEST_UPDATE_PROJECT";
export const RECEIVE_UPDATE_PROJECT = "RECEIVE_UPDATE_PROJECT";
export const PROJECT_UPDATE_ERROR = "PROJECT_UPDATE_ERROR";

/* Add Project Category */
export const REQUEST_CREATE_PROJECT_CATEGORY =
  "REQUEST_CREATE_PROJECT_CATEGORY";
export const RECEIVE_CREATE_PROJECT_CATEGORY =
  "RECEIVE_CREATE_PROJECT_CATEGORY";
export const PROJECT_CATEGORY_CREATE_ERROR = "PROJECT_CATEGORY_CREATE_ERROR";

/* Update Project Category */
export const REQUEST_UPDATE_PROJECT_CATEGORY =
  "REQUEST_UPDATE_PROJECT_CATEGORY";
export const RECEIVE_UPDATE_PROJECT_CATEGORY =
  "RECEIVE_UPDATE_PROJECT_CATEGORY";
export const PROJECT_CATEGORY_UPDATE_ERROR = "PROJECT_CATEGORY_UPDATE_ERROR";

/* Delete Project Category */
export const REQUEST_DELETE_PROJECT_CATEGORY =
  "REQUEST_DELETE_PROJECT_CATEGORY";
export const RECEIVE_DELETE_PROJECT_CATEGORY =
  "RECEIVE_DELETE_PROJECT_CATEGORY";
export const PROJECT_CATEGORY_DELETE_ERROR = "PROJECT_CATEGORY_DELETE_ERROR";

/* Fetch user details */
export const REQUEST_USER_DETAIL = "REQUEST_USER_DETAIL";
export const RECEIVE_USER_DETAIL = "RECEIVE_USER_DETAIL";
export const FETCH_USER_DETAIL_FAIL = "FETCH_USER_DETAIL_FAIL";

// Add New User
export const REQUEST_CREATE_NEW_USER = "REQUEST_CREATE_NEW_USER";
export const RECEIVE_CREATE_NEW_USER = "RECEIVE_CREATE_NEW_USER";
export const NEW_USER_CREATE_ERROR = "LANDING_PAGE_CREATE_NEW_USER";

// Update Feedback Status
export const REQUEST_UPDATE_FEEDBACK = "REQUEST_UPDATE_FEEDBACK";
export const RECEIVE_UPDATE_FEEDBACK = "RECEIVE_UPDATE_FEEDBACK";
export const FEEDBACK_UPDATE_ERROR = "EXPERT_UPDATE_ERROR";

/* Project List */
export const REQUEST_PROJECT_LIST = "REQUEST_PROJECT_LIST";
export const RECEIVE_PROJECT_LIST = "RECEIVE_PROJECT_LIST";
export const FETCH_PROJECT_LIST_FAIL = "FETCH_PROJECT_LIST_FAIL";

/* Project Change Page */
export const CHANGE_PROJECT_PAGE = "CHANGE_PROJECT_PAGE";

/* Playbook List */
export const REQUEST_PLAYBOOK_LIST = "REQUEST_PLAYBOOK_LIST";
export const RECEIVE_PLAYBOOK_LIST = "RECEIVE_PLAYBOOK_LIST";
export const RESET_PLAYBOOK_LIST = "RESET_PLAYBOOK_LIST";
export const FETCH_PLAYBOOK_LIST_FAIL = "FETCH_PLAYBOOK_LIST_FAIL";

/* Project Change Page */
export const CHANGE_PLAYBOOK_PAGE = "CHANGE_PLAYBOOK_PAGE";

/* Setting List */
export const REQUEST_SETTINGS_LIST = "REQUEST_SETTINGS_LIST";
export const RECEIVE_SETTINGS_LIST = "RECEIVE_SETTINGS_LIST";
export const RECEIVE_PARTNER_SETTINGS_LIST = "RECEIVE_PARTNER_SETTINGS_LIST";
export const FETCH_PARTNER_SETTINGS_LIST_FAIL =
  "FETCH_PARTNER_SETTINGS_LIST_FAIL";
export const REQUEST_PARTNER_SETTINGS_LIST = "REQUEST_PARTNER_SETTINGS_LIST";
export const FETCH_SETTINGS_LIST_FAIL = "FETCH_SETTINGS_LIST_FAIL";

/* Update playbook Inquiry Update */
export const REQUEST_UPDATE_PLAYBOOK_INQUIRY =
  "REQUEST_UPDATE_PLAYBOOK_INQUIRY";
export const RECEIVE_UPDATE_PLAYBOOK_INQUIRY =
  "RECEIVE_UPDATE_PLAYBOOK_INQUIRY";
export const PLAYBOOK_INQUIRY_UPDATE_ERROR = "PLAYBOOK_INQUIRY_UPDATE_ERROR";

/* Update Deal Update */
export const REQUEST_UPDATE_DEAL = "REQUEST_UPDATE_DEAL";
export const RECEIVE_UPDATE_DEAL = "RECEIVE_UPDATE_DEAL";
export const DEAL_UPDATE_ERROR = "DEAL_UPDATE_ERROR";

/* Update Partner Status */
export const REQUEST_UPDATE_PARTNER = "REQUEST_UPDATE_PARTNER";
export const RECEIVE_UPDATE_PARTNER = "RECEIVE_UPDATE_PARTNER";
export const PARTNER_UPDATE_ERROR = "PARTNER_UPDATE_ERROR";

/* Delete Deal */
export const REQUEST_DELETE_DEAL = "REQUEST_DELETE_DEAL";
export const DEAL_DELETE_ERROR = "DEAL_DELETE_ERROR";

/* ResourceList */
export const REQUEST_RESOURCE_LIST = "REQUEST_RESOURCE_LIST";
export const RECEIVE_RESOURCE_LIST = "RECEIVE_RESOURCE_LIST";
export const RESET_RESOURCE_LIST = "RESET_RESOURCE_LIST";
export const FETCH_RESOURCE_LIST_FAIL = "FETCH_RESOURCE_LIST_FAIL";
export const CHANGE_RESOURCE_PAGE = "CHANGE_RESOURCE_PAGE";

/* Course List */
export const REQUEST_COURSE_LIST = "REQUEST_COURSE_LIST";
export const RECEIVE_COURSE_LIST = "RECEIVE_COURSE_LIST";
export const RESET_COURSE_LIST = "RESET_COURSE_LIST";
export const FETCH_COURSE_LIST_FAIL = "FETCH_COURSE_LIST_FAIL";
export const CHANGE_COURSE_PAGE = "CHANGE_COURSE_PAGE";

/* Delete salesforce lead status mapping */
export const REQUEST_DELETE_SALESFORCE_LEAD_STATUS_MAPPING =
  "REQUEST_DELETE_SALESFORCE_LEAD_STATUS_MAPPING";
export const RECEIVE_DELETE_SALESFORCE_LEAD_STATUS_MAPPING =
  "RECEIVE_DELETE_SALESFORCE_LEAD_STATUS_MAPPING";
export const SALESFORCE_LEAD_STATUS_MAPPING_DELETE_ERROR =
  "SALESFORCE_LEAD_STATUS_MAPPING_DELETE_ERROR";

/* Add salesforce lead status mapping */
export const REQUEST_CREATE_SALESFORCE_LEAD_STATUS_MAPPING =
  "REQUEST_CREATE_SALESFORCE_LEAD_STATUS_MAPPING";
export const RECEIVE_CREATE_SALESFORCE_LEAD_STATUS_MAPPING =
  "RECEIVE_CREATE_SALESFORCE_LEAD_STATUS_MAPPING";
export const SALESFORCE_LEAD_STATUS_MAPPING_CREATE_ERROR =
  "SALESFORCE_LEAD_STATUS_MAPPING_CREATE_ERROR";

/* Update salesforce lead status mapping */
export const REQUEST_UPDATE_SALESFORCE_LEAD_STATUS_MAPPING =
  "REQUEST_UPDATE_SALESFORCE_LEAD_STATUS_MAPPING";
export const RECEIVE_UPDATE_SALESFORCE_LEAD_STATUS_MAPPING =
  "RECEIVE_UPDATE_SALESFORCE_LEAD_STATUS_MAPPING";
export const SALESFORCE_LEAD_STATUS_MAPPING_UPDATE_ERROR =
  "SALESFORCE_LEAD_STATUS_MAPPING_UPDATE_ERROR";

/* Add salesforce lead field mapping */
export const REQUEST_CREATE_SALESFORCE_LEAD_FIELD_MAPPING =
  "REQUEST_CREATE_SALESFORCE_LEAD_FIELD_MAPPING";
export const RECEIVE_CREATE_SALESFORCE_LEAD_FIELD_MAPPING =
  "RECEIVE_CREATE_SALESFORCE_LEAD_FIELD_MAPPING";
export const SALESFORCE_LEAD_FIELD_MAPPING_CREATE_ERROR =
  "SALESFORCE_LEAD_FIELD_MAPPING_CREATE_ERROR";

/* Update salesforce lead field mapping */
export const REQUEST_UPDATE_SALESFORCE_LEAD_FIELD_MAPPING =
  "REQUEST_UPDATE_SALESFORCE_LEAD_FIELD_MAPPING";
export const RECEIVE_UPDATE_SALESFORCE_LEAD_FIELD_MAPPING =
  "RECEIVE_UPDATE_SALESFORCE_LEAD_FIELD_MAPPING";
export const SALESFORCE_LEAD_FIELD_MAPPING_UPDATE_ERROR =
  "SALESFORCE_LEAD_FIELD_MAPPING_UPDATE_ERROR";

/* Delete salesforce lead field mapping */
export const REQUEST_DELETE_SALESFORCE_LEAD_FIELD_MAPPING =
  "REQUEST_DELETE_SALESFORCE_LEAD_FIELD_MAPPING";
export const RECEIVE_DELETE_SALESFORCE_LEAD_FIELD_MAPPING =
  "RECEIVE_DELETE_SALESFORCE_LEAD_FIELD_MAPPING";
export const SALESFORCE_LEAD_FIELD_MAPPING_DELETE_ERROR =
  "SALESFORCE_LEAD_FIELD_MAPPING_DELETE_ERROR";

/* Add hubspot field mapping */
export const REQUEST_CREATE_HUBSPOT_FIELD_MAPPING =
  "REQUEST_CREATE_HUBSPOT_FIELD_MAPPING";
export const RECEIVE_CREATE_HUBSPOT_FIELD_MAPPING =
  "RECEIVE_CREATE_HUBSPOT_FIELD_MAPPING";
export const HUBSPOT_FIELD_MAPPING_CREATE_ERROR =
  "HUBSPOT_FIELD_MAPPING_CREATE_ERROR";

/* Update hubspot field mapping */
export const REQUEST_UPDATE_HUBSPOT_FIELD_MAPPING =
  "REQUEST_UPDATE_HUBSPT_FIELD_MAPPING";
export const RECEIVE_UPDATE_HUBSPOT_FIELD_MAPPING =
  "RECEIVE_UPDATE_HUBSPT_FIELD_MAPPING";
export const HUBSPOT_FIELD_MAPPING_UPDATE_ERROR =
  "HUBSPOT_FIELD_MAPPING_UPDATE_ERROR";

/* Delete hubspot field mapping */
export const REQUEST_DELETE_HUBSPOT_FIELD_MAPPING =
  "REQUEST_DELETE_HUBSPOT_FIELD_MAPPING";
export const RECEIVE_DELETE_HUBSPOT_FIELD_MAPPING =
  "RECEIVE_DELETE_HUBSPOT_FIELD_MAPPING";
export const HUBSPOT_FIELD_MAPPING_DELETE_ERROR =
  "HUBSPOT_FIELD_MAPPING_DELETE_ERROR";

/* Delete referralLink */
export const REQUEST_DELETE_REFERRAL_LINK = "REQUEST_DELETE_REFERRAL_LINK";
export const RECEIVE_DELETE_REFERRAL_LINK = "RECEIVE_DELETE_REFERRAL_LINK";
export const REFERRAL_LINK_DELETE_ERROR = "REFERRAL_LINK_DELETE_ERROR";

/* Add referralLink */
export const REQUEST_CREATE_REFERRAL_LINK = "REQUEST_CREATE_REFERRAL_LINK";
export const RECEIVE_CREATE_REFERRAL_LINK = "RECEIVE_CREATE_REFERRAL_LINK";
export const REFERRAL_LINK_CREATE_ERROR = "REFERRAL_LINK_CREATE_ERROR";

/* Update referralLinks */
export const REQUEST_UPDATE_REFERRAL_LINK = "REQUEST_UPDATE_REFERRAL_LINK";
export const RECEIVE_UPDATE_REFERRAL_LINK = "RECEIVE_UPDATE_REFERRAL_LINK";
export const REFERRAL_LINK_UPDATE_ERROR = "REFERRAL_LINK_UPDATE_ERROR";

/* Update referral */
export const REQUEST_UPDATE_REFERRAL = "REQUEST_UPDATE_REFERRAL";
export const RECEIVE_UPDATE_REFERRAL = "RECEIVE_UPDATE_REFERRAL";
export const REFERRAL_UPDATE_ERROR = "REFERRAL_UPDATE_ERROR";

/* Delete referralLink */
export const REQUEST_DELETE_REFERRAL = "REQUEST_DELETE_REFERRAL";
export const RECEIVE_DELETE_REFERRAL = "RECEIVE_DELETE_REFERRAL";
export const REFERRAL_DELETE_ERROR = "REFERRAL_DELETE_ERROR";

/* Delete Referral Status */
export const REQUEST_DELETE_REFERRAL_STATUS = "REQUEST_DELETE_REFERRAL_STATUS";
export const RECEIVE_DELETE_REFERRAL_STATUS = "RECEIVE_DELETE_REFERRAL_STATUS";
export const REFERRAL_STATUS_DELETE_ERROR = "REFERRAL_STATUS_DELETE_ERROR";

/* Add Referral Status */
export const REQUEST_CREATE_REFERRAL_STATUS = "REQUEST_CREATE_REFERRAL_STATUS";
export const RECEIVE_CREATE_REFERRAL_STATUS = "RECEIVE_CREATE_REFERRAL_STATUS";
export const REFERRAL_STATUS_CREATE_ERROR = "REFERRAL_STATUS_CREATE_ERROR";

/* Update Referral Status */
export const REQUEST_UPDATE_REFERRAL_STATUS = "REQUEST_UPDATE_REFERRAL_STATUS";
export const RECEIVE_UPDATE_REFERRAL_STATUS = "RECEIVE_UPDATE_REFERRAL_STATUS";
export const REFRRAL_STATUS_UPDATE_ERROR = "REFRRAL_STATUS_UPDATE_ERROR";

/* Update Company */
export const REQUEST_UPDATE_COMPANY = "REQUEST_UPDATE_COMPANY";
export const RECEIVE_UPDATE_COMPANY = "RECEIVE_UPDATE_COMPANY";
export const COMPANY_UPDATE_ERROR = "COMPANY_UPDATE_ERROR";

export const FETCH_APP_LIST = "FETCH_APP_LIST";
export const FETCH_APP_LIST_FAIL = "FETCH_APP_LIST_FAIL";

export const REQUEST_THEME_SETTINGS_LIST = "REQUEST_THEME_SETTINGS_LIST";
export const RECEIVE_THEME_SETTINGS_LIST = "RECEIVE_THEME_SETTINGS_LIST";
export const FETCH_THEME_SETTINGS_LIST_FAIL = "FETCH_THEME_SETTINGS_LIST_FAIL";
