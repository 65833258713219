import React from "react";

// Page Components
import LandingPageBlocksForm from "./LandingPageBlocksForm";

const LandingPageBlocksDetails = props => {
  return (
    <div>
      <LandingPageBlocksForm history={props.history} match={props.match} />
    </div>
  );
};

export default LandingPageBlocksDetails;
