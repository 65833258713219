import React from "react";

// Page Components
import UpdatePlaybookForm from "./UpdatePlaybookForm";

const PlaybookDetails = props => {
  return (
    <div>
      <UpdatePlaybookForm history={props.history} match={props.match} />
    </div>
  );
};

export default PlaybookDetails;
