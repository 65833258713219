import React from "react";
import { Col, Row } from "reactstrap";
import _ from "lodash";

// Page components
import NoRecordsFound from "../../../components/base/NoRecordsFound";
import ResourceCardGrid from "./ResourceCardGrid";

// Actions
import { fetchResourceList } from "../../../actions/resource";

// Configs
import { endpoints } from "../../../configs";

// API call
import { apiClient } from "../../../apiClient";
import { isBadRequest } from "../../../common/http";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isPartner,
  isCompanyManager,
  getParamsByName
} from "../../../lib/helper";

// Components
import Form from "../../../components/base/Form";
import PageSearch from "../../../components/base/PageSearch";
import Spinner from "../../../components/base/Spinner";
import Pagination from "../../../components/base/Pagination";
import SelectDropdown from "../../../components/base/SelectDropdown";
import CheckboxDropdownSelect from "../../../components/base/CheckboxDropdownSelect";

// Asset
import sourcingIcon from "../../../assets/img/icons/nav-icon-sourcing.svg";

// 404 Page Redirect
import Page404 from "../../Page404";

// Constants
import { TAG_TYPE_RESOURCE_CATEGORY } from "../../../tagType/Constants";

import { connect } from "react-redux";
import PageSize from "../../../components/PageSize";
import {
  DEFAULT_CARD_PAGE_SIZE,
  cardPageSizeOption
} from "../../../helpers/Page";

class ResourceCardView extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchParam: "",
      status: "",
      currentPage: 1,
      pageSize: DEFAULT_CARD_PAGE_SIZE,
      category: "",
      categoryOpt: [],
      isLoading: false,
      categoryObj: {},
      selectedSortOption: "Most Recent",
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "title:ASC",
          label: "Title"
        }
      ]
    };
  }

  componentDidMount() {
    const searchParam = getParamsByName("search") || "";
    const sort = getParamsByName("sort") || "";
    const sortDir = getParamsByName("sortDir") || "";
    this.setState(
      { currentPage: 1, searchParam: searchParam, isLoading: true },
      () => {
        this.props.actions.fetchResourceList(
          this.updateUrl({
            status: this.props.activeTab,
            pageSize: this.state.pageSize,
            search: encodeURIComponent(searchParam),
            page: 1,
            sort: sort,
            sortDir: sortDir
          })
        );
        this.getTagValues(TAG_TYPE_RESOURCE_CATEGORY);
      }
    );
    this.pushQueryToUrl();
  }

  componentDidUpdate(prevProps) {
    const searchParam = getParamsByName("search") || "";
    if (prevProps.activeTab !== this.props.activeTab) {
      this.setState({
        currentPage: 1,
        categoryObj: [],
        category: "",
        isLoading: true
      });
      this.props.actions
        .fetchResourceList(
          this.updateUrl({
            status: this.props.activeTab,
            pageSize: this.state.pageSize,
            search: encodeURIComponent(searchParam),
            page: 1
          })
        )
        .then(() => {
          this.setState({
            isLoading: false
          });
        });
    }
  }

  // Search Query string
  pushQueryToUrl = () => {
    const { searchParam, sort, sortDir, category, pageSize } = this.state;
    const { activeTab } = this.props;
    const params = [];

    if (searchParam) {
      params.push(`search=${searchParam}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    if (category) {
      params.push(`category=${category}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }
    this.props.history.push(
      `/resources?section=${activeTab}&${params.join("&")}`
    );
  };
  // Search resource
  _handleInputChange = e => {
    let categoryId = [];
    this.setState(
      {
        searchParam: e.target.value
      },
      () => {
        const { searchParam, category } = this.state;

        let categoryList = [];
        categoryList = category.split(",");
        categoryId = this.getCategoryId(categoryList);

        const sort = getParamsByName("sort") || "";
        const sortDir = getParamsByName("sortDir") || "";
        const params = {
          status: this.props.activeTab,
          search: encodeURIComponent(searchParam),
          pageSize: this.state.pageSize,
          sort: sort,
          sortDir: sortDir,
          category: categoryId ? categoryId : []
        };
        this.pushQueryToUrl();
        this.props.actions.fetchResourceList(this.updateUrl(params));
        this.props.getSearchParam(searchParam);
      }
    );
  };

  // Search playbook by status
  _handleStatusChange(searchStatus) {
    this.setState({ status: searchStatus, activeTab: searchStatus }, () => {
      this.props.actions.fetchResourceList(
        this.updateUrl({
          status: searchStatus,
          page: this.state.currentPage,
          pageSize: this.state.pageSize
        })
      );
    });
  }

  // Form URL
  updateUrl(data) {
    const { search, page, pageSize, sort, sortDir, status, category } = data;

    const params = [];

    if (search) {
      params.push(`search=${search}`);
    }

    if (status) {
      params.push(`status=${status}`);
    }

    if (page) {
      params.push(`page=${page}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    if (category) {
      params.push(`category=${category}`);
    }

    if (params.length > 0) {
      return params.join("&");
    }
  }

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  handleSortByChange = value => {
    const valueArray = this.getSortValueFromLabel(value).split(":");
    const sortBy = valueArray[0];
    const sortDir = valueArray[1];
    let categoryId = [];
    this.setState(
      {
        sort: sortBy,
        sortDir: sortDir,
        currentPage: 1
      },
      () => {
        const {
          searchParam,
          sort,
          sortDir,
          currentPage,
          pageSize,
          category
        } = this.state;

        let categoryList = [];
        categoryList = category.split(",");
        categoryId = this.getCategoryId(categoryList);

        this.pushQueryToUrl();
        this.props.actions.fetchResourceList(
          this.updateUrl({
            status: this.props.activeTab,
            sort: sort,
            sortDir: sortDir,
            category: categoryId ? categoryId : [],
            page: currentPage,
            pageSize: pageSize,
            search: searchParam
          })
        );
      }
    );
  };

  // Handle Page Change
  handlePageChange = page => {
    this.setState(
      {
        currentPage: page
      },
      () => {
        const { actions, resource } = this.props;
        let { category } = this.state;

        if (
          !(page in resource.pages) ||
          resource.statuses[page] !== this.props.activeTab
        ) {
          let categoryList = [];
          let categoryId = [];
          categoryList = category.split(",");
          categoryId = this.getCategoryId(categoryList);
          const searchParam = getParamsByName("search") || "";

          actions.fetchResourceList(
            this.updateUrl({
              sort: this.state.sort,
              sortDir: this.state.sortDir,
              status: this.props.activeTab,
              search: searchParam,
              page: page,
              pageSize: this.state.pageSize,
              category: categoryId ? categoryId : []
            })
          );
        } else {
          actions.changePlaybookPage(page);
        }
      }
    );
    this.props.getSearchParam(this.state.searchParam, page);
  };

  // Get Tag Values
  getTagValues = async tagType => {
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${tagType}`
      );
      const results = response.data.data;
      // Check the tag type skills
      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });
        this.setState({
          categoryOpt: tagList,
          isLoading: false
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        this.setState({ isLoading: false });
        console.error(errorMessage);
      }
    }
  };

  // Get category id
  getCategoryId = value => {
    let selectedCategory = value ? value : value && value[0] ? value[0] : [];
    let { categoryOpt } = this.state;
    let ids = [];
    categoryOpt.forEach(result => {
      selectedCategory.forEach(elemenet => {
        if (result.name === elemenet) {
          ids.push(result.id);
        }
      });
    });
    return ids;
  };

  // Handle category change
  handleCategoryChange = values => {
    let categoryObj = values;
    let category = "",
      categoryArray = [],
      categoryId = [];
    const selectedCategoryArrays = Object.entries(categoryObj);

    if (selectedCategoryArrays.length > 0) {
      selectedCategoryArrays.forEach(async selectedCategoryArray => {
        if (selectedCategoryArray[1] === true) {
          categoryArray.push(selectedCategoryArray[0]);
        }
      });
    }

    if (categoryArray.length > 0) {
      categoryId = this.getCategoryId(categoryArray);
      category = categoryArray.join();
    }

    this.setState(
      { category: category ? category : "", categoryObj, currentPage: 1 },
      () => {
        const searchParam = getParamsByName("search") || "";
        const sort = getParamsByName("sort") || "";
        const sortDir = getParamsByName("sortDir") || "";
        this.pushQueryToUrl();
        this.props.actions.fetchResourceList(
          this.updateUrl({
            search: searchParam,
            sort: sort,
            sortDir: sortDir,
            status: this.props.activeTab,
            category: categoryId ? categoryId : [],
            page: this.state.currentPage,
            pageSize: this.state.pageSize
          })
        );
      }
    );
  };

  handlePageSizeChange = e => {
    this.setState({ pageSize: e, currentPage: this.state.currentPage }, () => {
      const searchParam = getParamsByName("search") || "";
      const sort = getParamsByName("sort") || "";
      const sortDir = getParamsByName("sortDir") || "";
      this.pushQueryToUrl();
      this.props.actions.fetchResourceList(
        this.updateUrl({
          search: searchParam,
          sort: sort,
          sortDir: sortDir,
          status: this.props.activeTab,
          page: this.state.currentPage,
          pageSize: e
        })
      );
    });
  };

  render() {
    // Props
    const { resource, resourceDetails, activeTab } = this.props;

    // State
    const {
      searchParam,
      currentPage,
      pageSize,
      selectedSortOption,
      sortByOptions,
      categoryOpt,
      isLoading,
      categoryObj
    } = this.state;

    let resourceList;
    let pagination;
    let totalCount;
    if (resource && resource.pagination) {
      pagination = resource.pagination;
      resourceList = resource.pages[pagination.currentPage];
      totalCount = pagination.totalCount;
    }

    const params = {
      page: currentPage,
      pageSize: pageSize,
      status: activeTab,
      sort: selectedSortOption,
      searchParam: searchParam
    };

    // headers for Export
    const headers = [
      { label: "Name", key: "title" },
      { label: "Status", key: "status" },
      { label: "Category", key: "primaryCategory" }
    ];

    const dataArray = [];

    const adminPlaybookDetails = resourceDetails || {};
    Object.keys(resourceList || {}).forEach(key => {
      const data = adminPlaybookDetails[resourceList[key]];
      if (data) {
        dataArray.push(data);
      }
    });

    let startPage = 1;
    let endPage = "";

    startPage = (currentPage - 1) * pageSize;
    startPage = startPage > totalCount ? totalCount : startPage;

    endPage = currentPage * pageSize;
    endPage = endPage > totalCount ? totalCount : endPage;

    const resources = totalCount > 1 ? dataArray : dataArray;

    // Return Page 404
    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId) &&
      !isCompanyManager(this.props.roleId) &&
      !isPartner(this.props.roleId)
    ) {
      return <Page404 />;
    }

    return (
      <div>
        <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column">
          <div className="mr-1">
            <PageSize
              onChange={e => this.handlePageSizeChange(e)}
              customPageSizeOption={cardPageSizeOption}
            />
          </div>
          <PageSearch
            classnames="page-search"
            placeholder={`Search`}
            onChange={this._handleInputChange.bind(this)}
            value={this.state.searchParam}
          />

          <div className="mr-2">
            <Form>
              <CheckboxDropdownSelect
                id="resourceCategory"
                name="category"
                buttonLabel="Category"
                dropdownLinks={categoryOpt}
                handleChange={e => {
                  this.handleCategoryChange(e);
                }}
                checkedItems={categoryObj}
                color="gray"
                hideCaret
              />
            </Form>
          </div>

          <SelectDropdown
            buttonLabel={selectedSortOption}
            dropdownLinks={sortByOptions}
            sortByOptions={this.state.sortByOptions}
            color={"gray"}
            hideCaret
            selectName={"sortby_partners"}
            handleChange={this.handleSortByChange}
          />
        </div>
        {/* Card List */}
        <div>
          {isLoading ? (
            <Spinner />
          ) : totalCount ? (
            <div
              className="mx-2"
              style={{
                width: "100%",
                marginRight: "auto"
              }}
            >
              <div className="mx-2">
                <ResourceCardGrid
                  resources={resources}
                  params={params}
                  history={this.props.history}
                />
              </div>
              {/* Show the Pagination */}
              {totalCount > 0 && (
                <Row>
                  <Col>
                    Showing {startPage + 1} to {endPage} of {totalCount} entries
                  </Col>
                  <Col>
                    <Pagination
                      currentPage={currentPage}
                      totalCount={totalCount}
                      pageSize={pageSize}
                      onPageChange={this.handlePageChange}
                    />
                  </Col>
                </Row>
              )}
            </div>
          ) : searchParam ? (
            !totalCount ? (
              <NoRecordsFound
                icon={sourcingIcon}
                hideCard={true}
                boldMessage={
                  isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)
                    ? `Resources is where you can select content and materials to share with Partners.`
                    : `No Resources have been shared with you yet.`
                }
                description={
                  isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)
                    ? `Get started by selecting “Add Resource”.`
                    : `This is where content and materials shared with you will be available.`
                }
              />
            ) : (
              ""
            )
          ) : (
            <NoRecordsFound
              hideCard={true}
              icon={sourcingIcon}
              boldMessage={
                isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)
                  ? `Resources is where you can select content and materials to share with Partners.`
                  : `No Resources have been shared with you yet.`
              }
              description={
                isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)
                  ? `Get started by selecting “Add Resource”.`
                  : `This is where content and materials shared with you will be available.`
              }
            />
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(ResourceCardView);
