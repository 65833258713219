import React, { Component } from "react";
import { Label, Input } from "reactstrap";

class Checkbox extends Component {
  render() {
    const { selectedOptions, options, name, title, handleChange } = this.props;
    return (
      <>
        <label htmlFor={name} className="form-label">
          {title}
        </label>
        {options.map(option => {
          return (
            <div className="checkbox-wrapper">
              <Label key={option.id}>
                <Input
                  id={name}
                  name={name}
                  onChange={handleChange}
                  value={option.id}
                  checked={selectedOptions.indexOf(option.id) > -1}
                  type="checkbox"
                />{" "}
                <span
                  className={`checkbox-placeholder ${selectedOptions.filter(
                    s => s === option.id
                  ).length > 0 && "active"}`}
                />
                <span>{option.description}</span>
              </Label>
            </div>
          );
        })}
      </>
    );
  }
}

export default Checkbox;
