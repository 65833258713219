import React from "react";
import AvatarCard from "../../../components/base/AvatarCard";
import { Collapse } from "reactstrap";

function Table(props) {
  const { data, toggleCollapse, isCollapsed } = props;
  return (
    <div>
      <div
        className="company-name  row justify-content-between mr-0 ml-0 mb-0"
        onClick={() => toggleCollapse(data && data.partner_id)}
        style={{
          cursor: "pointer"
        }}
      >
        <div
          className="d-flex justify-content-between mx-2 mt-2 pb-0"
          style={{ width: "90%" }}
        >
          <div>
            <AvatarCard
              companyName={data && data.partnerName}
              url={data && data.avatarUrl}
            />
          </div>
          <div className="mt-1 ml-2 pt-1">
            {data && data.courseCompletedPartnerUsersCount}/
            {data && data.partnerUserCount} Users Completed
          </div>
        </div>
        {isCollapsed[data && data.partner_id] ? (
          <span className="company-name fa fa-chevron-up "></span> // Up arrow (open)
        ) : (
          <span className="company-name fa fa-chevron-down "></span> // Down arrow (closed)
        )}
      </div>
      <Collapse isOpen={isCollapsed[data && data.partner_id] || false}>
        <div
          style={{
            backgroundColor: "#e7e7ea",
            marginBottom: "-20px",
            paddingTop: "1px",
            marginTop: "4px",
            paddingBottom: "7px"
          }}
        >
          {data &&
            data.partnerUser.map((value, index) => (
              <div className="row mx-5 mt-2 pb-0" key={index}>
                <AvatarCard companyName={value.name} url={value.avatarUrl} />
                <div className="mt-1 ml-2 pt-1">
                  {": "}
                  {value.totalCount}/{value.lessonCount} lesson Completed
                </div>
              </div>
            ))}
        </div>
      </Collapse>
    </div>
  );
}

export default Table;
