import React from "react";

// Icons
import { WorldIcon } from "../../assets/img/icons";

// Components
import Text from "../../components/base/Text";
import Zipcode from "../../components/base/Zipcode";
import SecondaryButton from "../../components/base/SecondaryButton";
import State from "../../components/State";
import Country from "../../components/base/Country";
import TimeZone from "../../components/base/TimeZone";

// Constants
import { CANADA, UNITED_STATES } from "../../Country/Constants";

class Location extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptTerms: false,
      required: false,
      selectedOption: "",
      selectedCountryName: ""
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  // Handle Country
  handleCountryChange = ({ values }) => {
    const selectedOption = values.country && values.country.id;
    const selectedCountryName = values.country && values.country.label;
    this.setState({
      selectedOption,
      selectedCountryName: selectedCountryName
    });
  };

  render() {
    if (this.props.currentStep !== this.props.step) {
      // Prop: The current step
      return null;
    }

    const { selectedCountry } = this.props;

    const { required, selectedOption, selectedCountryName } = this.state;

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <WorldIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-3">Location</h2>
        </div>
        <div className="content">
          <div className="field-wrapper">
            <Text
              autoComplete="nope"
              name="address1"
              label="Address 1"
              placeholder="Enter address 1"
              error=""
              {...{ required }}
            />
          </div>

          <div className="field-wrapper">
            <Text
              autoComplete="nope"
              name="address2"
              label="Address 2"
              placeholder="Enter address 2"
            />
          </div>

          <div className="field-wrapper">
            <Text
              name="city"
              label="City"
              placeholder="Enter city"
              error=""
              {...{ required }}
            />
          </div>
          <div className="field-wrapper">
            <State
              name="state"
              label="State/Province/Region"
              placeholder="Enter State"
              error=""
              selectedCountry={
                this.state.selectedOption
                  ? this.state.selectedOption
                  : this.props.selectedCountry
              }
              selectedCountryName={
                this.props.selectedCountry ? this.props.selectedCountry : ""
              }
            />

            <Country
              name="country"
              label="Country"
              placeholder="Select Country"
              error=""
              {...{ required }}
              onChange={this.handleCountryChange}
            />
          </div>

          <div className="field-wrapper">
            <Zipcode
              name="zipcode"
              label="Zip/Postal Code"
              placeholder="Enter Zipcode"
              error=""
              locale={selectedCountryName ? selectedCountryName : UNITED_STATES}
            />

            <TimeZone
              name="timezone"
              label="Timezone"
              placeholder="Select Timezone"
            />
          </div>

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton
              className="mr-4"
              label="Go Back"
              onClick={this.props.prev}
            />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={() => {
                this.setState({ required: true });
                return this.props.next;
              }}
            >
              Next: Description
            </button>
          </div>
          <p className="text-inline-grayed h7 mt-3">
            Note: Providing your location helps us coordinate schedules with you
            and customers.
          </p>
        </div>
      </div>
    );
  }
}

export default Location;
