import React, { useState, useEffect } from "react";
// Configs
import { endpoints } from "../../../../configs";
// API Client
import { apiClient } from "../../../../apiClient";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import Text from "../../../../components/base/Text";
import Country from "../../../../components/base/Country";
import State from "../../../../components/State";
import Zipcode from "../../../../components/base/Zipcode";
import Phone from "../../../../components/base/Phone";
import SaveButton from "../../../../components/base/SaveButton";
import URL from "../../../../components/base/URL";

// Constants
import {
  SETTINGS_PORTAL_COMPANY_NAME,
  SETTINGS_PORTAL_COMPANY_ADDRESS,
  SETTINGS_PORTAL_COMPANY_CITY,
  SETTINGS_PORTAL_COMPANY_STATE,
  SETTINGS_PORTAL_COMPANY_COUNTRY,
  SETTINGS_PORTAL_COMPANY_ZIP_CODE,
  SETTINGS_PORTAL_COMPANY_PHONE,
  SETTINGS_PORTAL_COMPANY_WEBSITE
} from "../../../../setting/Constants";

// Helper
import { toString, removeMaskedPhoneNumber } from "../../../../lib/helper";

const AddressSection = props => {
  const { adminSettings, saveSettings } = props;
  const [selectedOption, setSelectedOption] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState(
    props.adminSettings.marketplace_company_country
  );
  const [selectedCountry, setSelectedCountry] = useState("");

  const {
    marketplace_company_name,
    marketplace_company_address,
    marketplace_company_zip_code,
    marketplace_company_city,
    marketplace_company_state,
    marketplace_company_country,
    marketplace_company_phone,
    marketplace_company_website
  } = adminSettings;

  useEffect(() => {
    getCountryDetails(selectedCountryName);
  }, [selectedCountryName, selectedOption, selectedCountryName]);

  // Handle Country
  const handleCountryChange = ({ values }) => {
    const selectedOption =
      values.marketplace_company_country &&
      values.marketplace_company_country.id;
    const selectedCountryName =
      values.marketplace_company_country &&
      values.marketplace_company_country.label;
    setSelectedOption(selectedOption);
    setSelectedCountryName(selectedCountryName);
  };

  // Get Country Details
  const getCountryDetails = countryName => {
    return apiClient
      .get(`${endpoints().countryAPI}/${countryName}`)
      .then(response => {
        const data = response.data;
        setSelectedCountry(data.id);
      });
  };
  // General Settings Address Section Initial Values
  const generalSettingsAddressSectionInitialValues = {
    marketplace_company_name,
    marketplace_company_address,
    marketplace_company_zip_code,
    marketplace_company_city,
    marketplace_company_state:
      marketplace_company_state && marketplace_company_state !== "null"
        ? {
            label: marketplace_company_state,
            value: marketplace_company_state
          }
        : "",
    marketplace_company_country:
      marketplace_company_country && marketplace_company_country !== "null"
        ? {
            label: marketplace_company_country,
            value: marketplace_company_country
          }
        : "",
    marketplace_company_phone,
    marketplace_company_website
  };

  // General Settings Address Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Marketplace company name
    if (values.marketplace_company_name !== undefined) {
      data.append(
        "marketplace_company_name",
        values.marketplace_company_name ? values.marketplace_company_name : ""
      );
    }

    // Marketplace company address
    if (values.marketplace_company_address !== undefined) {
      data.append(
        "marketplace_company_address",
        values.marketplace_company_address
          ? values.marketplace_company_address
          : ""
      );
    }

    // Marketplace company city
    if (values.marketplace_company_city !== undefined) {
      data.append(
        "marketplace_company_city",
        values.marketplace_company_city ? values.marketplace_company_city : ""
      );
    }

    // Marketplace company state
    if (values.marketplace_company_state !== undefined) {
      data.append(
        "marketplace_company_state",
        toString(
          values.marketplace_company_state &&
            typeof values.marketplace_company_state === "object"
            ? values.marketplace_company_state.value
            : values.marketplace_company_state
        )
      );
    }

    // Marketplace company country
    if (values.marketplace_company_country !== undefined) {
      data.append(
        "marketplace_company_country",
        toString(
          values.marketplace_company_country &&
            typeof values.marketplace_company_country === "object"
            ? values.marketplace_company_country.label
            : values.marketplace_company_country
        )
      );
    }

    // Marketplace company zipcode
    if (values.marketplace_company_zip_code !== undefined) {
      data.append(
        "marketplace_company_zip_code",
        values.marketplace_company_zip_code
          ? values.marketplace_company_zip_code
          : ""
      );
    }

    // Marketplace company phone
    if (values.marketplace_company_phone !== undefined) {
      data.append(
        "marketplace_company_phone",
        values.marketplace_company_phone
          ? removeMaskedPhoneNumber(values.marketplace_company_phone)
          : ""
      );
    }
    // Marketplace company website
    if (values.marketplace_company_website !== undefined) {
      data.append(
        "marketplace_company_website",
        values.marketplace_company_website
          ? values.marketplace_company_website
          : ""
      );
    }

    // Save settings
    saveSettings(data);
  };
  return (
    <Form
      initialValues={generalSettingsAddressSectionInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          {/* Marketplace Company Name */}
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_COMPANY_NAME}
              label="Company Name"
              placeholder="Enter Marketplace Company Name"
              required={true}
            />
          </div>

          {/* Marketplace Company Address */}
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_COMPANY_ADDRESS}
              label="Address"
              placeholder="Enter Marketplace Company Address"
              required={true}
            />
            <Text
              name={SETTINGS_PORTAL_COMPANY_CITY}
              label="City"
              placeholder="Enter Marketplace Company City"
              required={true}
            />
          </div>
          <div className="field-wrapper">
            <State
              name={SETTINGS_PORTAL_COMPANY_STATE}
              label="State/Province/Region"
              placeholder="Enter State"
              selectedCountry={
                selectedOption ? selectedOption : selectedCountry
              }
              selectedCountryName={
                selectedCountryName ? selectedCountryName : ""
              }
              required
            />
            <Country
              name={SETTINGS_PORTAL_COMPANY_COUNTRY}
              label="Country"
              placeholder="Enter Country"
              onChange={handleCountryChange}
              required
            />
          </div>
          <div className="field-wrapper">
            <Zipcode
              name={SETTINGS_PORTAL_COMPANY_ZIP_CODE}
              label="Zip/Postal Code"
              placeholder="Enter Zip"
              required={true}
            />
            <Phone
              name={SETTINGS_PORTAL_COMPANY_PHONE}
              label="Phone Number"
              placeholder="Phone number..."
            />
          </div>
          <div className="field-wrapper">
            <URL
              name={SETTINGS_PORTAL_COMPANY_WEBSITE}
              label="Website Url"
              placeholder=" Enter Website Url"
            />
          </div>
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default AddressSection;
