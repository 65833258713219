import React, { Component } from "react";

// Components
import Form from "../../components/base/Form";
import Email from "../../components/base/Email";
import Text from "../../components/base/Text";
import RadioButton from "../../components/base/RadioButton";
import Checkbox from "../../components/base/Checkbox";
import Button from "../../components/base/Button";

class ExpertAvailabilityForm extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkBox: {
        highlyRequestedAreas: [],
        interestedArea: []
      },
      availabilityOptions: ["Available for work", "Unavailable for work"],
      levelOfBandwidthOptions: [
        "+/- 10 hours per week",
        "+/- 20 hours per week",
        "+/- 30 hours per week",
        "40 hours per week"
      ],
      salesforceCertificationsOptions: ["Yes", "No"],
      highlyRequestedAreasOptions: [
        "Salesforce Marketing Cloud - Technical Architect",
        "Pardot",
        "Datorama",
        "Salesforce Sales Cloud",
        "Adobe Experience Manager"
      ],
      interestedAreasOption: [
        "Paid Advertising",
        "Social Channel Management / Strategy",
        "UX / UI",
        "SEO",
        "Content",
        "Other"
      ]
    };

    this.handleHighlyRequestedAreasCheckbox = this.handleHighlyRequestedAreasCheckbox.bind(
      this
    );

    this.handleInterestedAreaCheckbox = this.handleInterestedAreaCheckbox.bind(
      this
    );
  }

  // Handle highly requested areas
  handleHighlyRequestedAreasCheckbox(e) {
    const newSelection = e.target.value;
    let newSelectionArray;

    if (this.state.checkBox.highlyRequestedAreas.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.highlyRequestedAreas.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.highlyRequestedAreas,
        newSelection
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        highlyRequestedAreas: newSelectionArray
      }
    }));
  }

  // Handle interested areas
  handleInterestedAreaCheckbox(e) {
    const newSelection = e.target.value;

    let newSelectionArray;

    if (this.state.checkBox.interestedArea.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.interestedArea.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [...this.state.checkBox.interestedArea, newSelection];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        interestedArea: newSelectionArray
      }
    }));
  }

  render() {
    let { email } = this.props;

    const initialValues = {
      email: "",
      availability: ""
    };

    const renderExpertAvailabilityForm = (
      <div className={"expert-availability-form-content"}>
        <div>
          <Text
            name="firstName"
            label="First Name"
            placeholder="Enter first name"
          />
        </div>

        <div>
          <Text
            name="lastName"
            label="Last Name"
            placeholder="Enter last name"
          />
        </div>

        <div>
          <Email
            name="email"
            label="What email address can we reach you at?"
            placeholder="Enter email"
            value={email}
            required
          />
        </div>

        <div>
          <RadioButton
            label="I am currently:"
            name="availability"
            required={true}
            options={this.state.availabilityOptions}
            className="radio-btn-section"
          />
        </div>

        <div>
          <RadioButton
            label="What level of bandwidth can you offer to new projects?"
            name="availabilityBandwidth"
            options={this.state.levelOfBandwidthOptions}
          />
        </div>

        <div>
          <Text
            name="expectedAvailability"
            label="When do you expect to become available?"
            placeholder="Ex: by November"
          />
        </div>

        <div>
          <Checkbox
            title="Are you an expert in any of these highly-requested areas?"
            name="highlyRequestedArea"
            options={this.state.highlyRequestedAreasOptions}
            selectedOptions={this.state.checkBox.highlyRequestedAreas}
            handleChange={this.handleHighlyRequestedAreasCheckbox}
          />
        </div>

        <div>
          <RadioButton
            label="Do you have any active Salesforce Certifications?"
            name="salesforceCertification"
            options={this.state.salesforceCertificationsOptions}
          />
        </div>

        <div>
          <Checkbox
            title="Which of these types of projects should we keep you in mind for?"
            name="interestedArea"
            options={this.state.interestedAreasOption}
            selectedOptions={this.state.checkBox.interestedArea}
            handleChange={this.handleInterestedAreaCheckbox}
          />
        </div>

        <div style={{ marginBottom: "80px" }}>
          <div className="text-center">
            <Button type="submit" label="Submit" />
          </div>
        </div>
      </div>
    );

    return (
      <div>
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            values.firstName = values.firstName ? values.firstName : null;
            values.lastName = values.lastName ? values.lastName : null;
            values.email = values.email ? values.email : null;
            values.availability = values.availability
              ? values.availability
              : null;
            values.highlyRequestedArea = this.state.checkBox
              .highlyRequestedAreas
              ? this.state.checkBox.highlyRequestedAreas.join(", ")
              : null;
            values.interestedArea = this.state.checkBox.interestedArea
              ? this.state.checkBox.interestedArea.join(", ")
              : null;
            values.salesforceCertification = values.salesforceCertification
              ? values.salesforceCertification
              : null;
            values.availabilityBandwidth = values.availabilityBandwidth
              ? values.availabilityBandwidth
              : null;
            values.expectedAvailability = values.expectedAvailability
              ? values.expectedAvailability
              : null;

            this.props.actions.expertAvailabilityUpdate(values);
            return false;
          }}
        >
          {renderExpertAvailabilityForm}
        </Form>
      </div>
    );
  }
}

export default ExpertAvailabilityForm;
