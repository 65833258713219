import React from "react";

const OpportunityAuditTableData = props => {
  let { value1, value2 } = props;
  return (
    <>
      <span className={`${!value1 && "text-danger"}`}>
        {value1 ? value1 : "NOT AVAILABLE"}
      </span>
      <br />
      <span
        className={`${!value2 && "text-danger"} ${
          value1 && value2 && value1 != value2 ? "text-danger" : ""
        }`}
      >
        {value2 ? value2 : "NOT AVAILABLE"}
      </span>
    </>
  );
};
export default OpportunityAuditTableData;
