import React from "react";
import PropTypes from "prop-types";
import { Label } from "reactstrap";
import { COLOR_GREY } from "../../common/color";
import { SIZE_SMALLER } from "../../common/size";

class DateLabel extends React.Component {
  render() {
    const { id, children, className } = this.props;

    return (
      <Label
        id={id || children}
        for={id}
        className={className}
        style={{
          color: COLOR_GREY,
          fontSize: SIZE_SMALLER,
          margin: "0 10px 2px",
          paddingBottom: "60px"
        }}
      >
        {children}
      </Label>
    );
  }
}

DateLabel.propTypes = {
  id: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element])
};

export default DateLabel;
