// Partner Type Setting
export const ACCOUNT_MAPPING = "accountMapping";
export const COMARKETING_CAMPAIGNS = "coMarketingCampaigns";
export const LEAD_DISTRIBUTION = "leadDistribution";
export const MDF = "mdf";
export const PARTNER_RESOURCES = "partnerResources";
export const PARTNERSHIP_WITH_INDIVIDUAL_PERSON =
  "partnerShipWithIndividualPerson";
export const TEAM_MANAGEMENT = "teamManagement";
export const REFERRALS_DEAL_REGISTRATION = "referralsDealRegistration";
export const ALLOW_REFERRAL_SUBMISSION = "allow_referral_submission";
export const PARTNER_AUTO_APPROVE = "partner_auto_approve";
export const REFERRAL_AUTO_APPROVE = "referral_auto_approve";
