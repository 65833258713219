import React, { Component } from "react";
import DefaultContent from "../content/DefaultContent";
import Text from "../base/Text";
import { FormGroup } from "reactstrap";
import Checkbox from "../../views/expertSignUp/Checkbox";
import { FieldArray } from "formik";
import ErrorMessage from "../base/ErrorMessage";
import moment from "moment";
import ExpertProfileEditDatepicker from "./ExpertProfileEditDatepicker";

class ExpertProfileEducation extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  setStartDate(field, value) {
    this.setState({
      [field]: value
    });
  }
  render() {
    const { educations, id } = this.props;
    return (
      <>
        <DefaultContent title={"Education"}>
          <p className={["text-inline-grayed"].join("")}>
            We want to know more about what your education. This will help
            prospective clients get a better idea of how impressive you are!
          </p>
          <FieldArray
            name="education"
            render={arrayHelpers => (
              <div className={["educations", "education-editing"].join(" ")}>
                {arrayHelpers.form.values.education &&
                  arrayHelpers.form.values.education.map((education, index) => (
                    <div className="education">
                      <div className="field-wrapper">
                        <ErrorMessage
                          name={`education[${index}].collegeName`}
                        />
                        <Text
                          id={id}
                          placeholder="College Name"
                          name={`education[${index}].collegeName`}
                          required
                        />
                      </div>
                      <div className="field-wrapper">
                        <Text
                          id={id}
                          name={`education[${index}].degree`}
                          placeholder="Degree"
                        />
                      </div>
                      <div className="field-wrapper">
                        <Text
                          id={id}
                          placeholder={
                            "Course (ex. Financial management of Business)"
                          }
                          name={`education[${index}].course`}
                        />
                      </div>
                      <div className="field-wrapper">
                        <Text
                          id={id}
                          placeholder={
                            "Field of Study (ex. Graphic Design, Economics)"
                          }
                          name={`education[${index}].courseType`}
                        />
                      </div>
                      <div className="field-wrapper">
                        <FormGroup>
                          <ExpertProfileEditDatepicker
                            id={id}
                            name={`education[${index}].startDate`}
                            placeholder={"Start date"}
                            value={
                              arrayHelpers.form.values.education[index]
                                .startDate
                            }
                            onChange={val => {
                              arrayHelpers.form.values.education[
                                index
                              ].startDate = moment(val).format("YYYY-MM-DD");
                              this.setStartDate(
                                `education[${index}].startDate`,
                                moment(val).format("YYYY-MM-DD")
                              );
                            }}
                            clearIcon={null}
                            maxDate={new Date()}
                          />
                        </FormGroup>
                        {!arrayHelpers.form.values.education[index]
                          .currentlyGoingHere && (
                          <FormGroup>
                            <ExpertProfileEditDatepicker
                              id={id}
                              name={`education[${index}].endDate`}
                              placeholder={"End date"}
                              value={
                                arrayHelpers.form.values.education[index]
                                  .endDate
                              }
                              onChange={val => {
                                arrayHelpers.form.values.education[
                                  index
                                ].endDate = moment(val).format("YYYY-MM-DD");
                              }}
                              clearIcon={null}
                              minDate={
                                this.state[`education[${index}].startDate`]
                                  ? new Date(
                                      this.state[
                                        `education[${index}].startDate`
                                      ]
                                    )
                                  : arrayHelpers.form.values.education[index]
                                      .startDate
                                  ? new Date(
                                      arrayHelpers.form.values.education[
                                        index
                                      ].startDate
                                    )
                                  : ""
                              }
                              disabled={
                                this.state[`education[${index}].endDate`] ||
                                arrayHelpers.form.values.education[index]
                                  .startDate
                                  ? false
                                  : true
                              }
                            />
                          </FormGroup>
                        )}
                      </div>
                      <div className="field-wrapper">
                        <Checkbox
                          className="custom-checkbox accepted-terms pb-0 mb-0"
                          id={`education_currently_going_here_${index}`}
                          name={`education[${index}].currentlyGoingHere`}
                          label={"Currently going here"}
                        />
                      </div>
                      <div className={["btn-wrapper", "text-right"].join(" ")}>
                        <a
                          className="remove-link"
                          onClick={() => arrayHelpers.remove(index)}
                          href="javascript:void(0)"
                        >
                          Remove
                        </a>
                      </div>
                    </div>
                  ))}
                <a
                  href="javascript:void(0)"
                  className="h7"
                  onClick={e => {
                    let startDate, endDate;
                    e.preventDefault();
                    arrayHelpers.push({
                      collegeName: "",
                      degree: "",
                      course: "",
                      courseType: "",
                      currentlyGoingHere: false,
                      startDate,
                      endDate
                    });
                  }}
                >
                  {`Add ${
                    educations && educations.length > 0 ? "another" : ""
                  } education...`}
                </a>
              </div>
            )}
          />
        </DefaultContent>
      </>
    );
  }
}

export default ExpertProfileEducation;
