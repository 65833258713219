/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Form from "../../../../components/base/Form";
import Button from "../../../../components/base/Button";

import CancelButton from "../../../../components/base/CancelButton";

import ProfileCard from "../../../../components/base/ProfileCard";

import Label from "../../../../components/base/Label";

import SidebarItem from "../../../referral/inner-components/SidebarItem";

const ReferralRequestConfirmationModal = ({
  openModal,
  toggle,
  onSubmit,
  accountList,
  partnerDetail
}) => {
  return (
    <>
      <Modal
        isOpen={openModal}
        toggle={() => {
          toggle();
        }}
        className="edit-task-modal expert-modal"
        backdrop="static"
        size="xl"
        style={{ maxWidth: "100%", minWidth: "50%", margin: "10px auto" }}
      >
        <ModalHeader toggle={toggle}>
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <div className="img-wrapper d-flex justify-content-center mb-2 mt-3"></div>
              <p className="text-center font-weight-bold mb-3">
                Referral Request
              </p>
            </div>
          </div>
        </ModalHeader>

        <Form
          enableReinitialize={true}
          initialValues={{
            partner: partnerDetail && partnerDetail.company_name
          }}
          onSubmit={values => {}}
        >
          <div className="project-details-body">
            <div className="details-body-main">
              <div className="card-body">
                <div className="col-lg-12">
                  {/* Account Name */}
                  <div className="field-wrapper row">
                    <div className="col-lg-12">
                      <Label className="font-weight-bold">Account</Label>
                      {accountList &&
                        accountList.length > 0 &&
                        accountList.map(data => (
                          <>
                            <div className="field-wrapper">
                              <div className="form-group">
                                <div className="d-flex flex-row justify-content-start align-items-center">
                                  <div>
                                    <ProfileCard
                                      firstName={data.name}
                                      fontSize={15}
                                      avatarWidth={"45px"}
                                      avatarHeight={"45px"}
                                      lineHeight={"45px"}
                                    />
                                  </div>
                                  <div className="d-flex flex-column ml-1">
                                    <div>{data.name}</div>
                                    <div style={{ fontSize: "13px" }}>
                                      {data.accountWebsite}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="details-body-sidebar">
              <SidebarItem>
                {/* Source Partner */}
                <Label className="font-weight-bold">Partner</Label>

                <div className="field-wrapper">
                  <div className="form-group">
                    <div className="d-flex flex-row justify-content-start align-items-center">
                      <div>
                        <ProfileCard
                          firstName={
                            partnerDetail && partnerDetail.company_name
                          }
                          fontSize={15}
                          avatarWidth={"45px"}
                          avatarHeight={"45px"}
                          lineHeight={"45px"}
                        />
                      </div>
                      <div> {partnerDetail && partnerDetail.company_name} </div>
                    </div>
                  </div>
                </div>
              </SidebarItem>
            </div>
          </div>
          <ModalFooter>
            <div className="pt-3 pb-4 pull-right">
              <CancelButton className="ml-auto mr-1" onClick={() => toggle()} />
              <Button
                id="submitButton"
                label={`Submit`}
                type="submit"
                className="btn btn-primary"
                onClick={() => onSubmit()}
              />
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default ReferralRequestConfirmationModal;
