import React from "react";

// Components
import UpdateCustomerForm from "./UpdateCustomerForm";

const CustomerDetails = props => {
  return (
    <div>
      <UpdateCustomerForm history={props.history} match={props.match} />
    </div>
  );
};

export default CustomerDetails;
