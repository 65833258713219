import React from "react";

// Config
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";

// Components
import toast from "../../../components/base/Toast";
import PartnerSelectionModal from "../../../components/PartnerSelectionModal";
import PartnerSection from "./PartnerSection";

// Helper
import { isLoggedIn } from "../../../lib/helper";

import { PARTNER_STATUS_APPROVED } from "../../../partnerStatus/Constants";
import { LEAD_PARTNER_STATUS_PENDING } from "../../../lead/Constants";
import { isBadRequest } from "../../../common/http";

// Deal source more partners pending list statuses
const sourceMorePartnersPendingListStatuses = [LEAD_PARTNER_STATUS_PENDING];

class SourcePartners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPartnerLoading: false,
      partnerList: [],
      partnerIds: [],
      isPartnerModal: false,
      isDealPartnerLoading: false,
      dealPartnerList: [],
      projectPartnerWaitingList: [],
      pendingDealPartnerList: [],
      partnerRequestId: "",
      partnerStatus: "",
      partnerWaitingRequestId: [],
      checkBox: {
        shortListedPartners: []
      },
      editInterestStatusModal: false,
      selectedEditInterestPartnerId: "",
      filteredPartnerList: [],
      showSourceAdd: false
    };
    this.toggle = this.toggle.bind(this);
    this.handlePartners = this.handlePartners.bind(this);
    this._handleShortListedPartners = this._handleShortListedPartners.bind(
      this
    );
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this._getPartnersList(this.props.leadId);
  }

  componentDidUpdate(prevProps) {
    // Check is logged in user
    isLoggedIn();
    if (prevProps.isLeadUpdate !== this.props.isLeadUpdate)
      this._getPartnersList(this.props.leadId);

    if (prevProps && prevProps.leadId !== this.props.leadId)
      this._getPartnersList(this.props.leadId);
  }

  // Filter Partner List
  _filterPartnersList(selectedFilters) {
    const { partnerList } = this.state;
    let filteredPartnerList = [],
      tags = [],
      city = "",
      type = "",
      tier = "";

    // Check filters selected
    if (selectedFilters) {
      city = selectedFilters.city || "";
      type = selectedFilters.type || "";
      tier = selectedFilters.tier || "";
      tags = selectedFilters.tags || [];
    }

    // Filter partners
    if (partnerList && partnerList.length > 0) {
      this.setState({ isPartnerLoading: true }, () => {
        partnerList.forEach(partner => {
          const partnerType = partner.partnerTypeName || "";
          const partnerTier = partner.partnerTierName || "";
          const partnerTags = partner.tags || [];
          const partnerCity = partner.city || "";
          let isTypeExist = false;
          let isCityExist = false;
          let isTagExist = false;
          let isTierExist = false;

          if (partnerType === type || type === "") isTypeExist = true;
          if (partnerTier === tier || tier === "") isTierExist = true;
          if (partnerCity === city || city === "") isCityExist = true;

          if (tags && tags.length > 0) {
            tags.forEach(tagName => {
              let isCustomTagExist = false;
              isCustomTagExist = this.isTagExist(partnerTags, tagName);
              if (isCustomTagExist) {
                isTagExist = true;
              }
            });
          } else {
            isTagExist = true;
          }
          if (isTypeExist && isTierExist && isCityExist && isTagExist) {
            filteredPartnerList.push(partner);
          }
        });
        this.setState({
          filteredPartnerList: filteredPartnerList,
          isPartnerLoading: false
        });
      });
    }
  }

  // Check is tag exist in partner tag data
  isTagExist = (tagList, filterTagName) => {
    if (!tagList.length) {
      return false;
    }

    let isExist = false;
    tagList.forEach(tag => {
      if (filterTagName === tag.tag_name) {
        isExist = true;
      }
    });

    return isExist;
  };

  _getPartnersList(leadId) {
    this.setState({ isPartnerLoading: true }, () => {
      apiClient
        .get(
          `${
            endpoints().partnerAPI
          }/search?marketplaceStatus=${PARTNER_STATUS_APPROVED}`
        )
        .then(response => {
          const partnerList = response.data.data;

          this.setState(
            {
              isPartnerLoading: false,
              partnerList: partnerList ? partnerList : this.state.partnerList
            },
            () => {
              this._filterPartnersList({});
            }
          );
          this._getSourceMorePartnersPendingList(
            true,
            sourceMorePartnersPendingListStatuses.join(", ")
          );
        });
    });
  }

  // Handle Modal Click
  toggle = () => {
    this.setState({ isPartnerModal: !this.state.isPartnerModal });
  };

  // Handle partners
  handlePartners(value) {
    if (!value) {
      return this.setState({ partnerIds: [] });
    }

    const partnerIds = this.state.partnerIds.slice();
    if (partnerIds.indexOf(value) > -1) {
      this.setState({ partnerIds: partnerIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ partnerIds: partnerIds.concat(value) });
    }
  }

  // Create Project User
  createLeadPartner() {
    const { partnerIds } = this.state;
    const leadId = this.props.leadId;

    const data = {
      partnerIds: partnerIds,
      leadId: leadId,
      status: LEAD_PARTNER_STATUS_PENDING
    };

    return apiClient
      .post(`${endpoints().leadPartnerAPI}`, data)
      .then(() => {
        this.toggle();
        this.setState({ partnerIds: [] });
        this._getSourceMorePartnersPendingList(
          true,
          sourceMorePartnersPendingListStatuses.join(", ")
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Update Partner List
  updatePartnerList = selectedFilters => {
    this._filterPartnersList(selectedFilters);
  };

  // Get Project User Pending List
  _getSourceMorePartnersPendingList(isDealPartner, status) {
    const leadId = this.props.leadId;
    this.setState({ isDealPartnerLoading: isDealPartner }, () => {
      apiClient
        .get(
          `${
            endpoints().leadPartnerAPI
          }/search?leadId=${leadId}&status=${status}`
        )
        .then(response => {
          const dealPartnerList = response.data.data;

          const selectedIds = [];
          if (dealPartnerList) {
            dealPartnerList.map(partner =>
              selectedIds.push(partner.companyName)
            );
          }

          this.setState({
            isDealPartnerLoading: !isDealPartner,
            dealPartnerList: dealPartnerList ? dealPartnerList : "",
            showSourceAdd: true
          });
        });
    });
  }

  _handleShortListedPartners(e) {
    const newSelection = parseInt(e.target.value, 10);
    let newSelectionArray;

    if (this.state.checkBox.shortListedPartners.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.shortListedPartners.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.shortListedPartners,
        parseInt(newSelection, 10)
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        shortListedPartners: newSelectionArray
      }
    }));
  }

  // Delete the project waiting list user
  deleteDealWaitingPartner(id) {
    return apiClient
      .delete(`${endpoints().leadPartnerAPI}/${id}`)
      .then(() => {
        this._getSourceMorePartnersPendingList(
          true,
          sourceMorePartnersPendingListStatuses.join(", ")
        );
        // this.props.handleProjectDetails(this.props.projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    const {
      partnerIds,
      isPartnerModal,
      isPartnerLoading,
      dealPartnerList,
      filteredPartnerList
    } = this.state;

    const { leadId, leadDetails } = this.props;

    let assignedPartnerList = [...(dealPartnerList ? dealPartnerList : [])];

    if (leadDetails && leadDetails.partnerDetails) {
      assignedPartnerList.push(leadDetails.partnerDetails);
      this.setState({ showSourceAdd: true });
    }

    return (
      <>
        {this.state.showSourceAdd && (
          <PartnerSection
            currentUserId={this.props.currentUserId}
            leadPartnerList={assignedPartnerList}
            onSourcePartner={() => {
              this._getPartnersList(leadId);
              this.toggle();
            }}
            activeTab={this.props.activeTab}
            isPartnerLoading={isPartnerLoading}
          />
        )}
        {/*Partner modal*/}
        <PartnerSelectionModal
          isPartnerModal={isPartnerModal}
          isLoading={isPartnerLoading}
          toggle={this.toggle}
          partnerList={filteredPartnerList}
          selectedPartnerList={assignedPartnerList}
          handlePartners={this.handlePartners}
          partnerIds={partnerIds}
          createLeadPartner={e => this.createLeadPartner(e)}
          updatePartnerList={this.updatePartnerList}
        />
      </>
    );
  }
}

export default SourcePartners;
