import {
  REQUEST_DELETE_SALESFORCE_LEAD_FIELD_MAPPING,
  RECEIVE_DELETE_SALESFORCE_LEAD_FIELD_MAPPING,
  SALESFORCE_LEAD_FIELD_MAPPING_DELETE_ERROR,
  REQUEST_CREATE_SALESFORCE_LEAD_FIELD_MAPPING,
  RECEIVE_CREATE_SALESFORCE_LEAD_FIELD_MAPPING,
  SALESFORCE_LEAD_FIELD_MAPPING_CREATE_ERROR,
  REQUEST_UPDATE_SALESFORCE_LEAD_FIELD_MAPPING,
  RECEIVE_UPDATE_SALESFORCE_LEAD_FIELD_MAPPING,
  SALESFORCE_LEAD_FIELD_MAPPING_UPDATE_ERROR
} from "../actionType/Constants";

import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import toast from "../components/base/Toast";
import { fetchList } from "./table";
import { isBadRequest } from "../common/http";

/**
 * Request for deleting salesforce lead field mapping
 */
export function requestDeleteSalesforceLeadFieldMapping() {
  return {
    type: REQUEST_DELETE_SALESFORCE_LEAD_FIELD_MAPPING
  };
}

/**
 * Receive for deleting salesforce lead field mapping
 */
export function receiveDeleteSalesforceLeadFieldMapping() {
  return {
    type: RECEIVE_DELETE_SALESFORCE_LEAD_FIELD_MAPPING
  };
}

/**
 * Receive for error deleting salesforce lead field mapping
 */
export function salesforceLeadFieldMappingDeleteError(error) {
  return {
    type: SALESFORCE_LEAD_FIELD_MAPPING_DELETE_ERROR,
    error
  };
}

/**
 * Delete salesforce lead field mapping
 *
 * @param id
 * @returns {function(*): *}
 */
export function deleteSalesforceLeadFieldMapping(id, params) {
  return dispatch => {
    dispatch(requestDeleteSalesforceLeadFieldMapping());

    apiClient
      .delete(`${endpoints().salesforceFieldMappingAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "salesforceLeadFieldMapping",
            `${endpoints().salesforceFieldMappingAPI}`,
            1,
            10,
            { ...params, pagination: true }
          )
        );
      })
      .catch(error => {
        dispatch(salesforceLeadFieldMappingDeleteError(error));
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}

/**
 * Request for creating salesforce lead field mapping
 */
export function requestCreatesalesforceLeadFieldMapping() {
  return {
    type: REQUEST_CREATE_SALESFORCE_LEAD_FIELD_MAPPING
  };
}

/**
 * Receive for receive salesforce lead field mapping
 */
export function receiveCreatesalesforceLeadFieldMapping() {
  return {
    type: RECEIVE_CREATE_SALESFORCE_LEAD_FIELD_MAPPING
  };
}

/**
 * Receive for error creating salesforce lead field mapping
 */
export function salesforceLeadFieldMappingCreateError(error) {
  return {
    type: SALESFORCE_LEAD_FIELD_MAPPING_CREATE_ERROR,
    error
  };
}

/**
 * Create salesforce lead field mapping
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function addSalesforceLeadFieldMapping(data, params) {
  return dispatch => {
    dispatch(requestCreatesalesforceLeadFieldMapping());

    return apiClient
      .post(`${endpoints().salesforceFieldMappingAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "salesforceLeadFieldMapping",
            `${endpoints().salesforceFieldMappingAPI}`,
            1,
            10,
            { ...params, pagination: true }
          )
        );
        dispatch(receiveCreatesalesforceLeadFieldMapping());
      })
      .catch(error => {
        dispatch(salesforceLeadFieldMappingCreateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Request for updating salesforce lead field mapping
 */
export function requestUpdateSalesforceLeadFieldMapping() {
  return {
    type: REQUEST_UPDATE_SALESFORCE_LEAD_FIELD_MAPPING
  };
}

/**
 * Receive for updating salesforce lead field mapping
 */
export function receiveUpdateSalesforceLeadFieldMapping() {
  return {
    type: RECEIVE_UPDATE_SALESFORCE_LEAD_FIELD_MAPPING
  };
}

/**
 * Receive for error updating salesforce lead field mapping
 */
export function SalesforceLeadFieldMappingUpdateError(error) {
  return {
    type: SALESFORCE_LEAD_FIELD_MAPPING_UPDATE_ERROR,
    error
  };
}

/**
 * Update salesforce lead field mapping details
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateSalesforceLeadFieldMapping(id, data, params) {
  return dispatch => {
    dispatch(requestUpdateSalesforceLeadFieldMapping());
    apiClient
      .put(`${endpoints().salesforceFieldMappingAPI}/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "salesforceLeadFieldMapping",
            `${endpoints().salesforceFieldMappingAPI}`,
            1,
            10,
            params
          )
        );
      })
      .catch(error => {
        dispatch(SalesforceLeadFieldMappingUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}
