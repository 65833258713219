import _ from "lodash";

// Contants
import { RECEIVE_PLAYBOOK_LIST } from "../actionType/Constants";

export function playbookDetailsReducer(
  state = {
    isFetching: false
  },
  action
) {
  switch (action.type) {
    // Fetch playbook list fail
    case RECEIVE_PLAYBOOK_LIST: {
      return Object.assign({}, state, {
        ..._.keyBy(action.payload.data, n => n.id),
        isFetching: false
      });
    }
    default:
      return state;
  }
}
