import React, { useState } from "react";
import { ChevronDown, ChevronRight } from "../../../../assets/img/icons";

const KickOffTimePickerAccordion = ({ icon, title, children }) => {
  const [visible, setVisible] = useState(true);
  const toggleVisibility = () => setVisible(prevState => !prevState);

  return (
    <div className={"kickoff-scheduler__accordeon"}>
      <button
        className={"kickoff-scheduler__button"}
        onClick={toggleVisibility}
      >
        {icon} {title} {visible ? <ChevronDown /> : <ChevronRight />}
      </button>
      <div
        className={`kickoff-scheduler__times ${visible ? "d-block" : "d-none"}`}
      >
        {children}
      </div>
    </div>
  );
};

export default KickOffTimePickerAccordion;
