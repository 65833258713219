import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// Constants
import {
  NOTIFICATION_EMAIL_SUB_TAB,
  NOTIFICATION_PARTNER_SUB_TAB,
  NOTIFICATION_SLACK_SUB_TAB,
  TAB_NOTIFICATION
} from "../../../../setting/Constants";

// Sub Tab  Sections
import EmailSection from "./EmailSection";
import SlackSection from "./SlackSection";
import PartnerSection from "./PartnerSection";

const NotificationTab = props => {
  let {
    activeSubTab,
    adminSettings,
    saveSettings,
    toggleTabs,
    history
  } = props;
  const { enable_referrals, enable_experts, enable_partners } = adminSettings;

  if (
    enable_referrals == "true" ||
    enable_experts == "true" ||
    enable_partners == "true"
  ) {
    activeSubTab = activeSubTab;
  } else {
    activeSubTab = NOTIFICATION_SLACK_SUB_TAB;
  }
  return (
    <>
      <Nav tabs className="setting-tabs">
        {/* Email Tab */}
        {(enable_referrals == "true" ||
          enable_experts == "true" ||
          enable_partners == "true") && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === NOTIFICATION_EMAIL_SUB_TAB
              })}
              onClick={() => {
                toggleTabs(TAB_NOTIFICATION, NOTIFICATION_EMAIL_SUB_TAB);
              }}
            >
              {NOTIFICATION_EMAIL_SUB_TAB}
            </NavLink>
          </NavItem>
        )}

        {/* Slack Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === NOTIFICATION_SLACK_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_NOTIFICATION, NOTIFICATION_SLACK_SUB_TAB);
            }}
          >
            {NOTIFICATION_SLACK_SUB_TAB}
          </NavLink>
        </NavItem>

        {/* Partner Tab */}
        {enable_partners == "true" && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === NOTIFICATION_PARTNER_SUB_TAB
              })}
              onClick={() => {
                toggleTabs(TAB_NOTIFICATION, NOTIFICATION_PARTNER_SUB_TAB);
              }}
            >
              {NOTIFICATION_PARTNER_SUB_TAB}
            </NavLink>
          </NavItem>
        )}
      </Nav>
      <TabContent activeTab={activeSubTab}>
        {/* Email Tab */}
        <TabPane tabId={NOTIFICATION_EMAIL_SUB_TAB} className="w-100">
          <EmailSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        {/* Slack Tab */}
        <TabPane tabId={NOTIFICATION_SLACK_SUB_TAB} className="w-100">
          <SlackSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        {/* Partner Tab */}
        <TabPane tabId={NOTIFICATION_PARTNER_SUB_TAB} className="w-100">
          <PartnerSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default NotificationTab;
