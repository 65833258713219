import React, { Component } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import DatePicker from "../../components/base/Date";

// Constants
import {
  TAB_BASICS,
  TAB_DETAILS,
  TAB_DELIVERABLES,
  TAB_PRICING_AND_EXTRAS,
  PROJECT_TYPE_PROJECT,
  PROJECT_TYPE_CAMPAIGN
} from "../../project/Constants";

// Components
import Text from "../../components/base/Text";
import TextArea from "../../components/base/TextArea";
import ColorPicker from "../../components/base/ColorPicker";
import IconPicker from "../../components/base/IconPicker";
import PlaybookCard from "../../components/PlaybookCard";
import Multiselect from "../../components/base/Multiselect";
import Select from "../../components/base/Select";
import DragAndDropField from "../../components/base/DragAndDropField";
import Hint from "../../components/base/Hint";
import TabsAndContent from "../../components/base/TabsAndContent";
import Currency from "../../components/base/Currency";
import SideBar from "../app/components/SideBar";

import PlaybookPricingFields from "../playbook/inner_components/PlaybookPricingFields";
import PlaybookDeliverables from "../playbook/inner_components/PlaybookDeliverables";
import Checkbox from "../projects/inner-components/Checkbox";

// Assets
import { MaximizeAltIcon } from "../../assets/img/icons";

class AddProjectFormFields extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      description: "",
      bannerImageUrl: "",
      playbookDetails: [],
      loading: false,
      activeTab: TAB_BASICS,
      values: [],
      isExists: this.props.extras && this.props.extras.length,
      extras: this.props.extras,
      updatedExtras: [],
      extra: {},
      isOpen: false,
      fileTypeError: false,
      value: ""
    };

    this._handleChange = this._handleChange.bind(this);
    this._handleDescriptionChange = this._handleDescriptionChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.checkAndSetActiveTab = this.checkAndSetActiveTab.bind(this);
  }

  // Handle OnChange playbook name field
  _handleChange = e => {
    const target = e.target;
    const name = target.value;
    this.setState({ name });
    this.props.handleNameChange(name);
  };

  setProjectTimeline = (date, stateProperty) => {
    this.setState({ [stateProperty]: date });

    if (stateProperty === "startDate") {
      this.props.handleStartDate(date);
    } else {
      this.props.handleEndDate(date);
    }
  };

  //Handle OnChange playbook description field
  _handleDescriptionChange = e => {
    const target = e.target;
    const description = target.value;
    this.setState({ description });
    this.props.handleDescriptionChange(description);
  };

  // Handle package price
  _handlePackagePriceChange = e => {
    const { value } = e.target;
    this.setState({ [e.target.name]: value });
  };

  // Toggle Tab
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab }, () => {
        window.localStorage.setItem("playbookActiveTab", this.state.activeTab);
      });
    }
  };

  // Check and Set active tab
  checkAndSetActiveTab = () => {
    const existingActiveTab = window.localStorage.getItem("playbookActiveTab");
    if (existingActiveTab !== null) {
      this.setState({ activeTab: existingActiveTab });
    }
  };

  componentDidMount() {
    this.checkAndSetActiveTab();
  }

  // Select the uploaded image
  onSelectFile = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          this.setState({
            fileTypeError: false,
            value: targetFile.files
          });
          this.props.bannerImageUpload(e);
          this.setState({ value: "" });

          break;

        default:
          this.setState({
            fileTypeError: true
          });
          break;
      }
    }
  };

  // Render the form fields
  render() {
    const existPlaybookData = this.props.data;
    const {
      expertRoles,
      skills,
      customerList,
      industries,
      defaultDeliverables,
      supportingMaterials,
      getDeliverablesValue,
      currentProjectType,
      history,
      handlePartnerChange
    } = this.props;

    const { location } = history;

    // getting partner id from query
    const partnerId = this.props.partnerId
      ? this.props.partnerId
      : location.query
      ? location.query.partnerId
      : "";

    const data = {
      name:
        !this.state.name && this.props.projectName
          ? this.props.projectName
          : this.state.name,
      description:
        !this.state.description &&
        existPlaybookData &&
        existPlaybookData.description
          ? existPlaybookData.description
          : this.state.description,
      bannerIcon:
        !this.props.bannerIcon &&
        existPlaybookData &&
        existPlaybookData.bannerIcon
          ? existPlaybookData.bannerIcon
          : this.props.bannerIcon,
      bannerColor:
        !this.props.bannerColor &&
        existPlaybookData &&
        existPlaybookData.bannerColor
          ? existPlaybookData.bannerColor
          : this.props.bannerColor,
      bannerImageUrl:
        !this.props.bannerImageUrl &&
        existPlaybookData &&
        existPlaybookData.bannerImageUrl
          ? existPlaybookData.bannerImageUrl
          : this.props.bannerImageUrl
    };

    const playbookCardPlaceHolder =
      existPlaybookData && existPlaybookData.description ? false : true;

    const { activeTab } = this.state;

    const fileTypeError = this.state.fileTypeError;

    return (
      <div>
        <Nav tabs className="admin-tabs">
          {existPlaybookData ? "" : this.toggle(this.props.currentTab + "")}
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TAB_BASICS })}
              onClick={() => {
                this.toggle(TAB_BASICS);
                this.props.backButton(TAB_BASICS);
              }}
            >
              {TAB_BASICS}
            </NavLink>
          </NavItem>
          {currentProjectType === PROJECT_TYPE_PROJECT && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === TAB_DETAILS })}
                onClick={() => {
                  this.toggle(TAB_DETAILS);
                  this.props.backButton(TAB_DETAILS);
                }}
              >
                {TAB_DETAILS}
              </NavLink>
            </NavItem>
          )}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === TAB_PRICING_AND_EXTRAS
              })}
              onClick={() => {
                this.toggle(TAB_PRICING_AND_EXTRAS);
                this.props.backButton(TAB_PRICING_AND_EXTRAS);
              }}
            >
              {TAB_PRICING_AND_EXTRAS}
            </NavLink>
          </NavItem>
        </Nav>
        <TabsAndContent>
          {/*added this condition below to support multiple contents inside a tab pane with white background*/}
          <div
            className={`${
              activeTab === TAB_DELIVERABLES ? "" : "tab-content-wrapper d-flex"
            }`}
          >
            <TabContent activeTab={activeTab}>
              <TabPane tabId={TAB_BASICS}>
                <div className="form-wrapper">
                  <div className="field-wrapper">
                    <Text
                      name="name"
                      label={`${currentProjectType} Name`}
                      placeholder={`Enter ${currentProjectType} Name`}
                      error=""
                      required={true}
                      onChange={this._handleChange.bind(this)}
                    />
                  </div>

                  <div className="form-wrapper">
                    <div className="field-wrapper flex-column">
                      <TextArea
                        name="description"
                        label="Description"
                        onChange={this._handleDescriptionChange.bind(this)}
                        placeholder="Enter Description"
                        error=""
                        maxLength={1000}
                      />
                      <Hint
                        id="requirements"
                        hintText={`Description will appear on ${currentProjectType} Detail Page`}
                      />
                    </div>
                  </div>

                  {currentProjectType === PROJECT_TYPE_CAMPAIGN && (
                    <div className="row mt-4">
                      <div className="col-sm-6">
                        <label>Start Date</label>
                        <DatePicker
                          name="datepicker-start"
                          className="datepicker d-flex"
                          placeholder="MM/DD/YYYY"
                          format="MM/dd/yyyy"
                          onChange={date =>
                            this.setProjectTimeline(date, "startDate")
                          }
                          minDate={new Date()}
                          selected={this.state.startDate}
                          clearIcon={true}
                          value={this.props.startDate}
                        />
                      </div>
                      <div className="col-sm-6">
                        <label>Target End Date</label>
                        <DatePicker
                          name={`datepicker-end`}
                          className="datepicker"
                          placeholder="MM/DD/YYYY"
                          format="MM/dd/yyyy"
                          onChange={date =>
                            this.setProjectTimeline(date, "endDate")
                          }
                          minDate={this.state.startDate}
                          selected={this.state.endDate}
                          clearIcon={true}
                          value={this.props.endDate}
                        />
                      </div>
                    </div>
                  )}

                  <div className="field-wrapper mt-4">
                    <div className="form-group">
                      <label>Color</label>
                      <ColorPicker
                        onColourPickerClick={this.props.onColourPickerClick}
                        bannerColor={this.props.bannerColor}
                      />
                    </div>
                  </div>
                  <div className="field-wrapper">
                    <div className="form-group">
                      <label>Icon</label>
                      <IconPicker
                        onIconClick={this.props.onIconClick}
                        bannerIcon={this.props.bannerIcon}
                      />
                    </div>
                  </div>
                  <div className="upload-field d-inline-block">
                    <input
                      name="file"
                      className="form-control d-none"
                      type="file"
                      id="bannerImage"
                      placeholder="Banner Image"
                      onChange={e => {
                        this.onSelectFile(e);
                      }}
                      accept="image/png,image/gif,image/jpeg"
                      value={this.state.value}
                    />
                    <span className="profilePicOverlay d-block ">
                      <label
                        htmlFor="bannerImage"
                        className="profile-img-sm mb-0"
                      >
                        <span className="banner-image-upload-link">
                          Upload Custom Cover Graphic
                        </span>
                      </label>
                    </span>
                  </div>
                  {/* Remove image */}
                  {data.bannerImageUrl && (
                    <span
                      className="banner-image-upload-link text-danger ml-md-1"
                      onClick={this.props.handleImageRemove}
                    >
                      (Remove)
                    </span>
                  )}

                  <small
                    className={`text-danger mt-3 ${
                      fileTypeError ? "d-block" : "d-none"
                    }`}
                  >
                    Please select a image file
                  </small>
                  <Hint
                    id="bannerRequirements"
                    hintText="We recommended using 400 x 300 image"
                  />
                </div>
              </TabPane>

              {currentProjectType === PROJECT_TYPE_PROJECT && (
                <TabPane tabId={TAB_DETAILS}>
                  <div className="form-wrapper">
                    <div className="field-wrapper flex-column">
                      <Select
                        name="customer"
                        label="Customer"
                        options={customerList}
                        isSearchable={true}
                        required
                      />
                    </div>

                    <div className="field-wrapper flex-column">
                      <Select
                        name="expertRoles"
                        label="Expert Role"
                        options={expertRoles}
                      />
                      <Hint
                        id="expert-roles"
                        hintText="The type of experts you will need to complete this project"
                      />
                    </div>

                    <div className="field-wrapper flex-column">
                      <Multiselect
                        name="required_skills"
                        label="Required Skills"
                        options={skills}
                        placeholder="Copywriting, Design, Marketing..."
                      />

                      <Hint
                        id="required-skills"
                        hintText="If the experts need to have certain skills, list them here"
                      />
                    </div>

                    <div className="field-wrapper flex-column">
                      <Multiselect
                        name="required_industries"
                        label="Required Industries"
                        options={industries}
                        placeholder="Healthcare, Law, Retail..."
                      />

                      <Hint
                        id={"required-industries"}
                        hintText="If the experts need to have certain industry experience"
                      />
                    </div>

                    <div className="grouped-fields">
                      <div className="">
                        <label>Start Date</label>
                        <DatePicker
                          name="datepicker-start"
                          className="datepicker"
                          placeholderText="MM/DD/YYYY"
                          onChange={date =>
                            this.setProjectTimeline(date, "startDate")
                          }
                          minDate={new Date()}
                          selected={this.state.startDate}
                          clearIcon={null}
                          width={"150px"}
                        />
                      </div>
                      <div className="">
                        <label>Target End Date</label>
                        <DatePicker
                          name={`datepicker-end`}
                          className="datepicker"
                          placeholderText="MM/DD/YYYY"
                          onChange={date =>
                            this.setProjectTimeline(date, "endDate")
                          }
                          minDate={this.state.startDate}
                          selected={this.state.endDate}
                          clearIcon={null}
                          width={"150px"}
                        />
                      </div>
                    </div>

                    <div className="flex-column mt-3">
                      <DragAndDropField
                        label={"Supporting Materials"}
                        name={"supporting_materials"}
                        hiddenDropzone
                        btnLabel={"Add Supporting Materials"}
                        onFileChange={this.props.handleSupportingMaterial}
                        onFileDelete={this.props.handleDeleteSupportingMaterial}
                        data={supportingMaterials}
                      />
                    </div>
                  </div>
                </TabPane>
              )}

              <TabPane tabId={TAB_PRICING_AND_EXTRAS}>
                <div className="form-wrapper">
                  {currentProjectType === PROJECT_TYPE_PROJECT && (
                    <div className="field-wrapper-group">
                      <PlaybookPricingFields
                        initialPricingType={
                          existPlaybookData ? existPlaybookData.pricingType : ""
                        }
                      />

                      <div className="field-wrapper">
                        <Checkbox
                          name="autoPayment"
                          label="Handle billing outside platform"
                          className="accepted-terms mr-3"
                        />
                      </div>

                      <div className="field-wrapper">
                        <Currency
                          name="price"
                          label="Price"
                          placeholder="Price"
                        />
                        <Currency
                          name="margin"
                          label="Margin"
                          placeholder="Margin"
                        />
                      </div>
                    </div>
                  )}

                  <div className="field-wrapper-group">
                    <PlaybookDeliverables
                      deliverables={defaultDeliverables}
                      deliverablesValue={getDeliverablesValue}
                      task={this.props.task}
                      id={this.props.campaignId}
                      deleteTask={this.props.deleteTask}
                    />
                  </div>
                </div>
              </TabPane>
            </TabContent>

            {
              <div className="preview-wrapper">
                {/* partner section */}
                {currentProjectType === PROJECT_TYPE_CAMPAIGN && (
                  <div>
                    <SideBar
                      partnerId={partnerId}
                      handlePartnerChange={handlePartnerChange}
                    />
                  </div>
                )}
                <label className="font-weight-bold pl-3">Preview</label>
                <div className="ml-4 pl-20">
                  <PlaybookCard
                    isPreview={this.props.detailLink ? "false" : "true"}
                    link={this.props.detailLink}
                    openLinkInNewTab={true}
                    boxShadow={"true"}
                    placeHolder={playbookCardPlaceHolder}
                    data={data}
                    files={this.props.files || ""}
                    bannerImageUrl={this.props.bannerImageUrl}
                    tag={"div"}
                    fullWidth={false}
                  />
                  {this.props.detailLink && (
                    <div className="preview-full mt-4 d-flex align-items-center justify-content-center">
                      <MaximizeAltIcon />
                      <a
                        href={`${this.props.detailLink}?redirect=${window.location.pathname}`}
                        target="_blank"
                        rel="noopener noreferrer"
                        className={["text-underline"].join(" ")}
                      >
                        View Full Preview
                      </a>
                    </div>
                  )}
                </div>
              </div>
            }
          </div>
        </TabsAndContent>
      </div>
    );
  }
}

export default AddProjectFormFields;
