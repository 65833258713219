import React from "react";
import toast from "../../components/base/Toast";
import moment from "moment";
import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";

// Components
import Form from "../../components/base/Form";
import CancelButton from "../../components/base/CancelButton";
import PageTitle from "../../components/base/PageTitle";
import Button from "../../components/base/Button";
import AdminActionNavigation from "../../components/base/AdminActionNavigation";

// Configs
import { endpoints, DEFAULT_API_KEY } from "../../configs";
import {
  getCookie,
  getUrlParameter,
  isPartner,
  getKeyValueByObject
} from "../../lib/helper";
import { COOKIE_SESSION_TOKEN } from "../../lib/cookie";

// API call
import { apiClient } from "../../apiClient";

// Helper
import { toString, removeMaskedPhoneNumber } from "../../lib/helper";
import { UNITED_STATES } from "../../Country/Constants";

// Page Components
import DealFormFields from "./DealFormFields";
import SideBar from "./inner-components/SideBar";

//Assets
import { ChevronRight } from "../../assets/img/icons";

// Constant
import { TAG_TYPE_PRODUCTS } from "../../tagType/Constants";
import { DEAL_STATUS_DRAFT } from "../../dealStatus/Constants";
import { SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY } from "../../setting/Constants";
import { isBadRequest } from "../../common/http";

import { connect } from "react-redux";
import CoSell, {
  cosellWithAwsOption,
  opportunityTypeOption
} from "../../Constants/Cosell";
import ArrayList from "../../helpers/ArrayList";

class RegisterDealForm extends React.Component {
  constructor(props) {
    super(props);
    this.reCaptcha = React.createRef();
    // Set the initial input values
    this.state = {
      loading: false,
      checkBox: {
        productsInvolvedOptionSelections: []
      },
      selectedProductsInvolved: "",
      closeDate: new Date(),
      tagPlatforms: [],
      partnerIds: "",
      partnerStatus: "",
      currentPartner: "",
      partnerUser: "",
      isUserVerified: false,
      reCaptchaSiteKey: props.settings
        ? getKeyValueByObject(
            props.settings,
            SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY
          )
        : "",
      partnerTierCommissionPercentages: [],
      dealRegister: false,
      shareWithAws: "",
      oppType: ""
    };
  }

  componentDidMount() {
    if (this.props.publicDealForm && this.state.reCaptchaSiteKey) {
      loadReCaptcha(this.state.reCaptchaSiteKey);
    }

    this.getTagsByTagType(TAG_TYPE_PRODUCTS);

    if (!isPartner(this.props.roleId)) {
      this._getDealPartner();
    }
    if (isPartner(this.props.roleId)) {
      this._getCurrentPartner();
      this._getPartnerTierCommissionPercentages();
    }
  }
  // Get Deal Partner
  _getDealPartner = () => {
    const encryptedPartnerId = getUrlParameter("partner");

    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    apiClient
      .get(
        `${
          endpoints().publicAPI
        }/partner/id?partnerProfileUrl=${encryptedPartnerId}`
      )
      .then(response => {
        const partnerUser = response.data;
        this.setState({
          isPartnerLoading: false,
          partnerUser: partnerUser ? partnerUser : this.state.partnerUser
        });
      });
  };

  // Get Current Partner
  _getCurrentPartner = () => {
    const id = getUrlParameter("id");
    this.setState({ isPartnerLoading: true }, () => {
      apiClient.get(`${endpoints().partnerAPI}?id=${id}`).then(response => {
        const currentPartner = response.data;

        this.setState({
          isPartnerLoading: false,
          currentPartner: currentPartner
            ? currentPartner
            : this.state.currentPartner
        });
      });
    });
  };

  //Get Partner Tier Commission Percentages
  _getPartnerTierCommissionPercentages = () => {
    return apiClient
      .get(`${endpoints().partnerTierAPI}/search`)
      .then(response => {
        if (response && response.data && response.data.data) {
          this.setState({
            partnerTierCommissionPercentages: response.data.data
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save Deal data form
    this.createDeal(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define deal constants
    const accountName = values.accountName;

    if (!accountName) {
      success = false;
    }

    if (this.props.publicDealForm && !this.state.isUserVerified) {
      success = false;
    }

    return success;
  }

  setDate = (date, stateProperty) => {
    this.setState({ [stateProperty]: date });
  };

  // Handle change playbook extras
  handleChangeProductsInvolved = e => {
    const selectedExtras = e.target.value;
    let newSelectionArray;

    if (
      this.state.checkBox.productsInvolvedOptionSelections.indexOf(
        selectedExtras
      ) > -1
    ) {
      newSelectionArray = this.state.checkBox.productsInvolvedOptionSelections.filter(
        s => s !== selectedExtras
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.productsInvolvedOptionSelections,
        selectedExtras
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        productsInvolvedOptionSelections: newSelectionArray
      }
    }));

    this.setState({ selectedProductsInvolved: newSelectionArray });
  };

  // To Array
  _toArray(values) {
    const {
      selectedProductsInvolved,
      closeDate,
      partnerIds,
      partnerStatus,
      shareWithAws,
      oppType
    } = this.state;

    values.accountName = toString(values.accountName);
    values.firstName = toString(values.firstName);
    values.lastName = toString(values.lastName);
    values.title = toString(values.title);
    values.email = toString(values.email);
    values.address = toString(values.address);
    values.phone = values.phone ? removeMaskedPhoneNumber(values.phone) : "";
    values.city = toString(values.city);
    values.state = toString(values.state ? values.state.value : values.state);
    values.country = toString(
      values.country ? values.country.label : values.country
    );
    values.postalCode = toString(values.postalCode);

    values.stage = toString(values.stage ? values.stage.value : values.stage);
    values.amount = toString(values.amount);
    values.salesRep = toString(values.salesRep);
    values.notes = toString(values.notes);
    values.productsInvolved = ArrayList.isNotEmpty(selectedProductsInvolved)
      ? selectedProductsInvolved.join(",")
      : selectedProductsInvolved;
    values.closeDate = closeDate ? moment(closeDate).format("YYYY-MM-DD") : "";
    values.encryptedPartnerId = this.props.encryptedPartnerId || null;
    values.partnerIds = partnerIds ? partnerIds : "";
    values.partnerStatus = partnerStatus ? partnerStatus : "";
    if (shareWithAws !== "") {
      values.share_with_aws = toString(this.state.shareWithAws);
    }
    if (oppType !== "") {
      values.opportunity_type = toString(this.state.oppType);
    }
    values.website = toString(values.website);

    return values;
  }

  // Customer update API Call
  async createDeal(data) {
    this.setState({ dealRegister: true });
    if (!getCookie(COOKIE_SESSION_TOKEN))
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

    try {
      const response = await apiClient.post(`${endpoints().dealsAPI}`, data);
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
        this.setState({ dealRegister: false });
        if (this.props.publicDealForm) {
          const currentUrl = window.location.pathname + window.location.search;
          this.props.history.push(
            `/deal-registration-success?sourceUrl=${currentUrl}`
          );
        } else this.props.history.push("/deals");
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
        this.setState({ dealRegister: false });
      }
    }
  }

  handleCancelButton = () => {
    window.location.href = "/deals";
  };

  // Get Tags by tag type
  getTagsByTagType = tagType => {
    return apiClient
      .get(`${endpoints().playbookAPI}/tags/list?tagType=${tagType}`)
      .then(response => {
        const tags = response.data.data;

        if (tags.length > 0) {
          // Check the tag type products
          if (tagType === TAG_TYPE_PRODUCTS) {
            const tagPlatforms = [];
            tags.forEach(tag => {
              tagPlatforms.push({
                option: tag.name
              });
            });
            this.setState({
              productsInvolvedOptions: tagPlatforms
            });
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Set Deal Partners Ids
  setDealPartners = (partnerIds, status) => {
    this.setState({ partnerIds: partnerIds, partnerStatus: status });
  };

  handleAwsShareChange = e => {
    this.setState({
      shareWithAws: e.target.value
    });
  };

  handleOppTypeChange = e => {
    this.setState({
      oppType: e.target.value
    });
  };
  render() {
    const initialValues = {
      accountName: "",
      firstName: "",
      lastName: "",
      email: "",
      city: "",
      state: "",
      postalCode: "",
      phone: "",
      stage: "",
      country: {
        value: UNITED_STATES,
        label: UNITED_STATES
      },
      address: "",
      website: ""
    };

    let cosellWithAwsObj = CoSell.cosellWithAwsOption.find(
      item => item.value == this.state.shareWithAws
    );

    let oppTypeObj = CoSell.opportunityTypeOption.find(
      item => item.value == this.state.oppType
    );
    const {
      closeDate,
      productsInvolvedOptions,
      currentPartner,
      partnerUser,
      partnerTierCommissionPercentages,
      dealRegister
    } = this.state;

    return (
      <>
        <React.Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={values => {
              this._submit(values);
            }}
          >
            {!this.props.publicDealForm && (
              <AdminActionNavigation>
                <div className="d-flex align-items-center mb-3">
                  <a
                    className="cursor-pointer"
                    onClick={() => {
                      this.props.history.push("/deals");
                    }}
                  >
                    Deals
                  </a>
                  <span
                    className="text-dark d-inline-flex align-items-center"
                    style={{ width: "15px" }}
                  >
                    <ChevronRight />
                  </span>
                  <span
                    onClick={e => e.preventDefault()}
                    className={[
                      "text-dark",
                      "font-weight-bold",
                      "d-inline-flex",
                      "align-items-center",
                      "project-name",
                      "w-320"
                    ].join(" ")}
                  >
                    <span className="ellipsis">Register a Deal</span>
                  </span>
                </div>
                <CancelButton
                  className="ml-auto mr-1"
                  onClick={this.handleCancelButton}
                />
                <Button
                  label="Register Deal"
                  type="submit"
                  className="btn btn-primary"
                  disabled={dealRegister}
                />
              </AdminActionNavigation>
            )}

            <PageTitle label="Register a Deal" />

            <div className="card border-0">
              <div className="project-details-body">
                <div className="details-body-main">
                  <div className="card-body">
                    <DealFormFields
                      productsInvolvedOptions={productsInvolvedOptions}
                      productsInvolvedOptionSelections={
                        this.state.checkBox.productsInvolvedOptionSelections
                      }
                      onChange={this.handleChangeProductsInvolved.bind(this)}
                      setDate={this.setDate.bind(this)}
                      closeDate={closeDate}
                      defaultCountry={initialValues.country.label}
                      handleAwsShareChange={this.handleAwsShareChange}
                      shareAws={cosellWithAwsObj}
                      handleOppTypeChange={this.handleOppTypeChange}
                      oppType={oppTypeObj}
                    />
                    {this.props.publicDealForm && this.state.reCaptchaSiteKey && (
                      <div className="field-wrapper row mt-3">
                        <ReCaptcha
                          ref={ref => (this.reCaptcha = ref)}
                          sitekey={this.state.reCaptchaSiteKey}
                          action="submit"
                          verifyCallback={verify =>
                            verify && this.setState({ isUserVerified: true })
                          }
                        />
                        <div className="col-lg-12 mx-3">
                          <Button
                            label="Register Deal"
                            type="submit"
                            className="btn btn-primary"
                            disabled={dealRegister}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <SideBar
                  publicDealForm={this.props.publicDealForm}
                  dealStatus={DEAL_STATUS_DRAFT}
                  setDealPartners={this.setDealPartners}
                  currentPartner={partnerUser ? partnerUser : currentPartner}
                  partnerTierCommissionPercentages={
                    partnerTierCommissionPercentages
                  }
                />
              </div>
            </div>
          </Form>
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = state => {
  const roleId = state.user ? state.user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(RegisterDealForm);
