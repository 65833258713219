import React from "react";
import { Link } from "react-router-dom";
import { fetchList } from "../../actions/table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import PageTitle from "../../components/base/PageTitle";
import Page404 from "../Page404";
import { DropdownItem } from "reactstrap";
import MoreDropdown from "../../components/base/MoreDropdown";
import toast from "../../components/base/Toast";

import AddContactFormModal from "./AddContactFormModal";

//API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Helper
import {
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import { isBadRequest } from "../../common/http";

class ContactForms extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "form_name",
          label: "Form Name"
        }
      ]
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  // Contact form page delete api call
  handleDelete = id => {
    return apiClient
      .delete(`${endpoints().contactForm}/${id}`)
      .then(() => {
        this.props.actions.fetchList("contactForms", `/v1/contactForm`);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  render() {
    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId) &&
      !isCompanyManager(this.props.roleId)
    ) {
      return <Page404 />;
    }

    const headerButton = (
      <div className="btn-wrapper pull-right no-gutters mt-n4">
        <AddContactFormModal />
      </div>
    );

    return (
      <>
        {/* /.page-heading */}
        <PageTitle label="Contact Forms" />
        {headerButton}
        <div className="mt-4 mb-5">
          <ReduxTable
            id="contactForms"
            showHeader
            searchPlaceholder="Search"
            apiURL="/v1/contactForm/"
            onRowClick={row =>
              this.props.history.push(`/contact-form/details/${row.id}`)
            }
            newTableHeading
            sortByOptions={this.state.sortByOptions}
          >
            <ReduxColumn
              field="form_name"
              sortBy="form_name"
              type="link"
              isClickable="true"
            >
              Form Name
            </ReduxColumn>
            <ReduxColumn field="createdAt" sortBy="createdAt">
              Created
            </ReduxColumn>
            <ReduxColumn
              field="Action"
              disableOnClick
              renderField={row => (
                <div className="text-center landing-group-dropdown">
                  <MoreDropdown>
                    <Link
                      to={`/contact-form/details/${row.id}`}
                      style={{
                        color: "inherit",
                        textDecoration: "none"
                      }}
                    >
                      <DropdownItem>Edit</DropdownItem>
                    </Link>
                    <DropdownItem
                      className={"text-danger"}
                      onClick={() => this.handleDelete(row.id)}
                    >
                      Delete
                    </DropdownItem>
                  </MoreDropdown>
                </div>
              )}
            >
              Action
            </ReduxColumn>
          </ReduxTable>
        </div>
      </>
    );
  }
}

// export default ContactForms;
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(ContactForms);
