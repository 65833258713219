import React, { Component } from "react";
// Helper
import { getParamsByName } from "../../lib/helper";

// Components
import Hint from "../../components/base/Hint";
import TextArea from "../../components/base/TextArea";
import Select from "../../components/base/Select";
import toast from "../../components/base/Toast";
import SaveButton from "../../components/base/SaveButton";
import Form from "../../components/base/Form";

import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";
import { apiClient } from "../../apiClient";

export default class Details extends Component {
  constructor(props) {
    super(props);
    this.state = {
      availableForOptions: [
        {
          label: "Crossbeam",
          value: "crossbeam"
        },
        {
          label: "Gartner",
          value: "gartner"
        }
      ],
      isMediaLoading: true,
      appId: "",
      availableFor: this.props.appDetails.available_for
    };
  }

  componentDidMount() {
    this.setState({
      appId: getParamsByName("id")
    });
  }

  // Create app
  _submit = values => {
    this._updateApp(this._toArray(values), false);
  };

  //handle change
  handleChange = value => {
    if (value == null || "") {
      this.setState({
        availableFor: ""
      });
    }
  };

  // To Array
  _toArray(values) {
    const data = new FormData();
    data.append("appName", values.appName ? values.appName : "");
    data.append(
      "availableFor",
      values.availableFor ? values.availableFor.value : ""
    );
    data.append("howItWorks", values.howItWorks ? values.howItWorks : "");
    data.append(
      "installationInstructions",
      values.installationInstructions ? values.installationInstructions : ""
    );
    return data;
  }

  // update app
  _updateApp(data) {
    const id = this.state.appId;
    return apiClient
      .put(`${endpoints().appAPI}/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.getAppDetails(id);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isSubmitting: false });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    return (
      <>
        <Form
          initialValues={{
            id: this.props.appDetails.id || "",
            appName: this.props.appDetails.app_name || "",
            availableFor: this.props.appDetails.available_for
              ? this.state.availableForOptions.find(
                  availableForOptions =>
                    availableForOptions.value === this.state.availableFor
                )
              : "",
            howItWorks: this.props.appDetails.how_it_works || "",
            installationInstructions:
              this.props.appDetails.installation_instructions || ""
          }}
          onSubmit={values => {
            this._submit(values);
          }}
        >
          <div className="form-wrapper ml-4">
            <div className="field-wrapper flex-column mt-4">
              <Select
                isClearable={true}
                name="availableFor"
                label="Available For"
                fontBolded
                options={this.state.availableForOptions}
                handleChange={e => this.handleChange(e)}
                defaultValue={
                  this.state.availableFor
                    ? this.state.availableForOptions.find(
                        availableForOptions =>
                          availableForOptions.value ===
                          this.props.appDetails.available_for
                      )
                    : ""
                }
              />

              <div className="">
                <Hint hintText="This refers to which software license this integration is for" />
              </div>
            </div>
            {/* How it Works */}

            <div className="field-wrapper flex-column mt-4">
              <TextArea
                name="howItWorks"
                label="How it Works "
                placeholder="Enter description"
                fontBolded
              />
              <div className="field-wrapper">
                <Hint hintText="Describ how the integration works" />
              </div>
            </div>
            {/* Installation Instructions */}
            <div className="field-wrapper flex-column mt-4">
              <TextArea
                name="installationInstructions"
                label="Installation Instructions"
                placeholder="Enter description"
                fontBolded
              />
              <div className="">
                <Hint hintText="Provide a summary of how a user would install this integration" />
              </div>
            </div>
            <SaveButton />
          </div>
        </Form>
      </>
    );
  }
}
