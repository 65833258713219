import { apiClient } from "../apiClient";
import toast from "../components/base/Toast";
import { endpoints } from "../configs";
import { fetchList } from "./table";

import {
  REQUEST_CREATE_NEW_USER,
  RECEIVE_CREATE_NEW_USER,
  NEW_USER_CREATE_ERROR,
  REQUEST_USER_DETAIL,
  RECEIVE_USER_DETAIL,
  FETCH_USER_DETAIL_FAIL
} from "../actionType/Constants";
import {
  CUSTOMER_ROLE,
  CUSTOMER_ROLE_TEXT,
  EXPERT_ROLE,
  EXPERT_ROLE_TEXT,
  COMPANY_ADMIN_ROLE,
  SUPER_ADMIN_ROLE,
  ROLE_SUPER_ADMIN_TEXT,
  COMPANY_MANAGER_ROLE,
  PARTNER_ROLE,
  PARTNER_ROLE_TEXT
} from "../roles/Constants";
import { isBadRequest } from "../common/http";

/**
 * Request for creating new user
 */
export function requestCreateNewUser() {
  return {
    type: REQUEST_CREATE_NEW_USER
  };
}

/**
 * Receive for creating new user
 */
export function receiveCreateNewUser() {
  return {
    type: RECEIVE_CREATE_NEW_USER
  };
}

/**
 * Receive for error creating new user
 */
export function newUserCreateError(error) {
  return {
    type: NEW_USER_CREATE_ERROR,
    error
  };
}

/**
 * Create New User
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function addNewUser(data, roleId, currentParams = {}, toggle) {
  const {
    currentSuperAdminSort,
    currentSuperAdminSortDir,
    currentCustomerSort,
    currentCustomerSortDir,
    currentExpertSort,
    currentExpertSortDir,
    currentParnerSort,
    currentPartnerSortDir
  } = currentParams;
  return dispatch => {
    dispatch(requestCreateNewUser());

    return apiClient
      .post(endpoints().userAPI, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        if (roleId === SUPER_ADMIN_ROLE) {
          dispatch(
            fetchList(
              ROLE_SUPER_ADMIN_TEXT,
              `${endpoints().userAPI}/search`,
              1,
              10,
              {
                roleIds: `${SUPER_ADMIN_ROLE},${SUPER_ADMIN_ROLE}`,
                search: "",
                sort: currentSuperAdminSort,
                sortDir: currentSuperAdminSortDir,
                pagination: true
              }
            )
          );
        } else if (
          roleId === COMPANY_ADMIN_ROLE ||
          roleId === COMPANY_MANAGER_ROLE
        ) {
          dispatch(
            fetchList(
              ROLE_SUPER_ADMIN_TEXT,
              `${endpoints().userAPI}/search`,
              1,
              10,
              {
                roleIds: `${COMPANY_ADMIN_ROLE},${COMPANY_MANAGER_ROLE}`,
                search: "",
                sort: currentSuperAdminSort,
                sortDir: currentSuperAdminSortDir,
                pagination: true
              }
            )
          );
        } else if (roleId === CUSTOMER_ROLE) {
          dispatch(
            fetchList(
              CUSTOMER_ROLE_TEXT,
              `${endpoints().userAPI}/search`,
              1,
              10,
              {
                roleId: CUSTOMER_ROLE,
                search: "",
                sort: currentCustomerSort,
                sortDir: currentCustomerSortDir,
                pagination: true
              }
            )
          );
        } else if (roleId === EXPERT_ROLE) {
          dispatch(
            fetchList(
              EXPERT_ROLE_TEXT,
              `${endpoints().userAPI}/search`,
              1,
              10,
              {
                roleId: EXPERT_ROLE,
                search: "",
                sort: currentExpertSort,
                sortDir: currentExpertSortDir,
                pagination: true
              }
            )
          );
        } else if (roleId === PARTNER_ROLE) {
          dispatch(
            fetchList(
              PARTNER_ROLE_TEXT,
              `${endpoints().userAPI}/search`,
              1,
              10,
              {
                roleId: PARTNER_ROLE,
                search: "",
                sort: currentParnerSort,
                sortDir: currentPartnerSortDir,
                pagination: true
              }
            )
          );
        } else {
          dispatch(
            fetchList(
              PARTNER_ROLE_TEXT,
              `${endpoints().userAPI}/search`,
              1,
              10,
              {
                roleIds: `${COMPANY_ADMIN_ROLE}, ${COMPANY_MANAGER_ROLE}, ${CUSTOMER_ROLE}, ${EXPERT_ROLE}, ${PARTNER_ROLE}`,
                search: "",
                sort: currentParnerSort,
                sortDir: currentPartnerSortDir,
                pagination: true
              }
            )
          );
        }
        //dispatch(fetchList("Super Admin", `${endpoints().userAPI}`, 1, 10));
        dispatch(receiveCreateNewUser());
        setTimeout(() => {
          toggle();
        }, 500);
      })
      .catch(error => {
        dispatch(newUserCreateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Request for user details
 */
function requestUserDetail() {
  return { type: REQUEST_USER_DETAIL };
}

/**
 * Receive user details
 */
function receiveUserDetail(payload) {
  return { type: RECEIVE_USER_DETAIL, payload };
}

/**
 * Receive user details fail
 */
function fetchUserDetailFail(error) {
  return { type: FETCH_USER_DETAIL_FAIL, error };
}

/**
 * Get user details
 *
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function fetchUserDetail() {
  return dispatch => {
    dispatch(requestUserDetail());

    return apiClient
      .get(`/v1/user`)
      .then(response => {
        dispatch(receiveUserDetail(response.data));
      })
      .catch(error => {
        dispatch(fetchUserDetailFail(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}
