import React from "react";
import {
  DownloadIcon,
  ImageIcon,
  FileIcon,
  TrashIcon
} from "../../../assets/img/icons";
import { isExpert } from "../../../lib/helper";
import { PROJECT_USER_STATUS_ACCEPTED } from "../../../project/Constants";
import { useSelector } from "react-redux";

const SupportingMaterials = props => {
  const {
    onMaterialUpload,
    data,
    projectUserStatus,
    deleteSupportMaterial
  } = props;
  const imageType = ["jpg", "jpeg", "gif", "png"];
  const currentUser = useSelector(state => state.user);

  return (
    <div>
      <div className="supporting-materials">
        {data && data.length > 0
          ? data.map(material => (
              <div className="supporting-material">
                <div className="icon">
                  {imageType.includes(material.name.split(".").slice(-1)[0]) ? (
                    <ImageIcon />
                  ) : (
                    <FileIcon />
                  )}
                </div>
                <div className="name ellipsis mr-1">
                  <a href={material.fileUrl} download={material.name}>
                    {material.name}
                  </a>
                </div>
                <div className="action d-flex">
                  <a href={material.fileUrl} download={material.name}>
                    <DownloadIcon />
                  </a>
                  <span
                    onClick={e => {
                      e.preventDefault();
                      deleteSupportMaterial(material.id, material.name);
                    }}
                  >
                    <TrashIcon />
                  </span>
                </div>
              </div>
            ))
          : ""}
      </div>

      <div
        className={[
          "btn-wrapper mt-3",
          `${
            isExpert(currentUser && currentUser.roleId) &&
            projectUserStatus &&
            projectUserStatus !== PROJECT_USER_STATUS_ACCEPTED
              ? "disabled"
              : ""
          }`
        ].join(" ")}
      >
        <input
          name="file"
          className="form-control d-none"
          type="file"
          id="projectMaterial"
          onChange={e => onMaterialUpload(e)}
          accept=".docx, .doc, application/*, image/*, text/*"
        />
        <label htmlFor="projectMaterial" className="btn btn-primary w-100">
          Add Supporting Materials
        </label>
      </div>
    </div>
  );
};

export default SupportingMaterials;
