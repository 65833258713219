import React, { useState, useRef, useEffect } from "react";
import { Card, CardBody } from "reactstrap";
import Button from "../../components/base/Button";
import { apiClient } from "../../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../../configs";
import Toast from "../../components/base/Toast";
import { metaCXTrack } from "../../lib/metaCX";
import {
  COOKIE_DEFAULT_CURRENCY,
  COOKIE_SESSION_TOKEN,
  COOKIE_USER_ID
} from "../../lib/cookie";
import { getCookie, setCookie } from "../../lib/helper";
import { Local } from "../../Constants/LocalStorage";
import UserRolePermissionService from "../../services/UserRolePermissionService";
import { TAG_TYPE_CURRENCY } from "../../tagType/Constants";
import Tag from "../../Constants/Tag";
import { isBadRequest } from "../../common/http";

const TwoFactorAuth = props => {
  const [otp, setOtp] = useState(Array(6).fill(""));
  const [isEmpty, setIsEmpty] = useState(false);
  const inputRefs = useRef(Array(6).fill(null));

  const urlParams = new URLSearchParams(window.location.search);

  const email = urlParams.get("email");

  const handleInputChange = (e, index) => {
    const value = e.target.value;

    if (/^[0-9]$/.test(value)) {
      const newOtp = [...otp];
      newOtp[index] = value;
      setOtp(newOtp);

      if (index < 6 - 1 && value !== "") {
        inputRefs.current[index + 1].focus();
      }
    }

    if (e.key === "Backspace") {
      if (inputRefs.current[0] == "") {
        e.preventDefault();
      }
      const newOtp = [...otp];
      newOtp[index] = "";

      if (index > 0) {
        setOtp(newOtp);
        inputRefs.current[index - 1].focus();
      } else {
        setOtp(newOtp);
      }
    }
  };

  useEffect(() => {
    inputRefs.current[0].focus();
  }, []);

  let height = window.innerHeight;

  const cardCenter = (height / 100) * 30;

  const getTerminology = async () => {
    setHeaders();
    const url = `${endpoints().terminologyAPI}`;
    const customName = [];
    const response = await apiClient.get(url);
    const list = response.data.data;
    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        customName.push({
          custom_name: list[i].custom_name,
          system_name: list[i].system_name
        });
      }
    }
    localStorage.setItem(Local.TERMINOLOGY, JSON.stringify(customName));
  };

  const setHeaders = () => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
  };
  const getTagValues = async tagType => {
    try {
      setHeaders();

      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${tagType}`
      );
      const results = response.data.data;

      // Check the tag type skills
      if (results && results.length > 0) {
        results.forEach(tag => {
          if (
            tag.type == TAG_TYPE_CURRENCY &&
            tag.defaultValue == Tag.IsDefault
          ) {
            setCookie(COOKIE_DEFAULT_CURRENCY, tag.name);
          }
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  const getRolePermission = async role => {
    setHeaders();
    const permissionList = await UserRolePermissionService.search(role);
    if (permissionList && permissionList.length > 0) {
      let values = permissionList.map(obj => obj.value);

      // Convert the array to a comma-separated string
      let valuesString = values.join(",");

      localStorage.setItem(Local.PERMISSION, valuesString);
    }
  };

  const submit = async () => {
    const isAnyEmpty = otp.some(value => value === "");
    setIsEmpty(isAnyEmpty);

    if (!isAnyEmpty) {
      let data = {
        verificationCode: otp.join(""),
        email: email
      };
      await apiClient
        .post(`${endpoints().userAPI}/verifyAccount`, data)
        .then(async response => {
          if (response && response) {
            let value = response.data && response.data.data;
            if (response.status == 200 && value) {
              Toast.success(response.data.message);

              setCookie(COOKIE_SESSION_TOKEN, value && value.token);
              setCookie(COOKIE_USER_ID, value && value.id);

              await getTerminology();
              await getTagValues();
              await getRolePermission(value && value.role);
              metaCXTrack(
                props.eventKey || "user-login",
                {
                  email: value && value.email,
                  firstName: value && value.first_name,
                  lastName: value && value.last_name
                },
                props.settings,
                () => {}
              );
              window.location.replace("./dashboard");
            }
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
              Toast.error(error.response.data.message);
            }
            return { errorMessage } || {};
          }
        });
    }
  };

  return (
    <div className="container-fluid" style={{ height: height }}>
      <div className="container">
        <div className="row justify-content-center">
          <div
            className="col-lg-7 col-md-7 col-sm-12"
            style={{ paddingTop: cardCenter }}
          >
            <Card className="w-75 mx-auto rounded">
              <CardBody className="pb-0">
                <div className="text-center pb-3">
                  <div className="font-weight-bold">
                    Two Factor Authentication
                  </div>
                  <small>
                    Please enter the authentication code sent to your registered
                    email.
                  </small>
                </div>
                <div className="d-flex mb-3">
                  {otp.map((value, index) => (
                    <input
                      key={index}
                      type="text"
                      value={value}
                      onChange={e => handleInputChange(e, index)}
                      onKeyDown={e => handleInputChange(e, index)}
                      maxLength="1"
                      style={{
                        borderColor: isEmpty && value === "" ? "red" : ""
                      }}
                      className="form-control mx-2 text-center"
                      ref={el => (inputRefs.current[index] = el)}
                    />
                  ))}
                </div>
                {isEmpty && (
                  <div className="text-danger">
                    Please fill in all the fields.
                  </div>
                )}
              </CardBody>
              <div className="pr-2">
                <Button
                  type="submit"
                  color="#E0674C"
                  hoverColor="#ff876c"
                  label="Verify"
                  className="rounded-0 text-white w-100 font-weight-bold"
                  onClick={submit}
                />
              </div>
            </Card>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TwoFactorAuth;
