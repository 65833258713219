import React, { useState } from "react";

import { InlineInfoIcon, CrossIcon } from "../../../assets/img/icons";

import { Alert } from "reactstrap";
import { Link } from "react-router-dom";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

const PaymentPreference = () => {
  const [isPayoutBanner, setIsPayoutBanner] = useState(true);
  const closeToggle = () => setIsPayoutBanner(!isPayoutBanner);
  return (
    <div className="alerts-wrapper mb-2">
      <Alert
        isOpen={isPayoutBanner}
        color="danger"
        className={
          "d-flex align-items-center justify-content-stretch text-white"
        }
      >
        <InlineInfoIcon />
        <p className="ml-2 mb-0 font-weight-bold mr-auto text-white">
          To get your {Terminology.get(SystemName.REFERRAL)} earnings, please
          setup your payout preferences.
        </p>
        <Link to={`/edit/partner/public-profile?section=payout-preferences`}>
          <div className="btn-wrapper ml-auto mr-3">
            <button
              type="button"
              className="btn font-weight-bold outline-bold text-white p-0 plain-btn"
            >
              Setup Payout Preferences
            </button>
          </div>
        </Link>
        <div className="close-alert mr-0 ml-0">
          <button
            className="btn-plain btn text-white p-0"
            type="button"
            onClick={() => closeToggle()}
          >
            <CrossIcon />
          </button>
        </div>
      </Alert>
    </div>
  );
};

export default PaymentPreference;
