import React from "react";
import { TabContent, TabPane } from "reactstrap";

// Constants
import {
  SYSTEM_TAB,
  TAB_ACTIVITIES,
  TAB_COMPANIES,
  TAB_GENERAL,
  TAB_IMPORT,
  TAB_LIST,
  TAB_SALESFORCE,
  TAB_SCHEDULER,
  TAB_SYSTEM_LOGS
} from "../../../supportPortal/Constants";

// Icon
import { ChevronRight } from "../../../assets/img/icons";

// Tab Sections
import {
  BOMBORA_TAB,
  CROSS_BEAM_TAB,
  REFERRAL_TAB,
  SUPER_ADMIN_SUB_TAB,
  USERS_TAB
} from "../../..//setting/Constants";
import CrossBeam from "../../crossBeam";
import ActivitiesTab from "../../reports/tabs/ActivitiesTab";
import ManageLists from "../ManageLists";
import Bombora from "../tabs/Bombora";
import CompaniesTab from "../tabs/CompaniesTab/";
import ImportTab from "../tabs/ImportTab";
import JobsTab from "../tabs/JobsTab/";
import ReferralTab from "../tabs/ReferralTab";
import SystemLogsTab from "../tabs/SystemLogTab/";
import SystemTab from "../tabs/SystemTab/";
import UsersTab from "../tabs/UsersTab/index";
import SalesforceTab from "../tabs/salesforce";
import Scheduler from "../tabs/schedulerJobs/Schedulerjobs";

const TabContentSection = props => {
  const {
    activeTab,
    activeSubTab,
    activeSection,
    activeSubSection,
    toggleTabs,
    history,
    match,
    adminSettings
  } = props;

  let sortByOptions = [];
  if (activeTab === TAB_COMPANIES) {
    sortByOptions.push(
      {
        value: "name:ASC",
        label: "Company Name"
      },
      {
        value: "createdAt:DESC",
        label: "Most Recent"
      }
    );
  }
  if (activeTab === CROSS_BEAM_TAB) {
    sortByOptions.push(
      {
        value: "company_name:ASC",
        label: "Company Name"
      },
      {
        value: "createdAt:ASC",
        label: "Most Recent"
      }
    );
  }
  if (activeTab === BOMBORA_TAB) {
    sortByOptions.push(
      {
        value: "company_name:ASC",
        label: "Company Name"
      },
      {
        value: "createdAt:ASC",
        label: "Most Recent"
      }
    );
  }

  return (
    <TabContent activeTab={activeTab}>
      {activeTab === USERS_TAB && activeSubTab && activeSection ? (
        ""
      ) : activeTab === TAB_COMPANIES && activeSubTab && activeSection ? (
        ""
      ) : (
        <>
          {/* Breadcrumb Start */}
          <div className="d-flex align-items-center pt-4 pb-3">
            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(`/support-portal/${TAB_COMPANIES}`);
              }}
            >
              Support Portal
            </span>
            <span
              className={`${
                activeSubTab ? "" : "text-dark font-weight-bold "
              } d-inline-flex pt-1 `}
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <span
              className={
                activeSubTab ? "cursor-pointer" : "text-dark font-weight-bold "
              }
              onClick={() => {
                if (activeSubTab) history.push(`/support-portal/${activeTab}/`);
              }}
            >
              <span>{activeTab}</span>
            </span>
            {activeSubTab && (
              <>
                <span
                  className={`${
                    activeSection ? "" : "text-dark font-weight-bold"
                  } d-inline-flex pt-1 `}
                  style={{ width: "15px" }}
                >
                  <ChevronRight />
                </span>
                <span
                  className={
                    activeSection
                      ? "cursor-pointer"
                      : "text-dark font-weight-bold text-capitalize"
                  }
                  onClick={() => {
                    if (activeSection)
                      history.push(
                        `/support-portal/${activeTab}/${activeSubTab}`
                      );
                  }}
                >
                  <span>{activeSubTab}</span>
                </span>
              </>
            )}
            {activeSection && (
              <>
                <span
                  className="text-dark font-weight-bold d-inline-flex pt-1"
                  style={{ width: "15px" }}
                >
                  <ChevronRight />
                </span>
                <span
                  onClick={e => e.preventDefault()}
                  className={"text-dark font-weight-bold"}
                >
                  <span>{activeSection}</span>
                </span>
              </>
            )}
          </div>
          {/* Breadcrumb End */}
          {/* <h5 className=" mt-3 mb-4 text-left font-weight-bold">
            <span className="pull-right" style={{ float: "top right" }}></span>
          </h5> */}
        </>
      )}

      {/* Companies Tab Start */}
      <TabPane tabId={TAB_COMPANIES}>
        <div className="tab-content-wrapper">
          <CompaniesTab
            activeTab={activeTab}
            activeSubTab={activeSubTab}
            activeSection={activeSection}
            history={history}
            match={match}
            sortByOptions={sortByOptions}
          />
        </div>
      </TabPane>
      {/* Companies Tab End */}

      {/*User Tab start*/}
      <TabPane tabId={USERS_TAB}>
        <div className="tab-content-wrapper">
          <UsersTab
            activeTab={activeTab}
            activeSubTab={activeSubTab || SUPER_ADMIN_SUB_TAB}
            activeSection={activeSection}
            adminSettings={adminSettings}
            toggleTabs={toggleTabs}
            history={history}
            match={match}
          />
        </div>
      </TabPane>
      {/*User Tab end*/}

      {/* SystemLogs Tab Start */}
      <TabPane tabId={TAB_SYSTEM_LOGS}>
        <div className="tab-content-wrapper">
          <SystemLogsTab
            activeTab={activeTab}
            activeSubTab={activeSubTab}
            activeSection={activeSection}
            history={history}
            match={match}
          />
        </div>
      </TabPane>
      {/* SystemLogs Tab End */}

      {/* List Tab Start */}
      <TabPane tabId={TAB_LIST}>
        <div className="tab-content-wrapper">
          <ManageLists
            activeTab={activeTab}
            activeSubTab={activeSubTab || TAB_LIST}
            activeSection={activeSection}
            adminSettings={adminSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>

      <TabPane tabId={TAB_SALESFORCE}>
        <div className="tab-content-wrapper">
          <SalesforceTab
            activeTab={activeTab}
            activeSubTab={activeSubTab}
            activeSection={activeSection}
            activeSubSection={activeSubSection}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>
      <TabPane tabId={TAB_ACTIVITIES}>
        <div className="tab-content-wrapper">
          <ActivitiesTab
            history={history}
            activeTab={TAB_ACTIVITIES}
            settings={props.settings}
          />
        </div>
      </TabPane>

      {/* List Tab End */}

      {/* Jobs Settings Start */}
      <TabPane tabId={TAB_SCHEDULER}>
        <div className="tab-content-wrapper">
          {/* Scheduler Settings Start */}
          <TabPane tabId={TAB_SCHEDULER}>
            <div className="tab-content-wrapper">
              <Scheduler
                activeTab={activeTab}
                activeSubTab={activeSubTab}
                activeSection={activeSection}
                adminSettings={adminSettings}
                toggleTabs={toggleTabs}
                history={history}
              />
            </div>
          </TabPane>
          {/* Scheduler Settings End */}

          <JobsTab
            activeTab={activeTab}
            activeSubTab={activeSubTab}
            activeSection={activeSection}
            adminSettings={adminSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>
      {/* Jobs Settings End */}

      {/* Import Settings Start */}
      <TabPane tabId={TAB_IMPORT}>
        <div className="tab-content-wrapper">
          <ImportTab history={history} />
        </div>
      </TabPane>
      {/* Import Settings End */}

      {/*List Tab start*/}
      <TabPane tabId={SYSTEM_TAB}>
        <div className="tab-content-wrapper">
          <SystemTab
            activeTab={activeTab}
            activeSubTab={activeSubTab || TAB_GENERAL}
            adminSettings={adminSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>

      {/*List Tab start*/}
      <TabPane tabId={REFERRAL_TAB}>
        <div className="tab-content-wrapper">
          <ReferralTab
            activeTab={activeTab}
            activeSubTab={activeSubTab || TAB_GENERAL}
            adminSettings={adminSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </div>
      </TabPane>

      {/*List Tab start*/}
      <TabPane tabId={BOMBORA_TAB}>
        <div className="tab-content-wrapper">
          <Bombora
            activeTab={activeTab}
            activeSubTab={activeSubTab || TAB_GENERAL}
            adminSettings={adminSettings}
            toggleTabs={toggleTabs}
            history={history}
            sortByOptions={sortByOptions}
          />
        </div>
      </TabPane>
      <TabPane tabId={CROSS_BEAM_TAB}>
        <div className="tab-content-wrapper">
          <CrossBeam
            activeTab={activeTab}
            activeSubTab={activeSubTab || TAB_GENERAL}
            adminSettings={adminSettings}
            toggleTabs={toggleTabs}
            history={history}
            sortByOptions={sortByOptions}
          />
        </div>
      </TabPane>
      {/*List Tab end*/}
    </TabContent>
  );
};

export default TabContentSection;
