import React from "react";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

const PaymentSuccessSection = ({ invoiceDetails, isReferral }) => {
  return (
    <div>
      <div
        className="card border-0 text-white mb-4"
        style={{ backgroundColor: "#0AC297" }}
      >
        <div className="project-details-body">
          <div className="details-body-main">
            <div className="card-body pt-0">
              <div className="certification mb-2">
                <span
                  className="badge badge-pill badge-primary"
                  style={{ backgroundColor: "#292934", marginLeft: "122px" }}
                >
                  PAID
                </span>
              </div>
              <h4 className="font-weight-bold">Congratulations!</h4>
              <p className="font-weight-bold">
                This{" "}
                {isReferral == true
                  ? `${Terminology.get(SystemName.REFERRAL)}`
                  : "invoice"}{" "}
                has been paid in full.
              </p>

              {/* <div className="btn-wrapper">
                <button
                  className="btn btn-primary px-5 mr-2 text-white"
                  onClick={() => {
                    // edit payment action
                  }}
                >
                  Edit Payment
                </button>
              </div> */}
            </div>
          </div>

          <div className="details-body-sidebar">
            <div className={["sidebar-item"].join(" ")}>
              <p className="font-weight-bold mb-0">Amount Paid</p>
              <h5 className="font-weight-bold mt-0">
                ${invoiceDetails.amount ? invoiceDetails.amount : 0}
              </h5>
              <p className="font-weight-bold mt-3 mb-0">Paid Date:</p>
              <h5 className="font-weight-bold mt-0">
                {invoiceDetails.paidAt ? invoiceDetails.paidAt : ""}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentSuccessSection;
