import React, { useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { ChevronDown } from "../../assets/img/icons";
import Avatar from "../../components/base/Avatar";

const UserDropdown = ({
  id,
  taskIndex,
  assignee,
  userlist,
  updateAssignee,
  setNewAssignee,
  currentUserId
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const toggle = () => setDropdownOpen(prevState => !prevState);

  return (
    <div className="dropdown-wrapper">
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className={["user-dropdown"].join(" ")}
      >
        <DropdownToggle
          aria-expanded={dropdownOpen}
          className={["h7", "dropdown-toggle", "hide-caret"].join(" ")}
          caret
          color={"gray"}
        >
          {Object.keys(assignee).length > 0 ? (
            <>
              <span className="user-badge">
                <Avatar
                  id={id}
                  firstName={assignee.firstName}
                  lastName={assignee.lastName}
                  url={assignee.picture}
                  size="customSize"
                  imageSize={"20"}
                  fontSize={8}
                ></Avatar>
                <span style={{ marginLeft: "5px" }}>
                  {currentUserId == assignee.id ? "You" : assignee.name}
                </span>
              </span>
            </>
          ) : (
            <span className={["no-assignee"].join(" ")}>Assignee...</span>
          )}
          <ChevronDown />
        </DropdownToggle>
        <DropdownMenu>
          {userlist.map(user => (
            <button
              className={`dropdown-item ${
                user.id === assignee.id ? "active-selection" : ""
              }`}
              onClick={() => {
                toggle();
                updateAssignee(taskIndex, {
                  id: user.id,
                  name: user.name,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  picture: user.picture,
                  partnerId: user.partnerId
                });
                setNewAssignee &&
                  setNewAssignee({
                    id: user.id,
                    name: user.name,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    picture: user.picture,
                    partnerId: user.partnerId
                  });
              }}
            >
              <span className={`user-badge d-inline-flex w-100`}>
                <Avatar
                  firstName={user.firstName}
                  lastName={user.lastName}
                  url={user.picture}
                  size="customSize"
                  imageSize={"20"}
                  fontSize={8}
                ></Avatar>
                <span style={{ marginLeft: "5px" }}>
                  {currentUserId == user.id ? "You" : user.name}
                </span>
                <span className="ml-auto">
                  <i
                    className={`d-flex justify-content-end ${
                      user.id === assignee.id ? "fas fa-check" : ""
                    }`}
                    style={{ color: "#009DDA" }}
                  ></i>
                </span>
              </span>
            </button>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default UserDropdown;
