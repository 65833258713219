import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import Form from "../../../components/base/Form";
import TextArea from "../../../components/base/TextArea";
import CancelButton from "../../../components/base/CancelButton";

const DeclineExpertModal = ({
  modal,
  setModal,
  expertId,
  projectId,
  declineMatchedExpert
}) => {
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={setModal}
        className={["edit-task-modal"].join(" ")}
      >
        <ModalHeader toggle={setModal}>
          <div
            className={["d-flex", "flex-column", "align-items-center"].join(
              " "
            )}
          >
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
              Decline This Expert
            </h4>
          </div>
        </ModalHeader>
        <Form
          initialValues={{
            declinedMessage: ""
          }}
          onSubmit={values => {
            declineMatchedExpert(expertId, projectId, values.declinedMessage);

            setTimeout(() => {
              setModal();
            }, 500);
          }}
        >
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="form-wrapper">
              <p className="text-center">
                No worries,{" "}
                <b>we'll find a new expert for you within 24 hours.</b> Can you
                tell us why you declined this expert?
              </p>

              <hr />

              <div className="field-wrapper">
                <TextArea
                  label="Your Message"
                  name="declinedMessage"
                  placeholder="Your Message..."
                  error="Your message is required"
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="btn-wrapper">
              <CancelButton onClick={setModal} />
              <button className="btn btn-primary" type={"submit"}>
                Decline Expert
              </button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default DeclineExpertModal;
