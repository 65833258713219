import React from "react";
import { endpoints } from "../configs";
import { apiClient } from "../apiClient";
import { BlueMessageIcon, MessageCircleIcon } from "../assets/img/icons";
import useToggle from "./customhooks/useToggle";
import Form from "./base/Form";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import TextArea from "./base/TextArea";
import toast from "./base/Toast";
import CancelButton from "../components/base/CancelButton";
import { isBadRequest } from "../common/http";

const LetsTalkModal = ({ isOpen, toggle }) => {
  const handleSubmit = values => {
    apiClient
      .post(`${endpoints().faqAPI}/ask/help`, {
        message: values.message
      })
      .then(response => {
        toggle();
        toast.success(response.data.message);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={["edit-task-modal"].join(" ")}
      backdrop="static"
    >
      <Form
        initialValues={{ message: "" }}
        onSubmit={values => {
          handleSubmit(values);
        }}
      >
        <ModalHeader toggle={toggle}>
          <div
            className={["d-flex", "flex-column", "align-items-center"].join(
              " "
            )}
          >
            <BlueMessageIcon />
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
              Let's Talk
            </h4>
          </div>
        </ModalHeader>
        <ModalBody className={["mb-4", "px-0"].join(" ")}>
          <div className={["text-center", "modal-body-subtitle"].join(" ")}>
            <p>
              Send a message to the support team by using the textarea below:
            </p>
          </div>
          <div className="form-wrapper justify-content-center d-flex">
            <div className="field-wrapper w-100">
              <TextArea
                name="message"
                label="Your message"
                placeholder="Your message..."
                rows="5"
                error="Your message is required"
                required
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={toggle} />
            <Button type="submit">Send Message</Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const LetsTalk = () => {
  const [modal, setModal] = useToggle();

  return (
    <div className="lets-talk ml-auto d-flex align-items-center">
      <p className={"mb-0 mr-3"}>
        Not finding the help you were looking for here?
      </p>
      <button
        className="btn btn-outline-primary d-inline-flex"
        onClick={() => setModal(true)}
      >
        <MessageCircleIcon /> Let's Talk!
      </button>

      <LetsTalkModal isOpen={modal} toggle={setModal} />
    </div>
  );
};

export default LetsTalk;
