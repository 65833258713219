import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import moment from "moment";

// Configs
import { endpoints } from "../../../../configs";
import {
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  getParamsByName,
  getKeyValueByObject
} from "../../../../lib/helper";

// API call
import { apiClient } from "../../../../apiClient";

//Assets
import { DownloadIcon } from "../../../../assets/img/icons";

// Action
import { fetchList } from "../../../../actions/table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import Form from "../../../../components/base/Form";
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import UserSelector from "../../../../components/UserSelector";
import DateInput from "../../../../components/base/Date";

// Constants
import { SETTINGS_THEME_LAYOUT } from "../../../../setting/Constants";

// 404 Page
import Page404 from "../../../Page404";
import { isBadRequest } from "../../../../common/http";
import PageSize from "../../../../components/PageSize";
class Activities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageNotFound: false,
      startDate: "",
      endDate: "",
      status: "",
      searchUserId: "",
      activityReportCsv: [],
      pageSize: getParamsByName("pageSize")
    };
  }
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this._getActivityReoprtDetails();
  }
  // piplinedetails details api call
  _getActivityReoprtDetails = async () => {
    try {
      const response = await apiClient.get(`${endpoints().activity}`);

      const activityData = [];
      response.data.data.forEach(data => {
        activityData.push({
          userName: data.userName,
          companyName: data.companyName,
          activity_type: data.activity_type,
          createdAt: data.createdAt
        });
      });
      this.setState({ activityReportCsv: activityData, loading: false });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  //download csv of selected user
  downloadCSV = async () => {
    const { startDate, endDate, searchUserId } = this.state;

    const response = await apiClient.get(
      `${
        endpoints().activity
      }?startDate=${startDate}&endDate=${endDate}&searchUserId=${searchUserId}`
    );
    const activityData = [];
    response.data &&
      response.data.data.forEach(data => {
        activityData.push({
          userName: data.userName,
          companyName: data.companyName,
          activity_type: data.activity_type,
          createdAt: data.createdAt
        });
      });
    this.setState({ activityReportCsv: activityData, loading: false });
  };

  handleStartDate = startDate => {
    this.setState({ startDate: startDate ? startDate : "" }, () => {
      this.fetchData();
      this.updateUrl();
    });
  };

  handleEndDate = endDate => {
    this.setState({ endDate: endDate ? endDate : "" }, () => {
      this.fetchData();
      this.updateUrl();
    });
  };

  handleUserChange = values => {
    const searchUserId = values && values.value ? values.value : "";
    this.setState({ searchUserId: searchUserId }, () => {
      this.fetchData();
      this.updateUrl();
      this.downloadCSV();
    });
  };

  fetchData = () => {
    const { startDate, endDate, searchUserId, pageSize } = this.state;
    this.props.actions.fetchList(
      "activities",
      `${endpoints().activity}`,
      1,
      pageSize ? pageSize : 10,
      {
        startDate,
        endDate,
        searchUserId,
        pagination: true
      }
    );
  };
  updateUrl() {
    const { searchUserId, startDate, endDate, pageSize } = this.state;

    const params = [];

    if (searchUserId) {
      params.push(`Userid=${searchUserId}`);
    }

    if (startDate) {
      params.push(`startDate=${startDate.toLocaleDateString("en-US")}`);
    }

    if (endDate) {
      params.push(`endDate=${endDate.toLocaleDateString("en-US")}`);
    }

    params.push(`pageSize=${pageSize}`);

    if (params.length > 0) {
      this.props.history.push(`/reports/user-activities/?${params.join("&")}`);
    }
  }

  handlePageSizeChange = e => {
    this.setState({ pageSize: e }, () => {
      this.fetchData();
      this.updateUrl();
      this.downloadCSV();
    });
  };

  render() {
    // Page Not Found
    if (this.state.isPageNotFound) {
      return <Page404 />;
    }

    const DefaultLayout = this.props.settings
      ? getKeyValueByObject(this.props.settings, SETTINGS_THEME_LAYOUT)
      : "";

    const headers = [
      { label: "User", key: "userName" },
      { label: "Partner", key: "companyName" },
      { label: "Activity", key: "activity_type" },
      { label: "createdAt", key: "createdAt" }
    ];

    return (
      <div>
        {/* <PageTitle label="Activities" /> */}

        <div>
          <div className={`page-heading cover`}>
            <div className="row">
              <div
                className={
                  DefaultLayout == "Fluid Width"
                    ? "col-1 col-sm-1 col-md-1 col-lg-1 activties-page-size"
                    : "col-sm-1 mr-4"
                }
              >
                <PageSize onChange={e => this.handlePageSizeChange(e)} />
              </div>
              <div
                className={
                  DefaultLayout == "Fluid Width"
                    ? "col-3 col-sm-3 col-md-3 col-lg-3"
                    : "col-sm-3"
                }
              >
                <Form>
                  <DateInput
                    name="startDate"
                    className="datepicker"
                    placeholder="Start Date"
                    onChange={this.handleStartDate}
                    maxDate={new Date(this.state.endDate)}
                    selected={this.state.startDate}
                    isClearable
                  />
                </Form>
              </div>
              <div
                className={
                  DefaultLayout == "Fluid Width"
                    ? "col-3 col-sm-3 col-md-3 col-lg-3"
                    : "col-sm-3"
                }
              >
                <Form>
                  <DateInput
                    name="endDate"
                    className="datepicker"
                    placeholder="End Date"
                    onChange={this.handleEndDate}
                    minDate={new Date(this.state.startDate)}
                    selected={this.state.endDate}
                    isClearable
                  />
                </Form>
              </div>
              <div
                className={
                  DefaultLayout == "Fluid Width"
                    ? "col-4 col-sm-4 col-md-4 col-lg-4"
                    : "col-sm-3"
                }
              >
                <Form>
                  <UserSelector
                    name="user"
                    placeholder="Select User"
                    isSearchable={true}
                    isClearable={true}
                    handleChange={this.handleUserChange}
                    width="auto"
                  />
                </Form>
              </div>
              <div
                className={
                  DefaultLayout == "Fluid Width"
                    ? "col-1 col-sm-1 col-md-1 col-lg-1 mt-2 d-flex justify-content-end"
                    : "col-1 col-sm-1 col-md-1 col-lg-1 d-flex justify-content-end p-0 ml-4 mt-1"
                }
              >
                <CSVLink
                  data={this.state.activityReportCsv}
                  headers={headers}
                  filename={`Activity_Report_${moment().format(
                    "YYYY-MM-DD"
                  )}.csv`}
                >
                  <span className="download-pdf ml-auto d-flex" href="#">
                    <DownloadIcon />
                    <span className="ml-1">Export</span>
                  </span>
                </CSVLink>
              </div>
            </div>
          </div>
          <ReduxTable
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            id="activities"
            apiURL={`${endpoints().activity}`}
            newTableHeading
            params={
              this.state.searchUserId
                ? { searchUserId: this.state.searchUserId }
                : ""
            }
          >
            <ReduxColumn width="150px" field="createdAt" sortBy="createdAt">
              Date
            </ReduxColumn>
            <ReduxColumn width="100px" field="userName" sortBy="userName">
              User
            </ReduxColumn>
            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) && (
              <ReduxColumn
                width="100px"
                field="companyName"
                sortBy="companyName"
              >
                Partner
              </ReduxColumn>
            )}
            <ReduxColumn
              width="150px"
              field="activity_type"
              sortBy="activity_type"
            >
              Activity
            </ReduxColumn>
            <ReduxColumn width="100px" field="notes" sortBy="notes">
              Notes
            </ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(Activities);
