import React, { Component } from "react";
import { Label, Input } from "reactstrap";

class Checkbox extends Component {
  render() {
    const {
      selectedOptions,
      options,
      name,
      title,
      handleChange,
      titleFontBold,
      className
    } = this.props;
    return (
      <div className="TextAlignment">
        <label
          htmlFor={name}
          className={[
            "form-label",
            `${titleFontBold ? "font-weight-bold" : ""}`,
            className
          ].join(" ")}
        >
          {title}
        </label>
        <div className="checkbox" style={{ marginLeft: "29px" }}>
          {options.map(option => {
            return (
              <div>
                <Label key={option} className="checkbox-inline">
                  <Input
                    id={option}
                    name={name}
                    onChange={handleChange}
                    value={option}
                    checked={selectedOptions.indexOf(option) > -1}
                    type="checkbox"
                  />{" "}
                  <span className="checkbox-btn-label">{option}</span>
                </Label>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Checkbox;
