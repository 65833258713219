class ArrayList {
  static isNotEmpty(value) {
    if (value && value.length > 0) {
      return true;
    } else {
      return false;
    }
  }
}
export default ArrayList;
