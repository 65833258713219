import React from "react";
import DefaultContent from "../../../components/content/DefaultContent";
import SaveButton from "../../../components/base/SaveButton";
import Form from "../../../components/base/Form";
import {
  ALLOW_UPGRADE,
  UPGRADE_HUBSPOT_FORM_URL,
  SETTINGS_PASSWORD_EXPIRY_DAYS
} from "../../../setting/Constants";
import SingleCheckbox from "../../../components/base/SingleCheckbox";
import Text from "../../../components/base/Text";

// Components

const Settings = props => {
  const { adminSettings, saveSettings } = props;

  const {
    allow_upgrade,
    upgrade_hubspot_form_url,
    password_expiry_days
  } = adminSettings;

  // General Settings General Section Initial Values
  const generalSettingsGeneralSectionInitialValues = {
    allow_upgrade: allow_upgrade === "true",
    upgrade_hubspot_form_url,
    password_expiry_days
  };

  // General Settings General Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Marketplace name
    if (values.allow_upgrade !== undefined) {
      data.append(
        ALLOW_UPGRADE,
        values.allow_upgrade ? values.allow_upgrade : ""
      );
    }
    if (values.upgrade_hubspot_form_url !== undefined) {
      data.append(
        UPGRADE_HUBSPOT_FORM_URL,
        values.upgrade_hubspot_form_url ? values.upgrade_hubspot_form_url : ""
      );
    }
    if (values.password_expiry_days !== undefined) {
      data.append(
        SETTINGS_PASSWORD_EXPIRY_DAYS,
        values.password_expiry_days ? values.password_expiry_days : ""
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={generalSettingsGeneralSectionInitialValues}
      enableReinitialize
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          <div className="field-wrapper mb-3">
            <SingleCheckbox
              name="allow_upgrade"
              label="Allow Upgrade"
              className="accepted-terms mb-0 pb-0 mr-3"
            />
          </div>
          <hr />
          <div className="field-wrapper">
            <Text
              name={UPGRADE_HUBSPOT_FORM_URL}
              label="Upgrade URL"
              placeholder="Enter App Url"
              fontBolded
            />
          </div>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PASSWORD_EXPIRY_DAYS}
              label="Password Expiry Days"
              placeholder="Enter Password Expiry Days"
              fontBolded
            />
          </div>
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default Settings;
