import React from "react";

const TagCloud = props => {
  const { theme, badges } = props;
  return (
    <div className="tag-cloud sidebar-content-box">
      <div className="sidebar-content">
        {badges &&
          badges.map(badge => (
            <span key={badge.id} className={`badge badge-${theme}`}>
              {badge.name}
            </span>
          ))}
      </div>
      {/* /.sidebar-content */}
    </div>
  );
};

export default TagCloud;
