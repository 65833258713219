import React from "react";
import { toast } from "react-toastify";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import {
  toString,
  getParamsByName,
  removeMaskedPhoneNumber,
  getCookie,
  setCookie,
  clearCookie,
  getKeyValueByObject,
  getSettingMediaUrl,
  validateStrongPassword,
  getBaseUrlParam
} from "../../lib/helper";

// Components
import Form from "../../components/base/Form";

// Inner Components
import LeftSideSection from "./LeftSideSection";
import GetStarted from "./GetStarted";
import RequestReceived from "./RequestReceived";
import BasicInformation from "./BasicInformation";

// Constants
import {
  SETTINGS_PARTNER_SIGNUP_HERO_IMAGE,
  BACKGROUND_IMAGE_URL,
  SETTINGS_PORTAL_NAME,
  SETTINGS_ENABLE_SALESFORCE_LOGIN,
  SETTINGS_ENABLE_OFFICE_365_LOGIN,
  SETTINGS_ENABLE_GOOGLE_LOGIN,
  SETTINGS_ENABLE_LINKEDIN_LOGIN,
  SETTINGS_LINKEDIN_CLIENT_ID,
  SETTINGS_LINKEDIN_REDIRECT_URI,
  SETTINGS_GOOGLE_CLIENT_ID,
  SETTINGS_SALESFORCE_LOGIN_CLIENT_ID,
  SETTINGS_SALESFORCE_LOGIN_REDIRECT_URL
} from "../../setting/Constants";
import { COOKIE_SESSION_TOKEN, COOKIE_USER_ID } from "../../lib/cookie";

import { UNITED_STATES } from "../../Country/Constants";
import {
  COMPANY_SIGNUP_BASE_URL_PATH,
  COMPANY_SIGNUP_BASIC_INFORMATION,
  COMPANY_SIGNUP_BASIC_INFORMATION_TEXT,
  COMPANY_SIGNUP_GETTING_STARTED,
  COMPANY_SIGNUP_GETTING_STARTED_TEXT,
  COMPANY_SIGNUP_REQUEST_RECEIVED,
  COMPANY_SIGNUP_REQUEST_RECEIVED_TEXT
} from "../../Company/Constants";
import { isBadRequest } from "../../common/http";

class CompanySignUp extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      height: window.innerHeight,
      firstName: "",
      lastName: "",
      errorMessage: "",
      companyNameErrorMessage: "",
      emailErrorMessage: "",
      passwordMismatchError: "",
      confirmPasswordMismatchError: "",
      companySignup: false,
      passwordToken: "",
      currentUserId: null,
      companyDetails: {},
      isLoading: true
    };

    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this._submit = this._submit.bind(this);
    this._close = this._close.bind(this);

    // Bind the update dimensions
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    const companyId = getParamsByName("companyId");
    const section = getParamsByName("section");

    if (companyId) this.getCompanyDetails(companyId);
    else this.setState({ isLoading: false });

    if (section === COMPANY_SIGNUP_GETTING_STARTED_TEXT) {
      this.setState({
        currentStep: COMPANY_SIGNUP_GETTING_STARTED
      });
    }
    if (section === COMPANY_SIGNUP_BASIC_INFORMATION_TEXT) {
      this.setState({
        currentStep: COMPANY_SIGNUP_BASIC_INFORMATION
      });
    }
    if (section === COMPANY_SIGNUP_REQUEST_RECEIVED_TEXT) {
      this.setState({
        currentStep: COMPANY_SIGNUP_REQUEST_RECEIVED
      });
    }

    if (section === null) {
      this.props.history.push(
        `/${COMPANY_SIGNUP_BASE_URL_PATH}?section=${COMPANY_SIGNUP_GETTING_STARTED_TEXT}`
      );
    }

    this.getCountryDetails(UNITED_STATES);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  // Update the screen height
  updateDimensions() {
    this.setState({
      height: window.innerHeight
    });
  }

  // Update param based on step selected
  updateParam(currentStep) {
    const sectionParam =
      currentStep === COMPANY_SIGNUP_GETTING_STARTED
        ? COMPANY_SIGNUP_GETTING_STARTED_TEXT
        : currentStep === COMPANY_SIGNUP_BASIC_INFORMATION
        ? COMPANY_SIGNUP_BASIC_INFORMATION_TEXT
        : currentStep === COMPANY_SIGNUP_REQUEST_RECEIVED
        ? COMPANY_SIGNUP_REQUEST_RECEIVED_TEXT
        : "";
    const companyId = getParamsByName("companyId");
    const userId = getParamsByName("userId");

    let query = "";
    if (
      currentStep === COMPANY_SIGNUP_BASIC_INFORMATION &&
      companyId &&
      userId
    ) {
      query = `&companyId=${companyId}&userId=${userId}`;
    }

    this.props.history.push(
      `/${COMPANY_SIGNUP_BASE_URL_PATH}?section=${sectionParam}${query}`
    );
  }

  // _next functions will be called on button click
  _next = props => {
    let currentStep = this.state.currentStep;

    currentStep = currentStep >= 3 ? 3 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });
    this.updateParam(currentStep);
  };

  // _previous functions will be called on button click
  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
    this.updateParam(currentStep);
  }
  getCompanyDetails = companyId => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

    return apiClient
      .get(`${endpoints().publicAPI}/company?id=${companyId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        this.setState({
          companyDetails: response.data,
          isLoading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          toast.error(errorMessage);
        }
      });
  };
  // _previous functions will be called on button click
  _submit(values) {
    if (this._validateFields(values) === false) {
      return;
    }

    let currentStep = this.state.currentStep;

    // Save "Basic Information" form of company
    if (currentStep === 2) {
      this.props.history.push(
        `/${COMPANY_SIGNUP_BASE_URL_PATH}?section=${COMPANY_SIGNUP_REQUEST_RECEIVED_TEXT}`
      );
      if (!this.state.companySignup) {
        this._companySignUp(this._toArray(values));
      } else {
        this._updateCompany(this._toArray(values));
      }
    }
  }

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define company sign up constants
    const companyName = values.companyName;
    const firstName = values.firstName;
    const lastName = values.lastName;
    const email = values.email;
    const password = values.password;
    const confirmPassword = values.confirmPassword;

    const passwordError = validateStrongPassword(password);

    switch (this.state.currentStep) {
      case 2:
        if (
          companyName == null ||
          firstName == null ||
          lastName == null ||
          email == null
        ) {
          success = false;
        } else if (passwordError) {
          this.setState({
            passwordMismatchError: passwordError
          });
          success = false;
        } else if (
          password &&
          confirmPassword &&
          password !== confirmPassword
        ) {
          this.setState({
            passwordMismatchError: "",
            confirmPasswordMismatchError: "Confirm password did not match"
          });
          success = false;
        } else {
          this.setState({
            passwordMismatchError: "",
            confirmPasswordMismatchError: ""
          });
        }
        break;
      default:
        break;
    }

    return success;
  }

  // Handle password error messages
  _handlePasswordErrors = e => {
    const password = e.values.password;
    const confirmPassword = e.values.confirmPassword;
    const passwordError = validateStrongPassword(password);
    if (passwordError) {
      this.setState({
        passwordMismatchError: passwordError
      });
    } else if (password && confirmPassword && password !== confirmPassword) {
      this.setState({
        passwordMismatchError: "",
        confirmPasswordMismatchError: "Confirm password did not match"
      });
    } else {
      this.setState({
        passwordMismatchError: "",
        confirmPasswordMismatchError: ""
      });
    }
  };

  // Close the wizard
  _close() {
    window.location = "/";
  }

  // Render square box
  _renderSquare(currentValue) {
    const currentStep = this.state.currentStep;
    const showActive = currentStep === currentValue ? "active" : "in-active";
    return currentStep < 3 ? <div className={`box ${showActive}`} /> : "";
  }

  // Render wizard indicator
  _renderWizardIndicator() {
    return (
      <div className="expert-request-active-btn-section d-flex">
        {[1, 2, 3].map(n => {
          return this._renderSquare(n);
        })}
      </div>
    );
  }

  // To Array
  _toArray(data) {
    let values = { ...data };
    const firstName = values.firstName;
    const lastName = values.lastName;
    const email = values.email;
    const password = values.password;

    this.setState({
      firstName,
      lastName,
      email
    });

    values.companyName = toString(values.companyName);
    values.firstName = toString(values.firstName);
    values.lastName = toString(values.lastName);
    values.email = toString(values.email);
    values.password = toString(password);
    values.phone = toString(
      values.phone && removeMaskedPhoneNumber(values.phone)
    );
    values.countryId = toString(values.countryId);
    values.countryName = toString(
      values.country ? values.country.label : values.country
    );

    values.address1 = toString(values.address1);
    values.address2 = toString(values.address2);
    values.city = toString(values.city);
    values.stateId = toString(values.stateId);
    values.stateName = toString(
      values.state ? values.state.value : values.state
    );
    values.acceptTerms = values.acceptTerms ? "Yes" : "No";

    return values;
  }

  // Company SignUp API Call
  _companySignUp = data => {
    const companyId = getParamsByName("companyId");
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    let currentStep = this.state.currentStep;

    return apiClient
      .post(`${endpoints().companyAPI}?${getBaseUrlParam()}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        currentStep = currentStep >= 3 ? 3 : currentStep + 1;
        this.setState(
          {
            currentStep: currentStep,
            companySignup: true,
            currentUserId: response.data.userId
          },
          () => {
            const { token, role, userId } = response.data;

            setCookie(COOKIE_SESSION_TOKEN, token);
            setCookie(COOKIE_USER_ID, userId);

            clearCookie("googleAuthToken");
            clearCookie("office365AuthToken");
            clearCookie("linkedinAuthToken");
            clearCookie("salesforceAuthToken");
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          const isCompanyNameExitsError =
            errorMessage === "Company Name already exist" ? errorMessage : "";
          const isEmailExitsError =
            errorMessage === "Email already exists" ? errorMessage : "";
          this.setState({
            errorMessage,
            companyNameErrorMessage: isCompanyNameExitsError,
            emailErrorMessage: isEmailExitsError
          });
        }
      });
  };

  // Update Company Details
  _updateCompany(data) {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    let currentStep = this.state.currentStep;
    data.companySignup = true;

    return apiClient
      .put(`${endpoints().companyAPI}?${getBaseUrlParam()}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        currentStep = currentStep >= 3 ? 3 : currentStep + 1;
        this.setState({
          currentStep: currentStep,
          passwordToken: response.data.password_token
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          this.setState({
            errorMessage
          });
        }
      });
  }

  // Get Country Details
  getCountryDetails = countryName => {
    return apiClient
      .get(`${endpoints().countryAPI}/${countryName}`)
      .then(response => {
        const data = response.data;
        this.setState({
          selectedCountry: data.id
        });
      });
  };

  setUserDetails = data => {
    const companyDetails = {
      ...this.state.companyDetails,
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      email: data.email || "",
      authToken: data.authToken || ""
    };

    this.setState({ companyDetails, currentStep: 2, isLoading: true }, () =>
      this.setState({ isLoading: false })
    );
  };

  render() {
    const {
      currentStep,
      height,
      email,
      errorMessage,
      companyNameErrorMessage,
      emailErrorMessage,
      passwordMismatchError,
      confirmPasswordMismatchError,
      companyDetails,
      isLoading,
      selectedCountry
    } = this.state;

    if (isLoading) return "";

    const { settings } = this.props;

    const companySignupHeroImage =
      settings &&
      getKeyValueByObject(settings, SETTINGS_PARTNER_SIGNUP_HERO_IMAGE)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, SETTINGS_PARTNER_SIGNUP_HERO_IMAGE)
          )
        : "";
    const backgroundImage =
      settings && getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
          )
        : "";
    const marketplaceName = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_NAME)
      : "";

    const enableSalesforceLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_SALESFORCE_LOGIN)
      : "";

    const enableGoogleLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_GOOGLE_LOGIN)
      : "";

    const enableOffice365Login = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_OFFICE_365_LOGIN)
      : "";

    const enableLinkedInLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_LINKEDIN_LOGIN)
      : "";

    const linkedInClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_CLIENT_ID)
      : "";

    const linkedInRedirectUri = settings
      ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_REDIRECT_URI)
      : "";

    const googleClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_GOOGLE_CLIENT_ID)
      : "";

    const salesforceLoginClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_SALESFORCE_LOGIN_CLIENT_ID)
      : "";

    const salesforceLoginRedirectUrl = settings
      ? getKeyValueByObject(settings, SETTINGS_SALESFORCE_LOGIN_REDIRECT_URL)
      : "";

    let initialValues = {
      companyName: companyDetails.companyName || "",
      firstName: "",
      lastName: "",
      email: companyDetails.email || "",
      password: "",
      confirmPassword: "",
      acceptTerms: "",
      address1: "",
      address2: "",
      country: {
        value: UNITED_STATES,
        label: UNITED_STATES
      },
      state: "",
      city: "",
      phone: companyDetails.phone || "",
      zipcode: ""
    };

    return (
      <div
        className="container-fluid"
        style={{ height: height, minHeight: "100vh" }}
      >
        <div className="row overflow-scroll" style={{ height: "100%" }}>
          <LeftSideSection
            height={height}
            signupHeroImage={companySignupHeroImage}
            backgroundImage={backgroundImage}
          />
          <div className="col" style={{ background: "#fff", height: "100%" }}>
            <div className="row find-an-expert-budget-right-side-section form-wrapper flex-column">
              {this._renderWizardIndicator()}
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  if (getCookie("googleAuthToken")) {
                    values.googleAuthToken = getCookie("googleAuthToken");
                  }
                  if (getCookie("office365AuthToken")) {
                    values.office365AuthToken = getCookie("office365AuthToken");
                  }
                  if (getCookie("linkedinAuthToken")) {
                    values.linkedinAuthToken = getCookie("linkedinAuthToken");
                  }
                  if (getCookie("salesforceAuthToken")) {
                    values.salesforceAuthToken = getCookie(
                      "salesforceAuthToken"
                    );
                  }
                  values.companyId = getParamsByName("companyId");
                  values.userId = getParamsByName("userId");
                  this._submit(values);
                }}
                onReset={() => {
                  this.setState({ confirmPasswordMismatchError: "" });
                  return this._next();
                }}
              >
                <GetStarted
                  step={1}
                  currentStep={currentStep}
                  setUserDetails={data => this.setUserDetails(data)}
                  next={this._next}
                  enableSalesforceLogin={enableSalesforceLogin}
                  enableOffice365Login={enableOffice365Login}
                  enableGoogleLogin={enableGoogleLogin}
                  enableLinkedInLogin={enableLinkedInLogin}
                  linkedInClientId={linkedInClientId}
                  linkedInRedirectUri={linkedInRedirectUri}
                  marketplaceName={marketplaceName}
                  googleClientId={googleClientId}
                  salesforceLoginClientId={salesforceLoginClientId}
                  salesforceLoginRedirectUrl={salesforceLoginRedirectUrl}
                />

                <BasicInformation
                  step={2}
                  companyNameErrorMessage={companyNameErrorMessage}
                  emailErrorMessage={emailErrorMessage}
                  currentStep={currentStep}
                  errorMessage={errorMessage}
                  passwordMismatchError={passwordMismatchError}
                  confirmPasswordMismatchError={confirmPasswordMismatchError}
                  handlePasswordErrors={this._handlePasswordErrors}
                  companySignup={this.state.companySignup}
                  selectedCountry={selectedCountry}
                  next={this._submit}
                  prev={this._prev}
                />

                <RequestReceived
                  step={3}
                  currentStep={currentStep}
                  close={this._close}
                  prev={this._prev}
                  email={email}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanySignUp;
