import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import AddButton from "../../../../../components/base/AddButton";
import Form from "../../../../../components/base/Form";
import PageTitle from "../../../../../components/base/PageTitle";
import Text from "../../../../../components/Text";
import Radio from "../../../../../components/base/Radio";
import toast from "../../../../../components/base/Toast";
import SaveButton from "../../../../../components/base/SaveButton";
import DeleteModal from "../../../../../components/base/DeleteModal";
import NoRecordsFound from "../../../../../components/base/NoRecordsFound";
import PartnerSelector from "../../../../../../src/partner/component/PartnerSelector";
import Spinner from "../../../../../components/base/Spinner";
import Tags from "./inner-component/Tags";
import ToggleSwitch from "../../../../../components/base/ToggleSwitch";

// Helper
import { toString } from "../../../../../lib/helper";

// API call
import { apiClient } from "../../../../../apiClient";

// Configs
import { endpoints } from "../../../../../configs";

// Tags
import { deleteTagList, addTag, updateTag } from "../../../../../actions/tags";
import { fetchList } from "../../../../../actions/table";

//Assets
import { ChevronRight } from "../../../../../assets/img/icons";

// Constant
import {
  GENERAL_GENERAL_SUB_TAB,
  TAB_GENERAL
  // MARKETPLACES_SUB_TAB,
  // TAB_MARKETPLACE
} from "../../../../../setting/Constants";
import {
  TAG_PERMISSION_INTERNAL_ONLY,
  TAG_PERMISSION_SELECT_AND_ADD,
  TAG_PERMISSION_SELECT_AND_NOT_ADD,
  TAG_PERMISSION_INTERNAL_ONLY_VALUE,
  TAG_PERMISSION_SELECT_AND_ADD_VALUE,
  TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE,
  TAG_VISIBILITY_PUBLIC,
  TAG_VISIBILITY_ADMIN
} from "../../../../../tag/Constants";
import {
  PARTNER_PERMISSION_ALL_PARTNER,
  PARTNER_PERMISSION_ALL_PARTNER_VALUE,
  PARTNER_PERMISSION_FILTERED_PARTNERS,
  PARTNER_PERMISSION_FILTERED_PARTNERS_VALUE,
  PARTNER_PERMISSION_INDIVIDUAL_PARTNER,
  PARTNER_PERMISSION_INDIVIDUAL_PARTNER_VALUE
} from "../../../../../resource/Constants";

//Common
import { isNotEmpty } from "../../../../../common/utils";
import { isBadRequest } from "../../../../../common/http";
import { Field } from "formik";
// import DefaultContent from "../../../../../components/content/DefaultContent";

class ManageTagList extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      isLoading: false,
      isTypeLoading: false,
      isOpen: false,
      data: "",
      id: 0,
      tagId: "",
      name: "",
      type: "",
      typeId: null,
      tierId: null,
      projectCategory: "",
      projectCategoryId: null,
      isSearchable: "true",
      tagTypeDetails: [],
      projectCategoryDetails: [],
      tagList: [],
      permissionList: [],
      tagTypeFilter: "",
      tagTypeFilterId: null,
      visibility: "",
      tagTierFilter: "",
      tagTierFilterId: null,
      tagTypePermission: null,
      permissionFilterType: "",
      sourceTaskOrder: "",
      targetTaskOrder: "",
      openEditModal: false,
      OpenDeleteModal: false,
      setCurrentTask: "",
      currentTask: "",
      taskItem: "",
      partnerTypeList: [],
      partnerTypeLists: [],
      partnerTierList: [],
      partnerTierLists: []
    };
  }

  handleTaskDelete = id => {
    this.props.actions.deleteTagList(id, {}, () => {
      const tagTypeFilter = this.props.selectedTagType;
      this.getTagList(tagTypeFilter);
    });
  };
  /**
   * Create tag
   *
   * @param data
   */
  tagCreate = data => {
    return apiClient
      .post(`${endpoints().tagAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._toggle();
        const tagTypeFilter = this.props.selectedTagType;
        this.getTagList(tagTypeFilter);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  /**
   * Update tag
   *
   * @param data
   * @param id
   */
  tagUpdate = data => {
    this.props.actions.updateTag(this.state.id, data, {
      tagType: this.state.tagTypeFilter || ""
    });
    this._toggle();
    const tagTypeFilter = this.props.selectedTagType;
    setTimeout(() => {
      this.getTagList(tagTypeFilter);
    }, 300);
  };

  _toggle = tagId => {
    this.setState({ isOpen: !this.state.isOpen, id: tagId || "" });
  };

  // Render the header button
  headerButton = () => (
    <div className="d-flex align-items-center mb-n3 pull-right">
      <div className="btn-wrapper">
        <AddButton
          label="Add New"
          className="btn btn-secondary"
          onClick={() => {
            this.setState({
              name: "",
              projectCategory: "",
              projectCategoryId: null,
              type: "",
              typeId: null
            });
            this._toggle();
          }}
        />
        {this.tagModal()}
      </div>
    </div>
  );

  componentDidMount = () => {
    const tagTypeFilter = this.props.selectedTagType;
    const tagId = this.props.selectedId;
    this.setState({ tagTypeFilter });
    this.setState({ tagId: tagId });
    this.getTagList(tagTypeFilter);
    this.getTagTypeData();
    this.getProjectCategory();
  };

  getTagList = async tagType => {
    this.setState({
      isTypeLoading: true
    });
    await apiClient
      .get(`${endpoints().tagAPI}?${tagType ? "&tagType=" + tagType : ""}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        console.error(successMessage);
        this.getTagTypeData();
        const permissionList = response.data.permissions || [];

        const tagList = response.data.data || [];

        this.setState({
          tagList: tagList,
          isTypeLoading: false,
          permissionList: permissionList
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get Tag Type Data
  getTagTypeData = async () => {
    await apiClient
      .get(`${endpoints().tagTypeAPI}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        console.error(successMessage);

        const tagTypeDetails = response.data.data || [];
        const tagTypeFilterId = tagTypeDetails.find(
          type => type.name === this.state.tagTypeFilter
        );

        this.setState({
          visibility: tagTypeFilterId ? tagTypeFilterId.visibility : "",
          tagTypeDetails,
          tagTypeFilterId: tagTypeFilterId ? tagTypeFilterId.id : null,
          tagTypePermission: tagTypeFilterId
            ? tagTypeFilterId.permission
            : null,
          permissionFilterType: tagTypeFilterId
            ? tagTypeFilterId.partner_filter_type
            : null,
          isLoading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get Project Category Data
  getProjectCategory = () => {
    this.setState({ isLoading: true }, () => {
      return apiClient
        .get(`${endpoints().projectCategoryAPI}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);
          this.getTagTypeData();

          this.setState({ projectCategoryDetails: response.data.data });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  };

  // Handle Tag Type Permissions
  handleTagTypePermission = permission => {
    const data = new FormData();

    if (permission !== undefined) {
      data.append(
        "permission",
        permission === TAG_PERMISSION_SELECT_AND_ADD
          ? TAG_PERMISSION_SELECT_AND_ADD_VALUE
          : permission === TAG_PERMISSION_SELECT_AND_NOT_ADD
          ? TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
          : permission === TAG_PERMISSION_INTERNAL_ONLY
          ? TAG_PERMISSION_INTERNAL_ONLY_VALUE
          : ""
      );
    }

    return apiClient
      .put(`${endpoints().tagTypeAPI}/${this.state.tagTypeFilterId}`, data)
      .then(response => {
        if (response && response.data) {
          response.data.message && toast.success(response.data.message);
        }
        const tagTypeFilter = this.props.selectedTagType;
        this.getTagList(tagTypeFilter);
        this.getTagTypeData();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  // Tag type options
  tagTypeOptions = () => {
    const { tagTypeDetails } = this.state;
    return (
      tagTypeDetails &&
      tagTypeDetails.map(tagType => ({
        label: tagType.name,
        value: tagType.id
      }))
    );
  };

  // Render the tag modal
  tagModal = () => (
    <div>
      <Modal
        isOpen={this.state.isOpen}
        toggle={this._toggle}
        className="landing-create-popup w-100"
        backdrop="static"
      >
        <ModalHeader
          toggle={this._toggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <p>
            {" "}
            {`${
              !this.state.id
                ? `Create ${this.state.tagTypeFilter}`
                : `Edit ${this.state.tagTypeFilter}`
            }`}
          </p>
        </ModalHeader>
        <Form
          id={this.state.id ? "tag-edit" : "tag-create"}
          initialValues={{
            name: this.state.name,
            projectCategory: this.state.projectCategoryId
              ? {
                  value: this.state.projectCategoryId,
                  label: this.state.projectCategory
                }
              : "",
            typeCategory:
              this.state.typeId || this.state.tagTypeFilter
                ? {
                    value: this.state.typeId || this.state.tagTypeFilterId,
                    label: this.state.type || this.state.tagTypeFilter
                  }
                : "",
            isSearchable: this.state.isSearchable || false
          }}
          onSubmit={values => {
            values.name = toString(values.name);
            values.type = toString(
              values.typeCategory ? values.typeCategory.value : ""
            );
            values.projectCategory = toString(
              values.projectCategory ? values.projectCategory.value : ""
            );
            this.state.id ? this.tagUpdate(values) : this.tagCreate(values);
          }}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div>
              <Text
                name="name"
                label="Tag Name"
                placeholder="Enter tag name"
                error=""
                required={true}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col text-center">
                <SaveButton label="" />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
  setSourceTaskOrder = tagType => {
    if (this.state.sourceTaskOrder !== tagType.sort) {
      this.setState({ sourceTaskOrder: tagType.sort });
    }
  };

  setTargetTaskOrder = tagType => {
    if (this.state.targetTaskOrder !== tagType.sort) {
      this.setState({ targetTaskOrder: tagType.sort });
    }
  };

  // Update
  updateTagOrder = updatedTagList => {
    apiClient
      .put(`${endpoints().tagAPI}/update/sortOrder`, updatedTagList)
      .then(response => {
        if (response.data) {
          this.setState({
            isLoading: true
          });
          const tagTypeFilter = this.props.selectedTagType;
          this.getTagList(tagTypeFilter);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          this.setState({
            isLoading: false
          });
        }
      });
  };

  updateTask = taskItem => {
    this.setState({
      id: taskItem.id,
      name: taskItem.name,
      projectCategory: taskItem.projectCategory,
      projectCategoryId: taskItem.projectCategoryId,
      isSearchable: taskItem.isSearchable === "Yes" ? true : false,
      type: taskItem.type,
      typeId: taskItem.typeId,
      isOpen: true
    });
  };
  deleteTask = taskItem => {
    this.setState({
      id: taskItem.id,
      name: taskItem.name,
      projectCategory: taskItem.projectCategory,
      projectCategoryId: taskItem.projectCategoryId,
      isSearchable: taskItem.isSearchable === "Yes" ? true : false,
      type: taskItem.type,
      typeId: taskItem.typeId,
      OpenDeleteModal: true
    });
  };

  changeTaskOrder = () => {
    const { tagList, sourceTaskOrder, targetTaskOrder } = this.state;
    if (tagList && tagList.length > 0) {
      tagList.splice(targetTaskOrder, 0, tagList.splice(sourceTaskOrder, 1)[0]);

      // Updated Selected Tag Type List
      const updatedTagList = [];

      tagList.forEach(tagType => {
        updatedTagList.push({
          ...tagType,
          // Update Selected Tag Type Order
          sort: updatedTagList.length
        });
      });
      this.updateTagOrder(updatedTagList);
      this.setState({
        tagList: updatedTagList,
        sourceTaskOrder: "",
        targetTaskOrder: ""
      });
    } else {
      // Reset Order Positions of Selected Tag Type
      this.setState({
        sourceTaskOrder: "",
        targetTaskOrder: ""
      });
    }
  };

  //Get Id By Name
  getIdByName = (name, values) => {
    let id = "";
    if (values && values.length > 0)
      values.forEach(value => {
        if (value.label === name) {
          id = value.value;
        }
      });
    return id;
  };

  //Handle Partner Type Permission
  handleTypePermission = typeId => {
    if (typeId) {
      const data = new FormData();
      data.append("partnerTypeId", typeId);
      data.append(
        "tagId",
        this.state.typeId || this.state.tagTypeFilter
          ? this.state.typeId || this.state.tagTypeFilterId
          : ""
      );

      apiClient
        .put(`${endpoints().tagAPI}/permission`, data)
        .then(response => {
          if (response.data) {
            toast.success(response.data.message);
            const tagTypeFilter = this.props.selectedTagType;
            this.getTagList(tagTypeFilter);
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    }
  };

  //Handle Tier Permission
  handleTierPermission = tierId => {
    if (tierId) {
      const data = new FormData();
      data.append("partnerTierId", tierId);
      data.append(
        "tagId",
        this.state.typeId || this.state.tagTypeFilter
          ? this.state.typeId || this.state.tagTypeFilterId
          : ""
      );

      apiClient
        .put(`${endpoints().tagAPI}/permission`, data)
        .then(response => {
          if (response.data) {
            toast.success(response.data.message);
            const tagTypeFilter = this.props.selectedTagType;
            this.getTagList(tagTypeFilter);
            this.getTagTypeData();
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    }
  };

  //Profile visibilty On Change save
  handleVisibility = value => {
    const data = new FormData();

    if (value !== undefined) {
      data.append(
        "visibility",
        value === true ? TAG_VISIBILITY_PUBLIC : TAG_VISIBILITY_ADMIN
      );
    }
    this.setState({ loadingModal: true });
    apiClient
      .put(`${endpoints().tagTypeAPI}/${this.state.tagTypeFilterId}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          const tagTypeFilter = this.props.selectedTagType;
          this.getTagList(tagTypeFilter);
          this.getTagTypeData();
        }
        this.setState({ loadingModal: false });
      })
      .catch(error => {
        this.setState({ loadingModal: false });
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };
  //Hangle Partner Permission
  handlePartnerPermission = e => {
    const partnerPermission = e;
    this.partnerPermissionLevel(partnerPermission);
  };

  partnerPermissionLevel = permission => {
    const permissionId = permission === PARTNER_PERMISSION_ALL_PARTNER ? 1 : 2;
    if (permissionId) {
      const data = new FormData();
      data.append("partnerPermissionId", permissionId);

      apiClient
        .put(`${endpoints().tagTypeAPI}/${this.state.tagTypeFilterId}`, data)
        .then(response => {
          if (response.data) {
            toast.success(response.data.message);
          }
          const tagTypeFilter = this.props.selectedTagType;
          this.getTagList(tagTypeFilter);
          this.getTagTypeData();
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    }
  };

  render() {
    const history = this.props.history;
    const {
      tagList,
      OpenDeleteModal,
      currentTask,
      permissionList,
      isTypeLoading,
      isLoading
    } = this.state;
    //Initial Value
    if (isLoading) {
      return <Spinner />;
    }
    const initialValues = {
      visibility: this.state.visibility === TAG_VISIBILITY_PUBLIC
    };
    const { activeTab, activeSubTab } = this.props;

    const permissionFilterType =
      this.state.permissionFilterType &&
      parseInt(this.state.permissionFilterType) ===
        PARTNER_PERMISSION_ALL_PARTNER_VALUE
        ? PARTNER_PERMISSION_ALL_PARTNER
        : this.state.permissionFilterType ===
          PARTNER_PERMISSION_FILTERED_PARTNERS_VALUE
        ? PARTNER_PERMISSION_FILTERED_PARTNERS
        : this.state.permissionFilterType ===
          PARTNER_PERMISSION_INDIVIDUAL_PARTNER_VALUE
        ? PARTNER_PERMISSION_INDIVIDUAL_PARTNER
        : "";
    const tagTypePermission =
      this.state.tagTypePermission && parseInt(this.state.tagTypePermission);

    return (
      <div>
        <>
          <DeleteModal
            isOpen={OpenDeleteModal}
            toggle={() => {
              this.setState({ OpenDeleteModal: false });
            }}
            title="Delete Tag"
            label={this.state.name}
            task={currentTask}
            deleteFunction={() => this.handleTaskDelete(this.state.id)}
          />
          {/* Breadcrumb Start */}
          <div className="d-flex align-items-center pt-4 pb-3">
            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(
                  `/admin-settings/${TAB_GENERAL}/${GENERAL_GENERAL_SUB_TAB}`
                );
              }}
            >
              Admin Settings
            </span>
            <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
              <ChevronRight />
            </span>
            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(`/admin-settings/${activeTab}`);
              }}
            >
              <span>{activeTab}</span>
            </span>
            <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
              <ChevronRight />
            </span>

            <span
              className="cursor-pointer"
              onClick={() => {
                history.push(`/admin-settings/${activeTab}/${activeSubTab}`);
              }}
            >
              <span>{activeSubTab}</span>
            </span>
            <span
              className=" text-dark font-weight-bold d-inline-flex pt-1"
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <span className="text-dark font-weight-bold">
              <span>{this.state.tagTypeFilter}</span>
            </span>
          </div>
          {/* Breadcrumb End */}
        </>
        <PageTitle label={this.state.tagTypeFilter} />

        <>
          <Form enableReinitialize={true} initialValues={initialValues}>
            <Field
              name="visibility"
              render={({ field, form }) => {
                return (
                  <div>
                    <div className="row">
                      <div className="col-4 pt-2 pb-2">
                        <span className="font-weight-bold">
                          Show In Public Profile
                        </span>
                      </div>
                      <div className="col-8 task-right pt-2 pb-2">
                        <ToggleSwitch
                          name={"visibility"}
                          value={field.value}
                          handleChange={() => {
                            this.handleVisibility(!field.value);
                            form.setFieldValue("visibility", !field.value);
                          }}
                          outlined
                        />
                      </div>
                    </div>
                  </div>
                );
              }}
            />
            {!isLoading ? (
              <div>
                <PartnerSelector
                  showPartnerTier
                  showPartnerType
                  partnerPermissionDefaultValue={permissionFilterType}
                  SelectedTierOptions={permissionList}
                  handleTierOption={e => {
                    this.handleTierPermission(e);
                  }}
                  SelectedTypeOptions={permissionList}
                  handleTypeOption={e => {
                    this.handleTypePermission(e);
                  }}
                  handlePermissionOnchange={e => {
                    this.handlePartnerPermission(e);
                  }}
                />
              </div>
            ) : (
              <Spinner />
            )}
            <div className="form-wrapper">
              <h6 className="font-weight-bold mb-3">Partner Permissions</h6>
              <div className="px-4 w-100">
                {!isLoading ? (
                  <Radio
                    name="permissions"
                    options={[
                      TAG_PERMISSION_SELECT_AND_ADD,
                      TAG_PERMISSION_SELECT_AND_NOT_ADD,
                      TAG_PERMISSION_INTERNAL_ONLY
                    ]}
                    size="large"
                    onChange={e => {
                      this.handleTagTypePermission(e.target.value);
                    }}
                    defaultValue={
                      tagTypePermission &&
                      tagTypePermission === TAG_PERMISSION_SELECT_AND_ADD_VALUE
                        ? TAG_PERMISSION_SELECT_AND_ADD
                        : tagTypePermission ===
                          TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
                        ? TAG_PERMISSION_SELECT_AND_NOT_ADD
                        : tagTypePermission ===
                          TAG_PERMISSION_INTERNAL_ONLY_VALUE
                        ? TAG_PERMISSION_INTERNAL_ONLY
                        : ""
                    }
                  />
                ) : (
                  ""
                )}
              </div>
            </div>
          </Form>
          <div className="pb-4 mb-4">{this.headerButton()}</div>
        </>
        <div className="card">
          <div className="pl-3 pt-3  w-100"></div>
          <h6 className="font-weight-bold pt-4 px-4 pb-3">Tags</h6>
          <div className="task-list">
            {isNotEmpty(tagList) ? (
              tagList
                .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
                .map(taskItem => (
                  <>
                    <div
                      id={taskItem.sort}
                      key={taskItem.id}
                      draggable="true"
                      onDrag={() => {
                        this.setSourceTaskOrder(taskItem);
                      }}
                      onDragOver={e => {
                        e.preventDefault();
                        this.setTargetTaskOrder(taskItem);
                      }}
                      onDrop={() => {
                        this.changeTaskOrder();
                      }}
                    >
                      <Tags
                        task={taskItem}
                        openEditModal={() => {
                          this.updateTask(taskItem);
                        }}
                        openDeleteModal={() => {
                          this.deleteTask(taskItem);
                        }}
                      />
                    </div>
                  </>
                ))
            ) : (
              <NoRecordsFound hideCard description="No Tags are added yet" />
            )}
          </div>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { deleteTagList, addTag, updateTag, fetchList },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(ManageTagList);
