// API Client
import { apiClient } from "../apiClient";

import { endpoints } from "../configs";

// Get System Settings Value by Name
export const getSystemSettingByName = async name => {
  if (!name) {
    return "";
  }
  let setting = "";
  await apiClient.get(`${endpoints().systemSettingAPI}`).then(response => {
    const settings = response.data.data;

    if (settings) {
      settings.forEach(data => {
        if (data.name == name) {
          setting = data.value;
        }
      });
    }
  });
  return setting;
};

// Get Company Settings Value by Name
export const getCompanySettingByName = async name => {
  if (!name) {
    return "";
  }
  let setting = "";
  await apiClient.get(`${endpoints().settingAPI}`).then(response => {
    const settings = response.data.data;

    if (settings) {
      settings.forEach(data => {
        if (data.name == name) {
          setting = data.value;
        }
      });
    }
  });
  return setting;
};
