import React from "react";
import { connect } from "react-redux";

// API Client

// Helper
import {
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  isPartner,
  isCompanyManager,
  getParamsByName,
  getKeyValueByObject
} from "../../../lib/helper";

// Configs
import { endpoints } from "../../../configs";

import AllReferral from "./AllReferral";

import { isBadRequest } from "../../../common/http";

import { getPartnerSettingById } from "../../../lib/PartnerSetting";

import { ALLOW_REFERRAL_SUBMISSION } from "../../../views/adminSettings/tabs/Partner/PartnerTypes/Constants";

import { SETTINGS_ENABLE_PARTNER_PAYMENTS } from "../../../setting/Constants";
import Page404 from "../../Page404";
import {
  REFERRAL_SECTION_APPROVED,
  REFERRAL_SECTION_CONVERTED,
  REFERRAL_SECTION_DECLINED,
  REFERRAL_SECTION_NEW,
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_CLOSED_WON,
  REFERRAL_STATUS_DECLINED,
  REFERRAL_STATUS_NEW
} from "../../../referral/Constants";
import { apiClient } from "../../../apiClient";

class ReferralReport extends React.Component {
  state = {
    status: "",
    paymentMethodDetails: "",
    isPartnerAdmin: false,
    partnerDetails: {},
    sortByOptions: [
      {
        value: "account_name:ASC",
        label: "Account Name"
      },
      {
        value: "createdAt:DESC",
        label: "Most Recent"
      }
    ],
    currentNewReferralsSort: "createdAt",
    currentNewReferralsSortDir: "DESC",
    currentApprovedReferralsSort: "createdAt",
    currentApprovedReferralsSortDir: "DESC",
    currentDeclinedReferralsSort: "createdAt",
    currentDeclinedReferralsSortDir: "DESC",
    currentAllReferralsSort: "createdAt",
    currentAllReferralsSortDir: "DESC",
    allReferralsCount: "",
    exportData: "",
    referralCount: 0,
    referralSize: 0,
    referralTotalAmount: 0,
    currentPage: this.props.allReferralsCurrentPage,
    allowReferralSubmission: false,
    activeTab: getParamsByName("activeTab")
      ? getParamsByName("activeTab")
      : "Referral"
  };

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    if (isPartner(this.props.loggedInUser && this.props.loggedInUser.roleId)) {
      this._getPartnerDetails();
    }
    const currentStatus = this.props.match.params.status;

    const status =
      currentStatus === REFERRAL_SECTION_APPROVED
        ? REFERRAL_STATUS_APPROVED
        : currentStatus === REFERRAL_SECTION_CONVERTED
        ? REFERRAL_STATUS_CLOSED_WON
        : currentStatus === REFERRAL_SECTION_NEW
        ? REFERRAL_STATUS_NEW
        : currentStatus === REFERRAL_SECTION_DECLINED
        ? REFERRAL_STATUS_DECLINED
        : "";

    this.setState({ status: status }, () => {
      this.getReferralInitialList(status);
    });
  }

  getPartnerSetting = async () => {
    try {
      if (this.state.partnerDetails) {
        let allowReferralSubmission = getPartnerSettingById(
          this.state.partnerDetails.partnerType,
          this.props.partnerSettings,
          ALLOW_REFERRAL_SUBMISSION
        );
        this.setState({ allowReferralSubmission: allowReferralSubmission });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Get Referral List
  getReferralInitialList = async (
    search,
    start_date,
    end_date,
    status,
    sort,
    sortDir,
    partner
  ) => {
    const partner_Id = getParamsByName("partner") || "";
    search = search || "";
    start_date = start_date || "";
    end_date = end_date || "";
    sort = sort || "";
    sortDir = sortDir || "";
    partner = partner_Id || partner;
    status = status || getParamsByName("status");

    const submittedFrom = getParamsByName("submittedFrom") || "";

    const partnerType = getParamsByName("partnerType") || "";
    const partnerTier = getParamsByName("partnerTier") || "";

    try {
      const params = [];

      let apiUrl;

      if (search) {
        params.push(`search=${search}`);
      }

      if (start_date) {
        params.push(`start_date=${start_date}`);
      }
      if (end_date) {
        params.push(`end_date=${end_date}`);
      }
      if (status) {
        params.push(`status=${status}`);
      }

      if (sort) {
        params.push(`sort=${sort}`);
      }

      if (sortDir) {
        params.push(`sortDir=${sortDir}`);
      }

      if (partner) {
        params.push(`partner=${partner}`);
      }

      if (submittedFrom) {
        params.push(`submittedFrom=${submittedFrom}`);
      }

      if (partnerType) {
        params.push(`partnerType=${partnerType}`);
      }
      if (partnerTier) {
        params.push(`partnerTier=${partnerTier}`);
      }

      params.push(`pagination=${true}`);

      if (params && params.length > 0) {
        apiUrl = `${endpoints().referralReportApi}/search?${params.join("&")}`;
      } else {
        apiUrl = `${endpoints().referralReportApi}/search`;
      }

      const response = await apiClient.get(apiUrl);

      const reportData = [];
      const referralCount = response.data.totalCount || 0;
      const referralSize = response.data.averageAmount || 0;
      const referralTotalAmount = response.data.totalAmount || 0;
      response.data &&
        response.data.data &&
        response.data.data.forEach(data => {
          reportData.push({
            accountName: data.account_name,
            name: `${data.first_name} ${data.last_name}`,
            companyName: data.companyName,
            submittedBy: data.submitted_user_name,
            status: data.status,
            amount: data.amount,
            createdAt: data.createdAt
          });
        });
      this.setState({
        exportData: reportData,
        referralCount,
        referralSize,
        referralTotalAmount,
        loading: false
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
          this.setState({
            exportData: [],
            referralCount: 0,
            referralSize: 0,
            referralTotalAmount: 0,
            loading: false
          });
        }
        console.error(errorMessage);
      }
    }
  };

  // Get Partner Details
  _getPartnerDetails = () => {
    return apiClient
      .get(`${endpoints().partnerAPI}`)
      .then(response => {
        this.setState(
          {
            partnerDetails: {
              ...response.data
            }
          },
          () => {
            this.getPartnerSetting();
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
        }
      });
  };

  componentDidUpdate(prevState, prevProps) {
    // Check is logged in user
    isLoggedIn();

    if (
      this.props.allReferralsSort &&
      this.props.allReferralsSort !== this.state.currentAllReferralsSort
    ) {
      this.setState({
        currentAllReferralsSort: this.props.allReferralsSort
      });
    }
    if (
      this.props.allReferralsSortDir &&
      this.props.allReferralsSortDir !== this.state.currentAllReferralsSortDir
    ) {
      this.setState({
        currentAllReferralsSortDir: this.props.allReferralsSortDir
      });
    }
  }

  // Get Referral List
  getReferralList = params => {
    let search = params ? params.search : "";
    let start_date = params ? params.start_date : "";
    let end_date = params ? params.end_date : "";
    let status = params ? params.status : "";
    let sort = params ? params.sort : "";
    let sortDir = params ? params.sortDir : "";
    let partner = params ? params.partner : "";
    let partnerType = params ? params.partnerType : "";
    let partnerTier = params ? params.partnerTier : "";
    let referralType = params ? params.referralType : "";
    let pageSize = params ? params.pageSize : 10;

    return apiClient
      .get(
        `${
          endpoints().referralReportApi
        }/search?search=${search}&start_date=${start_date}&end_date=${end_date}&status=${status}&sort=${sort}&sortDir=${sortDir}&partner=${partner}&partnerType=${partnerType}&partnerTier=${partnerTier}&page=${
          this.state.currentPage
        }&pageSize=${pageSize}&pagination=${true}&referralType=${referralType}`
      )
      .then(response => {
        const reportData = [];
        const referralCount = response.data.totalCount || 0;
        const referralSize = response.data.averageAmount || 0;
        const referralTotalAmount = response.data.totalAmount || 0;
        response.data &&
          response.data.data &&
          response.data.exportData.forEach(data => {
            reportData.push({
              accountName: data.account_name,
              name: `${data.first_name} ${data.last_name}`,
              companyName: data.companyName,
              submittedBy: data.submitted_user_name,
              status: data.status,
              amount: data.amount,
              createdAt: data.createdAt
            });
          });
        this.setState({
          exportData: reportData,
          referralCount,
          referralSize,
          referralTotalAmount,
          loading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            this.setState({
              exportData: [],
              referralCount: 0,
              referralSize: 0,
              referralTotalAmount: 0,
              loading: false
            });
          }
          console.error(errorMessage);
        }
      });
  };

  // Toggle Tab
  toggle = tab => {
    this.setState({
      activeTab: tab
    });
  };

  render() {
    const headers = [
      { label: "Account Name", key: "accountName" },
      { label: "Name", key: "name" },
      { label: "Company Name", key: "companyName" },
      { label: "Submitted By", key: "submittedBy" },
      { label: "Status", key: "status" },
      { label: "Amount", key: "amount" },
      { label: "Created At", key: "createdAt" }
    ];

    if (
      !isSuperAdmin(
        this.props.loggedInUser && this.props.loggedInUser.roleId
      ) &&
      !isCompanyAdmin(
        this.props.loggedInUser && this.props.loggedInUser.roleId
      ) &&
      !isPartner(this.props.loggedInUser && this.props.loggedInUser.roleId) &&
      !isCompanyManager(
        this.props.loggedInUser && this.props.loggedInUser.roleId
      )
    ) {
      return <Page404 />;
    }

    const enableReferralSizeAndValue = this.props.settings
      ? getKeyValueByObject(
          this.props.settings,
          SETTINGS_ENABLE_PARTNER_PAYMENTS
        )
      : "";

    return (
      <>
        <div>
          <AllReferral
            history={this.props.history}
            allReferralsSort={this.state.currentAllReferralsSort}
            allReferralsSortDir={this.state.currentAllReferralsSortDir}
            allReferralsCurrentPage={this.props.allReferralsCurrentPage}
            allReferralsCount={this.props.allReferralsCount}
            averageReferralSize={this.state.referralSize}
            totalValue={this.state.referralTotalAmount}
            getReferralList={this.getReferralList}
            status={this.state.status}
            approvedReferralsSort={this.props.approvedReferralsSort}
            approvedReferralsSortDir={this.props.approvedReferralsSortDir}
            declinedReferralsSort={this.props.declinedReferralsSort}
            declinedReferralsSortDir={this.props.declinedReferralsSortDir}
            newReferralsSort={this.props.newReferralsSort}
            newReferralsSortDir={this.props.newReferralsSortDir}
            enableReferralSizeAndValue={enableReferralSizeAndValue}
            exportData={this.state.exportData}
            headers={headers}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  const {
    newReferrals,
    approvedReferrals,
    declinedReferrals,
    allReferrals,
    convertedReferrals
  } = state.table;

  const newReferralsSort =
    newReferrals && !newReferrals.isFetching ? newReferrals.sort : "";

  const newReferralsSortDir =
    newReferrals && !newReferrals.isFetching ? newReferrals.sortDir : "";

  const approvedReferralsSort =
    approvedReferrals && !approvedReferrals.isFetching
      ? approvedReferrals.sort
      : "";

  const approvedReferralsSortDir =
    approvedReferrals && !approvedReferrals.isFetching
      ? approvedReferrals.sortDir
      : "";

  const declinedReferralsSort =
    declinedReferrals && !declinedReferrals.isFetching
      ? declinedReferrals.sort
      : "";

  const declinedReferralsSortDir =
    declinedReferrals && !declinedReferrals.isFetching
      ? declinedReferrals.sortDir
      : "";
  const allReferralsSort =
    allReferrals && !allReferrals.isFetching ? allReferrals.sort : "";

  const allReferralsSortDir =
    allReferrals && !allReferrals.isFetching ? allReferrals.sortDir : "";

  const allReferralsCurrentPage =
    allReferrals && !allReferrals.isFetching ? allReferrals.currentPage : 1;

  const allReferralsCount =
    allReferrals && !allReferrals.isFetching ? allReferrals.totalCount : 0;

  //convertedReferrals count
  const convertedReferralCount =
    convertedReferrals && !convertedReferrals.isFetching
      ? convertedReferrals.totalCount
      : 0;

  return {
    newReferralsSort,
    newReferralsSortDir,
    approvedReferralsSort,
    approvedReferralsSortDir,
    declinedReferralsSort,
    declinedReferralsSortDir,
    allReferralsSort,
    allReferralsSortDir,
    allReferralsCurrentPage,
    allReferralsCount,
    convertedReferralCount
  };
};
export default connect(mapStateToProps)(ReferralReport);
