import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import toast from "../../../../components/base/Toast";
import DatePicker from "react-date-picker";

// Assets
import { LargeWatchIcon } from "../../../../assets/img/icons";

// Components
import Form from "../../../../components/base/Form";
import Number from "../../../../components/base/Number";
import Button from "../../../../components/base/Button";
import CancelButton from "../../../../components/base/CancelButton";

const LogHoursModal = ({
  modal,
  setModal,
  name,
  index,
  logHours,
  id,
  projectId
}) => {
  const [startDate, setStartDate] = useState(new Date());
  const [hours, setHours] = useState("");
  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      backdrop="static"
      className={["log-hours-modal", "edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={setModal}>
        <LargeWatchIcon />
        <h4 className={["font-weight-bold", "my-4"].join(" ")}>
          Log Time for “{name}”
        </h4>
      </ModalHeader>
      <Form
        onSubmit={values => {
          if (!hours) {
            toast.error("Hours is required");
            return false;
          }
          logHours(setModal, index, projectId, id, hours, startDate);
        }}
      >
        <ModalBody className={["text-center", "mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <Number
                placeholder="hrs"
                className={["hours-input"].join(" ")}
                name={`task-${index}-hours`}
                style={{ marginBottom: 0, marginRight: 0 }}
                onChange={e => {
                  setHours(e.target.value);
                }}
                value={hours}
              />
              <span>hours on</span>
              <div className="datepicker-wrapper">
                <DatePicker
                  name={`datepicker-task-${index}`}
                  className="datepicker"
                  dayPlaceholder="MM/DD/YYYY"
                  onChange={date => setStartDate(date)}
                  minDate={new Date()}
                  value={startDate}
                  clearIcon={null}
                  required
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={() => setModal()} />

            <Button label="Add Time" type="submit" />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default LogHoursModal;
