import { Badge, Input } from "reactstrap";
import React, { useState } from "react";
import { Link } from "react-router-dom";

// Icons
import { TrashIconAlt } from "../../../assets/img/icons";

// Components
import Label from "../../../components/base/Label";
import Avatar from "../../../components/base/Avatar";
import ProjectUserMessageModal from "./ProjectUserMessageModal";

// Helper
import { setExpertBadge } from "../../../lib/helper";

const SourceMoreExperts = props => {
  const [
    showProjectUserMessageModal,
    setShowProjectUserMessageModal
  ] = useState(false);
  const [currentExpert, setCurrentExpert] = useState(null);

  const {
    projectExpertList,
    deleteProjectExpert,
    handleExpertRequestApproval,
    expertRequestId,
    showDelete
  } = props;
  const currentUrl = window.location.pathname + window.location.search;

  return (
    <>
      {projectExpertList &&
        projectExpertList.map(projectExpert => {
          return (
            <div className="field-wrapper" key={projectExpert.expertId}>
              <div className="user-wrapper">
                {showDelete && (
                  <div className="user-actions">
                    <a
                      className="text-inline-grayed mr-1 source-more cursor-pointer"
                      onClick={e => deleteProjectExpert(projectExpert.id)}
                    >
                      <TrashIconAlt />
                    </a>
                    <div className="form-wrapper mr-2">
                      <Label>
                        <Input
                          type="radio"
                          name="user_radio"
                          defaultChecked={expertRequestId && false}
                          className="radio-button"
                          onClick={e =>
                            handleExpertRequestApproval({
                              expertId: projectExpert.expertId,
                              expertStatus: projectExpert.status
                            })
                          }
                        />
                        <p className="d-flex align-items-center justify-content-start">
                          <span className="radio-placeholder" />
                        </p>
                      </Label>
                    </div>
                  </div>
                )}

                <div className="field-icon">
                  <Link
                    target="_blank"
                    to={`/expert-profile/${projectExpert.expertId}?redirect=${currentUrl}`}
                  >
                    <Avatar
                      firstName={projectExpert.firstName}
                      lastName={projectExpert.lastName}
                      size="xs"
                      fontSize={12}
                      url={projectExpert.avatarUrl}
                    />
                  </Link>
                </div>
                <div className="expert-info ml-2 d-flex flex-column align-items-start">
                  {projectExpert.jobTitle && (
                    <p
                      className="h7 text-truncate"
                      style={{ maxWidth: "130px" }}
                    >
                      {projectExpert.jobTitle}
                    </p>
                  )}
                  <Link
                    target="_blank"
                    to={`/expert-profile/${projectExpert.expertId}?redirect=${currentUrl}`}
                    className={"font-weight-bold h7 text-truncate text-dark"}
                  >
                    {projectExpert.name ? projectExpert.name : ""}
                  </Link>

                  <div className={"status-tooltips"}>
                    <Badge
                      color={setExpertBadge(projectExpert.status)}
                      className="text-white h7 font-weight-bold text-uppercase d-inline"
                    >
                      {projectExpert.status}
                    </Badge>
                    {projectExpert.message && (
                      <div
                        onClick={() => {
                          setCurrentExpert(projectExpert.expertId);
                          setShowProjectUserMessageModal(
                            !showProjectUserMessageModal
                          );
                        }}
                        className="d-inline ml-2 cursor-pointer"
                      >
                        <Badge
                          color={"secondary"}
                          className="text-white h7 font-weight-bold text-uppercase px-2 d-inline"
                        >
                          {"IF"}
                        </Badge>
                      </div>
                    )}
                  </div>
                  {projectExpert.expertId === currentExpert &&
                    projectExpert.message && (
                      <ProjectUserMessageModal
                        message={projectExpert.message}
                        expertDetails={projectExpert}
                        isOpen={showProjectUserMessageModal}
                        toggle={() =>
                          setShowProjectUserMessageModal(
                            !showProjectUserMessageModal
                          )
                        }
                      />
                    )}
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default SourceMoreExperts;
