import React, { Component } from "react";

// Components
import toast from "../../components/base/Toast";

// Assets
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";
import { apiClient } from "../../apiClient";
import Spinner from "../../components/base/Spinner";

//Constant
import { MEDIA_APP_VIDEO, MEDIA_APP_PHOTO } from "../../views/app/Constant";

// Helper
import { getParamsByName } from "../../lib/helper";

import VideoItem from "./VideoItem";
import GalleryItem from "./GalleryItem";

export default class Gallery extends Component {
  constructor(props) {
    super(props);
    this.state = {
      photos: [],
      videos: [],
      closeImageModal: false,
      closeVideoModal: false,
      appId: "",
      isMediaLoading: false
    };
  }
  // Media Upload
  mediaUpload = values => {
    const data = new FormData();
    data.append("title", values && values.title ? values.title : "");
    data.append(
      "description",
      values && values.description ? values.description : ""
    );
    data.append("url", values && values.url ? values.url : "");
    data.append("uploadFile", values && values.file ? values.file : "");
    data.append(
      "uploadFileName",
      values && values.fileName ? values.fileName : ""
    );
    data.append("appId", this.state.appId ? this.state.appId : 1);
    data.append("object", values && values.object ? values.object : "");
    this.setState({ isMediaLoading: false });

    apiClient
      .post(`${endpoints().mediaAPI}`, data)
      .then(response => {
        if (response.data) {
          toast.success(response.data.message);

          //Get Media
          this.getMedia(values && values.object);

          if (values.object === MEDIA_APP_PHOTO) {
            this.setState({ closeImageModal: true });
          } else this.setState({ closeVideoModal: true });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  componentDidMount() {
    this.getMedia(MEDIA_APP_PHOTO);
    this.getMedia(MEDIA_APP_VIDEO);
  }

  openModal = () => {
    this.setState({ closeImageModal: false });
  };

  openVideoModal = () => {
    this.setState({ closeVideoModal: false });
  };

  getMedia = value => {
    this.setState({ isLoading: true }, async () => {
      const appId = getParamsByName("id");
      this.setState({ appId: appId });
      const apiUrl = `${
        endpoints().mediaAPI
      }/app?appId=${appId}&object=${value}`;

      try {
        const response = await apiClient.get(`${apiUrl}`);
        if (response && response.data && response.data.data) {
          this.setState({ isMediaLoading: true });
          if (value === MEDIA_APP_PHOTO) {
            this.setState({
              photos: response.data.data,
              isMediaLoading: false
            });
          } else
            this.setState({
              videos: response.data.data,
              isMediaLoading: false
            });
        }
      } catch (error) {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          console.error(errorMessage);
          this.setState({ isInvalidRequest: true, isMediaLoading: false });
        }
      }
    });
  };

  //Media Delete
  mediaDelete = data => {
    //call delete api
    let id = data.id;
    apiClient
      .delete(`${endpoints().mediaAPI}/${id}?object=${data.object}`)
      .then(response => {
        let message;
        if (response.data) {
          if (data.object == MEDIA_APP_PHOTO) {
            message = "Photo Deleted Successfully";
          } else message = "Video Deleted Successfully";
          toast.success(message);
          //Get Media
          this.getMedia(data && data.object);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  render() {
    const { photos, videos, closeImageModal, closeVideoModal } = this.state;

    const { isMediaLoading } = this.state;
    if (isMediaLoading) {
      return <Spinner />;
    }
    return (
      <>
        <div>
          {/* Gallery Item */}
          <GalleryItem
            imageUpload={e => {
              this.mediaUpload(e);
            }}
            handlePhotoDelete={this.mediaDelete}
            photos={photos}
            openModal={this.openModal}
            closeImageModal={closeImageModal}
          />
        </div>
        <br></br>
        <div>
          {/* Video Item */}
          <VideoItem
            videoUpload={e => {
              this.mediaUpload(e);
            }}
            handleVideoDelete={this.mediaDelete}
            videos={videos}
            openVideoModal={this.openVideoModal}
            closeVideoModal={closeVideoModal}
          />
        </div>
      </>
    );
  }
}
