import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CancelButton from "../../../components/base/CancelButton";

const CompletedProjectModal = ({
  id,
  modal,
  setModal,
  projectName,
  projectType,
  setCompletedProjectStatus
}) => {
  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      className={["edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={setModal}>
        <h4 className={["font-weight-bold"].join(" ")}>
          {projectType} Complete?
        </h4>
      </ModalHeader>
      <ModalBody className={["text-center", "mb-4"].join(" ")}>
        <div className="content">
          By checking off this final item, your project,{" "}
          <strong>"{projectName}"</strong>, will be marked as completed. Is
          this&nbsp;{projectType.toLowerCase()} fully complete?
        </div>
      </ModalBody>
      <ModalFooter className={["justify-content-center"].join(" ")}>
        <div className="btn-wrapper">
          <CancelButton onClick={() => setModal(prevState => !prevState)} />
          <button
            className="btn btn-success"
            onClick={() => {
              setCompletedProjectStatus();
              setModal();
            }}
          >
            Yes, this&nbsp;{projectType.toLowerCase()} is complete
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default CompletedProjectModal;
