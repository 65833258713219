import React from "react";
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";

//Assets
import {
  ChevronRight,
  SettingsGoogleAnalyticsIcon,
  SettingsMetaCxIcon
} from "../../../../assets/img/icons";

// Constants
import {
  INTEGRATION_ANALYCTICS_SUB_TAB,
  TAB_GOOGLE_ANALYTICS_SETTINGS,
  TAB_INTEGRATION,
  TAB_METACX_SETTINGS
} from "../../../../setting/Constants";
import GoogleAnalyticsSection from "./sections/GoogleAnalyticsSection";
import MetaCxSection from "./sections/MetaCxSection";

const AnalyticsSection = props => {
  const { activeSection, adminSettings, saveSettings, history } = props;

  return (
    <>
      {!activeSection && (
        <>
          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_ANALYCTICS_SUB_TAB}/${TAB_GOOGLE_ANALYTICS_SETTINGS}`}
            className="onboarding-link mb-2"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="step-count d-block completed">
                  <SettingsGoogleAnalyticsIcon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">Google Analytics</h5>
              <p className="font-weight-bold text-inline-grayed">
                {/* Sync leads from Salesforce */}
              </p>
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>

          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_ANALYCTICS_SUB_TAB}/${TAB_METACX_SETTINGS}`}
            className="onboarding-link mb-2"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="step-count d-block completed">
                  <SettingsMetaCxIcon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">MetaCx</h5>
              <p className="font-weight-bold text-inline-grayed">
                {/* Sync leads from Salesforce */}
              </p>
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>
        </>
      )}

      <TabContent activeTab={activeSection}>
        {/* Google Analytics Tab */}
        <TabPane tabId={TAB_GOOGLE_ANALYTICS_SETTINGS} className="w-100">
          <GoogleAnalyticsSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        {/* MetaCX Tab */}
        <TabPane tabId={TAB_METACX_SETTINGS} className="w-100">
          <MetaCxSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default AnalyticsSection;
