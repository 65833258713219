import React from "react";
import AddModal from "../../../components/base/Modal";
import { DashboardCampaignIcon } from "../../../assets/img/icons";
import { Link } from "react-router-dom";
// Helper
import { getCookie, setCookie } from "../../../lib/helper";
import { COOKIE_SHOW_CAMPAIGNS_NOTIFICATION_POPUP } from "../../../lib/cookie";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

// Icon
// import DashboardCampaignIcon from "../../../assets/img/icons/index";
class CampaignPopup extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      popUpClosed: false
    };
  }

  componentDidMount(prevProps) {
    const pop_status = getCookie(COOKIE_SHOW_CAMPAIGNS_NOTIFICATION_POPUP);
    const popup_show = pop_status === "false" ? true : false;
    if (this.props.isOpen && popup_show) {
      this.setState({
        popUpClosed: true
      });
    }
  }

  _toggle = () => {
    this.setState({ popUpClosed: true });
    setCookie(COOKIE_SHOW_CAMPAIGNS_NOTIFICATION_POPUP, "false");
  };

  render() {
    const campaignModalHeader = (
      <>
        <div>
          <div
            className="d-flex align-items-center ml-auto mr-auto"
            style={{
              width: "150px"
            }}
          >
            <DashboardCampaignIcon />
          </div>
          <div className="font-weight-bold mt-2">
            <span>Welcome back! You have been</span>
            <br />
            <span>invited to participate in a</span>
            <br />
            <span>{Terminology.get(SystemName.COMARKETING_CAMPAIGN)}</span>
          </div>
        </div>
      </>
    );
    const campaignModalBody = (
      <div className="ml-3 mr-3 mt-2 mb-3">
        <div>
          <span>You're ready to review and accept the invite to</span>
          <br />
          <span>
            participate in the{" "}
            {Terminology.get(SystemName.COMARKETING_CAMPAIGN)}
          </span>
        </div>
      </div>
    );
    const campaignModalFooter = (
      <>
        <div className="col-md-12 mb-4">
          <Link
            className="text-decoration-none"
            onClick={this._toggle}
            to="/campaigns?status=Active"
          >
            <button id="viewReferralButton" className="btn btn-primary">
              Go to {Terminology.get(SystemName.COMARKETING_CAMPAIGN)}
            </button>
          </Link>
        </div>
      </>
    );

    return (
      <div>
        {!this.state.popUpClosed ? (
          <AddModal
            isOpen={this.props.isOpen}
            toggle={this._toggle}
            toggleModalClose={this._toggle}
            HeaderClassName={
              ({ "modal-title": "w-100 text-center mt-3" },
              { backgroundColor: "#DCDCDC" })
            }
            modalTitle={campaignModalHeader}
            modalBody={campaignModalBody}
            modalFooter={campaignModalFooter}
            hideDefaultButtons
          />
        ) : (
          ""
        )}
      </div>
    );
  }
}

export default CampaignPopup;
