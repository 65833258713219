import React from "react";

// API call
import { apiClient } from "../../apiClient";

// Configs
import {
  DEFAULT_API_KEY,
  endpoints,
  TORCHLITE_WEBSITE_URL
} from "../../configs";

import AuthButtons, {
  GoogleButton,
  LinkedInButton,
  OfficeButton,
  SalesforceButton
} from "../../components/authentication/AuthButtons";

import toast from "../../components/base/Toast";

// Helper
import { setCookie, clearCookie } from "../../lib/helper";

// Constants
import { PARTNER_SIGNUP_WELCOME_MESSAGE } from "../../partnerSignup/Constants";
import Text from "../../components/base/Text";
import Email from "../../components/base/Email";
import Password from "../../components/base/Password";
import Checkbox from "./Checkbox";
import { isBadRequest } from "../../common/http";

/**
 * Remove local storage when user signup with social auth
 */
function removeOAuthLocalStorage() {
  clearCookie("googleAuthToken");
  clearCookie("office365AuthToken");
  clearCookie("linkedinAuthToken");
  clearCookie("salesforceAuthToken");
}

class GetStarted extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptTerms: false,
      required: false
    };
  }
  // Signup with Google OAuth
  signUpWithGoogle(googleToken, callback, actions) {
    removeOAuthLocalStorage();
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    apiClient
      .get(
        `${endpoints().userAPI}/google/auth/details?googleToken=${googleToken}`
      )
      .then(response => {
        actions && actions.setAccountSignUpMethod(true);
        setCookie(
          "googleAuthToken",
          response.data.authToken ? response.data.authToken : ""
        );

        actions && actions.setUserDetails(response.data);
      })
      // .then(() => callback())
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Signup with Microsoft Office365
  signUpWithOffice365(office365Token, callback, actions) {
    removeOAuthLocalStorage();
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    apiClient
      .get(
        `${
          endpoints().userAPI
        }/office365/auth/details?office365Token=${office365Token}`
      )
      .then(response => {
        actions && actions.setAccountSignUpMethod(true);
        setCookie(
          "office365AuthToken",
          response.data.authToken ? response.data.authToken : ""
        );

        actions && actions.setUserDetails(response.data);
      })
      // .then(() => callback())
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Signup with Linkedin
  signUpWithLinkedin(linkedInAccessToken, callback, actions) {
    removeOAuthLocalStorage();
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    apiClient
      .get(
        `${
          endpoints().userAPI
        }/linkedin/auth/details?linkedInAccessToken=${linkedInAccessToken}`
      )
      .then(response => {
        actions && actions.setAccountSignUpMethod(true);
        setCookie(
          "linkedinAuthToken",
          response.data.authToken ? response.data.authToken : ""
        );

        actions && actions.setUserDetails(response.data);
      })
      // .then(() => callback())
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Signup with Salesforce
  signUpWithSalesforce(salesforceToken, callback, actions) {
    removeOAuthLocalStorage();
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    apiClient
      .get(
        `${
          endpoints().userAPI
        }/salesforce/auth/details?salesforceToken=${salesforceToken}`
      )
      .then(response => {
        actions && actions.setAccountSignUpMethod(true);
        setCookie(
          "salesforceAuthToken",
          response.data.authToken ? response.data.authToken : ""
        );

        actions && actions.setUserDetails(response.data);
      })
      // .then(() => callback())
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    let { partnerSignupWelcomeMessage } = this.props;
    const {
      marketplaceName,
      enableSalesforceLogin,
      enableOffice365Login,
      enableGoogleLogin,
      enableLinkedInLogin,
      linkedInClientId,
      linkedInRedirectUri,
      encryptedUserId,
      googleClientId,
      salesforceLoginClientId,
      salesforceLoginRedirectUrl,
      isThirdPartyAccountSignUp
    } = this.props;

    partnerSignupWelcomeMessage = partnerSignupWelcomeMessage
      ? partnerSignupWelcomeMessage
      : PARTNER_SIGNUP_WELCOME_MESSAGE;

    if (this.props.currentStep !== this.props.step) {
      // Prop: The current step
      return null;
    }

    const { required } = this.state;

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <h3 className="font-weight-bold mb-0">
            {/* {partnerSignupWelcomeMessage} */}
            {this.props.partnerTypeWelcomeMessage ? (
              <>
                <div
                  dangerouslySetInnerHTML={{
                    __html: this.props.partnerTypeWelcomeMessage
                  }}
                />
              </>
            ) : (
              <>{`Join ${marketplaceName} on Torchlite`}</>
            )}
          </h3>
        </div>

        <AuthButtons>
          {enableSalesforceLogin === "true" && (
            <SalesforceButton
              redirectUrl={"/partner-signup?section=basic_information"}
              signUpWithSalesforce={this.signUpWithSalesforce}
              eventKey="salesforce-signup"
              actions={this.props}
              salesforceLoginClientId={salesforceLoginClientId}
              salesforceLoginRedirectUrl={salesforceLoginRedirectUrl}
            />
          )}

          {enableGoogleLogin === "true" && (
            <GoogleButton
              redirectUrl={"/partner-signup?section=basic_information"}
              signUpWithGoogle={this.signUpWithGoogle}
              eventKey="google-signup"
              actions={this.props}
              googleClientId={googleClientId}
            />
          )}

          {enableOffice365Login === "true" && (
            <OfficeButton
              redirectUrl={"/partner-signup?section=basic_information"}
              signUpWithOffice365={this.signUpWithOffice365}
              eventKey="microsoft-office265-signup"
              actions={this.props}
            />
          )}

          {enableLinkedInLogin === "true" && (
            <LinkedInButton
              redirectUrl={"/partner-signup?section=basic_information"}
              signUpWithLinkedin={this.signUpWithLinkedin}
              eventKey="linkedin-signup"
              actions={this.props}
              linkedInClientId={linkedInClientId}
              linkedInRedirectUri={linkedInRedirectUri}
            />
          )}
        </AuthButtons>
        <div className="login-form-inline-title">
          <p>or signup with email</p>
        </div>

        <div className="field-wrapper">
          <Text
            name="firstName"
            label="First Name"
            placeholder="First Name"
            error=""
            {...{ required }}
          />
        </div>

        <div className="field-wrapper">
          <Text
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            error=""
            {...{ required }}
          />
        </div>

        <div className="field-wrapper">
          <Email
            name="email"
            label="Email Address"
            placeholder="Work Email Address"
            {...{ required }}
            disabled={encryptedUserId ? true : false}
          />
        </div>

        {!isThirdPartyAccountSignUp && (
          <>
            <div className="field-wrapper">
              <Password
                name="password"
                label="Password"
                placeholder="Password"
                required={true}
                error={
                  this.props.passwordMismatchError
                    ? this.props.passwordMismatchError
                    : ""
                }
                strongPassword={
                  this.props.passwordMismatchError ===
                  "Please choose stronger password"
                }
                onInputChange={this.props.handlePasswordErrors}
                {...{ required }}
              />
            </div>

            <div className="field-wrapper">
              <Password
                name="confirmPassword"
                label="Confirm Password"
                placeholder="Confirm Password"
                required={true}
                error={
                  this.props.confirmPasswordMismatchError
                    ? this.props.confirmPasswordMismatchError
                    : ""
                }
                onInputChange={this.props.handlePasswordErrors}
                {...{ required }}
              />
            </div>
          </>
        )}

        <div className="field-wrapper position-relative">
          <Checkbox
            name="acceptTerms"
            label="I have read and accept the"
            required={true}
            error="You must accept the terms and conditions"
            className={"validation accepted-terms mr-3"}
          />{" "}
          <a
            className="term-and-condition cursor-pointer"
            href={`${TORCHLITE_WEBSITE_URL}/terms-of-service`}
            target="_blank"
            rel="noopener noreferrer"
          >
            terms and conditions
          </a>
        </div>
        <p className="term-and-condition pt-3">
          By agreeing to these terms, you agree that Torchlite may share any
          User Content with {marketplaceName}, including your name, location,
          contact information and any other information to permit{" "}
          {marketplaceName}’s solicitation of your business.
        </p>
        <div className="btn-wrapper mt-4-5">
          <button
            id="btn-getstarted"
            className="btn btn-login w-100"
            type="submit"
            onClick={() => {
              this.setState({ required: true });
              // return this.props.next();
            }}
          >
            Get Started
          </button>
        </div>
        {/* /.btn-wrapper */}
      </div>
    );
  }
}

export default GetStarted;
