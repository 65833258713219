export const APP_STATUS_DRAFT = "Draft";
export const APP_STATUS_IN_REVIEW = "In Review";
export const APP_STATUS_APPROVED = "Approved";
export const APP_STATUS_REJECTED = "Rejected";
export const APP_STATUS_DECLINED = "Declined";
export const APP_STATUS_SUSPENDED = "Suspended";

//type
export const APP_TYPE_PUBLIC = "Public";
export const APP_TYPE_PRIVATE = "Private";

//Visibility
export const APP_VISIBILITY_LIVE = "Live";
export const APP_VISIBILITY_DISABLED = "Disabled";

export const SITE_VISIBILITY_DISABLED = "Disabled";
export const SITE_VISIBILITY_LIVE = "Live";

//Media
export const MEDIA_APP_PHOTO = "APP_PHOTO";
export const MEDIA_APP_VIDEO = "APP_VIDEO";

//cookie constant
export const COOKIE_MARKETPLACE_APP_ID = "appId";
