import { Local } from "../Constants/LocalStorage";

class Permission {
  //  Permissions
  static REFERRAL_MANAGE_OTHERS = "referral_manage_others";
  static ENABLE_THEME_VIEW = "enable_theme_view";

  static UserPermissionLists() {
    return [
      {
        label: this.REFERRAL_MANAGE_OTHERS,
        value: this.REFERRAL_MANAGE_OTHERS
      },
      {
        label: this.ENABLE_THEME_VIEW,
        value: this.ENABLE_THEME_VIEW
      }
    ];
  }

  static permissionList = [
    {
      title: "Referral",
      permission: [
        { name: this.REFERRAL_MANAGE_OTHERS, label: "Referral Manage Others " }
      ]
    },
    {
      title: "Admin Settings",
      permission: [
        { name: this.ENABLE_THEME_VIEW, label: "Enable Theme Edit " }
      ]
    }
  ];
  static get(name) {
    const data = localStorage.getItem(Local.PERMISSION);
    if (data) {
      return data.includes(name);
    } else {
      return false;
    }
  }
}

export default Permission;
