import React from "react";
import OnboardingLink from "./OnboardingLink";
import { RocketGraphicIcon } from "../../../assets/img/icons";

const ExpertStatus = ({
  expertAvailableStatus,
  expertAvailableText,
  percentage
}) => (
  <div className="expert-onboarding">
    <OnboardingLink
      icon={<RocketGraphicIcon />}
      title={"Hey Karl. Great job so far!"}
      description={"You are currently a Certified Pro Expert."}
      availability={{
        status:
          expertAvailableStatus === "Completed" ? "available" : "not-available",
        text: expertAvailableText
      }}
      progress={percentage}
      expertStatus={{
        accepted: true,
        experttype: "normal"
      }}
    />
  </div>
);

export default ExpertStatus;
