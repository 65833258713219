import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownItem, Nav, NavItem, NavLink } from "reactstrap";
// Components
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import MoreDropdown from "../../../../components/base/MoreDropdown";
import DeleteModal from "../../../../components/base/DeleteModal";

import AddLandingPageModal from "./AddLandingPageModal";

// Helper
import { isLoggedIn } from "../../../../lib/helper";

// Actions
import {
  deleteLandingPage,
  updateLandingPage
} from "../../../../actions/landingPage";

// Constants
import {
  LANDING_PAGE_STATUS_ACTIVE,
  LANDING_PAGE_STATUS_DRAFT
} from "../../../../landingPage/Constants";
import {
  MARKETPLACES_SUB_TAB,
  TAB_MARKETPLACE
} from "../../../../setting/Constants";
import LandingPageDetails from "./LandingPageDetails";

const statusStyle = {
  borderRadius: "7px",
  color: "white",
  fontWeight: 600,
  width: "64px"
};

class MarketplaceTab extends React.Component {
  state = {
    searchTerm: "",
    deletePlaybookModal: false,
    deleteId: "",
    name: "",
    selectedId: "",
    sortByOptions: [
      {
        value: "name:ASC",
        label: "Name"
      },
      {
        value: "createdAt:DESC",
        label: "Most Recent"
      }
    ]
  };
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }
  deleteModal = row => {
    this.setState({
      deleteId: row.id,
      name: row.name
    });
    this.setState({
      deletePlaybookModal: true
    });
  };
  // Landing page delete api call
  handleDelete = id => {
    this.props.actions.deleteLandingPage(id, this.state.searchTerm);
  };

  // Update by status
  updateByStatus(id, status) {
    this.props.actions.updateLandingPage(
      id,
      { status: status },
      this.state.searchTerm
    );
  }
  saveSearchTerm = value => {
    this.setState({ searchTerm: value });
  };

  render() {
    const headerButton = (
      <div className="btn-wrapper">
        <AddLandingPageModal />
      </div>
    );
    const currentUrl = this.props.history.location.search;

    let marketplaceId;
    // get landingpage id
    if (this.props.match && this.props.match.params.tab === TAB_MARKETPLACE) {
      marketplaceId = this.props.activeSection;
    }
    const { deletePlaybookModal } = this.state;

    return (
      <>
        <DeleteModal
          isOpen={deletePlaybookModal}
          toggle={() => {
            this.setState({ deletePlaybookModal: false });
          }}
          title="Delete Landing Page"
          label={this.state.name}
          deleteFunction={() => this.handleDelete(this.state.deleteId)}
        />
        <>
          {!marketplaceId ? (
            <div className="mt-4 mb-5">
              <AddLandingPageModal />
              <Nav tabs className="admin-tabs">
                {/*Landing Page Tab */}
                <NavItem>
                  <NavLink className="active">{MARKETPLACES_SUB_TAB}</NavLink>
                </NavItem>
              </Nav>
              <ReduxTable
                className="mt-3 mb-3"
                id="landingPage"
                apiURL="/v1/landingPage"
                headerButton={headerButton}
                showHeader
                newTableHeading
                searchPlaceholder="Search"
                onRowClick={row => {
                  this.setState({ selectedId: row.id });
                  this.props.history.push(
                    `/admin-settings/${TAB_MARKETPLACE}/${MARKETPLACES_SUB_TAB}/${row.id}?redirect${currentUrl}`
                  );
                }}
                sortByOptions={this.state.sortByOptions}
                paramsToUrl={true}
                history={this.props.history}
                islandingTable={true}
                saveSearchTerm={this.saveSearchTerm}
              >
                <ReduxColumn
                  width={"80px"}
                  className="col-1"
                  type="link"
                  isClickable="true"
                  field="name"
                  sortBy="name"
                >
                  Name
                </ReduxColumn>

                <ReduxColumn
                  width={"20px"}
                  className="col-1"
                  field="status"
                  disableOnClick
                  renderField={row => (
                    <div
                      className={`text-center ${
                        row.status === LANDING_PAGE_STATUS_ACTIVE
                          ? "landing-page-status-active"
                          : "landing-page-status-draft"
                      }`}
                      style={statusStyle}
                    >
                      <p>{row.status}</p>
                    </div>
                  )}
                >
                  Status
                </ReduxColumn>
                <ReduxColumn
                  width={"20px"}
                  field="status"
                  disableOnClick
                  renderField={row => (
                    <div className="text-center landing-group-dropdown">
                      <MoreDropdown>
                        <DropdownItem
                          onClick={() =>
                            window.open(
                              `/marketplace/${
                                row.vanity_url_slug
                                  ? row.vanity_url_slug
                                  : row.id
                              }?eid=${encodeURIComponent(row.eid)}`,
                              "_blank"
                            )
                          }
                        >
                          View Landing Page
                        </DropdownItem>
                        {row.status === LANDING_PAGE_STATUS_DRAFT ? (
                          <DropdownItem
                            onClick={() =>
                              this.updateByStatus(
                                row.id,
                                LANDING_PAGE_STATUS_ACTIVE
                              )
                            }
                          >
                            Make Active
                          </DropdownItem>
                        ) : (
                          <DropdownItem
                            onClick={() =>
                              this.updateByStatus(
                                row.id,
                                LANDING_PAGE_STATUS_DRAFT
                              )
                            }
                          >
                            Save as Draft
                          </DropdownItem>
                        )}
                        <DropdownItem
                          className={"text-danger"}
                          onClick={() => this.deleteModal(row)}
                        >
                          Delete
                        </DropdownItem>
                      </MoreDropdown>
                    </div>
                  )}
                >
                  Action
                </ReduxColumn>
              </ReduxTable>
            </div>
          ) : (
            <LandingPageDetails
              marketplaceId={this.state.selectedId || marketplaceId}
              sectionId={this.props.activeSubSection}
              history={this.props.history}
              match={this.props.match}
              settings={this.props.settings}
            />
          )}
        </>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { deleteLandingPage, updateLandingPage },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(MarketplaceTab);
