import React, { Component } from "react";
import { Col, Row } from "reactstrap";
import PropTypes from "prop-types";

// API Client
import { apiClient } from "../../apiClient";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchPlaybookList, changePlaybookPage } from "../../actions/playbook";

// Page components
import NoRecordsFound from "../../components/base/NoRecordsFound";
import PlaybookCard from "../../components/PlaybookCard";
import toast from "../../components/base/Toast";
import AddModal from "../../components/base/Modal";

// Configs
import { endpoints } from "../../configs";
import marketplaceIcon from "../../assets/img/icons/icon-marketplace.svg";

// Helper
import {
  getParamsByName,
  isSuperAdmin,
  isCompanyAdmin,
  isCustomer,
  isPartner,
  getKeyValueByObject,
  isCompanyManager
} from "../../lib/helper";

// Components
import PageSearch from "../../components/base/PageSearch";
import AddButton from "../../components/base/AddButton";
import Spinner from "../../components/base/Spinner";
import Pagination from "../../components/base/Pagination";
import SelectDropdown from "../../components/base/SelectDropdown";

// 404 Page Redirect
import Page404 from "../Page404";

// Constant
import {
  PLAYBOOK_STATUS_DRAFT,
  PLAYBOOK_STATUS_ACTIVE
} from "../../playbookStatus/Constants";
import { SETTINGS_ALLOW_PARTNER_TO_CREATE_PLAYBOOK } from "../../setting/Constants";
import { isBadRequest } from "../../common/http";

class Playbook extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      searchParam: "",
      status: "",
      activeTab: "playbooks",
      currentPage: 1,
      pageSize: 12,
      isOpen: false,
      activePlaybookList: [],
      playbookIds: [],
      sortByOptions: [
        {
          value: "Active",
          label: "Active Playbooks"
        },
        {
          value: "Draft",
          label: "Draft Playbooks"
        }
      ],
      selectedSortOption: "Active Playbooks"
    };

    this.toggleModal = this.toggleModal.bind(this);
    this.toggleModalClose = this.toggleModalClose.bind(this);
    this.handlePlaybooks = this.handlePlaybooks.bind(this);
  }

  // Handle Page Change
  handlePageChange(page) {
    this.setState(
      {
        currentPage: page
      },
      () => {
        const { actions, playbooks } = this.props;

        if (
          !(page in playbooks.pages) ||
          playbooks.statuses[page] !== this.state.status
        ) {
          actions.fetchPlaybookList(
            this.updateUrl({
              status: this.state.status,
              page: page,
              pageSize: this.state.pageSize,
              search: this.state.searchParam
            })
          );
        } else {
          actions.changePlaybookPage(page);
        }
      }
    );
  }

  componentDidMount() {
    if (isPartner(this.props.roleId)) {
      this.getPlaybooksList();
    }
    const { actions } = this.props;

    const searchParam = getParamsByName("search") || "";

    let status = PLAYBOOK_STATUS_ACTIVE;
    this.setState(
      {
        status: PLAYBOOK_STATUS_ACTIVE
      },
      () => {
        actions.fetchPlaybookList(
          this.updateUrl({
            status,
            pageSize: this.state.pageSize,
            search: searchParam
          })
        );
        this.props.getPlaybookList(this.state.status);
      }
    );
    window.localStorage.removeItem("playbookActiveTab");
  }

  // Form Playbook URL
  updateUrl(data) {
    const { search, page, pageSize, sort, sortDir, status } = data;

    const params = [];

    if (search) {
      params.push(`search=${search}`);
    }

    if (status) {
      params.push(`status=${status}`);
    }

    if (page) {
      params.push(`page=${page}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    if (params.length > 0) {
      return params.join("&");
    }
  }

  pushQueryToUrl() {
    const { searchParam, status, activeTab } = this.state;
    const params = [];

    if (searchParam) {
      params.push(`search=${searchParam}`);
    }

    if (status) {
      params.push(`status=${status}`);
    }

    this.props.history.push(
      `/marketplace-sourcing?section=${activeTab}&${params.join("&")}`
    );
  }

  // Toggle Active Tab
  toggle(tab) {
    this._handleStatusChange(tab);
    this.setState({
      activeTab: tab,
      currentPage: 1
    });
  }

  // Save playbook status as Draft
  _handleSaveDraft = id => {
    return apiClient
      .put(`${endpoints().playbookAPI}/draft/${id}`)
      .then(() => {
        this.props.history.push(
          `/marketplace-sourcing?section=${PLAYBOOK_STATUS_ACTIVE}`
        );
        this._handleStatusChange(PLAYBOOK_STATUS_ACTIVE);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  //Save playbook status as Active
  _handleSavePublish = id => {
    return apiClient
      .put(`${endpoints().playbookAPI}/publish/${id}`)
      .then(() => {
        this.props.history.push(
          `/marketplace-sourcing?section=${PLAYBOOK_STATUS_DRAFT}`
        );
        this._handleStatusChange(PLAYBOOK_STATUS_DRAFT);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Create duplicate playbook
  _handleSaveDuplicate = id => {
    return apiClient
      .post(`${endpoints().playbookAPI}/duplicate/${id}`)
      .then(() => {
        const searchStatus = this.state.activeTab;
        this.props.history.push(
          `/marketplace-sourcing?section=${searchStatus}`
        );
        this._handleStatusChange(searchStatus);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // _getPlaybookList() {
  //   apiClient.get(`${endpoints().playbookAPI}/`).then(response => {
  //     const data = response.data;
  //   });
  // }

  // Delete duplicate playbook
  _handleDelete = id => {
    return apiClient
      .delete(`${endpoints().playbookAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        const searchStatus = this.state.activeTab;
        this.props.history.push(
          `/marketplace-sourcing?section=${searchStatus}`
        );
        this._handleStatusChange(searchStatus);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Search playbook
  _handleInputChange = e => {
    this.setState(
      {
        searchParam: e.target.value
      },
      () => {
        const { searchParam, status } = this.state;

        const params = {
          status,
          search: encodeURIComponent(searchParam),
          pageSize: this.state.pageSize
        };
        this.pushQueryToUrl();

        this.props.actions.fetchPlaybookList(this.updateUrl(params));
      }
    );
  };

  // Search playbook by status
  _handleStatusChange(searchStatus) {
    const searchParam = getParamsByName("search") || "";
    this.setState({ status: searchStatus }, () => {
      this.pushQueryToUrl();
      this.getPlaybooksList();
      this.props.actions.fetchPlaybookList(
        this.updateUrl({
          page: this.state.currentPage,
          pageSize: this.state.pageSize,
          status: this.state.status,
          search: searchParam
        })
      );
    });
  }

  toggleModal() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  // Handle Modal Click
  toggleModalClose() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  // Get Playbook List
  getPlaybooksList() {
    this.setState({ isPlaybookLoading: true }, () => {
      apiClient
        .get(
          `${endpoints().playbookAPI}?pagination=true?status=${
            this.state.status
          }?search=${this.state.searchParam}?pageSize=${this.state.pageSize}`
        )
        .then(response => {
          const activePlaybookList = response.data.data;

          this.setState({
            isPlaybookLoading: false,
            activePlaybookList: activePlaybookList
              ? activePlaybookList
              : this.state.activePlaybookList
          });
        });
    });
  }

  // Handle playbooks
  handlePlaybooks(value) {
    const playbookIds = this.state.playbookIds.slice();
    if (playbookIds.indexOf(value) > -1) {
      this.setState({ playbookIds: playbookIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ playbookIds: playbookIds.concat(value) });
    }
  }

  // Handle Selected Playbooks
  handleSelectedPlaybooks = () => {
    this.toggleModal();
    const playbookIds = this.state.playbookIds;
    const selectedPlaybooks = playbookIds.length > 0 ? playbookIds : [];
    this.createPartnerPlaybook(selectedPlaybooks);
  };

  // Partner Playbook Create API Call
  async createPartnerPlaybook(playbookIds) {
    try {
      const response = await apiClient.post(endpoints().partnerPlaybookAPI, {
        playbookIds: playbookIds
      });
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }

      setTimeout(() => {
        toast.success(successMessage);

        const url =
          this.state.publishStatus === PLAYBOOK_STATUS_ACTIVE
            ? `/marketplace-sourcing?section=${PLAYBOOK_STATUS_ACTIVE}`
            : "/marketplace-sourcing";
        this.props.history.push(url);
        this._handleStatusChange(PLAYBOOK_STATUS_ACTIVE);

        if (isPartner(this.props.roleId)) {
          this.getPlaybooksList();
        }
      }, 2000);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  }

  // Handle remove partner playbooks
  handleRemovePlaybooks = async id => {
    try {
      const response = await apiClient.delete(
        `${endpoints().partnerPlaybookAPI}/${id}`
      );
      const searchStatus = this.state.activeTab;
      this.props.history.push(
        `/marketplace-sourcing${searchStatus && `?section=${searchStatus}`}`
      );
      this._handleStatusChange(searchStatus);

      if (isPartner(this.props.roleId)) {
        this.getPlaybooksList();
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  handleSortByChange = value => {
    const searchParam = getParamsByName("search") || "";
    this._handleStatusChange(this.getSortValueFromLabel(value));
    this.setState({
      status: this.getSortValueFromLabel(value),
      currentPage: 1,
      search: searchParam
    });
  };

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      this.props.getPlaybookList(selectedSortOption.value);

      return selectedSortOption.value;
    }

    return "";
  }

  render() {
    // Props
    const { history, playbooks, playbookDetails, settings } = this.props;

    // State
    const { searchParam, currentPage, activeTab, pageSize } = this.state;

    let playbooktList;
    let pagination;
    let totalCount;
    let draftedPlaybookCount, activePlaybookCount;
    if (playbooks.pagination) {
      pagination = playbooks.pagination;
      playbooktList = playbooks.pages[pagination.currentPage];
      totalCount = pagination.totalCount;
      activePlaybookCount = pagination.activePlaybookCount || 0;
      draftedPlaybookCount = pagination.draftedPlaybookCount || 0;
    }

    const dataArray = [];

    const adminPlaybookDetails = playbookDetails || {};
    Object.keys(playbooktList || {}).map(key => {
      const data = adminPlaybookDetails[playbooktList[key]];
      if (data) {
        dataArray.push(data);
      }
    });

    let startPage = 1;
    let endPage = "";

    startPage = (currentPage - 1) * pageSize;
    startPage = startPage > totalCount ? totalCount : startPage;

    endPage = currentPage * pageSize;
    endPage = endPage > totalCount ? totalCount : endPage;

    const {
      activePlaybookList,
      playbookIds,
      selectedSortOption,
      sortByOptions
    } = this.state;

    const playbookList = totalCount > 1 ? dataArray : dataArray;

    const currentUrl = window.location.pathname + window.location.search;

    const playbookModalHeader = "SELECT PLAYBOOKS";
    const playbookModalBody = (
      <div className="row mt-n4">
        {!activePlaybookList.length ? (
          <p className="text-center w-100 mt-4">No playbooks are added yet</p>
        ) : (
          ""
        )}
        {activePlaybookList.length > 0 &&
          activePlaybookList.map(playbook => (
            <div className="col-12 col-md-12 col-lg-6 col-xl-4">
              <PlaybookCard
                data={playbook}
                isActive={playbookIds.indexOf(parseInt(playbook.id, 10)) > -1}
                selectPlaybooks={true}
                handlePlaybooks={this.handlePlaybooks}
                key={playbook.id}
                history={this.props.history}
              />
            </div>
          ))}
      </div>
    );

    const playbookModalFooter = (
      <AddButton
        label="Add Selected Playbooks"
        onClick={() => this.handleSelectedPlaybooks()}
        isDisabled={playbookIds.length > 0 ? false : true}
      />
    );

    // Return Page 404
    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId) &&
      !isCompanyManager(this.props.roleId) &&
      !isPartner(this.props.roleId) &&
      !isCustomer(this.props.roleId)
    ) {
      return <Page404 />;
    }

    const settingsData = settings ? settings : "";

    const allowPartnerToCreatePlaybook = getKeyValueByObject(
      settingsData,
      SETTINGS_ALLOW_PARTNER_TO_CREATE_PLAYBOOK
    );

    return (
      <>
        <div className="row">
          <div className="col">
            {isPartner(this.props.roleId) && (
              <AddButton
                label="Select Playbooks"
                className="pull-right btn btn-secondary ml-2 font-weight-bold my-3"
                onClick={() =>
                  this.setState({ playbookIds: [] }, () => this.toggleModal())
                }
              />
            )}
          </div>
        </div>

        {/*Select Playbooks Modal*/}
        <AddModal
          isOpen={this.state.isOpen}
          toggle={this.toggleModal}
          toggleModalClose={this.toggleModalClose}
          HeaderClassName="expert-modal"
          modalTitle={playbookModalHeader}
          modalBody={playbookModalBody}
          modalFooter={playbookModalFooter}
          hideDefaultButtons
        />
        {activeTab && (
          <div className="page-heading d-flex justify-content-between cover">
            <PageSearch
              classnames="page-search"
              placeholder={`Search`}
              onChange={this._handleInputChange.bind(this)}
              value={this.state.searchParam}
            />

            {(isPartner(this.props.roleId) ||
              isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) && (
              <SelectDropdown
                buttonLabel={selectedSortOption}
                dropdownLinks={sortByOptions}
                color={"gray"}
                hideCaret
                selectName={"sortby_partners"}
                handleChange={this.handleSortByChange}
              />
            )}
          </div>
        )}
        {/* /.page-heading */}
        <div className="mt-4 mb-5">
          {playbooks.isFetching ? (
            <Spinner />
          ) : totalCount ? (
            <>
              <div className="row pl-3">
                {playbookList.map(playbook => (
                  <PlaybookCard
                    showOption={
                      isSuperAdmin(this.props.roleId) ||
                      isCompanyAdmin(this.props.roleId) ||
                      isPartner(this.props.roleId) ||
                      isCompanyManager(this.props.roleId)
                    }
                    data={playbook}
                    link={
                      isSuperAdmin(this.props.roleId) ||
                      isCompanyAdmin(this.props.roleId) ||
                      isCompanyManager(this.props.roleId) ||
                      (isPartner(this.props.roleId) && playbook.partnerId)
                        ? `/marketplace-sourcing/playbook/edit/${playbook.id}`
                        : `/playbook/${playbook.id}?redirect=${currentUrl}`
                    }
                    openLinkInNewTab={
                      isSuperAdmin(this.props.roleId) ||
                      isCompanyAdmin(this.props.roleId) ||
                      isCompanyManager(this.props.roleId) ||
                      isPartner(this.props.roleId)
                        ? ""
                        : true
                    }
                    viewLink={`/playbook/${playbook.id}?redirect=${currentUrl}`}
                    onSaveDraft={this._handleSaveDraft.bind(this, playbook.id)}
                    onSavePublish={this._handleSavePublish.bind(
                      this,
                      playbook.id
                    )}
                    onSaveDuplicate={this._handleSaveDuplicate.bind(
                      this,
                      playbook.id
                    )}
                    removePartnerPlaybook={this.handleRemovePlaybooks.bind(
                      this,
                      playbook.id
                    )}
                    delete={this._handleDelete.bind(this, playbook.id)}
                    history={history}
                    isPreview
                    showEditOptions
                    size={"col-xl-4 col-lg-6 col-md-6"}
                  />
                ))}
              </div>
              {/* Show the Pagination */}
              {totalCount > 0 && (
                <Row>
                  <Col>
                    Showing {startPage + 1} to {endPage} of {totalCount} entries
                  </Col>
                  <Col>
                    <Pagination
                      currentPage={currentPage}
                      totalCount={totalCount}
                      pageSize={pageSize}
                      onPageChange={this.handlePageChange.bind(this)}
                    />
                  </Col>
                </Row>
              )}
            </>
          ) : searchParam ? (
            !totalCount ? (
              <NoRecordsFound hideCard={true} />
            ) : (
              ""
            )
          ) : (
            <NoRecordsFound
              hideCard={true}
              icon={marketplaceIcon}
              description={
                isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)
                  ? `Get started by selecting "New Playbook".`
                  : `This is where content and materials shared with you will be available.`
              }
            />
          )}
        </div>
      </>
    );
  }
}

Playbook.propTypes = {
  actions: PropTypes.object,
  playbooks: PropTypes.object,
  playbookDetails: PropTypes.object
};

function mapStateToProps(state) {
  return {
    playbooks: state.playbooks,
    playbookDetails: state.playbookDetails,
    roleId: state.user ? state.user.roleId : ""
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        fetchPlaybookList,
        changePlaybookPage
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Playbook);
