import React from "react";
import toast from "../../components/base/Toast";

// Page components
import ResourceFormFields from "./ResourceFormFields";

// Components
import PageTitle from "../../components/base/PageTitle";
import Form from "../../components/FormWithErrorHandling";
import CancelButton from "../../components/base/CancelButton";
import Spinner from "../../components/base/Spinner";
import SaveButton from "../../components/base/SaveButton";
import { isEmpty } from "../../lib/helper";
import Page404 from "../Page404";
// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient, apiClientGet } from "../../apiClient";

// Constants
import {
  RESOURCE_DRAFT,
  RESOURCE_PUBLISHED,
  RESOURCE_WEB_PAGE,
  RESOURCE_VIDEO,
  PARTNER_PERMISSION_FILTERED_PARTNERS,
  PARTNER_PERMISSION_INDIVIDUAL_PARTNER,
  PARTNER_PERMISSION_ALL_PARTNER
} from "../../resource/Constants";
import { TAB_BASICS, TAB_DETAILS } from "../../playbook/Constants";
import MyContext from "../../context/MyContext";
import { TAG_TYPE_RESOURCE_CATEGORY } from "../../tagType/Constants";
import { isBadRequest } from "../../common/http";

class EditResource extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      bannerColor: "",
      bannerIcon: "",
      files: [],
      fileCount: "",
      bannerImageUrl: "",
      backButton: "Cancel",
      publishPlaybook: "Next",
      status: RESOURCE_DRAFT,
      buttonType: "button",
      currentTab: TAB_BASICS,
      nextTab: "",
      playbookIconValidate: true,
      playbookColorValidate: true,
      skills: [],
      tools: [],
      isExpertLoading: false,
      isOpen: false,
      expertList: [],
      expertIds: [],
      supportingMaterial: "",
      resourceFile: "",
      resourceFileList: "",
      deliverablesValue: "",
      defaultDeliverablesValue: "",
      spotlightImagePathUrl: "",
      checkboxFieldValue: "",
      extrasValue: "",
      isSubmit: false,
      isErrors: false,
      isSubmitting: false,
      resource: {},
      isLoading: false,
      category: [],
      categoryValue: {},
      permissionRequired: false,
      resourceCategory: [],
      isPageNotFound: false,
      checkboxFieldValue: {
        sharingPermission: props.sharingPermission
          ? props.sharingPermission
          : PARTNER_PERMISSION_ALL_PARTNER
      },
      uploadFile: ""
    };
    this.onColourPickerClick = this.onColourPickerClick.bind(this);
    this.onIconClick = this.onIconClick.bind(this);
    this._handleBannerImagePath = this._handleBannerImagePath.bind(this);
    this.bannerImageUpload = this.bannerImageUpload.bind(this);
    this.backButton = this.backButton.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.cancelButton = this.cancelButton.bind(this);
  }

  // Get Tag Values
  getTagValues = async tagType => {
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${tagType}`
      );
      const results = response.data.data;
      // Check the tag type skills
      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });
        this.setState({
          category: tagList
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  getResource = async () => {
    this.setState({ isLoading: true }, () => {
      return apiClientGet(
        `${endpoints().resourceAPI}/${this.props.match.params.id}`,
        (errMessage, response) => {
          if (errMessage) {
            this.setState({
              isPageNotFound: true,
              isLoading: false
            });
          } else {
            const resourceDetails = response && response.data;
            this.setState(prevState => ({
              checkboxFieldValue: {
                ...prevState.checkboxFieldValue,
                sharingPermission: resourceDetails.sharingPermission,
                partnerTier: resourceDetails.sharedTags.partnerTier,
                partnerType: resourceDetails.sharedTags.partnerType,
                showPartnerSpotlight: resourceDetails.show_partner_spotlight,
                existingResourceSource:
                  resourceDetails && resourceDetails.web_page_url !== ""
                    ? RESOURCE_WEB_PAGE
                    : resourceDetails && resourceDetails.video_embed_code !== ""
                    ? RESOURCE_VIDEO
                    : ""
              }
            }));
            this.setState({
              isLoading: false,
              resource: resourceDetails,
              resourceCategory: resourceDetails.resourceCategory,
              bannerImageUrl: resourceDetails.bannerImagePathUrl,
              spotlightImagePathUrl: resourceDetails.spotlightImagePathUrl,
              uploadFile: resourceDetails.resourceFile
            });
          }
        }
      );
    });
  };

  componentDidMount() {
    this.getResource();
    this.getTagValues(TAG_TYPE_RESOURCE_CATEGORY);
  }

  setSubmitState = () => {
    this.setState({ isSubmit: true });
  };

  reSetSubmitState = () => {
    this.setState({ isSubmit: false }, () => {
      this.setState({ isErrors: false });
    });
  };

  setIsErrors = () => {
    this.setState({ isErrors: true });
  };

  // Create playbook
  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }
    const {
      existingResourceSource,
      partnerTier,
      partnerType,
      sharingPermission
    } = this.state.checkboxFieldValue;

    // Check if the permission field is selected in case of selected partner option
    if (
      sharingPermission === PARTNER_PERMISSION_FILTERED_PARTNERS &&
      (partnerTier.length || partnerType.length) === 0
    ) {
      this.setState({
        permissionRequired: true
      });
      return;
    } else {
      this.setState({
        permissionRequired: false
      });
    }

    // Check if the permission field is selected in case of Individual partner option
    if (
      sharingPermission === PARTNER_PERMISSION_INDIVIDUAL_PARTNER &&
      (values.sharedPartner === undefined || values.sharedPartner === null)
    ) {
      this.setState({
        permissionRequired: true
      });
      return;
    } else {
      this.setState({
        permissionRequired: false
      });
    }

    // Save playbook form in the final
    this._updateResource(this._toArray(values)).then(() => {
      let file = [];
      // if file then converting to array to check isEmpty
      if (this.state.resourceFile || this.state.resourceFileName) {
        file.push({
          name: this.state.resourceFile.name,
          path: this.state.resourceFile.path
        });
      }
      // is file existing
      if (
        existingResourceSource === (RESOURCE_WEB_PAGE || RESOURCE_VIDEO) &&
        isEmpty(
          this.state.uploadFile ? this.state.uploadFile : file ? file : ""
        )
      ) {
        this._handleDeleteResourceFile();
      }
    });
  };

  // Get deliverable values
  getDeliverablesValue = values => {
    this.setState({ deliverablesValue: values });
  };

  // Get default deliverable values
  getDefaultDeliverablesValue = values => {
    this.setState({ defaultDeliverablesValue: values });
  };

  // Get extras values
  getExtrasValue = values => {
    this.setState({ extrasValue: values });
  };

  // Color picker
  onColourPickerClick(e) {
    this.setState({ bannerColor: e.target.id, playbookColorValidate: true });
  }

  // Icon click
  onIconClick(e) {
    this.setState({
      bannerIcon: e.target.className,
      playbookIconValidate: true
    });
  }

  // Back Button
  backButton(e) {
    let tab = e;
    this.setState({ currentTab: tab });
    if (tab === TAB_BASICS) {
      this.setState({ backButton: "Cancel" });
    } else {
      this.setState({ backButton: "Back" });
    }
    if (tab === TAB_DETAILS) {
      this.setState({ publishPlaybook: "Publish Resource" });
    } else {
      this.setState({ publishPlaybook: "Next" });
    }
  }

  // Cancel Button
  cancelButton() {
    if (this.state.backButton === "Cancel") {
      this.props.history.goBack();
    } else {
      const currentTab = this.state.currentTab;
      this.state.isErrors && this.setSubmitState();

      switch (currentTab) {
        case TAB_DETAILS:
          this.backButton(TAB_BASICS);
          break;
      }
    }
  }

  // Set next playbook tab
  setNextTab = tab => {
    if (this.state.currentTab !== TAB_DETAILS) {
      return this.setState({
        currentTab: tab,
        buttonType: "button"
      });
    }

    return this.setState({
      currentTab: tab,
      status: RESOURCE_PUBLISHED,
      buttonType: "submit",
      publishPlaybook: "Publish Resource"
    });
  };

  // Next Button
  nextButton() {
    const currentTab = this.state.currentTab;
    if (this.state.isErrors) {
      return this.setSubmitState();
    }

    switch (currentTab) {
      case TAB_BASICS:
        this.setNextTab(TAB_DETAILS);
        this.backButton(TAB_DETAILS);
        break;
      case TAB_DETAILS:
        this.setNextTab(TAB_DETAILS);
        break;
    }
  }

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Banner Image Upload
  bannerImageUpload = file => {
    this.setState({ files: file });
  };

  // Banner Image Remove
  handleImageRemove = () => {
    this.setState(
      { bannerImageUrl: "", files: "" },
      this._handleDeleteBannerImage
    );
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const title = values.title;

    if (!title) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    const data = new FormData();
    const { existingResourceSource } = this.state.checkboxFieldValue;
    data.append(
      "webPageUrl",
      existingResourceSource && values.webPageUrl ? values.webPageUrl : ""
    );
    data.append(
      "videoEmbedCode",
      existingResourceSource && values.videoEmbedCode
        ? values.videoEmbedCode
        : ""
    );
    data.append("status", this.state.status);
    data.append("title", values.title ? values.title : "");
    data.append("description", values.description ? values.description : "");
    let categoryData = [];
    values &&
      values.category &&
      values.category.forEach(data => {
        if (data) {
          categoryData.push(data.value);
        }
      });
    categoryData = JSON.stringify(categoryData);
    data.append("category", categoryData ? categoryData : "");
    data.append(
      "bannerImagePath",
      this.state.bannerImageUrl ? this.state.bannerImageUrl : ""
    );
    data.append(
      "bannerColor",
      this.state.bannerColor ? this.state.bannerColor : values.bannerColor
    );
    data.append(
      "bannerIcon",
      this.state.bannerIcon ? this.state.bannerIcon : values.bannerIcon
    );
    data.append("resourceFile", this.state.resourceFile);
    data.append("resourceFileName", this.state.resourceFileName);
    data.append("file", this.state.files);
    data.append(
      "spotlightImage",
      this.state.spotlightImagePathUrlPreview
        ? this.state.spotlightImagePathUrlPreview
        : ""
    );
    data.append(
      "showPartnerSpotlight",
      this.state.checkboxFieldValue &&
        this.state.checkboxFieldValue.showPartnerSpotlight
        ? this.state.checkboxFieldValue.showPartnerSpotlight
        : false
    );
    data.append(
      "sharedTags",
      this.state.checkboxFieldValue
        ? [...this.state.checkboxFieldValue.partnerType]
        : ""
    );
    data.append(
      "partnerTiers",
      this.state.checkboxFieldValue
        ? [...this.state.checkboxFieldValue.partnerTier]
        : ""
    );
    data.append(
      "sharingPermission",
      this.state.checkboxFieldValue &&
        this.state.checkboxFieldValue.sharingPermission
        ? this.state.checkboxFieldValue.sharingPermission
        : this.state.resource.sharingPermission
    );
    data.append(
      "sharedPartnerId",
      values.sharedPartner ? values.sharedPartner.id : ""
    );
    if (values.sendNotification !== undefined) {
      data.append("sendNotification", values.sendNotification);
    }
    return data;
  }

  // Resourceupdate API Call
  async _updateResource(data) {
    this.setState({ isSubmitting: true });
    try {
      const response = await apiClient.put(
        `${endpoints().resourceAPI}/${this.props.match.params.id}`,
        data
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }

      toast.success(successMessage);
      this.getTagValues(TAG_TYPE_RESOURCE_CATEGORY);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        this.setState({ isSubmitting: false });
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  }

  // Handle OnChange playbook banner image path
  _handleBannerImagePath = e => {
    const target = e.target;
    const bannerImagePath = target.value;
    this.setState({ bannerImagePath });
  };

  // Handle resource file
  handleResourceFile = files => {
    this.setState({ resourceFileName: files[0].name, resourceFile: files[0] });
  };

  // Resourcedelete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().resourceAPI}/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        window.location.replace("/resources");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Delete resource file
  _handleDeleteResourceFile = async () => {
    try {
      const response = await apiClient.delete(
        `${endpoints().resourceAPI}/file/${this.props.match.params.id}`
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        this.getResource();
      }
      toast.success(successMessage);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.stringify(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  // Delete banner image
  _handleDeleteBannerImage = () => {
    return apiClient
      .delete(`${endpoints().resourceAPI}/banner/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Delete spotlight image
  _handleDeleteSpotLightImage = () => {
    return apiClient
      .delete(
        `${endpoints().resourceAPI}/spotlight/${this.props.match.params.id}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Set image preview in state
  setPreviewImage = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [`${state}Preview`]: reader.result });
    };
  };

  // Update image uploads
  handleSpotlightImageChange = e => {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files ? e.target.files[0] : "";
      this.setPreviewImage(file, e.target.name);
      this.setState({ [`${e.target.name}`]: file });
    }
  };

  // Remove image
  handleSpotlightImageRemove = e => {
    this.setState(
      {
        [`${e.target.id}`]: "",
        [`${e.target.id}Preview`]: ""
      },
      this._handleDeleteSpotLightImage
    );
  };

  headerButton = () => {
    return (
      <div>
        <CancelButton onClick={() => this.props.history.goBack()} />
        <button
          className="btn btn-outline-dark pr-2 mr-2"
          type="submit"
          onClick={() => {
            this.setState({
              status: RESOURCE_DRAFT
            });
            this.state.isErrors && this.setSubmitState();
          }}
        >
          Save as Draft
        </button>
        <button
          type="submit"
          className="btn btn-secondary pr-2"
          onClick={() =>
            this.setState({ status: RESOURCE_PUBLISHED }, this.setSubmitState)
          }
        >
          Publish Resource
        </button>
      </div>
    );
  };

  //Get Category Tag Value
  getTagInitialValues = (resourceCategory, category) => {
    const selectedTags = [];
    const tagInitialValues = {};
    if (resourceCategory && resourceCategory.length > 0) {
      resourceCategory.forEach(tagType => {
        if (category && category.length > 0)
          category.forEach(tag => {
            if (tagType.tag_id === tag.id) selectedTags.push(tag);
          });
      });
      tagInitialValues["category"] = selectedTags;
    }
    return tagInitialValues;
  };

  render() {
    const {
      category,
      resource,
      isLoading,
      bannerImageUrl,
      isPageNotFound
    } = this.state;

    const { settings } = this.props;

    if (isLoading) {
      return <Spinner />;
    }

    if (isPageNotFound) return <Page404 />;

    const {
      playbookIconValidate,
      playbookColorValidate,
      currentTab,
      resourceFile,
      isSubmit,
      isErrors,
      resourceFileList,
      spotlightImagePathUrlPreview,
      spotlightImagePathUrl,
      checkboxFieldValue,
      permissionRequired,
      resourceCategory
    } = this.state;

    const tagTypeInitialValues =
      this.getTagInitialValues(resourceCategory, category) || {};

    const categoryValue = tagTypeInitialValues;

    const initialValues = {
      ...tagTypeInitialValues,
      // category: categoryValue,
      title: resource.title,
      description: resource.description,
      bannerImageUrl: "",
      fileCount: "",
      bannerColor: resource.banner_color,
      bannerIcon: resource.banner_icon,
      sharedPartner: resource.sharedPartner
        ? {
            label:
              (resource.sharedPartner && resource.sharedPartner.company_name) ||
              "",
            value: (resource.sharedPartner && resource.sharedPartner.id) || "",
            id: (resource.sharedPartner && resource.sharedPartner.id) || ""
          }
        : null,
      videoEmbedCode: resource.video_embed_code || "",
      webPageUrl: resource.web_page_url || "",
      sendNotification: resource.sendNotification === 1 ? true : false
    };

    return (
      <>
        <MyContext.Consumer>
          {context => (
            <>
              {context.isMobile && (
                <div className="d-flex">
                  <PageTitle label="Edit Resource" />
                  {this.headerButton()}
                </div>
              )}
              <Form
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={values => {
                  this._submit(values);
                }}
                showErrorToast={true}
                isSubmit={isSubmit}
                reSetSubmitState={this.reSetSubmitState}
                setIsErrorState={this.setIsErrors}
                isErrorsState={isErrors}
              >
                <div
                  className={`page-heading d-flex ${
                    context.isMobile
                      ? "justify-content-end"
                      : "justify-content-between"
                  }`}
                >
                  {!context.isMobile && (
                    <>
                      <PageTitle label="Edit Resource" />
                      {this.headerButton()}
                    </>
                  )}
                </div>
                <ResourceFormFields
                  colorValidate={playbookColorValidate}
                  settings={this.props.settings}
                  iconValidate={playbookIconValidate}
                  onColourPickerClick={this.onColourPickerClick}
                  onIconClick={this.onIconClick}
                  bannerColor={
                    this.state.bannerColor || initialValues.bannerColor
                  }
                  bannerIcon={this.state.bannerIcon || initialValues.bannerIcon}
                  handleBannerImagePath={this._handleBannerImagePath}
                  bannerImageUpload={e => this.bannerImageUpload(e)}
                  handleImageRemove={this.handleImageRemove}
                  backButton={e => this.backButton(e)}
                  currentTab={currentTab}
                  category={category}
                  resourceCategory={resourceCategory}
                  handleCategoryValue={this.handleCategoryValue}
                  handleResourceFile={this.handleResourceFile}
                  resourceFile={resourceFileList || resource.resourceFile}
                  handleDeleteSupportingMaterial={
                    this._handleDeleteResourceFile
                  }
                  data={resource}
                  bannerImageUrl={bannerImageUrl}
                  getCheckboxFieldValue={values =>
                    this.setState({ checkboxFieldValue: values })
                  }
                  handleSpotlightImageChange={this.handleSpotlightImageChange}
                  handleSpotlightImageRemove={this.handleSpotlightImageRemove}
                  spotlightImagePathUrlPreview={spotlightImagePathUrlPreview}
                  spotlightImagePathUrl={spotlightImagePathUrl}
                  showPartnerSpotlight={resource.show_partner_spotlight}
                  checkboxFieldValue={checkboxFieldValue}
                  sharingPermission={resource.sharingPermission}
                  webPageUrl={initialValues.webPageUrl}
                  videoEmbedCode={initialValues.videoEmbedCode}
                  permissionRequired={permissionRequired}
                  updatePermission={value =>
                    this.setState({ permissionRequired: value })
                  }
                  files={this.state.files}
                />
              </Form>
            </>
          )}
        </MyContext.Consumer>
      </>
    );
  }
}

export default EditResource;
