import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// Constants
import {
  REFERRAL_LINKS_SUB_TAB,
  REFERRAL_PARTNER_TIERS_SUB_TAB,
  REFERRAL_STATUS_SUB_TAB,
  REFERRAL_QUALIFICATION_QUESTIONS_SUB_TAB,
  TAB_REFERRALS,
  SALESFORCE_SALES_ROUTING_SUB_TAB,
  SETTINGS_ENABLE_SALES_ROUTING,
  HUBSPOT_SALES_ROUTING_SUB_TAB,
  REFERRAL_SETTING_CONFIG
} from "../../../../setting/Constants";

// Sub Tab  Sections
import StatusSection from "./StatusSection";
import QualificationQuestionSection from "../../../../../src/referral/settings/QualificationQuestionSection";
import NewStatusButton from "./NewStatusButton";
import LinksSection from "../../../../referralSetting/LinksSection";
import SalesRoutingSetting from "../../../../salesRouting/salesforceSalesRouting/component/EnableSalesRouting";
import SalesRouting from "../../../../salesRouting/salesforceSalesRouting/component/SalesRouting";

import HubSpotSalesRoutingSetting from "../../../../salesRouting/hubspotSalesRouting/component/EnableSalesRouting";
import HubSPotSalesRouting from "../../../../salesRouting/hubspotSalesRouting/component/SalesRouting";
import Setting from "../../../../../src/referral/settings/Setting";

const ReferralsTab = props => {
  const {
    activeSubTab,
    adminSettings,
    saveSettings,
    toggleTabs,
    history
  } = props;
  const { enable_sales_routing, enable_hubspot_sales_routing } = adminSettings;
  // initilize states
  // const [openPartnerTier, setOpenPartnerTier] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");
  const [group, setGroup] = useState("");
  const [status, setStatus] = useState("");
  const [salesforce_status, setSalesforceStatus] = useState("");
  const [showSalesRouting, setSalesRouting] = useState(enable_sales_routing);
  const [showHubspotSalesRouting, setHubspotSalesRouting] = useState(
    enable_hubspot_sales_routing
  );

  //Referral status toggle modal
  const _referralStatusToggle = data => {
    setId((data && data.id) || "");
    setGroup((data && data.group) || "");
    setStatus((data && data.status) || "");
    setSalesforceStatus((data && data.salesforce_status) || "");
    setIsOpen(!isOpen);
  };

  // Partner tier toggle modal
  // const _partnerTierToggle = data => {
  //   setTierId((data && data.id) || "");
  //   setTierName((data && data.name) || "");
  //   setTierCommission((data && data.percentage) || "");
  //   setAmount((data && data.quota_amount) || "");
  //   setType((data && data.quota_type) || "");

  //   setOpenPartnerTier(!openPartnerTier);
  // };
  return (
    <>
      <span
        style={{
          float: "right",
          position: "absolute",
          right: "15px",
          top: "60px"
        }}
      >
        {activeSubTab === REFERRAL_STATUS_SUB_TAB && (
          <NewStatusButton
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
            setId={setId}
            setGroup={setGroup}
            setStatus={setStatus}
            setSalesforceStatus={setSalesforceStatus}
            id={id}
            group={group}
            status={status}
            salesforce_status={salesforce_status}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
          />
        )}
      </span>
      <Nav tabs className="admin-tabs">
        {/* Links Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === REFERRAL_LINKS_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_REFERRALS, REFERRAL_LINKS_SUB_TAB);
            }}
          >
            {REFERRAL_LINKS_SUB_TAB}
          </NavLink>
        </NavItem>

        {/* Status Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === REFERRAL_STATUS_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_REFERRALS, REFERRAL_STATUS_SUB_TAB);
            }}
          >
            {REFERRAL_STATUS_SUB_TAB}
          </NavLink>
        </NavItem>

        {/* Sales Routing Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === SALESFORCE_SALES_ROUTING_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_REFERRALS, SALESFORCE_SALES_ROUTING_SUB_TAB);
            }}
          >
            {SALESFORCE_SALES_ROUTING_SUB_TAB}
          </NavLink>
        </NavItem>

        {/* Hubspot Sales Routing Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === HUBSPOT_SALES_ROUTING_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_REFERRALS, HUBSPOT_SALES_ROUTING_SUB_TAB);
            }}
          >
            {HUBSPOT_SALES_ROUTING_SUB_TAB}
          </NavLink>
        </NavItem>

        {/* Qualification Questions Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === REFERRAL_QUALIFICATION_QUESTIONS_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(
                TAB_REFERRALS,
                REFERRAL_QUALIFICATION_QUESTIONS_SUB_TAB
              );
            }}
          >
            {REFERRAL_QUALIFICATION_QUESTIONS_SUB_TAB}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === REFERRAL_SETTING_CONFIG
            })}
            onClick={() => {
              toggleTabs(TAB_REFERRALS, REFERRAL_SETTING_CONFIG);
            }}
          >
            {REFERRAL_SETTING_CONFIG}
          </NavLink>
        </NavItem>
      </Nav>

      <TabContent activeTab={activeSubTab}>
        {/* Links Tab */}
        <TabPane tabId={REFERRAL_LINKS_SUB_TAB} className="w-100">
          <LinksSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        {/* Referral status Tab */}
        <TabPane tabId={REFERRAL_STATUS_SUB_TAB} className="w-100">
          <StatusSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
            setIsOpen={setIsOpen}
            isOpen={isOpen}
            referralStatusToggle={_referralStatusToggle}
          />
        </TabPane>

        {/* Sales Routing Tab */}
        <TabPane tabId={SALESFORCE_SALES_ROUTING_SUB_TAB} className="w-100">
          <SalesRoutingSetting
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
            setSalesRouting={setSalesRouting}
          />
          {showSalesRouting == "true" && (
            <SalesRouting
              adminSettings={adminSettings}
              saveSettings={saveSettings}
              history={history}
            />
          )}
        </TabPane>

        {/* Sales Routing Tab */}
        <TabPane tabId={HUBSPOT_SALES_ROUTING_SUB_TAB} className="w-100">
          <HubSpotSalesRoutingSetting
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
            setHubspotSalesRouting={setHubspotSalesRouting}
          />
          {showHubspotSalesRouting == "true" && (
            <HubSPotSalesRouting
              adminSettings={adminSettings}
              saveSettings={saveSettings}
              history={history}
            />
          )}
        </TabPane>

        {/* Qualification Questions Tab */}
        <TabPane
          tabId={REFERRAL_QUALIFICATION_QUESTIONS_SUB_TAB}
          className="w-100"
        >
          <QualificationQuestionSection
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
        <TabPane tabId={REFERRAL_SETTING_CONFIG} className="w-100">
          <Setting
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default ReferralsTab;
