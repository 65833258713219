import React from "react";
import Text from "../../../components/base/Text";
import Select from "../../../components/base/Select";
import Button from "../../../components/base/Button";
import CoSell from "../../../Constants/Cosell";
import Form from "../../../components/base/Form";
import TextArea from "../../../components/base/TextArea";

const AdditionalDetailSection = props => {
  let { detail, showButton, readOnly } = props;
  return (
    <div>
      <Form
        enableReinitialize={true}
        initialValues={{
          partner_crm_unique_identifier:
            (detail && detail.partner_crm_unique_identifier) || "",
          competitive_tracking:
            (detail &&
              props.competitiveTrackingOption.find(
                value => value.value == detail.competitive_tracking
              )) ||
            "",
          aws_account_id: (detail && detail.aws_account_id) || "",
          additional_comments: (detail && detail.additional_comments) || ""
        }}
        onSubmit={values => {
          props.handleUpdate(values);
        }}
      >
        <div className="card border-0">
          <div
            style={{
              backgroundColor: "#fafafa",
              borderBottom: "1px solid",
              borderColor: "#eaeded"
            }}
          >
            {!props.enableSaveButton && (
              <div className="mt-4 mx-4">
                <label className="font-weight-bold  form-group">
                  Customer Detail
                </label>
              </div>
            )}
          </div>
          <div className="card-body">
            <div className="col-lg-12">
              <div className="field-wrapper mt-3">
                <Text
                  name="partner_crm_unique_identifier"
                  label="Partner CRM Unique Identifier"
                  placeholder="Enter Partner CRM Unique Identifier"
                  error=""
                  disabled={readOnly}
                />
              </div>
              <div className="field-wrapper mt-3">
                <Select
                  name="competitive_tracking"
                  label="Competitive Tracking"
                  placeholder="Enter Competitive Tracking"
                  error=""
                  options={props.competitiveTrackingOption}
                  isDisabled={readOnly}
                />
              </div>
              <div className="field-wrapper">
                <Text
                  name="aws_account_id"
                  label="AWS Account ID "
                  placeholder="Enter AWS Account ID "
                  error=""
                  disabled={readOnly}
                />
              </div>

              <div className="field-wrapper w-100">
                <TextArea
                  name="additional_comments"
                  label="Additional Comments"
                  placeholder="Additional Comments..."
                  rows="5"
                  disabled={readOnly}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="d-flex justify-content-end">
          {props.handleChange && (
            <Button
              label="Back"
              type="button"
              onClick={() => {
                props.setActiveTab(1);
                props.handleChange(1);
              }}
              className=" mt-3 mb-3"
            />
          )}
          {!showButton && (
            <Button
              label={props.enableSaveButton ? "Save" : "Next"}
              type="submit"
              className=" mt-3 mb-3"
            />
          )}
        </div>
      </Form>
    </div>
  );
};

export default AdditionalDetailSection;
