import React, { Component } from "react";

// utils
import { concatName } from "../../../../../src/common/utils";

// Components
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import TextArea from "../../../../components/base/TextArea";
import PageTitle from "../../../../components/base/PageTitle";
import Label from "../../../../components/base/Label";
import DefaultContent from "../../../../components/content/DefaultContent";
import Form from "../../../../components/base/Form";

// helper
import { endpoints } from "../../../../configs";

class Signup extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values.
    this.state = {
      id: "",
      name: "",
      isLoading: false,
      isOpen: false,
      partnerType: "",
      inboundLinkCopySuccess: "Copy",
      signLink: "",
      params: ""
    };
  }
  componentDidMount() {
    this.updateUrl();
  }
  updateUrl = () => {
    const params = [];
    const base_url = window.location.origin;
    const signupLink = `${base_url}/partner-signup`;
    this.setState({
      params: params,
      signLink: signupLink
    });
    let partnertype = this.state.partnerType;
    let urlPartnerType = partnertype.replace(" ", "-");
    const value = urlPartnerType.toLowerCase();
    if (signupLink) {
      params.push(`${signupLink}`);
    }
    if (value) {
      params.push(`partnertype=${value}`);
    }
    if (params && params.length > 0) {
      return params.join("&");
    }
  };

  render() {
    const { inboundLinkCopySuccess, signLink, params } = this.state;

    const copyInboundReferralLinkRef = e => {
      navigator.clipboard.writeText(signLink);
      this.setState({
        inboundLinkCopySuccess: "Copied!",
        partnerType: e
      });
    };
    const copyPartnerLinkRef = (url, name) => {
      let link = concatName(url, name);
      navigator.clipboard.writeText(link);
      this.setState({
        [name]: "Copied!"
      });
    };
    return (
      <>
        <div className="mt-4">
          <PageTitle label="Default Link for Partner Signup" />
        </div>
        <span className={["d-block mb-4"].join("")}>
          Use this link as your default partner signup link. You can hook this
          up to your website or send it out to potential partners
        </span>
        <DefaultContent>
          {/* Default Partner Signup */}
          <>
            <Label className="font-weight-bold">
              {"Link for Default Partner Signup"}
            </Label>
            {/* <LinksSection /> */}
            <Form>
              <div className="d-flex align-items-center mb-1">
                <TextArea
                  // id={id}
                  name="defaultPartnerSignupLink"
                  defaultValue={signLink}
                  placeholder={signLink}
                  className="w-100 mb-2"
                  disabled
                />
                <div>
                  <button
                    className={[
                      "btn",
                      `${
                        inboundLinkCopySuccess !== "Copied!"
                          ? "btn-primary"
                          : "btn-success"
                      }`,
                      "ml-2"
                    ].join(" ")}
                    type="button"
                    onClick={copyInboundReferralLinkRef}
                  >
                    <span>{this.state.inboundLinkCopySuccess}</span>
                  </button>
                </div>
              </div>
            </Form>
          </>
        </DefaultContent>
        <div className="mt-4">
          <PageTitle label="Signup Links for Partner Types" />
        </div>
        <span className={["d-block"].join("")}>
          These are the signup links that you can use for specific types of
          partners. This allows you to tailor the initial user experience for
          different partner types.
        </span>
        <div className="task-list px-2">
          <div>
            <ReduxTable
              id="partnerTypes"
              apiURL={`${endpoints().partnerTypeAPI}/search`}
              params={{ list: true }}
              showHeader
              searchDisabled
            >
              <ReduxColumn minWidth="50px" disableOnClick field="name">
                Partner Type
              </ReduxColumn>
              <ReduxColumn
                minWidth="450px"
                type="link"
                disableOnClick
                field="link"
                renderField={row => {
                  return (
                    <>
                      <div className="align-items-center mb-1">
                        <Form>
                          <div className="row">
                            <div className="col-9">
                              <TextArea
                                name="defaultPartnerSignupLink"
                                defaultValue={concatName(signLink, row.name)}
                                placeholder={params}
                                className="w-100 mb-2"
                                disabled
                              />
                            </div>
                            <div className="col-3 p-0">
                              <button
                                name={row.name}
                                className={[
                                  "btn",
                                  `${
                                    this.state[row.name] !== "Copied!"
                                      ? "btn-primary"
                                      : "btn-success"
                                  }`,
                                  "ml-2"
                                ].join(" ")}
                                type="button"
                                onClick={() => {
                                  copyPartnerLinkRef(signLink, row.name);
                                }}
                              >
                                <span>
                                  {this.state[row.name] ? "Copied" : "Copy"}
                                </span>
                              </button>
                            </div>
                          </div>
                        </Form>
                      </div>
                    </>
                  );
                }}
              >
                Link
              </ReduxColumn>
            </ReduxTable>
          </div>
        </div>
      </>
    );
  }
}
export default Signup;
