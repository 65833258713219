import React from "react";
import { Media } from "reactstrap";
import SVG from "react-inlinesvg";

// Components
import PlaybookCard from "../../components/PlaybookCard";

//Assets
import starIcon from "../../assets/img/icons/icon-star.svg";
import galaxyIcon from "../../assets/img/icons/icon-galaxy.svg";

const OrderItem = props => {
  const { title, author, ratings } = props.itemData;
  const showStars = starcount => {
    let stars = [];

    for (let i = 1; i < 6; i++) {
      if (i <= starcount) {
        stars.push(<SVG key={i} src={starIcon} className="filled" />);
      } else {
        stars.push(<SVG key={i} src={starIcon} />);
      }
    }
    return stars;
  };
  const {
    description,
    deliverables,
    defaultDeliverables
  } = props.playbookDetails;

  return (
    <div className="order-item cart-item">
      <Media className="align-items-stretch">
        <Media left tag={"div"}>
          <PlaybookCard
            data={props.playbookDetails}
            showCardBody={false}
            bannerImageUrl={props.playbookDetails.bannerImagePath}
            size={"w-100 mb-0"}
            tag={"div"}
          />
        </Media>
        <Media body className="justify-content-between d-flex flex-column ml-3">
          <Media heading className="section-title">
            <h4>{title}</h4>
            <span className="author mt-0 h7">{author}</span>

            {/* /.rating-and-usage */}
            <div className="d-none">
              <div className="rating-and-usage d-flex">
                <div className="rating-wrapper d-flex">
                  {showStars(ratings.stars)}
                  <span className="h7">{ratings.count} Ratings</span>
                </div>
                <div className="usage-wrapper d-flex">
                  <SVG src={galaxyIcon} />
                  <span className="h7">Used {ratings.used} Times</span>
                </div>
                {/* /.usage-wrapper */}
              </div>
            </div>
            {/* /.rating-and-usage */}
          </Media>
        </Media>
      </Media>

      <p>{description}</p>
      {defaultDeliverables && defaultDeliverables.length > 0 ? (
        <>
          <p>
            <b>Campaign Deliverables:</b>
          </p>
          {defaultDeliverables.map((deliverable, index) => (
            <li key={index}>{deliverable.description}</li>
          ))}
        </>
      ) : (
        ""
      )}
    </div>
  );
};

export default OrderItem;
