import React from "react";
import { Redirect } from "react-router-dom";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Configs
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchList } from "../../actions/table";

// Helper
import {
  getParamsByName,
  getCookie,
  isLoggedIn,
  getKeyValueByObject,
  isSuperAdmin,
  isCompanyAdmin,
  isCustomer,
  isPartner,
  isCompanyManager,
  getUserId
} from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import NLFormWrapper from "../../components/nl-form/NLFormWrapper";
import CountBadge from "../../components/base/CountBadge";
import PageSearch from "../../components/base/PageSearch";
import SelectDropdown from "../../components/base/SelectDropdown";
import ExpertGrid from "../../components/ExpertGrid";
import PrimaryDropdown from "../../components/base/PrimaryDropdown";
import CSVDownload from "../../components/CSVDownload";

import PendingExperts from "./PendingExperts";
import PendingPartners from "./PendingPartners";
import AppList from "../app/AppList";

// Inner Components
import Spinner from "../../components/base/Spinner";
import ApprovedPartners from "./ApprovedPartners";

import Playbook from "../playbook";

// Assets
import { CrossIcon, OctagonAlertIcon } from "../../assets/img/icons";

// Constants
import {
  TAG_TYPE_CERTIFICATION,
  TAG_TYPE_EXPERT_ROLE,
  TAG_TYPE_INDUSTRIES,
  TAG_TYPE_SKILLS,
  TAG_TYPE_TOOLS
} from "../../tagType/Constants";

import {
  PLAYBOOK_STATUS_DRAFT,
  PLAYBOOK_STATUS_ACTIVE
} from "../../playbookStatus/Constants";
import {
  AVAILABLE_EXPERT,
  APPROVED_EXPERT,
  getExpertAvailabilityOption
} from "../../expertAvailability/Constants";

import {
  ONE_PROJECT_EXPERIENCE,
  MORE_THAN_ONE_PROJECT_EXPERIENCE,
  NO_EXPERIENCE,
  ONE_PROJECT_EXPERIENCE_IDENTIFIER,
  MORE_THAN_ONE_PROJECT_EXPERIENCE_IDENTIFIER,
  NO_EXPERIENCE_IDENTIFIER,
  getExpertLevelOfExperienceOption
} from "../../expertLevelOfExperience/Constants";

import {
  SETTINGS_ENABLE_EXPERTS,
  SETTINGS_ENABLE_PARTNERS,
  SETTINGS_ALLOW_PARTNER_TO_CREATE_PLAYBOOK,
  SETTINGS_ENABLE_PLAYBOOK,
  SETTINGS_ENABLE_APP
} from "../../setting/Constants";

import { getExpertHourlyOption } from "../../expertHours/Constants";
import Page404 from "../Page404";

import { COOKIE_SHOW_ASSIGNED_PARTNERS } from "../../lib/cookie";
import AddButton from "../../components/base/AddButton";
import { isBadRequest } from "../../common/http";
import { isTrue } from "../../common/utils";
//Inner Component
import AddModal from "../../views/app/AddModal";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
import toast from "../../components/base/Toast";

// constants
const TAB_APPROVE_SKILL = "approve-skills";
const TAB_PLAYBOOKS = "playbooks";
const TAB_PARTNERS = "partners";
const TAB_EXPERTS = "experts";
const TAB_APPLICANTS = "Applicants";
const TAB_APPS = "Apps";

const PENDING_EXPERTS = "Pending Experts";
const PENDING_PARTNERS = "Pending Partners";

class MarketplaceSourcing extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowId: "",
      loading: false,
      isExpertLoading: true,
      expertList: [],
      isPartnerLoading: true,
      partnerList: [],
      status: "",
      searchCount: 0,
      isLoading: false,
      searchParam: "",
      appList: "",
      playbookTotalCount: "",
      playBooks: [],
      activeTab: TAB_APPS,
      isOpen: false,
      isclose: true,
      sortByOptions: [
        {
          value: "a-z",
          label: "Alphabetical A-Z"
        },
        {
          value: "rating",
          label: "Rating"
        }
      ],
      selectedSortOption: "Alphabetical A-Z",
      selectedFilters: {},
      skills: [
        {
          multiselect: 1,
          label: "skill",
          value: "",
          placeholder: true
        }
      ],
      tools: [
        {
          multiselect: 1,
          label: "tool",
          value: "",
          placeholder: true
        }
      ],
      industries: [
        {
          multiselect: 1,
          label: "industry",
          value: "",
          placeholder: true
        }
      ],
      certificates: [
        {
          multiselect: 1,
          label: "certification",
          value: "",
          placeholder: true
        }
      ],
      expertTypes: [
        {
          label: "Expert type",
          value: "Expert type",
          selected: false,
          placeholder: true
        }
      ],
      applicantLabel: PENDING_EXPERTS,
      applicantParams: "pending_experts_table",
      partners: [],
      searchTerms: "",
      appActivityStats: "",
      appCount: "",
      params: {
        pageSize: getParamsByName("pageSize"),
        page: getParamsByName("page"),
        sort: getParamsByName("sort"),
        sortDir: getParamsByName("sortDir"),
        search: getParamsByName("search")
      }
    };
    this.appMarketplaceCreate = this.appMarketplaceCreate.bind(this);
    this.getSelectedFilters = this.getSelectedFilters.bind(this);
    this.renderExpertList = this.renderExpertList.bind(this);
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this.getParamsStatus();
    this.setActiveTab();
    this._getExpertsList();
    this._getPartnerList();
    this._getPlaybookList();
    this.getAppStatics();
    this.getAppList(this.state.params);

    const settings = this.props.settings ? this.props.settings : "";
    const enablePartners = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS)
      : "";
    const enableExperts = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS)
      : "";
    const queryParam = getParamsByName("pending");
    let applicantParams =
      enablePartners &&
      enablePartners === "true" &&
      queryParam === PENDING_PARTNERS
        ? "pending_partners_table"
        : enableExperts &&
          enableExperts === "true" &&
          queryParam === PENDING_EXPERTS
        ? "pending_experts_table"
        : enableExperts &&
          enableExperts === "true" &&
          queryParam === PENDING_PARTNERS
        ? "pending_experts_table"
        : enablePartners && enablePartners === "true"
        ? "pending_partners_table"
        : "";

    const value =
      enablePartners &&
      enablePartners === "true" &&
      queryParam === PENDING_PARTNERS
        ? PENDING_PARTNERS
        : enableExperts &&
          enableExperts === "true" &&
          queryParam === PENDING_EXPERTS
        ? PENDING_EXPERTS
        : enableExperts && enableExperts === "true"
        ? PENDING_EXPERTS
        : enablePartners && enablePartners === "true"
        ? PENDING_PARTNERS
        : "";
    this.setState({
      applicantLabel: value,
      applicantParams
    });
  }

  // Get search query string value
  getParamsStatus = () => {
    const settings = this.props.settings ? this.props.settings : "";

    const enablePlaybook = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PLAYBOOK)
      : "";
    const enableApp = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_APP)
      : "";

    const enablePartners = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS)
      : "";

    const enableExperts = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS)
      : "";

    const status = getParamsByName("section");
    this.setState({
      status: status
        ? status
        : (enableApp && enableApp === "true" && TAB_APPS) ||
          (enablePlaybook && enablePlaybook === "true" && TAB_PLAYBOOKS) ||
          (enableExperts && enableExperts === "true" && TAB_EXPERTS) ||
          (enablePartners && enablePartners === "true" && TAB_PARTNERS) ||
          TAB_APPLICANTS
    });
  };

  // Load Expert Tab API Call
  loadExpertsTabAPICall(tab) {
    if (tab === TAB_EXPERTS) {
      this._getTagsByTagType(TAG_TYPE_EXPERT_ROLE);
      this._getTagsByTagType(TAG_TYPE_SKILLS);
      this._getTagsByTagType(TAG_TYPE_TOOLS);
      this._getTagsByTagType(TAG_TYPE_INDUSTRIES);
      this._getTagsByTagType(TAG_TYPE_CERTIFICATION);
    }
  }

  // Set Active Tab
  setActiveTab = () => {
    const status = getParamsByName("section");

    const settings = this.props.settings ? this.props.settings : "";

    const enablePlaybook = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PLAYBOOK)
      : "";
    const enableApp = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_APP)
      : "";

    const enablePartners = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS)
      : "";

    const enableExperts = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS)
      : "";

    this.loadExpertsTabAPICall(status);

    this.setState({
      activeTab:
        status === TAB_APPS
          ? TAB_APPS
          : status === TAB_EXPERTS
          ? TAB_EXPERTS
          : status === TAB_PARTNERS
          ? TAB_PARTNERS
          : status === TAB_APPLICANTS
          ? TAB_APPLICANTS
          : status === TAB_PLAYBOOKS
          ? TAB_PLAYBOOKS
          : (enableApp && enableApp === "true" && TAB_APPS) ||
            (enablePlaybook && enablePlaybook === "true" && TAB_PLAYBOOKS) ||
            (enableExperts && enableExperts === "true" && TAB_EXPERTS) ||
            (enablePartners && enablePartners === "true" && TAB_PARTNERS) ||
            TAB_APPLICANTS
    });
  };

  //get Expert List
  _getExpertsList() {
    this.setState({ isExpertLoading: true }, () => {
      apiClient
        .get(`${endpoints().expertAPI}/search?marketplaceStatus=Approved`)
        .then(response => {
          const expertList = response.data.data;
          const data = response.data;
          this.setState({
            isLoading: false,
            expertList: !expertList ? [] : expertList,
            totalCount: data.totalCount,
            isExpertLoading: false
          });
        });
    });
  }

  //get partner List
  _getPartnerList() {
    let queryParam;
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);

    if (checked_status) {
      queryParam = `?marketplaceStatus=Approved&managerUserId=${getUserId()}`;
    } else {
      queryParam = `?marketplaceStatus=Approved`;
    }

    this.setState({ isPartnerLoading: true }, () => {
      apiClient
        .get(`${endpoints().partnerAPI}/search${queryParam}`)
        .then(response => {
          const partnerList = response.data.data;
          const data = response.data;
          this.setState({
            isLoading: false,
            partnerList: !partnerList ? [] : partnerList,
            partnerTotalCount: data && data.data && data.data.length,
            isPartnerLoading: false
          });
        });
    });
  }

  // Get Playbook List
  _getPlaybookList(status) {
    apiClient
      .get(
        `${endpoints().playbookAPI}?section=playbooks?search=${
          this.state.searchParam
        }?status=${status}`
      )
      .then(response => {
        const data = response.data.data;
        const activePlaybookCount = response.data.activePlaybookCount;
        const draftPlaybookCount = response.data.draftedPlaybookCount;
        this.setState({
          playBooks: data,
          playbookTotalCount:
            status === PLAYBOOK_STATUS_ACTIVE
              ? activePlaybookCount
              : status === PLAYBOOK_STATUS_DRAFT
              ? draftPlaybookCount
              : PLAYBOOK_STATUS_DRAFT
        });
      });
  }

  // Search expert
  _handleInputChange = e => {
    this.setState(
      {
        searchParam: e.target.value
      },
      () => {
        apiClient
          .get(
            `${
              endpoints().expertAPI
            }/search?marketplaceStatus=Approved&search=${encodeURIComponent(
              this.state.searchParam
            )}`
          )
          .then(response => {
            const data = response.data;
            this.setState({
              expertList: !data ? [] : data.data,
              searchCount: data.totalCount,
              totalCount: data.totalCount
            });
          });
      }
    );
  };

  // Get Tags by tag type
  _getTagsByTagType = tagType => {
    return apiClient
      .get(
        `${
          endpoints().playbookAPI
        }/tags/list?tagType=${tagType}&isSearchable=true`
      )
      .then(response => {
        const tags = response.data.data;
        if (tags.length > 0) {
          // Check the tag type experts
          if (tagType === TAG_TYPE_EXPERT_ROLE) {
            tags.forEach(tag => {
              this.state.expertTypes.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          } else if (tagType === TAG_TYPE_SKILLS) {
            tags.forEach(tag => {
              this.state.skills.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          } else if (tagType === TAG_TYPE_TOOLS) {
            tags.forEach(tag => {
              this.state.tools.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          } else if (tagType === TAG_TYPE_INDUSTRIES) {
            tags.forEach(tag => {
              this.state.industries.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          } else if (tagType === TAG_TYPE_CERTIFICATION) {
            tags.forEach(tag => {
              this.state.certificates.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Render the Expert List
  renderExpertList() {
    const selectedFilters = this.state.selectedFilters;
    const tags = [];
    const certificates = [];

    const expertsTags = selectedFilters.expertTypeObj
      ? selectedFilters.expertTypeObj.selectedValue
      : "";

    if (expertsTags.length > 0) {
      expertsTags.forEach(expert => {
        tags.push(expert);
      });
    }

    const expertTypesTags = selectedFilters.expertTypeObj
      ? selectedFilters.expertTypeObj.selectedValue
      : "";

    if (expertTypesTags.length > 0) {
      expertTypesTags.forEach(expertType => {
        tags.push(expertType);
      });
    }

    const skillsTags = selectedFilters.multiSelect
      ? selectedFilters.multiSelect.selectedValue
      : "";

    if (skillsTags.length > 0) {
      skillsTags.forEach(skill => {
        tags.push(skill);
      });
    }

    const certificateTags = selectedFilters.certification
      ? selectedFilters.certification.selectedValue
      : "";

    if (certificateTags.length > 0) {
      certificateTags.forEach(certificate => {
        certificates.push(certificate);
      });
    }

    const toolsTags = selectedFilters.tool
      ? selectedFilters.tool.selectedValue
      : "";

    if (toolsTags.length > 0) {
      toolsTags.forEach(tool => {
        tags.push(tool);
      });
    }

    const industriesTags = selectedFilters.industry
      ? selectedFilters.industry.selectedValue
      : "";

    if (industriesTags.length > 0) {
      industriesTags.forEach(industry => {
        tags.push(industry);
      });
    }

    const isAvailability =
      selectedFilters &&
      selectedFilters.availabilityObj &&
      selectedFilters.availabilityObj.selectedValue.length > 0 &&
      selectedFilters.availabilityObj.selectedValue &&
      selectedFilters.availabilityObj.selectedValue[0] !== APPROVED_EXPERT
        ? selectedFilters.availabilityObj.selectedValue[0] === AVAILABLE_EXPERT
          ? true
          : false
        : "";

    const availability =
      selectedFilters &&
      selectedFilters.hoursObj &&
      selectedFilters.hoursObj.selectedValue &&
      selectedFilters.hoursObj.selectedValue.length > 0
        ? selectedFilters.hoursObj.selectedValue[0].replace(/\+/gi, "%2B")
        : "";

    const levelOfExperienceValue =
      selectedFilters &&
      selectedFilters.levelOfExp &&
      selectedFilters.levelOfExp.selectedValue &&
      selectedFilters.levelOfExp.selectedValue.length > 0
        ? selectedFilters.levelOfExp.selectedValue[0]
        : "";

    let levelOfExperience = "";
    if (levelOfExperienceValue === NO_EXPERIENCE) {
      levelOfExperience = NO_EXPERIENCE_IDENTIFIER;
    } else if (levelOfExperienceValue === ONE_PROJECT_EXPERIENCE) {
      levelOfExperience = ONE_PROJECT_EXPERIENCE_IDENTIFIER;
    } else if (levelOfExperienceValue === MORE_THAN_ONE_PROJECT_EXPERIENCE) {
      levelOfExperience = MORE_THAN_ONE_PROJECT_EXPERIENCE_IDENTIFIER;
    }

    const location =
      selectedFilters &&
      selectedFilters.location &&
      selectedFilters.location.selectedValue
        ? selectedFilters.location.selectedValue
        : "";
    const locationSplit =
      location && location[0] ? location[0].split(", ") : "";
    const city = locationSplit[0] || "";

    apiClient
      .get(
        `${
          endpoints().expertAPI
        }/search?marketplaceStatus=Approved&tags=${tags}&city=${city}&isAvailable=${isAvailability}&availability=${availability}&levelOfExperience=${levelOfExperience}&certificate=${certificates}`
      )
      .then(response => {
        const expertList = response.data.data || [];
        const data = response.data;
        this.setState({
          expertList,
          totalCount: data.totalCount
        });
      });
  }

  // Handle status change
  _handleStatusChange = tabStatus => {
    const settings = this.props.settings ? this.props.settings : "";

    const enablePartners = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS)
      : "";

    const enableExperts = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS)
      : "";
    let applicantParams = "";
    let applicant = "";
    if (tabStatus === TAB_EXPERTS) {
      this.loadExpertsTabAPICall(tabStatus);
    }
    if (tabStatus === TAB_APPLICANTS) {
      const { applicantLabel } = this.state;
      applicantParams =
        applicantLabel === PENDING_EXPERTS
          ? `&pending=${PENDING_EXPERTS}`
          : applicantLabel === PENDING_PARTNERS
          ? `&pending=${PENDING_PARTNERS}`
          : enableExperts && enableExperts === "true"
          ? `&pending=${PENDING_EXPERTS}`
          : enablePartners && enablePartners === "true"
          ? `&pending=${PENDING_PARTNERS}`
          : ``;
      applicant =
        enablePartners &&
        enablePartners === "true" &&
        applicantLabel === PENDING_PARTNERS
          ? "pending_partners_table"
          : enableExperts &&
            enableExperts === "true" &&
            applicantLabel === PENDING_EXPERTS
          ? "pending_experts_table"
          : "";
    }

    this.props.history.push(
      `/marketplace-sourcing?section=${tabStatus}${applicantParams}`
    );
    this.setState({ status: tabStatus, applicantParams: applicant });
  };

  // Toggle Tab
  toggle = tab => {
    this.setState({
      activeTab: tab
    });
  };

  // Get Selected Filters
  getSelectedFilters = (selectedFilters, selectName) => {
    this.setState(prevState => ({
      selectedFilters: {
        ...prevState.selectedFilters,
        [selectName]: selectedFilters
      }
    }));
  };

  // Get Filter Keys
  getFilterKeys = () => Object.keys(this.state.selectedFilters);

  // Delete Filter Keys
  deleteFilters = e => {
    const { dataset } = e.target;
    this.setState(
      prevState => {
        let currentValueIndex = prevState.selectedFilters[
          dataset["parentobj"]
        ].selectedValue.indexOf(dataset["filter"]);
        let currentArray =
          prevState.selectedFilters[dataset["parentobj"]].selectedValue;
        currentArray.splice(currentValueIndex, 1);

        return {
          selectedFilters: {
            ...prevState.selectedFilters,
            [dataset["parentobj"]]: {
              selectedValue: [...currentArray]
            }
          }
        };
      },
      () => {
        this.renderExpertList();
      }
    );
  };

  // Handle Applicant
  handleApplicant = e => {
    const { label, value } = e.target.dataset;
    const queryParam =
      value === "pending_experts_table" ? PENDING_EXPERTS : PENDING_PARTNERS;
    this.props.history.push(
      `/marketplace-sourcing?section=Applicants&pending=${queryParam}`
    );
    this.setState({
      applicantLabel: label,
      applicantParams: value
    });
  };

  componentDidUpdate(prevProps) {
    // Check is logged in user
    isLoggedIn();
    if (prevProps.settings !== this.props.settings) {
      const settings = this.props.settings ? this.props.settings : "";

      const enablePartners = settings
        ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS)
        : "";

      const enableExperts = settings
        ? getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS)
        : "";
      const queryParam = getParamsByName("pending");

      let applicantParams =
        enablePartners &&
        enablePartners === "true" &&
        queryParam === PENDING_PARTNERS
          ? "pending_partners_table"
          : enableExperts &&
            enableExperts === "true" &&
            queryParam === PENDING_EXPERTS
          ? "pending_experts_table"
          : enableExperts &&
            enableExperts === "true" &&
            queryParam === PENDING_PARTNERS
          ? "pending_experts_table"
          : enablePartners && enablePartners === "true"
          ? "pending_partners_table"
          : "";

      const value =
        enablePartners &&
        enablePartners === "true" &&
        queryParam === PENDING_PARTNERS
          ? PENDING_PARTNERS
          : enableExperts &&
            enableExperts === "true" &&
            queryParam === PENDING_EXPERTS
          ? PENDING_EXPERTS
          : enableExperts && enableExperts === "true"
          ? PENDING_EXPERTS
          : enablePartners && enablePartners === "true"
          ? PENDING_PARTNERS
          : "";
      this.setState({
        applicantLabel: value,
        applicantParams
      });
    }

    if (prevProps.expertCount !== this.props.expertCount) {
      this.setState({ totalCount: this.props.expertCount });
    }

    if (prevProps.partnerCount !== this.props.partnerCount) {
      this.setState({ partnerTotalCount: this.props.partnerCount });
    }
  }
  addAppModal = () => {
    this.setState({ isOpen: true });
  };

  closeEditModal = () => {
    this.setState({ isOpen: false });
  };

  filteredCount = e => {
    if (this.state.partnerTotalCount !== e)
      this.setState({ partnerTotalCount: e });
  };

  getPartnerList = (partnerList, searchTerms) => {
    this.setState({ partners: partnerList, searchTerms: searchTerms });
  };
  setPartnerList = partners => {
    this.setState({ partners: partners });
  };

  // get apps stats
  getAppStatics = () => {
    try {
      apiClient
        .get(`${endpoints().appAPI}/appActivity/stats`)
        .then(response => {
          if (response && response.data) {
            this.setState({
              appActivityStats: response.data
            });
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.stringify(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    } catch (err) {}
  };

  appMarketplaceCreate(data, props, toggle, params) {
    return apiClient
      .post(endpoints().appAPI, data)
      .then(async response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          toggle();
          await this.getAppList(this.state.params);
          this.getAppStatics();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // App list api call
  getAppList = async params => {
    const queryString = [];
    if (params) {
      Object.keys(params).forEach(param => {
        queryString.push(`${param}=${params[param]}`);
      });
    }

    let apiUrl = `${endpoints().appAPI}/search`;

    if (queryString && queryString.length > 0) {
      apiUrl = `${apiUrl}?${queryString.join("&")}`;
    } else {
      apiUrl = apiUrl;
    }

    const response = await apiClient.get(apiUrl);
    const appList = response && response.data && response.data.data;

    const appCount = response && response.data && response.data.totalCount;

    this.setState({ appList: appList, appCount: appCount });
  };

  render() {
    const {
      status,
      expertList,
      partnerTotalCount,
      isExpertLoading,
      sortByOptions,
      isLoading,
      totalCount,
      selectedSortOption,
      activeTab,
      selectedFilters,
      expertTypes,
      skills,
      industries,
      tools,
      certificates,
      applicantLabel,
      applicantParams,
      partnerList,
      partners,
      appList,
      appActivityStats,
      appCount
    } = this.state;

    const partnerCSVData = [];
    if (partnerList) {
      partnerList.forEach(data => {
        partnerCSVData.push({
          companyName: data.companyName,
          email: data.email,
          partnerTierName: data.partnerTierName,
          partnerTypeName: data.partnerTypeName,
          headline: data.headline,
          about_me: data.about_me,
          services: data.services,
          countryName: data.countryName,
          stateName: data.stateName,
          city: data.city,
          phone: data.phone,
          address1: data.address1,
          address2: data.address2,
          postal_code: data.postal_code,
          website_url: data.website_url
        });
      });
    }
    const csvData = partners ? partners : partnerCSVData;

    // Export header
    const csvHeaders = [
      { label: "Company Name", key: "companyName" },
      { label: "Tier", key: "partnerTierName" },
      { label: "Type", key: "partnerTypeName" },
      { label: "Headline", key: "headline" },
      { label: "About", key: "about_me" },
      { label: "Services", key: "services" },
      { label: "Address1", key: "address1" },
      { label: "Address2", key: "address2" },
      { label: "Country", key: "countryName" },
      { label: "State", key: "stateName" },
      { label: "City", key: "city" },
      { label: "Zip Code", key: "postal_code" },
      { label: "Email Address", key: "email" },
      { label: "Phone Number", key: "phone" },
      { label: "Website Url", key: "website_url" }
    ];

    const ENUM = {
      pending_experts_table: <PendingExperts />,
      pending_partners_table: <PendingPartners />
    };

    const settings = this.props.settings ? this.props.settings : "";

    // natural language form setup.
    const selectObj = {
      expertTypeObj: expertTypes,
      hoursObj: getExpertHourlyOption(),
      availabilityObj: getExpertAvailabilityOption(),
      industry: industries,
      tool: tools,
      levelOfExp: getExpertLevelOfExperienceOption(),
      multiSelect: skills,
      certification: certificates,
      location: [
        {
          location: true
        }
      ]
    };

    const paragraphs = [
      "I'm looking for an {expertTypeObj}",
      "with {multiSelect} expertise and {certification}",
      "that is {availabilityObj} for up to {hoursObj}"
    ];

    const extraParagraphs = [
      "That has experience in {industry}",
      "that utilizes {tool}",
      "that has {levelOfExp}",
      "that is located in {location}"
    ];

    const enablePlaybook = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PLAYBOOK)
      : "";
    const enablePartners = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS)
      : "";
    const allowPartnerToCreatePlaybook = settings
      ? getKeyValueByObject(settings, SETTINGS_ALLOW_PARTNER_TO_CREATE_PLAYBOOK)
      : "";

    const enableExperts = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS)
      : "";

    const enableApp = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_APP)
      : "";

    let applicantDropdownOptions = [];
    if (enableExperts && enableExperts === "true") {
      applicantDropdownOptions.push({
        label: PENDING_EXPERTS,
        value: "pending_experts_table"
      });
    }
    if (enablePartners && enablePartners === "true") {
      applicantDropdownOptions.push({
        label: PENDING_PARTNERS,
        value: "pending_partners_table"
      });
    }

    const currentUrl = window.location.pathname + window.location.search;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchStatus = params.get("section");

    const isPlaybook =
      searchStatus === TAB_PLAYBOOKS || activeTab === TAB_PLAYBOOKS;
    const isExpert = searchStatus === TAB_EXPERTS || activeTab === TAB_EXPERTS;
    const isPartners =
      searchStatus === TAB_PARTNERS || activeTab === TAB_PARTNERS;
    const isApplicants =
      searchStatus === TAB_APPROVE_SKILL || activeTab === TAB_APPLICANTS;

    const isApps = searchStatus === TAB_APPS || activeTab === TAB_APPS;

    // Props
    const {
      pendingPartnersCount,
      pendingExpertsCount,
      playBookCount,
      appsCount
    } = this.props;

    const applicantCount =
      applicantLabel === PENDING_EXPERTS
        ? pendingExpertsCount
        : applicantLabel === PENDING_PARTNERS
        ? pendingPartnersCount
        : pendingExpertsCount;

    return isLoading || !settings ? (
      <Spinner />
    ) : ((isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
        ((enablePlaybook && enablePlaybook === "true" && isPlaybook) ||
          (enableApp && enableApp === "true" && isApps) ||
          (enableExperts &&
            enableExperts === "true" &&
            (isExpert || isApplicants)) ||
          (enablePartners &&
            enablePartners === "true" &&
            (isPartners || isApplicants)))) ||
      ((isCustomer(this.props.roleId) || isPartner(this.props.roleId)) &&
        (isPlaybook ||
          (enablePlaybook && enablePlaybook === "true") ||
          (enableApp && enableApp === "true" && isApps) ||
          (enableExperts && enableExperts === "true" && isExpert) ||
          (enablePartners && enablePartners === "true" && isPartners))) ? (
      <div>
        <div className="row">
          <div className="col-6">
            <PageTitle label={Terminology.get(SystemName.MARKETPLACE)} />
          </div>

          {activeTab === TAB_APPS && (
            <div className="col-6">
              {(isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId) ||
                isPartner(this.props.roleId)) && (
                <AddButton
                  label="New App"
                  onClick={e => {
                    this.addAppModal();
                  }}
                  className="pull-right btn btn-secondary"
                />
              )}
            </div>
          )}

          {activeTab === TAB_PLAYBOOKS && (
            <div className="col-6">
              {(isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId) ||
                (isPartner(this.props.roleId) &&
                  allowPartnerToCreatePlaybook === "true")) && (
                <AddButton
                  label="New Playbook"
                  targetUrl="/playbook/new"
                  className="pull-right btn btn-secondary"
                />
              )}
            </div>
          )}
          {/* partner export */}
          {activeTab === TAB_PARTNERS &&
            (isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) && (
              <div className="col-6 mt-2">
                <CSVDownload
                  data={csvData}
                  headers={csvHeaders}
                  fileName="Partners"
                  className="pull-right"
                />
              </div>
            )}
        </div>
        {status === TAB_PLAYBOOKS ? (
          <Redirect to={`/marketplace-sourcing?section=${TAB_PLAYBOOKS}`} />
        ) : (
          ""
        )}

        <Nav tabs className="admin-tabs mb-0">
          {isTrue(enableApp) && (
            <NavItem>
              <NavLink
                className={classnames({ active: isApps })}
                onClick={() => {
                  this.toggle(TAB_APPS);
                  this._handleStatusChange(TAB_APPS);
                }}
              >
                Apps
                <CountBadge count={appCount} isActive={isApps} />
              </NavLink>
            </NavItem>
          )}
          {enablePlaybook && enablePlaybook === "true" && (
            <NavItem>
              <NavLink
                className={classnames({ active: isPlaybook })}
                onClick={() => {
                  this.toggle(TAB_PLAYBOOKS);
                  this._handleStatusChange(TAB_PLAYBOOKS);
                }}
              >
                Playbooks
                <CountBadge count={playBookCount} isActive={isPlaybook} />
              </NavLink>
            </NavItem>
          )}

          {enableExperts &&
            enableExperts === "true" &&
            (isPartner(this.props.roleId) ||
              isCustomer(this.props.roleId) ||
              isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: isExpert })}
                  onClick={() => {
                    this.toggle(TAB_EXPERTS);
                    this._handleStatusChange(TAB_EXPERTS);
                  }}
                >
                  Experts
                  <CountBadge count={totalCount} isActive={isExpert} />
                </NavLink>
              </NavItem>
            )}

          {enablePartners &&
            enablePartners === "true" &&
            (isCustomer(this.props.roleId) ||
              isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: isPartners })}
                  onClick={() => {
                    this.toggle(TAB_PARTNERS);
                    this._handleStatusChange(TAB_PARTNERS);
                  }}
                >
                  Partners
                  <CountBadge count={partnerTotalCount} isActive={isPartners} />
                </NavLink>
              </NavItem>
            )}

          {(isSuperAdmin(this.props.roleId) ||
            isCompanyAdmin(this.props.roleId) ||
            isCompanyManager(this.props.roleId)) &&
            ((enableExperts && enableExperts === "true") ||
              (enablePartners && enablePartners === "true")) && (
              <NavItem>
                <NavLink
                  className={classnames({ active: isApplicants })}
                  onClick={() => {
                    this.toggle(TAB_APPLICANTS);
                    this._handleStatusChange(TAB_APPLICANTS);
                  }}
                >
                  Applicants
                  <CountBadge count={applicantCount} isActive={isApplicants} />
                </NavLink>
              </NavItem>
            )}
        </Nav>

        {/* Tab Content */}
        <TabContent activeTab={activeTab}>
          {/*Apps Tab*/}
          {enableApp && enableApp === "true" && (
            <TabPane tabId={TAB_APPS}>
              <AppList
                history={this.props.history}
                enableApp={enableApp}
                activeTab={activeTab}
                appsCount={appsCount}
                appActivityStats={appActivityStats}
                settings={settings}
                appList={this.state.appList}
                getAppList={this.getAppList}
                appCount={appCount}
              />
              <AddModal
                isModalOpen={this.state.isOpen}
                closeEditModal={this.closeEditModal}
                appMarketplaceCreate={this.appMarketplaceCreate}
              />
            </TabPane>
          )}

          {/*Playbooks Tab*/}
          {enablePlaybook && enablePlaybook === "true" && (
            <TabPane tabId={TAB_PLAYBOOKS}>
              <Playbook
                settings={settings}
                history={this.props.history}
                getPlaybookList={this._getPlaybookList.bind(this)}
              />
            </TabPane>
          )}

          {/* Experts Tab */}
          {enableExperts && enableExperts === "true" && (
            <TabPane tabId={TAB_EXPERTS}>
              <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column">
                <PageSearch
                  classnames="page-search"
                  placeholder="Search"
                  onChange={e => this._handleInputChange(e)}
                />

                <SelectDropdown
                  buttonLabel={selectedSortOption}
                  dropdownLinks={sortByOptions}
                  color={"gray"}
                  hideCaret
                  selectName={"sortby_experts"}
                />
              </div>

              <NLFormWrapper
                paragraphs={paragraphs}
                selectObj={selectObj}
                extraParagraphs={extraParagraphs}
                getSelectedFilters={this.getSelectedFilters}
                liveFilters={selectedFilters}
                renderExpertList={e => this.renderExpertList(e)}
              />

              {this.getFilterKeys().length > 0 ? (
                <div className="filters-wrapper">
                  {this.getFilterKeys().map(filterObj =>
                    this.state.selectedFilters[filterObj]["selectedValue"].map(
                      value =>
                        value && (
                          <button
                            className={`badge badge-primary ${filterObj}`}
                            data-parentobj={filterObj}
                            data-filter={value}
                            onClick={this.deleteFilters}
                          >
                            {value} <CrossIcon />
                          </button>
                        )
                    )
                  )}
                </div>
              ) : (
                ""
              )}

              {!isExpertLoading && !expertList.length && (
                <div className="inline-alert alert alert-light">
                  <OctagonAlertIcon />{" "}
                  <span>
                    There are no Experts available that match your query. Try
                    different filters.
                  </span>
                </div>
              )}

              {/* /.selectedFilters */}
              <div className="card-list-wrapper">
                {totalCount > 0 && (
                  <span className="d-block font-weight-bold h6-5">
                    Showing {totalCount}{" "}
                    {totalCount > 1 ? "experts" : "experts"}…
                  </span>
                )}
                <ExpertGrid
                  expertList={expertList}
                  currentUrl={currentUrl}
                  openLinkInNewTab={true}
                />
              </div>
            </TabPane>
          )}

          {/* Partners Tab */}
          {enablePartners && enablePartners === "true" && (
            <TabPane tabId={TAB_PARTNERS}>
              <ApprovedPartners
                filteredCount={e => {
                  this.filteredCount(e);
                }}
                settings={settings}
                setPartnerList={this.setPartnerList}
                partnerCount={this.props.partnerCount}
                getPartnerList={this.getPartnerList}
              />
            </TabPane>
          )}

          {/* Partners Tab */}
          {(isSuperAdmin(this.props.roleId) ||
            isCompanyAdmin(this.props.roleId) ||
            isCompanyManager(this.props.roleId)) &&
            ((enableExperts && enableExperts === "true") ||
              (enablePartners && enablePartners === "true")) && (
              <TabPane tabId={TAB_APPLICANTS}>
                <div className="mt-3">
                  <PrimaryDropdown
                    buttonLabel={applicantLabel}
                    dropdownLinks={applicantDropdownOptions}
                    onClick={e => this.handleApplicant(e)}
                    menuPosition={false}
                  />
                  <div className="mt-3">{ENUM[applicantParams]}</div>
                </div>
              </TabPane>
            )}
        </TabContent>
      </div>
    ) : (
      <Page404 />
    );
  }
}

const mapStateToProps = state => {
  const {
    pendingExperts,
    expertProfileTags,
    approvedExperts,
    sourceProjects,
    pendingPartners,
    approvedPartners,
    app
  } = state.table;

  const roleId = state.user.roleId;

  //get playbooks count
  const playBookCount =
    state.playbooks.pagination && state.playbooks.pagination.totalCount;

  // Get pending expert count
  const appsCount = app && !app.isFetching ? app.totalCount : 0;

  // Get pending expert count
  const pendingExpertsCount =
    pendingExperts && !pendingExperts.isFetching
      ? pendingExperts.totalCount
      : 0;
  // Get expert profile skills count
  const expertProfileSkillsCount =
    expertProfileTags && !expertProfileTags.isFetching
      ? expertProfileTags.totalCount
      : 0;
  // Get experts count
  const expertCount =
    approvedExperts && !approvedExperts.isFetching
      ? approvedExperts.totalCount
      : 0;

  // Get partners count
  const partnerCount =
    approvedPartners && !approvedPartners.isFetching
      ? approvedPartners.totalCount
      : 0;

  // Get source projects count
  const sourceProjectsCount =
    sourceProjects && !sourceProjects.isFetching
      ? sourceProjects.totalCount
      : 0;

  // Get pending partners count
  const pendingPartnersCount =
    pendingPartners && !pendingPartners.isFetching
      ? pendingPartners.totalCount
      : 0;

  return {
    pendingExpertsCount,
    expertProfileSkillsCount,
    expertCount,
    sourceProjectsCount,
    pendingPartnersCount,
    partnerCount,
    playBookCount,
    appsCount,
    roleId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MarketplaceSourcing);
