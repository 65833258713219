import React from "react";

const ProgressBar = props => {
  let { value, maxValue, leftText, rightText, fillColorClassName } = props;
  return (
    <div
      style={{ position: "relative", height: "50px" }}
      className={`${fillColorClassName ? fillColorClassName : ""}`}
    >
      <progress
        value={value}
        max={maxValue}
        style={{
          width: "100%",
          height: "50px"
        }}
      ></progress>
      <span
        style={{
          position: "absolute",
          left: "20px",
          top: "12px",
          color: "white"
        }}
      >
        {leftText}
      </span>

      <span
        style={{
          position: "absolute",
          right: "20px",
          top: "12px",
          color: "#87878C"
        }}
      >
        {rightText}
      </span>
    </div>
  );
};

export default ProgressBar;
