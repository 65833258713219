import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormGroup, FormFeedback } from "reactstrap";
import Label from "../base/Label";
import moment from "moment";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

class DateInput extends React.Component {
  validate(value) {
    const { label, placeholder, required, selected } = this.props;

    let errorMessage;
    let inputLabel = label || placeholder;

    if (!selected && required) {
      errorMessage = inputLabel ? `${inputLabel} is required` : "Required";
    } else {
      errorMessage = null;
    }

    return errorMessage;
  }

  renderInput({ field, form: { touched, errors, setFieldValue } }) {
    const {
      name,
      id,
      label,
      placeholder,
      format,
      required,
      onChange,
      selected,
      minDate,
      width,
      isClearable,
      maxDate,
      disabled
    } = this.props;

    let errorMessage = touched[name] && errors[name] ? errors[name] : null;

    errorMessage =
      typeof errorMessage != "object" && !selected ? errorMessage : null;

    const inputId = id || name;

    return (
      <FormGroup key={id} style={{ marginBottom: 22, position: "relative" }}>
        {label && (
          <Label id={inputId} required={required}>
            {label}
          </Label>
        )}

        <DatePicker
          name={name}
          id={inputId}
          disabled={disabled}
          className={`form-control ${errorMessage ? "is-invalid" : ""}`}
          onChange={value => {
            onChange && onChange(value);
            value
              ? setFieldValue(name, moment(value).format("YYYY-MM-DD"))
              : setFieldValue(name, null);
          }}
          dateFormat={format || "dd MMM, yyyy"}
          minDate={minDate}
          selected={
            field.value ? new Date(field.value) : selected ? selected : null
          }
          placeholderText={placeholder || label}
          wrapperClassName={`w-${width ? width : 100}`}
          isClearable={isClearable ? isClearable : false}
          maxDate={maxDate}
        />

        {errorMessage && (
          <FormFeedback
            style={{ position: "absolute", marginTop: 1, display: "block" }}
          >
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }

  render() {
    const { name, id, readOnly, className, date } = this.props;

    return (
      <>
        {readOnly ? (
          <>
            <div className={className}>
              <Label>{date}</Label>
            </div>
          </>
        ) : (
          <>
            <Field
              id={id || name}
              validate={this.validate.bind(this)}
              name={name}
              render={this.renderInput.bind(this)}
            />
          </>
        )}
      </>
    );
  }
}

DateInput.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  format: PropTypes.string,
  required: PropTypes.bool
};

export default DateInput;
