import React from "react";
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";

//Assets
import {
  ChevronRight,
  SettingsBillDotComIcon,
  SettingsStripeIcon
} from "../../../../assets/img/icons";

// Constants
import {
  INTEGRATION_PAYMENTS_SUB_TAB,
  TAB_BILL_DOT_COM_SETTINGS,
  TAB_INTEGRATION,
  TAB_STRIPE_SETTINGS
} from "../../../../setting/Constants";
import BillDotComSection from "./sections/BillDotComSection";
import StripeSection from "./sections/StripeSection";

const PaymentsCategorySection = props => {
  const {
    activeSection,
    adminSettings,
    saveSettings,
    billDotComSettings,
    history
  } = props;

  return (
    <>
      {!activeSection && (
        <>
          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_PAYMENTS_SUB_TAB}/${TAB_BILL_DOT_COM_SETTINGS}`}
            className="onboarding-link mb-2"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="step-count d-block completed">
                  <SettingsBillDotComIcon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">Bill.com</h5>
              {/* <p className="font-weight-bold text-inline-grayed">
          Bill.com Settings
        </p> */}
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>

          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_PAYMENTS_SUB_TAB}/${TAB_STRIPE_SETTINGS}`}
            className="onboarding-link mb-2"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="step-count completed">
                  <SettingsStripeIcon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">Stripe</h5>
              <p className="font-weight-bold text-inline-grayed"></p>
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>
        </>
      )}

      <TabContent activeTab={activeSection}>
        <TabPane tabId={TAB_BILL_DOT_COM_SETTINGS} className="w-100">
          <BillDotComSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            billDotComSettings={billDotComSettings}
            history={history}
          />
        </TabPane>

        <TabPane tabId={TAB_STRIPE_SETTINGS} className="w-100">
          <StripeSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default PaymentsCategorySection;
