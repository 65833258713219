//plannedactivitytimeline

import React from "react";
import PropTypes from "prop-types";
import Avatar from "../base/Avatar";
import moment from "moment";

class PlannedActivityTimeline extends React.Component {
  render() {
    const { data } = this.props;

    return (
      <>
        {data && (
          <div
            className="dropdown-wrapper ml-auto"
            style={{ display: "flex", flexDirection: "row" }}
          >
            <Avatar
              id={
                data.id
                  ? data.id
                  : data.id
                  ? data.user.first_name
                  : data.userName
              }
              firstName={data.user ? data.user.first_name : data.userName}
              lastName={data.user ? data.user.last_name : ""}
              url={data.user && data.user.avatarUrl ? data.user.avatarUrl : ""}
              size="xs"
            ></Avatar>

            <div className="activity-line" style={{ left: "2%" }}></div>
            <div>
              <h5
                style={{
                  display: "flex",
                  flexDirection: "row",
                  fontSize: 14,
                  fontWeight: "bold",
                  margin: "0 10px 2px"
                }}
              >
                An event for&nbsp; <q>{data.notes}</q>&nbsp;is scheduled for{" "}
                {moment(data.activity_at).format("MMMM DD, YYYY")}
              </h5>
              <h6
                style={{
                  textColor: "#dcdcdc !important",
                  fontSize: 12,
                  margin: "0 10px 2px",
                  paddingBottom: "60px"
                }}
              >
                {data.createdAt}
              </h6>
            </div>
          </div>
        )}
      </>
    );
  }
}

PlannedActivityTimeline.propTypes = {
  label: PropTypes.string
};

export default PlannedActivityTimeline;
