import React from "react";

// Icons
import { InfoIcon } from "../../assets/img/icons";

// Components
import Email from "../../components/base/Email";
import Phone from "../../components/base/Phone";
import Password from "../../components/base/Password";
import Text from "../../components/base/Text";
import SecondaryButton from "../../components/base/SecondaryButton";
import Zipcode from "../../components/base/Zipcode";
import State from "../../components/State";
import Country from "../../components/base/Country";
import Checkbox from "./Checkbox";

// Constants
import { UNITED_STATES } from "../../Country/Constants";

// Helper
import { TORCHLITE_WEBSITE_URL } from "../../configs";

class BasicInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      acceptTerms: false,
      required: false,
      selectedOption: "",
      selectedCountryName: "",
      selectedRegionOption: "America"
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  // Handle Country
  handleCountryChange = ({ values }) => {
    const selectedOption = values.country && values.country.value;
    const selectedCountryName = values.country && values.country.label;
    this.setState({
      selectedOption,
      selectedCountryName
    });
  };

  render() {
    if (this.props.currentStep !== this.props.step) {
      // Prop: The current step
      return null;
    }

    const { selectedCountry } = this.props;

    const { required, selectedOption, selectedCountryName } = this.state;

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <InfoIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-3">Basic Information</h2>
        </div>
        <div className="content">
          <div className="field-wrapper">
            <Text
              name="companyName"
              label="Company Name"
              placeholder="Enter company name"
              required={true}
              error={this.props.companyNameErrorMessage}
              onKeyDown={this.props.hideErrorMessage}
              {...{ required }}
            />
          </div>

          <div className="field-wrapper">
            <Text
              name="firstName"
              label="First Name"
              placeholder="Enter first name"
              required={true}
              error=""
              {...{ required }}
            />
            <Text
              name="lastName"
              label="Last Name"
              placeholder="Enter last name"
              error=""
              {...{ required }}
            />
          </div>

          <div className="field-wrapper">
            <Email
              name="email"
              label="Email"
              placeholder="Enter email"
              error={this.props.emailErrorMessage}
              onKeyDown={this.props.hideErrorMessage}
              {...{ required }}
            />
            <Phone
              name="phone"
              label="Phone"
              placeholder="Enter phone"
              error=""
              {...{ required }}
            />
          </div>

          <div className="field-wrapper">
            <Password
              name="password"
              label="Password"
              placeholder="Enter password"
              required={true}
              error={
                this.props.passwordMismatchError
                  ? this.props.passwordMismatchError
                  : ""
              }
              strongPassword={
                this.props.passwordMismatchError ===
                "Please choose stronger password"
              }
              onInputChange={this.props.handlePasswordErrors}
              {...{ required }}
            />
            <Password
              name="confirmPassword"
              label="Confirm Password"
              placeholder="Enter confirm password"
              required={true}
              error={
                this.props.confirmPasswordMismatchError
                  ? this.props.confirmPasswordMismatchError
                  : ""
              }
              onInputChange={this.props.handlePasswordErrors}
              {...{ required }}
            />
          </div>

          <div className="field-wrapper">
            <Text
              autoComplete="nope"
              name="address1"
              label="Street Address 1"
              placeholder="Enter street address 1"
              error=""
              {...{ required }}
            />
          </div>

          <div className="field-wrapper">
            <Text
              autoComplete="nope"
              name="address2"
              label="Street Address 2"
              placeholder="Enter street address 2"
            />
          </div>

          <div className="field-wrapper">
            <Text
              name="city"
              label="City"
              placeholder="Enter city"
              error=""
              {...{ required }}
            />

            <Zipcode
              name="zipcode"
              label="Zip/Postal Code"
              placeholder="Enter Zipcode"
              error=""
              {...{ required }}
              locale={selectedCountryName ? selectedCountryName : UNITED_STATES}
            />
          </div>
          <div className="field-wrapper">
            <State
              name="state"
              label="State/Province/Region"
              placeholder="Enter State"
              error=""
              selectedCountry={
                selectedOption ? selectedOption : selectedCountry
              }
              selectedCountryName={selectedCountry ? selectedCountry : ""}
            />

            <Country
              name="country"
              label="Country"
              placeholder="Select Country"
              error=""
              {...{ required }}
              onChange={this.handleCountryChange}
            />
          </div>

          <div className="field-wrapper position-relative">
            <Checkbox
              name="acceptTerms"
              label="I have read and accept the"
              required
              error="You must accept the terms and conditions"
              className={"validation accepted-terms mr-3"}
            />{" "}
            <a
              className="term-and-condition cursor-pointer"
              href={`${TORCHLITE_WEBSITE_URL}/terms-of-service`}
              target="_blank"
              rel="noreferrer"
            >
              terms and conditions
            </a>
          </div>

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton
              className="mr-4"
              label="Go Back"
              onClick={this.props.prev}
            />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={() => {
                this.setState({ required: true });
                return this.props.next;
              }}
            >
              Create Account
            </button>
          </div>
          <p className="text-grayed h7 mt-3">
            Note: We'll keep your personal information private, but profile
            information is displayed in our partner directory.
          </p>
        </div>
      </div>
    );
  }
}

export default BasicInformation;
