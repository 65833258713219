import { apiClient } from "../apiClient";
import { isBadRequest } from "../common/http";
import { endpoints } from "../configs";
import { TAG_TYPE_REGION } from "../tagType/Constants";

class PartnerService {
  static async TierList() {
    try {
      let partnerTierListOptions = [];

      const response = await apiClient.get(
        `${endpoints().partnerTierAPI}/search`
      );
      const results = response.data.data;

      if (results.length > 0) {
        results
          .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
          .forEach(type => {
            partnerTierListOptions.push({ value: type.id, label: type.name });
          });

        return partnerTierListOptions;
      } else {
        return partnerTierListOptions;
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  }

  static async TypeList() {
    try {
      let partnerTypeListOptions = [];

      const response = await apiClient.get(
        `${endpoints().partnerTypeAPI}/search`
      );
      const results = response.data.data;

      if (results.length > 0) {
        results
          .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
          .forEach(type => {
            partnerTypeListOptions.push({ value: type.id, label: type.name });
          });

        return partnerTypeListOptions;
      } else {
        return partnerTypeListOptions;
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  }
  static async List() {
    try {
      let partnerList = [];

      const response = await apiClient.get(
        `${endpoints().partnerAPI}/search?marketplaceStatus=Approved`
      );
      const results = response && response.data && response.data.data;

      if (results && results.length > 0) {
        results.forEach(partner => {
          partnerList.push({
            value: partner.id,
            label: partner.companyName
          });
        });
      }
      return partnerList;
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  }
  static async getRegion() {
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${TAG_TYPE_REGION}`
      );

      const results = response.data.data;
      const tagList = [];

      if (results && results.length > 0) {
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });
        return tagList;
      } else {
        return tagList;
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  }
}
export default PartnerService;
