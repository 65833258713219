import React from "react";
import {
  CircularProgressbarWithChildren,
  buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  ExpertCertifiedIcon,
  ExpertEliteIcon,
  ExpertIcon,
  ExpertProIcon
} from "../../../assets/img/icons";
import SampleProjectTooltip from "../../projects/inner-components/SampleProjectTooltip";
import { Link } from "react-router-dom";

const OnboardingLink = ({
  icon,
  title,
  description,
  availability,
  progress,
  link,
  expertStatus = {}
}) => {
  return (
    <div className="onboarding-link">
      <div className="onboarding-header">
        <div className="icon-wrapper">{icon}</div>
      </div>
      <div className="onboarding-main">
        <h5 className="font-weight-bold">{title}</h5>
        <p className="font-weight-bold text-inline-grayed">{description}</p>
        <Link
          to={link || "#"}
          className={`availability font-weight-bold ${
            availability.status === "available" ? "available" : "not-available"
          }`}
        >
          {availability.text}
        </Link>
      </div>
      <div className="completion-gauge">
        <CircularProgressbarWithChildren
          value={progress}
          styles={buildStyles({
            // Rotation of path and trail, in number of turns (0-1)
            rotation: 0,

            // Whether to use rounded or flat corners on the ends - can use 'butt' or 'round'
            strokeLinecap: "round",

            // Text size
            textSize: "16px",

            // How long animation takes to go from one percentage to another, in seconds
            pathTransitionDuration: 0.5,

            // Can specify path transition in more detail, or remove it entirely
            // pathTransition: 'none',

            // Colors
            pathColor: expertStatus.pathColor || "rgba(10, 194, 151, 1)",
            trailColor: "#F0F0F1",
            backgroundColor: "#fff"
          })}
        >
          <span className="d-block text-center font-weight-light h3 mb-0">
            {progress < 100 ? (
              <>{progress}%</>
            ) : (
              <>
                {expertStatus.accepted && (
                  <>
                    <span className="d-flex flex-column align-items-center">
                      {expertStatus.experttype === "normal" && (
                        <>
                          <ExpertIcon />
                          <a
                            href="#"
                            className="h6 mt-2 text-underline"
                            id={`expert-tooltip-${expertStatus.experttype}`}
                          >
                            Expert
                          </a>
                        </>
                      )}
                      {expertStatus.experttype === "pro" && (
                        <>
                          <ExpertProIcon />
                          <a
                            href="#"
                            className="h6 mt-2 text-underline"
                            id={`expert-tooltip-${expertStatus.experttype}`}
                          >
                            Pro Expert
                          </a>
                        </>
                      )}
                      {expertStatus.experttype === "elite" && (
                        <>
                          <ExpertEliteIcon />
                          <a
                            href="#"
                            className="h6 mt-2 text-underline"
                            id={`expert-tooltip-${expertStatus.experttype}`}
                          >
                            Elite Expert
                          </a>
                        </>
                      )}
                      <SampleProjectTooltip
                        tooltipId={`expert-tooltip-${expertStatus.experttype}`}
                        placement={"bottom"}
                      >
                        <p className="font-weight-bold">Expert Tier</p>
                        <p>
                          You can move up tiers by completing projects &
                          certifications:
                        </p>
                        <div className="steps-placeholder">
                          <div className="steps-wrapper">
                            <div className="step current-step">
                              <div className="step-icon bg-white text-black-50">
                                <ExpertIcon />
                              </div>
                              <div className="flex-wrapper">
                                <span
                                  className={`step-status-text font-weight-bold d-block text-white`}
                                >
                                  Expert
                                </span>
                                <span
                                  className={`step-status-text d-block text-white`}
                                >
                                  Accepted as Torchlite Expert
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="steps-wrapper">
                            <div className="step current-step">
                              <div className="step-icon bg-white text-black-50">
                                <ExpertCertifiedIcon />
                              </div>
                              <div className="flex-wrapper">
                                <span
                                  className={`step-status-text font-weight-bold d-block text-white`}
                                >
                                  Certified Expert
                                </span>
                                <span
                                  className={`step-status-text d-block text-white`}
                                >
                                  Certifications Completed
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="steps-wrapper">
                            <div className="step current-step">
                              <div className="step-icon bg-white text-black-50">
                                <ExpertProIcon />
                              </div>
                              <div className="flex-wrapper">
                                <span
                                  className={`step-status-text font-weight-bold d-block text-white`}
                                >
                                  Pro Expert
                                </span>
                                <span
                                  className={`step-status-text d-block text-white`}
                                >
                                  20 Projects Completed
                                </span>
                              </div>
                            </div>
                          </div>

                          <div className="steps-wrapper">
                            <div className="step current-step">
                              <div className="step-icon bg-white text-black-50">
                                <ExpertEliteIcon />
                              </div>
                              <div className="flex-wrapper">
                                <span
                                  className={`step-status-text font-weight-bold d-block text-white`}
                                >
                                  Elite Expert
                                </span>
                                <span
                                  className={`step-status-text d-block text-white`}
                                >
                                  100 Projects Completed
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </SampleProjectTooltip>
                    </span>
                  </>
                )}
              </>
            )}
          </span>
          {progress < 100 && (
            <span className="d-block text-center font-weight-bold h6 mb-0">
              Complete
            </span>
          )}
        </CircularProgressbarWithChildren>
      </div>
    </div>
  );
};

export default OnboardingLink;
