import React from "react";

// Assets
import { EditIconAlt } from "../../../assets/img/icons";

import BillingDetailModal from "./BilllingDetailModal";

import DefaultContent from "../../../components/content/DefaultContent";

class BillingDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBillingModal: false
    };
  }

  // Toggle modal
  ToggleBillingModal = () => {
    this.setState({
      isBillingModal: !this.state.isBillingModal
    });
  };

  render() {
    const { billingDetails, title, classNames } = this.props;

    return (
      <>
        <BillingDetailModal
          isBillingModal={this.state.isBillingModal}
          ToggleBillingModal={this.ToggleBillingModal}
          billingDetails={this.props.billingDetails}
          billingDetailsSubmit={this.props.billingDetailsSubmit}
        />
        <DefaultContent>
          <div className="row">
            <h4 className="ml-3">
              <b>{title}</b>
            </h4>
            <div className="ml-5"></div>
            <button
              className="btn btn-link ml-3 p-1"
              onClick={this.ToggleBillingModal}
              type={"button"}
            >
              {" "}
              {billingDetails ? <EditIconAlt /> : ""}
              {billingDetails ? "Edit" : "Add"}
            </button>
          </div>
          <div
            className={`${["billing-details", "d-flex"].join(
              " "
            )} ${classNames && classNames}`}
          >
            {billingDetails ? (
              <dl className={["definition-list"].join(" ")}>
                {billingDetails.name && <dd>{billingDetails.name}</dd>}
                {billingDetails.address && <dd>{billingDetails.address}</dd>}
                {(billingDetails.city ||
                  billingDetails.state ||
                  billingDetails.postal_code) && (
                  <dd>{`${billingDetails.city}, ${billingDetails.state}, ${billingDetails.postal_code}`}</dd>
                )}
                {billingDetails.country && <dd>{billingDetails.country}</dd>}
                {billingDetails.phone && <dd>{billingDetails.phone}</dd>}
              </dl>
            ) : (
              <h6>Update your billing info!</h6>
            )}
          </div>
        </DefaultContent>
      </>
    );
  }
}

export default BillingDetail;
