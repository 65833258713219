import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";
import { isLoggedIn } from "../../lib/helper";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

export default class UserList extends Component {
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    return (
      <div>
        <ReduxTable
          id="customerUsers"
          apiURL={`${endpoints().customerUserAPI}`}
        >
          <ReduxColumn field="role">Role</ReduxColumn>
          <ReduxColumn field="createdAt">Created At</ReduxColumn>
        </ReduxTable>
      </div>
    );
  }
}
