import React, { useState, useEffect } from "react";

import { useDispatch } from "react-redux";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { Field } from "formik";

// Action
import { fetchList } from "../../../../actions/table";

// Components
import toast from "../../../../components/base/Toast";
import Form from "../../../../components/base/Form";
import Text from "../../../../components/Text";
import Number from "../../../../components/base/Number";
import Select from "../../../../components/base/Select";
import AddButton from "../../../../components/base/AddButton";
import SingleCheckbox from "../../../../components/base/SingleCheckbox";
import PartnerType from "../../../../components/PartnerType";
import ToggleSwitch from "../../../../components/base/ToggleSwitch";

// Configs
import { endpoints } from "../../../../configs";
import { apiClient } from "../../../../apiClient";
import Currency from "../../../../components/base/Currency";

// Constants
import {
  PARTNER_TIER_QUOTA_NO_QUOTA,
  PARTNER_TIER_QUOTA_YEARLY_REVENUE,
  PARTNER_TIER_QUOTA_QUATERLY_REVENUE,
  PARTNER_TIER_QUOTA_YEARLY_DEAL_COUNT,
  PARTNER_TIER_QUOTA_QUATERLY_DEAL_COUNT,
  ENABLE_TIER_VISIBILITY,
  DISABLE_TIER_VISIBILITY
} from "../../../../setting/Constants";

// Common
import { isBadRequest } from "../../../../common/http";

const AddPartnerTiersBtn = props => {
  const {
    setOpenPartnerTier,
    openPartnerTier,
    tierId,
    tierName,
    tierCommission,
    amount,
    type,
    selectedType,
    setTierId,
    setTierName,
    setTierCommission,
    setAmount,
    setType,
    setPartnerType,
    setIsDefault,
    isDefault,
    setSelectedType,
    partnerType,
    partnerTypeName,
    partnerTypeId,
    getPartnerTierList,
    setVisibility,
    visibility
  } = props;

  // States
  const [partnerTypeList, setPartnerTypeList] = useState(false);

  // Use Effect
  useEffect(() => {
    getSelectedTypes(partnerType);
    setSelectedType(type ? type : "");
  }, [partnerType, tierName]);

  // Dispatch
  const dispatch = useDispatch();

  const handleTypeChange = selectedTypeObject => {
    if (selectedTypeObject && selectedTypeObject.value) {
      setSelectedType(selectedTypeObject.value);

      setAmount("");
    }
  };

  // Quota options
  const quotaTypeOptions = [
    {
      value: PARTNER_TIER_QUOTA_NO_QUOTA,

      label: PARTNER_TIER_QUOTA_NO_QUOTA
    },

    {
      value: PARTNER_TIER_QUOTA_YEARLY_REVENUE,

      label: PARTNER_TIER_QUOTA_YEARLY_REVENUE
    },

    {
      value: PARTNER_TIER_QUOTA_QUATERLY_REVENUE,

      label: PARTNER_TIER_QUOTA_QUATERLY_REVENUE
    },

    {
      value: PARTNER_TIER_QUOTA_YEARLY_DEAL_COUNT,

      label: PARTNER_TIER_QUOTA_YEARLY_DEAL_COUNT
    },

    {
      value: PARTNER_TIER_QUOTA_QUATERLY_DEAL_COUNT,

      label: PARTNER_TIER_QUOTA_QUATERLY_DEAL_COUNT
    }
  ];

  // Partner tier toggle modal
  const _partnerTierToggle = data => {
    setTierId((data && data.id) || "");
    setTierName((data && data.name) || "");
    setTierCommission((data && data.percentage) || "");
    setAmount((data && data.quota_amount) || "");
    setVisibility(data && data.visibility);
    setType((data && data.quota_type) || "");
    setPartnerType((data && data.partner_type) || "");
    setOpenPartnerTier(!openPartnerTier);
    setIsDefault((data && data.isDefault) || "");
    setSelectedType("");
    setPartnerTypeList("");
  };

  /**
   * Create partner tier
   * @param data
   * @param id
   */

  const partnerTierCreate = async data => {
    try {
      const response = await apiClient.post(
        `${endpoints().partnerTierAPI}`,
        data
      );

      if (response && response.data) {
        toast.success(response.data.message);
        getPartnerTierList();
        _partnerTierToggle();
        // Update partner tier list
        dispatch(
          fetchList(
            "partnerTiers",
            `${endpoints().partnerTierAPI}/search`,
            1,
            10
          )
        );

        setTimeout(() => {
          _partnerTierToggle();
        }, 100);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        toast.error(error.response.data.message);
      }
    }
  };

  /**
   * Update partner tier
   * @param Data
   * @param id
   */

  const partnerTierUpdate = async (data, id) => {
    try {
      const response = await apiClient.put(
        `${endpoints().partnerTierAPI}/${id}`,
        data
      );

      if (response && response.data) {
        toast.success(response.data.message);
        getPartnerTierList();
        // Update partner tier list
        dispatch(
          fetchList(
            "partnerTiers",
            `${endpoints().partnerTierAPI}/search`,
            1,
            10
          )
        );

        setTimeout(() => {
          _partnerTierToggle();
        }, 100);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        toast.error(error.response.data.message);
      }
    }
  };

  //checking partnertype ids
  const checkType = (typeId, typeList) => {
    let result = false;
    typeList.forEach(type => {
      if (type.id == typeId) {
        result = true;
      }
    });

    return result;
  };
  // get partnerType
  const getSelectedTypes = types => {
    const typeInitialValues = {};
    const selectedTypes = [];
    apiClient
      .get(`${endpoints().partnerTypeAPI}/search`)
      .then(response => {
        if (response.data && response.data.data) {
          const typeList = response.data.data;

          if (types && types.length > 0) {
            types.forEach(type => {
              if (checkType(type.id, typeList)) {
                selectedTypes.push({
                  value: type.id,
                  label: type.name
                });
              }
            });
          }
          setPartnerTypeList(selectedTypes);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
    setPartnerTypeList(selectedTypes);
  };

  // Render the partner tier modal
  const partnerTierModal = () => (
    <div>
      <Modal
        isOpen={openPartnerTier}
        toggle={_partnerTierToggle}
        className="landing-create-popup w-100"
        backdrop="static"
      >
        <ModalHeader
          toggle={_partnerTierToggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <p>{tierId ? "Edit Partner Tier" : "Add Partner Tier"}</p>
        </ModalHeader>

        <Form
          initialValues={{
            name: tierName || "",
            partnerType: partnerTypeList,
            percentage: tierCommission || "",
            quotaAmount: amount || "",
            isDefault: isDefault || "",
            visibility: visibility === 1 ? true : false,
            quotaType: type
              ? quotaTypeOptions.find(
                  quotaTypeOptionDetails =>
                    quotaTypeOptionDetails.value === type
                )
              : ""
          }}
          onSubmit={values => {
            let partnerType = [];
            const data = new FormData();
            data.append("name", values.name.trim());
            data.append(
              "quotaAmount",
              values.quotaAmount
                ? selectedType === PARTNER_TIER_QUOTA_NO_QUOTA
                  ? 0
                  : values.quotaAmount
                : ""
            );
            data.append(
              "visibility",
              values.visibility === true
                ? ENABLE_TIER_VISIBILITY
                : DISABLE_TIER_VISIBILITY
            );

            data.append(
              "type",
              (values.quotaType &&
                values.quotaType.value &&
                values.quotaType.value) ||
                ""
            );
            values &&
              values.partnerType &&
              values.partnerType.forEach(data => {
                if (data) {
                  partnerType.push({
                    partnerType: data.value
                  });
                }
              });
            data.append(
              "percentage",
              (values.percentage && values.percentage) || ""
            );
            data.append("partnerType", JSON.stringify(partnerType) || "");
            data.append("isDefault", values && values.isDefault ? 1 : 0);
            tierId ? partnerTierUpdate(data, tierId) : partnerTierCreate(data);
          }}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-5">
            <div>
              <Text
                name="name"
                label="Partner Tier Name"
                placeholder="Enter partner tier name"
                error=""
                required={true}
                style={{}}
              />
              <PartnerType
                isMulti
                name="partnerType"
                label="Partner Type"
                placeholder="Select type"
                isSearchable={true}
                error=""
                required={true}
              />

              <Number
                label="Commission(%)"
                placeholder="Enter commission percentage"
                name="percentage"
                required={false}
                error={""}
              />

              <Select
                label="Quota Type"
                placeholder="Enter quota type"
                name="quotaType"
                options={quotaTypeOptions}
                error={""}
                handleChange={e => handleTypeChange(e)}
              />

              {selectedType && selectedType !== PARTNER_TIER_QUOTA_NO_QUOTA && (
                <>
                  <Currency
                    label="Quota Amount"
                    placeholder="Enter quota amount"
                    name="quotaAmount"
                    required={false}
                    error={""}
                  />
                </>
              )}

              <SingleCheckbox
                name="isDefault"
                label="Make as default"
                className="accepted-terms mx-1"
              />
              <div className="position-absolute mx-0 mt-4 row">
                <Field
                  name="visibility"
                  render={({ field, form }) => {
                    return (
                      <ToggleSwitch
                        name={"visibislity"}
                        label2={"Show In Public Profile"}
                        label={"Show In Public Profile"}
                        value={field.value}
                        handleChange={() => {
                          // handleVisibility(!field.value);
                          form.setFieldValue("visibility", !field.value);
                        }}
                        outlined
                      />
                    );
                  }}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="">
                  {tierId ? "Save" : "Add"}
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );

  return (
    <>
      {partnerTierModal()}

      <AddButton
        label="Add New"
        className="btn btn-secondary mb-4"
        onClick={() => {
          _partnerTierToggle();
        }}
      />
    </>
  );
};

export default AddPartnerTiersBtn;
