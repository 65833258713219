import React from "react";
import { RocketGraphicIcon } from "../../../assets/img/icons";
import OnboardingLink from "./OnboardingLink";

const ExpertProStatus = () => {
  const percentage = 100;
  return (
    <div className="expert-onboarding">
      <OnboardingLink
        icon={<RocketGraphicIcon />}
        title={"Hey Karl. Great job so far!"}
        description={"You are currently a Certified Pro Expert."}
        availability={{
          status: "available",
          text: "You’re Available 10+ Hours Per Week"
        }}
        progress={percentage}
        expertStatus={{
          accepted: true,
          experttype: "pro"
        }}
      />
    </div>
  );
};

export default ExpertProStatus;
