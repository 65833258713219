import {
  REQUEST_CREATE_REFERRAL_STATUS,
  RECEIVE_CREATE_REFERRAL_STATUS,
  REFERRAL_STATUS_CREATE_ERROR,
  REQUEST_UPDATE_REFERRAL_STATUS,
  RECEIVE_UPDATE_REFERRAL_STATUS,
  REFRRAL_STATUS_UPDATE_ERROR,
  REQUEST_DELETE_REFERRAL_STATUS,
  RECEIVE_DELETE_REFERRAL_STATUS,
  REFERRAL_STATUS_DELETE_ERROR
} from "../actionType/Constants";
import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import toast from "../components/base/Toast";
import { fetchList } from "./table";
import { isBadRequest } from "../common/http";

/**
 * Request for deleting status
 */
export function requestDeleteReferralStatus() {
  return {
    type: REQUEST_DELETE_REFERRAL_STATUS
  };
}

/**
 * Receive for deleting status
 */
export function receiveDeleteReferralStatus() {
  return {
    type: RECEIVE_DELETE_REFERRAL_STATUS
  };
}

/**
 * Receive for error deleting status
 */
export function referralStatusDeleteError(error) {
  return {
    type: REFERRAL_STATUS_DELETE_ERROR,
    error
  };
}

/**
 * Delete status
 *
 * @param id
 * @returns {function(*): *}
 */
export function deleteReferralStatus(id) {
  return dispatch => {
    dispatch(requestDeleteReferralStatus());

    apiClient
      .delete(`${endpoints().referralStatusAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList("referralStatus", `${endpoints().referralStatusAPI}`, 1, 10)
        );
      })
      .catch(error => {
        dispatch(referralStatusDeleteError(error));
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}

/**
 * Request for creating status
 */
export function requestCreateReferralStatus() {
  return {
    type: REQUEST_CREATE_REFERRAL_STATUS
  };
}

/**
 * Receive for receive status
 */
export function receiveCreateReferralStatus() {
  return {
    type: RECEIVE_CREATE_REFERRAL_STATUS
  };
}

/**
 * Receive for error creating status
 */
export function ReferralStatusCreateError(error) {
  return {
    type: REFERRAL_STATUS_CREATE_ERROR,
    error
  };
}

/**
 * Create status
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function addReferralStatus(data) {
  return dispatch => {
    dispatch(requestCreateReferralStatus());

    return apiClient
      .post(`${endpoints().referralStatusAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList("referralStatus", `${endpoints().referralStatusAPI}`, 1, 10)
        );
        dispatch(receiveCreateReferralStatus());
      })
      .catch(error => {
        dispatch(ReferralStatusCreateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Request for updating status
 */
export function requestUpdateReferralStatus() {
  return {
    type: REQUEST_UPDATE_REFERRAL_STATUS
  };
}

/**
 * Receive for updating status
 */
export function receiveUpdateReferralStatus() {
  return {
    type: RECEIVE_UPDATE_REFERRAL_STATUS
  };
}

/**
 * Receive for error updating status
 */
export function ReferralStatusUpdateError(error) {
  return {
    type: REFRRAL_STATUS_UPDATE_ERROR,
    error
  };
}

/**
 * Update status details
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateReferralStatus(id, data) {
  return dispatch => {
    dispatch(requestUpdateReferralStatus());
    apiClient
      .put(`${endpoints().referralStatusAPI}/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList("referralStatus", `${endpoints().referralStatusAPI}`, 1, 10)
        );
        dispatch(receiveCreateReferralStatus());
      })
      .catch(error => {
        dispatch(ReferralStatusUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}
