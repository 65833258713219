import { endpoints } from "../configs";
import { apiClient } from "../apiClient";

class SlackService {
  static async connect() {
    const response = await apiClient.post(`${endpoints().slackAPI}/auth`);
    if (response !== undefined) {
      return response && response.data;
    }
  }
}

export default SlackService;
