import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CancelButton from "../../../../components/base/CancelButton";

const DeleteTaskModal = ({
  modal,
  setModal,
  taskIndex,
  id,
  deleteProjectTasks,
  deleteTask,
  taskName,
  handleShowUploader
}) => {
  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={setModal}>
        <h4 className={["font-weight-bold"].join(" ")}>
          Delete Task / Deliverable
        </h4>
      </ModalHeader>
      <ModalBody className={["text-center", "mb-4"].join(" ")}>
        <p>
          Are you sure you want to delete <br />
          <b>"{taskName}"</b>?
        </p>
      </ModalBody>
      <ModalFooter className={["justify-content-center"].join(" ")}>
        <div className="btn-wrapper">
          <CancelButton onClick={() => setModal(prevState => !prevState)} />
          <button
            className="btn btn-danger"
            onClick={() => {
              setModal(prevState => !prevState);
              deleteProjectTasks(id);
              deleteTask(taskIndex);
              handleShowUploader(true);
            }}
          >
            Yes, Delete
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default DeleteTaskModal;
