import React, { useState } from "react";
import { Vendor } from "../../Constants/coSellVendors";
import Pagination from "../../components/base/Pagination";
import { MicrosoftIcon, GoogleIcon, AmazonIcon } from "../../assets/img/icons";
import NoRecordsFound from "../../components/base/NoRecordsFound";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
const Table = props => {
  let startPage = 1;
  let endPage = "";
  startPage = (props.detail.currentPage - 1) * props.detail.pageSize;
  startPage =
    startPage > props.detail.totalCount ? props.detail.totalCount : startPage;

  endPage = props.detail.currentPage * props.detail.pageSize;
  endPage =
    endPage > props.detail.totalCount ? props.detail.totalCount : endPage;

  return (
    <div
      className="container-fluid site-maincontent p-0"
      style={{ overflowX: "auto" }}
    >
      {props.detail && props.detail.data && props.detail.data.length > 0 ? (
        <table style={{ borderCollapse: "collapse", width: "100%" }}>
          <thead>
            <tr style={{ backgroundColor: "#e7e7ea" }}>
              <th
                colSpan="3"
                style={{
                  border: "1px solid black"
                }}
              >
                <p className="text-center m-0"> TECHNOLOGY </p>
              </th>
              <th
                colSpan="1"
                style={{
                  border: "1px solid black",
                  borderBottomColor: "transparent",
                  cursor: "pointer"
                }}
              >
                <div
                  className="text-center m-0 mt-3"
                  onClick={() => props.handleSort("domain")}
                >
                  COMPANY
                  {props.sortOrder == "asc" && props.sortParam == "domain" ? (
                    <div className="fa fa-sort-asc mx-1"> </div>
                  ) : (
                    <div className="fa fa-sort-desc mx-1"> </div>
                  )}
                </div>
              </th>
              <th
                colSpan="6"
                style={{
                  border: "1px solid black"
                }}
              >
                <p className="text-center m-0"> INTENT BY WEEK </p>
              </th>
            </tr>
            <tr
              style={{
                textAlign: "center",
                backgroundColor: "#e7e7ea",
                cursor: "pointer"
              }}
            >
              <th
                style={{
                  border: "1px solid black",
                  width: "50px"
                }}
              >
                <div
                  onClick={() => props.handleSort(Vendor.AMAZON)}
                  className="d-flex justify-content-center"
                >
                  <AmazonIcon />
                  {props.sortOrder == "asc" && props.sortParam == "amazon" ? (
                    <div className="fa fa-sort-asc mx-1"> </div>
                  ) : (
                    <div className="fa fa-sort-desc mx-1"> </div>
                  )}
                </div>
              </th>
              <th
                style={{
                  border: "1px solid black",
                  width: "50px"
                }}
              >
                <div
                  onClick={() => props.handleSort(Vendor.GOOGLE)}
                  className="d-flex justify-content-center"
                >
                  <GoogleIcon />
                  {props.sortOrder == "asc" && props.sortParam == "google" ? (
                    <div className="fa fa-sort-asc mx-1"> </div>
                  ) : (
                    <div className="fa fa-sort-desc mx-1"> </div>
                  )}
                </div>
              </th>
              <th
                style={{
                  border: "1px solid black",
                  width: "50px"
                }}
              >
                <div
                  onClick={() => props.handleSort(Vendor.MICROSOFT)}
                  className="d-flex justify-content-center"
                >
                  <MicrosoftIcon />
                  {props.sortOrder == "asc" &&
                  props.sortParam == "microsoft" ? (
                    <div className="fa fa-sort-asc mx-1"> </div>
                  ) : (
                    <div className="fa fa-sort-desc mx-1"> </div>
                  )}
                </div>
              </th>
              <th
                style={{ border: "1px solid black" }}
                onClick={() => props.handleSort("domain")}
              >
                {/* Company header content here */}
              </th>

              <th
                style={{
                  border: "1px solid black",
                  minWidth: "50px"
                }}
                onClick={() => props.handleSort("week1")}
              >
                <div className="d-flex justify-content-center">
                  1
                  {props.sortOrder == "asc" && props.sortParam == "week1" ? (
                    <div
                      className="fa fa-sort-asc mx-1"
                      style={{ marginTop: "6px" }}
                    >
                      {" "}
                    </div>
                  ) : (
                    <div
                      className="fa fa-sort-desc mx-1"
                      style={{ marginTop: "2px" }}
                    >
                      {" "}
                    </div>
                  )}
                </div>
              </th>
              <th
                style={{
                  border: "1px solid black",
                  minWidth: "50px"
                }}
                onClick={() => props.handleSort("week2")}
              >
                <div className="d-flex justify-content-center">
                  2
                  {props.sortOrder == "asc" && props.sortParam == "week2" ? (
                    <div
                      className="fa fa-sort-asc mx-1"
                      style={{ marginTop: "6px" }}
                    >
                      {" "}
                    </div>
                  ) : (
                    <div
                      className="fa fa-sort-desc mx-1"
                      style={{ marginTop: "2px" }}
                    >
                      {" "}
                    </div>
                  )}
                </div>
              </th>
              <th
                style={{
                  border: "1px solid black",
                  minWidth: "50px"
                }}
                onClick={() => props.handleSort("week3")}
              >
                <div className="d-flex justify-content-center">
                  3
                  {props.sortOrder == "asc" && props.sortParam == "week3" ? (
                    <div
                      className="fa fa-sort-asc mx-1"
                      style={{ marginTop: "6px" }}
                    >
                      {" "}
                    </div>
                  ) : (
                    <div
                      className="fa fa-sort-desc mx-1"
                      style={{ marginTop: "2px" }}
                    >
                      {" "}
                    </div>
                  )}
                </div>
              </th>
              <th
                style={{
                  border: "1px solid black",
                  minWidth: "50px"
                }}
                onClick={() => props.handleSort("week4")}
              >
                <div className="d-flex justify-content-center">
                  4
                  {props.sortOrder == "asc" && props.sortParam == "week4" ? (
                    <div
                      className="fa fa-sort-asc mx-1"
                      style={{ marginTop: "6px" }}
                    >
                      {" "}
                    </div>
                  ) : (
                    <div
                      className="fa fa-sort-desc mx-1"
                      style={{ marginTop: "2px" }}
                    >
                      {" "}
                    </div>
                  )}
                </div>
              </th>
              <th
                style={{
                  border: "1px solid black",
                  minWidth: "50px"
                }}
                onClick={() => props.handleSort("week5")}
              >
                <div className="d-flex justify-content-center">
                  5
                  {props.sortOrder == "asc" && props.sortParam == "week5" ? (
                    <div
                      className="fa fa-sort-asc mx-1"
                      style={{ marginTop: "6px" }}
                    >
                      {" "}
                    </div>
                  ) : (
                    <div
                      className="fa fa-sort-desc mx-1"
                      style={{ marginTop: "2px" }}
                    >
                      {" "}
                    </div>
                  )}
                </div>
              </th>

              <th
                style={{ border: "1px solid black", minWidth: "50px" }}
                onClick={() => props.handleSort("week6")}
              >
                <div className="d-flex justify-content-center">
                  6
                  {props.sortOrder == "asc" && props.sortParam == "week6" ? (
                    <div
                      className="fa fa-sort-asc mx-1"
                      style={{ marginTop: "6px" }}
                    >
                      {" "}
                    </div>
                  ) : (
                    <div
                      className="fa fa-sort-desc mx-1"
                      style={{ marginTop: "2px" }}
                    >
                      {" "}
                    </div>
                  )}
                </div>
              </th>
            </tr>
          </thead>
          <tbody style={{ height: "50px", overflowX: "auto" }}>
            {props.detail &&
              props.detail.data &&
              props.detail.data.length > 0 &&
              props.detail.data.map((value, index) => (
                <tr key={index} style={{ textAlign: "center", height: "50px" }}>
                  {/* Amazon column */}

                  <td style={{ border: "1px solid black", minWidth: "50px" }}>
                    {value && value.amazon && (
                      <span
                        key={index}
                        style={{
                          color:
                            value.amazon === "H"
                              ? "green"
                              : value.amazon === "M"
                              ? "blue"
                              : value.amazon === "L"
                              ? "red"
                              : "inherit",
                          fontWeight: 400
                        }}
                      >
                        {value.amazon}
                      </span>
                    )}
                  </td>

                  {/* Google column */}
                  <td style={{ border: "1px solid black", minWidth: "50px" }}>
                    {value && value.google && (
                      <span
                        key={index}
                        style={{
                          color:
                            value.google === "H"
                              ? "green"
                              : value.google === "M"
                              ? "blue"
                              : value.google === "L"
                              ? "red"
                              : "inherit",
                          fontWeight: 400
                        }}
                      >
                        {value.google}
                      </span>
                    )}
                  </td>
                  {/* Microsoft column */}
                  <td style={{ border: "1px solid black", minWidth: "50px" }}>
                    {value && value.microsoft && (
                      <span
                        key={index}
                        style={{
                          color:
                            value.microsoft === "H"
                              ? "green"
                              : value.microsoft === "M"
                              ? "blue"
                              : value.microsoft === "L"
                              ? "red"
                              : "inherit",
                          fontWeight: 400
                        }}
                      >
                        {value.microsoft}
                      </span>
                    )}
                  </td>

                  {/* Domain column */}
                  <td
                    style={{
                      border: "1px solid black",
                      minWidth: "300px",
                      textAlign: "left",
                      padding: "10px"
                    }}
                  >
                    <Link to={`/co-sell/detail/${value && value.id}`}>
                      {value && value.domain ? value.domain : "-"}
                    </Link>
                  </td>

                  {/* Intent Strength column */}
                  <td style={{ border: "1px solid black" }}>
                    {value && value.week1 && (
                      <span
                        key={index}
                        style={{
                          color:
                            value.week1 === "H"
                              ? "green"
                              : value.week1 === "M"
                              ? "blue"
                              : value.week1 === "L"
                              ? "red"
                              : "inherit",
                          fontWeight: 400
                        }}
                      >
                        {value.week1}
                      </span>
                    )}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {value && value.week2 && (
                      <span
                        key={index}
                        style={{
                          color:
                            value.week2 === "H"
                              ? "green"
                              : value.week2 === "M"
                              ? "blue"
                              : value.week2 === "L"
                              ? "red"
                              : "inherit",
                          fontWeight: 400
                        }}
                      >
                        {value.week2}
                      </span>
                    )}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {value && value.week3 && (
                      <span
                        key={index}
                        style={{
                          color:
                            value.week3 === "H"
                              ? "green"
                              : value.week3 === "M"
                              ? "blue"
                              : value.week3 === "L"
                              ? "red"
                              : "inherit",
                          fontWeight: 400
                        }}
                      >
                        {value.week3}
                      </span>
                    )}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {value && value.week4 && (
                      <span
                        key={index}
                        style={{
                          color:
                            value.week4 === "H"
                              ? "green"
                              : value.week4 === "M"
                              ? "blue"
                              : value.week4 === "L"
                              ? "red"
                              : "inherit",
                          fontWeight: 400
                        }}
                      >
                        {value.week4}
                      </span>
                    )}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {value && value.week5 && (
                      <span
                        key={index}
                        style={{
                          color:
                            value.week5 === "H"
                              ? "green"
                              : value.week5 === "M"
                              ? "blue"
                              : value.week5 === "L"
                              ? "red"
                              : "inherit",
                          fontWeight: 400
                        }}
                      >
                        {value.week5}
                      </span>
                    )}
                  </td>
                  <td style={{ border: "1px solid black" }}>
                    {value && value.week6 && (
                      <span
                        key={index}
                        style={{
                          color:
                            value.week6 === "H"
                              ? "green"
                              : value.week6 === "M"
                              ? "blue"
                              : value.week6 === "L"
                              ? "red"
                              : "inherit",
                          fontWeight: 400
                        }}
                      >
                        {value.week6}
                      </span>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      ) : (
        <NoRecordsFound />
      )}
      <div className="mt-1 ">
        {props.detail.totalCount > 0 && (
          <div className="d-flex justify-content-between">
            <div className="col-lg-10 col-5 p-0">
              Showing {startPage + 1} to {endPage} of {props.detail.totalCount}{" "}
              entries
            </div>
            <div className="col-12 col-sm-2 col-lg-1 col-md-5  p-0">
              <Pagination
                currentPage={props.detail.currentPage}
                totalCount={props.detail.totalCount}
                pageSize={props.detail.pageSize}
                onPageChange={props.handlePageChange}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Table;
