import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

// Components
import Avatar from "../components/base/Avatar";
import { COLOR_GREY } from "../common/color";
import { SIZE_SMALLER } from "../common/size";

// Assets
import { UserIcon } from "../assets/img/icons";

import MoreDropdown from "../components/base/MoreDropdown";
import { DropdownItem, Label } from "reactstrap";
import moment from "moment";
import DateLabel from "../components/base/DateLabel";

import detectBrowser from "../components/helpers/detectBrowser";

// Configs
import { endpoints } from "../configs";
import { apiClient } from "../apiClient";

// Helper
import { getCookie, getUserId } from "../lib/helper";
import { COOKIE_SHOW_ASSIGNED_PARTNERS } from "../lib/cookie";

import { REFERRAL_LINK_CLICKED } from "../reports/Constants";

import { REFERRAL_SUBMITTED_FROM_LINK_TRACKING } from "../referral/Constants";
import Terminology from "../lib/Terminology";
import SystemName from "../Constants/SystemName";
import { GetCurrencySymbol } from "../lib/CurrencySymbol";

// Hook
function useHover() {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}

const ProgramCard = props => {
  const { history } = props;
  const [hoverRef, isHovered] = useHover();
  const [isMobile] = useState(detectBrowser());

  const {
    approvedPartners,
    pendingPartners,
    declinedPartners,
    totalPartners,
    type,
    activity,
    toggle,
    handleStatusChange,
    partnerTypeId,
    actions,
    partnerList,
    referralStatistics
  } = props;

  const typeId = partnerTypeId;

  let recentActivity = activity && activity[0];

  const getArray = async () => {
    let partnerlists = [];

    if (type) {
      let data = type.trim();
      await apiClient
        .get(`${endpoints().partnerAPI}/partnerType/type?type=${data}`)
        .then(response => {
          if (response.data.data && response.data.data.length) {
            let res = response && response.data;
            partnerlists.push(res);
          }
        });
    }
    return partnerlists;
  };

  // navigate to approved partners list
  const navigationToApprovedPartners = () => {
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    const managerUserId = getUserId();
    // Params
    const params = {
      status: "Approved",
      partnerType: typeId
    };

    if (checked_status) {
      params.managerUserId = managerUserId;
    }

    toggle("all-partners");
    handleStatusChange("all-partners", "Approved", typeId);
    actions.fetchList(
      "all-partners",
      `${endpoints().partnerAPI}/search`,
      1,
      10,
      {
        ...params
      }
    );
  };

  // navigate to Pending partners list
  const navigationToPendingPartners = () => {
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    const managerUserId = getUserId();
    // Params
    const params = {
      status: "Pending",
      partnerType: typeId
    };

    if (checked_status) {
      params.managerUserId = managerUserId;
    }
    if (checked_status) {
      params.managerUserId = managerUserId;
    }

    toggle("all-partners");
    handleStatusChange("all-partners", "Pending", typeId);
    actions.fetchList(
      "all-partners",
      `${endpoints().partnerAPI}/search`,
      1,
      10,
      {
        ...params
      }
    );
  };

  // navigate to declined partners list
  const navigationToDeclinedPartners = () => {
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    const managerUserId = getUserId();
    // Params

    const params = {
      status: "Declined",
      partnerType: typeId
    };

    if (checked_status) {
      params.managerUserId = managerUserId;
    }
    toggle("all-partners");
    handleStatusChange("all-partners", "Declined", typeId);
    actions.fetchList(
      "all-partners",
      `${endpoints().partnerAPI}/search`,
      1,
      10,
      {
        ...params
      }
    );
  };

  // navigate to all partners list
  const navigationToAllPartners = () => {
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    const managerUserId = getUserId();
    // Params
    const params = {
      partnerType: typeId
    };

    if (checked_status) {
      params.managerUserId = managerUserId;
    }

    toggle("all-partners");
    handleStatusChange("all-partners", "", typeId);
    actions.fetchList(
      "all-partners",
      `${endpoints().partnerAPI}/search`,
      1,
      10,
      {
        ...params
      }
    );
  };
  return (
    <>
      <div className="project-list">
        <div
          className={`project d-flex justify-content-start text-decoration-none ${
            isHovered ? "shadow-none" : ""
          }`}
          // ref={hoverRef}
        >
          <div className="project-data d-flex flex-column w-100">
            <div
              className={`${
                recentActivity ? "partner-program" : "p-3"
              } d-flex justify-content-between`}
            >
              <div className="project-finances">
                <label style={{ fontWeight: "bold", fontSize: 18 }}>
                  {type}
                </label>
                {/* {partnerList.filter(data => {})} */}
                {recentActivity ? (
                  <div className="project-finances mt-4 ml-2 mb-1">
                    <label style={{ fontWeight: "bold", fontSize: 14 }}>
                      {"Recent Activity"}
                    </label>

                    <div className="single-activity accordion accordion-flush">
                      <div className="accordion-item">
                        <div
                          className="dropdown-wrapper mr-auto"
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            margin: "6px 6px"
                          }}
                        >
                          <Avatar
                            id={
                              recentActivity.id
                                ? recentActivity.id
                                : recentActivity.id
                                ? recentActivity.partner.first_name
                                : recentActivity.userName
                            }
                            firstName={
                              recentActivity.company_name
                                ? recentActivity.company_name
                                : recentActivity.company_name
                            }
                            url={
                              recentActivity && recentActivity.avatarUrl
                                ? recentActivity.avatarUrl
                                : ""
                            }
                            size="xs"
                          ></Avatar>

                          <div
                            className="activity-line "
                            style={{ left: "2%" }}
                          ></div>

                          <div>
                            <h5
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                fontSize: 14,
                                fontWeight: "bold",
                                margin: "0 1rem"
                              }}
                            >
                              {recentActivity.activityType
                                ? recentActivity.activityType
                                : ""}
                            </h5>
                            <Label
                              style={{
                                display: "flex",
                                margin: "0 1rem"
                              }}
                            >
                              {recentActivity && recentActivity.company_name
                                ? recentActivity.company_name
                                : ""}
                            </Label>
                            <DateLabel className="px-2">
                              {recentActivity.createdAt}
                            </DateLabel>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
              <div className={!isMobile && "project-progress"}>
                <div className="field-wrapper">
                  <div className="d-flex align-items-center">
                    <div className="field-icon">
                      <UserIcon />
                    </div>
                    <Link
                      style={{ color: "black" }}
                      onClick={navigationToAllPartners}
                    >
                      <p className="font-weight-bold ml-1 mr-7">
                        {totalPartners}
                        <span className="text-grayed font-weight-bold">
                          Partners
                        </span>
                      </p>
                    </Link>
                    <p className="font-weight-bold ml-1 mr-7">
                      {""}
                      <span className="text-grayed font-weight-bold">{""}</span>
                    </p>
                    <div className="text-center landing-group-dropdown">
                      <MoreDropdown>
                        <Link
                          to={`/admin-settings/Partner/Types/${typeId}`}
                          style={{
                            color: "inherit",
                            textDecoration: "none"
                          }}
                        >
                          <DropdownItem>Edit</DropdownItem>
                        </Link>
                      </MoreDropdown>
                    </div>
                  </div>
                </div>
                {approvedPartners ? (
                  <div className="field-wrapper">
                    <div className="d-flex align-items-center">
                      <div className="field-icon">
                        <UserIcon />
                      </div>
                      <Link
                        style={{ color: "black" }}
                        onClick={navigationToApprovedPartners}
                      >
                        <p className="font-weight-bold ml-1">
                          {approvedPartners || 0}
                          <span className="text-grayed font-weight-bold">
                            Approved Partners
                          </span>
                        </p>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {pendingPartners ? (
                  <div className="field-wrapper">
                    <div className="d-flex align-items-center">
                      <div className="field-icon">
                        <UserIcon />
                      </div>
                      <Link
                        style={{ color: "black" }}
                        onClick={navigationToPendingPartners}
                      >
                        <p className="font-weight-bold ml-1">
                          {pendingPartners || 0}
                          <span className="text-grayed font-weight-bold">
                            Pending Partners
                          </span>
                        </p>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
                {declinedPartners ? (
                  <div className="field-wrapper">
                    <div className="d-flex align-items-center">
                      <div className="field-icon">
                        <UserIcon />
                      </div>
                      <Link
                        style={{ color: "black" }}
                        onClick={navigationToDeclinedPartners}
                      >
                        <p className="font-weight-bold ml-1">
                          {declinedPartners}
                          <span className="text-grayed font-weight-bold">
                            Declined Partners
                          </span>
                        </p>
                      </Link>
                    </div>
                  </div>
                ) : (
                  ""
                )}
              </div>
            </div>
            <div className="partner-program">
              <div className="ml-2 mb-1">
                <label style={{ fontWeight: "bold", fontSize: 14 }}>
                  {`${Terminology.get(SystemName.REFERRAL)} Statistics`}
                </label>
              </div>

              <div className="d-flex flex-row justify-content-between">
                <div className="d-flex flex-column align-items-center">
                  <Link
                    className="text-decoration-none"
                    to={`/reports/partner-activities?activityType=${REFERRAL_LINK_CLICKED}&typeId=${partnerTypeId}`}
                    style={{ cursor: "pointer" }}
                  >
                    <p>
                      {referralStatistics &&
                        referralStatistics.referralLinkClickedCount}
                    </p>
                  </Link>
                  <p>Clicked Count</p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <Link
                    className="text-decoration-none"
                    to={`/referrals?typeId=${partnerTypeId}&submittedFrom=${REFERRAL_SUBMITTED_FROM_LINK_TRACKING}`}
                    style={{ cursor: "pointer" }}
                  >
                    <p>
                      {referralStatistics &&
                        referralStatistics.referralSubmmittedCount}
                    </p>
                  </Link>
                  <p>{Terminology.get(SystemName.REFERRAL)} Submission Count</p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="text-primary">
                    {`${GetCurrencySymbol()}${referralStatistics &&
                      referralStatistics.averageReferralSize}`}
                  </p>{" "}
                  <p>Average {Terminology.get(SystemName.REFERRAL)} Size</p>
                </div>
                <div className="d-flex flex-column align-items-center">
                  <p className="text-primary">
                    {`${GetCurrencySymbol()}${referralStatistics &&
                      referralStatistics.totalReferralValue}`}
                  </p>{" "}
                  <p>Total {Terminology.get(SystemName.REFERRAL)} Value</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ProgramCard;
