import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// Components
import StatisticsCountCard from "../../components/base/StatisticsCountCard";

// Actions
import { fetchCustomerDashboardDetails } from "../../actions/dashboard";
import { GetCurrencySymbol } from "../../lib/CurrencySymbol";

const CustomersStatisticsCards = () => {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(fetchCustomerDashboardDetails());
  }, []);

  const customerDashboard = dashboard.customerDashboard;

  return (
    <div className={"card-statistics"}>
      {customerDashboard && (
        <>
          {/* Show Pending Project Count */}
          <StatisticsCountCard
            count={customerDashboard && customerDashboard.pendingProjectsCount}
            label="Pending Projects"
            redirectUrl="/projects"
            className={"text-white"}
          />

          {/* Show Active Projects Count */}
          <StatisticsCountCard
            count={customerDashboard && customerDashboard.activeProjectsCount}
            label="Active Projects"
            redirectUrl="/projects"
            className={"text-white"}
          />

          {/* Show Completed Projects Count */}
          <StatisticsCountCard
            count={
              customerDashboard && customerDashboard.completedProjectsCount
            }
            label="Completed Projects"
            redirectUrl="/projects?status=Completed"
            className={"text-white"}
          />

          {/* Show Balance Due Count */}
          <StatisticsCountCard
            count={customerDashboard && customerDashboard.balanceDue}
            label="Balance Due"
            redirectUrl="/billings"
            prefix={GetCurrencySymbol()}
            className={"text-white"}
          />
        </>
      )}
    </div>
  );
};

export default CustomersStatisticsCards;
