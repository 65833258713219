// Api Call
import { apiClient } from "../apiClient";
import { endpoints } from "../configs";

// Config
import { DEFAULT_API_KEY } from "../configs";

// Helper
import { getCookie } from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

// Constants
import {
  REQUEST_COURSE_LIST,
  RECEIVE_COURSE_LIST,
  RESET_COURSE_LIST,
  FETCH_COURSE_LIST_FAIL,
  CHANGE_COURSE_PAGE
} from "../actionType/Constants";

/**
 * Request Course
 */
export function requestCourseList() {
  return {
    type: REQUEST_COURSE_LIST
  };
}

/**
 * Receive Course
 */
export function receiveCourseList(data) {
  return {
    type: RECEIVE_COURSE_LIST,
    payload: data
  };
}

/**
 * Reset Course
 */
export function resetCourseList(data) {
  return {
    type: RESET_COURSE_LIST,
    payload: data
  };
}

/**
 * Fetch Course fail
 *
 * @param {*} error
 */
export function fetchCourseListFail(error) {
  return {
    type: FETCH_COURSE_LIST_FAIL,
    error
  };
}

/**
 * Fetch Course
 */
export function fetchCourseList(params) {
  params = params ? `&${params}` : "";
  return async dispatch => {
    dispatch(requestCourseList());
    try {
      if (getCookie(COOKIE_SESSION_TOKEN)) {
        apiClient.defaults.headers.common.Authorization = getCookie(
          COOKIE_SESSION_TOKEN
        );
      } else {
        apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
      }
      const response = await apiClient.get(
        `${endpoints().courseAPI}/search?pagination=true${params}`
      );
      const data = response.data;
      return dispatch(receiveCourseList(data));
    } catch (error) {
      dispatch(fetchCourseListFail(error));
      if (error.status >= 400) {
        console.log("Error");
      }
    }
  };
}

/**
 * Reset Course
 */
export function resetPlaybook(params) {
  return dispatch => {
    return dispatch(resetCourseList());
  };
}

/**
 *  Change Course Page
 *
 * @param {*} payload
 */
export function changePlaybookPage(payload) {
  return { type: CHANGE_COURSE_PAGE, payload };
}
