import React from "react";
import { Link } from "react-router-dom";

//Assets
import {
  ChevronRight,
  SettingsSalesforceIcon
} from "../../../../assets/img/icons";

// Components
import PageTitle from "../../../../components/base/PageTitle";
import Page404 from "../../../Page404";

// Helper
import { isSuperAdmin, isCompanyAdmin } from "../../../../lib/helper";
import SalesforceAccount from "./account";
import SalesforceContact from "./contact";
import RecordType from "./recordType";
import SalesforceOpportunity from "./opportunity";

import Users from "../../../../salesforce/salesforceUsers";
import UsersSynced from "../../../../salesforce/users";
import OpportunityAudit from "./OpportunityAudit";
import { connect } from "react-redux";

class Salesforce extends React.Component {
  render() {
    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId)
    ) {
      return <Page404 />;
    }
    const {
      activeTab,
      activeSubTab,
      activeSection,
      activeSubSection,
      history
    } = this.props;

    return (
      <>
        {!activeSubTab ? (
          <div className="container">
            <PageTitle label="" />
            {/* content layout */}
            <div>
              {/* Account Object Card */}
              <Link to="account" className="onboarding-link">
                <div className="onboarding-header">
                  <div className="step-wrapper">
                    <span className="step-count d-block completed">
                      <SettingsSalesforceIcon />
                    </span>
                  </div>
                </div>
                <div className="onboarding-main">
                  <h5 className="font-weight-bold">Account</h5>
                  <p className="font-weight-bold text-inline-grayed">
                    Salesforce account object
                  </p>
                </div>
                <div className="onboarding-url">
                  <ChevronRight />
                </div>
              </Link>
              {/* Contact Object Card */}
              <Link to="contact" className="onboarding-link">
                <div className="onboarding-header">
                  <div className="step-wrapper">
                    <span className="step-count d-block completed">
                      <SettingsSalesforceIcon />
                    </span>
                  </div>
                </div>
                <div className="onboarding-main">
                  <h5 className="font-weight-bold">Contact</h5>
                  <p className="font-weight-bold text-inline-grayed">
                    Salesforce contact object
                  </p>
                </div>
                <div className="onboarding-url">
                  <ChevronRight />
                </div>
              </Link>
              {/* Opportunity Object Card */}
              <Link to="opportunity" className="onboarding-link">
                <div className="onboarding-header">
                  <div className="step-wrapper">
                    <span className="step-count d-block completed">
                      <SettingsSalesforceIcon />
                    </span>
                  </div>
                </div>
                <div className="onboarding-main">
                  <h5 className="font-weight-bold">Opportunity</h5>
                  <p className="font-weight-bold text-inline-grayed">
                    Salesforce opportunity object
                  </p>
                </div>
                <div className="onboarding-url">
                  <ChevronRight />
                </div>
              </Link>
              {/* Opportunity Audit Report Card */}
              <Link to="opportunityAudit" className="onboarding-link">
                <div className="onboarding-header">
                  <div className="step-wrapper">
                    <span className="step-count d-block completed">
                      <SettingsSalesforceIcon />
                    </span>
                  </div>
                </div>
                <div className="onboarding-main">
                  <h5 className="font-weight-bold">Opportunity Audit Report</h5>
                  <p className="font-weight-bold text-inline-grayed">
                    Salesforce opportunity Audit Report
                  </p>
                </div>
                <div className="onboarding-url">
                  <ChevronRight />
                </div>
              </Link>
              {/* Account Object Record Type */}
              <Link to="RecordType" className="onboarding-link">
                <div className="onboarding-header">
                  <div className="step-wrapper">
                    <span className="step-count d-block completed">
                      <SettingsSalesforceIcon />
                    </span>
                  </div>
                </div>
                <div className="onboarding-main">
                  <h5 className="font-weight-bold">Account Record Types</h5>
                  <p className="font-weight-bold text-inline-grayed">
                    Salesforce account object record types
                  </p>
                </div>
                <div className="onboarding-url">
                  <ChevronRight />
                </div>
              </Link>
              {/* Users (Synced) */}
              <Link to="Users (Synced)" className="onboarding-link">
                <div className="onboarding-header">
                  <div className="step-wrapper">
                    <span className="step-count d-block completed">
                      <SettingsSalesforceIcon />
                    </span>
                  </div>
                </div>
                <div className="onboarding-main">
                  <h5 className="font-weight-bold">Users (Synced)</h5>
                  <p className="font-weight-bold text-inline-grayed">
                    Salesforce Users (Synced)
                  </p>
                </div>
                <div className="onboarding-url">
                  <ChevronRight />
                </div>
              </Link>
              {/* Users */}
              <Link to="Users" className="onboarding-link">
                <div className="onboarding-header">
                  <div className="step-wrapper">
                    <span className="step-count d-block completed">
                      <SettingsSalesforceIcon />
                    </span>
                  </div>
                </div>
                <div className="onboarding-main">
                  <h5 className="font-weight-bold">Users</h5>
                  <p className="font-weight-bold text-inline-grayed">
                    Salesforce Users
                  </p>
                </div>
                <div className="onboarding-url">
                  <ChevronRight />
                </div>
              </Link>
            </div>
          </div>
        ) : (
          <>
            {activeSubTab == "account" && (
              <SalesforceAccount
                activeTab={activeTab}
                activeSubTab={activeSubTab}
                activeSection={activeSection}
                activeSubSection={activeSubSection}
                history={history}
              />
            )}
            {activeSubTab == "contact" && (
              <SalesforceContact
                activeTab={activeTab}
                activeSubTab={activeSubTab}
                activeSection={activeSection}
                activeSubSection={activeSubSection}
                history={history}
              />
            )}
            {activeSubTab == "opportunity" && (
              <SalesforceOpportunity
                activeTab={activeTab}
                activeSubTab={activeSubTab}
                activeSection={activeSection}
                activeSubSection={activeSubSection}
                history={history}
              />
            )}
            {activeSubTab == "opportunityAudit" && (
              <OpportunityAudit
                activeTab={activeTab}
                activeSubTab={activeSubTab}
                activeSection={activeSection}
                activeSubSection={activeSubSection}
                history={history}
              />
            )}
            {activeSubTab == "RecordType" && (
              <RecordType
                activeTab={activeTab}
                activeSubTab={activeSubTab}
                activeSection={activeSection}
                activeSubSection={activeSubSection}
                history={history}
              />
            )}
            {activeSubTab == "Users (Synced)" && (
              <UsersSynced
                activeTab={activeTab}
                activeSubTab={activeSubTab}
                activeSection={activeSection}
                activeSubSection={activeSubSection}
                history={history}
              />
            )}
            {activeSubTab == "Users" && (
              <Users
                activeTab={activeTab}
                activeSubTab={activeSubTab}
                activeSection={activeSection}
                activeSubSection={activeSubSection}
                history={history}
              />
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(Salesforce);
