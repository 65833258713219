import React, { useState, useEffect } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import Form from "../../../components/base/Form";
import Select from "../../../components/base/Select";
import CancelButton from "../../../components/base/CancelButton";
import SaveButton from "../../../components/base/SaveButton";
import PartnerType from "../../../components/PartnerType";

import Text from "../../../components/base/Text";
import {
  applicationTaskTypeOptions,
  TASK_TYPE_FILL_OUT_FORM,
  TASK_TYPE_VISIT_URL
} from "../Constant";
import {
  isCompanyAdmin,
  isCompanyManager,
  isSuperAdmin
} from "../../../lib/helper";

import { useSelector } from "react-redux";

const TaskEdit = props => {
  const { task, isOpen, formList } = props;
  let { name, selectedFormId, type, target_url, permission } = task;

  const [selectedTaskType, setSelectedTaskType] = useState("");
  const [partnerTypeList, setPartnerTypeList] = useState("");

  const currentUser = useSelector(state => state.user);

  // modal toggle
  const closeEditTaskModal = () => {
    props.toggle();
    setSelectedTaskType("");
  };

  // handle type changes
  const handleTypeChange = selectedTypeObject => {
    if (selectedTypeObject && selectedTypeObject.value) {
      setSelectedTaskType(selectedTypeObject.value);
    }
  };

  // useEffect
  useEffect(() => {
    getSelectedPartnerTypes();
  }, [task]);

  // get selected type
  const getSelectedPartnerTypes = () => {
    const typeInitialValues = {};
    const selectedPartnerTypes = [];
    if (permission && permission.length > 0) {
      permission.forEach(type => {
        type.partnerType &&
          selectedPartnerTypes.push({
            value: type.type_id,
            label: type.partnerType.name
          });
      });
    }
    typeInitialValues["partnerType"] = selectedPartnerTypes;
    setPartnerTypeList(typeInitialValues);
  };

  //Update Task Details
  const updateTaskDetails = (values, task) => {
    let partnerType = [];
    const data = new FormData();
    data.append("name", values.name || "");
    values &&
      values.partnerType &&
      values.partnerType.forEach(data => {
        if (data) {
          partnerType.push({
            partnerType: data.value
          });
        }
      });

    data.append("partnerType", JSON.stringify(partnerType) || "");
    data.append(
      "selectedFormId",
      (values.selectedForm &&
        values.selectedForm.value &&
        values.selectedForm.value) ||
        ""
    );
    data.append("type", (values.type && values.type.label) || "");
    data.append("visitUrl", (values && values.visitUrl) || "");
    props.updateTask(task.id, data, isSuccess => {
      if (isSuccess) {
        closeEditTaskModal();
      }
    });
  };

  // Set selected task input field type
  const selectTaskField = selectedTaskType ? selectedTaskType : type;

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeEditTaskModal}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <Form
        initialValues={{
          ...partnerTypeList,
          name: name || "",
          type: { label: type, value: type },
          visitUrl: target_url || "",
          selectedForm: selectedFormId
            ? formList.find(formItem => formItem.value === selectedFormId)
            : ""
        }}
        enableReinitialize={true}
        onSubmit={values => {
          updateTaskDetails(values, task);
        }}
      >
        <ModalHeader toggle={closeEditTaskModal}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Edit Application Task
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div>
              <Text
                id={`name`}
                name={`name`}
                label="Name"
                placeholder="Name"
                required
                error=""
              />
            </div>
            <div className="field-wrapper">
              <PartnerType
                isMulti
                name="partnerType"
                label="Partner Type"
                placeholder="Select type"
                isSearchable={true}
                isDisabled={
                  !isSuperAdmin(currentUser && currentUser.roleId) &&
                  !isCompanyAdmin(currentUser && currentUser.roleId) &&
                  !isCompanyManager(currentUser && currentUser.roleId)
                    ? true
                    : false
                }
              />
            </div>
            <div>
              <Select
                id={`type`}
                label="Type of Task"
                name="type"
                placeholder="Select"
                defaultValue={
                  type
                    ? applicationTaskTypeOptions.find(
                        applicationTaskTypeOptions =>
                          applicationTaskTypeOptions.value === type
                      )
                    : ""
                }
                options={applicationTaskTypeOptions}
                isSearchable={true}
                required
                handleChange={e => handleTypeChange(e)}
              />
            </div>
            {selectTaskField === TASK_TYPE_FILL_OUT_FORM && (
              <>
                <div className="form-wrapper">
                  <div className="flex-column">
                    <Select
                      label="Form"
                      name="selectedForm"
                      placeholder="Select"
                      options={formList}
                      isSearchable={true}
                    />
                  </div>
                </div>
              </>
            )}
            {selectTaskField === TASK_TYPE_VISIT_URL && (
              <>
                <div className="form-wrapper mt-4">
                  <div className="flex-column">
                    <Text
                      id={`visitUrl`}
                      name="visitUrl"
                      label="Visit URL"
                      placeholder="Visit URL"
                    />
                  </div>
                </div>
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={closeEditTaskModal} />
            <SaveButton />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};
export default TaskEdit;
