import React, { useState, useEffect } from "react";
import ManageTagList from "./tabs/Lists/ManageTagList";
import ManageTagTypeList from "./tabs/Lists/ManageTagTypeList";
import ManageProjectCategoriesList from "./tabs/Lists/ManageProjectCategoriesList";
import {
  LIST_PROJECT_CATEGORIES_SUB_TAB,
  LIST_TAGS_SUB_TAB,
  LIST_TAG_TYPES_SUB_TAB
} from "../..//setting/Constants";
import { TAB_LIST } from "../../supportPortal/Constants";

const ManageLists = props => {
  const { activeSubTab, adminSettings, saveSettings, history } = props;
  const [selectedList, setSelectedList] = useState("tags_type_table");
  const [label, setLabel] = useState("Manage Tags");
  const [params, setParams] = useState(activeSubTab);
  const [id, setId] = useState();

  const dropdownOptions = [
    { label: "Manage Tags", value: LIST_TAGS_SUB_TAB },
    { label: "Manage Tag Types", value: LIST_TAG_TYPES_SUB_TAB },
    {
      label: "Manage Project Categories",
      value: LIST_PROJECT_CATEGORIES_SUB_TAB
    }
  ];

  useEffect(() => {
    // Set label
    const option = dropdownOptions.find(option => option.value === params);
    option && setLabel(option.label);

    setParams(activeSubTab);
    let url =
      props.history &&
      props.history.location &&
      props.history.location.pathname;
    const tagId = url.split("/").pop();
    setId(tagId);
  });

  const selectList = e => {
    const { value, label } = e.target.dataset;
    setLabel(label);
    setParams(value);
    history.push(`${history.location.pathname}/List/${value}`);
    setSelectedList(value);
  };

  const ENUM = {
    [TAB_LIST]: <ManageTagList history={history} />,
    [TAB_LIST]: (
      <ManageTagTypeList
        history={history}
        link={`/support-portal/${TAB_LIST}/${LIST_TAGS_SUB_TAB}`}
      />
    ),
    [LIST_PROJECT_CATEGORIES_SUB_TAB]: <ManageProjectCategoriesList />
  };

  return <div>{id ? <ManageTagList history={history} /> : ENUM[params]}</div>;
};

export default ManageLists;
