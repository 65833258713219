import React, { Component } from "react";

// Components
import PageTitle from "../../components/base/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import AvatarCard from "../../components/base/AvatarCard";

class UserManagement extends Component {
  render() {
    return (
      <div>
        <PageTitle label="User Management" />
        <div className="mb-5">
          <ReduxTable
            id="userManagement"
            newTableHeading
            apiURL="/v1/customer/team/member"
          >
            <ReduxColumn
              field="firstName"
              isClickable="true"
              sortBy="first_name"
              renderField={row => (
                <AvatarCard
                  firstName={row.firstName}
                  lastName={row.lastName}
                  url={row.avatarUrl}
                />
              )}
            >
              Name
            </ReduxColumn>
            <ReduxColumn field="email" sortBy="email">
              Email
            </ReduxColumn>
            <ReduxColumn field="role">Role</ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}

export default UserManagement;
