export const EXPERT_TYPE_HOURS_WEEK = "hours/week";
export const EXPERT_TYPE_10_40_HOURS_TOTAL = "10-40 hours total";
export const EXPERT_TYPE_10_20_HOURS_WEEK = "10-20 hours/week";
export const EXPERT_TYPE_20_30_HOURS_WEEK = "20-30 hours/week";
export const EXPERT_TYPE_30_PLUS_HOURS_WEEK = "30+ hours/week";
export const EXPERT_TYPE_NOT_CURRENTLY_AVAILABLE =
  "Not currently available (expect to be available later)";

/**
 * Get Expert Hours Option
 */
export function getExpertHourlyOption() {
  return [
    {
      label: EXPERT_TYPE_HOURS_WEEK,
      value: EXPERT_TYPE_HOURS_WEEK,
      selected: false,
      placeholder: true
    },
    {
      label: EXPERT_TYPE_10_40_HOURS_TOTAL,
      value: EXPERT_TYPE_10_40_HOURS_TOTAL,
      selected: false
    },
    {
      label: EXPERT_TYPE_10_20_HOURS_WEEK,
      value: EXPERT_TYPE_10_20_HOURS_WEEK,
      selected: false
    },
    {
      label: EXPERT_TYPE_20_30_HOURS_WEEK,
      value: EXPERT_TYPE_20_30_HOURS_WEEK,
      selected: false
    },
    {
      label: EXPERT_TYPE_30_PLUS_HOURS_WEEK,
      value: EXPERT_TYPE_30_PLUS_HOURS_WEEK,
      selected: false
    }
  ];
}
