import React from "react";

// Components
import PartnerSection from "./PartnerSection";

const SideBar = ({ partnerId, showTitle, classNames }) => {
  return (
    <div className="details-body-sidebar">
      {/* Partner */}
      <div className={[`sidebar-item ${classNames}`].join(" ")}>
        <PartnerSection partnerId={partnerId} showTitle={showTitle} />
      </div>
    </div>
  );
};

export default SideBar;
