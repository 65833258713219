import React from "react";
import { apiClient } from "../../apiClient";
import { Redirect } from "react-router-dom";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Configs
import { endpoints } from "../../configs";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchList } from "../../actions/table";

// Helper
import {
  getParamsByName,
  isLoggedIn,
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
// import AddButton from "../../components/base/AddButton";

// Inner Components
import MyDeals from "./MyDeals";
import OpenDeals from "./OpenDeals";
import ClosedDeals from "./ClosedDeals";
import AllDeals from "./AllDeals";
import Spinner from "../../components/base/Spinner";
import NewDeals from "./NewDeals";
import { isBadRequest } from "../../common/http";
import CountBadge from "../../components/base/CountBadge";

// constants
const TAB_ALL_DEALS = "all-deals";
const TAB_CLOSED_DEALS = "closed-deals";
const TAB_MY_DEALS = "my-deals";
const TAB_NEW_DEALS = "new-deals";
const TAB_OPEN_DEALS = "open-deals";

class Deals extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      rowId: "",
      loading: false,
      status: "",
      searchCount: 0,
      isLoading: false,
      partnerId: "",
      searchParam: "",
      activeTab: TAB_CLOSED_DEALS,
      sortByOptions: [
        {
          value: "a-z",
          label: "Alphabetical A-Z"
        },
        {
          value: "rating",
          label: "Rating"
        }
      ],
      selectedSortOption: "Alphabetical A-Z"
    };
    // const sectionName = getParamsByName("section") || "";
  }

  getDefaultTab = () => {
    return isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
      ? TAB_ALL_DEALS
      : TAB_MY_DEALS;
  };

  // Get search query string value
  getParamsStatus = () => {
    const status = getParamsByName("section");
    this.setState({ status: status || this.getDefaultTab() });
  };

  // Set Active Tab
  setActiveTab = () => {
    const status = getParamsByName("section");
    this.setState({
      activeTab:
        status === TAB_CLOSED_DEALS
          ? TAB_CLOSED_DEALS
          : status === TAB_ALL_DEALS
          ? TAB_ALL_DEALS
          : status === TAB_MY_DEALS
          ? TAB_MY_DEALS
          : status === TAB_OPEN_DEALS
          ? TAB_OPEN_DEALS
          : this.getDefaultTab()
    });
  };

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this.getParamsStatus();
    this.setActiveTab();
    if (isPartner(this.props.roleId)) {
      this._getPartnerDetails();
    }
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  // Get partner details
  _getPartnerDetails = () => {
    this.setState(
      {
        isLoading: true
      },
      () => {
        return apiClient
          .get(`${endpoints().partnerAPI}`)
          .then(response => {
            this.setState({
              isLoading: false,
              partnerDetails: response.data,
              partnerId: response.data.id
            });
          })
          .catch(error => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              console.error(errorMessage);
            }
          });
      }
    );
  };
  // Handle status change
  _handleStatusChange = tabStatus => {
    this.props.history.push(`/deals?section=${tabStatus}`);
    this.setState({ status: tabStatus });
  };

  // Toggle Tab
  toggle = tab => {
    this.setState({
      activeTab: tab
    });
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (prevProps.expertCount !== this.props.expertCount) {
      this.setState({ totalCount: this.props.expertCount });
    }

    if (prevProps.partnerCount !== this.props.partnerCount) {
      this.setState({ partnerTotalCount: this.props.partnerCount });
    }
  }

  render() {
    const { status, isLoading, activeTab, partnerId } = this.state;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchStatus = params.get("section");
    const isNewDeals =
      searchStatus === TAB_NEW_DEALS || activeTab === TAB_NEW_DEALS;
    const isMyDeals =
      searchStatus === TAB_MY_DEALS || activeTab === TAB_MY_DEALS;
    const isOpenDeals =
      searchStatus === TAB_OPEN_DEALS || activeTab === TAB_OPEN_DEALS;
    const isAllDeals =
      searchStatus === TAB_ALL_DEALS || activeTab === TAB_ALL_DEALS;
    const isClosedDeals =
      searchStatus === TAB_CLOSED_DEALS || activeTab === TAB_CLOSED_DEALS;
    const {
      newDealsCount,
      openDealsCount,
      allDealsCount,
      closedDealsCount
    } = this.props;

    return (
      <div>
        <PageTitle
          label="Deals"
          targetUrl={"/deal/add"}
          buttonLabel="Register a Deal"
        />

        {isLoading && <Spinner />}

        {status === this.getDefaultTab() ? (
          <Redirect to={`/deals?section=${this.getDefaultTab()}`} />
        ) : (
          ""
        )}

        {/* Tab Content */}
        <TabContent activeTab={activeTab}>
          {/*My Deals Tab*/}
          {isPartner(this.props.roleId) && (
            <TabPane tabId={TAB_MY_DEALS}>
              <MyDeals history={this.props.history} />
            </TabPane>
          )}

          {/* All Deals Tab */}
          <TabPane tabId={TAB_ALL_DEALS}>
            <AllDeals history={this.props.history} partnerId={partnerId} />
          </TabPane>
        </TabContent>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { pendingExperts } = state.table;
  const reduxTable = state.table;
  const roleId = state.user ? state.user.roleId : "";

  // Get pending expert count
  const pendingExpertsCount =
    pendingExperts && !pendingExperts.isFetching
      ? pendingExperts.totalCount
      : 0;

  // Get New Deals count
  const newDealsCount =
    reduxTable["newDeals"] && !reduxTable["newDeals"].isFetching
      ? reduxTable["newDeals"].totalCount
      : 0;
  // Get Open Deals count
  const openDealsCount =
    reduxTable["openDeals"] && !reduxTable["openDeals"].isFetching
      ? reduxTable["openDeals"].totalCount
      : 0;
  // Get closed Deals count
  const closedDealsCount =
    reduxTable["closedDeals"] && !reduxTable["closedDeals"].isFetching
      ? reduxTable["closedDeals"].totalCount
      : 0;
  // Get closed Deals count
  const allDealsCount =
    reduxTable["allDeals"] && !reduxTable["allDeals"].isFetching
      ? reduxTable["allDeals"].totalCount
      : 0;
  return {
    pendingExpertsCount,
    newDealsCount,
    openDealsCount,
    closedDealsCount,
    allDealsCount,
    roleId
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Deals);
