import React from "react";
import DatePicker from "react-datepicker";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { formatCurrency } from "../../helpers/currency";
import _ from "lodash";

// Action
import { fetchList } from "../../actions/table";
import {
  updateReferral,
  deleteReferral,
  referralDecline
} from "../../actions/referral";

// Config
import { endpoints } from "../../configs";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import { DropdownItem } from "reactstrap";
import MoreDropdown from "../../components/base/MoreDropdown";
import ReferralStatCard from "../../components/referral/ReferralStatCard";
import CheckboxDropdownSelect from "../../components/base/CheckboxDropdownSelect";
import Form from "../../components/base/Form";
import PageSearch from "../../components/base/PageSearch";
import Partner from "../../components/Partner";
import DeleteModal from "../../components/base/DeleteModal";

// Inner Components
import ReferralDeclineModal from "./inner-components/ReferralDeclineModal";

// Helper
import {
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  getFullName,
  getParamsByName,
  getUserId,
  isCompanyManager,
  getCookie,
  setCookie,
  clearCookie
} from "../../lib/helper";
import {
  REFERRAL_STATUS_DECLINED,
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_NEW,
  REFERRAL_STATUS_CLOSED_WON,
  REFERRAL_STATUS_ACCEPTED,
  REFERRAL_STATUS_REJECTED
} from "../../referral/Constants";

import { ReferralIcon } from "../../assets/img/icons";
import { COOKIE_SHOW_ASSIGNED_PARTNERS } from "../../lib/cookie";
import SelectDropdown from "../../components/base/SelectDropdown";
import { apiClient } from "../../apiClient";
import detectBrowser from "../../components/helpers/detectBrowser";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
import {
  SETTINGS_SHOW_REFERRAL_NUMBER,
  SETTINGS_ENABLE_REFERRAL_REQUEST
} from "../../setting/Constants";

import { getSettingsData } from "../../actions/settings";
import { isBadRequest } from "../../common/http";
import PartnerType from "../../components/PartnerType";
import Select from "../../components/base/Select";
import Currency from "../../components/Currency";
import Referral from "../../helpers/Referral";
import Filter from "../../components/filter";
import PartnerService from "../../services/partnerService";
import DateTime from "../../lib/DateTime";

class AllReferral extends React.Component {
  state = {
    isChecked: "",
    isMobile: detectBrowser(),
    sortByOptions: [
      {
        value: "createdAt:DESC",
        label: "Most Recent"
      },
      {
        value: "account_name:ASC",
        label: "Account Name"
      }
    ],
    deleteReferralModal: false,
    deleteReferral: false,
    partner: "",
    start_date: "",
    end_date: "",
    status: "",
    search: "",
    referralList: "",
    selectedSortValue: "",
    sort: "",
    sortDir: "",
    statusObj: {},
    typeObj: {},
    selectedSortOption: "createdAt:DESC",
    page: this.props.allReferralsCurrentPage,
    pageSize: getParamsByName("pageSize"),
    currentPage: this.props.allReferralsCurrentPage,
    referral_id: "",
    referralDeclineModal: false,
    submittedFrom: "",
    partnerType: "",
    showReferralNumber: false,
    referralType: getParamsByName("referralType"),
    account: getParamsByName("account"),
    referralRequestEnabled: false,
    partnerTypeOptions: [],
    partnerTierOptions: [],
    partnerList: [],
    accountList: [],
    params: {
      start_date: getParamsByName("start_date"),
      end_date: getParamsByName("end_date"),
      account: getParamsByName("account"),
      partner: getParamsByName("partner"),
      status: getParamsByName("status"),
      partnerType: getParamsByName("partnerType"),
      partnerTier: getParamsByName("partnerTier"),
      referralType: getParamsByName("referralType"),
      pageSize: getParamsByName("pageSize"),
      sort: getParamsByName("sort"),
      sortDir: getParamsByName("sortDir"),
      page: this.props.allReferralsCurrentPage || getParamsByName("page"),
      pagination: true
    }
  };

  // Assigned partners checkbox toggle
  toggleChange = e => {
    this.setState({ isChecked: !this.state.isChecked });

    if (this.state.isChecked) {
      clearCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    }

    if (!this.state.isChecked) {
      clearCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
      setCookie(COOKIE_SHOW_ASSIGNED_PARTNERS, "true");
    }
  };

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this.getReferralData();
    this.getSetting();
    this.getPartnerType();
    this.getPartnerList();
    this.getAccount();
    let partner = this.props.partner;
    if (partner) {
      this.setState({ partner });
    } else {
      const partner = getParamsByName("partner") || "";
      if (partner) {
        this.setState({ partner: partner });
        let partner_Id = {},
          partnerIdArray = [];
        partnerIdArray = partner.split(",");
        if (partnerIdArray.length > 0) {
          partnerIdArray.forEach(element => {
            partner_Id[`${element}`] = true;
          });
        }
        this.setState({ partner: partner });
      }

      const submittedFrom = getParamsByName("submittedFrom") || "";

      const partnerType = getParamsByName("partnerType") || "";

      if (partnerType) {
        this.setState({ partnerType: partnerType });
      }

      if (submittedFrom) {
        this.setState({ submittedFrom: submittedFrom });
      }
    }
    let status = this.props.status;
    if (status) {
      this.setState({ status });
    } else {
      const statusNames = getParamsByName("status") || "";
      if (statusNames) {
        this.setState({ status: statusNames });
        let statusObj = {},
          statusArray = [];
        statusArray = statusNames.split(",");
        if (statusArray.length > 0) {
          statusArray.forEach(element => {
            statusObj[`${element}`] = true;
          });
        }
        this.setState({ statusObj: statusObj });
      }
    }
    // Assigned partner checkbox status
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    if (checked_status) {
      this.setState({ isChecked: !this.state.isChecked });
    }

    if (!checked_status) {
      this.setState({ isChecked: this.state.isChecked });
    }
    this.handleSearch();
  }

  componentDidUpdate(prevProps, prevState) {
    // Check is logged in user
    isLoggedIn();
    const status = this.props.status;
    if (prevProps && prevProps.status !== status) {
      this.setState({ status });
    }
  }

  getSetting = () => {
    getSettingsData(SETTINGS_SHOW_REFERRAL_NUMBER, response => {
      if (response) {
        this.setState({
          showReferralNumber: response.value == "true" ? true : false
        });
      }
    });

    getSettingsData(SETTINGS_ENABLE_REFERRAL_REQUEST, response => {
      if (response) {
        this.setState({
          referralRequestEnabled: response.value == "true" ? true : false
        });
      }
    });
  };

  //handle search function
  handleSearch() {
    const search = window.location.search;
    const pathParams = new URLSearchParams(search);
    const searchItem = pathParams.get("search");

    if (searchItem != this.state.search) {
      this.setState({ search: searchItem ? searchItem : "" });
      this.fetchData({ search: searchItem ? searchItem : "" });
    }
  }

  // Update Referral Status
  updateReferralStatus(status, id) {
    this.props.actions.updateReferral(id, status, {
      newReferralsSort: this.props.newReferralsSort,
      newReferralsSortDir: this.props.newReferralsSortDir,
      allReferralsSort: this.props.allReferralsSort,
      allReferralsSortDir: this.props.allReferralsSortDir,
      approvedReferralsSort: this.props.approvedReferralsSort,
      approvedReferralsSortDir: this.props.approvedReferralsSortDir,
      declinedReferralsSort: this.props.declinedReferralsSort,
      declinedReferralsSortDir: this.props.declinedReferralsSortDir,
      currentStatus: this.state.status,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      partner: this.state.partner,
      account: this.state.account,
      currentPage: this.props.allReferralsCurrentPage
    });
  }

  // Update Decline Status
  updateDeclineStatus(status, id, declineMessage, declineNotification) {
    this.props.actions.referralDecline(
      id,
      status,
      declineMessage,
      declineNotification,
      {
        newReferralsSort: this.props.newReferralsSort,
        newReferralsSortDir: this.props.newReferralsSortDir,
        allReferralsSort: this.props.allReferralsSort,
        allReferralsSortDir: this.props.allReferralsSortDir,
        approvedReferralsSort: this.props.approvedReferralsSort,
        approvedReferralsSortDir: this.props.approvedReferralsSortDir,
        declinedReferralsSort: this.props.declinedReferralsSort,
        declinedReferralsSortDir: this.props.declinedReferralsSortDir,
        currentStatus: this.state.status,
        start_date: this.state.start_date,
        end_date: this.state.end_date,
        partner: this.state.partner,
        account: this.state.account,
        currentPage: this.props.allReferralsCurrentPage
      }
    );
  }

  handleDelete(id) {
    this.props.actions.deleteReferral(id, {
      newReferralsSort: this.props.newReferralsSort,
      newReferralsSortDir: this.props.newReferralsSortDir,
      allReferralsSort: this.props.allReferralsSort,
      allReferralsSortDir: this.props.allReferralsSortDir,
      approvedReferralsSort: this.props.approvedReferralsSort,
      approvedReferralsSortDir: this.props.approvedReferralsSortDir,
      declinedReferralsSort: this.props.declinedReferralsSort,
      declinedReferralsSortDir: this.props.declinedReferralsSortDir,
      currentStatus: this.state.status,
      start_date: this.state.start_date,
      end_date: this.state.end_date,
      partner: this.state.partner,
      account: this.state.account,
      currentPage: this.props.allReferralsCurrentPage
    });
  }

  handleStartDate = start_date => {
    this.setState(
      {
        start_date: start_date ? DateTime.toISOStringDate(start_date) : ""
      },
      () => {
        this.fetchData({
          start_date: start_date ? DateTime.formatDate(start_date) : ""
        });
      }
    );
  };

  handleEndDate = end_date => {
    this.setState(
      {
        end_date: end_date ? DateTime.toISOStringDate(end_date) : ""
      },
      () => {
        this.fetchData({
          end_date: end_date ? DateTime.formatDate(end_date) : ""
        });
      }
    );
  };
  handlePageSizeChange = e => {
    this.setState(
      {
        pageSize: e
      },
      () => {
        this.fetchData({
          pageSize: e
        });
      }
    );
  };

  handlePartnerChange = partner => {
    this.setState({ partner: partner ? partner.value : "" }, () => {
      this.fetchData({ partner: partner ? partner.value : "" });
    });
  };

  handleStatusChange = values => {
    let statusObj = values;
    let status = "",
      statusArray = [];
    const selectedStatusArrays = Object.entries(statusObj);

    if (selectedStatusArrays.length > 0) {
      selectedStatusArrays.forEach(async selectedStatusArray => {
        if (selectedStatusArray[1] === true) {
          statusArray.push(selectedStatusArray[0]);
        }
      });
    }

    if (statusArray.length > 0) {
      status = statusArray.join();
    }

    this.setState({ status: status ? status : "", statusObj }, () => {
      this.fetchData({ status: status ? status : "" });
    });
  };

  handleAccountChange = account => {
    // const status = getParamsByName("status") || "";
    this.setState({ account: account ? account.value : "" }, () => {
      this.fetchData({ account: account ? account.value : "" });
    });
  };

  handletypeChange = type => {
    this.setState({ partnerType: type ? type.value : "" }, () => {
      this.fetchData({ partnerType: type ? type.value : "" });
    });
  };
  handleSortByChange = value => {
    const valueArray = this.getSortValueFromLabel(value).split(":");
    const sort = valueArray[0];
    const sortDir = valueArray[1];
    this.setState({ sort, sortDir }, () => {
      this.fetchData({ sort: sort, sortDir: sortDir });
    });
  };

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  getSelectedSortLabel() {
    const sortByOptions = this.props.sortByOptions
      ? this.props.sortByOptions
      : this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.value === this.state.selectedSortOption
    );

    if (selectedSortOption) {
      return selectedSortOption.label;
    }

    return "";
  }

  getPartnerType = async () => {
    const response = await PartnerService.TypeList();
    this.setState({ partnerTypeOptions: response });
  };

  getPartnerList = async () => {
    const response = await PartnerService.List();
    this.setState({ partnerList: response });
  };
  getAccount = async () => {
    try {
      const response = await apiClient.get(`${endpoints().accountAPI}`);
      const results = response.data.data;

      let data = [];

      if (results && results.length > 0) {
        for (let i = 0; i < results.length; i++) {
          data.push({ label: results[i].name, value: results[i].id });
        }
      }

      this.setState({ accountList: data });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  fetchData = updateParams => {
    let params = {
      start_date: this.state.start_date || getParamsByName("start_date"),
      end_date: this.state.end_date || getParamsByName("end_date"),
      partner: getParamsByName("partner"),
      account: getParamsByName("account"),
      status: Array.isArray(updateParams.status)
        ? updateParams.status.join(",")
        : getParamsByName("status"),
      partnerType: getParamsByName("partnerType"),
      referralType: getParamsByName("referralType"),
      sort: getParamsByName("sort"),
      sortDir: getParamsByName("sortDir"),
      pageSize: getParamsByName("pageSize"),
      pagination: true,
      search: this.state.search || getParamsByName("search"),
      ...updateParams
    };

    params.page =
      this.props.allReferralsCurrentPage || getParamsByName("page") || 1;

    this.setState({ params: params });

    this.props.getReferralList(params);

    let param = [];
    if (params.search) {
      param.push(`search=${params.search}`);
    }
    if (params.partner) {
      param.push(`partner=${params.partner}`);
    }

    if (Array.isArray(params.status) && params.status.length > 0) {
      // param.push(`status=${params.status.join(",")}`);
    } else {
      param.push(`status=${params.status}`);
    }

    if (params.partnerType) {
      param.push(`partnerType=${params.partnerType}`);
    }

    if (params.pageSize) {
      param.push(`pageSize=${params.pageSize}`);
    }

    if (params.sort) {
      param.push(`sort=${params.sort}`);
    }

    if (params.sortDir) {
      param.push(`sortDir=${params.sortDir}`);
    }
    if (params.start_date) {
      let date = new Date(params.start_date);

      // Check if date is a valid Date object
      if (!isNaN(date.getTime())) {
        param.push(`start_date=${DateTime.formatDate(date)}`);
      }
    }

    if (params.referralType) {
      param.push(`referralType=${params.referralType}`);
    }

    if (params.page) {
      param.push(
        `page=${this.props.allReferralsCurrentPage ||
          getParamsByName("page") ||
          1}`
      );
    }
    if (params.account) {
      param.push(`account=${params.account}`);
    }

    if (params.end_date) {
      let date = new Date(params.end_date);

      // Check if date is a valid Date object
      if (!isNaN(date.getTime())) {
        param.push(`end_date=${DateTime.formatDate(date)}`);
      }
    }

    if (param.length > 0) {
      this.props.history.push(`/referrals?${param.join("&")}`);
    } else this.props.history.push(`/referrals`);

    // Get Referral list from redux table
    this.props.actions.fetchList(
      "allReferrals",
      this.getApiURL(),
      this.props.allReferralsCurrentPage,
      10,
      params
    );
  };

  // Search
  onSearch(event) {
    event.persist();
    const value = event.target.value;
    this.setState({ search: decodeURI(value) });
    this.doSearch(value);
  }

  doSearch = _.debounce(value => {
    this.setState(
      {
        isSearch: true,
        page: this.props.allReferralsCurrentPage
      },
      () => {
        this.fetchData({ search: decodeURI(value) });
      }
    );
  }, 500);

  getApiURL() {
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    if (checked_status && isCompanyManager(this.props.roleId)) {
      return `${endpoints().referralApi}/search?managerUserId=${getUserId()}&`;
    } else {
      return `${endpoints().referralApi}/search`;
    }
  }

  //get referrals data
  getReferralData = async () => {
    const response = await apiClient.get(`${endpoints().referralApi}/search`);
    const currentPage = response.data.currentPage;
    this.setState({ page: currentPage });
  };

  // Toggle Referral Decline Modal
  toggleModal = referralId => {
    let referral_id = "";
    if (referralId) {
      referral_id = referralId;
    }
    const { referralDeclineModal } = this.state;
    this.setState({ referral_id: referral_id }, () => {
      this.setState({ referralDeclineModal: !referralDeclineModal });
    });
  };

  handleReferralTypeChange = type => {
    let params = new Object();
    if (type) {
      params.referralType = type.value;
    }
    this.setState(params, () => {
      this.fetchData({ referralType: type ? type.value : "" });
    });
  };

  handleDeleteFilter = removedFilter => {
    const currentStatus = getParamsByName("status");

    if (removedFilter.status && currentStatus) {
      const removedStatus = removedFilter.status;
      const updatedStatus = currentStatus
        .split(",")
        .filter(status => status !== removedStatus)
        .join(",");

      let statusObj = {};
      updatedStatus.split(",").forEach(status => {
        statusObj[status] = true;
      });

      // Update the state
      this.setState({ statusObj: statusObj });

      // Call fetchData with the updated status
      this.fetchData({ status: updatedStatus });
    } else {
      // If no status to remove or current status is not available, call fetchData without changes
      this.fetchData(removedFilter);
    }
  };

  render() {
    const {
      deleteReferralModal,
      referral_id,
      deleteReferral,
      referralDeclineModal,
      search,
      isMobile,
      showReferralNumber,
      referralType,
      referralRequestEnabled,
      partnerList,
      partnerTierOptions,
      partnerTypeOptions,
      account,
      accountList,
      params,
      statusObj
    } = this.state;

    params.search = search ? search : "";

    const statusOptions =
      referralType == Referral.TYPE_REFERRAL
        ? [
            {
              value: REFERRAL_STATUS_NEW,
              label: REFERRAL_STATUS_NEW
            },
            {
              value: REFERRAL_STATUS_APPROVED,
              label: REFERRAL_STATUS_APPROVED
            },
            {
              value: REFERRAL_STATUS_DECLINED,
              label: REFERRAL_STATUS_DECLINED
            },
            {
              value: REFERRAL_STATUS_CLOSED_WON,
              label: REFERRAL_STATUS_CLOSED_WON
            }
          ]
        : referralType == Referral.TYPE_REQUEST
        ? [
            {
              value: REFERRAL_STATUS_ACCEPTED,
              label: REFERRAL_STATUS_ACCEPTED
            },
            {
              value: REFERRAL_STATUS_REJECTED,
              label: REFERRAL_STATUS_REJECTED
            }
          ]
        : [
            {
              value: REFERRAL_STATUS_NEW,
              label: REFERRAL_STATUS_NEW
            },
            {
              value: REFERRAL_STATUS_APPROVED,
              label: REFERRAL_STATUS_APPROVED
            },
            {
              value: REFERRAL_STATUS_DECLINED,
              label: REFERRAL_STATUS_DECLINED
            },
            {
              value: REFERRAL_STATUS_CLOSED_WON,
              label: REFERRAL_STATUS_CLOSED_WON
            },
            {
              value: REFERRAL_STATUS_ACCEPTED,
              label: REFERRAL_STATUS_ACCEPTED
            },
            {
              value: REFERRAL_STATUS_REJECTED,
              label: REFERRAL_STATUS_REJECTED
            }
          ];
    const referralTypeOption = [
      {
        value: Referral.TYPE_ALL,
        label: Referral.TYPE_ALL
      },
      {
        value: Referral.TYPE_REFERRAL,
        label: Referral.TYPE_REFERRAL
      },
      {
        value: Referral.TYPE_REQUEST,
        label: Referral.TYPE_REQUEST
      }
    ];

    const type_id = getParamsByName("partnerType");

    const defaultPartnerTypeId = type_id
      ? this.state.partnerTypeOptions.find(
          partnerDetail => partnerDetail.value === parseInt(type_id, 10)
        )
      : "";
    const defaultAccount = getParamsByName("account")
      ? this.state.accountList.find(
          data => data.value === parseInt(getParamsByName("account"), 10)
        )
      : "";

    const defaultReferralType = referralType
      ? referralTypeOption &&
        referralTypeOption.find(
          partnerDetail => partnerDetail.value === parseInt(referralType, 10)
        )
      : "";

    const statusParam = getParamsByName("status");

    const statusArray = statusParam
      ? statusParam.split(",").map(status => status.trim())
      : [];

    const initialValues = {
      partnerType: partnerTypeOptions.find(partnerDetail =>
        partnerDetail.value == getParamsByName("partnerType")
          ? getParamsByName("partnerType")
          : ""
      ),

      partnerTier: partnerTierOptions.find(partnerDetail =>
        partnerDetail.value == getParamsByName("partnerTier")
          ? getParamsByName("partnerTier")
          : ""
      ),
      referralType: referralTypeOption.find(partnerDetail =>
        partnerDetail.value == getParamsByName("referralType")
          ? getParamsByName("referralType")
          : ""
      ),
      status: statusOptions.filter(partnerDetail =>
        statusArray.includes(partnerDetail.value)
      ),
      partner: partnerList.find(partnerDetail =>
        partnerDetail.value == getParamsByName("partner")
          ? getParamsByName("partner")
          : ""
      ),
      start_date: getParamsByName("start_date")
        ? getParamsByName("start_date")
        : "",
      end_date: getParamsByName("end_date") ? getParamsByName("end_date") : "",
      account: accountList.find(data =>
        data.value == getParamsByName("account")
          ? getParamsByName("account")
          : ""
      )
    };

    return (
      <>
        <div>
          <ReferralStatCard
            noOfReferralCount={this.props.allReferralsCount}
            totalValue={this.props.totalValue}
            averageReferralSize={this.props.averageReferralSize}
            enableReferralSizeAndValue={this.props.enableReferralSizeAndValue}
          />
        </div>

        <Filter
          showHeader
          newTableHeading
          showSearch
          displayFilter
          sortByDropdown
          initialValues={initialValues}
          sortByOptions={this.state.sortByOptions}
          pageSearchOnChange={this.onSearch.bind(this)}
          search={search}
          getSelectedSortLabel={this.getSelectedSortLabel()}
          handleSortByChange={this.handleSortByChange}
          showDateFilter
          showPartnerFilter
          showReferralTypeFilter
          showPartnerTypeFilter
          showTypeFilter
          showReferralStatusFilter
          handleStartDate={this.handleStartDate}
          handleEndDate={this.handleEndDate}
          selectedStartDate={this.state.start_date}
          selectedEndDate={this.state.end_date}
          handlePartnerChange={this.handlePartnerChange}
          selectedPartnerId={getParamsByName("partner")}
          handleStatusChange={this.handleStatusChange}
          statusOptions={statusOptions}
          selectedStatus={this.state.statusObj}
          handletypeChange={this.handletypeChange}
          defaultPartnerTypeId={defaultPartnerTypeId}
          partnerTypeOptions={this.state.partnerTypeOptions}
          handleReferralTypeChange={this.handleReferralTypeChange}
          defaultReferralType={defaultReferralType}
          referralTypeOption={referralTypeOption}
          partnerList={this.state.partnerList}
          handleDeleteFilter={this.handleDeleteFilter}
          loggedInUser={this.props}
          handleAccountChange={this.handleAccountChange}
          showAccountFilter
          accountOptions={this.state.accountList}
          referralRequestEnabled={referralRequestEnabled}
          defaultAccount={defaultAccount}
          handlePageSizeChange={this.handlePageSizeChange}
        />
        <div className="mb-5">
          <ReduxTable
            start_date={this.state.start_date}
            end_date={this.state.end_date}
            id="allReferrals"
            apiURL={this.getApiURL()}
            params={params}
            onRowClick={row => this.history.push(`/referrals/edit/${row.id}`)}
            sortByOptions={this.state.sortByOptions}
            searchDisabled
            icon={<ReferralIcon />}
            message={`You can track the ${Terminology.get(
              SystemName.REFERRALS
            )} you have submitted here`}
            subtextMessage={`To get started tap the Submit a ${Terminology.get(
              SystemName.REFERRAL
            )} button`}
            assignedPartnersCheckbox="assignedPartnersCheckbox"
            assignedPartnerValue={this.state.isChecked}
            assignedPartnerChange={this.toggleChange}
            paramsToUrl={true}
            history={this.props.history}
          >
            {showReferralNumber && (
              <ReduxColumn
                minWidth={"110px"}
                width={"110px"}
                type="link"
                disableOnClick
                className="text-center"
                field="referral_number"
                renderField={row => (
                  <Link
                    to={
                      row.type == Referral.TYPE_REQUEST
                        ? `/referral/request/${row.id}`
                        : `/referral/edit/${row.id}`
                    }
                  >
                    {row.referral_number}
                  </Link>
                )}
              >
                {`${Terminology.get(SystemName.REFERRAL)}#`}
              </ReduxColumn>
            )}
            <ReduxColumn
              minWidth={"110px"}
              width={"110px"}
              type="link"
              isClickable="false"
              field="account_name"
              sortBy="account_name"
              renderField={row => (
                <Link
                  to={
                    row.type == Referral.TYPE_REQUEST
                      ? `/referral/request/${row.id}`
                      : `/referral/edit/${row.id}`
                  }
                >
                  {row.account_name}
                </Link>
              )}
            >
              {`${Terminology.get(SystemName.ACCOUNT)}`}
            </ReduxColumn>
            <ReduxColumn
              minWidth={"110px"}
              width={"110px"}
              field="name"
              sortBy="first_name"
              renderField={row => (
                <span>{getFullName(row.first_name, row.last_name)}</span>
              )}
            >
              Contact
            </ReduxColumn>
            <ReduxColumn
              minWidth={"150px"}
              width={"150px"}
              sortBy="account_name"
              field="companyName"
            >
              Partner
            </ReduxColumn>
            <ReduxColumn
              minWidth={"100px"}
              maxWidth={"100px"}
              sortBy="status"
              field="status"
              className="text-center"
            >
              Status
            </ReduxColumn>
            <ReduxColumn
              minWidth={"110px"}
              width={"110px"}
              sortBy="amount"
              field="amount"
              className="text-right"
              renderField={row => (
                <span>
                  {row.amount != null ? (
                    <Currency amount={row.amount} currencyName={row.currency} />
                  ) : (
                    ""
                  )}
                </span>
              )}
            >
              Amount
            </ReduxColumn>
            {referralRequestEnabled && (
              <ReduxColumn
                minWidth={"100px"}
                maxWidth={"100px"}
                sortBy="type"
                field="type"
              >
                Type
              </ReduxColumn>
            )}
            <ReduxColumn
              minWidth={"130px"}
              width={"130px"}
              field="createdAt"
              className="text-center"
              sortBy="createdAt"
            >
              Created At
            </ReduxColumn>
            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) && (
              <ReduxColumn
                minWidth={"100px"}
                width={"100px"}
                maxWidth={"100px"}
                field="Action"
                disableOnClick
                renderField={row => (
                  <div className="text-center landing-group-dropdown">
                    <MoreDropdown>
                      {/* Edit Action */}
                      {(row.submitted_by === getUserId() ||
                        isSuperAdmin(this.props.roleId) ||
                        isCompanyAdmin(this.props.roleId) ||
                        isCompanyManager(this.props.roleId)) && (
                        <Link
                          className="text-dark text-decoration-none"
                          to={
                            row.type == Referral.TYPE_REQUEST
                              ? `/referral/request/${row.id}`
                              : `/referral/edit/${row.id}`
                          }
                          style={{ cursor: "pointer" }}
                        >
                          <DropdownItem id="refferalEdit ">
                            Edit Details
                          </DropdownItem>
                        </Link>
                      )}
                      {/* Delete Action */}
                      {(row.submitted_by === getUserId() ||
                        isSuperAdmin(this.props.roleId) ||
                        isCompanyAdmin(this.props.roleId) ||
                        isCompanyManager(this.props.roleId)) && (
                        <DropdownItem
                          id="referralDelete"
                          className="font-weight-bold text-danger"
                          onClick={() =>
                            this.setState({
                              deleteReferralModal: true,
                              deleteReferral: row
                            })
                          }
                        >
                          Delete
                        </DropdownItem>
                      )}
                    </MoreDropdown>
                  </div>
                )}
              >
                Action
              </ReduxColumn>
            )}
          </ReduxTable>
          <DeleteModal
            id="deleteReferal"
            isOpen={deleteReferralModal}
            toggle={() => {
              this.setState({ deleteReferralModal: false });
            }}
            title={`Delete ${Terminology.get(SystemName.REFERRAL)}`}
            label={deleteReferral.account_name}
            deleteFunction={() => this.handleDelete(deleteReferral.id)}
          />

          {/* Referral Decline Modal */}
          {referral_id && referralDeclineModal && (
            <ReferralDeclineModal
              modal={referralDeclineModal}
              setModal={this.toggleModal}
              referralId={referral_id}
              params={{ status: REFERRAL_STATUS_NEW }}
              updateReferralStatus={e => {
                this.updateDeclineStatus(
                  REFERRAL_STATUS_DECLINED,
                  referral_id,
                  e
                );
              }}
            />
          )}
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { updateReferral, deleteReferral, referralDecline, fetchList },
      dispatch
    )
  };
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(AllReferral);
