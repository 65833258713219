import React from "react";
import toast from "../../components/base/Toast";

// Actions
import { fetchCourseList } from "../../actions/course";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient, apiClientGet } from "../../apiClient";

import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { ChevronRight, CheckmarkIcon } from "../../assets/img/icons";
import classnames from "classnames";

// Constants
import {
  TAB_BASICS,
  COURSE_PUBLISHED,
  TAB_DETAILS,
  COURSE_LESSON_STATUS_COMPLETED,
  COURSE_LESSON_STATUS_PENDING,
  TAB_PARTNERS
} from "../../course/Constants";

import ShowMoreContent from "../../components/base/showMoreContent";
import SidebarItem from "./SidebarItem";
import CourseViewCard from "./CourseViewCard";
import LessonModuleTab from "./LessonModuleTab";
import LessonCompleteModal from "./inner-component/LessonCompleteModal";
import { isBadRequest } from "../../common/http";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import Table from "./inner-component/table";

class TrainingCourse extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      currentTab: TAB_BASICS,
      currentLessonData: "",
      courseId: "",
      course: {},
      courseMedia: {},
      userDetail: {},
      currentData: "",
      courseDetails: "",
      questionCount: 0,
      activeTab: TAB_DETAILS,
      lessonCompletedModal: false,
      currentLessonId: "",
      currentLessonTitle: "",
      status: COURSE_LESSON_STATUS_PENDING,
      bannerImageUrl: "",
      answerCount: "",
      userData: "",
      isCollapsed: {}
    };
    this.toggle = this.toggle.bind(this);
  }
  // Toggle Tab
  toggle = tab => {
    if (this.state.activeTab !== tab) {
      this.setState({ activeTab: tab }, () => {
        window.localStorage.setItem("courseActiveTab", this.state.activeTab);
      });
    }
  };

  componentDidUpdate() {
    fetchCourseList();
  }

  //Get Course Details
  getBasicsDetails = async () => {
    this.setState({ isLoading: true }, () => {
      return apiClientGet(
        `${endpoints().courseAPI}/${this.props.match.params.id}`,
        (errMessage, response) => {
          if (errMessage) {
            this.setState({
              isPageNotFound: true,
              isLoading: false
            });
          } else {
            const courseDetails = response && response.data;
            this.setState({ courseDetails: courseDetails });
            this.setState({
              isLoading: false,
              questionCount: courseDetails.totalLessonQuestionsCount,
              course: courseDetails.courseData,
              userDetail: courseDetails.partnerDetail,
              lessonData: courseDetails.lessonData,
              bannerImageUrl: courseDetails.bannerImagePathUrl,
              answerCount: courseDetails.answerCount
            });
            const mediaList = [];
            courseDetails.courseFile.forEach(list => {
              mediaList.push({
                name: list.name,
                fileUrl: list.fileUrl
              });
              this.setState({ courseMedia: mediaList });
            });
          }
        }
      );
    });
  };

  componentDidMount() {
    this.getBasicsDetails();
    this._getCourseAnswers();
  }

  // Get Lesson API Call
  _getLessonList = () => {
    return apiClient
      .get(
        `${endpoints().courseAPI}/search/lesson/${this.props.match.params.id}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({
          isLoading: false,
          lessonData: response.data.lessonData,
          currentLessonData: response.data.lessonData
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isSubmitting: false });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  //Set Complete Modal Open
  handleCompleteLesson = value => {
    this.setState({
      currentLessonTitle: value.lesson_title,
      currentLessonId: value.id
    });
    if (!value.lessonStatus.length > 0)
      this.setState({ lessonCompletedModal: true });
  };
  //Submit Complete Lesson
  handleSubmit(lessonId) {
    return apiClient
      .post(`${endpoints().courseAPI}/user/lesson/${lessonId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this.getBasicsDetails();
          toast.success(successMessage);
          this.setState({ status: COURSE_LESSON_STATUS_COMPLETED });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
        }
      });
  }

  //Get Course Answers
  _getCourseAnswers = () => {
    return apiClient
      .get(
        `${endpoints().courseAPI}/partner/course/${this.props.match.params.id}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({
          answer: response.data
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
  toggleCollapse = dataListId => {
    this.setState(prevState => {
      const updatedCollapseStates = { ...prevState.isCollapsed };
      updatedCollapseStates[dataListId] = !updatedCollapseStates[dataListId];
      return { isCollapsed: updatedCollapseStates };
    });
  };
  render() {
    const {
      course,
      lessonData,
      questionCount,
      answer,
      currentData,
      lessonCompletedModal,
      currentLessonTitle,
      activeTab,
      userDetail,
      courseMedia,
      bannerImageUrl,
      answerCount,
      courseDetails
    } = this.state;
    return (
      <>
        <LessonCompleteModal
          courseName={currentLessonTitle}
          modal={lessonCompletedModal}
          setModal={() => {
            this.setState({ lessonCompletedModal: false });
          }}
          onChange={() => {
            this.handleSubmit(this.state.currentLessonId);
          }}
        />
        <>
          <div className="d-flex justify-content-between">
            <div className="d-flex align-items-center mb-3">
              <a
                className="cursor-pointer text-dark"
                onClick={() => {
                  this.props.history.push(
                    `/course?section=${COURSE_PUBLISHED}`
                  );
                }}
              >
                Training & Resources
              </a>
              <span
                className="text-dark d-inline-flex align-items-center"
                style={{ width: "15px" }}
              >
                <ChevronRight />
              </span>
              <span
                className={[
                  "text-dark",
                  "font-weight-bold",
                  "d-inline-flex",
                  "align-items-center",
                  "project-name",
                  "w-320"
                ].join(" ")}
              >
                <span className="ellipsis">Partner Training Courses</span>
              </span>
              <span
                className="text-dark d-inline-flex align-items-center"
                style={{ width: "15px" }}
              >
                <ChevronRight />
              </span>
              <span
                className={[
                  "text-dark",
                  "font-weight-bold",
                  "d-inline-flex",
                  "align-items-center",
                  "project-name",
                  "w-320"
                ].join(" ")}
              >
                <span className="ellipsis">{course.title}</span>
              </span>
            </div>
          </div>
        </>
        <Nav tabs className="admin-tabs">
          <NavItem>
            <NavLink
              className={classnames({ active: activeTab === TAB_DETAILS })}
              onClick={() => {
                this.toggle(TAB_DETAILS);
              }}
            >
              {TAB_DETAILS}
            </NavLink>
          </NavItem>
          {course &&
            course.courseLesson &&
            course.courseLesson.length > 0 &&
            course.courseLesson.map((LessonModules, i) => {
              let { id } = LessonModules;
              return (
                <NavItem>
                  <NavLink
                    className={classnames({
                      active: activeTab === id
                    })}
                    onClick={() => {
                      this.toggle(id);
                      this.setState({ activeTab: id });
                    }}
                  >
                    {`Lesson Module ${i + 1}`}
                  </NavLink>
                </NavItem>
              );
            })}
          {courseDetails && courseDetails.isPartnerAdmin == true && (
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === TAB_PARTNERS })}
                onClick={() => {
                  this.toggle(TAB_PARTNERS);
                }}
              >
                {TAB_PARTNERS}
              </NavLink>
            </NavItem>
          )}
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={TAB_DETAILS} className="w-100">
            {/* Detail Tab */}
            <div className="project-details mt-3 mx-1">
              <CourseViewCard
                data={course}
                bannerImageUrl={course.banner_image}
              />
              <div className="project-details-body border-top  mx-1 row">
                <div className="col-md-8 border-right pr-0">
                  {course &&
                    course.courseLesson &&
                    course.courseLesson.length > 0 &&
                    course.courseLesson
                      .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
                      .map(LessonModules => {
                        let {
                          id,
                          lesson_title,
                          text_element,
                          lessonStatus,
                          Button
                        } = LessonModules;
                        return (
                          <>
                            <div className="field-wrapper mt-3">
                              <div className="user-wrapper">
                                <div className="d-flex">
                                  {lessonStatus &&
                                    lessonStatus
                                      .sort(
                                        (a, b) =>
                                          parseFloat(a.id) - parseFloat(b.id)
                                      )
                                      .map(details => {
                                        let { status } = details;
                                        return (
                                          <span
                                            className={`lessonCheckbox ${
                                              status && status == 1
                                                ? `checkbox-completed`
                                                : `lessonCheckbox`
                                            }`}
                                            onClick={() =>
                                              this.handleCompleteLesson(
                                                LessonModules
                                              )
                                            }
                                          >
                                            {status ? <CheckmarkIcon /> : ""}
                                          </span>
                                        );
                                      })}
                                  {!lessonStatus.length && (
                                    <span
                                      className={`lessonCheckbox`}
                                      onClick={() =>
                                        this.handleCompleteLesson(LessonModules)
                                      }
                                    >
                                      <CheckmarkIcon />
                                    </span>
                                  )}
                                  <p className="font-weight-bold ml-2 my-2">
                                    {lesson_title}
                                  </p>
                                </div>
                                <ShowMoreContent content={text_element} />
                                <hr></hr>
                              </div>
                            </div>
                          </>
                        );
                      })}
                </div>
                <div className="col-md-4 pl-0 mb-4">
                  <SidebarItem
                    answer={answer}
                    media={courseMedia}
                    course={course}
                    totalQuestionCount={questionCount}
                    answerCount={answerCount}
                    user={userDetail}
                  />
                </div>
              </div>
            </div>
          </TabPane>
          {/* Detail Tab End */}

          {/* Lesson Module Tab */}
          {lessonData &&
            lessonData.length > 0 &&
            lessonData.map((LessonModules, i) => {
              let {
                id,
                lesson_title,
                text_element,
                lessonQuestion,
                lessonMedia,
                multimedia_url
              } = LessonModules;
              return (
                <TabPane tabId={id} className="w-100">
                  <LessonModuleTab
                    count={i}
                    courseMedia={courseMedia}
                    toggle={this.toggle}
                    questionId={id}
                    media={lessonMedia}
                    totalQuestionCount={questionCount}
                    question={lessonQuestion}
                    bannerImage={course.banner_image}
                    lessonTitle={lesson_title}
                    description={text_element}
                    course={course}
                    answerCount={answerCount}
                    answer={answer}
                    user={userDetail}
                    courseDetails={this.getBasicsDetails}
                    multimedia_url={multimedia_url}
                  />
                </TabPane>
              );
            })}
          <TabPane tabId={TAB_PARTNERS}>
            <div className="mb-5 ">
              <ReduxTable
                apiURL={`${endpoints().courseAPI}`}
                params={{ id: this.props.match.params.id }}
                message="You can start by inviting Partners."
                history={this.props.history}
                paramsToUrl={true}
              >
                <ReduxColumn
                  minWidth="170px"
                  isClickable
                  className="text-center  "
                  field="companyName"
                  sortBy="company_name"
                  renderField={row => (
                    <div>
                      <Table
                        data={row}
                        toggleCollapse={this.toggleCollapse}
                        isCollapsed={this.state.isCollapsed}
                      />
                    </div>
                  )}
                >
                  Partner Name
                </ReduxColumn>
              </ReduxTable>
            </div>
          </TabPane>
        </TabContent>
        {/* Lesson Module Tab End */}
      </>
    );
  }
}

export default TrainingCourse;
