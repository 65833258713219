import React from "react";
import toast from "../components/base/Toast";

// Configs
import { endpoints } from "../configs";

// API call
import { apiClient } from "../apiClient";

// Components
import Page404 from "../views/Page404";

// Helper
import { isSuperAdmin, isCompanyAdmin } from "../lib/helper";
import { isBadRequest } from "../common/http";
import ReduxTable, { ReduxColumn } from "../components/base/ReduxTable";
import Spinner from "../components/base/Spinner";
import { connect } from "react-redux";

class UsersSynced extends React.Component {
  state = {
    isLoading: false,
    userLists: "",
    deleteAppModal: false,
    selectedIds: ""
  };

  // Bulk update handler
  handleBulkUpdate = ids => {
    this.setState({ selectedIds: ids });
  };

  // delete user(synced) page api call
  deleteUserFunction = async () => {
    try {
      this.setState({
        isLoading: true
      });
      const response = await apiClient
        .delete(
          `${endpoints().salesforceUserAPI}/salesforce/users/delete/?ids=${
            this.state.selectedIds
          }`
        )
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;

            toast.success(successMessage);
            this.setState({
              userLists: response.data,
              isLoading: false
            });
          }
        });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  render() {
    const { isLoading, selectedIds } = this.state;

    if (!isSuperAdmin(this.props.roleId)) {
      return <Page404 />;
    }
    const { Pagination, activeSection } = this.props;

    if (isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <div>
          <div className="row mb-2 ">
            <div className="col-6">
              <h5 className="mt-2">Users (Synced)</h5>
            </div>
            <div className="col-6">
              <button
                type="button"
                className="m-1 pull-right btn btn-danger"
                onClick={() => {
                  this.deleteUserFunction();
                }}
                disabled={selectedIds && selectedIds.length > 0 ? false : true}
              >
                Delete
              </button>
            </div>
          </div>
          <ReduxTable
            bulkSelect
            onBulkSelect={e => {
              this.handleBulkUpdate(e);
            }}
            id="users"
            apiURL={`${endpoints().salesforceUserAPI}/salesforce/users/search`}
            paramsToUrl={true}
            history={this.props.history}
            isOnCheckIntegerValue={true}
          >
            <ReduxColumn field="salesforce_id" sortBy="salesforce_id">
              Salesforce_id
            </ReduxColumn>
            <ReduxColumn field="name" sortBy="first_name">
              Name
            </ReduxColumn>
            <ReduxColumn field="email" sortBy="email">
              Email
            </ReduxColumn>
          </ReduxTable>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(UsersSynced);
