import React, { Component } from "react";

// Components
import Text from "../../components/base/Text";

class ProjectCategoryFormFields extends Component {
  // Render the form fields
  render() {
    return (
      <div>
        <div className="row">
          <div className="col">
            <Text
              name="name"
              label="Name"
              placeholder="Enter name"
              required={true}
              error=""
            />
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectCategoryFormFields;
