import React, { useState } from "react";
import { Alert } from "reactstrap";

// Assets
import { CheckmarkCircleIcon, CrossIcon } from "../../../assets/img/icons";

const CustomerProjectMatchingAlert = props => {
  const { handleCustomerProjectMatchingAlert } = props;
  const message =
    "We are matching your project with an expert. Click on your active project card for more details.";

  return (
    <div className="alerts-wrapper">
      <Alert
        color="success"
        className={
          "d-flex align-items-center justify-content-stretch text-white"
        }
      >
        <CheckmarkCircleIcon />
        <p className="ml-3 mb-0 mr-auto text-white">
          <b>{message}</b>
        </p>
        <div className="close-alert mr-0 ml-0">
          <button
            className="btn-plain btn text-white p-0"
            onClick={handleCustomerProjectMatchingAlert}
          >
            <CrossIcon />
          </button>
        </div>
      </Alert>
    </div>
  );
};

export default CustomerProjectMatchingAlert;
