import React from "react";
import AddTagTypeForm from "./AddTagTypeForm";

// Components
import PageTitle from "../../components/base/PageTitle";

const AddTagType = props => {
  return (
    <div>
      <PageTitle label="Add tag type" />

      <AddTagTypeForm history={props.history} />
    </div>
  );
};

export default AddTagType;
