import { apiClient } from "../apiClient";

// Toast
import toast from "../components/base/Toast";

// Configs
import { endpoints } from "../configs";
import { fetchList } from "./table";

import {
  REQUEST_UPDATE_DEAL,
  RECEIVE_UPDATE_DEAL,
  DEAL_UPDATE_ERROR,
  REQUEST_DELETE_DEAL,
  DEAL_DELETE_ERROR
} from "../actionType/Constants";
import {
  DEAL_STATUS_APPROVED,
  DEAL_STATUS_CLOSED,
  DEAL_STATUS_DECLINED,
  DEAL_STATUS_REQUESTED
} from "../dealStatus/Constants";
import {
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  getParamsByName
} from "../lib/helper";
import { isBadRequest } from "../common/http";

/**
 * Request for updating deal
 */
export function requestUpdateDeal() {
  return {
    type: REQUEST_UPDATE_DEAL
  };
}

/**
 * Receive for updating deal
 */
export function receiveUpdateDeal() {
  return {
    type: RECEIVE_UPDATE_DEAL
  };
}

/**
 * Receive for error updating deal
 */
export function dealUpdateError(error) {
  return {
    type: DEAL_UPDATE_ERROR,
    error
  };
}

/**
 * Update deal
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateDeal(id, data, paramsObj, searchTerm, roleId) {
  return dispatch => {
    dispatch(requestUpdateDeal());
    apiClient
      .put(`${endpoints().dealsAPI}/status/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        // const params = { pagination: true, ...paramsObj };
        let {
          sort,
          sortDir,
          page,
          pageSize,
          pagination,
          status,
          search
        } = paramsObj;
        if (
          isSuperAdmin(roleId) ||
          isCompanyAdmin(roleId) ||
          isCompanyManager(roleId)
        ) {
          dispatch(
            fetchList("allDeals", `${endpoints().dealsAPI}/search`, 1, 10, {
              search: search || "",
              sort: sort || "createdAt",
              sortDir: sortDir || "ASC",
              pagination: true,
              status: status
            })
          );
        } else if (isPartner(roleId)) {
          dispatch(
            fetchList("myDeals", `${endpoints().dealsAPI}/search`, 1, 10, {
              search: search || "",
              sort: sort || "createdAt",
              sortDir: sortDir || "ASC",
              pagination: true,
              status: `${DEAL_STATUS_REQUESTED},${DEAL_STATUS_APPROVED}`
            })
          );
        }
        dispatch(receiveUpdateDeal());
      })
      .catch(error => {
        dispatch(dealUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Request for deleting landing page
 */
export function requestDeleteDeal() {
  return {
    type: REQUEST_DELETE_DEAL
  };
}

/**
 * Receive for error deleting landing page
 */
export function dealDeleteError(error) {
  return {
    type: DEAL_DELETE_ERROR,
    error
  };
}

/**
 * Delete landing page
 *
 * @param id
 * @returns {function(*): *}
 */
export function deleteDeal(id, roleId) {
  return dispatch => {
    dispatch(requestDeleteDeal());
    apiClient
      .delete(`${endpoints().dealsAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        const params = {
          search: getParamsByName("search") || "",
          sort: getParamsByName("sort") || "createdAt",
          sortDir: getParamsByName("sortDir") || "ASC",
          pagination: true
        };
        if (
          isSuperAdmin(roleId) ||
          isCompanyAdmin(roleId) ||
          isCompanyManager(roleId)
        ) {
          dispatch(
            fetchList("allDeals", `${endpoints().dealsAPI}/search`, 1, 10, {
              ...params,
              status: getParamsByName("status")
            })
          );
        } else if (isPartner(roleId)) {
          dispatch(
            fetchList("myDeals", `${endpoints().dealsAPI}/search`, 1, 10, {
              ...params,
              status: `${DEAL_STATUS_REQUESTED},${DEAL_STATUS_APPROVED}`
            })
          );
        }
      })
      .catch(error => {
        dispatch(dealDeleteError(error));
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(errorMessage);
        console.error(errorMessage);
      });
  };
}
