import React, { Component, Suspense } from "react";
import { Route, Switch } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

// routes config
import routes from "../../routes";

// Components
import SideBar from "../../components/SideBar";
import DashboardNavigation from "../../components/header/DashboardNavigation";

// Constants
import {
  SETTINGS_PORTAL_NAME,
  SETTINGS_PORTAL_LOGO_REDIRECT_URL,
  SETTINGS_PORTAL_LOGO,
  SETTINGS_PORTAL_FOOTER_COLOR,
  SETTINGS_PORTAL_FOOTER_HEADING_COLOR,
  SETTINGS_PORTAL_FOOTER_TEXT_COLOR,
  SETTINGS_PORTAL_FOOTER_COPY_RIGHTS_TEXT,
  SETTINGS_RAMBLE_CHAT_BOT_SCRIPT,
  SETTINGS_PORTAL_HEADER_COLOR,
  SETTINGS_PORTAL_HEADER_TEXT_COLOR,
  SETTINGS_PORTAL_FAV_ICON,
  SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_IMAGE,
  SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_COLOR,
  SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_HOVER_OVER_COLOR,
  SETTINGS_ENABLE_HELP,
  SETTINGS_GA_TRACKING_ID,
  SETTINGS_RAMBLE_CHAT_ENABLE_ADMIN,
  SETTINGS_RAMBLE_CHAT_ENABLE_CUSTOMER,
  SETTINGS_RAMBLE_CHAT_ENABLE_PARTNER,
  SETTINGS_RAMBLE_CHAT_ENABLE_EXPERT,
  SETTINGS_FACEBOOK_URL,
  SETTINGS_INSTAGRAM_URL,
  SETTINGS_LINKEDIN_URL,
  SETTINGS_TWITTER_URL,
  SETTINGS_YOUTUBE_URL,
  SETTINGS_THEME_LAYOUT,
  THEME_LAYOUT_FLUID_WIDTH,
  SETTING_ENABLE_PARTNER_TERMS_AND_CONDITIONS_UPDATE_NOTIFICATION,
  SETTINGS_PORTAL_TAG_LINE
} from "../../setting/Constants";

import {
  getCookie,
  isExpert,
  isLoggedIn,
  getKeyValueByObject,
  getSettingMediaUrl,
  isSuperAdmin,
  isCompanyAdmin,
  isCustomer,
  isPartner,
  isCompanyManager
} from "../../lib/helper";

// API Call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Constants
import { USER_EMAIL_VERIFICATION_REQUIRED } from "../../users/Constants";
import { COOKIE_SESSION_TOKEN } from "../../lib/cookie";

// Context
import UserProvider from "../../context/userContext/UserProvider";

import { GAPageView } from "../../lib/GoogleAnalytics";
import { isBadRequest } from "../../common/http";
import { getDestinationUrl } from "../../url/Service";

const DefaultFooter = React.lazy(() => import("./DefaultFooter"));

class DefaultLayout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      avatarUrl: "",
      isLoading: false,
      userId: null,
      allowAccess: true,
      settingsData: [],
      companyList: []
    };

    this.toggle = this.toggle.bind(this);
  }

  async componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    const settings = this.props.settings && this.props.settings.settings;
    const termsAndConditionStatus =
      settings &&
      getKeyValueByObject(
        settings,
        SETTING_ENABLE_PARTNER_TERMS_AND_CONDITIONS_UPDATE_NOTIFICATION
      );

    if (
      (isExpert(this.props.user.roleId) || isPartner(this.props.user.roleId)) &&
      getCookie(COOKIE_SESSION_TOKEN)
    ) {
      this.checkUserVerification(termsAndConditionStatus);
    }
    this.getUrl();

    this.getCompanyList();

    this.gaTrackingView();

    const partnerSettings =
      this.props.partnerSettings && this.props.partnerSettings.partnerSettings;
    if (settings) {
      const rambleChatScript =
        settings &&
        (await getKeyValueByObject(settings, SETTINGS_RAMBLE_CHAT_BOT_SCRIPT));

      const enableAdminRambleChat =
        settings &&
        (await getKeyValueByObject(
          settings,
          SETTINGS_RAMBLE_CHAT_ENABLE_ADMIN
        ));
      const enableCustomerRambleChat =
        settings &&
        (await getKeyValueByObject(
          settings,
          SETTINGS_RAMBLE_CHAT_ENABLE_CUSTOMER
        ));
      const enablePartnerRambleChat =
        settings &&
        (await getKeyValueByObject(
          settings,
          SETTINGS_RAMBLE_CHAT_ENABLE_PARTNER
        ));
      const enableExpertRambleChat =
        settings &&
        (await getKeyValueByObject(
          settings,
          SETTINGS_RAMBLE_CHAT_ENABLE_EXPERT
        ));

      // Check Enable Chat Bot
      const enableRambleChatBot =
        ((isSuperAdmin(this.props.user.roleId) ||
          isCompanyAdmin(this.props.user.roleId) ||
          isCompanyManager(this.props.user.roleId)) &&
          enableAdminRambleChat &&
          enableAdminRambleChat === "true") ||
        (isCustomer(this.props.user.roleId) &&
          enableCustomerRambleChat &&
          enableCustomerRambleChat === "true") ||
        (isPartner(this.props.user.roleId) &&
          enablePartnerRambleChat &&
          enablePartnerRambleChat === "true") ||
        (isExpert(this.props.user.roleId) &&
          enableExpertRambleChat &&
          enableExpertRambleChat === "true");

      if (
        enableRambleChatBot &&
        rambleChatScript &&
        rambleChatScript.length > 0
      ) {
        // Create Footer Script Element
        const script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        const scriptElementCode = document.createTextNode(rambleChatScript);
        script.appendChild(scriptElementCode);
        document.body.appendChild(script);
      }
    }
  }

  componentDidUpdate() {
    this.gaTrackingView();
  }

  //Get Url
  getUrl = async () => {
    let redirectUrl = await getDestinationUrl();
    if (redirectUrl) {
      window.location.replace(redirectUrl);
    }
  };

  // GA Tracking View
  gaTrackingView = () => {
    const settingData =
      this.props.settings && this.props.settings.settings
        ? this.props.settings.settings
        : "";

    if (settingData) {
      const gaTrackingId = getKeyValueByObject(
        settingData,
        SETTINGS_GA_TRACKING_ID
      );

      window.ga("create", `${gaTrackingId}`, "auto");

      GAPageView();
    }
  };

  checkTermsAndCondition = (status, termsAccepted) => {
    if (status && status == "true" && !termsAccepted) {
      this.setState({ allowAccess: false });
    }
  };

  // Get user email verification
  checkUserVerification = async termsAndConditionStatus => {
    await apiClient
      .get(`${endpoints().userAPI}`)
      .then(response => {
        if (response && response.data) {
          const { emailVerification, terms_accepted_at } = response.data;
          //Check Email Verification
          this.setState({
            allowAccess:
              emailVerification === USER_EMAIL_VERIFICATION_REQUIRED
                ? false
                : true
          });
          //Check Terms and Condition Verification
          this.checkTermsAndCondition(
            termsAndConditionStatus,
            terms_accepted_at
          );
          this.getPartnerDetails();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // getPartnerDetails
  getPartnerDetails = () => {
    apiClient.get(`${endpoints().partnerAPI}/}`).then(response => {
      if (response && response.data) {
        const { marketplaceStatus } = response.data;
        this.setState({
          marketplaceStatus
        });
      }
    });
  };

  // Get portal company list
  getCompanyList = () => {
    apiClient
      .get(`${endpoints().companyAPI}/user/company/list`)
      .then(response => {
        if (response && response.data) {
          const companies = [];
          const companyList = response.data && response.data.data;
          if (companyList.length > 0) {
            companyList.forEach(company => {
              companies.push({
                ...company,
                label: company.companyName,
                value: company.id
              });
            });
          }

          this.setState({
            companyList: companies
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // User Company Update
  handleCompanyChange = value => {
    apiClient
      .put(`${endpoints().companyAPI}/user/company/update`, {
        companyId: value
      })
      .then(response => {
        if (response && response.data) {
          const redirectUrl =
            response.data.data && response.data.data.redirectUrl;

          if (redirectUrl) {
            window.location.replace(`${redirectUrl}/dashboard`);
          } else {
            window.location.replace("/dashboard");
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  loading = () => (
    <div className="animated fadeIn pt-1 text-center">Loading...</div>
  );

  toggle() {
    this.setState({
      isOpen: !this.state.isOpen
    });
  }

  render() {
    const settings =
      this.props.settings && this.props.settings.settings
        ? this.props.settings.settings
        : "";
    const partnerSettings =
      this.props.partnerSettings && this.props.partnerSettings.partnerSettings
        ? this.props.partnerSettings.partnerSettings
        : "";

    const user = this.props.user && this.props.user ? this.props.user : "";

    const facebookUrl = settings
      ? getKeyValueByObject(settings, SETTINGS_FACEBOOK_URL)
      : "";
    const instagramUrl = settings
      ? getKeyValueByObject(settings, SETTINGS_INSTAGRAM_URL)
      : "";
    const linkedInUrl = settings
      ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_URL)
      : "";
    const twitterUrl = settings
      ? getKeyValueByObject(settings, SETTINGS_TWITTER_URL)
      : "";
    const youtubeUrl = settings
      ? getKeyValueByObject(settings, SETTINGS_YOUTUBE_URL)
      : "";
    const enablePartnerTermsAndConditions = settings
      ? getKeyValueByObject(
          settings,
          SETTING_ENABLE_PARTNER_TERMS_AND_CONDITIONS_UPDATE_NOTIFICATION
        )
      : "";
    const marketplaceLogoRedirectUrl = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO_REDIRECT_URL)
      : "";

    const portalLogo =
      settings && getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO)
          )
        : "";

    let name = document.getElementById("portalName");
    const portalName = getKeyValueByObject(settings, SETTINGS_PORTAL_NAME);
    const portalTagLine = getKeyValueByObject(
      settings,
      SETTINGS_PORTAL_TAG_LINE
    );

    if (portalName) {
      name.innerHTML = portalName;
    }
    const favIcon = document.getElementById("marketPlaceFavIcon");
    const marketplaceFavIconUrl =
      settings && getKeyValueByObject(settings, SETTINGS_PORTAL_FAV_ICON)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, SETTINGS_PORTAL_FAV_ICON)
          )
        : "";
    if (marketplaceFavIconUrl && favIcon.href !== marketplaceFavIconUrl) {
      favIcon.href = marketplaceFavIconUrl;
    }

    // const legalDisclosure = getKeyValueByObject(
    //   settings,
    //   SETTINGS_LEGAL_DISCLOSURE
    // );

    const headerColor = getKeyValueByObject(
      settings,
      SETTINGS_PORTAL_HEADER_COLOR
    );

    const headerTextColor = getKeyValueByObject(
      settings,
      SETTINGS_PORTAL_HEADER_TEXT_COLOR
    );

    const footerColor = getKeyValueByObject(
      settings,
      SETTINGS_PORTAL_FOOTER_COLOR
    );
    const footerHeadingColor = getKeyValueByObject(
      settings,
      SETTINGS_PORTAL_FOOTER_HEADING_COLOR
    );

    const footerTextColor = getKeyValueByObject(
      settings,
      SETTINGS_PORTAL_FOOTER_TEXT_COLOR
    );

    const footerCopyRightsText = getKeyValueByObject(
      settings,
      SETTINGS_PORTAL_FOOTER_COPY_RIGHTS_TEXT
    );

    const leftNavigationBackgroundImage =
      settings &&
      getKeyValueByObject(
        settings,
        SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_IMAGE
      )
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(
              settings,
              SETTINGS_PORTAL_LEFT_NAVIGATION_BACKGROUND_IMAGE
            )
          )
        : "";

    const leftNavigationTextColor = settings
      ? getKeyValueByObject(
          settings,
          SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_COLOR
        )
      : "";

    const leftNavigationTextHoverColor = settings
      ? getKeyValueByObject(
          settings,
          SETTINGS_PORTAL_LEFT_NAVIGATION_TEXT_HOVER_OVER_COLOR
        )
      : "";

    const enableHelp = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_HELP)
      : "";
    const DefaultLayout = settings
      ? getKeyValueByObject(settings, SETTINGS_THEME_LAYOUT)
      : "";
    const { allowAccess, companyList, marketplaceStatus } = this.state;

    const pathName = window.location.href;

    const currentRoute = window.location.pathname;

    let showNav = "";
    if (
      pathName.includes("/find-an-expert") > 0 ||
      pathName.includes("/expert-signup") > 0 ||
      pathName.includes("/partner-signup") > 0 ||
      pathName.includes("/playbook-view") > 0 ||
      pathName.includes("/partner-profile") > 0
    ) {
      showNav = "d-none";
    }

    let changeNavBarColor;
    if (pathName.includes("/expert-availability") > 0) {
      changeNavBarColor = "bg-color-black";
    }

    const hideComponent =
      pathName.includes("/accounting-settings") > 0 ||
      pathName.includes("/edit/public-profile") > 0;

    return (
      <div className="app">
        <UserProvider>
          <div className="app-body">
            {!hideComponent && (
              <ToastContainer
                autoClose={4000}
                pauseOnHover={false}
                toastClassName="toastRequestSuccess"
                bodyClassName="toastBody"
              />
            )}
            <main
              className="main drawer-container"
              style={{ position: "relative" }}
            >
              <DashboardNavigation
                layout={DefaultLayout}
                pathName={pathName}
                allowAccess={allowAccess}
                partnerStatus={marketplaceStatus}
                showNav={showNav}
                changeNavBarColor={changeNavBarColor}
                portalLogo={portalLogo}
                portalName={portalName}
                marketplaceLogoRedirectUrl={marketplaceLogoRedirectUrl}
                headerColor={headerColor}
                headerTextColor={headerTextColor}
                enableHelp={enableHelp}
                companyList={companyList}
                handleCompanyChange={this.handleCompanyChange}
                roleId={this.props.user.roleId}
                id={user && user.partnerId}
                portalTagLine={portalTagLine}
              />

              {getCookie(COOKIE_SESSION_TOKEN) &&
              !pathName.includes("/expert-availability") &&
              !pathName.includes("/find-an-expert") &&
              !pathName.includes("/expert-profile/") &&
              !pathName.includes("/expert-signup") &&
              !pathName.includes("/partner-signup") ? (
                <>
                  <div
                    className="left-nav-bg"
                    style={{
                      background: `url(${leftNavigationBackgroundImage})`
                    }}
                  ></div>
                  <div
                    className={`${
                      DefaultLayout &&
                      DefaultLayout === THEME_LAYOUT_FLUID_WIDTH
                        ? "container-fluid"
                        : "container"
                    }`}
                  >
                    <div className="row d-flex flex-nowrap">
                      <SideBar
                        enable={allowAccess}
                        partnerStatus={marketplaceStatus}
                        settings={
                          (this.props.settings &&
                            this.props.settings.settings) ||
                          ""
                        }
                        partnerSettings={
                          (this.props.partnerSettings &&
                            this.props.partnerSettings.partnerSettings) ||
                          ""
                        }
                        leftNavigationBackgroundImage={
                          leftNavigationBackgroundImage
                        }
                        leftNavigationTextColor={leftNavigationTextColor}
                        leftNavigationTextHoverColor={
                          leftNavigationTextHoverColor
                        }
                        user={user}
                        currentRoute={currentRoute}
                      />
                      <div
                        className={`${
                          DefaultLayout &&
                          DefaultLayout === THEME_LAYOUT_FLUID_WIDTH
                            ? "container-full-width main-wrapper"
                            : "col-lg-9 pr-md-0 main-wrapper"
                        }`}
                      >
                        <div className="container-fluid site-maincontent">
                          <Suspense>
                            <Switch>
                              {routes.map((route, idx) => {
                                return route.component ? (
                                  <Route
                                    key={idx}
                                    path={route.path}
                                    exact={route.exact}
                                    name={route.name}
                                    render={props => (
                                      <route.component
                                        {...props}
                                        settings={settings}
                                        partnerSettings={partnerSettings}
                                        loggedInUser={user}
                                      />
                                    )}
                                  />
                                ) : null;
                              })}
                            </Switch>
                          </Suspense>
                        </div>
                        {/* /.main-content */}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <Suspense fallback={this.loading()}>
                  <Switch>
                    {routes.map((route, idx) => {
                      return route.component ? (
                        <Route
                          key={idx}
                          path={route.path}
                          exact={route.exact}
                          name={route.name}
                          render={props => (
                            <route.component
                              {...props}
                              settings={settings}
                              partnerSettings={partnerSettings}
                            />
                          )}
                        />
                      ) : null;
                    })}
                  </Switch>
                </Suspense>
              )}
            </main>
          </div>
          <footer
            className={`footer ${showNav}`}
            style={{
              backgroundColor: footerColor,
              color: footerTextColor ? footerTextColor : "#FFFFFF"
            }}
          >
            <div className="container">
              <Suspense fallback={this.loading()}>
                <DefaultFooter
                  footerColor={footerColor}
                  footerHeadingColor={footerHeadingColor}
                  footerTextColor={footerTextColor}
                  footerCopyRightsText={footerCopyRightsText}
                  facebookUrl={facebookUrl}
                  instagramUrl={instagramUrl}
                  linkedInUrl={linkedInUrl}
                  twitterUrl={twitterUrl}
                  youtubeUrl={youtubeUrl}
                />
              </Suspense>
            </div>
          </footer>
        </UserProvider>
      </div>
    );
  }
}

export default DefaultLayout;
