import React, { useState } from "react";
import Text from "../../../components/base/Text";
import Select from "../../../components/base/Select";
import Button from "../../../components/base/Button";
import CoSell from "../../../Constants/Cosell";
import Form from "../../../components/base/Form";
import TextArea from "../../../components/base/TextArea";
import Radio from "../../../components/base/Radio";
import DateInput from "../../../components/base/Date";
import Multiselect from "../../../components/base/Multiselect";
import Number from "../../../components/base/Number";
import { getUrlParameter } from "../../../lib/helper";
import URL from "../../../components/base/URL";
import Country from "../../../components/base/Country";
import StateDropdown from "../../../components/State";
import Zipcode from "../../../components/base/Zipcode";
import { UNITED_STATES } from "../../../Country/Constants";
import Email from "../../../components/base/Email";
import Phone from "../../../components/base/Phone";
import DealFormFields from "../../deals/DealFormFields";
import ProjectDetailTab from "./ProjectDetailSection";
import AdditionalDetailSection from "./AdditionalDetailSection";
import ContactDetailTab from "./ContactDetailsSection";
import PageTitle from "../../../components/base/PageTitle";
import SaveButton from "../../../components/base/SaveButton";

const SummaryTab = props => {
  let {
    awsDetail,
    showButton,
    detail,
    enableReinitialize,
    handleUpdate
  } = props;

  const [isOpen, setIsOpen] = useState({
    customerDetail: true,
    AdditionalDetailSection: true,
    projectDetail: true,
    contactDetail: true
  });
  const [selectedCountry, setSelectedCountry] = useState("");
  const [selectedCountryName, setSelectedCountryName] = useState("");

  const toggleAccordion = section => {
    setIsOpen(prevState => ({
      ...prevState,
      [section]: !prevState[section]
    }));
  };
  let awsProductValue = [];

  let awsDealList =
    awsDetail && awsDetail.aws_products && awsDetail.aws_products.split(",");
  if (awsDealList && awsDealList.length > 0) {
    for (let i = 0; i < awsDealList.length; i++) {
      let awsDeal = props.awsProductOption.find(
        value => value.value == awsDealList[i]
      );
      awsProductValue.push(awsDeal ? awsDeal : "");
    }
  }

  let deliveryModelValue = [];

  let deliveryList =
    awsDetail &&
    awsDetail.delivery_model &&
    awsDetail.delivery_model.split(",");
  if (deliveryList && deliveryList.length > 0) {
    for (let i = 0; i < deliveryList.length; i++) {
      let awsDeal = props.deliveryModelOption.find(
        value => value.value === deliveryList[i]
      );
      deliveryModelValue.push(awsDeal ? awsDeal : "");
    }
  }

  let apnProgramValue = [];

  let apnList =
    awsDetail && awsDetail.apn_programs && awsDetail.apn_programs.split(",");
  if (apnList && apnList.length > 0) {
    for (let i = 0; i < apnList.length; i++) {
      let awsDeal = props.apnProgramOption.find(
        value => value.value == apnList[i]
      );
      apnProgramValue.push(awsDeal ? awsDeal : "");
    }
  }

  let saleActivity = [];

  let saleActivityList =
    awsDetail &&
    awsDetail.sales_activities &&
    awsDetail.sales_activities.split(",");
  if (saleActivityList && saleActivityList.length > 0) {
    for (let i = 0; i < saleActivityList.length; i++) {
      let awsDeal = props.salesActivityOption.find(
        value => value.value === saleActivityList[i]
      );
      saleActivity.push(awsDeal ? awsDeal : "");
    }
  }

  let cosellWithAwsObj = CoSell.cosellWithAwsOption.find(
    item =>
      item.value == awsDetail &&
      awsDetail.share_with_aws &&
      awsDetail.share_with_aws
  );

  let oppTypeObj = CoSell.opportunityTypeOption.find(
    item =>
      item.value == awsDetail &&
      awsDetail.opportunity_type &&
      awsDetail.opportunity_type
  );
  let oppSource = CoSell.opportunitySourceOption.find(
    item =>
      item.value == awsDetail &&
      awsDetail.opportunity_source &&
      awsDetail.opportunity_source
  );

  let customerDetailInitialValues = {
    accountName: detail.accountName
      ? detail.accountName
      : getUrlParameter("company_name"),
    website: detail && detail.website,
    firstName: detail && detail.firstName,
    lastName: detail && detail.lastName,
    title: detail && detail.title,
    email: detail && detail.email,
    phone: detail && detail.phone,
    notes: detail && detail.notes,
    address: detail && detail.address,
    city: detail && detail.city,
    postalCode: detail && detail.postalCode,
    customer_duns: detail && detail.customer_duns,
    industry_vertical: props.industryVerticalOption.find(
      value => value.value == detail.industry_vertical
    ),
    state: {
      value: detail && detail.state ? detail && detail.state : "",
      label: detail && detail.state ? detail && detail.state : ""
    },
    target_close_date: (awsDetail && awsDetail.target_close_date) || "",
    country: {
      value: props.selectedCountryName
        ? props.selectedCountryName
        : detail && detail.country
        ? detail && detail.country
        : "",
      label: props.selectedCountryName
        ? props.selectedCountryName
        : detail && detail.country
        ? detail && detail.country
        : ""
    }
  };
  let projectDetailInitialValues = {
    share_with_aws: "",
    opportunity_type: "",
    partner_project_title: (awsDetail && awsDetail.partner_project_title) || "",
    sales_activities: saleActivity || "",
    customer_business_problem:
      (awsDetail && awsDetail.customer_business_problem) || "",
    solutions_offered:
      props.solutionOfferedOption.find(
        value => value.value == awsDetail.solutions_offered
      ) || "",
    other_solution_offered:
      (awsDetail && awsDetail.other_solution_offered) || "",
    aws_products: awsProductValue.length > 0 ? awsProductValue : "",
    next_step: (awsDetail && awsDetail.next_step) || "",
    use_case: props.useCaseOption.find(
      value => value.value == awsDetail.use_case
    ),
    delivery_model: deliveryModelValue || "",
    estimated_aws_monthly_recurring_revenue:
      (awsDetail && awsDetail.estimated_aws_monthly_recurring_revenue) || "",
    apn_programs: awsProductValue.length > 0 ? awsProductValue : "",
    opportunity_source: ""
  };
  let additionalDetailInitialValues = {
    partner_crm_unique_identifier:
      (awsDetail && awsDetail.partner_crm_unique_identifier) || "",
    competitive_tracking:
      (awsDetail &&
        props.competitiveTrackingOption.find(
          value => value.value == awsDetail.competitive_tracking
        )) ||
      "",
    aws_account_id: (awsDetail && awsDetail.aws_account_id) || "",
    additional_comments: (awsDetail && awsDetail.additional_comments) || ""
  };

  let partnerDetailInitialValues = {
    primary_contact_first_name:
      (awsDetail && awsDetail.primary_contact_first_name) || "",
    primary_contact_last_name:
      (awsDetail && awsDetail.primary_contact_last_name) || "",
    primary_contact_title: (awsDetail && awsDetail.primary_contact_title) || "",
    primary_contact_email: (awsDetail && awsDetail.primary_contact_email) || "",
    primary_contact_phone: (awsDetail && awsDetail.primary_contact_phone) || ""
  };

  let initialValues = {
    ...customerDetailInitialValues,
    ...projectDetailInitialValues,
    ...additionalDetailInitialValues,
    ...partnerDetailInitialValues
  };

  // Handle Country
  const handleCountryChange = ({ values }) => {
    const selectedOption = values.country && values.country.id;
    const selectedCountryName = values.country && values.country.label;

    setSelectedCountry(selectedOption);
    setSelectedCountryName(selectedCountryName);
  };

  return (
    <div>
      <div className="mt-2 d-flex justify-content-between">
        <PageTitle label="Summary" />
        <>
          <Button
            label="Submit"
            type="submit"
            onClick={() => {
              props.handleSummaryUpdate(initialValues);
            }}
            className=" mt-3 mb-3 mx-3"
          />
        </>
      </div>
      <div className="card border-0">
        <div
          style={{
            backgroundColor: "#fafafa",
            borderBottom: "1px solid",
            borderColor: "#eaeded"
          }}
        ></div>
        <div
          className="accordion-header  cursor-pointer p-2 mt-2 rounded"
          onClick={() => toggleAccordion("customerDetail")}
        >
          <div className="d-flex justify-content-between bg-light-grey w-100  p-2">
            Customer Detail
            <span
              className={
                isOpen.customerDetail
                  ? "fa fa-chevron-up "
                  : "fa fa-chevron-down "
              }
            ></span>
          </div>
        </div>
        {isOpen.customerDetail && (
          <Form
            enableReinitialize={enableReinitialize}
            initialValues={customerDetailInitialValues}
            enableReferrals={true}
            onSubmit={values => {
              handleUpdate(values);
            }}
          >
            <DealFormFields
              handleAwsShareChange={props.handleAwsShareChange}
              shareAws={cosellWithAwsObj}
              handleOppTypeChange={props.handleOppTypeChange}
              oppType={oppTypeObj}
              dealDetails={awsDetail}
              handleCountryChange={handleCountryChange}
              selectedCountryName={selectedCountryName}
              selectedCountry={selectedCountry}
              oppSource={oppSource}
              industryVerticalOption={props.industryVerticalOption}
              id={getUrlParameter("id")}
            />
            <div className="d-flex justify-content-end">
              <Button label="Save" type="submit" className=" mt-3 mb-3 mx-3" />
            </div>
          </Form>
        )}
        <div
          className="accordion-header  cursor-pointer p-2 rounded"
          onClick={() => toggleAccordion("projectDetail")}
        >
          <div className="d-flex justify-content-between bg-light-grey w-100  p-2">
            Project Detail
            <span
              className={
                isOpen.projectDetail
                  ? "fa fa-chevron-up "
                  : "fa fa-chevron-down "
              }
            ></span>
          </div>
        </div>
        {isOpen.projectDetail && (
          <ProjectDetailTab
            history={props.history}
            handleUpdate={props.handleProjectUpdate}
            handleOppTypeChange={props.handleOppTypeChange}
            handleAwsShareChange={props.handleAwsShareChange}
            handleOppSourceChange={props.handleOppSourceChange}
            detail={props.awsDetail}
            enableSaveButton
            awsProductOption={props.awsProductOption}
            deliveryModelOption={props.deliveryModelOption}
            apnProgramOption={props.apnProgramOption}
            salesActivityOption={props.salesActivityOption}
            useCaseOption={props.useCaseOption}
            solutionOfferedOption={props.solutionOfferedOption}
          />
        )}

        <div
          className="accordion-header  cursor-pointer p-2 rounded"
          onClick={() => toggleAccordion("AdditionalDetailSection")}
        >
          <div className="d-flex justify-content-between bg-light-grey w-100  p-2">
            Additional Detail
            <span
              className={
                isOpen.AdditionalDetailSection
                  ? "fa fa-chevron-up "
                  : "fa fa-chevron-down "
              }
            ></span>
          </div>
        </div>
        {isOpen.AdditionalDetailSection && (
          <AdditionalDetailSection
            history={props.history}
            handleUpdate={props.handleAdditionalDetailUpdate}
            detail={props.awsDetail}
            enableSaveButton
            competitiveTrackingOption={props.competitiveTrackingOption}
          />
        )}

        <div
          className="accordion-header  cursor-pointer p-2 rounded"
          onClick={() => toggleAccordion("contactDetail")}
        >
          <div className="d-flex justify-content-between bg-light-grey w-100  p-2">
            Contact Detail
            <span
              className={
                isOpen.contactDetail
                  ? "fa fa-chevron-up "
                  : "fa fa-chevron-down "
              }
            ></span>
          </div>
        </div>
        {isOpen.contactDetail && (
          <ContactDetailTab
            history={props.history}
            handleUpdate={props.handleContactDetailUpdate}
            detail={props.awsDetail}
            enableSaveButton
          />
        )}
      </div>
    </div>
  );
};

export default SummaryTab;
