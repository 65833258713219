import React from "react";

// Page components
import AddPlaybookForm from "./AddPlaybookForm";

const AddPlaybook = props => {
  return (
    <>
      <AddPlaybookForm history={props.history} />
    </>
  );
};

export default AddPlaybook;
