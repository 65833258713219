import {
  REQUEST_SETTINGS_LIST,
  RECEIVE_SETTINGS_LIST,
  FETCH_SETTINGS_LIST_FAIL
} from "../actionType/Constants";

import { apiClient } from "../apiClient";

import { DEFAULT_API_KEY, endpoints } from "../configs";

// Components
import toast from "../components/base/Toast";
import { getCookie } from "../lib/helper";

import { isBadRequest } from "../common/http";
import HTTPStatus from "../helpers/HTTPStatus";

/**
 * Request for Settings list
 */
function requestSettings() {
  return {
    type: REQUEST_SETTINGS_LIST
  };
}

/**
 * Receive for Settings list
 */
function receiveSettings({ data: payload }) {
  return {
    type: RECEIVE_SETTINGS_LIST,
    payload
  };
}

/**
 * Settings list fails
 */
function fetchSettingsFail() {
  return {
    type: FETCH_SETTINGS_LIST_FAIL,
    payload: []
  };
}

/**
 * Fetch Settings
 */
export function fetchSettings(partnerId) {
  return dispatch => {
    dispatch(requestSettings());

    // Default API Key For Public API
    if (getCookie("session_token")) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        "session_token"
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }

    let params = "";
    if (partnerId) {
      params = `?partnerId=${partnerId}`;
    }

    return apiClient
      .get(`${endpoints().publicAPI}/setting${params}`)
      .then(response => response)
      .then(response => {
        dispatch(receiveSettings(response));
      })
      .catch(error => {
        dispatch(fetchSettingsFail());

        if (
          (error && error.status >= HTTPStatus.BAD_REQUEST) ||
          (error &&
            error.response &&
            error.response.status >= HTTPStatus.BAD_REQUEST)
        ) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}

export function getSettingsData(settingName, callback) {
  return apiClient
    .get(`${endpoints().settingAPI}/${settingName}`)
    .then(response => {
      return callback(response && response.data && response.data.data);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
      return callback(null);
    });
}

export function getSetting(callback) {
  return apiClient
    .get(`${endpoints().settingAPI}`)
    .then(response => {
      return callback(response && response.data && response.data.data);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
      return callback(null);
    });
}

export function fetchPartnerSettings(id, name, callback) {
  try {
    if (id && name) {
      apiClient
        .get(`${endpoints().partnerTypeSettingAPI}/setting/${id}?name=${name}`)
        .then(response => {
          const settings = response && response.data;
          return callback(settings);
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.stringify(errorRequest.response).message;
            }
            return callback(false);
          }
        });
    }
  } catch (err) {
    return callback(false);
  }
}
