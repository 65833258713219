import moment from "moment";

export const formatDate = date => {
  let d = new Date();
  let today = d.getDate();
  let yesterday = d.getDate() - 1;

  if (date === today) {
    return "Today";
  } else if (date === yesterday) {
    return "1 day ago";
  } else if (!date) {
    return "Not Logged in yet";
  } else {
    return date;
  }
};

export const getCurrentDate = () => {
  let format = "YYYY-MM-DD";
  return moment().format(format);
};

export function FormatTimeStampDate(date, withTime) {
  if (date) {
    var formatDate = new Date(date);

    if (withTime) {
      return moment(formatDate).format("DD-MMM-YY HH:mm:ss");
    } else {
      return moment(formatDate).format("DD-MMM-YYYY");
    }
  } else {
    return "";
  }
}

export function calculateDaysBeforeToday(date) {
  if (date) {
    // Create Date objects for the input dates
    const currentDate = new Date(moment(new Date()).format("YYYY-MM-DD"));

    const paramDate = new Date(moment(date).format("YYYY-MM-DD"));

    // Get the time values (milliseconds since Jan 1, 1970) for each date
    const time1 = paramDate.getTime();

    const time2 = currentDate.getTime();

    // Calculate the time difference in milliseconds
    const timeDiffInMs = Math.abs(time2 - time1);

    // Convert the time difference from milliseconds to days
    const daysDiff = timeDiffInMs / (1000 * 60 * 60 * 24);

    return daysDiff;
  }
}
