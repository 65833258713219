import React from "react";
import toast from "../../components/base/Toast";
import moment from "moment";

// Components
import Form from "../../components/base/Form";
import CancelButton from "../../components/base/CancelButton";
import PageTitle from "../../components/base/PageTitle";
import Button from "../../components/base/Button";
import AdminActionNavigation from "../../components/base/AdminActionNavigation";

// Configs
import { endpoints } from "../../configs";
import { getUrlParameter, isPartner } from "../../lib/helper";

// API call
import { apiClient, apiClientGet } from "../../apiClient";

// Helper
import { toString, removeMaskedPhoneNumber } from "../../lib/helper";

// Page Components
import MdfRequestFormFields from "./MdfRequestFormFields";
import SideBar from "./inner-components/SideBar";

//Assets
import { ChevronRight } from "../../assets/img/icons";

// Constant
import { isBadRequest } from "../../common/http";

import { connect } from "react-redux";

class SubmitMdfRequest extends React.Component {
  constructor(props) {
    super(props);
    this.reCaptcha = React.createRef();
    // Set the initial input values
    this.state = {
      loading: false,
      eventDate: "",
      mdfSubmit: false,
      partnerIds: [],
      partnerStatus: "",
      mdfDetails: "",
      status: "",
      currentPartner: ""
    };
  }

  componentDidMount() {
    if (isPartner(this.props.roleId)) {
      this._getCurrentPartner();
    }
  }
  // Get Current Partner
  _getCurrentPartner = () => {
    const id = getUrlParameter("id");
    this.setState({ isPartnerLoading: true }, () => {
      apiClient.get(`${endpoints().partnerAPI}?id=${id}`).then(response => {
        const currentPartner = response.data;
        this.setState({
          isPartnerLoading: false,
          currentPartner: currentPartner
            ? currentPartner.id
            : this.state.currentPartner
        });
      });
    });
  };

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save MDF Request data form
    this.createMdfRequest(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    const title = values.title;

    if (!title) {
      success = false;
    }

    return success;
  }

  setDate = (date, stateProperty) => {
    this.setState({ [stateProperty]: date });
  };

  // To Array
  _toArray(values) {
    const { eventDate, partnerIds, partnerStatus, currentPartner } = this.state;
    values.title = toString(values.title).trim();
    values.activityType = toString(values.activityType);
    values.reason = toString(values.reason);
    values.expectedResult = toString(values.expectedResult);
    values.requestAmount = toString(values.requestAmount);
    values.requestorFirstName = toString(values.requestorFirstName);

    values.requestorLastName = toString(values.requestorLastName);
    values.requestorContactTitle = toString(values.requestorContactTitle);
    values.requestorContactEmail = toString(values.requestorContactEmail);
    values.requestorContactPhone = values.requestorContactPhone
      ? removeMaskedPhoneNumber(values.requestorContactPhone)
      : "";
    values.notes = toString(values.notes);
    values.eventDate = eventDate ? moment(eventDate).format("YYYY-MM-DD") : "";
    values.partnerIds = currentPartner ? currentPartner : partnerIds;
    values.partnerStatus = partnerStatus ? partnerStatus : "";

    return values;
  }

  createMdfRequest = async data => {
    this.setState({ mdfSubmit: true });
    try {
      const response = await apiClient.post(
        `${endpoints().mdfRequestAPI}`,
        data
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
        this.setState({ mdfSubmit: false });
        this.props.history.push("/mdf-requests");
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
        this.setState({ mdfSubmit: false });
      }
    }
  };

  // Account details api call by id
  _getMdfDetails = () => {
    this.setState({ loading: true }, () => {
      apiClientGet(
        `${endpoints().mdfRequestAPI}/${this.props.match.params.id}`,
        (errMessage, response) => {
          if (errMessage) {
            this.setState({ isInvalidRequest: true });
          } else {
            const mdfDetails = response && response.data;
            this.setState({
              mdfDetails,
              eventDate: mdfDetails.eventDate
                ? new Date(mdfDetails.eventDate)
                : "",
              loading: false
            });
          }
        }
      );
    });
  };

  // Set Deal Partners Ids
  setMdfPartners = (partnerIds, status) => {
    this.setState({ partnerIds: partnerIds, partnerStatus: status });
  };
  handleCancelButton = () => {
    window.location.href = "/mdf-requests";
  };

  render() {
    const initialValues = {
      title: "",
      activityType: "",
      reason: "",
      expectedResult: "",
      requestAmount: "",
      eventDate: "",
      requestorFirstName: "",
      requestorLastName: "",
      requestorContactTitle: "",
      requestorContactEmail: "",
      requestorContactPhone: "",
      notes: ""
    };

    const { eventDate, mdfSubmit, mdfDetails } = this.state;

    return (
      <>
        <React.Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={values => {
              this._submit(values);
            }}
          >
            <AdminActionNavigation>
              <div className="d-flex align-items-center mb-3">
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    this.props.history.push("/mdf-requests");
                  }}
                >
                  MDF
                </a>
                <span
                  className="text-dark d-inline-flex align-items-center"
                  style={{ width: "15px" }}
                >
                  <ChevronRight />
                </span>
                <span
                  onClick={e => e.preventDefault()}
                  className={[
                    "text-dark",
                    "font-weight-bold",
                    "d-inline-flex",
                    "align-items-center",
                    "project-name",
                    "w-320"
                  ].join(" ")}
                >
                  <span className="ellipsis">Submit a MDF Request</span>
                </span>
              </div>
              <CancelButton
                className="ml-auto mr-1"
                onClick={this.handleCancelButton}
              />
              <Button
                label="Submit"
                type="submit"
                className="btn btn-primary"
                disabled={mdfSubmit}
              />
            </AdminActionNavigation>

            <div className="px-3">
              <PageTitle label="Submit a MDF Request" />
            </div>

            <div className="card border-0">
              <div className="project-details-body">
                <div className="details-body-main">
                  <div className="card-body">
                    <MdfRequestFormFields
                      setDate={this.setDate.bind(this)}
                      eventDate={eventDate}
                    />
                  </div>
                </div>
                <SideBar
                  status={this.partnerStatus}
                  partnerIds={this.partnerIds}
                  setMdfPartners={this.setMdfPartners}
                  mdfstatus={mdfDetails.status}
                  mdfDetails={mdfDetails}
                  mdfRequestId={this.props.match.params.id}
                />
              </div>
            </div>
          </Form>
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(SubmitMdfRequest);
