import React from "react";

// Assets
import {
  DashboardDealRegisteredIcon,
  DashboardDealPendingIcon,
  DashboardCommissionEarnedIcon
} from "../../assets/img/icons";
// Components
import StatisticsCountCard from "../base/StatisticsCountCard";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
import { GetCurrencySymbol } from "../../lib/CurrencySymbol";

const ReferralStatCard = props => {
  const {
    noOfReferralCount,
    averageReferralSize,
    totalValue,
    id,
    enableReferralSizeAndValue
  } = props;

  return (
    <div className="report-card-statistics row justify-content-start">
      <div className="col-4">
        {/* Referrals Count */}
        <StatisticsCountCard
          id={id}
          icon={<DashboardCommissionEarnedIcon />}
          count={noOfReferralCount}
          label={`Number of ${Terminology.get(SystemName.REFERRALS)}`}
          redirectUrl=""
        />
      </div>

      {enableReferralSizeAndValue == "true" && (
        <>
          <div className="col-4">
            {/* Average Referral Size */}
            <StatisticsCountCard
              id={id}
              icon={<DashboardDealPendingIcon />}
              count={averageReferralSize}
              prefix={GetCurrencySymbol()}
              label={`Average ${Terminology.get(SystemName.REFERRAL)} Size`}
              redirectUrl=""
            />
          </div>
          <div className="col-4">
            {/* Total Value */}
            <StatisticsCountCard
              id={id}
              icon={<DashboardCommissionEarnedIcon />}
              count={totalValue}
              prefix={GetCurrencySymbol()}
              label="Total Value"
              redirectUrl=""
            />
          </div>
        </>
      )}
    </div>
  );
};
export default ReferralStatCard;
