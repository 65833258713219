import React, { useState } from "react";
import { Input, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DatePicker from "react-date-picker";
import moment from "moment";

// Components
import UserDropdown from "../../../components/UserDropdown";
import CancelButton from "../../../../components/base/CancelButton";
import { getCookie } from "../../../../lib/helper";

const EditTaskModal = ({
  modal,
  setModal,
  id,
  name,
  index,
  created_at,
  assignee,
  task_users,
  currentUserId,
  updateAssignee,
  updateTaskName,
  updateTaskDate,
  updateProjectTasks,
  setCurrentTaskPartnerId,
  setAssignTaskPartnerId
}) => {
  const selectedDate = created_at
    ? moment(moment(created_at).format(), moment.defaultFormat).toDate()
    : new Date();
  const [dueDate, setDueDate] = useState(selectedDate);
  const [newAssignee, setNewAssignee] = useState({});
  const [input, setInput] = useState(name);
  const prevName = name;
  const prevAssignee = assignee;

  const handleInputChange = e => {
    const { value } = e.target;
    setInput(value);
  };

  const handleTaskAssignee = (taskIndex = 0, updatedField = {}) => {
    setNewAssignee(updatedField);
  };

  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={setModal}>
        <h4 className={["font-weight-bold"].join(" ")}>
          Edit Task / Deliverable
        </h4>
      </ModalHeader>
      <ModalBody className={["mb-4"].join(" ")}>
        <div className="form-wrapper">
          <div className="field-wrapper">
            <div className="field-wrapper">
              <label htmlFor={`task-name-${index}`}>
                Deliverable / Task Name
              </label>
              <Input
                id={`task-name-${index}`}
                name={`task-name-${index}`}
                label="Deliverable / Task Name"
                placeholder="Add Task Name"
                onChange={handleInputChange}
                value={input}
                required
                style={{
                  background: "#F3F3F4",
                  border: "none",
                  borderRadius: "5px",
                  fontSize: "14px",
                  height: "40px"
                }}
              />
            </div>
          </div>
          <div className="grouped-fields">
            <div className="field-wrapper">
              <label>Assigned to</label>
              <UserDropdown
                id={assignee.id}
                currentUserId={currentUserId || getCookie("userId")}
                taskIndex={index}
                assignee={
                  Object.keys(newAssignee).length > 0 ? newAssignee : assignee
                }
                userlist={task_users}
                updateAssignee={updateAssignee}
                setNewAssignee={setNewAssignee}
              />
            </div>
            <div className="field-wrapper">
              <label>Due Date</label>
              <div className="datepicker-wrapper">
                <DatePicker
                  name={`datepicker-task-${index}`}
                  className="datepicker"
                  dayPlaceholder="MM/DD/YYYY"
                  onChange={date => setDueDate(date)}
                  minDate={new Date()}
                  value={dueDate}
                  required
                  clearIcon={null}
                />
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={["justify-content-center"].join(" ")}>
        <div className="btn-wrapper">
          <CancelButton
            onClick={() => {
              setModal(prevState => !prevState);
              setInput(prevName);
              setNewAssignee(prevAssignee);
              updateAssignee(index, newAssignee);
            }}
          />
          <button
            className="btn btn-primary"
            onClick={() => {
              setCurrentTaskPartnerId(
                newAssignee.partnerId
                  ? newAssignee.partnerId
                  : assignee.partnerId || assignee.partner_id
              );
              setAssignTaskPartnerId(
                newAssignee.id ? newAssignee.id : assignee.id
              );
              updateTaskName(index, input);
              updateTaskDate(index, input, dueDate);
              updateProjectTasks(id, input, dueDate, newAssignee || assignee);
              setModal(prevState => !prevState);
            }}
          >
            Update
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditTaskModal;
