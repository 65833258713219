import React from "react";
import { Badge } from "reactstrap";

// Assets
import { EditIconAlt, TrashIcon } from "../../../../../assets/img/icons";
import { Link } from "react-router-dom";
import {
  PARTNER_PROFILE_SUB_TAB,
  TAB_PARTNER
} from "../../../../../setting/Constants";

const Tags = props => {
  let { task, openEditModal, openDeleteModal } = props;
  let { id, name, category, isDefault } = task;
  const editLink = `/admin-settings/${TAB_PARTNER}/${PARTNER_PROFILE_SUB_TAB}/${name}/${id}`;

  return (
    <div className=" task pr-4 pt-2 pb-1" style={{ minHeight: "70px" }}>
      <div className="task-left">
        <p className="pr-3 pt-3" style={{ cursor: "move", width: "55px" }}>
          <i className="fa fa-arrows"></i>
        </p>
        {/*Show Checkbox Based On Status*/}
        <div className="name-comments mr-2 ">
          {/*Show Tags Name*/}
          <Link to={editLink} className="text-link text-underline">
            {name}
          </Link>
          <span className="text-decoration-none text-secondary">
            {isDefault ? "(Default)" : ""}
          </span>
          {category === "System" && (
            <Badge color="secondary" pill>
              Default
            </Badge>
          )}
        </div>
      </div>

      <div className="task-right">
        {/*Show Task Actions For All Tasks Based on the Condition*/}
        <div className="task-actions">
          <>
            <span
              className={"mt-1 d-flex justify-content-between"}
              style={{
                visibility: category === "System" ? "hidden" : "show"
              }}
            >
              <button
                type="button"
                className={["edit-btn", "btn-link", "btn"].join(" ")}
                onClick={() => {
                  openEditModal(true);
                }}
              >
                <EditIconAlt />
              </button>
            </span>
            <button
              type="button"
              className={["delete-btn", "btn-link", "btn"].join(" ")}
              onClick={() => {
                openDeleteModal(true);
              }}
            >
              <TrashIcon />
            </button>
          </>
        </div>
      </div>
    </div>
  );
};

export default Tags;
