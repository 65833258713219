import React from "react";
import { isExpert, isPartner, isCustomer } from "../lib/helper";
import { ProjectsIcon } from "../assets/img/icons";
import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import ProjectsList from "../views/projects/inner-components/ProjectsList";
import { PROJECT_STATUS_ACTIVE } from "../projectStatus/Constants";
import {
  PROJECT_USER_STATUS_NO_RESPONSE,
  PROJECT_USER_STATUS_ACCEPTED
} from "../project/Constants";
import { Modal, ModalBody, ModalFooter, ModalHeader, Button } from "reactstrap";
import { BlueMessageIcon } from "../assets/img/icons";
import Form from "../components/base/Form";
import TextArea from "./base/TextArea";
import toast from "../components/base/Toast";
import CancelButton from "../components/base/CancelButton";
import { isBadRequest } from "../common/http";
import { connect } from "react-redux";

class ProjectList extends React.Component {
  state = {
    projectDetails: [],
    messageAccountManagerModal: false,
    accountManagerMessage: ""
  };

  // Get active project list
  getProjectList = status => {
    this.setState({ isLoading: true }, () => {
      const projectUserStatus =
        isExpert(this.props.roleId) || isPartner(this.props.roleId)
          ? this.props.projectUserStatuses
            ? `&projectUserStatus=${this.props.projectUserStatuses}`
            : `&projectUserStatus=${PROJECT_USER_STATUS_NO_RESPONSE}, ${PROJECT_USER_STATUS_ACCEPTED}`
          : "";
      apiClient.get(`${endpoints().projectAPI}`).then(response => {
        const data = response.data;
        this.setState({
          isLoading: false,
          projectDetails: !data ? [] : data,
          totalCount: data.totalCount
        });
      });
    });
  };

  toggleMessageAccountManagerModal = () => {
    this.setState({
      messageAccountManagerModal: !this.state.messageAccountManagerModal
    });
  };
  onChangeMessage = e => {
    this.setState({ accountManagerMessage: e.target.value });
  };
  sendMessage = () => {
    apiClient
      .post(`${endpoints().dashboardAPI}/contact/support`, {
        message: this.state.accountManagerMessage
      })
      .then(response => {
        this.toggleMessageAccountManagerModal();
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            toast.error(errorMessage);
          }
        }
      });
  };

  componentDidMount() {
    const projectStatus = PROJECT_STATUS_ACTIVE;

    this.getProjectList(projectStatus);
  }

  render() {
    const { projectDetails, isLoading } = this.state;

    if (isLoading) {
      return "";
    }

    return (
      ((isPartner(this.props.roleId) &&
        this.props.isCampaign &&
        projectDetails.data &&
        projectDetails.data.length) ||
        !isPartner(this.props.roleId)) && (
        <div className={`${!this.props.enable ? "disabled" : ""}`}>
          <h5 className={["mt-4", "mb-2", "font-weight-bold"].join(" ")}>
            {this.props.title}
          </h5>
          {projectDetails && projectDetails.totalCount > 0 && !isLoading ? (
            <>
              {/* Active project list */}
              <ProjectsList
                data={projectDetails}
                history={this.props.history}
                projectDetailsUrlBasePath={
                  this.props.projectDetailsUrlBasePath || ""
                }
              />
            </>
          ) : (
            <>
              <div
                className="card d-flex align-items-center justify-content-center flex-column mb-5"
                style={{ minHeight: "350px" }}
              >
                <div className="active-projects-icon mb-4">
                  <ProjectsIcon />
                </div>
                <span className="h6 d-block font-weight-bold text-inline-grayed">
                  As soon as an active project is in process, you'll see it
                  here!
                </span>
                <span className="h6 d-block">
                  <i className="fas fa-info-circle text-link mr-2"></i>
                  <span className="font-weight-bold">Need Help?</span>{" "}
                  <a
                    href=""
                    className="text-link text-underline"
                    onClick={e => {
                      e.preventDefault();
                      this.toggleMessageAccountManagerModal();
                    }}
                  >
                    Contact Torchlite Account Manager
                  </a>
                </span>
              </div>
            </>
          )}
          <MessageAccountManagerModal
            isOpen={this.state.messageAccountManagerModal}
            toggle={this.toggleMessageAccountManagerModal}
            onChangeMessage={this.onChangeMessage}
            sendMessage={this.sendMessage}
          />
        </div>
      )
    );
  }
}
const MessageAccountManagerModal = ({
  isOpen,
  toggle,
  onChangeMessage,
  sendMessage,
  id
}) => {
  return (
    <Modal
      id={id}
      isOpen={isOpen}
      toggle={toggle}
      className={["edit-task-modal"].join(" ")}
      backdrop="static"
    >
      <Form
        id={id}
        initialValues={{ message: "" }}
        onSubmit={values => {
          sendMessage(values);
        }}
      >
        <ModalHeader toggle={toggle}>
          <div
            className={["d-flex", "flex-column", "align-items-center"].join(
              " "
            )}
          >
            <BlueMessageIcon />
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
              Message Account Manager
            </h4>
          </div>
        </ModalHeader>
        <ModalBody className={["mb-4", "px-0"].join(" ")}>
          <div className={["text-center", "modal-body-subtitle"].join(" ")}>
            <p>
              Send a message to the account manager by using the text area
              below:
            </p>
          </div>
          <div className="form-wrapper justify-content-center d-flex">
            <div className="field-wrapper w-100">
              <TextArea
                id={id}
                name="message"
                label="Your message"
                placeholder="Your message..."
                rows="5"
                onChange={onChangeMessage}
                error="Your message is required"
                required
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton id={id} onClick={toggle} />
            <Button id={id} type="submit" label="">
              Send Message
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(ProjectList);
