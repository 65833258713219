// MDF Status
export const MDF_STATUS_APPROVED = "Approved";
export const MDF_STATUS_DECLINED = "Declined";
export const MDF_STATUS_REQUESTED = "Requested";
export const MDF_STATUS_DRAFT = "Draft";
export const MDF_STATUS_NOT_INTERESTED = "Not Interested";

//  Sub Tabs
export const TAB_NEW = "New";
export const TAB_APPROVED = "Approved";
export const TAB_ALL = "All";

// Partner Status
export const MDF_PARTNER_STATUS_PENDING = "Pending";
export const MDF_PARTNER_STATUS_ACCEPTED = "Accepted";
export const MDF_PARTNER_STATUS_NOT_INTERESTED = "Not Interested";
