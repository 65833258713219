import React from "react";

const DepositSummary = props => {
  const { classnames, playbook, cartOption } = props;
  return (
    <div className={`sidebar-content-box ${classnames}`}>
      <div className="sidebar-content">
        <div className="cart-item">
          <span className="title h4 d-block">Deposit Summary</span>
        </div>
        <div className="cart-item">
          <span className="title h5 d-block mb-3">
            <b>{playbook.name}</b>
          </span>
          <ul className="list-unstyled h-6-5">
            <li className="d-flex h6-5 mb-2 justify-content-between align-items-end">
              <span>{cartOption.package_type} Package Refundable Deposit</span>{" "}
              <span>$100.00</span>
            </li>
          </ul>
        </div>
        <div className="cart-item">
          <span className="d-flex align-items-baseline justify-content-between">
            <span className="text-black">
              <b>Today’s Total</b>
            </span>
            <span className="text-orange">
              <b>$100.00</b>
            </span>
          </span>
        </div>
      </div>
    </div>
  );
};

export default DepositSummary;
