import moment from "moment";
class DateTime {
  static GetNumberOfDays(destinationDate) {
    let startDate = new Date();

    let endDate = new Date(destinationDate);

    const timeDifference = startDate.getTime() - endDate.getTime();

    const daysDifference = Math.floor(timeDifference / (1000 * 60 * 60 * 24));

    return daysDifference;
  }
  static getStartOfMonth() {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();

    const firstDayOfMonth = new Date(
      currentDate.getFullYear(),
      currentMonth,
      1
    );

    return firstDayOfMonth;
  }
  static getToday() {
    const currentDate = new Date();
    currentDate.setHours(0, 0, 0, 0);
    return currentDate;
  }

  static getDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }
  static toISOStringDate(date) {
    if (!date) {
      return null;
    }
    let gmtDateTime = moment.utc(date);

    return gmtDateTime.local().format("DD-MMM-YYYY");
  }
  static formatDate(date) {
    return moment(date).format("YYYY-MM-DD");
  }

  static getTimeZones() {
    try {
      let timeZones = Intl.supportedValuesOf("timeZone");

      return timeZones;
    } catch (err) {
      console.log(err);
    }
  }
}

const dateTime = new DateTime();
// dateTime.formats.formattedDateFormat
export default DateTime;
