import React from "react";
import { Elements } from "react-stripe-elements";

// Page Components
import InjectedCheckoutForm from "./CheckoutForm";

class MyStoreCheckout extends React.Component {
  render() {
    const {
      playbook,
      cartOption,
      totalAmount,
      requestedStartDate,
      customerDetails,
      playbookExtrasIds,
      setNewPaymentOptionModal,
      actualAmount,
      projectNotes,
      settings
    } = this.props;
    return (
      <Elements>
        <InjectedCheckoutForm
          playbook={playbook}
          cartOption={cartOption}
          estimatedBudget={totalAmount}
          totalAmount={totalAmount}
          actualAmount={actualAmount}
          customerDetails={customerDetails}
          requestedStartDate={requestedStartDate}
          playbookExtrasIds={playbookExtrasIds}
          setNewPaymentOptionModal={setNewPaymentOptionModal}
          projectNotes={projectNotes}
          settings={settings}
        />
      </Elements>
    );
  }
}

export default MyStoreCheckout;
