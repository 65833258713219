import React, { useState, useEffect } from "react";

const ClutchReview = props => {
  const { domainName } = props;

  useEffect(() => {
    InjectScript();
  }, []);

  const InjectScript = () => {
    let iframe = document.getElementById("clutchReviewiframe");
    if (iframe != null) {
      iframe.srcdoc = `<html lang=en>
      <head>
        <meta charset=UTF-8>
          <script type=text/javascript src=https://widget.clutch.co/static/js/widget.js></script>
          <title>Clutch Widget</title>
        </head>
        <body>
        <div
          class=clutch-widget
          data-url=https://widget.clutch.co
          data-widget-type=4
          data-expandifr=true
          data-max-witdth=100%
          data-height=auto
          data-clutchcompany-domain=${domainName}
          data-snippets=true
          data-header-color=#105E81
          data-header-text-color=#FFFFFF
          data-footer-color=##FFFFFF
          data-footer-text-color=#000000
          data-primary-color=#808000
          data-secondary-color=#bfbf8d
          data-background-color=#FFFFFF
          data-review-card-color=#FFFFFF
         ></div>
      </body>
      </html>`;
    }
  };

  return (
    <>
      <div>
        <iframe
          id="clutchReviewiframe"
          height="800px"
          width="1000"
          frameBorder="0"
        ></iframe>
      </div>
    </>
  );
};
export default ClutchReview;
