import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Components
import AdminActionNavigation from "../../components/base/AdminActionNavigation";
import Spinner from "../../components/base/Spinner";
import Form from "../../components/base/Form";
import Text from "../../components/base/Text";
import TextArea from "../../components/base/TextArea";

// Assets
import { ChevronRight } from "../../assets/img/icons";
import { isBadRequest } from "../../common/http";

class DealDetails extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dealDetails: {},
      isLoading: true
    };
  }

  componentDidMount() {
    this._getDealDetails(this.props.match.params.id);
  }

  // Get Partner Request Details
  _getDealDetails(id) {
    this.setState({ isLoading: true });
    return apiClient
      .get(`${endpoints().contactFormSubmission}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({
          dealDetails: response.data.data,
          isLoading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isLoading: false });
        }
      });
  }
  render() {
    const { dealDetails, isLoading } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    let initialValues = {};
    dealDetails &&
      dealDetails.map(data => {
        const { fieldName, value } = data;
        initialValues[fieldName] = value;
      });
    return (
      <>
        <AdminActionNavigation>
          <div className="d-flex w-100 align-items-center">
            <a
              className="cursor-pointer"
              onClick={() => this.props.history.goBack()}
            >
              Partner Request
            </a>
            <span
              className="text-dark d-flex align-items-cente"
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <span
              onClick={e => e.preventDefault()}
              className={[
                "text-dark",
                "font-weight-bold",
                "d-flex",
                "align-items-center",
                "project-name",
                "w-320"
              ].join(" ")}
            >
              <span className="ellipsis">
                Request #{this.props.match.params.id}
              </span>
            </span>
          </div>
        </AdminActionNavigation>
        <div className="card bg-white mb-3">
          <div className="card-body">
            <div className="row mt-4 mb-4">
              <div className="col-lg-6 col-sm-12">
                <Form initialValues={initialValues}>
                  <div className="form-wrapper justify-content-center d-flex flex-column">
                    {dealDetails &&
                      dealDetails.map(data => (
                        <div className="field-wrapper">
                          <Text
                            name={data.fieldName}
                            label={data.fieldName}
                            disabled={true}
                          />
                        </div>
                      ))}
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default DealDetails;
