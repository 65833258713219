import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Constant
import {
  LANDING_PAGE_BLOCK_TYPE_BANNER,
  LANDING_PAGE_BLOCK_TYPE_EXPERT,
  LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT,
  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK,
  LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT,
  LANDING_PAGE_BLOCK_TYPE_SIGN_UP,
  LANDING_PAGE_BLOCK_TYPE_CONTENT,
  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID,
  LANDING_PAGE_BLOCK_TYPE_PARTNER,
  LANDING_PAGE_BLOCK_TYPE_APPS,
  LANDING_PAGE_FEATURED_COLLECTION
} from "../../landingPage/Constants";
import {
  SETTINGS_ENABLE_PARTNERS,
  SETTINGS_ENABLE_PLAYBOOK,
  SETTINGS_RAMBLE_CHAT_BOT_SCRIPT,
  SETTINGS_RAMBLE_CHAT_ENABLE_ADMIN,
  SETTINGS_RAMBLE_CHAT_ENABLE_CUSTOMER,
  SETTINGS_RAMBLE_CHAT_ENABLE_EXPERT,
  SETTINGS_RAMBLE_CHAT_ENABLE_PARTNER,
  SETTINGS_RAMBLE_CHAT_ENABLE_GUEST
} from "../../setting/Constants";

// Configs
import * as landingPageActionCreators from "../../actions/landingPage";
import {
  endpoints,
  DEFAULT_API_KEY,
  TORCHLITE_WEBSITE_URL
} from "../../configs";

//styles
import "./styles.scss";

// 404 page
import Page404 from "../Page404";

// Components
import Banner from "../../components/base/Banner";
import MarketplaceSection from "../../components/MarketplaceSection";
import MarketplaceAdvisor from "../../components/MarketplaceAdvisor";
import PlaybookCard from "../../components/PlaybookCard";
import ExpertCard from "../../components/base/ExpertCard";
import Spinner from "../../components/base/Spinner";
import PageTitle from "../../components/base/PageTitle";
import Otherpartners from "./inner-components/Otherpartners";
import PartnerFilters from "./inner-components/PartnerFilter";
// Assets
import fallbackBackground from "../../assets/header-triangles.svg";
import partnerSearchIcon from "../../assets/img/icons/icon-partner-search.svg";
import { CrossIcon } from "../../assets/img/icons";

import BannerHeader from "./BannerHeader";

import MarketplaceAppCard from "../../components/MarketplaceAppCard";

// API call
import { apiClient } from "../../apiClient";

//styles
import "./styles.scss";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  sortNameByOrder,
  getKeyValueByObject,
  isCompanyManager,
  isCustomer,
  isPartner,
  isExpert,
  getParamsByName
} from "../../lib/helper";
import detectBrowser from "../../components/helpers/detectBrowser";

// Icons
import {
  InlineInfoIcon,
  MessageCircleIcon,
  UserPlusIcon
} from "../../assets/img/icons";

// MetaCX
import { metaCXTrack } from "../../lib/metaCX";
import {
  METACX_EVENT_MARKETPLACE_LANDING_PAGE_LEARN_MORE_BUTTON_CLICK,
  METACX_EVENT_MARKETPLACE_LANDING_PAGE_VISIT,
  METACX_EVENT_MARKETPLACE_LANDING_PAGE_GET_SOCIAL_BUTTON_CLICK,
  METACX_EVENT_MARKETPLACE_LANDING_PAGE_CONTACT_US_BUTTON_CLICK
} from "../../metaCXEvents/Constants";

import PartnerCard from "../../components/PartnerCard";
import PartnerSidebarFilter from "../../components/PartnerSidebarFilter";
import {
  TAG_TYPE_APP_CATEGORY,
  TAG_TYPE_PRODUCTS,
  TAG_TYPE_SERVICES
} from "../../tagType/Constants";

// Components
import NoRecordsFound from "../../components/base/NoRecordsFound";
import { isBadRequest } from "../../common/http";
import AppSideBarFilter from "../../components/AppSidebarFilter";
import { getTagTypeByCategory } from "../../tag/Service";
import FeaturedCollectionSection from "./FeaturedCollectionSection";

// Condition Wrap
const ConditionalWrap = ({ condition, wrap, children }) =>
  condition ? wrap(children) : children;

class Marketplace extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      landingPageName: "",
      landingPageId: "",
      landingPageBlocks: [],
      status: "",
      isInvalidRequest: false,
      salesforceLanding: true,
      services: [],
      industries: [],
      partnerList: [],
      landingBlockId: "",
      appList: [],
      typeIds: [],
      partnerSelectedFilters: {},
      partnerSelectedFilterValues: {},
      appSelectedFilters: "",
      appSelectedFiltersValues: {},
      categorySearch: {},
      partnerSearch: "",
      showAppsInSingleLine: false,
      showAppSearch: false,
      tagTypeList: [],
      landingPageBlockId: "",
      appLoading: false,
      fetchResult: true,
      blockFilters: {}
    };
  }

  async componentDidMount() {
    this._getLandingPageDetails();
    const isMobile = detectBrowser();
    this.getTagTypes();
    this.setState({
      isMobile
    });

    const settings = this.props.settings;

    if (settings) {
      const rambleChatScript =
        settings &&
        (await getKeyValueByObject(settings, SETTINGS_RAMBLE_CHAT_BOT_SCRIPT));
      const enableAdminRambleChat =
        settings &&
        (await getKeyValueByObject(
          settings,
          SETTINGS_RAMBLE_CHAT_ENABLE_ADMIN
        ));
      const enableCustomerRambleChat =
        settings &&
        (await getKeyValueByObject(
          settings,
          SETTINGS_RAMBLE_CHAT_ENABLE_CUSTOMER
        ));
      const enablePartnerRambleChat =
        settings &&
        (await getKeyValueByObject(
          settings,
          SETTINGS_RAMBLE_CHAT_ENABLE_PARTNER
        ));
      const enableExpertRambleChat =
        settings &&
        (await getKeyValueByObject(
          settings,
          SETTINGS_RAMBLE_CHAT_ENABLE_EXPERT
        ));
      const enableGuestRambleChat =
        settings &&
        (await getKeyValueByObject(
          settings,
          SETTINGS_RAMBLE_CHAT_ENABLE_GUEST
        ));

      // Check Enable Chat Bot
      const enableRambleChatBot =
        ((isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) &&
          enableAdminRambleChat &&
          enableAdminRambleChat === "true") ||
        (isCustomer(this.props.roleId) &&
          enableCustomerRambleChat &&
          enableCustomerRambleChat === "true") ||
        (isPartner(this.props.roleId) &&
          enablePartnerRambleChat &&
          enablePartnerRambleChat === "true") ||
        (isExpert(this.props.roleId) &&
          enableGuestRambleChat &&
          enableExpertRambleChat === "true") ||
        (!isSuperAdmin(this.props.roleId) &&
          !isCompanyAdmin(this.props.roleId) &&
          !isCompanyManager(this.props.roleId) &&
          !isCustomer(this.props.roleId) &&
          !isPartner(this.props.roleId) &&
          !isExpert(this.props.roleId) &&
          enableGuestRambleChat &&
          enableGuestRambleChat === "true");

      if (
        enableRambleChatBot &&
        rambleChatScript &&
        rambleChatScript.length > 0
      ) {
        // Create Footer Script Element
        const script = document.createElement("script");
        script.setAttribute("type", "text/javascript");
        const scriptElementCode = document.createTextNode(rambleChatScript);
        script.appendChild(scriptElementCode);
        document.body.appendChild(script);
      }
    }
  }

  getTagTypes = async () => {
    const tagValue = await getTagTypeByCategory(TAG_TYPE_APP_CATEGORY);
    this.setState({ tagTypeList: tagValue });
  };
  toggle() {
    this.setState({
      popoverOpen: !this.state.popoverOpen
    });
  }

  updateBlockSearchTerm = (term, blockId) => {
    try {
      this.setState(prevState => ({
        categorySearch: {
          ...prevState.categorySearch,
          [blockId]: term
        }
      }));
    } catch (err) {
      console.log(err);
    }
  };

  extractCategoryIds(log) {
    const categoryIdsObject = {};
    log.forEach(obj => {
      Object.keys(obj).forEach(key => {
        const jsonStr = obj[key];
        if (jsonStr) {
          const jsonObj = JSON.parse(jsonStr);
          const filters = jsonObj.appSelectedFilters;
          for (const filterKey in filters) {
            if (filterKey.startsWith("category-")) {
              const categoryId = filterKey.split("-")[1];
              categoryIdsObject[key] = categoryIdsObject[key] || [];
              if (!categoryIdsObject[key].includes(categoryId)) {
                categoryIdsObject[key].push(categoryId);
              }
            }
          }
        }
      });
    });
    return categoryIdsObject;
  }

  // Landing Page Detail api call by id
  _getLandingPageDetails = data => {
    const formData = new FormData();

    let values = [];
    if (data && data.categoryId) {
      Object.entries(data && data.categoryId).forEach(([key, value]) => {
        values.push({ [key]: JSON.stringify(value) });
      });
    }

    const categoryIds = this.extractCategoryIds(values);

    let eid = getParamsByName("eid");
    this.setState({ loading: true }, () => {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

      let apiUrl = "";
      if (data) {
        apiUrl = `${endpoints().publicAPI}/landingPage/${
          this.props.match.params.id
        }?eid=${eid}&landingPageBlockId=${data && data.landingPageBlockId}`;
      } else {
        apiUrl = `${endpoints().publicAPI}/landingPage/${
          this.props.match.params.id
        }?eid=${eid}`;
      }

      return apiClient
        .post(apiUrl, {
          categoryId: categoryIds,
          search: data && data.searchTerm
        })
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);
          this.setState({
            landingPageId: response.data.id,
            landingPageName: response.data.name,
            landingPageBlocks: response.data.content,
            typeIds: response.data.typeIds && response.data.typeIds,
            status: response.data.status,
            loading: false,
            partnerLoading: false,
            appLoading: false
          });

          let services = [],
            industries = [];
          if (
            response &&
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            response.data.content.forEach(block => {
              if (
                block.type === LANDING_PAGE_BLOCK_TYPE_PARTNER &&
                block.partners &&
                block.partners.length > 0
              ) {
                this.setState({ partnerList: block.partners });
                block.partners.forEach(partner => {
                  if (
                    partner.partnerProfileTag &&
                    partner.partnerProfileTag.length > 0
                  ) {
                    partner.partnerProfileTag.forEach(partnerTag => {
                      if (
                        partnerTag.tag &&
                        partnerTag.tag.tagType.name === TAG_TYPE_SERVICES
                      ) {
                        if (
                          !services.find(
                            item => item.value === partnerTag.tag.id
                          )
                        ) {
                          services.push({
                            value: partnerTag.tag.id,
                            label: partnerTag.tag.name
                          });
                        }
                      }

                      if (
                        partnerTag.tag &&
                        partnerTag.tag.tagType.name === TAG_TYPE_PRODUCTS
                      ) {
                        if (
                          !industries.find(
                            item => item.value === partnerTag.tag.id
                          )
                        ) {
                          industries.push({
                            value: partnerTag.tag.id,
                            label: partnerTag.tag.name
                          });
                        }
                      }
                    });
                  }
                });
              }
            });
          }

          this.setState({
            services: sortNameByOrder(services),
            industries: sortNameByOrder(industries)
          });

          const { settings } = this.props;

          // Track The Landing Page View
          metaCXTrack(
            METACX_EVENT_MARKETPLACE_LANDING_PAGE_VISIT,
            {
              landingPageId: response.data.id,
              landingPageName: response.data.name
            },
            settings,
            () => {}
          );
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
            this.setState({ isInvalidRequest: true });
          }
        });
    });
  };

  // Filter Partners Collection
  filterPartnerList = (values, search, selectName, selectValue) => {
    if (values) {
      this.setState({
        partnerSelectedFilterValues: values
      });
    }

    if (selectName && selectValue) {
      this.setState(prevState => ({
        partnerSelectedFilters: {
          ...prevState.partnerSelectedFilters,
          [selectName]: selectValue
        }
      }));
    }
    if (selectName && selectValue && selectValue.value === false) {
      const currentSelectedFilters = this.state.partnerSelectedFilters;
      delete currentSelectedFilters[selectName];
      this.setState({
        partnerSelectedFilters: currentSelectedFilters
      });
    }
    let partnerTagIds = [],
      partnerTypeIds = [],
      partnerTierIds = [];
    values &&
      Object.keys(values).forEach(value => {
        let fieldKey = `${value}`.split("-");
        if (fieldKey[0] === "partnerType" && values[value] === true) {
          partnerTypeIds.push(fieldKey[1]);
        } else if (fieldKey[0] === "partnerTier" && values[value] === true) {
          partnerTierIds.push(fieldKey[1]);
        } else if (fieldKey[0] === "checkbox" && values[value] === true) {
          partnerTagIds.push(fieldKey[1]);
        } else if (fieldKey[0] === "Partner Type") {
          partnerTypeIds.push(values[value].value);
        } else if (fieldKey[0] === "Partner Tier") {
          partnerTierIds.push(values[value].value);
        } else {
          partnerTagIds.push(values[value].value);
        }
      });

    const searchTerm = search || "";
    let eid = getParamsByName("eid");

    this.setState({ partnerLoading: true }, () => {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

      return apiClient
        .post(
          `${endpoints().publicAPI}/landingPage/${
            this.props.match.params.id
          }?eid=${eid}&searchTerm=${searchTerm}&partnerTags=${partnerTagIds}&partnerType=${partnerTypeIds}&partnerTier=${partnerTierIds}`
        )
        .then(response => {
          if (
            response &&
            response.data &&
            response.data.content &&
            response.data.content.length > 0
          ) {
            response.data.content.forEach(block => {
              if (
                block.type === LANDING_PAGE_BLOCK_TYPE_PARTNER &&
                block.partners
              ) {
                this.setState({
                  partnerList: block.partners,
                  partnerLoading: false
                });
              }
            });
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
            this.setState({ isInvalidRequest: true });
          }
        });
    });
  };

  // Get Filter Keys
  getAppFilterKeys = () => Object.keys(this.state.appSelectedFilters);

  // Get Filter Keys
  filterAppList = async (values, search, selectName, selectValue, blockId) => {
    const fetchResult =
      values && !Object.values(values).some(value => value === true);
    if (typeof search !== "object") {
      this.setState(prevState => ({
        categorySearch: {
          ...prevState.categorySearch,
          [blockId]: search
        }
      }));
    }
    this.setState(
      prevState => {
        const blockFilters = {
          ...prevState.blockFilters,
          [blockId]: {
            appSelectedFilters:
              prevState.blockFilters[blockId] &&
              prevState.blockFilters[blockId].appSelectedFilters
                ? { ...prevState.blockFilters[blockId].appSelectedFilters }
                : {},
            appSelectedFiltersValues:
              prevState.blockFilters[blockId] &&
              prevState.blockFilters[blockId].appSelectedFiltersValues
                ? {
                    ...prevState.blockFilters[blockId].appSelectedFiltersValues
                  }
                : {},
            category:
              prevState.blockFilters[blockId] &&
              prevState.blockFilters[blockId].category
                ? { ...prevState.blockFilters[blockId].category }
                : {}
          }
        };

        if (fetchResult) {
          blockFilters[blockId].landingPageBlockId = "";
        }

        if (values) {
          blockFilters[blockId].appSelectedFiltersValues = values;
        }

        if (selectName && selectValue) {
          blockFilters[blockId].appSelectedFilters = {
            ...(blockFilters[blockId].appSelectedFilters || {}),
            [selectName]: selectValue
          };
        }
        if (selectName && selectValue && selectValue.value === false) {
          const currentSelectedFilters = {
            ...(blockFilters[blockId].appSelectedFilters || {})
          };
          delete currentSelectedFilters[selectName];
          blockFilters[blockId].appSelectedFilters = currentSelectedFilters;
        }

        return {
          blockFilters: blockFilters,
          appLoading: true
        };
      },
      () => {
        const { blockFilters, categorySearch } = this.state;

        this.setState({ appLoading: true }, () => {
          apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

          let params = {
            searchTerm: categorySearch,
            categoryId: blockFilters,
            landingPageBlockId: blockId
          };

          this._getLandingPageDetails(params);
        });
      }
    );
  };

  // Get Filter Keys
  getPartnerFilterKeys = () => Object.keys(this.state.partnerSelectedFilters);

  // Delete Filter Keys
  deletePartnerFilters = e => {
    const { dataset } = e.target;
    this.setState(
      prevState => {
        const currentSelectedFilters = this.state.partnerSelectedFilters;
        delete currentSelectedFilters[dataset["parentobj"]];
        return {
          partnerSelectedFilters: currentSelectedFilters,
          partnerSelectedFilterValues: {
            ...prevState.partnerSelectedFilterValues,
            [dataset["parentobj"]]: ""
          }
        };
      },
      () => {
        this.filterPartnerList(
          this.state.partnerSelectedFilterValues,
          this.state.partnerSearch
        );
      }
    );
  };

  // Reset Filter Keys
  resetPartnerFilters = () => {
    this.setState(
      {
        partnerSelectedFilters: {},
        partnerSelectedFilterValues: {}
      },
      () => {
        this.filterPartnerList(
          this.state.partnerSelectedFilterValues,
          this.state.partnerSearch
        );
      }
    );
  };

  // Reset Filter Keys based on blockId
  resetAppFilters = blockId => {
    this.setState(
      prevState => {
        const updatedBlockFilters = { ...prevState.blockFilters };
        if (updatedBlockFilters[blockId]) {
          updatedBlockFilters[blockId].appSelectedFilters = {};
          updatedBlockFilters[blockId].appSelectedFiltersValues = {};
          updatedBlockFilters[blockId].landingPageBlockId = "";
        }
        return {
          blockFilters: updatedBlockFilters
        };
      },
      () => {
        this.filterAppList({}, this.state.categorySearch, null, null, blockId);
      }
    );
  };

  // Delete App Filter Keys based on blockId
  deleteAppFilters = (e, blockId) => {
    const { dataset } = e.target;
    const filterKey = dataset["parentobj"];

    this.setState(
      prevState => {
        const currentBlockFilters = { ...prevState.blockFilters };

        // Check if the blockId exists in blockFilters and appSelectedFilters
        if (
          currentBlockFilters[blockId] &&
          currentBlockFilters[blockId].appSelectedFilters
        ) {
          const currentSelectedFilters = {
            ...currentBlockFilters[blockId].appSelectedFilters
          };
          delete currentSelectedFilters[filterKey];
          const currentSelectedValues = {
            ...currentBlockFilters[blockId].appSelectedFiltersValues
          };
          delete currentSelectedValues[filterKey];
          currentBlockFilters[
            blockId
          ].appSelectedFilters = currentSelectedFilters;
          currentBlockFilters[
            blockId
          ].appSelectedFiltersValues = currentSelectedValues;
        }

        return {
          blockFilters: currentBlockFilters
        };
      },
      () => {
        this.filterAppList(
          this.state.blockFilters[blockId].appSelectedFiltersValues,
          this.state.categorySearch,
          null,
          null,
          blockId
        );
      }
    );
  };

  searchTerm = terms => {
    this.setState({
      partnerSearch: terms
    });
  };

  renderFilterSection(blockId) {
    const blockFilters =
      this.state && this.state.blockFilters && this.state.blockFilters[blockId];

    if (
      !blockFilters ||
      !blockFilters.appSelectedFilters ||
      Object.keys(blockFilters.appSelectedFilters).length === 0
    ) {
      return null;
    }
    const { appSelectedFilters } = blockFilters;

    return (
      <div className="col-12">
        {appSelectedFilters &&
        appSelectedFilters !== undefined &&
        Object.keys(appSelectedFilters).length > 0 ? (
          <div className="filters-wrapper">
            {Object.entries(appSelectedFilters).map(
              ([filterKey, filter]) =>
                filter.value && (
                  <button
                    key={filterKey}
                    className={`badge badge-primary ${filterKey}`}
                    data-parentobj={filterKey}
                    data-filter={filter.value}
                    onClick={e => this.deleteAppFilters(e, blockId)}
                  >
                    {filter.label} <CrossIcon />
                  </button>
                )
            )}
            <a
              href=""
              onClick={e => {
                e.preventDefault();
                this.resetAppFilters(blockId);
              }}
            >
              Clear Filters...
            </a>
          </div>
        ) : (
          ""
        )}
      </div>
    );
  }

  render() {
    const {
      landingPageId,
      landingPageName,
      landingPageBlocks,
      isInvalidRequest,
      salesforceLanding,
      loading,
      partnerLoading,
      appLoading,
      partnerList,
      appList,
      status,
      partnerSelectedFilterValues,
      partnerSelectedFilters,
      appSelectedFilters,
      appSelectedFiltersValues,
      bgColor,
      services,
      industries,
      typeIds,
      blockFilters
    } = this.state;

    const metaCXData = {
      landingPageId: `${landingPageId}`,
      landingPageName
    };

    const { settings } = this.props;

    const enablePartners = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS)
      : "";

    const enablePlaybook = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PLAYBOOK)
      : "";

    if (
      isInvalidRequest ||
      (!isSuperAdmin(this.props.roleId) &&
        !isCompanyAdmin(this.props.roleId) &&
        !isCompanyManager(this.props.roleId) &&
        status === "Draft")
    ) {
      return (
        <div className="app-body">
          <Page404 />
        </div>
      );
    }

    const styles = {
      // styles that are applied to the body from the admin
      backgroundColor: bgColor
    };

    const partnerFilterKeys = this.getPartnerFilterKeys();

    const appFilterKeys = this.getAppFilterKeys();

    let eid = getParamsByName("eid");

    return (
      <div className="marketplace-landing" style={styles}>
        {!landingPageBlocks && <Spinner />}
        {landingPageBlocks.map(block => (
          <>
            {block.type && block.type === LANDING_PAGE_BLOCK_TYPE_CONTENT && (
              <div
                dangerouslySetInnerHTML={{
                  __html: block.content ? block.content : ""
                }}
              />
            )}
            {block.type &&
              block.type === LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID &&
              block.playbooks &&
              block.playbooks.length > 0 && (
                <MarketplaceSection>
                  {block.playbooks.map(playbook => (
                    <PlaybookCard
                      data={playbook}
                      size={"col-12 col-md-6 col-lg-4"}
                      key={playbook.id}
                      url={`/playbook/${playbook.id}`}
                      link={`/playbook/${playbook.id}?redirect=${window.location.pathname}`}
                      history={this.props.history}
                      salesforceLandingGridArrow={true}
                      openLinkInNewTab={true}
                    />
                  ))}
                </MarketplaceSection>
              )}
            {block.type && block.type === LANDING_PAGE_BLOCK_TYPE_BANNER && (
              <BannerHeader
                background={{
                  bgColor: block.backgroundColor,
                  bgImage: block.backgroundImageDesktopUrl || "",
                  bgImageMobile: block.backgroundImageMobileUrl,
                  bgLogoLink: block.logoLink,
                  bgLogoImageDesktopImage: block.logoImageDesktopUrl,
                  bgLogoImageMobile:
                    block.logoImageMobileUrl || block.logoImageDesktopUrl,
                  bgTextColor: block.textColor,
                  bgTitle: block.title,
                  bgSubtitle: block.subtitle
                }}
                btn={{
                  text: block.buttonLabel,
                  url: block.buttonLink,
                  buttonColor: block.buttonColor
                }}
              >
                {block.title && <h1>{block.title}</h1>}
                {block.subtitle && <p>{block.subtitle}</p>}
              </BannerHeader>
            )}
            {block.type &&
              block.type === LANDING_PAGE_FEATURED_COLLECTION &&
              block.featuredCollection &&
              block.featuredCollection.length > 0 && (
                <MarketplaceSection className="p-0 m-0" title={block.title}>
                  <FeaturedCollectionSection
                    showAppsInSingelLine={block.show_in_single_line}
                    data={block.featuredCollection}
                  />
                </MarketplaceSection>
              )}
            {block.type &&
              block.type === LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT && (
                <MarketplaceAdvisor requestToBecomeAnAdvisor={block} />
              )}

            {enablePlaybook &&
              enablePlaybook === "true" &&
              block.type &&
              block.type === LANDING_PAGE_BLOCK_TYPE_PLAYBOOK &&
              block.playbooks &&
              block.playbooks.length > 0 && (
                <MarketplaceSection
                  title={block.title}
                  classnames={[
                    `${salesforceLanding && "salesforce-playbooks"}`
                  ].join(" ")}
                >
                  {/*TODO: @Santhosh to add the class above if it's a salesforce landing page*/}
                  {
                    <ConditionalWrap
                      condition={Boolean(salesforceLanding)}
                      wrap={children => (
                        <div className="playbook-wrapper col-12 col-sm-6 col-md-9">
                          {children}
                        </div>
                      )} // Can be anything
                    >
                      {block.playbooks.map(playbook => (
                        <PlaybookCard
                          data={playbook}
                          size={`${
                            salesforceLanding
                              ? "col-12 col-md-6 col-lg-12"
                              : "col-12 col-md-6 col-lg-3"
                          }`}
                          key={playbook.id}
                          showPrice={true}
                          link={`/playbook/${playbook.id}?redirect=${window.location.pathname}`}
                          history={this.props.history}
                          landingPageName={landingPageName}
                          isMarketPlaceLandingPage
                          fullWidth={salesforceLanding}
                          salesforceLanding={salesforceLanding}
                          tag={(salesforceLanding && "div") || "a"}
                          isPreview={"true"}
                          settings={settings}
                        />
                      ))}
                    </ConditionalWrap>
                  }
                  {/*TODO: @Santhosh to show the sidebar if it's a salesforce landing page.*/}
                  {salesforceLanding && (
                    <aside
                      className={
                        "landing-page-sidebar col-12 col-sm-6 col-md-3"
                      }
                    >
                      <div className="sidebar-cta purple">
                        <div className="content-wrapper">
                          <UserPlusIcon />
                          <span>Didn’t Find What You’re Looking For?</span>
                        </div>
                        <div className="btn-wrapper">
                          <a
                            href="/find-an-expert"
                            className="btn btn-outline-light h5"
                            target="_blank"
                            onClick={e => {
                              // Track MetaCX event for click on Get Social
                              metaCXTrack(
                                METACX_EVENT_MARKETPLACE_LANDING_PAGE_CONTACT_US_BUTTON_CLICK,
                                metaCXData,
                                settings,
                                () => {}
                              );
                            }}
                          >
                            Contact Us
                          </a>
                        </div>
                      </div>

                      <div className="sidebar-cta green">
                        <div className="content-wrapper">
                          <MessageCircleIcon />
                          <span>
                            Want to connect with other small businesses?
                          </span>
                        </div>
                        <div className="btn-wrapper">
                          <a
                            href="http://bit.ly/SmallBusinessStrong"
                            className="btn btn-outline-light h5"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={e => {
                              // Track MetaCX event for click on Get Social
                              metaCXTrack(
                                METACX_EVENT_MARKETPLACE_LANDING_PAGE_GET_SOCIAL_BUTTON_CLICK,
                                metaCXData,
                                settings,
                                () => {}
                              );
                            }}
                          >
                            Get Social
                          </a>
                        </div>
                      </div>

                      <div className="sidebar-cta blue">
                        <div className="content-wrapper">
                          <InlineInfoIcon />
                          <span>Want More Info About Torchlite?</span>
                        </div>
                        <div className="btn-wrapper">
                          <a
                            href={`${TORCHLITE_WEBSITE_URL}/about/`}
                            className="btn btn-outline-light h5"
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={e => {
                              // Track MetaCX event for click on Learn More
                              metaCXTrack(
                                METACX_EVENT_MARKETPLACE_LANDING_PAGE_LEARN_MORE_BUTTON_CLICK,
                                metaCXData,
                                settings,
                                () => {}
                              );
                            }}
                          >
                            Learn More
                          </a>
                        </div>
                      </div>
                    </aside>
                  )}
                </MarketplaceSection>
              )}
            {block.type &&
              block.type === LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT && (
                <Banner
                  alignCenter
                  theme="dark" // implement a dynamic solution for this, either provide a possibility to set button hover colors as well
                  title={block.title}
                  subtitle={block.subtitle}
                  textColor={block.textColor}
                  btn={{
                    text: block.buttonLabel,
                    url: block.buttonLink,
                    buttonColor: block.buttonColor
                  }}
                  background={{
                    bgColor:
                      block.backgroundColor && block.backgroundColor.length > 0
                        ? block.backgroundColor
                        : "#F5EEE5",
                    bgImage:
                      block.backgroundImageDesktopUrl &&
                      block.backgroundImageDesktopUrl.length > 0
                        ? block.backgroundImageDesktopUrl
                        : fallbackBackground,
                    bgImageMobile: block.backgroundImageMobileUrl
                  }}
                  classnames={""}
                />
              )}

            {enablePartners &&
              enablePartners === "true" &&
              block.type &&
              block.type === LANDING_PAGE_BLOCK_TYPE_PARTNER &&
              block.partners && (
                <div className="container">
                  <div className="row">
                    <div className="col-12 col-lg-3">
                      <div className="mt-5">
                        {this.state.isMobile === true ? (
                          <PartnerFilters
                            title={block.title}
                            partnerSelectedFilters={partnerSelectedFilterValues}
                            services={services}
                            industries={industries}
                            filterPartnerList={this.filterPartnerList}
                            searchTerm={this.searchTerm}
                            marketplaceId={this.props.match.params.id}
                            data={block}
                          />
                        ) : (
                          <PartnerSidebarFilter
                            typeIds={typeIds}
                            partnerSelectedFilters={partnerSelectedFilterValues}
                            landingBlockTagTypes={block.landingBlockTagTypes}
                            services={services}
                            industries={industries}
                            filterPartnerList={this.filterPartnerList}
                            searchTerm={this.searchTerm}
                            data={block}
                          />
                        )}
                      </div>
                    </div>
                    <div className="col-12 col-lg-9">
                      {!this.state.isMobile ? (
                        <MarketplaceSection title={block.title}>
                          {partnerFilterKeys.length &&
                          partnerFilterKeys.length > 0 ? (
                            <div className="filters-wrapper">
                              {partnerFilterKeys.map(
                                filterObj =>
                                  partnerSelectedFilters[filterObj] &&
                                  partnerSelectedFilters[filterObj].value && (
                                    <button
                                      className={`badge badge-primary ${filterObj}`}
                                      data-parentobj={filterObj}
                                      data-filter={
                                        partnerSelectedFilters[filterObj].value
                                      }
                                      onClick={this.deletePartnerFilters}
                                    >
                                      {partnerSelectedFilters[filterObj].label}{" "}
                                      <CrossIcon />
                                    </button>
                                  )
                              )}
                              <a
                                href=""
                                onClick={e => {
                                  e.preventDefault();
                                  this.resetPartnerFilters();
                                }}
                              >
                                Clear Filters...
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                          {partnerList && partnerList.length > 0 && (
                            <div className="col-12">
                              <span className="d-block font-weight-bold h6-5">
                                Showing {partnerList.length}{" "}
                                {partnerList.length > 1
                                  ? "partners"
                                  : "partner"}
                                …
                              </span>
                            </div>
                          )}
                          {partnerLoading ? (
                            <div className="col-12">
                              <div className="card pt-5 pb-5 mb-5">
                                <div className="pt-5 pb-5">
                                  <Spinner />
                                </div>
                              </div>
                            </div>
                          ) : partnerList && partnerList.length > 0 ? (
                            partnerList.map(partner => (
                              <PartnerCard
                                classnames={"mt-3"}
                                data={partner}
                                size={`col-12 col-md-6 col-lg-4 ${salesforceLanding &&
                                  "col-sm-6"}`}
                                key={partner.id}
                                certified={true}
                                link={`/partner-profile/${
                                  partner.companyProfileUrl
                                    ? partner.companyProfileUrl
                                    : partner.id
                                }?redirect=${window.location.pathname}`}
                                openLinkInNewTab={true}
                                fixedWidth={false}
                                headline={partner.headline}
                              />
                            ))
                          ) : (
                            <>
                              <div className="col-12">
                                <NoRecordsFound
                                  icon={partnerSearchIcon}
                                  showMessage="false"
                                  boldMessage="No Partners Found"
                                  description="Check the spelling or try another search using broader terms"
                                />
                                <PageTitle
                                  label="Other Partners You May Like"
                                  showHamburgermenu="false"
                                />
                                <Otherpartners
                                  landingPageId={this.props.match.params.id}
                                />
                              </div>
                            </>
                          )}
                        </MarketplaceSection>
                      ) : (
                        <>
                          {partnerFilterKeys.length &&
                          partnerFilterKeys.length > 0 ? (
                            <div className="filters-wrapper">
                              {partnerFilterKeys.map(
                                filterObj =>
                                  partnerSelectedFilters[filterObj] &&
                                  partnerSelectedFilters[filterObj].value && (
                                    <button
                                      className={`badge badge-primary ${filterObj}`}
                                      data-parentobj={filterObj}
                                      data-filter={
                                        partnerSelectedFilters[filterObj].value
                                      }
                                      onClick={this.deletePartnerFilters}
                                    >
                                      {partnerSelectedFilters[filterObj].label}{" "}
                                      <CrossIcon />
                                    </button>
                                  )
                              )}
                              <a
                                href=""
                                onClick={e => {
                                  e.preventDefault();
                                  this.resetPartnerFilters();
                                }}
                              >
                                Clear Filters...
                              </a>
                            </div>
                          ) : (
                            ""
                          )}
                          {partnerList && partnerList.length > 0 && (
                            <div className="col-12">
                              <span className="d-block font-weight-bold h6-5">
                                Showing {partnerList.length}{" "}
                                {partnerList.length > 1
                                  ? "partners"
                                  : "partner"}
                                …
                              </span>
                            </div>
                          )}
                          {partnerLoading ? (
                            <div className="col-12">
                              <div className="card pt-5 pb-5 mb-5">
                                <div className="pt-5 pb-5">
                                  <Spinner />
                                </div>
                              </div>
                            </div>
                          ) : partnerList && partnerList.length > 0 ? (
                            partnerList.map(partner => (
                              <PartnerCard
                                classnames={"mt-3"}
                                data={partner}
                                size={`col-12 col-md-6 col-lg-4 ${salesforceLanding &&
                                  "col-sm-6"}`}
                                key={partner.id}
                                certified={true}
                                link={`/partner-profile/${partner.id}?redirect=${window.location.pathname}`}
                                openLinkInNewTab={true}
                                fixedWidth={false}
                              />
                            ))
                          ) : (
                            <>
                              <div className="col-12">
                                <NoRecordsFound
                                  icon={partnerSearchIcon}
                                  showMessage="false"
                                  boldMessage="No Partners Found"
                                  description="Check the spelling or try another search using broader terms"
                                />
                                <PageTitle
                                  label="Other Partners You May Like"
                                  showHamburgermenu="false"
                                />
                                <Otherpartners
                                  landingPageId={this.props.match.params.id}
                                />
                              </div>
                            </>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              )}

            {block.type &&
              block.type === LANDING_PAGE_BLOCK_TYPE_EXPERT &&
              block.experts &&
              block.experts.length > 0 && (
                <MarketplaceSection title={block.title}>
                  {block.experts.map(expert => (
                    <ExpertCard
                      classnames={"expert-card"}
                      data={expert}
                      size={`col-12 col-md-6 col-lg-3 ${salesforceLanding &&
                        "col-sm-6"}`}
                      key={expert.id}
                      certified={true}
                      url={`/expert-profile/${expert.id}`}
                      link={`/expert-profile/${expert.id}`}
                      fixedWidth={false}
                      openLinkInNewTab={true}
                    />
                  ))}
                </MarketplaceSection>
              )}

            {block.type && block.type === LANDING_PAGE_BLOCK_TYPE_SIGN_UP && (
              <Banner
                theme="dark" // implement a dynamic solution for this, either provide a possibility to set button hover colors as well
                title={block.title}
                subtitle={block.subtitle}
                textColor={block.textColor}
                btn={{
                  text: block.buttonLabel,
                  url: block.buttonLink,
                  buttonColor: block.buttonColor
                }}
                background={{
                  bgColor:
                    block.backgroundColor && block.backgroundColor.length > 0
                      ? block.backgroundColor
                      : "#F5EEE5",
                  bgImage:
                    block.backgroundImageDesktopUrl &&
                    block.backgroundImageDesktopUrl.length > 0
                      ? block.backgroundImageDesktopUrl
                      : fallbackBackground,
                  bgImageMobile: block.backgroundImageMobileUrl
                }}
                classnames={""}
              />
            )}

            {block.type &&
              block.type === LANDING_PAGE_BLOCK_TYPE_APPS &&
              block.appDetail &&
              block.appDetail.length >= 0 && (
                <>
                  <div className="container">
                    <div className="row">
                      {block.show_filter == "true" && (
                        <div className="col-12 col-lg-3">
                          <div className="mt-5">
                            <AppSideBarFilter
                              details={block.appDetail}
                              filterAppList={this.filterAppList}
                              appSelectedFilters={
                                blockFilters &&
                                blockFilters[block.id] &&
                                blockFilters[block.id].appSelectedFiltersValues
                              }
                              searchTerm={term =>
                                this.updateBlockSearchTerm(term, block.id)
                              }
                              tagTypeList={this.state.tagTypeList}
                              id={block.id}
                              landingPageBlockId={this.state.landingPageBlockId}
                            />
                          </div>
                        </div>
                      )}
                      <div className="col-12 col-lg-9">
                        <MarketplaceSection title={block.title}>
                          {this.renderFilterSection(block.id)}

                          {block.appDetail && block.appDetail.length > 0 && (
                            <div className="col-12 pl-0 mb-3">
                              <span className="d-block font-weight-bold h6-5">
                                Showing {block.appDetail.length}{" "}
                                {block.appDetail.length > 1 ? "apps" : "app"}…
                              </span>
                            </div>
                          )}
                          {appLoading &&
                          this.state.landingPageBlockId == block.id ? (
                            <div className="col-12">
                              <div className="card pt-5 pb-5 mb-5">
                                <div className="pt-5 pb-5">
                                  <Spinner />
                                </div>
                              </div>
                            </div>
                          ) : block.appDetail && block.appDetail.length > 0 ? (
                            block.appDetail.map(app => (
                              <MarketplaceAppCard
                                data={app}
                                size={"col-12 col-sm-4"}
                                key={app.id}
                                url={`/app/${app.profile_url}?eid=${eid}`}
                                link={`/app/${app.profile_url}?eid=${eid}`}
                                history={this.props.history}
                                salesforceLandingGridArrow={true}
                                openLinkInNewTab={true}
                                partnerDetails
                              />
                            ))
                          ) : (
                            <>
                              <div className="col-12">
                                <NoRecordsFound
                                  icon={partnerSearchIcon}
                                  middleHeight="100%"
                                  showMessage="false"
                                  boldMessage="No Apps Found"
                                  description="Check the spelling or try another search using broader terms"
                                />
                                <PageTitle
                                  label="Other Apps You May Like"
                                  showHamburgermenu="false"
                                />
                              </div>
                            </>
                          )}
                        </MarketplaceSection>
                      </div>
                    </div>
                  </div>
                </>
              )}
          </>
        ))}
      </div>
    );
  }
}

Marketplace.propTypes = {
  actions: PropTypes.object,
  landingPage: PropTypes.object
};

function mapDispatchToProps(dispatch) {
  return { actions: bindActionCreators(landingPageActionCreators, dispatch) };
}

export default connect(
  state => ({
    landingPage: state.landingPage,
    roleId: state.user ? state.user.roleId : ""
  }),
  mapDispatchToProps
)(Marketplace);
