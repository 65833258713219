import React from "react";

// Components
import PageTitle from "../../components/base/PageTitle";

import AddCustomerForm from "./AddCustomerForm";

const AddCustomer = props => {
  return (
    <div>
      <PageTitle label="Add Customer" />
      <AddCustomerForm history={props.history} />
    </div>
  );
};

export default AddCustomer;
