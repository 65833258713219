import React, { Component } from "react";

// Components
import Text from "../../components/base/Text";
import Select from "../../components/base/Select";
import Zipcode from "../../components/base/Zipcode";
import Phone from "../../components/base/Phone";
import State from "../../components/State";
import Country from "../../components/base/Country";

// Constants
import { CANADA, UNITED_STATES } from "../../Country/Constants";

class CustomerFormFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedCountry: "",
      selectedCountryName: "",
      country: props.country || "",
      countryId: props.countryId || ""
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  // Handle Country
  handleCountryChange = ({ values }) => {
    const selectedOption = values.country && values.country.id;
    const selectedCountryName = values.country && values.country.label;
    this.setState({
      selectedCountry: selectedOption,
      selectedCountryName
    });
  };

  // Render the form fields
  render() {
    // Master Service Agreement Status options
    const masterServiceAgreementStatusOption = [
      {
        value: "Sent",
        label: "Sent"
      },
      {
        value: "Signed",
        label: "Signed"
      },
      {
        value: "Expired",
        label: "Expired"
      }
    ];

    // css for sent MSA
    const linkStyle = {
      padding: "0",
      paddingTop: "37px"
    };

    const {
      selectedCountry,
      selectedCountryName,
      country,
      selectedOption
    } = this.state;
    const { countryId } = this.props;

    return (
      <div className="card border-0">
        <div className="card-body">
          <div className="col-lg-6">
            <div className="field-wrapper">
              <Text
                name="displayCompanyName"
                label="Display Company Name"
                placeholder="Enter Display Company Name"
                required={true}
                error=""
              />
            </div>
            <div className="field-wrapper">
              <Text
                name="legalCompanyName"
                label="Legal Company Name"
                placeholder="Enter Legal Company Name"
                error=""
              />
            </div>
            <div className="field-wrapper">
              <Text
                name="legalContactFullName"
                label="Legal Contact Full Name"
                placeholder="Enter Legal Contact Full Name"
              />
            </div>
            <div className="field-wrapper">
              <Text
                name="legalContactEmail"
                label="Legal Contact Email"
                placeholder="Enter Legal Contact Email"
              />
            </div>
            <div className="field-wrapper align-items-end">
              <div className="row">
                <div className="col-lg-9 mw-100">
                  <Select
                    name="masterServiceAgreementStatus"
                    label="Master Service Agreement Status"
                    placeholder="Select MSA Status"
                    options={masterServiceAgreementStatusOption}
                    isSearchable={true}
                  />
                </div>
                <div className="col-lg-3" style={linkStyle}>
                  <a href="#" className={["text-underline"].join(" ")}>
                    Send MSA
                  </a>
                </div>
              </div>
            </div>
            <div className="field-wrapper">
              <Text
                name="address1"
                label="Address1"
                placeholder="Enter Address1"
              />
            </div>
            <div className="field-wrapper">
              <Text
                name="address2"
                label="Address2"
                placeholder="Enter Address2"
              />
            </div>
            <div className="field-wrapper">
              <Text name="city" label="City" placeholder="Enter City" />
            </div>
            <div className="field-wrapper">
              <State
                name="state"
                label="State/Province/Region"
                placeholder="Enter State"
                error=""
                selectedCountry={
                  selectedOption ? selectedOption : selectedCountry
                }
                selectedCountryName={
                  selectedCountryName ? selectedCountryName : ""
                }
                // notify={true}
                // required
              />
            </div>
            <div className="field-wrapper">
              <Country
                name="country"
                label="Country"
                placeholder="Select Country"
                error=""
                onChange={this.handleCountryChange}
              />
            </div>
            <div className="field-wrapper">
              <Zipcode
                name="postalCode"
                label="Zip/Postal Code"
                placeholder="Enter Zip Code"
                required={true}
                locale={
                  selectedCountryName ? selectedCountryName : UNITED_STATES
                }
              />
            </div>
            <div className="field-wrapper">
              <Phone name="phone" label="Phone" placeholder="Enter Phone" />
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CustomerFormFields;
