const REACT_APP_API_URL = process.env.REACT_APP_API_URL;

const appApi = path => `${REACT_APP_API_URL}/${path}`;

// API call routes
export const endpoints = version => ({
  expertAvailabilityUpdate: appApi("v1/users/capacityJournals"),
  expertRequest: appApi("v1/experts/request"),
  expertRequestList: appApi("v1/experts/request"),
  userCapacityList: appApi("v1/users/capacity"),
  userLogin: appApi("v1/user/login"),
  userAvatarUpdate: appApi("v1/user/updateAvatar"),
  dashboardAPI: appApi("v1/dashboard"),
  customerAPI: appApi("v1/customer"),
  expertAPI: appApi("v1/expert"),
  partnerInviteAPI: appApi("v1/partner/invite"),
  partnerFeedbackAPI: appApi("v1/partner/feedback"),
  expertAvatarUpdate: appApi("v1/expert/updateAvatar"),
  partnerAvatarUpdate: appApi("v1/partner/updateAvatar"),
  expertRecommendationAPI: appApi("v1/expert/recommendation"),
  expertCertificationAPI: appApi("v1/expert/certification"),
  expertEmploymentAPI: appApi("v1/expert/employment"),
  expertEducationAPI: appApi("v1/expert/education"),
  expertAvailabilityAPI: appApi("v1/expert/updateAvailability"),
  expertProfileVisibilityAPI: appApi("v1/expert/updateProfileVisibility"),
  expertSignUp: appApi("v1/expert"),
  expertSignUpUpdate: appApi("v1/expert"),
  expertSignUpAvatarUpdate: appApi("v1/expert/updateAvatar"),
  partnerSignUp: appApi("v1/partner"),
  partnerSignUpUpdate: appApi("v1/partner"),
  partnerSignUpAvatarUpdate: appApi("v1/partner/updateAvatar"),
  tagAPI: appApi("v1/tag"),
  tagTypeAPI: appApi("v1/tagType"),
  projectAPI: appApi("v1/project"),
  userAPI: appApi("v1/user"),
  settingAPI: appApi("v1/setting"),
  systemSettingAPI: appApi("v1/systemSetting"),
  projectCategoryAPI: appApi("v1/projectCategory"),
  playbookAPI: appApi("v1/playbook"),
  playbookTagAPI: appApi("v1/playbook/tags/list"),
  collectionAPI: appApi("v1/collection"),
  customerUserAPI: appApi("v1/customerUser"),
  paymentAPI: appApi("v1/payment"),
  invoiceAPI: appApi("v1/invoice"),
  discussionAPI: appApi("v1/discussion"),
  collectionPlaybookAPI: appApi("v1/collectionPlaybook"),
  landingPageAPI: appApi("v1/landingPage"),
  landingPageBlocksAPI: appApi("v1/landingPageBlocks"),
  expertProfileTagAPI: appApi("v1/expertProfileTag"),
  publicAPI: appApi("v1/public"),
  stripeAPI: appApi("v1/stripe"),
  orderAPI: appApi("v1/order"),
  projectUserAPI: appApi("v1/projectUser"),
  projectDiscussionAPI: appApi("v1/projectDiscussion"),
  projectTimeEntryAPI: appApi("v1/projectTimeEntry"),
  projectDeliverableAPI: appApi("v1/project/deliverable"),
  projectReviewAPI: appApi("v1/project/review"),
  expertReviewAPI: appApi("v1/expert/review"),
  reviewAPI: appApi("v1/review"),
  projectDeliverableMaterialAPI: appApi("v1/project/deliverable/material"),
  publicTagAPI: appApi("v1/public/tag/list"),
  publicProjectCategoryAPI: appApi("v1/public/projectCategories/list"),
  publicUserAPI: appApi("v1/public/customer/signup"),
  faqAPI: appApi("v1/faq"),
  couponAPI: appApi("v1/coupon"),
  partnerAPI: appApi("v1/partner"),
  partnerPlaybookAPI: appApi("v1/partner/playbook"),
  projectExtendAPI: appApi("v1/projectExtend"),
  partnerRequest: appApi("v1/partners/request"),
  activity: appApi("v1/activity"),
  systemLogAPI: appApi("v1/system/log"),
  contactForm: appApi("v1/contactForm"),
  contactFormSubmission: appApi("v1/contactFormSubmission"),
  partnerType: appApi("v1/partnerType"),
  partnerTypeSettingAPI: appApi("v1/partnerTypeSetting"),
  partnerTier: appApi("v1/partnerTier"),
  resourceAPI: appApi("v1/partner/resource"),
  courseAPI: appApi("v1/course"),
  dealsAPI: appApi("v1/deal"),
  dealPartnerAPI: appApi("v1/dealPartner"),
  leadPartnerAPI: appApi("v1/leadPartner"),
  lead: appApi("v1/lead"),
  salesforceLeadStatusMappingAPI: appApi("v1/salesforce/lead/status/mapping"),
  salesforceLeadFieldMappingAPI: appApi("v1/salesforce/lead/field/mapping"),
  salesforceFieldMappingAPI: appApi("v1/salesforce/field/mapping"),
  referralApi: appApi("v1/referral"),
  referralRequestApi: appApi("v1/referralRequest"),
  referralPartnerAPI: appApi("v1/referralPartner"),
  salesforceUserAPI: appApi("v1/salesforceUser"),
  referralLinkAPI: appApi("v1/referralLink"),
  contactAPI: appApi("v1/contact"),
  appAPI: appApi("v1/app"),
  partnerTierAPI: appApi("v1/partner/tier"),
  partnerUserAPI: appApi("v1/partnerUser"),
  countryAPI: appApi("v1/country"),
  stateAPI: appApi("v1/state"),
  accountAPI: appApi("v1/account"),
  syncAPI: appApi("v1/sync"),
  salesforceLookupAPI: appApi("v1/salesforce/lookup"),
  salesforceAPI: appApi("v1/salesforce"),
  billDotComAPI: appApi("v1/billdotcom"),
  accountPaymentMethodAPI: appApi("v1/account/payment/method"),
  billingInfoAPI: appApi("v1/account/billing/info"),
  crossbeamAPI: appApi("v1/crossbeam"),
  crossbeamPartnerAPI: appApi("v1/crossbeam/partner"),
  referralStatusAPI: appApi("v1/referralStatus"),
  schedulerJobAPI: appApi("v1/schedulerJob"),
  companyAPI: appApi("v1/company"),
  taskAPI: appApi("v1/task"),
  partnerApplicationFieldAPI: appApi("v1/partnerApplicationField"),
  partnerApplicationTaskAPI: appApi("v1/partnerApplicationTask"),
  partnerApplicationFormAPI: appApi("v1/partnerApplicationForm"),
  pipelineReportAPI: appApi("v1/report/pipeline"),
  messageAPI: appApi("v1/message"),
  referralQuestionAPI: appApi("v1/referralQuestion"),
  partnerTypeAPI: appApi("v1/partnertype"),
  mdfRequestAPI: appApi("v1/mdfRequest"),
  googleApi: appApi("v1/google"),
  userRoleAPI: appApi("v1/user/role"),
  permissionAPI: appApi("v1/permission"),
  userRolePermissionAPI: appApi("v1/role/permission"),
  mediaAPI: appApi("v1/media"),
  emailTemplateAPI: appApi("v1/emailTemplate"),
  urlApi: appApi("v1/url"),
  appSiteAPI: appApi("v1/appSite"),
  appVersionApi: appApi("v1/appVersion"),
  appPricingAPI: appApi("v1/appPricing"),
  salesforceSyncRuleRoute: appApi("v1/syncRule"),
  hubSpotRoute: appApi("v1/hubspot"),
  hubSpotLookUpRoute: appApi("v1/hubspot/lookup"),
  hubSpotFieldMapRoute: appApi("v1/hubspot/fieldMap"),
  hubspotSyncRule: appApi("v1/hubspot/syncRule"),
  terminologyAPI: appApi("v1/terminology"),
  bomboraAPI: appApi("v1/bombora"),
  cross_beamAPI: appApi("v1/cross_beam"),
  userRolePermissionAPI: appApi("v1/userRolePermission"),
  slackAPI: appApi("v1/slack"),
  overallSummaryAPI: appApi("v1/overallSummary"),
  referralReportApi: appApi("v1/referralReport"),
  partnerReportApi: appApi("v1/partnerReport"),
  partnerUserReportApi: appApi("v1/partnerUserReport"),
  certificateApi: appApi("v1/certificate"),
  intentDataAPI: appApi("v1/intentData"),
  intentDatasAPI: appApi("v1/intentDatas"),
  coSellAPI: appApi("v1/coSell"),
  fileAPI: appApi("v1/file"),
  awsDealAPI: appApi("v1/awsDeal"),
  marketplaceFeaturedCollectionAPI: appApi("v1/marketplaceFeaturedCollection")
});

// Default API Key
export const DEFAULT_API_KEY = process.env.REACT_APP_DEFAULT_API_KEY || "";

// WebSite URL
export const TORCHLITE_WEBSITE_URL =
  process.env.REACT_APP_TORCHLITE_WEBSITE_URL || "https://torchlite.com";
