import React, { Component } from "react";
import _ from "lodash";
import { Link } from "react-router-dom";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

//Assets
import { CrossIcon, ReportIcon } from "../../../../assets/img/icons";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";

// Action
import { fetchList } from "../../../../actions/table";

// Constants
import StatisticsCountCard from "../../../../components/base/StatisticsCountCard";
import ProgressBar from "../../../../components/base/ProgressBar";
import PageSearch from "../../../../components/base/PageSearch";
import SelectDropdown from "../../../../components/base/SelectDropdown";
import CheckboxDropdownSelectGroup from "../../../../components/base/CheckboxDropdownSelectGroup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons/faArrowAltCircleUp";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { isBadRequest } from "../../../../common/http";
import { TAB_PARTNER_TIER_OR_QUOTA_REPORT } from "../../../../reports/Constants";
import { getStringToUrl } from "../../../../lib/helper";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  width: "fit-content",
  fontSize: "13px",
  paddingLeft: "5px",
  paddingTop: "2px",
  paddingRight: "5px"
};

const FILTER_GROUP_PARTNER_TIER = "partnerTier";
const FILTER_GROUP_QUOTA_AMOUNT = "quotaAmount";
class PartnerTier extends Component {
  state = {
    startDate: "",
    endDate: "",
    status: "",
    tierList: [],
    quotaAmountList: [],
    tierCountData: [],
    searchTerm: "",
    selectedSortOption: "company_name:ASC",
    sortByOptions: [
      {
        value: "company_name:ASC",
        label: "Partner Name A-Z"
      },
      {
        value: "createdAt:DESC",
        label: "Most Recent"
      }
    ],
    filterOptions: [],
    partnerTierSelectedFilters: {},
    partnerTierSelectedFilterList: [],
    selectedPartnerTierNameList: [],
    selectedPartnerTierQuotaList: []
  };
  componentDidMount() {
    this._getTierPartnerCounts();
    this._getTierQuotaPartnerCounts();
    const { sortByOptions } = this.state;

    this.setState(
      {
        selectedSortOption:
          sortByOptions && sortByOptions.length
            ? sortByOptions[0].value
            : this.state.selectedSortOption
      },
      () => {
        const selectedSortOption = this.getSelectedSortLabel(
          this.state.selectedSortOption
        );
        this.handleSortByChange(selectedSortOption);
      }
    );
  }
  // Get Tier Partner Counts
  _getTierPartnerCounts = () => {
    return apiClient
      .get(`${endpoints().partnerAPI}/tier/count`)
      .then(response => {
        const tierCountData = [],
          tierList = [],
          quotaAmountList = [];
        response.data.data.forEach(data => {
          tierCountData.push({
            id: data.id,
            name: data.name,
            partnerCount: data.partnerCount,
            quotaAmount: data.quotaAmount
          });
          tierList.push({
            label: data.name,
            value: data.name,
            id: data.id
          });
          quotaAmountList.push({
            label: `$${data.quotaAmount} Quota`,
            value: data.quotaAmount,
            id: data.id
          });
        });
        this.setState({
          tierCountData: tierCountData,
          tierList: tierList,
          quotaAmountList: quotaAmountList,
          filterOptions: [
            ...this.state.filterOptions,
            {
              groupLabel: "Tier Level",
              groupValue: FILTER_GROUP_PARTNER_TIER,
              options: tierList
            },
            {
              groupLabel: "Quota Amount",
              groupValue: FILTER_GROUP_QUOTA_AMOUNT,
              options: quotaAmountList
            }
          ],
          loading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get Tier Partner Counts
  _getTierQuotaPartnerCounts = () => {
    return apiClient
      .get(`${endpoints().partnerAPI}/tierQuota/count`)
      .then(response => {
        const quotaAchievedCounts = (response.data && response.data.data) || {};

        const {
          totalPartnerCount,
          achievedQuotaPartnerCount,
          underQuotaPartnerCount,
          overQuotaPartnerCount
        } = quotaAchievedCounts;

        this.setState({
          totalPartnerCount,
          achievedQuotaPartnerCount,
          underQuotaPartnerCount,
          overQuotaPartnerCount,
          loading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get Selected Filters
  getPartnerTierSelectedFilters = selectedFilters => {
    this.setState(
      {
        partnerTierSelectedFilters: selectedFilters
      },
      () => {
        this.setPartnerTierSelectedFilterList();
      }
    );
  };

  setPartnerTierSelectedFilterList = async () => {
    const { partnerTierSelectedFilters } = this.state;

    const selectedFilterGroups = Object.entries(partnerTierSelectedFilters);

    let partnerTierSelectedFilterList = [],
      selectedPartnerTierNameList = [],
      selectedPartnerTierQuotaList = [];

    if (selectedFilterGroups.length > 0) {
      selectedFilterGroups.forEach(async filterGroup => {
        const filterGroupTags = Object.entries(filterGroup[1]);

        if (filterGroupTags.length > 0) {
          filterGroupTags.forEach(async filterGroupTag => {
            if (filterGroupTag[1] === true) {
              const selectedFilterTagLabel = this.getSelectedFilterTagLabel(
                filterGroupTag[0]
              );

              if (selectedFilterTagLabel)
                partnerTierSelectedFilterList.push(selectedFilterTagLabel);

              if (filterGroup[0] === FILTER_GROUP_PARTNER_TIER) {
                selectedPartnerTierNameList.push(filterGroupTag[0]);
              } else if (filterGroup[0] === FILTER_GROUP_QUOTA_AMOUNT) {
                selectedPartnerTierQuotaList.push(filterGroupTag[0]);
              }
            }
          });
        }
      });
      this.setState(
        {
          partnerTierSelectedFilterList: partnerTierSelectedFilterList,
          selectedPartnerTierNameList: selectedPartnerTierNameList,
          selectedPartnerTierQuotaList: selectedPartnerTierQuotaList
        },
        () => {
          this.fetchData();
          this.updateUrl();
        }
      );
    }
  };

  deletePartnerTierFilters = async e => {
    const { dataset } = e.target;
    const { partnerTierSelectedFilters } = this.state;
    const filterTagValue = this.getSelectedFilterTagValue(dataset["filter"]);
    if (
      partnerTierSelectedFilters.quotaAmount &&
      partnerTierSelectedFilters.quotaAmount[filterTagValue]
    )
      partnerTierSelectedFilters.quotaAmount[filterTagValue] = false;
    if (
      partnerTierSelectedFilters.partnerTier &&
      partnerTierSelectedFilters.partnerTier[filterTagValue]
    )
      partnerTierSelectedFilters.partnerTier[filterTagValue] = false;

    this.setState(
      {
        partnerTierSelectedFilters: partnerTierSelectedFilters
      },
      () => {
        this.setPartnerTierSelectedFilterList();
      }
    );
  };

  getSelectedFilterTagLabel(value) {
    const { tierList, quotaAmountList } = this.state;

    let selectedFilterTagOption = tierList.find(option => {
      if (option.value === value) {
        return option;
      }
    });

    if (selectedFilterTagOption) {
      return selectedFilterTagOption.label;
    } else {
      selectedFilterTagOption = quotaAmountList.find(option => {
        if (option.value === value) {
          return option;
        }
      });
      if (selectedFilterTagOption) {
        return selectedFilterTagOption.label;
      }
    }

    return "";
  }

  getSelectedFilterTagValue(label) {
    const { tierList, quotaAmountList } = this.state;

    let selectedFilterTagOption = tierList.find(option => {
      if (option.label === label) {
        return option;
      }
    });

    if (selectedFilterTagOption) {
      return selectedFilterTagOption.value;
    } else {
      selectedFilterTagOption = quotaAmountList.find(option => {
        if (option.label === label) {
          return option;
        }
      });
      if (selectedFilterTagOption) {
        return selectedFilterTagOption.value;
      }
    }

    return "";
  }

  resetFilters = () => {
    const quotaAmountObj =
      (this.state.partnerTierSelectedFilters &&
        this.state.partnerTierSelectedFilters.quotaAmount &&
        Object.keys(this.state.partnerTierSelectedFilters.quotaAmount).forEach(
          i => (this.state.partnerTierSelectedFilters.quotaAmount[i] = false)
        )) ||
      {};

    const partnerTierObj =
      (this.state.partnerTierSelectedFilters &&
        this.state.partnerTierSelectedFilters.partnerTier &&
        Object.keys(this.state.partnerTierSelectedFilters.partnerTier).forEach(
          i => (this.state.partnerTierSelectedFilters.partnerTier[i] = false)
        )) ||
      {};

    this.setState(
      {
        partnerTierSelectedFilters: {
          quotaAmount: quotaAmountObj,
          partnerTier: partnerTierObj
        }
      },
      () => {
        this.setPartnerTierSelectedFilterList();
      }
    );
  };

  getSelectedSortLabel() {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.value === this.state.selectedSortOption
    );

    if (selectedSortOption) {
      return selectedSortOption.label;
    }

    return "";
  }

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  // Sort by option
  getSortByOptions(value) {
    const valueArray = this.getSortValueFromLabel(value).split(":");
    const sortBy = valueArray[0];
    const sortDir = valueArray[1];
    this.setState({ sort: sortBy, sortDir }, () => {
      this.fetchData();
      this.updateUrl();
    });
  }

  handleSortByChange = value => {
    this.setState({ selectedSortOption: this.getSortValueFromLabel(value) });
    this.getSortByOptions(value);
    this.updateUrl();
  };
  /**
   * Change search term
   *
   * @param {*} event
   */
  onChange(event) {
    event.persist();
    this.doSearch(event);
  }

  doSearch = _.debounce(event => {
    this.setState(
      {
        isSearch: true,
        searchTerm: encodeURIComponent(event.target.value),
        page: 1
      },
      () => {
        this.fetchData();
        this.updateUrl();
      }
    );
  }, 500);

  fetchData = () => {
    const {
      sort,
      sortDir,
      searchTerm,
      selectedPartnerTierNameList,
      selectedPartnerTierQuotaList
    } = this.state;
    //output of searchterm
    this.props.actions.fetchList(
      "partnerTierQuotaReports",
      `${endpoints().partnerAPI}/tier/report`,
      1,
      10,
      {
        pagination: true,
        sort,
        sortDir,
        search: searchTerm ? searchTerm : "",
        tierNames:
          selectedPartnerTierNameList && selectedPartnerTierNameList.length > 0
            ? selectedPartnerTierNameList.toString()
            : "",
        tierQuotas:
          selectedPartnerTierQuotaList &&
          selectedPartnerTierQuotaList.length > 0
            ? selectedPartnerTierQuotaList.toString()
            : ""
      }
    );
  };
  updateUrl() {
    if (
      this.props.activeTab === getStringToUrl(TAB_PARTNER_TIER_OR_QUOTA_REPORT)
    ) {
      const { searchTerm, page, pageSize, sort, sortDir } = this.state;

      const params = [];

      if (searchTerm) {
        params.push(`search=${searchTerm}`);
      }

      if (page) {
        params.push(`page=${page}`);
      }

      if (pageSize) {
        params.push(`pageSize=${pageSize}`);
      }
      if (sort) {
        params.push(`sort=${sort}`);
      }
      if (sortDir) {
        params.push(`sortDir=${sortDir}`);
      }

      if (params.length > 0) {
        this.props.history.push(
          `/reports/partner-tier-quota-report/?${params.join("&")}`
        );
      }
    }
  }
  render() {
    const {
      tierCountData,
      totalPartnerCount,
      achievedQuotaPartnerCount,
      underQuotaPartnerCount,
      overQuotaPartnerCount,
      filterOptions,
      sort,
      searchTerm,
      sortDir,
      selectedPartnerTierNameList,
      selectedPartnerTierQuotaList,
      partnerTierSelectedFilterList
    } = this.state;
    return (
      <div>
        <div className="mt-4 mb-5">
          {tierCountData.length > 0 && (
            <div className="report-card-statistics">
              {tierCountData.map(tierCountDetails => (
                <StatisticsCountCard
                  count={tierCountDetails.partnerCount}
                  label={`# of Partners at ${tierCountDetails.name} Tier`}
                />
              ))}
            </div>
          )}

          <div className="card mb-5">
            <div className="px-5 py-3">
              <div className="row mb-4">
                <div className="col-5">
                  <div className="row">
                    <div className="col-3 px-0 text-right my-auto">
                      <h1 className="font-weight-bold">
                        {achievedQuotaPartnerCount}
                      </h1>
                    </div>
                    <div className="col-9">
                      # of Partners that have Achieved Tier Quota
                    </div>
                  </div>
                </div>
                <div className="col-7 px-0">
                  <div className="border-left pl-1">
                    <ProgressBar
                      fillColorClassName="blue-progress-bar"
                      value={achievedQuotaPartnerCount}
                      maxValue={totalPartnerCount}
                      leftText={`${Math.round(
                        (achievedQuotaPartnerCount / totalPartnerCount) * 100
                      )}%`}
                      rightText={`${totalPartnerCount} Partners`}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-4">
                <div className="col-5">
                  <div className="row">
                    <div className="col-3 px-0 text-right my-auto">
                      <h1 className="font-weight-bold">
                        {underQuotaPartnerCount}
                      </h1>
                    </div>
                    <div className="col-9">
                      # of Partners that have Under Tier Quota
                    </div>
                  </div>
                </div>
                <div className="col-7 px-0">
                  <div className="border-left pl-1">
                    <ProgressBar
                      fillColorClassName="red-progress-bar"
                      value={underQuotaPartnerCount}
                      maxValue={totalPartnerCount}
                      leftText={`${Math.round(
                        (underQuotaPartnerCount / totalPartnerCount) * 100
                      )}%`}
                      rightText={`${totalPartnerCount} Partners`}
                    />
                  </div>
                </div>
              </div>

              <div className="row mb-2">
                <div className="col-5">
                  <div className="row">
                    <div className="col-3 px-0 text-right my-auto">
                      <h1 className="font-weight-bold">
                        {overQuotaPartnerCount}
                      </h1>
                    </div>
                    <div className="col-9">
                      # of Partners that have Over Tier Quota
                    </div>
                  </div>
                </div>
                <div className="col-7 px-0">
                  <div className="border-left pl-1">
                    <ProgressBar
                      fillColorClassName="green-progress-bar"
                      value={overQuotaPartnerCount}
                      maxValue={totalPartnerCount}
                      leftText={`${Math.round(
                        (overQuotaPartnerCount / totalPartnerCount) * 100
                      )}%`}
                      rightText={`${totalPartnerCount} Partners`}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className={`page-heading cover`}>
            <div className="d-flex justify-content-between">
              <div>
                <PageSearch
                  classnames="page-search"
                  placeholder="Search"
                  onChange={this.onChange.bind(this)}
                  width="200px"
                />
              </div>
              <div>
                <CheckboxDropdownSelectGroup
                  dropdownLabel="Filter By:"
                  buttonLabel="Tiers/Quotas"
                  color={"gray"}
                  dropdownLinks={filterOptions}
                  hideCaret
                  width="180px"
                  getSelectedFilters={this.getPartnerTierSelectedFilters}
                  liveFilters={{}}
                />
              </div>
              <div>
                <SelectDropdown
                  buttonLabel={this.getSelectedSortLabel()}
                  dropdownLinks={this.state.sortByOptions}
                  color={"gray"}
                  hideCaret
                  selectName={"sortby_partner"}
                  handleChange={this.handleSortByChange}
                />
              </div>
            </div>
          </div>

          {partnerTierSelectedFilterList.length &&
          partnerTierSelectedFilterList.length > 0 ? (
            <div className="filters-wrapper">
              {partnerTierSelectedFilterList.map(filterObj => (
                <button
                  className={`badge badge-primary ${filterObj}`}
                  data-parentobj={filterObj}
                  data-filter={filterObj}
                  onClick={this.deletePartnerTierFilters}
                >
                  {filterObj} <CrossIcon />
                </button>
              ))}
              <a
                href=""
                onClick={e => {
                  e.preventDefault();
                  this.resetFilters();
                }}
              >
                Clear Filters...
              </a>
            </div>
          ) : (
            ""
          )}

          <ReduxTable
            id="partnerTierQuotaReports"
            apiURL={`${endpoints().partnerAPI}/tier/report`}
            icon={<ReportIcon />}
            searchDisabled
            params={{
              sort,
              sortDir,
              search: searchTerm ? searchTerm : "",
              tierNames:
                selectedPartnerTierNameList &&
                selectedPartnerTierNameList.length > 0
                  ? selectedPartnerTierNameList.toString()
                  : "",
              tierQuotas:
                selectedPartnerTierQuotaList &&
                selectedPartnerTierQuotaList.length > 0
                  ? selectedPartnerTierQuotaList.toString()
                  : ""
            }}
            // sortByOptions={this.state.sortByOptions}
            // paramsToUrl={true}
            // history={this.props.history}
            paramsToUrl={true}
            history={this.props.history}
          >
            <ReduxColumn
              width={"175px"}
              minWidth={"175px"}
              type="link"
              isClickable="false"
              field="company_name"
              sortBy="company_name"
              renderField={row => (
                <Link to={`/edit/partner/public-profile?id=${row.id}`}>
                  {row.companyName}
                </Link>
              )}
            >
              Account Name
            </ReduxColumn>
            <ReduxColumn
              width={"110px"}
              minWidth={"110px"}
              field="tierName"
              disableOnClick
            >
              Tier
            </ReduxColumn>
            <ReduxColumn
              width={"110px"}
              minWidth={"110px"}
              field="tierPercentage"
              disableOnClick
              renderField={row =>
                row.tierPercentage ? <span>{row.tierPercentage}%</span> : ""
              }
            >
              Rate
            </ReduxColumn>
            <ReduxColumn
              width={"110px"}
              minWidth={"110px"}
              className="text-left"
              field="tierQuota"
              disableOnClick
              renderField={row =>
                row.tierQuota != null ? <span>$ {row.tierQuota}</span> : ""
              }
            >
              Quota
            </ReduxColumn>
            <ReduxColumn
              maxWidth={"155px"}
              minWidth={"155px"}
              field="tierQuotaProgress"
              disableOnClick
              renderField={row => (
                <div
                  className={`text-uppercase ${
                    row.tierQuotaProgress < 100
                      ? "bg-danger"
                      : row.tierQuotaProgress === 100
                      ? "bg-primary"
                      : "landing-page-status-active"
                  }`}
                  style={statusStyle}
                >
                  <p className="text-left">
                    {row.tierQuotaProgress < 100 ? row.tierQuotaProgress : 100}%{" "}
                    <FontAwesomeIcon
                      icon={
                        row.tierQuotaProgress >= 100
                          ? faArrowAltCircleUp
                          : faArrowAltCircleDown
                      }
                    />
                  </p>
                </div>
              )}
            >
              Quota Progress
            </ReduxColumn>
            <ReduxColumn field="createdAt" sortBy="createdAt">
              Created At
            </ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

// Get ReportStatCard Counts
const mapStateToProps = state => {
  const { partnerTierQuotaReports } = state.table;
  const partnerTierQuotaReportsCount =
    partnerTierQuotaReports && !partnerTierQuotaReports.isFetching
      ? partnerTierQuotaReports.totalCount
      : 0;
  return {
    partnerTierQuotaReportsCount
  };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(PartnerTier);
