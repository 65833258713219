import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import toast from "../../../../components/base/Toast";

// Components
import Form from "../../../../components/base/Form";
import DeleteButton from "../../../../components/base/DeleteButton";
import CancelButton from "../../../../components/base/CancelButton";
import SaveButton from "../../../../components/base/SaveButton";
import PageTitle from "../../../../components/base/PageTitle";

import DeleteModal from "../../../../components/base/DeleteModal";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

// 404 Page Redirect
import Page404 from "../../../Page404";
import Spinner from "../../../../components/base/Spinner";

import { isBadRequest } from "../../../../common/http";
import Text from "../../../../components/Text";
import {
  GENERAL_TAB_ROLE,
  PERMISSION_TAB_ROLE,
  TAB_ROLE
} from "../../../../setting/Constants";
import Select from "../../../../components/base/Select";
import { User } from "../../../../Constants/Status";
import Permission from "./permissionTab";

export default class Detail extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      roleDetails: "",
      totalCount: "",
      loading: false,
      isInvalidRequest: false,
      openDeleteModal: false,
      activeTab: "",
      statusOption: [
        {
          value: User.STATUS_ACTIVE_VALUE,
          label: User.STATUS_ACTIVE_TEXT
        },
        {
          value: User.STATUS_INACTIVE_VALUE,
          label: User.STATUS_INACTIVE_TEXT
        }
      ],
      selectedSortOption: "Most Recent Activity",
      currentSection: ""
    };
  }

  componentDidMount = () => {
    const params = new URLSearchParams(this.props.history.location.search);

    const section = params.get("section");

    this.setState({
      currentSection: section,
      activeTab: section || GENERAL_TAB_ROLE
    });

    this.getDetails();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { search } = prevProps.history.location;
    const params = new URLSearchParams(search);
    const section = params.get("section");
    // Set current active tab
    if (this.state.currentSection !== section) {
      this.setState({
        currentSection: section,
        activeTab: section || GENERAL_TAB_ROLE
      });
    }
  }

  //  update API Call
  async _updateRole(data) {
    const { id } = this.state.roleDetails;

    data.status = data.status.value || null;

    try {
      const response = await apiClient.put(
        `${endpoints().userRoleAPI}/${id}`,
        data
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  }

  //  delete api call
  handleDelete = async () => {
    const { id } = this.state.roleDetails;
    try {
      const response = await apiClient.delete(
        `${endpoints().userRoleAPI}/${id}`
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      this.props.history.push("/admin-settings/Roles");
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  //  details api call by id
  getDetails = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().userRoleAPI}/${this.props.roleId}`
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }

      if (response.data) {
        this.setState({ roleDetails: response.data, loading: true });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
        this.setState({ isInvalidRequest: true });
      }
    }
  };

  toggle = tab => {
    this.setState(
      {
        activeTab: tab
      },
      () => {
        this.props.history.push(`?section=${tab}`);
      }
    );
  };

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return <Spinner />;
    }

    const { activeTab, statusOption, roleDetails } = this.state;

    const initialValues = {
      name: roleDetails.name,
      status: statusOption.find(item => item.value === roleDetails.status)
    };
    const { openDeleteModal } = this.state;

    return (
      <>
        <DeleteModal
          isOpen={openDeleteModal}
          toggle={() => {
            this.setState({ openDeleteModal: false });
          }}
          title="Delete Role "
          label={roleDetails.name}
          deleteFunction={this.handleDelete}
        />
        <div className="d-flex justify-content-between align-items-center">
          <PageTitle label={roleDetails.name} />
          <DeleteButton
            label={"Delete Role"}
            onClick={() => {
              this.setState({
                openDeleteModal: true
              });
            }}
          />
        </div>
        <Nav tabs className="admin-tabs">
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === GENERAL_TAB_ROLE
              })}
              onClick={() => {
                this.toggle(GENERAL_TAB_ROLE);
              }}
            >
              {GENERAL_TAB_ROLE}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === PERMISSION_TAB_ROLE
              })}
              onClick={() => {
                this.toggle(PERMISSION_TAB_ROLE);
              }}
            >
              {PERMISSION_TAB_ROLE}
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent activeTab={activeTab}>
          <TabPane tabId={GENERAL_TAB_ROLE}>
            <div className="card card-body">
              <div>
                <Form
                  initialValues={initialValues}
                  onSubmit={values => {
                    this._updateRole(values);
                  }}
                >
                  <div clasName="mt-3">
                    <Text
                      name="name"
                      label="Role Name"
                      placeholder="Enter Name"
                      error=""
                      required
                    />
                  </div>

                  <div>
                    <Select
                      name="status"
                      label="Status"
                      placeholder="Select Status"
                      error=""
                      options={statusOption}
                    />
                  </div>
                  <div className="mt-4">
                    <CancelButton
                      onClick={() =>
                        this.props.history.push("/admin-settings/Roles")
                      }
                    />
                    <SaveButton />
                  </div>
                </Form>
              </div>
            </div>
          </TabPane>
          <TabPane tabId={PERMISSION_TAB_ROLE}>
            <Permission
              activeSection={this.props.activeSection}
              history={this.props.history}
              roleId={this.props.roleId}
              match={this.props.match}
            />
          </TabPane>
        </TabContent>
      </>
    );
  }
}
