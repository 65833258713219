export const TAG_TYPE_SKILLS = "Skills";
export const TAG_TYPE_TOOLS = "Tools";
export const TAG_TYPE_INDUSTRIES = "Industries";
export const TAG_TYPE_EXPERT_ROLE = "Experts";
export const TAG_TYPE_PLATFORMS = "Platforms";
export const TAG_TYPE_SERVICES = "Services";
export const TAG_TYPE_ABM_EXPERTISE = "ABM Expertise";
export const TAG_TYPE_CRM_MARKETING = "CRM Marketing";
export const TAG_TYPE_MARKETING_CHANNEL = "Marketing Channel";
export const TAG_TYPE_PARTNER_ROLE = "Partners";
export const TAG_TYPE_CERTIFICATION = "Certifications";
export const TAG_TYPE_DEAL_STAGE = "Deal Stages";
export const TAG_TYPE_PARTNER_TIER = "Partner Tiers";
export const TAG_TYPE_PARTNER_TYPE = "Partner Types";
export const TAG_TYPE_PRODUCTS = "Products";
export const TAG_TYPE_SALESFORCE_LEAD_STATUS = "Salesforce Lead Status";
export const TAG_TYPE_TORCHLITE_DEAL_STATUS = "Torchlite Deal Status";
export const TAG_TYPE_SALESFORCE_LEAD_FIELD = "Salesforce Lead Field";
export const TAG_TYPE_TORCHLITE_DEAL_FIELD = "Torchlite Deal Field";
export const TAG_TYPE_SALESFORCE_OPPORTUNITY_FIELDS =
  "Salesforce Opportunity Fields";
export const TAG_TYPE_OBJECT_NAME = "Object Name";
export const TAG_TYPE_SALESFORCE_OBJECT_NAME = "Salesforce Object Name";
export const TAG_TYPE_REGION = "Region";
export const TAG_TYPE_RESOURCE_CATEGORY = "Resource Category";
export const TAG_TYPE_APP_CATEGORY = "App Category";
export const TAG_TYPE_TRAINING_CATEGORY = "Training Category";
export const TAG_TYPE_COURSE_LESSON_QUIZ_TYPE = "Course Lesson Quiz Type";
export const TAG_TYPE_PAYMENT_METHOD = "Payment Method Types";
export const TAG_TYPE_CURRENCY = "Currency";
export const TAG_TYPE_PHONE_COUNTRY_CODE = "Phone Country Code";
export const TAG_TYPE_FILE_CATEGORY = "File Category";
export const TAG_TYPE_COSELL_CATEGORY = "Co-Sell Category";
export const AWS_INDUSTRY_VERTICAL = "AWS Industry Vertical";
export const AWS_USECASE = "AWS UseCase";
export const AWS_SALES_ACTIVITY = "AWS Sales Activity";
export const AWS_SOLUTION_OFFERED = "AWS Solution Offered";
export const AWS_DELIVERY_MODEL = "AWS Delivery Model";
export const AWS_PRODUCT = "AWS Product";
export const AWS_APN_PROGRAM = "AWS Apn Program";
export const AWS_COMPETITIVE_TRACKING = "AWS Competitive Tracking";

// Type
export const TYPE_SYSTEM = "System";
export const TYPE_USER_DEFINED = "User Defined";

// Tag
export const SETTINGS_TAG_IS_SEARCHABLE = "isSearchable";
export const SETTINGS_TAG_IS_DEFAULT = "isDefault";

// Category
export const TAG_TYPE_CATEGORY_MARKETPLACE = "marketplace";
export const TAG_TYPE_CATEGORY_MARKETPLACE_LABEL = "Marketplace";
export const TAG_TYPE_CATEGORY_OTHERS = "others";
export const TAG_TYPE_CATEGORY_OTHERS_LABEL = "Others";

export const TAG_TYPE_CATEGORY_PARTNER = "partner";
export const TAG_TYPE_CATEGORY_PARTNER_LABEL = "Partner";
