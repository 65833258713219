import React from "react";

// Components
import { InlineInfoIcon } from "../../../../assets/img/icons";
import AlertBanner from "../../../../components/base/AlertBanner";

const OwnerAccountToogle = props => {
  const { setNoOpportunityOwnerToggleValues } = props;
  return (
    <>
      <AlertBanner
        icon={<InlineInfoIcon />}
        buttonLabel="Select Owner"
        buttonOnClick={() => {
          setNoOpportunityOwnerToggleValues({
            ownerAccountBanner: true,
            ownerSelectionModalOpen: true
          });
        }}
        removeOnClick={() => {
          setNoOpportunityOwnerToggleValues({
            ownerAccountBanner: false
          });
        }}
        message="Owner was not found. Select a Owner for the Opportunity"
      />
    </>
  );
};

export default OwnerAccountToogle;
