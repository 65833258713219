import React from "react";
import { Modal, ModalBody, ModalHeader, Input } from "reactstrap";

import Label from "../../../../components/base/Label";

const AccountSelectionModal = props => {
  const {
    isAccountSelectedModalOpen,
    referralDetails,
    selectedSalesforceAccount,
    setAccountSelectionModalValues,
    isAccountSelected,
    createAccount,
    accountType
  } = props;
  return (
    <Modal
      isOpen={isAccountSelectedModalOpen}
      className={["log-hours-modal", "edit-task-modal"].join(" ")}
    >
      <ModalHeader
        toggle={() => {
          setAccountSelectionModalValues({
            isSelectedSalesforceAccountConfirmed: false,
            isAccountSelectedModalOpen: false,
            isAccountSelected: false
          });
        }}
      >
        <h4 className={["font-weight-bold", "mt-3"].join(" ")}>
          Check Partial Matches
        </h4>
      </ModalHeader>
      <ModalBody className={["text-center", "mb-4"].join(" ")}>
        <div className="d-block">
          <h6 className={["font-weight-bold"].join(" ")}>
            Please Confirm the Account Name You Want to Use
          </h6>
          <div className="text-muted">
            <span style={{ fontSize: "11px" }}>
              This step will confirm that you’ve checked for partial matches on
              Salesforce.
            </span>
          </div>
          <div className="text-left mt-4">
            <div className="d-flex align-items-center justify-content-center">
              <div>
                {selectedSalesforceAccount && selectedSalesforceAccount.label && (
                  <div className="form-wrapper">
                    <div>
                      <Label>
                        <Input
                          type="radio"
                          name="user_radio"
                          className="radio-button"
                          onClick={() => {
                            setAccountSelectionModalValues({
                              isSelectedSalesforceAccountConfirmed: true,
                              isAccountSelected: true,
                              isAccountSelectedModalOpen: true
                            });
                          }}
                        />
                        <p className="d-flex align-items-center justify-content-start">
                          <span className="radio-placeholder mr-2" />
                          <span className="d-block text-left">
                            <span className="d-block font-weight-bold">
                              {selectedSalesforceAccount.label
                                ? selectedSalesforceAccount.label
                                : ""}
                            </span>

                            <span
                              className="d-block h7 text-muted"
                              style={{ fontSize: "11px" }}
                            >
                              Account name matched in Salesforce
                            </span>
                          </span>
                        </p>
                      </Label>
                    </div>
                  </div>
                )}

                <div className="form-wrapper">
                  <div>
                    <Label>
                      <Input
                        type="radio"
                        name="user_radio"
                        className="radio-button"
                        onClick={() => {
                          setAccountSelectionModalValues({
                            isSelectedSalesforceAccountConfirmed: false,
                            isAccountSelected: true,
                            isAccountSelectedModalOpen: true
                          });
                        }}
                      />
                      <p className="d-flex align-items-center justify-content-start">
                        <span className="radio-placeholder mr-2" />
                        <span className="d-block text-left">
                          <span className="d-block font-weight-bold">
                            {accountType === "Account (Prospect)"
                              ? referralDetails && referralDetails.account_name
                                ? referralDetails.account_name
                                : ""
                              : accountType === "Account (Partner)"
                              ? referralDetails &&
                                referralDetails.partner &&
                                referralDetails.partner.company_name
                                ? referralDetails.partner.company_name
                                : ""
                              : ""}
                          </span>

                          <span
                            className="d-block h7 text-muted"
                            style={{ fontSize: "11px" }}
                          >
                            Account name entered by Partner
                          </span>
                        </span>
                      </p>
                    </Label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center btn-wrapper">
              <button
                className="btn btn-outline-secondary px-3"
                onClick={() => {
                  setAccountSelectionModalValues({
                    isSelectedSalesforceAccountConfirmed: false,
                    currentAccountSearchTerm: " ",
                    isAccountSelected: false,
                    isAccountSelectedModalOpen: false,
                    isInspectAccountModalOpen:
                      accountType === "Account (Prospect)" ? true : false,
                    partnerInspectAccountModalOpen:
                      accountType === "Account (Partner)" ? true : false
                  });
                }}
              >
                Go Back
              </button>
              <button
                className="btn btn-primary px-3"
                onClick={() => {
                  setAccountSelectionModalValues({
                    isAccountSelectedModalOpen: false,
                    isNoPartnerAccountModalOpen: false,
                    isInspectAccountModalOpen: false,
                    partnerInspectAccountModalOpen: false
                  });

                  createAccount(accountType);
                }}
                disabled={!isAccountSelected}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AccountSelectionModal;
