import React from "react";

// react memo test.

const SomeComponents = React.memo(function SomeComponents(props) {
  return <div>{console.log("somecomponent render")}</div>;
});

// const SomeComponents = (props) => {
//   return <div>{console.log("somecomponent render")}</div>;
// }

export default SomeComponents;
