import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

// Config
import { endpoints } from "../../configs";

// Action
import { updateReview } from "../../actions/review";

class PartnerFeedbackTable extends Component {
  render() {
    return (
      <div>
        <ReduxTable
          id={this.props.tableName}
          showHeader
          searchPlaceholder="Search"
          newTableHeading
          apiURL={`${endpoints().partnerFeedbackAPI}/search`}
          disableColumnSort
          sortByOptions={this.props.sortByOptions}
        >
          <ReduxColumn field="userName">User Name</ReduxColumn>
          <ReduxColumn field="feedback">Feedback</ReduxColumn>
          <ReduxColumn field="createdAt">Created At</ReduxColumn>
        </ReduxTable>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updateReview }, dispatch)
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(PartnerFeedbackTable);
