import { Badge, Input } from "reactstrap";
import React from "react";
import { Link } from "react-router-dom";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../lib/helper";

// Icons
import { TrashIconAlt } from "../../../assets/img/icons";

// Components
import Label from "../../../components/base/Label";
import Avatar from "../../../components/base/Avatar";
import { setExpertBadge } from "../../../lib/helper";
import EditInterestStatusModal from "./EditInterestStatusModal";

import { InlineInfoIcon } from "../../../assets/img/icons";
import SampleProjectTooltip from "../../../views/projects/inner-components/SampleProjectTooltip";

import {
  PROJECT_USER_STATUS_SHORTLISTED,
  PROJECT_USER_STATUS_NO_RESPONSE,
  PROJECT_USER_STATUS_DECLINED
} from "../../../project/Constants";

import { useSelector } from "react-redux";

const SourceMoreWaitingExperts = props => {
  const {
    projectExpertList,
    deleteProjectExpert,
    handleShortListedExpert,
    selectedOptions,
    editInterestStatusModal,
    setEditInterestStatusModal,
    selectedEditInterestExpertId,
    updateExpertInterestStatus,
    redirectUrl
  } = props;

  const currentUser = useSelector(state => state.user);

  return (
    <>
      {projectExpertList &&
        projectExpertList.map(projectExpert => {
          const isShortListedStatus =
            projectExpert.status === PROJECT_USER_STATUS_SHORTLISTED;
          const isWaitingStatus =
            projectExpert.status === PROJECT_USER_STATUS_NO_RESPONSE;
          return (
            <div className="field-wrapper" key={projectExpert.expertId}>
              {projectExpert.expertId && (
                <div className="user-wrapper">
                  <div className="user-actions">
                    <a
                      href="javascript:void(0)"
                      className="text-inline-grayed mr-1 source-more cursor-pointer"
                      onClick={e => deleteProjectExpert(projectExpert.id)}
                    >
                      <TrashIconAlt />
                    </a>
                    <div className="form-wrapper mr-2">
                      <Label>
                        <Input
                          type="checkbox"
                          name="user_radio"
                          className="radio-button"
                          value={projectExpert.expertId}
                          onChange={handleShortListedExpert}
                          checked={
                            selectedOptions.indexOf(projectExpert.expertId) > -1
                          }
                          disabled={isWaitingStatus}
                        />
                        {isShortListedStatus ? (
                          <p className="d-flex align-items-center justify-content-start">
                            <span className="radio-placeholder checkbox-placeholder" />
                          </p>
                        ) : (
                          <p className="d-flex align-items-center justify-content-start">
                            <span style={{ width: "20px", height: "20px" }} />
                          </p>
                        )}
                      </Label>
                    </div>
                  </div>

                  <div className="field-icon">
                    <Link
                      target="_blank"
                      to={`/expert-profile/${projectExpert.expertId}${redirectUrl}`}
                    >
                      <Avatar
                        firstName={
                          projectExpert.firstName ? projectExpert.firstName : ""
                        }
                        lastName={
                          projectExpert.lastName ? projectExpert.lastName : ""
                        }
                        size="xs"
                        fontSize={12}
                        url={projectExpert.avatarUrl}
                      />
                    </Link>
                  </div>
                  <div className="expert-info ml-2 d-flex flex-column align-items-start">
                    {projectExpert.jobTitle && (
                      <p
                        className="h7 text-truncate"
                        style={{ maxWidth: "130px" }}
                      >
                        {projectExpert.jobTitle}
                      </p>
                    )}
                    <Link
                      target="_blank"
                      to={`/expert-profile/${projectExpert.expertId}${redirectUrl}`}
                      className={"font-weight-bold h7 text-truncate text-dark"}
                    >
                      {projectExpert.name ? projectExpert.name : ""}
                    </Link>

                    <div
                      className={[
                        "status-tooltips d-flex",
                        `${projectExpert.status.toLowerCase() ===
                          "no response" && "align-items-start"}`
                      ].join(" ")}
                    >
                      <Badge
                        id={`status-tooltip-${projectExpert.expertId}`}
                        color={setExpertBadge(projectExpert.status)}
                        className="text-white h7 font-weight-bold text-uppercase"
                      >
                        {projectExpert.status}
                      </Badge>
                      {(isSuperAdmin(currentUser && currentUser.roleId) ||
                        isCompanyAdmin(currentUser && currentUser.roleId) ||
                        isCompanyManager(currentUser && currentUser.roleId)) &&
                        projectExpert.status === PROJECT_USER_STATUS_DECLINED &&
                        projectExpert.message && (
                          <div
                            className="cursor-pointer p-0 ml-2 text-link"
                            id="declined-message-tooltip"
                          >
                            <InlineInfoIcon />
                            <SampleProjectTooltip
                              tooltipId={"declined-message-tooltip"}
                              placement={"top"}
                            >
                              <p>
                                {projectExpert.message
                                  ? `Customer: "${projectExpert.message}"`
                                  : ""}
                              </p>
                            </SampleProjectTooltip>
                          </div>
                        )}
                      {(isSuperAdmin(currentUser && currentUser.roleId) ||
                        isCompanyAdmin(currentUser && currentUser.roleId) ||
                        isCompanyManager(currentUser && currentUser.roleId)) &&
                        projectExpert.status.toLowerCase() ===
                          "no response" && (
                          <button
                            className="h7-i btn btn-link p-0 ml-2"
                            onClick={e => {
                              e.preventDefault();
                              setEditInterestStatusModal(
                                projectExpert.expertId
                              );
                            }}
                          >
                            Edit
                          </button>
                        )}
                    </div>

                    {(isSuperAdmin(currentUser && currentUser.roleId) ||
                      isCompanyAdmin(currentUser && currentUser.roleId) ||
                      isCompanyManager(currentUser && currentUser.roleId)) && (
                      <EditInterestStatusModal
                        modal={editInterestStatusModal}
                        setModal={setEditInterestStatusModal}
                        expertId={selectedEditInterestExpertId}
                        updateInterestStatus={updateExpertInterestStatus}
                      />
                    )}
                  </div>
                </div>
              )}
            </div>
          );
        })}
    </>
  );
};

export default SourceMoreWaitingExperts;
