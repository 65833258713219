import React from "react";
import toast from "../../components/base/Toast";

// Components
import Form from "../../components/base/Form";
import Text from "../../components/base/Text";
import SaveButton from "../../components/base/SaveButton";
import CancelButton from "../../components/base/CancelButton";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import { toString } from "../../lib/helper";
import { isBadRequest } from "../../common/http";

export default class AddtagForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      initialValues: {
        name: "",
        type: "",
        projectCategory: ""
      }
    };
  }

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save tag form in the final
    this._tagRequest(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define tag request constants
    const name = values.name;
    const type = values.type;

    if (!name || !type) {
      success = false;
    }

    if (name && name.length > 51) {
      toast.error("Max allowed character 50");
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.name = toString(values.name);
    values.type = toString(values.type);
    return values;
  }

  // tag Request API Call
  _tagRequest(data) {
    return apiClient
      .post(endpoints().tagAPI, data)
      .then(() => {
        window.location.replace("/settings/tags");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    return (
      <div className="card">
        <div className="card-body">
          <Form
            initialValues={this.state.initialValues}
            onSubmit={values => {
              this._submit(values);
            }}
          >
            <div>
              <Text
                name="name"
                label="Tag"
                placeholder="Enter tag"
                required={true}
                error=""
              />
            </div>

            <div>
              <Text
                name="type"
                label="Type"
                placeholder="Enter Type"
                required={true}
                error=""
              />
            </div>

            <div>
              <Text
                name="projectCategory"
                label="Project category"
                placeholder="Enter project category"
              />
            </div>

            <SaveButton />
            <CancelButton onClick={() => this.props.history.goBack()} />
          </Form>
        </div>
      </div>
    );
  }
}
