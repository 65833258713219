import React, { Component } from "react";
import ProjectContext from "./ProjectContext";
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";
import toast from "../../components/base/Toast";
import {
  PROJECT_USER_STATUS_ACCEPTED,
  PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE
} from "../../project/Constants";
import { EXPERT_ROLE } from "../../roles/Constants";
import { isBadRequest } from "../../common/http";

// Helper
import { getUrlPath } from "../../lib/helper";
// Constants
import { PROJECT_TYPE_CAMPAIGN_URL_PATH } from "../../project/Constants";

class ProjectProvider extends Component {
  constructor(props) {
    super(props);

    this.expertApprovalSuccessRef = React.createRef();
  }
  state = {
    projectId: null,
    pdContext: "hello worl2d",
    showProjectReviewFeedback: true,
    hideFeedbackAlert: false,
    hideFeedbackAwaitingAlert: false,
    expertApprovalSuccess: false,
    kickOffModalPreview: false,
    additionInterestedInfoModal: false,
    projectCompletedModal: false,
    generalHoursModal: false,
    approvedProjectExpertList: [],
    isExpertApprovedProjectsLoading: false,
    showMore: false,
    hideReviewMatchExpert: false,
    projectDetails: [],
    projectUserStatus: "",
    projectExtendStatus: "",
    projectExtendDetails: [],
    approvedExpertProjectUserStatus: "",
    projectUserDetails: [],
    tasks: [],
    pendingProjectUsers: [],
    isProjectWaitingForAcceptance: false,
    projectWaitingForAcceptanceExpertList: [],
    currentUserId: "",
    isProjectTasksLoading: false,
    isUpdatedProjectInterestStatus: false,
    isPendingProjectExpertsLoading: false,
    isLoading: false,
    kickoffHappened: false,
    currentTaskPartnerId: "",
    assignTaskPartnerId: []
  };
  /*
   * Start of API call methods
   */

  // Set completed after project task and deliverables completed
  // setCompletedProjectStatus = () => {
  //   const projectId = this.state.projectId;
  //
  //   return apiClient
  //     .put(`${endpoints().projectAPI}/${projectId}`, {
  //       status: PROJECT_STATUS_COMPLETED
  //     })
  //     .then(response => {
  //       this.setState({ isLoading: true });
  //       this.getProjectDetails(projectId);
  //       this._getProjectReviewByProjectId(true, projectId);
  //       this._getExpertReviewByProjectId(true, projectId);
  //     })
  //     .catch(error => {
  //       if (error.response && error.response.status >= 400) {
  //         let errorMessage;
  //         const errorRequest = error.response.request;
  //         if (errorRequest && errorRequest.response) {
  //           errorMessage = JSON.parse(errorRequest.response).message;
  //         }
  //         console.error(errorMessage);
  //         this.setState({ isInvalidRequest: true });
  //       }
  //     });
  // };

  /*
   ** Set
   */

  createProjectTimeEntry = data => {
    return apiClient
      .post(endpoints().projectTimeEntryAPI, data)
      .then(response => {
        if (response && response.data) {
          this.getProjectApprovedExperts(this.state.projectId, true);
        }
        this.toggleGeneralHoursModal();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  /*
   ** Get
   */

  // most important details
  getProjectDetails = (projectId, callback) => {
    const { currentTaskPartnerId } = this.state;
    const pid = projectId ? projectId : this.state.projectId;
    return apiClient
      .get(
        `${endpoints().projectAPI}/${pid}${
          currentTaskPartnerId ? "?partnerId=" + currentTaskPartnerId : ""
        }`
      )
      .then(response => {
        this.setState(
          {
            projectDetails: response.data,
            isLoading: true
          },
          () => {
            if (callback) callback();
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isProjectInvalidRequest: true });
        }
      });
  };

  // Get Project User List
  _getProjectUserDetails = (isProjectUser, projectId) => {
    this.setState({ isProjectExpertLoading: isProjectUser }, () => {
      apiClient
        .get(`${endpoints().projectUserAPI}?projectId=${projectId}`)
        .then(response => {
          const projectUserDetails = response.data.data;
          {
            let approvedExpertProjectUserStatus = "";
            projectUserDetails &&
              projectUserDetails.length > 0 &&
              projectUserDetails.forEach(projectUser => {
                if (
                  projectUser.status === PROJECT_USER_STATUS_ACCEPTED &&
                  projectUser.role === EXPERT_ROLE
                ) {
                  approvedExpertProjectUserStatus = projectUser.status;
                }

                this.setState({
                  projectUserStatus: projectUser.status,
                  projectUserDetails
                });
              });

            if (approvedExpertProjectUserStatus) {
              this.setState({
                approvedExpertProjectUserStatus
              });
            }
          }
        });
    });
  };

  // Check campaign project
  isCampaignProject = () => {
    const currentBasePath = getUrlPath(1);
    const isCampaign = currentBasePath === PROJECT_TYPE_CAMPAIGN_URL_PATH;
    return isCampaign;
  };

  // Get project task Completed Count
  getProjectTaskCompletedCount = async projectId => {
    const isCampaign = this.isCampaignProject();
    let pid = projectId ? projectId : this.state.projectId;
    return await apiClient
      .get(`${endpoints().projectAPI}/task/count/completed/${pid}`)
      .then(response => {
        if (response.data) {
          const { deliverablesCount, taskCompleted } = response.data;
          if (deliverablesCount && taskCompleted === 0) {
            this.setState({
              projectCompletedModal: !this.state.projectCompletedModal
            });
          } else if (
            isCampaign &&
            deliverablesCount === taskCompleted &&
            taskCompleted !== 0
          ) {
            this.setState({
              projectCompletedModal: !this.state.projectCompletedModal
            });
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  getProjectApprovedExperts = (projectId, isExpertApprovedProjectsLoading) => {
    const pid = projectId ? projectId : this.state.projectId;
    apiClient
      .get(`${endpoints().projectUserAPI}/approved/expert/${pid}`)
      .then(response => {
        const approvedProjectExpertList = response.data.data;

        this.setState({
          approvedProjectExpertList: approvedProjectExpertList
            ? approvedProjectExpertList
            : "",
          isExpertApprovedProjectsLoading: !isExpertApprovedProjectsLoading
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get project tasks list by project id
  getProjectTasks = projectId => {
    const { currentTaskPartnerId } = this.state;
    return apiClient
      .get(
        `${endpoints().projectDeliverableAPI}/search?projectId=${projectId}${
          currentTaskPartnerId ? "&partnerId=" + currentTaskPartnerId : ""
        }`
      )
      .then(response => {
        this.setState({
          tasks: response.data.data,
          currentUserId: response.data.currentUserId
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.stringify(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isProjectInvalidRequest: true });
        }
      });
  };

  // Get project extend details by project id
  getProjectExtendDetails = projectId => {
    return apiClient
      .get(`${endpoints().projectExtendAPI}/${projectId}`)
      .then(response => {
        if (response && response.data && response.data.status) {
          this.setState({
            projectExtendDetails: response.data,
            projectExtendStatus: response.data.status
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Update project extend details by project id
  updateProjectExtendStatus = (status, projectId) => {
    let pid = projectId ? projectId : this.state.projectId;
    return apiClient
      .put(`${endpoints().projectExtendAPI}/${pid}`, { status })
      .then(response => {
        this.getProjectDetails(pid);
        this.getProjectExtendDetails(pid);
        toast.success(response.data.message);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Save the additional interested info updated by expert
  sendAdditionalInterestedInfoMessage = value => {
    apiClient
      .post(`${endpoints().projectUserAPI}/sendInterestedExpertSpecification`, {
        message: value.interested_message,
        projectId: this.state.projectId
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Handle New Estimated Budget
  handleNewEstimatedBudget = (status, projectId) => {
    const { newEstimatedBudget } = this.state.projectDetails;
    let data = {};
    if (status === "Approve") {
      data.estimatedBudget = newEstimatedBudget;
      data.budgetStatus = "Approve";
      data.newEstimatedBudget = "";
    } else {
      data.newEstimatedBudget = "";
      data.budgetStatus = "Decline";
    }

    const pid = projectId ? projectId : this.state.projectId;

    return apiClient
      .put(`${endpoints().projectAPI}/${pid}`, data)
      .then(() => {
        this.getProjectDetails(pid);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  updateProjectStatus = (status, projectId) => {
    let pid = projectId ? projectId : this.state.projectId;
    return apiClient
      .post(
        `${
          endpoints().projectUserAPI
        }/updateStatus?projectId=${pid}&status=${status}`
      )
      .then(response => {
        this.getProjectApprovedExperts(pid, true);
        this._getProjectUserDetails(true, pid);

        if (status === "Interested") {
          // In showed "Interest" in the Project Show Additional Interest Popup
          this.setAdditionInterestedInfoModal();
        }
        this.getProjectDetails(pid);
        if (status === PROJECT_USER_STATUS_ACCEPTED) {
          this.getProjectApprovedExperts(pid, true);
          this.setPreviewKickOffModal();
        }
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;

          toast.success(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Get pending project user list
  _getProjectPendingExperts = (isPendingProjectExpertsLoading, projectId) => {
    const pid = projectId ? projectId : this.state.projectId;
    apiClient
      .get(`${endpoints().projectUserAPI}/pending/expert/${pid}`)
      .then(response => {
        const projectPendingUserList = response.data.data;

        this.setState({
          pendingProjectUsers: projectPendingUserList
            ? projectPendingUserList
            : "",
          isPendingProjectExpertsLoading: !isPendingProjectExpertsLoading
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get Project User Waiting For Acceptance List
  _getExpertsWaitingForAcceptanceList = (
    isProjectWaitingForAcceptance,
    projectId
  ) => {
    const sourceMoreExpertsWaitingForAcceptanceStatuses = [
      PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE
    ];
    this.setState(
      { isProjectWaitingForAcceptance: isProjectWaitingForAcceptance },
      () => {
        apiClient
          .get(
            `${
              endpoints().projectUserAPI
            }?projectId=${projectId}&status=${sourceMoreExpertsWaitingForAcceptanceStatuses}`
          )
          .then(response => {
            const data = response.data;
            const projectWaitingForAcceptanceExpertList = data.data;

            this.setState({
              isProjectWaitingForAcceptance: !isProjectWaitingForAcceptance,
              projectWaitingForAcceptanceExpertList: projectWaitingForAcceptanceExpertList
                ? projectWaitingForAcceptanceExpertList
                : []
            });
          });
      }
    );
  };

  /**
   *  Approve match expert by expert id
   */
  approveMatchedExpert = (id, projectId) => {
    let pid = projectId ? projectId : this.state.projectId;

    apiClient
      .post(
        `${endpoints().projectUserAPI}/approve/expert/${id}?project_id=${pid}`
      )
      .then(() => {
        this._getProjectPendingExperts(true, pid);
        this._getExpertsWaitingForAcceptanceList(true, pid);
        this.getProjectApprovedExperts(pid, true);
        this.getProjectDetails(pid);
        this.showExpertApprovalSuccess(() => {
          this.expertApprovalSuccessRef.current.scrollIntoView({
            behavior: "smooth",
            block: "start"
          });
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  /**
   * Decline match expert by expert id
   */
  declineMatchedExpert = (id, projectId, message) => {
    let pid = projectId ? projectId : this.state.projectId;
    apiClient
      .post(
        `${endpoints().projectUserAPI}/decline/expert/${id}?project_id=${pid}`,
        { message: message }
      )
      .then(() => {
        this._getProjectPendingExperts(true, pid);
        this._getExpertsWaitingForAcceptanceList(true, pid);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  updateKickoffTimes = (status, projectId, expertId, cb) => {
    let data = {
      status: status
    };

    return apiClient
      .patch(`/v1/project/${projectId}/kickoff/${expertId}/select`, data)
      .then(_ => {
        cb && cb();
      });
  };

  /*
   * Start of PURE functions methods
   */

  // Set project ID
  setProjectId = id => {
    this.setState({
      projectId: id
    });
  };

  // Set current task partner ID
  setCurrentTaskPartnerId = id => {
    if (this.state.currentTaskPartnerId !== id)
      this.setState(
        {
          currentTaskPartnerId: id
        },
        () => {
          if (id) {
            this.getProjectDetails(this.state.projectId, () => {
              this.getProjectTasks(this.state.projectId);
            });
          }
        }
      );
  };

  // Set Assignt task partner ID
  setAssignTaskPartnerId = id => {
    if (this.state.assignTaskPartnerId !== id)
      this.setState({
        assignTaskPartnerId: id
      });
  };

  // set status
  setExpertInterestedStatus = cb => {
    this.setState({ isUpdatedProjectInterestStatus: true }, cb);
  };

  // Hide Review Match Expert Notification
  _hideReviewMatchExpert = () => {
    this.setState({ hideReviewMatchExpert: true });
  };

  // toggle show more content
  showMoreHandler = () => {
    this.setState({ showMore: !this.state.showMore });
  };

  // Toggle general hours modal
  toggleGeneralHoursModal = () => {
    this.setState({
      generalHoursModal: !this.state.generalHoursModal
    });
  };

  // Set project completed modal
  setProjectCompletedModal = () => {
    this.setState({
      projectCompletedModal: !this.state.projectCompletedModal
    });
  };

  // change Campaign status
  changeCampaignStatus = () => {
    this.setState({
      projectCompletedModal: true ? false : true
    });
  };

  // Show the additional interested info modal
  setAdditionInterestedInfoModal = () => {
    this.setState({
      additionInterestedInfoModal: !this.state.additionInterestedInfoModal
    });
  };

  // Show Preview of Kickoff Modal
  setPreviewKickOffModal = () => {
    this.setState({
      kickOffModalPreview: !this.state.kickOffModalPreview
    });
  };

  // Show Expert Approval Success
  showExpertApprovalSuccess = callback => {
    this.setState(
      {
        expertApprovalSuccess: true
      },
      callback
    );
  };

  // Hide Expert Approval Success
  hideExpertApprovalSuccess = () => {
    this.setState({
      expertApprovalSuccess: false
    });
  };

  // Hide awaiting feedback alert
  hideFeedbackAwaitingAlert = () => {
    this.setState({
      hideFeedbackAwaitingAlert: true
    });
  };

  hideFeedbackAlert = () => {
    this.setState({ hideFeedbackAlert: true });
  };

  // Show playbook feedback review alert
  showFeedback = () => {
    this.setState({
      showProjectReviewFeedback: true
    });
  };

  // Hide playbook feedback review alert
  hideFeedback = () => {
    this.setState({
      showProjectReviewFeedback: false
    });
  };

  //setTasks
  setTasks = tasks => {
    this.setState(tasks);
  };

  // set kickoff happened
  setKickoffHappened = () => {
    // only setting it to true, so it illustrates that it can't be marked as not happened.
    this.setState({ kickoffHappened: true });
  };

  render() {
    return (
      <ProjectContext.Provider
        value={{
          ...this.state,
          sendAdditionalInterestedInfoMessage: this
            .sendAdditionalInterestedInfoMessage,
          getProjectDetails: this.getProjectDetails,
          getProjectTaskCompletedCount: this.getProjectTaskCompletedCount,
          getProjectApprovedExperts: this.getProjectApprovedExperts,
          _getProjectUserDetails: this._getProjectUserDetails,
          getProjectTasks: this.getProjectTasks,
          createProjectTimeEntry: this.createProjectTimeEntry,
          handleNewEstimatedBudget: this.handleNewEstimatedBudget,
          updateProjectStatus: this.updateProjectStatus,
          _getProjectPendingExperts: this._getProjectPendingExperts,
          _getExpertsWaitingForAcceptanceList: this
            ._getExpertsWaitingForAcceptanceList,
          declineMatchedExpert: this.declineMatchedExpert,
          approveMatchedExpert: this.approveMatchedExpert,
          updateKickoffTimes: this.updateKickoffTimes,
          // pure functions below
          setProjectId: this.setProjectId,
          setCurrentTaskPartnerId: this.setCurrentTaskPartnerId,
          hideFeedback: this.hideFeedback,
          showFeedback: this.showFeedback,
          setHideFeedbackAlert: this.hideFeedbackAlert,
          setHideFeedbackAwaitingAlert: this.hideFeedbackAwaitingAlert,
          hideExpertApprovalSuccess: this.hideExpertApprovalSuccess,
          showExpertApprovalSuccess: this.showExpertApprovalSuccess,
          setPreviewKickOffModal: this.setPreviewKickOffModal,
          setAdditionInterestedInfoModal: this.setAdditionInterestedInfoModal,
          setProjectCompletedModal: this.setProjectCompletedModal,
          toggleGeneralHoursModal: this.toggleGeneralHoursModal,
          showMoreHandler: this.showMoreHandler,
          _hideReviewMatchExpert: this._hideReviewMatchExpert,
          setExpertInterestedStatus: this.setExpertInterestedStatus,
          setTasks: this.setTasks,
          setKickoffHappened: this.setKickoffHappened,
          getProjectExtendDetails: this.getProjectExtendDetails,
          updateProjectExtendStatus: this.updateProjectExtendStatus,
          changeCampaignStatus: this.changeCampaignStatus,
          setAssignTaskPartnerId: this.setAssignTaskPartnerId,
          // refs
          expertApprovalSuccessRef: this.expertApprovalSuccessRef
        }}
      >
        {this.props.children}
      </ProjectContext.Provider>
    );
  }
}

export default ProjectProvider;
