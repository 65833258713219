import React from "react";
import Avatar from "../../components/base/Avatar";
import ReactCrop from "react-image-crop";
import { apiClient } from "../../apiClient";
import { DEFAULT_API_KEY } from "../../configs";
import { CrossIcon, EditIconAlt } from "../../assets/img/icons";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchUserDetail } from "../../actions/user";
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import { isBadRequest } from "../../common/http";
import { toast } from "react-toastify";

class UploadAvatar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      croppedImageUrl: "",
      fileSize: "",
      closePreviewImage: false,
      src: null,
      file: "",
      crop: {
        unit: "%",
        width: 80,
        height: 80
      },
      zoom: 1,
      isImageUploaded: false,
      avatarWidth: "",
      avatarHeight: "",
      avatarUrl: ""
    };
  }

  // User profile upload
  _userProfileUpdate = details => {
    const data = new FormData();
    data.append("file", details.file);
    data.append("email", details.email);
    data.append("userId", this.props.userId);

    if (this.props.publicRoute) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    return apiClient
      .put(this.props.apiURL, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          (isSuperAdmin(this.props.user.roleId) ||
            isCompanyAdmin(this.props.user.roleId) ||
            isCompanyManager(this.props.user.roleId)) &&
            this.props.actions.fetchUserDetail();
          toast.success(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Handle image upload
  handleImageUpload = () => {
    const { getFile, apiURL, data, setValue } = this.props;
    this.setState({ closePreviewImage: false, isImageUploaded: true });
    apiURL &&
      this._userProfileUpdate({
        file: this.state.file,
        email: data.email
      });

    setValue && setValue("avatar", this.state.file);
    getFile && getFile(this.state.file);
  };

  // Select the uploaded image
  onSelectFile = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fSize = targetFile.files.item(0).size;
      const fileSize = Math.round(fSize / 1024);
      this.setState({
        fileSize,
        isImageUploaded: false
      });

      if (fileSize < 10240) {
        const reader = new FileReader();
        reader.addEventListener("load", () =>
          this.setState({ src: reader.result, closePreviewImage: true })
        );
        reader.readAsDataURL(e.target.files[0]);
      }
    }
  };

  // Load the initial crop screen
  onImageLoaded = image => {
    this.imageRef = image;
  };

  // Get crop value from the preview image
  onCropComplete = crop => {
    this.makeClientCrop(crop);
  };

  // Move the crop the crop selector
  onCropChange = (crop, percentCrop) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    this.setState({ crop });
  };

  // Make the crop the image in preview view
  async makeClientCrop(crop) {
    if (this.imageRef && crop.width && crop.height) {
      const croppedImageUrl = await this.getCroppedImg(
        this.imageRef,
        crop,
        "newFile.jpeg"
      );
      this.setState({ croppedImageUrl });
    }
  }

  // Get Cropped image size
  getCroppedImg(image, crop, fileName) {
    const canvas = document.createElement("canvas");
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    canvas.width = crop.width;
    canvas.height = crop.height;
    const ctx = canvas.getContext("2d");

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width,
      crop.height
    );

    // Convert URL to base64 data
    const base64Image = canvas.toDataURL("image/jpeg");

    if (base64Image) {
      this.setState({
        file: base64Image,
        email: this.props.data && this.props.data.email
      });
    }

    return new Promise((resolve, reject) => {
      canvas.toBlob(blob => {
        if (!blob) {
          //reject(new Error('Canvas is empty'));
          console.error("Canvas is empty");
          return;
        }
        blob.name = fileName;
        window.URL.revokeObjectURL(this.fileUrl);
        this.fileUrl = window.URL.createObjectURL(blob);
        resolve(this.fileUrl);
      }, "image/jpeg");

      canvas.toBlob(file => {
        var ImageURL = this.state.src;
        var block = ImageURL.split(";");

        file.name = fileName;
        resolve(file);
      }, "image/jpeg");
    });
  }

  // get profile image width and height
  componentDidMount() {
    if (this.props.data && this.props.data.avatarUrl) {
      this.getMeta(this.props.data.avatarUrl, (width, height) => {
        this.setState({ avatarWidth: width, avatarHeight: height });
      });
    }
  }

  // get image meta data
  getMeta(url, callback) {
    var img = new Image();
    img.src = url;
    img.onload = function() {
      callback(this.width, this.height);
    };
  }

  render() {
    const {
      id,
      data,
      hideUploadButton,
      showInitials,
      placeholderUrl,
      publicRoute,
      allowEdit,
      width,
      height,
      lineHeight,
      className,
      size
    } = this.props;
    const {
      croppedImageUrl,
      fileSize,
      closePreviewImage,
      src,
      crop,
      isImageUploaded,
      avatarHeight,
      avatarWidth
    } = this.state;
    let backgroundSize, objectFit;

    if (id == "partner") {
      if (avatarWidth > 200 && avatarHeight > 170) {
        backgroundSize = "cover";
      } else if ((avatarWidth > 0 && avatarWidth < 200) || avatarHeight < 170) {
        backgroundSize = "contain";
      } else {
        backgroundSize = "contain";
      }

      if (crop.width > 200 && crop.height > 170) {
        objectFit = "cover";
      } else if (crop.width < 200 || crop.height < 170) {
        objectFit = "contain";
      }
    }

    return (
      <>
        <div className="upload-image align-items-center justify-content-start mb-4 text-center">
          <label
            className={`position-relative profilePicPreview mb-0 mr-4 flex-shrink-0 mx-auto
              ${this.props.square && "d-block"}`}
            htmlFor="profilePicture"
          >
            {isImageUploaded &&
            croppedImageUrl &&
            croppedImageUrl.length > 0 ? (
              <>
                <div
                  style={{
                    position: "relative",
                    width: width ? width : "140px",
                    height: height ? height : "140px",
                    backgroundSize: backgroundSize ? backgroundSize : "cover"
                  }}
                >
                  <img
                    alt="profile"
                    src={croppedImageUrl}
                    className={`profile-picture-preview ${
                      className ? className : "rounded-circle"
                    } d-block`}
                    style={{
                      width: width ? width : "140px",
                      height: height ? height : "140px",
                      backgroundSize: backgroundSize ? backgroundSize : "",
                      objectFit: objectFit ? objectFit : "cover"
                    }}
                  />
                  <div>
                    {allowEdit == true && (
                      <span
                        style={{
                          position: "absolute",
                          right: "0",
                          bottom: "0",
                          padding: "10px",
                          borderRadius: "8px",
                          color: "#ffffff"
                        }}
                        className="cursor-pointer"
                      >
                        <EditIconAlt style={{ verticalAlign: "bottom" }} />
                      </span>
                    )}
                  </div>
                </div>
              </>
            ) : (
              <Avatar
                editProfile={true}
                companyName={(data && data.companyName) || ""}
                url={
                  (data && data.avatarUrl) ||
                  (placeholderUrl && placeholderUrl) ||
                  ""
                }
                customSize={this.props.customSize}
                fontSize={32}
                className="profile-picture-preview rounded-circle d-block avatar-placeholder"
                square={this.props.square}
                editicon={this.props.editicon}
                allowEdit={allowEdit}
                contain={backgroundSize}
                lineHeight={lineHeight}
                width={width}
                size={size}
              />
            )}
          </label>
          <div
            className={[`${hideUploadButton && "d-none"}`, "upload-field"].join(
              " "
            )}
          >
            <input
              name="file"
              className="form-control d-none"
              type="file"
              id="profilePicture"
              placeholder="Profile Photo"
              onChange={event => {
                this.onSelectFile(event);
                event.target.value = "";
              }}
              accept="image/*"
            />
            <small
              className={`text-danger ${
                fileSize > 10240 ? "d-block" : "d-none"
              }`}
            >
              Please select a file less than 10mb
            </small>
            <span className="profilePicOverlay d-block mt-2 mb-3">
              <label htmlFor="profilePicture" className="profile-img-sm mb-0">
                <span className="btn btn-secondary cursor-pointer">
                  Upload New Photo
                </span>
              </label>
            </span>
            <span className="info h7 d-block">
              Images should be at least 530x530px and not exceed 10MB.
            </span>
          </div>
        </div>
        {closePreviewImage && src && (
          <div className="crop-modal" style={{ zIndex: "12" }}>
            <div className="backdrop" />
            <div className="crop-modal-body">
              <div
                className="crop-modal-close"
                onClick={() => this.setState({ closePreviewImage: false })}
              >
                <CrossIcon />
              </div>
              <h5 className="mb-3">
                <b>Crop image and upload</b>
              </h5>
              <ReactCrop
                src={src}
                crop={crop}
                ruleOfThirds
                onImageLoaded={this.onImageLoaded}
                onComplete={this.onCropComplete}
                onChange={this.onCropChange}
              />

              <div className="mt-4 d-flex justify-content-center">
                <button
                  className="btn btn-secondary"
                  onClick={this.handleImageUpload}
                >
                  Upload Profile Photo
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  return state;
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchUserDetail }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UploadAvatar);
