import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import Form from "../../../../../components/base/Form";
import CancelButton from "../../../../../components/base/CancelButton";
import Button from "../../../../../components/base/Button";
import Text from "../../../../../components/base/Text";

const AddFormModal = props => {
  const { isOpen, toggle, addNewForm } = props;

  const closeAddTaskModal = () => {
    toggle();
  };

  const _submit = values => {
    const data = new FormData();
    data.append("name", values.name || "");
    data.append("description", values.description || "");

    addNewForm(data);
    // closeAddTaskModal();
  };

  const initialValues = {
    name: "",
    description: ""
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeAddTaskModal}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          _submit(values);
        }}
      >
        <ModalHeader toggle={closeAddTaskModal}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Add Application Form
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div>
              <Text
                id={`name`}
                name={`name`}
                label="Name"
                placeholder="Name"
                required
                error=""
              />
            </div>
            <div>
              <Text
                id={`description`}
                name={`description`}
                label="Description"
                placeholder="Description"
                error=""
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={closeAddTaskModal} />
            <Button
              label="Add New Form"
              type="submit"
              className="btn btn-primary"
            />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddFormModal;
