import React from "react";
import toast from "../../components/base/Toast";

// Components
import Form from "../../components/base/Form";
import TextArea from "../../components/base/TextArea";
import SaveButton from "../../components/base/SaveButton";
import CancelButton from "../../components/base/CancelButton";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";

export default class AddUserForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      initialValues: {
        description: ""
      },
      projectId: "",
      isLoading: false,
      count: "",
      data: []
    };
  }

  _submit = values => {
    if (!this._validateFields(values)) {
      return;
    }

    // Add customer user form in the final..
    this._createDiscussion(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const description = values.description;

    if (!description) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    const data = {
      description: values.description,
      projectId: this.props.projectId
    };

    return data;
  }

  // Customer user API Call
  _createDiscussion(data) {
    return apiClient
      .post(endpoints().discussionAPI, data)
      .then(() => {
        this.props.toggle();
        window.location.reload();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    return (
      <Form
        initialValues={this.state.initialValues}
        onSubmit={values => {
          this._submit(values);
        }}
      >
        <div className="ml-3 mr-3 mt-2 mb-3">
          <div>
            <TextArea
              name="description"
              label="Description"
              placeholder="Description"
              rows="5"
              error="Description is required"
              required
            />
          </div>
          <CancelButton onClick={this.props.toggle} />
          <SaveButton />
        </div>
      </Form>
    );
  }
}
