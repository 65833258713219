import React from "react";
import { Link } from "react-router-dom";

// Constants
import {
  INVOICE_STATUS_PENDING,
  INVOICE_STATUS_APPROVED,
  INVOICE_STATUS_PARTIALLY_PAID
} from "../../../invoice/Constants";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

const PaymentPendingSection = ({
  invoiceDetails,
  toggleMarkPaymentMadeModal,
  show,
  payCommissionAmount,
  approveInvoiceBill,
  enableSchedulePayment,
  togglePayInvoice,
  isBillDotComConnected,
  updateStatus
}) => {
  return (
    <div>
      <div
        className="card border-0 text-white mb-4"
        style={{ backgroundColor: "#2F2F3E" }}
      >
        <div className="project-details-body">
          <div className="details-body-main">
            <div className="card-body pt-0">
              <div className="certification mb-2">
                <span
                  className="badge badge-pill badge-primary"
                  style={{ backgroundColor: "#0AC297", marginLeft: "122px" }}
                >
                  {invoiceDetails.paymentStatus === INVOICE_STATUS_PENDING &&
                    "READY FOR PAYOUT"}
                  {invoiceDetails.paymentStatus === INVOICE_STATUS_APPROVED &&
                    "APPROVED"}
                  {invoiceDetails.paymentStatus ===
                    INVOICE_STATUS_PARTIALLY_PAID && "PARTIALLY PAID"}
                </span>
              </div>
              <h4 className="font-weight-bold">Attention!</h4>
              <p className="font-weight-bold">
                The {Terminology.get(SystemName.REFERRAL)} commission payment to
                partner is {invoiceDetails.paymentStatus}
              </p>
              {invoiceDetails.paymentStatus === INVOICE_STATUS_PENDING && (
                <>
                  {enableSchedulePayment ? (
                    <div className="btn-wrapper">
                      <button
                        className="btn btn-primary px-3 mr-2 text-white"
                        onClick={() => {
                          payCommissionAmount();
                        }}
                      >
                        Make Payment
                      </button>
                    </div>
                  ) : (
                    <button
                      className="btn btn-primary px-3 mr-2 text-white"
                      onClick={() => {
                        isBillDotComConnected
                          ? approveInvoiceBill()
                          : updateStatus();
                      }}
                    >
                      {isBillDotComConnected ? "Approve Bill" : "Appprove"}
                    </button>
                  )}
                </>
              )}

              {(invoiceDetails.paymentStatus === INVOICE_STATUS_APPROVED ||
                invoiceDetails.paymentStatus ===
                  INVOICE_STATUS_PARTIALLY_PAID) && (
                <>
                  <div className="btn-wrapper">
                    <div className="btn-group text-right">
                      <button
                        type="button"
                        className="btn btn-primary dropdown-toggle"
                        onClick={togglePayInvoice}
                      >
                        <span className="px-5">Pay Invoice</span>
                      </button>
                      <span
                        className={`dropdown-menu ${show}`}
                        style={{ width: "auto", fontSize: "12px" }}
                      >
                        <a
                          className="dropdown-item"
                          onClick={() => {
                            toggleMarkPaymentMadeModal();
                            togglePayInvoice();
                          }}
                        >
                          Mark Payment as Made
                        </a>
                      </span>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>

          <div className="details-body-sidebar">
            <div className={["sidebar-item"].join(" ")}>
              <p className="font-weight-bold mb-0">Amount:</p>
              <h5 className="font-weight-bold mt-0">
                ${invoiceDetails.amount ? invoiceDetails.amount : 0}
              </h5>
              <p className="font-weight-bold mt-3 mb-0">Due Date:</p>
              <h5 className="font-weight-bold mt-0">
                {invoiceDetails.dueAt ? invoiceDetails.dueAt : ""}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentPendingSection;
