import React from "react";
import { COMPANY_MANAGER_ROLE } from "../../../roles/Constants";
import SimpleExpertCard from "../../../components/SimpleExpertCard";
import {
  EditIconAlt,
  MessageCircleIcon,
  InlineInfoIcon,
  GlobeIcon
} from "../../../assets/img/icons";
import {
  isExpert,
  isSuperAdmin,
  isCompanyAdmin,
  shortTimeZone,
  isCompanyManager
} from "../../../lib/helper";
import SampleProjectTooltip from "./SampleProjectTooltip";

import { useSelector } from "react-redux";

const ProjectTeam = React.memo(function ProjectTeam(props) {
  const {
    toggle,
    data,
    noDataFoundMessage,
    toggleAccountMessage,
    currentUserId,
    toggleChangeAccountManager
  } = props;

  const currentUser = useSelector(state => state.user);

  return (
    <div className="supporting-materials">
      {data && data.length > 0 ? (
        data.map((user, index) => (
          <div>
            <div className={"supporting-material"} key={index}>
              <SimpleExpertCard
                data={user}
                key={user.id}
                currentUserId={currentUserId}
              />
              {/* Modal toggle */}
              {toggle &&
                (isSuperAdmin(currentUser && currentUser.roleId) ||
                  isCompanyAdmin(currentUser && currentUser.roleId) ||
                  isCompanyManager(currentUser && currentUser.roleId)) && (
                  <div className="action">
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        if (user.role === COMPANY_MANAGER_ROLE) {
                          toggleChangeAccountManager(user.id);
                        } else {
                          toggle(user.id, user.firstName, user.lastName);
                        }
                      }}
                    >
                      <EditIconAlt />
                    </a>
                  </div>
                )}
              {isExpert(currentUser && currentUser.roleId) &&
                toggleAccountMessage && (
                  <a
                    href="#"
                    onClick={e => {
                      e.preventDefault();
                      toggleAccountMessage();
                    }}
                  >
                    <MessageCircleIcon />
                  </a>
                )}
            </div>
            {user && user.timezone ? (
              <div className="field-wrapper py-2">
                <div className="d-flex align-items-center">
                  <div className="field-icon">
                    <GlobeIcon />
                  </div>
                  <p className="font-weight-bold d-flex justify-content-between">
                    <span className="font-weight-bold my-auto">
                      {shortTimeZone(user.timezone)}
                    </span>
                    <span>
                      <div className="customer-timezone" id="customer-timezone">
                        <InlineInfoIcon />
                        <SampleProjectTooltip
                          tooltipId={"customer-timezone"}
                          placement={"top"}
                        >
                          <span>{user.timezone}</span>
                        </SampleProjectTooltip>
                      </div>
                    </span>
                  </p>
                </div>
              </div>
            ) : (
              ""
            )}
          </div>
        ))
      ) : (
        <span className="text-grayed">{noDataFoundMessage || ""}</span>
      )}
    </div>
  );
});

export default ProjectTeam;
