import React from "react";

const KickOffMethodOfCommunication = React.memo(
  function KickOffMethodOfCommunication({
    waitingForExpertSelection,
    selectedCommunicationMethod
  }) {
    return (
      waitingForExpertSelection && (
        <div className={"kickoff-scheduler__communication-method"}>
          <p className="font-weight-bold">Preferred Method of Communication:</p>
          <span className={["ml-2", "font-weight-normal"].join(" ")}>
            {selectedCommunicationMethod}
          </span>
        </div>
      )
    );
  }
);

export default KickOffMethodOfCommunication;
