export const ONE_PROJECT_EXPERIENCE =
  "Worked on at least 1 project before with Torchlite";
export const MORE_THAN_ONE_PROJECT_EXPERIENCE =
  "Worked on multiple Projects before with Torchlite";
export const NO_EXPERIENCE = "Not worked on a project before with Torchlite";
export const PROJECT_EXPERIENCE_PLACEHOLDER =
  "level of experience with Torchlite";

export const ONE_PROJECT_EXPERIENCE_IDENTIFIER = 1;
export const MORE_THAN_ONE_PROJECT_EXPERIENCE_IDENTIFIER = 2;
export const NO_EXPERIENCE_IDENTIFIER = 3;

/**
 * Get Expert Level Of Experience Option
 */
export function getExpertLevelOfExperienceOption() {
  return [
    {
      label: PROJECT_EXPERIENCE_PLACEHOLDER,
      value: PROJECT_EXPERIENCE_PLACEHOLDER,
      selected: false,
      placeholder: true
    },
    {
      label: ONE_PROJECT_EXPERIENCE,
      value: ONE_PROJECT_EXPERIENCE,
      selected: false
    },
    {
      label: MORE_THAN_ONE_PROJECT_EXPERIENCE,
      value: MORE_THAN_ONE_PROJECT_EXPERIENCE,
      selected: false
    },
    {
      label: NO_EXPERIENCE,
      value: NO_EXPERIENCE,
      selected: false
    }
  ];
}
