import React from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import Text from "../../../../components/base/Text";
import SaveButton from "../../../../components/base/SaveButton";

// Constants
import {
  MEDIA_BASE_URL,
  SETTINGS_SENDGRID_API_KEY,
  SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY,
  SETTINGS_LINKEDIN_URL,
  SETTINGS_LINKEDIN_CLIENT_ID,
  SETTINGS_LINKEDIN_CLIENT_SECRET,
  SETTINGS_LINKEDIN_REDIRECT_URI,
  SETTINGS_GOOGLE_CLIENT_ID,
  SETTINGS_SALESFORCE_LOGIN_CLIENT_ID,
  SETTINGS_SALESFORCE_LOGIN_REDIRECT_URL,
  SETTINGS_OFFICE365_CLIENT_ID,
  SETTINGS_OFFICE365_REDIRECT_URI,
  SETTINGS_AWS_KEY_ID,
  SETTINGS_AWS_SECRET_KEY_ACCESS,
  SETTINGS_AWS_BUCKET,
  SETTINGS_STRIPE_ACCOUNT_ID,
  SETTINGS_DEFAULT_TIMEZONE,
  SETTINGS_GOOGLE_PLACES_API_KEY,
  SETTING_SLACK_APP_URL
} from "../../../../setting/Constants";

const GeneralSection = props => {
  const { adminSettings, saveSettings } = props;

  // System Settings General Section Initial Values
  const systemSettingsGeneralSectionInitialValues = {
    [SETTINGS_SENDGRID_API_KEY]: adminSettings[SETTINGS_SENDGRID_API_KEY],
    [SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY]:
      adminSettings[SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY],
    [SETTINGS_GOOGLE_CLIENT_ID]: adminSettings[SETTINGS_GOOGLE_CLIENT_ID],
    [SETTINGS_GOOGLE_PLACES_API_KEY]:
      adminSettings[SETTINGS_GOOGLE_PLACES_API_KEY],
    [SETTINGS_LINKEDIN_URL]: adminSettings[SETTINGS_LINKEDIN_URL],
    [SETTINGS_LINKEDIN_CLIENT_ID]: adminSettings[SETTINGS_LINKEDIN_CLIENT_ID],
    [SETTINGS_LINKEDIN_CLIENT_SECRET]:
      adminSettings[SETTINGS_LINKEDIN_CLIENT_SECRET],
    [SETTINGS_LINKEDIN_REDIRECT_URI]:
      adminSettings[SETTINGS_LINKEDIN_REDIRECT_URI],
    [SETTINGS_SALESFORCE_LOGIN_CLIENT_ID]:
      adminSettings[SETTINGS_SALESFORCE_LOGIN_CLIENT_ID],
    [SETTINGS_SALESFORCE_LOGIN_REDIRECT_URL]:
      adminSettings[SETTINGS_SALESFORCE_LOGIN_REDIRECT_URL],
    [SETTINGS_OFFICE365_CLIENT_ID]: adminSettings[SETTINGS_OFFICE365_CLIENT_ID],
    [SETTINGS_OFFICE365_REDIRECT_URI]:
      adminSettings[SETTINGS_OFFICE365_REDIRECT_URI],
    [MEDIA_BASE_URL]: adminSettings[MEDIA_BASE_URL],
    [SETTINGS_AWS_KEY_ID]: adminSettings[SETTINGS_AWS_KEY_ID],
    [SETTINGS_AWS_SECRET_KEY_ACCESS]:
      adminSettings[SETTINGS_AWS_SECRET_KEY_ACCESS],
    [SETTINGS_AWS_BUCKET]: adminSettings[SETTINGS_AWS_BUCKET],
    [SETTINGS_STRIPE_ACCOUNT_ID]: adminSettings[SETTINGS_STRIPE_ACCOUNT_ID],
    [SETTINGS_DEFAULT_TIMEZONE]: adminSettings[SETTINGS_DEFAULT_TIMEZONE],
    [SETTING_SLACK_APP_URL]: adminSettings[SETTING_SLACK_APP_URL]
  };

  // System Settings General Section Submit Form
  const submit = values => {
    const data = new FormData();
    if (values[SETTINGS_DEFAULT_TIMEZONE] !== undefined) {
      data.append(
        SETTINGS_DEFAULT_TIMEZONE,
        values[SETTINGS_DEFAULT_TIMEZONE]
          ? values[SETTINGS_DEFAULT_TIMEZONE]
          : ""
      );
    }

    // AWS

    // Media Portal Url
    if (values[MEDIA_BASE_URL] !== undefined) {
      data.append(
        MEDIA_BASE_URL,
        values[MEDIA_BASE_URL] ? values[MEDIA_BASE_URL] : ""
      );
    }
    if (values[SETTINGS_AWS_KEY_ID] !== undefined) {
      data.append(
        SETTINGS_AWS_KEY_ID,
        values[SETTINGS_AWS_KEY_ID] ? values[SETTINGS_AWS_KEY_ID] : ""
      );
    }
    if (values[SETTINGS_AWS_SECRET_KEY_ACCESS] !== undefined) {
      data.append(
        SETTINGS_AWS_SECRET_KEY_ACCESS,
        values[SETTINGS_AWS_SECRET_KEY_ACCESS]
          ? values[SETTINGS_AWS_SECRET_KEY_ACCESS]
          : ""
      );
    }
    if (values[SETTINGS_AWS_BUCKET] !== undefined) {
      data.append(
        SETTINGS_AWS_BUCKET,
        values[SETTINGS_AWS_BUCKET] ? values[SETTINGS_AWS_BUCKET] : ""
      );
    }

    // Sendgrid API Key
    if (values[SETTINGS_SENDGRID_API_KEY] !== undefined) {
      data.append(
        SETTINGS_SENDGRID_API_KEY,
        values[SETTINGS_SENDGRID_API_KEY]
          ? values[SETTINGS_SENDGRID_API_KEY]
          : ""
      );
    }

    // Google reCaptcha site key
    if (values[SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY] !== undefined) {
      data.append(
        SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY,
        values[SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY]
          ? values[SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY]
          : ""
      );
    }

    // Google client id
    if (values[SETTINGS_GOOGLE_CLIENT_ID] !== undefined) {
      data.append(
        SETTINGS_GOOGLE_CLIENT_ID,
        values[SETTINGS_GOOGLE_CLIENT_ID]
          ? values[SETTINGS_GOOGLE_CLIENT_ID]
          : ""
      );
    }
    // Google places api key
    if (values.google_places_api_key !== undefined) {
      data.append(
        "google_places_api_key",
        values.google_places_api_key ? values.google_places_api_key : ""
      );
    }

    // Office 365 client id
    if (values.office365_client_id !== undefined) {
      data.append(
        "office365_client_id",
        values.office365_client_id ? values.office365_client_id : ""
      );
    }

    // Office 365 Redirect Url
    if (values.office365_redirect_uri !== undefined) {
      data.append(
        "office365_redirect_uri",
        values.office365_redirect_uri ? values.office365_redirect_uri : ""
      );
    }

    // Salesforce client id
    if (values.salesforce_login_client_id !== undefined) {
      data.append(
        "salesforce_login_client_id",
        values.salesforce_login_client_id
          ? values.salesforce_login_client_id
          : ""
      );
    }

    // Salesforce Redirect Url
    if (values.salesforce_login_redirect_url !== undefined) {
      data.append(
        "salesforce_login_redirect_url",
        values.salesforce_login_redirect_url
          ? values.salesforce_login_redirect_url
          : ""
      );
    }

    // LinkedIn URL
    if (values.linkedin_url !== undefined) {
      data.append(
        "linkedin_url",
        values.linkedin_url ? values.linkedin_url : ""
      );
    }

    // LinkedIn Clint ID
    if (values.linkedin_client_id !== undefined) {
      data.append(
        "linkedin_client_id",
        values.linkedin_client_id ? values.linkedin_client_id : ""
      );
    }

    // LinkedIn client secret
    if (values.linkedin_client_secret !== undefined) {
      data.append(
        "linkedin_client_secret",
        values.linkedin_client_secret ? values.linkedin_client_secret : ""
      );
    }

    if (values.linkedin_redirect_uri !== undefined) {
      data.append(
        "linkedin_redirect_uri",
        values.linkedin_redirect_uri ? values.linkedin_redirect_uri : ""
      );
    }

    // Stripe
    if (values.stripe_account_id !== undefined) {
      data.append(
        "stripe_account_id",
        values.stripe_account_id ? values.stripe_account_id : ""
      );
    }

    //Slack
    if (values[SETTING_SLACK_APP_URL] !== undefined) {
      data.append(
        SETTING_SLACK_APP_URL,
        values[SETTING_SLACK_APP_URL] ? values[SETTING_SLACK_APP_URL] : ""
      );
    }

    // Save settings
    saveSettings(data);
  };
  // const slack = adminSettings[SETTING_SLACK_APP_URL];
  return (
    <Form
      initialValues={systemSettingsGeneralSectionInitialValues}
      enableReinitialize={true}
      onSubmit={values => {
        submit(values);
      }}
    >
      <div className="tab-content-wrapper">
        <DefaultContent title="Website">
          <div className="field-wrapper">
            <h5 className="font-weight-bold">Timezone</h5>
            <Text
              name={SETTINGS_DEFAULT_TIMEZONE}
              placeholder="Enter Website Timezone"
            />
          </div>
        </DefaultContent>

        <DefaultContent title="AWS S3">
          <h5 className="font-weight-bold">Media Base Url</h5>
          <Text name={MEDIA_BASE_URL} placeholder="Enter Media Portal Url" />
          <h5 className="font-weight-bold">Key ID</h5>
          <Text name={SETTINGS_AWS_KEY_ID} placeholder="Enter AWS Key ID" />

          <h5 className="font-weight-bold">Secret</h5>
          <Text
            name={SETTINGS_AWS_SECRET_KEY_ACCESS}
            placeholder="Enter AWS Secret Key Access"
          />

          <h5 className="font-weight-bold">Bucket Name</h5>
          <Text
            name={SETTINGS_AWS_BUCKET}
            placeholder="Enter AWS Secret Key Access"
          />
        </DefaultContent>

        <DefaultContent title="SendGrid">
          <div className="form-wrapper">
            <h5 className="font-weight-bold">Api Key</h5>
            <Text
              name={SETTINGS_SENDGRID_API_KEY}
              placeholder="Enter SendGrid Api Key"
            />
          </div>
        </DefaultContent>

        <DefaultContent title="Stripe">
          <div className="field-wrapper">
            <h5 className="font-weight-bold">Account ID</h5>
            <Text
              name={SETTINGS_STRIPE_ACCOUNT_ID}
              placeholder="Enter Stripe Account ID"
            />
          </div>
        </DefaultContent>

        <DefaultContent title="Google">
          <h5 className="font-weight-bold">reCAPTCHA Site Key</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY}
              placeholder="Enter Google reCAPTCHA Site Key"
            />
          </div>

          <h5 className="font-weight-bold">Client Id</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_GOOGLE_CLIENT_ID}
              placeholder="Enter Client ID"
            />
          </div>
        </DefaultContent>

        <DefaultContent title="Google Places API">
          <div className="field-wrapper">
            <h5 className="font-weight-bold">API Key</h5>
            <Text
              name={SETTINGS_GOOGLE_PLACES_API_KEY}
              placeholder="Enter API Key"
            />
          </div>
        </DefaultContent>

        <DefaultContent title="LinkedIn">
          <h5 className="font-weight-bold">Url</h5>
          <div className="field-wrapper">
            <Text name={SETTINGS_LINKEDIN_URL} placeholder="Enter Url" />
          </div>

          <h5 className="font-weight-bold">Client Id</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_LINKEDIN_CLIENT_ID}
              placeholder="Enter Client ID"
            />
          </div>

          <h5 className="font-weight-bold">Client Secret</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_LINKEDIN_CLIENT_SECRET}
              placeholder="Enter Client Secret"
            />
          </div>

          <h5 className="font-weight-bold">Redirect Uri</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_LINKEDIN_REDIRECT_URI}
              placeholder="Enter Redirect Uri"
            />
          </div>
        </DefaultContent>

        <DefaultContent title="Office 365">
          <h5 className="font-weight-bold">Client Id</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_OFFICE365_CLIENT_ID}
              placeholder="Enter Client ID"
            />
          </div>

          <h5 className="font-weight-bold">Redirect Url</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_OFFICE365_REDIRECT_URI}
              placeholder="Enter Redirect Url"
            />
          </div>
        </DefaultContent>

        <DefaultContent title="Salesforce">
          <h5 className="font-weight-bold">Client Id</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_SALESFORCE_LOGIN_CLIENT_ID}
              placeholder="Enter Client ID"
            />
          </div>

          <h5 className="font-weight-bold">Redirect Url</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_SALESFORCE_LOGIN_REDIRECT_URL}
              placeholder="Enter Redirect Url"
            />
          </div>
        </DefaultContent>

        {/* Slack */}
        <DefaultContent title="Slack">
          <h5 className="font-weight-bold">Slack App Url</h5>
          <div className="field-wrapper">
            <Text name={SETTING_SLACK_APP_URL} placeholder="Enter App Url" />
          </div>
        </DefaultContent>
      </div>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default GeneralSection;
