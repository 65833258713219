import React from "react";

//component
import MessageCard from "../../views/message/MessageCard";
import NoRecordsFound from "../../components/base/NoRecordsFound";
import PartnerCommunicationIcon from "../../assets/img/icons/nav-icon-partnercommunication.svg";
import Spinner from "../../components/base/Spinner";
import { isEmpty } from "../../lib/helper";

const MessageList = props => {
  const {
    messageList,
    handleMessage,
    messageButton,
    buttonLabel,
    handleMessageModal,
    handleClose,
    isLoading
  } = props;
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div className="marketplace-section-content pt-3 pb-3">
      <div className="tab-content-wrapper">
        <div className="team-members overflow-hidden">
          {!isLoading && isEmpty(messageList) ? (
            messageList.map((messageDetails, index) => (
              <>
                <MessageCard
                  messageDetails={messageDetails}
                  handleMessage={handleMessage}
                />
              </>
            ))
          ) : (
            <NoRecordsFound
              icon={PartnerCommunicationIcon}
              hideCard={true}
              description={`Get started by selecting “New Message”.`}
              messageButton={messageButton ? messageButton : false}
              buttonLabel={buttonLabel ? buttonLabel : ""}
              handleMessageModal={handleMessageModal}
              handleClose={handleClose}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default MessageList;
