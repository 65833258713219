import React from "react";

// Components
import PageTitle from "../../components/base/PageTitle";

class IntroToPortal extends React.Component {
  render() {
    return (
      <>
        {/* page heading */}
        <PageTitle label="Intro To Portal" />
      </>
    );
  }
}

export default IntroToPortal;
