import React, { useState } from "react";

// Components
import Form from "../components/base/Form";
import DefaultContent from "../components/content/DefaultContent";
import Text from "../components/base/Text";
import SaveButton from "../components/base/SaveButton";

// Constants
import {
  SETTINGS_CROSSBEAM_CLIENT_ID,
  SETTINGS_CROSSBEAM_CLIENT_SECRET
} from "../setting/Constants";

// API call
import { apiClient } from "../apiClient";

// Configs
import { endpoints } from "../configs";

import toast from "../components/base/Toast";

import { isBadRequest } from "../common/http";

const CrossbeamSection = props => {
  const { adminSettings, saveSettings } = props;

  const {
    crossbeam_client_id,
    crossbeam_client_secret,
    crossbeam_refresh_token
  } = adminSettings;

  const [isCrossbeamConnected, setIsCrossbeamConnected] = useState(
    crossbeam_refresh_token ? true : false
  );

  // Crossbeam Section Initial Values
  const crossbeamSettingsInitialValues = {
    crossbeam_client_id,
    crossbeam_client_secret
  };

  // Connect Crossbeam
  const _connectCrossbeam = () => {
    return apiClient
      .get(`${endpoints().settingAPI}/crossbeam/auth`)
      .then(response => {
        if (response && response.data) {
          window.location = response.data.redirectTo;
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Crossbeam Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Crossbeam Integration Data
    if (values.crossbeam_client_id !== undefined) {
      data.append("crossbeam_client_id", values.crossbeam_client_id);
    }

    if (values.crossbeam_client_secret !== undefined) {
      data.append("crossbeam_client_secret", values.crossbeam_client_secret);
    }

    // Save settings
    saveSettings(data);
  };

  // Disconnect Crossbeam
  const handleCrossbeamDisconnect = () => {
    const data = new FormData();
    data.append("crossbeam_refresh_token", "");
    setIsCrossbeamConnected(false);
    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={crossbeamSettingsInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent
        title="Crossbeam"
        enableButton
        buttonLabel={!isCrossbeamConnected ? "Connect" : "Disconnect"}
        onButtonClick={
          !isCrossbeamConnected ? _connectCrossbeam : handleCrossbeamDisconnect
        }
      >
        <h5 className="font-weight-bold">Client Id</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_CROSSBEAM_CLIENT_ID}
            placeholder="Crossbeam Client Id"
          />
        </div>

        <h5 className="font-weight-bold">Client Secret</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_CROSSBEAM_CLIENT_SECRET}
            placeholder="Crossbeam Client Secret"
          />
        </div>
      </DefaultContent>

      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default CrossbeamSection;
