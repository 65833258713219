import React, { useState, useRef } from "react";

// API call
import { apiClient } from "../../../apiClient";

// Configs
import { endpoints } from "../../../configs";

// Components
import DefaultContent from "../../../components/content/DefaultContent";
import Form from "../../../components/base/Form";
import SaveButton from "../../../components/base/SaveButton";
import toast from "../../../components/base/Toast";
import { isBadRequest } from "../../../common/http";

const ImportTab = () => {
  const settingConfigRef = useRef();

  const [settingConfigFileTypeError, setSettingConfigFileTypeError] = useState(
    false
  );

  const [settingConfigObject, setSettingConfigObject] = useState("");

  // Validate Filetype
  const validateFile = (e, File) => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "application/json":
          File === "settingConfig" && setSettingConfigFileTypeError(false);
          break;
        default:
          File === "settingConfig" && setSettingConfigFileTypeError(true);
          break;
      }
    }
  };

  // Handle Setting Config File Change
  const onSettingConfigChange = e => {
    validateFile(e, "settingConfig");
    if (!settingConfigFileTypeError) {
      const systemConfigfile = e.target.files ? e.target.files[0] : "";
      const reader = new FileReader();
      reader.onload = event => {
        const objJSON = JSON.parse(event.target.result);
        setSettingConfigObject(objJSON);
      };
      reader.readAsText(systemConfigfile);
    }
  };

  // Setting Config File Remove
  const onSettingConfigRemove = () => {
    setSettingConfigFileTypeError(false);
    settingConfigRef.current.value = "";
    setSettingConfigObject("");
  };

  const settingConfigUpload = data => {
    return apiClient
      .put(`${endpoints().settingAPI}/job/createSystemConfig`, data)
      .then(response => {
        if (response && response.data) {
          toast.success("Settings saved successfully");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  return (
    <Form
      onSubmit={() => {
        if (settingConfigObject) {
          settingConfigUpload(settingConfigObject);
        }
      }}
    >
      <div className="form-wrapper">
        <DefaultContent>
          {/* Setting Config upload */}
          <h5 className="font-weight-bold">Setting Config</h5>
          <div className="field-wrapper d-flex">
            <div>
              <div className="upload-field d-inline-block">
                <input
                  name="file"
                  className="form-control d-none"
                  type="file"
                  id="settingConfig"
                  placeholder=""
                  onChange={e => {
                    onSettingConfigChange(e);
                  }}
                  accept="application/json"
                  ref={settingConfigRef}
                />
                <span className="profilePicOverlay d-block ">
                  <label
                    htmlFor="settingConfig"
                    className="profile-img-sm mb-0"
                  >
                    <span className="banner-image-upload-link">
                      Upload Setting Config JSON File
                    </span>
                  </label>
                </span>
              </div>
              {/* Remove image */}
              {settingConfigObject && (
                <span
                  className="banner-image-upload-link text-danger ml-md-1"
                  onClick={onSettingConfigRemove}
                >
                  (Remove)
                </span>
              )}
              <div className="field-wrapper">
                <small
                  className={`text-danger mt-3 ${
                    settingConfigFileTypeError ? "d-block" : "d-none"
                  }`}
                >
                  Please select a JSON file
                </small>
              </div>
            </div>
          </div>
        </DefaultContent>
      </div>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default ImportTab;
