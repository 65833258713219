import React from "react";

// Configs
import { apiClient } from "../../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../../configs";

// Components
import ExpertCard from "../../components/base/ExpertCard";
import MarketplaceSection from "../../components/MarketplaceSection";

// Helper
import { getCookie } from "../../lib/helper";
import { COOKIE_SESSION_TOKEN } from "../../lib/cookie";

class PartnersDirectory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partners: []
    };
  }

  getPartners = () => {
    const sessionToken = getCookie(COOKIE_SESSION_TOKEN);
    apiClient.defaults.headers.common.Authorization = !sessionToken
      ? DEFAULT_API_KEY
      : sessionToken;

    apiClient
      .get(
        `${
          endpoints().partnerAPI
        }/search?marketplaceStatus=Approved&profileVisibility=true`
      )
      .then(response => {
        const partners = response.data.data || [];
        this.setState({
          partners
        });
      });
  };

  componentDidMount() {
    // Check is logged in user
    this.getPartners();
  }

  render() {
    const { partners } = this.state;

    return (
      <>
        {/* /.page-heading */}
        <div className="marketplace-landing">
          <MarketplaceSection title="Terminus Partners">
            {partners &&
              partners.length > 0 &&
              partners.map(partner => (
                <ExpertCard
                  classnames={"expert-card"}
                  data={partner}
                  size="col-12 col-md-6 col-lg-3 col-sm-6"
                  key={partner.id}
                  certified={true}
                  openLinkInNewTab={true}
                  link={`/partner-profile/${partner.id}`}
                  fixedWidth={false}
                />
              ))}
          </MarketplaceSection>
        </div>
      </>
    );
  }
}

export default PartnersDirectory;
