import React, { Component } from "react";

// Api call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Components
import Text from "../../components/base/Text";
import Select from "../../components/base/Select";
import TextArea from "../../components/base/TextArea";
import Number from "../../components/base/Number";
import Currency from "../../components/base/Currency";
import Label from "../../components/base/Label";
import DatePicker from "react-date-picker";
import { isBadRequest } from "../../common/http";

class ProjectFormFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      customerList: []
    };
  }

  componentDidMount = () => {
    this.getCustomers();
  };

  // Get Tag Values
  getCustomers() {
    return apiClient
      .get(`${endpoints().customerAPI}`)
      .then(response => {
        this.setState({
          customerList: response.data.data
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  // Render the form fields
  render() {
    // Billing type options
    const billingTypeOptions = [
      {
        value: "Hourly",
        label: "Hourly"
      },
      {
        value: "Fixed",
        label: "Fixed"
      },
      {
        value: "Half up front",
        label: "Half up front"
      }
    ];
    // Billing type options
    const statusOptions = [
      {
        value: "Active",
        label: "Active"
      },
      {
        value: "Non Started - Resourcing",
        label: "Non Started - Resourcing"
      },
      {
        value: "Resourced - Waiting Acceptance",
        label: "Resourced - Waiting Acceptance"
      },
      {
        value: "Acceptance - Waiting Payment",
        label: "Acceptance - Waiting Payment"
      },
      {
        value: "Requested",
        label: "Requested"
      },
      {
        value: "Completed",
        label: "Completed"
      },
      {
        value: "Cancelled",
        label: "Cancelled"
      }
    ];
    // Billing type options
    const methodsOfProjectCreationOptions = [
      {
        value: "User initiated from portal",
        label: "User initiated from portal"
      },
      {
        value: "Marketplace Self Service auto created",
        label: "Marketplace Self Service auto created"
      }
    ];

    //Customers
    const { customerList } = this.state;
    let customers = [];
    if (customerList) {
      customerList.forEach(customer => {
        customers.push({
          label: customer.displayCompanyName,
          value: customer.id
        });
      });
    }
    const { setDate, desiredStartDate } = this.props;

    return (
      <div>
        <div className="card border-0">
          <h5 className="mt-3 ml-3">
            <b>Project Details</b>
          </h5>
          <div className="card-body">
            <div className="row">
              <div className="col col-sm-12">
                <Text
                  name="name"
                  label="PageTitle"
                  placeholder="Enter name"
                  required={true}
                  error=""
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="projectCategory"
                  label="Project Category"
                  placeholder="Enter project Category"
                  error=""
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="methodology"
                  label="Methodology"
                  placeholder="Enter Methodology"
                  error=""
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-sm-12">
                <TextArea
                  name="description"
                  label="Description"
                  placeholder="Enter Description"
                  error=""
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-sm-12">
                <TextArea
                  name="requirements"
                  label="Requirements"
                  placeholder="Enter Requirements"
                  error=""
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-sm-12">
                <TextArea
                  name="outOfScope"
                  label="Out Of Scope"
                  placeholder="Enter Out Of Scope"
                  error=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-sm-12">
                <TextArea
                  name="changeManagement"
                  label="Change Management"
                  placeholder="Enter Change Management"
                  error=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3 mb-3 border-0">
          <h5 className="mt-3 ml-3">
            <b>Estimation</b>
          </h5>
          <div className="card-body">
            <div className="row">
              <div className="col col-sm-12">
                <TextArea
                  name="toolsExpectedExpertHas"
                  label="Tools Expected Expert Has"
                  placeholder="Enter Tools Expected Expert Has"
                  error=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col col-sm-12">
                <TextArea
                  name="industryOfRelevance"
                  label="Industry Of Relevance"
                  placeholder="Enter Industry Of Relevance"
                  error=""
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-sm-12">
                <TextArea
                  name="deliverables"
                  label="Deliverables"
                  placeholder="Enter Deliverables"
                  error=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="estimatedTimeline"
                  label="Estimated Timeline"
                  placeholder="Enter Estimated Timeline"
                  error=""
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <Currency
                  name="estimatedBudget"
                  label="Estimated Budget"
                  placeholder="Enter Estimated Budget"
                  error=""
                />
              </div>
            </div>

            <div className="row">
              <div className="col col-sm-12">
                <TextArea
                  name="desiredCommitments"
                  label="Desired Commitments"
                  placeholder="Enter Desired Commitments"
                  error=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Label id="desiredStartDate">Desired Start Date</Label>
                <DatePicker
                  name="desiredStartDate"
                  className="datepicker"
                  dayPlaceholder="DD"
                  monthPlaceholder="MM"
                  yearPlaceholder="YYYY"
                  minDate={new Date()}
                  value={desiredStartDate}
                  onChange={date => setDate(date, "desiredStartDate")}
                  clearIcon={null}
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <Number
                  name="estimatedLength"
                  label="Estimated Length"
                  placeholder="Enter Estimated Length in Hours"
                  error=""
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="locationPreference"
                  label="Location Preferences"
                  placeholder="Enter Location Preference"
                  error=""
                />
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3 mb-3 border-0">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Select
                  name="billingType"
                  label="Billing Type"
                  placeholder="Enter Billing Type"
                  options={billingTypeOptions}
                  isSearchable={true}
                  error=""
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="paymentTerms"
                  label="Payment Terms"
                  placeholder="Enter Payment Terms"
                  isSearchable={true}
                  error=""
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="margin"
                  label="Margin"
                  placeholder="Enter Margin"
                  error=""
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <Select
                  name="status"
                  label="Status"
                  placeholder="Enter Status"
                  options={statusOptions}
                  isSearchable={true}
                  error=""
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Select
                  name="methodOfProjectCreation"
                  label="Method Of Project Creation"
                  placeholder="Enter Method Of Project Creation"
                  options={methodsOfProjectCreationOptions}
                  isSearchable={true}
                  error=""
                />
              </div>
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="creatorUserId"
                  label="Creator"
                  placeholder="Enter"
                  error=""
                />
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Select
                  name="customer"
                  label="Customer"
                  placeholder="Enter Customer"
                  options={customers}
                  isSearchable={true}
                  error=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectFormFields;
