import axios from "axios";

// Constants
import {
  SETTINGS_PORTAL_NAME,
  SETTINGS_METACX_CUSTOMER_ID,
  SETTINGS_METACX_EXPERT_VIRTUAL_CUSTOMER_ID,
  SETTINGS_METACX_VISITOR_VIRTUAL_CUSTOMER_ID,
  SETTINGS_METACX_ADMIN_VIRTUAL_CUSTOMER_ID,
  SETTINGS_METACX_CLIENT_ID,
  SETTINGS_METACX_CLIENT_SECRET,
  SETTINGS_METACX_API_URL
} from "../setting/Constants";

import {
  COOKIE_ROLE,
  COOKIE_CUSTOMER_ID,
  COOKIE_CUSTOMER_NAME,
  COOKIE_USER_ID
} from "./cookie";

import {
  ROLE_COMPANY_ADMIN_TEXT,
  CUSTOMER_ROLE_TEXT,
  EXPERT_ROLE_TEXT
} from "../roles/Constants";

// Helpers
import {
  isExpert,
  getLoggedInStatus,
  isCustomer,
  getCookie,
  setCookie,
  isCompanyAdmin,
  getKeyValueByObject
} from "./helper";

// API call
import { apiClient } from "../apiClient";
import { endpoints } from "../configs";

/**
 * MetaCX Event
 *
 * @param data
 * @param authData
 * @param callback
 * @returns {*}
 */
function metaCXEvent(data, authData, callback) {
  const {
    settingsMetaCXClientId,
    settingsMetaCXClientSecret,
    settingsMetaCXApiUrl
  } = authData;
  if (
    !settingsMetaCXClientId ||
    !settingsMetaCXClientSecret ||
    !settingsMetaCXApiUrl
  ) {
    return callback();
  }

  const metaCXApiClient = axios.create({
    baseURL: settingsMetaCXApiUrl,
    timeout: 20000,
    headers: {
      "Content-Type": "application/json"
    }
  });

  const metaCXClientAuth = {
    clientId: settingsMetaCXClientId,
    clientSecret: settingsMetaCXClientSecret
  };

  // Get MetaCX Access Token
  metaCXApiClient
    .post("/getToken", JSON.stringify(metaCXClientAuth))
    .then(response => {
      const result = response.data;
      const accessToken = result.accessToken;

      /* <----- To debug MetaCX workFlow, Uncomment the following console logs -----> */
      // console.log("-------- MetaCX Event - START --------");
      // console.log("MetaCX API URL---->", settingsMetaCXApiUrl);
      // console.log(
      //   "MetaCX API Get Token URL---->",
      //   `${settingsMetaCXApiUrl}/getToken`
      // );
      // console.log(
      //   "MetaCX Event Track API URL---->",
      //   `${settingsMetaCXApiUrl}/events`
      // );
      // console.log("MetaCX Event Data---->", data);
      // console.log("MetaCX Token Response---->", result);
      // console.log("-------- MetaCX Event - END --------");

      // Send MetaCX Event Data
      metaCXApiClient.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      metaCXApiClient.post("/events", data).then(response => {
        /* <----- To debug MetaCX event response, Uncomment the following console -----> */
        // console.log("MetaCX Event Response---->", response.data);
        return callback();
      });
    });
}

/**
 * Generate MetaCX Event Data
 *
 * @param eventKey
 * @param eventData
 * @returns {{eventKey: string, metaKeys: {field: string, name: string, alternateName: string}[], data: {eventData}[]}}
 */
function generateMetaCXEventData(eventKey, eventData) {
  return {
    eventKey,
    metaKeys: [
      {
        name: eventKey,
        alternateName: eventKey,
        field: eventKey
      }
    ],
    data: [eventData]
  };
}

/**
 * Generate MetaCX Event Data
 *
 * @param eventKey
 * @param data
 * @param callback
 * @returns {*}
 */
export function metaCXTrack(eventKey, data, settings, callback) {
  if (!eventKey || !data) {
    return callback();
  }

  getSettingsData(settings, settingMetaCxData => {
    // Define Settings Cookie constants
    const settingsMarketplace = settingMetaCxData.settingsMarketplace;
    const settingsMetaCXCustomerId = settingMetaCxData.settingsMetaCXCustomerId;
    const settingsMetaCXExpertVirtualCustomerId =
      settingMetaCxData.settingsMetaCXExpertVirtualCustomerId;
    const settingsMetaCXVisitorVirtualCustomerId =
      settingMetaCxData.settingsMetaCXVisitorVirtualCustomerId;
    const settingsMetaCXAdminVirtualCustomerId =
      settingMetaCxData.settingsMetaCXAdminVirtualCustomerId;

    const auth = {
      settingsMetaCXClientId: settingMetaCxData.settingsMetaCXClientId,
      settingsMetaCXClientSecret: settingMetaCxData.settingsMetaCXClientSecret,
      settingsMetaCXApiUrl: settingMetaCxData.settingsMetaCXApiUrl
    };

    let eventData = {};

    // Add Event Date with MetaCX Event Data
    const eventDate = new Date();
    eventData.eventDate = `${eventDate.getTime()}`;

    // Add Torchlite Marketplace ID and Multi Site ID with MetaCX Event Data
    eventData.torchliteMarketplaceID = settingsMarketplace;
    eventData.torchliteCustomerID = settingsMetaCXCustomerId;

    // Verify Logged in User
    if (getLoggedInStatus()) {
      // Set Virtual Customer ID for Admin User
      if (isCompanyAdmin()) {
        eventData.customerId = settingsMetaCXAdminVirtualCustomerId;
        eventData.userType = ROLE_COMPANY_ADMIN_TEXT;

        // Set Virtual Customer ID for Expert User
      } else if (isExpert()) {
        eventData.customerId = settingsMetaCXExpertVirtualCustomerId;
        eventData.userType = EXPERT_ROLE_TEXT;

        // Add Customer Details with MetaCX Data
      } else if (isCustomer()) {
        data.userType = CUSTOMER_ROLE_TEXT;
        if (getCookie(COOKIE_CUSTOMER_ID) && getCookie(COOKIE_CUSTOMER_NAME)) {
          eventData.customerId = getCookie(COOKIE_CUSTOMER_ID);
          eventData.customerName = getCookie(COOKIE_CUSTOMER_NAME);
        } else {
          apiClient.defaults.headers.common.Authorization = getLoggedInStatus();

          // Get Customer Details
          apiClient
            .get(`${endpoints().customerAPI}/undefined`)
            .then(response => {
              const customerDetails = response.data;
              if (!customerDetails) {
                return callback();
              }
              eventData.customerId = `${customerDetails.id}`;
              eventData.customerName = customerDetails.legalCompanyName;

              // Set Customer Id and Name in Cookie
              setCookie(COOKIE_CUSTOMER_ID, `${customerDetails.id}`);
              setCookie(COOKIE_CUSTOMER_NAME, customerDetails.legalCompanyName);
            });
        }
      }

      eventData.userId = getCookie(COOKIE_USER_ID);
      eventData.role = getCookie(COOKIE_ROLE);

      // Set Virtual Customer ID for Guest User
    } else {
      eventData.customerId = settingsMetaCXVisitorVirtualCustomerId;
    }

    eventData = { ...eventData, ...data };

    if (
      eventData.torchliteMarketplaceID &&
      eventData.torchliteCustomerID &&
      eventData.customerId
    ) {
      // Call MetaCx Event API Call
      metaCXEvent(generateMetaCXEventData(eventKey, eventData), auth, () => {
        return callback();
      });
    } else {
      return callback();
    }
  });
}

export const getSettingsData = (settings, callBack) => {
  if (!settings.length) {
    return "";
  }

  const settingsMarketplace = settings
    ? getKeyValueByObject(settings, SETTINGS_PORTAL_NAME)
    : "";

  const settingsMetaCXCustomerId = settings
    ? getKeyValueByObject(settings, SETTINGS_METACX_CUSTOMER_ID)
    : "";
  const settingsMetaCXExpertVirtualCustomerId = settings
    ? getKeyValueByObject(settings, SETTINGS_METACX_EXPERT_VIRTUAL_CUSTOMER_ID)
    : "";
  const settingsMetaCXVisitorVirtualCustomerId = settings
    ? getKeyValueByObject(settings, SETTINGS_METACX_VISITOR_VIRTUAL_CUSTOMER_ID)
    : "";
  const settingsMetaCXAdminVirtualCustomerId = settings
    ? getKeyValueByObject(settings, SETTINGS_METACX_ADMIN_VIRTUAL_CUSTOMER_ID)
    : "";
  const settingsMetaCXClientId = settings
    ? getKeyValueByObject(settings, SETTINGS_METACX_CLIENT_ID)
    : "";
  const settingsMetaCXClientSecret = settings
    ? getKeyValueByObject(settings, SETTINGS_METACX_CLIENT_SECRET)
    : "";
  const settingsMetaCXApiUrl = settings
    ? getKeyValueByObject(settings, SETTINGS_METACX_API_URL)
    : "";

  return callBack({
    settingsMarketplace,
    settingsMetaCXCustomerId,
    settingsMetaCXExpertVirtualCustomerId,
    settingsMetaCXVisitorVirtualCustomerId,
    settingsMetaCXAdminVirtualCustomerId,
    settingsMetaCXClientId,
    settingsMetaCXClientSecret,
    settingsMetaCXApiUrl
  });
};
