import React, { useEffect } from "react";
import { DashboardResourceIcon } from "../../../assets/img/icons";
import { PARTNER_STATUS_APPROVED } from "../../../partnerStatus/Constants";
import ListViewCard from "../../../components/base/ListViewCard";

// Components
import ResourceGridCard from "../../resource/inner-component/ResourceGridCard";
import { PARTNER_TYPE_SETTING_ENABLE_PARTNER_RESOURCES } from "../../../partnerTypeSetting/Constants";
import { getKeyValueByObject } from "../../../lib/helper";

const RecommendedResourcesList = props => {
  const {
    partnerSettings,
    data,
    currentPartnerTier,
    partnerName,
    enable,
    partnerStatus,
    resourCount
  } = props;

  const partnerTypeSettingEnablePartnerResources = partnerSettings
    ? getKeyValueByObject(
        partnerSettings,
        PARTNER_TYPE_SETTING_ENABLE_PARTNER_RESOURCES
      )
    : "";

  return (
    <>
      {partnerTypeSettingEnablePartnerResources === "true" && (
        <div
          className={`${
            !enable
              ? "disabled"
              : partnerStatus !== PARTNER_STATUS_APPROVED
              ? "disabled"
              : ""
          }`}
        >
          {data && data.length > 0 && (
            <div className={["mt-5", "d-flex justify-content-start"].join(" ")}>
              <span className={"mr-3"}>
                <div className={"elite-resource-wrapper text-white"}>
                  <DashboardResourceIcon />
                </div>
              </span>

              <span className="align-self-center">
                {/* <h6
                  className={["mb-0", "font-weight-bold"].join(" ")}
                  style={{ color: "#5B44F8" }}
                >
                  {currentPartnerTier
                    ? currentPartnerTier + " Partner Tier"
                    : "All Partners"}
                </h6> */}
                <h3 className={["mb-2", "font-weight-bold"].join(" ")}>
                  Recommended Resources
                </h3>
              </span>
            </div>
          )}
          <ListViewCard
            link={data.length > 4 ? "resources?section=Published" : ""}
          >
            {
              <div className="row" style={{ marginLeft: "43px" }}>
                {resourCount.map(resource => (
                  <ResourceGridCard
                    category={resource.primaryCategory}
                    description={resource.description}
                    data={{
                      ...resource,
                      partnerId: resource.partner_id,
                      partnerName
                    }}
                    position="relative"
                    right={resource.banner_image ? "18px" : ""}
                    left="123px"
                    link={resource.resourceFile || resource.web_page_url}
                    history={props.history}
                    openLinkInNewTab={true}
                    isPreview
                    size={"col-xl-6 col-lg-6 col-md-6"}
                  />
                ))}
              </div>
            }
          </ListViewCard>
        </div>
      )}
    </>
  );
};

export default RecommendedResourcesList;
