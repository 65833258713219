import React from "react";

import { connect } from "react-redux";

import { bindActionCreators } from "redux";

// Components
import Form from "../../../../components/base/Form";

import LandingPageTitle from "../../../../components/LandingPageTitle";

import LandingPageBlocks from "./LandingPageBlocks";

import Page404 from "../../../Page404";

import Spinner from "../../../../components/base/Spinner";

import toast from "../../../../components/base/Toast";

import Select from "../../../../components/Select";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

// Actions
import { fetchLandingPageDetail } from "../../../../actions/landingPage";

// Constants
import {
  GENERAL_GENERAL_SUB_TAB,
  MARKETPLACES_SUB_TAB,
  TAB_GENERAL,
  TAB_MARKETPLACE
} from "../../../../setting/Constants";

import { ChevronRight } from "../../../../assets/img/icons";

import { isBadRequest } from "../../../../common/http";

class LandingPageTypeForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      maxLength: 0,
      vanityUrlSlug: "",
      status: "",
      landingPageDetails: "",
      loading: false,
      isCollectionLoading: false,
      editing: false,
      isVanityUrlSlugEditable: false,
      collections: [],
      isInvalidRequest: false,
      isSlugEmpty: false
    };

    this.vanityUrlSlugHandleChange = this.vanityUrlSlugHandleChange.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.edit = this.edit.bind(this);
    this.vanityUrlEdit = this.vanityUrlEdit.bind(this);
    this.save = this.save.bind(this);
    this.saveVanitySlug = this.saveVanitySlug.bind(this);
    this.close = this.close.bind(this);
  }

  componentDidMount = () => {
    this._getLandingPageDetails();
    if (this.props.marketplaceId) {
      this.props.actions.fetchLandingPageDetail(this.props.marketplaceId);
    }
  };

  // Landing page API Call
  landingPageUpdate(data) {
    return apiClient
      .put(`${endpoints().landingPageAPI}/${this.props.marketplaceId}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Landing page api call by id
  _getLandingPageDetails = () => {
    if (this.props.marketplaceId) {
      return apiClient
        .get(`${endpoints().landingPageAPI}/${this.props.marketplaceId}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);
          const { name, status, vanity_url_slug } = response.data;

          this.setState({
            name: name,
            maxLength: name && name.length,
            vanityUrlSlug: vanity_url_slug,
            restoreVanityUrlSlug: vanity_url_slug,
            status: status,
            landingPageDetails: response.data,
            loading: true
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
            this.setState({ isInvalidRequest: true });
          }
        });
    }
  };

  handleStatus = values => {
    const status = values;
    this.setState({ status: status });

    return apiClient
      .put(`${endpoints().landingPageAPI}/${this.props.marketplaceId}`, {
        status: status
      })
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.props.actions.fetchLandingPageDetail(this.props.marketplaceId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ status: "Draft" });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Edit the landing page
  edit() {
    this.setState({
      editing: true,
      isVanityUrlSlugEditable: false
    });
  }

  // Edit the vanity url page
  vanityUrlEdit() {
    this.setState({
      isVanityUrlSlugEditable: true,
      editing: false
    });
  }

  // Save the landing page name
  save() {
    const landingPageName = this.refs.landingPageName.value;
    this.setState(
      {
        editing: false
      },
      () => {
        this.landingPageUpdate({ name: landingPageName });
        this.setState({
          name: landingPageName
        });
      }
    );
  }

  // Save the vanity slug url
  saveVanitySlug() {
    const vanityUrlSlug = this.refs.vanityUrlSlug.value;
    if (!vanityUrlSlug) {
      return false;
    }
    this.setState(
      {
        restoreVanityUrlSlug: this.state.vanityUrlSlug,
        isVanityUrlSlugEditable: false
      },
      () => {
        this.landingPageUpdate({ vanityUrlSlug: vanityUrlSlug });
        this.setState({
          vanityUrlSlug: vanityUrlSlug
        });
      }
    );
  }

  // Close the inline edit for landing page name
  close() {
    this.setState({
      vanityUrlSlug: this.state.restoreVanityUrlSlug,
      editing: false,
      isVanityUrlSlugEditable: false
    });
  }

  // Render the edit landing page form
  renderEditLandingPageForm() {
    const { name, editing } = this.state;
    const maxLength = 255;

    return (
      <div className="section-title inline-edit-section col-md-9">
        <div className="form-inline">
          <input
            className="landing-page-name"
            name="landingPageName"
            ref="landingPageName"
            defaultValue={name}
            maxLength={maxLength}
            onKeyUp={e => this.setState({ maxLength: e.target.value.length })}
          />
        </div>
        <div className={`landing-page-edit ${editing ? "d-block" : "d-none"}`}>
          <i className="fas fa-check" onClick={this.save}></i>
          <i className="fas fa-times" onClick={this.close}></i>
        </div>
        <span className="char-count d-block text-inline-grayed h7">
          {`${this.state.maxLength}/${maxLength} Characters`}
        </span>
      </div>
    );
  }

  // Render the landing page title
  renderLandingPageTitle(name) {
    return (
      <LandingPageTitle
        settings={this.props && this.props.settings}
        name={this.state.name}
        handleEdit={this.edit}
      />
    );
  }

  // Render the vanity url
  renderLandingPageVanityUrlSlug(vanityUrl) {
    return (
      <div className="section-title col-md-9 mb-2">
        <div className="form-inline">
          <span className="d-block">{vanityUrl}</span>
          <span
            style={{
              backgroundColor: "white",
              padding: "5px",
              borderRadius: "8px"
            }}
            className="ml-2 cursor-pointer"
            onClick={this.vanityUrlEdit}
          >
            <i className="fas fa-pen" />
          </span>
        </div>
      </div>
    );
  }

  // Render the edit vanity url form
  renderVanityUrlSlugInputs() {
    const { vanityUrlSlug, isVanityUrlSlugEditable, isSlugEmpty } = this.state;

    return (
      <div className="inline-edit-section col-md-9 mt-1">
        <div className="form-inline">
          <input
            type="text"
            placeholder="Vanity URL Slug"
            name="vanityUrlSlug"
            ref="vanityUrlSlug"
            className="landing-page-vanity-url"
            defaultValue={vanityUrlSlug}
            value={vanityUrlSlug}
            style={{
              height: "40px",
              padding: "10px"
            }}
            onChange={this.vanityUrlSlugHandleChange}
          />
        </div>
        <div
          className={`landing-page-edit ${
            isVanityUrlSlugEditable ? "d-block" : "d-none"
          }`}
        >
          <i className="fas fa-check" onClick={this.saveVanitySlug}></i>
          <i className="fas fa-times" onClick={this.close}></i>
        </div>
        <span className={`text-danger ${isSlugEmpty ? "d-block" : "d-none"}`}>
          Slug url is required
        </span>
      </div>
    );
  }

  // Vanity url change
  vanityUrlSlugHandleChange(e) {
    const vanityUrlSlug = e.target.value
      .split(" ")
      .join("-")
      .toLowerCase();
    this.setState({ vanityUrlSlug, isSlugEmpty: !vanityUrlSlug });
  }

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return <Spinner />;
    }

    const {
      status,
      name,
      vanityUrlSlug,
      isVanityUrlSlugEditable,
      editing
    } = this.state;

    const sectionId = this.props.sectionId;
    const history = this.props.history;

    const Options = [
      {
        label: "Active",
        value: "Active"
      },
      {
        label: "Draft",
        value: "Draft"
      }
    ];
    return (
      <div>
        {!sectionId && (
          <>
            <>
              {/* Breadcrumb Start */}
              <div className="d-flex align-items-center pt-4 pb-3">
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    history.push(
                      `/admin-settings/${TAB_GENERAL}/${GENERAL_GENERAL_SUB_TAB}`
                    );
                  }}
                >
                  Admin Settings
                </span>
                <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
                  <ChevronRight />
                </span>
                <span
                  className="cursor-pointer"
                  onClick={() => {
                    history.push(
                      `/admin-settings/${TAB_MARKETPLACE}/${MARKETPLACES_SUB_TAB}`
                    );
                  }}
                >
                  <span>{MARKETPLACES_SUB_TAB}</span>
                </span>
                <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
                  <ChevronRight />
                </span>
                <span className="text-dark font-weight-bold ellipsis w-50">
                  <span>{name}</span>
                </span>
              </div>
            </>
            <div className="row">
              {/*Render the landing page and edit the name*/}
              {editing
                ? this.renderEditLandingPageForm()
                : this.renderLandingPageTitle(name)}

              <div className="col-md-3 mt-3">
                <Form
                  initialValues={{
                    status: status
                      ? Options.find(option => option.value === status)
                      : ""
                  }}
                >
                  <Select
                    name="status"
                    defaultValue={Options.find(
                      option => option.value === status
                    )}
                    options={Options}
                    onInputChange={e => {
                      if (e.values && e.values.status && e.values.status.value)
                        this.handleStatus(e.values.status.value);
                    }}
                  />
                </Form>
              </div>

              {isVanityUrlSlugEditable
                ? this.renderVanityUrlSlugInputs()
                : this.renderLandingPageVanityUrlSlug(vanityUrlSlug)}
            </div>
          </>
        )}

        <LandingPageBlocks
          marketplaceId={this.props.marketplaceId}
          marketplaceName={name}
          slugUrl={vanityUrlSlug}
          sectionId={this.props.sectionId}
          history={this.props.history}
          settings={this.props.settings}
          match={this.props.match}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchLandingPageDetail }, dispatch)
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(LandingPageTypeForm);
