import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { DropdownItem } from "reactstrap";

// Config
import { endpoints } from "../../configs";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import MoreDropdown from "../../components/base/MoreDropdown";
import Form from "../../components/base/Form";
import Text from "../../components/base/Text";
import Select from "../../components/base/Select";
import AddButton from "../../components/base/AddButton";
import ToggleSwitch from "../../components/base/ToggleSwitch";
import Spinner from "../../components/base/Spinner";
import DeleteModal from "../../components/base/DeleteModal";
import toast from "../../components/base/Toast";

import { Field } from "formik";

//Helper
import { apiClient } from "../../apiClient";
import { isBadRequest } from "../../common/http";
import { getParamsByName, isLoggedIn } from "../../lib/helper";

//Constant
import {
  SITE_VISIBILITY_DISABLED,
  SITE_VISIBILITY_LIVE
} from "../../views/app/Constant";

//Assets
import { GlobeIcon, PrivateIcon } from "../../assets/img/icons";

class Site extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      id: "",
      site_name: "",
      hosted_url: "",
      currentValue: "",
      template: "",
      type: "",
      appId: "",
      deleteAppSiteModal: false,
      deleteAppSite: false,
      isOpen: false,
      visibility: "",
      visibilityToggle: "",
      isLoading: false,
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Recently Updated"
        },
        {
          value: "first_name:ASC",
          label: "Name"
        }
      ],
      templateOptions: [
        {
          value: "Partner Portal",
          label: "Partner Portal"
        },
        {
          value: "Marketplace",
          label: "Marketplace"
        }
      ],
      typeOptions: [
        {
          value: "Public",
          label: "Public"
        },
        {
          value: "Private",
          label: "Private"
        }
      ]
    };
  }

  componentDidMount() {
    isLoggedIn();
    this.setState({
      appId: getParamsByName("id")
    });
    this._getSiteDetails(this.state.appId);
  }
  componentDidUpdate() {
    // Check is logged in user
  }
  _toggle = tagId => {
    this.setState({
      isOpen: !this.state.isOpen,
      id: tagId || "",
      visibility: false
    });
  };
  /**
   * Create Site
   *
   * @param data
   */
  siteCreate = data => {
    return apiClient
      .post(`${endpoints().appSiteAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._toggle();
        this._getSiteDetails();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  // Get Site name
  _getSiteDetails() {
    this.setState({ isLoading: true });
    apiClient
      .get(`${endpoints().appSiteAPI}/search`)
      .then(response => {
        const templateDeteils = response.data.data;
        this.setState({
          templateDeteils: templateDeteils
            ? templateDeteils
            : this.state.templateDeteils,
          isLoading: false
        });
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isLoading: false });
          console.error(errorMessage);
        }
      });
  }

  // update Site Name page api call by id
  _updateSiteDetails(data) {
    apiClient
      .put(`${endpoints().appSiteAPI}/update`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._toggle();
        this._getSiteDetails();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  }

  // delete Site Name page api call by id
  _deleteSiteDetails(id) {
    apiClient
      .delete(`${endpoints().appSiteAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._getSiteDetails();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  tagModal = () => (
    <div>
      <Modal
        isOpen={this.state.isOpen}
        toggle={this._toggle}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader
          toggle={this._toggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <p> {this.state.id ? "Edit Site" : "Create Site"}</p>
        </ModalHeader>
        <Form
          initialValues={{
            id: this.state.id || "",
            site_name: this.state.site_name.trim() || "",
            template: this.state.currentValue
              ? this.state.templateOptions.find(
                  templateOptions =>
                    templateOptions.value == this.state.currentValue.template
                )
              : "",
            type: this.state.currentValue
              ? this.state.typeOptions.find(
                  typeOptions =>
                    typeOptions.value == this.state.currentValue.type
                )
              : "",
            profile_visibility:
              this.state.visibilityToggle == SITE_VISIBILITY_LIVE
                ? true
                : false,
            hosted_url: this.state.hosted_url || "",
            app_id: getParamsByName("id")
          }}
          onSubmit={values => {
            values.template = values.template.value;
            values.site_name = values.site_name.trim();
            values.type = values.type.value;
            values.visibility =
              this.state.visibilityToggle == SITE_VISIBILITY_LIVE
                ? SITE_VISIBILITY_LIVE
                : SITE_VISIBILITY_DISABLED;
            values.id = this.state.id;
            this.state.id
              ? this._updateSiteDetails(values)
              : this.siteCreate(values);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div>
              <Text
                name="site_name"
                label="Site Name"
                placeholder="Enter Site name"
                error=""
                required={true}
              />
              <div className=" field-wrapper mb-5">
                <Select
                  name="template"
                  label="Template"
                  placeholder="Select Template"
                  options={this.state.templateOptions}
                  defaultValue={
                    this.state.currentValue
                      ? this.state.templateOptions.find(
                          templateOptions =>
                            templateOptions.value ==
                            this.state.currentValue.template
                        )
                      : ""
                  }
                  isSearchable={true}
                  error=""
                  required={true}
                  hintText="Templates are starter implementations to help you setup faster."
                />
              </div>
              <div className="mt-4">
                <Text
                  name="hosted_url"
                  label="Hosted URL"
                  placeholder="Enter URL"
                  error=""
                  required={true}
                />
              </div>
              <div className="d-flex align-items-center justify-content-between mb-2">
                <label className="p-0 m-0"> Visibility </label>
                <div className="pull-right p-0">
                  <Field
                    name="profile_visibility"
                    render={({ field, form }) => {
                      return (
                        <ToggleSwitch
                          name="visibility"
                          label1={
                            this.state.visibilityToggle == SITE_VISIBILITY_LIVE
                              ? "Live"
                              : "Disabled"
                          }
                          label2={
                            this.state.visibilityToggle == SITE_VISIBILITY_LIVE
                              ? "Live"
                              : "Disabled"
                          }
                          value={
                            this.state.visibilityToggle == SITE_VISIBILITY_LIVE
                              ? true
                              : false
                          }
                          handleChange={() => {
                            this.setState({
                              visibilityToggle:
                                this.state.visibilityToggle ==
                                SITE_VISIBILITY_LIVE
                                  ? SITE_VISIBILITY_DISABLED
                                  : this.state.visibilityToggle ==
                                    SITE_VISIBILITY_DISABLED
                                  ? SITE_VISIBILITY_LIVE
                                  : SITE_VISIBILITY_DISABLED
                            });
                            form.setFieldValue("visibility", !field.value);
                          }}
                          outlined
                        />
                      );
                    }}
                  />
                </div>
              </div>
              <div className=" field-wrapper mb-5">
                <Select
                  name="type"
                  label="Type"
                  placeholder="Select Type"
                  options={this.state.typeOptions}
                  defaultValue={
                    this.state.currentValue
                      ? this.state.typeOptions.find(
                          typeOptions =>
                            typeOptions.value == this.state.currentValue.type
                        )
                      : ""
                  }
                  isSearchable={true}
                  error=""
                  required={true}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col text-center">
                <Button type="submit" label="">
                  Save
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );

  render() {
    const { deleteAppSiteModal, deleteAppSite, isLoading, appId } = this.state;

    if (isLoading) {
      return <Spinner />;
    }

    return (
      <>
        {/* List */}
        <div className="mb-5">
          <div className="d-flex align-items-center mb-2">
            <div className="col-12">
              <AddButton
                label="Add New"
                className="pull-right btn btn-secondary mb-3"
                onClick={() => {
                  this.setState({
                    isOpen: true,
                    site_name: "",
                    currentValue: "",
                    hosted_url: "",
                    visibilityToggle: ""
                  });
                  this._toggle();
                }}
              />
              {this.tagModal()}
            </div>
          </div>
          <ReduxTable
            id="appSite"
            apiURL={`${endpoints().appSiteAPI}/search`}
            params={{ appId }}
            // searchPlaceholder="Search"
            // sortByOptions={this.state.sortByOptions}
            // newTableHeading
            // showHeader
            history={this.props.history}
          >
            <ReduxColumn
              width="100px"
              field="site_name"
              disableOnClick
              renderField={row => {
                return (
                  <>
                    <a
                      className="cursor-pointer text-decoration-none"
                      onClick={() => {
                        this.setState({
                          currentValue: row,
                          site_name: row.site_name,
                          hosted_url: row.hosted_url,
                          visibilityToggle: row.visibility
                        });
                        return this._toggle(row.id);
                      }}
                    >
                      {row.site_name}
                    </a>
                    <br></br>
                    {row.site_name && row.type == "Public" ? (
                      <small className="font-weight-bold text-secondary text-decoration-none">
                        <span className="pr-1">
                          <GlobeIcon />
                        </span>
                        Public
                      </small>
                    ) : (
                      <small className="font-weight-bold text-secondary text-decoration-none">
                        <span className="pr-1">
                          <PrivateIcon />
                        </span>
                        Private
                      </small>
                    )}
                  </>
                );
              }}
            >
              Site Name
            </ReduxColumn>
            <ReduxColumn width="120px" field="template" disableOnClick>
              Template
            </ReduxColumn>
            <ReduxColumn width="210px" field="hosted_url" disableOnClick>
              Hosted URL
            </ReduxColumn>
            <ReduxColumn width="100px" field="createdAt" disableOnClick>
              Created
            </ReduxColumn>
            <ReduxColumn width="100px" field="visibility" disableOnClick>
              Visibility
            </ReduxColumn>
            <ReduxColumn
              width="100px"
              field="status"
              className="action-column"
              disableOnClick
              renderField={row => (
                <div className="text-center landing-group-dropdown">
                  <MoreDropdown>
                    <DropdownItem
                      onClick={() => {
                        this.setState({
                          currentValue: row,
                          site_name: row.site_name,
                          hosted_url: row.hosted_url,
                          visibilityToggle: row.visibility
                        });
                        return this._toggle(row.id);
                      }}
                    >
                      Edit
                    </DropdownItem>
                    <DropdownItem
                      className="font-weight-bold text-danger"
                      onClick={() =>
                        this.setState({
                          deleteAppSiteModal: true,
                          deleteAppSite: row
                        })
                      }
                    >
                      Delete
                    </DropdownItem>
                  </MoreDropdown>
                </div>
              )}
            >
              Action
            </ReduxColumn>
          </ReduxTable>
          <DeleteModal
            id="deleteAppSite"
            isOpen={deleteAppSiteModal}
            toggle={() => {
              this.setState({ deleteAppSiteModal: false });
            }}
            title="Delete App Site"
            label={deleteAppSite.site_name}
            deleteFunction={() => this._deleteSiteDetails(deleteAppSite.id)}
          />
        </div>
      </>
    );
  }
}

export default Site;
