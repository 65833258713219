// Api Call
import { apiClient } from "../apiClient";
import { endpoints } from "../configs";

// Config
import { DEFAULT_API_KEY } from "../configs";

// Helper
import { getCookie } from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

// Constants
import {
  REQUEST_PLAYBOOK_LIST,
  RECEIVE_PLAYBOOK_LIST,
  FETCH_PLAYBOOK_LIST_FAIL,
  CHANGE_PLAYBOOK_PAGE,
  RESET_PLAYBOOK_LIST,
  FETCH_APP_LIST,
  FETCH_APP_LIST_FAIL
} from "../actionType/Constants";

/**
 * Request Playbook list
 */
export function requestPlaybookList() {
  return {
    type: REQUEST_PLAYBOOK_LIST
  };
}

/**
 * Receive Playbook list
 */
export function receivePlaybookList(data) {
  return {
    type: RECEIVE_PLAYBOOK_LIST,
    payload: data
  };
}

/**
 * Reset Playbook list
 */
export function resetPlaybookList(data) {
  return {
    type: RESET_PLAYBOOK_LIST,
    payload: data
  };
}

/**
 * Fetch Playbook list fail
 *
 * @param {*} error
 */
export function fetchPlaybookListFail(error) {
  return {
    type: FETCH_PLAYBOOK_LIST_FAIL,
    error
  };
}

/**
 * Fetch Playbook list
 */
export function fetchPlaybookList(params) {
  params = params ? `&${params}` : "";
  return async dispatch => {
    dispatch(requestPlaybookList());
    try {
      if (getCookie(COOKIE_SESSION_TOKEN)) {
        apiClient.defaults.headers.common.Authorization = getCookie(
          COOKIE_SESSION_TOKEN
        );
      } else {
        apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
      }
      const response = await apiClient.get(
        `${endpoints().playbookAPI}?pagination=true${params}`
      );
      const data = response.data;

      return dispatch(receivePlaybookList(data));
    } catch (error) {
      dispatch(fetchPlaybookListFail(error));
      if (error.status >= 400) {
        console.log("Error");
      }
    }
  };
}

/**
 * Reset Playbook list
 */
export function resetPlaybook(params) {
  return dispatch => {
    return dispatch(resetPlaybookList());
  };
}

/**
 *  Change Playbook Page
 *
 * @param {*} payload
 */
export function changePlaybookPage(payload) {
  return { type: CHANGE_PLAYBOOK_PAGE, payload };
}

export function fetchAppList(params) {
  params = params ? `&${params}` : "";
  return async dispatch => {
    dispatch(requestAppList());
    try {
      if (getCookie(COOKIE_SESSION_TOKEN)) {
        apiClient.defaults.headers.common.Authorization = getCookie(
          COOKIE_SESSION_TOKEN
        );
      } else {
        apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
      }
      const response = await apiClient.get(
        `${endpoints().appAPI}/search?pagination=true${params}`
      );
      const data = response.data;

      return dispatch(receiveAppList(data));
    } catch (error) {
      dispatch(fetchAppListFail(error));
      if (error.status >= 400) {
        console.log("Error");
      }
    }
  };
}

function requestAppList() {
  return {
    type: FETCH_APP_LIST
  };
}

function receiveAppList(data) {
  return {
    type: FETCH_APP_LIST,
    payload: data
  };
}

export function fetchAppListFail(error) {
  return {
    type: FETCH_APP_LIST_FAIL,
    error
  };
}
