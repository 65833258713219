import React from "react";
import toast from "../../components/base/Toast";
import moment from "moment";

// Page components
import AddProjectFormFields from "./AddProjectFormFields";

// Components
import PageTitle from "../../components/base/PageTitle";
import Form from "../../components/FormWithErrorHandling";
import CancelButton from "../../components/base/CancelButton";
import Page404 from "../Page404";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Constants
import {
  PROJECT_STATUS_REQUESTED,
  PROJECT_STATUS_DRAFT
} from "../../projectStatus/Constants";
import {
  PLAYBOOK_PRICING_TYPE_FIXED_PRICE,
  TAB_BASICS,
  TAB_DETAILS,
  TAB_PRICING_AND_EXTRAS,
  PROJECT_TYPE_PROJECT,
  PROJECT_TYPE_PROJECT_URL_PATH,
  PROJECT_TYPE_CAMPAIGN,
  PROJECT_TYPE_CAMPAIGN_URL_PATH,
  PROJECT_USER_STATUS_ACCEPTED
} from "../../project/Constants";
import {
  TAG_TYPE_EXPERT_ROLE,
  TAG_TYPE_INDUSTRIES,
  TAG_TYPE_SKILLS,
  TAG_TYPE_TOOLS
} from "../../tagType/Constants";

import { getUrlPath } from "../../lib/helper";

// Context API Call
import MyContext from "../../context/MyContext";
import { isBadRequest } from "../../common/http";

class AddProjectForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      bannerColor: "#75B0CE",
      bannerIcon: "EnvelopeIcon",
      files: [],
      fileCount: "",
      bannerImageUrl: "",
      backButton: "Cancel",
      publishPlaybook: "Next",
      status: PROJECT_STATUS_DRAFT,
      buttonType: "button",
      currentTab: TAB_BASICS,
      nextTab: "",
      playbookIconValidate: true,
      playbookColorValidate: true,
      expertRoles: [],
      skills: [],
      tools: [],
      customerList: [],
      isOpen: false,
      supportingMaterial: "",
      supportingMaterials: [],
      deliverablesValue: "",
      defaultDeliverablesValue: "",
      extrasValue: "",
      projectDetails: "",
      description: "",
      startDate: "",
      id: "",
      partnerId: "",
      isInvalidRequest: false
    };
    this.onColourPickerClick = this.onColourPickerClick.bind(this);
    this.onIconClick = this.onIconClick.bind(this);
    this._handleBannerImagePath = this._handleBannerImagePath.bind(this);
    this.bannerImageUpload = this.bannerImageUpload.bind(this);
    this.backButton = this.backButton.bind(this);
    this.nextButton = this.nextButton.bind(this);
    this.cancelButton = this.cancelButton.bind(this);
  }

  componentDidMount() {
    this._getTagsList();
    this.getCustomers();

    // campaign/project id
    let id = this.props.history.location.pathname.split("/")[3];
    if (id) {
      this.setState({ id: id });
      this.getProjectDetails(id);
    }
  }

  // Create playbook
  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save playbook form in the final
    if (this.state.id) {
      this._updateProject(this._toArray(values));
      this.updateDeliverable(values, this.state.id);
    } else {
      this._createProject(this._toArray(values));
    }
  };

  // Get deliverable values
  getDeliverablesValue = values => {
    this.setState({ deliverablesValue: values });
  };

  // Get default deliverable values
  getDefaultDeliverablesValue = values => {
    this.setState({ defaultDeliverablesValue: values });
  };

  // Get extras values
  getExtrasValue = values => {
    this.setState({ extrasValue: values });
  };

  // Color picker
  onColourPickerClick(e) {
    this.setState({ bannerColor: e.target.id, playbookColorValidate: true });
  }

  // Icon click
  onIconClick(e) {
    this.setState({
      bannerIcon: e.target.className,
      playbookIconValidate: true
    });
  }

  // Back Button
  backButton(e) {
    let tab = e;
    this.setState({ currentTab: tab });
    if (tab === TAB_BASICS) {
      this.setState({ backButton: "Cancel" });
    } else {
      this.setState({ backButton: "Back" });
    }
    if (tab === TAB_PRICING_AND_EXTRAS) {
      this.setState({ publishPlaybook: "Publish" });
    } else {
      this.setState({ publishPlaybook: "Next" });
    }
  }

  // Cancel Button
  cancelButton() {
    if (this.state.backButton === "Cancel") {
      this.props.history.goBack();
    } else {
      const currentTab = this.state.currentTab;
      const currentBasePath = getUrlPath(1);
      const isCampaign = currentBasePath === PROJECT_TYPE_CAMPAIGN_URL_PATH;

      switch (currentTab) {
        case TAB_DETAILS:
          this.backButton(TAB_BASICS);
          break;
        case TAB_PRICING_AND_EXTRAS:
          this.backButton(isCampaign ? TAB_BASICS : TAB_DETAILS);
          break;
      }
    }
  }

  // Set next project tab
  setNextTab = tab => {
    if (tab === TAB_PRICING_AND_EXTRAS) {
      return this.setState({
        currentTab: tab,
        buttonType: "button",
        publishPlaybook: "Publish"
      });
    }

    if (!tab) {
      return this.setState({
        buttonType: "submit",
        status: PROJECT_STATUS_REQUESTED
      });
    }

    return this.setState({
      currentTab: tab,
      buttonType: "button"
    });
  };

  // Next Button
  nextButton() {
    const currentTab = this.state.currentTab;
    const currentBasePath = getUrlPath(1);
    const isCampaign = currentBasePath === PROJECT_TYPE_CAMPAIGN_URL_PATH;

    switch (currentTab) {
      case TAB_BASICS:
        this.setNextTab(isCampaign ? TAB_PRICING_AND_EXTRAS : TAB_DETAILS);
        this.backButton(isCampaign ? TAB_PRICING_AND_EXTRAS : TAB_DETAILS);
        break;
      case TAB_DETAILS:
        this.setNextTab(TAB_PRICING_AND_EXTRAS);
        break;
      case TAB_PRICING_AND_EXTRAS:
        this.setNextTab();
        break;
    }
  }

  // Banner Image Upload
  bannerImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    this.setState({
      bannerImageUrl: URL.createObjectURL(files),
      files
    });
  };

  // Banner Image Remove
  handleImageRemove = () => {
    this.deleteCoverImage();
    this.setState({ bannerImageUrl: "", files: "" });
  };

  // handle name
  handleNameChange = name => {
    this.setState({ name });
  };

  //Handle Description Change
  handleDescriptionChange = description => {
    this.setState({ description: description });
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const name = values.name;

    if (!name) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    const { deliverablesValue, startDate, endDate } = this.state;
    const data = new FormData();
    data.append("name", values.name ? values.name.trim() : "");
    data.append("status", this.state.status);
    data.append("category", values.category ? values.category : "");
    data.append("description", values.description ? values.description : "");
    data.append("customerId", values.customer ? values.customer.value : "");
    data.append("customerName", values.customer ? values.customer.label : "");
    data.append(
      "bannerImagePath",
      this.state.bannerImageUrl ? this.state.bannerImageUrl : ""
    );
    data.append(
      "bannerColor",
      this.state.bannerColor ? this.state.bannerColor : ""
    );
    data.append(
      "bannerIcon",
      this.state.bannerIcon ? this.state.bannerIcon : "EnvelopeIcon"
    );
    data.append("estimatedBudget", values.price ? values.price : "");
    data.append("margin", values.margin ? values.margin : "");
    data.append(
      "startDate",
      startDate ? moment(startDate).format("YYYY-MM-DD") : ""
    );
    data.append("endDate", endDate ? moment(endDate).format("YYYY-MM-DD") : "");
    data.append("autoPayment", values.autoPayment ? values.autoPayment : false);
    data.append("deliverables", values.deliverables ? values.deliverables : "");
    data.append(
      "expertRoles",
      values.expertRoles ? JSON.stringify([values.expertRoles]) : []
    );
    data.append(
      "required_skills",
      values.required_skills ? JSON.stringify(values.required_skills) : []
    );
    data.append(
      "required_industries",
      values.required_industries
        ? JSON.stringify(values.required_industries)
        : []
    );
    data.append("supportingMaterial", this.state.supportingMaterial);
    data.append("file", this.state.files);
    data.append(
      "billingType",
      values.pricingType ? values.pricingType.value : ""
    );
    data.append(
      "paymentType",
      values.pricingSchedule ? values.pricingSchedule.value : ""
    );
    data.append("type", values.type ? values.type : "");

    // Add Project deliverables
    const projectDeliverables = [];
    deliverablesValue &&
      deliverablesValue.forEach(data => {
        if (data.description) {
          projectDeliverables.push({
            description: data.description
          });
        }
      });
    data.append("deliverables", JSON.stringify(projectDeliverables));

    return data;
  }

  // Playbook API Call
  async _createProject(data) {
    try {
      const response = await apiClient.post(endpoints().projectAPI, data);
      let successMessage;
      if (response && response.data) {
        let camapignId = response && response.data.campaignId;
        const data = {
          partnerIds: this.state.partnerId,
          projectId: camapignId,
          status: PROJECT_USER_STATUS_ACCEPTED,
          campaignStatus: response.data.status
        };
        // if campaignId create project user
        if (this.state.partnerId) {
          const createUser = await apiClient.post(
            `${endpoints().projectUserAPI}/partner`,
            data
          );

          if (createUser) {
            successMessage = response.data.message;
          }
        } else {
          successMessage = response.data.message;
        }
      }
      const currentBasePath = getUrlPath(1);
      const currentProjectType =
        currentBasePath === PROJECT_TYPE_PROJECT_URL_PATH
          ? PROJECT_TYPE_PROJECT_URL_PATH
          : currentBasePath === PROJECT_TYPE_CAMPAIGN_URL_PATH
          ? PROJECT_TYPE_CAMPAIGN_URL_PATH
          : PROJECT_TYPE_PROJECT_URL_PATH;

      setTimeout(() => {
        toast.success(successMessage);

        const url =
          this.state.status === PROJECT_STATUS_DRAFT
            ? `/${currentBasePath}s?status=Draft`
            : `/${currentBasePath}s`;
        this.props.history.push(url);
      }, 2000);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  }

  // Project update API Call
  async _updateProject(data) {
    try {
      const response = await apiClient.put(
        `${endpoints().projectAPI}/campaign/${this.state.id}`,
        data
      );
      let successMessage;
      if (response && response.data) {
        // successMessage = response.data.message;
        const data = {
          partnerIds: this.state.partnerId,
          projectId: this.state.id,
          status: PROJECT_USER_STATUS_ACCEPTED,
          campaignStatus: response.data.status
        };
        // if campaignId create project user
        if (this.state.partnerId) {
          const createUser = await apiClient.post(
            `${endpoints().projectUserAPI}/partner`,
            data
          );

          if (createUser) {
            successMessage = response.data.message;
          }
        } else {
          successMessage = response.data.message;
        }
      }

      toast.success(successMessage);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  }

  //update project deliverable
  async updateDeliverable(values, id) {
    const { deliverablesValue } = this.state;
    try {
      if (deliverablesValue && deliverablesValue.length) {
        deliverablesValue.map(async deliverable => {
          if (!deliverable.key) {
            await apiClient.post(`${endpoints().projectDeliverableAPI}`, {
              project_id: id,
              name: deliverable.description
            });
          } else if (deliverable.key == "update-task") {
            await apiClient.put(
              `${endpoints().projectDeliverableAPI}/${deliverable.id}`,
              {
                name: deliverable.description,
                isCampaign: true
              }
            );
          }
        });
      }
    } catch (err) {
      console.log(err);
    }
  }

  // Get Customer Details
  async getCustomers() {
    try {
      const response = await apiClient.get(`${endpoints().customerAPI}`);
      let customerArray = response.data.data.map(company => ({
        value: company.id,
        label: company.displayCompanyName
      }));
      return this.setState({ customerList: customerArray });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  }

  //get tags list
  _getTagsList() {
    this.setState({ isLoading: true }, () => {
      apiClient.get(`${endpoints().playbookTagAPI}`).then(response => {
        const tags = response.data.data;
        const expertRoles = [];
        const skills = [];
        const tools = [];
        const industries = [];
        if (tags && tags.length > 0) {
          tags.forEach(tag => {
            if (tag.type === TAG_TYPE_EXPERT_ROLE) {
              expertRoles.push({
                value: tag.id,
                label: tag.name
              });
            } else if (tag.type === TAG_TYPE_SKILLS) {
              skills.push({
                value: tag.id,
                label: tag.name
              });
            } else if (tag.type === TAG_TYPE_TOOLS) {
              tools.push({
                value: tag.id,
                label: tag.name
              });
            } else if (tag.type === TAG_TYPE_INDUSTRIES) {
              industries.push({
                value: tag.id,
                label: tag.name
              });
            }
          });
        }

        this.setState({
          isLoading: false,
          expertRoles,
          skills,
          tools,
          industries
        });
      });
    });
  }

  // Handle OnChange playbook banner image path
  _handleBannerImagePath = e => {
    const target = e.target;
    const bannerImagePath = target.value;
    this.setState({ bannerImagePath });
  };

  // Handle supporting material
  _handleSupportingMaterial = files => {
    this.setState({ supportingMaterial: files[0] });
  };

  // Handle Start Date
  handleStartDate = date => {
    this.setState({ startDate: date });
  };

  // Handle End Date
  handleEndDate = date => {
    this.setState({ endDate: date });
  };

  // Project details api call by id
  getProjectDetails = async id => {
    return await apiClient
      .get(`${endpoints().projectAPI}/${id}`)
      .then(response => {
        this.setState({
          projectDetails: response.data,
          loading: true,
          desiredStartDate: response.data.desiredStartDate
            ? new Date(response.data.desiredStartDate)
            : ""
        });
        if (id) {
          this.setState({
            name: response.data.name,
            description: response.data.description,
            bannerColor: response.data.bannerColor,
            bannerIcon: response.data.bannerIcon,
            startDate: response.data.startDate,
            endDate: response.data.endDate,
            bannerImageUrl: response.data.bannerImageUrl,
            status: response.data.status
          });
          // get partner id
          if (response.data && response.data.projectPartners) {
            let campaignPartners = [];
            campaignPartners = response.data.projectPartners;
            let partnerIds = [];
            campaignPartners
              .sort((a, b) => a.updatedAt.localeCompare(b.updatedAt))
              .forEach(result => {
                partnerIds.push(result.partnerId);
              });
            if (partnerIds) {
              let partnerId = partnerIds[partnerIds.length - 1];
              this.setState({ partnerId: partnerId });
            }
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Delete project tasks / deliverable by deliverable id
  deleteProjectTasks = async id => {
    const projectId = this.state.id;
    return await apiClient
      .delete(`${endpoints().projectDeliverableAPI}/${id}`)
      .then(res => {
        let successMessage = res.data.message;
        toast.success(successMessage);
        this.getProjectDetails(projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Delete project Image
  deleteCoverImage = () => {
    const projectId = this.state.id;
    return apiClient
      .delete(`${endpoints().projectAPI}/coverImage/${projectId}`)
      .then(res => {
        let successMessage = res.data.message;
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.stringify(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // handlePartnerChange
  handlePartnerChange = value => {
    this.setState({ partnerId: value });
  };

  render() {
    const { expertRoles, skills, tools, industries } = this.state;

    const headerButton = (
      <div className="btn-wrapper mt-2 mb-4">
        <button
          onClick={() => {
            this.setState({ status: PROJECT_STATUS_DRAFT });
          }}
          className="btn btn-outline-dark pr-2 mr-1"
          style={{ boxShadow: "none" }}
        >
          Save as Draft
        </button>
        <button
          type={this.state.buttonType}
          className="btn btn-secondary pr-2"
          style={{ boxShadow: "none" }}
          onClick={this.nextButton}
        >
          {this.state.publishPlaybook}
        </button>
      </div>
    );

    const {
      playbookIconValidate,
      playbookColorValidate,
      isOpen,
      currentTab,
      supportingMaterials,
      customerList,
      projectDetails,
      partnerId,
      isInvalidRequest
    } = this.state;

    const pricingType = {
      value: PLAYBOOK_PRICING_TYPE_FIXED_PRICE,
      label: PLAYBOOK_PRICING_TYPE_FIXED_PRICE
    };

    const currentBasePath = getUrlPath(1);
    const currentProjectType =
      currentBasePath === PROJECT_TYPE_PROJECT_URL_PATH
        ? PROJECT_TYPE_PROJECT
        : currentBasePath === PROJECT_TYPE_CAMPAIGN_URL_PATH
        ? PROJECT_TYPE_CAMPAIGN
        : PROJECT_TYPE_PROJECT;

    const initialValues = {
      name: this.state.name ? this.state.name : "",
      description: this.state.description || "",
      bannerImageUrl: this.state.bannerImageUrl || "",
      fileCount: "",
      startDate: this.state.startDate,
      endDate: this.state.endDate,
      pricingType
    };

    if (isInvalidRequest) {
      return <Page404 />;
    }

    // campaign/project id
    let id = this.props.history.location.pathname.split("/")[3];

    return (
      <>
        <Form
          initialValues={initialValues}
          enableReinitialize={true}
          onSubmit={values => {
            values.type = currentProjectType;
            this._submit(values);
          }}
          showErrorToast={true}
        >
          <div className={`d-flex justify-content-between`}>
            <PageTitle
              label={
                id ? `Edit ${currentProjectType}` : `New ${currentProjectType}`
              }
            />
            {headerButton && headerButton}
          </div>
          <AddProjectFormFields
            projectName={this.state.name}
            colorValidate={playbookColorValidate}
            iconValidate={playbookIconValidate}
            onColourPickerClick={this.onColourPickerClick}
            onIconClick={this.onIconClick}
            bannerColor={this.state.bannerColor}
            bannerIcon={this.state.bannerIcon}
            handleBannerImagePath={this._handleBannerImagePath}
            projectCategory={this.state.projectCategory}
            bannerImageUpload={e => this.bannerImageUpload(e)}
            bannerImageUrl={this.state.bannerImageUrl}
            handleImageRemove={this.handleImageRemove}
            backButton={e => this.backButton(e)}
            currentTab={currentTab}
            expertRoles={expertRoles}
            handleSupportingMaterial={this._handleSupportingMaterial}
            supportingMaterials={supportingMaterials}
            isOpen={isOpen}
            skills={skills}
            tools={tools}
            industries={industries}
            customerList={customerList}
            getDeliverablesValue={this.getDeliverablesValue}
            getDefaultDeliverablesValue={this.getDefaultDeliverablesValue}
            getExtrasValue={this.getExtrasValue}
            handleStartDate={this.handleStartDate}
            handleEndDate={this.handleEndDate}
            currentProjectType={currentProjectType}
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            id={id}
            task={this.state.projectDetails.tasks}
            handleNameChange={this.handleNameChange}
            handleDescriptionChange={this.handleDescriptionChange}
            campaignId={id}
            deleteTask={this.deleteProjectTasks}
            history={this.props.history}
            partnerId={partnerId}
            handlePartnerChange={e => {
              this.handlePartnerChange(e);
            }}
          />

          {/* Show Cancel Button */}
          {currentTab !== TAB_BASICS ? (
            <CancelButton
              name={this.state.backButton}
              onClick={this.cancelButton}
            />
          ) : (
            ""
          )}

          <div className="page-heading d-flex justify-content-between float-right">
            {headerButton && headerButton}
          </div>
        </Form>
      </>
    );
  }
}

export default AddProjectForm;
