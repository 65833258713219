import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { useDispatch } from "react-redux";
import { endpoints } from "../../../../configs";
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import { DropdownItem } from "reactstrap";
import MoreDropdown from "../../../../components/base/MoreDropdown";
import { MEDIA_CERTIFICATE_PHOTO } from "../../../marketplaceSourcing/Constants";
import { toast } from "react-toastify";
import { apiClient } from "../../../../apiClient";
import { isBadRequest } from "../../../../common/http";
import PageTitle from "../../../../components/base/PageTitle";
import Text from "../../../../components/base/Text";
import Form from "../../../../components/base/Form";
import { API_URL } from "../../../../config/Constants";
import { fetchList } from "../../../../actions/table";
import AvatarCard from "../../../../components/base/AvatarCard";
import Status from "../../../../helpers/Certificate";
import CertificateService from "../../../../services/certificateService";
import DeleteModal from "../../../../components/base/DeleteModal";

const CertificateTab = props => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [name, setName] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [deleteContactModal, setDeleteContactModal] = useState(false);

  const [base64Data, setBase64Data] = useState("");
  const [rowValue, setRow] = useState("");

  const status = {
    ACTIVE: "ACTIVE",
    INACTIVE: "INACTIVE"
  };
  const statusOptions = [
    {
      value: status.ACTIVE,
      label: status.ACTIVE
    },
    {
      value: status.INACTIVE,
      label: status.INACTIVE
    }
  ];

  const [isFileExist, setIsFileExist] = useState("");

  const _createModaltoggle = () => {
    setName("");
    setFile("");
    setFileName("");
    setIsOpen(!isOpen);
    setRow("");
    setIsFileExist("");
  };

  const openAddModal = () => {
    setName("");
    setFile("");
    setFileName("");
    _createModaltoggle();
  };

  // handle delete contact
  const handleDelete = () => {
    CertificateService.delete(rowValue.id, res => {
      if (res) {
        dispatch(
          fetchList(
            "certificate",
            `${endpoints().certificateApi}/search`,
            1,
            10
          )
        );
        setDeleteContactModal(false);
      }
    });
  };
  //Set Media
  const media = e => {
    const data = e.target.files ? e.target.files[0] : "";
    if (data) {
      setFile(data);
      setFileName(data.name);

      // Convert to Base64
      const reader = new FileReader();

      reader.onloadend = () => {
        setBase64Data(reader.result);
      };
      reader.readAsDataURL(data);
    } else {
      setFile(null);
      setFileName("");
      setBase64Data("");
    }
  };
  const upload = () => {
    if (!rowValue.imageUrl && !file) {
      setIsFileExist("Image is required");
      return;
    }
    const data = {};

    data.name = name;
    data.file = base64Data;
    data.fileName = fileName;
    data.object = MEDIA_CERTIFICATE_PHOTO;
    mediaUpload(data);
    setFile("");
  };

  const mediaUpload = async values => {
    const data = new FormData();
    if (!rowValue.id) {
      data.append("name", values && values.name ? values.name : "");

      data.append("uploadFile", values && values.file ? values.file : "");
      data.append(
        "uploadFileName",
        values && values.fileName ? values.fileName : ""
      );
      data.append("object", MEDIA_CERTIFICATE_PHOTO);

      dispatch(
        await CertificateService.create(data, res => {
          if (res) {
            _createModaltoggle();
          }
        })
      );
    } else {
      data.append("name", values && values.name ? values.name : rowValue.name);

      data.append("uploadFile", values && values.file ? values.file : "");
      data.append(
        "uploadFileName",
        values && values.fileName ? values.fileName : ""
      );
      data.append("object", MEDIA_CERTIFICATE_PHOTO);
      data.append("id", rowValue.id);

      dispatch(
        await CertificateService.update(data, res => {
          if (res) {
            _createModaltoggle();
          }
        })
      );
    }
  };

  const initialValues = {
    name: (rowValue && rowValue.name) || ""
  };
  return (
    <>
      <div>
        <DeleteModal
          isOpen={deleteContactModal}
          toggle={() => {
            setDeleteContactModal(prevState => !prevState);
          }}
          title="Delete"
          label={rowValue.name}
          deleteFunction={handleDelete}
        />
        <Modal
          isOpen={isOpen}
          toggle={_createModaltoggle}
          className="landing-create-popup w-100"
          backdrop="static"
        >
          <ModalHeader
            toggle={_createModaltoggle}
            cssModule={{ "modal-name": "w-100 text-center" }}
          >
            <p>{rowValue.id ? "Edit" : "Add"}</p>
          </ModalHeader>
          <Form initialValues={initialValues}>
            <ModalBody className="ml-3 mr-3 mt-2 mb-3">
              <div>
                <Text
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                  error=""
                  onChange={e => {
                    setName(e.target.value);
                  }}
                />
              </div>

              <div className="">
                <input
                  name="file"
                  className="form-control d-none"
                  type="file"
                  id="photo"
                  onChange={e => {
                    media && media(e);
                  }}
                  accept="image/png, image/gif, image/jpeg"
                />
                <span className="profilePicOverlay d-block ">
                  <label htmlFor="photo" className="profile-img-sm mb-0">
                    <span className="banner-image-upload-link">
                      Upload Image
                    </span>
                    <span className="text-danger ml-1">*</span>
                  </label>

                  {/* Show image Name */}
                  {(file || rowValue.imageUrl) && (
                    <span className="pl-3">
                      {" "}
                      <span className="pr-3">
                        <img
                          src={
                            file ? URL.createObjectURL(file) : rowValue.imageUrl
                          }
                          alt="Selected File"
                          className="img-thumbnail"
                          style={{ maxWidth: "150px", maxHeight: "150px" }}
                        />
                      </span>
                    </span>
                  )}
                  {(!rowValue.imageUrl || isFileExist) && (
                    <small className="text-danger position-absolute">
                      {isFileExist}
                    </small>
                  )}
                </span>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="container-fluid">
                <div className="col-sm-12 text-center">
                  <Button
                    label=""
                    onClick={e => {
                      upload(e);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
      <PageTitle
        label="Certificate"
        buttonHandler={() => {
          openAddModal();
        }}
        buttonLabel="Add New"
      />
      <div className="p-0 col-12 col-md-12">
        <ReduxTable
          id="certificate"
          newTableHeading
          noRecordFoundHeight={"7vh"}
          apiURL={`${endpoints().certificateApi}/search`}
          searchPlaceholder="Search"
          paramsToUrl={true}
          history={props.history}
          statusOptions={statusOptions}
          sortByDropdown
        >
          <ReduxColumn
            width="150px"
            field="name"
            sortBy="name"
            renderField={row => (
              <>
                <div className="cursor-pointer">
                  <AvatarCard
                    companyName={row.name}
                    url={row && row.imageUrl}
                  />
                </div>
              </>
            )}
          >
            Name
          </ReduxColumn>

          <ReduxColumn
            width="150px"
            field="status"
            sortBy="name"
            isClickable="true"
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            disableOnClick
            className="text-center"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      openAddModal();
                      setRow(row);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  {row.status == Status.IN_ACTIVE_TEXT ? (
                    <DropdownItem
                      onClick={async () =>
                        await CertificateService.updateStatus(
                          row.id,
                          Status.ACTIVE,
                          res => {
                            if (res) {
                              dispatch(
                                fetchList(
                                  "certificate",
                                  `${endpoints().certificateApi}/search`,
                                  1,
                                  10
                                )
                              );
                            }
                          }
                        )
                      }
                    >
                      Make Active
                    </DropdownItem>
                  ) : (
                    <DropdownItem
                      onClick={async () =>
                        await CertificateService.updateStatus(
                          row.id,
                          Status.IN_ACTIVE,
                          res => {
                            if (res) {
                              dispatch(
                                fetchList(
                                  "certificate",
                                  `${endpoints().certificateApi}/search`,
                                  1,
                                  10
                                )
                              );
                            }
                          }
                        )
                      }
                    >
                      Make InActive
                    </DropdownItem>
                  )}
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setDeleteContactModal(true);
                      setRow(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};

export default CertificateTab;
