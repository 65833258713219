import React from "react";
import { DownloadIcon, ImageIcon, FileIcon } from "../../../assets/img/icons";
import { isExpert } from "../../../lib/helper";
import { PROJECT_USER_STATUS_ACCEPTED } from "../../../project/Constants";
import { useSelector } from "react-redux";

const TrainingSupportMaterial = props => {
  const { onMaterialUpload, data, userStatus, addMaterial } = props;
  const imageType = ["jpg", "jpeg", "gif", "png"];
  const currentUser = useSelector(state => state.user);

  return (
    <div>
      <div className="supporting-materials">
        {data && data.length
          ? data.map(material => (
              <div className="supporting-material">
                <div className="icon">
                  {imageType.includes(material.name.split(".").slice(-1)[0]) ? (
                    <ImageIcon />
                  ) : (
                    <FileIcon />
                  )}
                </div>
                <div className="name ellipsis mr-1">{material.name}</div>
                <div className="action">
                  <a href={material.fileUrl} download={material.name}>
                    <DownloadIcon />
                  </a>
                </div>
              </div>
            ))
          : ""}
      </div>

      <div
        className={[
          "btn-wrapper mt-3",
          `${
            isExpert(currentUser && currentUser.roleId) &&
            userStatus &&
            userStatus !== PROJECT_USER_STATUS_ACCEPTED
              ? "disabled"
              : ""
          }`
        ].join(" ")}
      >
        {addMaterial && (
          <>
            <input
              name="file"
              className="form-control d-none"
              type="file"
              id="projectMaterial"
              onChange={e => onMaterialUpload(e)}
              accept=".docx, .doc, application/*, image/*, text/*"
            />
            <label htmlFor="projectMaterial" className="btn btn-primary w-100">
              Add Supporting Materials
            </label>
          </>
        )}
      </div>
    </div>
  );
};

export default TrainingSupportMaterial;
