import React from "react";
import { Modal, ModalBody, ModalHeader, Input } from "reactstrap";

import Label from "../../../../components/base/Label";

const AccountSelectionModal = props => {
  const {
    isAccountSelectedModalOpen,
    referralDetails,
    selectedHubSpotCompany,
    setAccountSelectionModalValues,
    isAccountSelected,
    createAccount,
    hubSpotCompanyType
  } = props;
  return (
    <Modal
      isOpen={isAccountSelectedModalOpen}
      className={["log-hours-modal", "edit-task-modal"].join(" ")}
    >
      <ModalHeader
        toggle={() => {
          setAccountSelectionModalValues({
            isSelectedHubSpotCompanyConfirmed: false,
            isHubSpotPartialMatchConfirmationModalOpen: false,
            isAccountSelected: false
          });
        }}
      >
        <h4 className={["font-weight-bold", "mt-3"].join(" ")}>
          Check Partial Matches
        </h4>
      </ModalHeader>
      <ModalBody className={["text-center", "mb-4"].join(" ")}>
        <div className="d-block">
          <h6 className={["font-weight-bold"].join(" ")}>
            Please Confirm the Company Name You Want to Use
          </h6>
          <div className="text-muted">
            <span style={{ fontSize: "11px" }}>
              This step will confirm that you’ve checked for partial matches on
              HubSpot.
            </span>
          </div>
          <div className="text-left mt-4">
            <div className="d-flex align-items-center justify-content-center">
              <div>
                {selectedHubSpotCompany && selectedHubSpotCompany.label && (
                  <div className="form-wrapper">
                    <div>
                      <Label>
                        <Input
                          type="radio"
                          name="user_radio"
                          className="radio-button"
                          onClick={() => {
                            setAccountSelectionModalValues({
                              isSelectedHubSpotCompanyConfirmed: true,
                              isAccountSelected: true,
                              isHubSpotPartialMatchConfirmationModalOpen: true
                            });
                          }}
                        />
                        <p className="d-flex align-items-center justify-content-start">
                          <span className="radio-placeholder mr-2" />
                          <span className="d-block text-left">
                            <span className="d-block font-weight-bold">
                              {selectedHubSpotCompany.label
                                ? selectedHubSpotCompany.label
                                : ""}
                            </span>

                            <span
                              className="d-block h7 text-muted"
                              style={{ fontSize: "11px" }}
                            >
                              Company name matched in HubSpot
                            </span>
                          </span>
                        </p>
                      </Label>
                    </div>
                  </div>
                )}

                <div className="form-wrapper">
                  <div>
                    <Label>
                      <Input
                        type="radio"
                        name="user_radio"
                        className="radio-button"
                        onClick={() => {
                          setAccountSelectionModalValues({
                            isSelectedHubSpotCompanyConfirmed: false,
                            isAccountSelected: true,
                            isHubSpotPartialMatchConfirmationModalOpen: true
                          });
                        }}
                      />
                      <p className="d-flex align-items-center justify-content-start">
                        <span className="radio-placeholder mr-2" />
                        <span className="d-block text-left">
                          <span className="d-block font-weight-bold">
                            {hubSpotCompanyType === "Company (Prospect)"
                              ? referralDetails && referralDetails.account_name
                                ? referralDetails.account_name
                                : ""
                              : hubSpotCompanyType === "Company (Partner)"
                              ? referralDetails &&
                                referralDetails.partner &&
                                referralDetails.partner.company_name
                                ? referralDetails.partner.company_name
                                : ""
                              : ""}
                          </span>

                          <span
                            className="d-block h7 text-muted"
                            style={{ fontSize: "11px" }}
                          >
                            HubSpot name entered by Partner
                          </span>
                        </span>
                      </p>
                    </Label>
                  </div>
                </div>
              </div>
            </div>
            <div className="text-center btn-wrapper">
              <button
                className="btn btn-outline-secondary px-3"
                onClick={() => {
                  setAccountSelectionModalValues({
                    isSelectedHubSpotCompanyConfirmed: false,
                    isAccountSelected: false,
                    isHubSpotPartialMatchConfirmationModalOpen: false,
                    isHubSpotProspectAccountSelectModalOpen:
                      hubSpotCompanyType === "Company (Prospect)"
                        ? true
                        : false,
                    isHubSpotPartnerAccountSelectModalOpen:
                      hubSpotCompanyType === "Company (Partner)" ? true : false
                  });
                }}
              >
                Go Back
              </button>
              <button
                className="btn btn-primary px-3"
                onClick={() => {
                  setAccountSelectionModalValues({
                    isHubSpotPartialMatchConfirmationModalOpen: false
                  });

                  createAccount(hubSpotCompanyType);
                }}
                disabled={!isAccountSelected}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AccountSelectionModal;
