import React, { useEffect, useState } from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// calendar component
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "react-big-calendar/lib/css/react-big-calendar.css";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";

// Components
import Text from "./Text";
import DateInput from "./Date";
import PageTitle from "../../components/base/PageTitle";
import Spinner from "../../components/base/Spinner";
import AddButton from "../../components/base/AddButton";
import toast from "../../components/base/Toast";
import Form from "../../components/base/Form";
import PartnerDropDown from "../../components/PartnerDropDown";

// Constants
import { PROJECT_TYPE_CAMPAIGN } from "../../project/Constants";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

import { useSelector } from "react-redux";
import SystemName from "../../Constants/SystemName";
import Terminology from "../../lib/Terminology";

// calendar localizer
const localizer = momentLocalizer(moment);
const CALENDAR_EVENT = "event";

const EventCalendar = props => {
  const { history } = props;

  // state
  const [isLoading, setIsLoading] = useState(false);
  const [camapaignList, setCampaignList] = useState([]);
  const [activityList, setActivityList] = useState([]);
  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [partnerId, setPartnerId] = useState("");

  const currentUser = useSelector(state => state.user);

  // useState
  useEffect(() => {
    setIsLoading(true);
    getCampaginsList();
    getActivityList();
  }, []);

  // Toggle
  const toggle = () => {
    setModal(!modal);
    setStartDate("");
    setPartnerId("");
  };

  // handle partner change
  const handlePartnerChange = value => {
    setPartnerId(value.id);
  };

  // handle select date
  const setDate = date => {
    setStartDate(date);
  };

  // Calendar layout
  const calenderLayout = {
    height: 600,
    margin: "30px"
  };

  // Get campaign list
  const getCampaginsList = () => {
    apiClient
      .get(`${endpoints().projectAPI}/search`)
      .then(response => {
        let campignList = [];
        if (response.data) {
          response.data.data.forEach(element => {
            // if project is campaign
            if (element.type === PROJECT_TYPE_CAMPAIGN) {
              // campaign have start and end date
              if (element.start_date || element.end_date) {
                campignList.push({
                  id: element.id,
                  title: element.name,
                  start: new Date(element.start_date),
                  end: new Date(element.end_date),
                  isCampaign: true,
                  navigate: true
                });
              }
            }
          });
          setCampaignList(campignList);
        }
        setIsLoading(false);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          setIsLoading(false);
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.log(errorMessage);
        }
      });
  };

  // Get activity list
  const getActivityList = () => {
    apiClient
      .get(`${endpoints().activity}`)
      .then(response => {
        let activityList = [];
        if (response && response.data) {
          let list = response.data.data;
          list &&
            list.forEach(result => {
              // foreach activity
              if (result && result.activity_type === CALENDAR_EVENT) {
                // push the activity details
                activityList.push({
                  id: result.id,
                  title: result.notes,
                  start: new Date(result.activityAtFormatted),
                  end: new Date(result.activityAtFormatted)
                });
              }
            });
          setActivityList(activityList);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
        }
      });
  };

  // Create partner activity
  const partnerActivityCreate = async (data, toggle) => {
    try {
      return await apiClient.post(endpoints().activity, data).then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        setTimeout(() => {
          getActivityList();
          toggle();
        }, 300);
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };

  // Navigate root for campaigns
  const onSelectEvent = (event, callback) => {
    let root;
    if (event && event.id && event.navigate) {
      root = history.push(`/campaign/${event.id}`);
    }
    return callback(root);
  };

  // Pass color for events
  const eventStyleGetter = (event, callback) => {
    const backgroundColor = event.isCampaign
      ? "rgba(255, 0, 0, 0.5)"
      : "rgba(0, 0, 255, 0.5)";
    const fontSize = "10px";
    const display = "block";
    const cursorPointer = !event.isCampaign ? "default" : "";
    let style = {
      backgroundColor: backgroundColor,
      fontSize: fontSize,
      display: display,
      cursor: cursorPointer
    };
    return callback(style);
  };

  // spinner
  if (isLoading) {
    return <Spinner />;
  }

  return (
    <div>
      {/* page heading */}
      <div className="d-flex justify-content-between mb-3">
        <PageTitle label={Terminology.get(SystemName.COMARKETING_CAMPAIGNS)} />
        {/* evenet button */}
        {(isSuperAdmin(currentUser && currentUser.roleId) ||
          isCompanyAdmin(currentUser && currentUser.roleId) ||
          isCompanyManager(currentUser && currentUser.roleId)) && (
          <div>
            <AddButton
              label="New Event"
              onClick={() => {
                setModal(true);
              }}
              className="pull-right btn btn-secondary mt-2"
            />
          </div>
        )}
        <Modal
          isOpen={modal}
          toggle={toggle}
          backdrop="static"
          className={["edit-task-modal"].join(" ")}
        >
          <Form
            initialValues={{
              activityNotes: "",
              startDate: "",
              activityPartner: ""
            }}
            onSubmit={values => {
              const data = new FormData();
              data.append(
                "activityNotes",
                values.activityNotes ? values.activityNotes : ""
              );
              data.append("startDate", startDate ? startDate : null);
              data.append("activityType", CALENDAR_EVENT);
              data.append("partnerId", partnerId ? partnerId : null);
              partnerActivityCreate(data, toggle);
            }}
          >
            <ModalHeader toggle={toggle}>
              <h4 className={["font-weight-bold"].join(" ")}>Add Activity</h4>
            </ModalHeader>
            <ModalBody className={["mb-4"].join(" ")}>
              <div className="form-wrapper">
                <div>
                  <Text
                    name="activityNotes"
                    label="Activity"
                    placeholder="Enter Activity"
                    required
                    error=""
                  />
                </div>
              </div>
              <div className="field-wrapper w-100">
                <PartnerDropDown
                  name={"activityPartner"}
                  label={"Select Partner"}
                  handleChange={handlePartnerChange}
                  required={true}
                />
              </div>
              <div className="grouped-fields mt-4">
                <div className="">
                  <label>
                    Activity Date <span className="text-danger">*</span>
                  </label>
                  <br></br>
                  <DateInput
                    selected={startDate}
                    name="startDate"
                    className="datepicker"
                    placeholder="MM-DD-YYYY"
                    required
                    minDate={new Date()}
                    onChange={setDate}
                    format="MM-dd-yyyy"
                    width={"150px"}
                    value={startDate}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter className={["justify-content-center"].join(" ")}>
              <div className="btn-wrapper">
                <Button label="" type="submit" className="btn btn-primary">
                  Add
                </Button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>

      {/* calendar display */}
      <div className="card mx-auto h-100">
        <Calendar
          localizer={localizer}
          events={activityList.concat(camapaignList)}
          defaultDate={new Date()}
          startAccessor="start"
          endAccessor="end"
          style={calenderLayout}
          onSelectEvent={event => {
            let naviagtion;
            onSelectEvent(event, response => {
              naviagtion = response;
            });
            return naviagtion;
          }}
          eventPropGetter={event => {
            let styles;
            eventStyleGetter(event, response => {
              styles = response;
            });
            return { style: styles };
          }}
        />
      </div>
    </div>
  );
};

export default EventCalendar;
