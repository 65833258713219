import React, { useState, useEffect } from "react";
import { Label } from "reactstrap";
import { Link } from "react-router-dom";
import AdditionalContactList from "./AdditionalContactList";
import SidebarItem from "./SidebarItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

function AdditionalContact(props) {
  const {
    contactArray,
    onClick,
    EditContactToggle,
    setEditContactData
  } = props;

  // Use state
  const [VisibleContacts, setVisibleContact] = useState(1);
  const [showHr, setHr] = useState(false);

  useEffect(() => {
    setVisibleContact(1);
  }, [contactArray]);

  // Show contact list
  const showContacts = length => {
    length && setVisibleContact(length);
    setHr(true);
  };
  // Show less contact list
  const LessContacts = length => {
    length && setVisibleContact(1);
    setHr(false);
  };

  return (
    <>
      <SidebarItem>
        <div className="row d-flex justify-content-between">
          <div className="col-8">
            <Label className="font-weight-bold mb-4">Additional Contacts</Label>
          </div>
          <div className="col-4 font-weight-bold" onClick={onClick}>
            <Link to="#">
              <FontAwesomeIcon icon={faPlus} style={{ fontSize: "12px" }} />{" "}
              <u>Add</u>
            </Link>
          </div>
        </div>
        <div
          style={{
            maxHeight: "876px",
            position: "relative"
          }}
        >
          <div className="card h-90">
            {contactArray && contactArray.length > 0 ? (
              <>
                <div
                  className="card"
                  style={{
                    boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
                    padding: "0px"
                  }}
                >
                  {/* Show contact list */}
                  {contactArray &&
                    contactArray.length > 0 &&
                    contactArray.slice(0, VisibleContacts).map(data => {
                      return (
                        <>
                          <>
                            <div className="single-activity accordion accordion-flush">
                              <div className="accordion-item">
                                <AdditionalContactList
                                  data={data}
                                  toggle={EditContactToggle}
                                  setEditContactData={setEditContactData}
                                />
                              </div>
                            </div>
                          </>
                          {showHr && <hr />}
                        </>
                      );
                    })}
                </div>
                <div
                  className="card"
                  style={{
                    boxShadow: "0 0 0 0 rgba(0, 0, 0, 0)",
                    padding: "0px"
                  }}
                >
                  {/* Show more option to view more contacts */}
                  {contactArray && contactArray.length > VisibleContacts && (
                    <div className="col-12">
                      <p
                        className="cursor-pointer"
                        onClick={() => {
                          showContacts(contactArray.length);
                        }}
                        style={{ color: "primary" }}
                      >
                        <p style={{ color: "#009DDA", fontSize: 12 }}>
                          Show all contacts...
                        </p>
                        <i
                          className="fa pull-right pt-1"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  )}
                  {/* Show less option to view less contacts */}
                  {contactArray && VisibleContacts > 1 ? (
                    <div className="">
                      <p
                        className="cursor-pointer"
                        onClick={() => {
                          LessContacts(contactArray.length);
                        }}
                        style={{ color: "primary" }}
                      >
                        <p style={{ color: "#009DDA", fontSize: 12 }}>
                          Show less contacts...
                        </p>
                        <i
                          className="fa pull-right pt-1"
                          aria-hidden="true"
                        ></i>
                      </p>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </>
            ) : (
              <span className="text-grayed">
                No additional contacts added yet.
              </span>
            )}
          </div>
        </div>
      </SidebarItem>
    </>
  );
}

export default AdditionalContact;
