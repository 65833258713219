import React from "react";
import ExpertRequestQuestion from "./ExpertRequestQuestion";

// Compoenents
import SecondaryButton from "../../components/base/SecondaryButton";

// Assets
import { ProjectTypeIcon } from "../../assets/img/icons";

class ProjectType extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      projectTypeOptions: [
        {
          value: "Implementation/New Project",
          label: "Implementation/New Project",
          tooltip: ""
        },
        {
          value: "Integration or Data Migration",
          label: "Integration or Data Migration",
          tooltip: ""
        },
        {
          value: "Campaign Optimization",
          label: "Campaign Optimization",
          tooltip: ""
        },
        {
          value: "Custom Scoped Project/Other",
          label: "Custom Scoped Project/Other",
          tooltip: ""
        },
        {
          value:
            "None of the Above, I'm interested in learning about Torchlite",
          label:
            "None of the Above, I'm interested in learning about Torchlite",
          tooltip: ""
        }
      ]
    };
  }

  render() {
    const { currentStep, step, prev, next } = this.props;
    const { projectTypeOptions } = this.state;

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <ProjectTypeIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-4">Project Type</h2>
        </div>
        <div className="subtitle-wrapper mt-4">
          <h5 className="font-weight-light">What type of project?</h5>
        </div>
        <div className="content">
          <ExpertRequestQuestion
            name="projectType"
            options={projectTypeOptions}
            error="Project type is required"
            required
          />

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={next}
            >
              Next: Timeline
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ProjectType;
