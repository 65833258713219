import React, { useEffect, useState } from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import SaveButton from "../../../../components/base/SaveButton";

import TimeZoneSelect from "../../../../components/TimeZoneSelect";
import DateTime from "../../../../lib/DateTime";
import { TIME_ZONE } from "../../../../setting/Constants";

const SettingsSection = props => {
  const { adminSettings, saveSettings } = props;
  const [timeZones, setTimeZone] = useState([]);
  useEffect(() => {
    getTimeZones();
  }, []);

  const getTimeZones = () => {
    try {
      let timeZones = DateTime.getTimeZones();
      let timeZoneList = new Array();
      for (let i = 0; i < timeZones.length; i++) {
        timeZoneList.push({
          label: timeZones[i],
          value: timeZones[i]
        });
      }
      setTimeZone(timeZoneList);
    } catch (err) {
      console.log(err);
    }
  };
  const { time_zone } = adminSettings;

  // General Settings Social Section Initial Values
  const initialValues = {
    time_zone: timeZones.find(data => data.value === time_zone)
  };

  // General Settings Social Section Submit Form
  const submit = values => {
    try {
      const data = new FormData();

      //Youtube_url
      if (values && values.time_zone !== undefined) {
        data.append(
          TIME_ZONE,
          values && values.time_zone && values.time_zone.value
            ? values.time_zone.value
            : null
        );
      }

      // Save settings
      saveSettings(data);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <Form
      initialValues={initialValues}
      enableReinitialize
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          <TimeZoneSelect name={TIME_ZONE} label="Time Zone" />
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default SettingsSection;
