import React from "react";
import {
  TAG_PERMISSION_SELECT_AND_ADD_VALUE,
  TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
} from "../../tag/Constants";
import { isSuperAdmin, isCompanyAdmin } from "../../lib/helper";
import DefaultContent from "../content/DefaultContent";
import MultiSelect from "../base/Multiselect";
import MultiselectCreatable from "../base/MultiselectCreatable";
import { useSelector } from "react-redux";

const PartnerProfileTagType = ({ id, name, permission, tags }) => {
  let tagList = [];
  let sort = [];
  tags &&
    tags.length &&
    tags.forEach(tag => {
      sort.push(tag.sort);
    });
  let sortValue = sort[0];
  let sortParam;
  if (sortValue == null) {
    sortParam = "name";
  } else sortParam = "sort";
  tags &&
    tags
      .sort((a, b) =>
        (a[`${sortParam}`] || "")
          .toString()
          .localeCompare((b[`${sortParam}`] || "").toString())
      )
      .forEach(tag => {
        tagList.push({
          id: tag.id,
          name: tag.name,
          value: tag.value,
          label: tag.label,
          sort: tag.sort,
          tagTypeId: tag.tagTypeId
        });
      });

  const currentUser = useSelector(state => state.user);

  return (
    <>
      {isSuperAdmin(currentUser && currentUser.roleId) ||
      isCompanyAdmin(currentUser && currentUser.roleId) ||
      permission === TAG_PERMISSION_SELECT_AND_ADD_VALUE ? (
        <DefaultContent title={name}>
          <div className="field-wrapper">
            <MultiselectCreatable
              name={name}
              // label={description}
              placeholder={`Select an ${name}...`}
              options={tagList}
              closeMenuOnSelect={false}
            />
          </div>
        </DefaultContent>
      ) : (
        permission === TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE && (
          <DefaultContent title={name}>
            <div className="field-wrapper">
              <MultiSelect
                name={name}
                placeholder={`Select an ${name}...`}
                options={tagList}
              />
            </div>
          </DefaultContent>
        )
      )}
    </>
  );
};

export default PartnerProfileTagType;
