import React, { useEffect, useState } from "react";
import UserContext from "../../context/userContext/UserContext";
import Select from "../../components/base/Select";
import Form from "../../components/base/Form";

const CompanyDropdown = React.memo(function CompanyDropdown(props) {
  const {
    headerTextColor,
    headerColor,
    companyList,
    handleCompanyChange,
    id
  } = props;

  const [currentCompanyId, setCurrentCompanyId] = useState("");
  const [currentCompanyName, setCurrentCompanyName] = useState("");

  const getCompanyNameById = id => {
    if (currentCompanyId != id) setCurrentCompanyId(id);
    if (companyList.length > 0)
      companyList.forEach(companyDetails => {
        if (companyDetails.value == id) {
          setCurrentCompanyName(companyDetails.label);
          return companyDetails.label;
        }
      });
    else return "Company";
  };

  useEffect(() => {
    getCompanyNameById(currentCompanyId);
  }, [currentCompanyId]);

  return (
    <div className="dropdown-wrapper ml-auto mr-2" style={{ zIndex: "13" }}>
      <UserContext.Consumer>
        {context => (
          <>
            <Form>
              {context.userLoggedIn ? (
                <Select
                  id={id}
                  name="Company"
                  placeholder="Company"
                  defaultValue={companyList.find(
                    companyListDetails =>
                      companyListDetails.label === currentCompanyName ||
                      getCompanyNameById(context.user.companyId)
                  )}
                  options={companyList}
                  isSearchable={true}
                  handleChange={values => {
                    handleCompanyChange(values.id);
                  }}
                  width={"200px"}
                  textColor={headerTextColor}
                  color={headerColor}
                  marignBottom
                />
              ) : (
                ""
              )}
            </Form>
          </>
        )}
      </UserContext.Consumer>
    </div>
  );
});

export default CompanyDropdown;
