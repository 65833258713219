import React, { Component } from "react";
import RichTextEditor, { stateToHTML } from "react-rte";
import _ from "lodash";

export class RichtextEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: !_.isEmpty(this.props.aboutMe)
        ? RichTextEditor.createValueFromString(this.props.aboutMe, "html")
        : RichTextEditor.createEmptyValue()
    };
  }
  onChange = value => {
    const textValue = value.toString("html");
    this.setState({ value });

    this.props.handleAboutMe(textValue);
  };

  render() {
    const toolbarConfig = {
      // Optionally specify the groups to display (displayed in the order listed).
      display: [
        "INLINE_STYLE_BUTTONS",
        "BLOCK_TYPE_BUTTONS",
        "LINK_BUTTONS",
        "BLOCK_TYPE_DROPDOWN"
      ],
      INLINE_STYLE_BUTTONS: [
        { label: "Bold", style: "BOLD", className: "custom-css-class" },
        { label: "Italic", style: "ITALIC" },
        { label: "Underline", style: "UNDERLINE" }
      ],
      BLOCK_TYPE_DROPDOWN: [
        { label: "Normal", style: "unstyled" },
        { label: "Heading Large", style: "header-one" },
        { label: "Heading Medium", style: "header-two" },
        { label: "Heading Small", style: "header-three" }
      ],
      BLOCK_TYPE_BUTTONS: [
        { label: "UL", style: "unordered-list-item" },
        { label: "OL", style: "ordered-list-item" }
      ]
    };

    const { placeholder } = this.props;
    return (
      <div>
        <RichTextEditor
          value={this.state.value}
          onChange={this.onChange}
          placeholder={placeholder}
          toolbarConfig={toolbarConfig}
        />
      </div>
    );
  }
}
