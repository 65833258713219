import React, { useState } from "react";

// Components
import ExpertCard from "../../../../components/base/ExpertCard";

// Assets
import { CheckmarkIcon, CrossIcon } from "../../../../assets/img/icons";

import DeclineExpertModal from "../DeclineExpertModal";

const SidebarCustomerExpertMatched = ({
  projectWaitingForAcceptanceExpertList,
  pendingProjectUsers,
  approveMatchedExpert,
  declineMatchedExpert,
  projectId
}) => {
  const [declineExpertModal, setDeclineExpertModal] = useState(false);

  const toggleModal = () => setDeclineExpertModal(prevState => !prevState);

  return (
    <>
      <p className="font-weight-bold">Your Matched Expert</p>
      {projectWaitingForAcceptanceExpertList &&
        projectWaitingForAcceptanceExpertList.length > 0 &&
        projectWaitingForAcceptanceExpertList.map(expert => (
          <>
            <p className="d-block text-grayed my-2 wrap-text h6 font-weight-light">
              You have approved your Expert. We have sent them a notification to
              confirm this project.
            </p>
            <ExpertCard
              classnames={"expert-card"}
              certified={true}
              data={{
                id: expert.expertId,
                avatarUrl: expert.avatarUrl,
                firstName: expert.firstName,
                lastName: expert.lastName,
                jobTitle: expert.jobTitle,
                city: expert.city,
                stateName: expert.stateName,
                hourlyRate: expert.hourlyRate,
                marketplaceStatus: expert.marketplaceStatus,
                phone: expert.phone,
                email: expert.email
              }}
              size={"w100"}
              expertUrl={true}
            />
          </>
        ))}
      {pendingProjectUsers &&
        pendingProjectUsers.length > 0 &&
        pendingProjectUsers.map(expert => (
          <>
            <p className="d-block text-grayed my-2 wrap-text h6 font-weight-light">
              You been matched with an Expert. Please review and provide
              approval to avoid unavailability.
            </p>
            <ExpertCard
              classnames={"expert-card"}
              certified={true}
              data={{
                id: expert.id,
                avatarUrl: expert.avatarUrl,
                firstName: expert.firstName,
                lastName: expert.lastName,
                jobTitle: expert.jobTitle,
                city: expert.city,
                stateName: expert.stateName,
                hourlyRate: expert.hourlyRate,
                marketplaceStatus: expert.marketplaceStatus,
                phone: expert.phone,
                email: expert.email
              }}
              size={"w100"}
              expertUrl={true}
            />

            <div className="btn-wrapper d-flex">
              <button
                className="btn btn-success text-white d-inline-flex align-items-center"
                onClick={() => {
                  approveMatchedExpert(expert.id, projectId);
                }}
              >
                <CheckmarkIcon />
                <span className="ml-2">Approve</span>
              </button>
              <button
                className="btn btn-outline-danger d-inline-flex align-items-center"
                onClick={() => {
                  toggleModal();
                }}
              >
                <CrossIcon />
                <span className="ml-2">Decline</span>
              </button>
            </div>

            {/* Declined Expert Modal */}
            {declineExpertModal && (
              <DeclineExpertModal
                modal={declineExpertModal}
                setModal={() => toggleModal()}
                declineMatchedExpert={declineMatchedExpert}
                expertId={expert.id}
                projectId={projectId}
              />
            )}
          </>
        ))}
    </>
  );
};

export default SidebarCustomerExpertMatched;
