import React from "react";
class HTMLParagraph extends React.Component {
  render() {
    const { message } = this.props;

    return (
      <p
        className="ml-3 small"
        style={{ height: "100%", whiteSpace: "pre-line" }}
      >
        <div dangerouslySetInnerHTML={{ __html: message }} />
      </p>
    );
  }
}

export default HTMLParagraph;
