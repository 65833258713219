import React from "react";

// Components
import ReferralStatus from "../../../../views/referral/inner-components/ReferralStatus";

import ProfileCard from "../../../../components/base/ProfileCard";

import Label from "../../../../components/base/Label";

import SidebarItem from "../../../referral/inner-components/SidebarItem";

const SideBar = props => {
  const { partnerDetail, referralStatus } = props;

  return (
    <div className="details-body-sidebar">
      {/* Source Partner */}

      <SidebarItem>
        <Label className="font-weight-bold mb-3">Partner</Label>

        <div className="field-wrapper">
          <div className="form-group">
            <div className="d-flex flex-row justify-content-start align-items-center">
              {partnerDetail && (
                <>
                  <div>
                    <ProfileCard
                      avatarUrl={partnerDetail.avatar}
                      firstName={partnerDetail.company_name}
                    />
                  </div>
                  <div> {partnerDetail.company_name} </div>
                </>
              )}
            </div>
          </div>
        </div>
      </SidebarItem>

      {/* Referral status  */}
      {<ReferralStatus status={referralStatus} />}
    </div>
  );
};

export default SideBar;
