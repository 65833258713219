import React, { Component } from "react";

// Components
import Text from "../../../components/base/Text";
import Select from "../../../components/base/Select";
import DragAndDropField from "../../../components/base/DragAndDropField";
import Hint from "../../../components/base/Hint";
import SingleCheckbox from "../../../components/base/SingleCheckbox";
import Radio from "../../../components/base/Radio";
import TextArea from "../../../components/base/TextArea";
import SaveButton from "../../../components/base/SaveButton";
import MultiselectCreatable from "../../../components/base/MultiselectCreatable";

// Constants
import {
  TAB_BASICS,
  COURSE_DRAFT,
  COURSE_TYPE_PROGRESSIVE,
  COURSE_SINGLE_COURSE,
  COURSE_PROGRESSIVE_COURSE,
  COURSE_FILE
} from "../../../course/Constants";
import CourseListDropdown from "../CourseListDropdown";
import { endpoints } from "../../../configs";
import MultiDragDrop from "../../components/MultiDragDrop";
import MultiSelect from "../../../components/base/Multiselect";

export default class EditBasicTab extends Component {
  constructor(props) {
    super(props);

    // Set the initial input values
    this.state = {
      title: "",
      description: "",
      bannerImageUrl: "",
      isSubmit: false,
      status: COURSE_DRAFT,
      isErrors: false,
      isSubmitting: false,
      loading: false,
      activeTab: TAB_BASICS,
      values: [],
      courseId: "",
      fileTypeError: false,
      checkBox: {
        partnerTier:
          (props.checkboxFieldValue && props.checkboxFieldValue.partnerTier) ||
          [],
        partnerType:
          (props.checkboxFieldValue && props.checkboxFieldValue.partnerType) ||
          [],
        sharingPermission: props.sharingPermission || "",
        courseRequirement:
          props.course && props.course.type === COURSE_TYPE_PROGRESSIVE
            ? COURSE_PROGRESSIVE_COURSE
            : COURSE_SINGLE_COURSE
      },
      partnerTierList: [],
      partnerTypeList: []
    };
  }

  // Select the uploaded image
  onSelectFile = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          this.setState({
            fileTypeError: false
          });
          this.props.bannerImageUpload(e);
          break;

        default:
          this.setState({
            fileTypeError: true
          });
          break;
      }
    }
  };
  // Select the uploaded image
  handleMedia = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          this.setState({
            fileTypeError: false
          });
          this.props.certificateImageUpload(e);
          break;

        default:
          this.setState({
            fileTypeError: true
          });
          break;
      }
    }
  };
  handleCourseRequirement = e => {
    const courseRequirement = e.target.value;
    const { getCheckboxFieldValue } = this.props;

    this.setState(
      prevState => ({
        checkBox: {
          ...prevState.checkBox,
          courseRequirement
        }
      }),
      () => {
        getCheckboxFieldValue && getCheckboxFieldValue(this.state.checkBox);
      }
    );
  };
  handleChange = e => {
    const target = e.target;
    const title = target.value;
    this.setState({ title });
  };

  // Progressive Course
  handleCourse = e => {
    this.setState({ selectedCourseId: e.id });
  };
  handleCategoryChange = e => {
    let category =
      e && e.values && e.values.courseCategory && e.values.courseCategory.value
        ? e.values.courseCategory.value
        : "";
    this.setState({ courseCategory: category });
  };

  render() {
    const datas = {
      bannerImageUrl: this.props.bannerImageUrl
        ? this.props.bannerImageUrl
        : this.props.bannerImageUrl
    };

    const {
      courseCategory,
      courseFile,
      values,
      handleChange,
      course,
      reviewerList
    } = this.props;

    const { selectedCourseId } = this.state;
    const progresiveCourceId = selectedCourseId
      ? selectedCourseId
      : course.link_course_id;

    return (
      <div>
        <div className="form-wrapper">
          <div className="field-wrapper mt-3">
            <Text
              name="title"
              label="Course Title"
              fontBolded
              placeholder="Enter title"
              error=""
              required={true}
              onChange={handleChange}
              onKeyPress={e => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </div>

          <div className="field-wrapper flex-column">
            <MultiselectCreatable
              isSingleSelect={true}
              name="courseCategory"
              label="Category"
              fontBolded
              isClearable={true}
              placeholder={"Select or Create Category"}
              options={courseCategory}
              onInputChange={this.handleCategoryChange}
              defaultValue={
                values && values.category && values.category.value
                  ? courseCategory.find(
                      courseCategoryDetails =>
                        courseCategoryDetails.value === values.category.value
                    )
                  : ""
              }
            />
            <div className="pt-2">
              <Hint
                id="expert-roles"
                hintText="The type of category you want to add course"
              />
            </div>
          </div>
          <label className="font-weight-bold">Course Description</label>
          <div className="field-wrapper">
            <TextArea
              name="description"
              placeholder="Enter course description"
            />
          </div>
          <Hint
            id="expert-roles"
            hintText="Description will appear on Course Detail Page"
          />

          {/* Send Notification to Partner */}
          <div className="field-wrapper flex-column mt-3">
            <label className="font-weight-bold">
              Course Publication Notification
            </label>
            <SingleCheckbox
              name="sendNotification"
              label="Send notification to partners that have permission"
              className="accepted-terms  mb-2 pb-0 mr-3"
            />
          </div>
          <div>
            <MultiSelect
              name="reviewer"
              label="Reviewer"
              fontBolded
              placeholder="Select"
              options={reviewerList}
            />
          </div>

          {/* Course Requirements */}
          <label className="font-weight-bold">Course Requirements</label>
          <div className="form-wrapper ml-4 mb-1">
            <div className="w-100">
              <Radio
                name="courseRequirement"
                size="large"
                options={[COURSE_SINGLE_COURSE, COURSE_PROGRESSIVE_COURSE]}
                onChange={this.handleCourseRequirement}
                defaultValue={
                  course.type === COURSE_TYPE_PROGRESSIVE
                    ? COURSE_PROGRESSIVE_COURSE
                    : COURSE_SINGLE_COURSE
                }
              />
            </div>
          </div>

          {/* Course to Unlock */}
          {this.state.checkBox.courseRequirement ===
            COURSE_PROGRESSIVE_COURSE && (
            <div className="field-wrapper flex-column">
              <CourseListDropdown
                name="progressiveCourse"
                label="Choose Course to Unlock:"
                fontBolded
                placeholder="Select course"
                defaultValue={progresiveCourceId}
                currentCourseId={course.id}
                handleChange={this.handleCourse}
              />
              <Hint
                id="expert-roles"
                hintText="Come back to add this course if it hasn’t been created yet"
              />
            </div>
          )}
          <div className="upload-field d-inline-block">
            <input
              name="file"
              className="form-control d-none"
              type="file"
              id="bannerImage"
              placeholder="Banner Image"
              onChange={e => {
                this.onSelectFile(e);
              }}
              accept="image/png,image/gif,image/jpeg"
            />
            <span className="profilePicOverlay d-block ">
              <label htmlFor="bannerImage" className="profile-img-sm mb-0">
                <span className="banner-image-upload-link">
                  Upload Card Image
                </span>
              </label>
            </span>
          </div>
          {/* Remove image */}
          {datas.bannerImageUrl && (
            <span
              className="banner-image-upload-link text-danger ml-md-1"
              onClick={this.props.handleImageRemove}
            >
              (Remove)
            </span>
          )}

          <Hint
            id="bannerRequirements"
            hintText="We recommended using 400 x 300 image"
          />
          <div className="field-wrapper mt-3">
            <Text
              name="certificateName"
              label="Certificate "
              fontBolded
              placeholder="Enter certificate name"
              error=""
              onKeyPress={e => {
                e.key === "Enter" && e.preventDefault();
              }}
            />
          </div>
          <div className="upload-field d-inline-block">
            <input
              name="file"
              className="form-control d-none"
              type="file"
              id="photo"
              onChange={e => {
                this.handleMedia && this.handleMedia(e);
              }}
              accept="image/png, image/gif, image/jpeg"
            />
            <div className="d-flex flex-column flex-sm-row ">
              <label
                htmlFor="photo"
                className="profile-img-sm mb-2 mb-sm-0 mr-sm-2"
              >
                <span className="banner-image-upload-link">
                  Upload Certificate Image
                </span>
              </label>

              {/* Remove image */}
              {this.props.certificateImageUrl && (
                <div className="d-flex">
                  <span
                    className="banner-image-upload-link text-danger mr-lg-5 pr-lg-5 mr-md-5 pr-md-5"
                    onClick={this.props.handleCertificateImageRemove}
                  >
                    (Remove)
                  </span>
                  <img
                    src={this.props.certificateImageUrl}
                    alt="Selected File"
                    className="img-thumbnail"
                    style={{ maxWidth: "50px", maxHeight: "50px" }}
                  />
                </div>
              )}
            </div>
          </div>
          <div className="form-wrapper mt-4">
            <label className="font-weight-bold">
              Upload Course Syllabus File
            </label>
            <div className="flex-column">
              <MultiDragDrop
                name={"course_file"}
                btnLabel={"Upload File"}
                minHeight={"220px"}
                uploadLabel={"Drag & Drop Files to Upload"}
                objectId={course && course.id}
                object={COURSE_FILE}
                getMediaUrl={`${endpoints().courseAPI}/courseMedia/${course &&
                  course.id}?object=${COURSE_FILE}&objectId=${course &&
                  course.id}`}
                mediaUploadUrl={`${endpoints().courseAPI}/uploadCourse/`}
              />
            </div>
          </div>

          <div className="btn-wrapper pull-left">
            <SaveButton />
          </div>
        </div>
      </div>
    );
  }
}
