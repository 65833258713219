import { Local } from "../Constants/LocalStorage";

class Terminology {
  static get = systemName => {
    if (localStorage.getItem(Local.TERMINOLOGY)) {
      const data = JSON.parse(localStorage.getItem(Local.TERMINOLOGY));
      const terminologyName = data.find(
        item => item.system_name === systemName
      );

      return terminologyName ? terminologyName.custom_name : systemName;
    } else {
      return systemName;
    }
  };
}

export default Terminology;
