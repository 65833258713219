import React from "react";
import Page404 from "../Page404";
import PlaybookFormFields from "./PlaybookFormFields";
import toast from "../../components/base/Toast";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Components
import PageTitle from "../../components/base/PageTitle";
import Form from "../../components/base/Form";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import DeleteButton from "../../components/base/DeleteButton";
import Spinner from "../../components/base/Spinner";

// Constants
import { EXPERT_STATUS_APPROVED } from "../../expertStatus/Constants";
import { TAB_BASICS } from "../../playbook/Constants";
import { PLAYBOOK_TAB } from "../../playbook/Constants";

import {
  PLAYBOOK_STATUS_ACTIVE,
  PLAYBOOK_STATUS_DRAFT
} from "../../playbookStatus/Constants";
import {
  TAG_TYPE_SKILLS,
  TAG_TYPE_TOOLS,
  TAG_TYPE_EXPERT_ROLE,
  TAG_TYPE_CERTIFICATION
} from "../../tagType/Constants";
import MyContext from "../../context/MyContext";
import DeleteModal from "../../components/base/DeleteModal";
import { isBadRequest } from "../../common/http";

export default class UpdatePlaybookForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      playbookDetails: [],
      loading: false,
      bannerColor: "",
      bannerIcon: "",
      bannerImageUrl: "",
      files: [],
      bannerImage: "",
      currentTab: TAB_BASICS,
      publishStatus: "",
      playbookIconValidate: true,
      playbookColorValidate: true,
      isInvalidRequest: false,
      expertRoles: [],
      skills: [],
      tools: [],
      isExpertLoading: false,
      isOpen: false,
      expertList: [],
      expertIds: [],
      supportingMaterial: "",
      checkBox: {
        standardPackageDeliverables: [],
        basicPackageDeliverables: [],
        premiumPackageDeliverables: []
      },
      updatedExtras: [],
      supportingMaterialList: "",
      deliverablesValue: "",
      defaultDeliverablesValue: "",
      extrasValue: ""
    };
    this.onColourPickerClick = this.onColourPickerClick.bind(this);
    this.onIconClick = this.onIconClick.bind(this);
    this._handleBannerImagePath = this._handleBannerImagePath.bind(this);
    this.bannerImageUpload = this.bannerImageUpload.bind(this);
    this.backButton = this.backButton.bind(this);
    this.handleExperts = this.handleExperts.bind(this);
    this._handleStandardPackageDeliverable = this._handleStandardPackageDeliverable.bind(
      this
    );
    this._handlePremiumPackageDeliverable = this._handlePremiumPackageDeliverable.bind(
      this
    );
    this._handleBasicPackageDeliverable = this._handleBasicPackageDeliverable.bind(
      this
    );
  }

  componentDidMount = () => {
    this._getPlaybookDetails();
    this._getExpertsList();
    this._getTagsList();
  };

  // Handle Standard Package Deliverable
  _handleStandardPackageDeliverable(e) {
    const newSelection = parseInt(e.target.value, 10);
    let newSelectionArray;

    if (
      this.state.checkBox.standardPackageDeliverables.indexOf(newSelection) > -1
    ) {
      newSelectionArray = this.state.checkBox.standardPackageDeliverables.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.standardPackageDeliverables,
        parseInt(newSelection, 10)
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        standardPackageDeliverables: newSelectionArray
      }
    }));
  }

  // Handle Premium Package Deliverable
  _handlePremiumPackageDeliverable(e) {
    const newSelection = parseInt(e.target.value, 10);
    let newSelectionArray;

    if (
      this.state.checkBox.premiumPackageDeliverables.indexOf(newSelection) > -1
    ) {
      newSelectionArray = this.state.checkBox.premiumPackageDeliverables.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.premiumPackageDeliverables,
        parseInt(newSelection, 10)
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        premiumPackageDeliverables: newSelectionArray
      }
    }));
  }

  // Handle Basic Package Deliverable
  _handleBasicPackageDeliverable(e) {
    const newSelection = parseInt(e.target.value, 10);
    let newSelectionArray;

    if (
      this.state.checkBox.basicPackageDeliverables.indexOf(newSelection) > -1
    ) {
      newSelectionArray = this.state.checkBox.basicPackageDeliverables.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.basicPackageDeliverables,
        parseInt(newSelection, 10)
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        basicPackageDeliverables: newSelectionArray
      }
    }));
  }

  // Get Expert List
  _getExpertsList() {
    this.setState({ isExpertLoading: true }, () => {
      apiClient
        .get(
          `${
            endpoints().expertAPI
          }/search?marketplaceStatus=${EXPERT_STATUS_APPROVED}`
        )
        .then(response => {
          const expertList = response.data.data;

          this.setState({
            isExpertLoading: false,
            expertList: expertList ? expertList : this.state.expertList
          });
        });
    });
  }

  // Handle experts
  handleExperts(value) {
    const expertIds = this.state.expertIds;
    if (expertIds.indexOf(value) > -1) {
      this.setState({
        expertIds: expertIds.filter(el => el !== value)
      });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ expertIds: expertIds.concat(value) });
    }
  }

  // Get deliverable values
  getDeliverablesValue = values => {
    this.setState({ deliverablesValue: values });
  };

  // Get default deliverable values
  getDefaultDeliverablesValue = values => {
    this.setState({ defaultDeliverablesValue: values });
  };

  // Get extras values
  getExtrasValue = values => {
    this.setState({ extrasValue: values });
  };

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save name form in the final
    this._updatePlaybook(this._toArray(values));
  };

  // Color Picker
  onColourPickerClick(e) {
    this.setState({ bannerColor: e.target.id, playbookColorValidate: true });
  }

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  // Banner Image Upload
  bannerImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";
    this.setState({ bannerImageUrl: URL.createObjectURL(files) }, () =>
      this.setBase64Image(files, "files")
    );
  };

  // Banner Image Remove
  handleImageRemove = () => {
    this.setState({ bannerImageUrl: "", files: "", bannerImagePath: "" });
  };

  // Icon Click
  onIconClick(e) {
    this.setState({
      bannerIcon: e.target.className,
      playbookIconValidate: true
    });
  }

  // Handle OnChange playbook banner image path
  _handleBannerImagePath = e => {
    const target = e.target;
    const bannerImagePath = target.value;
    this.setState({ bannerImagePath });
  };

  // set Active Status
  setPublishStatus = () => {
    this.setState({
      publishStatus: PLAYBOOK_STATUS_ACTIVE
    });
  };

  // Back Button
  backButton(e) {
    let tab = e;
    this.setState({ currentTab: tab });
    if (tab === TAB_BASICS) {
      this.setState({ backButton: "Cancel" });
    } else {
      this.setState({ backButton: "Back" });
    }
  }

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define playbook constants
    const name = values.name;

    if (!name) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    const {
      deliverablesValue,
      defaultDeliverablesValue,
      extrasValue
    } = this.state;

    // Add playbook deliverables
    const playbookDeliverables = [];
    let defaultDeliverables;
    deliverablesValue &&
      deliverablesValue.forEach(data => {
        if (data.description) {
          playbookDeliverables.push({
            description: data.description
          });
        }
      });
    defaultDeliverables = JSON.stringify(playbookDeliverables);

    // Add playbook default deliverables
    const playbookDefaultDeliverables = [];
    let defaultTaskDeliverables;
    defaultDeliverablesValue &&
      defaultDeliverablesValue.forEach(data => {
        if (data.description) {
          playbookDefaultDeliverables.push({
            description: data.description
          });
        }
      });
    defaultTaskDeliverables = JSON.stringify(playbookDefaultDeliverables);

    // Packages
    let packages = [];
    if (values["Standard-package-1"] || !values["Standard-package-1"]) {
      packages.push({
        price: values["Standard-package-1"]
          ? values["Standard-package-1"]
          : null,
        package_type: "Standard",
        turnaround_time: values["Standard_playbook_turnaround_time"]
          ? values["Standard_playbook_turnaround_time"]
          : null,
        included_deliverables:
          values["Standard-package-1"] && values["extra-Standard-1"]
            ? values["extra-Standard-1"]
            : null
      });
    }
    if (values["Basic-package-2"] || !values["Basic-package-2"]) {
      packages.push({
        price: values["Basic-package-2"] ? values["Basic-package-2"] : null,
        package_type: "Basic",
        turnaround_time: values["Basic_playbook_turnaround_time"]
          ? values["Basic_playbook_turnaround_time"]
          : null,
        included_deliverables:
          values["Basic-package-2"] && values["extra-Basic-1"]
            ? values["extra-Basic-1"]
            : null
      });
    }
    if (values["Premium-package-3"] || !values["Premium-package-3"]) {
      packages.push({
        price: values["Premium-package-3"] ? values["Premium-package-3"] : null,
        package_type: "Premium",
        turnaround_time: values["Premium_playbook_turnaround_time"]
          ? values["Premium_playbook_turnaround_time"]
          : null,
        included_deliverables:
          values["Premium-package-3"] && values["extra-Premium-1"]
            ? values["extra-Premium-1"]
            : null
      });
    }

    packages = JSON.stringify(packages);

    // Playbook Extras
    let playbookExtras = [];
    extrasValue &&
      extrasValue.forEach(data => {
        if (data.option && data.price) {
          playbookExtras.push({
            extra_type: data.option,
            price: data.price
          });
        }
      });
    playbookExtras = JSON.stringify(playbookExtras);

    const data = new FormData();
    data.append("name", values.name ? values.name : "");
    data.append("publishStatus", this.state.publishStatus);
    data.append("category", values.category ? values.category : "");
    data.append("description", values.description ? values.description : "");
    data.append(
      "bannerImagePath",
      this.state.bannerImagePath ? this.state.bannerImagePath : ""
    );
    data.append(
      "bannerColor",
      this.state.bannerColor ? this.state.bannerColor : values.bannerColor
    );
    data.append(
      "bannerIcon",
      this.state.bannerIcon ? this.state.bannerIcon : values.bannerIcon
    );
    data.append("price", values.price ? values.price : "");
    data.append("requirements", values.requirements ? values.requirements : "");
    data.append("outOfScope", values.outOfScope ? values.outOfScope : "");
    data.append(
      "changeManagement",
      values.changeManagement ? values.changeManagement : ""
    );
    data.append("deliverables", values.deliverables ? values.deliverables : "");
    data.append(
      "expertRoles",
      values.expertRoles ? JSON.stringify([values.expertRoles]) : []
    );
    data.append(
      "required_skills",
      values.required_skills ? JSON.stringify(values.required_skills) : []
    );
    data.append(
      "required_tools",
      values.required_tools ? JSON.stringify(values.required_tools) : []
    );
    data.append("methodology", values.methodology ? values.methodology : "");
    data.append(
      "estimatedLength",
      values.estimatedLength ? values.estimatedLength : ""
    );
    data.append("supportingMaterial", this.state.supportingMaterial);
    data.append("supportingMaterialName", this.state.supportingMaterialName);
    data.append("file", this.state.files ? this.state.files : "");
    data.append(
      "pricingType",
      values.pricingType ? values.pricingType.value : ""
    );
    data.append(
      "pricingSchedule",
      values.pricingSchedule ? values.pricingSchedule.value : ""
    );
    data.append("defaultDeliverables", defaultDeliverables);

    data.append("defaultTaskDeliverables", defaultTaskDeliverables);

    data.append("packages", packages);

    data.append("extras", playbookExtras);
    data.append("expertIds", this.state.expertIds);

    const standardDeliverables = values.standardDeliverables;
    data.append("standardDeliverables", standardDeliverables.split(","));

    const basicDeliverables = values.basicDeliverables;
    data.append("basicDeliverables", basicDeliverables.split(","));

    const premiumDeliverables = values.premiumDeliverables;
    data.append("premiumDeliverables", premiumDeliverables.split(","));

    return data;
  }

  // Playbook update API Call
  _updatePlaybook(data) {
    return apiClient
      .put(`${endpoints().playbookAPI}/${this.props.match.params.id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        setTimeout(() => {
          toast.success(successMessage);

          const url =
            this.state.playbookDetails.publishStatus === "Draft"
              ? "/marketplace-sourcing?section=Draft"
              : "/marketplace-sourcing";
          this.props.history.push(url);
        }, 2000);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Playbook delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().playbookAPI}/${this.props.match.params.id}`)

      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        setTimeout(() => {
          toast.success(successMessage);

          const url =
            this.state.playbookDetails.publishStatus === PLAYBOOK_TAB
              ? "/marketplace-sourcing?section=Draft"
              : "/marketplace-sourcing";
          this.props.history.push(url);
        }, 2000);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Playbook details api call by id
  _getPlaybookDetails = () => {
    return apiClient
      .get(`${endpoints().playbookAPI}/${this.props.match.params.id}`)
      .then(response => {
        const data = response.data;

        this.setState({ publishStatus: data.publishStatus });

        const existStandardDeliverables = data.deliverables.standard;
        const standardIds = [];
        if (existStandardDeliverables && existStandardDeliverables.length > 0) {
          existStandardDeliverables.forEach(existStandardDeliverable => {
            standardIds.push(existStandardDeliverable.id);
          });
        }

        const existBasicDeliverables = data.deliverables.basic;
        const basicIds = [];
        if (existBasicDeliverables && existBasicDeliverables.length > 0) {
          existBasicDeliverables.forEach(existBasicDeliverable => {
            basicIds.push(existBasicDeliverable.id);
          });
        }

        const existPremiumDeliverables = data.deliverables.premium;
        const premiumIds = [];
        if (existPremiumDeliverables && existPremiumDeliverables.length > 0) {
          existPremiumDeliverables.forEach(existPremiumDeliverable => {
            premiumIds.push(existPremiumDeliverable.id);
          });
        }

        const selectedIds = [];
        if (data.playbookExperts) {
          data.playbookExperts.forEach(expert => {
            selectedIds.push(expert.expert_id);
          });
        }

        this.setState({
          playbookDetails: response.data,
          bannerImagePath: response.data.bannerImagePath,
          bannerImageUrl: response.data.bannerImagePathUrl,
          loading: true,
          expertIds: selectedIds,
          checkBox: {
            standardPackageDeliverables: standardIds,
            basicPackageDeliverables: basicIds,
            premiumPackageDeliverables: premiumIds
          }
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Get tags list
  _getTagsList() {
    this.setState({ isLoading: true }, () => {
      apiClient.get(`${endpoints().playbookTagAPI}`).then(response => {
        const tags = response.data.data;
        const expertRoles = [];
        const skills = [];
        const tools = [];
        const certificates = [];
        if (tags && tags.length > 0) {
          tags.forEach(tag => {
            if (tag.type === TAG_TYPE_EXPERT_ROLE) {
              expertRoles.push({
                value: tag.id,
                label: tag.name
              });
            } else if (tag.type === TAG_TYPE_SKILLS) {
              skills.push({
                value: tag.id,
                label: tag.name
              });
            } else if (tag.type === TAG_TYPE_TOOLS) {
              tools.push({
                value: tag.id,
                label: tag.name
              });
            } else if (tag.type === TAG_TYPE_CERTIFICATION) {
              certificates.push({
                value: tag.id,
                label: tag.name
              });
            }
          });
        }

        this.setState({
          isLoading: false,
          expertRoles,
          skills,
          tools,
          certificates
        });
      });
    });
  }

  // Delete supporting material
  deleteSupportingMaterial = materialId => {
    return apiClient
      .delete(
        `${endpoints().playbookAPI}/material/${
          this.props.match.params.id
        }/${materialId}`
      )
      .then(response => {
        if (response && response.data) {
          setTimeout(() => {
            window.location.replace("/playbooks");
          }, 2000);
          this.setState({ supportingMaterialList: response.data });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Handle supporting material
  _handleSupportingMaterial = files => {
    this.setState({ supportingMaterialName: files[0].name });
    this.setBase64Image(files[0], "supportingMaterial");
  };

  // Delete supporting material
  _handleDeleteSupportingMaterial = materialId => {
    this.deleteSupportingMaterial(materialId);
  };

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return <Spinner />;
    }

    const { bannerImageUrl } = this.state;
    let {
      id,
      name,
      publishStatus,
      category,
      description,
      bannerColor,
      bannerIcon,
      price,
      requirements,
      outOfScope,
      changeManagement,
      methodology,
      estimatedLength,
      pricingType,
      pricingSchedule,
      cartOptions,
      extras,
      deliverables,
      tags,
      defaultDeliverables,
      supportingMaterials,
      defaultTaskDeliverables
    } = this.state.playbookDetails;

    pricingType = {
      value: pricingType ? pricingType : "",
      label: pricingType ? pricingType : ""
    };

    pricingSchedule = {
      value: pricingSchedule ? pricingSchedule : "",
      label: pricingSchedule ? pricingSchedule : ""
    };

    let standardPrice = "";
    let standardTurnarondTime = "";
    let basicPrice = "";
    let basicTurnarondTime = "";
    let premiumPrice = "";
    let premiumTurnarondTime = "";
    if (cartOptions && cartOptions.length > 0) {
      cartOptions.forEach(cartOption => {
        if (cartOption.package_type === "Standard") {
          standardPrice = cartOption.price;
          standardTurnarondTime = cartOption.turnaround_time;
        }
        if (cartOption.package_type === "Basic") {
          basicPrice = cartOption.price;
          basicTurnarondTime = cartOption.turnaround_time;
        }
        if (cartOption.package_type === "Premium") {
          premiumPrice = cartOption.price;
          premiumTurnarondTime = cartOption.turnaround_time;
        }
      });
    }
    const {
      playbookIconValidate,
      playbookColorValidate,
      expertRoles,
      skills,
      tools,
      certification,
      expertIds,
      isOpen,
      expertList
    } = this.state;
    const initialValues = {
      name,
      category,
      description,
      bannerImageUrl,
      bannerColor,
      bannerIcon,
      price,
      requirements,
      certification,
      outOfScope,
      changeManagement,
      methodology,
      estimatedLength,
      expertRoles: tags.expertRoles[0],
      required_skills: tags.skills,
      required_tools: tags.tools,
      pricingType,
      pricingSchedule,
      "Standard-package-1": standardPrice,
      Standard_playbook_turnaround_time: standardTurnarondTime,
      "Basic-package-2": basicPrice,
      Basic_playbook_turnaround_time: basicTurnarondTime,
      "Premium-package-3": premiumPrice,
      Premium_playbook_turnaround_time: premiumTurnarondTime
    };

    const headerButton = (
      <div className="btn-wrapper mt-2 mb-4">
        <button className="btn btn-outline-dark pr-2 mr-4">
          Save as Draft
        </button>
        <button
          className="btn btn-secondary pr-2"
          onClick={this.setPublishStatus}
        >
          Publish PlayBook
        </button>
      </div>
    );
    const { deletePlaybookModal } = this.state;

    return (
      <>
        <MyContext.Consumer>
          {context => (
            <>
              {context.isMobile && <PageTitle label="Edit Playbook" />}
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  values.extras = extras;
                  values.packageDeliverables = deliverables;
                  values.defaultDeliverables = defaultDeliverables;
                  values.defaultTaskDeliverables = defaultTaskDeliverables;
                  values.standardDeliverables = this.state.checkBox
                    .standardPackageDeliverables
                    ? this.state.checkBox.standardPackageDeliverables.join(", ")
                    : null;
                  values.basicDeliverables = this.state.checkBox
                    .basicPackageDeliverables
                    ? this.state.checkBox.basicPackageDeliverables.join(", ")
                    : null;
                  values.premiumDeliverables = this.state.checkBox
                    .premiumPackageDeliverables
                    ? this.state.checkBox.premiumPackageDeliverables.join(", ")
                    : null;

                  this._submit(values);
                }}
              >
                <div
                  className={`page-heading d-flex ${
                    context.isMobile
                      ? "justify-content-end"
                      : "justify-content-between"
                  }`}
                >
                  {!context.isMobile && <PageTitle label="Edit Playbook" />}

                  {publishStatus === PLAYBOOK_STATUS_DRAFT && headerButton}
                </div>
                <DeleteModal
                  isOpen={deletePlaybookModal}
                  toggle={() => {
                    this.setState({ deletePlaybookModal: false });
                  }}
                  title="Delete playbook"
                  label={name}
                  deleteFunction={this.handleDelete}
                />
                <PlaybookFormFields
                  colorValidate={playbookColorValidate}
                  iconValidate={playbookIconValidate}
                  data={initialValues}
                  onColourPickerClick={this.onColourPickerClick}
                  onIconClick={this.onIconClick}
                  bannerColor={
                    this.state.bannerColor || initialValues.bannerColor
                  }
                  bannerIcon={this.state.bannerIcon || initialValues.bannerIcon}
                  handleBannerImagePath={this._handleBannerImagePath}
                  projectCategory={this.state.projectCategory}
                  bannerImageUpload={e => this.bannerImageUpload(e)}
                  bannerImageUrl={this.state.bannerImageUrl}
                  backButton={e => this.backButton(e)}
                  currentTab={this.state.currentTab}
                  handleImageRemove={this.handleImageRemove}
                  extras={extras}
                  defaultDeliverables={defaultDeliverables}
                  defaultTaskDeliverables={defaultTaskDeliverables}
                  deliverables={deliverables}
                  expertRoles={expertRoles}
                  skills={skills}
                  certification={certification}
                  tools={tools}
                  expertList={expertList}
                  expertIds={expertIds}
                  selectedIds={expertIds}
                  handleExperts={this.handleExperts}
                  standardDeliverableOnChange={
                    this._handleStandardPackageDeliverable
                  }
                  premiumDeliverableOnChange={
                    this._handlePremiumPackageDeliverable
                  }
                  basicDeliverableOnChange={this._handleBasicPackageDeliverable}
                  handleSupportingMaterial={this._handleSupportingMaterial}
                  supportingMaterials={
                    this.state.supportingMaterialList || supportingMaterials
                  }
                  handleDeleteSupportingMaterial={
                    this._handleDeleteSupportingMaterial
                  }
                  standardPackageDeliverables={
                    this.state.checkBox.standardPackageDeliverables
                  }
                  basicPackageDeliverables={
                    this.state.checkBox.basicPackageDeliverables
                  }
                  premiumPackageDeliverables={
                    this.state.checkBox.premiumPackageDeliverables
                  }
                  isOpen={isOpen}
                  detailLink={`/playbook/${id}`}
                  removeAddedExtras={this.removeAddedExtras}
                  getDeliverablesValue={this.getDeliverablesValue}
                  getDefaultDeliverablesValue={this.getDefaultDeliverablesValue}
                  createdId={this.state.newCreatedDeliverableId}
                  getExtrasValue={this.getExtrasValue}
                  isPreview
                />

                <div className="mb-4">
                  <DeleteButton
                    label={"Delete Playbook"}
                    onClick={() => this.setState({ deletePlaybookModal: true })}
                  />
                  <div className="float-right">
                    {this.state.currentTab !== TAB_BASICS ? (
                      <CancelButton
                        onClick={() => this.props.history.goBack()}
                      />
                    ) : (
                      ""
                    )}

                    <SaveButton />
                  </div>
                </div>
              </Form>
            </>
          )}
        </MyContext.Consumer>
      </>
    );
  }
}
