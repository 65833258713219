import React, { Component } from "react";
import moment from "moment";
import classnames from "classnames";
import { Nav, NavItem, NavLink } from "reactstrap";
import { Link } from "react-router-dom";

// Configs
import { endpoints } from "../../configs";
import {
  isLoggedIn,
  isCustomer,
  isExpert,
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  getUrlParameter,
  isCompanyManager,
  getKeyValueByObject,
  getCookie
} from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import Spinner from "../../components/base/Spinner";
import {
  ACCOUNTING_TAB_BILLING,
  ACCOUNTING_TAB_PAYMENTS,
  ACCOUNTING_TAB_PROMOTIONS
} from "../../Accounting/Constants";

import { SETTINGS_ENABLE_PROJECTS } from "../../setting/Constants";

import { DollarIcon } from "../../assets/img/icons";
import Currency from "../../components/Currency";
import { COOKIE_DEFAULT_CURRENCY } from "../../lib/cookie";

import { connect } from "react-redux";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class Payment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      data: [],
      totalCount: 0,
      activeTab: ACCOUNTING_TAB_BILLING,
      defaultSortByOptions: [
        {
          value: "description:ASC",
          label: "Description - Asc"
        },
        {
          value: "description:DESC",
          label: "Description - Desc"
        },
        {
          value: "amount:ASC",
          label: "Amount - Asc"
        },
        {
          value: "amount:DESC",
          label: "Amount - Desc"
        }
      ],
      sortByOptions: []
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();

    this.parseTabsFromUrl();

    const tab = getUrlParameter("type");

    // Redirect To Default Active Tab For Super Admin
    if (
      (isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
      !tab
    ) {
      return this.setState({ activeTab: ACCOUNTING_TAB_BILLING }, () => {
        this.props.history.push(`/accounting?type=${ACCOUNTING_TAB_BILLING}`);
        this.parseTabsFromUrl();
      });
    }
    this.setState({ activeTab: tab });
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  // componentDidUpdate(prevProps) {
  //   const { search } = prevProps.history.location;
  // }

  getApiURL() {
    if (isExpert(this.props.roleId)) {
      return `${endpoints().invoiceAPI}/expert/me`;
    }

    if (isCustomer(this.props.roleId)) {
      return `${endpoints().invoiceAPI}/customer/me`;
    }

    if (this.state.activeTab === ACCOUNTING_TAB_BILLING) {
      return `${endpoints().invoiceAPI}/admin/billing`;
    } else if (this.state.activeTab === ACCOUNTING_TAB_PAYMENTS) {
      return `${endpoints().invoiceAPI}/admin/payments`;
    } else {
      return endpoints().orderAPI;
    }
  }

  transformRequestData(data) {
    return data.map(payment => ({
      ...payment,
      projectName: (payment.project && payment.project.name) || "",
      projectCategory:
        (payment.project && payment.project.project_category) || "",
      customerCompany:
        payment.project && payment.project.projectCustomer
          ? payment.project.projectCustomer.display_company_name
          : "",
      expertName: payment.expert
        ? `${payment.expert.first_name} ${payment.expert.last_name}`
        : "",
      createdAt: payment.createdAt
        ? moment(payment.createdAt).format("MM/DD/YYYY")
        : "",
      dueAt: payment.due_at ? moment(payment.due_at).format("MM/DD/YYYY") : "",
      payoutDueAt: payment.payout_due_at
        ? moment(payment.payout_due_at).format("MM/DD/YYYY")
        : "",
      accountName:
        (payment.account && payment.account.company_name) ||
        payment.accountName,
      accountId: (payment.account && payment.account.id) || ""
    }));
  }

  createSortOptions(tab) {
    const { defaultSortByOptions } = this.state;
    const sortByOptions = [...defaultSortByOptions];
    const activeTab = tab || this.state.activeTab;
    this.setState({ isLoading: true });

    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId) &&
      !isCompanyManager(this.props.roleId)
    ) {
      sortByOptions.unshift(
        ...[
          {
            value: "project.name:ASC",
            label: "Project Name - Asc"
          },
          {
            value: "project.name:DESC",
            label: "Project Name - Desc"
          }
        ]
      );
    }

    if (
      (isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
      activeTab === ACCOUNTING_TAB_BILLING
    ) {
      sortByOptions.unshift(
        ...[
          {
            value: "display_company_name:ASC",
            label: "Customer Name - Asc"
          },
          {
            value: "display_company_name:DESC",
            label: "Customer Name - Desc"
          }
        ]
      );
    }

    if (
      (isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
      activeTab === ACCOUNTING_TAB_PAYMENTS
    ) {
      sortByOptions.unshift(
        ...[
          {
            value: "expert.first_name,expert.last_name:ASC",
            label: "Expert Name - Asc"
          },
          {
            value: "expert.first_name,expert.last_name:DESC",
            label: "Expert Name - Desc"
          }
        ]
      );
    }

    if (
      (isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
      activeTab === ACCOUNTING_TAB_PROMOTIONS
    ) {
      sortByOptions.unshift(
        ...[
          {
            value: "value-1",
            label: "Label-1"
          },
          {
            value: "value-2",
            label: "Label-2"
          }
        ]
      );
    }

    if (
      isExpert(this.props.roleId) ||
      isPartner(this.props.roleId) ||
      ((isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
        activeTab === ACCOUNTING_TAB_PAYMENTS)
    ) {
      sortByOptions.unshift(
        ...[
          {
            value: "createdAt:DESC",
            label: "Most Recent"
          },
          {
            value: "payout_due_at:ASC",
            label: "Due At - Asc"
          },
          {
            value: "payout_due_at:DESC",
            label: "Due At - Desc"
          }
        ]
      );
    }

    if (
      isCustomer(this.props.roleId) ||
      ((isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
        activeTab === ACCOUNTING_TAB_BILLING)
    ) {
      sortByOptions.unshift(
        ...[
          {
            value: "due_at:ASC",
            label: "Due At - Asc"
          },
          {
            value: "due_at:DESC",
            label: "Due At - Desc"
          }
        ]
      );
    }

    if (
      isCustomer(this.props.roleId) ||
      ((isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
        activeTab === ACCOUNTING_TAB_BILLING)
    ) {
      sortByOptions.unshift(
        ...[
          {
            value: "createdAt:DESC",
            label: "Most Recent"
          }
        ]
      );
    }

    this.setState(
      {
        sortByOptions
      },
      () => {
        this.setState({ isLoading: false });
      }
    );
  }

  formatCurrency(value) {
    const formatter = new Intl.NumberFormat("en-US", {
      style: "currency",
      currency: "USD"
    });

    return formatter.format(value);
  }

  toggle = tab => {
    if (tab === ACCOUNTING_TAB_BILLING) {
      this.props.history.push(
        `${this.props.location.pathname}?type=${ACCOUNTING_TAB_BILLING}`
      );
    } else if (tab === ACCOUNTING_TAB_PAYMENTS) {
      this.props.history.push(
        `${this.props.location.pathname}?type=${ACCOUNTING_TAB_PAYMENTS}`
      );
    } else if (tab === ACCOUNTING_TAB_PROMOTIONS) {
      this.props.history.push(
        `${this.props.location.pathname}?type=${ACCOUNTING_TAB_PROMOTIONS}`
      );
    }

    this.createSortOptions(tab);

    this.setState({
      activeTab: tab
    });
  };

  parseTabsFromUrl() {
    const search = this.props.location.search;

    if (
      (isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
      search
    ) {
      if (search.indexOf(`type=${ACCOUNTING_TAB_BILLING}`) >= 0) {
        this.toggle(ACCOUNTING_TAB_BILLING);
      } else if (search.indexOf(`type=${ACCOUNTING_TAB_PAYMENTS}`) >= 0) {
        this.toggle(ACCOUNTING_TAB_PAYMENTS);
      } else if (search.indexOf(`type=${ACCOUNTING_TAB_PROMOTIONS}`) >= 0) {
        this.toggle(ACCOUNTING_TAB_PROMOTIONS);
      }
    }

    this.createSortOptions();
  }

  renderTabs() {
    const { activeTab } = this.state;
    const { settings } = this.props;

    const projectStatus =
      settings && getKeyValueByObject(settings, SETTINGS_ENABLE_PROJECTS);

    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    ) {
      return (
        <Nav tabs className="admin-tabs">
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === ACCOUNTING_TAB_BILLING
              })}
              onClick={() => this.toggle(ACCOUNTING_TAB_BILLING)}
            >
              Invoices
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeTab === ACCOUNTING_TAB_PAYMENTS
              })}
              onClick={() => this.toggle(ACCOUNTING_TAB_PAYMENTS)}
            >
              Payments
            </NavLink>
          </NavItem>
          {projectStatus == "true" && (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === ACCOUNTING_TAB_PROMOTIONS
                })}
                onClick={() => this.toggle(ACCOUNTING_TAB_PROMOTIONS)}
              >
                Promotions
              </NavLink>
            </NavItem>
          )}
        </Nav>
      );
    }

    return null;
  }

  renderDueColumn() {
    const { activeTab } = this.state;
    let field = "";

    if (
      isExpert(this.props.roleId) ||
      ((isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
        activeTab === ACCOUNTING_TAB_PAYMENTS)
    ) {
      field = "payoutDueAt";
    } else if (
      isCustomer(this.props.roleId) ||
      ((isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
        activeTab === ACCOUNTING_TAB_BILLING)
    ) {
      field = "dueAt";
    }

    return (
      <ReduxColumn
        disableOnClick
        maxWidth={"110px"}
        minWidth={"110px"}
        field={field}
      >
        Due Date
      </ReduxColumn>
    );
  }

  pageTitle() {
    if (isExpert(this.props.roleId) || isPartner(this.props.roleId)) {
      return "Payments";
    }

    if (isCustomer(this.props.roleId)) {
      return "Billings";
    }

    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    ) {
      return "Accounting";
    }

    return null;
  }

  searchPlaceholder() {
    const { activeTab } = this.state;

    if (
      isExpert(this.props.roleId) ||
      isPartner(this.props.roleId) ||
      ((isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
        activeTab === ACCOUNTING_TAB_PAYMENTS)
    ) {
      return "Payments";
    }

    if (
      isCustomer(this.props.roleId) ||
      ((isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
        activeTab === ACCOUNTING_TAB_BILLING)
    ) {
      return "Billings";
    }

    if (
      (isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
      activeTab === ACCOUNTING_TAB_PROMOTIONS
    ) {
      return "Promotions";
    }
  }

  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }

    const paymentsDropdownStyle = {
      overflowY: "scroll",
      height: "250px",
      overflowX: "hidden"
    };
    return (
      <div>
        <PageTitle label={this.pageTitle()} />
        {this.renderTabs()}

        <div>
          {this.state.activeTab !== ACCOUNTING_TAB_PROMOTIONS ? (
            <ReduxTable
              id="payments"
              apiURL={this.getApiURL()}
              onRowClick={row =>
                this.props.history.push(`/accounting/billings/${row.id}`)
              }
              transformData={this.transformRequestData}
              disableColumnSort
              showHeader
              searchPlaceholder="Search"
              newTableHeading
              paramsToUrl={true}
              history={this.props.history}
              sortByOptions={this.state.sortByOptions}
              icon={<DollarIcon />}
              message="After you start getting payments, they will show up here"
              dropdownStyle={paymentsDropdownStyle}
            >
              <ReduxColumn
                Width={"110px"}
                field="id"
                renderField={row => (
                  <div className="text-center">
                    <Link to={`/accounting/billings/${row.id}`}>{row.id}</Link>
                  </div>
                )}
              >
                Invoice#
              </ReduxColumn>
              <ReduxColumn disableOnClick field="accountName" width="110px">
                Account
              </ReduxColumn>

              <ReduxColumn disableOnClick field="description" width="120px">
                Description
              </ReduxColumn>
              <ReduxColumn
                Width={"110px"}
                field="amount"
                disableOnClick
                isClickable="true"
                renderField={row => (
                  <span>
                    {row.amount != null ? (
                      <Currency
                        amount={row.amount}
                        currencyName={getCookie(COOKIE_DEFAULT_CURRENCY)}
                      />
                    ) : (
                      ""
                    )}
                  </span>
                )}
              >
                Amount
              </ReduxColumn>
              <ReduxColumn disableOnClick Width={"135px"} field="createdAt">
                Created Date
              </ReduxColumn>
              {this.renderDueColumn()}
              <ReduxColumn
                Width={"135px"}
                field="payment_status"
                disableOnClick
                renderField={row => (
                  <div
                    className={`text-center text-uppercase ${
                      row.payment_status !== "pending"
                        ? "landing-page-status-active"
                        : "landing-page-status-draft"
                    }`}
                    style={statusStyle}
                  >
                    <p>{row.payment_status}</p>
                  </div>
                )}
              >
                Status
              </ReduxColumn>
            </ReduxTable>
          ) : (
            <div className="mt-4 md-5">
              <ReduxTable
                id="promotions"
                apiURL={this.getApiURL()}
                params={{ coupon: true }}
                transformData={this.transformRequestData}
                onRowClick={row =>
                  this.props.history.push(`/project/${row.projectId}`)
                }
                disableColumnSort
                showHeader
                searchPlaceholder={`Search ${this.searchPlaceholder()}...`}
              >
                <ReduxColumn field="createdAt" isClickable="true">
                  Date
                </ReduxColumn>
                <ReduxColumn type="link" isClickable="true" field="projectName">
                  Project Name
                </ReduxColumn>
                <ReduxColumn
                  field="customerCompanyName"
                  type="link"
                  isClickable="true"
                  onLinkClick={row =>
                    this.props.history.push(`/customer/${row.customerId}`)
                  }
                >
                  Customer
                </ReduxColumn>
                <ReduxColumn
                  field="price"
                  isClickable="true"
                  renderField={row => this.formatCurrency(row.price)}
                >
                  Amount
                </ReduxColumn>
                <ReduxColumn field="couponCode" isClickable="true">
                  Coupon Code
                </ReduxColumn>
                <ReduxColumn
                  field="discountedAmount"
                  isClickable="true"
                  renderField={row => this.formatCurrency(row.discountedAmount)}
                >
                  Discount Amount
                </ReduxColumn>
              </ReduxTable>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(Payment);
