import React from "react";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import {
  toString,
  fromArray,
  getParamsByName,
  removeMaskedPhoneNumber,
  getCookie,
  setCookie,
  clearCookie,
  validateStrongPassword,
  getKeyValueByObject,
  getSettingMediaUrl
} from "../../lib/helper";

// Components
import Form from "../../components/base/Form";

import LeftSideSection from "./LeftSideSection";
import GetStarted from "./GetStarted";
import RequestReceived from "./RequestReceived";
import BasicInformation from "./BasicInformation";
import Location from "./Location";
import TellUsAboutYourself from "./TellUsAboutYourself";
import SkillsAndTools from "./SkillsAndTools";

// Constants
import {
  EXPERT_SIGNUP_BASIC_INFORMATION,
  EXPERT_SIGNUP_BASIC_INFORMATION_TEXT,
  EXPERT_SIGNUP_LOCATION,
  EXPERT_SIGNUP_LOCATION_TEXT,
  EXPERT_SIGNUP_GETTING_STARTED,
  EXPERT_SIGNUP_GETTING_STARTED_TEXT,
  EXPERT_SIGNUP_REQUEST_RECEIVED,
  EXPERT_SIGNUP_REQUEST_RECEIVED_TEXT,
  EXPERT_SIGNUP_SKILLS_AND_TOOLS,
  EXPERT_SIGNUP_SKILLS_AND_TOOLS_TEXT,
  EXPERT_SIGNUP_TELL_US_ABOUT_YOUR_SELF,
  EXPERT_SIGNUP_TELL_US_ABOUT_YOUR_SELF_TEXT
} from "../../expertSignup/Constants";

import { UNITED_STATES } from "../../Country/Constants";

import {
  SETTINGS_EXPERT_SIGNUP_WELCOME_MESSAGE,
  SETTINGS_EXPERT_SIGNUP_HERO_IMAGE,
  BACKGROUND_IMAGE_URL,
  SETTINGS_PORTAL_NAME,
  SETTINGS_ENABLE_SALESFORCE_LOGIN,
  SETTINGS_ENABLE_OFFICE_365_LOGIN,
  SETTINGS_ENABLE_GOOGLE_LOGIN,
  SETTINGS_ENABLE_LINKEDIN_LOGIN,
  SETTINGS_LINKEDIN_CLIENT_ID,
  SETTINGS_LINKEDIN_REDIRECT_URI
} from "../../setting/Constants";
import { COOKIE_SESSION_TOKEN, COOKIE_USER_ID } from "../../lib/cookie";
import { isBadRequest } from "../../common/http";

class ExpertSignUp extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      height: window.innerHeight,
      firstName: "",
      lastName: "",
      errorMessage: "",
      yearsExperience: "",
      yearsOfExperience: "",
      timezone: "",
      expertSignup: false,
      expertType: [],
      toolsType: [],
      industriesType: [],
      passwordToken: "",
      jobTitle: "",
      passwordMismatchError: "",
      confirmPasswordMismatchError: "",
      currentUserId: null
    };

    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this._submit = this._submit.bind(this);
    this._close = this._close.bind(this);

    // Bind the update dimensions
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    const section = getParamsByName("section");
    if (section === EXPERT_SIGNUP_GETTING_STARTED_TEXT) {
      this.setState({
        currentStep: EXPERT_SIGNUP_GETTING_STARTED
      });
    }
    if (section === EXPERT_SIGNUP_BASIC_INFORMATION_TEXT) {
      this.setState({
        currentStep: EXPERT_SIGNUP_BASIC_INFORMATION
      });
    }
    if (section === EXPERT_SIGNUP_LOCATION_TEXT) {
      this.setState({
        currentStep: EXPERT_SIGNUP_LOCATION
      });
    }
    if (section === EXPERT_SIGNUP_TELL_US_ABOUT_YOUR_SELF_TEXT) {
      this.setState({
        currentStep: EXPERT_SIGNUP_TELL_US_ABOUT_YOUR_SELF
      });
    }
    if (section === EXPERT_SIGNUP_SKILLS_AND_TOOLS_TEXT) {
      this.setState({
        currentStep: EXPERT_SIGNUP_SKILLS_AND_TOOLS
      });
    }
    if (section === EXPERT_SIGNUP_REQUEST_RECEIVED_TEXT) {
      this.setState({
        currentStep: EXPERT_SIGNUP_REQUEST_RECEIVED
      });
    }

    if (section === null) {
      this.props.history.push(
        `/expert-signup?section=${EXPERT_SIGNUP_GETTING_STARTED_TEXT}`
      );
    }

    this.getCountryDetails(UNITED_STATES);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  // Update the screen height
  updateDimensions() {
    this.setState({
      height: window.innerHeight
    });
  }

  // Get Country Details
  getCountryDetails = countryName => {
    return apiClient
      .get(`${endpoints().countryAPI}/${countryName}`)
      .then(response => {
        const data = response.data;
        this.setState({
          selectedCountry: data.id
        });
      });
  };
  // Update param based on step selected
  updateParam(currentStep) {
    const param =
      currentStep === EXPERT_SIGNUP_GETTING_STARTED
        ? EXPERT_SIGNUP_GETTING_STARTED_TEXT
        : currentStep === EXPERT_SIGNUP_BASIC_INFORMATION
        ? EXPERT_SIGNUP_BASIC_INFORMATION_TEXT
        : currentStep === EXPERT_SIGNUP_LOCATION
        ? EXPERT_SIGNUP_LOCATION_TEXT
        : currentStep === EXPERT_SIGNUP_TELL_US_ABOUT_YOUR_SELF
        ? EXPERT_SIGNUP_TELL_US_ABOUT_YOUR_SELF_TEXT
        : currentStep === EXPERT_SIGNUP_SKILLS_AND_TOOLS
        ? EXPERT_SIGNUP_SKILLS_AND_TOOLS_TEXT
        : currentStep === EXPERT_SIGNUP_REQUEST_RECEIVED
        ? EXPERT_SIGNUP_REQUEST_RECEIVED_TEXT
        : "";
    this.props.history.push(`/expert-signup?section=${param}`);
  }

  // _next functions will be called on button click
  _next = props => {
    let currentStep = this.state.currentStep;
    // If the current step is 5 or 6, then add one on "next" button click
    currentStep = currentStep >= 6 ? 6 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });
    this.updateParam(currentStep);
  };

  // _previous functions will be called on button click
  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
    this.updateParam(currentStep);
  }

  // Handle job run
  _updateExpertProfileCompletion = async userId => {
    if (!userId) return null;

    try {
      await apiClient.put(
        `${endpoints().expertAPI}/job/profileCompletionPercentageUpdate`,
        { userId }
      );
    } catch (error) {
      return null;
    }
  };

  // _previous functions will be called on button click
  _submit(values) {
    if (this._validateFields(values) === false) {
      return;
    }

    let currentStep = this.state.currentStep;

    // Save "Basic Information" form of expert
    if (currentStep === 2) {
      this.props.history.push(
        `/expert-signup?section=${EXPERT_SIGNUP_LOCATION_TEXT}`
      );
      if (!this.state.expertSignup) {
        this._expertSignUp(this._toArray(values));
      } else {
        this._updateExpert(this._toArray(values));
      }
    }

    // Save "Location" form of expert
    if (currentStep === 3) {
      this.props.history.push(
        `/expert-signup?section=${EXPERT_SIGNUP_TELL_US_ABOUT_YOUR_SELF_TEXT}`
      );
      this._updateExpert(this._toArray(values));
    }

    // Save "Tell us about yourself!" form of expert
    if (currentStep === 4) {
      this.props.history.push(
        `/expert-signup?section=${EXPERT_SIGNUP_SKILLS_AND_TOOLS_TEXT}`
      );
      this._updateExpert(this._toArray(values));
    }

    // Save "Skills & Tools"  form in the final step
    if (currentStep === 5) {
      this.props.history.push(
        `/expert-signup?section=${EXPERT_SIGNUP_REQUEST_RECEIVED_TEXT}`
      );
      this._updateExpert(this._toArray(values));
    }
  }

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define expert sign up constants
    const firstName = values.firstName;
    const lastName = values.lastName;
    const email = values.email;
    const password = values.password;
    const confirmPassword = values.confirmPassword;
    const jobTitle = values.jobTitle;
    const description = values.description;

    const passwordError = validateStrongPassword(password);

    switch (this.state.currentStep) {
      case 2:
        if (firstName == null || lastName == null || email == null) {
          success = false;
        } else if (passwordError) {
          this.setState({
            passwordMismatchError: passwordError
          });
          success = false;
        } else if (
          password &&
          confirmPassword &&
          password !== confirmPassword
        ) {
          this.setState({
            passwordMismatchError: "",
            confirmPasswordMismatchError: "Confirm password did not match"
          });
          success = false;
        } else {
          this.setState({
            passwordMismatchError: "",
            confirmPasswordMismatchError: ""
          });
        }
        break;
      case 3:
        if (jobTitle == null || description == null) {
          success = false;
        }
        break;
      default:
        break;
    }

    return success;
  }

  // Handle password error messages
  _handlePasswordErrors = e => {
    const password = e.values.password;
    const confirmPassword = e.values.confirmPassword;
    const passwordError = validateStrongPassword(password);
    if (passwordError) {
      this.setState({
        passwordMismatchError: passwordError
      });
    } else if (password && confirmPassword && password !== confirmPassword) {
      this.setState({
        passwordMismatchError: "",
        confirmPasswordMismatchError: "Confirm password did not match"
      });
    } else {
      this.setState({
        passwordMismatchError: "",
        confirmPasswordMismatchError: ""
      });
    }
  };

  // Close the wizard
  _close() {
    window.location = "/dashboard";
  }

  // Render square box
  _renderSquare(currentValue) {
    const currentStep = this.state.currentStep;
    const showActive = currentStep === currentValue ? "active" : "in-active";
    return currentStep < 6 ? <div className={`box ${showActive}`} /> : "";
  }

  // Render wizard indicator
  _renderWizardIndicator() {
    return (
      <div className="expert-request-active-btn-section d-flex">
        {[1, 2, 3, 4, 5, 6].map(n => {
          return this._renderSquare(n);
        })}
      </div>
    );
  }

  // To Array
  _toArray(data) {
    let values = { ...data };
    const firstName = values.firstName;
    const lastName = values.lastName;
    const email = values.email;
    const password = values.password;
    const toolsType = values.toolsType;
    const expertType = values.expertType;
    const industriesType = values.industriesType;

    this.setState({
      firstName,
      lastName,
      email,
      yearsOfExperience: values.yearsOfExperience,
      timezone: values.timezone,
      toolsType,
      expertType,
      industriesType
    });

    values.firstName = toString(values.firstName);
    values.lastName = toString(values.lastName);
    values.email = toString(values.email);
    values.password = toString(password);
    values.phone = toString(
      values.phone && removeMaskedPhoneNumber(values.phone)
    );
    values.countryId = toString(values.countryId);
    values.countryName = toString(
      values.country ? values.country.value : values.country
    );
    values.address1 = toString(values.address1);
    values.address2 = toString(values.address2);
    values.city = toString(values.city);
    values.stateId = toString(values.stateId);
    values.stateName = toString(
      values.state ? values.state.value : values.state
    );
    values.yearsExperience = toString(
      values.yearsOfExperience
        ? values.yearsOfExperience.value
        : values.yearsOfExperience
    );
    values.availability = toString(
      values.availability ? values.availability.value : values.availability
    );
    values.timeZone = toString(
      values.timezone ? values.timezone.value : values.timezone
    );
    values.acceptTerms = values.acceptTerms ? "Yes" : "No";
    values.jobTitle = toString(values.jobTitle);
    values.expertType = toString(this.state.jobTitle);
    values.description = toString(values.description);
    values.aboutMe = toString(values.description);
    values.expertType = fromArray(expertType);
    values.tools = fromArray(toolsType);
    values.industries = fromArray(industriesType);

    return values;
  }

  // Expert SignUp API Call
  _expertSignUp(data) {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    let currentStep = this.state.currentStep;

    return apiClient
      .post(endpoints().expertSignUp, data)
      .then(response => {
        // If the current step is 5 or 6, then add one on "next" button click
        currentStep = currentStep >= 6 ? 6 : currentStep + 1;
        this.setState(
          {
            currentStep: currentStep,
            expertSignup: true,
            currentUserId: response.data.userId
          },
          () => {
            const { token, role, userId } = response.data;

            setCookie(COOKIE_SESSION_TOKEN, token);
            setCookie(COOKIE_USER_ID, userId);

            clearCookie("googleAuthToken");
            clearCookie("office365AuthToken");
            clearCookie("linkedinAuthToken");
            clearCookie("salesforceAuthToken");
          }
        );

        this._updateExpertProfileCompletion(response.data.userId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          this.setState({
            errorMessage
          });
        }
      });
  }

  // Update Expert Details
  _updateExpert(data) {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    let currentStep = this.state.currentStep;

    return apiClient
      .put(endpoints().expertSignUpUpdate, data)
      .then(response => {
        // If the current step is 5 or 6, then add one on "next" button click
        currentStep = currentStep >= 6 ? 6 : currentStep + 1;
        this.setState({
          currentStep: currentStep,
          passwordToken: response.data.password_token
        });

        this._updateExpertProfileCompletion(this.state.currentUserId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          this.setState({
            errorMessage
          });
        }
      });
  }

  // Update Expert Details
  _expertProfileUpdate(details) {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    const data = new FormData();
    data.append("file", details.file);
    data.append("email", details.email);

    return apiClient
      .put(endpoints().expertSignUpAvatarUpdate, data)
      .then(() => {})
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  handleJobTitle = ({ values }) => {
    this.setState({ jobTitle: values.jobTitle });
  };

  render() {
    const {
      currentStep,
      height,
      email,
      errorMessage,
      passwordMismatchError,
      confirmPasswordMismatchError,
      yearsExperience,
      jobTitle,
      selectedCountry
    } = this.state;

    const { settings } = this.props;

    const expertSignupWelcomeMessage = settings
      ? getKeyValueByObject(settings, SETTINGS_EXPERT_SIGNUP_WELCOME_MESSAGE)
      : "";

    const expertSignupHeroImage =
      settings &&
      getKeyValueByObject(settings, SETTINGS_EXPERT_SIGNUP_HERO_IMAGE)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, SETTINGS_EXPERT_SIGNUP_HERO_IMAGE)
          )
        : "";

    const backgroundImage =
      settings && getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
          )
        : "";

    const marketplaceName = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_NAME)
      : "";

    const enableSalesforceLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_SALESFORCE_LOGIN)
      : "";

    const enableGoogleLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_GOOGLE_LOGIN)
      : "";

    const enableOffice365Login = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_OFFICE_365_LOGIN)
      : "";

    const enableLinkedInLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_LINKEDIN_LOGIN)
      : "";

    const linkedInClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_CLIENT_ID)
      : "";

    const linkedInRedirectUri = settings
      ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_REDIRECT_URI)
      : "";

    let initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      password: "",
      confirmPassword: "",
      reference: "",
      yearsOfExperience: "",
      timezone: "",
      acceptTerms: "",
      jobTitle: jobTitle || "",
      description: "",
      address1: "",
      address2: "",
      country: {
        value: UNITED_STATES,
        label: UNITED_STATES
      },
      state: "",
      city: "",
      phone: "",
      availability: "",
      trailheadUrl: "",
      zipcode: "",
      selectedCountry: ""
    };

    return (
      <div
        className="container-fluid"
        style={{ height: height, minHeight: "100vh" }}
      >
        <div className="row overflow-hidden" style={{ height: "100%" }}>
          <LeftSideSection
            height={height}
            expertSignupHeroImage={expertSignupHeroImage}
            backgroundImage={backgroundImage}
          />
          <div
            className="col"
            style={{ background: "#fff", overflowY: "scroll", height: "100%" }}
          >
            <div className="row find-an-expert-budget-right-side-section form-wrapper flex-column">
              {this._renderWizardIndicator()}
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  if (getCookie("googleAuthToken")) {
                    values.googleAuthToken = getCookie("googleAuthToken");
                  }
                  if (getCookie("office365AuthToken")) {
                    values.office365AuthToken = getCookie("office365AuthToken");
                  }
                  if (getCookie("linkedinAuthToken")) {
                    values.linkedinAuthToken = getCookie("linkedinAuthToken");
                  }
                  if (getCookie("salesforceAuthToken")) {
                    values.salesforceAuthToken = getCookie(
                      "salesforceAuthToken"
                    );
                  }
                  this._submit(values);
                }}
                onReset={() => {
                  this.setState({ confirmPasswordMismatchError: "" });
                  return this._next();
                }}
              >
                <GetStarted
                  step={1}
                  currentStep={currentStep}
                  expertSignupWelcomeMessage={expertSignupWelcomeMessage}
                  enableSalesforceLogin={enableSalesforceLogin}
                  enableOffice365Login={enableOffice365Login}
                  enableGoogleLogin={enableGoogleLogin}
                  enableLinkedInLogin={enableLinkedInLogin}
                  linkedInClientId={linkedInClientId}
                  linkedInRedirectUri={linkedInRedirectUri}
                  marketplaceName={marketplaceName}
                  next={this._next}
                />

                <BasicInformation
                  step={2}
                  currentStep={currentStep}
                  errorMessage={errorMessage}
                  passwordMismatchError={passwordMismatchError}
                  confirmPasswordMismatchError={confirmPasswordMismatchError}
                  yearsExperience={yearsExperience}
                  expertSignup={this.state.expertSignup}
                  handlePasswordErrors={this._handlePasswordErrors}
                  next={this._submit}
                  prev={this._prev}
                />

                <Location
                  step={3}
                  currentStep={currentStep}
                  errorMessage={errorMessage}
                  yearsExperience={yearsExperience}
                  expertSignup={this.state.expertSignup}
                  selectedCountry={selectedCountry}
                  next={this._submit}
                  prev={this._prev}
                />

                <TellUsAboutYourself
                  step={4}
                  firstName={this.state.firstName}
                  lastName={this.state.lastName}
                  email={this.state.email}
                  marketplaceName={marketplaceName}
                  handleJobTitle={this.handleJobTitle}
                  currentStep={currentStep}
                  fileUpload={this._expertProfileUpdate}
                  next={this._submit}
                  prev={this._prev}
                />

                <SkillsAndTools
                  step={5}
                  currentStep={currentStep}
                  next={this._submit}
                  prev={this._prev}
                />

                <RequestReceived
                  step={6}
                  currentStep={currentStep}
                  close={this._close}
                  prev={this._prev}
                  email={email}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ExpertSignUp;
