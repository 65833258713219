import React, { useEffect, useState } from "react";

// Components
import Form from "../../../components/base/Form";
import ReferralFormFields from "./ReferralFormFields";
import CancelButton from "../../../components/base/CancelButton";
import Button from "../../../components/base/Button";

//Sidebar
import SideBar from "./components/SideBar";

import ReferralRequestService from "../../../services/ReferralRequestService";

import { COLOR_DANGER, COLOR_SUCCESS } from "../../../common/color";

import AdminActionNavigation from "../../../components/base/AdminActionNavigation";

import Terminology from "../../../lib/Terminology";

import SystemName from "../../../Constants/SystemName";

import PageTitle from "../../../components/base/PageTitle";

import { ChevronRight } from "../../../assets/img/icons";

import {
  REFERRAL_STATUS_ACCEPTED,
  REFERRAL_STATUS_REJECTED,
  REFERRAL_STATUS_REQUESTED
} from "../../../referral/Constants";
import {
  isPartner,
  isCompanyAdmin,
  isCompanyManager,
  isSuperAdmin
} from "../../../lib/helper";

const EditReferralRequest = props => {
  const [referralDetail, setReferralDetail] = useState("");

  const id = props.match.params.id;

  useEffect(() => {
    getReferralDetail();
  }, []);

  const getReferralDetail = () => {
    ReferralRequestService.get(id, response => {
      if (response && response.data) {
        let accountObj = response.data;
        setReferralDetail(accountObj);
      }
    });
  };

  let initialValues = {
    accountName: referralDetail && referralDetail.accountName,
    accountUrl: referralDetail && referralDetail.accountUrl
  };

  const updateReferral = values => {
    if (props.location && props.location.state) {
      values.accountList = props.location.state.accountList;
    }
    ReferralRequestService.update(values, id);
  };

  const updateStatus = status => {
    ReferralRequestService.update({ status: status }, id, () => {
      getReferralDetail();
      if (status == REFERRAL_STATUS_ACCEPTED) {
        props.history.push({
          pathname: `/referral/add`,
          state: {
            accountName: referralDetail && referralDetail.accountName,
            requestReferralId: id,
            partnerId:
              referralDetail &&
              referralDetail.partnerDetail &&
              referralDetail.partnerDetail.id
          }
        });
      }
    });
  };

  return (
    <>
      <Form
        enableReinitialize={true}
        initialValues={initialValues}
        onSubmit={values => {
          updateReferral(values);
        }}
      >
        <AdminActionNavigation>
          <div className="d-flex align-items-center mt-2">
            <a
              href="javascript:void(0)"
              className="cursor-pointer"
              onClick={() => {
                this.props.history.push("/referrals");
              }}
            >
              {`${Terminology.get(SystemName.REFERRAL)}`}
            </a>
            <span
              className="text-dark d-inline-flex align-items-center"
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <span
              onClick={e => e.preventDefault()}
              className={[
                "text-dark",
                "font-weight-bold",
                "d-inline-flex",
                "align-items-center",
                "project-name",
                "w-100"
              ].join(" ")}
            >
              <span className="ellipsis">
                {referralDetail.account_name ||
                  `Edit ${Terminology.get(SystemName.REFERRAL)}`}
              </span>
            </span>
          </div>

          {(isPartner(props.loggedInUser && props.loggedInUser.id) ||
            isCompanyAdmin(props.loggedInUser && props.loggedInUser.id) ||
            isCompanyManager(props.loggedInUser && props.loggedInUser.id) ||
            isSuperAdmin(props.loggedInUser && props.loggedInUser.id)) && (
            <div className="align-items-center">
              {referralDetail &&
                referralDetail.status == REFERRAL_STATUS_REQUESTED && (
                  <Button
                    color={COLOR_DANGER}
                    label={"Reject"}
                    align={"left"}
                    onClick={() => {
                      updateStatus(REFERRAL_STATUS_REJECTED);
                    }}
                  />
                )}
              {referralDetail &&
                referralDetail.status == REFERRAL_STATUS_REQUESTED && (
                  <Button
                    color={COLOR_SUCCESS}
                    label={"Accept"}
                    align={"left"}
                    onClick={() => {
                      updateStatus(REFERRAL_STATUS_ACCEPTED);
                    }}
                  />
                )}
            </div>
          )}
        </AdminActionNavigation>

        <div className="d-flex justify-content-between align-items-center mb-2">
          <PageTitle
            label={`${Terminology.get(SystemName.REFERRAL)} Details `}
          />
        </div>
        <div className="card border-0">
          <div className="project-details-body">
            <ReferralFormFields />
            <SideBar
              partnerDetail={referralDetail && referralDetail.partnerDetail}
              referralStatus={
                referralDetail && referralDetail.status
                  ? referralDetail.status
                  : ""
              }
            />
          </div>
        </div>
        <div className="pt-3 pb-4 pull-left">
          <CancelButton
            className="ml-auto mr-1"
            onClick={() => (window.location.href = "/referrals")}
          />
          <Button
            id="submitButton"
            label={`Save`}
            type="submit"
            className="btn btn-primary"
          />
        </div>
      </Form>
    </>
  );
};

export default EditReferralRequest;
