import React, { useState, useEffect } from "react";

// Configs
import { endpoints } from "../configs";
// API call
import { apiClient } from "../apiClient";

import Select from "./base/Select";
import { isBadRequest } from "../common/http";
import { PARTNER_STATUS_APPROVED } from "../../src/partnerStatus/Constants";
import AvatarCard from "./base/AvatarCard";

const PartnerDropDown = ({
  name,
  label,
  fontBolded,
  placeholder,
  handleChange,
  id,
  required
}) => {
  const [partnerList, setPartnerList] = useState();

  // Get Partner list
  const getPartnersList = () => {
    apiClient
      .get(
        `${
          endpoints().partnerAPI
        }/search?marketplaceStatus=${PARTNER_STATUS_APPROVED}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          setPartnerList(response.data.data);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  useEffect(() => {
    getPartnersList();
  }, []);

  // get avatar for each partner
  const getDropDownTemplate = (companyName, avatarUrl) => {
    return (
      <div className="">
        <AvatarCard
          companyName={companyName}
          size="xs"
          fontSize={8}
          url={avatarUrl}
        />
      </div>
    );
  };

  //Get options
  const getPartnerListOption = () => {
    const options = [];
    if (!partnerList) return options;

    partnerList.forEach(partner => {
      if (partner.companyName) {
        options.push({
          id: partner.id,
          value: partner.companyName,
          label: getDropDownTemplate(partner.companyName, partner.avatarUrl)
        });
      }
    });

    return options;
  };

  return (
    <Select
      id={id || name}
      name={name}
      label={label}
      fontBolded={fontBolded}
      options={getPartnerListOption()}
      placeholder={placeholder}
      isSearchable={true}
      handleChange={handleChange}
      required={required}
    />
  );
};

export default PartnerDropDown;
