import { apiClient } from "../apiClient";
import toast from "../components/base/Toast";
import { endpoints } from "../configs";
import { fetchList } from "./table";

import {
  REQUEST_UPDATE_PLAYBOOK_INQUIRY,
  RECEIVE_UPDATE_PLAYBOOK_INQUIRY,
  PLAYBOOK_INQUIRY_UPDATE_ERROR
} from "../actionType/Constants";

// Constants
import {
  LEAD_PARTNER_STATUS_PENDING,
  LEAD_STATUS_CLOSE,
  LEAD_STATUS_IN_PROGRESS
} from "../lead/Constants";
import { isBadRequest } from "../common/http";

/**
 * Request for updating lead
 */
export function requestUpdateLead() {
  return {
    type: REQUEST_UPDATE_PLAYBOOK_INQUIRY
  };
}

/**
 * Receive for updating lead
 */
export function receiveUpdateLead() {
  return {
    type: RECEIVE_UPDATE_PLAYBOOK_INQUIRY
  };
}

/**
 * Receive for error updating lead
 */
export function leadUpdateError(error) {
  return {
    type: PLAYBOOK_INQUIRY_UPDATE_ERROR,
    error
  };
}

/**
 * Update Lead Inquiry
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateLead(
  id,
  tableListId,
  data,
  paramsObj,
  updateTableListId
) {
  return dispatch => {
    dispatch(requestUpdateLead());
    apiClient
      .put(`${endpoints().lead}/status/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        // Table refresh data

        dispatch(
          fetchList("playbookInquiry", `${endpoints().lead}/search`, 1, 10, {
            status: LEAD_PARTNER_STATUS_PENDING,
            pagination: true
          })
        );
        dispatch(
          fetchList("leadInProgressList", `${endpoints().lead}/search`, 1, 10, {
            status: LEAD_STATUS_IN_PROGRESS,
            pagination: true
          })
        );
        dispatch(
          fetchList("leadConvertedList", `${endpoints().lead}/search`, 1, 10, {
            status: LEAD_STATUS_CLOSE,
            pagination: true
          })
        );
        dispatch(
          fetchList("leadAllList", `${endpoints().lead}/search`, 1, 10, {
            pagination: true
          })
        );
        dispatch(receiveUpdateLead());
      })
      .catch(error => {
        dispatch(leadUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}
