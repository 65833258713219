import React from "react";
import {
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_DECLINED,
  REFERRAL_STATUS_NEW,
  REFERRAL_STATUS_DRAFT,
  REFERRAL_STATUS_CLOSED,
  REFERRAL_STATUS_CLOSED_WON,
  REFERRAL_STATUS_EXPIRED,
  REFERRAL_STATUS_REQUESTED,
  REFERRAL_STATUS_ACCEPTED,
  REFERRAL_STATUS_REJECTED
} from "../../../referral/Constants";

// Components
import SidebarItem from "./SidebarItem";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

const ReferralStatus = ({ status }) => {
  return (
    <>
      <SidebarItem>
        <p className="font-weight-bold mb-1">
          {status === REFERRAL_STATUS_DRAFT
            ? `${Terminology.get(SystemName.REFERRAL)} Registration Status`
            : "Status"}
        </p>
        <div className="d-flex align-items-start justify-content-between">
          <div className="deal-status-line text-warning">
            {status === REFERRAL_STATUS_DRAFT ? REFERRAL_STATUS_DRAFT : ""}
          </div>
          <div className="deal-status-line text-warning">
            {status === REFERRAL_STATUS_NEW
              ? REFERRAL_STATUS_NEW
              : status == REFERRAL_STATUS_REQUESTED
              ? REFERRAL_STATUS_REQUESTED
              : ""}
          </div>
          <div
            className={`deal-status-line ${
              status === REFERRAL_STATUS_APPROVED
                ? "text-success"
                : status === REFERRAL_STATUS_ACCEPTED
                ? "text-success"
                : status == REFERRAL_STATUS_REJECTED
                ? "text-danger"
                : status === REFERRAL_STATUS_DECLINED
                ? "text-danger"
                : status == REFERRAL_STATUS_CLOSED
                ? "text-danger"
                : status == REFERRAL_STATUS_EXPIRED
                ? "text-danger"
                : "text-secondary"
            }`}
          >
            {status === REFERRAL_STATUS_APPROVED
              ? REFERRAL_STATUS_APPROVED
              : status == REFERRAL_STATUS_ACCEPTED
              ? REFERRAL_STATUS_ACCEPTED
              : status == REFERRAL_STATUS_REJECTED
              ? REFERRAL_STATUS_REJECTED
              : status === REFERRAL_STATUS_DECLINED
              ? REFERRAL_STATUS_DECLINED
              : status === REFERRAL_STATUS_CLOSED_WON
              ? REFERRAL_STATUS_CLOSED_WON
              : status == REFERRAL_STATUS_CLOSED
              ? REFERRAL_STATUS_CLOSED
              : status == REFERRAL_STATUS_EXPIRED
              ? status == REFERRAL_STATUS_REQUESTED
                ? REFERRAL_STATUS_REQUESTED
                : REFERRAL_STATUS_EXPIRED
              : ""}
          </div>
        </div>

        <div className="mt-2 d-flex align-items-center justify-content-between">
          <div
            className={`deal-status-line  border ${
              status === REFERRAL_STATUS_DRAFT
                ? "border-warning"
                : status === REFERRAL_STATUS_NEW ||
                  status === REFERRAL_STATUS_APPROVED ||
                  status === REFERRAL_STATUS_ACCEPTED ||
                  status === REFERRAL_STATUS_DECLINED ||
                  status == REFERRAL_STATUS_REQUESTED ||
                  status === REFERRAL_STATUS_REJECTED
                ? "border-success"
                : "border-secondary"
            }`}
          ></div>
          <div
            className={`deal-status-line  border ${
              status === REFERRAL_STATUS_NEW ||
              status == REFERRAL_STATUS_REQUESTED
                ? "border-warning"
                : status === REFERRAL_STATUS_APPROVED ||
                  status === REFERRAL_STATUS_DECLINED ||
                  status === REFERRAL_STATUS_ACCEPTED
                ? "border-success"
                : "border-secondary"
            }`}
          ></div>
          <div
            className={`deal-status-line border ${
              status === REFERRAL_STATUS_APPROVED ||
              status === REFERRAL_STATUS_ACCEPTED
                ? "border-success"
                : status === REFERRAL_STATUS_DECLINED ||
                  status === REFERRAL_STATUS_REJECTED ||
                  status == REFERRAL_STATUS_CLOSED ||
                  status == REFERRAL_STATUS_EXPIRED
                ? "border-danger"
                : "border-secondary"
            }`}
          ></div>
        </div>
      </SidebarItem>
    </>
  );
};

export default ReferralStatus;
