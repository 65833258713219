import React from "react";
import { toast } from "react-toastify";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import {
  toString,
  getParamsByName,
  removeMaskedPhoneNumber,
  getCookie,
  setCookie,
  clearCookie,
  validateStrongPassword,
  getKeyValueByObject,
  getSettingMediaUrl
} from "../../lib/helper";

// Components
import Form from "../../components/base/Form";

import LeftSideSection from "./LeftSideSection";
import GetStarted from "./GetStarted";
import BasicInformation from "./BasicInformation";
import RequestReceived from "./RequestReceived";

// Constants
import {
  PARTNER_USER_SIGNUP_BASIC_INFORMATION,
  PARTNER_USER_SIGNUP_BASIC_INFORMATION_TEXT,
  PARTNER_USER_SIGNUP_GETTING_STARTED,
  PARTNER_USER_SIGNUP_GETTING_STARTED_TEXT,
  PARTNER_USER_SIGNUP_REQUEST_RECEIVED,
  PARTNER_USER_SIGNUP_REQUEST_RECEIVED_TEXT
} from "../../partnerUserSignUp/Constants";
import { PARTNER_ROLE } from "../../roles/Constants";

import {
  SETTINGS_PARTNER_SIGNUP_WELCOME_MESSAGE,
  SETTINGS_PARTNER_SIGNUP_HERO_IMAGE,
  BACKGROUND_IMAGE_URL,
  SETTINGS_PORTAL_NAME,
  SETTINGS_ENABLE_SALESFORCE_LOGIN,
  SETTINGS_ENABLE_OFFICE_365_LOGIN,
  SETTINGS_ENABLE_GOOGLE_LOGIN,
  SETTINGS_ENABLE_LINKEDIN_LOGIN,
  SETTINGS_LINKEDIN_CLIENT_ID,
  SETTINGS_LINKEDIN_REDIRECT_URI
} from "../../setting/Constants";
import { COOKIE_SESSION_TOKEN, COOKIE_USER_ID } from "../../lib/cookie";
import { isBadRequest } from "../../common/http";

class PartnerSignUp extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      height: window.innerHeight,
      firstName: "",
      lastName: "",
      errorMessage: "",
      yearsExperience: "",
      yearsOfExperience: "",
      timezone: "",
      partnerRoles: [],
      passwordToken: "",
      jobTitle: "",
      passwordMismatchError: "",
      confirmPasswordMismatchError: "",
      currentUserId: null,
      partnerTypeList: [],
      partnerTypeId: null,
      partnerDetails: {},
      isLoading: true,
      userDetails: {},
      partnerUserId: getParamsByName("partnerUserId"),
      partnerId: getParamsByName("partnerId"),
      isUserDetailsLoading: false,
      token: "",
      role: "",
      userId: ""
    };

    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this._submit = this._submit.bind(this);
    this._close = this._close.bind(this);

    // Bind the update dimensions
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
    const section = getParamsByName("section");
    const partnerId = getParamsByName("partnerId");
    const partnerUserId = getParamsByName("partnerUserId");

    this.setState({ partnerUserId });

    if (partnerId) this.getPartnerDetails(partnerId);
    else this.setState({ isLoading: false });

    if (section === PARTNER_USER_SIGNUP_GETTING_STARTED_TEXT) {
      this.setState({
        currentStep: PARTNER_USER_SIGNUP_GETTING_STARTED
      });
    }
    if (section === PARTNER_USER_SIGNUP_BASIC_INFORMATION_TEXT) {
      this.setState({
        currentStep: PARTNER_USER_SIGNUP_BASIC_INFORMATION
      });
    }
    if (section === PARTNER_USER_SIGNUP_REQUEST_RECEIVED_TEXT) {
      this.setState({
        currentStep: PARTNER_USER_SIGNUP_REQUEST_RECEIVED
      });
    }

    if (section === null) {
      this.props.history.push(
        `/partner-user-signup?section=${PARTNER_USER_SIGNUP_GETTING_STARTED_TEXT}&partnerId=${partnerId}&partnerUserId=${partnerUserId}`
      );
    }

    this.fetchUserDetails();
  }

  fetchUserDetails = () => {
    this.setState({ isUserDetailsLoading: true }, () => {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

      const userId = this.state.partnerUserId;

      if (!userId) return this.setState({ isUserDetailsLoading: false });

      apiClient
        .get(`${endpoints().userAPI}/${userId}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }

          this.setState({
            userDetails: response.data || {},
            isUserDetailsLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }

            toast.error(errorMessage);
          }
        });
    });
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  // Update the screen height
  updateDimensions() {
    this.setState({
      height: window.innerHeight
    });
  }

  // Update param based on step selected
  updateParam(currentStep) {
    const sectionParam =
      currentStep === PARTNER_USER_SIGNUP_GETTING_STARTED
        ? PARTNER_USER_SIGNUP_GETTING_STARTED_TEXT
        : currentStep === PARTNER_USER_SIGNUP_BASIC_INFORMATION
        ? PARTNER_USER_SIGNUP_BASIC_INFORMATION_TEXT
        : currentStep === PARTNER_USER_SIGNUP_REQUEST_RECEIVED
        ? PARTNER_USER_SIGNUP_REQUEST_RECEIVED_TEXT
        : "";

    const partnerId = getParamsByName("partnerId");

    let query = `&partnerId=${this.state.partnerId}&partnerUserId=${this.state.partnerUserId}`;

    if (currentStep === PARTNER_USER_SIGNUP_BASIC_INFORMATION && partnerId) {
      query = `&partnerId=${partnerId}&partnerUserId=${this.state.partnerUserId}`;
    }

    this.props.history.push(
      `/partner-user-signup?section=${sectionParam}${query}`
    );
  }

  // _next functions will be called on button click
  _next = props => {
    let currentStep = this.state.currentStep;
    // If the current step is 5 or 6, then add one on "next" button click
    currentStep = currentStep >= 3 ? 3 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });
    this.updateParam(currentStep);
  };

  // _previous functions will be called on button click
  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
    this.updateParam(currentStep);
  }

  getPartnerDetails = partnerId => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

    return apiClient
      .get(`${endpoints().partnerSignUp}?id=${partnerId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        this.setState({
          partnerDetails: response.data,
          isLoading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          toast.error(errorMessage);
        }
      });
  };

  // Handle job run
  _updatePartnerProfileCompletion = async userId => {
    if (!userId) return null;

    try {
      await apiClient.put(
        `${endpoints().partnerAPI}/job/profileCompletionPercentageUpdate`,
        { userId }
      );
    } catch (error) {
      return null;
    }
  };

  // _previous functions will be called on button click
  _submit(values) {
    if (this._validateFields(values) === false) {
      return;
    }

    let currentStep = this.state.currentStep;
    const userId = this.state.partnerUserId;

    // Save "Basic Information" form of partner
    if (currentStep === 2) {
      this.props.history.push(
        `/partner-user-signup?section=${PARTNER_USER_SIGNUP_REQUEST_RECEIVED_TEXT}&partnerId=${this.state.partnerId}&partnerUserId=${this.state.partnerUserId}`
      );
      if (userId) {
        this._partnerUserSignUp(this._toArray(values), userId);
      }
    }
  }

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define partner sign up constants
    const firstName = values.firstName;
    const lastName = values.lastName;
    const email = values.email;
    const password = values.password;
    const confirmPassword = values.confirmPassword;
    const jobTitle = values.jobTitle;
    const description = values.description;

    const passwordError = validateStrongPassword(password);

    switch (this.state.currentStep) {
      case 2:
        if (firstName == null || lastName == null || email == null) {
          success = false;
        } else if (passwordError) {
          this.setState({
            passwordMismatchError: passwordError
          });
          success = false;
        } else if (
          password &&
          confirmPassword &&
          password !== confirmPassword
        ) {
          this.setState({
            passwordMismatchError: "",
            confirmPasswordMismatchError: "Confirm password did not match"
          });
          success = false;
        } else {
          this.setState({
            passwordMismatchError: "",
            confirmPasswordMismatchError: ""
          });
        }
        break;
      case 3:
        if (jobTitle == null || description == null) {
          success = false;
        }
        break;
      default:
        break;
    }

    return success;
  }

  // Handle password error messages
  _handlePasswordErrors = e => {
    const password = e.values.password;
    const confirmPassword = e.values.confirmPassword;
    const passwordError = validateStrongPassword(password);
    if (passwordError) {
      this.setState({
        passwordMismatchError: passwordError
      });
    } else if (password && confirmPassword && password !== confirmPassword) {
      this.setState({
        passwordMismatchError: "",
        confirmPasswordMismatchError: "Confirm password did not match"
      });
    } else {
      this.setState({
        passwordMismatchError: "",
        confirmPasswordMismatchError: ""
      });
    }
  };

  // Close the wizard
  _close() {
    window.location = "/dashboard";
  }

  // Render square box
  _renderSquare(currentValue) {
    const currentStep = this.state.currentStep;
    const showActive = currentStep === currentValue ? "active" : "in-active";
    return currentStep < 3 ? <div className={`box ${showActive}`} /> : "";
  }

  // Render wizard indicator
  _renderWizardIndicator() {
    return (
      <div className="expert-request-active-btn-section d-flex">
        {[1, 2, 3].map(n => {
          return this._renderSquare(n);
        })}
      </div>
    );
  }

  // To Array
  _toArray(data) {
    let values = { ...data };
    const firstName = values.firstName;
    const lastName = values.lastName;
    const email = values.email;
    const password = values.password;
    const partnerRoles = values.partnerRoles;
    const products = [];
    const industries = [];
    const abmExpertise = [];
    const marketingServices = [];
    const crmMarketings = [];
    const regions = [];

    this.setState({
      firstName,
      lastName,
      email
    });

    values.firstName = toString(values.firstName);
    values.lastName = toString(values.lastName);
    values.email = toString(values.email);
    values.password = toString(password);
    values.phone = toString(
      values.phone && removeMaskedPhoneNumber(values.phone)
    );
    values.acceptTerms = values.acceptTerms ? "Yes" : "No";
    values.roleId = PARTNER_ROLE;
    values.partnerUserId = this.state.partnerUserId;

    return values;
  }

  // Partner user SignUp API Call
  _partnerUserSignUp(data, userId) {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    let currentStep = this.state.currentStep;
    data.partnerUserSignup = true;

    return apiClient
      .put(`${endpoints().userAPI}/${userId}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        // If the current step is 5 or 6, then add one on "next" button click
        currentStep = 3;
        this.setState(
          {
            currentStep: currentStep,
            currentUserId: response.data.userId
          },
          () => {
            const { token, role, userId } = response.data;

            setCookie(COOKIE_SESSION_TOKEN, token);
            setCookie(COOKIE_USER_ID, userId);

            clearCookie("googleAuthToken");
            clearCookie("office365AuthToken");
            clearCookie("linkedinAuthToken");
            clearCookie("salesforceAuthToken");
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          this.setState({
            errorMessage
          });
        }
      });
  }

  handleJobTitle = ({ values }) => {
    this.setState({ jobTitle: values.jobTitle });
  };

  setUserDetails = data => {
    const userDetails = {
      ...this.state.userDetails,
      firstName: data.firstName || "",
      lastName: data.lastName || "",
      email: data.email || "",
      authToken: data.authToken || ""
    };

    this.setState({ userDetails, currentStep: 2, isLoading: true }, () =>
      this.setState({ isLoading: false })
    );
  };

  render() {
    const {
      currentStep,
      height,
      email,
      errorMessage,
      passwordMismatchError,
      confirmPasswordMismatchError,
      yearsExperience,
      jobTitle,
      defaultRegion,
      partnerDetails,
      isLoading,
      userDetails,
      isUserDetailsLoading
    } = this.state;

    if (isLoading || isUserDetailsLoading) return "";

    const { settings } = this.props;

    const partnerSignupWelcomeMessage = settings
      ? getKeyValueByObject(settings, SETTINGS_PARTNER_SIGNUP_WELCOME_MESSAGE)
      : "";

    const partnerSignupHeroImage =
      settings &&
      getKeyValueByObject(settings, SETTINGS_PARTNER_SIGNUP_HERO_IMAGE)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, SETTINGS_PARTNER_SIGNUP_HERO_IMAGE)
          )
        : "";
    const backgroundImage =
      settings && getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
          )
        : "";
    const marketplaceName = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_NAME)
      : "";

    const enableSalesforceLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_SALESFORCE_LOGIN)
      : "";

    const enableGoogleLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_GOOGLE_LOGIN)
      : "";

    const enableOffice365Login = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_OFFICE_365_LOGIN)
      : "";

    const enableLinkedInLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_LINKEDIN_LOGIN)
      : "";

    const linkedInClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_CLIENT_ID)
      : "";

    const linkedInRedirectUri = settings
      ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_REDIRECT_URI)
      : "";

    let initialValues = {
      firstName: "",
      lastName: "",
      email: userDetails.email || "",
      password: "",
      confirmPassword: "",
      acceptTerms: ""
    };

    return (
      <div
        className="container-fluid"
        style={{ height: height, minHeight: "100vh" }}
      >
        <div className="row overflow-hidden" style={{ height: "100%" }}>
          <LeftSideSection
            height={height}
            partnerSignupHeroImage={partnerSignupHeroImage}
            backgroundImage={backgroundImage}
          />
          <div
            className="col"
            style={{ background: "#fff", overflowY: "scroll", height: "100%" }}
          >
            <div className="row find-an-expert-budget-right-side-section form-wrapper flex-column">
              {this._renderWizardIndicator()}
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  if (getCookie("googleAuthToken")) {
                    values.googleAuthToken = getCookie("googleAuthToken");
                  }
                  if (getCookie("office365AuthToken")) {
                    values.office365AuthToken = getCookie("office365AuthToken");
                  }
                  if (getCookie("linkedinAuthToken")) {
                    values.linkedinAuthToken = getCookie("linkedinAuthToken");
                  }
                  if (getCookie("salesforceAuthToken")) {
                    values.salesforceAuthToken = getCookie(
                      "salesforceAuthToken"
                    );
                  }

                  values.partnerId = getParamsByName("partnerId");

                  this._submit(values);
                }}
                onReset={() => {
                  this.setState({ confirmPasswordMismatchError: "" });
                  return this._next();
                }}
              >
                <GetStarted
                  step={1}
                  currentStep={currentStep}
                  partnerSignupWelcomeMessage={partnerSignupWelcomeMessage}
                  setUserDetails={data => this.setUserDetails(data)}
                  next={this._next}
                  enableSalesforceLogin={enableSalesforceLogin}
                  enableOffice365Login={enableOffice365Login}
                  enableGoogleLogin={enableGoogleLogin}
                  enableLinkedInLogin={enableLinkedInLogin}
                  linkedInClientId={linkedInClientId}
                  linkedInRedirectUri={linkedInRedirectUri}
                  marketplaceName={marketplaceName}
                />

                <BasicInformation
                  step={2}
                  partnerDetails={partnerDetails}
                  currentStep={currentStep}
                  errorMessage={errorMessage}
                  passwordMismatchError={passwordMismatchError}
                  confirmPasswordMismatchError={confirmPasswordMismatchError}
                  yearsExperience={yearsExperience}
                  handlePasswordErrors={this._handlePasswordErrors}
                  next={this._submit}
                  prev={this._prev}
                />

                <RequestReceived
                  step={3}
                  currentStep={currentStep}
                  close={this._close}
                  prev={this._prev}
                  email={email}
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerSignUp;
