import React, { Component } from "react";

// Components
import Text from "../../../components/base/Text";
import Email from "../../../components/base/Email";
import Phone from "../../../components/base/Phone";
import Zipcode from "../../../components/base/Zipcode";
import State from "../../../components/State";
import Country from "../../../components/base/Country";
import { UNITED_STATES } from "../../../Country/Constants";

class CompanyFormFields extends Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedOption: "",
      selectedCountryName: this.props.compnyDetails.country
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
  }
  // Handle Country
  handleCountryChange = ({ values }) => {
    const selectedOption = values.countryName && values.countryName.value;
    const selectedCountryName = values.countryName && values.countryName.label;
    this.setState({
      selectedOption,
      selectedCountryName: selectedCountryName
    });
  };
  // Render the form fields
  render() {
    const { selectedCountry } = this.props;

    const { selectedOption, selectedCountryName } = this.state;
    return (
      <div>
        <div className="card bg-white mt-3 mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div>
                  <Text
                    name="companyName"
                    label="Company Name"
                    placeholder="Enter Company Name"
                    error=""
                    required={true}
                  />
                </div>
                <div>
                  <Email
                    name="email"
                    label="Email"
                    placeholder="Enter Email"
                    error=""
                    required={true}
                  />
                </div>
                <div>
                  <Phone
                    name="phone"
                    label="Phone Number"
                    placeholder="Enter phone number"
                    required={true}
                    error=""
                  />
                </div>
                <div className="field-wrapper">
                  <Text
                    autoComplete="nope"
                    name="address1"
                    label="Street Address 1"
                    placeholder="Enter street address 1"
                    required={true}
                    error=""
                  />
                </div>

                <div className="field-wrapper">
                  <Text
                    autoComplete="nope"
                    name="address2"
                    label="Street Address 2"
                    placeholder="Enter street address 2"
                  />
                </div>

                <div className="field-wrapper">
                  <Text
                    name="city"
                    label="City"
                    placeholder="Enter city"
                    required={true}
                    error=""
                  />

                  <Zipcode
                    name="zipCode"
                    label="Zip/Postal Code"
                    placeholder="Enter Zipcode"
                    error=""
                    required={true}
                    locale={
                      selectedCountryName ? selectedCountryName : UNITED_STATES
                    }
                  />
                </div>
                <div className="field-wrapper">
                  <State
                    name="state"
                    label="State/Province/Region"
                    placeholder="Enter State"
                    error=""
                    selectedCountry={
                      selectedOption ? selectedOption : selectedCountry
                    }
                    required={true}
                  />

                  <Country
                    name="country"
                    label="Country"
                    placeholder="Select Country"
                    error=""
                    required={true}
                    onChange={this.handleCountryChange}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CompanyFormFields;
