import React, { Component } from "react";
import {
  BrowserRouter as Router,
  Route,
  Redirect,
  Switch
} from "react-router-dom";

import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import Loadable from "react-loadable";
import { Auth0Provider } from "@auth0/auth0-react";

// ReactToastify CSS
import "react-toastify/dist/ReactToastify.min.css";

// History
import history from "./history";

// SCSS
import "./App.scss";

// Helper
import { getCookie, getKeyValueByObject, getParamsByName } from "./lib/helper";

import MyProvider from "./context/MyProvider";

/** Layouts **/
import DefaultLayout from "./containers/DefaultLayout";
import PublicPageLayout from "./containers/PublicPageLayout";

/** Views **/
import Dashboard from "./views/dashboard";
import LoginPage from "./views/login";

// Expert
import ExpertSignUp from "./views/expertSignUp";
import FindAnExpert from "./views/findAnExpert";
import ExpertRequest from "./views/expertRequest";
import ExpertRequestDetails from "./views/expertRequest/ExpertRequestDetails";
import ExpertCapacity from "./views/expertCapacity";
import ExpertProfile from "./views/sourceExperts/ExpertProfile";
import MarketplaceSourcing from "./views/marketplaceSourcing";
import ExpertsAvailability from "./views/expertAvailability";

// Partner
import PartnerSignUp from "./views/partnerSignUp";
import PartnerProfile from "../src/partners/PartnerProfile";
import PartnerList from "../src/views/partner/PartnerList";
import PartnersDirectory from "./views/partnersDirectory";
import PartnerRequest from "./views/partnerRequest";
import PartnerRequestDetails from "./views/partnerRequest/PartnerRequestDetails";
import Activities from "./views/activities";
import Message from "./views/message";
import MessageDetail from "./views/message/Detail";
// Embedded Partners Directory
import EmbedpartnersDirectory from "./views/embedpartnersDirectory";

// Embedded Partners Profile
import EmbedPartnerProfile from "../src/partners/EmbedPartnerProfile";

// Customer
import Customers from "./views/customers";
import AddCustomer from "./views/customers/AddCustomer";
import CustomerDetails from "./views/customers/CustomerDetails";

// Setting
import Settings from "./views/adminSettings/index";

//General Settings
import GeneralSettings from "./views/generalSettings";

// Tag
import Tags from "./views/tag";
import AddTag from "./views/tag/AddTag";
import TagDetails from "./views/tag/TagDetails";

// Tag Type
import TagType from "./views/tagType";
import AddTagType from "./views/tagType/AddTagType";
import TagTypeDetails from "./views/tagType/TagTypeDetails";

// Project
import Projects from "./views/projects";
import AddProject from "./views/projects/AddProject";
import ProjectEdit from "./views/projects/ProjectEdit";
import ProjectDetails from "./views/projects/ProjectDetails";

// Potential Projects
import PotentialProjects from "./views/potentialProjects";

// Project Category
import ProjectCategory from "./views/projectCategory";
import AddProjectCategory from "./views/projectCategory/AddProjectCategory";
import ProjectCategoryDetails from "./views/projectCategory/ProjectCategoryDetails";

// Users
import Users from "./views/users";
import AddUser from "./views/users/AddUser";
import UserDetails from "./views/users/userDetails";

// Playbook
import Playbook from "./views/playbook";
import AddPlaybook from "./views/playbook/AddPlaybook";
import PlaybookDetails from "./views/playbook/PlaybookDetails";

// Collection
import Collection from "./views/collection";
import AddCollection from "./views/collection/AddCollection";
import CollectionDetails from "./views/collection/CollectionDetails";
import coSellDetail from "./views/coSell/detail";

// Payment
import AddPayment from "./views/payment/AddPayment";
import PaymentDetails from "./views/payment/PaymentDetails";
import Payment from "./views/payment";
import SinglePayment from "./views/payment/SinglePayment";

// Invoice
import AddInvoice from "./views/invoice/AddInvoice";
import InvoiceDetails from "./views/invoice/InvoiceDetails";
import Invoice from "./views/invoice";

// Reports
import Reports from "./views/reports";

import CoSell from "./views/coSell";

import Pipeline from "./views/reports/Pipeline";

// Feedback
import Feedback from "./views/feedback";
import FeedbackDetails from "./views/feedback/FeedbackDetails";

// Marketplace Page
import Marketplace from "./views/marketplace";

// Playbook View
import PlaybookView from "./views/playbookView";
import PlaybookOrderDetails from "./views/playbookView/PlaybookOrderDetails";
import Checkout from "./views/playbookView/Checkout";

// Landing Page Blocks page
import LandingPageBlocksDetails from "./views/landingPageBlocks/LandingPageBlocksDetails";
import Help from "./views/help";

// Edit My Profile
import EditMyGeneralProfile from "./views/editMyGeneralProfile";

// Edit Expert Public Profile
import EditExpertProfile from "./views/editProfiles/expert/EditExpertProfile";

// Edit Partner Public Profile
import EditPartnerProfile from "./views/editPartnerProfiles/EditPartnerProfile";

// Forgot password
import ForgotPassword from "./views/forgotPassword";

// Edit My Profile
import SetPassword from "./views/setPassword";

//User Management
import UserManagement from "./views/UserManagement";

// Salesforce
import Salesforce from "./views/supportPortal/tabs/salesforce";
import SalesforceContact from "./views/supportPortal/tabs/salesforce/contact";
import SalesforceAccount from "./views/supportPortal/tabs/salesforce/account";
import SalesforceOpportunity from "./views/supportPortal/tabs/salesforce/opportunity";

// Salesforce
import Deals from "./views/deals";

// Account Create
import customerSignup from "./views/customerSignup";
import AccountingSettings from "./views/accountingSettings";

//Logout
import Logout from "./views/logout";
import { COOKIE_SESSION_TOKEN } from "./lib/cookie";

//Logout
import ContactForms from "./views/contactForms";
import ContactFormDetails from "./views/contactForms/ContactFormDetails";

// Add Deal
import AddDeal from "./views/deals/AddDeal";
import DealRegister from "./views/DealRegister";

// Deal Detail
import DealDetails from "./views/deals/UpdateDealForm";
// Playbook Inquiry List
import PlaybookInquiry from "./views/playbookInquiry";
import PlaybookInquiryDetails from "./views/playbookInquiry/PlaybookInquiryDetails";

import PartnerContactDetails from "./views/PartnerContactSubmission/PartnerContactDetails";

// Intro to portal
import IntroToPortal from "./views/introToPortal";

// Leads
import Leads from "./views/leads";

// Partner Resource
import Resource from "./views/resource";
import AddResource from "./views/resource/AddResource";
import EditResource from "./views/resource/EditResource";
import Referral from "./views/referral";
import SubmitReferral from "./views/referral/SubmitReferral";
import EditReferral from "./referral/EditReferral";
import SubmitPublicReferral from "./views/submitReferral";

// Partner Course
import Course from "./views/course";
import EditCourse from "./views/course/EditCourse";
import TrainingCourse from "./views/course/TrainingCourse";

import DealRegisteredSuccessfully from "./views/DealRegister/success";
import ReferralRegisteredSuccessfully from "./views/submitReferral/success";

import Contacts from "./views/contacts";
import EditContact from "./views/contacts/EditContact";
import PartnerUserSignUp from "./views/partnerUserSignUp";

// Acounts
import Accounts from "./views/accounts";
import EditAccount from "./views/accounts/EditAccount";

// Companies
import CompanySignUp from "./views/companySignUp";
import Companies from "./views/companies";
import CompanyDetails from "./views/companies/CompanyDetails";

// Support Portal
import SupportPortal from "./views/supportPortal";

//salesforce Account Record Type
import RecordType from "./views/supportPortal/tabs/salesforce/recordType";

//AccountMapping Partner Records
import AccountMappingPartners from "./views/dashboard/AccountMappingPartners";
import CrossBeamReport from "./views/accountMapping/CrossBeamPopulations";

// MDF
import MDF from "./views/mdf";

// calendar
import Calendar from "../src/components/base/Calendar";

// Setting Constants
import {
  SETTINGS_PORTAL_DEFAULT_START_PAGE_URL,
  SETTINGS_AUTH0_DOMAIN_URL,
  SETTINGS_AUTH0_CLIENT_ID
} from "./setting/Constants";

import { TAB_GENERAL } from ".//setting/Constants";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Tags
import { fetchSettings } from "./actions/settings";
import { fetchPartnerSettings } from "./actions/partnerSettings";

import ReferralRedirect from "./views/referralRedirect";
import LoginByAuth0 from "./views/login/LoginByAuth0";
import SubmitMdfRequest from "./views/mdf/SubmitMdfRequest";
import EditMdfRequest from "./views/mdf/EditMdfRequest";

//App
import EditApp from "./views/app/EditApp";
import AppPublicProfile from "./views/app/Details";

//salesforce Account Record Type
import ReferralDetails from "./views/supportPortal/tabs/ReferralTab/ReferralDetails";

import { isPartner } from "../src/lib/helper";

import { fetchUserDetail } from "./services/UserService";
import ReferralRequest from "./views/referral/referralRequest";

import EditReferralRequest from "./views/referral/referralRequest/EditReferral";
import OverallSummary from "./views/reports/tabs/OverAllSummaryTab/index";
import ReferralReport from "./views/reports/ReferralReport/index";
import PartnerReport from "./views/reports/partnerReport/index";
import PartnerUserReport from "./views/reports/partnerUserReport/index";
import TwoFactorAuthentication from "./views/TwoFactorAuthentication";

import PartnerFiles from "./views/files";
import AddPage from "./views/coSell/Opportunity/addPage";

const loading = () => (
  <div className="animated fadeIn pt-3 text-center">Loading...</div>
);

// 404 error page
const Page404 = Loadable({
  loader: () => import("./views/Page404"),
  loading
});

/*
   App
 */
class App extends Component {
  bodyClass(authenticated) {
    if (!authenticated) {
      document.body.classList.remove("loggedin-layout");
    } else {
      document.body.classList.add("loggedin-layout");
    }
  }

  componentDidMount() {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      this.getUserDetail();
    }
    this.bodyClass(getCookie(COOKIE_SESSION_TOKEN));

    const partnerId = getParamsByName("partnerId");
    setTimeout(() => {
      this.props.actions.fetchSettings(partnerId);
    }, 500);
  }

  getUserDetail = async () => {
    this.props.actions.fetchUserDetail(response => {
      if (response && response.data) {
        if (isPartner(response.data.roleId)) {
          this.props.actions.fetchPartnerSettings();
        }
      }
    });
  };

  componentDidUpdate(prevProps) {
    const currentPath = window.location.pathname + window.location.search;

    this.bodyClass(getCookie(COOKIE_SESSION_TOKEN));
    if (
      this.props &&
      this.props.settings &&
      this.props.settings.settings &&
      currentPath === "/"
    ) {
      const settingList = this.props.settings.settings;

      const defaultStartPageUrl = getKeyValueByObject(
        settingList,
        SETTINGS_PORTAL_DEFAULT_START_PAGE_URL
      );

      const startPageUrl = defaultStartPageUrl ? defaultStartPageUrl : "/login";

      window.location.replace(startPageUrl);
    }
  }

  componentWillUnmount() {
    this.bodyClass(getCookie(COOKIE_SESSION_TOKEN));
  }

  customAuthHandler = () => {
    // Redirect to the /login page that has a CustomLoginComponent
    this.props.history.push("/login");
  };

  render() {
    const { settings, partnerSettings, user } = this.props;
    const settingList = settings && settings.settings;
    if (!settingList) return "";

    return (
      <div>
        <Auth0Provider
          domain={
            (settingList &&
              getKeyValueByObject(settingList, SETTINGS_AUTH0_DOMAIN_URL)) ||
            ""
          }
          clientId={
            (settingList &&
              getKeyValueByObject(settingList, SETTINGS_AUTH0_CLIENT_ID)) ||
            ""
          }
          redirectUri={`${window.location.origin}/auth0/login`}
        >
          <MyProvider>
            <div className="routeSection">
              <Router history={history}>
                <Switch>
                  <Route exact path="/"></Route>

                  <PublicPageLayout
                    exact
                    name="Login"
                    path="/login"
                    component={LoginPage}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Auth0 Login"
                    path="/auth0/login"
                    component={LoginByAuth0}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="logout"
                    path="/logout"
                    component={Logout}
                    settings={settings}
                    user={user}
                  />

                  <Route
                    exact
                    name="Linkedin"
                    path="/linkedin"
                    component={LinkedInPopUp}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Salesforce"
                    path="/salesforce"
                    component={Salesforce}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Salesforce"
                    path="/support-portal/salesforce/contact"
                    component={SalesforceContact}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Salesforce"
                    path="/support-portal/salesforce/account"
                    component={SalesforceAccount}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Salesforce"
                    path="/support-portal/salesforce/opportunity"
                    component={SalesforceOpportunity}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Salesforce"
                    path="/support-portal/referral/:id"
                    component={ReferralDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Salesforce"
                    path="/support-portal/salesforce/RecordType"
                    component={RecordType}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Help"
                    path="/help"
                    component={Help}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Dashboard"
                    path="/dashboard"
                    component={Dashboard}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Expert SignUp"
                    path="/expert-signup"
                    component={ExpertSignUp}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Partner SignUp"
                    path="/partner-signup"
                    component={PartnerSignUp}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Partner User SignUp"
                    path="/partner-user-signup"
                    component={PartnerUserSignUp}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Partner Requests"
                    path="/partner/requests"
                    component={PartnerRequest}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Partner Request Details"
                    path="/partner-requests/details/:id"
                    component={PartnerRequestDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Partner Communications"
                    path="/partner-communications"
                    component={Message}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Partner Communications"
                    path="/communication/:id"
                    component={MessageDetail}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Activities"
                    path="/activities"
                    component={Activities}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Find An Expert"
                    path="/find-an-expert"
                    component={FindAnExpert}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Set Password"
                    path="/set-password"
                    component={SetPassword}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Forgot Password"
                    path="/forgot-password"
                    component={ForgotPassword}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Customer Signup"
                    path="/customer-signup"
                    component={customerSignup}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Expert Requests"
                    path="/expert/requests"
                    component={ExpertRequest}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Expert Capacity"
                    path="/expert/capacity"
                    component={ExpertCapacity}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Expert Profile"
                    path="/expert-profile/:id"
                    component={ExpertProfile}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Partner Profile"
                    path="/partner-profile/:id"
                    component={PartnerProfile}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Embedded Partner Profile"
                    path="/embed/partner-profile/:id"
                    component={EmbedPartnerProfile}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Partners"
                    path="/partners-directory"
                    component={PartnersDirectory}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Embed Partners"
                    path="/embed/partners-directory"
                    component={EmbedpartnersDirectory}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Sourcing Experts"
                    path="/marketplace-sourcing"
                    component={MarketplaceSourcing}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Partner Profile"
                    path="/partner-profile/:id"
                    component={PartnerProfile}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Edit App"
                    path="/marketplace-sourcing/app/edit"
                    component={EditApp}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="AppPublicProfile"
                    path="/app/:id"
                    component={AppPublicProfile}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Expert Requests"
                    path="/expert-requests"
                    component={ExpertRequest}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Expert Request Details"
                    path="/leads/expert-requests/details/:id"
                    component={ExpertRequestDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Expert Availability"
                    path="/expert-availability"
                    component={ExpertsAvailability}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Partners"
                    path="/partners"
                    component={PartnerList}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Customers"
                    path="/customers"
                    component={Customers}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Add Customer"
                    path="/customer/add"
                    component={AddCustomer}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Customer Details"
                    path="/customer/:id"
                    component={CustomerDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <Route exact path="/admin-settings">
                    <Redirect to={`admin-settings/${TAB_GENERAL}`} />
                  </Route>

                  <DefaultLayout
                    exact
                    name="Admin Settings"
                    path={[
                      "/admin-settings/:tab",
                      "/admin-settings/:tab/:subtab",
                      "/admin-settings/:tab/:subtab/:section",
                      "/admin-settings/:tab/:subtab/:section/:subsection"
                    ]}
                    component={Settings}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Accounting Settings"
                    path="/accounting-settings"
                    component={AccountingSettings}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="My Settings"
                    path="/my-settings"
                    component={Settings}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="General Settings"
                    path="/settings/general"
                    component={GeneralSettings}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Edit Profile"
                    path="/edit/profile"
                    component={EditMyGeneralProfile}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Edit Profile"
                    path="/edit/public-profile"
                    component={EditExpertProfile}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Edit Profile"
                    path="/edit/partner/public-profile"
                    component={EditPartnerProfile}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Tags"
                    path="/settings/tags"
                    component={Tags}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Add Tag"
                    path="/settings/tags/add"
                    component={AddTag}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Tag Details"
                    path="/settings/tag/:id"
                    component={TagDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Tag Type"
                    path="/settings/tagtypes"
                    component={TagType}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Add Tag Type"
                    path="/settings/tagtype/add"
                    component={AddTagType}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Tag Type Details"
                    path="/settings/tagtype/:id"
                    component={TagTypeDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Projects"
                    path="/projects"
                    component={Projects}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Potential Projects"
                    path="/potential-projects"
                    component={PotentialProjects}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Add Project"
                    path="/project/new"
                    component={AddProject}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Project Edit"
                    path="/project/edit/:id"
                    component={ProjectEdit}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Project Details"
                    path="/project/:id"
                    component={ProjectDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Projects Categories"
                    path="/settings/project/categories"
                    component={ProjectCategory}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Add Project Categories"
                    path="/settings/project/category/add"
                    component={AddProjectCategory}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Project Categories Details"
                    path="/settings/project/category/:id"
                    component={ProjectCategoryDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Users"
                    path="/users"
                    component={Users}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="New User"
                    path="/user/new"
                    component={AddUser}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="User Details"
                    path="/user/edit/:id"
                    component={UserDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Playbooks"
                    path="/playbooks"
                    component={Playbook}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Add Playbook"
                    path="/playbook/new"
                    component={AddPlaybook}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Playbook Details"
                    path="/marketplace-sourcing/playbook/edit/:id"
                    component={PlaybookDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Collection"
                    path="/collection"
                    component={Collection}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Add Collection"
                    path="/collection/add"
                    component={AddCollection}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Collection Details"
                    path="/collection/:id"
                    component={CollectionDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Co-Sell Detail"
                    path="/co-sell/detail/:id"
                    component={coSellDetail}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Add Payment"
                    path="/payment/add"
                    component={AddPayment}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Payment Details"
                    path="/payment/:id"
                    component={PaymentDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Payment"
                    path="/payment"
                    component={Payment}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Add Invoice"
                    path="/invoice/add"
                    component={AddInvoice}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Invoice Details"
                    path="/invoice/:id"
                    component={InvoiceDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Invoice"
                    path="/invoice"
                    component={Invoice}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Reports"
                    path={[
                      "/reports",
                      "/reports/:tab",
                      "/reports/:tab/:subtab",
                      "/reports/:tab/:subtab/:section"
                    ]}
                    component={Reports}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Co-Sell"
                    path={["/co-sell"]}
                    component={CoSell}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Pipeline Reports"
                    path="/reports/pipelines"
                    component={Pipeline}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Pipeline Reports"
                    path="/reports/user-activity"
                    component={Activities}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Feedback"
                    path="/feedback"
                    component={Feedback}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Feedback Details"
                    path="/feedback/details/:id"
                    component={FeedbackDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Accounting"
                    path="/accounting"
                    component={Payment}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Billings"
                    path="/billings"
                    component={Payment}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Billing Details"
                    path="/accounting/billings/:id"
                    component={SinglePayment}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Landing Page Blocks"
                    path="/landing-page-block/edit/:id"
                    component={LandingPageBlocksDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="User Management"
                    path="/user-management"
                    component={UserManagement}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    name="Marketplace"
                    path="/marketplace/:id"
                    component={Marketplace}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Two Factor Authentication"
                    path="/two-factor-authentication"
                    component={TwoFactorAuthentication}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Playbook View"
                    path="/playbook/:id"
                    component={PlaybookView}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    path="/playbook/order/summary/:id"
                    name="Playbook Order Summary"
                    component={PlaybookOrderDetails}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Checkout"
                    path="/playbook/order/checkout/:id"
                    component={Checkout}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Company Settings"
                    path="/company/settings"
                    component={CustomerDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Contact Forms"
                    path="/contact-forms"
                    component={ContactForms}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Contact Form Details"
                    path="/contact-form/details/:id"
                    component={ContactFormDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Page Not Found"
                    path="/page-not-found"
                    component={Page404}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Deals"
                    path="/deals"
                    component={Deals}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Deal Register"
                    path="/deal/add"
                    component={AddDeal}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Deal Register"
                    path="/deal/register"
                    component={DealRegister}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Submit Referral"
                    path="/referral/submit"
                    component={SubmitPublicReferral}
                    history={history}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Deal Details"
                    path="/deal/details/:id"
                    component={DealDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Playbook Inquiry"
                    path="/playbook-inquiry"
                    component={PlaybookInquiry}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Edit Playbook Inquiry"
                    path="/leads/playbook-inquiry/:id"
                    component={PlaybookInquiryDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Edit Partner Contact"
                    path="/leads/partner-contact/details/:id"
                    component={PartnerContactDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Intro To Portal"
                    path="/intro-to-portal"
                    component={IntroToPortal}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Leads"
                    path="/leads"
                    component={Leads}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Partner Resource"
                    path="/resources"
                    component={Resource}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Partner Files"
                    path="/files"
                    component={PartnerFiles}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Add Resource"
                    path="/resource/add"
                    component={AddResource}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Edit Resource"
                    path="/resource/edit/:id"
                    component={EditResource}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Partner Training"
                    path="/course"
                    component={Course}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Edit Course"
                    path="/course/edit/:id"
                    component={EditCourse}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Training Course"
                    path="/course/details/:id"
                    component={TrainingCourse}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Referral Redirect"
                    path="/referral-redirect/:id"
                    component={ReferralRedirect}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Referrals"
                    path={["/referrals", "/referrals/:status"]}
                    component={Referral}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Register Referral"
                    path="/referral/add"
                    component={SubmitReferral}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Edit Referral"
                    path="/referral/edit/:id"
                    component={EditReferral}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Contacts"
                    path="/contacts"
                    component={Contacts}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Edit Contact"
                    path="/contact/edit/:id"
                    component={EditContact}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Referral success public page"
                    path="/referral-submission-success"
                    component={ReferralRegisteredSuccessfully}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Deal registration success public page"
                    path="/deal-registration-success"
                    component={DealRegisteredSuccessfully}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="System Logs"
                    path="/system-logs"
                    component={DealRegisteredSuccessfully}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Accounts"
                    path="/accounts"
                    component={Accounts}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Edit Account"
                    path="/account/edit/:id"
                    component={EditAccount}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <PublicPageLayout
                    exact
                    name="Company SignUp"
                    path="/company-signup"
                    component={CompanySignUp}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Companies"
                    path="/companies"
                    component={Companies}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Company Details"
                    path="/company/:id"
                    component={CompanyDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Admin Settings"
                    path={[
                      "/support-portal",
                      "/support-portal/:tab",
                      "/support-portal/:tab/:subtab",
                      "/support-portal/:tab/:subtab/:section",
                      "/support-portal/:tab/:subtab/:section/:subsection"
                    ]}
                    component={SupportPortal}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="AccountMappingPartnerRecords"
                    path="/account-mapping"
                    component={AccountMappingPartners}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="CrossBeamReports"
                    path="/crossbeam-report"
                    component={CrossBeamReport}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="TrainingCourse"
                    path="/course/details/:id"
                    component={TrainingCourse}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Co-Marketing Campaigns"
                    path="/campaigns"
                    component={Projects}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Add Campaign"
                    path="/campaign/new"
                    component={AddProject}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Campaigns Calendar"
                    path="/campaign/calendar"
                    component={Calendar}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Campaign Edit"
                    path="/campaign/edit/:id"
                    component={AddProject}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Campaign Details"
                    path="/campaign/:id"
                    component={ProjectDetails}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="MDF"
                    path="/mdf-requests"
                    component={MDF}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="New MDF Request"
                    path="/mdf-request/new"
                    component={SubmitMdfRequest}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="New Co-Sell Opportunity"
                    path="/co-sell/add"
                    component={AddPage}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="New MDF Request"
                    path="/mdf-request/details/:id"
                    component={EditMdfRequest}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="New Referral Request "
                    path="/referral/request"
                    component={ReferralRequest}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Edit Referral Request "
                    path="/referral/request/:id"
                    component={EditReferralRequest}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Overall Summary"
                    path="/reports/overall-summary"
                    component={OverallSummary}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Referral Reports"
                    path="/reports/referral-report"
                    component={ReferralReport}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Partner Reports"
                    path="/reports/partner-report"
                    component={PartnerReport}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                  <DefaultLayout
                    exact
                    name="Partner User Report"
                    path="/reports/partner-user-report"
                    component={PartnerUserReport}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />

                  <DefaultLayout
                    exact
                    name="Page 404"
                    path=""
                    component={Page404}
                    partnerSettings={partnerSettings}
                    settings={settings}
                    user={user}
                  />
                </Switch>
              </Router>
            </div>
          </MyProvider>
        </Auth0Provider>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    settings: state.settings,
    partnerSettings: state.partnerSettings,
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        fetchPartnerSettings,
        fetchSettings,
        fetchUserDetail
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
