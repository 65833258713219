import React, { useState, useEffect } from "react";
import { DropdownItem } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

//Components
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import MoreDropdown from "../../../../components/base/MoreDropdown";
import Form from "../../../../components/base/Form";
import Text from "../../../../components/Text";
import AddButton from "../../../../components/base/AddButton";
import Spinner from "../../../../components/base/Spinner";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

import { fetchList } from "../../../../actions/table";

// Helper
import DeleteModal from "../../../../components/base/DeleteModal";
import { toast } from "react-toastify";

// 404 Page
import { isBadRequest } from "../../../../common/http";

const UrlSection = () => {
  //states
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState(0);
  const [sourceUrl, setSourceUrl] = useState("");
  const [destinationUrl, setDestinationUrl] = useState("");
  const [urlData, setUrlData] = useState("");
  const [deleteUrl, setDeleteUrl] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    getUrlData();
    setIsLoading(false);
  }, []);

  const headerButton = (
    <div className="btn-wrapper">
      <AddButton
        label="Add New"
        className="pull-right btn btn-secondary"
        onClick={() => {
          setIsOpen(true);
          setId("");
          setSourceUrl("");
          setDestinationUrl("");
        }}
      />
    </div>
  );

  const toggle = id => {
    if (id) {
      setId(id);
      setIsOpen(!isOpen);
    } else {
      setId("");
      setIsOpen(!isOpen);
    }
  };

  //create url data
  const createData = async data => {
    if (data) {
      try {
        const response = await apiClient.post(`${endpoints().urlApi}`, data);
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          setIsLoading(true);
          await getUrlData();
          setIsLoading(false);
          toggle();
        }
      } catch (error) {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      }
    }
  };

  //get url data
  const getUrlData = async () => {
    try {
      const response = await apiClient.get(`${endpoints().urlApi}`);
      const data = response.data.data;
      setId(data.id);
      setUrlData(data);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  //delete url data
  const handleDelete = async id => {
    const response = await apiClient.delete(`${endpoints().urlApi}/${id}`);
    let successMessage;
    if (response && response.data) {
      successMessage = response.data.message;

      toast.success(successMessage);
      setIsLoading(true);
      await getUrlData();
      setIsLoading(false);
    }
  };

  //edit url data
  const editUrlData = async (id, data) => {
    try {
      const response = await apiClient.put(`${endpoints().urlApi}/${id}`, data);
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;

        toast.success(successMessage);
        setIsLoading(true);
        await getUrlData();
        setIsLoading(false);
        toggle(id);
        setId("");
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  };
  if (isLoading) {
    return <Spinner />;
  }
  return (
    <>
      {/* model */}
      <div className="d-flex justify-content-between flex-wrap relative">
        <Modal
          isOpen={isOpen}
          toggle={toggle}
          className="custom-modal-popup w-100"
          backdrop="static"
        >
          <Form
            initialValues={{
              sourceUrl: sourceUrl,
              destinationUrl: destinationUrl
            }}
            onSubmit={values => {
              // values && createData(values);
              id ? editUrlData(id, values) : createData(values);
            }}
          >
            <ModalHeader toggle={toggle}>
              <div className="content-wrapper">
                <div className="icon-wrapper">
                  <p className="text-center font-weight-bold mb-3">
                    {id ? "Edit URL" : "Add URL"}
                  </p>
                  <p
                    className="text-center"
                    style={{ fontSize: "14px", lineHeight: "20px" }}
                  ></p>
                </div>
                {/* /.icon-wrapper */}
              </div>
              {/* /.content-wrapper */}
            </ModalHeader>

            <ModalBody>
              <div>
                <Text
                  name="sourceUrl"
                  label="Source Url"
                  placeholder="Enter source url..."
                  error=""
                  required
                />
              </div>
              <div>
                <Text
                  name="destinationUrl"
                  label="Destination Url"
                  placeholder="Enter destination url..."
                  error=""
                  required
                />
              </div>
            </ModalBody>

            <ModalFooter>
              <div className="container-fluid">
                <div className="col-sm-12 text-center">
                  <Button type="submit" label="">
                    {id ? "Save" : "Add"}
                  </Button>
                </div>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>

      {/* Table */}
      <div className="mt-4 mb-5">
        <DeleteModal
          isOpen={openDeleteModal}
          toggle={() => {
            setOpenDeleteModal(false);
          }}
          title="Delete URL"
          deleteFunction={() => {
            console.log(deleteUrl);
            handleDelete(deleteUrl.id);
          }}
          label="URL"
        />
        <ReduxTable
          id="url"
          apiURL={`${endpoints().urlApi}`}
          headerButton={headerButton}
          showHeader
          searchPlaceholder="Search"
          onRowClick={row => {
            // window.open(`http://${row.destination_url}`);
          }}
        >
          <ReduxColumn
            width="180px"
            type="link"
            isClickable="true"
            field="name"
            sortBy="name"
            onLinkClick={row => {
              window.open(`${row.source_url}`);
            }}
            renderField={row => {
              return <>{row.source_url}</>;
            }}
          >
            Source Url
          </ReduxColumn>

          <ReduxColumn
            width="180px"
            type="link"
            isClickable="true"
            field="name"
            sortBy="name"
            onLinkClick={row => {
              window.open(`${row.destination_url}`);
            }}
            renderField={row => {
              return <>{row.destination_url}</>;
            }}
          >
            Destination Url
          </ReduxColumn>
          <ReduxColumn
            width="100px"
            field="status"
            disableOnClick
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    className={"text-dark"}
                    onClick={() => {
                      setSourceUrl(row.source_url);
                      setId(row.id);
                      setDestinationUrl(row.destination_url);
                      setIsOpen(true);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setDeleteUrl(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    </>
  );
};

// export default UrlSection;

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(UrlSection);
