export const COMPANY_SIGNUP_BASE_URL_PATH = "company-signup";

export const COMPANY_SIGNUP_GETTING_STARTED_TEXT = "getting_started";
export const COMPANY_SIGNUP_GETTING_STARTED = 1;

export const COMPANY_SIGNUP_BASIC_INFORMATION_TEXT = "basic_information";
export const COMPANY_SIGNUP_BASIC_INFORMATION = 2;

export const COMPANY_SIGNUP_REQUEST_RECEIVED_TEXT = "request_received";
export const COMPANY_SIGNUP_REQUEST_RECEIVED = 3;
// Company status start
export const COMPANY_STATUS_APPROVED = "Approved";
export const COMPANY_STATUS_PENDING = "Pending";
export const COMPANY_STATUS_DECLINED = "Declined";
// Company status end
