import React, { Component } from "react";

// Components
import CheckboxCart from "../../components/base/CheckboxCart";

class OrderExtras extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkBox: {
        extraOptionSelections: []
      }
    };

    this.handleExtrasOptions = this.handleExtrasOptions.bind(this);
  }

  // Handle highly requested areas
  handleExtrasOptions(e) {
    const newSelection = e.target.value;
    let newSelectionArray;

    if (this.state.checkBox.extraOptionSelections.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.extraOptionSelections.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.extraOptionSelections,
        newSelection
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        extraOptionSelections: newSelectionArray
      }
    }));
  }

  render() {
    const { extrasOptions, onChange, extraOptionSelections } = this.props;
    return (
      <div className="cart-item pb-0">
        <h5 className="mb-3">
          <b>Add Extras:</b>
        </h5>
        <CheckboxCart
          name="highlyRequestedArea"
          classnames="custom-checkbox"
          options={extrasOptions}
          selectedOptions={extraOptionSelections}
          handleChange={onChange}
        />
      </div>
    );
  }
}

export default OrderExtras;
