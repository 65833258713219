// API call
import { apiClient } from "../apiClient";

// Configs
import { endpoints } from "../configs";

import toast from "../components/base/Toast";

import { isBadRequest } from "../common/http";

class MessageService {
  static bulkMessage(bodyData) {
    try {
      apiClient
        .post(`${endpoints().messageAPI}/bulkMessage`, bodyData)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          toast.success(successMessage);
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  }
}

export default MessageService;
