import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";
import { isLoggedIn } from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

export default class ExpertCapacity extends Component {
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    return (
      <div>
        <PageTitle label="Expert Capacity" />

        <div>
          <ReduxTable
            id="expertCapacity"
            apiURL={`${endpoints().userCapacityList}`}
          >
            <ReduxColumn type="link" isClickable="true" field="firstName">
              First Name
            </ReduxColumn>
            <ReduxColumn field="lastName">Last Name</ReduxColumn>
            <ReduxColumn field="email">Email</ReduxColumn>
            <ReduxColumn field="availability">Availability</ReduxColumn>
            <ReduxColumn field="availabilityBandwidth">
              Availability Bandwidth
            </ReduxColumn>
            <ReduxColumn field="expectedAvailability">
              Expected Availability
            </ReduxColumn>
            <ReduxColumn field="highlyRequestedArea">
              {" "}
              Highly Requested Area
            </ReduxColumn>
            <ReduxColumn field="salesforceCertification">
              Salesforce Certification
            </ReduxColumn>
            <ReduxColumn field="interestedArea">Interested Area</ReduxColumn>
            <ReduxColumn field="createdAt">Created At</ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}
