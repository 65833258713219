import React from "react";
import toast from "../../components/base/Toast";
import InvoiceFormFields from "./InvoiceFormFields";

// Components
import DeleteButton from "../../components/base/DeleteButton";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import PageTitle from "../../components/base/PageTitle";
import Form from "../../components/base/Form";

// Config
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import { toString } from "../../lib/helper";
import { isBadRequest } from "../../common/http";

export default class UpdateInvoiceForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      InvoiceDetails: [],
      loading: false
    };
  }

  componentDidMount = () => {
    this._getInvoiceDetails();
  };

  // Update invoice
  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save invoice data form
    this._updateInvoice(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define Customer constants
    const name = values.name;

    if (!name) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.name = toString(values.name);
    values.description = toString(values.description);
    values.amount = toString(values.amount);
    values.date = toString(values.date);
    return values;
  }

  // Invoice update API Call
  _updateInvoice(data) {
    return apiClient
      .put(`${endpoints().invoiceAPI}/${this.props.match.params.id}`, data)
      .then(() => {
        window.location.replace("/invoice");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Invoice delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().invoiceAPI}/${this.props.match.params.id}`)
      .then(() => {
        window.location.replace("/invoice");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Invoice details api call by id
  _getInvoiceDetails = () => {
    return apiClient
      .get(`${endpoints().invoiceAPI}/${this.props.match.params.id}`)
      .then(response => {
        this.setState({ InvoiceDetails: response.data, loading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  render() {
    if (!this.state.loading) {
      return "";
    }

    const { name, date, description, amount } = this.state.InvoiceDetails;

    const initialValues = { name, date, description, amount };
    return (
      <React.Fragment>
        <PageTitle label={name} />
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            this._submit(values);
          }}
        >
          <InvoiceFormFields />
          <div className="mb-4">
            <DeleteButton
              label={"Delete Invoice"}
              onClick={this.handleDelete}
            />
            <div className="float-right">
              <CancelButton onClick={() => this.props.history.goBack()} />
              <SaveButton />
            </div>
          </div>
        </Form>
      </React.Fragment>
    );
  }
}
