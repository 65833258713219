import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { Link } from "react-router-dom";

import { getKeyValueByObject, getCookie } from "../../lib/helper";
import {
  SETTINGS_ENABLE_PARTNERS,
  SETTINGS_ENABLE_REFERRALS
} from "../../setting/Constants";
import {
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_CLOSED_WON
} from "../../referral/Constants";

// Components
import ListViewCard from "../../components/base/ListViewCard";
import AvatarCard from "../../components/base/AvatarCard";

// Asset
import Img from "../../assets/img/icons/icon-user.svg";

// Actions
import { fetchAdminDashboardCount } from "../../actions/dashboard";

// Cookies
import { COOKIE_SHOW_ASSIGNED_PARTNERS } from "../../lib/cookie";
import NoUserFound from "../../components/base/NoUserFound";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

const List = styled.div`
  padding: 15px;
  border-bottom: 1px solid #e9e8ee;
`;

const TopPartners = props => {
  const dispatch = useDispatch();
  const dashboard = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(fetchAdminDashboardCount());
  }, []);

  const adminDashboard = dashboard.adminDashboard;
  const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);

  let topPartnerProfile;

  if (checked_status) {
    topPartnerProfile =
      adminDashboard && adminDashboard.topAssignedPartnerProfile;
  } else {
    topPartnerProfile = adminDashboard && adminDashboard.topPartnerProfile;
  }

  const enablePartners = props.settings
    ? getKeyValueByObject(props.settings, SETTINGS_ENABLE_PARTNERS)
    : "";
  const enableReferrals = props.settings
    ? getKeyValueByObject(props.settings, SETTINGS_ENABLE_REFERRALS)
    : "";

  return (
    <>
      {adminDashboard && (
        <>
          {enablePartners === "true" && enableReferrals === "true" && (
            <ListViewCard
              label={`Top 5 Partners by ${Terminology.get(
                SystemName.REFERRALS
              )} Submitted`}
              link={
                topPartnerProfile.length > 0
                  ? "/referrals?status=Approved,Closed%20Won"
                  : ""
              }
            >
              {topPartnerProfile && topPartnerProfile.length > 0 ? (
                <>
                  {/*Top Partners*/}

                  {topPartnerProfile.map(profile => (
                    <Link
                      className="text-decoration-none text-dark"
                      to={`/referrals?partnerId=${profile.partner_id}&status=${REFERRAL_STATUS_APPROVED},${REFERRAL_STATUS_CLOSED_WON}`}
                    >
                      <List key={profile.partner.id}>
                        <div className="row d-flex align-items-center justify-content-between">
                          <div className="col-8 text-truncate">
                            <AvatarCard
                              firstName={profile.partner.company_name}
                              url={profile.partner.avatar}
                              size="xs"
                            />
                          </div>
                          <div className="col-4">
                            <span className="mr-3" style={{ fontSize: "14px" }}>
                              {profile.referral_submit_count}{" "}
                              {profile.referral_submit_count > 1
                                ? Terminology.get(SystemName.REFERRALS)
                                : Terminology.get(SystemName.REFERRAL)}
                            </span>
                          </div>
                        </div>
                      </List>
                    </Link>
                  ))}
                </>
              ) : (
                <NoUserFound
                  title={`No Partners Have Submitted a ${Terminology.get(
                    SystemName.REFERRAL
                  )} Yet`}
                  icon={Img}
                  hideCard
                  description={`After you start getting ${Terminology.get(
                    SystemName.REFERRALS
                  )}, they will show up here.`}
                />
              )}
            </ListViewCard>
          )}
        </>
      )}
    </>
  );
};

export default TopPartners;
