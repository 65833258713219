import { Field } from "formik";
import React from "react";

//Base Components
import ToggleSwitch from "../../../components/base/ToggleSwitch";
import Form from "../../../components/base/Form";
import { SETTINGS_ENABLE_HUBSPOT_SALES_ROUTING } from "../../../setting/Constants";

const SalesRoutingSetting = props => {
  const { adminSettings, saveSettings } = props;
  const {
    enable_hubspot_sales_routing,
    enable_deal_owner_for_existing_prospect_company,
    enable_deal_for_new_prospect_company
  } = adminSettings;

  // Sales Routing Section Initial Values
  const salesRoutingSectionInitialValues = {
    enable_hubspot_sales_routing: enable_hubspot_sales_routing === "true"
  };

  // Handle enable sales routing
  const handleEnableSalesRouting = enable_hubspot_sales_routing => {
    const data = new FormData();

    if (enable_hubspot_sales_routing !== undefined) {
      props.setHubspotSalesRouting(
        enable_hubspot_sales_routing == true ? "true" : "false"
      );
      data.append("enable_hubspot_sales_routing", enable_hubspot_sales_routing);
    }
    if (enable_deal_for_new_prospect_company == undefined) {
      data.append("enable_deal_for_new_prospect_company", true);
    }
    if (enable_deal_owner_for_existing_prospect_company == undefined) {
      data.append("enable_deal_owner_for_existing_prospect_company", true);
    }
    // Save settings
    saveSettings(data);
  };
  return (
    <>
      <Form
        initialValues={salesRoutingSectionInitialValues}
        onSubmit={values => {
          this.submit(values);
        }}
      >
        <div className={["set-expert-visibility"].join(" ")}>
          <Field
            name={SETTINGS_ENABLE_HUBSPOT_SALES_ROUTING}
            render={({ field, form }) => {
              return (
                <ToggleSwitch
                  name={SETTINGS_ENABLE_HUBSPOT_SALES_ROUTING}
                  label1={"Enable Hubspot Sales Routing"}
                  label2={"Enable Hubspot Sales Routing"}
                  value={field.value}
                  handleChange={() => {
                    handleEnableSalesRouting(!field.value);
                    form.setFieldValue(
                      SETTINGS_ENABLE_HUBSPOT_SALES_ROUTING,
                      !field.value
                    );
                  }}
                  outlined
                />
              );
            }}
          />
        </div>
      </Form>
    </>
  );
};
export default SalesRoutingSetting;
