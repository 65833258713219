import React from "react";

class RequestReceived extends React.Component {
  render() {
    const { currentStep, step, email } = this.props;
    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className={`content-wrapper custom-width`}>
        <div className="title-wrapper d-flex align-items-center">
          {/* <HandsIcon />{" "} */}
          <h2 className="h1-5 font-weight-bold mb-0">
            Verify Your Email Address
          </h2>
        </div>
        <div className="content">
          <p className="h5 lh-2 mb-0">We have sent a verification email to</p>
          <p className="h5 text-link mt-0">{email}</p>

          <div className="btn-wrapper mt-4-5">
            <button
              id="btn-resendemail"
              className="btn btn-login w-100"
              type="button"
              onClick={() => {
                window.location.replace("/dashboard");
              }}
            >
              GoTo Dashboard
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default RequestReceived;
