export const EXPERT_SIGNUP_GETTING_STARTED_TEXT = "getting_started";
export const EXPERT_SIGNUP_BASIC_INFORMATION_TEXT = "basic_information";
export const EXPERT_SIGNUP_LOCATION_TEXT = "location";
export const EXPERT_SIGNUP_TELL_US_ABOUT_YOUR_SELF_TEXT =
  "tell_us_about_yourself";
export const EXPERT_SIGNUP_SKILLS_AND_TOOLS_TEXT = "skills_&_availability";
export const EXPERT_SIGNUP_REQUEST_RECEIVED_TEXT = "request_received";
export const EXPERT_SIGNUP_GETTING_STARTED = 1;
export const EXPERT_SIGNUP_BASIC_INFORMATION = 2;
export const EXPERT_SIGNUP_LOCATION = 3;
export const EXPERT_SIGNUP_TELL_US_ABOUT_YOUR_SELF = 4;
export const EXPERT_SIGNUP_SKILLS_AND_TOOLS = 5;
export const EXPERT_SIGNUP_REQUEST_RECEIVED = 6;
export const EXPERT_SIGNUP_WELCOME_MESSAGE = "Welcome Advisors!";
