import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { CongratsGraphicIcon } from "../../../assets/img/icons";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

const ProfileAcceptedModal = props => {
  let { modal, setModal } = props;
  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      className={["log-hours-modal", "edit-task-modal"].join(" ")}
      backdrop="static"
    >
      <ModalHeader toggle={setModal}>
        <CongratsGraphicIcon />
        <h4 className={["font-weight-bold", "mt-4 mb-3 mx-5 px-3"].join(" ")}>
          Awesome! Your application has been accepted!
        </h4>
      </ModalHeader>
      <ModalBody className={["text-center", "mb-4"].join(" ")}>
        <div className="form-wrapper">
          <div className="field-wrapper">
            <p className={"px-4"}>
              You are ready to go to your dashboard and start submitting
              {Terminology.get(SystemName.REFERRAL)} and registering deals.
            </p>
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={["justify-content-center"].join(" ")}>
        <div className="btn-wrapper">
          <button className="btn btn-primary" onClick={() => setModal()}>
            View the Dashboard
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default ProfileAcceptedModal;
