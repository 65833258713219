import React from "react";
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import Spinner from "../../../../components/base/Spinner";

import MoreDropdown from "../../../../components/base/MoreDropdown";
import Form from "../../../../components/base/Form";
import Text from "../../../../components/Text";
import AddButton from "../../../../components/base/AddButton";
import TextArea from "../../../../components/base/TextArea";

import DeleteModal from "../../../../components/base/DeleteModal";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownItem
} from "reactstrap";

// Helper
import { toString } from "../../../../lib/helper";

// API call
import { apiClient } from "../../../../apiClient";

// Configs
import { endpoints } from "../../../../configs";

// Constant
import { isBadRequest } from "../../../../common/http";
import { toast } from "react-toastify";

class EmailTemplate extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values.
    this.state = {
      id: "",
      name: "",
      subject: "",
      content: "",
      templateDeteils: [],
      isLoading: false,
      deleteEmailTemplateModal: false,
      deleteEmailTemplate: false,
      referrLinkOptions: [],
      attachmentFileName: "",
      uploadFile: "",
      sortByOptions: [
        {
          value: "name:ASC",
          label: "Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ]
    };
  }

  componentDidMount() {
    this._getTemplateDetails();
    this.getReferralLinks();
  }

  _toggle = tagId => {
    this.setState({ isOpen: !this.state.isOpen, id: tagId || "" });
  };

  /**
   * Create template
   *
   * @param data
   */
  templateCreate = async values => {
    const data = new FormData();
    data.append("id", values.id ? values.id : "");
    data.append("name", values.name ? values.name : "");
    data.append("subject", values.subject ? values.subject : "");
    data.append("content", values.content ? values.content : "");
    data.append("file", values.file ? values.file : "");

    this.setState({ isLoading: true });
    return await apiClient
      .post(`${endpoints().emailTemplateAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          this._getTemplateDetails();
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._toggle();
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  // Template Name page api call

  _getTemplateDetails() {
    this.setState({ isLoading: true });
    apiClient
      .get(`${endpoints().emailTemplateAPI}/search`)
      .then(response => {
        const templateDeteils = response.data.data;
        this.setState({
          templateDeteils: templateDeteils
            ? templateDeteils
            : this.state.templateDeteils
        });

        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isLoading: false });
          console.error(errorMessage);
        }
      });
  }

  // delete Template Name page api call by id
  _deleteTemplateDetails(id) {
    this.setState({ isLoading: true });
    apiClient
      .delete(`${endpoints().emailTemplateAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._getTemplateDetails();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  // update Template Name page api call by id
  _updateTemplateDetails(values) {
    const data = new FormData();
    data.append("id", values.id ? values.id : "");
    data.append("name", values.name ? values.name : "");
    data.append("subject", values.subject ? values.subject : "");
    data.append("content", values.content ? values.content : "");
    data.append("file", values.file ? values.file : "");

    this.setState({ isLoading: true });
    apiClient
      .put(`${endpoints().emailTemplateAPI}/update`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          this._getTemplateDetails();
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._toggle();
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  }

  // get Referral links api call
  getReferralLinks() {
    this.setState({ isLoading: true });
    apiClient
      .get(`${endpoints().referralLinkAPI}/search`)
      .then(response => {
        let referralArray = [];
        const referralLinks = response.data.data;
        referralLinks
          .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
          .forEach(data => {
            referralArray.push({
              value: data.name,
              label: data.name
            });
          });
        this.setState({
          referrLinkOptions: referralArray
        });
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isLoading: false });
          console.error(errorMessage);
        }
      });
  }

  // Handle file upload
  handleFileChange = e => {
    let files = e.target.files ? e.target.files[0] : "";
    this.setState({ attachmentFileName: files.name, uploadFile: files });
  };

  // Attachment file Remove
  handleAttachmentRemove = () => {
    if (this.state.id) {
      this.setState({ uploadFile: "", attachmentFileName: "" });
    } else {
      this._handleDeleteAttachment(this.state.id);
    }
  };

  // Delete Attachment
  _handleDeleteAttachment = async id => {
    return await apiClient
      .delete(`${endpoints().emailTemplateAPI}/attachmentFile/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.stringify(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  render() {
    const {
      deleteEmailTemplateModal,
      deleteEmailTemplate,
      isLoading,
      referrLinkOptions,
      attachmentFileName,
      uploadFile
    } = this.state;

    if (isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <div className="d-flex justify-content-between">
          <h5 className="font-weight-bold">Email Templates</h5>
          <div className="btn-wrapper">
            <AddButton
              label="Add New"
              className="pull-right btn btn-secondary"
              onClick={() => {
                this.setState({
                  id: "",
                  name: "",
                  subject: "",
                  content: "",
                  file: "",
                  attachmentFileName: "",
                  uploadFile: "",
                  isOpen: true
                });
              }}
            />
          </div>
        </div>

        <br />
        <div>
          <Modal
            isOpen={this.state.isOpen}
            toggle={this._toggle}
            className="landing-create-popup w-100"
            backdrop="static"
          >
            <ModalHeader
              toggle={this._toggle}
              cssModule={{ "modal-title": "w-100 text-center" }}
            >
              <p>
                {this.state.id ? "Edit Email Template" : "Add Email Template"}
              </p>
            </ModalHeader>
            <Form
              initialValues={{
                id: this.state.id || "",
                name: this.state.name || "",
                subject: this.state.subject || "",
                content: this.state.content || "",
                file: this.state.uploadFile || ""
              }}
              onSubmit={values => {
                values.name = toString(values.name).trim();
                values.subject = toString(values.subject);
                values.content = toString(values.content);
                values.id = this.state.id;
                values.file = this.state.uploadFile
                  ? this.state.uploadFile
                  : "";
                this.state.id
                  ? this._updateTemplateDetails(values)
                  : this.templateCreate(values);
              }}
            >
              <ModalBody className="ml-3 mr-3 mt-2 mb-3">
                <div>
                  <Text
                    name="name"
                    label="Name"
                    placeholder="Enter name"
                    error=""
                    required={true}
                  />
                </div>
                <div>
                  <Text
                    name="subject"
                    label="Subject"
                    placeholder="Enter Subject"
                    error=""
                    required={true}
                  />
                </div>
                <div>
                  <TextArea
                    name="content"
                    label="Content"
                    placeholder="Enter Content"
                    rows="8"
                    error=""
                    required={true}
                    showReferralLinks
                    referrLinkOptions={referrLinkOptions}
                  />
                </div>
                <span className="text-decoration-none">Attachment</span>
                <div className="">
                  <input
                    name="file"
                    className="form-control d-none"
                    type="file"
                    id="photo"
                    onChange={e => {
                      this.handleFileChange(e);
                    }}
                    accept=".docx, .doc, .pdf, image/*, text/*"
                  />
                  <span className="profilePicOverlay d-block ">
                    <label htmlFor="photo" className="profile-img-sm mb-0">
                      <span className="banner-image-upload-link">
                        {!attachmentFileName ? "Select File" : ""}
                      </span>
                    </label>

                    {/* Show file Name */}
                    {uploadFile && attachmentFileName && (
                      <a href={uploadFile}>{attachmentFileName}</a>
                    )}
                    {/* Remove image */}
                    {attachmentFileName && (
                      <span
                        className="banner-image-upload-link text-danger ml-md-2"
                        onClick={this.handleAttachmentRemove}
                      >
                        (Remove)
                      </span>
                    )}
                  </span>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="container-fluid">
                  <div className="col text-center">
                    <Button type="submit" label="">
                      {this.state.id ? "Save" : "Add"}
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </Form>
          </Modal>
        </div>
        {/* REDUX TABLE */}
        <ReduxTable
          className="mt-3 mb-3"
          apiURL={`${endpoints().emailTemplateAPI}/search`}
          showHeader
          newTableHeading
          paramsToUrl={true}
          pagination={true}
          history={this.props.history}
          searchPlaceholder="Search"
          sortByOptions={this.state.sortByOptions}
          onRowClick={row => {
            this.setState({
              name: row.name,
              subject: row.subject,
              content: row.content,
              uploadFile: row.files,
              attachmentFileName: row.attachmentFileName
            });
            return this._toggle(row.id);
          }}
        >
          <ReduxColumn
            type="link"
            isClickable="true"
            field="name"
            sortBy="name"
          >
            Name
          </ReduxColumn>
          <ReduxColumn field="updatedAt" maxWidth={"107px"} sortBy="updatedAt">
            Last Updated
          </ReduxColumn>
          <ReduxColumn
            field="status"
            disableOnClick
            className="action-column"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      this.setState({
                        name: row.name,
                        subject: row.subject,
                        content: row.content,
                        uploadFile: row.files,
                        attachmentFileName: row.attachmentFileName
                      });
                      return this._toggle(row.id);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() =>
                      this.setState({
                        deleteEmailTemplateModal: true,
                        deleteEmailTemplate: row
                      })
                    }
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          id="deletePartnerType"
          isOpen={deleteEmailTemplateModal}
          toggle={() => {
            this.setState({ deleteEmailTemplateModal: false });
          }}
          title="Delete Email Template"
          label={deleteEmailTemplate.name}
          deleteFunction={() =>
            this._deleteTemplateDetails(deleteEmailTemplate.id)
          }
        />
      </>
    );
  }
}

export default EmailTemplate;
