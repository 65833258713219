import React from "react";
import isEqual from "react-fast-compare";

// Assets
import { EditIconAlt, TrashIcon } from "../../../../../assets/img/icons";

// Components

const DeleteButton = React.memo(function DeleteTaskButton(props) {
  let { task, setCurrentQuestion, setDeleteQuestionModal } = props;

  return (
    <>
      <button
        type="button"
        className={["delete-btn", "btn-link", "btn"].join(" ")}
        onClick={() => {
          setCurrentQuestion(task);
          setDeleteQuestionModal(true);
        }}
      >
        <TrashIcon />
      </button>
    </>
  );
}, isEqual);

const Question = props => {
  let {
    task,
    setEditQuestionModal,
    setCurrentQuestion,
    setDeleteQuestionModal
  } = props;
  let { label, conditionalDisplay } = task;
  return (
    <div className=" task pr-4 pt-2 pb-1" style={{ minHeight: "70px" }}>
      <div className="task-left">
        <p className="pr-3 pt-3" style={{ cursor: "move", width: "55px" }}>
          <i className="fa fa-arrows"></i>
        </p>
        {/*Show Checkbox Based On Status*/}
        <div className="name-comments mr-2 ">
          {/*Show Task Name*/}
          <span
            className="text-decoration-none"
            style={{ minWidth: "4.3rem", maxWidth: "10.3rem" }}
          >
            {label}
          </span>
        </div>

        {/*Show conditional display Name*/}
        <span className="text-decoration-none pl-5">{conditionalDisplay}</span>
      </div>

      <div className="task-right">
        {/*Show Task Actions For All Tasks Based on the Condition*/}
        <div className="task-actions">
          <>
            <span className={"mt-1 d-flex justify-content-between"}>
              <button
                type="button"
                className={["edit-btn", "btn-link", "btn"].join(" ")}
                onClick={() => {
                  setCurrentQuestion(task);
                  setEditQuestionModal(true);
                }}
              >
                <EditIconAlt />
              </button>

              {/* Trigger Edit Task modal */}
              <DeleteButton
                task={task}
                setCurrentQuestion={setCurrentQuestion}
                setDeleteQuestionModal={setDeleteQuestionModal}
              />
            </span>
          </>
        </div>
      </div>
    </div>
  );
};

export default Question;
