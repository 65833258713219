import React from "react";
import { Badge } from "reactstrap";
import isEqual from "react-fast-compare";

// Assets
import { EditIconAlt, TrashIcon } from "../../../../../assets/img/icons";

// Components
import Form from "../../../../../components/base/Form";
import Select from "../../../../../components/base/Select";

// Constants
import { percentOptions } from "../../../../../modules/task/Constant";

// Constants

const DeleteFieldButton = React.memo(function DeleteFieldButton(props) {
  let { field, setCurrentField, setDeleteFieldModal } = props;

  return (
    <>
      <button
        type="button"
        className={["delete-btn", "btn-link", "btn"].join(" ")}
        onClick={() => {
          setCurrentField(field);
          setDeleteFieldModal(true);
        }}
      >
        <TrashIcon />
      </button>
    </>
  );
}, isEqual);

const Field = props => {
  let {
    field,
    setEditFieldModal,
    setCurrentField,
    setDeleteFieldModal
  } = props;
  let { id, name, category, percent } = field;
  const handlePercentageChange = (id, e) => {
    const data = new FormData();

    data.append("percent", e.value);

    props.updateField(id, data);
  };

  return (
    <div className="task pr-4 pt-2 pb-1" style={{ minHeight: "70px" }}>
      <div className="task-left">
        <p className="pr-3 pt-3" style={{ cursor: "move", width: "55px" }}>
          <i className="fa fa-arrows"></i>
        </p>
        {/*Show Checkbox Based On Status*/}
        <div className="name-comments mr-2 ">
          {/*Show Field Name*/}
          <span className="text-decoration-none">{name}</span>
          {category === "System" && (
            <Badge color="secondary" pill>
              Default
            </Badge>
          )}
        </div>
      </div>

      <div className="task-right">
        {/*Show Field Actions For All Fields Based on the Condition*/}
        <div className="task-actions">
          <>
            <div className="name-comments mr-2 ">
              <Form
                initialValues={{
                  [`percentage-${id}`]: {
                    label: `${percent}%`,
                    value: percent
                  }
                }}
              >
                <span className="text-muted h7">Percent Complete</span>
                <span className="pl-2">
                  <Select
                    name={`percentage-${id}`}
                    placeholder="Select"
                    defaultValue={percentOptions.find(
                      percentOptionDetails =>
                        percentOptionDetails.value === percent
                    )}
                    options={percentOptions}
                    handleChange={e => handlePercentageChange(id, e)}
                    isSearchable={true}
                    width={"90px"}
                  />
                </span>
              </Form>
            </div>
            <span
              className={"mt-1 d-flex justify-content-between"}
              style={{
                visibility: category === "System" ? "hidden" : "show"
              }}
            >
              <button
                type="button"
                className={["edit-btn", "btn-link", "btn"].join(" ")}
                onClick={() => {
                  setCurrentField(field);
                  setEditFieldModal(true);
                }}
              >
                <EditIconAlt />
              </button>

              {/* Trigger Edit Field modal */}
              <DeleteFieldButton
                field={field}
                setCurrentField={setCurrentField}
                setDeleteFieldModal={setDeleteFieldModal}
              />
            </span>
          </>
        </div>
      </div>
    </div>
  );
};

export default Field;
