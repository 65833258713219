import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// Constants
import {
  TAB_PARTNER_RESOURCE,
  PARTNER_RESOURCE_CATEGORY_SUB_TAB,
  APP_CATEGORY,
  TAB_APP_SETTINGS,
  TAB_APPS
} from "../../../../setting/Constants";
import AppsCategory from "./AppsCategory";
import classNames from "classnames";

const AppsCategoryTab = props => {
  const {
    activeTab,
    activeSubTab,
    adminSettings,
    saveSettings,
    toggleTabs,
    history,
    activeSection
  } = props;

  return (
    <>
      <Nav tabs className="admin-tabs">
        {/*CategoryTab */}
        <NavItem>
          <NavLink
            className={classNames({
              active: activeSubTab === APP_CATEGORY
            })}
            onClick={() => {
              toggleTabs(TAB_APPS, APP_CATEGORY);
            }}
          >
            {APP_CATEGORY}
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classNames({
              active: activeSubTab === TAB_APP_SETTINGS
            })}
            onClick={() => {
              toggleTabs(TAB_APPS, TAB_APP_SETTINGS);
            }}
          >
            {TAB_APP_SETTINGS}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeSubTab}>
        <TabPane tabId={APP_CATEGORY} className="w-100">
          <AppsCategory
            activeTab={activeTab}
            activeSubTab={activeSubTab || APP_CATEGORY}
            activeSection={activeSection}
            adminSettings={adminSettings}
            toggleTabs={toggleTabs}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default AppsCategoryTab;
