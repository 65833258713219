import React, { useState, useEffect } from "react";
import { FieldArray } from "formik";
import DefaultContent from "../content/DefaultContent";
import Text from "../base/Text";
import TextArea from "../base/TextArea";
import Email from "../base/Email";
import ErrorMessage from "../base/ErrorMessage";

const ExpertProfileRecommendations = props => {
  const { recommendations, id } = props;

  return (
    <DefaultContent title={"Recommendations"}>
      <p className={["text-inline-grayed"].join("")}>
        If you have any customer testimonials, please put them here. This will
        help give confidence to prospective clients.
      </p>
      <FieldArray
        name="recommendations"
        render={arrayHelpers => (
          <div
            className={["recommendations", "recommendations-editing"].join(" ")}
          >
            {arrayHelpers.form.values.recommendations &&
              arrayHelpers.form.values.recommendations.map(
                (recommendation, index) => (
                  <div className="recommendation" key={index}>
                    <div className="field-wrapper">
                      <ErrorMessage name={`recommendations[${index}].name`} />
                      <Text
                        id={id}
                        name={`recommendations[${index}].name`}
                        placeholder={"Recommendation Name"}
                        required
                      />
                    </div>
                    <div className="field-wrapper">
                      <ErrorMessage
                        name={`recommendations[${index}].companyName`}
                      />
                      <Text
                        id={id}
                        name={`recommendations[${index}].companyName`}
                        placeholder={"Company Name"}
                        required
                      />
                    </div>
                    <div className="field-wrapper">
                      <ErrorMessage name={`recommendations[${index}].role`} />
                      <Text
                        id={id}
                        name={`recommendations[${index}].role`}
                        placeholder={"Role/Occupation"}
                        required
                      />
                    </div>
                    <div
                      className="field-wrapper"
                      style={{ marginBottom: "1rem" }}
                    >
                      <ErrorMessage name={`recommendations[${index}].email`} />
                      <Email
                        id={id}
                        name={`recommendations[${index}].email`}
                        placeholder={"Email Address"}
                        required
                      />
                    </div>
                    <div className="field-wrapper">
                      <ErrorMessage
                        name={`recommendations[${index}].testimonial`}
                      />
                      <TextArea
                        id={id}
                        name={`recommendations[${index}].testimonial`}
                        placeholder="Testimonial"
                        maxLength={false}
                        required
                      />
                    </div>
                    <div className={["btn-wrapper", "text-right"].join(" ")}>
                      <a
                        className="remove-link"
                        onClick={() => arrayHelpers.remove(index)}
                      >
                        Remove
                      </a>
                    </div>
                  </div>
                )
              )}
            <a
              href="#"
              className="h7"
              onClick={e => {
                e.preventDefault();
                arrayHelpers.push({
                  tempId: recommendations && recommendations.length + 1,
                  name: "",
                  companyName: "",
                  role: "",
                  email: "",
                  testimonial: ""
                });
              }}
            >
              {`Add ${
                recommendations && recommendations.length > 0 ? "another" : ""
              } recommendation...`}
            </a>
          </div>
        )}
      />
    </DefaultContent>
  );
};

export default ExpertProfileRecommendations;
