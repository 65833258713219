import { Badge, Input } from "reactstrap";
import React from "react";
import { Link } from "react-router-dom";

// Icons
import { TrashIconAlt } from "../../../assets/img/icons";

// Components
import Label from "../../../components/base/Label";
import Avatar from "../../../components/base/Avatar";
import { setExpertBadge } from "../../../lib/helper";

// Constants
import {
  DEAL_PARTNER_STATUS_ACCEPTED,
  DEAL_PARTNER_STATUS_PENDING
} from "../../../deal/Constants";

const SourceMoreWaitingPartners = props => {
  const {
    dealPartnerList,
    deleteDealPartner,
    handleShortListedPartners,
    selectedOptions
  } = props;

  return (
    <>
      {dealPartnerList &&
        dealPartnerList.map(dealPartner => {
          const isShortListedStatus =
            dealPartner.status === DEAL_PARTNER_STATUS_ACCEPTED;
          const isWaitingStatus =
            dealPartner.status === DEAL_PARTNER_STATUS_PENDING;
          return (
            <div className="field-wrapper" key={dealPartner.partnerId}>
              <div className="user-wrapper">
                <div className="user-actions">
                  <a
                    className="text-inline-grayed mr-1 source-more cursor-pointer"
                    onClick={e => deleteDealPartner(dealPartner.id)}
                  >
                    <TrashIconAlt />
                  </a>
                  <div className="form-wrapper mr-2">
                    <Label>
                      <Input
                        type="checkbox"
                        name="user_radio"
                        className="radio-button"
                        value={dealPartner.partnerId}
                        onChange={handleShortListedPartners}
                        checked={
                          selectedOptions.indexOf(dealPartner.partnerId) > -1
                        }
                        disabled={isWaitingStatus}
                      />
                      {isShortListedStatus ? (
                        <p className="d-flex align-items-center justify-content-start">
                          <span className="radio-placeholder checkbox-placeholder" />
                        </p>
                      ) : (
                        <p className="d-flex align-items-center justify-content-start">
                          <span style={{ width: "20px", height: "20px" }} />
                        </p>
                      )}
                    </Label>
                  </div>
                </div>

                <div className="field-icon">
                  <Link
                    target="_blank"
                    to={`/expert-profile/${dealPartner.partnerId}`}
                  >
                    <Avatar
                      firstName={dealPartner.companyName}
                      size="xs"
                      fontSize={12}
                      url={dealPartner.avatarUrl}
                    />
                  </Link>
                </div>
                <div className="expert-info ml-2 d-flex flex-column align-items-start">
                  {dealPartner.jobTitle && (
                    <p
                      className="h7 text-truncate"
                      style={{ maxWidth: "130px" }}
                    >
                      {dealPartner.jobTitle}
                    </p>
                  )}
                  <Link
                    target="_blank"
                    to={`/partner-profile/${dealPartner.partnerId}`}
                    className={"font-weight-bold h7 text-truncate text-dark"}
                  >
                    {dealPartner.companyName ? dealPartner.companyName : ""}
                  </Link>

                  <div
                    className={[
                      "status-tooltips d-flex",
                      `${dealPartner.status.toLowerCase() === "no response" &&
                        "align-items-start"}`
                    ].join(" ")}
                  >
                    <Badge
                      id={`status-tooltip-${dealPartner.partnerId}`}
                      color={setExpertBadge(dealPartner.status)}
                      className="text-white h7 font-weight-bold text-uppercase"
                    >
                      {dealPartner.status}
                    </Badge>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
    </>
  );
};

export default SourceMoreWaitingPartners;
