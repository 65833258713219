import React from "react";
import { getNavList } from "../../_nav";
import "./styles.scss";
import MyContext from "../../context/MyContext";
import SideBarItem from "./SideBarItem";
import { PARTNER_STATUS_APPROVED } from "../../partnerStatus/Constants";
import { isPartner, getKeyValueByObject } from "../../lib/helper";
import {
  SETTINGS_THEME_LAYOUT,
  THEME_LAYOUT_FLUID_WIDTH
} from "../../setting/Constants";

import { connect } from "react-redux";

class Index extends React.Component {
  // Render Sidebar list
  renderSideBarList(currentRoute, enable, user) {
    const textColor = this.props.leftNavigationTextColor
      ? this.props.leftNavigationTextColor
      : "";

    const textHoverColor = this.props.leftNavigationTextHoverColor
      ? this.props.leftNavigationTextHoverColor
      : "";

    return getNavList(
      this.props.settings,
      this.props.partnerSettings,
      user
    ).map((navigation, key) => (
      <SideBarItem
        id={key}
        key={key}
        navigation={navigation}
        currentRoute={currentRoute}
        enable={enable}
        textColor={textColor}
        textHoverColor={textHoverColor}
        user={user}
      />
    ));
  }

  closeMenuOnBlur = (e, ctx) => {
    if (e.target.classList.contains("site-sidebar")) return;
    ctx.updateMenuToggled();
  };

  render() {
    const settings =
      this.props.settings && this.props.settings.settings
        ? this.props.settings.settings
        : "";
    const currentRoute = this.props.currentRoute;
    let leftNavStyle = {};
    if (this.props.leftNavigationBackgroundImage) {
      leftNavStyle = {
        background: `url(${this.props.leftNavigationBackgroundImage})`
      };
    }
    const enable = !this.props.enable
      ? false
      : isPartner(this.props.roleId) &&
        this.props.partnerStatus !== PARTNER_STATUS_APPROVED
      ? false
      : true;

    const DefaultLayout = settings
      ? getKeyValueByObject(settings, SETTINGS_THEME_LAYOUT)
      : "";

    return (
      <MyContext.Consumer>
        {context => (
          <div
            className={` w-auto sidebar-wrapper pr-0 ${
              context.menuToggled ? "menu-toggled" : "menu-hidden"
            }`}
            onClick={e => this.closeMenuOnBlur(e, context)}
          >
            <nav
              id={`${
                DefaultLayout && DefaultLayout === THEME_LAYOUT_FLUID_WIDTH
                  ? "primary-sidebar"
                  : "sidebar"
              }`}
              style={leftNavStyle}
            >
              <ul className="list-unstyled mb-0 pb-3 sideBar-width">
                {/*render the sidebar menu*/}
                {this.renderSideBarList(currentRoute, enable, this.props.user)}
              </ul>
            </nav>
          </div>
        )}
      </MyContext.Consumer>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(Index);
