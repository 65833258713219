import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import SVG from "react-inlinesvg";

// 404 page
import Page404 from "../Page404";

// Page Components
import CartTabs from "./CartTabs";
import TagCloud from "./TagCloud";

// Components
import Banner from "../../components/base/Banner";
import MarketplaceSection from "../../components/MarketplaceSection";
import ExpertCard from "../../components/base/ExpertCard";
import ContentCards from "../../components/base/ContentCards";
import Ratings from "../../components/base/Ratings";
import PlaybookCard from "../../components/PlaybookCard";
import Spinner from "../../components/base/Spinner";

// Assets
import headerTriangles from "../../assets/header-triangles.svg";
import starIcon from "../../assets/img/icons/icon-star.svg";

// API call
import { apiClient } from "../../apiClient";

// Configs
import {
  DEFAULT_API_KEY,
  endpoints,
  TORCHLITE_WEBSITE_URL
} from "../../configs";

// Helper
import {
  getFullName,
  getCookie,
  getUrlParameter,
  isSuperAdmin,
  isCompanyAdmin,
  getKeyValueByObject,
  isCompanyManager
} from "../../lib/helper";

// Constants
import { PLAYBOOK_STATUS_DRAFT } from "../../playbookStatus/Constants";
import SubNavigation from "../../components/header/SubNavigation";
import { COOKIE_SESSION_TOKEN } from "../../lib/cookie";
import { SETTINGS_ENABLE_PLAYBOOK } from "../../setting/Constants";
import { isBadRequest } from "../../common/http";
import { connect } from "react-redux";

class PlaybookView extends Component {
  constructor(props) {
    super(props);
    this.state = {
      playbookDetails: [],
      // Some default styles, this would be than updated by the API
      bgColor: "#fff",
      mockBannerBtn: {
        text: "Learn more",
        url: `${TORCHLITE_WEBSITE_URL}/salesforce-essentials-advisors/`,
        target: "_blank"
      },
      mockBannerBackground: {
        bgColor: "#F5EEE5",
        bgImage: headerTriangles,
        bgImageMobile: headerTriangles
      },
      isInvalidRequest: false,
      customerDetails: "",
      isLoading: ""
    };
    this.redirectTo = this.redirectTo.bind(this);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    this._getPlaybookDetails();
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      this._getCustomerBillingDetails();
    }
  };

  // Playbook details api call by id
  _getPlaybookDetails = () => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .get(`${endpoints().publicAPI}/playbook/${this.props.match.params.id}`)
      .then(response => {
        this.setState({ playbookDetails: response.data, loading: true });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }

          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Get Customer Billing Details
  _getCustomerBillingDetails() {
    apiClient.defaults.headers.common.Authorization = getCookie(
      COOKIE_SESSION_TOKEN
    );
    return apiClient
      .get(`${endpoints().customerUserAPI}/getDetails/`)
      .then(response => {
        this.setState({
          customerDetails: response.data,
          isLoading: true
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  // Redirect the page
  redirectTo() {
    const { goBack, action } = this.props.history;
    return action === "POP"
      ? (window.location = getUrlParameter("redirect") || "/")
      : goBack();
  }

  render() {
    const currentUrl = window.location.pathname + window.location.search;

    const { settings } = this.props;

    const enablePlaybook = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PLAYBOOK)
      : "";

    const styles = {
      // styles that are applied to the body from the admin
      backgroundColor: this.state.bgColor
    };
    const {
      mockBannerBtn,
      mockBannerBackground,
      playbookDetails,
      isInvalidRequest,
      customerDetails
    } = this.state;

    // Redirect to 404 page
    if (
      isInvalidRequest ||
      (this.state.playbookDetails.publishStatus === PLAYBOOK_STATUS_DRAFT &&
        !isSuperAdmin(this.props.roleId) &&
        !isCompanyAdmin(this.props.roleId) &&
        !isCompanyManager(this.props.roleId))
    ) {
      return (
        <div className="app-body">
          <Page404 />
        </div>
      );
    }

    const {
      name,
      description,
      defaultDeliverables,
      designExperts,
      ratings,
      tags,
      cartOptions,
      createdByUserDetail,
      packageDeliverables,
      pricingType
    } = playbookDetails;

    const showStars = stars_given => {
      const max_stars = Number(stars_given);
      let stars_arr = [];
      for (let i = 1; i < 6; i++) {
        stars_arr.push(
          <SVG
            key={i}
            src={starIcon}
            className={i <= max_stars ? "filled" : ""}
          />
        );
      }
      return stars_arr;
    };

    const isExistPlaybook =
      this.state.playbookDetails.length === undefined ? true : false;

    if (enablePlaybook && enablePlaybook === "true") {
      return (
        <div className="marketplace-landing" style={styles}>
          {!isExistPlaybook && <Spinner />}
          {isExistPlaybook && <SubNavigation redirectTo={this.redirectTo} />}
          {isExistPlaybook && (
            <Container>
              <Row>
                <Col md="9">
                  <div className="site-maincontent mt-3">
                    <Banner
                      theme={"light"}
                      title={"You’re viewing a Packaged Service Playbook"}
                      subtitle={"Services performed by vetted Experts"}
                      btn={{
                        url: `${TORCHLITE_WEBSITE_URL}/salesforce-essentials-advisors/`,
                        text: "Learn More",
                        target: "_blank"
                      }}
                      background={{
                        bgColor: "#000",
                        bgImage: headerTriangles,
                        bgImageMobile: headerTriangles
                      }}
                      classnames={"banner-xsmall"}
                    />

                    {name && (
                      <MarketplaceSection
                        title={name}
                        author={
                          createdByUserDetail &&
                          `by ${getFullName(
                            createdByUserDetail.first_name,
                            createdByUserDetail.last_name
                          )}`
                        }
                      >
                        <div className="d-none">
                          <div className="rating-and-usage d-flex">
                            <div className="rating-wrapper d-flex">
                              {showStars(4).map(star => star)}
                              <span className="h7">
                                {playbookDetails.ratingCount} Ratings
                              </span>
                            </div>
                            <div className="usage-wrapper d-flex">
                              <i className="fa fa-cog" aria-hidden="true" />
                              <span className="h7 ml-1">Used 46 Times</span>
                            </div>
                            {/* /.usage-wrapper */}
                          </div>
                        </div>
                        {/* /.rating-and-usage */}
                        <>
                          <div
                            className="content"
                            style={{
                              width: "100%"
                            }}
                          >
                            <div
                              className="mr-3 mb-2 float-left"
                              style={{
                                fontSize: 0,
                                width: "270px"
                              }}
                            >
                              <PlaybookCard
                                data={playbookDetails}
                                showCardBody={false}
                                bannerImageUrl={playbookDetails.bannerImagePath}
                              />
                            </div>
                            <p>{description}</p>
                          </div>

                          {defaultDeliverables &&
                          defaultDeliverables.length > 0 ? (
                            <div className="ml-2">
                              <p>
                                <b>Campaign Deliverables:</b>
                              </p>
                              {defaultDeliverables.map(deliverable => (
                                <li>{deliverable.description}</li>
                              ))}
                            </div>
                          ) : (
                            ""
                          )}
                        </>
                        {/* /.content */}
                      </MarketplaceSection>
                    )}
                    {designExperts && (
                      <ContentCards>
                        <div className="col-12 pl-0">
                          <span className="d-block h5">
                            <b>
                              Meet some of the Salesforce Essential Advisors
                            </b>
                          </span>
                        </div>
                        <p>
                          Each Advisor has gone through official Salesforce
                          course.
                        </p>

                        <div className="row">
                          {designExperts.map(expert => (
                            <ExpertCard
                              classnames={"expert-card"}
                              data={expert}
                              size={"col-4"}
                              key={expert.id}
                              certified={expert.certified}
                              link={`/expert-profile/${expert.id}?redirect=${currentUrl}`}
                            />
                          ))}
                        </div>
                      </ContentCards>
                    )}
                    {ratings && ratings.length > 0 && (
                      <Ratings ratings={ratings} />
                    )}
                  </div>
                </Col>
                <Col md="3">
                  <div className="site-sidebar mt-3 pt-4">
                    {cartOptions && cartOptions.length > 0 && (
                      <CartTabs
                        id={this.props.match.params.id}
                        playbook={playbookDetails}
                        options={cartOptions}
                        pricingType={pricingType}
                        customerDetails={customerDetails}
                        packageDeliverables={packageDeliverables}
                        settings={settings}
                      />
                    )}
                    {tags && tags.length > 0 && (
                      <TagCloud theme="dark" badges={tags} />
                    )}
                    <Banner
                      theme={"light"}
                      title={"Looking for a custom project?"}
                      subtitle={
                        "If you already have an idea of what you need, we can help you find an Expert."
                      }
                      btn={{
                        url: "/find-an-expert/",
                        text: "Learn More"
                      }}
                      background={{
                        bgColor: "#ffa200",
                        bgImage: headerTriangles,
                        bgImageMobile: headerTriangles
                      }}
                      classnames={"banner-box banner-xsmall"}
                    />
                  </div>
                </Col>
              </Row>
            </Container>
          )}
          {isExistPlaybook && (
            <Banner
              theme={"dark"}
              title={"Exceptional service, powered by Torchlite"}
              subtitle={
                "We verify each and every one of the Experts and make sure they match your specific project needs. You get VIP support for 100% satisfaction every time you work with Torchlite."
              }
              btn={mockBannerBtn}
              background={mockBannerBackground}
              classnames={""}
            />
          )}
        </div>
      );
    } else {
      return (
        <div className="app-body">
          <Page404 />
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(PlaybookView);
