import React, { Component } from "react";

// Components
import Text from "../../components/base/Text";
import UploadAvatar from "./UploadAvatar";
import UserRole from "../../components/UserRole";

// Constants
import {
  USER_INVITED_TEXT,
  USER_ACCEPTED_TEXT,
  USER_STATUS_ACCEPTED,
  USER_STATUS_INVITED
} from "../../roles/Constants";

import { endpoints } from "../../configs";
import Password from "../../components/base/Password";
import SingleCheckbox from "../adminSettings/inner-component/Checkbox";
import { isSuperAdmin } from "../../lib/helper";
import TimeZoneSelect from "../../components/TimeZoneSelect";
import { TIME_ZONE } from "../../setting/Constants";

const statusStyle = {
  borderRadius: "10px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "75px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px",
  paddingBottom: "2px",
  paddingTop: "2px",
  backgroundColor: "#0AD4BB"
};

class UserFormFields extends Component {
  // Render the form fields
  render() {
    const {
      confirmPasswordError,
      strongPasswordError
    } = this.props.errorMessage;
    return (
      <div>
        <div className="card bg-white mt-3 mb-3">
          <div className="card-body">
            <div className="row">
              <p
                className={`text-center ml-3 ${
                  this.props.UserDetails.status === USER_STATUS_INVITED
                    ? "bg-secondary"
                    : this.props.UserDetails.status === USER_STATUS_ACCEPTED
                    ? this.props.UserDetails.status === null
                    : "bg-white"
                }`}
                style={statusStyle}
                disableOnClick
              >
                {this.props.UserDetails.status === USER_STATUS_INVITED
                  ? USER_INVITED_TEXT
                  : this.props.UserDetails.status === USER_STATUS_ACCEPTED
                  ? USER_ACCEPTED_TEXT
                  : this.props.UserDetails.status === null
                  ? null
                  : USER_INVITED_TEXT}
              </p>
              <p className="ml-3">{this.props.UserDetails.status_updated_at}</p>
            </div>
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="firstName"
                  label="First Name"
                  placeholder="Enter First Name"
                  required={true}
                  error=""
                />
                <Text
                  name="lastName"
                  label="Last Name"
                  placeholder="Enter Last Name"
                  error=""
                  required={true}
                />
                <Text
                  name="email"
                  label="Email"
                  placeholder="Enter Email"
                  required={true}
                  error=""
                />
                <UserRole
                  name="roleId"
                  label="Role"
                  placeholder="Enter Role"
                  isSearchable={true}
                  defaultValue={this.props.defaultValue}
                  roleNames={this.props.UserDetails.roleName}
                  required
                />
                <Password
                  name="newPassword"
                  label="New Password"
                  placeholder="Enter New Password"
                  error={strongPasswordError}
                />
                <Password
                  name="confirmPassword"
                  label="Confirm Password"
                  placeholder="Enter Confirm Password"
                  error={confirmPasswordError}
                />
                <TimeZoneSelect name={TIME_ZONE} label="Time Zone" />
                {isSuperAdmin(
                  this.props.loggedInUser && this.props.loggedInUser.roleId
                ) && (
                  <SingleCheckbox
                    name="internal_user"
                    label="Internal user"
                    className="accepted-terms  pb-0  mr-3"
                  />
                )}
              </div>
              <div className="col-lg-6 col-sm-12 p-3">
                <UploadAvatar
                  data={this.props.UserDetails}
                  apiURL={`${endpoints().userAvatarUpdate}`}
                  userId={this.props.userId}
                  customSize={140}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default UserFormFields;
