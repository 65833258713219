import React from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import Text from "../../../../components/base/Text";
import SaveButton from "../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_FACEBOOK_URL,
  SETTINGS_INSTAGRAM_URL,
  SETTINGS_LINKEDIN_URL,
  SETTINGS_TWITTER_URL,
  SETTINGS_YOUTUBE_URL
} from "../../../../setting/Constants";

const SocialSection = props => {
  const { adminSettings, saveSettings } = props;

  const {
    facebook_url,
    instagram_url,
    linkedin_url,
    twitter_url,
    youtube_url
  } = adminSettings;

  // General Settings Social Section Initial Values
  const generalSettingsSocialSectionInitialValues = {
    facebook_url,
    instagram_url,
    linkedin_url,
    twitter_url,
    youtube_url
  };

  // General Settings Social Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Facebook url
    if (values.facebook_url !== undefined) {
      data.append(
        "facebook_url",
        values.facebook_url ? values.facebook_url : ""
      );
    }

    // instagram url
    if (values.instagram_url !== undefined) {
      data.append(
        "instagram_url",
        values.instagram_url ? values.instagram_url : ""
      );
    }

    // LinkedIn url
    if (values.linkedin_url !== undefined) {
      data.append(
        "linkedin_url",
        values.linkedin_url ? values.linkedin_url : ""
      );
    }

    // Twitter url
    if (values.twitter_url !== undefined) {
      data.append("twitter_url", values.twitter_url ? values.twitter_url : "");
    }
    //Youtube_url
    if (values.youtube_url !== undefined) {
      data.append("youtube_url", values.youtube_url ? values.youtube_url : "");
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={generalSettingsSocialSectionInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          <h5 className="font-weight-bold">Facebook Url</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_FACEBOOK_URL}
              placeholder="Enter Facebook Url"
            />
          </div>
          <h5 className="font-weight-bold">Instagram Url</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_INSTAGRAM_URL}
              placeholder="Enter Instagram Url"
            />
          </div>
          <h5 className="font-weight-bold">LinkedIn Url</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_LINKEDIN_URL}
              placeholder="Enter LinkedIn Url"
            />
          </div>
          <h5 className="font-weight-bold">Twitter Url</h5>
          <div className="field-wrapper">
            <Text name={SETTINGS_TWITTER_URL} placeholder="Enter Twitter Url" />
          </div>
          <h5 className="font-weight-bold">Youtube Url</h5>
          <div className="field-wrapper">
            <Text name={SETTINGS_YOUTUBE_URL} placeholder="Enter Youtube Url" />
          </div>
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default SocialSection;
