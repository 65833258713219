import React, { Component } from "react";

// Configs
import { isLoggedIn } from "../../lib/helper";

// Inner Components
import TabContentSection from "./inner-components/TabContentSection";

//Components
import List from "../../components/base/List";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
class Reports extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    const activeTab = this.props.match.params.tab;
    const activeSubTab = this.props.match.params.subtab;
    const activeSection = this.props.match.params.section;
    const activeSubSection = this.props.match.params.subsection;

    const tableheader = [
      {
        name: "Report Name"
      },
      {
        name: "Category"
      }
    ];

    const reportdata = [
      {
        title: "Overall Summary Report",
        link: "/reports/overall-summary",
        description: "Report on Overall Summary",
        category: "Overall Summary"
      },
      {
        title: `${Terminology.get(SystemName.REFERRAL)} Report`,
        link: "/reports/referral-report",
        description: `Report on  ${Terminology.get(SystemName.REFERRALS)} `,
        category: `${Terminology.get(SystemName.REFERRALS)}`
      },
      {
        title: "Pipeline Reports",
        link: "/reports/pipeline",
        description: `Report on all Partner sourced ${Terminology.get(
          SystemName.REFERRALS
        )}`,
        category: "Pipeline"
      },
      {
        title: "Partner Report",
        link: "/reports/partner-report",
        description: "Report on all Partners",
        category: "Partner Activity"
      },
      {
        title: "Partner User Report",
        link: "/reports/partner-user-report",
        description: "Report on all Partners Users",
        category: "Partner User Activity"
      },
      {
        title: "User Activity Reports",
        link: "/reports/user-activities",
        description: "Report on all User Activities",
        category: "User Activity"
      },
      {
        title: "Partner Activity Reports",
        link: "/reports/partner-activities",
        description: "Report on all Partner Activities",
        category: "Partner Activity"
      },
      {
        title: "Partner Tier / Quota Report",
        link: "/reports/partner-tier-quota-report",
        description: "Report on all Partner Activities",
        category: "Partner Activity"
      },
      {
        title: `${Terminology.get(
          SystemName.REFERRAL
        )} Link Tracking Activity Report`,
        link: "/reports/referral-link-tracking-activity-report",
        description: `Report on all ${Terminology.get(
          SystemName.REFERRAL
        )} Link Tracking Activities`,
        category: "Partner Activity"
      },
      {
        title: `${Terminology.get(SystemName.REFERRAL)} Link Tracking Report`,
        link: "/reports/referral-link-tracking-report",
        description: `Report on all ${Terminology.get(
          SystemName.REFERRAL
        )} Link Tracking`,
        category: "Partner Activity"
      },
      {
        title: `Closed Won ${Terminology.get(SystemName.REFERRAL)} Report`,
        link: "/reports/closed-won-referral-report",
        description: `Report on all Closed Won ${Terminology.get(
          SystemName.REFERRAL
        )} Report`,
        category: `${Terminology.get(SystemName.REFERRAL)} `
      }
    ];

    return !activeTab ? (
      <>
        <List header={tableheader} data={reportdata} heading="Reports" />
      </>
    ) : (
      <TabContentSection
        activeTab={activeTab}
        activeSubTab={activeSubTab}
        activeSection={activeSection}
        activeSubSection={activeSubSection}
        toggleTabs={this.toggle}
        history={this.props.history}
        match={this.props.match}
        settings={this.props.settings}
        loggedInUser={this.props.loggedInUser}
      />
    );
  }
}

export default Reports;
