import React from "react";

import { apiClient } from "../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../configs";
import { TAG_TYPE_REGION } from "../tagType/Constants";

// Helper
import { getCookie } from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

// Components
import Select from "./base/Select";
import { isBadRequest } from "../common/http";

class Region extends React.Component {
  state = {
    RegionTypeList: "",
    defaultRegion: {}
  };

  // Get Region tags
  getRegionTags = async () => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${TAG_TYPE_REGION}`
      );
      const results = response.data.data;

      if (results && results.length > 0) {
        const tagList = [];
        const defaultRegion = {};
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });

          // Set default region
          if (tag.name === "America") {
            defaultRegion.value = tag.id;
            defaultRegion.label = tag.name;
          }
        });
        this.setState({ RegionTypeList: tagList, defaultRegion });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  componentDidMount() {
    this.getRegionTags();
  }

  // Get options
  getRegionTypeOptions = () => {
    const { RegionTypeList } = this.state;
    let regionTyeOptions = [];

    if (!RegionTypeList) return regionTyeOptions;

    RegionTypeList.forEach(type => {
      regionTyeOptions.push({ value: type.id, label: type.name });
    });

    return regionTyeOptions;
  };

  render() {
    const {
      name,
      label,
      placeholder,
      error,
      required,
      defaultValue,
      setDefaultRegion,
      onChange,
      id,
      isClearable
    } = this.props;

    return (
      <Select
        id={id || name}
        name={name}
        label={label}
        options={this.getRegionTypeOptions()}
        placeholder={placeholder}
        error={error}
        required={required}
        defaultValue={
          defaultValue || (setDefaultRegion ? this.state.defaultRegion : "")
        }
        onInputChange={onChange}
        isSearchable={true}
        isClearable={isClearable}
      />
    );
  }
}

export default Region;
