import React from "react";
import DefaultContent from "../content/DefaultContent";
import TextArea from "../base/TextArea";

const PartnerProfileAboutMe = ({ certificateName, certificateUrl }) => {
  return (
    <>
      <DefaultContent
        title={"Headline"}
        certificateImage={certificateUrl}
        certificateName={certificateName}
      >
        <TextArea name="headline" placeholder={"Headline"} />
      </DefaultContent>
    </>
  );
};

export default PartnerProfileAboutMe;
