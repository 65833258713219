import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import UserDropdown from "./UserDropdown";
import { getUserId } from "../../../lib/helper";
import CancelButton from "../../../components/base/CancelButton";

const AccountManageModal = React.memo(function AccountManageModal({
  isOpen,
  toggle,
  defaultAccountManagerList,
  selectedAccountManagerId,
  handleUpdateAccountManager,
  changeAccountManager
}) {
  const projectAccountManager = defaultAccountManagerList.find(
    x => x.id === selectedAccountManagerId
  );
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={["edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={toggle}>
        <h4 className={["font-weight-bold"].join(" ")}>
          Change Account Manager
        </h4>
      </ModalHeader>
      <ModalBody className={["mb-4"].join(" ")}>
        <div className="form-wrapper justify-content-center d-flex">
          <div className="field-wrapper">
            <label>Account Manager</label>
            <UserDropdown
              taskIndex={1}
              assignee={projectAccountManager}
              userlist={defaultAccountManagerList}
              updateDefaultManager={handleUpdateAccountManager}
              currentUserId={getUserId()}
            />
          </div>
        </div>
      </ModalBody>
      <ModalFooter className={["justify-content-center"].join(" ")}>
        <div className="btn-wrapper">
          <CancelButton onClick={toggle} />
          <button className="btn btn-primary" onClick={changeAccountManager}>
            Change Account Manager
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
});

export default AccountManageModal;
