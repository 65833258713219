import React from "react";
import { getFormattedMessageDateTime } from "../lib/helper";
import {
  ACTIVITY_TYPE_PARTNER_PROFILE_VIEW,
  ACTIVITY_TYPE_RESOURCE_VIEWED,
  ACTIVITY_TYPE_COURSE_VIEWED,
  ACTIVITY_TYPE_REFERAL_SUBMISSION,
  ACTIVITY_TYPE_PARTNER_USER_INVITATION_ACCEPTED
} from "../Activity/Constants";
// Components
import Avatar from "../components/base/Avatar";
import Terminology from "../lib/Terminology";
import SystemName from "../Constants/SystemName";

class PartnerActivityCard extends React.Component {
  render() {
    const {
      partnerAvatar,
      partnerCompanyName,
      userName,
      activityType,
      activityTime,
      notes,
      firstName,
      lastName,
      id
    } = this.props;
    return (
      <div className="row d-flex align-items-center justify-content-justify">
        <div className="col-1 mr-2">
          <Avatar
            id={id}
            firstName={firstName}
            lastName={lastName}
            size="xs"
            fontSize={12}
            imageSize={"35"}
            url={partnerAvatar}
            bgColor={"rgb(0 0 0)"}
            color={"rgb(255 255 255)"}
          />
        </div>
        <div className=" col-10">
          {userName ? (
            <span className="mr-3" style={{ fontSize: "14px" }}>
              {userName}
              {activityType === ACTIVITY_TYPE_PARTNER_PROFILE_VIEW
                ? ` viewed ${partnerCompanyName} Partner Profile in Marketplace`
                : activityType === ACTIVITY_TYPE_COURSE_VIEWED
                ? ` from ${partnerCompanyName} viewed course: ${notes}`
                : activityType === ACTIVITY_TYPE_REFERAL_SUBMISSION
                ? ` from ${partnerCompanyName} submitted a ${Terminology.get(
                    SystemName.REFERRAL
                  )} (${notes})`
                : activityType ===
                  ACTIVITY_TYPE_PARTNER_USER_INVITATION_ACCEPTED
                ? ` from ${partnerCompanyName} accepted Invitation to partner`
                : ` from ${partnerCompanyName} ${activityType &&
                    " " + activityType}`}
            </span>
          ) : (
            <span className="mr-3" style={{ fontSize: "14px" }}>
              {activityType === ACTIVITY_TYPE_PARTNER_PROFILE_VIEW
                ? ` Guest viewed ${partnerCompanyName}` +
                  `${activityType && " " + activityType}`
                : `${partnerCompanyName}` +
                  `${activityType && " " + activityType}  by Guest`}
            </span>
          )}
          <div>
            {activityTime && (
              <p className={["text-inline-grayed", "h7"].join(" ")}>
                {activityTime}
              </p>
            )}
          </div>
        </div>
      </div>
    );
  }
}

export default PartnerActivityCard;
