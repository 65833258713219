import React from "react";

// Components
import ProfileCard from "../../../components/base/ProfileCard";

import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";
import {
  isPartner,
  isCompanyAdmin,
  isCompanyManager,
  isSuperAdmin
} from "../../../lib/helper";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";
import { connect } from "react-redux";

class PartnerSection extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPartnerLoading: false,
      partner: ""
    };
  }

  componentDidMount() {
    this.props.partnerId && this.getPartnerDetails(this.props.partnerId);
  }

  componentDidUpdate(prevProps) {
    if (prevProps.partnerId !== this.props.partnerId)
      this.getPartnerDetails(this.props.partnerId);
  }

  // getPartnerDetails
  getPartnerDetails = partnerId => {
    apiClient
      .get(`${endpoints().partnerAPI}/?id=${partnerId}`)
      .then(response => {
        if (response && response.data) {
          const partner = response.data;
          this.setState({
            partner
          });
        }
      });
  };

  render() {
    const { partner } = this.state;
    const { roleId } = this.props;

    const managerUser = partner.managerUser;
    return (
      <>
        {isPartner(roleId) && (
          <ProfileCard
            title={Terminology.get(SystemName.PARTNER_MANAGER)}
            firstName={managerUser ? managerUser.first_name : ""}
            avatarUrl={managerUser ? managerUser.avatarUrl : ""}
            profileName={managerUser ? managerUser.first_name : ""}
          />
        )}
        {(isSuperAdmin(roleId) ||
          isCompanyAdmin(roleId) ||
          isCompanyManager(roleId)) && (
          <ProfileCard
            title={!this.props.showTitle ? "Partner" : ""}
            firstName={partner.companyName}
            avatarUrl={partner.avatarUrl}
            profileName={partner && partner.companyName}
            redirectUrl={`/edit/partner/public-profile?id=${partner.id}`}
            openInNewTab={false}
          />
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(PartnerSection);
