import React from "react";
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import Spinner from "../../../../components/base/Spinner";

import MoreDropdown from "../../../../components/base/MoreDropdown";
import Form from "../../../../components/base/Form";
import Text from "../../../../components/Text";
import AddButton from "../../../../components/base/AddButton";
import DeleteModal from "../../../../components/base/DeleteModal";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownItem
} from "reactstrap";

// Helper
import { isLoggedIn, toString } from "../../../../lib/helper";

// API call
import { apiClient } from "../../../../apiClient";

// Configs
import { endpoints } from "../../../../configs";

// Constant
import { isBadRequest } from "../../../../common/http";
import { toast } from "react-toastify";

class Terminology extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values.
    this.state = {
      id: "",
      system_name: "",
      custom_name: "",
      deleteModal: false,
      deleteData: false,
      isLoading: false,
      isOpen: false,
      sortByOptions: [
        {
          value: "system_name:ASC",
          label: "Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ]
    };
  }

  _toggle = id => {
    this.setState({ isOpen: !this.state.isOpen, id: id || "" });
  };

  /**
   * Create template
   *
   * @param data
   */
  create = async values => {
    const data = new FormData();
    data.append("id", values.id ? values.id : "");
    data.append("system_name", values.system_name ? values.system_name : "");
    data.append("custom_name", values.custom_name ? values.custom_name : "");
    return await apiClient
      .post(`${endpoints().terminologyAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.setState({ isLoading: true });

        this._toggle();
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  // delete Name page api call by id
  _delete(id) {
    apiClient
      .delete(`${endpoints().terminologyAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({ isLoading: true });

        toast.success(successMessage);
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  // update Name page api call by id
  update(values) {
    const data = new FormData();
    data.append("id", values.id ? values.id : "");
    data.append("system_name", values.system_name ? values.system_name : "");
    data.append("custom_name", values.custom_name ? values.custom_name : "");
    apiClient
      .put(`${endpoints().terminologyAPI}/${values.id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({ isLoading: true });

        toast.success(successMessage);
        this._toggle();
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  }

  // Delete Attachment
  render() {
    const { deleteModal, deleteData, isLoading } = this.state;
    if (isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <div className="d-flex justify-content-between">
          <h5 className="font-weight-bold">Terminology</h5>
          <div className="btn-wrapper">
            <AddButton
              label="Add New"
              className="pull-right btn btn-secondary"
              onClick={() => {
                this.setState({
                  id: "",
                  system_name: "",
                  custom_name: "",
                  isOpen: true
                });
              }}
            />
          </div>
        </div>

        <br />
        <div>
          <Modal
            isOpen={this.state.isOpen}
            toggle={this._toggle}
            className="landing-create-popup w-100"
            backdrop="static"
          >
            <ModalHeader
              toggle={this._toggle}
              cssModule={{ "modal-title": "w-100 text-center" }}
            >
              <p>{this.state.id ? "Edit Terminology" : "Add Terminology"}</p>
            </ModalHeader>
            <Form
              initialValues={{
                id: this.state.id || "",
                system_name: this.state.system_name || "",
                custom_name: this.state.custom_name || ""
              }}
              onSubmit={values => {
                values.system_name = toString(values.system_name).trim();
                values.custom_name = toString(values.custom_name);

                this.state.id ? this.update(values) : this.create(values);
              }}
            >
              <ModalBody className="ml-3 mr-3 mt-2 mb-3">
                <div>
                  <Text
                    name="system_name"
                    label="System Name"
                    placeholder="Enter System Name"
                    error=""
                  />
                </div>
                <div>
                  <Text
                    name="custom_name"
                    label="Custom Name"
                    placeholder="Enter Custom Name"
                    error=""
                  />
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="container-fluid">
                  <div className="col text-center">
                    <Button type="submit" label="">
                      {this.state.id ? "Save" : "Add"}
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </Form>
          </Modal>
        </div>
        {/* REDUX TABLE */}
        <ReduxTable
          id="terminology"
          className="mt-3 mb-3"
          apiURL={`${endpoints().terminologyAPI}`}
          showHeader
          newTableHeading
          paramsToUrl={true}
          pagination={true}
          history={this.props.history}
          searchPlaceholder="Search"
          sortByOptions={this.state.sortByOptions}
          onRowClick={row => {
            this.setState({
              system_name: row.system_name,
              custom_name: row.custom_name
            });
            return this._toggle(row.id);
          }}
        >
          <ReduxColumn
            type="link"
            isClickable="true"
            field="system_name"
            sortBy="system_name"
          >
            System Name
          </ReduxColumn>
          <ReduxColumn
            type="link"
            isClickable="true"
            field="custom_name"
            sortBy="custom_name"
          >
            Custom Name
          </ReduxColumn>

          <ReduxColumn
            field="status"
            disableOnClick
            className="action-column"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      this.setState({
                        system_name: row.system_name,
                        custom_name: row.custom_name
                      });
                      return this._toggle(row.id);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() =>
                      this.setState({
                        deleteModal: true,
                        deleteData: row
                      })
                    }
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          id="deleteTerminology"
          isOpen={deleteModal}
          toggle={() => {
            this.setState({ deleteModal: false });
          }}
          title="Delete Terminology"
          label={deleteData.system_name}
          deleteFunction={() => this._delete(deleteData.id)}
        />
      </>
    );
  }
}

export default Terminology;
