import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// Constants
import {
  OUTGOING_PAYMENTS_SUB_TAB,
  REFERRAL_PAYMENTS_SUB_TAB,
  TAB_PAYMENTS
} from "../../../../setting/Constants";

// Sub Tabs
import OutgoingPaymentsTab from "./OutgoingPaymentsTab";
import ReferralPaymentsTab from "./ReferralPaymentsTab";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

const PaymentsTab = props => {
  const {
    activeSubTab,
    adminSettings,
    saveSettings,
    toggleTabs,
    history
  } = props;

  return (
    <>
      <Nav tabs className="admin-tabs">
        {/* Outgoing Payments Tab */}
        {props.isBillDotcomConnected && (
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === OUTGOING_PAYMENTS_SUB_TAB
              })}
              onClick={() => {
                toggleTabs(TAB_PAYMENTS, OUTGOING_PAYMENTS_SUB_TAB);
              }}
            >
              {OUTGOING_PAYMENTS_SUB_TAB}
            </NavLink>
          </NavItem>
        )}

        {/* Referral Payments Tab */}
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === REFERRAL_PAYMENTS_SUB_TAB
            })}
            onClick={() => {
              toggleTabs(TAB_PAYMENTS, REFERRAL_PAYMENTS_SUB_TAB);
            }}
          >
            {Terminology.get(SystemName.REFERRAL)} Payments
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeSubTab}>
        {/* Outgoing Payments Tab */}
        <TabPane tabId={OUTGOING_PAYMENTS_SUB_TAB} className="w-100">
          {props.isBillDotcomConnected && (
            <OutgoingPaymentsTab
              adminSettings={adminSettings}
              saveSettings={saveSettings}
              history={history}
            />
          )}
        </TabPane>

        {/* Referral Payments Tab */}
        <TabPane tabId={REFERRAL_PAYMENTS_SUB_TAB} className="w-100">
          <ReferralPaymentsTab
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default PaymentsTab;
