import React from "react";
import { MatchedGraphicIcon } from "../../../assets/img/icons";
import OnboardingLink from "./OnboardingLink";

const ExpertMatched = props => {
  const percentage = 100;
  const { expertAvailableStatus, expertAvailableText } = props;
  return (
    <div className="expert-onboarding">
      <OnboardingLink
        icon={<MatchedGraphicIcon />}
        title={"You’ve been matched with your first project!"}
        description={
          "Check out the latest project activity and take action on the request as soon as possible."
        }
        availability={{
          status:
            expertAvailableStatus === "Completed"
              ? "available"
              : "not-available",
          text: expertAvailableText
        }}
        progress={percentage}
        expertStatus={{
          accepted: true,
          experttype: "normal"
        }}
      />
    </div>
  );
};

export default ExpertMatched;
