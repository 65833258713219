import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import Select from "../../../components/base/Select";
import Form from "../../../components/base/Form";
import CancelButton from "../../../components/base/CancelButton";

const EditSubcriptionStatusModal = ({
  modal,
  setModal,
  subscriptionStatusOptions,
  subscriptionStatus,
  updateProjectSubscriptionStatus,
  setProjectSubscriptionStatus
}) => {
  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      keyboard={true}
      backdrop="static"
      className={["edit-task-modal", "edit-client-modal"].join(" ")}
    >
      <ModalHeader toggle={setModal}>
        <h4 className={["font-weight-bold"].join(" ")}>
          Change Subscription Status
        </h4>
      </ModalHeader>
      <Form
        initialValues={{
          subscriptionStatus: subscriptionStatus
        }}
        className={["d-block"].join(" ")}
        onSubmit={values => {
          const projectSubscriptionStatus = values.subscriptionStatus
            ? values.subscriptionStatus.value
            : "";
          values.subscriptionStatus = projectSubscriptionStatus;

          updateProjectSubscriptionStatus(values);
          setProjectSubscriptionStatus(projectSubscriptionStatus);
          setModal();
        }}
      >
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper flex-column">
              <Select
                name="subscriptionStatus"
                label="Subscription Status"
                placeholder="Select Subscription Status"
                options={subscriptionStatusOptions}
                isSearchable={true}
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={setModal} className="mr-2" />
            <Button type="submit" label="">
              Change
            </Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default EditSubcriptionStatusModal;
