import React from "react";

import { apiClient } from "../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../configs";

// Helper
import { getCookie } from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

// Components
import Select from "./base/Select";
import { isBadRequest } from "../common/http";

class Partner extends React.Component {
  state = {
    partnerList: "",
    partnerOptions: []
  };

  // Get partner type
  getPartners = async () => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    try {
      const response = await apiClient.get(
        `${endpoints().partnerAPI}/search?marketplaceStatus=Approved`
      );
      const results = response && response.data && response.data.data;
      if (results && results.length > 0) {
        const partnerList = [];
        results.forEach(partner => {
          partnerList.push({
            id: partner.id,
            name: partner.companyName
          });
        });
        this.setState({ partnerList }, () => {
          this.getPartnerOptions();
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  componentDidMount() {
    this.getPartners();
  }

  //  Get options
  getPartnerOptions = () => {
    const { partnerList } = this.state;
    let partnerOptions = [];

    if (partnerList && partnerList.length > 0)
      partnerList.forEach(partner => {
        partnerOptions.push({
          value: partner.id,
          label: partner.name
        });
      });
    this.setState({ partnerOptions: partnerOptions });
  };

  render() {
    const {
      name,
      label,
      placeholder,
      error,
      required,
      isSearchable,
      width,
      handleChange,
      isDisabled,
      partnerId,
      isClearable
    } = this.props;
    const partnerOptions = this.state.partnerOptions;
    const defaultValue = partnerId
      ? partnerOptions.find(
          partnerDetail => partnerDetail.value === parseInt(partnerId, 10)
        )
      : "";

    return (
      <Select
        id={partnerId}
        name={name}
        label={label}
        placeholder={placeholder}
        options={partnerOptions}
        isSearchable={isSearchable}
        error={error}
        defaultValue={defaultValue}
        required={required}
        handleChange={handleChange}
        width={width}
        isDisabled={isDisabled}
        isClearable={isClearable}
      />
    );
  }
}

export default Partner;
