import React from "react";
import AvatarCard from "../../../components/base/AvatarCard";
import { Collapse } from "reactstrap";

function ReportTable(props) {
  const { data, toggleCollapse, isCollapsed } = props;
  return (
    <div>
      <div
        className="company-name  row justify-content-between mr-0 ml-0 mb-0"
        onClick={() => toggleCollapse(data.partner_id)}
        style={{
          cursor: "pointer"
        }}
      >
        <div className="row mx-2 mt-2 pb-0">
          <AvatarCard companyName={data.partnerName} url={data.avatarUrl} />
        </div>
        {isCollapsed[data.partner_id] ? (
          <span className="company-name fa fa-chevron-up "></span> // Up arrow (open)
        ) : (
          <span className="company-name fa fa-chevron-down "></span> // Down arrow (closed)
        )}
      </div>
      <Collapse isOpen={isCollapsed[data.partner_id] || false}>
        <div
          style={{
            backgroundColor: "#e7e7ea",
            marginBottom: "-20px",
            paddingTop: "1px",
            marginTop: "4px",
            paddingBottom: "7px"
          }}
        >
          {data.partnerUser.map((value, index) => (
            <div className="row mx-5 mt-2 pb-0" key={index}>
              <AvatarCard
                firstName={value.firstName}
                lastName={value.lastName}
                url={value.avatarUrl}
              />
              <div className="mt-1 ml-2 pt-1">
                {": "}
                {value.referralCount}
              </div>
            </div>
          ))}
        </div>
      </Collapse>
    </div>
  );
}

export default ReportTable;
