import React, { useEffect, useState } from "react";
import { Label } from "reactstrap";

// Assets
import { EditIconAlt } from "../../assets/img/icons";

// Payment Option
import PaymentOptionsList from "./paymentOptions/PaymentOptionsList";
import PaymentOption from "./paymentOptions/PaymentOption";

// Components
import WithDefaultWrapper from "../../components/WithDefaultWrapper";
import toast from "../../components/base/Toast";

// API Client
import { apiClient } from "../../apiClient";

// COnfigs
import { endpoints } from "../../configs";

// Add Payments
import AddPaymentOptions from "./AddPaymentOptions";

export const getPaymentMethods = async () => {
  return apiClient.get(`${endpoints().customerAPI}/payment-method`);
};

export const setPaymentMethods = async data => {
  return await apiClient
    .post(`${endpoints().customerAPI}/payment-method`, data)
    .then(res => {
      return res;
    })
    .catch(err => {
      toast.error(err.response.data.message);
    });
};

const WithSelectableWrap = ({
  optionSelectable,
  paymentOptionId,
  getSelectedPaymentOption,
  defaultPaymentOption,
  children
}) => {
  useEffect(() => {
    if (defaultPaymentOption && optionSelectable)
      getSelectedPaymentOption(paymentOptionId);
  }, []);
  return optionSelectable ? (
    <Label htmlFor={`payment-${paymentOptionId}`} className={"payment-option"}>
      <input
        type="radio"
        id={`payment-${paymentOptionId}`}
        value={paymentOptionId}
        className={"d-none"}
        name={"paymentOption"}
        onChange={() => getSelectedPaymentOption(paymentOptionId)}
        defaultChecked={defaultPaymentOption}
      />
      {children}
    </Label>
  ) : (
    children
  );
};

const PaymentOptions = ({
  listView,
  title,
  getSelectedPaymentOption,
  optionSelectable,
  showPaymentOptionButtons = true,
  showEditPaymentOptions = false,
  setNewPaymentOptionModal
}) => {
  const [paymentMethodList, setPaymentMethodList] = useState([]);

  const setAndUpdatePaymentMethods = data =>
    setPaymentMethods(data).then(res =>
      updatePaymentOptionsUi(res.data.message)
    );

  const verifyPaymentMethod = data => {
    return apiClient
      .patch(`/v1/customer/payment-method/${data.id}/verify`, {
        amounts: data.withdrawnAmounts
      })
      .then(() => {
        updatePaymentOptionsUi();
      })
      .catch(err => toast.error(err.response.data.message));
  };

  const deletePaymentMethod = id => {
    return apiClient
      .delete(`${endpoints().customerAPI}/payment-method/${id}`)
      .then(res => updatePaymentOptionsUi(res.data.message));
  };

  const setDefaultPaymentMethod = id => {
    return apiClient
      .patch(`${endpoints().customerAPI}/payment-method/${id}/set-default`)
      .then(res => updatePaymentOptionsUi(res.data.message));
  };

  const updatePaymentOptionsUi = message => {
    getPaymentMethods()
      .then(res => {
        message && toast.success(message);
        setPaymentMethodList(res.data.data);
      })
      .catch(err => console.error(err));
  };

  useEffect(() => {
    getPaymentMethods()
      .then(res => setPaymentMethodList(res.data.data))
      .catch(err => console.error(err));
  }, []);

  let sortedOptions = [...paymentMethodList].sort(
    (a, b) => b.is_default - a.is_default
  );

  return (
    <>
      {showPaymentOptionButtons && (
        <AddPaymentOptions
          setPaymentMethod={setAndUpdatePaymentMethods}
          title={"Add payment options"}
          isModal={false}
          updatePaymentMethodUi={updatePaymentOptionsUi}
        />
      )}
      <WithDefaultWrapper title={title}>
        {showEditPaymentOptions && (
          <button
            className="edit-btn btn btn-link d-flex"
            onClick={setNewPaymentOptionModal}
            type={"button"}
          >
            <EditIconAlt />
            Edit
          </button>
        )}
        <PaymentOptionsList>
          {sortedOptions.map(paymentOption => (
            <WithSelectableWrap
              paymentOptionId={paymentOption.id}
              optionSelectable={optionSelectable}
              getSelectedPaymentOption={getSelectedPaymentOption}
              defaultPaymentOption={paymentOption.is_default}
            >
              <PaymentOption
                paymentOption={paymentOption}
                deletePaymentOption={deletePaymentMethod}
                setDefaultPaymentOption={setDefaultPaymentMethod}
                verifyPaymentMethod={verifyPaymentMethod}
                listView={listView}
              />
            </WithSelectableWrap>
          ))}
          {sortedOptions.length < 1 && (
            <h6 className={["mt-3"].join(" ")}>
              You currently don't have any payment options added, please
              consider adding one!
            </h6>
          )}
        </PaymentOptionsList>
      </WithDefaultWrapper>
    </>
  );
};

export default PaymentOptions;
