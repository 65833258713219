import React from "react";

// Components
import DefaultContent from "../../../components/content/DefaultContent";
import StatisticsCard from "../../../components/base/StatisticsCard";
// Helper
import {
  isCompanyManager,
  isSuperAdmin,
  isCompanyAdmin
} from "../../../lib/helper";

import { REFERRAL_LINK_CLICKED } from "../../../reports/Constants";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";
import { useSelector } from "react-redux";
import { GetCurrencySymbol } from "../../../lib/CurrencySymbol";

const ReferralProgramStats = ({
  partnerStatics,
  partnerId,
  isApproved,
  enablePartnerPayments
}) => {
  const currentUser = useSelector(state => state.user);

  return (
    partnerStatics && (
      <DefaultContent>
        <div className="form-wrapper">
          <h5 className="font-weight-bold">
            {Terminology.get(SystemName.REFERRAL)} Program
          </h5>

          <div className={["card-count-statistics"].join(" ")}>
            <StatisticsCard
              count={partnerStatics.referralClickCount}
              label="Clicks"
              redirectUrl={
                isSuperAdmin(currentUser && currentUser.roleId) ||
                isCompanyAdmin(currentUser && currentUser.roleId) ||
                isCompanyManager(currentUser && currentUser.roleId)
                  ? `/reports/partner-activities?activityType=${REFERRAL_LINK_CLICKED}&partnerId=${partnerId}`
                  : "#"
              }
            />

            <StatisticsCard
              count={partnerStatics.referralCount}
              label={Terminology.get(SystemName.REFERRALS)}
              redirectUrl={
                isSuperAdmin(currentUser && currentUser.roleId) ||
                isCompanyAdmin(currentUser && currentUser.roleId) ||
                isCompanyManager(currentUser && currentUser.roleId)
                  ? `/referrals?partnerId=${partnerId}`
                  : isApproved
                  ? `/referrals`
                  : "#"
              }
            />

            <StatisticsCard
              count={0}
              label="Sold"
              redirectUrl={
                isSuperAdmin(currentUser && currentUser.roleId) ||
                isCompanyAdmin(currentUser && currentUser.roleId) ||
                isCompanyManager(currentUser && currentUser.roleId)
                  ? `/referrals?partnerId=${partnerId}`
                  : isApproved
                  ? `/referrals`
                  : "#"
              }
            />

            {enablePartnerPayments === "true" && (
              <StatisticsCard
                count={partnerStatics.earnedReferralAmount}
                prefix={GetCurrencySymbol()}
                label="Earned"
                redirectUrl={
                  isSuperAdmin(currentUser && currentUser.roleId) ||
                  isCompanyAdmin(currentUser && currentUser.roleId) ||
                  isCompanyManager(currentUser && currentUser.roleId)
                    ? `/referrals/converted?partnerId=${partnerId}`
                    : isApproved
                    ? `/referrals/converted`
                    : "#"
                }
              />
            )}
          </div>
        </div>
      </DefaultContent>
    )
  );
};

export default ReferralProgramStats;
