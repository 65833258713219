import React from "react";
import { Alert, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import { WarningRedIcon } from "../../../../assets/img/icons";

const ProspectAccountModal = props => {
  const {
    isNoAccountFoundModalOpen,
    setProspectModalValues,
    createAccount
  } = props;
  return (
    <>
      <Modal
        isOpen={isNoAccountFoundModalOpen}
        className={["log-hours-modal", "edit-task-modal"].join(" ")}
      >
        <ModalHeader
          toggle={() => {
            setProspectModalValues({
              isNoHubSpotProspectAccountFoundModalOpen: false
            });
          }}
        >
          <WarningRedIcon />
          <h4 className={["font-weight-bold", "mt-3"].join(" ")}>
            Prospect Company was not found. Check for partial matches before
            approving.
          </h4>
        </ModalHeader>
        <ModalBody className={["text-center", "mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <h6 className="mx-4"></h6>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          className={["justify-content-center", "border-top-0"].join(" ")}
        >
          <div className="btn-wrapper">
            <button
              className="btn btn-primary px-3"
              onClick={() => {
                setProspectModalValues({
                  isNoHubSpotProspectAccountFoundModalOpen: false,
                  isHubSpotProspectAccountSelectModalOpen: true,
                  hubSpotCompanyType: "Company (Prospect)"
                });
              }}
            >
              Inspect Companies
            </button>
            <button
              className="btn btn-success px-3"
              onClick={() => {
                setProspectModalValues({
                  isNoHubSpotProspectAccountFoundModalOpen: false,
                  hubSpotCompanyType: "Company (Prospect)"
                });
                createAccount("Company (Prospect)");
              }}
            >
              Approve (New Company)
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ProspectAccountModal;
