import React, { useState } from "react";

// Components
import PageTitle from "../../components/base/PageTitle";
import PageSearch from "../../components/base/PageSearch";
import LetsTalk from "../../components/LetsTalk";

import { HelpIcon, ChevronDown, ChevronUp } from "../../assets/img/icons";

// Helper
import { getKeyValueByObject } from "../../lib/helper";

// Constants
import { SETTINGS_ENABLE_HELP } from "../../setting/Constants";

// 404 page
import Page404 from "../Page404";

class Help extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      searchParam: ""
    };
  }
  _handleInputChange = e => {
    this.setState({
      searchParam: e.target.value
    });
  };

  render() {
    const { searchParam } = this.state;
    var searchResults = [];

    //Sample help data from mockup
    const helpList = [
      {
        question: "I've finished my profile, now what?",
        answer: `Salesforce Essentials and Torchlite are promoting this
        marketplace to attract customers and bring them into the platform so
        that we can match you for projects. We will make sure to let you know
        as soon as there is a project available.`
      },
      {
        question:
          "I have other certifications, should I include them on my profile?",
        answer: `Please include any Salesforce related Certifications.`
      },
      {
        question:
          "How can I contact Torchlite to ask a question or give feedback?",
        answer: `On your dashboard you can contact Torchlite Account Manager,
        or reach out to your Account Manager via email directly.
        You can also email success@torchlite.com.`
      }
    ];
    const { settings } = this.props;
    const enableHelp = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_HELP)
      : "";
    if (enableHelp && enableHelp === "false") {
      return <Page404 />;
    }

    return (
      <>
        <PageTitle label="Help" />
        <div
          className={`page-heading d-flex cover flex-md-row align-items-center flex-column
                      "justify-content-between"
                 `}
        >
          <PageSearch
            classnames="page-search"
            placeholder="Search"
            onChange={this._handleInputChange.bind(this)}
          />
          <LetsTalk />
        </div>
        <div className={`card rounded mb-5`}>
          {helpList.forEach(help => {
            if (searchParam) {
              if (help.question.includes(searchParam)) {
                searchResults.push(help);
                return <SingleHelp help={help} />;
              }
            } else {
              return <SingleHelp help={help} />;
            }
          })}

          {searchParam && searchResults.length === 0 && (
            <div className="p-4 border-bottom align-items-center justify-content-center">
              <div className="d-flex justify-content-center">
                <HelpIcon />
                <span className="ml-4 font-weight-bold">No Results Found</span>
              </div>
            </div>
          )}
        </div>
      </>
    );
  }
}
const SingleHelp = props => {
  const [showAnswer, setShowAnswer] = useState(false);

  const { help } = props;
  return (
    <div className="p-4 border-bottom">
      <div className="d-inline-flex">
        <HelpIcon />
        <span className="ml-4 font-weight-bold">
          {help.question && help.question}
        </span>
      </div>
      <div
        className="pull-right cursor-pointer"
        onClick={e => setShowAnswer(prevState => !prevState)}
      >
        {showAnswer && help.answer ? <ChevronUp /> : <ChevronDown />}
      </div>
      {showAnswer && help.answer && (
        <p className="mx-4 mt-3 px-4 h6-5">{help.answer && help.answer}</p>
      )}
    </div>
  );
};

export default Help;
