import React, { useState } from "react";

// Configs
import { endpoints } from "../../../../configs";

import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";

const BomboraTab = props => {
  const { sortByOptions } = props;

  return (
    <>
      <ReduxTable
        onScroll
        id="bombora"
        apiURL={`${endpoints().bomboraAPI}/search`}
        history={props.history}
        showHeader
        sortByOptions={sortByOptions}
        newTableHeading
        paramsToUrl={true}
      >
        <ReduxColumn
          width={"140px"}
          isClickable="false"
          field="accountName"
          sortBy="account_name"
        >
          Account Name
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="companyName" sortBy="company_name">
          Company Name
        </ReduxColumn>
        <ReduxColumn width={"110px"} sortBy="domain" field="domain">
          Domain
        </ReduxColumn>
        <ReduxColumn Width={"105px"} sortBy="company_size" field="companySize">
          Company Size
        </ReduxColumn>
        <ReduxColumn
          width={"135px"}
          field="companyRevenue"
          sortBy="company_revenue"
        >
          Company Revenue
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="industry" sortBy="industry">
          Industry
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="address1" sortBy="address1">
          Address 1
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="address2" sortBy="address2">
          Address 2
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="hqCity" sortBy="hq_city">
          HQ City
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="hqState" sortBy="hq_state">
          HQ State
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="hqZip" sortBy="hq_zip">
          HQ Zip
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="hqCountry" sortBy="hq_country">
          HQ Country
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="averageScore"
          sortBy="average_score"
        >
          Average Score
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="topicCount" sortBy="topic_count">
          Topic Count
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="topicCountDelta"
          sortBy="topic_count_delta"
        >
          Topic Count Delta
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="createdAt" sortBy="createdAt">
          createdAt
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="updatedAt" sortBy="updatedAt">
          updatedAt
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default BomboraTab;
