import React from "react";

// Config
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";

// Components
import SidebarItem from "./SidebarItem";
import toast from "../../../components/base/Toast";
import PartnerSelectionModal from "../../../components/PartnerSelectionModal";
import SourceMoreWaitingPartners from "./SourceMoreWaitingPartners";
import PartnerSection from "./PartnerSection";

// Helper
import {
  getUrlParameter,
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../lib/helper";

// Constants
import { PARTNER_STATUS_APPROVED } from "../../../partnerStatus/Constants";
import { DEAL_STATUS_DRAFT } from "../../../dealStatus/Constants";
import {
  REFERRAL_PARTNER_STATUS_PENDING,
  REFERRAL_PARTNER_STATUS_ACCEPTED,
  REFERRAL_PARTNER_STATUS_NOT_INTERESTED
} from "../../../referral/Constants";
import { isBadRequest } from "../../../common/http";
import { connect } from "react-redux";

// Referral source more partners pending list statuses
const sourceMorePartnersPendingListStatuses = [
  REFERRAL_PARTNER_STATUS_PENDING,
  REFERRAL_PARTNER_STATUS_ACCEPTED,
  REFERRAL_PARTNER_STATUS_NOT_INTERESTED
];

class SourcePartners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPartnerLoading: false,
      partnerList: [],
      currentPartner: {},
      partnerIds: [],
      isPartnerModal: false,
      isReferralPartnerLoading: false,
      referralPartnerList: [],
      projectPartnerWaitingList: [],
      pendingReferralPartnerList: [],
      partnerRequestId: "",
      partnerStatus: "",
      partnerWaitingRequestId: [],
      checkBox: {
        shortListedPartners: []
      },
      editInterestStatusModal: false,
      selectedEditInterestPartnerId: "",
      filteredPartnerList: [],
      reassignButton: false,
      reAssignValue: ""
    };
    this.toggle = this.toggle.bind(this);
    this.handlePartners = this.handlePartners.bind(this);
    this._handleShortListedPartners = this._handleShortListedPartners.bind(
      this
    );
  }
  componentDidMount() {
    // Check is logged in user
    // isLoggedIn();
    this._getPartnersList(this.props.referralId);
    if (
      isPartner(this.props.roleId) &&
      this.props.referralStatus &&
      this.props.referralStatus === DEAL_STATUS_DRAFT &&
      this.props.currentUserId
    ) {
      this._getCurrentPartner();
    }
  }

  componentDidUpdate(prevProps) {
    // Check is logged in user
    // isLoggedIn();
    if (prevProps.isReferralUpdate !== this.props.isReferralUpdate)
      this._getPartnersList(this.props.referralId);
  }

  componentWillReceiveProps(nextProps) {
    if (
      nextProps.reassignfun !== this.state.reassignButton &&
      nextProps.reassignfun !== undefined &&
      nextProps.reAssignValue === "Reassign Partner"
    ) {
      this.setState({
        reassignButton: nextProps.reassignfun,
        reAssignValue: nextProps.reAssignValue
      });
      this._getPartnersList();
      this.toggle();
    }
  }

  getSelectedPartner() {
    if (this.props.selectedPartner) {
      this.setState({
        referralPartnerList: [this.props.selectedPartner],
        partnerIds: [this.props.selectedPartner.id]
      });

      if (this.props.setReferralPartners) {
        this.props.setReferralPartners(
          [this.props.selectedPartner.id],
          PARTNER_STATUS_APPROVED
        );
      }
    }
  }

  // Get Current Partner
  _getCurrentPartner = () => {
    const id = getUrlParameter("id");
    this.setState({ isPartnerLoading: true }, () => {
      apiClient.get(`${endpoints().partnerAPI}?id=${id}`).then(response => {
        const currentPartner = response.data;

        this.setState({
          isPartnerLoading: false,
          currentPartner: currentPartner
            ? currentPartner
            : this.state.currentPartner
        });
      });
    });
  };

  // Filter Partner List
  _filterPartnersList(selectedFilters) {
    const { partnerList } = this.state;
    let filteredPartnerList = [],
      tags = [],
      city = "",
      type = "",
      tier = "";

    // Check filters selected
    if (selectedFilters) {
      city = selectedFilters.city || "";
      type = selectedFilters.type || "";
      tags = selectedFilters.tags || [];
      tier = selectedFilters.tier || "";
    }

    // Filter partners
    if (partnerList && partnerList.length > 0) {
      this.setState({ isPartnerLoading: true }, () => {
        partnerList.forEach(partner => {
          const partnerType = partner.partnerTypeName || "";
          const partnerServices = partner.services || [];
          const partnerPlatforms = partner.platforms || [];
          const partnerCity = partner.city || "";
          const partnerTier = partner.partnerTierName || "";
          let isTypeExist = false;
          let isCityExist = false;
          let isTagExist = false;
          let isTierExist = false;

          if (partnerType === type || type === "") isTypeExist = true;
          if (partnerCity === city || city === "") isCityExist = true;
          if (partnerTier === tier || tier === "") isTierExist = true;

          if (tags && tags.length > 0) {
            tags.forEach(tagName => {
              let isServiceExist = false;
              let isPlatformExist = false;
              isServiceExist = this.isTagExist(partnerServices, tagName);
              isPlatformExist = this.isTagExist(partnerPlatforms, tagName);
              if (isServiceExist || isPlatformExist) {
                isTagExist = true;
              }
            });
          } else {
            isTagExist = true;
          }
          if (isTypeExist && isCityExist && isTagExist && isTierExist) {
            filteredPartnerList.push(partner);
          }
        });
        this.setState({
          filteredPartnerList: filteredPartnerList,
          isPartnerLoading: false
        });
      });
    }
  }

  // Check is tag exist in partner tag data
  isTagExist = (tagList, filterTagName) => {
    if (!tagList.length) {
      return false;
    }

    let isExist = false;
    tagList.forEach(tag => {
      if (filterTagName === tag.tag_name) {
        isExist = true;
      }
    });

    return isExist;
  };

  _getPartnersList(referralId) {
    this.setState({ isPartnerLoading: true }, () => {
      apiClient
        .get(
          `${
            endpoints().partnerAPI
          }/search?marketplaceStatus=${PARTNER_STATUS_APPROVED}`
        )
        .then(response => {
          const partnerList = response.data.data;

          this.setState(
            {
              isPartnerLoading: false,
              partnerList: partnerList ? partnerList : this.state.partnerList
            },
            () => {
              this._filterPartnersList({});
              this.getSelectedPartner();
            }
          );
          this.props.referralStatus &&
            this.props.referralStatus !== DEAL_STATUS_DRAFT &&
            this._getSourceMorePartnersPendingList(
              true,
              sourceMorePartnersPendingListStatuses.join(", ")
            );
        });
    });
  }

  // Handle Modal Click
  toggle = () => {
    this.setState({
      isPartnerModal: !this.state.isPartnerModal,
      reAssignValue: null
    });
  };

  // Handle partners
  handlePartners(value) {
    if (!value) {
      return this.setState({ partnerIds: [] });
    }

    const partnerIds = this.state.partnerIds.slice();
    if (partnerIds.indexOf(value) > -1) {
      this.setState({ partnerIds: partnerIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ partnerIds: [value] });
    }
  }

  // Create Project User
  createReferralPartner() {
    const { partnerIds } = this.state;
    const referralId = this.props.referralId;

    if (referralId) {
      const data = {
        partnerIds: partnerIds,
        referralId: referralId,
        status: REFERRAL_PARTNER_STATUS_PENDING
      };

      return apiClient
        .put(
          `${
            endpoints().referralApi
          }/${referralId}?status=${REFERRAL_PARTNER_STATUS_PENDING}`,
          data
        )
        .then(() => {
          this.toggle();
          this.setState({
            partnerIds: [],
            isPartnerModal: false,
            reassignButton: true,
            reAssignValue: null
          });
          this._getSourceMorePartnersPendingList(
            true,
            sourceMorePartnersPendingListStatuses.join(", ")
          );
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    } else if (this.props.setReferralPartners) {
      this.setSelectedReferralPartners(partnerIds);
      this.props.setReferralPartners(partnerIds, PARTNER_STATUS_APPROVED);
      this.setState({ partnerIds: [] }, () => {
        this.toggle();
      });
    }
  }

  setSelectedReferralPartners = partnerIds => {
    const partnerList = this.state.partnerList;
    if (partnerIds && partnerIds.length > 0 && partnerList.length > 0) {
      partnerList.map(partner => {
        partnerIds.forEach(partnerId => {
          if (partner.id === partnerId) {
            if (
              this.state.referralPartnerList.filter(
                referralPartner => referralPartner.id === partnerId
              ).length === 0
            ) {
              this.setState({ referralPartnerList: [partner] });
            }
          }
        });
      });
    }
  };

  // Update Partner List
  updatePartnerList = selectedFilters => {
    this._filterPartnersList(selectedFilters);
  };

  // Get Project User Pending List
  _getSourceMorePartnersPendingList(isReferralPartner, status) {
    const referralId = this.props.referralId;
    this.setState(
      { isReferralPartnerLoading: isReferralPartner, isPartnerLoading: true },
      () => {
        apiClient
          .get(
            `${endpoints().referralPartnerAPI}/search?referralId=${referralId}`
          )
          .then(response => {
            const referralPartnerList = response.data.data;

            const selectedIds = [];
            if (referralPartnerList) {
              referralPartnerList.map(partner =>
                selectedIds.push(partner.partnerId)
              );
            }

            this.setState({
              isReferralPartnerLoading: !isReferralPartner,
              referralPartnerList: referralPartnerList
                ? referralPartnerList
                : "",
              isPartnerLoading: false
            });
          });
      }
    );
  }

  _handleShortListedPartners(e) {
    const newSelection = parseInt(e.target.value, 10);
    let newSelectionArray;

    if (this.state.checkBox.shortListedPartners.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.shortListedPartners.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.shortListedPartners,
        parseInt(newSelection, 10)
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        shortListedPartners: newSelectionArray
      }
    }));
  }

  // Delete the project waiting list user
  deleteReferralWaitingPartner(id) {
    return apiClient
      .delete(`${endpoints().referralPartnerAPI}/${id}`)
      .then(() => {
        this._getSourceMorePartnersPendingList(
          true,
          sourceMorePartnersPendingListStatuses.join(", ")
        );
        // this.props.handleProjectDetails(this.props.projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    const {
      partnerIds,
      isPartnerModal,
      isPartnerLoading,
      referralPartnerList,
      filteredPartnerList,
      currentPartner
    } = this.state;

    const { referralId, referralDetails } = this.props;

    const selectedPartnerInterestIds = this.state.checkBox.shortListedPartners;
    let assignedPartnerList = [
      ...(referralPartnerList ? referralPartnerList : [])
    ];

    if (referralDetails && referralDetails.partnerDetails) {
      assignedPartnerList.push(referralDetails.partnerDetails);
    }

    if (
      isPartner(this.props.roleId) &&
      this.props.referralStatus &&
      this.props.referralStatus === DEAL_STATUS_DRAFT &&
      this.props.currentUserId
    ) {
      assignedPartnerList.push(currentPartner);
    }

    if (
      !isPartner(this.props.roleId) &&
      this.props.referralStatus &&
      this.props.referralStatus === DEAL_STATUS_DRAFT &&
      this.props.referralPartner
    ) {
      assignedPartnerList.push(this.props.referralPartner);
    }

    return (
      <>
        <PartnerSection
          currentUserId={this.props.currentUserId}
          referralPartnerList={assignedPartnerList}
          onSourcePartner={() => {
            this._getPartnersList(referralId);
            this.toggle();
          }}
          isPartnerLoading={isPartnerLoading}
          referralStatus={this.props.referralStatus}
        />
        {/*Partner modal*/}
        <PartnerSelectionModal
          isPartnerModal={isPartnerModal}
          isLoading={isPartnerLoading}
          toggle={this.toggle}
          partnerList={filteredPartnerList}
          selectedPartnerList={assignedPartnerList}
          handlePartners={this.handlePartners}
          partnerIds={partnerIds}
          createReferralPartner={e => this.createReferralPartner(e)}
          updatePartnerList={this.updatePartnerList}
        />
        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) &&
          this.props.enablePartners && (
            <>
              <SidebarItem>
                <p className="font-weight-bold mb-1">
                  Shortlist of Sourced Partners{" "}
                </p>{" "}
                {/*had to move this here because of design and global css restrictions*/}
                <span className="sidebar-item-description d-block h7 text-grayed mb-3">
                  Once your shortlist is approved by Admin, you’ll select one
                  primary Partner to share with the Client.
                </span>
                <span
                  onClick={() => {
                    this._getPartnersList(referralId);
                    this.toggle();
                  }}
                  className="source-more h6-5 font-weight-bold mb-4 d-block cursor-pointer"
                >
                  {`Source ${
                    partnerIds && partnerIds.length > 0 ? "More " : ""
                  }Partners`}
                </span>
              </SidebarItem>
              {referralPartnerList.length > 0 && (
                <SidebarItem>
                  <SourceMoreWaitingPartners
                    referralPartnerList={[...referralPartnerList]}
                    selectedOptions={selectedPartnerInterestIds}
                    handleShortListedPartners={this._handleShortListedPartners}
                    deleteReferralPartner={e =>
                      this.deleteReferralWaitingPartner(e)
                    }
                  />
                  <div className="btn-wrapper mt-4">
                    <button
                      className={
                        selectedPartnerInterestIds &&
                        selectedPartnerInterestIds.length > 0
                          ? `btn btn-primary w-100`
                          : `btn btn-outline-secondary w-100`
                      }
                      disabled={
                        selectedPartnerInterestIds &&
                        !selectedPartnerInterestIds.length
                      }
                      // onClick={() => this.sendVerifyExpertAvailability()}
                    >
                      Verify Partner Availability
                    </button>
                  </div>
                </SidebarItem>
              )}
            </>
          )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(SourcePartners);
