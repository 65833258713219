import React from "react";
import { Nav, NavItem } from "reactstrap";
import { HelpIcon } from "../../assets/img/icons";
import { isExpert } from "../../lib/helper";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const UtilityList = props => {
  const { headerTextColor, id } = props;
  const currentUser = useSelector(state => state.user);
  return (
    <>
      <Nav className="utility-list align-items-center">
        {/*<NavItem>*/}
        {/*  <NavLink href="#">*/}
        {/*    <SearchIcon />*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
        {/*<NavItem>*/}
        {/*  <NavLink href="#">*/}
        {/*    <FavoritesIcon />*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
        <NavItem>
          <Link
            id={id}
            to="/help"
            className={`nav-link ${
              isExpert(currentUser && currentUser.roleId) && !props.enable
                ? "disabled"
                : ""
            }`}
          >
            <HelpIcon headerTextColor={headerTextColor} />
          </Link>
        </NavItem>
        {/*<NavItem>*/}
        {/*  <NavLink href="#">*/}
        {/*    <NotificationIcon />*/}
        {/*  </NavLink>*/}
        {/*</NavItem>*/}
      </Nav>
    </>
  );
};

export default UtilityList;
