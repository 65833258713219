import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DatePicker from "../../components/base/Date";
import Form from "../../components/base/Form";
import SaveButton from "../../components/base/SaveButton";
import CancelButton from "../../components/base/CancelButton";

const SnoozeModal = props => {
  const { modalOpen, toggle, statusUpdate, crossbeamAccountId } = props;
  const [setModal, setSnoozeModal] = useState(modalOpen);
  const [setDateTime, setDateandTime] = useState(null);
  const [showRequired, setShowRequired] = useState(false);

  function handleDate(date) {
    setDateandTime(date);
    setShowRequired(false);
  }
  function statusUpdation(e) {
    e.preventDefault();
    if (setDateTime != null) {
      statusUpdate(crossbeamAccountId, setDateTime);
      toggle();
    } else {
      setShowRequired(true);
    }
  }

  return (
    <>
      <Modal isOpen={setModal} toggle={toggle} backdrop="static">
        <Form
          initialValues={{ datepicker: "" }}
          onSubmit={values => {
            this.statusUpdation(values);
          }}
        >
          <ModalHeader toggle={toggle}>
            <h4 className="font-weight-bold">Snooze Until</h4>
          </ModalHeader>
          <ModalBody className="mb-4">
            <div className="mt-3">
              <label>
                Snooze Date<span className="text-danger">*</span>
              </label>
              <DatePicker
                name="datepicker"
                id="datepicker"
                clearIcon={null}
                onChange={date => handleDate(date)}
                placeholder="Select Snooze Date"
                minDate={new Date()}
                selected={setDateTime}
                required={true}
                width={100}
              />
              {showRequired && (
                <span
                  className="text-danger"
                  style={{
                    position: "absolute",
                    display: "block",
                    marginBottom: "10px",
                    fontSize: 13
                  }}
                >
                  Date is required
                </span>
              )}
            </div>
          </ModalBody>
          <div>
            <ModalFooter className={["justify-content-center"].join(" ")}>
              <div className="btn-wrapper">
                <CancelButton onClick={toggle} />
                <SaveButton
                  onClick={e => statusUpdation(e)}
                  className="btn btn-primary"
                >
                  Save
                </SaveButton>
              </div>
            </ModalFooter>
          </div>
        </Form>
      </Modal>
    </>
  );
};

export default SnoozeModal;
