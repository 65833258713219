import React from "react";

// Components
import DefaultContent from "../../../../components/content/DefaultContent";
import Form from "../../../../components/base/Form";
import Radio from "../../../../components/base/Radio";

// Constants
import { SETTINGS_ENABLE_SCHEDULE_PAYMENT } from "../../../../setting/Constants";
import {
  CREATE_BILL_NOT_SCHEDULE_PAYMENT,
  CREATE_BILL_AND_SCHEDULE_PAYMENT
} from "../../../../invoice/Constants";

const OutgoingPaymentsTab = props => {
  const { adminSettings, saveSettings, history } = props;

  // Handle Enable Schedule Payment
  const handleEnableSchedulePayment = enable_schedule_payment => {
    const data = new FormData();

    if (enable_schedule_payment !== undefined) {
      data.append(
        "enable_schedule_payment",
        enable_schedule_payment === CREATE_BILL_AND_SCHEDULE_PAYMENT
          ? "true"
          : "false"
      );
    }

    // Save settings
    saveSettings(data);
  };

  // Saved enable Schedule Payment config value
  const enableSchedulePayment =
    adminSettings && adminSettings.enable_schedule_payment;

  return (
    <DefaultContent>
      <Form>
        <div className="form-wrapper">
          <h6 className="font-weight-bold mb-3">
            Workflow Configuration - Outgoing Payments
          </h6>
          <div className="px-4 w-100 mb-5">
            <Radio
              name={SETTINGS_ENABLE_SCHEDULE_PAYMENT}
              options={[
                CREATE_BILL_NOT_SCHEDULE_PAYMENT,
                CREATE_BILL_AND_SCHEDULE_PAYMENT
              ]}
              size="large"
              onChange={e => {
                handleEnableSchedulePayment(e.target.value);
              }}
              defaultValue={
                enableSchedulePayment && enableSchedulePayment === "true"
                  ? CREATE_BILL_AND_SCHEDULE_PAYMENT
                  : enableSchedulePayment === "false"
                  ? CREATE_BILL_NOT_SCHEDULE_PAYMENT
                  : ""
              }
            />
          </div>
        </div>
      </Form>
    </DefaultContent>
  );
};

export default OutgoingPaymentsTab;
