import React, { useEffect, useRef } from "react";
import { ChevronRight } from "../assets/img/icons";
import { Link } from "react-router-dom/cjs/react-router-dom.min";
import Avatar from "./base/Avatar";

const QuickLinkCard = props => {
  const {
    title,
    description,
    icon,
    link,
    buttonLabel,
    logo,
    url,
    onClick
  } = props;

  return (
    <div>
      {link && (
        <Link
          to={link}
          className="onboarding-link"
          style={{ borderBottom: "1px solid #e2e2e8" }}
          onClick={onClick}
        >
          <div className="onboarding-header">
            <div className="step-wrapper">
              <span className="step-count red-faded completed">{icon}</span>
            </div>
          </div>
          <div className="onboarding-main">
            <h5 className="font-weight-bold">{title}</h5>
            <p className="font-weight-bold text-inline-grayed">{description}</p>
          </div>
          <div className="onboarding-url">
            <ChevronRight />
          </div>
        </Link>
      )}
      {url && (
        <Link
          className="onboarding-link"
          style={{ borderBottom: "1px solid #e2e2e8" }}
        >
          <div className="onboarding-header">
            <div className="step-wrapper">
              <span className="step-count">
                <Avatar url={logo ? logo : ""} size="xl" />
              </span>
            </div>
          </div>
          <div className="onboarding-main">
            <h5 className="font-weight-bold">{title}</h5>
            <p className="font-weight-bold text-inline-grayed">{description}</p>
          </div>
          <div className="onboarding-url pr-4" style={{ maxWidth: "unset" }}>
            <button
              className="btn btn-primary"
              onClick={() => {
                window.open(url);
              }}
            >
              {buttonLabel}
            </button>
          </div>
        </Link>
      )}
    </div>
  );
};

export default QuickLinkCard;
