import React from "react";

import { useDispatch } from "react-redux";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Action

import { fetchList } from "../../../../actions/table";

// Components

import toast from "../../../../components/base/Toast";

import Form from "../../../../components/base/Form";

import Text from "../../../../components/Text";

import Select from "../../../../components/base/Select";

import AddButton from "../../../../components/base/AddButton";

// Configs

import { endpoints } from "../../../../configs";

import { apiClient } from "../../../../apiClient";
import { isBadRequest } from "../../../../common/http";

const NewStatusButton = props => {
  const {
    setIsOpen,
    isOpen,
    id,
    group,
    status,
    salesforce_status,
    setId,
    setGroup,
    setStatus,
    setSalesforceStatus
  } = props;

  const dispatch = useDispatch();

  const groupNames = () => [
    {
      label: "New",
      value: "New"
    },
    {
      label: "Approved",
      value: "Approved"
    },
    {
      label: "Closed",
      value: "Closed"
    }
  ];
  const _referralStatusToggle = data => {
    setId((data && data.id) || "");
    setGroup((data && data.group) || "");
    setStatus((data && data.status) || "");
    setSalesforceStatus((data && data.salesforce_status) || "");
    setIsOpen(!isOpen);
  };

  /**
   * Create tag
   *
   * @param data
   */
  const referralStatusCreate = async data => {
    try {
      const response = await apiClient.post(
        `${endpoints().referralStatusAPI}`,

        data
      );

      if (response && response.data) {
        toast.success(response.data.message);

        // Update referral status list

        dispatch(
          fetchList(
            "referralStatus",

            `${endpoints().referralStatusAPI}`,

            1,

            10
          )
        );

        setTimeout(() => {
          _referralStatusToggle();
        }, 100);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        toast.error(error.response.data.message);
      }
    }
  };

  /**
   * Update status
   *
   * @param data
   * @param id
   */
  const updateReferralStatus = async data => {
    try {
      const response = await apiClient.put(
        `${endpoints().referralStatusAPI}/${id}`,

        data
      );

      if (response && response.data) {
        toast.success(response.data.message);

        // Update referral status list

        dispatch(
          fetchList(
            "referralStatus",

            `${endpoints().referralStatusAPI}`,
            1,
            10
          )
        );

        setTimeout(() => {
          _referralStatusToggle();
        }, 100);
      }
    } catch (error) {
      if (isBadRequest(error)) {
        toast.error(error.response.data.message);
      }
    }
  };

  // Render the status modal
  const referralStatusModal = () => (
    <Modal
      isOpen={isOpen}
      toggle={_referralStatusToggle}
      className="landing-create-popup w-100"
    >
      <ModalHeader
        toggle={_referralStatusToggle}
        cssModule={{ "modal-title": "w-100 text-center" }}
      >
        <p>{id ? "Edit Status" : "Create Status"}</p>
      </ModalHeader>
      <Form
        initialValues={{
          status: status || "",
          group: group
            ? {
                label: group,
                value: group
              }
            : "",
          salesforce_status: salesforce_status || ""
        }}
        onSubmit={values => {
          values.group = (values.group && values.group.value) || "";
          id ? updateReferralStatus(values) : referralStatusCreate(values);
          setTimeout(() => {
            setIsOpen(!isOpen);
          }, 100);
        }}
      >
        <ModalBody className="ml-3 mr-3 mt-2 mb-3">
          <div>
            <Text
              name="status"
              label="Status Name"
              placeholder="Enter Status"
              required={true}
              error=""
            />
          </div>
          <div>
            <Select
              name="group"
              label="Group Name"
              placeholder="Select Group Name"
              options={groupNames()}
              defaultValue={groupNames().find(group => group.value === group)}
              isSearchable={true}
              error=""
              required
            />
          </div>
          <div>
            <Text
              name="salesforce_status"
              label="Salesforce Status Name"
              placeholder="Enter Salesforce Status"
              required={true}
              error=""
            />
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="container-fluid">
            <div className="col-sm-12 text-center">
              <Button type="submit" label="">
                {id ? "Update Status" : "Create Status"}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );

  return (
    <>
      {referralStatusModal()}
      <AddButton
        label="Add New"
        className="pull-right btn btn-secondary"
        onClick={() => {
          _referralStatusToggle();
        }}
      />
    </>
  );
};

export default NewStatusButton;
