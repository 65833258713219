import React from "react";

// Components
import Form from "../../../../../components/base/Form";
import DefaultContent from "../../../../../components/content/DefaultContent";
import Text from "../../../../../components/base/Text";
import SaveButton from "../../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_AUTH0_DOMAIN_URL,
  SETTINGS_AUTH0_CLIENT_ID
} from "../../../../../setting/Constants";

const Auth0Section = props => {
  const { adminSettings, saveSettings } = props;

  const { auth0_domain_url, auth0_client_id } = adminSettings;

  // Auth0 Section Initial Values
  const auth0InitialValues = {
    auth0_domain_url,
    auth0_client_id
  };

  // Auth0 Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Auth0 Domain Url
    if (values.auth0_domain_url !== undefined) {
      data.append(
        "auth0_domain_url",
        values.auth0_domain_url ? values.auth0_domain_url : ""
      );
    }

    // Auth0 Client Id
    if (values.auth0_client_id !== undefined) {
      data.append(
        "auth0_client_id",
        values.auth0_client_id ? values.auth0_client_id : ""
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={auth0InitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          <h5 className="font-weight-bold">Domain</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_AUTH0_DOMAIN_URL}
              placeholder="Enter Domain Url"
            />
          </div>
          <h5 className="font-weight-bold">Client Id</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_AUTH0_CLIENT_ID}
              placeholder="Enter Client ID"
            />
          </div>
          <div className="btn-wrapper">
            <SaveButton />
          </div>
        </div>
      </DefaultContent>
    </Form>
  );
};

export default Auth0Section;
