import React from "react";
import PropTypes from "prop-types";

class OutlineButton extends React.Component {
  render() {
    const {
      onClick,
      className,
      name,
      label,
      color,
      borderColor,
      backgroundColor,
      fontSize
    } = this.props;

    return (
      <button
        id={name}
        label={label}
        onClick={onClick}
        type="button"
        className={className}
        style={{
          borderRadius: "7px",
          width: "mx-auto",
          border: "2px solid black",
          fontSize: fontSize ? fontSize : ".950rem",
          backgroundColor: backgroundColor ? backgroundColor : "#FFFF",
          borderColor: borderColor ? borderColor : "#2196F3",
          color: color ? color : "#2196F3"
        }}
      >
        <span>{label}</span>
      </button>
    );
  }
}

OutlineButton.propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
  style: PropTypes.object
};

export default OutlineButton;
