import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import CancelButton from "../../../components/base/CancelButton";
import {
  PROJECT_USER_STATUS_INTERESTED,
  PROJECT_USER_STATUS_NOT_INTERESTED
} from "../../../project/Constants";

const EditInterestStatusModal = ({
  modal,
  setModal,
  expertId,
  updateInterestStatus
}) => {
  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      keyboard={true}
      backdrop="static"
      className={["edit-task-modal", "edit-timeline-modal"].join(" ")}
    >
      <ModalHeader toggle={setModal}>
        <h4 className={["font-weight-bold"].join(" ")}>Edit Interest Status</h4>
      </ModalHeader>
      <ModalBody className={["mb-4"].join(" ")}>
        <p className="text-center">
          Have you informed that this expert is interested or not interested
          through direct communication
        </p>
      </ModalBody>
      <ModalFooter className={["justify-content-center"].join(" ")}>
        <div className="btn-wrapper">
          <CancelButton onClick={setModal} className="mr-2" />
          <button
            type="button"
            label=""
            className="btn btn-danger mr-2"
            onClick={() =>
              updateInterestStatus(expertId, PROJECT_USER_STATUS_NOT_INTERESTED)
            }
          >
            Not Interested
          </button>
          <button
            type="button"
            label=""
            className="btn btn-success"
            onClick={() =>
              updateInterestStatus(expertId, PROJECT_USER_STATUS_INTERESTED)
            }
          >
            Yes, Interested
          </button>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default EditInterestStatusModal;
