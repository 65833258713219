import React from "react";

// Configs
import { endpoints } from "../configs";

// API call
import { apiClient } from "../apiClient";

// Components
import Page404 from "../views/Page404";
import ReduxTable, { ReduxColumn } from "../components/base/ReduxTable";
import Spinner from "../components/base/Spinner";
import toast from "../components/base/Toast";

// Helper
import { isSuperAdmin, isCompanyAdmin } from "../lib/helper";
import { isBadRequest } from "../common/http";
import { connect } from "react-redux";

class Users extends React.Component {
  state = {
    isLoading: false,
    userLists: ""
  };

  componentDidMount() {
    this.salesforceUserLists();
  }

  salesforceUserLists = () => {
    return apiClient
      .get(`${endpoints().salesforceAPI}/userList`)
      .then(response => {
        this.setState({
          userLists: response && response.data
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  render() {
    const { userLists, deleteAppModal, isLoading } = this.state;

    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId)
    ) {
      return <Page404 />;
    }
    const { Pagination, activeSection } = this.props;

    if (isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <div>
          <div className="row mb-2 ">
            <div className="col-6">
              <h5 className="mt-2">Users</h5>
            </div>
          </div>
          <ReduxTable
            id="salesforceUsers"
            apiURL={`${endpoints().salesforceAPI}/userList`}
            paramsToUrl={true}
            history={this.props.history}
          >
            <ReduxColumn field="Id">Salesforce_id</ReduxColumn>
            <ReduxColumn field="FirstName">Name</ReduxColumn>
            <ReduxColumn field="Email">Email</ReduxColumn>
          </ReduxTable>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(Users);
