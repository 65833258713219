import React from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import SubTitle from "../../../../components/base/SubTitle";
import SingleCheckbox from "../../../../components/base/SingleCheckbox";
import CancelButton from "../../../../components/base/CancelButton";
import SaveButton from "../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_ENABLE_EXPERTS,
  SETTINGS_ENABLE_PARTNERS,
  SETTINGS_ENABLE_FILES,
  SETTINGS_ENABLE_RESOURCES,
  SETTINGS_ENABLE_CONTACTS,
  SETTINGS_ENABLE_ACCOUNTS,
  SETTINGS_ENABLE_CUSTOMERS,
  SETTINGS_ENABLE_COMMUNICATIONS,
  SETTINGS_ENABLE_LEADS,
  SETTINGS_ENABLE_FEEDBACK,
  SETTINGS_ENABLE_PARTNER_MANAGER,
  SETTINGS_ENABLE_PARTNER_PAYMENTS,
  SETTINGS_ENABLE_PARTNER_TRAINING,
  SETTINGS_ENABLE_DEALS,
  SETTINGS_ENABLE_REFERRALS,
  SETTINGS_ENABLE_REFERRAL_REQUEST,
  SETTINGS_ENABLE_REFERRAL_PAYMENTS,
  SETTINGS_ENABLE_CONTACT_PARTNER_FORM,
  SETTINGS_ENABLE_PAYMENTS,
  SETTINGS_ENABLE_ACCOUNTING,
  SETTINGS_ENABLE_REPORTS,
  SETTINGS_ENABLE_USERS,
  SETTINGS_ENABLE_ACCOUNT_MAPPING,
  SETTINGS_ALLOW_PARTNER_TO_CREATE_PLAYBOOK,
  SETTINGS_ENABLE_PROJECTS,
  SETTINGS_ENABLE_CAMPAIGNS,
  SETTINGS_ENABLE_PLAYBOOK,
  SETTINGS_ENABLE_PLAYBOOK_INQUIRY,
  SETTINGS_ALLOW_PLAYBOOK_PURCHASE,
  SETTINGS_ENABLE_HELP,
  SETTINGS_ENABLE_SALESFORCE_LOGIN,
  SETTINGS_ENABLE_OFFICE_365_LOGIN,
  SETTINGS_ENABLE_GOOGLE_LOGIN,
  SETTINGS_ENABLE_LINKEDIN_LOGIN,
  SETTINGS_ENABLE_OKTA_LOGIN,
  SETTINGS_ENABLE_AUTH0_LOGIN,
  SETTINGS_DASHBOARD_SHOW_ACCOUNT_MAPPING,
  SETTINGS_ENABLE_MDF_REQUEST,
  SETTINGS_ENABLE_APP,
  SETTINGS_ENABLE_CLUTCH_REVIEW,
  SETTINGS_ENABLE_PARTNER_PROGRAMS,
  SETTINGS_ENABLE_REFERRAL_ADDITIONAL_CONTACTS,
  SHOW_BECOME_A_PARTNER_OPTION_IN_LOGIN,
  SETTINGS_ENABLE_COSELL,
  ENABLE_2FA_ON_LOGIN,
  SETTINGS_ENABLE_MARKETPLACE
} from "../../../../setting/Constants";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

const LicensesAndFeatures = props => {
  const { adminSettings, saveSettings, history } = props;

  const {
    allow_partner_to_create_playbook,
    enable_partners,
    enable_files,
    enable_resources,
    enable_contacts,
    enable_customers,
    enable_leads,
    enable_accounts,
    enable_communications,
    enable_projects,
    enable_campaigns,
    enable_experts,
    enable_playbook,
    enable_feedback,
    enable_partner_training,
    enable_marketplace,
    enable_playbook_inquiry,
    allow_playbook_purchase,
    enable_deals,
    enable_help,
    enable_payments,
    enable_account_mapping,
    enable_accounting,
    enable_users,
    enable_app,
    enable_reports,
    enable_referrals,
    enable_referral_payments,
    enable_referral_additional_contacts,
    enable_partner_payments,
    enable_partner_manager,
    enable_custom_contact_form,
    enable_salesforce_login,
    enable_office_365_login,
    enable_google_login,
    enable_linkedin_login,
    enable_okta_login,
    enable_auth0_login,
    dashboard_show_account_mapping,
    enable_mdf_request,
    enable_salesforce_account_note_update,
    enable_clutch_review,
    enable_partner_programs,
    enable_hubspot,
    enable_referral_request,
    show_become_a_partner_option_in_login,
    enable_co_sell,
    enable_2fa_on_login
  } = adminSettings;
  // System Settings Features Section Initial Values
  const systemSettingsFeaturesSectionInitialValues = {
    // To convert string to boolean 'allow_partner_to_create_playbook === "true"'
    allow_partner_to_create_playbook:
      allow_partner_to_create_playbook === "true" ? "true" : "",
    enable_partners: enable_partners === "true" ? "true" : "",
    enable_files: enable_files === "true" ? "true" : "",
    enable_resources: enable_resources === "true" ? "true" : "",
    enable_contacts: enable_contacts === "true" ? "true" : "",
    enable_customers: enable_customers === "true" ? "true" : "",
    enable_leads: enable_leads === "true" ? "true" : "",
    enable_communications: enable_communications === "true" ? "true" : "",
    enable_accounts: enable_accounts === "true" ? "true" : "",
    enable_projects: enable_projects === "true" ? "true" : "",
    enable_campaigns: enable_campaigns === "true" ? "true" : "",
    enable_experts: enable_experts === "true" ? "true" : "",
    enable_playbook: enable_playbook === "true" ? "true" : "",
    enable_partner_training: enable_partner_training === "true" ? "true" : "",
    enable_marketplace: enable_marketplace === "true" ? "true" : "",
    enable_feedback: enable_feedback === "true" ? "true" : "",
    enable_playbook_inquiry: enable_playbook_inquiry === "true" ? "true" : "",
    allow_playbook_purchase: allow_playbook_purchase === "true" ? "true" : "",
    enable_deals: enable_deals === "true" ? "true" : "",
    enable_help: enable_help === "true" ? "true" : "",
    enable_referrals: enable_referrals === "true" ? "true" : "",
    enable_referral_payments: enable_referral_payments === "true" ? "true" : "",
    enable_referral_additional_contacts:
      enable_referral_additional_contacts === "true" ? "true" : "",
    enable_partner_payments: enable_partner_payments === "true" ? "true" : "",
    enable_payments: enable_payments === "true" ? "true" : "",
    enable_account_mapping: enable_account_mapping === "true" ? "true" : "",
    enable_accounting: enable_accounting === "true" ? "true" : "",
    enable_users: enable_users === "true" ? "true" : "",
    enable_app: enable_app === "true" ? "true" : "",
    enable_reports: enable_reports === "true" ? "true" : "",
    enable_partner_manager: enable_partner_manager === "true" ? "true" : "",
    enable_custom_contact_form:
      enable_custom_contact_form === "true" ? "true" : "",
    enable_salesforce_login: enable_salesforce_login === "true" ? "true" : "",
    enable_office_365_login: enable_office_365_login === "true" ? "true" : "",
    enable_google_login: enable_google_login === "true" ? "true" : "",
    enable_linkedin_login: enable_linkedin_login === "true" ? "true" : "",
    enable_okta_login: enable_okta_login === "true" ? "true" : "",
    enable_auth0_login: enable_auth0_login === "true" ? "true" : "",
    dashboard_show_account_mapping:
      dashboard_show_account_mapping === "true" ? "true" : "",
    enable_mdf_request: enable_mdf_request === "true" ? "true" : "",
    enable_salesforce_account_note_update:
      enable_salesforce_account_note_update === "true" ? "true" : "",
    enable_clutch_review: enable_clutch_review === "true" ? "true" : "",
    enable_partner_programs: enable_partner_programs === "true" ? "true" : "",
    enable_hubspot: enable_hubspot === "true" ? "true" : "",
    enable_referral_request: enable_referral_request === "true" ? "true" : "",
    show_become_a_partner_option_in_login:
      show_become_a_partner_option_in_login === "true" ? "true" : "",
    enable_co_sell: enable_co_sell === "true" ? "true" : "",
    enable_2fa_on_login: enable_2fa_on_login === "true" ? "true" : ""
  };

  // System Settings Features Section Submit Form
  const submit = values => {
    const data = new FormData();

    // enable settings form values
    if (values.allow_partner_to_create_playbook !== (undefined || "")) {
      data.append(
        "allow_partner_to_create_playbook",
        values.allow_partner_to_create_playbook
      );
    }

    if (values.enable_partners !== (undefined || "")) {
      data.append("enable_partners", values.enable_partners);
    }
    if (values.enable_files !== (undefined || "")) {
      data.append("enable_files", values.enable_files);
    }
    if (values.enable_resources !== (undefined || "")) {
      data.append("enable_resources", values.enable_resources);
    }
    if (values.show_become_a_partner_option_in_login !== (undefined || "")) {
      data.append(
        "show_become_a_partner_option_in_login",
        values.show_become_a_partner_option_in_login
      );
    }
    if (values.enable_contacts !== (undefined || "")) {
      data.append("enable_contacts", values.enable_contacts);
    }
    if (values.enable_customers !== (undefined || "")) {
      data.append("enable_customers", values.enable_customers);
    }
    if (values.enable_leads !== (undefined || "")) {
      data.append("enable_leads", values.enable_leads);
    }
    if (values.enable_communications !== (undefined || "")) {
      data.append("enable_communications", values.enable_communications);
    }
    if (values.enable_accounts !== (undefined || "")) {
      data.append("enable_accounts", values.enable_accounts);
    }
    if (values.enable_projects !== (undefined || "")) {
      data.append("enable_projects", values.enable_projects);
    }

    if (values.enable_campaigns !== (undefined || "")) {
      data.append("enable_campaigns", values.enable_campaigns);
    }

    if (values.enable_partner_training !== (undefined || "")) {
      data.append("enable_partner_training", values.enable_partner_training);
    }
    if (values.enable_marketplace !== (undefined || "")) {
      data.append("enable_marketplace", values.enable_marketplace);
    }
    if (values.enable_feedback !== (undefined || "")) {
      data.append("enable_feedback", values.enable_feedback);
    }

    if (values.enable_experts !== (undefined || "")) {
      data.append("enable_experts", values.enable_experts);
    }

    if (values.enable_deals !== (undefined || "")) {
      data.append("enable_deals", values.enable_deals);
    }
    if (values.enable_2fa_on_login !== (undefined || "")) {
      data.append("enable_2fa_on_login", values.enable_2fa_on_login);
    }
    if (values.enable_playbook !== (undefined || "")) {
      data.append("enable_playbook", values.enable_playbook);
    }

    if (values.enable_playbook_inquiry !== (undefined || "")) {
      data.append("enable_playbook_inquiry", values.enable_playbook_inquiry);
    }

    if (values.allow_playbook_purchase !== (undefined || "")) {
      data.append("allow_playbook_purchase", values.allow_playbook_purchase);
    }

    if (values.enable_help !== (undefined || "")) {
      data.append("enable_help", values.enable_help);
    }

    if (values.enable_referrals !== (undefined || "")) {
      data.append("enable_referrals", values.enable_referrals);
    }

    if (values.enable_partner_manager !== (undefined || "")) {
      data.append("enable_partner_manager", values.enable_partner_manager);
    }

    if (values.enable_referral_additional_contacts !== (undefined || "")) {
      data.append(
        "enable_referral_additional_contacts",
        values.enable_referral_additional_contacts
      );
    }

    if (values.enable_partner_payments !== (undefined || "")) {
      data.append("enable_partner_payments", values.enable_partner_payments);
    }
    if (values.enable_payments !== (undefined || "")) {
      data.append("enable_payments", values.enable_payments);
    }
    if (values.enable_account_mapping !== (undefined || "")) {
      data.append("enable_account_mapping", values.enable_account_mapping);
    }
    if (values.enable_accounting !== (undefined || "")) {
      data.append("enable_accounting", values.enable_accounting);
    }
    if (values.enable_users !== (undefined || "")) {
      data.append("enable_users", values.enable_users);
    }
    if (values.enable_app !== (undefined || "")) {
      data.append("enable_app", values.enable_app);
    }
    if (values.enable_reports !== (undefined || "")) {
      data.append("enable_reports", values.enable_reports);
    }
    if (values.enable_salesforce_login !== (undefined || "")) {
      data.append("enable_salesforce_login", values.enable_salesforce_login);
    }

    if (values.enable_office_365_login !== (undefined || "")) {
      data.append("enable_office_365_login", values.enable_office_365_login);
    }

    if (values.enable_google_login !== (undefined || "")) {
      data.append("enable_google_login", values.enable_google_login);
    }

    if (values.enable_linkedin_login !== (undefined || "")) {
      data.append("enable_linkedin_login", values.enable_linkedin_login);
    }

    if (values.enable_okta_login !== (undefined || "")) {
      data.append("enable_okta_login", values.enable_okta_login);
    }

    if (values.enable_auth0_login !== (undefined || "")) {
      data.append("enable_auth0_login", values.enable_auth0_login);
    }

    if (values.enable_custom_contact_form !== (undefined || "")) {
      data.append(
        "enable_custom_contact_form",
        values.enable_custom_contact_form
      );
    }

    if (values.enable_referral_payments !== (undefined || "")) {
      data.append("enable_referral_payments", values.enable_referral_payments);
    }

    if (values.dashboard_show_account_mapping !== (undefined || "")) {
      data.append(
        "dashboard_show_account_mapping",
        values.dashboard_show_account_mapping
      );
    }
    if (values.enable_mdf_request !== (undefined || "")) {
      data.append("enable_mdf_request", values.enable_mdf_request);
    }

    if (values.enable_salesforce_account_note_update !== (undefined || "")) {
      data.append(
        "enable_salesforce_account_note_update",
        values.enable_salesforce_account_note_update
      );
    }

    if (values.enable_clutch_review !== (undefined || "")) {
      data.append("enable_clutch_review", values.enable_clutch_review);
    }

    if (values.enable_partner_programs !== (undefined || "")) {
      data.append("enable_partner_programs", values.enable_partner_programs);
    }

    if (values.enable_hubspot !== (undefined || "")) {
      data.append("enable_hubspot", values.enable_hubspot);
    }

    if (values.enable_referral_request !== (undefined || "")) {
      data.append("enable_referral_request", values.enable_referral_request);
    }
    if (values.enable_co_sell !== (undefined || "")) {
      data.append("enable_co_sell", values.enable_co_sell);
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={systemSettingsFeaturesSectionInitialValues}
      enableReinitialize
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          <SubTitle label="Expert Settings" />
          <div className="form-wrapper">
            {/* Enable Expert */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_EXPERTS}
                label="Enable Experts"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            <SubTitle label="Customer Settings" />
            {/* Enable Customers*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_CUSTOMERS}
                label="Enable Customers"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <SubTitle label="Partner Settings" />
            {/* Enable Communications*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_COMMUNICATIONS}
                label="Enable Communications"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            {/* Enable Partners*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_PARTNERS}
                label="Enable Partners"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_FILES}
                label="Enable Files"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            {/* Enable Resources*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_RESOURCES}
                label="Enable Resources"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            {/* Enable Leads*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_LEADS}
                label="Enable Leads"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/*Enable Campaign */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_CAMPAIGNS}
                label="Enable Campaigns"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable Contacts*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_CONTACTS}
                label="Enable Contacts"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable Partner Manager */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_PARTNER_MANAGER}
                label={`Enable ${Terminology.get(SystemName.PARTNER_MANAGER)}`}
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable Partner Training */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_PARTNER_TRAINING}
                label="Enable Courses"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable Partner Training */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_MARKETPLACE}
                label="Enable MarketPlace"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable Partner Payments */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_PARTNER_PAYMENTS}
                label="Enable Partner Payments"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_DEALS}
                label="Enable Deals"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_REFERRALS}
                label="Enable Referrals"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_REFERRAL_REQUEST}
                label="Enable Referral Request"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Allow Referral Payments*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_REFERRAL_PAYMENTS}
                label="Enable Referral Payments"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Allow Referral Additional Contacts*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_REFERRAL_ADDITIONAL_CONTACTS}
                label="Enable Referrral Additional Contacts"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable Contact Partner Forms */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_CONTACT_PARTNER_FORM}
                label="Enable Contact Partner Forms"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable Payments*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_PAYMENTS}
                label="Enable Payments"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Create New Playbook */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ALLOW_PARTNER_TO_CREATE_PLAYBOOK}
                label="Allow partner to create playbook"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            {/* Enable MDF*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_MDF_REQUEST}
                label="Enable MDF Requests"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable clutch review */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_CLUTCH_REVIEW}
                label="Enable Clutch Review"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable partner programs */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_PARTNER_PROGRAMS}
                label="Enable Partner Programs"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            <div className="field-wrapper">
              <SingleCheckbox
                name={SHOW_BECOME_A_PARTNER_OPTION_IN_LOGIN}
                label="Show Become a Partner Option in Login"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            <SubTitle label="Project Settings" />

            {/*Enable Project */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_PROJECTS}
                label="Enable Projects"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <SubTitle label="Playbook Settings" />

            {/* Enable Playbook*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_PLAYBOOK}
                label="Enable Playbook"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable Playbook Inquiry*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_PLAYBOOK_INQUIRY}
                label="Enable Playbook Inquiry"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Allow Playbook Purchase*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ALLOW_PLAYBOOK_PURCHASE}
                label="Allow Playbook Purchase"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* MarketPlace Settings */}
            <SubTitle label="Marketplace Settings" />

            {/* Enable App */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_APP}
                label="Enable App"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <SubTitle label="Help Settings" />
            {/* Allow Playbook Purchase*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_HELP}
                label="Enable Help"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <SubTitle label="Social Login Settings" />

            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_SALESFORCE_LOGIN}
                label="Enable Salesforce Login"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_OFFICE_365_LOGIN}
                label="Enable Office365 Login"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_GOOGLE_LOGIN}
                label="Enable Google Login"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_LINKEDIN_LOGIN}
                label="Enable LinkedIn Login"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_OKTA_LOGIN}
                label="Enable Okta Login"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_AUTH0_LOGIN}
                label="Enable Auth0 Login"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            <SubTitle label="Other Settings" />
            {/* Enable Accounts*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_ACCOUNTS}
                label="Enable Accounts"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable Accounting */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_ACCOUNTING}
                label="Enable Accounting"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable Account Mapping */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_ACCOUNT_MAPPING}
                label="Enable Account Mapping"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            {/* Enable Reports */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_REPORTS}
                label="Enable Reports"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            {/* Enable CoSell */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_COSELL}
                label="Enable CoSell"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <SubTitle label="System Settings" />

            {/* Enable Feedback*/}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_FEEDBACK}
                label="Enable Feedback"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            <div className="field-wrapper">
              <SingleCheckbox
                name={ENABLE_2FA_ON_LOGIN}
                label="Enable 2FA on Login"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
            {/* Enable Users */}
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_ENABLE_USERS}
                label="Enable Users"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>

            <SubTitle label="Dashboard" />
            <div className="field-wrapper">
              <SingleCheckbox
                name={SETTINGS_DASHBOARD_SHOW_ACCOUNT_MAPPING}
                label="Show Account Mapping"
                className="accepted-terms mt-3 mb-0 pb-0 mr-3"
              />
            </div>
          </div>
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <CancelButton onClick={() => history.goBack()} />
        <SaveButton />
      </div>
    </Form>
  );
};

export default LicensesAndFeatures;
