import React from "react";
import {
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_DECLINED
} from "../referral/Constants";

import { Alert } from "reactstrap";
import Terminology from "../lib/Terminology";
import SystemName from "../Constants/SystemName";

const PartnerAcceptReffalAlert = props => {
  return (
    <div className="alerts-wrapper">
      <Alert
        color="dark-info"
        className={"d-flex align-items-center justify-content-stretch"}
      >
        <p className="mb-0 font-weight-bold mr-auto text-white">
          Would you like to accept this {Terminology.get(SystemName.REFERRAL)}?
        </p>
        <div className="btn-wrapper ml-auto mr-0">
          <button
            id={props.id}
            type="button"
            className="btn btn-danger mr-4 text-white"
            onClick={() =>
              props.updatePartnerReferralStatus(
                REFERRAL_STATUS_DECLINED,
                props.referralDetails.id
              )
            }
          >
            No, I Reject!
          </button>
          <button
            id={props.id}
            type="button"
            className="btn btn-success"
            onClick={() =>
              props.updatePartnerReferralStatus(
                REFERRAL_STATUS_APPROVED,
                props.referralDetails.id
              )
            }
          >
            Yes, I Accept!
          </button>
        </div>
      </Alert>
    </div>
  );
};

export default PartnerAcceptReffalAlert;
