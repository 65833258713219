import React, { useEffect, useState } from "react";
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";
import Spinner from "../../components/base/Spinner";
import { AmazonIcon, GoogleIcon, MicrosoftIcon } from "../../assets/img/icons";
import BreadCrumb from "../../components/base/BreadCrump";
import Toast from "../../components/base/Toast";
import Page404 from "../Page404";
import CloudTable from "./cloudTable";
import IntentTable from "./intentTable";
import Select from "../../components/base/Select";
import { intentTag } from "../../Constants/intentTag";
import Form from "../../components/base/Form";
import PageTitle from "../../components/base/PageTitle";

const Detail = props => {
  const [detail, setDetail] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isPageNotFound, setPageNotfound] = useState(false);
  const [tag, setTag] = useState("");

  let id = props.match.params.id;
  useEffect(() => {
    getDetail();
  }, [id]);

  const getDetail = async data => {
    setIsLoading(true);
    let apiUrl;
    if (data !== undefined && data !== "All") {
      apiUrl = `${endpoints().coSellAPI}/${id}?intentTag=${data}`;
    } else {
      apiUrl = `${endpoints().coSellAPI}/${id}`;
    }
    try {
      await apiClient
        .get(apiUrl)
        .then(response => {
          const results = response.data && response.data.data;
          if (results) {
            setDetail(results);
            setIsLoading(false);
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;

            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
            setIsLoading(false);
            if (errorRequest && errorRequest.status == 404) {
              setPageNotfound(true);
            }
            console.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  if (isLoading) {
    return <Spinner />;
  }

  const breadcrumbList = [
    { label: "Home", link: "/dashboard" },
    {
      label: "Co-Sell",
      link: `/co-sell?page=${1}&sort=${"week1"}&sortDir=${"desc"}`
    },
    { label: "Co-Sell Detail", link: "" }
  ];

  const handleChange = values => {
    let value = values && values.value ? values.value : "All";
    setTag(value);
    getDetail(value);
  };

  let initialValues = {
    intentTag: intentTag.find(value => value.value == tag)
  };
  return (
    <div>
      {isPageNotFound ? (
        <Page404 />
      ) : (
        <div>
          <BreadCrumb list={breadcrumbList} />
          <div className="card card-body ">
            <div>
              <PageTitle
                label={`COMPANY: ${detail.domain}`}
                targetUrl={`/co-sell/new?company_name=${detail.domain}`}
                buttonLabel="New Co-Sell Opportunity"
                fontLight
              />
            </div>
            <div className="mt-3">TECHNOLOGY USAGE</div>
            <div className="col-3 mt-2 p-0 d-flex">
              {detail.amazon !== "" && (
                <div className="d-flex align-items-center">
                  <AmazonIcon />
                  <div
                    className="mx-1 d-flex justify-content-center rounded-circle border border-dark  pr-2 pl-2 "
                    style={{ width: "27px" }}
                  >
                    {detail.amazon}{" "}
                  </div>
                </div>
              )}
              {detail.google !== "" && (
                <div className="d-flex mx-2 justify-content-center align-items-center">
                  <GoogleIcon />
                  <div
                    className="mx-1 d-flex justify-content-center rounded-circle border border-dark  pr-2 pl-2"
                    style={{ width: "27px" }}
                  >
                    {detail.google}{" "}
                  </div>
                </div>
              )}
              {detail.microsoft !== "" && (
                <div className="d-flex  justify-content-center align-items-center">
                  <MicrosoftIcon />
                  <div
                    className=" d-flex justify-content-center rounded-circle border border-dark  pr-2 pl-2"
                    style={{ width: "27px" }}
                  >
                    {detail.microsoft}
                  </div>
                </div>
              )}
            </div>

            <div
              className="container-fluid site-maincontent p-0"
              style={{ overflowX: "auto" }}
            >
              <IntentTable detail={detail} />
            </div>
            <div className=" mt-4">
              <CloudTable detail={detail} />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
export default Detail;
