import React from "react";
import { Badge } from "reactstrap";

// Inner Components
import SidebarItem from "./SidebarItem";

// Helper
import {
  isCustomer,
  isExpert,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../../lib/helper";

// Constants
import { PLAYBOOK_PRICING_TYPE_SUBSCRIPTION } from "../../../../playbook/Constants";

// Get Pricing Schedule Label
import { getPricingScheduleValue } from "../../../playbook/inner_components/getPricingScheduleValue";

import { useSelector } from "react-redux";

const SidebarCustomerAndAdminInvoicing = React.memo(
  function SidebarCustomerAndAdminInvoicing({
    projectId,
    projectName,
    estimatedBudget,
    customerId,
    sendInvoiceClientReminder,
    paymentType,
    billingType
  }) {
    const pricingScheduleLabel = getPricingScheduleValue(paymentType);

    const currentUser = useSelector(state => state.user);

    return (
      <SidebarItem>
        <p className="font-weight-bold mb-1">Project Invoicing</p>{" "}
        {/*had to move this here because of design and global css restrictions*/}
        <Badge
          color="warning"
          className="text-white h7 font-weight-bold text-uppercase mb-2"
        >
          Pending
        </Badge>
        <p>
          {`Initial 5 Hours - ${projectName}`}
          <span className="d-block text-inline-grayed">Due: Feb. 1, 2020</span>
          <span className="text-success d-block">
            $
            {PLAYBOOK_PRICING_TYPE_SUBSCRIPTION === billingType
              ? `${estimatedBudget} ${pricingScheduleLabel}`
              : estimatedBudget}
          </span>
        </p>
        {(isSuperAdmin(currentUser && currentUser.roleId) ||
          isCompanyAdmin(currentUser && currentUser.roleId) ||
          isExpert(currentUser && currentUser.roleId) ||
          isCompanyManager(currentUser && currentUser.roleId)) && (
          <div className="btn-wrapper mt-4">
            <button
              className="btn btn-primary w-100"
              onClick={() =>
                sendInvoiceClientReminder(
                  projectId,
                  customerId,
                  projectName,
                  estimatedBudget
                )
              }
            >
              Send Client Reminder
            </button>
          </div>
        )}
        {isCustomer(currentUser && currentUser.roleId) && (
          <div className="btn-wrapper mt-4">
            <button className="btn btn-primary w-100">Pay Invoice</button>
            <button className="btn btn-outline-dark w-100 mt-2">
              Print Order Details
            </button>
          </div>
        )}
      </SidebarItem>
    );
  }
);

export default SidebarCustomerAndAdminInvoicing;
