import React from "react";

// Components
import { SETTINGS_SHOW_REGION } from "../../../../../setting/Constants";
import Form from "../../../../../components/base/Form";
import DefaultContent from "../../../../../components/content/DefaultContent";
import SaveButton from "../../../../../components/base/SaveButton";
import SingleCheckbox from "../../../inner-component/Checkbox";

const Setting = props => {
  const { adminSettings, saveSettings } = props;

  const { show_region } = adminSettings;

  // General Settings General Section Initial Values
  const generalSettingsGeneralSectionInitialValues = {
    show_region
  };

  // General Settings General Section Submit Form
  const submit = values => {
    const data = new FormData();

    data.append("show_region", values.show_region ? values.show_region : "");
    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={generalSettingsGeneralSectionInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          {/* Landing page URL */}
          <div className="field-wrapper mb-3">
            <SingleCheckbox
              name={SETTINGS_SHOW_REGION}
              label="Show Region"
              className="accepted-terms mb-0 pb-0 mr-3"
            />
          </div>
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default Setting;
