import { Field } from "formik";
import React from "react";

//Base Components
import Form from "../../../../components/base/Form";
import ToggleSwitch from "../../../../components/base/ToggleSwitch";

//Constant
import {
  SETTINGS_PARTNER_REFERRAL_APPROVAL_EMAIL_NOTIFICATION,
  SETTING_PARTNER_INVITE_EMAIL_NOTIFICATION,
  SETTINGS_SALESFORCE_UPDATE_EMAIL_NOTIFICATION,
  SETTING_ENABLE_PARTNER_TERMS_AND_CONDITIONS_UPDATE_NOTIFICATION
} from "../../../../setting/Constants";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

const PartnerSection = props => {
  const { adminSettings, saveSettings } = props;
  const {
    enable_partner_invite_email_notification,
    enable_partner_referral_approval_email_notification,
    enable_salesforce_update_email_notification,
    enable_partners,
    enable_partner_terms_and_conditions_update_notification
  } = adminSettings;

  // Notification Initial Values
  const partnerNotificationInitialValues = {
    enable_partner_invite_email_notification:
      enable_partner_invite_email_notification === "true",
    enable_partner_referral_approval_email_notification:
      enable_partner_referral_approval_email_notification === "true",
    enable_salesforce_update_email_notification:
      enable_salesforce_update_email_notification === "true",
    enable_partner_terms_and_conditions_update_notification:
      enable_partner_terms_and_conditions_update_notification === "true"
  };

  // Handle Enable Invite Notification
  const handleInviteNotification = value => {
    const data = new FormData();

    if (value !== undefined) {
      data.append("enable_partner_invite_email_notification", value);
    }

    // Save settings
    saveSettings(data);
  };
  const handleTermsCondition = value => {
    const data = new FormData();

    if (value !== undefined) {
      data.append(
        "enable_partner_terms_and_conditions_update_notification",
        value
      );
    }

    // Save settings

    saveSettings(data);
  };

  // Handle Enable Referral Notification
  const handleReferralNotification = value => {
    const data = new FormData();

    if (value !== undefined) {
      data.append("enable_partner_referral_approval_email_notification", value);
    }
    // Save settings
    saveSettings(data);
  };

  // Handle Enable Salesforce Update Notification
  const handleSalesforceUpdateNotification = value => {
    const data = new FormData();

    if (value !== undefined) {
      data.append("enable_salesforce_update_email_notification", value);
    }
    // Save settings
    saveSettings(data);
  };
  return (
    <div className="card">
      <Form
        initialValues={partnerNotificationInitialValues}
        onSubmit={values => {
          this.submit(values);
        }}
      >
        {/* Toggle for Invite Email Notification */}
        {enable_partners === "true" && (
          <Field
            name={SETTING_PARTNER_INVITE_EMAIL_NOTIFICATION}
            render={({ field, form }) => {
              return (
                <ToggleSwitch
                  name={SETTING_PARTNER_INVITE_EMAIL_NOTIFICATION}
                  label={"Invite Email Notification"}
                  togglePosition={"right"}
                  togglePadding="p-3"
                  value={field.value}
                  fontBolded
                  handleChange={() => {
                    handleInviteNotification(!field.value);
                    form.setFieldValue(
                      SETTING_PARTNER_INVITE_EMAIL_NOTIFICATION,
                      !field.value
                    );
                  }}
                  outlined
                />
              );
            }}
          />
        )}
        {/* Toggle for Referral Apporve Email Notification */}
        {enable_partners === "true" && (
          <Field
            name={SETTINGS_PARTNER_REFERRAL_APPROVAL_EMAIL_NOTIFICATION}
            render={({ field, form }) => {
              return (
                <ToggleSwitch
                  name={SETTINGS_PARTNER_REFERRAL_APPROVAL_EMAIL_NOTIFICATION}
                  label={`${Terminology.get(
                    SystemName.REFERRAL
                  )} Approval Email Notification`}
                  togglePosition={"right"}
                  togglePadding="p-3"
                  value={field.value}
                  fontBolded
                  handleChange={() => {
                    handleReferralNotification(!field.value);
                    form.setFieldValue(
                      SETTINGS_PARTNER_REFERRAL_APPROVAL_EMAIL_NOTIFICATION,
                      !field.value
                    );
                  }}
                  outlined
                />
              );
            }}
          />
        )}
        {/* Toggle Salesforce Update Email Notification */}
        {enable_partners === "true" && (
          <Field
            name={SETTINGS_SALESFORCE_UPDATE_EMAIL_NOTIFICATION}
            render={({ field, form }) => {
              return (
                <ToggleSwitch
                  name={SETTINGS_SALESFORCE_UPDATE_EMAIL_NOTIFICATION}
                  label={"Salesforce Update Email Notification"}
                  togglePosition={"right"}
                  togglePadding="p-3"
                  value={field.value}
                  fontBolded
                  handleChange={() => {
                    handleSalesforceUpdateNotification(!field.value);
                    form.setFieldValue(
                      SETTINGS_SALESFORCE_UPDATE_EMAIL_NOTIFICATION,
                      !field.value
                    );
                  }}
                  outlined
                />
              );
            }}
          />
        )}
        <Field
          name={SETTING_ENABLE_PARTNER_TERMS_AND_CONDITIONS_UPDATE_NOTIFICATION}
          render={({ field, form }) => {
            return (
              <ToggleSwitch
                name={
                  SETTING_ENABLE_PARTNER_TERMS_AND_CONDITIONS_UPDATE_NOTIFICATION
                }
                label={"Terms and Conditions Update Notification"}
                togglePosition={"right"}
                togglePadding="p-3"
                value={field.value}
                fontBolded
                handleChange={() => {
                  handleTermsCondition(!field.value);

                  form.setFieldValue(
                    SETTING_ENABLE_PARTNER_TERMS_AND_CONDITIONS_UPDATE_NOTIFICATION,

                    !field.value
                  );
                }}
                outlined
              />
            );
          }}
        />
      </Form>
    </div>
  );
};
export default PartnerSection;
