/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import toast from "../../../components/base/Toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchList } from "../../../actions/table";

// Assets

// Component
import AddButton from "../../../components/base/AddButton";
import Text from "../../../components/base/Text";
import Form from "../../../components/base/Form";
import Email from "../../../components/base/Email";

// Helper

//API call
import { apiClient } from "../../../apiClient";

// Configs
import { endpoints } from "../../../configs";
import Phone from "../../../components/base/Phone";
import TextArea from "../../../components/base/TextArea";
import {
  LEAD_PARTNER_STATUS_PENDING,
  LEAD_SOURCE_PARTNER_CONTACT,
  LEAD_STATUS_CLOSE,
  LEAD_STATUS_IN_PROGRESS
} from "../../../lead/Constants";
import PageTitle from "../../../components/base/PageTitle";
import { isBadRequest } from "../../../common/http";

// Customer Create API Call
function leadCreate(data, props) {
  return apiClient
    .post(endpoints().lead, data)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      props.actions.fetchList(
        "playbookInquiry",
        `${endpoints().lead}/search?pagination=true`,
        1,
        10,
        { pagination: true, status: LEAD_PARTNER_STATUS_PENDING }
      );
      props.actions.fetchList(
        "leadInProgressList",
        `${endpoints().lead}/search?pagination=true`,
        1,
        10,
        { pagination: true, status: LEAD_STATUS_IN_PROGRESS }
      );
      props.actions.fetchList(
        "leadConvertedList",
        `${endpoints().lead}/search?pagination=true`,
        1,
        10,
        { pagination: true, status: LEAD_STATUS_CLOSE }
      );
      props.actions.fetchList(
        "leadAllList",
        `${endpoints().lead}/search?pagination=true`,
        1,
        10,
        { pagination: true }
      );
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    });
}

const AddLeadModal = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  return (
    <>
      <PageTitle label="Leads" buttonHandler={toggle} buttonLabel="New Lead" />

      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(false);
        }}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <div className="img-wrapper d-flex justify-content-center mb-2 mt-3"></div>
              <p className="text-center font-weight-bold mb-3">
                Create a new Lead
              </p>
              <p className="text-center h6-5 mb-2"></p>
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.content-wrapper */}
        </ModalHeader>

        <Form
          initialValues={{
            name: "",
            email: "",
            phone: "",
            companyName: "",
            notes: ""
          }}
          onSubmit={values => {
            values.source = LEAD_SOURCE_PARTNER_CONTACT;
            leadCreate(values, props);

            toggle();
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div className="field-wrapper">
                <Text
                  name="name"
                  label="Name"
                  placeholder="Enter name"
                  required={true}
                  error=""
                />
              </div>
              <div className="field-wrapper">
                <Email
                  name="email"
                  label="Email Address"
                  placeholder="Enter email address"
                  required={true}
                  error=""
                />
              </div>
              <div className="field-wrapper">
                <Phone
                  name="phone"
                  label="Phone Number"
                  placeholder="Enter phone number"
                  error=""
                  required
                />
              </div>
              <div className="field-wrapper">
                <Text
                  name="companyName"
                  label="Company Name"
                  placeholder="Enter company name"
                  required={true}
                  error=""
                />
              </div>
              <div className="field-wrapper">
                <TextArea
                  name="notes"
                  label="Description of need or project"
                  placeholder="Enter project description"
                  required={true}
                  error="Please enter project description"
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button
                  type="submit"
                  label=""
                  className="h6-5-important"
                  disabled={!modal}
                >
                  Create Lead
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(() => {
  return {};
}, mapDispatchToProps)(AddLeadModal);
