import React from "react";
import ExpertRequestQuestion from "./ExpertRequestQuestion";
// Components
import SecondaryButton from "../../components/base/SecondaryButton";

// Assets
import { WomanIcon } from "../../assets/img/icons";

class Experts extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      expertsOptions: [
        {
          value: "One expert",
          label: "One expert",
          tooltip: ""
        },
        {
          value: "Multiple experts",
          label: "Multiple experts",
          tooltip: ""
        },
        {
          value:
            "A cross-functional team (developers, designers, project managers)",
          label:
            "A cross-functional team (developers, designers, project managers)",
          tooltip: ""
        },
        {
          value: "I want help figuring this out",
          label: "I want help figuring this out",
          tooltip: ""
        },
        {
          value: "I'll decide later",
          label: "I'll decide later",
          tooltip: ""
        }
      ]
    };
  }

  render() {
    const { currentStep, step, prev, next } = this.props;
    const { expertsOptions } = this.state;

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <WomanIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-4">Experts</h2>
        </div>
        <div className="subtitle-wrapper mt-4">
          <h5 className="font-weight-light">How many experts do you need?</h5>
        </div>
        <div className="content">
          <ExpertRequestQuestion
            name="experts"
            options={expertsOptions}
            error="Experts is required"
            required
          />

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={next}
            >
              Next: Location
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Experts;
