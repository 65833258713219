import React, { Component } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import Form from "../../components/base/Form";
import Button from "../../components/base/Button";
import Text from "../../components/base/Text";
import toast from "../../components/base/Toast";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

import { COURSE_DRAFT } from "../../course/Constants";
import { isBadRequest } from "../../common/http";

export default class AddLessonModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      title: "",
      status: COURSE_DRAFT,
      values: []
    };
  }
  _onBasicSubmit = values => {
    this._createCourse(this._toArray(values));
  };
  _createCourse(data) {
    return apiClient
      .post(
        `${endpoints().courseAPI}/lesson/${this.props.match.params.id}`,
        data
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.props.getLessonList();
        toast.success(successMessage);
        this.props.toggle();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isSubmitting: false });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  _toArray(values) {
    const data = new FormData();
    data.append("lessonTitle", values.lessonTitle.trim());
    return data;
  }
  render() {
    const { AddLessonModal, toggle } = this.props;

    const closeEditModal = () => {
      toggle();
    };

    return (
      <Modal
        isOpen={AddLessonModal}
        toggle={closeEditModal}
        backdrop="static"
        className={["edit-task-modal"].join(" ")}
      >
        <Form
          initialValues={{
            lessonTitle: ""
          }}
          onSubmit={values => {
            this._onBasicSubmit(values);
          }}
        >
          <ModalHeader toggle={closeEditModal}>
            <h4 className={["font-weight-bold"].join(" ")}>
              Add Lesson Module
            </h4>
          </ModalHeader>
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="form-wrapper">
              <div>
                <Text
                  id={`lessonTitle`}
                  name="lessonTitle"
                  label="Lesson Title"
                  placeholder="Enter Lesson Title"
                  required={true}
                  error=""
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <Button label="Add" type="submit" className="btn btn-primary" />
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    );
  }
}
