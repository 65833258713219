import React, { Component } from "react";

// Configs
import { endpoints } from "../../../../configs";

// Library
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  isLoggedIn
} from "../../../../lib/helper";

// Components
import PageTitle from "../../../../components/base/PageTitle";
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";

// 404 Page
import Page404 from "../../../Page404";

import { connect } from "react-redux";

class SystemLogsTab extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ],
      isPageNotFound: false
    };
  }
  componentDidMount() {
    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    ) {
      // Check is logged in user
      isLoggedIn();
    } else {
      this.setState({
        isPageNotFound: true
      });
    }
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    // Page Not Found
    if (this.state.isPageNotFound) {
      return <Page404 />;
    }
    return (
      <div>
        <div>
          <ReduxTable
            id="systemLogs"
            apiURL={`${endpoints().systemLogAPI}`}
            newTableHeading
            showHeader
            searchPlaceholder="Search"
            sortByOptions={this.state.sortByOptions}
            paramsToUrl={true}
            history={this.props.history}
          >
            <ReduxColumn width={"120px"} field="createdAt" sortBy="createdAt">
              Date
            </ReduxColumn>
            <ReduxColumn width={"105px"} field="user" sortBy="user">
              User
            </ReduxColumn>
            <ReduxColumn width={"260px"} field="message" sortBy="message">
              Message
            </ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(SystemLogsTab);
