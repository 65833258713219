import React, { useState } from "react";
import { Col, Row } from "reactstrap";
import { Link } from "react-router-dom";

// Inner Components
import ProjectStepWrapper from "./ProjectStepWrapper";
import Steps from "./ProjectSteps";

// Components
import Avatar from "../../../components/base/Avatar";
import PlaybookCard from "../../../components/PlaybookCard";
import Pagination from "../../../components/base/Pagination";

// Assets
import { ActivityIcon, DollarIcon, UserIcon } from "../../../assets/img/icons";

// Helper
import { isExpert } from "../../../lib/helper";

// Constants
import { PLAYBOOK_PRICING_TYPE_SUBSCRIPTION } from "../../../playbook/Constants";

// Get Pricing Schedule Label
import { getPricingScheduleValue } from "../../playbook/inner_components/getPricingScheduleValue";

import { useSelector } from "react-redux";

const ProjectsList = props => {
  const {
    status,
    data,
    pagination,
    activeProjectsList,
    activeProjectsCount,
    projectDetailsUrlBasePath
  } = props;
  const projectDetails = data.data;
  const { totalCount } = data;

  const currentUser = useSelector(state => state.user);

  const totalProjectCount =
    isExpert(currentUser && currentUser.roleId) && activeProjectsList && !status
      ? activeProjectsCount
      : totalCount;
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize] = useState(10);

  // Get current posts
  let startPage = "";
  let endPage = "";

  startPage = (currentPage - 1) * pageSize;
  startPage = startPage > totalProjectCount ? totalProjectCount : startPage;

  endPage = currentPage * pageSize;
  endPage = endPage > totalProjectCount ? totalProjectCount : endPage;

  const currentProjectList = pagination
    ? totalProjectCount > 1
      ? projectDetails.slice(startPage, endPage)
      : projectDetails
    : projectDetails;
  const steps = Steps();

  // Change page
  const onPageChange = pageNumber => setCurrentPage(pageNumber);

  return (
    <div className="project-list">
      {currentProjectList &&
        currentProjectList.map(project => (
          <Link
            to={`/${
              projectDetailsUrlBasePath ? projectDetailsUrlBasePath : "project"
            }/${project.id}`}
            className="project d-flex justify-content-start text-decoration-none"
            key={project.id}
          >
            <PlaybookCard
              data={project}
              isPreview={"false"}
              size={"project-playbook-card"}
              boxShadow={"false"}
              placeHolder={true}
              link={`/project/${project.id}`}
              bannerImageUrl={project.bannerImageUrl}
              notifications={project.projectNotifications}
              tag={"div"}
              fullWidth
            />
            <div className="project-data d-flex flex-column w-100">
              <div className="project-maininfo d-flex justify-content-between">
                <div className="project-finances">
                  <div className="field-wrapper">
                    <p className="font-weight-bold">Client</p>
                    <span className="d-block text-grayed font-weight-bold">
                      {project.customer ? project.customer.displayName : ""}
                    </span>
                  </div>
                  <div className="field-wrapper">
                    <p className="font-weight-bold">Budget</p>
                    {project.estimatedBudget > 0 && (
                      <span className="d-block text-grayed font-weight-bold">
                        Invoiced ${`${project.invoicedAmount}`} of
                        {project.estimatedBudget
                          ? ` $${project.estimatedBudget}`
                          : ""}
                      </span>
                    )}
                  </div>

                  <div className="field-wrapper">
                    <p className="font-weight-bold">Timeline</p>
                    <span className="d-block text-grayed font-weight-bold">
                      {project.estimatedTimeline
                        ? project.estimatedTimeline
                        : ""}
                    </span>
                  </div>
                </div>
                <div className="project-progress">
                  <div className="field-wrapper">
                    <div className="user-wrapper">
                      {project.users &&
                        project.usersCount > 0 &&
                        project.users.slice(0, 4).map((user, index) => (
                          <React.Fragment key={index}>
                            <div
                              className={`field-icon ${project.usersCount > 2 &&
                                "mr-2"}`}
                            >
                              {user &&
                              user.avatarUrl !== null &&
                              user.avatarUrl ? (
                                <Avatar
                                  firstName={user.firstName}
                                  lastName={user.lastName}
                                  size="customSize"
                                  imageSize={"32"}
                                  url={user.avatarUrl}
                                />
                              ) : (
                                <Avatar
                                  firstName={user.firstName}
                                  lastName={user.lastName}
                                  size="customSize"
                                  imageSize={"32"}
                                />
                              )}
                            </div>
                            {project.users && project.users.length < 4 && (
                              <p className="font-weight-bold">{user.name}</p>
                            )}
                          </React.Fragment>
                        ))}
                      {project.users && project.users.length > 3 && (
                        <div className="field-icon rounded-circle">...</div>
                      )}
                      {project.users && !project.users.length && (
                        <>
                          <div className="field-icon">
                            <UserIcon />
                          </div>
                          <p className="font-weight-bold">Sourcing Expert</p>
                        </>
                      )}
                    </div>
                  </div>

                  <div className="field-wrapper">
                    <div className="d-flex align-items-center">
                      <div className="field-icon">
                        <UserIcon />
                      </div>
                      <p className="font-weight-bold">
                        {project.hoursThisMonth || 0}
                        <span className="text-grayed font-weight-bold">
                          Total Hours
                        </span>
                      </p>
                    </div>
                  </div>

                  <div className="field-wrapper">
                    <div className="d-flex align-items-center">
                      <div className="field-icon">
                        <DollarIcon />
                      </div>
                      <p className="font-weight-bold">
                        {project.estimatedBudget > 0 ? (
                          <>
                            ${project.estimatedBudget}
                            <span className="text-grayed font-weight-bold">
                              {PLAYBOOK_PRICING_TYPE_SUBSCRIPTION ===
                              project.billingType
                                ? `${getPricingScheduleValue(
                                    project.paymentType
                                  )}`
                                : "Fixed"}
                            </span>
                          </>
                        ) : (
                          <>
                            {project.experts ? project.experts.hourlyRate : ""}
                            <span className="text-grayed font-weight-bold">
                              {project.experts ? "per hour" : ""}
                            </span>
                          </>
                        )}
                      </p>
                    </div>
                  </div>

                  <div className="field-wrapper">
                    <div className="d-flex align-items-center">
                      <div className="field-icon">
                        <ActivityIcon />
                      </div>

                      {/* Show Assignment Pending When Expert Is Not Assigned */}
                      {!project.expertId ? (
                        <div className="d-flex flex-column">
                          <p className="font-weight-bold">Assignment Pending</p>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Show No Activity When No Discussion */}
                      {project.expertId && !project.assignmentPending ? (
                        <div className="d-flex flex-column">
                          <p className="font-weight-bold">No Activity</p>
                        </div>
                      ) : (
                        ""
                      )}

                      {/* Show Last Activity When Discussion */}
                      {project.expertId && project.assignmentPending ? (
                        <div className="d-flex flex-column">
                          <p className="font-weight-bold">Last Activity</p>
                          <span className="d-block text-grayed font-weight-bold">
                            {project.assignmentPending}
                          </span>
                        </div>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <div className="project-timeline">
                <ProjectStepWrapper
                  steps={steps}
                  projectStatusHistory={project.statusHistory}
                  projectStatus={project.status}
                />
              </div>
            </div>
          </Link>
        ))}

      {/* Show Pagination */}
      {totalProjectCount > 0 && pagination && (
        <Row>
          <Col>
            Showing {startPage + 1} to {endPage} of {totalProjectCount} entries
          </Col>
          <Col>
            <Pagination
              currentPage={currentPage}
              totalCount={totalProjectCount}
              pageSize={pageSize}
              onPageChange={onPageChange.bind(this)}
            />
          </Col>
        </Row>
      )}
    </div>
  );
};

export default ProjectsList;
