import React from "react";
import PartnerCustomField from "../../../components/editPartnerProfile/PartnerCustomField";

// Components
import Form from "../../../components/base/Form";

class Applications extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      partnerApplicationTaskList,
      uploadTaskFile,
      deleteUploadedTaskFile,
      updateCustomField,
      updateCustomTags
    } = this.props;

    return (
      <>
        <Form>
          {partnerApplicationTaskList &&
            partnerApplicationTaskList.length > 0 &&
            partnerApplicationTaskList.map(customfField => (
              <PartnerCustomField
                customfField={customfField}
                uploadTaskFile={uploadTaskFile}
                deleteUploadedTaskFile={deleteUploadedTaskFile}
                updateCustomField={updateCustomField}
                updateCustomTags={updateCustomTags}
              />
            ))}
        </Form>
      </>
    );
  }
}

export default Applications;
