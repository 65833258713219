import React, { useEffect, useState } from "react";
import PartnerReffrelReportService from "../../../../services/PartnerReffrelReportService";
import Form from "../../../../components/base/Form";
import DatePicker from "react-datepicker";
import PageTitle from "../../../../components/base/PageTitle";
import { getParamsByName } from "../../../../lib/helper";
import DateTime from "../../../../lib/DateTime";
import Table from "./Table";
import Spinner from "../../../../components/base/Spinner";

const OverAllSummary = props => {
  const [count, setCount] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [startDateValue, setStartDateValue] = useState(
    getParamsByName("startDateValue") ? getParamsByName("startDateValue") : null
  );

  const [endDateValue, setEndDateValue] = useState(
    getParamsByName("endDateValue") ? getParamsByName("endDateValue") : null
  );

  useEffect(() => {
    let params = {
      startDateValue: startDateValue
        ? startDateValue
        : DateTime.getStartOfMonth(),
      endDateValue: endDateValue ? endDateValue : DateTime.getToday()
    };

    getOverallSummary(params);
  }, []);

  const getOverallSummary = async params => {
    setIsLoading(true);
    const data = await PartnerReffrelReportService.search(params);

    setIsLoading(false);
    setCount(data && data.data);
  };
  const updateUrl = param => {
    const params = [];

    if (param.startDateValue) {
      params.push(
        `startDateValue=${param.startDateValue.toLocaleDateString("en-US")}`
      );
    }

    if (param.endDateValue) {
      params.push(
        `endDateValue=${param.endDateValue.toLocaleDateString("en-US")}`
      );
    }

    if (params.length > 0) {
      props.history.push(`/reports/overall-summary?${params.join("&")}`);
    } else {
      props.history.push(`/reports/overall-summary?`);
    }
  };

  const handleStartDateFilter = startDate => {
    setStartDateValue(startDate ? startDate : "");

    let params = {};

    if (startDate) {
      params.startDateValue = startDate;
    }
    if (endDateValue !== null) {
      params.endDateValue = endDateValue;
    }
    if (!endDateValue) {
      params.endDateValue = DateTime.getToday();
    }
    getOverallSummary(params);
    updateUrl(params);
  };

  const handleEndDateFilter = endDate => {
    setEndDateValue(endDate ? endDate : "");
    let params = {};
    if (startDateValue !== null) {
      params.startDateValue = startDateValue;
    }
    if (!startDateValue) {
      params.startDateValue = DateTime.getStartOfMonth();
    }
    if (endDate) {
      params.endDateValue = endDate;
    }
    getOverallSummary(params);
    updateUrl(params);
  };
  let initialValues = {
    startDate:
      startDateValue === ""
        ? startDateValue
        : startDateValue
        ? startDateValue
        : DateTime.getStartOfMonth(),
    endDate:
      endDateValue === ""
        ? endDateValue
        : endDateValue
        ? endDateValue
        : DateTime.getToday()
  };

  if (isLoading) {
    return <Spinner />;
  }
  return (
    <div>
      <PageTitle label="Overall Summary Report" />
      <div className={`page-heading cover`}>
        <Form initialValues={initialValues} enableReinitialize>
          <div className="row">
            <div className="col">
              <DatePicker
                name="startDate"
                id="startDate"
                className="datepicker"
                placeholderText="Start Date"
                onChange={handleStartDateFilter}
                selected={
                  startDateValue
                    ? startDateValue
                    : initialValues.startDate
                    ? initialValues.startDate
                    : null
                }
                isClearable
                clearIcon={null}
              />
            </div>
            <div className="col">
              <DatePicker
                name="endDate"
                id="endDate"
                className="datepicker"
                placeholderText="End Date"
                onChange={handleEndDateFilter}
                selected={
                  endDateValue
                    ? endDateValue
                    : initialValues.endDate
                    ? initialValues.endDate
                    : null
                }
                isClearable
                clearIcon={null}
              />
            </div>
          </div>
        </Form>
      </div>
      <Table
        initialValues={initialValues}
        startDateValue={startDateValue}
        endDateValue={endDateValue}
        data={count}
        isLoading={isLoading}
      />
    </div>
  );
};

export default OverAllSummary;
