import React from "react";
import ExpertRequestQuestion from "./ExpertRequestQuestion";

// Components
import SecondaryButton from "../../components/base/SecondaryButton";

// Assets
import { WorldIcon } from "../../assets/img/icons";

class Location extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      locationOptions: [
        {
          value: "I need local Experts",
          label: "I need local Experts",
          tooltip: ""
        },
        {
          value:
            "I prefer local Experts, but am open to others locations experts",
          label:
            "I prefer local Experts, but am open to others locations experts",
          tooltip: ""
        },
        {
          value: "I don't need local, but i need timezone overlap",
          label: "I don't need local, but i need timezone overlap",
          tooltip: ""
        },
        {
          value: "This is not a requirement",
          label: "This is not a requirement",
          tooltip: ""
        }
      ]
    };
  }

  render() {
    const { currentStep, step, prev, next } = this.props;
    const { locationOptions } = this.state;

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <WorldIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-4">Location</h2>
        </div>
        <div className="subtitle-wrapper mt-4">
          <h5 className="font-weight-light">Do you need a local Expert?</h5>
          <p className="text-grayed">
            Requesting a local expert reduces the number of potential Experts
            available for your project, so the benefit of being open to other
            locations can improve immediacy, and cost
          </p>
        </div>
        <div className="content">
          <ExpertRequestQuestion
            name="location"
            options={locationOptions}
            error="Location is required"
            required
          />

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={next}
            >
              Next: Budget
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default Location;
