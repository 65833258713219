import React from "react";

import { apiClient } from "../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../configs";
import { TAG_TYPE_DEAL_STAGE } from "../tagType/Constants";
import { getCookie } from "../lib/helper";
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

// Components
import Select from "./base/Select";
import { isBadRequest } from "../common/http";

class DealStage extends React.Component {
  state = {
    dealStageTagList: ""
  };

  // Get deal stage tag
  getDealStageTagType = async () => {
    if (!getCookie(COOKIE_SESSION_TOKEN))
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${TAG_TYPE_DEAL_STAGE}`
      );
      const results = response.data.data;

      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });
        this.setState({ dealStageTagList: tagList });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  componentDidMount() {
    this.getDealStageTagType();
  }

  //  Get options
  getDealStageTagTypeOptions = () => {
    const { dealStageTagList } = this.state;
    let dealStageOptions = [];

    if (!dealStageTagList) return dealStageOptions;

    dealStageTagList.forEach(type => {
      dealStageOptions.push({ value: type.id, label: type.label });
    });

    return dealStageOptions;
  };

  render() {
    const {
      name,
      label,
      placeholder,
      error,
      required,
      isSearchable,
      fullWidth,
      id
    } = this.props;

    return (
      <Select
        id={id || name}
        name={name}
        label={label}
        placeholder={placeholder}
        options={this.getDealStageTagTypeOptions()}
        fullWidth={fullWidth}
        isSearchable={isSearchable}
        error={error}
        {...{ required }}
      />
    );
  }
}

export default DealStage;
