import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";
import {
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

// Components
import PageTitle from "../../components/base/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

// 404 Page
import Page404 from "../Page404";

import { connect } from "react-redux";

class Activities extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ],
      isPageNotFound: false
    };
  }
  componentDidMount() {
    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    ) {
      // Check is logged in user
      isLoggedIn();
    } else {
      this.setState({
        isPageNotFound: true
      });
    }
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    // Page Not Found
    if (this.state.isPageNotFound) {
      return <Page404 />;
    }
    return (
      <div>
        <PageTitle label="Activities" />

        <div>
          <ReduxTable
            id="activities"
            apiURL={`${endpoints().activity}`}
            newTableHeading
            showHeader
            searchPlaceholder="Search"
            sortByOptions={this.state.sortByOptions}
          >
            <ReduxColumn field="createdAt">Date</ReduxColumn>
            <ReduxColumn field="userName">User</ReduxColumn>
            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) && (
              <ReduxColumn field="companyName">Partner</ReduxColumn>
            )}
            <ReduxColumn field="activity_type" sortBy="activity_type">
              Activity
            </ReduxColumn>
            <ReduxColumn field="notes" sortBy="notes">
              Notes
            </ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(Activities);
