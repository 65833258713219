import React from "react";

// Assets
import { ArrowRight, LogoIcon } from "../../assets/img/icons";

class GetStarted extends React.Component {
  render() {
    const { currentStep, step, next } = this.props;

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <LogoIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-3">Get Started</h2>
        </div>

        {/* Content */}
        <div className="content h5 text-grayed font-weight-light">
          <p>Find and manage experts for your results-oriented projects.</p>

          <p>
            Tell us about your needs and we will identify a short list of top
            candidates to take your projects to the finish line.
          </p>
        </div>
        <div className="btn-wrapper">
          <button
            className="btn btn-primary d-flex align-items-center"
            onClick={next}
          >
            Continue <ArrowRight />
          </button>
        </div>
      </div>
    );
  }
}

export default GetStarted;
