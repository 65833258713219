import React from "react";
import PageSize from "../PageSize";
import PageSearch from "./PageSearch";
import SelectDropdown from "./SelectDropdown";

const Search = props => {
  const {
    selectedSortOption,
    handlePageSizeChange,
    searchParam,
    handleInputChange,
    sortByOptions,
    handleSortByChange
  } = props;

  const cardListPageSizeoptions = [
    {
      value: "12",
      label: "12"
    },
    {
      value: "24",
      label: "24"
    },
    {
      value: "36",
      label: "36"
    },
    {
      value: "48",
      label: "48"
    },
    {
      value: "60",
      label: "60"
    }
  ];

  return (
    <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column">
      <div className="mr-1">
        <PageSize
          onChange={handlePageSizeChange}
          customPageSizeOption={cardListPageSizeoptions}
        />
      </div>
      <PageSearch
        classnames="page-search"
        placeholder="Search"
        onChange={handleInputChange}
        value={searchParam}
      />

      <SelectDropdown
        buttonLabel={selectedSortOption}
        dropdownLinks={sortByOptions}
        color={"gray"}
        hideCaret
        selectName={"sortby_app"}
        handleChange={handleSortByChange}
      />
    </div>
  );
};

export default Search;
