// API Client
import { apiClient } from "../apiClient";

// Constants
import {
  REQUEST_DASHBOARD_COUNT,
  RECEIVE_DASHBOARD_COUNT,
  FETCH_DASHBOARD_COUNT_FAIL,
  REQUEST_CUSTOMER_DASHBOARD_COUNT,
  RECEIVE_CUSTOMER_DASHBOARD_COUNT,
  FETCH_CUSTOMER_DASHBOARD_COUNT_FAIL
} from "../actionType/Constants";

// Components
import toast from "../components/base/Toast";

// Configs
import { endpoints } from "../configs";
import { getUserId } from "../lib/helper";
import { isBadRequest } from "../common/http";

function requestAdminDashboardCount() {
  return { type: REQUEST_DASHBOARD_COUNT };
}

function receiveAdminDashboardCount(payload) {
  return { type: RECEIVE_DASHBOARD_COUNT, payload };
}

function fetchAdminDashboardCountFail(error) {
  return { type: FETCH_DASHBOARD_COUNT_FAIL, error };
}

export function fetchAdminDashboardCount() {
  return dispatch => {
    dispatch(requestAdminDashboardCount());

    return apiClient
      .get(
        `${endpoints().dashboardAPI}/admin/count?managerUserId=${getUserId()}&`
      )
      .then(response => {
        const data = response.data;
        return dispatch(receiveAdminDashboardCount(data));
      })
      .catch(error => {
        dispatch(fetchAdminDashboardCountFail(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}

function requestCustomerDashboard() {
  return { type: REQUEST_CUSTOMER_DASHBOARD_COUNT };
}

function receiveCustomerDashboard(payload) {
  return { type: RECEIVE_CUSTOMER_DASHBOARD_COUNT, payload };
}

export function fetchCustomerDashboardDetails() {
  return dispatch => {
    dispatch(requestCustomerDashboard());

    return apiClient
      .get(`${endpoints().dashboardAPI}/customer/count`)
      .then(response => {
        const data = response.data;

        dispatch(receiveCustomerDashboard(data));
      })
      .catch(error => {
        if (error && isBadRequest(error)) {
          let errorMessage;

          const errorRequest = error.response.request;

          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
        }
      });
  };
}
