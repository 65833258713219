import React from "react";
import { Redirect } from "react-router-dom";

const ReferralRedirect = props => {
  const encryptedPartnerId = props.match.params.id;

  const redirect = `/deal/register?partner=${encryptedPartnerId}`;
  return (
    <>
      <Redirect to={redirect} />
    </>
  );
};

export default ReferralRedirect;
