import getSymbolFromCurrency from "currency-symbol-map";

class Currency {
  static getSymbol = async currency => {
    if (currency) {
      let currencySymbol = getSymbolFromCurrency(currency);
      return currencySymbol;
    } else {
      return "";
    }
  };
}

export default Currency;
