import React, { useState } from "react";
import { Alert } from "reactstrap";

// Icons
import { CrossIcon, InlineInfoIcon } from "../../../assets/img/icons";
import { Link } from "react-router-dom";

const ExpertAvailabilityCheck = ({ projectDetails, handleClose }) => {
  const [hideProjectIds] = useState("");
  const { projectId, meetingAvailability } = projectDetails;
  return (
    <>
      {!meetingAvailability && hideProjectIds !== projectId && (
        <div className="alerts-wrapper">
          <Alert
            color="danger"
            className={
              "d-flex align-items-center justify-content-stretch text-white"
            }
          >
            <InlineInfoIcon />
            <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
              <span className="font-weight-bold">Project Active!</span> Please
              update your availability for a{" "}
              <span className="font-weight-bold">Kickoff Call</span>
            </p>
            <div className="btn-wrapper ml-auto mr-3">
              <Link className="btn btn-outline-light" to={"/availability"}>
                Update Availability
              </Link>
            </div>
            <div className="close-alert mr-0 ml-0">
              <button
                className="btn-plain btn text-white p-0"
                onClick={handleClose}
              >
                <CrossIcon />
              </button>
            </div>
          </Alert>
        </div>
      )}
    </>
  );
};

export default ExpertAvailabilityCheck;
