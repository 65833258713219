import React, { Component } from "react";

// Components
import Select from "../../../components/base/Select";

// Constants
import {
  PLAYBOOK_PRICING_TYPE_FIXED_PRICE,
  PLAYBOOK_PRICING_TYPE_HOURLY_PAYMENT,
  PLAYBOOK_PRICING_TYPE_SUBSCRIPTION,
  PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_MONTHLY,
  PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_QUARTERLY,
  PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_YEARLY
} from "../../../playbook/Constants";

export default class PlaybookPricingFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedOption:
        this.props.initialPricingType.label || PLAYBOOK_PRICING_TYPE_FIXED_PRICE
    };
  }

  // Handle pricing type
  handleChange = ({ values }) => {
    const selectedOption = values.pricingType && values.pricingType.label;
    this.setState({
      selectedOption
    });
  };

  // Show pricing schedule options
  pricingScheduleOptions = () => {
    const { selectedOption } = this.state;
    let options = [];
    switch (selectedOption) {
      case PLAYBOOK_PRICING_TYPE_FIXED_PRICE:
        options = [
          {
            label: "Half upfront, Half on completion",
            value: "Half upfront, Half on completion"
          },
          {
            label: "Full on completion",
            value: "Full on completion"
          },
          {
            label: "Full upfront",
            value: "Full upfront"
          }
        ];
        break;
      case PLAYBOOK_PRICING_TYPE_HOURLY_PAYMENT:
        options = [
          {
            label: "Every two weeks",
            value: "Every two weeks"
          },
          {
            label: "Once a month",
            value: "Once a month"
          },
          {
            label: "Prepaid hours",
            value: "Prepaid hours"
          }
        ];
        break;
      case PLAYBOOK_PRICING_TYPE_SUBSCRIPTION:
        options = [
          {
            label: PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_MONTHLY,
            value: PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_MONTHLY
          },
          {
            label: PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_QUARTERLY,
            value: PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_QUARTERLY
          },
          {
            label: PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_YEARLY,
            value: PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_YEARLY
          }
        ];
        break;
      default:
        options = [];
    }

    return options;
  };

  render() {
    return (
      <>
        <div className="field-wrapper">
          <Select
            name="pricingType"
            label="Pricing Type"
            options={[
              {
                label: PLAYBOOK_PRICING_TYPE_FIXED_PRICE,
                value: PLAYBOOK_PRICING_TYPE_FIXED_PRICE
              },
              {
                label: PLAYBOOK_PRICING_TYPE_HOURLY_PAYMENT,
                value: PLAYBOOK_PRICING_TYPE_HOURLY_PAYMENT
              },
              {
                label: PLAYBOOK_PRICING_TYPE_SUBSCRIPTION,
                value: PLAYBOOK_PRICING_TYPE_SUBSCRIPTION
              }
            ]}
            onInputChange={e => this.handleChange(e)}
            isSearchable={true}
          />
        </div>
        <div className="field-wrapper">
          <Select
            name="pricingSchedule"
            label="Payment Schedule"
            options={this.pricingScheduleOptions()}
            isSearchable={true}
          />
        </div>
      </>
    );
  }
}
