import React from "react";
import {
  AmexIcon,
  ArrowUpIcon,
  MastercardIcon,
  TrashIcon,
  VisaIcon
} from "../../../assets/img/icons";
import { Badge } from "reactstrap";

// Inner Components
import VerifyACHModal from "../modals/VerifyACHModal";

// Components
import useToggle from "../../../components/customhooks/useToggle";

const getPaymentOptionLast4 = paymentOption => paymentOption.last_4;
const getFormattedCreditCardName = paymentOption => {
  const cardType = paymentOption.card_type;
  return `${cardType.charAt(0).toUpperCase()}${cardType.slice(1)}`;
};
const getPaymentOptionStatus = paymentOption =>
  paymentOption.verifiedAt !== null;

const paymentOptionTitle = TYPE => {
  switch (TYPE) {
    case "card":
      return "Credit Card";
    case "ach":
      return "ACH";
  }
};

const buildPaymentOptionList = (TYPE, payload, verifyPaymentMethod) => {
  switch (TYPE) {
    case "card":
      return (
        <ul className="option-list list-unstyled">
          <li>
            {getFormattedCreditCardName(payload)} ending in:{" "}
            {getPaymentOptionLast4(payload)}
          </li>
        </ul>
      );

    case "ach":
      return (
        <ul className="option-list list-unstyled">
          <li>
            <span>Accounting Number:</span> ****{" "}
            {getPaymentOptionLast4(payload)}
          </li>
          <li>
            <span>Status:</span>{" "}
            <span
              className={[
                `${
                  getPaymentOptionStatus(payload)
                    ? "text-success"
                    : "text-warning"
                }`
              ].join(" ")}
            >
              {getPaymentOptionStatus(payload) ? (
                "Verified"
              ) : (
                <VerifyACH
                  paymentOption={payload}
                  verifyPaymentMethod={verifyPaymentMethod}
                />
              )}
            </span>
          </li>
        </ul>
      );
  }
};

const VerifyACH = ({ paymentOption, verifyPaymentMethod }) => {
  const [modal, setModal] = useToggle(false);
  return (
    <>
      <span>Not Verified</span>
      <a
        href="#"
        className={"d-block"}
        onClick={e => {
          e.preventDefault();
          setModal(true);
        }}
      >
        Please verify Payment Option
      </a>
      <VerifyACHModal
        paymentOption={paymentOption}
        verifyPaymentMethod={verifyPaymentMethod}
        isOpen={modal}
        toggle={setModal}
      />
    </>
  );
};

const PaymentOption = ({
  paymentOption,
  setDefaultPaymentOption,
  deletePaymentOption,
  verifyPaymentMethod,
  listView
}) => (
  <div className="payment-option">
    <div className="option-info">
      <h6 className="font-weight-bold">
        {paymentOptionTitle(paymentOption.payment_method_type)}
      </h6>
      {buildPaymentOptionList(
        paymentOption.payment_method_type,
        paymentOption,
        verifyPaymentMethod
      )}
      <div className="card-logo">
        {paymentOption.card_type === "visa" && <VisaIcon />}
        {paymentOption.card_type === "mastercard" && <MastercardIcon />}
        {paymentOption.card_type === "amex" && <AmexIcon />}
      </div>
    </div>
    <div className="btn-wrapper d-flex align-items-center">
      {paymentOption.is_default ? (
        <Badge color="primary" pill>
          Primary
        </Badge>
      ) : listView ? (
        ""
      ) : (
        <button
          className={["btn", "btn-link"].join(" ")}
          onClick={() => setDefaultPaymentOption(paymentOption.id)}
        >
          Make Primary <ArrowUpIcon />
        </button>
      )}

      {!listView && (
        <button
          className={["btn", "btn-plain", "text-danger", "ml-3"].join(" ")}
          onClick={() => deletePaymentOption(paymentOption.id)}
        >
          Delete <TrashIcon />
        </button>
      )}
    </div>
  </div>
);

export default PaymentOption;
