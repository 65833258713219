import React, { useState, useEffect } from "react";

import SaveButton from "../../components/base/SaveButton";

import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// Components
import Form from "../../components/base/Form";
import Text from "../../components/Text";

import Select from "../../components/base/Select";

import * as HubSpotConstants from "../Constants";

const addSyncRuleModal = ({
  isOpen,
  toggle,
  CompanyObjectFieldList,
  ContactObjectFieldList,
  DealObjectFieldList,
  propValue,
  syncRuleDetail,
  setSelectedSyncRule
}) => {
  const [selectedSyncDirection, setSyncDirection] = useState("");
  const [selectedHubspoteObjectName, setSelectedHubspotObject] = useState("");
  const [selectedTorchliteObject, setSelectedTorchliteObject] = useState();

  useEffect(() => {
    updateInitialValue();
  }, [isOpen]);

  const updateInitialValue = () => {
    if (syncRuleDetail) {
      let syncDirection = syncRuleDetail.syncDirectionId;
      let hubspotObjectName = syncRuleDetail.hubspotObjectName;
      setSyncDirection(syncDirection);
      setSelectedHubspotObject(hubspotObjectName);
    }
  };

  const handleSyncDirectionChange = e => {
    setSyncDirection(
      e && e.values && e.values.syncDirection && e.values.syncDirection.value
    );
  };

  const handleHubspotObjectChange = e => {
    setSelectedHubspotObject(
      e &&
        e.values &&
        e.values.hubspotObjectName &&
        e.values.hubspotObjectName.value
    );
  };

  const handleTorchliteObjectChange = e => {
    setSelectedTorchliteObject(
      e &&
        e.values &&
        e.values.torchliteObjectName &&
        e.values.torchliteObjectName.value
    );
  };

  const closeToggle = () => {
    setSyncDirection("");
    setSelectedHubspotObject("");
    setSelectedTorchliteObject("");
    setSelectedSyncRule("");
    toggle();
  };

  return (
    <div>
      <Modal isOpen={isOpen} className="edit-task-modal" backdrop="static">
        <ModalHeader
          toggle={() => {
            toggle();
          }}
        >
          <p className="text-center font-weight-bold mb-3">
            {syncRuleDetail
              ? "Edit Hubspot Sync Rule"
              : "Add Hubspot Sync Rule"}
          </p>
        </ModalHeader>

        <Form
          initialValues={{
            syncDirection:
              syncRuleDetail &&
              HubSpotConstants.getSyncDirection().find(
                data => data.value == syncRuleDetail.syncDirectionId
              ),
            hubspotObjectName:
              syncRuleDetail &&
              HubSpotConstants.HubspotObjectNameList().find(
                data => data.value == syncRuleDetail.hubspotObjectName
              ),
            hubspotFieldName:
              syncRuleDetail && syncRuleDetail.hubspotObjectName === "Companies"
                ? CompanyObjectFieldList &&
                  CompanyObjectFieldList.find(
                    data => data.value == syncRuleDetail.hubspotFieldName
                  )
                : syncRuleDetail &&
                  syncRuleDetail.hubspotObjectName == "Contacts"
                ? ContactObjectFieldList &&
                  ContactObjectFieldList.find(
                    data => data.value == syncRuleDetail.hubspotFieldName
                  )
                : syncRuleDetail && syncRuleDetail.hubspotObjectName == "Deals"
                ? DealObjectFieldList &&
                  DealObjectFieldList.find(
                    data => data.value == syncRuleDetail.hubspotFieldName
                  )
                : [],
            torchliteObjectName:
              syncRuleDetail &&
              HubSpotConstants.TorchliteObject().find(
                data => data.value == syncRuleDetail.torchliteObjectName
              ),
            torchliteFieldName:
              syncRuleDetail &&
              syncRuleDetail.torchliteObjectName === "referral"
                ? HubSpotConstants.TorchliteReferralFields().find(
                    data => data.value == syncRuleDetail.torchliteFieldName
                  )
                : syncRuleDetail &&
                  syncRuleDetail.torchliteObjectName == "account"
                ? HubSpotConstants.TorchliteAccountFields().find(
                    data => data.value == syncRuleDetail.torchliteFieldName
                  )
                : syncRuleDetail &&
                  syncRuleDetail.torchliteObjectName == "contact"
                ? HubSpotConstants.TorchliteContactFields().find(
                    data => data.value == syncRuleDetail.torchliteFieldName
                  )
                : [],
            operator:
              syncRuleDetail &&
              HubSpotConstants.relationalOperators().find(
                data => data.value == syncRuleDetail.operator
              ),
            value: syncRuleDetail && syncRuleDetail.value
          }}
          //onsubmit function
          onSubmit={values => {
            let submitData = new Object();

            submitData.syncDirection =
              values && values.syncDirection && values.syncDirection.value;

            submitData.hubspotObjectName =
              values &&
              values.hubspotObjectName &&
              values.hubspotObjectName.value;

            submitData.torchliteObjectName =
              values &&
              values.torchliteObjectName &&
              values.torchliteObjectName.value;

            submitData.hubspotFieldName =
              values &&
              values.hubspotFieldName &&
              values.hubspotFieldName.value;

            submitData.torchliteFieldName =
              values &&
              values.torchliteFieldName &&
              values.torchliteFieldName.value;

            submitData.operator =
              values && values.operator && values.operator.value;

            submitData.value = values && values.value;

            submitData.type =
              values && values.hubspotFieldName && values.hubspotFieldName.type;

            if (syncRuleDetail) {
              propValue.actions.updateHubspotSyncRule(
                syncRuleDetail.id,
                submitData,
                "",
                closeToggle
              );
            } else {
              propValue.actions.addHubspotSyncRule(submitData, "", closeToggle);
            }
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div className="field-wrapper mb-3">
                <Select
                  name="syncDirection"
                  label="Sync Direction"
                  placeholder="Select Sync Direction"
                  options={HubSpotConstants.getSyncDirection()}
                  onInputChange={e => handleSyncDirectionChange(e)}
                  required
                  isClearable={true}
                />
              </div>

              {(selectedSyncDirection ==
                HubSpotConstants.Hubspot.SYNC_DIRECTION_INBOUND ||
                selectedSyncDirection ==
                  HubSpotConstants.Hubspot.SYNC_DIRECTION_BOTH) && (
                <div className="field-wrapper mb-3">
                  <Select
                    name="hubspotObjectName"
                    label="HubSpot Object"
                    placeholder="Select HubSpot Object"
                    options={HubSpotConstants.HubspotObjectNameList()}
                    onInputChange={e => handleHubspotObjectChange(e)}
                    required
                    isClearable={true}
                  />
                </div>
              )}

              {(selectedSyncDirection ==
                HubSpotConstants.Hubspot.SYNC_DIRECTION_INBOUND ||
                selectedSyncDirection ==
                  HubSpotConstants.Hubspot.SYNC_DIRECTION_BOTH) && (
                <div className="field-wrapper mb-3">
                  <Select
                    name="hubspotFieldName"
                    label="Hubspot Field"
                    placeholder="Select Hubspot Field "
                    options={
                      selectedHubspoteObjectName === "Companies"
                        ? CompanyObjectFieldList
                        : selectedHubspoteObjectName == "Contacts"
                        ? ContactObjectFieldList
                        : selectedHubspoteObjectName == "Deals"
                        ? DealObjectFieldList
                        : []
                    }
                    required
                    isClearable={true}
                    isSearchable={true}
                  />
                </div>
              )}

              {(selectedSyncDirection ==
                HubSpotConstants.Hubspot.SYNC_DIRECTION_OUTBOUND ||
                selectedSyncDirection ==
                  HubSpotConstants.Hubspot.SYNC_DIRECTION_BOTH) && (
                <div className="field-wrapper mb-3">
                  <Select
                    name="torchliteObjectName"
                    label="Torchlite Object"
                    placeholder="Select HubSpot Object"
                    options={HubSpotConstants.TorchliteObject()}
                    onInputChange={e => handleTorchliteObjectChange(e)}
                    required
                    isClearable={true}
                  />
                </div>
              )}

              {(selectedSyncDirection ==
                HubSpotConstants.Hubspot.SYNC_DIRECTION_OUTBOUND ||
                selectedSyncDirection ==
                  HubSpotConstants.Hubspot.SYNC_DIRECTION_BOTH) && (
                <div className="field-wrapper mb-3">
                  <Select
                    name="torchliteFieldName"
                    label="Torchlite Field"
                    placeholder="Select Torchlite Field "
                    options={
                      selectedTorchliteObject === "contact"
                        ? HubSpotConstants.TorchliteContactFields()
                        : selectedTorchliteObject == "referral"
                        ? HubSpotConstants.TorchliteReferralFields()
                        : selectedTorchliteObject == "account"
                        ? HubSpotConstants.TorchliteAccountFields()
                        : []
                    }
                    required
                    isClearable={true}
                    isSearchable={true}
                  />
                </div>
              )}

              <div className="field-wrapper mb-3">
                <Select
                  name="operator"
                  placeholder="Select condition"
                  label="Condition"
                  options={HubSpotConstants.relationalOperators()}
                  isClearable={true}
                  required
                />
              </div>

              <Text
                name="value"
                label="Value"
                placeholder="Enter Value"
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <SaveButton />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};
export default addSyncRuleModal;
