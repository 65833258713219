import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Inner Components
import ProjectStepWrapper from "../ProjectStepWrapper";
import Steps from "../ProjectSteps";

// Components
import Avatar from "../../../../components/base/Avatar";
import PlaybookIcon from "../../../../components/base/PlaybookIcon";

// Assets
import {
  ActivityIcon,
  DollarIcon,
  UserIcon,
  MessageCircleIcon,
  ArrowRight
} from "../../../../assets/img/icons";
import headerTriangles from "../../../../assets/header-triangles.svg";

// Variable
import variables from "../../../../scss/_variables.scss";

// Constants
import { PLAYBOOK_PRICING_TYPE_SUBSCRIPTION } from "../../../../playbook/Constants";
import {
  PROJECT_TYPE_PROJECT,
  PROJECT_TYPE_CAMPAIGN
} from "../../../../project/Constants";

// Get Pricing Schedule Label
import { getPricingScheduleValue } from "../../../playbook/inner_components/getPricingScheduleValue";

// Hook
function useHover() {
  const [value, setValue] = useState(false);

  const ref = useRef(null);

  const handleMouseOver = () => setValue(true);
  const handleMouseOut = () => setValue(false);

  useEffect(
    () => {
      const node = ref.current;
      if (node) {
        node.addEventListener("mouseover", handleMouseOver);
        node.addEventListener("mouseout", handleMouseOut);

        return () => {
          node.removeEventListener("mouseover", handleMouseOver);
          node.removeEventListener("mouseout", handleMouseOut);
        };
      }
    },
    [ref.current] // Recall only if ref changes
  );

  return [ref, value];
}

const renderUnreadMessages = notifications => {
  if (notifications !== undefined && notifications.newMessages !== null) {
    return (
      <div className="new-message">
        <MessageCircleIcon />
        {notifications.newMessages}
      </div>
    );
  }
};

const renderAlertNotification = notifications => {
  if (
    notifications !== undefined &&
    notifications.notificationAlertLabel !== null
  ) {
    return (
      <div className="new-alert-message">
        {notifications.notificationAlertLabel}
      </div>
    );
  }
};

const ProjectCard = props => {
  const { data, currentProjectType, projectDetailsUrlBasePath } = props;

  const {
    id,
    bannerColor,
    name,
    bannerImagePathUrl,
    bannerImageUrl,
    bannerIcon,
    placeHolder,
    projectNotifications,
    customer,
    invoicedAmount,
    estimatedBudget,
    estimatedTimeline,
    users,
    usersCount,
    hoursThisMonth,
    billingType,
    paymentType,
    experts,
    projectAcceptedUserCount,
    assignmentPending,
    statusHistory,
    expertId,
    status,
    interestedPartnerCount,
    invitedPartnerCount
  } = data;

  const [hoverRef, isHovered] = useHover();

  const ImageURL = bannerImageUrl ? bannerImageUrl : bannerImagePathUrl;

  const steps = Steps();

  return (
    <div className="project-list">
      <Link
        to={`/${projectDetailsUrlBasePath}/${id}`}
        className={`project d-flex justify-content-start text-decoration-none ${
          isHovered ? "shadow-none" : ""
        }`}
        key={id}
        ref={hoverRef}
      >
        <div
          id="card-link"
          style={{ color: "#212529", cursor: "pointer" }}
          className={`project-playbook-card mb-4 text-decoration-none`}
        >
          <div className={`cursor-pointer card mx-auto`}>
            <LazyLoad>
              <div
                id="card-image"
                className="card-header"
                style={{
                  backgroundImage: `url(${
                    bannerImageUrl
                      ? bannerImageUrl
                      : ImageURL
                      ? ImageURL
                      : headerTriangles
                  })`,
                  backgroundColor: `${
                    bannerColor ? bannerColor : variables.cardHeaderDefaultColor
                  }`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat"
                }}
              >
                <div className="card-header-icon">
                  {!ImageURL ? <PlaybookIcon iconName={bannerIcon} /> : ""}
                </div>
              </div>
            </LazyLoad>
            <div className={"body-wrapper"}>
              <div className="card-body">
                <p className="card-title font-weight-bold">
                  {!name && placeHolder ? "" : name}
                </p>
              </div>
              <div className="card-footer float-right bg-white arrow-hover mt-n5">
                <ArrowRight />
              </div>
            </div>
          </div>
          {renderUnreadMessages(projectNotifications)}
          {renderAlertNotification(projectNotifications)}
        </div>
        <div className="project-data d-flex flex-column w-100">
          {currentProjectType === PROJECT_TYPE_PROJECT && (
            <div className="project-maininfo d-flex justify-content-between">
              <div className="project-finances">
                <div className="field-wrapper">
                  <p className="font-weight-bold">Client</p>
                  <span className="d-block text-grayed font-weight-bold">
                    {customer ? customer.displayName : ""}
                  </span>
                </div>
                <div className="field-wrapper">
                  <p className="font-weight-bold">Budget</p>
                  <span className="d-block text-grayed font-weight-bold">
                    {estimatedBudget && estimatedBudget > 0 && (
                      <>
                        Invoiced ${`${invoicedAmount}`} of
                        {estimatedBudget ? ` $${estimatedBudget}` : ""}
                      </>
                    )}
                  </span>
                </div>

                <div className="field-wrapper">
                  <p className="font-weight-bold">Timeline</p>
                  <span className="d-block text-grayed font-weight-bold">
                    {estimatedTimeline ? estimatedTimeline : ""}
                  </span>
                </div>
              </div>
              <div className="project-progress">
                <div className="field-wrapper">
                  <div className="user-wrapper">
                    {users &&
                      usersCount > 0 &&
                      users.slice(0, 4).map((user, index) => (
                        <React.Fragment key={index}>
                          <div
                            className={`field-icon ${usersCount > 2 && "mr-2"}`}
                          >
                            {user &&
                            user.avatarUrl !== null &&
                            user.avatarUrl ? (
                              <Avatar
                                firstName={user.firstName}
                                lastName={user.lastName}
                                size="customSize"
                                imageSize={"32"}
                                url={user.avatarUrl}
                              />
                            ) : (
                              <Avatar
                                firstName={user.firstName}
                                lastName={user.lastName}
                                size="customSize"
                                imageSize={"32"}
                              />
                            )}
                          </div>
                          {users && users.length < 4 && (
                            <p className="font-weight-bold">{user.name}</p>
                          )}
                        </React.Fragment>
                      ))}
                    {users && users.length > 3 && (
                      <div className="field-icon rounded-circle">...</div>
                    )}
                    {users && !users.length && (
                      <>
                        <div className="field-icon">
                          <UserIcon />
                        </div>
                        <p className="font-weight-bold">Sourcing Expert</p>
                      </>
                    )}
                  </div>
                </div>

                <div className="field-wrapper">
                  <div className="d-flex align-items-center">
                    <div className="field-icon">
                      <UserIcon />
                    </div>
                    <p className="font-weight-bold">
                      {hoursThisMonth || 0}
                      <span className="text-grayed font-weight-bold">
                        Total Hours
                      </span>
                    </p>
                  </div>
                </div>

                <div className="field-wrapper">
                  <div className="d-flex align-items-center">
                    <div className="field-icon">
                      <DollarIcon />
                    </div>
                    <p className="font-weight-bold">
                      {estimatedBudget > 0 ? (
                        <>
                          ${estimatedBudget}
                          <span className="text-grayed font-weight-bold">
                            {PLAYBOOK_PRICING_TYPE_SUBSCRIPTION === billingType
                              ? `${getPricingScheduleValue(paymentType)}`
                              : "Fixed"}
                          </span>
                        </>
                      ) : (
                        <>
                          {experts ? experts.hourlyRate : ""}
                          <span className="text-grayed font-weight-bold">
                            {experts ? "per hour" : ""}
                          </span>
                        </>
                      )}
                    </p>
                  </div>
                </div>

                <div className="field-wrapper">
                  <div className="d-flex align-items-center">
                    <div className="field-icon">
                      <ActivityIcon />
                    </div>

                    {/* Show Assignment Pending When Expert Is Not Assigned */}
                    {!expertId ? (
                      <div className="d-flex flex-column">
                        <p className="font-weight-bold">Assignment Pending</p>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Show No Activity When No Discussion */}
                    {expertId && !assignmentPending ? (
                      <div className="d-flex flex-column">
                        <p className="font-weight-bold">No Activity</p>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Show Last Activity When Discussion */}
                    {expertId && assignmentPending ? (
                      <div className="d-flex flex-column">
                        <p className="font-weight-bold">Last Activity</p>
                        <span className="d-block text-grayed font-weight-bold">
                          {assignmentPending}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          {currentProjectType === PROJECT_TYPE_CAMPAIGN && (
            <div className="project-maininfo d-flex justify-content-between">
              <div className="project-progress">
                <div className="field-wrapper">
                  <div className="user-wrapper">
                    <div className="field-icon">
                      <UserIcon />
                    </div>
                    <p className="font-weight-bold">
                      {interestedPartnerCount || 0} Partners Interested
                    </p>
                  </div>
                </div>

                <div className="field-wrapper">
                  <div className="d-flex align-items-center">
                    <div className="user-wrapper">
                      <div className="field-icon">
                        <UserIcon />
                      </div>
                      <p className="font-weight-bold">
                        {invitedPartnerCount || 0} Partners Invited
                      </p>
                    </div>
                  </div>
                </div>

                <div className="field-wrapper">
                  <div className="d-flex align-items-center">
                    <div className="field-icon">
                      <ActivityIcon />
                    </div>

                    {/* Show Assignment Pending When Expert Is Not Assigned */}
                    {!expertId ? (
                      <div className="d-flex flex-column">
                        <p className="font-weight-bold">Assignment Pending</p>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Show No Activity When No Discussion */}
                    {expertId && !assignmentPending ? (
                      <div className="d-flex flex-column">
                        <p className="font-weight-bold">No Activity</p>
                      </div>
                    ) : (
                      ""
                    )}

                    {/* Show Last Activity When Discussion */}
                    {expertId && assignmentPending ? (
                      <div className="d-flex flex-column">
                        <p className="font-weight-bold">Last Activity</p>
                        <span className="d-block text-grayed font-weight-bold">
                          {assignmentPending}
                        </span>
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
          <div className="project-timeline">
            <ProjectStepWrapper
              steps={steps}
              projectStatusHistory={statusHistory}
              projectStatus={status}
            />
          </div>
        </div>
      </Link>
    </div>
  );
};

export default ProjectCard;
