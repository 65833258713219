import React from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-date-picker";

// Components
import Text from "../../components/base/Text";
import TextArea from "../../components/base/TextArea";
import Phone from "../../components/base/Phone";
import Email from "../../components/base/Email";
import Button from "../../components/base/Button";
import Select from "../../components/base/Select";
import MultiSelect from "../../components/base/Multiselect";
import MultiselectCreatable from "../../components/base/MultiselectCreatable";
import Label from "../../components/base/Label";
import RadioButton from "../../components/base/RadioButton";
import Currency from "../../components/base/Currency";
import Number from "../../components/base/Number";
import DateInput from "../../components/base/Date";

import { loadReCaptcha, ReCaptcha } from "react-recaptcha-v3";

import { getKeyValueByObject } from "../../lib/helper";

//Constants
import {
  SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY,
  SETTINGS_SHOW_PARTNER_MANAGER,
  SETTINGS_SHOW_REFERRAL_AMOUNT,
  SETTING_REFERRAL_CUSTOMER_READINESS_OPTION,
  SETTING_REFERRAL_SHOW_CUSTOMER_READINESS,
  SETTINGS_REFERRAL_PHONE_NUMBER_REQUIRED
} from "../../setting/Constants";
import { readinessOptions } from "../../referral/Constants";
import {
  ALWAYS_ASK,
  TASK_TYPE_DROP_DOWN_FIELD,
  TASK_TYPE_TEXT_FIELD,
  TASK_TYPE_DATE_FIELD,
  TASK_TYPE_WEBSITE_FIELD,
  TASK_TYPE_DROP_DOWN_SINGLE_SELECT,
  CONDITIONAL,
  TASK_TYPE_DROP_DOWN_MULTI_SELECT,
  TASK_TYPE_CURRENCY_FIELD,
  TASK_TYPE_NUMBER_FIELD
} from "../../modules/task/Constant";

import * as CountryCode from "../../phone/CountryCode";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import { TAG_PERMISSION_SELECT_AND_ADD_VALUE } from "../../tag/Constants";
import { isNotEmpty, isTrue } from "../../common/utils";

import URL from "../../components/base/URL";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

import { getSetting } from "../../actions/settings";

import { connect } from "react-redux";

// Column constants
const COLUMN_VALUE_TWO = 2;
const COLUMN_VALUE_THREE = 3;
const COLUMN_VALUE_FOUR = 4;
const COLUMN_VALUE_SIX = 6;
const COLUMN_VALUE_NINE = 9;
const COLUMN_VALUE_TEN = 10;

class ReferralFormFields extends React.Component {
  reCaptcha = React.createRef();
  customerReadinessRef = React.createRef();

  constructor(props) {
    super(props);
    const { questionList } = this.props;
    this.state = {
      reCaptchaSiteKey: this.props.settings
        ? getKeyValueByObject(
            this.props.settings,
            SETTINGS_GOOGLE_RECAPTCHA_SITE_KEY
          )
        : "",
      isUserVerified: false,
      fieldId: "",
      question: [],
      handleChange: false,
      seletedQuestion:
        questionList && questionList.length > 0 ? [...questionList] : [],
      answerValue: "",
      questionState: [],
      showQuestion: [],
      onChangeQuestion: [],
      selectedConditionField: "",
      conditionalFieldIds: [],
      alwaysAskFieldList: [],
      conditionalFieldList: [],
      commonQuestionList: [],
      dateField: [],
      showPartnerManager: false,
      showAmoutField: "",
      contactFieldRequired: false
    };
  }
  componentDidMount() {
    if (this.props.publicReferralForm && this.state.reCaptchaSiteKey) {
      loadReCaptcha(this.state.reCaptchaSiteKey);
    }
    this.getConditionFieldIdsList();
    this.getSettings();
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.isUserVerified !== prevState.isUserVerified) {
      this.props.onUserVerified(this.state.isUserVerified);
    }
    this.props.customerReadinessTarget &&
      setTimeout(() => {
        this.customerReadinessRef.current.scrollIntoView({
          behavior: "smooth",
          block: "start"
        });
        this.props.setCustomerReadiness();
      }, 500);
  }

  getSettings = () => {
    getSetting(response => {
      if (response && response.length > 0) {
        let showPartnerManager =
          getKeyValueByObject(response, SETTINGS_SHOW_PARTNER_MANAGER) == "true"
            ? true
            : false;

        let showAmoutField = getKeyValueByObject(
          response,
          SETTINGS_SHOW_REFERRAL_AMOUNT
        );

        let contactFieldRequired =
          getKeyValueByObject(
            response,
            SETTINGS_REFERRAL_PHONE_NUMBER_REQUIRED
          ) == "true"
            ? true
            : false;

        this.setState({
          showAmoutField: showAmoutField,
          showPartnerManager: showPartnerManager,
          contactFieldRequired: contactFieldRequired
        });
      }
    });
  };
  getNameById = (permission, values) => {
    let ids = JSON.parse(permission);
    let readiness = [];
    ids &&
      ids.length &&
      ids.forEach(id => {
        values &&
          values.length &&
          values.forEach(value => {
            if (id === value.id) {
              readiness.push(value.name);
            }
          });
      });
    return readiness;
  };

  ConditionalFieldHandleChange = (
    SelectedAlwaysAskQuestionDetails,
    questionIndex,
    onChangeObject
  ) => {
    try {
      let conditionalFieldId;

      //destructure the state values
      const { commonQuestionList, conditionalFieldList } = this.state;

      //add commonQuestionList array in updated question list
      let updatedCommonQuestionList = [...commonQuestionList];

      //selected custom field condition values
      let selectedAlwaysAskQuestionValueId =
        onChangeObject.value && onChangeObject.value.value;

      //assign a selected always ask question ID to conditonal field
      conditionalFieldId = SelectedAlwaysAskQuestionDetails.id;

      //check the selected question always ask question or not
      if (
        SelectedAlwaysAskQuestionDetails &&
        SelectedAlwaysAskQuestionDetails.conditionalDisplay == ALWAYS_ASK
      ) {
        //check the selected always ask ID in conditional field list
        let isCondtionFieldExist = conditionalFieldList.find(
          data => data.conditionalFieldId == conditionalFieldId
        );

        //if the always ask question found in condtional field
        if (isCondtionFieldExist) {
          //check the slected always ask question value set in conditional field conditional value list
          let isAnswerIdMatch = conditionalFieldList.find(
            data => data.fieldValue == selectedAlwaysAskQuestionValueId
          );

          //check the always ask conditional field ID already exist in common field list
          let isExistCommonList = updatedCommonQuestionList.find(
            data => data.conditionalFieldId == conditionalFieldId
          );

          //if selected always ask question match with conditional field conditional value
          if (isAnswerIdMatch) {
            //check the conditional field exist or not and selected question not in common list(duplicate field check)
            if (isCondtionFieldExist && !isExistCommonList) {
              //place the matched conditional field in next to selected always ask questions
              updatedCommonQuestionList.splice(
                questionIndex + 1,
                0,
                isCondtionFieldExist
              );

              //check the updated common quest list exist or not
              if (updatedCommonQuestionList) {
                this.setState({
                  commonQuestionList: updatedCommonQuestionList
                });
              }
            }
            //is Answer is not match conditional field value and selected question already exist in common list
          } else if (isExistCommonList) {
            //find the index of exsing conditional field
            let index = updatedCommonQuestionList.findIndex(
              data => data.conditionalFieldId == conditionalFieldId
            );

            //remove that conditional field question from updated common list
            updatedCommonQuestionList.splice(index, 1);
            this.setState({
              commonQuestionList: updatedCommonQuestionList
            });
          }
        }
      }
    } catch (err) {}
  };

  getConditionFieldIdsList = () => {
    const { questionList } = this.props;
    const { conditionalFieldList } = this.state;
    let alwaysAskFieldList = [];
    let conditionalFieldLists = [];
    let commonQuestionList = [];

    //Get always ask fields selected values
    let ansIds = [];
    questionList.forEach(questionDetails => {
      if (questionDetails["selectedValue"]) {
        if (questionDetails.conditionalDisplay == ALWAYS_ASK) {
          Object.keys(questionDetails.selectedValue).forEach((key, value) => {
            questionDetails.selectedValue[key].forEach(data => {
              ansIds.push(data.value);
            });
          });
        }
      }
    });

    //Make the loop fir question list
    questionList.forEach(questionDetails => {
      //check the question condition display alwas ask or not
      if (questionDetails && questionDetails.conditionalDisplay == ALWAYS_ASK) {
        alwaysAskFieldList.push(questionDetails);
        commonQuestionList.push(questionDetails);
      } else {
        //question conditional is not always ask take conditional
        conditionalFieldLists.push(questionDetails);
        //check the conditional field value exist or not
        if (questionDetails["selectedValue"]) {
          //check the condtional field conditional value exist or not in always ask answer list ids
          if (ansIds.indexOf(questionDetails.fieldValue) > -1) {
            //check For Select value exist or not
            let isSelectConditionalfieldAnswerExist =
              questionDetails.selectedValue;
            //check for Text field value exist or not
            let isTextConditionalFieldValueExist = questionDetails.value;

            //check in that conditional field answer exsit or not
            var isValueExsit = Object.keys(
              isSelectConditionalfieldAnswerExist
            ).filter(function(key) {
              return isSelectConditionalfieldAnswerExist[key];
            });
            if (isValueExsit.length > 0) {
              commonQuestionList.push(questionDetails);
            } else if (isTextConditionalFieldValueExist) {
              commonQuestionList.push(questionDetails);
            }
          }
        }
      }
    });

    this.setState({
      conditionalFieldList: conditionalFieldLists,
      alwaysAskFieldList: alwaysAskFieldList,
      commonQuestionList: commonQuestionList
    });
  };

  getDate = id => {
    try {
      let dateList = this.state.dateField;

      if (dateList && dateList.length > 0) {
        let date = dateList.find(data => data.id == id);

        return date && date.date ? date.date : null;
      }
      return null;
    } catch (err) {
      console.log(err);
    }
  };

  dateCustomFieldOnchange = (id, date) => {
    try {
      let dateList = this.state.dateField;

      if (dateList && dateList.length > 0) {
        let index = dateList.findIndex(data => data.id == id);

        if (index > -1) {
          dateList[index].date = date ? date : null;
          this.setState({ dateField: dateList });
        } else {
          dateList.push({ id: id, date: date ? date : null });
          this.setState({ dateField: dateList });
        }
      } else {
        dateList.push({ id: id, date: date ? date : null });
        this.setState({ dateField: dateList });
      }
    } catch (err) {
      console.log(err);
    }
  };

  render() {
    const {
      questionList,
      overlayLoader,
      isSubmitPage,
      setDatefieldDate,
      dateField,
      userList,
      currencyList,
      partnerSubmit,
      partnerOptionalSubmit,
      requiredOnApproval,
      optionalOnApproval,
      countryCodeList
    } = this.props;
    let fieldMyValue = "";
    questionList.forEach(myData => {
      fieldMyValue = myData.fieldValue;
    });

    // Country coude Options
    let countryCode = [];
    CountryCode.countryCode.forEach(countryData => {
      countryCode.push({
        label: countryData.dial_code,
        value: countryData.dial_code
      });
    });

    const {
      question,
      showQuestion,
      handleChange,
      answerValue,
      questionState,
      seletedQuestion,
      selectedConditionField,
      conditionalFieldList,
      alwaysAskFieldList,
      commonQuestionList,
      showPartnerManager,
      showAmoutField,
      contactFieldRequired
    } = this.state;
    let showValue = "";
    question.forEach(data => {
      showValue = data.name;
    });
    const settings =
      this.props.settings && this.props.settings ? this.props.settings : "";

    const enableCustomerReadiness = settings
      ? getKeyValueByObject(settings, SETTING_REFERRAL_SHOW_CUSTOMER_READINESS)
      : "";
    const customerReadinessOptions = settings
      ? getKeyValueByObject(
          settings,
          SETTING_REFERRAL_CUSTOMER_READINESS_OPTION
        )
      : "";

    const permisionList =
      customerReadinessOptions && JSON.parse(customerReadinessOptions);
    return (
      <div className="details-body-main">
        <div className="card-body">
          <div className="col-lg-12">
            {/* Account Name */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text
                  id="accountName"
                  name="accountName"
                  label={Terminology.get(SystemName.REFERRAL_ACCOUNT_NAME)}
                  placeholder={`Select a ${Terminology.get(
                    SystemName.REFERRAL_ACCOUNT_NAME
                  )}`}
                  required={true}
                  error=""
                />
              </div>
            </div>
            {/* First Name */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text
                  id="firstName"
                  name="firstName"
                  label={Terminology.get(SystemName.REFERRAL_FIRST_NAME)}
                  placeholder={`Select a ${Terminology.get(
                    SystemName.REFERRAL_FIRST_NAME
                  )}`}
                  required={true}
                  error=""
                />
              </div>
            </div>
            {/* Last Name */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text
                  id="lastName"
                  name="lastName"
                  label={Terminology.get(SystemName.REFERRAL_LAST_NAME)}
                  placeholder={`Select a ${Terminology.get(
                    SystemName.REFERRAL_LAST_NAME
                  )}`}
                  required={true}
                  error=""
                />
              </div>
            </div>
            {/* Contact Title */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text
                  id="contactTitle"
                  name="contactTitle"
                  label={Terminology.get(SystemName.REFERRAL_CONTACT_TITLE)}
                  placeholder={`Select a ${Terminology.get(
                    SystemName.REFERRAL_CONTACT_TITLE
                  )}`}
                  required={true}
                  error=""
                />
              </div>
            </div>
            {/* Contact Email */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Email
                  id="title"
                  name="contactEmail"
                  required={true}
                  label={Terminology.get(SystemName.REFERRAL_CONTACT_EMAIL)}
                  placeholder={`Select a ${Terminology.get(
                    SystemName.REFERRAL_CONTACT_EMAIL
                  )}`}
                  error=""
                />
              </div>
            </div>
            {/* Contact Phone */}
            <Label required={contactFieldRequired}>
              {`${Terminology.get(SystemName.REFERRAL_CONTACT_PHONE)}`}
            </Label>
            <div className="row field-wrapper">
              <div
                className={`col-lg-${
                  this.props.publicReferralForm
                    ? COLUMN_VALUE_TWO
                    : COLUMN_VALUE_THREE
                }`}
              >
                <Select
                  id="countryCode"
                  name="countryCode"
                  options={countryCodeList}
                  isSearchable={true}
                />
              </div>
              <div
                className={`col-lg-${
                  this.props.publicReferralForm
                    ? COLUMN_VALUE_TEN
                    : COLUMN_VALUE_NINE
                } pl-0`}
              >
                <Phone
                  id="contactPhone"
                  name="contactPhone"
                  placeholder={`Select a ${Terminology.get(
                    SystemName.REFERRAL_CONTACT_PHONE
                  )}`}
                  required={contactFieldRequired}
                  error=""
                />
              </div>
            </div>
            {showPartnerManager && (
              <div className="field-wrapper row">
                <div className="col-lg-12">
                  <Select
                    name="partner_manager"
                    label={Terminology.get(SystemName.PARTNER_MANAGER)}
                    placeholder={`Select a ${Terminology.get(
                      SystemName.PARTNER_MANAGER
                    )}`}
                    options={userList}
                    isSearchable={true}
                    // defaultValue={selectedOption}
                  />
                </div>
              </div>
            )}

            <div className="field-wrapper row">
              <div className="col-lg-12">
                <URL
                  id={"accountUrl"}
                  name={"accountUrl"}
                  label="Account URL"
                  placeholder={"Enter Account Url"}
                />
              </div>
            </div>
            {(partnerSubmit ||
              partnerOptionalSubmit ||
              requiredOnApproval ||
              optionalOnApproval) && (
              <div>
                <div className="field-wrapper row">
                  <div
                    className={`col-lg-${
                      this.props.publicReferralForm
                        ? COLUMN_VALUE_TWO
                        : COLUMN_VALUE_THREE
                    }`}
                  >
                    <Select
                      id="currency"
                      name="currency"
                      label="Currency"
                      options={currencyList}
                      placeholder="Currency"
                      isSearchable={true}
                      onInputChange={this.props.onCurrencyChange}
                      required={
                        requiredOnApproval || partnerSubmit ? true : false
                      }
                    />
                  </div>
                  <div
                    className={`col-lg-${
                      this.props.publicReferralForm
                        ? COLUMN_VALUE_TEN
                        : COLUMN_VALUE_NINE
                    } pl-0 `}
                  >
                    <Number
                      id="amount"
                      name="amount"
                      placeholder="Enter Amount"
                      label="Amount"
                      error=""
                      required={
                        requiredOnApproval || partnerSubmit ? true : false
                      }
                    />
                  </div>
                </div>
              </div>
            )}
            {!this.props.disableCloseDate && (
              <div className="row col-lg-6">
                <div className="w-100">
                  <div>
                    <label>Close Date</label>
                  </div>
                  <DatePicker
                    id="closeDate"
                    name="closeDate"
                    className="datepicker"
                    dayPlaceholder="MM/DD/YYYY"
                    format="MM/dd/yyyy"
                    onChange={date => this.props.setCloseDate(date)}
                    minDate={new Date()}
                    value={this.props.closeDate}
                    clearIcon={null}
                    disabled={
                      !isSuperAdmin(this.props.roleId) &&
                      !isCompanyAdmin(this.props.roleId) &&
                      !isCompanyManager(this.props.roleId)
                    }
                  />
                </div>
              </div>
            )}
            {/* Referral Question Fields */}
            <div className="mt-4">
              {commonQuestionList && commonQuestionList.length > 0 && (
                <>
                  <h5 className="font-weight-bold mb-3">
                    Qualification Questions
                  </h5>
                  <hr />
                </>
              )}
              {commonQuestionList &&
                commonQuestionList.length > 0 &&
                commonQuestionList.map((questionDetails, index) => (
                  <>
                    <>
                      {questionDetails.type === TASK_TYPE_DATE_FIELD &&
                      questionDetails.type !== TASK_TYPE_TEXT_FIELD &&
                      questionDetails.type !== TASK_TYPE_DROP_DOWN_FIELD ? (
                        <div className="field-wrapper row">
                          <div
                            className={`col-lg-${
                              this.props.publicReferralForm
                                ? COLUMN_VALUE_FOUR
                                : COLUMN_VALUE_SIX
                            }`}
                          >
                            <div>
                              <label>{questionDetails.label}</label>
                            </div>
                            <DateInput
                              id={questionDetails.id}
                              selected={this.getDate(questionDetails.id)}
                              name={`${questionDetails.id}`}
                              className="datepicker"
                              placeholder="MM-DD-YYYY"
                              onChange={value => {
                                this.dateCustomFieldOnchange(
                                  questionDetails.id,
                                  value
                                );
                              }}
                              minDate={new Date()}
                              format="MM-dd-yyyy"
                              width={200}
                              required={
                                questionDetails.isRequired === true
                                  ? true
                                  : false
                              }
                            />
                          </div>
                        </div>
                      ) : questionDetails.type === TASK_TYPE_WEBSITE_FIELD ? (
                        <div className="field-wrapper row">
                          <div className="col-lg-12">
                            <div className="field-wrapper">
                              <URL
                                id={questionDetails.id}
                                name={questionDetails.id}
                                label={questionDetails.label}
                                defaultValue={
                                  questionDetails && questionDetails.value
                                    ? questionDetails.value
                                    : ""
                                }
                                error=""
                                required={
                                  questionDetails.isRequired === true
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                        </div>
                      ) : questionDetails.type === TASK_TYPE_TEXT_FIELD &&
                        questionDetails.type !== TASK_TYPE_DROP_DOWN_FIELD ? (
                        <>
                          <div className="field-wrapper row">
                            <div className="col-lg-12">
                              <Text
                                id={questionDetails.id}
                                name={questionDetails.id}
                                label={questionDetails.label}
                                error=""
                                defaultValue={
                                  questionDetails && questionDetails.value
                                    ? questionDetails.value
                                    : ""
                                }
                                required={
                                  questionDetails.isRequired === true
                                    ? true
                                    : false
                                }
                              />
                            </div>
                          </div>
                        </>
                      ) : questionDetails.type == TASK_TYPE_DROP_DOWN_FIELD &&
                        questionDetails.selectType ==
                          TASK_TYPE_DROP_DOWN_SINGLE_SELECT &&
                        questionDetails.type != TASK_TYPE_TEXT_FIELD ? (
                        <>
                          <Select
                            id={questionDetails.id}
                            name={questionDetails.id}
                            label={questionDetails.label}
                            options={questionDetails.tagList}
                            onInputChange={e =>
                              this.ConditionalFieldHandleChange(
                                questionDetails,
                                index,
                                e
                              )
                            }
                            required={
                              questionDetails.isRequired === true ? true : false
                            }
                          />
                        </>
                      ) : questionDetails.type == TASK_TYPE_DROP_DOWN_FIELD &&
                        questionDetails.selectType ==
                          TASK_TYPE_DROP_DOWN_MULTI_SELECT &&
                        questionDetails.type != TASK_TYPE_TEXT_FIELD ? (
                        <MultiSelect
                          id={questionDetails && questionDetails.id}
                          name={questionDetails && questionDetails.id}
                          label={questionDetails && questionDetails.label}
                          options={questionDetails && questionDetails.tagList}
                          onInputChange={e =>
                            this.ConditionalFieldHandleChange(
                              questionDetails,
                              index,
                              e
                            )
                          }
                          required={
                            questionDetails.isRequired === true ? true : false
                          }
                        />
                      ) : questionDetails.type == TASK_TYPE_NUMBER_FIELD ? (
                        <Number
                          id={questionDetails && questionDetails.id}
                          name={questionDetails && questionDetails.id}
                          label={questionDetails && questionDetails.label}
                          error={""}
                          required={
                            questionDetails.isRequired === true ? true : false
                          }
                        />
                      ) : questionDetails.type == TASK_TYPE_CURRENCY_FIELD ? (
                        <Currency
                          id={questionDetails && questionDetails.id}
                          name={questionDetails && questionDetails.id}
                          label={questionDetails && questionDetails.label}
                          minValue={0}
                          required={
                            questionDetails.isRequired === true ? true : false
                          }
                        />
                      ) : (
                        ""
                      )}
                    </>
                  </>
                ))}

              <hr />
            </div>
            {/* Notes */}
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <TextArea
                  id="notes"
                  name="notes"
                  label="Notes"
                  placeholder={`Your ${Terminology.get(
                    SystemName.REFERRAL
                  )} Notes...`}
                  maxLength={255}
                  rows="4"
                />
              </div>
            </div>
            {/* ----------------- */}
            {/* Customer Readiness */}
            {isTrue(enableCustomerReadiness) && isNotEmpty(permisionList) ? (
              <>
                <div ref={this.customerReadinessRef}>
                  <Label required={true}>Customer Readiness</Label>
                </div>
                <div className="field-wrapper ml-4 mb-1">
                  <div className="form-group">
                    <div>
                      <RadioButton
                        id="customerReadiness"
                        name="customerReadiness"
                        options={this.getNameById(
                          customerReadinessOptions,
                          readinessOptions
                        )}
                      />
                    </div>
                  </div>
                </div>
              </>
            ) : (
              ""
            )}
            {this.props.publicReferralForm && this.state.reCaptchaSiteKey && (
              <div className="field-wrapper row mt-3">
                <ReCaptcha
                  ref={ref => (this.reCaptcha = ref)}
                  sitekey={this.state.reCaptchaSiteKey}
                  action="submit"
                  verifyCallback={verify =>
                    verify && this.setState({ isUserVerified: true })
                  }
                />
                <div className="col-lg-12">
                  <Button
                    id="submitReferralButton"
                    label={`Submit  ${Terminology.get(SystemName.REFERRAL)}`}
                    type="submit"
                    className="btn btn-primary"
                  />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(ReferralFormFields);
