import React from "react";
import isEmail from "validator/lib/isEmail";

class Email {
  /**
   * Is data Empty
   * @param data
   */
  static isValid = data => {
    if (data && !isEmail(data.trim())) {
      return true;
    } else return null;
  };
}

export default Email;
