import React from "react";
import { endpoints } from "../../../../configs";
import { apiClient } from "../../../../apiClient";
import UserList from "./inner-components/userList";

// Helper
import { isLoggedIn } from "../../../../lib/helper";

//Common
import { isBadRequest } from "../../../../common/http";

class users extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: false,
      permissionList: [],
      userRoleList: []
    };
  }

  componentDidMount() {
    this._getUserRoleDetails();
    // Check is logged in user
    isLoggedIn();
  }
  // Get User Role page api call
  _getUserRoleDetails() {
    this.setState({ isLoading: true });
    apiClient
      .get(`${endpoints().userRoleAPI}/search`)
      .then(response => {
        const userRoleList = response.data.data;

        this.setState({
          userRoleList: userRoleList.name,
          isLoading: false
        });
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({
            isLoading: false
          });
        }
      });
  }

  render() {
    const { activeSection, history } = this.props;
    const roleformId = activeSection;
    return (
      <>
        <UserList
          supportPortal={true}
          history={this.props.history}
          match={this.props.match}
          settings={this.props.settings}
        />
      </>
    );
  }
}

export default users;
