import React from "react";
import { Nav, NavItem } from "reactstrap";

// Component
import SideNavBarItem from "./SideNavBarItem";

// Constant
import { getSupportPortalsNavList } from "../../../supportPortal/Constants";

import { useSelector } from "react-redux";

const SideNavBar = props => {
  const { activeTab, handleClick } = props;

  const currentUser = useSelector(state => state.user);

  const supportPortalNavList = getSupportPortalsNavList(
    currentUser && currentUser.roleId
  );

  // Return Support Portal Nav List
  return (
    <Nav tabs vertical pills>
      <NavItem className="p-3 pt-4">
        <h5 className="text-left font-weight-bold">Support Portal</h5>
      </NavItem>

      {/* Nav List Start */}
      {supportPortalNavList &&
        supportPortalNavList.length > 0 &&
        supportPortalNavList.map((supportPortalNavItem, key) => (
          <SideNavBarItem
            key={key}
            activeTab={activeTab}
            tabName={supportPortalNavItem.name}
            defaultSubTab={supportPortalNavItem.defaultSubTab}
            handleClick={handleClick}
          />
        ))}
      {/* Nav List End */}
    </Nav>
  );
};

export default SideNavBar;
