//App Tab
export const TAB_BASICS = "Basics";
export const TAB_DETAILS = "Details";
export const TAB_PRICING = "Pricing";
export const TAB_SITES = "Sites";
export const TAB_FAQ = "faq";
export const TAB_VERSIONS = "Versions";
export const TAB_ACTIVIY = "Activity";

export const APP_STATUS_DRAFT = "Draft";
export const APP_STATUS_IN_REVIEW = "In Review";
export const APP_STATUS_APPROVED = "Approved";
export const APP_STATUS_REJECTED = "Rejected";
export const APP_STATUS_DECLINED = "Declined";
export const APP_STATUS_SUSPENDED = "Suspended";

export const TAB_GALLERY = "Gallery";

//Media
export const MEDIA_APP_PHOTO = "APP_PHOTO";
export const MEDIA_APP_VIDEO = "APP_VIDEO";
export const MEDIA_CERTIFICATE_PHOTO = "CERTIFICATE_PHOTO";

//Visibility
export const APP_VISIBILITY_LIVE = "Live";
export const APP_VISIBILITY_DISABLED = "Disabled";

//type
export const APP_TYPE_PUBLIC = "Public";
export const APP_TYPE_PRIVATE = "Private";

//App Errors
export const APP_404_ERROR = 404;
export const APP_400_ERROR = 400;
