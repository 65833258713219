export const LEAD_SOURCE_FIND_AN_EXPERT = "Find An Expert";
export const LEAD_SOURCE_PARTNER_CONTACT = "Partner Contact";
export const LEAD_SOURCE_PLAYBOOK_INQUIRY = "Playbook Inquiry";

export const LEAD_PARTNER_STATUS_PENDING = "Pending";
export const LEAD_PARTNER_STATUS_ACCEPTED = "Accepted";
export const LEAD_PARTNER_STATUS_DECLINED = "Declined";
export const LEAD_PARTNER_STATUS_SHORTLISTED = "Shortlisted";
export const LEAD_PARTNER_STATUS_CHOSEN = "Chosen";

export const LEAD_STATUS_ACCEPT = "Accept";
export const LEAD_STATUS_IN_PROGRESS = "In Progress";
export const LEAD_STATUS_CLOSE = "Close";
