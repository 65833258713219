import React, { useEffect, useState } from "react";

// Configs
import { endpoints } from "../../../../configs";

import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";

import { Link } from "react-router-dom";

// Helper
import { getFullName } from "../../../../lib/helper";

import { formatCurrency } from "../../../../helpers/currency";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

const ReferralTab = props => {
  return (
    <>
      <ReduxTable
        onScroll
        id="allReferrals"
        apiURL={`${endpoints().referralApi}/search`}
        message={`You can track the ${Terminology.get(
          SystemName.REFERRALS
        )} you have submitted here`}
        history={props.history}
      >
        <ReduxColumn
          width={"140px"}
          type="link"
          isClickable="false"
          field="account_name"
          sortBy="account_name"
          renderField={row => (
            <Link to={`/support-portal/referral/${row.id}`}>
              {row.account_name}
            </Link>
          )}
        >
          Account
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="name"
          sortBy="first_name"
          renderField={row => (
            <span>{getFullName(row.first_name, row.last_name)}</span>
          )}
        >
          Contact
        </ReduxColumn>
        <ReduxColumn
          width={"110px"}
          sortBy="submitted_by"
          field="submitted_user_name"
        >
          Submitted By
        </ReduxColumn>
        <ReduxColumn Width={"105px"} sortBy="status" field="status">
          Status
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          sortBy="amount"
          field="amount"
          className="text-right"
          renderField={row => (
            <span>
              {row.amount != null ? `$${formatCurrency(row.amount)}` : ""}
            </span>
          )}
        >
          Amount
        </ReduxColumn>
        <ReduxColumn
          width={"135px"}
          field="contact_email"
          sortBy="contact_email"
        >
          Contact Email
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="contact_phone"
          sortBy="Contact Phone"
        >
          Contact Phone
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="notes" sortBy="notes">
          Notes
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="commission_percentage"
          sortBy="commission_percentage"
        >
          commission_percentage
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="commission_amount"
          sortBy="commission_amount"
        >
          commission_amount
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="submitted_user_name"
          sortBy="submitted_user_name"
        >
          submitted_user_name
        </ReduxColumn>
        <ReduxColumn
          minWidth={"105px"}
          field="salesforce_opportunity_id"
          sortBy="salesforce_opportunity_id"
        >
          Salesforce Opportunity Id
        </ReduxColumn>

        <ReduxColumn
          width={"105px"}
          field="salesforce_account_id"
          sortBy="salesforce_account_id"
        >
          Salesforce Account Id
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="salesforce_contact_id"
          sortBy="salesforce_contact_id"
        >
          Salesforce Contact Id
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="partner_salesforce_account_id:"
          sortBy="partner_salesforce_account_id:"
        >
          Partner Salesforce Account Id:
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="partner_salesforce_contact_id"
          sortBy="partner_salesforce_contact_id"
        >
          Partner Salesforce Contact Id
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="owner_salesforce_id"
          sortBy="owner_salesforce_id"
        >
          Owner Salesforce Id
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="stage" sortBy="stage">
          Stage
        </ReduxColumn>
        <ReduxColumn
          width={"105px"}
          field="submitted_from"
          sortBy="submitted_from"
        >
          Submitted From
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="createdAt" sortBy="createdAt">
          createdAt
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="updatedAt" sortBy="updatedAt">
          updatedAt
        </ReduxColumn>
        <ReduxColumn width={"105px"} field="companyName" sortBy="companyName">
          Partner company Name
        </ReduxColumn>
      </ReduxTable>
    </>
  );
};

export default ReferralTab;
