import React from "react";

// Assets
import { CreditCardIcon } from "../assets/img/icons";

// Components
import WithDefaultWrapper from "./WithDefaultWrapper";
import AddPaymentOption from "../../src/views/editPartnerProfiles/inner-components/paymentOptions/AddPaymentOptionModal";
// constans
import {
  PAYMENT_OPTION_ACH,
  PAYMENT_OPTION_WIRE_TRANSFER,
  PAYMENT_OPTION_INTERNATIONAL
} from "../Constants/PaymentOptions";

class AddPaymentOptions extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      paymentType: ""
    };
  }

  // Toggle modal
  ToggleModal = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleChange = e => {
    let type = e.value;
    this.setState({ paymentType: type });
  };

  render() {
    const { isOpen, paymentType } = this.state;
    return (
      <>
        <AddPaymentOption
          isOpen={isOpen}
          paymentTypes={paymentType}
          Toggle={this.ToggleModal}
          paymentMethodSubmit={this.props.paymentMethodSubmit}
        />
        <WithDefaultWrapper title="Add Payment Options">
          <div className="payment-options mt-4">
            <div className="payment-option">
              <CreditCardIcon />
              <div>
                <button
                  className={["btn", "btn-plain", "btn-link"].join(" ")}
                  onClick={() => {
                    this.ToggleModal();
                    this.setState({ paymentType: PAYMENT_OPTION_ACH });
                  }}
                >
                  Add ACH
                </button>
              </div>
            </div>
            <div className="payment-option">
              <CreditCardIcon />
              <div>
                <button
                  style={{ width: "max-content" }}
                  className={["btn", "btn-plain", "btn-link"].join(" ")}
                  onClick={() => {
                    this.ToggleModal();
                    this.setState({
                      paymentType: PAYMENT_OPTION_WIRE_TRANSFER
                    });
                  }}
                >
                  Add Wire Transfer
                </button>
              </div>
            </div>
            <div className="payment-option">
              <CreditCardIcon />
              <div>
                <button
                  style={{ width: "max-content" }}
                  className={["btn", "btn-plain", "btn-link"].join(" ")}
                  onClick={() => {
                    this.ToggleModal();
                    this.setState({
                      paymentType: PAYMENT_OPTION_INTERNATIONAL
                    });
                  }}
                >
                  Add International
                </button>
              </div>
            </div>
          </div>
        </WithDefaultWrapper>
      </>
    );
  }
}

export default AddPaymentOptions;
