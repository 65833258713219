import React from "react";
import moment from "moment";

import toast from "../components/base/Toast";

// Helper
import {
  removeMaskedPhoneNumber,
  getKeyValueByObject,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  isPartner
} from "../lib/helper";

// Configs
import { endpoints } from "../configs";

// 404 Page Redirect

import Page404 from "../views/Page404";

// API call
import { apiClient } from "../apiClient";

// Components
import { ChevronRight } from "../assets/img/icons";
import Form from "../components/base/Form";
import ReferralFormFields from "../views/referral/ReferralFormFields";
import CancelButton from "../components/base/CancelButton";
import PageTitle from "../components/base/PageTitle";
import AdminActionNavigation from "../components/base/AdminActionNavigation";
import Spinner from "../components/base/Spinner";
import DeleteButton from "../components/base/DeleteButton";
import DeleteModal from "../components/base/DeleteModal";
import MessageDialog from "../views/message/MessageDialog";
import SaveButton from "../components/base/SaveButton";

// Inner Components
import PaymentSection from "../views/referral/inner-components/PaymentSection";
import ReferralClosedBanner from "../views/referral/inner-components/ReferralClosedBanner";
import SideBar from "./Components/SideBar";
import ReferralDeclineModal from "../views/referral/inner-components/ReferralDeclineModal";

// Constants
import {
  SETTINGS_ENABLE_PARTNERS,
  SETTINGS_ENABLE_REFERRAL_PAYMENTS,
  SETTINGS_SALESFORCE_TERRITORY_FIELD_NAME,
  SETTINGS_ENABLE_INVOICE_SETTINGS,
  SETTINGS_ENABLE_REFERRAL_PAYMENT_TRACKING,
  SETTINGS_ENABLE_REFERRAL_ADDITIONAL_CONTACTS,
  SETTINGS_HUBSPOT_REFRESH_TOKEN,
  SALESFORCE_REFRESH_TOKEN,
  SETTINGS_ENABLE_HUBSPOT_SYNC_REFERRAL,
  SETTINGS_SHOW_REFERRAL_AMOUNT
} from "../setting/Constants";
import {
  REFERRAL_STATUS_NEW,
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_DECLINED,
  REFERRAL_STATUS_CLOSED_WON,
  CUSTOMER_READINESS_READY_TEXT,
  CUSTOMER_READINESS_NOT_READY_TEXT,
  CUSTOMER_READINESS_NOT_READY,
  CUSTOMER_READINESS_READY,
  CUSTOMER_READINESS_PURCHASED_READY_TEXT,
  CUSTOMER_READINESS_PURCHASED_READY,
  REFERRAL_PARTNER_STATUS_PENDING,
  REFERRAL_SUBMITTED_FROM_LINK_TRACKING,
  REFERRAL_SUBMITTED_FROM_SALESFORCE,
  Referral
} from "./Constants";
import { INVOICE_STATUS_PAID } from "../invoice/Constants";
import SelectDropdown from "../components/base/SelectDropdown";
import { isBadRequest } from "../common/http";

//Salesforce Banners
import SalesforceProspectAccountToggle from "./Components/Banner/SalesforceBanner/ProspectAccountToggle";
import SalesforcePartnerAccountToggle from "./Components/Banner/SalesforceBanner/PartnerAccountToggle";
import SalesforceTaskCompletedToggle from "./Components/Banner/SalesforceBanner/TaskCompletedToggle";
import SalesforceOpportunityConflictToggle from "./Components/Banner/SalesforceBanner/OpportunityConflictToggle";
import SalesforceTerritoryAssignmentToggle from "./Components/Banner/SalesforceBanner/TerritoryAssignmentToggle";
import SalesforceNoConflictNotificationToggle from "./Components/Banner/SalesforceBanner/NoConflictNotificationToggle";
import SalesforceOpportunityOwnerToggle from "./Components/Banner/SalesforceBanner/OpportunityOwnerToggle";
import SalesforceReferralSourceBanner from "./Components/Banner/SalesforceBanner/ReferralSourceBanner";

//Salesforce Modals
import SalesforceProspectAccountModal from "./Components/Modal/SalesforceModal/ProspectAccountModal";
import SalesforcePartnerAccountModal from "./Components/Modal/SalesforceModal/PartnerAccountModal";
import SalesforceProspectInspectModal from "./Components/Modal/SalesforceModal/ProspectInspectModal";
import SalesforcePartnerAccountInspectModal from "./Components/Modal/SalesforceModal/ParnterAccountInspectModal";
import SalesforceAccountConfirmModal from "./Components/Modal/SalesforceModal/AccountSelectionModal";
import SalesforceApproveConfirmationModal from "./Components/Modal/SalesforceModal/ApproveConfirmationModal";
import SalesforcePaymentMadeModal from "./Components/Modal/SalesforceModal/PaymentMadeModal";
import SalesforceOwnerSelectionModal from "./Components/Modal/SalesforceModal/OwnerSelectionModal";
import ReferralConflictModal from "./Components/Modal/SalesforceModal/ReferralConflictModal";

//Hubspot Banner
import HubSpotProspectAccountToggle from "./Components/Banner/HubspotBanner/ProspectAccountToggle";
import HubSpotPartnerAccountToggle from "./Components/Banner/HubspotBanner/PartnerAccountToggle";
import NoHubSpotDealConflictNotificationToggle from "./Components/Banner/HubspotBanner/NoConflictNotificationToggle";
import DealsConflictToggle from "./Components/Banner/HubspotBanner/DealConflictToggle";

//Hubspot Modal
import HubSpotProspectAccountModal from "./Components/Modal/HubSpotModal/ProspectAccountModal";
import HubSpotPartnerAccountModal from "./Components/Modal/HubSpotModal/PartnerAccountModal";
import HubSpotPartnerAccountInspectModal from "./Components/Modal/HubSpotModal/PartnerAccountInspectModal";
import HubSpotProspectAccountInspectModal from "./Components/Modal/HubSpotModal/ProspectAccountInspectModal";
import HubSpotOwnerSelectionModal from "./Components/Modal/HubSpotModal/OwnerSelectionModal";

import HubSpotCompanyConfirmationModal from "./Components/Modal/HubSpotModal/AccountSelectionModal";

import * as API from "./actions";

import PartnerAcceptReffalAlert from "../components/PartnerAcceptReferralAlert";
import {
  TASK_TYPE_DROP_DOWN_FIELD,
  TASK_TYPE_TEXT_FIELD,
  TASK_TYPE_DATE_FIELD,
  TASK_TYPE_WEBSITE_FIELD,
  TASK_TYPE_CURRENCY_FIELD,
  TASK_TYPE_NUMBER_FIELD
} from "../modules/task/Constant";
import PaymentSuccessSection from "../views/payment/inner-components/PaymentSuccessSection";

//Loader
import LoadingOverlay from "react-loading-overlay";
import { BeatLoader } from "react-spinners";

import Button from "../components/base/Button";
import { COLOR_DANGER, COLOR_SUCCESS } from "../common/color";
import Terminology from "../lib/Terminology";
import SystemName from "../Constants/SystemName";
import { COMPANY_ADMIN_ROLE, COMPANY_MANAGER_ROLE } from "../roles/Constants";
import Users from "../helpers/User";
import { TAG_TYPE_CURRENCY } from "../tagType/Constants";
import { connect } from "react-redux";
import CommissionModel from "./Components/CommissionModel";

class EditReferral extends React.Component {
  state = {
    referralDetails: "",
    isApprove: false,
    isLoading: true,
    referralId: this.props.match.params.id,
    isReferralUpdate: false,
    closeDate: "",
    partnerTierCommissionPercentage: [],
    salesforceAccountDetails: "",
    isSalesforceLoading: true,
    isPageNotFound: false,
    accountId: "",
    defaultAccountSearchTerm: "",
    currentAccountSearchTerm: "",
    selectedSalesforceAccount: "",
    isSelectedSalesforceAccountConfirmed: false,
    isAccountSelected: false,
    showPayInvoice: false,
    isPayingBill: false,
    paymentSuccess: false,
    isWaitingMfaOTP: false,
    isAuthenticating: false,
    isPaymentProcessing: false,
    isInvoicePaid: false,
    isPaymentSectionPopupOpen: false,
    isButtonDisabled: false,
    isMarkPaymentMadeModal: false,
    paymentDate: "",
    isSendPaymentEmailToPartner: false,
    questionList: [],
    questionValue: [],
    questionListValue: [],
    deleteReferralModal: false,
    deleteReferral: false,
    accountType: "",
    refferalPartnerUser: "",
    overlayLoader: false,
    defaultStatusApprove: false,
    reassignfun: false,
    reAssignValue: "",
    salesforceUsersList: "",
    ownerSelectionModalOpen: false,
    enableOpportunityOwnerExistAccount: "",
    enableOpportunityOwnerNewAccount: "",
    enableSalesRouting: "",
    EnableReferralPaymentTracking: "",
    ownerAccountBanner: false,
    referralDeclineModal: false,
    dateField: "",
    paymentId: "",
    invoiceDetails: "",
    enableInvoiceSettings: "",
    referralSourceBanner: true,
    contactArray: [],
    isHubSpotConnected: false,
    isSalesforceConnected: false,
    isApproveConfirmationModalOpen: false,
    referralConflictModalOpen: false,
    userList: [],
    currencyList: [],
    showAmoutField: "",
    commissionModel: false,

    //salesforce State values

    //Banner states
    isNoSalesforceProspectAccountFoundNotification: false,
    isNoSalesforcePartnerAccountFoundNotification: false,
    isTerrirtoryAssignmentNotification: false,

    //conflict notification
    isNoSalesforceConflictsNotification: false,
    isSalesforceOppOppotunityConflictNotification: false,

    //Modal States  values
    isNoSalesforceProspectAccountFoundModalOpen: false,
    isNoSalesforcePartnerAccountFoundModalOpen: false,

    isSalesforceProspectAccountSelectModalOpen: false,
    isSalesforcePartnerAccountSelectModalOpen: false,

    //account select modal states
    isPartialMatchConfirmationModalOpen: false,

    //HubSpot state values
    isHubSpotProspectAccountNotFoundNotification: false,
    isHubSpotPartnerAccountNotFoundNotification: false,

    //Modal state values
    isNoHubSpotProspectAccountFoundModalOpen: false,
    isNoHubSpotPartnerAccountFoundModalOpen: false,

    isHubSpotProspectAccountSelectModalOpen: false,
    isHubSpotPartnerAccountSelectModalOpen: false,

    currentHubSpotPartnerCompanySearchTerm: "",
    currentHubSpotProspectCompanySearchTerm: "",
    hubSpotSearchCompanyOptions: [],
    isHubSpotPartialMatchConfirmationModalOpen: false,
    selectedHubSpotCompany: "",

    defaultHubSpotPartnerCompanySearchTerm: "",
    defaultHubSpotProspectCompanySearchTerm: "",

    hubSpotCompanyType: "",
    isSelectedHubSpotCompanyConfirmed: false,

    hubSpotProspectCompanyDetail: "",
    hubSpotPartnerCompanyDetail: "",
    hubSpotDetail: "",

    isHubSpotLoading: false,
    isHubSpotNoDealConflictNotification: false,

    EnableHubSpotSalesRouting: "",
    EnableDealOwnerForExistingProspectCompany: "",
    EnableDealForNewProspectCompany: "",
    hubspotOwnerSelectionModalOpen: false,
    hubspotUserList: [],
    enableHubspotSyncReferral: false,
    conflictReferralList: [],
    settingsList: this.props.settings
  };

  componentDidMount() {
    this.getSettingsData(SETTINGS_ENABLE_INVOICE_SETTINGS);
    this.getSettingsData(SETTINGS_ENABLE_HUBSPOT_SYNC_REFERRAL);
    this.getSettingsData(SETTINGS_SHOW_REFERRAL_AMOUNT);
    this.isCRMConnected();
    this._getPartnerTierCommissionPercentage();
    this.getUsersList();
    this.getTagValues(TAG_TYPE_CURRENCY);
  }

  isCRMConnected = async () => {
    await this.getSettingsData(SETTINGS_HUBSPOT_REFRESH_TOKEN);
    await this.getSettingsData(SALESFORCE_REFRESH_TOKEN);

    this.getReferralDetails();
    this.getSalesforceUserDetails();
    this.getHubSpotCompanyDetails();
  };

  getSalesforceUserDetails = async () => {
    API.getSalesforceUserDetails(data => {
      if (data) {
        this.setState({ salesforceUsersList: data });
      }
    });
  };

  getTagValues = async tagType => {
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${tagType}`
      );
      const results = response.data.data;

      // Check the tag type skills
      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            value: tag.id,
            label: tag.name
          });
        });

        this.setState({
          currencyList: tagList
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };
  getHubSpotCompanyDetails = async () => {
    try {
      if (
        this.state.isHubSpotConnected &&
        this.state.enableHubspotSyncReferral
      ) {
        this.setState({ isHubSpotLoading: true }, () => {
          API.getHubSpotCompanyDetails(this.props.match.params.id, response => {
            if (response && response.data) {
              let hubSpotCompanyDetail = response.data;

              this.setState({ hubSpotDetail: hubSpotCompanyDetail });

              if (hubSpotCompanyDetail.partnerCompanyCount == 0) {
                this.setState({
                  isHubSpotPartnerAccountNotFoundNotification: true
                });
              } else {
                this.setState({
                  isHubSpotPartnerAccountNotFoundNotification: false
                });
              }

              if (hubSpotCompanyDetail.prospectCompanyCount == 0) {
                this.setState({
                  isHubSpotProspectAccountNotFoundNotification: true
                });
              } else {
                this.setState({
                  isHubSpotProspectAccountNotFoundNotification: false
                });
              }

              if (hubSpotCompanyDetail.prospectCompanyDetail) {
                this.setState({
                  hubSpotProspectCompanyDetail:
                    hubSpotCompanyDetail.prospectCompanyDetail
                });
              }

              if (hubSpotCompanyDetail.partnerCompanyDetail) {
                this.setState({
                  hubSpotPartnerCompanyDetail:
                    hubSpotCompanyDetail.partnerCompanyDetail
                });
              }

              if (
                hubSpotCompanyDetail.dealCount === 0 &&
                !this.state.referralDetails.hubspot_deal_id &&
                hubSpotCompanyDetail.prospectCompanyCount > 0
              ) {
                this.setState({
                  isHubSpotNoDealConflictNotification: true
                });
              } else {
                this.setState({
                  isHubSpotNoDealConflictNotification: false
                });
              }

              if (
                hubSpotCompanyDetail &&
                hubSpotCompanyDetail.dealCount > 0 &&
                this.state.referralDetails &&
                !this.state.referralDetails.hubspot_deal_id
              ) {
                this.setState({
                  isHubSpotDealConflictNotification: true
                });
              } else {
                this.setState({
                  isHubSpotDealConflictNotification: false
                });
              }
            }

            this.setState({
              overlayLoader: false,
              isHubSpotLoading: false
            });
          });
        });
      } else {
        this.setState({
          overlayLoader: false,
          isHubSpotLoading: false
        });
      }
    } catch (err) {
      this.setState({
        overlayLoader: false,
        isHubSpotLoading: false
      });
    }
  };

  getUsersList = async () => {
    let userData = [];
    let roleId = `${COMPANY_MANAGER_ROLE},${COMPANY_ADMIN_ROLE}`;
    await Users.search(roleId, list => {
      for (let i = 0; i < list.length; i++) {
        userData.push({
          label: list[i].name,
          value: list[i].id
        });
      }
    });
    this.setState({ userList: userData });
  };

  // Get Salesforce Account Details start
  getSalesforceAccountDetails = async referralId => {
    if (referralId && this.state.isSalesforceConnected) {
      API.SalesforceAccountDetails(referralId, response => {
        if (response && response.data) {
          this.setState({
            salesforceAccountDetails: response.data,
            isSalesforceLoading: false,
            isTerrirtoryAssignmentNotification: true,
            isApprove: false
          });

          if (response.data && response.data.partnerAccountCount === 0) {
            this.setState({
              isNoSalesforcePartnerAccountFoundNotification: true
            });
          } else {
            this.setState({
              isNoSalesforcePartnerAccountFoundNotification: false
            });
          }
          if (response.data.accountCount === 0) {
            this.setState({
              isNoSalesforceProspectAccountFoundNotification: true
            });
          } else {
            this.setState({
              isNoSalesforceProspectAccountFoundNotification: false
            });
          }

          if (
            response.data.opportunityList &&
            response.data.opportunityList.length > 0 &&
            this.state.referralDetails &&
            !this.state.referralDetails.salesforce_opportunity_id
          ) {
            this.setState({
              isSalesforceOppOppotunityConflictNotification: true
            });
          } else {
            this.setState({
              isSalesforceOppOppotunityConflictNotification: false
            });
          }
          if (
            response.data.opportunityList.length === 0 &&
            !this.state.referralDetails.salesforce_opportunity_id &&
            response.data.accountCount > 0
          ) {
            this.setState({
              isNoSalesforceConflictsNotification: true
            });
          } else {
            this.setState({
              isNoSalesforceConflictsNotification: false
            });
          }
          this.checkAccountDetails();

          this.setState({
            isLoading: false,
            isSalesforceLoading: false,
            overlayLoader: false
          });
        } else {
          this.setState({
            isLoading: false,
            isSalesforceLoading: false,
            overlayLoader: false,
            isApprove: false
          });
        }
      });
    } else {
      this.setState({
        isLoading: false,
        isSalesforceLoading: false,
        overlayLoader: false,
        isApprove: false
      });
    }
  };
  getSettingsData = async settingValue => {
    try {
      return apiClient
        .get(`${endpoints().settingAPI}/${settingValue}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);
          const settings = response.data.data;

          if (settings) {
            if (settings.name === SETTINGS_ENABLE_INVOICE_SETTINGS) {
              this.setState({
                enableInvoiceSettings: settings.value
              });
            }

            if (settings.name === SALESFORCE_REFRESH_TOKEN) {
              if (settings.value) {
                this.setState({
                  isSalesforceConnected: true
                });
              }
            }

            if (settings.name === SETTINGS_HUBSPOT_REFRESH_TOKEN) {
              if (settings.value) {
                this.setState({
                  isHubSpotConnected: true
                });
              }
            }

            if (settings.name === SETTINGS_SHOW_REFERRAL_AMOUNT) {
              if (settings.value) {
                this.setState({
                  showAmoutField: settings.value
                });
              }
            }

            if (settings.name === SETTINGS_ENABLE_HUBSPOT_SYNC_REFERRAL) {
              if (settings.value == "true") {
                this.setState({
                  enableHubspotSyncReferral: true
                });
              }
            }
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  //Get Referral Details function start
  getReferralDetails = async () => {
    this.setState({ isApprove: true });
    API.getReferralDetails(this.props.match.params.id, async response => {
      let questionList = [],
        questionValue = [];
      let contactList = [];
      if (response && response.data && response.data.data) {
        if (response.data.data.values && response.data.data.values.length > 0) {
          response.data.data.values.forEach(questionDetails => {
            if (
              isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId) ||
              (isPartner(this.props.roleId) && questionDetails.showToPartner)
            ) {
              questionList.push(questionDetails);
            }
          });
        }
        if (response.data.data && response.data.data.contactList) {
          this.setState({ contactArray: [] });
          let list = response.data.data.contactList;
          list &&
            list.map(value => {
              let dataList = [];
              dataList.push(value);
              let contactData = this.state.contactArray.concat(dataList);
              contactList.push(contactData);
            });
        }
        this.partnerPaymentDetails(response.data.data.partner_id);
        if (
          response.data.data.questionList &&
          response.data.data.questionList.length > 0
        ) {
          response.data.data.questionList.forEach(value => {
            if (
              isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId) ||
              (isPartner(this.props.roleId) && value.show_to_partner)
            ) {
              questionValue.push(value);
            }
          });
        }

        this.setState({
          referralDetails: response.data.data,
          enableOpportunityOwnerExistAccount:
            response.data.data.enableOpportunityForExisitAccount,
          enableOpportunityOwnerNewAccount:
            response.data.data.enableOpportunityForNewAccount,
          enableSalesRouting: response.data.data.enableSalesRouting,
          EnableReferralPaymentTracking:
            response.data.data.EnableReferralPaymentTracking,
          referralId: response.data.data.id,
          refferalPartnerUser: response.data.data.partnerUser || "",
          questionList: questionList,
          questionValue: questionValue,
          questionListValue:
            response.data && response.data.data.questionListValue,
          defaultAccountSearchTerm: response.data.data.account_name.split(
            " "
          )[0],
          closeDate:
            response.data.data.close_date &&
            new Date(response.data.data.close_date),
          ownerAccountBanner: response.data.data.owner_salesforce_id
            ? false
            : true,
          contactArray: contactList,
          defaultHubSpotProspectCompanySearchTerm: response.data.data.account_name.split(
            " "
          )[0],
          defaultHubSpotPartnerCompanySearchTerm:
            response.data.data.partner &&
            response.data.data.partner.company_name,
          EnableHubSpotSalesRouting:
            response.data.data.EnableHubSpotSalesRouting,
          EnableDealOwnerForExistingProspectCompany:
            response.data.data.EnableDealOwnerForExistingProspectCompany,
          EnableDealForNewProspectCompany:
            response.data.data.EnableDealForNewProspectCompany,
          hubspotUserList: response.data.data.hubspotUserList
        });
        if (
          response.data.data &&
          response.data.data.submitted_from !=
            REFERRAL_SUBMITTED_FROM_LINK_TRACKING
        ) {
          this.getSalesforceAccountDetails(this.props.match.params.id);
        } else {
          this.setState({ isLoading: false });
        }
        if (this.state.referralDetails.invoice_id) {
          this.getInvoiceDetails(this.state.referralDetails.invoice_id);
        }
      } else {
        this.setState({ isLoading: false, isPageNotFound: true });
      }
    });
  };

  ApproveReferral = (status, isConflictFlag) => {
    let data = {};
    if (status === REFERRAL_STATUS_APPROVED) {
      data.status = REFERRAL_STATUS_APPROVED;
      this.setState({ isApprove: true });
    } else {
      data.status = status;
    }
    this.UpdateStatus(data.status, isConflictFlag);
  };

  // Update Declined Status
  updateReferralStatus(status, id, declineMessage, declineNotification) {
    API.declineReferral(
      id,
      status,
      declineMessage,
      declineNotification,
      response => {}
    );

    this.getReferralDetails();
  }

  UpdateStatus = (status, isConflictFlag) => {
    this.setState({
      overlayLoader: true
    });
    API.updateStatus(
      this.props.match.params.id,
      status,
      isConflictFlag,
      (referralList, response) => {
        if (response) {
          this.setState(
            {
              referralDetails: response.data,
              closeDate:
                response.data && response.data.close_date
                  ? new Date(response.data.close_date)
                  : "",
              isSalesforceLoading: true,
              isApprove: false,
              overlayLoader: false
            },
            () => {
              this.getReferralDetails(true);
              this.getHubSpotCompanyDetails();
            }
          );
        } else {
          this.setState({
            overlayLoader: false,
            isSalesforceLoading: false
          });

          if (referralList && referralList.length > 0) {
            this.setState({ conflictReferralList: referralList });
            this.toggleReferralConflictConfirmation();
          }

          this.getReferralDetails();
          this.getHubSpotCompanyDetails();
        }
      }
    );
  };

  updateOwner = (ownerId, setIsselectedOwner, isSalesforce) => {
    this.setState({ isSalesforceLoading: true });
    let ownerIdData = { ownerId: ownerId };
    API.updateOwner(
      this.props.match.params.id,
      ownerIdData,
      isSalesforce,
      response => {
        if (response) {
          setIsselectedOwner(true);
          this.setState({
            referralDetails: response.data,
            isSalesforceLoading: false,
            ownerAccountBanner: false
          });
        }
      }
    );
  };

  createAccount = AccountType => {
    this.setState({
      isButtonDisabled: true
    });
    const {
      isSelectedSalesforceAccountConfirmed,
      selectedSalesforceAccount
    } = this.state;
    const salesforceAccountId = selectedSalesforceAccount
      ? selectedSalesforceAccount.Id
      : "";
    const salesforceAccountName = selectedSalesforceAccount
      ? selectedSalesforceAccount.Name
      : "";

    if (
      isSelectedSalesforceAccountConfirmed === true &&
      salesforceAccountId &&
      salesforceAccountName
    ) {
      const data = {
        salesforceAccountId,
        salesforceAccountName,
        AccountType: AccountType
      };
      this.setState({
        isSalesforceLoading: true,
        overlayLoader: true
      });

      return apiClient
        .put(
          `${endpoints().referralApi}/create/account/${
            this.props.match.params.id
          }`,
          data
        )
        .then(() => {
          this.getSalesforceAccountDetails(this.props.match.params.id);
          this.getReferralDetails();
        })
        .catch(error => {
          if (isBadRequest(error)) {
            toast.error(error.response.data.message);
          }
          this.setState({
            isSalesforceLoading: false,
            overlayLoader: false
          });
        });
    }

    if (isSelectedSalesforceAccountConfirmed === false) {
      this.setState({
        isSalesforceLoading: true,
        overlayLoader: true
      });
      return apiClient
        .put(
          `${endpoints().referralApi}/create/salesforce/account/${
            this.props.match.params.id
          }`,
          { AccountType: AccountType }
        )
        .then(response => {
          this.getSalesforceAccountDetails(this.props.match.params.id, true);
        })
        .catch(error => {
          if (isBadRequest(error)) {
            toast.error(error.response.data.message);
          }
          this.setState({
            isSalesforceLoading: false,
            overlayLoader: false
          });
        });
    }
  };

  createHubSpotCompany = companyType => {
    try {
      const {
        selectedHubSpotCompany,
        isSelectedHubSpotCompanyConfirmed
      } = this.state;
      const HubSpotCompanyId = selectedHubSpotCompany
        ? selectedHubSpotCompany.value
        : "";
      const HubSpotCompanyName = selectedHubSpotCompany
        ? selectedHubSpotCompany.label
        : "";

      this.setState({
        overlayLoader: true,
        isHubSpotLoading: true
      });

      if (
        isSelectedHubSpotCompanyConfirmed === true &&
        HubSpotCompanyId &&
        HubSpotCompanyName
      ) {
        const data = {
          HubSpotCompanyId,
          HubSpotCompanyName,
          companyType: companyType
        };

        return apiClient
          .put(
            `${endpoints().referralApi}/create/hubspot/account/${
              this.props.match.params.id
            }`,
            data
          )
          .then(() => {
            this.getHubSpotCompanyDetails();
            this.getReferralDetails();
          })
          .catch(error => {
            if (isBadRequest(error)) {
              toast.error(error.response.data.message);
            }
          });
      }

      if (isSelectedHubSpotCompanyConfirmed === false) {
        return apiClient
          .put(
            `${endpoints().referralApi}/create/hubspot/company/${
              this.props.match.params.id
            }`,
            { companyType: companyType }
          )
          .then(response => {
            this.getHubSpotCompanyDetails();
          })
          .catch(error => {
            if (isBadRequest(error)) {
              toast.error(error.response.data.message);
            }
          });
      }
    } catch (err) {
      this.setState({
        overlayLoader: false,
        isHubSpotLoading: false
      });
      console.log(err);
    }
  };

  getInvoiceDetails = id => {
    API.InvoiceDetails(id, response => {
      if (response && response.data) {
        const invoiceDetails = response.data;
        if (invoiceDetails.paymentStatus === INVOICE_STATUS_PAID) {
          this.setState({
            isInvoicePaid: true,
            invoiceDetails: invoiceDetails,
            isLoading: false
          });
        }
      }
    });
  };

  // Handle form submit
  handleSubmit = values => {
    this.setState({ isSalesforceTasksLoading: true });
    API.UpdateReferralDetails(values, this.props.match.params.id, response => {
      this.setState({ isSalesforceTasksLoading: false });
      this.getReferralDetails();
    });
  };

  // Handle delete
  handleDelete = () => {
    API.deleteReferral(this.props.match.params.id, response => {
      if (response && response.data) {
        this.props.history.push("/referrals");
      }
    });
  };

  // Update Partner referral Status
  updatePartnerReferralStatus = (status, referralId) => {
    return apiClient
      .put(
        `${
          endpoints().referralPartnerAPI
        }/updateStatus?referralId=${referralId}&status=${status}`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          this.getReferralDetails();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          toast.error(error.response.data.message);
        }
      });
  };

  //Get Partner Tier Commission Percentage
  _getPartnerTierCommissionPercentage = () => {
    API.PartnerTierCommissionPercentage(response => {
      if (response && response.data) {
        this.setState({
          partnerTierCommissionPercentage: response.data
        });
      }
    });
  };

  //  Set Close Date
  setCloseDate = date => {
    this.setState({ closeDate: date });
  };

  setDatefieldDate = date => {
    this.setState({ dateField: date });
  };

  // Sync To Salesforce
  handleSyncToSalesforce = () => {
    this.setState({ isReferralSyncing: true, overlayLoader: true }, () => {
      API.handleSyncToSalesforce(this.props.match.params.id, resonse => {
        this.getReferralDetails(true);
        this.setState({ isReferralSyncing: false, overlayLoader: false });
      });
    });
  };

  // Sync From Salesforce
  handleSyncFromSalesforce = () => {
    this.setState({ isReferralSyncing: true, overlayLoader: true }, () => {
      API.handleSyncFromSalesforce(this.props.match.params.id, resonse => {
        this.getReferralDetails(true);
        this.setState({ isReferralSyncing: false, overlayLoader: false });
      });
    });
  };

  // Calculate Commission
  updatePaymentInfo = () => {
    this.setState({ isReferralSyncing: true, overlayLoader: true }, () => {
      API.updatePaymentInfo(this.props.match.params.id, resonse => {
        this.getReferralDetails(true);
        this.setState({ isReferralSyncing: false, overlayLoader: false });
      });
    });
  };
  // Calculate Commission
  updateCommission = () => {
    API.updateCommission(this.props.match.params.id, response => {
      this.getReferralDetails(true);
    });
  };

  // Handle Invoice Generate
  handleCreateInvoice = () => {
    this.setState({ isReferralSyncing: true, overlayLoader: true }, () => {
      API.handleCreateInvoice(this.props.match.params.id, resonse => {
        this.getReferralDetails(true);
        this.setState({ isReferralSyncing: false, overlayLoader: false });
      });
    });
  };

  //handle sync to hubspot
  handleSyncToHubSpot = () => {
    this.setState({ isHubSpotLoading: true, overlayLoader: true }, () => {
      API.handleSyncToHubSpot(this.props.match.params.id, resonse => {
        this.getHubSpotCompanyDetails();
        this.getReferralDetails(true);
      });
    });
  };

  //handle sync From hubspot
  handleSyncFromHubSpot = () => {
    if (this.state.referralDetails)
      this.setState({ isHubSpotLoading: true, overlayLoader: true }, () => {
        API.syncFromHubSpot(
          this.state.referralDetails.hubspot_deal_id,
          resonse => {
            this.getHubSpotCompanyDetails();
            this.getReferralDetails(true);
          }
        );
      });
  };

  salesforceAccountInspect = async searchTerm => {
    const search =
      searchTerm !== " " ? searchTerm : this.state.defaultAccountSearchTerm;
    let accountType = this.state.accountType;
    return await apiClient
      .get(
        `${
          endpoints().salesforceLookupAPI
        }/account/search?searchTerm=${encodeURIComponent(
          search
        )}&accountType=${accountType}`
      )
      .then(async response => {
        const { records } = response.data;
        let accountList = [];

        this.setState({
          currentAccountSearchTerm: search
        });
        if (records && records.length > 0) {
          records.forEach(accountDetails => {
            accountList.push({
              ...accountDetails,
              label: `${accountDetails.Name} - ${moment(
                accountDetails.CreatedDate
              ).format("MM/DD/YYYY")}`,
              value: accountDetails.Id
            });
          });
        } else {
          return [];
        }
        return accountList;
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          return [];
        }
      });
  };

  HubSpotCompanyInspect = async searchTerm => {
    let hubSpotCompanyOptions = new Array();

    return await apiClient
      .get(
        `${
          endpoints().hubSpotLookUpRoute
        }/company/search?searchTerm=${searchTerm}`
      )
      .then(response => {
        if (response && response.data && response.data.searchResult) {
          let searchResult = response.data.searchResult;
          if (
            searchResult &&
            Array.isArray(searchResult) &&
            searchResult.length > 0
          ) {
            for (let i = 0; i < searchResult.length; i++) {
              hubSpotCompanyOptions.push({
                label: searchResult[i].properties.name,
                value: searchResult[i].id
              });
            }
            return hubSpotCompanyOptions;
          } else {
            return [];
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  openProspectAccountModal = () => {
    let noProspectAccountModalOpen =
      this.state.salesforceAccountDetails &&
      this.state.salesforceAccountDetails.accountCount === 0
        ? true
        : false;
    this.setState({
      isNoSalesforceProspectAccountFoundModalOpen: noProspectAccountModalOpen
    });
  };

  openParterAccountModal = () => {
    let noPartnerAccountModalOpen =
      this.state.salesforceAccountDetails &&
      this.state.salesforceAccountDetails.partnerAccountCount === 0
        ? true
        : false;
    this.setState({
      isNoSalesforcePartnerAccountFoundModalOpen: noPartnerAccountModalOpen
    });
  };

  //side bar click open modal
  openHubSpotProspectCompanyModal = () => {
    let isNoHubSpotProspectAccountFoundModalOpen =
      this.state.hubSpotDetail &&
      this.state.hubSpotDetail.prospectCompanyCount === 0
        ? true
        : false;
    this.setState({
      isNoHubSpotProspectAccountFoundModalOpen: isNoHubSpotProspectAccountFoundModalOpen
    });
  };

  openHubSpotPartnerCompanyModal = () => {
    let isNoHubSpotPartnerAccountFoundModalOpen =
      this.state.hubSpotDetail &&
      this.state.hubSpotDetail.partnerCompanyCount === 0
        ? true
        : false;
    this.setState({
      isNoHubSpotPartnerAccountFoundModalOpen: isNoHubSpotPartnerAccountFoundModalOpen
    });
  };

  checkAccountDetails = () => {
    const { salesforceAccountDetails } = this.state;
    const accountDetails = salesforceAccountDetails.accountDetails;
    if (accountDetails && accountDetails.id) {
      this.setState({
        accountId: accountDetails.id
      });
    }
  };

  // Send Partner Message API Call
  sendMessageData = values => {
    const { referralDetails } = this.state;
    return apiClient
      .post(`${endpoints().referralApi}/sendMessage`, {
        message: values.message,
        referralDetails
      })
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);

        setTimeout(() => {}, 300);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Send Partner payment reminder API Call
  partnerPaymentReminder = () => {
    let id = this.state.referralDetails.partner_id;
    return apiClient
      .post(`${endpoints().referralApi}/paymentMethodReminder`, { id })
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Get Partner payment details
  partnerPaymentDetails = id => {
    return apiClient
      .get(`${endpoints().referralApi}/paymentDetails/${id}`)
      .then(response => {
        const data = response.data;
        this.setState({
          paymentId: data.paymentId
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.stringify(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Toggle Pay Invoice Button Dropdown
  togglePayInvoice = () => {
    this.setState({ showPayInvoice: !this.state.showPayInvoice });
  };

  // Toggle PaymentSection popup
  togglePaymentSection = () => {
    this.setState({
      isPaymentSectionPopupOpen: !this.state.isPaymentSectionPopupOpen
    });
  };

  // Toggle Mark Payment Made Modal
  toggleMarkPaymentMadeModal = () => {
    this.setState({
      isMarkPaymentMadeModal: !this.state.isMarkPaymentMadeModal,
      paymentDate: ""
    });
  };

  // Set Process Date
  setPaymentDate = date => {
    this.setState({ paymentDate: date });
  };

  // Toggle sending payment notification to partner
  toggleIsSendPaymentEmailToPartner = () => {
    this.setState({
      isSendPaymentEmailToPartner: !this.state.isSendPaymentEmailToPartner
    });
  };

  toggleApproveConfirmation = () => {
    this.setState({
      isApproveConfirmationModalOpen: !this.state.isApproveConfirmationModalOpen
    });
  };

  toggleReferralConflictConfirmation = () => {
    this.setState({
      referralConflictModalOpen: !this.state.referralConflictModalOpen
    });
  };

  // Set Payment As Made
  setPaymentAsMade = values => {
    this.setState({ isMarkPaymentMadeLoading: true }, () => {
      const id = this.state.referralDetails.id;
      const invoice_id = this.state.referralDetails.invoice_id;
      if (id && invoice_id) {
        values.invoice_id = invoice_id;
        apiClient
          .put(`${endpoints().referralApi}/markPaymentMade/${id}`, values)
          .then(response => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
            }
            this.setState({
              isMarkPaymentMadeLoading: false,
              isMarkPaymentMadeModal: false
            });
            toast.success(successMessage);
            this.getReferralDetails();
          })
          .catch(error => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              toast.error(error.response.data.message);
              console.error(errorMessage);
            }
          });
      }
    });
  };

  linkOpportunity = opportunityId => {
    this.setState({ overlayLoader: true });
    //get refeeral id
    const id = this.props.match.params.id;

    if (id && opportunityId) {
      apiClient
        .put(`${endpoints().referralApi}/linkOpportunity/${id}`, {
          opportunityId: opportunityId
        })
        .then(response => {
          this.setState({ overlayLoader: false });
          if (response && response.data && response.status == 200) {
            this.getReferralDetails();
            toast.success(response.data.message);
          }
        })
        .catch(error => {
          this.setState({ overlayLoader: false });
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    } else {
      this.setState({ overlayLoader: false });
    }
  };
  handleAdminAction = value => {
    if (value == "Create Invoice" || value == "Update Invoice") {
      this.setState({ reassignfun: false, reAssignValue: "" });
      this.handleCreateInvoice();
    } else if (value === "Update Payment Info") {
      this.setState({ reassignfun: false, reAssignValue: "" });
      this.updatePaymentInfo();
    } else if (value === "Update Commission") {
      this.updateCommission();
    } else if (value === "Sync From Salesforce") {
      this.setState({ reassignfun: false, reAssignValue: "" });
      this.handleSyncFromSalesforce();
    } else if (value == "Sync to HubSpot") {
      this.handleSyncToHubSpot();
    } else if (value == "Sync From HubSpot") {
      this.handleSyncFromHubSpot();
    } else if (value === "Sync To Salesforce") {
      this.setState({ reassignfun: false, reAssignValue: "" });
      this.handleSyncToSalesforce();
    } else if (value !== "Sync To Salesforce" && value === "Reassign Partner") {
      this.setState({ reassignfun: true, reAssignValue: value });
    } else if (value === "Delete") {
      this.setState({ deleteReferralModal: true });
    } else {
      let saleforcedetails = this.state.salesforceAccountDetails;
      if (saleforcedetails && saleforcedetails.accountDetails) {
        let instanceUrl = saleforcedetails.instanceUrl;
        let opportunityId = this.state.referralDetails
          .salesforce_opportunity_id;
        if (instanceUrl && opportunityId) {
          window.open(
            instanceUrl + "/lightning/r/" + opportunityId + "/view",
            "_blank"
          );
        }
      }
    }
  };

  setToggleValues = data => {
    this.setState({
      isNoSalesforceProspectAccountFoundModalOpen:
        data && data.isNoAccountFoundModalOpen,
      isNoSalesforceProspectAccountFoundNotification:
        data && data.isNoAccountFoundNotification,
      accountType: data.accountType,
      defaultStatusApprove: false
    });
  };

  setHubSpotProspectToggleValues = data => {
    this.setState({
      isNoHubSpotProspectAccountFoundModalOpen:
        data && data.isNoHubSpotProspectAccountFoundModalOpen,
      isHubSpotProspectAccountNotFoundNotification:
        data && data.isHubSpotProspectAccountNotFoundNotification
    });
  };

  setHubspotPartnerToggleValues = data => {
    this.setState({
      isNoHubSpotPartnerAccountFoundModalOpen:
        data && data.isNoHubSpotPartnerAccountFoundModalOpen,
      isHubSpotPartnerAccountNotFoundNotification:
        data && data.isHubSpotPartnerAccountNotFoundNotification
    });
  };

  setPartnerToggleValues = data => {
    this.setState({
      isNoSalesforcePartnerAccountFoundModalOpen:
        data && data.isNoPartnerAccountModalOpen,
      isNoSalesforcePartnerAccountFoundNotification:
        data && data.isNoPartnerAccountFoundInSalesforce,
      accountType: data.accountType,
      defaultStatusApprove: false
    });
  };

  setSalesforceProspectModalValues = data => {
    this.setState({
      isNoSalesforceProspectAccountFoundModalOpen:
        data.isNoAccountFoundModalOpen,
      isSalesforceProspectAccountSelectModalOpen:
        data && data.isInspectAccountModalOpen,
      accountType: data && data.accountType
    });
  };

  setSalesforcePartnerModalValues = data => {
    this.setState({
      isNoSalesforcePartnerAccountFoundModalOpen:
        data.isNoPartnerAccountModalOpen,
      isSalesforcePartnerAccountSelectModalOpen:
        data.partnerInspectAccountModalOpen,
      accountType: data.accountType
    });
  };

  setHubSpotProspectModalValues = data => {
    this.setState({
      isNoHubSpotProspectAccountFoundModalOpen:
        data.isNoHubSpotProspectAccountFoundModalOpen,
      isHubSpotProspectAccountSelectModalOpen:
        data.isHubSpotProspectAccountSelectModalOpen,
      hubSpotCompanyType: data.hubSpotCompanyType
    });
  };

  setHubSpotPartnerModalValues = data => {
    this.setState({
      isNoHubSpotPartnerAccountFoundModalOpen:
        data.isNoHubSpotPartnerAccountFoundModalOpen,
      isHubSpotPartnerAccountSelectModalOpen:
        data.isHubSpotPartnerAccountSelectModalOpen,
      hubSpotCompanyType: data.hubSpotCompanyType
    });
  };

  setSalesforceProspectInspectModalValues = data => {
    this.setState({
      isSalesforceProspectAccountSelectModalOpen:
        data.isSalesforceProspectAccountSelectModalOpen,
      currentAccountSearchTerm: data.currentAccountSearchTerm,
      selectedSalesforceAccount: data.selectedSalesforceAccount,
      isPartialMatchConfirmationModalOpen: data.isAccountSelectedModalOpen
    });
  };

  setSalesforcePartnerAccountInspectModalValues = data => {
    this.setState({
      isSalesforcePartnerAccountSelectModalOpen:
        data.isSalesforcePartnerAccountSelectModalOpen,
      currentAccountSearchTerm: data.currentAccountSearchTerm,
      selectedSalesforceAccount: data.selectedSalesforceAccount,
      isSalesforceProspectAccountSelectModalOpen:
        data.isInspectAccountModalOpen,
      isPartialMatchConfirmationModalOpen: data.isAccountSelectedModalOpen
    });
  };

  setHubSpotProspectAccountInspectModalValues = data => {
    this.setState({
      isHubSpotProspectAccountSelectModalOpen:
        data.isHubSpotProspectAccountSelectModalOpen,
      currentHubSpotProspectCompanySearchTerm: data.currentAccountSearchTerm,
      selectedHubSpotCompany: data.selectedHubSpotCompany,
      isHubSpotPartialMatchConfirmationModalOpen:
        data.isHubSpotPartialMatchConfirmationModalOpen
    });
  };

  setHubSpotPartnerAccountInspectModalValues = data => {
    this.setState({
      isHubSpotPartnerAccountSelectModalOpen:
        data.isHubSpotPartnerAccountSelectModalOpen,
      currentHubSpotPartnerCompanySearchTerm: data.currentAccountSearchTerm,
      selectedHubSpotCompany: data.selectedHubSpotCompany,
      isSalesforceProspectAccountSelectModalOpen:
        data.isInspectAccountModalOpen,
      isHubSpotPartialMatchConfirmationModalOpen:
        data.isHubSpotPartialMatchConfirmationModalOpen
    });
  };

  setSalesforceAccountSelectionModalValues = data => {
    this.setState({
      isSelectedSalesforceAccountConfirmed:
        data.isSelectedSalesforceAccountConfirmed,
      isPartialMatchConfirmationModalOpen: data.isAccountSelectedModalOpen,
      isAccountSelected: data.isAccountSelected,
      currentAccountSearchTerm: data.currentAccountSearchTerm,
      isSalesforcePartnerAccountSelectModalOpen:
        data.partnerInspectAccountModalOpen,
      isSalesforceProspectAccountSelectModalOpen: data.isInspectAccountModalOpen
    });
  };

  setHubSpotCompanySelectionModalValues = data => {
    this.setState({
      isSelectedHubSpotCompanyConfirmed: data.isSelectedHubSpotCompanyConfirmed,
      isHubSpotPartialMatchConfirmationModalOpen:
        data.isHubSpotPartialMatchConfirmationModalOpen,
      isAccountSelected: data.isAccountSelected,
      currentHubSpotProspectCompanySearchTerm: "",
      currentHubSpotPartnerCompanySearchTerm: "",
      isHubSpotProspectAccountSelectModalOpen:
        data.isHubSpotProspectAccountSelectModalOpen,
      isHubSpotPartnerAccountSelectModalOpen:
        data.isHubSpotPartnerAccountSelectModalOpen
    });
  };

  setApproveConfirmationModalValues = data => {
    this.setState({
      isApproveConfirmationModalOpen: data.isApproveConfirmationModalOpen
    });
  };

  setTaskCompletionToggleValues = data => {
    this.setState({
      isTerrirtoryAssignmentNotification:
        data.isTerrirtoryAssignmentNotification
    });
  };

  setOpportunityConflictToggleValues = data => {
    this.setState({
      isSalesforceOppOppotunityConflictNotification:
        data.isOppOppotunityConflictNotification
    });
  };

  setNoDealConflictToggleValues = data => {
    this.setState({
      isHubSpotNoDealConflictNotification:
        data.isHubSpotNoDealConflictNotification
    });
  };

  setDealConflictToggleValues = data => {
    this.setState({
      isHubSpotDealConflictNotification: data.isHubSpotDealConflictNotification
    });
  };

  setTeritoryAssignementToggleValues = data => {
    this.setState({
      isTerrirtoryAssignmentNotification:
        data.isTerrirtoryAssignmentNotification,
      isNoSalesforceProspectAccountFoundModalOpen:
        data.isNoAccountFoundModalOpen
    });
  };

  setNoConflictToggleValues = data => {
    this.setState({
      isNoSalesforceConflictsNotification: data.setNoConflictToggleValues
    });
  };

  setNoOpportunityOwnerToggleValues = data => {
    this.setState({
      ownerAccountBanner: data.ownerAccountBanner,
      ownerSelectionModalOpen: data.ownerSelectionModalOpen
    });
  };

  openOwnerSelectionModal = () => {
    this.setState({
      ownerSelectionModalOpen: !this.state.ownerSelectionModalOpen
    });
  };

  openHubSpotOwnerSelectionModal = () => {
    this.setState({
      hubspotOwnerSelectionModalOpen: !this.state.hubspotOwnerSelectionModalOpen
    });
  };

  closeReferralSourceBanner = () => {
    this.setState({
      referralSourceBanner: !this.state.referralSourceBanner
    });
  };

  Approve = () => {
    //check the  reqired field validation
    let isRequriedFieldExist = "";
    let requiredFieldName = "";
    let questionList = this.state.questionList;
    if (questionList && questionList.length > 0) {
      let length = questionList.length;
      for (let i = 0; i < length; i++) {
        if (questionList[i].type == TASK_TYPE_DROP_DOWN_FIELD) {
          if (
            questionList[i].isRequired &&
            questionList[i].tagTypeValue.length == 0
          ) {
            isRequriedFieldExist = true;
            requiredFieldName = questionList[i].label;
            break;
          }
        } else if (questionList[i].type == TASK_TYPE_TEXT_FIELD) {
          if (questionList[i].isRequired && !questionList[i].value) {
            isRequriedFieldExist = true;
            requiredFieldName = questionList[i].label;
            break;
          }
        } else if (questionList[i].type == TASK_TYPE_DATE_FIELD) {
          if (questionList[i].isRequired && !questionList[i].value) {
            isRequriedFieldExist = true;
            requiredFieldName = questionList[i].label;
            break;
          }
        } else if (questionList[i].type == TASK_TYPE_WEBSITE_FIELD) {
          if (questionList[i].isRequired && !questionList[i].value) {
            isRequriedFieldExist = true;
            requiredFieldName = questionList[i].label;
            break;
          }
        }
      }
    }

    if (isRequriedFieldExist && requiredFieldName) {
      toast.error(`${requiredFieldName} is Required`);
      return isRequriedFieldExist;
    }

    if (this.state.isSalesforceConnected) {
      let salesforceAccountDetails = this.state.salesforceAccountDetails;
      let referralDetails = this.state.referralDetails;

      let isPartnerAccountFound =
        salesforceAccountDetails &&
        salesforceAccountDetails.partnerAccountCount > 0;

      let isProspectAccountFound =
        salesforceAccountDetails && salesforceAccountDetails.accountCount > 0;

      let isSalesRoutingEnabled = this.state.enableSalesRouting == "true";

      let isOwnerExistInreferral =
        referralDetails && referralDetails.owner_salesforce_id ? true : false;

      let salesRouteCheck =
        isSalesRoutingEnabled && !isOwnerExistInreferral ? false : true;

      if (isPartnerAccountFound && isProspectAccountFound && salesRouteCheck) {
        this.ApproveReferral(REFERRAL_STATUS_APPROVED);
      } else {
        let message = !isPartnerAccountFound
          ? "Partner"
          : !isProspectAccountFound
          ? "prospect"
          : !salesRouteCheck
          ? "Owner"
          : "";
        if (message != "Owner") {
          toast.error(
            `Please complete the salesforce task (${message} Account Inspection)`
          );
        } else if (message == "Owner") {
          toast.error(`Please complete the salesforce task (Owner Inspection)`);
        }
      }
    } else if (this.state.isHubSpotConnected) {
      this.ApproveReferral(REFERRAL_STATUS_APPROVED);
    } else {
      this.ApproveReferral(REFERRAL_STATUS_APPROVED);
    }
  };

  // Toggle Decline Referral modal
  toggleModal = referralId => {
    let referral_id = "";
    if (referralId) {
      referral_id = referralId;
    }
    const { referralDeclineModal } = this.state;
    this.setState({ referral_id: referral_id }, () => {
      this.setState({ referralDeclineModal: !referralDeclineModal });
    });
  };

  // contact data
  saveContactData = (value, toggle) => {
    let data = [];
    data.push(value ? value : "");
    let contactData = this.state.contactArray.concat(data);
    if (this.state.referralDetails.id) {
      this.createContactData(value, this.state.referralDetails.id);
      toggle();
    } else {
      this.setState({
        contactArray: contactData
      });
      toggle();
    }
  };

  // Create contact
  createContactData = async (data, id) => {
    const referralId = id ? id : this.state.referralDetails.id;
    return await apiClient
      .post(
        `${
          endpoints().referralApi
        }/additional/contact/${id}?referralId=${referralId}`,
        data
      )
      .then(response => {
        if (response && response.data && response.status == 200) {
          this.getReferralDetails();
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.stringify(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };

  // update contact
  updateContactData = async (data, id, toggle) => {
    const referralId = this.state.referralDetails.id;
    return await apiClient
      .put(
        `${
          endpoints().referralApi
        }/additional/contact/${id}?referralId=${referralId}`,
        data
      )
      .then(response => {
        if (response && response.data && response.status == 200) {
          toggle();
          this.getReferralDetails();
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.stringify(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };

  // delete contact
  deleteContactData = async (id, firstName, lastName) => {
    if (id) {
      const referralId = this.state.referralDetails.id;
      return await apiClient
        .delete(
          `${
            endpoints().referralApi
          }/additional/contact/${id}?referralId=${referralId}`
        )
        .then(response => {
          if (response && response.data && response.status == 200) {
            this.getReferralDetails();
            toast.success(response.data.message);
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.stringify(errorRequest.response).message;
              console.log(errorMessage);
            }
            toast.error(error.response.data.message);
          }
        });
    } else {
      let contactData = [];
      this.state.contactArray.forEach(item => {
        item.forEach(elemet => {
          if (
            elemet.contactFirstname !== firstName ||
            elemet.contactLastName !== lastName
          ) {
            contactData.push(item);
          }
        });
      });
      this.setState({
        contactArray: contactData
      });
    }
  };

  render() {
    const {
      referralDetails,
      isLoading,
      isNoSalesforceProspectAccountFoundNotification,
      isSalesforceOppOppotunityConflictNotification,
      isNoSalesforceConflictsNotification,
      isTerrirtoryAssignmentNotification,
      isNoSalesforceProspectAccountFoundModalOpen,
      isSalesforceProspectAccountSelectModalOpen,
      defaultAccountSearchTerm,
      currentAccountSearchTerm,
      selectedSalesforceAccount,
      isPartialMatchConfirmationModalOpen,
      salesforceAccountDetails,
      isSalesforceLoading,
      accountId,
      showPayInvoice,
      isInvoicePaid,
      isMarkPaymentMadeModal,
      paymentDate,
      isSendPaymentEmailToPartner,
      isApproveConfirmationModalOpen,
      referralConflictModalOpen,
      questionList,
      questionValue,
      questionListValue,
      deleteReferralModal,
      deleteReferral,
      isNoSalesforcePartnerAccountFoundNotification,
      isNoSalesforcePartnerAccountFoundModalOpen,
      isSalesforcePartnerAccountSelectModalOpen,
      refferalPartnerUser,
      salesforceUsersList,
      enableOpportunityOwnerExistAccount,
      enableOpportunityOwnerNewAccount,
      enableSalesRouting,
      EnableReferralPaymentTracking,
      ownerAccountBanner,
      referralDeclineModal,
      dateField,
      paymentId,
      invoiceDetails,
      enableInvoiceSettings,
      contactArray,
      referralSourceBanner,
      isSalesforceConnected,
      isHubSpotPartnerAccountNotFoundNotification,
      isHubSpotProspectAccountNotFoundNotification,
      isHubSpotConnected,
      isNoHubSpotPartnerAccountFoundModalOpen,
      isNoHubSpotProspectAccountFoundModalOpen,
      isHubSpotProspectAccountSelectModalOpen,
      isHubSpotPartnerAccountSelectModalOpen,
      isHubSpotPartialMatchConfirmationModalOpen,
      currentHubSpotPartnerCompanySearchTerm,
      currentHubSpotProspectCompanySearchTerm,
      defaultHubSpotProspectCompanySearchTerm,
      defaultHubSpotPartnerCompanySearchTerm,
      selectedHubSpotCompany,
      hubSpotPartnerCompanyDetail,
      hubSpotProspectCompanyDetail,
      hubSpotDetail,
      isHubSpotLoading,
      isHubSpotNoDealConflictNotification,
      isHubSpotDealConflictNotification,
      EnableHubSpotSalesRouting,
      EnableDealOwnerForExistingProspectCompany,
      EnableDealForNewProspectCompany,
      hubspotUserList,
      overlayLoader,
      enableHubspotSyncReferral,
      userList,
      currencyList,
      showAmoutField,
      commissionModel
    } = this.state;

    const isSubmittedFromSalesforce = referralDetails
      ? referralDetails.submitted_from == REFERRAL_SUBMITTED_FROM_SALESFORCE
        ? true
        : false
      : false;

    const showReferralSourceBanner = isSubmittedFromSalesforce ? false : true;

    // Admin action
    let adminActionOptions = isSuperAdmin(this.props.roleId)
      ? [
          {
            value: "Reassign Partner",
            label: "Reassign Partner"
          },
          { type: "separator" },
          {
            value: "Update Commission",
            label: "Update Commission"
          },
          {
            value: "Update Payment Info",
            label: "Update Payment Info"
          },
          { type: "separator" },

          {
            value: `${
              referralDetails.invoice_id !== null
                ? "Update Invoice"
                : "Create Invoice"
            }`,
            label: `${
              referralDetails.invoice_id !== null
                ? "Update Invoice"
                : "Create Invoice"
            }`
          }
        ]
      : [
          {
            value: "Reassign Partner",
            label: "Reassign Partner"
          }
        ];

    if (this.state.isSalesforceConnected) {
      adminActionOptions.push(
        { type: "separator" },
        {
          value: "Sync From Salesforce",
          label: "Sync From Salesforce"
        },
        {
          value: "Sync To Salesforce",
          label: "Sync To Salesforce"
        },
        {
          value: "View in Salesforce",
          label: "View in Salesforce"
        }
      );
    }

    if (this.state.isSalesforceConnected || this.state.isHubSpotConnected) {
      adminActionOptions.push();
    }

    if (this.state.isHubSpotConnected && enableHubspotSyncReferral) {
      adminActionOptions.push(
        { type: "separator" },
        {
          value: "Sync to HubSpot",
          label: "Sync to HubSpot"
        }
      );

      if (referralDetails && referralDetails.hubspot_deal_id) {
        adminActionOptions.push(
          { type: "separator" },
          {
            value: "Sync From HubSpot",
            label: "Sync From HubSpot"
          }
        );
      }
    }
    if (referralDetails && referralDetails.isAllowedToEdit) {
      adminActionOptions.push(
        { type: "separator" },
        {
          value: "Delete",
          label: "Delete"
        }
      );
    }
    {
      adminActionOptions.map((option, index) => (
        <div key={index}>
          {option.type === "separator" ? (
            <hr style={{ borderTop: "1px solid #CCCCCC" }} />
          ) : (
            <div>{option.label}</div>
          )}
        </div>
      ));
    }

    const show = showPayInvoice ? "show" : "";

    if (isLoading) {
      return <Spinner />;
    }

    if (this.state.isPageNotFound) {
      return <Page404 />;
    }

    const settings = this.props.settings ? this.props.settings : "";

    const enablePartners = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS) &&
        getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS) === "true" &&
        true
      : false;

    const enablePaymentTrackingSetting = settings
      ? getKeyValueByObject(
          settings,
          SETTINGS_ENABLE_REFERRAL_PAYMENT_TRACKING
        ) &&
        getKeyValueByObject(
          settings,
          SETTINGS_ENABLE_REFERRAL_PAYMENT_TRACKING
        ) === "true" &&
        true
      : false;

    // get Additional Contact setting
    const enableAdditionalContacts = settings
      ? getKeyValueByObject(
          settings,
          SETTINGS_ENABLE_REFERRAL_ADDITIONAL_CONTACTS
        ) &&
        getKeyValueByObject(
          settings,
          SETTINGS_ENABLE_REFERRAL_ADDITIONAL_CONTACTS
        ) === "true" &&
        true
      : false;

    const enableReferralPayments =
      settings &&
      getKeyValueByObject(settings, SETTINGS_ENABLE_REFERRAL_PAYMENTS) &&
      getKeyValueByObject(settings, SETTINGS_ENABLE_REFERRAL_PAYMENTS) ===
        "true"
        ? true
        : false;
    const salesforeTerritoryFieldName =
      settings &&
      getKeyValueByObject(settings, SETTINGS_SALESFORCE_TERRITORY_FIELD_NAME);

    const accountDetails =
      salesforceAccountDetails && salesforceAccountDetails.accountDetails;
    let questionInitials = {};
    if (questionList && questionList.length > 0) {
      questionList.forEach(list => {
        if (list.type == TASK_TYPE_DROP_DOWN_FIELD) {
          questionInitials[`${list.id}`] = list.tagTypeValue;
        } else if (list.type == TASK_TYPE_TEXT_FIELD) {
          questionInitials[`${list.id}`] = list.value;
        } else if (list.type == TASK_TYPE_DATE_FIELD) {
          questionInitials[`${list.id}`] = list.value;
        } else if (list.type == TASK_TYPE_WEBSITE_FIELD) {
          questionInitials[`${list.id}`] = list.value;
        } else if (list.type == TASK_TYPE_CURRENCY_FIELD) {
          questionInitials[`${list.id}`] = list.value;
        } else if (list.type == TASK_TYPE_NUMBER_FIELD) {
          questionInitials[`${list.id}`] = list.value;
        }
      });
    }

    let partnerSubmit =
      (isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId) ||
        isPartner(this.props.roleId)) &&
      showAmoutField == Referral.AMOUNT_REQUIRED_ON_PARTNER_SUBMIT;

    let partnerOptionalSubmit =
      (isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId) ||
        isPartner(this.props.roleId)) &&
      showAmoutField == Referral.AMOUNT_OPTIONAL_ON_PARTNER_SUBMIT;

    let requiredOnApproval =
      (isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
      showAmoutField == Referral.AMOUNT_REQUIRED_ON_APPROVAL &&
      referralDetails.id;

    let optionalOnApproval =
      (isSuperAdmin(this.props.roleId) ||
        isCompanyAdmin(this.props.roleId) ||
        isCompanyManager(this.props.roleId)) &&
      showAmoutField == Referral.AMOUNT_OPTIONAL_ON_APPROVAL &&
      referralDetails.id;

    const initialValues = {
      ...questionInitials,
      accountName: referralDetails.account_name || "",
      firstName: referralDetails.first_name || "",
      lastName: referralDetails.last_name || "",
      contactTitle: referralDetails.contact_title || "",
      contactEmail: referralDetails.contact_email || "",
      contactPhone: referralDetails.contact_phone || "",
      amount: referralDetails.amount || null,
      accountUrl: referralDetails.accountUrl,
      countryCode:
        {
          value: referralDetails.country_code,
          label: referralDetails.country_code
        } || "",
      notes: referralDetails.notes || "",
      customerReadiness:
        referralDetails.customer_readiness === CUSTOMER_READINESS_READY
          ? CUSTOMER_READINESS_READY_TEXT
          : referralDetails.customer_readiness === CUSTOMER_READINESS_NOT_READY
          ? CUSTOMER_READINESS_NOT_READY_TEXT
          : referralDetails.customer_readiness ===
            CUSTOMER_READINESS_PURCHASED_READY
          ? CUSTOMER_READINESS_PURCHASED_READY_TEXT
          : "",
      partner_manager: userList.find(
        data => data.value === referralDetails.partner_manager_id
      ),
      currency: currencyList.find(
        data => data.value === referralDetails.currency_type_id
      )
    };

    return (
      <>
        <LoadingOverlay
          active={overlayLoader}
          styles={{
            overlay: base => ({
              ...base,
              background: "none"
            })
          }}
          spinner={<BeatLoader />}
        >
          <MessageDialog
            modal={this.state.isPaymentSectionPopupOpen}
            toggle={this.togglePaymentSection}
            handleClose={this.togglePaymentSection}
            sendMessageData={this.sendMessageData}
            heading="Send Partner a Message"
            buttonLabel="Send Message"
          />
          <SalesforceProspectAccountModal
            isNoAccountFoundModalOpen={
              isNoSalesforceProspectAccountFoundModalOpen
            }
            setProspectModalValues={this.setSalesforceProspectModalValues}
            createAccount={this.createAccount}
          />

          <SalesforcePartnerAccountModal
            isNoPartnerAccountModalOpen={
              isNoSalesforcePartnerAccountFoundModalOpen
            }
            setPartnerModalValues={this.setSalesforcePartnerModalValues}
            createAccount={this.createAccount}
          />

          <SalesforceApproveConfirmationModal
            isApproveConfirmationModalOpen={isApproveConfirmationModalOpen}
            toggleApproveConfirmation={this.toggleApproveConfirmation}
            updateReferralByStatus={this.ApproveReferral}
            setApproveConfirmationModalValues={
              this.setApproveConfirmationModalValues
            }
          />

          <ReferralConflictModal
            referralConflictModalOpen={referralConflictModalOpen}
            toggleReferralConflictConfirmation={
              this.toggleReferralConflictConfirmation
            }
            updateReferralByStatus={this.ApproveReferral}
            conflictReferralList={this.state.conflictReferralList}
          />

          <SalesforceProspectInspectModal
            isInspectAccountModalOpen={
              isSalesforceProspectAccountSelectModalOpen
            }
            referralDetails={this.state.referralDetails}
            defaultAccountSearchTerm={defaultAccountSearchTerm}
            currentAccountSearchTerm={currentAccountSearchTerm}
            getSearchedOptionList={this.salesforceAccountInspect}
            setProspectInspectModalValues={
              this.setSalesforceProspectInspectModalValues
            }
          />

          <SalesforcePartnerAccountInspectModal
            partnerInspectAccountModalOpen={
              isSalesforcePartnerAccountSelectModalOpen
            }
            referralDetails={referralDetails}
            defaultAccountSearchTerm={defaultAccountSearchTerm}
            currentAccountSearchTerm={currentAccountSearchTerm}
            getSearchedOptionList={this.salesforceAccountInspect}
            setPartnerAccountInspectModalValues={
              this.setSalesforcePartnerAccountInspectModalValues
            }
          />

          <SalesforceAccountConfirmModal
            isAccountSelectedModalOpen={isPartialMatchConfirmationModalOpen}
            referralDetails={referralDetails}
            selectedSalesforceAccount={selectedSalesforceAccount}
            setAccountSelectionModalValues={
              this.setSalesforceAccountSelectionModalValues
            }
            isAccountSelected={this.state.isAccountSelected}
            createAccount={this.createAccount}
            accountType={this.state.accountType}
          />

          <HubSpotCompanyConfirmationModal
            isAccountSelectedModalOpen={
              isHubSpotPartialMatchConfirmationModalOpen
            }
            referralDetails={referralDetails}
            selectedHubSpotCompany={selectedHubSpotCompany}
            setAccountSelectionModalValues={
              this.setHubSpotCompanySelectionModalValues
            }
            isAccountSelected={this.state.isAccountSelected}
            createAccount={this.createHubSpotCompany}
            hubSpotCompanyType={this.state.hubSpotCompanyType}
          />

          <SalesforcePaymentMadeModal
            isMarkPaymentMadeModal={isMarkPaymentMadeModal}
            toggleMarkPaymentMadeModal={this.toggleMarkPaymentMadeModal}
            referralDetails={referralDetails}
            paymentDate={paymentDate}
            isSendPaymentEmailToPartner={isSendPaymentEmailToPartner}
            setPaymentAsMade={this.setPaymentAsMade}
            setPaymentDate={this.setPaymentDate}
            toggleIsSendPaymentEmailToPartner={
              this.toggleIsSendPaymentEmailToPartner
            }
          />
          <SalesforceOwnerSelectionModal
            userList={this.state.salesforceUsersList}
            isOpen={this.state.ownerSelectionModalOpen}
            updateOwner={this.updateOwner}
            toggle={this.openOwnerSelectionModal}
            referralDetails={referralDetails}
          />

          <HubSpotOwnerSelectionModal
            userList={this.state.hubspotUserList}
            isOpen={this.state.hubspotOwnerSelectionModalOpen}
            updateOwner={this.updateOwner}
            toggle={this.openHubSpotOwnerSelectionModal}
            referralDetails={referralDetails}
          />

          <HubSpotProspectAccountModal
            isNoAccountFoundModalOpen={isNoHubSpotProspectAccountFoundModalOpen}
            setProspectModalValues={this.setHubSpotProspectModalValues}
            createAccount={this.createHubSpotCompany}
          />

          <HubSpotPartnerAccountModal
            isNoPartnerAccountModalOpen={
              isNoHubSpotPartnerAccountFoundModalOpen
            }
            setPartnerModalValues={this.setHubSpotPartnerModalValues}
            createAccount={this.createHubSpotCompany}
          />

          <HubSpotProspectAccountInspectModal
            isInspectAccountModalOpen={isHubSpotProspectAccountSelectModalOpen}
            referralDetails={this.state.referralDetails}
            defaultAccountSearchTerm={defaultHubSpotProspectCompanySearchTerm}
            currentAccountSearchTerm={currentHubSpotProspectCompanySearchTerm}
            getSearchedOptionList={this.HubSpotCompanyInspect}
            setProspectInspectModalValues={
              this.setHubSpotProspectAccountInspectModalValues
            }
          />

          <HubSpotPartnerAccountInspectModal
            partnerInspectAccountModalOpen={
              isHubSpotPartnerAccountSelectModalOpen
            }
            referralDetails={referralDetails}
            defaultAccountSearchTerm={defaultHubSpotPartnerCompanySearchTerm}
            currentAccountSearchTerm={currentHubSpotPartnerCompanySearchTerm}
            getSearchedOptionList={this.HubSpotCompanyInspect}
            setPartnerAccountInspectModalValues={
              this.setHubSpotPartnerAccountInspectModalValues
            }
          />
          <CommissionModel
            isOpen={commissionModel}
            toggle={() => {
              this.setState({ commissionModel: false });
            }}
            title="Update Commission"
            getReferralDetails={this.getReferralDetails}
            referralId={this.props.match.params.id}
          />
          {/* Edit Referral Details Form */}
          <Form
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={values => {
              if (values.partner_manager) {
                values.partner_manager = values.partner_manager.value
                  ? values.partner_manager.value
                  : "";
              }
              if (values.currency) {
                values.currency = values.currency.value
                  ? values.currency.value
                  : null;
              }
              if (values.amount) {
                values.amount = values.amount ? values.amount : null;
              }
              values.contactPhone = values.contactPhone
                ? removeMaskedPhoneNumber(values.contactPhone)
                : "";

              values.customer_readiness =
                values.customerReadiness === CUSTOMER_READINESS_READY_TEXT
                  ? CUSTOMER_READINESS_READY
                  : values.customerReadiness ===
                    CUSTOMER_READINESS_NOT_READY_TEXT
                  ? CUSTOMER_READINESS_NOT_READY
                  : values.customerReadiness ===
                    CUSTOMER_READINESS_PURCHASED_READY_TEXT
                  ? CUSTOMER_READINESS_PURCHASED_READY
                  : "";
              const customFields = [];
              if (values && Object.keys(values).length > 0) {
                Object.keys(values).forEach(value => {
                  if (!isNaN(value)) {
                    customFields.push({
                      questionId: value,
                      value: values[value]
                    });
                  }
                });
                values.customFields = customFields;
              }

              if (contactArray) {
                let updateList = [];
                contactArray.forEach(list => {
                  updateList.push(list[0]);
                });
                values.contactArray = updateList ? updateList : "";
              }
              this.handleSubmit(values);
            }}
          >
            <AdminActionNavigation>
              <div className="d-flex align-items-center mt-2 col-4">
                <a
                  href="javascript:void(0)"
                  className="cursor-pointer"
                  onClick={() => {
                    this.props.history.push("/referrals");
                  }}
                >
                  {`${Terminology.get(SystemName.REFERRAL)}`}
                </a>
                <span
                  className="text-dark d-inline-flex align-items-center"
                  style={{ width: "15px" }}
                >
                  <ChevronRight />
                </span>
                <span
                  onClick={e => e.preventDefault()}
                  className={[
                    "text-dark",
                    "font-weight-bold",
                    "d-inline-flex",
                    "align-items-center",
                    "project-name",
                    "w-100"
                  ].join(" ")}
                >
                  <span className="ellipsis">
                    {referralDetails.account_name ||
                      `Edit ${Terminology.get(SystemName.REFERRAL)}`}
                  </span>
                </span>
              </div>

              <div className="align-items-center">
                {this.state.referralDetails.submitted_from !=
                  REFERRAL_SUBMITTED_FROM_LINK_TRACKING &&
                  (isSuperAdmin(this.props.roleId) ||
                    isCompanyAdmin(this.props.roleId) ||
                    isCompanyManager(this.props.roleId)) &&
                  (referralDetails.status === REFERRAL_STATUS_NEW ||
                    referralDetails.status === REFERRAL_STATUS_DECLINED) && (
                    <button
                      id="approveReferral"
                      type="button"
                      disabled={this.state.isApprove ? true : false}
                      className="m-1 btn btn-success"
                      onClick={() => {
                        this.Approve();
                      }}
                    >
                      Approve
                    </button>
                  )}

                {this.state.referralDetails.submitted_from !=
                  REFERRAL_SUBMITTED_FROM_LINK_TRACKING &&
                  (isSuperAdmin(this.props.roleId) ||
                    isCompanyAdmin(this.props.roleId) ||
                    isCompanyManager(this.props.roleId)) &&
                  (referralDetails.status === REFERRAL_STATUS_NEW ||
                    referralDetails.status === REFERRAL_STATUS_APPROVED) && (
                    <button
                      id="declineReferral"
                      type="button"
                      className="m-1 btn btn-danger"
                      onClick={() => this.toggleModal(referralDetails.id)}
                    >
                      Decline
                    </button>
                  )}
                {referralDetails.status === REFERRAL_STATUS_APPROVED &&
                  !isHubSpotConnected &&
                  !isSalesforceConnected && (
                    <>
                      <Button
                        color={COLOR_DANGER}
                        label={"Closed"}
                        onClick={() => {
                          this.UpdateStatus("Closed");
                        }}
                      />

                      <Button
                        disabled={this.state.isApprove ? true : false}
                        color={COLOR_SUCCESS}
                        label={"Closed Won"}
                        onClick={() => {
                          this.UpdateStatus("Closed Won");
                        }}
                      />
                    </>
                  )}

                {(isPartner(this.props.roleId) &&
                  referralDetails.status == REFERRAL_STATUS_NEW) ||
                  ((isSuperAdmin(this.props.roleId) ||
                    isCompanyAdmin(this.props.roleId) ||
                    isCompanyManager(this.props.roleId)) && <SaveButton />)}
                {/* Referral Decline Modal */}
                {referralDetails.id && referralDeclineModal && (
                  <ReferralDeclineModal
                    modal={referralDeclineModal}
                    setModal={this.toggleModal}
                    referralId={referralDetails.id}
                    params={{ status: REFERRAL_STATUS_NEW }}
                    updateReferralStatus={e => {
                      this.updateReferralStatus(
                        REFERRAL_STATUS_DECLINED,
                        referralDetails.id,
                        e
                      );
                    }}
                  />
                )}
              </div>
            </AdminActionNavigation>
            <DeleteModal
              isOpen={deleteReferralModal}
              toggle={() => {
                this.setState({ deleteReferralModal: false });
              }}
              title={`Delete ${Terminology.get(SystemName.REFERRAL)}`}
              label={referralDetails.account_name}
              deleteFunction={() => this.handleDelete(deleteReferral.id)}
            />

            {referralSourceBanner && isSubmittedFromSalesforce && (
              <SalesforceReferralSourceBanner
                submittedFrom={
                  referralDetails && referralDetails.submitted_from
                }
                closeReferralSourceBanner={this.closeReferralSourceBanner}
              />
            )}

            {(referralDetails.status === REFERRAL_STATUS_NEW ||
              referralDetails.status === REFERRAL_STATUS_APPROVED) && (
              <>
                {showReferralSourceBanner &&
                  isNoSalesforcePartnerAccountFoundNotification &&
                  (isSuperAdmin(this.props.roleId) ||
                    isCompanyAdmin(this.props.roleId) ||
                    isCompanyManager(this.props.roleId)) && (
                    <SalesforcePartnerAccountToggle
                      setPartnerToggleValues={this.setPartnerToggleValues}
                    />
                  )}

                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) &&
                  showReferralSourceBanner &&
                  isNoSalesforceProspectAccountFoundNotification && (
                    <SalesforceProspectAccountToggle
                      setProspectToggleValues={this.setToggleValues}
                    />
                  )}

                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) &&
                  showReferralSourceBanner &&
                  isHubSpotPartnerAccountNotFoundNotification && (
                    <HubSpotPartnerAccountToggle
                      setPartnerToggleValues={
                        this.setHubspotPartnerToggleValues
                      }
                    />
                  )}

                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) &&
                  isHubSpotProspectAccountNotFoundNotification && (
                    <HubSpotProspectAccountToggle
                      setProspectToggleValues={
                        this.setHubSpotProspectToggleValues
                      }
                    />
                  )}

                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) &&
                  showReferralSourceBanner &&
                  isSalesforceOppOppotunityConflictNotification && (
                    <>
                      <SalesforceOpportunityConflictToggle
                        setOpportunityConflictToggleValues={
                          this.setOpportunityConflictToggleValues
                        }
                      />
                    </>
                  )}

                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) &&
                  showReferralSourceBanner &&
                  isHubSpotDealConflictNotification && (
                    <>
                      <DealsConflictToggle
                        setDealConflictToggleValues={
                          this.setDealConflictToggleValues
                        }
                      />
                    </>
                  )}

                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) &&
                  isHubSpotNoDealConflictNotification && (
                    <>
                      <NoHubSpotDealConflictNotificationToggle
                        setDealConflictToggleValues={
                          this.setNoDealConflictToggleValues
                        }
                      />
                    </>
                  )}

                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) &&
                  showReferralSourceBanner &&
                  isNoSalesforceConflictsNotification && (
                    <>
                      <SalesforceNoConflictNotificationToggle
                        setNoConflictToggleValues={
                          this.setNoConflictToggleValues
                        }
                      />
                    </>
                  )}

                {isSalesforceConnected &&
                  showReferralSourceBanner &&
                  this.state.referralDetails.submitted_from !=
                    REFERRAL_SUBMITTED_FROM_LINK_TRACKING &&
                  (isSuperAdmin(this.props.roleId) ||
                    isCompanyAdmin(this.props.roleId) ||
                    isCompanyManager(this.props.roleId)) &&
                  ownerAccountBanner &&
                  enableSalesRouting == "true" && (
                    <>
                      <SalesforceOpportunityOwnerToggle
                        setNoOpportunityOwnerToggleValues={
                          this.setNoOpportunityOwnerToggleValues
                        }
                      />
                    </>
                  )}
              </>
            )}

            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) &&
              showReferralSourceBanner &&
              isTerrirtoryAssignmentNotification &&
              referralDetails.status === REFERRAL_STATUS_APPROVED &&
              accountDetails &&
              accountDetails.id &&
              salesforeTerritoryFieldName &&
              accountDetails[salesforeTerritoryFieldName] === null && (
                <SalesforceTerritoryAssignmentToggle
                  setTeritoryAssignementToggleValues={
                    this.setTeritoryAssignementToggleValues
                  }
                />
              )}

            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) &&
              showReferralSourceBanner &&
              isTerrirtoryAssignmentNotification &&
              ((referralDetails.status === REFERRAL_STATUS_APPROVED &&
                accountDetails &&
                accountDetails.id &&
                salesforeTerritoryFieldName &&
                accountDetails[salesforeTerritoryFieldName] &&
                accountDetails[salesforeTerritoryFieldName] !== null) ||
                (referralDetails.status === REFERRAL_STATUS_APPROVED &&
                  !salesforeTerritoryFieldName &&
                  referralDetails &&
                  referralDetails.salesforce_opportunity_id)) && (
                <div className="alerts-wrapper">
                  <SalesforceTaskCompletedToggle
                    setTaskCompletionToggleValues={
                      this.setTaskCompletionToggleValues
                    }
                  />
                </div>
              )}
            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId) ||
              isPartner(this.props.roleId)) &&
              isInvoicePaid && (
                <PaymentSuccessSection
                  isReferral={true}
                  invoiceDetails={invoiceDetails}
                />
              )}

            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) &&
              !isInvoicePaid &&
              enableInvoiceSettings != 3 &&
              enablePaymentTrackingSetting === true &&
              referralDetails.commission_amount &&
              referralDetails.status === REFERRAL_STATUS_CLOSED_WON && (
                <PaymentSection
                  referralDetails={referralDetails}
                  show={show}
                  togglePayInvoice={this.togglePayInvoice}
                  enableReferralPayments={enableReferralPayments}
                  isInvoicePaid={isInvoicePaid}
                  togglePaymentSection={this.togglePaymentSection}
                  partnerReminder={this.partnerPaymentReminder}
                  toggleMarkPaymentMadeModal={this.toggleMarkPaymentMadeModal}
                  paymentId={paymentId}
                />
              )}

            {isPartner(this.props.roleId) &&
              referralDetails.commission_amount &&
              enableInvoiceSettings != 3 &&
              enablePaymentTrackingSetting == true &&
              referralDetails.status === REFERRAL_STATUS_CLOSED_WON &&
              !isInvoicePaid && (
                <ReferralClosedBanner
                  referralDetails={referralDetails}
                  show={show}
                  togglePayInvoice={this.togglePayInvoice}
                  enableReferralPayments={enableReferralPayments}
                  isInvoicePaid={isInvoicePaid}
                />
              )}

            <div className="d-flex justify-content-between align-items-center mb-2">
              <PageTitle
                label={`${Terminology.get(SystemName.REFERRAL)} Details `}
              />
              {(isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId)) && (
                <SelectDropdown
                  buttonLabel="Admin Actions"
                  dropdownLinks={adminActionOptions}
                  color={"gray"}
                  hideCaret
                  selectName={"admin_action"}
                  handleChange={this.handleAdminAction}
                />
              )}
            </div>
            {isPartner(this.props.roleId) &&
              refferalPartnerUser &&
              refferalPartnerUser.status ===
                REFERRAL_PARTNER_STATUS_PENDING && (
                <PartnerAcceptReffalAlert
                  updatePartnerReferralStatus={this.updatePartnerReferralStatus}
                  referralDetails={referralDetails}
                />
              )}
            <div className="card border-0">
              <div className="project-details-body">
                <ReferralFormFields
                  settings={settings}
                  setCloseDate={this.setCloseDate}
                  closeDate={this.state.closeDate}
                  disableCloseDate={!this.state.closeDate}
                  questionList={questionList}
                  questionValue={questionValue}
                  questionListValue={questionListValue}
                  overlayLoader={this.state.overlayLoader}
                  isSubmitPage={false}
                  setDatefieldDate={this.setDatefieldDate}
                  dateField={dateField}
                  userList={this.state.userList}
                  currencyList={currencyList}
                  referralDetails={referralDetails}
                  partnerSubmit={partnerSubmit}
                  partnerOptionalSubmit={partnerOptionalSubmit}
                  requiredOnApproval={requiredOnApproval}
                  optionalOnApproval={optionalOnApproval}
                />
                <SideBar
                  referralDetails={referralDetails}
                  enablePartners={enablePartners}
                  referralId={this.state.referralId}
                  isReferralUpdate={this.state.isReferralUpdate}
                  partnerTierCommissionPercentage={
                    this.state.partnerTierCommissionPercentage
                  }
                  salesforceAccountDetails={this.state.salesforceAccountDetails}
                  isSalesforceLoading={this.state.isSalesforceLoading}
                  isSalesforceTasksLoading={isSalesforceLoading}
                  openProspectAccountModal={this.openProspectAccountModal}
                  openParterAccountModal={this.openParterAccountModal}
                  openOwnerSelectionModal={this.openOwnerSelectionModal}
                  accountId={accountId}
                  instanceUrl={salesforceAccountDetails.instanceUrl}
                  salesforeTerritoryFieldName={salesforeTerritoryFieldName}
                  reassignfun={this.state.reassignfun}
                  reAssignValue={this.state.reAssignValue}
                  salesforceUsersList={salesforceUsersList}
                  enableSalesRouting={enableSalesRouting}
                  enableOpportunityOwnerExistAccount={
                    enableOpportunityOwnerExistAccount
                  }
                  enableOpportunityOwnerNewAccount={
                    enableOpportunityOwnerNewAccount
                  }
                  EnableReferralPaymentTracking={EnableReferralPaymentTracking}
                  linkOpportunity={this.linkOpportunity}
                  saveContactData={(e, toggle) => {
                    this.saveContactData(e, toggle);
                  }}
                  contactArray={contactArray}
                  enableAdditionalContacts={enableAdditionalContacts}
                  updateContactData={(e, id, toggle) => {
                    this.updateContactData(e, id, toggle);
                  }}
                  deleteContactData={(e, firstName, lastName) => {
                    this.deleteContactData(e, firstName, lastName);
                  }}
                  isSalesforceConnected={isSalesforceConnected}
                  isHubSpotConnected={isHubSpotConnected}
                  hubSpotPartnerCompanyDetail={hubSpotPartnerCompanyDetail}
                  hubSpotProspectCompanyDetail={hubSpotProspectCompanyDetail}
                  hubSpotDetail={hubSpotDetail}
                  openHubSpotProspectCompanyModal={
                    this.openHubSpotProspectCompanyModal
                  }
                  openHubSpotPartnerCompanyModal={
                    this.openHubSpotPartnerCompanyModal
                  }
                  isHubSpotLoading={isHubSpotLoading}
                  enableHubSpotSalesRouting={EnableHubSpotSalesRouting}
                  EnableDealForNewProspectCompany={
                    EnableDealForNewProspectCompany
                  }
                  EnableDealOwnerForExistingProspectCompany={
                    EnableDealOwnerForExistingProspectCompany
                  }
                  openHubSpotOwnerSelectionModal={
                    this.openHubSpotOwnerSelectionModal
                  }
                  hubspotUserList={hubspotUserList}
                  enableHubspotSyncReferral={enableHubspotSyncReferral}
                />
              </div>
            </div>
          </Form>
        </LoadingOverlay>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(EditReferral);
