import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormGroup, Input, FormFeedback } from "reactstrap";
import Label from "./Label";

class Radio extends React.Component {
  validate(value) {
    const { label, placeholder, required, error } = this.props;

    let errorMessage;
    const inputLabel = label || placeholder;
    const errorMessageLabel = error;

    if ((!value || !value.trim()) && required) {
      errorMessage = errorMessageLabel
        ? `${errorMessageLabel}`
        : `${inputLabel} is required`;
    }

    return errorMessage;
  }

  renderInput({ field, form: { touched, errors, values } }) {
    const {
      name,
      id,
      label,
      required,
      options,
      onRender,
      className,
      onChange,
      defaultValue,
      size,
      hideOptionName,
      showInline,
      radioClassName,
      showValue,
      readOnly
    } = this.props;

    const errorMessage = touched[name] && errors[name] ? errors[name] : null;
    const inputId = id || name;
    const sizeStyle =
      size && size === "large"
        ? {
            height: "25px",
            width: "25px",
            verticalAlign: "middle"
          }
        : size && size === "medium"
        ? {
            height: "20px",
            width: "20px",
            verticalAlign: "middle"
          }
        : {};

    const fieldInput = (
      <FormGroup
        style={{ position: "relative" }}
        className={`${(!!errorMessage && "is-invalid") || ""}`}
      >
        {label && (
          <Label id={inputId} required={required}>
            <span style={{ fontWeight: "lighter" }}>{label}</span>
          </Label>
        )}

        <div className={`${className}`}>
          {options.map((option, key) => (
            <div
              key={key}
              style={{ display: "block" }}
              className={radioClassName}
            >
              <Label check className={`${name} radio-item`}>
                <Input
                  type="radio"
                  {...field}
                  name={inputId}
                  disabled={readOnly}
                  defaultChecked={
                    option.label
                      ? defaultValue === option.label
                      : (field.value && field.value.value === option.value) ||
                        defaultValue === option
                  }
                  value={showValue ? option.value : option}
                  className={`radio-button1 ${showInline && "mt-0"}`}
                  style={sizeStyle}
                  onChange={onChange}
                />
                {!hideOptionName && (
                  <div className="ml-3">
                    {!option.label && (
                      <p
                        className={
                          size && size === "large" ? "ml-3 pt-1 mb-0" : ""
                        }
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {option}
                      </p>
                    )}
                    {option.label && (
                      <p
                        className="mb-0 pt-1"
                        style={{ fontSize: "16px", fontWeight: "500" }}
                      >
                        {option.label}
                      </p>
                    )}
                    {option.subtext && (
                      <p
                        className="mb-0"
                        style={{ fontSize: "12px", color: "gray" }}
                      >
                        {option.subtext}
                      </p>
                    )}
                  </div>
                )}
              </Label>
            </div>
          ))}
        </div>

        {errorMessage && (
          <FormFeedback style={{ marginTop: 1, display: "block" }}>
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );

    if (!onRender) {
      return fieldInput;
    }

    return onRender(values, (err, render) => (render ? fieldInput : null));
  }

  render() {
    const { name, id } = this.props;

    return (
      <Field
        id={id || name}
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

Radio.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  className: PropTypes.string,
  error: PropTypes.string,
  required: PropTypes.bool,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string
    })
  )
};

export default Radio;
