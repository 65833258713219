import React, { Component, useState } from "react";

// Components
import Checkbox from "./Checkbox";
import Number from "../../../components/base/Number";
import Currency from "../../../components/base/Currency";

class BasicPricingPackage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      checkBox: {
        packageDeliverables: []
      }
    };
  }

  render() {
    const {
      id,
      packageType,
      price,
      defaultDeliverables,
      basicDeliverableOnChange,
      basicPackageDeliverables
    } = this.props;

    const deliverables = [];
    if (defaultDeliverables && defaultDeliverables.length > 0) {
      defaultDeliverables.forEach(deliverable => {
        deliverables.push(deliverable.description);
      });
    }

    return (
      <>
        <label>{packageType} Package</label>
        <div className="field-wrapper-group group-outlined">
          <Currency
            name={`${packageType}-package-${id}`}
            label="Price"
            placeholder="Price"
            id={id}
            value={price}
            onChange={e => this.props.onChange(e)}
            minValue={0}
          />
          <Number
            name={`${packageType}_playbook_turnaround_time`}
            label="Turnaround Time"
            placeholder="Days Until Delivery"
            maxLength={3}
          />
          {defaultDeliverables && defaultDeliverables.length > 0 && (
            <div className="package-deliverables">
              <Checkbox
                classnames="custom-checkbox"
                id={`${packageType}-deliverable-${id}`}
                name={`${packageType}-deliverable-${id}`}
                options={defaultDeliverables}
                selectedOptions={basicPackageDeliverables}
                handleChange={basicDeliverableOnChange}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

export default BasicPricingPackage;
