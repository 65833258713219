//Scheduler API URl
export const SchedulerApiUrl = [
  {
    label: "Crossbeam: Sync Partner and Accounts", //Crossbeam: Sync
    value: "v1/crossbeam/job/sync"
  },
  {
    label: "Crossbeam: Update Snooze", //Crossbeam: Update Snooze
    value: "v1/crossbeam/partner/job/partners/statusUpdate"
  },
  {
    label: "Company: Create default record", //Company: Create default record
    value: "v1/company/job/createDefaultRecord"
  },
  {
    label: "Company: Create company record for partners", //Company: Create company record for partners
    value: "v1/company/job/createCompany"
  },
  {
    label: "Deal: Reindex", //Deal: Reindex
    value: "v1/deal/job/reindex/all"
  },
  {
    label: "Expert Profile: Update Completion Percentage", //Expert Profile: Update Completion Percentage
    value: "v1/expert/job/profileCompletionPercentageUpdate"
  },
  {
    label: "Expert: Reindex", //Expert: Reindex
    value: "v1/expert/job/reindex/all"
  },
  {
    label: "Google: Mail Activities", //Google: Mail Activities
    value: "v1/google/mail/job"
  },
  {
    label: "Invoice: Create for Closed Referrals", //Invoice: Create for Closed Referrals
    value: "v1/referral/job/createInvoice/all"
  },
  {
    label: "Partner Profile: Update Completion Percentage", //Partner Profile: Update Completion Percentage
    value: "v1/partner/job/profileCompletionPercentageUpdate"
  },
  {
    label: "Partner Profile: Set Public Visibility", //Partner Profile: Set Public Visibility
    value: "v1/partner/job/partner/setProfileVisibility/"
  },
  {
    label: "Partner: Reindex", //Partner: Reindex
    value: "v1/partner/job/reindex/all"
  },
  {
    label: "Partner: Set Profile URL", //Partner: Set Profile URL
    value: "v1/partner/job/generateProfileUrl/all"
  },
  {
    label: "Partner Profile: Sync from Salesforce", //Partner Profile: Sync from Salesforce
    value: "v1/partner/job/sync/partnerAccount"
  },
  {
    label: "Partner: Create partner record for companies", //Partner: Create partner record for companies
    value: "v1/partner/job/createPartner"
  },
  {
    label: "Account: Update prospect account record from referral", //Account: Update prospect account record from referral
    value: "v1/partner/job/updateProspectAccount"
  },
  {
    label: "Account: Update partner account record from partner profile", //Account: Update partner account record from partner profile
    value: "v1/partner/job/updateAccount"
  },
  {
    label: "Partner: Update partner company", //Partner: Update partner company
    value: "v1/partner/job/createCompanyPartner"
  },
  {
    label:
      "Partner: Update Company Id Partner Profile Custom Field Value Table", //Partner: Update company id in partner_profile_custom_field_value_table
    value: "v1/partner/job/updateCustomFieldValue"
  },
  {
    label: "Project: Reindex", //Project: Reindex
    value: "v1/project/job/reindex/all"
  },
  {
    label: "Referral: Reindex", //Referral: Reindex
    value: "v1/referral/job/reindex/all"
  },
  {
    label: "Referral: Set Customer Readiness", //Referral: Set Customer Readiness
    value: "v1/referral/job/referral/customerReadiness"
  },
  {
    label: "Salesforce: Sync Opportunities", //Salesforce: Sync Opportunities
    value: "v1/referral/job/sync/opportunities"
  },
  {
    label: "Salesforce: Sync Users", //Salesforce: Sync Users
    value: "v1/salesforce/job/sync/users"
  },
  {
    label: "Salesforce: Sync account", //Salesforce: Sync account
    value: "v1/salesforce/job/sync/account"
  },
  {
    label: "Salesforce: Sync Opportunity Owner", //Salesforce: Sync Opportunity Owner
    value: "v1/salesforce/job/sync/owner"
  },
  {
    label: "Salesforce: Snapshot Opportunities", //Salesforce: Snapshot Opportunities
    value: "v1/salesforce/job/opportunitySnapshot"
  },
  {
    label: "Message: Update Message User", //Message: Update Message User
    value: "v1/message/job/updateMessageUser"
  },
  {
    label: "Hubspot: Sync Deals", //HubSpot: Sync Hubspot
    value: "v1/hubspot/job/syncDeal"
  },
  {
    label: "Salesforce: Sync Account and Opportunities", //Salesforce: Sync Account and Opportunities
    value: "v1/salesforce/job/sync/AccountOpportunity"
  },
  {
    label: "HubSpot: Users Sync", //HubSpot: Users Sync
    value: "v1/hubspot/job/syncUser"
  },
  {
    label: "HubSpot: Contacts Sync", //HubSpot: Contacts Sync
    value: "v1/hubspot/job/syncContact"
  },
  {
    label: "Hubspot: Create Contact In Hubspot",
    value: "v1/hubspot/job/createContactInHubspot"
  },
  {
    label: "Contact: Create Missing Partner Contacts",
    value: "v1/partner/job/createContact"
  },
  {
    label: "Referral: Update Expired Status",
    value: "v1/referral/job/updateExpiredStatus"
  },
  {
    label: "Account Mapping: Update Index Table",
    value: "v1/crossbeam/job/accountMappingIndex"
  },
  {
    label: "Crossbeam Population Report: Update Population Report",
    value: "v1/crossbeam/job/updatePopulationReport"
  }
];
