import React from "react";
import { Card, CardBody } from "reactstrap";

// Components
import Form from "../../components/base/Form";
import Button from "../../components/base/Button";
import Email from "../../components/base/Email";

// Assets
import logo from "../../assets/logo.svg";
import headerTriangles from "../../assets/header-triangles.svg";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";

class ForgotPassword extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      errorMessage: "",
      successMessage: ""
    };
    this._userForgotPassword = this._userForgotPassword.bind(this);
    this._updateDimensions = this._updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this._updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this._updateDimensions);
  }

  // Update the screen height
  _updateDimensions() {
    this.setState({
      height: window.innerHeight
    });
  }

  // User forgot password API call
  _userForgotPassword(data) {
    return apiClient
      .put(`${endpoints().userAPI}/forgotPassword`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this.setState({ successMessage, errorMessage: "" });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ errorMessage, successMessage: "" });
          console.error(errorMessage);
        }
      });
  }

  // Reset the message
  _hideErrorMessage = () => {
    this.setState({ errorMessage: "", successMessage: "" });
  };

  render() {
    const cardCenter = (this.state.height / 100) * 30;
    const { errorMessage, height, successMessage } = this.state;

    const initialValues = {
      email: ""
    };

    return (
      <div className="container-fluid" style={{ height: height }}>
        <div className="container">
          <div className="row justify-content-center">
            <div
              className="col-lg-5 col-md-7 col-sm-12"
              style={{ paddingTop: cardCenter }}
            >
              <Card className="w-75 mx-auto rounded">
                <Form
                  initialValues={initialValues}
                  onSubmit={values => {
                    values.email = values.email ? values.email : "";
                    this._userForgotPassword(values);
                    return false;
                  }}
                >
                  <CardBody className="pb-0">
                    <div className="text-center pb-3">
                      <div className="font-weight-bold">Forgot password?</div>
                      <small>
                        Tell us your email so we can send you a reset link.
                      </small>
                    </div>
                    <div className={`${errorMessage ? "mb-2" : "mb-4"}`}>
                      <div className="mb-4">
                        <Email
                          name="email"
                          placeholder="Email Address"
                          required
                          onKeyDown={this._hideErrorMessage}
                        />
                      </div>
                    </div>
                    <div
                      className={`${
                        errorMessage || successMessage ? "mt-n3" : "mb-2"
                      }`}
                    >
                      <span
                        style={{ fontSize: "0.80rem" }}
                        className={
                          errorMessage ? "error-message" : "text-success"
                        }
                      >
                        {successMessage || errorMessage}
                      </span>
                    </div>
                  </CardBody>
                  <div className="pr-2">
                    <Button
                      type="submit"
                      color="#E0674C"
                      hoverColor="#ff876c"
                      label="Send Reset Password Link"
                      className="rounded-0 text-white w-100 font-weight-bold"
                    />
                  </div>
                </Form>
              </Card>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ForgotPassword;
