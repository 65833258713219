import React, { Component } from "react";
import SidebarContent from "../base/SidebarContent";
import UploadAvatar from "../../views/users/UploadAvatar";
import Text from "../base/Text";
import Country from "../base/Country";
import State from "../State";
import Phone from "../base/Phone";
import Email from "../base/Email";
import Zipcode from "../../components/base/Zipcode";
import TimeZone, { TimeZones } from "../base/TimeZone";
import URL from "../base/URL";
import PartnerType from "../PartnerType";
import PartnerTier from "../PartnerTier";
import CrossbeamPartner from "../base/CrossbeamPartner";
import PartnerManager from "../../views/editPartnerProfiles/inner-components/PartnerManager";
import Select from "../../components/Select";

// Constants
import { UNITED_STATES } from "../../Country/Constants";
import { PARTNER_TYPE_SETTING_ENABLE_PARTNERSHIP_WITH_INDIVIDUAL_PERSON } from "../../partnerTypeSetting/Constants";

import {
  FacebookIcon,
  GlobeIcon,
  InstagramIcon,
  TwitterIcon,
  LinkedinIcon,
  YoutubeIcon
} from "../../assets/img/icons";
import { Field } from "formik";
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  getKeyValueByObject,
  isPartner
} from "../../lib/helper";

import { connect } from "react-redux";

class PartnerProfileSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: this.props.partnerDetails.skills,
      tools: this.props.partnerDetails.tools,
      industries: this.props.partnerDetails.industries,
      selectedOption: "",
      selectedCountryName: this.props.partnerDetails.countryName,
      selectedRegionOption: this.props.partnerDetails.region,
      selectedTypeId: []
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  // Handle Country
  handleCountryChange = ({ values }) => {
    const selectedOption = values.countryName && values.countryName.id;
    const selectedCountryName = values.countryName && values.countryName.label;
    this.setState({
      selectedOption,
      selectedCountryName: selectedCountryName
    });
  };
  getSelectedPartnerTypeId = () => {
    let selectedTypeId;
    let typeId = [];
    if (this.props.partnerDetails.partnerType) {
      this.props.partnerDetails.partnerType &&
        this.props.partnerDetails.partnerType.forEach(element => {
          typeId.push({ id: element.type_id });
        });
    }
    if (this.state.selectedTypeId.length > 0) return this.state.selectedTypeId;
    else return typeId;
  };
  // get selected partner type id
  handlePartnerType = event => {
    let selectedType = event && event !== null ? event.value : [];
    let typeId = [];
    selectedType &&
      selectedType.forEach(element => {
        typeId.push({ id: element.value });
        this.setState({
          selectedTypeId: typeId
        });
      });
  };

  render() {
    const { selectedCountry, id, partnerSettings, loggedInUser } = this.props;

    const { selectedOption, selectedCountryName, selectedTypeId } = this.state;

    const enablePartnerShipWithIndividualPerson = partnerSettings
      ? getKeyValueByObject(
          partnerSettings,
          PARTNER_TYPE_SETTING_ENABLE_PARTNERSHIP_WITH_INDIVIDUAL_PERSON
        )
      : "";
    return (
      <div className="expert-details-sidebar mb-4">
        <SidebarContent title={""}>
          {(isPartner(this.props.roleId) &&
            (!enablePartnerShipWithIndividualPerson ||
              enablePartnerShipWithIndividualPerson === "false")) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId) ||
          isSuperAdmin(this.props.roleId) ? (
            <>
              <Field
                render={({ form }) => {
                  return (
                    <UploadAvatar
                      allowEdit={true}
                      id="partner"
                      data={this.props.partnerDetails}
                      apiURL={this.props.apiUrl}
                      square
                      hideUploadButton
                      setValue={form.setFieldValue}
                      width={"100%"}
                      height={"100%"}
                      lineHeight={"230px"}
                      backgroundSize={"contain"}
                      className="rounded-0"
                      size={"lg"}
                      loggedInUser={loggedInUser}
                    />
                  );
                }}
              />
              <div className="field-wrapper">
                <Text
                  id={id}
                  name={"companyName"}
                  placeholder={"Enter company name"}
                  label={"Company Name"}
                  error=""
                  required={true}
                />
              </div>
              <div className="field-wrapper">
                <Text
                  id={id}
                  name={"companyShortName"}
                  placeholder={"Enter Company Short Name"}
                  label={"Short Name"}
                  error=""
                  required={true}
                />
              </div>
              <div className="field-wrapper">
                <Text
                  id={id}
                  name={"companyProfileUrl"}
                  placeholder={"Enter Profile URL"}
                  label={"Profile URL"}
                  error=""
                  required={true}
                />
              </div>
            </>
          ) : (
            ""
          )}
          <div className="field-wrapper">
            <PartnerType
              id={id}
              isMulti
              name="partnerType"
              label="Type"
              placeholder="Select type"
              isSearchable={true}
              readOnly={isPartner(this.props.roleId) ? true : false}
              isDisabled={
                !isSuperAdmin(this.props.roleId) &&
                !isCompanyAdmin(this.props.roleId) &&
                !isCompanyManager(this.props.roleId)
                  ? true
                  : false
              }
              onChange={e => {
                this.handlePartnerType(e);
              }}
              required={!isPartner(this.props.roleId) ? true : false}
            />
          </div>
          <div className="field-wrapper">
            <PartnerTier
              id={id}
              name="partnerTier"
              label="Tier"
              placeholder="Select Tier"
              isSearchable={true}
              readOnly={isPartner(this.props.roleId) ? true : false}
              isDisabled={
                !isSuperAdmin(this.props.roleId) &&
                !isCompanyAdmin(this.props.roleId) &&
                !isCompanyManager(this.props.roleId)
                  ? true
                  : false
              }
              required={!isPartner(this.props.roleId) ? true : false}
              typeId={this.getSelectedPartnerTypeId()}
            />
          </div>
          <div className="field-wrapper">
            <Text
              id={id}
              name="address1"
              placeholder="Street Address1"
              error=""
              label={"Street Address1"}
            />
          </div>
          <div className="field-wrapper">
            <Text
              id={id}
              name="address2"
              placeholder="Street Address2"
              error=""
              label={"Street Address2"}
            />
          </div>
          <div className="field-wrapper">
            <Country
              id={id}
              name="countryName"
              label="Country"
              placeholder="Select Country"
              error=""
              onChange={this.handleCountryChange}
            />
          </div>
          <div className="field-wrapper">
            <State
              id={id}
              name="stateName"
              label="State/Province/Region"
              placeholder="Enter State"
              error=""
              selectedCountry={
                selectedOption ? selectedOption : selectedCountry
              }
              selectedCountryName={
                selectedCountryName ? selectedCountryName : ""
              }
              notRequired
            />
          </div>
          <div className="field-wrapper">
            <Text
              id={id}
              name="city"
              placeholder="Enter city"
              error=""
              label={"City"}
            />
          </div>
          <div className="field-wrapper">
            <Zipcode
              id={id}
              name="zipcode"
              label="Zip Code"
              placeholder="Enter Zipcode"
              error=""
              locale={selectedCountryName ? selectedCountryName : UNITED_STATES}
            />
          </div>
          <div className="field-wrapper">
            {this.props.showRegionField == true && (
              <Select
                name="region"
                label={"Region"}
                options={this.props.regionList}
                placeholder={"Select Region"}
                isSearchable={true}
              />
            )}
          </div>

          <div className="field-wrapper">
            <TimeZone
              id={id}
              name="timezone"
              label="Timezone"
              placeholder="Select Timezone..."
              defaultValue={TimeZones.find(
                timezone =>
                  timezone.value === this.props.partnerDetails.timezone
              )}
            />
          </div>
          <div className="field-wrapper">
            <Phone
              id={id}
              name="phone"
              label="Phone Number"
              placeholder="Phone number..."
            />
          </div>
          <div className="field-wrapper">
            <Email
              id={id}
              name="email"
              label="Email Address"
              placeholder="Email"
              error=""
            />
          </div>
        </SidebarContent>

        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) && (
          <SidebarContent title={""}>
            <h5 className="font-weight-bold">Crossbeam Partner</h5>
            <div className="field-wrapper">
              <CrossbeamPartner
                id={id}
                name="crossbeamPartner"
                placeholder="Select Partner"
                isSearchable={true}
              />
            </div>
          </SidebarContent>
        )}

        {this.props.enablePartnerManager === "true" && (
          <SidebarContent title={""} sidebarHeight={{ height: "165px" }}>
            <div className="text-truncate">
              <PartnerManager
                isPartnerManagerLoading={this.props.isPartnerManagerLoading}
                selectedManagerId={this.props.managerUserId}
                managerUser={this.props.managerUser}
                userList={this.props.userList}
                changeManager={this.props.changeManager}
              />
            </div>
          </SidebarContent>
        )}

        <SidebarContent title={""}>
          <h5 className="font-weight-bold">Social</h5>
          <div className="field-wrapper">
            <URL
              id={id}
              name={"websiteUrl"}
              addonText={<GlobeIcon />}
              addonType={"prepend"}
              placeholder={"Website url"}
            />
          </div>

          <div className="field-wrapper">
            <URL
              id={id}
              name={"facebookUrl"}
              addonText={<FacebookIcon />}
              addonType={"prepend"}
              placeholder={"Facebook url"}
            />
          </div>

          <div className="field-wrapper">
            <URL
              id={id}
              name={"twitterUrl"}
              addonText={<TwitterIcon />}
              addonType={"prepend"}
              placeholder={"Twitter url"}
            />
          </div>

          <div className="field-wrapper">
            <URL
              id={id}
              name={"linkedinUrl"}
              addonText={<LinkedinIcon />}
              addonType={"prepend"}
              placeholder={"Linkedin url"}
            />
          </div>

          <div className="field-wrapper">
            <URL
              id={id}
              name={"instagramUrl"}
              addonText={<InstagramIcon />}
              addonType={"prepend"}
              placeholder={"Instagram url"}
            />
          </div>

          <div className="field-wrapper">
            <URL
              id={id}
              name={"youtubeUrl"}
              addonText={<YoutubeIcon />}
              addonType={"prepend"}
              placeholder={"youtube url"}
            />
          </div>
        </SidebarContent>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(PartnerProfileSidebar);
