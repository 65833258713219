/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import Text from "../../components/base/Text";
import Form from "../../components/base/Form";
import UserRole from "../../components/UserRole";
import Email from "../../components/base/Email";

// Helper
import { toString } from "../../lib/helper";

// Assets
import createLandingIcon from "../../assets/pages/landing-page/create-landing-page.png";

// Action
import { addNewUser } from "../../actions/user";
import UploadAvatar from "./UploadAvatar";
import PageTitle from "../../components/base/PageTitle";
import Hint from "../../components/base/Hint";
import Select from "../../components/base/Select";
import {
  COMPANY_ADMIN_ROLE,
  ROLE_COMPANY_ADMIN_TEXT,
  COMPANY_MANAGER_ROLE,
  COMPANY_MANAGER_ROLE_TEXT,
  ROLE_SUPER_ADMIN_TEXT,
  SUPER_ADMIN_ROLE,
  PARTNER_ROLE_TEXT,
  PARTNER_ROLE,
  CUSTOMER_ROLE_TEXT,
  CUSTOMER_ROLE,
  EXPERT_ROLE_TEXT,
  EXPERT_ROLE,
  USERS_ROLE_TEXT,
  USERS_ROLE
} from "../../roles/Constants";

class AddUserModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      isOpen: false,
      file: ""
    };
    this._toggle = this._toggle.bind(this);
  }

  _submit = (values, toggle) => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save user form in the final
    this._createUser(this._toArray(values), toggle);
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const email = values.email;

    if (!email) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.firstName = toString(values.firstName).trim();
    values.lastName = toString(values.lastName).trim();
    values.email = toString(values.email);
    values.roleId = values.roleId
      ? values.roleId.value
      : this.props.supportPortal
      ? SUPER_ADMIN_ROLE
      : "";
    values["file"] = this.state.file;
    return values;
  }

  _toggle() {
    this.setState({ isOpen: !this.state.isOpen });
  }
  _createUser(data, toggle) {
    let { activeTab, id, supportPortal } = this.props;
    let roleId;

    if (id === USERS_ROLE_TEXT) {
      roleId = USERS_ROLE;
    } else if (activeTab === ROLE_SUPER_ADMIN_TEXT && supportPortal) {
      roleId = SUPER_ADMIN_ROLE;
    } else if (activeTab === ROLE_SUPER_ADMIN_TEXT && !supportPortal) {
      roleId = COMPANY_ADMIN_ROLE;
    } else if (activeTab === PARTNER_ROLE_TEXT) {
      roleId = PARTNER_ROLE;
    } else if (activeTab === CUSTOMER_ROLE_TEXT) {
      roleId = CUSTOMER_ROLE;
    } else if (activeTab === EXPERT_ROLE_TEXT) {
      roleId = EXPERT_ROLE;
    }

    this.props.actions.addNewUser(
      data,
      roleId,
      this.props.currentParams,
      toggle
    );
    // Reset the file
    this.setState({ file: "" });
  }

  // Handle Image
  handleImage = file => {
    this.setState({ file });
  };

  render() {
    const { isOpen } = this.state;
    const initialValues = {
      firstName: "",
      lastName: "",
      email: "",
      roleId: ""
    };

    //user roles
    const userRoles = [
      {
        value: COMPANY_ADMIN_ROLE,
        label: ROLE_COMPANY_ADMIN_TEXT
      },
      {
        value: COMPANY_MANAGER_ROLE,
        label: COMPANY_MANAGER_ROLE_TEXT
      }
    ];

    return (
      <div>
        {!this.props.supportPortal && (
          <PageTitle
            label="Users"
            buttonHandler={
              this.props.activeTab == ROLE_SUPER_ADMIN_TEXT ? this._toggle : ""
            }
            buttonLabel={
              this.props.activeTab == ROLE_SUPER_ADMIN_TEXT ? "Add New" : ""
            }
          />
        )}
        {this.props.supportPortal && (
          <div className="pt-3 pb-3">
            <PageTitle buttonHandler={this._toggle} buttonLabel="Add New" />
          </div>
        )}
        <Modal
          isOpen={isOpen}
          toggle={this._toggle}
          className="edit-task-modal"
          backdrop="static"
        >
          <ModalHeader
            toggle={this._toggle}
            cssModule={{ "modal-title": "w-100 text-center" }}
          >
            <div className="content-wrapper">
              <div className="icon-wrapper">
                <div className="row justify-content-center mb-2 mt-3">
                  <img src={createLandingIcon} size="30" alt="" />
                </div>
                <p className="text-center mb-3">Create a new User</p>
                <p
                  className="text-center"
                  style={{ fontSize: "14px", lineHeight: "20px" }}
                ></p>
              </div>
              {/* /.icon-wrapper */}
            </div>
            {/* /.content-wrapper */}
          </ModalHeader>

          <Form
            initialValues={initialValues}
            onSubmit={values => {
              this._submit(values, this._toggle);
            }}
          >
            <ModalBody>
              <div className="mt-2 mb-3">
                <UploadAvatar getFile={this.handleImage} />
                <div>
                  <Text
                    name="firstName"
                    label="First Name"
                    placeholder="Enter First Name"
                    required={true}
                    error=""
                  />
                </div>
                <div>
                  <Text
                    name="lastName"
                    label="Last Name"
                    placeholder="Enter Last Name"
                    error=""
                    required={true}
                  />
                </div>
                <div>
                  <Email
                    label="Email"
                    name="email"
                    placeholder="Enter Email"
                    required={true}
                  />
                </div>
                <div>
                  {!this.props.supportPortal && (
                    <Select
                      id="roleId"
                      name="roleId"
                      placeholder="Select Role"
                      options={userRoles}
                      required
                      label="Role"
                    />
                  )}
                  {this.props.supportPortal && (
                    <UserRole
                      name="roleId"
                      required
                      label="Role"
                      roleNames={ROLE_SUPER_ADMIN_TEXT}
                    />
                  )}
                  <Hint hintText="To invite a partner, please go to the Partners screen and tap Invite Partner" />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="container-fluid">
                <div className="col-sm-12 text-center">
                  <Button type="submit" label="">
                    Create User
                  </Button>
                </div>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ addNewUser }, dispatch)
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(AddUserModal);
