// Components
import toast from "../components/base/Toast";
import { endpoints } from "../configs";

// Configs
import { apiClient } from "../apiClient";

import { fetchList } from "../actions/table";
import { isBadRequest } from "../common/http";

const params = { pagination: true };
export function schedulerUpdate(id, data, params) {
  let successMessage;
  return dispatch => {
    return apiClient
      .put(`${endpoints().schedulerJobAPI}/${id}`, data)
      .then(response => {
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        dispatch(
          fetchList(
            "scheduler",
            `${endpoints().schedulerJobAPI}/search`,
            1,
            10,
            {
              ...params
            }
          )
        );
        return successMessage;
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Create Scheduler
 *
 * @param data
 * @param id
 */
export function schedulerCreate(data) {
  let successMessage;
  return dispatch => {
    return apiClient
      .post(`${endpoints().schedulerJobAPI}`, data)
      .then(response => {
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        dispatch(
          fetchList(
            "scheduler",
            `${endpoints().schedulerJobAPI}/search`,
            1,
            10,
            {
              ...params
            }
          )
        );
        return successMessage;
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}

export function schedulerDelete(id) {
  return dispatch => {
    apiClient
      .delete(`${endpoints().schedulerJobAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        dispatch(
          fetchList(
            "scheduler",
            `${endpoints().schedulerJobAPI}/search`,
            1,
            10,
            {
              ...params
            }
          )
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}
