import React from "react";
import toast from "../../../../components/base/Toast";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

// Components
import PageTitle from "../../../../components/base/PageTitle";
import { Table } from "reactstrap";
import Page404 from "../../../Page404";

// Helper
import { isSuperAdmin, isCompanyAdmin } from "../../../../lib/helper";
import { isBadRequest } from "../../../../common/http";
import { connect } from "react-redux";

class RecordType extends React.Component {
  state = {
    recordTypeList: ""
  };

  componentDidMount() {
    this.salesforceAccountRecordType();
  }

  salesforceAccountRecordType = () => {
    return apiClient
      .get(`${endpoints().salesforceAPI}/recordType/Account`)
      .then(response => {
        this.setState({ recordTypeList: response.data });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  render() {
    const { recordTypeList } = this.state;
    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId)
    ) {
      return <Page404 />;
    }
    const { activeSubTab, activeSection } = this.props;
    return (
      <>
        {!activeSection ? (
          <>
            <PageTitle label="Account Record Types" />

            {/* Main Layout */}
            <div>
              {/* Search Bar */}
              <div>
                {/* Opportunity List */}
                <Table className="table-hover bg-white">
                  <thead className="thead-dark table-bordered">
                    <tr>
                      <th>RecordType Name</th>
                      <th>RecordType Id</th>
                    </tr>
                  </thead>
                  <tbody>
                    {recordTypeList && recordTypeList.length > 0 ? (
                      recordTypeList.map(recordTypeList => (
                        <tr>
                          <td>{recordTypeList.name}</td>
                          <td>{recordTypeList.recordTypeId}</td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td className="text-center p-4" colSpan={2}>
                          <p>No Result Found</p>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              </div>
            </div>
          </>
        ) : (
          <>{activeSubTab === "RecordType" && <RecordType />}</>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(RecordType);
