// Playbook Purchase Flow Events
export const METACX_EVENT_PLAYBOOK_DETAILS_ORDER_BUTTON_CLICK =
  "playbook details-order";
export const METACX_EVENT_PLAYBOOK_CHECKOUT_USER_LOGIN_BUTTON_CLICK =
  "playbook checkout-login";
export const METACX_EVENT_PLAYBOOK_CHECKOUT_GOOGLE_AUTH_BUTTON_CLICK =
  "playbook checkout-continue with google";
export const METACX_EVENT_PLAYBOOK_CHECKOUT_LINKEDIN_AUTH_BUTTON_CLICK =
  "playbook checkout-continue with linkedin";
export const METACX_EVENT_PLAYBOOK_CHECKOUT_OFFICE365_AUTH_BUTTON_CLICK =
  "playbook checkout-continue with microsoft";
export const METACX_EVENT_PLAYBOOK_CHECKOUT_SALESFORCE_AUTH_BUTTON_CLICK =
  "playbook checkout-continue with salesforce";
export const METACX_EVENT_PLAYBOOK_CHECKOUT_JOIN_NOW_LINK_CLICK =
  "playbook checkout-join now";
export const METACX_EVENT_PLAYBOOK_CONTINUE_ORDER_BUTTON_CLICK =
  "playbook order summary-continue to deposit";
export const METACX_EVENT_PLAYBOOK_CHECKOUT_PAY_NOW_BUTTON_CLICK =
  "playbook checkout-pay deposit";

//Landing Page Events
export const METACX_EVENT_MARKETPLACE_LANDING_PAGE_VISIT = "landing page";
export const METACX_EVENT_MARKETPLACE_LANDING_PAGE_PLAYBOOK_CLICK =
  "landing page-view playbook";
export const METACX_EVENT_MARKETPLACE_LANDING_PAGE_REGISTER_HERE_BUTTON_CLICK =
  "landing page-register here";
export const METACX_EVENT_MARKETPLACE_LANDING_PAGE_CONTACT_US_BUTTON_CLICK =
  "landing page-contact us";
export const METACX_EVENT_MARKETPLACE_LANDING_PAGE_REQUEST_ADVISOR_BUTTON_CLICK =
  "landing page-request an advisor";
export const METACX_EVENT_MARKETPLACE_LANDING_PAGE_LEARN_MORE_BUTTON_CLICK =
  "landing page-learn more";
export const METACX_EVENT_MARKETPLACE_LANDING_PAGE_GET_SOCIAL_BUTTON_CLICK =
  "landing page-get social";

//Customer Signup
export const METACX_EVENT_CUSTOMER_SIGNUP_SUCCESS = "Customer-Signup";
