import { Skills } from "../Skills";

import { getExpertHourlyOption } from "../../expertHours/Constants";

import { getExpertAvailabilityOption } from "../../expertAvailability/Constants";

import { getExpertLevelOfExperienceOption } from "../../expertLevelOfExperience/Constants";

const paragraphs = [
  "I'm looking for an Expert {expertTypeObj}",
  "with {multiSelect} expertise and {certification}",
  "that is {availabilityObj} for at least {hoursObj}"
];

const extraParagraphs = [
  "That has experience in {industry}",
  "that utilizes {tool}",
  "that has {levelOfExp}",
  "that is located in {location}"
];

const selectObj = {
  expertTypeObj: [
    {
      label: "Expert type",
      value: "Expert type",
      selected: false,
      placeholder: true
    },
    {
      label: "Graphic Designer",
      value: "Graphic Designer",
      selected: false
    },
    {
      label: "Front-end Developer",
      value: "Front-end Developer",
      selected: false
    }
  ],
  hoursObj: getExpertHourlyOption(),
  availabilityObj: getExpertAvailabilityOption(),
  industry: [
    {
      label: "industry",
      value: "industry",
      selected: false,
      placeholder: true
    },
    {
      label: "Accomodations",
      value: "Accomodations",
      selected: false
    },
    {
      label: "Accounting",
      value: "Accounting",
      selected: false
    },
    {
      label: "Advertising",
      value: "Advertising",
      selected: false
    },
    {
      label: "Aerospace",
      value: "Aerospace",
      selected: false
    },
    {
      label: "Agriculture",
      value: "Agriculture",
      selected: false
    },
    {
      label: "Air Transportation",
      value: "Air Transportation",
      selected: false
    }
  ],
  tool: [
    {
      multiselect: 1,
      label: "tool",
      value: "",
      placeholder: true
    },
    {
      label: "Photoshop",
      value: "Photoshop",
      selected: false
    },
    {
      label: "Illustrator",
      value: "Illustrator",
      selected: false
    },
    {
      label: "InDesign",
      value: "InDesign",
      selected: false
    },
    {
      label: "Sketch",
      value: "Sketch",
      selected: false
    },
    {
      label: "InVision",
      value: "InVision",
      selected: false
    },
    {
      label: "Figma",
      value: "Figma",
      selected: false
    }
  ],
  levelOfExp: getExpertLevelOfExperienceOption(),
  multiSelect: [
    {
      multiselect: 1,
      label: "skill",
      value: "",
      placeholder: true
    },
    ...Skills
  ],
  certification: [
    {
      multiselect: 1,
      label: "certification",
      value: "",
      placeholder: true
    }
  ],
  location: [
    {
      location: true
    }
  ]
};

export { paragraphs, extraParagraphs, selectObj };
