import React from "react";
import PropTypes from "prop-types";
import Avatar from "./Avatar";
import { Link } from "react-router-dom";

class AccountCard extends React.Component {
  render() {
    const { title, firstName, lastName, companyName, website } = this.props;
    const names = [];
    if (firstName) {
      names.push(firstName);
    }
    if (lastName) {
      names.push(lastName);
    }
    if (companyName) {
      names.push(companyName);
    }
    const name = names.join(" ");

    return (
      <div style={{ display: "table" }}>
        <div
          style={{
            display: "table-cell",
            verticalAlign: "middle",
            paddingRight: 10
          }}
        >
          <Avatar {...this.props} />
        </div>
        {(name || title || companyName) && (
          <div style={{ display: "table-cell", verticalAlign: "middle" }}>
            {(companyName || name) && (
              <>
                <h3
                  style={{
                    fontSize: 16,
                    fontWeight: "bold",
                    margin: "0 0 2px"
                  }}
                >
                  {companyName || name}
                </h3>
                <h3
                  style={{
                    fontSize: 14,
                    margin: "0 0 2px",
                    marginTop: "0.5rem"
                  }}
                >
                  {website ? website : ""}
                </h3>
              </>
            )}
            {title && (
              <p style={{ fontSize: 13, margin: 0 }} className="text-muted">
                {title}
              </p>
            )}
          </div>
        )}
      </div>
    );
  }
}

AccountCard.propTypes = {
  url: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  defaultUrl: PropTypes.string,
  size: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  square: PropTypes.bool,
  customSize: PropTypes.number
};

export default AccountCard;
