import React from "react";
import Select from "./base/Select";

import { Collapse } from "reactstrap";
import SingleCheckbox from "../views/adminSettings/inner-component/Checkbox";
import { isEmpty } from "../lib/helper";

class LandingPageFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: 5
    };
  }
  componentDidMount() {
    this.setState({ [this.props.displayName]: true });
  }
  SectionBreakLine = className => {
    return (
      <div
        className={`pt-1 mb-3`}
        style={{ borderBottom: "1px solid #f3f3f4" }}
      ></div>
    );
  };

  getFilterOptions = tagList => {
    let options = [];
    if (tagList && tagList.length > 0) {
      tagList.forEach(state => {
        options.push({
          value: state.id,
          label: state.name
        });
      });
    }

    return options;
  };

  _toggle = tagName => {
    this.setState({ [tagName]: !this.state[tagName] });
  };

  showAll = length => {
    length && this.setState({ visible: length });
  };

  render() {
    const {
      tagTypeList,
      multiselect,
      displayName,
      id,
      fieldStartName
    } = this.props;
    const tagType = tagTypeList && tagTypeList[0];
    const { visible } = this.state;

    return (
      <>
        {tagType && (
          <div className="col-12">
            <p
              className="cursor-pointer mb-3"
              onClick={() => {
                this._toggle(displayName);
              }}
            >
              {isEmpty(tagType.tags) && (
                <>
                  <b>{displayName}</b>
                  {this.state[displayName] && multiselect ? (
                    <i
                      className="fa fa-minus pull-right pt-1"
                      aria-hidden="true"
                    ></i>
                  ) : multiselect ? (
                    <i
                      className="fa fa-plus pull-right pt-1"
                      aria-hidden="true"
                    ></i>
                  ) : (
                    ""
                  )}
                </>
              )}
            </p>
            {multiselect ? (
              <>
                <Collapse isOpen={this.state[displayName]}>
                  <div className="form-wrapper">
                    {tagType.tags &&
                      tagType.tags
                        .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
                        .slice(0, visible)
                        .map(tag => {
                          return (
                            <div
                              className="field-wrapper"
                              key={`parnter-checkbox-filter-${tag.id}`}
                            >
                              <SingleCheckbox
                                name={`${
                                  fieldStartName ? fieldStartName : "checkbox"
                                }-${tag.id}`}
                                label={tag.name}
                                className="accepted-terms  pb-0 mr-3"
                                handleOnChangeSubmit={
                                  this.props.handleOnchangeSubmit
                                }
                              />
                            </div>
                          );
                        })}
                  </div>
                </Collapse>
                {tagType.tags.length > visible && (
                  <div className="col-12">
                    <p
                      className="cursor-pointer mb-3"
                      onClick={() => {
                        this.showAll(tagType.tags.length);
                      }}
                      style={{ color: "primary" }}
                    >
                      <p style={{ color: "#00cccc", fontSize: 14 }}>
                        Show all...
                      </p>
                      <i className="fa pull-right pt-1" aria-hidden="true"></i>
                    </p>
                  </div>
                )}
                {tagType.tags &&
                  tagType.tags.length > 0 &&
                  this.SectionBreakLine()}
              </>
            ) : (
              <div className="form-wrapper">
                <div className="field-wrapper">
                  {tagType.tags && tagType.tags.length > 0 && (
                    <Select
                      id={id}
                      name={tagType.name}
                      placeholder="Choose an option..."
                      options={this.getFilterOptions(
                        tagType.tags.sort(
                          (a, b) => parseFloat(a.sort) - parseFloat(b.sort)
                        )
                      )}
                      isSearchable={true}
                      handleOnChangeSubmit={this.props.handleOnchangeSubmit}
                    />
                  )}
                </div>
              </div>
            )}
          </div>
        )}
      </>
    );
  }
}
export default LandingPageFilter;
