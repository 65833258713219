import React from "react";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import Page404 from "../Page404";

import AddCustomerModal from "./AddCustomerModal";
import { CustomerIcon } from "../../assets/img/icons";

// Helper
import {
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import { connect } from "react-redux";

class Customers extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "display_company_name:ASC",
          label: "Company Name"
        }
      ]
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId) &&
      !isCompanyManager(this.props.roleId)
    ) {
      return <Page404 />;
    }
    const search = window.location.search;
    const pathParams = new URLSearchParams(search);

    const searchItem = pathParams.get("search");

    let params = {
      searchItem: searchItem
    };

    const headerButton = <AddCustomerModal />;

    return (
      <>
        {/* /.page-heading */}
        {headerButton}
        <div className="mt-4 mb-5">
          <ReduxTable
            id="customers"
            params={params}
            showHeader
            searchPlaceholder="Search"
            apiURL="/v1/customer"
            onRowClick={row => this.props.history.push(`/customer/${row.id}`)}
            newTableHeading
            icon={<CustomerIcon />}
            message="Get started by selecting New Customer."
            sortByOptions={this.state.sortByOptions}
            paramsToUrl={true}
            history={this.props.history}
          >
            <ReduxColumn
              width={"150px"}
              type="link"
              isClickable="true"
              field="displayCompanyName"
              sortBy="display_company_name"
            >
              Customer
            </ReduxColumn>
            <ReduxColumn
              width={"325px"}
              field="legalContactEmail"
              sortBy="legal_contact_email"
            >
              Email
            </ReduxColumn>
            <ReduxColumn Width={"135px"} field="createdAt" sortBy="createdAt">
              Created
            </ReduxColumn>
            <ReduxColumn
              width={"200px"}
              field="recentProject"
              sortBy="recentProject"
              type="link"
              isClickable="true"
              onLinkClick={row =>
                this.props.history.push(`/project/${row.projectId}`)
              }
            >
              Recent Project
            </ReduxColumn>
          </ReduxTable>
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(Customers);
