import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownItem } from "reactstrap";
import { toast } from "react-toastify";
import { Link } from "react-router-dom";

// Components
import MoreDropdown from "../../components/base/MoreDropdown";
import SourcePartners from "./list-components/SourcePartners";
import DeleteModal from "../../components/base/DeleteModal";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";
import { getParamsByName, isLoggedIn } from "../../lib/helper";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

// Action
import { updatePlaybookInquiry } from "../../actions/playbookInquiry";
import { fetchList } from "../../actions/table";
import {
  LEAD_SOURCE_FIND_AN_EXPERT,
  LEAD_SOURCE_PLAYBOOK_INQUIRY,
  LEAD_SOURCE_PARTNER_CONTACT,
  LEAD_STATUS_ACCEPT,
  LEAD_STATUS_IN_PROGRESS,
  LEAD_PARTNER_STATUS_PENDING,
  LEAD_STATUS_CLOSE
} from "../../lead/Constants";

import { SendIcon } from "../../assets/img/icons";
import { isBadRequest } from "../../common/http";

const statusStyle = {
  borderRadius: "5px",
  fontSize: "12px"
};

const partnerStyle = {
  width: "120px"
};

class NewLead extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "name:ASC",
          label: "Name"
        }
      ],
      deleteLeadModal: false,
      deleteLead: false
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  // Update The Expert By Status
  updatePlaybookInquiryStatus(status, playbookInquiryId, params) {
    this.props.actions.updatePlaybookInquiry(
      playbookInquiryId,
      { status: status },
      params ? params : {}
    );
  }

  // Delete
  handleDelete = id => {
    apiClient
      .delete(`${endpoints().lead}/${id}`)
      .then(response => {
        toast.success(response.data.message);

        this.props.actions.fetchList(
          "playbookInquiry",
          "/v1/lead/search",
          1,
          10,
          { status: LEAD_PARTNER_STATUS_PENDING, pagination: true }
        );

        this.props.actions.fetchList(
          "leadInProgressList",
          "/v1/lead/search",
          1,
          10,
          { status: LEAD_STATUS_IN_PROGRESS, pagination: true }
        );

        this.props.actions.fetchList(
          "leadConvertedList",
          "/v1/lead/search",
          1,
          10,
          { status: LEAD_STATUS_CLOSE, pagination: true }
        );

        this.props.actions.fetchList("leadAllList", "/v1/lead/search", 1, 10, {
          pagination: true
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  handleRedirectToDetailsPage = row => {
    if (row.source === LEAD_SOURCE_PLAYBOOK_INQUIRY) {
      this.props.history.push(`/leads/playbook-inquiry/${row.id}`);
    } else if (row.source === LEAD_SOURCE_FIND_AN_EXPERT) {
      this.props.history.push(`/leads/expert-requests/details/${row.id}`);
    } else if (row.source === LEAD_SOURCE_PARTNER_CONTACT) {
      this.props.history.push(`/leads/partner-contact/details/${row.id}`);
    } else {
      this.props.history.push(`/leads/partner-contact/details/${row.id}`);
    }
  };

  render() {
    const search = window.location.search;
    const pathParams = new URLSearchParams(search);
    const searchItem = pathParams.get("search");
    let params = {
      section: "newleads",
      searchItem: searchItem,
      status: "Pending",
      pagination: true
    };
    const { deleteLeadModal, deleteLead } = this.state;
    return (
      <>
        <ReduxTable
          id="playbookInquiry"
          apiURL={`/v1/lead/search`}
          newTableHeading
          showHeader
          searchPlaceholder="Search"
          onRowClick={this.handleRedirectToDetailsPage}
          params={params}
          sortByOptions={this.state.sortByOptions}
          icon={<SendIcon />}
          message="After you get a lead, it will show up here."
          subtextMessage="Leads come when prospects request to contact you through the Partner Directory"
          paramsToUrl={true}
          history={this.props.history}
        >
          <ReduxColumn
            field="name"
            sortBy="name"
            type="link"
            isClickable="true"
            renderField={row => (
              <Link to={`leads/partner-contact/details/${row.id}`}>
                {row.name}
              </Link>
            )}
          >
            Name
          </ReduxColumn>
          <ReduxColumn
            field="status"
            sortBy="status"
            renderField={row => (
              <div
                className={`text-center text-uppercase w-100 border-5 text-white pl-1 pr-1 font-weight-bold ${
                  row.status === LEAD_PARTNER_STATUS_PENDING
                    ? row.status === LEAD_STATUS_IN_PROGRESS
                      ? "landing-page-status-active"
                      : "landing-page-status-draft"
                    : "bg-danger"
                }`}
                style={statusStyle}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="parnter"
            sortBy="parnter"
            style={partnerStyle}
            renderField={row => (
              <>
                {row.partner && row.partner.company_name ? (
                  <p className="text-center">
                    {row.status === LEAD_STATUS_IN_PROGRESS &&
                      row.partner.company_name}
                  </p>
                ) : (
                  <div style={partnerStyle}>
                    <SourcePartners leadId={row.id} />
                  </div>
                )}
              </>
            )}
          >
            Partners
          </ReduxColumn>
          <ReduxColumn field="source" sortBy="source">
            Source
          </ReduxColumn>
          <ReduxColumn field="createdAt" sortBy="createdAt">
            Created
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <Link
                    className="text-dark text-decoration-none"
                    to={
                      row.source === LEAD_SOURCE_PLAYBOOK_INQUIRY
                        ? `/leads/playbook-inquiry/${row.id}`
                        : row.source === LEAD_SOURCE_FIND_AN_EXPERT
                        ? `/leads/expert-requests/details/${row.id}`
                        : row.source === LEAD_SOURCE_PARTNER_CONTACT
                        ? `/leads/partner-contact/details/${row.id}`
                        : `/leads/partner-contact/details/${row.id}`
                    }
                    style={{ cursor: "pointer" }}
                  >
                    <DropdownItem>Edit Details</DropdownItem>
                  </Link>
                  {row.status !== "Pending" && (
                    <DropdownItem
                      onClick={() =>
                        this.updatePlaybookInquiryStatus("Pending", row.id)
                      }
                    >
                      Pending
                    </DropdownItem>
                  )}
                  {row.status !== LEAD_STATUS_IN_PROGRESS && (
                    <DropdownItem
                      onClick={() =>
                        this.updatePlaybookInquiryStatus(
                          LEAD_STATUS_IN_PROGRESS,
                          row.id,
                          params
                        )
                      }
                    >
                      {LEAD_STATUS_ACCEPT}
                    </DropdownItem>
                  )}
                  {row.status !== "Close" && (
                    <DropdownItem
                      onClick={() =>
                        this.updatePlaybookInquiryStatus(
                          "Close",
                          row.id,
                          params
                        )
                      }
                    >
                      Close
                    </DropdownItem>
                  )}
                  <DropdownItem
                    className="text-danger"
                    onClick={() =>
                      this.setState({
                        deleteLeadModal: true,
                        deleteLead: row
                      })
                    }
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          isOpen={deleteLeadModal}
          toggle={() => {
            this.setState({ deleteLeadModal: false });
          }}
          title="Delete Lead"
          label={deleteLead.name}
          deleteFunction={() => this.handleDelete(deleteLead.id)}
        />
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updatePlaybookInquiry, fetchList }, dispatch)
  };
}

const mapStateToProps = state => {
  const { playbookInquiry } = state.table;
  const playbookInquirySort =
    playbookInquiry && !playbookInquiry.isFetching
      ? playbookInquiry.sort
      : "createdAt";

  const playbookInquirySortDir =
    playbookInquiry && !playbookInquiry.isFetching
      ? playbookInquiry.sortDir
      : "DESC";
  return { playbookInquirySort, playbookInquirySortDir };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(NewLead);
