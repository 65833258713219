import React, { useEffect, useState } from "react";
import moment from "moment";

// Helper
import {
  isCustomer,
  isExpert,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../../lib/helper";

// Icons
import { EditIconAlt } from "../../../../assets/img/icons";

// Components
import SidebarItem from "./SidebarItem";
import UpdateBudgetModal from "../UpdateBudgetModal";
import EditTimelineModal from "../EditTimelineModal";
import EditClientModal from "../EditClientModal";
import KickOffSidebarInfo from "../kickoff/KickOffSidebarInfo";

import KickoffProvider from "../../../../context/kickoffContext/KickoffProvider";

// API Call
import { apiClient } from "../../../../apiClient";
import { endpoints } from "../../../../configs";

// Constants
import { PROJECT_SOURCE_EXPERT_MAX_DAYS } from "../../../../project/Constants";

import EditKickoffScheduleModal from "../../../../views/projects/inner-components/EditKickoffScheduleModal";
import { isBadRequest } from "../../../../common/http";

import { useSelector } from "react-redux";

const SidebarClientInfo = React.memo(function SidebarClientInfo({
  customerName,
  estimatedBudget,
  invoicedAmount,
  updateBudgetModal,
  setUpdateBudgetModal,
  onChangeBudget,
  timelineModal,
  setTimelineModal,
  startDate,
  endDate,
  setDate,
  clientModal,
  setClientModal,
  updateProject,
  newEstimatedBudget,
  formattedStartDate,
  formattedEndDate,
  showSourcingExpertTimeFrame,
  approvedExpertId,
  projectId,
  kickoffHappened
}) {
  const [clientList, setClientList] = useState([]);
  const [customClient, setCustomClient] = useState(null);
  const [kickoffLoaded, setKickoffLoaded] = useState(false);
  const [kickoffData, setKickoffData] = useState({});
  const [editKickoffModel, setEditKickoffModel] = useState(false);

  const currentUser = useSelector(state => state.user);

  // Get Customer Details
  const getCustomers = () => {
    return apiClient
      .get(`${endpoints().customerAPI}`)
      .then(response => {
        let companyArray = response.data.data.map(company => ({
          value: company.id,
          label: company.displayCompanyName
        }));
        return setClientList(companyArray);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  const toggle = () => {
    setEditKickoffModel(prevState => !prevState);
  };

  useEffect(() => {
    getCustomers();

    if (approvedExpertId) {
      getKickoffTimes(true);
    }
  }, []);

  // Get Kickoff Times
  const getKickoffTimes = isLoading => {
    if (approvedExpertId) {
      return apiClient
        .get(`/v1/project/${projectId}/kickoff/${approvedExpertId}`)
        .then(res => {
          if (res) {
            setKickoffData(res.data.data);
            setKickoffLoaded(isLoading);
          }
        })
        .catch(err => console.error(err));
    }
  };

  // Get Date Diff
  const getDateDiff = (startDate, endDate) => {
    startDate = moment(startDate, "MMM DD, YYYY");
    endDate = moment(endDate, "MMM DD, YYYY");

    return endDate.diff(startDate, "days");
  };

  // Get Expert Sourcing Time Frame
  const getExpertSourcingTimeframe = () => {
    const completedDays = getDateDiff(startDate, moment());

    if (completedDays > PROJECT_SOURCE_EXPERT_MAX_DAYS) {
      return { completedDays: PROJECT_SOURCE_EXPERT_MAX_DAYS };
    }

    return { completedDays: completedDays || 0 };
  };

  const kickoffCallTimes = kickoffLoaded
    ? kickoffData && kickoffData.times
    : [];

  return (
    <>
      {(isSuperAdmin(currentUser && currentUser.roleId) ||
        isCompanyAdmin(currentUser && currentUser.roleId) ||
        isCompanyManager(currentUser && currentUser.roleId)) && (
        <KickoffProvider projectId={projectId} expertId={approvedExpertId}>
          <KickOffSidebarInfo
            projectId={projectId}
            expertId={approvedExpertId}
            editKickoff={true}
            toggle={toggle}
          />
        </KickoffProvider>
      )}

      <SidebarItem>
        {!kickoffHappened &&
          kickoffData &&
          kickoffCallTimes.length > 0 &&
          !kickoffData.selectedTimeId &&
          (isSuperAdmin(currentUser && currentUser.roleId) ||
            isCompanyAdmin(currentUser && currentUser.roleId) ||
            isCompanyManager(currentUser && currentUser.roleId)) && (
            <div className="supporting-materials">
              <div className="supporting-material1">
                <div className="field-wrapper">
                  <p className="font-weight-bold">
                    Kickoff Call{" "}
                    {kickoffData && !kickoffData.selectedTimeId
                      ? `(Customer Times)`
                      : ""}
                  </p>

                  <span className="d-block font-weight-bold mt-2">
                    {kickoffLoaded &&
                      kickoffData &&
                      !kickoffData.selectedTimeId &&
                      kickoffCallTimes &&
                      kickoffCallTimes.map((kickoffCallTime, index) => (
                        <>
                          <p className="p-1">
                            <span className="text-grayed">
                              {moment(kickoffCallTime.date).format("MMMM DD")}
                              {kickoffCallTime.time
                                ? ` at ${moment(
                                    kickoffCallTime.time,
                                    "h:mm A"
                                  ).format("h:mm a")}`
                                : ""}
                            </span>{" "}
                            {index === 0 && (
                              <a
                                href="#"
                                onClick={() => toggle()}
                                style={{ fontSize: "12px" }}
                              >
                                Edit
                              </a>
                            )}
                          </p>
                        </>
                      ))}
                  </span>
                </div>
              </div>
            </div>
          )}
        {/* Edit Kickoff Call */}
        {editKickoffModel && (
          <EditKickoffScheduleModal
            isOpen={editKickoffModel}
            kickoffData={kickoffData}
            expertId={approvedExpertId}
            projectId={projectId}
            kickoffLoaded={kickoffLoaded}
            getKickoffTimes={e => getKickoffTimes(e)}
            toggle={() => toggle()}
          />
        )}
        {(isSuperAdmin(currentUser && currentUser.roleId) ||
          isCompanyAdmin(currentUser && currentUser.roleId) ||
          isCompanyManager(currentUser && currentUser.roleId) ||
          isExpert(currentUser && currentUser.roleId)) && (
          <>
            {(isSuperAdmin(currentUser && currentUser.roleId) ||
              isCompanyAdmin(currentUser && currentUser.roleId) ||
              isCompanyManager(currentUser && currentUser.roleId)) &&
            !customerName ? (
              <div className="supporting-materials">
                <div className="supporting-material">
                  <div className="field-wrapper">
                    <p className="font-weight-bold">Client</p>
                    <span className="d-block text-grayed font-weight-bold">
                      {customClient ? customClient : "Set Client"}
                    </span>
                  </div>
                  {setClientModal && (
                    <div className="action">
                      <a
                        href="#"
                        onClick={e => {
                          e.preventDefault();
                          setClientModal();
                        }}
                      >
                        <EditIconAlt />
                      </a>
                    </div>
                  )}
                </div>
                {setClientModal &&
                  (isSuperAdmin(currentUser && currentUser.roleId) ||
                    isCompanyAdmin(currentUser && currentUser.roleId) ||
                    isCompanyManager(currentUser && currentUser.roleId)) && (
                    <EditClientModal
                      modal={clientModal}
                      setModal={setClientModal}
                      clientList={clientList}
                      setCustomClient={setCustomClient}
                      updateProject={updateProject}
                    />
                  )}
              </div>
            ) : (
              <div className="field-wrapper">
                <p className="font-weight-bold">Client</p>
                <span className="d-block text-grayed font-weight-bold">
                  {customerName}
                </span>
              </div>
            )}
          </>
        )}
        {showSourcingExpertTimeFrame &&
          isCustomer(currentUser && currentUser.roleId) && (
            <div className="field-wrapper">
              <p className="font-weight-bold">Expert Sourcing Timeframe</p>
              <progress
                max={PROJECT_SOURCE_EXPERT_MAX_DAYS}
                value={getExpertSourcingTimeframe().completedDays}
              />
              <span className="d-block text-grayed font-weight-bold">
                {`Day ${
                  getExpertSourcingTimeframe().completedDays
                } of ${PROJECT_SOURCE_EXPERT_MAX_DAYS}`}
              </span>
            </div>
          )}
        {estimatedBudget > 0 && (
          <>
            {(isSuperAdmin(currentUser && currentUser.roleId) ||
              isCompanyAdmin(currentUser && currentUser.roleId) ||
              isCompanyManager(currentUser && currentUser.roleId)) && (
              <div className="supporting-materials">
                <div className="supporting-material">
                  <div className="field-wrapper">
                    <p className="font-weight-bold">Budget</p>
                    <span className="d-block text-grayed font-weight-bold">
                      Invoiced ${invoicedAmount} of $
                      {estimatedBudget ? estimatedBudget : 0}
                    </span>
                  </div>
                  {setUpdateBudgetModal &&
                    (isSuperAdmin(currentUser && currentUser.roleId) ||
                      isCompanyAdmin(currentUser && currentUser.roleId) ||
                      isCompanyManager(currentUser && currentUser.roleId)) && (
                      <div className="action">
                        <a
                          href="#"
                          onClick={e => {
                            e.preventDefault();
                            setUpdateBudgetModal();
                          }}
                        >
                          <EditIconAlt />
                        </a>
                      </div>
                    )}
                </div>
                {setUpdateBudgetModal &&
                  (isSuperAdmin(currentUser && currentUser.roleId) ||
                    isCompanyAdmin(currentUser && currentUser.roleId) ||
                    isCompanyManager(currentUser && currentUser.roleId)) && (
                    <UpdateBudgetModal
                      modal={updateBudgetModal}
                      setModal={setUpdateBudgetModal}
                      currentBudget={estimatedBudget}
                      onChangeBudget={onChangeBudget}
                      updateProject={updateProject}
                      newEstimatedBudget={newEstimatedBudget}
                    />
                  )}
              </div>
            )}

            {(isCustomer(currentUser && currentUser.roleId) ||
              isExpert(currentUser && currentUser.roleId)) && (
              <div className="field-wrapper">
                <p className="font-weight-bold">Budget</p>
                <span className="d-block text-grayed font-weight-bold">
                  {isCustomer(currentUser && currentUser.roleId) &&
                    `Invoiced $${invoicedAmount} of `}
                  ${estimatedBudget ? estimatedBudget : 0}
                </span>
              </div>
            )}
          </>
        )}
        {isSuperAdmin(currentUser && currentUser.roleId) ||
        isCompanyAdmin(currentUser && currentUser.roleId) ||
        isCompanyManager(currentUser && currentUser.roleId) ? (
          <div className="supporting-materials">
            <div className="supporting-material">
              <div className="field-wrapper">
                <p className="font-weight-bold">Timeline</p>
                <span className="d-block text-grayed font-weight-bold">
                  {formattedStartDate ? formattedStartDate : ""}
                  {formattedEndDate ? ` - ${formattedEndDate}` : ""}
                </span>
              </div>
              {setTimelineModal &&
                (isSuperAdmin(currentUser && currentUser.roleId) ||
                  isCompanyAdmin(currentUser && currentUser.roleId) ||
                  isCompanyManager(currentUser && currentUser.roleId)) && (
                  <div className="action">
                    <a
                      href="#"
                      onClick={e => {
                        e.preventDefault();
                        setTimelineModal();
                      }}
                    >
                      <EditIconAlt />
                    </a>
                  </div>
                )}
            </div>
            {setTimelineModal &&
              (isSuperAdmin(currentUser && currentUser.roleId) ||
                isCompanyAdmin(currentUser && currentUser.roleId) ||
                isCompanyManager(currentUser && currentUser.roleId)) && (
                <EditTimelineModal
                  modal={timelineModal}
                  setModal={setTimelineModal}
                  startDate={startDate}
                  endDate={endDate}
                  setDate={setDate}
                  updateProject={updateProject}
                />
              )}
          </div>
        ) : (
          <div className="field-wrapper">
            <p className="font-weight-bold">Timeline</p>
            <span className="d-block text-grayed font-weight-bold">
              {formattedStartDate ? formattedStartDate : ""}
              {formattedEndDate ? ` - ${formattedEndDate}` : ""}
            </span>
          </div>
        )}
      </SidebarItem>
    </>
  );
});

export default SidebarClientInfo;
