import React, { useEffect, useState } from "react";

import DatePicker from "react-datepicker";

// Configs
import { endpoints } from "../../../../configs";
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  getUrlParameter
} from "../../../../lib/helper";

// Action
import { fetchList } from "../../../../actions/table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import Form from "../../../../components/base/Form";
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";

import Partner from "../../../../components/Partner";
import PageSize from "../../../../components/PageSize";

const PartnerActivities = props => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState();
  const [partnerId, setPartnerId] = useState("");
  const [activityType, setActivityType] = useState("");
  const [typeId, setTypeId] = useState("");
  const [pageSize, setPageSize] = useState(10);

  useEffect(() => {
    if (!partnerId && !startDate && !endDate && !activityType) {
      getParams();
    }
  }, [props]);

  useEffect(() => {
    // Check is logged in user
    fetchData();
    updateUrl();
  }, [partnerId, startDate, endDate, activityType, typeId, pageSize]);

  const getParams = () => {
    try {
      let activityType = getUrlParameter("activityType");

      let partnerId = getUrlParameter("partnerId");
      let startDate = getUrlParameter("startDate");
      let endDate = getUrlParameter("endDate");
      let typeId = getUrlParameter("typeId");
      let pageSize = getUrlParameter("pageSize");

      if (startDate) {
        let startDates = new Date(startDate);

        setStartDate(startDates);
      }
      if (endDate) {
        let endDates = new Date(endDate);
        setEndDate(endDates);
      }
      if (partnerId) {
        setPartnerId(partnerId);
      }

      if (activityType) {
        setActivityType(activityType);
      }

      if (typeId) {
        setTypeId(typeId);
      }

      if (pageSize) {
        setPageSize(pageSize);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleStartDate = startDate => {
    setStartDate(startDate);
  };

  const handleEndDate = endDate => {
    setEndDate(endDate);
  };

  const handlePartnerChange = currentVal => {
    let partnerId = currentVal ? currentVal.value : "";
    setPartnerId(partnerId);
  };

  const fetchData = () => {
    let params = new Object();

    params.startDate = startDate ? startDate : "";

    params.endDate = endDate ? endDate : "";

    params.partnerId = partnerId ? partnerId : "";

    params.activityType = activityType ? activityType : "";

    params.typeId = typeId ? typeId : "";

    params.pagination = true;

    props.actions.fetchList(
      "activities",
      `${endpoints().activity}`,
      1,
      pageSize ? pageSize : 10,
      params
    );
  };

  const updateUrl = () => {
    const params = [];

    if (startDate) {
      params.push(`startDate=${startDate.toLocaleDateString("en-US")}`);
    }

    if (endDate) {
      params.push(`endDate=${endDate.toLocaleDateString("en-US")}`);
    }

    if (partnerId) {
      params.push(`partnerId=${partnerId}`);
    }

    if (activityType) {
      params.push(`activityType=${activityType}`);
    }

    if (typeId) {
      params.push(`typeId=${typeId}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }

    if (params.length > 0) {
      props.history.push(`/reports/partner-activities/?${params.join("&")}`);
    } else {
      props.history.push(window.location.pathname);
    }
  };

  const handlePageSizeChange = e => {
    setPageSize(e);
  };

  return (
    <div>
      <div className={`page-heading cover`}>
        <div className="d-flex justify-content-between">
          <div className="mr-1">
            <PageSize onChange={e => handlePageSizeChange(e)} />
          </div>
          <div className="w-50 mt-1 mr-2">
            <Form>
              <Partner
                name="partner"
                placeholder="Select Partner"
                defaultValue="partner"
                isSearchable={true}
                isClearable={true}
                partnerId={partnerId}
                handleChange={currentVal => {
                  handlePartnerChange(currentVal);
                }}
              />
            </Form>
          </div>

          <div className="datepicker-wrapper py-1 mr-3">
            <DatePicker
              name="startDate"
              className="datepicker"
              placeholderText="Start Date"
              onChange={handleStartDate}
              maxDate={new Date(endDate)}
              selected={startDate}
              clearIcon={null}
            />
          </div>
          <div className="datepicker-wrapper py-1 mr-3">
            <DatePicker
              name="endDate"
              className="datepicker"
              placeholderText="End Date"
              onChange={handleEndDate}
              minDate={new Date(startDate)}
              selected={endDate}
              clearAriaLabel
              clearIcon={null}
              width={"150px"}
            />
          </div>
        </div>
      </div>
      <ReduxTable
        startDate={startDate}
        endDate={endDate}
        id="activities"
        apiURL={`${endpoints().activity}`}
        newTableHeading
        params={{
          pagination: true,
          partnerId: partnerId ? partnerId : "",
          startDate: startDate ? startDate : "",
          endDate: endDate ? endDate : "",
          activityType: activityType ? activityType : "",
          typeId: typeId ? typeId : ""
        }}
      >
        <ReduxColumn width="150px" field="createdAt" sortBy="createdAt">
          Date
        </ReduxColumn>
        <ReduxColumn width="100px" field="userName" sortBy="userName">
          User
        </ReduxColumn>
        {(isSuperAdmin(props.roleId) ||
          isCompanyAdmin(props.roleId) ||
          isCompanyManager(props.roleId)) && (
          <ReduxColumn width="100px" field="companyName" sortBy="companyName">
            Partner
          </ReduxColumn>
        )}
        <ReduxColumn width="150px" field="activity_type" sortBy="activity_type">
          Activity
        </ReduxColumn>
        <ReduxColumn width="100px" field="notes" sortBy="notes">
          Notes
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

// Get ReportStatCard Counts
const mapStateToProps = state => {
  const { activities } = state.table;
  let user = state.user;
  return {
    activities: activities,
    roleId: user.roleId
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerActivities);
