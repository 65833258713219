import React, { Component } from "react";
import { Media } from "reactstrap";
import Editor from "draft-js-plugins-editor";
import createEmojiPlugin from "draft-js-emoji-plugin";
import createMentionPlugin from "draft-js-mention-plugin";
import { convertToRaw, EditorState, ContentState } from "draft-js";
import "draft-js/dist/Draft.css";
import "draft-js-emoji-plugin/lib/plugin.css";
import moment from "moment";

// Library

// Assets
import { GifIcon } from "../../../assets/img/icons";

// Components
import Avatar from "../../../components/base/Avatar";
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";
import FilePreview from "../../../components/base/FilePreview";
import { isBadRequest } from "../../../common/http";
import { getCookie } from "../../../lib/helper";
import { COOKIE_USER_ID } from "../../../lib/cookie";

// Toast
import toast from "../../../components/base/Toast";

import { getFullName } from "../../../lib/helper";

import Button from "../../../components/base/Button";

const emojiPlugin = createEmojiPlugin();
const { EmojiSuggestions, EmojiSelect } = emojiPlugin;

// Discussion person entry

const UserComment = props => {
  const { projectDiscussion, handleProjectDiscussionDelete } = props;
  const linkStyle = {
    color: "rgba(0, 0, 0, 0.25)"
  };

  let {
    id,
    from_user_id,
    message,
    subject,
    createdAt,
    readAt,
    userDetails,
    discussionMaterial,
    isEditable,
    fromUserAvatar,
    companyName
  } = projectDiscussion;

  // Get discussion date
  const getDiscussionDate = () => {
    const todayDate = moment(new Date()).format("DD MMM, YYYY");
    const discussionTime = moment(createdAt).format("h:mm A");
    const prevDate = moment(createdAt).format("DD MMM, YYYY");
    let discussionDate = moment(createdAt).format("DD MMM, YYYY");

    discussionDate =
      todayDate === discussionDate && todayDate === prevDate
        ? `Today at ${discussionTime}`
        : `${prevDate} ${discussionTime}`;

    return discussionDate;
  };
  //apply line break for message
  if (message) {
    message = message.replace(/\n/g, "<br />");
  }

  return (
    <Media className="user-rating mb-3">
      <div className="field-icon mr-3 mt-2">
        <Avatar
          firstName={userDetails && userDetails.first_name}
          lastName={userDetails && userDetails.last_name}
          size="xs"
          fontSize={12}
          url={fromUserAvatar}
          customSize={36}
        />
      </div>
      <Media body>
        <div className="rating-and-usage">
          <div className="rating-wrapper mb-0">
            <div>
              <div className="d-flex justify-content-start">
                <div>
                  <b className="h7">
                    {from_user_id ? (
                      getCookie(COOKIE_USER_ID) === from_user_id.toString() ? (
                        <span>You:</span>
                      ) : companyName ? (
                        companyName
                      ) : (
                        userDetails &&
                        getFullName(
                          userDetails.first_name,
                          userDetails.last_name
                        )
                      )
                    ) : (
                      ""
                    )}
                  </b>
                </div>
                {createdAt && (
                  <div>
                    <span
                      className={["text-inline-grayed pt-1 pl-2", "h8"].join(
                        " "
                      )}
                    >
                      {getDiscussionDate()}
                    </span>
                  </div>
                )}
              </div>
              {/* communication subject */}
              <span
                className={`${
                  getCookie(COOKIE_USER_ID) === from_user_id
                    ? ""
                    : readAt
                    ? "fs-5"
                    : "font-weight-bold fs-5"
                }`}
              >
                {subject}
              </span>

              {isEditable ? (
                <div className="discussion-actions d-inline-flex">
                  <a
                    className={[
                      "link-hover",
                      "text-inline-grayed",
                      "mr-2",
                      "cursor-pointer"
                    ].join(" ")}
                    onClick={() => handleProjectDiscussionDelete(id)}
                  >
                    Delete
                  </a>
                </div>
              ) : (
                ""
              )}
            </div>
          </div>
        </div>
        <div
          className={`${
            getCookie(COOKIE_USER_ID) === from_user_id
              ? ""
              : readAt
              ? "small"
              : "font-weight-bold small"
          }`}
        >
          <div dangerouslySetInnerHTML={{ __html: message }} />
        </div>
        {discussionMaterial && (
          <FilePreview
            key={discussionMaterial.id}
            img={""}
            preview={discussionMaterial.fileUrl}
            name={discussionMaterial.name}
            file={discussionMaterial}
            isPreview={false}
            linkStyle={linkStyle}
          />
        )}
      </Media>
    </Media>
  );
};

class Discussion extends Component {
  constructor(props) {
    super(props);

    this.state = {
      editorState: EditorState.createEmpty(),
      projectDiscussions: [],
      material: "",
      partnerId: "",
      suggestions: [],
      error: false
    };

    this.handleProjectDiscussionDelete = this.handleProjectDiscussionDelete.bind(
      this
    );

    this.discussionMentionPlugin = createMentionPlugin({
      entityMutability: "IMMUTABLE",
      mentionPrefix: "@",
      supportWhitespace: true
    });
  }

  onChange = editorState => {
    this.setState({
      editorState: editorState,
      error: false
    });
  };

  focus = () => {
    this.editor.focus();
  };

  handleReturn(evt, editorState) {
    if (
      !this.state.editorState
        .getCurrentContent()
        .getPlainText()
        .trim()
    ) {
      this.setState({ error: true });
      return "handled";
    }
    const rawComment = convertToRaw(this.state.editorState.getCurrentContent());
    // getting message data from the object of rawcomment
    let message = rawComment && rawComment.blocks[0].text;

    if (!message) {
      this.setState({ error: true });
    }

    const emptyEditorState = EditorState.moveFocusToEnd(
      EditorState.push(this.state.editorState, ContentState.createFromText(""))
    );

    this.setState({ editorState: emptyEditorState });

    const data = new FormData();

    data.append("message", message);
    data.append("partnerId", this.props.partnerId);
    data.append("toUserId", this.props.messageDetails.userDetails.id);
    data.append("sharingPermission", "Individual Partner");
    data.append("messageId", this.props.id);

    apiClient
      .post(endpoints().messageAPI, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }

        this.setState({
          editorState: EditorState.createEmpty()
        });

        this.props.getMessageLists();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });

    return "handled";
  }

  // Project discussion delete api call
  handleProjectDiscussionDelete = () => {
    return apiClient
      .delete(`${endpoints().messageAPI}/`)
      .then(() => {
        this.props.getMessageLists();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  render() {
    const { editorState, materialPreview } = this.state;
    const { projectDiscussions } = this.props;

    const plugins = [this.discussionMentionPlugin, emojiPlugin];
    const rawComment = convertToRaw(this.state.editorState.getCurrentContent());
    // getting message data from the object of rawcomment
    let message = rawComment && rawComment.blocks[0].text;

    return (
      <div className="discussions">
        <div className="discussions-body">
          <div className="form-wrapper discussion-input-wrapper mb-n3">
            <div className="field-wrapper position-relative">
              <div className="discussion-editor-edit">
                <div
                  className={`editor message-card ${materialPreview && "mb-0"}`}
                  onClick={this.focus}
                >
                  <Editor
                    editorState={editorState}
                    onChange={this.onChange}
                    plugins={plugins}
                    placeholder="Your message.."
                    ref={element => {
                      this.editor = element;
                    }}
                    handleReturn={this.handleReturn.bind(this)}
                  />

                  <EmojiSuggestions />
                </div>

                {this.state.error === true && !message.trim() ? (
                  <span
                    className="small text-danger"
                    style={{ position: "absolute", bottom: 10 }}
                  >
                    Message Is Required
                  </span>
                ) : (
                  ""
                )}
              </div>
              <div className="discussion-actions">
                <div className={["discussion-action"].join(" ")}>
                  <EmojiSelect />
                </div>
                <div className={["discussion-action"].join(" ")}>
                  <GifIcon />
                </div>
              </div>
            </div>
          </div>
          <div>
            <Button
              id="submitButton"
              label="Send"
              className="btn btn-primary"
              onClick={() => {
                this.handleReturn();
              }}
              align={"right"}
            />
          </div>
          <br />
          <br />
          <div className="discussion-list">
            {projectDiscussions &&
              projectDiscussions.map((projectDiscussion, index) => (
                <UserComment
                  projectDiscussion={projectDiscussion}
                  handleProjectDiscussionDelete={
                    this.handleProjectDiscussionDelete
                  }
                  key={index}
                />
              ))}
          </div>
        </div>
      </div>
    );
  }
}

export default Discussion;
