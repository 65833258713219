import React from "react";
import { endpoints } from "../../../../../configs";
import toast from "../../../../../components/base/Toast";
import { apiClient } from "../../../../../apiClient";

import { TabPane } from "reactstrap";

// Components
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import DeleteModal from "../../../../../components/base/DeleteModal";
import Spinner from "../../../../../components/base/Spinner";
import UserDropdown from "../../../../projects/inner-components/UserDropdown";

// Components
import Text from "../../../../../components/base/Text";
import Form from "../../../../../components/base/Form";

// Assets
import createLandingIcon from "../../../../../assets/pages/landing-page/create-landing-page.png";
import Multiselect from "../../../../../components/base/Multiselect";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  getUserId,
  getKeyValueByObject,
  isLoggedIn
} from "../../../../../lib/helper";
import Users from "../../../../../helpers/User";

import PartnerType from "./partnerTypes";

import {
  PARTNERS_SUB_TAB,
  SETTINGS_ENABLE_CLUTCH_REVIEW
} from "../../../../../setting/Constants";
import { isBadRequest } from "../../../../../common/http";
import SingleCheckbox from "../../../../../components/base/SingleCheckbox";
import Tags from "./inner-component/Tags";
import { concatName, isNotEmpty, isTrue } from "../../../../../common/utils";
import NoRecordsFound from "../../../../../components/base/NoRecordsFound";
import {
  ACCOUNT_MAPPING,
  COMARKETING_CAMPAIGNS,
  LEAD_DISTRIBUTION,
  MDF,
  PARTNERSHIP_WITH_INDIVIDUAL_PERSON,
  PARTNER_RESOURCES,
  REFERRALS_DEAL_REGISTRATION,
  TEAM_MANAGEMENT,
  ALLOW_REFERRAL_SUBMISSION,
  PARTNER_AUTO_APPROVE,
  REFERRAL_AUTO_APPROVE
} from "./Constants";
import {
  COMPANY_MANAGER_ROLE,
  COMPANY_ADMIN_ROLE
} from "../../../../../roles/Constants";
// Components
import Select from "../../../../../components/base/Select";
import TextArea from "../../../../../components/base/TextArea";
import Hint from "../../../../../components/base/Hint";
import partner_type from "../../../../../Constants/partnerType";
import Label from "../../../../../components/base/Label";
import Terminology from "../../../../../lib/Terminology";
import SystemName from "../../../../../Constants/SystemName";
import { connect } from "react-redux";

class PartnerTypeTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      searchTerm: "",
      name: "",
      id: "",
      isOpen: false,
      partnerTypeList: [],
      partnerTypeSettings: [],
      partnerTypeId: "",
      sourceTaskOrder: "",
      openEditModal: false,
      openDeleteModal: false,
      currentTask: "",
      taskItem: "",
      isLoading: false,
      isDefault: true,
      deletePartnerTypeModal: false,
      deletePartnerType: false,
      editPartnerTypeModal: false,
      editPartnerType: false,
      sortByOptions: [
        {
          value: "name:ASC",
          label: "name"
        }
      ],
      partnerTypeData: "",
      updateFlag: 0,
      EmailTemplateOptions: [],
      inviteEmailTemplateId: "",
      userList: [],
      currentManagerUserId: "",
      inboundLinkCopySuccess: "Copy",
      signLink: "",
      partnerType: "",
      fileTypeError: false,
      partnerLoginImageUrl: false,
      partnerSignupImageUrl: false,
      partner_signup_page_image: false,
      partner_login_page_image: false,
      partnerManagerList: [],
      partnerManagerValue: []
    };
  }

  componentDidMount() {
    this._getPartnerTypeDetails();
    // Check is logged in user
    isLoggedIn();
    this.getEmailTemplate();
    if (isSuperAdmin(this.props.roleId) || isCompanyAdmin(this.props.roleId)) {
      this.getUsersList();
      this.updateUrl();
    }
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }
  saveSearchTerm = value => {
    this.setState({ searchTerm: value });
  };

  handleEdit = row => {
    this.setState({
      name: row.name,
      id: row.id,
      isDefault: row.isDefault === 1 ? true : false
    });
    return this._toggle(row.id);
  };
  getPartnerTypeList = partnerType => {
    apiClient
      .get(
        `${endpoints().partnerTypeAPI}?sort=sort&sortDir=ASC${
          partnerType ? "&partnerType=" + partnerType : ""
        }`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        console.error(successMessage);
        this.getPartnerTypeData();

        const partnerTypeList = response.data.data || [];

        this.setState({ partnerTypeList: partnerTypeList, isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };
  getPartnerTypeData = () => {
    this.setState({ isLoading: true }, () => {
      return apiClient
        .get(`${endpoints().partnerTypeAPI}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);

          const partnerTypeDetails = response.data.data || [];

          const partnerTypeFilterId = partnerTypeDetails.find(
            type => type.name === this.state.partnerTypeFilter
          );

          this.setState({
            partnerTypeDetails,
            partnerTypeFilterId: partnerTypeFilterId
              ? partnerTypeFilterId.id
              : null,
            partnerTypePermission: partnerTypeFilterId
              ? partnerTypeFilterId.permission
              : null,
            isLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  };
  createPartnerType = data => {
    apiClient
      .post(`${endpoints().partnerTypeAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._getPartnerTypeDetails();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
  updatePartnerTypeOrder = updatedPartnerTypeList => {
    apiClient
      .put(
        `${endpoints().partnerTypeAPI}/update/sortOrder`,
        updatedPartnerTypeList
      )
      .then(response => {
        if (response.data) {
          this.setState({
            isLoading: true
          });
          this._getPartnerTypeDetails();
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          this.setState({
            isLoading: false
          });
        }
      });
  };
  // partner type page api call
  async _getPartnerTypeSettings(taskItem, taskId) {
    this.setState({ isLoading: true });
    await apiClient
      .get(
        `${endpoints().partnerTypeSettingAPI}?id=${
          taskItem.id ? taskItem.id : taskId
        }`
      )
      .then(response => {
        const partnerTypeSettings =
          response && response.data && response.data.settings;
        this.setState({
          partnerTypeSettings: partnerTypeSettings,
          isLoading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({
            isLoading: false
          });
        }
      });
  }

  // partner type page api call
  async _getPartnerTypeDetails() {
    this.setState({ isLoading: true });
    await apiClient
      .get(`${endpoints().partnerTypeAPI}/search`)
      .then(response => {
        const partnerTypeList = response.data.data;

        this.setState({
          partnerTypeList: partnerTypeList
            ? partnerTypeList
            : this.state.partnerTypeList,
          isLoading: false
        });
        this.getPartnerTypeDataFromId();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({
            isLoading: false
          });
        }
      });
  }

  // delete partner type page api call by id
  _deletePartnerTypeDetails(id) {
    apiClient
      .delete(`${endpoints().partnerTypeAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this._getPartnerTypeDetails();
        }
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          toast.error(errorMessage);
        }
      });
  }
  setSourceTaskOrder = PartnerType => {
    if (this.state.sourceTaskOrder !== PartnerType.sort) {
      this.setState({ sourceTaskOrder: PartnerType.sort });
    }
  };
  setTargetTaskOrder = PartnerType => {
    if (this.state.targetTaskOrder !== PartnerType.sort) {
      this.setState({ targetTaskOrder: PartnerType.sort });
    }
  };
  deleteTask = taskItem => {
    this.setState({
      id: taskItem.id,
      name: taskItem.name,
      isDefault: taskItem.isDefault === "Yes" ? true : false,
      type: taskItem.type,
      typeId: taskItem.typeId,
      openDeleteModal: true
    });
  };
  changeTaskOrder = () => {
    const { partnerTypeList, sourceTaskOrder, targetTaskOrder } = this.state;
    if (partnerTypeList && partnerTypeList.length > 0) {
      partnerTypeList.splice(
        targetTaskOrder,
        0,
        partnerTypeList.splice(sourceTaskOrder, 1)[0]
      );

      // Updated Selected Partner Type List
      const updatedPartnerTypeList = [];

      partnerTypeList.forEach(PartnerType => {
        updatedPartnerTypeList.push({
          ...PartnerType,
          // Update Selected Partner Type Order
          sort: updatedPartnerTypeList.length
        });
      });
      this.updatePartnerTypeOrder(updatedPartnerTypeList);
      this.setState({
        partnerTypeList: updatedPartnerTypeList,
        sourceTaskOrder: "",
        targetTaskOrder: ""
      });
    } else {
      // Reset Order Positions of Selected Partner Type
      this.setState({
        sourceTaskOrder: "",
        targetTaskOrder: ""
      });
    }
  };

  updateTask = taskItem => {
    this.setState({
      id: taskItem.id,
      name: taskItem.name,
      partnerManagerValue: taskItem.partnerManager,
      isDefault: taskItem.isDefault === 1 ? true : false,
      type: taskItem.type,
      typeId: taskItem.typeId,
      isOpen: true,
      inviteEmailTemplateId: taskItem.welcome_email_template_id,
      currentManagerUserId: taskItem.default_partner_manager,
      partnerLoginImageUrl: taskItem.partner_login_page_image,
      partnerSignupImageUrl: taskItem.partner_signup_page_image
    });
  };
  handleTaskDelete = id => {
    this.props.actions.deletePartnerType(id, {}, () => {
      const partnerTypeFilter = this.props.selectedPartnerType;
      this.getPartnerTypeList(partnerTypeFilter);
    });
  };

  // update partner type page api call by id
  _updatePartnerTypeDetails(data) {
    apiClient
      .put(`${endpoints().partnerTypeAPI}/update`, data)
      .then(response => {
        let successMessage;
        this._getPartnerTypeDetails();
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._toggle();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  }

  // update partner type page api call by id
  _updatePartnerTypeSetting(data) {
    apiClient
      .post(
        `${endpoints().partnerTypeSettingAPI}?id=${this.state.id ||
          this.state.partnerTypeData.id}`,
        data
      )
      .then(response => {
        let successMessage;
        this._getPartnerTypeDetails();
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this._toggle();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  }

  _toggle = partnerTypeId => {
    this.setState({ isOpen: !this.state.isOpen, id: partnerTypeId || "" });

    this.props.history.push("/admin-settings/Partner/Types");
  };
  headerButton = () => (
    <div className="d-flex align-items-center mb-n3 pull-right">
      <PartnerType createPartnerType={this.createPartnerType} />
    </div>
  );

  handleUpdate = values => {
    const data = new FormData();
    const data1 = new FormData();
    let partnerManager = [];
    if (this.state.id || this.state.partnerTypeData.id) {
      data.append("id", this.state.id || this.state.partnerTypeData.id);
    }
    if (values && values.name !== undefined) {
      data.append("name", values.name);
    }

    values &&
      values.partner_manager &&
      values.partner_manager.forEach(data => {
        if (data) {
          partnerManager.push({
            partner_manager: data.value
          });
        }
      });
    data.append("partner_manager", JSON.stringify(partnerManager));
    data.append("isDefault", values && values.isDefault ? 1 : 0);
    if (values && values.sort !== undefined) {
      data.append("sort", values.sort);
    }
    // default partner manager
    if (this.state.currentManagerUserId) {
      data.append("default_partner_manager", this.state.currentManagerUserId);
    }

    if (values && values.EmailTemplate !== undefined) {
      let selectedEmailTemplateId = values.EmailTemplate
        ? values.EmailTemplate.value
        : "";
      data.append("welcome_email_template_id", selectedEmailTemplateId);
    }

    if (values && values.accountMapping !== undefined) {
      data1.append("accountMapping", values.accountMapping);
    }
    if (values && values.coMarketingCampaigns !== undefined) {
      data1.append("coMarketingCampaigns", values.coMarketingCampaigns);
    }
    if (values && values.leadDistribution !== undefined) {
      data1.append("leadDistribution", values.leadDistribution);
    }
    if (values && values.mdf !== undefined) {
      data1.append("mdf", values.mdf);
    }

    if (values && values.partnerShipWithIndividualPerson !== undefined) {
      data1.append(
        "partnerShipWithIndividualPerson",
        values.partnerShipWithIndividualPerson
      );
    }
    if (values && values.referralsDealRegistration !== undefined) {
      data1.append(
        "referralsDealRegistration",
        values.referralsDealRegistration
      );
    }
    if (values && values.teamManagement !== undefined) {
      data1.append("teamManagement", values.teamManagement);
    }
    if (values && values.enable_clutch_review !== undefined) {
      data1.append("enable_clutch_review", values.enable_clutch_review);
    }

    if (values && values.allow_referral_submission !== undefined) {
      data1.append(
        "allow_referral_submission",
        values.allow_referral_submission
      );
    }

    if (values && values.partner_auto_approve !== undefined) {
      data1.append("partner_auto_approve", values.partner_auto_approve);
    }

    if (values && values.referral_auto_approve !== undefined) {
      data1.append("referral_auto_approve", values.referral_auto_approve);
    }

    if (this.state.partner_login_page_image !== undefined) {
      data.append(
        "partner_login_page_image",
        this.state.partner_login_page_image
          ? this.state.partner_login_page_image
          : ""
      );
    }

    if (this.state.partner_signup_page_image !== undefined) {
      data.append(
        "partner_signup_page_image",
        this.state.partner_signup_page_image
          ? this.state.partner_signup_page_image
          : ""
      );
    }

    if (values && values.signup_page_welcome_message !== undefined) {
      data1.append(
        "signup_page_welcome_message",
        values.signup_page_welcome_message
          ? values.signup_page_welcome_message
          : ""
      );
    }
    this._updatePartnerTypeDetails(data);
    this._updatePartnerTypeSetting(data1);
  };

  async getPartnerTypeDataFromId() {
    if (
      this.props.activeSection &&
      this.state.updateFlag < 1 &&
      this.props.activeSubTab == PARTNERS_SUB_TAB
    ) {
      if (this.state.partnerTypeList.length) {
        this.setState({ isLoading: true });
        this.state.partnerTypeList.filter(data => {
          if (data.id == this.props.activeSection) {
            this.setState({
              partnerTypeData: data
            });
          }
        });
        this.setState({ isLoading: false });
      }
      //get the type setting for the type id
      this.setState({ isOpen: true, isLoading: true });
      //update flag - to avoid the reopening of model after update
      this.setState({ updateFlag: 1 });

      this.setState({ isDefault: this.state.partnerTypeData.isDefault });
      await this._getPartnerTypeSettings(
        "",
        parseInt(this.props.activeSection)
      );
      this.setState({ isLoading: false });
    } else {
      this.setState({ isOpen: false });
    }
  }

  getEmailTemplate = async () => {
    try {
      await apiClient
        .get(`${endpoints().emailTemplateAPI}/search`)
        .then(response => {
          if (
            response &&
            response.data &&
            response.data.data &&
            response.data.data.length > 0
          ) {
            let EmailTemplate = response.data.data;
            let EmailTemplateOptions = new Array();
            for (let i = 0; i < EmailTemplate.length; i++) {
              EmailTemplateOptions.push({
                label: EmailTemplate[i].name,
                value: EmailTemplate[i].id
              });
            }
            this.setState({
              EmailTemplateOptions: EmailTemplateOptions,
              EmailTemplates: EmailTemplate
            });
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    } catch (err) {}
  };

  // Get Users
  getUsersList = async () => {
    let usersList = [];
    let roleId = `${COMPANY_MANAGER_ROLE},${COMPANY_ADMIN_ROLE}`;
    await Users.search(roleId, list => {
      this.setState({ userList: list });

      for (let i = 0; i < list.length; i++) {
        usersList[i] = {
          value: list[i].id,
          label: list[i].name
        };
      }
      this.setState({ partnerManagerList: usersList });
    });
  };

  // handele selected manager
  handleUpdateManager = userId => {
    this.setState({ currentManagerUserId: userId });
  };

  updateUrl = () => {
    const params = [];
    const base_url = window.location.origin;
    const signupLink = `${base_url}/partner-signup`;
    this.setState({
      params: params,
      signLink: signupLink
    });
    let partnertype = this.state.name;
    let urlPartnerType = partnertype.replace(" ", "-");
    const value = urlPartnerType.toLowerCase();
    if (signupLink) {
      params.push(`${signupLink}`);
    }
    if (value) {
      params.push(`partnertype=${value}`);
    }
    if (params && params.length > 0) {
      return params.join("&");
    }
  };

  // Set image preview in state
  setBase64Image = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [state]: reader.result });
    };
  };

  //partner Image Remove
  handleSignupImageRemove = () => {
    toast.success("Image Removed Successfully");
    this.setState({
      partnerSignupImageUrl: "",
      partner_signup_page_image: ""
    });
  };

  //partner Image Remove
  handleLoginImageRemove = () => {
    toast.success("Image Removed Successfully");
    this.setState({
      partnerLoginImageUrl: "",
      partner_login_page_image: ""
    });
  };
  //partner Image Upload
  signupImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";

    files &&
      this.setState(
        {
          partnerSignupImageUrl: URL.createObjectURL(files),
          partner_signup_page_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, partner_type.PARTNER_SIGNUP_PAGE_IMAGE)
      );
  };

  //partner Image Upload
  loginImageUpload = e => {
    const files = e.target.files ? e.target.files[0] : "";

    files &&
      this.setState(
        {
          partnerLoginImageUrl: URL.createObjectURL(files),
          partner_login_page_image: URL.createObjectURL(files)
        },
        () => this.setBase64Image(files, partner_type.PARTNER_LOGIN_PAGE_IMAGE)
      );
  };

  // Select thepartner Image
  onSelectSignupImage = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          this.setState({
            fileTypeError: false
          });
          this.signupImageUpload(e);
          break;

        default:
          this.setState({
            fileTypeError: true
          });
          break;
      }
    }
  };

  // Select thepartner Image
  onSelectLoginImage = e => {
    const targetFile = e.target;

    if (targetFile.files && targetFile.files.length > 0) {
      const fileType = targetFile.files.item(0).type;
      switch (fileType) {
        case "image/jpeg":
        case "image/png":
        case "image/gif":
          this.setState({
            fileTypeError: false
          });
          this.loginImageUpload(e);
          break;

        default:
          this.setState({
            fileTypeError: true
          });
          break;
      }
    }
  };
  render() {
    const settings = this.props && this.props.adminSettings;

    const {
      partnerTypeList,
      isOpen,
      openDeleteModal,
      currentTask,
      isLoading,
      partnerTypeData,
      EmailTemplateOptions,
      inviteEmailTemplateId,
      userList,
      currentManagerUserId,
      signLink,
      partnerManagerList,
      partnerManagerValue
    } = this.state;

    let selectedPartnerManager;
    // Get Selected Partner Manager
    selectedPartnerManager = userList.find(x => x.id === currentManagerUserId);

    if (!selectedPartnerManager) {
      selectedPartnerManager = {};
    }

    const {
      enable_referrals,
      enable_resources,
      enable_leads,
      enable_partners,
      enable_campaigns,
      enable_account_mapping,
      enable_mdf_request,
      enable_clutch_review
    } = settings;

    if (isLoading) {
      return <Spinner />;
    }

    const copyPartnerLinkRef = (url, name) => {
      let link = concatName(url, name);
      navigator.clipboard.writeText(link);
      this.setState({
        [name]: "Copied!"
      });
    };

    let managerValue = [];

    if (partnerManagerValue) {
      partnerManagerValue.forEach(result => {
        managerValue.push({
          value: result.value,
          label: result.label
        });
      });
    }

    return (
      <>
        <div className="d-flex justify-content-between flex-wrap relative">
          <Modal
            isOpen={isOpen}
            toggle={this._toggle}
            className="custom-modal-popup w-100"
            backdrop="static"
          >
            <Form
              initialValues={{
                partner_manager: managerValue,
                name: this.state.name || partnerTypeData.name || "",
                id: this.state.id || partnerTypeData.id || "",
                isDefault: this.state.isDefault,
                EmailTemplate:
                  EmailTemplateOptions &&
                  EmailTemplateOptions.find(
                    template =>
                      template.value === parseInt(inviteEmailTemplateId, 10)
                  ),
                accountMapping:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(
                    this.state.partnerTypeSettings,
                    ACCOUNT_MAPPING
                  ) == "true",
                coMarketingCampaigns:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(
                    this.state.partnerTypeSettings,
                    COMARKETING_CAMPAIGNS
                  ) == "true",
                leadDistribution:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(
                    this.state.partnerTypeSettings,
                    LEAD_DISTRIBUTION
                  ) == "true",
                mdf:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(this.state.partnerTypeSettings, MDF) ==
                    "true",

                partnerShipWithIndividualPerson:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(
                    this.state.partnerTypeSettings,
                    PARTNERSHIP_WITH_INDIVIDUAL_PERSON
                  ) == "true",
                referralsDealRegistration:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(
                    this.state.partnerTypeSettings,
                    REFERRALS_DEAL_REGISTRATION
                  ) == "true",
                teamManagement:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(
                    this.state.partnerTypeSettings,
                    TEAM_MANAGEMENT
                  ) == "true",
                enable_clutch_review:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(
                    this.state.partnerTypeSettings,
                    SETTINGS_ENABLE_CLUTCH_REVIEW
                  ) == "true",
                allow_referral_submission:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(
                    this.state.partnerTypeSettings,
                    ALLOW_REFERRAL_SUBMISSION
                  ) == "true",
                partner_auto_approve:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(
                    this.state.partnerTypeSettings,
                    PARTNER_AUTO_APPROVE
                  ) == "true",
                referral_auto_approve:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(
                    this.state.partnerTypeSettings,
                    REFERRAL_AUTO_APPROVE
                  ) == "true",
                partner_login_page_image: this.state.partner_login_page_image,
                partner_signup_page_image: this.state.partner_signup_page_image,
                signup_page_welcome_message:
                  this.state.partnerTypeSettings &&
                  getKeyValueByObject(
                    this.state.partnerTypeSettings,
                    "signup_page_welcome_message"
                  )
              }}
              onSubmit={values => {
                this.handleUpdate(values);
              }}
            >
              <ModalHeader toggle={this._toggle}>
                <div className="content-wrapper">
                  <div className="icon-wrapper">
                    <div className="row justify-content-center mb-2 mt-3">
                      <img src={createLandingIcon} size="30" alt="" />
                    </div>
                    <p className="text-center font-weight-bold mb-3">
                      Edit a Partner Type
                    </p>
                    <p
                      className="text-center"
                      style={{ fontSize: "14px", lineHeight: "20px" }}
                    ></p>
                  </div>
                  {/* /.icon-wrapper */}
                </div>
                {/* /.content-wrapper */}
              </ModalHeader>
              <ModalBody>
                <div className="mt-2 mb-2">
                  <div className="form-wrapper">
                    <div className="mb-2">
                      <Text
                        name="name"
                        label="Partner Type Name"
                        placeholder="Enter Partner Types Name..."
                        error=""
                        required
                      />
                    </div>
                    <div className="mb-3">
                      <label>
                        Default {Terminology.get(SystemName.PARTNER_MANAGER)}
                      </label>
                      <UserDropdown
                        assignee={selectedPartnerManager}
                        placeholderText={`Select ${Terminology.get(
                          SystemName.PARTNER_MANAGER
                        )}`}
                        userlist={userList}
                        updateDefaultManager={this.handleUpdateManager}
                        currentUserId={getUserId()}
                        partnerManagerId={"partner-manager"}
                      />
                    </div>
                    <label>{Terminology.get(SystemName.PARTNER_MANAGER)}</label>
                    <div className="field-wrapper">
                      <Multiselect
                        name="partner_manager"
                        placeholder="Select Partner Manager..."
                        options={partnerManagerList}
                        error=""
                      />
                    </div>
                    <div className="mb-2">
                      <Select
                        name="EmailTemplate"
                        label="Welcome Email Template"
                        options={this.state.EmailTemplateOptions}
                        placeholder="Select Welcome Email Template"
                        isClearable={true}
                      />
                    </div>
                    <div className="field-wrapper">
                      <SingleCheckbox
                        name="isDefault"
                        label="Default Partner Type for new partner applications"
                        className="accepted-terms mb-0 pb-2 mr-3"
                      />
                    </div>
                    <div className="field-wrapper">
                      <SingleCheckbox
                        name={PARTNERSHIP_WITH_INDIVIDUAL_PERSON}
                        label="Partnership with individual person"
                        className="accepted-terms mb-0 pb-2 mr-3"
                      />
                    </div>

                    {/* Auto Approve */}
                    <div className="field-wrapper">
                      <SingleCheckbox
                        name={PARTNER_AUTO_APPROVE}
                        label="Partner Auto Approve"
                        className="accepted-terms mb-0 pb-2 mr-3"
                      />
                    </div>

                    {/* Auto Approve */}
                    <div className="field-wrapper">
                      <SingleCheckbox
                        name={REFERRAL_AUTO_APPROVE}
                        label={`${Terminology.get(
                          SystemName.REFERRAL
                        )} Auto Approve`}
                        className="accepted-terms mb-0 pb-2 mr-3"
                      />
                    </div>

                    <div>
                      {(enable_referrals == "true" ||
                        enable_resources == "true" ||
                        enable_leads == "true" ||
                        enable_partners == "true" ||
                        enable_campaigns == "true" ||
                        enable_account_mapping == "true" ||
                        enable_mdf_request == "true" ||
                        enable_clutch_review == "true") && (
                        <h5 className="font-weight-bold my-4">
                          Partner Permissions
                        </h5>
                      )}
                      {isTrue(enable_referrals) && (
                        <div className="field-wrapper">
                          <SingleCheckbox
                            name={REFERRALS_DEAL_REGISTRATION}
                            label={Terminology.get(SystemName.REFERRAL)}
                            className="accepted-terms mb-0 pb-2 mr-3"
                          />
                        </div>
                      )}

                      {isTrue(enable_leads) && (
                        <div className="field-wrapper">
                          <SingleCheckbox
                            name={LEAD_DISTRIBUTION}
                            label="Lead Distribution"
                            className="accepted-terms mb-0 pb-2 mr-3"
                          />
                        </div>
                      )}
                      {isTrue(enable_partners) && (
                        <div className="field-wrapper">
                          <SingleCheckbox
                            name={TEAM_MANAGEMENT}
                            label="Team Management"
                            className="accepted-terms mb-0 pb-2 mr-3"
                          />
                        </div>
                      )}
                      {isTrue(enable_campaigns) && (
                        <div className="field-wrapper">
                          <SingleCheckbox
                            name={COMARKETING_CAMPAIGNS}
                            label={Terminology.get(
                              SystemName.COMARKETING_CAMPAIGNS
                            )}
                            className="accepted-terms mb-0 pb-2 mr-3"
                          />
                        </div>
                      )}
                      {isTrue(enable_account_mapping) && (
                        <div className="field-wrapper">
                          <SingleCheckbox
                            name={ACCOUNT_MAPPING}
                            label="Account Mapping"
                            className="accepted-terms mb-0 pb-2 mr-3"
                          />
                        </div>
                      )}
                      {isTrue(enable_mdf_request) && (
                        <div className="field-wrapper">
                          <SingleCheckbox
                            name={MDF}
                            label="MDF"
                            className="accepted-terms mb-0 pb-2 mr-3"
                          />
                        </div>
                      )}
                      {/* Clutch Review */}
                      {isTrue(enable_clutch_review) && (
                        <div className="field-wrapper">
                          <SingleCheckbox
                            name={SETTINGS_ENABLE_CLUTCH_REVIEW}
                            label="Clutch Review"
                            className="accepted-terms mb-0 pb-2 mr-3"
                          />
                        </div>
                      )}

                      {/* Allow Referral Submission */}
                      <div className="field-wrapper">
                        <SingleCheckbox
                          name={ALLOW_REFERRAL_SUBMISSION}
                          label={`Allow ${Terminology.get(
                            SystemName.REFERRAL
                          )} Submission`}
                          className="accepted-terms mb-0 pb-2 mr-3"
                        />
                      </div>
                      <div className="row">
                        <div className="col-9">
                          <TextArea
                            name="defaultPartnerSignupLink"
                            defaultValue={concatName(signLink, this.state.name)}
                            className="w-100 mb-2"
                            disabled
                          />
                        </div>
                        <div className="col-3 p-0">
                          <button
                            name={this.state.name}
                            className={[
                              "btn",
                              `${
                                this.state[this.state.name] !== "Copied!"
                                  ? "btn-primary"
                                  : "btn-success"
                              }`,
                              "ml-2 mt-4"
                            ].join(" ")}
                            type="button"
                            onClick={() => {
                              copyPartnerLinkRef(signLink, this.state.name);
                            }}
                          >
                            <span>
                              {this.state[this.state.name] ? "Copied" : "Copy"}
                            </span>
                          </button>
                        </div>
                      </div>
                    </div>
                    <label className="font-weight-bold">
                      Upload partner Signup Image
                    </label>
                    <div className="row">
                      <div className="flex-column w-50 col-7">
                        <div className="upload-field d-inline-block">
                          <input
                            name="partner_signup_page_image"
                            className="form-control d-none"
                            type="file"
                            id="partner_signup_page_image"
                            onChange={e => {
                              this.onSelectSignupImage(e);
                              e.target.value = null;
                            }}
                            accept="image/png,image/gif,image/jpeg"
                          />
                          <span className="profilePicOverlay d-block ">
                            <label
                              htmlFor="partner_signup_page_image"
                              className="profile-img-sm mb-0"
                            >
                              <span className="banner-image-upload-link">
                                Upload partner Signup Image
                              </span>
                            </label>
                          </span>
                        </div>
                        {/* Remove image */}
                        {this.state.partnerSignupImageUrl && (
                          <span
                            className="banner-image-upload-link text-danger ml-md-1"
                            onClick={this.handleSignupImageRemove}
                          >
                            (Remove)
                          </span>
                        )}
                        <div className="">
                          <Hint
                            id="partner_signup_page_image"
                            hintText="We recommend using 400 X 300 image"
                          />
                        </div>
                      </div>
                      <div className="ml-auto pull-right col">
                        {this.state.partnerSignupImageUrl ? (
                          <img
                            src={this.state.partnerSignupImageUrl}
                            style={{ width: "100px" }}
                            className="ml-auto pull-right col ml-auto"
                            alt="Avatar"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <label className="font-weight-bold">
                      Upload partner Login Image
                    </label>
                    <div className="row">
                      <div className="flex-column w-50 col-7">
                        <div className="upload-field d-inline-block">
                          <input
                            name="partner_login_page_image"
                            className="form-control d-none"
                            type="file"
                            id="partner_login_page_image"
                            onChange={e => {
                              this.onSelectLoginImage(e);
                              e.target.value = null;
                            }}
                            accept="image/png,image/gif,image/jpeg"
                          />
                          <span className="profilePicOverlay d-block ">
                            <label
                              htmlFor="partner_login_page_image"
                              className="profile-img-sm mb-0"
                            >
                              <span className="banner-image-upload-link">
                                Upload partner Login Image
                              </span>
                            </label>
                          </span>
                        </div>
                        {/* Remove image */}
                        {this.state.partnerLoginImageUrl && (
                          <span
                            className="banner-image-upload-link text-danger ml-md-1"
                            onClick={this.handleLoginImageRemove}
                          >
                            (Remove)
                          </span>
                        )}
                        <div className="">
                          <Hint hintText="We recommend using 400 X 300 image" />
                        </div>
                      </div>
                      <div className="ml-auto pull-right col">
                        {this.state.partnerLoginImageUrl ? (
                          <img
                            src={this.state.partnerLoginImageUrl}
                            style={{ width: "100px" }}
                            className="ml-auto pull-right col ml-auto"
                            alt="Avatar"
                          />
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                    <div className="row mt-2">
                      <div className="col-lg-12 col-sm-12">
                        <Label>SignUp Page Welcome Message</Label>
                        <TextArea
                          name="signup_page_welcome_message"
                          placeholder="Your Welcome message..."
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </ModalBody>
              <ModalFooter>
                <div className="container-fluid">
                  <div className="col-sm-12 text-center">
                    <Button type="submit" label="">
                      Save
                    </Button>
                  </div>
                </div>
              </ModalFooter>
            </Form>
          </Modal>
        </div>

        <TabPane tabId={PARTNERS_SUB_TAB}>
          <>
            <DeleteModal
              isOpen={openDeleteModal}
              toggle={() => {
                this.setState({ openDeleteModal: false });
              }}
              title="Delete PartnerType"
              label={this.state.name}
              task={currentTask}
              deleteFunction={() =>
                this._deletePartnerTypeDetails(this.state.id)
              }
            />
            <div className="pb-4 pt-3 mb-4">{this.headerButton()}</div>
            <div className="card">
              <h6 className="font-weight-bold pt-4 px-4 pb-3">Partner Types</h6>

              <div className="task-list">
                {isLoading && <Spinner />}

                {isNotEmpty(partnerTypeList) ? (
                  partnerTypeList
                    .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
                    .map(taskItem => (
                      <>
                        <div
                          id={taskItem.sort}
                          key={taskItem.id}
                          onRowClick={row => {
                            this.setState({
                              // name: row.name,
                              id: row.id,
                              isDefault: row.isDefault === 1 ? true : false
                            });
                            return this._toggle(row.id);
                          }}
                          draggable="true"
                          onDrag={e => {
                            this.setSourceTaskOrder(taskItem);
                          }}
                          onDragOver={e => {
                            e.preventDefault();

                            this.setTargetTaskOrder(taskItem);
                          }}
                          onDrop={e => {
                            this.changeTaskOrder();
                          }}
                        >
                          <Tags
                            task={taskItem}
                            openEditModal={() => {
                              this._getPartnerTypeSettings(taskItem);
                              this.updateTask(taskItem);
                            }}
                            openDeleteModal={() => {
                              this.deleteTask(taskItem);
                            }}
                          />
                        </div>
                      </>
                    ))
                ) : (
                  <NoRecordsFound
                    hideCard
                    description="No Partner Types are added yet"
                  />
                )}
              </div>
            </div>
          </>
        </TabPane>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { roleId } = state.user;

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(PartnerTypeTab);
