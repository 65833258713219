import React from "react";
import toast from "../../components/base/Toast";

// Component
import Form from "../../components/base/Form";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import CustomerFormFields from "./CustomerFormFields";

// Configs
import { endpoints } from "../../configs";

//API call
import { apiClient } from "../../apiClient";

// Helper
import { toString } from "../../lib/helper";
import { isBadRequest } from "../../common/http";

export default class AddCustomerForm extends React.Component {
  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save customer form in the final
    this._customerRequest(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define customer request constants
    const displayCompanyName = values.displayCompanyName;
    const legalCompanyName = values.legalCompanyName;

    if (displayCompanyName == null || legalCompanyName == null) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.displayCompanyName = toString(values.displayCompanyName);
    values.legalCompanyName = toString(values.legalCompanyName);
    values.masterServiceAgreementStatus = toString(
      values.masterServiceAgreementStatus
        ? values.masterServiceAgreementStatus.value
        : values.masterServiceAgreementStatus
    );
    values.legalContactFullName = toString(values.legalContactFullName);
    values.legalContactEmail = toString(values.legalContactEmail);
    values.address1 = toString(values.address1);
    values.address2 = toString(values.address2);
    values.city = toString(values.city);
    values.state = toString(values.state ? values.state.value : values.state);
    values.postalCode = toString(values.postalCode);
    values.phone = toString(values.phone);

    return values;
  }

  // Customer Request API Call
  _customerRequest(data) {
    return apiClient
      .post(endpoints().customerAPI, data)
      .then(() => {
        window.location.replace("/customers");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  render() {
    const initialValues = {
      displayCompanyName: "",
      legalCompanyName: ""
    };

    return (
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          this._submit(values);
        }}
      >
        <CustomerFormFields />
        <div className="d-flex justify-content-end">
          <CancelButton onClick={() => this.props.history.goBack()} />
          <SaveButton />
        </div>
      </Form>
    );
  }
}
