import React from "react";
import SVG from "react-inlinesvg";
import Button from "../base/Button";
import AddButton from "./AddButton";

const NoRecordsFound = props => {
  const {
    topHeight,
    middleHeight,
    hideCard,
    icon,
    iconClass,
    description,
    message,
    buttonLabel,
    buttonLink,
    buttonIcon,
    position,
    showMessage,
    boldMessage,
    messageButton,
    handleMessageModal,
    handleClose
  } = props;

  const top = topHeight ? topHeight : "9vh";
  const middle = middleHeight ? middleHeight : "520px";

  const root = {
    minHeight: position === "top" ? top : middle
  };

  return (
    <div
      className={`${
        !hideCard ? "card mb-5" : ""
      } d-flex align-items-center justify-content-center flex-column`}
      style={root}
    >
      {icon && (
        <div className={`no-records-icon ${iconClass ? iconClass : ""} mb-4`}>
          <SVG src={icon} />
        </div>
      )}
      {!showMessage && !message && <strong>No records found</strong>}
      <strong>{message && message}</strong>
      {boldMessage && <strong>{boldMessage}</strong>}
      {description && (
        <div className="text-center m-1">
          <p>{description}</p>
        </div>
      )}

      {buttonLink && (
        <a href={buttonLink}>
          <Button label={buttonLabel} icon={buttonIcon} />
        </a>
      )}
      {messageButton && (
        <AddButton
          label={buttonLabel}
          onClick={() => {
            {
              handleMessageModal(true);
            }
          }}
          icon={buttonIcon}
          handleClose={handleClose}
        />
      )}
    </div>
  );
};

export default NoRecordsFound;
