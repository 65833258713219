import React from "react";
import { Media } from "reactstrap";
import moment from "moment";

// Components
import PageTitle from "../../components/base/PageTitle";
import Communication from "./inner-components/Communication";
import SideBar from "./inner-components/SideBar";
import Page404 from "../Page404";
import Avatar from "../../components/base/Avatar";
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";
import HTMLParagraph from "./inner-components/HTMLParagraph";
// Lib
import { COOKIE_USER_ID } from "../../lib/cookie";
import { getCookie } from "../../lib/helper";
import "draft-js-emoji-plugin/lib/plugin.css";
import "draft-js/dist/Draft.css";
import {
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import { connect } from "react-redux";

import { PARTNER_PERMISSION_INDIVIDUAL_PARTNER } from "../../resource/Constants";

const UserComment = props => {
  const { projectDiscussion } = props;
  const {
    message,
    userDetails,
    createdAt,
    fromUserAvatar,
    subject
  } = projectDiscussion;

  // Get discussion date
  const getDiscussionDate = () => {
    const todayDate = moment(new Date()).format("DD MMM, YYYY");
    const discussionTime = moment(createdAt).format("h:mm A");
    const prevDate = moment(createdAt).format("DD MMM, YYYY");
    let discussionDate = moment(createdAt).format("DD MMM, YYYY");

    discussionDate =
      todayDate === discussionDate && todayDate === prevDate
        ? `Today at ${discussionTime}`
        : `${prevDate} ${discussionTime}`;

    return discussionDate;
  };

  return (
    <Media className="user-rating mb-3">
      <div className="field-icon ml-4 mt-3">
        <Avatar
          firstName={userDetails && userDetails.first_name}
          lastName={userDetails && userDetails.last_name}
          size="xs"
          fontSize={12}
          url={fromUserAvatar}
        />
      </div>
      <Media body>
        <div className="rating-and-usage ml-3">
          <div className="rating-wrapper mb-0">
            <div>
              <div className="d-flex justify-content-start">
                <div>
                  <b className="h7">
                    {userDetails ? (
                      getCookie(COOKIE_USER_ID) ===
                      userDetails.id.toString() ? (
                        <span>You:</span>
                      ) : userDetails.first_name ? (
                        userDetails.first_name
                      ) : "" + " " + userDetails.last_name ? (
                        userDetails.last_name
                      ) : (
                        ""
                      )
                    ) : (
                      ""
                    )}
                  </b>
                </div>
                {createdAt && (
                  <div>
                    <span
                      className={["text-inline-grayed pl-2", "h8"].join(" ")}
                    >
                      {getDiscussionDate()}
                    </span>
                  </div>
                )}
              </div>
            </div>
            {subject && <span>{subject}</span>}
          </div>
        </div>
        <HTMLParagraph message={message} />
      </Media>
    </Media>
  );
};
class MessageDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPageNotFound: false,
      partnerId: this.props.match.params.id,
      messageId: "",
      type:
        this.props.location && this.props.location.state
          ? this.props.location.state.messageDetails.type
          : "",
      type_id:
        this.props.location && this.props.location.state
          ? this.props.location.state.messageDetails.type_id
          : "",
      messageDetails:
        this.props.location && this.props.location.state
          ? this.props.location.state.messageDetails
          : "",
      partnerDetails:
        this.props.location &&
        this.props.location.state &&
        this.props.location.state.messageDetails
          ? this.props.location.state.messageDetails.partnerDetails
          : "",
      partnerId: ""
    };
  }

  setId = partnerId => {
    this.setState({ partnerId });
  };
  componentDidMount() {
    this.setId(this.props.match.params.id);
    //get message List
    this.getMessageList(true);
  }

  // Project discussion api list
  getMessageList = () => {
    //crate params based on partner permission

    const partnerId =
      this.state.messageDetails && this.state.messageDetails.partner_id
        ? this.state.messageDetails.partner_id
        : null;

    const toUserId =
      this.state.messageDetails &&
      this.state.messageDetails.userDetails &&
      this.state.messageDetails.userDetails.id;

    const MessageId = this.props.match.params.id;

    let params;
    if (partnerId) {
      params = `partnerId=${partnerId}`;
    }

    if (
      toUserId &&
      this.state.messageDetails.type == PARTNER_PERMISSION_INDIVIDUAL_PARTNER
    ) {
      if (params) {
        params = params + `&toUserId=${toUserId}&messageId=${MessageId}`;
      } else {
        params = `toUserId=${toUserId}&messageId=${MessageId}`;
      }
    } else {
      if (params) {
        params = params + `&messageId=${MessageId}`;
      } else {
        params = `messageId=${MessageId}`;
      }
    }

    if (this.state.type_id) {
      if (params) {
        params = params + `&type=${this.state.type_id}`;
      } else {
        params = `type=${this.state.type_id}`;
      }
    }

    //get message list with details
    apiClient
      .get(`${endpoints().messageAPI}/?${params}`)
      .then(response => {
        //validate response exist or not
        if (response && response.data) {
          this.setState(
            {
              projectDiscussions: response.data.data,
              partnerId: response.data.data[0].partner_id
            },
            () => {
              this.setId(this.state.partnerId);
            }
          );
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({
            isPageNotFound: true
          });
        }
      });
  };

  render() {
    if (this.state.isPageNotFound) {
      return <Page404 />;
    }
    const { messageDetails, projectDiscussions } = this.state;
    let user =
      messageDetails && messageDetails.userDetails
        ? messageDetails.userDetails
        : null;

    return (
      <>
        <PageTitle label="Partner Communication" />
        {this.state.type == "Individual Partner" ||
        isPartner(this.props.roleId) ? (
          <>
            <div className="card border-0">
              <div className="project-details-body">
                <div className="details-body-main mt-5">
                  <Communication
                    id={this.props.match.params.id}
                    setId={this.setId}
                    type={this.state.type}
                    projectDiscussions={this.state.projectDiscussions}
                    partnerId={this.state.partnerId}
                    getMessageLists={this.getMessageList}
                    messageDetails={messageDetails}
                  />
                </div>

                {isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId) ? (
                  <SideBar partnerId={this.state.partnerId} />
                ) : isPartner(this.props.roleId) ? (
                  user && (
                    <div className="mt-4 ml-3 details-body-sidebar">
                      {/* Partner */}
                      <div className={[`sidebar-item`].join(" ")}>
                        <span className="font-weight-bold">
                          {messageDetails.companyName}
                        </span>
                        {/* Need in feature */}
                        {/* <ProfileCard
                          title="User"
                          firstName={user.first_name}
                          avatarUrl={avatar}
                          profileName={user.first_name + " " + user.last_name}
                        /> */}
                      </div>
                    </div>
                  )
                ) : (
                  ""
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            {projectDiscussions && projectDiscussions.length > 0 && (
              <>
                <div className="card border-0">
                  <div className="row">
                    <div className="col-sm-6">
                      {projectDiscussions.map(data => {
                        return (
                          <>
                            <UserComment projectDiscussion={data} />
                          </>
                        );
                      })}
                    </div>
                    {!isPartner(this.props.roleId) && (
                      <div className="col-sm-6 mt-3">
                        <span className="ml-4 font-weight-bold">Partner</span>
                        {projectDiscussions[0].partnerList &&
                          projectDiscussions[0].partnerList.length > 0 &&
                          projectDiscussions[0].partnerList.map(data => {
                            return (
                              <div className="">
                                <SideBar
                                  partnerId={data.id}
                                  showTitle={true}
                                  classNames="pt-0 ml-2"
                                />
                              </div>
                            );
                          })}
                      </div>
                    )}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(MessageDetail);
