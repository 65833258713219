import React from "react";
import ExpertRequestQuestion from "./ExpertRequestQuestion";

// Components
import SecondaryButton from "../../components/base/SecondaryButton";

// Assets
import { CalendarIcon } from "../../assets/img/icons";

class StartDate extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      startDateOptions: [
        {
          value: "Immediately",
          label: "Immediately",
          tooltip: ""
        },
        {
          value: "In 1 to 2 weeks",
          label: "In 1 to 2 weeks",
          tooltip: ""
        },
        {
          value: "More than 2 weeks from now",
          label: "More than 2 weeks from now",
          tooltip: ""
        },
        {
          value: "I'll decide later",
          label: "I'll decide later",
          tooltip: ""
        }
      ]
    };
  }

  render() {
    const { currentStep, step, prev, next } = this.props;
    const { startDateOptions } = this.state;

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <CalendarIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-4">Start Date</h2>
        </div>
        <div className="subtitle-wrapper mt-4">
          <h5 className="font-weight-light">
            When do you need the expert to start?
          </h5>
        </div>
        <div className="content">
          <ExpertRequestQuestion
            name="startDate"
            options={startDateOptions}
            error="State date is required"
            required
          />

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={next}
            >
              Next: Experts
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default StartDate;
