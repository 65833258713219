import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// Components
import Form from "../../../../../components/base/Form";
import DefaultContent from "../../../../../components/content/DefaultContent";
import Text from "../../../../../components/base/Text";
import SaveButton from "../../../../../components/base/SaveButton";
import HubSpotFieldMappingTab from "../../../../../hubspot/setting/HubSpotFieldMappingTab";
import HubSpotSyncRuleTab from "../../../../../hubspot/setting/HubSpotSyncRuleTab";
import SingleCheckbox from "../../../inner-component/Checkbox";

// Constants
import {
  SETTINGS_HUBSPOT_CLIENT_ID,
  SETTINGS_HUBSPOT_CLIENT_SECRET,
  SETTINGS_ENABLE_HUBSPOT_SYNC_REFERRAL
} from "../../../../../setting/Constants";
import Terminology from "../../../../../lib/Terminology";
import SystemName from "../../../../../Constants/SystemName";
import ConfirmationModal from "../../../../../components/base/ConfirmationModal";

const HubspotSection = props => {
  const [activeTab, setActiveTab] = useState(1);

  const {
    adminSettings,
    saveSettings,
    connectHubspot,
    HubSpotFieldList
  } = props;

  const [isHubSpotConnected, setHubSpotConnect] = useState(
    adminSettings.hubspot_refresh_token ? true : false
  );
  const [confirmationModal, setConfirmationModal] = useState(false);

  let {
    hubspot_client_id,
    hubspot_client_secret,
    enable_hubspot_sync_referral
  } = adminSettings;

  // hubspot Section Initial Values
  const hubspotSettingsInitialValues = {
    hubspot_client_id,
    hubspot_client_secret,
    enable_hubspot_sync_referral:
      enable_hubspot_sync_referral == "true" ? true : false
  };

  // Hubspot Section Submit Form
  const submit = values => {
    const data = new FormData();

    if (values.hubspot_client_id !== undefined) {
      data.append(SETTINGS_HUBSPOT_CLIENT_ID, values.hubspot_client_id);
    }

    if (values.hubspot_client_secret !== undefined) {
      data.append(SETTINGS_HUBSPOT_CLIENT_SECRET, values.hubspot_client_secret);
    }

    if (values.enable_hubspot_sync_referral !== undefined) {
      data.append(
        SETTINGS_ENABLE_HUBSPOT_SYNC_REFERRAL,
        values.enable_hubspot_sync_referral
      );
    }

    // Save settings
    saveSettings(data);
  };

  const handleConfirmationModal = () => {
    setConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  // Disconnect salesforce
  const disconnectHubSpot = () => {
    const data = new FormData();
    data.append("hubspot_access_token", "");
    data.append("hubspot_refresh_token", "");

    setHubSpotConnect(false);

    // Save settings
    saveSettings(data);
  };

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  return (
    <>
      <Nav tabs className="admin-tabs mb-2">
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 1
            })}
            onClick={() => {
              toggle(1);
            }}
          >
            General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 2
            })}
            onClick={() => {
              toggle(2);
            }}
          >
            Hubspot Field Mapping
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 3
            })}
            onClick={() => {
              toggle(3);
            }}
          >
            Hubspot Sync Rule
          </NavLink>
        </NavItem>
      </Nav>

      {isHubSpotConnected && (
        <ConfirmationModal
          title="Disconnect Hubspot"
          description="Are you sure, you want to disconnect Hubspot?"
          setModal={() => handleConfirmationModal()}
          modal={confirmationModal}
          closeModal={() => closeConfirmationModal()}
          submitValue={disconnectHubSpot}
        />
      )}

      <div className="tabs-and-preview full-width d-flex admin-settings">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className="w-100">
            <Form
              initialValues={hubspotSettingsInitialValues}
              onSubmit={values => {
                submit(values);
              }}
            >
              <DefaultContent
                title="Hubspot"
                enableButton
                buttonLabel={!isHubSpotConnected ? "Connect" : "Disconnect"}
                onButtonClick={() => {
                  if (!isHubSpotConnected) {
                    props.connectHubspot();
                  } else {
                    handleConfirmationModal();
                  }
                }}
              >
                <h5 className="font-weight-bold">Client Id</h5>
                <div className="field-wrapper">
                  <Text
                    name={SETTINGS_HUBSPOT_CLIENT_ID}
                    placeholder="Hubspot Client Id"
                  />
                </div>

                <h5 className="font-weight-bold">Client Secret</h5>
                <div className="field-wrapper">
                  <Text
                    name={SETTINGS_HUBSPOT_CLIENT_SECRET}
                    placeholder="Hubspot Client Secret"
                  />
                </div>

                <div className="field-wrapper mt-4">
                  <SingleCheckbox
                    name={SETTINGS_ENABLE_HUBSPOT_SYNC_REFERRAL}
                    label={`Enable Sync ${Terminology.get(
                      SystemName.REFERRAL
                    )}`}
                    className="accepted-terms mb-0 pb-0 mr-3"
                  />
                </div>
              </DefaultContent>

              <div className="btn-wrapper">
                <SaveButton />
              </div>
            </Form>
          </TabPane>

          <TabPane tabId={2} className="w-100">
            <HubSpotFieldMappingTab
              history={props.history}
              HubSpotFieldList={HubSpotFieldList}
            />
          </TabPane>
          <TabPane tabId={3} className="w-100">
            <HubSpotSyncRuleTab
              history={props.history}
              HubSpotFieldList={HubSpotFieldList}
            />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default HubspotSection;
