import ArrayList from "../lib/ArrayList";
/**
 * get partner setting by Id
 */
export function getPartnerSettingById(
  partnerType,
  partnerSettings,
  settingName
) {
  try {
    //initially set allow referral is false
    let allowReferralSubmission = false;

    //validate partner detaisl exist or not
    if (partnerType && partnerType.length > 0) {
      //get partner setting lis by name
      let allowReferralSubmissionPartnerSetting = ArrayList.getByKey(
        partnerSettings,
        settingName
      );

      //validate partner setting exist or not
      if (
        allowReferralSubmissionPartnerSetting &&
        allowReferralSubmissionPartnerSetting.length > 0
      ) {
        //loop the setting compare to partner partne type
        allowReferralSubmissionPartnerSetting.forEach(data => {
          //find the partner type in partner details
          let partnerTypeExist = partnerType.find(
            type => type.type_id == data.partnerTypeId
          );

          //validate partner type exist or not
          if (partnerTypeExist && data.value == "true") {
            allowReferralSubmission = true;
          }
        });
      }

      return allowReferralSubmission;
    }
  } catch (err) {
    console.log(err);
  }
}

export function IsPartnerTypeSettingEnabled(partnerSettings, settingName) {
  try {
    let value = false;

    if (partnerSettings && partnerSettings.length > 0) {
      for (let i = 0; i < partnerSettings.length; i++) {
        if (
          partnerSettings[i].name == settingName &&
          partnerSettings[i].value == "true"
        ) {
          value = true;
        }
      }

      return value;
    }
  } catch (err) {
    console.log(err);
  }
}
