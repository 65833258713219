import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import Form from "../../../../../components/base/Form";
import Select from "../../../../../components/base/Select";
import CancelButton from "../../../../../components/base/CancelButton";
import Button from "../../../../../components/base/Button";
import Text from "../../../../../components/base/Text";
import Radio from "../../../../../components/base/Radio";
import MultiselectCreatable from "../../../../../components/base/MultiselectCreatable";
import DragAndDropField from "../../../../../components/base/DragAndDropField";
import Hint from "../../../../../components/base/Hint";

import {
  TASK_TYPE_DROP_DOWN_FIELD,
  TASK_TYPE_DROP_DOWN_MULTI_SELECT,
  TASK_TYPE_DROP_DOWN_SINGLE_SELECT,
  TASK_TYPE_FILE_UPLOAD,
  TASK_VISIBILITY_PUBLIC,
  percentOptions,
  taskTypeOptions,
  visibilityOptions
} from "../../../../../modules/task/Constant";
import {
  TAG_PERMISSION_SELECT_AND_ADD,
  TAG_PERMISSION_SELECT_AND_ADD_VALUE,
  TAG_PERMISSION_SELECT_AND_NOT_ADD,
  TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
} from "../../../../../tag/Constants";

const AddFieldModal = props => {
  const { isOpen, toggle, addNewField, index, formId } = props;
  const [resourceFile, setResorceFile] = useState("");
  const [resourceFileName, setResorceFileName] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [selectedDropDownType, setSelectedDropDownType] = useState(
    TASK_TYPE_DROP_DOWN_SINGLE_SELECT
  );
  const [tagTypePermission, setTagTypePermission] = useState("");

  const handleTypeChange = selectedTypeObject => {
    if (selectedTypeObject && selectedTypeObject.value) {
      setSelectedType(selectedTypeObject.value);
    }
  };

  const closeAddFieldModal = () => {
    toggle();
    setResorceFileName("");
    setResorceFile("");
    setSelectedType("");
  };

  const _submit = values => {
    const data = new FormData();
    data.append("formId", formId);
    data.append("name", values.name || "");
    data.append("description", values.description || "");
    data.append(
      "type",
      (values.type && values.type.value && values.type.value) || ""
    );
    data.append("order", index || 0);
    data.append(
      "percent",
      (values.percentage &&
        values.percentage.value &&
        values.percentage.value) ||
        0
    );
    data.append(
      "visibility",
      (values.visibility &&
        values.visibility.value &&
        values.visibility.value) ||
        TASK_VISIBILITY_PUBLIC
    );
    data.append("resourceFile", resourceFile ? resourceFile : "");
    data.append("resourceFileName", resourceFileName ? resourceFileName : "");
    data.append("selectType", selectedDropDownType ? selectedDropDownType : "");
    data.append(
      "customTags",
      values.customTags && values.customTags.length > 0
        ? JSON.stringify(values.customTags)
        : ""
    );

    data.append(
      "tagTypePermission",
      tagTypePermission === TAG_PERMISSION_SELECT_AND_ADD
        ? TAG_PERMISSION_SELECT_AND_ADD_VALUE
        : tagTypePermission === TAG_PERMISSION_SELECT_AND_NOT_ADD
        ? TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
        : ""
    );

    addNewField(data);
    // closeAddFieldModal();
  };

  // Handle resource file upload
  const handleResourceFile = files => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.name) {
        setResorceFileName(file.name);

        setResorceFile(file);
      }
    }
  };

  const initialValues = {
    name: "",
    description: "",
    type: "",
    percentage: "",
    visibility: "",
    tagType_select: ""
  };

  return (
    <Modal
      isOpen={isOpen}
      toggle={closeAddFieldModal}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          _submit(values);
        }}
      >
        <ModalHeader toggle={closeAddFieldModal}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Add Application Field
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div>
              <Text
                id={`name`}
                name={`name`}
                label="Name"
                placeholder="Name"
                required
                error=""
              />
            </div>
            <div>
              <Text
                id={`description`}
                name={`description`}
                label="Description"
                placeholder="Description"
                error=""
              />
            </div>
            <div>
              <Select
                label="Percentage"
                name="percentage"
                placeholder="Select"
                options={percentOptions}
                isSearchable={true}
              />
            </div>
            <div>
              <Select
                label="Visibility"
                name="visibility"
                placeholder="Select"
                options={visibilityOptions}
                isSearchable={true}
              />
            </div>
            <div>
              <Select
                label="Type"
                name="type"
                placeholder="Select"
                options={taskTypeOptions}
                isSearchable={true}
                required
                handleChange={e => handleTypeChange(e)}
              />
            </div>
            {selectedType === TASK_TYPE_FILE_UPLOAD && (
              <>
                <div className="w-100 border-bottom mt-4 mb-3"></div>
                <div className="form-wrapper mt-4">
                  <div className="flex-column">
                    <DragAndDropField
                      label={
                        "Add Supporting Materials for a Partner to Download"
                      }
                      name={"file"}
                      btnLabel={"Upload File"}
                      onFileChange={handleResourceFile}
                      data={[]}
                      minHeight={"140px"}
                    />

                    <Hint
                      id="supportingMaterial"
                      hintText="If the partners need to download a template, add them here."
                    />
                  </div>
                </div>
              </>
            )}
            {selectedType === TASK_TYPE_DROP_DOWN_FIELD && (
              <>
                <div className="w-100 border-bottom mt-4 mb-3"></div>
              </>
            )}
            {selectedType === TASK_TYPE_DROP_DOWN_FIELD && (
              <>
                <div>
                  <Radio
                    name={`dropdownType`}
                    options={[
                      TASK_TYPE_DROP_DOWN_SINGLE_SELECT,
                      TASK_TYPE_DROP_DOWN_MULTI_SELECT
                    ]}
                    size="large"
                    label="Drop Down Type"
                    onChange={e => {
                      if (e && e.target && e.target.value) {
                        setSelectedDropDownType(e.target.value);
                      }
                    }}
                    defaultValue={selectedDropDownType}
                    showInline={true}
                    className="row ml-0 mr-0 mb-0 pl-1 pr-1"
                    radioClassName="col-6 mb-0"
                  />
                </div>
                <div className="field-wrapper">
                  <MultiselectCreatable
                    name={"customTags"}
                    label={"Field Values"}
                    placeholder={"Enter the field values"}
                    closeMenuOnSelect={false}
                  />
                </div>
                {selectedDropDownType === TASK_TYPE_DROP_DOWN_MULTI_SELECT && (
                  <div className="form-wrapper">
                    <h6 className="mb-2">Partner Permissions</h6>
                    <div className="px-3 w-100">
                      <Radio
                        name="permissions"
                        options={[
                          TAG_PERMISSION_SELECT_AND_ADD,
                          TAG_PERMISSION_SELECT_AND_NOT_ADD
                        ]}
                        size="large"
                        onChange={e => {
                          if (e && e.target && e.target.value) {
                            setTagTypePermission(e.target.value);
                          }
                        }}
                        defaultValue={
                          tagTypePermission &&
                          tagTypePermission ===
                            TAG_PERMISSION_SELECT_AND_ADD_VALUE
                            ? TAG_PERMISSION_SELECT_AND_ADD
                            : tagTypePermission ===
                              TAG_PERMISSION_SELECT_AND_NOT_ADD_VALUE
                            ? TAG_PERMISSION_SELECT_AND_NOT_ADD
                            : ""
                        }
                      />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={closeAddFieldModal} />
            <Button
              label="Add New Field"
              type="submit"
              className="btn btn-primary"
            />
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default AddFieldModal;
