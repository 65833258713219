export const ELITE_PARTNER = "Elite Partner";
export const PARTNER_UNSPECIFIED = "Unspecified";

/**
 * Get Partner Type Option
 */
export const partnerTypeOptions = [
  {
    label: ELITE_PARTNER,
    value: ELITE_PARTNER
  }
];
