import React, { useState } from "react";
import { EnvelopeIcon } from "../../assets/img/icons";

// Components
import Avatar from "../../components/base/Avatar";
import SelectDropdown from "../../components/base/SelectDropdown";

// Helper
import { getFullName } from "../../lib/helper";

const TeamMember = ({
  member,
  index,
  userRoles,
  removeTeamMember,
  handleUserRole
}) => {
  const [removeMember, setRemoveMember] = useState(false);

  return (
    <div className="member" key={index}>
      <div className="avatar">
        {member.avatarUrl ? (
          <Avatar
            firstName={member.firstName}
            lastName={member.lastName}
            size="customSize"
            imageSize={"48"}
            url={member.avatarUrl}
          />
        ) : (
          <EnvelopeIcon />
        )}
      </div>
      <p className="h6-5 font-weight-bold">
        {getFullName(member.firstName, member.lastName)}
        <span className="font-weight-normal text-inline-grayed">
          ({member.email})
        </span>
      </p>
      <div className="actions">
        {!removeMember ? (
          <SelectDropdown
            name="userRoles"
            dropdownLinks={userRoles}
            buttonLabel={member.role || "Admin"}
            required={true}
            handleChange={values => handleUserRole(values, member)}
          />
        ) : (
          <>
            <span className={["h7"].join(" ")}>Are you sure ?</span>
            <button
              className="btn btn-danger"
              onClick={() => {
                setRemoveMember(prevState => !prevState);
                removeTeamMember(member);
              }}
            >
              Yes, remove.
            </button>
          </>
        )}
        <button
          className="btn btn-outline-grayed"
          onClick={() => {
            setRemoveMember(prevState => !prevState);
          }}
        >
          {!removeMember ? "Remove" : "Nevermind!"}
        </button>
      </div>
    </div>
  );
};

export default TeamMember;
