import React from "react";
import SelectDropdown from "./base/SelectDropdown";
import { getParamsByName } from "../lib/helper";
import {
  DEFAULT_CARD_PAGE_SIZE,
  DEFAULT_PAGE_SIZE,
  PAGE_SIZE
} from "../helpers/Page";

//Page size filter options
const options = [
  {
    value: "10",
    label: "10"
  },
  {
    value: "25",
    label: "25"
  },
  {
    value: "50",
    label: "50"
  },
  {
    value: "100",
    label: "100"
  }
];

class PageSize extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectedPageSizeOption: getParamsByName("pageSize")
        ? getParamsByName("pageSize")
        : this.props.customPageSizeOption
        ? DEFAULT_CARD_PAGE_SIZE
        : props.pageSizeOptions
        ? PAGE_SIZE
        : DEFAULT_PAGE_SIZE
    };
  }

  componentDidMount() {
    this.setState({
      selectedPageSizeOption: this.state.selectedPageSizeOption
        ? this.state.selectedPageSizeOption
        : this.props.customPageSizeOption
        ? DEFAULT_CARD_PAGE_SIZE
        : this.props.pageSizeOptions
        ? PAGE_SIZE
        : DEFAULT_PAGE_SIZE
    });
  }

  // handle Page Size
  handlePageSizeByChange = value => {
    this.setState({
      selectedPageSizeOption: this.getPageSizeValueFromLabel(value)
    });
    this.getPageSizeByOptions(value);
  };

  // get page size value from label
  getPageSizeValueFromLabel(label) {
    const pageSizeOptions = this.props.customPageSizeOption
      ? this.props.customPageSizeOption
      : this.props.pageSizeOptions
      ? this.props.pageSizeOptions
      : options
      ? options
      : this.state.selectedPageSizeOption;

    const selectedPageSizeOption = pageSizeOptions.find(
      option => option.label === label
    );

    if (selectedPageSizeOption) {
      return selectedPageSizeOption.value;
    }

    return "";
  }

  // get seleted page size
  getSelectedPageSizeLabel() {
    const pageSizeOptions = this.props.customPageSizeOption
      ? this.props.customPageSizeOption
      : this.props.pageSizeOptions
      ? this.props.pageSizeOptions
      : options
      ? options
      : this.state.selectedPageSizeOption;

    let selectedPageSizeOptions;
    selectedPageSizeOptions = pageSizeOptions.find(
      option => option.value == this.state.selectedPageSizeOption
    );

    if (selectedPageSizeOptions) {
      return selectedPageSizeOptions.label;
    }

    return "";
  }

  // handle on change
  getPageSizeByOptions = value => {
    const valueArray = this.getPageSizeValueFromLabel(value).split(":");
    const PageSize = valueArray[0];
    this.props.onChange(PageSize);
  };

  render() {
    return (
      <SelectDropdown
        name="pageSize"
        buttonLabel={this.getSelectedPageSizeLabel()}
        dropdownLinks={
          this.props.customPageSizeOption
            ? this.props.customPageSizeOption
            : this.props.pageSizeOptions
            ? this.props.pageSizeOptions
            : options
        }
        color={"gray"}
        pageSize
        hideCaret
        handleChange={this.handlePageSizeByChange}
      />
    );
  }
}

export default PageSize;
