import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchResourceList } from "../../actions/resource";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";
import { isBadRequest } from "../../common/http";
import toast from "../../components/base/Toast";

// Components
import PageTitle from "../../components/base/PageTitle";
import CountBadge from "../../components/base/CountBadge";
import AddButton from "../../components/base/AddButton";
import ResourceCardView from "./inner-component/ResourceCardView";
import CSVDownload from "../../components/CSVDownload";

import { RESOURCE_PUBLISHED, RESOURCE_DRAFT } from "../../resource/Constants";

// Helper
import {
  getParamsByName,
  isSuperAdmin,
  isCompanyAdmin,
  isPartner,
  isCompanyManager
} from "../../lib/helper";

class Resource extends React.Component {
  state = {
    searchCount: 0,
    searchParam: "",
    activeTab: RESOURCE_PUBLISHED,
    cvsData: [],
    page: 1,
    pageSizeValue: getParamsByName("pageSize"),
    searchValue: getParamsByName("search")
  };
  componentDidMount() {
    // Check is logged in user
    this.setActiveTab();
    this._resourceList();
  }

  // Set Active Tab
  setActiveTab = () => {
    const status = getParamsByName("section") || this.state.activeTab;
    const searchParam = this.state.searchValue ? this.state.searchValue : "";
    const pageSize = this.state.pageSizeValue ? this.state.pageSizeValue : 12;

    this.setState(
      {
        activeTab:
          status === RESOURCE_PUBLISHED
            ? RESOURCE_PUBLISHED
            : status === RESOURCE_DRAFT
            ? RESOURCE_DRAFT
            : RESOURCE_PUBLISHED,
        searchParam: searchParam
      },
      () => {
        if (searchParam) {
          this.props.history.push(
            `/resources?section=${status}&pagination=true&search=${searchParam}&pageSize=${pageSize}`
          );
        } else {
          this.props.history.push(
            `/resources?section=${status}&pageSize=${pageSize}`
          );
        }
      }
    );
  };

  // Resourcedelete api call
  _resourceList = () => {
    return apiClient
      .get(`${endpoints().resourceAPI}/search/resourcelist`)
      .then(response => {
        const resourceData = response.data.data;
        if (resourceData) {
          resourceData.forEach(data => {
            this.state.cvsData.push({
              title: data.title,
              status: data.status,
              sharingPermission: data.sharingPermission,
              primaryCategory: data.primaryCategory
            });
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Toggle Tab
  toggle = tab => {
    this.setState({
      activeTab: tab
    });
    const searchParam = getParamsByName("search") || "";
    let params = [];
    if (searchParam) {
      params.push(`search=${searchParam}`);
    }
    this.props.history.push(
      `/resources?section=${tab}&pageSize=${
        this.state.pageSizeValue ? this.state.pageSizeValue : 10
      }&${params.join("&")}`
    );
  };

  getSearchParam = (searchParam, page) => {
    this.setState({
      searchParam: searchParam,
      page: page
    });
  };

  render() {
    const { activeTab, cvsData, searchParam, page } = this.state;
    const { resource, roleId } = this.props;
    let resourceData = [];
    if (resource && resource.data !== undefined) {
      Object.keys(resource.data).forEach(function(key) {
        resourceData.push({
          title: resource.data[key].title,
          status: resource.data[key].status,
          primaryCategory: resource.data[key].primaryCategory
        });
      });
    }

    let cvsDatas = searchParam
      ? resourceData
      : !searchParam && activeTab !== RESOURCE_DRAFT && page === 1
      ? cvsData
      : resourceData;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchStatus = params.get("section");

    const isPublished =
      searchStatus === RESOURCE_PUBLISHED || activeTab === RESOURCE_PUBLISHED;
    const isDraft =
      searchStatus === RESOURCE_DRAFT || activeTab === RESOURCE_DRAFT;

    // headers for Export
    const headers = [
      { label: "Name", key: "title" },
      { label: "Status", key: "status" },
      { label: "Category", key: "primaryCategory" }
    ];

    return (
      <div>
        {/* page heading */}
        <div className="d-flex justify-content-between">
          <PageTitle
            label={
              isPartner(this.props.roleId) ? "Resources" : "Partner Resources"
            }
          />
          {(isSuperAdmin(roleId) ||
            isCompanyAdmin(roleId) ||
            isCompanyManager(roleId)) && (
            <div className="col-7 mt-2">
              <CSVDownload
                data={cvsDatas}
                headers={headers}
                fileName="Partner Resources"
                className="pull-right"
              />
            </div>
          )}
          {(isSuperAdmin(roleId) ||
            isCompanyAdmin(roleId) ||
            isCompanyManager(roleId)) && (
            <div>
              <AddButton
                label="Add Resource"
                targetUrl="/resource/add"
                className="pull-right btn btn-secondary mt-2"
              />
            </div>
          )}
        </div>
        {(isSuperAdmin(roleId) ||
          isCompanyAdmin(roleId) ||
          isCompanyManager(roleId)) && (
          <Nav tabs className="admin-tabs mb-0">
            <NavItem>
              <NavLink
                className={classnames({ active: isPublished })}
                onClick={() => {
                  this.toggle(RESOURCE_PUBLISHED);
                }}
              >
                Published
                <CountBadge
                  count={
                    resource.pagination
                      ? resource.pagination.publishedResourceCount
                      : 0
                  }
                  isActive={isPublished}
                />
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: isDraft })}
                onClick={() => {
                  this.toggle(RESOURCE_DRAFT);
                }}
              >
                Draft
                <CountBadge
                  count={
                    resource.pagination
                      ? resource.pagination.draftedResourceCount
                      : 0
                  }
                  isActive={isDraft}
                />
              </NavLink>
            </NavItem>
          </Nav>
        )}

        <TabContent activeTab={activeTab}>
          {/*Published Tab*/}
          <TabPane tabId={RESOURCE_PUBLISHED}>
            <ResourceCardView
              activeTab={activeTab}
              resource={this.props.resource}
              resourceDetails={this.props.resourceDetails}
              actions={this.props.actions}
              history={this.props.history}
              getSearchParam={this.getSearchParam}
            />
          </TabPane>

          {/* Draft Tab */}
          {(isSuperAdmin(roleId) ||
            isCompanyAdmin(roleId) ||
            isCompanyManager(roleId)) && (
            <TabPane tabId={RESOURCE_DRAFT}>
              <ResourceCardView
                activeTab={activeTab}
                resource={this.props.resource}
                resourceDetails={this.props.resourceDetails}
                actions={this.props.actions}
                history={this.props.history}
                getSearchParam={this.getSearchParam}
              />
            </TabPane>
          )}
        </TabContent>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    resource: state.resource,
    resourceDetails: state.resource ? state.resource.data : {},
    roleId: state.user ? state.user.roleId : ""
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchResourceList }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Resource);
