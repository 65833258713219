import React from "react";

// Components
import Currency from "../../../components/base/Currency";
import SidebarItem from "./SidebarItem";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../lib/helper";

import { useSelector } from "react-redux";

const EstimatedEarnings = ({ handleChange, estimatedEarning }) => {
  const currentUser = useSelector(state => state.user);

  return (
    <>
      <SidebarItem>
        <p className="font-weight-bold mb-1">Estimated Earnings</p>
        <Currency
          name="estimatedEarning"
          placeholder="Estimated Earnings"
          minValue={0}
          onChange={handleChange}
          disabled={
            !isSuperAdmin(currentUser && currentUser.roleId) &&
            !isCompanyAdmin(currentUser && currentUser.roleId) &&
            !isCompanyManager(currentUser && currentUser.roleId)
              ? true
              : false
          }
        />
      </SidebarItem>

      <SidebarItem></SidebarItem>
    </>
  );
};

export default EstimatedEarnings;
