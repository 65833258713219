import React from "react";
import PropTypes from "prop-types";
import ExpertCard from "./base/ExpertCard";
import { connect } from "react-redux";

class ExpertGrid extends React.Component {
  state = {
    experts: this.props.expertList
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { expertList, updatedExpertList } = this.props;

    const prevExpertList = JSON.stringify(prevProps.expertList);
    const prevUpdatedExpertList = JSON.stringify(prevProps.updatedExpertList);

    // Update expert list
    if (prevExpertList !== JSON.stringify(expertList)) {
      this.setState({ experts: expertList });
    } else if (prevUpdatedExpertList !== JSON.stringify(updatedExpertList)) {
      this.setState({ experts: updatedExpertList });
    }
  }

  render() {
    const {
      selectedExperts,
      handleExperts,
      selectExperts,
      certified,
      openLinkInNewTab,
      showDeleteIcon,
      currentUrl,
      expertIds
    } = this.props;

    const { experts, id } = this.state;

    return (
      <div className="row" style={this.props.style}>
        {experts &&
          experts.length > 0 &&
          experts.map(expert =>
            selectedExperts ? (
              (selectExperts
                ? selectedExperts.indexOf(expert.id) === -1
                : selectedExperts.indexOf(expert.id) > -1) && (
                <ExpertCard
                  id={id}
                  classnames="expert-card"
                  data={expert}
                  selectExperts={selectExperts}
                  selected={
                    expertIds && expertIds.indexOf(parseInt(expert.id, 10)) > -1
                  }
                  key={expert.id}
                  certified={certified}
                  showDeleteIcon={showDeleteIcon}
                  handleExperts={handleExperts}
                  link={
                    currentUrl
                      ? `/expert-profile/${expert.id}?redirect=${currentUrl}`
                      : ""
                  }
                  openLinkInNewTab={openLinkInNewTab}
                />
              )
            ) : (
              <ExpertCard
                id={id}
                classnames="expert-card"
                data={expert}
                key={expert.id}
                certified={certified}
                handleExperts={handleExperts}
                link={
                  currentUrl
                    ? `/expert-profile/${expert.id}?redirect=${currentUrl}`
                    : ""
                }
                openLinkInNewTab={openLinkInNewTab}
              />
            )
          )}
      </div>
    );
  }
}

ExpertGrid.propTypes = {
  expertList: PropTypes.string,
  selectedExperts: PropTypes.string,
  selectExperts: PropTypes.string,
  openLinkInNewTab: PropTypes.string,
  removeExpert: PropTypes.string,
  currentUrl: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.string,
  size: PropTypes.string,
  handleExperts: PropTypes.func,
  certified: PropTypes.string
};

const mapStateToProps = state => {
  const { approvedExperts } = state.table;
  return {
    updatedExpertList:
      approvedExperts && !approvedExperts.isFetching ? approvedExperts["1"] : ""
  };
};

export default connect(mapStateToProps)(ExpertGrid);
