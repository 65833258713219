import React, { useState } from "react";
import SaveButton from "../../components/base/SaveButton";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// Components
import Form from "../../components/base/Form";
import Text from "../../components/Text";

import Select from "../../components/base/Select";

const editSyncRuleModal = props => {
  const {
    isOpen,
    toggle,
    selectedSyncRule,
    AccountObjectFieldList,
    OpportunityObjectFieldList,
    salesforceObjectNameList,
    relationalOperators,
    updateFunction
  } = props;

  const [
    selectedSalesforceObjectName,
    setSelectedSalesforceObjectName
  ] = useState("");

  const handleSalesforceObjectChange = e => {
    setSelectedSalesforceObjectName(
      e &&
        e.values &&
        e.values.salesforceObjectName &&
        e.values.salesforceObjectName.value
    );
  };
  return (
    <div>
      <Modal isOpen={isOpen} className="edit-task-modal" backdrop="static">
        <ModalHeader
          toggle={() => {
            toggle();
          }}
        >
          <p className="text-center font-weight-bold mb-3">
            Edit Salesforce Sync Rule
          </p>
        </ModalHeader>

        <Form
          initialValues={{
            salesforceObjectName: selectedSyncRule
              ? salesforceObjectNameList().find(
                  ({ value }) => value == selectedSyncRule.salesforceObjectName
                )
              : [],
            salesforceFieldName:
              selectedSyncRule && selectedSyncRule.salesforceFieldName
                ? {
                    label: selectedSyncRule.salesforceFieldName,
                    value: selectedSyncRule.salesforceFieldName,
                    type: selectedSyncRule.type
                  }
                : [],
            operator: selectedSyncRule
              ? relationalOperators().find(
                  ({ value }) => value == selectedSyncRule.operator
                )
              : [],
            value: (selectedSyncRule && selectedSyncRule.value) || ""
          }}
          onSubmit={values => {
            let updateData = {};
            let SycRuleId = selectedSyncRule && selectedSyncRule.id;

            updateData.SalesforceObjectName =
              values &&
              values.salesforceObjectName &&
              values.salesforceObjectName.value;

            updateData.salesforceFieldName =
              values &&
              values.salesforceFieldName &&
              values.salesforceFieldName.value;

            updateData.operator =
              values && values.operator && values.operator.value;
            updateData.value = values && values.value;

            updateData.type =
              values &&
              values.salesforceFieldName &&
              values.salesforceFieldName.type
                ? values.salesforceFieldName.type
                : selectedSyncRule.salesforceFieldName.type;
            if (SycRuleId) {
              updateFunction.actions.updateSalesforceSyncRule(
                SycRuleId,
                updateData,
                " ",
                toggle
              );
            }
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div className="field-wrapper mb-3">
                <Select
                  name="salesforceObjectName"
                  label="Salesforce Object "
                  placeholder="Select Salesforce Object "
                  options={salesforceObjectNameList()}
                  onInputChange={e => handleSalesforceObjectChange(e)}
                  required
                  isClearable={true}
                />
              </div>
              <div className="field-wrapper mb-3">
                <Select
                  name="salesforceFieldName"
                  label="Salesforce Field "
                  placeholder="Select Salesforce Field "
                  options={
                    selectedSalesforceObjectName === "Account" ||
                    (selectedSyncRule &&
                      selectedSyncRule.salesforceObjectName == "Account")
                      ? AccountObjectFieldList
                      : selectedSalesforceObjectName === "Opportunities" ||
                        (selectedSyncRule &&
                          selectedSyncRule.salesforceObjectName ==
                            "Opportunities")
                      ? OpportunityObjectFieldList
                      : []
                  }
                  required
                  isClearable={true}
                  isSearchable={true}
                />
              </div>

              <div className="field-wrapper mb-3">
                <Select
                  name="operator"
                  placeholder="Select Condition"
                  label="Condition"
                  options={relationalOperators()}
                  isClearable={true}
                  required
                />
              </div>

              <Text
                name="value"
                label="Value"
                placeholder="Enter Value"
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <SaveButton />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};
export default editSyncRuleModal;
