import React from "react";

const MondayDotComSection = props => {
  return (
    <div className="text-center" style={{ marginTop: "40%" }}>
      <h4>Coming soon...</h4>
    </div>
  );
};

export default MondayDotComSection;
