import React, { useState, useEffect } from "react";
import Slider from "react-slick";
import { ChevronRight } from "../../assets/img/icons";

const CardSection = ({ data, showAppsInSingelLine }) => {
  const [slidesToShow, setSlidesToShow] = useState(2);

  const [screenSize, setScreenSize] = useState(0);

  useEffect(() => {
    // Function to update slidesToShow based on screen width
    const handleResize = () => {
      if (window.innerWidth < 600) {
        setScreenSize(window.innerWidth);
        setSlidesToShow(1);
      } else {
        setScreenSize(window.innerWidth);
        setSlidesToShow(2);
      }
    };

    // Call handleResize initially
    handleResize();

    // Add event listener for window resize
    window.addEventListener("resize", handleResize);

    // Cleanup function to remove event listener
    return () => window.removeEventListener("resize", handleResize);
  }, []); // Run only once on component mount

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: slidesToShow, // Use the dynamically calculated slidesToShow value
    slidesToScroll: 1,
    prevArrow: screenSize < 600 ? null : <div></div>, // Remove prev arrow on small screens
    nextArrow: screenSize < 600 ? null : <div></div>
  };

  return (
    <div className="container pb-5" id="slider-container">
      {showAppsInSingelLine == "true" ? (
        <div>
          <Slider {...settings}>
            {data.map(item => (
              <div
                className="col-12"
                style={{ maxWidth: "48%", flex: "0 0 48%" }}
                key={item.id}
              >
                <div className="card p-0 m-0" style={{ height: "100%" }}>
                  <div
                    className="card-image w-100"
                    style={{
                      paddingTop: "56.25%",
                      position: "relative",
                      overflow: "hidden"
                    }}
                  >
                    {item.backgroundImage !== "" &&
                    item.backgroundImage !== null ? (
                      <img
                        src={item.backgroundImage}
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          objectFit: "contain"
                        }}
                        alt={item.title}
                      />
                    ) : (
                      <div
                        style={{
                          position: "absolute",
                          top: "0",
                          left: "0",
                          width: "100%",
                          height: "100%",
                          backgroundColor: item && item.background_color
                        }}
                      ></div>
                    )}
                  </div>
                  <div className="card-body">
                    <div className="font-weight-bold text-dark text-truncate">
                      {item.title}
                    </div>
                    <div
                      className="multiline-ellipsis"
                      style={{ minHeight: "72px" }}
                    >
                      {item.description}
                    </div>
                  </div>
                  <div className="pl-3 ml-1 mb-2 d-flex">
                    <div>
                      <a
                        href={item.button_link}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-dark font-weight-bold"
                      >
                        {item.button_label}
                      </a>
                    </div>
                    <div className="text-dark font-weight-bold">
                      <ChevronRight />
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      ) : (
        <div className="row section-content pt-5">
          {data.map(item => (
            <div
              className="col-sm-12 col-md-6 col-lg-4 col-xl-6 mb-4"
              key={item.id}
            >
              <div className="card p-0 m-0" style={{ height: "100%" }}>
                <div
                  className="card-image w-100"
                  style={{
                    paddingTop: "56.25%",
                    position: "relative",
                    overflow: "hidden"
                  }}
                >
                  {item.backgroundImage !== "" &&
                  item.backgroundImage !== null ? (
                    <img
                      src={item.backgroundImage}
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        objectFit: "contain"
                      }}
                      alt={item.title}
                    />
                  ) : (
                    <div
                      style={{
                        position: "absolute",
                        top: "0",
                        left: "0",
                        width: "100%",
                        height: "100%",
                        backgroundColor: item && item.background_color
                      }}
                    ></div>
                  )}
                </div>
                <div className="card-body">
                  <div className="font-weight-bold text-truncate">
                    {item.title}
                  </div>
                  <div
                    className="multiline-ellipsis"
                    style={{ minHeight: "72px" }}
                  >
                    {item.description}
                  </div>
                </div>
                <div className="pl-3 ml-1 mb-2 d-flex">
                  <div>
                    <a
                      href={item.button_link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-dark font-weight-bold"
                    >
                      {item.button_label}
                    </a>
                  </div>
                  <div className="text-dark font-weight-bold">
                    <ChevronRight />
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default CardSection;
