import React, { Component } from "react";

// Components
import Text from "../../components/base/Text";

class CollectionFormFields extends Component {
  // Render the form fields
  render() {
    return (
      <div>
        <div className="card mt-3 mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <Text
                  name="name"
                  label="Name"
                  placeholder="Enter Name"
                  required={true}
                  error=""
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CollectionFormFields;
