import React from "react";

// Config
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";

// Components
import SidebarItem from "./sidebar-items/SidebarItem";
import toast from "../../../components/base/Toast";
import PartnerModal from "./PartnerModal";

// Helper
import {
  isCompanyAdmin,
  isCompanyManager,
  isLoggedIn,
  isSuperAdmin
} from "../../../lib/helper";

// Constants
import {
  PROJECT_USER_STATUS_SHORTLISTED,
  PROJECT_USER_STATUS_NO_RESPONSE,
  PROJECT_USER_STATUS_INTERESTED,
  PROJECT_USER_STATUS_NOT_INTERESTED
} from "../../../project/Constants";
import { PARTNER_STATUS_APPROVED } from "../../../partnerStatus/Constants";
import SourcedPartnerList from "./SourcedPartnerList";
import { isBadRequest } from "../../../common/http";

import { connect } from "react-redux";

// Project source more partners waiting list statuses
const shortListedPartnerStatuses = [PROJECT_USER_STATUS_SHORTLISTED];
const invitedPartnerStatuses = [
  PROJECT_USER_STATUS_NO_RESPONSE,
  PROJECT_USER_STATUS_NOT_INTERESTED,
  PROJECT_USER_STATUS_INTERESTED
];

// Project source more partners pending list statuses
const sourcedPartnerStatuses = [
  PROJECT_USER_STATUS_SHORTLISTED,
  PROJECT_USER_STATUS_NO_RESPONSE,
  PROJECT_USER_STATUS_NOT_INTERESTED,
  PROJECT_USER_STATUS_INTERESTED
];

// Project source more partners pending list statuses
// const sourcedPartnerApprovedListStatuses = [PROJECT_USER_STATUS_ACCEPTED];

class SourcePartners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isPartnerLoading: false,
      partnerList: [],
      partnerIds: [],
      selectedPartnerIds: [],
      isPartnerModal: false,
      isProjectPartnerLoading: false,
      projectPartnerList: [],
      projectPartnerWaitingList: [],
      pendingProjectPartnerList: [],
      partnerRequestId: "",
      partnerStatus: "",
      partnerWaitingRequestId: [],
      checkBox: {
        shortListedPartners: []
      },
      editInterestStatusModal: false,
      selectedEditInterestPartnerId: "",
      selectedOptions: [],
      selectedPartnerList: [],
      shortListedPartnerList: [],
      invitedPartnerList: [],
      sourcedPartnerList: []
    };
    this.toggle = this.toggle.bind(this);
    this.handlePartners = this.handlePartners.bind(this);
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this._getProjectPartnerList(
      true,
      shortListedPartnerStatuses.join(", "),
      "shortListedPartnerList"
    );
    this._getProjectPartnerList(
      true,
      invitedPartnerStatuses.join(", "),
      "invitedPartnerList"
    );
    this._getProjectPartnerList(true, sourcedPartnerStatuses.join(", "), "");
    this._getPartnersList(this.props.projectId);
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  // Get Partner List
  _getPartnersList(projectId, selectedFilters) {
    let tags = [],
      city = "",
      type = "",
      tier = "";

    // Check filters selected
    if (selectedFilters) {
      city = selectedFilters.city;
      type = selectedFilters.type;
      tags = selectedFilters.tags;
      tier = selectedFilters.tier;
    }
    this.setState({ isPartnerLoading: true }, () => {
      apiClient
        .get(
          `${
            endpoints().partnerAPI
          }/project/${projectId}?marketplaceStatus=${PARTNER_STATUS_APPROVED}&type=${type}&tier=${tier}&tags=${tags}&city=${city}`
        )
        .then(response => {
          const partnerList = response.data.data;

          this.setState({
            isPartnerLoading: false,
            partnerList: partnerList ? partnerList : this.state.partnerList
          });
        });
    });
  }

  // Handle Modal Click
  toggle = () => {
    this.setState({ isPartnerModal: !this.state.isPartnerModal });
  };

  // Handle partners
  handlePartners(value) {
    if (!value) {
      return this.setState({ selectedPartnerIds: [] });
    }

    const partnerIds = this.state.selectedPartnerIds.slice();
    if (partnerIds.indexOf(value) > -1) {
      this.setState({
        selectedPartnerIds: partnerIds.filter(el => el !== value)
      });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ selectedPartnerIds: partnerIds.concat(value) });
    }
  }

  // Create Project User
  createProjectPartner() {
    const { selectedPartnerIds } = this.state;
    const projectId = this.props.projectId;

    const data = {
      partnerIds: selectedPartnerIds,
      projectId: projectId,
      status: PROJECT_USER_STATUS_SHORTLISTED
    };

    return apiClient
      .post(`${endpoints().projectUserAPI}/partner`, data)
      .then(() => {
        this.toggle();
        this._getProjectPartnerList(
          true,
          shortListedPartnerStatuses.join(", "),
          "shortListedPartnerList"
        );
        this._getProjectPartnerList(
          true,
          sourcedPartnerStatuses.join(", "),
          ""
        );
        this._getProjectPartnerList(
          true,
          invitedPartnerStatuses.join(", "),
          "invitedPartnerList"
        );
        this.props.handleProjectDetails(this.props.projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Delete the shortlisted partner
  deleteProjectPartner = id => {
    return apiClient
      .delete(`${endpoints().projectUserAPI}/${id}`)
      .then(() => {
        this._getProjectPartnerList(
          true,
          shortListedPartnerStatuses.join(", "),
          "shortListedPartnerList"
        );
        this._getProjectPartnerList(
          true,
          sourcedPartnerStatuses.join(", "),
          ""
        );
        this._getProjectPartnerList(
          true,
          invitedPartnerStatuses.join(", "),
          "invitedPartnerList"
        );
        this.props.handleProjectDetails(this.props.projectId);
        this.setState({ selectedOptions: "" });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Invite Project User
  inviteProjectPartner = () => {
    const { selectedOptions } = this.state;
    const projectId = this.props.projectId;

    const data = {
      partnerIds: selectedOptions,
      projectId: projectId,
      status: PROJECT_USER_STATUS_NO_RESPONSE
    };

    return apiClient
      .put(`${endpoints().projectUserAPI}/partner`, data)
      .then(() => {
        this._getProjectPartnerList(
          true,
          shortListedPartnerStatuses.join(", "),
          "shortListedPartnerList"
        );
        this._getProjectPartnerList(
          true,
          invitedPartnerStatuses.join(", "),
          "invitedPartnerList"
        );
        this._getProjectPartnerList(
          true,
          sourcedPartnerStatuses.join(", "),
          ""
        );
        this.props.handleProjectDetails(this.props.projectId);

        this.setState({ selectedOptions: [] });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Update Partner List
  updatePartnerList = selectedFilters => {
    this._getPartnersList(this.props.projectId, selectedFilters);
  };

  // Get Project User Pending List
  _getProjectPartnerList(isProjectUser, status, stateName = "") {
    const projectId = this.props.projectId;
    this.setState({ isProjectPartnerLoading: isProjectUser }, () => {
      apiClient
        .get(
          `${
            endpoints().projectUserAPI
          }?projectId=${projectId}&status=${status}`
        )
        .then(response => {
          const projectPartnerList = response.data.data;

          const selectedIds = [];
          if (projectPartnerList) {
            projectPartnerList.forEach(partner => {
              if (partner.partnerId) {
                selectedIds.push(partner.partnerId);
              }
            });
          }

          if (stateName) {
            this.setState({
              [stateName]: projectPartnerList
            });
          } else {
            this.setState({
              sourcedPartnerList: projectPartnerList,
              partnerIds: selectedIds,
              isProjectPartnerLoading: !isProjectUser,
              projectPartnerList: projectPartnerList ? projectPartnerList : ""
            });
          }
        });
    });
  }
  handleSelectShortListedPartner = partnerId => {
    const { selectedOptions } = this.state;
    if (selectedOptions.includes(partnerId)) {
      this.setState({
        selectedOptions: selectedOptions.filter(id => id !== partnerId)
      });
    } else {
      this.setState({
        selectedOptions: [...selectedOptions, partnerId]
      });
    }
  };

  render() {
    const {
      partnerIds,
      selectedPartnerIds,
      partnerList,
      isPartnerModal,
      isPartnerLoading,
      selectedOptions,
      shortListedPartnerList,
      invitedPartnerList
    } = this.state;

    const currentUrl = window.location.pathname + window.location.search;
    const { projectId } = this.props;
    const redirectUrl = `?redirect=${currentUrl}`;
    const selectedPartnerInterestIds = this.state.checkBox.shortListedPartners;

    return (
      <>
        <SidebarItem>
          {(isSuperAdmin(this.props.roleId) ||
            isCompanyAdmin(this.props.roleId) ||
            isCompanyManager(this.props.roleId)) && (
            <>
              <p className="font-weight-bold mb-1">
                {`${
                  partnerIds && partnerIds.length > 0
                    ? "Shortlist of Invited "
                    : "Invite "
                }Partners`}
              </p>{" "}
              {/*had to move this here because of design and global css restrictions*/}
              <span className="sidebar-item-description d-block h7 text-grayed mb-3">
                Select partners, send invites to participate, and track interest
              </span>
              <span
                onClick={() => {
                  this._getPartnersList(projectId);
                  this.toggle();
                }}
                className="source-more h6-5 font-weight-bold mb-4 d-block cursor-pointer"
              >
                {`Invite ${
                  partnerIds && partnerIds.length > 0 ? "More " : ""
                }Partners`}
              </span>
              {/*Partner modal*/}
              <PartnerModal
                isPartnerModal={isPartnerModal}
                isLoading={isPartnerLoading}
                toggle={this.toggle}
                partnerList={partnerList}
                handlePartners={this.handlePartners}
                selectedIds={[...selectedPartnerIds]}
                partnerIds={[...partnerIds, ...selectedPartnerIds]}
                createProjectPartner={e => this.createProjectPartner(e)}
                updatePartnerList={this.updatePartnerList}
              />
              <SourcedPartnerList
                selectedOptions={selectedOptions}
                selectedPartnerList={shortListedPartnerList}
                redirectUrl={redirectUrl}
                handleSelectShortListedPartner={
                  this.handleSelectShortListedPartner
                }
                deleteProjectPartner={this.deleteProjectPartner}
              />
              <SourcedPartnerList
                hideCheckBox={true}
                selectedOptions={partnerIds}
                selectedPartnerList={invitedPartnerList}
                redirectUrl={redirectUrl}
                deleteProjectPartner={this.deleteProjectPartner}
              />
              {shortListedPartnerList &&
                shortListedPartnerList.length > 0 &&
                partnerIds.length > 0 && (
                  <div className="btn-wrapper mt-4">
                    <button
                      className={`btn btn-primary w-100`}
                      disabled={selectedOptions.length < 1}
                      onClick={() => {
                        this.inviteProjectPartner();
                      }}
                    >
                      Invite Selected Partner
                    </button>
                  </div>
                )}
            </>
          )}
        </SidebarItem>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(SourcePartners);
