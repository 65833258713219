import React, { useEffect, useState } from "react";

// Components
import DefaultContent from "../../components/content/DefaultContent";

import Text from "../../components/Text";

const SlackSection = props => {
  return (
    <DefaultContent>
      <Text name="slackId" placeholder="Enter Slack Id" label="Slack Id:" />
    </DefaultContent>
  );
};

export default SlackSection;
