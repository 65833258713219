import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import toast from "../../components/base/Toast";

// Components
import Form from "../../components/base/Form";
import DeleteButton from "../../components/base/DeleteButton";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import PageTitle from "../../components/base/PageTitle";
import AdminActionNavigation from "../../components/base/AdminActionNavigation";
import PageSearch from "../../components/base/PageSearch";
import NoRecordsFound from "../../components/base/NoRecordsFound";
import SelectDropdown from "../../components/base/SelectDropdown";
import TabsAndContent from "../../components/base/TabsAndContent";
import DeleteModal from "../../components/base/DeleteModal";

// Constants
import { PROJECT_STATUS_COMPLETED } from "../../projectStatus/Constants";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Helper
import {
  toString,
  isCustomer,
  removeMaskedPhoneNumber,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

// Page Components
import ProjectsList from "../projects/inner-components/ProjectsList";
import CustomerFormFields from "./CustomerFormFields";
import AddUser from "./AddUser";
import UserList from "./UserList";
import AddTeamMember from "./AddTeamMember";

// 404 Page Redirect
import Page404 from "../Page404";
import Spinner from "../../components/base/Spinner";
import { TAB_DETAIL, TAB_PROJECTS, TAB_TEAM } from "../../customer/Constants";

//Assets
import projectsIcon from "../../assets/img/icons/nav-icon-projects.svg";
import { ChevronRight } from "../../assets/img/icons";
import { isBadRequest } from "../../common/http";
import { connect } from "react-redux";

class UpdateCustomerForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      customerDetails: [],
      projects: [],
      teamMembers: [],
      totalCount: "",
      loading: false,
      isInvalidRequest: false,
      openDeleteModal: false,
      activeTab: TAB_DETAIL,
      sortByOptions: [
        {
          value: "createdAt",
          label: "Most Recent Activity"
        },
        {
          value: "updatedAt",
          label: "Date Modified"
        }
      ],
      selectedSortOption: "Most Recent Activity",
      currentSection: "",
      countryId: ""
    };
  }

  componentDidMount = () => {
    const params = new URLSearchParams(this.props.history.location.search);
    const section = params.get("section");

    this.setState({
      currentSection: section,
      activeTab: section || TAB_DETAIL
    });

    this.getCustomerDetails();
    this._getCustomerProjects();
    this._getCustomerUsers();
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { search } = prevProps.history.location;
    const params = new URLSearchParams(search);
    const section = params.get("section");
    // Set current active tab
    if (this.state.currentSection !== section) {
      this.setState({
        currentSection: section,
        activeTab: section || TAB_DETAIL
      });
    }
  }

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save customer data form
    this._updateCustomer(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define Customer constants
    const displayCompanyName = values.displayCompanyName;

    if (!displayCompanyName) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    values.displayCompanyName = toString(values.displayCompanyName);
    values.legalCompanyName = toString(values.legalCompanyName);
    values.selectedMasterServiceAgreementStatus = toString(
      values.masterServiceAgreementStatus
        ? values.masterServiceAgreementStatus.value
        : values.masterServiceAgreementStatus
    );
    values.legalContactFullName = toString(values.legalContactFullName);
    values.legalContactEmail = toString(values.legalContactEmail);
    values.address1 = toString(values.address1);
    values.address2 = toString(values.address2);
    values.city = toString(values.city);
    values.stateName = toString(
      values.state ? values.state.value : values.state
    );
    values.countryName = toString(
      values.country ? values.country.label : values.country
    );
    values.postalCode = toString(values.postalCode);
    values.phone = values.phone ? removeMaskedPhoneNumber(values.phone) : "";
    return values;
  }

  // Customer update API Call
  _updateCustomer(data) {
    const { id } = this.state.customerDetails;

    const customerId = this.props.match.params.id;

    return apiClient
      .put(`${endpoints().customerAPI}/${customerId}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          if (
            isSuperAdmin(this.props.roleId) ||
            isCompanyAdmin(this.props.roleId) ||
            isCompanyManager(this.props.roleId)
          ) {
            this.props.history.push("/customers");
          }
        }
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Customer delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().customerAPI}/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        window.location.replace("/customers");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Customer details api call by id
  getCustomerDetails = () => {
    return apiClient
      .get(`${endpoints().customerAPI}/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        if (response.data) {
          this.getCountryDetails(response.data.country);
          this.setState({ customerDetails: response.data, loading: true });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  getCountryDetails = countryName => {
    return apiClient
      .get(`${endpoints().countryAPI}/${countryName}`)
      .then(response => {
        const data = response.data;
        this.setState({
          countryId: data.id
        });
      });
  };

  // Get customer projects by customer id
  _getCustomerProjects = () => {
    const customerId = !isCustomer(this.props.roleId)
      ? this.props.match.params.id
      : "";
    let params = "";
    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    ) {
      params = `?customer_id=${customerId}`;
    }
    return apiClient
      .get(`${endpoints().projectAPI}${params}`)
      .then(response => {
        const data = response.data;
        const projects = data;
        this.setState({
          projects,
          totalCount: data.totalCount
        });
      });
  };

  // Get customer users by customer id
  _getCustomerUsers = () => {
    if (!isCustomer(this.props.roleId)) {
      return apiClient
        .get(`${endpoints().customerUserAPI}/${this.props.match.params.id}`)
        .then(response => {
          const data = response.data;
          const users = data.users;
          this.setState({
            teamMembers: users
          });
        });
    } else {
      return apiClient
        .get(`${endpoints().customerAPI}/team/member`)
        .then(response => {
          const customerUsers = response.data;
          const users = customerUsers.data;
          this.setState({
            teamMembers: users
          });
        });
    }
  };

  // Search project
  _handleInputChange = e => {
    const customerId = !isCustomer(this.props.roleId)
      ? this.props.match.params.id
      : "";
    this.setState(
      {
        searchParam: e.target.value
      },
      () => {
        this.setState({ isLoading: true }, () => {
          apiClient
            .get(
              `${
                endpoints().projectAPI
              }?customer_id=${customerId}&search=${encodeURIComponent(
                this.state.searchParam
              )}&status=${this.state.status}`
            )
            .then(response => {
              const data = response.data;
              this.setState({
                isLoading: false,
                projects: !data ? [] : data,
                totalCount: data.totalCount,
                searchCount: data.totalCount
              });
            });
        });
      }
    );
  };

  toggle = tab => {
    this.setState(
      {
        activeTab: tab
      },
      () => {
        this.props.history.push(`?section=${tab}`);
      }
    );
  };

  // Handle Team Member
  handleTeamMemberChange = () => {
    this._getCustomerUsers();
  };

  handleCancelButton = () => {
    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    )
      this.props.history.push("/customers");
    else if (isCustomer(this.props.roleId)) this.props.history.push("/");
  };
  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return <Spinner />;
    }

    let {
      state,
      country,
      masterServiceAgreementStatus
    } = this.state.customerDetails;

    const selectedCountry = this.state.customerDetails.country;

    state = {
      value: state ? state : "",
      label: state ? state : ""
    };

    country = {
      value: country ? country : "",
      label: country ? country : ""
    };

    masterServiceAgreementStatus = {
      value: masterServiceAgreementStatus ? masterServiceAgreementStatus : "",
      label: masterServiceAgreementStatus ? masterServiceAgreementStatus : ""
    };

    const {
      id,
      displayCompanyName,
      legalCompanyName,
      legalContactFullName,
      legalContactEmail,
      address1,
      address2,
      city,
      postalCode,
      phone
    } = this.state.customerDetails;

    const initialValues = {
      displayCompanyName,
      legalCompanyName: legalCompanyName
        ? legalCompanyName
        : displayCompanyName,
      masterServiceAgreementStatus,
      legalContactFullName,
      legalContactEmail,
      address1,
      address2,
      city,
      state,
      postalCode,
      phone,
      country
    };

    const {
      activeTab,
      sortByOptions,
      selectedSortOption,
      projects,
      totalCount,
      isLoading,
      searchCount,
      searchParam,
      currentSection,
      countryId
    } = this.state;
    const { openDeleteModal } = this.state;

    const isCompleted = currentSection === PROJECT_STATUS_COMPLETED;

    return (
      <>
        <DeleteModal
          isOpen={openDeleteModal}
          toggle={() => {
            this.setState({ openDeleteModal: false });
          }}
          title="Delete Customer "
          label={displayCompanyName}
          deleteFunction={this.handleDelete}
        />
        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) && (
          <div className="d-flex align-items-center mb-3">
            <a
              className="cursor-pointer"
              onClick={() => {
                this.props.history.push("/customers");
              }}
            >
              Customers
            </a>
            <span
              className="text-dark d-inline-flex align-items-center"
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <span
              onClick={e => e.preventDefault()}
              className={[
                "text-dark",
                "font-weight-bold",
                "d-inline-flex",
                "align-items-center",
                "project-name",
                "w-320"
              ].join(" ")}
            >
              <span className="ellipsis">{displayCompanyName}</span>
            </span>
          </div>
        )}
        <React.Fragment>
          <PageTitle label={displayCompanyName} />

          <Nav tabs className="admin-tabs">
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === TAB_DETAIL })}
                onClick={() => {
                  this.toggle(TAB_DETAIL);
                }}
              >
                Details
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === TAB_TEAM })}
                onClick={() => {
                  this.toggle(TAB_TEAM);
                }}
              >
                Team
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === TAB_PROJECTS })}
                onClick={() => {
                  this.toggle(TAB_PROJECTS);
                }}
              >
                Projects
              </NavLink>
            </NavItem>
          </Nav>
          <TabsAndContent fullWidth>
            <TabContent activeTab={activeTab}>
              <TabPane tabId={TAB_DETAIL}>
                <div>
                  <Form
                    initialValues={initialValues}
                    onSubmit={values => {
                      this._submit(values);
                    }}
                  >
                    <CustomerFormFields
                      country={selectedCountry}
                      countryId={countryId}
                    />

                    <div className="mt-4">
                      <AdminActionNavigation>
                        <DeleteButton
                          label={"Delete Customer"}
                          onClick={() => {
                            this.setState({
                              openDeleteModal: true
                            });
                          }}
                        />
                        <CancelButton
                          className="ml-auto mr-1"
                          onClick={this.handleCancelButton}
                        />
                        <SaveButton />
                      </AdminActionNavigation>
                    </div>
                  </Form>
                </div>
              </TabPane>

              <TabPane tabId={TAB_TEAM}>
                <AddTeamMember
                  customerId={this.props.match.params.id || id}
                  members={this.state.teamMembers}
                  handleTeamMemberChange={this.handleTeamMemberChange}
                />
              </TabPane>

              <TabPane tabId={TAB_PROJECTS}>
                <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column">
                  <PageSearch
                    classnames="page-search"
                    placeholder={`Search ${
                      isCompleted ? "Completed" : "Active"
                    } Projects...`}
                    onChange={this._handleInputChange.bind(this)}
                  />

                  <SelectDropdown
                    dropdownLabel="Sort By:"
                    buttonLabel={selectedSortOption}
                    dropdownLinks={sortByOptions}
                    color={"gray"}
                    hideCaret
                    selectName={"sortby_projects"}
                  />
                </div>
                {isLoading ? (
                  <Spinner />
                ) : totalCount ? (
                  <ProjectsList
                    status={isCompleted}
                    data={projects}
                    history={this.props.history}
                    pagination={true}
                    type={"active"}
                  />
                ) : searchParam ? (
                  !searchCount ? (
                    <NoRecordsFound
                      icon={projectsIcon}
                      message="No Projects Yet"
                      description="After you start a project, they will show up here"
                    />
                  ) : (
                    ""
                  )
                ) : (
                  <NoRecordsFound
                    icon={projectsIcon}
                    message="No Projects Yet"
                    description="After you start a project, they will show up here"
                  />
                )}
              </TabPane>
              <TabPane tabId="4">
                <AddUser customerId={id} />
                <UserList match={this.props.match} />
              </TabPane>
            </TabContent>
          </TabsAndContent>
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = state => {
  let roleId = state ? state.user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(UpdateCustomerForm);
