import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import AddButton from "../../components/base/AddButton";
import SaveButton from "../../components/base/SaveButton";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownItem
} from "reactstrap";
// Components
import Form from "../../components/base/Form";
import Text from "../../components/Text";

// Configs
import { endpoints } from "../../configs";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import MoreDropdown from "../../components/base/MoreDropdown";

// API call
import { apiClient } from "../../apiClient";

import {
  addSalesforceLeadFieldMapping,
  updateSalesforceLeadFieldMapping,
  deleteSalesforceLeadFieldMapping
} from "../../actions/salesforceLeadFieldMapping";
import Select from "../../components/base/Select";
import DeleteModal from "../../components/base/DeleteModal";
import { isBadRequest } from "../../common/http";

import PartnerType from "../../components/PartnerType";

import {
  SALESFORCE_SYNC_DIRECTION_OUTBOUND,
  SALESCORCE_SYNC_DIRECTION_INBOUND,
  SALESCORCE_SYNC_DIRECTION_BOTH
} from "../Constants";
import { getParamsByName } from "../../lib/helper";

const EditMappingModal = props => {
  const [selectedSalesforceField, setSelectedSalesforceField] = useState("");
  const {
    selectedRowObject,
    closeEditModal,
    salesforceObjectNameList,
    handleChange,
    customFieldList,
    openEditModal,
    setOpenEditModal,
    update,
    selectedSalesforceObjectName,
    AccountObjectFieldList,
    OpportunityObjectFieldList,
    AccountObjectList,
    ReferralObjectList,
    accountFieldList,
    picklist,
    selectedObjectName,
    handleObjectNameChange,
    syncDirection
  } = props;
  useEffect(() => {
    setSelectedSalesforceField(picklist);
  }, [picklist]);
  const salesforceFieldChanges = async e => {
    setSelectedSalesforceField(e && e.values && e.values.salesforceFieldName);
  };

  const searchItem = getParamsByName("search") || "";

  return (
    <div>
      <Modal
        isOpen={openEditModal}
        toggle={() => {
          setOpenEditModal(false);
          setSelectedSalesforceField(picklist);
        }}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader
          toggle={() => {
            setOpenEditModal(false);
            setSelectedSalesforceField(picklist);
          }}
        >
          <p className="text-center font-weight-bold mb-3">
            Edit Salesforce Field Mapping
          </p>
        </ModalHeader>

        <Form
          initialValues={{
            objectName: selectedRowObject.objectName
              ? {
                  label: selectedRowObject.objectName,
                  value: selectedRowObject.objectName
                }
              : "",
            salesforceObjectName: selectedRowObject.salesforceObject
              ? {
                  label: selectedRowObject.salesforceObject,
                  value: selectedRowObject.salesforceObject
                }
              : "",
            fieldName: selectedRowObject.fieldName
              ? {
                  label: selectedRowObject.fieldName,
                  value: selectedRowObject.fieldName
                }
              : "",
            salesforceFieldName: selectedRowObject.salesforceFieldName
              ? {
                  label: selectedRowObject.salesforceFieldName,
                  value: selectedSalesforceField && selectedSalesforceField.name
                }
              : "",
            ObjectDefaultValue: {
              label: selectedRowObject && selectedRowObject.defaultValue,
              value: selectedRowObject && selectedRowObject.defaultValue
            },
            TextDefaultValue: selectedRowObject.defaultValue,
            partnerType: selectedRowObject.partnerType,
            syncDirection: syncDirection().find(
              ({ value }) => value === selectedRowObject.syncDirection
            )
          }}
          onSubmit={values => {
            values.objectName =
              values.objectName && values.objectName.value
                ? values.objectName.value
                : "";
            values.fieldName =
              values.fieldName && values.fieldName.value
                ? values.fieldName.value
                : "";
            values.salesforceObjectName = values.salesforceObjectName.value;
            values.defaultValue =
              selectedSalesforceField &&
              selectedSalesforceField.type === "picklist"
                ? values &&
                  values.ObjectDefaultValue &&
                  values.ObjectDefaultValue.value
                : values && values.TextDefaultValue;
            values.syncDirection =
              values && values.syncDirection ? values.syncDirection.value : 0;
            values.partnerType =
              values && values.partnerType ? values.partnerType.value : null;
            update.actions.updateSalesforceLeadFieldMapping(
              selectedRowObject.id,
              values,
              { search: searchItem }
            );
            closeEditModal(false);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <h6>
                Salesforce Object <span className="text-danger">*</span>
              </h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="salesforceObjectName"
                  placeholder="Select Salesforce Object Name"
                  options={salesforceObjectNameList()}
                  onInputChange={e => handleChange(e)}
                  required
                  isClearable={true}
                />
              </div>

              <h6>
                Salesforce Fields <span className="text-danger">*</span>
              </h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="salesforceFieldName"
                  placeholder="Select Salesforce Field Name"
                  onInputChange={e => salesforceFieldChanges(e)}
                  options={
                    selectedSalesforceObjectName === "Account (Prospect)" ||
                    selectedSalesforceObjectName === "Account (Partner)"
                      ? AccountObjectFieldList
                      : selectedSalesforceObjectName === "Opportunities"
                      ? OpportunityObjectFieldList
                      : selectedRowObject.salesforceObject ===
                          "Account (Prospect)" ||
                        selectedRowObject.salesforceObject ===
                          "Account (Partner)"
                      ? AccountObjectFieldList
                      : selectedRowObject.salesforceObject === "Opportunities"
                      ? OpportunityObjectFieldList
                      : []
                  }
                  required
                  isClearable={true}
                  isSearchable={true}
                />
              </div>

              <h6>Object</h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="objectName"
                  placeholder="Select Object Name"
                  onInputChange={e => handleObjectNameChange(e)}
                  options={
                    selectedSalesforceObjectName === "Account (Prospect)" ||
                    selectedSalesforceObjectName === "Account (Partner)"
                      ? AccountObjectList()
                      : selectedSalesforceObjectName === "Opportunities"
                      ? ReferralObjectList()
                      : selectedRowObject.salesforceObject ===
                          "Account (Prospect)" ||
                        selectedRowObject.salesforceObject ===
                          "Account (Partner)"
                      ? AccountObjectList()
                      : selectedRowObject.salesforceObject === "Opportunities"
                      ? ReferralObjectList()
                      : []
                  }
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
              <h6>Field</h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="fieldName"
                  placeholder="Select Field Name"
                  options={
                    selectedObjectName === "Account (Partner)" ||
                    selectedObjectName === "Account (Prospect)"
                      ? accountFieldList()
                      : selectedObjectName === "Referral"
                      ? customFieldList
                      : selectedRowObject.objectName === "Account (Partner)" ||
                        selectedRowObject.objectName === "Account (Prospect)"
                      ? accountFieldList()
                      : selectedRowObject.objectName === "Referral"
                      ? customFieldList
                      : []
                  }
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
              {selectedSalesforceField &&
              selectedSalesforceField.type === "picklist" ? (
                <>
                  {" "}
                  <h6>Default Value</h6>
                  <div className="field-wrapper mb-3">
                    <Select
                      name="ObjectDefaultValue"
                      placeholder="Select Default Value"
                      options={selectedSalesforceField.picklistValues}
                      isClearable={true}
                      isSearchable={true}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <Text
                    name="TextDefaultValue"
                    label="Default Value"
                    placeholder="Enter Default Value"
                  />
                </div>
              )}
              <div className="field-wrapper">
                <PartnerType
                  name="partnerType"
                  label="Partner Type"
                  placeholder="Select Partner type"
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
              <div className="field-wrapper mb-5">
                <Select
                  name="syncDirection"
                  label="Sync Direction"
                  placeholder="Select Sync Direction"
                  options={syncDirection()}
                  isClearable={true}
                  isSearchable={true}
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="" className="h6-5-important">
                  Update
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

const AddNewMappingModal = props => {
  const [selectedSalesforceField, setSelectedSalesforceField] = useState("");

  const {
    openAddModal,
    closeAddModal,
    salesforceObjectNameList,
    addfunction,
    handleChange,
    handleObjectNameChange,
    selectedSalesforceObjectName,
    AccountObjectFieldList,
    OpportunityObjectFieldList,
    AccountObjectList,
    ReferralObjectList,
    customFieldList,
    accountFieldList,
    selectedObjectName,
    syncDirection
  } = props;
  const salesforceFieldChanges = e => {
    setSelectedSalesforceField(e && e.values && e.values.salesforceFieldName);
  };
  const searchItem = getParamsByName("search") || "";

  return (
    <div>
      <Modal
        isOpen={openAddModal}
        toggle={() => {
          closeAddModal(prevState => !prevState);
          setSelectedSalesforceField("");
        }}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader
          toggle={() => {
            closeAddModal(prevState => !prevState);
            setSelectedSalesforceField("");
          }}
        >
          <p className="text-center font-weight-bold mb-3">
            Add Salesforce Field Mapping
          </p>
        </ModalHeader>

        <Form
          initialValues={{
            objectName: "",
            salesforceObjectName: "",
            fieldName: "",
            salesforceFieldName: "",
            defaultValue: "",
            partnerType: "",
            syncDirection: ""
          }}
          onSubmit={values => {
            values.objectName =
              values && values.objectName ? values.objectName.value : "";
            values.fieldName =
              values && values.fieldName ? values.fieldName.value : "";
            values.salesforceObjectName =
              values && values.salesforceObjectName
                ? values.salesforceObjectName.value
                : "";
            values.defaultValue =
              selectedSalesforceField &&
              selectedSalesforceField.type === "picklist"
                ? values &&
                  values.ObjectDefaultValue &&
                  values.ObjectDefaultValue.value
                : values && values.TextDefaultValue;
            values.syncDirection =
              values && values.syncDirection ? values.syncDirection.value : 0;
            addfunction.actions.addSalesforceLeadFieldMapping(values, {
              search: searchItem
            });
            closeAddModal(false);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div className="field-wrapper mb-3">
                <Select
                  name="salesforceObjectName"
                  label="Salesforce Object Name"
                  placeholder="Select Salesforce Object Name"
                  options={salesforceObjectNameList()}
                  onInputChange={e => handleChange(e)}
                  required
                  isClearable={true}
                />
              </div>
              <div className="field-wrapper mb-3">
                <Select
                  name="salesforceFieldName"
                  label="Salesforce Field Name"
                  placeholder="Select Salesforce Field Name"
                  onInputChange={e => salesforceFieldChanges(e)}
                  options={
                    selectedSalesforceObjectName === "Account (Partner)" ||
                    selectedSalesforceObjectName === "Account (Prospect)"
                      ? AccountObjectFieldList
                      : selectedSalesforceObjectName === "Opportunities"
                      ? OpportunityObjectFieldList
                      : []
                  }
                  required
                  isClearable={true}
                  isSearchable={true}
                />
              </div>

              <h6>Object</h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="objectName"
                  placeholder="Select Object Name"
                  onInputChange={e => handleObjectNameChange(e)}
                  options={
                    selectedSalesforceObjectName === "Account (Partner)" ||
                    selectedSalesforceObjectName === "Account (Prospect)"
                      ? AccountObjectList()
                      : selectedSalesforceObjectName === "Opportunities"
                      ? ReferralObjectList()
                      : []
                  }
                  isClearable={true}
                  isSearchable={true}
                />
              </div>

              <h6>Field</h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="fieldName"
                  placeholder="Select Field Name"
                  options={
                    selectedObjectName === "Account (Partner)" ||
                    selectedObjectName === "Account (Prospect)"
                      ? accountFieldList()
                      : selectedObjectName === "Referral"
                      ? customFieldList
                      : []
                  }
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
              {selectedSalesforceField &&
              selectedSalesforceField.type === "picklist" ? (
                <>
                  {" "}
                  <h6>Default Value</h6>
                  <div className="field-wrapper mb-3">
                    <Select
                      name="ObjectDefaultValue"
                      placeholder="Select Default Value"
                      options={selectedSalesforceField.picklistValues}
                      isClearable={true}
                      isSearchable={true}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <Text
                    name="TextDefaultValue"
                    label="Default Value"
                    placeholder="Enter Default Value"
                  />
                </div>
              )}
              <div className="field-wrapper">
                <PartnerType
                  name="partnerType"
                  label="Partner Type"
                  placeholder="Select Partner type"
                  isSearchable={true}
                  isClearable={true}
                />
              </div>

              <div className="field-wrapper mb-5">
                <Select
                  name="syncDirection"
                  label="Sync Direction"
                  placeholder="Select Sync Direction"
                  options={syncDirection()}
                  isClearable={true}
                  isSearchable={true}
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <SaveButton />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

const SalesforceFieldMapping = props => {
  const [openAddModal, setOpenAddModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState(false);
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [selectedSalesforceObjectName, setSalesforceObjectName] = useState("");
  const [selectedRowObject, setSelectedRowObject] = useState("");
  const [selectData, setSelectData] = useState("");
  const [customFieldList, setCustomField] = useState(" ");
  const [pickList, setPicklist] = useState(" ");
  const [objectName, setSelectedObjectName] = useState("");

  const AccountObjectList = () => [
    {
      value: "Account (Partner)",
      label: "Account (Partner)"
    },
    {
      value: "Account (Prospect)",
      label: "Account (Prospect)"
    },
    {
      value: "Referral",
      label: "Referral"
    }
  ];

  const ReferralObjectList = () => [
    {
      value: "Referral",
      label: "Referral"
    }
  ];

  const salesforceObjectNameList = () => [
    {
      value: "Opportunities",
      label: "Opportunities"
    },
    {
      value: "Account (Partner)",
      label: "Account (Partner)"
    },
    {
      value: "Account (Prospect)",
      label: "Account (Prospect)"
    }
  ];

  const ReferralFieldList = () => [
    {
      value: "account_name",
      label: "account_name"
    },
    {
      value: "amount",
      label: "amount"
    },
    {
      value: "owner_salesforce_id",
      label: "owner_salesforce_id"
    },
    {
      value: "contact_title",
      label: "contact_title"
    },
    {
      value: "contact_email",
      label: "contact_email"
    },
    {
      value: "contact_phone",
      label: "contact_phone"
    },
    {
      value: "close_date",
      label: "close_date"
    },
    {
      value: "createdAt",
      label: "createdAt"
    },
    {
      value: "first_name",
      label: "first_name"
    },
    {
      value: "last_name",
      label: "last_name"
    },
    {
      value: "notes",
      label: "notes"
    },
    {
      value: "status",
      label: "status"
    },
    {
      value: "salesforce_account_id",
      label: "salesforce_account_id"
    },
    {
      value: "salesforce_contact_id",
      label: "salesforce_contact_id"
    },
    {
      value: "salesforce_opportunity_id",
      label: "salesforce_opportunity_id"
    },
    {
      value: "stage",
      label: "stage"
    },
    {
      value: "submitted_by",
      label: "submitted_by"
    },
    {
      value: "partner_salesforce_account_id",
      label: "partner_salesforce_account_id"
    },
    {
      value: "partner_salesforce_contact_id",
      label: "partner_salesforce_contact_id"
    },
    {
      value: "partner_manager_user_id",
      label: "partner_manager_user_id"
    },
    {
      value: "last_stage_changed_at",
      label: "last_stage_changed_at"
    }
  ];

  const accountFieldList = () => [
    {
      value: "address1",
      label: "address1"
    },
    {
      value: "address2",
      label: "address2"
    },
    {
      value: "country_name",
      label: "country_name"
    },
    {
      value: "city",
      label: "city"
    },
    {
      value: "company_name",
      label: "company_name"
    },
    {
      value: "phone",
      label: "phone"
    },
    {
      value: "state_name",
      label: "state_name"
    },
    {
      value: "partner_manager",
      label: "partner_manager"
    }
  ];

  const syncDirection = () => [
    {
      value: SALESFORCE_SYNC_DIRECTION_OUTBOUND,
      label: "Outbound"
    },
    {
      value: SALESCORCE_SYNC_DIRECTION_INBOUND,
      label: "Inbound"
    },
    {
      value: SALESCORCE_SYNC_DIRECTION_BOTH,
      label: "Both"
    }
  ];

  const getCustomFieldList = () => {
    apiClient
      .get(`${endpoints().referralQuestionAPI}`)
      .then(response => {
        if (response.data && response.data.data) {
          let customField = [];
          response.data.data.forEach(data => {
            customField.push({
              value: data.name,
              label: data.name
            });
          });
          let customFielddata = ReferralFieldList().concat(customField);
          if (customFielddata) {
            setCustomField(customFielddata);
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  const addNewMapping = () => {
    getCustomFieldList();
    setOpenAddModal(true);
  };

  const headerButton = () => (
    <div className="btn-wrapper mb-n0 mt-0">
      <AddButton
        label="Add New"
        className="pull-right btn btn-secondary"
        onClick={() => {
          addNewMapping();
        }}
      />
    </div>
  );
  function closeAddModal() {
    setOpenAddModal(false);
    setSalesforceObjectName("");
    setSelectedObjectName("");
  }

  const getPicklist = row => {
    if (row.salesforceObject === "Opportunities") {
      if (
        props &&
        props.OpportunityObjectFieldList &&
        props.OpportunityObjectFieldList.length > 0
      ) {
        props.OpportunityObjectFieldList.map(data => {
          if (data.label === row.salesforceFieldName) {
            setPicklist(data);
          }
        });
      }
    } else {
      if (
        props &&
        props.AccountObjectFieldList &&
        props.AccountObjectFieldList.length > 0
      ) {
        props.AccountObjectFieldList.map(data => {
          if (data.label === row.salesforceFieldName) {
            setPicklist(data);
          }
        });
      }
    }
  };

  const FieldEditToggle = row => {
    getCustomFieldList();
    getPicklist(row);
    setSelectedRowObject(row);
    setOpenEditModal(true);
  };
  const closeEditModal = () => {
    setOpenEditModal(false);
    setSalesforceObjectName("");
    setSelectedObjectName("");
  };

  // Handle pricing type
  const handleChange = data => {
    if (
      data &&
      data.values &&
      data.values.salesforceObjectName &&
      data.values.salesforceObjectName.value
    ) {
      let selectedObject = data.values.salesforceObjectName.value;
      setSalesforceObjectName(selectedObject);
    }
  };

  const handleObjectNameChange = data => {
    if (
      data &&
      data.values &&
      data.values.objectName &&
      data.values.objectName.value
    ) {
      let selectedObject = data.values.objectName.value;
      setSelectedObjectName(selectedObject);
    }
  };

  const deleteSalesforceLeadFieldMappings = id => {
    const params = { search: getParamsByName("search") };
    props.actions.deleteSalesforceLeadFieldMapping(id, params);
  };
  const deleteModal = row => {
    setOpenDeleteModal(true);
    setSelectData(row);
  };

  return (
    <div>
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(prevState => !prevState);
        }}
        title="Delete Salesforce Field Mapping"
        id={selectData ? selectData.id : ""}
        label={selectData ? selectData.salesforceFieldName : ""}
        deleteFunction={deleteSalesforceLeadFieldMappings}
      />
      <AddNewMappingModal
        openAddModal={openAddModal}
        closeAddModal={closeAddModal}
        salesforceObjectNameList={salesforceObjectNameList}
        addfunction={props}
        handleChange={handleChange}
        handleObjectNameChange={handleObjectNameChange}
        selectedSalesforceObjectName={selectedSalesforceObjectName}
        selectedObjectName={objectName}
        AccountObjectFieldList={props.AccountObjectFieldList}
        OpportunityObjectFieldList={props.OpportunityObjectFieldList}
        accountFieldList={accountFieldList}
        customFieldList={customFieldList}
        ReferralFieldList={ReferralFieldList}
        AccountObjectList={AccountObjectList}
        ReferralObjectList={ReferralObjectList}
        syncDirection={syncDirection}
      />
      <EditMappingModal
        selectedRowObject={selectedRowObject}
        closeEditModal={closeEditModal}
        salesforceObjectNameList={salesforceObjectNameList}
        handleChange={handleChange}
        openEditModal={openEditModal}
        setOpenEditModal={setOpenEditModal}
        update={props}
        selectedSalesforceObjectName={selectedSalesforceObjectName}
        selectedObjectName={objectName}
        handleObjectNameChange={handleObjectNameChange}
        AccountObjectFieldList={props.AccountObjectFieldList}
        OpportunityObjectFieldList={props.OpportunityObjectFieldList}
        accountFieldList={accountFieldList}
        customFieldList={customFieldList}
        ReferralFieldList={ReferralFieldList}
        ReferralObjectList={ReferralObjectList}
        AccountObjectList={AccountObjectList}
        picklist={pickList}
        syncDirection={syncDirection}
      />
      <ReduxTable
        onScroll
        id="salesforceLeadFieldMapping"
        apiURL={`${endpoints().salesforceFieldMappingAPI}`}
        headerButton={headerButton()}
        onRowClick={row => {
          FieldEditToggle(row);
        }}
        paramsToUrl={true}
        searchPlaceholder="Search"
        showHeader
        history={props.history}
      >
        <ReduxColumn
          minWidth={"102px"}
          type="link"
          isClickable="true"
          field="salesforceObject"
          sortBy="salesforce_object"
        >
          Salesforce Object
        </ReduxColumn>
        <ReduxColumn
          minWidth={"102px"}
          field="salesforceFieldName"
          sortBy="salesforce_field_name"
        >
          Salesforce Field
        </ReduxColumn>
        <ReduxColumn minWidth={"74px"} field="objectName" sortBy="object_name">
          Object
        </ReduxColumn>
        <ReduxColumn minWidth={"60px"} field="fieldName" sortBy="field_name">
          Field
        </ReduxColumn>
        <ReduxColumn
          minWidth={"80px"}
          field="defaultValue"
          sortBy="defaultValue"
        >
          Default Value
        </ReduxColumn>
        <ReduxColumn minWidth={"81px"} field="partnerTypes">
          Partner Type
        </ReduxColumn>
        <ReduxColumn
          minWidth={"62px"}
          field="syncDirectionText"
          sortBy="sync_direction"
        >
          Sync Direction
        </ReduxColumn>
        <ReduxColumn
          minWidth={"75px"}
          disableOnClick
          className="action-column"
          renderField={row => (
            <div className="text-center landing-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    FieldEditToggle(row);
                  }}
                >
                  Edit
                </DropdownItem>
                <DropdownItem
                  className={"text-danger"}
                  onClick={event => {
                    deleteModal(row);
                  }}
                >
                  Delete
                </DropdownItem>
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        addSalesforceLeadFieldMapping,
        updateSalesforceLeadFieldMapping,
        deleteSalesforceLeadFieldMapping
      },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(SalesforceFieldMapping);
