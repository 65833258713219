/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Components
import AddButton from "../../../../components/base/AddButton";
import Text from "../../../../components/base/Text";
import Form from "../../../../components/base/Form";

// Helper
import { toString } from "../../../../lib/helper";

// Assets
import createLandingIcon from "../../../../assets/pages/landing-page/create-landing-page.png";
import SingleCheckbox from "../../../../components/base/SingleCheckbox";

class PartnerType extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: ""
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(e) {
    this.setState({ name: e });
  }

  _toggle = id => {
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { isOpen } = this.state;
    const { createPartnerType } = this.props;

    return (
      <div className="d-flex justify-content-between flex-wrap">
        <AddButton
          label="Add New"
          className="pull-right btn btn-secondary"
          onClick={this._toggle}
        />
        <Modal
          isOpen={isOpen}
          toggle={this._toggle}
          className="custom-modal-popup w-100"
          backdrop="static"
        >
          <ModalHeader toggle={this._toggle}>
            <div className="content-wrapper">
              <div className="icon-wrapper">
                <div className="row justify-content-center mb-2 mt-3">
                  <img src={createLandingIcon} size="30" alt="" />
                </div>
                <p className="text-center font-weight-bold mb-3">
                  Create a new Partner Type
                </p>
                <p
                  className="text-center"
                  style={{ fontSize: "14px", lineHeight: "20px" }}
                ></p>
              </div>
              {/* /.icon-wrapper */}
            </div>
            {/* /.content-wrapper */}
          </ModalHeader>

          <Form
            initialValues={{
              name: ""
            }}
            onSubmit={values => {
              values.name = toString(values.name).trim();
              values.isDefault = values && values.isDefault ? 1 : 0;
              values.sort = values.sort;
              createPartnerType(values);
            }}
          >
            <ModalBody>
              <div className="mt-2 mb-5">
                <div className="mb-2">
                  <Text
                    name="name"
                    label="Name"
                    placeholder="Enter Partner Types Name..."
                    onChange={this.handleChange}
                    error=""
                    required
                  />
                </div>
                <div className="field-wrapper">
                  <SingleCheckbox
                    name="isDefault"
                    label="Make as default"
                    className="accepted-terms"
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="container-fluid">
                <div className="col-sm-12 text-center">
                  <Button type="submit" label="">
                    Create Partner Type
                  </Button>
                </div>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

export default PartnerType;
