import React from "react";
import PropTypes from "prop-types";
import MobileHamburgerMenu from "../base/MobileHamburgerMenu";
import AddButton from "./AddButton";

class PageTitle extends React.Component {
  render() {
    const {
      label,
      showHamburgermenu,
      id,
      buttonHandler,
      buttonLabel,
      adminSettings,
      targetUrl,
      fontLight
    } = this.props;

    return (
      <div className="row">
        <div className={`${adminSettings ? `d-flex` : "d-flex pl-3"}`}>
          <h5
            id={id || label}
            className={`page-title mt-2 mb-4 ${
              fontLight ? "" : "font-weight-bold"
            }`}
          >
            {label}
          </h5>
          {showHamburgermenu == true && <MobileHamburgerMenu />}
        </div>

        <div className="col">
          {(buttonHandler || targetUrl) && (
            <AddButton
              label={buttonLabel}
              className="pull-right btn btn-secondary h6-5-important font-weight-bold"
              onClick={buttonHandler}
              targetUrl={targetUrl}
            />
          )}
        </div>
      </div>
    );
  }
}

PageTitle.propTypes = {
  label: PropTypes.string
};

export default PageTitle;
