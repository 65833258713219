import React, { useState, useEffect } from "react";

// Helper
import {
  getUrlParameter,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager,
  isPartner,
  removeMaskedPhoneNumber,
  getKeyValueByObject
} from "../../lib/helper";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Components
import { ChevronRight } from "../../assets/img/icons";
import Form from "../../components/base/Form";
import ReferralFormFields from "./ReferralFormFields";
import CancelButton from "../../components/base/CancelButton";
import Button from "../../components/base/Button";
import AdminActionNavigation from "../../components/base/AdminActionNavigation";
import toast from "../../components/base/Toast";
import ReferralModal from "../../components/referral/ReferralModal";
import Spinner from "../../components/base/Spinner";
import SelectDropdown from "../../components/base/SelectDropdown";

// Constant
import {
  REFERRAL_STATUS_DRAFT,
  CUSTOMER_READINESS_READY_TEXT,
  CUSTOMER_READINESS_NOT_READY_TEXT,
  CUSTOMER_READINESS_NOT_READY,
  CUSTOMER_READINESS_PURCHASED_READY_TEXT,
  CUSTOMER_READINESS_READY,
  CUSTOMER_READINESS_PURCHASED_READY,
  REQUIRED_ON_PARTNER_SUBMIT,
  Referral
} from "../../referral/Constants";

import {
  SETTING_REFERRAL_CUSTOMER_READINESS_OPTION,
  SETTING_REFERRAL_SHOW_CUSTOMER_READINESS,
  SETTINGS_ENABLE_REFERRAL_ADDITIONAL_CONTACTS,
  SETTINGS_SHOW_REFERRAL_AMOUNT
} from "../../setting/Constants";

import { TASK_TYPE_DROP_DOWN_FIELD } from "../../modules/task/Constant";

//Common
import { isFalse, isTrue, isNotEmpty } from "../../common/utils";
import { isBadRequest } from "../../common/http";

//Sidebar
import SideBar from "../../referral/Components/SideBar";

//Loader
import LoadingOverlay from "react-loading-overlay";
import { BeatLoader } from "react-spinners";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
import {
  COMPANY_ADMIN_ROLE,
  COMPANY_MANAGER_ROLE
} from "../../roles/Constants";

import Users from "../../helpers/User";
import {
  TAG_TYPE_CURRENCY,
  TAG_TYPE_PHONE_COUNTRY_CODE
} from "../../tagType/Constants";
import { getSetting } from "../../actions/settings";
import { useSelector } from "react-redux";
import Tag from "../../Constants/Tag";

const SubmitReferral = props => {
  const [currentPartner, setCurrentPartner] = useState("");
  const [partnerIds, setPartnerIds] = useState("");
  const [partnerStatus, setPartnerStatus] = useState("");
  const [closeDateValue, setCloseDateValue] = useState();
  const [submit, setSubmit] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [isAccountLoading, setIsAccountLoading] = useState(false);
  const [
    partnerTierCommissionPercentage,
    setPartnerTierCommissionPercentage
  ] = useState([]);
  const [customerReadinessModalOpen, setCustomerReadinessModal] = useState(
    false
  );
  const [customerReadinessTarget, setCustomerReadinessTarget] = useState(false);
  const [questionList, setQuestionList] = useState([]);
  const [isQuestionLoading, setIsQuestionLoading] = useState(true);
  const [reassignfun, setReassignfun] = useState(false);
  const [reAssignValue, setReAssignValue] = useState("");
  const [reassignButton, setReassignButton] = useState(false);
  const [overlayLoader, setOverlayLoader] = useState(false);
  const [dateField, setDatefield] = useState("");
  const [partnerAccountData, setPartnerAccountData] = useState("");
  const [contactArray, setContactArray] = useState([]);
  const [userList, setUserList] = useState([]);
  const [currencyList, setCurrencyList] = useState([]);
  const [currrency, setCurrency] = useState();
  const [isCurrencyLoading, setIsCurrencyLoading] = useState(false);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [selectedPartner, setSelectedPartner] = useState("");

  let currency = currrency
    ? currrency
    : currencyList.find(data => data.isDefault == Tag.IsDefault);

  let phoneCountryCode = countryCodeList.find(
    data => data.isDefault == Tag.IsDefault
  );

  let currentUser = useSelector(state => state.user);

  const [showAmoutField, setShowAmount] = useState("");

  const { location } = props.history;

  const settings = props.settings && props.settings ? props.settings : "";

  const enableCustomerReadiness = settings
    ? getKeyValueByObject(settings, SETTING_REFERRAL_SHOW_CUSTOMER_READINESS)
    : "";
  const customerReadinessOptions = settings
    ? getKeyValueByObject(settings, SETTING_REFERRAL_CUSTOMER_READINESS_OPTION)
    : "";

  let partnerSubmit =
    (isSuperAdmin(currentUser && currentUser.roleId) ||
      isCompanyAdmin(currentUser && currentUser.roleId) ||
      isCompanyManager(currentUser && currentUser.roleId) ||
      isPartner(currentUser && currentUser.roleId)) &&
    showAmoutField == Referral.AMOUNT_REQUIRED_ON_PARTNER_SUBMIT;

  let partnerOptionalSubmit =
    (isSuperAdmin(currentUser && currentUser.roleId) ||
      isCompanyAdmin(currentUser && currentUser.roleId) ||
      isCompanyManager(currentUser && currentUser.roleId) ||
      isPartner(currentUser && currentUser.roleId)) &&
    showAmoutField == Referral.AMOUNT_OPTIONAL_ON_PARTNER_SUBMIT;
  // get Additional Contact setting
  const enableAdditionalContacts = settings
    ? getKeyValueByObject(
        settings,
        SETTINGS_ENABLE_REFERRAL_ADDITIONAL_CONTACTS
      ) &&
      getKeyValueByObject(
        settings,
        SETTINGS_ENABLE_REFERRAL_ADDITIONAL_CONTACTS
      ) === "true" &&
      true
    : false;

  const adminActionOptions = [
    {
      value: "Reassign Partner",
      label: "Reassign Partner"
    }
  ];

  // Get Users
  const getUsersList = async () => {
    let userData = [];
    let roleId = `${COMPANY_MANAGER_ROLE},${COMPANY_ADMIN_ROLE}`;
    await Users.search(roleId, list => {
      for (let i = 0; i < list.length; i++) {
        userData.push({
          label: list[i].name,
          value: list[i].id
        });
      }
    });
    setUserList(userData);
  };

  const getSettings = () => {
    getSetting(response => {
      if (response && response.length > 0) {
        let showReferralAmount = getKeyValueByObject(
          response,
          SETTINGS_SHOW_REFERRAL_AMOUNT
        );

        setShowAmount(showReferralAmount);
      }
    });
  };
  const getTagValues = async tagType => {
    try {
      setIsCurrencyLoading(true);

      const response = await apiClient.get(`${endpoints().tagAPI}`);
      const results = response.data.data;

      // Check the tag type skills
      if (results && results.length > 0) {
        const currencyList = [];
        const countryCodeList = [];

        results.forEach(tag => {
          if (tag.type == TAG_TYPE_CURRENCY) {
            currencyList.push({
              value: tag.id,
              label: tag.name,
              isDefault: tag.defaultValue
            });
          }
          if (tag.type == TAG_TYPE_PHONE_COUNTRY_CODE) {
            countryCodeList.push({
              value: tag.id,
              label: tag.name,
              isDefault: tag.defaultValue
            });
          }
        });
        setCountryCodeList(countryCodeList);
        setCurrencyList(currencyList);
      }
      setIsCurrencyLoading(false);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  const _getQuestionList = () => {
    apiClient
      .get(`${endpoints().referralQuestionAPI}`)
      .then(response => {
        if (response && response.data) {
          if (response.data.data && response.data.data.length > 0) {
            response.data.data.forEach(questionDetails => {
              if (
                isSuperAdmin(currentUser && currentUser.roleId) ||
                isCompanyAdmin(currentUser && currentUser.roleId) ||
                isCompanyManager(currentUser && currentUser.roleId) ||
                (isPartner(currentUser && currentUser.roleId) &&
                  questionDetails.showToPartner)
              ) {
                questionList.push(questionDetails);
              }
            });
          }
          setLoading(false);
          setIsQuestionLoading(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          setLoading(false);
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
        }
      });
  };

  // Handle form submit
  const handleSubmit = values => {
    setOverlayLoader(true);
    setSubmit(true);
    values.closeDate = closeDateValue;

    return apiClient
      .post(endpoints().referralApi, values)
      .then(response => {
        let successMessage;
        setOverlayLoader(false);
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          setSubmit(false);
          props.history.push(`/referral/edit/${response.data.id}`);
        }
      })
      .catch(error => {
        setOverlayLoader(false);
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
          setSubmit(false);
        }
      });
  };

  //Get Partner Tier Commission Percentage
  const _getPartnerTierCommissionPercentage = () => {
    return apiClient
      .get(`${endpoints().partnerTierAPI}/search`)
      .then(response => {
        if (response && response.data && response.data.data) {
          setPartnerTierCommissionPercentage(response.data.data);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };
  // Get Current Partner
  const _getCurrentPartner = () => {
    const id = location && location.state && location.state.partnerId;
    apiClient.get(`${endpoints().partnerAPI}?id=${id}`).then(response => {
      const currentPartnerUser = response.data;
      setCurrentPartner(currentPartnerUser);
    });
  };

  // Get Current Partner
  const onCurrencyChange = e => {
    if (e.values) {
      setCurrency(e.values.currency);
    }
  };

  // Set Referral Partners Ids
  const setReferralPartners = (partnerIds, status) => {
    setPartnerIds(partnerIds);
    setPartnerStatus(status);
  };

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    _getQuestionList();
    if (isPartner(currentUser && currentUser.roleId)) {
      _getCurrentPartner();
      _getPartnerTierCommissionPercentage();
    }
    if (!isPartner(currentUser && currentUser.roleId)) {
      getSelectedPartner();
    }
    partnerAccountDetail();
    getUsersList();
    getTagValues(TAG_TYPE_CURRENCY);
    getSettings();
  }, []);

  const handleAdminAction = value => {
    if (value === "Reassign Partner") {
      setReAssignValue(value);
      setReassignfun(true);
      setReassignButton(false);
    }
  };

  const setDatefieldDate = date => {
    setDatefield(date);
  };

  const setCustomerReadiness = () => {
    setCustomerReadinessTarget(!customerReadinessTarget);
  };
  let questionInitials = {};
  if (questionList && questionList.length > 0) {
    questionList.forEach(list => {
      questionInitials[`${list.id}`] = "";
    });
  }

  const getSelectedPartner = () => {
    let partnerId = location && location.state && location.state.partnerId;
    if (partnerId) {
      apiClient
        .get(`${endpoints().partnerAPI}?id=${partnerId}`)
        .then(response => {
          const currentPartnerUser = response.data;
          setSelectedPartner(currentPartnerUser);
        });
    }
  };

  //Get partner accoutn details
  const partnerAccountDetail = async () => {
    const id = location && location.query && location.query.account_id;
    if (id) {
      setIsAccountLoading(true);
      apiClient
        .get(`${endpoints().accountAPI}/${id}`)
        .then(response => {
          if (response && response.data) {
            setPartnerAccountData(response.data);
          }
          setIsAccountLoading(false);
        })
        .catch(error => {
          setIsAccountLoading(false);
          if (isBadRequest(error)) {
            toast.error(error.response.data.message);
          }
        });
    }
  };

  // Contact data
  const saveContactData = (value, toggle) => {
    let data = [];
    data.push(value ? value : "");
    let contactList = contactArray && contactArray.concat(data);
    setContactArray(contactList);
    toggle();
  };

  // update contact
  const updateContactData = (value, id, toggle) => {
    let contactData = [];
    // Remove old updated contact and get unchanged contact
    contactArray.forEach(item => {
      item.forEach(element => {
        value &&
          value.forEach(result => {
            if (element.contactArrayIndex !== result.contactArrayIndex) {
              contactData.push({
                id: element.id ? element.id : "",
                contactArrayIndex: element.contactArrayIndex
                  ? element.contactArrayIndex
                  : "",
                referralId: element.referralId ? element.referralId : "",
                contactFirstname: element.contactFirstname
                  ? element.contactFirstname
                  : "",
                contactLastName: element.contactLastName
                  ? element.contactLastName
                  : "",
                title: element.title ? element.title : "",
                contactEmail: element.contactEmail ? element.contactEmail : "",
                contactPhone: element.contactPhone ? element.contactPhone : ""
              });
            }
          });
      });
    });
    let data = [];
    let contactOldData = [];
    // update old array
    contactOldData.push(contactData ? contactData : "");
    // update current changed array
    data.push(value ? value : "");
    // concat the old and update array
    let contactUpdateArray = contactOldData && contactOldData.concat(data);
    setContactArray(contactUpdateArray);
    toggle();
  };

  // Delete contact
  const deleteContactData = (firstName, lastName) => {
    let contactData = [];
    contactArray.forEach(item => {
      item.forEach(elemet => {
        if (
          elemet.contactFirstname !== firstName ||
          elemet.contactLastName !== lastName
        ) {
          contactData.push(item);
        }
      });
    });
    setContactArray(contactData);
  };

  if (questionList) {
    questionList.forEach(data => {
      let Id = data.id;
      if (data.default_value) {
        initialValues[Id] = data.default_value;
      }
    });
  }
  let initialValues = {
    ...questionInitials,
    accountName:
      (partnerAccountData && partnerAccountData.companyName) ||
      (location && location.state && location.state.accountName) ||
      "",
    firstName: (partnerAccountData && partnerAccountData.firstName) || "",
    lastName: (partnerAccountData && partnerAccountData.lastName) || "",
    contactTitle: "",
    contactEmail: (partnerAccountData && partnerAccountData.email) || "",
    contactPhone: (partnerAccountData && partnerAccountData.phone) || "",
    countryCode: phoneCountryCode,
    notes: "",
    customerReadiness: "",
    amount: "",
    currency: currency && currency,
    accountUrl: ""
  };
  const permisionList =
    customerReadinessOptions && JSON.parse(customerReadinessOptions);

  if (isLoading || isAccountLoading || isCurrencyLoading) {
    return <Spinner />;
  }
  return (
    <>
      <Form
        initialValues={initialValues}
        onSubmit={values => {
          if (
            isTrue(enableCustomerReadiness) &&
            isNotEmpty(permisionList) &&
            !values.customerReadiness
          ) {
            return setCustomerReadinessModal(true);
          }
          values.contactPhone = values.contactPhone
            ? removeMaskedPhoneNumber(values.contactPhone)
            : "";
          values.partnerIds = partnerIds ? partnerIds : "";
          if (values.partner_manager) {
            values.partner_manager = values.partner_manager.value
              ? values.partner_manager.value
              : "";
          }
          if (values.currency) {
            values.currency = values.currency ? values.currency.value : null;
          }
          if (values.amount >= 0) {
            values.amount = values.amount ? values.amount : null;
          }
          values.partnerStatus = partnerStatus ? partnerStatus : "";
          values.customer_readiness =
            values.customerReadiness &&
            values.customerReadiness === CUSTOMER_READINESS_READY_TEXT
              ? CUSTOMER_READINESS_READY
              : values.customerReadiness === CUSTOMER_READINESS_NOT_READY_TEXT
              ? CUSTOMER_READINESS_NOT_READY
              : values.customerReadiness ===
                CUSTOMER_READINESS_PURCHASED_READY_TEXT
              ? CUSTOMER_READINESS_PURCHASED_READY
              : null;
          values &&
            values.customFields &&
            values.customFields.length > 0 &&
            values.customFields.forEach(tagValue => {
              if (tagValue.value && tagValue.value.length > 0) {
                values.customTagValues = values.customFields;
              }
            });
          const customFields = [];
          if (values && Object.keys(values).length > 0) {
            Object.keys(values).forEach(value => {
              if (!isNaN(value)) {
                customFields.push({
                  questionId: value,
                  value: values[value]
                });
              }
            });
            values.customFields = customFields;
          }
          values.contactArray = contactArray ? contactArray : "";

          if (location && location.state && location.state.requestReferralId) {
            values.requestReferralId = location.state.requestReferralId;
          }

          if (isFalse(enableCustomerReadiness)) {
            handleSubmit(values);
          } else values.customer_readiness !== "" && handleSubmit(values);
        }}
      >
        <AdminActionNavigation>
          <div className="d-flex align-items-center mb-3">
            <a
              href="javascript:void(0)"
              className="cursor-pointer"
              onClick={() => {
                props.history.push("/referrals");
              }}
            >
              {`${Terminology.get(SystemName.REFERRAL)}`}
            </a>
            <span
              className="text-dark d-inline-flex align-items-center"
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <span
              onClick={e => e.preventDefault()}
              className={[
                "text-dark",
                "font-weight-bold",
                "d-inline-flex",
                "align-items-center",
                "project-name",
                "w-320"
              ].join(" ")}
            >
              <span className="ellipsis">{`Submit a ${Terminology.get(
                SystemName.REFERRAL
              )}`}</span>
            </span>
          </div>
          {partnerIds ? (
            <div className="d-flex justify-content-between pull-right mb-2">
              {(isSuperAdmin(currentUser && currentUser.roleId) ||
                isCompanyAdmin(currentUser && currentUser.roleId)) && (
                <SelectDropdown
                  buttonLabel="Admin Actions"
                  dropdownLinks={adminActionOptions}
                  color={"gray"}
                  hideCaret
                  selectName={"admin_action"}
                  handleChange={handleAdminAction}
                />
              )}
            </div>
          ) : (
            ""
          )}
        </AdminActionNavigation>

        <div className="card border-0">
          <div className="project-details-body">
            <ReferralFormFields
              settings={props.settings}
              disableCloseDate
              customerReadinessTarget={customerReadinessTarget}
              setCustomerReadiness={setCustomerReadiness}
              questionList={questionList}
              overlayLoader={overlayLoader}
              isSubmitPage={true}
              setDatefieldDate={setDatefieldDate}
              dateField={dateField}
              userList={userList}
              currencyList={currencyList}
              countryCodeList={countryCodeList}
              partnerSubmit={partnerSubmit}
              partnerOptionalSubmit={partnerOptionalSubmit}
              onCurrencyChange={onCurrencyChange}
            />
            <ReferralModal
              setCustomerReadinessModal={setCustomerReadinessModal}
              setCustomerReadiness={setCustomerReadiness}
              customerReadinessModalOpen={customerReadinessModalOpen}
            />
            <SideBar
              selectedPartner={selectedPartner}
              referralStatus={REFERRAL_STATUS_DRAFT}
              setReferralPartners={setReferralPartners}
              currentPartner={currentPartner}
              partnerTierCommissionPercentage={partnerTierCommissionPercentage}
              reassignfun={reassignfun}
              reAssignValue={reAssignValue}
              saveContactData={(e, toggle) => {
                saveContactData(e, toggle);
              }}
              updateContactData={(e, id, toggle) => {
                updateContactData(e, id, toggle);
              }}
              contactArray={contactArray}
              enableAdditionalContacts={enableAdditionalContacts}
              deleteContactData={(e, firstName, lastName) => {
                deleteContactData(firstName, lastName);
              }}
              contactArrayIndex={contactArray.length}
            />
          </div>
        </div>
        <div className="pt-3 pb-4">
          <CancelButton
            className="ml-auto mr-1"
            onClick={() => (window.location.href = "/referrals")}
          />
          <Button
            id="submitButton"
            label={`Submit ${Terminology.get(SystemName.REFERRAL)}`}
            type="submit"
            className="btn btn-primary"
            disabled={submit}
          />
        </div>
      </Form>
    </>
  );
};

export default SubmitReferral;
