// Task Status
export const PAYMENT_OPTION_ACH = "ACH";
export const PAYMENT_OPTION_WIRE_TRANSFER = "Wire Transfer";
export const PAYMENT_OPTION_INTERNATIONAL = "International";

// Payment Options

export const paymentOptions = [
  {
    value: PAYMENT_OPTION_ACH,
    label: PAYMENT_OPTION_ACH
  },
  {
    value: PAYMENT_OPTION_WIRE_TRANSFER,
    label: PAYMENT_OPTION_WIRE_TRANSFER
  },
  {
    value: PAYMENT_OPTION_INTERNATIONAL,
    label: PAYMENT_OPTION_INTERNATIONAL
  }
];
