import React, { useState } from "react";
import { ChevronDown, ChevronUp } from "../../assets/img/icons";

const ShowMoreContent = props => {
  const [showMore, setShowMore] = useState(false);
  const { content, maxContentLength, id } = props;

  const showMoreHandler = () => {
    setShowMore(!showMore);
  };

  let contentLength = maxContentLength || 200;
  const isContentLengthExceed = content && content.length > contentLength;
  return (
    <p>
      <p>
        {isContentLengthExceed && !showMore
          ? `${content.slice(0, contentLength)}...`
          : content}
      </p>
      {isContentLengthExceed ? (
        <a
          id={id}
          href="#"
          className={["d-flex", "show-more"].join(" ")}
          onClick={e => {
            e.preventDefault();
            showMoreHandler();
          }}
        >
          Show{" "}
          {showMore ? (
            <>
              Less
              <ChevronUp />
            </>
          ) : (
            <>
              More
              <ChevronDown />
            </>
          )}
        </a>
      ) : (
        ""
      )}
    </p>
  );
};
export default ShowMoreContent;
