import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";

// Components
import DeleteModal from "../../components/base/DeleteModal";
import Search from "../../components/base/Search";

// Action
import { updateExpert } from "../../actions/expert";
import { fetchAppList } from "../../actions/playbook";

//API
import { endpoints } from "../../configs";
import { apiClient } from "../../apiClient";
import { isBadRequest } from "../../common/http";

//Inner Component
import AddModal from "./AddModal";

import {
  getKeyValueByObject,
  getParamsByName,
  getUrlPath,
  isPartner
} from "../../lib/helper";
import { fetchList } from "../../actions/table";
import AppActivityStats from "./AppActivityStats";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import { SETTINGS_THEME_LAYOUT } from "../../setting/Constants";
import MarketplaceAppCard from "../../components/MarketplaceAppCard";
import Pagination from "../../components/base/Pagination";
import NoRecordsFound from "../../components/base/NoRecordsFound";
import MarketplaceIcon from "../../assets/img/icons/icon-marketplace.svg";

// constants
const TAB_APPS = "Apps";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class AppList extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortByOptions: [
        {
          value: "app_name:ASC",
          label: "Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ],
      searchParam: "",
      status: "",
      app_name: "",
      deleteAppModal: false,
      selectedSortValue: "app_name:ASC",
      app: "",
      appId: "",
      appList: "",
      page: getParamsByName("page") ? parseInt(getParamsByName("page")) : 1,
      sort: getParamsByName("sort") ? getParamsByName("sort") : "app_name",
      sortDir: getParamsByName("sortDir") ? getParamsByName("sortDir") : "ASC",
      pageSize: getParamsByName("pageSize")
        ? parseInt(getParamsByName("pageSize"))
        : 12,
      isInvalidRequest: false,
      filterOptions: [
        {
          label: "Approved"
        },
        {
          label: "Draft"
        },
        {
          label: "In Review"
        },
        {
          label: "Suspended"
        },
        {
          label: "Rejected"
        }
      ]
    };
  }

  // App delete api call
  handleDelete = async (appId, params) => {
    const { currentPage } = this.props;
    return apiClient
      .delete(`${endpoints().appAPI}/${appId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;

          toast.success(successMessage);
        }
        this.props.actions.fetchList(
          "app",
          "/v1/app/search",
          getParamsByName("page"),
          getParamsByName("pageSize"),
          params
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  _handleInputChange = e => {
    this.setState(
      {
        searchParam: e.target.value,
        page: 1
      },
      () => {
        const { searchParam, status } = this.state;
        const params = {
          status,
          search: encodeURIComponent(searchParam),
          page: getParamsByName("page"),
          pageSize: getParamsByName("pageSize"),
          sort: getParamsByName("sort"),
          sortDir: getParamsByName("sortDir")
        };
        this.pushQueryToUrl();
        this.props.getAppList(params);
      }
    );
  };

  // Update The App By Status
  updateExpertByStatus(status, expertId) {
    this.props.actions.updateExpert(
      expertId,
      { marketplaceStatus: status },
      {
        marketplaceStatus: "Pending",
        sort: this.props.pendingExpertsSort,
        sortDir: this.props.pendingExpertsSortDir
      }
    );
  }

  pushQueryToUrl() {
    const { searchParam, pageSize, page, sort, sortDir } = this.state;

    const params = [];

    if (searchParam) {
      params.push(`search=${searchParam}`);
    }

    if (pageSize) {
      params.push(`pageSize=${pageSize}`);
    }

    if (page) {
      params.push(`page=${page}`);
    }
    if (sort) {
      params.push(`sort=${sort}`);
    }
    if (sortDir) {
      params.push(`sortDir=${sortDir}`);
    }

    this.props.history.push(
      `/marketplace-sourcing?section=Apps&${params.join("&")}`
    );
    let param = {
      sort: getParamsByName("sort"),
      sortDir: getParamsByName("sortDir"),
      page: getParamsByName("page") ? parseInt(getParamsByName("page")) : 1,
      pageSize: getParamsByName("pageSize") ? getParamsByName("pageSize") : 12,
      search: getParamsByName("search")
    };
    this.props.getAppList(param);
  }

  handlePageSizeChange = e => {
    const pageSize = e;
    this.setState(
      {
        pageSize: pageSize,
        page: getParamsByName("page") ? parseInt(getParamsByName("page")) : 1
      },
      () => {
        const { getAppList } = this.props;

        let params = {
          sort: getParamsByName("sort"),
          sortDir: getParamsByName("sortDir"),
          page: getParamsByName("page") ? parseInt(getParamsByName("page")) : 1,
          pageSize: pageSize,
          search: getParamsByName("search")
        };

        this.pushQueryToUrl();
      }
    );
  };

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  handleSortByChange = value => {
    const sortValue = this.getSortValueFromLabel(value);

    const [sortBy, sortDir] = sortValue.split(":");

    this.setState(
      {
        sort: sortBy,
        sortDir: sortDir,
        page: 1
      },
      () => {
        const params = {
          sort: sortBy,
          sortDir: sortDir,
          search: getParamsByName("search"),
          page: getParamsByName("page") ? parseInt(getParamsByName("page")) : 1,
          pageSize: getParamsByName("pageSize")
        };
        this.pushQueryToUrl();
      }
    );
  };

  onPageChange(page) {
    this.setState(
      {
        page: page
      },
      () => {
        if (page) {
          const { pageSize } = this.state;
          const params = {
            page: page,
            pageSize: getParamsByName("pageSize"),
            search: getParamsByName("search"),
            sort: getParamsByName("sort"),
            sortDir: getParamsByName("sortDir")
          };
          this.pushQueryToUrl();
        }
      }
    );
  }

  render() {
    const { deleteAppModal, app, appId, pageSize } = this.state;

    let page = getParamsByName("page")
      ? getParamsByName("page")
      : this.state.page;

    const {
      enableApp,
      activeTab,
      appActivityStats,
      settings,
      appList
    } = this.props;
    const currentUrl = window.location.pathname + window.location.search;
    const search = window.location.search;
    const pathParams = new URLSearchParams(search);
    const searchItem = pathParams.get("search");
    let params = {
      searchItem: searchItem,
      sort: getParamsByName("sort") || "createdAt",
      sortDir: getParamsByName("sortDir") || "DESC",
      search: getParamsByName("search"),
      page: getParamsByName("page"),
      pageSize: getParamsByName("pageSize")
    };

    const DefaultLayout = settings
      ? getKeyValueByObject(settings, SETTINGS_THEME_LAYOUT)
      : "";

    const totalCounts = this.props && this.props.appCount;
    let startPage = (page - 1) * pageSize + 1;
    let endPage = parseInt(startPage) + parseInt(pageSize) - 1;
    endPage = Math.min(endPage, totalCounts);

    const appDetails = appList && appList.length > 0 ? appList : [];

    let selectedSortOption;

    if (getParamsByName("sort") && getParamsByName("sortDir")) {
      selectedSortOption = this.state.sortByOptions.find(option => {
        const [value, direction] = option.value.split(":");
        return (
          value === getParamsByName("sort") &&
          direction === getParamsByName("sortDir")
        );
      });
    } else {
      selectedSortOption = this.state.sortByOptions[0];
    }

    return (
      <>
        <div>
          <DeleteModal
            id="app"
            isOpen={deleteAppModal}
            toggle={() => {
              this.setState({ deleteAppModal: false });
            }}
            title="Delete App"
            label={app}
            deleteFunction={() => this.handleDelete(appId, params)}
          />
          <AddModal />
        </div>
        <div className="mt-3">
          {/* app activity stats */}
          {enableApp === "true" && activeTab === TAB_APPS && (
            <div>
              {(isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId) ||
                isPartner(this.props.roleId)) && (
                <div>
                  <AppActivityStats appActivityStats={appActivityStats} />
                </div>
              )}
            </div>
          )}
        </div>
        <div>
          <Search
            sortByOptions={this.state.sortByOptions}
            selectedSortOption={selectedSortOption && selectedSortOption.label}
            handlePageSizeChange={e => this.handlePageSizeChange(e)}
            handleInputChange={e => this._handleInputChange(e)}
            handleSortByChange={this.handleSortByChange}
            searchParam={getParamsByName("search")}
          />
        </div>
        {appDetails && appDetails.length > 0 ? (
          <div className="row">
            {appDetails &&
              appDetails.length > 0 &&
              appDetails.map(app => (
                <MarketplaceAppCard
                  data={app}
                  size={DefaultLayout !== "Fluid Width" ? "m-2 p-1" : "m-4 p-1"}
                  key={app.id}
                  url={`marketplace-sourcing/app/edit?id=${app.id}`}
                  link={`marketplace-sourcing/app/edit?id=${app.id}`}
                  history={this.props.history}
                  salesforceLandingGridArrow={true}
                />
              ))}
          </div>
        ) : (
          <>
            {" "}
            <NoRecordsFound
              icon={MarketplaceIcon}
              showMessage={true}
              hideCard={true}
              message="No Records Found"
              position="center"
            />
          </>
        )}
        <div>
          {totalCounts > 0 && (
            <Row>
              <Col>
                Showing {startPage} to {endPage} of {totalCounts} entries
              </Col>
              <Col>
                <Pagination
                  currentPage={
                    getParamsByName("page")
                      ? parseInt(getParamsByName("page"))
                      : page
                  }
                  totalCount={totalCounts}
                  pageSize={
                    getParamsByName("pageSize")
                      ? parseInt(getParamsByName("pageSize"))
                      : pageSize
                  }
                  onPageChange={this.onPageChange.bind(this)}
                />
              </Col>
            </Row>
          )}
        </div>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { fetchAppList, updateExpert, fetchList },
      dispatch
    )
  };
}

const mapStateToProps = state => {
  const { appsCount, app } = state.table;
  const { roleId } = state.user;

  const appsCountSort =
    appsCount && !appsCount.isFetching ? appsCount.sort : "createdAt";

  const appsCountSortDir =
    appsCount && !appsCount.isFetching ? appsCount.sortDir : "DESC";

  const currentPage = app && !app.isFetching ? app.currentPage : 1;

  return { appsCountSort, appsCountSortDir, currentPage, roleId };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(AppList);
