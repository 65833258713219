import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Form from "../../../components/base/Form";
import TextArea from "../../../components/base/TextArea";
import CancelButton from "../../../components/base/CancelButton";

const DeleteExpertModal = ({
  modal,
  setModal,
  expertId,
  removeApprovedExpert,
  approvedProjectExpertList
}) => {
  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      backdrop="static"
      className={["edit-task-modal"].join(" ")}
    >
      <Form
        initialValues={{
          deleteNote: "",
          expertId:
            approvedProjectExpertList.length > 0 &&
            approvedProjectExpertList[0].id
        }}
        onSubmit={values => {
          removeApprovedExpert(values);
          setModal(prevState => !prevState);
        }}
      >
        <ModalHeader toggle={setModal}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Delete Approved Expert
          </h4>
        </ModalHeader>
        <ModalBody className={["text-center", "mb-4"].join(" ")}>
          <p>Are you sure you want to delete "Expert" ?</p>
          <div className="form-wrapper text-left d-flex">
            <div className="field-wrapper w-100">
              <TextArea
                name="deleteNote"
                label="Your message"
                row="5"
                placeholder="Your message..."
                error="Your message is required"
                required
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={() => setModal(prevState => !prevState)} />
            <button className="btn btn-danger" type="submit">
              Yes, Delete
            </button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default DeleteExpertModal;
