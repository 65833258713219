import React, { useState } from "react";
import moment from "moment-timezone";

// Kickoff TimePicker With Checkbox
export const KickOffTimePickerCheckbox = ({
  handleChange,
  getSelectedValue,
  index,
  date,
  hour,
  visualTimezone,
  customerWaitingForExpert
}) => {
  return (
    <>
      <input
        type="checkbox"
        name={`hour-${moment(date).date()}-${index}`}
        id={`hour-${moment(date).date()}-${index}`}
        onChange={handleChange}
        value={getSelectedValue(date, hour.raw, visualTimezone)}
        className={"kickoff-scheduler__checkbox"}
        disabled={customerWaitingForExpert}
      />
      {!customerWaitingForExpert && <span className={`checkbox-placeholder`} />}
    </>
  );
};

// Kickoff TimePicker With Checkbox
export const KickOffTimePickerAdminCheckbox = ({
  handleChange,
  getSelectedValue,
  index,
  date,
  hour,
  visualTimezone,
  customerWaitingForExpert,
  dateId,
  selectedHours
}) => {
  const selectedHoursRaw = selectedHours ? selectedHours.raw : "";
  const hourRaw = hour ? hour.raw : "";
  const isCHecked =
    selectedHoursRaw &&
    hourRaw &&
    `${selectedHours && selectedHours.raw}` === `${hour && hour.raw}`;

  const [isCheckedBox, setIsCheckedBox] = useState(isCHecked);

  const handleCheckboxChange = value => {
    if (isCheckedBox) {
      setIsCheckedBox(prevState => !prevState);
    } else {
      setIsCheckedBox(prevState => !prevState);
    }
    handleChange(value);
  };

  return (
    <>
      <input
        type="checkbox"
        name={`hour-${moment(date).date()}-${index}`}
        id={`hour-${moment(date).date()}-${index}`}
        onChange={handleCheckboxChange}
        value={getSelectedValue(date, hour.raw, visualTimezone)}
        className={"kickoff-scheduler__checkbox"}
        checked={isCheckedBox}
        disabled={customerWaitingForExpert}
      />
      {!customerWaitingForExpert && <span className={`checkbox-placeholder`} />}
    </>
  );
};

// Kickoff TimePicker With Radio
export const KickOffTimePickerRadio = ({
  handleChange,
  getSelectedValue,
  index,
  date,
  hour,
  defaultTimezone,
  dateId
}) => {
  return (
    <>
      <input
        type="radio"
        name={`expert-selected-hour`}
        id={`hour-${moment(date).date()}-${index}`}
        onChange={handleChange}
        value={getSelectedValue(date, hour.raw, defaultTimezone, dateId)}
        data-id={dateId}
        className={
          "kickoff-scheduler__checkbox kickoff-scheduler__checkbox--radio"
        }
      />
      <span className={`radio-placeholder checkbox-placeholder`} />
    </>
  );
};
