import React from "react";
import { connect } from "react-redux";
import { CSVLink } from "react-csv";
import moment from "moment";

// API Client
import { apiClient } from "../../apiClient";

//Assets
import { DownloadIcon } from "../../assets/img/icons";

// Components
import PageTitle from "../../components/base/PageTitle";
import Page404 from "../Page404";
import AddButton from "../../components/base/AddButton";
import PaymentPreference from "./inner-components/PaymentPreference";
// Helper
import {
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  isPartner,
  isCompanyManager,
  getUserId,
  getParamsByName,
  getKeyValueByObject
} from "../../lib/helper";

// Configs
import { endpoints } from "../../configs";

import AllReferral from "./AllReferral";
import {
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_CLOSED_WON,
  REFERRAL_STATUS_NEW,
  REFERRAL_SECTION_NEW,
  REFERRAL_SECTION_CONVERTED,
  REFERRAL_SECTION_APPROVED,
  REFERRAL_SECTION_DECLINED,
  REFERRAL_STATUS_DECLINED
} from "../../referral/Constants";
import { isBadRequest } from "../../common/http";

import { getPartnerSettingById } from "../../lib/PartnerSetting";

import { ALLOW_REFERRAL_SUBMISSION } from "../../views/adminSettings/tabs/Partner/PartnerTypes/Constants";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";
import { SETTINGS_ENABLE_PARTNER_PAYMENTS } from "../../setting/Constants";

class Referrals extends React.Component {
  state = {
    status: "",
    paymentMethodDetails: "",
    isPartnerAdmin: false,
    partnerDetails: {},
    sortByOptions: [
      {
        value: "account_name:ASC",
        label: "Account Name"
      },
      {
        value: "createdAt:DESC",
        label: "Most Recent"
      }
    ],
    currentNewReferralsSort: "createdAt",
    currentNewReferralsSortDir: "DESC",
    currentApprovedReferralsSort: "createdAt",
    currentApprovedReferralsSortDir: "DESC",
    currentDeclinedReferralsSort: "createdAt",
    currentDeclinedReferralsSortDir: "DESC",
    currentAllReferralsSort: "createdAt",
    currentAllReferralsSortDir: "DESC",
    allReferralsCount: "",
    exportData: "",
    referralCount: 0,
    referralSize: 0,
    referralTotalAmount: 0,
    currentPage: this.props.allReferralsCurrentPage,
    allowReferralSubmission: false,
    activeTab: getParamsByName("activeTab")
      ? getParamsByName("activeTab")
      : "Referral",
    params: {
      start_date: getParamsByName("start_date"),
      end_date: getParamsByName("end_date"),
      account: getParamsByName("account"),
      partner: getParamsByName("partner"),
      status: getParamsByName("status"),
      partnerType: getParamsByName("partnerType"),
      partnerTier: getParamsByName("partnerTier"),
      referralType: getParamsByName("referralType"),
      pageSize: getParamsByName("pageSize"),
      sort: getParamsByName("sort"),
      sortDir: getParamsByName("sortDir"),
      page: this.props.allReferralsCurrentPage || getParamsByName("page"),
      pagination: true
    }
  };

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    if (isPartner(this.props.roleId)) {
      this._getPartnerDetails();
      this.getPaymentMethod();
    }
    const currentStatus = this.props.match.params.status;

    const status =
      currentStatus === REFERRAL_SECTION_APPROVED
        ? REFERRAL_STATUS_APPROVED
        : currentStatus === REFERRAL_SECTION_CONVERTED
        ? REFERRAL_STATUS_CLOSED_WON
        : currentStatus === REFERRAL_SECTION_NEW
        ? REFERRAL_STATUS_NEW
        : currentStatus === REFERRAL_SECTION_DECLINED
        ? REFERRAL_STATUS_DECLINED
        : "";

    this.setState({ status }, () => {});
    this.getReferralList(this.state.params);
  }

  getPartnerSetting = async () => {
    try {
      if (this.state.partnerDetails) {
        let allowReferralSubmission = getPartnerSettingById(
          this.state.partnerDetails.partnerType,
          this.props.partnerSettings,
          ALLOW_REFERRAL_SUBMISSION
        );
        this.setState({ allowReferralSubmission: allowReferralSubmission });
      }
    } catch (err) {
      console.log(err);
    }
  };

  // Get Payment Method
  getPaymentMethod() {
    return apiClient
      .get(
        `${endpoints().accountPaymentMethodAPI}/search?id=${
          this.state.partnerDetails.id
        }`
      )
      .then(response => {
        this.setState({
          paymentMethodDetails: response.data.data
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
        }
      });
  }

  // Get Partner Details
  _getPartnerDetails = () => {
    return apiClient
      .get(`${endpoints().partnerAPI}`)
      .then(response => {
        this.setState(
          {
            partnerDetails: {
              ...response.data
            }
          },
          () => {
            this.getPaymentMethod();
            this.getPartnerSetting();
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
        }
      });
  };

  componentDidUpdate(prevState, prevProps) {
    // Check is logged in user
    isLoggedIn();

    if (
      this.props.allReferralsSort &&
      this.props.allReferralsSort !== this.state.currentAllReferralsSort
    ) {
      this.setState({
        currentAllReferralsSort: this.props.allReferralsSort
      });
    }
    if (
      this.props.allReferralsSortDir &&
      this.props.allReferralsSortDir !== this.state.currentAllReferralsSortDir
    ) {
      this.setState({
        currentAllReferralsSortDir: this.props.allReferralsSortDir
      });
    }
  }

  // Get Referral List
  getReferralList = param => {
    let search = (param && param.search) || "";
    let start_date = (param && param.start_date) || "";
    let end_date = (param && param.end_date) || "";
    let status = param && param.status ? param && param.status : "";
    let sort = (param && param.sort) || "";
    let sortDir = (param && param.sortDir) || "";
    let partner = param && param.partner;
    let partnerType = param && param.partnerType;
    let referralType = param && param.referralType;
    let account = param && param.account;
    let pageSize = param && param.pageSize;
    let page = (param && param.page) || 1;

    let apiUrl;

    if (param) {
      apiUrl = `${
        endpoints().referralApi
      }/search?search=${search}&start_date=${start_date}&end_date=${end_date}&status=${status}&sort=${sort}&sortDir=${sortDir}&partner=${partner}&partnerType=${partnerType}&page=${page}&pageSize=${pageSize}&pagination=${true}&referralType=${referralType}&account=${account}`;
    } else {
      apiUrl = `${
        endpoints().referralApi
      }/search?search=${search}&sort=${sort}&sortDir=${sortDir}&page=${page}&pageSize=${getParamsByName(
        "pageSize"
      )}&pagination=${true}`;
    }
    return apiClient
      .get(apiUrl)
      .then(response => {
        const reportData = [];
        const referralCount = response.data.totalCount || 0;
        const referralSize = response.data.averageAmount || 0;
        const referralTotalAmount = response.data.totalAmount || 0;
        response.data &&
          response.data.data &&
          response.data.exportData.forEach(data => {
            reportData.push({
              accountName: data.account_name,
              name: `${data.first_name} ${data.last_name}`,
              companyName: data.companyName,
              submittedBy: data.submitted_user_name,
              status: data.status,
              amount: data.amount,
              createdAt: data.createdAt
            });
          });
        this.setState({
          exportData: reportData,
          referralCount,
          referralSize,
          referralTotalAmount,
          loading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            this.setState({
              exportData: [],
              referralCount: 0,
              referralSize: 0,
              referralTotalAmount: 0,
              loading: false
            });
          }
          console.error(errorMessage);
        }
      });
  };

  // Toggle Tab
  toggle = tab => {
    this.setState({
      activeTab: tab
    });
  };

  render() {
    const { paymentMethodDetails, allowReferralSubmission } = this.state;
    let { admin_user_id } = this.state.partnerDetails;

    const search = window.location.search;
    const params = new URLSearchParams(search);

    const isPartnerAdmin = admin_user_id === getUserId() ? true : false;

    const currentStatus = this.props.match.params.status;
    const status =
      currentStatus === "approved"
        ? REFERRAL_STATUS_APPROVED
        : currentStatus === "declined"
        ? REFERRAL_STATUS_DECLINED
        : currentStatus === "converted"
        ? REFERRAL_STATUS_CLOSED_WON
        : "";

    const headers = [
      { label: "Account Name", key: "accountName" },
      { label: "Name", key: "name" },
      { label: "Company Name", key: "companyName" },
      { label: "Submitted By", key: "submittedBy" },
      { label: "Status", key: "status" },
      { label: "Amount", key: "amount" },
      { label: "Created At", key: "createdAt" }
    ];

    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId) &&
      !isPartner(this.props.roleId) &&
      !isCompanyManager(this.props.roleId)
    ) {
      return <Page404 />;
    }

    const enableReferralSizeAndValue = this.props.settings
      ? getKeyValueByObject(
          this.props.settings,
          SETTINGS_ENABLE_PARTNER_PAYMENTS
        )
      : "";

    return (
      <>
        <div>
          {/* /.page-heading */}
          <div className="d-flex justify-content-between  align-items-center mb-4 mt-3">
            <PageTitle label={Terminology.get(SystemName.REFERRAL)} />

            {/* PaymentPreference banner */}
            {isPartner(this.props.roleId) &&
              isPartnerAdmin === true &&
              !paymentMethodDetails &&
              this.props.convertedReferralCount > 0 && <PaymentPreference />}
            <div className="d-flex justify-content-between">
              {!isPartner(this.props.roleId) && (
                <div className="mr-3">
                  <CSVLink
                    data={this.state.exportData}
                    headers={headers}
                    filename={`Referral_Report_${moment().format(
                      "YYYY-MM-DD"
                    )}.csv`}
                  >
                    <span className="download-pdf ml-auto d-flex" href="#">
                      <DownloadIcon />
                      <span className="ml-1">Export</span>
                    </span>
                  </CSVLink>
                </div>
              )}
              {/* Add Referrals Button */}
              {(allowReferralSubmission ||
                isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)) && (
                <AddButton
                  id="submitReferralButton"
                  label={`Submit a ${Terminology.get(SystemName.REFERRAL)}`}
                  targetUrl="/referral/add"
                  className="pull-right btn btn-secondary"
                />
              )}
            </div>
          </div>

          <AllReferral
            history={this.props.history}
            allReferralsSort={this.state.currentAllReferralsSort}
            allReferralsSortDir={this.state.currentAllReferralsSortDir}
            allReferralsCurrentPage={this.props.allReferralsCurrentPage}
            allReferralsCount={this.props.allReferralsCount}
            averageReferralSize={this.state.referralSize}
            totalValue={this.state.referralTotalAmount}
            getReferralList={this.getReferralList}
            status={this.state.status}
            approvedReferralsSort={this.props.approvedReferralsSort}
            approvedReferralsSortDir={this.props.approvedReferralsSortDir}
            declinedReferralsSort={this.props.declinedReferralsSort}
            declinedReferralsSortDir={this.props.declinedReferralsSortDir}
            newReferralsSort={this.props.newReferralsSort}
            newReferralsSortDir={this.props.newReferralsSortDir}
            enableReferralSizeAndValue={enableReferralSizeAndValue}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  const {
    newReferrals,
    approvedReferrals,
    declinedReferrals,
    allReferrals,
    convertedReferrals
  } = state.table;

  const roleId = state.user ? state.user.roleId : "";

  const newReferralsSort =
    newReferrals && !newReferrals.isFetching ? newReferrals.sort : "";

  const newReferralsSortDir =
    newReferrals && !newReferrals.isFetching ? newReferrals.sortDir : "";

  const approvedReferralsSort =
    approvedReferrals && !approvedReferrals.isFetching
      ? approvedReferrals.sort
      : "";

  const approvedReferralsSortDir =
    approvedReferrals && !approvedReferrals.isFetching
      ? approvedReferrals.sortDir
      : "";

  const declinedReferralsSort =
    declinedReferrals && !declinedReferrals.isFetching
      ? declinedReferrals.sort
      : "";

  const declinedReferralsSortDir =
    declinedReferrals && !declinedReferrals.isFetching
      ? declinedReferrals.sortDir
      : "";
  const allReferralsSort =
    allReferrals && !allReferrals.isFetching ? allReferrals.sort : "";

  const allReferralsSortDir =
    allReferrals && !allReferrals.isFetching ? allReferrals.sortDir : "";

  const allReferralsCurrentPage =
    allReferrals && !allReferrals.isFetching ? allReferrals.currentPage : 1;

  const allReferralsCount =
    allReferrals && !allReferrals.isFetching ? allReferrals.totalCount : 0;

  //convertedReferrals count
  const convertedReferralCount =
    convertedReferrals && !convertedReferrals.isFetching
      ? convertedReferrals.totalCount
      : 0;

  return {
    newReferralsSort,
    newReferralsSortDir,
    approvedReferralsSort,
    approvedReferralsSortDir,
    declinedReferralsSort,
    declinedReferralsSortDir,
    allReferralsSort,
    allReferralsSortDir,
    allReferralsCurrentPage,
    allReferralsCount,
    convertedReferralCount,
    roleId
  };
};
export default connect(mapStateToProps)(Referrals);
