import React, { useEffect, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import toast from "../../../../components/base/Toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchList } from "../../../../actions/table";

// Assets
import createLandingIcon from "../../../../assets/pages/landing-page/create-landing-page.png";

// Component
import AddButton from "../../../../components/base/AddButton";
import Text from "../../../../components/base/Text";
import Form from "../../../../components/base/Form";
import Email from "../../../../components/base/Email";

// Helper
import { toString } from "../../../../lib/helper";

//API call
import { apiClient } from "../../../../apiClient";

// Configs
import { endpoints } from "../../../../configs";
//commom
import {
  getResponseMessage,
  getErrorMessage,
  isBadRequest
} from "../../../../common/http";
import Select from "../../../../components/base/Select";
import companyService from "../../../../services/companyService";

// Customer Create API Call

const AddCompanyModal = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  function companyCreate(data, props) {
    return apiClient
      .post(`${endpoints().companyAPI}/invite`, data)
      .then(response => {
        toast.success(getResponseMessage(response));
        props.actions.fetchList("companies", "v1/company");
        setTimeout(() => {
          toggle();
        }, 500);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          toast.error(getErrorMessage(error));
          console.error(getErrorMessage(error));
        }
      });
  }

  return (
    <>
      <AddButton
        label="Add New"
        className="pull-right btn btn-secondary h6-5-important font-weight-bold"
        onClick={toggle}
      />
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <div className="img-wrapper d-flex justify-content-center mb-2 mt-3">
                <img src={createLandingIcon} size="30" alt="" />
              </div>
              <p className="text-center font-weight-bold mb-3">
                Create a new Company
              </p>
              <p className="text-center h6-5 mb-2"></p>
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.content-wrapper */}
        </ModalHeader>

        <Form
          initialValues={{
            companyName: "",
            email: ""
          }}
          onSubmit={values => {
            values.companyName = toString(values.companyName);
            values.email = toString(values.email);

            companyCreate(values, props);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div>
                <Text
                  name="companyName"
                  label="Company Name"
                  placeholder="Company Name..."
                  error=""
                  required={true}
                />
              </div>
              <div>
                <Email
                  name="email"
                  label="Contact Email"
                  placeholder="Contact Email Address..."
                  error=""
                  required={true}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="" className="h6-5-important">
                  Create Company
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(() => {
  return {};
}, mapDispatchToProps)(AddCompanyModal);
