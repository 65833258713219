//Landing page block type starts
export const LANDING_PAGE_BLOCK_TYPE_BANNER = "Banner Header";
export const LANDING_PAGE_BLOCK_TYPE_PLAYBOOK = "Playbook Collection";
export const LANDING_PAGE_BLOCK_TYPE_EXPERT = "Expert Collection";
export const LANDING_PAGE_BLOCK_TYPE_PARTNER = "Partner Collection";
export const LANDING_PAGE_BLOCK_TYPE_FIND_AN_EXPERT = "Find an Expert CTA";
export const LANDING_PAGE_BLOCK_TYPE_SIGN_UP = "Sign up CTA";
export const LANDING_PAGE_BLOCK_TYPE_NEED_AN_EXPERT =
  "Combined Expert/Need an Expert";
export const LANDING_PAGE_BLOCK_TYPE_CONTENT = "Content";
export const LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID =
  "Playbook Collection (Grid)";
export const LANDING_PAGE_BLOCK_TYPE_APPS = "App Collection (Grid)";

export const LANDING_PAGE_FEATURED_COLLECTION = "Featured Collection";

//Landing page block type ends

//Landing page status start
export const LANDING_PAGE_STATUS_ACTIVE = "Active";
export const LANDING_PAGE_STATUS_DRAFT = "Draft";
//Landing page status ends

// Landing page partner block specify partners
export const LANDING_PAGE_BLOCK_PARTNER_SPECIFY_ALL = "All Approved Partners";
export const LANDING_PAGE_BLOCK_PARTNER_SPECIFY_ALL_VALUE = 1;
export const LANDING_PAGE_BLOCK_PARTNER_SPECIFY_SELECTED = "Selected Partners";
export const LANDING_PAGE_BLOCK_PARTNER_SPECIFY_SELECTED_VALUE = 2;
export const LANDING_PAGE_BLOCK_PARTNER_SPECIFY_FILTERED = "Filtered Partners";
export const LANDING_PAGE_BLOCK_PARTNER_SPECIFY_FILTERED_VALUE = 3;

// Landing page partner block specify partners
export const LANDING_PAGE_BLOCK_TAG_TYPE_MULTI_SELECT = "Multi Select";
export const LANDING_PAGE_BLOCK_TAG_TYPE_MULTI_SELECT_VALUE = true;
export const LANDING_PAGE_BLOCK_TAG_TYPE_SINGLE_SELECT = "Single Select";
export const LANDING_PAGE_BLOCK_TAG_TYPE_SINGLE_SELECT_VALUE = false;

export const LANDING_PAGE_BLOCK_EXPERT_SIGNUP_CTA = "Expert Signup";
export const LANDING_PAGE_BLOCK_EXPERT_SIGNUP_CTA_VALUE = 1;
export const LANDING_PAGE_BLOCK_PARTNER_SIGNUP_CTA = "Partner Signup";
export const LANDING_PAGE_BLOCK_PARTNER_SIGNUP_CTA_VALUE = 2;
export const LANDING_PAGE_BLOCK_CUSTOM_CTA = "Custom";
export const LANDING_PAGE_BLOCK_CUSTOM_CTA_VALUE = 3;

//Landing Page Filter Type
export const LANDING_PAGE_FILTER_PARTNER_TYPE_VALUE = -1;
export const LANDING_PAGE_FILTER_PARTNER_TIRE_VALUE = -2;
export const LANDING_PAGE_FILTER_PARTNER_TYPE = "Partner Type";
export const LANDING_PAGE_FILTER_PARTNER_TIRE = "Partner Tier";
