import React, { useState } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownItem } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

// Component
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import MoreDropdown from "../../../../components/base/MoreDropdown";
import AddButton from "../../../../components/base/AddButton";
import Form from "../../../../components/base/Form";
import Text from "../../../../components/base/Text";
import Select from "../../../../components/base/Select";
import DeleteModal from "../../../../components/base/DeleteModal";

// Configs
import { endpoints } from "../../../../configs";

// Helper
import { getParamsByName, toString } from "../../../../lib/helper";

// Actions
import {
  deleteTagType,
  addTagType,
  updateTagType
} from "../../../../actions/tagType";

// Constants
import {
  TAG_TYPE_CATEGORY_MARKETPLACE,
  TAG_TYPE_CATEGORY_MARKETPLACE_LABEL,
  TAG_TYPE_CATEGORY_OTHERS,
  TAG_TYPE_CATEGORY_OTHERS_LABEL,
  TYPE_SYSTEM,
  TYPE_USER_DEFINED
} from "../../../../tagType/Constants";

// Constants
import {
  TAB_LIST,
  LIST_TAGS_SUB_TAB
} from "../../../../supportPortal/Constants";

const ManageTagTypeList = props => {
  const [isOpen, setIsOpen] = useState(false);
  const [id, setId] = useState("");
  const [name, setName] = useState("");
  const [category, setCategory] = useState("");
  const [type, setType] = useState("");
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [deleteTagTypeRow, setDeleteTagTypeRow] = useState("");

  /**
   * Create tag
   *
   * @param data
   */
  const tagTypeCreate = data => {
    props.actions.addTagType(
      data,
      { list: true, type: props.tagType ? props.tagType : "" },
      setIsOpen,
      () => {},
      res => {
        if (res) {
          _toggle();
        } else {
          _toggle(false);
        }
      }
    );
  };

  /**
   * Update tag type
   *
   * @param data
   * @param id
   */
  const tagTypeUpdate = data => {
    props.actions.updateTagType(
      id,
      data,
      { list: true, type: props.tagType ? props.tagType : "" },
      setIsOpen,
      () => {}
    );
  };

  /**
   * Delete tag type
   *
   * @param id
   */
  const handleDelete = id => {
    props.actions.deleteTagType(
      id,
      { list: true, type: props.tagType ? props.tagType : "" },
      () => {}
    );
  };

  const _toggle = tagTypeId => {
    setId(tagTypeId || 0);
    setIsOpen(!isOpen);
  };

  const headerButton = () => (
    <div className="btn-wrapper mb-n3">
      {!props.showButton && (
        <AddButton
          label="Add New"
          className="pull-right btn btn-secondary"
          onClick={() => {
            setName("");
            setCategory("");
            setType("");
            _toggle();
          }}
        />
      )}
      {tagTypeModal()}
    </div>
  );

  // Render the tag type modal
  const tagTypeModal = () => (
    <Modal
      isOpen={isOpen}
      toggle={_toggle}
      className="landing-create-popup w-100"
    >
      <ModalHeader
        toggle={_toggle}
        cssModule={{ "modal-title": "w-100 text-center" }}
      >
        <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
          {id ? "Edit Tag Type" : "Create Tag Type"}
        </h4>
      </ModalHeader>
      <Form
        initialValues={{
          name: name || ""
        }}
        onSubmit={values => {
          values.name = toString(values.name).trim();
          values.type = type ? toString(type) : toString(TYPE_USER_DEFINED);
          values.category = TAG_TYPE_CATEGORY_OTHERS;
          id ? tagTypeUpdate(values) : tagTypeCreate(values);
        }}
      >
        <ModalBody className="ml-3 mr-3 mt-2 mb-3">
          <div>
            <Text
              name="name"
              label="Tag Type"
              placeholder="Enter tag type"
              required={true}
              error=""
              disabled={type === TYPE_SYSTEM ? true : false}
            />
            <div className="d-lg-none d-md-none d-sm-none">
              <Text
                name="category"
                label="Category"
                placeholder="Select Category"
                defaultValue={TAG_TYPE_CATEGORY_OTHERS}
                isSearchable={true}
                disabled
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <div className="container-fluid">
            <div className="col-sm-12 text-center">
              <Button type="submit" label="">
                {id ? "Save" : "Create Tag Type"}
              </Button>
            </div>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );

  return (
    <div>
      <DeleteModal
        isOpen={openDeleteModal}
        toggle={() => {
          setOpenDeleteModal(false);
        }}
        title="Delete Tag Type"
        deleteFunction={() => {
          console.log(deleteTagTypeRow);
          handleDelete(deleteTagTypeRow.id);
        }}
        label={deleteTagTypeRow.name}
      />
      <ReduxTable
        id="tagTypes"
        apiURL={`${endpoints().tagTypeAPI}`}
        params={{
          list: true,
          type: props.tagType ? props.tagType : "",
          page: getParamsByName("page") || 1,
          pageSize: getParamsByName("pageSize") || 10,
          pagination: getParamsByName("pagination") || true
        }}
        headerButton={headerButton()}
        onRowClick={row => {
          props.history.push(
            `${props.link}/${row.name}/${row.id}?tag_type_filter=${row.name}`
          );
        }}
        showHeader
        searchDisabled
      >
        <ReduxColumn
          minWidth={"335px"}
          width={"335px"}
          maxWidth={"335px"}
          type="link"
          isClickable="true"
          field="name"
          sortBy="name"
        >
          Name
        </ReduxColumn>
        <ReduxColumn
          minWidth={"173px"}
          width={"173px"}
          maxWidth={"173px"}
          field="type"
          sortBy="type"
        >
          Type
        </ReduxColumn>
        <ReduxColumn
          minWidth={"100px"}
          width={"100px"}
          maxWidth={"100px"}
          field="status"
          disableOnClick
          className="action-column"
          renderField={row => (
            <div className="text-center landing-group-dropdown">
              <MoreDropdown>
                <DropdownItem
                  onClick={() => {
                    setName(row.name);
                    setCategory(row.category);
                    setType(row.type);
                    return _toggle(row.id);
                  }}
                >
                  Edit
                </DropdownItem>
                {row.type === TYPE_USER_DEFINED && (
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setOpenDeleteModal(true);
                      setDeleteTagTypeRow(row);
                    }}
                  >
                    Delete
                  </DropdownItem>
                )}
              </MoreDropdown>
            </div>
          )}
        >
          Action
        </ReduxColumn>
      </ReduxTable>
    </div>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { deleteTagType, addTagType, updateTagType },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(ManageTagTypeList);
