// Configs
import { endpoints } from "../configs";

import toast from "../components/base/Toast";

// API call
import { apiClient } from "../apiClient";

import { isBadRequest } from "../common/http";

export const SalesforceAccountDetails = (referralId, callback) => {
  if (referralId) {
    apiClient
      .get(`${endpoints().salesforceAPI}/account/${referralId}`)
      .then(response => {
        if (response && response.data) {
          return callback(response);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
        return callback(null);
      });
  }
};

export const getReferralDetails = (Id, callback) => {
  apiClient
    .get(`${endpoints().referralApi}/${Id}`)
    .then(response => {
      return callback(response);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
          console.log(errorMessage);
        }
        return callback(null);
      }
    });
};

export const InvoiceDetails = (id, callback) => {
  apiClient.get(`${endpoints().invoiceAPI}/${id}`).then(response => {
    if (response && response.data) {
      return callback(response);
    }
  });
};

// Handle form submit
export const UpdateReferralDetails = (values, Id, callback) => {
  return apiClient
    .put(`${endpoints().referralApi}/${Id}`, values)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
        return callback(response);
      }
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
          console.log(errorMessage);
        }
        return callback();
        toast.error(error.response.data.message);
      }
    });
};

// Handle delete
export const deleteReferral = (Id, callback) => {
  apiClient
    .delete(`${endpoints().referralApi}/${Id}`)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
        return callback(response);
      }
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
          console.log(errorMessage);
        }
        toast.error(error.response.data.message);
      }
    });
};

// Update Status
export const updateStatus = (id, status, isConflictFlag, callback) => {
  const data = { status, isConflictFlag };
  apiClient
    .put(`${endpoints().referralApi}/status/${id}`, data)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
        return callback(null, response);
      }
      return callback(null, null);
    })
    .catch(error => {
      let errorMessage;
      const errorRequest = error && error.response && error.response.request;
      if (errorRequest && errorRequest.response) {
        let responseData = JSON.parse(errorRequest.response);
        let referralList = responseData && responseData.referralList;
        errorMessage = responseData && responseData.message;
        if (errorMessage && !Array.isArray(referralList)) {
          console.log(errorMessage);
          toast.error(error.response.data.message);
        }
        return callback(referralList, null);
      }
    });
};

// Update Referral Decline
export const declineReferral = (
  id,
  status,
  declineMessage,
  declineNotification,
  callback
) => {
  const data = { status, declineMessage, declineNotification };
  apiClient
    .put(`${endpoints().referralApi}/decline/${id}`, data)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
        return callback(response);
      }
      return callback(false);
    })
    .catch(error => {
      let errorMessage;
      const errorRequest = error && error.response && error.response.request;
      if (errorRequest && errorRequest.response) {
        errorMessage = JSON.parse(errorRequest.response).message;
        console.log(errorMessage);
        toast.error(error.response.data.message);
        return callback(false);
      }
    });
};

//Update Owner Id
export const updateOwner = (referralId, ownerId, isSalesforce, callback) => {
  apiClient
    .put(
      `${
        endpoints().referralApi
      }/owner/${referralId}?isSalesforce=${isSalesforce}`,
      ownerId
    )
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
        return callback(response.data);
      }
      return callback(false);
    })
    .catch(error => {
      let errorMessage;
      const errorRequest = error && error.response && error.response.request;
      if (errorRequest && errorRequest.response) {
        errorMessage = JSON.parse(errorRequest.response).message;
        console.log(errorMessage);
        toast.error(error.response.data.message);
      }
      return callback(false);
    });
};

//Get Partner Tier Commission Percentage
export const PartnerTierCommissionPercentage = callback => {
  apiClient
    .get(`${endpoints().partnerTierAPI}/search`)
    .then(response => {
      if (response && response.data && response.data.data) {
        return callback(response);
      }
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
          console.log(errorMessage);
        }
        toast.error(error.response.data.message);
      }
    });
};

// Sync To Salesforce
export const handleSyncToSalesforce = (Id, callback) => {
  apiClient
    .put(`${endpoints().syncAPI}/salesforce/referral/${Id}`)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      return callback(response);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
      return callback(null);
    });
};

// Sync To Salesforce
export const handleSyncFromSalesforce = (Id, callback) => {
  apiClient
    .put(`${endpoints().referralApi}/job/sync/opportunities/?id=${Id}`)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      return callback(response);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
      return callback(null);
    });
};

// Commission Calculation
export const updatePaymentInfo = (Id, callback) => {
  apiClient
    .put(`${endpoints().referralApi}/referral/updatePaymentInfo/${Id}`)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      return callback(response);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
      return callback(null);
    });
};

export const updateCommission = (Id, callback) => {
  apiClient
    .put(`${endpoints().referralApi}/referral/updateCommission/${Id}`)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      return callback(response);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
      return callback(null);
    });
};

// Invoice Generate
export const handleCreateInvoice = (Id, callback) => {
  apiClient
    .put(`${endpoints().referralApi}/referral/createInvoice/${Id}`)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      return callback(response);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
      return callback(null);
    });
};

// Sync To Salesforce
export const getSalesforceUserDetails = callback => {
  apiClient
    .get(`${endpoints().referralApi}/salesforce/users`)
    .then(response => {
      if (response && response.data) {
        return callback(response.data);
      }
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    });
};

// Sync To Salesforce
export const handleSyncToHubSpot = (Id, callback) => {
  apiClient
    .put(`${endpoints().referralApi}/syncToHubspot/${Id}`)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      return callback(response);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
      return callback(null);
    });
};

// Sync From HubSpot
export const syncFromHubSpot = (dealId, callback) => {
  apiClient
    .put(`${endpoints().referralApi}/syncFromHubspot/${dealId}`)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      return callback(response);
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
      return callback(null);
    });
};

export const getHubSpotCompanyDetails = (referralId, callback) => {
  try {
    if (referralId) {
      apiClient
        .get(`${endpoints().hubSpotRoute}/company/search/${referralId}`)
        .then(response => {
          if (response && response.data) {
            return callback(response.data);
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    }
  } catch (err) {
    console.log(err);
  }
};

export const HubSpotCompanyInspect = (searchTerm, callback) => {
  try {
    if (searchTerm) {
      apiClient
        .get(
          `${
            endpoints().hubSpotLookUpRoute
          }/company/search?searchTerm=${searchTerm}`
        )
        .then(response => {
          if (response && response.data && response.data.searchResult) {
            return callback(response.data.searchResult);
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            toast.error(error.response.data.message);
            console.error(errorMessage);
          }
        });
    }
  } catch (err) {
    console.log(err);
  }
};
