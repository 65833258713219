import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { TabPane } from "reactstrap";

// Action
import { fetchList } from "../../../../actions/table";

// Components
import toast from "../../../../components/base/Toast";

// Configs
import { endpoints } from "../../../../configs";
import { apiClient } from "../../../../apiClient";

// Constants
import DeleteModal from "../../../../components/base/DeleteModal";
import { isBadRequest } from "../../../../common/http";
import { isNotEmpty } from "../../../../common/utils";
import PageTitle from "../../../../components/base/PageTitle";
import Spinner from "../../../../components/base/Spinner";
import AddPartnerTiersBtn from "../ReferralsTab/AddPartnerTierBtn";
import Tags from "./inner-component/Tags";
import NoRecordsFound from "../../../../components/base/NoRecordsFound";

import { REFERRAL_PARTNER_TIERS_SUB_TAB } from "../../../../setting/Constants";

const PartnerTierSection = props => {
  const dispatch = useDispatch();
  const _partnerTierToggle = data => {
    setTierId((data && data.id) || "");
    setTierName((data && data.name) || "");
    setTierSort((data && data.sort) || "");
    setTierCommission((data && data.percentage.toString()) || "");
    setAmount((data && data.quota_amount) || "");
    setType((data && data.quota_type) || "");
    setPartnerTypeName((data && data.partner_type) || "");
    setPartnerTypeId((data && data.partner_typeId) || "");
    setIsDefault((data && data.isDefault) || "");
    setPartnerType((data && data.partnerType) || "");
    setOpenPartnerTier(!openPartnerTier);
    setVisibility((data && data.visibility) || "");
  };
  // initilize states
  const [deleteTierModal, setdeleteTierModal] = useState(false);
  const [currentTier, setCurrentTier] = useState(false);
  const [openPartnerTier, setOpenPartnerTier] = useState(false);
  const [tierId, setTierId] = useState("");
  const [isDefault, setIsDefault] = useState(false);
  const [tierName, setTierName] = useState("");
  const [visibility, setVisibility] = useState(false);
  const [tierSort, setTierSort] = useState("");
  const [tierCommission, setTierCommission] = useState("");
  const [amount, setAmount] = useState("");
  const [type, setType] = useState("");
  const [selectedType, setSelectedType] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [partnerTierList, setPartnerTierList] = useState([]);
  const [sourceTaskOrder, setSourceTaskOrderState] = useState("");
  const [targetTaskOrder, setTargetTaskOrderState] = useState("");
  const [partnerTypeName, setPartnerTypeName] = useState("");
  const [partnerTypeId, setPartnerTypeId] = useState("");
  const [partnerType, setPartnerType] = useState("");

  // const [toggle, setToggle] = useState();

  const partnerTierDelete = async id => {
    try {
      const response = await apiClient.delete(
        `${endpoints().partnerTierAPI}/${id}`
      );
      if (response && response.data) {
        toast.success(response.data.message);
        getPartnerTierList();
        // Update partner tier list
        dispatch(
          fetchList(
            "partnerTiers",
            `${endpoints().partnerTierAPI}/search`,
            1,
            10
          )
        );
      }
    } catch (error) {
      if (isBadRequest(error)) {
        toast.error(error.response.data.message);
      }
    }
  };

  // Getting Partner tier List
  const getPartnerTierList = () => {
    apiClient
      .get(`${endpoints().partnerTierAPI}/search`)
      .then(async response => {
        if (response.data && response.data.data) {
          await setPartnerTierList(response.data.data);
          await setTierId(response.data.data.id);
          await setTierName(response.data.data.name);
          await setIsDefault(response.data.data.isDefault);
          await setTierCommission(response.data.data.percentage);
          await setAmount(response.data.data.quota_amount);
          await setType(response.data.data.quota_type);
          await setPartnerTypeName(response.data.data.partner_type);
          await setPartnerTypeId(response.data.data.partner_typeId);
          await setPartnerType(response.data.data.partnerType);
          await setVisibility(response.data.data.visibility);
          await setIsLoading(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.stringify(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  const setSourceTaskOrder = PartnerTier => {
    if (sourceTaskOrder !== PartnerTier.sort) {
      setSourceTaskOrderState(PartnerTier.sort);
    }
  };

  const setTargetTaskOrder = PartnerTier => {
    if (targetTaskOrder !== PartnerTier.sort) {
      setTargetTaskOrderState(PartnerTier.sort);
    }
  };
  const changeTaskOrder = () => {
    // Current Selected Question List
    if (partnerTierList && partnerTierList.length > 0) {
      partnerTierList.splice(
        targetTaskOrder,
        0,
        partnerTierList.splice(sourceTaskOrder, 1)[0]
      );
      // Updated Selected Tag Type List
      const updatedPartnerTierList = [];

      partnerTierList.forEach(PartnerTier => {
        updatedPartnerTierList.push({
          ...PartnerTier,
          // Update Selected Tag Type Order
          sort: updatedPartnerTierList.length
        });
      });

      updatePartnerTierList(updatedPartnerTierList);
      setPartnerTierList(updatedPartnerTierList);
      setSourceTaskOrderState("");
      setTargetTaskOrderState("");
    } else {
      // Reset Order Positions of Selected Tag Type
      setSourceTaskOrderState("");
      setTargetTaskOrderState("");
    }
  };
  const updatePartnerTierList = updatedPartnerTierList => {
    apiClient
      .put(
        `${endpoints().partnerTierAPI}/update/sortOrder`,
        updatedPartnerTierList
      )
      .then(response => {
        if (response.data) {
          setIsLoading(false);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  // const toggleTier = partnerTierId => {
  //   setToggle(isOpen, partnerTierId || "");
  // };

  useEffect(() => {
    getPartnerTierList();
  }, []);

  return (
    <>
      <div className="d-flex justify-content-between flex-wrap mt-3">
        <div className="col-6">
          <PageTitle label="Partner Tiers" />
        </div>

        <div className="pull-right">
          <AddPartnerTiersBtn
            getPartnerTierList={getPartnerTierList}
            setTierId={setTierId}
            setIsDefault={setIsDefault}
            setTierName={setTierName}
            setTierSort={setTierSort}
            setVisibility={setVisibility}
            setTierCommission={setTierCommission}
            setAmount={setAmount}
            setType={setType}
            setPartnerType={setPartnerTypeName}
            setSelectedType={setSelectedType}
            tierId={tierId}
            partnerType={partnerType}
            partnerTypeName={partnerTypeName}
            partnerTypeId={partnerTypeId}
            tierName={tierName}
            tierSort={tierSort}
            isDefault={isDefault}
            tierCommission={tierCommission}
            amount={amount}
            type={type}
            selectedType={selectedType}
            setOpenPartnerTier={setOpenPartnerTier}
            openPartnerTier={openPartnerTier}
            visibility={visibility}
          />
        </div>
      </div>

      <TabPane tabId={REFERRAL_PARTNER_TIERS_SUB_TAB}>
        <>
          <DeleteModal
            isOpen={deleteTierModal}
            toggle={() => {
              setdeleteTierModal(prevState => !prevState);
            }}
            title="Delete Partner Tier"
            id={currentTier.id}
            label={currentTier.name}
            deleteFunction={partnerTierDelete}
          />

          <div className="pb-0 pt-0 mb-0"></div>
          <div className="card">
            <div className="d-flex justify-content-between no-wrap relative bg-dark text-white">
              <h6 className="font-weight-bold pt-4 px-4 pb-3">Partner Tier</h6>
              <h6 className="font-weight-bold pt-4 px-4 pb-3">Commission</h6>
              <h6 className="font-weight-bold pt-4 px-4 pb-3">Quota</h6>
              <h6 className="font-weight-bold pt-4 px-4 pb-3">Action</h6>
            </div>

            <div className="task-list">
              {isLoading && <Spinner />}
              {isNotEmpty(partnerTierList) ? (
                partnerTierList
                  .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
                  .map((taskItem, i) => (
                    <>
                      <div
                        id={taskItem.sort}
                        key={taskItem.id}
                        onRowClick={row => {
                          setSourceTaskOrder(row.name, row.id);
                          return _partnerTierToggle(row.id);
                        }}
                        draggable="true"
                        onDrag={e => {
                          setSourceTaskOrder(taskItem);
                        }}
                        onDragOver={e => {
                          e.preventDefault();

                          setTargetTaskOrder(taskItem);
                        }}
                        onDrop={e => {
                          changeTaskOrder();
                        }}
                      >
                        <Tags
                          task={taskItem}
                          openEditModal={() => {
                            _partnerTierToggle(taskItem);
                          }}
                          openDeleteModal={() => {
                            setCurrentTier(taskItem);
                            setdeleteTierModal(taskItem);
                          }}
                        />
                      </div>
                    </>
                  ))
              ) : (
                <NoRecordsFound
                  hideCard
                  description="No Partner Tiers are added yet"
                />
              )}
            </div>
          </div>
        </>
      </TabPane>
    </>
  );
};

export default PartnerTierSection;
