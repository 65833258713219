import React, { useEffect, useRef } from "react";
import AddToCalendar from "react-add-to-calendar";

const KickOffAddToCalendar = ({ event }) => {
  const addToCalendarEl = useRef(null);

  useEffect(() => {
    // addToCalendarEl.current.toggleCalendarDropdown();
  }, []);
  return (
    <div className={["kickoff-scheduler__add-to-calendar"]}>
      <AddToCalendar
        event={event}
        buttonLabel={"Pick a calendar below to add your event"}
        listItems={[
          { google: "Add to Google Calendar" },
          { apple: "Add to Apple Calendar" },
          { outlookcom: "Add to Outlook Calendar" },
          { yahoo: "Add to Yahoo Calendar" }
        ]}
        optionsOpen={true}
        ref={addToCalendarEl}
      />
    </div>
  );
};

export default KickOffAddToCalendar;
