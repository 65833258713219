import React from "react";

const PartnerDashboardBanner = props => {
  const { spotlightImageUrl } = props;

  const partnerDashboardDefaultBannerImageUrl = spotlightImageUrl
    ? spotlightImageUrl
    : "";

  return (
    partnerDashboardDefaultBannerImageUrl && (
      <div className="alerts-wrapper">
        <img
          className="dashboard-banner-image"
          src={partnerDashboardDefaultBannerImageUrl}
          alt="dashboard-banner-image"
        />
      </div>
    )
  );
};
export default PartnerDashboardBanner;
