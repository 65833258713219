import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-date-picker";

// Components
import Text from "../../../../../components/base/Text";
import TextArea from "../../../../../components/base/TextArea";
import Phone from "../../../../../components/base/Phone";
import Email from "../../../../../components/base/Email";

class ContactFormFields extends Component {
  // Render the form fields
  render() {
    return (
      <div>
        <div className="card bg-white mt-3 mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div>
                  <Text
                    name="salesforceId"
                    label="Salesforce Id"
                    error=""
                    disabled
                  />
                </div>
                <div>
                  <Email name="email" label="Email" error="" disabled />
                </div>
                <div>
                  <Text name="firstName" label="First Name" error="" disabled />
                </div>
                <div>
                  <Text name="lastName" label="Last Name" error="" disabled />
                </div>
                <div>
                  <Text name="name" label="Name" error="" disabled />
                </div>
                <div>
                  <Phone name="phone" label="Phone" error="" disabled />
                </div>
                <div>
                  <TextArea
                    name="description"
                    label="Description"
                    error=""
                    disabled
                  />
                </div>
                <div className="w-100 mb-2">
                  <div>
                    <label>Created Date</label>
                  </div>
                  <DatePicker
                    name="createdDate"
                    className="datepicker"
                    dayPlaceholder="MM/DD/YYYY"
                    minDate={new Date()}
                    value={
                      this.props.contactDetails &&
                      this.props.contactDetails.CreatedDate
                    }
                    clearIcon={null}
                    disabled
                  />
                </div>
                <div className="w-100 mb-2">
                  <div>
                    <label>Last Modified Date</label>
                  </div>
                  <DatePicker
                    name="lastModifiedDate"
                    className="datepicker"
                    dayPlaceholder="MM/DD/YYYY"
                    minDate={new Date()}
                    value={
                      this.props.contactDetails &&
                      this.props.contactDetails.LastModifiedDate
                    }
                    clearIcon={null}
                    disabled
                  />
                </div>
                <div>
                  <Text
                    name="createdById"
                    label="Created By Id"
                    error=""
                    disabled
                  />
                </div>
                <div>
                  <Text name="ownerId" label="Owner Id" error="" disabled />
                </div>
                <div>
                  <Text name="isDeleted" label="Is Deleted" error="" disabled />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactFormFields;
