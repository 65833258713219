import React from "react";

// Components
import Spinner from "../../../components/base/Spinner";
import PartnerCard from "../../../components/PartnerCard";

// Configs
import { endpoints } from "../../../configs";

// API call
import { apiClient } from "../../../apiClient";

// Constants
import { LANDING_PAGE_BLOCK_TYPE_PARTNER } from "../../../landingPage/Constants";
import {
  TAG_TYPE_PRODUCTS,
  TAG_TYPE_SERVICES
} from "../../../tagType/Constants";

export default class Otherpartners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partnerList: [],

      services: [],
      industries: [],
      partnerList: [],
      loading: false,
      salesforceLanding: false
    };
  }

  componentDidMount = () => {
    this._getPartners();
  };

  // Get partner list
  _getPartners = () => {
    apiClient
      .get(`${endpoints().publicAPI}/landingPage/${this.props.landingPageId}`)
      .then(response => {
        let services = [],
          industries = [];
        if (
          response &&
          response.data &&
          response.data.content &&
          response.data.content.length > 0
        ) {
          response.data.content.forEach(block => {
            if (
              block.type === LANDING_PAGE_BLOCK_TYPE_PARTNER &&
              block.partners &&
              block.partners.length > 0
            ) {
              let partnerList = block.partners;
              partnerList.sort(() => Math.random() - 0.5);
              partnerList = partnerList.slice(0, 3);
              this.setState({ partnerList });
              partnerList.forEach(partner => {
                if (
                  partner.partnerProfileTag &&
                  partner.partnerProfileTag.length > 0
                ) {
                  partner.partnerProfileTag.forEach(partnerTag => {
                    if (
                      partnerTag.tag &&
                      partnerTag.tag.tagType.name === TAG_TYPE_SERVICES
                    ) {
                      if (
                        !services.find(item => item.value === partnerTag.tag.id)
                      ) {
                        services.push({
                          value: partnerTag.tag.id,
                          label: partnerTag.tag.name
                        });
                      }
                    }

                    if (
                      partnerTag.tag &&
                      partnerTag.tag.tagType.name === TAG_TYPE_PRODUCTS
                    ) {
                      if (
                        !industries.find(
                          item => item.value === partnerTag.tag.id
                        )
                      ) {
                        industries.push({
                          value: partnerTag.tag.id,
                          label: partnerTag.tag.name
                        });
                      }
                    }
                  });
                }
              });
            }
          });
        }
      });
  };

  render() {
    const { partnerList, partnerLoading, salesforceLanding } = this.state;
    return (
      <>
        <div className="row">
          {partnerLoading ? (
            <div className="col-12">
              <div className="card pt-5 pb-5 mb-5">
                <div className="pt-5 pb-5">
                  <Spinner />
                </div>
              </div>
            </div>
          ) : partnerList && partnerList.length > 0 ? (
            partnerList.map(partner => (
              <PartnerCard
                classnames={"mt-3"}
                data={partner}
                size={`col-12 col-md-6 col-lg-4 ${salesforceLanding &&
                  "col-sm-6"}`}
                key={partner.id}
                certified={true}
                link={`/partner-profile/${partner.id}?redirect=${window.location.pathname}`}
                openLinkInNewTab={true}
                fixedWidth={false}
              />
            ))
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}
