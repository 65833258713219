import React from "react";
import PropTypes from "prop-types";
import Avatar from "./Avatar";
import { Link } from "react-router-dom";
import { Popover, PopoverBody } from "reactstrap";

class AvatarCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false
    };
    this.popOverRef = React.createRef();
  }

  componentDidMount() {
    document.addEventListener("mousedown", this.handleClickOutside);
  }
  toggle = () => {
    this.setState({
      isOpen: !this.state.isOpen
    });
  };

  handleClickOutside = event => {
    if (
      this.popOverRef.current &&
      !this.popOverRef.current.contains(event.target)
    ) {
      this.setState({
        isOpen: false
      });
    }
  };
  render() {
    const {
      title,
      firstName,
      lastName,
      companyName,
      url,
      certificateImage,
      certificateName,
      courseCount,
      link,
      id,
      data
    } = this.props;

    const names = [];
    if (firstName) {
      names.push(firstName);
    }

    if (lastName) {
      names.push(lastName);
    }

    if (companyName) {
      names.push(companyName);
    }

    const name = names.join(" ");

    return (
      <div style={{ display: "flex", alignItems: "center" }}>
        <div style={{ marginRight: "10px" }}>
          {link ? (
            <Link
              className="h6-5 font-weight-bold text-dark text-decoration-none"
              to={link}
              style={{ cursor: "pointer" }}
            >
              <Avatar {...this.props} />
            </Link>
          ) : (
            <Avatar {...this.props} />
          )}
        </div>
        <div style={{ display: "flex", flexDirection: "column" }}>
          {(companyName || name) && (
            <h3 style={{ fontSize: 14, margin: "0 0 2px" }}>
              {link ? (
                <Link
                  className="h6-5 font-weight-bold text-dark text-decoration-none"
                  to={link}
                  style={{ cursor: "pointer" }}
                >
                  {companyName || name}
                </Link>
              ) : (
                companyName || name
              )}
            </h3>
          )}
          {title && (
            <p style={{ fontSize: 13, margin: 0 }} className="text-muted">
              {title}
            </p>
          )}
          {certificateImage && certificateName && (
            <>
              <Popover
                placement="right"
                isOpen={this.state.isOpen}
                target={`viewCertificate${id}`}
                toggle={this.toggle}
                className="account-in-salesforce"
              >
                <PopoverBody>
                  <div className="p-2" ref={this.popOverRef}>
                    <div
                      className="ml-auto cursor-pointer"
                      onClick={() => this.toggle()}
                    >
                      <div className="d-flex justify-content-end">
                        <i className="fa fa-close" />
                      </div>
                      {data &&
                        data.map((value, index) => (
                          <React.Fragment key={index}>
                            <div className="row">
                              <img
                                src={value.image}
                                alt="No Image"
                                style={{
                                  width: "25px",
                                  height: "25px",
                                  borderRadius: "50%",
                                  marginRight: "5px"
                                }}
                              />
                              <p style={{ fontSize: 13 }}>{value.name}</p>
                            </div>
                          </React.Fragment>
                        ))}
                    </div>
                  </div>
                </PopoverBody>
              </Popover>

              <div
                id={`viewCertificate${id}`}
                className="cursor-pointer d-flex align-items-center"
              >
                <img
                  src={certificateImage}
                  alt="No Image"
                  style={{
                    width: "25px",
                    height: "25px",
                    borderRadius: "50%",
                    marginTop: "5px",
                    marginRight: "5px"
                  }}
                />
                <p style={{ fontSize: 13, marginTop: 5 }}>
                  {certificateName}{" "}
                  <span className="text-primary font-weight-bold ml-0">
                    {courseCount ? `+${courseCount}` : ""}
                  </span>
                </p>
              </div>
            </>
          )}
        </div>
      </div>
    );
  }
}

AvatarCard.propTypes = {
  url: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  title: PropTypes.string,
  defaultUrl: PropTypes.string,
  size: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  square: PropTypes.bool,
  customSize: PropTypes.number,
  certificateImage: PropTypes.string,
  certificateName: PropTypes.string
};

export default AvatarCard;
