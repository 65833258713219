import React from "react";
import AddCollectionForm from "./AddCollectionForm";

// Components
import PageTitle from "../../components/base/PageTitle";

const AddCollection = props => {
  return (
    <div>
      <PageTitle label="Add Collection" />
      <AddCollectionForm history={props.history} />
    </div>
  );
};

export default AddCollection;
