import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import toast from "../../../components/base/Toast";
import { DashboardFeedbackIcon } from "../../../assets/img/icons";

// Assets
import iconCheckCircle from "../../../assets/img/mock_imgs/icon-check-circle.png";

// Component
import TextArea from "../../../components/base/TextArea";
import Form from "../../../components/base/Form";
import CancelButton from "../../../components/base/CancelButton";

// Helper
import { toString } from "../../../lib/helper";

//API call
import { apiClient } from "../../../apiClient";

// Configs
import { endpoints } from "../../../configs";
import { isBadRequest } from "../../../common/http";

// Feedback Create API Call
function FeedbackCreate(data, toggleSuccessModal, toggle) {
  return apiClient
    .post(endpoints().partnerFeedbackAPI, data)
    .then(response => {
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toggle();
      toggleSuccessModal();
    })
    .catch(error => {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toggle();
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    });
}
const SuccessModal = props => {
  const { modal, toggle } = props;
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="custom-modal-popup"
        backdrop="static"
      >
        <ModalHeader toggle={toggle} className="justify-content-center">
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <div className="img-wrapper d-flex justify-content-center mb-2 mt-3">
                <img className="w-25" src={iconCheckCircle} alt="" />
              </div>
              <p className="text-center font-weight-bold mb-3 ">
                Feedback Completed
              </p>
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.content-wrapper */}
        </ModalHeader>

        <ModalBody className="custom-modal-body">
          <div className="mt-2 mb-3">
            <div>
              <p className="text-center h6-5 mb-2">
                Thank you for your feedback and helping us improve your
                experience!
              </p>
            </div>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

const FeedbackModal = props => {
  const { modal, toggle } = props;
  const [toggleSuccess, setToggleSuccess] = useState(false);

  const toggleSuccessModal = () => setToggleSuccess(!toggleSuccess);
  return (
    <>
      <SuccessModal modal={toggleSuccess} toggle={toggleSuccessModal} />
      <Modal
        isOpen={modal}
        toggle={toggle}
        className="custom-modal-popup"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <div className="img-wrapper d-flex justify-content-center mb-2 mt-3">
                <DashboardFeedbackIcon />
              </div>
              <p className="text-center font-weight-bold mb-3 ">
                Send Feedback
              </p>
            </div>
            {/* /.icon-wrapper */}
            <p className="text-center h6-5 mb-2">
              Tell us about your experience and let us know how we can improve
              our product
            </p>
          </div>
          {/* /.content-wrapper */}
        </ModalHeader>

        <Form
          initialValues={{
            feedback: ""
          }}
          onSubmit={values => {
            values.feedback = toString(values.feedback);
            FeedbackCreate(values, toggleSuccessModal, toggle);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div>
                <TextArea
                  name="feedback"
                  placeholder="Enter your suggestions here..."
                  rows="5"
                  error="Your message is required"
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <div className="btn-wrapper">
                  <CancelButton
                    className="btn btn-outline-secondary mr-2"
                    onClick={toggle}
                  />
                  <Button type="submit">Send Feedback</Button>
                </div>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default FeedbackModal;
