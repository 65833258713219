import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  UncontrolledDropdown,
  NavLink
} from "reactstrap";
import { UserIcon } from "../../assets/img/icons";
import Avatar from "../base/Avatar";

import {
  isCustomer,
  isExpert,
  isSuperAdmin,
  isCompanyAdmin,
  isPartner,
  isCompanyManager
} from "../../lib/helper";
import UserContext from "../../context/userContext/UserContext";
// Constants
import { TAB_COMPANIES } from "../../supportPortal/Constants";

import { useSelector } from "react-redux";

const UserNavDropdown = React.memo(function UserNavDropdown(props) {
  const { headerTextColor, enablePartnerPayments, enable, id, roleId } = props;

  const loginTextColor = headerTextColor
    ? headerTextColor
    : !headerTextColor
    ? "text-dark"
    : "";

  const currentUser = useSelector(state => state.user);

  return (
    <div
      id="avatarDiv"
      className="dropdown-wrapper ml-auto"
      style={{ zIndex: "13" }}
    >
      <UserContext.Consumer>
        {context => (
          <>
            {context.userLoggedIn ? (
              <UncontrolledDropdown inNavbar>
                <DropdownToggle nav className="p-0">
                  <div
                    className={`d-flex justify-content-between align-items-center ${
                      isPartner(roleId) ? "pr-5" : ""
                    }`}
                  >
                    <div className="pr-2" style={{ color: loginTextColor }}>
                      {isPartner(roleId) && context.user.partnerName}
                    </div>
                    {context.token && (
                      <Avatar
                        id="avatar"
                        firstName={context.user.firstName}
                        lastName={context.user.lastName}
                        size="xs"
                        fontSize={12}
                        url={context.user.avatarUrl}
                        //headerTextColor={headerTextColor}
                      />
                    )}
                  </div>
                </DropdownToggle>
                <DropdownMenu right>
                  <DropdownItem tag={"li"} className={"edit-profile-item"}>
                    {context.token && (
                      <Avatar
                        id={id}
                        firstName={context.user.firstName}
                        lastName={context.user.lastName}
                        size="customSize"
                        imageSize={"50"}
                        url={context.user.avatarUrl}
                        //headerTextColor={headerTextColor}
                      />
                    )}
                    <div className="edit-profile-actions">
                      <div className="edit-profile-name">
                        <strong>
                          {context.user.firstName} {context.user.lastName}
                        </strong>
                      </div>
                      {(isExpert(currentUser && currentUser.roleId) && (
                        <Link
                          id={id}
                          to={{
                            pathname: "/edit/public-profile",
                            state: {
                              expertId: context.user.expertId
                            }
                          }}
                          className={[
                            "edit-profile-name",
                            "d-block",
                            `${!props.enable ? "disabled" : ""}`
                          ].join(" ")}
                        >
                          Edit Public Profile
                        </Link>
                      )) || (
                        <Link
                          id={id}
                          to={{
                            pathname: "/edit/profile",
                            state: {
                              partnerId: context.user.userId
                            }
                          }}
                          className={[
                            "edit-profile-name",
                            "d-block",
                            `${!props.enable ? "disabled" : ""}`
                          ].join(" ")}
                        >
                          Edit Profile
                        </Link>
                      )}
                    </div>
                  </DropdownItem>
                  <DropdownItem divider />
                  <div>
                    <Link
                      id={id}
                      to="/dashboard"
                      style={{
                        color: "inherit",

                        textDecoration: "none"
                      }}
                      className={[
                        "edit-profile-name",
                        "text-decoration-none",
                        "text-dark d-block",
                        `${
                          (isExpert(currentUser && currentUser.roleId) ||
                            isPartner(currentUser && currentUser.roleId)) &&
                          !props.enable
                            ? "disabled"
                            : ""
                        }`
                      ].join(" ")}
                    >
                      <DropdownItem>My Dashboard</DropdownItem>
                    </Link>
                  </div>

                  {isCustomer(currentUser && currentUser.roleId) ? (
                    <div>
                      <Link
                        id={id}
                        to="/company/settings"
                        className="text-decoration-none text-dark"
                      >
                        <DropdownItem>Company Settings</DropdownItem>
                      </Link>
                      <Link
                        id={id}
                        to="/user-management"
                        className="text-decoration-none text-dark"
                      >
                        <DropdownItem>User Management</DropdownItem>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}

                  {(isSuperAdmin(currentUser && currentUser.roleId) ||
                    isCompanyAdmin(currentUser && currentUser.roleId) ||
                    isCompanyManager(currentUser && currentUser.roleId)) && (
                    <div>
                      <Link
                        id={id}
                        to="/admin-settings"
                        style={{
                          color: "inherit",

                          textDecoration: "none"
                        }}
                      >
                        <DropdownItem>Admin Settings</DropdownItem>
                      </Link>
                    </div>
                  )}

                  {isSuperAdmin(currentUser && currentUser.roleId) && (
                    <div>
                      <Link
                        id={id}
                        to={`/support-portal/${TAB_COMPANIES}`}
                        style={{
                          color: "inherit",

                          textDecoration: "none"
                        }}
                      >
                        <DropdownItem>Support Portal</DropdownItem>
                      </Link>
                    </div>
                  )}

                  {isCustomer(currentUser && currentUser.roleId) && (
                    <>
                      <Link
                        id={id}
                        to="/accounting-settings"
                        style={{
                          color: "inherit",
                          textDecoration: "none"
                        }}
                      >
                        <DropdownItem>Accounting Settings</DropdownItem>
                      </Link>
                    </>
                  )}
                  <DropdownItem divider />
                  <Link
                    id={id}
                    to=""
                    onClick={() =>
                      context.logoutUser(currentUser && currentUser.roleId)
                    }
                    className="edit-profile-logout text-danger"
                    style={{ textDecoration: "none" }}
                  >
                    <DropdownItem id="signout">Sign Out</DropdownItem>
                  </Link>
                </DropdownMenu>
              </UncontrolledDropdown>
            ) : (
              <NavLink
                id={id}
                href={"/login"}
                className={[
                  `${loginTextColor}`,
                  "font-weight-bold",
                  "d-inline-flex",
                  "login-btn",
                  "h6-5"
                ].join(" ")}
                style={{
                  color: headerTextColor,
                  textDecoration: "none"
                }}
              >
                <span id={id} className={["mr-2"].join(" ")}>
                  Portal Login
                </span>{" "}
                <UserIcon />
              </NavLink>
            )}
          </>
        )}
      </UserContext.Consumer>
    </div>
  );
});

export default UserNavDropdown;
