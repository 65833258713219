import React from "react";
import { clearAllCookies } from "../../lib/cookie";
import { clearAllSettingsCookies } from "../../lib/settingsCookie";

const Logout = props => {
  clearAllCookies();
  clearAllSettingsCookies();

  return <>{props.history.push("/login")}</>;
};

export default Logout;
