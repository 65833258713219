class Video {
  static isValid = url => {
    const youtubeRegex = /^(https?\:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/;
    const vimeoRegex = /^(https?\:\/\/)?(www\.)?(vimeo\.com)\/.+$/;
    const isValid = youtubeRegex.test(url) || vimeoRegex.test(url);
    return isValid;
  };
}

export default Video;
