import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import { COMPANY_STATUS_APPROVED } from "../Company/Constants";
class companyService {
  static async list() {
    try {
      let dataArray = [];
      let response = await apiClient.get(
        `${
          endpoints().companyAPI
        }?status=${COMPANY_STATUS_APPROVED}&isDefaultCompany=${true}`
      );
      let data = response && response.data && response.data.data;

      if (data && data.length > 0) {
        for (let i = 0; i < data.length; i++) {
          dataArray.push({
            label: data[i].companyName,
            value: data[i].id
          });
        }
      }
      return dataArray;
    } catch (err) {
      console.log(err);
    }
  }
}
export default companyService;
