import React, { useState } from "react";

//Base Components
import Form from "../../../components/base/Form";
import PageTitle from "../../../components/base/PageTitle";
import Radio from "../../../components/base/Radio";

//Components
import DefaultContent from "../../../components/content/DefaultContent";

//SalesRouting Constants
import {
  DEAL_OWNER_IN_APPROVAL_WORKFLOW,
  DEAL_OWNER_TO_COMPANY_OWNER_FOR_EXISTING_PROSPECT,
  DEAL_OWNER_TO_INTEGRATION_USER_FOR_EXISTING_PROSPECT,
  DEAL_OWNER_TO_INTEGRATION_USER_FOR_NEW_PROSPECT
} from "../Constants";

const SalesRouting = props => {
  const { adminSettings, saveSettings } = props;

  // Handle Deal for an Existing Prospect Company
  const handleExistingSalesRouting = enable_deal_owner_for_existing_prospect_company => {
    const data = new FormData();

    if (enable_deal_owner_for_existing_prospect_company !== undefined) {
      data.append(
        "enable_deal_owner_for_existing_prospect_company",
        enable_deal_owner_for_existing_prospect_company ===
          DEAL_OWNER_TO_COMPANY_OWNER_FOR_EXISTING_PROSPECT
          ? "true"
          : "false"
      );
    }

    // Save settings
    saveSettings(data);
  };

  // Saved deal for an Existing Prospect company
  const enableOpportunityForExistingProspect =
    adminSettings &&
    adminSettings.enable_deal_owner_for_existing_prospect_company;

  // Handle Deal for an New Prospect Company
  const handleNewSalesRouting = enable_deal_for_new_prospect_company => {
    const data = new FormData();

    if (enable_deal_for_new_prospect_company !== undefined) {
      data.append(
        "enable_deal_for_new_prospect_company",
        enable_deal_for_new_prospect_company ===
          DEAL_OWNER_TO_INTEGRATION_USER_FOR_NEW_PROSPECT
          ? "true"
          : "false"
      );
    }

    // Save settings
    saveSettings(data);
  };

  // Saved Deal for an New Prospect Account
  const enableDealForNewProspect =
    adminSettings && adminSettings.enable_deal_for_new_prospect_company;

  return (
    <>
      <Form
        onSubmit={values => {
          this.submit(values);
        }}
      >
        <PageTitle label="Sales Routing" />
        <DefaultContent>
          <div className="form-wrapper">
            <label className="font-weight-bold">
              When Creating an Deal for an Existing Prospect Company
            </label>
            <div className="field-wrapper ml-4 mb-0">
              <Radio
                name="opportunity_for_new_prospect"
                size="large"
                options={[
                  DEAL_OWNER_TO_COMPANY_OWNER_FOR_EXISTING_PROSPECT,
                  DEAL_OWNER_TO_INTEGRATION_USER_FOR_EXISTING_PROSPECT
                ]}
                onChange={e => {
                  handleExistingSalesRouting(e.target.value);
                }}
                defaultValue={
                  enableOpportunityForExistingProspect &&
                  enableOpportunityForExistingProspect === "false"
                    ? DEAL_OWNER_TO_INTEGRATION_USER_FOR_EXISTING_PROSPECT
                    : DEAL_OWNER_TO_COMPANY_OWNER_FOR_EXISTING_PROSPECT
                }
              />
            </div>
          </div>
          <div className="form-wrapper">
            <label className="font-weight-bold">
              When Creating an Deal for an New Prospect Company
            </label>
            <div className="field-wrapper ml-4 mb-0">
              <Radio
                name="deal_for_existing_prospect"
                size="large"
                options={[
                  DEAL_OWNER_TO_INTEGRATION_USER_FOR_NEW_PROSPECT,
                  DEAL_OWNER_IN_APPROVAL_WORKFLOW
                ]}
                onChange={e => {
                  handleNewSalesRouting(e.target.value);
                }}
                defaultValue={
                  enableDealForNewProspect &&
                  enableDealForNewProspect === "false"
                    ? DEAL_OWNER_IN_APPROVAL_WORKFLOW
                    : DEAL_OWNER_TO_INTEGRATION_USER_FOR_NEW_PROSPECT
                }
              />
            </div>
          </div>
        </DefaultContent>
      </Form>
    </>
  );
};

export default SalesRouting;
