import React from "react";
import ToggleSwitch from "../base/ToggleSwitch";
import { Field } from "formik";
import SaveButton from "../../components/base/SaveButton";

const ExpertProfileVisibilitySettings = ({
  expertId,
  showProfileVisibility,
  setSubmitState,
  id
}) => (
  <div className={["set-expert-visibility"].join(" ")}>
    {showProfileVisibility ? (
      <Field
        name="profile_visibility"
        render={({ field, form }) => {
          return (
            <ToggleSwitch
              id={id}
              name={"profile_visibility"}
              label1={"Your profile's public visibility"}
              label2={"Your profile's public visibility"}
              value={field.value}
              handleChange={() =>
                form.setFieldValue("profile_visibility", !field.value)
              }
              outlined
            />
          );
        }}
      />
    ) : (
      <div className="flex-row" />
    )}
    <div className="btn-wrapper">
      <a
        id={id}
        href={`/expert-profile/${expertId}`}
        target="_blank"
        className={["btn", "btn-outline-secondary", "mr-2"].join(" ")}
        rel="noopener noreferrer"
      >
        View Public Profile
      </a>

      <SaveButton
        id={id}
        type="submit"
        className={["btn", "btn-primary"].join(" ")}
        onClick={() => {
          setSubmitState();
        }}
      >
        Save Profile
      </SaveButton>
    </div>
  </div>
);

export default ExpertProfileVisibilitySettings;
