import React, { useState } from "react";

// Inner Components
import SidebarItem from "./sidebar-items/SidebarItem";
import EditSubcriptionStatusModal from "./EditSubcriptionStatusModal";

// Helper
import {
  isCustomer,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../../lib/helper";

// Constants
import {
  PROJECT_SUBSCRIPTION_STATUS_ACTIVE,
  PROJECT_SUBSCRIPTION_STATUS_CANCELLED,
  PROJECT_SUBSCRIPTION_STATUS_PAUSED
} from "../../../project/Constants";

// Icons
import { EditIconAlt } from "../../../assets/img/icons";

import { useSelector } from "react-redux";

const ProjectSubcription = ({
  paymentType,
  subscriptionStatus,
  formattedSubscriptionStartDate,
  formattedSubscriptionEndDate,
  updateProjectSubscriptionStatus,
  subcriptionStatusModal,
  setSubcriptionStatusModal
}) => {
  const [projectSubscriptionStatus, setProjectSubscriptionStatus] = useState(
    subscriptionStatus
  );

  const currentUser = useSelector(state => state.user);

  let subscriptionStatus1 = {
    label: subscriptionStatus || "",
    value: subscriptionStatus || ""
  };

  // Subscription Status Options
  const subscriptionStatusOptions = [
    {
      label: PROJECT_SUBSCRIPTION_STATUS_ACTIVE,
      value: PROJECT_SUBSCRIPTION_STATUS_ACTIVE
    },
    {
      label: PROJECT_SUBSCRIPTION_STATUS_PAUSED,
      value: PROJECT_SUBSCRIPTION_STATUS_PAUSED
    },
    {
      label: PROJECT_SUBSCRIPTION_STATUS_CANCELLED,
      value: PROJECT_SUBSCRIPTION_STATUS_CANCELLED
    }
  ];

  return (
    paymentType &&
    (isCustomer(currentUser && currentUser.roleId) ||
      isSuperAdmin(currentUser && currentUser.roleId) ||
      isCompanyAdmin(currentUser && currentUser.roleId) ||
      isCompanyManager(currentUser && currentUser.roleId)) && (
      <>
        <SidebarItem>
          <p className="font-weight-bold">Subscription</p>

          <div className="supporting-materials">
            <div className="supporting-material">
              <div className="field-wrapper">
                <p className="font-weight-bold">Status</p>
                <span className="d-block text-grayed font-weight-bold">
                  {projectSubscriptionStatus ? projectSubscriptionStatus : ""}
                </span>
              </div>
              <div className="action">
                <a
                  href="javascript:void(0)"
                  onClick={e => {
                    e.preventDefault();
                    setSubcriptionStatusModal();
                  }}
                >
                  <EditIconAlt />
                </a>
              </div>
            </div>

            {/* Change Subsscription Modal */}
            {subcriptionStatusModal &&
              (isCustomer(currentUser && currentUser.roleId) ||
                isSuperAdmin(currentUser && currentUser.roleId) ||
                isCompanyAdmin(currentUser && currentUser.roleId) ||
                isCompanyManager(currentUser && currentUser.roleId)) && (
                <EditSubcriptionStatusModal
                  modal={subcriptionStatusModal}
                  setModal={setSubcriptionStatusModal}
                  subscriptionStatusOptions={subscriptionStatusOptions}
                  subscriptionStatus={subscriptionStatus1}
                  updateProjectSubscriptionStatus={
                    updateProjectSubscriptionStatus
                  }
                  setProjectSubscriptionStatus={setProjectSubscriptionStatus}
                />
              )}
          </div>

          {/* Start Date */}
          {formattedSubscriptionStartDate && (
            <div className="supporting-materials">
              <div className="supporting-material">
                <div className="field-wrapper">
                  <p className="font-weight-bold">Start Date</p>
                  <span className="d-block text-grayed font-weight-bold">
                    {formattedSubscriptionStartDate
                      ? formattedSubscriptionStartDate
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          )}

          {/* End Date */}
          {formattedSubscriptionEndDate && (
            <div className="supporting-materials">
              <div className="supporting-material">
                <div className="field-wrapper">
                  <p className="font-weight-bold">End Date</p>
                  <span className="d-block text-grayed font-weight-bold">
                    {formattedSubscriptionEndDate
                      ? formattedSubscriptionEndDate
                      : ""}
                  </span>
                </div>
              </div>
            </div>
          )}
        </SidebarItem>
      </>
    )
  );
};

export default ProjectSubcription;
