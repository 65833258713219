import React from "react";
import PropTypes from "prop-types";
import PartnerCard from "./PartnerCard";
import { connect } from "react-redux";
import { getKeyValueByObject } from "../lib/helper";
import { SETTINGS_THEME_LAYOUT } from "../setting/Constants";

class PartnerGrid extends React.Component {
  state = {
    partners: this.props.partnerList
  };

  componentDidUpdate(prevProps, prevState, snapshot) {
    const { partnerList, updatedPartnerList } = this.props;

    const prevPartnerList = JSON.stringify(prevProps.partnerList);
    const prevUpdatedPartnerList = JSON.stringify(prevProps.updatedPartnerList);

    // Update partner list
    if (prevPartnerList !== JSON.stringify(partnerList)) {
      this.setState({ partners: partnerList });
    } else if (prevUpdatedPartnerList !== JSON.stringify(updatedPartnerList)) {
      this.setState({ partners: updatedPartnerList });
    }
  }

  render() {
    const {
      selectedPartners,
      handlePartners,
      selectPartners,
      certified,
      openLinkInNewTab,
      showDeleteIcon,
      currentUrl,
      partnerIds,
      size,
      id,
      settings
    } = this.props;

    const DefaultLayout = settings
      ? getKeyValueByObject(settings, SETTINGS_THEME_LAYOUT)
      : "";

    const { partners } = this.state;

    // const partners = updatedPartnerList || partnerList;

    return (
      <div className="row">
        {partners &&
          partners.length > 0 &&
          partners.map(partner =>
            selectedPartners ? (
              (selectPartners
                ? selectedPartners.indexOf(partner.id) === -1
                : selectedPartners.indexOf(partner.id) > -1) && (
                <PartnerCard
                  id={id}
                  classnames=""
                  data={partner}
                  selectPartners={selectPartners}
                  selected={
                    partnerIds &&
                    partnerIds.indexOf(parseInt(partner.id, 10)) > -1
                  }
                  size={size ? size : "col-12 col-md-12 col-lg-6 col-xl-4"}
                  key={partner.id}
                  certified={certified}
                  showDeleteIcon={showDeleteIcon}
                  handlePartners={handlePartners}
                  link={
                    currentUrl
                      ? `/partner-profile/${
                          partner.companyProfileUrl
                            ? partner.companyProfileUrl
                            : partner.id
                        }`
                      : ""
                  }
                  openLinkInNewTab={openLinkInNewTab}
                />
              )
            ) : (
              <PartnerCard
                id={id}
                classnames={DefaultLayout !== "Fluid Width" ? "m-2" : "m-4"}
                data={partner}
                key={partner.id}
                certified={certified}
                handlePartners={handlePartners}
                link={
                  currentUrl
                    ? `/partner-profile/${
                        partner.companyProfileUrl
                          ? partner.companyProfileUrl
                          : partner.id
                      }`
                    : ""
                }
                openLinkInNewTab={openLinkInNewTab}
              />
            )
          )}
      </div>
    );
  }
}

PartnerGrid.propTypes = {
  partnerList: PropTypes.string,
  selectedPartners: PropTypes.string,
  selectPartners: PropTypes.string,
  openLinkInNewTab: PropTypes.string,
  removePartner: PropTypes.string,
  currentUrl: PropTypes.string,
  id: PropTypes.string,
  data: PropTypes.string,
  size: PropTypes.string,
  handlePartners: PropTypes.func,
  certified: PropTypes.string
};

const mapStateToProps = state => {
  const { approvedPartners } = state.table;
  return {
    updatedPartnerList:
      approvedPartners && !approvedPartners.isFetching
        ? approvedPartners["1"]
        : ""
  };
};

export default connect(mapStateToProps)(PartnerGrid);
