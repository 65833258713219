import React, { useEffect, useState } from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";

import SaveButton from "../../../../components/base/SaveButton";

// Helper
import PartnerService from "../../../../services/partnerService";
import Select from "../../../../components/Select";

const General = props => {
  const { adminSettings, saveSettings, history } = props;

  const [partnerList, setPartnerList] = useState([]);

  useEffect(() => {
    getPartnerList();
  }, []);

  const { aws_cloud_partner } = adminSettings;

  const getPartnerList = async () => {
    const response = await PartnerService.List();
    setPartnerList(response);
  };
  // General Settings Expert Section Initial Values
  const initialValues = {
    aws_cloud_partner: partnerList.find(data => data.value == aws_cloud_partner)
  };

  // General Settings Expert Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Default Expert Title
    if (values.aws_cloud_partner !== undefined) {
      data.append(
        "aws_cloud_partner",
        values.aws_cloud_partner && values.aws_cloud_partner.value
          ? values.aws_cloud_partner.value
          : ""
      );
    }
    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={initialValues}
      enableReinitialize
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          {/* Find an Expert */}
          <h5 className="font-weight-bold">AWS Cloud Partner</h5>
          <div className="field-wrapper">
            <Select
              name={"aws_cloud_partner"}
              label={""}
              partner_id={aws_cloud_partner}
              options={partnerList}
            />
          </div>
        </div>
      </DefaultContent>

      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default General;
