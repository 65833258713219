import React from "react";

// Components
import { InlineInfoIcon } from "../../../../assets/img/icons";
import AlertBanner from "../../../../components/base/AlertBanner";

const DealsConflictToggle = props => {
  const { setDealConflictToggleValues } = props;
  return (
    <>
      <>
        <AlertBanner
          icon={<InlineInfoIcon />}
          buttonLabel=" View Deals in HubSpot"
          id="viewHubSpotOpenDeal"
          removeOnClick={() => {
            setDealConflictToggleValues({
              isHubSpotDealConflictNotification: false
            });
          }}
          message=" Company matched, but there may be an deals conflict."
        />
      </>
    </>
  );
};

export default DealsConflictToggle;
