import React from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Components
import Avatar from "./Avatar";

class ProfileCard extends React.Component {
  render() {
    const {
      title,
      size,
      fontSize,
      firstName,
      avatarUrl,
      profileName,
      redirectUrl,
      id,
      openInNewTab
    } = this.props;
    return (
      <>
        <p className="font-weight-bold">{title}</p>
        <div className="field-wrapper">
          <div className="user-wrapper">
            <div className="field-icon">
              {redirectUrl ? (
                <Link
                  target={openInNewTab ? "_blank" : "_self"}
                  to={redirectUrl}
                >
                  <LazyLoad>
                    <Avatar
                      id={id}
                      firstName={firstName ? firstName : ""}
                      size={size ? size : "xs"}
                      fontSize={fontSize ? fontSize : 12}
                      url={avatarUrl ? avatarUrl : ""}
                    />
                  </LazyLoad>
                </Link>
              ) : (
                <LazyLoad>
                  <Avatar
                    id={id}
                    firstName={firstName ? firstName : ""}
                    size={size ? size : "xs"}
                    fontSize={fontSize ? fontSize : 12}
                    url={avatarUrl ? avatarUrl : ""}
                  />
                </LazyLoad>
              )}
            </div>

            <div className="expert-info ml-2 d-flex flex-column align-items-start">
              <p className="font-weight-bold">
                {redirectUrl ? (
                  <Link
                    id={id}
                    target={openInNewTab ? "_blank" : "_self"}
                    to={redirectUrl}
                  >
                    {profileName ? profileName : ""}
                  </Link>
                ) : (
                  <span>{profileName ? profileName : ""}</span>
                )}
              </p>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default ProfileCard;
