import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// 404 Page
import Page404 from "../Page404";

// Components
import Form from "../../components/base/Form";
import DeleteButton from "../../components/base/DeleteButton";
import CancelButton from "../../components/base/CancelButton";
import SaveButton from "../../components/base/SaveButton";
import LandingPageBlockFormFields from "./LandingPageBlockFormFields";
import PlaybookCard from "../../components/PlaybookCard";
import Spinner from "../../components/base/Spinner";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";

// Constants
import {
  LANDING_PAGE_BLOCK_TYPE_EXPERT,
  LANDING_PAGE_BLOCK_TYPE_PARTNER,
  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK,
  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID
} from "../../landingPage/Constants";
import { PLAYBOOK_STATUS_ACTIVE } from "../../playbookStatus/Constants";
import { EXPERT_STATUS_APPROVED } from "../../expertStatus/Constants";

// Actions
import {
  updateLandingPageBlock,
  deleteLandingPageBlock
} from "../../actions/landingPageBlock";
import ExpertGrid from "../../components/ExpertGrid";
import { PARTNER_STATUS_APPROVED } from "../../partnerStatus/Constants";
import PartnerGrid from "../../components/PartnerGrid";
import { isBadRequest } from "../../common/http";

class LandingPageBlocksForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      landingPageBlockDetails: "",
      loading: false,
      backgroundColor: "",
      textColor: "",
      isOpen: "",
      logoLink: "",
      logoImageDesktopUrlPreview: "",
      logoImageDesktopUrl: "",
      logoImageMobileUrlPreview: "",
      logoImageMobileUrl: "",
      backgroundImageDesktopUrl: "",
      backgroundImageMobileUrl: "",
      backgroundImageDesktopUrlPreview: "",
      backgroundImageMobileUrlPreview: "",
      backgroundImageDesktopUrl1: "",
      backgroundImageMobileUrl1: "",
      backgroundImageDesktopUrl1Preview: "",
      backgroundImageMobileUrl1Preview: "",
      isParnerLoading: false,
      partnerList: [],
      selectedPartners: [],
      partnerIds: [],
      initialPartnerIdsLength: 0,
      isExpertLoading: false,
      expertList: [],
      expertIds: [],
      isPlaybookLoading: false,
      playbookList: [],
      playbookIds: [],
      isInvalidRequest: false,
      backgroundColor1: "",
      textColor1: "",
      selectedPlaybooks: [],
      selectedExperts: [],
      initialExpertIdsLength: 0
    };

    this.handleBackgroundColor = this.handleBackgroundColor.bind(this);
    this.handleTextColor = this.handleTextColor.bind(this);
    this.handleBackgroundColor1 = this.handleBackgroundColor1.bind(this);
    this.handleTextColor1 = this.handleTextColor1.bind(this);
    this.handleImageChange = this.handleImageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.handlePartners = this.handlePartners.bind(this);
    this.handleExperts = this.handleExperts.bind(this);
    this.handlePlaybooks = this.handlePlaybooks.bind(this);
    this.handleContrastColorSelect = this.handleContrastColorSelect.bind(this);
  }

  componentDidMount = () => {
    window.scrollTo(0, 0);

    this._getLandingPageDetails();
    this._getPartnerList();
    this._getExpertsList();
    this._getPlaybooksList();
  };

  // Update Landing page blocks
  _submit = values => {
    // Save landing page block form
    this.landingPageBlockUpdate(this._toArray(values));
  };

  // Handle partners
  handlePartners(value) {
    const partnerIds = this.state.partnerIds.slice();
    if (partnerIds.indexOf(value) > -1) {
      this.setState({ partnerIds: partnerIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ partnerIds: partnerIds.concat(value) });
    }
  }

  handleRemovePartners = value => {
    const partnerIds = this.state.partnerIds.slice();
    if (partnerIds.indexOf(value) > -1) {
      this.setState(
        { partnerIds: partnerIds.filter(el => el !== value) },
        () => {
          this.setState({ selectedPartners: this.state.partnerIds });
        }
      );
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ partnerIds: partnerIds.concat(value) });
    }
  };

  handleSelectedPartners = () => {
    this.toggle();
    const partnerIds = this.state.partnerIds;
    const selectedPartners = partnerIds.length > 0 ? partnerIds : [];
    this.setState({ selectedPartners });
  };

  // Handle experts
  handleExperts(value) {
    const expertIds = this.state.expertIds.slice();
    if (expertIds.indexOf(value) > -1) {
      this.setState({ expertIds: expertIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ expertIds: expertIds.concat(value) });
    }
  }
  // Handle experts
  handleRemoveExperts = value => {
    const expertIds = this.state.expertIds.slice();
    if (expertIds.indexOf(value) > -1) {
      this.setState({ expertIds: expertIds.filter(el => el !== value) }, () => {
        this.setState({ selectedExperts: this.state.expertIds });
      });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ expertIds: expertIds.concat(value) });
    }
  };

  handleSelectedExperts = () => {
    this.toggle();
    const expertIds = this.state.expertIds;
    const selectedExperts = expertIds.length > 0 ? expertIds : [];
    this.setState({ selectedExperts });
  };

  // Handle playbooks
  handlePlaybooks(value) {
    const playbookIds = this.state.playbookIds.slice();
    if (playbookIds.indexOf(value) > -1) {
      this.setState({ playbookIds: playbookIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ playbookIds: playbookIds.concat(value) });
    }
  }

  // Handle remove playbooks
  handleRemovePlaybooks = value => {
    const playbookIds = this.state.playbookIds.slice();
    if (playbookIds.indexOf(value) > -1) {
      this.setState(
        { playbookIds: playbookIds.filter(el => el !== value) },
        () => {
          this.setState({ selectedPlaybooks: this.state.playbookIds });
        }
      );
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ playbookIds: playbookIds.concat(value) });
    }
  };

  handleSelectedPlaybooks = () => {
    this.toggle();
    const playbookIds = this.state.playbookIds;
    const selectedPlaybooks = playbookIds.length > 0 ? playbookIds : [];
    this.setState({ selectedPlaybooks });
  };

  // To Array
  _toArray(values) {
    const data = new FormData();
    data.append(
      "backgroundColor",
      this.state.backgroundColor ? this.state.backgroundColor : ""
    );
    data.append("title", values.title ? values.title : "");
    data.append("link", values.link ? values.link : "");
    data.append("subtitle", values.subtitle ? values.subtitle : "");
    data.append("textColor", this.state.textColor ? this.state.textColor : "");
    data.append("buttonLabel", values.buttonLabel ? values.buttonLabel : "");
    data.append("buttonLink", values.buttonLink ? values.buttonLink : "");
    data.append(
      "partnerIds",
      this.state.partnerIds ? this.state.partnerIds : values.partnerIds
    );
    data.append(
      "expertIds",
      this.state.expertIds ? this.state.expertIds : values.expertIds
    );
    data.append(
      "playbookIds",
      this.state.playbookIds ? this.state.playbookIds : values.playbookIds
    );
    data.append(
      "backgroundImageDesktop",
      this.state.backgroundImageDesktopUrl
        ? this.state.backgroundImageDesktopUrl
        : ""
    );
    data.append(
      "backgroundImageMobile",
      this.state.backgroundImageMobileUrl
        ? this.state.backgroundImageMobileUrl
        : ""
    );
    data.append(
      "backgroundImageMobileFile",
      this.state.backgroundImageMobileUrlPreview
        ? this.state.backgroundImageMobileUrlPreview
        : ""
    );
    data.append(
      "backgroundImageDesktop1",
      this.state.backgroundImageDesktopUrl1
        ? this.state.backgroundImageDesktopUrl1
        : ""
    );
    data.append(
      "backgroundImageMobile1",
      this.state.backgroundImageMobileUrl1
        ? this.state.backgroundImageMobileUrl1
        : ""
    );
    data.append(
      "backgroundImageMobileFile1",
      this.state.backgroundImageMobileUrl1Preview
        ? this.state.backgroundImageMobileUrl1Preview
        : ""
    );
    data.append("logoLink", values.logoLink ? values.logoLink : "");
    data.append(
      "logoImageDesktop",
      this.state.logoImageDesktopUrl ? this.state.logoImageDesktopUrl : ""
    );
    data.append(
      "logoImageMobile",
      this.state.logoImageMobileUrl ? this.state.logoImageMobileUrl : ""
    );
    data.append(
      "logoImageMobileFile",
      this.state.logoImageMobileUrlPreview
        ? this.state.logoImageMobileUrlPreview
        : ""
    );
    data.append("title1", values.title1 ? values.title1 : "");
    data.append("link1", values.link1 ? values.link1 : "");
    data.append("subtitle1", values.subtitle1 ? values.subtitle1 : "");
    data.append(
      "backgroundColor1",
      this.state.backgroundColor1 ? this.state.backgroundColor1 : ""
    );
    data.append(
      "textColor1",
      this.state.textColor1 ? this.state.textColor1 : ""
    );
    data.append("content", values.content ? values.content : "");

    return data;
  }

  // Landing page API Call
  landingPageBlockUpdate(data) {
    const { landingPageId } = this.state.landingPageBlockDetails;
    this.props.actions.updateLandingPageBlock(
      this.props.match.params.id,
      data,
      () => {}
    );
  }

  // Landing page api call by id
  _getLandingPageDetails = () => {
    return apiClient
      .get(`${endpoints().landingPageBlocksAPI}/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        const data = response.data;
        const partnerIds = data.partnerIds ? data.partnerIds.split(",") : [];
        const expertIds = data.expertIds ? data.expertIds.split(",") : [];
        const playbookIds = data.playbookIds ? data.playbookIds.split(",") : [];

        const partners = [];
        partnerIds.forEach(partnerId => {
          partners.push(parseInt(partnerId, 10));
        });

        const experts = [];
        expertIds.forEach(expertId => {
          experts.push(parseInt(expertId, 10));
        });

        const playbooks = [];
        playbookIds.forEach(playbook => {
          playbooks.push(parseInt(playbook, 10));
        });
        this.setState(
          {
            landingPageId: data.landingPageId,
            landingPageBlockDetails: data,
            backgroundColor: data.backgroundColor,
            textColor: data.textColor,
            backgroundColor1: data.backgroundColor1,
            textColor1: data.textColor1,
            backgroundImageDesktopUrl: data.backgroundImageDesktopUrl,
            backgroundImageDesktopUrl1: data.backgroundImageDesktopUrl1,
            backgroundImageMobileUrl: data.backgroundImageMobileUrl,
            backgroundImageMobileUrl1: data.backgroundImageMobileUrl1,
            logoLink: data.logoLink,
            logoImageDesktopUrl: data.logoImageDesktopUrl,
            logoImageMobileUrl: data.logoImageMobileUrl,
            loading: true,
            partnerIds: partners,
            selectedPartners: partners,
            expertIds: experts,
            selectedExperts: experts,
            playbookIds: playbooks,
            selectedPlaybooks: playbooks
          },
          () => {
            this.setState({
              initialExpertIdsLength: this.state.expertIds.length,
              initialPartnerIdsLength: this.state.partnerIds.length
            });
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Get Partner List
  _getPartnerList() {
    this.setState({ isParnerLoading: true }, () => {
      apiClient
        .get(
          `${
            endpoints().partnerAPI
          }/search?marketplaceStatus=${PARTNER_STATUS_APPROVED}`
        )
        .then(response => {
          const partnerList = response.data.data;

          this.setState({
            isParnerLoading: false,
            partnerList: partnerList ? partnerList : this.state.partnerList
          });
        });
    });
  }

  // Get Expert List
  _getExpertsList() {
    this.setState({ isExpertLoading: true }, () => {
      apiClient
        .get(
          `${
            endpoints().expertAPI
          }/search?marketplaceStatus=${EXPERT_STATUS_APPROVED}`
        )
        .then(response => {
          const expertList = response.data.data;

          this.setState({
            isExpertLoading: false,
            expertList: expertList ? expertList : this.state.expertList
          });
        });
    });
  }

  // Get Playbook List
  _getPlaybooksList() {
    this.setState({ isPlaybookLoading: true }, () => {
      apiClient
        .get(`${endpoints().playbookAPI}?status=${PLAYBOOK_STATUS_ACTIVE}`)
        .then(response => {
          const playbookList = response.data.data;

          this.setState({
            isPlaybookLoading: false,
            playbookList: playbookList ? playbookList : this.state.playbookList
          });
        });
    });
  }

  // Landing page block delete api call
  handleDelete = () => {
    const { landingPageId } = this.state.landingPageBlockDetails;
    this.props.actions.deleteLandingPageBlock(
      this.props.match.params.id,
      landingPageId,
      this.props.history.push(`/landing-page/edit/${this.state.landingPageId}`)
    );
  };

  // Set image preview in state
  setPreviewImage = (file, state) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      this.setState({ [`${state}Preview`]: reader.result });
    };
  };

  // Update image uploads
  handleImageChange(e) {
    if (e.target.files && e.target.files.length > 0) {
      const file = e.target.files ? e.target.files[0] : "";
      this.setPreviewImage(file, e.target.name);
      this.setState({ [`${e.target.name}`]: file });
    }
  }

  // Remove image
  handleImageRemove = e => {
    this.setState({
      [`${e.target.id}`]: "",
      [`${e.target.id}Preview`]: ""
    });
  };

  // Handle Background Color
  handleBackgroundColor(e) {
    this.setState({ backgroundColor: e.target.id });
  }

  // Handle Text Color
  handleTextColor(e) {
    this.setState({ textColor: e.target.id });
  }

  // Handle Background Color1
  handleBackgroundColor1(e) {
    this.setState({ backgroundColor1: e.target.id });
  }

  // Handle Text Color1
  handleTextColor1(e) {
    this.setState({ textColor1: e.target.id });
  }

  // Handle Text Color High Contrast Version

  handleContrastColorSelect(e) {
    this.setState({ [e.target.name]: e.target.value });
  }

  // Handle Modal Click
  toggle() {
    this.setState({
      isOpen: !this.state.isOpen,
      initialExpertIdsLength: this.state.expertIds.length,
      initialPartnerIdsLength: this.state.partnerIds.length
    });
  }

  // Handle Modal Click
  toggleModalClose() {
    this.setState({
      expertIds: this.state.selectedExperts,
      playbookIds: this.state.selectedPlaybooks,
      isOpen: !this.state.isOpen
    });
  }

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (!this.state.loading) {
      return <Spinner />;
    }

    const currentUrl = window.location.pathname + window.location.search;
    const {
      landingPageBlockDetails,
      expertIds,
      isOpen,
      partnerList,
      selectedPartners,
      partnerIds,
      initialPartnerIdsLength,
      expertList,
      selectedExperts,
      playbookList,
      playbookIds,
      backgroundImageDesktopUrlPreview,
      backgroundImageMobileUrlPreview,
      selectedPlaybooks,
      initialExpertIdsLength,
      logoLink,
      logoImageDesktopUrlPreview,
      logoImageDesktopUrl,
      logoImageMobileUrlPreview,
      logoImageMobileUrl,
      backgroundImageDesktopUrl,
      backgroundImageMobileUrl,
      backgroundImageDesktopUrl1,
      backgroundImageMobileUrl1,
      backgroundImageDesktopUrl1Preview,
      backgroundImageMobileUrl1Preview
    } = this.state;

    // Initialize form values
    const initialValues = {
      title: landingPageBlockDetails.title,
      subtitle: landingPageBlockDetails.subtitle,
      type: landingPageBlockDetails.type,
      sort: landingPageBlockDetails.sort,
      logoUrl: landingPageBlockDetails.logoUrl,
      backgroundImageDesktopUrl:
        landingPageBlockDetails.backgroundImageDesktopUrl,
      backgroundImageMobileUrl:
        landingPageBlockDetails.backgroundImageMobileUrl,
      buttonLabel: landingPageBlockDetails.buttonLabel,
      buttonLink: landingPageBlockDetails.buttonLink,
      playbookIds: landingPageBlockDetails.playbookIds,
      partnerIds: landingPageBlockDetails.partnerIds,
      expertIds: landingPageBlockDetails.expertIds,
      link: landingPageBlockDetails.link,
      title1: landingPageBlockDetails.title1,
      subtitle1: landingPageBlockDetails.subtitle1,
      link1: landingPageBlockDetails.link1,
      backgroundImageDesktopUrl1:
        landingPageBlockDetails.backgroundImageDesktopUrl1,
      backgroundImageMobileUrl1:
        landingPageBlockDetails.backgroundImageMobileUrl1,
      logoLink: landingPageBlockDetails.logoLink,
      logoImageDesktopUrl: landingPageBlockDetails.logoImageDesktopUrl,
      logoImageMobileUrl: landingPageBlockDetails.logoImageMobileUrl,
      content: landingPageBlockDetails.content,
      show_filter:
        landingPageBlockDetails && landingPageBlockDetails.show_filter == "true"
          ? true
          : false,
      show_in_single_line:
        landingPageBlockDetails &&
        landingPageBlockDetails.show_in_single_line == "true"
          ? true
          : false,
      show_partner_type:
        landingPageBlockDetails &&
        landingPageBlockDetails.show_partner_type == "true"
          ? true
          : false,
      show_partner_tier:
        landingPageBlockDetails &&
        landingPageBlockDetails.show_partner_tier == "true"
          ? true
          : false
    };

    return (
      <div>
        <div className="section-title">
          <span className="h1 d-block">
            {landingPageBlockDetails.title}
            <a
              href={`/marketplace/${this.state.landingPageId}`}
              target="blank"
              className="pull-right mt-4"
              style={{ fontSize: "16px" }}
            >
              View Full Preview
            </a>
          </span>
        </div>
        <Form
          initialValues={initialValues}
          onSubmit={values => {
            this._submit(values);
          }}
        >
          <div className="card bg-white mt-3 mb-3">
            <div className="card-body">
              <LandingPageBlockFormFields
                type={landingPageBlockDetails.type}
                sort={landingPageBlockDetails.sort}
                handleImageChange={this.handleImageChange}
                handleImageRemove={this.handleImageRemove}
                handleBackgroundColor={this.handleBackgroundColor}
                handleTextColor={this.handleTextColor}
                handleBackgroundColor1={this.handleBackgroundColor1}
                handleTextColor1={this.handleTextColor1}
                toggle={this.toggle}
                toggleModalClose={() => this.toggleModalClose()}
                isOpen={isOpen}
                partnerList={partnerList}
                selectedPartners={selectedPartners}
                partnerIds={partnerIds}
                initialPartnerIdsLength={initialPartnerIdsLength}
                handlePartners={this.handlePartners}
                handleSelectedPartners={this.handleSelectedPartners}
                expertList={expertList}
                initialExpertIdsLength={initialExpertIdsLength}
                expertIds={expertIds}
                selectedExperts={selectedExperts}
                handleExperts={this.handleExperts}
                handleSelectedExperts={this.handleSelectedExperts}
                playbookList={playbookList}
                handlePlaybooks={this.handlePlaybooks}
                handleSelectedPlaybooks={this.handleSelectedPlaybooks}
                selectedPlaybooks={selectedPlaybooks}
                playbookIds={playbookIds}
                logoLink={logoLink}
                logoImageDesktopUrlPreview={logoImageDesktopUrlPreview}
                logoImageMobileUrlPreview={logoImageMobileUrlPreview}
                logoImageDesktopUrl={logoImageDesktopUrl}
                logoImageMobileUrl={logoImageMobileUrl}
                backgroundImageDesktopUrlPreview={
                  backgroundImageDesktopUrlPreview
                }
                backgroundImageMobileUrlPreview={
                  backgroundImageMobileUrlPreview
                }
                backgroundImageDesktopUrl={backgroundImageDesktopUrl}
                backgroundImageMobileUrl={backgroundImageMobileUrl}
                backgroundImageDesktopUrl1Preview={
                  backgroundImageDesktopUrl1Preview
                }
                backgroundImageMobileUrl1Preview={
                  backgroundImageMobileUrl1Preview
                }
                backgroundImageDesktopUrl1={backgroundImageDesktopUrl1}
                backgroundImageMobileUrl1={backgroundImageMobileUrl1}
                textColor={
                  this.state.textColor
                    ? this.state.textColor
                    : landingPageBlockDetails.textColor
                }
                backgroundColor={
                  this.state.backgroundColor
                    ? this.state.backgroundColor
                    : landingPageBlockDetails.backgroundColor
                }
                textColor1={
                  this.state.textColor1
                    ? this.state.textColor1
                    : landingPageBlockDetails.textColor1
                }
                backgroundColor1={
                  this.state.backgroundColor1
                    ? this.state.backgroundColor1
                    : landingPageBlockDetails.backgroundColor1
                }
                handleContrastColorSelect={this.handleContrastColorSelect}
              />

              {landingPageBlockDetails.type ===
                LANDING_PAGE_BLOCK_TYPE_PARTNER && (
                <PartnerGrid
                  partnerList={partnerList}
                  showDeleteIcon={true}
                  selectedPartners={partnerIds}
                  handlePartners={this.handleRemovePartners}
                />
              )}
              {landingPageBlockDetails.type ===
                LANDING_PAGE_BLOCK_TYPE_EXPERT && (
                <ExpertGrid
                  expertList={expertList}
                  showDeleteIcon={true}
                  selectedExperts={expertIds}
                  handleExperts={this.handleRemoveExperts}
                />
              )}
              {(landingPageBlockDetails.type ===
                LANDING_PAGE_BLOCK_TYPE_PLAYBOOK ||
                landingPageBlockDetails.type ===
                  LANDING_PAGE_BLOCK_TYPE_PLAYBOOK_GRID) && (
                <div className="row playbook-list-section">
                  {playbookList.length > 0 &&
                    playbookList.map(
                      playbook =>
                        playbookIds &&
                        playbookIds.indexOf(playbook.id) > -1 && (
                          <div className="col-12 col-md-6 col-lg-6 col-xl-4">
                            <PlaybookCard
                              data={playbook}
                              key={playbook.id}
                              link={`/playbook/${playbook.id}?redirect=${currentUrl}`}
                              openLinkInNewTab={true}
                              removePlaybook={true}
                              handlePlaybooks={this.handleRemovePlaybooks}
                              history={this.props.history}
                            />
                          </div>
                        )
                    )}
                </div>
              )}
            </div>
          </div>
          <div className="mb-4">
            <DeleteButton label="Delete Section" onClick={this.handleDelete} />
            <div className="float-right">
              <CancelButton onClick={() => this.props.history.goBack()} />
              <SaveButton />
            </div>
          </div>
        </Form>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { updateLandingPageBlock, deleteLandingPageBlock },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(LandingPageBlocksForm);
