import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";

//Constant
import {
  SETTING_SLACK_ACCESS_TOKEN,
  SETTING_SLACK_APP_URL
} from "../../../../../setting/Constants";

//Helper
import { isNotEmpty } from "../../../../../common/utils";
import { getSystemSettingByName } from "../../../../../setting/Service";
import { getKeyValueByObject } from "../../../../../lib/helper";
import SaveButton from "../../../../../components/base/SaveButton";
import SlackService from "../../../../../services/slackService";

const SlackSection = props => {
  const { settings, saveSettings } = props;

  const connect = async () => {
    let response = await SlackService.connect();

    if (response && response.redirectTo) {
      window.open(response.redirectTo, "_blank");
    }
  };

  const disConnect = () => {
    const data = new FormData();
    data.append("slack_access_token", "");
    saveSettings(data);
  };

  let accessToken =
    settings && getKeyValueByObject(settings, SETTING_SLACK_ACCESS_TOKEN);
  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 mt-2">
        <div className="card">
          <div className="card-body d-flex align-items-center">
            <p
              style={{
                margin: 8,
                flexGrow: 1,
                textAlign: "left",
                fontWeight: "bold",
                fontSize: "24px"
              }}
            >
              {"Slack"}
            </p>
            <SaveButton
              onClick={accessToken ? disConnect : connect}
              label={accessToken ? "Disconnect" : "Connect"}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default SlackSection;
