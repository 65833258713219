import React, { Component } from "react";

// Components
import Text from "../../../components/base/Text";
import TextArea from "../../../components/base/TextArea";

// Assets
import { PlusIcon, TrashIcon } from "../../../assets/img/icons";
import SingleRadio from "../../../components/base/SingleRadio";
import DeleteModal from "../../../components/base/DeleteModal";

export default class CourseQuestionsAnswer extends Component {
  constructor(props) {
    super(props);
    const { answers } = this.props;
    this.state = {
      answer: "",
      alphabet: "A",
      openDeleteModal: false,
      openAnswerDeleteModal: false,
      correctAnswers: {},
      currentQuestionData: {},
      currentAnswerData: {},
      answerField:
        answers && answers.length
          ? [...answers]
          : [{ id: "1", questionId: 1, answerId: 1, answer: null }],
      answerCount: 1,
      deleteId: "",
      enableTrash: false
    };
  }

  // Add Answer field
  addAnswerField = (i, j, event) => {
    event.preventDefault();
    // Generate added answer field key
    const id = `${this.state.answerCount + 1}`;
    const answerField = [...this.state.answerField];

    answerField.push({
      answer: null,
      id: id,
      answerId: j + 1,
      questionId: i + 1
    });
    // }
    this.setState({ answerField, answerCount: this.state.answerCount + 1 });
  };

  // Handle Remove Answer
  handleRemoveAnswer = j => {
    const answerField = [...this.state.answerField];
    if (answerField.length > 1) {
      answerField.splice(j, 1);
      this.setState({ answerField });
    }
  };
  // Handle Remove Answer
  handledDeleteAnswer = id => {
    let answerField = this.state.answerField;
    var removeIndex =
      answerField &&
      answerField.length > 0 &&
      answerField.findIndex(item => item.id === id);
    answerField.splice(removeIndex, 1);
    this.setState({ answerField });
  };

  render() {
    const { e, i, handleCorrectAnswer, handleRemove } = this.props;
    const {
      answerField,
      alphabet,
      openDeleteModal,
      openAnswerDeleteModal,
      currentQuestionData,
      currentAnswerData,
      deleteId,
      enableTrash
    } = this.state;
    return (
      <>
        <DeleteModal
          isOpen={openDeleteModal}
          title="Delete Question "
          toggle={() => {
            this.setState({ openDeleteModal: false });
          }}
          label={currentQuestionData.question}
          deleteFunction={() =>
            this.props.handleQuestionDelete(currentQuestionData.id)
          }
        />
        <DeleteModal
          isOpen={openAnswerDeleteModal}
          title="Delete Answer "
          toggle={() => {
            this.setState({ openAnswerDeleteModal: false });
          }}
          label={currentAnswerData.answer}
          deleteFunction={() =>
            this.props
              .handleAnswerDelete(currentAnswerData.id, currentQuestionData.id)
              .then(_el => {
                this.handledDeleteAnswer(deleteId);
              })
          }
        />
        <div className="pt-3 task-list" key={e.id}>
          <label>{`Question #${i + 1}`}</label>
          <div className="field-wrapper">
            <TextArea
              name={`question-${e.id}`}
              placeholder={`Enter question ${i + 1}`}
            />
            <div className="task-actions">
              <button
                type="button"
                className={["delete-btn", "btn-link", "btn"].join(" ")}
                onClick={() => {
                  if (e.questionId) {
                    handleRemove(i);
                  } else if (e.id) {
                    this.setState({
                      openDeleteModal: true,
                      currentQuestionData: e
                    });
                  }
                }}
              >
                <TrashIcon />
              </button>
            </div>
          </div>
          {answerField.map((el, j) => (
            <div className="field-wrapper">
              <div className="col-1 ml-2">
                <SingleRadio
                  name={`correctAnswer-${e.id}`}
                  size="large"
                  options={String.fromCharCode(alphabet.charCodeAt() + j)}
                  selected={el.correct_answer === 1}
                  onChange={() => {
                    handleCorrectAnswer(e.id, el.id);
                  }}
                  defaultValue={
                    el.correct_answer && el.correct_answer === 1 ? true : false
                  }
                />
              </div>
              <Text
                className={"ml-3"}
                name={`answer-${e.id}-${el.id}`}
                placeholder={`Enter Answer`}
              />
              <button
                onClick={e => {
                  this.addAnswerField(i, j, e);
                  this.setState({ enableTrash: true });
                }}
                className="btn btn-link d-inline-flex px-0 text-underline"
              >
                <PlusIcon />
              </button>
              <div className="task-actions">
                {(answerField.length > 1 || enableTrash) && (
                  <button
                    // disabled={el.answer || enableTrash ? false : true}
                    type="button"
                    className={["delete-btn", "btn-link", "btn"].join(" ")}
                    onClick={() => {
                      if (el.answerId) {
                        this.handleRemoveAnswer(j);
                      } else {
                        this.setState({
                          openAnswerDeleteModal: true,
                          currentAnswerData: el,
                          currentQuestionData: e,
                          deleteId: el.id
                        });
                      }
                      if (answerField.length == 2) {
                        this.setState({ enableTrash: false });
                      }
                    }}
                  >
                    <TrashIcon />
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
      </>
    );
  }
}
