import React from "react";

// Page Components
import UpdateProjectCategoryForm from "./updateProjectCategoryForm";

// Components
import PageTitle from "../../components/base/PageTitle";

const ProjectCategoryDetails = props => {
  return (
    <div>
      <PageTitle label="Edit Project Category" />
      <UpdateProjectCategoryForm history={props.history} match={props.match} />
    </div>
  );
};

export default ProjectCategoryDetails;
