import React from "react";
import { CrossIcon, InlineInfoIcon } from "../../assets/img/icons";
import { Alert } from "reactstrap";

function ProjectEstimatedBudgetAlert({ handleNewEstimatedBudget, onclick }) {
  return (
    <Alert
      color="danger"
      className={"d-flex align-items-center justify-content-stretch text-white"}
    >
      <InlineInfoIcon />
      <p className="ml-3 mb-0 font-weight-normal mr-auto text-white">
        <span className="font-weight-bold">
          Torchlite is awaiting for your approval on the project budget
        </span>
      </p>
      <div className="btn-wrapper mr-2">
        <button
          className="btn btn-outline-light"
          onClick={() => handleNewEstimatedBudget("Approve")}
        >
          Approve
        </button>
      </div>

      <div className="btn-wrapper mr-2">
        <button
          className="btn btn-outline-light"
          onClick={() => handleNewEstimatedBudget("Decline")}
        >
          Decline
        </button>
      </div>
      <div className="close-alert mr-0 ml-0">
        <button className="btn-plain btn text-white p-0" onClick={onclick}>
          <CrossIcon />
        </button>
      </div>
    </Alert>
  );
}

const ProjectBudgetAlerts = ({
  handleProjectBudgetNotification,
  handleNewEstimatedBudget
}) => {
  return (
    <div className={["alerts-wrapper"].join(" ")}>
      <ProjectEstimatedBudgetAlert
        onclick={handleProjectBudgetNotification}
        handleNewEstimatedBudget={handleNewEstimatedBudget}
      />
    </div>
  );
};

export default ProjectBudgetAlerts;
