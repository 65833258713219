import React, { Component } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import DatePicker from "../../components/base/Date";
import Page404 from "../Page404";
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";

// Assets
import {
  ChevronRight,
  DownloadIcon,
  SuccessCheckIcon,
  DoubleClapGraphicIcon
} from "../../assets/img/icons";

// Components
import toast from "../../components/base/Toast";
import AdminActionNavigation from "../../components/base/AdminActionNavigation";
import Form from "../../components/base/Form";
import Text from "../../components/base/Text";
import Loader from "../../components/base/Loader";

// Constants
import {
  INVOICE_STATUS_PAID,
  INVOICE_STATUS_PENDING,
  INVOICE_STATUS_APPROVED,
  INVOICE_STATUS_PARTIALLY_PAID
} from "../../invoice/Constants";

import {
  SETTINGS_PAYMENT_WORKFLOW,
  SETTINGS_BILL_DOT_COM_USER_ID
} from "../../setting/Constants";

// Inner Components
import PaymentPendingSection from "./inner-components/PaymentPendingSection";
import PaymentSuccessSection from "./inner-components/PaymentSuccessSection";
import { SETTINGS_ENABLE_SCHEDULE_PAYMENT } from "../../setting/Constants";
import {
  getKeyValueByObject,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";
import { isBadRequest } from "../../common/http";
import PaymentModeModal from "../../referral/Components/Modal/SalesforceModal/PaymentMadeModal";
import SelectDropdown from "../../components/base/SelectDropdown";
import DeleteModal from "../../components/base/DeleteModal";
import { connect } from "react-redux";

class SinglePayment extends Component {
  constructor(props) {
    super(props);

    this.state = {
      invoiceHtml: null,
      iframeHeight: 950,
      iframeStyle: {
        width: "100%",
        border: "none"
      },
      htmlEndpoint: `${endpoints().invoiceAPI}/${props.match.params.id}/render`,
      pdfEndpoint: `${endpoints().invoiceAPI}/${props.match.params.id}/pdf`,
      invoiceDetails: "",
      isPayingBill: false,
      paymentSuccess: false,
      isWaitingMfaOTP: false,
      isAuthenticating: false,
      isPaymentProcessing: false,
      isPaymenProcessDateModal: false,
      processDate: "",
      isBillCreating: false,
      showPayInvoice: false,
      paymentDate: "",
      isMarkPaymentMadeModal: false,
      isSendPaymentEmailToPartner: false,
      createBillSuccess: false,
      isInvoiceDetailsNotFound: false,
      showAdminAction: false,
      isBillDotComConnected: false,
      deleteModal: false
    };
  }
  adminActionOptions = [
    {
      value: "Email to AP Provider",
      label: "Email to AP Provider"
    },
    {
      value: "Delete",
      label: "Delete"
    }
  ];

  async getInvoiceHtml() {
    let data;

    try {
      const response = await apiClient.get(this.state.htmlEndpoint);
      data = response.data;
    } catch (err) {
      data = err.data;
    }

    if (
      data &&
      data !== undefined &&
      this.state.isInvoiceDetailsNotFound == false
    ) {
      this.state.invoiceHtml.contentDocument.body.innerHTML = data;
      this.setState({
        iframeHeight:
          this.state.invoiceHtml.contentDocument.body.scrollHeight + 20
      });
    }
  }

  async getInvoicePdf(e) {
    e.preventDefault();

    try {
      const response = await apiClient.get(this.state.pdfEndpoint);

      const a = document.createElement("a");
      a.href = response.data.data.file;
      a.download = response.data.data.name;
      a.click();
    } catch (err) {
      if (err.response && err.response.status >= 400) {
        toast.error(err.response.data.message);
      }
    }
  }

  // Get Invoice Details
  async getInvoiceDetails() {
    let invoiceDetails;

    try {
      const response = await apiClient.get(
        `${endpoints().invoiceAPI}/${this.props.match.params.id}`
      );
      invoiceDetails = response.data;
    } catch (err) {
      this.setState({
        isInvoiceDetailsNotFound: true
      });
      console.log(err);
    }

    this.setState({
      invoiceDetails: invoiceDetails ? invoiceDetails : ""
    });
  }

  componentDidMount() {
    this.getInvoiceHtml();
    this.getInvoiceDetails();
    this.getSettingsData(SETTINGS_PAYMENT_WORKFLOW);
    this.getSettingsData(SETTINGS_BILL_DOT_COM_USER_ID);
  }

  getSettingsData = async settingValue => {
    try {
      return apiClient
        .get(`${endpoints().settingAPI}/${settingValue}`)
        .then(response => {
          if (response && response.data) {
            const settings = response.data.data;

            if (settings) {
              if (settings.name == SETTINGS_PAYMENT_WORKFLOW) {
                this.setState({
                  showAdminAction: settings.value == "true" ? true : false
                });
              }
              if (settings.name == SETTINGS_BILL_DOT_COM_USER_ID) {
                this.setState({
                  isBillDotComConnected: settings.value ? true : false
                });
              }
            }
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  // Bill.com MFA Challenge
  mfaChallengeCreate = async () => {
    try {
      const response = await apiClient.post(
        `${endpoints().billDotComAPI}/mfa/challenge`,
        {}
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      return successMessage;
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        toast.error(errorMessage);
        console.error(errorMessage);
      }
    }
  };

  // Bill.com MFA Authenticate
  mfaAuthenticate = async values => {
    try {
      const response = await apiClient.post(
        `${endpoints().billDotComAPI}/mfa/verify`,
        values
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }

      this.setState({ isWaitingMfaOTP: false }, () => {
        this.payCommissionAmount();
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        if (
          error.response &&
          error.response.data &&
          error.response.data.message
        ) {
          errorMessage = error.response.data.message;
        }
        toast.error(errorMessage);
        console.error(errorMessage);
      }
    }
  };

  // Pay the referral commission to the partner through Bill.com
  payReferralCommission = invoiceId => {
    this.setState({ isPaymentProcessing: true }, async () => {
      try {
        const response = await apiClient.put(
          `${endpoints().invoiceAPI}/pay/${invoiceId}`
        );
        if (response && response.data) {
          this.setState({
            isPaymentProcessing: false,
            paymentSuccess: true
          });
          this.getInvoiceDetails();
          this.getInvoiceHtml();
        }
      } catch (error) {
        if (isBadRequest(error)) {
          let errorMessage;
          if (
            error.response &&
            error.response.data &&
            error.response.data.message
          ) {
            errorMessage = error.response.data.message;
            this.setState({ isPaymentProcessing: false, isPayingBill: false });
          }
          if (errorMessage === "Untrusted session.") {
            this.mfaChallengeCreate();
            return this.setState({
              isWaitingMfaOTP: true,
              isPayingBill: true
            });
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      }
    });
  };

  // Get Referral Bill.com Bill Details
  getBillComBillDetails = (invoiceId, isApprove) => {
    this.setState(
      { isPaymentProcessing: !isApprove, isBillCreating: isApprove },
      async () => {
        try {
          const response = await apiClient.get(
            `${
              endpoints().invoiceAPI
            }/get/bill/${invoiceId}?approve=${isApprove}`
          );
          if (response && response.data) {
            if (isApprove) {
              this.setState({
                isBillCreating: false,
                createBillSuccess: true
              });
              this.getInvoiceDetails();
              this.getInvoiceHtml();
            } else this.payReferralCommission(invoiceId);
          }
        } catch (error) {
          if (isBadRequest(error)) {
            let errorMessage;
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMessage = error.response.data.message;
              this.setState({
                isPaymentProcessing: false,
                isBillCreating: false,
                isPayingBill: false
              });
            }
            if (errorMessage === "Untrusted session.") {
              this.mfaChallengeCreate();
              return this.setState({
                isWaitingMfaOTP: true,
                isPayingBill: true
              });
            }
            toast.error(errorMessage);
            console.error(errorMessage);
          }
        }
      }
    );
  };

  // Get Partner Bill.com Vendor Bank Account Details
  getVendorBankAccount = (invoiceId, accountId, isApprove) => {
    this.setState(
      { isPaymentProcessing: !isApprove, isBillCreating: isApprove },
      async () => {
        try {
          const response = await apiClient.get(
            `${endpoints().partnerAPI}/bank/account/${accountId}`
          );
          if (response && response.data) {
            this.getBillComBillDetails(invoiceId, isApprove);
          }
        } catch (error) {
          if (isBadRequest(error)) {
            let errorMessage;
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMessage = error.response.data.message;
              this.setState({
                isPaymentProcessing: false,
                isBillCreating: false,
                isPayingBill: false
              });
            }
            if (errorMessage === "Untrusted session.") {
              this.mfaChallengeCreate();
              return this.setState({
                isWaitingMfaOTP: true,
                isPayingBill: true
              });
            }
            toast.error(errorMessage);
            console.error(errorMessage);
          }
        }
      }
    );
  };

  // Get Partner Bill.com Vendor Details
  getVendorDetails = (invoiceId, accountId, isApprove) => {
    if (accountId) {
      this.setState(
        {
          isPaymentProcessing: !isApprove,
          isBillCreating: isApprove
        },
        async () => {
          return apiClient
            .get(`${endpoints().partnerAPI}/vendor/${accountId}`)
            .then(response => {
              if (response && response.data) {
                this.getVendorBankAccount(invoiceId, accountId, isApprove);
              }
            })
            .catch(error => {
              if (isBadRequest(error)) {
                let errorMessage;
                if (
                  error.response &&
                  error.response.data &&
                  error.response.data.message
                ) {
                  errorMessage = error.response.data.message;
                  this.setState({
                    isPaymentProcessing: false,
                    isBillCreating: false,
                    isPayingBill: false
                  });
                }
                if (errorMessage === "Untrusted session.") {
                  this.mfaChallengeCreate();
                  return this.setState({
                    isPayingBill: true,
                    isWaitingMfaOTP: true
                  });
                }
                toast.error(errorMessage);
                console.error(errorMessage);
              }
            });
        }
      );
    }
  };

  // Toggle Pay Invoice Button Dropdown
  togglePayInvoice = () => {
    this.setState({ showPayInvoice: !this.state.showPayInvoice });
  };

  // Toggle Mark Payment Made Modal
  toggleMarkPaymentMadeModal = () => {
    this.setState({
      isMarkPaymentMadeModal: !this.state.isMarkPaymentMadeModal,
      paymentDate: ""
    });
  };

  // Set Process Date
  setPaymentDate = date => {
    this.setState({ paymentDate: date });
  };

  // Toggle sending payment notification to partner
  toggleIsSendPaymentEmailToPartner = () => {
    this.setState({
      isSendPaymentEmailToPartner: !this.state.isSendPaymentEmailToPartner
    });
  };

  // Set Payment As Made
  setPaymentAsMade = values => {
    values.invoice = "invoice";
    this.setState({ isMarkPaymentMadeLoading: true }, () => {
      const id = this.state.invoiceDetails.referralId;
      const invoiceId = this.state.invoiceDetails.id;
      values.invoice_id = invoiceId;
      if (id && invoiceId) {
        apiClient
          .put(`${endpoints().referralApi}/markPaymentMade/${id}`, values)
          .then(response => {
            let successMessage;
            if (response && response.data) {
              successMessage = response.data.message;
            }
            this.setState({
              isMarkPaymentMadeLoading: false,
              isMarkPaymentMadeModal: false
            });
            toast.success(successMessage);
            this.getInvoiceDetails();
            this.getInvoiceHtml();
          })
          .catch(error => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              toast.error(error.response.data.message);
              console.error(errorMessage);
            }
          });
      }
    });
  };

  updateStatus = async () => {
    try {
      let data = { status: "approved" };
      return apiClient
        .put(`${endpoints().invoiceAPI}/${this.props.match.params.id}`, data)
        .then(response => {
          if (response && response.data) {
            let message = response.data.message;
            if (message) {
              toast.success(message);
              this.getInvoiceDetails();
            }
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
              toast.error(errorMessage);
            }
            console.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  // Bill.com Authenticate
  billDotComAuthenticate = (invoiceId, accountId, isApprove) => {
    return this.setState({ isAuthenticating: true }, () => {
      apiClient
        .post(`${endpoints().billDotComAPI}/connect`)
        .then(response => {
          if (response && response.data) {
            this.setState(
              {
                isAuthenticating: false
              },
              () => {
                this.getVendorDetails(invoiceId, accountId, isApprove);
              }
            );
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            if (
              error.response &&
              error.response.data &&
              error.response.data.message
            ) {
              errorMessage = error.response.data.message;
            }
            if (errorMessage === "Untrusted session.") {
              this.mfaChallengeCreate();
              return this.setState({
                isWaitingMfaOTP: true,
                isAuthenticating: false
              });
            } else {
              this.setState({ isPayingBill: false });
            }
            toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    });
  };
  // Pay Commission Amount to Partner
  payCommissionAmount = async () => {
    const invoiceDetails = this.state.invoiceDetails;
    const invoiceId = invoiceDetails && invoiceDetails.id;
    const referralId = invoiceDetails && invoiceDetails.referralId;
    const accountId = invoiceDetails && invoiceDetails.accountId;

    if (referralId && accountId) {
      try {
        this.setState({ isPayingBill: true }, async () => {
          this.billDotComAuthenticate(invoiceId, accountId, false);
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  // Save Payment process Date
  savePaymentProcessDate = async data => {
    const invoiceDetails = this.state.invoiceDetails;
    const invoiceId = invoiceDetails && invoiceDetails.id;
    return apiClient
      .put(`${endpoints().invoiceAPI}/create/payment/${invoiceId}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.togglePaymenProcessDateModal();
        this.payCommissionAmount();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Toggle Payment Modal
  togglePaymentModal = () => {
    this.setState({
      isPayingBill: false,
      paymentSuccess: false,
      isWaitingMfaOTP: false,
      isAuthenticating: false,
      isPaymentProcessing: false,
      isBillCreating: false,
      createBillSuccess: false
    });
  };

  // Toggle Payment Modal
  togglePaymenProcessDateModal = () => {
    this.setState({
      isPaymenProcessDateModal: !this.state.isPaymenProcessDateModal,
      processDate: ""
    });
  };

  //  Set Process Date
  setProcessDate = date => {
    this.setState({ processDate: date });
  };

  // Approve Invoice and Create Bill
  approveInvoiceBill = async () => {
    const invoiceDetails = this.state.invoiceDetails;
    const invoiceId = invoiceDetails && invoiceDetails.id;
    const referralId = invoiceDetails && invoiceDetails.referralId;
    const accountId = invoiceDetails && invoiceDetails.accountId;

    if (referralId && accountId) {
      try {
        this.setState({ isPayingBill: true }, async () => {
          this.billDotComAuthenticate(invoiceId, accountId, true);
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  sendMailToAPIProvider = async () => {
    try {
      return apiClient
        .post(
          `${endpoints().invoiceAPI}/sendEmailToProvider/${
            this.props.match.params.id
          }`
        )
        .then(response => {
          if (response && response.data) {
            let message = response.data.message;
            if (message) {
              toast.success(message);
            }
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
              toast.error(errorMessage);
            }
            console.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };
  // Delete Partner Api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().invoiceAPI}/${this.props.match.params.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);

          // Redirect user to there respective tab
          this.props.history.push("/accounting");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  handleAdminAction = async value => {
    switch (value) {
      case "Email to AP Provider":
        return this.sendMailToAPIProvider();
      case "Delete":
        return this.setState({ deleteModal: true });
      default:
        return "";
    }
  };

  render() {
    const {
      invoiceDetails,
      isPayingBill,
      paymentSuccess,
      isWaitingMfaOTP,
      isAuthenticating,
      isPaymentProcessing,
      isPaymenProcessDateModal,
      isSendPaymentEmailToPartner,
      isMarkPaymentMadeModal,
      processDate,
      paymentDate,
      isBillCreating,
      showPayInvoice,
      createBillSuccess,
      isInvoiceDetailsNotFound,
      showAdminAction,
      isBillDotComConnected,
      deleteModal
    } = this.state;

    const show = showPayInvoice ? "show" : "";
    const modalHeaderProps = {};
    if (isWaitingMfaOTP) {
      modalHeaderProps.toggle = () => {
        this.togglePaymentModal();
      };
    }

    const minProcessDays = 3;
    const minProcessDate = new Date(
      new Date().getTime() + minProcessDays * 24 * 60 * 60 * 1000
    );

    const settings = this.props.settings ? this.props.settings : "";
    const enableSchedulePayment =
      settings &&
      getKeyValueByObject(settings, SETTINGS_ENABLE_SCHEDULE_PAYMENT) &&
      getKeyValueByObject(settings, SETTINGS_ENABLE_SCHEDULE_PAYMENT) === "true"
        ? true
        : false;
    if (isInvoiceDetailsNotFound == true) {
      return (
        <div className="app-body">
          <Page404 />
        </div>
      );
    }
    return (
      <>
        <DeleteModal
          isOpen={deleteModal}
          title="Delete Invoice"
          toggle={() => {
            this.setState({ deleteModal: false });
          }}
          label={`Invoice# ${invoiceDetails.id}`}
          deleteFunction={() => this.handleDelete()}
        />
        <PaymentModeModal
          isMarkPaymentMadeModal={isMarkPaymentMadeModal}
          toggleMarkPaymentMadeModal={this.toggleMarkPaymentMadeModal}
          paymentDate={paymentDate}
          referralDetails={invoiceDetails}
          isSendPaymentEmailToPartner={isSendPaymentEmailToPartner}
          setPaymentAsMade={this.setPaymentAsMade}
          setPaymentDate={this.setPaymentDate}
          toggleIsSendPaymentEmailToPartner={
            this.toggleIsSendPaymentEmailToPartner
          }
        />
        {/* Paying Invoice Modal Start */}
        <Modal
          isOpen={isPayingBill}
          className={["pay-invoice-modal"].join(" ")}
          backdrop="static"
        >
          <Form
            initialValues={{ mfaOtp: "" }}
            onSubmit={values => {
              this.mfaAuthenticate(values);
            }}
          >
            <ModalHeader {...modalHeaderProps}>
              {paymentSuccess ? (
                <div className="mt-4">
                  <DoubleClapGraphicIcon />
                </div>
              ) : createBillSuccess ? (
                <div className="mt-4 d-flex justify-content-center">
                  <SuccessCheckIcon />
                </div>
              ) : !isWaitingMfaOTP ? (
                <Loader />
              ) : (
                <div
                  className={[
                    "d-flex",
                    "flex-column",
                    "align-items-center"
                  ].join(" ")}
                >
                  <h2 className={["font-weight-bold", "mt-3"].join(" ")}>
                    Authenticate Your Payment
                  </h2>
                  <p className="pl-4 pr-4 text-muted subtext">
                    Protecting your payment is our priority. Please confirm by
                    entering the authorization code sent
                  </p>
                </div>
              )}
              {paymentSuccess ? (
                <div className="mb-5">
                  <h2 className="font-weight-bold mt-4 mb-3">All Done!</h2>
                  <p className="pl-2 pr-2 text-muted mb-0 subtext">
                    We’ll send an email when the payment is confirmed.
                  </p>
                </div>
              ) : createBillSuccess ? (
                <div className="mb-5">
                  <h2 className="font-weight-bold mt-4 mb-3">All Done!</h2>
                  <p className="pl-2 pr-2 text-muted mb-0 subtext">
                    Bill created successfully.
                  </p>
                </div>
              ) : !isWaitingMfaOTP ? (
                <>
                  {isAuthenticating && (
                    <h2 className="font-weight-bold mt-4 mb-5">
                      Authenticating...
                    </h2>
                  )}
                  {isBillCreating && (
                    <h2 className="font-weight-bold mt-4 mb-5">
                      Creating Bill...
                    </h2>
                  )}
                  {isPaymentProcessing && (
                    <div className="mb-5">
                      <h2 className={["font-weight-bold", "mt-3"].join(" ")}>
                        Processing
                      </h2>
                      <p className="pl-4 pr-4 text-muted mb-0 subtext">
                        Thank you for your payment.
                      </p>
                      <p className="pl-4 pr-4 text-muted mt-0 subtext">
                        Please wait while it finishes processing.
                      </p>
                    </div>
                  )}
                </>
              ) : (
                <div className="form-wrapper justify-content-center d-flex">
                  <div className="field-wrapper mt-3">
                    <Text
                      name="mfaOtp"
                      placeholder="Enter MFA OTP..."
                      rows="5"
                      error="MFA OTP is required"
                      required
                    />
                  </div>
                </div>
              )}
              {paymentSuccess || createBillSuccess ? (
                <div className="btn-wrapper">
                  <button
                    className="btn btn-primary px-3"
                    type="button"
                    onClick={() => {
                      this.togglePaymentModal();
                    }}
                  >
                    Goto Invoice Details
                  </button>
                </div>
              ) : !isWaitingMfaOTP ? (
                ""
              ) : (
                <div className="btn-wrapper">
                  <button className="btn btn-primary px-3" type="submit">
                    Submit
                  </button>
                  <p className="h7 mt-3">
                    Haven’t received it?{" "}
                    <a
                      href=""
                      onClick={e => {
                        e.preventDefault();
                        this.mfaChallengeCreate();
                      }}
                    >
                      Resend a new code.
                    </a>
                  </p>
                </div>
              )}
            </ModalHeader>
          </Form>
        </Modal>
        {/* Paying Invoice Modal End */}

        {/* Payment Process Date Modal Start */}
        <Modal
          isOpen={isPaymenProcessDateModal}
          className={["edit-task-modal"].join(" ")}
        >
          <ModalHeader toggle={this.togglePaymenProcessDateModal}>
            <h4 className={["font-weight-bold"].join(" ")}>
              Select Schedule Date
            </h4>
          </ModalHeader>
          <Form
            initialValues={{ processDate: "" }}
            onSubmit={values => {
              if (processDate) {
                values.processDate = processDate;
                this.savePaymentProcessDate(values);
              }
            }}
          >
            <ModalBody className={["mb-4"].join(" ")}>
              <div>
                <p className="font-weight-bold">Payment Schedule Date</p>
                <DatePicker
                  name="processDate"
                  id="processDate"
                  clearIcon={null}
                  onChange={this.setProcessDate}
                  placeholder="Schedule Date"
                  minDate={new Date()}
                  selected={processDate}
                  required={true}
                  width={50}
                />
              </div>
            </ModalBody>
            <ModalFooter className={["justify-content-center"].join(" ")}>
              <div className="btn-wrapper text-center">
                <button className="btn btn-primary px-3" type="submit">
                  Schedule Payment
                </button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
        {/* Payment Process Date Modal End */}

        <div className="single-payment">
          <AdminActionNavigation>
            <div className="d-flex w-100 align-items-center">
              <a
                className="cursor-pointer"
                onClick={() => this.props.history.goBack()}
              >
                Billings
              </a>
              <span
                className="text-dark d-flex align-items-cente"
                style={{ width: "15px" }}
              >
                <ChevronRight />
              </span>
              <span
                onClick={e => e.preventDefault()}
                className={[
                  "text-dark",
                  "font-weight-bold",
                  "d-flex",
                  "align-items-center",
                  "project-name",
                  "w-320"
                ].join(" ")}
              >
                <span className="ellipsis">
                  Invoice #{this.props.match.params.id}
                </span>
              </span>

              <div className="ml-auto d-flex">
                <a
                  className="download-pdf mr-2"
                  href="#"
                  onClick={e => this.getInvoicePdf(e)}
                >
                  <DownloadIcon />
                  Download as PDF
                </a>
                {(isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId)) &&
                  showAdminAction && (
                    <SelectDropdown
                      buttonLabel="Admin Actions"
                      dropdownLinks={this.adminActionOptions}
                      color={"gray"}
                      hideCaret
                      selectName={"admin_action"}
                      handleChange={this.handleAdminAction}
                    />
                  )}
              </div>
            </div>
          </AdminActionNavigation>

          {invoiceDetails &&
            invoiceDetails.referralId &&
            invoiceDetails.accountId &&
            (invoiceDetails.paymentStatus === INVOICE_STATUS_PENDING ||
              invoiceDetails.paymentStatus === INVOICE_STATUS_APPROVED ||
              invoiceDetails.paymentStatus ===
                INVOICE_STATUS_PARTIALLY_PAID) && (
              <PaymentPendingSection
                togglePayInvoice={this.togglePayInvoice}
                isBillDotComConnected={isBillDotComConnected}
                show={show}
                invoiceDetails={invoiceDetails}
                payCommissionAmount={this.togglePaymenProcessDateModal}
                toggleMarkPaymentMadeModal={this.toggleMarkPaymentMadeModal}
                approveInvoiceBill={this.approveInvoiceBill}
                updateStatus={this.updateStatus}
                enableSchedulePayment={enableSchedulePayment}
              />
            )}

          {invoiceDetails &&
            invoiceDetails.referralId &&
            invoiceDetails.accountId &&
            invoiceDetails.paymentStatus === INVOICE_STATUS_PAID && (
              <PaymentSuccessSection invoiceDetails={invoiceDetails} />
            )}
          <iframe
            ref={iframe => (this.state.invoiceHtml = iframe)}
            height={this.state.iframeHeight}
            style={this.state.iframeStyle}
          />
        </div>
      </>
    );
  }
}
const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(SinglePayment);
