import React from "react";

// Components
import { InlineInfoIcon } from "../../../../assets/img/icons";
import AlertBanner from "../../../../components/base/AlertBanner";

const PartnerAccountToggle = props => {
  const { setPartnerToggleValues } = props;
  return (
    <>
      <AlertBanner
        icon={<InlineInfoIcon />}
        buttonLabel="Check Partner company in HubSpot"
        buttonOnClick={() => {
          setPartnerToggleValues({
            isNoHubSpotPartnerAccountFoundModalOpen: true,
            isHubSpotPartnerAccountNotFoundNotification: true,
            accountType: "Company (Partner)"
          });
        }}
        removeOnClick={() => {
          setPartnerToggleValues({
            isHubSpotPartnerAccountNotFoundNotification: false
          });
        }}
        message="Partner Company was not found. Do a check for partial matches."
      />
    </>
  );
};

export default PartnerAccountToggle;
