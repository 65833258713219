const SystemName = {
  REFERRAL: "Referral",
  REFERRALS: "Referrals",
  MARKETPLACE: "Marketplace",
  REFERRAL_DEAl_REG: "Referrals & Deal Reg",
  PARTNER_MANAGER: "Partner Manager",
  REFERRAL_ACCOUNT_NAME: "Account Name",
  REFERRAL_FIRST_NAME: "First Name",
  REFERRAL_LAST_NAME: "Last Name",
  REFERRAL_CONTACT_TITLE: "Contact Title",
  REFERRAL_CONTACT_EMAIL: "Contact Email",
  REFERRAL_CONTACT_PHONE: "Contact Phone",
  ACCOUNT: "Account",
  COMARKETING_CAMPAIGNS: "Co-Marketing Campaigns",
  COMARKETING_CAMPAIGN: "Co-Marketing Campaign"
};
export default SystemName;
