export const COMPANY_ADMIN_ROLE = 1;
export const CUSTOMER_ROLE = 2;
export const EXPERT_ROLE = 3;
export const COMPANY_MANAGER_ROLE = 4;
export const CUSTOMER_ADMIN_ROLE = 5;
export const CUSTOMER_MEMBER_ROLE = 6;
export const PARTNER_ROLE = 7;
export const PARTNER_ADMIN_ROLE = 8;
export const PARTNER_MEMBER_ROLE = 9;
export const SUPER_ADMIN_ROLE = 10;
export const USERS_ROLE = 6;

export const PARTNER_USER_INVITED = 0;
export const PARTNER_USER_ACCEPTED = 1;
export const USER_STATUS_INVITED = 0;
export const USER_STATUS_ACCEPTED = 1;

export const ROLE_SUPER_ADMIN_TEXT = "Super Admin";
export const ROLE_COMPANY_ADMIN_TEXT = "Company Admin";
export const CUSTOMER_ROLE_TEXT = "Customer";
export const EXPERT_ROLE_TEXT = "Expert";
export const COMPANY_MANAGER_ROLE_TEXT = "Company Manager";
export const CUSTOMER_ADMIN_TEXT = "Customer Admin";
export const CUSTOMER_MEMBER_TEXT = "Customer Member";
export const PARTNER_ROLE_TEXT = "Partner";
export const PARTNER_ADMIN_TEXT = "Partner Admin";
export const PARTNER_MEMBER_TEXT = "Partner Member";
export const PARTNER_USER_INVITED_TEXT = "Invited";
export const PARTNER_USER_ACCEPTED_TEXT = "Accepted";
export const USER_INVITED_TEXT = "Invited";
export const USER_ACCEPTED_TEXT = "Accepted";
export const USERS_ROLE_TEXT = "Users";
