import React from "react";
import { NavItem, NavLink } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";

// Icon
import { ChevronRight } from "../../../assets/img/icons";

const SideNavBarItem = props => {
  const { key, activeTab, tabName, defaultSubTab, handleClick, label } = props;

  return (
    <Link id={key} to={`/admin-settings/${tabName}/${defaultSubTab}`}>
      <NavItem key={key} style={{ borderBottom: "2px solid #e9ecef" }}>
        <NavLink
          className={classnames({
            active: activeTab === tabName
          })}
          onClick={() => {
            handleClick(tabName, defaultSubTab);
          }}
          style={{ border: "0px" }}
        >
          {label}

          <span className="pull-right">
            <ChevronRight />
          </span>
        </NavLink>
      </NavItem>
    </Link>
  );
};

export default SideNavBarItem;
