import React from "react";
import DateTime from "../../../../lib/DateTime";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

const Table = props => {
  const { data, startDateValue, initialValues, endDateValue } = props;

  return (
    <div>
      <table className="expensive-table">
        <thead>
          <tr>
            <th colSpan="7" className="text-center">
              Overall Summary Result
              <br />
              {`${
                startDateValue
                  ? DateTime.getDate(startDateValue)
                  : initialValues.startDate
                  ? DateTime.getDate(initialValues.startDate)
                  : ""
              } To ${
                endDateValue
                  ? DateTime.getDate(endDateValue)
                  : initialValues.endDate
                  ? DateTime.getDate(initialValues.endDate)
                  : ""
              }`}
            </th>
          </tr>
        </thead>
        <tbody>
          {data.map((data, index) => (
            <>
              <tr key={index + "-submitted-count"}>
                <td
                  className="align-left"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  {Terminology.get(SystemName.REFERRALS)} Submitted Count
                </td>
                <td style={{ color: "black" }}>
                  {data.refferralsSubmiittedCount}
                </td>
              </tr>
              <tr key={index + "-submitted-count"}>
                <td
                  className="align-left"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  {Terminology.get(SystemName.REFERRALS)} Approved Count
                </td>
                <td className="align-left" style={{ color: "black" }}>
                  {data.refferralApprovedCount}
                </td>
              </tr>
              <tr key={index + "-declined"}>
                <td
                  className="align-left"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  {Terminology.get(SystemName.REFERRALS)} Declined Count
                </td>
                <td style={{ color: "black" }}>
                  {data.refferralsDeclinedCount}
                </td>
              </tr>
              <tr className="align-left" key={index + "-commissions-paid"}>
                <td style={{ color: "black", fontWeight: "bold" }}>
                  {Terminology.get(SystemName.REFERRALS)} Commissions Paid Count
                </td>
                <td style={{ color: "black" }}>
                  {data.refferralsCommissionsPaidCount}
                </td>
              </tr>
              <tr className="align-left" key={index + "-approved-amount"}>
                <td style={{ color: "black", fontWeight: "bold" }}>
                  {Terminology.get(SystemName.REFERRALS)} Approved Amount
                </td>
                <td style={{ color: "black" }}>
                  {Math.round(data.refferralsApprovedAmount)}
                </td>
              </tr>
              <tr key={index + "-commission-amount-paid"}>
                <td
                  className="align-left"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  {Terminology.get(SystemName.REFERRALS)} Commission Amount Paid
                </td>
                <td style={{ color: "black" }}>
                  {Math.round(data.refferralsComissionAmountPaid)}
                </td>
              </tr>
              <tr key={index + "-partners-registered"}>
                <td
                  className="align-left"
                  style={{ color: "black", fontWeight: "bold" }}
                >
                  Partners Registered
                </td>
                <td style={{ color: "black" }}>{data.partnersRegistered}</td>
              </tr>
            </>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default Table;
