import React from "react";
import propTypes from "prop-types";
import { Link } from "react-router-dom";
import {
  DEAL_STATUS_APPROVED,
  DEAL_STATUS_CLOSED,
  DEAL_STATUS_DECLINED,
  DEAL_STATUS_DRAFT,
  DEAL_STATUS_PENDING,
  DEAL_STATUS_REQUESTED
} from "../../../dealStatus/Constants";
import {
  PARTNER_DASHBOARD_OPEN_DEALS_COUNT,
  PARTNER_DASHBOARD_NULL_DEALS_COUNT
} from "../../../dashboard/Constants";

// Icons
import { DealRegistrationIcon, ChevronRight } from "../../../assets/img/icons";
import { SETTINGS_PORTAL_URL } from "../../../setting/Constants";

class StyledDealRegistrationCard extends React.Component {
  state = {
    copyLinkButtonLabel: "Copy Link"
  };

  handleCopyToClipboard = () => {
    const tempInput = document.createElement("input");

    tempInput.value = `${SETTINGS_PORTAL_URL}/deal/register?partner=${this.props.encryptedPartnerId}`;
    document.body.appendChild(tempInput);
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);

    this.setState({ copyLinkButtonLabel: "Copied!" });
  };

  render() {
    const {
      label,
      redirectUrl,
      subTitle,
      subText,
      partnerOpenDeals,
      partnerOpenDealsCount,
      enable
    } = this.props;

    return (
      <div
        className={["expert-onboarding", `${!enable ? "disabled" : ""}`].join(
          " "
        )}
      >
        <div className="card p-4">
          <div className="card-body">
            <div className="row">
              {partnerOpenDealsCount > PARTNER_DASHBOARD_NULL_DEALS_COUNT && (
                <div className="col text-right">
                  <small className="text-muted pr-1">Showing:</small>
                  <small>
                    {" "}
                    <strong>
                      {partnerOpenDeals.length} of {partnerOpenDealsCount}
                    </strong>{" "}
                    Deals
                  </small>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col">
                {/* Card label */}
                <div className="d-flex">
                  <DealRegistrationIcon />
                  <h6 className="ml-2 text-info font-weight-bold">
                    {label}
                    {partnerOpenDeals && partnerOpenDeals.length > 0
                      ? " & Open Deals"
                      : ""}
                  </h6>
                </div>
              </div>
            </div>
            <div className="row">
              <div
                className={
                  partnerOpenDeals && partnerOpenDeals.length > 0
                    ? "col-lg-6 col-md-6 col-12"
                    : "col"
                }
              >
                <Link
                  className="text-decoration-none text-dark"
                  to={redirectUrl || "#"}
                >
                  {/* Card content */}
                  <div className="mt-2 d-flex justify-content-between">
                    <div>
                      <h5 className="font-weight-bold">{subTitle}</h5>
                      <div className="pr-2">
                        <subText>
                          <b className="text-muted">{subText}</b>
                        </subText>
                      </div>
                    </div>
                    <div
                      className={
                        partnerOpenDeals && partnerOpenDeals.length > 0
                          ? "d-none"
                          : "pr-1 my-auto d-flex justify-content-end"
                      }
                    >
                      <ChevronRight />
                    </div>
                  </div>
                  {/* -------- */}
                </Link>
                {/* ------- */}
              </div>
              <div
                className={
                  partnerOpenDeals && partnerOpenDeals.length > 0
                    ? "col-lg-6 col-md-6 col-12"
                    : "d-none"
                }
              >
                <div className="mt-2 d-flex">
                  <h5 className="font-weight-bold">Recent Deals</h5>
                </div>
                {partnerOpenDeals &&
                  partnerOpenDeals.length > 0 &&
                  partnerOpenDeals.map(openDeal => (
                    <div className={["open-deal-item py-4"].join(" ")}>
                      <Link
                        to={`deal/details/${openDeal.id}`}
                        className="text-decoration-none text-dark"
                      >
                        <h6 className="font-weight-bold">
                          {openDeal.accountName}
                        </h6>
                      </Link>
                      <div className="row">
                        <div className="col-7">
                          <div>
                            <small className="text-muted">Submitted on: </small>
                            <strong className="pl-2">
                              {openDeal.createdAt}
                            </strong>
                          </div>
                        </div>
                        <div className="col-5">
                          <div>
                            <small className="text-muted">Status: </small>
                            <strong
                              className={`${
                                openDeal.status === DEAL_STATUS_DRAFT
                                  ? "text-warning"
                                  : openDeal.status === DEAL_STATUS_REQUESTED ||
                                    openDeal.status === DEAL_STATUS_PENDING ||
                                    openDeal.status === DEAL_STATUS_APPROVED ||
                                    openDeal.status === DEAL_STATUS_DECLINED ||
                                    openDeal.status === DEAL_STATUS_CLOSED
                                  ? "text-success"
                                  : "text-secondary"
                              }`}
                            >
                              {openDeal.status}
                            </strong>
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                {/* <div className="row">
              </div> */}
              </div>

              {partnerOpenDealsCount > PARTNER_DASHBOARD_OPEN_DEALS_COUNT && (
                <div className="col-lg-6 col-md-6 col-12 offset-md-6 pt-4">
                  <Link
                    className="text-decoration-none"
                    to="/deals?section=all-deals"
                  >
                    <button className="btn btn-primary">View More</button>
                  </Link>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

StyledDealRegistrationCard.propTypes = {
  count: propTypes.number,
  label: propTypes.string.isRequired,
  prefix: propTypes.string
};

export default StyledDealRegistrationCard;
