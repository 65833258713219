import React, { useEffect, useState } from "react";
import { DropdownItem } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Action
import { fetchList } from "../../../../actions/table";

// API call
import { apiClient } from "../../../../apiClient";

// Components
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import toast from "../../../../components/base/Toast";
import MoreDropdown from "../../../../components/base/MoreDropdown";
import AddButton from "../../../../components/base/AddButton";

// Constants
import { endpoints } from "../../../../configs";
import {
  PARTNER_APPLICATIONS_FORMS_SUB_TAB,
  TAB_PARTNER_ONBOARDING
} from "../../../../setting/Constants";

// Inner Components
import AddFormModal from "./inner-components/AddFormModal";
import DeleteFormModal from "./inner-components/DeleteTaskModal";
import EditFormModal from "./inner-components/EditFormModal";
import PartnerApplicationsField from "./PartnerApplicationsField";
import { isBadRequest } from "../../../../common/http";

const PartnerApplicationsForm = props => {
  const { adminSettings, saveSettings, history, getFormList } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [addFormModal, setAddFormModal] = useState(false);
  const [editFormModal, setEditFormModal] = useState(false);
  const [currentForm, setCurrentForm] = useState(false);
  const [deleteFormModal, setDeleteFormModal] = useState(false);
  const [selectedFormName, setSelectedFormName] = useState("");
  const [selectedFormIdValue, setSelectedFormId] = useState("");
  const addNewForm = values => {
    apiClient
      .post(`${endpoints().partnerApplicationFormAPI}`, values)
      .then(response => {
        if (response.data) {
          setIsLoading(true);
          setAddFormModal(false);
          toast.success(response.data.message);
          getFormList();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
          setIsLoading(false);
          setAddFormModal(true);
        }
      });
  };

  const updateForm = (id, values) => {
    apiClient
      .put(`${endpoints().partnerApplicationFormAPI}/${id}`, values)
      .then(response => {
        if (response.data) {
          setCurrentForm("");
          setIsLoading(true);
          setAddFormModal(false);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
          setIsLoading(false);
        }
      });
  };

  const deleteForm = id => {
    apiClient
      .delete(`${endpoints().partnerApplicationFormAPI}/${id}`)
      .then(response => {
        if (response.data) {
          setCurrentForm("");
          setIsLoading(true);
          toast.success(response.data.message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  const updateFormsTable = () => {
    props.actions.fetchList(
      "partnerApplicationForm",
      `${endpoints().partnerApplicationFormAPI}`,
      1,
      10,
      { pagination: true }
    );
    setIsLoading(false);
  };

  useEffect(() => {
    if (isLoading) {
      updateFormsTable();
    }
  }, [isLoading]);

  const selectedFormId = props.activeSection;
  return (
    <>
      {!selectedFormId ? (
        <>
          <AddFormModal
            isOpen={addFormModal}
            toggle={() => {
              setAddFormModal(prevState => !prevState);
            }}
            addNewForm={addNewForm}
          />

          <EditFormModal
            isOpen={editFormModal}
            toggle={() => {
              setEditFormModal(prevState => !prevState);
            }}
            task={currentForm}
            updateForm={updateForm}
          />

          <DeleteFormModal
            isOpen={deleteFormModal}
            toggle={() => {
              setDeleteFormModal(prevState => !prevState);
            }}
            task={currentForm}
            deleteTask={deleteForm}
          />

          <div className="card">
            <div className="row">
              <div className="col-6">
                <h6 className="font-weight-bold pt-4 px-4">
                  Forms for Application Task
                </h6>
              </div>
              <div className="col-6 pt-3 px-4">
                <AddButton
                  label="Add New"
                  className="pull-right btn btn-secondary"
                  onClick={() => {
                    setAddFormModal(prevState => !prevState);
                  }}
                />
              </div>
            </div>

            <div className="task-list px-2">
              <div>
                <ReduxTable
                  id="partnerApplicationForm"
                  apiURL={`${endpoints().partnerApplicationFormAPI}`}
                  params={{ list: true }}
                  onRowClick={row => {
                    props.history.push(
                      `/admin-settings/${TAB_PARTNER_ONBOARDING}/${PARTNER_APPLICATIONS_FORMS_SUB_TAB}/${row.id}`
                    );
                    setSelectedFormId(row.id);
                    setSelectedFormName(row.name);
                  }}
                  showHeader
                  searchDisabled
                >
                  <ReduxColumn
                    width={"220px"}
                    type="link"
                    isClickable="true"
                    field="name"
                    sortBy="name"
                  >
                    Name
                  </ReduxColumn>
                  <ReduxColumn
                    width={"100px"}
                    field="status"
                    disableOnClick
                    className="action-column"
                    renderField={row => (
                      <div className="text-center landing-group-dropdown">
                        <MoreDropdown>
                          <DropdownItem
                            onClick={() => {
                              setCurrentForm(row);
                              setEditFormModal(true);
                            }}
                          >
                            Edit
                          </DropdownItem>
                          <DropdownItem
                            className={"text-danger"}
                            onClick={() => {
                              setCurrentForm(row);
                              setDeleteFormModal(true);
                            }}
                          >
                            Delete
                          </DropdownItem>
                        </MoreDropdown>
                      </div>
                    )}
                  >
                    Action
                  </ReduxColumn>
                </ReduxTable>
              </div>
            </div>
          </div>
        </>
      ) : (
        <PartnerApplicationsField
          selectedFormName={selectedFormName}
          adminSettings={adminSettings}
          saveSettings={saveSettings}
          history={history}
          match={props.match}
          selectedFormId={selectedFormIdValue}
        />
      )}
    </>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(PartnerApplicationsForm);
