/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Form from "../../components/base/Form";
import PartnerDropDown from "../../components/PartnerDropDown";
import Toast from "../../components/base/Toast";
import Button from "../../components/base/Button";

const InvitePartner = props => {
  const [modal, setModal] = useState(false);

  const toggle = () => setModal(!modal);

  const submit = values => {
    if (values) {
      Toast.success("Invite Partner Successfully");
      toggle();
    }
  };

  return (
    <>
      <Button onClick={toggle} label="Invite Partner to Map" />
      <Modal
        isOpen={modal}
        toggle={() => {
          setModal(false);
        }}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader toggle={toggle}>
          <div className="content-wrapper">
            <div className="icon-wrapper">
              <div className="img-wrapper d-flex justify-content-center mb-2 mt-3"></div>
              <p className="text-center font-weight-bold mb-3">
                Invite Partner
              </p>
              <p className="text-center h6-5 mb-2"></p>
            </div>
            {/* /.icon-wrapper */}
          </div>
          {/* /.content-wrapper */}
        </ModalHeader>

        <Form
          initialValues={{
            partner: ""
          }}
          onSubmit={values => {
            submit(values);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <div>
                <PartnerDropDown
                  label="Partner"
                  name="partner"
                  placeholder="Select Partner"
                  defaultValue="partner"
                  isSearchable={true}
                  isClearable={true}
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button
                  type="submit"
                  label="Invite"
                  className="h6-5-important"
                  disabled={!modal}
                />
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default InvitePartner;
