import React from "react";
import LeftSideSection from "./ineer-components/LeftSideSection";
import AuthButtons, {
  Auth0Button,
  GoogleButton,
  LinkedInButton,
  LoginForm,
  OfficeButton,
  SalesforceButton
} from "../../components/authentication/AuthButtons";
import { OktaIcon } from "../../assets/img/icons";
import {
  SETTINGS_PORTAL_LOGO,
  SETTINGS_PORTAL_NAME,
  BACKGROUND_IMAGE_URL,
  SETTINGS_SECONDARY_PORTAL_API_BASE_URL,
  SETTINGS_SECONDARY_PORTAL_API_DEFAULT_KEY,
  SETTINGS_SECONDARY_PORTAL_REDIRECT_MESSAGE,
  SETTINGS_ENABLE_SALESFORCE_LOGIN,
  SETTINGS_ENABLE_OFFICE_365_LOGIN,
  SETTINGS_ENABLE_GOOGLE_LOGIN,
  SETTINGS_ENABLE_LINKEDIN_LOGIN,
  SETTINGS_LINKEDIN_CLIENT_ID,
  SETTINGS_LINKEDIN_REDIRECT_URI,
  SETTINGS_ENABLE_OKTA_LOGIN,
  SETTINGS_ENABLE_AUTH0_LOGIN,
  SETTINGS_GOOGLE_CLIENT_ID,
  SETTINGS_SALESFORCE_LOGIN_CLIENT_ID,
  SETTINGS_SALESFORCE_LOGIN_REDIRECT_URL,
  SETTINGS_OFFICE365_CLIENT_ID,
  SETTINGS_OFFICE365_REDIRECT_URI,
  SETTINGS_PORTAL_HEADER_TEXT_COLOR,
  SETTINGS_PORTAL_HEADER_COLOR
} from "../../setting/Constants";

// Components
import toast from "../../components/base/Toast";
import SecondaryPortalRedirectModel from "./ineer-components/SecondaryPortalRedirectModel";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints, DEFAULT_API_KEY } from "../../configs";

// Helper
import {
  getCookie,
  setCookie,
  getUrlParameter,
  getRedirectUrl,
  getKeyValueByObject,
  getSettingMediaUrl
} from "../../lib/helper";

import {
  COOKIE_DEFAULT_CURRENCY,
  COOKIE_ROLE,
  COOKIE_SESSION_TOKEN,
  COOKIE_TERMINOLOGY,
  COOKIE_USER_ID
} from "../../lib/cookie";
import { Button } from "reactstrap";
import { isBadRequest } from "../../common/http";
import Logo from "../../components/base/Logo";
import PublicNavigation from "../../components/header/PublicNavigation";
import detectBrowser from "../../components/helpers/detectBrowser";
import { TAG_TYPE_CURRENCY } from "../../tagType/Constants";
import Tag from "../../Constants/Tag";
import UserRolePermissionService from "../../services/UserRolePermissionService";
import { Local } from "../../Constants/LocalStorage";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      height: window.innerHeight,
      showSecondaryPortalRedirectModel: false,
      oktaLoginModal: false,
      isMobile: detectBrowser()
    };
  }

  componentDidMount() {
    const redirectUrl = getRedirectUrl();
    // const redirectUrl = getUrlParameter("redirect");
    const { preventRedirect = false } = this.props;

    if (redirectUrl && !getCookie(COOKIE_SESSION_TOKEN) && !preventRedirect) {
      this.props.history.push(`/login?redirect=${redirectUrl}`);
    } else if (
      getCookie(COOKIE_SESSION_TOKEN) &&
      redirectUrl &&
      !preventRedirect
    ) {
      this.props.history.push(redirectUrl);
    } else if (getCookie(COOKIE_SESSION_TOKEN) && !preventRedirect) {
      // if session_token redirect to dashboard
      this.props.history.push("/dashboard");
    }

    this.getTerminology();
    this.getRolePermission();
    this.getTagValues(TAG_TYPE_CURRENCY);
    window.addEventListener("resize", this._updateDimensions);
  }

  setHeaders = () => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
  };
  getTagValues = async tagType => {
    try {
      this.setHeaders();

      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${tagType}`
      );
      const results = response.data.data;

      // Check the tag type skills
      if (results && results.length > 0) {
        results.forEach(tag => {
          if (
            tag.type == TAG_TYPE_CURRENCY &&
            tag.defaultValue == Tag.IsDefault
          ) {
            setCookie(COOKIE_DEFAULT_CURRENCY, tag.name);
          }
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };
  getTerminology = async () => {
    this.setHeaders();
    const url = `${endpoints().terminologyAPI}`;
    const terminologyName = [];
    const response = await apiClient.get(url);
    const list = response.data.data;
    if (list && list.length > 0) {
      for (let i = 0; i < list.length; i++) {
        terminologyName.push({
          custom_name: list[i].custom_name,
          system_name: list[i].system_name
        });
      }
    }
    localStorage.setItem(Local.TERMINOLOGY, JSON.stringify(terminologyName));
  };

  getRolePermission = async role => {
    const permissionList = await UserRolePermissionService.search(role);
    if (permissionList && permissionList.length > 0) {
      let values = permissionList.map(obj => obj.value);

      // Convert the array to a comma-separated string
      let valuesString = values.join(",");

      localStorage.setItem(Local.PERMISSION, valuesString);
    }
  };

  componentWillUnmount() {
    window.removeEventListener("resize", this._updateDimensions);
  }

  validateSecondaryPortalLogin = (email, callback) => {
    const {
      secondaryPortalApiBaseUrl,
      secondaryPortalBaseUrl,
      secondaryPortalAPIDefaultKey
    } = this.getSecondaryPortalDetails();

    if (
      !secondaryPortalApiBaseUrl &&
      !secondaryPortalBaseUrl &&
      !secondaryPortalAPIDefaultKey &&
      !email
    ) {
      return callback("Secondary portal details not define");
    }
    apiClient.defaults.headers.common.Authorization = secondaryPortalAPIDefaultKey;
    return apiClient
      .get(`${secondaryPortalApiBaseUrl}/v1/user/email/${email}`)
      .then(() => {
        const { showSecondaryPortalRedirectModel } = this.state;

        this.setState({
          showSecondaryPortalRedirectModel: !showSecondaryPortalRedirectModel
        });

        return callback();
      })
      .catch(err => callback(err));
  };

  // Signin with Google OAuth
  loginByGoogle = (googleToken, callback) => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .get(
        `${
          endpoints().userAPI
        }/google/auth/details?googleToken=${googleToken}&isLogin=true`
      )
      .then(() => {
        return apiClient
          .post(endpoints().userLogin, {
            googleToken
          })
          .then(async response => {
            const { token, role, userId } = response.data;

            setCookie(COOKIE_SESSION_TOKEN, token);
            setCookie(COOKIE_USER_ID, userId);
            await this.getTerminology();
            await this.getTagValues();
            await this.getRolePermission(role);

            return callback();
          });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }

          this.validateSecondaryPortalLogin(error.response.data.email, err => {
            if (!err) return null;
            toast.error(error.response.data.message);
          });
        }
      });
  };

  // Signin with Microsoft Office365
  loginByOffice365 = (office365Token, callback) => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .get(
        `${
          endpoints().userAPI
        }/office365/auth/details?office365Token=${office365Token}&isLogin=true`
      )
      .then(() => {
        return apiClient
          .post(endpoints().userLogin, {
            office365Token
          })
          .then(async response => {
            const { token, role, userId } = response.data;

            setCookie(COOKIE_SESSION_TOKEN, token);
            setCookie(COOKIE_USER_ID, userId);
            await this.getTerminology();
            await this.getTagValues();
            await this.getRolePermission(role);

            return callback();
          });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }

          this.validateSecondaryPortalLogin(error.response.data.email, err => {
            if (!err) return null;
            toast.error(error.response.data.message);
          });
        }
      });
  };

  // Signin with Linkedin
  loginByLinkedin = (linkedInToken, callback) => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .get(
        `${
          endpoints().userAPI
        }/linkedin/auth/details?linkedInAccessToken=${linkedInToken}&isLogin=true`
      )
      .then(async response => {
        const { token, role, userId } = response.data;

        setCookie(COOKIE_SESSION_TOKEN, token);
        setCookie(COOKIE_USER_ID, userId);
        await this.getTerminology();
        await this.getTagValues();
        await this.getRolePermission(role);

        return callback();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }

          this.validateSecondaryPortalLogin(error.response.data.email, err => {
            if (!err) return null;
            toast.error(error.response.data.message);
          });
        }
      });
  };

  // Signin with Salesforce
  loginBySalesforce = (salesforceAuthToken, callback) => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .get(
        `${
          endpoints().userAPI
        }/salesforce/auth/details?salesforceToken=${salesforceAuthToken}&isLogin=true`
      )
      .then(() => {
        return apiClient
          .post(endpoints().userLogin, {
            salesforceAuthToken
          })
          .then(async response => {
            const { token, role, userId } = response.data;

            setCookie(COOKIE_SESSION_TOKEN, token);
            setCookie(COOKIE_USER_ID, userId);
            await this.getTerminology();
            await this.getTagValues();
            await this.getRolePermission(role);

            return callback();
          });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }

          this.validateSecondaryPortalLogin(error.response.data.email, err => {
            if (!err) return null;
            toast.error(error.response.data.message);
          });
        }
      });
  };

  // toggle the oktaModal
  toggleOktaModal = () => {
    this.setState({
      oktaLoginModal: !this.state.oktaLoginModal
    });
  };

  getSecondaryPortalDetails() {
    const { settings } = this.props;
    const details = {};

    if (!settings) return details;

    details.secondaryPortalApiBaseUrl = getKeyValueByObject(
      settings,
      SETTINGS_SECONDARY_PORTAL_API_BASE_URL
    );
    details.secondaryPortalBaseUrl = getKeyValueByObject(settings);
    details.secondaryPortalAPIDefaultKey = getKeyValueByObject(
      settings,
      SETTINGS_SECONDARY_PORTAL_API_DEFAULT_KEY
    );
    details.secondaryPortalRedirectMessage = getKeyValueByObject(
      settings,
      SETTINGS_SECONDARY_PORTAL_REDIRECT_MESSAGE
    );

    return details;
  }

  render() {
    const { settings } = this.props;
    const {
      showSecondaryPortalRedirectModel,
      oktaLoginModal,
      isMobile
    } = this.state;

    const marketplaceLogo =
      settings && getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO)
          )
        : "";

    const marketplaceName = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_NAME)
      : "";

    const backgroundImage =
      settings && getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
          )
        : "";

    const enableSalesforceLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_SALESFORCE_LOGIN)
      : "";

    const enableGoogleLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_GOOGLE_LOGIN)
      : "";

    const enableOffice365Login = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_OFFICE_365_LOGIN)
      : "";

    const enableLinkedInLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_LINKEDIN_LOGIN)
      : "";

    const enableOktaLogin = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_OKTA_LOGIN)
      : "";

    const enableAuth0Login = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_AUTH0_LOGIN)
      : "";

    const googleClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_GOOGLE_CLIENT_ID)
      : "";

    const salesforceLoginClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_SALESFORCE_LOGIN_CLIENT_ID)
      : "";

    const salesforceLoginRedirectUrl = settings
      ? getKeyValueByObject(settings, SETTINGS_SALESFORCE_LOGIN_REDIRECT_URL)
      : "";
    const headerTextColor = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_HEADER_TEXT_COLOR)
      : "";
    const headerColor = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_HEADER_COLOR)
      : "";

    const linkedInClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_CLIENT_ID)
      : "";

    const linkedInRedirectUri = settings
      ? getKeyValueByObject(settings, SETTINGS_LINKEDIN_REDIRECT_URI)
      : "";

    const office365ClientId = settings
      ? getKeyValueByObject(settings, SETTINGS_OFFICE365_CLIENT_ID)
      : "";

    const office365RedirectUri = settings
      ? getKeyValueByObject(settings, SETTINGS_OFFICE365_REDIRECT_URI)
      : "";

    const secondaryPortalDetails = this.getSecondaryPortalDetails();
    const {
      secondaryPortalApiBaseUrl,
      secondaryPortalBaseUrl,
      secondaryPortalRedirectMessage
    } = secondaryPortalDetails;

    let redirectUrl = getUrlParameter("redirect");
    if (!redirectUrl) {
      redirectUrl = "/dashboard";
    }

    return (
      <div className="container-fluid" style={{ minHeight: "100vh" }}>
        <div
          className="row overflow-hidden"
          style={{ height: "100%", minHeight: "100vh" }}
        >
          <LeftSideSection
            marketplaceLogo={marketplaceLogo}
            marketplaceName={marketplaceName}
            backgroundImage={backgroundImage}
          />

          <div
            className="col"
            style={{
              background: "#fff",
              overflowY: "scroll",
              margin: "0"
            }}
          >
            {isMobile && (
              <PublicNavigation
                loginPage
                marketplaceLogo={marketplaceLogo}
                portalName={marketplaceName}
                headerColor={headerColor}
                headerTextColor={headerTextColor}
              />
            )}
            <div className="find-an-expert-budget-right-side-section form-wrapper  d-flex justify-content-center">
              <div className="client-login-wrapper">
                <div className="title-wrapper d-flex align-items-center">
                  <h2 className="h1-5 font-weight-bold mb-0">Login</h2>
                </div>
                <AuthButtons>
                  {enableSalesforceLogin === "true" && (
                    <SalesforceButton
                      redirectUrl={redirectUrl}
                      signUpWithSalesforce={this.loginBySalesforce}
                      eventKey="salesforce-login"
                      salesforceLoginClientId={salesforceLoginClientId}
                      salesforceLoginRedirectUrl={salesforceLoginRedirectUrl}
                    />
                  )}

                  {enableGoogleLogin === "true" && (
                    <GoogleButton
                      redirectUrl={redirectUrl}
                      signUpWithGoogle={this.loginByGoogle}
                      eventKey="google-login"
                      googleClientId={googleClientId}
                    />
                  )}

                  {enableOffice365Login === "true" && (
                    <OfficeButton
                      redirectUrl={redirectUrl}
                      signUpWithOffice365={this.loginByOffice365}
                      eventKey="microsoft-office265-signup"
                      office365ClientId={office365ClientId}
                      office365RedirectUri={office365RedirectUri}
                    />
                  )}

                  {enableLinkedInLogin === "true" && (
                    <LinkedInButton
                      redirectUrl={redirectUrl}
                      signUpWithLinkedin={this.loginByLinkedin}
                      eventKey="linkedin-signup"
                      linkedInClientId={linkedInClientId}
                      linkedInRedirectUri={linkedInRedirectUri}
                    />
                  )}

                  {enableAuth0Login === "true" && (
                    <Auth0Button
                      redirectUrl={redirectUrl}
                      eventKey="auth0-login"
                      enableAuth0Login={enableAuth0Login}
                    />
                  )}
                </AuthButtons>
                {(enableSalesforceLogin === "true" ||
                  enableGoogleLogin === "true" ||
                  enableOffice365Login === "true" ||
                  enableLinkedInLogin === "true" ||
                  enableOktaLogin === "true" ||
                  enableAuth0Login === "true") && (
                  <div className="login-form-inline-title">
                    <p>or log in to your {marketplaceName} account:</p>
                  </div>
                )}

                {secondaryPortalApiBaseUrl && secondaryPortalBaseUrl && (
                  <SecondaryPortalRedirectModel
                    redirectPortalUrl={`${secondaryPortalBaseUrl}/login`}
                    secondaryPortalRedirectMessage={
                      secondaryPortalRedirectMessage
                    }
                    isOpen={showSecondaryPortalRedirectModel}
                    toggle={() =>
                      this.setState({
                        showSecondaryPortalRedirectModel: !showSecondaryPortalRedirectModel
                      })
                    }
                  />
                )}

                <LoginForm
                  secondaryPortalDetails={secondaryPortalDetails}
                  settings={settings}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default Login;
