import { Badge, Input } from "reactstrap";
import React, { useState } from "react";
import { Link } from "react-router-dom";

// Icons
import { TrashIconAlt } from "../../../assets/img/icons";

// Components
import Label from "../../../components/base/Label";
import Avatar from "../../../components/base/Avatar";
import { setExpertBadge } from "../../../lib/helper";

import {
  PROJECT_USER_STATUS_SHORTLISTED,
  PROJECT_USER_STATUS_NO_RESPONSE,
  PROJECT_USER_STATUS_APPROVED
} from "../../../project/Constants";
import DeleteModal from "../../../components/base/DeleteModal";

const SourcedPartner = props => {
  const [openDeleteModal, setOpenDeleteModal] = useState(false);
  const [isDisable, setIsDisable] = useState(false);
  const deletePartner = del => {
    props.deleteProjectPartner(del);
    setIsDisable(true);
  };
  if (props.selectedPartnerStatus === PROJECT_USER_STATUS_APPROVED) {
    return (
      <div className="field-wrapper" key={props.partnerid}>
        <div className="user-wrapper">
          <div className="user-actions">
            <a
              className="text-inline-grayed mr-1 source-more cursor-pointer"
              onClick={() => setOpenDeleteModal(true)}
            >
              <TrashIconAlt disabled={isDisable} />
            </a>
            <div
              className={`form-wrapper checkbox-wrapper  ${
                props.hideCheckBox ? "invisible mr-3" : "mr-2"
              }`}
            >
              <DeleteModal
                isOpen={openDeleteModal}
                toggle={() => {
                  setOpenDeleteModal(false);
                }}
                title="Delete Partner"
                label={props.companyName}
                deleteFunction={() => {
                  !isDisable && deletePartner(props.partnerid);
                }}
              />
              <Label>
                <Input
                  type="checkbox"
                  name="user_radio"
                  value={props.partnerid}
                  onChange={e => {
                    props.handleSelectShortListedPartner(props.partnerID);
                  }}
                  disabled={props.isWaitingStatus}
                />
                {props.isShortListedStatus ? (
                  <p className="d-flex align-items-center justify-content-start">
                    <span
                      className={`checkbox-placeholder ${
                        props.selectedOptions.indexOf(props.partnerID) > -1
                          ? "active"
                          : ""
                      }`}
                    />
                  </p>
                ) : (
                  <p className="d-flex align-items-center justify-content-start">
                    <span style={{ width: "20px", height: "20px" }} />
                  </p>
                )}
              </Label>
            </div>
          </div>

          <div className="field-icon">
            <Link target="_blank" to={`/partner-profile/${props.profileUrl}`}>
              <Avatar
                firstName={props.companyName}
                lastName={""}
                size="xs"
                fontSize={12}
                url={props.avatarUrl}
              />
            </Link>
          </div>
          <div className="expert-info ml-2 d-flex flex-column align-items-start">
            <Link
              target="_blank"
              to={`/partner-profile/${props.profileUrl}`}
              className={"font-weight-bold h7 text-truncate text-dark"}
            >
              {props.companyName ? props.companyName : ""}
            </Link>

            <div
              className={[
                "status-tooltips d-flex",
                `${props.status.toLowerCase() === "no response" &&
                  "align-items-start"}`
              ].join(" ")}
            >
              <Badge
                id={`status-tooltip-${props.partnerid}`}
                color={setExpertBadge(props.status)}
                className="text-white h7 font-weight-bold text-uppercase"
              >
                {props.status}
              </Badge>
            </div>
          </div>
        </div>
      </div>
    );
  } else {
    return null;
  }
};

const SourcedPartnerList = props => {
  const {
    selectedPartnerList,
    deleteProjectPartner,
    handleSelectShortListedPartner,
    selectedOptions,
    redirectUrl,
    hideCheckBox
  } = props;

  return (
    <>
      {selectedPartnerList &&
        selectedPartnerList.map(partner => {
          const isShortListedStatus =
            partner.status === PROJECT_USER_STATUS_SHORTLISTED;
          const isWaitingStatus =
            partner.status === PROJECT_USER_STATUS_NO_RESPONSE;
          return (
            <div className="field-wrapper" key={partner.id}>
              <SourcedPartner
                partnerid={partner.id}
                partnerID={partner.partnerId}
                isWaitingStatus={isWaitingStatus}
                companyName={partner.companyName}
                status={partner.status}
                deleteProjectPartner={() => deleteProjectPartner(partner.id)}
                avatarUrl={partner.avatarUrl}
                isShortListedStatus={isShortListedStatus}
                hideCheckBox={hideCheckBox}
                selectedOptions={selectedOptions}
                handleSelectShortListedPartner={handleSelectShortListedPartner}
                redirectUrl={redirectUrl}
                profileUrl={partner.profile_url}
                selectedPartnerStatus={partner.partnerStatus}
              />
            </div>
          );
        })}
    </>
  );
};

export default SourcedPartnerList;
