import { faCross } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import Button from "./base/Button";
import SelectDropdown from "./base/SelectDropdown";
import PageSearch from "./base/PageSearch";
import Drawer from "./Drawer";
import Select from "./base/Select";
import DateInput from "./base/Date";
import {
  getParamsByName,
  isCompanyAdmin,
  isCompanyManager,
  isSuperAdmin
} from "../lib/helper";
import Partner from "./Partner";
import CheckboxDropdownSelect from "./base/CheckboxDropdownSelect";
import Region from "./Region";
import PageSize from "./PageSize";

const Filter = props => {
  let { initialValues, handleDeleteFilter } = props;

  const [displayFilter, setDisplayFilter] = useState(true);
  const [isModalOpen, setIsModalOpen] = useState();
  const [isHovered, setIsHovered] = useState(false);

  let hideParamsValue = {
    objectName: true,
    pagination: true
  };

  const handleRemoveFilter = obj => {
    let key = obj.key;
    let value = obj.value;

    if (key === "status" && value !== undefined) {
      // Remove the specific value from the array
      handleDeleteFilter && handleDeleteFilter({ [key]: value });
    } else {
      // If it's not an array or no specific value provided, remove the entire filter
      let objectvalue = {};
      objectvalue[key] = "";
      handleDeleteFilter && handleDeleteFilter(objectvalue);
    }
  };

  let filteredArrayValues = [];
  let filteredObjectValues = [];
  let filteredNormalValues = [];
  let filteredValues = [];

  if (initialValues) {
    const filteredData = Object.fromEntries(
      Object.entries(initialValues).filter(([key, value]) => {
        if (value !== undefined && value !== null && value !== "") {
          if (key === "status" && Array.isArray(value)) {
            // Handle array of objects (status options)
            const statusValues = value.map(statusObj => statusObj.value);
            return statusValues.length > 0;
          } else {
            return true;
          }
        }
        return false;
      })
    );

    if (filteredData) {
      const arrayValues = [];
      const objectValues = [];
      const normalValues = [];

      for (const [key, value] of Object.entries(filteredData)) {
        if (Array.isArray(value)) {
          // Handle array of objects (status options)
          const statusValues = value.map(statusObj => statusObj.value);
          arrayValues.push({ [key]: statusValues });
        } else if (typeof value === "object" && value !== null) {
          objectValues.push({ [key]: value });
        } else {
          normalValues.push({ [key]: value });
        }
      }

      for (let item of objectValues) {
        const key = Object.keys(item)[0];
        const value = item[key];
        let filteredKey =
          Object.keys(hideParamsValue).find(values => values === key) || "";
        if (value !== undefined && !filteredKey) {
          if (key === "product") {
            const url =
              (value &&
                value.label &&
                value.label.props &&
                value.label.props.url) ||
              undefined;
            filteredObjectValues.push({ [key]: url });
          } else {
            filteredObjectValues.push({
              [key]: (value && value.label) || undefined
            });
          }
        }
      }
      for (let item of normalValues) {
        const key = Object.keys(item)[0];
        const value = item[key];
        let filteredKey =
          Object.keys(hideParamsValue).find(values => values === key) || "";
        if (value !== undefined && !filteredKey) {
          filteredNormalValues.push({ [key]: value });
        }
      }
      for (let item of arrayValues) {
        const key = Object.keys(item)[0];
        const values = item[key];
        for (let value of values) {
          filteredValues.push({ [key]: value });
        }
      }
    }
  }
  let mergedValues = [
    ...filteredArrayValues,
    ...filteredObjectValues,
    ...filteredNormalValues,
    ...filteredValues
  ];

  const groupedFilters = mergedValues.reduce((acc, obj) => {
    const key = Object.keys(obj)[0];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj[key]);
    return acc;
  }, {});
  const modelBody = (
    <>
      <div className="flex: 1 1 0%; padding: 16px 16px 0px; overflow-y: auto;">
        {/*  Date Filter */}

        {/* Payment Type Filter */}
        {props.showDateFilter && (
          <div>
            <div>
              <DateInput
                name="start_date"
                className="datepicker"
                placeholder="Start Date"
                onChange={props.handleStartDate}
                format="dd-MMM-yyyy"
                isClearable
              />
            </div>
            <div>
              <DateInput
                name="end_date"
                className="datepicker"
                placeholder="End Date"
                format="dd-MMM-yyyy"
                onChange={props.handleEndDate}
                isClearable
              />
            </div>
          </div>
        )}

        <div>
          {(isSuperAdmin(props.loggedInUser && props.loggedInUser.roleId) ||
            isCompanyAdmin(props.loggedInUser && props.loggedInUser.roleId) ||
            isCompanyManager(
              props.loggedInUser && props.loggedInUser.roleId
            )) &&
            props.showPartnerFilter && (
              <Partner
                partnerid="refferalPartner"
                name="partner"
                partner_id={props.selectedPartnerId}
                placeholder="Select Partner"
                defaultValue="partner"
                isSearchable={true}
                isClearable={true}
                handleChange={currentVal => {
                  props.handlePartnerChange(currentVal);
                }}
              />
            )}
        </div>
        {props.showReferralStatusFilter && (
          <div className="mb-3">
            <CheckboxDropdownSelect
              name="status"
              buttonLabel="Status"
              width="100%"
              dropdownLinks={props.statusOptions}
              handleChange={values => {
                props.handleStatusChange(values);
              }}
              checkedItems={props.selectedStatus}
              color="gray"
              hideCaret
            />
          </div>
        )}
        {props.showPartnerStatusFilter && (
          <div className="mb-3">
            <Select
              name="status"
              buttonLabel="Status"
              placeholder="Select Status"
              width="100%"
              options={props.partnerStatusOptions}
              defaultValue={props.defaultStatus}
              handleChange={values => {
                props.handleStatusChange(values);
              }}
            />
          </div>
        )}
        {props.showAccountFilter && (
          <div className="mb-3">
            <Select
              name="account"
              buttonLabel="Account"
              placeholder="Select Account"
              width="100%"
              isSearchable={true}
              options={props.accountOptions}
              defaultValue={props.defaultAccount}
              isClearable
              showVirtualizedMenu
              handleChange={values => {
                props.handleAccountChange(values);
              }}
            />
          </div>
        )}
        {props.showPartnerTypeFilter && (
          <Select
            name="partnerType"
            placeholder="Select Partner type"
            isSearchable={true}
            defaultValue={props.defaultPartnerTypeId}
            options={props.partnerTypeOptions}
            handleChange={e => {
              props.handletypeChange(e);
            }}
            isClearable
          />
        )}
        {props.showPartnerTierFilter && (
          <Select
            name="partnerTier"
            placeholder="Select Partner Tier"
            isSearchable={true}
            defaultValue={props.defaultPartnerTierId}
            options={props.partnerTierOptions}
            handleChange={e => {
              props.handletierChange(e);
            }}
            isClearable
          />
        )}
        {props.showRegionFilter == true && (
          <Region
            id="region"
            name="region"
            background="#4d4d4d"
            placeholder="Select Region"
            onChange={e => {
              props.handleRegionChange && props.handleRegionChange(e);
            }}
            isClearable
          />
        )}
        <div>
          {(isSuperAdmin(props.loggedInUser && props.loggedInUser.roleId) ||
            isCompanyAdmin(props.loggedInUser && props.loggedInUser.roleId) ||
            isCompanyManager(
              props.loggedInUser && props.loggedInUser.roleId
            )) &&
            props.showTypeFilter &&
            props.referralRequestEnabled && (
              <Select
                name="referralType"
                placeholder="Select type"
                isSearchable={true}
                defaultValue={props.defaultReferralType}
                options={props.referralTypeOption}
                handleChange={e => {
                  props.handleReferralTypeChange(e);
                }}
              />
            )}
        </div>
      </div>
    </>
  );
  const handleOpenModal = () => {
    setIsModalOpen(!isModalOpen);
  };
  return (
    <>
      <Drawer
        DrawerBody={modelBody}
        initialValues={initialValues}
        handleOpenModal={handleOpenModal}
        handleCloseModal={handleOpenModal}
        handleDrawerClose={handleOpenModal}
        isModalOpen={isModalOpen}
        enableReinitialize
        className="filter-width"
        modelTitle="Filter"
      />
      {props.showHeader && !props.disableHeader && (
        <>
          {props.newTableHeading && displayFilter ? (
            // To display show and hide filter using this id---> filterSection
            <div style={{ display: "block" }}>
              <div className="page-heading  cover ">
                <div
                  className={` d-flex align-items-start ${
                    !props.showSearch
                      ? "justify-content-end"
                      : "justify-content-between"
                  }`}
                >
                  <div className="mr-1 mt-1">
                    <PageSize
                      onChange={e =>
                        props.handlePageSizeChange &&
                        props.handlePageSizeChange(e)
                      }
                    />
                  </div>
                  {/* <div className="col-9"> */}
                  {props.showSearch && (
                    <PageSearch
                      classnames="page-search mt-1"
                      placeholder="Search"
                      onChange={props.pageSearchOnChange}
                      value={props.search}
                    />
                  )}
                  {props.sortByDropdown && (
                    <div className="d-none d-sm-block mt-1">
                      <SelectDropdown
                        buttonLabel={
                          props.dropdownLabel
                            ? props.dropdownLabel
                            : props.getSelectedSortLabel
                        }
                        dropdownLinks={props.sortByOptions}
                        hideCaret
                        handleChange={props.handleSortByChange}
                        color={"gray"}
                        selectName={"sortby_experts"}
                      />
                    </div>
                  )}

                  <Button
                    label={""}
                    onClick={handleOpenModal}
                    className={"btn btn-primary fa fa-filter bg-dark"}
                  />
                </div>
                <div className="row container w-100 mt-2">
                  {groupedFilters &&
                    Object.entries(groupedFilters).map(([key, values]) => (
                      <div className="mobile-filter-container" key={key}>
                        <p className="filter-label">{key}:</p>
                        <div className="filter-values">
                          {values.map((value, index) => (
                            <div key={index} className="filter-value">
                              <p className="badges font-size-12">
                                {value}
                                <span
                                  onClick={() =>
                                    handleRemoveFilter({ key, value })
                                  }
                                  className={`far fa-lg fa-times-circle remove-icon ${
                                    isHovered ? "hovered" : ""
                                  }`}
                                />
                              </p>
                            </div>
                          ))}
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          ) : (
            ""
          )}
        </>
      )}
    </>
  );
};

export default Filter;
