import React, { Component } from "react";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCustomer,
  isExpert,
  getKeyValueByObject,
  isCompanyManager,
  getUrlPath
} from "../../../lib/helper";

import {
  EXPERT_STATUS_APPROVED,
  EXPERT_STATUS_DECLINED
} from "../../../expertStatus/Constants";
import {
  PROJECT_USER_STATUS_SHORTLISTED,
  PROJECT_USER_STATUS_NO_RESPONSE,
  PROJECT_USER_STATUS_INTERESTED,
  PROJECT_USER_STATUS_WAITING_FOR_APPROVAL,
  PROJECT_USER_STATUS_NOT_INTERESTED,
  PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE,
  PROJECT_USER_STATUS_DECLINED,
  PROJECT_TYPE_CAMPAIGN_URL_PATH,
  PROJECT_TYPE_PROJECT_URL_PATH
} from "../../../project/Constants";
import { PLAYBOOK_PRICING_TYPE_SUBSCRIPTION } from "../../../playbook/Constants";
import {
  SETTINGS_ENABLE_EXPERTS,
  SETTINGS_ENABLE_PARTNERS
} from "../../../setting/Constants";

// Components
import SourceMoreExperts from "./SourceMoreExperts";
import SourceMoreWaitingExperts from "./SourceMoreWaitingExperts";
import ProjectTeam from "./ProjectTeam";
import ExpertModal from "./ExpertModal";
import MessageAccountManagerModal from "./MessageAccountManagerModal";
import AccountManageModal from "./AccountManageModal";
import KickOffSidebarInfo from "./kickoff/KickOffSidebarInfo";
import ProjectSubscription from "./ProjectSubcription";

// API Call
import { apiClient } from "../../../apiClient";
import { endpoints } from "../../../configs";
import toast from "../../../components/base/Toast";

// Components
import SidebarCustomerExpertMatched from "./sidebar-items/SidebarCustomerExpertMatched";
import SidebarCustomerAndAdminInvoicing from "./sidebar-items/SidebarCustomerAndAdminInvoicing";
import SidebarClientInfo from "./sidebar-items/SidebarClientInfo";
import SidebarApprovedExpertList from "./sidebar-items/SidebarApprovedExpertList";
import SidebarItem from "./sidebar-items/SidebarItem";
import SidebarSupportingMaterials from "./sidebar-items/SidebarSupportingMaterials";
import KickoffProvider from "../../../context/kickoffContext/KickoffProvider";
import SourcePartners from "./SourcePartners";
import CampaignTimeline from "./CampaignTimeline";
import { getErrorMessage, isBadRequest } from "../../../common/http";

import { connect } from "react-redux";

// Project source more experts pending list statuses
const sourceMoreExpertsPendingListStatuses = [
  PROJECT_USER_STATUS_INTERESTED,
  PROJECT_USER_STATUS_WAITING_FOR_APPROVAL,
  PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE
];

// Project source more experts waiting list statuses
const sourceMoreExpertsWaitingListStatuses = [
  PROJECT_USER_STATUS_SHORTLISTED,
  PROJECT_USER_STATUS_NO_RESPONSE,
  PROJECT_USER_STATUS_NOT_INTERESTED,
  PROJECT_USER_STATUS_DECLINED
];

// Project source more experts waiting for acceptance statuses
const sourceMoreExpertsWaitingForAcceptanceStatuses = [
  PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE
];

class ProjectDetailsSidebar extends Component {
  constructor(props) {
    const { startDate, endDate } = props.projectDetails;

    super(props);
    // Set the initial input values
    this.state = {
      isExpertLoading: false,
      expertList: [],
      formattedEndDate: "",
      formattedStartDate: "",
      isProjectExpertLoading: false,
      projectExpertList: [],
      projectExpertWaitingList: [],
      pendingProjectExpertList: [],
      isExpertModal: false,
      expertIds: [],
      expertRequestId: "",
      messageAccountManagerModal: false,
      changeAccountManagerModal: false,
      supportingMaterials: "",
      expertStatus: "",
      accountManagerMessage: "",
      selectedProjectTeamMemberFirstName: "",
      selectedProjectTeamMemberLastName: "",
      selectedProjectTeamMemberId: "",
      updateBudgetModal: false,
      requestedUpdatedBudget: "",
      timelineModal: false,
      projectTimelineStart: startDate ? new Date(startDate) : new Date(),
      projectTimelineEnd: endDate ? new Date(endDate) : new Date(),
      clientModal: false,
      subcriptionStatusModal: false,
      expertWaitingRequestId: [],
      checkBox: {
        shortListedExperts: []
      },
      isProjectWaitingForAcceptance: false,
      projectWaitingForAcceptanceExpertList: [],
      editInterestStatusModal: false,
      declineExpertModal: false,
      selectedEditInterestExpertId: "",
      defaultAccountManagerList: [],
      selectedAccountManagerId: "",
      expertCount: 0
    };

    this.toggle = this.toggle.bind(this);
    this.handleExperts = this.handleExperts.bind(this);
    this.sendExpertRequestApproval = this.sendExpertRequestApproval.bind(this);
    this.handleExpertRequestApproval = this.handleExpertRequestApproval.bind(
      this
    );
    this.updateExpertList = this.updateExpertList.bind(this);
    this.toggleUpdateBudgetModal = this.toggleUpdateBudgetModal.bind(this);
    this.requestedUpdatedBudget = this.requestedUpdatedBudget.bind(this);
    this.setTimelineModal = this.setTimelineModal.bind(this);
    this.setClientModal = this.setClientModal.bind(this);
    this._handleShortListedExperts = this._handleShortListedExperts.bind(this);
    this.setEditInterestStatusModal = this.setEditInterestStatusModal.bind(
      this
    );
  }

  componentDidMount() {
    // get Project source more experts (interested, waiting for approval and waiting for acceptance)
    this._getSourceMoreExpertsPendingList(
      true,
      sourceMoreExpertsPendingListStatuses.join(", ")
    );
    // get Project source more experts (waiting for interest, not interested)
    this._getSourceMoreExpertsWaitingList(
      true,
      sourceMoreExpertsWaitingListStatuses.join(", ")
    );
    // get Project source more experts (waiting for acceptance)
    this._getSourceMoreExpertsWaitingForAcceptanceList(
      true,
      sourceMoreExpertsWaitingForAcceptanceStatuses
    );
    this._getExpertsList(this.props.projectDetails.id);
    this.getProjectDetails(this.props.projectDetails.id);
    if (
      isSuperAdmin(this.props.roleId) ||
      isCompanyAdmin(this.props.roleId) ||
      isCompanyManager(this.props.roleId)
    ) {
      this.getDefaultAccountManagerList();
    }
  }

  componentDidUpdate(nextProps) {
    if (
      nextProps.pendingProjectUsers &&
      nextProps.pendingProjectUsers !== this.props.pendingProjectUsers
    ) {
      // get Project source more experts (waiting for acceptance)
      this._getSourceMoreExpertsWaitingForAcceptanceList(
        true,
        sourceMoreExpertsWaitingForAcceptanceStatuses
      );
      this.getProjectDetails(this.props.projectDetails.id);
    }
  }

  // Handle experts
  handleExperts(value) {
    if (!value) {
      return this.setState({ expertIds: [] });
    }

    const expertIds = this.state.expertIds.slice();
    if (expertIds.indexOf(value) > -1) {
      this.setState({ expertIds: expertIds.filter(el => el !== value) });
    } else {
      // Otherwise, add more elements to the list.
      this.setState({ expertIds: expertIds.concat(value) });
    }
  }

  // Handle experts requests approval
  handleExpertRequestApproval(value) {
    this.setState({
      expertRequestId: value.expertId,
      expertStatus: value.expertStatus
    });
  }

  handleExpertSendWaitingRequest(value) {
    this.setState({
      expertWaitingRequestId: value.expertId,
      expertWaitingStatus: value.expertStatus
    });
  }

  _handleShortListedExperts(e) {
    const newSelection = parseInt(e.target.value, 10);
    let newSelectionArray;

    if (this.state.checkBox.shortListedExperts.indexOf(newSelection) > -1) {
      newSelectionArray = this.state.checkBox.shortListedExperts.filter(
        s => s !== newSelection
      );
    } else {
      newSelectionArray = [
        ...this.state.checkBox.shortListedExperts,
        parseInt(newSelection, 10)
      ];
    }

    this.setState(prevState => ({
      checkBox: {
        ...prevState.checkBox,
        shortListedExperts: newSelectionArray
      }
    }));
  }

  // Update Expert List
  updateExpertList = selectedFilters => {
    this._getExpertsList(this.props.projectDetails.id, selectedFilters);
  };

  // Call project material API
  uploadSupportingMaterial = data => {
    apiClient
      .post(`${endpoints().projectAPI}/material`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this.setState({
            supportingMaterials: response.data.supportingMaterials || ""
          });
        }
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Update Project
  updateProject(data) {
    const projectId = this.props.projectDetails.id;
    return apiClient
      .put(`${endpoints().projectAPI}/${projectId}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this.getProjectDetails(projectId);
          toast.success(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  // Update Project Subscription Status
  updateProjectSubscriptionStatus(data) {
    const projectId = this.props.projectDetails.id;
    return apiClient
      .put(`${endpoints().projectAPI}/subscription/status/${projectId}`, data)
      .then(response => {
        if (response && response.data) {
          this.props.handleProjectDetails(this.props.projectId);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  // Project details api call by id
  getProjectDetails = projectId => {
    return apiClient
      .get(`${endpoints().projectAPI}/${projectId}`)
      .then(response => {
        this.setState({
          formattedEndDate: response.data.formattedEndDate,
          formattedStartDate: response.data.formattedStartDate,
          isLoading: true
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  // Handle supporting material
  handleMaterialUpload = e => {
    const file = e.target.files[0];
    const data = new FormData();
    data.append("file", file);
    data.append("projectId", this.props.projectDetails.id);

    file && this.uploadSupportingMaterial(data);
  };

  // Handle Modal Click
  toggle = () => {
    this.setState({ isExpertModal: !this.state.isExpertModal });
  };

  // Get Expert List
  _getExpertsList(projectId, selectedFilters, initialExpertCount) {
    let tags = [],
      city = "",
      isAvailability = "",
      availability = "",
      certificates = "",
      levelOfExperience = "";

    // Check filters selected
    if (selectedFilters) {
      city = selectedFilters.city;
      tags = selectedFilters.tags;
      isAvailability = selectedFilters.isAvailability;
      availability = selectedFilters.availability;
      levelOfExperience = selectedFilters.levelOfExperience;
      certificates = selectedFilters.certificates;
    }
    this.setState({ isExpertLoading: true }, () => {
      apiClient
        .get(
          `${
            endpoints().expertAPI
          }/project/${projectId}?marketplaceStatus=${EXPERT_STATUS_APPROVED},${EXPERT_STATUS_DECLINED}&tags=${tags}&city=${city}&isAvailable=${isAvailability}&availability=${availability}&levelOfExperience=${levelOfExperience}&certificate=${certificates}`
        )
        .then(response => {
          const expertList = response.data.data;
          if (initialExpertCount) {
            this.setState({
              expertCount: expertList.length || 0
            });
          }

          this.setState({
            isExpertLoading: false,
            expertList: expertList ? expertList : this.state.expertList
          });
        });
    });
  }

  // Get Project User Pending List
  _getSourceMoreExpertsPendingList(isProjectUser, status) {
    const projectId = this.props.projectId;
    this.setState({ isProjectExpertLoading: isProjectUser }, () => {
      apiClient
        .get(
          `${
            endpoints().projectUserAPI
          }?projectId=${projectId}&status=${status}`
        )
        .then(response => {
          const projectExpertList = response.data.data;

          const selectedIds = [];
          if (projectExpertList) {
            projectExpertList.map(expert => selectedIds.push(expert.expertId));
          }

          this.setState({
            expertIds: selectedIds,
            isProjectExpertLoading: !isProjectUser,
            projectExpertList: projectExpertList ? projectExpertList : ""
          });
        });
    });
  }

  // Get Project User Waiting For Acceptance List
  _getSourceMoreExpertsWaitingForAcceptanceList(
    isProjectWaitingForAcceptance,
    status
  ) {
    const projectId = this.props.projectId;
    this.setState(
      { isProjectWaitingForAcceptance: isProjectWaitingForAcceptance },
      () => {
        apiClient
          .get(
            `${
              endpoints().projectUserAPI
            }?projectId=${projectId}&status=${status}`
          )
          .then(response => {
            const data = response.data;
            const projectWaitingForAcceptanceExpertList = data.data;

            this.setState({
              isProjectWaitingForAcceptance: !isProjectWaitingForAcceptance,
              projectWaitingForAcceptanceExpertList: projectWaitingForAcceptanceExpertList
                ? projectWaitingForAcceptanceExpertList
                : ""
            });
          });
      }
    );
  }

  // Get Project User Waiting List
  _getSourceMoreExpertsWaitingList(isProjectUser, status) {
    const projectId = this.props.projectId;
    this.setState({ isProjectExpertLoading: isProjectUser }, () => {
      apiClient
        .get(
          `${
            endpoints().projectUserAPI
          }?projectId=${projectId}&status=${status}`
        )
        .then(response => {
          const projectExpertWaitingList = response.data.data;

          const selectedIds = [];
          if (projectExpertWaitingList) {
            projectExpertWaitingList.map(expert =>
              selectedIds.push(expert.expertId)
            );
          }

          this.setState({
            expertIds: selectedIds,
            isProjectExpertLoading: !isProjectUser,
            projectExpertWaitingList: projectExpertWaitingList
              ? projectExpertWaitingList
              : ""
          });
        });
    });
  }

  // Get Project User List
  _getProjectUserList(isProjectUser, status) {
    const projectId = this.props.projectId;
    this.setState({ isProjectExpertLoading: isProjectUser }, () => {
      apiClient
        .get(
          `${
            endpoints().projectUserAPI
          }?projectId=${projectId}&status=${status}`
        )
        .then(response => {
          if (status === PROJECT_USER_STATUS_NO_RESPONSE) {
            const projectExpertWaitingList = response.data.data;

            const selectedIds = [];
            if (projectExpertWaitingList) {
              projectExpertWaitingList.map(expert =>
                selectedIds.push(expert.expertId)
              );
            }

            this.setState({
              expertIds: selectedIds,
              isProjectExpertLoading: !isProjectUser,
              projectExpertWaitingList: projectExpertWaitingList
                ? projectExpertWaitingList
                : ""
            });
          }
        });
    });
  }

  // Create Project User
  createProjectExpert() {
    const { expertIds } = this.state;
    const projectId = this.props.projectId;

    const data = {
      expertIds: expertIds,
      projectId: projectId,
      status: PROJECT_USER_STATUS_SHORTLISTED
    };

    return apiClient
      .post(endpoints().projectUserAPI, data)
      .then(() => {
        this._getSourceMoreExpertsWaitingList(
          true,
          sourceMoreExpertsWaitingListStatuses.join(", ")
        );
        this.toggle();
        this.props.handleProjectDetails(this.props.projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Delete the project pending user
  deleteProjectExpert(id) {
    return apiClient
      .delete(`${endpoints().projectUserAPI}/${id}`)
      .then(() => {
        this._getSourceMoreExpertsPendingList(
          true,
          sourceMoreExpertsPendingListStatuses.join(", ")
        );
        this.props.handleProjectDetails(this.props.projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Delete the project waiting list user
  deleteProjectWaitingExpert(id) {
    return apiClient
      .delete(`${endpoints().projectUserAPI}/${id}`)
      .then(() => {
        this._getSourceMoreExpertsWaitingList(
          true,
          sourceMoreExpertsWaitingListStatuses.join(", ")
        );
        this.props.handleProjectDetails(this.props.projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Send Expert Request Client Approval
  sendExpertRequestApproval() {
    const projectId = this.props.projectId;
    const expertRequestId = this.state.expertRequestId;

    const data = {
      expertRequestId: expertRequestId,
      projectId: projectId,
      status: PROJECT_USER_STATUS_WAITING_FOR_APPROVAL
    };

    // Clear current requested expert status and id
    this.setState({ expertRequestId: "", expertStatus: "" });

    return apiClient
      .post(`${endpoints().projectUserAPI}/sendExpertRequest`, data)
      .then(() => {
        this._getSourceMoreExpertsPendingList(
          true,
          sourceMoreExpertsPendingListStatuses.join(", ")
        );
        this.props.handleProjectDetails(this.props.projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Send verify expert availability
  sendVerifyExpertAvailability() {
    const data = {
      expertRequestIds: this.state.checkBox.shortListedExperts,
      projectId: this.props.projectId,
      status: PROJECT_USER_STATUS_NO_RESPONSE
    };

    // Clear current requested expert status and id
    this.setState({ expertWaitingRequestId: "", expertWaitingStatus: "" });

    return apiClient
      .post(`${endpoints().projectUserAPI}/sendVerifyExpertAvailablity`, data)
      .then(() => {
        this._getSourceMoreExpertsWaitingList(
          true,
          sourceMoreExpertsWaitingListStatuses.join(", ")
        );
        this.props.handleProjectDetails(this.props.projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  toggleMessageAccountManagerModal = (id, firstName, lastName) => {
    this.setState({
      messageAccountManagerModal: !this.state.messageAccountManagerModal,
      selectedProjectTeamMemberId: id,
      selectedProjectTeamMemberFirstName: firstName,
      selectedProjectTeamMemberLastName: lastName
    });
  };

  // Show/Hide Account Manager Modal
  toggleAccountManagerModal = () => {
    this.setState({
      changeAccountManagerModal: !this.state.changeAccountManagerModal
    });
  };

  // Change Account Manager
  toggleChangeAccountManager = id => {
    this.setState({
      changeAccountManagerModal: !this.state.changeAccountManagerModal,
      selectedAccountManagerId: id
    });
  };

  toggleUpdateBudgetModal = () => {
    this.setState({
      updateBudgetModal: !this.state.updateBudgetModal
    });
  };

  setTimelineModal = () => {
    this.setState({
      timelineModal: !this.state.timelineModal
    });
  };

  setProjectTimeline = (date, stateProperty) => {
    this.setState({ [stateProperty]: date });
  };

  setClientModal = () => {
    this.setState({
      clientModal: !this.state.clientModal
    });
  };

  setSubcriptionStatusModal = () => {
    this.setState({
      subcriptionStatusModal: !this.state.subcriptionStatusModal
    });
  };

  onChangeMessage = e => {
    this.setState({ accountManagerMessage: e.target.value });
  };

  requestedUpdatedBudget = e => {
    this.setState({ requestedUpdatedBudget: e.target.value });
  };

  // Send Message to account manager
  sendMessageToAccountManager = (project_id, id) => {
    apiClient
      .post(
        `${
          endpoints().projectAPI
        }/team/send/message/${id}?project_id=${project_id}`,
        { message: this.state.accountManagerMessage }
      )
      .then(response => {
        this.toggleMessageAccountManagerModal();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  /**
   * Send reminder for invoice to client
   */
  sendInvoiceClientReminder = (
    projectId,
    customerId,
    projectName,
    estimatedBudget
  ) => {
    apiClient
      .post(`${endpoints().invoiceAPI}/client/reminder`, {
        projectId,
        customerId,
        projectName,
        estimatedBudget
      })
      .then(response => {})
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  setEditInterestStatusModal = expertId => {
    this.setState({
      editInterestStatusModal: !this.state.editInterestStatusModal,
      selectedEditInterestExpertId: expertId
    });
  };

  /**
   * Admin update the shortlisted expert interest status
   */
  updateExpertInterestStatus = (expertId, status) => {
    const projectId = this.props.projectId;
    apiClient
      .post(
        `${
          endpoints().projectUserAPI
        }/updateStatus?projectId=${projectId}&status=${status}&expertId=${expertId}`
      )
      .then(response => {
        this.setState({ editInterestStatusModal: false });
        this._getSourceMoreExpertsWaitingList(
          true,
          sourceMoreExpertsWaitingListStatuses.join(", ")
        );
        this._getSourceMoreExpertsPendingList(
          true,
          sourceMoreExpertsPendingListStatuses.join(", ")
        );
        this.props.handleProjectDetails(projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Get default account manager user list
  getDefaultAccountManagerList = () => {
    return apiClient
      .get(`${endpoints().settingAPI}/account/manager/list`)
      .then(response => {
        const results = response.data.data;
        let defaultAccountManagerList = [];
        if (results) {
          results.forEach(data => {
            defaultAccountManagerList.push({
              id: data.id,
              name: data.name,
              picture: data.avatarUrl
            });
          });
        }
        this.setState({
          defaultAccountManagerList
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Handle Upadte Account Manager
  handleUpdateAccountManager = id => {
    this.setState({ selectedAccountManagerId: id });
  };

  // Change Account Manager
  changeAccountManager = () => {
    const { projectId } = this.props;
    const { selectedAccountManagerId } = this.state;
    return apiClient
      .post(`${endpoints().projectUserAPI}/updateAccountManager`, {
        projectId: projectId,
        userId: selectedAccountManagerId
      })
      .then(() => {
        this.setState({ changeAccountManagerModal: false });
        this.props.handleProjectDetails(this.props.projectId);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  deleteSupportMaterial = async id => {
    try {
      const response = await apiClient.delete(
        `${endpoints().projectAPI}/delete/${id}`
      );

      if (response && response.data) {
        let successMessage = response.data.message;
        toast.success(successMessage);
        this.setState({
          supportingMaterials: response.data.supportingMaterials || ""
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        toast.error(getErrorMessage(error));
        console.error(getErrorMessage(error));
      }
    }
  };

  render() {
    const {
      expertList,
      isExpertModal,
      isExpertLoading,
      expertIds,
      expertRequestId,
      projectExpertList,
      projectExpertWaitingList,
      pendingProjectExpertList,
      messageAccountManagerModal,
      changeAccountManagerModal,
      expertStatus,
      selectedProjectTeamMemberId,
      selectedProjectTeamMemberFirstName,
      selectedProjectTeamMemberLastName,
      updateBudgetModal,
      timelineModal,
      projectTimelineStart,
      projectTimelineEnd,
      clientModal,
      expertWaitingRequestId,
      projectWaitingForAcceptanceExpertList,
      editInterestStatusModal,
      selectedEditInterestExpertId,
      defaultAccountManagerList,
      selectedAccountManagerId,
      subcriptionStatusModal,
      expertCount
    } = this.state;

    const {
      projectDetails,
      projectId,
      pendingProjectUsers,
      handleReviewMatchExpert,
      approveMatchedExpert,
      declineMatchedExpert,
      approvedProjectExpertList,
      projectUserStatus,
      kickoffHappened,
      handleProjectDetails
    } = this.props;

    const currentBasePath = getUrlPath(1);
    const isProject = currentBasePath === PROJECT_TYPE_PROJECT_URL_PATH;
    const settings = this.props.settings ? this.props.settings : "";

    const enablePartners = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS) &&
        getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS) === "true" &&
        true
      : false;

    const enableExperts = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS) &&
        getKeyValueByObject(settings, SETTINGS_ENABLE_EXPERTS) === "true" &&
        true
      : false;

    let {
      name,
      customerName,
      estimatedBudget,
      invoicedAmount,
      newEstimatedBudget,
      projectTeam,
      customerId,
      currentUserId,
      formattedEndDate,
      formattedStartDate,
      showSourcingExpertTimeFrame,
      formattedSubscriptionStartDate,
      formattedSubscriptionEndDate,
      paymentType,
      billingType,
      subscriptionStatus,
      expertId
    } = projectDetails;

    formattedEndDate = this.state.formattedEndDate;
    formattedStartDate = this.state.formattedStartDate;

    const selectedExpertInterestIds = this.state.checkBox.shortListedExperts;

    const currentPath = window.location.pathname + window.location.search;
    let redirectUrl = "";
    if (currentPath) {
      redirectUrl = `?redirect=${currentPath}`;
    }
    const isCampaign = getUrlPath(1) === PROJECT_TYPE_CAMPAIGN_URL_PATH;
    return (
      <div className="details-body-sidebar">
        {!isCampaign && (
          <>
            {this.props.expertId && (
              <KickoffProvider
                projectId={projectId}
                expertId={this.props.expertId}
              >
                {(isCustomer(this.props.roleId) ||
                  isExpert(this.props.roleId)) && (
                  <KickOffSidebarInfo
                    projectId={projectId}
                    expertId={this.props.expertId}
                  />
                )}
              </KickoffProvider>
            )}

            {isCustomer(this.props.roleId) &&
              ((pendingProjectUsers && pendingProjectUsers.length > 0) ||
                (projectWaitingForAcceptanceExpertList &&
                  projectWaitingForAcceptanceExpertList.length > 0)) && (
                <div ref={handleReviewMatchExpert} className={"sidebar-item"}>
                  <SidebarCustomerExpertMatched
                    projectWaitingForAcceptanceExpertList={
                      projectWaitingForAcceptanceExpertList
                    }
                    pendingProjectUsers={pendingProjectUsers}
                    approveMatchedExpert={approveMatchedExpert}
                    declineMatchedExpert={declineMatchedExpert}
                    projectId={projectId}
                  />
                </div>
              )}
            {/* Show Subscription */}
            {billingType === PLAYBOOK_PRICING_TYPE_SUBSCRIPTION && (
              <ProjectSubscription
                paymentType={paymentType}
                subscriptionStatus={subscriptionStatus}
                formattedSubscriptionStartDate={formattedSubscriptionStartDate}
                formattedSubscriptionEndDate={formattedSubscriptionEndDate}
                updateProjectSubscriptionStatus={e =>
                  this.updateProjectSubscriptionStatus(e)
                }
                subcriptionStatusModal={subcriptionStatusModal}
                setSubcriptionStatusModal={this.setSubcriptionStatusModal}
              />
            )}

            <SidebarClientInfo
              formattedStartDate={formattedStartDate}
              formattedEndDate={formattedEndDate}
              customerName={customerName}
              estimatedBudget={estimatedBudget}
              invoicedAmount={invoicedAmount}
              newEstimatedBudget={newEstimatedBudget}
              setUpdateBudgetModal={this.toggleUpdateBudgetModal}
              updateBudgetModal={updateBudgetModal}
              onChangeBudget={this.requestedUpdatedBudget}
              timelineModal={timelineModal}
              setTimelineModal={this.setTimelineModal}
              startDate={projectTimelineStart}
              endDate={projectTimelineEnd}
              setDate={this.setProjectTimeline}
              clientModal={clientModal}
              setClientModal={this.setClientModal}
              updateProject={e => this.updateProject(e)}
              showSourcingExpertTimeFrame={showSourcingExpertTimeFrame}
              approvedExpertId={expertId}
              projectId={projectId}
              kickoffHappened={kickoffHappened}
            />
            {enableExperts && (
              <SidebarApprovedExpertList
                billingType={projectDetails.billingType}
                approvedProjectExpertList={approvedProjectExpertList}
                projectWaitingForAcceptanceExpertList={
                  projectWaitingForAcceptanceExpertList
                }
              />
            )}
            <SidebarItem>
              <p className="font-weight-bold">Project Team</p>
              {/* Project team member */}
              <ProjectTeam
                data={projectTeam}
                noDataFoundMessage="No users added yet"
                toggle={this.toggleMessageAccountManagerModal}
                toggleChangeAccountManager={this.toggleChangeAccountManager}
                currentUserId={currentUserId}
              />
              <AccountManageModal
                projectId={projectId}
                isOpen={changeAccountManagerModal}
                toggle={this.toggleAccountManagerModal}
                selectedAccountManagerId={selectedAccountManagerId}
                defaultAccountManagerList={defaultAccountManagerList}
                handleUpdateAccountManager={this.handleUpdateAccountManager}
                changeAccountManager={this.changeAccountManager}
              />
              <MessageAccountManagerModal
                projectId={projectId}
                isOpen={messageAccountManagerModal}
                toggle={this.toggleMessageAccountManagerModal}
                onChangeMessage={this.onChangeMessage}
                selectedProjectTeamMemberFirstName={
                  selectedProjectTeamMemberFirstName
                }
                selectedProjectTeamMemberLastName={
                  selectedProjectTeamMemberLastName
                }
                selectedProjectTeamMemberId={selectedProjectTeamMemberId}
                sendMessageToAccountManager={this.sendMessageToAccountManager}
              />
            </SidebarItem>
          </>
        )}
        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) &&
          enableExperts &&
          !isCampaign && (
            <>
              <SidebarItem>
                <p className="font-weight-bold mb-1">
                  Shortlist of Sourced Experts{" "}
                </p>{" "}
                {/*had to move this here because of design and global css restrictions*/}
                <span className="sidebar-item-description d-block h7 text-grayed mb-3">
                  Once your shortlist is approved by Admin, you’ll select one
                  primary Expert to share with the Client.
                </span>
                <span
                  onClick={() => {
                    this._getExpertsList(projectId, null, true);
                    this.toggle();
                  }}
                  className="source-more h6-5 font-weight-bold mb-4 d-block cursor-pointer"
                >
                  {`Source ${
                    expertIds && expertIds.length > 0 ? "More " : ""
                  }Experts`}
                </span>
                {/*Expert modal*/}
                <ExpertModal
                  isExpertModal={isExpertModal}
                  isLoading={isExpertLoading}
                  toggle={this.toggle}
                  expertList={expertList}
                  handleExperts={this.handleExperts}
                  expertIds={expertIds}
                  createProjectExpert={e => this.createProjectExpert(e)}
                  updateExpertList={this.updateExpertList}
                  redirectUrl={redirectUrl}
                  expertCount={expertCount}
                />
                <SourceMoreExperts
                  showDelete={true}
                  projectExpertList={[
                    ...projectExpertList,
                    ...pendingProjectExpertList
                  ]}
                  handleExpertRequestApproval={e =>
                    this.handleExpertRequestApproval(e)
                  }
                  expertRequestId={expertRequestId}
                  sendExpertRequestApproval={e =>
                    this.sendExpertRequestApproval(e)
                  }
                  deleteProjectExpert={e => this.deleteProjectExpert(e)}
                />
                {((expertStatus !==
                  PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE &&
                  projectExpertList.length > 0) ||
                  pendingProjectExpertList.length > 0) && (
                  <div className="btn-wrapper mt-4">
                    <button
                      className={
                        expertStatus === PROJECT_USER_STATUS_INTERESTED ||
                        expertStatus ===
                          PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE
                          ? `btn btn-primary w-100`
                          : `btn btn-outline-secondary w-100`
                      }
                      disabled={
                        expertStatus !==
                          PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE &&
                        expertStatus !== PROJECT_USER_STATUS_INTERESTED
                      }
                      onClick={() => this.sendExpertRequestApproval()}
                    >
                      Request Client Approval
                    </button>
                  </div>
                )}
              </SidebarItem>

              {(projectExpertWaitingList.length > 1 ||
                pendingProjectExpertList.length > 0) &&
                selectedExpertInterestIds && (
                  <SidebarItem>
                    <SourceMoreWaitingExperts
                      projectExpertList={[
                        ...projectExpertWaitingList,
                        ...pendingProjectExpertList
                      ]}
                      handleExpertRequestApproval={e =>
                        this.handleExpertSendWaitingRequest(e)
                      }
                      expertRequestId={expertWaitingRequestId}
                      sendExpertRequestApproval={e =>
                        this.sendVerifyExpertAvailability(e)
                      }
                      selectedOptions={selectedExpertInterestIds}
                      handleShortListedExpert={this._handleShortListedExperts}
                      deleteProjectExpert={e =>
                        this.deleteProjectWaitingExpert(e)
                      }
                      editInterestStatusModal={editInterestStatusModal}
                      setEditInterestStatusModal={
                        this.setEditInterestStatusModal
                      }
                      selectedEditInterestExpertId={
                        selectedEditInterestExpertId
                      }
                      updateExpertInterestStatus={
                        this.updateExpertInterestStatus
                      }
                      redirectUrl={redirectUrl}
                    />
                    <div className="btn-wrapper mt-4">
                      <button
                        className={
                          selectedExpertInterestIds &&
                          selectedExpertInterestIds.length > 0
                            ? `btn btn-primary w-100`
                            : `btn btn-outline-secondary w-100`
                        }
                        disabled={
                          selectedExpertInterestIds &&
                          !selectedExpertInterestIds.length
                        }
                        onClick={() => this.sendVerifyExpertAvailability()}
                      >
                        Verify Expert Availability
                      </button>
                    </div>
                  </SidebarItem>
                )}
            </>
          )}

        {isCampaign && (
          <CampaignTimeline
            formattedStartDate={formattedStartDate}
            formattedEndDate={formattedEndDate}
          />
        )}
        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) &&
          enablePartners &&
          isCampaign && (
            <>
              <KickoffProvider
                projectId={projectId}
                expertId={this.props.expertId}
                isCampaign
              >
                <KickOffSidebarInfo
                  projectId={projectId}
                  expertId={this.props.expertId}
                  isCampaign
                />
              </KickoffProvider>
              <SourcePartners
                projectId={projectId}
                handleProjectDetails={handleProjectDetails}
              />
            </>
          )}
        {isProject &&
          (isCustomer(this.props.roleId) ||
            isSuperAdmin(this.props.roleId)) && (
            <SidebarCustomerAndAdminInvoicing
              projectId={projectId}
              projectName={name}
              estimatedBudget={estimatedBudget}
              customerId={customerId}
              paymentType={paymentType}
              billingType={billingType}
              sendInvoiceClientReminder={this.sendInvoiceClientReminder}
            />
          )}
        <SidebarSupportingMaterials
          handleMaterialUpload={this.handleMaterialUpload}
          supportingMaterialsAsState={this.state.supportingMaterials}
          supportingMaterialsAsProps={
            this.props.projectDetails.supportingMaterials
          }
          deleteSupportMaterial={this.deleteSupportMaterial}
          projectUserStatus={projectUserStatus}
        />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(ProjectDetailsSidebar);
