import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

//Components
import ImageCarousel from "../../components/base/imageCarasoul/ImageCarousel.js";
import NoRecordsFound from "../../components/base/NoRecordsFound";
import PageTitle from "../../components/base/PageTitle";
import Text from "../../components/base/Text";
import Form from "../../components/base/Form";

//Constant
import { MEDIA_APP_PHOTO } from "./Constant";

const GalleryItem = props => {
  const {
    imageUpload,
    photos,
    handlePhotoDelete,
    closeImageModal,
    openModal
  } = props;
  // initilize states
  const [isOpen, setIsOpen] = useState(closeImageModal);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [file, setFile] = useState("");
  const [fileName, setFileName] = useState("");
  const [isFileExist, setIsFileExist] = useState(false);

  //Image Remove
  const imageRemoveHandler = photo => {
    handlePhotoDelete(photo);
  };

  // Toggle Create modal
  const _createModaltoggle = () => {
    setTitle("");
    setDescription("");
    setFile("");
    setFileName("");
    setIsFileExist(false);
    setIsOpen(!isOpen);
  };

  const openAddModal = () => {
    setTitle("");
    setDescription("");
    setFile("");
    setFileName("");
    openModal();
    _createModaltoggle();
  };

  //Set Media
  const media = e => {
    const data = e.target.files ? e.target.files[0] : "";
    setFile(data);
    setFileName(data && data.name);
    setIsFileExist(false);
  };

  //Upload File
  const upload = () => {
    const data = {};
    if (!file && !fileName) {
      setIsFileExist(true);
    } else {
      data.title = title;
      data.description = description;
      data.file = file;
      data.fileName = fileName;
      data.object = MEDIA_APP_PHOTO;
      imageUpload && imageUpload(data);
      setFile("");
    }
  };

  if (closeImageModal == true && isOpen == true) {
    _createModaltoggle();
  }

  return (
    <>
      <div>
        <Modal
          isOpen={isOpen}
          toggle={_createModaltoggle}
          className="landing-create-popup w-100"
          backdrop="static"
        >
          <ModalHeader
            toggle={_createModaltoggle}
            cssModule={{ "modal-title": "w-100 text-center" }}
          >
            <p>Add Gallery Items</p>
          </ModalHeader>
          <Form>
            <ModalBody className="ml-3 mr-3 mt-2 mb-3">
              <div>
                <Text
                  name="imageTitle"
                  label="Title"
                  placeholder="Enter title"
                  error=""
                  onChange={e => {
                    setTitle(e.target.value);
                  }}
                />
              </div>
              <div>
                <Text
                  name="imageDescription"
                  label="Description"
                  placeholder="Enter Description"
                  error=""
                  onChange={e => {
                    setDescription(e.target.value);
                  }}
                />
              </div>
              <div className="">
                <input
                  name="file"
                  className="form-control d-none"
                  type="file"
                  id="photo"
                  onChange={e => {
                    media && media(e);
                  }}
                  accept="image/png, image/gif, image/jpeg"
                />
                <span className="profilePicOverlay d-block ">
                  <label htmlFor="photo" className="profile-img-sm mb-0">
                    <span className="banner-image-upload-link">
                      Add Gallery Image
                    </span>
                    <span className="text-danger ml-1">*</span>
                  </label>

                  {/* Show image Name */}
                  {file && fileName && <span className="pl-3">{fileName}</span>}
                </span>
                {isFileExist && (
                  <small className={`text-danger position-absolute `}>
                    Gallery Image is required
                  </small>
                )}
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="container-fluid">
                <div className="col-sm-12 text-center">
                  <Button
                    label=""
                    onClick={e => {
                      upload(e);
                    }}
                  >
                    Save
                  </Button>
                </div>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
      <div>
        <PageTitle
          label="Gallery"
          buttonHandler={() => {
            openAddModal();
          }}
          buttonLabel="Add New"
        />
        {photos && photos.length > 0 ? (
          <div className="d-block ">
            <ImageCarousel
              photos={photos}
              showRemove={true}
              appStyle={true}
              handlePhotoDelete={imageRemoveHandler}
              style={{
                width: "100%",
                height: "140px",
                objectFit: "scale-down"
              }}
              slides={2}
            />
          </div>
        ) : (
          <NoRecordsFound
            showMessage={true}
            hideCard={true}
            message="No Photos Found"
            position="top"
          />
        )}
        {/* <SimpleSlider /> */}
      </div>
    </>
  );
};
export default GalleryItem;
