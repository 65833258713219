import React, { useEffect, useState } from "react";

// Components
import SidebarItem from "./SidebarItem";

const ReferralPartnerTier = ({
  referralPartnerTier,
  partnerTierCommissionPercentages
}) => {
  const [
    currentPartnerCommissionPercentage,
    setCurrentPartnerCommissionPercentage
  ] = useState("");

  const getCurrentPartnerCommissionPercentage = () => {
    const partnerTierCommissionPercentage = partnerTierCommissionPercentages.find(
      partnerTierCommission =>
        partnerTierCommission.name === referralPartnerTier
    );
    if (partnerTierCommissionPercentage) {
      setCurrentPartnerCommissionPercentage(
        partnerTierCommissionPercentage.percentage
      );
    }
  };

  useEffect(() => {
    if (
      referralPartnerTier &&
      partnerTierCommissionPercentages &&
      partnerTierCommissionPercentages.length
    ) {
      getCurrentPartnerCommissionPercentage();
    }
  }, [referralPartnerTier, partnerTierCommissionPercentages]);

  return (
    <div style={{ backgroundColor: "#EFECFF" }}>
      <SidebarItem>
        <p className="font-weight-bold mb-1">Partner Tier</p>
        <div className="d-flex align-items-center">
          {currentPartnerCommissionPercentage >= 0 && (
            <span className={`text-circle mr-2`}>
              <p className={`font-weight-bold`}>
                {currentPartnerCommissionPercentage}%
              </p>
            </span>
          )}
          <span className={`title h5 d-block`} style={{ color: "#636BF7" }}>
            <b>{referralPartnerTier}</b>
          </span>
        </div>
      </SidebarItem>
    </div>
  );
};

export default ReferralPartnerTier;
