import React from "react";

import { apiClient } from "../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../configs";

// Helper
import { getCookie } from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

// Components
import MultiselectCreatable from "./base/MultiselectCreatable";
import Avatar from "./base/Avatar";
import { isBadRequest } from "../common/http";

class PartnerSelect extends React.Component {
  state = {
    partnerList: ""
  };

  // Get Partner List
  getPartnerList = async () => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    try {
      const response = await apiClient.get(
        `${endpoints().publicAPI}/partnerlist`
      );
      const results = response.data.data;

      if (results && results.length > 0) {
        const partnerList = [];
        results.forEach(partner => {
          partnerList.push({
            id: partner.id,
            name: partner.companyName,
            logo: partner.avatarUrl,
            details: partner
          });
        });
        this.setState({ partnerList: partnerList });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  componentDidMount() {
    this.getPartnerList();
  }

  //  Get options
  getPartnerListOptions = () => {
    const { partnerList } = this.state;
    let partnerListOptions = [];

    if (!partnerList) return partnerListOptions;

    partnerList.forEach(partner => {
      partnerListOptions.push({
        details: partner.details,
        value: partner.name,
        label: (
          <>
            <span className="d-flex justify-content-start align-items-center">
              <Avatar
                firstName={partner.name}
                // lastName={assignee.lastName}
                url={partner.logo}
                size="customSize"
                imageSize={"20"}
                fontSize={8}
              ></Avatar>
              <span style={{ marginLeft: "5px" }}>{partner.name}</span>
            </span>
          </>
        )
      });
    });

    return partnerListOptions;
  };

  render() {
    const {
      name,
      label,
      placeholder,
      error,
      required,
      isSearchable,
      fullWidth,
      onchange,
      disabled,
      id
    } = this.props;

    return (
      <MultiselectCreatable
        id={id || name}
        isSingleSelect={true}
        name={name}
        label={label}
        placeholder={placeholder}
        options={this.getPartnerListOptions()}
        fullWidth={fullWidth}
        isSearchable={isSearchable}
        error={error}
        {...{ required }}
        onInputChange={onchange}
        disabled={disabled}
      />
    );
  }
}

export default PartnerSelect;
