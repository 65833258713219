import React from "react";
import LazyLoad, { forceVisible } from "react-lazyload";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

// Variable
import variables from "../scss/_variables.scss";

// Assets
import headerTriangles from "../assets/header-triangles.svg";
import { ArrowRight, MessageCircleIcon } from "../assets/img/icons";

// Components
import PlaybookIcon from "../components/base/PlaybookIcon";
import MoreDropdown from "../components/base/MoreDropdown";
import { ElitePartner } from "../assets/img/icons";

// Constants
import {
  PLAYBOOK_PRICING_TYPE_HOURLY_PAYMENT,
  PLAYBOOK_PRICING_TYPE_SUBSCRIPTION
} from "../playbook/Constants";
import { METACX_EVENT_MARKETPLACE_LANDING_PAGE_PLAYBOOK_CLICK } from "../metaCXEvents/Constants";

// Inner Components
import { getPricingScheduleValue } from "../views/playbook/inner_components/getPricingScheduleValue";

// Library
import { metaCXTrack } from "../lib/metaCX";
import {
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  getKeyValueByObject,
  isCompanyManager
} from "../lib/helper";
import { SETTINGS_ALLOW_PLAYBOOK_PURCHASE } from "../setting/Constants";
import DeleteModal from "./base/DeleteModal";

import { connect } from "react-redux";

class PlaybookCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      deletePlaybookModal: false,
      deletePlaybook: false,
      cardLink:
        this.props.isPreview === "true"
          ? "#"
          : this.props.link
          ? this.props.link
          : "#"
    };
    this.renderUnreadMessages = this.renderUnreadMessages.bind(this);
    this.renderAlertNotification = this.renderAlertNotification.bind(this);
  }

  handledelete = () => {
    this.props.delete();
  };

  componentDidMount() {
    forceVisible();
  }

  renderUnreadMessages() {
    const { notifications } = this.props;
    if (notifications !== undefined && notifications.newMessages !== null) {
      return (
        <div className="new-message">
          <MessageCircleIcon />
          {notifications.newMessages}
        </div>
      );
    }
  }

  renderAlertNotification() {
    const { notifications } = this.props;
    if (
      notifications !== undefined &&
      notifications.notificationAlertLabel !== null
    ) {
      return (
        <div className="new-alert-message">
          {notifications.notificationAlertLabel}
        </div>
      );
    }
  }

  // Open the link
  openLink() {
    const { link, openLinkInNewTab } = this.props;
    if (link) {
      openLinkInNewTab ? window.open(link) : this.props.history.push(link);
    }
  }

  // Set Card Link
  setCardLink() {
    const { openLinkInNewTab } = this.props;
    if (openLinkInNewTab) {
      this.setState({
        cardLink: "#"
      });
    } else {
      this.setState({
        cardLink:
          this.props.isPreview === "true"
            ? "Javascript:void(0);"
            : this.props.link
            ? this.props.link
            : "#"
      });
    }
  }

  // Reset Card Link
  resetCardLink() {
    this.setState({
      cardLink: "#"
    });
  }

  render() {
    const cardBoxStyle = {
      height: showCardBody ? "350px" : "",
      width: this.props.fullWidth ? "" : "255px"
    };

    const multiLineElipsisStyle = {
      display: "-webkit-box",
      WebkitLineClamp: "3",
      WebkitBoxOrient: "vertical",
      overflow: "hidden"
    };

    // props
    const {
      landingPageName,
      data,
      size,
      link,
      viewLink,
      openLinkInNewTab,
      showEditOptions,
      onSaveDraft,
      onSavePublish,
      onSaveDuplicate,
      selectPlaybooks,
      removePlaybook,
      showCardBody,
      isActive,
      handlePlaybooks,
      isPreview,
      bannerImageUrl,
      boxShadow,
      placeHolder,
      showPrice,
      showOption,
      isMarketPlaceLandingPage,
      salesforceLanding,
      salesforceLandingGridArrow,
      settings,
      projectEditOptions,
      onClickProjectArchive,
      onProjectStatusChange,
      onClickDeleteExpertModal,
      approvedProjectExpertList,
      removePartnerPlaybook,
      optionName,
      editOption,
      onClickEdit
    } = this.props;

    // Playbook details
    const {
      id,
      price,
      bannerImagePathUrl,
      publishStatus,
      pricingType,
      pricingSchedule,
      priceRange,
      description,
      partnerId
    } = data;

    const pricingScheduleLabel = getPricingScheduleValue(pricingSchedule);
    const name = data.name || data.title;
    const bannerIcon = data.bannerIcon || data.banner_icon;
    const bannerColor = data.bannerColor || data.banner_color;

    const pricesArray = [];
    let standard, basic, premium;
    if (price && price.priceRange) {
      const priceRange = price.priceRange;
      standard = priceRange.standard;
      basic = priceRange.basic;
      premium = priceRange.premium;
    }

    if (standard) {
      pricesArray.push(standard);
    }

    if (basic) {
      pricesArray.push(basic);
    }

    if (premium) {
      pricesArray.push(premium);
    }

    const cursorPointer = isPreview === "true" ? "" : "cursor-pointer";

    const ImageURL = bannerImagePathUrl || bannerImageUrl;

    const isActivePlaybooks = isActive
      ? "active-playbooks far fa-check-circle"
      : "inactive-playbooks far fa-circle";

    const activePlaybooks = `float-right ${cursorPointer} ${isActivePlaybooks}`;
    const removeSelectPlaybook = `float-right cursor-pointer remove-expert far fa-times-circle`;

    const TagName = this.props.tag || Link;

    const allowPlaybookPurchase = settings
      ? getKeyValueByObject(settings, SETTINGS_ALLOW_PLAYBOOK_PURCHASE)
      : "";
    const { deletePlaybookModal } = this.state;

    return (
      <TagName
        id="card-link"
        {...(TagName === "a"
          ? { href: this.state.cardLink }
          : { to: this.state.cardLink })}
        style={{
          color: "#212529",
          cursor: isPreview ? "default" : "pointer",
          paddingLeft: "0px",
          paddingRight: "0px"
        }}
        className={`
          ${boxShadow !== false ? "playbook-card-link" : ""}
          ${isPreview === "true" ? "" : size ? size : "col-4"}
          mb-4
          text-decoration-none
        `}
        key={id}
        onClick={e => {
          // Track MetaCX event for click on Playbook card
          isMarketPlaceLandingPage &&
            metaCXTrack(
              METACX_EVENT_MARKETPLACE_LANDING_PAGE_PLAYBOOK_CLICK,
              {
                landingPageName,
                playbookId: `${id}`,
                playbookName: name,
                currentPrice: `${price.currentPrice}`,
                pricingType
              },
              settings,
              () => {
                e.preventDefault();
              }
            );
          if (openLinkInNewTab && TagName !== "a") {
            e.preventDefault();
            this.openLink();
          }
        }}
      >
        <DeleteModal
          isOpen={deletePlaybookModal}
          toggle={() => {
            this.setState({ deletePlaybookModal: false });
          }}
          title="Delete playbook"
          label={name}
          deleteFunction={this.handledelete}
        />
        <div className={`${cursorPointer} card `} style={cardBoxStyle}>
          <LazyLoad>
            <div
              id="card-image"
              className="card-header"
              style={{
                backgroundImage: `url(${
                  bannerImageUrl
                    ? bannerImageUrl
                    : ImageURL
                    ? ImageURL
                    : headerTriangles
                })`,
                backgroundColor: `${bannerColor ? bannerColor : ""}`,
                backgroundPosition: "center",
                backgroundSize: "contain",
                backgroundRepeat: "no-repeat"
              }}
              onMouseOver={e => {
                if (showOption) {
                  e.preventDefault();
                  if (e.target.id === "card-image") {
                    this.setCardLink();
                  } else {
                    this.resetCardLink();
                  }
                }
              }}
              onClick={e => {
                if (showOption) {
                  e.preventDefault();
                  if (e.target.id === "card-image") {
                    this.openLink();
                  }
                }
              }}
            >
              {showOption && showEditOptions && (
                <MoreDropdown>
                  {isSuperAdmin(this.props.roleId) ||
                  isCompanyAdmin(this.props.roleId) ||
                  isCompanyManager(this.props.roleId) ||
                  (isPartner(this.props.roleId) && partnerId) ? (
                    <>
                      {viewLink && (
                        <a
                          href={viewLink}
                          target="_blank"
                          style={{
                            color: "inherit",
                            textDecoration: "none"
                          }}
                          rel="noreferrer"
                        >
                          <DropdownItem
                            onClick={e => {
                              e.preventDefault();
                              return viewLink && window.open(viewLink);
                            }}
                          >
                            View
                          </DropdownItem>
                        </a>
                      )}
                      <Link
                        to={`${this.props.editLink || link}`}
                        style={{
                          color: "inherit",
                          textDecoration: "none"
                        }}
                      >
                        <DropdownItem>Edit</DropdownItem>
                      </Link>
                      {onSaveDuplicate && (
                        <DropdownItem onClick={onSaveDuplicate}>
                          Duplicate
                        </DropdownItem>
                      )}
                      {(onSavePublish || onSaveDraft) && (
                        <DropdownItem
                          onClick={
                            publishStatus === "Active"
                              ? onSaveDraft
                              : onSavePublish
                          }
                        >
                          {publishStatus === "Active" ? "Unpublish" : "Publish"}
                        </DropdownItem>
                      )}
                      <DropdownItem divider />
                      <DropdownItem
                        className={"text-danger"}
                        onClick={() =>
                          this.setState({ deletePlaybookModal: true })
                        }
                      >
                        Delete
                      </DropdownItem>
                    </>
                  ) : (
                    <>
                      <DropdownItem
                        className={"text-danger"}
                        onClick={removePartnerPlaybook}
                      >
                        Remove
                      </DropdownItem>
                    </>
                  )}
                </MoreDropdown>
              )}

              {(isSuperAdmin(this.props.roleId) ||
                isCompanyAdmin(this.props.roleId) ||
                isCompanyManager(this.props.roleId)) &&
                showOption &&
                projectEditOptions && (
                  <MoreDropdown>
                    {approvedProjectExpertList.length > 0 && (
                      <DropdownItem
                        className={"text-danger"}
                        onClick={onClickDeleteExpertModal}
                      >
                        Delete Expert
                      </DropdownItem>
                    )}
                    <Link
                      to={`/campaign/edit/${id}`}
                      style={{
                        color: "inherit",
                        textDecoration: "none"
                      }}
                    >
                      <DropdownItem onClick={onClickEdit}>
                        {editOption}
                      </DropdownItem>
                    </Link>

                    <DropdownItem onClick={onProjectStatusChange}>
                      Change Status
                    </DropdownItem>
                    <DropdownItem
                      className={"text-danger"}
                      onClick={onClickProjectArchive}
                    >
                      {optionName}
                    </DropdownItem>
                  </MoreDropdown>
                )}

              {/* /.dropdown-wrapper */}
              <div
                className="card-header-icon"
                onClick={() => showOption && this.openLink()}
              >
                {!ImageURL ? <PlaybookIcon iconName={bannerIcon} /> : ""}
              </div>

              {selectPlaybooks && (
                <span
                  onClick={() => handlePlaybooks(id)}
                  className={activePlaybooks}
                  style={{
                    right: "20px",
                    top: "0.75rem",
                    position: "absolute",
                    color: "white",
                    fontSize: "50px"
                  }}
                />
              )}
              <div id="remove-playbook">
                {removePlaybook && (
                  <span
                    onClick={e => {
                      e.preventDefault();
                      e.stopPropagation();
                      handlePlaybooks(id);
                    }}
                    className={removeSelectPlaybook}
                    onMouseOver={e => {
                      if (e.target.id === "remove-playbook") {
                        this.setCardLink();
                      } else {
                        this.resetCardLink();
                      }
                    }}
                  />
                )}
              </div>

              {this.renderUnreadMessages()}
              {this.renderAlertNotification()}
            </div>
          </LazyLoad>
          <div className={"body-wrapper"}>
            {showCardBody !== false ? (
              <div
                className="card-body"
                onClick={() => showOption && this.openLink()}
              >
                {data.partnerTier && data.partnerTier.name && (
                  <div className="d-flex align-items-center">
                    <ElitePartner />
                    <small className="ml-1">{data.partnerTier.name}</small>
                  </div>
                )}
                <p
                  className={`${cursorPointer} card-title font-weight-bold mb-0 mt-2`}
                  style={multiLineElipsisStyle}
                >
                  {!name && placeHolder ? "" : name}
                </p>
                {salesforceLanding && description && (
                  <p className={["h6-5", "playbook-description"].join(" ")}>
                    {description}
                  </p>
                )}
                {data.category && (
                  <small className="text-muted">{data.category}</small>
                )}
              </div>
            ) : (
              ""
            )}
            {price && price.priceRange && (
              <div
                className="card-footer"
                style={{ minHeight: "40px" }}
                onClick={
                  !salesforceLandingGridArrow ? () => this.openLink() : ""
                }
              >
                {price.salesPrice && (
                  <p>
                    <b>On Sale: </b>{" "}
                    <span className="price text-success">
                      <s>{`$${price.currentPrice}`}</s>{" "}
                      <b> {`$${price.currentPrice}`}</b>
                    </span>
                  </p>
                )}
                {allowPlaybookPurchase === "true" && pricesArray.length > 0 ? (
                  <p>
                    <b>
                      {pricesArray.length === 1 ? "Price: " : "Starting at: "}
                    </b>
                    <span className="price text-success">
                      <b>{`$${Math.min.apply(Math, pricesArray)}`}</b>
                    </span>
                  </p>
                ) : (
                  ""
                )}

                {allowPlaybookPurchase === "true" &&
                  showPrice !== false &&
                  price.currentPrice && (
                    <p>
                      <b>
                        {priceRange && priceRange.length === 1
                          ? "Price: "
                          : "Starting at: "}
                      </b>
                      <span className="price text-success">
                        <b>
                          {`$${price.currentPrice} ${
                            pricingType === PLAYBOOK_PRICING_TYPE_SUBSCRIPTION
                              ? pricingScheduleLabel
                              : ""
                          }
                      ${
                        pricingType === PLAYBOOK_PRICING_TYPE_HOURLY_PAYMENT
                          ? "/hr."
                          : ""
                      }`}
                        </b>
                      </span>

                      {salesforceLandingGridArrow && (
                        <div className="pull-right">
                          <div className="text-primary">
                            <ArrowRight />
                          </div>
                        </div>
                      )}
                    </p>
                  )}
                {!price.priceRange && !price.salesPrice && price.currentPrice && (
                  <p>
                    <b>Price: </b>{" "}
                    <span className="price text-success">
                      <b>{`$${price.currentPrice}`}</b>
                    </span>
                  </p>
                )}
                {salesforceLanding && (
                  <Link to={this.state.cardLink}>
                    View Playbook <ArrowRight />
                  </Link>
                )}
              </div>
            )}
          </div>
        </div>
      </TagName>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(PlaybookCard);
