import React from "react";
import LeftSideSection from "./LeftSideSection";
import GetStarted from "./GetStarted";
import ExpertType from "./ExpertType";
import ProjectType from "./ProjectType";
import Timeline from "./Timeline";
import TimeCommitment from "./TimeCommitment";
import StartDate from "./StartDate";
import Experts from "./Experts";
import Location from "./Location";
import Budget from "./Budget";
import ContactInformation from "./ContactInformation";
import RequestReceived from "./RequestReceived";
import FindExpertMinimal from "./FindExpertMinimal";

// Components
import Form from "../../components/base/Form";

//Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";

//API call
import { apiClient } from "../../apiClient";

//Helper
import {
  toString,
  getKeyValueByObject,
  getSettingMediaUrl
} from "../../lib/helper";
import _renderWizardIndicator from "../../components/RenderWizardSquares";

// GA
import { gaEvent } from "../../lib/GoogleAnalytics";

//Constants
import { TORCHLITE_WEBSITE_URL } from "../../configs";
import {
  SETTINGS_PORTAL_TALENT_NAME,
  SETTINGS_PORTAL_DEFAULT_LANDING_PAGE_URL,
  SETTINGS_PORTAL_LOGO,
  SETTINGS_PORTAL_NAME,
  BACKGROUND_IMAGE_URL,
  FIND_AN_EXPERT_FORM
} from "../../setting/Constants";
import { SIMPLY_FORM } from "../../findAnExpertLayout/Constants";
import { LEAD_SOURCE_FIND_AN_EXPERT } from "../../lead/Constants";
import { isBadRequest } from "../../common/http";

class FindAnExpert extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      height: window.innerHeight,
      email: "",
      marketplace: ""
    };

    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this._submit = this._submit.bind(this);
    this._close = this._close.bind(this);

    // Bind the update dimensions
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  // Update the screen height
  updateDimensions() {
    this.setState({
      height: window.innerHeight
    });
  }

  // _next functions will be called on button click
  _next = props => {
    let currentStep = this.state.currentStep;
    // If the current step is 10 or 11, then add one on "next" button click
    currentStep = currentStep >= 11 ? 11 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });
  };

  // _previous functions will be called on button click
  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
  }

  // _previous functions will be called on button click
  _submit(values) {
    if (this._validateFields(values) === false) {
      return;
    }

    let currentStep = this.state.currentStep;
    // If the current step is 10 or 11, then add one on "next" button click
    currentStep = currentStep >= 11 ? 11 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });

    // Save Expert request form in the final
    if (currentStep === 11) {
      this._expertRequest(this._toArray(values));
    }
  }

  // Validate Fields
  _validateFields(values) {
    let success = true;

    // define expert request constants
    const expertType = values.expertType;
    const projectType = values.projectType;
    const timeline = values.timeline;
    const timeCommitment = values.timeCommitment;
    const startDate = values.startDate;
    const experts = values.experts;
    const location = values.location;
    const budget = values.budget;
    const firstName = values.firstName;
    const lastName = values.lastName;
    const additionalDetails = values.additionalDetails;
    const reference = values.reference;
    const email = values.email;

    switch (this.state.currentStep) {
      case 2:
        if (expertType == null) {
          success = false;
        }
        break;
      case 3:
        if (projectType == null) {
          success = false;
        }
        break;
      case 4:
        if (timeline == null) {
          success = false;
        }
        break;
      case 5:
        if (timeCommitment == null) {
          success = false;
        }
        break;
      case 6:
        if (startDate == null) {
          success = false;
        }
        break;
      case 7:
        if (experts == null) {
          success = false;
        }
        break;
      case 8:
        if (location == null) {
          success = false;
        }
        break;

      case 9:
        if (budget == null) {
          success = false;
        }
        break;

      case 10:
        if (
          firstName == null ||
          lastName == null ||
          email == null ||
          reference == null ||
          additionalDetails == null
        ) {
          success = false;
        }
        break;
      default:
        break;
    }

    return success;
  }

  // Close the wizard
  _close() {
    window.location = { TORCHLITE_WEBSITE_URL };
  }

  // // Render square box
  // _renderSquare(currentValue) {
  //   const currentStep = this.state.currentStep;
  //   const showActive = currentStep === currentValue ? "active" : "in-active";
  //   return currentStep < 11 ? <div className={`box ${showActive}`} /> : "";
  // }
  //
  // // Render wizard indicator
  // _renderWizardIndicator() {
  //   return (
  //     <div className="expert-request-active-btn-section d-flex">
  //       {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(n => {
  //         return this._renderSquare(n);
  //       })}
  //     </div>
  //   );
  // }

  // To Array
  _toArray(values) {
    values.expertType = toString(values.expertType);
    values.projectType = toString(values.projectType);
    values.timeline = toString(values.timeline);
    values.timeCommitment = toString(values.timeCommitment);
    values.startDate = toString(values.startDate);
    values.experts = toString(values.experts);
    values.location = toString(values.location);
    values.budget = toString(values.budget);
    values.firstName = toString(values.firstName);
    values.lastName = toString(values.lastName);
    values.email = toString(values.email);
    values.reference = toString(
      values.reference ? values.reference.value : values.reference
    );
    values.additionalDetails = toString(values.additionalDetails);
    values.source = toString(LEAD_SOURCE_FIND_AN_EXPERT);

    this.setState({
      email: values.email
    });

    return values;
  }

  // Expert Request API Call
  _expertRequest(data) {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .post(endpoints().lead, data)
      .then(response => {
        // Event
        gaEvent({
          category: "Find An Expert",
          action: "Clicked on Find An Expert",
          label: "Find An Expert"
        });

        let currentStep = this.state.currentStep;
        // If the current step is 10 or 11, then add one on "next" button click
        currentStep = currentStep >= 11 ? 11 : currentStep + 1;
        this.setState({
          currentStep: currentStep
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  render() {
    const { currentStep, height, email } = this.state;
    const { settings } = this.props;

    const marketplaceLogo =
      settings && getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO)
          )
        : "";

    const marketplaceName = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_NAME)
      : "";

    const marketplaceTalentName = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_TALENT_NAME)
      : "";

    const backgroundImage =
      settings && getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
          )
        : "";

    const marketplaceDefaultLandingPageUrl = settings
      ? getKeyValueByObject(settings, SETTINGS_PORTAL_DEFAULT_LANDING_PAGE_URL)
      : "";

    const findanexpertlayout = settings
      ? getKeyValueByObject(settings, FIND_AN_EXPERT_FORM)
      : "";

    const initialValues = {
      expertType: "",
      projectType: "",
      timeline: "",
      timeCommitment: "",
      startDate: "",
      experts: "",
      location: "",
      budget: "",
      firstName: "",
      lastName: "",
      additionalDetails: "",
      reference: "",
      email: ""
    };

    if (findanexpertlayout === SIMPLY_FORM) {
      return (
        <FindExpertMinimal
          marketplaceLogo={marketplaceLogo ? marketplaceLogo : ""}
          marketplaceName={marketplaceName ? marketplaceName : ""}
          marketplaceTalentName={
            marketplaceTalentName ? marketplaceTalentName : ""
          }
          marketplaceDefaultLandingPageUrl={marketplaceDefaultLandingPageUrl}
          settings={settings}
        />
      );
    }

    return (
      <div
        className="container-fluid"
        style={{ height: height, minHeight: "100vh" }}
      >
        <div className="row overflow-hidden" style={{ height: "100%" }}>
          <LeftSideSection
            height={height}
            marketplaceLogo={marketplaceLogo ? marketplaceLogo : ""}
            marketplaceName={marketplaceName ? marketplaceName : ""}
            backgroundImage={backgroundImage}
          />

          <div
            className="col"
            style={{ background: "#fff", overflowY: "scroll", height: "100%" }}
          >
            <div className="row find-an-expert-budget-right-side-section form-wrapper flex-column">
              {_renderWizardIndicator(currentStep, 11)}
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  this._submit(values);
                }}
              >
                <GetStarted
                  step={1}
                  currentStep={currentStep}
                  next={this._next}
                />

                <ExpertType
                  step={2}
                  currentStep={currentStep}
                  next={this._submit}
                  prev={this._prev}
                />

                <ProjectType
                  step={3}
                  currentStep={currentStep}
                  next={this._submit}
                  prev={this._prev}
                />

                <Timeline
                  step={4}
                  currentStep={currentStep}
                  next={this._submit}
                  prev={this._prev}
                />

                <TimeCommitment
                  step={5}
                  currentStep={currentStep}
                  next={this._submit}
                  prev={this._prev}
                />

                <StartDate
                  step={6}
                  currentStep={currentStep}
                  next={this._submit}
                  prev={this._prev}
                />

                <Experts
                  step={7}
                  currentStep={currentStep}
                  next={this._submit}
                  prev={this._prev}
                />

                <Location
                  step={8}
                  currentStep={currentStep}
                  next={this._submit}
                  prev={this._prev}
                />

                <Budget
                  step={9}
                  currentStep={currentStep}
                  next={this._submit}
                  prev={this._prev}
                />

                <ContactInformation
                  step={10}
                  currentStep={currentStep}
                  next={this._submit}
                  prev={this._prev}
                />

                <RequestReceived
                  step={11}
                  currentStep={currentStep}
                  close={this._close}
                  email={email}
                  marketplaceDefaultLandingPageUrl={
                    marketplaceDefaultLandingPageUrl
                  }
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FindAnExpert;
