import React, { useEffect } from "react";
import {
  MDF_STATUS_APPROVED,
  MDF_STATUS_DECLINED,
  MDF_STATUS_REQUESTED,
  MDF_STATUS_DRAFT
} from "../Constants";

import SidebarItem from "./SidebarItem";

const MdfStatus = ({ status }) => {
  useEffect(() => {}, [status]);
  return (
    <SidebarItem>
      <div>
        <p className="font-weight-bold mb-1">
          {status === MDF_STATUS_REQUESTED
            ? "Request Status"
            : " Request Status"}
        </p>
        <div
          className="d-flex align-items-start justify-content-between"
          style={{ marginTop: "40px" }}
        >
          <div className="deal-status-line text-warning">
            {status === MDF_STATUS_REQUESTED ? MDF_STATUS_REQUESTED : ""}
          </div>
          <div
            className={`deal-status-line ${
              status === MDF_STATUS_APPROVED
                ? "text-success"
                : status === MDF_STATUS_DECLINED
                ? "text-danger"
                : ""
            }`}
          >
            {status === MDF_STATUS_APPROVED
              ? MDF_STATUS_APPROVED
              : status === MDF_STATUS_DECLINED
              ? MDF_STATUS_DECLINED
              : ""}
          </div>
        </div>

        <div className="mt-2 d-flex align-items-center justify-content-between">
          <div
            className={`deal-status-line  border ${
              status === MDF_STATUS_DRAFT
                ? "border-warning"
                : status === MDF_STATUS_REQUESTED ||
                  status === MDF_STATUS_APPROVED ||
                  status === MDF_STATUS_DECLINED
                ? "border-success"
                : "border-secondary"
            }`}
          ></div>
          <div
            className={`deal-status-line  border ${
              status === MDF_STATUS_REQUESTED
                ? "border-warning"
                : status === MDF_STATUS_APPROVED ||
                  status === MDF_STATUS_DECLINED
                ? "border-success"
                : "border-secondary"
            }`}
          ></div>
          <div
            className={`deal-status-line border ${
              status === MDF_STATUS_APPROVED
                ? "border-success"
                : status === MDF_STATUS_DECLINED
                ? "border-danger"
                : "border-secondary"
            }`}
          ></div>
        </div>
      </div>
    </SidebarItem>
  );
};

export default MdfStatus;
