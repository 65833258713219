import React, { useState } from "react";
import { Link } from "react-router-dom";
import { DashboardLinksIcon, LockIcon, ArrowUp } from "../../assets/img/icons";
import "react-circular-progressbar/dist/styles.css";
import AddButton from "../../components/base/AddButton";
import AlertSuccess from "../../components/base/Success";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

const QuickLinks = props => {
  const [successAlert, setSuccessAlert] = useState(true);
  const [submit, setSubmit] = useState(false);
  return (
    <div className={["expert-onboarding py-4"].join(" ")}>
      {successAlert && (
        <AlertSuccess
          message={props.message}
          onClick={() => setSuccessAlert(false)}
        />
      )}
      <h6 className="mb-0 text-info font-weight-bold quick-link">
        <span className="mr-2">
          <DashboardLinksIcon />{" "}
        </span>
        What Can I Do Now?
      </h6>

      <Link className="onboarding-link">
        <div className="onboarding-header">
          <div className="step-wrapper">
            <span className="step-count blue-faded completed">
              <LockIcon />
            </span>
          </div>
        </div>
        <div className="onboarding-main">
          <h5 className="font-weight-bold">Log in to Portal</h5>
          <p className="font-weight-bold text-inline-grayed">
            Your company profile appears in our partner directory and lets
            customers easily find you.
          </p>
        </div>
        <div className="onboarding-url" style={{ maxWidth: "fit-content" }}>
          <Link
            to={{
              pathname: "/dashboard"
            }}
          >
            <button
              className="btn btn-primary pr-3 mr-1 ml-3"
              style={{ width: props.deal ? "123px" : "145px" }}
            >
              Log in
            </button>
          </Link>
        </div>
      </Link>

      <div className="onboarding-link">
        <div className="onboarding-header">
          <div className="step-wrapper">
            <span className="step-count blue-faded completed">
              <ArrowUp />
            </span>
          </div>
        </div>
        <div className="onboarding-main">
          <h5 className="font-weight-bold">
            {props.deal
              ? "Submit Another Deal"
              : `Submit Another ${Terminology.get(SystemName.REFERRAL)}`}
          </h5>
          <p className="font-weight-bold text-inline-grayed">
            Copy your personalized link and share it with your team.
          </p>
        </div>
        <div className="onboarding-url" style={{ maxWidth: "fit-content" }}>
          <Link>
            <AddButton
              label={`Submit a ${
                props.deal ? "Deal" : `${Terminology.get(SystemName.REFERRAL)}`
              }`}
              className="btn btn-primary ml-8 p-2 pr-1 pt-2 pb-2"
              onClick={() => {
                setSubmit(true);
                props.redirectUrl && window.location.replace(props.redirectUrl);
              }}
              disabled={submit}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};

export default QuickLinks;
