import React from "react";
import { CalendarGraphicIcon } from "../../../assets/img/icons";
import "react-circular-progressbar/dist/styles.css";
import OnboardingLink from "./OnboardingLink";
import { HowItWorks, HowItWorksItem } from "./HowItWorks";
import hiw1 from "../../../assets/img/mock_imgs/how-it-works-1.jpg";
import hiw2 from "../../../assets/img/mock_imgs/how-it-works-2.jpg";
import hiw3 from "../../../assets/img/mock_imgs/how-it-works-3.jpg";

const ExpertOnboardingAlmostDone = props => {
  const {
    enable,
    percentage,
    expertAvailableStatus,
    expertAvailableText,
    showHowItWorks
  } = props;
  return (
    <div
      className={["expert-onboarding", `${!enable ? "disabled" : ""}`].join(
        " "
      )}
    >
      <a href="edit/profile" className="d-none">
        <OnboardingLink
          icon={<CalendarGraphicIcon />}
          title={"Awesome, you’re done for now!"}
          description={
            "We are currently evaluating your profile and qualifications. You will receive an update in less than 30 days."
          }
          availability={{
            status:
              expertAvailableStatus === "Completed"
                ? "available"
                : "not-available",
            text: expertAvailableText
          }}
          progress={percentage}
        />
      </a>
      {showHowItWorks && (
        <HowItWorks title={"Explore How Torchlite Works"}>
          <HowItWorksItem
            imgsrc={hiw1}
            title={"Get Notified"}
            description={
              "When a project is matched to your expertise, Torchlite will send a notification to gauge your interest. You’ll see the project in the “Incoming Projects” section on your Dashboard."
            }
            align={"left"}
            number={"1"}
          />
          <HowItWorksItem
            imgsrc={hiw2}
            title={"Make a Decision"}
            description={
              "To learn more about the project match, go to the project detail page. Once you’ve made a decision, take action as soon as possible on whether you are able to accept the project."
            }
            align={"right"}
            number={"2"}
          />
          <HowItWorksItem
            imgsrc={hiw3}
            title={"Project is Active"}
            description={
              "Once the project has been fully setup by the account manager, you will be notified when it has been activated and a kickoff call is ready to be scheduled."
            }
            align={"left"}
            number={"3"}
          />
        </HowItWorks>
      )}
    </div>
  );
};

export default ExpertOnboardingAlmostDone;
