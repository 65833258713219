import React from "react";
import SidebarItem from "./SidebarItem";
import SupportingMaterials from "../SupportingMaterials";

const SidebarSupportingMaterials = React.memo(
  function SidebarSupportingMaterials({
    supportingMaterialsAsState,
    supportingMaterialsAsProps,
    handleMaterialUpload,
    deleteSupportMaterial
  }) {
    return (
      <SidebarItem>
        <p className="font-weight-bold mb-1">Supporting Materials</p>{" "}
        <span className="sidebar-item-description d-block h7 text-grayed mb-3">
          Any materials that will help the expert complete the task.
        </span>
        <SupportingMaterials
          data={supportingMaterialsAsState || supportingMaterialsAsProps}
          onMaterialUpload={handleMaterialUpload}
          deleteSupportMaterial={deleteSupportMaterial}
        />
      </SidebarItem>
    );
  }
);

export default SidebarSupportingMaterials;
