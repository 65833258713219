import React, { useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import DatePicker from "react-datepicker";

// Components
import Form from "../../../../../components/base/Form";
import DefaultContent from "../../../../../components/content/DefaultContent";
import Text from "../../../../../components/base/Text";
import Select from "../../../../../components/base/Select";
import GroupCheckbox from "../../../../../components/base/GroupCheckbox";
import SaveButton from "../../../../../components/base/SaveButton";

// Component
import SalesforceFieldMapping from "../../../../../salesforce/setting/SalesforceFieldMappingTab";

import SalesforceSyncRule from "../../../../../salesforce/setting/SalesforceSyncRule";

// Constants
import {
  SETTINGS_DEAL_REGISTRATION_APPROVAL_PLATFORM,
  SETTINGS_DEAL_REGISTRATION_DEFAULT_OWNER,
  SETTINGS_PARTNER_ACCOUNT_TYPE_NAME,
  SETTINGS_SALESFORCE_ACCOUNT_OWNER_FIELD_NAME,
  SETTINGS_SALESFORCE_ACCOUNT_STATUS_FIELD_NAME,
  SETTINGS_SALESFORCE_CLIENT_ID,
  SETTINGS_SALESFORCE_CLIENT_SECRET,
  SETTINGS_SALESFORCE_ENVIRONMENT,
  SETTINGS_SALESFORCE_OBJECT,
  SETTINGS_SALESFORCE_OPPORTUNITY_ACCOUNT_CREATED_BY_FIELD_NAME,
  SETTINGS_SALESFORCE_OPPORTUNITY_AMOUNT_FIELD_NAME,
  SETTINGS_SALESFORCE_OPPORTUNITY_CLOSED_STATUS_NAME,
  SETTINGS_SALESFORCE_OPPORTUNITY_PARTNER_ACCOUNT_NAME_FIELD,
  SETTINGS_SALESFORCE_OPPORTUNITY_PARTNER_CONTACT_NAME_FIELD,
  SETTINGS_SALESFORCE_OPPORTUNITY_SYNC_FROM_DATE,
  SETTINGS_SALESFORCE_TERRITORY_FIELD_NAME,
  SALESFORCE_PROSPECT_ACCOUNT_RECORD_TYPE,
  SALESFORCE_PARTNER_ACCOUNT_RECORD_TYPE,
  SETTINGS_ENABLE_SALESFORCE_ACCOUNT_NOTE_UPDATE
} from "../../../../../setting/Constants";

import ToggleSwitch from "../../../../../components/base/ToggleSwitch";

// Helper
import { toString } from "../../../../../lib/helper";
import ConfirmationModal from "../../../../../components/base/ConfirmationModal";

const SalesforceSection = props => {
  const {
    adminSettings,
    saveSettings,
    salesforceSettings,
    AccountObjectFieldList,
    OpportunityObjectFieldList
  } = props;

  const {
    salesforce_instance_url,
    salesforce_opportunity_sync_from_date,
    salesforce_environment,
    salesforce_client_id,
    salesforce_client_secret,
    salesforce_object,
    salesforce_opportunity_closed_status_name,
    salesforce_opportunity_amount_field_name,
    salesforce_opportunity_account_created_by_field_name,
    salesforce_opportunity_partner_account_name_field,
    salesforce_opportunity_partner_contact_name_field,
    deal_registration_approval_platform,
    deal_registration_default_owner,
    partner_account_type_name,
    salesforce_territory_field_name,
    salesforce_account_owner_field_name,
    salesforce_account_status_field_name,
    salesforce_prospect_account_record_type,
    salesforce_partner_account_record_type,
    enable_salesforce_account_note_update
  } = adminSettings;

  // initilize states
  const [activeTab, setActiveTab] = useState(1);
  const [confirmationModal, setConfirmationModal] = useState(false);

  const [
    enableSalesforceAccountNoteUpdate,
    setEnableSalesforceAccountNoteUpdate
  ] = useState(enable_salesforce_account_note_update == "true" ? true : false);

  const { handleSalesForceConnect, resetInstanceUrl } = salesforceSettings;

  // Salesforce Section Initial Values
  const salesforceSettingsInitialValues = {
    salesforce_client_id,
    salesforce_client_secret,
    salesforce_opportunity_closed_status_name,
    salesforce_opportunity_amount_field_name,
    salesforce_opportunity_account_created_by_field_name,
    salesforce_opportunity_partner_account_name_field,
    salesforce_opportunity_partner_contact_name_field,
    partner_account_type_name,
    salesforce_territory_field_name,
    salesforce_account_owner_field_name,
    salesforce_account_status_field_name,
    salesforce_prospect_account_record_type,
    salesforce_partner_account_record_type
  };

  const defaultAccountManagerList =
    adminSettings.defaultAccountManagerList || [];

  const isSalesforceConnected = salesforce_instance_url ? true : false;

  const [defaultSyncFromDate, setDefaultSyncFromDate] = useState(
    salesforce_opportunity_sync_from_date &&
      new Date(salesforce_opportunity_sync_from_date)
  );

  const toggle = tab => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const salesforceObjectOption = () => [
    {
      label: "Lead",
      value: "Lead"
    },
    {
      label: "Opportunity",
      value: "Opportunity"
    }
  ];

  const salesforceEnvironmentOption = () => [
    {
      label: "Production",
      value: "Production"
    },
    {
      label: "Sandbox",
      value: "Sandbox"
    }
  ];

  const approvalPlatform = () => [
    {
      label: "Approval in Salesforce",
      value: "Approval in Salesforce"
    },
    {
      label: "Approval in Torchlite Platform",
      value: "Approval in Torchlite Platform"
    }
  ];

  // const PartnerAccountType = () => [
  //   {
  //     label: "Channel Partner / Reseller",
  //     value: "Channel Partner / Reseller"
  //   },
  //   {
  //     label: "Installation Partner",
  //     value: "Installation Partner"
  //   },
  //   {
  //     label: "Technology Partner",
  //     value: "Technology Partner"
  //   }
  // ];

  const partnerRegistrationTypes = [
    {
      option: "Partners register in Torchlite Platform"
    },
    {
      option: "Partners are synced from Salesforce"
    }
  ];

  const recordTypes = [
    {
      option: "Deals can be submitted as a Lead"
    },
    {
      option: "Deals can be submitted as an Opportunity"
    }
  ];

  // Salesforce Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Salesforce Integration Data
    if (values.salesforce_url !== undefined) {
      data.append("salesforce_url", values.salesforce_url);
    }

    if (values.salesforce_client_id !== undefined) {
      data.append("salesforce_client_id", values.salesforce_client_id);
    }

    if (values.salesforce_client_secret !== undefined) {
      data.append("salesforce_client_secret", values.salesforce_client_secret);
    }

    if (values.deal_registration_approval_platform !== undefined) {
      data.append(
        "deal_registration_approval_platform",
        toString(
          values.deal_registration_approval_platform &&
            typeof values.deal_registration_approval_platform === "object"
            ? values.deal_registration_approval_platform.value
            : values.deal_registration_approval_platform
        )
      );
    }

    if (values.salesforce_object !== undefined) {
      data.append(
        "salesforce_object",
        toString(
          values.salesforce_object &&
            typeof values.salesforce_object === "object"
            ? values.salesforce_object.value
            : values.salesforce_object
        )
      );
    }

    if (values.salesforce_environment !== undefined) {
      data.append(
        "salesforce_environment",
        toString(
          values.salesforce_environment &&
            typeof values.salesforce_environment === "object"
            ? values.salesforce_environment.value
            : values.salesforce_environment
        )
      );
    }

    if (values.deal_registration_default_owner !== undefined) {
      data.append(
        "deal_registration_default_owner",
        toString(
          values.deal_registration_default_owner &&
            typeof values.deal_registration_default_owner === "object"
            ? values.deal_registration_default_owner.value
            : values.deal_registration_default_owner
        )
      );
    }

    if (values.salesforce_opportunity_sync_from_date !== undefined) {
      data.append(
        "salesforce_opportunity_sync_from_date",
        values.salesforce_opportunity_sync_from_date !== null
          ? values.salesforce_opportunity_sync_from_date
          : ""
      );
    }

    if (values.salesforce_opportunity_closed_status_name !== undefined) {
      data.append(
        "salesforce_opportunity_closed_status_name",
        values.salesforce_opportunity_closed_status_name
      );
    }

    if (values.salesforce_opportunity_amount_field_name !== undefined) {
      data.append(
        "salesforce_opportunity_amount_field_name",
        values.salesforce_opportunity_amount_field_name
      );
    }
    if (values.partner_account_type_name !== undefined) {
      data.append(
        "partner_account_type_name",
        toString(
          values.partner_account_type_name &&
            typeof values.partner_account_type_name === "object"
            ? values.partner_account_type_name.value
            : values.partner_account_type_name
        )
      );
    }

    if (values.salesforce_territory_field_name !== undefined) {
      data.append(
        "salesforce_territory_field_name",
        values.salesforce_territory_field_name
          ? values.salesforce_territory_field_name
          : ""
      );
    }

    if (
      values.salesforce_opportunity_partner_account_name_field !== undefined
    ) {
      data.append(
        "salesforce_opportunity_partner_account_name_field",
        values.salesforce_opportunity_partner_account_name_field
          ? values.salesforce_opportunity_partner_account_name_field
          : ""
      );
    }

    if (
      values.salesforce_opportunity_partner_contact_name_field !== undefined
    ) {
      data.append(
        "salesforce_opportunity_partner_contact_name_field",
        values.salesforce_opportunity_partner_contact_name_field
          ? values.salesforce_opportunity_partner_contact_name_field
          : ""
      );
    }

    if (
      values.salesforce_opportunity_account_created_by_field_name !== undefined
    ) {
      data.append(
        "salesforce_opportunity_account_created_by_field_name",
        values.salesforce_opportunity_account_created_by_field_name
          ? values.salesforce_opportunity_account_created_by_field_name
          : ""
      );
    }

    if (values.salesforce_account_owner_field_name !== undefined) {
      data.append(
        "salesforce_account_owner_field_name",
        values.salesforce_account_owner_field_name
          ? values.salesforce_account_owner_field_name
          : ""
      );
    }

    if (values.salesforce_account_status_field_name !== undefined) {
      data.append(
        "salesforce_account_status_field_name",
        values.salesforce_account_status_field_name
          ? values.salesforce_account_status_field_name
          : ""
      );
    }

    if (values.salesforce_prospect_account_record_type !== undefined) {
      data.append(
        "salesforce_prospect_account_record_type",
        values.salesforce_prospect_account_record_type
          ? values.salesforce_prospect_account_record_type
          : ""
      );
    }

    if (values.salesforce_partner_account_record_type !== undefined) {
      data.append(
        "salesforce_partner_account_record_type",
        values.salesforce_partner_account_record_type
          ? values.salesforce_partner_account_record_type
          : ""
      );
    }

    // Save settings
    saveSettings(data);
  };

  // Disconnect salesforce
  const disconnectSalesforce = () => {
    const data = new FormData();
    data.append("salesforce_instance_url", "");
    data.append("salesforce_refresh_token", "");
    resetInstanceUrl();

    // Save settings
    saveSettings(data);
  };

  const handleConfirmationModal = () => {
    setConfirmationModal(true);
  };

  const closeConfirmationModal = () => {
    setConfirmationModal(false);
  };

  // Handle enable salesforce note update
  const handleEnableAccountNoteUpdate = enable_salesforce_account_note_update => {
    setEnableSalesforceAccountNoteUpdate(enable_salesforce_account_note_update);

    const data = new FormData();

    if (enable_salesforce_account_note_update !== undefined) {
      data.append(
        "enable_salesforce_account_note_update",
        enable_salesforce_account_note_update == true ? "true" : "false"
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <>
      {/* Salesforce Disconnect Confirmation Modal */}
      {isSalesforceConnected && (
        <ConfirmationModal
          title="Disconnect SalesForce"
          description="Are you sure, you want to disconnect Salesforce?"
          setModal={() => handleConfirmationModal()}
          modal={confirmationModal}
          closeModal={() => closeConfirmationModal()}
          submitValue={disconnectSalesforce}
        />
      )}

      <Nav tabs className="admin-tabs mb-2">
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 1
            })}
            onClick={() => {
              toggle(1);
            }}
          >
            General
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 2
            })}
            onClick={() => {
              toggle(2);
            }}
          >
            Opportunity
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 3
            })}
            onClick={() => {
              toggle(3);
            }}
          >
            Deals
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 4
            })}
            onClick={() => {
              toggle(4);
            }}
          >
            Account
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 5
            })}
            onClick={() => {
              toggle(5);
            }}
          >
            Salesforce Field Mapping
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeTab === 6
            })}
            onClick={() => {
              toggle(6);
            }}
          >
            Salesforce Sync Rule
          </NavLink>
        </NavItem>
      </Nav>

      <div className="tabs-and-preview full-width d-flex admin-settings">
        <TabContent activeTab={activeTab}>
          <TabPane tabId={1} className="w-100">
            <Form
              initialValues={salesforceSettingsInitialValues}
              onSubmit={values => {
                submit(values);
              }}
            >
              <DefaultContent
                title="Salesforce"
                enableButton
                disabled={
                  !salesforce_client_id || !salesforce_client_secret
                    ? true
                    : false
                }
                buttonLabel={!isSalesforceConnected ? "Connect" : "Disconnect"}
                onButtonClick={() => {
                  if (!isSalesforceConnected) {
                    handleSalesForceConnect();
                  } else {
                    handleConfirmationModal();
                  }
                }}
              >
                <h5 className="font-weight-bold">Salesforce Environment</h5>
                <div className="field-wrapper">
                  <Select
                    name={SETTINGS_SALESFORCE_ENVIRONMENT}
                    placeholder="Select Salesforce Environment"
                    options={salesforceEnvironmentOption()}
                    defaultValue={salesforceEnvironmentOption().find(
                      type => type.value === salesforce_environment
                    )}
                    isSearchable={true}
                  />
                </div>

                <h5 className="font-weight-bold">Client Id</h5>
                <div className="field-wrapper">
                  <Text
                    name={SETTINGS_SALESFORCE_CLIENT_ID}
                    placeholder="Salesforce Client Id"
                  />
                </div>

                <h5 className="font-weight-bold">Client Secret</h5>
                <div className="field-wrapper">
                  <Text
                    name={SETTINGS_SALESFORCE_CLIENT_SECRET}
                    placeholder="Salesforce Client Secret"
                  />
                </div>

                <h5 className="font-weight-bold">Salesforce Object</h5>
                <div className="field-wrapper">
                  <Select
                    name={SETTINGS_SALESFORCE_OBJECT}
                    placeholder="Select salesforce object"
                    options={salesforceObjectOption()}
                    defaultValue={salesforceObjectOption().find(
                      type => type.value === salesforce_object
                    )}
                    isSearchable={true}
                  />
                </div>
              </DefaultContent>

              <div className="btn-wrapper">
                <SaveButton />
              </div>
            </Form>
          </TabPane>
          {/* Opportunity Tab */}
          <TabPane tabId={2} className="w-100">
            <Form
              initialValues={salesforceSettingsInitialValues}
              onSubmit={values => {
                values[
                  SETTINGS_SALESFORCE_OPPORTUNITY_SYNC_FROM_DATE
                ] = defaultSyncFromDate;

                submit(values);
              }}
            >
              <DefaultContent>
                <h5 className="font-weight-bold">Close Status Name</h5>
                <div className="field-wrapper">
                  <Text
                    name={SETTINGS_SALESFORCE_OPPORTUNITY_CLOSED_STATUS_NAME}
                    placeholder="Enter Close Status Name"
                  />
                </div>
                <h5 className="font-weight-bold">Amount Field Name</h5>
                <div className="field-wrapper">
                  <Text
                    name={SETTINGS_SALESFORCE_OPPORTUNITY_AMOUNT_FIELD_NAME}
                    placeholder="Enter Amount Field Name"
                  />
                </div>
                <h5 className="font-weight-bold">
                  Account Created By Field Name
                </h5>
                <div className="field-wrapper">
                  <Text
                    name={
                      SETTINGS_SALESFORCE_OPPORTUNITY_ACCOUNT_CREATED_BY_FIELD_NAME
                    }
                    placeholder="Enter Account Created By Field Name"
                  />
                </div>
                <h5 className="font-weight-bold">Partner Account Name Field</h5>
                <div className="field-wrapper">
                  <Text
                    name={
                      SETTINGS_SALESFORCE_OPPORTUNITY_PARTNER_ACCOUNT_NAME_FIELD
                    }
                    placeholder="Enter Partner Account Name Field"
                  />
                </div>
                <h5 className="font-weight-bold">Partner Contact Name Field</h5>
                <div className="field-wrapper">
                  <Text
                    name={
                      SETTINGS_SALESFORCE_OPPORTUNITY_PARTNER_CONTACT_NAME_FIELD
                    }
                    placeholder="Enter Partner Contact Name Field"
                  />
                </div>
                <h5 className="font-weight-bold">Sync From Date</h5>
                <div className="field-wrapper form-group">
                  <DatePicker
                    name={SETTINGS_SALESFORCE_OPPORTUNITY_SYNC_FROM_DATE}
                    className="datepicker"
                    placeholderText="MM/DD/YYYY"
                    onChange={date => setDefaultSyncFromDate(date)}
                    maxDate={new Date()}
                    selected={defaultSyncFromDate}
                    clearIcon={null}
                  />
                </div>

                <h5 className="font-weight-bold">
                  Salesforce Account Note Update
                </h5>
                <ToggleSwitch
                  label1={"Enable "}
                  label2={"Disable"}
                  value={enableSalesforceAccountNoteUpdate}
                  handleChange={() => {
                    handleEnableAccountNoteUpdate(
                      !enableSalesforceAccountNoteUpdate
                    );
                  }}
                  outlined
                />
              </DefaultContent>

              <div className="btn-wrapper">
                <SaveButton />
              </div>
            </Form>
          </TabPane>

          {/* Deal Registration Tab */}
          <TabPane tabId={3} className="w-100">
            <Form
              initialValues={salesforceSettingsInitialValues}
              onSubmit={values => {
                submit(values);
              }}
            >
              <DefaultContent title="Deal Registration">
                <h5 className="font-weight-bold">Approval Platform</h5>
                <div className="field-wrapper">
                  <Select
                    name={SETTINGS_DEAL_REGISTRATION_APPROVAL_PLATFORM}
                    placeholder="Select a Deal Registration Approval Platform"
                    options={approvalPlatform()}
                    defaultValue={approvalPlatform().find(
                      type => type.value === deal_registration_approval_platform
                    )}
                    isSearchable={true}
                  />
                </div>

                <h5 className="font-weight-bold">Record Types</h5>
                <div className="field-wrapper">
                  <GroupCheckbox
                    name="productsInvolved"
                    classnames="custom-checkbox"
                    options={recordTypes}
                  />
                </div>

                <h5 className="font-weight-bold">Default Owner</h5>
                <div className="field-wrapper">
                  <Select
                    name={SETTINGS_DEAL_REGISTRATION_DEFAULT_OWNER}
                    label="Who do you want to assign as the default Owner?"
                    placeholder="Select a default account manager"
                    options={defaultAccountManagerList}
                    defaultValue={defaultAccountManagerList.find(
                      type =>
                        type.value ===
                        parseInt(deal_registration_default_owner, 10)
                    )}
                    isSearchable={true}
                  />
                </div>

                <h5 className="font-weight-bold">Partner Registration</h5>
                <div className="field-wrapper">
                  <GroupCheckbox
                    name="productsInvolved"
                    classnames="custom-checkbox"
                    options={partnerRegistrationTypes}
                  />
                </div>
              </DefaultContent>
              <div className="btn-wrapper">
                <SaveButton />
              </div>
            </Form>
          </TabPane>

          {/* Account Tab */}
          <TabPane tabId={4} className="w-100">
            <Form
              initialValues={salesforceSettingsInitialValues}
              onSubmit={values => {
                submit(values);
              }}
            >
              <DefaultContent>
                <h5 className="font-weight-bold">Partner Account Type Name</h5>
                <div className="field-wrapper">
                  <Text
                    name={SETTINGS_PARTNER_ACCOUNT_TYPE_NAME}
                    placeholder="Select Partner Account Type Name"
                  />
                  <h5 className="font-weight-bold">Territory Field Name</h5>
                  <div className="field-wrapper">
                    <Text
                      name={SETTINGS_SALESFORCE_TERRITORY_FIELD_NAME}
                      placeholder="Enter Territory Field Name"
                    />
                  </div>
                  <h5 className="font-weight-bold">Account Owner Field Name</h5>
                  <div className="field-wrapper">
                    <Text
                      name={SETTINGS_SALESFORCE_ACCOUNT_OWNER_FIELD_NAME}
                      placeholder="Enter Account Owner Field Name"
                    />
                    <h5 className="font-weight-bold">
                      Account Status Field Name
                    </h5>
                    <div className="field-wrapper">
                      <Text
                        name={SETTINGS_SALESFORCE_ACCOUNT_STATUS_FIELD_NAME}
                        placeholder="Enter Account Status Field Name"
                      />
                    </div>

                    <h5 className="font-weight-bold">
                      Salesforce Partner Account Record Type
                    </h5>
                    <div className="field-wrapper">
                      <Text
                        name={SALESFORCE_PARTNER_ACCOUNT_RECORD_TYPE}
                        placeholder="Enter Salesforce Partner Account Record Type"
                      />
                    </div>

                    <h5 className="font-weight-bold">
                      Salesforce Prospect Account Record Type
                    </h5>
                    <div className="field-wrapper">
                      <Text
                        name={SALESFORCE_PROSPECT_ACCOUNT_RECORD_TYPE}
                        placeholder="Enter Salesforce Prospect Account Record Type "
                      />
                    </div>
                  </div>
                </div>
              </DefaultContent>
              <div className="btn-wrapper">
                <SaveButton />
              </div>
            </Form>
          </TabPane>

          {/* Account Tab */}
          <TabPane tabId={5} className="w-100">
            <SalesforceFieldMapping
              AccountObjectFieldList={AccountObjectFieldList}
              OpportunityObjectFieldList={OpportunityObjectFieldList}
              history={props.history}
            />
          </TabPane>

          {/* Account Tab */}
          <TabPane tabId={6} className="w-100">
            <SalesforceSyncRule
              AccountObjectFieldList={AccountObjectFieldList}
              OpportunityObjectFieldList={OpportunityObjectFieldList}
            />
          </TabPane>
        </TabContent>
      </div>
    </>
  );
};

export default SalesforceSection;
