import React, { Component } from "react";
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-date-picker";

// Components
import Text from "../../../../../components/base/Text";
import TextArea from "../../../../../components/base/TextArea";
import Currency from "../../../../../components/base/Currency";

class AccountFormFields extends Component {
  // Render the form fields
  render() {
    let OpportunityDetails = this.props.opportunityDetails;
    return (
      <div>
        <div className="card bg-white mt-3 mb-3">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-6 col-sm-12">
                <div>
                  {OpportunityDetails &&
                    Object.keys(OpportunityDetails).map(key => {
                      return (
                        <>
                          {typeof OpportunityDetails[key] != "object" && (
                            <Text
                              name={key}
                              label={key}
                              error=""
                              defaultValue={OpportunityDetails[key]}
                              disabled
                            />
                          )}
                        </>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default AccountFormFields;
