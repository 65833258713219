// Helper
import Terminology from "../lib/Terminology";
import { getStringToUrl } from "../lib/helper";

export const TAB_PIPELINE = "Pipeline";
export const TAB_ACTIVITIES = "User Activities";
export const TAB_PARTNER_ACTIVITIES = "Partner Activities";
export const TAB_PARTNER_TIER_OR_QUOTA_REPORT = "Partner Tier / Quota Report";
export const TAB_REFERRAL_LINK_TRACKING_ACTIVITY_REPORT =
  "Referral Link Tracking Activity Report";
export const TAB_REFERRAL_LINK_TRACKING_REPORT =
  "Referral Link Tracking Report";
export const REFERRAL_LINK_CLICKED = "Referral Link Clicked";
export const TAB_CLOSED_WON_REFERRAL_REPORT = "Closed Won Referral Report";
export const TAB_OVERALL_SUMMARY = "Overall Summary";
export const TAB_REFERRAL_REPORT = "Referral Report";
export const TAB_PARTNER_REPORT = "Partner Report";
export const TAB_PARTNER_USER_REPORT = "Partner User Report";

//get tabName from URL
const tabNameList = [
  TAB_PIPELINE,
  TAB_ACTIVITIES,
  TAB_PARTNER_TIER_OR_QUOTA_REPORT,
  TAB_PARTNER_ACTIVITIES
];
export const getTabNameFromUrl = URL => {
  let tabName = "";
  tabNameList.forEach(tab => {
    if (getStringToUrl(tab) === URL) {
      tabName = tab;
    }
  });
  return tabName;
};

/**
 * Get Reports Side Nav List
 *
 * @returns {Array}
 */
export function getReportsNavList() {
  let reportsNavList = [];

  // Return Reports Nav Object
  const getReportsNavObject = (tab, subtab = "") => {
    return {
      name: tab,
      defaultSubTab: subtab
    };
  };

  reportsNavList = [
    ...reportsNavList,
    getReportsNavObject(TAB_PIPELINE),
    getReportsNavObject(TAB_ACTIVITIES),
    getReportsNavObject(TAB_PARTNER_TIER_OR_QUOTA_REPORT),
    getReportsNavObject(TAB_REFERRAL_LINK_TRACKING_ACTIVITY_REPORT)
  ];

  // Return Reports Nav List
  return reportsNavList;
}
