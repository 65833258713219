import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownItem } from "reactstrap";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import MoreDropdown from "../../components/base/MoreDropdown";

// Config
import { endpoints } from "../../configs";

// Assets
import { StarIcon, StarIconFilled } from "../../assets/img/icons";

// Action
import { updateReview } from "../../actions/review";
import {
  FEEDBACK_REVIEW_STATUS_APPROVED,
  FEEDBACK_REVIEW_STATUS_PENDING,
  FEEDBACK_TABLE_PENDING,
  FEEDBACK_APPROVE_LABEL,
  FEEDBACK_UNAPPROVE_LABEL
} from "../../feedback/Constants";
import Rating from "react-rating";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class AdminFeedbackTable extends Component {
  // Update The Expert By Status
  updateReviewByStatus(status, reviewId) {
    this.props.actions.updateReview(
      reviewId,
      { status },
      { status: "Pending" }
    );
  }

  render() {
    return (
      <div>
        <ReduxTable
          id={this.props.tableName}
          showHeader
          searchPlaceholder="Search"
          newTableHeading
          apiURL={`${endpoints().reviewAPI}/search`}
          params={{ status: this.props.status }}
          disableColumnSort
          sortByOptions={this.props.sortByOptions}
          onRowClick={row =>
            this.props.history.push(`/feedback/details/${row.id}`)
          }
        >
          <ReduxColumn field="category">Category</ReduxColumn>
          <ReduxColumn type="link" isClickable="true" field="projectName">
            Project
          </ReduxColumn>
          <ReduxColumn
            field="rating"
            renderField={row => (
              <p style={{ width: "120px" }}>
                <Rating
                  initialRating={row.rating}
                  emptySymbol={<StarIcon />}
                  fullSymbol={<StarIconFilled />}
                  readonly
                />
              </p>
            )}
          >
            Rating
          </ReduxColumn>

          <ReduxColumn field="userName">Posted By</ReduxColumn>
          <ReduxColumn field="createdAt">Created At</ReduxColumn>
          <ReduxColumn
            field="status"
            renderField={row => (
              <div
                className={`text-center text-uppercase ${
                  row.status === FEEDBACK_REVIEW_STATUS_APPROVED
                    ? "landing-page-status-active"
                    : "landing-page-status-draft"
                }`}
                style={statusStyle}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="Actions"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() =>
                      this.updateReviewByStatus(
                        this.props.tableName === FEEDBACK_TABLE_PENDING
                          ? FEEDBACK_REVIEW_STATUS_APPROVED
                          : FEEDBACK_REVIEW_STATUS_PENDING,
                        row.id
                      )
                    }
                  >
                    {this.props.tableName === FEEDBACK_TABLE_PENDING
                      ? FEEDBACK_APPROVE_LABEL
                      : FEEDBACK_UNAPPROVE_LABEL}
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updateReview }, dispatch)
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(AdminFeedbackTable);
