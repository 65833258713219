import React from "react";
import moment from "moment";

// Components
import ProjectList from "../../components/ProjectList";
import AdminStatisticsCards from "./AdminStatisticsCards";
import TopPartners from "./TopPartners";
import TopPartnersDealClosed from "./TopPartnersDealClosed";
import CustomersStatisticsCards from "./CustomersStatisticsCards";
import ExpertStatisticsCards from "./ExpertStatisticsCards";
import PartnerStatisticsCards from "./PartnerStatisticsCards";
import ExpertOnboarding from "./expert-components/ExpertOnboarding";
import IncomingProjects from "./expert-components/IncomingProjects";
import ExpertOnboardingAlmostDone from "./expert-components/ExpertOnboardingAlmostDone";
import ExpertAccepted from "./expert-components/ExpertAccepted";
import ExpertMatched from "./expert-components/ExpertMatched";
import ExpertStatus from "./expert-components/ExpertStatus";
import ExpertProStatus from "./expert-components/ExpertProStatus";
import ExpertEliteStatus from "./expert-components/ExpertEliteStatus";
import AlertWarning from "../../components/base/Warning";
import Spinner from "../../components/base/Spinner";
import KickOffMeetingList from "../projects/inner-components/kickoff/KickOffMeetingList";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import { fetchProjectList } from "../../actions/project";

import { PROJECT_USER_STATUS_NO_RESPONSE } from "../../project/Constants";

// Partner dashboard component
import PartnerOnboarding from "./partner-components/PartnerOnboarding";
import StyledDealRegistrationCard from "./partner-components/StyledDealRegistrationCard";
import StyledReferralSubmitCard from "./partner-components/StyledReferralSubmitCard";
import PartnerDashboardBanner from "./PartnerDashboardBanner";
import SingleCheckbox from "../../components/base/SingleCheckbox";
import AccountMappingInsights from "./partner-components/AccountMappingInsights";
import CampaignPopup from "./partner-components/CampaignsPopup";

import { Formik, Form as FormikForm } from "formik";
import toast from "../../components/base/Toast";
import {
  SETTING_PORTAL_TERMS_AND_CONDITIONS_LINK,
  SETTINGS_ENABLE_CAMPAIGNS,
  SETTINGS_ENABLE_PARTNER_PAYMENTS
} from "../../setting/Constants";
// Helper
import {
  isLoggedIn,
  getCookie,
  setCookie,
  clearCookie,
  isCustomer,
  isExpert,
  isPartner,
  isSuperAdmin,
  isCompanyAdmin,
  getUrlParameter,
  removeParamsByName,
  getKeyValueByObject,
  isCompanyManager
} from "../../lib/helper";

import {
  COOKIE_SHOW_ASSIGNED_PARTNERS,
  COOKIE_PARTNER_PROFILE_COMPLETED,
  COOKIE_PARTNER_PROFILE_ACCEPTED
} from "../../lib/cookie";
// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Constants
import {
  PROJECT_USER_STATUS_INTERESTED,
  PROJECT_USER_STATUS_WAITING_FOR_APPROVAL,
  PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE,
  PROJECT_USER_STATUS_ACCEPTED,
  PROJECT_TYPE_CAMPAIGN_URL_PATH
} from "../../project/Constants";
import {
  PROJECT_STATUS_REQUESTED,
  PROJECT_STATUS_MATCHING,
  PROJECT_STATUS_INPROCESS
} from "../../projectStatus/Constants";
import { USER_EMAIL_VERIFICATION_REQUIRED } from "../../users/Constants";

import CustomerHowItWorks from "./customer-components/CustomerHowItWorks";
import PaymentOverdueModal from "./customer-components/PaymentOverdueModal";
import CustomerAlerts from "./customer-components/CustomerAlerts";
import ExpertAvailabilityCheck from "./expert-components/ExpertAvailabilityCheck";
import ExpertInterestCheck from "./expert-components/ExpertInterestCheck";
import TaskCompletedModal from "./customer-components/TaskCompletedModal";
import { COOKIE_SESSION_TOKEN } from "../../lib/cookie";
import MyContext from "../../context/MyContext";
import PageTitle from "../../components/base/PageTitle";

import {
  EXPERT_TYPE_10_20_HOURS_WEEK,
  EXPERT_TYPE_10_40_HOURS_TOTAL,
  EXPERT_TYPE_20_30_HOURS_WEEK,
  EXPERT_TYPE_30_PLUS_HOURS_WEEK,
  EXPERT_TYPE_NOT_CURRENTLY_AVAILABLE
} from "../../expertHours/Constants";
import CustomerProjectMatchingAlert from "./customer-components/CustomerProjectMatchingAlert";

import RecommendedResourcesList from "./partner-components/RecommendedResourcesList";
import AdminNeedsAttention from "./AdminNeedsAttention";
import SelectDropdown from "../../components/base/SelectDropdown";
import {
  PARTNER_DASHBOARD_MY_REFERRALS_COUNT,
  PARTNER_DASHBOARD_OPEN_DEALS_COUNT,
  PARTNER_MANAGER_DASHBOARD,
  SERVICE_DELIVERY_DASHBOARD
} from "../../dashboard/Constants";
import {
  SETTINGS_ENABLE_DEALS,
  SETTINGS_ENABLE_PARTNERS,
  SETTINGS_ENABLE_PROJECTS,
  SETTINGS_DASHBOARD_SHOW_ACCOUNT_MAPPING,
  SETTING_ENABLE_PARTNER_TERMS_AND_CONDITIONS_UPDATE_NOTIFICATION
} from "../../setting/Constants";
import { SETTINGS_ENABLE_REFERRALS } from "../../setting/Constants";
import PayoutPreferencePopup from "./PayoutPreferencePopup";
import ProfileCompletedModal from "./partner-components/ProfileCompletedModal";
import ProfileAcceptedModal from "./partner-components/ProfileAcceptedModal";
import {
  PARTNER_STATUS_APPROVED,
  PARTNER_STATUS_PENDING
} from "../../partnerStatus/Constants";

// Partner type setting constants
import { PARTNER_TYPE_SETTING_ENABLE_CO_MARKETING_CAMPAIGNS } from "../../../src/partnerTypeSetting/Constants";

import RecentPartnerUserActivities from "./RecentPartnerUserActivities";
import PartnerTierProgress from "./PartnerTierProgress";
import { isBadRequest } from "../../common/http";
import SubTitle from "../../components/base/SubTitle";
import { Link } from "react-router-dom";
import AlertBanner from "../../components/base/AlertBanner";
import { OctagonAlertIcon } from "../../assets/img/icons";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

import { REFERRAL_STATUS_REQUESTED } from "../../referral/Constants";

class Dashboard extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isChecked: "",
      selectedManagerDashboard: PARTNER_MANAGER_DASHBOARD,
      expertProfileCompletionPercentage: "",
      partnerProfileCompletionPercentage: "",
      profileTaskCompleted: false,
      profileUpdateStatus: "",
      expertPaymentAccessStatus: "",
      partnerPaymentAccessStatus: "",
      expertAvailableStatus: "",
      availability: "",
      alertModal: false,
      startDate: new Date(),
      startTime: new Date(),
      paymentOverdueModal: false,
      hidePaymentOverdueNotification: false,
      hideHandleHowItWorksClose: false,
      taskCompleteModal: false,
      showExpertAvailabilityCheck: true,
      isCustomerDashboardCountLoading: true,
      customerPendingProjectsCount: 0,
      customerActiveProjectsCount: 0,
      customerCompletedProjectsCount: 0,
      isUpdateAvailabilityBanner: 0,
      activeProjects: [],
      isProjectDeliverablesLoading: false,
      pendingProjectDeliverables: "",
      expertIncomingProjects: [],
      expertIncomingProjectCount: 0,
      isApprovedExpert: false,
      showHowItWorks: false,
      allowAccess: true,
      LoadingResendVerification: false,
      expertCustomerProjectsCount: 0,
      expertCompletedProjectsCount: 0,
      isExpertDashboardCountLoading: false,
      isPartnerDashboardCountLoading: false,
      isExpertIncomingProjectAlert: false,
      expertIncomingProjectAlertList: [],
      hideCustomerProjectMatchingAlert: false,
      matchingProjectsCount: 0,
      expertActivePojectsCount: 0,
      dealsMarketplaceReferralCount: 0,
      dealsRegisteredCount: 0,
      pendingDealsCount: 0,
      closedDealsCount: 0,
      earnedReferralAmount: 0,
      earnedReferralCommissionAmount: 0,
      earnedDealAmount: 0,
      projectedDealAmount: 0,
      submittedReferralCount: 0,
      closeReferralCount: 0,
      accountPaymentCount: 0,
      acceptedReferralCount: 0,
      spotlightImageUrl: "",
      resourceList: [],
      partnerOpenDeals: [],
      partnerOpenDealsCount: 0,
      partnerReferrals: [],
      partnerReferralsCount: 0,
      isProfileCompleted: false,
      isProfileTasksCompleted: false,
      isProfileAccepted: false,
      partnerApplicationTaskList: [],
      isTaskFileUploadModal: false,
      currentTask: "",
      enablePartner: false,
      partnerTierList: [],
      currentPartnerTierDetails: "",
      crossBeamData: "",
      crossbeamDashboardData: "",
      newCampaignCount: 0,
      campaginInvitedPopup: true,
      resourCount: [],
      sendMessageModal: false,
      isOpen: false,
      terms_accepted_at: "",
      isloading: false,
      referralRequestedCount: 0
    };

    this.handleResendVerificationMail = this.handleResendVerificationMail.bind(
      this
    );
  }

  // Assigned partners checkbox toggle
  toggleChange = () => {
    this.setState({ isChecked: !this.state.isChecked });

    if (this.state.isChecked) {
      clearCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    }

    if (!this.state.isChecked) {
      clearCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
      setCookie(COOKIE_SHOW_ASSIGNED_PARTNERS, "true");
    }
  };

  // Add class in the body tag
  bodyClass(authenticated) {
    if (!authenticated) {
      document.body.classList.remove("loggedin-layout");
    } else {
      document.body.classList.add("loggedin-layout");
    }
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this.getCrossBeamPartnerRecords();
    this.bodyClass(getCookie(COOKIE_SESSION_TOKEN));

    const { actions } = this.props;

    // Assigned partner checkbox status
    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);
    if (checked_status) {
      this.setState({ isChecked: !this.state.isChecked });
    }

    if (!checked_status) {
      this.setState({ isChecked: this.state.isChecked });
    }

    if (isExpert(this.props.roleId)) {
      this._getExpertDashboardCounts(true);
      this._getProjectUserDetails();
      if (getUrlParameter("token")) {
        this._verifyExpertEmailbyToken(getUrlParameter("token"));
      }
      this._getUserDetail();
      this._getExpertProjects();
      this._getExpertIncomingProjectList();
      this._getProjectListCount();
      this._getProjectUserList();
      this._getExpertProfileCompletion();
      this._getExpertIncomingProjectAlertList(true);
      this._getExpertActiveProjectCount();
    }

    if (isPartner(this.props.roleId)) {
      this._getCampaginsListCount();
      this._getUserDetail();
      this._getPartnerDashboardCounts(true);
      if (getUrlParameter("token")) {
        this._verifyExpertEmailbyToken(getUrlParameter("token"));
      }
    }

    if (isPartner(this.props.roleId)) {
      const profileCompleted = getCookie(COOKIE_PARTNER_PROFILE_COMPLETED);
      if (profileCompleted === "true")
        this.setState({ isProfileTasksCompleted: true });
      this._getTaskList();
      this._getSpotlightImageUrl();
      this._getRecommendedResourceList();
      this._getOpenDealList();
      this._getOpenReferralList();
      this._getPartnerTierList();
    }

    if (isCustomer(this.props.roleId)) {
      this._getCustomerDashboardCounts();
      this._getProjectDeliverableCustomerList();
      this._getMatchingProjectList();
    }
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
    this.bodyClass(getCookie(COOKIE_SESSION_TOKEN));
  }

  // Verify expert email by token
  _verifyExpertEmailbyToken(token) {
    apiClient
      .get(`${endpoints().userAPI}/verify/${token}`)
      .then(() => {
        this.setState(
          {
            allowAccess: true
          },
          () => {
            removeParamsByName("token");
            window.history.replaceState({}, "", `${window.location.pathname}`);
            this._getUserDetail();
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ allowAccess: false, isInvalidRequest: true });
        }
      });
  }

  // Get Expert Detail
  _getUserDetail() {
    this.setState({ isloading: true });
    apiClient.get(`${endpoints().userAPI}`).then(response => {
      if (response.data) {
        const {
          emailVerification,
          partnerId,
          terms_accepted_at
        } = response.data;

        const enablePartnerTermsAndConditions = this.props.settings
          ? getKeyValueByObject(
              this.props.settings,
              SETTING_ENABLE_PARTNER_TERMS_AND_CONDITIONS_UPDATE_NOTIFICATION
            )
          : "";

        this.setState({
          isloading: false,
          allowAccess:
            emailVerification === USER_EMAIL_VERIFICATION_REQUIRED
              ? false
              : true,
          emailVerification,
          terms_accepted_at: terms_accepted_at
        });
        partnerId &&
          emailVerification === USER_EMAIL_VERIFICATION_REQUIRED &&
          this.checkPartnerStatus();
        if (enablePartnerTermsAndConditions) {
          if (
            enablePartnerTermsAndConditions === "false" &&
            !terms_accepted_at
          ) {
            this.setState({ allowAccess: true });
          } else if (
            enablePartnerTermsAndConditions === "true" &&
            !terms_accepted_at
          ) {
            this.setState({ allowAccess: false });
          }
        } else {
          this.setState({
            isloading: false
          });
        }
      }
    });
  }

  checkPartnerStatus = () => {
    this.setState({
      enablePartner:
        this.state.marketplaceStatus === PARTNER_STATUS_APPROVED ? true : false
    });
  };

  // Get Experts Projects
  _getExpertProjects() {
    apiClient
      .get(`${endpoints().dashboardAPI}/expert/projects`)
      .then(response => {
        if (response.data) {
          const { projectsCount } = response.data;
          if (!projectsCount) {
            this.setState({
              showHowItWorks: true
            });
          }
        }
      });
  }

  // Get Recommended Partner Resource
  _getRecommendedResourceList = () => {
    apiClient
      .get(`${endpoints().resourceAPI}/search?&status=Published`)
      .then(response => {
        if (response && response.data && response.data.data) {
          const resourceList = response.data.data;
          let resourCount = [];
          let dataLength = resourceList.length;
          let length = dataLength < 5 ? dataLength : 4;
          for (var i = 0; i < length; i++) {
            resourCount.push(resourceList[i]);
          }

          const currentPartnerTier = response.data.currentPartnerTier;
          this.setState({
            resourceList: resourceList,
            currentPartnerTier: currentPartnerTier && currentPartnerTier.name,
            currentPartnerTierDetails: currentPartnerTier,
            resourCount: resourCount
          });
        }
      });
  };

  // Get Recommended Partner Resource
  _getPartnerTierList = () => {
    apiClient
      .get(
        `${
          endpoints().partnerTierAPI
        }/search?sort=percentage&sortDir=ASC&pagination=true`
      )
      .then(response => {
        if (response && response.data && response.data.data) {
          const partnerTierList = response.data.data;

          if (partnerTierList && partnerTierList.length > 0) {
            this.setState({
              partnerTierList: partnerTierList
            });
          }
        }
      });
  };

  // Get Open Deals
  _getOpenDealList = () => {
    // Number of Open Deals Showing
    const pageSize = PARTNER_DASHBOARD_OPEN_DEALS_COUNT;

    // API call
    apiClient
      .get(
        `${
          endpoints().dealsAPI
        }/open/deals?pageSize=${pageSize}&pagination=true`
      )
      .then(response => {
        if (response && response.data && response.data.data) {
          const partnerOpenDealsList = response.data.data;

          if (partnerOpenDealsList && partnerOpenDealsList.length > 0) {
            this.setState({
              partnerOpenDeals: partnerOpenDealsList,
              partnerOpenDealsCount: response.data.totalCount
            });
          }
        }
      });
  };

  // Get MyReferrals
  _getOpenReferralList = () => {
    // Number of Referrals Showing
    const pageSize = PARTNER_DASHBOARD_MY_REFERRALS_COUNT;

    // API call
    apiClient
      .get(
        `${
          endpoints().referralApi
        }/partner/referrals?pageSize=${pageSize}&pagination=true`
      )
      .then(response => {
        if (response && response.data && response.data.data) {
          const partnerReferralList = response.data.data;

          if (partnerReferralList && partnerReferralList.length > 0) {
            let referralRequested = partnerReferralList.filter(
              data => data.status == REFERRAL_STATUS_REQUESTED
            );

            this.setState({
              partnerReferrals: partnerReferralList,
              partnerReferralsCount: response.data.totalCount,
              referralRequestedCount:
                referralRequested && referralRequested.length
            });
          }
        }
      });
  };

  // Get Spotlight Image Url
  _getSpotlightImageUrl = () => {
    apiClient
      .get(
        `${
          endpoints().resourceAPI
        }/search?pageSize=1&showPartnerSpotlight=true&status=Published`
      )
      .then(response => {
        if (response && response.data && response.data.data) {
          const resourceList = response.data.data;

          if (resourceList && resourceList.length > 0) {
            this.setState({
              spotlightImageUrl:
                resourceList && resourceList[0].spotlightImagePathUrl
            });
          }
        }
      });
  };

  // Get Project User List
  _getProjectUserDetails() {
    this.setState({ isProjectExpertLoading: true }, () => {
      apiClient
        .get(`${endpoints().dashboardAPI}/expert/shortlist/project`)
        .then(response => {
          const projectUserDetails = response.data.data;
          this.setState({
            projectUserDetails
          });
        });
    });
  }

  // Get Customer Dashboard Counts
  _getCustomerDashboardCounts() {
    this.setState({ isCustomerDashboardCountLoading: true }, () => {
      apiClient
        .get(`${endpoints().dashboardAPI}/customer/count`)
        .then(response => {
          const customerDashboardCounts = response.data;
          if (customerDashboardCounts) {
            const {
              pendingProjectsCount,
              activeProjectsCount,
              completedProjectsCount
            } = customerDashboardCounts;
            this.setState({
              customerPendingProjectsCount: pendingProjectsCount,
              customerActiveProjectsCount: activeProjectsCount,
              customerCompletedProjectsCount: completedProjectsCount,
              isCustomerDashboardCountLoading: false
            });
          }
        });
    });
  }

  // Get Expert Dashboard Counts
  _getExpertDashboardCounts(isExpertDashboardCountLoading) {
    apiClient.get(`${endpoints().dashboardAPI}/expert/count`).then(response => {
      const expertDashboardCounts = response.data;
      if (expertDashboardCounts) {
        const { customerCount, completedProjectsCount } = expertDashboardCounts;

        this.setState({
          expertCustomerProjectsCount: customerCount,
          expertCompletedProjectsCount: completedProjectsCount,
          isExpertDashboardCountLoading: !isExpertDashboardCountLoading
        });
      }
    });
  }

  // Get Expert Dashboard Counts
  _getPartnerDashboardCounts(isPartnerDashboardCountLoading) {
    apiClient
      .get(`${endpoints().dashboardAPI}/partner/count`)
      .then(response => {
        const partnerDashboardCounts = response.data;
        if (partnerDashboardCounts) {
          const {
            isApprovedPartner,
            activePlaybookCount,
            playbookPurchasesCount,
            profileViewCount,
            marketplaceStatus,
            partnerPaymentAccessStatus,
            profileUpdateStatus,
            pendingProjectCount,
            dealsMarketplaceReferralCount,
            dealsRegisteredCount,
            pendingDealsCount,
            closedDealsCount,
            earnedDealAmount,
            projectedDealAmount,
            earnedReferralCommissionAmount,
            earnedReferralAmount,
            submittedReferralCount,
            closeReferralCount,
            accountPaymentCount,
            acceptedReferralCount,
            partnerId,
            partnerRoleId,
            partnerName,
            encryptedPartnerId
          } = partnerDashboardCounts;

          const profileAccepted = getCookie(COOKIE_PARTNER_PROFILE_ACCEPTED);
          this.setState({
            isApprovedPartner,
            isProfileAccepted: profileAccepted ? false : isApprovedPartner,
            partnerPaymentAccessStatus,
            playbookPurchasesCount,
            profileViewCount,
            activePlaybookCount,
            pendingProjectCount,
            partnerStatus: marketplaceStatus,
            partnerProfileUpdateStatus: profileUpdateStatus,
            dealsMarketplaceReferralCount,
            dealsRegisteredCount,
            pendingDealsCount,
            closedDealsCount,
            earnedDealAmount,
            projectedDealAmount,
            earnedReferralAmount,
            earnedReferralCommissionAmount,
            submittedReferralCount,
            closeReferralCount,
            accountPaymentCount,
            acceptedReferralCount,
            isPartnerDashboardCountLoading: !isPartnerDashboardCountLoading,
            partnerId,
            partnerRoleId,
            partnerName,
            encryptedPartnerId
          });
        }
      });
  }

  // Get expert profile completion percentage
  _getExpertProfileCompletion = () => {
    return apiClient
      .get(`${endpoints().dashboardAPI}/expert/profile/completion`)
      .then(response => {
        this.setState({
          expertProfileCompletionPercentage: response.data.percentage,
          profileUpdateStatus: response.data.profileUpdateStatus,
          expertPaymentAccessStatus: response.data.expertPaymentAccessStatus,
          expertAvailableStatus: response.data.expertAvaialableStatus,
          availability: response.data.availability,
          expertStatus: response.data.marketplaceStatus,
          isApprovedExpert: response.data.isApprovedExpert
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  toggleModal = () => {
    this.setState({
      alertModal: !this.state.alertModal
    });
  };

  setDateAndTime = (key, value) => {
    this.setState({ [key]: value });
  };

  togglePaymentOverdueModal = () => {
    this.setState({
      paymentOverdueModal: !this.state.paymentOverdueModal
    });
  };

  toggleTaskCompleteModal = () => {
    this.setState({
      taskCompleteModal: !this.state.taskCompleteModal
    });
  };

  toggleProfileCompletedModal = () => {
    this.setState({
      isProfileCompleted: !this.state.isProfileCompleted,
      isProfileTasksCompleted: !this.state.isProfileTasksCompleted
    });

    if (this.state.isProfileCompleted) {
      clearCookie(COOKIE_PARTNER_PROFILE_COMPLETED);
      setCookie(COOKIE_PARTNER_PROFILE_COMPLETED, "true", false);
    }
  };

  toggleTaskFileUploadModal = () => {
    this.setState({
      isTaskFileUploadModal: !this.state.isTaskFileUploadModal
    });
  };
  closeModal = e => {
    this.setState({ sendMessageModal: e });
  };

  setCurrentTask = task => {
    this.setState({
      currentTask: task ? task : ""
    });
  };

  // Get Partner Application
  _getTaskList = () => {
    const profileCompleted = getCookie(COOKIE_PARTNER_PROFILE_COMPLETED);

    apiClient
      .get(`${endpoints().partnerApplicationTaskAPI}/partner/dashboard`)
      .then(response => {
        if (response.data) {
          this.setState({
            partnerApplicationTaskList: response.data.partnerApplicationTasks,
            partnerProfileCompletionPercentage:
              response.data.profileCompletionPercent,
            profileTaskCompleted: response.data.profileCompleted,
            isProfileCompleted: profileCompleted
              ? false
              : response.data.profileCompletionPercent >= 100
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };
  toggleProfileAcceptedModal = () => {
    this.setState({
      isProfileAccepted: !this.state.isProfileAccepted
    });

    if (this.state.isProfileAccepted) {
      clearCookie(COOKIE_PARTNER_PROFILE_ACCEPTED);
      setCookie(COOKIE_PARTNER_PROFILE_ACCEPTED, "true", false);
    }
  };

  // Handle Email Verification Notification
  toggleEmailVerifiedNotification = () => {
    this.setState({ allowAccess: true });
  };

  //Handle terms and conditions

  toogleTermsAndCondition = () => {
    this.setState({ allowAccess: true });
  };

  // Handle Payment Overdue Notification
  handlePaymentOverdueNotification() {
    this.setState({
      hidePaymentOverdueNotification: !this.state.hidePaymentOverdueNotification
    });
  }

  // Handle Customer Project Matching Alert
  handleCustomerProjectMatchingAlert() {
    this.setState({
      hideCustomerProjectMatchingAlert: !this.state
        .hideCustomerProjectMatchingAlert
    });
  }

  handleExpertAvailabilityCheckClose() {
    this.setState({
      showExpertAvailabilityCheck: !this.state.showExpertAvailabilityCheck
    });
  }

  handleHowItWorksClose() {
    this.setState({
      hideHandleHowItWorksClose: !this.state.hideHandleHowItWorksClose
    });
  }

  // Get project list count
  _getProjectListCount() {
    this.setState({ isLoading: true }, () => {
      apiClient.get(`${endpoints().projectAPI}`).then(response => {
        const { totalCount } = response.data;

        this.setState({
          isUpdateAvailabilityBanner: totalCount > 0
        });
      });
    });
  }

  // Get project list count
  _getCampaginsListCount() {
    this.setState({ isLoading: true }, () => {
      apiClient.get(`${endpoints().projectAPI}/search`).then(response => {
        const totalCount = response.data.data.length > 0;
        this.setState({
          newCampaignCount: totalCount
        });
      });
    });
  }

  // Get Expert Incoming Project List Alert
  _getExpertIncomingProjectAlertList(isExpertIncomingProjectAlert) {
    const projectUserStatuses = `${PROJECT_USER_STATUS_NO_RESPONSE}, ${PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE}`;
    apiClient
      .get(`${endpoints().projectAPI}?projectUserStatus=${projectUserStatuses}`)
      .then(response => {
        const data = response.data;

        this.setState({
          expertIncomingProjectAlertList: !data ? [] : data.data,
          isExpertIncomingProjectAlert: !isExpertIncomingProjectAlert
        });
      });
  }

  // Get Expert Incoming Project List
  _getExpertIncomingProjectList() {
    this.setState({ isLoading: true }, () => {
      const projectUserStatuses = `${PROJECT_USER_STATUS_NO_RESPONSE}, ${PROJECT_USER_STATUS_INTERESTED}, ${PROJECT_USER_STATUS_WAITING_FOR_APPROVAL}, ${PROJECT_USER_STATUS_WAITING_FOR_ACCEPTANCE}`;
      apiClient
        .get(
          `${endpoints().projectAPI}?projectUserStatus=${projectUserStatuses}`
        )
        .then(response => {
          const data = response.data;
          const totalCount = data.totalCount;

          this.setState({
            expertIncomingProjectCount: totalCount,
            expertIncomingProjects: !data ? [] : data
          });
        });
    });
  }

  // Get Expert Incoming Project List
  _getExpertActiveProjectCount() {
    this.setState({ isLoading: true }, () => {
      const projectUserStatuses = `${PROJECT_USER_STATUS_ACCEPTED}`;
      apiClient
        .get(
          `${endpoints().projectAPI}?projectUserStatus=${projectUserStatuses}`
        )
        .then(response => {
          const { data } = response.data;

          let expertActivePojectsCount = 0;
          if (data && data.length > 0) {
            data.forEach(project => {
              if (project.status === PROJECT_STATUS_INPROCESS) {
                expertActivePojectsCount++;
              }
            });
          }
          this.setState({
            expertActivePojectsCount
          });
        });
    });
  }

  // Get project user list
  _getProjectUserList() {
    this.setState({ isLoading: true }, () => {
      const status = isExpert ? PROJECT_USER_STATUS_ACCEPTED : "";
      apiClient
        .get(`${endpoints().projectUserAPI}?status=${status}`)
        .then(response => {
          const { data } = response.data;

          this.setState({
            activeProjects: !data ? [] : data
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.log(errorMessage);
          }
        });
    });
  }

  // Handle terms and condition
  handleTermsCondition = async () => {
    let terms_accepted_at = new Date();
    let data = { terms_accepted_at: terms_accepted_at };
    await apiClient
      .put(`${endpoints().companyAPI}/updateTerms`, data)
      .then(res => {
        if (res.statusText == "OK") {
          this.setState({ isLoading: true });
          this._getUserDetail();
          this.setState({ isLoading: false });
          window.location.reload();
        }
      });
  };

  // Get recent project deliverable pending status
  _getProjectDeliverableCustomerList() {
    this.setState({ isProjectDeliverablesLoading: true }, () => {
      apiClient
        .get(`${endpoints().projectDeliverableAPI}/pending`)
        .then(response => {
          const data = response.data;

          this.setState({
            pendingProjectDeliverables: !data ? "" : data,
            taskCompleteModal: !data ? false : true
          });
        });
    });
  }

  // Share expert availability api call
  shareExpertAvailability(projectId) {
    const data = {};
    data.startDate = this.state.startDate
      ? moment(this.state.startDate).format("DD-MM-YYYY")
      : "";
    data.startTime = this.state.startTime
      ? moment(this.state.startTime).format("h:mm A")
      : "";
    data.projectId = projectId;

    return apiClient
      .post(`${endpoints().projectUserAPI}/share/availability`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          console.log(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ errorMessage, successMessage: "" });
          console.error(errorMessage);
        }
      });
  }

  //get matching project list
  _getMatchingProjectList() {
    apiClient.get(`${endpoints().projectAPI}`).then(response => {
      const { data } = response.data;
      const activeProjectList = data;
      let matchingProjectsCount = 0;
      if (activeProjectList && activeProjectList.length > 0) {
        activeProjectList.forEach(project => {
          if (
            project.status === PROJECT_STATUS_REQUESTED ||
            project.status === PROJECT_STATUS_MATCHING
          ) {
            matchingProjectsCount++;
          }
        });
      }
      this.setState({
        matchingProjectsCount
      });
    });
  }

  // Handle Resend Verification Mail
  handleResendVerificationMail() {
    this.setState({ LoadingResendVerification: true }, () => {
      apiClient
        .get(`${endpoints().userAPI}/resend/verify`)
        .then(() => {
          this.setState({
            LoadingResendVerification: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            this.setState({ errorMessage, successMessage: "" });
            console.error(errorMessage);
          }
        });
    });
  }

  // Handle Manager Dashboard Change
  handleManagerDashboardChange = value => {
    const selectedManagerDashboard = value;
    this.setState({
      selectedManagerDashboard: selectedManagerDashboard
    });
  };

  getPartnerTierProgressDetails = (amount, tierList) => {
    const quotaAmount = amount > 0 ? amount : 0;
    let completedTier = "",
      nextTier = "",
      nextTierCompletedPercent = "",
      nextTierQuota = 0;
    if (tierList && tierList.length > 0) {
      tierList.forEach(tier => {
        if (tier.quota_amount <= quotaAmount) {
          completedTier = tier.name;
        }

        if (quotaAmount >= nextTierQuota) {
          nextTier = tier.name;
          nextTierCompletedPercent =
            ((quotaAmount - nextTierQuota) /
              (tier.quota_amount - nextTierQuota)) *
            100;
          return (nextTierQuota = tier.quota_amount);
        }
      });
    }
    nextTierCompletedPercent =
      nextTierCompletedPercent > 0 ? nextTierCompletedPercent : 0;
    return {
      completedTierText: completedTier
        ? `You are currently at ${completedTier} tier`
        : `You are currently at ${nextTier} tier`,
      preNextTierText: completedTier ? " and are" : "",
      nextTierText: completedTier
        ? nextTier
          ? ` to achieving ${nextTier} Tier`
          : ""
        : "to meeting quota",
      nextTierCompletedPercent:
        nextTierCompletedPercent > 0
          ? Math.round(nextTierCompletedPercent) + "%"
          : "0%",
      nextTierQuota
    };
  };

  getCrossBeamPartnerRecords = () => {
    let crossbeamDashboardData = [];
    apiClient
      .get(
        `${
          endpoints().crossbeamPartnerAPI
        }/crossbeamPartnerRecords?sort=total_overlap_count&sortDir=DESC&type=Partner`
      )
      .then(data => {
        if (data.data.partnerData) {
          let accountCount = 0;
          for (let i = 0; i < data.data.partnerData.length; i++) {
            if (accountCount < 4) {
              crossbeamDashboardData.push(data.data.partnerData[i]);
              accountCount = accountCount + 1;
            }
          }
          this.setState({
            crossBeamData: data.data,
            crossbeamDashboardData: crossbeamDashboardData
          });
        }
      });
  };

  crossbeamPartnerStatusUpdate = (crossbeamAccountId, snoozeDate) => {
    apiClient
      .post(`${endpoints().crossbeamPartnerAPI}/status/update`, {
        crossbeamAccountId: crossbeamAccountId,
        snoozeDate: snoozeDate
      })
      .then(data => {
        if (data.status === 200) {
          toast.success(data.data.message);
          this.getCrossBeamPartnerRecords();
        }
      });
  };

  model = () => {
    this.setState({ sendMessageModal: true });
  };

  messageModel = e => (
    <button
      type="button"
      className="btn font-weight-bold outline-bold text-white p-1 plain-btn border-white"
      onClick={this.model}
    >
      Message Our Partner Team
    </button>
  );

  render() {
    const { partnerSettings, settings } = this.props;
    const {
      projectUserDetails,
      expertAvailableStatus,
      expertStatus,
      partnerStatus,
      hideHandleHowItWorksClose,
      showExpertAvailabilityCheck,
      hidePaymentOverdueNotification,
      customerPendingProjectsCount,
      customerActiveProjectsCount,
      customerCompletedProjectsCount,
      isUpdateAvailabilityBanner,
      activeProjects,
      pendingProjectDeliverables,
      expertIncomingProjects,
      expertIncomingProjectCount,
      expertIncomingProjectAlertList,
      isApprovedExpert,
      showHowItWorks,
      allowAccess,
      LoadingResendVerification,
      expertCustomerProjectsCount,
      expertCompletedProjectsCount,
      isCustomerDashboardCountLoading,
      hideCustomerProjectMatchingAlert,
      matchingProjectsCount,
      expertActivePojectsCount,
      activePlaybookCount,
      playbookPurchasesCount,
      profileViewCount,
      pendingProjectCount,
      dealsMarketplaceReferralCount,
      dealsRegisteredCount,
      pendingDealsCount,
      closedDealsCount,
      earnedDealAmount,
      projectedDealAmount,
      earnedReferralCommissionAmount,
      earnedReferralAmount,
      submittedReferralCount,
      closeReferralCount,
      accountPaymentCount,
      acceptedReferralCount,
      partnerProfileCompletionPercentage,
      partnerProfileUpdateStatus,
      partnerPaymentAccessStatus,
      spotlightImageUrl,
      resourceList,
      partnerOpenDeals,
      partnerOpenDealsCount,
      partnerReferrals,
      partnerReferralsCount,
      selectedManagerDashboard,
      currentPartnerTier,
      isProfileCompleted,
      isProfileAccepted,
      profileTaskCompleted,
      isProfileTasksCompleted,
      currentPartnerTierDetails,
      partnerTierList,
      campaginInvitedPopup,
      newCampaignCount,
      resourCount,
      sendMessageModal,
      terms_accepted_at
    } = this.state;

    let partnerTierProgressDetails = {};

    if (isPartner(this.props.roleId)) {
      partnerTierProgressDetails = this.getPartnerTierProgressDetails(
        earnedReferralCommissionAmount,
        partnerTierList
      );
    }

    const termsAndCondition = settings
      ? getKeyValueByObject(settings, SETTING_PORTAL_TERMS_AND_CONDITIONS_LINK)
      : "";

    const enableDeals = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_DEALS)
      : "";

    const enableReferrals = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_REFERRALS)
      : "";

    const enablePartners = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNERS)
      : "";
    const enablePartnerTermsAndConditions = settings
      ? getKeyValueByObject(
          settings,
          SETTING_ENABLE_PARTNER_TERMS_AND_CONDITIONS_UPDATE_NOTIFICATION
        )
      : "";

    const enableProjects = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PROJECTS)
      : "";

    const showAccountMapping = settings
      ? getKeyValueByObject(settings, SETTINGS_DASHBOARD_SHOW_ACCOUNT_MAPPING)
      : "";
    const enablePartnerPayments = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNER_PAYMENTS)
      : "";

    const enableCampaigns = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_CAMPAIGNS)
      : "";

    const partnerTypeSettingEnableCampaign = partnerSettings
      ? getKeyValueByObject(
          partnerSettings,
          PARTNER_TYPE_SETTING_ENABLE_CO_MARKETING_CAMPAIGNS
        )
      : "";

    const managerDashboardOptions =
      enablePartners === "true" && enableProjects === "true"
        ? [
            {
              value: PARTNER_MANAGER_DASHBOARD,
              label: PARTNER_MANAGER_DASHBOARD
            },
            {
              value: SERVICE_DELIVERY_DASHBOARD,
              label: SERVICE_DELIVERY_DASHBOARD
            }
          ]
        : enablePartners === "true"
        ? [
            {
              value: PARTNER_MANAGER_DASHBOARD,
              label: PARTNER_MANAGER_DASHBOARD
            }
          ]
        : enableProjects === "true"
        ? [
            {
              value: SERVICE_DELIVERY_DASHBOARD,
              label: SERVICE_DELIVERY_DASHBOARD
            }
          ]
        : [];

    const availability = this.state.availability;
    const availableText =
      availability === EXPERT_TYPE_10_40_HOURS_TOTAL
        ? "You’re Available 10-40 hours total"
        : availability === EXPERT_TYPE_10_20_HOURS_WEEK
        ? "You’re Available 10 to 20 Hours Per Week"
        : availability === EXPERT_TYPE_20_30_HOURS_WEEK
        ? "You’re Available 20 to 30 Hours Per Week"
        : availability === EXPERT_TYPE_30_PLUS_HOURS_WEEK
        ? "You’re Available More Than 30 Hours Per Week"
        : availability === EXPERT_TYPE_NOT_CURRENTLY_AVAILABLE
        ? "You’re Not Currently Available. (Expect to be Available Later)"
        : "You’re Not Available";
    const notAvailableText = "You’re Not Available";
    const expertAvailableText =
      this.state.expertAvailableStatus === "Completed"
        ? availableText
        : notAvailableText;

    if (isCustomerDashboardCountLoading && isCustomer(this.props.roleId)) {
      return <Spinner />;
    }

    const resendEmailVerificationNotificationButton = () => {
      return (
        <button
          type="button"
          className="btn font-weight-bold outline-bold text-white p-0 plain-btn border-white"
          onClick={this.handleResendVerificationMail}
        >
          {LoadingResendVerification ? "Sending..." : "Resend Verification"}
        </button>
      );
    };

    return (
      <>
        <MyContext.Consumer>
          {context => (
            <>
              {context.isMobile && (
                <PageTitle label="Dashboard" showHamburgermenu="false" />
              )}
            </>
          )}
        </MyContext.Consumer>

        {/* KickOff Meeting Notifications */}
        {(isExpert(this.props.roleId) ||
          isCustomer(this.props.roleId) ||
          isPartner(this.props.roleId)) && <KickOffMeetingList />}

        {/* Email not verified banner */}
        {this.state.emailVerification === USER_EMAIL_VERIFICATION_REQUIRED && (
          <AlertWarning
            messageTitle="Email Not Verified."
            message="Please verify your email from the confirmation email we sent you."
            onClick={() => this.toggleEmailVerifiedNotification}
            button={resendEmailVerificationNotificationButton()}
          />
        )}

        {/* Terms and conditions Banner */}
        {enablePartnerTermsAndConditions &&
          enablePartnerTermsAndConditions == "true" && (
            <>
              {isPartner(this.props.roleId) && !terms_accepted_at && (
                <AlertBanner
                  icon={<OctagonAlertIcon />}
                  content={
                    <div>
                      Before you can proceed you must read and accept the
                      updated{" "}
                      <Link
                        target="_blank"
                        class="text-white fw-600 text-underline"
                        to={termsAndCondition}
                      >
                        Terms and Conditions
                      </Link>
                      .
                    </div>
                  }
                  hideCrossIcon={true}
                  isloading={this.state.isloading}
                  terms_accepted_at={terms_accepted_at}
                  termsAndCondition={termsAndCondition}
                  buttonOnClick={this.handleTermsCondition}
                  buttonLabel="I Agree"
                />
              )}
            </>
          )}

        {isPartner(this.props.roleId) && (
          <div className="mb-2">
            <PageTitle label="Partner Dashboard" />
          </div>
        )}

        {/*Profile not Approved banner */}
        {isPartner(this.props.roleId) &&
          this.state.partnerStatus === PARTNER_STATUS_PENDING && (
            <AlertWarning
              message="Your Partner Profile is not yet approved."
              button={this.messageModel()}
              onClick={this.model}
              hideCrossIcon={true}
            />
          )}

        <div className="d-none">
          {isExpert(this.props.roleId) &&
          isUpdateAvailabilityBanner &&
          showExpertAvailabilityCheck &&
          activeProjects &&
          activeProjects.length > 0
            ? activeProjects.map((projectDetails, index) => (
                <ExpertAvailabilityCheck
                  projectDetails={projectDetails}
                  modal={this.state.alertModal}
                  setModal={this.toggleModal}
                  startDate={this.state.startDate}
                  startTime={this.state.startTime}
                  setDateAndTime={this.setDateAndTime}
                  setValue={this.setValue}
                  index={index}
                  shareExpertAvailability={e => this.shareExpertAvailability(e)}
                  handleClose={() => this.handleExpertAvailabilityCheckClose()}
                />
              ))
            : ""}
        </div>

        {/*Show the incoming project alert for expert in project status "No Response" and "Waiting for Acceptance"*/}
        {isExpert(this.props.roleId) && expertIncomingProjectAlertList && (
          <>
            {expertIncomingProjectAlertList.map(project => (
              <ExpertInterestCheck projectDetails={project} />
            ))}
          </>
        )}

        <div className="d-none">
          {isCustomer(this.props.roleId) && !hidePaymentOverdueNotification && (
            <CustomerAlerts
              handlePaymentOverdueNotification={() =>
                this.handlePaymentOverdueNotification()
              }
            />
          )}
        </div>

        {isCustomer(this.props.roleId) &&
          matchingProjectsCount > 0 &&
          !hideCustomerProjectMatchingAlert && (
            <CustomerProjectMatchingAlert
              handleCustomerProjectMatchingAlert={() =>
                this.handleCustomerProjectMatchingAlert()
              }
            />
          )}

        {/*Show Dashboard Count For Super Admin*/}
        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) &&
          (enablePartners === "true" || enableProjects === "true") && (
            <>
              {managerDashboardOptions.length > 1 ? (
                <>
                  <div className="d-flex row flex-wrap">
                    <div className="col">
                      <PageTitle
                        label={selectedManagerDashboard}
                        showHamburgermenu="false"
                      />
                    </div>
                    <SelectDropdown
                      buttonLabel={selectedManagerDashboard}
                      dropdownLinks={managerDashboardOptions}
                      color={"gray"}
                      hideCaret
                      selectName={"manager_dashboard"}
                      handleChange={this.handleManagerDashboardChange}
                    />
                  </div>
                </>
              ) : (
                <div className="row pl-3">
                  <PageTitle
                    label={selectedManagerDashboard}
                    showHamburgermenu="false"
                  />
                </div>
              )}

              <div className="row d-flex justify-content-between pl-3">
                <SubTitle label="Top Stats" showHamburgermenu="false" />
                {isCompanyManager(this.props.roleId) && (
                  <div className="pr-4">
                    <Formik>
                      <SingleCheckbox
                        assignedPartnerValue={this.state.isChecked}
                        name={"assignedPartnersCheckbox"}
                        label="Show My Assigned Partners Only"
                        className="assign-partner-checkbox mt-2"
                        assignedPartnersCheckbox="assignedPartnersCheckbox"
                        assignedPartnerChange={this.toggleChange}
                      />
                    </Formik>
                  </div>
                )}
              </div>
              <AdminStatisticsCards
                settings={this.props.settings}
                enablePartnerPayments={enablePartnerPayments}
                selectedManagerDashboard={
                  managerDashboardOptions.length === 1
                    ? managerDashboardOptions[0].value
                    : selectedManagerDashboard
                }
                submittedReferralCount={submittedReferralCount}
              />
            </>
          )}

        {isPartner(this.props.roleId) && enablePartnerPayments === "true" && (
          <>
            {/* Show Payout preference popup */}
            <PayoutPreferencePopup
              closeReferralCount={closeReferralCount}
              accountPaymentCount={accountPaymentCount}
              partnerId={this.state.partnerId}
              partnerRoleId={this.state.partnerRoleId}
            />
            {/* Show Dashboard Banner Partner */}
            <PartnerDashboardBanner
              enable={allowAccess}
              spotlightImageUrl={spotlightImageUrl}
              settings={this.props.settings}
            />
          </>
        )}

        {/* Show Dashboard Count For Partner */}
        {isPartner(this.props.roleId) && (
          <PartnerStatisticsCards
            enable={allowAccess}
            partnerStatus={partnerStatus}
            activePlaybookCount={activePlaybookCount}
            playbookPurchasesCount={playbookPurchasesCount}
            profileViewCount={profileViewCount}
            pendingProjectCount={pendingProjectCount}
            dealsMarketplaceReferralCount={dealsMarketplaceReferralCount}
            dealsRegisteredCount={dealsRegisteredCount}
            pendingDealsCount={pendingDealsCount}
            earnedDealAmount={earnedDealAmount}
            projectedDealAmount={projectedDealAmount}
            closedDealsCount={closedDealsCount}
            closeReferralCount={closeReferralCount}
            earnedReferralCommissionAmount={earnedReferralCommissionAmount}
            earnedReferralAmount={earnedReferralAmount}
            submittedReferralCount={submittedReferralCount}
            acceptedReferralCount={acceptedReferralCount}
            settings={this.props.settings}
          />
        )}

        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) && (
          <>
            <AdminNeedsAttention
              settings={this.props.settings}
              pendingDealsCount={pendingDealsCount}
            />
            {/* Row 1 */}
            <div className="list-view-card">
              <TopPartners settings={this.props.settings} />
              <TopPartnersDealClosed settings={this.props.settings} />
              <RecentPartnerUserActivities settings={this.props.settings} />
            </div>
          </>
        )}

        {/* Show Dashboard Count For Customer */}
        {isCustomer(this.props.roleId) && (
          <CustomersStatisticsCards
            activeProjectsCount={customerActiveProjectsCount}
            pendingProjectsCount={customerPendingProjectsCount}
            completedProjectsCount={customerCompletedProjectsCount}
            balanceDue={0}
          />
        )}

        {/* Show Dashboard Count For Expert */}
        {isExpert(this.props.roleId) && (
          <ExpertStatisticsCards
            enable={allowAccess}
            customerCount={expertCustomerProjectsCount}
            CompletedProject={expertCompletedProjectsCount}
          />
        )}

        {/* Show Active Projects */}
        {isCustomer(this.props.roleId) && <ProjectList enable={allowAccess} />}

        {isCustomer(this.props.roleId) &&
          !hideHandleHowItWorksClose &&
          customerActiveProjectsCount === 0 &&
          customerCompletedProjectsCount === 0 && (
            <CustomerHowItWorks
              handleClose={() => this.handleHowItWorksClose()}
            />
          )}

        {isCustomer(this.props.roleId) && (
          <PaymentOverdueModal
            modal={this.state.paymentOverdueModal}
            setModal={this.togglePaymentOverdueModal}
          />
        )}

        {/* TODO: @SANTHOSH TASK/DELIVERABLE COMPLETED MODAL BELOW. */}
        {isCustomer(this.props.roleId) && pendingProjectDeliverables && (
          <TaskCompletedModal
            projectDeliverables={pendingProjectDeliverables}
            modal={this.state.taskCompleteModal}
            setModal={this.toggleTaskCompleteModal}
          />
        )}

        {/*TODO: @SANTHOSH MAKE SURE TO READ THE COMMENTS BELOW*/}
        {isExpert(this.props.roleId) &&
          this.state.expertProfileCompletionPercentage < 100 && (
            <>
              {/* Illustration of expert onboarding: https://projects.invisionapp.com/d/main#/console/18592948/397058628/inspect*/}
              <ExpertOnboarding
                enable={allowAccess}
                percentage={this.state.expertProfileCompletionPercentage}
                profileUpdateStatus={this.state.profileUpdateStatus}
                expertPaymentAccessStatus={this.state.expertPaymentAccessStatus}
                expertAvailableStatus={expertAvailableStatus}
                expertAvailableText={expertAvailableText}
                expertStatus={expertStatus}
              />
            </>
          )}

        {isPartner(this.props.roleId) && isProfileAccepted && (
          <ProfileAcceptedModal
            modal={isProfileAccepted}
            setModal={this.toggleProfileAcceptedModal}
          />
        )}

        {/*TODO: @SANTHOSH MAKE SURE TO READ THE COMMENTS BELOW*/}
        {isPartner(this.props.roleId) &&
          partnerProfileCompletionPercentage &&
          !getCookie(COOKIE_PARTNER_PROFILE_COMPLETED) && (
            <ProfileCompletedModal
              modal={isProfileCompleted}
              setModal={this.toggleProfileCompletedModal}
            />
          )}
        {/* Illustration of expert onboarding: https://projects.invisionapp.com/d/main#/console/18592948/397058628/inspect*/}

        {isPartner(this.props.roleId) && partnerProfileCompletionPercentage && (
          <PartnerOnboarding
            partnerSettings={partnerSettings}
            enable={allowAccess}
            getTaskList={this._getTaskList}
            percentage={partnerProfileCompletionPercentage}
            profileUpdateStatus={partnerProfileUpdateStatus}
            partnerPaymentAccessStatus={partnerPaymentAccessStatus}
            partnerStatus={partnerStatus}
            settings={this.props.settings}
            encryptedPartnerId={this.state.encryptedPartnerId}
            history={this.props.history}
            taskList={this.state.partnerApplicationTaskList}
            profileTaskCompleted={profileTaskCompleted}
            setCurrentTask={this.setCurrentTask}
            setTaskFileUploadModal={this.toggleTaskFileUploadModal}
            isProfileTasksCompleted={isProfileTasksCompleted}
            openMessageModel={sendMessageModal}
            closeModal={e => this.closeModal(e)}
            referralRequestedCount={this.state.referralRequestedCount}
          />
        )}

        {isExpert(this.props.roleId) && !isApprovedExpert && (
          <>
            {/*Illustration of expert onboarding: https://projects.invisionapp.com/d/main#/console/18592948/404068938/inspect */}
            <ExpertOnboardingAlmostDone
              enable={allowAccess}
              percentage={this.state.expertProfileCompletionPercentage}
              expertAvailableStatus={expertAvailableStatus}
              expertAvailableText={expertAvailableText}
              showHowItWorks={showHowItWorks}
            />
          </>
        )}

        {isExpert(this.props.roleId) &&
          isApprovedExpert &&
          !expertIncomingProjectCount && (
            <>
              {/*Illustration of expert onboarding: https://projects.invisionapp.com/d/main#/console/18592948/404068973/inspect */}
              {/* https://trello.com/c/NQ6g1Uvz TODO : @SANTHOSH*/}
              <ExpertAccepted
                enable={allowAccess}
                percentage={this.state.expertProfileCompletionPercentage}
                expertAvailableStatus={expertAvailableStatus}
                expertAvailableText={expertAvailableText}
              />
            </>
          )}

        {isExpert(this.props.roleId) &&
          projectUserDetails &&
          (projectUserDetails.length === 1 &&
          projectUserDetails[0].status === PROJECT_USER_STATUS_NO_RESPONSE ? (
            <>
              {/* https://trello.com/c/UMmsMa23 TODO: @Santhosh */}
              <ExpertMatched
                expertAvailableStatus={expertAvailableStatus}
                expertAvailableText={expertAvailableText}
              />
            </>
          ) : (
            ""
          ))}

        <div className="d-none">
          {isExpert(this.props.roleId) && (
            <>
              {/* Illustration of expert onboarding: https://projects.invisionapp.com/d/main#/console/18592948/404069039/inspect */}
              <ExpertStatus
                expertAvailableStatus={expertAvailableStatus}
                expertAvailableText={expertAvailableText}
                percentage={"100"}
              />
            </>
          )}

          {isExpert(this.props.roleId) && (
            <>
              {/* Illustration of expert onboarding: https://projects.invisionapp.com/d/main#/console/18592948/404069039/inspect */}
              <ExpertProStatus />
            </>
          )}

          {isExpert(this.props.roleId) && (
            <>
              {/* Illustration of expert onboarding: https://projects.invisionapp.com/d/main#/console/18592948/404069039/inspect */}
              <ExpertEliteStatus />
            </>
          )}
        </div>

        {isExpert(this.props.roleId) && expertActivePojectsCount < 1 && (
          <IncomingProjects
            enable={allowAccess}
            expertIncomingProjects={expertIncomingProjects}
          />
        )}

        {isPartner(this.props.roleId) && enableCampaigns === "true" && (
          <ProjectList
            projectDetailsUrlBasePath={PROJECT_TYPE_CAMPAIGN_URL_PATH}
            title={`Requested ${Terminology.get(
              SystemName.COMARKETING_CAMPAIGN
            )}`}
            projectUserStatuses={PROJECT_USER_STATUS_NO_RESPONSE}
            isCampaign={enableCampaigns === "true"}
            enable={allowAccess}
          />
        )}

        {isPartner(this.props.roleId) && enableReferrals === "true" && (
          <div
            className={[
              "row expert-onboarding",
              `${partnerStatus !== PARTNER_STATUS_APPROVED ? "disabled" : ""}`
            ].join(" ")}
          >
            <div className={"col-md-12"}>
              <StyledReferralSubmitCard
                partnerSettings={partnerSettings}
                enable={allowAccess}
                label={`Submit a ${Terminology.get(SystemName.REFERRAL)}`}
                subTitle={`Submit a ${Terminology.get(SystemName.REFERRAL)}`}
                subText={`Submitting a ${Terminology.get(
                  SystemName.REFERRAL
                )} is quick and easy. Submit your ${Terminology.get(
                  SystemName.REFERRAL
                )} and then track the progress`}
                redirectUrl="/referral/add"
                encryptedPartnerId={this.state.encryptedPartnerId}
                partnerReferrals={partnerReferrals}
                partnerReferralsCount={partnerReferralsCount}
              />
            </div>
          </div>
        )}

        {isPartner(this.props.roleId) && enableDeals === "true" && (
          <div
            className={[
              "row expert-onboarding",
              `${partnerStatus !== PARTNER_STATUS_APPROVED ? "disabled" : ""}`
            ].join(" ")}
          >
            <div className={"col-md-12"}>
              <StyledDealRegistrationCard
                enable={allowAccess}
                label="Deal Registration"
                subTitle="Register a New Deal"
                subText="Registering an opportunity is quick and easy. Submit your deal and then track the progress."
                redirectUrl="/deal/add"
                partnerId={this.state.partnerId}
                partnerName={this.state.partnerName}
                partnerOpenDeals={partnerOpenDeals}
                partnerOpenDealsCount={partnerOpenDealsCount}
                encryptedPartnerId={this.state.encryptedPartnerId}
              />
            </div>
          </div>
        )}

        {/* {isPartner() && partnerTierList && partnerTierList.length > 1 && (
          <div
            className={[
              "row expert-onboarding",
              `${partnerStatus !== PARTNER_STATUS_APPROVED ? "disabled" : ""}`,
            ].join(" ")}
          >
            <PartnerTierProgress
              enable={allowAccess}
              currentPartnerTierDetails={currentPartnerTierDetails}
              partnerTierProgressDetails={partnerTierProgressDetails}
              earnedReferralCommissionAmount={earnedReferralCommissionAmount}
              partnerTierList={partnerTierList}
            />
          </div>
        )} */}

        {isPartner(this.props.roleId) && (
          <div className="pb-2">
            <RecommendedResourcesList
              partnerSettings={partnerSettings}
              enable={allowAccess}
              partnerStatus={partnerStatus}
              data={resourceList}
              resourCount={resourCount}
              currentPartnerTier={currentPartnerTier}
              partnerName={this.state.partnerName}
              history={this.props.history}
            />
          </div>
        )}

        {isExpert(this.props.roleId) && (
          <ProjectList
            title={`${
              isPartner(this.props.roleId)
                ? "Pending Projects"
                : "Active Projects"
            }`}
            enable={allowAccess}
          />
        )}
        {(isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId) ||
          isPartner(this.props.roleId)) &&
          showAccountMapping === "true" &&
          this.state.crossBeamData &&
          this.state.crossBeamData.partnerData.length > 0 && (
            <div className="pb-5">
              <AccountMappingInsights
                partnerStatus={partnerStatus}
                enable={allowAccess}
                crossBeamData={this.state.crossBeamData}
                crossBeamPartnerRecords={this.state.crossBeamData}
                crossBeamDataDashboard={this.state.crossbeamDashboardData}
                crossbeamPartnerStatusUpdate={this.crossbeamPartnerStatusUpdate}
                getCrossBeamPartnerRecords={this.getCrossBeamPartnerRecords}
                history={this.props.history}
              />
            </div>
          )}

        {isPartner(this.props.roleId) &&
          newCampaignCount > 0 &&
          partnerTypeSettingEnableCampaign === "true" && (
            <div className="pb-5">
              <CampaignPopup isOpen={campaginInvitedPopup} />
            </div>
          )}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchProjectList }, dispatch)
  };
};

const mapStateToProps = state => {
  let roleId = state ? state.user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(Dashboard);
