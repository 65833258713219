import React from "react";

// Assets
import { ArrowRight } from "../../assets/img/icons";
import Select from "../../components/base/Select";
import TextArea from "../../components/base/TextArea";
import { TAG_TYPE_EXPERT_ROLE } from "../../tagType/Constants";
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";

class GetStartedMinimal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      expert_types: []
    };
  }

  componentDidMount() {
    this._getTagsByTagType(TAG_TYPE_EXPERT_ROLE);
  }

  //Get expert type
  _getTagsByTagType = tagType => {
    return apiClient
      .get(`${endpoints().playbookAPI}/tags/list?tagType=${tagType}`)
      .then(response => {
        const tags = response.data.data;
        let expert_types = [];

        if (tags.length > 0) {
          tags.forEach(tag => {
            expert_types.push({
              label: tag.name,
              value: tag.id
            });
          });

          this.setState({
            expert_types
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  render() {
    const { currentStep, step, next, marketplaceTalentName } = this.props;
    const { expert_types } = this.state;
    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <h2 className="font-weight-bold">
            Didn’t find what you’re looking for?
          </h2>
        </div>

        {/* Content */}
        <div className="content font-weight-light">
          <div className="field-wrapper flex-column">
            <Select
              name="expertType"
              label="What type of Expert are you looking for?"
              placeholder="Select one"
              options={expert_types}
              fullWidth={true}
              isSearchable={true}
              required
            />
          </div>

          <div className="field-wrapper">
            <TextArea
              name="description"
              label={`Tell us a bit about what you could use some help with so we can match you with the right ${marketplaceTalentName}.`}
              placeholder="Tell us more..."
              rows="5"
              error="Description is required"
              required
            />
          </div>
        </div>
        <div className="btn-wrapper">
          <button
            className="btn btn-primary d-flex align-items-center"
            onClick={next}
          >
            Continue <ArrowRight />
          </button>
        </div>
        {/* /.btn-wrapper */}
      </div>
    );
  }
}

export default GetStartedMinimal;
