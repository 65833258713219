import React, { useState, useEffect } from "react";

import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
// Components
import Form from "../../components/base/Form";

import Text from "../../components/Text";

import Select from "../../components/base/Select";

import PartnerType from "../../components/PartnerType";

import { getParamsByName } from "../../lib/helper";

import MultiselectCreatable from "../../components/base/MultiselectCreatable";

const EditMappingModal = props => {
  const [selectedHubspotField, setSelectedHubspotField] = useState("");
  const [selectedHubSpotFieldDetail, setSelectedHubSpotFieldDetail] = useState(
    ""
  );

  const {
    selectedRowObject,
    closeEditModal,
    hubspotObjectNameList,
    handleChange,
    customFieldList,
    openEditModal,
    setOpenEditModal,
    update,
    selectedHubspotObjectName,
    AccountObjectList,
    ReferralObjectList,
    accountFieldList,
    picklist,
    selectedObjectName,
    handleObjectNameChange,
    syncDirection,
    HubSpotFieldList,
    contactFieldList,
    contactObjectList
  } = props;

  useEffect(() => {
    getHubSpotFieldNameDetail();
  }, [selectedRowObject]);

  useEffect(() => {
    setSelectedHubspotField(picklist);
  }, [picklist]);

  const getHubSpotFieldNameDetail = selectedHubspotField => {
    let selectedHubSpotFieldDetail =
      selectedRowObject.hubspotObject === "Deal"
        ? DealObjectFieldList
          ? DealObjectFieldList.find(
              data => data.label === selectedRowObject.hubspotFieldName
            )
          : []
        : CompanyObjectFieldList
        ? CompanyObjectFieldList.find(
            data => data.label === selectedRowObject.hubspotFieldName
          )
        : [];

    setSelectedHubSpotFieldDetail(selectedHubSpotFieldDetail);
  };

  const hubspotFieldChanges = async e => {
    setSelectedHubspotField(e && e.values && e.values.hubspotFieldName);
    setSelectedHubSpotFieldDetail(e && e.values && e.values.hubspotFieldName);
  };

  let CompanyObjectFieldList =
    HubSpotFieldList && HubSpotFieldList.companyObjectFields
      ? HubSpotFieldList.companyObjectFields
      : [];

  let DealObjectFieldList =
    HubSpotFieldList && HubSpotFieldList.dealObjectFields
      ? HubSpotFieldList.dealObjectFields
      : [];

  let ContactObjectFieldList =
    HubSpotFieldList && HubSpotFieldList.contactObjectFields
      ? HubSpotFieldList.contactObjectFields
      : [];

  const searchItem = getParamsByName("search") || "";

  return (
    <div>
      <Modal
        isOpen={openEditModal}
        toggle={() => {
          setOpenEditModal(false);
          setSelectedHubspotField("");
          setSelectedHubSpotFieldDetail("");
        }}
        className="edit-task-modal"
        backdrop="static"
      >
        <ModalHeader
          toggle={() => {
            setOpenEditModal(false);
            setSelectedHubspotField(picklist);
            setSelectedHubspotField("");
            setSelectedHubSpotFieldDetail("");
          }}
        >
          <p className="text-center font-weight-bold mb-3">
            Edit HubSpot Field Mapping
          </p>
        </ModalHeader>

        <Form
          initialValues={{
            objectName: selectedRowObject.objectName
              ? {
                  label: selectedRowObject.objectName,
                  value: selectedRowObject.objectName
                }
              : "",
            hubspotObjectName: selectedRowObject.hubspotObject
              ? {
                  label: selectedRowObject.hubspotObject,
                  value: selectedRowObject.hubspotObject
                }
              : "",
            fieldName: selectedRowObject.fieldName
              ? {
                  label: selectedRowObject.fieldName,
                  value: selectedRowObject.fieldName
                }
              : "",
            hubspotFieldName: selectedRowObject.hubspotFieldName
              ? {
                  label: selectedRowObject.hubspotFieldName,
                  value: selectedHubspotField && selectedHubspotField.name
                }
              : "",
            ObjectDefaultValue: {
              label: selectedRowObject && selectedRowObject.defaultValue,
              value: selectedRowObject && selectedRowObject.defaultValue
            },
            TextDefaultValue: selectedRowObject.defaultValue,
            partnerType: selectedRowObject.partnerType,
            syncDirection: syncDirection().find(
              ({ value }) => value === selectedRowObject.syncDirection
            )
          }}
          onSubmit={values => {
            values.objectName =
              values.objectName && values.objectName.value
                ? values.objectName.value
                : "";
            values.fieldName =
              values.fieldName && values.fieldName.value
                ? values.fieldName.value
                : "";
            values.hubspotObjectName = values.hubspotObjectName.value;
            values.defaultValue =
              selectedHubSpotFieldDetail &&
              selectedHubSpotFieldDetail.type === "enumeration"
                ? values &&
                  values.ObjectDefaultValue &&
                  values.ObjectDefaultValue.value
                : values && values.TextDefaultValue;
            values.syncDirection =
              values && values.syncDirection ? values.syncDirection.value : 0;
            values.partnerType =
              values && values.partnerType ? values.partnerType.value : null;
            update.actions.updateHubspotFieldMapping(
              selectedRowObject.id,
              values,
              { search: searchItem }
            );
            closeEditModal(false);
          }}
        >
          <ModalBody className="custom-modal-body">
            <div className="mt-2 mb-3">
              <h6>
                Hubspot Object <span className="text-danger">*</span>
              </h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="hubspotObjectName"
                  placeholder="Select Hubspot Object Name"
                  options={hubspotObjectNameList()}
                  onInputChange={e => handleChange(e)}
                  required
                  isClearable={true}
                />
              </div>

              <h6>
                Hubspot Fields <span className="text-danger">*</span>
              </h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="hubspotFieldName"
                  placeholder="Select Hubspot Field Name"
                  onInputChange={e => hubspotFieldChanges(e)}
                  options={
                    selectedHubspotObjectName === "Company (Partner)" ||
                    selectedHubspotObjectName === "Company (Prospect)"
                      ? CompanyObjectFieldList
                      : selectedHubspotObjectName === "Deal"
                      ? DealObjectFieldList
                      : selectedHubspotObjectName === "Contact (Partner)" ||
                        selectedHubspotObjectName === "Contact (Prospect)"
                      ? ContactObjectFieldList
                      : []
                  }
                  required
                  isClearable={true}
                  isSearchable={true}
                />
              </div>

              <h6>Object</h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="objectName"
                  placeholder="Select Object Name"
                  onInputChange={e => handleObjectNameChange(e)}
                  options={
                    selectedHubspotObjectName === "Company (Partner)" ||
                    selectedHubspotObjectName === "Company (Prospect)"
                      ? AccountObjectList()
                      : selectedHubspotObjectName === "Deal"
                      ? ReferralObjectList()
                      : selectedHubspotObjectName === "Contact (Partner)" ||
                        selectedHubspotObjectName === "Contact (Prospect)"
                      ? contactObjectList()
                      : []
                  }
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
              <h6>Field</h6>
              <div className="field-wrapper mb-3">
                <Select
                  name="fieldName"
                  placeholder="Select Field Name"
                  options={
                    selectedObjectName === "Account (Partner)" ||
                    selectedObjectName === "Account (Prospect)"
                      ? accountFieldList()
                      : selectedObjectName === "Referral"
                      ? customFieldList
                      : selectedRowObject.objectName === "Account (Partner)" ||
                        selectedRowObject.objectName === "Account (Prospect)"
                      ? accountFieldList()
                      : selectedRowObject.objectName === "Referral"
                      ? customFieldList
                      : selectedHubspotObjectName === "Contact (Partner)" ||
                        selectedHubspotObjectName === "Contact (Prospect)"
                      ? contactFieldList()
                      : []
                  }
                  isClearable={true}
                  isSearchable={true}
                />
              </div>
              {selectedHubSpotFieldDetail &&
              selectedHubSpotFieldDetail.type === "enumeration" ? (
                <>
                  {" "}
                  <h6>Default Value</h6>
                  <div className="field-wrapper mb-3">
                    <MultiselectCreatable
                      isSingleSelect={true}
                      name="ObjectDefaultValue"
                      placeholder="Select Default Value"
                      options={
                        selectedHubSpotFieldDetail &&
                        selectedHubSpotFieldDetail.picklistValues
                      }
                      isSearchable={true}
                    />
                  </div>
                </>
              ) : (
                <div>
                  <Text
                    name="TextDefaultValue"
                    label="Default Value"
                    placeholder="Enter Default Value"
                  />
                </div>
              )}
              <div className="field-wrapper">
                <PartnerType
                  name="partnerType"
                  label="Partner Type"
                  placeholder="Select Partner type"
                  isSearchable={true}
                  isClearable={true}
                />
              </div>
              <div className="field-wrapper mb-5">
                <Select
                  name="syncDirection"
                  label="Sync Direction"
                  placeholder="Select Sync Direction"
                  options={syncDirection()}
                  isClearable={true}
                  isSearchable={true}
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="" className="h6-5-important">
                  Update
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );
};

export default EditMappingModal;
