import React from "react";
import classnames from "classnames";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";

// Asset
import { ChevronRight } from "../../../../assets/img/icons";

// Components
import Form from "../../../../components/base/Form";
import CancelButton from "../../../../components/base/CancelButton";
import SaveButton from "../../../../components/base/SaveButton";
import toast from "../../../../components/base/Toast";
import CompanyFormFields from "./inner-component/CompanyFormFields";
import CompanyTeam from "./CompanyTeam";
import LicensesAndFeatures from "./LicensesAndFeatures";
import Systems from "./Systems";
import SelectDropdown from "../../../../components/base/SelectDropdown";

// Configs
import { endpoints } from "../../../../configs";

// API call

import { apiClient } from "../../../../apiClient";

// Helper
import { isSuperAdmin, setCookie, clearCookie } from "../../../../lib/helper";
import {
  COMPANY_GENERAL_SECTION,
  COMPANY_LICENSES_AND_FEATURES_SECTION,
  COMPANY_TEAM_SECTION,
  TAB_COMPANIES,
  COMPANY_SYSTEMS_SECTION,
  TAB_SETTINGS,
  THEME,
  EMAIL
} from "../../../../supportPortal/Constants";

import {
  COOKIE_SESSION_TOKEN,
  COOKIE_USER_ID,
  clearAllCookies
} from "../../../../lib/cookie";
// Constants
import {
  COMPANY_STATUS_APPROVED,
  COMPANY_STATUS_DECLINED
} from "../../../../Company/Constants";

import DeleteModal from "../../../../components/base/DeleteModal.js";
import { isBadRequest } from "../../../../common/http";
import { connect } from "react-redux";

import Settings from "../../company/settings";
import Spinner from "../../../../components/base/Spinner";
import ThemeTab from "../ThemeTab.js/ThemeTab.js";
import companyService from "../../../../services/companyService.js";
import EmailTab from "../EmailTab/EmailTab.js";
import SettingModel from "./inner-component/SettingModel.js";
class CompanyDetails extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      companyDetails: [],
      loading: true,
      teamUsers: [],
      isSettingsLoading: true,
      adminSettings: {},
      deleteCompanyModal: false,
      companyList: [],
      settingModal: false
    };
  }

  componentDidMount = () => {
    if (this.props.companyId && this.props.activeTab === TAB_COMPANIES) {
      this._getCompanyUsers();
      this._getCompanyDetails();
      this.getSettingsData();
      this._getCompanyList();
    }
  };

  // Company update API Call
  _updateCompany(data) {
    return apiClient
      .put(`${endpoints().companyAPI}/${this.props.companyId}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this._getCompanyDetails();

        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  // Company delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().companyAPI}/${this.props.companyId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this._getCompanyDetails();
          toast.success(successMessage);

          this.props.history.push(`/support-portal/${TAB_COMPANIES}`);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Company details api call by id
  _getCompanyDetails = () => {
    return apiClient
      .get(`${endpoints().companyAPI}/${this.props.companyId}`)
      .then(response => {
        this.setState({ companyDetails: response.data, loading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  _getCompanyList = async () => {
    try {
      let response = await companyService.list();

      this.setState({ companyList: response });
    } catch (err) {
      console.log(err);
    }
  };
  // Login By Admin
  loginByAdmin = email => {
    return apiClient
      .post(`${endpoints().userAPI}/loginByAdmin/${email}`)
      .then(response => {
        if (response && response.data) {
          const { token, role, userId } = response.data;

          clearAllCookies();

          clearCookie("googleAuthToken");
          clearCookie("office365AuthToken");
          clearCookie("linkedinAuthToken");
          clearCookie("salesforceAuthToken");

          setCookie(COOKIE_SESSION_TOKEN, token);
          setCookie(COOKIE_USER_ID, userId);

          this.props.history.push("/dashboard");

          window.location.reload();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
  // Get users by company id
  _getCompanyUsers = () => {
    let id = this.props.companyId;
    return apiClient
      .get(`${endpoints().companyAPI}/user/list/${id}`)
      .then(response => {
        const data = response.data;
        this.setState({
          teamUsers: data
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get users by company id

  // Get Settings Data
  getSettingsData = () => {
    this.setState({ isSettingsLoading: true }, () => {
      return apiClient
        .get(`${endpoints().settingAPI}/company/${this.props.companyId}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);
          const settings = response.data.data;
          const adminSettings = {};
          if (settings) {
            settings.forEach(data => {
              const name = data.name;
              const value = data.value;
              adminSettings[name] = value;
            });
            this.setState({ isSettingsLoading: false, adminSettings });
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
              this.setState({ isInvalidRequest: true });
            }
            console.error(errorMessage);
          }
        });
    });
  };

  // Settings Save
  _saveSettings = data => {
    return apiClient
      .put(`${endpoints().settingAPI}/company/${this.props.companyId}`, data)
      .then(() => {
        toast.success("Settings saved successfully");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
  handleAdminAction = value => {
    const { id, email } = this.state.companyDetails;
    switch (value) {
      case "Login As":
        return this.loginByAdmin(email);
      case "Delete":
        return this.setState({ deleteCompanyModal: "true" });
      case "Approve Company":
        return this.props.updateStatus(COMPANY_STATUS_APPROVED, id);
      case "Decline Company":
        return this.props.updateStatus(COMPANY_STATUS_DECLINED, id);
      case "Load Default Settings":
        return this.setState({ settingModal: "true" });

      default:
        return "";
    }
  };
  render() {
    const {
      companyId,
      activeTab,
      activeSubTab,
      activeSection,
      history,
      match,
      roleId
    } = this.props;

    const { adminSettings, loading, isSettingsLoading } = this.state;

    const { deleteCompanyModal, settingModal } = this.state;

    if (loading || isSettingsLoading) {
      return <Spinner />;
    }
    const {
      companyName,
      email,
      masterServiceAgreementStatus,
      status,
      isDefaultCompany
    } = this.state.companyDetails;
    const adminActionOptions =
      status === COMPANY_STATUS_APPROVED
        ? [
            {
              value: "Login As",
              label: "Login As"
            },
            {
              value: "Decline Company",
              label: "Decline Company"
            },

            {
              value: "Delete",
              label: "Delete"
            }
          ]
        : status === COMPANY_STATUS_DECLINED
        ? [
            {
              value: "Login As",
              label: "Login As"
            },
            {
              value: "Approve Company",
              label: "Approve Company"
            },

            {
              value: "Delete",
              label: "Delete"
            }
          ]
        : [
            {
              value: "Login As",
              label: "Login As"
            },
            {
              value: "Approve Company",
              label: "Approve Company"
            },

            {
              value: "Decline Company",
              label: "Decline Company"
            },
            {
              value: "Delete",
              label: "Delete"
            }
          ];

    if (!isDefaultCompany) {
      adminActionOptions.splice(2, 0, {
        value: "Load Default Settings",
        label: "Load Default Settings"
      });
    }
    const initialValues = {
      companyName,
      email,
      masterServiceAgreementStatus:
        masterServiceAgreementStatus && masterServiceAgreementStatus !== "null"
          ? {
              label: masterServiceAgreementStatus,
              value: masterServiceAgreementStatus
            }
          : "",

      isDefaultCompany,
      portal_url:
        this.state.companyDetails && this.state.companyDetails.portalUrl,
      portal_api_url:
        this.state.companyDetails && this.state.companyDetails.portalApiUrl
    };

    return (
      <>
        <DeleteModal
          isOpen={deleteCompanyModal}
          toggle={() => {
            this.setState({ deleteCompanyModal: false });
          }}
          title="Delete Company"
          label={companyName}
          deleteFunction={this.handleDelete}
        />
        <SettingModel
          isOpen={settingModal}
          toggle={() => {
            this.setState({ settingModal: false });
          }}
          title="Select Setting"
          companyId={this.props.companyId}
          history={history}
        />
        {/* Breadcrumb Start */}
        <div className="d-flex align-items-center pt-4 pb-3">
          <span
            className="cursor-pointer"
            onClick={() => {
              history.push(`/support-portal/${TAB_COMPANIES}`);
            }}
          >
            Support Portal
          </span>
          <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
            <ChevronRight />
          </span>
          <span
            className={
              activeSubTab ? "cursor-pointer" : "text-dark font-weight-bold "
            }
            onClick={() => {
              if (activeSubTab) history.push(`/support-portal/${activeTab}`);
            }}
          >
            <span>{activeTab}</span>
          </span>
          <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
            <ChevronRight />
          </span>
          <span
            className={
              activeSection ? "cursor-pointer" : "text-dark font-weight-bold "
            }
            onClick={() => {
              history.push(
                `/support-portal/${TAB_COMPANIES}/${activeSubTab}/${COMPANY_GENERAL_SECTION}`
              );
            }}
          >
            <span>{companyName}</span>
          </span>

          <span className="d-inline-flex pt-1" style={{ width: "15px" }}>
            <ChevronRight />
          </span>

          <span
            className={`${
              activeSection ? "" : "text-dark font-weight-bold"
            } d-inline-flex pt-1 `}
            onClick={() => {
              history.push(
                `/support-portal/${TAB_COMPANIES}/${activeSubTab}/${activeSection}`
              );
            }}
          >
            <span>{activeSection}</span>
          </span>
        </div>

        {/* Breadcrumb End */}
        <div className="d-flex">
          <div>
            <h5 className="mt-3 mb-4 text-left font-weight-bold">
              {companyName}
            </h5>
          </div>
          <div className="ml-auto mt-2">
            {isSuperAdmin(roleId) && (
              <div className="d-flex align-items-center">
                <SelectDropdown
                  buttonLabel="Admin Actions"
                  dropdownLinks={adminActionOptions}
                  color={"gray"}
                  hideCaret
                  selectName={"admin_action"}
                  handleChange={this.handleAdminAction}
                />
              </div>
            )}
          </div>
        </div>

        <Nav tabs className="admin-tabs">
          {/* Details TAB */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSection === COMPANY_GENERAL_SECTION
              })}
              onClick={() => {
                history.push(
                  `/support-portal/${TAB_COMPANIES}/${activeSubTab}/${COMPANY_GENERAL_SECTION}`
                );
              }}
            >
              {COMPANY_GENERAL_SECTION}
            </NavLink>
          </NavItem>

          {/* Team Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSection === COMPANY_TEAM_SECTION
              })}
              onClick={() => {
                history.push(
                  `/support-portal/${TAB_COMPANIES}/${activeSubTab}/${COMPANY_TEAM_SECTION}`
                );
              }}
            >
              {COMPANY_TEAM_SECTION}
            </NavLink>
          </NavItem>

          {/* Licenses and Features Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSection === COMPANY_LICENSES_AND_FEATURES_SECTION
              })}
              onClick={() => {
                history.push(
                  `/support-portal/${TAB_COMPANIES}/${activeSubTab}/${COMPANY_LICENSES_AND_FEATURES_SECTION}`
                );
              }}
            >
              Licenses and Features
            </NavLink>
          </NavItem>
          {/* // Systems tab*/}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSection === COMPANY_SYSTEMS_SECTION
              })}
              onClick={() => {
                history.push(
                  `/support-portal/${TAB_COMPANIES}/${activeSubTab}/${COMPANY_SYSTEMS_SECTION}`
                );
              }}
            >
              {COMPANY_SYSTEMS_SECTION}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSection === TAB_SETTINGS
              })}
              onClick={() => {
                history.push(
                  `/support-portal/${TAB_COMPANIES}/${activeSubTab}/${TAB_SETTINGS}`
                );
              }}
            >
              {TAB_SETTINGS}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSection === THEME
              })}
              onClick={() => {
                history.push(
                  `/support-portal/${TAB_COMPANIES}/${activeSubTab}/${THEME}`
                );
              }}
            >
              {THEME}
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSection === EMAIL
              })}
              onClick={() => {
                history.push(
                  `/support-portal/${TAB_COMPANIES}/${activeSubTab}/${EMAIL}`
                );
              }}
            >
              {EMAIL}
            </NavLink>
          </NavItem>
        </Nav>

        <TabContent activeTab={activeSection}>
          <TabPane tabId={COMPANY_GENERAL_SECTION}>
            <Form
              initialValues={initialValues}
              enableReinitialize
              onSubmit={values => {
                values.msaStatus = values.masterServiceAgreementStatus
                  ? values.masterServiceAgreementStatus.value
                  : values.masterServiceAgreementStatus;

                this._updateCompany(values);
              }}
            >
              <CompanyFormFields
                companyDetails={this.state.companyDetails}
                companyList={this.state.companyList}
              />

              <div className="mb-4">
                <div className="float-right">
                  <CancelButton
                    onClick={() =>
                      this.props.history.push(
                        `/support-portal/${TAB_COMPANIES}`
                      )
                    }
                  />
                  <SaveButton />
                </div>
              </div>
            </Form>
          </TabPane>

          <TabPane tabId={COMPANY_TEAM_SECTION}>
            <CompanyTeam
              companyId={companyId}
              activeTab={activeTab}
              activeSubTab={activeSubTab}
              activeSection={activeSection}
              history={history}
              match={match}
              settings={this.props.settings}
              members={this.state.teamUsers}
              getCompanyUsers={this._getCompanyUsers}
            />
          </TabPane>
          <TabPane tabId={COMPANY_LICENSES_AND_FEATURES_SECTION}>
            <LicensesAndFeatures
              companyId={companyId}
              adminSettings={adminSettings}
              saveSettings={this._saveSettings}
              activeTab={activeTab}
              activeSubTab={activeSubTab}
              activeSection={activeSection}
              history={history}
              match={match}
              settings={this.props.settings}
            />
          </TabPane>
          <TabPane tabId={COMPANY_SYSTEMS_SECTION}>
            <Systems
              companyId={companyId}
              adminSettings={adminSettings}
              saveSettings={this._saveSettings}
              activeTab={activeTab}
              activeSubTab={activeSubTab}
              activeSection={activeSection}
              history={history}
              match={match}
              settings={this.props.settings}
            />
          </TabPane>

          <TabPane tabId={TAB_SETTINGS}>
            <Settings
              companyId={companyId}
              adminSettings={adminSettings}
              saveSettings={this._saveSettings}
              settings={this.props.settings}
            />
          </TabPane>

          <TabPane tabId={THEME} className="w-100">
            <ThemeTab
              history={history}
              companyId={companyId}
              settings={adminSettings}
            />
          </TabPane>
          <TabPane tabId={EMAIL} className="w-100">
            <EmailTab
              history={history}
              companyId={companyId}
              settings={adminSettings}
            />
          </TabPane>
        </TabContent>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(CompanyDetails);
