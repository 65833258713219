import React from "react";

// Components
import Email from "../../components/base/Email";
import Text from "../../components/base/Text";
import TextArea from "../../components/base/TextArea";
import Select from "../../components/base/Select";
import SecondaryButton from "../../components/base/SecondaryButton";

// Assets
import { ArrowRight, InfoIcon } from "../../assets/img/icons";

class ContactInformation extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      required: false
    };
  }
  render() {
    const { currentStep, step, prev, next, minimal } = this.props;

    if (currentStep !== step) {
      // Prop: The current step
      return null;
    }
    const { required } = this.state;

    // Roles options
    const referenceOptions = [
      {
        value: "Salesforce Salesperson/Customer Success",
        label: "Salesforce Salesperson/Customer Success"
      },
      {
        value: "Salesforce Agency Partner",
        label: "Salesforce Agency Partner"
      },
      {
        value: "Torchlite sales/marketing",
        label: "Torchlite sales/marketing"
      },
      {
        value: "Internal referral",
        label: "Internal referral"
      },
      {
        value: "Personal referral",
        label: "Personal referral"
      }
    ];

    return (
      <div className="content-wrapper">
        <div className="title-wrapper d-flex align-items-center">
          <InfoIcon />{" "}
          <h2 className="h1-5 font-weight-bold mb-0 ml-4">
            Contact Information
          </h2>
        </div>
        {minimal && (
          <div className="subtitle-wrapper mt-4">
            <h5 className="font-weight-light text-inline-grayed">
              Once you fill out the form, your Expert project request will be
              submitted to the Torchlite Resourcing Team.
            </h5>
          </div>
        )}
        <div className="content">
          <div className="field-wrapper">
            <Text
              name="firstName"
              label="First Name"
              placeholder="Enter first name"
              error=""
              {...{ required }}
            />
          </div>

          <div className="field-wrapper">
            <Text
              name="lastName"
              label="Last Name"
              placeholder="Enter last name"
              error=""
              {...{ required }}
            />
          </div>

          <div className="field-wrapper">
            <Email
              name="email"
              label="What email address can we reach you at?"
              placeholder="Enter email"
              error=""
              validationMessage="Email address"
              {...{ required }}
            />
          </div>

          {!minimal && (
            <>
              <div className="divider" />
              <div className="field-wrapper">
                <Select
                  name="reference"
                  label="Where did you hear about Torchlite?"
                  options={referenceOptions}
                  isSearchable={true}
                />
              </div>

              <div className="field-wrapper">
                <TextArea
                  name="additionalDetails"
                  label="Are there any additional details you want to share?"
                  rows="5"
                />
              </div>
            </>
          )}

          <div className="btn-wrapper d-flex align-items-center justify-content-start">
            <SecondaryButton className="mr-4" label="Go Back" onClick={prev} />

            <button
              className="btn btn-primary d-flex align-items-center"
              onClick={() => {
                this.setState({ required: true });
                return next;
              }}
            >
              {minimal ? (
                <>
                  Submit
                  <ArrowRight />
                </>
              ) : (
                "Next: Get Matched With Experts"
              )}
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default ContactInformation;
