/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState, useEffect } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import toast from "../../components/base/Toast";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchList } from "../../actions/table";
import DateInput from "../base/Date";

// Component
import Text from "../../components/base/Text";
import Form from "../../components/base/Form";

//API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";
import PageTitle from "../base/PageTitle";

// Customer Create API Call
const AddActivityModal = props => {
  useEffect(() => {
    getActivityList(props.partnerId);
  }, []);

  async function activityMarketplaceCreate(data, toggle) {
    try {
      const response = await apiClient.post(endpoints().activity, data);
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
      }
      toast.success(successMessage);
      setModal(props.closeEditModal);

      setTimeout(() => {
        getActivityList(data.partnerId);
        toggle();
      }, 300);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
      }
    }
  }

  const { partnerId } = props;
  const { getActivityList } = props;
  const [modal, setModal] = useState(false);
  const [startDate, setStartDate] = useState("");
  const toggle = () => {
    setModal(props.closeEditModal);
    setStartDate("");
  };

  const setDate = date => {
    setStartDate(date);
  };

  return (
    <>
      <PageTitle
        label="Activity History"
        buttonHandler={() => {
          setModal(true);
        }}
        buttonLabel="Add a New Activity"
      />
      <Modal
        isOpen={modal}
        toggle={toggle}
        backdrop="static"
        className={["edit-task-modal"].join(" ")}
      >
        <Form
          initialValues={{
            activityNotes: "",
            startDate: ""
          }}
          onSubmit={values => {
            values.startDate = startDate;
            values.activityType = "event";
            values.partnerId = partnerId;
            activityMarketplaceCreate(values, toggle);
          }}
        >
          <ModalHeader toggle={toggle}>
            <h4 className={["font-weight-bold"].join(" ")}>Add Activity</h4>
          </ModalHeader>
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="form-wrapper">
              <div>
                <Text
                  name="activityNotes"
                  label="Activity"
                  placeholder="Enter Activity"
                  required
                  error=""
                />
              </div>
            </div>
            <div className="grouped-fields mt-4">
              <div className="">
                <label>
                  Activity Date <span className="text-danger">*</span>
                </label>
                <br></br>
                <DateInput
                  selected={startDate}
                  name="startDate"
                  className="datepicker"
                  placeholder="MM-DD-YYYY"
                  required
                  minDate={new Date()}
                  onChange={setDate}
                  format="MM-dd-yyyy"
                  // clearIcon={false}
                  width={"150px"}
                  // isClearable={true}
                  value={startDate}
                  // error="Date is required"
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <Button label="" type="submit" className="btn btn-primary">
                Add
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(() => {
  return {};
}, mapDispatchToProps)(AddActivityModal);
