import React, { useEffect, useState } from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// API call
import { apiClient } from "../../../../apiClient";

// Constants
import { endpoints } from "../../../../configs";

// Constants
import {
  PARTNER_ONBOARDING_SUB_TAB,
  PARTNER_APPLICATIONS_TASKS_SUB_TAB,
  PARTNER_APPLICATIONS_FORMS_SUB_TAB,
  TAB_PARTNER_ONBOARDING
} from "../../../../setting/Constants";

import PartnerApplicationsForm from "./PartnerApplicationsForm";
import { isBadRequest } from "../../../../common/http";
import TaskListPage from "../../../../modules/task/TaskListPage";
import Signup from "./Signup";

const PartnerApplicationsTab = props => {
  const {
    activeSubTab,
    activeSection,
    adminSettings,
    saveSettings,
    toggleTabs,
    history
  } = props;

  const [isLoading, setIsLoading] = useState(true);
  const [formList, setFormList] = useState([]);

  //get form list
  const getFormList = () => {
    apiClient
      .get(`${endpoints().partnerApplicationFormAPI}`)
      .then(response => {
        if (
          response.data &&
          response.data.data &&
          response.data.data.length > 0
        ) {
          const formSelectOptions = [];

          response.data.data.forEach(formDetails => {
            formSelectOptions.push({
              value: formDetails.id,
              label: formDetails.name
            });
          });

          setFormList(formSelectOptions);
          setIsLoading(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          setIsLoading(false);
        }
      });
  };

  useEffect(() => {
    if (isLoading) {
      getFormList();
    }
  }, [isLoading]);
  const partnerApplicationFormId = activeSection;

  return (
    <>
      {!partnerApplicationFormId && (
        <Nav tabs className="admin-tabs">
          {/* Signup Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === PARTNER_ONBOARDING_SUB_TAB
              })}
              onClick={() => {
                toggleTabs(TAB_PARTNER_ONBOARDING, PARTNER_ONBOARDING_SUB_TAB);
              }}
            >
              {PARTNER_ONBOARDING_SUB_TAB}
            </NavLink>
          </NavItem>
          {/* Email Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === PARTNER_APPLICATIONS_TASKS_SUB_TAB
              })}
              onClick={() => {
                toggleTabs(
                  TAB_PARTNER_ONBOARDING,
                  PARTNER_APPLICATIONS_TASKS_SUB_TAB
                );
              }}
            >
              {PARTNER_APPLICATIONS_TASKS_SUB_TAB}
            </NavLink>
          </NavItem>

          {/* Slack Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active: activeSubTab === PARTNER_APPLICATIONS_FORMS_SUB_TAB
              })}
              onClick={() => {
                toggleTabs(
                  TAB_PARTNER_ONBOARDING,
                  PARTNER_APPLICATIONS_FORMS_SUB_TAB
                );
              }}
            >
              {PARTNER_APPLICATIONS_FORMS_SUB_TAB}
            </NavLink>
          </NavItem>
        </Nav>
      )}

      <TabContent activeTab={activeSubTab}>
        {/* Tasks Tab */}
        <TabPane tabId={PARTNER_ONBOARDING_SUB_TAB} className="w-100">
          <Signup
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
            formList={formList}
          />
        </TabPane>

        {/* Tasks Tab */}
        <TabPane tabId={PARTNER_APPLICATIONS_TASKS_SUB_TAB} className="w-100">
          <TaskListPage
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
            formList={formList}
          />
        </TabPane>

        {/* Fields Tab */}
        <TabPane tabId={PARTNER_APPLICATIONS_FORMS_SUB_TAB} className="w-100">
          <PartnerApplicationsForm
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
            match={props.match}
            getFormList={getFormList}
            formList={formList}
          />
        </TabPane>

        {/* Fields Tab */}
        {/* <TabPane tabId={PARTNER_APPLICATIONS_FIELDS_SUB_TAB} className="w-100">
          <PartnerApplicationsField
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane> */}
      </TabContent>
    </>
  );
};

export default PartnerApplicationsTab;
