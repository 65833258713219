import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";
import { isLoggedIn } from "../../lib/helper";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";

export default class DiscussionList extends Component {
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    return (
      <div>
        <div>
          <ReduxTable id="discussion" apiURL={`${endpoints().discussionAPI}`}>
            <ReduxColumn field="description">Description</ReduxColumn>
            <ReduxColumn field="createdAt">Created At</ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}
