import React, { Component } from "react";
import DatePicker from "react-datepicker";
import _ from "lodash";

// Configs
import { endpoints } from "../../../../configs";

// Action
import { fetchList } from "../../../../actions/table";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import PageTitle from "../../../../components/base/PageTitle";
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";

// 404 Page
import Page404 from "../../../Page404";

import PageSearch from "../../../../components/base/PageSearch";

import { Link } from "react-router-dom";

import { REFERRAL_LINK_CLICKED } from "../../../../reports/Constants";

import { REFERRAL_SUBMITTED_FROM_LINK_TRACKING } from "../../../../referral/Constants";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

class PartnerLinkTrackingStatistics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isPageNotFound: false,
      startDate: "",
      endDate: "",
      status: "",
      searchTerm: ""
    };
  }

  /**
   * Change search term
   *
   * @param {*} event
   */
  onChange(event) {
    event.persist();
    const value = event.target.value;
    this.setState({ searchTerm: decodeURI(value) });
    this.doSearch(event);
  }

  doSearch = _.debounce(event => {
    this.setState(
      {
        isSearch: true,
        searchTerm: encodeURIComponent(event.target.value),
        page: 1
      },
      () => {
        this.fetchData();
        this.updateUrl();
      }
    );
  }, 500);

  //Hanlde Start Date Function
  handleStartDate = startDate => {
    this.setState({ startDate: startDate ? startDate : "" }, () => {
      this.fetchData();
      this.updateUrl();
    });
  };

  //Hanlde End Date Function
  handleEndDate = endDate => {
    this.setState({ endDate: endDate ? endDate : "" }, () => {
      this.fetchData();
      this.updateUrl();
    });
  };

  //Fetch API Data
  fetchData = () => {
    const { startDate, endDate, searchTerm } = this.state;
    const params = [];
    if (searchTerm) {
      params.push(`search=${searchTerm}`);
    }
    if (startDate) {
      params.push(`startDate=${startDate.toLocaleDateString("en-US")}`);
    }
    if (endDate) {
      params.push(`endDate=${endDate.toLocaleDateString("en-US")}`);
    }

    this.props.actions.fetchList(
      "linkTrackingStatistics",
      `${endpoints().activity}/linkTracking/statisticsReport`,
      1,
      10,
      {
        startDate,
        endDate,
        search: searchTerm,
        pagination: true,
        linkTracking: true
      }
    );
  };

  //Update URL query
  updateUrl() {
    const { searchTerm, startDate, endDate } = this.state;
    const params = [];

    if (searchTerm) {
      params.push(`search=${searchTerm}`);
    } else {
      params.push(`search=${""}`);
    }
    if (startDate) {
      params.push(`startDate=${startDate.toLocaleDateString("en-US")}`);
    }
    if (endDate) {
      params.push(`endDate=${endDate.toLocaleDateString("en-US")}`);
    }
    if (params.length > 0) {
      this.props.history.push(
        `/reports/referral-link-tracking-report/?${params.join("&")}`
      );
    }
  }

  render() {
    const { searchTerm } = this.state;

    // Page Not Found
    if (this.state.isPageNotFound) {
      return <Page404 />;
    }

    return (
      <div>
        {/* Page Title */}
        <PageTitle
          label={`Closed Won ${Terminology.get(
            SystemName.REFERRAL
          )} Link Tracking Report`}
        />
        <div>
          <div className={`page-heading cover`}>
            <div className="d-flex justify-content-between">
              <div>
                <PageSearch
                  classnames="page-search"
                  placeholder="Search Partner Name"
                  onChange={this.onChange.bind(this)}
                  width="250px"
                />
              </div>
              <div className="datepicker-wrapper py-1 mr-3">
                {/* Start Date */}
                <DatePicker
                  name="startDate"
                  className="datepicker"
                  placeholderText="Start Date"
                  onChange={this.handleStartDate}
                  selected={this.state.startDate}
                  clearIcon={null}
                />
              </div>
              <div className="datepicker-wrapper py-1 mr-3">
                {/* End Date */}
                <DatePicker
                  name="endDate"
                  className="datepicker"
                  placeholderText="End Date"
                  onChange={this.handleEndDate}
                  selected={this.state.endDate}
                  clearAriaLabel
                  clearIcon={null}
                  width={"150px"}
                />
              </div>
            </div>
          </div>
          <ReduxTable
            startDate={this.state.startDate}
            endDate={this.state.endDate}
            id="linkTrackingStatistics"
            apiURL={`${endpoints().activity}/linkTracking/statisticsReport`}
            newTableHeading
            params={{
              search: searchTerm ? searchTerm : ""
            }}
            paramsToUrl={true}
            history={this.props.history}
          >
            <ReduxColumn
              field="partnerName"
              sortBy="company_name"
              renderField={row => {
                return (
                  <Link
                    className="text-decoration-none"
                    to={`/edit/partner/public-profile?id=${row.partnerId}`}
                    style={{ cursor: "pointer" }}
                  >
                    {row.partnerName}
                  </Link>
                );
              }}
            >
              Partner
            </ReduxColumn>
            <ReduxColumn
              field="linkClickedCount"
              className="text-center"
              sortBy="referral_link_clicked_count"
              renderField={row => {
                return (
                  <Link
                    className="text-decoration-none"
                    to={`/reports/partner-activities?activityType=${REFERRAL_LINK_CLICKED}&partnerId=${row.partnerId}`}
                    style={{ cursor: "pointer" }}
                  >
                    {row.linkClickedCount}
                  </Link>
                );
              }}
            >
              Links Clicked
            </ReduxColumn>
            <ReduxColumn
              field="referralSubmittedCount"
              className="text-center"
              sortBy="link_tracking_referral_submitted"
              renderField={row => {
                return (
                  <Link
                    className="text-decoration-none"
                    to={`/referrals?partnerId=${row.partnerId}&submittedFrom=${REFERRAL_SUBMITTED_FROM_LINK_TRACKING}`}
                    style={{ cursor: "pointer" }}
                  >
                    {row.referralSubmittedCount}
                  </Link>
                );
              }}
            >
              {Terminology.get(SystemName.REFERRALS)} Submitted
            </ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(PartnerLinkTrackingStatistics);
