import React from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Actions
import { fetchList } from "../../../actions/table";

// Components
import Form from "../../../components/base/Form";
import TextArea from "../../../components/base/TextArea";
import CancelButton from "../../../components/base/CancelButton";
import SingleCheckbox from "../../../components/base/SingleCheckbox";

// Constants
import { REFERRAL_STATUS_DECLINED } from "../../../referral/Constants";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

class ReferralDeclineModal extends React.Component {
  state = { declineNotification: false };

  declineReferral = (referralId, values) => {
    const { setModal } = this.props;
    let declineMessage;
    let declineNotification;
    if (values && values.declinedMessage !== undefined) {
      declineMessage =
        values && values.declinedMessage ? values.declinedMessage : "";
    }

    if (values && values.declineNotification !== undefined) {
      declineNotification =
        values && values.declineNotification ? values.declineNotification : "";
    }
    if (referralId) {
      const value = {
        REFERRAL_STATUS_DECLINED,
        referralId,
        declineMessage,
        declineNotification
      };
      this.props.updateReferralStatus(value);
      setModal();
    }
  };

  // Handle Referral Decline Notification
  handleNotificationChange = async e => {
    this.setState({ declineNotification: e });
  };

  render() {
    const enableDeclineNotification =
      this.state &&
      this.state.declineNotification &&
      this.state.declineNotification.declineNotification;

    const { modal, setModal, referralId } = this.props;

    return (
      <>
        <Modal
          isOpen={modal}
          toggle={setModal}
          className={["edit-task-modal"].join(" ")}
        >
          <ModalHeader toggle={setModal}>
            <div
              className={["d-flex", "flex-column", "align-items-center"].join(
                " "
              )}
            >
              <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
                {`Decline ${Terminology.get(SystemName.REFERRAL)}`}
              </h4>
            </div>
          </ModalHeader>
          <Form
            initialValues={{
              declinedMessage: ""
            }}
            onSubmit={values => {
              this.declineReferral(referralId, values);
            }}
          >
            <ModalBody className={["mb-4"].join(" ")}>
              <div className="form-wrapper">
                <div className="field-wrapper">
                  <TextArea
                    label="Decline Reason"
                    name="declinedMessage"
                    placeholder="Your Message..."
                    error="Your message is required"
                    required="true"
                  />
                </div>
              </div>
              <div className="form-wrapper">
                <div className="field-wrapper">
                  <SingleCheckbox
                    name="declineNotification"
                    label="Do not send notification to partner"
                    className="accepted-terms pb-0 mr-3"
                    handleOnChangeSubmit={e => {
                      this.handleNotificationChange(e);
                    }}
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="btn-wrapper">
                <CancelButton onClick={setModal} />
                <button className="btn btn-primary" type={"submit"}>
                  {`Decline ${Terminology.get(SystemName.REFERRAL)}`}
                </button>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </>
    );
  }
}
function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

const mapStateToProps = state => {};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(ReferralDeclineModal);
