import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import Form from "../../../components/base/Form";
import Text from "../../../components/base/Text";
import Button from "../../../components/base/Button";
import CancelButton from "../../../components/base/CancelButton";

const ArchiveProjectModal = ({
  modal,
  setModal,
  updateProjectArchive,
  label
}) => {
  return (
    <>
      <Modal
        isOpen={modal}
        toggle={setModal}
        className={["edit-task-modal"].join(" ")}
        backdrop="static"
      >
        <ModalHeader toggle={setModal}>
          <div
            className={["d-flex", "flex-column", "align-items-center"].join(
              " "
            )}
          >
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>{label}</h4>
          </div>
        </ModalHeader>
        <Form
          initialValues={{
            reasonForArchive: ""
          }}
          onSubmit={values => {
            updateProjectArchive(values.reasonForArchive);
            setModal();
          }}
        >
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="form-wrapper">
              <p className="text-center">Are you sure you want to {label}?</p>

              <br />

              <div className="field-wrapper">
                <Text
                  label="Reason for archive"
                  name="reasonForArchive"
                  placeholder="Reason for archive..."
                  required={true}
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="btn-wrapper">
              <CancelButton onClick={setModal} />

              <Button type="submit" label={"Yes, Archive"} color="#F57059" />
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default ArchiveProjectModal;
