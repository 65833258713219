import React, { useRef, useState } from "react";

// Components
import SourcePartners from "../../views/referral/inner-components/SourcePartners";
import ReferralStatus from "../../views/referral/inner-components/ReferralStatus";
import SidebarItem from "../../views/referral/inner-components/SidebarItem";
import ReferralPartnerTier from "../../views/referral/inner-components/ReferralPartnerTier";
import { formatCurrency } from "../../helpers/currency";

// Helper
import {
  getUserId,
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

// Constants
import {
  REFERRAL_STATUS_CLOSED_WON,
  REFERRAL_SUBMITTED_FROM_LINK_TRACKING
} from "../Constants";
import SalesforceTasks from "./SalesforceTasks";
import { isInteger } from "../../common/utils";
import { Popover, PopoverBody } from "reactstrap";
import { CrossIcon } from "../../assets/img/icons";
import AdditionalContact from "../../views/referral/inner-components/AdditionalContact";
import AdditionalContactModal from "./Modal/SalesforceModal/AdditionalContactModal";
import EditAdditionalCotactModal from "./Modal/SalesforceModal/EditAdditionalCotactModal";
import HubSpotTask from "./HubSpotTasks";
import { useSelector } from "react-redux";
import Currency from "../../components/Currency";

const SideBar = props => {
  const {
    referralDetails,
    enableAdditionalContacts,
    isSalesforceConnected,
    isHubSpotConnected,
    hubSpotPartnerCompanyDetail,
    hubSpotProspectCompanyDetail,
    hubSpotDetail,
    openHubSpotProspectCompanyModal,
    openHubSpotPartnerCompanyModal,
    isHubSpotLoading,
    enableHubSpotSalesRouting,
    EnableDealForNewProspectCompany,
    EnableDealOwnerForExistingProspectCompany,
    openHubSpotOwnerSelectionModal,
    hubspotUserList,
    enableHubspotSyncReferral,
    selectedPartner
  } = props;
  const [paymentPopoverOpen, setPaymentPopoverOpen] = useState(false);
  const [AmountPopOverOpen, setAmountPopOverOpen] = useState(false);
  const [contactModalOpen, setContactModalOpen] = useState(false);
  const [editContactModalOpen, setEditContactModalOpen] = useState(false);
  const [editContactData, setEditContactData] = useState("");
  const [invoicePopUpOpen, setInvoicePopup] = useState(false);

  const currentUser = useSelector(state => state.user);

  const referralStatus =
    (props.referralDetails && props.referralDetails.status) ||
    props.referralStatus ||
    "";
  const referralPartnerTier =
    (props.referralDetails &&
      props.referralDetails.partner &&
      props.referralDetails.partner.partner_tier_name) ||
    (props.currentPartner &&
      props.currentPartner &&
      props.currentPartner.partnerTier &&
      props.currentPartner.partnerTier.name) ||
    "";
  const amount = props.referralDetails && props.referralDetails.amount;
  const commissionAmount =
    props.referralDetails && props.referralDetails.commission_amount;

  const togglePayment = () => {
    setPaymentPopoverOpen(!paymentPopoverOpen);
  };

  const toggleCommissionAmount = () => {
    setAmountPopOverOpen(!AmountPopOverOpen);
  };
  const toggleInvoice = () => {
    setInvoicePopup(!invoicePopUpOpen);
  };

  // contact modal toggle
  const toggleContactModal = () => {
    setContactModalOpen(!contactModalOpen);
  };

  // edit additional modal toggle
  const toggleEditContactModal = () => {
    setEditContactModalOpen(!editContactModalOpen);
  };

  const popOverRef = useRef();

  return (
    <div className="details-body-sidebar">
      {/* Source Partner */}
      <SourcePartners
        referralId={props.referralId}
        currentUserId={getUserId()}
        isReferralUpdate={props.isReferralUpdate}
        referralDetails={props.referralDetails}
        referralStatus={referralStatus}
        setReferralPartners={props.setReferralPartners}
        referralPartner={props.referralPartner}
        reassignfun={props.reassignfun}
        reAssignValue={props.reAssignValue}
        selectedPartner={selectedPartner}
      />
      {referralPartnerTier && (
        <ReferralPartnerTier
          referralPartnerTier={referralPartnerTier}
          partnerTierCommissionPercentages={
            props.partnerTierCommissionPercentage
          }
        />
      )}
      {/* Referral status  */}
      {!props.publicReferralForm && <ReferralStatus status={referralStatus} />}
      {/* Additional contacts */}
      {enableAdditionalContacts === true && (
        <AdditionalContact
          contactArray={props.contactArray}
          onClick={toggleContactModal}
          EditContactToggle={toggleEditContactModal}
          setEditContactData={setEditContactData}
        />
      )}
      {/* Additional Modal */}
      <AdditionalContactModal
        referralId={props.referralId}
        isOpen={contactModalOpen}
        toggle={toggleContactModal}
        saveContactData={props.saveContactData}
        contactArrayIndex={props.contactArrayIndex}
      />
      {/* Edit additional contact modal */}
      <EditAdditionalCotactModal
        referralId={props.referralId}
        isOpen={editContactModalOpen}
        toggle={toggleEditContactModal}
        editData={editContactData}
        saveContactData={props.saveContactData}
        updateContactData={props.updateContactData}
        deleteContactData={props.deleteContactData}
      />
      {referralDetails && referralDetails.createdAt && (
        <SidebarItem>
          <p className="font-weight-bold mb-2">Created Date</p>
          <div className="text-secondary">{referralDetails.createdAt}</div>
        </SidebarItem>
      )}
      {referralDetails && referralDetails.stage && (
        <SidebarItem>
          <p className="font-weight-bold mb-2">Stage</p>
          <div className="text-secondary">{referralDetails.stage}</div>
        </SidebarItem>
      )}
      {referralDetails && referralDetails.referralName && (
        <SidebarItem>
          <p className="font-weight-bold mb-2">Referral Name</p>
          <div className="text-secondary">{referralDetails.referralName}</div>
        </SidebarItem>
      )}
      {/* Amount */}
      {amount && (
        <SidebarItem>
          <p className="font-weight-bold mb-1">Amount</p>
          <p>
            <Currency amount={amount} currencyName={referralDetails.currency} />
          </p>
        </SidebarItem>
      )}
      {referralDetails && referralDetails.commission_amount && (
        <SidebarItem>
          <p className="font-weight-bold mb-2">Commission</p>
          <div className="d-flex">
            {referralDetails.commission_percentage}%
            <div className="mx-2">
              <Currency
                amount={referralDetails.commission_amount}
                currencyName={referralDetails.currency}
              />
            </div>
          </div>
        </SidebarItem>
      )}
      {/* Payment */}
      {(isSuperAdmin(currentUser && currentUser.roleId) ||
        isCompanyAdmin(currentUser && currentUser.roleId) ||
        isCompanyManager(currentUser && currentUser.roleId)) &&
      props.EnableReferralPaymentTracking == "true" ? (
        referralDetails &&
        referralDetails.payment &&
        referralDetails.payment.length > 0 ? (
          <>
            <SidebarItem>
              <div className="row mb-2">
                <div className="col-6">
                  <p className="font-weight-bold mb-2">Payment</p>
                </div>
                <div className="col-6">
                  {referralDetails &&
                    referralDetails.payment &&
                    referralDetails.payment.length > 2 && (
                      <>
                        <p
                          id="viewPayment"
                          className="cursor-pointer pull-right"
                        >
                          <a
                            style={{
                              color: "#3498DB",
                              textDecoration: "underline",
                              fontSize: 13
                            }}
                          >
                            View All
                          </a>
                        </p>
                        <Popover
                          placement="top"
                          isOpen={paymentPopoverOpen}
                          target="viewPayment"
                          toggle={togglePayment}
                          className="account-in-salesforce"
                        >
                          <PopoverBody>
                            <div className="p-2" ref={popOverRef}>
                              <div className="row">
                                <div className="col-md-12 d-flex bd-highlight mb-2">
                                  <div
                                    className="ml-auto cursor-pointer"
                                    onClick={() => togglePayment()}
                                  >
                                    <CrossIcon />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <SidebarItem>
                              <div className="ml-3">
                                <h6 className="font-weight-bold mb-3">
                                  Payment
                                </h6>

                                <div className="row">
                                  {referralDetails.payment.map(detail => (
                                    <>
                                      <div className="col-md-6">
                                        <p>{detail.orderDate}</p>
                                      </div>
                                      <div className="col-md-6 pull-right">
                                        {isInteger(detail.amount) && (
                                          <p>
                                            ${formatCurrency(detail.amount)}
                                          </p>
                                        )}
                                      </div>
                                    </>
                                  ))}
                                </div>
                              </div>
                            </SidebarItem>
                          </PopoverBody>
                        </Popover>
                      </>
                    )}
                </div>
              </div>

              {referralDetails.payment.slice(0, 2).map(detail => (
                <div className="row">
                  <div className="col-6">
                    <p>{detail.orderDate}</p>
                  </div>
                  <div className="col-6">
                    {isInteger(detail.amount) && (
                      <p>${formatCurrency(detail.amount)}</p>
                    )}
                  </div>
                </div>
              ))}
            </SidebarItem>
          </>
        ) : (
          <div className="sidebar-item">Payment Not Received</div>
        )
      ) : (
        ""
      )}

      {isSuperAdmin(currentUser && currentUser.roleId) ||
      isCompanyAdmin(currentUser && currentUser.roleId) ||
      isCompanyManager(currentUser && currentUser.roleId) ? (
        referralStatus === REFERRAL_STATUS_CLOSED_WON &&
        referralDetails &&
        referralDetails.invoice_list &&
        referralDetails.invoice_list.length > 0 ? (
          <SidebarItem>
            <div className="row">
              <span className="font-weight-bold mb-2 ml-3">Invoice</span>
              {referralDetails.invoice_list.length > 2 && (
                <p
                  id="viewInvoice"
                  className="cursor-pointer pull-right ml-auto"
                >
                  <a
                    className="mr-3"
                    style={{
                      color: "#3498DB",
                      textDecoration: "underline",
                      fontSize: 13
                    }}
                  >
                    View All
                  </a>
                </p>
              )}
              {referralDetails.invoice_list.length > 2 && (
                <Popover
                  placement="top"
                  isOpen={invoicePopUpOpen}
                  target="viewInvoice"
                  toggle={toggleInvoice}
                  className="account-in-salesforce"
                >
                  <PopoverBody>
                    <div className="p-2" ref={popOverRef}>
                      <div className="row">
                        <div className="col-md-12 d-flex bd-highlight mb-2">
                          <div
                            className="ml-auto cursor-pointer"
                            onClick={() => toggleInvoice()}
                          >
                            <CrossIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    <SidebarItem>
                      <div className="ml-3">
                        <h6 className="font-weight-bold mb-3">Invoice</h6>

                        {referralDetails &&
                          referralDetails.invoice_list &&
                          referralDetails.invoice_list.length > 0 &&
                          referralDetails.invoice_list.map(data => {
                            return (
                              <>
                                <div className="row">
                                  <div className="col-6">
                                    <p>{data.date}</p>
                                  </div>
                                  <div className="col-6">
                                    <Currency
                                      amount={data.amount}
                                      currencyName={referralDetails.currency}
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </SidebarItem>
                  </PopoverBody>
                </Popover>
              )}
            </div>

            {referralDetails &&
              referralDetails.invoice_list &&
              referralDetails.invoice_list.length > 0 &&
              referralDetails.invoice_list.map((data, index) => {
                return (
                  <>
                    {index <= 1 && (
                      <div className="row">
                        <div className="col-6">
                          <p>{data.date}</p>
                        </div>
                        <div className="col-6">
                          <Currency
                            amount={data.amount}
                            currencyName={referralDetails.currency}
                          />
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
          </SidebarItem>
        ) : (
          <div className="sidebar-item">Invoice Not Found</div>
        )
      ) : (
        ""
      )}
      {/* Commission Amount */}
      {referralStatus === REFERRAL_STATUS_CLOSED_WON &&
        referralDetails &&
        referralDetails.invoice_list &&
        referralDetails.invoice_list.length > 0 && (
          <SidebarItem>
            <div className="row">
              <span className="font-weight-bold mb-2 ml-3">
                Commission Amount
              </span>
              {referralDetails.invoice_list.length > 2 && (
                <p
                  id="viewCommissionAmount"
                  className="cursor-pointer pull-right ml-auto"
                >
                  <a
                    className="mr-3"
                    style={{
                      color: "#3498DB",
                      textDecoration: "underline",
                      fontSize: 13
                    }}
                  >
                    View All
                  </a>
                </p>
              )}
              {referralDetails.invoice_list.length > 2 && (
                <Popover
                  placement="top"
                  isOpen={AmountPopOverOpen}
                  target="viewCommissionAmount"
                  toggle={toggleCommissionAmount}
                  className="account-in-salesforce"
                >
                  <PopoverBody>
                    <div className="p-2" ref={popOverRef}>
                      <div className="row">
                        <div className="col-md-12 d-flex bd-highlight mb-2">
                          <div
                            className="ml-auto cursor-pointer"
                            onClick={() => toggleCommissionAmount()}
                          >
                            <CrossIcon />
                          </div>
                        </div>
                      </div>
                    </div>
                    <SidebarItem>
                      <div className="ml-3">
                        <h6 className="font-weight-bold mb-3">
                          Commission Amount
                        </h6>

                        {referralDetails &&
                          referralDetails.invoice_list &&
                          referralDetails.invoice_list.length > 0 &&
                          referralDetails.invoice_list.map(data => {
                            return (
                              <>
                                <div className="row">
                                  <div className="col-6">
                                    <Currency
                                      amount={data.amount}
                                      currencyName={referralDetails.currency}
                                    />
                                  </div>
                                </div>
                              </>
                            );
                          })}
                      </div>
                    </SidebarItem>
                  </PopoverBody>
                </Popover>
              )}
            </div>

            {referralDetails &&
              referralDetails.invoice_list &&
              referralDetails.invoice_list.length > 0 &&
              referralDetails.invoice_list.map((data, index) => {
                return (
                  <>
                    {index <= 1 && (
                      <div className="row">
                        <div className="col-6">
                          <Currency
                            amount={data.amount}
                            currencyName={referralDetails.currency}
                          />
                        </div>
                      </div>
                    )}
                  </>
                );
              })}
          </SidebarItem>
        )}
      {/* {isSuperAdmin() && props.referralId && (
        <SalesforceStatus
          referralId={props.referralId}
          status={props.referralDetails && props.referralDetails.status}
          salesforceAccountDetails={props.salesforceAccountDetails}
          isSalesforceLoading={props.isSalesforceLoading}
        />
      )} */}
      {isSalesforceConnected &&
        referralDetails &&
        referralDetails.submitted_from !=
          REFERRAL_SUBMITTED_FROM_LINK_TRACKING &&
        (isSuperAdmin(currentUser && currentUser.roleId) ||
          isCompanyAdmin(currentUser && currentUser.roleId) ||
          isCompanyManager(currentUser && currentUser.roleId)) &&
        props.referralDetails && (
          <SalesforceTasks
            isSalesforceTasksLoading={props.isSalesforceTasksLoading}
            salesforceAccountDetails={props.salesforceAccountDetails}
            openProspectAccountModal={props.openProspectAccountModal}
            openParterAccountModal={props.openParterAccountModal}
            accountId={props.accountId}
            instanceUrl={props.instanceUrl}
            salesforeTerritoryFieldName={props.salesforeTerritoryFieldName}
            referralDetails={props.referralDetails}
            openOwnerSelectionModal={props.openOwnerSelectionModal}
            salesforceUsersList={props.salesforceUsersList}
            enableSalesRouting={props.enableSalesRouting}
            enableOpportunityOwnerExistAccount={
              props.enableOpportunityOwnerExistAccount
            }
            enableOpportunityOwnerNewAccount={
              props.enableOpportunityOwnerNewAccount
            }
            linkOpportunity={props.linkOpportunity}
          />
        )}
      {isHubSpotConnected &&
        enableHubspotSyncReferral &&
        referralDetails &&
        referralDetails.submitted_from !=
          REFERRAL_SUBMITTED_FROM_LINK_TRACKING &&
        (isSuperAdmin(currentUser && currentUser.roleId) ||
          isCompanyAdmin(currentUser && currentUser.roleId) ||
          isCompanyManager(currentUser && currentUser.roleId)) &&
        props.referralDetails && (
          <HubSpotTask
            hubSpotPartnerCompanyDetail={hubSpotPartnerCompanyDetail}
            hubSpotProspectCompanyDetail={hubSpotProspectCompanyDetail}
            openHubSpotProspectCompanyModal={openHubSpotProspectCompanyModal}
            openHubSpotPartnerCompanyModal={openHubSpotPartnerCompanyModal}
            referralDetails={props.referralDetails}
            hubSpotDetail={hubSpotDetail}
            isHubSpotLoading={isHubSpotLoading}
            enableHubSpotSalesRouting={enableHubSpotSalesRouting}
            EnableDealForNewProspectCompany={EnableDealForNewProspectCompany}
            EnableDealOwnerForExistingProspectCompany={
              EnableDealOwnerForExistingProspectCompany
            }
            openHubSpotOwnerSelectionModal={openHubSpotOwnerSelectionModal}
            hubspotUserList={hubspotUserList}
          />
        )}
    </div>
  );
};

export default SideBar;
