import React, { useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { fetchList } from "../../actions/table";

// Component
import Text from "../../components/base/Text";
import Form from "../../components/base/Form";

// Configs
import { getParamsByName } from "../../lib/helper";

const AddModal = props => {
  const { appMarketplaceCreate, isModalOpen, closeEditModal } = props;
  const [modal, setModal] = useState(isModalOpen);
  const toggle = () => setModal(closeEditModal);

  const params = {
    sort: getParamsByName("sort"),
    sortDir: getParamsByName("sortDir"),
    pagination: getParamsByName("pagination"),
    search: getParamsByName("search")
  };

  return (
    <>
      <Modal
        isOpen={props.isModalOpen}
        toggle={props.closeEditModal}
        backdrop="static"
        className={["edit-task-modal"].join(" ")}
      >
        <Form
          initialValues={{
            appName: ""
          }}
          onSubmit={values => {
            appMarketplaceCreate(values, props, toggle, params);
          }}
        >
          <ModalHeader toggle={toggle}>
            <h4 className={["font-weight-bold"].join(" ")}>Add App</h4>
          </ModalHeader>
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="form-wrapper">
              <div>
                <Text
                  name="appName"
                  label="App Name"
                  placeholder="Enter App Name"
                  required
                  error=""
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper">
              <Button label="" type="submit" className="btn btn-primary">
                Add
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(() => {
  return {};
}, mapDispatchToProps)(AddModal);
