import React, { Component } from "react";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";

// Components
import Text from "../../components/base/Text";
import Select from "../../components/base/Select";
import Zipcode from "../../components/base/Zipcode";
import Phone from "../../components/base/Phone";
import Email from "../../components/base/Email";
import State from "../../components/State";
import Country from "../../components/base/Country";
import Currency from "../../components/base/Currency";
import TextArea from "../../components/base/TextArea";
import Avatar from "../../components/base/Avatar";
import DealStage from "../../components/DealStage";

// Constants
import { CANADA, UNITED_STATES } from "../../Country/Constants";
import {
  DEAL_STATUS_APPROVED,
  DEAL_STATUS_DECLINED,
  DEAL_STATUS_REQUESTED,
  DEAL_STATUS_CLOSED,
  DEAL_STATUS_DRAFT,
  DEAL_STATUS_SUBMITTED
} from "../../dealStatus/Constants";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  isCompanyManager
} from "../../lib/helper";

import { connect } from "react-redux";
import Number from "../../components/base/Number";

class DealFormFields extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: {
        ProjectDetail: false,
        AdditionalDetailTab: false
      }
    };
  }

  toggleAccordion = section => {
    this.setState(prevState => ({
      isOpen: {
        ...prevState.isOpen,
        [section]: !prevState.isOpen[section]
      }
    }));
  };
  // Render the form fields
  render() {
    const { id, partnerId, selectedCountryName } = this.props;

    const partnerIds = [];
    if (partnerId) {
      partnerIds.push(partnerId);
    }

    const statusType = [
      { value: DEAL_STATUS_APPROVED, label: DEAL_STATUS_APPROVED },
      { value: DEAL_STATUS_DECLINED, label: DEAL_STATUS_DECLINED },
      { value: DEAL_STATUS_REQUESTED, label: DEAL_STATUS_REQUESTED },
      { value: DEAL_STATUS_CLOSED, label: DEAL_STATUS_CLOSED },
      { value: DEAL_STATUS_DRAFT, label: DEAL_STATUS_DRAFT },
      { value: DEAL_STATUS_SUBMITTED, label: DEAL_STATUS_SUBMITTED }
    ];

    return (
      <div className="col-lg-12">
        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Text
              name="accountName"
              label="Account Name"
              placeholder="Account Name"
              error=""
            />
          </div>
        </div>
        <div className="field-wrapper">
          <div className="row">
            <div className="col-lg-12 mw-100">
              <Text
                name="firstName"
                label="First Name"
                placeholder="Enter First Name"
                error=""
              />
            </div>
          </div>
        </div>
        <div className="field-wrapper row">
          <div className="col-lg-12 mw-100">
            <Text
              name="lastName"
              label="Last Name"
              placeholder="Enter Last Name"
              error=""
            />
          </div>
        </div>
        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Text
              name="title"
              label="Contact Title"
              placeholder="Enter Contact Title"
            />
          </div>
        </div>

        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Email
              name="email"
              label="Contact Email"
              placeholder="Enter Contact Email"
              error=""
            />
          </div>
        </div>

        <div className="field-wrapper">
          <div className="row">
            <div className="col-lg-12 mw-100">
              <Text
                name="address"
                label="Address"
                placeholder="Enter Address"
                error=""
              />
            </div>
          </div>
        </div>
        <div className="field-wrapper">
          <div className="row">
            <div className="col-lg-12 mw-100">
              <Text
                name="website"
                label="Website"
                placeholder="Enter Website"
                error=""
              />
            </div>
          </div>
        </div>
        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Phone
              name="phone"
              label="Contact Phone"
              placeholder="Enter Contact Phone"
            />
          </div>
        </div>

        {id && (
          <>
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Number
                  name="customer_duns"
                  label="Customer DUNS"
                  placeholder="Enter Customer DUNS"
                  hintText="Customer data universal number system, 9 characters"
                  error=""
                  maxLength={9}
                />
              </div>
            </div>
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Select
                  name="industry_vertical"
                  label="Industry Vertical"
                  placeholder="Enter Industry Vertical"
                  error=""
                  options={this.props.industryVerticalOption}
                  required
                />
              </div>
            </div>
            <div className="field-wrapper row">
              <div className="col-lg-12">
                <Text name="city" label="City" placeholder="Enter City" />
              </div>
            </div>

            <div className="field-wrapper row">
              <div className="col-lg-12">
                <State
                  name="state"
                  label="State/Province/Region"
                  placeholder="Enter State"
                  error=""
                  selectedCountry={
                    this.props.selectedCountry ? this.props.selectedCountry : ""
                  }
                  selectedCountryName={
                    this.props.selectedCountryName
                      ? this.props.selectedCountryName
                      : ""
                  }
                />
              </div>
            </div>

            <div className="field-wrapper row">
              <div className="col-lg-6">
                <Country
                  name="country"
                  label="Country"
                  placeholder="Select Country"
                  onChange={this.props.handleCountryChange}
                />
              </div>
              <div className="col-lg-6">
                <Zipcode
                  name="postalCode"
                  label="Zip/Postal Code"
                  placeholder="Enter Zip Code"
                  locale={
                    selectedCountryName ? selectedCountryName : UNITED_STATES
                  }
                />
              </div>
            </div>

            <div className="field-wrapper row">
              <div className="col-lg-12">
                <DealStage
                  name="stage"
                  label="Stage"
                  placeholder="Select Stage"
                  isSearchable={true}
                />
              </div>
            </div>

            <div className="field-wrapper row">
              <div className="col-lg-6">
                <Currency
                  name="amount"
                  label="Amount"
                  placeholder="Amount"
                  minValue={0}
                />
              </div>
              {this.props.dealDetails &&
                !this.props.dealDetails.target_close_date && (
                  <div className="row col-lg-6">
                    <div className="w-100">
                      <div>
                        <label>Close Date</label>
                      </div>
                      <DatePicker
                        name="closeDate"
                        className="datepicker"
                        dayPlaceholder="DD"
                        monthPlaceholder="MM"
                        yearPlaceholder="YYYY"
                        onChange={date => this.props.setDate(date, "closeDate")}
                        minDate={new Date()}
                        value={this.props.closeDate}
                        clearIcon={null}
                      />
                    </div>
                  </div>
                )}
            </div>
          </>
        )}

        <div className="field-wrapper w-100 row">
          <div className="col-lg-12">
            <TextArea
              name="notes"
              label="Deal Notes"
              placeholder="Your Deal Notes..."
              rows="8"
            />
          </div>
        </div>

        {this.props.dealRegisteredBy && (
          <div>
            <label className="mb-3">
              <b>Registered By</b>
            </label>
            <div className="d-flex">
              <div>
                <Avatar
                  url={this.props.avatarUrl}
                  firstName={this.props.dealRegistered.first_name}
                  lastName={this.props.dealRegistered.last_name}
                />
              </div>
              <div className="pt-2 pl-2">
                <span className="text-center h6">
                  {this.props.dealRegistered.first_name}{" "}
                  {this.props.dealRegistered.last_name}
                </span>
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(DealFormFields);
