import React from "react";
import {
  LEAD_PARTNER_STATUS_ACCEPTED,
  LEAD_PARTNER_STATUS_DECLINED
} from "../lead/Constants";

import { Alert } from "reactstrap";

const PartnerAcceptLeadAlert = props => {
  return (
    <div className="alerts-wrapper">
      <Alert
        color="dark-info"
        className={"d-flex align-items-center justify-content-stretch"}
      >
        <p className="mb-0 font-weight-bold mr-auto text-white">
          Would you like to accept this lead?
        </p>
        <div className="btn-wrapper ml-auto mr-0">
          <button
            id={props.id}
            type="button"
            className="btn btn-danger mr-4 text-white"
            onClick={() =>
              props.updatePartnerLeadStatus(
                LEAD_PARTNER_STATUS_DECLINED,
                props.leadDetails.id
              )
            }
          >
            No, I Reject!
          </button>
          <button
            id={props.id}
            type="button"
            className="btn btn-success"
            onClick={() =>
              props.updatePartnerLeadStatus(
                LEAD_PARTNER_STATUS_ACCEPTED,
                props.leadDetails.id
              )
            }
          >
            Yes, I Accept!
          </button>
        </div>
      </Alert>
    </div>
  );
};

export default PartnerAcceptLeadAlert;
