import React from "react";
import AddDiscussionForm from "./AddDiscussionForm";

// Components
import Modal from "../../components/base/Modal";
import Button from "../../components/base/Button";
import PageTitle from "../../components/base/PageTitle";

export default class AddUser extends React.Component {
  state = {
    modal: false
  };

  toggle = () => {
    const modal = this.state.modal ? false : true;
    this.setState({ modal });
  };

  render() {
    return (
      <div>
        <PageTitle label="Discussion" />
        <Button
          label="New Discussion"
          icon="fa fa-plus"
          align="right"
          onClick={this.toggle}
        />
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          modalTitle="New Discussion"
          modalBody={
            <AddDiscussionForm
              toggle={this.toggle}
              projectId={this.props.projectId}
            />
          }
          hideFooter={true}
        />
      </div>
    );
  }
}
