import React from "react";
import PropTypes from "prop-types";
import { Field } from "formik";
import { FormGroup, FormFeedback } from "reactstrap";
import NumberFormat from "react-number-format";
import Label from "../base/Label";

class Currency extends React.Component {
  validate(value) {
    const { label, placeholder, required, minValue } = this.props;

    let errorMessage;
    let inputLabel = label || placeholder;

    if (!value && required) {
      errorMessage = inputLabel ? `${inputLabel} is required` : "Required";
    }

    if (value < minValue) {
      errorMessage = inputLabel
        ? `${inputLabel} should be greater than $${minValue}`
        : "";
    }

    return errorMessage;
  }

  renderInput({ field, form: { touched, errors, setFieldValue, values } }) {
    const {
      name,
      id,
      label,
      placeholder,
      required,
      onChange,
      defaultValue,
      className,
      maxLength,
      minValue,
      disabled
    } = this.props;

    const errorMessage = touched[name] && errors[name] ? errors[name] : null;
    const inputId = id || name;

    return (
      <FormGroup style={{ marginBottom: 22, position: "relative" }}>
        {label && (
          <Label id={inputId} required={required}>
            {label}
          </Label>
        )}

        {(defaultValue || field.value) && (
          <span
            style={{
              position: "absolute",
              top: `${label ? "42px" : "10px"}`,
              left: "10px",
              fontSize: "14px",
              zIndex: "0"
            }}
          >
            $
          </span>
        )}

        <NumberFormat
          defaultValue={defaultValue || field.value}
          value={field.value}
          id={inputId}
          {...field}
          placeholder={placeholder || label}
          className={`${className} form-control ${
            errorMessage ? "is-invalid" : ""
          }`}
          style={{
            background: "#F3F3F4",
            border: "none",
            borderRadius: "5px",
            fontSize: "14px",
            height: "40px",
            paddingLeft: "20px"
          }}
          onValueChange={value => {
            setFieldValue(name, value.value);
          }}
          onKeyUp={onChange}
          maxLength={maxLength ? maxLength : 6}
          minValue={minValue ? minValue : 0}
          disabled={disabled || false}
        />

        {errorMessage && (
          <FormFeedback style={{ position: "absolute", marginTop: 1 }}>
            {errorMessage}
          </FormFeedback>
        )}
      </FormGroup>
    );
  }

  render() {
    const { name, id } = this.props;

    return (
      <Field
        id={id || name}
        validate={this.validate.bind(this)}
        name={name}
        render={this.renderInput.bind(this)}
      />
    );
  }
}

Currency.propTypes = {
  name: PropTypes.string.isRequired,
  id: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool
};

export default Currency;
