import React from "react";
import UpdateUserForm from "./updateUserForm";
import UserProvider from "../../context/userContext/UserProvider";
import UserContext from "../../context/userContext/UserContext";

const UserDetails = props => {
  return (
    <UserProvider>
      <UserContext.Consumer>
        {context => (
          <UpdateUserForm
            history={props.history}
            match={props.match}
            context={context}
            loggedInUser={props.loggedInUser}
          />
        )}
      </UserContext.Consumer>
    </UserProvider>
  );
};

export default UserDetails;
