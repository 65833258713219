import React from "react";
import { TabContent, TabPane } from "reactstrap";
import { Link } from "react-router-dom";

//Assets
import {
  OktaIcon,
  ChevronRight,
  Auth0Icon
} from "../../../../assets/img/icons";

// Constants
import {
  INTEGRATION_SINGLE_SIGN_ON_SUB_TAB,
  TAB_AUTH0,
  TAB_INTEGRATION,
  TAB_OKTA_SETTINGS
} from "../../../../setting/Constants";
import OktaSection from "./sections/OktaSection";
import Auth0Section from "./sections/Auth0Section";

const SingleSignOn = props => {
  const { activeSection, adminSettings, saveSettings, history } = props;

  return (
    <>
      {!activeSection && (
        <>
          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_SINGLE_SIGN_ON_SUB_TAB}/${TAB_OKTA_SETTINGS}`}
            className="onboarding-link mb-2"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="step-count completed">
                  <OktaIcon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">{TAB_OKTA_SETTINGS}</h5>
              {/* <p className="font-weight-bold text-inline-grayed"></p> */}
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>

          <Link
            to={`/admin-settings/${TAB_INTEGRATION}/${INTEGRATION_SINGLE_SIGN_ON_SUB_TAB}/${TAB_AUTH0}`}
            className="onboarding-link mb-2"
          >
            <div className="onboarding-header">
              <div className="step-wrapper">
                <span className="step-count completed">
                  <Auth0Icon />
                </span>
              </div>
            </div>
            <div className="onboarding-main">
              <h5 className="font-weight-bold">{TAB_AUTH0}</h5>
              {/* <p className="font-weight-bold text-inline-grayed"></p> */}
            </div>
            <div className="onboarding-url">
              <ChevronRight />
            </div>
          </Link>
        </>
      )}

      <TabContent activeTab={activeSection}>
        <TabPane tabId={TAB_OKTA_SETTINGS} className="w-100">
          <OktaSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        <TabPane tabId={TAB_AUTH0} className="w-100">
          <Auth0Section
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default SingleSignOn;
