import React from "react";
import SVG from "react-inlinesvg";
import Button from "../base/Button";

const top = "60px";
const middle = "400px";

const NoUserFound = props => {
  const {
    hideCard,
    icon,
    iconClass,
    description,
    message,
    buttonLabel,
    buttonLink,
    buttonIcon,
    position,
    showMessage,
    boldMessage,
    title
  } = props;

  const root = {
    height: position === "top" ? top : middle,
    textAlign: "center",
    paddingLeft: "40px",
    paddingRight: "40px",
    paddingBottom: "40px"
  };

  return (
    <div
      className={`${
        !hideCard ? "card mb-5" : ""
      } d-flex align-items-center justify-content-center flex-column`}
      style={root}
    >
      {icon && (
        <div className={`no-records-icon ${iconClass ? iconClass : ""} mb-4`}>
          <SVG src={icon} />
        </div>
      )}
      {!showMessage && (
        <div>
          <strong>{title}</strong>
        </div>
      )}
      {message && message}
      {boldMessage && <strong>{boldMessage}</strong>}
      {description && <p className="text-center">{description}</p>}

      {buttonLink && (
        <a href={buttonLink}>
          <Button label={buttonLabel} icon={buttonIcon} />
        </a>
      )}
    </div>
  );
};

export default NoUserFound;
