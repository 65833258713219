import React from "react";
import { CongratsGraphicIcon } from "../../../assets/img/icons";
import "react-circular-progressbar/dist/styles.css";
import OnboardingLink from "./OnboardingLink";

const ExpertAccepted = props => {
  const {
    enable,
    percentage,
    expertAvailableStatus,
    expertAvailableText
  } = props;
  return (
    <div
      className={["expert-onboarding", `${!enable ? "disabled" : ""}`].join(
        " "
      )}
    >
      <OnboardingLink
        icon={<CongratsGraphicIcon />}
        title={"Congratulations! You have been accepted!"}
        description={"Torchlite will now start matching projects to you."}
        availability={{
          status:
            expertAvailableStatus === "Completed"
              ? "available"
              : "not-available",
          text: expertAvailableText
        }}
        progress={percentage}
        expertStatus={{
          accepted: true,
          experttype: "normal",
          pathColor: percentage < 99 ? "" : "#F0F0F1"
        }}
        link="/availability"
      />
      {/*<HowItWorks title={"Here are some tips to help you get noticed"}>*/}
      {/*  <HowItWorksItem*/}
      {/*    imgsrc={""}*/}
      {/*    title={"Leverage agile frameworks"}*/}
      {/*    description={*/}
      {/*      "Capitalize on low hanging fruit to identify a ballpark value added activity to beta test. Override the digital divide with additional clickthroughs from DevOps."*/}
      {/*    }*/}
      {/*    align={"left"}*/}
      {/*    number={""}*/}
      {/*  />*/}
      {/*  <HowItWorksItem*/}
      {/*    imgsrc={""}*/}
      {/*    title={"Provide a robust synopsis"}*/}
      {/*    description={*/}
      {/*      "Provide a robust synopsis for high level overviews. Iterative approaches to corporate strategy foster collaborative thinking to further the overall value proposition."*/}
      {/*    }*/}
      {/*    align={"left"}*/}
      {/*    number={""}*/}
      {/*  />*/}
      {/*  <HowItWorksItem*/}
      {/*    imgsrc={""}*/}
      {/*    title={"Credibly reintermediate ideas"}*/}
      {/*    description={*/}
      {/*      "Holistically foster superior methodologies without market-driven best practices.\n" +*/}
      {/*      "Distinctively exploit optimal alignments for intuitive bandwidth. "*/}
      {/*    }*/}
      {/*    align={"left"}*/}
      {/*    number={""}*/}
      {/*  />*/}
      {/*</HowItWorks>*/}
    </div>
  );
};

export default ExpertAccepted;
