import React, { Component } from "react";
import { DropdownItem } from "reactstrap";
import { Link } from "react-router-dom";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import MoreDropdown from "../../components/base/MoreDropdown";

// configs
import { endpoints } from "../../configs";

// Helper
import { DEAL_STATUS_CLOSED } from "../../dealStatus/Constants";

import { DealIcon } from "../../assets/img/icons";
import { getParamsByName } from "../../lib/helper";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class ClosedDeals extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "amount:ASC",
          label: "Amount"
        }
      ]
    };
  }

  render() {
    const currentUrl = window.location.pathname + window.location.search;
    const search = window.location.search;
    const pathParams = new URLSearchParams(search);
    const searchItem = pathParams.get("search");
    let params = {
      section: getParamsByName("section") || "closed-deals",
      status: DEAL_STATUS_CLOSED,
      searchItem: searchItem
    };
    return (
      <>
        <ReduxTable
          id="closedDeals"
          apiURL={`${endpoints().dealsAPI}/search`}
          params={params}
          searchPlaceholder="Search"
          onRowClick={row =>
            this.props.history.push(
              `/deal/details/${row.deal_id}?redirect=${currentUrl}`
            )
          }
          sortByOptions={this.state.sortByOptions}
          newTableHeading
          showHeader
          icon={<DealIcon />}
          message="You can track the deals you have registered here"
          subtextMessage="To get started tap the Register a Deal button"
          paramsToUrl={true}
          history={this.props.history}
        >
          <ReduxColumn
            type="link"
            isClickable="true"
            sortBy="account_name"
            field="accountName"
            renderField={row => (
              <Link to={`deal/details/${row.id}`}>{row.accountName}</Link>
            )}
          >
            Account Name
          </ReduxColumn>
          <ReduxColumn
            field="status"
            disableOnClick
            renderField={row => (
              <div
                className={`text-center text-uppercase ${
                  row.status === DEAL_STATUS_CLOSED
                    ? "bg-danger"
                    : "landing-page-status-draft"
                }`}
                style={statusStyle}
              >
                <p>{row.status}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn field="stage_tag_name" sortBy="stage_tag_name">
            Stage
          </ReduxColumn>
          <ReduxColumn
            field="amount"
            disableOnClick
            renderField={row => {
              return (
                <div className="text-left">
                  <p>{row.amount ? `$ ${row.amount}` : ""}</p>
                </div>
              );
            }}
          >
            Amount
          </ReduxColumn>
          <ReduxColumn field="partnerName" sortBy="partner_name">
            Partner
          </ReduxColumn>
          <ReduxColumn field="createdAt" sortBy="createdAt">
            Created At
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      this.props.history.push(
                        `/deal/details/${row.deal_id}?redirect=${currentUrl}`
                      );
                    }}
                  >
                    View Details
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </>
    );
  }
}

export default ClosedDeals;
