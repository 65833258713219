import React from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { bindActionCreators } from "redux";

// Config
import { endpoints } from "../../configs";

// Components
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import { DropdownItem } from "reactstrap";
import MoreDropdown from "../../components/base/MoreDropdown";
import AvatarCard from "../../components/base/AvatarCard";

// Helper
import { isLoggedIn } from "../../lib/helper";
import {
  PARTNER_STATUS_APPROVED,
  PARTNER_STATUS_DECLINED,
  PARTNER_STATUS_PENDING
} from "../../partnerStatus/Constants";
// Action
import { updatePendingPartners } from "../../actions/partners";

const statusStyle = {
  borderRadius: "5px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "100px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px"
};

class Partners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByOptions: [
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        },
        {
          value: "company_name:ASC",
          label: "Name"
        }
      ]
    };
  }

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }

  // Update partner status handler
  updatePartnerByStatus(status, partnerId) {
    this.props.actions.updatePendingPartners(
      partnerId,
      { marketplaceStatus: status },
      {
        marketplaceStatus: "Pending",
        sort: this.props.partnersSort,
        sortDir: this.props.partnersSortDir
      }
    );
  }

  render() {
    const currentUrl = window.location.pathname + window.location.search;

    return (
      <>
        <ReduxTable
          id="pendingPartners"
          apiURL={`${endpoints().partnerAPI}/search`}
          params={{ marketplaceStatus: "Pending" }}
          message="No Partners found"
          onRowClick={row =>
            window.open(
              `/partner-profile/${row.id}?redirect=${currentUrl}`,
              "_blank"
            )
          }
          searchPlaceholder="Search"
          sortByOptions={this.state.sortByOptions}
          newTableHeading
          showHeader
        >
          <ReduxColumn
            type="link"
            isClickable="false"
            field="companyName"
            sortBy="companyName"
            renderField={row => (
              <Link to={`edit/partner/public-profile?id=${row.id}`}>
                <AvatarCard companyName={row.companyName} url={row.avatarUrl} />
              </Link>
            )}
          >
            Name
          </ReduxColumn>
          <ReduxColumn field="email" disableOnClick>
            Email
          </ReduxColumn>
          <ReduxColumn field="createdAt" sortBy="createdAt">
            Date
          </ReduxColumn>
          <ReduxColumn
            field="marketplaceStatus"
            disableOnClick
            renderField={row => (
              <div
                className={`text-center text-uppercase ${
                  row.marketplaceStatus !== PARTNER_STATUS_PENDING
                    ? row.marketplaceStatus === PARTNER_STATUS_DECLINED
                      ? "bg-danger"
                      : "landing-page-status-active"
                    : "landing-page-status-draft"
                }`}
                style={statusStyle}
              >
                <p>{row.marketplaceStatus}</p>
              </div>
            )}
          >
            Status
          </ReduxColumn>
          <ReduxColumn
            field="Action"
            disableOnClick
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() =>
                      window.open(
                        `/partner-profile/${row.companyProfileUrl}`,
                        "_blank"
                      )
                    }
                  >
                    View Details
                  </DropdownItem>
                  <DropdownItem
                    onClick={() =>
                      this.updatePartnerByStatus(
                        PARTNER_STATUS_APPROVED,
                        row.id
                      )
                    }
                  >
                    Approve Partner
                  </DropdownItem>

                  <DropdownItem
                    onClick={() =>
                      this.updatePartnerByStatus(
                        PARTNER_STATUS_DECLINED,
                        row.id
                      )
                    }
                  >
                    Decline Partner
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
      </>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ updatePendingPartners }, dispatch)
  };
}

const mapStateToProps = state => {
  const { pendingPartners } = state.table;
  const partnersSort =
    pendingPartners && !pendingPartners.isFetching
      ? pendingPartners.sort
      : "createdAt";

  const partnersSortDir =
    pendingPartners && !pendingPartners.isFetching
      ? pendingPartners.sortDir
      : "DESC";
  return { partnersSort, partnersSortDir };
};

export default connect(() => {
  return mapStateToProps;
}, mapDispatchToProps)(Partners);
