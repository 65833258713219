// MultiDragAndDrop.js
import React, { useState, useCallback, useEffect } from "react";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import {
  DownloadIcon,
  FileIcon,
  FilePlusIcon,
  ImageIcon,
  TrashIcon
} from "../../assets/img/icons";
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";
import Toast from "../../components/base/Toast";
import { isBadRequest } from "../../common/http";

const MultiDragDrop = props => {
  const [selectedFiles, setSelectedFiles] = useState([]);

  const imageType = ["jpg", "jpeg", "gif", "png"];

  useEffect(() => {
    getMedia();
  }, [selectedFiles && selectedFiles.length > 0]);

  const onDrop = useCallback(
    async acceptedFiles => {
      if (acceptedFiles) {
        setSelectedFiles([...selectedFiles, ...acceptedFiles]);
        mediaUpload(acceptedFiles);
      }
    },
    [selectedFiles]
  );

  const getMedia = async () => {
    try {
      if (props.objectId !== undefined) {
        const response = await apiClient.get(props.getMediaUrl);

        if (response && response.data && response.data.data) {
          setSelectedFiles(response.data.data);
        } else {
          setSelectedFiles("");
        }
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }

        console.error(errorMessage);
      }
    }
  };

  const mediaUpload = values => {
    const data = new FormData();

    if (values && values.length > 0 && props.objectId !== undefined) {
      values.forEach(file => {
        data.append("uploadFile", file);
        data.append("uploadFileName", file.name);
        data.append("objectId", props.objectId);
        data.append("object", props.object);

        apiClient
          .post(props.mediaUploadUrl, data)
          .then(response => {
            if (response.data) {
              Toast.success(response.data.message);
              getMedia(props.object);
            }
          })
          .catch(error => {
            if (isBadRequest(error)) {
              let errorMessage;
              const errorRequest = error.response.request;
              if (errorRequest && errorRequest.response) {
                errorMessage = JSON.parse(errorRequest.response).message;
              }
              console.error(errorMessage);
            }
          });
      });
    }
  };

  const handleDelete = id => {
    apiClient
      .delete(`${endpoints().mediaAPI}/${id}`)
      .then(response => {
        let message;
        if (response.data) {
          message = "File Deleted Successfully";
          getMedia(props.object);
          Toast.success(message);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <div>
      <div
        {...getRootProps()}
        className={`dropzone ${isDragActive ? "active" : ""}`}
        style={{
          border: "2px dashed #cccccc",
          borderRadius: "4px",
          padding: "20px",
          textAlign: "center",
          cursor: "pointer",
          backgroundColor: "#f3f3f4"
        }}
      >
        <input {...getInputProps()} />
        <p>
          {isDragActive
            ? "Drop the files here ..."
            : "Drag and drop some files here, or click to select files"}
        </p>
        <FilePlusIcon />
      </div>

      {selectedFiles && selectedFiles.length > 0 && (
        <div className="supporting-materials supporting-material-preview">
          {selectedFiles.map(file => (
            <div className="row" key={file.name}>
              <div>
                {imageType.includes(file.name.split(".").slice(-1)[0]) ? (
                  <ImageIcon />
                ) : (
                  <FileIcon />
                )}
              </div>
              <div className="name ellipsis pr-2 mt-1 mb-1 col-lg-10 col-9">
                {file.name}
              </div>
              <div className="d-flex ">
                <div
                  className="mb-1 mt-1"
                  onClick={() => handleDelete(file.mediaId)}
                  style={{
                    background: "none",
                    border: "none",
                    cursor: "pointer"
                  }}
                >
                  <FontAwesomeIcon size="lg" icon={faTrash} color="black" />
                </div>
                <div className="action mb-1 mt-1">
                  <a
                    href={file.fileUrl}
                    style={{ cursor: "pointer" }}
                    target="_blank"
                    rel="noreferrer"
                  >
                    <DownloadIcon />
                  </a>
                </div>
              </div>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(mapStateToProps)(MultiDragDrop);
