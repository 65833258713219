import React from "react";
import { connect } from "react-redux";

// Helper
import {
  isLoggedIn,
  isSuperAdmin,
  isCompanyAdmin,
  isPartner,
  isCompanyManager,
  getParamsByName
} from "../../../lib/helper";
import { fetchList } from "../../../actions/table";

// Configs
import { endpoints } from "../../../configs";

import Report from "./report";

import { isBadRequest } from "../../../common/http";

import Page404 from "../../Page404";

import { apiClient } from "../../../apiClient";
import { bindActionCreators } from "redux";

const TAB_ALL_PARTNERS = "all-partners";
const TAB_PENDING_PARTNERS = "pending-partners";
const TAB_APPROVED_PARTNERS = "approved-partners";

class PartnerReport extends React.Component {
  state = {
    status: "",
    currentPage: this.props.currentPage
  };

  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
  }

  render() {
    if (
      !isSuperAdmin(
        this.props.loggedInUser && this.props.loggedInUser.roleId
      ) &&
      !isCompanyAdmin(
        this.props.loggedInUser && this.props.loggedInUser.roleId
      ) &&
      !isPartner(this.props.loggedInUser && this.props.loggedInUser.roleId) &&
      !isCompanyManager(
        this.props.loggedInUser && this.props.loggedInUser.roleId
      )
    ) {
      return <Page404 />;
    }

    return (
      <>
        <div>
          <Report
            history={this.props.history}
            status={this.state.status}
            currentPage={this.state.currentPage}
            loggedInUser={this.props.loggedInUser}
          />
        </div>
      </>
    );
  }
}

const mapStateToProps = state => {
  const reduxTable = state.table;

  // Get pending partners count
  const pendingPartnersRequestCount =
    reduxTable[TAB_PENDING_PARTNERS] &&
    !reduxTable[TAB_PENDING_PARTNERS].isFetching
      ? reduxTable[TAB_PENDING_PARTNERS].totalCount
      : 0;

  // Get approved partners count
  const approvedPartnersRequestCount =
    reduxTable[TAB_APPROVED_PARTNERS] &&
    !reduxTable[TAB_APPROVED_PARTNERS].isFetching
      ? reduxTable[TAB_APPROVED_PARTNERS].totalCount
      : 0;

  // Get all partners count
  const allPartnersRequestCount =
    reduxTable[TAB_ALL_PARTNERS] && !reduxTable[TAB_ALL_PARTNERS].isFetching
      ? reduxTable[TAB_ALL_PARTNERS].totalCount
      : 0;

  //get current page
  const currentPage =
    reduxTable[TAB_ALL_PARTNERS] && !reduxTable[TAB_ALL_PARTNERS].isFetching
      ? reduxTable[TAB_ALL_PARTNERS].currentPage
      : 1;

  return {
    pendingPartnersRequestCount,
    approvedPartnersRequestCount,
    allPartnersRequestCount,
    currentPage
  };
};

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(PartnerReport);
