import React, { useState } from "react";

// Components
import Form from "../../components/base/Form";
import DefaultContent from "../../components/content/DefaultContent";

import Text from "../../components/base/Text";
import SaveButton from "../../components/base/SaveButton";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

import toast from "../../components/base/Toast";

import { isBadRequest } from "../../common/http";

// Constants
import {
  SETTINGS_GOOGLE_API_CLIENT_ID,
  SETTINGS_GOOGLE_API_CLIENT_SECRET
} from "../../setting/Constants";

const Gmail = props => {
  const { adminSettings, saveSettings } = props;
  const {
    google_api_client_id,
    google_api_client_secret,
    google_api_refresh_token
  } = adminSettings;

  const [isGoogleConnected, setisGoogleConnected] = useState(
    google_api_refresh_token ? true : false
  );

  //connect with google
  const _connectGoogle = () => {
    return apiClient
      .post(`${endpoints().googleApi}/auth`)
      .then(response => {
        if (response && response.data) {
          if (response.data.redirectUri);
          window.open(response.data.redirectUri, "_self");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // google Section Initial Values
  const gmailSettingsInitialValues = {
    google_api_client_id,
    google_api_client_secret
  };

  // google Section Submit Form
  const submit = values => {
    const data = new FormData();

    // google Integration Data
    if (values.google_api_client_id !== undefined) {
      data.append("google_api_client_id", values.google_api_client_id);
    }

    if (values.google_api_client_secret !== undefined) {
      data.append("google_api_client_secret", values.google_api_client_secret);
    }

    // Save settings
    saveSettings(data);
  };

  // Disconnect Google
  const handleGoogleDisconnect = () => {
    const data = new FormData();
    data.append("google_api_refresh_token", "");
    setisGoogleConnected(false);
    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={gmailSettingsInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent
        title="Gmail"
        enableButton
        buttonLabel={!isGoogleConnected ? "Connect" : "Disconnect"}
        onButtonClick={
          !isGoogleConnected ? _connectGoogle : handleGoogleDisconnect
        }
      >
        <h5 className="font-weight-bold">Client Id</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_GOOGLE_API_CLIENT_ID}
            placeholder="google Client Id"
          />
        </div>

        <h5 className="font-weight-bold">Client Secret</h5>
        <div className="field-wrapper">
          <Text
            name={SETTINGS_GOOGLE_API_CLIENT_SECRET}
            placeholder="google Client Secret"
          />
        </div>
      </DefaultContent>

      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default Gmail;
