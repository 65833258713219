import React from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import Text from "../../../../components/base/Text";
import SaveButton from "../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_PORTAL_URL,
  SETTINGS_PORTAL_API_URL,
  MEDIA_BASE_URL,
  SETTINGS_AWS_BUCKET,
  SETTINGS_REFERRAL_LINK_BASE_URL
} from "../../../../setting/Constants";

// Helper

const SystemTab = props => {
  const { adminSettings, saveSettings, history } = props;

  // System Settings General Section Initial Values
  const systemSettingsInitialValues = {
    [SETTINGS_PORTAL_URL]: adminSettings[SETTINGS_PORTAL_URL],
    [SETTINGS_PORTAL_API_URL]: adminSettings[SETTINGS_PORTAL_API_URL],
    [SETTINGS_REFERRAL_LINK_BASE_URL]:
      adminSettings[SETTINGS_REFERRAL_LINK_BASE_URL],
    [MEDIA_BASE_URL]: adminSettings[MEDIA_BASE_URL],
    [SETTINGS_AWS_BUCKET]: adminSettings[SETTINGS_AWS_BUCKET],
    [SETTINGS_REFERRAL_LINK_BASE_URL]:
      adminSettings[SETTINGS_REFERRAL_LINK_BASE_URL]
  };

  // General Settings General Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Portal Url
    if (values[SETTINGS_PORTAL_URL] !== undefined) {
      data.append(
        SETTINGS_PORTAL_URL,
        values[SETTINGS_PORTAL_URL] ? values[SETTINGS_PORTAL_URL] : ""
      );
    }

    // Portal API Url
    if (values[SETTINGS_PORTAL_API_URL] !== undefined) {
      data.append(
        SETTINGS_PORTAL_API_URL,
        values[SETTINGS_PORTAL_API_URL] ? values[SETTINGS_PORTAL_API_URL] : ""
      );
    }

    // Referral Link Portal Url
    if (values[SETTINGS_REFERRAL_LINK_BASE_URL] !== undefined) {
      data.append(
        SETTINGS_REFERRAL_LINK_BASE_URL,
        values[SETTINGS_REFERRAL_LINK_BASE_URL]
          ? values[SETTINGS_REFERRAL_LINK_BASE_URL]
          : ""
      );
    }

    // Media Portal Url
    if (values[MEDIA_BASE_URL] !== undefined) {
      data.append(
        MEDIA_BASE_URL,
        values[MEDIA_BASE_URL] ? values[MEDIA_BASE_URL] : ""
      );
    }

    if (values[SETTINGS_AWS_BUCKET] !== undefined) {
      data.append(
        SETTINGS_AWS_BUCKET,
        values[SETTINGS_AWS_BUCKET] ? values[SETTINGS_AWS_BUCKET] : ""
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={systemSettingsInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          {/* Portal Company Portal Url */}
          <h5 className="font-weight-bold">Portal Url</h5>
          <div className="field-wrapper">
            <Text name={SETTINGS_PORTAL_URL} placeholder="Enter Portal Url" />
          </div>

          {/* Marketplace Portal API Url */}
          <h5 className="font-weight-bold">Portal API Url</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_PORTAL_API_URL}
              placeholder="Enter Portal API Url"
            />
          </div>

          {/* Referral Link Portal URL */}
          <h5 className="font-weight-bold">Referral Link Portal Url</h5>
          <div className="field-wrapper">
            <Text
              name={SETTINGS_REFERRAL_LINK_BASE_URL}
              placeholder="Enter Referral Link Portal Url"
            />
          </div>
        </div>
      </DefaultContent>
      <div className="btn-wrapper">
        <SaveButton />
      </div>
    </Form>
  );
};

export default SystemTab;
