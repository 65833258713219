import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Input } from "reactstrap";

import DatePicker from "../../../../components/base/Date";

import Form from "../../../../components/base/Form";
import CancelButton from "../../../../components/base/CancelButton";

import Button from "../../../../components/base/Button";

import Label from "../../../../components/base/Label";
import Text from "../../../../components/base/Text";
import Currency from "../../../../components/base/Currency";
import PaymentMethodType from "../../../../components/PaymentMethodType";

const PaymentModeModal = props => {
  const {
    isMarkPaymentMadeModal,
    toggleMarkPaymentMadeModal,
    referralDetails,
    paymentDate,
    isSendPaymentEmailToPartner,
    setPaymentAsMade,
    setPaymentDate,
    toggleIsSendPaymentEmailToPartner
  } = props;
  return (
    <>
      <Modal
        isOpen={isMarkPaymentMadeModal}
        toggle={toggleMarkPaymentMadeModal}
        backdrop="static"
      >
        <ModalHeader
          toggle={toggleMarkPaymentMadeModal}
          className="border-bottom-0 mb-0"
        >
          <div className="mt-3">
            <p className="text-left h6 font-weight-bold mb-0">
              Mark Payment as Made
            </p>
          </div>
        </ModalHeader>
        <Form
          initialValues={{
            amount:
              referralDetails && referralDetails.invoice_due_amount > 0
                ? referralDetails.invoice_due_amount
                : "",
            paymentMethod: "",
            paymentDate: "",
            paymentNote: ""
          }}
          onSubmit={values => {
            if (paymentDate) {
              values.paymentMethod = values.paymentMethod
                ? values.paymentMethod.label
                : "";
              values.paymentDate = paymentDate;
              values.sendPaymentEmailToPartner = isSendPaymentEmailToPartner;
              setPaymentAsMade(values);
            }
          }}
        >
          <ModalBody className="custom-modal-body mb-0 mt-0">
            <div className="mt-0 mb-0">
              <div>
                <Currency
                  name="amount"
                  placeholder="Amount"
                  minValue={0}
                  required
                />
                <PaymentMethodType
                  name="paymentMethod"
                  placeholder="Payment Method"
                  isSearchable={true}
                  required
                />
                <div className="mb-3">
                  <DatePicker
                    name="paymentDate"
                    id="paymentDate"
                    clearIcon={null}
                    onChange={setPaymentDate}
                    placeholder="Payment Date"
                    minDate={new Date()}
                    selected={paymentDate}
                    required={true}
                    width={100}
                  />
                </div>
                <Text
                  name="paymentNote"
                  placeholder="Payment Notes (optional)"
                />
                <div className="form-wrapper">
                  <Label className="mt-0 mb-0 ml-0 mr-0">
                    <Input
                      type="checkbox"
                      name="emailToPartner"
                      value={isSendPaymentEmailToPartner}
                      className="radio-button mt-0 mb-0 ml-0 mr-0"
                      onClick={() => {
                        toggleIsSendPaymentEmailToPartner();
                      }}
                    />
                    {/* <p className="d-flex font-weight-bold mt-0 mb-0 ml-0 mr-0">
                      <span className="radio-placeholder mt-0 mb-0 ml-0 mr-2" />
                      Send a payment notification email
                    </p> */}
                  </Label>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter className="border-top-0 mb-0 mt-0 pt-0">
            <div className="text-center w-100">
              <Button
                type="submit"
                label="Mark Payment as Made"
                className="btn btn-primary w-100"
              />
            </div>
            <div className="text-center w-100">
              <CancelButton
                className="w-100"
                onClick={() => {
                  toggleMarkPaymentMadeModal();
                }}
              />
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default PaymentModeModal;
