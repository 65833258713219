import React, { Component } from "react";

// Configs
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";
import { isBadRequest } from "../../common/http";

// Helper
import { getParamsByName } from "../../lib/helper";

//Inner Components
import EditAppFields from "./Edit";
// Constants
import { APP_404_ERROR, APP_400_ERROR } from "../marketplaceSourcing/Constants";

// Components
import Spinner from "../../components/base/Spinner";
import Page404 from "../Page404";

class EditApp extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isSubmitting: false,
      appDetails: [],
      isLoading: false,
      appId: "",
      appLogoFileUrl: "",
      dataStatus: "",
      appLogoFile: "",
      files: []
    };
  }

  componentDidMount() {
    const appId = getParamsByName("id");
    this.getAppDetails(appId);
  }
  // App details
  getAppDetails = appId => {
    this.setState({ isLoading: true }, () => {
      return apiClient
        .get(`${endpoints().appAPI}/${appId}`)
        .then(response => {
          this.setState({
            appDetails: response.data,
            isLoading: false,
            appLogoFileUrl: response.data.app_logo
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;

            const errorRequest = error.response.request;
            this.setState({
              isLoading: false,
              dataStatus: errorRequest.status
            });
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.stringify(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  };

  render() {
    const { isLoading, dataStatus } = this.state;
    if (isLoading) {
      return <Spinner />;
    }
    if (dataStatus == APP_400_ERROR || dataStatus == APP_404_ERROR) {
      return (
        <div className="app-body">
          <Page404 />
        </div>
      );
    }

    return (
      <div>
        <EditAppFields
          history={this.props.history}
          appDetails={this.state.appDetails}
          appLogoFileUrl={this.state.appLogoFileUrl}
          handleAppLogoRemove={this.handleAppLogoRemove}
          getAppDetails={this.getAppDetails}
        />
      </div>
    );
  }
}
export default EditApp;
