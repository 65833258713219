import React from "react";

// Config
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";

// Components
import PartnerGrid from "../../components/PartnerGrid";
import NLFormWrapper from "../../components/nl-form/NLFormWrapper";
import PageSearch from "../../components/base/PageSearch";
import SelectDropdown from "../../components/base/SelectDropdown";
import Spinner from "../../components/base/Spinner";

// Assets
import { CrossIcon, OctagonAlertIcon } from "../../assets/img/icons";

// Helper
import { isLoggedIn, getCookie, getUserId, isEmpty } from "../../lib/helper";

import { COOKIE_SHOW_ASSIGNED_PARTNERS } from "../../lib/cookie";

// Constants
import {
  TAG_TYPE_PRODUCTS,
  TAG_TYPE_SERVICES,
  TAG_TYPE_CATEGORY_MARKETPLACE
} from "../../tagType/Constants";
import { isBadRequest } from "../../common/http";
import { isStringExistInArray } from "../../common/utils";

class ApprovedPartners extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sortByOptions: [
        {
          value: "company_name:ASC",
          label: "Alphabetical A-Z"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ],
      selectedSortOption: "Alphabetical A-Z",
      selectedSortValue: "company_name:ASC",
      isLoading: false,
      isPartnerLoading: true,
      partnerSelectedFilters: {},
      partnerSelectObj: { partnerTypeObj: [], partnerTierObj: [] },
      partnerParagraphs: [],
      partnerExtraParagraphs: [],
      platforms: [
        {
          multiselect: 1,
          label: "platform",
          value: "",
          placeholder: true
        }
      ],
      services: [
        {
          multiselect: 1,
          label: "service",
          value: "",
          placeholder: true
        }
      ],
      partnerTypes: [
        {
          multiselect: 1,
          label: "Partner Type",
          value: "Partner Type",
          selected: false,
          placeholder: true
        }
      ],
      partnerTiers: [
        {
          multiselect: 1,
          label: "Partner Tier",
          value: "Partner Tier",
          selected: false,
          placeholder: true
        }
      ],
      tagTypeList: [],
      tagsTypeLoading: false
    };
    this.getPartnerSelectedFilters = this.getPartnerSelectedFilters.bind(this);
  }

  componentDidMount() {
    this._getPartnerList();
    this.getPartnerType();
  }

  //get partner List
  _getPartnerList() {
    const valueArray = this.state.selectedSortValue.split(":");
    const sort = valueArray[0];
    const sortDir = valueArray[1];

    let queryParam;

    const checked_status = getCookie(COOKIE_SHOW_ASSIGNED_PARTNERS);

    if (checked_status) {
      queryParam = `??marketplaceStatus=Approved&sort=${sort}&sortDir=${sortDir}&managerUserId=${getUserId()}`;
    } else {
      queryParam = `?marketplaceStatus=Approved&sort=${sort}&sortDir=${sortDir}`;
    }
    this.setState({ isLoading: true, isPartnerLoading: true }, () => {
      apiClient
        .get(`${endpoints().partnerAPI}/search${queryParam}`)
        .then(response => {
          const partnerList = response.data.data;
          const data = response.data;
          this.setState(
            {
              isLoading: false,
              partnerList: !partnerList ? [] : partnerList,
              partnerTotalCount: data.totalCount,
              isPartnerLoading: false
            },
            () => {
              this.filterPartnerList();
            }
          );
        });
    });
  }

  // Load Partner Tag API Call
  loadPartnerTagAPICall() {
    this._getTagsByTagType(TAG_TYPE_PRODUCTS);
    this._getTagsByTagType(TAG_TYPE_SERVICES);
  }

  // Get Tags by tag type
  _getTagsByTagType = tagType => {
    return apiClient
      .get(`${endpoints().tagAPI}?tagType=${tagType}&isSearchable=true`)
      .then(response => {
        const tags = response && response.data && response.data.data;

        if (tags && tags.length > 0) {
          if (tagType === TAG_TYPE_PRODUCTS) {
            tags.forEach(tag => {
              this.state.platforms.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          } else if (tagType === TAG_TYPE_SERVICES) {
            tags.forEach(tag => {
              this.state.services.push({
                label: tag.name,
                value: tag.name,
                selected: false
              });
            });
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Get partner tiers
  getPartnerTier = async (type, istrue) => {
    try {
      const response = await apiClient.get(
        `${endpoints().partnerTierAPI}/search`
      );
      const results = response.data.data;
      if (results && results.length > 0) {
        // If type
        if (isEmpty(type)) {
          let options = [];
          let partnerTierOptions = [];
          // Remove the old tier list in state
          this.state.partnerSelectObj.partnerTierObj.splice(
            0,
            this.state.partnerSelectObj.partnerTierObj.length
          );
          // Set tier object
          this.state.partnerSelectObj.partnerTierObj.push(
            this.state.partnerTiers[0]
          );
          // get tier list
          results.forEach(tier => {
            // If partner type in tier
            tier &&
              tier.partnerType.forEach(element => {
                // If selected type and assigned type equal to each other
                type.forEach(result => {
                  if (result === element.name) {
                    options.push({
                      id: tier.id,
                      value: tier.name,
                      label: tier.name,
                      selected: false
                    });
                    // get tier ids to compair
                    const tierIds = options.map(tier => tier.value);
                    // filtering duplicate tier
                    partnerTierOptions = options.filter(
                      ({ value }, index) => !tierIds.includes(value, index + 1)
                    );
                  }
                });
              });
          });
          // If filtered tier
          partnerTierOptions.forEach(tierList => {
            this.state.partnerTiers.push({
              label: tierList.label,
              value: tierList.value,
              selected: tierList.selected
            });

            // Update tier state with current value
            this.state.partnerSelectObj.partnerTierObj.push({
              id: tierList.id,
              label: tierList.label,
              value: tierList.value,
              selected: tierList.selected
            });
          });
        } else {
          // If type is not there

          // Remove the old tier list in state
          this.state.partnerSelectObj.partnerTierObj.splice(
            0,
            this.state.partnerSelectObj.partnerTierObj.length
          );

          this.state.partnerSelectObj.partnerTierObj.push(
            this.state.partnerTiers[0]
          );
          results.forEach(tier => {
            this.state.partnerTiers.push({
              label: tier.name,
              value: tier.name,
              selected: false
            });
            this.state.partnerSelectObj.partnerTierObj.push({
              id: tier.id,
              label: tier.name,
              value: tier.name,
              selected: false
            });
          });
          if (istrue) {
            this.getMarketplaceTagValues();
          }
        }
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }

      this.getMarketplaceTagValues();
    }
  };

  // Get partner tiers
  getPartnerType = async () => {
    try {
      const response = await apiClient.get(
        `${endpoints().partnerTypeAPI}/search`
      );
      const results = response.data.data;
      if (results && results.length > 0) {
        this.state.partnerSelectObj.partnerTypeObj.push(
          this.state.partnerTypes[0]
        );
        results
          .sort((a, b) => parseFloat(a.sort) - parseFloat(b.sort))
          .forEach(type => {
            this.state.partnerTypes.push({
              label: type.name,
              value: type.name,
              selected: false
            });
            this.state.partnerSelectObj.partnerTypeObj.push({
              id: type.id,
              label: type.name,
              value: type.name,
              selected: false
            });
          });
      }
      this.getPartnerTier([], true);
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
      this.getPartnerTier([], true);
    }
  };

  handleSortByChange = value => {
    this.setState(
      { selectedSortValue: this.getSortValueFromLabel(value) },
      () => {
        this._getPartnerList();
      }
    );
  };

  getSortValueFromLabel(label) {
    const sortByOptions = this.state.sortByOptions;

    const selectedSortOption = sortByOptions.find(
      option => option.label === label
    );

    if (selectedSortOption) {
      return selectedSortOption.value;
    }

    return "";
  }

  // Filter Partners List
  filterPartnerList = () => {
    const selectedFilters = this.state.partnerSelectedFilters;
    const tags = [];
    let type = [];
    let tier = "";
    let tagTypeList = this.state.tagTypeList;

    const selectedPartnerTypes = selectedFilters.partnerTypeObj
      ? selectedFilters.partnerTypeObj.selectedValue
      : "";
    const partnerTierTags = selectedFilters.partnerTierObj
      ? selectedFilters.partnerTierObj.selectedValue
      : "";

    if (partnerTierTags.length > 0) {
      tier = `${partnerTierTags[0]}`;
    }
    if (tagTypeList.length > 0) {
      tagTypeList.forEach(tagType => {
        if (
          selectedFilters[tagType.name] &&
          selectedFilters[tagType.name].selectedValue &&
          selectedFilters[tagType.name].selectedValue.length > 0
        )
          selectedFilters[tagType.name].selectedValue.forEach(customTag => {
            tags.push(customTag);
          });
      });
    }

    const serviceTags = selectedFilters.service
      ? selectedFilters.service.selectedValue
      : "";
    if (serviceTags.length > 0) {
      serviceTags.forEach(service => {
        tags.push(service);
      });
    }

    const platformTags = selectedFilters.platform
      ? selectedFilters.platform.selectedValue
      : "";

    if (platformTags.length > 0) {
      platformTags.forEach(platform => {
        tags.push(platform);
      });
    }

    const location =
      selectedFilters &&
      selectedFilters.location &&
      selectedFilters.location.selectedValue
        ? selectedFilters.location.selectedValue
        : "";
    const locationSplit =
      location && location[0] ? location[0].split(", ") : "";
    const locationName = locationSplit[0] || "";

    const { partnerList } = this.state;
    let filteredPartnerList = [];

    // Filter partners
    if (partnerList && partnerList.length > 0) {
      this.setState({ isPartnerLoading: true }, () => {
        partnerList.forEach(partner => {
          const partnerTypes =
            (partner &&
              partner.partnerTypeName &&
              partner.partnerTypeName.split(",")) ||
            partner.partnerTypeName;
          const partnerTier = partner.partnerTierName || "";
          const partnerTags = partner.tags || [];
          const partnerServices = partner.services || [];
          const partnerPlatforms = partner.platforms || [];
          const partnerCountry = partner.countryName || "";
          const partnerState = partner.stateName || "";
          const partnerCity = partner.city || "";
          let isTypeExist = false;
          let isTierExist = false;
          let isCountryExist = false;
          let isTagExist = false;

          if (
            partnerCountry === locationName ||
            partnerState === locationName ||
            partnerCity === locationName ||
            locationName === ""
          ) {
            isCountryExist = true;
          }

          if (selectedPartnerTypes && selectedPartnerTypes.length > 0) {
            selectedPartnerTypes.forEach(selectedPartnerType => {
              let isPartnerTypesExist = false;
              isPartnerTypesExist = isStringExistInArray(
                partnerTypes,
                selectedPartnerType
              );
              if (isPartnerTypesExist) {
                isTypeExist = true;
              }
            });
          } else {
            isTypeExist = false;
          }
          if (tier) {
            if (partnerTier === tier) {
              isTierExist = true;
            }
          } else {
            isTierExist = false;
          }

          if (tags && tags.length > 0) {
            tags.forEach(tagName => {
              let isServiceExist = false;
              let isPlatformExist = false;
              let isCustomTagExist = false;
              isServiceExist = this.isTagExist(partnerServices, tagName);
              isPlatformExist = this.isTagExist(partnerPlatforms, tagName);
              isCustomTagExist = this.isTagExist(partnerTags, tagName);
              if (isServiceExist || isPlatformExist || isCustomTagExist) {
                isTagExist = true;
              }
            });
          } else {
            isTagExist = true;
          }

          if (isTypeExist && isCountryExist && isTagExist) {
            filteredPartnerList.push(partner);
          } else if (isTierExist && isCountryExist && isTagExist) {
            filteredPartnerList.push(partner);
          } else if (
            selectedPartnerTypes.length === 0 &&
            partnerTierTags.length === 0 &&
            tags.length === 0
          ) {
            filteredPartnerList.push(partner);
          }
        });
        this.props.setPartnerList(filteredPartnerList);
        this.setState({
          filteredPartnerList: filteredPartnerList,
          filteredPartnerCount:
            (filteredPartnerList && filteredPartnerList.length) || 0,
          isPartnerLoading: false
        });
      });
    } else {
      this.setState({
        filteredPartnerList: partnerList
      });
    }
  };
  // Check is tag exist in partner tag data
  isTagExist = (tagList, filterTagName) => {
    if (!tagList.length) {
      return false;
    }

    let isExist = false;
    tagList.forEach(tag => {
      if (filterTagName == tag.tag_name) {
        isExist = true;
      }
    });

    return isExist;
  };

  // Get Selected Filters
  getPartnerSelectedFilters = (selectedFilters, selectName) => {
    // Get tier based on selected type
    if (selectName === "partnerTypeObj") {
      this.getPartnerTier(selectedFilters.selectedValue);
    }

    this.setState(
      prevState => ({
        partnerSelectedFilters: {
          ...prevState.partnerSelectedFilters,
          [selectName]: selectedFilters
        }
      }),
      () => {
        this.filterPartnerList();
      }
    );
  };

  // Get Filter Keys
  getPartnerFilterKeys = () => Object.keys(this.state.partnerSelectedFilters);

  // Delete Filter Keys
  deletePartnerFilters = e => {
    const { dataset } = e.target;
    this.setState(
      prevState => {
        let currentValueIndex = prevState.partnerSelectedFilters[
          dataset["parentobj"]
        ].selectedValue.indexOf(dataset["filter"]);
        let currentArray =
          prevState.partnerSelectedFilters[dataset["parentobj"]].selectedValue;
        currentArray.splice(currentValueIndex, 1);

        // Get partner tier
        if (dataset["parentobj"] === "partnerTypeObj") {
          this.getPartnerTier(currentArray);
        }

        return {
          partnerSelectedFilters: {
            ...prevState.partnerSelectedFilters,
            [dataset["parentobj"]]: {
              selectedValue: [...currentArray]
            }
          }
        };
      },
      () => {
        this.filterPartnerList();
      }
    );
  };

  // Search partner
  _handleSearchPartner = e => {
    const valueArray = this.state.selectedSortValue.split(":");
    const sort = valueArray[0];
    const sortDir = valueArray[1];
    this.setState(
      {
        searchParam: e.target.value
      },
      () => {
        apiClient
          .get(
            `${
              endpoints().partnerAPI
            }/search?marketplaceStatus=Approved&search=${encodeURIComponent(
              this.state.searchParam
            )}&sort=${sort}&sortDir=${sortDir}`
          )
          .then(response => {
            const data = response.data;
            this.setState(
              {
                partnerList: !data ? [] : data.data,
                partnerTotalCount: data.totalCount
              },
              () => {
                this.filterPartnerList();
              }
            );
          });
      }
    );
  };

  // Get Marketplace Tag Values
  getMarketplaceTagValues = async () => {
    this.setState({ tagsTypeLoading: true }, async () => {
      try {
        const response = await apiClient.get(
          `${endpoints().tagTypeAPI}?category=${TAG_TYPE_CATEGORY_MARKETPLACE}`
        );
        const results = response.data.data;

        if (this.state.partnerTypes.length > 1)
          this.state.partnerParagraphs.push(`I'm looking for {partnerTypeObj}`);

        if (this.state.partnerTiers.length > 1)
          this.state.partnerParagraphs.push(
            `${`I'm looking for {partnerTierObj}`}`
          );
        // Check the tag type skills
        if (results && results.length > 0) {
          const tagTypeList = [];
          await results.forEach(async (tagType, i) => {
            const tagList = [];
            this.state.partnerSelectObj[tagType.name] = [
              {
                multiselect: 1,
                label: tagType.name,
                value: "",
                placeholder: true
              }
            ];

            if (tagType.tags && tagType.tags.length > 0) {
              await tagType.tags.forEach(tag => {
                tagList.push({
                  id: tag.id,
                  name: tag.name,
                  value: tag.name,
                  label: tag.name,
                  tagTypeId: tagType.id
                });
                this.state.partnerSelectObj[tagType.name].push({
                  label: tag.name,
                  value: tag.name,
                  selected: false
                });
              });

              if (this.state.partnerParagraphs.length > 2) {
                this.state.partnerExtraParagraphs.push(
                  `with {${tagType.name}}`
                );
              } else {
                this.state.partnerParagraphs.push(
                  `${
                    this.state.partnerParagraphs.length > 0
                      ? "with {" + tagType.name + "}"
                      : "I'm looking for {" + tagType.name + "}"
                  }`
                );
              }
            }
            tagTypeList.push({
              id: tagType.id,
              name: tagType.name,
              permission: tagType.permission,
              tags: tagList
            });
          });

          this.setState(
            {
              tagTypeList: tagTypeList
            },
            () => {
              this.setState({
                tagsTypeLoading: false
              });
            }
          );
        } else {
          this.setState({
            tagsTypeLoading: false
          });
        }
      } catch (error) {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      }
    });
  };

  render() {
    const {
      sortByOptions,
      selectedSortOption,
      partnerList,
      partnerTotalCount,
      partnerSelectedFilters,
      isLoading,
      isPartnerLoading,
      filteredPartnerList,
      filteredPartnerCount,
      partnerSelectObj,
      partnerParagraphs,
      partnerExtraParagraphs
    } = this.state;

    const { filteredCount, settings } = this.props;
    const currentUrl = window.location.pathname + window.location.search;

    const totalCount = this.props.partnerCount || partnerTotalCount;
    if (filteredPartnerCount) {
      filteredCount(filteredPartnerCount);
    }
    const partnerFilterKeys = this.getPartnerFilterKeys();

    return (
      <>
        {isLoading && <Spinner />}
        <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column">
          <PageSearch
            classnames="page-search"
            placeholder={"Search"}
            onChange={e => this._handleSearchPartner(e)}
          />
          <div className="mr-2">
            <SelectDropdown
              buttonLabel={selectedSortOption}
              dropdownLinks={sortByOptions}
              color={"gray"}
              hideCaret
              selectName={"sortby_partners"}
              handleChange={this.handleSortByChange}
            />
          </div>
        </div>

        <NLFormWrapper
          paragraphs={partnerParagraphs}
          selectObj={partnerSelectObj}
          extraParagraphs={partnerExtraParagraphs}
          getSelectedFilters={this.getPartnerSelectedFilters}
          liveFilters={partnerSelectedFilters}
          renderExpertList={e => this.filterPartnerList(e)}
          hideDiscoverButton="Discover Partner"
        />

        {partnerFilterKeys.length && partnerFilterKeys.length > 0 ? (
          <div className="filters-wrapper">
            {partnerFilterKeys.map(filterObj =>
              this.state.partnerSelectedFilters[filterObj]["selectedValue"].map(
                value =>
                  value && (
                    <button
                      className={`badge badge-primary ${filterObj}`}
                      data-parentobj={filterObj}
                      data-filter={value}
                      onClick={this.deletePartnerFilters}
                    >
                      {value} <CrossIcon />
                    </button>
                  )
              )
            )}
          </div>
        ) : (
          ""
        )}

        {!isPartnerLoading && !partnerList.length && (
          <div className="inline-alert alert alert-light">
            <OctagonAlertIcon />{" "}
            <span>
              There are no Partners available that match your query. Try
              different filters.
            </span>
          </div>
        )}

        {/* /.selectedFilters */}
        <div className="card-list-wrapper">
          {isEmpty(partnerParagraphs) && totalCount > 0 && (
            <span className="d-block font-weight-bold h6-5">
              Showing {filteredPartnerCount}{" "}
              {filteredPartnerCount > 1 ? "partners" : "partner"}…
            </span>
          )}
          <PartnerGrid
            partnerList={filteredPartnerList}
            currentUrl={currentUrl}
            openLinkInNewTab={true}
            settings={settings}
          />
        </div>
      </>
    );
  }
}

export default ApprovedPartners;
