import React, { useState } from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import toast from "../../../components/base/Toast";
import DatePicker from "react-date-picker";
import moment from "moment";

// Assets
import { LargeWatchIcon } from "../../../assets/img/icons";

// Components
import Form from "../../../components/base/Form";
import Number from "../../../components/base/Number";
import CancelButton from "../../../components/base/CancelButton";

const LogGeneralHoursModal = props => {
  const { modal, setModal, projectDetails, createProjectTimeEntry } = props;
  const [startDate, setStartDate] = useState(new Date());
  const [hours, setHours] = useState("");
  const { id, name } = projectDetails;
  return (
    <Modal
      isOpen={modal}
      toggle={setModal}
      className={["log-hours-modal", "edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={setModal}>
        <LargeWatchIcon />
        <h4 className={["font-weight-bold", "my-4"].join(" ")}>
          Log General Time
        </h4>
      </ModalHeader>
      <Form
        initialValues={{
          hoursSpent: "",
          date: ""
        }}
        onSubmit={values => {
          if (!hours) {
            toast.error("Hours is required");
            return false;
          }

          values.hoursSpent = hours;
          values.date = moment(startDate).format("YYYY-MM-DD");
          values.projectId = id;
          createProjectTimeEntry(values);
          setHours("");
        }}
      >
        <ModalBody className={["text-center", "mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <Number
                placeholder="hrs"
                className={["hours-input"].join(" ")}
                name={`task-project-index-hours`}
                style={{ marginBottom: 0, marginRight: 0 }}
                onChange={e => {
                  setHours(e.target.value);
                }}
                value={hours}
              />
              <span>hours on</span>
              <div className="datepicker-wrapper">
                <DatePicker
                  name={`datepicker-project-index`}
                  className="datepicker"
                  dayPlaceholder="MM/DD/YYYY"
                  minDate={new Date()}
                  value={startDate}
                  onChange={date => setStartDate(date)}
                  clearIcon={null}
                />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={() => setModal()} />
            <button className="btn btn-primary">Add Time</button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

export default LogGeneralHoursModal;
