import React, { Component } from "react";
import DatePicker from "react-datepicker";
import { CSVLink } from "react-csv";
import moment from "moment";
// Configs
import { isLoggedIn } from "../../lib/helper";
import { endpoints } from "../../configs";

// API call
import { apiClient } from "../../apiClient";
//Assets
import { DownloadIcon } from "../../assets/img/icons";

import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import PageTitle from "../../components/base/PageTitle";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import PipelineReportStatCard from "../../components/reports/PipelineReportStatCard";
import Select from "../../components/base/Select";
import Form from "../../components/base/Form";

// Action
import { fetchList } from "../../actions/table";

// Constants
import {
  REFERRAL_STATUS_NEW,
  REFERRAL_STATUS_APPROVED,
  REFERRAL_STATUS_CLOSED_WON
} from "../../referral/Constants";
import { isBadRequest } from "../../common/http";
class Pipeline extends Component {
  state = {
    startDate: "",
    endDate: "",
    status: "",
    refferalReportCsv: []
  };
  componentDidMount() {
    // Check is logged in user
    isLoggedIn();
    this._getPiplineReoprtDetails();
  }

  componentDidUpdate() {
    // Check is logged in user
    isLoggedIn();
  }
  // piplinedetails details api call
  _getPiplineReoprtDetails = () => {
    const { startDate, endDate, status } = this.state;
    return apiClient
      .get(
        `${
          endpoints().pipelineReportAPI
        }?startDate=${startDate}&endDate=${endDate}&status=${status}`
      )
      .then(response => {
        // ReportStatCard Counts
        const totalAmounts = response.data.totalAmount;
        const totalCounts = response.data.totalCount;
        const averageReferrals = totalAmounts / totalCounts;
        const reportData = [];
        response.data.data.forEach(data => {
          reportData.push({
            accountName: data.account_name,
            amount: data.amount,
            status: data.status,
            createdAt: data.createdAt
          });
        });
        this.setState({
          refferalReportCsv: reportData,
          totalAmount: totalAmounts,
          averageReferral: averageReferrals,
          totalCount: totalCounts,
          loading: false
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  handleStartDate = startDate => {
    this.setState({ startDate }, () => {
      this.fetchData();
      this._getPiplineReoprtDetails();
    });
  };

  handleEndDate = endDate => {
    this.setState({ endDate }, () => {
      this._getPiplineReoprtDetails();
      this.fetchData();
    });
  };

  handleStatusChange = status => {
    this.setState({ status }, () => {
      this._getPiplineReoprtDetails();
      this.fetchData();
    });
  };

  fetchData = () => {
    const { startDate, endDate, status, totalAmount } = this.state;
    this.props.actions.fetchList(
      "pipelineReports",
      `v1/report/pipeline`,
      1,
      10,
      { startDate, endDate, status, totalAmount }
    );
  };

  render() {
    const statusOptions = [
      {
        value: REFERRAL_STATUS_NEW,
        label: REFERRAL_STATUS_NEW
      },
      {
        value: REFERRAL_STATUS_APPROVED,
        label: REFERRAL_STATUS_APPROVED
      },
      {
        value: REFERRAL_STATUS_CLOSED_WON,
        label: REFERRAL_STATUS_CLOSED_WON
      }
    ];
    const headers = [
      { label: "AccountName", key: "accountName" },
      { label: "Amount", key: "amount" },
      { label: "Status", key: "status" },
      { label: "createdAt", key: "createdAt" }
    ];

    return (
      <div>
        {/* /.page-heading */}
        <PageTitle label="Pipeline" />
        <div className="mt-4 mb-5">
          <PipelineReportStatCard
            averageReferralSize={this.state.averageReferral}
            totalValue={this.state.totalAmount}
            noOfReferralCount={this.state.totalCount}
          />
          <div className={`page-heading cover`}>
            <div className="d-flex" style={{ alignItems: "center" }}>
              <div className="datepicker-wrapper py-1 mr-3">
                <DatePicker
                  name="startDate"
                  className="datepicker"
                  placeholderText="Start Date"
                  onChange={this.handleStartDate}
                  maxDate={new Date()}
                  selected={this.state.startDate}
                  clearIcon={null}
                />
              </div>
              <div className="datepicker-wrapper py-1 mr-3">
                <DatePicker
                  name="endDate"
                  className="datepicker"
                  placeholderText="End Date"
                  onChange={this.handleEndDate}
                  selected={this.state.endDate}
                  clearAriaLabel
                  clearIcon={null}
                  width={"150px"}
                />
              </div>
              <div className="mr-3">
                <Form>
                  <Select
                    name="status"
                    placeholder="Select Status"
                    defaultValue=""
                    options={statusOptions}
                    handleChange={e => {
                      this.handleStatusChange(e.value);
                    }}
                    isSearchable={true}
                    width={"200px"}
                    marignBottom
                  />
                </Form>
              </div>
              <div>
                <CSVLink
                  data={this.state.refferalReportCsv}
                  headers={headers}
                  filename={`Pipeline_Report_${moment().format(
                    "YYYY-MM-DD"
                  )}.csv`}
                >
                  <span className="download-pdf ml-auto d-flex" href="#">
                    <DownloadIcon />
                    <span className="ml-1">Export</span>
                  </span>
                </CSVLink>
              </div>
            </div>
          </div>
          <ReduxTable
            id="pipelineReports"
            apiURL={`${endpoints().pipelineReportAPI}`}
            searchDisabled
          >
            <ReduxColumn
              maxWidth={"215px"}
              minWidth={"215px"}
              sortBy="account_name"
              field="account_name"
            >
              Company
            </ReduxColumn>
            <ReduxColumn
              maxWidth={"190px"}
              minWidth={"190px"}
              text={"centerAlign"}
              isClickable="true"
              sortBy="amount"
              field="amount"
            >
              Amount
            </ReduxColumn>
            <ReduxColumn
              maxWidth={"175px"}
              minWidth={"175px"}
              isClickable="true"
              sortBy="status"
              field="status"
            >
              Status
            </ReduxColumn>
            <ReduxColumn
              maxWidth={"230px"}
              minWidth={"230px"}
              isClickable="true"
              field="createdAt"
              sortBy="createdAt"
            >
              Created At
            </ReduxColumn>
          </ReduxTable>
        </div>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchList }, dispatch)
  };
}

export default connect(null, mapDispatchToProps)(Pipeline);
