import React, { useEffect, useState } from "react";
import PageTitle from "../../components/base/PageTitle";
import PageSearch from "../../components/base/PageSearch";
import Select from "../../components/base/Select";
import Form from "../../components/base/Form";
import { getParamsByName } from "../../lib/helper";
import Table from "./Table";
import { isBadRequest } from "../../common/http";
import { apiClient } from "../../apiClient";
import { endpoints } from "../../configs";
import Spinner from "../../components/base/Spinner";
import _ from "lodash";
import PageSize from "../../components/PageSize";

const coSellList = props => {
  const [detail, setDetail] = useState([]);
  const [page, setPage] = useState(1);
  const [isLoading, setIsLoading] = useState(true);
  const [params, setParams] = useState({
    page: getParamsByName("page") || 1,
    vendor: getParamsByName("vendor"),
    search: getParamsByName("search"),
    pageSize: getParamsByName("pageSize"),
    sort: getParamsByName("sort") ? getParamsByName("sort") : "week1",
    sortDir: getParamsByName("sortDir") ? getParamsByName("sortDir") : "desc"
  });
  const [vendorList, setVendorList] = useState([]);
  const [search, setSearch] = useState(getParamsByName("search"));
  const [sortOrder, setSortOrder] = useState("asc");

  const [sortParam, setSortParam] = useState("week1");

  useEffect(() => {
    getDetail(params);
    getVendorList();
  }, []);

  const handleSort = value => {
    setSortParam(value);
    setSortOrder(prevSortOrder => (prevSortOrder === "asc" ? "desc" : "asc"));
    handleParams({ sort: value, sortDir: sortOrder });
  };

  const getDetail = async params => {
    await apiClient
      .get(`${endpoints().coSellAPI}/search?&pagination=${true}`, { params })
      .then(response => {
        const results = response.data && response.data;
        if (results) {
          setDetail(results);
          setIsLoading(false);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };
  const getVendorList = async () => {
    await apiClient
      .get(`${endpoints().intentDataAPI}/vendorList`)
      .then(response => {
        const results = response.data && response.data && response.data.data;
        if (results) {
          setVendorList(results);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  const handleParams = updatedPrams => {
    let params = {
      search: getParamsByName("search"),
      vendor: getParamsByName("vendor"),
      page: page ? page : getParamsByName("page"),
      pageSize: getParamsByName("pageSize"),
      sortDir: getParamsByName("page") ? getParamsByName("sortDir") : "desc",
      sort: getParamsByName("sort") ? getParamsByName("sort") : "week1",
      ...updatedPrams
    };
    setParams(params);

    let param = [];
    if (params.search) {
      param.push(`search=${params.search}`);
    }
    if (params.vendor) {
      param.push(`vendor=${params.vendor}`);
    }
    if (params.page) {
      param.push(`page=${params.page ? params.page : 1}`);
    }
    if (params.sort) {
      param.push(`sort=${params.sort}`);
    }
    if (params.pageSize) {
      param.push(`pageSize=${params.pageSize}`);
    }
    if (params.sortDir) {
      param.push(`sortDir=${params.sortDir}`);
    }

    if (param.length > 0) {
      props.history.push(`/co-sell?${param.join("&")}`);
    } else props.history.push(`/co-sell`);
    getDetail(params);
  };

  const handlePageChange = e => {
    setPage(e);
    handleParams({ page: e });
  };

  const handleDomain = values => {
    let value =
      values && values.value && values.value.value ? values.value.value : "";

    handleParams({ vendor: value, page: 1 });
  };

  const handlePageSize = values => {
    handleParams({ pageSize: values });
  };

  const onSearch = event => {
    event.persist();
    const value = event.target.value;
    setSearch(value);
    doSearch(decodeURI(value));
  };

  const doSearch = _.debounce(value => {
    handleParams({ search: decodeURI(value), page: 1 });
  }, 500);

  if (isLoading) {
    return <Spinner />;
  }
  let initialValues = {
    vendor:
      vendorList &&
      vendorList.find(data => data.value == getParamsByName("vendor"))
  };
  const pageSizeOptions = [
    {
      value: "25",
      label: "25"
    },
    {
      value: "50",
      label: "50"
    },
    {
      value: "100",
      label: "100"
    }
  ];

  return (
    <div>
      <div className="d-flex justify-content-between  align-items-center mb-4">
        <PageTitle label="CLOUD PARTNERS" />
      </div>
      <div className={`page-heading cover pb-0`}>
        <div className="d-flex flex-column flex-md-row justify-content-between">
          <div className="p-0 pb-3">
            <PageSize
              onChange={e => handlePageSize(e)}
              pageSizeOptions={pageSizeOptions}
            />
          </div>
          <div className="pr-2 p-0 col-md-8">
            <PageSearch
              classnames="page-search"
              placeholder="Search"
              onChange={onSearch}
              value={search}
            />
          </div>
          <div className="col-12  col-md-3  p-0">
            <Form initialValues={initialValues}>
              <Select
                name="vendor"
                placeholder="Select"
                options={vendorList}
                onInputChange={handleDomain}
                isClearable
              />
            </Form>
          </div>
        </div>
      </div>
      <Table
        detail={detail}
        handlePageChange={handlePageChange}
        handleSort={handleSort}
        sortOrder={sortOrder}
        sortParam={sortParam}
      />
    </div>
  );
};

export default coSellList;
