import { apiClient } from "../apiClient";
import toast from "../components/base/Toast";
import { endpoints } from "../configs";
import { fetchList } from "./table";

import {
  REQUEST_UPDATE_REFERRAL,
  RECEIVE_UPDATE_REFERRAL,
  REFERRAL_UPDATE_ERROR,
  REQUEST_DELETE_REFERRAL,
  REFERRAL_DELETE_ERROR,
  RECEIVE_DELETE_REFERRAL
} from "../actionType/Constants";
import { getParamsByName } from "../lib/helper";
// Constants
import {
  REFERRAL_STATUS_NEW,
  REFERRAL_STATUS_DECLINED,
  REFERRAL_STATUS_APPROVED
} from "../referral/Constants";
import { isBadRequest } from "../common/http";

/**
 * Request for updating referrals
 */
export function requestUpdateReferral() {
  return {
    type: REQUEST_UPDATE_REFERRAL
  };
}

/**
 * Receive for updating referrals
 */
export function receiveUpdateReferral() {
  return {
    type: RECEIVE_UPDATE_REFERRAL
  };
}

/**
 * Receive for error updating referrals
 */
export function referralUpdateError(error) {
  return {
    type: REFERRAL_UPDATE_ERROR,
    error
  };
}

/**
 * Update referrals Inquiry
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateReferral(id, status, params) {
  const searchItem = getParamsByName("search") || "";
  const data = { status };
  return dispatch => {
    dispatch(requestUpdateReferral());
    apiClient
      .put(`${endpoints().referralApi}/status/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        // Table refresh data
        dispatch(
          fetchList(
            "newReferrals",
            `${endpoints().referralApi}/search`,
            params.currentPage || 1,
            10,
            {
              status: REFERRAL_STATUS_NEW,
              pagination: true,
              search: searchItem,
              sort: params.newReferralsSort,
              sortDir: params.newReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus,
              partnerId: params.partnerId || ""
            }
          )
        );
        dispatch(
          fetchList(
            "approvedReferrals",
            `${endpoints().referralApi}/search`,
            params.currentPage || 1,
            10,
            {
              status: REFERRAL_STATUS_APPROVED,
              pagination: true,
              search: searchItem,
              sort: params.approvedReferralsSort,
              sortDir: params.approvedReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus
            }
          )
        );
        dispatch(
          fetchList(
            "declinedReferrals",
            `${endpoints().referralApi}/search`,
            params.currentPage || 1,
            10,
            {
              status: REFERRAL_STATUS_DECLINED,
              pagination: true,
              search: searchItem,
              sort: params.declinedReferralsSort,
              sortDir: params.declinedReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus,
              partnerId: params.partnerId || ""
            }
          )
        );
        dispatch(
          fetchList(
            "allReferrals",
            `${endpoints().referralApi}/search`,
            params.currentPage || 1,
            10,
            {
              status: params.currentStatus,
              pagination: true,
              search: searchItem,
              sort: params.allReferralsSort,
              sortDir: params.allReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus,
              partnerId: params.partnerId || ""
            }
          )
        );
        dispatch(receiveUpdateReferral());
      })
      .catch(error => {
        dispatch(referralUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Update referrals decline
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function referralDecline(
  id,
  status,
  declineMessage,
  declineNotification,
  params
) {
  const searchItem = getParamsByName("search") || "";
  const data = { status, declineMessage, declineNotification };
  return dispatch => {
    dispatch(requestUpdateReferral());
    apiClient
      .put(`${endpoints().referralApi}/decline/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        // Table refresh data
        dispatch(
          fetchList(
            "newReferrals",
            `${endpoints().referralApi}/search`,
            params.currentPage || 1,
            10,
            {
              status: REFERRAL_STATUS_NEW,
              pagination: true,
              search: searchItem,
              sort: params.newReferralsSort,
              sortDir: params.newReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus,
              partnerId: params.partnerId || ""
            }
          )
        );
        dispatch(
          fetchList(
            "approvedReferrals",
            `${endpoints().referralApi}/search`,
            params.currentPage || 1,
            10,
            {
              status: REFERRAL_STATUS_APPROVED,
              pagination: true,
              search: searchItem,
              sort: params.approvedReferralsSort,
              sortDir: params.approvedReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus
            }
          )
        );
        dispatch(
          fetchList(
            "declinedReferrals",
            `${endpoints().referralApi}/search`,
            params.currentPage || 1,
            10,
            {
              status: REFERRAL_STATUS_DECLINED,
              pagination: true,
              search: searchItem,
              sort: params.declinedReferralsSort,
              sortDir: params.declinedReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus,
              partnerId: params.partnerId || ""
            }
          )
        );
        dispatch(
          fetchList(
            "allReferrals",
            `${endpoints().referralApi}/search`,
            params.currentPage || 1,
            10,
            {
              status: params.currentStatus,
              pagination: true,
              search: searchItem,
              sort: params.allReferralsSort,
              sortDir: params.allReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus,
              partnerId: params.partnerId || ""
            }
          )
        );
        dispatch(receiveUpdateReferral());
      })
      .catch(error => {
        dispatch(referralUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Request for deleting landing page
 */
export function requestDeleteReferral() {
  return {
    type: REQUEST_DELETE_REFERRAL
  };
}

/**
 * Receive for updating referrals
 */
export function receiveDeleteReferral() {
  return {
    type: RECEIVE_UPDATE_REFERRAL
  };
}
/**
 * Receive for error deleting landing page
 */
export function referralDeleteError(error) {
  return {
    type: REFERRAL_DELETE_ERROR,
    error
  };
}

/**
 * Delete landing page
 *
 * @param id
 * @returns {function(*): *}
 */
export function deleteReferral(id, params) {
  const searchItem = getParamsByName("search") || "";
  return dispatch => {
    dispatch(requestDeleteReferral());
    apiClient
      .delete(`${endpoints().referralApi}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        // Table delete data
        dispatch(
          fetchList(
            "newReferrals",
            `${endpoints().referralApi}/search`,
            params.currentPage || 1,
            10,
            {
              pagination: true,
              search: searchItem,
              status: REFERRAL_STATUS_NEW,
              sort: params.newReferralsSort,
              sortDir: params.newReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus,
              partnerId: params.partnerId || getParamsByName("partnerId")
            }
          )
        );
        dispatch(
          fetchList(
            "approvedReferrals",
            `${endpoints().referralApi}/search`,
            1,
            10,
            {
              pagination: true,
              status: REFERRAL_STATUS_APPROVED,
              search: searchItem,
              sort: params.approvedReferralsSort,
              sortDir: params.approvedReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus
            }
          )
        );
        dispatch(
          fetchList(
            "declinedReferrals",
            `${endpoints().referralApi}/search`,
            params.currentPage || 1,
            10,
            {
              pagination: true,
              search: searchItem,
              status: REFERRAL_STATUS_DECLINED,
              sort: params.declinedReferralsSort,
              sortDir: params.declinedReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus
            }
          )
        );
        dispatch(
          fetchList(
            "allReferrals",
            `${endpoints().referralApi}/search`,
            params.currentPage || 1,
            10,
            {
              pagination: true,
              search: searchItem,
              sort: params.allReferralsSort,
              sortDir: params.allReferralsSortDir,
              startDate: params.startDate,
              endDate: params.endDate,
              status: params.currentStatus,
              partnerId: params.partnerId || ""
            }
          )
        );
        dispatch(receiveDeleteReferral());
      })
      .catch(error => {
        dispatch(referralDeleteError(error));
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(errorMessage);
        console.error(errorMessage);
      });
  };
}
