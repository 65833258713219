import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader, Input } from "reactstrap";

// Components
import { WarningRedIcon } from "../../../../assets/img/icons";

import { REFERRAL_STATUS_APPROVED } from "../../../Constants";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

const ApproveConfirmationModal = props => {
  const {
    isApproveConfirmationModalOpen,
    toggleApproveConfirmation,
    setApproveConfirmationModalValues,
    updateReferralByStatus
  } = props;
  return (
    <>
      <Modal
        isOpen={isApproveConfirmationModalOpen}
        className={["log-hours-modal", "edit-task-modal"].join(" ")}
      >
        <ModalHeader
          toggle={() => {
            toggleApproveConfirmation();
          }}
        >
          <WarningRedIcon />
          <h4 className={["font-weight-bold", "mt-3"].join(" ")}>
            There is an another {Terminology.get(SystemName.REFERRAL)} conflicts
            this.
          </h4>
        </ModalHeader>
        <ModalBody className={["text-center", "mb-4"].join(" ")}>
          <div className="form-wrapper">
            <div className="field-wrapper">
              <h6 className="mx-4">
                Are you sure, you want to approve this{" "}
                {Terminology.get(SystemName.REFERRAL)} ?
              </h6>
            </div>
          </div>
        </ModalBody>
        <ModalFooter
          className={["justify-content-center", "border-top-0"].join(" ")}
        >
          <div className="btn-wrapper">
            <button
              className="btn btn-outline-secondary px-3"
              onClick={() => {
                toggleApproveConfirmation();
              }}
            >
              No
            </button>
            <button
              className="btn btn-success px-3"
              onClick={() => {
                setApproveConfirmationModalValues({
                  isApproveConfirmationModalOpen: false
                });
                updateReferralByStatus(REFERRAL_STATUS_APPROVED);
              }}
            >
              Yes, Approve
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default ApproveConfirmationModal;
