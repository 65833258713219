import React, { useState } from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ReduxTable, {
  ReduxColumn
} from "../../../../../components/base/ReduxTable";

import { endpoints } from "../../../../../configs";
import Button from "../../../../../components/base/Button";
import { apiClient } from "../../../../../apiClient";
import Toast from "../../../../../components/base/Toast";
import { isBadRequest } from "../../../../../common/http";

const SettingModel = ({ isOpen, toggle, title, companyId, history }) => {
  const [ids, setIds] = useState([]);

  // Bulk update handler
  const handleBulkUpdate = ids => {
    setIds(ids);
  };

  const handleUpdate = async () => {
    try {
      const data = new FormData();
      data.append("selectedIds", ids);
      await apiClient
        .put(
          `${endpoints().companyAPI}/updateDefaultSetting/${companyId}`,
          data
        )
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
            toggle();
          }
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
              Toast.error(errorMessage);
            }
            console.error(errorMessage);
          }
        });
    } catch (err) {
      console.log(err);
    }
  };

  const sortByOptions = [
    {
      value: "name:ASC",
      label: "Name"
    },
    {
      value: "createdAt:DESC",
      label: "Most Recent"
    }
  ];

  return (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        backdrop="static"
        className="edit-task-modal expert-modal"
        size="xl"
      >
        <ModalHeader toggle={toggle}>
          <h4 className={["font-weight-bold"].join(" ")}>{title}</h4>
        </ModalHeader>

        <ModalBody
          className={["text-center", "mb-4"].join(" ")}
          style={{ maxHeight: "70vh" }}
        >
          <div>
            <ReduxTable
              bulkSelect
              newTableHeading
              showHeader
              onBulkSelect={e => {
                handleBulkUpdate(e);
              }}
              id="settings"
              apiURL={`${endpoints().settingAPI}/defaultSetting/${companyId}`}
              paramsToUrl={true}
              isOnCheckIntegerValue={true}
              history={history}
              sortByOptions={sortByOptions}
              searchPlaceholder="Search"
              showTypeFilter
            >
              <ReduxColumn
                field="name"
                minWidth={"100px"}
                maxWidth={"100px"}
                sortBy="name"
                className="text-left"
              >
                Name
              </ReduxColumn>
              <ReduxColumn
                field="defaultValue"
                minWidth={"100px"}
                maxWidth={"100px"}
                disableOnClick
              >
                Default Value
              </ReduxColumn>
              <ReduxColumn
                field="companyValue"
                minWidth={"100px"}
                maxWidth={"100px"}
                disableOnClick
              >
                Company Value
              </ReduxColumn>
            </ReduxTable>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <Button
              label="Update Settings"
              onClick={() => {
                handleUpdate();
              }}
            />
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default SettingModel;
