import React from "react";
import toast from "../../../../components/base/Toast";

// Assets
import { SportVestIcon } from "../../../../assets/img/icons";

// Component
import NoRecordsFound from "../../../../components/base/NoRecordsFound";
import Form from "../../../../components/base/Form";
import Email from "../../../../components/base/Email";

import TeamMember from "./inner-component/TeamMember";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

import {
  COMPANY_ADMIN_ROLE,
  COMPANY_MANAGER_ROLE,
  ROLE_COMPANY_ADMIN_TEXT,
  COMPANY_MANAGER_ROLE_TEXT
} from "../../../../roles/Constants";
import { isBadRequest } from "../../../../common/http";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

class CompanyTeam extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInviting: false,
      errorMessage: "",
      successMessage: ""
    };
  }

  // Handle Parter User Invite
  handleSubmit = data => {
    const { companyId } = this.props;
    this.setState({ isInviting: true });
    return apiClient
      .post(`${endpoints().companyAPI}/user/invite`, {
        companyId,
        email: data.email,
        reInvite: data.reInvite ? data.reInvite : false
      })
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          if (this.props.getCompanyUsers) {
            this.props.getCompanyUsers();
          }
        }
        this.setState({ successMessage, errorMessage: "" });
        toast.success(successMessage, { containerId: "Team-Member-toast" });
        this.setState({ isInviting: false });
        data.email = "";
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          if (
            data.isResend &&
            error.response.data.message ===
              "It looks like that user has already been invited"
          ) {
            toast.success("User Invited Successfully", {
              containerId: "Team-Member-toast"
            });
          } else {
            this.setState({
              errorMessage: error.response.data.message,
              successMessage: ""
            });
            toast.error(error.response.data.message, {
              containerId: "Team-Member-toast"
            });
          }
        }
      });
  };

  // Handle User Role
  handleUserRole = (value, member) => {
    const role =
      value === ROLE_COMPANY_ADMIN_TEXT
        ? COMPANY_ADMIN_ROLE
        : COMPANY_MANAGER_ROLE;

    return apiClient
      .put(`${endpoints().companyAPI}/user/role/${member.id}`, { role })
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage, { containerId: "Team-Member-toast" });
          if (this.props.getCompanyUsers) {
            this.props.getCompanyUsers();
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message, {
            containerId: "Team-Member-toast"
          });
        }
      });
  };

  // Remove team member
  handleUserRemove = member => {
    let id = member.id;
    const { companyId } = this.props;

    return apiClient
      .delete(`${endpoints().companyAPI}/user/${id}?companyId=${companyId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage, { containerId: "Team-Member-toast" });
          if (this.props.getCompanyUsers) {
            this.props.getCompanyUsers();
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message, {
            containerId: "Team-Member-toast"
          });
          console.error(errorMessage);
        }
      });
  };

  render() {
    const { members } = this.props;
    const { errorMessage } = this.state;

    const initialValues = {
      email: ""
    };

    // Customer User Role Options
    const userRoles = [
      {
        value: COMPANY_ADMIN_ROLE,
        label: ROLE_COMPANY_ADMIN_TEXT
      },
      {
        value: COMPANY_MANAGER_ROLE,
        label: COMPANY_MANAGER_ROLE_TEXT
      }
    ];

    return (
      <>
        <div className="marketplace-section-content">
          <div className="tab-content-wrapper">
            <div className="add-team-member d-flex flex-column align-items-center text-center">
              <div className="img-wrapper mb-4">
                <SportVestIcon />
              </div>
              <h4 className="mb-2">Add Team Members</h4>
              <p className="mb-4">
                Team members have access to the company portal and will be able
                to manage {Terminology.get(SystemName.REFERRALS)} and Company
                Resources.
              </p>
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  values.email = values.email ? values.email : null;
                  this.handleSubmit(values);
                  return false;
                }}
              >
                <div
                  className="form-wrapper w-300"
                  style={{
                    width: "400px"
                  }}
                >
                  <div className="field-wrapper text-center d-flex flex-column">
                    <Email
                      className="w-100"
                      name="email"
                      placeholder="Email Address"
                      required
                    />
                    {errorMessage && (
                      <small
                        className={errorMessage ? "text-danger" : ""}
                      ></small>
                    )}
                  </div>

                  <div className="btn-wrapper mt-4">
                    <button
                      type="submit"
                      className={["btn", "btn-secondary"].join(" ")}
                    >
                      Send Invitation
                    </button>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
        {/* Team Members Section*/}
        <div className="marketplace-section-content pt-3 pb-3">
          <div className="tab-content-wrapper">
            <div className="team-members">
              {members && members.length > 0 ? (
                members.map((member, index) => (
                  <TeamMember
                    member={member}
                    index={index}
                    userRoles={userRoles}
                    handleUserRole={this.handleUserRole}
                    removeTeamMember={this.handleUserRemove}
                    resendInvite={this.handleSubmit}
                  />
                ))
              ) : (
                <NoRecordsFound
                  showMessage="false"
                  message="No team members"
                  position="top"
                  hideCard={true}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default CompanyTeam;
