import React from "react";
import toast from "../../components/base/Toast";

// Components
import Form from "../../components/base/Form";
import Select from "../../components/base/Select";
import Email from "../../components/base/Email";
import SecondaryButton from "../../components/base/SecondaryButton";
import SaveButton from "../../components/base/SaveButton";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";
import { isBadRequest } from "../../common/http";

export default class AddUserForm extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      initialValues: {
        email: "",
        role: ""
      },
      userId: "",
      customerId: "",
      isLoading: false,
      count: "",
      data: []
    };
  }

  componentDidMount() {
    this.loadList();
  }

  isEmailExist = values => {
    const data = this.state.data.find(data => data.email === values.email);
    if (!data) return false;

    this.setState({
      userId: data.id,
      customerId: this.props.customerId
    });

    return true;
  };

  _submit = values => {
    if (!this._validateFields(values)) {
      return;
    }

    //Check email is exist
    const success = this.isEmailExist(values);
    if (!success) {
      toast.error("Email not found");
      return;
    }

    // Add customer user form in the final..
    this._createCustomerUser(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;
    const email = values.email;
    const role = values.role;

    if (!email || !role) {
      success = false;
    }

    return success;
  }

  // To Array
  _toArray(values) {
    const data = {
      role: values.role.value,
      userId: this.state.userId,
      customerId: this.state.customerId
    };

    return data;
  }

  // Customer user API Call
  _createCustomerUser(data) {
    return apiClient
      .post(endpoints().customerUserAPI, data)
      .then(() => {
        this.props.toggle();
        window.location.reload();
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }

  loadList(pageSize) {
    this.setState({ isLoading: true }, () => {
      apiClient
        .get(
          `${endpoints().userAPI}/search?pageSize=${pageSize ? pageSize : 10}`
        )
        .then(response => {
          const data = response.data;
          this.setState({
            isLoading: false,
            count: !data ? [] : data.totalCount,
            data: !data ? [] : data.data
          });
        });
    });
  }

  render() {
    // Role options
    const roleOptions = [
      {
        value: "Admin",
        label: "Admin"
      },
      {
        value: "Customer",
        label: "Customer"
      },
      {
        value: "Expert",
        label: "Expert"
      }
    ];

    return (
      <Form
        initialValues={this.state.initialValues}
        onSubmit={values => {
          this._submit(values);
        }}
      >
        <div className="ml-3 mr-3 mt-2 mb-3">
          <div>
            <Email
              name="email"
              label="Email"
              placeholder="Enter email"
              required={true}
              error=""
            />
          </div>

          <div>
            <Select
              name="role"
              label="Role"
              options={roleOptions}
              placeholder="Select role"
              error="Role is required"
              required
              isSearchable={true}
            />
          </div>
          <SecondaryButton onClick={this.props.toggle} label="Cancel" />
          <SaveButton />
        </div>
      </Form>
    );
  }
}
