import React from "react";
import { Modal, Button, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

//Components
import useToggle from "../components/customhooks/useToggle";
import Form from "../components/base/Form";
import TextArea from "../components/base/TextArea";
import toast from "../components/base/Toast";
import Email from "../components/base/Email";
import Text from "../components/base/Text";
import Phone from "../components/base/Phone";
import CancelButton from "../components/base/CancelButton";

// Helper
import { toString, removeMaskedPhoneNumber } from "../lib/helper";
import { LEAD_SOURCE_PARTNER_CONTACT } from "../lead/Constants";
import { getCookie } from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

// API Configs
import { DEFAULT_API_KEY } from "../configs";
import { isBadRequest } from "../common/http";
import { endpoints } from "../configs";
import { apiClient } from "../apiClient";

const ContactUsModal = ({ isOpen, toggle, partnerId }) => {
  const handleSubmit = values => {
    values.source = LEAD_SOURCE_PARTNER_CONTACT;
    // If dont have session send default API key
    if (!getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    apiClient
      .post(`${endpoints().lead}`, values)
      .then(response => {
        toggle();
        toast.success(response.data.message);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  // To Array
  const _toArray = data => {
    let values = { ...data };
    values.name = toString(values.name);
    values.email = toString(values.email);
    values.phone = toString(
      values.phone && removeMaskedPhoneNumber(values.phone)
    );
    values.companyName = toString(values.companyName);
    values.notes = toString(values.notes);
    values.source = toString(LEAD_SOURCE_PARTNER_CONTACT);
    values.partnerId = partnerId;

    return values;
  };

  return (
    <Modal isOpen={isOpen} toggle={toggle} backdrop="static">
      <Form
        initialValues={{
          name: "",
          email: "",
          phone: "",
          companyName: "",
          description: "",
          source: "",
          notes: ""
        }}
        onSubmit={values => {
          handleSubmit(_toArray(values));
        }}
      >
        <ModalHeader className="justify-content-center" toggle={toggle}>
          <div className={["d-flex", "flex-column"].join(" ")}>
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
              Contact Partner
            </h4>
          </div>
        </ModalHeader>
        <ModalBody className={["mb-4", "px-0"].join(" ")}>
          <div className={["text-center", "modal-body-subtitle"].join(" ")}>
            <p>
              Enter your contact information below and a representative from
              this company will be in touch with you shortly.
            </p>
          </div>
          <div className="form-wrapper justify-content-center d-flex flex-column">
            <div className="field-wrapper">
              <Text
                name="name"
                label="Name"
                placeholder="Enter your name"
                required={true}
                error=""
              />
            </div>
            <div className="field-wrapper">
              <Email
                name="email"
                label="Email Address"
                placeholder="Enter your email address"
                required={true}
                error=""
              />
            </div>
            <div className="field-wrapper">
              <Phone
                name="phone"
                label="Phone Number"
                placeholder="Enter phone number"
                error=""
                required
              />
            </div>
            <div className="field-wrapper">
              <Text
                name="companyName"
                label="Company Name"
                placeholder="Enter company name"
                required={true}
                error=""
              />
            </div>
            <div className="field-wrapper">
              <TextArea
                name="notes"
                label="Description"
                placeholder="Enter description"
                required={true}
                error="Please enter description"
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <CancelButton onClick={toggle} />
            <Button type="submit">Contact</Button>
          </div>
        </ModalFooter>
      </Form>
    </Modal>
  );
};

const ContactPartnerForm = ({
  partnerId,
  formFields,
  contactUsFormId,
  label
}) => {
  const [modal, setModal] = useToggle();

  return (
    <div>
      <Button
        className="btn text-white"
        size="lg"
        color="warning"
        onClick={() => setModal(true)}
      >
        {label ? label : "Contact Partner"}
      </Button>

      <ContactUsModal
        isOpen={modal}
        toggle={setModal}
        partnerId={partnerId}
        formFields={formFields}
        contactUsFormId={contactUsFormId}
      />
    </div>
  );
};

export default ContactPartnerForm;
