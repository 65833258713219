import { apiClient } from "../apiClient";
import { endpoints } from "../configs";
import toast from "../components/base/Toast";
import { fetchList } from "./table";
import { isBadRequest } from "../common/http";

/**
 * Delete salesforce lead field mapping
 *
 * @param id
 * @returns {function(*): *}
 */
export function deleteSalesforceSyncRule(id, params) {
  return dispatch => {
    apiClient
      .delete(`${endpoints().salesforceSyncRuleRoute}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "salesforceSyncRule",
            `${endpoints().salesforceSyncRuleRoute}`,
            1,
            10,
            { ...params, pagination: true }
          )
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };
}
/**
 * Create salesforce lead field mapping
 *
 * @param data
 * @returns {function(*): Promise<AxiosResponse<any>>}
 */
export function addSalesforceSyncRule(data, params, toggle) {
  return dispatch => {
    return apiClient
      .post(`${endpoints().salesforceSyncRuleRoute}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          toggle();
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "salesforceSyncRule",
            `${endpoints().salesforceSyncRuleRoute}`,
            1,
            10,
            { ...params, pagination: true }
          )
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Update salesforce lead field mapping details
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateSalesforceSyncRule(id, data, params, toggle) {
  return dispatch => {
    apiClient
      .put(`${endpoints().salesforceSyncRuleRoute}/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage);
          toggle();
        }
      })
      .then(() => {
        dispatch(
          fetchList(
            "salesforceSyncRule",
            `${endpoints().salesforceSyncRuleRoute}`,
            1,
            10,
            params
          )
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}
