import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// Constants
import {
  INTEGRATION_ACCOUNT_MAPPING_SUB_TAB,
  INTEGRATION_ACCOUNT_MAPPING_URL_TAB,
  INTEGRATION_ANALYCTICS_SUB_TAB,
  INTEGRATION_CHAT_SUB_TAB,
  INTEGRATION_CRMS_SUB_TAB,
  INTEGRATION_PAYMENTS_SUB_TAB,
  INTEGRATION_SINGLE_SIGN_ON_SUB_TAB,
  INTEGRATION_COLLABORATION_SUB_TAB,
  TAB_INTEGRATION
} from "../../../../setting/Constants";

// Sub Tab  Sections
import AnalyticsSection from "./AnalyticsSection";
import CRMsSection from "./CRMsSection";
import ChatSection from "./ChatSection";
import AccountMappingSection from "./AccountMappingSection";
import CollaborationSection from "./CollaborationSection";
import PaymentsCategorySection from "./PaymentsCategorySection";
import SingleSignOn from "./SingleSignOn";

const IntegrationTab = props => {
  const {
    activeSubTab,
    activeSection,
    settings,
    adminSettings,
    saveSettings,
    salesforceSettings,
    billDotComSettings,
    toggleTabs,
    history,
    AccountObjectFieldList,
    OpportunityObjectFieldList,
    connectHubspot,
    HubSpotFieldList
  } = props;

  return (
    <>
      {!activeSection && (
        <>
          <Nav tabs className="admin-tabs">
            {/* CRMs Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeSubTab === INTEGRATION_CRMS_SUB_TAB
                })}
                onClick={() => {
                  toggleTabs(TAB_INTEGRATION, INTEGRATION_CRMS_SUB_TAB);
                }}
              >
                {INTEGRATION_CRMS_SUB_TAB}
              </NavLink>
            </NavItem>

            {/* Analytics Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeSubTab === INTEGRATION_ANALYCTICS_SUB_TAB
                })}
                onClick={() => {
                  toggleTabs(TAB_INTEGRATION, INTEGRATION_ANALYCTICS_SUB_TAB);
                }}
              >
                {INTEGRATION_ANALYCTICS_SUB_TAB}
              </NavLink>
            </NavItem>

            {/* Chat Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeSubTab === INTEGRATION_CHAT_SUB_TAB
                })}
                onClick={() => {
                  toggleTabs(TAB_INTEGRATION, INTEGRATION_CHAT_SUB_TAB);
                }}
              >
                {INTEGRATION_CHAT_SUB_TAB}
              </NavLink>
            </NavItem>

            {/* Account Mapping Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeSubTab === INTEGRATION_ACCOUNT_MAPPING_URL_TAB
                })}
                onClick={() => {
                  toggleTabs(
                    TAB_INTEGRATION,
                    INTEGRATION_ACCOUNT_MAPPING_URL_TAB
                  );
                }}
              >
                {INTEGRATION_ACCOUNT_MAPPING_SUB_TAB}
              </NavLink>
            </NavItem>

            {/* Payments Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeSubTab === INTEGRATION_PAYMENTS_SUB_TAB
                })}
                onClick={() => {
                  toggleTabs(TAB_INTEGRATION, INTEGRATION_PAYMENTS_SUB_TAB);
                }}
              >
                {INTEGRATION_PAYMENTS_SUB_TAB}
              </NavLink>
            </NavItem>

            {/* Single Sign on Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeSubTab === INTEGRATION_SINGLE_SIGN_ON_SUB_TAB
                })}
                onClick={() => {
                  toggleTabs(
                    TAB_INTEGRATION,
                    INTEGRATION_SINGLE_SIGN_ON_SUB_TAB
                  );
                }}
              >
                {INTEGRATION_SINGLE_SIGN_ON_SUB_TAB}
              </NavLink>
            </NavItem>

            {/* Collaboration Tab */}
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeSubTab === INTEGRATION_COLLABORATION_SUB_TAB
                })}
                onClick={() => {
                  toggleTabs(
                    TAB_INTEGRATION,
                    INTEGRATION_COLLABORATION_SUB_TAB
                  );
                }}
              >
                {INTEGRATION_COLLABORATION_SUB_TAB}
              </NavLink>
            </NavItem>
          </Nav>
        </>
      )}
      <TabContent activeTab={activeSubTab}>
        {/* CRMs Tab */}
        <TabPane tabId={INTEGRATION_CRMS_SUB_TAB} className="w-100">
          <CRMsSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            salesforceSettings={salesforceSettings}
            saveSettings={saveSettings}
            history={history}
            AccountObjectFieldList={AccountObjectFieldList}
            OpportunityObjectFieldList={OpportunityObjectFieldList}
            connectHubspot={connectHubspot}
            HubSpotFieldList={HubSpotFieldList}
          />
        </TabPane>
        {/* Analytics Tab */}
        <TabPane tabId={INTEGRATION_ANALYCTICS_SUB_TAB} className="w-100">
          <AnalyticsSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        {/* Chat Tab */}
        <TabPane tabId={INTEGRATION_CHAT_SUB_TAB} className="w-100">
          <ChatSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            settings={settings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        {/* Account Mapping Tab */}
        <TabPane tabId={INTEGRATION_ACCOUNT_MAPPING_URL_TAB} className="w-100">
          <AccountMappingSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        {/* Payments Tab */}
        <TabPane tabId={INTEGRATION_PAYMENTS_SUB_TAB} className="w-100">
          <PaymentsCategorySection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            billDotComSettings={billDotComSettings}
            history={history}
          />
        </TabPane>

        {/* Single Sign on Tab */}
        <TabPane tabId={INTEGRATION_SINGLE_SIGN_ON_SUB_TAB} className="w-100">
          <SingleSignOn
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            history={history}
          />
        </TabPane>

        {/* Others Tab */}
        <TabPane tabId={INTEGRATION_COLLABORATION_SUB_TAB} className="w-100">
          <CollaborationSection
            activeSection={activeSection}
            adminSettings={adminSettings}
            saveSettings={saveSettings}
            billDotComSettings={billDotComSettings}
            history={history}
          />
        </TabPane>
      </TabContent>
    </>
  );
};

export default IntegrationTab;
