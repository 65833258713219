import React from "react";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";

// Redux
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Actions
import { fetchCourseList } from "../../actions/course";

// Components
import PageTitle from "../../components/base/PageTitle";
import CountBadge from "../../components/base/CountBadge";
import AddButton from "../../components/base/AddButton";
import CourseCardView from "./CourseCard";

import { COURSE_PUBLISHED, COURSE_DRAFT } from "../../course/Constants";

// Helper
import {
  getParamsByName,
  isSuperAdmin,
  isCompanyAdmin,
  isPartner,
  isCompanyManager,
  getKeyValueByObject,
  getSettingMediaUrl
} from "../../lib/helper";
import AddCourseModal from "./AddCourseModal";
import {
  SETTINGS_ENABLE_PARTNER_TRAINING,
  SETTINGS_THEME_LAYOUT,
  SETTING_TRAINING_SOURCE,
  TRAINING_SOURCE_BUILD_IN_TRAINING,
  TRAINING_SOURCE_THIRD_PARTY_TRAINING_SYSTEM,
  TRAINING_THIRD_PARTY_CTA_TEXT,
  TRAINING_THIRD_PARTY_LOGO,
  TRAINING_THIRD_PARTY_SYSTEM_NAME,
  TRAINING_THIRD_PARTY_URL
} from "../../setting/Constants";
import { Link } from "react-router-dom";
import Avatar from "../../components/base/Avatar";

class Course extends React.Component {
  state = {
    searchCount: 0,
    searchParam: "",
    OpenAddModal: false,
    activeTab: COURSE_PUBLISHED,
    pageSizeValue: getParamsByName("pageSize"),
    searchValue: getParamsByName("search")
  };

  componentDidMount() {
    this.setActiveTab();
  }

  // Set Active Tab
  setActiveTab = () => {
    const status = getParamsByName("section") || this.state.activeTab;
    const searchParam = this.state.searchValue ? this.state.searchValue : "";
    const pageSize = this.state.pageSizeValue ? this.state.pageSizeValue : 10;

    this.setState(
      {
        activeTab:
          status === COURSE_PUBLISHED
            ? COURSE_PUBLISHED
            : status === COURSE_DRAFT
            ? COURSE_DRAFT
            : COURSE_PUBLISHED
      },
      () => {
        if (searchParam) {
          this.props.history.push(
            `/course?section=${status}&pagination=true&search=${searchParam}&pageSize=${pageSize}`
          );
        } else {
          this.props.history.push(
            `/course?section=${status}&pageSize=${pageSize}`
          );
        }
      }
    );
  };

  // Toggle Tab
  toggle = tab => {
    this.setState({
      activeTab: tab
    });
    const searchParam = getParamsByName("search") || "";
    let params = [];
    if (searchParam) {
      params.push(`search=${searchParam}`);
    }
    this.props.history.push(
      `/course?section=${tab}&pageSize=${
        this.state.pageSizeValue ? this.state.pageSizeValue : 12
      }&${params.join("&")}`
    );
  };

  render() {
    const { activeTab, OpenAddModal } = this.state;
    const { course, settings } = this.props;
    const search = window.location.search;
    const params = new URLSearchParams(search);
    const searchStatus = params.get("section");

    const isPublished = searchStatus
      ? searchStatus === COURSE_PUBLISHED
      : activeTab === COURSE_PUBLISHED;
    const isDraft = searchStatus
      ? searchStatus === COURSE_DRAFT
      : activeTab === COURSE_DRAFT;

    const enableTraining = settings
      ? getKeyValueByObject(settings, SETTINGS_ENABLE_PARTNER_TRAINING)
      : "";
    const thirdPartySystemName = settings
      ? getKeyValueByObject(settings, TRAINING_THIRD_PARTY_SYSTEM_NAME)
      : "";
    const trainingThirdPartySystemText = settings
      ? getKeyValueByObject(settings, TRAINING_THIRD_PARTY_CTA_TEXT)
      : "";
    const trainingSystem = settings
      ? getKeyValueByObject(settings, SETTING_TRAINING_SOURCE)
      : "";

    const trainingThirdPartyUrl = settings
      ? getKeyValueByObject(settings, TRAINING_THIRD_PARTY_URL)
      : "";
    const trainingThirdPartyLogo =
      settings && getKeyValueByObject(settings, TRAINING_THIRD_PARTY_LOGO)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, TRAINING_THIRD_PARTY_LOGO)
          )
        : "";
    const DefaultLayout = settings
      ? getKeyValueByObject(settings, SETTINGS_THEME_LAYOUT)
      : "";

    return (
      <div className="text-break">
        {/* page heading */}
        <div className="d-flex justify-content-between">
          <PageTitle
            label={
              isPartner(this.props.roleId)
                ? "Course"
                : "Partner Training System"
            }
          />
          {(isSuperAdmin(this.props.roleId) ||
            isCompanyAdmin(this.props.roleId) ||
            isCompanyManager(this.props.roleId)) &&
            trainingSystem == TRAINING_SOURCE_BUILD_IN_TRAINING && (
              <div>
                <AddButton
                  onClick={() => this.setState({ OpenAddModal: true })}
                  label="Add Course"
                  className="pull-right btn btn-secondary"
                />
              </div>
            )}
        </div>
        {enableTraining === "true" &&
        (isSuperAdmin(this.props.roleId) ||
          isCompanyAdmin(this.props.roleId) ||
          isCompanyManager(this.props.roleId)) &&
        trainingSystem == TRAINING_SOURCE_THIRD_PARTY_TRAINING_SYSTEM ? (
          <div className="page-heading d-flex justify-content-between align-items-start cover flex-md-row flex-column ">
            <span>
              <img
                src={trainingThirdPartyLogo ? trainingThirdPartyLogo : ""}
                alt="profile"
                style={{
                  height: "140px",
                  width: "180px",
                  objectFit: "cover"
                }}
                className="d-block"
              />
            </span>
            <div className="onboarding-main col-6 mt-3">
              <h5 className="font-weight-bold">{thirdPartySystemName}</h5>
              <p className="font-weight-bold text-inline-grayed">
                {trainingThirdPartySystemText}
              </p>
            </div>
            <div className="onboarding-url pr-4" style={{ maxWidth: "unset" }}>
              <Link target="_blank">
                <button
                  className="btn btn-primary mt-4"
                  onClick={() => {
                    window.open(trainingThirdPartyUrl);
                  }}
                >
                  Go to {thirdPartySystemName}
                </button>
              </Link>
            </div>
          </div>
        ) : isPartner(this.props.roleId) &&
          trainingSystem == TRAINING_SOURCE_BUILD_IN_TRAINING ? (
          <CourseCardView
            activeTab={activeTab}
            DefaultLayout={DefaultLayout}
            course={this.props.course}
            courseDetails={this.props.courseDetails}
            actions={this.props.actions}
            history={this.props.history}
          />
        ) : (
          trainingSystem == TRAINING_SOURCE_BUILD_IN_TRAINING && (
            <div>
              <Nav tabs className="admin-tabs mb-0">
                <NavItem>
                  <NavLink
                    className={classnames({ active: isPublished })}
                    onClick={() => {
                      this.toggle(COURSE_PUBLISHED);
                    }}
                  >
                    Published
                    {course &&
                      course.pagination &&
                      course.pagination.totalCount > 0 && (
                        <CountBadge
                          count={
                            course.pagination
                              ? course.pagination.publishedCourseCount
                              : 0
                          }
                          isActive={isPublished}
                        />
                      )}
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    className={classnames({ active: isDraft })}
                    onClick={() => {
                      this.toggle(COURSE_DRAFT);
                    }}
                  >
                    Draft
                    {course &&
                      course.pagination &&
                      course.pagination.totalCount > 0 && (
                        <CountBadge
                          count={
                            course.pagination
                              ? course.pagination.draftedCourseCount
                              : 0
                          }
                          isActive={isDraft}
                        />
                      )}
                  </NavLink>
                </NavItem>
              </Nav>

              <TabContent activeTab={activeTab}>
                {/*Published Tab*/}
                <TabPane tabId={COURSE_PUBLISHED}>
                  <CourseCardView
                    activeTab={activeTab}
                    DefaultLayout={DefaultLayout}
                    course={this.props.course}
                    courseDetails={this.props.courseDetails}
                    actions={this.props.actions}
                    history={this.props.history}
                  />
                </TabPane>

                {/* Draft Tab */}
                <TabPane tabId={COURSE_DRAFT}>
                  <CourseCardView
                    activeTab={activeTab}
                    DefaultLayout={DefaultLayout}
                    course={this.props.course}
                    courseDetails={this.props.courseDetails}
                    actions={this.props.actions}
                    history={this.props.history}
                  />
                </TabPane>
              </TabContent>
            </div>
          )
        )}
        <AddCourseModal
          fetchCourseList={() => fetchCourseList()}
          course={this.props.course}
          courseDetails={this.props.courseDetails}
          activeTab={activeTab}
          actions={this.props.actions}
          isOpen={OpenAddModal}
          toggle={() => {
            this.setState({ OpenAddModal: false });
          }}
          history={this.props.history}
        />
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    course: state.course,
    courseDetails: state.course ? state.course.data : {},
    roleId: state.user ? state.user.roleId : ""
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ fetchCourseList }, dispatch)
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(Course);
