import React from "react";
import toast from "../../components/base/Toast";
import moment from "moment";

// Components
import Form from "../../components/base/Form";
import CancelButton from "../../components/base/CancelButton";
import PageTitle from "../../components/base/PageTitle";
import Button from "../../components/base/Button";
import AdminActionNavigation from "../../components/base/AdminActionNavigation";
import SelectDropdown from "../../components/base/SelectDropdown";

import { MDF_STATUS_APPROVED, MDF_STATUS_DECLINED } from "./Constants";

// Configs
import { endpoints } from "../../configs";
import { isPartner } from "../../lib/helper";

// API call
import { apiClient, apiClientGet } from "../../apiClient";

// Helper
import { toString, removeMaskedPhoneNumber } from "../../lib/helper";

// Page Components
import MdfRequestFormFields from "./MdfRequestFormFields";
import SideBar from "./inner-components/SideBar";

//Assets
import { ChevronRight } from "../../assets/img/icons";

// Constant
import { isBadRequest } from "../../common/http";

import { connect } from "react-redux";

class EditMdfRequest extends React.Component {
  constructor(props) {
    super(props);
    this.reCaptcha = React.createRef();
    // Set the initial input values
    this.state = {
      loading: false,
      eventDate: "",
      mdfSubmit: false,
      mdfDetails: "",
      partnerId: "",
      status: ""
    };
  }

  componentDidMount = () => {
    this._getMdfDetails();
  };

  _submit = values => {
    if (this._validateFields(values) === false) {
      return;
    }

    // Save MDF Request data form
    this.createMdfRequest(this._toArray(values));
  };

  // Validate Fields
  _validateFields(values) {
    let success = true;

    const title = values.title;

    if (!title) {
      success = false;
    }

    return success;
  }

  setDate = (date, stateProperty) => {
    this.setState({ [stateProperty]: date });
  };
  // To Array
  _toArray(values) {
    const { eventDate } = this.state;
    values.title = toString(values.title);
    values.activityType = toString(values.activityType);
    values.reason = toString(values.reason);
    values.expectedResult = toString(values.expectedResult);
    values.requestAmount = toString(values.requestAmount);
    values.requestorFirstName = toString(values.requestorFirstName);
    values.requestorLastName = toString(values.requestorLastName);
    values.requestorContactTitle = toString(values.requestorContactTitle);
    values.requestorContactEmail = toString(values.requestorContactEmail);
    values.requestorContactPhone = values.requestorContactPhone
      ? removeMaskedPhoneNumber(values.requestorContactPhone)
      : "";
    values.notes = toString(values.notes);
    values.eventDate = eventDate ? moment(eventDate).format("YYYY-MM-DD") : "";

    return values;
  }

  createMdfRequest = async data => {
    this.setState({ mdfSubmit: true });
    try {
      const response = await apiClient.put(
        `${endpoints().mdfRequestAPI}/${this.props.match.params.id}`,
        data
      );
      let successMessage;
      if (response && response.data) {
        successMessage = response.data.message;
        toast.success(successMessage);
        this.setState({ mdfSubmit: false });
        this.props.history.goBack();
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        toast.error(error.response.data.message);
        console.error(errorMessage);
        this.setState({ mdfSubmit: false });
      }
    }
  };

  // Account details api call by id
  _getMdfDetails = () => {
    this.setState({ loading: true }, () => {
      apiClientGet(
        `${endpoints().mdfRequestAPI}/${this.props.match.params.id}`,
        (errMessage, response) => {
          if (errMessage) {
            this.setState({ isInvalidRequest: true });
          } else {
            const mdfDetails = response && response.data;
            const partnerId = mdfDetails.partnerId;
            this.setState({
              mdfDetails,
              partnerId,
              eventDate: mdfDetails.eventDate
                ? new Date(mdfDetails.eventDate)
                : "",
              loading: false
            });
          }
        }
      );
    });
  };

  updateMdfRequestArchive = id => {
    return apiClient
      .put(`${endpoints().mdfRequestAPI}/archive/${id}`)
      .then(response => {
        toast.success(response.data.message);
        this.props.history.push("/mdf-requests");
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  // Update mdf By Status
  updateMdfByStatus = (status, id) => {
    let data = { status };
    this.setState({ status: data.status });
    return apiClient
      .put(`${endpoints().mdfRequestAPI}/status/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          this._getMdfDetails();
          toast.success(successMessage);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message);
        }
      });
  };
  handleCancelButton = () => {
    this.props.history.goBack();
  };

  handleAdminAction = value => {
    const { id } = this.props.match.params;
    switch (value) {
      case "Approve Funds":
        return this.updateMdfByStatus(MDF_STATUS_APPROVED, id);
      case "Decline Funds":
        return this.updateMdfByStatus(MDF_STATUS_DECLINED, id);
      case "Archive":
        return this.updateMdfRequestArchive(id);
      default:
        return "";
    }
  };

  render() {
    const { mdfDetails, partnerId } = this.state;
    const initialValues = {
      title: mdfDetails.title || "",
      activityType: mdfDetails.activityType || "",
      reason: mdfDetails.reason || "",
      expectedResult: mdfDetails.expectedResult || "",
      requestAmount: mdfDetails.requestAmount || "",
      eventDate: mdfDetails.eventDate || "",
      requestorFirstName: mdfDetails.requestorFirstName || "",
      requestorLastName: mdfDetails.requestorLastName || "",
      requestorContactTitle: mdfDetails.requestorContactTitle || "",
      requestorContactEmail: mdfDetails.requestorContactEmail || "",
      requestorContactPhone: mdfDetails.requestorContactPhone || "",
      notes: mdfDetails.notes || ""
    };

    const adminActions = [
      {
        value: "Approve Funds",
        label: "Approve Funds"
      },
      {
        value: "Decline Funds",
        label: "Decline Funds"
      },
      {
        value: "Archive",
        label: "Archive"
      }
    ];

    const { eventDate, mdfSubmit, loading } = this.state;

    if (loading) {
      return "";
    }

    return (
      <>
        <React.Fragment>
          <Form
            initialValues={initialValues}
            onSubmit={values => {
              this._submit(values);
            }}
          >
            <AdminActionNavigation>
              <div className="d-flex align-items-center ">
                <a
                  className="cursor-pointer"
                  onClick={() => {
                    this.props.history.push("/mdf-requests");
                  }}
                >
                  MDF
                </a>
                <span
                  className="text-dark d-inline-flex align-items-center"
                  style={{ width: "15px" }}
                >
                  <ChevronRight />
                </span>
                <span
                  onClick={e => e.preventDefault()}
                  className={[
                    "text-dark",
                    "font-weight-bold",
                    "d-inline-flex",
                    "align-items-center",
                    "project-name",
                    "w-320"
                  ].join(" ")}
                >
                  <span className="ellipsis">MDF Request Details</span>
                </span>
              </div>
              {!isPartner(this.props.roleId) && (
                <>
                  <div className="d-flex pull-right justify-content-between mb-2 align-items-right">
                    <SelectDropdown
                      buttonLabel="Admin Actions"
                      dropdownLinks={adminActions}
                      color={"gray"}
                      hideCaret
                      selectName={"admin_action"}
                      handleChange={this.handleAdminAction}
                    />
                  </div>
                </>
              )}
            </AdminActionNavigation>

            <div className="px-3">
              <PageTitle label="MDF Request Details" />
            </div>

            <div className="card border-0">
              <div className="project-details-body">
                <div className="details-body-main">
                  <div className="card-body">
                    <MdfRequestFormFields
                      setDate={this.setDate.bind(this)}
                      eventDate={eventDate}
                    />
                  </div>
                </div>
                <SideBar
                  status={mdfDetails.status}
                  mdfDetails={mdfDetails}
                  mdfRequestId={this.props.match.params.id}
                  partnerId={partnerId}
                  getMdfDetails={this._getMdfDetails}
                />
              </div>
            </div>
            <div className="d-flex justify-content-between mb-2 align-items-right">
              <CancelButton
                className="ml-auto mr-1"
                onClick={this.handleCancelButton}
              />
              <Button
                label="Save"
                type="submit"
                className="btn btn-primary"
                disabled={mdfSubmit}
              />
            </div>
          </Form>
        </React.Fragment>
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(EditMdfRequest);
