import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";

// Components
import CancelButton from "../../../components/base/CancelButton";
import Form from "../../../components/base/Form";
import Number from "../../../components/base/Number";

const VerifyAchModal = ({
  isOpen,
  toggle,
  paymentOption,
  verifyPaymentMethod
}) => {
  const handleSubmit = values => {
    verifyPaymentMethod({
      id: paymentOption.id,
      withdrawnAmounts: [+values.withdrawalOne, +values.withdrawalTwo]
    });
    toggle();
  };
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={["edit-task-modal", "edit-billing-modal"].join(" ")}
    >
      <ModalHeader toggle={toggle} tag={"div"}>
        <h4 className={["font-weight-bold", "mt-2"].join(" ")}>Add ACH</h4>
      </ModalHeader>
      <ModalBody>
        <div className="content">
          <p>
            Please input below, both of the amounts withdrawn from your account
            in order to verify your ACH Payment Option
          </p>
        </div>
        <div className="form-wrapper">
          <Form
            initialValues={{
              withdrawalOne: "",
              withdrawalTwo: ""
            }}
            onSubmit={values => handleSubmit(values)}
          >
            <div className="form-wrapper">
              <div className="field-wrapper">
                <Number
                  name="withdrawalOne"
                  label="First Withdrawn Amount"
                  placeholder="Enter First Withdrawn Amount"
                  error={""}
                  required
                />
              </div>
              <div className="field-wrapper">
                <Number
                  name="withdrawalTwo"
                  label="Second Withdrawn Amount"
                  placeholder="Enter Second Withdrawn Amount"
                  error={""}
                  required
                />
              </div>
            </div>
            <div className="btn-wrapper mt-4 d-flex justify-content-center">
              <CancelButton onClick={toggle} className="mr-2" />
              <button
                type="submit"
                className="btn btn-secondary h6-5-important m-1"
                onContextMenu={e => e.preventDefault()}
              >
                <span>Verify Payment Method</span>
              </button>
            </div>
          </Form>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default VerifyAchModal;
