import React, { Component } from "react";
import PlaybookCard from "../../components/PlaybookCard";
import ShowMoreContent from "../../components/base/showMoreContent";

export default class CourseViewCard extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { data, bannerImageUrl } = this.props;
    return (
      <>
        <div className="row">
          <div className=" col-md-4 img-wrapper mb-n4">
            <PlaybookCard
              bannerImageUrl={bannerImageUrl || data.banner_image}
              data={data}
              isPreview={"true"}
              size={"project-playbook-card"}
              tag={"div"}
              showCardBody={false}
              fullWidth
            />
          </div>
          <div className="col-md-8 mt-3">
            <div className="">
              <p>
                <b>{data.title}</b>
              </p>
              <ShowMoreContent content={data.description} />
            </div>
          </div>
        </div>
      </>
    );
  }
}
