import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownItem
} from "reactstrap";
import { Field } from "formik";

// Actions
import {
  addReferralLink,
  updateReferralLink,
  deleteReferralLink,
  addReferralLinkParameters,
  updateReferralLinkParameters,
  deleteReferralLinkParameters
} from "../actions/referralLink";

// Components
import Form from "../components/base/Form";
import ToggleSwitch from "../components/base/ToggleSwitch";
import PageTitle from "../components/base/PageTitle";
import AddButton from "../components/base/AddButton";
import ReduxTable, { ReduxColumn } from "../components/base/ReduxTable";
import MoreDropdown from "../components/base/MoreDropdown";
import Text from "../components/base/Text";
import MultiselectCreatable from "../components/base/MultiselectCreatable";

// Config
import { apiClient } from "../apiClient";
import { endpoints } from "../configs";

// Tags
import DeleteModal from "../components/base/DeleteModal";

// Constants
import { SETTINGS_ENABLE_PARTNER_REFERRAL_TRACKING } from "../setting/Constants";
import TorchliteJSSnippet from "./component/torchliteJSSnippet";
import { Link } from "react-router-dom";
import Hint from "../components/base/Hint";
import Terminology from "../lib/Terminology";
import SystemName from "../Constants/SystemName";

const LinksSection = props => {
  const { adminSettings, saveSettings } = props;

  const dispatch = useDispatch();

  // initilize states
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isEditModalOpen, setEditIsModalOpen] = useState(false);
  const [id, setId] = useState("");
  const [paramId, setParamId] = useState("");
  const [destinationURL, setDestinationURL] = useState("");
  const [deleteReferrallinkModal, setdeleteReferrallinkModal] = useState(false);
  const [currentReferrallink, setCurrentReferrallink] = useState(false);
  const [deleteParamsModal, setDeleteParamModal] = useState(false);
  const [currentUtm, setCurrentUtm] = useState(false);
  const [name, setName] = useState("");
  const [linkId, setLinkId] = useState("");
  const [value, setValue] = useState("");
  const [utmMedium, setUtmMedium] = useState("");
  const [utmSource, setUtmSource] = useState("");
  const [utmCampaign, setUtmCampaign] = useState("");
  const [parameterName, setParameterName] = useState("");
  const [parameterValue, setParameterValue] = useState("");
  //Param Name
  const paramName = [
    {
      value: "utm_source_value",
      label: "utm_source"
    },
    {
      value: "utm_medium_value",
      label: "utm_medium"
    },
    {
      value: "utm_campaign_value",
      label: "utm_campaign"
    }
  ];

  // UTM source Values
  const utm_source = [
    {
      value: "PARTNER_NAME",
      label: "PARTNER_NAME"
    },
    {
      value: utmSource === "PARTNER_NAME" ? "" : utmSource,
      label: utmSource === "PARTNER_NAME" ? "" : utmSource
    }
  ];

  // UTM medium Values
  const utm_medium = [
    {
      value: "PARTNER_NAME",
      label: "PARTNER_NAME"
    },
    {
      value: utmMedium === "PARTNER_NAME" ? "" : utmMedium,
      label: utmMedium === "PARTNER_NAME" ? "" : utmMedium
    }
  ];

  // UTM campaign Values
  const utm_campaign = [
    {
      value: "PARTNER_NAME",
      label: "PARTNER_NAME"
    },
    {
      value: utmCampaign === "PARTNER_NAME" ? "" : utmCampaign,
      label: utmCampaign === "PARTNER_NAME" ? "" : utmCampaign
    }
  ];

  // UTM Paramter Values
  const initailPartnerName = [
    {
      value: "PARTNER_NAME",
      label: "PARTNER_NAME"
    },
    {
      value: parameterValue === "PARTNER_NAME" ? "" : parameterValue,
      label: parameterValue === "PARTNER_NAME" ? "" : parameterValue
    }
  ];
  // Toggle Create modal
  const _createModaltoggle = defaultLinkId => {
    setName("");
    setValue("");
    setIsOpen(!isOpen);
    setId(defaultLinkId || "");
  };

  // Toggle modal
  const _editModaltoggle = defaultLinkId => {
    setName("");
    setValue("");
    setUtmMedium("");
    setUtmSource("");
    setUtmCampaign("");
    setIsModalOpen(!isModalOpen);
    setId(defaultLinkId || "");
  };

  // Toggle Edit Params modal
  const _editParamModalToggle = () => {
    setParamId("");
    setParameterName("");
    setParameterValue("");
    setEditIsModalOpen(false);
  };

  const {
    enable_partner_referral_tracking,
    referral_link_base_url
  } = adminSettings;

  // Referral Settings Links Section Initial Values
  const referralSettingsLinksSectionInitialValues = {
    enable_partner_referral_tracking:
      enable_partner_referral_tracking === "true"
  };

  // Handle enable referral
  const handleEnableReferral = enable_partner_referral_tracking => {
    const data = new FormData();

    if (enable_partner_referral_tracking !== undefined) {
      data.append(
        "enable_partner_referral_tracking",
        enable_partner_referral_tracking
      );
    }

    // Save settings
    saveSettings(data);
  };

  // Handle enable referral
  const submit = values => {
    const data = new FormData();

    // Save settings
    saveSettings(data);
  };

  /**
   * Create referral Link
   *
   * @param data
   */
  const referralLinkCreate = data => {
    dispatch(addReferralLink(data, {}));
  };

  /**
   * Update referral Link
   *
   * @param data
   * @param id
   */
  const referralLinkUpdate = data => {
    dispatch(updateReferralLink(id, data, {}));
  };

  /**
   * Create referral Link Parameters
   *
   * @param data
   * @param id
   */
  const createReferralLinkParams = data => {
    dispatch(addReferralLinkParameters(id, data, {}));
  };

  /**
   * Create referral Link Parameters
   *
   * @param data
   * @param id
   */
  const updateReferralLinkParams = data => {
    dispatch(updateReferralLinkParameters(id, paramId, data, {}));
    setParamId("");
  };

  /**
   * Create Delete referral Link Parameters
   *
   * @param id
   */
  const deleteReferralLinkParams = () => {
    dispatch(deleteReferralLinkParameters(currentUtm.id, id, {}));
    setParamId("");
  };

  /**
   * Delete referral Link
   *
   * @param data
   * @param id
   */
  const referralLinkDelete = referralLinkId => {
    dispatch(deleteReferralLink(referralLinkId, {}));
  };

  //Get Parameter Details
  const getParamDetails = async parameterId => {
    return await apiClient
      .get(`${endpoints().referralLinkAPI}/parameter/${parameterId}`)
      .then(response => {
        if (response && response.data && response.data.defaultParameter) {
          const data = response.data.defaultParameter;
          data &&
            data.length > 0 &&
            data.forEach(detail => {
              if (detail.parameter_name === "utm_source") {
                let sourceValue = detail.parameter_value;
                setUtmSource(sourceValue);
              }
              if (detail.parameter_name === "utm_medium") {
                let mediumValue = detail.parameter_value;
                setUtmMedium(mediumValue);
              }
              if (detail.parameter_name === "utm_campaign") {
                let campaignValue = detail.parameter_value;
                setUtmCampaign(campaignValue);
              }
            });
        }
      });
  };

  // Validate Fields
  const _validateFields = (name, value) => {
    let success = true;

    if (!name) {
      success = false;
    }
    if (!value) {
      success = false;
    }

    return success;
  };

  //Handle Save
  const handleUpdate = values => {
    if (_validateFields(values.paramName, values.paramvalue.value) === false) {
      return;
    }

    const data = new FormData();
    data.append("paramId", paramId);
    data.append("name", values.paramName);
    data.append("value", values.paramvalue.value);
    updateReferralLinkParams(data);
    setEditIsModalOpen(false);
  };

  const isModal = () => {
    setEditIsModalOpen(true);
  };

  //Handle Row Click
  const handleRowClick = async row => {
    setLinkId(row.id);
    getParamDetails(row.id).then(() => {
      setIsModalOpen(!isModalOpen);
      setId(row.id || "");
      setName(row.name);
      setDestinationURL(row.destinationURL);
    });
  };

  //Parameter Update
  const handleCreate = values => {
    let data = [];
    data.push({
      name: "utm_campaign",
      value:
        (values.utm_campaign && values.utm_campaign.value === null) || ""
          ? ""
          : values.utm_campaign && values.utm_campaign.value
          ? values.utm_campaign.value
          : ""
    });

    data.push({
      name: "utm_medium",
      value:
        (values.utm_medium && values.utm_medium.value === null) || ""
          ? "null"
          : values.utm_medium && values.utm_medium.value
          ? values.utm_medium.value
          : ""
    });

    data.push({
      name: "utm_source",
      value:
        values.utm_source && values.utm_source.value
          ? values.utm_source.value
          : ""
    });

    if (linkId) {
      data.push({ linkId: linkId });
    }
    if (data) createReferralLinkParams(data);
  };

  //Parameter Create
  const handleParamCreate = values => {
    let name = values.paramName;
    let value = values.paramvalue.value;

    let data = [];
    if (name && value) {
      data.push({ name: name, value: value });
    }
    if (linkId) {
      data.push({ linkId: linkId });
    }
    if (data) createReferralLinkParams(data);
    setEditIsModalOpen(false);
  };

  // Render the referral link Create modal
  const referralLinkModal = () => (
    <div>
      <Modal
        isOpen={isOpen}
        toggle={_createModaltoggle}
        className="landing-create-popup w-100"
        backdrop="static"
      >
        <ModalHeader
          toggle={_createModaltoggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <p>Create Default Link</p>
        </ModalHeader>
        <Form
          initialValues={{
            name: name || "",
            destinationURL: destinationURL || ""
          }}
          onSubmit={values => {
            const data = values;
            referralLinkCreate(data);
            _createModaltoggle();
          }}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div>
              <div className="pb-5">
                <Text
                  name="name"
                  label="Name of Default Link"
                  placeholder="Enter Name"
                  error=""
                  required={true}
                  hintText="This shows up in the partner portal to help partners know the purpose of the personalized link. Example: Link for requesting a demo"
                />
              </div>
              <Text
                name="destinationURL"
                label="Destination URL"
                placeholder="Enter Destination URL"
                error=""
                required={true}
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="">
                  Save
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );

  // Render the Link Edit modal
  const referralLinkEditModal = () => (
    <div>
      <Modal
        isOpen={isModalOpen}
        toggle={_editModaltoggle}
        className="landing-create-popup w-100"
        backdrop="static"
      >
        <ModalHeader
          toggle={_editModaltoggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <p>Edit Default Link</p>
        </ModalHeader>
        <Form
          initialValues={{
            name: name || "",
            destinationURL: destinationURL || "",
            utm_medium: utmMedium
              ? utm_medium.find(mediumValue => mediumValue.value === utmMedium)
              : "" || "",
            utm_campaign: utmCampaign
              ? utm_campaign.find(
                  camapignValue => camapignValue.value === utmCampaign
                )
              : "" || "",
            utm_source: utmSource
              ? utm_source.find(sourceValue => sourceValue.value === utmSource)
              : "" || ""
          }}
          enableReinitialize={true}
          onSubmit={values => {
            const data = values;
            if (data.destinationURL) {
              data.destinationURL = values.destinationURL;
              referralLinkUpdate(data);
              handleCreate(data);
            }
            setTimeout(() => {
              _editModaltoggle();
            }, 100);
          }}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div className="field-wrapper">
              <div className="pb-5">
                <Text
                  name="name"
                  label="Name of Default Link"
                  placeholder="Enter Name"
                  error=""
                  required={true}
                  fontBolded
                  hintText="This shows up in the partner portal to help partners know the purpose of the personalized link. Example: Link for requesting a demo"
                />
              </div>
              <Text
                name="destinationURL"
                label="Destination URL"
                placeholder="Enter Destination URL"
                error=""
                required={true}
                fontBolded
              />
            </div>
            <hr />
            <label className="font-weight-bold">Default Parameters</label>
            <div className="">
              <div className="row">
                <div className="col-5">utm_source</div>
                <div className="col-7">
                  <MultiselectCreatable
                    name="utm_source"
                    placeholder="Enter Value"
                    closeMenuOnSelect={true}
                    isSingleSelect={true}
                    options={utm_source}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">utm_medium</div>
                <div className="col-7">
                  <MultiselectCreatable
                    name="utm_medium"
                    placeholder="Enter Value"
                    closeMenuOnSelect={true}
                    isSingleSelect={true}
                    options={utm_medium}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-5">utm_campaign</div>
                <div className="col-7">
                  <MultiselectCreatable
                    name="utm_campaign"
                    placeholder="Enter Value"
                    closeMenuOnSelect={true}
                    isSingleSelect={true}
                    options={utm_campaign}
                  />
                </div>
              </div>
            </div>
            <hr />
            <div className="form-wrapper row mt-3">
              <div className="col-8 mt-2">
                <label className="font-weight-bold">Custom Parameter</label>
              </div>
              <div className="col-4 pull-right">
                <AddButton
                  label="Add New"
                  className="pull-right btn btn-secondary"
                  onClick={e => {
                    isModal();
                    setParameterName("");
                    setParameterValue("");
                  }}
                />
              </div>
            </div>
            <div className="pt-3">
              <DeleteModal
                isOpen={deleteParamsModal}
                toggle={() => {
                  setDeleteParamModal(false);
                }}
                id={currentUtm.id}
                title="Delete Tag Type"
                deleteFunction={deleteReferralLinkParams}
                label={currentUtm.parameter_name}
              />
              <ReduxTable
                id="link"
                message="No Records found"
                apiURL={
                  isModalOpen
                    ? `${endpoints().referralLinkAPI}/parameter/${linkId}`
                    : ""
                }
                searchDisabled
                showNoRecord
                noRecordFoundHeight={"7vh"}
              >
                <ReduxColumn field="parameter_name">Name</ReduxColumn>
                <ReduxColumn field="parameter_value">Value</ReduxColumn>
                <ReduxColumn
                  field="status"
                  disableOnClick
                  className="action-column"
                  renderField={row => (
                    <div className="text-center landing-group-dropdown">
                      <MoreDropdown>
                        <DropdownItem
                          onClick={() => {
                            isModal();
                            setParamId(row.id);
                            setParameterValue(row.parameter_value);
                            setParameterName(row.parameter_name);
                          }}
                        >
                          Edit
                        </DropdownItem>
                        <DropdownItem
                          className={"text-danger"}
                          onClick={() => {
                            setDeleteParamModal(true);
                            setCurrentUtm(row);
                          }}
                        >
                          Delete
                        </DropdownItem>
                      </MoreDropdown>
                    </div>
                  )}
                >
                  Action
                </ReduxColumn>
              </ReduxTable>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit" label="">
                  Save
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );

  return (
    <>
      {id ? referralLinkEditModal() : referralLinkModal()}

      {/* Parameter Edit Modal */}
      <Modal
        isOpen={isEditModalOpen}
        toggle={_editParamModalToggle}
        className="landing-create-popup w-100"
        backdrop="static"
      >
        <ModalHeader
          toggle={_editParamModalToggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <p>{paramId ? "Edit" : "Add"} Parameter</p>
        </ModalHeader>
        <Form
          initialValues={{
            paramName: parameterName || "",
            paramvalue: parameterValue
              ? initailPartnerName.find(
                  paramvalue => paramvalue.value === parameterValue
                )
              : "" || ""
          }}
          onSubmit={values => {
            paramId ? handleUpdate(values) : handleParamCreate(values);
          }}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div className="field-wrapper">
              <Text
                name="paramName"
                label="Parameter Name"
                placeholder="Enter Name"
                isSearchable={true}
                required
              />
            </div>
            <div className="field-wrapper mt-3">
              <MultiselectCreatable
                name="paramvalue"
                label="Parameter Value"
                placeholder="Enter Value"
                closeMenuOnSelect={true}
                isSingleSelect={true}
                options={initailPartnerName}
                required
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="container-fluid">
              <div className="col-sm-12 text-center">
                <Button type="submit">
                  {paramId && paramName && parameterValue ? "Update" : "Add"}
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      <Form initialValues={referralSettingsLinksSectionInitialValues}>
        <div className={["set-expert-visibility"].join(" ")}>
          <Field
            name={SETTINGS_ENABLE_PARTNER_REFERRAL_TRACKING}
            render={({ field, form }) => {
              return (
                <ToggleSwitch
                  name={SETTINGS_ENABLE_PARTNER_REFERRAL_TRACKING}
                  label1={`Enable Partner ${Terminology.get(
                    SystemName.REFERRAL
                  )} Tracking`}
                  label2={`Enable Partner ${Terminology.get(
                    SystemName.REFERRAL
                  )} Tracking`}
                  value={field.value}
                  handleChange={() => {
                    handleEnableReferral(!field.value);
                    form.setFieldValue(
                      SETTINGS_ENABLE_PARTNER_REFERRAL_TRACKING,
                      !field.value
                    );
                  }}
                  outlined
                />
              );
            }}
          />
        </div>
      </Form>

      <div className="d-flex justify-content-between">
        <PageTitle label="Default Links" />
        <div>
          <AddButton
            label="Add Default Link"
            className="pull-right btn btn-secondary"
            onClick={() => {
              setDestinationURL("");
              _createModaltoggle();
            }}
          />
        </div>
      </div>

      <div className="mt-1 mb-5">
        <ReduxTable
          id="referralLinks"
          apiURL={`${endpoints().referralLinkAPI}/search`}
          onRowClick={row => {
            handleRowClick(row);
          }}
          noRecordFoundHeight={"7vh"}
        >
          <ReduxColumn
            width={"200px"}
            isClickable="true"
            disableOnClick
            renderField={row => (
              <>
                <>
                  <span>{row.name}</span>
                </>
                <br></br>
                <>
                  <Link>{row.destinationURL}</Link>
                </>
              </>
            )}
          >
            Destination URL
          </ReduxColumn>
          <ReduxColumn
            width={"150px"}
            className="text-inline-grayed"
            disableOnClick
            field="utmParameter"
          >
            UTM Parameters
          </ReduxColumn>
          <ReduxColumn width={"120px"} field="createdAt" disableOnClick>
            Date Added
          </ReduxColumn>
          <ReduxColumn
            width={"100px"}
            disableOnClick
            className="action-column"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      handleRowClick(row);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      setCurrentReferrallink(row);
                      setdeleteReferrallinkModal(true);
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          isOpen={deleteReferrallinkModal}
          toggle={() => {
            setdeleteReferrallinkModal(prevState => !prevState);
          }}
          title={`Delete ${Terminology.get(SystemName.REFERRAL)} Link`}
          id={currentReferrallink.id}
          label={currentReferrallink.destinationURL}
          deleteFunction={referralLinkDelete}
        />
      </div>
      <TorchliteJSSnippet />
    </>
  );
};

export default LinksSection;
