import React from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { DropdownItem } from "reactstrap";

// Components
import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";
import MoreDropdown from "../../../../components/base/MoreDropdown";
import AddButton from "../../../../components/base/AddButton";
import Form from "../../../../components/base/Form";
import PageTitle from "../../../../components/base/PageTitle";
import Text from "../../../../components/Text";
import Select from "../../../../components/base/Select";
import SingleCheckbox from "../../../../views/adminSettings/inner-component/Checkbox";
import DeleteModal from "../../../../components/base/DeleteModal";

// Helper
import { toString } from "../../../../lib/helper";

// API call
import { apiClient } from "../../../../apiClient";

// Configs
import { endpoints } from "../../../../configs";

// Tags
import { deleteTag, addTag, updateTag } from "../../../../actions/tags";
import { fetchList } from "../../../../actions/table";

// Constant
import {
  SETTINGS_TAG_IS_DEFAULT,
  SETTINGS_TAG_IS_SEARCHABLE
} from "../../../../tagType/Constants";
import { isBadRequest } from "../../../../common/http";
import Spinner from "../../../../components/base/Spinner";

class ManageTagList extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values.s
    this.state = {
      isLoading: false,
      isOpen: false,
      id: 0,
      name: "",
      type: "",
      typeId: null,
      projectCategory: "",
      projectCategoryId: null,
      isSearchable: "true",
      isDefault: false,
      tagTypeDetails: [],
      projectCategoryDetails: [],
      tagTypeFilter: "",
      tagTypeFilterId: null,
      deleteModel: false,
      tagData: ""
    };

    this.handleDelete = this.handleDelete.bind(this);
  }

  /**
   *
   * @param id
   */
  handleDelete(id) {
    let url =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname;
    const tagId = url.split("/").pop();
    this.props.actions.deleteTag(
      id,
      {
        tagType: this.state.tagTypeFilter || "",
        pagination: true,
        typeId: tagId
      },
      "tags"
    );
  }

  /**
   * Create tag
   *
   * @param data
   */
  tagCreate = data => {
    let url =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname;
    const tagId = url.split("/").pop();

    this.props.actions.addTag(
      data,
      {
        tagType: this.state.tagTypeFilter || "",
        pagination: true,
        typeId: tagId
      },
      "tags",
      this._toggle
    );
  };

  /**
   * Update tag
   *
   * @param data
   * @param id
   */
  tagUpdate = data => {
    let url =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname;
    const tagId = url.split("/").pop();
    this.props.actions.updateTag(
      this.state.id,
      data,
      {
        tagType: this.state.tagTypeFilter || "",
        pagination: true,
        typeId: tagId
      },
      "tags",
      this._toggle
    );
  };

  _toggle = tagId => {
    this.setState({ isOpen: !this.state.isOpen, id: tagId || 0 });
  };

  // Render the header button
  headerButton = () => (
    <div className="d-flex align-items-center mb-n3">
      <div className="btn-wrapper">
        <AddButton
          label="Add New"
          className="pull-right btn btn-secondary"
          onClick={() => {
            this.setState({
              name: "",
              projectCategory: "",
              projectCategoryId: null,
              type: "",
              typeId: null,
              isSearchable: true
            });
            this._toggle();
          }}
        />
        {this.tagModal()}
      </div>
    </div>
  );

  componentDidMount = () => {
    const tagTypeFilter = new URLSearchParams(
      this.props.history.location.search
    ).get("tag_type_filter");

    this.setState({ tagTypeFilter });

    this.getTagTypeData();
    this.getProjectCategory();
    let url =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname;
    const tagId = url.split("/").pop();

    this.setState({ typeId: tagId });
  };

  // Get Tag Type Data
  getTagTypeData = () => {
    this.setState({ isLoading: true }, () => {
      return apiClient
        .get(`${endpoints().tagTypeAPI}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);

          const tagTypeDetails = response.data.data || [];

          const tagTypeFilterId = tagTypeDetails.find(
            type => type.name === this.state.tagTypeFilter
          );

          this.setState({
            tagTypeDetails,
            tagTypeFilterId: tagTypeFilterId ? tagTypeFilterId.id : null,
            isLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  };

  // Get Project Category Data
  getProjectCategory = () => {
    this.setState({ isLoading: true }, () => {
      return apiClient
        .get(`${endpoints().projectCategoryAPI}`)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
          }
          console.error(successMessage);

          this.setState({
            projectCategoryDetails: response.data.data,
            isLoading: false
          });
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            console.error(errorMessage);
          }
        });
    });
  };

  // Tag type options
  tagTypeOptions = () => {
    const { tagTypeDetails } = this.state;
    return (
      tagTypeDetails &&
      tagTypeDetails.map(tagType => ({
        label: tagType.name,
        value: tagType.id
      }))
    );
  };

  // Project category options
  projectCategoryOptions = () => {
    const { projectCategoryDetails } = this.state;
    return (
      projectCategoryDetails &&
      projectCategoryDetails.map(projectCategory => ({
        label: projectCategory.name,
        value: projectCategory.id
      }))
    );
  };

  // Render the tag modal
  tagModal = () => (
    <div>
      <Modal
        isOpen={this.state.isOpen}
        toggle={this._toggle}
        className="landing-create-popup w-100"
        backdrop="static"
      >
        <ModalHeader
          toggle={this._toggle}
          cssModule={{ "modal-title": "w-100 text-center" }}
        >
          <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
            {this.state.id ? "Edit Tag" : "Create Tag"}
          </h4>
        </ModalHeader>
        <Form
          initialValues={{
            name: this.state.name || "",
            projectCategory: this.state.projectCategoryId
              ? {
                  value: this.state.projectCategoryId,
                  label: this.state.projectCategory
                }
              : "",
            type:
              this.state.typeId || this.state.tagTypeFilter
                ? {
                    value: this.state.typeId || this.state.tagTypeFilterId,
                    label: this.state.type || this.state.tagTypeFilter
                  }
                : "",
            isSearchable: this.state.isSearchable || false,
            isDefault: this.state.isDefault || false
          }}
          onSubmit={values => {
            values.name = toString(values.name).trim();
            values.type = toString(values.type ? values.type.value : "");
            values.projectCategory = toString(
              values.projectCategory ? values.projectCategory.value : ""
            );
            this.state.id ? this.tagUpdate(values) : this.tagCreate(values);
          }}
        >
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div>
              <Text
                name="name"
                label="Tag Name"
                placeholder="Enter tag name"
                error=""
                required={true}
              />
            </div>
            <div>
              <Select
                name="type"
                label="Tag Type"
                options={this.tagTypeOptions()}
                error=""
                required={true}
                isSearchable={true}
              />
            </div>
            <div>
              {!this.props.showField && (
                <Select
                  name="projectCategory"
                  label="Project Category"
                  options={this.projectCategoryOptions()}
                  error=""
                  isSearchable={true}
                />
              )}
            </div>
            <div className="col-6 row">
              {!this.props.showField && (
                <SingleCheckbox
                  name={SETTINGS_TAG_IS_SEARCHABLE}
                  label="Is Searchable?"
                  className="accepted-terms"
                />
              )}

              <SingleCheckbox
                name={SETTINGS_TAG_IS_DEFAULT}
                label="Is Default?"
                className="accepted-terms"
              />
            </div>
          </ModalBody>
          <ModalFooter>
            <div>
              <div className="col align-self-center">
                <Button type="submit" label="" disabled={!this.state.isOpen}>
                  {this.state.id ? "Save" : "Create Tag"}
                </Button>
              </div>
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </div>
  );

  render() {
    if (this.state.isLoading) {
      return <Spinner />;
    }
    let url =
      this.props.history &&
      this.props.history.location &&
      this.props.history.location.pathname;
    const tagId = url.split("/").pop();
    return (
      <div>
        <PageTitle label={this.state.tagTypeFilter} />
        <ReduxTable
          id="tags"
          apiURL={`${endpoints().tagAPI}`}
          headerButton={this.headerButton()}
          params={{
            tagType: this.state.tagTypeFilter || "",
            id: this.state.id,
            typeId: tagId
          }}
          onRowClick={row => {
            this.setState({
              name: row.name,
              projectCategory: row.projectCategory,
              projectCategoryId: row.projectCategoryId,
              isSearchable: row.isSearchable === "Yes" ? true : false,
              type: row.type,
              typeId: row.typeId,
              isDefault: row.isDefault === "Yes" ? true : false
            });
            return this._toggle(row.id);
          }}
          showHeader
          searchDisabled
        >
          <ReduxColumn
            minWidth={"92px"}
            width={"45px"}
            maxWidth={"45px"}
            type="link"
            isClickable="true"
            field="name"
            sortBy="name"
          >
            Name
          </ReduxColumn>
          <ReduxColumn
            minWidth={"83px"}
            width={"45px"}
            maxWidth={"45px"}
            field="type"
            sortBy="tag_type"
          >
            Type
          </ReduxColumn>
          {!this.props.showField && (
            <ReduxColumn
              minWidth={"173px"}
              width={"173px"}
              maxWidth={"173px"}
              field="projectCategory"
            >
              Project Category
            </ReduxColumn>
          )}
          {!this.props.showField && (
            <ReduxColumn
              minWidth={"149px"}
              width={"149px"}
              maxWidth={"149px"}
              field="is_searchable"
              sortBy="is_default"
            >
              Is Searchable
            </ReduxColumn>
          )}
          <ReduxColumn
            minWidth={"149px"}
            width={"149px"}
            maxWidth={"149px"}
            field="isDefault"
            sortBy="is_default"
          >
            Is Default
          </ReduxColumn>
          <ReduxColumn
            minWidth={"100px"}
            width={"100px"}
            maxWidth={"100px"}
            field="status"
            disableOnClick
            className="action-column"
            renderField={row => (
              <div className="text-center landing-group-dropdown">
                <MoreDropdown>
                  <DropdownItem
                    onClick={() => {
                      this.setState({
                        name: row.name,
                        projectCategory: row.projectCategory,
                        projectCategoryId: row.projectCategoryId,
                        type: row.type,
                        typeId: row.typeId,
                        isSearchable: row.isSearchable === "Yes" ? true : false,
                        isDefault: row.isDefault === "Yes" ? true : false
                      });
                      return this._toggle(row.id);
                    }}
                  >
                    Edit
                  </DropdownItem>
                  <DropdownItem
                    className={"text-danger"}
                    onClick={() => {
                      this.setState({ deleteModel: true });
                      this.setState({ tagData: row });
                    }}
                  >
                    Delete
                  </DropdownItem>
                </MoreDropdown>
              </div>
            )}
          >
            Action
          </ReduxColumn>
        </ReduxTable>
        <DeleteModal
          id="deleteTag"
          isOpen={this.state.deleteModel}
          toggle={() => {
            this.setState({ deleteModel: false });
          }}
          title="Delete Tag"
          label={this.state.tagData.name}
          deleteFunction={() => this.handleDelete(this.state.tagData.id)}
        />
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      { deleteTag, addTag, updateTag, fetchList },
      dispatch
    )
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(ManageTagList);
