import React from "react";

// Components
import Modal from "../../components/base/Modal";
import Button from "../../components/base/Button";

import AddUserForm from "./AddUserForm";

export default class AddUser extends React.Component {
  state = {
    modal: false
  };

  // Toggle modal
  toggle = () => {
    const modal = this.state.modal ? false : true;
    this.setState({ modal });
  };

  render() {
    return (
      <div>
        <h4 className="d-inline-block">Users</h4>
        <Button
          label="New User"
          icon="fa fa-plus"
          align="right"
          onClick={this.toggle}
        />
        <Modal
          isOpen={this.state.modal}
          toggle={this.toggle}
          modalTitle="Add user"
          modalBody={
            <AddUserForm
              toggle={this.toggle}
              customerId={this.props.customerId}
            />
          }
          hideFooter={true}
        />
      </div>
    );
  }
}
