import React, { Component } from "react";
import { Field } from "formik";

// Inner Components
import SidebarContent from "../base/SidebarContent";
import UploadAvatar from "../../views/users/UploadAvatar";

// Assets
import uploadImgPlaceholder from "../../assets/img/mock_imgs/upload_placeholder.jpg";
import {
  FacebookIcon,
  GlobeIcon,
  InstagramIcon,
  TwitterIcon
} from "../../assets/img/icons";

// Components
import Text from "../base/Text";
import Select from "../base/Select";
import Country from "../base/Country";
import State from "../../components/State";
import Phone from "../base/Phone";
import Email from "../base/Email";
import TimeZone, { TimeZones } from "../base/TimeZone";
import URL from "../base/URL";
import Multiselect from "../base/Multiselect";

// Constants
import { AVAILABILITY } from "../../availabilityArray/Constants";

import { SETTINGS_MARKETPLACE_SALESFORCE_ESSENTIALS } from "../../setting/Constants";

class ExpertProfileSidebar extends Component {
  constructor(props) {
    super(props);
    this.state = {
      skills: this.props.expertDetails.skills,
      tools: this.props.expertDetails.tools,
      industries: this.props.expertDetails.industries,
      selectedOption: "",
      selectedCountryName: this.props.expertDetails.countryName
    };

    this.handleCountryChange = this.handleCountryChange.bind(this);
  }

  // Handle Country
  handleCountryChange = ({ values }) => {
    const selectedOption = values.countryName.id;
    const selectedCountryName = values.countryName && values.countryName.label;
    this.setState({
      selectedOption,
      selectedCountryName: selectedCountryName
    });
  };

  render() {
    const {
      showHourlyRate,
      skillsList,
      toolsList,
      industriesList,
      marketplaceSetting,
      selectedCountry,
      id
    } = this.props;

    const { selectedOption, selectedCountryName } = this.state;

    return (
      <div className="expert-details-sidebar mb-4">
        <SidebarContent title={""}>
          <Field
            render={({ field, form }) => {
              return (
                <UploadAvatar
                  id={id}
                  data={this.props.expertDetails}
                  apiURL={this.props.apiUrl}
                  square
                  customSize={"100%"}
                  hideUploadButton
                  placeholderUrl={uploadImgPlaceholder}
                  setValue={form.setFieldValue}
                />
              );
            }}
          />

          <div className="field-wrapper">
            <Text
              id={id}
              name={"firstName"}
              placeholder={"Your first name..."}
              label={"First Name"}
              required
            />
          </div>

          <div className="field-wrapper">
            <Text
              id={id}
              name={"lastName"}
              placeholder={"Your lastName name..."}
              label={"Last Name"}
              required
            />
          </div>

          {marketplaceSetting ===
            SETTINGS_MARKETPLACE_SALESFORCE_ESSENTIALS && (
            <div className="field-wrapper">
              <Text
                id={id}
                name={"jobTitle"}
                placeholder={"Expert Title..."}
                label={"I am a …"}
              />
            </div>
          )}

          <div className="field-wrapper">
            <Country
              id={id}
              name="countryName"
              label={"Country"}
              placeholder="Select Country"
              error=""
              required={true}
              onChange={this.handleCountryChange}
            />
          </div>

          <div className="field-wrapper">
            <State
              id={id}
              name="stateName"
              label="State/Province/Region"
              placeholder="Enter State"
              error=""
              required
              selectedCountry={
                selectedOption ? selectedOption : selectedCountry
              }
              selectedCountryName={
                selectedCountryName ? selectedCountryName : ""
              }
            />
          </div>

          <div className="field-wrapper">
            <Text
              id={id}
              name="city"
              placeholder="Enter city"
              required={true}
              error=""
              label={"City"}
            />
          </div>

          <div className="field-wrapper">
            <Phone name="phone" placeholder="Phone number..." required />
          </div>

          <div className="field-wrapper">
            <Email name="email" placeholder="Email..." required />
          </div>

          {showHourlyRate && (
            <div className="field-wrapper align-items-end">
              <Text
                id={id}
                name={"hourlyRate"}
                label={"Base rate"}
                addonText={"$"}
                addonType={"prepend"}
              />
              <span className="text-inline-grayed h6-5">per hour</span>
            </div>
          )}

          <div className="field-wrapper">
            <TimeZone
              id={id}
              name="timezone"
              label="Timezone"
              placeholder="Select Timezone..."
              defaultValue={TimeZones.find(
                timezone => timezone.value === this.props.expertDetails.timezone
              )}
            />
          </div>

          <div className="field-wrapper">
            <Select
              id={id}
              name="availability"
              label="What’s your general availability for work?"
              placeholder="Select one..."
              options={AVAILABILITY}
              defaultValue={AVAILABILITY.find(
                available =>
                  available.value === this.props.expertDetails.availability
              )}
              isSearchable={true}
            />
          </div>
        </SidebarContent>

        <SidebarContent title={""}>
          <div className="field-wrapper">
            <URL
              id={id}
              name={"trailheadProfileUrl"}
              addonText={<GlobeIcon />}
              addonType={"prepend"}
              placeholder={"Trailhead profile url"}
            />
          </div>

          <div className="field-wrapper">
            <URL
              id={id}
              name={"websiteUrl"}
              addonText={<GlobeIcon />}
              addonType={"prepend"}
              placeholder={"Website url"}
            />
          </div>

          <div className="field-wrapper">
            <URL
              id={id}
              name={"twitterUrl"}
              addonText={<TwitterIcon />}
              addonType={"prepend"}
              placeholder={"Twitter url"}
            />
          </div>

          <div className="field-wrapper">
            <URL
              id={id}
              name={"facebookUrl"}
              addonText={<FacebookIcon />}
              addonType={"prepend"}
              placeholder={"Facebook url"}
            />
          </div>
          <div className="field-wrapper">
            <URL
              id={id}
              name={"instagramUrl"}
              addonText={<InstagramIcon />}
              addonType={"prepend"}
              placeholder={"Instagram url"}
            />
          </div>
        </SidebarContent>

        <SidebarContent title={"Top Five Skills"}>
          <div className="field-wrapper mb-4">
            <Multiselect
              id={id}
              name="skills"
              options={skillsList}
              label="What are the top five (5) skills that you've mastered?"
              closeMenuOnSelect={false}
              placeholder="e.g. Photoshop, Sketch, Python..."
            />
          </div>
        </SidebarContent>

        <SidebarContent title={"Tools"}>
          <div className="field-wrapper mb-4">
            <Multiselect
              id={id}
              name="tools"
              options={toolsList}
              label="What are the top five (5) tools that you use?"
              closeMenuOnSelect={false}
              placeholder="e.g. Photoshop, Sketch..."
            />
          </div>
        </SidebarContent>

        <SidebarContent title={"Industries"}>
          <div className="field-wrapper mb-4">
            <Multiselect
              id={id}
              name="industries"
              options={industriesList}
              label="What industries do you serve? (e.g. Airline, Retail, Food)"
              closeMenuOnSelect={false}
              placeholder="e.g. Airline, Fintech..."
            />
          </div>
        </SidebarContent>
      </div>
    );
  }
}

export default ExpertProfileSidebar;
