import React from "react";
import { getUserId } from "../../../lib/helper";
import SelectPartner from "./SelectPartner";

const SideBar = props => {
  let {
    status,
    appId,
    appDetails,
    partnerIds,
    partnerId,
    createAppPartner,
    getAppDetails,
    handlePartnerChange
  } = props;

  return (
    <div className="details-body-sidebar">
      <SelectPartner
        createAppPartner={createAppPartner}
        appId={appId}
        currentUserId={getUserId()}
        appDetails={appDetails}
        partnerId={partnerId}
        status={status}
        partnerIds={partnerIds}
        setAppPartners={props.setAppPartners}
        getAppDetails={getAppDetails}
        handlePartnerChange={handlePartnerChange}
      />
      {/* <MdfStatus status={status} /> */}
    </div>
  );
};

export default SideBar;
