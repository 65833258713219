import React, { useEffect, useState } from "react";
import currency from "../lib/Currency";

const Currency = ({ amount, currencyName }) => {
  const [currencyValue, setCurrency] = useState([]);

  useEffect(() => {
    getCurrencySymbol(currencyName);
  }, [currencyName]);

  const getCurrencySymbol = async name => {
    if (name) {
      let currencySymbol = await currency.getSymbol(name);
      setCurrency(currencySymbol);
    } else {
      setCurrency("");
    }
  };

  return (
    <div>
      {currencyValue} {parseFloat(amount).toFixed(2)}
    </div>
  );
};
export default Currency;
