import React from "react";
import AddProjectCategoryForm from "./AddProjectCategoryForm";

// Components
import PageTitle from "../../components/base/PageTitle";

const AddProjectCategory = props => {
  return (
    <div>
      <PageTitle label="Add Project Category" />
      <AddProjectCategoryForm history={props.history} />
    </div>
  );
};

export default AddProjectCategory;
