import React from "react";
import TextArea from "../base/TextArea";
import DefaultContent from "../content/DefaultContent";

const ExpertProfileSpecifications = ({ id }) => {
  return (
    <>
      <DefaultContent
        id={id}
        title={"Exclusions or Specifications"}
        titleHint={"(Torchlite Admins Only)"}
      >
        <TextArea
          id={id}
          name="specifications"
          maxLength={true}
          placeholder={
            "Let the Torchlite admins know if you'd like to be excluded from any particular industries or have other specifications. This will not be seen by any clients."
          }
        />
      </DefaultContent>
    </>
  );
};

export default ExpertProfileSpecifications;
