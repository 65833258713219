import React, { Component } from "react";
import { Modal, ModalBody } from "reactstrap";

// API Client
import { apiClient } from "../../../apiClient";

// Components
import ExpertProfileMain from "../../../components/editProfile/ExpertProfileMain";
import ExpertProfileSidebar from "../../../components/editProfile/ExpertProfileSidebar";
import ExpertProfileVisibilitySettings from "../../../components/editProfile/ExpertProfileVisibilitySettings";
import Form from "../../../components/FormWithErrorHandling";
import Loader from "../../../components/base/Loader";
import PageTitle from "../../../components/base/PageTitle";
import toast from "../../../components/base/Toast";
import ToastContainerComponent from "../../../components/base/ToastContainer";

// Configs
import { endpoints } from "../../../configs";

// Constants
import {
  TAG_TYPE_INDUSTRIES,
  TAG_TYPE_SKILLS,
  TAG_TYPE_TOOLS
} from "../../../tagType/Constants";
import { SETTINGS_MARKETPLACE } from "../../../setting/Constants";

// Context
import MyContext from "../../../context/MyContext";

// Helper
import {
  getUrlParameter,
  removeMaskedPhoneNumber,
  toString,
  getKeyValueByObject
} from "../../../lib/helper";
import { isBadRequest } from "../../../common/http";
import { AVAILABILITY as availabilityOption } from "../../../availabilityArray/Constants";

const LoadingModal = ({ isLoading }) => (
  <Modal
    isOpen={isLoading}
    className={["log-hours-modal", "edit-task-modal"].join(" ")}
  >
    <ModalBody className={["text-center", "mb-4"].join(" ")}>
      <div className="form-wrapper">
        <Loader />
        <div className="field-wrapper">
          <h4 className={["font-weight-bold", "my-4"].join(" ")}>
            Saving Profile
          </h4>
        </div>
      </div>
    </ModalBody>
  </Modal>
);

class EditExpertProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoading: true,
      expertDetails: {},
      skillsList: [],
      toolsList: [],
      industriesList: [],
      isSavingBasicInfo: false,
      isSavingSocialInfo: false,
      isSubmit: false,
      loadingModal: false
    };
  }

  componentDidMount() {
    this._getExpertDetails().then(() => this.setState({ isLoading: false }));
    this.getTagValues(TAG_TYPE_SKILLS);
    this.getTagValues(TAG_TYPE_TOOLS);
    this.getTagValues(TAG_TYPE_INDUSTRIES);
  }

  componentDidUpdate(prevState) {
    // Updating page after saving
    if (
      prevState.loadingModal &&
      prevState.loadingModal !== this.state.loadingModal &&
      this.state.loadingModal === false
    )
      this._getExpertDetails();
  }

  // Get Country Details
  getCountryDetails = countryName => {
    return apiClient
      .get(`${endpoints().countryAPI}/${countryName}`)
      .then(response => {
        const data = response.data;
        this.setState({
          selectedCountry: data.id
        });
      });
  };

  setSubmitState = () => {
    this.setState({ isSubmit: true });
  };

  reSetSubmitState = () => {
    this.setState({ isSubmit: false }, () => {});
  };

  _getExpertDetails = () => {
    const id = getUrlParameter("id");
    return apiClient
      .get(`${endpoints().expertAPI}?id=${id}`)
      .then(response => {
        this.setState({
          expertDetails: {
            ...this.state.expertDetails,
            ...response.data
          }
        });

        if (response.data && response.data.countryName) {
          this.getCountryDetails(response.data.countryName);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
        }
      });
  };

  setTagType = tagType => {
    switch (tagType) {
      case TAG_TYPE_SKILLS:
        return "skills";
      case TAG_TYPE_TOOLS:
        return "tools";
      case TAG_TYPE_INDUSTRIES:
        return "industries";
    }
  };

  // Get Tag Values
  getTagValues = tagType => {
    return apiClient
      .get(`${endpoints().tagAPI}?tagType=${tagType}`)
      .then(response => {
        const results = response.data.data;
        // Check the tag type skills
        if (results && results.length > 0) {
          const tagList = [];
          results.forEach(tag => {
            tagList.push({
              id: tag.id,
              name: tag.name,
              value: tag.name,
              label: tag.name,
              tagTypeId: tag.typeId
            });
          });
          this.setState({
            [`${this.setTagType(tagType)}List`]: tagList
          });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  handleTagChange = ({ values }, tagType) => {
    this.setState({
      [`${this.setTagType(tagType)}List`]: values[this.setTagType(tagType)]
    });
  };

  // Update Visibility
  updateVisibility = () => {
    this.setState({
      expertDetails: {
        ...this.state.expertDetails,
        profile_visibility: !this.state.profile_visibility
      }
    });
  };

  handleSubmit = async values => {
    this.setState({ loadingModal: true });
    apiClient
      .patch(`v1/expert/update-profile/${this.state.expertDetails.id}`, values)
      .then(res => {
        toast.success(res.data.message);
        this.setState({ loadingModal: false });
      })
      .catch(e => {
        this.setState({ loadingModal: false });
        if (299 < e.response.status && 400 > e.response.status) {
          toast.warn(e.message);
        } else if (400 <= e.response.status) {
          toast.error(e.message);
        } else {
          toast.warn(e.message);
        }
      });
  };

  render() {
    const {
      isLoading,
      toolsList,
      skillsList,
      industriesList,
      loadingModal,
      isSubmit,
      selectedCountry
    } = this.state;

    let {
      countryName,
      stateName,
      availability,
      timezone
    } = this.state.expertDetails;

    const { settings } = this.props;

    const marketplaceSetting = settings
      ? getKeyValueByObject(settings, SETTINGS_MARKETPLACE)
      : "";

    countryName = {
      label: countryName,
      value: countryName
    };

    stateName = {
      label: stateName,
      value: stateName
    };

    availability = {
      label: availability,
      value: availability
    };

    timezone = {
      label: timezone,
      value: timezone
    };

    const {
      id,
      profile_visibility,
      firstName,
      lastName,
      jobTitle,
      city,
      phone,
      email,
      hourlyRate,
      websiteUrl,
      trailheadProfileUrl,
      twitterUrl,
      facebookUrl,
      instagramUrl,
      skills,
      tools,
      industries,
      aboutMe,
      recommendations,
      certifications,
      employments,
      education,
      avatar,
      showHourlyRate,
      showProfileVisibility,
      specifications,
      notes
    } = this.state.expertDetails;

    return (
      !isLoading && (
        <div className={["edit-user-profile"].join(" ")}>
          <MyContext.Consumer>
            {context => (
              <>
                {context.isMobile && <PageTitle label={"Edit Your Profile"} />}
              </>
            )}
          </MyContext.Consumer>
          <div className="form-wrapper">
            <Form
              initialValues={{
                firstName: firstName || "",
                lastName: lastName || "",
                jobTitle: jobTitle || "",
                countryName: countryName || "",
                city: city || "",
                stateName: stateName || "",
                phone: phone || "",
                email: email || "",
                hourlyRate: hourlyRate || "",
                timezone: timezone || "",
                availability: availability || "",
                websiteUrl: websiteUrl || "",
                trailheadProfileUrl: trailheadProfileUrl || "",
                twitterUrl: twitterUrl || "",
                facebookUrl: facebookUrl || "",
                instagramUrl: instagramUrl || "",
                profile_visibility,
                industries: industries || "",
                tools: tools || "",
                skills: skills || "",
                aboutMe: aboutMe || "",
                recommendations,
                certifications,
                employments,
                education,
                avatar: avatar || "",
                specifications: specifications || "",
                notes: notes || ""
              }}
              onSubmit={values => {
                let newValues = Object.assign({}, values);

                if (newValues.phone) {
                  newValues.phone = removeMaskedPhoneNumber(newValues.phone);
                }

                newValues.skills =
                  newValues.skills && newValues.skills.length > 0
                    ? newValues.skills
                    : [];

                newValues.tools =
                  newValues.tools && newValues.tools.length > 0
                    ? newValues.tools
                    : [];

                newValues.industries =
                  newValues.industries && newValues.industries.length > 0
                    ? newValues.industries
                    : [];

                newValues.availability = toString(
                  newValues.availability
                    ? newValues.availability.value
                    : newValues.availability
                );
                newValues.isAvailable = toString(
                  !newValues.availability ||
                    newValues.availability === availabilityOption[4].value
                    ? "false"
                    : "true"
                );
                newValues.timezone = toString(
                  newValues.timezone
                    ? newValues.timezone.value
                    : newValues.timezone
                );
                newValues.stateName = toString(
                  newValues.stateName
                    ? newValues.stateName.value
                    : newValues.stateName
                );
                newValues.countryName = toString(
                  newValues.countryName
                    ? newValues.countryName.label
                    : newValues.countryName
                );
                newValues.education &&
                  newValues.education.length > 0 &&
                  newValues.education.map((educate, index) => {
                    if (educate.startDate === "Invalid date") {
                      newValues.education[index].startDate = null;
                      newValues.education[index].endDate = null;
                    }
                    if (educate.endDate === "Invalid date") {
                      newValues.education[index].endDate = null;
                    }
                  });

                newValues.employments &&
                  newValues.employments > 0 &&
                  newValues.employments.map((educate, index) => {
                    newValues.employments[
                      index
                    ].employmentTypeValue = educate.employmentType
                      ? educate.employmentType.value
                      : "";
                  });

                this.handleSubmit(newValues);
              }}
              showErrorBanner={true}
              isSubmit={isSubmit}
              reSetSubmitState={this.reSetSubmitState}
            >
              <ExpertProfileVisibilitySettings
                expertId={id}
                updateVisibility={this.updateVisibility}
                profileVisibility={profile_visibility}
                showProfileVisibility={showProfileVisibility}
                setSubmitState={this.setSubmitState}
              />

              <ToastContainerComponent />

              <div className="user-profile-main d-flex align-items-start">
                <ExpertProfileSidebar
                  expertId={id}
                  expertDetails={this.state.expertDetails}
                  skillsList={skillsList}
                  toolsList={toolsList}
                  industriesList={industriesList}
                  showHourlyRate={showHourlyRate}
                  marketplaceSetting={marketplaceSetting}
                  selectedCountry={selectedCountry}
                  apiUrl={`${endpoints().expertAvatarUpdate}?id=${id}`}
                />

                <ExpertProfileMain
                  expertId={id}
                  expertDetails={this.state.expertDetails}
                />
              </div>
            </Form>
          </div>

          <LoadingModal isLoading={loadingModal} />
        </div>
      )
    );
  }
}

export default EditExpertProfile;
