import { apiClient } from "../apiClient";
import toast from "../components/base/Toast";
import { endpoints } from "../configs";
import { fetchList } from "./table";
import { isBadRequest } from "../common/http";

import {
  REQUEST_UPDATE_PARTNER,
  RECEIVE_UPDATE_PARTNER,
  PARTNER_UPDATE_ERROR
} from "../actionType/Constants";
import {
  PARTNER_STATUS_APPROVED,
  PARTNER_STATUS_PENDING,
  PARTNER_STATUS_DECLINED
} from "../partnerStatus/Constants";

/**
 * Request for updating Partner
 */
export function requestUpdatePartner() {
  return {
    type: REQUEST_UPDATE_PARTNER
  };
}

/**
 * Receive for updating Partner
 */
export function receiveUpdatePartner() {
  return {
    type: RECEIVE_UPDATE_PARTNER
  };
}

/**
 * Receive for error updating Partner
 */
export function partnerUpdateError(error) {
  return {
    type: PARTNER_UPDATE_ERROR,
    error
  };
}

/**
 * Update Partners
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updatePartners(id, data, page, params) {
  return dispatch => {
    dispatch(requestUpdatePartner());
    apiClient
      .put(`${endpoints().partnerAPI}/status/approve/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        dispatch(
          fetchList(
            "all-partners",
            `${endpoints().partnerAPI}/search`,
            page || 1,
            10,
            {
              section: "all-partners"
            }
          )
        );
        dispatch(
          fetchList(
            "approved-partners",
            `${endpoints().partnerAPI}/search`,
            page || 1,
            10,
            {
              marketplaceStatus: PARTNER_STATUS_APPROVED,
              status: PARTNER_STATUS_APPROVED,
              section: "approved-partners"
            }
          )
        );
        dispatch(
          fetchList(
            "pending-partners",
            `${endpoints().partnerAPI}/search`,
            page || 1,
            10,
            {
              marketplaceStatus: PARTNER_STATUS_PENDING,
              status: PARTNER_STATUS_PENDING,
              section: "pending-partners"
            }
          )
        );
        dispatch(receiveUpdatePartner());
      })
      .catch(error => {
        dispatch(partnerUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Update Pending Partners
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updatePendingPartners(id, data, params) {
  return dispatch => {
    dispatch(requestUpdatePartner());
    apiClient
      .put(`${endpoints().partnerAPI}/status/approve/${id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        dispatch(
          fetchList(
            "pendingPartners",
            `${endpoints().partnerAPI}/search`,
            1,
            10,
            {
              marketplaceStatus: PARTNER_STATUS_PENDING
            }
          )
        );
        dispatch(
          fetchList(
            "approvedPartners",
            `${endpoints().partnerAPI}/search`,
            1,
            10,
            {
              marketplaceStatus: PARTNER_STATUS_APPROVED
            }
          )
        );
        dispatch(receiveUpdatePartner());
      })
      .catch(error => {
        dispatch(partnerUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}

/**
 * Delte Partners
 *
 * @param id
 * @returns {function(...[*]=)}
 */
export function deletePartners(id, params) {
  return dispatch => {
    dispatch(requestUpdatePartner());
    apiClient
      .delete(`${endpoints().partnerAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
      })
      .then(() => {
        dispatch(
          fetchList(
            "all-partners",
            `${endpoints().partnerAPI}/search`,
            1,
            10,
            params ? params : {}
          )
        );
        dispatch(receiveUpdatePartner());
      })
      .catch(error => {
        dispatch(partnerUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}
