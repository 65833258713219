import React from "react";

import { apiClient } from "../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../configs";
import { TAG_TYPE_PAYMENT_METHOD } from "../tagType/Constants";

// Helper
import { getCookie } from "../lib/helper";

// Cookie
import { COOKIE_SESSION_TOKEN } from "../lib/cookie";

// Components
import Select from "./base/Select";
import { isBadRequest } from "../common/http";

class PaymentMethodType extends React.Component {
  state = {
    paymentMethodTypeList: ""
  };

  // Get Payment Method type
  getPaymentMethodType = async () => {
    if (getCookie(COOKIE_SESSION_TOKEN)) {
      apiClient.defaults.headers.common.Authorization = getCookie(
        COOKIE_SESSION_TOKEN
      );
    } else {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    }
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${TAG_TYPE_PAYMENT_METHOD}`
      );
      const results = response.data.data;

      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });
        this.setState({ paymentMethodTypeList: tagList });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  componentDidMount() {
    this.getPaymentMethodType();
  }

  //  Get options
  getPaymentMethodTypeOptions = () => {
    const { paymentMethodTypeList } = this.state;
    let paymentMethodTyeOptions = [];

    if (!paymentMethodTypeList) return paymentMethodTyeOptions;

    paymentMethodTypeList.forEach(type => {
      paymentMethodTyeOptions.push({ value: type.id, label: type.name });
    });

    return paymentMethodTyeOptions;
  };

  render() {
    const {
      name,
      label,
      placeholder,
      error,
      required,
      isSearchable,
      fullWidth,
      isDisabled,
      id
    } = this.props;

    return (
      <Select
        id={id || name}
        name={name}
        label={label}
        placeholder={placeholder}
        options={this.getPaymentMethodTypeOptions()}
        fullWidth={fullWidth}
        isSearchable={isSearchable}
        error={error}
        {...{ required }}
        isDisabled={isDisabled}
      />
    );
  }
}

export default PaymentMethodType;
