import React from "react";

// Components
import RegisterDealForm from "./RegisterDealForm";

const AddDeals = props => {
  return (
    <>
      <RegisterDealForm history={props.history} match={props.match} />
    </>
  );
};

export default AddDeals;
