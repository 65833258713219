import React, { Component } from "react";
import PropTypes from "prop-types";
import { bindActionCreators } from "redux";
import ExpertAvailabilityForm from "./ExpertAvailabilityForm";
import { connect } from "react-redux";

// Actions
import { expertAvailabilityUpdate } from "../../actions/expertAvailabilityUpdate";

// Assets
import headerTriangles from "../../assets/header-triangles.svg";

class ExpertAvailability extends Component {
  render() {
    const { actions } = this.props;

    const search = this.props.location.search;
    const params = new URLSearchParams(search);
    const email = params.get("email");

    return (
      <div
        className="mb-5 availability-container"
        style={{ background: "#FFF" }}
      >
        <div className="main-container mb-5">
          <div
            className="availability-update-section"
            style={{ backgroundImage: "url(" + headerTriangles + ")" }}
          >
            <div className="col-12 text-center">
              <div className="availability-update-header-section">
                <h1
                  className="text-center p-3 mb-3"
                  style={{ color: "#dadadc" }}
                >
                  Availability Update
                </h1>
                <p className="header-section" style={{ color: "#dadadc" }}>
                  <strong>Torchlite here again!</strong> Please take a few
                  minutes and help us update our records. Let us know whether or
                  not you are available for new projects so that we're confident
                  in our client communication. While you're here, please also
                  note if your experience aligns with some of your highly
                  requested areas. Thank you!
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="availability-update-form mt-5 container">
          <ExpertAvailabilityForm actions={actions} email={email} />
        </div>
      </div>
    );
  }
}

ExpertAvailability.propTypes = {
  actions: PropTypes.object
};

function mapStateToProps(state) {
  return {
    user: state.user
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        expertAvailabilityUpdate
      },
      dispatch
    )
  };
}

export default connect(mapStateToProps, mapDispatchToProps)(ExpertAvailability);
