import React from "react";
import { Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { HandsIcon } from "../../assets/img/icons";
import { Link } from "react-router-dom";
// Helper
import { getCookie, setCookie } from "../../lib/helper";
import { COOKIE_SHOW_REFFERRAL_CLOSED_NOTIFICATION_POPUP } from "../../lib/cookie";
import { PARTNER_ADMIN_ROLE } from "../../roles/Constants";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

class PayoutPreferencePopup extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      isOpen: false,
      closeReferralCount: 0,
      accountPaymentCount: 0
    };
  }

  componentDidUpdate(prevProps) {
    const pop_status = getCookie(
      COOKIE_SHOW_REFFERRAL_CLOSED_NOTIFICATION_POPUP
    );
    const popup_show = pop_status === "hide" ? false : true;
    if (
      this.state.closeReferralCount !== this.props.closeReferralCount &&
      this.state.accountPaymentCount === this.props.accountPaymentCount &&
      this.props.partnerRoleId === PARTNER_ADMIN_ROLE &&
      this.props.closeReferralCount > 0 &&
      popup_show
    ) {
      this.setState({
        isOpen: true,
        closeReferralCount: this.props.closeReferralCount
      });
    }
  }
  _toggle = () => {
    this.setState({ isOpen: !this.state.isOpen });
    setCookie(COOKIE_SHOW_REFFERRAL_CLOSED_NOTIFICATION_POPUP, "hide");
  };
  render() {
    return (
      <div>
        <Modal
          isOpen={this.state.isOpen}
          className="referral-modal-popup w-100 text-center mx-auto"
        >
          <ModalHeader
            toggle={this._toggle}
            cssModule={{ "modal-title": "w-100 text-center mt-3" }}
            style={{ backgroundColor: "#DCDCDC" }}
          >
            <div className="d-flex justify-content-center">
              <HandsIcon />
            </div>
            <div className="font-weight-bold mt-2">
              <span>Congratulations!</span>
            </div>
          </ModalHeader>
          <ModalBody className="ml-3 mr-3 mt-2 mb-3">
            <div>
              <span>
                Your first {Terminology.get(SystemName.REFERRAL)} has been
              </span>
              <br />
              <span>closed and is ready to be paid.</span>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="d-flex justify-content-between pb-4">
              <Link
                className="text-decoration-none"
                onClick={this._toggle}
                to={`/edit/partner/public-profile?id=${this.props.partnerId}&section=payout-preferences`}
              >
                <button className="btn btn-primary mr-1">
                  Setup Your Payout Preferences
                </button>
              </Link>
              <button className="btn border border-dark" onClick={this._toggle}>
                Setup Preferences Later
              </button>
            </div>
          </ModalFooter>
        </Modal>
      </div>
    );
  }
}

export default PayoutPreferencePopup;
