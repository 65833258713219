import React from "react";
import { Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

// Components
import Form from "../../../components/base/Form";
import Select from "../../../components/base/Select";
import Button from "../../../components/base/Button";
import CancelButton from "../../../components/base/CancelButton";

// Contents
import {
  PROJECT_STATUS_DRAFT,
  PROJECT_STATUS_REQUESTED,
  PROJECT_STATUS_MATCHING,
  PROJECT_STATUS_INTERVIEWING,
  PROJECT_STATUS_INPROCESS,
  PROJECT_STATUS_COMPLETED
} from "../../../projectStatus/Constants";

const ProjectStatusChangeModal = ({
  modal,
  setModal,
  onUpdateProjectStatus,
  label
}) => {
  const projectStatusOptions = [
    {
      label: PROJECT_STATUS_DRAFT,
      value: PROJECT_STATUS_DRAFT
    },
    {
      label: PROJECT_STATUS_REQUESTED,
      value: PROJECT_STATUS_REQUESTED
    },
    {
      label: PROJECT_STATUS_MATCHING,
      value: PROJECT_STATUS_MATCHING
    },
    {
      label: PROJECT_STATUS_INTERVIEWING,
      value: PROJECT_STATUS_INTERVIEWING
    },
    {
      label: PROJECT_STATUS_INPROCESS,
      value: PROJECT_STATUS_INPROCESS
    },
    {
      label: PROJECT_STATUS_COMPLETED,
      value: PROJECT_STATUS_COMPLETED
    }
  ];

  return (
    <>
      <Modal
        isOpen={modal}
        toggle={setModal}
        className={["edit-task-modal"].join(" ")}
      >
        <ModalHeader toggle={setModal}>
          <div
            className={["d-flex", "flex-column", "align-items-center"].join(
              " "
            )}
          >
            <h4 className={["font-weight-bold", "mt-2"].join(" ")}>
              Change Status
            </h4>
          </div>
        </ModalHeader>
        <Form
          initialValues={{
            status: ""
          }}
          onSubmit={values => {
            onUpdateProjectStatus(values.status.value);
            setModal();
          }}
        >
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="form-wrapper">
              <p className="text-center">
                Are you sure you want to change the {label}?
              </p>

              <br />
              <div className="field-wrapper">
                <Select
                  label={label}
                  name="status"
                  placeholder="Select Status"
                  options={projectStatusOptions}
                  isSearchable={true}
                  required
                />
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <div className="btn-wrapper">
              <CancelButton onClick={setModal} />

              <Button
                type="submit"
                class="btn btn-primary"
                label={"Yes, Change"}
              />
            </div>
          </ModalFooter>
        </Form>
      </Modal>
    </>
  );
};

export default ProjectStatusChangeModal;
