import React, { useEffect, useState } from "react";
import { Dropdown, DropdownMenu, DropdownToggle } from "reactstrap";
import { ChevronDown } from "../../../assets/img/icons";
import Avatar from "../../../components/base/Avatar";

const UserDropdown = ({
  assignee,
  userlist,
  updateDefaultManager,
  currentUserId,
  partnerManagerId,
  placeholderText
}) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [newUserList, setUserList] = useState([]);
  const toggle = () => setDropdownOpen(prevState => !prevState);
  const updateUserList = () => {
    let usersList = [];
    let data = userlist;
    for (let i = 0; i < data.length; i++) {
      usersList[i] = {
        id: data[i].id,
        name: data[i].name,
        picture: data[i].avatarUrl,
        firstName: data[i].firstName,
        lastName: data[i].lastName
      };
      if (currentUserId === data[i].id) {
        let index = i;
        var b = usersList[index];
        usersList[index] = usersList[0];
        usersList[0] = b;
      }
    }
    setUserList(usersList);
  };
  useEffect(() => {
    updateUserList();
  }, [userlist]);
  return (
    <div className="dropdown-wrapper">
      <Dropdown
        isOpen={dropdownOpen}
        toggle={toggle}
        className={["user-dropdown"].join(" ")}
        id={partnerManagerId}
      >
        <DropdownToggle
          aria-expanded={dropdownOpen}
          className={["h7", "dropdown-toggle", "hide-caret"].join(" ")}
          style={{ width: "100%" }}
          caret
          color={"gray"}
        >
          {Object.keys(assignee).length > 0 ? (
            <>
              <span className="user-badge">
                <Avatar
                  firstName={assignee.firstName}
                  lastName={assignee.lastName}
                  url={assignee.picture}
                  size="customSize"
                  imageSize={"20"}
                  fontSize={8}
                ></Avatar>
                <span style={{ marginLeft: "5px" }}>
                  {currentUserId === assignee.id ? "You" : assignee.name}
                </span>
              </span>
            </>
          ) : (
            <span className={["no-assignee"].join(" ")}>
              {placeholderText ? placeholderText : "Assignee..."}
            </span>
          )}
          <ChevronDown />
        </DropdownToggle>
        <DropdownMenu>
          {newUserList.map(user => (
            <button
              className={`dropdown-item ${
                user.id === assignee.id ? "active-selection text-truncate" : ""
              }`}
              onClick={() => {
                toggle();
                updateDefaultManager(user.id);
              }}
            >
              <span className={`user-badge d-inline-flex w-100`}>
                <Avatar
                  firstName={user.firstName}
                  lastName={user.lastName}
                  url={user.picture}
                  size="customSize"
                  imageSize={"20"}
                  fontSize={8}
                ></Avatar>
                <span className="text-truncate" style={{ marginLeft: "5px" }}>
                  {currentUserId === user.id ? "You" : user.name}
                </span>
                <span className="ml-auto">
                  <i
                    className={`d-flex justify-content-end ${
                      user.id === assignee.id ? "fas fa-check" : ""
                    }`}
                    style={{ color: "#009DDA" }}
                  ></i>
                </span>
              </span>
            </button>
          ))}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

export default UserDropdown;
