import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink, Button } from "reactstrap";
import classnames from "classnames";
import { Link } from "react-router-dom";

// Assets
import { CheckmarkIcon, ClockIcon } from "../../assets/img/icons";

import { getPricingScheduleValue } from "../playbook/inner_components/getPricingScheduleValue";

// Constant
import {
  PLAYBOOK_PRICING_TYPE_HOURLY_PAYMENT,
  PLAYBOOK_PRICING_TYPE_SUBSCRIPTION
} from "../../playbook/Constants";
import { METACX_EVENT_PLAYBOOK_DETAILS_ORDER_BUTTON_CLICK } from "../../metaCXEvents/Constants";
import {
  PLAYBOOK_PACKAGE_BASIC,
  PLAYBOOK_PACKAGE_STANDARD,
  PLAYBOOK_PACKAGE_PREMIUM
} from "../../playbook/Constants";

import {
  SETTINGS_ENABLE_PLAYBOOK_INQUIRY,
  SETTINGS_ALLOW_PLAYBOOK_PURCHASE
} from "../../setting/Constants";

// Components
import useToggle from "../../components/customhooks/useToggle";
import LoginModal from "../login/LoginModal";

import PlaybookInquiryModal from "./inner-components/PlaybookInquiryModal";

// Helper
import {
  getLoggedInStatus,
  getFormattedPrice,
  getParamsByName,
  getKeyValueByObject
} from "../../lib/helper";

// Library
import { metaCXTrack } from "../../lib/metaCX";

// Google Analystics
import { gaEvent } from "../../lib/GoogleAnalytics";

// Get Active Tab
const getActiveTab = options => {
  let package_type = "";
  options.forEach((option, index) => {
    if (index === 0) {
      package_type = option.package_type;
    }
  });

  return package_type;
};

// Get Active Packages
const getActivePackages = packageOptions => {
  let package_type = [];
  packageOptions.forEach((packageOption, index) => {
    if (packageOption && packageOption.price) {
      package_type.push(packageOption);
    }
  });

  return package_type;
};

const CartTabs = props => {
  const {
    id,
    playbook,
    options,
    pricingType,
    packageDeliverables,
    customerDetails,
    settings
  } = props;

  const enable_playbook_inquiry = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_PLAYBOOK_INQUIRY)
    : "";

  const allow_playbook_purchase = settings
    ? getKeyValueByObject(settings, SETTINGS_ALLOW_PLAYBOOK_PURCHASE)
    : "";

  // Packages Has Price
  const packagesOptions = getActivePackages(options);

  const { pricingSchedule } = playbook;

  const pricingScheduleLabel = getPricingScheduleValue(pricingSchedule);

  // Current Page URL
  const currentUrl = window.location.pathname + window.location.search;

  const [activeTab, setActiveTab] = useState(getActiveTab(packagesOptions));

  // Set Active Tab For Package Deliverables
  const toggle = tab => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const [loginModal, setLoginModal] = useToggle();

  // Handle Playbook Order
  const handlePlaybookOrder = e => {
    if (!getLoggedInStatus()) {
      e.preventDefault();
      setLoginModal();
    }
  };

  let deliverables = [];
  if (activeTab === PLAYBOOK_PACKAGE_STANDARD) {
    deliverables = packageDeliverables && packageDeliverables.standard;
  } else if (activeTab === PLAYBOOK_PACKAGE_BASIC) {
    deliverables = packageDeliverables && packageDeliverables.basic;
  } else if (activeTab === PLAYBOOK_PACKAGE_PREMIUM) {
    deliverables = packageDeliverables && packageDeliverables.premium;
  }

  const partnerId = getParamsByName("partnerId");

  return (
    <div className="cart-tabs sidebar-content-box">
      {packagesOptions && packagesOptions.length > 0 && (
        <Nav tabs>
          {packagesOptions.map(option => (
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === option.package_type
                })}
                onClick={() => {
                  toggle(option.package_type);
                }}
              >
                {option.package_type}
              </NavLink>
            </NavItem>
          ))}
        </Nav>
      )}
      <TabContent activeTab={activeTab}>
        {packagesOptions.map(option => (
          <TabPane tabId={option.package_type}>
            <div className="sidebar-content">
              <span className="title h5 d-block">
                <b>{playbook.name}</b>
              </span>
              <span className="subtitle h7 d-block">
                Get all the work done for:
              </span>

              {/*Show price*/}
              {allow_playbook_purchase === "true" && option.price && (
                <span className="price d-block h4">
                  {pricingType === PLAYBOOK_PRICING_TYPE_SUBSCRIPTION
                    ? `${getFormattedPrice(
                        option.price
                      )} ${pricingScheduleLabel}`
                    : getFormattedPrice(option.price)}
                  {pricingType === PLAYBOOK_PRICING_TYPE_HOURLY_PAYMENT &&
                    "/hr."}
                </span>
              )}

              <ul className="list-unstyled checklist">
                {deliverables &&
                  deliverables.length > 0 &&
                  deliverables.map(deliverable => (
                    <>
                      <li>
                        <CheckmarkIcon /> {deliverable.description}
                      </li>
                    </>
                  ))}
              </ul>
              {option.turnaround_time && (
                <p className="d-flex align-items-center">
                  <ClockIcon /> <b>{option.turnaround_time} Days Delivery</b>
                </p>
              )}

              {allow_playbook_purchase === "true" && (
                <Link
                  to={{
                    pathname: `/playbook/order/summary/${id}${
                      partnerId ? "?partnerId=" + partnerId : ""
                    }`,
                    state: {
                      playbook: playbook,
                      cartOptions: packagesOptions,
                      selectedCartType: activeTab,
                      customerDetails: customerDetails
                    }
                  }}
                  style={{
                    color: "inherit",
                    textDecoration: "none"
                  }}
                  onClick={handlePlaybookOrder}
                >
                  <Button
                    color={"primary"}
                    onContextMenu={e => e.preventDefault()}
                    onClick={e => {
                      // Track MetaCX Events when click on "Order Playbook" button in playbook view page
                      metaCXTrack(
                        METACX_EVENT_PLAYBOOK_DETAILS_ORDER_BUTTON_CLICK,
                        {
                          playbookId: `${playbook.id}`,
                          playbookName: playbook.name
                        },
                        settings,
                        () => {}
                      );

                      // GA Event
                      gaEvent({
                        category: "Playbook Details",
                        action: "Clicked on order playbook checkout",
                        label: "Playbook Details",
                        value: `${playbook.id}`
                      });

                      // Start Checkout Info Funnel Step
                      window.ga("ec:setAction", "checkout", {
                        step: 1
                      });

                      window.ga("ec:addImpression", {
                        // Provide Playbook details in an impressionFieldObject.
                        id: `${playbook.id} - Playbook`, // Playbook ID (string).
                        name: `${playbook.name}`, // Playbook name (string).
                        category: "Marketplace Playbook", // Playbook category (string).
                        brand: "Torchlite", // Playbook brand (string).
                        variant: "Torchlite", // Playbook variant (string).
                        list: "Playbooks View", // Playbook list (string).
                        position: 1, // Playbook position (number).
                        dimension1: "Member" // Custom dimension (string).
                      });

                      window.ga("ec:setAction", "detail"); // Detail action.
                    }}
                  >
                    Order Playbook
                  </Button>
                </Link>
              )}
              {enable_playbook_inquiry === "true" && (
                <PlaybookInquiryModal playbookId={id} />
              )}
            </div>
          </TabPane>
        ))}
      </TabContent>
      {/*Login popup*/}
      <LoginModal
        loginModal={loginModal}
        setLoginModal={setLoginModal}
        playbookId={id}
        persistState={{
          playbook: playbook,
          cartOptions: packagesOptions,
          selectedCartType: activeTab,
          customerDetails: customerDetails
        }}
        redirectUrl={currentUrl}
        settings={settings}
      />
    </div>
  );
};

export default CartTabs;
