import React from "react";

const WrapperWithHamburgerMenu = props => {
  return props.isMobile ? (
    <div className={"with-hamburger-menu"}>{props.children}</div>
  ) : (
    props.children
  );
};

export default WrapperWithHamburgerMenu;
