import React, { useState, useEffect } from "react";

// Configs
import { endpoints } from "../configs";
// API call
import { apiClient } from "../apiClient";

import Select from "./base/Select";
import { isBadRequest } from "../common/http";

const UserSelector = ({
  name,
  label,
  id,
  fontBolded,
  placeholder,
  handleChange,
  width,
  isSearchable,
  isClearable
}) => {
  const [userList, setUserList] = useState();

  // Get User list
  const getUsersList = () => {
    apiClient
      .get(`${endpoints().userAPI}/search`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          setUserList(response.data.data);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          console.error(errorMessage);
        }
      });
  };

  useEffect(() => {
    getUsersList();
  }, []);

  //Get options
  const getUserListOption = () => {
    const options = [];

    if (!userList) return options;

    userList.forEach(user => {
      if (user.name) {
        options.push({ label: user.name, value: user.id });
      }
    });

    return options;
  };

  return (
    <Select
      id={id || name}
      name={name}
      label={label}
      fontBolded={fontBolded}
      options={getUserListOption()}
      placeholder={placeholder}
      isSearchable={isSearchable}
      isClearable={isClearable}
      width={width}
      handleChange={handleChange}
    />
  );
};

export default UserSelector;
