import React, { useState, useEffect } from "react";

import EmailValidation from "../../../../lib/Email";
import PhoneValidation from "../../../../lib/Phone";

import { Label, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import Email from "../../../../components/base/Email";
import Phone from "../../../../components/base/Phone";
import Text from "../../../../components/base/Text";
import Form from "../../../../components/base/Form";
import DeleteModal from "../../../../components/base/DeleteModal";

function EditAdditionalCotactModal(props) {
  const {
    referralId,
    isOpen,
    toggle,
    editData,
    updateContactData,
    deleteContactData
  } = props;

  const [id, setId] = useState("");
  const [contactFirstname, setContactFirstName] = useState("");
  const [contactLastName, setContactLastName] = useState("");
  const [title, setTitle] = useState("");
  const [contactEmail, setContactEmail] = useState("");
  const [contactPhone, setContactPhone] = useState("");
  const [deleteContactModal, setDeleteContactModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const [contactArrayIndex, setContactArrayIndex] = useState(0);

  // Use effect
  useEffect(() => {
    if (editData) {
      handleInitalValues(editData);
    }
  }, [editData, toggle]);

  // handle initial values
  const handleInitalValues = editData => {
    if (editData) {
      editData.forEach(item => {
        setId(item.id);
        setContactFirstName(item.contactFirstname);
        setContactLastName(item.contactLastName);
        setTitle(item.title);
        setContactEmail(item.contactEmail);
        setContactPhone(item.contactPhone);
        setContactArrayIndex(item.contactArrayIndex);
      });
    }
  };

  // handle chnage for fields
  const handleContactFirstName = event => {
    setContactFirstName(event.target.value);
    setErrorMessage(false);
  };

  const handleContactLastName = event => {
    setContactLastName(event.target.value);
  };

  const handleContactTitle = event => {
    setTitle(event.target.value);
  };

  const handleContactEmail = event => {
    setContactEmail(event.target.value);
  };

  const handleContactPhone = event => {
    setContactPhone(event.target.value);
  };

  // validate the inputes
  const validateContact = data => {
    let success = true;
    data &&
      data.forEach(values => {
        if (values && !values.contactFirstname) {
          success = false;
          setErrorMessage(true);
        } else if (values && EmailValidation.isValid(values.contactEmail)) {
          success = false;
        } else if (values && PhoneValidation.isValid(values.contactPhone)) {
          success = false;
        }
      });
    return success;
  };

  const handleContactData = () => {
    const data = [];
    data.push({
      id: id ? id : "",
      contactArrayIndex: contactArrayIndex ? contactArrayIndex : "",
      referralId: referralId ? referralId : "",
      contactFirstname: contactFirstname ? contactFirstname : "",
      contactLastName: contactLastName ? contactLastName : "",
      title: title ? title : "",
      contactEmail: contactEmail ? contactEmail : "",
      contactPhone: contactPhone ? contactPhone : ""
    });
    if (validateContact(data) === false) {
      return;
    }
    updateContactData(data, id, toggle);
  };

  // Delete contact
  const openContactDeleteModal = () => {
    setDeleteContactModal(true);
  };

  // handle delete contact
  const handleDeleteContact = () => {
    deleteContactData(id, contactFirstname, contactLastName);
    toggle();
  };

  // handle toggle func
  const handleToggle = () => {
    toggle();
    setErrorMessage(false);
  };

  return (
    <>
      <DeleteModal
        isOpen={deleteContactModal}
        toggle={() => {
          setDeleteContactModal(prevState => !prevState);
        }}
        title="Delete Additional Contact"
        label={contactFirstname + " " + contactLastName}
        deleteFunction={handleDeleteContact}
      />
      <Modal
        isOpen={isOpen}
        toggle={handleToggle}
        className={["edit-task-modal"].join(" ")}
      >
        <ModalHeader toggle={handleToggle}>
          <Label>Edit Additional Contact</Label>
        </ModalHeader>
        <Form
          initialValues={{
            contactFirstName: contactFirstname || "",
            contactLastName: contactLastName || "",
            title: title || "",
            email: contactEmail || "",
            phone: contactPhone || ""
          }}
          enableReinitialize={true}
        >
          <ModalBody>
            <Text
              id={id}
              name="contactFirstName"
              label="First Name"
              placeholder="Enter first name"
              onChange={e => {
                handleContactFirstName(e);
              }}
              required={true}
            />
            <small className={`text-danger ${"d-block"}`}>
              {errorMessage && "First Name is required"}
            </small>
            <Text
              id={id}
              name="contactLastName"
              label="Last Name"
              placeholder="Enter last name"
              onChange={e => {
                handleContactLastName(e);
              }}
            />
            <Text
              id={id}
              name="title"
              label="Title"
              placeholder="Enter Contact Title"
              onChange={e => {
                handleContactTitle(e);
              }}
            />
            <Email
              name="email"
              label="Email"
              placeholder="Enter Email Address..."
              onChange={e => {
                handleContactEmail(e);
              }}
            />
            <Phone
              name="phone"
              label="Phone Number"
              placeholder="Enter phone number"
              onChange={e => {
                handleContactPhone(e);
              }}
            />
          </ModalBody>
        </Form>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <div className="btn-wrapper">
            <button className="btn btn-danger" onClick={openContactDeleteModal}>
              Delete
            </button>
            <button
              className="btn btn-primary ml-2"
              onClick={handleContactData}
            >
              Save
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
}

export default EditAdditionalCotactModal;
