import React, { Component } from "react";
import toast from "../../../components/base/Toast";
import DeleteModal from "../../../components/base/DeleteModal";

// Constants
import {
  COURSE_DRAFT,
  COURSE_SINGLE_COURSE,
  COURSE_PROGRESSIVE_COURSE,
  TAB_BASICS
} from "../../../course/Constants";

// Assets
import { EditIconAlt, PlusIcon, TrashIcon } from "../../../assets/img/icons";

// API call
import { apiClient } from "../../../apiClient";

// Configs
import { endpoints } from "../../../configs";
import { isBadRequest } from "../../../common/http";

export default class EditLessonTab extends Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      lessonTitle: "",
      modalOpen: false,
      textElement: "",
      multimediaUrl: "",
      quizTitle: "",
      quizTypeCategory: "",
      question: "",
      answer: "",
      lessonValue: "",
      currentLessonData: "",
      loading: false,
      openEdit: false,
      OpenAddModal: false,
      openDeleteModal: false,
      activeTab: TAB_BASICS,
      values: [],
      counts: "",
      fileTypeError: false,
      checkBox: {
        partnerTier:
          (props.checkboxFieldValue && props.checkboxFieldValue.partnerTier) ||
          [],
        partnerType:
          (props.checkboxFieldValue && props.checkboxFieldValue.partnerType) ||
          [],
        courseRequirement: props.courseToUnlock
          ? COURSE_PROGRESSIVE_COURSE
          : COURSE_SINGLE_COURSE
      },
      partnerTierList: [],
      partnerTypeList: []
    };
  }
  componentDidMount() {
    if (this.props.lessonData && this.props.lessonData.length) {
      this.props.lessonData.sort((a, b) => parseFloat(a.id) - parseFloat(b.id));
      this.setState({ values: this.props.lessonData });
    }
  }
  componentDidUpdate(prevProps) {
    if (
      prevProps.lessonData &&
      prevProps.lessonData.length >= 0 &&
      this.props.lessonData &&
      JSON.stringify(prevProps.lessonData) !==
        JSON.stringify(this.props.lessonData)
    ) {
      this.setState({ values: this.props.lessonData });
    }
  }

  addField = event => {
    event.preventDefault();
    const id = Math.floor(1000 + Math.random() * 9000);
    const values = [...this.state.values];
    values.push({ description: null, id });
    this.setState({ values });
  };
  modalOpen = event => {
    event.preventDefault();
    this.setState({ modalOpen: true });
  };

  // Handle remove
  handleRemove = i => {
    const values = [...this.state.values];
    values.splice(i, 2);
    this.setState({ values });
  };
  _onLessonSubmit = values => {
    this._createLesson(this._toLessonArray(values));
  };

  // Lesson API Call
  _createLesson(data) {
    const { actions } = this.props;

    return apiClient
      .post(
        `${endpoints().courseAPI}/lesson/${this.props.match.params.id}`,
        data
      )
      .then(response => {
        this.setState({ courseId: response.data.courseId });
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        setTimeout(() => {
          toast.success(successMessage);
          const url =
            this.state.status === COURSE_DRAFT
              ? `/course/add/${this.props.match.params.id}`
              : "/course";
          this.props.history.push(url);
        }, 2000);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          this.setState({ isSubmitting: false });
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  }
  // Partner course delete api call

  handleDelete = lessonId => {
    return apiClient
      .delete(`${endpoints().courseAPI}/lesson/${lessonId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.props.getLessonList();
        toast.success(successMessage);
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // To Array
  _toLessonArray(values) {
    const data = new FormData();
    data.append("lessonTitle", values.lessonTitle ? values.lessonTitle : "");
    return data;
  }

  render() {
    const {
      setCurrentLessonData,
      toggleEditLessonModal,
      toggleAddLessonModal
    } = this.props;

    const { values, currentLessonData, openDeleteModal } = this.state;
    return (
      <div>
        <DeleteModal
          isOpen={openDeleteModal}
          title="Delete Lesson Module"
          toggle={() => {
            this.setState({ openDeleteModal: false });
          }}
          label={currentLessonData.lesson_title}
          deleteFunction={() => this.handleDelete(currentLessonData.id)}
        />
        {values
          .sort((a, b) => parseFloat(a.id) - parseFloat(b.id))
          .map((el, i) => (
            <div className="w-100">
              <div
                className="task-list pr-4 pt-1"
                style={{ minHeight: "70px" }}
                key={el.id}
              >
                <div className="pull-left task-actions">
                  <label className="font-weight-bold pt-2">{`${el.lesson_title}`}</label>
                </div>

                <div className="pull-right">
                  <div className="task-actions">
                    <>
                      <span className={"d-flex"}>
                        <button
                          type="button"
                          className={["edit-btn", "btn-link", "btn"].join(" ")}
                          onClick={() => {
                            this.setState({ counts: `${i + 1}` });
                            this.setState({ OpenAddModal: true });
                            this.setState({ openEdit: true });
                            this.setState({ currentLessonData: el });
                            toggleEditLessonModal();
                            setCurrentLessonData(el);
                          }}
                        >
                          <EditIconAlt />
                        </button>
                        <button
                          type="button"
                          className={["delete-btn", "btn-link", "btn"].join(
                            " "
                          )}
                          onClick={() => {
                            this.setState({
                              openEdit: false,
                              openDeleteModal: true,
                              currentLessonData: el
                            });
                          }}
                        >
                          <TrashIcon />
                        </button>
                      </span>
                    </>
                  </div>
                </div>
              </div>
              <hr />
            </div>
          ))}
        <div className="task-list pt-3 pb-3">
          <div onClick={toggleAddLessonModal} className="task-left">
            <div className="checkbox add-task cursor-pointer">
              <PlusIcon />
            </div>
            <a className={["add-task cursor-pointer text-link"].join(" ")}>
              Add a New Lesson Modules to this Course...
            </a>
          </div>
        </div>
      </div>
    );
  }
}
