import { fetchList } from "../actions/table";
import { apiClient } from "../apiClient";
import { isBadRequest } from "../common/http";
import Toast from "../components/base/Toast";
import { endpoints } from "../configs";
import Permission from "../helpers/Permission";
import ArrayList from "../lib/ArrayList";

class UserRolePermissionService {
  static async create(data, roleId, params, callback) {
    if (roleId) {
      params.role_id = roleId;
    }
    return dispatch => {
      apiClient
        .post(`${endpoints().userRolePermissionAPI}?roleId=${roleId}`, data)
        .then(response => {
          let successMessage;
          if (response && response.data) {
            successMessage = response.data.message;
            Toast.success(successMessage);
          }
        })
        .then(() => {
          dispatch(
            fetchList(
              "userRole",
              `${endpoints().userRolePermissionAPI}/search`,
              1,
              25
            )
          );
        })
        .catch(error => {
          if (isBadRequest(error)) {
            let errorMessage;
            const errorRequest = error.response.request;
            if (errorRequest && errorRequest.response) {
              errorMessage = JSON.parse(errorRequest.response).message;
            }
            Toast.error(errorMessage);
            console.error(errorMessage);
          }
        });
    };
  }
  static async getPermissionList() {
    try {
      const response = await apiClient.get(
        `${endpoints().userRolePermissionAPI}/search`
      );
      return response && response.data.data;
    } catch (err) {
      console.log(err);
    }
  }
  static async search(roleId) {
    try {
      const response = await apiClient.get(
        `${endpoints().userRolePermissionAPI}/search?role_id=${roleId}`
      );
      //Get Permission List
      let data = Permission.UserPermissionLists();
      let rolePermissionList = [];
      let permissionList = [];
      let result = response && response.data && response.data.data;
      let permissionData = await this.getPermissionList();

      //Get Role based List
      if (result && result.length > 0) {
        result.forEach(list => {
          rolePermissionList.push(list.role_permission);
        });
      }
      if (data && data.length > 0) {
        data.forEach(list => {
          permissionList.push(list.value);
        });
      }

      if (permissionData && permissionData.length > 0) {
        permissionData.forEach(data => {
          permissionList.push(data.name);
        });
      }

      //Array Filter
      let filteredValue = await ArrayList.arrayToArrayFilter(
        permissionList,
        rolePermissionList
      );

      //Filtered List
      let filteredList = [];
      if (filteredValue && filteredValue.length > 0) {
        filteredValue.forEach(list => {
          filteredList.push({ label: list, value: list });
        });
      }
      return filteredList;
    } catch (error) {
      console.log(error);
    }
  }
}

export default UserRolePermissionService;
