export const Hubspot = {
  //object name
  OBJECT_COMPANY: "Companies",
  OBJECT_DEAL: "Deals",
  OBJECT_CONTACT: "Contacts",

  //sync direction
  SYNC_DIRECTION_OUTBOUND: 1,
  SYNC_DIRECTION_INBOUND: 2,
  SYNC_DIRECTION_BOTH: 3,
  SYNC_DIRECTION_OUTBOUND_TEXT: "OutBound ",
  SYNC_DIRECTION_INBOUND_TEXT: "InBound",
  SYNC_DIRECTION_BOTH_TEXT: "Both"
};

export const relationalOperators = () => [
  {
    label: "Less than",
    value: "less_than"
  },
  {
    label: "Greater than",
    value: "greater_than"
  },
  {
    label: "Greater than or Equal to",
    value: "greater_than_or_equal_to"
  },
  {
    label: "Equal to",
    value: "equal_to"
  },
  {
    label: "Not Equal to",
    value: "not_equal_to"
  }
];

export const getSyncDirection = () => [
  {
    label: Hubspot.SYNC_DIRECTION_INBOUND_TEXT,
    value: Hubspot.SYNC_DIRECTION_INBOUND
  },
  {
    label: Hubspot.SYNC_DIRECTION_OUTBOUND_TEXT,
    value: Hubspot.SYNC_DIRECTION_OUTBOUND
  },
  {
    label: Hubspot.SYNC_DIRECTION_BOTH_TEXT,
    value: Hubspot.SYNC_DIRECTION_BOTH
  }
];

export const HubspotObjectNameList = () => [
  {
    label: Hubspot.OBJECT_COMPANY,
    value: Hubspot.OBJECT_COMPANY
  },
  {
    label: Hubspot.OBJECT_DEAL,
    value: Hubspot.OBJECT_DEAL
  },
  {
    label: Hubspot.OBJECT_CONTACT,
    value: Hubspot.OBJECT_CONTACT
  }
];

export const TorchliteObject = () => [
  {
    label: "Referral",
    value: "referral"
  },
  {
    label: "Account",
    value: "account"
  },
  {
    label: "Contact",
    value: "contact"
  }
];

export const TorchliteReferralFields = () => [];

export const TorchliteAccountFields = () => [];

export const TorchliteContactFields = () => [
  {
    label: "Partner Type",
    value: "partner_type"
  }
];
