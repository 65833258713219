import React, { Component } from "react";
import UserContext from "./UserContext";

// API Client
import { apiClient } from "../../apiClient";

// Library
import { getCookie } from "../../lib/helper";
import {
  clearAllCookies,
  COOKIE_SESSION_TOKEN,
  COOKIE_USER_ID
} from "../../lib/cookie";
import { clearAllSettingsCookies } from "../../lib/settingsCookie";
import { createActivity } from "../../lib/Activity";

// Configs
import { endpoints } from "../../configs";

// Constant
import { ACTIVITY_TYPE_LOGOUT } from "../../Activity/Constants";
import { isBadRequest } from "../../common/http";
import { connect } from "react-redux";

class UserProvider extends Component {
  constructor(props) {
    super(props);
    this.setToken();
    this.state = {
      userLoggedIn: false,
      token: null,
      user: {
        firstName: "",
        lastName: "",
        avatarUrl: "",
        userId: "",
        expertId: "",
        companyId: "",
        partnerId: "",
        isPartnerAdmin: false,
        partnerName: ""
      }
    };
    this.loginUser = this.loginUser.bind(this);
  }

  componentDidMount() {
    this.setToken();
    this.getToken();
    this.userLoggedIn().then(_ => {
      this.state.userLoggedIn && this.getUserDetails();
    });
  }

  loginUser() {
    this.setState(
      {
        userLoggedIn:
          getCookie(COOKIE_USER_ID) !== undefined &&
          getCookie(COOKIE_USER_ID) !== ""
      },
      () => {
        this.getToken();
        this.getUserDetails();
      }
    );
  }

  logoutUser(roleId) {
    const activityData = {
      activityType: ACTIVITY_TYPE_LOGOUT
    };

    // Create Logout Activity
    createActivity(activityData, roleId, () => {
      apiClient.defaults.headers.common.Authorization = null;
      clearAllCookies();
      clearAllSettingsCookies();
      return window.location.replace("/login");
    });
  }

  async userLoggedIn() {
    await this.setState({
      userLoggedIn:
        getCookie(COOKIE_USER_ID) !== undefined &&
        getCookie(COOKIE_USER_ID) !== ""
    });
  }

  async getUserDetails() {
    try {
      this.setToken();
      let response = await apiClient.get(`${endpoints().userAPI}`);
      const {
        id,
        firstName,
        lastName,
        avatarUrl,
        expertId,
        companyId,
        partnerId,
        isPartnerAdmin,
        partnerName
      } = response.data;

      this.setState({
        user: {
          firstName,
          lastName,
          avatarUrl,
          expertId,
          userId: id,
          companyId,
          partnerId,
          isPartnerAdmin,
          partnerName
        }
      });
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  }

  _getUserDetailsById = id => {
    return apiClient
      .get(`${endpoints().userAPI}/${id}`)
      .then(response => {
        return response.data;
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          return { error: true };
        }
      });
  };

  setToken() {
    // this hack is required for unknown reasons which was taken over from Thidiff Team.
    // This will be discussed in the upcoming call and we'll have this working properly.
    return (apiClient.defaults.headers.common.Authorization = getCookie(
      COOKIE_SESSION_TOKEN
    ));
  }

  getToken() {
    const token = getCookie(COOKIE_SESSION_TOKEN);
    this.setState({
      token:
        apiClient.defaults.headers.common.Authorization === token
          ? token
          : apiClient.defaults.headers.common.Authorization
    });
  }

  render() {
    return (
      <UserContext.Provider
        value={{
          ...this.state,
          logoutUser: this.logoutUser,
          _getUserDetailsById: this._getUserDetailsById,
          loginUser: this.loginUser
        }}
      >
        {this.props.children}
      </UserContext.Provider>
    );
  }
}

const mapStateToProps = state => {
  const roleId = state.user ? state.user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
})(UserProvider);
