import { apiClient } from "../apiClient";
import toast from "../components/base/Toast";
import { endpoints } from "../configs";
import { fetchList } from "./table";

import {
  REQUEST_UPDATE_FEEDBACK,
  RECEIVE_UPDATE_FEEDBACK,
  FEEDBACK_UPDATE_ERROR
} from "../actionType/Constants";
import {
  FEEDBACK_TABLE_PENDING,
  FEEDBACK_TABLE_APPROVED
} from "../feedback/Constants";
import { isBadRequest } from "../common/http";
/**
 * Request for updating Expert
 */
export function requestUpdateFeedback() {
  return {
    type: REQUEST_UPDATE_FEEDBACK
  };
}

/**
 * Receive for updating Expert
 */
export function receiveUpdateFeedback() {
  return {
    type: RECEIVE_UPDATE_FEEDBACK
  };
}

/**
 * Receive for error updating Expert
 */
export function feedbackUpdateError(error) {
  return {
    type: FEEDBACK_UPDATE_ERROR,
    error
  };
}

/**
 * Update Expert
 *
 * @param id
 * @param data
 * @returns {function(...[*]=)}
 */
export function updateReview(id, status, params) {
  return dispatch => {
    dispatch(requestUpdateFeedback());
    apiClient
      .put(`${endpoints().reviewAPI}/status/${id}`, status)
      .then(response => {
        console.log(response.data.message);
      })
      .then(() => {
        dispatch(
          fetchList(
            FEEDBACK_TABLE_PENDING,
            `${endpoints().reviewAPI}/search`,
            1,
            10,
            {
              status: "Pending"
            }
          )
        );
        dispatch(
          fetchList(
            FEEDBACK_TABLE_APPROVED,
            `${endpoints().reviewAPI}/search`,
            1,
            10,
            {
              status: "Approved"
            }
          )
        );
        dispatch(receiveUpdateFeedback());
      })
      .catch(error => {
        dispatch(feedbackUpdateError(error));

        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };
}
