import React from "react";
import LeftSideSection from "./LeftSideSection";
import GetStartedMinimal from "./GetStartedMinimal";
import ContactInformation from "./ContactInformation";
import RequestReceived from "./RequestReceived";

// Components
import Form from "../../components/base/Form";

//Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";

//API call
import { apiClient } from "../../apiClient";

// GA
import { gaEvent } from "../../lib/GoogleAnalytics";

//Helper
import {
  toString,
  getKeyValueByObject,
  getSettingMediaUrl
} from "../../lib/helper.js";
import _renderWizardIndicator from "../../components/RenderWizardSquares";
import { TORCHLITE_WEBSITE_URL } from "../../configs";

import {
  BACKGROUND_IMAGE_URL,
  SETTINGS_PORTAL_LOGO
} from "../../setting/Constants";
import { LEAD_SOURCE_FIND_AN_EXPERT } from "../../lead/Constants";
import { isBadRequest } from "../../common/http";

class FindExpertMinimal extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      currentStep: 1, // Default is Step 1
      height: window.innerHeight,
      email: ""
    };

    // Bind new functions for next and previous
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this._submit = this._submit.bind(this);
    this._close = this._close.bind(this);

    // Bind the update dimensions
    this.updateDimensions = this.updateDimensions.bind(this);
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions);
  }

  // Update the screen height
  updateDimensions() {
    this.setState({
      height: window.innerHeight
    });
  }

  // _next functions will be called on button click
  _next = props => {
    let currentStep = this.state.currentStep;
    // If the current step is 10 or 11, then add one on "next" button click
    currentStep = currentStep >= 11 ? 11 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });
  };

  // _previous functions will be called on button click
  _prev() {
    let currentStep = this.state.currentStep;
    // If the current step is 2 or 3, then subtract one on "previous" button click
    currentStep = currentStep <= 1 ? 1 : currentStep - 1;
    this.setState({
      currentStep: currentStep
    });
  }

  // _previous functions will be called on button click
  _submit(values) {
    if (this._validateFields(values) === false) {
      return;
    }

    let currentStep = this.state.currentStep;
    // If the current step is 10 or 11, then add one on "next" button click
    currentStep = currentStep >= 3 ? 3 : currentStep + 1;
    this.setState({
      currentStep: currentStep
    });

    // Save Expert request form in the final
    if (currentStep === 3) {
      this._expertRequest(this._toArray(values));
    }
  }

  // Validate Fields
  _validateFields(values) {
    let success = true;

    const firstName = values.firstName;
    const lastName = values.lastName;
    const email = values.email;
    const expertType = values.expertType
      ? values.expertType.value
      : values.expertType;

    switch (this.state.currentStep) {
      case 1:
        if (expertType == null) {
          success = false;
        }
        break;
      case 2:
        if (firstName == null || lastName == null || email == null) {
          success = false;
        }
        break;
      default:
        break;
    }

    return success;
  }

  // Close the wizard
  _close() {
    window.location = { TORCHLITE_WEBSITE_URL };
  }

  // To Array
  _toArray(values) {
    values.expertType = toString(
      values.expertType ? values.expertType.value : values.expertType
    );
    values.description = toString(values.description);
    values.firstName = toString(values.firstName);
    values.lastName = toString(values.lastName);
    values.email = toString(values.email);
    values.source = toString(LEAD_SOURCE_FIND_AN_EXPERT);

    this.setState({
      email: values.email
    });

    return values;
  }

  // Expert Request API Call
  _expertRequest(data) {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .post(endpoints().lead, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }

        // Event
        gaEvent({
          category: "Find An Expert Minimal",
          action: "Clicked on Find An Expert Minimal",
          label: "Find An Expert Minimal"
        });

        let currentStep = this.state.currentStep;
        // If the current step is 2 or 3, then add one on "next" button click
        currentStep = currentStep >= 3 ? 3 : currentStep + 1;
        this.setState({
          currentStep: currentStep
        });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  render() {
    const { currentStep, height, email } = this.state;
    const { settings } = this.props;

    const marketplaceLogo =
      settings && getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, SETTINGS_PORTAL_LOGO)
          )
        : "";

    const backgroundImage =
      settings && getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
        ? getSettingMediaUrl(
            settings,
            getKeyValueByObject(settings, BACKGROUND_IMAGE_URL)
          )
        : "";

    const {
      marketplaceName,
      marketplaceTalentName,
      marketplaceDefaultLandingPageUrl
    } = this.props;

    const initialValues = {
      expertType: "",
      description: "",
      firstName: "",
      lastName: "",
      email: ""
    };

    return (
      <div
        className="container-fluid"
        style={{ height: height, minHeight: "100vh" }}
      >
        <div className="row overflow-hidden" style={{ height: "100%" }}>
          <LeftSideSection
            height={height}
            marketplaceLogo={marketplaceLogo}
            marketplaceName={marketplaceName}
            backgroundImage={backgroundImage}
          />

          <div
            className="col"
            style={{ background: "#fff", overflowY: "scroll", height: "100%" }}
          >
            <div className="row find-an-expert-budget-right-side-section form-wrapper flex-column">
              {_renderWizardIndicator(currentStep, 4)}
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  this._submit(values);
                }}
              >
                <GetStartedMinimal
                  step={1}
                  currentStep={currentStep}
                  next={this._submit}
                  marketplaceTalentName={marketplaceTalentName}
                />
                <ContactInformation
                  step={2}
                  currentStep={currentStep}
                  next={this._submit}
                  prev={this._prev}
                  minimal={true}
                />
                <RequestReceived
                  step={3}
                  currentStep={currentStep}
                  close={this._close}
                  email={email}
                  minimal
                  marketplaceDefaultLandingPageUrl={
                    marketplaceDefaultLandingPageUrl
                  }
                />
              </Form>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default FindExpertMinimal;
