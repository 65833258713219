import React from "react";
import ReduxTable, { ReduxColumn } from "../../components/base/ReduxTable";
import Spinner from "../../components/base/Spinner";

import Form from "../../components/base/Form";
import Text from "../../components/base/Text";
import AddButton from "../../components/base/AddButton";
import DeleteModal from "../../components/base/DeleteModal";
import DragAndDropField from "../../components/base/DragAndDropField";
import MultiselectCreatable from "../../components/base/MultiselectCreatable";
import Drawer from "../../components/Drawer";

import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  DropdownItem
} from "reactstrap";

// Helper
import {
  getUrlParameter,
  isCompanyAdmin,
  isCompanyManager,
  isSuperAdmin,
  toString
} from "../../lib/helper";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { endpoints } from "../../configs";

// Constant
import { isBadRequest } from "../../common/http";
import { toast } from "react-toastify";
import TextArea from "../../components/base/TextArea";
import MoreDropdown from "../../components/base/MoreDropdown";
import MultiSelect from "../../components/base/Multiselect";
import { FILE } from "../../course/Constants";
import { TAG_TYPE_FILE_CATEGORY } from "../../tagType/Constants";
import { DownloadIcon, FileIcon, ImageIcon } from "../../assets/img/icons";

class File extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values.
    this.state = {
      id: "",
      name: "",
      notes: "",
      deleteModal: false,
      deleteData: false,
      isLoading: false,
      isOpen: false,
      uploadFileError: "",
      user: "",
      partnerUserList: [],
      category: "",
      categoryList: [],
      detail: "",
      uploadFile: "",
      row: "",
      uploadFileName: "",
      sortByOptions: [
        {
          value: "name:ASC",
          label: "Name"
        },
        {
          value: "createdAt:DESC",
          label: "Most Recent"
        }
      ]
    };
  }

  componentDidMount() {
    this.getPartnerDetails();
    this.getDetail();
    if (this.state.row.imageUrl) {
      this.setState({ uploadFileName: "" });
      this.setState({ uploadFile: "" });
    }
    this.getTagValues(TAG_TYPE_FILE_CATEGORY);
  }

  getPartnerDetails = () => {
    const userDetail = this.props.users;
    let partnerUserList = [];
    if (userDetail && userDetail.length > 0) {
      for (let i = 0; i < userDetail.length; i++) {
        partnerUserList.push({
          label: userDetail[i].firstName
            ? userDetail[i].firstName
            : "" + userDetail[i].lastName
            ? userDetail[i].lastName
            : "",
          value: userDetail[i].id
        });
      }
    }
    this.setState({
      partnerUserList: partnerUserList
    });
  };

  _toggle = id => {
    this.setState({
      isOpen: !this.state.isOpen,
      id: id || "",
      row: "",
      user: "",
      category: ""
    });
  };

  closeToggle = () => {
    this.setState({ isOpen: this.state.isOpen, row: "" });
  };

  handleCategoryChange = data => {
    let category =
      data && data.values && data.values.category ? data.values.category : "";
    this.setState({ category: category });
  };

  /**
   * Create template
   *
   * @param data
   */
  create = async values => {
    let selectedUsers = values && values.selectedUsers.map(data => data.value);
    const data = new FormData();
    data.append("name", values.name ? values.name : "");
    data.append("notes", values.notes ? values.notes : "");
    data.append("partnerId", this.props.partnerId ? this.props.partnerId : "");
    data.append(
      "uploadFile",
      this.state.uploadFile ? this.state.uploadFile : ""
    );
    data.append("category", values.category ? values.category.value : "");
    data.append(
      "uploadFileName",
      this.state.uploadFileName ? this.state.uploadFileName : ""
    );
    if (selectedUsers && selectedUsers.length > 0) {
      data.append("selectedUsers", selectedUsers);
    }
    data.append("object", FILE);
    return await apiClient
      .post(`${endpoints().fileAPI}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.setState({ user: "" });
        this.setState({ category: "" });
        this.setState({ isLoading: true });

        this._toggle();
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  upload = async values => {
    let selectedUsers = values && values.selectedUsers.map(data => data.value);
    const data = new FormData();
    data.append("name", values.name ? values.name : "");
    data.append("notes", values.notes ? values.notes : "");
    data.append("partnerId", this.props.partnerId ? this.props.partnerId : "");
    data.append("category", values.category ? values.category.value : "");
    if (selectedUsers && selectedUsers.length > 0) {
      data.append("selectedUsers", selectedUsers);
    }
    data.append("object", FILE);
    await apiClient
      .put(`${endpoints().fileAPI}/${this.state.row.id}`, data)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        toast.success(successMessage);
        this.setState({ user: "" });
        this.setState({ category: "" });
        this.setState({ isLoading: true });
        this.getTagValues(TAG_TYPE_FILE_CATEGORY);
        this._toggle();
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(errorMessage);
          console.error(errorMessage);
        }
      });
  };

  getDetail = () => {
    apiClient
      .get(
        `${endpoints().fileAPI}/${this.state.id}?partnerId=${
          this.props.partnerId
        }`
      )
      .then(response => {
        if (response && response.data) {
          // setDetail(response.data);
          this.setState({ detail: response.data });
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  getTagValues = async courseTagType => {
    try {
      const response = await apiClient.get(
        `${endpoints().tagAPI}?tagType=${courseTagType}`
      );
      const results = response.data.data;
      // Check the tag type skills
      if (results && results.length > 0) {
        const tagList = [];
        results.forEach(tag => {
          tagList.push({
            id: tag.id,
            name: tag.name,
            value: tag.name,
            label: tag.name,
            tagTypeId: tag.typeId
          });
        });
        this.setState({
          categoryList: tagList
        });
      }
    } catch (error) {
      if (isBadRequest(error)) {
        let errorMessage;
        const errorRequest = error.response.request;
        if (errorRequest && errorRequest.response) {
          errorMessage = JSON.parse(errorRequest.response).message;
        }
        console.error(errorMessage);
      }
    }
  };

  // Handle file upload
  handleUploadFile = files => {
    if (files && files.length > 0) {
      const file = files[0];
      if (file.name) {
        this.setState({
          uploadFileName: file.name,
          uploadFile: file,
          uploadFileError: ""
        });
      }
    } else {
      this.setState({
        uploadFileError: "File is required"
      });
    }
  };

  handleName = data => {
    let value = data && data.target.value ? data.target.value : "";
    this.setState({ name: value });
  };

  handleNotes = data => {
    let values =
      data && data.target && data.target.value ? data.target.value : "";

    this.setState({ notes: values });
  };

  handleUserChange = data => {
    let values =
      data && data.values && data.values.selectedUsers
        ? data.values.selectedUsers
        : [];
    this.setState({ user: values });
  };

  // delete Name page api call by id
  _delete(id) {
    apiClient
      .delete(`${endpoints().fileAPI}/${id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
        }
        this.setState({ isLoading: true });

        toast.success(successMessage);
        this.setState({ isLoading: false });
      })
      .catch(error => {
        if (isBadRequest(error)) {
          this.setState({ isLoading: false });
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  }

  handleSubmit = values => {
    try {
      values.name = toString(values && values.name).trim();
      values.notes =
        values && values.notes ? toString(values && values.notes).trim() : "";
      values.uploadFile = this.state.uploadFile ? this.state.uploadFile : "";
      values.uploadFileName = this.state.uploadFileName
        ? this.state.uploadFileName
        : "";
      values.category = this.state.category ? this.state.category : "";
      if (!values.uploadFileName && !values.uploadFile) {
        this.setState({ uploadFileError: "File is Required" });
      }
      if (values.selectedUsers && values.selectedUsers.length > 0) {
        values.selectedUsers = values.selectedUsers;
      }
      if (this.state.row.id) {
        this.upload(values);
      } else {
        this.create(values);
      }
    } catch (error) {}
  };

  // Delete Attachment
  render() {
    const { deleteModal, deleteData, isLoading, row, name } = this.state;

    const imageType = ["jpg", "jpeg", "gif", "png"];

    let selectedUserValue = [];

    if (row && row.selectedUsers.length > 0) {
      for (let i = 0; i < row.selectedUsers.length; i++) {
        let data = this.state.partnerUserList.find(
          value => value.value == row.selectedUsers[i]
        );
        selectedUserValue.push(data);
      }
    }

    const initialValues = {
      id: this.state.row.id ? this.state.row.id : this.state.id || "",
      name: this.state.name
        ? this.state.name
        : this.state.row.name
        ? this.state.row.name
        : "",
      notes: this.state.notes ? this.state.notes : this.state.row.notes || "",
      uploadFile: this.state.uploadFileName
        ? this.state.uploadFileName
        : this.state.row.imageUrl,
      course_file: this.state.row.imageUrl,
      selectedUsers: this.state.user
        ? this.state.user
        : selectedUserValue || [],
      category:
        this.state.category !== ""
          ? this.state.category
          : this.state.row.tagName
          ? { value: this.state.row.tagName, label: this.state.row.tagName }
          : this.state.categoryList.find(
              category => category.value === this.state.detail.category
            ) || null
    };

    const addFileForm = (
      <div>
        <Text
          name="name"
          label="File Name"
          placeholder="Enter File Name"
          disabled={row && row.allowEdit == false}
          onChange={this.handleName}
          error=""
          required
        />
        <TextArea
          name="notes"
          maxLength={250}
          label="Notes"
          placeholder={"Notes"}
          onChange={this.handleNotes}
          disabled={row && row.allowEdit == false}
        />
        <MultiselectCreatable
          isSingleSelect={true}
          isClearable={false}
          name="category"
          disabled={row && row.allowEdit == false}
          label="Category"
          fontBolded
          placeholder={"Select or Create Category"}
          options={this.state.categoryList}
          onInputChange={this.handleCategoryChange}
          defaultValue={
            this.state.category !== ""
              ? this.state.category
              : initialValues.category !== null
              ? initialValues.category
              : this.state.categoryList.find(
                  category => category.value === this.state.detail.category
                ) || null
          }
        />
        {row && row.allowEdit == true ? (
          <DragAndDropField
            label={"Upload File"}
            fontBolded
            name={"course_file"}
            btnLabel={"Upload Partner File"}
            onFileChange={this.handleUploadFile}
            data={this.state.row.imageUrl}
            disabled
          />
        ) : row && row.allowEdit == false ? (
          <>
            <p className="mt-2 mb-2">File</p>
            <div className="border border-dark">
              <div className="row mt-2 mb-2">
                {row.imageUrl && row.imageUrl.length > 0 && row.imageUrl[0] && (
                  <>
                    <div className="mx-2 col-1">
                      {imageType.includes(
                        row.imageUrl[0].name.split(".").slice(-1)[0]
                      ) ? (
                        <ImageIcon />
                      ) : (
                        <FileIcon />
                      )}
                    </div>
                    <div className="name ellipsis pr-2 mt-1 mb-1 col-lg-9 col-8">
                      {row.imageUrl[0].name}
                    </div>
                    <div className="col-1 d-flex">
                      <div className="action mb-1 mt-1">
                        <a
                          href={row.imageUrl[0].fileUrl}
                          style={{ cursor: "pointer" }}
                          target="_blank"
                          rel="noreferrer"
                        >
                          <DownloadIcon />
                        </a>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        ) : (
          <>
            <DragAndDropField
              label={"Upload File"}
              fontBolded
              name={"course_file"}
              btnLabel={"Upload Partner File"}
              onFileChange={this.handleUploadFile}
              data={this.state.row.imageUrl}
              required
            />
            {this.state.uploadFileError && (
              <span className="error-message">
                {this.state.uploadFileError}
              </span>
            )}
          </>
        )}
        {row && row.allowEdit == true ? (
          <div className="mt-2">
            <MultiSelect
              name="selectedUsers"
              label="Share With"
              required
              options={this.state && this.state.partnerUserList}
              placeholder="Select Partners"
              onInputChange={this.handleUserChange}
            />
          </div>
        ) : !row ? (
          <div className="mt-2">
            <MultiSelect
              name="selectedUsers"
              label="Share With"
              required
              options={this.state && this.state.partnerUserList}
              placeholder="Select Partners"
              onInputChange={this.handleUserChange}
            />
          </div>
        ) : (
          ""
        )}
      </div>
    );

    const addFileFooter = (
      <>
        {row && row.allowEdit ? (
          <div className="container-fluid">
            <div className="col text-center">
              <Button type="submit" label="">
                {this.state.row.id ? "Save" : "Add"}
              </Button>
            </div>
          </div>
        ) : (
          <div className="container-fluid">
            <div className="col text-center">
              <Button type="submit" label="">
                {this.state.row.id ? "Save" : "Add"}
              </Button>
            </div>
          </div>
        )}
      </>
    );

    if (isLoading) {
      return <Spinner />;
    }
    return (
      <>
        <div className="d-flex justify-content-end">
          <div className="btn-wrapper">
            {(isSuperAdmin(this.props.roleId) ||
              isCompanyAdmin(this.props.roleId) ||
              isCompanyManager(this.props.roleId)) && (
              <AddButton
                label="Add New"
                className="pull-right btn btn-secondary"
                onClick={() => {
                  this.setState({
                    id: "",
                    name: "",
                    notes: "",
                    uploadFile: "",
                    uploadFileName: "",
                    category: "",
                    row: "",
                    user: "",
                    isOpen: true
                  });
                }}
              />
            )}
          </div>
        </div>
        <br />
        <Drawer
          modelTitle={row.id ? "Edit File" : "Add File"}
          DrawerBody={addFileForm}
          DrawerFooter={addFileFooter}
          onSubmit={values => {
            this.handleSubmit(values);
          }}
          initialValues={initialValues}
          handleOpenModal={this._toggle}
          handleCloseModal={this._toggle}
          handleDrawerClose={this._toggle}
          isModalOpen={this.state.isOpen}
          enableReinitialize
          showFooter
        />
        {/* REDUX TABLE */}
        <ReduxTable
          id="file"
          className="mt-3 mb-3"
          apiURL={`${endpoints().fileAPI}`}
          showHeader
          newTableHeading
          paramsToUrl={true}
          pagination={true}
          history={this.props.history}
          searchPlaceholder="Search"
          sortByOptions={this.state.sortByOptions}
          onRowClick={row => (
            this._toggle(row.id), this.setState({ row: row })
          )}
          params={{
            partnerId:
              this.props && this.props.partnerId ? this.props.partnerId : ""
          }}
        >
          <ReduxColumn
            type="link"
            isClickable="true"
            field="name"
            sortBy="name"
          >
            Name
          </ReduxColumn>
          <ReduxColumn isClickable="false" field="notes" sortBy="notes">
            Notes
          </ReduxColumn>
          <ReduxColumn isClickable="false" field="tagName" sortBy="tagName">
            Tag
          </ReduxColumn>
          {(isSuperAdmin(this.props.roleId) ||
            isCompanyAdmin(this.props.roleId) ||
            isCompanyManager(this.props.roleId)) && (
            <ReduxColumn
              field="status"
              disableOnClick
              className="action-column"
              renderField={row => (
                <div className="text-center landing-group-dropdown">
                  <MoreDropdown>
                    {row && row.allowEdit && (
                      <DropdownItem
                        className={"text-danger"}
                        onClick={() =>
                          this.setState({
                            deleteModal: true,
                            deleteData: row
                          })
                        }
                      >
                        Delete
                      </DropdownItem>
                    )}
                  </MoreDropdown>
                </div>
              )}
            >
              Action
            </ReduxColumn>
          )}
        </ReduxTable>
        <DeleteModal
          id="deleteFile"
          isOpen={deleteModal}
          toggle={() => {
            this.setState({ deleteModal: false });
          }}
          title="Delete File"
          label={deleteData.name}
          deleteFunction={() => this._delete(deleteData.id)}
        />
      </>
    );
  }
}

export default File;
