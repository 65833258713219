import React, { Component } from "react";
import DatePicker from "react-date-picker";
import "react-datepicker/dist/react-datepicker.css";

// Components
import Text from "../../components/base/Text";
import Phone from "../../components/base/Phone";
import Email from "../../components/base/Email";
import Currency from "../../components/base/Currency";
import TextArea from "../../components/base/TextArea";

class MdfRequestFormFields extends Component {
  // Render the form fields
  render() {
    return (
      <div className="col-lg-12">
        <div className="field-wrapper">
          <div className="row">
            <div className="col-lg-12 mw-100">
              <Text
                name="title"
                required={true}
                label="Request Title"
                placeholder="Enter Request Title"
                error=""
              />
            </div>
          </div>
        </div>
        <div className="field-wrapper row">
          <div className="col-lg-12 mw-100">
            <Text
              name="activityType"
              label="Type of Marketing Activity"
              placeholder="Enter Type of Marketing Activity"
            />
          </div>
        </div>
        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Text
              name="reason"
              label="Request Reason"
              placeholder="Enter Request Reason"
            />
          </div>
        </div>
        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Text
              name="expectedResult"
              label="Expected Results"
              placeholder="Enter Expected Results"
            />
          </div>
        </div>
        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Currency
              name="requestAmount"
              label="Request Amount"
              placeholder="Amount"
              minValue={0}
            />
          </div>
        </div>
        <div className="field-wrapper row mb-2">
          <div className="col-lg-12 ">
            <div>
              <label>Event Date</label>
            </div>
            <DatePicker
              name="eventDate"
              className="datepicker"
              dayPlaceholder="DD"
              monthPlaceholder="MM"
              yearPlaceholder="YYYY"
              onChange={date => this.props.setDate(date, "eventDate")}
              minDate={new Date()}
              value={this.props.eventDate}
              clearIcon={null}
            />
          </div>
        </div>
        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Text
              name="requestorFirstName"
              label="Requestor’s First Name"
              placeholder="Enter Requestor’s First Name"
            />
          </div>
        </div>
        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Text
              name="requestorLastName"
              label="Requestor’s Last Name"
              placeholder="Enter Requestor’s Last Name"
            />
          </div>
        </div>
        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Text
              name="requestorContactTitle"
              label="Requestor’s Contact Title"
              placeholder="Enter Requestor’s Contact Title"
            />
          </div>
        </div>
        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Email
              name="requestorContactEmail"
              label="Requestor’s Contact Email"
              placeholder="Enter Requestor’s Contact Email"
            />
          </div>
        </div>

        <div className="field-wrapper row">
          <div className="col-lg-12">
            <Phone
              name="requestorContactPhone"
              label="Requestor’s Contact Phone"
              placeholder="Enter Requestor’s Contact Phone"
            />
          </div>
        </div>

        <div className="field-wrapper w-100 row">
          <div className="col-lg-12">
            <TextArea
              name="notes"
              label="Notes"
              placeholder="Enter Notes..."
              rows="8"
            />
          </div>
        </div>
      </div>
    );
  }
}

export default MdfRequestFormFields;
