import { LinkedInPopUp } from "react-linkedin-login-oauth2";
import DefaultLayout from "./containers/DefaultLayout";

// Login
import Login from "./views/login";

// Dashboard
import Dashboard from "./views/dashboard";
import AddPage from "./views/coSell/Opportunity/addPage";

// Expert
import expertSignUp from "./views/expertSignUp";
import FindAnExpert from "./views/findAnExpert";
import ExpertRequest from "./views/expertRequest";
import ExpertRequestDetails from "./views/expertRequest/ExpertRequestDetails";
import ExpertCapacity from "./views/expertCapacity";
import ExpertProfile from "./views/sourceExperts/ExpertProfile";
import MarketplaceSourcing from "./views/marketplaceSourcing";
import expertsAvailability from "./views/expertAvailability";

// partner
import partnerSignUp from "./views/partnerSignUp";
import PartnerProfile from "./partners/PartnerProfile";
import PartnerRequest from "./views/partnerRequest";
import PartnerRequestDetails from "./views/partnerRequest/PartnerRequestDetails";
import Activities from "./views/activities";
import Message from "./views/message";
import MessageDetail from "./views/message/Detail";

// Customer
import Customers from "./views/customers";
import AddCustomer from "./views/customers/AddCustomer";
import CustomerDetails from "./views/customers/CustomerDetails";

// Setting
import Settings from "./views/adminSettings/index";
import mySettings from "./views/mySettings";

// Tag
import Tags from "./views/tag";
import AddTag from "./views/tag/AddTag";
import TagDetails from "./views/tag/TagDetails";

// Tag Type
import TagType from "./views/tagType";
import AddTagType from "./views/tagType/AddTagType";
import TagTypeDetails from "./views/tagType/TagTypeDetails";

// Project
import projects from "./views/projects";
import AddProject from "./views/projects/AddProject";
import ProjectEdit from "./views/projects/ProjectEdit";
import ProjectDetails from "./views/projects/ProjectDetails";

// Potential Projects
import PotentialProjects from "./views/potentialProjects";

// Project Category
import ProjectCategory from "./views/projectCategory";
import AddProjectCategory from "./views/projectCategory/AddProjectCategory";
import ProjectCategoryDetails from "./views/projectCategory/ProjectCategoryDetails";

// Users
import users from "./views/users";
import AddUser from "./views/users/AddUser";
import userDetails from "./views/users/userDetails";

// Playbook
import Playbook from "./views/playbook";
import AddPlaybook from "./views/playbook/AddPlaybook";
import PlaybookDetails from "./views/playbook/PlaybookDetails";

// Playbook Landingpage

// Collection
import Collection from "./views/collection";
import AddCollection from "./views/collection/AddCollection";
import CollectionDetails from "./views/collection/CollectionDetails";

// Payment
import AddPayment from "./views/payment/AddPayment";
import PaymentDetails from "./views/payment/PaymentDetails";
import Payment from "./views/payment";
import SinglePayment from "./views/payment/SinglePayment";

// Availability
import Availability from "./views/availability";

// Invoice
import AddInvoice from "./views/invoice/AddInvoice";
import InvoiceDetails from "./views/invoice/InvoiceDetails";
import Invoice from "./views/invoice";

// Reports
import Reports from "./views/reports";
import CoSell from "./views/coSell";

// Feedback
import Feedback from "./views/feedback";
import FeedbackDetails from "./views/feedback/FeedbackDetails";

// Accounting

// Marketplace
import Marketplace from "./views/marketplace";

// 404
import Page404 from "./views/Page404";

// Playbook View
import PlaybookView from "./views/playbookView";
import PlaybookOrderDetails from "./views/playbookView/PlaybookOrderDetails";
import Checkout from "./views/playbookView/Checkout";
import GeneralSettings from "./views/generalSettings";
import Help from "./views/help";

// Edit My Profile
import EditMyGeneralProfile from "./views/editMyGeneralProfile";

// Edit Expert Profile
import EditExpertProfile from "./views/editProfiles/expert/EditExpertProfile";

//Billing

//User management
import UserManagement from "./views/UserManagement";

//Forgot Password
import ForgotPassword from "./views/forgotPassword";

// Salesforce
import Salesforce from "./views/supportPortal/tabs/salesforce";
import SalesforceContact from "./views/supportPortal/tabs/salesforce/contact";
import ContactDetails from "./views/supportPortal/tabs/salesforce/contactDetails";
import SalesforceAccount from "./views/supportPortal/tabs/salesforce/account";
import AccountDetails from "./views/supportPortal/tabs/salesforce/accountDetails";
import SalesforceOpportunity from "./views/supportPortal/tabs/salesforce/opportunity";
import OpportunityDetails from "./views/supportPortal/tabs/salesforce/opportunityDetails";

// Accounting settings
import AccountingSettings from "./views/accountingSettings";

// Partners
import PartnerList from "./views/partner/PartnerList";

// Partner Directory
import PartnersDirectory from "./views/partnersDirectory";

// Tag
import ContactForms from "./views/contactForms";
// import AddCustomForm from "./views/customForms/AddCustomForm";
import ContactFormDetails from "./views/contactForms/ContactFormDetails";

// Embedded Partner Directory
import EmbedpartnersDirectory from "./views/embedpartnersDirectory";

// Embedded Partner Directory
import EmbedPartnerProfile from "../src/partners/EmbedPartnerProfile";

// Deals
import Deals from "./views/deals";

// Deal Register
import AddDeal from "./views/deals/AddDeal";
import DealRegister from "./views/DealRegister";

// Deal Details
import DealDetails from "./views/deals/UpdateDealForm";

// Playbook Inquiry List
import PlaybookInquiry from "./views/playbookInquiry";
import PlaybookInquiryDetails from "./views/playbookInquiry/PlaybookInquiryDetails";

import PartnerContactDetails from "./views/PartnerContactSubmission/PartnerContactDetails";

// Edit Partner Public Profile
import EditPartnerProfile from "./views/editPartnerProfiles/EditPartnerProfile";

// Intro to portal
import IntroToPortal from "./views/introToPortal";

// Leads
import Leads from "./views/leads";

// Resource
import Resource from "./views/resource";
import AddResource from "./views/resource/AddResource";
import EditResource from "./views/resource/EditResource";
import Referral from "./views/referral";
import SubmitReferral from "./views/referral/SubmitReferral";
import EditReferral from "./referral/EditReferral";
import SubmitPublicReferral from "./views/submitReferral";
import Contacts from "./views/contacts";
import EditContact from "./views/contacts/EditContact";
import PartnerUserSignUp from "./views/partnerUserSignUp";
import Accounts from "./views/accounts";
import EditAccount from "./views/accounts/EditAccount";

// Partner Course
import Course from "./views/course";
import EditCourse from "./views/course/EditCourse";
import TrainingCourse from "./views/course/TrainingCourse";

// Companies
import Companies from "./views/companies";
import CompanyDetails from "./views/companies/CompanyDetails";

// Support Portal
import SupportPortal from "./views/supportPortal";

import AccountMappingPartnerRecords from "./views/dashboard/AccountMappingPartners";
import crossBeamReport from "./views/accountMapping/CrossBeamPopulations";

// MDF
import MDF from "./views/mdf";

//salesforce Account Record Type
import RecordType from "./views/supportPortal/tabs/salesforce/recordType";
import SubmitMdfRequest from "./views/mdf/SubmitMdfRequest";
import EditMdfRequest from "./views/mdf/EditMdfRequest";

//App
import EditApp from "./views/app/EditApp";
import AppPublicProfile from "./views/app/Details";

//salesforce Account Record Type
import ReferralDetails from "./views/supportPortal/tabs/ReferralTab/ReferralDetails";

// calendar
import calendar from "../src/components/base/Calendar";

import ReferralRequest from "./views/referral/referralRequest";

import EditReferralRequest from "./views/referral/referralRequest/EditReferral";

import OverallSummary from "./views/reports/tabs/OverAllSummaryTab";
import ReferralReport from "./views/reports/ReferralReport/index";
import PartnerReport from "./views/reports/partnerReport/index";
import PartnerUserReport from "./views/reports/partnerUserReport/index";
import coSellDetail from "./views/coSell/detail";
import TwoFactorAuthentication from "./views/TwoFactorAuthentication";
import PartnerFiles from "./views/files";

// List of routes
const routes = [
  {
    path: "/",
    exact: true,
    name: "Home",
    component: DefaultLayout
  },
  {
    path: "/login",
    exact: true,
    name: "Login",
    component: Login
  },

  {
    path: "/linkedin",
    exact: true,
    name: "Linkedin",
    component: LinkedInPopUp
  },
  {
    path: "/salesforce",
    exact: true,
    name: "Salesforce",
    component: Salesforce
  },
  {
    path: "/dashboard",
    exact: true,
    name: "Dashboard",
    component: Dashboard
  },
  {
    path: "/two-factor-authentication",
    extra: true,
    name: "Two Factor Authentication",
    component: TwoFactorAuthentication
  },
  {
    path: "/help",
    exact: true,
    name: "Help",
    component: Help
  },
  {
    path: "/expert-availability",
    exact: true,
    name: "Expert Availability",
    component: expertsAvailability
  },
  {
    path: "/find-an-expert",
    exact: true,
    name: "Find An Expert",
    component: FindAnExpert
  },
  {
    path: "/expert/requests",
    exact: true,
    name: "Expert Requests",
    component: ExpertRequest
  },
  {
    path: "/expert/capacity",
    exact: true,
    name: "Expert Capacity",
    component: ExpertCapacity
  },
  {
    path: "/settings/general",
    exact: true,
    name: "General Settings",
    component: GeneralSettings
  },
  {
    path: "/settings/tag/:id",
    exact: true,
    name: "Tag Details",
    component: TagDetails
  },
  {
    path: "/settings/tags/add",
    exact: true,
    name: "Add Tag",
    component: AddTag
  },
  {
    path: "/settings/tags",
    exact: true,
    name: "Tags",
    component: Tags
  },
  {
    path: "/settings/tagtypes",
    exact: true,
    name: "Tag Type",
    component: TagType
  },
  {
    path: "/settings/tagtype/add",
    exact: true,
    name: "Add Tag Type",
    component: AddTagType
  },
  {
    path: "/settings/tagtype/:id",
    exact: true,
    name: "Tag Type Details",
    component: TagTypeDetails
  },
  {
    path: "/settings/project/categories",
    exact: true,
    name: "Projects Categories",
    component: ProjectCategory
  },
  {
    path: "/potential-projects",
    exact: true,
    name: "Potential Projects",
    component: PotentialProjects
  },
  {
    path: "/settings/project/category/add",
    exact: true,
    name: "Add Project Categories",
    component: AddProjectCategory
  },
  {
    path: "/settings/project/category/:id",
    exact: true,
    name: "Project Categories Details",
    component: ProjectCategoryDetails
  },
  {
    path: [
      "/admin-settings/:tab",
      "/admin-settings/:tab/:subtab",
      "/admin-settings/:tab/:subtab/:section",
      "/admin-settings/:tab/:subtab/:section/:subsection"
    ],
    exact: true,
    name: "Admin Settings",
    component: Settings
  },
  {
    path: "/my-settings",
    exact: true,
    name: "My Settings",
    component: mySettings
  },
  // {
  //   path: "/sourcing",
  //   exact: true,
  //   name: "Sourcing Experts",
  //   component: Experts
  // },
  {
    path: "/marketplace-sourcing",
    exact: true,
    name: "Sourcing Experts",
    component: MarketplaceSourcing
  },
  // {
  //   path: "/sourcing/expert/:id",
  //   exact: true,
  //   name: "Sourcing Experts Details",
  //   component: ExpertDetails
  // },
  {
    path: "/expert-requests",
    exact: true,
    name: "Expert Requests",
    component: ExpertRequest
  },
  {
    path: "/leads/expert-requests/details/:id",
    exact: true,
    name: "Expert Request Details",
    component: ExpertRequestDetails
  },
  {
    path: "/expert-profile/:id",
    exact: true,
    name: "Expert Profile",
    component: ExpertProfile
  },
  {
    path: "/customers",
    exact: true,
    name: "Customers",
    component: Customers
  },
  {
    path: "/customer/add",
    exact: true,
    name: "Add Customer",
    component: AddCustomer
  },
  {
    path: "/customer/:id",
    exact: true,
    name: "Customer Details",
    component: CustomerDetails
  },
  {
    path: "/expert-signup",
    exact: true,
    name: "Expert SignUp",
    component: expertSignUp
  },
  {
    path: "/partner-signup",
    exact: true,
    name: "Partner SignUp",
    component: partnerSignUp
  },
  {
    path: "/partner-profile/:id",
    exact: true,
    name: "Partner Profile",
    component: PartnerProfile
  },
  {
    path: "/embed/partner-profile/:id",
    exact: true,
    name: "Partner Profile",
    component: EmbedPartnerProfile
  },
  {
    path: "/partners",
    exact: true,
    name: "Partners",
    component: PartnerList
  },
  {
    path: "/partners-directory",
    exact: true,
    name: "Partners",
    component: PartnersDirectory
  },
  {
    path: "/embed/partners-directory",
    exact: true,
    name: "Embedded Partners",
    component: EmbedpartnersDirectory
  },
  {
    path: "/partner-requests",
    exact: true,
    name: "Partner Requests",
    component: PartnerRequest
  },
  {
    path: "/partner-requests/details/:id",
    exact: true,
    name: "Partner Requests Details",
    component: PartnerRequestDetails
  },
  {
    path: "/activities",
    exact: true,
    name: "Activities",
    component: Activities
  },
  {
    path: "/projects",
    exact: true,
    name: "Projects",
    component: projects
  },
  {
    path: "/project/new",
    exact: true,
    name: "Add Project",
    component: AddProject
  },
  {
    path: "/project/edit/:id",
    exact: true,
    name: "Project Edit",
    component: ProjectEdit
  },
  {
    path: "/project/:id",
    exact: true,
    name: "Project Details",
    component: ProjectDetails
  },
  {
    path: "/users",
    exact: true,
    name: "Users",
    component: users
  },
  {
    path: "/edit/profile",
    exact: true,
    name: "Edit Profile",
    component: EditMyGeneralProfile
  },
  {
    path: "/edit/public-profile",
    exact: true,
    name: "Edit Public Profile",
    component: EditExpertProfile
  },
  {
    path: "/user/new",
    exact: true,
    name: "New User",
    component: AddUser
  },
  {
    path: "/user/edit/:id",
    exact: true,
    name: "User Details",
    component: userDetails
  },
  {
    path: "/playbook/new",
    exact: true,
    name: "Add Playbook",
    component: AddPlaybook
  },
  {
    path: "marketplace-sourcing/playbook/edit/:id",
    exact: true,
    name: "Playbook Details",
    component: PlaybookDetails
  },

  {
    path: "/playbooks",
    exact: true,
    name: "Playbooks",
    component: Playbook
  },
  {
    path: "/leads/playbook-inquiry",
    exact: true,
    name: "Playbook Inquiry",
    component: PlaybookInquiry
  },
  {
    path: "/marketplace-sourcing/playbook/edit/:id",
    exact: true,
    name: "Playbook Details",
    component: PlaybookDetails
  },
  {
    path: "/collection/add",
    exact: true,
    name: "Add Collection",
    component: AddCollection
  },
  {
    path: "/collection/:id",
    exact: true,
    name: "Collection Details",
    component: CollectionDetails
  },
  {
    path: "/collection",
    exact: true,
    name: "Collection",
    component: Collection
  },
  {
    path: "/payment/add",
    exact: true,
    name: "Add Payment",
    component: AddPayment
  },
  {
    path: "/payment/:id",
    exact: true,
    name: "Payment Details",
    component: PaymentDetails
  },
  {
    path: "/payment",
    exact: true,
    name: "Payment",
    component: Payment
  },
  {
    path: "/invoice/add",
    exact: true,
    name: "Add Payment",
    component: AddInvoice
  },
  {
    path: "/invoice/:id",
    exact: true,
    name: "Invoice Details",
    component: InvoiceDetails
  },
  {
    path: "/invoice",
    exact: true,
    name: "Invoice",
    component: Invoice
  },
  {
    path: [
      "/reports",
      "/reports/:tab",
      "/reports/:tab/:subtab",
      "/reports/:tab/:subtab/:section"
    ],
    exact: true,
    name: "Reports",
    component: Reports
  },
  {
    path: "/co-sell",
    exact: true,
    name: "Co-Sell",
    component: CoSell
  },
  {
    path: "/co-sell/detail/:id",
    exact: true,
    name: "Co-Sell Detail",
    component: coSellDetail
  },
  // {
  //   path: "/reports/pipeline",
  //   exact: true,
  //   name: "Pipeline",
  //   component: Pipeline
  // },
  {
    path: "/feedback",
    exact: true,
    name: "Feedback",
    component: Feedback
  },
  {
    path: "/feedback/details/:id",
    exact: true,
    name: "Feedback Details",
    component: FeedbackDetails
  },
  {
    path: "/accounting",
    exact: true,
    name: "Accounting",
    component: Payment
  },
  {
    path: "/accounting-settings",
    exact: true,
    name: "Accounting Settings",
    component: AccountingSettings
  },
  {
    path: "/billings",
    exact: true,
    name: "Billings",
    component: Payment
  },
  {
    path: "/accounting/billings/:id",
    name: "Billing Details",
    component: SinglePayment
  },
  {
    path: "/marketplace/:id",
    exact: true,
    name: "Marketplace",
    component: Marketplace
  },
  {
    path: "/marketplace-sourcing/app/edit",
    exact: true,
    name: "Edit App",
    component: EditApp
  },
  {
    path: "/app/:id",
    exact: true,
    name: "AppPublicProfile",
    component: AppPublicProfile
  },
  {
    path: "/playbook-view",
    exact: true,
    name: "Playbook View",
    component: PlaybookView
  },
  {
    path: "/playbook/order/summary/:id",
    exact: true,
    name: "Playbook Order Details",
    component: PlaybookOrderDetails
  },
  {
    path: "/user-management",
    exact: true,
    name: "User Management",
    component: UserManagement
  },
  {
    path: "/playbook/order/checkout/:id",
    exact: true,
    name: "Checkout",
    component: Checkout
  },
  {
    path: "/company/settings",
    extra: true,
    name: "Company Setting",
    component: CustomerDetails
  },
  {
    path: "/forgot-password",
    extra: true,
    name: "Forgot Password",
    component: ForgotPassword
  },
  {
    path: "/availability",
    extra: true,
    name: "Availability",
    component: Availability
  },
  {
    path: "/contact-forms",
    exact: true,
    name: "Contact Forms",
    component: ContactForms
  },
  // {
  //   path: "/contact-form/",
  //   exact: true,
  //   name: "Add Tag",
  //   component: AddTag
  // },
  {
    path: "/contact-form/details/:id",
    exact: true,
    name: "Contact Form Details",
    component: ContactFormDetails
  },
  {
    path: "/deals",
    exact: true,
    name: "Deals",
    component: Deals
  },
  {
    path: "/deal/add",
    exact: true,
    name: "Deal Register",
    component: AddDeal
  },
  {
    path: "/deal/register",
    exact: true,
    name: "Deal Register",
    component: DealRegister
  },
  {
    path: "/deal/details/:id",
    exact: true,
    name: "Deal Details",
    component: DealDetails
  },
  {
    path: "/intro-to-portal",
    exact: true,
    name: "Intro To Portal",
    component: IntroToPortal
  },
  {
    path: "/leads/playbook-inquiry/:id",
    exact: true,
    name: "Edit Playbook Inquiry",
    component: PlaybookInquiryDetails
  },
  {
    path: "/leads/partner-contact/details/:id",
    exact: true,
    name: "Edit Partner Contact",
    component: PartnerContactDetails
  },
  {
    name: "Edit Profile",
    path: "/edit/partner/public-profile",
    component: EditPartnerProfile
  },
  {
    name: "Leads",
    path: "/leads",
    component: Leads
  },
  {
    path: "/files",
    exact: true,
    name: "Partner Files",
    component: PartnerFiles
  },
  {
    path: "/resources",
    exact: true,
    name: "Partner Resource",
    component: Resource
  },
  {
    path: "/resource/add",
    exact: true,
    name: "Add Resource",
    component: AddResource
  },
  {
    path: "/resource/edit/:id",
    exact: true,
    name: "Edit Resource",
    component: EditResource
  },
  {
    path: "/course",
    exact: true,
    name: "Partner Training",
    component: Course
  },

  {
    path: "/course/edit/:id",
    exact: true,
    name: "Edit Course",
    component: EditCourse
  },
  {
    path: "/course/details/:id",
    exact: true,
    name: "Training Course",
    component: TrainingCourse
  },
  {
    path: "/partner-communications",
    exact: true,
    name: "PartnerCommunications",
    component: Message
  },
  {
    path: "/communication/:id",
    exact: true,
    name: "PartnerCommunications Details",
    component: MessageDetail
  },
  {
    path: ["/referrals", "/referrals/:status"],
    exact: true,
    name: "Referrals",
    component: Referral
  },
  {
    path: "/referral/add",
    exact: true,
    name: "Register Referral",
    component: SubmitReferral
  },
  {
    path: "/referral/edit/:id",
    exact: true,
    name: "Edit Referral",
    component: EditReferral
  },
  {
    path: "/referral/submit",
    exact: true,
    name: "Submit Referral",
    component: SubmitPublicReferral
  },
  {
    path: "/contacts",
    exact: true,
    name: "Contacts",
    component: Contacts
  },
  {
    path: "/contact/edit/:id",
    exact: true,
    name: "Edit Contacts",
    component: EditContact
  },
  {
    path: "/partner-user-signup",
    exact: true,
    name: "Partner User SignUp",
    component: PartnerUserSignUp
  },
  {
    path: "/accounts",
    exact: true,
    name: "accounts",
    component: Accounts
  },
  {
    path: "/account/edit/:id",
    exact: true,
    name: "Edit Accounts",
    component: EditAccount
  },
  {
    path: "/salesforce/contact",
    exact: true,
    name: "Salesforce Contact",
    component: SalesforceContact
  },
  {
    path: "/support-portal/salesforce/contact/:id",
    exact: true,
    name: "Salesforce Contact Details",
    component: ContactDetails
  },
  {
    path: "/salesforce/account",
    exact: true,
    name: "Salesforce Account",
    component: SalesforceAccount
  },
  {
    path: "/support-portal/salesforce/account/:id",
    exact: true,
    name: "Salesforce Account Details",
    component: AccountDetails
  },
  {
    path: "/salesforce/opportunity",
    exact: true,
    name: "Salesforce Opportunity",
    component: SalesforceOpportunity
  },
  {
    path: "/salesforce/RecordType",
    exact: true,
    name: "Salesforce Account Record Type",
    component: RecordType
  },
  {
    path: "/support-portal/salesforce/opportunity/:id",
    exact: true,
    name: "Salesforce Opportunity Details",
    component: OpportunityDetails
  },
  {
    path: "/support-portal/referral/:id",
    exact: true,
    name: "Referral Details",
    component: ReferralDetails
  },
  // {
  //   path: "/account-mapping",
  //   exact: true,
  //   name: "Account Mapping",
  //   component: AccountMapping
  // },
  {
    path: "/companies",
    exact: true,
    name: "Companies",
    component: Companies
  },
  {
    path: "/company/:id",
    exact: true,
    name: "Company Details",
    component: CompanyDetails
  },
  {
    path: [
      "/support-portal",
      "/support-portal/:tab",
      "/support-portal/:tab/:subtab",
      "/support-portal/:tab/:subtab/:section",
      "/support-portal/:tab/:subtab/:section/:subsection"
    ],
    exact: true,
    name: "Support Portal",
    component: SupportPortal
  },
  {
    path: "/account-mapping",
    exact: true,
    name: "AccountMappingPartnerRecords",
    component: AccountMappingPartnerRecords
  },
  {
    path: "/crossbeam-report",
    exact: true,
    name: "CrossBeamReport",
    component: crossBeamReport
  },
  {
    path: "/course/details/:id",
    exact: true,
    name: "TrainingCourse",
    component: TrainingCourse
  },
  {
    path: "/campaigns",
    exact: true,
    name: "Co-Marketing Campaigns",
    component: projects
  },
  {
    path: "/campaign/new",
    exact: true,
    name: "Add Campaign",
    component: AddProject
  },
  {
    path: "/campaign/calendar",
    exact: true,
    name: "Campaigns Calendar",
    component: calendar
  },
  {
    path: "/campaign/edit/:id",
    exact: true,
    name: "Campaign Edit",
    component: AddProject
  },
  {
    path: "/campaign/:id",
    exact: true,
    name: "Campaign Details",
    component: ProjectDetails
  },
  {
    path: "/mdf-requests",
    exact: true,
    name: "MDF",
    component: MDF
  },
  {
    path: "/mdf-request/new",
    exact: true,
    name: "New MDF Request",
    component: SubmitMdfRequest
  },
  {
    path: "/co-sell/new",
    exact: true,
    name: "New Co-Sell Opportunity",
    component: AddPage
  },
  {
    path: "/mdf-request/details/:id",
    exact: true,
    name: "New MDF Request",
    component: EditMdfRequest
  },
  {
    path: "/referral/request",
    exact: true,
    name: "Referral Request",
    component: ReferralRequest
  },
  {
    path: "/referral/request/:id",
    exact: true,
    name: "Edit Referral Request",
    component: EditReferralRequest
  },
  {
    path: "",
    exact: true,
    name: "Page Not Found",
    component: Page404
  },
  {
    path: "/page404",
    exact: true,
    name: "Page Not Found",
    component: Page404
  },
  {
    path: "/reports/overall-summary",
    exact: true,
    name: "Overall Summary",
    component: OverallSummary
  },
  {
    path: "/reports/referral-report",
    exact: true,
    name: "Referral Report",
    component: ReferralReport
  },
  {
    path: "/reports/partner-report",
    exact: true,
    name: "Partner Report",
    component: PartnerReport
  },
  {
    path: "/reports/partner-user-report",
    exact: true,
    name: "Partner User Report",
    component: PartnerUserReport
  }
];

export default routes;
