import React from "react";

// Configs
import { apiClient } from "../../apiClient";
import { DEFAULT_API_KEY, endpoints } from "../../configs";

import { sortNameByOrder } from "../../lib/helper";

// Components
import NoRecordsFound from "../../components/base/NoRecordsFound";
import PartnerCard from "../../components/PartnerCard";
import Spinner from "../../components/base/Spinner";
import PartnerFilter from "../../components/PartnerFilter";

class EmbeddedPartnersDirectory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      partners: [],
      services: [],
      industries: []
    };
  }

  getPartners = () => {
    this.setState({ partnerLoading: true }, () => {
      apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;

      apiClient
        .get(
          `${
            endpoints().partnerAPI
          }/search?marketplaceStatus=Approved&profileVisibility=true`
        )
        .then(response => {
          const partners = response.data.data || [];

          let services = [],
            platforms = [];

          partners.forEach(partner => {
            const partnerServices = partner.services ? partner.services : [];
            const partnerPlatforms = partner.platforms ? partner.platforms : [];

            partnerServices.length > 0 &&
              partnerServices.forEach(partnerTag => {
                const { tag_id, tag_name } = partnerTag;

                if (!services.find(item => item.id === tag_id)) {
                  services.push({
                    id: tag_id,
                    name: tag_name,
                    value: tag_id,
                    label: tag_name
                  });
                }
              });
            partnerPlatforms.length > 0 &&
              partnerPlatforms.forEach(partnerTag => {
                const { tag_id, tag_name } = partnerTag;
                if (!platforms.find(item => item.id === tag_id)) {
                  platforms.push({
                    id: tag_id,
                    name: tag_name,
                    value: tag_id,
                    label: tag_name
                  });
                }
              });
          });

          this.setState(
            {
              partners,
              services: sortNameByOrder(services),
              platforms: sortNameByOrder(platforms),
              partnerLoading: false
            },
            () => {
              this.filterPartnerList();
            }
          );
        });
    });
  };

  // Filter Partners Collection
  filterPartnerList = (partnerTagIds, partnerTiers, countryName) => {
    const tags = partnerTagIds || [];
    const tiers = partnerTiers || [];
    const country = countryName || "";

    const { partners } = this.state;
    let filteredPartnerList = [];

    // Filter partners
    if (partners && partners.length > 0) {
      this.setState({ isPartnerLoading: true }, () => {
        partners.forEach(partner => {
          const partnerTier = partner.partnerTierName || "";
          const partnerServices = partner.services || [];
          const partnerPlatforms = partner.platforms || [];
          const partnerCountry = partner.countryName || "";
          let isTypeExist = false;
          let isCountryExist = false;
          let isTagExist = false;

          if (partnerCountry === country || country === "")
            isCountryExist = true;
          if (tiers && tiers.length > 0) {
            tiers.forEach(tierName => {
              let isTierExist = false;
              isTierExist = partnerTier === tierName ? true : false;

              if (isTierExist) {
                isTypeExist = true;
              }
            });
          } else {
            isTypeExist = true;
          }

          if (tags && tags.length > 0) {
            tags.forEach(tagName => {
              let isServiceExist = false;
              let isPlatformExist = false;
              isServiceExist = this.isTagExist(partnerServices, tagName);
              isPlatformExist = this.isTagExist(partnerPlatforms, tagName);
              if (isServiceExist || isPlatformExist) {
                isTagExist = true;
              }
            });
          } else {
            isTagExist = true;
          }
          if (isTypeExist && isCountryExist && isTagExist) {
            filteredPartnerList.push(partner);
          }
        });
        this.setState({
          filteredPartnerList: filteredPartnerList,
          isPartnerLoading: false
        });
      });
    }
  };
  // Check is tag exist in partner tag data
  isTagExist = (tagList, filterTagName) => {
    if (!tagList.length) {
      return false;
    }

    let isExist = false;
    tagList.forEach(tag => {
      if (filterTagName == tag.tag_id) {
        isExist = true;
      }
    });

    return isExist;
  };

  componentDidMount() {
    // Check is logged in user
    this.getPartners();
  }

  render() {
    const { partners, partnerLoading, filteredPartnerList } = this.state;
    return (
      <>
        {/* /.page-heading */}
        <div className="embedded-partners-directory">
          <div className="content-wrapper">
            <div className="row">
              <div className="col-12">
                <PartnerFilter
                  services={this.state.services}
                  industries={this.state.platforms}
                  filterPartnerList={this.filterPartnerList}
                />
              </div>

              {partnerLoading ? (
                <div className="col-12">
                  <div className="card pt-5 pb-5 mb-5">
                    <div className="pt-5 pb-5">
                      <Spinner />
                    </div>
                  </div>
                </div>
              ) : filteredPartnerList && filteredPartnerList.length > 0 ? (
                filteredPartnerList.map(partner => (
                  <PartnerCard
                    classnames={""}
                    data={partner}
                    size="col-12 col-md-6 col-lg-3"
                    key={partner.id}
                    certified={true}
                    link={`/embed/partner-profile/${partner.id}`}
                    fixedWidth={false}
                  />
                ))
              ) : (
                <div className="col-12">
                  <NoRecordsFound
                    position={"top"}
                    message="No Partners Found"
                  />
                </div>
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default EmbeddedPartnersDirectory;
