/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { Component } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

// Components
import AddButton from "../../../../components/base/AddButton";
import Text from "../../../../components/base/Text";
import Form from "../../../../components/base/Form";
import Hint from "../../../../components/base/Hint";
import TextArea from "../../../../components/base/TextArea";

// Helper
import { toString } from "../../../../lib/helper";

// Assets
import createLandingIcon from "../../../../assets/pages/landing-page/create-landing-page.png";

// Action
import { addLandingPage } from "../../../../actions/landingPage";

class AddLandingPageModal extends Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      vanityUrlSlug: "",
      isSlugEmpty: false,
      isOpen: false
    };
    this.handleChange = this.handleChange.bind(this);
    this._toggle = this._toggle.bind(this);
    this.vanityUrlSlugHandleChange = this.vanityUrlSlugHandleChange.bind(this);
  }

  // Landing Page Create API Call
  landingPageCreate(data, toggle) {
    this.props.actions.addLandingPage(data, toggle);
  }

  handleChange(e) {
    const name = e.target.value;
    const vanityUrlSlug = name
      .split(" ")
      .join("-")
      .toLowerCase();

    this.setState({ vanityUrlSlug });
  }

  vanityUrlSlugHandleChange(e) {
    const vanityUrlSlug = e.target.value.toLowerCase();
    this.setState({ vanityUrlSlug });
  }

  _toggle() {
    this.setState({ isOpen: !this.state.isOpen, vanityUrlSlug: "" });
  }

  render() {
    const { isOpen, isSlugEmpty } = this.state;

    return (
      <div className="">
        <AddButton
          label="Add New"
          className="pull-right btn btn-secondary"
          onClick={this._toggle}
        />
        <Modal
          isOpen={isOpen}
          toggle={this._toggle}
          className="custom-modal-popup w-100"
          backdrop="static"
        >
          <ModalHeader toggle={this._toggle}>
            <div className="content-wrapper">
              <div className="icon-wrapper">
                <div className="row justify-content-center mb-2 mt-3">
                  <img src={createLandingIcon} size="30" alt="" />
                </div>
                <p className="text-center mb-3">Create a new Landing Page</p>
                <p
                  className="text-center"
                  style={{ fontSize: "14px", lineHeight: "20px" }}
                ></p>
              </div>
              {/* /.icon-wrapper */}
            </div>
            {/* /.content-wrapper */}
          </ModalHeader>

          <Form
            initialValues={{
              name: "",
              vanityUrlSlug: ""
            }}
            onSubmit={values => {
              values.name = toString(values.name);
              values.vanityUrlSlug = toString(this.state.vanityUrlSlug);
              values.description = toString(values.description);
              if (!values.vanityUrlSlug) {
                this.setState({ isSlugEmpty: true });
                return false;
              }
              this.landingPageCreate(values, this._toggle);
            }}
          >
            <ModalBody>
              <div className="mt-2 mb-3">
                <div className="mb-2">
                  <Text
                    name="name"
                    label="Title"
                    placeholder="Landing Display Page Name..."
                    onChange={this.handleChange}
                    error=""
                    required
                  />
                  <Hint
                    id="name"
                    hintText="This will be the heading for your landing page"
                  />
                </div>
                <div className="mb-2 pb-1">
                  <label>Vanity URL Slug</label>
                  <input
                    type="text"
                    placeholder="Vanity URL Slug"
                    name="vanityUrlSlug"
                    className={"form-control default-input"}
                    value={this.state.vanityUrlSlug}
                    onChange={this.vanityUrlSlugHandleChange}
                  />
                  <small
                    className={`text-danger position-absolute ${
                      isSlugEmpty ? "d-block" : "d-none"
                    }`}
                  >
                    Slug url is required
                  </small>
                </div>
                <div className="mb-2">
                  <TextArea
                    name="description"
                    label="Quick Description"
                    placeholder="Type something..."
                  />
                  <Hint
                    id="description"
                    hintText="This will be a short blurb below your heading"
                  />
                </div>
              </div>
            </ModalBody>
            <ModalFooter>
              <div className="container-fluid">
                <div className="col-sm-12 text-center">
                  <Button type="submit" label="">
                    Create Landing Page
                  </Button>
                </div>
              </div>
            </ModalFooter>
          </Form>
        </Modal>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators({ addLandingPage }, dispatch)
  };
}

export default connect(() => {
  return {};
}, mapDispatchToProps)(AddLandingPageModal);
