import React from "react";
import PropTypes from "prop-types";
import defaultAvatar from "../../assets/default-avatar.png";
import { EditIconAlt } from "../../assets/img/icons";

class Avatar extends React.Component {
  render() {
    let {
      id,
      url,
      firstName,
      lastName,
      companyName,
      defaultUrl,
      size,
      bgColor,
      square,
      customSize,
      imageSize,
      fontSize,
      className,
      customStyle,
      allowEdit,
      headerTextColor,
      contain,
      lineHeight,
      width,
      fullName
    } = this.props;
    const sizes = {
      xs: 32,
      sm: 45,
      md: 60,
      lg: "100%",
      customSize: parseInt(imageSize, 10)
    };

    let dimension;
    if (customSize) {
      dimension = customSize;
    }

    if (!dimension && size) {
      dimension = sizes[size];
    }

    if (!dimension) {
      dimension = sizes["sm"];
    }

    if (width) {
      dimension = width;
    }

    const defaultStyle = {
      width: dimension,
      height: dimension,
      display: "block",
      borderRadius: square ? "0" : "50%"
    };

    if (url) {
      let backgroundImage = `url(${url})`;
      if (defaultUrl) {
        backgroundImage = `${backgroundImage}, url(${defaultUrl})`;
      }

      const style = Object.assign({}, defaultStyle, {
        backgroundSize: contain ? contain : "cover",
        backgroundRepeat: "no-repeat",
        backgroundImage,
        backgroundPosition: "center"
      });

      return (
        <>
          <div
            id={id}
            style={style}
            className={["avatar-picture cursor-pointer"].join(" ")}
          />{" "}
          <div>
            {allowEdit === true && (
              <span
                style={{
                  position: "absolute",
                  right: "0",
                  bottom: "0",
                  padding: "10px",
                  borderRadius: "8px",
                  color: headerTextColor || "#ffffff"
                }}
                className="pt-5 cursor-pointer"
              >
                <EditIconAlt style={{ verticalAlign: "bottom" }} />
              </span>
            )}
          </div>
        </>
      );
    }

    if (firstName || lastName || companyName || fullName) {
      const initial = [];
      if (firstName) {
        firstName = firstName.trim();
        initial.push(firstName[0]);
      }

      if (lastName) {
        lastName = lastName.trim();
        initial.push(lastName[0]);
      }

      if (companyName) {
        companyName = companyName.trim();
        initial.push(companyName[0]);
      }

      if (initial.length === 1 && firstName) {
        initial.push(firstName[1]);
      }

      if (initial.length === 1 && lastName) {
        initial.push(lastName[1]);
      }

      if (initial.length === 1 && companyName) {
        initial.push(companyName[1]);
      }

      if (initial.length > 0) {
        const style = Object.assign({}, defaultStyle, {
          lineHeight: lineHeight ? lineHeight : `${dimension}px`,
          backgroundColor: bgColor || "#212121",
          color: headerTextColor || "#ffffff",
          textTransform: "uppercase",
          textAlign: "center",
          fontSize: fontSize || 15,
          letterSpacing: 1
        });

        return (
          <>
            <div id={id} className={customStyle} style={style}>
              {initial.join("")}
            </div>
            <div>
              {allowEdit === true ? (
                <span
                  style={{
                    position: "absolute",
                    right: "0",
                    bottom: "0",
                    padding: "10px",
                    borderRadius: "8px",
                    color: headerTextColor || "#ffffff"
                  }}
                  className="pt-5 cursor-pointer"
                >
                  <EditIconAlt style={{ verticalAlign: "bottom" }} />
                </span>
              ) : (
                ""
              )}
            </div>
          </>
        );
      } else if (fullName) {
        const style = Object.assign({}, defaultStyle, {
          lineHeight: lineHeight ? lineHeight : `${dimension}px`,
          backgroundColor: bgColor || "#212121",
          color: headerTextColor || "#ffffff",
          textTransform: "uppercase",
          textAlign: "center",
          fontSize: fontSize || 18
        });

        return (
          <>
            <div id={id} className={customStyle} style={style}>
              {fullName}
            </div>
          </>
        );
      }
    }

    if (defaultUrl) {
      const style = Object.assign({}, defaultStyle, {
        backgroundSize: "cover",
        backgroundImage: `url(${defaultUrl})`,
        margin: "auto"
      });

      return <div id={id} style={style} />;
    }

    const style = Object.assign({}, defaultStyle, {
      backgroundSize: "cover",
      backgroundImage: `url(${defaultAvatar})`
    });

    return <div id={id} className={className} style={style} />;
  }
}

Avatar.propTypes = {
  url: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  defaultUrl: PropTypes.string,
  className: PropTypes.string,
  size: PropTypes.string,
  bgColor: PropTypes.string,
  color: PropTypes.string,
  square: PropTypes.bool,
  customSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Avatar;
