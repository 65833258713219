import React, { useState } from "react";

// Components
import Form from "../../../../components/base/Form";
import DefaultContent from "../../../../components/content/DefaultContent";
import Email from "../../../../components/base/Email";
import MultiEmail from "../../../../components/base/MultiselectCreatable";
import SaveButton from "../../../../components/base/SaveButton";

// Constants
import {
  SETTINGS_DEAL_SUBMISSION_NOTIFICATION_EMAIL,
  SETTINGS_EXPERT_REQUEST_NOTIFICATION_EMAIL,
  SETTINGS_PARTNER_REQUEST_NOTIFICATION_EMAIL,
  SETTINGS_REFERRAL_SUBMISSION_NOTIFICATION_EMAIL
} from "../../../../setting/Constants";
import { isTrue } from "../../../../common/utils";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";

const EmailSection = props => {
  const { adminSettings, saveSettings } = props;

  const {
    marketplace_from_email,
    marketplace_from_email_display_name,
    expert_request_notification_email,
    partner_request_notification_email,
    referral_submission_notification_email,
    deal_submission_notification_email,
    partner_invite_email_subject,
    partner_invite_email_message,
    enable_experts,
    enable_partners,
    enable_referrals,
    enable_deals
  } = adminSettings;

  // expert email
  let expertEmails = [];
  if (expert_request_notification_email) {
    let email = expert_request_notification_email.split(",");
    email.forEach(element => {
      expertEmails.push({ value: element, label: element });
    });
  }

  let partnerEmails = [];
  // partner request email
  if (partner_request_notification_email) {
    let email = partner_request_notification_email.split(",");
    email.forEach(element => {
      partnerEmails.push({ value: element, label: element });
    });
  }

  let referralEmails = [];
  // referral submit email
  if (referral_submission_notification_email) {
    let email = referral_submission_notification_email.split(",");
    email.forEach(element => {
      referralEmails.push({ value: element, label: element });
    });
  }

  let dealEmails = [];
  // referral submit email
  if (deal_submission_notification_email) {
    let email = deal_submission_notification_email.split(",");
    email.forEach(element => {
      dealEmails.push({ value: element, label: element });
    });
  }

  // Notification Settings Email Section Initial Values
  const notificationSettingsEmailSectionInitialValues = {
    marketplace_from_email,
    marketplace_from_email_display_name,
    expert_request_notification_email: expertEmails ? expertEmails : "",
    partner_request_notification_email: partnerEmails ? partnerEmails : "",
    referral_submission_notification_email: referralEmails
      ? referralEmails
      : "",
    deal_submission_notification_email: dealEmails ? dealEmails : "",
    partner_invite_email_subject,
    partner_invite_email_message
  };

  // Notification Settings Email Section Submit Form
  const submit = values => {
    const data = new FormData();

    // Expert request notification email
    if (values.marketplace_from_email !== undefined) {
      data.append(
        "marketplace_from_email",
        values.marketplace_from_email ? values.marketplace_from_email : ""
      );
    }

    // Expert request notification email
    if (values.marketplace_from_email_display_name !== undefined) {
      data.append(
        "marketplace_from_email_display_name",
        values.marketplace_from_email_display_name
          ? values.marketplace_from_email_display_name
          : ""
      );
    }

    let expertEmail = [];
    // Expert request notification email
    if (values.expert_request_notification_email !== undefined) {
      values.expert_request_notification_email.forEach(element => {
        expertEmail.push(element.value);
      });
      data.append(
        "expert_request_notification_email",
        expertEmail ? expertEmail : ""
      );
    }

    let partnerEmail = [];
    // Partner request notification email
    if (values.partner_request_notification_email !== undefined) {
      values.partner_request_notification_email.forEach(element => {
        partnerEmail.push(element.value);
      });
      data.append(
        "partner_request_notification_email",
        partnerEmail ? partnerEmail : ""
      );
    }

    let referralEmail = [];
    // Referral submission notification email
    if (values.referral_submission_notification_email !== undefined) {
      values.referral_submission_notification_email.forEach(element => {
        referralEmail.push(element.value);
      });
      data.append(
        "referral_submission_notification_email",
        referralEmail ? referralEmail : ""
      );
    }

    let dealEmail = [];
    // Referral submission notification email
    if (values.deal_submission_notification_email !== undefined) {
      values.deal_submission_notification_email.forEach(element => {
        dealEmail.push(element.value);
      });
      data.append(
        "deal_submission_notification_email",
        dealEmail ? dealEmail : ""
      );
    }

    // Partner invite notification email subject
    if (values.partner_invite_email_subject !== undefined) {
      data.append(
        "partner_invite_email_subject",
        values.partner_invite_email_subject
          ? values.partner_invite_email_subject
          : ""
      );
    }

    // Partner invite notification email message
    if (values.partner_invite_email_message !== undefined) {
      data.append(
        "partner_invite_email_message",
        values.partner_invite_email_message
          ? values.partner_invite_email_message
          : ""
      );
    }

    // Save settings
    saveSettings(data);
  };

  return (
    <Form
      initialValues={notificationSettingsEmailSectionInitialValues}
      onSubmit={values => {
        submit(values);
      }}
    >
      <DefaultContent>
        <div className="form-wrapper">
          {isTrue(enable_experts) && (
            <div>
              <h5 className="font-weight-bold">
                Expert Request Notification Email
              </h5>
              <div className="field-wrapper">
                <MultiEmail
                  id={SETTINGS_EXPERT_REQUEST_NOTIFICATION_EMAIL}
                  name={SETTINGS_EXPERT_REQUEST_NOTIFICATION_EMAIL}
                  placeholder="Enter expert request notification email"
                  validationMessage="email address"
                  options={expertEmails}
                />
              </div>
            </div>
          )}
          {isTrue(enable_partners) && (
            <div>
              <h5 className="font-weight-bold">
                Partner Request Notification Email
              </h5>
              <div className="field-wrapper">
                <MultiEmail
                  id={SETTINGS_PARTNER_REQUEST_NOTIFICATION_EMAIL}
                  name={SETTINGS_PARTNER_REQUEST_NOTIFICATION_EMAIL}
                  placeholder="Enter partner request notification email"
                  validationMessage="email address"
                  options={partnerEmails}
                />
              </div>
            </div>
          )}
          {isTrue(enable_referrals) && (
            <div>
              <h5 className="font-weight-bold">
                {Terminology.get(SystemName.REFERRAL)} Submission Notification
                Email
              </h5>
              <div className="field-wrapper">
                <MultiEmail
                  id={SETTINGS_REFERRAL_SUBMISSION_NOTIFICATION_EMAIL}
                  name={SETTINGS_REFERRAL_SUBMISSION_NOTIFICATION_EMAIL}
                  placeholder="Enter referral submission notification email"
                  validationMessage="email address"
                  options={referralEmails}
                />
              </div>
            </div>
          )}
          {isTrue(enable_deals) && (
            <div>
              <h5 className="font-weight-bold">
                Deal Submission Notification Email
              </h5>
              <div className="field-wrapper">
                <MultiEmail
                  id={SETTINGS_DEAL_SUBMISSION_NOTIFICATION_EMAIL}
                  name={SETTINGS_DEAL_SUBMISSION_NOTIFICATION_EMAIL}
                  placeholder="Enter deal submission notification email"
                  validationMessage="email address"
                  options={dealEmails}
                />
              </div>
            </div>
          )}
        </div>
      </DefaultContent>
      {(enable_referrals == "true" ||
        enable_experts == "true" ||
        enable_partners == "true") && (
        <div className="btn-wrapper">
          <SaveButton />
        </div>
      )}
    </Form>
  );
};

export default EmailSection;
