import {
  AWS_APN_PROGRAM,
  AWS_PRODUCT,
  AWS_COMPETITIVE_TRACKING,
  AWS_DELIVERY_MODEL,
  AWS_INDUSTRY_VERTICAL,
  AWS_SALES_ACTIVITY,
  AWS_SOLUTION_OFFERED,
  AWS_USECASE
} from "../tagType/Constants";

const CoSell = {
  cosellWithAwsOption: [
    {
      value: 1,
      label: "Co-Sell with AWS",
      subtext:
        "Share the opportunity with AWS to receive deal assistance and support."
    },
    {
      value: 2,
      label: "Do Not Need Support from AWS Sales Rep",
      subtext:
        "Share this opportunity with AWS for visibility only, you will not receive deal assistance and support."
    }
  ],
  opportunityTypeOption: [
    {
      value: 1,
      label: "Net New Business",
      subtext:
        "This opportunity is based on a new contract or agreement with this new customer/ logo for your company."
    },
    {
      value: 2,
      label: "Expansion",
      subtext:
        "This opportunity is based on an existing contract with this end customer which may include new line of business, partner product, or additional customer instances."
    }
  ],

  opportunitySourceOption: [
    {
      value: 1,
      label: "Yes: Sourced from marketing activity."
    },
    {
      value: 2,
      label: "No: Not sourced from marketing activity."
    }
  ],

  industryVerticalOption: [
    {
      label: "Aerospace",
      value: "Aerospace"
    },
    {
      label: "Agriculture",
      value: "Agriculture"
    },
    {
      label: "Automotive",
      value: "Automotive"
    },
    {
      label: "Computers & Electronics",
      value: "Computers & Electronics"
    },
    {
      label: "Consumer Goods",
      value: "Consumer Goods"
    },
    {
      label: "Education",
      value: "Education"
    },
    {
      label: "Energy - Oil & Gas",
      value: "Energy - Oil & Gas"
    },
    {
      label: "Energy - Power & Utilities",
      value: "Energy - Power & Utilities"
    },
    {
      label: "Financial Services",
      value: "Financial Services"
    },
    {
      label: "Gaming",
      value: "Gaming"
    },
    {
      label: "Government",
      value: "Government"
    },
    {
      label: "Healthcare",
      value: "Healthcare"
    },
    {
      label: "Hospitality",
      value: "Hospitality"
    },
    {
      label: "Life Sciences",
      value: "Life Sciences"
    },
    {
      label: "Manufacturing",
      value: "Manufacturing"
    },
    {
      label: "Marketing & Advertising",
      value: "Marketing & Advertising"
    },
    {
      label: "Media & Entertainment",
      value: "Media & Entertainment"
    },
    {
      label: "Mining",
      value: "Mining"
    },
    {
      label: "Non-Profit Organization",
      value: "Non-Profit Organization"
    },
    {
      label: "Other",
      value: "Other"
    },
    {
      label: "Professional Services",
      value: "Professional Services"
    },
    {
      label: "Real Estate & Construction",
      value: "Real Estate & Construction"
    },
    {
      label: "Retail",
      value: "Retail"
    },
    {
      label: "Software & Internet",
      value: "Software & Internet"
    },
    {
      label: "Telecommunications",
      value: "Telecommunications"
    },
    {
      label: "Transportation & Logistics",
      value: "Transportation & Logistics"
    },
    {
      label: "Travel",
      value: "Travel"
    },
    {
      label: "Wholesale & Distribution",
      value: "Wholesale & Distribution"
    }
  ],

  useCaseOption: [
    {
      label: "AI Machine Learning and Analytics",
      value: "AI Machine Learning and Analytics"
    },
    {
      label: "Archiving",
      value: "Archiving"
    },
    {
      label:
        "Big Data: Data Warehouse / Data Integration / ETL / Data Lake / BI",
      value:
        "Big Data: Data Warehouse / Data Integration / ETL / Data Lake / BI"
    },
    {
      label: "Blockchain",
      value: "Blockchain"
    },
    {
      label: "Business Applications: Mainframe Modernization",
      value: "Business Applications: Mainframe Modernization"
    },
    {
      label: "Business Applications & Contact Center",
      value: "Business Applications & Contact Center"
    },
    {
      label: "Business Applications & SAP Production",
      value: "Business Applications & SAP Production"
    },
    {
      label: "Centralized Operations Management",
      value: "Centralized Operations Management"
    },
    {
      label: "Cloud Management Tools",
      value: "Cloud Management Tools"
    },
    {
      label:
        "Cloud Management Tools & DevOps with Continuous Integration & Continuous Delivery (CICD)",
      value:
        "Cloud Management Tools & DevOps with Continuous Integration & Continuous Delivery (CICD)"
    },
    {
      label: "Configuration, Compliance & Auditing",
      value: "Configuration, Compliance & Auditing"
    },
    {
      label: "Connected Services",
      value: "Connected Services"
    },
    {
      label: "Containers & Serverless",
      value: "Containers & Serverless"
    },
    {
      label: "Content Delivery & Edge Services",
      value: "Content Delivery & Edge Services"
    },
    {
      label: "Database",
      value: "Database"
    },
    {
      label: "Edge Computing / End User Computing",
      value: "Edge Computing / End User Computing"
    },
    {
      label: "Energy",
      value: "Energy"
    },
    {
      label: "Enterprise Governance & Controls",
      value: "Enterprise Governance & Controls"
    },
    {
      label: "Enterprise Resource Planning",
      value: "Enterprise Resource Planning"
    },
    {
      label: "Financial Services",
      value: "Financial Services"
    },
    {
      label: "Healthcare and Life Sciences",
      value: "Healthcare and Life Sciences"
    },
    {
      label: "High Performance Computing",
      value: "High Performance Computing"
    },
    {
      label: "Hybrid Application Platform",
      value: "Hybrid Application Platform"
    },
    {
      label: "Industrial Software",
      value: "Industrial Software"
    },
    {
      label: "IOT",
      value: "IOT"
    },
    {
      label: "Manufacturing, Supply Chain and Operations",
      value: "Manufacturing, Supply Chain and Operations"
    },
    {
      label: "Media & High performance computing (HPC)",
      value: "Media & High performance computing (HPC)"
    },
    {
      label: "Migration / Database Migration",
      value: "Migration / Database Migration"
    },
    {
      label: "Monitoring, logging and performance",
      value: "Monitoring, logging and performance"
    },
    {
      label: "Monitoring & Observability",
      value: "Monitoring & Observability"
    },
    {
      label: "Networking",
      value: "Networking"
    },
    {
      label: "Outpost",
      value: "Outpost"
    },
    {
      label: "SAP",
      value: "SAP"
    },
    {
      label: "Security & Compliance",
      value: "Security & Compliance"
    },
    {
      label: "Storage & Backup",
      value: "Storage & Backup"
    },
    {
      label: "Training",
      value: "Training"
    },
    {
      label: "VMC",
      value: "VMC"
    },
    {
      label: "VMWare",
      value: "VMWare"
    },
    {
      label: "Web development & DevOps",
      value: "Web development & DevOps"
    }
  ],

  salesActivityOption: [
    {
      label: "Initialized discussions with customer",
      value: "Initialized discussions with customer"
    },
    {
      label: "Customer has shown interest in solution",
      value: "Customer has shown interest in solution"
    },
    {
      label: "Conducted POC / Demo",
      value: "Conducted POC / Demo"
    },
    {
      label: "In evaluation / planning stage",
      value: "In evaluation / planning stage"
    },
    {
      label: "Agreed on solution to Business Problem",
      value: "Agreed on solution to Business Problem"
    },
    {
      label: "Completed Action Plan",
      value: "Completed Action Plan"
    },
    {
      label: "Finalized Deployment Need",
      value: "Finalized Deployment Need"
    },
    {
      label: "SOW Signed",
      value: "SOW Signed"
    }
  ],

  solutionOfferedOption: [
    {
      label: "Other",
      value: "Other"
    }
  ],

  deliveryModelOption: [
    {
      label: "SaaS or PaaS",
      value: "SaaS or PaaS"
    },
    {
      label: "BYOL or AMI",
      value: "BYOL or AMI"
    },
    {
      label: "Managed Services",
      value: "Managed Services"
    },
    {
      label: "Professional Services",
      value: "Professional Services"
    },
    {
      label: "Resell",
      value: "Resell"
    },
    {
      label: "Other",
      value: "Other"
    }
  ],
  awsProductOption: [
    {
      value: "01t8W000006oBUnQAM",
      label: "Alexa for Business"
    },
    {
      value: "01t8W000006oBTGQA2",
      label: "Amazon API Gateway"
    },
    {
      value: "01t8W000006oBSQQA2",
      label: "Amazon AppFlow"
    },
    {
      value: "01t8W000006oBVIQA2",
      label: "Amazon AppStream 2.0"
    },
    {
      value: "01t8W000006oBVJQA2",
      label: "Amazon Athena"
    }
  ],

  apnProgramOption: [
    {
      label: "APN Immersion Days",
      value: "APN Immersion Days"
    },
    {
      label: "APN Solution Space",
      value: "APN Solution Space"
    },
    {
      label: "ATO (Authority to Operate)",
      value: "ATO (Authority to Operate)"
    },
    {
      label: "AWS Marketplace Campaign",
      value: "AWS Marketplace Campaign"
    },
    {
      label: "ISV Immersion Day SFID Program",
      value: "ISV Immersion Day SFID Program"
    },
    {
      label: "ISV Workload Migration",
      value: "ISV Workload Migration"
    },
    {
      label: "Migration Acceleration Program (MAP)",
      value: "Migration Acceleration Program"
    },
    {
      label: "P3",
      value: "P3"
    },
    {
      label: "Partner Launch Initiative",
      value: "Partner Launch Initiative"
    },
    {
      label: "Partner Opportunity Acceleration Funded",
      value: "Partner Opportunity Acceleration Funded"
    },
    {
      label: "The Next Smart",
      value: "The Next Smart"
    },
    {
      label: "VMware Cloud on AWS",
      value: "VMware Cloud on AWS"
    },
    {
      label: "Well-Architected",
      value: "Well-Architected"
    },
    {
      label: "Windows",
      value: "Windows"
    },
    {
      label: "Workspaces/AppStream Accelerator Program",
      value: "Workspaces/AppStream Accelerator Program"
    },
    {
      label: "WWPS NDPP",
      value: "WWPS NDPP"
    }
  ],

  competitiveTrackingOption: [
    {
      label: "Oracle Cloud",
      value: "Oracle Cloud"
    },
    {
      label: "On-Prem",
      value: "On-Prem"
    },
    {
      label: "Co-location",
      value: "Co-location"
    },
    {
      label: "Akamai",
      value: "Akamai"
    },
    {
      label: "AliCloud",
      value: "AliCloud"
    },
    {
      label: "Google Cloud Platform",
      value: "Google Cloud Platform"
    },
    {
      label: "IBM Softlayer",
      value: "IBM Softlayer"
    },
    {
      label: "Microsoft Azure",
      value: "Microsoft Azure"
    },
    {
      label: "Other- Cost Optimization",
      value: "Other- Cost Optimization"
    },
    {
      label: "No Competition",
      value: "No Competition"
    },
    {
      label: "*Other",
      value: "*Other"
    }
  ],
  tagTypeOption: [
    AWS_INDUSTRY_VERTICAL,
    AWS_USECASE,
    AWS_SALES_ACTIVITY,
    AWS_SOLUTION_OFFERED,
    AWS_DELIVERY_MODEL,
    AWS_PRODUCT,
    AWS_APN_PROGRAM,
    AWS_COMPETITIVE_TRACKING
  ]
};

export default CoSell;
