export const intentTag = [
  {
    value: "All",
    label: "All"
  },
  {
    value: "Intent Data Amazon Topics",
    label: "Amazon"
  },
  {
    value: "Intent Data Google Topics",
    label: "Google"
  },
  {
    value: "Intent Data Microsoft Topics",
    label: "Microsoft"
  }
];
