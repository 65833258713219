import React, { Component } from "react";
import { CardElement, injectStripe } from "react-stripe-elements";

// Components
import CancelButton from "../../components/base/CancelButton";

class AddCreditCard extends Component {
  constructor(props) {
    super(props);
    this.state = { cardNumberRequired: true };
  }
  handleSubmit = ev => {
    // We don't want to let default form submission happen here, which would refresh the page.
    ev.preventDefault();
    this.state.cardNumberRequired && this._isEmpty();
    if (this.state.CardErrorMessage === "") {
      // Use Elements to get a reference to the Card Element mounted somewhere
      // in your <Elements> tree. Elements will know how to find your Card Element
      // because only one is allowed.
      // See our getElement documentation for more:
      // https://stripe.com/docs/stripe-js/reference#elements-get-element
      const cardElement = this.props.elements.getElement("card");
      // From here we can call createPaymentMethod to create a PaymentMethod
      // See our createPaymentMethod documentation for more:
      // https://stripe.com/docs/stripe-js/reference#stripe-create-payment-method
      this.props.stripe
        .createPaymentMethod({
          type: "card",
          card: cardElement
        })
        .then(res => {
          if (res && res.paymentMethod) {
            this.props.setPaymentMethod(res.paymentMethod);
            this.props.toggle();
          }
        })
        .then(() => {
          this.props.updatePaymentMethodUi &&
            this.props.updatePaymentMethodUi();
        });
    }
  };

  handleReady = element => {
    this.setState({ cardElement: element });
  };

  handleChange = element => {
    if (element.error) {
      this.setState({
        cardNumberRequired: true,
        CardErrorMessage: element.error.message,
        cardComplete: element.complete
      });
    } else {
      this.setState(
        {
          cardNumberRequired: false,
          cardComplete: element.complete
        },
        () => this._isEmpty()
      );
    }
    if (!element.error && element.empty && !element.complete) {
      this.setState(
        {
          cardNumberRequired: true,
          cardComplete: element.complete
        },
        () => this._isEmpty()
      );
    }
  };

  _isEmpty() {
    if (this.state.cardNumberRequired) {
      if (!this.state.CardErrorMessage) {
        this.setState({
          CardErrorMessage: "Credit Card Number is required"
        });
      }
    } else if (!this.state.cardNumberRequired) {
      this.setState({
        CardErrorMessage: ""
      });
    }
  }

  render() {
    const style = {
      marginTop: "15px",
      base: {
        color: "#32325d",
        fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#aab7c4"
        }
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a"
      }
    };

    const { toggle } = this.props;

    const { CardErrorMessage } = this.state;

    return (
      <form onSubmit={this.handleSubmit}>
        <CardElement
          className="MyCardElement"
          style={style}
          onReady={this.handleReady}
          onChange={this.handleChange}
        />
        <span className="d-block credit-card-error">{CardErrorMessage}</span>
        <div className="btn-wrapper mt-4 d-flex justify-content-center">
          <CancelButton onClick={toggle} className="mr-2" />
          <button
            type="submit"
            className="btn btn-secondary h6-5-important m-1"
            onContextMenu={e => e.preventDefault()}
          >
            <span>Add Credit Card</span>
          </button>
        </div>
      </form>
    );
  }
}

export default injectStripe(AddCreditCard);
