import React from "react";
import { Link } from "react-router-dom";
import LazyLoad from "react-lazyload";

// Helper
import {
  isSuperAdmin,
  isCompanyAdmin,
  setExpertBadge,
  isCompanyManager
} from "../../../lib/helper";
import {
  REFERRAL_PARTNER_STATUS_ACCEPTED,
  REFERRAL_STATUS_CLOSED_WON,
  REFERRAL_PARTNER_STATUS_PENDING
} from "../../../referral/Constants";

// Components
import { Badge } from "reactstrap";
import SidebarItem from "./SidebarItem";
import Avatar from "../../../components/base/Avatar";
import Spinner from "../../../components/base/Spinner";
import { useSelector } from "react-redux";

const PartnerSection = ({
  referralPartnerList,
  currentUserId,
  onSourcePartner,
  isPartnerLoading,
  referralStatus
}) => {
  const spinnerStyle = `margin: 0;  position: absolute;  top: 50%;  left: 35%;`;
  const currentUser = useSelector(state => state.user);

  return (
    <>
      <SidebarItem>
        <p className="font-weight-bold">Partner</p>
        {!referralPartnerList && isPartnerLoading ? (
          <div
            style={{
              position: "relative",
              height: "100px"
            }}
          >
            <Spinner spinnerStyle={spinnerStyle} />
          </div>
        ) : (
          <>
            {referralPartnerList && referralPartnerList.length ? (
              referralPartnerList.map(partner => (
                <>
                  {partner.userId === currentUserId &&
                  partner.status &&
                  partner.status === REFERRAL_PARTNER_STATUS_PENDING ? (
                    <div className="field-wrapper">
                      <div className="user-wrapper">
                        <div className="field-icon">
                          <Link
                            target="_blank"
                            to={`/partner-profile/${
                              partner.companyProfileUrl
                                ? partner.companyProfileUrl
                                : partner.profile_url
                            }`}
                          >
                            <LazyLoad>
                              <Avatar
                                firstName={partner.companyName}
                                size="xs"
                                fontSize={12}
                                url={partner.avatarUrl}
                              />
                            </LazyLoad>
                          </Link>
                        </div>

                        <div className="expert-info ml-2 d-flex flex-column align-items-start">
                          <p className="font-weight-bold">
                            <Link
                              target="_blank"
                              to={`/partner-profile/${
                                partner.companyProfileUrl
                                  ? partner.companyProfileUrl
                                  : partner.profile_url
                              }`}
                            >
                              {partner.userId === currentUserId
                                ? "You"
                                : partner.companyName}
                            </Link>
                          </p>

                          {partner.status &&
                            partner.status !==
                              REFERRAL_PARTNER_STATUS_ACCEPTED && (
                              <div
                                className={[
                                  "status-tooltips d-flex mt-2",
                                  `${partner.status.toLowerCase() ===
                                    "no response" && "align-items-start"}`
                                ].join(" ")}
                              >
                                <Badge
                                  id={`status-tooltip-${partner.partnerId}`}
                                  color={setExpertBadge(partner.status)}
                                  className="text-white h7 font-weight-bold text-uppercase"
                                >
                                  {partner.status}
                                </Badge>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="field-wrapper">
                      <div className="user-wrapper">
                        <div className="field-icon">
                          <Link
                            target="_blank"
                            to={`/partner-profile/${
                              partner.companyProfileUrl
                                ? partner.companyProfileUrl
                                : partner.profile_url
                            }`}
                          >
                            <LazyLoad>
                              <Avatar
                                firstName={partner.companyName}
                                size="xs"
                                fontSize={12}
                                url={partner.avatarUrl}
                              />
                            </LazyLoad>
                          </Link>
                        </div>

                        <div className="expert-info ml-2 d-flex flex-column align-items-start">
                          <p className="font-weight-bold">
                            <Link
                              target="_blank"
                              to={`/edit/partner/public-profile?id=${partner.partnerId}`}
                            >
                              {partner.userId === currentUserId
                                ? "You"
                                : partner.companyName}
                            </Link>
                          </p>

                          {partner.status &&
                            partner.status !==
                              REFERRAL_PARTNER_STATUS_ACCEPTED && (
                              <div
                                className={[
                                  "status-tooltips d-flex mt-2",
                                  `${partner.status.toLowerCase() ===
                                    "no response" && "align-items-start"}`
                                ].join(" ")}
                              >
                                <Badge
                                  id={`status-tooltip-${partner.partnerId}`}
                                  color={setExpertBadge(partner.status)}
                                  className="text-white h7 font-weight-bold text-uppercase"
                                >
                                  {partner.status}
                                </Badge>
                              </div>
                            )}
                        </div>
                      </div>
                    </div>
                  )}
                </>
              ))
            ) : isPartnerLoading ? (
              "Loading..."
            ) : !referralPartnerList.length ? (
              <span className="text-grayed">No partner assigned yet</span>
            ) : (
              ""
            )}
          </>
        )}
        {(isSuperAdmin(currentUser && currentUser.roleId) ||
          isCompanyAdmin(currentUser && currentUser.roleId) ||
          isCompanyManager(currentUser && currentUser.roleId)) &&
          referralPartnerList &&
          referralPartnerList.length < 1 && (
            <button
              id={
                !referralPartnerList.length
                  ? "assignPartnerButton"
                  : "reassignPartnerButton"
              }
              className="btn btn-primary w-100 mt-2"
              type="button"
              onClick={onSourcePartner}
              disabled={
                referralStatus === REFERRAL_STATUS_CLOSED_WON ? true : false
              }
            >
              Assign Partner
            </button>
          )}
      </SidebarItem>
    </>
  );
};

export default PartnerSection;
