import {
  PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_YEARLY,
  PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_QUARTERLY,
  PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_MONTHLY
} from "../../../playbook/Constants";

export function getPricingScheduleValue(pricingSchedule) {
  let pricingScheduleLabel = "";
  if (pricingSchedule === PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_MONTHLY) {
    pricingScheduleLabel = "per month";
  } else if (
    pricingSchedule === PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_QUARTERLY
  ) {
    pricingScheduleLabel = "per quarter";
  } else if (
    pricingSchedule === PLAYBOOK_PRICING_SCHEDULE_TYPE_SUBSCRIPTION_YEARLY
  ) {
    pricingScheduleLabel = "per year";
  }

  return pricingScheduleLabel;
}
