import React, { useRef } from "react";

import {
  TAB_INTENT_DATA_COMPANY,
  TAB_INTENT_DATA_WEEK,
  TAB_INTENT_DATA
} from "../../../../setting/Constants";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import classnames from "classnames";
import CompanyTab from "./companyTab";
import WeeklyTab from "./weeklyTab";
import PageTitle from "../../../../components/base/PageTitle";
import * as XLSX from "xlsx";
import { endpoints } from "../../../../configs";
import { toast } from "react-toastify";
import { apiClient } from "../../../../apiClient";
import FileUploader from "../../../../components/FileUploader";

const IntentDataTab = props => {
  const { activeSubTab, toggleTabs, history } = props;

  // Company Data Start
  const hiddenFileInputRef = useRef(null);
  //Set Media

  const importCompanyData = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const handleFileChange = e => {
    var file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = evt => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      let jsonData = convertToJson(data);

      importBomboraData(jsonData);
    };
    reader.readAsBinaryString(file);
  };

  const convertToJson = data => {
    var result = [];

    if (data && data.length > 0) {
      var headers = data[0];

      if (headers && headers.length > 0) {
        for (let i = 1; i < data.length; i++) {
          var obj = {};
          if (headers.length == data[i].length) {
            for (var j = 0; j < headers.length; j++) {
              obj[headers[j]] = data[i][j];
            }
            result.push(obj);
          }
        }
      }
    }

    //return result; //JavaScript object
    return result; //JSON
  };

  const importBomboraData = data => {
    let fileContent = new Array();
    let content;

    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        content = convertObjectKeysToLowercase(data[i]);

        fileContent.push({
          domain: content.domain,
          product: content.product,
          description: content.product_description,
          vendor: content.vendor,
          category: content.category,
          sub_category: content.sub_category
        });
      }

      if (fileContent) {
        apiClient
          .post(`${endpoints().intentDataAPI}`, { importData: fileContent })
          .then(response => {
            if (response && response.data) {
              let successMessage = response.data.message;
              toast.success(successMessage);
            }
          });
      }
    }
  };

  // Company Data End

  // Weekly Data start
  const importWeeklyData = () => {
    if (hiddenFileInputRef.current) {
      hiddenFileInputRef.current.click();
    }
  };

  const handleWeekFileChange = e => {
    var file = e.target.files[0];

    const reader = new FileReader();
    reader.onload = evt => {
      // evt = on_file_select event
      /* Parse data */
      const bstr = evt.target.result;
      const wb = XLSX.read(bstr, { type: "binary" });
      /* Get first worksheet */
      const wsname = wb.SheetNames[0];
      const ws = wb.Sheets[wsname];
      /* Convert array of arrays */
      const data = XLSX.utils.sheet_to_json(ws, { header: 1 });

      let jsonData = convertToJsonFormat(data);

      importWeekData(jsonData);
    };
    reader.readAsBinaryString(file);
  };

  const convertToJsonFormat = data => {
    var result = [];

    if (data && data.length > 0) {
      var headers = data[0];

      if (headers && headers.length > 0) {
        for (let i = 1; i < data.length; i++) {
          var obj = {};
          if (headers.length == data[i].length) {
            for (var j = 0; j < headers.length; j++) {
              obj[headers[j]] = data[i][j];
            }
            result.push(obj);
          }
        }
      }
    }

    //return result; //JavaScript object
    return result; //JSON
  };

  const excelSerialToDate = serial => {
    const utcDays = Math.floor(serial - 25569);
    const utcValue = utcDays * 86400;
    const dateInfo = new Date(utcValue * 1000);

    const year = dateInfo.getUTCFullYear();
    const month = (dateInfo.getUTCMonth() + 1).toString().padStart(2, "0");
    const day = dateInfo
      .getUTCDate()
      .toString()
      .padStart(2, "0");

    return `${year}-${month}-${day}`;
  };

  const importWeekData = data => {
    let fileContent = new Array();
    let content;

    if (data && data.length > 0) {
      for (let i = 0; i < data.length; i++) {
        content = convertObjectKeysToLowercase(data[i]);

        fileContent.push({
          domain: content.domain,
          date: excelSerialToDate(content.date),
          keyword: content.keyword,
          intent_strength: content.intent_strength,
          is_trending: content.is_trending.toString()
        });
      }

      if (fileContent) {
        apiClient
          .post(`${endpoints().intentDatasAPI}`, { importData: fileContent })
          .then(response => {
            if (response && response.data) {
              let successMessage = response.data.message;
              toast.success(successMessage);
            }
          });
      }
    }
  };

  const convertObjectKeysToLowercase = obj => {
    const convertedObject = {};

    for (let key in obj) {
      if (obj.hasOwnProperty(key)) {
        const lowercaseKey = key.toLowerCase();
        convertedObject[lowercaseKey] = obj[key];
      }
    }

    return convertedObject;
  };

  // Weekly Data End
  const importData =
    activeSubTab === TAB_INTENT_DATA_COMPANY
      ? importCompanyData
      : importWeeklyData;
  const buttonLabel = "Import";
  const handleChangeFunction =
    activeSubTab === TAB_INTENT_DATA_COMPANY
      ? handleFileChange
      : handleWeekFileChange;

  return (
    <>
      <PageTitle
        label=""
        buttonHandler={importData}
        buttonLabel={buttonLabel}
      />
      <FileUploader
        hiddenFileInput={hiddenFileInputRef}
        handleChange={handleChangeFunction}
      />
      <Nav tabs className="admin-tabs">
        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === TAB_INTENT_DATA_COMPANY
            })}
            onClick={() => {
              toggleTabs(TAB_INTENT_DATA, TAB_INTENT_DATA_COMPANY);
            }}
          >
            {TAB_INTENT_DATA_COMPANY}
          </NavLink>
        </NavItem>

        <NavItem>
          <NavLink
            className={classnames({
              active: activeSubTab === TAB_INTENT_DATA_WEEK
            })}
            onClick={() => {
              toggleTabs(TAB_INTENT_DATA, TAB_INTENT_DATA_WEEK);
            }}
          >
            {TAB_INTENT_DATA_WEEK}
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent activeTab={activeSubTab}>
        <TabPane tabId={TAB_INTENT_DATA_COMPANY} className="w-100">
          <CompanyTab history={history} />
        </TabPane>
        <TabPane tabId={TAB_INTENT_DATA_WEEK} className="w-100">
          <WeeklyTab history={history} />
        </TabPane>
      </TabContent>
    </>
  );
};

export default IntentDataTab;
