import React, { useState } from "react";
import PropTypes from "prop-types";
import { Container } from "reactstrap";

// Components
import detectBrowser from "../../components/helpers/detectBrowser";

const BannerHeader = props => {
  const [isMobile] = useState(detectBrowser());

  const { background, classnames, children, btn } = props;

  const { bgLogoImageDesktopImage, bgLogoImageMobile, bgLogoLink } = background;

  const bgLogo = bgLogoImageDesktopImage
    ? bgLogoImageDesktopImage
    : bgLogoImageMobile;

  const styles = {
    backgroundColor: background.bgColor,
    backgroundImage: `url(${
      isMobile ? background.bgImageMobile : background.bgImage
    })`
  };

  const textStyles = {
    color: background.bgTextColor ? background.bgTextColor : "black"
  };

  return (
    <section
      style={styles}
      className={`section banner banner-big ${classnames && classnames}`}
    >
      <Container>
        <div className="section-content">
          {bgLogo && (
            <a
              href={bgLogoLink ? bgLogoLink : "Javascript:void(0);"}
              target={bgLogoLink ? "_blank" : ""}
              className={`logo-wrapper my-auto`}
              rel="noreferrer"
            >
              <img
                src={isMobile ? bgLogoImageMobile : bgLogoImageDesktopImage}
                alt="logo"
              />
            </a>
          )}

          {/* /.logo-wrapper */}
          <div
            className={`content-wrapper ${
              Object.keys(bgLogo).length > 0 ? "text-left" : "text-center"
            }`}
            style={textStyles}
          >
            {children}
            {btn.url && btn.text && (
              <a
                href={btn.url ? btn.url : "Javascript:void(0);"}
                className={`btn btn-outline-light font-weight-bold outline-bold ml-0`}
                target="_blanck"
              >
                {btn.text}
              </a>
            )}
          </div>
        </div>
        {/* /.section-content */}
      </Container>
    </section>
  );
};

BannerHeader.propTypes = {
  background: PropTypes.object,
  classnames: PropTypes.string
};

export default BannerHeader;
