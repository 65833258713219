import React from "react";
import toast from "../../../components/base/Toast";

// Assets
import { SportVestIcon } from "../../../assets/img/icons";

// Component
import NoRecordsFound from "../../../components/base/NoRecordsFound";
import Form from "../../../components/base/Form";
import Text from "../../../components/base/Text";
import Email from "../../../components/base/Email";

import TeamMember from "./TeamMemberUser";

// Configs
import { endpoints } from "../../../configs";

// API call
import { apiClient } from "../../../apiClient";

import {
  PARTNER_ADMIN_ROLE,
  PARTNER_MEMBER_ROLE,
  PARTNER_ADMIN_TEXT,
  PARTNER_MEMBER_TEXT
} from "../../../roles/Constants";
import { isBadRequest } from "../../../common/http";
import Terminology from "../../../lib/Terminology";
import SystemName from "../../../Constants/SystemName";

class AddTeamMember extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInviting: false,
      errorMessage: "",
      successMessage: ""
    };
  }

  // Handle Parter User Invite
  handleSubmit = data => {
    const { partnerId } = this.props;
    this.setState({ isInviting: true });
    return apiClient
      .post(`${endpoints().partnerUserAPI}/invite`, {
        partnerId,
        name: data.name,
        email: data.email,
        invite: data.invite ? data.invite : null
      })
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          if (this.props.getPartnerUsers) {
            this.props.getPartnerUsers();
          }
          this.props.getActivityList();
        }
        this.setState({ successMessage, errorMessage: "" });
        toast.success(successMessage, { containerId: "Team-Member-toast" });
        this.setState({ isInviting: false });
        data.name = "";
        data.email = "";
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          if (
            data.isResend &&
            error.response.data.message ===
              "It looks like that user has already been invited"
          ) {
            toast.success("User Invited Successfully", {
              containerId: "Team-Member-toast"
            });
          } else {
            this.setState({
              errorMessage: error.response.data.message,
              successMessage: ""
            });
            toast.error(error.response.data.message, {
              containerId: "Team-Member-toast"
            });
          }
        }
      });
  };

  handleChange = event => {
    this.setState({ errorMessage: "" });
  };

  // Handle User Role
  handleUserRole = (value, member) => {
    const role =
      value === PARTNER_ADMIN_TEXT ? PARTNER_ADMIN_ROLE : PARTNER_MEMBER_ROLE;

    return apiClient
      .put(`${endpoints().partnerUserAPI}/update/role/${member.id}`, { role })
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage, { containerId: "Team-Member-toast" });
          this.props.getPartnerUsers();
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
            console.log(errorMessage);
          }
          toast.error(error.response.data.message, {
            containerId: "Team-Member-toast"
          });
        }
      });
  };

  // Remove team member
  handleRemove = member => {
    const { partnerId } = this.props;
    let id = member.id;
    return apiClient
      .delete(`${endpoints().partnerUserAPI}/${id}?partnerId=${partnerId}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          toast.success(successMessage, { containerId: "Team-Member-toast" });
          if (this.props.getPartnerUsers) {
            this.props.getPartnerUsers();
          }
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message, {
            containerId: "Team-Member-toast"
          });
          console.error(errorMessage);
        }
      });
  };

  render() {
    const { members } = this.props;
    const { errorMessage, successMessage } = this.state;

    const initialValues = {
      name: "",
      email: ""
    };

    // Customer User Role Options
    const userRoles = [
      {
        value: PARTNER_ADMIN_ROLE,
        label: PARTNER_ADMIN_TEXT
      },
      {
        value: PARTNER_MEMBER_ROLE,
        label: PARTNER_MEMBER_TEXT
      }
    ];

    return (
      <>
        <div className="marketplace-section-content">
          <div className="tab-content-wrapper">
            <div className="add-team-member d-flex flex-column align-items-center text-center">
              <div className="img-wrapper mb-4">
                <SportVestIcon />
              </div>
              <h4 className="mb-2">Add Team Members</h4>
              <p className="mb-4">
                Team members have access to the partner portal and will be able
                to submit {Terminology.get(SystemName.REFERRALS)} and access
                Partner Resources.
              </p>
              <Form
                initialValues={initialValues}
                onSubmit={values => {
                  values.name = values.name ? values.name : null;
                  values.email = values.email ? values.email : null;
                  values.invite = "Invite";
                  this.handleSubmit(values);
                  return false;
                }}
              >
                <div className="team-emal">
                  <Text
                    className="w-100"
                    name="name"
                    placeholder="Enter Name"
                    onChange={e => this.handleChange(e)}
                    required
                  />
                </div>
                <div className="team-emal">
                  <Email
                    className="w-100"
                    name="email"
                    placeholder="Email Address"
                    onChange={e => this.handleChange(e)}
                    required
                  />
                </div>
                {errorMessage && (
                  <small className={errorMessage ? "text-danger" : ""}>
                    {errorMessage || successMessage}
                  </small>
                )}

                <div className="mt-4">
                  <button
                    type="submit"
                    className={["btn", "btn-secondary"].join(" ")}
                  >
                    Send Invitation
                  </button>
                </div>
              </Form>
            </div>
          </div>
        </div>
        {/* Team Members Section*/}
        <div className="marketplace-section-content pt-3 pb-3">
          <div className="">
            <div className="team-members">
              {members && members.length > 0 ? (
                members.map((member, index) => (
                  <TeamMember
                    member={member}
                    index={index}
                    userRoles={userRoles}
                    handleUserRole={this.handleUserRole}
                    removeTeamMember={this.handleRemove}
                    resendInvite={this.handleSubmit}
                  />
                ))
              ) : (
                <NoRecordsFound
                  showMessage="false"
                  message="No team members"
                  position="top"
                  hideCard={true}
                />
              )}
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default AddTeamMember;
