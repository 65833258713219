import React from "react";
import { HowItWorks, HowItWorksItem } from "../expert-components/HowItWorks";
import hiw1 from "../../../assets/img/mock_imgs/customer-hiw-1.png";
import hiw2 from "../../../assets/img/mock_imgs/customer-hiw-2.png";
import hiw3 from "../../../assets/img/mock_imgs/customer-hiw-3.png";

const CustomerHowItWorks = props => (
  <HowItWorks
    title={"Explore How Torchlite Works"}
    close={true}
    handleClose={props.handleClose}
  >
    <HowItWorksItem
      imgsrc={hiw1}
      title="Sourcing Your Expert"
      description="After starting a project, we'll match it with an expert within 48hours. The graphic to left shows an example of what a your email notifcation will look like."
      align="left"
      number="1"
    />
    <HowItWorksItem
      imgsrc={hiw2}
      title="Expert Review"
      description="Let's make sure we've selected the right person for your project. Once you've reviewed our suggested expert,
       you'll be able to select them and move forward, or you'll be matched with a new expert based on your feedback."
      align="right"
      number="2"
    />
    <HowItWorksItem
      imgsrc={hiw3}
      title="Expert Approval"
      description="After you have approved an expert, you will receive an email notification saying that your project has been activated."
      align="left"
      number="3"
    />
  </HowItWorks>
);

export default CustomerHowItWorks;
