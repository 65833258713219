import React, { useState, useEffect } from "react";

// Configs
import { endpoints } from "../../configs";
// API call
import { apiClient } from "../../apiClient";

import Select from "../../components/base/Select";
import { isBadRequest } from "../../common/http";

// Constants
import { COURSE_PUBLISHED } from "../../course/Constants";

const CourseListDropdown = ({
  name,
  label,
  fontBolded,
  placeholder,
  handleChange,
  defaultValue,
  currentCourseId
}) => {
  const [courseList, setCourseList] = useState();

  // Get Course list
  const getCoursesList = () => {
    apiClient
      .get(
        `${endpoints().courseAPI}/list${
          currentCourseId ? "?excludeId=" + currentCourseId : ""
        }`
      )
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;
          setCourseList(response.data.data);
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
        }
      });
  };

  useEffect(() => {
    getCoursesList();
  }, []);

  //Get options
  const getCourseListOption = () => {
    const options = [];

    if (!courseList) return options;

    courseList.forEach(course => {
      if (course.title && course.status === COURSE_PUBLISHED) {
        options.push({
          id: course.id,
          label: course.title,
          value: course.title
        });
      }
    });

    return options;
  };

  return (
    <Select
      name={name}
      label={label}
      fontBolded={fontBolded}
      options={getCourseListOption()}
      placeholder={placeholder}
      defaultValue={getCourseListOption().find(
        type => type.id === defaultValue
      )}
      isSearchable
      handleChange={handleChange}
    />
  );
};

export default CourseListDropdown;
