import React from "react";
import { Modal, ModalBody, ModalHeader, ModalFooter } from "reactstrap";

// Icons
import { DoubleClapGraphicIcon } from "../../../assets/img/icons";

const SecondaryPortalRedirectModel = ({
  isOpen,
  toggle,
  redirectPortalUrl,
  secondaryPortalRedirectMessage
}) => {
  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      className={["log-hours-modal", "edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={toggle}>
        <DoubleClapGraphicIcon />
        <h4 className={["font-weight-bold", "my-4"].join(" ")}>Almost Done!</h4>
      </ModalHeader>

      <ModalBody className={["text-center", "mb-4"].join(" ")}>
        <div className="form-wrapper">
          <div className="field-wrapper">
            <p>{secondaryPortalRedirectMessage}</p>
          </div>
        </div>
      </ModalBody>

      <ModalFooter className={["justify-content-center"].join(" ")}>
        <div className="btn-wrapper">
          <a href={redirectPortalUrl} className="btn btn-primary">
            Continue
          </a>
        </div>
      </ModalFooter>
    </Modal>
  );
};

export default SecondaryPortalRedirectModel;
