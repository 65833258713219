import React, { useState } from "react";

// Components
import Form from "../../components/base/Form";
import TextArea from "../../components/base/TextArea";

const OrderProjectNotes = props => {
  let { onChange, projectNotes } = props;
  projectNotes = projectNotes ? projectNotes : "";
  const initialValues = { projectNotes };
  return (
    <div className="cart-item">
      <h5 className="mb-3">
        <b>Project Details:</b>
      </h5>

      <Form initialValues={initialValues}>
        <div className="row">
          <div className="col-lg-6 ml-0">
            <TextArea
              name="projectNotes"
              // label="Project Details:"
              placeholder="Enter the project details here..."
              defaultValue={projectNotes}
              onChange={onChange}
            />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default OrderProjectNotes;
