import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { Link, Redirect } from "react-router-dom";

// GA
import { gaEvent } from "../../lib/GoogleAnalytics";

// History
import history from "../../history";

// Components
import AuthButtons, {
  GoogleButton,
  LinkedInButton,
  LoginForm,
  OfficeButton,
  SalesforceButton
} from "../../components/authentication/AuthButtons";

// Helper
import { setCookie, getKeyValueByObject } from "../../lib/helper";

// API call
import { apiClient } from "../../apiClient";

// Configs
import { DEFAULT_API_KEY, endpoints } from "../../configs";
import {
  METACX_EVENT_PLAYBOOK_CHECKOUT_USER_LOGIN_BUTTON_CLICK,
  METACX_EVENT_PLAYBOOK_CHECKOUT_GOOGLE_AUTH_BUTTON_CLICK,
  METACX_EVENT_PLAYBOOK_CHECKOUT_LINKEDIN_AUTH_BUTTON_CLICK,
  METACX_EVENT_PLAYBOOK_CHECKOUT_OFFICE365_AUTH_BUTTON_CLICK,
  METACX_EVENT_PLAYBOOK_CHECKOUT_SALESFORCE_AUTH_BUTTON_CLICK,
  METACX_EVENT_PLAYBOOK_CHECKOUT_JOIN_NOW_LINK_CLICK
} from "../../metaCXEvents/Constants";
import { metaCXTrack } from "../../lib/metaCX";
import { COOKIE_SESSION_TOKEN, COOKIE_USER_ID } from "../../lib/cookie";
import {
  SETTINGS_PORTAL_NAME,
  SETTINGS_ENABLE_SALESFORCE_LOGIN,
  SETTINGS_ENABLE_OFFICE_365_LOGIN,
  SETTINGS_ENABLE_GOOGLE_LOGIN,
  SETTINGS_ENABLE_LINKEDIN_LOGIN
} from "../../setting/Constants";
import { isBadRequest } from "../../common/http";

const LoginModal = props => {
  const {
    loginModal,
    setLoginModal,
    playbookId,
    persistState,
    settings
  } = props;
  const [redirectUser, setRedirectUser] = useState(false);
  const redirectUserToDestination = () =>
    setRedirectUser(prevState => !prevState);

  const marketplaceName = settings
    ? getKeyValueByObject(settings, SETTINGS_PORTAL_NAME)
    : "";

  const enableSalesforceLogin = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_SALESFORCE_LOGIN)
    : "";

  const enableGoogleLogin = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_GOOGLE_LOGIN)
    : "";

  const enableOffice365Login = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_OFFICE_365_LOGIN)
    : "";

  const enableLinkedInLogin = settings
    ? getKeyValueByObject(settings, SETTINGS_ENABLE_LINKEDIN_LOGIN)
    : "";

  // Signin with Google OAuth
  const loginByGoogle = (googleToken, callback) => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .post(endpoints().userLogin, {
        googleToken
      })
      .then(response => {
        const {
          token,
          role,
          email,
          firstName,
          lastName,
          userId
        } = response.data;

        setCookie(COOKIE_SESSION_TOKEN, token);
        setCookie(COOKIE_USER_ID, userId);

        // GA Event
        gaEvent({
          category: "Customer Login",
          action: "Clicked on playbook checkout continue with google",
          label: "Customer Google Login",
          value: userId
        });

        // MetaCX Track after user logged-in with google auth when playbook purchase flow
        metaCXTrack(
          METACX_EVENT_PLAYBOOK_CHECKOUT_GOOGLE_AUTH_BUTTON_CLICK,
          {
            email,
            firstName,
            lastName,
            playbookId: `${playbookId}`,
            playbookName: persistState.playbook.name
          },
          settings,
          () => {
            return callback();
          }
        );
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);

          // Get Customer Google Login Details
          apiClient
            .get(
              `${
                endpoints().userAPI
              }/google/auth/details?googleToken=${googleToken}`
            )
            .then(response => {
              const responseData = response.data;
              const { authToken, firstName, lastName, email } = responseData;

              // Redirect to Customer Signup Page
              history.push(
                `/customer-signup?email=${email}&firstName=${firstName}&lastName=${lastName}&authToken=${authToken}&selectedCartType=${persistState.selectedCartType}&redirect=/playbook/order/summary/${playbookId}`
              );
              window.location.reload();
            });
        }
      });
  };

  // Signin with Microsoft Office365
  const loginByOffice365 = (office365Token, callback) => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .post(endpoints().userLogin, {
        office365Token
      })
      .then(response => {
        const {
          token,
          role,
          email,
          firstName,
          lastName,
          userId
        } = response.data;

        setCookie(COOKIE_SESSION_TOKEN, token);
        setCookie(COOKIE_USER_ID, userId);

        // GA Event
        gaEvent({
          category: "Customer Login",
          action: "Clicked on playbook checkout continue with microsoft",
          label: "Customer microsoft Login",
          value: userId
        });

        // MetaCX Track after user logged-in with microsoft office365 auth when playbook purchase flow
        metaCXTrack(
          METACX_EVENT_PLAYBOOK_CHECKOUT_OFFICE365_AUTH_BUTTON_CLICK,
          {
            email,
            firstName,
            lastName,
            playbookId: `${playbookId}`,
            playbookName: persistState.playbook.name
          },
          settings,
          () => {
            return callback();
          }
        );
      });
  };

  // Signin with Linkedin
  const loginByLinkedin = (linkedInToken, callback) => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .post(endpoints().userLogin, {
        linkedInToken
      })
      .then(response => {
        const {
          token,
          role,
          email,
          firstName,
          lastName,
          userId
        } = response.data;

        setCookie(COOKIE_SESSION_TOKEN, token);
        setCookie(COOKIE_USER_ID, userId);

        // GA Event
        gaEvent({
          category: "Customer Login",
          action: "Clicked on playbook checkout continue with linkedin",
          label: "Customer linkedin Login",
          value: userId
        });

        // MetaCX Track after user logged-in with linkedin auth when playbook purchase flow
        metaCXTrack(
          METACX_EVENT_PLAYBOOK_CHECKOUT_LINKEDIN_AUTH_BUTTON_CLICK,
          {
            email,
            firstName,
            lastName,
            playbookId: `${playbookId}`,
            playbookName: persistState.playbook.name
          },
          settings,
          () => {
            return callback();
          }
        );
      });
  };

  // Signin with Salesforce OAuth
  const loginBySalesforce = (salesforceAuthToken, callback) => {
    apiClient.defaults.headers.common.Authorization = DEFAULT_API_KEY;
    return apiClient
      .post(endpoints().userLogin, {
        salesforceAuthToken
      })
      .then(response => {
        const {
          token,
          role,
          email,
          firstName,
          lastName,
          userId
        } = response.data;

        setCookie(COOKIE_SESSION_TOKEN, token);
        setCookie(COOKIE_USER_ID, userId);

        // GA Event
        gaEvent({
          category: "Customer Login",
          action: "Clicked on playbook checkout continue with salesforce",
          label: "Customer salesforce Login",
          value: userId
        });

        // MetaCX Track after user logged-in with salesforce auth when playbook purchase flow
        metaCXTrack(
          METACX_EVENT_PLAYBOOK_CHECKOUT_SALESFORCE_AUTH_BUTTON_CLICK,
          {
            email,
            firstName,
            lastName,
            playbookId: `${playbookId}`,
            playbookName: persistState.playbook.name
          },
          settings,
          () => {
            return callback();
          }
        );
      });
  };

  return (
    <Modal
      isOpen={loginModal}
      toggle={setLoginModal}
      backdrop="static"
      className={["login-modal", "edit-task-modal"].join(" ")}
    >
      <ModalHeader toggle={setLoginModal}>
        <h4 className={["font-weight-bold"].join(" ")}>Login</h4>
      </ModalHeader>
      <ModalBody>
        <p className={["subtitle"].join(" ")}>
          Before continuing with your order, you’ll need to login or join as a
          new member.
        </p>
        <AuthButtons>
          {enableSalesforceLogin === "true" && (
            <SalesforceButton
              redirect={redirectUserToDestination}
              signUpWithSalesforce={loginBySalesforce}
            />
          )}

          {enableGoogleLogin === "true" && (
            <GoogleButton
              redirect={redirectUserToDestination}
              signUpWithGoogle={loginByGoogle}
            />
          )}

          {enableOffice365Login === "true" && (
            <OfficeButton
              redirect={redirectUserToDestination}
              signUpWithOffice365={loginByOffice365}
            />
          )}

          {enableLinkedInLogin === "true" && (
            <LinkedInButton
              redirect={redirectUserToDestination}
              signUpWithLinkedin={loginByLinkedin}
            />
          )}
          {(enableSalesforceLogin === "true" ||
            enableGoogleLogin === "true" ||
            enableOffice365Login === "true" ||
            enableLinkedInLogin === "true") && (
            <div className="login-form-inline-title">
              <p>or log in to your {marketplaceName} account:</p>
            </div>
          )}
          <div className="form-wrapper">
            <LoginForm
              redirect={redirectUserToDestination}
              eventKey={METACX_EVENT_PLAYBOOK_CHECKOUT_USER_LOGIN_BUTTON_CLICK}
              settings={settings}
            />
            {redirectUser && (
              <Redirect
                to={{
                  pathname: `/playbook/order/summary/${playbookId}`,
                  search: `?redirect=${props.redirectUrl}`,
                  state: { ...persistState, successfulLogin: true }
                }}
              />
            )}
          </div>
          <div className="text-center mt-2 h6-5">
            <span>Not a member yet? </span>
            <Link
              to={`/customer-signup?selectedCartType=${persistState.selectedCartType}&redirect=/playbook/order/summary/${playbookId}`}
              onClick={e => {
                // Track MetaCX event for click on Get Social
                metaCXTrack(
                  METACX_EVENT_PLAYBOOK_CHECKOUT_JOIN_NOW_LINK_CLICK,
                  {
                    playbookId: `${playbookId}`,
                    playbookName: persistState.playbook.name
                  },
                  settings,
                  () => {}
                );
              }}
            >
              Join now
            </Link>
          </div>
        </AuthButtons>
      </ModalBody>
    </Modal>
  );
};

export default LoginModal;
