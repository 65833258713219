import React, { useState } from "react";
import { Link } from "react-router-dom";
import MoreDropdown from "../../../components/base/MoreDropdown";
import { DropdownItem } from "reactstrap";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Form from "../../../components/base/Form";

// Components
import Avatar from "../../../components/base/Avatar";
import CancelButton from "../../../components/base/CancelButton";
import Select from "../../../components/base/Select";
import AvatarCard from "../../../components/base/AvatarCard";
// Helper
import { getFullName, isPartner } from "../../../lib/helper";

import {
  PARTNER_USER_INVITED_TEXT,
  PARTNER_USER_ACCEPTED_TEXT,
  PARTNER_USER_INVITED,
  PARTNER_USER_ACCEPTED
} from "../../../roles/Constants";

import { useSelector } from "react-redux";

const statusStyle = {
  borderRadius: "10px",
  color: "#FFFFFF",
  fontWeight: 600,
  width: "75px",
  fontSize: "12px",
  paddingLeft: "5px",
  paddingRight: "5px",
  backgroundColor: "#0AD4BB"
};

const fontStyle = {
  fontSize: "14px"
};

const initialValues = {
  role: ""
};

const TeamMember = ({
  member,
  userRoles,
  removeTeamMember,
  handleUserRole,
  resendInvite
}) => {
  const [removeMember] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);
  const [removeModal, setRemoveModal] = useState(false);
  const [showRoleChangeButton, setShowRoleChangeButton] = useState(true);
  const memberToggle = () => setRemoveModal(!removeModal);

  const [role, setRole] = useState();

  const currentUser = useSelector(state => state.user);

  const handleChange = values => {
    const selectedRole = values.label;
    setRole(selectedRole);
    setShowRoleChangeButton(false);
  };

  return (
    <>
      <div className="team-banner">
        <div className="team-option" style={{ width: "260px" }}>
          {!isPartner(currentUser && currentUser.roleId) ? (
            <AvatarCard
              firstName={getFullName(member.firstName, member.lastName)}
              title={member.email}
              certificateImage={member && member.certificateImage}
              certificateName={member && member.certificateName}
              courseCount={member && member.courseCount}
              link={`/user/edit/${member.id}?redirect=/users?type=Partner`}
              id={member && member.id}
              data={member && member.certificateDetails}
            />
          ) : (
            <AvatarCard
              firstName={getFullName(member.firstName, member.lastName)}
              title={member.email}
              certificateImage={member && member.certificateImage}
              certificateName={member && member.certificateName}
              courseCount={member && member.courseCount}
            />
          )}
        </div>
        {member.last_loggedin_at ? (
          <div className="team-option">
            <div style={fontStyle}>
              <p className="font-weight-bold">Last Login</p>
              <p>{member.last_loggedin_at}</p>
            </div>
          </div>
        ) : (
          <div className="team-option">
            <div style={fontStyle}>
              <p className="font-weight-bold">Invited sent</p>
              <p>{member.createdAt}</p>
            </div>
          </div>
        )}
        <div className="team-option">
          {!removeMember && (
            <div style={fontStyle}>
              <p className="font-weight-bold ">Role</p>
              <p style={{ width: "140px" }}> {member.role || "Admin"}</p>
            </div>
          )}
        </div>
        <div className="team-option mt-2">
          <p
            className={`text-center ${
              member.status !== PARTNER_USER_INVITED
                ? member.status === PARTNER_USER_ACCEPTED
                : "bg-secondary"
            }`}
            style={statusStyle}
            disableOnClick
          >
            {member.status === PARTNER_USER_INVITED
              ? PARTNER_USER_INVITED_TEXT
              : PARTNER_USER_ACCEPTED
              ? PARTNER_USER_ACCEPTED_TEXT
              : ""}
          </p>
        </div>
        <div className="teams-option float-right landing-group-dropdown mt-3">
          <MoreDropdown>
            {
              <DropdownItem
                onClick={() => {
                  resendInvite({
                    name: getFullName(member.firstName, member.lastName),
                    email: member.email,
                    isResend: true
                  });
                }}
              >
                Resend Invite
              </DropdownItem>
            }

            <DropdownItem onClick={toggle}>Change Role</DropdownItem>

            <DropdownItem onClick={memberToggle}>Remove</DropdownItem>
          </MoreDropdown>
        </div>
      </div>

      {/*  Chnage Role Model */}
      <Modal isOpen={isOpen} className={["edit-task-modal"].join(" ")}>
        <ModalHeader toggle={toggle}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Change Partner Role
          </h4>
        </ModalHeader>
        <Form
          initialValues={initialValues}
          onSubmit={() => {
            if (role) {
              handleUserRole(role, member);
              setShowRoleChangeButton(true);
              setTimeout(() => {
                toggle();
              }, 100);
            }
          }}
        >
          <ModalBody className={["mb-4"].join(" ")}>
            <div className="row">
              {!isPartner(currentUser && currentUser.roleId) ? (
                <AvatarCard
                  firstName={getFullName(member.firstName, member.lastName)}
                  title={member.email}
                  certificateImage={member && member.certificateImage}
                  certificateName={member && member.certificateName}
                  courseCount={member && member.courseCount}
                  link={`/user/edit/${member.id}?redirect=/users?type=Partner`}
                  id={member && member.id}
                  data={member && member.certificateDetails}
                />
              ) : (
                <AvatarCard
                  firstName={getFullName(member.firstName, member.lastName)}
                  title={member.email}
                  certificateImage={member && member.certificateImage}
                  certificateName={member && member.certificateName}
                  courseCount={member && member.courseCount}
                />
              )}
            </div>
            <div className="row">
              <p className="font-weight-bold ml-5 pl-3">Partner Role</p>
            </div>
            <div>
              <div className="w-50 ml-5">
                {!removeMember && (
                  <Select
                    name="userRoles"
                    options={userRoles}
                    placeholder={member.role || "Admin"}
                    isSearchable
                    handleChange={handleChange}
                    required={true}
                  />
                )}
              </div>
            </div>
          </ModalBody>
          <ModalFooter className={["justify-content-center"].join(" ")}>
            <div className="btn-wrapper text-center">
              <Button
                type="submit"
                label=""
                disabled={showRoleChangeButton ? true : false}
              >
                Change
              </Button>
            </div>
          </ModalFooter>
        </Form>
      </Modal>

      {/*  Remove Partner User  */}
      <Modal
        isOpen={removeModal}
        toggle={memberToggle}
        className={["edit-task-modal"].join(" ")}
      >
        <ModalHeader toggle={memberToggle}>
          <h4 className={["font-weight-bold"].join(" ")}>
            Remove Partner Member
          </h4>
        </ModalHeader>
        <ModalBody className={["mb-4"].join(" ")}>
          <div className=" d-flex">
            <Avatar
              firstName={member.firstName}
              lastName={member.lastName}
              size="customSize"
              imageSize={"48"}
              url={member.avatarUrl}
              certificateImage={member && member.certificateImage}
              certificateName={member && member.certificateName}
              courseCount={member && member.courseCount}
            />
            <p className="h6-5 font-weight-bold ml-3">
              <Link
                className="text-dark text-decoration-none"
                to={`/user/edit/${member.id}?redirect=/users?type=Partner`}
                style={{ cursor: "pointer" }}
              >
                {getFullName(member.firstName, member.lastName)}
              </Link>

              <div>
                <span className="font-weight-normal text-inline-grayed">
                  ({member.email})
                </span>
              </div>
            </p>
          </div>
        </ModalBody>
        <ModalFooter className={["justify-content-center"].join(" ")}>
          <p className="font-weight-bold">Are you sure you want to remove ?</p>

          <div className="btn-wrapper mt-4 d-flex justify-content-center">
            <CancelButton onClick={memberToggle} className="mr-2" />
            <button
              className="btn btn-secondary h6-5-important m-1"
              onClick={() => {
                removeTeamMember(member);
                setTimeout(() => {
                  memberToggle();
                }, 100);
              }}
            >
              <span>Yes, Remove</span>
            </button>
          </div>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default TeamMember;
