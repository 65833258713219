import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import "react-circular-progressbar/dist/styles.css";
import { getKeyValueByObject } from "../../lib/helper";
import {
  SETTINGS_ENABLE_DEALS,
  SETTINGS_ENABLE_PARTNERS,
  SETTINGS_ENABLE_REFERRALS
} from "../../setting/Constants";
import { PENDING_PARTNERS } from "../../marketplace/Constants";
import ReferralsPopUp from "./ReferralsPopUp";
import ClosedReferralsPopup from "./ClosedReferralsPopup";
import AdminNeedsAttentionCard from "../../components/base/AdminNeedAttentionCard";
// Actions
import { fetchAdminDashboardCount } from "../../actions/dashboard";
import AppReviewPopUp from "../app/components/AppReviewPopUp";
import Terminology from "../../lib/Terminology";
import SystemName from "../../Constants/SystemName";

const AdminNeedsAttention = props => {
  const { pendingDealsCount } = props;

  const dispatch = useDispatch();
  const dashboard = useSelector(state => state.dashboard);

  useEffect(() => {
    dispatch(fetchAdminDashboardCount());
  }, []);

  const adminDashboard = dashboard.adminDashboard;
  const newAppCount =
    dashboard &&
    dashboard.adminDashboard &&
    dashboard.adminDashboard.newAppsCount;

  const enablePartners = props.settings
    ? getKeyValueByObject(props.settings, SETTINGS_ENABLE_PARTNERS)
    : "";
  const enableDeals = props.settings
    ? getKeyValueByObject(props.settings, SETTINGS_ENABLE_DEALS)
    : "";
  const enableReferrals = props.settings
    ? getKeyValueByObject(props.settings, SETTINGS_ENABLE_REFERRALS)
    : "";

  return (
    <div className={["expert-onboarding"].join(" ")}>
      {adminDashboard &&
        (adminDashboard.newReferralCount > 0 ||
          adminDashboard.pendingPartnerCount > 0 ||
          adminDashboard.pendingDealsCount > 0 ||
          adminDashboard.pendingLeadCount > 0) && (
          <>
            <div>
              <h6 className="mb-0 text-info font-weight-bold quick-link">
                Need Attention
                <>
                  {adminDashboard.closeReferralCount > 0 && (
                    <ClosedReferralsPopup
                      closeReferralCount={adminDashboard.closeReferralCount}
                      newReferralCount={adminDashboard.newReferralCount}
                    />
                  )}
                  {adminDashboard.newReferralCount > 0 && (
                    <ReferralsPopUp
                      newReferralCount={adminDashboard.newReferralCount}
                    />
                  )}
                  {newAppCount > 0 && (
                    <AppReviewPopUp newAppCount={newAppCount} />
                  )}
                </>
              </h6>
            </div>
            {enableReferrals &&
              enableReferrals === "true" &&
              adminDashboard.newReferralCount > 0 && (
                <AdminNeedsAttentionCard
                  pageURL={"/referrals/new/?status=New"}
                  title={`${Terminology.get(
                    SystemName.REFERRALS
                  )} that Need to be Approved`}
                  subTitle={`Review and approve ${Terminology.get(
                    SystemName.REFERRALS
                  )} submitted by your partners`}
                  count={adminDashboard.newReferralCount || 0}
                  bellIcon="far fa-bell fa-2x"
                  avatorColor="red-faded"
                  countColor={"#FF6347"}
                  textColor={"#FF6347"}
                />
              )}
            {adminDashboard.newMessage > 0 && (
              <AdminNeedsAttentionCard
                pageURL={"/partner-communications"}
                title="New Partner Communications"
                subTitle="Review and respond to messages submitted by your
                partners"
                count={adminDashboard.newMessage || 0}
                bellIcon="far fa-bell fa-2x"
                avatorColor="red-faded"
                countColor={"#FF6347"}
                textColor={"#FF6347"}
              />
            )}
            {enablePartners &&
              enablePartners === "true" &&
              adminDashboard.pendingPartnerCount > 0 && (
                <AdminNeedsAttentionCard
                  pageURL={`/marketplace-sourcing?section=Applicants&pending=${PENDING_PARTNERS}`}
                  title="New Partner Applications"
                  subTitle="Review pending partner applications to accept into
                  your partner programs"
                  count={adminDashboard.pendingPartnerCount || 0}
                  avatorColor="blue-faded"
                  textColor={"#009DDA"}
                />
              )}
            {enableDeals &&
              enableDeals === "true" &&
              adminDashboard.pendingDealsCount > 0 && (
                <AdminNeedsAttentionCard
                  pageURL={"/deals"}
                  title="Pending Registered Deals"
                  subTitle="Review and approve deals submitted by your partners"
                  count={adminDashboard.pendingDealsCount || 0}
                  avatorColor="blue-faded"
                  textColor={"#009DDA"}
                />
              )}
            {adminDashboard.pendingLeadCount > 0 && (
              <AdminNeedsAttentionCard
                pageURL={"/leads"}
                title="New Leads"
                subTitle="Distribute leads submitted from the marketplace"
                count={adminDashboard.pendingLeadCount || 0}
                avatorColor="blue-faded"
                textColor={"#009DDA"}
              />
            )}
          </>
        )}
    </div>
  );
};

export default AdminNeedsAttention;
