import React from "react";
import DefaultContent from "../content/DefaultContent";
import TextArea from "../base/TextArea";

const ExpertProfileAboutMe = ({ id }) => {
  return (
    <>
      <DefaultContent title={"About me"}>
        <p className={["text-inline-grayed"].join("")}>
          Tell us more about you and your skills. This will be shown to
          customers prominently on your profile. This is your personal
          statement, so be thorough!
        </p>
        <TextArea
          id={id}
          name="aboutMe"
          showCharCount
          required
          placeholder={"About Me"}
        />
      </DefaultContent>
    </>
  );
};

export default ExpertProfileAboutMe;
