import React from "react";
import { getKeyValueByObject } from "../lib/helper";
import {
  SETTINGS_THEME_LAYOUT,
  THEME_LAYOUT_FIXED_WIDTH
} from "../setting/Constants";

class LandingPageTitle extends React.Component {
  render() {
    const { settings } = this.props;

    const layout =
      settings && getKeyValueByObject(settings, SETTINGS_THEME_LAYOUT);

    let fixedWidth = layout == THEME_LAYOUT_FIXED_WIDTH;

    return (
      <div className="section-title col-md-9 ">
        <div className="form-inline">
          <span
            className="h1 d-block"
            style={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              maxWidth: fixedWidth ? "400px" : "75%",
              minWidth: "auto",
              resize: "horizontal"
            }}
          >
            {this.props.name}
          </span>
          <span
            style={{
              backgroundColor: "white",
              padding: "10px",
              borderRadius: "8px"
            }}
            className="ml-2 mt-n5 cursor-pointer"
            onClick={this.props.handleEdit}
          >
            <i className="fas fa-pen" />
          </span>
        </div>
      </div>
    );
  }
}

export default LandingPageTitle;
