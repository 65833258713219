import React from "react";

// Page components
import UpdatePaymentForm from "./UpdatePaymentForm";

const PaymentDetails = props => {
  return (
    <div>
      <UpdatePaymentForm history={props.history} match={props.match} />
    </div>
  );
};

export default PaymentDetails;
