import React from "react";
import Page404 from "../../../Page404";
import toast from "../../../../components/base/Toast";
import OpportunityFields from "./inner-components/OpportunityFields";

// Components
import Form from "../../../../components/base/Form";
import DeleteButton from "../../../../components/base/DeleteButton";
import CancelButton from "../../../../components/base/CancelButton";
import AdminActionNavigation from "../../../../components/base/AdminActionNavigation";
import { ChevronRight } from "../../../../assets/img/icons";

// Configs
import { endpoints } from "../../../../configs";

// API call
import { apiClient } from "../../../../apiClient";

// Helper
import {
  removeMaskedPhoneNumber,
  isSuperAdmin,
  isCompanyAdmin
} from "../../../../lib/helper";
import { isBadRequest } from "../../../../common/http";

import Spinner from "../../../../components/base/Spinner";

import { Nav, NavItem, NavLink } from "reactstrap";

import classnames from "classnames";

import ReduxTable, {
  ReduxColumn
} from "../../../../components/base/ReduxTable";

// Constants
import {
  OPPORTUNITY_DETAILS,
  REFERRAL_LIST
} from "../../../../salesforce/Constants";

import { Link } from "react-router-dom";
import Terminology from "../../../../lib/Terminology";
import SystemName from "../../../../Constants/SystemName";
import { connect } from "react-redux";

class OpportunityDetails extends React.Component {
  constructor(props) {
    super(props);
    // Set the initial input values
    this.state = {
      name: "",
      opportunityDetails: [],
      loading: false,
      isInvalidRequest: false,
      activeTab: OPPORTUNITY_DETAILS
    };
  }

  componentDidMount = () => {
    this._getOpportunityDetails();
  };

  // Opportunity delete api call
  handleDelete = () => {
    return apiClient
      .delete(`${endpoints().salesforceLookupAPI}/opportunity/${this.props.id}`)
      .then(response => {
        let successMessage;
        if (response && response.data) {
          successMessage = response.data.message;

          toast.success(successMessage);

          this.props.history.push("/salesforce/opportunity");
        }
      })
      .catch(error => {
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          toast.error(error.response.data.message);
          console.error(errorMessage);
        }
      });
  };

  // Opportunity details api call by id
  _getOpportunityDetails = tag => {
    this.setState({ loading: true });
    return apiClient
      .get(
        `${endpoints().salesforceLookupAPI}/opportunity/${
          this.props.match.params.id
        }`
      )
      .then(response => {
        this.setState({ opportunityDetails: response.data, loading: false });
        if (tag) {
          toast.success("Opportunity Synced Successfully");
        }
      })
      .catch(error => {
        this.setState({ loading: false });
        if (isBadRequest(error)) {
          let errorMessage;
          const errorRequest = error.response.request;
          if (errorRequest && errorRequest.response) {
            errorMessage = JSON.parse(errorRequest.response).message;
          }
          console.error(errorMessage);
          this.setState({ isInvalidRequest: true });
        }
      });
  };

  toggleTabs = activeTab => {
    this.setState({ activeTab: activeTab });
  };

  render() {
    if (this.state.isInvalidRequest) {
      return <Page404 />;
    }

    if (
      !isSuperAdmin(this.props.roleId) &&
      !isCompanyAdmin(this.props.roleId)
    ) {
      return <Page404 />;
    }

    if (this.state.loading) {
      return <Spinner />;
    }

    const {
      Id,
      Name,
      StageName,
      Amount,
      CloseDate,
      OwnerId,
      CreatedById,
      AccountId,
      IsDeleted,
      Description
    } = this.state.opportunityDetails;

    const initialValues = {
      salesforceId: Id,
      name: Name,
      stageName: StageName,
      amount: Amount,
      closeDate: CloseDate,
      ownerId: OwnerId,
      createdById: CreatedById,
      accountId: AccountId,
      isDeleted: IsDeleted,
      description: Description
    };

    return (
      <>
        {/* Breadcrumbs Start */}
        <AdminActionNavigation>
          <div className="d-flex align-items-center mb-3">
            <a
              className="cursor-pointer"
              onClick={() => {
                this.props.history.push("/salesforce/opportunity");
              }}
            >
              Salesforce Opportunity
            </a>
            <span
              className="text-dark d-inline-flex align-items-center"
              style={{ width: "15px" }}
            >
              <ChevronRight />
            </span>
            <span
              onClick={e => e.preventDefault()}
              className={[
                "text-dark",
                "font-weight-bold",
                "d-inline-flex",
                "align-items-center",
                "project-name",
                "w-320"
              ].join(" ")}
            >
              <span className="ellipsis">{Id || "Opportunity Details"}</span>
            </span>
          </div>

          <CancelButton
            className="ml-auto mr-1 mt-1"
            onClick={() => this.props.history.goBack()}
          />

          <DeleteButton
            className="mt-1"
            label={"Delete Account"}
            onClick={this.handleDelete}
          />
          <button
            className="btn btn-primary ml-2 py-1"
            type="button"
            onClick={() => this._getOpportunityDetails(true)}
          >
            Sync From Salesforce
          </button>
        </AdminActionNavigation>

        {/* Breadcrumbs Ends */}

        <Nav tabs className="admin-tabs">
          {/* General Tab */}
          <NavItem>
            <NavLink
              className={classnames({
                active:
                  this.state.activeTab == OPPORTUNITY_DETAILS ? true : false
              })}
              onClick={() => {
                this.toggleTabs(OPPORTUNITY_DETAILS);
              }}
            >
              Details
            </NavLink>
          </NavItem>
          <NavItem>
            <NavLink
              className={classnames({
                active: this.state.activeTab == REFERRAL_LIST ? true : false
              })}
              onClick={() => {
                this.toggleTabs(REFERRAL_LIST);
              }}
            >
              {Terminology.get(SystemName.REFERRAL)}
            </NavLink>
          </NavItem>
        </Nav>

        {this.state.activeTab == OPPORTUNITY_DETAILS ? (
          <div className="mt-2">
            <Form
              initialValues={initialValues}
              onSubmit={values => {
                values.phone =
                  values.phone && removeMaskedPhoneNumber(values.phone);
              }}
            >
              <OpportunityFields
                opportunityDetails={this.state.opportunityDetails}
              />
            </Form>
          </div>
        ) : this.state.activeTab == REFERRAL_LIST ? (
          <div className="mt-2">
            <ReduxTable
              id="url"
              apiURL={`${endpoints().salesforceLookupAPI}/referral/search/${
                this.props.match.params.id
              }`}
            >
              <ReduxColumn
                field="AccountName"
                renderField={row => (
                  <Link to={`/referral/edit/${row.id}`}>{row.AccountName}</Link>
                )}
              >
                Account
              </ReduxColumn>

              <ReduxColumn field="Contact">Contact</ReduxColumn>

              <ReduxColumn field="SubmittedBy">Submitted By</ReduxColumn>

              <ReduxColumn field="Status">Status</ReduxColumn>

              <ReduxColumn field="Amount">Amount</ReduxColumn>

              <ReduxColumn field="CreatedAt">Created At</ReduxColumn>
            </ReduxTable>
          </div>
        ) : (
          ""
        )}
      </>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state;

  const roleId = user && !user.isFetching ? user.roleId : "";

  return { roleId };
};

export default connect(() => {
  return mapStateToProps;
}, "")(OpportunityDetails);
